import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCashmilon } from "vhows-design/src/object/design/item/list/ItemCashmilon";
import { ItemLagging } from "vhows-design/src/object/design/item/list/ItemLagging";
import { ItemMat } from "vhows-design/src/object/design/item/list/ItemMat";
import { ItemNonwoven } from "vhows-design/src/object/design/item/list/ItemNonwoven";
import { ItemQuilting } from "vhows-design/src/object/design/item/list/ItemQuilting";
import { ItemScreen } from "vhows-design/src/object/design/item/list/ItemScreen";
import { ItemShadenet } from "vhows-design/src/object/design/item/list/ItemShadenet";
import { ItemTent } from "vhows-design/src/object/design/item/list/ItemTent";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { ItemWeavingFilm } from "vhows-design/src/object/design/item/list/ItemWeavingFilm";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";
import { PartitionPosition } from "vhows-design/src/object/design/other/partition/PartitionPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-04
 */
@jsonObject({
  knownTypes: [],
})
export class PartitionPart_Cover extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public coverLevel: CoverLevel; // 기초 피복 중수

  // 샘플

  // 아이템

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  protected partitionWidth: number; // 칸막이 피복 하나의 폭
  protected partitionLength: number; // 칸막이 피복 하나의 길이
  protected partitionTotalLength: number; // 칸막이 피복 전체 길이

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    // 아이템
    this.itemAC = [
      // 동적 생성
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.init_coverLevel();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    /// //////// 선언 ///////////

    const partitionPosition: PartitionPosition = <PartitionPosition>this.position;

    /// //////// 수치 ///////////

    // 칸막이 피복 하나의 폭과 길이
    this.partitionWidth = this.basicLevel.heightMax + 0.2 + partitionPosition.gatePart.doorQuantity * 2.0;
    this.partitionLength = this.basicLevel.width + 0.5;

    // 칸막이 피복의 통합 길이
    this.partitionTotalLength = this.partitionLength * partitionPosition.partitionQuantity;

    /// //////// 생성, 선택, 가시성, 규격, 수량 ///////////

    for (const coverPosition of this.coverLevel.positionAC as CoverPosition[]) {
      if (
        coverPosition.type === CONST.LB_COVER_TYPE_VINYL ||
        coverPosition.type === CONST.LB_COVER_TYPE_WEAVING_FILM ||
        coverPosition.type === CONST.LB_COVER_TYPE_SHADENET ||
        coverPosition.type === CONST.LB_COVER_TYPE_TENT ||
        coverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN ||
        coverPosition.type === CONST.LB_COVER_TYPE_QUILTING ||
        coverPosition.type === CONST.LB_COVER_TYPE_SCREEN ||
        coverPosition.type === CONST.LB_COVER_TYPE_MAT
      ) {
        // 비닐류
        this.makePartitionVinylStyle(coverPosition);
      } else if (
        coverPosition.type === CONST.LB_COVER_TYPE_CASHMILON ||
        coverPosition.type === CONST.LB_COVER_TYPE_COLOR_LAGGING ||
        coverPosition.type === CONST.LB_COVER_TYPE_LAGGING
      ) {
        // 카시미론류
        this.makePartitionCashmilonStyle(coverPosition);
      }
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 초기화: 기초 피복 중수
   */
  protected init_coverLevel(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.coverLevel = <CoverLevel>this.struct.coverWork.levelAC[this.level.index];
    }
  }

  /**
   * 비닐류 칸막이 피복 만들기
   */
  protected makePartitionVinylStyle(coverPosition: CoverPosition): void {
    let item: Item;

    // 전체
    if (this.position.label === CONST.LB_POSITION_WHOLE && coverPosition.partitionCoverTrunk === true) {
      item = this.getNewPartitionCoverItem(coverPosition);
      item.purpose = `칸막이 피복> ${coverPosition.label}`;
      item.designQuantity = 1;

      this.itemAC.push(item);
    }
  }

  /**
   * 카시미론류 칸막이 피복 만들기
   */
  protected makePartitionCashmilonStyle(coverPosition: CoverPosition): void {
    const partitionPosition: PartitionPosition = <PartitionPosition>this.position;
    let item: any;
    const overlapSize: number = this.design.preference.cashmilonStyleOverlapLength;

    // 전체
    if (this.position.label === CONST.LB_POSITION_WHOLE && coverPosition.partitionCoverTrunk === true) {
      item = this.getNewPartitionCoverItem(coverPosition);
      item.purpose = `칸막이 피복> ${coverPosition.label}`;
      item.designQuantity =
        ((this.partitionWidth * this.partitionLength) / ((item.specWidth - overlapSize) * item.specLength)) *
        partitionPosition.partitionQuantity;

      this.itemAC.push(item);
    }
  }

  /**
   * 새로운 칸막이 피복 아이템 만들기
   * @param coverPosition 기초 피복 위치 객체
   * @return 칸막이 피복 아이템
   */
  private getNewPartitionCoverItem(coverPosition: CoverPosition): Item {
    let item: Item;

    if (coverPosition.type === CONST.LB_COVER_TYPE_VINYL) {
      item = ItemVinyl.getNewDesignItemBySample(this, coverPosition.vinylPart.vinylSample);

      (<ItemVinyl>item).specWidth = (<ItemVinyl>item).getFoldedWidth(CommonUtil.convertM2CM(this.partitionWidth));
      (<ItemVinyl>item).specLength = this.partitionTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_WEAVING_FILM) {
      item = ItemWeavingFilm.getNewDesignItemBySample(this, coverPosition.weavingFilmPart.weavingFilmSample);

      (<ItemWeavingFilm>item).specWidth = CommonUtil.convertM2CM(this.partitionWidth);
      (<ItemWeavingFilm>item).specLength = this.partitionTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_SHADENET) {
      item = ItemShadenet.getNewDesignItemBySample(this, coverPosition.shadenetPart.shadenetSample);

      (<ItemShadenet>item).specWidth = this.partitionWidth;
      (<ItemShadenet>item).specLength = (<ItemShadenet>item).getAjustedSpecLength(this.partitionTotalLength);
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_TENT) {
      item = ItemTent.getNewDesignItemBySample(this, coverPosition.tentPart.tentSample);

      (<ItemTent>item).specWidth = this.partitionWidth;
      (<ItemTent>item).specLength = this.partitionTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN) {
      item = ItemNonwoven.getNewDesignItemBySample(this, coverPosition.nonwovenPart.nonwovenSample);

      (<ItemNonwoven>item).specWidth = this.partitionWidth;
      (<ItemNonwoven>item).specLength = this.partitionTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_CASHMILON) {
      item = ItemCashmilon.getNewDesignItemBySample(this, coverPosition.cashmilonPart.cashmilonSample);
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
      item = ItemLagging.getNewDesignItemBySample(this, coverPosition.colorLaggingPart.colorLaggingSample);
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_LAGGING) {
      item = ItemLagging.getNewDesignItemBySample(this, coverPosition.laggingPart.laggingSample);
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_QUILTING) {
      item = ItemQuilting.getNewDesignItemBySample(this, coverPosition.quiltingPart.quiltingSample);

      (<ItemQuilting>item).specWidth = this.partitionWidth;
      (<ItemQuilting>item).specLength = this.partitionTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_SCREEN) {
      item = ItemScreen.getNewDesignItemBySample(this, coverPosition.screenPart.screenSample);

      (<ItemScreen>item).specWidth = this.partitionWidth;
      (<ItemScreen>item).specLength = this.partitionTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_MAT) {
      item = ItemMat.getNewDesignItemBySample(this, coverPosition.matPart.matSample);

      (<ItemMat>item).specWidth = this.partitionWidth;
      (<ItemMat>item).specLength = this.partitionTotalLength;
    }

    return item;
  }
}
