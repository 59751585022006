import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPadVinyl } from "vhows-design/src/object/design/item/list/ItemPadVinyl";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { Part } from "vhows-design/src/object/design/base/Part";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { SkyFrameSample_CondensationPad } from "vhows-design/src/object/design/skylight/frame/SkyFrameSample_CondensationPad";
import { SkyFrameSample_NormalPad } from "vhows-design/src/object/design/skylight/frame/SkyFrameSample_NormalPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-24
 */
@jsonObject({
  knownTypes: [SkyFrameSample_CondensationPad, SkyFrameSample_NormalPad],
})
export class SkyFramePart_Enhanced extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public enhancedCondensationPadSample: SkyFrameSample_CondensationPad; // 보강용 결로 패드 샘플
  public enhancedNormalPadSample: SkyFrameSample_NormalPad; // 보강용 일반 패드 샘플

  // 아이템
  public enhanced_saddleHolder: ItemHolder;
  public enhanced_condensationPad: ItemPad;
  public enhanced_condensationPadConnectionPin: ItemPadConnector;
  public enhanced_pePipe: ItemWateringPipe;
  public enhanced_normalPad: ItemPad;
  public enhanced_padConnectionPin: ItemPadConnector;
  public enhanced_normalPadSpring: ItemPadSpring;
  public enhanced_padVinyl: ItemPadVinyl;
  public enhanced_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.enhancedCondensationPadSample = new SkyFrameSample_CondensationPad();
    this.enhancedNormalPadSample = new SkyFrameSample_NormalPad();

    this.sampleAC = [this.enhancedCondensationPadSample, this.enhancedNormalPadSample];

    // 아이템

    // 아이템
    this.enhanced_saddleHolder = new ItemHolder();
    this.enhanced_condensationPad = new ItemPad();
    this.enhanced_condensationPadConnectionPin = new ItemPadConnector();
    this.enhanced_pePipe = new ItemWateringPipe();
    this.enhanced_normalPad = new ItemPad();
    this.enhanced_padConnectionPin = new ItemPadConnector();
    this.enhanced_normalPadSpring = new ItemPadSpring();
    this.enhanced_padVinyl = new ItemPadVinyl();
    this.enhanced_screw = new ItemScrew();

    this.itemAC = [
      this.enhanced_saddleHolder,
      this.enhanced_condensationPad,
      this.enhanced_condensationPadConnectionPin,
      this.enhanced_pePipe,
      this.enhanced_normalPad,
      this.enhanced_padConnectionPin,
      this.enhanced_normalPadSpring,
      this.enhanced_padVinyl,
      this.enhanced_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.enhancedCondensationPadSample = <SkyFrameSample_CondensationPad>this.sampleAC[0];
    this.enhancedNormalPadSample = <SkyFrameSample_NormalPad>this.sampleAC[1];

    // 아이템
    this.enhanced_saddleHolder = <ItemHolder>this.itemAC[0];
    this.enhanced_condensationPad = <ItemPad>this.itemAC[1];
    this.enhanced_condensationPadConnectionPin = <ItemPadConnector>this.itemAC[2];
    this.enhanced_pePipe = <ItemWateringPipe>this.itemAC[3];
    this.enhanced_normalPad = <ItemPad>this.itemAC[4];
    this.enhanced_padConnectionPin = <ItemPadConnector>this.itemAC[5];
    this.enhanced_normalPadSpring = <ItemPadSpring>this.itemAC[6];
    this.enhanced_padVinyl = <ItemPadVinyl>this.itemAC[7];
    this.enhanced_screw = <ItemScrew>this.itemAC[8];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.enhancedCondensationPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_CONDENSATION_PAD,
        CONST.ITEM_NAME_CONDENSATION_PAD,
        "패드 보강",
        "알루미늄, 1T, 5m",
      );
      this.enhancedNormalPadSample.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "패드 보강",
        "칼라 철, 0.6T, 6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.enhanced_saddleHolder.setDefaultData(
        0,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "새들 보강",
        "원형, 일반, 25mm, -",
      );
      this.enhanced_condensationPad.setDefaultData(
        1,
        CONST.ITEM_ID_CONDENSATION_PAD,
        CONST.ITEM_NAME_CONDENSATION_PAD,
        "패드 보강",
        "알루미늄, 1T, 5m",
      );
      this.enhanced_condensationPadConnectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONDENSATION_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_CONDENSATION_PAD_CONNECTION_PIN,
        "패드 보강",
        "플라스틱",
      );
      this.enhanced_pePipe.setDefaultData(
        3,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "패드 보강",
        "연질관, 13mm, 200m",
      );
      this.enhanced_normalPad.setDefaultData(
        4,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "패드 보강",
        "칼라 철, 0.6T, 6m",
      );
      this.enhanced_padConnectionPin.setDefaultData(
        5,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "패드 보강",
        "칼라 철",
      );
      this.enhanced_normalPadSpring.setDefaultData(
        6,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "패드 보강",
        "일반, 2m",
      );
      this.enhanced_padVinyl.setDefaultData(
        7,
        CONST.ITEM_ID_PAD_VINYL,
        CONST.ITEM_NAME_PAD_VINYL,
        "패드 보강",
        "일반, 200m",
      );
      this.enhanced_screw.setDefaultData(
        8,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "골조 보강",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_enhanced_saddleHolder();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    /// //////// 선택, 가시성 ///////////

    if ((<SkyFramePosition>this.position).enhancedType === CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE) {
      this.enhanced_saddleHolder.selected = true;
      this.enhanced_saddleHolder.visible = true;
      this.enhanced_condensationPad.selected = false;
      this.enhanced_condensationPad.visible = false;
      this.enhanced_condensationPadConnectionPin.selected = false;
      this.enhanced_condensationPadConnectionPin.visible = false;
      this.enhanced_pePipe.selected = false;
      this.enhanced_pePipe.visible = false;
      this.enhanced_normalPad.selected = false;
      this.enhanced_normalPad.visible = false;
      this.enhanced_padConnectionPin.setSelectedByPad(false, this.enhanced_normalPad.specMaterial);
      this.enhanced_padConnectionPin.visible = false;
      this.enhanced_normalPadSpring.selected = false;
      this.enhanced_normalPadSpring.visible = false;
      this.enhanced_padVinyl.selected = false;
      this.enhanced_padVinyl.visible = false;
      this.enhanced_screw.selected = true;
      this.enhanced_screw.visible = true;
    } else if (
      (<SkyFramePosition>this.position).enhancedType === CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_CONDENSATION_PAD
    ) {
      this.enhanced_saddleHolder.selected = true;
      this.enhanced_saddleHolder.visible = true;
      this.enhanced_condensationPad.selected = true;
      this.enhanced_condensationPad.visible = true;
      this.enhanced_condensationPadConnectionPin.selected = true;
      this.enhanced_condensationPadConnectionPin.visible = true;
      this.enhanced_pePipe.selected = true;
      this.enhanced_pePipe.visible = true;
      this.enhanced_normalPad.selected = false;
      this.enhanced_normalPad.visible = false;
      this.enhanced_padConnectionPin.setSelectedByPad(false, this.enhanced_normalPad.specMaterial);
      this.enhanced_padConnectionPin.visible = false;
      this.enhanced_normalPadSpring.selected = true;
      this.enhanced_normalPadSpring.visible = true;
      this.enhanced_padVinyl.selected = this.design.basic.padVinylFlag;
      this.enhanced_padVinyl.visible = true;
      this.enhanced_screw.selected = true;
      this.enhanced_screw.visible = true;
    } else if (
      (<SkyFramePosition>this.position).enhancedType === CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_NORMAL_PAD
    ) {
      this.enhanced_saddleHolder.selected = true;
      this.enhanced_saddleHolder.visible = true;
      this.enhanced_condensationPad.selected = false;
      this.enhanced_condensationPad.visible = false;
      this.enhanced_condensationPadConnectionPin.selected = false;
      this.enhanced_condensationPadConnectionPin.visible = false;
      this.enhanced_pePipe.selected = false;
      this.enhanced_pePipe.visible = false;
      this.enhanced_normalPad.selected = true;
      this.enhanced_normalPad.visible = true;
      this.enhanced_padConnectionPin.setSelectedByPad(true, this.enhanced_normalPad.specMaterial);
      this.enhanced_padConnectionPin.visible = true;
      this.enhanced_normalPadSpring.selected = true;
      this.enhanced_normalPadSpring.visible = true;
      this.enhanced_padVinyl.selected = this.design.basic.padVinylFlag;
      this.enhanced_padVinyl.visible = true;
      this.enhanced_screw.selected = true;
      this.enhanced_screw.visible = true;
    }

    /// //////// 수치 ///////////

    /// //////// 수량 ///////////

    if ((<SkyFramePosition>this.position).enhancedType === CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE) {
      this.enhanced_saddleHolder.designQuantity =
        (trunkPosition.rafterPart.rafterQuantity / 2 + 1 * this.basic.buildingNumber) *
        2 *
        CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
    } else if (
      (<SkyFramePosition>this.position).enhancedType ===
        CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_CONDENSATION_PAD ||
      (<SkyFramePosition>this.position).enhancedType === CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_NORMAL_PAD
    ) {
      this.enhanced_saddleHolder.designQuantity =
        (trunkPosition.rafterPart.rafterQuantity / 2 + 1 * this.basic.buildingNumber) *
        1 *
        CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
    }

    this.enhanced_condensationPad.designQuantity =
      Math.ceil(skyFramePosition.skylightLength / this.enhanced_condensationPad.specLength) *
      1 *
      this.basic.buildingNumber;

    this.enhanced_condensationPadConnectionPin.designQuantity = this.enhanced_condensationPad.getSelectedQuantity();

    this.enhanced_pePipe.designQuantity =
      (this.enhanced_condensationPadConnectionPin.getSelectedQuantity() * 3) / this.enhanced_pePipe.specLength;

    this.enhanced_normalPad.designQuantity =
      Math.ceil(skyFramePosition.skylightLength / this.enhanced_normalPad.specLength) * 1 * this.basic.buildingNumber;

    this.enhanced_padConnectionPin.designQuantity = this.enhanced_normalPad.getSelectedQuantity();

    this.enhanced_normalPadSpring.designQuantity =
      (this.enhanced_condensationPad.getSelectedQuantity() *
        (this.enhanced_condensationPad.specLength / this.enhanced_normalPadSpring.specLength) +
        this.enhanced_normalPad.getSelectedQuantity() *
          (this.enhanced_normalPad.specLength / this.enhanced_normalPadSpring.specLength)) *
      CONST.NUM_EXTRA_RATE_PAD_SPRING;

    this.enhanced_padVinyl.designQuantity =
      (this.enhanced_condensationPad.getSelectedQuantity() * this.enhanced_condensationPad.specLength +
        this.enhanced_normalPad.getSelectedQuantity() * this.enhanced_normalPad.specLength) /
      this.enhanced_padVinyl.specLength;

    this.enhanced_screw.designQuantity =
      ((this.enhanced_saddleHolder.getSelectedQuantity() * 3 +
        this.enhanced_condensationPad.getSelectedQuantity() * 6 +
        this.enhanced_condensationPadConnectionPin.getSelectedQuantity() * 2 +
        this.enhanced_normalPad.getSelectedQuantity() * 6 +
        this.enhanced_padConnectionPin.getSelectedQuantity() * 2) /
        this.enhanced_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [새들 보강] 새들 고정구 <- 서까래 파이프 샘플(기초 골조)
   */
  public algorithmSpec_enhanced_saddleHolder(): void {
    this.enhanced_saddleHolder.specPipeType =
      this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specPipeType;
    this.enhanced_saddleHolder.specCrossSize1 =
      this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [패드 보강] 결로 패드 <- 보강용 결로 패드 샘플
   */
  public algorithmSpec_enhanced_condensationPad(): void {
    this.enhanced_condensationPad.specs = this.enhancedCondensationPadSample.specs;
  }

  /**
   * 규격 알고리즘: [패드 보강] 결로 패드 연결핀 <- 공통 샘플
   */
  public algorithmSpec_enhanced_condensationPadConnectionPin(): void {}

  /**
   * 규격 알고리즘: [패드 보강] PE관 <- 공통 샘플
   */
  public algorithmSpec_enhanced_pePipe(): void {}

  /**
   * 규격 알고리즘: [패드 보강] 일반 패드 <- 보강용 일반 패드 샘플
   */
  public algorithmSpec_enhanced_normalPad(): void {
    this.enhanced_normalPad.specs = this.enhancedNormalPadSample.specs;
  }

  /**
   * 규격 알고리즘: [패드 보강] 패드 연결핀 <- 보강용 일반 패드 샘플
   */
  public algorithmSpec_enhanced_padConnectionPin(): void {}

  /**
   * 규격 알고리즘: [패드 보강] 일반 패드 스프링 <- 공통 샘플
   */
  public algorithmSpec_enhanced_normalPadSpring(): void {}

  /**
   * 규격 알고리즘: [패드 보강] 패드 비닐 <- 공통 샘플
   */
  public algorithmSpec_enhanced_padVinyl(): void {}

  /**
   * 규격 알고리즘: [골조 보강] 나사 <- 공통 샘플
   */
  public algorithmSpec_enhanced_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
