import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { ItemDrainPipeHanger } from "vhows-design/src/object/design/item/list/ItemDrainPipeHanger";
import { ItemDrainspoutBox } from "vhows-design/src/object/design/item/list/ItemDrainspoutBox";
import { ItemPvcConnector } from "vhows-design/src/object/design/item/list/ItemPvcConnector";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { GutterSampleIL_DrainagePVCPipe } from "vhows-design/src/object/design/frame/gutter/il/GutterSampleIL_DrainagePVCPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-20
 */
@jsonObject({
  knownTypes: [GutterSampleIL_DrainagePVCPipe],
})
export class GutterPartIL_DrainagePVCPipe extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public drainagePVCPipeSample: GutterSampleIL_DrainagePVCPipe; // 배수 PVC관 샘플

  // 아이템
  public pvcPipe_pvcPipe: ItemDrainPipe;
  public pvcPipe_pvcSocket: ItemPvcConnector;
  public pvcPipe_pvcElbow: ItemPvcConnector;
  public pvcPipe_pvcDegree45Elbow: ItemPvcConnector;
  public pvcPipe_pvcExactT: ItemPvcConnector;
  public pvcPipe_drainspoutBox: ItemDrainspoutBox;
  public pvcPipe_drainPipeHanger: ItemDrainPipeHanger;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _verticalPiping: number; // 수직 배관수

  /**
   * 수직 배관수
   */
  public get verticalPiping(): number {
    return this._verticalPiping;
  }

  //
  public set verticalPiping(value: number) {
    this._verticalPiping = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.drainagePVCPipeSample = new GutterSampleIL_DrainagePVCPipe();

    this.sampleAC = [this.drainagePVCPipeSample];

    // 아이템
    this.pvcPipe_pvcPipe = new ItemDrainPipe();
    this.pvcPipe_pvcSocket = new ItemPvcConnector();
    this.pvcPipe_pvcElbow = new ItemPvcConnector();
    this.pvcPipe_pvcDegree45Elbow = new ItemPvcConnector();
    this.pvcPipe_pvcExactT = new ItemPvcConnector();
    this.pvcPipe_drainspoutBox = new ItemDrainspoutBox();
    this.pvcPipe_drainPipeHanger = new ItemDrainPipeHanger();

    this.itemAC = [
      this.pvcPipe_pvcPipe,
      this.pvcPipe_pvcSocket,
      this.pvcPipe_pvcElbow,
      this.pvcPipe_pvcDegree45Elbow,
      this.pvcPipe_pvcExactT,
      this.pvcPipe_drainspoutBox,
      this.pvcPipe_drainPipeHanger,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.drainagePVCPipeSample = <GutterSampleIL_DrainagePVCPipe>this.sampleAC[0];

    // 아이템
    this.pvcPipe_pvcPipe = <ItemDrainPipe>this.itemAC[0];
    this.pvcPipe_pvcSocket = <ItemPvcConnector>this.itemAC[1];
    this.pvcPipe_pvcElbow = <ItemPvcConnector>this.itemAC[2];
    this.pvcPipe_pvcDegree45Elbow = <ItemPvcConnector>this.itemAC[3];
    this.pvcPipe_pvcExactT = <ItemPvcConnector>this.itemAC[4];
    this.pvcPipe_drainspoutBox = <ItemDrainspoutBox>this.itemAC[5];
    this.pvcPipe_drainPipeHanger = <ItemDrainPipeHanger>this.itemAC[6];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.drainagePVCPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PVC_PIPE,
        CONST.ITEM_NAME_PVC_PIPE,
        "PVC관 배수",
        "일반, 50mm, 4m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.pvcPipe_pvcPipe.setDefaultData(
        0,
        CONST.ITEM_ID_PVC_PIPE,
        CONST.ITEM_NAME_PVC_PIPE,
        "PVC관 배수",
        "일반, 50mm, 4m",
      );
      this.pvcPipe_pvcSocket.setDefaultData(
        1,
        CONST.ITEM_ID_PVC_SOCKET,
        CONST.ITEM_NAME_PVC_SOCKET,
        "PVC관 배수",
        "PVC, 일반, 50mm, 50mm",
      );
      this.pvcPipe_pvcElbow.setDefaultData(
        2,
        CONST.ITEM_ID_PVC_ELBOW,
        CONST.ITEM_NAME_PVC_ELBOW,
        "PVC관 배수",
        "PVC, 일반, 50mm, 50mm",
      );
      this.pvcPipe_pvcDegree45Elbow.setDefaultData(
        3,
        CONST.ITEM_ID_PVC_DEGREE45_ELBOW,
        CONST.ITEM_NAME_PVC_DEGREE45_ELBOW,
        "PVC관 배수",
        "PVC, 일반, 50mm, 50mm",
      );
      this.pvcPipe_pvcExactT.setDefaultData(
        4,
        CONST.ITEM_ID_PVC_EXACT_T,
        CONST.ITEM_NAME_PVC_EXACT_T,
        "PVC관 배수",
        "PVC, 일반, 50mm, 50mm",
      );
      this.pvcPipe_drainspoutBox.setDefaultData(
        5,
        CONST.ITEM_ID_DRAINSPOUT_BOX,
        CONST.ITEM_NAME_DRAINSPOUT_BOX,
        "PVC관 배수",
        "일반, 50mm",
      );
      this.pvcPipe_drainPipeHanger.setDefaultData(
        6,
        CONST.ITEM_ID_DRAIN_PIPE_HANGER,
        CONST.ITEM_NAME_DRAIN_PIPE_HANGER,
        "PVC관 배수",
        "일반, 50mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_verticalPiping();

    super.algorithmBasic();
  }

  /**
   * 파트 알고리즘 <- 기둥 간격(기둥 골조), 앞면/뒷면 처마 길이(지붕 골조/1중)
   */
  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.pvcPipe_pvcPipe.selected = true;
    this.pvcPipe_pvcPipe.visible = true;
    this.pvcPipe_pvcSocket.selected = true;
    this.pvcPipe_pvcSocket.visible = true;
    this.pvcPipe_pvcElbow.selected = true;
    this.pvcPipe_pvcElbow.visible = true;
    this.pvcPipe_pvcDegree45Elbow.selected = true;
    this.pvcPipe_pvcDegree45Elbow.visible = true;
    this.pvcPipe_pvcExactT.selected = true;
    this.pvcPipe_pvcExactT.visible = true;
    this.pvcPipe_drainspoutBox.selected = true;
    this.pvcPipe_drainspoutBox.visible = true;
    this.pvcPipe_drainPipeHanger.selected = true;
    this.pvcPipe_drainPipeHanger.visible = true;

    /// //////// 수량 ///////////

    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      this.pvcPipe_pvcPipe.designQuantity =
        ((this.basicLevel.heightShoulder + 0.5) * this.verticalPiping +
          (this.basicLevel.width * this.basic.buildingNumber + 2.0)) /
        this.drainagePVCPipeSample.specLength;
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const rafterValleyQuantity: number = this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterValleyQuantity;
      this.pvcPipe_pvcPipe.designQuantity =
        ((this.basicLevel.heightShoulder + 0.5) * this.verticalPiping +
          (this.basicLevel.width * this.basic.buildingNumber * rafterValleyQuantity + 2.0)) /
        this.drainagePVCPipeSample.specLength;
    }
    let pvcSocketQuantity: number = this.basic.buildingNumber;
    if (this.basicLevel.heightShoulder > this.drainagePVCPipeSample.specLength) {
      pvcSocketQuantity += this.verticalPiping;
    }
    this.pvcPipe_pvcSocket.designQuantity = pvcSocketQuantity;

    this.pvcPipe_pvcElbow.designQuantity = 1;

    // this.pvcPipe_pvcDegree45Elbow.designQuantity = 0;

    this.pvcPipe_pvcExactT.designQuantity = this.verticalPiping - 1;

    this.pvcPipe_drainspoutBox.designQuantity = this.verticalPiping;

    let pipeHangerQuantity: number;
    if (this.basicLevel.heightShoulder <= 4) {
      pipeHangerQuantity = this.verticalPiping * 3;
    } else {
      pipeHangerQuantity = this.verticalPiping * 4;
    }
    this.pvcPipe_drainPipeHanger.designQuantity = pipeHangerQuantity;
  }

  /**
   * 알고리즘: 수직 배관수 <- 하우스 동수(기본 정보), 서까래 골 수량(벤로)
   */
  public algorithm_verticalPiping(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.level.index === 0) {
        this.verticalPiping = this.basic.buildingNumber - 1;
      } else {
        this.verticalPiping = this.basic.buildingNumber * 2;
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.level.index === 0) {
        const rafterValleyQuantity: number = this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterValleyQuantity;
        this.verticalPiping = this.basic.buildingNumber * rafterValleyQuantity + 1;
      } else {
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [PVC관 배수] PVC관 <- 배수 PVC관 샘플
   */
  public algorithm_pvcPipe_pvcPipe(): void {
    this.pvcPipe_pvcPipe.specs = this.drainagePVCPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [PVC관 배수] PVC 소켓 <- 배수 PVC관 샘플
   */
  public algorithm_pvcPipe_pvcSocket(): void {
    this.pvcPipe_pvcSocket.specDiameter1 = this.drainagePVCPipeSample.specDiameter;
    this.pvcPipe_pvcSocket.specDiameter2 = this.drainagePVCPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [PVC관 배수] PVC 엘보 <- 배수 PVC관 샘플
   */
  public algorithm_pvcPipe_pvcElbow(): void {
    this.pvcPipe_pvcElbow.specDiameter1 = this.drainagePVCPipeSample.specDiameter;
    this.pvcPipe_pvcElbow.specDiameter2 = this.drainagePVCPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [PVC관 배수] PVC 45도 엘보 <- 배수 PVC관 샘플
   */
  public algorithm_pvcPipe_pvcDegree45Elbow(): void {
    this.pvcPipe_pvcDegree45Elbow.specDiameter1 = this.drainagePVCPipeSample.specDiameter;
    this.pvcPipe_pvcDegree45Elbow.specDiameter2 = this.drainagePVCPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [PVC관 배수] PVC 정티 <- 배수 PVC관 샘플
   */
  public algorithm_pvcPipe_pvcExactT(): void {
    this.pvcPipe_pvcExactT.specDiameter1 = this.drainagePVCPipeSample.specDiameter;
    this.pvcPipe_pvcExactT.specDiameter2 = this.drainagePVCPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [PVC관 배수] 물받이통 <- 배수 PVC관 샘플
   */
  public algorithm_pvcPipe_drainspoutBox(): void {
    this.pvcPipe_drainspoutBox.specDiameter = this.drainagePVCPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [PVC관 배수] 잡이쇠 <- 배수 PVC관 샘플
   */
  public algorithm_pvcPipe_drainPipeHanger(): void {
    this.pvcPipe_drainPipeHanger.specDiameter = this.drainagePVCPipeSample.specDiameter;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
