import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { Part } from "vhows-design/src/object/design/base/Part";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { RoofSampleIL_RoofBracingPipe } from "vhows-design/src/object/design/frame/roof/RoofSampleIL_RoofBracingPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject({
  knownTypes: [RoofSampleIL_RoofBracingPipe],
})
export class RoofPartIL_RoofBracing extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public roofBracingPipeSample: RoofSampleIL_RoofBracingPipe; // 지붕 브레싱 파이프 샘플

  // 아이템
  public roofBracing_pipe: ItemPipe;
  public roofBracingAndRafter_steelStringGrabber: ItemHolder;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _roofBracingQuantity: number; // 지붕 브레싱 수량
  @jsonMember(Number)
  public _roofBracingTotalQuantity: number; // 지붕 브레싱 전체 수량

  /**
   * 지붕 브레싱 수량
   */
  public get roofBracingQuantity(): number {
    return this._roofBracingQuantity;
  }

  //
  public set roofBracingQuantity(value: number) {
    this._roofBracingQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_roofBracingTotalQuantity();
    this.algorithmPart();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_roofBracingQuantityByLevel1();
    }
  }

  /**
   * 지붕 브레싱 전체 수량
   */
  public get roofBracingTotalQuantity(): number {
    return this._roofBracingTotalQuantity;
  }

  public set roofBracingTotalQuantity(value: number) {
    this._roofBracingTotalQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.roofBracingPipeSample = new RoofSampleIL_RoofBracingPipe();

    this.sampleAC = [this.roofBracingPipeSample];

    // 아이템
    this.roofBracing_pipe = new ItemPipe();
    this.roofBracingAndRafter_steelStringGrabber = new ItemHolder();

    this.itemAC = [this.roofBracing_pipe, this.roofBracingAndRafter_steelStringGrabber];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.roofBracingPipeSample = <RoofSampleIL_RoofBracingPipe>this.sampleAC[0];

    // 아이템
    this.roofBracing_pipe = <ItemPipe>this.itemAC[0];
    this.roofBracingAndRafter_steelStringGrabber = <ItemHolder>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param roofBracingQuantity: number 지붕 브레싱 수량
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    roofBracingQuantity: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._roofBracingQuantity = roofBracingQuantity;

    // 샘플
    if (this.level.index >= 0) {
      this.roofBracingPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕 브레싱",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.roofBracing_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕 브레싱",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.roofBracingAndRafter_steelStringGrabber.setDefaultData(
        1,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "지붕 브레싱 + 서까래",
        "원형, 일반, 25mm, 32mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_roofBracingTotalQuantity();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.roofBracing_pipe.selected = true;
    this.roofBracing_pipe.visible = true;
    this.roofBracingAndRafter_steelStringGrabber.selected = true;
    this.roofBracingAndRafter_steelStringGrabber.visible = true;

    /// //////// 수량 ///////////

    this.roofBracing_pipe.designQuantity = this.roofBracingTotalQuantity;

    this.roofBracingAndRafter_steelStringGrabber.designQuantity =
      Math.floor(this.roofBracing_pipe.specLength * 2) *
      this.roofBracing_pipe.getSelectedQuantity() *
      CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
  }

  /**
   * 알고리즘: 지붕 브레싱 수량 <- 지붕 브레싱 수량(1중)
   */
  public algorithm_roofBracingQuantityByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.roofWorkIL.levelAC.length; l++) {
        (<RoofLevelIL>this.struct.roofWorkIL.levelAC[l]).roofPosition.roofBracingPart.roofBracingQuantity =
          this.roofBracingQuantity;
      }
    }
  }

  /**
   * 알고리즘: 지붕 브레싱 전체 수량 <- 지붕 브레싱 수량, 동수(기본정보)
   */
  public algorithm_roofBracingTotalQuantity(): void {
    this.roofBracingTotalQuantity = this.roofBracingQuantity * this.basic.buildingNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [지붕 브레싱] 파이프 <- 지붕 브레싱 파이프 샘플
   */
  public algorithmSpec_roofBracing_pipe(): void {
    this.roofBracing_pipe.specs = this.roofBracingPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [지붕 브레싱 + 서까래] 강선 조리개 <- 지붕 브레싱 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_roofBracingAndRafter_steelStringGrabber(): void {
    this.roofBracingAndRafter_steelStringGrabber.specPipeType = this.roofBracingPipeSample.specPipeType;
    this.roofBracingAndRafter_steelStringGrabber.specCrossSize1 = this.roofBracingPipeSample.specCrossSize;
    this.roofBracingAndRafter_steelStringGrabber.specCrossSize2 = (<RoofPositionIL>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
