import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ItemGroundCarrier } from "vhows-design/src/object/design/item/list/ItemGroundCarrier";
import { RailPosition } from "vhows-design/src/object/design/other/rail/RailPosition";
import { RailPart_Carrier } from "vhows-design/src/object/design/other/rail/RailPart_Carrier";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-10-29
 */
@jsonObject
export class RailSample_GroundCarrier extends ItemGroundCarrier {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // 설계 수량
  // @override
  public get designQuantity(): number {
    return this._designQuantity;
  }

  //
  public set designQuantity(value: number) {
    if (value < 0) {
      this._designQuantity = 0;
    } else {
      this._designQuantity = CommonUtil.roundUp(CommonUtil.fixFloat(value), 1);
    }

    // 알고리즘
    if (this.isInit === false) {
      // 파트 알고리즘 호출
      this.part.algorithmPart();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const carrierPart: RailPart_Carrier = <RailPart_Carrier>this.part;
    carrierPart.algorithmSpec_carrier_groundCarrier();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    (<RailPosition>this.position).groundRailPart.algorithmSpec_rail_railInterval();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 지면 레일 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specRollerSize = (<RailPosition>this.position).groundRailPart.railPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
