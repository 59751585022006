import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SkySwitchPositionIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPositionIL";
import { SkySwitchPartIL_SupDiagonal } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPartIL_SupDiagonal";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-22
 */
@jsonObject
export class SkySwitchSampleIL_DiagonalSupAssistPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const supDiagonalPart: SkySwitchPartIL_SupDiagonal = <SkySwitchPartIL_SupDiagonal>this.part;
    supDiagonalPart.algorithmSpec_diagonalSupportAssist_pipe();
    supDiagonalPart.algorithmSpec_diagonalSupport_supportFixingPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    (<SkySwitchPositionIL>this.position).powerPart.powerSwitcherSample.algorithmSpec();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 대각형 지지 보조 파이프 샘플(개폐기)
   */
  // @override
  public algorithmSpec(): void {
    this.specs = this.struct.switcherWorkIL.level1.columnPosition.supportPart.diagonalSupportAssistPipeSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
