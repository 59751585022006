import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Struct } from "vhows-design/src/object/design/base/Struct";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-16
 */
@jsonObject({
  knownTypes: [Level],
})
export class Work extends BaseEntity {
  // --------------------------------------------------------------------------
  //
  // Variables
  //
  // --------------------------------------------------------------------------

  // ----------------------------------
  // 레퍼런스 변수
  // ----------------------------------

  /** 설계 객체 */
  public design: Design;
  /** 구조 객체 */
  public struct: Struct;

  // ----------------------------------
  // 데이터 변수
  // ----------------------------------

  /** 형식적 명칭 */
  public get formalLabel(): string {
    return `${CONST.LB_WORK}: ${this.label}`;
  }

  //
  public set formalLabel(value: string) {}

  // --------------------------------------------------------------------------
  //
  // Remote Variables
  //
  // --------------------------------------------------------------------------

  // ----------------------------------
  // 레퍼런스 변수
  // ----------------------------------

  /** 중AC */
  @jsonArrayMember(Level)
  public levelAC: Level[];

  // ----------------------------------
  // 데이터 변수
  // ----------------------------------

  @jsonMember(Number)
  public _index: number; // 인덱스
  @jsonMember(Number)
  public _order: number; // 순서
  @jsonMember(Boolean)
  public _selected: boolean; // 선택
  @jsonMember(Boolean)
  public _selectedEstimate: boolean = true; // 견적 선택
  @jsonMember(Boolean)
  public _enabled: boolean; // 가용성 (사용 안함)
  @jsonMember(Boolean)
  public _visible: boolean; // 가시성
  @jsonMember(String)
  public _label: string; // 명칭
  @jsonMember(String)
  public _category: string; // 분류
  @jsonMember(String)
  public _buildNote: string; // 시공 방법

  /**
   * 인덱스 <br/>
   * 현재 작업의 고유번호이고, 순서도 중요함.
   */
  public get index(): number {
    return this._index;
  }

  //
  public set index(value: number) {
    this._index = value;
  }

  /**
   * 순서
   */
  public get order(): number {
    return this._order;
  }

  //
  public set order(value: number) {
    this._order = value;
  }

  /**
   * 선택
   */
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;
  }

  /**
   * 견적 선택
   */
  public get selectedEstimate(): boolean {
    return this._selectedEstimate;
  }

  //
  public set selectedEstimate(value: boolean) {
    this._selectedEstimate = value;
  }

  /**
   * 가용성
   */
  public get enabled(): boolean {
    return this._enabled;
  }

  //
  public set enabled(value: boolean) {
    this._enabled = value;
  }

  /**
   * 가시성 (사용 안하고 있음)
   */
  public get visible(): boolean {
    return this._visible;
  }

  //
  public set visible(value: boolean) {
    this._visible = value;
  }

  /**
   * 명칭
   */
  public get label(): string {
    return this._label;
  }

  //
  public set label(value: string) {
    this._label = value;
  }

  /**
   * 분류
   */
  public get category(): string {
    return this._category;
  }

  //
  public set category(value: string) {
    this._category = value;
  }

  /**
   * 시공 방법
   */
  public get buildNote(): string {
    return this._buildNote;
  }

  //
  public set buildNote(value: string) {
    this._buildNote = value;
  }

  // --------------------------------------------------------------------------
  //
  // Constructor
  //
  // --------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  // --------------------------------------------------------------------------
  //
  // Methods
  //
  // --------------------------------------------------------------------------

  // ----------------------------------
  // 객체 일반
  // ----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design, struct: Struct): void {
    this.design = design;
    this.struct = struct;

    // 중수
    let level: Level;
    for (level of this.levelAC) {
      level.setAssociation(design, struct, this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 중수
    let level: Level;
    for (level of this.levelAC) {
      level.setReferenceVariable();
    }
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param order: number 순서
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성 (사용 안함)
   * @param label: string 명칭
   * @param category: string
   * @param buildNote: string 시공 방법
   */
  public setDefaultData(
    index: number = 0,
    order: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    category: string = "",
    buildNote: string = "",
  ): void {
    this._index = index;
    this._order = order;
    this._selected = selected;
    this._enabled = enabled;
    this._visible = visible;
    this._label = label;
    this._category = category;
    this._buildNote = buildNote;
    this._selectedEstimate = true;

    // 추후 확인 - 벤로형 구조 추가 때문에 추가함
    // 중수
    let level: Level;
    for (level of this.levelAC) {
      level.setDefaultData();
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 중수
    let level: Level;
    for (level of this.levelAC) {
      level.setDefaultVariable();
    }
  }

  /**
   * 기본 모델 설정: 데이터베이스를 대신함
   */
  public setDefaultModel(): void {
    // 중수
    let level: Level;
    for (level of this.levelAC) {
      level.setDefaultModel();
    }
  }

  /**
   * 저장된 아이디값 세팅
   */
  public setSavedId(savedWork: Work): void {
    if (savedWork != null) {
      this.id = savedWork.id;

      if (this.levelAC != null && savedWork.levelAC != null) {
        for (let i: number = 0; i < this.levelAC.length; i++) {
          // 기존 배열길이만큼 할당하되 저장된 배열길이가 더 작을경우는 할당하지 않음
          if (i < savedWork.levelAC.length) {
            this.levelAC[i].setSavedId(savedWork.levelAC[i]);
          }
        }
      }
    }
  }

  /**
   * 최신 객체로 복원
   */
  public restoreLatestObject(design: Design, struct: Struct): void {
    // 중수
    let level: Level;
    for (level of this.levelAC) {
      level.restoreLatestObject(design, struct, this);
    }
  }

  // ----------------------------------
  // 하우스 설계
  // ----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  public algorithmBasic(): void {
    this.algorithm_selectedFromLevel();

    // 작업
    let level: Level;
    for (level of this.levelAC) {
      level.algorithmBasic();
    }
  }

  /**
   * 작업 선택 <- 중수 객체의 선택
   */
  public algorithm_selectedFromLevel(): void {
    let isUnselectedAll: boolean = true;

    // 유효한 중수 찾기 : 중수AC의 길이와 기본정보 중수 중에 더 작은 값
    let validLevel: number = this.levelAC.length;
    if (validLevel > this.design.basic.levelNumber) {
      validLevel = this.design.basic.levelNumber;
    }

    // 유효한 중수AC 뒤지기
    for (let i: number = 0; i < validLevel; i++) {
      if (this.levelAC[i].selected === true) {
        isUnselectedAll = false;
        break;
      }
    }

    if (isUnselectedAll === true) {
      this.selected = false;
    } else {
      this.selected = true;
    }
  }

  // --------------------------------------------------------------------------
  //
  // Internal Methods
  //
  // --------------------------------------------------------------------------
}
