import { jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Basic } from "vhows-design/src/object/design/basic/Basic";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";
import { BrandCompanyModel } from "vhows-design/src/object/item/brand/BrandCompanyModel";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { ProductCompanyModel } from "vhows-design/src/object/item/product/ProductCompanyModel";
import { SpecCompanyModel } from "vhows-design/src/object/item/spec/SpecCompanyModel";
import { UnitPriceModel } from "vhows-design/src/object/item/unitprice/UnitPriceModel";
import { OptionUnitPriceModel } from "vhows-design/src/object/item/unitprice/OptionUnitPriceModel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-02-02
 */
@jsonObject({
  knownTypes: [UnitPriceModel],
})
export class Item extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 주요 설계 객체

  /** 설계 객체 */
  public design: Design = null;
  /** 기본정보 객체 */
  public basic: Basic = null;
  /** 중수별 기본정보 객체 */
  public basicLevel: BasicLevel = null;
  /** 구조 객체 */
  public struct: Struct = null;
  /** 작업 객체 */
  public work: Work = null;
  /** 중 객체 */
  public level: Level = null;
  /** 위치 객체 */
  public position: Position = null;
  /** 파트 객체 */
  public part: Part = null;

  // 주요 품목 모델

  /** 품목 모델 */
  public itemModel: ItemModel = null;
  /** 분류 모델 */
  public categoryCompanyModel: CategoryCompanyModel = null;
  /** 상품 모델 */
  public productCompanyModel: ProductCompanyModel = null;
  /** 규격 모델 */
  public specCompanyModel: SpecCompanyModel = null;
  /** 상표 모델 */
  public brandCompanyModel: BrandCompanyModel = null;

  // 옵션 단가 모델

  /** 끈형태 단가 모델 */
  public stringTypeUnitPriceModel: OptionUnitPriceModel = null;
  /** 셔터 단가 모델 */
  public shutterUnitPriceModel: OptionUnitPriceModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 규격/상표의 조합을 수행할지 여부 <br/>
   * true: 조합 수행
   * false: 조합 수행 안함
   */
  public isCombine: boolean = true;
  /**
   * 초기화 단계인지 여부 <br/>
   * 초기화 단계가 마치면, false 해줘야함 <br/>
   * true: 초기화 단계  (알고리즘 수행 불가)
   * false: 초기화 단계 아님 (알고리즘 수행 가능)
   */
  public isInit: boolean = true;
  /**
   * 규격/상표 오류 여부 <br/>
   * true: 오류
   * false: 오류 없음
   */
  public isSpecError: boolean = false;

  // 하우스 설계 > 상표

  /** 회사 */
  public _brandCompany: string = undefined;
  /**
   * 회사
   */
  public get brandCompany(): string {
    return this._brandCompany;
  }

  //
  public set brandCompany(value: string) {
    if (this._brandCompany === value) return;

    this._brandCompany = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  // 검토 관련

  /** 공사명 (Groupig용도) */
  public constructionName: string = undefined;
  /** 작업명 (Groupig용도) */
  public workName: string = undefined;
  /** 중수명 (Groupig용도) */
  public levelName: string = undefined;
  /** 위치명 (Groupig용도) */
  public positionName: string = undefined;
  /** 파트명 (Groupig용도) */
  public partName: string = undefined;
  /** 부적합 여부 */
  public isUnfit: boolean = false;

  // 뷰에서 사용

  /** 임시 용도 */
  public purposeTemp: string = "";
  /** 임시 설계 수량 */
  public designQuantityTemp: number = 0;

  /** 단위기호 */
  public get unitSymbol(): string {
    if (this.unitPriceModel) return this.unitPriceModel.productCompanyModel.productModel.unitSymbol;
    return "";
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 단가 모델 */
  @jsonMember(UnitPriceModel)
  public unitPriceModel: UnitPriceModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 단위기호 */
  @jsonMember(String)
  public materialUnitSymbol: string = "";

  // 하우스 설계 > 일반

  /** 인덱스 */
  @jsonMember(Number)
  public _index: number = undefined;
  /** 선택 */
  @jsonMember(Boolean)
  public _selected: boolean = false;
  /** 견적 선택 - 부분 견적 기능을 위한 선택 */
  @jsonMember(Boolean)
  public _selectedEstimate: boolean = true;
  /** 가용성 */
  @jsonMember(Boolean)
  public _enabled: boolean = false;
  /** 가시성 */
  @jsonMember(Boolean)
  public _visible: boolean = false;
  /** 임의 추가 여부 - 사용자가 임의로 추가한 상품 */
  @jsonMember(Boolean)
  public _isCustomAdded: boolean = false;
  /** 용도 */
  @jsonMember(String)
  public _purpose: string = undefined;
  /** 설계 수량 */
  @jsonMember(Number)
  public _designQuantity: number = 0;
  /** 시공 방법 (사용안함) */
  @jsonMember(String)
  public _buildNote: string = undefined;

  // 하우스 설계 > 상품

  /** 상품 아이디 */
  @jsonMember(String)
  public _productId: string = undefined;
  /** 상품명 */
  @jsonMember(String)
  public _label: string = undefined;

  // 하우스 설계 > 규격 : []

  /** 규격 (값+단위) */
  @jsonMember(String)
  public _specs: string = undefined;
  /** 규격 리스트 (값+단위) : 드랍다운리스트용 */
  public _specsList: string = undefined;

  // 하우스 설계 > 상표 : [회사]

  /** 상표 (값+단위) */
  @jsonMember(String)
  public _brands: string = undefined;
  /** 상표 리스트 (값+단위) : 드랍다운리스트용 */
  public _brandsList: string = undefined;

  // 하우스 설계 > 비용형태

  /** 비용형태 */
  @jsonMember(String)
  public _costType: string = undefined;

  // 하우스 설계 > 기타

  /** 임의 매입가 */
  @jsonMember(Number)
  public _customPurchaseSupply: number = 0;
  /** 임의 단가 (공급가액) */
  @jsonMember(Number)
  public _customUnitSupply: number = 0;

  //----------------------------------
  // 하우스 설계 > 일반
  //----------------------------------

  /**
   * 인덱스 <br/>
   * 현재 아이템의 교유번호이고, 순서는 중요하지 않음.
   */
  public get index(): number {
    return this._index;
  }

  //
  public set index(value: number) {
    this._index = value;
  }

  /**
   * 선택
   */
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    this._selected = value;
  }

  /**
   * 선택 (수량이 0이상인 경우)
   */
  public get selectedByQuantity(): boolean {
    if (this.designQuantity > 0) {
      return this._selected;
    }
    return false;
  }

  /**
   * 견적 선택
   */
  public get selectedEstimate(): boolean {
    return this._selectedEstimate;
  }

  //
  public set selectedEstimate(value: boolean) {
    this._selectedEstimate = value;
  }

  /**
   * 가용성
   */
  public get enabled(): boolean {
    return this._enabled;
  }

  //
  public set enabled(value: boolean) {
    this._enabled = value;
  }

  /**
   * 가시성
   */
  public get visible(): boolean {
    return this._visible;
  }

  //
  public set visible(value: boolean) {
    this._visible = value;
  }

  /**
   * 임의추가여부 <br/>
   * - 사용자가 임의로 추가한 품목인지 아닌지
   */
  public get isCustomAdded(): boolean {
    return this._isCustomAdded;
  }

  //
  public set isCustomAdded(value: boolean) {
    this._isCustomAdded = value;
  }

  /**
   * 용도
   */
  public get purpose(): string {
    return this._purpose;
  }

  //
  public set purpose(value: string) {
    this._purpose = value;
  }

  /**
   * 설계 수량
   */
  public get designQuantity(): number {
    return this._designQuantity;
  }

  //
  public set designQuantity(value: number) {
    if (value < 0) {
      this._designQuantity = 0;
    } else {
      this._designQuantity = CommonUtil.roundUp(CommonUtil.fixFloat(value), 3);
    }
  }

  /**
   * 시공 방법 (사용안함)
   */
  public get buildNote(): string {
    return this._buildNote;
  }

  //
  public set buildNote(value: string) {
    this._buildNote = value;
  }

  //----------------------------------
  // 하우스 설계 > 상품
  //----------------------------------

  /**
   * 상품 아이디
   */
  public get productId(): string {
    return this._productId;
  }

  //
  public set productId(value: string) {
    this._productId = value;
  }

  /**
   * 상품명
   */
  public get label(): string {
    return this._label;
  }

  //
  public set label(value: string) {
    this._label = value;
  }

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 규격 (값+단위)
   */
  public get specs(): string {
    return this._specs;
  }

  //
  public set specs(value: string) {
    if (this._specs === value) return;

    this._specs = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeEachSpecBySpecs();
      this.makeSpecsList();
      this.makeSpecs(); // 한번 더 호출함!
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findUnitPriceModel();
      // 알고리즘
      this.algorithmSample();
    }
  }

  /**
   * 규격 리스트 (값+단위)
   */
  public get specsList(): string {
    return this._specsList;
  }

  //
  public set specsList(value: string) {
    if (this._specsList === value) return;

    this._specsList = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeEachSpecBySpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findUnitPriceModel();
      // 알고리즘
      this.algorithmSample_specsList();
    }
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  /**
   * 상표 (값+단위)
   */
  public get brands(): string {
    return this._brands;
  }

  //
  public set brands(value: string) {
    if (this._brands === value) return;

    this._brands = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeEachBrandByBrands();
      this.makeBrandsList();
      this.makeBrands(); // 한번 더 호출함!
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findUnitPriceModel();
      // 알고리즘
      this.algorithmSample();
    }
  }

  /**
   * 상표 리스트 (값+단위)
   */
  public get brandsList(): string {
    return this._brandsList;
  }

  //
  public set brandsList(value: string) {
    if (this._brandsList === value) return;

    this._brandsList = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeEachBrandByBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findUnitPriceModel();
      // 알고리즘
    }
  }

  //----------------------------------
  // 하우스 설계 > 비용형태
  //----------------------------------

  /**
   * 비용형태
   */
  public get costType(): string {
    return this._costType;
  }

  public set costType(value: string) {
    this._costType = value;
  }

  //----------------------------------
  // 하우스 설계 > 기타
  //----------------------------------

  /**
   * 임의 매입가
   */
  public get customPurchaseSupply(): number {
    return this._customPurchaseSupply;
  }

  public set customPurchaseSupply(value: number) {
    if (this._customPurchaseSupply === value) return;

    this._customPurchaseSupply = value;
  }

  /**
   * 임의 단가 (공급가액)
   */
  public get customUnitSupply(): number {
    return this._customUnitSupply;
  }

  //
  public set customUnitSupply(value: number) {
    if (this._customUnitSupply === value) return;

    this._customUnitSupply = value;

    // 알고리즘
    if (this.isCombine === true) {
      this.algorithm_customUnitSupply();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.id = NaN;
    this._index = NaN;
    this._selected = true;
    this._enabled = true;
    this._visible = true;
    this._isCustomAdded = false;
    this._purpose = "";
    this._designQuantity = 0;
    this._costType = "";
    this._buildNote = "";
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(
    design: Design,
    struct: Struct,
    work: Work,
    level: Level,
    position: Position,
    part: Part,
  ): void {
    this.itemModel = design.itemModel;

    this.design = design;
    this.struct = struct;
    this.work = work;
    this.level = level;
    this.position = position;
    this.part = part;
    if (design.basic != null) {
      this.basic = design.basic;
      this.basicLevel = <BasicLevel>this.basic.basicLevelAC[work.levelAC.indexOf(level)];
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {}

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index 인덱스
   * @param productId 상품아이디
   * @param label 상품명
   * @param purpose 용도
   * @param specs 규격
   * @param brands 상표
   * @param costType 비용형태
   */
  public setDefaultData(
    index: number = null,
    productId: string = "",
    label: string = "",
    purpose: string = "",
    specs: string = "",
    brands: string = CONST.ITEM_BRAND_VALUE_DEFAULT,
    costType: string = "",
  ): void {
    // 일반
    this._index = index;
    this._selected = true;
    this._enabled = true;
    this._visible = true;
    this._isCustomAdded = false;
    this._purpose = purpose;
    this._designQuantity = 0;
    this._buildNote = "";
    // 상품
    this._productId = productId;
    this._label = label;
    // 규격
    this._specs = specs;
    // 상표
    this._brands = brands;
    // 비용형태
    this._costType = costType;
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 규격 및 상표 나눠넣기
    this.isCombine = false;
    this.makeEachSpecBySpecs();
    this.makeSpecsList();
    this.makeEachBrandByBrands();
    this.makeBrandsList();
    this.isCombine = true;
    // 단가 모델 찾기
    // (기본 모델 설정에서 하지 않는 이유는, 데이터베이스를 대신하는 내용이 아니기 때문)
    this.categoryCompanyModel = this.getCategoryModel();
    if (this.categoryCompanyModel != null) {
      this.productCompanyModel = this.categoryCompanyModel.getProductModel(this.productId);
      if (this.productCompanyModel != null) {
        this.unitPriceModel = this.productCompanyModel.getUnitPriceModel(this.specsList, this.brandsList);
        if (this.unitPriceModel != null) {
          this.specCompanyModel = this.unitPriceModel.specCompanyModel;
          this.brandCompanyModel = this.unitPriceModel.brandCompanyModel;
        }
      }
    }
    // 초기화 단계 종료
    this.isInit = false;
  }

  /**
   * 기본 모델 설정: 데이터베이스를 대신함
   */
  public setDefaultModel(): void {}

  /**
   * 분류 모델 가져오기
   */
  public getCategoryModel(): CategoryCompanyModel {
    // 상속용
    return null;
  }

  /**
   * 깊은 복사 (객체 연관 및 규격/상표 완성, 모델 연관을 별도로 해줘야함)
   * @param origin 원본 객체
   */
  public deepCopy(origin: Item): void {
    // 변수
    this.id = origin.id;
    this.createDate = origin.createDate;
    this.dbUpdate = origin.dbUpdate;
    this._index = origin.index;
    this._selected = origin.selected;
    this._enabled = origin.enabled;
    this._visible = origin.visible;
    this._isCustomAdded = origin.isCustomAdded;
    this._purpose = origin.purpose;
    this._designQuantity = origin.designQuantity;
    this._buildNote = origin.buildNote;
    this._productId = origin.productId;
    this._label = origin.label;
    this._specs = origin.specs;
    this._brands = origin.brands;
    this._costType = origin.costType;
    this._customPurchaseSupply = origin.customPurchaseSupply;
    this._customUnitSupply = origin.customUnitSupply;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   * - 기본 정보 변경시 호출됨
   * - 해당 영역이 선택될때,
   */
  public algorithmBasic(): void {
    // 샘플
    this.algorithmSample();
    // 아이템
    this.algorithmItem();
  }

  /**
   * 샘플 알고리즘 <br/>
   * - setDefaultVariable() 시에는 실행되면 안되고, 기본정보에의해 알고리즘이 호출될때만 실행되어야함 <br/>
   * - (주의) 소스코드상에 상표가 잘못 입력되면, 상표를 새로 찾아서 넣기위해 실행되기도함
   */
  public algorithmSample(): void {
    // 상속용
    // trace('algorithmSample! setDefaultVariable()시에 실행되면 안됨!');
  }

  /**
   * 샘플 알고리즘: 규격 리스트 <br/>
   * - 기본정보 알고리즘에 영향을 받지 않기위해 algorithmSample()에서 분리함
   */
  public algorithmSample_specsList(): void {
    // 상속용
    // trace('algorithmSample! setDefaultVariable()시에 실행되면 안됨!');
  }

  /**
   * 아이템 알고리즘 <br/>
   * - setDefaultVariable() 시에는 실행되면 안되고, 기본정보에의해 알고리즘이 호출될때만 실행되어야함 <br/>
   */
  public algorithmItem(): void {
    // 상속용
    // trace('algorithmItem! setDefaultVariable()시에 실행되면 안됨!');
  }

  /**
   * 규격 알고리즘
   */
  public algorithmSpec(): void {
    // 상속용
  }

  /**
   * 아이템의 설계 가능성 설정하기 (선택 및 가시성)
   * @param bool
   */
  public setDesignable(bool: boolean): void {
    if (bool === true) {
      this.selected = true;
      this.visible = true;
    } else {
      this.selected = false;
      this.visible = false;
    }
  }

  /**
   * 선택된 설계 수량 가져오기
   */
  public getSelectedQuantity(): number {
    // 선택되지 않은 경우, 설계 수량은 무조건 0
    if (this.selected === false) {
      return 0;
    }
    return this._designQuantity;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 선택 및 가시성 체크
   * @param selected 사용 여부
   * @param visible 가시성
   */
  public checkSelected(selected: boolean, visible: boolean = true): void {
    this.selected = selected;
    this.visible = visible;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  /**
   * 단가 모델 검사
   */
  public checkUnitPriceModel(): void {
    if (this.unitPriceModel != null) {
      if (
        this.unitPriceModel.brandCompanyModel.productCompanyModel.categoryCompanyModel.itemModel.company.id !==
        this.design.company.id
      ) {
        // 단가 모델 다시찾기
        this.setReferenceVariable();
      }
    } else {
      // 단가 모델 다시찾기
      this.setReferenceVariable();
    }
  }

  // /**
  //  * 미가공 견적 수량 가져오기 <br/>
  //  * - 설계 수량의 정의가 상품마다 다름
  //  * - 소수점 첫째자리까지 표현함
  //  */
  // public getEstimateQuantityRaw(designQuantity: number): number {
  //   return CommonUtil.roundUp(designQuantity, 1);
  // }

  /**
   * 매출가 가져오기
   * - 단가 체계가 상품마다 다름
   * @param type 매출가 형태 (단가 또는 매입가)
   * @param otherCost 매출가 계산 시 활용할 비용 (ex.공과잡비의 설계 자재비)
   */
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 매출가 계산식
    let sellingSupply: number = defaultUnitSupply;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 각각의 규격 만들기 <br/>
   * 규격 -> 각각의 규격
   */
  protected makeEachSpecBySpecs(): void {
    // 상속용
  }

  /**
   * 각각 규격 만들기 <br/>
   * 규격 리스트 -> 각각의 규격
   */
  protected makeEachSpecBySpecsList(): void {
    // 상속용
  }

  /**
   * 규격 리스트 만들기 <br/>
   * 각각의 규격 -> 규격 리스트
   */
  protected makeSpecsList(): void {
    this.specsList = this._specs;
  }

  /**
   * 규격 만들기 <br/>
   * 각각의 규격 -> 규격
   */
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  /**
   * 각각 상표 만들기 <br/>
   * 상표 -> 각각의 상표
   */
  protected makeEachBrandByBrands(): void {
    this.brandCompany = this._brands;
  }

  /**
   * 각각 상표 만들기 <br/>
   * 상표 리스트 -> 각각의 상표
   */
  protected makeEachBrandByBrandsList(): void {
    this.brandCompany = this._brandsList;
  }

  /**
   * 상표 리스트 만들기 <br/>
   * 각각의 상표 -> 상표 리스트
   */
  protected makeBrandsList(): void {
    this.brandsList = this._brandCompany;
  }

  /**
   * 상표 만들기 <br/>
   * 각각의 상표 -> 상표
   */
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }

  //----------------------------------
  // 하우스 설계 > 기타
  //----------------------------------

  /**
   * 알고리즘: 임의 단가
   */
  protected algorithm_customUnitSupply(): void {
    // 상속용
  }

  //----------------------------------
  // 품목 모델
  //----------------------------------

  /**
   * 단가 모델 찾기<br/>
   * 규격이나 상표가 변경될때, 규격 모델/상표 모델/단가 모델을 찾아줌
   */
  public findUnitPriceModel(): void {
    if (this.productCompanyModel != null) {
      this.unitPriceModel = this.productCompanyModel.getUnitPriceModel(this.specsList, this.brandsList);
      if (this.unitPriceModel != null) {
        this.specCompanyModel = this.unitPriceModel.specCompanyModel;
        this.brandCompanyModel = this.unitPriceModel.brandCompanyModel;
      } else {
        this.specCompanyModel = this.productCompanyModel.getSpecModel(this.specsList);
        // 단가모델을 못 찾은 경우, 변경한 규격의 첫번째 상표를 강제 선택해줌
        if (this.specCompanyModel != null) {
          this.brandCompanyModel = this.productCompanyModel.getFirstBrandModel(this.specsList);
          if (this.brandCompanyModel != null) {
            // 하드코딩
            // 비닐과 분수호스는 brands에 숨은 값을 찾아줘야함
            if (this.productId === CONST.ITEM_ID_VINYL) {
              this.brands =
                this.brandCompanyModel.brandModel.label +
                CONST.DELIMITER_BRAND +
                this.brandCompanyModel.brandModel.num1;
            } else if (this.productId === CONST.ITEM_ID_FOUNTAIN_HOSE) {
              this.brands =
                this.brandCompanyModel.brandModel.label +
                CONST.DELIMITER_BRAND +
                this.brandCompanyModel.brandModel.num1 +
                CONST.UNIT_METER;
            } else {
              this.brands = this.brandCompanyModel.brandModel.label;
            }
            this.brandsList = this.brandCompanyModel.brandModel.label;
            this.unitPriceModel = this.productCompanyModel.getUnitPriceModel2(this.specCompanyModel, this.brandsList);
          } else {
            // 상표/단가 모델 문제!
            this.brandCompanyModel = null;
            this.unitPriceModel = null;
          }
        } else {
          // 규격/상표/단가 모델 문제!
          this.specCompanyModel = null;
          this.brandCompanyModel = null;
          this.unitPriceModel = null;
        }
      }
    }
  }
}
