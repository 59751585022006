import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { BandstringWork } from "vhows-design/src/object/design/cover/bandstring/BandstringWork";
import { CoverWork } from "vhows-design/src/object/design/cover/cover/CoverWork";
import { FixingWork } from "vhows-design/src/object/design/cover/fixing/FixingWork";
import { EndpieceWork } from "vhows-design/src/object/design/frame/endpiece/EndpieceWork";
import { TrunkWork } from "vhows-design/src/object/design/frame/trunk/TrunkWork";
import { BuildingWork } from "vhows-design/src/object/design/building/BuildingWork";
import { MaterialWork } from "vhows-design/src/object/design/other/material/MaterialWork";
import { PartitionWork } from "vhows-design/src/object/design/other/partition/PartitionWork";
import { RackWork } from "vhows-design/src/object/design/other/rack/RackWork";
import { RailWork } from "vhows-design/src/object/design/other/rail/RailWork";
import { WindowWork } from "vhows-design/src/object/design/other/window/WindowWork";
import { SkyCoverWork } from "vhows-design/src/object/design/skylight/cover/SkyCoverWork";
import { SkyFrameWork } from "vhows-design/src/object/design/skylight/frame/SkyFrameWork";
import { SkySwitchWork } from "vhows-design/src/object/design/skylight/switches/SkySwitchWork";
import { ControllerWork } from "vhows-design/src/object/design/switches/controller/ControllerWork";
import { ScreennetWork } from "vhows-design/src/object/design/cover/screennet/ScreennetWork";
import { SkirtWork } from "vhows-design/src/object/design/cover/skirt/SkirtWork";
import { SwitcherWork } from "vhows-design/src/object/design/switches/switcher/SwitcherWork";
import { WindbreakWork } from "vhows-design/src/object/design/cover/windbreak/WindbreakWork";
import { VentExhaustWork } from "vhows-design/src/object/design/vent/exhaust/VentExhaustWork";
import { VentInsideWork } from "vhows-design/src/object/design/vent/inside/VentInsideWork";
import { MainpipeWork } from "vhows-design/src/object/design/watering/mainpipe/MainpipeWork";
import { WateringWork } from "vhows-design/src/object/design/watering/watering/WateringWork";
import { TemHeatingWork } from "vhows-design/src/object/design/temperature/heating/TemHeatingWork";
import { TemCoolingWork } from "vhows-design/src/object/design/temperature/cooling/TemCoolingWork";
import { HydSystemWork } from "vhows-design/src/object/design/hydroponics/system/HydSystemWork";
import { EnvironmentWork } from "vhows-design/src/object/design/smartfarm/EnvironmentWork";
import { HydCultivationWork } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationWork";
import { FoundationWork } from "vhows-design/src/object/design/foundation/FoundationWork";
import { GutterWork } from "vhows-design/src/object/design/frame/gutter/GutterWork";
import { WorkroomWork } from "vhows-design/src/object/design/other/workroom/WorkroomWork";
import { Co2Work } from "vhows-design/src/object/design/other/co2/Co2Work";
import { AutosprayWork } from "vhows-design/src/object/design/other/autospray/AutosprayWork";
import { FacilityWork } from "vhows-design/src/object/design/other/facility/FacilityWork";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-01-07
 */
@jsonObject
export class StructSG extends Struct {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 기초 공사
    this.foundationWork = new FoundationWork();
    // 골조 공사
    this.trunkWork = new TrunkWork();
    this.endpieceWork = new EndpieceWork();
    this.gutterWork = new GutterWork();
    // 개폐 공사
    this.switcherWork = new SwitcherWork();
    this.controllerWork = new ControllerWork();
    // 피복 공사
    this.coverWork = new CoverWork();
    this.fixingWork = new FixingWork();
    this.skirtWork = new SkirtWork();
    this.windbreakWork = new WindbreakWork();
    this.screennetWork = new ScreennetWork();
    this.bandstringWork = new BandstringWork();
    // 천창 공사
    this.skyFrameWork = new SkyFrameWork();
    this.skySwitchWork = new SkySwitchWork();
    this.skyCoverWork = new SkyCoverWork();
    // 추가 건축 공사
    this.partitionWork = new PartitionWork();
    this.windowWork = new WindowWork();
    this.workroomWork = new WorkroomWork();
    this.materialWork = new MaterialWork();
    // 환기 공사
    this.ventExhaustWork = new VentExhaustWork();
    this.ventInsideWork = new VentInsideWork();
    // 관수 공사
    this.mainpipeWork = new MainpipeWork();
    this.wateringWork = new WateringWork();
    // 양액 공사
    this.hydSystemWork = new HydSystemWork();
    this.hydCultivationWork = new HydCultivationWork();
    // 냉난방 공사
    this.temHeatingWork = new TemHeatingWork();
    this.temCoolingWork = new TemCoolingWork();
    // 스마트팜 공사
    this.environmentWork = new EnvironmentWork();
    // 추가 기계/설비 공사
    this.rackWork = new RackWork();
    this.railWork = new RailWork();
    this.co2Work = new Co2Work();
    this.autosprayWork = new AutosprayWork();
    this.facilityWork = new FacilityWork();
    // 통합 시공
    this.buildingWork = new BuildingWork();

    // 최종 workAC
    this.workAC = [
      this.foundationWork,
      this.trunkWork,
      this.endpieceWork,
      this.gutterWork,
      this.switcherWork,
      this.controllerWork,
      this.coverWork,
      this.fixingWork,
      this.skirtWork,
      this.windbreakWork,
      this.screennetWork,
      this.bandstringWork,
      this.skyFrameWork,
      this.skySwitchWork,
      this.skyCoverWork,
      this.partitionWork,
      this.windowWork,
      this.workroomWork,
      this.materialWork,
      this.ventExhaustWork,
      this.ventInsideWork,
      this.mainpipeWork,
      this.wateringWork,
      this.hydSystemWork,
      this.hydCultivationWork,
      this.temHeatingWork,
      this.temCoolingWork,
      this.environmentWork,
      this.rackWork,
      this.railWork,
      this.co2Work,
      this.autosprayWork,
      this.facilityWork,
      this.buildingWork,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.foundationWork = <FoundationWork>this.workAC[CONST.INDEX_SG_FOUNDATION_WORK];
    this.trunkWork = <TrunkWork>this.workAC[CONST.INDEX_SG_TRUNK_WORK];
    this.endpieceWork = <EndpieceWork>this.workAC[CONST.INDEX_SG_ENDPIECE_WORK];
    this.gutterWork = <GutterWork>this.workAC[CONST.INDEX_SG_GUTTER_WORK];
    this.switcherWork = <SwitcherWork>this.workAC[CONST.INDEX_SG_SWITCHER_WORK];
    this.controllerWork = <ControllerWork>this.workAC[CONST.INDEX_SG_CONTROLLER_WORK];
    this.coverWork = <CoverWork>this.workAC[CONST.INDEX_SG_COVER_WORK];
    this.fixingWork = <FixingWork>this.workAC[CONST.INDEX_SG_FIXING_WORK];
    this.skirtWork = <SkirtWork>this.workAC[CONST.INDEX_SG_SKIRT_WORK];
    this.windbreakWork = <WindbreakWork>this.workAC[CONST.INDEX_SG_WINDBREAK_WORK];
    this.screennetWork = <ScreennetWork>this.workAC[CONST.INDEX_SG_SCREENNET_WORK];
    this.bandstringWork = <BandstringWork>this.workAC[CONST.INDEX_SG_BANDSTRING_WORK];
    this.skyFrameWork = <SkyFrameWork>this.workAC[CONST.INDEX_SG_SKYLIGHT_FRAME_WORK];
    this.skySwitchWork = <SkySwitchWork>this.workAC[CONST.INDEX_SG_SKYLIGHT_SWITCH_WORK];
    this.skyCoverWork = <SkyCoverWork>this.workAC[CONST.INDEX_SG_SKYLIGHT_COVER_WORK];
    this.partitionWork = <PartitionWork>this.workAC[CONST.INDEX_SG_PARTITION_WORK];
    this.windowWork = <WindowWork>this.workAC[CONST.INDEX_SG_WINDOW_WORK];
    this.workroomWork = <WorkroomWork>this.workAC[CONST.INDEX_SG_WORKROOM_WORK];
    this.materialWork = <MaterialWork>this.workAC[CONST.INDEX_SG_OTHER_MATERIAL_WORK];
    this.ventExhaustWork = <VentExhaustWork>this.workAC[CONST.INDEX_SG_VENT_EXHAUST_WORK];
    this.ventInsideWork = <VentInsideWork>this.workAC[CONST.INDEX_SG_VENT_INSIDE_WORK];
    this.mainpipeWork = <MainpipeWork>this.workAC[CONST.INDEX_SG_WATERING_MAINPIPE_WORK];
    this.wateringWork = <WateringWork>this.workAC[CONST.INDEX_SG_WATERING_WATERING_WORK];
    this.hydSystemWork = <HydSystemWork>this.workAC[CONST.INDEX_SG_HYD_SYSTEM_WORK];
    this.hydCultivationWork = <HydCultivationWork>this.workAC[CONST.INDEX_SG_HYD_CULTIVATION_WORK];
    this.temHeatingWork = <TemHeatingWork>this.workAC[CONST.INDEX_SG_TEM_HEATING_WORK];
    this.temCoolingWork = <TemCoolingWork>this.workAC[CONST.INDEX_SG_TEM_COOLING_WORK];
    this.environmentWork = <EnvironmentWork>this.workAC[CONST.INDEX_SG_ENVIRONMENT_WORK];
    this.rackWork = <RackWork>this.workAC[CONST.INDEX_SG_RACK_WORK];
    this.railWork = <RailWork>this.workAC[CONST.INDEX_SG_RAIL_WORK];
    this.co2Work = <Co2Work>this.workAC[CONST.INDEX_SG_CO2_WORK];
    this.autosprayWork = <AutosprayWork>this.workAC[CONST.INDEX_SG_AUTOSPRAY_WORK];
    this.facilityWork = <FacilityWork>this.workAC[CONST.INDEX_SG_OTHER_FACILITIES_WORK];
    this.buildingWork = <BuildingWork>this.workAC[CONST.INDEX_SG_BUILDING_WORK];

    super.setReferenceVariable();
  }

  // @override
  public setDefaultData(): void {
    super.setDefaultData();

    // 기초 공사
    this.foundationWork.setDefaultData(
      CONST.INDEX_SG_FOUNDATION_WORK,
      CONST.ORDER_FOUNDATION_WORK,
      false,
      true,
      true,
      CONST.LB_FOUNDATION_WORK,
      CONST.LB_FOUNDATION_CONSTRUCTION,
    );
    // 골조 공사
    this.trunkWork.setDefaultData(
      CONST.INDEX_SG_TRUNK_WORK,
      CONST.ORDER_TRUNK_WORK,
      true,
      true,
      true,
      CONST.LB_TRUNK_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    this.endpieceWork.setDefaultData(
      CONST.INDEX_SG_ENDPIECE_WORK,
      CONST.ORDER_ENDPIECE_WORK,
      true,
      true,
      true,
      CONST.LB_ENDPIECE_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    this.gutterWork.setDefaultData(
      CONST.INDEX_SG_GUTTER_WORK,
      CONST.ORDER_GUTTER_WORK,
      false,
      true,
      true,
      CONST.LB_GUTTER_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    // 개폐 공사
    this.switcherWork.setDefaultData(
      CONST.INDEX_SG_SWITCHER_WORK,
      CONST.ORDER_SWITCHER_WORK,
      true,
      true,
      true,
      CONST.LB_SWITCHER_WORK,
      CONST.LB_SWITCH_CONSTRUCTION,
    );
    this.controllerWork.setDefaultData(
      CONST.INDEX_SG_CONTROLLER_WORK,
      CONST.ORDER_CONTROLLER_WORK,
      false,
      false,
      true,
      CONST.LB_CONTROLLER_WORK,
      CONST.LB_SWITCH_CONSTRUCTION,
    );
    // 피복 공사
    this.coverWork.setDefaultData(
      CONST.INDEX_SG_COVER_WORK,
      CONST.ORDER_COVER_WORK,
      true,
      true,
      true,
      CONST.LB_COVER_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.fixingWork.setDefaultData(
      CONST.INDEX_SG_FIXING_WORK,
      CONST.ORDER_FIXING_WORK,
      true,
      true,
      true,
      CONST.LB_FIXING_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.skirtWork.setDefaultData(
      CONST.INDEX_SG_SKIRT_WORK,
      CONST.ORDER_SKIRT_WORK,
      true,
      true,
      true,
      CONST.LB_SKIRT_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.windbreakWork.setDefaultData(
      CONST.INDEX_SG_WINDBREAK_WORK,
      CONST.ORDER_WINDBREAK_WORK,
      true,
      true,
      true,
      CONST.LB_WINDBREAK_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.screennetWork.setDefaultData(
      CONST.INDEX_SG_SCREENNET_WORK,
      CONST.ORDER_SCREENNET_WORK,
      false,
      false,
      true,
      CONST.LB_SCREENNET_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.bandstringWork.setDefaultData(
      CONST.INDEX_SG_BANDSTRING_WORK,
      CONST.ORDER_BANDSTRING_WORK,
      true,
      true,
      true,
      CONST.LB_BANDSTRING_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    // 천창 공사
    this.skyFrameWork.setDefaultData(
      CONST.INDEX_SG_SKYLIGHT_FRAME_WORK,
      CONST.ORDER_SKYLIGHT_FRAME_WORK,
      false,
      false,
      true,
      CONST.LB_SKYLIGHT_FRAME_WORK,
      CONST.LB_SKYLIGHT_CONSTRUCTION,
    );
    this.skySwitchWork.setDefaultData(
      CONST.INDEX_SG_SKYLIGHT_SWITCH_WORK,
      CONST.ORDER_SKYLIGHT_SWITCH_WORK,
      false,
      false,
      true,
      CONST.LB_SKYLIGHT_SWITCH_WORK,
      CONST.LB_SKYLIGHT_CONSTRUCTION,
    );
    this.skyCoverWork.setDefaultData(
      CONST.INDEX_SG_SKYLIGHT_COVER_WORK,
      CONST.ORDER_SKYLIGHT_COVER_WORK,
      false,
      false,
      true,
      CONST.LB_SKYLIGHT_COVER_WORK,
      CONST.LB_SKYLIGHT_CONSTRUCTION,
    );
    // 추가 건축 공사
    this.partitionWork.setDefaultData(
      CONST.INDEX_SG_PARTITION_WORK,
      CONST.ORDER_PARTITION_WORK,
      false,
      false,
      true,
      CONST.LB_PARTITION_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    this.windowWork.setDefaultData(
      CONST.INDEX_SG_WINDOW_WORK,
      CONST.ORDER_WINDOW_WORK,
      false,
      false,
      true,
      CONST.LB_WINDOW_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    this.workroomWork.setDefaultData(
      CONST.INDEX_SG_WORKROOM_WORK,
      CONST.ORDER_WORKROMM_WORK,
      false,
      false,
      true,
      CONST.LB_WORKROOM_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    this.materialWork.setDefaultData(
      CONST.INDEX_SG_OTHER_MATERIAL_WORK,
      CONST.ORDER_OTHER_MATERIAL_WORK,
      false,
      false,
      true,
      CONST.LB_OTHER_MATERIAL_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    // 환기 공사
    this.ventExhaustWork.setDefaultData(
      CONST.INDEX_SG_VENT_EXHAUST_WORK,
      CONST.ORDER_VENT_EXHAUST_WORK,
      false,
      false,
      true,
      CONST.LB_VENT_EXHAUST_WORK,
      CONST.LB_VENT_CONSTRUCTION,
    );
    this.ventInsideWork.setDefaultData(
      CONST.INDEX_SG_VENT_INSIDE_WORK,
      CONST.ORDER_VENT_INSIDE_WORK,
      false,
      false,
      true,
      CONST.LB_VENT_INSIDE_WORK,
      CONST.LB_VENT_CONSTRUCTION,
    );
    // 관수 공사
    this.mainpipeWork.setDefaultData(
      CONST.INDEX_SG_WATERING_MAINPIPE_WORK,
      CONST.ORDER_WATERING_MAINPIPE_WORK,
      false,
      false,
      true,
      CONST.LB_WATERING_MAINPIPE_WORK,
      CONST.LB_WATERING_CONSTRUCTION,
    );
    this.wateringWork.setDefaultData(
      CONST.INDEX_SG_WATERING_WATERING_WORK,
      CONST.ORDER_WATERING_WATERING_WORK,
      false,
      false,
      true,
      CONST.LB_WATERING_WATERING_WORK,
      CONST.LB_WATERING_CONSTRUCTION,
    );
    // 양액 공사
    this.hydSystemWork.setDefaultData(
      CONST.INDEX_SG_HYD_SYSTEM_WORK,
      CONST.ORDER_HYD_SYSTEM_WORK,
      false,
      false,
      true,
      CONST.LB_HYD_SYSTEM_WORK,
      CONST.LB_HYDROPONICS_CONSTRUCTION,
    );
    this.hydCultivationWork.setDefaultData(
      CONST.INDEX_SG_HYD_CULTIVATION_WORK,
      CONST.ORDER_HYD_CULTIVATION_WORK,
      false,
      false,
      true,
      CONST.LB_HYD_CULTIVATION_WORK,
      CONST.LB_HYDROPONICS_CONSTRUCTION,
    );
    // 냉난방 공사
    this.temHeatingWork.setDefaultData(
      CONST.INDEX_SG_TEM_HEATING_WORK,
      CONST.ORDER_TEM_HEATING_WORK,
      false,
      false,
      true,
      CONST.LB_TEM_HEATING_WORK,
      CONST.LB_TEMPERATURE_CONSTRUCTION,
    );
    this.temCoolingWork.setDefaultData(
      CONST.INDEX_SG_TEM_COOLING_WORK,
      CONST.ORDER_TEM_COOLING_WORK,
      false,
      false,
      true,
      CONST.LB_TEM_COOLING_WORK,
      CONST.LB_TEMPERATURE_CONSTRUCTION,
    );
    // 스마트팜 공사
    this.environmentWork.setDefaultData(
      CONST.INDEX_SG_ENVIRONMENT_WORK,
      CONST.ORDER_ENVIRONMENT_WORK,
      false,
      false,
      true,
      CONST.LB_ENVIRONMENT_WORK,
      CONST.LB_SMARTFARM_CONSTRUCTION,
    );
    // 추가 기계/설비 공사
    this.rackWork.setDefaultData(
      CONST.INDEX_SG_RACK_WORK,
      CONST.ORDER_RACK_WORK,
      false,
      false,
      true,
      CONST.LB_RACK_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    this.railWork.setDefaultData(
      CONST.INDEX_SG_RAIL_WORK,
      CONST.ORDER_RAIL_WORK,
      false,
      false,
      true,
      CONST.LB_RAIL_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    this.co2Work.setDefaultData(
      CONST.INDEX_SG_CO2_WORK,
      CONST.ORDER_CO2_WORK,
      false,
      false,
      true,
      CONST.LB_CO2_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    this.autosprayWork.setDefaultData(
      CONST.INDEX_SG_AUTOSPRAY_WORK,
      CONST.ORDER_AUTOSPRAY_WORK,
      false,
      false,
      true,
      CONST.LB_AUTOSPRAY_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    this.facilityWork.setDefaultData(
      CONST.INDEX_SG_OTHER_FACILITIES_WORK,
      CONST.ORDER_OTHER_FACILITIES_WORK,
      false,
      false,
      true,
      CONST.LB_OTHER_FACILITIES_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    // 통합 시공
    this.buildingWork.setDefaultData(
      CONST.INDEX_SG_BUILDING_WORK,
      CONST.ORDER_BUILDING_WORK,
      false,
      false,
      true,
      CONST.LB_BUILDING_WORK,
      CONST.LB_BUILDING_CONSTRUCTION,
    );
  }

  // @override
  public restoreLatestObject(design: Design): void {
    // 작업 순서대로 객체 재구성 - 추가적인 공사만 해당함 (골조/개폐/피복 공사는 해당 없음)
    // workAC에 특정 작업 빠져있어도 되지만, 최소한 index 순서대로 정렬되어 있어야함
    for (let i: number = 0; i <= CONST.INDEX_SG_LAST_WORK; i++) {
      if (i === CONST.INDEX_SG_FOUNDATION_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_FOUNDATION_WORK)) {
          const foundationWork: FoundationWork = new FoundationWork();
          foundationWork.setAssociation(design, this);
          foundationWork.setDefaultData(
            CONST.INDEX_SG_FOUNDATION_WORK,
            CONST.ORDER_FOUNDATION_WORK,
            false,
            true,
            true,
            CONST.LB_FOUNDATION_WORK,
            CONST.LB_FOUNDATION_CONSTRUCTION,
          );
          foundationWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, foundationWork);
        }
      } else if (i === CONST.INDEX_SG_GUTTER_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_GUTTER_WORK)) {
          const gutterWork: GutterWork = new GutterWork();
          gutterWork.setAssociation(design, this);
          gutterWork.setDefaultData(
            CONST.INDEX_SG_GUTTER_WORK,
            CONST.ORDER_GUTTER_WORK,
            false,
            true,
            true,
            CONST.LB_GUTTER_WORK,
            CONST.LB_FRAME_CONSTRUCTION,
          );
          gutterWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, gutterWork);
        }
      } else if (i === CONST.INDEX_SG_SKYLIGHT_FRAME_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_SKYLIGHT_FRAME_WORK)
        ) {
          const skyFrameWork: SkyFrameWork = new SkyFrameWork();
          skyFrameWork.setAssociation(design, this);
          skyFrameWork.setDefaultData(
            CONST.INDEX_SG_SKYLIGHT_FRAME_WORK,
            CONST.ORDER_SKYLIGHT_FRAME_WORK,
            false,
            false,
            true,
            CONST.LB_SKYLIGHT_FRAME_WORK,
            CONST.LB_SKYLIGHT_CONSTRUCTION,
          );
          skyFrameWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, skyFrameWork);
        }
      } else if (i === CONST.INDEX_SG_SKYLIGHT_SWITCH_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_SKYLIGHT_SWITCH_WORK)
        ) {
          const skySwitchWork: SkySwitchWork = new SkySwitchWork();
          skySwitchWork.setAssociation(design, this);
          skySwitchWork.setDefaultData(
            CONST.INDEX_SG_SKYLIGHT_SWITCH_WORK,
            CONST.ORDER_SKYLIGHT_SWITCH_WORK,
            false,
            false,
            true,
            CONST.LB_SKYLIGHT_SWITCH_WORK,
            CONST.LB_SKYLIGHT_CONSTRUCTION,
          );
          skySwitchWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, skySwitchWork);
        }
      } else if (i === CONST.INDEX_SG_SKYLIGHT_COVER_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_SKYLIGHT_COVER_WORK)
        ) {
          const skyCoverWork: SkyCoverWork = new SkyCoverWork();
          skyCoverWork.setAssociation(design, this);
          skyCoverWork.setDefaultData(
            CONST.INDEX_SG_SKYLIGHT_COVER_WORK,
            CONST.ORDER_SKYLIGHT_COVER_WORK,
            false,
            false,
            true,
            CONST.LB_SKYLIGHT_COVER_WORK,
            CONST.LB_SKYLIGHT_CONSTRUCTION,
          );
          skyCoverWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, skyCoverWork);
        }
      } else if (i === CONST.INDEX_SG_PARTITION_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_PARTITION_WORK)) {
          const partitionWork: PartitionWork = new PartitionWork();
          partitionWork.setAssociation(design, this);
          partitionWork.setDefaultData(
            CONST.INDEX_SG_PARTITION_WORK,
            CONST.ORDER_PARTITION_WORK,
            false,
            false,
            true,
            CONST.LB_PARTITION_WORK,
            CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
          );
          partitionWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, partitionWork);
        }
      } else if (i === CONST.INDEX_SG_WINDOW_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_WINDOW_WORK)) {
          const windowWork: WindowWork = new WindowWork();
          windowWork.setAssociation(design, this);
          windowWork.setDefaultData(
            CONST.INDEX_SG_WINDOW_WORK,
            CONST.ORDER_WINDOW_WORK,
            false,
            false,
            true,
            CONST.LB_WINDOW_WORK,
            CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
          );
          windowWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, windowWork);
        }
      } else if (i === CONST.INDEX_SG_WORKROOM_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_WORKROOM_WORK)) {
          const workroomWork: WorkroomWork = new WorkroomWork();
          workroomWork.setAssociation(design, this);
          workroomWork.setDefaultData(
            CONST.INDEX_SG_WORKROOM_WORK,
            CONST.ORDER_WORKROMM_WORK,
            false,
            false,
            true,
            CONST.LB_WORKROOM_WORK,
            CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
          );
          workroomWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, workroomWork);
        }
      } else if (i === CONST.INDEX_SG_OTHER_MATERIAL_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_OTHER_MATERIAL_WORK)
        ) {
          const materialWork: MaterialWork = new MaterialWork();
          materialWork.setAssociation(design, this);
          materialWork.setDefaultData(
            CONST.INDEX_SG_OTHER_MATERIAL_WORK,
            CONST.ORDER_OTHER_MATERIAL_WORK,
            false,
            false,
            true,
            CONST.LB_OTHER_MATERIAL_WORK,
            CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
          );
          materialWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, materialWork);
        }
      } else if (i === CONST.INDEX_SG_VENT_EXHAUST_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_VENT_EXHAUST_WORK)
        ) {
          const ventExhaustWork: VentExhaustWork = new VentExhaustWork();
          ventExhaustWork.setAssociation(design, this);
          ventExhaustWork.setDefaultData(
            CONST.INDEX_SG_VENT_EXHAUST_WORK,
            CONST.ORDER_VENT_EXHAUST_WORK,
            false,
            false,
            true,
            CONST.LB_VENT_EXHAUST_WORK,
            CONST.LB_VENT_CONSTRUCTION,
          );
          ventExhaustWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, ventExhaustWork);
        }
      } else if (i === CONST.INDEX_SG_VENT_INSIDE_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_VENT_INSIDE_WORK)
        ) {
          const ventInsideWork: VentInsideWork = new VentInsideWork();
          ventInsideWork.setAssociation(design, this);
          ventInsideWork.setDefaultData(
            CONST.INDEX_SG_VENT_INSIDE_WORK,
            CONST.ORDER_VENT_INSIDE_WORK,
            false,
            false,
            true,
            CONST.LB_VENT_INSIDE_WORK,
            CONST.LB_VENT_CONSTRUCTION,
          );
          ventInsideWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, ventInsideWork);
        }
      } else if (i === CONST.INDEX_SG_WATERING_MAINPIPE_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_WATERING_MAINPIPE_WORK)
        ) {
          const mainpipeWork: MainpipeWork = new MainpipeWork();
          mainpipeWork.setAssociation(design, this);
          mainpipeWork.setDefaultData(
            CONST.INDEX_SG_WATERING_MAINPIPE_WORK,
            CONST.ORDER_WATERING_MAINPIPE_WORK,
            false,
            false,
            true,
            CONST.LB_WATERING_MAINPIPE_WORK,
            CONST.LB_WATERING_CONSTRUCTION,
          );
          mainpipeWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, mainpipeWork);
        }
      } else if (i === CONST.INDEX_SG_WATERING_WATERING_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_WATERING_WATERING_WORK)
        ) {
          const wateringWork: WateringWork = new WateringWork();
          wateringWork.setAssociation(design, this);
          wateringWork.setDefaultData(
            CONST.INDEX_SG_WATERING_WATERING_WORK,
            CONST.ORDER_WATERING_WATERING_WORK,
            false,
            false,
            true,
            CONST.LB_WATERING_WATERING_WORK,
            CONST.LB_WATERING_CONSTRUCTION,
          );
          wateringWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, wateringWork);
        }
      } else if (i === CONST.INDEX_SG_HYD_SYSTEM_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_HYD_SYSTEM_WORK)) {
          const hydSystemWork: HydSystemWork = new HydSystemWork();
          hydSystemWork.setAssociation(design, this);
          hydSystemWork.setDefaultData(
            CONST.INDEX_SG_HYD_SYSTEM_WORK,
            CONST.ORDER_HYD_SYSTEM_WORK,
            false,
            false,
            true,
            CONST.LB_HYD_SYSTEM_WORK,
            CONST.LB_HYDROPONICS_CONSTRUCTION,
          );
          hydSystemWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, hydSystemWork);
        }
      } else if (i === CONST.INDEX_SG_HYD_CULTIVATION_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_HYD_CULTIVATION_WORK)
        ) {
          const hydCultivationWork: HydCultivationWork = new HydCultivationWork();
          hydCultivationWork.setAssociation(design, this);
          hydCultivationWork.setDefaultData(
            CONST.INDEX_SG_HYD_CULTIVATION_WORK,
            CONST.ORDER_HYD_CULTIVATION_WORK,
            false,
            false,
            true,
            CONST.LB_HYD_CULTIVATION_WORK,
            CONST.LB_HYDROPONICS_CONSTRUCTION,
          );
          hydCultivationWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, hydCultivationWork);
        }
      } else if (i === CONST.INDEX_SG_TEM_HEATING_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_VENT_INSIDE_WORK)
        ) {
          const temHeatingWork: TemHeatingWork = new TemHeatingWork();
          temHeatingWork.setAssociation(design, this);
          temHeatingWork.setDefaultData(
            CONST.INDEX_SG_TEM_HEATING_WORK,
            CONST.ORDER_TEM_HEATING_WORK,
            false,
            false,
            true,
            CONST.LB_TEM_HEATING_WORK,
            CONST.LB_TEMPERATURE_CONSTRUCTION,
          );
          temHeatingWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, temHeatingWork);
        }
      } else if (i === CONST.INDEX_SG_TEM_COOLING_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_TEM_COOLING_WORK)
        ) {
          const temCoolingWork: TemCoolingWork = new TemCoolingWork();
          temCoolingWork.setAssociation(design, this);
          temCoolingWork.setDefaultData(
            CONST.INDEX_SG_TEM_COOLING_WORK,
            CONST.ORDER_TEM_COOLING_WORK,
            false,
            false,
            true,
            CONST.LB_TEM_COOLING_WORK,
            CONST.LB_TEMPERATURE_CONSTRUCTION,
          );
          temCoolingWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, temCoolingWork);
        }
      } else if (i === CONST.INDEX_SG_ENVIRONMENT_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_ENVIRONMENT_WORK)
        ) {
          const environmentWork: EnvironmentWork = new EnvironmentWork();
          environmentWork.setAssociation(design, this);
          environmentWork.setDefaultData(
            CONST.INDEX_SG_ENVIRONMENT_WORK,
            CONST.ORDER_ENVIRONMENT_WORK,
            false,
            false,
            true,
            CONST.LB_ENVIRONMENT_WORK,
            CONST.LB_SMARTFARM_CONSTRUCTION,
          );
          environmentWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, environmentWork);
        }
      } else if (i === CONST.INDEX_SG_RACK_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_RACK_WORK)) {
          const rackWork: RackWork = new RackWork();
          rackWork.setAssociation(design, this);
          rackWork.setDefaultData(
            CONST.INDEX_SG_RACK_WORK,
            CONST.ORDER_RACK_WORK,
            false,
            false,
            true,
            CONST.LB_RACK_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          rackWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, rackWork);
        }
      } else if (i === CONST.INDEX_SG_RAIL_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_RAIL_WORK)) {
          const railWork: RailWork = new RailWork();
          railWork.setAssociation(design, this);
          railWork.setDefaultData(
            CONST.INDEX_SG_RAIL_WORK,
            CONST.ORDER_RAIL_WORK,
            false,
            false,
            true,
            CONST.LB_RAIL_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          railWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, railWork);
        }
      } else if (i === CONST.INDEX_SG_CO2_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_CO2_WORK)) {
          const co2Work: Co2Work = new Co2Work();
          co2Work.setAssociation(design, this);
          co2Work.setDefaultData(
            CONST.INDEX_SG_CO2_WORK,
            CONST.ORDER_CO2_WORK,
            false,
            false,
            true,
            CONST.LB_CO2_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          co2Work.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, co2Work);
        }
      } else if (i === CONST.INDEX_SG_AUTOSPRAY_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_AUTOSPRAY_WORK)) {
          const autosprayWork: AutosprayWork = new AutosprayWork();
          autosprayWork.setAssociation(design, this);
          autosprayWork.setDefaultData(
            CONST.INDEX_SG_AUTOSPRAY_WORK,
            CONST.ORDER_AUTOSPRAY_WORK,
            false,
            false,
            true,
            CONST.LB_AUTOSPRAY_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          autosprayWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, autosprayWork);
        }
      } else if (i === CONST.INDEX_SG_OTHER_FACILITIES_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_OTHER_FACILITIES_WORK)
        ) {
          const facilityWork: FacilityWork = new FacilityWork();
          facilityWork.setAssociation(design, this);
          facilityWork.setDefaultData(
            CONST.INDEX_SG_OTHER_FACILITIES_WORK,
            CONST.ORDER_OTHER_FACILITIES_WORK,
            false,
            false,
            true,
            CONST.LB_OTHER_FACILITIES_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          facilityWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, facilityWork);
        }
      } else if (i === CONST.INDEX_SG_BUILDING_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_BUILDING_WORK)) {
          const buildingWork: BuildingWork = new BuildingWork();
          buildingWork.setAssociation(design, this);
          buildingWork.setDefaultData(
            CONST.INDEX_SG_BUILDING_WORK,
            CONST.ORDER_BUILDING_WORK,
            false,
            false,
            true,
            CONST.LB_BUILDING_WORK,
            CONST.LB_BUILDING_CONSTRUCTION,
          );
          buildingWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, buildingWork);
        }
      }
    }

    super.restoreLatestObject(design);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
