import { jsonObject } from "typedjson";

import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { SkyCoverPart_NormalPad } from "vhows-design/src/object/design/skylight/cover/SkyCoverPart_NormalPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-19
 */
@jsonObject
export class SkyCoverSample_NormalPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const normalPadPart: SkyCoverPart_NormalPad = <SkyCoverPart_NormalPad>this.part;
    normalPadPart.algorithmSpec_normalPad_normalPad();
    normalPadPart.algorithmSpec_normalPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 일반 패드 샘플(피복 고정>측면)
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = this.struct.fixingWork.level1.sidePosition.normalPadPart.normalPadSample.specsList;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
