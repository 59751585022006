import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { GutterSampleIL_DrainageTHPPipe } from "vhows-design/src/object/design/frame/gutter/il/GutterSampleIL_DrainageTHPPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-15
 */
@jsonObject({
  knownTypes: [GutterSampleIL_DrainageTHPPipe],
})
export class GutterPartIL_DrainagePleatedPipe extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public drainageTHPPipeSample: GutterSampleIL_DrainageTHPPipe; // 배수 THP관 샘플

  // 아이템
  public pleatedPipe_thpPipe: ItemDrainPipe;
  public pleatedPipe_filmBand: ItemFrameOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _verticalPiping: number; // 수직 배관수

  /**
   * 수직 배관수
   */
  public get verticalPiping(): number {
    return this._verticalPiping;
  }

  //
  public set verticalPiping(value: number) {
    this._verticalPiping = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.drainageTHPPipeSample = new GutterSampleIL_DrainageTHPPipe();

    this.sampleAC = [this.drainageTHPPipeSample];

    // 아이템
    this.pleatedPipe_thpPipe = new ItemDrainPipe();
    this.pleatedPipe_filmBand = new ItemFrameOther();

    this.itemAC = [this.pleatedPipe_thpPipe, this.pleatedPipe_filmBand];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.drainageTHPPipeSample = <GutterSampleIL_DrainageTHPPipe>this.sampleAC[0];

    // 아이템
    this.pleatedPipe_thpPipe = <ItemDrainPipe>this.itemAC[0];
    this.pleatedPipe_filmBand = <ItemFrameOther>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.drainageTHPPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_THP_PIPE,
        CONST.ITEM_NAME_THP_PIPE,
        "주름관 배수",
        "일반, 300mm, 4m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.pleatedPipe_thpPipe.setDefaultData(
        0,
        CONST.ITEM_ID_THP_PIPE,
        CONST.ITEM_NAME_THP_PIPE,
        "주름관 배수",
        "일반, 300mm, 4m",
      );
      this.pleatedPipe_filmBand.setDefaultData(
        1,
        CONST.ITEM_ID_FILM_BAND,
        CONST.ITEM_NAME_FILM_BAND,
        "주름관 배수",
        "중형",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 1) {
      // [주름관 배수] 필름 밴드 추가
      const drainage_filmBand: ItemFrameOther = new ItemFrameOther();
      drainage_filmBand.setAssociation(design, struct, work, level, position, this);
      drainage_filmBand.setDefaultData(1, CONST.ITEM_ID_FILM_BAND, CONST.ITEM_NAME_FILM_BAND, "주름관 배수", "중형");
      drainage_filmBand.selected = true;
      drainage_filmBand.visible = true;
      this.itemAC.push(drainage_filmBand);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_verticalPiping();

    super.algorithmBasic();
  }

  /**
   * 파트 알고리즘 <- 기둥 간격(기둥 골조), 앞면/뒷면 처마 길이(지붕 골조/1중)
   */
  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.pleatedPipe_thpPipe.selected = true;
    this.pleatedPipe_thpPipe.visible = true;
    this.pleatedPipe_filmBand.selected = true;
    this.pleatedPipe_filmBand.visible = true;

    /// //////// 수량 ///////////

    this.pleatedPipe_thpPipe.designQuantity =
      ((this.basicLevel.heightShoulder + 0.5) * this.verticalPiping) / this.drainageTHPPipeSample.specLength;

    // this.pleatedPipe_filmBand.designQuantity = 0;
  }

  /**
   * 알고리즘: 수직 배관수 <- 하우스 동수(기본 정보), 서까래 골 수량(벤로)
   */
  public algorithm_verticalPiping(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.level.index === 0) {
        this.verticalPiping = this.basic.buildingNumber - 1;
      } else {
        this.verticalPiping = this.basic.buildingNumber * 2;
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.level.index === 0) {
        const rafterValleyQuantity: number = this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterValleyQuantity;
        this.verticalPiping = this.basic.buildingNumber * rafterValleyQuantity + 1;
      } else {
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [주름관 배수] THP관 <- 배수 THP관 샘플
   */
  public algorithm_pleatedPipe_thpPipe(): void {
    this.pleatedPipe_thpPipe.specs = this.drainageTHPPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [주름관 배수] 필름 밴드 <- 공통 샘플
   */
  public algorithm_pleatedPipe_filmBand(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
