import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemSwitcherAssist } from "vhows-design/src/object/design/item/list/ItemSwitcherAssist";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherSampleIL_VerticalSupPipe } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_VerticalSupPipe";
import { SwitcherSampleIL_DiagonalSupAssistPipe } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_DiagonalSupAssistPipe";
import { SwitcherSampleIL_DiagonalSupPipe } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_DiagonalSupPipe";
import { SwitcherSampleIL_RafterSupPipe } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_RafterSupPipe";
import { SwitcherSampleIL_VerticalSupPipe2 } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_VerticalSupPipe2";
import { SwitcherSampleIL_DiagonalSupAssistPipe2 } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_DiagonalSupAssistPipe2";
import { SwitcherSampleIL_DiagonalSupPipe2 } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_DiagonalSupPipe2";
import { SwitcherSampleIL_RafterSupPipe2 } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_RafterSupPipe2";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject({
  knownTypes: [
    SwitcherSampleIL_VerticalSupPipe,
    SwitcherSampleIL_DiagonalSupAssistPipe,
    SwitcherSampleIL_DiagonalSupPipe,
    SwitcherSampleIL_RafterSupPipe,
    SwitcherSampleIL_VerticalSupPipe2,
    SwitcherSampleIL_DiagonalSupAssistPipe2,
    SwitcherSampleIL_DiagonalSupPipe2,
    SwitcherSampleIL_RafterSupPipe2,
  ],
})
export class SwitcherPartIL_Support extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public verticalSupportPipeSample: SwitcherSampleIL_VerticalSupPipe; // 수직형 지지 파이프 샘플
  public diagonalSupportPipeSample: SwitcherSampleIL_DiagonalSupPipe; // 대각형 지지 파이프 샘플
  public diagonalSupportAssistPipeSample: SwitcherSampleIL_DiagonalSupAssistPipe; // 대각형 지지 보조 파이프 샘플
  public rafterSupportPipeSample: SwitcherSampleIL_RafterSupPipe; // 서까래형 지지 보조 파이프 샘플
  public verticalSupportPipe2Sample: SwitcherSampleIL_VerticalSupPipe2; // 수직형 지지 파이프(2차) 샘플
  public diagonalSupportPipe2Sample: SwitcherSampleIL_DiagonalSupPipe2; // 대각형 지지 파이프(2차) 샘플
  public diagonalSupportAssistPipe2Sample: SwitcherSampleIL_DiagonalSupAssistPipe2; // 대각형 지지 보조 파이프(2차) 샘플
  public rafterSupportPipe2Sample: SwitcherSampleIL_RafterSupPipe2; // 서까래형 지지 보조 파이프(2차) 샘플

  // 아이템
  public supportVertical_pipe: ItemPipe;
  public supportDiagonal_pipe: ItemPipe;
  public supportRafter_pipe: ItemPipe;
  public supportAssist_pipe: ItemPipe;
  public support_hookHolder: ItemHolder;
  public support_supportFixingPin: ItemSwitcherAssist;
  public support_longBolt: ItemFrameOther;
  public support_axisRoller: ItemSwitcherAssist;
  public supportVertical2_pipe: ItemPipe;
  public supportDiagonal2_pipe: ItemPipe;
  public supportRafter2_pipe: ItemPipe;
  public supportAssist2_pipe: ItemPipe;
  public support2_hookHolder: ItemHolder;
  public support2_supportFixingPin: ItemSwitcherAssist;
  public support2_longBolt: ItemFrameOther;
  public support2_axisRoller: ItemSwitcherAssist;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _supportLength: number; // 지지 길이
  @jsonMember(Number)
  public _support2Length: number; // 지지(2차) 길이

  /**
   * 지지 길이
   */
  public get supportLength(): number {
    return this._supportLength;
  }

  //
  public set supportLength(value: number) {
    this._supportLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 지지 길이(2차)
   */
  public get support2Length(): number {
    return this._support2Length;
  }

  //
  public set support2Length(value: number) {
    this._support2Length = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.verticalSupportPipeSample = new SwitcherSampleIL_VerticalSupPipe();
    this.diagonalSupportPipeSample = new SwitcherSampleIL_DiagonalSupPipe();
    this.diagonalSupportAssistPipeSample = new SwitcherSampleIL_DiagonalSupAssistPipe();
    this.rafterSupportPipeSample = new SwitcherSampleIL_RafterSupPipe();
    this.verticalSupportPipe2Sample = new SwitcherSampleIL_VerticalSupPipe2();
    this.diagonalSupportPipe2Sample = new SwitcherSampleIL_DiagonalSupPipe2();
    this.diagonalSupportAssistPipe2Sample = new SwitcherSampleIL_DiagonalSupAssistPipe2();
    this.rafterSupportPipe2Sample = new SwitcherSampleIL_RafterSupPipe2();

    this.sampleAC = [
      this.verticalSupportPipeSample,
      this.diagonalSupportPipeSample,
      this.diagonalSupportAssistPipeSample,
      this.rafterSupportPipeSample,
      this.verticalSupportPipe2Sample,
      this.diagonalSupportPipe2Sample,
      this.diagonalSupportAssistPipe2Sample,
      this.rafterSupportPipe2Sample,
    ];

    // 아이템
    this.supportVertical_pipe = new ItemPipe();
    this.supportDiagonal_pipe = new ItemPipe();
    this.supportRafter_pipe = new ItemPipe();
    this.supportAssist_pipe = new ItemPipe();
    this.support_hookHolder = new ItemHolder();
    this.support_supportFixingPin = new ItemSwitcherAssist();
    this.support_longBolt = new ItemFrameOther();
    this.support_axisRoller = new ItemSwitcherAssist();
    this.supportVertical2_pipe = new ItemPipe();
    this.supportDiagonal2_pipe = new ItemPipe();
    this.supportRafter2_pipe = new ItemPipe();
    this.supportAssist2_pipe = new ItemPipe();
    this.support2_hookHolder = new ItemHolder();
    this.support2_supportFixingPin = new ItemSwitcherAssist();
    this.support2_longBolt = new ItemFrameOther();
    this.support2_axisRoller = new ItemSwitcherAssist();

    this.itemAC = [
      this.supportVertical_pipe,
      this.supportDiagonal_pipe,
      this.supportRafter_pipe,
      this.supportAssist_pipe,
      this.support_hookHolder,
      this.support_supportFixingPin,
      this.support_longBolt,
      this.support_axisRoller,
      this.supportVertical2_pipe,
      this.supportDiagonal2_pipe,
      this.supportRafter2_pipe,
      this.supportAssist2_pipe,
      this.support2_hookHolder,
      this.support2_supportFixingPin,
      this.support2_longBolt,
      this.support2_axisRoller,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.verticalSupportPipeSample = <SwitcherSampleIL_VerticalSupPipe>this.sampleAC[0];
    this.diagonalSupportPipeSample = <SwitcherSampleIL_DiagonalSupPipe>this.sampleAC[1];
    this.diagonalSupportAssistPipeSample = <SwitcherSampleIL_DiagonalSupAssistPipe>this.sampleAC[2];
    this.rafterSupportPipeSample = <SwitcherSampleIL_RafterSupPipe>this.sampleAC[3];
    this.verticalSupportPipe2Sample = <SwitcherSampleIL_VerticalSupPipe>this.sampleAC[4];
    this.diagonalSupportPipe2Sample = <SwitcherSampleIL_DiagonalSupPipe>this.sampleAC[5];
    this.diagonalSupportAssistPipe2Sample = <SwitcherSampleIL_DiagonalSupAssistPipe>this.sampleAC[6];
    this.rafterSupportPipe2Sample = <SwitcherSampleIL_RafterSupPipe2>this.sampleAC[7];

    // 아이템
    this.supportVertical_pipe = <ItemPipe>this.itemAC[0];
    this.supportDiagonal_pipe = <ItemPipe>this.itemAC[1];
    this.supportRafter_pipe = <ItemPipe>this.itemAC[2];
    this.supportAssist_pipe = <ItemPipe>this.itemAC[3];
    this.support_hookHolder = <ItemHolder>this.itemAC[4];
    this.support_supportFixingPin = <ItemSwitcherAssist>this.itemAC[5];
    this.support_longBolt = <ItemFrameOther>this.itemAC[6];
    this.support_axisRoller = <ItemSwitcherAssist>this.itemAC[7];
    this.supportVertical2_pipe = <ItemPipe>this.itemAC[8];
    this.supportDiagonal2_pipe = <ItemPipe>this.itemAC[9];
    this.supportRafter2_pipe = <ItemPipe>this.itemAC[10];
    this.supportAssist2_pipe = <ItemPipe>this.itemAC[11];
    this.support2_hookHolder = <ItemHolder>this.itemAC[12];
    this.support2_supportFixingPin = <ItemSwitcherAssist>this.itemAC[13];
    this.support2_longBolt = <ItemFrameOther>this.itemAC[14];
    this.support2_axisRoller = <ItemSwitcherAssist>this.itemAC[15];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param supportLength: number 지지 길이
   * @param support2Length: number 지지 길이(2차)
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    supportLength: number = 0,
    support2Length: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._supportLength = supportLength;
    this._support2Length = support2Length;

    // 샘플
    if (this.level.index >= 0) {
      this.verticalSupportPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "수직형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.diagonalSupportPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "대각형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.diagonalSupportAssistPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "대각형 지지",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.rafterSupportPipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, 벤딩",
      );
      this.verticalSupportPipe2Sample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "수직형 지지(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.diagonalSupportPipe2Sample.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "대각형 지지(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.diagonalSupportAssistPipe2Sample.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "대각형 지지(2차)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.rafterSupportPipe2Sample.setDefaultData(
        7,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래형 지지(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, 벤딩",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.supportVertical_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "수직형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.supportDiagonal_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "대각형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.supportRafter_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, 벤딩",
      );
      this.supportAssist_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "대각형 지지 보조",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.support_hookHolder.setDefaultData(
        4,
        CONST.ITEM_ID_HOOK_HOLDER,
        CONST.ITEM_NAME_HOOK_HOLDER,
        "대각형 지지 보조 + 마구리 샛기둥",
        "원형, 일반, 25mm, -",
      );
      this.support_supportFixingPin.setDefaultData(
        5,
        CONST.ITEM_ID_SUPPORT_FIXING_PIN,
        CONST.ITEM_NAME_SUPPORT_FIXING_PIN,
        "대각형 지지",
        "32mm",
      );
      this.support_longBolt.setDefaultData(
        6,
        CONST.ITEM_ID_LONG_BOLT,
        CONST.ITEM_NAME_LONG_BOLT,
        "대각형 지지",
        "일반",
      );
      this.support_axisRoller.setDefaultData(
        7,
        CONST.ITEM_ID_AXIS_ROLLER,
        CONST.ITEM_NAME_AXIS_ROLLER,
        "수직형 지지",
        "25mm",
      );
      this.supportVertical2_pipe.setDefaultData(
        8,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "수직형 지지(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.supportDiagonal2_pipe.setDefaultData(
        9,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "대각형 지지(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.supportRafter2_pipe.setDefaultData(
        10,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래형 지지(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, 벤딩",
      );
      this.supportAssist2_pipe.setDefaultData(
        11,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "대각형 지지 보조(2차)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.support2_hookHolder.setDefaultData(
        12,
        CONST.ITEM_ID_HOOK_HOLDER,
        CONST.ITEM_NAME_HOOK_HOLDER,
        "대각형 지지 보조(2차) + 마구리 샛기둥",
        "원형, 일반, 25mm, -",
      );
      this.support2_supportFixingPin.setDefaultData(
        13,
        CONST.ITEM_ID_SUPPORT_FIXING_PIN,
        CONST.ITEM_NAME_SUPPORT_FIXING_PIN,
        "대각형 지지(2차)",
        "32mm",
      );
      this.support2_longBolt.setDefaultData(
        14,
        CONST.ITEM_ID_LONG_BOLT,
        CONST.ITEM_NAME_LONG_BOLT,
        "대각형 지지(2차)",
        "일반",
      );
      this.support2_axisRoller.setDefaultData(
        15,
        CONST.ITEM_ID_AXIS_ROLLER,
        CONST.ITEM_NAME_AXIS_ROLLER,
        "수직형 지지(2차)",
        "25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>this.position;
    let verticalBothEndQuantity: number;
    let verticalCentralQuantity: number;
    let DiagonalBothEndQuantity: number;
    let DiagonalCentralQuantity: number;
    let vertical2BothEndQuantity: number;
    let vertical2CentralQuantity: number;
    let Diagonal2BothEndQuantity: number;
    let Diagonal2CentralQuantity: number;

    /// //////// 선택, 가시성 ///////////

    if (
      switcherPosition.switchOrderMax === CONST.VL_SWITCHER_ORDER_1 ||
      switcherPosition.switchOrderMax === CONST.VL_SWITCHER_ORDER_2
    ) {
      if (switcherPosition.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL) {
        this.supportVertical_pipe.selected = true;
        this.supportVertical_pipe.visible = true;
        this.supportDiagonal_pipe.selected = false;
        this.supportDiagonal_pipe.visible = false;
        this.supportRafter_pipe.selected = false;
        this.supportRafter_pipe.visible = false;
        this.supportAssist_pipe.selected = false;
        this.supportAssist_pipe.visible = false;
        this.support_hookHolder.selected = false;
        this.support_hookHolder.visible = false;
        this.support_supportFixingPin.selected = false;
        this.support_supportFixingPin.visible = false;
        this.support_longBolt.selected = false;
        this.support_longBolt.visible = false;
        if (switcherPosition.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
          this.support_axisRoller.selected = true;
          this.support_axisRoller.visible = true;
        } else {
          this.support_axisRoller.selected = false;
          this.support_axisRoller.visible = false;
        }
      } else if (switcherPosition.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL) {
        this.supportVertical_pipe.selected = false;
        this.supportVertical_pipe.visible = false;
        this.supportDiagonal_pipe.selected = true;
        this.supportDiagonal_pipe.visible = true;
        this.supportRafter_pipe.selected = false;
        this.supportRafter_pipe.visible = false;
        this.supportAssist_pipe.selected = true;
        this.supportAssist_pipe.visible = true;
        this.support_hookHolder.selected = true;
        this.support_hookHolder.visible = true;
        if (switcherPosition.type === CONST.LB_SWITCHER_TYPE_MANUAL) {
          this.support_supportFixingPin.selected = true;
          this.support_supportFixingPin.visible = true;
          this.support_longBolt.selected = true;
          this.support_longBolt.visible = true;
        } else {
          this.support_supportFixingPin.selected = false;
          this.support_supportFixingPin.visible = false;
          this.support_longBolt.selected = false;
          this.support_longBolt.visible = false;
        }
        this.support_axisRoller.selected = false;
        this.support_axisRoller.visible = false;
      } else if (switcherPosition.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_RAFTER) {
        this.supportVertical_pipe.selected = false;
        this.supportVertical_pipe.visible = false;
        this.supportDiagonal_pipe.selected = false;
        this.supportDiagonal_pipe.visible = false;
        this.supportRafter_pipe.selected = true;
        this.supportRafter_pipe.visible = true;
        this.supportAssist_pipe.selected = false;
        this.supportAssist_pipe.visible = false;
        this.support_hookHolder.selected = false;
        this.support_hookHolder.visible = false;
        this.support_supportFixingPin.selected = false;
        this.support_supportFixingPin.visible = false;
        this.support_longBolt.selected = false;
        this.support_longBolt.visible = false;
        if (switcherPosition.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
          this.support_axisRoller.selected = true;
          this.support_axisRoller.visible = true;
        } else if (
          switcherPosition.type === CONST.LB_SWITCHER_TYPE_MANUAL &&
          switcherPosition.manual1Part.manualSwitcherSample.specSwitchType === CONST.ITEM_SPEC_VALUE_CHAIN
        ) {
          this.support_axisRoller.selected = true;
          this.support_axisRoller.visible = true;
        } else {
          this.support_axisRoller.selected = false;
          this.support_axisRoller.visible = false;
        }
      } else {
        this.supportVertical_pipe.selected = false;
        this.supportVertical_pipe.visible = false;
        this.supportDiagonal_pipe.selected = false;
        this.supportDiagonal_pipe.visible = false;
        this.supportRafter_pipe.selected = false;
        this.supportRafter_pipe.visible = false;
        this.supportAssist_pipe.selected = false;
        this.supportAssist_pipe.visible = false;
        this.support_hookHolder.selected = false;
        this.support_hookHolder.visible = false;
        this.support_supportFixingPin.selected = false;
        this.support_supportFixingPin.visible = false;
        this.support_longBolt.selected = false;
        this.support_longBolt.visible = false;
        this.support_axisRoller.selected = false;
        this.support_axisRoller.visible = false;
      }
    }
    if (switcherPosition.switchOrderMax === CONST.VL_SWITCHER_ORDER_1) {
      this.supportVertical2_pipe.selected = false;
      this.supportVertical2_pipe.visible = false;
      this.supportDiagonal2_pipe.selected = false;
      this.supportDiagonal2_pipe.visible = false;
      this.supportRafter2_pipe.selected = false;
      this.supportRafter2_pipe.visible = false;
      this.supportAssist2_pipe.selected = false;
      this.supportAssist2_pipe.visible = false;
      this.support2_hookHolder.selected = false;
      this.support2_hookHolder.visible = false;
      this.support2_supportFixingPin.selected = false;
      this.support2_supportFixingPin.visible = false;
      this.support2_longBolt.selected = false;
      this.support2_longBolt.visible = false;
      this.support2_axisRoller.selected = false;
      this.support2_axisRoller.visible = false;
    }
    if (switcherPosition.switchOrderMax === CONST.VL_SWITCHER_ORDER_2) {
      if (switcherPosition.support2Type === CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL) {
        this.supportVertical2_pipe.selected = true;
        this.supportVertical2_pipe.visible = true;
        this.supportDiagonal2_pipe.selected = false;
        this.supportDiagonal2_pipe.visible = false;
        this.supportRafter2_pipe.selected = false;
        this.supportRafter2_pipe.visible = false;
        this.supportAssist2_pipe.selected = false;
        this.supportAssist2_pipe.visible = false;
        this.support2_hookHolder.selected = false;
        this.support2_hookHolder.visible = false;
        this.support2_supportFixingPin.selected = false;
        this.support2_supportFixingPin.visible = false;
        this.support2_longBolt.selected = false;
        this.support2_longBolt.visible = false;
        if (switcherPosition.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
          this.support2_axisRoller.selected = true;
          this.support2_axisRoller.visible = true;
        } else {
          this.support2_axisRoller.selected = false;
          this.support2_axisRoller.visible = false;
        }
      } else if (switcherPosition.support2Type === CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL) {
        this.supportVertical2_pipe.selected = false;
        this.supportVertical2_pipe.visible = false;
        this.supportDiagonal2_pipe.selected = true;
        this.supportDiagonal2_pipe.visible = true;
        this.supportRafter2_pipe.selected = false;
        this.supportRafter2_pipe.visible = false;
        this.supportAssist2_pipe.selected = true;
        this.supportAssist2_pipe.visible = true;
        this.support2_hookHolder.selected = true;
        this.support2_hookHolder.visible = true;
        if (switcherPosition.type === CONST.LB_SWITCHER_TYPE_MANUAL) {
          this.support2_supportFixingPin.selected = true;
          this.support2_supportFixingPin.visible = true;
          this.support2_longBolt.selected = true;
          this.support2_longBolt.visible = true;
        } else {
          this.support2_supportFixingPin.selected = false;
          this.support2_supportFixingPin.visible = false;
          this.support2_longBolt.selected = false;
          this.support2_longBolt.visible = false;
        }
        this.support2_axisRoller.selected = false;
        this.support2_axisRoller.visible = false;
      } else if (switcherPosition.support2Type === CONST.LB_SWITCHER_SUPPPORT_TYPE_RAFTER) {
        this.supportVertical2_pipe.selected = false;
        this.supportVertical2_pipe.visible = false;
        this.supportDiagonal2_pipe.selected = false;
        this.supportDiagonal2_pipe.visible = false;
        this.supportRafter2_pipe.selected = true;
        this.supportRafter2_pipe.visible = true;
        this.supportAssist2_pipe.selected = false;
        this.supportAssist2_pipe.visible = false;
        this.support2_hookHolder.selected = false;
        this.support2_hookHolder.visible = false;
        this.support2_supportFixingPin.selected = false;
        this.support2_supportFixingPin.visible = false;
        this.support2_longBolt.selected = false;
        this.support2_longBolt.visible = false;
        if (switcherPosition.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
          this.support2_axisRoller.selected = true;
          this.support2_axisRoller.visible = true;
        } else if (
          switcherPosition.type === CONST.LB_SWITCHER_TYPE_MANUAL &&
          switcherPosition.manual2Part.manualSwitcherSample.specSwitchType === CONST.ITEM_SPEC_VALUE_CHAIN
        ) {
          this.support2_axisRoller.selected = true;
          this.support2_axisRoller.visible = true;
        } else {
          this.support2_axisRoller.selected = false;
          this.support2_axisRoller.visible = false;
        }
      } else {
        this.supportVertical2_pipe.selected = false;
        this.supportVertical2_pipe.visible = false;
        this.supportDiagonal2_pipe.selected = false;
        this.supportDiagonal2_pipe.visible = false;
        this.supportRafter2_pipe.selected = false;
        this.supportRafter2_pipe.visible = false;
        this.supportAssist2_pipe.selected = false;
        this.supportAssist2_pipe.visible = false;
        this.support2_hookHolder.selected = false;
        this.support2_hookHolder.visible = false;
        this.support2_supportFixingPin.selected = false;
        this.support2_supportFixingPin.visible = false;
        this.support2_longBolt.selected = false;
        this.support2_longBolt.visible = false;
        this.support2_axisRoller.selected = false;
        this.support2_axisRoller.visible = false;
      }
    }

    /// //////// 수량 ///////////

    if (
      switcherPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      switcherPosition.label === CONST.LB_POSITION_SIDE_WING
    ) {
      this.supportVertical_pipe.designQuantity =
        (this.supportLength * switcherPosition.switchWayValue) / this.supportVertical_pipe.specLength;
      this.support_axisRoller.designQuantity = switcherPosition.switchWayValue;
      this.supportDiagonal_pipe.designQuantity =
        (this.supportLength * switcherPosition.switchWayValue) / this.supportDiagonal_pipe.specLength;
      this.supportAssist_pipe.designQuantity =
        (this.supportLength * switcherPosition.switchWayValue) / this.supportAssist_pipe.specLength;
      this.support_hookHolder.designQuantity = switcherPosition.switchWayValue;
      this.support_supportFixingPin.designQuantity = switcherPosition.switchWayValue;
      this.support_longBolt.designQuantity = switcherPosition.switchWayValue;
      this.supportVertical2_pipe.designQuantity =
        (this.support2Length * switcherPosition.switchWayValue) / this.supportVertical2_pipe.specLength;
      this.support2_axisRoller.designQuantity = switcherPosition.switchWayValue;
      this.supportDiagonal2_pipe.designQuantity =
        (this.support2Length * switcherPosition.switchWayValue) / this.supportDiagonal2_pipe.specLength;
      this.supportAssist2_pipe.designQuantity =
        (this.support2Length * switcherPosition.switchWayValue) / this.supportAssist2_pipe.specLength;
      this.support2_hookHolder.designQuantity = switcherPosition.switchWayValue;
      this.support2_supportFixingPin.designQuantity = switcherPosition.switchWayValue;
      this.support2_longBolt.designQuantity = switcherPosition.switchWayValue;

      if (switcherPosition.switchStep === CONST.VL_SWITCHER_ORDER_2) {
        this.supportVertical_pipe.designQuantity *= switcherPosition.switchStep;
        this.support_axisRoller.designQuantity *= switcherPosition.switchStep;
        this.supportDiagonal_pipe.designQuantity *= switcherPosition.switchStep;
        this.supportAssist_pipe.designQuantity *= switcherPosition.switchStep;
        this.support_hookHolder.designQuantity *= switcherPosition.switchStep;
        this.support_supportFixingPin.designQuantity *= switcherPosition.switchStep;
        this.support_longBolt.designQuantity *= switcherPosition.switchStep;
        this.supportVertical2_pipe.designQuantity *= switcherPosition.switchStep;
        this.support2_axisRoller.designQuantity *= switcherPosition.switchStep;
        this.supportDiagonal2_pipe.designQuantity *= switcherPosition.switchStep;
        this.supportAssist2_pipe.designQuantity *= switcherPosition.switchStep;
        this.support2_hookHolder.designQuantity *= switcherPosition.switchStep;
        this.support2_supportFixingPin.designQuantity *= switcherPosition.switchStep;
        this.support2_longBolt.designQuantity *= switcherPosition.switchStep;
      }
    } else if (switcherPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          verticalBothEndQuantity = (this.supportLength * 2) / this.supportVertical_pipe.specLength;
          verticalCentralQuantity =
            (this.supportLength * switcherPosition.switchWayValue * (this.basic.buildingNumber - 2)) /
            this.supportVertical_pipe.specLength;
          this.supportVertical_pipe.designQuantity = verticalBothEndQuantity + verticalCentralQuantity;
          verticalBothEndQuantity = 2;
          verticalCentralQuantity = switcherPosition.switchWayValue * (this.basic.buildingNumber - 2);
          this.support_axisRoller.designQuantity = verticalBothEndQuantity + verticalCentralQuantity;
          DiagonalBothEndQuantity = (this.supportLength * 2) / this.supportDiagonal_pipe.specLength;
          DiagonalCentralQuantity =
            (this.supportLength * switcherPosition.switchWayValue * (this.basic.buildingNumber - 2)) /
            this.supportDiagonal_pipe.specLength;
          this.supportDiagonal_pipe.designQuantity = DiagonalBothEndQuantity + DiagonalCentralQuantity;
          DiagonalBothEndQuantity = (this.supportLength * 2) / this.supportAssist_pipe.specLength;
          DiagonalCentralQuantity =
            (this.supportLength * switcherPosition.switchWayValue * (this.basic.buildingNumber - 2)) /
            this.supportAssist_pipe.specLength;
          this.supportAssist_pipe.designQuantity = DiagonalBothEndQuantity + DiagonalCentralQuantity;
          DiagonalBothEndQuantity = 2;
          DiagonalCentralQuantity = switcherPosition.switchWayValue * (this.basic.buildingNumber - 2);
          this.support_hookHolder.designQuantity = DiagonalBothEndQuantity + DiagonalCentralQuantity;
          this.support_supportFixingPin.designQuantity = DiagonalBothEndQuantity + DiagonalCentralQuantity;
          this.support_longBolt.designQuantity = DiagonalBothEndQuantity + DiagonalCentralQuantity;
          vertical2BothEndQuantity = (this.support2Length * 2) / this.supportVertical2_pipe.specLength;
          vertical2CentralQuantity =
            (this.support2Length * switcherPosition.switchWayValue * (this.basic.buildingNumber - 2)) /
            this.supportVertical2_pipe.specLength;
          this.supportVertical2_pipe.designQuantity = vertical2BothEndQuantity + vertical2CentralQuantity;
          vertical2BothEndQuantity = 2;
          vertical2CentralQuantity = switcherPosition.switchWayValue * (this.basic.buildingNumber - 2);
          this.support2_axisRoller.designQuantity = vertical2BothEndQuantity + vertical2CentralQuantity;
          Diagonal2BothEndQuantity = (this.support2Length * 2) / this.supportDiagonal2_pipe.specLength;
          Diagonal2CentralQuantity =
            (this.support2Length * switcherPosition.switchWayValue * (this.basic.buildingNumber - 2)) /
            this.supportDiagonal2_pipe.specLength;
          this.supportDiagonal2_pipe.designQuantity = Diagonal2BothEndQuantity + Diagonal2CentralQuantity;
          Diagonal2BothEndQuantity = (this.support2Length * 2) / this.supportAssist_pipe.specLength;
          Diagonal2CentralQuantity =
            (this.support2Length * switcherPosition.switchWayValue * (this.basic.buildingNumber - 2)) /
            this.supportAssist_pipe.specLength;
          this.supportAssist_pipe.designQuantity = Diagonal2BothEndQuantity + Diagonal2CentralQuantity;
          Diagonal2BothEndQuantity = 2;
          Diagonal2CentralQuantity = switcherPosition.switchWayValue * (this.basic.buildingNumber - 2);
          this.support2_hookHolder.designQuantity = Diagonal2BothEndQuantity + Diagonal2CentralQuantity;
          this.support2_supportFixingPin.designQuantity = Diagonal2BothEndQuantity + Diagonal2CentralQuantity;
          this.support2_longBolt.designQuantity = Diagonal2BothEndQuantity + Diagonal2CentralQuantity;
        } else {
          this.supportVertical_pipe.designQuantity =
            (this.supportLength * switcherPosition.switchWayValue * this.basic.buildingNumber) /
            this.supportVertical_pipe.specLength;
          this.support_axisRoller.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          this.supportDiagonal_pipe.designQuantity =
            (this.supportLength * switcherPosition.switchWayValue * this.basic.buildingNumber) /
            this.supportDiagonal_pipe.specLength;
          this.supportAssist_pipe.designQuantity =
            (this.supportLength * switcherPosition.switchWayValue * this.basic.buildingNumber) /
            this.supportAssist_pipe.specLength;
          this.support_hookHolder.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          this.support_supportFixingPin.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          this.support_longBolt.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          this.supportVertical2_pipe.designQuantity =
            (this.support2Length * switcherPosition.switchWayValue * this.basic.buildingNumber) /
            this.supportVertical2_pipe.specLength;
          this.support2_axisRoller.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          this.supportDiagonal2_pipe.designQuantity =
            (this.support2Length * switcherPosition.switchWayValue * this.basic.buildingNumber) /
            this.supportDiagonal2_pipe.specLength;
          this.supportAssist2_pipe.designQuantity =
            (this.support2Length * switcherPosition.switchWayValue * this.basic.buildingNumber) /
            this.supportAssist2_pipe.specLength;
          this.support2_hookHolder.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          this.support2_supportFixingPin.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          this.support2_longBolt.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
        }
      } else {
        this.supportVertical_pipe.designQuantity =
          (this.supportLength * switcherPosition.switchWayValue) / this.supportVertical_pipe.specLength;
        this.support_axisRoller.designQuantity = switcherPosition.switchWayValue;
        this.supportDiagonal_pipe.designQuantity =
          (this.supportLength * switcherPosition.switchWayValue) / this.supportDiagonal_pipe.specLength;
        this.supportAssist_pipe.designQuantity =
          (this.supportLength * switcherPosition.switchWayValue) / this.supportAssist_pipe.specLength;
        this.support_hookHolder.designQuantity = switcherPosition.switchWayValue;
        this.support_supportFixingPin.designQuantity = switcherPosition.switchWayValue;
        this.support_longBolt.designQuantity = switcherPosition.switchWayValue;
        this.supportVertical2_pipe.designQuantity =
          (this.support2Length * switcherPosition.switchWayValue) / this.supportVertical2_pipe.specLength;
        this.support2_axisRoller.designQuantity = switcherPosition.switchWayValue;
        this.supportDiagonal2_pipe.designQuantity =
          (this.support2Length * switcherPosition.switchWayValue) / this.supportDiagonal2_pipe.specLength;
        this.supportAssist2_pipe.designQuantity =
          (this.support2Length * switcherPosition.switchWayValue) / this.supportAssist2_pipe.specLength;
        this.support2_hookHolder.designQuantity = switcherPosition.switchWayValue;
        this.support2_supportFixingPin.designQuantity = switcherPosition.switchWayValue;
        this.support2_longBolt.designQuantity = switcherPosition.switchWayValue;
      }
    } else if (
      switcherPosition.label === CONST.LB_POSITION_FRONT ||
      switcherPosition.label === CONST.LB_POSITION_BACK
    ) {
      this.supportVertical_pipe.designQuantity = this.supportLength / this.supportVertical_pipe.specLength;
      this.support_axisRoller.designQuantity = 1;
      this.supportDiagonal_pipe.designQuantity = this.supportLength / this.supportDiagonal_pipe.specLength;
      this.supportAssist_pipe.designQuantity = this.supportLength / this.supportAssist_pipe.specLength;
      this.support_hookHolder.designQuantity = 1;
      this.support_supportFixingPin.designQuantity = 1;
      this.support_longBolt.designQuantity = 1;
      this.supportVertical2_pipe.designQuantity = this.support2Length / this.supportVertical2_pipe.specLength;
      this.support2_axisRoller.designQuantity = 1;
      this.supportDiagonal2_pipe.designQuantity = this.support2Length / this.supportDiagonal2_pipe.specLength;
      this.supportAssist2_pipe.designQuantity = this.support2Length / this.supportAssist2_pipe.specLength;
      this.support2_hookHolder.designQuantity = 1;
      this.support2_supportFixingPin.designQuantity = 1;
      this.support2_longBolt.designQuantity = 1;
    }
  }

  /**
   * 알고리즘: 지지 길이 <- 개폐 높이(위치)
   */
  public algorithm_supportLength(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>this.position;
    if ((<SwitcherPositionIL>this.position).supportType === CONST.LB_SWITCHER_SUPPORT_VERTICAL) {
      this.supportLength = CommonUtil.roundUpX(switcherPosition.switchHeight + 0.5, 0.5);
    } else if ((<SwitcherPositionIL>this.position).supportType === CONST.LB_SWITCHER_SUPPORT_DIAGONAL) {
      this.supportLength = CommonUtil.roundUpX(switcherPosition.switchHeight - 0.5, 0.5);
    }
  }

  /**
   * 알고리즘: 지지(2차) 길이 <- 개폐 높이(위치)
   */
  public algorithm_support2Length(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>this.position;
    if ((<SwitcherPositionIL>this.position).supportType === CONST.LB_SWITCHER_SUPPORT_VERTICAL) {
      this.support2Length = CommonUtil.roundUpX(switcherPosition.switchHeight + 0.5, 0.5);
    } else if ((<SwitcherPositionIL>this.position).supportType === CONST.LB_SWITCHER_SUPPORT_DIAGONAL) {
      this.support2Length = CommonUtil.roundUpX(switcherPosition.switchHeight - 0.5, 0.5);
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [수직형 지지] 파이프 <- 수직형 지지 파이프 샘플
   */
  public algorithmSpec_vertical_pipe(): void {
    this.supportVertical_pipe.specs = this.verticalSupportPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [수직형 지지] 축 롤러 <- 수직형 지지 파이프 샘플
   */
  public algorithmSpec_vertical_axisRoller(): void {
    this.support_axisRoller.specCrossSize = this.verticalSupportPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [대각형 지지] 파이프 <- 대각형 지지 파이프 파이프 샘플
   */
  public algorithmSpec_diagonal_pipe(): void {
    this.supportDiagonal_pipe.specs = this.diagonalSupportPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [대각형 지지 보조] 파이프 <- 대각형 지지 보조 파이프 파이프 샘플
   */
  public algorithmSpec_diagonalAssist_pipe(): void {
    this.supportDiagonal_pipe.specs = this.diagonalSupportPipeSample.specs;
    this.supportAssist_pipe.specs = this.diagonalSupportAssistPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [대각형 지지 보조 + 마구리 샛기둥] 고리 고정구 <- 형태(마구리 골조), 마구리 샛기둥 파이프 샘플
   */
  public algorithmSpec_diagonal_hookHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const endpieceLevel: EndpieceLevelIL = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
      // 앞면을 따름
      if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
        this.support_hookHolder.specPipeType =
          endpieceLevel.frontPosition.cylinderStudSepPart.studBottomPipeSample.specPipeType;
        this.support_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
        this.support_hookHolder.specCrossSize1 =
          endpieceLevel.frontPosition.cylinderStudSepPart.studBottomPipeSample.specCrossSize;
      } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
        this.support_hookHolder.specPipeType = CONST.ITEM_SPEC_VALUE_FREE_TYPE;
        this.support_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_FLAT_HOLDER;
        this.support_hookHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
      } else {
        this.support_hookHolder.specPipeType = endpieceLevel.frontPosition.cylinderStudPart.studPipeSample.specPipeType;
        this.support_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
        this.support_hookHolder.specCrossSize1 =
          endpieceLevel.frontPosition.cylinderStudPart.studPipeSample.specCrossSize;
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const endpieceLevel: EndpieceLevelVL = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
      // 앞면을 따름
      if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
        this.support_hookHolder.specPipeType =
          endpieceLevel.frontPosition.endpiecePart.straightBottomPipeSample.specPipeType;
        this.support_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
        this.support_hookHolder.specCrossSize1 =
          endpieceLevel.frontPosition.endpiecePart.straightBottomPipeSample.specCrossSize;
      } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
        this.support_hookHolder.specPipeType = CONST.ITEM_SPEC_VALUE_FREE_TYPE;
        this.support_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_FLAT_HOLDER;
        this.support_hookHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
      } else {
        this.support_hookHolder.specPipeType = endpieceLevel.frontPosition.endpiecePart.barPipeSample.specPipeType;
        this.support_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
        this.support_hookHolder.specCrossSize1 = endpieceLevel.frontPosition.endpiecePart.barPipeSample.specCrossSize;
      }
    }
  }

  /**
   * 규격 알고리즘: [대각형 지지] 지지 고정핀 <- 대각형 지지 보조 파이프 파이프 샘플
   */
  public algorithmSpec_diagonal_supportFixingPin(): void {
    this.support_supportFixingPin.specCrossSize = this.diagonalSupportAssistPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [대각형 지지] 장볼트 <- 없음
   */
  public algorithmSpec_diagonal_longBolt(): void {}

  /**
   * 규격 알고리즘: [수직형 지지](2차) 파이프 <- 수직형 지지 파이프 샘플
   */
  public algorithmSpec_vertical2_pipe(): void {
    this.supportVertical2_pipe.specs = this.verticalSupportPipe2Sample.specs;
  }

  /**
   * 규격 알고리즘: [수직형 지지](2차) 축 롤러 <- 수직형 지지 파이프 샘플
   */
  public algorithmSpec_vertical2_axisRoller(): void {
    this.support2_axisRoller.specCrossSize = this.verticalSupportPipe2Sample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [대각형 지지](2차) 파이프 <- 대각형 지지 파이프 파이프 샘플
   */
  public algorithmSpec_diagonal2_pipe(): void {
    this.supportDiagonal2_pipe.specs = this.diagonalSupportPipe2Sample.specs;
  }

  /**
   * 규격 알고리즘: [대각형 지지 보조](2차) 파이프 <- 대각형 지지 보조 파이프 파이프 샘플
   */
  public algorithmSpec_diagonalAssist2_pipe(): void {
    this.supportAssist2_pipe.specs = this.diagonalSupportAssistPipe2Sample.specs;
  }

  /**
   * 규격 알고리즘: [대각형 지지 보조 + 마구리 샛기둥](2차) 고리 고정구 <- 형태(마구리 골조), 마구리 샛기둥 파이프 샘플
   */
  public algorithmSpec_diagonal2_hookHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const endpieceLevel: EndpieceLevelIL = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
      // 앞면을 따름
      if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
        this.support2_hookHolder.specPipeType =
          endpieceLevel.frontPosition.cylinderStudSepPart.studBottomPipeSample.specPipeType;
        this.support2_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
        this.support2_hookHolder.specCrossSize1 =
          endpieceLevel.frontPosition.cylinderStudSepPart.studBottomPipeSample.specCrossSize;
      } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
        this.support2_hookHolder.specPipeType = CONST.ITEM_SPEC_VALUE_FREE_TYPE;
        this.support2_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_FLAT_HOLDER;
        this.support2_hookHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
      } else {
        this.support2_hookHolder.specPipeType =
          endpieceLevel.frontPosition.cylinderStudPart.studPipeSample.specPipeType;
        this.support2_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
        this.support2_hookHolder.specCrossSize1 =
          endpieceLevel.frontPosition.cylinderStudPart.studPipeSample.specCrossSize;
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const endpieceLevel: EndpieceLevelVL = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
      // 앞면을 따름
      if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
        this.support2_hookHolder.specPipeType =
          endpieceLevel.frontPosition.endpiecePart.straightBottomPipeSample.specPipeType;
        this.support2_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
        this.support2_hookHolder.specCrossSize1 =
          endpieceLevel.frontPosition.endpiecePart.straightBottomPipeSample.specCrossSize;
      } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
        this.support2_hookHolder.specPipeType = CONST.ITEM_SPEC_VALUE_FREE_TYPE;
        this.support2_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_FLAT_HOLDER;
        this.support2_hookHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
      } else {
        this.support2_hookHolder.specPipeType = endpieceLevel.frontPosition.endpiecePart.barPipeSample.specPipeType;
        this.support2_hookHolder.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
        this.support2_hookHolder.specCrossSize1 = endpieceLevel.frontPosition.endpiecePart.barPipeSample.specCrossSize;
      }
    }
  }

  /**
   * 규격 알고리즘: [대각형 지지](2차) 지지 고정핀 <- 대각형 지지 보조 파이프 파이프 샘플
   */
  public algorithmSpec_diagonal2_supportFixingPin(): void {
    this.support2_supportFixingPin.specCrossSize = this.diagonalSupportAssistPipe2Sample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [대각형 지지](2차) 장볼트 <- 없음
   */
  public algorithmSpec2_diagonal_longBolt(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
