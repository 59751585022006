import { jsonObject } from "typedjson";

import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { WindbreakPositionIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPositionIL";
import { CoverPartIL_Vinyl } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Vinyl";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-02-10
 */
@jsonObject
export class CoverSampleIL_VinylWindbreak extends ItemVinyl {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const vinylPart: CoverPartIL_Vinyl = <CoverPartIL_Vinyl>this.part;

    // 치마 피복
    vinylPart.normalSkirtVinylSample.algorithmSpec_specThickness();
    vinylPart.normalSkirtVinylSample.algorithmSpec_brands();

    /// //////// 외부 ///////////

    // 바람막이
    for (let l: number = 0; l < this.struct.windbreakWorkIL.levelAC.length; l++) {
      for (const windbreakPosition of this.struct.windbreakWorkIL.levelAC[l].positionAC as WindbreakPositionIL[]) {
        windbreakPosition.windbreakPart.algorithmPart();
      }
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <-
   */
  // @override
  public algorithmSpec(): void {}

  /**
   * 규격 알고리즘 : 두께 <- 두께(비닐 샘플)
   */
  public algorithmSpec_specThickness(): void {
    if (this.brands === (<CoverPartIL_Vinyl>this.part).vinylSample.brands) {
      this.specThickness = (<CoverPartIL_Vinyl>this.part).vinylSample.specThickness;
    }
  }

  /**
   * 규격 알고리즘 : 상표 <- 상표(비닐 샘플)
   */
  public algorithmSpec_brands(): void {
    if (this.specThickness === (<CoverPartIL_Vinyl>this.part).vinylSample.specThickness) {
      this.brands = (<CoverPartIL_Vinyl>this.part).vinylSample.brands;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
