import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemMiniSprinklerTubeSet } from "vhows-design/src/object/design/item/list/ItemMiniSprinklerTubeSet";
import { WateringPart_MiniklerFountain } from "vhows-design/src/object/design/watering/watering/WateringPart_MiniklerFountain";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject
export class WateringSample_MiniklerFountainTubeSet extends ItemMiniSprinklerTubeSet {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const miniklerFountainPart: WateringPart_MiniklerFountain = <WateringPart_MiniklerFountain>this.part;
    miniklerFountainPart.algorithmSpec_minikler_miniSprinklerTubeSet();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 미니 스프링클러 샘플
   */
  // @override
  public algorithmSpec(): void {
    if (
      (<WateringPart_MiniklerFountain>this.part).miniSprinklerSample.brandModelName === CONST.ITEM_SPEC_VALUE_SPINNET
    ) {
      this.specConnectionType = CONST.ITEM_SPEC_VALUE_SPINNET_TYPE;
    } else if (
      (<WateringPart_MiniklerFountain>this.part).miniSprinklerSample.brandModelName === CONST.ITEM_SPEC_VALUE_UD
    ) {
      this.specConnectionType = CONST.ITEM_SPEC_VALUE_UD_TYPE;
    } else {
      this.specConnectionType = CONST.ITEM_SPEC_VALUE_UD_TYPE;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
