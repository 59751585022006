import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { ColumnPartVL_Beam } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Beam";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-08
 */
@jsonObject
export class ColumnSampleVL_BeamPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismBeamPart: ColumnPartVL_Beam = <ColumnPartVL_Beam>this.part;
    prismBeamPart.algorithmSpec_beam_pipe();
    prismBeamPart.algorithmSpec_beamAndBeam_connectionPin();
    prismBeamPart.algorithmSpec_beamAndColumn_tClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    /// //////// 외부 ///////////

    // 지붕 골조
    const roofPosition: RoofPositionVL = this.struct.roofWorkVL.level1.roofPosition;
    roofPosition.rafterPart.algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce();
    roofPosition.rafterPart.algorithmSpec_rafterAndBeamEdge_firstLevelSoleSconce();

    // 마구리 골조
    for (const endpieceLevel of this.struct.endpieceWorkVL.levelAC as EndpieceLevelVL[]) {
      // 모든 중수
      for (const endpiecePosition of endpieceLevel.positionAC as EndpiecePositionVL[]) {
        endpiecePosition.endpiecePart.straightBottomPipeSample.algorithmSpec_specLength();
      }
    }

    // 방풍벽 골조
    const wingLevel1: WingLevelVL = this.struct.wingWorkVL.level1;
    wingLevel1.wingPosition.wingRafterPart.algorithmSpec_wingRafterFirstAndBeam_outerWallClamp();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 내부 기둥 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = (<ColumnPositionVL>this.position).prismColumnPart.columnPipeSample.specsList;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
