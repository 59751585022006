import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ColumnPartVL_Column } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Column";
import { ColumnPartVL_Beam } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Beam";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { ColumnPartVL_Middle } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Middle";

/**
 * @author 오은헤
 * @copyright RUNean Inc.
 * @date 2022-04-07
 */
@jsonObject
export class ColumnSampleVL_SmallColumnPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////
    const prismColumnPart: ColumnPartVL_Column = <ColumnPartVL_Column>this.part;
    prismColumnPart.algorithmSpec_smallColumn_pipe();

    // 다른 파트
    const prismBeamPart: ColumnPartVL_Beam = (<ColumnPositionVL>this.position).prismBeamPart;
    prismBeamPart.algorithmSpec_beamAndSmallColumn_tClamp();

    const prismMiddlePart: ColumnPartVL_Middle = (<ColumnPositionVL>this.position).prismMiddlePart;
    prismMiddlePart.middlePipeSample.algorithmSpec();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘
   */
  // @override
  public algorithmSpec(): void {
    this.specLength = this.basicLevel.width;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
