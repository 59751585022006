import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { RoofPartVL_Straight } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Straight";
import { RoofPartVL_RoofBracing } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_RoofBracing";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { RoofPartVL_Rafter } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Rafter";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject({
  knownTypes: [RoofPartVL_Rafter, RoofPartVL_Straight, RoofPartVL_RoofBracing],
})
export class RoofPositionVL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public rafterPart: RoofPartVL_Rafter;
  public straightPart: RoofPartVL_Straight;
  public roofBracingPart: RoofPartVL_RoofBracing;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    // 물받이
    // (<GutterLevelIL> this.struct.gutterWorkIL.levelAC[this.level.index]).gutterPosition.algorithm_selectedByRoof();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.rafterPart = new RoofPartVL_Rafter();
    this.straightPart = new RoofPartVL_Straight();
    this.roofBracingPart = new RoofPartVL_RoofBracing();

    this.partAC = [this.rafterPart, this.straightPart, this.roofBracingPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.rafterPart = <RoofPartVL_Rafter>this.partAC[0];
    this.straightPart = <RoofPartVL_Straight>this.partAC[1];
    this.roofBracingPart = <RoofPartVL_RoofBracing>this.partAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    if (this.level.index === 0) {
      // 서까래
      this.rafterPart.setDefaultData(
        CONST.INDEX_IL_ROOF_RAFTER,
        true,
        false,
        true,
        CONST.LB_ROOF_RAFTER,
        "",
        2,
        0.8,
        false,
      );

      // 가로대
      this.straightPart.setDefaultData(
        CONST.INDEX_IL_ROOF_STRAIGHT,
        true,
        false,
        true,
        CONST.LB_ROOF_STRAIGHT,
        "",
        6.0,
        false,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        3.0,
      );

      // 지붕 브레싱
      this.roofBracingPart.setDefaultData(
        CONST.INDEX_IL_ROOF_BRACING,
        true,
        false,
        true,
        CONST.LB_ROOF_BRACING,
        "",
        8.0,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_ROOF_TYPE_PEACH) {
      this.rafterPart.selected = true;
      this.rafterPart.visible = true;
      this.straightPart.selected = true;
      this.straightPart.visible = true;
      this.roofBracingPart.selected = true;
      this.roofBracingPart.visible = true;
    } else if (this.type === CONST.LB_ROOF_TYPE_ROUND) {
      this.rafterPart.selected = true;
      this.rafterPart.visible = true;
      this.straightPart.selected = true;
      this.straightPart.visible = true;
      this.roofBracingPart.selected = true;
      this.roofBracingPart.visible = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
