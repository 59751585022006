import { CONST } from "vhows-design/src/common/constant/CONST";

/**
 * 피복 값을 지닐 임시 객체 (서버에서는 필요없음)
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-07-02
 */
export class CoverTemp {
  //--------------------------------------------------------------------------
  //
  // Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 용도 */
  public purpose: string;

  /** 폭 */
  public width: number;

  /** 길이  */
  public length: number;

  /** 동수별 길이  */
  public lengthAC: number[];

  /** 수량 (한동기준) */
  public quantity: number = 1;

  /** 앞면 포함형 */
  public frontIncluded: boolean;

  /** 뒷면 포함형 */
  public backIncluded: boolean;

  /** 치마 포함형 */
  public skirtIncluded: boolean;

  /** 바람막이 포함형 */
  public windbreakIncluded: boolean;

  /** 방풍벽 포함형 */
  public wingSelected: boolean;

  /** 처마 포함형 */
  public eaveSelected: boolean;

  /** 출입문 수량 */
  public gateQuantity: number;

  /** 끈 형태 */
  public specStringType: string = CONST.ITEM_SPEC_VALUE_STRING_NONE;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {}

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 깊은 복사 (Stored Data)
   * @param origin 원본 객체
   */
  public deepCopy(origin: CoverTemp): void {
    this.purpose = origin.purpose;
    this.width = origin.width;
    this.length = origin.length;
    if (origin.lengthAC != null) {
      this.lengthAC = [...origin.lengthAC];
    }
    this.quantity = origin.quantity;
    this.frontIncluded = origin.frontIncluded;
    this.backIncluded = origin.backIncluded;
    this.skirtIncluded = origin.skirtIncluded;
    this.windbreakIncluded = origin.windbreakIncluded;
    this.wingSelected = origin.wingSelected;
    this.eaveSelected = origin.eaveSelected;
    this.gateQuantity = origin.gateQuantity;
    this.specStringType = origin.specStringType;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
