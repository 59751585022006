import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDoor } from "vhows-design/src/object/design/item/list/ItemDoor";
import { WindowLevel } from "vhows-design/src/object/design/other/window/WindowLevel";
import { WindowPart_Hinged } from "vhows-design/src/object/design/other/window/WindowPart_Hinged";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-04
 */
@jsonObject
export class WindowSample_HingedDoor extends ItemDoor {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const hingedPart: WindowPart_Hinged = <WindowPart_Hinged>this.part;
    hingedPart.algorithmSpec_hinged_door();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevel>this.level).rightPosition.hingedPart.hingedDoorSample.specs = this.specs;
      (<WindowLevel>this.level).frontPosition.hingedPart.hingedDoorSample.specs = this.specs;
      (<WindowLevel>this.level).backPosition.hingedPart.hingedDoorSample.specs = this.specs;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevel>this.level).backPosition.hingedPart.hingedDoorSample.specs = this.specs;
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 창문 폭(파트), 창문 높이(파트)
   */
  // @override
  public algorithmSpec(): void {
    this.specWidth = (<WindowPart_Hinged>this.part).frameHeight;
    this.specLength = (<WindowPart_Hinged>this.part).frameWidth;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
