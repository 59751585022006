import { CONST } from "vhows-design/src/common/constant/CONST";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-01-07
 */
export class CommonUtil {
  //--------------------------------------------------------------------------
  //
  // 환경
  //
  //--------------------------------------------------------------------------

  /** 8081포트 로컬 개발 서버인지 여부 */
  public static isDevServer8081(): boolean {
    return window.location.href.indexOf(CONST.DEV_SERVER_DEV_URL_8081) !== -1;
  }

  /** 크롬 브라우저인지 여부 */
  public static isChromeBrowser(): boolean {
    const browser = navigator.userAgent.toLowerCase();
    return browser.indexOf("chrome") !== -1;
  }

  /** IE 브라우저인지 여부 */
  public static isIEBrowser(): boolean {
    const browser = navigator.userAgent.toLowerCase();
    return (navigator.appName === "Netscape" && browser.indexOf("trident") !== -1) || browser.indexOf("msie") !== -1;
  }

  //--------------------------------------------------------------------------
  //
  // 객체 및 배열
  //
  //--------------------------------------------------------------------------

  /**
   * 배열에서 fromIndex의 객체를 toIndex로 옮기기
   * @param array: any[] 원본 배열
   * @param fromIndex: number 기존 인덱스
   * @param toIndex: number 바꿀 인덱스
   */
  public static changeArrayIndex(array: any[], fromIndex: number, toIndex: number): void {
    const object: any[] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, object[0]);
  }

  /**
   * index 필드가 있는 배열의 index 값을 실제 순서에 맞게 재할당
   * @param array 배열
   */
  public static reindexAC(array: any[]): void {
    array.forEach((it, index) => {
      it.index = index;
    });
  }

  //--------------------------------------------------------------------------
  //
  // 수치 연산
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 반올림, 올림, 내림 등
  //----------------------------------

  /**
   * 부동소수점 연산 오류 수정</br>
   * 소수점 places+1번째 자릿수에서 반올림
   * @param input: number
   * @param places: number 부동소수점을 교정할 자릿수로 양수만 지정해야함
   */
  public static fixFloat(input: number, places: number = 3): number {
    return Math.round(input * Math.pow(10, places)) / Math.pow(10, places);
  }

  /**
   * 특정 자릿수에서 반올림/올림/내림 선택 처리 <br/>
   * places == 양수 : 소수점 places+1 자리에서 반올림/올림/내림 <br/>
   * places == 0 : 소수점 첫번째 자리에서 반올림/올림/내림 <br/>
   * places == 음수: places 자리에서 반올림/올림/내림
   * @param input: number
   * @param places: number 자릿수
   * @param method: string 반올림/올림/내림 중 선택
   */
  public static roundWhat(input: number, places: number = 0, method: string = CONST.LB_FRACTION_TYPE_ROUND): number {
    let output: number = 0;
    if (method === CONST.LB_FRACTION_TYPE_ROUND) {
      // 반올림
      output = Math.round(input * Math.pow(10, places)) / Math.pow(10, places);
    } else if (method === CONST.LB_FRACTION_TYPE_ROUND_UP) {
      // 올림
      output = Math.ceil(input * Math.pow(10, places)) / Math.pow(10, places);
    } else if (method === CONST.LB_FRACTION_TYPE_ROUND_DOWN) {
      // 내림
      output = Math.floor(input * Math.pow(10, places)) / Math.pow(10, places);
    }
    return CommonUtil.fixFloat(output);
  }

  /**
   * 특정 자릿수에서 반올림 <br/>
   * places == 양수 : 소수점 places+1 자리에서 반올림 <br/>
   * places == 0 : 소수점 첫번째 자리에서 반올림 - Math.round()와 동일 <br/>
   * places == 음수: places 자리에서 반올림
   * @param input: number
   * @param places: number 자릿수
   */
  public static round(input: number, places: number = 0): number {
    return CommonUtil.fixFloat(Math.round(input * Math.pow(10, places)) / Math.pow(10, places));
  }

  /**
   * 특정 자릿수에서 올림 <br/>
   * places == 양수 : 소수점 places+1 자리에서 올림 <br/>
   * places == 0 : 소수점 첫번째 자리에서 올림 - Math.ceil()과 동일 <br/>
   * places == 음수: places 자리에서 올림
   * @param input: number
   * @param places: number 자릿수
   */
  public static roundUp(input: number, places: number = 0): number {
    return CommonUtil.fixFloat(Math.ceil(input * Math.pow(10, places)) / Math.pow(10, places));
  }

  /**
   * 특정 자릿수에서 내림 <br/>
   * places == 양수 : 소수점 places+1 자리에서 내림 <br/>
   * places == 0 : 소수점 첫번째 자리에서 내림 - Math.floor()와 동일 <br/>
   * places == 음수: places 자리에서 내림
   * @param input: number
   * @param places: number 자릿수
   */
  public static roundDown(input: number, places: number = 0): number {
    return CommonUtil.fixFloat(Math.floor(input * Math.pow(10, places)) / Math.pow(10, places));
  }

  /**
   * multiple의 배수 단위로 반올림 <br/>
   * 예를들어 roundX(12, 5) = 10, roundX(10.3, 0.5) = 10.5
   * @param input: number
   * @param multiple: number 배수
   */
  public static roundX(input: number, multiple: number = 0): number {
    const temp: number = CommonUtil.fixFloat(input / multiple);
    return CommonUtil.fixFloat(Math.round(temp) * multiple);
  }

  /**
   * multiple의 배수 단위로 올림 <br/>
   * 예를들어 roundUpX(12, 5) = 15, roundUpX(10.3, 0.5) = 10.5
   * @param input: number
   * @param multiple: number 배수
   */
  public static roundUpX(input: number, multiple: number = 0): number {
    const temp: number = CommonUtil.fixFloat(input / multiple);
    return CommonUtil.fixFloat(Math.ceil(temp) * multiple);
  }

  /**
   * multiple의 배수 단위로 내림 <br/>
   * 예를들어 roundDownX(12, 5) = 10, roundDownX(10.3, 0.5) = 10.0
   * @param input: number
   * @param multiple: number 배수
   */
  public static roundDownX(input: number, multiple: number = 0): number {
    const temp: number = CommonUtil.fixFloat(input / multiple);
    return CommonUtil.fixFloat(Math.floor(temp) * multiple);
  }

  //----------------------------------
  // 단위 변환
  //----------------------------------

  /**
   * m -> cm 변환
   */
  public static convertM2CM(m: number): number {
    let cm: number;
    cm = m * 100;

    return cm;
  }

  /**
   * cm -> m 변환
   */
  public static convertCM2M(cm: number): number {
    let m: number;
    m = CommonUtil.fixFloat(cm * 0.01);

    return m;
  }

  /**
   * m -> mm 변환
   */
  public static convertM2MM(m: number): number {
    let mm: number;
    mm = m * 1000;

    return mm;
  }

  /**
   * mm -> m 변환
   */
  public static convertMM2M(mm: number): number {
    let m: number;
    m = CommonUtil.fixFloat(mm * 0.001);

    return m;
  }

  /**
   * cm -> mm 변환
   */
  public static convertCM2MM(cm: number): number {
    let mm: number;
    mm = cm * 10;

    return mm;
  }

  /**
   * mm -> cm 변환
   */
  public static convertMM2CM(mm: number): number {
    let cm: number;
    cm = CommonUtil.fixFloat(mm / 10.0);

    return cm;
  }

  /**
   * 편 -> 타 변환: 1/2배
   */
  public static convertUnfold2Fold(unfold: number): number {
    let fold: number;
    fold = CommonUtil.fixFloat(unfold / 2.0);

    return fold;
  }

  /**
   * 타 -> 편 변환: 2배
   */
  public static convertFold2Unfold(fold: number): number {
    let unfold: number;
    unfold = fold * 2.0;

    return unfold;
  }

  /**
   * 퍼센트 -> 비율 변환: 1/100배
   */
  public static convertPercent2Rate(percent: number): number {
    let rate: number;
    rate = percent * 0.01;

    return rate;
  }

  /**
   * 비율 -> 퍼센트 변환: 100배
   */
  public static convertRate2Percent(rate: number): number {
    let percent: number;
    percent = rate * 100;

    return percent;
  }

  //----------------------------------
  // 공급대가, 공급가액, 부가세 등
  //----------------------------------

  /**
   * 공급대가 계산하기 by 공급가액
   * - 음수는 올림/내림 처리 전에 양수로 변환
   * @param supply
   */
  public static calcPriceBySupply(supply: number): number {
    let value: number = supply;
    if (supply < 0) {
      value = Math.abs(supply);
      return -Math.round(value * 1.1);
    }
    return Math.round(value * 1.1);
  }

  /**
   * 부가세 계산하기 by 공급가액
   * - 음수는 올림/내림 처리 전에 양수로 변환
   * @param supply
   */
  public static calcVatBySupply(supply: number): number {
    let value: number = supply;
    if (supply < 0) {
      value = Math.abs(supply);
      return -Math.round(value / 10);
    }
    return Math.round(value / 10);
  }

  /**
   * 공급가액 계산하기 by 공급대가
   * - 음수는 올림/내림 처리 전에 양수로 변환
   * @param price
   */
  public static calcSupplyByPrice(price: number): number {
    let value: number = price;
    if (price < 0) {
      value = Math.abs(price);
      return -Math.round(value / 1.1);
    }
    return Math.round(value / 1.1);
  }

  /**
   * 부가세 계산하기 by 공급대가
   * - 음수는 올림/내림 처리 전에 양수로 변환
   * @param price
   */
  public static calcVatByPrice(price: number): number {
    let value: number = price;
    if (price < 0) {
      value = Math.abs(price);
      return -Math.round(value / 11);
    }
    return Math.round(value / 11);
  }

  //--------------------------------------------------------------------------
  //
  // 문자 연산
  //
  //--------------------------------------------------------------------------

  /**
   * 숫자 포메팅하기: 쉼표 표기
   * @param value: number 포멧팅할 숫자
   * @return 변환된 문자열
   */
  public static formatNumber(value: number, fractionDigits: number = 0): string {
    let convertedValue: string;
    convertedValue = new Intl.NumberFormat("ko-KR", { maximumFractionDigits: fractionDigits }).format(value);
    return convertedValue;
  }

  /**
   * 숫자를 통화 포메팅하기: 통화 및 쉼표 표기 (1,000원)
   * @param value: number 포멧팅할 숫자
   * @return 변환된 문자열
   */
  public static formatCurrencyWon(value: number): string {
    let convertedValue: string;
    convertedValue = new Intl.NumberFormat("ko-KR", { maximumFractionDigits: 0 }).format(value);
    convertedValue += "원";
    return convertedValue;
  }

  /**
   * 숫자를 통화 포메팅하기: 통화 및 쉼표 표기 (\1,000)
   * @param value: number 포멧팅할 숫자
   * @return 변환된 문자열
   */
  public static formatCurrencyKRW(value: number): string {
    let convertedValue: string;
    convertedValue = new Intl.NumberFormat("ko-KR", {
      style: "currency",
      currency: "KRW",
    }).format(value);
    return convertedValue;
  }

  /**
   * 비율을 퍼센트로 포멧팅하기: 비율을 퍼센트로 변환 및 기호 추가
   * @param rate: number 비율
   * @param fractionalDigits: number=0 소수점 몇번째까지 표기할지 (주의:올림처리함)
   * @param isPercentSymbol: boolean=false 퍼센트 기호(%)를 표기할지
   */
  public static formattingPercent(
    rate: number,
    fractionalDigits: number = 1,
    isPercentSymbol: boolean = false,
  ): string {
    let percentNumber: number;
    percentNumber = CommonUtil.roundUp(rate * 100, fractionalDigits);

    let percent: string = percentNumber.toString();
    if (isPercentSymbol === true) {
      percent += "%"; // 퍼센트 기호
    }

    return percent;
  }

  /**
   * 문자열 찾아서 모두 대체하기 <br/>
   * - replace()함수에서 문자열 패턴이 한번만 바꿔주는데 반하여, 원본 문자열 전체를 바꿔줌 <br/>
   * - replace()함수에서 정규표현식 패턴g(Global)를 사용하면 모든 문자열을 대체할 수있음(추천 방법)
   * @param source 원본 문자열
   * @param find 찾을 문자열
   * @param replace 대체할 문자열
   * @return 대체된 문자열
   */
  public static replaceAll(source: string, find: string, replace: string): string {
    return source.replace(new RegExp(find, "g"), replace);
  }

  /**
   * 문자열에 구분자 추가하기 <br/>
   * 문장의 맨 앞에는 추가하지 않는다
   * @param string 문자열로 null값을 넘기면 안됨
   * @param delimiter 구분자
   */
  public static addDelimiter(string: string, delimiter: string = ", "): string {
    if (string !== "") {
      return string + delimiter;
    }
    return string;
  }

  /**
   * 날짜 포매팅하기 년 월 일 <br/>
   * @param date Date객체
   * @return 포매팅된 date
   */
  public static dateFormat(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : `0${month}`;
    day = day >= 10 ? day : `0${day}`;
    hour = hour >= 10 ? hour : `0${hour}`;
    minute = minute >= 10 ? minute : `0${minute}`;
    second = second >= 10 ? second : `0${second}`;

    return `${date.getFullYear()}년 ${month}월 ${day}일`;
  }

  /**
   * 날짜 포매팅하기 년-월-일 시:분:초 <br/>
   * @param date Date객체
   * @return 포매팅된 date
   */
  public static dateFormatWithMs(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : `0${month}`;
    day = day >= 10 ? day : `0${day}`;
    hour = hour >= 10 ? hour : `0${hour}`;
    minute = minute >= 10 ? minute : `0${minute}`;
    second = second >= 10 ? second : `0${second}`;

    return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  //--------------------------------------------------------------------------
  //
  // 데이터 타입 변환
  //
  //--------------------------------------------------------------------------

  /**
   * 불리언 값 -> 정수 값 변환
   * - 0 <- false
   * - 1 <- true
   */
  public static convertBool2Int(bool: boolean): number {
    if (bool === true) {
      return 1;
    }
    return 0;
  }

  /**
   * 정수 값 -> 불리언 값 변환
   * - false <- 0
   * - true <- 그외 모든 숫자
   */
  public static convertInt2Bool(int: number): boolean {
    if (int === 0) {
      return false;
    }
    return true;
  }

  /**
   * 문자 값 -> 불리언 값 변환
   * - false <- null, undefined, '0', 'false'
   * - true <- 그외 모든 값
   */
  public static convertStr2Bool(str: string): boolean {
    if (str && (str === "0" || str === "false")) {
      return false;
    }
    if (str) {
      return true;
    }
    return false;
  }

  //--------------------------------------------------------------------------
  //
  // UI 관련
  //
  //--------------------------------------------------------------------------

  /**
   * 목록의 시작 Row 인덱스 구하기
   * @param page 페이지
   * @param itemsPerPage 페이지당 보여질 항목수
   * @param startIndex 시작 인덱스
   */
  public static getStartRowIndex(page: number, itemsPerPage: number, startIndex: number): number {
    return (page - 1) * itemsPerPage + startIndex;
  }

  /**
   * DxDataGrid 요청 파라미터 만들기
   */
  public static makeDxDataGridParams(loadOptions: any): string {
    let params = "";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "filter",
      "totalSummary",
      "group",
      "groupSummary",
    ].forEach((i: any) => {
      if (i in loadOptions && loadOptions[i] !== undefined && loadOptions[i] !== null && loadOptions[i] !== "") {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);
    // 파라미터 특수문자 인코딩
    params = params
      .replace(/\\/gi, encodeURIComponent("\\"))
      .replace(/\[/gi, encodeURIComponent("["))
      .replace(/\]/gi, encodeURIComponent("]"))
      .replace(/\{/gi, encodeURIComponent("{ "))
      .replace(/\}/gi, encodeURIComponent("}"));
    return params;
  }

  /**
   * BTable 요청 파라미터 만들기
   */
  public static makeBTableParams(ctx: any): string {
    if (ctx === null) {
      return "";
    }
    return `page=${ctx.currentPage}&perPage=${ctx.perPage}&filter=${ctx.filter ? ctx.filter : ""}&sortBy=${
      ctx.sortBy
    }&sortDesc=${ctx.sortDesc}`;
  }

  /**
   * TreeView Data restore
   */
  public static restoreDataTree(data: any[], viewData?: any[]) {
    if (viewData) {
      const clone = JSON.parse(JSON.stringify(viewData));
      clone.forEach(value => {
        value.items.forEach(node => {
          if (data && data.includes(node.value)) node.selected = true;
        });
      });

      return clone;
    }
    return viewData;
  }

  //--------------------------------------------------------------------------
  //
  // 버전 비교
  //
  //--------------------------------------------------------------------------

  /**
   * 버전 비교
   * @param curVersion 현재버전
   * @param tarVersion 비교하고자하는 버전
   * @return 1: 업데이트 필요, 0: 같은버전, -1: 비교하고자하는 버전이 더 낮음
   */
  public static compareVersion(curVersion: string, tarVersion: string): number {
    let currentVersion: any;
    let curMajor: number = 0;
    let curMinor: number = 0;
    let curPatch: number = 0;

    let targetVersion: any;
    let tarMajor: number = 0;
    let tarMinor: number = 0;
    let tarPatch: number = 0;

    let result: number = 0;

    let tempCur = curVersion.slice(1);
    currentVersion = tempCur.split(".");
    curMajor = currentVersion[0];
    curMinor = currentVersion[1];
    curPatch = currentVersion[2];

    if (tarVersion[tarVersion.length - 1] === "하") {
      // db 버전에'이하'라고표시되어있으면 무조건 업데이트
      result = 1;
    } else {
      let tempTar = tarVersion.slice(1);
      targetVersion = tempTar.split(".");
      tarMajor = targetVersion[0];
      tarMinor = targetVersion[1];
      tarPatch = targetVersion[2];
    }

    if (tarVersion[tarVersion.length - 1] !== "하") {
      if (curMajor > tarMajor) {
        // 업데이트 진행
        result = 1;
      } else if (curMajor === tarMajor) {
        // 마이너 비교
        if (curMinor > tarMinor) {
          // 업데이트 진행
          result = 1;
        } else if (curMinor === tarMinor) {
          // 패치 비교
          if (curPatch > tarPatch) {
            // 업데이트 진행
            result = 1;
          } else if (curPatch === tarPatch) {
            // 스킵 (두개의 버전이 동일)
            result = 0;
          } else {
            // 패치 버전이 더 낮음
            result = -1;
          }
        } else {
          // 마이너 버전이 더 낮음
          result = -1;
        }
      } else {
        // 현재 버전이 더 낮음
        result = -1;
      }
    }

    return result;
  }

  public static getImageUrl(path) {
    return new URL(path, import.meta.url).href;
  }
}
