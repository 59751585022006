import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { MainpipePart_PeMain1 } from "vhows-design/src/object/design/watering/mainpipe/MainpipePart_PeMain1";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject
export class MainpipeSample_Main1PePipe extends ItemWateringPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const peMain1Part: MainpipePart_PeMain1 = <MainpipePart_PeMain1>this.part;
    peMain1Part.algorithmSpec_main1_pePipe();
    peMain1Part.algorithmSpec_waterTankOut_nipple();
    peMain1Part.algorithmSpec_waterTankOut_femaleBallValve();
    peMain1Part.algorithmSpec_waterTankOut_elbow();
    peMain1Part.algorithmSpec_waterTankOut_valveSocket();
    peMain1Part.algorithmSpec_waterPumpInOut_bushing();
    peMain1Part.algorithmSpec_waterPumpInOut_maleElbowSocket();
    peMain1Part.algorithmSpec_waterPump_valveSocket();
    peMain1Part.algorithmSpec_waterFilterIn_bushing();
    peMain1Part.waterTankSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    (<MainpipePosition>this.position).peMain2Part.main2CenterPePipeSample.algorithmSpec();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 펌프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specDiameter = (<MainpipePart_PeMain1>this.part).waterPumpSample.brandInOutDiameter;
    // 하드코딩
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.specLength = 50;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.specLength = 6;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
