import { jsonObject } from "typedjson";

import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { WateringPart_MiniklerFountain } from "vhows-design/src/object/design/watering/watering/WateringPart_MiniklerFountain";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject
export class WateringSample_MiniklerFountainPePipe extends ItemWateringPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const miniklerFountainPart: WateringPart_MiniklerFountain = <WateringPart_MiniklerFountain>this.part;
    miniklerFountainPart.algorithmSpec_miniklerPipe_pePipe();
    miniklerFountainPart.algorithmSpec_miniklerPipe_connectSocket();
    miniklerFountainPart.algorithmSpec_miniklerPipe_pePipeEnd();
    miniklerFountainPart.algorithmSpec_miniklerPipe_valveSocket();
    miniklerFountainPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    miniklerFountainPart.algorithmSpec_miniklerHangerHanger_pePipeHanger();
    miniklerFountainPart.algorithmSpec_miniklerHangerHanger_dripStartSaddle();
    miniklerFountainPart.algorithmSpec_sub_differentConnectSocket();
    miniklerFountainPart.subPePipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
