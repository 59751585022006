import { jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { CategoryModel } from "vhows-design/src/object/item/category/CategoryModel";
import { ProductCompanyModel } from "vhows-design/src/object/item/product/ProductCompanyModel";
import { SpecModel } from "vhows-design/src/object/item/spec/SpecModel";
import { BrandModel } from "vhows-design/src/object/item/brand/BrandModel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-26
 */
@jsonObject({
  knownTypes: [CategoryModel],
})
export class ProductModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 분류 모델 */
  @jsonMember(CategoryModel)
  public categoryModel: CategoryModel = null;

  /** 조직별 상품 모델 배열 */
  public productCompanyModelAC: ProductCompanyModel[] = null;

  /** 규격 모델 배열 */
  public specModelAC: SpecModel[] = null;

  /** 상표 모델 배열 */
  public brandModelAC: BrandModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 상품명 */
  @jsonMember(String)
  public label: string = null;
  /** 상품 별칭 */
  @jsonMember(String)
  public productAlias: string = null;
  /** 상품 아이디 */
  @jsonMember(String)
  public productId: string = null;
  /** 상품 설명 */
  @jsonMember(String)
  public productDesc: string = null;
  /** 가용 여부 - 설계에서 사용할지 여부 */
  @jsonMember(Boolean)
  public enabled: boolean = null;
  /** 주요 상품 여부 - 다른 상품에 비해 중요하게 보여줘야하는 상품 */
  @jsonMember(Boolean)
  public isMajor: boolean = null;
  /** 시스템 상품 여부 - 시스템만이 추가/삭제하는 상품 */
  @jsonMember(Boolean)
  public isSystemOnly: boolean = null;
  /** 임의 상품 여부 - 상품명 자유수정, 규격 자유수정, 상표 없음, 임의 단가 활용 상품 */
  @jsonMember(Boolean)
  public isCustomItem: boolean = null;
  /** 할인 상품 여부 - 할인 개념을 위한 금액이 마이너스인 상품 */
  @jsonMember(Boolean)
  public isDiscountItem: boolean = false;

  /** 비용 설명 */
  @jsonMember(String)
  public priceDesc: string = null;
  /** 비용 형태 */
  @jsonMember(String)
  public costType: string = null;
  /** 과세 형태 */
  @jsonMember(String)
  public taxType: string = null;
  /** 단위기호 */
  @jsonMember(String)
  public unitSymbol: string = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param enabled 사용 가능 여부
   * @param productId 상품 아이디
   * @param productName 상품명
   * @param productAlias 상품 별칭
   * @param productDesc 상품 설명
   * @param priceDesc 비용 설명
   * @param isMajor 주요 상품 여부
   * @param isSystemOnly 시스템 상품 여부
   * @param isCustomItem 임의 상품 여부
   * @param unitSymbol 단위기호
   * @param categoryModel 분류 모델
   * @param specModelAC 규격 모델 배열
   * @param brandModelAC 상표 모델 배열
   * @param productCompanyModelAC 조직별 상품 모델 배열
   */
  constructor(
    enabled: boolean = true,
    productId: string = "",
    productName: string = "",
    productAlias: string = "",
    productDesc: string = "",
    priceDesc: string = "",
    isMajor: boolean = false,
    isSystemOnly: boolean = false,
    isCustomItem: boolean = false,
    unitSymbol: string = "",
    categoryModel: CategoryModel = null,
    specModelAC: SpecModel[] = null,
    brandModelAC: BrandModel[] = null,
    productCompanyModelAC: ProductCompanyModel[] = null,
  ) {
    super();

    this.enabled = enabled;
    this.productId = productId;
    this.label = productName;
    this.productAlias = productAlias;
    this.productDesc = productDesc;
    this.priceDesc = priceDesc;
    this.isMajor = isMajor;
    this.isSystemOnly = isSystemOnly;
    this.isCustomItem = isCustomItem;
    this.unitSymbol = unitSymbol;
    if (categoryModel) {
      this.categoryModel = categoryModel;
    } else {
      this.categoryModel = new CategoryModel();
    }
    this.specModelAC = specModelAC;
    this.brandModelAC = brandModelAC;
    this.productCompanyModelAC = productCompanyModelAC;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 품목 모델
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
