import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SwitcherPart_Power } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Power";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-06-23
 */
@jsonObject
export class SwitcherSampleIL_RafterSupPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    // const supportPart: SwitcherPart_Support = <SwitcherPart_Support>this.part;
    // supportPart.algorithmSpec_rafter_pipe();
    // supportPart.algorithmSpec_rafter_axisRoller();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const power1Part: SwitcherPart_Power = (<SwitcherPosition>this.position).power1Part;
    power1Part.powerSwitcherSample.algorithmSpec();

    const power2Part: SwitcherPart_Power = (<SwitcherPosition>this.position).power2Part;
    power2Part.powerSwitcherSample.algorithmSpec();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 서까래 파이프 샘플, 최종 서까래 길이
   */
  // @override
  public algorithmSpec(): void {
    const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;
    // 하드코딩
    if (
      trunkPosition.rafterPart.rafterPipeSample.specPipeCrossSize === CONST.ITEM_SPEC_VALUE_25_MM_PIPE ||
      trunkPosition.rafterPart.rafterPipeSample.specPipeCrossSize === CONST.ITEM_SPEC_VALUE_32_MM_PIPE
    ) {
      this.specPipeCrossSize = trunkPosition.rafterPart.rafterPipeSample.specPipeCrossSize;
      this.specThickness = trunkPosition.rafterPart.rafterPipeSample.specThickness;
    } else if (trunkPosition.rafterPart.rafterPipeSample.specPipeCrossSize === CONST.ITEM_SPEC_VALUE_22_MM_PIPE) {
      // 22.2mm인 경우
      this.specPipeCrossSize = CONST.ITEM_SPEC_VALUE_25_MM_PIPE;
      this.specThickness = CONST.ITEM_SPEC_VALUE_1_5_T;
    } else {
      // 31.8mm보다 큰 경우
      this.specPipeCrossSize = CONST.ITEM_SPEC_VALUE_32_MM_PIPE;
      this.specThickness = CONST.ITEM_SPEC_VALUE_1_5_T;
    }
    this.specLength = trunkPosition.rafterPart.rafterTotalLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
