import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemScreen } from "vhows-design/src/object/design/item/list/ItemScreen";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPartIL } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL";
import { CoverSampleIL_Screen } from "vhows-design/src/object/design/cover/cover/il/CoverSampleIL_Screen";
/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-12-18
 */
@jsonObject({
  knownTypes: [CoverSampleIL_Screen],
})
export class CoverPartIL_Screen extends CoverPartIL {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public screenSample: CoverSampleIL_Screen; // 스크린 샘플

  // 아이템
  protected item: ItemScreen; // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.screenSample = new CoverSampleIL_Screen();

    this.sampleAC = [this.screenSample];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.screenSample = <CoverSampleIL_Screen>this.sampleAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.screenSample.setDefaultData(
        0,
        CONST.ITEM_ID_SCREEN,
        CONST.ITEM_NAME_SCREEN,
        "지붕+측면",
        "알루미늄, 6m, 56m, 끈 없음, 1회",
        "부전, BJ100U, 99%, 45%, 4m간격",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    //----------------------------------
    // 품목
    // 피복객체의 값을 실제 품목에 반영
    //----------------------------------

    // 측면 및 지붕
    if (this.coverPosition.scopeSelectedSide === true || this.coverPosition.scopeSelectedRoof === true) {
      // 2동이상
      if (this.basic.buildingNumber >= 2) {
        // 가변길이 여부
        if (this.basic.isVariableLength === true) {
          // 동수만큼 품목 생성
          for (let i: number = 0; i < this.basic.buildingNumber; i++) {
            // 양끝2동
            if (i === 0 || i === this.basic.buildingNumber - 1) {
              // 양끝동 지붕(+측면)
              if (this.coverEdgeRoof != null) {
                this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverEdgeRoof.purpose}`;
                this.item.specWidth = this.coverEdgeRoof.width;
                this.item.specLength = this.coverEdgeRoof.length;
                this.item.designQuantity = this.coverEdgeRoof.quantity;

                this.itemAC.push(this.item);
              }

              // 양끝동 지붕(+측면) - 비대칭 천창(짧은쪽)
              if (this.coverEdgeSkyShort != null) {
                this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverEdgeSkyShort.purpose}`;
                this.item.specWidth = this.coverEdgeSkyShort.width;
                this.item.specLength = this.coverEdgeSkyShort.lengthAC[i];
                this.item.designQuantity = this.coverEdgeSkyShort.quantity;

                this.itemAC.push(this.item);
              }

              // 양끝동 측면
              if (this.coverEdgeSide != null) {
                this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverEdgeSide.purpose}`;
                this.item.specWidth = this.coverEdgeSide.width;
                this.item.specLength = this.coverEdgeSide.lengthAC[i];
                this.item.designQuantity = this.coverEdgeSide.quantity;

                this.itemAC.push(this.item);
              }
            } else {
              // 중앙동 지붕
              if (this.coverCenterRoof != null) {
                this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverCenterRoof.purpose}`;
                this.item.specWidth = this.coverCenterRoof.width;
                this.item.specLength = this.coverCenterRoof.lengthAC[i];
                this.item.designQuantity = this.coverCenterRoof.quantity;

                this.itemAC.push(this.item);
              }

              // 중앙동 지붕 - 비대칭 천창(짧은쪽)
              if (this.coverCenterSkyShort != null) {
                this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverCenterSkyShort.purpose}`;
                this.item.specWidth = this.coverCenterSkyShort.width;
                this.item.specLength = this.coverCenterSkyShort.lengthAC[i];
                this.item.designQuantity = this.coverCenterSkyShort.quantity;

                this.itemAC.push(this.item);
              }
            }
          }
        } else {
          // 양끝동 지붕(+측면)
          if (this.coverEdgeRoof != null) {
            this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

            this.item.purpose = `양끝동 피복: ${this.coverEdgeRoof.purpose}`;
            this.item.specWidth = this.coverEdgeRoof.width;
            this.item.specLength = this.coverEdgeRoof.length;
            this.item.designQuantity = this.coverEdgeRoof.quantity * 2;

            this.itemAC.push(this.item);
          }

          // 양끝동 지붕(+측면) - 비대칭 천창(짧은쪽)
          if (this.coverEdgeSkyShort != null) {
            this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

            this.item.purpose = `양끝동 피복: ${this.coverEdgeSkyShort.purpose}`;
            this.item.specWidth = this.coverEdgeSkyShort.width;
            this.item.specLength = this.coverEdgeSkyShort.length;
            this.item.designQuantity = this.coverEdgeSkyShort.quantity * 2;

            this.itemAC.push(this.item);
          }

          // 양끝동 측면
          if (this.coverEdgeSide != null) {
            this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

            this.item.purpose = `양끝동 피복: ${this.coverEdgeSide.purpose}`;
            this.item.specWidth = this.coverEdgeSide.width;
            this.item.specLength = this.coverEdgeSide.length;
            this.item.designQuantity = this.coverEdgeSide.quantity * 2;

            this.itemAC.push(this.item);
          }

          // 중앙동 지붕
          if (this.coverCenterRoof != null) {
            this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

            this.item.purpose = `중앙동 피복: ${this.coverCenterRoof.purpose}`;
            this.item.specWidth = this.coverCenterRoof.width;
            this.item.specLength = this.coverCenterRoof.length;
            this.item.designQuantity = this.coverCenterRoof.quantity * (this.basic.buildingNumber - 2);

            this.itemAC.push(this.item);
          }

          // 중앙동 지붕 - 비대칭 천창(짧은쪽)
          if (this.coverCenterSkyShort != null) {
            this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

            this.item.purpose = `중앙동 피복: ${this.coverCenterSkyShort.purpose}`;
            this.item.specWidth = this.coverCenterSkyShort.width;
            this.item.specLength = this.coverCenterSkyShort.length;
            this.item.designQuantity = this.coverCenterSkyShort.quantity * (this.basic.buildingNumber - 2);

            this.itemAC.push(this.item);
          }
        }
      } else {
        // 측면
        if (this.coverEdgeSide != null) {
          this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

          this.item.purpose = this.coverEdgeSide.purpose;
          this.item.specWidth = this.coverEdgeSide.width;
          this.item.specLength = this.coverEdgeSide.length;
          this.item.designQuantity = this.coverEdgeSide.quantity;

          this.itemAC.push(this.item);
        }

        // 지붕 및 측면
        if (this.coverEdgeRoof != null) {
          this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

          this.item.purpose = this.coverEdgeRoof.purpose;
          this.item.specWidth = this.coverEdgeRoof.width;
          this.item.specLength = this.coverEdgeRoof.length;
          this.item.designQuantity = this.coverEdgeRoof.quantity;

          this.itemAC.push(this.item);
        }

        // 지붕 및 측면 - 비대칭 천창(짧은쪽)
        if (this.coverEdgeSkyShort != null) {
          this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

          this.item.purpose = this.coverEdgeSkyShort.purpose;
          this.item.specWidth = this.coverEdgeSkyShort.width;
          this.item.specLength = this.coverEdgeSkyShort.length;
          this.item.designQuantity = this.coverEdgeSkyShort.quantity;

          this.itemAC.push(this.item);
        }
      }
    }

    // 앞면(+뒷면)
    if (this.coverFront != null) {
      this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

      this.item.purpose = `마구리 피복: ${this.coverFront.purpose}`;
      this.item.specWidth = this.coverFront.width;
      this.item.specLength = this.coverFront.length;
      this.item.designQuantity = 1;

      this.itemAC.push(this.item);
    }

    // 뒷면
    if (this.coverBack != null) {
      this.item = ItemScreen.getNewDesignItemBySample(this, this.screenSample);

      this.item.purpose = `마구리 피복: ${this.coverBack.purpose}`;
      this.item.specWidth = this.coverBack.width;
      this.item.specLength = this.coverBack.length;
      this.item.designQuantity = 1;

      this.itemAC.push(this.item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);

    /// //////// 외부 ///////////

    // 알고리즘 호출: 피복 파트
    (<CoverLevelIL>this.level).callAlgorithm_coverPartByCover();

    // 알고리즘 호출: 피복 고정 파트
    (<CoverLevelIL>this.level).callAlgorithm_fixingPartByCover();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 아이템 <- 샘플
   */
  public algorithmSpec_item(): void {
    for (const item of this.itemAC as ItemScreen[]) {
      item.specsList = this.screenSample.specsList;
      item.specStringType = this.screenSample.specStringType;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
