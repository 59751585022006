import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWateringValve } from "vhows-design/src/object/design/item/list/ItemWateringValve";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringConnect } from "vhows-design/src/object/design/item/list/ItemWateringConnect";
import { ItemDripButtonAppendage } from "vhows-design/src/object/design/item/list/ItemDripButtonAppendage";
import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { ItemPvcConnector } from "vhows-design/src/object/design/item/list/ItemPvcConnector";
import { ItemPvcValve } from "vhows-design/src/object/design/item/list/ItemPvcValve";
import { ItemWateringTube } from "vhows-design/src/object/design/item/list/ItemWateringTube";
import { ItemWateringTubeNipple } from "vhows-design/src/object/design/item/list/ItemWateringTubeNipple";
import { ItemDripButton } from "vhows-design/src/object/design/item/list/ItemDripButton";
import { Part } from "vhows-design/src/object/design/base/Part";
import { HydSystemSample_SupplyMainAPePipe } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_SupplyMainAPePipe";
import { HydSystemSample_SupplyMainBPePipe } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_SupplyMainBPePipe";
import { HydSystemSample_SupplySubPePipe } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_SupplySubPePipe";
import { HydSystemSample_SupplyMainPvcPipe } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_SupplyMainPvcPipe";
import { HydSystemSample_DripButtonDropper } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_DripButtonDropper";
import { HydSystemPosition } from "vhows-design/src/object/design/hydroponics/system/HydSystemPosition";
import { HydCultivationPosition } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPosition";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject({
  knownTypes: [
    HydSystemSample_SupplyMainAPePipe,
    HydSystemSample_SupplyMainBPePipe,
    HydSystemSample_SupplySubPePipe,
    HydSystemSample_SupplyMainPvcPipe,
    HydSystemSample_DripButtonDropper,
  ],
})
export class HydSystemPart_HydSupply extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public supplyMainAPePipeSample: HydSystemSample_SupplyMainAPePipe; // 공급 주관A 샘플
  public supplyMainBPePipeSample: HydSystemSample_SupplyMainBPePipe; // 공급 주관B 샘플
  public supplySubPePipeSample: HydSystemSample_SupplySubPePipe; // 공급 지관 샘플
  public supplyMainPvcPipeSample: HydSystemSample_SupplyMainPvcPipe; // PVC 공급 주관 샘플
  public dripButtonSample: HydSystemSample_DripButtonDropper; // 점적단추(드롭퍼) 샘플

  // 아이템
  public supplyMainA_pePipe: ItemWateringPipe;
  public supplyMainA_nipple: ItemWateringConnect;
  public supplyMainA_femaleBallValve: ItemWateringValve;
  public supplyMainA_elbow: ItemWateringConnect;
  public supplyMainA_exactT: ItemWateringConnect;
  public electricValve_electricValve: ItemWateringValve;
  public electricValve_socketFemale: ItemWateringConnect;
  public electricValve_nipple: ItemWateringConnect;
  public supplyMainA_valveSocket: ItemWateringConnect;
  public supplyMainB_pePipe: ItemWateringPipe;
  public supplyMainB_pePipeEnd: ItemWateringConnect;
  public supplyMainB_valveSocket: ItemWateringConnect;
  public supplySub_pePipe: ItemWateringPipe;
  public supplySub_pePipeSaddle: ItemWateringConnect;
  public supplySub_unionValve: ItemWateringValve;
  public supplySub_pePipeEnd: ItemWateringConnect;
  public supplySub_valveSocket: ItemWateringConnect;
  public supplyMainB_pvcPipe: ItemDrainPipe;
  public supplyMainB_pvcFemaleBallValve: ItemPvcValve;
  public supplyMainB_pvcEnd: ItemPvcConnector;
  public supplyMainB_pvcExactT: ItemPvcConnector;
  public dripButton_dripButton: ItemDripButton;
  public dripButton_dripButtonManyHold: ItemDripButtonAppendage;
  public dripButton_tube: ItemWateringTube;
  public dripButton_dripButtonDropper: ItemDripButtonAppendage;
  public dripButton_tubeNipple: ItemWateringTubeNipple;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _supplyMainALength: number = 0; // 공급 주관A 길이
  @jsonMember(Number)
  public _supplyMainBLength: number = 0; // 공급 주관B 길이
  @jsonMember(Number)
  public _subLengthPerBed: number = 0; // 베드당 지관 길이
  @jsonMember(Number)
  public _subLineNumberPerBed: number; // 베드당 지관 줄수
  @jsonMember(Number)
  public _totalSupplySubLineNumber: number; // 총 공급 지관 줄수

  /**
   * 공급 주관A 길이
   */
  public get supplyMainALength(): number {
    return this._supplyMainALength;
  }

  //
  public set supplyMainALength(value: number) {
    this._supplyMainALength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 공급 주관B 길이
   */
  public get supplyMainBLength(): number {
    return this._supplyMainBLength;
  }

  //
  public set supplyMainBLength(value: number) {
    this._supplyMainBLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 베드당 지관 길이
   */
  public get subLengthPerBed(): number {
    return this._subLengthPerBed;
  }

  //
  public set subLengthPerBed(value: number) {
    this._subLengthPerBed = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 베드당 지관 줄수
   */
  public get subLineNumberPerBed(): number {
    return this._subLineNumberPerBed;
  }

  //
  public set subLineNumberPerBed(value: number) {
    this._subLineNumberPerBed = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalSupplySubLineNumber();
    this.algorithmPart();
  }

  /**
   * 총 공급 지관 줄수
   */
  public get totalSupplySubLineNumber(): number {
    return this._totalSupplySubLineNumber;
  }

  //
  public set totalSupplySubLineNumber(value: number) {
    this._totalSupplySubLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.supplyMainAPePipeSample = new HydSystemSample_SupplyMainAPePipe();
    this.supplyMainBPePipeSample = new HydSystemSample_SupplyMainBPePipe();
    this.supplySubPePipeSample = new HydSystemSample_SupplySubPePipe();
    this.supplyMainPvcPipeSample = new HydSystemSample_SupplyMainPvcPipe();
    this.dripButtonSample = new HydSystemSample_DripButtonDropper();

    this.sampleAC = [
      this.supplyMainAPePipeSample,
      this.supplyMainBPePipeSample,
      this.supplySubPePipeSample,
      this.supplyMainPvcPipeSample,
      this.dripButtonSample,
    ];

    // 아이템
    this.supplyMainA_pePipe = new ItemWateringPipe();
    this.supplyMainA_nipple = new ItemWateringConnect();
    this.supplyMainA_femaleBallValve = new ItemWateringValve();
    this.supplyMainA_elbow = new ItemWateringConnect();
    this.supplyMainA_exactT = new ItemWateringConnect();
    this.electricValve_electricValve = new ItemWateringValve();
    this.electricValve_socketFemale = new ItemWateringConnect();
    this.electricValve_nipple = new ItemWateringConnect();
    this.supplyMainA_valveSocket = new ItemWateringConnect();
    this.supplyMainB_pePipe = new ItemWateringPipe();
    this.supplyMainB_pePipeEnd = new ItemWateringConnect();
    this.supplyMainB_valveSocket = new ItemWateringConnect();
    this.supplySub_pePipe = new ItemWateringPipe();
    this.supplySub_pePipeSaddle = new ItemWateringConnect();
    this.supplySub_unionValve = new ItemWateringValve();
    this.supplySub_pePipeEnd = new ItemWateringConnect();
    this.supplySub_valveSocket = new ItemWateringConnect();
    this.supplyMainB_pvcPipe = new ItemDrainPipe();
    this.supplyMainB_pvcFemaleBallValve = new ItemPvcValve();
    this.supplyMainB_pvcEnd = new ItemPvcConnector();
    this.supplyMainB_pvcExactT = new ItemPvcConnector();
    this.dripButton_dripButton = new ItemDripButton();
    this.dripButton_dripButtonManyHold = new ItemDripButtonAppendage();
    this.dripButton_tube = new ItemWateringTube();
    this.dripButton_dripButtonDropper = new ItemDripButtonAppendage();
    this.dripButton_tubeNipple = new ItemWateringTubeNipple();

    this.itemAC = [
      this.supplyMainA_pePipe,
      this.supplyMainA_nipple,
      this.supplyMainA_femaleBallValve,
      this.supplyMainA_elbow,
      this.supplyMainA_exactT,
      this.electricValve_electricValve,
      this.electricValve_socketFemale,
      this.electricValve_nipple,
      this.supplyMainA_valveSocket,
      this.supplyMainB_pePipe,
      this.supplyMainB_pePipeEnd,
      this.supplyMainB_valveSocket,
      this.supplySub_pePipe,
      this.supplySub_pePipeSaddle,
      this.supplySub_unionValve,
      this.supplySub_pePipeEnd,
      this.supplySub_valveSocket,
      this.supplyMainB_pvcPipe,
      this.supplyMainB_pvcFemaleBallValve,
      this.supplyMainB_pvcEnd,
      this.supplyMainB_pvcExactT,
      this.dripButton_dripButton,
      this.dripButton_dripButtonManyHold,
      this.dripButton_tube,
      this.dripButton_dripButtonDropper,
      this.dripButton_tubeNipple,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.supplyMainAPePipeSample = <HydSystemSample_SupplyMainAPePipe>this.sampleAC[0];
    this.supplyMainBPePipeSample = <HydSystemSample_SupplyMainBPePipe>this.sampleAC[1];
    this.supplySubPePipeSample = <HydSystemSample_SupplySubPePipe>this.sampleAC[2];
    this.supplyMainPvcPipeSample = <HydSystemSample_SupplyMainPvcPipe>this.sampleAC[3];
    this.dripButtonSample = <HydSystemSample_DripButtonDropper>this.sampleAC[4];
    // 아이템
    this.supplyMainA_pePipe = <ItemWateringPipe>this.itemAC[0];
    this.supplyMainA_nipple = <ItemWateringConnect>this.itemAC[1];
    this.supplyMainA_femaleBallValve = <ItemWateringValve>this.itemAC[2];
    this.supplyMainA_elbow = <ItemWateringConnect>this.itemAC[3];
    this.supplyMainA_exactT = <ItemWateringConnect>this.itemAC[4];
    this.electricValve_electricValve = <ItemWateringValve>this.itemAC[5];
    this.electricValve_socketFemale = <ItemWateringConnect>this.itemAC[6];
    this.electricValve_nipple = <ItemWateringConnect>this.itemAC[7];
    this.supplyMainA_valveSocket = <ItemWateringConnect>this.itemAC[8];
    this.supplyMainB_pePipe = <ItemWateringPipe>this.itemAC[9];
    this.supplyMainB_pePipeEnd = <ItemWateringConnect>this.itemAC[10];
    this.supplyMainB_valveSocket = <ItemWateringConnect>this.itemAC[11];
    this.supplySub_pePipe = <ItemWateringPipe>this.itemAC[12];
    this.supplySub_pePipeSaddle = <ItemWateringConnect>this.itemAC[13];
    this.supplySub_unionValve = <ItemWateringValve>this.itemAC[14];
    this.supplySub_pePipeEnd = <ItemWateringConnect>this.itemAC[15];
    this.supplySub_valveSocket = <ItemWateringConnect>this.itemAC[16];
    this.supplyMainB_pvcPipe = <ItemDrainPipe>this.itemAC[17];
    this.supplyMainB_pvcFemaleBallValve = <ItemPvcValve>this.itemAC[18];
    this.supplyMainB_pvcEnd = <ItemPvcConnector>this.itemAC[19];
    this.supplyMainB_pvcExactT = <ItemPvcConnector>this.itemAC[20];
    this.dripButton_dripButton = <ItemDripButton>this.itemAC[21];
    this.dripButton_dripButtonManyHold = <ItemDripButtonAppendage>this.itemAC[22];
    this.dripButton_tube = <ItemWateringTube>this.itemAC[23];
    this.dripButton_dripButtonDropper = <ItemDripButtonAppendage>this.itemAC[24];
    this.dripButton_tubeNipple = <ItemWateringTubeNipple>this.itemAC[25];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param subLineNumberPerBed: number 베드당 지관 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    subLineNumberPerBed: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._subLineNumberPerBed = subLineNumberPerBed;

    // 샘플
    if (this.level.index >= 0) {
      this.supplyMainAPePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "공급 주관A",
        "수도관(이층관/직관), 40mm, 6m",
      );
      this.supplyMainBPePipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "공급 주관B",
        "수도관(단층관), 40mm, 50m",
      );
      this.supplySubPePipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "공급 지관",
        "연질관(타공), 25mm, 100m",
      );
      this.supplyMainPvcPipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PVC_PIPE,
        CONST.ITEM_NAME_PVC_PIPE,
        "공급 주관",
        "일반, 50mm, 4m",
      );
      this.dripButtonSample.setDefaultData(
        4,
        CONST.ITEM_ID_DRIP_BUTTON,
        CONST.ITEM_NAME_DRIP_BUTTON,
        "점적단추",
        "드롭퍼살수",
        "DAN, PC&CNL, 일반, 8L/H",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.supplyMainA_pePipe.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "공급 주관A",
        "수도관(이층관/직관), 40mm, 6m",
      );
      this.supplyMainA_nipple.setDefaultData(
        1,
        CONST.ITEM_ID_NIPPLE,
        CONST.ITEM_NAME_NIPPLE,
        "공급 주관A",
        "PE, 단니플, 40mm, 40mm",
      );
      this.supplyMainA_femaleBallValve.setDefaultData(
        2,
        CONST.ITEM_ID_FEMALE_BALL_VALVE,
        CONST.ITEM_NAME_FEMALE_BALL_VALVE,
        "공급 주관A",
        "PE, 일반, 40mm, 40mm",
      );
      this.supplyMainA_elbow.setDefaultData(
        3,
        CONST.ITEM_ID_ELBOW,
        CONST.ITEM_NAME_ELBOW,
        "공급 주관A",
        "PE, 소켓, 40mm, 40mm",
      );
      this.supplyMainA_exactT.setDefaultData(
        4,
        CONST.ITEM_ID_EXACT_T,
        CONST.ITEM_NAME_EXACT_T,
        "공급 주관A",
        "PE, 소켓, 40mm, 40mm",
      );
      this.electricValve_electricValve.setDefaultData(
        5,
        CONST.ITEM_ID_ELECTRIC_VALVE,
        CONST.ITEM_NAME_ELECTRIC_VALVE,
        "전자 밸브",
        "PE, 암나사, 40mm, 40mm",
      );
      this.electricValve_socketFemale.setDefaultData(
        6,
        CONST.ITEM_ID_SOCKET_FEMALE,
        CONST.ITEM_NAME_SOCKET_FEMALE,
        "전자 밸브",
        "PE, 일반, 40mm, 40mm",
      );
      this.electricValve_nipple.setDefaultData(
        7,
        CONST.ITEM_ID_NIPPLE,
        CONST.ITEM_NAME_NIPPLE,
        "전자 밸브",
        "PE, 장니플, 40mm, 40mm",
      );
      this.supplyMainA_valveSocket.setDefaultData(
        8,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "공급 주관A",
        "PE, 일반, 40mm, -",
      );
      this.supplyMainB_pePipe.setDefaultData(
        9,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "공급 주관B",
        "수도관(단층관), 40mm, 50m",
      );
      this.supplyMainB_pePipeEnd.setDefaultData(
        10,
        CONST.ITEM_ID_PE_PIPE_END,
        CONST.ITEM_NAME_PE_PIPE_END,
        "공급 주관B",
        "PE, 앤드캡, 40mm, -",
      );
      this.supplyMainB_valveSocket.setDefaultData(
        11,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "공급 주관B",
        "PE, 일반, 40mm, -",
      );
      this.supplySub_pePipe.setDefaultData(
        12,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "공급 지관",
        "연질관(타공), 25mm, 100m",
      );
      this.supplySub_pePipeSaddle.setDefaultData(
        13,
        CONST.ITEM_ID_PE_PIPE_SADDLE,
        CONST.ITEM_NAME_PE_PIPE_SADDLE,
        "공급 지관",
        "PE, 일반, 40mm, 25mm",
      );
      this.supplySub_unionValve.setDefaultData(
        14,
        CONST.ITEM_ID_UNION_VALVE,
        CONST.ITEM_NAME_UNION_VALVE,
        "공급 지관",
        "PE, 일반, 25mm, 25mm",
      );
      this.supplySub_pePipeEnd.setDefaultData(
        15,
        CONST.ITEM_ID_PE_PIPE_END,
        CONST.ITEM_NAME_PE_PIPE_END,
        "공급 지관",
        "PE, 앤드캡, 25mm, -",
      );
      this.supplySub_valveSocket.setDefaultData(
        16,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "공급 지관",
        "PE, 일반, 25mm, -",
      );
      this.supplyMainB_pvcPipe.setDefaultData(
        17,
        CONST.ITEM_ID_PVC_PIPE,
        CONST.ITEM_NAME_PVC_PIPE,
        "공급 주관B",
        "일반, 50mm, 4m",
      );
      this.supplyMainB_pvcFemaleBallValve.setDefaultData(
        18,
        CONST.ITEM_ID_PVC_FEMALE_BALL_VALVE,
        CONST.ITEM_NAME_PVC_FEMALE_BALL_VALVE,
        "공급 주관B",
        "PVC, 일반, 50mm, 50mm",
      );
      this.supplyMainB_pvcEnd.setDefaultData(
        19,
        CONST.ITEM_ID_PVC_END,
        CONST.ITEM_NAME_PVC_END,
        "공급 주관B",
        "PVC, 일반, 50mm, -",
      );
      this.supplyMainB_pvcExactT.setDefaultData(
        20,
        CONST.ITEM_ID_PVC_EXACT_T,
        CONST.ITEM_NAME_PVC_EXACT_T,
        "공급 주관B",
        "PVC, 일반, 50mm, 50mm",
      );
      this.dripButton_dripButton.setDefaultData(
        21,
        CONST.ITEM_ID_DRIP_BUTTON,
        CONST.ITEM_NAME_DRIP_BUTTON,
        "점적단추",
        "드롭퍼살수",
        "DAN, PC&CNL, 일반, 8L/H",
      );
      this.dripButton_dripButtonManyHold.setDefaultData(
        22,
        CONST.ITEM_ID_DRIP_BUTTON_MANY_HOLD,
        CONST.ITEM_NAME_DRIP_BUTTON_MANY_HOLD,
        "점적단추",
        "일반, 4구, 3/5mm",
      );
      this.dripButton_tube.setDefaultData(
        23,
        CONST.ITEM_ID_TUBE,
        CONST.ITEM_NAME_TUBE,
        "점적단추",
        "일반, 3/5mm, 200m",
      );
      this.dripButton_dripButtonDropper.setDefaultData(
        24,
        CONST.ITEM_ID_DRIP_BUTTON_DROPPER,
        CONST.ITEM_NAME_DRIP_BUTTON_DROPPER,
        "점적단추",
        "일반, ㄱ자, 3/5mm",
      );
      this.dripButton_tubeNipple.setDefaultData(
        25,
        CONST.ITEM_ID_TUBE_NIPPLE,
        CONST.ITEM_NAME_TUBE_NIPPLE,
        "점적단추",
        "일반, 엘디, 3/5mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;
    const hydCultivationPosition: HydCultivationPosition = this.struct.hydCultivationWork.level1.wholePosition;

    /// //////// 선택, 가시성 ///////////
    this.supplyMainA_pePipe.selected = true;
    this.supplyMainA_pePipe.visible = true;
    this.supplyMainA_nipple.selected = true;
    this.supplyMainA_nipple.visible = true;
    this.supplyMainA_femaleBallValve.selected = true;
    this.supplyMainA_femaleBallValve.visible = true;
    this.supplyMainA_elbow.selected = true;
    this.supplyMainA_elbow.visible = true;
    this.supplyMainA_exactT.selected = true;
    this.supplyMainA_exactT.visible = true;
    this.electricValve_electricValve.selected = true;
    this.electricValve_electricValve.visible = true;
    this.electricValve_socketFemale.selected = true;
    this.electricValve_socketFemale.visible = true;
    this.electricValve_nipple.selected = true;
    this.electricValve_nipple.visible = true;
    this.supplyMainA_valveSocket.selected = true;
    this.supplyMainA_valveSocket.visible = true;
    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.supplyMainB_pePipe.selected = true;
      this.supplyMainB_pePipe.visible = true;
      this.supplyMainB_pePipeEnd.selected = true;
      this.supplyMainB_pePipeEnd.visible = true;
      this.supplyMainB_valveSocket.selected = true;
      this.supplyMainB_valveSocket.visible = true;
      this.supplySub_pePipe.selected = true;
      this.supplySub_pePipe.visible = true;
      this.supplySub_pePipeSaddle.selected = true;
      this.supplySub_pePipeSaddle.visible = true;
      this.supplySub_unionValve.selected = true;
      this.supplySub_unionValve.visible = true;
      this.supplySub_pePipeEnd.selected = true;
      this.supplySub_pePipeEnd.visible = true;
      this.supplySub_valveSocket.selected = true;
      this.supplySub_valveSocket.visible = true;
      this.supplyMainB_pvcPipe.selected = false;
      this.supplyMainB_pvcPipe.visible = false;
      this.supplyMainB_pvcFemaleBallValve.selected = false;
      this.supplyMainB_pvcFemaleBallValve.visible = false;
      this.supplyMainB_pvcEnd.selected = false;
      this.supplyMainB_pvcEnd.visible = false;
      this.supplyMainB_pvcExactT.selected = false;
      this.supplyMainB_pvcExactT.visible = false;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.supplyMainB_pvcPipe.selected = true;
      this.supplyMainB_pvcPipe.visible = true;
      this.supplyMainB_pvcFemaleBallValve.selected = true;
      this.supplyMainB_pvcFemaleBallValve.visible = true;
      this.supplyMainB_pvcEnd.selected = true;
      this.supplyMainB_pvcEnd.visible = true;
      this.supplyMainB_pvcExactT.selected = true;
      this.supplyMainB_pvcExactT.visible = true;
      this.supplyMainB_pePipe.selected = false;
      this.supplyMainB_pePipe.visible = false;
      this.supplyMainB_pePipeEnd.selected = false;
      this.supplyMainB_pePipeEnd.visible = false;
      this.supplyMainB_valveSocket.selected = false;
      this.supplyMainB_valveSocket.visible = false;
      this.supplySub_pePipe.selected = false;
      this.supplySub_pePipe.visible = false;
      this.supplySub_pePipeSaddle.selected = false;
      this.supplySub_pePipeSaddle.visible = false;
      this.supplySub_unionValve.selected = false;
      this.supplySub_unionValve.visible = false;
      this.supplySub_pePipeEnd.selected = false;
      this.supplySub_pePipeEnd.visible = false;
      this.supplySub_valveSocket.selected = false;
      this.supplySub_valveSocket.visible = false;
    }
    this.dripButton_dripButton.selected = true;
    this.dripButton_dripButton.visible = true;
    this.dripButton_dripButtonManyHold.selected = true;
    this.dripButton_dripButtonManyHold.visible = true;
    this.dripButton_tube.selected = true;
    this.dripButton_tube.visible = true;
    this.dripButton_dripButtonDropper.selected = true;
    this.dripButton_dripButtonDropper.visible = true;
    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.dripButton_tubeNipple.selected = false;
      this.dripButton_tubeNipple.visible = false;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.dripButton_tubeNipple.selected = true;
      this.dripButton_tubeNipple.visible = true;
    }

    /// ////////  수량 ///////////
    this.supplyMainA_pePipe.designQuantity = this.supplyMainALength / this.supplyMainA_pePipe.specLength;

    this.supplyMainA_nipple.designQuantity = 1 * hydSystemPosition.hydcontrolPart.nutrientSolution.designQuantity;

    this.supplyMainA_femaleBallValve.designQuantity =
      1 * hydSystemPosition.hydcontrolPart.nutrientSolution.designQuantity;

    this.supplyMainA_elbow.designQuantity =
      hydSystemPosition.hydcontrolPart.nutrientSolution.designQuantity +
      this.electricValve_electricValve.designQuantity * 4;

    this.supplyMainA_exactT.designQuantity =
      hydSystemPosition.nutrientControlArea + this.electricValve_electricValve.designQuantity;

    this.electricValve_electricValve.designQuantity = hydSystemPosition.nutrientControlArea;

    this.electricValve_socketFemale.designQuantity = this.electricValve_electricValve.designQuantity;

    this.electricValve_nipple.designQuantity = this.electricValve_electricValve.designQuantity;

    this.supplyMainA_valveSocket.designQuantity =
      hydSystemPosition.hydcontrolPart.nutrientSolution.designQuantity +
      this.electricValve_electricValve.designQuantity * 2;

    this.supplyMainB_pePipe.designQuantity = this.supplyMainBLength / this.supplyMainB_pePipe.specLength;

    this.supplyMainB_pePipeEnd.designQuantity = this.electricValve_electricValve.designQuantity * 2;

    this.supplyMainB_valveSocket.designQuantity = this.supplyMainB_pePipeEnd.designQuantity;

    this.supplySub_pePipe.designQuantity =
      ((this.subLengthPerBed + 1) * this.totalSupplySubLineNumber) / this.supplySub_pePipe.specLength;

    this.supplySub_pePipeSaddle.designQuantity = this.totalSupplySubLineNumber;

    this.supplySub_unionValve.designQuantity = this.totalSupplySubLineNumber;

    this.supplySub_pePipeEnd.designQuantity = this.totalSupplySubLineNumber;

    this.supplySub_valveSocket.designQuantity =
      this.supplySub_pePipeSaddle.designQuantity + this.supplySub_pePipeEnd.designQuantity;

    this.supplyMainB_pvcPipe.designQuantity = this.supplyMainBLength / this.supplyMainB_pvcPipe.specLength;

    this.supplyMainB_pvcFemaleBallValve.designQuantity = hydCultivationPosition.bedPart.totalBedLineNumber;

    this.supplyMainB_pvcEnd.designQuantity =
      (this.electricValve_electricValve.designQuantity + hydCultivationPosition.bedPart.totalBedLineNumber) * 2;

    this.supplyMainB_pvcExactT.designQuantity = hydCultivationPosition.bedPart.totalBedLineNumber * 2;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.dripButton_dripButton.designQuantity =
        (hydCultivationPosition.bedPart.bedLength / hydCultivationPosition.bedPart.cropInterval) *
        hydCultivationPosition.bedPart.totalBedLineNumber *
        2;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.dripButton_dripButton.designQuantity =
        hydCultivationPosition.bedPart.totalBedLineNumber * hydCultivationPosition.bedPart.linePerNftBed;
    }

    this.dripButton_dripButtonManyHold.designQuantity =
      (hydCultivationPosition.bedPart.bedLength / hydCultivationPosition.bedPart.cropInterval) *
      hydCultivationPosition.bedPart.totalBedLineNumber *
      2;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.dripButton_tube.designQuantity =
        (0.5 * this.dripButton_dripButton.designQuantity * this.dripButton_dripButtonManyHold.specHold) /
        this.dripButton_tube.specLength;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.dripButton_tube.designQuantity =
        (0.5 * this.dripButton_dripButton.designQuantity) / this.dripButton_tube.specLength;
    }

    this.dripButton_dripButtonDropper.designQuantity =
      this.dripButton_dripButton.designQuantity * this.dripButton_dripButtonManyHold.specHold;

    this.dripButton_tubeNipple.designQuantity =
      hydCultivationPosition.bedPart.totalBedLineNumber * hydCultivationPosition.bedPart.linePerNftBed;
  }

  /**
   * 알고리즘: 공급 주관A 길이 <- 재배부 폭(양액시스템), 재배부 길이(양액시스템), 양액 제어구역(양액시스템)
   */
  public algorithm_supplyMainALength(): void {
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;

    this.supplyMainALength = CommonUtil.roundUp(
      ((hydSystemPosition.cultivationWidth + hydSystemPosition.cultivationLength) / 2) *
        hydSystemPosition.nutrientControlArea *
        1.2,
      1,
    );
  }

  /**
   * 알고리즘: 공급 주관B 길이 <- 형태(양액시스템), 재배부 폭(양액시스템), 양액 제어구역(양액시스템), 베드 폭(베드 파트), 베드 높이(베드 파트), 베드 매설 깊이(베드 파트), 총 베드 줄수(베드 파트)
   */
  public algorithm_supplyMainBLength(): void {
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;
    const hydCultivationPosition: HydCultivationPosition = this.struct.hydCultivationWork.level1.wholePosition;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.supplyMainBLength = CommonUtil.roundUp(
        hydSystemPosition.cultivationWidth * hydSystemPosition.nutrientControlArea * 1.1,
        1,
      );
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      const bedLength =
        (hydCultivationPosition.bedPart.bedWidth +
          hydCultivationPosition.bedPart.bedHeight +
          hydCultivationPosition.bedPart.bedDepthLaying) *
        hydCultivationPosition.bedPart.totalBedLineNumber;

      this.supplyMainBLength = CommonUtil.roundUp(
        (hydSystemPosition.cultivationWidth * hydSystemPosition.nutrientControlArea + bedLength) * 1.1,
        1,
      );
    }
  }

  /**
   * 알고리즘: 베드당 지관 길이 <- 베드 길이(베드 파트)
   */
  public algorithm_subLengthPerBed(): void {
    const hydCultivationPosition: HydCultivationPosition = this.struct.hydCultivationWork.level1.wholePosition;

    this.subLengthPerBed = hydCultivationPosition.bedPart.bedLength;
  }

  /**
   * 알고리즘: 총 공급 지관 줄수 <- 총 베드 줄수(베드 파트), 베드당 지관 줄수
   */
  public algorithm_totalSupplySubLineNumber(): void {
    const hydCultivationPosition: HydCultivationPosition = this.struct.hydCultivationWork.level1.wholePosition;

    this.totalSupplySubLineNumber = hydCultivationPosition.bedPart.totalBedLineNumber * this.subLineNumberPerBed;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [공급 주관A] PE관 <- 제어 주관 샘플(제어부)
   */
  public algorithmSpec_supplyMainA_pePipe(): void {
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;

    this.supplyMainA_pePipe.specs = hydSystemPosition.hydcontrolPart.controlMainPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [공급 주관A] 니플 <- 공급 주관A 샘플
   */
  public algorithmSpec_supplyMainA_nipple(): void {
    this.supplyMainA_nipple.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
    this.supplyMainA_nipple.specDiameter2 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 주관A] 암나사 볼 밸브 <- 공급 주관A 샘플
   */
  public algorithmSpec_supplyMainA_femaleBallValve(): void {
    this.supplyMainA_femaleBallValve.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
    this.supplyMainA_femaleBallValve.specDiameter2 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 주관A] 엘보 <- 공급 주관A 샘플
   */
  public algorithmSpec_supplyMainA_elbow(): void {
    this.supplyMainA_elbow.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
    this.supplyMainA_elbow.specDiameter2 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 주관A] 정티 <- 공급 주관A 샘플
   */
  public algorithmSpec_supplyMainA_exactT(): void {
    this.supplyMainA_exactT.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [전자 밸브] 전자 밸브 <- 공급 주관A 샘플
   */
  public algorithmSpec_electricValve_electricValve(): void {
    this.electricValve_electricValve.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [전자 밸브] 소켓(암나사) <- 공급 주관A 샘플
   */
  public algorithmSpec_electricValve_socketFemale(): void {
    this.electricValve_socketFemale.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
    this.electricValve_socketFemale.specDiameter2 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [전자 밸브] 니플 <- 공급 주관A 샘플
   */
  public algorithmSpec_electricValve_nipple(): void {
    this.electricValve_nipple.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
    this.electricValve_nipple.specDiameter2 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 주관A] 밸브 소켓 <- 공급 주관A 샘플
   */
  public algorithmSpec_supplyMainA_valveSocket(): void {
    this.supplyMainA_valveSocket.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 주관B] PE관 <- 공급 주관B 샘플
   */
  public algorithmSpec_supplyMainB_pePipe(): void {
    this.supplyMainB_pePipe.specs = this.supplyMainBPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [공급 지관] 관 마감 <- 공급 주관B 샘플
   */
  public algorithmSpec_supplyMainB_pePipeEnd(): void {
    this.supplyMainB_pePipeEnd.specDiameter1 = this.supplyMainBPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 지관] 밸브 소켓 <- 공급 주관B 샘플
   */
  public algorithmSpec_supplyMainB_valveSocket(): void {
    this.supplyMainB_valveSocket.specDiameter1 = this.supplyMainBPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 지관] PE관 <- 공급 지관 샘플
   */
  public algorithmSpec_supplySub_pePipe(): void {
    this.supplySub_pePipe.specs = this.supplySubPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [공급 지관] 관 새들 <- 공급 주관B 샘플, 공급 지관 샘플
   */
  public algorithmSpec_supplySub_pePipeSaddle(): void {
    this.supplySub_pePipeSaddle.specDiameter1 = this.supplyMainBPePipeSample.specDiameter;
    this.supplySub_pePipeSaddle.specDiameter2 = this.supplySubPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 지관] 유니온 밸브 <- 공급 지관 샘플
   */
  public algorithmSpec_supplySub_unionValve(): void {
    this.supplySub_unionValve.specDiameter1 = this.supplySubPePipeSample.specDiameter;
    this.supplySub_unionValve.specDiameter2 = this.supplySubPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 지관] 관 마감 <- 공급 지관 샘플
   */
  public algorithmSpec_supplySub_pePipeEnd(): void {
    this.supplySub_pePipeEnd.specDiameter1 = this.supplySubPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 지관] 밸브 소켓 <- 공급 지관 샘플
   */
  public algorithmSpec_supplySub_valveSocket(): void {
    this.supplySub_valveSocket.specDiameter1 = this.supplyMainAPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 주관] PVC 관 <- PVC 공급 주관 샘플
   */
  public algorithmSpec_supplyMainB_pvcPipe(): void {
    this.supplyMainB_pvcPipe.specs = this.supplyMainPvcPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [공급 주관] PVC 암나사 볼 밸브 <- PVC 공급 주관 샘플
   */
  public algorithmSpec_supplyMainB_pvcFemaleBallValve(): void {
    this.supplyMainB_pvcFemaleBallValve.specDiameter1 = this.supplyMainPvcPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 주관] PVC 소제구 <- PVC 공급 주관 샘플
   */
  public algorithmSpec_supplyMainB_pvcEnd(): void {
    this.supplyMainB_pvcEnd.specDiameter1 = this.supplyMainPvcPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [공급 주관] PVC 정티 <- PVC 공급 주관 샘플
   */
  public algorithmSpec_supplyMainB_pvcExactT(): void {
    this.supplyMainB_pvcExactT.specDiameter1 = this.supplyMainPvcPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [점적단추] 점적단추 <- 점적단추 샘플
   */
  public algorithmSpec_dripButton_dripButton(): void {
    this.dripButton_dripButton.specs = this.dripButtonSample.specs;
    this.dripButton_dripButton.brands = this.dripButtonSample.brands;
  }

  /**
   * 규격 알고리즘: [점적단추] 점적단추 매니홀드 <- 점적단추 샘플
   */
  public algorithmSpec_dripButton_dripButtonManyHold(): void {
    if (this.dripButtonSample.brandWateringQuantity === CONST.ITEM_SPEC_VALUE_2_LITER_PER_HOUR) {
      this.dripButton_dripButtonManyHold.specType = CONST.ITEM_SPEC_VALUE_1_HOLE;
      this.dripButton_dripButtonManyHold.specDiameter = CONST.ITEM_SPEC_VALUE_3_SEP_5_MM;
    } else if (this.dripButtonSample.brandWateringQuantity === CONST.ITEM_SPEC_VALUE_4_LITER_PER_HOUR) {
      this.dripButton_dripButtonManyHold.specType = CONST.ITEM_SPEC_VALUE_2_HOLE;
      this.dripButton_dripButtonManyHold.specDiameter = CONST.ITEM_SPEC_VALUE_3_SEP_5_MM;
    } else if (this.dripButtonSample.brandWateringQuantity === CONST.ITEM_SPEC_VALUE_8_LITER_PER_HOUR) {
      this.dripButton_dripButtonManyHold.specType = CONST.ITEM_SPEC_VALUE_4_HOLE;
      this.dripButton_dripButtonManyHold.specDiameter = CONST.ITEM_SPEC_VALUE_3_SEP_5_MM;
    } else {
      this.dripButton_dripButtonManyHold.specType = CONST.ITEM_SPEC_VALUE_4_HOLE;
      this.dripButton_dripButtonManyHold.specDiameter = CONST.ITEM_SPEC_VALUE_3_SEP_5_MM;
    }
  }

  /**
   * 규격 알고리즘: [점적단추] 튜브
   */
  public algorithmSpec_dripButton_tube(): void {}

  /**
   * 규격 알고리즘: [점적단추] 점적단추 드롭퍼
   */
  public algorithmSpec_dripButton_dripButtonDropper(): void {}

  /**
   * 규격 알고리즘: [점적 단추] 튜브 니플
   */
  public algorithmSpec_dripButton_tubeNipple(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
