import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";
import { PartitionLevel } from "vhows-design/src/object/design/other/partition/PartitionLevel";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { WindbreakPosition } from "vhows-design/src/object/design/cover/windbreak/WindbreakPosition";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";
import { WindowPosition } from "vhows-design/src/object/design/other/window/WindowPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-03
 */
@jsonObject({
  knownTypes: [CoverPosition],
})
export class CoverLevel extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public defaultPosition: CoverPosition;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 위치별 피복 겹수 (0:측면, 1:지붕, 2:앞면, 3:뒷면) */
  public coverLayers: number[] = [0, 0, 0, 0];

  /** 위치별 치마 피복 겹수 (0:측면, 1:지붕, 2:앞면, 3:뒷면) */
  public skirtCoverLayers: number[] = [0, 0, 0, 0];

  /** 위치별 바람막이 피복 겹수 (0:측면, 1:지붕, 2:앞면, 3:뒷면) */
  public windbreakCoverLayers: number[] = [0, 0, 0, 0];

  /** 칸막이 피복 겹수 */
  public partitionCoverLayer: number = 0;

  /** 위치별 피복 형태 (0:측면, 1:지붕, 2:앞면, 3:뒷면) */
  public coverTypes: string[];

  /** 위치별 피복 두께 1차 (0:측면, 1:지붕, 2:앞면, 3:뒷면) */
  public coverThicknesses1: number[] = [0, 0, 0, 0];

  /** 위치별 피복 두께 2차 (0:측면, 1:지붕, 2:앞면, 3:뒷면) */
  public coverThicknesses2: number[] = [0, 0, 0, 0];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.defaultPosition = new CoverPosition();

    this.positionAC = [this.defaultPosition];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index === 0) {
      this.defaultPosition.setDefaultData(
        0,
        true,
        true,
        true,
        CONST.LB_POSITION_COVER_1,
        CONST.LB_COVER_TYPE_VINYL,
        CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE,
        1,
        1,
        1,
        1,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      );
    } else if (index === 1 || index === 2 || index === 3) {
      this.defaultPosition.setDefaultData(
        0,
        true,
        true,
        true,
        CONST.LB_POSITION_COVER_1,
        CONST.LB_COVER_TYPE_VINYL,
        CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE,
        1,
        1,
        1,
        1,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    super.setDefaultVariable();

    this.algorithm_coverLayersAndTypes(); // 하위 객체 초기화 이후에 해야함
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘 호출: 차수 파트(개폐기), 개폐기 용도(개폐기), 일반 치마 파트(치마 피복), 바람막이 파트(바람막이 피복), 피복 파트(칸막이), 천창 피복 파트(천창 피복) <- 피복 파트(기초 피복)
   */
  public callAlgorithm_coverPartByCover(): void {
    // 개폐기
    for (const switcherPosition of this.struct.switcherWork.levelAC[this.index].positionAC as SwitcherPosition[]) {
      switcherPosition.manual1Part.manualSwitcherSample.algorithmSpec_specUsage();
      switcherPosition.manual2Part.manualSwitcherSample.algorithmSpec_specUsage();
      switcherPosition.power1Part.powerSwitcherSample.algorithmSpec_specUsage();
      switcherPosition.power2Part.powerSwitcherSample.algorithmSpec_specUsage();
      switcherPosition.axisPart.algorithmPart();
    }

    // 치마
    for (const skirtPosition of this.struct.skirtWork.levelAC[this.index].positionAC as SkirtPosition[]) {
      skirtPosition.normalPart.algorithmPart();
    }

    // 바람막이
    for (let l: number = 0; l < this.struct.windbreakWork.levelAC.length; l++) {
      for (const windbreakPosition of this.struct.windbreakWork.levelAC[l].positionAC as WindbreakPosition[]) {
        windbreakPosition.windbreakPart.algorithmPart();
      }
    }

    // 칸막이
    if (this.index === 0) {
      (<PartitionLevel>this.struct.partitionWork.levelAC[this.index]).trunkPosition.coverPart.algorithmPart();
    }

    // 천창
    const skyFramePosition = <SkyFramePosition>this.struct.skyFrameWork.level1.skylightPosition;
    if (skyFramePosition.skylightLevel > 0) {
      this.struct.skyCoverWork.level1.skylightPosition.vinylPart.init_coverLevel();
      this.struct.skyCoverWork.level1.skylightPosition.vinylPart.algorithmPart();
    }
  }

  /**
   * 알고리즘 호출: 피복 고정 파트(피복 고정), 피복 고정 파트(개폐기), 피복 고정 파트(치마 피복), 피복 고정 파트(바람막이 피복), 피복 고정 파트(칸막이) <- 피복 파트(기초 피복) <br/>
   * (속도에 너무 큰 영향을 줌..)
   */
  public callAlgorithm_fixingPartByCover(): void {
    // 피복 고정
    for (const fixingPosition of this.struct.fixingWork.levelAC[this.index].positionAC as FixingPosition[]) {
      fixingPosition.normalPadPart.algorithmPart();
      fixingPosition.laggingPadPart.algorithmPart();
    }

    // 개폐기 피복 고정
    // for (const switcherPosition of this.struct.switcherWork.levelAC[this.index].positionAC as SwitcherPosition[]) {
    //   switcherPosition.fixingNormalPadPart.algorithmPart();
    //   switcherPosition.fixingLaggingPadPart.algorithmPart();
    // }

    // 치마 피복 고정
    for (const skirtPosition of this.struct.skirtWork.levelAC[this.index].positionAC as SkirtPosition[]) {
      skirtPosition.fixingNormalPadPart.algorithmPart();
      skirtPosition.fixingLaggingPadPart.algorithmPart();
    }

    // 바람막이 피복 고정
    for (const windbreakPosition of this.struct.windbreakWork.levelAC[this.index].positionAC as WindbreakPosition[]) {
      windbreakPosition.fixingNormalPadPart.algorithmPart();
      windbreakPosition.fixingLaggingPadPart.algorithmPart();
    }

    // 창문주변 고정
    if (this.index === 0) {
      for (const windowPosition of this.struct.windowWork.levelAC[this.index].positionAC as WindowPosition[]) {
        windowPosition.fixingNormalPadPart.algorithmPart();
        windowPosition.fixingLaggingPadPart.algorithmPart();
      }
    }

    // 칸막이 피복 고정
    if (this.index === 0) {
      (
        this.struct.partitionWork.levelAC[this.index] as PartitionLevel
      ).trunkPosition.fixingNormalPadPart.algorithmPart();
      (
        this.struct.partitionWork.levelAC[this.index] as PartitionLevel
      ).trunkPosition.fixingLaggingPadPart.algorithmPart();
    }
  }

  /**
   * 알고리즘: 위치별 피복 겹수 및 형태 <- 선택(위치), 범위 형태(위치), 형태(형태), 피복 추가, 피복 삭제
   */
  public algorithm_coverLayersAndTypes(): void {
    this.coverLayers = [0, 0, 0, 0];
    this.skirtCoverLayers = [0, 0, 0, 0];
    this.windbreakCoverLayers = [0, 0, 0, 0];
    this.partitionCoverLayer = 0;
    this.coverTypes = ["", "", "", ""];
    this.coverThicknesses1 = [0, 0, 0, 0, 0];
    this.coverThicknesses2 = [0, 0, 0, 0, 0];

    for (const coverPosition of this.positionAC as CoverPosition[]) {
      if (coverPosition.scopeSelectedSide === true) {
        // 피복 겹수
        if (coverPosition.selected === true) {
          this.coverLayers[0] += 1;
          // 피복 두께값
          if (coverPosition.switcherOrderSide === 1) {
            this.coverThicknesses1[0] += coverPosition.thicknessValue;
          } else if (coverPosition.switcherOrderSide === 2) {
            this.coverThicknesses2[0] += coverPosition.thicknessValue;
          }
        }
        if (coverPosition.skirtCoverSide === true) {
          this.skirtCoverLayers[0] += 1;
        }
        if (coverPosition.windbreakCoverSide === true) {
          this.windbreakCoverLayers[0] += 1;
        }
        // 피복 형태
        if (this.coverTypes[0] === "") {
          this.coverTypes[0] = coverPosition.type;
        } else {
          this.coverTypes[0] += CONST.DELIMITER_COVER + coverPosition.type;
        }
      }
      if (coverPosition.scopeSelectedRoof === true) {
        // 피복 겹수
        if (coverPosition.selected === true) {
          this.coverLayers[1] += 1;
          // 피복 두께값
          if (coverPosition.switcherOrderRoof === 1) {
            this.coverThicknesses1[1] += coverPosition.thicknessValue;
          } else if (coverPosition.switcherOrderRoof === 2) {
            this.coverThicknesses2[1] += coverPosition.thicknessValue;
          }
        }
        if (coverPosition.skirtCoverRoof === true) {
          this.skirtCoverLayers[1] += 1;
        }
        if (coverPosition.windbreakCoverRoof === true) {
          this.windbreakCoverLayers[1] += 1;
        }
        // 피복 형태
        if (this.coverTypes[1] === "") {
          this.coverTypes[1] = coverPosition.type;
        } else {
          this.coverTypes[1] += CONST.DELIMITER_COVER + coverPosition.type;
        }
      }
      if (coverPosition.scopeSelectedFront === true) {
        // 피복 겹수
        if (coverPosition.selected === true) {
          this.coverLayers[2] += 1;
          // 피복 두께값
          if (coverPosition.switcherOrderFront === 1) {
            this.coverThicknesses1[2] += coverPosition.thicknessValue;
          } else if (coverPosition.switcherOrderFront === 2) {
            this.coverThicknesses2[2] += coverPosition.thicknessValue;
          }
        }
        if (coverPosition.skirtCoverFront === true) {
          this.skirtCoverLayers[2] += 1;
        }
        if (coverPosition.windbreakCoverFront === true) {
          this.windbreakCoverLayers[2] += 1;
        }
        // 피복 형태
        if (this.coverTypes[2] === "") {
          this.coverTypes[2] = coverPosition.type;
        } else {
          this.coverTypes[2] += CONST.DELIMITER_COVER + coverPosition.type;
        }
      }
      if (coverPosition.scopeSelectedBack === true) {
        // 피복 겹수
        if (coverPosition.selected === true) {
          this.coverLayers[3] += 1;
          // 피복 두께값
          if (coverPosition.switcherOrderBack === 1) {
            this.coverThicknesses1[3] += coverPosition.thicknessValue;
          } else if (coverPosition.switcherOrderBack === 2) {
            this.coverThicknesses2[3] += coverPosition.thicknessValue;
          }
        }
        if (coverPosition.skirtCoverBack === true) {
          this.skirtCoverLayers[3] += 1;
        }
        if (coverPosition.windbreakCoverBack === true) {
          this.windbreakCoverLayers[3] += 1;
        }
        // 피복 형태
        if (this.coverTypes[3] === "") {
          this.coverTypes[3] = coverPosition.type;
        } else {
          this.coverTypes[3] += CONST.DELIMITER_COVER + coverPosition.type;
        }
      }
      if (coverPosition.partitionCoverTrunk === true) {
        this.partitionCoverLayer += 1;
      }
    }
  }

  // @override
  public addPosition(refPosition: Position, maxLength: number = CONST.NUM_COVER_POSITION_MAX): Position {
    if (this.positionAC.length >= maxLength) {
      return null;
    }

    const newIndex: number = refPosition.index + 1;
    const refCoverPosition: CoverPosition = refPosition as CoverPosition;
    const newCoverPosition: CoverPosition = new CoverPosition();

    newCoverPosition.setAssociation(this.design, this.struct, this.work, this);
    newCoverPosition.setReferenceVariable();
    newCoverPosition.setDefaultData(
      newIndex,
      true,
      true,
      true,
      newIndex + 1 + CONST.LB_POSITION_COVER,
      CONST.LB_COVER_TYPE_VINYL,
      refCoverPosition.scopeType,
      refCoverPosition.switcherOrderSide,
      refCoverPosition.switcherOrderRoof,
      refCoverPosition.switcherOrderFront,
      refCoverPosition.switcherOrderBack,
      refCoverPosition.skirtCoverSide,
      refCoverPosition.skirtCoverRoof,
      refCoverPosition.skirtCoverFront,
      refCoverPosition.skirtCoverBack,
      refCoverPosition.windbreakCoverSide,
      refCoverPosition.windbreakCoverRoof,
      refCoverPosition.windbreakCoverFront,
      refCoverPosition.windbreakCoverBack,
      refCoverPosition.partitionCoverTrunk,
      refCoverPosition.skylightCover,
    );
    newCoverPosition.setDefaultVariable();
    newCoverPosition.setDefaultModel();
    this.positionAC.splice(newIndex, 0, newCoverPosition);
    this.reindexPosition();
    // 알고리즘
    this.algorithm_coverLayersAndTypes();
    newCoverPosition.algorithmBasic();

    /// //////// 외부 ///////////

    this.callAlgorithm_coverPartByCover();
    this.callAlgorithm_fixingPartByCover();

    return newCoverPosition;
  }

  // @override
  public deletePosition(position: Position, minLength: number = 1): boolean {
    const index: number = this.positionAC.indexOf(position);
    if (index !== -1 && this.positionAC.length > minLength) {
      this.positionAC.splice(index, 1);
      this.reindexPosition();
      // 알고리즘
      this.algorithm_coverLayersAndTypes();

      /// //////// 외부 ///////////

      this.callAlgorithm_coverPartByCover();
      this.callAlgorithm_fixingPartByCover();

      return true;
    }
    return false;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
