import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { ScreennetPartIL_Screennet } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPartIL_Screennet";
import { ScreennetPartIL_FixingLaggingPad } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPartIL_FixingLaggingPad";
import { ScreennetPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPartIL_FixingNormalPad";
import { ScreennetPartIL_FixingWood } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPartIL_FixingWood";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [
    ScreennetPartIL_FixingLaggingPad,
    ScreennetPartIL_FixingNormalPad,
    ScreennetPartIL_FixingWood,
    ScreennetPartIL_Screennet,
  ],
})
export class ScreennetPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public screennetPart: ScreennetPartIL_Screennet;
  public fixingNormalPadPart: ScreennetPartIL_FixingNormalPad;
  public fixingLaggingPadPart: ScreennetPartIL_FixingLaggingPad;
  public fixingWoodPart: ScreennetPartIL_FixingWood;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _fixingType: string; // 고정 형태

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 천창 피복
    if (this.label === CONST.LB_POSITION_SIDE_COLUMN || this.label === CONST.LB_POSITION_SIDE_WING) {
      this.struct.skyCoverWorkIL.level1.skylightPosition.algorithm_screennetSelected();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.screennetPart = new ScreennetPartIL_Screennet();
    this.fixingNormalPadPart = new ScreennetPartIL_FixingNormalPad();
    this.fixingLaggingPadPart = new ScreennetPartIL_FixingLaggingPad();
    this.fixingWoodPart = new ScreennetPartIL_FixingWood();

    this.partAC = [this.screennetPart, this.fixingNormalPadPart, this.fixingLaggingPadPart, this.fixingWoodPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.screennetPart = <ScreennetPartIL_Screennet>this.partAC[0];
    this.fixingNormalPadPart = <ScreennetPartIL_FixingNormalPad>this.partAC[1];
    this.fixingLaggingPadPart = <ScreennetPartIL_FixingLaggingPad>this.partAC[2];
    this.fixingWoodPart = <ScreennetPartIL_FixingWood>this.partAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param fixingType: string 고정 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._fixingType = fixingType;

    if (this.level.index >= 0) {
      // 방충망
      this.screennetPart.setDefaultData(
        CONST.INDEX_IL_SCREENNET_DEFAULT,
        true,
        false,
        true,
        CONST.LB_SCREENNET_DEFAULT,
        "",
      );

      // 방충망 고정 - 일반 패드 고정
      this.fixingNormalPadPart.setDefaultData(
        CONST.INDEX_IL_SCREENNET_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_SCREENNET_FIXING_NORMAL_PAD,
        "",
      );

      // 방충망 고정 - 광폭 패드 고정
      this.fixingLaggingPadPart.setDefaultData(
        CONST.INDEX_IL_SCREENNET_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_SCREENNET_FIXING_LAGGING_PAD,
        "",
      );

      // 방충망 고정 - 목재 고정
      this.fixingWoodPart.setDefaultData(
        CONST.INDEX_IL_SCREENNET_FIXING_WOOD,
        false,
        false,
        false,
        CONST.LB_SCREENNET_FIXING_WOOD,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 선택 <- 선택(개폐기)
   */
  public algorithm_selected(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );

    if (switcherPosition.selected) {
      // selected = true;
    } else {
      this.selected = false;
    }
  }

  /**
   * 알고리즘: 가용성 <- 선택(개폐기), 선택(커튼 개폐)
   */
  public algorithm_enabled(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );
    const curSwitchLevel: CurSwitchLevelIL = this.struct.curSwitchWorkIL.level1;

    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.enabled = switcherPosition.selected || curSwitchLevel.columnPosition.selected;
    } else {
      this.enabled = switcherPosition.selected;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_SCREENNET_TYPE_DEFAULT) {
      this.screennetPart.selected = true;
      this.screennetPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
      this.fixingNormalPadPart.selected = true;
      this.fixingNormalPadPart.visible = true;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingLaggingPadPart.selected = true;
      this.fixingLaggingPadPart.visible = true;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_WOOD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = true;
      this.fixingWoodPart.visible = true;
    } else {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태(피복 고정)
   */
  public algorithm_fixingTypeByFixing(): void {
    const fixingPosition: FixingPositionIL = <FixingPositionIL>(
      this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
    );

    if (
      fixingPosition.type === CONST.LB_FIXING_TYPE_NORMAL_PAD ||
      fixingPosition.type === CONST.LB_FIXING_TYPE_LAGGING_PAD ||
      fixingPosition.type === CONST.LB_FIXING_TYPE_WOOD
    ) {
      this.fixingType = fixingPosition.type;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
