import { jsonObject, jsonMember } from "typedjson";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-07-20
 */
@jsonObject
export class BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  /** DB 아이디 */
  @jsonMember(Number)
  public id: number = null;
  /** DB 생성 일시 */
  @jsonMember(Date)
  public createDate: Date = null;
  /** DB 변경 일시 */
  @jsonMember(Date)
  public dbUpdate: Date = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * @param origin 원본 객체
   */
  public deepCopy(origin: BaseEntity): void {
    this.id = origin.id;
    this.createDate = origin.createDate;
    this.dbUpdate = origin.dbUpdate;
  }
}
