import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { CoverPart } from "vhows-design/src/object/design/cover/cover/CoverPart";
import { CoverSample_Vinyl } from "vhows-design/src/object/design/cover/cover/CoverSample_Vinyl";
import { CoverSample_VinylNormalSkirt } from "vhows-design/src/object/design/cover/cover/CoverSample_VinylNormalSkirt";
import { CoverSample_VinylWindbreak } from "vhows-design/src/object/design/cover/cover/CoverSample_VinylWindbreak";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-06
 */
@jsonObject({
  knownTypes: [CoverSample_Vinyl, CoverSample_VinylNormalSkirt, CoverSample_VinylWindbreak],
})
export class CoverPart_Vinyl extends CoverPart {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public vinylSample: CoverSample_Vinyl; // 비닐 샘플
  public normalSkirtVinylSample: CoverSample_VinylNormalSkirt; // 일반 치마 비닐 샘플
  public windbreakVinylSample: CoverSample_VinylWindbreak; // 바람막이 비닐 샘플

  // 아이템
  protected item: ItemVinyl; // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.vinylSample = new CoverSample_Vinyl();
    this.normalSkirtVinylSample = new CoverSample_VinylNormalSkirt();
    this.windbreakVinylSample = new CoverSample_VinylWindbreak();

    this.sampleAC = [this.vinylSample, this.normalSkirtVinylSample, this.windbreakVinylSample];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.vinylSample = <CoverSample_Vinyl>this.sampleAC[0];
    this.normalSkirtVinylSample = <CoverSample_VinylNormalSkirt>this.sampleAC[1];
    this.windbreakVinylSample = <CoverSample_VinylWindbreak>this.sampleAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index === 0) {
      this.vinylSample.setDefaultData(
        0,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "지붕+측면",
        "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.normalSkirtVinylSample.setDefaultData(
        1,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "일반 치마",
        "0.1mm, 120cm, 56m, 일타, 말대(치마), 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.windbreakVinylSample.setDefaultData(
        2,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "바람막이",
        "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
    } else {
      this.vinylSample.setDefaultData(
        0,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "지붕+측면",
        "0.08mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.normalSkirtVinylSample.setDefaultData(
        1,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "일반 치마",
        "0.1mm, 120cm, 56m, 일타, 말대(치마), 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.windbreakVinylSample.setDefaultData(
        2,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "바람막이",
        "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      const normalSkirtVinylSample: CoverSample_VinylNormalSkirt = new CoverSample_VinylNormalSkirt(); // 일반 치마 비닐 샘플
      normalSkirtVinylSample.setAssociation(design, struct, work, level, position, this);
      normalSkirtVinylSample.setDefaultData(
        1,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "일반 치마",
        "0.1mm, 120cm, 56m, 일타, 말대(치마), 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.sampleAC.push(normalSkirtVinylSample);

      const windbreakVinylSample: CoverSample_VinylWindbreak = new CoverSample_VinylWindbreak(); // 바람막이 비닐 샘플
      windbreakVinylSample.setAssociation(design, struct, work, level, position, this);
      windbreakVinylSample.setDefaultData(
        2,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "바람막이",
        "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.sampleAC.push(windbreakVinylSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    //----------------------------------
    // 품목
    // 피복객체의 값을 실제 품목에 반영
    //----------------------------------

    // 가변길이인 경우
    if (this.basic.isVariableLength === true) {
      // 동수만큼 품목 생성
      for (let i: number = 0; i < this.basic.buildingNumber; i++) {
        // 측면
        if (this.coverSide != null) {
          this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

          this.item.purpose = `${i + 1}번동 피복: ${this.coverSide.purpose}`;
          this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverSide.width));
          this.item.specLength = this.coverSide.lengthAC[i];
          this.item.designQuantity = this.coverSide.quantity;

          this.itemAC.push(this.item);
        }

        // 측면(+측면+마구리) - 비대칭 천창(짧은쪽)
        if (this.coverSideSkyShort != null) {
          this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

          this.item.purpose = `${i + 1}번동 피복: ${this.coverSideSkyShort.purpose}`;
          this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverSideSkyShort.width));
          this.item.specLength = this.coverSideSkyShort.lengthAC[i];
          this.item.designQuantity = this.coverSideSkyShort.quantity;

          this.itemAC.push(this.item);
        }

        // 지붕(+측면+마구리)
        if (this.coverRoof != null) {
          this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

          this.item.purpose = `${i + 1}번동 피복: ${this.coverRoof.purpose}`;
          this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverRoof.width));
          this.item.specLength = this.coverRoof.lengthAC[i];
          this.item.designQuantity = this.coverRoof.quantity;

          this.itemAC.push(this.item);
        }

        // 지붕(+측면+마구리) - 비대칭 천창(짧은쪽)
        if (this.coverRoofSkyShort != null) {
          this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

          this.item.purpose = `${i + 1}번동 피복: ${this.coverRoofSkyShort.purpose}`;
          this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverRoofSkyShort.width));
          this.item.specLength = this.coverRoofSkyShort.lengthAC[i];
          this.item.designQuantity = this.coverRoofSkyShort.quantity;

          this.itemAC.push(this.item);
        }
      }
    } else {
      // 측면
      if (this.coverSide != null) {
        this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

        this.item.purpose = `피복: ${this.coverSide.purpose}`;
        this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverSide.width));
        this.item.specLength = this.coverSide.length;
        this.item.designQuantity = this.coverSide.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }

      // 측면(+측면+마구리) - 비대칭 천창(짧은쪽)
      if (this.coverSideSkyShort != null) {
        this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

        this.item.purpose = `피복: ${this.coverSideSkyShort.purpose}`;
        this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverSideSkyShort.width));
        this.item.specLength = this.coverSideSkyShort.length;
        this.item.designQuantity = this.coverSideSkyShort.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }

      // 특수
      if (this.coverOther != null) {
        this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

        this.item.purpose = `피복: ${this.coverOther.purpose}`;
        this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverOther.width));
        this.item.specLength = this.coverOther.length;
        this.item.designQuantity = this.coverOther.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }

      // 지붕(+측면+마구리)
      if (this.coverRoof != null) {
        this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

        this.item.purpose = `피복: ${this.coverRoof.purpose}`;
        this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverRoof.width));
        this.item.specLength = this.coverRoof.length;
        this.item.designQuantity = this.coverRoof.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }

      // 지붕(+측면+마구리) - 비대칭 천창(짧은쪽)
      if (this.coverRoofSkyShort != null) {
        this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

        this.item.purpose = `피복: ${this.coverRoofSkyShort.purpose}`;
        this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverRoofSkyShort.width));
        this.item.specLength = this.coverRoofSkyShort.length;
        this.item.designQuantity = this.coverRoofSkyShort.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }
    }

    // 앞면(+뒷면)
    if (this.coverFront != null) {
      this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

      this.item.purpose = `마구리 피복: ${this.coverFront.purpose}`;
      this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverFront.width));
      this.item.specLength = this.coverFront.length;
      this.item.designQuantity = 1;

      this.itemAC.push(this.item);
    }

    // 뒷면
    if (this.coverBack != null) {
      this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

      this.item.purpose = `마구리 피복: ${this.coverBack.purpose}`;
      this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverBack.width));
      this.item.specLength = this.coverBack.length;
      this.item.designQuantity = 1;

      this.itemAC.push(this.item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);

    /// //////// 외부 ///////////

    // 알고리즘 호출: 피복 파트
    (<CoverLevel>this.level).callAlgorithm_coverPartByCover();

    // 알고리즘 호출: 피복 고정 파트
    (<CoverLevel>this.level).callAlgorithm_fixingPartByCover();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 아이템 <- 샘플
   */
  public algorithmSpec_item(): void {
    for (const item of this.itemAC as ItemVinyl[]) {
      item.specsList = this.vinylSample.specsList;
      item.specWidthType = this.vinylSample.specWidthType;
      item.specPackingType = this.vinylSample.specPackingType;
      item.specStringType = this.vinylSample.specStringType;
      item.brands = this.vinylSample.brands;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
