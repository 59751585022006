import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 미니 스프링클러 튜브셋
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemMiniSprinklerTubeSet extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, 지름], (길이), [니플형태], [연결형태]
  public _specFeature: string = undefined; // 특성
  public _specDiameter: string = undefined; // 지름
  public _specLength: number = undefined; // 길이
  public _specNippleType: string = undefined; // 니플형태
  public _specConnectionType: string = undefined; // 연결형태

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 지름
   */
  public get specDiameter(): string {
    return this._specDiameter;
  }
  //
  public set specDiameter(value: string) {
    this._specDiameter = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 1m 간격(소수점 첫째자리 올림)
    this._specLength = Math.ceil(value);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_CENTIMETER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  /**
   * 니플형태
   */
  public get specNippleType(): string {
    return this._specNippleType;
  }
  //
  public set specNippleType(value: string) {
    this._specNippleType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 연결형태
   */
  public get specConnectionType(): string {
    return this._specConnectionType;
  }
  //
  public set specConnectionType(value: string) {
    this._specConnectionType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.miniSprinklerTubeSetCategoryModel;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 길이 단가
    const lengthUnitSupply: number = this.brandCompanyModel.optionUnitSupply1;

    // 매출가 계산식
    let sellingSupply: number = defaultUnitSupply + lengthUnitSupply * this.specLength;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    // 나눠 들어가는 순서도 중요함;;
    this.specFeature = specsArray[0];
    this.specDiameter = specsArray[1];
    this.specLengthLabel = specsArray[2];
    this.specNippleType = specsArray[3];
    this.specConnectionType = specsArray[4];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specDiameter = specsArray[1];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature + CONST.DELIMITER_SPEC + this._specDiameter;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList +
      CONST.DELIMITER_SPEC +
      this.specLengthLabel +
      CONST.DELIMITER_SPEC +
      this._specNippleType +
      CONST.DELIMITER_SPEC +
      this._specConnectionType;
  }
}
