import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCustomMaterial } from "vhows-design/src/object/design/item/list/ItemCustomMaterial";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { EndpiecePartIL_Gate } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_Gate";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2020-10-27
 */
@jsonObject
export class EndpieceSampleIL_CustomDoor extends ItemCustomMaterial {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  /**
   * 설계 수량
   */
  // @override
  public get designQuantity(): number {
    return this._designQuantity;
  }

  //
  public set designQuantity(value: number) {
    if (value < 0) {
      this._designQuantity = 0;
    } else {
      this._designQuantity = CommonUtil.roundUp(CommonUtil.fixFloat(value), 1);
    }

    // 알고리즘
    if (this.isInit === false) {
      // 파트 알고리즘 호출
      this.part.algorithmPart();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const gatePart: EndpiecePartIL_Gate = <EndpiecePartIL_Gate>this.part;
    gatePart.algorithmSpec_gate_customDoor();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevelIL>this.level).backPosition.gatePart.customDoorSample.specs = this.specs;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘 규격 <- 문짝 폭(파트), 문짝 높이(파트)
   */
  // @override
  public algorithmSpec(): void {
    const gatePart: EndpiecePartIL_Gate = <EndpiecePartIL_Gate>this.part;

    this.specs = `${gatePart.doorWidth + CONST.UNIT_MILLIMETER}*${gatePart.doorHeight}${CONST.UNIT_MILLIMETER}`;
  }

  /**
   * 알고리즘 수량 <- 문짝 개수(파트), 출입 동수(위치)
   */
  public algorithmQuantity(): void {
    const gatePart: EndpiecePartIL_Gate = <EndpiecePartIL_Gate>this.part;

    this.designQuantity = gatePart.doorQuantity * (this.position as EndpiecePositionIL).gateBuildingNumber;
  }

  // @override
  protected algorithm_customUnitSupply(): void {
    super.algorithm_customUnitSupply();

    const gatePart: EndpiecePartIL_Gate = <EndpiecePartIL_Gate>this.part;
    gatePart.algorithmSpec_gate_customDoor();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevelIL>this.level).backPosition.gatePart.customDoorSample.customUnitSupply = this.customUnitSupply;
    }
  }
}
