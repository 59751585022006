import { jsonArrayMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Company } from "vhows-design/src/object/company/Company";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { ProductCompanyModel } from "vhows-design/src/object/item/product/ProductCompanyModel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-26
 */
@jsonObject({
  knownTypes: [CategoryCompanyModel],
})
export class ItemModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 조직 모델 */
  public company: Company = null;

  /** 전체 상품 모델 배열 */
  public productCompanyModelAllAC: ProductCompanyModel[] = null;

  // 골조 자재
  public pipeCategoryModel: CategoryCompanyModel = null;
  public supportPipeCategoryModel: CategoryCompanyModel = null;
  public steelBarCategoryModel: CategoryCompanyModel = null;
  // 골조 부속 자재
  public bracketCategoryModel: CategoryCompanyModel = null;
  public connectorCategoryModel: CategoryCompanyModel = null;
  public holderCategoryModel: CategoryCompanyModel = null;
  public sconceCategoryModel: CategoryCompanyModel = null;
  public diamondClampCategoryModel: CategoryCompanyModel = null;
  public doorCategoryModel: CategoryCompanyModel = null;
  public doorMadeCategoryModel: CategoryCompanyModel = null;
  public hingedDoorCategoryModel: CategoryCompanyModel = null;
  public sashDoorCategoryModel: CategoryCompanyModel = null;
  public rollerCategoryModel: CategoryCompanyModel = null;
  public cementCategoryModel: CategoryCompanyModel = null;
  public screwCategoryModel: CategoryCompanyModel = null;
  public frameOtherCategoryModel: CategoryCompanyModel = null;
  // 물받이 자재
  public ironPlateGutterCategoryModel: CategoryCompanyModel = null;
  public plasticGutterCategoryModel: CategoryCompanyModel = null;
  public ubarCategoryModel: CategoryCompanyModel = null;
  public gutterHangerCategoryModel: CategoryCompanyModel = null;
  // 개폐기 자재
  public manualSwitcherCategoryModel: CategoryCompanyModel = null;
  public powerSwitcherCategoryModel: CategoryCompanyModel = null;
  public skylightMotorCategoryModel: CategoryCompanyModel = null;
  public rackpinionSetCategoryModel: CategoryCompanyModel = null;
  public electricWireCategoryModel: CategoryCompanyModel = null;
  public switcherAssistCategoryModel: CategoryCompanyModel = null;
  public integratedControllerCategoryModel: CategoryCompanyModel = null;
  public controllerSensorCategoryModel: CategoryCompanyModel = null;
  public switcherOtherCategoryModel: CategoryCompanyModel = null;
  // 피복 자재
  public vinylCategoryModel: CategoryCompanyModel = null;
  public weavingFilmCategoryModel: CategoryCompanyModel = null;
  public shadenetCategoryModel: CategoryCompanyModel = null;
  public tentCategoryModel: CategoryCompanyModel = null;
  public nonwovenCategoryModel: CategoryCompanyModel = null;
  public cashmilonCategoryModel: CategoryCompanyModel = null;
  public laggingCategoryModel: CategoryCompanyModel = null;
  public quiltingCategoryModel: CategoryCompanyModel = null;
  public screenCategoryModel: CategoryCompanyModel = null;
  public matCategoryModel: CategoryCompanyModel = null;
  public screennetCategoryModel: CategoryCompanyModel = null;
  // 피복 부속 자재
  public padCategoryModel: CategoryCompanyModel = null;
  public padConnectorCategoryModel: CategoryCompanyModel = null;
  public padSpringCategoryModel: CategoryCompanyModel = null;
  public padVinylCategoryModel: CategoryCompanyModel = null;
  public clipCategoryModel: CategoryCompanyModel = null;
  public woodCategoryModel: CategoryCompanyModel = null;
  public bandstringCategoryModel: CategoryCompanyModel = null;
  public steelWireCategoryModel: CategoryCompanyModel = null;
  // 환기 자재
  public ventilatorCategoryModel: CategoryCompanyModel = null;
  public ventFanCategoryModel: CategoryCompanyModel = null;
  public ventHoleCategoryModel: CategoryCompanyModel = null;
  public ventHoleSwitchSetCategoryModel: CategoryCompanyModel = null;
  public airFlowFanCategoryModel: CategoryCompanyModel = null;
  // 커튼 자재
  public reducedMotorCategoryModel: CategoryCompanyModel = null;
  public bearingCategoryModel: CategoryCompanyModel = null;
  public windingDrumCategoryModel: CategoryCompanyModel = null;
  public wireStrainCategoryModel: CategoryCompanyModel = null;
  public trussClipCategoryModel: CategoryCompanyModel = null;
  public towClipCategoryModel: CategoryCompanyModel = null;
  public curtainOtherCategoryModel: CategoryCompanyModel = null;
  // 관수 자재
  public nutrientSolutionCategoryModel: CategoryCompanyModel = null;
  public waterSolutionCategoryModel: CategoryCompanyModel = null;
  public waterTankCategoryModel: CategoryCompanyModel = null;
  public waterTankFittingCategoryModel: CategoryCompanyModel = null;
  public waterTankControllerCategoryModel: CategoryCompanyModel = null;
  public waterPumpCategoryModel: CategoryCompanyModel = null;
  public waterPumpConnectCategoryModel: CategoryCompanyModel = null;
  public waterPumpHoseCategoryModel: CategoryCompanyModel = null;
  public waterPumpControllerCategoryModel: CategoryCompanyModel = null;
  public waterFilterCategoryModel: CategoryCompanyModel = null;
  public wateringPipeCategoryModel: CategoryCompanyModel = null;
  public wateringValveCategoryModel: CategoryCompanyModel = null;
  public wateringConnectCategoryModel: CategoryCompanyModel = null;
  public wateringHangerCategoryModel: CategoryCompanyModel = null;
  public wateringTubeCategoryModel: CategoryCompanyModel = null;
  public wateringTubeNippleCategoryModel: CategoryCompanyModel = null;
  public miniSprinklerCategoryModel: CategoryCompanyModel = null;
  public miniSprinklerDropGuardCategoryModel: CategoryCompanyModel = null;
  public miniSprinklerTubeSetCategoryModel: CategoryCompanyModel = null;
  public dripPipeCategoryModel: CategoryCompanyModel = null;
  public dripButtonCategoryModel: CategoryCompanyModel = null;
  public dripButtonAppendageCategoryModel: CategoryCompanyModel = null;
  public dripBarbCategoryModel: CategoryCompanyModel = null;
  public dripValveCategoryModel: CategoryCompanyModel = null;
  public dripConnectCategoryModel: CategoryCompanyModel = null;
  public fountainHoseCategoryModel: CategoryCompanyModel = null;
  public fountainHoseConnectCategoryModel: CategoryCompanyModel = null;
  public fountainHoseHangerCategoryModel: CategoryCompanyModel = null;
  public wateringToolCategoryModel: CategoryCompanyModel = null;
  public wateringOtherCategoryModel: CategoryCompanyModel = null;
  // 배수 자재
  public drainPipeCategoryModel: CategoryCompanyModel = null;
  public pvcValveCategoryModel: CategoryCompanyModel = null;
  public pvcConnectorCategoryModel: CategoryCompanyModel = null;
  public drainspoutBoxCategoryModel: CategoryCompanyModel = null;
  public drainPipeHangerCategoryModel: CategoryCompanyModel = null;
  // 재배대 자재
  public formingBedCategoryModel: CategoryCompanyModel = null;
  public styrofoamBedCategoryModel: CategoryCompanyModel = null;
  public nftBedCategoryModel: CategoryCompanyModel = null;
  public bedAppendageCategoryModel: CategoryCompanyModel = null;
  public groundCoverCategoryModel: CategoryCompanyModel = null;
  public groundCoverAppendageCategoryModel: CategoryCompanyModel = null;
  public trellisStringAppendageCategorymodel: CategoryCompanyModel = null;
  // 운반 자재
  public carrierCategoryModel: CategoryCompanyModel = null;
  public groundCarrierCategoryModel: CategoryCompanyModel = null;
  public railHookCategoryModel: CategoryCompanyModel = null;
  public railIntervalCategoryModel: CategoryCompanyModel = null;
  // 냉난방 자재
  public boilerCategoryModel: CategoryCompanyModel = null;
  public heatingFanCategoryModel: CategoryCompanyModel = null;
  public heatingTubeCategoryModel: CategoryCompanyModel = null;
  // 잡 자재
  public cordWireCategoryModel: CategoryCompanyModel = null;
  // 기타 자재
  public customMaterialCategoryModel: CategoryCompanyModel = null;
  public buildingMisCategoryModel: CategoryCompanyModel = null;
  // 기타 자재비
  public materialDiscountCostCategoryModel: CategoryCompanyModel = null;
  // 기타 경비
  public customExpenseCategoryModel: CategoryCompanyModel = null;
  public otherExpenseCategoryModel: CategoryCompanyModel = null;
  public pipeBendingExpenseCategoryModel: CategoryCompanyModel = null;
  public equipmentExpenseCategoryModel: CategoryCompanyModel = null;
  // 기타 인건비
  public customLaborCostCategoryModel: CategoryCompanyModel = null;
  public buildingDiscountCostCategoryModel: CategoryCompanyModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 분류 모델 배열 */
  @jsonArrayMember(() => CategoryCompanyModel)
  public categoryCompanyModelAC: CategoryCompanyModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(): void {
    // 자식 연관
    let categoryCompanyModel: CategoryCompanyModel;
    for (categoryCompanyModel of this.categoryCompanyModelAC) {
      categoryCompanyModel.setAssociation(this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 골조 자재
    this.pipeCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[0];
    this.supportPipeCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[1];
    this.steelBarCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[101];
    // 골조 부속 자재
    this.bracketCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[115];
    this.connectorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[2];
    this.holderCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[3];
    this.sconceCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[5];
    this.diamondClampCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[94];
    this.doorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[6];
    this.doorMadeCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[60];
    this.hingedDoorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[61];
    this.sashDoorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[118]; // <----- 마지막
    this.rollerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[55];
    this.cementCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[9];
    this.screwCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[8];
    this.frameOtherCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[10];
    // 물받이 자재
    this.ironPlateGutterCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[11];
    this.plasticGutterCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[12];
    this.ubarCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[14];
    this.gutterHangerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[56];
    // 개폐기 자재
    this.manualSwitcherCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[15];
    this.powerSwitcherCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[16];
    this.skylightMotorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[103];
    this.rackpinionSetCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[102];
    this.electricWireCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[18];
    this.switcherAssistCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[19];
    this.integratedControllerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[17];
    this.controllerSensorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[95];
    this.switcherOtherCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[20];
    // 피복 자재
    this.vinylCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[21];
    this.weavingFilmCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[13];
    this.shadenetCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[22];
    this.tentCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[23];
    this.nonwovenCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[24];
    this.cashmilonCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[25];
    this.laggingCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[26];
    this.quiltingCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[27];
    this.screenCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[28];
    this.matCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[29];
    this.screennetCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[30];
    // 피복 부속 자재
    this.padCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[31];
    this.padConnectorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[32];
    this.padSpringCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[33];
    this.padVinylCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[34];
    this.clipCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[35];
    this.woodCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[36];
    this.bandstringCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[37];
    this.steelWireCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[39];
    // 환기 자재
    this.ventilatorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[87];
    this.ventFanCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[88];
    this.ventHoleCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[89];
    this.ventHoleSwitchSetCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[90];
    this.airFlowFanCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[91];
    // 커튼 자재
    this.reducedMotorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[44];
    this.bearingCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[45];
    this.windingDrumCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[46];
    this.wireStrainCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[47];
    this.trussClipCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[117];
    this.towClipCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[49];
    this.curtainOtherCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[50];
    // 관수 자재
    this.nutrientSolutionCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[104];
    this.waterSolutionCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[100];
    this.waterTankCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[62];
    this.waterTankFittingCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[84];
    this.waterTankControllerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[105];
    this.waterPumpCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[63];
    this.waterPumpConnectCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[64];
    this.waterPumpHoseCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[106];
    this.waterPumpControllerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[107];
    this.waterFilterCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[65];
    this.wateringPipeCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[40];
    this.wateringValveCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[67];
    this.wateringConnectCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[66];
    this.wateringHangerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[69];
    this.wateringTubeCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[70];
    this.wateringTubeNippleCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[71];
    this.miniSprinklerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[72];
    this.miniSprinklerDropGuardCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[73];
    this.miniSprinklerTubeSetCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[74];
    this.dripPipeCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[75];
    this.dripButtonCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[76];
    this.dripButtonAppendageCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[77];
    this.dripBarbCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[68];
    this.dripValveCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[86];
    this.dripConnectCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[85];
    this.fountainHoseCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[78];
    this.fountainHoseConnectCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[79];
    this.fountainHoseHangerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[80];
    this.wateringToolCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[81];
    this.wateringOtherCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[82];
    // 배수 자재
    this.drainPipeCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[7];
    this.pvcValveCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[116];
    this.pvcConnectorCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[57];
    this.drainspoutBoxCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[58];
    this.drainPipeHangerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[59];
    // 재배대 자재
    this.formingBedCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[108];
    this.styrofoamBedCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[109];
    this.nftBedCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[110];
    this.bedAppendageCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[111];
    this.groundCoverCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[112];
    this.groundCoverAppendageCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[113];
    this.trellisStringAppendageCategorymodel = <CategoryCompanyModel>this.categoryCompanyModelAC[114];
    // 운반 자재
    this.carrierCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[96];
    this.groundCarrierCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[97];
    this.railHookCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[98];
    this.railIntervalCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[99];
    // 냉난방 자재
    this.boilerCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[4];
    this.heatingFanCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[38];
    this.heatingTubeCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[48];
    // 잡 자재
    this.cordWireCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[83];
    // 기타 자재
    this.customMaterialCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[41];
    this.buildingMisCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[51];
    // 기타 자재비
    this.materialDiscountCostCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[43];
    // 기타 경비
    this.customExpenseCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[52];
    this.otherExpenseCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[53];
    this.pipeBendingExpenseCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[92];
    this.equipmentExpenseCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[93];
    // 기타 인건비
    this.customLaborCostCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[54];
    this.buildingDiscountCostCategoryModel = <CategoryCompanyModel>this.categoryCompanyModelAC[42];

    // 자식 레퍼런스변수
    let categoryCompanyModel: CategoryCompanyModel;
    for (categoryCompanyModel of this.categoryCompanyModelAC) {
      categoryCompanyModel.setReferenceVariable();
    }

    // 전체 상품 모델AC 만들기
    this.productCompanyModelAllAC = [];
    for (categoryCompanyModel of this.categoryCompanyModelAC) {
      let productCompanyModel: ProductCompanyModel;
      for (productCompanyModel of categoryCompanyModel.productCompanyModelAC) {
        this.productCompanyModelAllAC.push(productCompanyModel);
      }
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 자식 초기화
    let categoryCompanyModel: CategoryCompanyModel;
    for (categoryCompanyModel of this.categoryCompanyModelAC) {
      categoryCompanyModel.setDefaultVariable();
    }
  }

  //----------------------------------
  // 품목 모델
  //----------------------------------

  /**
   * 분류 모델 가져오기
   * @param category3 카테고리3 문자열
   * @return 분류 모델
   */
  public getCategoryModel(category3: string): CategoryCompanyModel {
    let categoryCompanyModel: CategoryCompanyModel;
    for (categoryCompanyModel of this.categoryCompanyModelAC) {
      if (categoryCompanyModel.categoryModel.category3 === category3) {
        return categoryCompanyModel;
      }
    }
    return null;
  }
}
