import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemWood } from "vhows-design/src/object/design/item/list/ItemWood";
import { Part } from "vhows-design/src/object/design/base/Part";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { FixingSampleIL_RectLumber } from "vhows-design/src/object/design/cover/fixing/il/FixingSampleIL_RectLumber";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject({
  knownTypes: [FixingSampleIL_RectLumber],
})
export class FixingPartIL_Wood extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public rectLumberSample: FixingSampleIL_RectLumber; // 각재 샘플

  // 아이템
  public wood_rectLumber: ItemWood;
  public woodTackerShort_tackerPin: ItemFrameOther;
  public woodTackerLong_tackerPin: ItemFrameOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.rectLumberSample = new FixingSampleIL_RectLumber();

    this.sampleAC = [this.rectLumberSample];

    // 아이템
    this.wood_rectLumber = new ItemWood();
    this.woodTackerShort_tackerPin = new ItemFrameOther();
    this.woodTackerLong_tackerPin = new ItemFrameOther();

    this.itemAC = [this.wood_rectLumber, this.woodTackerShort_tackerPin, this.woodTackerLong_tackerPin];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.rectLumberSample = <FixingSampleIL_RectLumber>this.sampleAC[0];

    // 아이템
    this.wood_rectLumber = <ItemWood>this.itemAC[0];
    this.woodTackerShort_tackerPin = <ItemFrameOther>this.itemAC[1];
    this.woodTackerLong_tackerPin = <ItemFrameOther>this.itemAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.rectLumberSample.setDefaultData(
        0,
        CONST.ITEM_ID_RECT_LUMBER,
        CONST.ITEM_NAME_RECT_LUMBER,
        "목재",
        "일반, 40×40mm, 3.6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.wood_rectLumber.setDefaultData(
        0,
        CONST.ITEM_ID_RECT_LUMBER,
        CONST.ITEM_NAME_RECT_LUMBER,
        "목재",
        "일반, 40×40mm, 3.6m",
      );
      this.woodTackerShort_tackerPin.setDefaultData(
        1,
        CONST.ITEM_ID_TACKER_PIN,
        CONST.ITEM_NAME_TACKER_PIN,
        "목재",
        "ㄷ자, 22mm",
      );
      this.woodTackerLong_tackerPin.setDefaultData(
        2,
        CONST.ITEM_ID_TACKER_PIN,
        CONST.ITEM_NAME_TACKER_PIN,
        "목재",
        "일자, 64mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const fixingPosition: FixingPositionIL = <FixingPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;
    let totalLength: number;
    // 외부
    let roofPosition: RoofPositionIL | RoofPositionVL;
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPosition = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }

    /// //////// 선택, 가시성 ///////////

    this.wood_rectLumber.selected = true;
    this.wood_rectLumber.visible = true;
    this.woodTackerShort_tackerPin.selected = false;
    this.woodTackerShort_tackerPin.visible = true;
    this.woodTackerLong_tackerPin.selected = false;
    this.woodTackerLong_tackerPin.visible = true;

    /// //////// 수량 ///////////

    if (
      fixingPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      fixingPosition.label === CONST.LB_POSITION_SIDE_WING
    ) {
      this.wood_rectLumber.designQuantity =
        (this.basicLevel.length / this.wood_rectLumber.specLength) * fixingPosition.lineNumber;
    } else if (fixingPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          bothEndQuantity = (this.basicLevel.length / this.wood_rectLumber.specLength) * 1 * 2;
          centralQuantity =
            (this.basicLevel.length / this.wood_rectLumber.specLength) *
            fixingPosition.lineNumber *
            (this.basic.buildingNumber - 2);
          this.wood_rectLumber.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          this.wood_rectLumber.designQuantity =
            (this.basicLevel.length / this.wood_rectLumber.specLength) *
            fixingPosition.lineNumber *
            this.basic.buildingNumber;
        }
      } else {
        this.wood_rectLumber.designQuantity =
          (this.basicLevel.length / this.wood_rectLumber.specLength) * fixingPosition.lineNumber;
      }
    } else if (fixingPosition.label === CONST.LB_POSITION_FRONT || fixingPosition.label === CONST.LB_POSITION_BACK) {
      totalLength = 0;
      if (fixingPosition.type === CONST.LB_FIXING_TYPE_WOOD) {
        totalLength += this.basicLevel.width * fixingPosition.lineNumber * this.basic.buildingNumber;
      }
      if (fixingPosition.endpieceRafterType === CONST.LB_FIXING_TYPE_WOOD) {
        totalLength +=
          roofPosition.rafterPart.rafterTotalLength *
          fixingPosition.endpieceRafterLineNumber *
          this.basic.buildingNumber;
      }
      if (fixingPosition.endpieceColumnType === CONST.LB_FIXING_TYPE_WOOD) {
        totalLength += this.basicLevel.heightShoulder * fixingPosition.endpieceColumnLineNumber;
      }
      if (fixingPosition.endpieceGateType === CONST.LB_FIXING_TYPE_WOOD) {
        if (fixingPosition.label === CONST.LB_POSITION_FRONT) {
          totalLength += 2.0 * fixingPosition.endpieceGateLineNumber * endpieceLevel.frontPosition.gateBuildingNumber;
        } else if (fixingPosition.label === CONST.LB_POSITION_BACK) {
          totalLength += 2.0 * fixingPosition.endpieceGateLineNumber * endpieceLevel.backPosition.gateBuildingNumber;
        }
      }
      totalLength += 0.5 * this.basic.buildingNumber;
      this.wood_rectLumber.designQuantity = totalLength / this.wood_rectLumber.specLength;
    }

    // wood_tackerPinShort.designQuantity = 0;

    // wood_tackerPinLong.designQuantity = 0;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [목재] 각재 <- 각재 샘플
   */
  public algorithmSpec_wood_rectLumber(): void {
    this.wood_rectLumber.specs = this.rectLumberSample.specs;
  }

  /**
   * 규격 알고리즘: [목재] 타카핀 <- 공통 샘플
   */
  public algorithmSpec_woodTackerShort_tackerPin(): void {}

  /**
   * 규격 알고리즘: [목재] 타카핀 <- 공통 샘플
   */
  public algorithmSpec_woodTackerLong_tackerPin(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
