import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemWood } from "vhows-design/src/object/design/item/list/ItemWood";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ScreennetSampleIL_RectLumber } from "vhows-design/src/object/design/cover/screennet/il/ScreennetSampleIL_RectLumber";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [ScreennetSampleIL_RectLumber],
})
export class ScreennetPartIL_FixingWood extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public rectLumberSample: ScreennetSampleIL_RectLumber; // 각재 샘플

  // 아이템
  public wood_rectLumber: ItemWood;
  public wood_tackerPinShort: ItemFrameOther;
  public wood_tackerPinLong: ItemFrameOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.rectLumberSample = new ScreennetSampleIL_RectLumber();

    this.sampleAC = [this.rectLumberSample];

    // 아이템
    this.wood_rectLumber = new ItemWood();
    this.wood_tackerPinShort = new ItemFrameOther();
    this.wood_tackerPinLong = new ItemFrameOther();

    this.itemAC = [this.wood_rectLumber, this.wood_tackerPinShort, this.wood_tackerPinLong];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.rectLumberSample = <ScreennetSampleIL_RectLumber>this.sampleAC[0];

    // 아이템
    this.wood_rectLumber = <ItemWood>this.itemAC[0];
    this.wood_tackerPinShort = <ItemFrameOther>this.itemAC[1];
    this.wood_tackerPinLong = <ItemFrameOther>this.itemAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.rectLumberSample.setDefaultData(
        0,
        CONST.ITEM_ID_RECT_LUMBER,
        CONST.ITEM_NAME_RECT_LUMBER,
        "목재",
        "일반, 40×40mm, 3.6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.wood_rectLumber.setDefaultData(
        1,
        CONST.ITEM_ID_RECT_LUMBER,
        CONST.ITEM_NAME_RECT_LUMBER,
        "목재",
        "일반, 40×40mm, 3.6m",
      );
      this.wood_tackerPinShort.setDefaultData(
        2,
        CONST.ITEM_ID_TACKER_PIN,
        CONST.ITEM_NAME_TACKER_PIN,
        "목재",
        "ㄷ자, 22mm",
      );
      this.wood_tackerPinLong.setDefaultData(
        3,
        CONST.ITEM_ID_TACKER_PIN,
        CONST.ITEM_NAME_TACKER_PIN,
        "목재",
        "일자, 64mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.wood_rectLumber.selected = false;
    this.wood_rectLumber.visible = true;
    this.wood_tackerPinShort.selected = false;
    this.wood_tackerPinShort.visible = true;
    this.wood_tackerPinLong.selected = false;
    this.wood_tackerPinLong.visible = true;

    /// //////// 수량 ///////////

    // wood_rectLumber.designQuantity = 0;

    // wood_tackerPinShort.designQuantity = 0;

    // wood_tackerPinLong.designQuantity = 0;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [목재] 각재 <- 각재 샘플
   */
  public algorithmSpec_wood_rectLumber(): void {
    this.wood_rectLumber.specs = this.rectLumberSample.specs;
  }

  /**
   * 규격 알고리즘: [목재] 타카핀 <- 공통 샘플
   */
  public algorithmSpec_woodTackerShort_tackerPin(): void {}

  /**
   * 규격 알고리즘: [목재] 타카핀 <- 공통 샘플
   */
  public algorithmSpec_woodTackerLong_tackerPin(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
