import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 감속 모터
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-16
 */
@jsonObject
export class ItemReducedMotor extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [마력, 감는방식, 변압방식], [축커플링], [지지크기]
  public _specHorsePower: string = undefined; // 마력
  public _specWindingType: string = undefined; // 감는방식
  public _specTransformType: string = undefined; // 변압방식
  public _specAxisCoupling: string = undefined; // 축커플링
  public _specSupportSize: string = undefined; // 지지크기

  // 하우스 설계 > 상표 : [회사, 모델, 와인딩크기]
  public _brandModelName: string = undefined; // 모델
  public _brandWindingSize: string = undefined; // 와인딩크기

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 마력
   */
  public get specHorsePower(): string {
    return this._specHorsePower;
  }
  //
  public set specHorsePower(value: string) {
    this._specHorsePower = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 감는방식
   */
  public get specWindingType(): string {
    return this._specWindingType;
  }
  //
  public set specWindingType(value: string) {
    this._specWindingType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 변압방식
   */
  public get specTransformType(): string {
    return this._specTransformType;
  }
  //
  public set specTransformType(value: string) {
    this._specTransformType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 내선 <br/>
   * 전선에서 사용되는 변압방식에 따른 내선
   */
  public get specInternalLine(): string {
    let internalLine: string = "";
    switch (this._specTransformType) {
      case "단상":
        internalLine = "2C";
        break;
      case "삼상":
        internalLine = "3C";
        break;
      default:
        internalLine = "2C";
        break;
    }
    return internalLine;
  }

  /**
   * 축커플링
   */
  public get specAxisCoupling(): string {
    return this._specAxisCoupling;
  }
  //
  public set specAxisCoupling(value: string) {
    this._specAxisCoupling = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 지지크기
   */
  public get specSupportSize(): string {
    return this._specSupportSize;
  }
  //
  public set specSupportSize(value: string) {
    this._specSupportSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  /**
   * 모델
   */
  public get brandModelName(): string {
    return this._brandModelName;
  }
  //
  public set brandModelName(value: string) {
    this._brandModelName = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 와인딩크기
   */
  public get brandWindingSize(): string {
    return this._brandWindingSize;
  }
  //
  public set brandWindingSize(value: string) {
    this._brandWindingSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.reducedMotorCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specHorsePower = specsArray[0];
    this.specWindingType = specsArray[1];
    this.specTransformType = specsArray[2];
    this.specAxisCoupling = specsArray[3];
    this.specSupportSize = specsArray[4];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specHorsePower = specsArray[0];
    this.specWindingType = specsArray[1];
    this.specTransformType = specsArray[2];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specHorsePower +
      CONST.DELIMITER_SPEC +
      this._specWindingType +
      CONST.DELIMITER_SPEC +
      this._specTransformType;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList + CONST.DELIMITER_SPEC + this._specAxisCoupling + CONST.DELIMITER_SPEC + this._specSupportSize;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    const brandsArray: string[] = this._brands.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandWindingSize = brandsArray[2];
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    const brandsArray: string[] = this._brandsList.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandWindingSize = brandsArray[2];
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList =
      this._brandCompany +
      CONST.DELIMITER_BRAND +
      this._brandModelName +
      CONST.DELIMITER_BRAND +
      this._brandWindingSize;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }
}
