import { AnyT, jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { Company } from "vhows-design/src/object/company/Company";
import { Design } from "vhows-design/src/object/design/Design";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-20
 */
@jsonObject({
  knownTypes: [Company],
})
export class Preference extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  @jsonMember(Company)
  public company: Company;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 골조 설계

  /** 연결형 파이프 설치시 여분 길이(m) */
  @jsonMember(Number)
  public connectedPipeExtraLength: number;

  /** 연결형 파이프 핀가공 플래그 (true: 핀가공 파이프 , false: 파이프+연결핀) */
  @jsonMember(Boolean)
  public connectedPipePinWorkedFlag: boolean;

  /** 바닥도리+각기둥 용접 플래그 (true: 용접 사용, false: 부속 사용) */
  @jsonMember(Boolean)
  public floorGirthPrismColumnWeldingFlag: boolean;

  /** 각형 마구리 용접 플래그 (true: 용접 사용, false: 부속 사용) */
  @jsonMember(Boolean)
  public prismEndpieceWeldingFlag: boolean;

  /** 마구리 T 고정구 플래그 (true: T 고정구 사용, false: 일반 고정구 사용) */
  @jsonMember(Boolean)
  public endpieceTholderFlag: boolean;

  /** 마구리 대각T 고정구 플래그 (true: 대각T 고정구 사용, false: 일반 고정구 사용) */
  @jsonMember(Boolean)
  public endpieceDiagonalTholderFlag: boolean;

  /** 마구리 대각T 클램프 플래그 (true: 대각T 클램프 사용, false: 일반 고정구 사용) */
  @jsonMember(Boolean)
  public endpieceDiagonalTclampFlag: boolean;

  // 피복 설계

  /** 앞뒷면 피복 연장 플래그 (true: 연장하기, false: 연장하지 않기) */
  @jsonMember(Boolean)
  public extendFrontBackCoverFlag: boolean;

  /** 개폐된 피복의 폭 줄이기 플래그 (true: 줄이기, false: 줄이지 않기) */
  @jsonMember(Boolean)
  public reduceOpenedCoverWidthFlag: boolean;

  /** 비닐 폭을 10cm간격 계산하기 플래그 (true: 10cm간격, false: 5cm간격) */
  @jsonMember(Boolean)
  public multiplyVinylWidthDecupleFlag: boolean;

  /** 차광망 여분 길이 추가 플래그 (true: 추가 길이 반영, false: 추가 길이 반영 안함) */
  @jsonMember(Boolean)
  public addExtraShadenetLengthFlag: boolean;

  /** 카시미론류 설치시 겹치는 길이(m) */
  @jsonMember(Number)
  public cashmilonStyleOverlapLength: number;

  /** 핀가공 일반 패드 [아연] 플래그 */
  @jsonMember(Boolean)
  public pinWorkedNormalPadZincFlag: boolean;

  /** 핀가공 일반 패드 [칼라 철] 플래그 */
  @jsonMember(Boolean)
  public pinWorkedNormalPadColorIronFlag: boolean;

  /** 패드 스프링 설치 배율 [1겹] */
  @jsonMember(Number)
  public padSpringMultFactor1: number;

  /** 패드 스프링 설치 배율 [2겹] */
  @jsonMember(Number)
  public padSpringMultFactor2: number;

  /** 패드 스프링 설치 배율 [3겹] */
  @jsonMember(Number)
  public padSpringMultFactor3: number;

  /** 패드 스프링 설치 배율 [4겹] */
  @jsonMember(Number)
  public padSpringMultFactor4: number;

  // 개폐 설계

  /** 개폐기 보호고무 플래그 (true: 개폐기 보호고무 사용, false: 개폐기 보호고무 사용안함) */
  @jsonMember(Boolean)
  public switcherProtectionRubberFlag: boolean;

  /** 기울기 클립 플래그 (true: 기울기 클립 사용, false: 기울기 클립 사용안함) */
  @jsonMember(Boolean)
  public slopClipFlag: boolean;

  /** 치마 피복 매설 깊이 */
  @jsonMember(Number)
  public skirtCoverLayingDepth: number;

  /** 치마 비닐 일타 설치 플래그 */
  @jsonMember(Boolean)
  public skirtVinylOneBurstFitFlag: boolean;

  // 추가 설계

  /** 커튼 설치시 기둥 피복 및 개폐 대체 플래그 (true: 빼고 설치하기, false: 추가로 설치하기) */
  @jsonMember(Boolean)
  public curtainColumnReplaceFlag: boolean;

  /** 천창 커튼이 겹치는 폭(m) */
  @jsonMember(Number)
  public curtainCeilingOverlapWidth: number;

  // 견적

  /** 단가 수정 플래그 (true: 단가 수정 가능, false: 단가 수정 불가) */
  @jsonMember(Boolean)
  public unitPriceEditFlag: boolean;

  /** 공과잡비 비율 (소수점) */
  @jsonMember(Number)
  public buildingMisPriceRate: number;

  /** 견적 최종 배율 최소값 (소수점) */
  @jsonMember(Number)
  public totalPriceRateFinalMin: number;

  /** 파이프 용도별 구분 플래그. */
  @jsonMember(Boolean)
  public purposePipeFlag: boolean;

  /** 용도별 일반고정구 구분 플래그. */
  @jsonMember(Boolean)
  public purposeNormalHolderFlag: boolean;

  // 규격 기본값

  /** 나사 기본값 설정 여부 플래그 */
  @jsonMember(Boolean)
  public defaultScrewFlag: boolean;

  /** 나사 [십자, 일반] 기본값 */
  @jsonMember(String)
  public defaultScrewCrossNormal: string;

  /** 나사 [십자, 스텐레스] 기본값 */
  @jsonMember(String)
  public defaultScrewCrossStainless: string;

  /** 나사 [육각, 일반] 기본값 */
  @jsonMember(String)
  public defaultScrewHexagonNormal: string;

  /** 일반 패드 기본값 설정 여부 플래그 */
  @jsonMember(Boolean)
  public defaultNormalPadFlag: boolean;

  /** 일반 패드 [칼라 철] 기본값 */
  @jsonMember(String)
  public defaultNormalPadColorIron: string;

  /** 일반 패드 [알루미늄] 기본값 */
  @jsonMember(String)
  public defaultNormalPadAluminum: string;

  // 시스템

  /** 최종 변경자. */
  @jsonMember(String)
  public lastUpdater: string;

  /** 최종 변경 일시. */
  @jsonMember(Date)
  public lastUpdate: Date;

  //----------------------------------
  // DTO 변수
  //----------------------------------

  @jsonMember(AnyT)
  public designPreferenceDefaultSpecDTO: any;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design): void {
    this.design = design;
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {
    // 골조 설계
    this.connectedPipeExtraLength = 1.0;
    this.connectedPipePinWorkedFlag = false;
    this.floorGirthPrismColumnWeldingFlag = true;
    this.prismEndpieceWeldingFlag = true;
    this.endpieceTholderFlag = true;
    this.endpieceDiagonalTholderFlag = true;
    this.endpieceDiagonalTclampFlag = true;
    // 피복 설계
    this.extendFrontBackCoverFlag = true;
    this.reduceOpenedCoverWidthFlag = false;
    this.multiplyVinylWidthDecupleFlag = true;
    this.addExtraShadenetLengthFlag = true;
    this.cashmilonStyleOverlapLength = 0.3;
    this.pinWorkedNormalPadZincFlag = false;
    this.pinWorkedNormalPadColorIronFlag = false;
    this.padSpringMultFactor1 = 100;
    this.padSpringMultFactor2 = 140;
    this.padSpringMultFactor3 = 180;
    this.padSpringMultFactor4 = 200;
    // 개폐 설계
    this.switcherProtectionRubberFlag = false;
    this.slopClipFlag = true;
    this.skirtCoverLayingDepth = 0.5;
    this.skirtVinylOneBurstFitFlag = false;
    // 추가 설계
    this.curtainColumnReplaceFlag = true;
    this.curtainCeilingOverlapWidth = 0.5;
    // 견적
    this.unitPriceEditFlag = true;
    this.buildingMisPriceRate = 0.03;
    this.totalPriceRateFinalMin = 0.8;
    this.purposePipeFlag = false;
    this.purposeNormalHolderFlag = false;
    // 규격 기본값
    this.defaultScrewFlag = true;
    this.defaultScrewCrossNormal = "십자, 일반, 8/9*13mm, 1000개";
    this.defaultScrewCrossStainless = "십자, 스텐레스, 8/9*13mm, 500개";
    this.defaultScrewHexagonNormal = "육각, 일반, 14*25mm, 500개";
    this.defaultNormalPadFlag = true;
    this.defaultNormalPadColorIron = "칼라 철, 0.6T, 6m";
    this.defaultNormalPadAluminum = "알루미늄, 0.8T, 6m";
  }

  //----------------------------------
  // 설계
  //----------------------------------

  /**
   * 패드 스프링 설치 배율 구하기
   * @param layer 피복 겹수
   * @return 패드 스프링 설치 배율(소수점값)
   */
  public getPadSpringMultFactor(layer: number): number {
    if (layer === 1) {
      return CommonUtil.convertPercent2Rate(this.padSpringMultFactor1);
    } else if (layer === 2) {
      return CommonUtil.convertPercent2Rate(this.padSpringMultFactor2);
    } else if (layer === 3) {
      return CommonUtil.convertPercent2Rate(this.padSpringMultFactor3);
    } else if (layer >= 4) {
      return CommonUtil.convertPercent2Rate(this.padSpringMultFactor4);
    }
    return CommonUtil.convertPercent2Rate(this.padSpringMultFactor1);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
