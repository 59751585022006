import { jsonObject } from "typedjson";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WindowPositionIL } from "vhows-design/src/object/design/other/window/il/WindowPositionIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { GutterPositionIL } from "vhows-design/src/object/design/frame/gutter/il/GutterPositionIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { RoofPartVL_Straight } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Straight";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { RoofPartVL_RoofBracing } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_RoofBracing";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class RoofSampleVL_StraightPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const straightPart: RoofPartVL_Straight = <RoofPartVL_Straight>this.part;
    straightPart.algorithmSpec_straight_pipe();
    straightPart.algorithmSpec_straight_connectionPin();
    straightPart.algorithmSpec_straightAndRafter_steelStringGrabber();
    straightPart.algorithmSpec_straightAndRafter_steelSheetGrabber();
    straightPart.algorithmSpec_straightAndRafter_crossSteelSheetGrabber();
    straightPart.algorithmSpec_straightAndRafter_lineSheetGrabber();
    straightPart.algorithmSpec_straightAndRafter_saddleHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    // const roofBracingPart: RoofPartVL_RoofBracing = (<RoofPositionVL> this.position).roofBracingPart;
    // roofBracingPart.roofBracingPipeSample.algorithmSpec();

    /// //////// 외부 ///////////

    // // 방풍벽
    // if (this.level.index === 0 || this.level.index === 1) {
    //   const wingPosition: WingPositionVL = (<WingLevelVL> this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition;
    //   wingPosition.wingStraightPart.wingStraightPipeSample.specs = this.specs;
    // }
    //
    // // 물받이
    // const gutterPosition: GutterPositionIL = (<GutterLevelIL> this.struct.gutterWorkIL.levelAC[this.level.index]).gutterPosition;
    // gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_pipe();
    // gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_connectionPin();
    // gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_steelStringGrabber();
    // gutterPosition.fixingNormalClipPart.algorithmSpec_normalClip_normalClip();

    // 피복 고정
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]; // 같은 중수
    fixingLevel.roofPosition.normalClipPart.normalClipSample.algorithmSpec();

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    skirtLevel.roofPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();

    // 창문
    if (this.level.index === 0) {
      for (const windowPosition of this.struct.windowWorkIL.levelAC[this.level.index]
        .positionAC as WindowPositionIL[]) {
        windowPosition.hingedPart.hingedFramePipeSample.algorithmSpec();
      }
    }

    // 관수 지관부
    const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
    wateringPosition.miniklerScreenPart.algorithmSpec_miniklerHangerHanger_pePipeHanger();
    wateringPosition.miniklerFountainPart.algorithmSpec_miniklerHangerHanger_pePipeHanger();
    wateringPosition.miniklerFogPart.algorithmSpec_miniklerHangerHanger_pePipeHanger();
    wateringPosition.hoseScreenPart.algorithmSpec_hose_fountainHoseHangerScreen();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
