import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { BandstringLevel } from "vhows-design/src/object/design/cover/bandstring/BandstringLevel";
import { TrunkPart_Straight } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Straight";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { GutterPosition } from "vhows-design/src/object/design/frame/gutter/GutterPosition";
import { GutterLevel } from "vhows-design/src/object/design/frame/gutter/GutterLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-31
 */
@jsonObject
export class TrunkSample_StraightPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const straightPart: TrunkPart_Straight = <TrunkPart_Straight>this.part;

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const rafterPart: TrunkPart_Rafter = (<TrunkPosition>this.position).rafterPart;
    rafterPart.bracingPipeSample.algorithmSpec();

    /// //////// 외부 ///////////
    // 물받이
    const gutterPosition: GutterPosition = (<GutterLevel>this.struct.gutterWork.levelAC[this.level.index])
      .gutterPosition;
    gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_pipe();
    gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_connectionPin();
    gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_steelStringGrabber();
    gutterPosition.fixingNormalClipPart.algorithmSpec_normalClip_normalClip();

    // 밴드끈
    if (this.level.index === 0) {
      const bandstringLevel: BandstringLevel = <BandstringLevel>this.struct.bandstringWork.levelAC[this.level.index];
      bandstringLevel.trunkPosition.fixingHookPart.hookPipeSample.specsList = this.specsList;
    }
  }

  // @override
  public algorithmSample_specsList(): void {
    /// //////// 내부 ///////////

    const straightPart: TrunkPart_Straight = <TrunkPart_Straight>this.part;
    straightPart.straightRoofPipeSample.algorithmSpec();
    straightPart.straightRoofSubPipeSample.algorithmSpec();
    straightPart.straightSidePipeSample.algorithmSpec();
    straightPart.straightSideSubPipeSample.algorithmSpec();
    straightPart.straightFloorPipeSample.algorithmSpec();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
