import { jsonObject } from "typedjson";

import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { BandstringPart_FixingStake } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_FixingStake";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-06-24
 */
@jsonObject
export class BandstringSample_Stake extends ItemFrameOther {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingStakePart: BandstringPart_FixingStake = <BandstringPart_FixingStake>this.part;
    fixingStakePart.algorithmSpec_stake_stake();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
