import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";
import { FoundationPositionIL } from "vhows-design/src/object/design/foundation/il/FoundationPositionIL";
import { RoofPartIL_Rafter } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Rafter";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { EndpieceSampleIL_PrismStudUpperPipe } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceSampleIL_PrismStudUpperPipe";
import { EndpieceSampleIL_PrismStudBottomPipe } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceSampleIL_PrismStudBottomPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-01-10
 */
@jsonObject({
  knownTypes: [EndpieceSampleIL_PrismStudUpperPipe, EndpieceSampleIL_PrismStudBottomPipe],
})
export class EndpiecePartIL_PrismStudSep extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public studUpperPipeSample: EndpieceSampleIL_PrismStudUpperPipe; // 마구리 샛기둥(상단) 파이프 샘플
  public studBottomPipeSample: EndpieceSampleIL_PrismStudBottomPipe; // 마구리 샛기둥(하단) 파이프 샘플

  // 아이템
  public studUpper_pipe: ItemPipe;
  public studUpperAndRafterEndpiece_normalHolder: ItemHolder;
  public studUpperAndRafterRafter_normalHolder: ItemHolder;
  public studUpperAndRafter_boltAndNut: ItemFrameOther;
  public studUpperAndRafterDiagonal_diagonalTClamp: ItemHolder;
  public studBottom_pipe: ItemPipe;
  public studBottomAndMiddle_tClamp: ItemHolder;
  public studBottomAndGirth_uClamp: ItemHolder;
  public stud_screw: ItemScrew;
  public stud_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _studUpperLengthMax: number; // 샛기둥 상단 최대 길이
  @jsonMember(Number)
  public _studBottomLength: number; // 샛기둥 하단 길이
  @jsonMember(Number)
  public _studLineNumber: number; // 샛기둥 줄수

  /**
   * 샛기둥 상단 최대 길이
   */
  public get studUpperLengthMax(): number {
    return this._studUpperLengthMax;
  }

  //
  public set studUpperLengthMax(value: number) {
    this._studUpperLengthMax = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 샛기둥 하단 길이
   */
  public get studBottomLength(): number {
    return this._studBottomLength;
  }

  //
  public set studBottomLength(value: number) {
    this._studBottomLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.studBottomPipeSample.algorithmSpec();
    this.algorithmPart();
  }

  /**
   * 샛기둥 줄수
   */
  public get studLineNumber(): number {
    return this._studLineNumber;
  }

  //
  public set studLineNumber(value: number) {
    this._studLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<EndpiecePositionIL>this.position).prismMiddlePart.algorithmPart();

    // 다른 위치
    this.algorithm_studLineNumberByPosition();

    /// //////// 외부 ///////////

    // 치마
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).frontPosition.fixingSkirtClipPart.algorithm_clipInterval();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).backPosition.fixingSkirtClipPart.algorithm_clipInterval();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.studUpperPipeSample = new EndpieceSampleIL_PrismStudUpperPipe();
    this.studBottomPipeSample = new EndpieceSampleIL_PrismStudBottomPipe();

    this.sampleAC = [this.studUpperPipeSample, this.studBottomPipeSample];

    // 아이템
    this.studUpper_pipe = new ItemPipe();
    this.studUpperAndRafterEndpiece_normalHolder = new ItemHolder();
    this.studUpperAndRafterRafter_normalHolder = new ItemHolder();
    this.studUpperAndRafter_boltAndNut = new ItemFrameOther();
    this.studUpperAndRafterDiagonal_diagonalTClamp = new ItemHolder();
    this.studBottom_pipe = new ItemPipe();
    this.studBottomAndMiddle_tClamp = new ItemHolder();
    this.studBottomAndGirth_uClamp = new ItemHolder();
    this.stud_screw = new ItemScrew();
    this.stud_welding = new ItemOtherExpense();

    this.itemAC = [
      this.studUpper_pipe,
      this.studUpperAndRafterEndpiece_normalHolder,
      this.studUpperAndRafterRafter_normalHolder,
      this.studUpperAndRafter_boltAndNut,
      this.studUpperAndRafterDiagonal_diagonalTClamp,
      this.studBottom_pipe,
      this.studBottomAndMiddle_tClamp,
      this.studBottomAndGirth_uClamp,
      this.stud_screw,
      this.stud_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.studUpperPipeSample = <EndpieceSampleIL_PrismStudUpperPipe>this.sampleAC[0];
    this.studBottomPipeSample = <EndpieceSampleIL_PrismStudBottomPipe>this.sampleAC[1];

    // 아이템
    this.studUpper_pipe = <ItemPipe>this.itemAC[0];
    this.studUpperAndRafterEndpiece_normalHolder = <ItemHolder>this.itemAC[1];
    this.studUpperAndRafterRafter_normalHolder = <ItemHolder>this.itemAC[2];
    this.studUpperAndRafter_boltAndNut = <ItemFrameOther>this.itemAC[3];
    this.studUpperAndRafterDiagonal_diagonalTClamp = <ItemHolder>this.itemAC[4];
    this.studBottom_pipe = <ItemPipe>this.itemAC[5];
    this.studBottomAndMiddle_tClamp = <ItemHolder>this.itemAC[6];
    this.studBottomAndGirth_uClamp = <ItemHolder>this.itemAC[7];
    this.stud_screw = <ItemScrew>this.itemAC[8];
    this.stud_welding = <ItemOtherExpense>this.itemAC[9];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param studLineNumber: number 샛기둥 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    studLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._studLineNumber = studLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.studUpperPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥(상단)",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.studBottomPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥(하단)",
        "각형, 일반, 60×60mm, 2.1T, 3.5m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.studUpper_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥(상단)",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.studUpperAndRafterEndpiece_normalHolder.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 샛기둥(상단) + 서까래 (1)",
        "원형, 일반, 48mm, -",
      );
      this.studUpperAndRafterRafter_normalHolder.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 샛기둥(상단) + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.studUpperAndRafter_boltAndNut.setDefaultData(
        3,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 샛기둥(상단) + 서까래",
        "일반, 8mm",
      );
      this.studUpperAndRafterDiagonal_diagonalTClamp.setDefaultData(
        4,
        CONST.ITEM_ID_DIAGONAL_T_CLAMP,
        CONST.ITEM_NAME_DIAGONAL_T_CLAMP,
        "마구리 샛기둥(상단) + 서까래",
        "원형, 일반, 32mm, 48mm",
      );
      this.studBottom_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥(하단)",
        "각형, 일반, 60×60mm, 2.1T, 3.5m, -",
      );
      this.studBottomAndMiddle_tClamp.setDefaultData(
        6,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "마구리 샛기둥(하단) + 골조 중방",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.studBottomAndGirth_uClamp.setDefaultData(
        7,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "마구리 샛기둥(하단) + 폭방향 바닥도리",
        "각형, 일반, 60×60mm, 48mm",
      );
      this.stud_screw.setDefaultData(
        8,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 샛기둥",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.stud_welding.setDefaultData(9, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "마구리 샛기둥", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 9) {
      const studUpperAndRafterDiagonal_diagonalTClamp: ItemHolder = new ItemHolder();
      studUpperAndRafterDiagonal_diagonalTClamp.setAssociation(design, struct, work, level, position, this);
      studUpperAndRafterDiagonal_diagonalTClamp.setDefaultData(
        4,
        CONST.ITEM_ID_DIAGONAL_T_CLAMP,
        CONST.ITEM_NAME_DIAGONAL_T_CLAMP,
        "마구리 샛기둥(상단) + 서까래",
        "원형, 일반, 32mm, 48mm",
      );
      studUpperAndRafterDiagonal_diagonalTClamp.selected = false;
      studUpperAndRafterDiagonal_diagonalTClamp.visible = false;
      this.itemAC.splice(4, 0, studUpperAndRafterDiagonal_diagonalTClamp);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_studUpperLengthMax();
    this.algorithm_studBottomLength();
    this.algorithm_studLineNumber();

    super.algorithmBasic();
  }

  /**
   * 파트 알고리즘 <- 선택(바닥 골조), 형태(바닥 골조), 폭방향 바닥도리 선택(바닥 골조)
   */
  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const endpiecePosition: EndpiecePositionIL = <EndpiecePositionIL>this.position;
    let gateQuantity: number; // 출입동인 경우
    let noGateQuantity: number; // 출입동이 아닌 경우
    // 외부
    const roofPart_Rafter: RoofPartIL_Rafter = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index])
      .roofPosition.rafterPart;
    const foundationPosition: FoundationPositionIL = this.struct.foundationWorkIL.level1.foundationPosition;

    /// //////// 수치 ///////////

    // 출입동이 아닌 동수
    let noGateBuildingNumber: number = this.basic.buildingNumber - endpiecePosition.gateBuildingNumber;
    if (noGateBuildingNumber < 0) {
      noGateBuildingNumber = 0;
    }

    /// //////// 선택, 가시성 ///////////

    this.studUpper_pipe.selected = true;
    this.studUpper_pipe.visible = true;
    this.studUpperAndRafterEndpiece_normalHolder.selected = true;
    this.studUpperAndRafterEndpiece_normalHolder.visible = true;
    this.studUpperAndRafterRafter_normalHolder.selected = true;
    this.studUpperAndRafterRafter_normalHolder.visible = true;
    this.studUpperAndRafter_boltAndNut.selected = true;
    this.studUpperAndRafter_boltAndNut.visible = true;
    // 대각T 클램프(25/48mm,32/48mm,42/42mm,48/48mm)
    // 하드코딩
    if (
      this.design.preference.endpieceDiagonalTclampFlag === true &&
      ((this.studUpper_pipe.specCrossSize === roofPart_Rafter.rafterPipeSample.specCrossSize &&
        (this.studUpper_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
          this.studUpper_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)) ||
        (roofPart_Rafter.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM &&
          this.studUpper_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) ||
        (roofPart_Rafter.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM &&
          this.studUpper_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM))
    ) {
      this.studUpperAndRafterDiagonal_diagonalTClamp.selected = true;
      this.studUpperAndRafterDiagonal_diagonalTClamp.visible = true;
    } else {
      this.studUpperAndRafterDiagonal_diagonalTClamp.selected = false;
      this.studUpperAndRafterDiagonal_diagonalTClamp.visible = false;
    }
    this.studBottom_pipe.selected = true;
    this.studBottom_pipe.visible = true;
    if (this.design.preference.prismEndpieceWeldingFlag === true) {
      this.studBottomAndMiddle_tClamp.selected = false;
      this.studBottomAndMiddle_tClamp.visible = false;
    } else {
      this.studBottomAndMiddle_tClamp.selected = true;
      this.studBottomAndMiddle_tClamp.visible = true;
    }
    // 폭방향 바닥도리 선택
    if (
      this.level.index === 0 &&
      foundationPosition.selected === true &&
      (foundationPosition.type === CONST.LB_FOUNDATION_TYPE_GIRTH_PIPE ||
        foundationPosition.type === CONST.LB_FOUNDATION_TYPE_GIRTH_AND_COLUMN_CONCRETE) &&
      foundationPosition.girthPipePart.widthGirthSelected === true
    ) {
      if (this.design.preference.prismEndpieceWeldingFlag === true) {
        this.studBottomAndGirth_uClamp.selected = false;
        this.studBottomAndGirth_uClamp.visible = false;
      } else {
        this.studBottomAndGirth_uClamp.selected = true;
        this.studBottomAndGirth_uClamp.visible = true;
      }
    } else {
      this.studBottomAndGirth_uClamp.selected = false;
      this.studBottomAndGirth_uClamp.visible = false;
    }
    this.stud_screw.selected = true;
    this.stud_screw.visible = true;
    this.stud_welding.selected = true;
    this.stud_welding.visible = true;

    /// //////// 수량 ///////////

    this.studUpper_pipe.designQuantity =
      ((this.studUpperLengthMax * (this.studLineNumber - 1) * this.basic.buildingNumber) /
        this.studUpper_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_STUD_PIPE;

    if (this.studUpperAndRafterDiagonal_diagonalTClamp.selected === true) {
      this.studUpperAndRafterEndpiece_normalHolder.designQuantity =
        1 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

      this.studUpperAndRafterRafter_normalHolder.designQuantity =
        1 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

      this.studUpperAndRafter_boltAndNut.designQuantity =
        this.studUpperAndRafterEndpiece_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

      this.studUpperAndRafterDiagonal_diagonalTClamp.designQuantity =
        (this.studLineNumber - 1) * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_DIAGONAL_T_CLAMP;
    } else {
      this.studUpperAndRafterEndpiece_normalHolder.designQuantity =
        this.studLineNumber * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

      this.studUpperAndRafterRafter_normalHolder.designQuantity =
        this.studLineNumber * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

      this.studUpperAndRafter_boltAndNut.designQuantity =
        this.studUpperAndRafterEndpiece_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

      this.studUpperAndRafterDiagonal_diagonalTClamp.designQuantity = 0;
    }

    let stud_screw_designQuantity: number =
      (this.studUpperAndRafterEndpiece_normalHolder.getSelectedQuantity() +
        this.studUpperAndRafterRafter_normalHolder.getSelectedQuantity()) *
      2;

    stud_screw_designQuantity += this.studUpperAndRafterDiagonal_diagonalTClamp.getSelectedQuantity() * 4;

    gateQuantity = Math.ceil(
      ((this.studLineNumber - 1) / Math.floor(this.studBottom_pipe.specLength / this.studBottomLength)) *
        endpiecePosition.gateBuildingNumber,
    );
    noGateQuantity = Math.ceil(
      (this.studLineNumber / Math.floor(this.studBottom_pipe.specLength / this.studBottomLength)) *
        noGateBuildingNumber,
    );
    this.studBottom_pipe.designQuantity = (gateQuantity + noGateQuantity) * CONST.NUM_EXTRA_RATE_ENDPIECE_STUD_PIPE;

    let stud_welding_designQuantity: number = this.studLineNumber * this.basic.buildingNumber;

    gateQuantity = (this.studLineNumber - 1) * 1 * endpiecePosition.gateBuildingNumber;
    noGateQuantity = this.studLineNumber * 1 * noGateBuildingNumber;
    if (this.design.preference.prismEndpieceWeldingFlag === true) {
      stud_welding_designQuantity += gateQuantity + noGateQuantity;
    } else {
      this.studBottomAndMiddle_tClamp.designQuantity = (gateQuantity + noGateQuantity) * CONST.NUM_EXTRA_RATE_T_CLAMP;

      stud_screw_designQuantity += this.studBottomAndMiddle_tClamp.getSelectedQuantity() * 4;
    }

    if (this.struct.foundationWorkIL.level1.foundationPosition.girthPipePart.widthGirthSelected === true) {
      gateQuantity = (this.studLineNumber - 1) * 1 * endpiecePosition.gateBuildingNumber;
      noGateQuantity = this.studLineNumber * 1 * noGateBuildingNumber;
      if (this.design.preference.prismEndpieceWeldingFlag === true) {
        stud_welding_designQuantity += gateQuantity + noGateQuantity;
      } else {
        this.studBottomAndGirth_uClamp.designQuantity = (gateQuantity + noGateQuantity) * CONST.NUM_EXTRA_RATE_U_CLAMP;
      }
    }

    this.stud_screw.designQuantity =
      (stud_screw_designQuantity / this.stud_screw.specUnitQuantity) * CONST.NUM_EXTRA_RATE_SCREW;

    this.stud_welding.designQuantity = stud_welding_designQuantity;
  }

  /**
   * 알고리즘: 샛기둥 상단 최대 길이 <- 지붕 높이(기본정보)
   */
  public algorithm_studUpperLengthMax(): void {
    this.studUpperLengthMax = this.basicLevel.heightRoofMax;
  }

  /**
   * 알고리즘: 샛기둥 상단 최대 길이 <- 측고(기본정보), 매설(기본정보)
   */
  public algorithm_studBottomLength(): void {
    const basicLevel1: BasicLevel = <BasicLevel>this.basic.basicLevelAC[0];
    this.studBottomLength = basicLevel1.heightShoulder + this.basicLevel.depthLaying;
  }

  /**
   * 알고리즘: 샛기둥 줄수 <- 폭(기본정보)
   */
  public algorithm_studLineNumber(): void {
    if (this.basicLevel.width < 7) {
      this.studLineNumber = 5;
    } else {
      this.studLineNumber = 7;
    }
  }

  /**
   * 알고리즘: 샛기둥 줄수 <- 샛기둥 줄수(위치/앞면)
   */
  public algorithm_studLineNumberByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (this.position.type === (<EndpieceLevelIL>this.level).backPosition.type) {
        (<EndpieceLevelIL>this.level).backPosition.prismStudSepPart.studLineNumber = this.studLineNumber;
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [마구리 샛기둥(상단)] 파이프 <- 마구리 샛기둥(상단) 파이프 샘플
   */
  public algorithmSpec_studUpper_pipe(): void {
    this.studUpper_pipe.specs = this.studUpperPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥(상단) + 서까래 (1)] 일반 고정구 <- 마구리 샛기둥(상단) 파이프 샘플
   */
  public algorithmSpec_studUpperAndRafterEndpiece_normalHolder(): void {
    this.studUpperAndRafterEndpiece_normalHolder.specPipeType = this.studUpperPipeSample.specPipeType;
    this.studUpperAndRafterEndpiece_normalHolder.specCrossSize1 = this.studUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥(상단) + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플
   */
  public algorithmSpec_studUpperAndRafterRafter_normalHolder(): void {
    this.studUpperAndRafterRafter_normalHolder.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.studUpperAndRafterRafter_normalHolder.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥(상단) + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_studUpperAndRafter_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [마구리 샛기둥(상단) + 서까래] 대각T 클램프 <- 마구리 샛기둥(상단) 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_studUpperAndRafterDiagonal_diagonalTClamp(): void {
    this.studUpperAndRafterDiagonal_diagonalTClamp.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.studUpperAndRafterDiagonal_diagonalTClamp.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    this.studUpperAndRafterDiagonal_diagonalTClamp.specCrossSize2 = this.studUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥(하단)] 파이프 <- 마구리 샛기둥(하단) 파이프 샘플
   */
  public algorithmSpec_studBottom_pipe(): void {
    this.studBottom_pipe.specs = this.studBottomPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥(하단) + 골조 중방] T 클램프 <- 마구리 샛기둥(하단) 파이프 샘플, 중방 파이프 샘플
   */
  public algorithmSpec_studBottomAndMiddle_tClamp(): void {
    this.studBottomAndMiddle_tClamp.specPipeType =
      this.struct.columnWorkIL.level1.columnPosition.prismMiddlePart.middlePipeSample.specPipeType;
    this.studBottomAndMiddle_tClamp.specCrossSize1 =
      this.struct.columnWorkIL.level1.columnPosition.prismMiddlePart.middlePipeSample.specCrossSize;
    this.studBottomAndMiddle_tClamp.specCrossSize2 = this.studBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥(하단) + 폭방향 바닥도리] U 클램프 <- 마구리 샛기둥(하단) 파이프 샘플, 폭방향 바닥도리 파이프 샘플
   */
  public algorithmSpec_studBottomAndGirth_uClamp(): void {
    this.studBottomAndGirth_uClamp.specPipeType = this.studBottomPipeSample.specPipeType;
    this.studBottomAndGirth_uClamp.specCrossSize1 = this.studBottomPipeSample.specCrossSize;
    this.studBottomAndGirth_uClamp.specCrossSize2 =
      this.struct.foundationWorkIL.level1.foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥] 나사 <- 공통 샘플
   */
  public algorithmSpec_stud_screw(): void {}

  /**
   * 규격 알고리즘: [마구리 샛기둥] 용접 <- 공통 샘플
   */
  public algorithmSpec_stud_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
