import { jsonObject } from "typedjson";

import { ItemIronPlateGutter } from "vhows-design/src/object/design/item/list/ItemIronPlateGutter";
import { GutterPart_CondensationGutter } from "vhows-design/src/object/design/frame/gutter/GutterPart_CondensationGutter";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-12-06
 */
@jsonObject
export class GutterSample_ConWorkedIronPlateGutter extends ItemIronPlateGutter {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const condensationGutterPart: GutterPart_CondensationGutter = <GutterPart_CondensationGutter>this.part;
    condensationGutterPart.algorithmSpec_condensationGutter_ironPlateGutter();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }
}
