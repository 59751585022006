import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";
import { SwitcherPart_Axis } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Axis";
import { SwitcherPart_FixingNormalClip } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_FixingNormalClip";
import { SwitcherPart_Manual } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Manual";
import { SwitcherPart_Power } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Power";
import { SwitcherPart_Support } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Support";
import { ScreennetPosition } from "vhows-design/src/object/design/cover/screennet/ScreennetPosition";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { WindbreakPosition } from "vhows-design/src/object/design/cover/windbreak/WindbreakPosition";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-20
 */
@jsonObject({
  knownTypes: [
    SwitcherPart_Axis,
    // SwitcherPart_FixingLaggingPad,
    SwitcherPart_FixingNormalClip,
    // SwitcherPart_FixingNormalPad,
    SwitcherPart_Manual,
    SwitcherPart_Power,
    SwitcherPart_Support,
  ],
})
export class SwitcherPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public coverLevel: CoverLevel; // 기초 피복 중수

  public manual1Part: SwitcherPart_Manual;
  public manual2Part: SwitcherPart_Manual;
  public power1Part: SwitcherPart_Power;
  public power2Part: SwitcherPart_Power;
  public axisPart: SwitcherPart_Axis;
  public supportPart: SwitcherPart_Support;
  // public fixingNormalPadPart: SwitcherPart_FixingNormalPad;
  public fixingNormalClipPart: SwitcherPart_FixingNormalClip;
  // public fixingLaggingPadPart: SwitcherPart_FixingLaggingPad;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 개폐 방향값 <- 개폐 방향
   */
  public get switchWayValue(): number {
    if (this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH || this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
      return 2;
    } else if (
      this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE ||
      this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ON
    ) {
      return 1;
    }
    return 0;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _switchWay: string = null; // 개폐 방향
  @jsonMember(Number)
  public _switchHeight: number = null; // 개폐 높이
  @jsonMember(Number)
  public _switchStep: number = null; // 개폐 단수
  @jsonMember(Number)
  public _switchOrderMax: number = null; // 개폐 최대 차수
  @jsonMember(String)
  public _supportType: string = null; // 지지 형태
  @jsonMember(String)
  public _support2Type: string = null; // 지지 형태(2차)
  @jsonMember(String)
  public _fixingType: string = null; // 고정(피복) 형태
  @jsonMember(String)
  public _fixing2Type: string = null; // 고정(피복) 형태(2차)

  /**
   * 개폐 방향
   */
  public get switchWay(): string {
    return this._switchWay;
  }

  public set switchWay(value: string) {
    this._switchWay = value;

    // 알고리즘
    this.algorithm_selectedAndSwitchWayForFullSwitchWay();
    this.algorithm_supportTypeByType();
    this.manual1Part.manualSwitcherSample.algorithmSpec();
    this.manual2Part.manualSwitcherSample.algorithmSpec();
    this.axisPart.algorithm_slopeClipSelectedRoof();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    this.algorithm_selectedBySwitchWay();
    this.algorithm_switchHeight();

    /// //////// 외부 ///////////

    // 제어기
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }

    // 피복 고정
    (<FixingPosition>(
      this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_selectedBySwitcher();
    (<FixingPosition>this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]).algorithm_lineNumber();
    (<FixingPosition>this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]).algorithm_status();

    // 치마
    (<SkirtPosition>this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]).stringPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingSkirtClipPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_skirtHeightBySwitcher();

    // 바람막이
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.index]
      )).fixingNormalPadPart.algorithmPart();
      (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.index]
      )).fixingLaggingPadPart.algorithmPart();
      (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.index]
      )).fixingWoodPart.algorithmPart();
      (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.index]
      )).screennetPart.algorithmPart();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 개폐 높이
   */
  public get switchHeight(): number {
    return this._switchHeight;
  }

  public set switchHeight(value: number) {
    this._switchHeight = value;

    // 알고리즘
    this.supportPart.algorithm_supportLength();
    this.supportPart.algorithm_support2Length();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 바람막이
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).windbreakPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.index]
      )).screennetPart.algorithmPart();
    }
  }

  /**
   * 개폐 단수
   */
  public get switchStep(): number {
    return this._switchStep;
  }

  public set switchStep(value: number) {
    this._switchStep = value;

    // 알고리즘

    // 파트 활성화

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 제어기
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }

    // 피복 고정
    (<FixingPosition>this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]).algorithm_lineNumber();
    (<FixingPosition>this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]).algorithm_status();

    // 치마
    (<SkirtPosition>this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]).stringPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingSkirtClipPart.algorithmPart();

    // 바람막이
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPosition>this.struct.screennetWork.level1.positionAC[this.index]).fixingNormalPadPart.algorithmPart();
      (<ScreennetPosition>this.struct.screennetWork.level1.positionAC[this.index]).fixingLaggingPadPart.algorithmPart();
      (<ScreennetPosition>this.struct.screennetWork.level1.positionAC[this.index]).fixingWoodPart.algorithmPart();
      (<ScreennetPosition>this.struct.screennetWork.level1.positionAC[this.index]).screennetPart.algorithmPart();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 개폐 최대 차수
   */
  public get switchOrderMax(): number {
    return this._switchOrderMax;
  }

  public set switchOrderMax(value: number) {
    this._switchOrderMax = value;

    // 알고리즘
    this.algorithm_partActivationByType();
    this.algorithm_support2TypeBySwitchOrder();
    this.algorithm_fixing2TypeBySwitchOrder();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 제어기
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }
  }

  /**
   * 지지 형태
   */
  public get supportType(): string {
    return this._supportType;
  }

  public set supportType(value: string) {
    this._supportType = value;

    // 알고리즘
    this.power1Part.powerSwitcherSample.algorithmSpec();
    this.power1Part.powerSwitcherSample.algorithmSpec_supportCoupling();

    // 파트 활성화
    this.algorithm_partActivationBySupportType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 지지 형태(2차)
   */
  public get support2Type(): string {
    return this._support2Type;
  }

  public set support2Type(value: string) {
    this._support2Type = value;

    // 알고리즘
    this.power2Part.powerSwitcherSample.algorithmSpec();
    this.power2Part.powerSwitcherSample.algorithmSpec_support2Coupling();

    // 파트 활성화
    this.algorithm_partActivationBySupportType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 고정(피복) 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  //
  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 고정(피복) 형태(2차)
   */
  public get fixing2Type(): string {
    return this._fixing2Type;
  }

  //
  public set fixing2Type(value: string) {
    this._fixing2Type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();
    this.algorithm_selectedAndSwitchWayForFullSwitchWay();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationBySupportType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    // 다른 위치
    this.algorithm_selectedBySwitchWay();

    /// //////// 외부 ///////////

    // 마구리
    if (this.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index]).frontPosition.algorithm_typeAndGateType();
    } else if (this.label === CONST.LB_POSITION_BACK) {
      (<EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index]).backPosition.algorithm_typeAndGateType();
    }

    // 제어기
    this.struct.controllerWork.level1.integratedPosition.algorithm_selectedBySwitcherByBasic();
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }

    // 피복 고정
    (<FixingPosition>(
      this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_selectedBySwitcher();
    (<FixingPosition>this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]).algorithm_lineNumber();
    (<FixingPosition>(
      this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_endpieceMiddleLineNumber();
    (<FixingPosition>this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]).algorithm_status();

    // 치마
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_selectedBySwitcher();
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_enabledBySwitcher();

    // 바람막이
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_selectedBySwitcher();
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_enabledBySwitcher();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.index]
      )).algorithm_selectedBySwitcher();
      (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.index]
      )).algorithm_enabledBySwitcher();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_supportTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    this.algorithm_typeByPosition();

    // 다른 중수
    if (this.level.index === 0) {
      this.algorithm_typeByLevel1();
    }

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWork.level1.integratedPosition.algorithm_selectedBySwitcherByBasic();
    this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.manual1Part = new SwitcherPart_Manual();
    this.manual2Part = new SwitcherPart_Manual();
    this.power1Part = new SwitcherPart_Power();
    this.power2Part = new SwitcherPart_Power();
    this.axisPart = new SwitcherPart_Axis();
    this.supportPart = new SwitcherPart_Support();
    this.fixingNormalClipPart = new SwitcherPart_FixingNormalClip();

    this.partAC = [
      this.manual1Part,
      this.manual2Part,
      this.power1Part,
      this.power2Part,
      this.axisPart,
      this.supportPart,
      this.fixingNormalClipPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.manual1Part = <SwitcherPart_Manual>this.partAC[0];
    this.manual2Part = <SwitcherPart_Manual>this.partAC[1];
    this.power1Part = <SwitcherPart_Power>this.partAC[2];
    this.power2Part = <SwitcherPart_Power>this.partAC[3];
    this.axisPart = <SwitcherPart_Axis>this.partAC[4];
    this.supportPart = <SwitcherPart_Support>this.partAC[5];
    this.fixingNormalClipPart = <SwitcherPart_FixingNormalClip>this.partAC[6];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param switchWay: string 개폐 방향
   * @param switchHeight: number 개폐 높이
   * @param switchStep: string 개폐 단수
   * @param switchOrderMax: string 개폐 차수
   * @param supportType: string 지지 형태
   * @param support2Type: string 지지 형태(2차)
   * @param fixingType: string 고정(피복) 형태
   * @param fixing2Type: string 고정(피복) 형태(2차)
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    switchWay: string = "",
    switchHeight: number = 0,
    switchStep: number = 0,
    switchOrderMax: number = 0,
    supportType: string = "",
    support2Type: string = "",
    fixingType: string = "",
    fixing2Type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._switchWay = switchWay;
    this._switchHeight = switchHeight;
    this._switchStep = switchStep;
    this._switchOrderMax = switchOrderMax;
    this._supportType = supportType;
    this._support2Type = support2Type;
    this._fixingType = fixingType;
    this._fixing2Type = fixing2Type;

    if (this.level.index === 0) {
      // 수동 개폐기 1차
      this.manual1Part.setDefaultData(
        CONST.INDEX_SG_SWITCHER_MANUAL1,
        true,
        false,
        true,
        CONST.LB_SWITCHER_MANUAL1,
        "",
      );

      // 수동 개폐기 2차
      this.manual2Part.setDefaultData(
        CONST.INDEX_SG_SWITCHER_MANUAL2,
        false,
        false,
        false,
        CONST.LB_SWITCHER_MANUAL2,
        "",
      );

      // 동력 개폐기 1차
      this.power1Part.setDefaultData(CONST.INDEX_SG_SWITCHER_POWER1, false, false, false, CONST.LB_SWITCHER_POWER1, "");

      // 동력 개폐기 2차
      this.power2Part.setDefaultData(CONST.INDEX_SG_SWITCHER_POWER2, false, false, false, CONST.LB_SWITCHER_POWER2, "");

      // 개폐 축
      this.axisPart.setDefaultData(
        CONST.INDEX_SG_SWITCHER_AXIS,
        true,
        false,
        true,
        CONST.LB_SWITCHER_AXIS,
        "",
        true,
        1.2,
      );

      if (label === CONST.LB_POSITION_SIDE) {
        // 개폐기 지지 - 수직형 지지
        this.supportPart.setDefaultData(
          CONST.INDEX_SG_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          2.0,
        );

        // 개폐기 지지 - 대각형 지지
        this.supportPart.setDefaultData(
          CONST.INDEX_SG_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          2.5,
        );
      } else if (label === CONST.LB_POSITION_ROOF) {
        // 개폐기 지지 - 수직형 지지
        this.supportPart.setDefaultData(
          CONST.INDEX_SG_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          2.0,
        );

        // 개폐기 지지 - 대각형 지지
        this.supportPart.setDefaultData(
          CONST.INDEX_SG_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          2.5,
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        // 개폐기 지지 - 수직형 지지
        this.supportPart.setDefaultData(
          CONST.INDEX_SG_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          2.5,
        );

        // 개폐기 지지 - 대각형 지지
        this.supportPart.setDefaultData(
          CONST.INDEX_SG_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          2.5,
        );
      }

      // 개폐기 지지 - 서까래형 지지
      this.supportPart.setDefaultData(
        CONST.INDEX_SG_SWITCHER_SUPPORT,
        false,
        false,
        false,
        CONST.LB_SWITCHER_SUPPORT_RAFTER,
        "",
      );

      // 개폐기 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_SG_SWITCHER_FIXING_NORMAL_CLIP,
        true,
        false,
        true,
        CONST.LB_SWITCHER_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );
    } else {
      // 수동 개폐기 1차
      this.manual1Part.setDefaultData(
        CONST.INDEX_SG_SWITCHER_MANUAL1,
        true,
        false,
        true,
        CONST.LB_SWITCHER_MANUAL1,
        "",
      );

      // 수동 개폐기 2차
      this.manual2Part.setDefaultData(
        CONST.INDEX_SG_SWITCHER_MANUAL2,
        false,
        false,
        false,
        CONST.LB_SWITCHER_MANUAL2,
        "",
      );

      // 동력 개폐기 1차
      this.power1Part.setDefaultData(CONST.INDEX_SG_SWITCHER_POWER1, false, false, false, CONST.LB_SWITCHER_POWER1, "");

      // 동력 개폐기 2차
      this.power2Part.setDefaultData(CONST.INDEX_SG_SWITCHER_POWER2, false, false, false, CONST.LB_SWITCHER_POWER2, "");

      // 개폐 축
      this.axisPart.setDefaultData(
        CONST.INDEX_SG_SWITCHER_AXIS,
        true,
        false,
        true,
        CONST.LB_SWITCHER_AXIS,
        "",
        false,
        1.2,
      );

      // 개폐기 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_SG_SWITCHER_FIXING_NORMAL_CLIP,
        true,
        false,
        true,
        CONST.LB_SWITCHER_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.init_coverLevel();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 11 && (<Part>this.partAC[5]).label === "수직형 지지") {
      // 개폐기 지지파트
      const supportPart: SwitcherPart_Support = new SwitcherPart_Support();
      supportPart.setAssociation(design, struct, work, level, this);
      supportPart.setDefaultData(
        CONST.INDEX_SG_SWITCHER_SUPPORT,
        false,
        false,
        false,
        CONST.LB_SWITCHER_SUPPORT,
        "",
        2.5,
        2.5,
      );
      if (this.supportType !== CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE) {
        supportPart._selected = true;
        supportPart._visible = true;
      }
      this.partAC.splice(5, 3, supportPart);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
      // 알고리즘 동작
      supportPart.setDefaultVariable();
      supportPart.algorithmPart();
    }
    if (this.partAC.length === 9 && (<Part>this.partAC[5]).label === "개폐기 지지") {
      // 일반 클립 고정 파트
      const fixingNomalClipPart: SwitcherPart_FixingNormalClip = new SwitcherPart_FixingNormalClip();
      fixingNomalClipPart.setAssociation(design, struct, work, level, this);
      fixingNomalClipPart.setDefaultData(
        CONST.INDEX_SG_SWITCHER_FIXING_NORMAL_CLIP,
        true,
        false,
        true,
        CONST.LB_SWITCHER_FIXING_NORMAL_CLIP,
        "",
        0.5,
        0.5,
      );
      this.partAC.splice(6, 3, fixingNomalClipPart);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
      // 알고리즘 동작
      fixingNomalClipPart.setDefaultVariable();
      fixingNomalClipPart.algorithmPart();
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 상세 피복별 선택 여부
   * @param coverPosition 피복 위치 객체
   */
  public selectedDetail(coverPosition: CoverPosition): boolean {
    // 위치별로 개폐가 선택되었다 하더라도, 피복별로 개폐 차수가 0차인 경우 개폐되지 않은 것으로 간주함
    if (this.label === CONST.LB_POSITION_SIDE && coverPosition.switcherOrderSide > 0) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_ROOF && coverPosition.switcherOrderRoof > 0) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_FRONT && coverPosition.switcherOrderFront > 0) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_BACK && coverPosition.switcherOrderBack > 0) {
      return this.selected;
    }
    return false;
  }

  /**
   * 알고리즘: 선택(지붕 위치) <- 개폐 방향
   */
  public algorithm_selectedBySwitchWay(): void {
    if (this.label === CONST.LB_POSITION_SIDE) {
      if (this.selected === true && this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
        // 측면의 개폐 방향이 완전개폐인 경우, 지붕의 개폐 선택을 해제한다.
        (<SwitcherLevel>this.level).roofPosition.selected = false;
      }
    }
  }

  /**
   * 알고리즘: 선택(지붕 위치) <- 선택(천창 골조)
   */
  public algorithm_selectedRoofBySkylight(): void {
    if (this.level.index === 0 && this.label === CONST.LB_POSITION_ROOF) {
      if (this.struct.skyFrameWork.level1.skylightPosition.selected === true) {
        this.selected = false;
      }
    }
  }

  /**
   * 알고리즘: 선택(앞뒷면 위치), 가용성(앞뒷면 위치) <- 선택(마구리 골조)
   */
  public algorithm_selectedAndEnabledByEndpiece(): void {
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true) {
        this.enabled = true;
      } else {
        this.enabled = false;
        this.selected = false;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true) {
        this.enabled = true;
      } else {
        this.enabled = false;
        this.selected = false;
      }
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_SWITCHER_TYPE_MANUAL) {
      if (this.switchOrderMax === 1) {
        this.manual1Part.selected = true;
        this.manual1Part.visible = true;
        this.manual2Part.selected = false;
        this.manual2Part.visible = false;
      } else if (this.switchOrderMax === 2) {
        this.manual1Part.selected = true;
        this.manual1Part.visible = true;
        this.manual2Part.selected = true;
        this.manual2Part.visible = true;
      }
      this.power1Part.selected = false;
      this.power1Part.visible = false;
      this.power2Part.selected = false;
      this.power2Part.visible = false;
      this.axisPart.selected = true;
      this.axisPart.visible = true;
    } else if (this.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP || this.type === CONST.LB_SWITCHER_TYPE_POWER_TOW) {
      if (this.switchOrderMax === 1) {
        this.power1Part.selected = true;
        this.power1Part.visible = true;
        this.power2Part.selected = false;
        this.power2Part.visible = false;
      } else if (this.switchOrderMax === 2) {
        this.power1Part.selected = true;
        this.power1Part.visible = true;
        this.power2Part.selected = true;
        this.power2Part.visible = true;
      }
      this.manual1Part.selected = false;
      this.manual1Part.visible = false;
      this.manual2Part.selected = false;
      this.manual2Part.visible = false;
      this.axisPart.selected = true;
      this.axisPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 지지 형태
   */
  public algorithm_partActivationBySupportType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL) {
      this.supportPart.selected = true;
      this.supportPart.visible = true;
    } else if (this.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL) {
      this.supportPart.selected = true;
      this.supportPart.visible = true;
    } else if (this.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_RAFTER) {
      this.supportPart.selected = true;
      this.supportPart.visible = true;
    } else if (this.supportType === CONST.LB_SWITCHER_SUPPORT) {
      this.supportPart.selected = true;
      this.supportPart.visible = true;
    } else if (
      this.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE &&
      this.support2Type === CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE
    ) {
      this.supportPart.selected = false;
      this.supportPart.visible = false;
    } else {
      this.supportPart.selected = true;
      this.supportPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
      this.fixingNormalClipPart.selected = true;
      this.fixingNormalClipPart.visible = true;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
    }
  }

  /**
   * 알고리즘: 완전개폐를 위한 선택,개폐 방향
   */
  public algorithm_selectedAndSwitchWayForFullSwitchWay(): void {
    if (this.label === CONST.LB_POSITION_ROOF) {
      if (
        this.selected === true &&
        (<SwitcherLevel>this.level).sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL
      ) {
        (<SwitcherLevel>this.level).sidePosition.switchWay = CONST.LB_SWITCHER_SWITCH_WAY_BOTH;
      }
    } else if (this.label === CONST.LB_POSITION_SIDE) {
      if (this.selected === true && this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
        (<SwitcherLevel>this.level).roofPosition.selected = false;
      }
    }
  }

  /**
   * 알고리즘: 지지 형태 <- 형태, 개폐 방향
   */
  public algorithm_supportTypeByType(): void {
    if (this.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
      if (this.label === CONST.LB_POSITION_SIDE) {
        if ((<SwitcherLevel>this.level).sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
          this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL;
        } else {
          this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
        }
      } else if (this.label === CONST.LB_POSITION_ROOF) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL;
      } else if (this.label === CONST.LB_POSITION_FRONT) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
      } else if (this.label === CONST.LB_POSITION_BACK) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
      }
    } else if (this.type === CONST.LB_SWITCHER_TYPE_MANUAL) {
      if (this.label === CONST.LB_POSITION_SIDE) {
        if ((<SwitcherLevel>this.level).sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
          this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE;
        } else {
          this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
        }
      } else if (this.label === CONST.LB_POSITION_ROOF) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE;
      } else if (this.label === CONST.LB_POSITION_FRONT) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
      } else if (this.label === CONST.LB_POSITION_BACK) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
      }
    } else if (this.type === CONST.LB_SWITCHER_TYPE_POWER_TOW) {
      this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE;
    }
  }

  /**
   * 알고리즘: 지지 형태(2차) <- 개폐 차수
   */
  public algorithm_support2TypeBySwitchOrder(): void {
    if (this.switchOrderMax === CONST.VL_SWITCHER_ORDER_2) {
      this.support2Type = this.supportType;
    }
  }

  /**
   * 알고리즘: 피복 고정 형태(2차) <- 개폐 차수
   */
  public algorithm_fixing2TypeBySwitchOrder(): void {
    if (this.switchOrderMax === CONST.VL_SWITCHER_ORDER_2) {
      this.fixing2Type = this.fixingType;
    }
  }

  /**
   * 알고리즘: 개폐 높이 <- 개폐 방향
   */
  public algorithm_switchHeight(): void {
    if (this.label === CONST.LB_POSITION_SIDE) {
      if (this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
        this.algorithm_switchHeightWithSwitchWayFull();
      } else {
        this.switchHeight = 1.5;
      }
    }
  }

  /**
   * 알고리즘: 개폐 높이(완전개폐) <- 개폐 방향, 최종 서까래 길이(기초 골조)
   */
  public algorithm_switchHeightWithSwitchWayFull(): void {
    if (this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
      const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;

      this.switchHeight = trunkPosition.rafterPart.rafterTotalLength / 2 - 0.5;
    }
  }

  /**
   * 알고리즘: 형태(다른 위치) <- 형태(측면 위치)
   */
  public algorithm_typeByPosition(): void {
    if (this.label === CONST.LB_POSITION_SIDE) {
      if (this.type !== CONST.LB_SWITCHER_TYPE_POWER_TOW) {
        (<SwitcherLevel>this.level).roofPosition.type = this.type;
        (<SwitcherLevel>this.level).frontPosition.type = this.type;
        (<SwitcherLevel>this.level).backPosition.type = this.type;
      } else {
        (<SwitcherLevel>this.level).roofPosition.type = CONST.LB_SWITCHER_TYPE_POWER_WRAP;
        (<SwitcherLevel>this.level).frontPosition.type = CONST.LB_SWITCHER_TYPE_POWER_WRAP;
        (<SwitcherLevel>this.level).backPosition.type = CONST.LB_SWITCHER_TYPE_POWER_WRAP;
      }
    }
  }

  /**
   * 알고리즘: 형태(2중이상) <- 형태(1중/측면 위치)
   */
  public algorithm_typeByLevel1(): void {
    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE) {
      for (let i: number = 1; i < this.struct.switcherWork.levelAC.length; i++) {
        (<SwitcherLevel>this.struct.switcherWork.levelAC[i]).sidePosition.type = this.type;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 초기화: 기초 피복 중수
   */
  protected init_coverLevel(): void {
    this.coverLevel = <CoverLevel>this.struct.coverWork.levelAC[this.level.index];
  }
}
