import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { ColumnSampleVL_ColumnPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_ColumnPipe";
import { ColumnSampleVL_ColumnBracingPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_ColumnBracingPipe";
import { ItemBracket } from "vhows-design/src/object/design/item/list/ItemBracket";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ColumnSampleVL_EdgeColumnPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_EdgeColumnPipe";
import { ColumnSampleVL_SideColumnPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_SideColumnPipe";
import { ColumnSampleVL_SideStudColumnPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_SideStudColumnPipe";
import { ColumnSampleVL_FrontAndBackColumnPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_FrontAndBackColumnPipe";
import { ColumnSampleVL_FrontAndBackStudColumnPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_FrontAndBackStudColumnPipe";
import { ColumnSampleVL_SmallColumnPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_SmallColumnPipe";
import { ColumnSampleVL_ColumnBracingRoundBar } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_ColumnBracingRoundBar";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { CurSwitchPartIL_FrameVL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_FrameVL";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-08
 */
@jsonObject({
  knownTypes: [
    ColumnSampleVL_ColumnPipe,
    ColumnSampleVL_EdgeColumnPipe,
    ColumnSampleVL_SideColumnPipe,
    ColumnSampleVL_SideStudColumnPipe,
    ColumnSampleVL_FrontAndBackColumnPipe,
    ColumnSampleVL_FrontAndBackStudColumnPipe,
    ColumnSampleVL_SmallColumnPipe,
    ColumnSampleVL_ColumnBracingRoundBar,
    ColumnSampleVL_ColumnBracingPipe,
  ],
})
export class ColumnPartVL_Column extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public columnPipeSample: ColumnSampleVL_ColumnPipe; // 내부 기둥 파이프 샘플
  public edgeColumnPipeSample: ColumnSampleVL_EdgeColumnPipe; // 모서리 기둥 파이프 샘플
  public sideColumnPipeSample: ColumnSampleVL_SideColumnPipe; // 측면 기둥 파이프 샘플
  public sideStudColumnPipeSample: ColumnSampleVL_SideStudColumnPipe; // 측면 샛기둥 파이프 샘플
  public frontAndBackColumnPipeSample: ColumnSampleVL_FrontAndBackColumnPipe; // 전후면 기둥 파이프 샘플
  public frontAndBackStudColumnPipeSample: ColumnSampleVL_FrontAndBackStudColumnPipe; // 전후면 샛기둥 파이프 샘플
  public smallColumnPipeSample: ColumnSampleVL_SmallColumnPipe; // 소기둥 파이프 샘플
  public columnBracingRoundBarSample: ColumnSampleVL_ColumnBracingRoundBar; // 기둥 브레싱 환봉 샘플
  public columnBracingPipeSample: ColumnSampleVL_ColumnBracingPipe; // 기둥 브레싱 파이프 샘플

  // 아이템
  public column_pipe: ItemPipe;
  public edgeColumn_pipe: ItemPipe;
  public sideColumn_pipe: ItemPipe;
  public sideStudColumn_pipe: ItemPipe;
  public frontAndBackColumn_pipe: ItemPipe;
  public frontAndBackStudColumn_pipe: ItemPipe;
  public smallColumn_pipe: ItemPipe;
  public columnBracing_roundBar: ItemPipe;
  public columnBracing_cSectionSteel: ItemPipe;
  public columnBracingColumn_bracket: ItemBracket;
  public roundBarAndBracket_trunbuckle: ItemFrameOther;
  public columnAndBracket_boltAndNut: ItemFrameOther;
  public columnAndCSectionSteel_welding: ItemOtherExpense;
  public columnBracing_pipe: ItemPipe;
  public columnBracingAndColumn_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _columnLength: number; // 기둥 길이
  @jsonMember(Number)
  public _columnInterval: number; // 기둥 간격
  @jsonMember(Number)
  public _columnTotalQuantity: number = 0; // 기둥 전체 수량
  @jsonMember(Number)
  public _columnQuantity: number; // 내부 기둥 수량
  @jsonMember(Number)
  public _edgeColumnQuantity: number; // 모서리 기둥 수량
  @jsonMember(Number)
  public _sideColumnQuantity: number; // 측면 기둥 수량
  @jsonMember(Number)
  public _sideStudColumnQuantity: number; // 측면 샛기둥 수량
  @jsonMember(Number)
  public _frontAndBackColumnQuantity: number; // 전후면 기둥 수량
  @jsonMember(Number)
  public _frontAndBackStudColumnQuantity: number; // 전후면 샛기둥 수량
  @jsonMember(Number)
  public _smallColumnQuantity: number; // 소기둥 수량
  @jsonMember(Boolean)
  public _columnPositionEditSelected: boolean; // 기둥 위치별 규격 선택
  @jsonMember(String)
  public _columnBracingType: string; // 기둥 브레싱 형태
  @jsonMember(Number)
  public _columnBracingQuantity: number; // 기둥 브레싱 수량
  @jsonMember(Number)
  public _columnBracingTotalQuantity: number; // 기둥 브레싱 전체 수량

  /**
   * 기둥 길이
   */
  public get columnLength(): number {
    return this._columnLength;
  }

  //
  public set columnLength(value: number) {
    this._columnLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.columnPipeSample.algorithmSpec();
    this.edgeColumnPipeSample.algorithmSpec();
    this.sideColumnPipeSample.algorithmSpec();
    this.sideStudColumnPipeSample.algorithmSpec();
    this.frontAndBackColumnPipeSample.algorithmSpec();
    this.frontAndBackStudColumnPipeSample.algorithmSpec();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();

    // 밴드끈
    if (this.level.index === 0) {
      this.struct.bandstringWorkIL.level1.trunkPosition.bandstringPart.algorithm_totalBandstringLength();
      this.struct.bandstringWorkIL.level1.trunkPosition.polyPart.algorithm_totalBandstringLength();
    }

    // 커튼 개폐
    for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
      curSwitchLevel.columnPosition.linePart.algorithmPart();
      curSwitchLevel.ceilingPosition.linePart.algorithmPart();
    }
  }

  /**
   * 기둥 간격
   */
  public get columnInterval(): number {
    return this._columnInterval;
  }

  //
  public set columnInterval(value: number) {
    this._columnInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnQuantity();
    this.algorithm_sideColumnQuantity();
    this.algorithm_sideStudColumnQuantity();
    this.algorithm_smallColumnQuantity();
    this.algorithmSpec_columnBracing_cSectionSteel();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionVL>this.position).prismMiddlePart.algorithm_middleLineNumber();
    (<ColumnPositionVL>this.position).sideStraightPart.algorithmPart();

    /// //////// 외부 ///////////

    // 물받이
    for (const gutterLevel of this.struct.gutterWorkIL.levelAC as GutterLevelIL[]) {
      gutterLevel.gutterPosition.ironPlatePart.algorithmPart();
      gutterLevel.gutterPosition.plasticPart.algorithmPart();
      gutterLevel.gutterPosition.weavingFilmPart.algorithmPart();
    }

    // 치마
    (<SkirtLevelIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index]
    )).columnPosition.fixingSkirtClipPart.algorithm_clipInterval();

    // 커튼 피복
    const curCoverLevel: CurCoverLevelIL = this.struct.curCoverWorkIL.level1 as CurCoverLevelIL;
    curCoverLevel.curCoverPosition.sideEndpiecePart.algorithmPart();
    curCoverLevel.curCoverPosition.quiltingPart.algorithmPart();
    curCoverLevel.curCoverPosition.nonwovenPart.algorithmPart();
    curCoverLevel.curCoverPosition.matPart.algorithmPart();
    curCoverLevel.curCoverPosition.shadenetPart.algorithmPart();
    curCoverLevel.curCoverPosition.screenPart.algorithmPart();
    curCoverLevel.curCoverPosition.ceilingLevel2QuiltingPart.algorithmPart();
    curCoverLevel.curCoverPosition.ceilingLevel2ShadenetPart.algorithmPart();
    curCoverLevel.curCoverPosition.ceilingLevel2ScreenPart.algorithmPart();
    curCoverLevel.curCoverPosition.ceilingLevel3QuiltingPart.algorithmPart();
    curCoverLevel.curCoverPosition.ceilingLevel3ShadenetPart.algorithmPart();
    curCoverLevel.curCoverPosition.ceilingLevel3ScreenPart.algorithmPart();

    // 커튼 개폐
    const curSwitchLevel: CurSwitchLevelIL = this.struct.curSwitchWorkIL.level1 as CurSwitchLevelIL;
    curSwitchLevel.columnPosition.axisPart.algorithmPart();
    curSwitchLevel.columnPosition.linePart.algorithmPart();
    curSwitchLevel.columnPosition.linePart.algorithm_towLineNumberAndSupportLineNumber();
    curSwitchLevel.columnPosition.fixingClipSidePart.algorithmPart();
    curSwitchLevel.frontPosition.axisPart.algorithmPart();
    curSwitchLevel.frontPosition.linePart.algorithmPart();
    curSwitchLevel.frontPosition.linePart.algorithm_towLineNumberAndSupportLineNumber();
    curSwitchLevel.frontPosition.fixingClipSidePart.algorithmPart();
    curSwitchLevel.backPosition.axisPart.algorithmPart();
    curSwitchLevel.backPosition.linePart.algorithmPart();
    curSwitchLevel.backPosition.fixingClipSidePart.algorithmPart();
    curSwitchLevel.backPosition.linePart.algorithm_towLineNumberAndSupportLineNumber();
    curSwitchLevel.ceilingPosition.framePart.algorithmPart();
    curSwitchLevel.ceilingPosition.linePart.algorithmPart();
    curSwitchLevel.ceilingPosition.linePart.algorithm_towLineNumberAndSupportLineNumber();
    curSwitchLevel.ceilingPosition.fixingClipCeilingPart.algorithmPart();

    (<CurSwitchPositionIL>(
      this.struct.curSwitchWorkIL.level1.ceilingPosition
    )).linePart.algorithm_towLineNumberAndSupportLineNumber();
    (<CurSwitchPositionIL>(
      this.struct.curSwitchWorkIL.level1.columnPosition
    )).linePart.algorithm_towLineNumberAndSupportLineNumber();
    (<CurSwitchPositionIL>(
      this.struct.curSwitchWorkIL.level1.frontPosition
    )).linePart.algorithm_towLineNumberAndSupportLineNumber();
    (<CurSwitchPositionIL>(
      this.struct.curSwitchWorkIL.level1.backPosition
    )).linePart.algorithm_towLineNumberAndSupportLineNumber();

    // 양액시스템
    if (this.level.index === 0) {
      this.struct.hydCultivationWork.level1.wholePosition.bedPart.algorithm_hallwayWidth();
    }
    // 운반레일
    if (this.level.index === 0) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithm_railHookInterval();
      this.struct.railWork.level1.wholePosition.sideRailPart.algorithm_railHookInterval();
    }
  }

  /**
   * 기둥 전체 수량
   */
  public get columnTotalQuantity(): number {
    return this._columnTotalQuantity;
  }

  //
  public set columnTotalQuantity(value: number) {
    this._columnTotalQuantity = CommonUtil.fixFloat(value);

    this.algorithmPart();
    /// //////// 외부 ///////////

    // 바닥 골조
    this.struct.foundationWorkIL.level1.foundationPosition.girthPipePart.algorithmPart();
    this.struct.foundationWorkIL.level1.foundationPosition.columnConcretePart.algorithm_moldQuantity();
    this.struct.columnWorkVL.level1.columnPosition.prismBeamPart.algorithmPart();
  }

  /**
   * 내부 기둥 수량
   */
  public get columnQuantity(): number {
    return this._columnQuantity;
  }

  //
  public set columnQuantity(value: number) {
    this._columnQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnTotalQuantity();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionVL>this.position).prismBeamPart.algorithmPart();
    (<ColumnPositionVL>this.position).prismMiddlePart.algorithmPart();
  }

  /**
   * 모서리 기둥 수량
   */
  public get edgeColumnQuantity(): number {
    return this._edgeColumnQuantity;
  }

  //
  public set edgeColumnQuantity(value: number) {
    this._edgeColumnQuantity = CommonUtil.fixFloat(value);

    /// //////// 외부 ///////////
    (<CurSwitchPartIL_FrameVL>this.struct.curSwitchWorkIL.level1.columnPosition.framePartVL).algorithmPart();

    // 알고리즘
    this.algorithm_columnTotalQuantity();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionVL>this.position).prismBeamPart.algorithmPart();
  }

  /**
   * 측면 기둥 수량
   */
  public get sideColumnQuantity(): number {
    return this._sideColumnQuantity;
  }

  //
  public set sideColumnQuantity(value: number) {
    this._sideColumnQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnTotalQuantity();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionVL>this.position).prismBeamPart.algorithmPart();
  }

  /**
   * 측면 샛기둥 수량
   */
  public get sideStudColumnQuantity(): number {
    return this._sideStudColumnQuantity;
  }

  //
  public set sideStudColumnQuantity(value: number) {
    this._sideStudColumnQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnTotalQuantity();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionVL>this.position).prismBeamPart.algorithmPart();
  }

  /**
   * 전후면 기둥 수량
   */
  public get frontAndBackColumnQuantity(): number {
    return this._frontAndBackColumnQuantity;
  }

  //
  public set frontAndBackColumnQuantity(value: number) {
    this._frontAndBackColumnQuantity = CommonUtil.fixFloat(value);

    /// //////// 외부 ///////////
    (<CurSwitchPartIL_FrameVL>this.struct.curSwitchWorkIL.level1.columnPosition.framePartVL).algorithmPart();

    // 알고리즘
    this.algorithm_columnTotalQuantity();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionVL>this.position).prismBeamPart.algorithmPart();
  }

  /**
   * 전후면 샛기둥 수량
   */
  public get frontAndBackStudColumnQuantity(): number {
    return this._frontAndBackStudColumnQuantity;
  }

  //
  public set frontAndBackStudColumnQuantity(value: number) {
    this._frontAndBackStudColumnQuantity = CommonUtil.fixFloat(value);

    /// //////// 외부 ///////////
    (<CurSwitchPartIL_FrameVL>this.struct.curSwitchWorkIL.level1.columnPosition.framePartVL).algorithmPart();

    // 알고리즘
    this.algorithm_columnTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 소기둥 수량
   */
  public get smallColumnQuantity(): number {
    return this._smallColumnQuantity;
  }

  //
  public set smallColumnQuantity(value: number) {
    this._smallColumnQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnTotalQuantity();
    this.algorithmPart();

    this.struct.foundationWorkIL.level1.foundationPosition.columnConcretePart.algorithm_moldQuantity();
  }

  /**
   * 기둥 위치별 규격 선택
   */
  public get columnPositionEditSelected(): boolean {
    return this._columnPositionEditSelected;
  }

  //
  public set columnPositionEditSelected(value: boolean) {
    this._columnPositionEditSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기둥 브레싱 형태
   */
  public get columnBracingType(): string {
    return this._columnBracingType;
  }

  //
  public set columnBracingType(value: string) {
    this._columnBracingType = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기둥 브레싱 수량
   */
  public get columnBracingQuantity(): number {
    return this._columnBracingQuantity;
  }

  public set columnBracingQuantity(value: number) {
    this._columnBracingQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnBracingTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 기둥 브레싱 전체 수량
   */
  public get columnBracingTotalQuantity(): number {
    return this._columnBracingTotalQuantity;
  }
  public set columnBracingTotalQuantity(value: number) {
    this._columnBracingTotalQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.columnPipeSample = new ColumnSampleVL_ColumnPipe();
    this.edgeColumnPipeSample = new ColumnSampleVL_EdgeColumnPipe();
    this.sideColumnPipeSample = new ColumnSampleVL_SideColumnPipe();
    this.sideStudColumnPipeSample = new ColumnSampleVL_SideStudColumnPipe();
    this.frontAndBackColumnPipeSample = new ColumnSampleVL_FrontAndBackColumnPipe();
    this.frontAndBackStudColumnPipeSample = new ColumnSampleVL_FrontAndBackStudColumnPipe();
    this.smallColumnPipeSample = new ColumnSampleVL_SmallColumnPipe();
    this.columnBracingRoundBarSample = new ColumnSampleVL_ColumnBracingRoundBar();
    this.columnBracingPipeSample = new ColumnSampleVL_ColumnBracingPipe();

    this.sampleAC = [
      this.columnPipeSample,
      this.edgeColumnPipeSample,
      this.sideColumnPipeSample,
      this.sideStudColumnPipeSample,
      this.frontAndBackColumnPipeSample,
      this.frontAndBackStudColumnPipeSample,
      this.smallColumnPipeSample,
      this.columnBracingRoundBarSample,
      this.columnBracingPipeSample,
    ];

    // 아이템
    this.column_pipe = new ItemPipe();
    this.edgeColumn_pipe = new ItemPipe();
    this.sideColumn_pipe = new ItemPipe();
    this.sideStudColumn_pipe = new ItemPipe();
    this.frontAndBackColumn_pipe = new ItemPipe();
    this.frontAndBackStudColumn_pipe = new ItemPipe();
    this.smallColumn_pipe = new ItemPipe();
    this.columnBracing_roundBar = new ItemPipe();
    this.columnBracing_cSectionSteel = new ItemPipe();
    this.columnBracingColumn_bracket = new ItemBracket();
    this.roundBarAndBracket_trunbuckle = new ItemFrameOther();
    this.columnAndBracket_boltAndNut = new ItemFrameOther();
    this.columnAndCSectionSteel_welding = new ItemOtherExpense();
    this.columnBracing_pipe = new ItemPipe();
    this.columnBracingAndColumn_welding = new ItemOtherExpense();

    this.itemAC = [
      this.column_pipe,
      this.edgeColumn_pipe,
      this.sideColumn_pipe,
      this.sideStudColumn_pipe,
      this.frontAndBackColumn_pipe,
      this.frontAndBackStudColumn_pipe,
      this.smallColumn_pipe,
      this.columnBracing_roundBar,
      this.columnBracing_cSectionSteel,
      this.columnBracingColumn_bracket,
      this.roundBarAndBracket_trunbuckle,
      this.columnAndBracket_boltAndNut,
      this.columnAndCSectionSteel_welding,
      this.columnBracing_pipe,
      this.columnBracingAndColumn_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.columnPipeSample = <ColumnSampleVL_ColumnPipe>this.sampleAC[0];
    this.edgeColumnPipeSample = <ColumnSampleVL_EdgeColumnPipe>this.sampleAC[1];
    this.sideColumnPipeSample = <ColumnSampleVL_SideColumnPipe>this.sampleAC[2];
    this.sideStudColumnPipeSample = <ColumnSampleVL_SideStudColumnPipe>this.sampleAC[3];
    this.frontAndBackColumnPipeSample = <ColumnSampleVL_FrontAndBackColumnPipe>this.sampleAC[4];
    this.frontAndBackStudColumnPipeSample = <ColumnSampleVL_FrontAndBackStudColumnPipe>this.sampleAC[5];
    this.smallColumnPipeSample = <ColumnSampleVL_SmallColumnPipe>this.sampleAC[6];
    this.columnBracingRoundBarSample = <ColumnSampleVL_ColumnBracingRoundBar>this.sampleAC[7];
    this.columnBracingPipeSample = <ColumnSampleVL_ColumnBracingPipe>this.sampleAC[8];

    // 아이템
    this.column_pipe = <ItemPipe>this.itemAC[0];
    this.edgeColumn_pipe = <ItemPipe>this.itemAC[1];
    this.sideColumn_pipe = <ItemPipe>this.itemAC[2];
    this.sideStudColumn_pipe = <ItemPipe>this.itemAC[3];
    this.frontAndBackColumn_pipe = <ItemPipe>this.itemAC[4];
    this.frontAndBackStudColumn_pipe = <ItemPipe>this.itemAC[5];
    this.smallColumn_pipe = <ItemPipe>this.itemAC[6];
    this.columnBracing_roundBar = <ItemPipe>this.itemAC[7];
    this.columnBracing_cSectionSteel = <ItemPipe>this.itemAC[8];
    this.columnBracingColumn_bracket = <ItemBracket>this.itemAC[9];
    this.roundBarAndBracket_trunbuckle = <ItemFrameOther>this.itemAC[10];
    this.columnAndBracket_boltAndNut = <ItemFrameOther>this.itemAC[11];
    this.columnAndCSectionSteel_welding = <ItemOtherExpense>this.itemAC[12];
    this.columnBracing_pipe = <ItemPipe>this.itemAC[13];
    this.columnBracingAndColumn_welding = <ItemOtherExpense>this.itemAC[14];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param columnLength: number 기둥 길이
   * @param columnInterval: number 기둥 간격
   * @param edgeColumnQuantity: number 모서리 기둥 수량
   * @param columnPositionEditSelected: boolean 기둥 위치별 규격 선택
   * @param columnBracingType: string 기둥 브레싱 형태
   * @param columnBracingQuantity: number 기둥 브레싱 수량
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    columnLength: number = 0,
    columnInterval: number = 0,
    edgeColumnQuantity: number = 0,
    columnPositionEditSelected: boolean = false,
    columnBracingType: string = "",
    columnBracingQuantity: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._columnLength = columnLength;
    this._columnInterval = columnInterval;
    this._edgeColumnQuantity = edgeColumnQuantity;
    this._columnPositionEditSelected = columnPositionEditSelected;
    this._columnBracingType = columnBracingType;
    this._columnBracingQuantity = columnBracingQuantity;

    // 샘플
    if (this.level.index >= 0) {
      this.columnPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "내부 기둥",
        "각형, 일반, 125×75mm, 2.1T, 5m, -",
      );
      this.edgeColumnPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "모서리 기둥",
        "각형, 일반, 125×125mm, 2.9T, 5m, -",
      );
      this.sideColumnPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 기둥",
        "각형, 일반, 125×75mm, 2.9T, 5m, -",
      );
      this.sideStudColumnPipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 샛기둥",
        "각형, 일반, 125×75mm, 2.9T, 5m, -",
      );
      this.frontAndBackColumnPipeSample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "전후면 기둥",
        "각형, 일반, 125×125mm, 2.9T, 5m, -",
      );
      this.frontAndBackStudColumnPipeSample.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "전후면 샛기둥",
        "각형, 일반, 125×75mm, 2.9T, 5m, -",
      );
      this.smallColumnPipeSample.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "소기둥",
        "각형, 일반, 75×45mm, 2.3T, 8m, -",
      );
      this.columnBracingRoundBarSample.setDefaultData(
        7,
        CONST.ITEM_ID_ROUND_BAR,
        CONST.ITEM_NAME_ROUND_BAR,
        "기둥 브레싱",
        "환봉, 일반, 12mm, -, 6m, -",
      );
      this.columnBracingPipeSample.setDefaultData(
        8,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 브레싱",
        "각형, 일반, 125×75mm, 2.1T, 5m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.column_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "내부 기둥",
        "각형, 일반, 125×75mm, 2.1T, 5m, -",
      );
      this.edgeColumn_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "모서리 기둥",
        "각형, 일반, 125×125mm, 2.9T, 5m, -",
      );
      this.sideColumn_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 기둥",
        "각형, 일반, 125×75mm, 2.9T, 5m, -",
      );
      this.sideStudColumn_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 샛기둥",
        "각형, 일반, 125×75mm, 2.9T, 5m, -",
      );
      this.frontAndBackColumn_pipe.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "전후면 기둥",
        "각형, 일반, 125×125mm, 2.9T, 5m, -",
      );
      this.frontAndBackStudColumn_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "전후면 샛기둥",
        "각형, 일반, 125×75mm, 2.9T, 5m, -",
      );
      this.smallColumn_pipe.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "소기둥",
        "각형, 일반, 75×45mm, 2.3T, 8m, -",
      );
      this.columnBracing_roundBar.setDefaultData(
        7,
        CONST.ITEM_ID_ROUND_BAR,
        CONST.ITEM_NAME_ROUND_BAR,
        "기둥 브레싱",
        "환봉, 일반, 12mm, -, 6m, -",
      );
      this.columnBracing_cSectionSteel.setDefaultData(
        8,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "기둥 브레싱",
        "각형, 일반, 75×45mm, 2.1T, 4m, -",
      );
      this.columnBracingColumn_bracket.setDefaultData(
        9,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "기둥 브레싱",
        "일반, 9T, 75×75mm",
      );
      this.roundBarAndBracket_trunbuckle.setDefaultData(
        10,
        CONST.ITEM_ID_TURNBUCKLE,
        CONST.ITEM_NAME_TURNBUCKLE,
        "기둥 브레싱",
        "12mm",
      );
      this.columnAndBracket_boltAndNut.setDefaultData(
        11,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "기둥 브레싱",
        "육각, M12*140mm",
      );
      this.columnAndCSectionSteel_welding.setDefaultData(
        12,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "기둥 브레싱",
        "일반",
      );
      this.columnBracing_pipe.setDefaultData(
        13,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 브레싱",
        "각형, 일반, 125×75mm, 2.1T, 5m, -",
      );
      this.columnBracingAndColumn_welding.setDefaultData(
        14,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "기둥 브레싱",
        "일반",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_columnLength();
    this.algorithm_columnTotalQuantity();
    this.algorithm_columnQuantity();
    this.algorithm_sideColumnQuantity();
    this.algorithm_sideStudColumnQuantity();
    this.algorithm_frontAndBackColumnQuantity();
    this.algorithm_frontAndBackStudColumnQuantity();
    this.algorithm_smallColumnQuantity();
    this.algorithm_columnBracingTotalQuantity();
    this.smallColumnPipeSample.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.column_pipe.selected = true;
    this.column_pipe.visible = true;
    this.edgeColumn_pipe.selected = true;
    this.edgeColumn_pipe.visible = true;
    this.sideColumn_pipe.selected = true;
    this.sideColumn_pipe.visible = true;
    this.sideStudColumn_pipe.selected = true;
    this.sideStudColumn_pipe.visible = true;
    this.frontAndBackColumn_pipe.selected = true;
    this.frontAndBackColumn_pipe.visible = true;
    this.frontAndBackStudColumn_pipe.selected = true;
    this.frontAndBackStudColumn_pipe.visible = true;
    this.smallColumn_pipe.selected = true;
    this.smallColumn_pipe.visible = true;
    if (this.columnBracingType === CONST.LB_COLUMN_BRACING_TYPE_X_BRACING) {
      this.columnBracing_roundBar.selected = true;
      this.columnBracing_roundBar.visible = true;
      this.columnBracing_cSectionSteel.selected = true;
      this.columnBracing_cSectionSteel.visible = true;
      this.columnBracingColumn_bracket.selected = true;
      this.columnBracingColumn_bracket.visible = true;
      this.roundBarAndBracket_trunbuckle.selected = true;
      this.roundBarAndBracket_trunbuckle.visible = true;
      this.columnAndBracket_boltAndNut.selected = true;
      this.columnAndBracket_boltAndNut.visible = true;
      this.columnAndCSectionSteel_welding.selected = true;
      this.columnAndCSectionSteel_welding.visible = true;
      this.columnBracing_pipe.selected = false;
      this.columnBracing_pipe.visible = false;
      this.columnBracingAndColumn_welding.selected = false;
      this.columnBracingAndColumn_welding.visible = false;
    } else {
      this.columnBracing_pipe.selected = true;
      this.columnBracing_pipe.visible = true;
      this.columnBracingAndColumn_welding.selected = true;
      this.columnBracingAndColumn_welding.visible = true;
      this.columnBracing_roundBar.selected = false;
      this.columnBracing_roundBar.visible = false;
      this.columnBracing_cSectionSteel.selected = false;
      this.columnBracing_cSectionSteel.visible = false;
      this.columnBracingColumn_bracket.selected = false;
      this.columnBracingColumn_bracket.visible = false;
      this.roundBarAndBracket_trunbuckle.selected = false;
      this.roundBarAndBracket_trunbuckle.visible = false;
      this.columnAndBracket_boltAndNut.selected = false;
      this.columnAndBracket_boltAndNut.visible = false;
      this.columnAndCSectionSteel_welding.selected = false;
      this.columnAndCSectionSteel_welding.visible = false;
    }

    /// //////// 수량 ///////////

    if (this.column_pipe.specLength > this.columnLength) {
      this.column_pipe.designQuantity =
        this.columnQuantity / Math.floor(this.column_pipe.specLength / this.columnLength);
    } else {
      this.column_pipe.designQuantity = this.columnQuantity;
    }

    if (this.edgeColumn_pipe.specLength > this.columnLength) {
      this.edgeColumn_pipe.designQuantity =
        this.edgeColumnQuantity / Math.floor(this.edgeColumn_pipe.specLength / this.columnLength);
    } else {
      this.edgeColumn_pipe.designQuantity = this.edgeColumnQuantity;
    }

    if (this.sideColumn_pipe.specLength > this.columnLength) {
      this.sideColumn_pipe.designQuantity =
        this.sideColumnQuantity / Math.floor(this.sideColumn_pipe.specLength / this.columnLength);
    } else {
      this.sideColumn_pipe.designQuantity = this.sideColumnQuantity;
    }

    if (this.sideStudColumn_pipe.specLength > this.columnLength) {
      this.sideStudColumn_pipe.designQuantity =
        this.sideStudColumnQuantity / Math.floor(this.sideStudColumn_pipe.specLength / this.columnLength);
    } else {
      this.sideStudColumn_pipe.designQuantity = this.sideStudColumnQuantity;
    }

    if (this.frontAndBackColumn_pipe.specLength > this.columnLength) {
      this.frontAndBackColumn_pipe.designQuantity =
        this.frontAndBackColumnQuantity / Math.floor(this.frontAndBackColumn_pipe.specLength / this.columnLength);
    } else {
      this.frontAndBackColumn_pipe.designQuantity = this.frontAndBackColumnQuantity;
    }

    if (this.frontAndBackStudColumn_pipe.specLength > this.columnLength) {
      this.frontAndBackStudColumn_pipe.designQuantity =
        this.frontAndBackStudColumnQuantity /
        Math.floor(this.frontAndBackStudColumn_pipe.specLength / this.columnLength);
    } else {
      this.frontAndBackStudColumn_pipe.designQuantity = this.frontAndBackStudColumnQuantity;
    }

    this.smallColumn_pipe.designQuantity =
      this.smallColumnQuantity / Math.floor(this.smallColumn_pipe.specLength / 0.8);

    this.columnBracing_roundBar.designQuantity = this.columnBracingTotalQuantity * 2;

    this.columnBracing_cSectionSteel.designQuantity = this.columnBracingTotalQuantity * 2;

    this.columnBracingColumn_bracket.designQuantity = this.columnBracing_roundBar.getSelectedQuantity() * 2;

    this.roundBarAndBracket_trunbuckle.designQuantity = this.columnBracing_roundBar.getSelectedQuantity();

    this.columnAndBracket_boltAndNut.designQuantity = this.columnBracingColumn_bracket.getSelectedQuantity();

    this.columnAndCSectionSteel_welding.designQuantity =
      this.columnBracingTotalQuantity * 2 + this.columnBracingColumn_bracket.getSelectedQuantity();

    this.columnBracing_pipe.designQuantity = this.columnBracingTotalQuantity;

    this.columnBracingAndColumn_welding.designQuantity = this.columnBracingTotalQuantity * 3;
  }

  /**
   * 알고리즘: 기둥 길이 <- 측고(기본정보), 매설(기본정보)
   */
  public algorithm_columnLength(): void {
    this.columnLength = this.basicLevel.heightShoulder + this.basicLevel.depthLaying;
  }

  /**
   * 알고리즘: 전체 기둥 수량 <- 내부 기둥 수량, 모서리 기둥 수량, 측면 기둥 수량, 측면 샛기둥 수량, 전후면 기둥 수량, 전후면 샛기둥 수량, 소기둥 수량
   */
  public algorithm_columnTotalQuantity(): void {
    this.columnTotalQuantity =
      this.columnQuantity +
      this.edgeColumnQuantity +
      this.sideColumnQuantity +
      this.sideStudColumnQuantity +
      this.frontAndBackColumnQuantity +
      this.frontAndBackStudColumnQuantity +
      this.smallColumnQuantity;
  }

  /**
   * 알고리즘: 내부 기둥 수량 <- 기둥 간격, 길이(기본정보), 동수(기본정보)
   */
  public algorithm_columnQuantity(): void {
    this.columnQuantity =
      (Math.floor(this.basicLevel.length / this.columnInterval) - 1) *
      (this.basic.buildingNumber - 1) *
      CONST.NUM_EXTRA_RATE_COLUMN_PIPE;
  }

  /**
   * 알고리즘: 측면 기둥 수량 <- 기둥 간격, 길이(기본정보)
   */
  public algorithm_sideColumnQuantity(): void {
    this.sideColumnQuantity =
      (Math.floor(this.basicLevel.length / this.columnInterval) - 1) * 2 * CONST.NUM_EXTRA_RATE_COLUMN_PIPE;
  }

  /**
   * 알고리즘: 측면 샛기둥 수량 <- 기둥 간격, 길이(기본정보)
   */
  public algorithm_sideStudColumnQuantity(): void {
    this.sideStudColumnQuantity =
      Math.floor(this.basicLevel.length / this.columnInterval) * 2 * CONST.NUM_EXTRA_RATE_COLUMN_PIPE;
  }

  /**
   * 알고리즘: 전후면 기둥 수량 <- 동수(기본정보)
   */
  public algorithm_frontAndBackColumnQuantity(): void {
    this.frontAndBackColumnQuantity = (this.basic.buildingNumber * 2 - 1) * 2 * CONST.NUM_EXTRA_RATE_COLUMN_PIPE;
  }

  /**
   * 알고리즘: 전후면 샛기둥 수량 <- 동수(기본정보)
   */
  public algorithm_frontAndBackStudColumnQuantity(): void {
    this.frontAndBackStudColumnQuantity = this.basic.buildingNumber * 2 * 2 * CONST.NUM_EXTRA_RATE_COLUMN_PIPE;
  }

  /**
   * 알고리즘: 소기둥 수량 <- 기둥 간격, 길이(기본정보), 동수(기본정보)
   */
  public algorithm_smallColumnQuantity(): void {
    this.smallColumnQuantity =
      (CommonUtil.roundUp(this.basicLevel.length / this.columnInterval) - 1) *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_COLUMN_PIPE;
  }

  /**
   * 알고리즘: 기둥 브레싱 전체 수량 <- 기둥 브레싱 수량, 동수(기본정보)
   */
  public algorithm_columnBracingTotalQuantity(): void {
    this.columnBracingTotalQuantity = this.columnBracingQuantity * (this.basic.buildingNumber + 1);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [내부 기둥] 파이프 <- 기둥 파이프 샘플
   */
  public algorithmSpec_column_pipe(): void {
    this.column_pipe.specs = this.columnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [모서리 기둥] 파이프 <- 모서리 기둥 파이프 샘플
   */
  public algorithmSpec_edgeColumn_pipe(): void {
    this.edgeColumn_pipe.specs = this.edgeColumnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [측면 기둥] 파이프 <- 측면 기둥 파이프 샘플
   */
  public algorithmSpec_sideColumn_pipe(): void {
    this.sideColumn_pipe.specs = this.sideColumnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [측면 샛기둥] 파이프 <- 측면 샛기둥 파이프 샘플
   */
  public algorithmSpec_sideStudColumn_pipe(): void {
    this.sideStudColumn_pipe.specs = this.sideStudColumnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [전후면 기둥] 파이프 <- 전후면 기둥 파이프 샘플
   */
  public algorithmSpec_frontAndBackColumn_pipe(): void {
    this.frontAndBackColumn_pipe.specs = this.frontAndBackColumnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [전후면 샛기둥] 파이프 <- 전후면 샛기둥 파이프 샘플
   */
  public algorithmSpec_frontAndBackStudColumn_pipe(): void {
    this.frontAndBackStudColumn_pipe.specs = this.frontAndBackStudColumnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [소기둥] 파이프 <- 소기둥 파이프 샘플
   */
  public algorithmSpec_smallColumn_pipe(): void {
    this.smallColumn_pipe.specs = this.smallColumnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [기둥 브레싱] 환봉 <- 환봉 샘플
   */
  public algorithmSpec_columnBracing_roundBar(): void {
    this.columnBracing_roundBar.specs = this.columnBracingRoundBarSample.specs;
  }

  /**
   * 규격 알고리즘: [기둥 브레싱] C형강 <- 측면 가로대 C형강 샘플
   */
  public algorithmSpec_columnBracing_cSectionSteel(): void {
    this.columnBracing_cSectionSteel.specLength = this.columnInterval;
  }

  /**
   * 규격 알고리즘: [기둥 브레싱] 브라켓트
   */
  public algorithmSpec_columnBracingColumn_bracket(): void {}

  /**
   * 규격 알고리즘: [기둥 브레싱] 턴버클 <- 환봉 샘플
   */
  public algorithmSpec_roundBarAndBracket_turnbuckle(): void {
    this.roundBarAndBracket_trunbuckle.specs = this.columnBracingRoundBarSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [기둥 브레싱] 볼트&너트
   */
  public algorithmSpec_columnAndBracket_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [기둥 브레싱] 용접 <- 공통 샘플
   */
  public algorithmSpec_columnAndCSectionSteel_welding(): void {}

  /**
   * 규격 알고리즘: [기둥 브레싱] 파이프 <- 기둥 브레싱 파이프 샘플
   */
  public algorithmSpec_columnBracing_pipe(): void {
    this.columnBracing_pipe.specs = this.columnBracingPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [기둥 브레싱 + 기둥] 용접 <- 공통 샘플
   */
  public algorithmSpec_columnBracingAndColumn_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
