import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";
import { SkirtLevel } from "vhows-design/src/object/design/cover/skirt/SkirtLevel";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { PartitionPosition } from "vhows-design/src/object/design/other/partition/PartitionPosition";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { BandstringLevel } from "vhows-design/src/object/design/cover/bandstring/BandstringLevel";
import { WindowPosition } from "vhows-design/src/object/design/other/window/WindowPosition";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { TrunkPart_Straight } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Straight";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { GutterLevel } from "vhows-design/src/object/design/frame/gutter/GutterLevel";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";
import { WindbreakLevel } from "vhows-design/src/object/design/cover/windbreak/WindbreakLevel";
import { EndpiecePosition } from "vhows-design/src/object/design/frame/endpiece/EndpiecePosition";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";
import { RafterExtensionData } from "vhows-design/src/object/design/frame/trunk/TrunkPartData";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-31
 */
@jsonObject
export class TrunkSample_RafterPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // 길이
  // @override
  public get specLength(): number {
    return this._specLength;
  }

  //
  public set specLength(value: number) {
    if (isNaN(value) || this._specLength === CommonUtil.roundUp(value, 1)) return;

    // 일반화된 간격 적용 - 0.1m 간격(소수점 둘째자리 올림)
    this._specLength = CommonUtil.roundUp(value, 1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      this.algorithmSample_specLength();
      (<TrunkPart_Rafter>this.part).algorithm_rafterTotalLength();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  // @override
  public algorithmBasic(): void {
    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    this.algorithmSpec_specLength();

    super.algorithmBasic();
    rafterPart.algorithm_rafterExtension();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    rafterPart.algorithmSpec_rafter_pipe();
    rafterPart.algorithmSpec_extensionPinned_pipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
    rafterPart.algorithm_rafterExtension();
    /// //////// 외부 ///////////
  }

  // @override
  public algorithmSample_specsList(): void {
    /// //////// 내부 ///////////

    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    rafterPart.algorithmSpec_rafter_pipe();
    rafterPart.algorithmSpec_rafter_pipeBendingExpense();
    rafterPart.algorithmSpec_extensionPinned_pipe();
    rafterPart.rafterExtensionPipeSample.algorithmSpec();
    rafterPart.algorithm_extensionType();
    rafterPart.algorithmProduct_bracingAndRafter_itemHolder();
    rafterPart.algorithmSpec_bracingAndRafter_itemHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const straightPart: TrunkPart_Straight = (<TrunkPosition>this.position).straightPart;
    straightPart.algorithmSpec_straightRoofAndRafter_steelStringGrabber();
    straightPart.algorithmSpec_straightRoofAndRafter_itemHolder();
    straightPart.algorithmSpec_straightRoofSubAndRafter_steelStringGrabber();
    straightPart.algorithmSpec_straightSideAndRafter_steelStringGrabber();
    straightPart.algorithmSpec_straightSideAndRafter_itemHolder();
    straightPart.algorithmSpec_straightSideSubAndRafter_steelStringGrabber();
    straightPart.algorithmSpec_straightFloorAndRafter_steelStringGrabber();
    straightPart.algorithmSpec_straightFloorAndRafter_itemHolder();

    /// //////// 외부 ///////////

    // 마구리 골조
    for (const endpiecePosition of this.struct.endpieceWork.levelAC[this.level.index]
      .positionAC as EndpiecePosition[]) {
      endpiecePosition.studPart.studPipeSample.algorithmSpec();
      endpiecePosition.studPart.algorithmSpec_studAndRafterCenter_tHolder();
      endpiecePosition.studPart.algorithmSpec_studAndRafterCenterRafter_normalHolder();
      endpiecePosition.studPart.algorithmSpec_studAndRafterDiagonal_diagonalTHolder();
      endpiecePosition.studPart.algorithmSpec_studAndRafterDiagonal_diagonalTClamp();
      endpiecePosition.studPart.algorithmSpec_studAndRafterDiagonalRafter_normalHolder();
      endpiecePosition.middlePart.middlePipeSample.algorithmSpec();
    }

    // 물받이
    const gutterLevel: GutterLevel = <GutterLevel>this.struct.gutterWork.levelAC[this.level.index];
    gutterLevel.gutterPosition.condensationGutterPart.algorithmSpec_condensationGutter_gutterHanger();
    gutterLevel.gutterPosition.ironPlatePart.algorithmSpec_ironPlate_ubar();
    gutterLevel.gutterPosition.plasticPart.algorithmSpec_plastic_ubar();
    gutterLevel.gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_steelStringGrabber();

    // 개폐기
    const switcherLevel: SwitcherLevel = <SwitcherLevel>this.struct.switcherWork.levelAC[this.level.index];
    for (const switcherPosition of switcherLevel.positionAC as SwitcherPosition[]) {
      switcherPosition.supportPart.rafterSupportPipeSample.algorithmSpec();
    }
    switcherLevel.roofPosition.axisPart.algorithmSpec_axis_slopeClip();

    // 피복 고정
    const fixingLevel: FixingLevel = <FixingLevel>this.struct.fixingWork.levelAC[this.level.index];
    fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipRafter_normalClip();
    fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipRafter_normalClip();

    // 치마 피복
    const skirtLevel: SkirtLevel = <SkirtLevel>this.struct.skirtWork.levelAC[this.level.index];
    skirtLevel.sidePosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    skirtLevel.roofPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

    // 바람막이 피복
    const windbreakLevel: WindbreakLevel = <WindbreakLevel>this.struct.windbreakWork.levelAC[this.level.index];
    windbreakLevel.sidePosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    windbreakLevel.roofPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();

    // 밴드끈
    if (this.level.index === 0) {
      const bandstringLevel: BandstringLevel = <BandstringLevel>this.struct.bandstringWork.levelAC[this.level.index];
      bandstringLevel.trunkPosition.fixingHookPart.hookHolderSample.algorithmSpec();
    }

    // 창문
    if (this.level.index === 0) {
      for (const windowPosition of this.struct.windowWork.level1.positionAC as WindowPosition[]) {
        windowPosition.sashPart.sashFramePipeSample.algorithmSpec();
      }
    }

    // 칸막이
    if (this.level.index === 0) {
      for (const partitionPosition of this.struct.partitionWork.level1.positionAC as PartitionPosition[]) {
        partitionPosition.studPart.studPipeSample.algorithmSpec();
        partitionPosition.studPart.algorithmSpec_studAndRafterCenter_tHolder();
        partitionPosition.studPart.algorithmSpec_studAndRafterCenterRafter_normalHolder();
        partitionPosition.studPart.algorithmSpec_studAndRafterDiagonal_diagonalTHolder();
        partitionPosition.studPart.algorithmSpec_studAndRafterDiagonalRafter_normalHolder();
        partitionPosition.studPart.algorithmPart();
        partitionPosition.fixingNormalClipPart.algorithmSpec_normalClipRafter_normalClip();
      }
    }

    // 선반/균상다이
    if (this.level.index === 0) {
      const rackPosition: RackPosition = this.struct.rackWork.level1.wholePosition;
      rackPosition.rackPart.algorithmSpec_rackMiddleAndRafter_tHolder();
      rackPosition.rackPart.algorithmSpec_rackMiddleAndRafter2_normalHolder();
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tHolder();
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tClamp();
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame2_normalHolder();
      rackPosition.rackPart.algorithmPart();
    }

    // 천창 골조
    if (this.level.index === 0) {
      this.struct.skyFrameWork.level1.skylightPosition.enhancedPart.algorithmSpec_enhanced_saddleHolder();
    }

    // 천창 개폐
    this.struct.skySwitchWork.level1.skylightPosition.rackpinionPart.algorithmSpec_rackpinion_skylightMotor();
    this.struct.skySwitchWork.level1.skylightPosition.rackpinionPart.algorithmSpec_rackpinionAaxisHolder_bearingSet();

    // 관수 지관부
    const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
    wateringPosition.miniklerScreenPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.miniklerFountainPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.miniklerFogPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.hoseScreenPart.algorithmSpec_hose_fountainHoseHangerScreen();
    wateringPosition.hoseSidePart.algorithmSpec_hose_fountainHoseHangerSide();

    // 운반레일
    if (this.level.index === this.basic.levelNumber - 1) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithmSpec_railAndFrame_secondLevelRailHook();
      this.struct.railWork.level1.wholePosition.sideRailPart.algorithmSpec_railAndFrame_sideRailHook();
    }
  }

  /**
   * 샘플 알고리즘: 규격 길이 <br/>
   *  - 규격리스트와 규격 길이를 분리함
   */
  public algorithmSample_specLength(): void {
    /// //////// 내부 ///////////

    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    rafterPart.algorithmSpec_rafter_pipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  public extractRafterExtensionData(): void {}

  /**
   * 규격 알고리즘: 단위길이 <- 서까래 길이 이상값(기본정보), 서까래 길이 반올림 선택 <br/>
   * - 기본정보로부터 단위길이 지정 <br/>
   * - 무조건 기본정보의 이상적인 서까래 길이로 덮어씌움
   */
  public algorithmSpec_specLength(): void {
    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    const basicLevel: BasicLevel = this.part.basicLevel;
    let length: number;
    let result: any;

    if (rafterPart.extensionSelected === true) {
      result = rafterPart.extractRafterExtensionData(this.basic.structureName, this.specPipeCrossSize);
      const data = result.data.find(v => v.rafterTotalLength === CommonUtil.round(rafterPart.lengthRafterIdeal));
      if (data?.rafterPipeSpecLength) {
        length = data.rafterPipeSpecLength;
      } else {
        length = rafterPart.lengthRafterIdeal - rafterPart.extensionLength * rafterPart.extensionPositionValue;
      }
    } else {
      length = basicLevel.lengthRafterIdeal;
    }

    if (rafterPart.rafterLengthRoundSelected === true) {
      length = Math.round(length);
    }

    this.specLength = length;
  }

  /**
   * 규격 알고리즘: 단위길이 <- 서까래 확장 선택, 서까래 확장 길이, 서까래 확장 위치, 이상적인 서까래 길이 <br/>
   * - 서까래 확장 정보로부터 단위길이 지정 <br/>
   * - 서까래 확장 정보가 달라졌을때, 현재 최종 서까래 길이를 기준으로 단위길이를 계산
   */
  public algorithmSpec_specLengthByRafterExtension(): void {
    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    let length: number;
    let result: any;

    if (rafterPart.extensionSelected === true) {
      result = rafterPart.extractRafterExtensionData(this.basic.structureName, this.specPipeCrossSize);
      const data = result.data.find(v => v.rafterTotalLength === CommonUtil.round(rafterPart.lengthRafterIdeal));
      if (data?.rafterPipeSpecLength) {
        length = data.rafterPipeSpecLength;
      } else {
        length = rafterPart.lengthRafterIdeal - rafterPart.extensionLength * rafterPart.extensionPositionValue;
      }
    } else {
      length = rafterPart.lengthRafterIdeal;
    }

    if (rafterPart.rafterLengthRoundSelected === true) {
      length = Math.round(length);
    }
    this.specLength = length;
  }

  /**
   * 규격 알고리즘: 단위길이 <- 최종 서까래 길이(2중이상) <br/>
   * - 최종 서까래 길이로부터 단위길이 지정(2중이상) <br/>
   * - 2중이상은 최종 서까래 길이가 1중에 의해 바뀌기 때문에, 최종 서까래 길이에 의해 동작
   */
  public algorithmSpec_specLengthByTotalRafterLength(): void {
    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    let length: number;
    let result: any;
    if (this.level.index >= 1) {
      // 2중이상
      if (rafterPart.extensionSelected === true) {
        result = rafterPart.extractRafterExtensionData(this.basic.structureName, this.specPipeCrossSize);
        const data = result.data.find(v => v.rafterTotalLength === CommonUtil.round(rafterPart.lengthRafterIdeal));
        if (data?.rafterPipeSpecLength) {
          length = data.rafterPipeSpecLength;
        } else {
          length = rafterPart.lengthRafterIdeal - rafterPart.extensionLength * rafterPart.extensionPositionValue;
        }
      } else {
        length = rafterPart.rafterTotalLength;
      }

      if (rafterPart.rafterLengthRoundSelected === true) {
        length = Math.round(length);
      }
      this.specLength = length;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
