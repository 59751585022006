import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";
import { SkirtPart_FixingNormalPad } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingNormalPad";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";
import { SkirtPart_FixingLaggingPad } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingLaggingPad";
import { SkirtPart_FixingNormalClip } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingNormalClip";
import { SkirtPart_FixingSkirtClip } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingSkirtClip";
import { SkirtPart_FixingWood } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingWood";
import { SkirtPart_Normal } from "vhows-design/src/object/design/cover/skirt/SkirtPart_Normal";
import { SkirtPart_String } from "vhows-design/src/object/design/cover/skirt/SkirtPart_String";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-20
 */
@jsonObject({
  knownTypes: [
    SkirtPart_FixingLaggingPad,
    SkirtPart_FixingNormalClip,
    SkirtPart_FixingNormalPad,
    SkirtPart_FixingSkirtClip,
    SkirtPart_FixingWood,
    SkirtPart_Normal,
    SkirtPart_String,
  ],
})
export class SkirtPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public normalPart: SkirtPart_Normal;
  public stringPart: SkirtPart_String;
  public fixingNormalPadPart: SkirtPart_FixingNormalPad;
  public fixingNormalClipPart: SkirtPart_FixingNormalClip;
  public fixingLaggingPadPart: SkirtPart_FixingLaggingPad;
  public fixingWoodPart: SkirtPart_FixingWood;
  public fixingSkirtClipPart: SkirtPart_FixingSkirtClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _skirtHeight: number; // 치마 높이
  @jsonMember(String)
  public _fixingType: string; // 고정 형태

  /**
   * 치마 높이
   */
  public get skirtHeight(): number {
    return this._skirtHeight;
  }

  public set skirtHeight(value: number) {
    this._skirtHeight = value;

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    this.algorithm_skirtHeightByLevel();

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
    444;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_fixingTypeByFixing();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.normalPart = new SkirtPart_Normal();
    this.stringPart = new SkirtPart_String();
    this.fixingNormalPadPart = new SkirtPart_FixingNormalPad();
    this.fixingNormalClipPart = new SkirtPart_FixingNormalClip();
    this.fixingLaggingPadPart = new SkirtPart_FixingLaggingPad();
    this.fixingWoodPart = new SkirtPart_FixingWood();
    this.fixingSkirtClipPart = new SkirtPart_FixingSkirtClip();

    this.partAC = [
      this.normalPart,
      this.stringPart,
      this.fixingNormalPadPart,
      this.fixingNormalClipPart,
      this.fixingLaggingPadPart,
      this.fixingWoodPart,
      this.fixingSkirtClipPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.normalPart = <SkirtPart_Normal>this.partAC[0];
    this.stringPart = <SkirtPart_String>this.partAC[1];
    this.fixingNormalPadPart = <SkirtPart_FixingNormalPad>this.partAC[2];
    this.fixingNormalClipPart = <SkirtPart_FixingNormalClip>this.partAC[3];
    this.fixingLaggingPadPart = <SkirtPart_FixingLaggingPad>this.partAC[4];
    this.fixingWoodPart = <SkirtPart_FixingWood>this.partAC[5];
    this.fixingSkirtClipPart = <SkirtPart_FixingSkirtClip>this.partAC[6];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param skirtHeight: number 치마 높이
   * @param fixingType: string 고정 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    skirtHeight: number = 0,
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._skirtHeight = skirtHeight;
    this._fixingType = fixingType;

    if (this.level.index === 0) {
      // 일반 치마 피복
      if (index === 0 || index === 2 || index === 3) {
        this.normalPart.setDefaultData(CONST.INDEX_SG_SKIRT_NORMAL, true, false, true, CONST.LB_SKIRT_NORMAL, "");
      } else if (index === 1) {
        this.normalPart.setDefaultData(CONST.INDEX_SG_SKIRT_NORMAL, false, false, false, CONST.LB_SKIRT_NORMAL, "");
      }

      // 끈 치마 피복
      this.stringPart.setDefaultData(CONST.INDEX_SG_SKIRT_STRING, false, false, false, CONST.LB_SKIRT_STRING, "");

      // 치마 피복 고정 - 일반 패드 고정
      this.fixingNormalPadPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_SKIRT_FIXING_NORMAL_PAD,
        "",
      );

      // 치마 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );

      // 치마 피복 고정 - 광폭 패드 고정
      this.fixingLaggingPadPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_LAGGING_PAD,
        "",
      );

      // 치마 피복 고정 - 목재 고정
      this.fixingWoodPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_WOOD,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_WOOD,
        "",
      );

      // 치마 피복 고정 - 치마 클립 고정
      this.fixingSkirtClipPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_SKIRT_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_SKIRT_CLIP,
        "",
        1.2,
      );
    } else {
      // 일반 치마 피복
      if (index === 0 || index === 2 || index === 3) {
        this.normalPart.setDefaultData(CONST.INDEX_SG_SKIRT_NORMAL, true, false, true, CONST.LB_SKIRT_NORMAL, "");
      } else if (index === 1) {
        this.normalPart.setDefaultData(CONST.INDEX_SG_SKIRT_NORMAL, false, false, false, CONST.LB_SKIRT_NORMAL, "");
      }

      // 끈 치마 피복
      if (index === 0 || index === 2 || index === 3) {
        this.stringPart.setDefaultData(CONST.INDEX_SG_SKIRT_STRING, false, false, false, CONST.LB_SKIRT_STRING, "");
      } else if (index === 1) {
        this.stringPart.setDefaultData(CONST.INDEX_SG_SKIRT_STRING, true, false, true, CONST.LB_SKIRT_STRING, "");
      }

      // 치마 피복 고정 - 일반 패드 고정
      if (index === 0 || index === 2 || index === 3) {
        this.fixingNormalPadPart.setDefaultData(
          CONST.INDEX_SG_SKIRT_FIXING_NORMAL_PAD,
          true,
          false,
          true,
          CONST.LB_SKIRT_FIXING_NORMAL_PAD,
          "",
        );
      } else if (index === 1) {
        this.fixingNormalPadPart.setDefaultData(
          CONST.INDEX_SG_SKIRT_FIXING_NORMAL_PAD,
          false,
          false,
          false,
          CONST.LB_SKIRT_FIXING_NORMAL_PAD,
          "",
        );
      }

      // 치마 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );

      // 치마 피복 고정 - 광폭 패드 고정
      this.fixingLaggingPadPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_LAGGING_PAD,
        "",
      );

      // 치마 피복 고정 - 목재 고정
      this.fixingWoodPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_WOOD,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_WOOD,
        "",
      );

      // 치마 피복 고정 - 치마 클립 고정
      if (index === 0 || index === 2 || index === 3) {
        this.fixingSkirtClipPart.setDefaultData(
          CONST.INDEX_SG_SKIRT_FIXING_SKIRT_CLIP,
          false,
          false,
          false,
          CONST.LB_SKIRT_FIXING_SKIRT_CLIP,
          "",
          1.2,
        );
      } else if (index === 1) {
        this.fixingSkirtClipPart.setDefaultData(
          CONST.INDEX_SG_SKIRT_FIXING_SKIRT_CLIP,
          true,
          false,
          true,
          CONST.LB_SKIRT_FIXING_SKIRT_CLIP,
          "",
          1.2,
        );
      }
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 6) {
      // 치마 피복 고정 - 일반 클립 고정
      const fixingNormalClipPart: SkirtPart_FixingNormalClip = new SkirtPart_FixingNormalClip();
      fixingNormalClipPart.setAssociation(design, struct, work, level, this);
      fixingNormalClipPart.setDefaultData(
        CONST.INDEX_SG_SKIRT_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );
      this.partAC.splice(3, 0, fixingNormalClipPart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 상세 피복별 선택 여부
   * @param coverPosition 피복 위치 객체
   */
  public selectedDetail(coverPosition: CoverPosition): boolean {
    // 위치별로 치마가 선택되었다 하더라도, 피복별로 치마가 선택되지 않은 경우가 우선
    if (this.label === CONST.LB_POSITION_SIDE && coverPosition.skirtCoverSide === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_ROOF && coverPosition.skirtCoverRoof === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_FRONT && coverPosition.skirtCoverFront === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_BACK && coverPosition.skirtCoverBack === true) {
      return this.selected;
    }
    return false;
  }

  /**
   * 알고리즘: 선택 <- 선택(개폐기)
   */
  public algorithm_selectedBySwitcher(): void {
    const switcherPosition: SwitcherPosition = <SwitcherPosition>(
      this.struct.switcherWork.levelAC[this.level.index].positionAC[this.index]
    );

    this.selected = switcherPosition.selected;
  }

  /**
   * 알고리즘: 가용성 <- 선택(개폐기)
   */
  public algorithm_enabledBySwitcher(): void {
    const switcherPosition: SwitcherPosition = <SwitcherPosition>(
      this.struct.switcherWork.levelAC[this.level.index].positionAC[this.index]
    );

    this.enabled = switcherPosition.selected;
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_SKIRT_TYPE_NORMAL) {
      this.normalPart.selected = true;
      this.normalPart.visible = true;
      this.stringPart.selected = false;
      this.stringPart.visible = false;
    } else if (this.type === CONST.LB_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER) {
      this.normalPart.selected = false;
      this.normalPart.visible = false;
      this.stringPart.selected = false;
      this.stringPart.visible = false;
    } else if (this.type === CONST.LB_SKIRT_TYPE_STRING) {
      this.normalPart.selected = false;
      this.normalPart.visible = false;
      this.stringPart.selected = true;
      this.stringPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
      this.fixingNormalPadPart.selected = true;
      this.fixingNormalPadPart.visible = true;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = true;
      this.fixingNormalClipPart.visible = true;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = true;
      this.fixingLaggingPadPart.visible = true;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_WOOD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = true;
      this.fixingWoodPart.visible = true;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_SKIRT_CLIP) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = true;
      this.fixingSkirtClipPart.visible = true;
    } else {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태, 형태(피복 고정)
   */
  public algorithm_fixingTypeByFixing(): void {
    if (this.type === CONST.LB_SKIRT_TYPE_NORMAL || this.type === CONST.LB_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER) {
      const fixingPosition: FixingPosition = <FixingPosition>(
        this.struct.fixingWork.levelAC[this.level.index].positionAC[this.index]
      );

      this.fixingType = fixingPosition.type;
    } else if (this.type === CONST.LB_SKIRT_TYPE_STRING) {
      this.fixingType = CONST.LB_FIXING_TYPE_SKIRT_CLIP;
    }
  }

  /**
   * 알고리즘: 치마 높이 <- 형태, 개폐 방향(개폐기)
   */
  public algorithm_skirtHeightBySwitcher(): void {
    // const switcherLevel: SwitcherLevel = <SwitcherLevel>this.struct.switcherWork.levelAC[this.level.index];
    // if (this.label === CONST.LB_POSITION_SIDE) {
    //   if ((<SwitcherPosition>switcherLevel.positionAC[this.index]).switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
    //     // 완전개폐인 경우
    //     this.skirtHeight = 0.5;
    //   } else {
    //     this.skirtHeight = 0.5;
    //   }
    // } else if (this.label === CONST.LB_POSITION_ROOF) {
    //   this.skirtHeight = 0.3;
    // } else if (this.label === CONST.LB_POSITION_FRONT
    //   || this.label === CONST.LB_POSITION_BACK) {
    //   this.skirtHeight = 0.5;
    // }
  }

  /**
   * 알고리즘: 치마 높이 <- 치마 높이(중수)
   */
  public algorithm_skirtHeightByLevel(): void {
    const switcherLevelCurrent: SwitcherLevel = <SwitcherLevel>this.struct.switcherWork.levelAC[this.level.index];

    for (let l: number = this.level.index + 1; l < CONST.NUM_SINGLE_LEVEL_MAX; l++) {
      const switcherLevelN: SwitcherLevel = <SwitcherLevel>this.struct.switcherWork.levelAC[l];

      // 현재 중수와 반영할 중수의 개폐 방향이 같은 경우에만, 치마 높이를 반영함
      if (
        (<SwitcherPosition>switcherLevelCurrent.positionAC[this.index]).switchWay ===
        (<SwitcherPosition>switcherLevelN.positionAC[this.index]).switchWay
      ) {
        (<SkirtPosition>this.work.levelAC[l].positionAC[this.index]).skirtHeight = this.skirtHeight;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
