import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { Authority } from "vhows-design/src/object/user/Authority";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-04-15
 */
@jsonObject({
  knownTypes: [Authority],
})
export class Role extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 권한목록
  @jsonArrayMember(Authority)
  public authorities: Authority[] = null;
  // 역할명
  @jsonMember(String)
  public rolename: string = null;
  // 역할 설명
  @jsonMember(String)
  public description: string = null;
  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------
}
