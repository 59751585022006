import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { WindowLevel } from "vhows-design/src/object/design/other/window/WindowLevel";
import { WindowPart_Sash } from "vhows-design/src/object/design/other/window/WindowPart_Sash";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-06
 */
@jsonObject
export class WindowSample_SashFramePipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const sashPart: WindowPart_Sash = <WindowPart_Sash>this.part;
    sashPart.algorithmSpec_sashFrame_pipe();
    sashPart.algorithmSpec_sashFrameHeight_pipe();
    sashPart.algorithmSpec_sashFrame_tHolder();
    sashPart.algorithmSpec_sashFrame_tClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevel>this.level).rightPosition.sashPart.sashFramePipeSample.specs = this.specs;
      (<WindowLevel>this.level).frontPosition.sashPart.sashFramePipeSample.specs = this.specs;
      (<WindowLevel>this.level).backPosition.sashPart.sashFramePipeSample.specs = this.specs;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevel>this.level).backPosition.sashPart.sashFramePipeSample.specs = this.specs;
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 서까래 파이프 샘플(기초 골조), 설치 형태
   */
  // @override
  public algorithmSpec(): void {
    if ((<WindowPart_Sash>this.part).installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_STRAIGHT) {
      // 하드코딩
      this.specsList = "각형, 일반, 40×20mm, 1.4T";
    } else if ((<WindowPart_Sash>this.part).installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER) {
      const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
      this.specsList = trunkPosition.rafterPart.rafterPipeSample.specsList;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
