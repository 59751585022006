import UniqId from "uniqid";
import { ReactElement } from "react";

type Stepper = {
  /**
   * 현재 페이지 step
   */
  step: number;
  /**
   * 총 페이지 step
   */
  total: number;
};

export const Stepper = ({ step, total }: Stepper) => {
  const makeStepper = (remain: number, total: number, stepper: Array<ReactElement>): any => {
    if (!total) {
      return stepper;
    } else if (!remain) {
      return makeStepper(0, total - 1, [<div key={UniqId()} className="h-0.5 flex-1 bg-[#12B564]" />, ...stepper]);
    } else if (remain && total && stepper) {
      return makeStepper(remain - 1, total - 1, [
        <div key={UniqId()} className="h-0.5 flex-1 bg-[#F2F2F2]" />,
        ...stepper,
      ]);
    }
  };

  if (step > 0 && total > 0 && total - step >= 0) {
    return (
      <div id="stepper" className="flex w-full">
        {makeStepper(total - step, total, [])}
      </div>
    );
  } else {
    return null;
  }
};
