import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpiecePosition } from "vhows-design/src/object/design/frame/endpiece/EndpiecePosition";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { EndpiecePart_Gate } from "vhows-design/src/object/design/frame/endpiece/EndpiecePart_Gate";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-01
 */
@jsonObject
export class EndpieceSample_CSectionSteel extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const gatePart: EndpiecePart_Gate = <EndpiecePart_Gate>this.part;
    gatePart.algorithmSpec_gate_cSectionSteel();
    gatePart.algorithmSpec_gate_cSectionSteelRoller();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevel>this.level).backPosition.gatePart.cSectionSteelSample.specs = this.specs;
    }

    /// //////// 외부 ///////////

    // 칸막이
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        this.struct.partitionWork.level1.trunkPosition.gatePart.cSectionSteelSample.algorithmSpec();
      }
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 출입문 형태, 미닫이문 샘플, 문짝 폭, 문짝 개수
   */
  // @override
  public algorithmSpec(): void {
    const endpiecePosition: EndpiecePosition = <EndpiecePosition>this.position;
    const gatePart: EndpiecePart_Gate = <EndpiecePart_Gate>this.part;

    let doorWidth: number;
    if (gatePart.makingMethod === CONST.LB_GATE_MAKING_METHOD_MADE) {
      doorWidth = CommonUtil.convertMM2M(gatePart.slidingDoorSample.specWidth);
    } else {
      doorWidth = CommonUtil.convertMM2M(gatePart.doorWidth);
    }
    const minSlideWidth: number = doorWidth * 2 * gatePart.doorQuantity - 0.1 * gatePart.doorQuantity;
    // 하드코딩
    if (this.specCrossSize === CONST.ITEM_SPEC_VALUE_60_30_MM) {
      if (minSlideWidth <= 5) {
        this.specLength = 5.0;
      } else if (minSlideWidth <= 6) {
        this.specLength = 6.0;
      } else {
        this.specLength = 10.0;
      }
    } else {
      this.specLength = 10.0;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
