import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { Part } from "vhows-design/src/object/design/base/Part";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { FixingSample_NormalClip } from "vhows-design/src/object/design/cover/fixing/FixingSample_NormalClip";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-06-15
 */
@jsonObject({
  knownTypes: [FixingSample_NormalClip],
})
export class FixingPart_NormalClip extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalClipSample: FixingSample_NormalClip; // 일반 클립 샘플

  // 아이템
  public normalClip_normalClip: ItemClip;
  public normalClipMiddle_normalClip: ItemClip;
  public normalClipRafter_normalClip: ItemClip;
  public normalClipGate_normalClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _clipInterval: number; // 클립 간격

  /**
   * 클립 간격
   */
  public get clipInterval(): number {
    return this._clipInterval;
  }

  //
  public set clipInterval(value: number) {
    this._clipInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalClipSample = new FixingSample_NormalClip();

    this.sampleAC = [this.normalClipSample];

    // 아이템
    this.normalClip_normalClip = new ItemClip();
    this.normalClipMiddle_normalClip = new ItemClip();
    this.normalClipRafter_normalClip = new ItemClip();
    this.normalClipGate_normalClip = new ItemClip();

    this.itemAC = [
      this.normalClip_normalClip,
      this.normalClipMiddle_normalClip,
      this.normalClipRafter_normalClip,
      this.normalClipGate_normalClip,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalClipSample = <FixingSample_NormalClip>this.sampleAC[0];

    // 아이템
    this.normalClip_normalClip = <ItemClip>this.itemAC[0];
    this.normalClipMiddle_normalClip = <ItemClip>this.itemAC[1];
    this.normalClipRafter_normalClip = <ItemClip>this.itemAC[2];
    this.normalClipGate_normalClip = <ItemClip>this.itemAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param clipInterval: number 클립 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    clipInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._clipInterval = clipInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.normalClipSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalClip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
      this.normalClipMiddle_normalClip.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(중방방향)",
        "플라스틱, 25mm",
      );
      this.normalClipRafter_normalClip.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(서까래쪽)",
        "플라스틱, 25mm",
      );
      this.normalClipGate_normalClip.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(문주위)",
        "플라스틱, 25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const fixingPosition: FixingPosition = <FixingPosition>this.position;
    // 외부
    const trunkPart_Rafter: TrunkPart_Rafter = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index])
      .trunkPosition.rafterPart;
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];

    /// //////// 선택, 가시성 ///////////

    if (fixingPosition.label === CONST.LB_POSITION_SIDE || fixingPosition.label === CONST.LB_POSITION_ROOF) {
      this.normalClip_normalClip.selected = true;
      this.normalClip_normalClip.visible = true;
      this.normalClipMiddle_normalClip.selected = false;
      this.normalClipMiddle_normalClip.visible = false;
      this.normalClipRafter_normalClip.selected = false;
      this.normalClipRafter_normalClip.visible = false;
      this.normalClipGate_normalClip.selected = false;
      this.normalClipGate_normalClip.visible = false;
    } else if (fixingPosition.label === CONST.LB_POSITION_FRONT || fixingPosition.label === CONST.LB_POSITION_BACK) {
      this.normalClip_normalClip.selected = false;
      this.normalClip_normalClip.visible = false;
      // 중방방향
      if (fixingPosition.type === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipMiddle_normalClip.selected = true;
        this.normalClipMiddle_normalClip.visible = true;
      } else {
        this.normalClipMiddle_normalClip.selected = false;
        this.normalClipMiddle_normalClip.visible = false;
      }
      // 서까래쪽
      if (fixingPosition.endpieceRafterType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipRafter_normalClip.selected = true;
        this.normalClipRafter_normalClip.visible = true;
      } else {
        this.normalClipRafter_normalClip.selected = false;
        this.normalClipRafter_normalClip.visible = false;
      }
      // 문주위
      if (fixingPosition.endpieceGateType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipGate_normalClip.selected = true;
        this.normalClipGate_normalClip.visible = true;
      } else {
        this.normalClipGate_normalClip.selected = false;
        this.normalClipGate_normalClip.visible = false;
      }
    }

    /// //////// 수량 ///////////

    this.normalClip_normalClip.designQuantity =
      Math.ceil(this.basicLevel.length / this.clipInterval) * fixingPosition.lineNumber * this.basic.buildingNumber;

    this.normalClipMiddle_normalClip.designQuantity =
      Math.ceil(this.basicLevel.width / this.clipInterval) * fixingPosition.lineNumber * this.basic.buildingNumber;

    this.normalClipRafter_normalClip.designQuantity =
      Math.ceil(trunkPart_Rafter.rafterTotalLength / this.clipInterval) *
      fixingPosition.endpieceRafterLineNumber *
      this.basic.buildingNumber;

    this.normalClipGate_normalClip.designQuantity =
      Math.ceil(2.0 / this.clipInterval) * fixingPosition.endpieceGateLineNumber * this.basic.buildingNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 클립] 일반 클립 <- 일반 클립 샘플
   */
  public algorithmSpec_normalClip_normalClip(): void {
    this.normalClip_normalClip.specs = this.normalClipSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 클립(중방방향)] 일반 클립 <- 일반 클립 샘플
   */
  public algorithmSpec_normalClipMiddle_normalClip(): void {
    this.normalClip_normalClip.specs = this.normalClipSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 클립(서까래쪽)] 일반 클립 <- 일반 클립 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_normalClipRafter_normalClip(): void {
    const trunkLevel: TrunkLevel = <TrunkLevel>this.struct.trunkWork.levelAC[this.level.index];
    this.normalClipRafter_normalClip.specMaterial = this.normalClipSample.specMaterial;
    this.normalClipRafter_normalClip.specCrossSize = trunkLevel.trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [일반 클립(문주위)] 일반 클립 <- 일반 클립 샘플, 마구리 샛기둥 파이프 샘플
   */
  public algorithmSpec_normalClipGate_normalClip(): void {
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];
    this.normalClipGate_normalClip.specMaterial = this.normalClipSample.specMaterial;
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.normalClipGate_normalClip.specCrossSize = endpieceLevel.frontPosition.studPart.studPipeSample.specCrossSize;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.normalClipGate_normalClip.specCrossSize = endpieceLevel.backPosition.studPart.studPipeSample.specCrossSize;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
