import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemVentHole } from "vhows-design/src/object/design/item/list/ItemVentHole";
import { ItemVentHoleSwitchSet } from "vhows-design/src/object/design/item/list/ItemVentHoleSwitchSet";
import { Part } from "vhows-design/src/object/design/base/Part";
import { VentExhaustSample_RoofVentHole } from "vhows-design/src/object/design/vent/exhaust/VentExhaustSample_RoofVentHole";
import { VentExhaustSample_RoofVentHoleSwitchSet } from "vhows-design/src/object/design/vent/exhaust/VentExhaustSample_RoofVentHoleSwitchSet";
import { VentExhaustPosition } from "vhows-design/src/object/design/vent/exhaust/VentExhaustPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-13
 */
@jsonObject({
  knownTypes: [VentExhaustSample_RoofVentHole, VentExhaustSample_RoofVentHoleSwitchSet],
})
export class VentExhaustPart_RoofFanManual extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public ventHoleSample: VentExhaustSample_RoofVentHole; // 환기구 샘플
  public ventHoleSwitchSetSample: VentExhaustSample_RoofVentHoleSwitchSet; // 환기구 개폐 세트 샘플

  // 아이템
  public vent_ventHole: ItemVentHole;
  public vent_ventHoleSwitchSet: ItemVentHoleSwitchSet;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 환풍구 수량
   */
  public get ventQuantity(): number {
    if (this._ventBuildingNumber === 0) {
      return 0;
    }
    return CommonUtil.round(this._ventTotalQuantity / this._ventBuildingNumber, 1);
  }

  //
  public set ventQuantity(value: number) {}

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _ventBuildingNumber: number; // 환기 동수
  @jsonMember(Number)
  public _ventTotalQuantity: number = 0; // 환풍구 전체 수량
  @jsonMember(Number)
  public _ventInterval: number; // 환풍구 간격
  @jsonMember(Number)
  public _ventLineNumber: number; // 환풍구 줄수
  @jsonMember(Boolean)
  public _switchSetSelected: boolean; // 개폐 세트 선택

  /**
   * 환기 동수
   */
  public get ventBuildingNumber(): number {
    return this._ventBuildingNumber;
  }

  //
  public set ventBuildingNumber(value: number) {
    this._ventBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_ventTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 환풍구 전체 수량
   */
  public get ventTotalQuantity(): number {
    return this._ventTotalQuantity;
  }

  //
  public set ventTotalQuantity(value: number) {
    this._ventTotalQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 환풍구 간격
   */
  public get ventInterval(): number {
    return this._ventInterval;
  }

  //
  public set ventInterval(value: number) {
    this._ventInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_ventTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 환풍구 줄수
   */
  public get ventLineNumber(): number {
    return this._ventLineNumber;
  }

  //
  public set ventLineNumber(value: number) {
    this._ventLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_ventTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 개폐 세트 선택
   */
  public get switchSetSelected(): boolean {
    return this._switchSetSelected;
  }

  //
  public set switchSetSelected(value: boolean) {
    this._switchSetSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.ventHoleSample = new VentExhaustSample_RoofVentHole();
    this.ventHoleSwitchSetSample = new VentExhaustSample_RoofVentHoleSwitchSet();

    this.sampleAC = [this.ventHoleSample, this.ventHoleSwitchSetSample];

    // 아이템
    this.vent_ventHole = new ItemVentHole();
    this.vent_ventHoleSwitchSet = new ItemVentHoleSwitchSet();

    this.itemAC = [this.vent_ventHole, this.vent_ventHoleSwitchSet];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.ventHoleSample = <VentExhaustSample_RoofVentHole>this.sampleAC[0];
    this.ventHoleSwitchSetSample = <VentExhaustSample_RoofVentHoleSwitchSet>this.sampleAC[1];
    // 아이템
    this.vent_ventHole = <ItemVentHole>this.itemAC[0];
    this.vent_ventHoleSwitchSet = <ItemVentHoleSwitchSet>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param ventBuildingNumber: number 환기 동수
   * @param ventInterval: number 환풍구 간격
   * @param ventLineNumber: number 환풍구 줄수
   * @param ventFrontBackMargin: number 앞뒷면 여유 길이
   * @param switchSetSelected: boolean 개폐 세트 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    ventBuildingNumber: number = 0,
    ventInterval: number = 0,
    ventLineNumber: number = 0,
    switchSetSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._ventBuildingNumber = ventBuildingNumber;
    this._ventInterval = ventInterval;
    this._ventLineNumber = ventLineNumber;
    this._switchSetSelected = switchSetSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.ventHoleSample.setDefaultData(
        0,
        CONST.ITEM_ID_VENT_HOLE,
        CONST.ITEM_NAME_VENT_HOLE,
        "지붕 환기구",
        "일반",
        "대흥산업, 기본, 600mm, 수동",
      );
      this.ventHoleSwitchSetSample.setDefaultData(
        1,
        CONST.ITEM_ID_VENT_HOLE_SWITCH_SET,
        CONST.ITEM_NAME_VENT_HOLE_SWITCH_SET,
        "지붕 환기구",
        "일반",
        "대흥산업, 기본, 10개, 95m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.vent_ventHole.setDefaultData(
        0,
        CONST.ITEM_ID_VENT_HOLE,
        CONST.ITEM_NAME_VENT_HOLE,
        "지붕 환기구",
        "일반",
        "대흥산업, 기본, 600mm, 수동",
      );
      this.vent_ventHoleSwitchSet.setDefaultData(
        1,
        CONST.ITEM_ID_VENT_HOLE_SWITCH_SET,
        CONST.ITEM_NAME_VENT_HOLE_SWITCH_SET,
        "지붕 환기구",
        "일반",
        "대흥산업, 기본, 10개, 95m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_ventBuildingNumber();
    this.algorithm_ventTotalQuantity();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const mainpipePosition: VentExhaustPosition = <VentExhaustPosition>this.position;

    /// //////// 선택, 가시성 ///////////

    this.vent_ventHole.selected = true;
    this.vent_ventHole.visible = true;
    if (this.switchSetSelected === true) {
      this.vent_ventHoleSwitchSet.selected = true;
      this.vent_ventHoleSwitchSet.visible = true;
    } else {
      this.vent_ventHoleSwitchSet.selected = false;
      this.vent_ventHoleSwitchSet.visible = false;
    }

    /// ////////  수량 ///////////

    this.vent_ventHole.designQuantity = this.ventTotalQuantity;

    this.vent_ventHoleSwitchSet.designQuantity = this.ventBuildingNumber;
  }

  /**
   * 알고리즘: 환기 동수 <- 동수(기본정보)
   */
  public algorithm_ventBuildingNumber(): void {
    this.ventBuildingNumber = this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 환풍구 전체 수량 <- 길이(기본정보), (환풍구 간격), 환기 동수, 환풍구 줄수
   */
  public algorithm_ventTotalQuantity(): void {
    const ventQuantityPerBuilding: number = Math.floor(this.basicLevel.length / this.ventInterval);
    if (this.ventInterval === 0 || ventQuantityPerBuilding <= 1) {
      this.ventTotalQuantity = 1 * this.ventLineNumber * this.ventBuildingNumber;
    } else {
      this.ventTotalQuantity = ventQuantityPerBuilding * this.ventLineNumber * this.ventBuildingNumber;
    }
  }

  /**
   * 알고리즘: 개폐 세트 선택 <- 환기구 샘플
   */
  public algorithm_switchSetSelected(): void {
    if (this.ventHoleSample.specFeature === CONST.ITEM_SPEC_VALUE_NO_POWER) {
      this.switchSetSelected = false;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [지붕 환기구] 환기구 <- 환기구 샘플
   */
  public algorithmSpec_vent_ventHole(): void {
    this.vent_ventHole.specs = this.ventHoleSample.specs;
    this.vent_ventHole.brands = this.ventHoleSample.brands;
  }

  /**
   * 규격 알고리즘: [지붕 환기구] 환기구 개폐 세트 <- 환기구 개폐 세트 샘플
   */
  public algorithmSpec_vent_ventHoleSwitchSet(): void {
    this.vent_ventHoleSwitchSet.specs = this.ventHoleSwitchSetSample.specs;
    this.vent_ventHoleSwitchSet.brands = this.ventHoleSwitchSetSample.brands;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
