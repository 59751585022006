import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 지면 운반구
 * @author 이덕형
 * @copyright RUNean Inc.
 * @date 2018-08-30
 */
@jsonObject
export class ItemGroundCarrier extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, 크기], (롤러간격),[롤러크기]
  public _specFeature: string = undefined; // 특성
  public _specSize: string = undefined; // 크기
  public _specInterval: number = undefined; // 롤러간격
  public _specRollerSize: string = undefined; // 롤러크기

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 크기
   */
  public get specSize(): string {
    return this._specSize;
  }
  //
  public set specSize(value: string) {
    this._specSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 간격
   */
  public get specInterval(): number {
    return this._specInterval;
  }
  //
  public set specInterval(value: number) {
    // 일반화된 간격 적용 - 0.05m 간격(0.05배수로 올림)
    this._specInterval = CommonUtil.roundUpX(value, 0.05);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 간격 레이블(값+단위기호)
   */
  public get specIntervalLabel(): string {
    return this.specInterval + CONST.UNIT_CENTIMETER_INTERVAL;
  }
  //
  public set specIntervalLabel(value: string) {
    this.specInterval = parseFloat(value);
  }

  /**
   * 롤러크기
   */
  public get specRollerSize(): string {
    return this._specRollerSize;
  }
  //
  public set specRollerSize(value: string) {
    this._specRollerSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.groundCarrierCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specSize = specsArray[1];
    this.specIntervalLabel = specsArray[2];
    this.specRollerSize = specsArray[3];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specSize = specsArray[1];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature + CONST.DELIMITER_SPEC + this._specSize;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList + CONST.DELIMITER_SPEC + this.specIntervalLabel + CONST.DELIMITER_SPEC + this.specRollerSize;
  }
}
