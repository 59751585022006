import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { Part } from "vhows-design/src/object/design/base/Part";

/**
 * 비닐
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-04
 */
@jsonObject
export class ItemVinyl extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [두께], (폭), (길이), [폭형태], [포장형태], [끈형태]
  public _specThickness: string = undefined; // 두께
  public _specWidth: number = undefined; // 폭
  public _specLength: number = undefined; // 길이
  public _specWidthType: string = undefined; // 폭형태
  public _specPackingType: string = undefined; // 포장형태
  public _specStringType: string = undefined; // 끈형태

  // 하우스 설계 > 상표 : [회사, 모델], {비중}
  public _brandModelName: string = undefined; // 모델
  public _brandSpecificGravity: number = undefined; // 비중

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 두께
   */
  public get specThickness(): string {
    return this._specThickness;
  }
  //
  public set specThickness(value: string) {
    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭
   */
  public get specWidth(): number {
    return this._specWidth;
  }
  //
  public set specWidth(value: number) {
    // 일반화된 간격 적용 - 5cm 간격(5배수로 올림)
    this._specWidth = CommonUtil.roundUpX(value, 5.0);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.algorithm_widthByBrand();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭 레이블(값+단위기호)
   */
  public get specWidthLabel(): string {
    return this.specWidth + CONST.UNIT_CENTIMETER;
  }
  //
  public set specWidthLabel(value: string) {
    this.specWidth = parseFloat(value);
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 1m 간격(소수점 첫째자리 올림)
    this._specLength = Math.ceil(value);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_METER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  /**
   * 폭형태
   */
  public get specWidthType(): string {
    return this._specWidthType;
  }
  //
  public set specWidthType(value: string) {
    this._specWidthType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.algorithm_widthByWidthType();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭형태값
   */
  public get specWidthTypeValue(): number {
    let widthTypeValue: number = 1;

    if (this._specWidthType === CONST.ITEM_SPEC_VALUE_SPREAD) {
      widthTypeValue = 1;
    } else if (this._specWidthType === CONST.ITEM_SPEC_VALUE_ONE_BURST) {
      widthTypeValue = 2;
    } else if (this._specWidthType === CONST.ITEM_SPEC_VALUE_BOTH_BURST) {
      widthTypeValue = 2;
    }

    return widthTypeValue;
  }

  /**
   * 포장형태
   */
  public get specPackingType(): string {
    return this._specPackingType;
  }
  //
  public set specPackingType(value: string) {
    this._specPackingType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 끈형태
   */
  public get specStringType(): string {
    return this._specStringType;
  }
  //
  public set specStringType(value: string) {
    this._specStringType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findStringTypeUnitPriceModel();
    }
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  public get brandsList(): string {
    return this._brandsList;
  }
  //
  public set brandsList(value: string) {
    if (this._brandsList === value) return;

    this._brandsList = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeEachBrandByBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findUnitPriceModel();
      this.findStringTypeUnitPriceModel();
      // 알고리즘

      // 상표 비중 찾기
      if (this.brandCompanyModel != null) {
        this.brandSpecificGravity = this.brandCompanyModel.brandModel.num1;
      }
    }
  }

  /**
   * 모델
   */
  public get brandModelName(): string {
    return this._brandModelName;
  }
  //
  public set brandModelName(value: string) {
    this._brandModelName = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.algorithm_widthByBrand();
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 비중
   */
  public get brandSpecificGravity(): number {
    return this._brandSpecificGravity;
  }
  //
  public set brandSpecificGravity(value: number) {
    this._brandSpecificGravity = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 비중 레이블(값+단위기호)
   */
  public get brandSpecificGravityLabel(): string {
    return `${this.brandSpecificGravity}`;
  }
  //
  public set brandSpecificGravityLabel(value: string) {
    this.brandSpecificGravity = parseFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setDefaultVariable(): void {
    super.setDefaultVariable();

    // 모델 찾기
    this.findStringTypeUnitPriceModel();
  }

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.vinylCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 샘플로 새로운 설계 아이템 만들기
   * @param part 아이템이 사용될 파트
   * @param sample 규격과 상표를 참고할 샘플
   * @return 새 아이템
   */
  public static getNewDesignItemBySample(part: Part, sample: ItemVinyl): ItemVinyl {
    let item: ItemVinyl;
    item = new ItemVinyl();
    item.setAssociation(part.design, part.struct, part.work, part.level, part.position, part);
    item.setDefaultData(NaN, sample.productId, sample.label, sample.purpose, sample.specs, sample.brands);
    item.setReferenceVariable();
    item.setDefaultVariable();
    item.setDefaultModel();
    item.algorithmItem();

    item.selected = true;
    item.visible = true;

    return item;
  }

  /**
   * 폭형태가 '타'인 폭 가져오기 <br/>
   * 설계 설정을 고려하여 10cm 또는 5cm 간격을 계산함
   * @param unfoldWidth 편 폭값
   */
  public getFoldedWidth(unfoldWidth: number): number {
    let foldWidth: number;
    foldWidth = CommonUtil.convertUnfold2Fold(unfoldWidth);

    if (this.design.preference.multiplyVinylWidthDecupleFlag === true) {
      // 설계 설정에 따라 10cm 간격(10배수로 올림)
      foldWidth = CommonUtil.roundUpX(foldWidth, 10.0);
    } else if (
      this.specPackingType === CONST.ITEM_SPEC_VALUE_BOX &&
      this.specWidthType === CONST.ITEM_SPEC_VALUE_SPREAD
    ) {
      // 포장형태='박스' && 폭형태='편'인 경우, 10cm 간격(10배수로 올림)
      foldWidth = CommonUtil.roundUpX(foldWidth, 10.0);
    } else {
      // 그 외 경우, 5cm 간격(5배수로 올림)
      foldWidth = CommonUtil.roundUpX(foldWidth, 5.0);
    }

    return foldWidth;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 견적 무게 계산식
    let estimateWeight: number =
      parseFloat(this.specThickness) *
      CommonUtil.convertCM2M(this.specWidth * this.specWidthTypeValue) *
      this.specLength *
      this.brandSpecificGravity;
    // 1보다 큰 경우, 반올림해줌
    if (estimateWeight > 1) {
      estimateWeight = CommonUtil.round(estimateWeight, 2);
    }

    // 끈형태 단가
    const stringTypeUnitSupply: number = this.stringTypeUnitPriceModel.optionUnitSupply;
    // 끈형태 매출가 계산식
    const stringTypeSellingSupply: number = stringTypeUnitSupply * this.specLength;

    // 매출가 계산식
    let sellingSupply: number = Math.ceil(defaultUnitSupply * estimateWeight) + stringTypeSellingSupply;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    // 나눠 들어가는 순서도 중요함;;
    this.specThickness = specsArray[0];
    this.specPackingType = specsArray[4];
    this.specStringType = specsArray[5];
    this.specWidthType = specsArray[3];
    this.specWidthLabel = specsArray[1];
    this.specLengthLabel = specsArray[2];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specThickness = specsArray[0];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specThickness;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList +
      CONST.DELIMITER_SPEC +
      this.specWidthLabel +
      CONST.DELIMITER_SPEC +
      this.specLengthLabel +
      CONST.DELIMITER_SPEC +
      this._specWidthType +
      CONST.DELIMITER_SPEC +
      this._specPackingType +
      CONST.DELIMITER_SPEC +
      this._specStringType;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    const brandsArray: string[] = this._brands.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandSpecificGravityLabel = brandsArray[2];
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    const brandsArray: string[] = this._brandsList.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList = this._brandCompany + CONST.DELIMITER_BRAND + this._brandModelName;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList + CONST.DELIMITER_BRAND + this.brandSpecificGravityLabel;
  }

  //----------------------------------
  // 하우스 설계 > 특정 규격 및 상표
  //----------------------------------

  /**
   * 알고리즘: 폭 <- 폭형태
   */
  protected algorithm_widthByWidthType(): void {
    if (this._specWidthType === CONST.ITEM_SPEC_VALUE_SPREAD) {
      this.specWidth *= 2;
    } else if (this._specWidthType === CONST.ITEM_SPEC_VALUE_ONE_BURST) {
      let widthTemp: number = this.specWidth / 2;
      // 폭이 20cm보다 작게 나올수 있기때문에 최소값인 20cm로 맞춰줌
      if (widthTemp < 20) {
        widthTemp = 20;
      }
      this.specWidth = widthTemp;
    } else if (this._specWidthType === CONST.ITEM_SPEC_VALUE_BOTH_BURST) {
      // 아직 설계상에 존재하지 않음
      // 수량을 절반으로 줄여야 하는데, 추후에 적용할 예정..
    }
  }

  /**
   * 알고리즘: 폭 <- 폭, 상표
   */
  protected algorithm_widthByBrand(): void {
    // 하드코딩
    if (this.brandModelName.substr(0, 4) === "솔라가드") {
      this.isSpecError = false;
      let width: number = this.specWidth;
      if (this._specWidthType === CONST.ITEM_SPEC_VALUE_SPREAD) {
        width /= 2;
      }
      // 솔라가드의 폭 범위
      if (width <= 400) width = 400;
      else if (width <= 450) width = 450;
      else if (width <= 500) width = 500;
      else if (width <= 550) width = 550;
      else if (width <= 650) width = 650;
      else if (width <= 750) width = 750;
      else if (width <= 800) width = 800;
      else {
        // 오류 출력
        this.isSpecError = true;
      }
      if (this._specWidthType === CONST.ITEM_SPEC_VALUE_SPREAD) {
        width *= 2;
      }
      this.specWidth = width;
    }
  }

  //----------------------------------
  // 품목 모델
  //----------------------------------

  /**
   * 끈형태 단가 모델 찾기
   */
  protected findStringTypeUnitPriceModel(): void {
    if (this.productCompanyModel != null) {
      this.stringTypeUnitPriceModel = this.productCompanyModel.getOptionUnitPriceModel(
        this.specStringType,
        this.brandsList,
      );
    }
  }
}
