import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Level } from "vhows-design/src/object/design/base/Level";
import { WindbreakPositionIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [WindbreakPositionIL],
})
export class WindbreakLevelIL extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public columnPosition: WindbreakPositionIL;
  public wingPosition: WindbreakPositionIL;
  public roofPosition: WindbreakPositionIL;
  public frontPosition: WindbreakPositionIL;
  public backPosition: WindbreakPositionIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.columnPosition = new WindbreakPositionIL();
    this.wingPosition = new WindbreakPositionIL();
    this.roofPosition = new WindbreakPositionIL();
    this.frontPosition = new WindbreakPositionIL();
    this.backPosition = new WindbreakPositionIL();

    this.positionAC = [
      this.columnPosition,
      this.wingPosition,
      this.roofPosition,
      this.frontPosition,
      this.backPosition,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.columnPosition = <WindbreakPositionIL>this.positionAC[0];
    this.wingPosition = <WindbreakPositionIL>this.positionAC[1];
    this.roofPosition = <WindbreakPositionIL>this.positionAC[2];
    this.frontPosition = <WindbreakPositionIL>this.positionAC[3];
    this.backPosition = <WindbreakPositionIL>this.positionAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index === 0) {
      this.columnPosition.setDefaultData(
        0,
        true,
        true,
        true,
        CONST.LB_POSITION_SIDE_COLUMN,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.wingPosition.setDefaultData(
        1,
        true,
        true,
        true,
        CONST.LB_POSITION_SIDE_WING,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.roofPosition.setDefaultData(
        2,
        true,
        true,
        true,
        CONST.LB_POSITION_ROOF,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.frontPosition.setDefaultData(
        3,
        false,
        false,
        true,
        CONST.LB_POSITION_FRONT,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.backPosition.setDefaultData(
        4,
        false,
        false,
        true,
        CONST.LB_POSITION_BACK,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
    } else if (index === 1 || index === 2) {
      this.columnPosition.setDefaultData(
        0,
        false,
        false,
        true,
        CONST.LB_POSITION_SIDE_COLUMN,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.wingPosition.setDefaultData(
        1,
        true,
        true,
        true,
        CONST.LB_POSITION_SIDE_WING,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.roofPosition.setDefaultData(
        2,
        true,
        true,
        true,
        CONST.LB_POSITION_ROOF,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.frontPosition.setDefaultData(
        3,
        false,
        false,
        true,
        CONST.LB_POSITION_FRONT,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.backPosition.setDefaultData(
        4,
        false,
        false,
        true,
        CONST.LB_POSITION_BACK,
        CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
