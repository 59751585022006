import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { MaterialPart_Custom } from "vhows-design/src/object/design/other/material/MaterialPart_Custom";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";
import { SkyFramePart_Rafter } from "vhows-design/src/object/design/skylight/frame/SkyFramePart_Rafter";
import { SkyFramePart_Straight } from "vhows-design/src/object/design/skylight/frame/SkyFramePart_Straight";
import { SkyFramePart_Enhanced } from "vhows-design/src/object/design/skylight/frame/SkyFramePart_Enhanced";
import { SkyFramePart_Rackpinion } from "vhows-design/src/object/design/skylight/frame/SkyFramePart_Rackpinion";
import { SkyCoverPosition } from "vhows-design/src/object/design/skylight/cover/SkyCoverPosition";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";

/** R
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-18
 */
@jsonObject({
  knownTypes: [
    SkyFramePart_Enhanced,
    SkyFramePart_Rafter,
    SkyFramePart_Straight,
    SkyFramePart_Rackpinion,
    MaterialPart_Custom,
  ],
})
export class SkyFramePosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public rafterPart: SkyFramePart_Rafter;
  public straightPart: SkyFramePart_Straight;
  public enhancedPart: SkyFramePart_Enhanced;
  public rackpinionPart: SkyFramePart_Rackpinion;
  public customPart: MaterialPart_Custom;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _enhancedType: string; // 보강 형태
  @jsonMember(Number)
  public _skylightLevel: number; // 설치 중수
  @jsonMember(Number)
  public _arcWidth: number; // 곡선 폭
  @jsonMember(Number)
  public _skylightLength: number = 0; // 천창 길이

  /**
   * 보강 형태
   */
  public get enhancedType(): string {
    return this._enhancedType;
  }

  //
  public set enhancedType(value: string) {
    this._enhancedType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByEnhancedType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 설치 중수
   */
  public get skylightLevel(): number {
    return this._skylightLevel;
  }

  //
  public set skylightLevel(value: number) {
    this._skylightLevel = value;

    // 알고리즘

    // 파트 활성

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 천창 피복
    this.struct.skyCoverWork.level1.skylightPosition.vinylPart.init_coverLevel();
    this.struct.skyCoverWork.level1.skylightPosition.vinylPart.algorithmPart();

    // 개폐기
    (
      this.struct.switcherWork.levelAC[this._skylightLevel - 1] as SwitcherLevel
    ).roofPosition.algorithm_selectedRoofBySkylight();

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    for (const coverPosition of this.struct.coverWork.levelAC[this._skylightLevel - 1].positionAC as CoverPosition[]) {
      coverPosition.algorithm_scopeTypeBySkylight();
    }
    (<CoverLevel>this.struct.coverWork.levelAC[this._skylightLevel - 1]).algorithmBasic();
  }

  /**
   * 곡선 폭
   */
  public get arcWidth(): number {
    return this._arcWidth;
  }

  //
  public set arcWidth(value: number) {
    this._arcWidth = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 천창 피복
    (<SkyCoverPosition>this.struct.skyCoverWork.level1.positionAC[this.index]).vinylPart.algorithmBasic();

    // 밴드끈
    this.struct.bandstringWork.level1.trunkPosition.bandstringPart.algorithm_bandstringLength();
    this.struct.bandstringWork.level1.trunkPosition.normalPart.algorithm_bandstringLength();
    this.struct.bandstringWork.level1.trunkPosition.polyPart.algorithm_bandstringLength();

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this._skylightLevel - 1]).algorithmBasic();
  }

  /**
   * 천창 길이
   */
  public get skylightLength(): number {
    return this._skylightLength;
  }

  //
  public set skylightLength(value: number) {
    this._skylightLength = value;

    // 알고리즘
    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 천창 피복
    (<SkyCoverPosition>this.struct.skyCoverWork.level1.positionAC[this.index]).vinylPart.algorithmBasic();
    (<SkyCoverPosition>this.struct.skyCoverWork.level1.positionAC[this.index]).screennetPart.algorithmBasic();
  }

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByEnhancedType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 천창 개폐
    this.struct.skySwitchWork.level1.skylightPosition.algorithm_selected();

    // 천창 피복
    this.struct.skyCoverWork.level1.skylightPosition.algorithm_selected();

    // 개폐기
    (
      this.struct.switcherWork.levelAC[this._skylightLevel - 1] as SwitcherLevel
    ).roofPosition.algorithm_selectedRoofBySkylight();

    // 밴드끈
    this.struct.bandstringWork.level1.trunkPosition.algorithm_typeBySkylight();
    this.struct.bandstringWork.level1.trunkPosition.bandstringPart.algorithm_bandstringLength();
    this.struct.bandstringWork.level1.trunkPosition.normalPart.algorithm_bandstringLength();
    this.struct.bandstringWork.level1.trunkPosition.polyPart.algorithm_bandstringLength();

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    for (const coverPosition of this.struct.coverWork.levelAC[this._skylightLevel - 1].positionAC as CoverPosition[]) {
      coverPosition.algorithm_scopeTypeBySkylight();
    }
    (<CoverLevel>this.struct.coverWork.levelAC[this._skylightLevel - 1]).algorithmBasic();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_enhancedType();
    this.algorithm_arcWidth();
    this.rafterPart.skyRafterPipeSample.algorithmSpec();
    this.rafterPart.algorithm_rafterLengthAndSkyColumnLength();
    this.straightPart.algorithm_straightLineNumber();
    this.straightPart.skyStraightPipeSample.algorithmSpec();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 천창 개폐
    this.struct.skySwitchWork.level1.skylightPosition.algorithm_selected();
    this.struct.skySwitchWork.level1.skylightPosition.algorithm_switchType();
    this.struct.skySwitchWork.level1.skylightPosition.algorithm_switchWay();
    this.struct.skySwitchWork.level1.skylightPosition.algorithm_switchHeight();

    // 천창 피복
    this.struct.skyCoverWork.level1.skylightPosition.algorithm_selected();
    this.struct.skyCoverWork.level1.skylightPosition.vinylPart.algorithmPart();
    this.struct.skyCoverWork.level1.skylightPosition.algorithm_lineNumber();
    this.struct.skyCoverWork.level1.skylightPosition.algorithm_screennetSelected();

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    for (const coverPosition of this.struct.coverWork.levelAC[this._skylightLevel - 1].positionAC as CoverPosition[]) {
      coverPosition.algorithm_scopeTypeBySkylight();
    }
    (<CoverLevel>this.struct.coverWork.levelAC[this._skylightLevel - 1]).algorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.rafterPart = new SkyFramePart_Rafter();
    this.straightPart = new SkyFramePart_Straight();
    this.enhancedPart = new SkyFramePart_Enhanced();
    this.rackpinionPart = new SkyFramePart_Rackpinion();
    this.customPart = new MaterialPart_Custom();

    this.partAC = [this.rafterPart, this.straightPart, this.enhancedPart, this.rackpinionPart, this.customPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.rafterPart = <SkyFramePart_Rafter>this.partAC[0];
    this.straightPart = <SkyFramePart_Straight>this.partAC[1];
    this.enhancedPart = <SkyFramePart_Enhanced>this.partAC[2];
    this.rackpinionPart = <SkyFramePart_Rackpinion>this.partAC[3];
    this.customPart = <MaterialPart_Custom>this.partAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param enhancedType: string 보강 형태
   * @param skylightLevel: number 설치 중수
   * @param arcWidth: number 곡선 폭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    enhancedType: string = "",
    skylightLevel: number = 0,
    arcWidth: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._enhancedType = enhancedType;
    this._arcWidth = arcWidth;
    this._skylightLevel = skylightLevel;

    if (this.level.index >= 0) {
      // 천창 서까래
      this.rafterPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_FRAME_RAFTER,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_FRAME_RAFTER,
        "",
        0.6,
        3.3,
        0.5,
        0,
      );

      // 천창 가로대
      this.straightPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_FRAME_STRAIGHT,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_FRAME_STRAIGHT,
        "",
        3,
      );

      // 골조 보강
      this.enhancedPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_FRAME_ENHANCED,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_FRAME_ENHANCED,
        "",
      );

      // 랙피니언 골조
      this.rackpinionPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_FRAME_RACKPINION,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_FRAME_RACKPINION,
        "",
        1,
        0.6,
      );

      // 임의 품목
      this.customPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_FRAME_CUSTOM,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_FRAME_CUSTOM,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 2) {
      // 골조 보강
      const enhancedPart: SkyFramePart_Enhanced = new SkyFramePart_Enhanced();
      enhancedPart.setAssociation(design, struct, work, level, this);
      enhancedPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_FRAME_ENHANCED,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_FRAME_ENHANCED,
        "",
      );
      this.partAC.push(enhancedPart);
      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
    }
    if (this.partAC.length === 3) {
      // 임의 품목
      const customPart: MaterialPart_Custom = new MaterialPart_Custom();
      customPart.setAssociation(design, struct, work, level, this);
      customPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_FRAME_CUSTOM,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_FRAME_CUSTOM,
        "",
      );
      this.partAC.push(customPart);
      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
    }
    if (this.partAC.length === 4 && (<Part>this.partAC[3]).label === CONST.LB_SKYLIGHT_FRAME_CUSTOM) {
      // 랙피니언
      const rackpinionPart: SkyFramePart_Rackpinion = new SkyFramePart_Rackpinion();
      rackpinionPart.setAssociation(design, struct, work, level, this);
      rackpinionPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_FRAME_RACKPINION,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_FRAME_RACKPINION,
        "",
        1,
        0.6,
      );
      this.partAC.splice(3, 0, rackpinionPart);
      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_skylightLength();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 선택, 형태
   */

  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF ||
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
    ) {
      this.rafterPart.selected = true;
      this.rafterPart.visible = true;
      this.straightPart.selected = true;
      this.straightPart.visible = true;
      this.rackpinionPart.selected = false;
      this.rackpinionPart.visible = false;
      this.customPart.selected = false;
      this.customPart.visible = false;
    } else if (
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.rafterPart.selected = false;
      this.rafterPart.visible = false;
      this.straightPart.selected = false;
      this.straightPart.visible = false;
      this.rackpinionPart.selected = true;
      this.rackpinionPart.visible = true;
      this.customPart.selected = false;
      this.customPart.visible = false;
    } else if (this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_CUSTOM) {
      this.rafterPart.selected = false;
      this.rafterPart.visible = false;
      this.straightPart.selected = false;
      this.straightPart.visible = false;
      this.rackpinionPart.selected = false;
      this.rackpinionPart.visible = false;
      this.customPart.selected = true;
      this.customPart.visible = true;
    } else {
      this.rafterPart.selected = false;
      this.rafterPart.visible = false;
      this.straightPart.selected = false;
      this.straightPart.visible = false;
      this.rackpinionPart.selected = false;
      this.rackpinionPart.visible = false;
      this.customPart.selected = false;
      this.customPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 선택, 보강 형태
   */
  public algorithm_partActivationByEnhancedType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////
    if (
      this.enhancedType === CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE ||
      this.enhancedType === CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_CONDENSATION_PAD ||
      this.enhancedType === CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_NORMAL_PAD
    ) {
      this.enhancedPart.selected = true;
      this.enhancedPart.visible = true;
    } else {
      this.enhancedPart.selected = false;
      this.enhancedPart.visible = false;
    }
  }

  /**
   * 알고리즘: 보강 형태 <- 형태
   */
  public algorithm_enhancedType(): void {
    if (this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH) {
      this.enhancedType = CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE;
    } else if (this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF || this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE) {
      this.enhancedType = CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_CONDENSATION_PAD;
    } else if (
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_CUSTOM ||
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.enhancedType = CONST.LB_SKYLIGHT_FRAME_ENHANCED_TYPE_NONE;
    }
  }

  /**
   * 알고리즘: 곡선 폭 <- 형태, 개폐 방향(천창 개폐)
   */
  public algorithm_arcWidth(): void {
    if (
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE ||
      this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF
    ) {
      this.arcWidth = 2.0;
    } else if (this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE) {
      this.arcWidth = 1.0;
    } else if (this.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH) {
      this.arcWidth = 2.0;
    }
  }

  /**
   * 알고리즘: 천창 길이 <- 하우스 길이(기본 정보)
   */
  public algorithm_skylightLength(): void {
    this.skylightLength = this.basicLevel.length;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
