import { jsonObject } from "typedjson";

import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { VentExhaustPosition } from "vhows-design/src/object/design/vent/exhaust/VentExhaustPosition";
import { VentInsidePosition } from "vhows-design/src/object/design/vent/inside/VentInsidePosition";
import { ControllerPartIL_Controller } from "vhows-design/src/object/design/switches/controller/il/ControllerPartIL_Controller";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-19
 */
@jsonObject
export class ControllerSampleIL_VentElectricWire extends ItemElectricWire {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const controllerPart: ControllerPartIL_Controller = <ControllerPartIL_Controller>this.part;

    // 파트 알고리즘 호출
    //			part.algorithmPart();

    /// //////// 외부 ///////////

    // 외부 환기
    const ventExhaustPosition: VentExhaustPosition = this.struct.ventExhaustWork.level1.wholePosition;
    ventExhaustPosition.endpieceFanPart.algorithmSpec_vent_electricWire();
    ventExhaustPosition.roofFanPowerPart.algorithmSpec_vent_electricWire();

    // 내부 환기
    const ventInsidePosition: VentInsidePosition = this.struct.ventInsideWork.level1.wholePosition;
    ventInsidePosition.airFlowFanPart.algorithmSpec_vent_electricWire();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
