import { jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { Item } from "vhows-design/src/object/design/item/Item";
import { UnitPriceModel } from "vhows-design/src/object/item/unitprice/UnitPriceModel";
import { Estimate } from "vhows-design/src/object/design/estimate/Estimate";
import { Design } from "vhows-design/src/object/design/Design";
import { DesignConst } from "vhows-design/src/common/constant/DesignConst";
import { ItemUtil } from "vhows-design/src/object/design/item/ItemUtil";
import { Material } from "vhows-design/src/object/design/material/Material";
import { EstimateItem } from "vhows-design/src/object/design/estimate/EstimateItem";

@jsonObject({
  knownTypes: [],
})
export class MaterialItem extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design = null;

  /** 자재 객체 */
  public material: Material = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 상품

  /** 상품 아이디 */
  public productId: string = null;
  /** 상품명 */
  public productName: string = null;
  /** 규격 */
  public specs: string = null;
  /** 상표 (설계 품목의 상표 리스트와 동일) */
  public brands: string = null;
  /** 용도 */
  public purpose: string = null;

  // 분류

  /** 분류1 */
  public category1: string = null;
  /** 분류2 */
  public category2: string = null;
  /** 분류3 */
  public category3: string = null;
  /** 분류 순서 */
  public categoryOrder: number = null;
  /** 규격 형태 */
  public specsType: string = null;
  /** 상표 형태 */
  public brandsType: string = null;

  // 수량

  /** 세트 단위수량 */
  public setUnitQuantity: number = 1;
  /** 설계 수량 */
  public designQuantity: number = 0;
  /** 미가공 견적 수량 */
  public estimateQuantityRaw: number = 0;
  /** 견적 수량 */
  public estimateQuantity: number = 0;
  /** 단위기호 */
  public unitSymbol: string = null;

  //--------------------------------------------------------------------------
  //
  // View Variables
  //
  //--------------------------------------------------------------------------

  /** designItemAC의 설계 정보가 변경되었는지 여부
   * - 변경된 경우에만, 적용을 눌렀을때 designItemAC에 임시로 저장된 값을 원본에 반영한다.
   */
  public designItemsChanged: boolean = false;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor(material: Material = null, design: Design = null) {
    super();
    this.material = material;
    this.design = design;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design, material: Material): void {
    this.design = design;
    this.material = material;
  }

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * @param origin 원본 객체
   */
  public deepCopy(origin: MaterialItem): void {
    // Variables

    this.design = origin.design; // 깊은 복사x
    this.material = origin.material; // 깊은 복사x

    // 하우스 품목 > 상품
    this.productId = origin.productId;
    this.productName = origin.productName;
    this.specs = origin.specs;
    this.brands = origin.brands;
    this.purpose = origin.purpose;
    // 하우스 품목 > 분류
    this.category1 = origin.category1;
    this.category2 = origin.category2;
    this.category3 = origin.category3;
    this.categoryOrder = origin.categoryOrder;
    this.specsType = origin.specsType;
    this.brandsType = origin.brandsType;
    this.unitSymbol = origin.unitSymbol;
    // 하우스 품목 > 수량
    this.setUnitQuantity = origin.setUnitQuantity;
    this.designQuantity = origin.designQuantity;
    this.estimateQuantityRaw = origin.estimateQuantityRaw;
    this.estimateQuantity = origin.estimateQuantity;
  }

  //----------------------------------
  // 하우스 자재 - 주요 함수
  //----------------------------------

  /**
   * 견적품목을 자재품목으로 변환하기
   * @param estimateItem 설계상품
   */
  public convertEstimateItemToMaterialItem(estimateItem: EstimateItem): void {
    // 상품
    this.productId = estimateItem.productId;
    this.productName = estimateItem.productName;
    this.specs = estimateItem.specs;
    this.brands = estimateItem.brands; // 비닐의 '비중'과 같은 상표를 보여주지 않기 위해 상표 대신 '상표 리스트'를 사용함
    this.purpose = estimateItem.purpose;
    // 분류
    this.category1 = estimateItem.category1;
    this.category2 = estimateItem.category2;
    this.category3 = estimateItem.category3;
    // this.categoryOrder = designItem.work.order;
    this.categoryOrder = estimateItem.categoryOrder;
    this.specsType = estimateItem.specsType;
    this.brandsType = estimateItem.brandsType;
    this.unitSymbol = estimateItem.unitSymbol;
    // 수량

    this.setUnitQuantity = estimateItem.setUnitQuantity;

    this.designQuantity = estimateItem.designQuantity;
    this.estimateQuantityRaw = estimateItem.designQuantity;
  }

  /**
   * 자재 수량 계산하기
   * - 세트 단위수량 적용
   */
  public calculateEstimateQuantity(): void {
    if (this.setUnitQuantity > 0) {
      this.estimateQuantity = CommonUtil.roundUpX(this.estimateQuantityRaw, this.setUnitQuantity);
    } else {
      this.estimateQuantity = 0;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
