import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { PartitionLevel } from "vhows-design/src/object/design/other/partition/PartitionLevel";
import { WindowLevel } from "vhows-design/src/object/design/other/window/WindowLevel";
import { BandstringLevel } from "vhows-design/src/object/design/cover/bandstring/BandstringLevel";
import { BandstringPart_FixingNormalPad } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_FixingNormalPad";
import { ScreennetPart_FixingNormalPad } from "vhows-design/src/object/design/cover/screennet/ScreennetPart_FixingNormalPad";
import { ScreennetPosition } from "vhows-design/src/object/design/cover/screennet/ScreennetPosition";
import { SkirtPart_FixingNormalPad } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingNormalPad";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { WindbreakPart_FixingNormalPad } from "vhows-design/src/object/design/cover/windbreak/WindbreakPart_FixingNormalPad";
import { WindbreakPosition } from "vhows-design/src/object/design/cover/windbreak/WindbreakPosition";
import { FixingPart_NormalPad } from "vhows-design/src/object/design/cover/fixing/FixingPart_NormalPad";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-06-24
 */
@jsonObject
export class FixingSample_NormalPadSpring extends ItemPadSpring {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const normalPadPart: FixingPart_NormalPad = <FixingPart_NormalPad>this.part;
    normalPadPart.algorithmSpec_normalPad_normalPadSpring();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<FixingPosition>this.level.positionAC[p]).normalPadPart.normalPadSpringSample.specMaterial = this.specMaterial;
    }

    /// //////// 외부 ///////////

    // 마구리
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      endpieceLevel.frontPosition.gatePart.algorithmSpec_gate_normalPadSpring();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      endpieceLevel.backPosition.gatePart.algorithmSpec_gate_normalPadSpring();
    }

    // 밴드끈
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_SIDE) {
        const bandstringPart_FixingNormalPad: BandstringPart_FixingNormalPad = (<BandstringLevel>(
          this.struct.bandstringWork.levelAC[this.level.index]
        )).trunkPosition.fixingNormalPadPart;
        bandstringPart_FixingNormalPad.algorithmSpec_normalPad_normalPadSpring();
      }
    }

    // 개폐기
    // const switcherPart_FixingNormalPad: SwitcherPart_FixingNormalPad = (<SwitcherPosition>this.struct.switcherWork.levelAC[this.level.index].positionAC[this.position.index]).fixingNormalPadPart;
    // switcherPart_FixingNormalPad.algorithmSpec_normalPad_normalPadSpring();

    // 치마 피복
    const skirtPart_FixingNormalPad: SkirtPart_FixingNormalPad = (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingNormalPadPart;
    skirtPart_FixingNormalPad.algorithmSpec_normalPad_normalPadSpring();

    // 바람막이 피복
    const windbreakPart_FixingNormalPad: WindbreakPart_FixingNormalPad = (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingNormalPadPart;
    windbreakPart_FixingNormalPad.algorithmSpec_normalPad_normalPadSpring();

    // 방충망
    if (this.level.index === 0) {
      const screennetPart_FixingNormalPad: ScreennetPart_FixingNormalPad = (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.position.index]
      )).fixingNormalPadPart;
      screennetPart_FixingNormalPad.algorithmSpec_normalPad_normalPadSpring();
    }

    // 창문
    if (this.level.index === 0) {
      const windowLevel: WindowLevel = <WindowLevel>this.struct.windowWork.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_SIDE) {
        windowLevel.leftPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
        windowLevel.rightPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        windowLevel.frontPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        windowLevel.backPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
      }
    }

    // 칸막이
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        (<PartitionLevel>(
          this.struct.partitionWork.levelAC[this.level.index]
        )).trunkPosition.gatePart.algorithmSpec_gate_normalPadSpring();
        (<PartitionLevel>(
          this.struct.partitionWork.levelAC[this.level.index]
        )).trunkPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
      }
    }

    // 천창 피복
    if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_SIDE) {
      this.struct.skyCoverWork.level1.skylightPosition.normalPadPart.algorithmSpec_normalPad_normalPadSpring();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
