import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { SwitcherSampleIL_NormalClip } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_NormalClip";
import { SwitcherSampleIL_NormalClip2 } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_NormalClip2";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject({
  knownTypes: [SwitcherSampleIL_NormalClip, SwitcherSampleIL_NormalClip2],
})
export class SwitcherPartIL_FixingNormalClip extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalClipSample: SwitcherSampleIL_NormalClip; // 일반 클립 샘플
  public normalClip2Sample: SwitcherSampleIL_NormalClip2; // 일반 클립 샘플(2차)

  // 아이템
  public normalClip_normalClip: ItemClip;
  public normalClip2_normalClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 개폐 최대 차수 - 뷰의 데이터변경 이벤트 호출용 변수 */
  public switchOrderMax: number;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _clipInterval: number; // 클립 간격
  @jsonMember(Number)
  public _clip2Interval: number; // 클립(2차) 간격

  /**
   * 클립 간격
   */
  public get clipInterval(): number {
    return this._clipInterval;
  }

  public set clipInterval(value: number) {
    this._clipInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 클립(2차) 간격
   */
  public get clip2Interval(): number {
    return this._clip2Interval;
  }

  public set clip2Interval(value: number) {
    this._clip2Interval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalClipSample = new SwitcherSampleIL_NormalClip();
    this.normalClip2Sample = new SwitcherSampleIL_NormalClip2();

    this.sampleAC = [this.normalClipSample, this.normalClip2Sample];

    // 아이템
    this.normalClip_normalClip = new ItemClip();
    this.normalClip2_normalClip = new ItemClip();

    this.itemAC = [this.normalClip_normalClip, this.normalClip2_normalClip];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalClipSample = <SwitcherSampleIL_NormalClip>this.sampleAC[0];
    this.normalClip2Sample = <SwitcherSampleIL_NormalClip2>this.sampleAC[1];

    // 아이템
    this.normalClip_normalClip = <ItemClip>this.itemAC[0];
    this.normalClip2_normalClip = <ItemClip>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param clipInterval: number 클립 간격
   * @param clip2Interval: number 클립(2차) 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    clipInterval: number = 0,
    clip2Interval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._clipInterval = clipInterval;
    this._clip2Interval = clip2Interval;

    // 샘플
    if (this.level.index >= 0) {
      this.normalClipSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
      this.normalClip2Sample.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(2차)",
        "플라스틱, 25mm",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalClip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
      this.normalClip2_normalClip.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(2차)",
        "플라스틱, 25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      // 일반 클립 샘플(2차) 추가
      const normalClip2Sample: SwitcherSampleIL_NormalClip2 = new SwitcherSampleIL_NormalClip2();
      normalClip2Sample.setAssociation(design, struct, work, level, position, this);
      normalClip2Sample.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(2차)",
        "플라스틱, 25mm",
      );
      this.sampleAC.push(normalClip2Sample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    // 아이템
    if (this.itemAC.length === 1) {
      // [개폐 축(2차)] 파이프 추가
      const normalClip2_normalClip: ItemClip = new ItemClip();
      normalClip2_normalClip.setAssociation(design, struct, work, level, position, this);
      normalClip2_normalClip.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(2차)",
        "플라스틱, 25mm",
      );
      this.itemAC.push(normalClip2_normalClip);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>this.position;
    this.switchOrderMax = switcherPosition.switchOrderMax;
    let bothEndQuantity: number;
    let centralQuantity: number;
    // 외부
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }

    /// //////// 선택, 가시성 ///////////

    this.normalClip_normalClip.selected = true;
    this.normalClip_normalClip.visible = true;
    if (switcherPosition.switchOrderMax === CONST.VL_SWITCHER_ORDER_2) {
      this.normalClip2_normalClip.selected = true;
      this.normalClip2_normalClip.visible = true;
    } else {
      this.normalClip2_normalClip.selected = false;
      this.normalClip2_normalClip.visible = false;
    }

    /// //////// 수량 ///////////

    if (
      switcherPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      switcherPosition.label === CONST.LB_POSITION_SIDE_WING
    ) {
      // 1차
      this.normalClip_normalClip.designQuantity =
        Math.ceil(this.basicLevel.length / this.clipInterval) * switcherPosition.switchWayValue;

      // 2차
      this.normalClip2_normalClip.designQuantity =
        Math.ceil(this.basicLevel.length / this.clip2Interval) * switcherPosition.switchWayValue;

      if (switcherPosition.switchStep === CONST.VL_SWITCHER_ORDER_2) {
        this.normalClip_normalClip.designQuantity *= switcherPosition.switchStep;

        this.normalClip2_normalClip.designQuantity *= switcherPosition.switchStep;
      }
    } else if (switcherPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          // 1차
          bothEndQuantity = Math.ceil(this.basicLevel.length / this.clipInterval) * 2;
          centralQuantity =
            Math.ceil(this.basicLevel.length / this.clipInterval) *
            switcherPosition.switchWayValue *
            (this.basic.buildingNumber - 2);
          this.normalClip_normalClip.designQuantity = bothEndQuantity + centralQuantity;

          // 2차
          bothEndQuantity = Math.ceil(this.basicLevel.length / this.clip2Interval) * 2;
          centralQuantity =
            Math.ceil(this.basicLevel.length / this.clip2Interval) *
            switcherPosition.switchWayValue *
            (this.basic.buildingNumber - 2);
          this.normalClip2_normalClip.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          // 1차
          this.normalClip_normalClip.designQuantity =
            Math.ceil(this.basicLevel.length / this.clipInterval) *
            switcherPosition.switchWayValue *
            this.basic.buildingNumber;

          // 2차
          this.normalClip2_normalClip.designQuantity =
            Math.ceil(this.basicLevel.length / this.clip2Interval) *
            switcherPosition.switchWayValue *
            this.basic.buildingNumber;
        }
      } else {
        // 1차
        this.normalClip_normalClip.designQuantity =
          Math.ceil(this.basicLevel.length / this.clipInterval) * switcherPosition.switchWayValue;

        // 2차
        this.normalClip2_normalClip.designQuantity =
          Math.ceil(this.basicLevel.length / this.clip2Interval) * switcherPosition.switchWayValue;
      }
    } else if (switcherPosition.label === CONST.LB_POSITION_FRONT) {
      // 1차
      this.normalClip_normalClip.designQuantity =
        (this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber) / this.clipInterval;

      // 2차
      this.normalClip2_normalClip.designQuantity =
        (this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber) / this.clip2Interval;
    } else if (switcherPosition.label === CONST.LB_POSITION_BACK) {
      // 1차
      this.normalClip_normalClip.designQuantity =
        (this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber) / this.clipInterval;

      // 2차
      this.normalClip2_normalClip.designQuantity =
        (this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber) / this.clip2Interval;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 클립] 일반 클립 <- 일반 클립 샘플
   */
  public algorithmSpec_normalClip_normalClip(): void {
    this.normalClip_normalClip.specs = this.normalClipSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 클립(2차)] 일반 클립 <- 일반 클립 샘플(2차)
   */
  public algorithmSpec_normalClip2_normalClip(): void {
    this.normalClip2_normalClip.specs = this.normalClip2Sample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
