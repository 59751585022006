import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { EndpiecePartIL_CylinderStudSep } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_CylinderStudSep";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-15
 */
@jsonObject
export class EndpieceSampleIL_CylinderStudBottomPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const cylinderStudSepPart: EndpiecePartIL_CylinderStudSep = <EndpiecePartIL_CylinderStudSep>this.part;
    cylinderStudSepPart.algorithmSpec_studBottom_pipe();
    cylinderStudSepPart.algorithmSpec_studBottomAndMiddle_tClamp();
    cylinderStudSepPart.algorithmSpec_studBottomAndGirth_fixAsibaClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevelIL>this.level).backPosition.cylinderStudSepPart.studBottomPipeSample.specs = this.specs;
    }

    /// //////// 외부 ///////////

    // 피복 고정
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
    }

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      skirtLevel.frontPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      skirtLevel.backPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    }

    // 바람막이 피복
    const windbreakLevel: WindbreakLevelIL = <WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      windbreakLevel.frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      windbreakLevel.backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    }

    // 개폐기
    const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];
    switcherLevel.columnPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.wingPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.roofPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.columnPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
    switcherLevel.wingPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
    switcherLevel.roofPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플(기둥 골조), 보 파이프 샘플(기둥 골조), 샛기둥 하단 길이(파트)
   */
  // @override
  public algorithmSpec(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    if (columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) {
      this.specsList = columnPosition.cylinderColumnPart.columnPipeSample.specsList;
    } else if (columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM) {
      this.specsList = columnPosition.cylinderBeamPart.beamPipeSample.specsList;
    }
    this.specLength = (<EndpiecePartIL_CylinderStudSep>this.part).studBottomLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
