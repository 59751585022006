import { jsonMember, jsonObject, AnyT } from "typedjson";
import { Company } from "vhows-design/src/object/company/Company";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";

/**
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject()
export class Charge extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  @jsonMember(AnyT)
  public company: Company = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 요금제
  @jsonMember(String)
  public rateSystem: string = null;
  // 요금
  @jsonMember(Number)
  public charge: number = null;
  // 할인율
  @jsonMember(Number)
  public discountRate: number = null;

  // 요금 가입 일시
  @jsonMember(Date)
  public joinDate: Date = null;
  // 만료 일시
  @jsonMember(Date)
  public expirationDate: Date = null;
  // 가용 일수
  @jsonMember(Number)
  public availableDays: number = null;
  // 만료 여부
  @jsonMember(Boolean)
  public isExpired: boolean = false;

  // 노트
  @jsonMember(String)
  public note: string = null;

  //----------------------------------
  // DTO 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------
}
