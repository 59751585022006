import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { ProductModel } from "vhows-design/src/object/item/product/ProductModel";
import { SpecCompanyModel } from "vhows-design/src/object/item/spec/SpecCompanyModel";
import { BrandCompanyModel } from "vhows-design/src/object/item/brand/BrandCompanyModel";
import { OptionUnitPriceModel } from "vhows-design/src/object/item/unitprice/OptionUnitPriceModel";
import { UnitPriceModel } from "vhows-design/src/object/item/unitprice/UnitPriceModel";

/**
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject({
  knownTypes: [ProductModel, SpecCompanyModel, BrandCompanyModel],
})
export class ProductCompanyModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 품목 모델 */
  public itemModel: ItemModel = null;
  /** 조직별 분류 모델 */
  public categoryCompanyModel: CategoryCompanyModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 상품 레이블 : 설계의 규격 수정에서 사용
   */
  public get label(): string {
    return this.productModel.label;
  }

  //
  public set label(value: string) {
    this.productModel.label = value;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 상품 모델 */
  @jsonMember(ProductModel)
  public productModel: ProductModel = null;

  /** 조직별 규격 모델 배열 */
  @jsonArrayMember(SpecCompanyModel)
  public specCompanyModelAC: SpecCompanyModel[] = null;

  /**
   * 규격리스트 : 설계의 규격 수정에서 사용
   */
  public get specsList(): SpecCompanyModel[] {
    return this.specCompanyModelAC;
  }

  public set specsList(value: SpecCompanyModel[]) {
    this.specCompanyModelAC = value;
  }

  /** 조직별 상표 모델 배열 */
  @jsonArrayMember(BrandCompanyModel)
  public brandCompanyModelAC: BrandCompanyModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 비용 형태 */
  @jsonMember(String)
  public costType: string = null;
  /** 과세 형태 */
  @jsonMember(String)
  public taxType: string = null;
  /** 매출가 처리 자리수 */
  @jsonMember(Number)
  public sellingPriceFractionPosition: number = null;
  /** 매출가 처리 형태 (올림, 반올림, 내림) */
  @jsonMember(String)
  public sellingPriceFractionType: string = null;
  /** 평균 마진율 */
  @jsonMember(Number)
  private avgMarginRate: number = null;

  // 시스템

  /** 최종 변경자 */
  @jsonMember(String)
  public lastUpdater: string = null;
  /** 최종 변경 일시 */
  @jsonMember(Date)
  public lastUpdate: Date = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param taxType 과세 형태
   * @param costType 비용 형태
   * @param sellingPriceFractionPosition 매출가 끝수 처리 자리수
   * @param sellingPriceFractionType 매출가 끝수 처리 형태 (올림, 반올림, 내림 )
   * @param productModel 상품 모델
   * @param categoryCompanyModel 조직별 분류 모델
   * @param specCompanyModelAC 조직별 규격 모델 배열
   * @param brandCompanyModelAC 조직별 상표 모델 배열
   */
  constructor(
    taxType: string = "",
    costType: string = "",
    sellingPriceFractionPosition: number = 0,
    sellingPriceFractionType: string = "",
    productModel: ProductModel = new ProductModel(),
    categoryCompanyModel: CategoryCompanyModel = null,
    specCompanyModelAC: SpecCompanyModel[] = null,
    brandCompanyModelAC: BrandCompanyModel[] = null,
  ) {
    super();

    this.taxType = taxType;
    this.costType = costType;
    this.sellingPriceFractionPosition = sellingPriceFractionPosition;
    this.sellingPriceFractionType = sellingPriceFractionType;
    this.productModel = productModel;
    this.categoryCompanyModel = categoryCompanyModel;
    this.specCompanyModelAC = specCompanyModelAC;
    this.brandCompanyModelAC = brandCompanyModelAC;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(itemModel: ItemModel, categoryCompanyModel: CategoryCompanyModel): void {
    this.itemModel = itemModel;
    this.categoryCompanyModel = categoryCompanyModel;

    // 자식 연관
    let specCompanyModel: SpecCompanyModel;
    for (specCompanyModel of this.specCompanyModelAC) {
      specCompanyModel.setAssociation(itemModel, categoryCompanyModel, this);
    }
    let brandCompanyModel: BrandCompanyModel;
    for (brandCompanyModel of this.brandCompanyModelAC) {
      brandCompanyModel.setAssociation(itemModel, categoryCompanyModel, this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 자식 레퍼런스변수
    let specCompanyModel: SpecCompanyModel;
    for (specCompanyModel of this.specCompanyModelAC) {
      specCompanyModel.setReferenceVariable();
    }
    let brandCompanyModel: BrandCompanyModel;
    for (brandCompanyModel of this.brandCompanyModelAC) {
      brandCompanyModel.setReferenceVariable();
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 자식 초기화
    let specCompanyModel: SpecCompanyModel;
    for (specCompanyModel of this.specCompanyModelAC) {
      specCompanyModel.setDefaultVariable();
    }
    let brandCompanyModel: BrandCompanyModel;
    for (brandCompanyModel of this.brandCompanyModelAC) {
      brandCompanyModel.setDefaultVariable();
    }
  }

  //----------------------------------
  // 품목 모델
  //----------------------------------

  /**
   * 규격 모델 가져오기
   * @param specsList 규격 리스트 문자열
   * @return 규격 모델
   */
  public getSpecModel(specsList: string): SpecCompanyModel {
    // specCompanyModelAC는 상표과 관계없이 모든 규격을 가지고 있음
    let specCompanyModel: SpecCompanyModel;
    for (specCompanyModel of this.specCompanyModelAC) {
      if (specCompanyModel.specModel.label === specsList) {
        return specCompanyModel;
      }
    }

    // 임의 규격 상품에 대한 예외 처리 = 무조건 0번 리턴
    if (this.productModel.isCustomItem === true) {
      return <SpecCompanyModel>this.specCompanyModelAC[0];
    }

    return null;
  }

  /**
   * 상표 모델 가져오기
   * @param brandsList 상표 리스트 문자열
   * @return 상표 모델
   */
  public getBrandModel(brandsList: string): BrandCompanyModel {
    // brandCompanyModelAC는 규격과 관계없이 모든 상표를 가지고 있음
    let brandCompanyModel: BrandCompanyModel;
    for (brandCompanyModel of this.brandCompanyModelAC) {
      if (brandCompanyModel.brandModel.label === brandsList) {
        return brandCompanyModel;
      }
    }

    // 임의 규격 상품에 대한 예외 처리 = 무조건 0번 리턴
    if (this.productModel.isCustomItem === true) {
      return <BrandCompanyModel>this.brandCompanyModelAC[0];
    }

    return null;
  }

  /**
   * 첫번째 상표 모델 가져오기 <br/>
   * 규격 리스트만으로 찾아서 나온 첫번째 상표 모델을 찾기
   * @param specsList 규격 리스트 문자열
   * @return 상표 모델
   */
  public getFirstBrandModel(specsList: string): BrandCompanyModel {
    let specCompanyModel: SpecCompanyModel;
    for (specCompanyModel of this.specCompanyModelAC) {
      // 규격 리스트로만 찾기
      if (specCompanyModel.specModel.label === specsList) {
        // 첫번째 단가 모델의 상표 모델을 리턴함
        return (<UnitPriceModel>specCompanyModel.unitPriceModelAC[0]).brandCompanyModel;
      }
    }
    return null;
  }

  /**
   * 단가 모델 가져오기 <br/>
   * 규격 리스트와 상표 리스트를 가지고 단가 모델을 찾기
   * @param specsList 규격 리스트 문자열
   * @param brandsList 상표 리스트 문자열
   * @return 단가 모델
   */
  public getUnitPriceModel(specsList: string, brandsList: string): UnitPriceModel {
    // 규격 리스트에 맞는 규격 모델 찾기
    let specCompanyModel: SpecCompanyModel;
    for (specCompanyModel of this.specCompanyModelAC) {
      if (specCompanyModel.specModel.label === specsList) {
        // 상표 리스트에 맞는 상표 모델 찾기
        let unitPriceModel: UnitPriceModel;
        for (unitPriceModel of specCompanyModel.unitPriceModelAC) {
          if (unitPriceModel.brandCompanyModel.brandModel.label === brandsList) {
            return unitPriceModel;
          }
        }
      }
    }

    // 임의 규격 상품에 대한 예외 처리 = 무조건 0번 리턴
    if (this.productModel.isCustomItem === true) {
      return <UnitPriceModel>(<SpecCompanyModel>this.specCompanyModelAC[0]).unitPriceModelAC[0];
    }

    // 규격 리스트에 맞는 규격 모델 조차 없거나,
    // 규격 모델은 있지만, 상표 리스트에 맞는 단가 모델이 없는 경우
    return null;
  }

  /**
   * 단가 모델 가져오기2 <br/>
   * 규격 모델과 상표 리스트를 가지고 단가 모델을 찾기
   * @param specCompanyModel 규격 모델
   * @param brandsList 상표 리스트 문자열
   * @return 단가 모델
   */
  public getUnitPriceModel2(specCompanyModel: SpecCompanyModel, brandsList: string): UnitPriceModel {
    // 상표 리스트에 맞는 상표 모델 찾기
    let unitPriceModel: UnitPriceModel;
    for (unitPriceModel of specCompanyModel.unitPriceModelAC) {
      if (unitPriceModel.brandCompanyModel.brandModel.label === brandsList) {
        return unitPriceModel;
      }
    }

    // 임의 규격 상품에 대한 예외 처리 = 무조건 0번 리턴
    if (this.productModel.isCustomItem === true) {
      return <UnitPriceModel>(<SpecCompanyModel>this.specCompanyModelAC[0]).unitPriceModelAC[0];
    }

    // 규격 리스트에 맞는 규격 모델 조차 없거나,
    // 규격 모델은 있지만, 상표 리스트에 맞는 단가 모델이 없는 경우
    return null;
  }

  /**
   * 옵션 단가 모델 가져오기
   * @param option 옵션 문자열
   * @param brandsList 상표 리스트 문자열
   * @return 옵션 단가 모델
   */
  public getOptionUnitPriceModel(option: string, brandsList: string): OptionUnitPriceModel {
    for (const brandCompanyModel of this.brandCompanyModelAC) {
      if (brandCompanyModel.optionUnitPriceModelAC != null) {
        for (const optionUnitPriceModel of brandCompanyModel.optionUnitPriceModelAC) {
          if (
            optionUnitPriceModel.optionSpecModel.label === option &&
            optionUnitPriceModel.brandCompanyModel.brandModel.label === brandsList
          ) {
            return optionUnitPriceModel;
          }
        }
      }
    }
    return null;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
