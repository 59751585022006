import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { ColumnPartIL_CylinderBeam } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderBeam";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-15
 */
@jsonObject
export class ColumnSampleIL_CylinderBeamPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const cylinderBeamPart: ColumnPartIL_CylinderBeam = <ColumnPartIL_CylinderBeam>this.part;
    cylinderBeamPart.algorithmSpec_beam_pipe();
    cylinderBeamPart.algorithmSpec_beamAndBeam_connectionPin();
    cylinderBeamPart.algorithmSpec_beamAndColumn_tClamp();

    // 다른 파트
    (<ColumnPositionIL>this.position).cylinderColumnPart.columnBracingPipeSample.algorithmSpec();
    (<ColumnPositionIL>this.position).cylinderColumnPart.columnBracingPipeSample.algorithmSpec_specLength();
    (<ColumnPositionIL>this.position).cylinderMiddlePart.middlePipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    if (this.position.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      // 지붕 골조
      for (const roofLevel of this.struct.roofWorkIL.levelAC as RoofLevelIL[]) {
        // 모든 중수
        roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce();
        roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamEdgeCylinder_steelStringGrabber();
        roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamEdgeCylinder_uClamp();
      }

      // 방풍벽 골조
      const wingLevel1: WingLevelIL = this.struct.wingWorkIL.level1;
      wingLevel1.wingPosition.wingRafterPart.algorithmSpec_wingRafterFirstAndBeam_outerWallClamp();

      // 마구리 골조
      for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) {
        // 모든 중수
        endpieceLevel.frontPosition.cylinderStudSepPart.studBottomPipeSample.algorithmSpec();
        endpieceLevel.backPosition.cylinderStudSepPart.studBottomPipeSample.algorithmSpec();
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    if (
      (<ColumnPositionIL>this.position).cylinderColumnPart.columnPipeSample.specCrossSize ===
      CONST.ITEM_SPEC_VALUE_48_MM
    ) {
      this.specsList = (<ColumnPositionIL>this.position).cylinderColumnPart.columnPipeSample.specsList;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
