import { jsonObject } from "typedjson";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { SwitcherPart_Support } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Support";
import { SwitcherPart_Power } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Power";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-21
 */
@jsonObject
export class SwitcherSample_VerticalSupPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const supportPart: SwitcherPart_Support = <SwitcherPart_Support>this.part;
    supportPart.algorithmSpec_vertical_pipe();
    supportPart.algorithmSpec_vertical_axisRoller();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const power1Part: SwitcherPart_Power = (<SwitcherPosition>this.position).power1Part;
    power1Part.powerSwitcherSample.algorithmSpec();
    power1Part.powerSwitcherSample.algorithmSpec_supportCoupling();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<SwitcherPosition>this.level.positionAC[p]).supportPart.verticalSupportPipeSample.specs = this.specs;
    }

    // 다른 중수
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        // 해당 중수의 측면 위치만 바꿔줘도 전체 위치의 규격이 바뀌게됨
        (<SwitcherLevel>this.work.levelAC[l]).sidePosition.supportPart.verticalSupportPipeSample.specs = this.specs;
      }
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 지지 형태
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
