import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ColumnPartVL_Column } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Column";
import { ColumnPartVL_Beam } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Beam";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { WindowPositionIL } from "vhows-design/src/object/design/other/window/il/WindowPositionIL";

/**
 * @author 오은헤
 * @copyright RUNean Inc.
 * @date 2022-04-07
 */
@jsonObject
export class ColumnSampleVL_SideColumnPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////
    const prismColumnPart: ColumnPartVL_Column = <ColumnPartVL_Column>this.part;
    prismColumnPart.algorithmSpec_sideColumn_pipe();

    // 다른 파트
    const prismBeamPart: ColumnPartVL_Beam = (<ColumnPositionVL>this.position).prismBeamPart;
    prismBeamPart.algorithmSpec_beamAndSideColumn_tClamp();

    /// //////// 외부 ///////////

    // 방풍벽 골조
    for (const wingLevel of this.struct.wingWorkVL.levelAC as WingLevelVL[]) {
      // 모든 중수
      wingLevel.wingPosition.wingRafterPart.algorithmSpec_wingRafterSecondAndColumn_secondLevelSoleSconce();
      wingLevel.wingPosition.wingRafterPart.algorithmSpec_wingRafterEnhanced_secondLevelSoleSconce();
    }

    // 창문
    if (this.level.index === 0) {
      for (const windowPosition of this.struct.windowWorkIL.levelAC[this.level.index]
        .positionAC as WindowPositionIL[]) {
        windowPosition.sashPart.sashFramePipeSample.algorithmSpec();
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘
   */
  // @override
  public algorithmSpec(): void {
    this.specLength = (<ColumnPartVL_Column>this.part).columnLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
