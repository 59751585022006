import { jsonObject } from "typedjson";

import { ItemPowerSwitcher } from "vhows-design/src/object/design/item/list/ItemPowerSwitcher";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurSwitchPartIL_Power } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Power";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject
export class CurSwitchSampleIL_PowerSwitcher extends ItemPowerSwitcher {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const powerPart: CurSwitchPartIL_Power = <CurSwitchPartIL_Power>this.part;
    powerPart.algorithmSpec_power_powerSwitcher();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageCurSwitcherAndMotorQuantity();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 개폐 축 파이프 샘플, 수직형 지지 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specAxisCoupling = (<CurSwitchPositionIL>this.position).axisPart.axisPipeSample.specCrossSize;
    this.specSupportCoupling = (<CurSwitchPositionIL>(
      this.position
    )).supVerticalPart.verticalSupportPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
