import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { FoundationSampleIL_LengthGirthPipe } from "vhows-design/src/object/design/foundation/il/FoundationSampleIL_LengthGirthPipe";
import { FoundationSampleIL_WidthGirthPipe } from "vhows-design/src/object/design/foundation/il/FoundationSampleIL_WidthGirthPipe";
import { FoundationPositionIL } from "vhows-design/src/object/design/foundation/il/FoundationPositionIL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject({
  knownTypes: [FoundationSampleIL_LengthGirthPipe, FoundationSampleIL_WidthGirthPipe],
})
export class FoundationPartIL_GirthPipe extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public lengthGirthPipeSample: FoundationSampleIL_LengthGirthPipe; // 길이방향 바닥도리 파이프 샘플
  public widthGirthPipeSample: FoundationSampleIL_WidthGirthPipe; // 폭방향 바닥도리 파이프 샘플

  // 아이템
  public girthLength_pipe: ItemPipe;
  public girthLength_connectionPin: ItemConnector;
  public girthLengthAndColumnCylinder_fixAsibaClamp: ItemHolder;
  public girthLengthAndColumnCylinder_steelStringGrabber: ItemHolder;
  public girthLengthAndColumnPrism_uClamp: ItemHolder;
  public girthWidth_pipe: ItemPipe;
  public girthWidth_connectionPin: ItemConnector;
  public girthWidthAndColumnCylinder_fixAsibaClamp: ItemHolder;
  public girthWidthAndColumnCylinder_steelStringGrabber: ItemHolder;
  public girthWidthAndColumnPrism_uClamp: ItemHolder;
  public girth_welding: ItemOtherExpense;
  public girth_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _lengthGirthLength: number; // 길이방향 바닥도리 길이
  @jsonMember(Number)
  public _lengthGirthQuantity: number; // 길이방향 바닥도리 수량
  @jsonMember(Boolean)
  public _widthGirthSelected: boolean; // 폭방향 바닥도리 선택
  @jsonMember(Number)
  public _widthGirthLength: number; // 폭방향 바닥도리 길이
  @jsonMember(Number)
  public _widthGirthQuantity: number; // 폭방향 바닥도리 수량
  @jsonMember(Boolean)
  public _centerLengthGirtSelected: boolean; // 중앙동 길이방향 바닥도리 선택

  /**
   * 길이방향 바닥도리 길이
   */
  public get lengthGirthLength(): number {
    return this._lengthGirthLength;
  }

  //
  public set lengthGirthLength(value: number) {
    this._lengthGirthLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_lengthGirthQuantity();
    this.algorithmPart();
  }

  /**
   * 길이방향 바닥도리 수량
   */
  public get lengthGirthQuantity(): number {
    return this._lengthGirthQuantity;
  }

  //
  public set lengthGirthQuantity(value: number) {
    this._lengthGirthQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 폭방향 바닥도리 선택
   */
  public get widthGirthSelected(): boolean {
    return this._widthGirthSelected;
  }

  //
  public set widthGirthSelected(value: boolean) {
    this._widthGirthSelected = value;

    // 알고리즘
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 마구리 골조
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) {
        endpieceLevel.frontPosition.cylinderStudPart.algorithmPart();
        endpieceLevel.frontPosition.cylinderStudSepPart.algorithmPart();
        endpieceLevel.frontPosition.prismStudSepPart.algorithmPart();
        endpieceLevel.backPosition.cylinderStudPart.algorithmPart();
        endpieceLevel.backPosition.cylinderStudSepPart.algorithmPart();
        endpieceLevel.backPosition.prismStudSepPart.algorithmPart();
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      for (const endpieceLevel of this.struct.endpieceWorkVL.levelAC as EndpieceLevelVL[]) {
        endpieceLevel.frontPosition.endpiecePart.algorithmPart();
        endpieceLevel.backPosition.endpiecePart.algorithmPart();
      }
    }
  }

  /**
   * 폭방향 바닥도리 길이
   */
  public get widthGirthLength(): number {
    return this._widthGirthLength;
  }

  //
  public set widthGirthLength(value: number) {
    this._widthGirthLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_widthGirthQuantity();
    this.algorithmPart();
  }

  /**
   * 폭방향 바닥도리 수량
   */
  public get widthGirthQuantity(): number {
    return this._widthGirthQuantity;
  }

  //
  public set widthGirthQuantity(value: number) {
    this._widthGirthQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중앙동 길이방향 바닥도리 선택
   */
  public get centerLengthGirtSelected(): boolean {
    return this._centerLengthGirtSelected;
  }

  //
  public set centerLengthGirtSelected(value: boolean) {
    this._centerLengthGirtSelected = value;

    // 알고리즘
    this.algorithm_lengthGirthQuantity();
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.lengthGirthPipeSample = new FoundationSampleIL_LengthGirthPipe();
    this.widthGirthPipeSample = new FoundationSampleIL_WidthGirthPipe();

    this.sampleAC = [this.lengthGirthPipeSample, this.widthGirthPipeSample];

    // 아이템
    this.girthLength_pipe = new ItemPipe();
    this.girthLength_connectionPin = new ItemConnector();
    this.girthLengthAndColumnCylinder_fixAsibaClamp = new ItemHolder();
    this.girthLengthAndColumnCylinder_steelStringGrabber = new ItemHolder();
    this.girthLengthAndColumnPrism_uClamp = new ItemHolder();
    this.girthWidth_pipe = new ItemPipe();
    this.girthWidth_connectionPin = new ItemConnector();
    this.girthWidthAndColumnCylinder_fixAsibaClamp = new ItemHolder();
    this.girthWidthAndColumnCylinder_steelStringGrabber = new ItemHolder();
    this.girthWidthAndColumnPrism_uClamp = new ItemHolder();
    this.girth_welding = new ItemOtherExpense();
    this.girth_screw = new ItemScrew();

    this.itemAC = [
      this.girthLength_pipe,
      this.girthLength_connectionPin,
      this.girthLengthAndColumnCylinder_fixAsibaClamp,
      this.girthLengthAndColumnCylinder_steelStringGrabber,
      this.girthLengthAndColumnPrism_uClamp,
      this.girthWidth_pipe,
      this.girthWidth_connectionPin,
      this.girthWidthAndColumnCylinder_fixAsibaClamp,
      this.girthWidthAndColumnCylinder_steelStringGrabber,
      this.girthWidthAndColumnPrism_uClamp,
      this.girth_welding,
      this.girth_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.lengthGirthPipeSample = <FoundationSampleIL_LengthGirthPipe>this.sampleAC[0];
    this.widthGirthPipeSample = <FoundationSampleIL_WidthGirthPipe>this.sampleAC[1];

    // 아이템
    this.girthLength_pipe = <ItemPipe>this.itemAC[0];
    this.girthLength_connectionPin = <ItemConnector>this.itemAC[1];
    this.girthLengthAndColumnCylinder_fixAsibaClamp = <ItemHolder>this.itemAC[2];
    this.girthLengthAndColumnCylinder_steelStringGrabber = <ItemHolder>this.itemAC[3];
    this.girthLengthAndColumnPrism_uClamp = <ItemHolder>this.itemAC[4];
    this.girthWidth_pipe = <ItemPipe>this.itemAC[5];
    this.girthWidth_connectionPin = <ItemConnector>this.itemAC[6];
    this.girthWidthAndColumnCylinder_fixAsibaClamp = <ItemHolder>this.itemAC[7];
    this.girthWidthAndColumnCylinder_steelStringGrabber = <ItemHolder>this.itemAC[8];
    this.girthWidthAndColumnPrism_uClamp = <ItemHolder>this.itemAC[9];
    this.girth_welding = <ItemOtherExpense>this.itemAC[10];
    this.girth_screw = <ItemScrew>this.itemAC[11];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param widthGirthSelected: boolean 폭방향 바닥도리 선택
   * @param centerLengthGirtSelected: boolean 중앙동 길이방향 바닥도리 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    widthGirthSelected: boolean = false,
    centerLengthGirtSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._widthGirthSelected = widthGirthSelected;
    this._centerLengthGirtSelected = centerLengthGirtSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.lengthGirthPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "길이방향 바닥도리",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.widthGirthPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "폭방향 바닥도리",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.girthLength_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "길이방향 바닥도리",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.girthLength_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "길이방향 바닥도리",
        "원형, 일반, 48mm, 48mm",
      );
      this.girthLengthAndColumnCylinder_fixAsibaClamp.setDefaultData(
        2,
        CONST.ITEM_ID_FIX_ASIBA_CLAMP,
        CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
        "길이방향 바닥도리 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.girthLengthAndColumnCylinder_steelStringGrabber.setDefaultData(
        3,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "길이방향 바닥도리 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.girthLengthAndColumnPrism_uClamp.setDefaultData(
        4,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "길이방향 바닥도리 + 기둥",
        "각형, 일반, 60×60mm, 48mm",
      );
      this.girthWidth_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "폭방향 바닥도리",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.girthWidth_connectionPin.setDefaultData(
        6,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "폭방향 바닥도리",
        "원형, 일반, 48mm, 48mm",
      );
      this.girthWidthAndColumnCylinder_fixAsibaClamp.setDefaultData(
        7,
        CONST.ITEM_ID_FIX_ASIBA_CLAMP,
        CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
        "폭방향 바닥도리 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.girthWidthAndColumnCylinder_steelStringGrabber.setDefaultData(
        8,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "폭방향 바닥도리 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.girthWidthAndColumnPrism_uClamp.setDefaultData(
        9,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "폭방향 바닥도리 + 기둥",
        "각형, 일반, 60×60mm, 48mm",
      );
      this.girth_welding.setDefaultData(10, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "바닥도리 + 기둥", "일반");
      this.girth_screw.setDefaultData(
        11,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "바닥도리",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 10) {
      const girthAndPrismColumn_welding: ItemOtherExpense = new ItemOtherExpense();
      girthAndPrismColumn_welding.setAssociation(design, struct, work, level, position, this);
      girthAndPrismColumn_welding.setDefaultData(
        10,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "바닥도리 + 각형 기둥",
        "일반",
      );
      girthAndPrismColumn_welding.selected = false;
      girthAndPrismColumn_welding.visible = false;
      this.itemAC.push(girthAndPrismColumn_welding);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (
      this.itemAC.length === 11 &&
      (<Item>this.itemAC[2]).productId === CONST.ITEM_ID_SCREW &&
      (<Item>this.itemAC[7]).productId === CONST.ITEM_ID_SCREW
    ) {
      // 나사 합치기
      const girth_screw: ItemScrew = <ItemScrew>this.itemAC[2];
      girth_screw.designQuantity += (<ItemScrew>this.itemAC[7]).getSelectedQuantity();
      girth_screw.selected = girth_screw.getSelectedQuantity() > 0;
      girth_screw.visible = girth_screw.getSelectedQuantity() > 0;
      girth_screw.purpose = "바닥도리";
      this.itemAC.splice(7, 1);
      CommonUtil.changeArrayIndex(this.itemAC, 2, 9);
      // [길이방향 바닥도리 + 기둥] 강선 조리개
      const girthLengthAndColumnCylinder_steelStringGrabber: ItemHolder = new ItemHolder();
      girthLengthAndColumnCylinder_steelStringGrabber.setAssociation(design, struct, work, level, position, this);
      girthLengthAndColumnCylinder_steelStringGrabber.setDefaultData(
        3,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "길이방향 바닥도리 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      girthLengthAndColumnCylinder_steelStringGrabber.selected = false;
      girthLengthAndColumnCylinder_steelStringGrabber.visible = false;
      this.itemAC.splice(3, 0, girthLengthAndColumnCylinder_steelStringGrabber);
      // [폭방향 바닥도리 + 기둥] 강선 조리개
      const girthWidthAndColumnCylinder_steelStringGrabber: ItemHolder = new ItemHolder();
      girthWidthAndColumnCylinder_steelStringGrabber.setAssociation(design, struct, work, level, position, this);
      girthWidthAndColumnCylinder_steelStringGrabber.setDefaultData(
        8,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "폭방향 바닥도리 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      girthWidthAndColumnCylinder_steelStringGrabber.selected = false;
      girthWidthAndColumnCylinder_steelStringGrabber.visible = false;
      this.itemAC.splice(8, 0, girthWidthAndColumnCylinder_steelStringGrabber);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_lengthGirthLength();
    this.algorithm_lengthGirthQuantity();
    this.algorithm_widthGirthLength();
    this.algorithm_widthGirthQuantity();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 선택, 가시성 ///////////

    this.girthLength_pipe.checkSelected(true);
    this.girthLength_connectionPin.checkSelected(true);
    if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      // 하드코딩 : 길이방향 바닥도리 단면크기 == 42mm || 48mm || 60mm
      if (
        this.lengthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
        this.lengthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM ||
        this.lengthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM
      ) {
        this.girthLengthAndColumnCylinder_fixAsibaClamp.selected = true;
        this.girthLengthAndColumnCylinder_fixAsibaClamp.visible = true;
        this.girthLengthAndColumnCylinder_steelStringGrabber.selected = false;
        this.girthLengthAndColumnCylinder_steelStringGrabber.visible = false;
      } else {
        this.girthLengthAndColumnCylinder_fixAsibaClamp.selected = false;
        this.girthLengthAndColumnCylinder_fixAsibaClamp.visible = false;
        this.girthLengthAndColumnCylinder_steelStringGrabber.selected = true;
        this.girthLengthAndColumnCylinder_steelStringGrabber.visible = true;
      }
      this.girthLengthAndColumnPrism_uClamp.selected = false;
      this.girthLengthAndColumnPrism_uClamp.visible = false;
      this.girth_welding.selected = false;
      this.girth_welding.visible = false;
    } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
      this.girthLengthAndColumnCylinder_fixAsibaClamp.selected = false;
      this.girthLengthAndColumnCylinder_fixAsibaClamp.visible = false;
      this.girthLengthAndColumnCylinder_steelStringGrabber.selected = false;
      this.girthLengthAndColumnCylinder_steelStringGrabber.visible = false;
      if (this.design.preference.floorGirthPrismColumnWeldingFlag === true) {
        this.girthLengthAndColumnPrism_uClamp.selected = false;
        this.girthLengthAndColumnPrism_uClamp.visible = false;
        this.girth_welding.selected = true;
        this.girth_welding.visible = true;
      } else {
        this.girthLengthAndColumnPrism_uClamp.selected = true;
        this.girthLengthAndColumnPrism_uClamp.visible = true;
        this.girth_welding.selected = false;
        this.girth_welding.visible = false;
      }
    }
    if (this.widthGirthSelected === true) {
      this.girthWidth_pipe.selected = true;
      this.girthWidth_pipe.visible = true;
      this.girthWidth_connectionPin.selected = true;
      this.girthWidth_connectionPin.visible = true;
      if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        // 하드코딩 : 폭방향 바닥도리 단면크기 == 42mm || 48mm || 60mm
        if (
          this.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
          this.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM ||
          this.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM
        ) {
          this.girthWidthAndColumnCylinder_fixAsibaClamp.selected = true;
          this.girthWidthAndColumnCylinder_fixAsibaClamp.visible = true;
          this.girthWidthAndColumnCylinder_steelStringGrabber.selected = false;
          this.girthWidthAndColumnCylinder_steelStringGrabber.visible = false;
        } else {
          this.girthWidthAndColumnCylinder_fixAsibaClamp.selected = false;
          this.girthWidthAndColumnCylinder_fixAsibaClamp.visible = false;
          this.girthWidthAndColumnCylinder_steelStringGrabber.selected = true;
          this.girthWidthAndColumnCylinder_steelStringGrabber.visible = true;
        }
        this.girthWidthAndColumnPrism_uClamp.selected = false;
        this.girthWidthAndColumnPrism_uClamp.visible = false;
        this.girth_welding.selected = false;
        this.girth_welding.visible = false;
      } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.girthWidthAndColumnCylinder_fixAsibaClamp.selected = false;
        this.girthWidthAndColumnCylinder_fixAsibaClamp.visible = false;
        this.girthWidthAndColumnCylinder_steelStringGrabber.selected = false;
        this.girthWidthAndColumnCylinder_steelStringGrabber.visible = false;
        if (this.design.preference.floorGirthPrismColumnWeldingFlag === true) {
          this.girthWidthAndColumnPrism_uClamp.selected = false;
          this.girthWidthAndColumnPrism_uClamp.visible = false;
          this.girth_welding.selected = true;
          this.girth_welding.visible = true;
        } else {
          this.girthWidthAndColumnPrism_uClamp.selected = true;
          this.girthWidthAndColumnPrism_uClamp.visible = true;
          this.girth_welding.selected = false;
          this.girth_welding.visible = false;
        }
      }
    } else {
      this.girthWidth_pipe.selected = false;
      this.girthWidth_pipe.visible = false;
      this.girthWidth_connectionPin.selected = false;
      this.girthWidth_connectionPin.visible = false;
      this.girthWidthAndColumnCylinder_fixAsibaClamp.selected = false;
      this.girthWidthAndColumnCylinder_fixAsibaClamp.visible = false;
      this.girthWidthAndColumnCylinder_steelStringGrabber.selected = false;
      this.girthWidthAndColumnCylinder_steelStringGrabber.visible = false;
      this.girthWidthAndColumnPrism_uClamp.selected = false;
      this.girthWidthAndColumnPrism_uClamp.visible = false;
    }
    this.girth_screw.selected = true;
    this.girth_screw.visible = true;

    /// //////// 수치 ///////////

    let weldingQuantity: number = 0;

    /// //////// 수량 ///////////

    this.girthLength_pipe.designQuantity = this.lengthGirthQuantity;

    if (this.lengthGirthLength > this.girthLength_pipe.specLength) {
      this.girthLength_connectionPin.designQuantity =
        this.girthLength_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.girthLength_connectionPin.designQuantity = 0;
    }

    if (this.centerLengthGirtSelected === true) {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        const columnPositionIL: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
        this.girthLengthAndColumnCylinder_fixAsibaClamp.designQuantity =
          columnPositionIL.cylinderColumnPart.columnQuantity * CONST.NUM_EXTRA_RATE_FIX_ASIBA_CLAMP;

        this.girthLengthAndColumnCylinder_steelStringGrabber.designQuantity =
          columnPositionIL.cylinderColumnPart.columnQuantity * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

        this.girthLengthAndColumnPrism_uClamp.designQuantity =
          columnPositionIL.prismColumnPart.columnQuantity * CONST.NUM_EXTRA_RATE_U_CLAMP;

        weldingQuantity = columnPositionIL.prismColumnPart.columnQuantity;
      } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
        const columnPositionVL: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
        this.girthLengthAndColumnPrism_uClamp.designQuantity =
          columnPositionVL.prismColumnPart.columnTotalQuantity * CONST.NUM_EXTRA_RATE_U_CLAMP;

        weldingQuantity = columnPositionVL.prismColumnPart.columnTotalQuantity;
      }
    } else {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        const columnPositionIL: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
        this.girthLengthAndColumnCylinder_fixAsibaClamp.designQuantity =
          Math.ceil(columnPositionIL.cylinderColumnPart.columnQuantity / (this.basic.buildingNumber + 1)) *
          2 *
          CONST.NUM_EXTRA_RATE_FIX_ASIBA_CLAMP;

        this.girthLengthAndColumnCylinder_steelStringGrabber.designQuantity =
          Math.ceil(columnPositionIL.cylinderColumnPart.columnQuantity / (this.basic.buildingNumber + 1)) *
          2 *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

        this.girthLengthAndColumnPrism_uClamp.designQuantity =
          Math.ceil(columnPositionIL.prismColumnPart.columnQuantity / (this.basic.buildingNumber + 1)) *
          2 *
          CONST.NUM_EXTRA_RATE_FIX_ASIBA_CLAMP;

        weldingQuantity =
          Math.ceil(columnPositionIL.prismColumnPart.columnQuantity / (this.basic.buildingNumber + 1)) * 2;
      } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
        const columnPositionVL: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
        this.girthLengthAndColumnPrism_uClamp.designQuantity =
          Math.ceil(columnPositionVL.prismColumnPart.columnTotalQuantity / (this.basic.buildingNumber + 1)) *
          2 *
          CONST.NUM_EXTRA_RATE_FIX_ASIBA_CLAMP;

        weldingQuantity =
          Math.ceil(columnPositionVL.prismColumnPart.columnTotalQuantity / (this.basic.buildingNumber + 1)) * 2;
      }
    }

    this.girthWidth_pipe.designQuantity = this.widthGirthQuantity;

    if (this.widthGirthLength > this.girthWidth_pipe.specLength) {
      this.girthWidth_connectionPin.designQuantity =
        this.girthWidth_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.girthWidth_connectionPin.designQuantity = 0;
    }

    this.girthWidthAndColumnCylinder_fixAsibaClamp.designQuantity = (this.basic.buildingNumber + 1) * 2;

    this.girthWidthAndColumnCylinder_steelStringGrabber.designQuantity = (this.basic.buildingNumber + 1) * 2;

    this.girthWidthAndColumnPrism_uClamp.designQuantity = (this.basic.buildingNumber + 1) * 2;

    weldingQuantity += (this.basic.buildingNumber + 1) * 2;
    this.girth_welding.designQuantity = weldingQuantity;

    this.girth_screw.designQuantity =
      ((this.girthLength_connectionPin.getSelectedQuantity() * 4 +
        this.girthWidth_connectionPin.getSelectedQuantity() * 4) /
        this.girth_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 길이방향 바닥도리 길이 <- 길이(기본정보)
   */
  public algorithm_lengthGirthLength(): void {
    this.lengthGirthLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
  }

  /**
   * 알고리즘: 길이방향 바닥도리 수량 <- 길이방향 바닥도리 길이, 중앙동 길이방향 바닥도리 선택, 단위길이(샘플), 동수(기본정보)
   */
  public algorithm_lengthGirthQuantity(): void {
    if (this.centerLengthGirtSelected === true) {
      this.lengthGirthQuantity = CommonUtil.roundUp(
        (this.girthLength_pipe.getConnectedPipeLength(this.lengthGirthLength, this.basic.buildingNumber + 1) /
          this.girthLength_pipe.specLength) *
          CONST.NUM_EXTRA_RATE_FLOOR_GIRTH_PIPE,
        1,
      );
    } else {
      this.lengthGirthQuantity = CommonUtil.roundUp(
        (this.girthLength_pipe.getConnectedPipeLength(this.lengthGirthLength, 2) / this.girthLength_pipe.specLength) *
          CONST.NUM_EXTRA_RATE_FLOOR_GIRTH_PIPE,
        1,
      );
    }
  }

  /**
   * 알고리즘: 중앙동 길이방향 바닥도리 선택 <- 형태(포지션)
   */
  public algorithm_centerLengthGirtSelected(): void {
    if ((<FoundationPositionIL>this.position).type === CONST.LB_FOUNDATION_TYPE_GIRTH_PIPE) {
      this.centerLengthGirtSelected = true;
    } else if ((<FoundationPositionIL>this.position).type === CONST.LB_FOUNDATION_TYPE_GIRTH_AND_COLUMN_CONCRETE) {
      this.centerLengthGirtSelected = false;
    }
  }

  /**
   * 알고리즘: 폭방향 바닥도리 길이 <- 폭(기본정보), 동수(기본정보)
   */
  public algorithm_widthGirthLength(): void {
    this.widthGirthLength = this.basicLevel.width * this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 폭방향 바닥도리 수량 <- 폭방향 바닥도리 길이, 단위길이(샘플)
   */
  public algorithm_widthGirthQuantity(): void {
    this.widthGirthQuantity =
      (this.girthWidth_pipe.getConnectedPipeLength(this.widthGirthLength, 2) / this.girthWidth_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_FLOOR_GIRTH_PIPE;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [길이방향 바닥도리] 파이프 <- 길이방향 바닥도리 파이프 샘플
   */
  public algorithmSpec_girthLength_pipe(): void {
    this.girthLength_pipe.specs = this.lengthGirthPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [길이방향 바닥도리] 연결핀 <- 길이방향 바닥도리 파이프 샘플
   */
  public algorithmSpec_girthLength_connectionPin(): void {
    this.girthLength_connectionPin.specPipeType = this.lengthGirthPipeSample.specPipeType;
    this.girthLength_connectionPin.specCrossSize1 = this.lengthGirthPipeSample.specCrossSize;
    this.girthLength_connectionPin.specCrossSize2 = this.lengthGirthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [길이방향 바닥도리 + 기둥] 고정 아시바 클램프 <- 길이방향 바닥도리 파이프 샘플, 기둥 파이프 샘플(기둥 골조/원기둥)
   */
  public algorithmSpec_girthLengthAndColumnCylinder_fixAsibaClamp(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;

      this.girthLengthAndColumnCylinder_fixAsibaClamp.specPipeType =
        columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
      this.girthLengthAndColumnCylinder_fixAsibaClamp.specCrossSize1 =
        columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      this.girthLengthAndColumnCylinder_fixAsibaClamp.specCrossSize2 = this.lengthGirthPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [길이방향 바닥도리 + 기둥] 강선 조리개 <- 길이방향 바닥도리 파이프 샘플, 기둥 파이프 샘플(기둥 골조/원기둥)
   */
  public algorithmSpec_girthLengthAndColumnCylinder_steelStringGrabber(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;

      this.girthLengthAndColumnCylinder_steelStringGrabber.specPipeType = this.lengthGirthPipeSample.specPipeType;
      this.girthLengthAndColumnCylinder_steelStringGrabber.specCrossSize1 = this.lengthGirthPipeSample.specCrossSize;
      this.girthLengthAndColumnCylinder_steelStringGrabber.specCrossSize2 =
        columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [길이방향 바닥도리 + 기둥] U 클램프 <- 길이방향 바닥도리 파이프 샘플, 기둥 파이프 샘플(기둥 골조/각기둥)
   */
  public algorithmSpec_girthLengthAndColumnPrism_uClamp(): void {
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }
    this.girthLengthAndColumnPrism_uClamp.specPipeType = columnPosition.prismColumnPart.columnPipeSample.specPipeType;
    this.girthLengthAndColumnPrism_uClamp.specCrossSize1 =
      columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
    this.girthLengthAndColumnPrism_uClamp.specCrossSize2 = this.lengthGirthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [폭방향 바닥도리] 파이프 <- 폭방향 바닥도리 파이프 샘플
   */
  public algorithmSpec_girthWidth_pipe(): void {
    this.girthWidth_pipe.specs = this.widthGirthPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [폭방향 바닥도리] 연결핀 <- 폭방향 바닥도리 파이프 샘플
   */
  public algorithmSpec_girthWidth_connectionPin(): void {
    this.girthWidth_connectionPin.specPipeType = this.widthGirthPipeSample.specPipeType;
    this.girthWidth_connectionPin.specCrossSize1 = this.widthGirthPipeSample.specCrossSize;
    this.girthWidth_connectionPin.specCrossSize2 = this.widthGirthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [폭방향 바닥도리 + 기둥] 고정 아시바 클램프 <- 폭방향 바닥도리 파이프 샘플, 기둥 파이프 샘플(기둥 골조/원기둥)
   */
  public algorithmSpec_girthWidthAndColumnCylinder_fixAsibaClamp(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;

      this.girthWidthAndColumnCylinder_fixAsibaClamp.specPipeType =
        columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
      this.girthWidthAndColumnCylinder_fixAsibaClamp.specCrossSize1 =
        columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      this.girthWidthAndColumnCylinder_fixAsibaClamp.specCrossSize2 = this.lengthGirthPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [폭방향 바닥도리 + 기둥] 강선 조리개 <- 폭방향 바닥도리 파이프 샘플, 기둥 파이프 샘플(기둥 골조/원기둥)
   */
  public algorithmSpec_girthWidthAndColumnCylinder_steelStringGrabber(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;

      this.girthWidthAndColumnCylinder_steelStringGrabber.specPipeType = this.lengthGirthPipeSample.specPipeType;
      this.girthWidthAndColumnCylinder_steelStringGrabber.specCrossSize1 = this.lengthGirthPipeSample.specCrossSize;
      this.girthWidthAndColumnCylinder_steelStringGrabber.specCrossSize2 =
        columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [폭방향 바닥도리 + 기둥] U 클램프 <- 폭방향 바닥도리 파이프 샘플, 기둥 파이프 샘플(기둥 골조/각기둥)
   */
  public algorithmSpec_girthWidthAndColumnPrism_uClamp(): void {
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }
    this.girthWidthAndColumnPrism_uClamp.specPipeType = columnPosition.prismColumnPart.columnPipeSample.specPipeType;
    this.girthWidthAndColumnPrism_uClamp.specCrossSize1 = columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
    this.girthWidthAndColumnPrism_uClamp.specCrossSize2 = this.lengthGirthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [바닥도리 + 기둥] 용접 <- 공통 샘플
   */
  public algorithmSpec_girth_welding(): void {}

  /**
   * 규격 알고리즘: [바닥도리] 나사 <- 공통 샘플
   */
  public algorithmSpec_girth_screw(): void {}
}
