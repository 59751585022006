import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDripBarb } from "vhows-design/src/object/design/item/list/ItemDripBarb";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemMiniSprinkler } from "vhows-design/src/object/design/item/list/ItemMiniSprinkler";
import { ItemMiniSprinklerDropGuard } from "vhows-design/src/object/design/item/list/ItemMiniSprinklerDropGuard";
import { ItemMiniSprinklerTubeSet } from "vhows-design/src/object/design/item/list/ItemMiniSprinklerTubeSet";
import { ItemWateringConnect } from "vhows-design/src/object/design/item/list/ItemWateringConnect";
import { ItemWateringHanger } from "vhows-design/src/object/design/item/list/ItemWateringHanger";
import { ItemWateringOther } from "vhows-design/src/object/design/item/list/ItemWateringOther";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringValve } from "vhows-design/src/object/design/item/list/ItemWateringValve";
import { Part } from "vhows-design/src/object/design/base/Part";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WateringSample_MiniklerFountainSubPePipe } from "vhows-design/src/object/design/watering/watering/WateringSample_MiniklerFountainSubPePipe";
import { WateringSample_MiniklerFountainMiniSprinkler } from "vhows-design/src/object/design/watering/watering/WateringSample_MiniklerFountainMiniSprinkler";
import { WateringSample_MiniklerFountainTubeSet } from "vhows-design/src/object/design/watering/watering/WateringSample_MiniklerFountainTubeSet";
import { WateringSample_MiniklerFountainPePipe } from "vhows-design/src/object/design/watering/watering/WateringSample_MiniklerFountainPePipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [
    WateringSample_MiniklerFountainMiniSprinkler,
    WateringSample_MiniklerFountainTubeSet,
    WateringSample_MiniklerFountainPePipe,
    WateringSample_MiniklerFountainSubPePipe,
  ],
})
export class WateringPart_MiniklerFountain extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public miniSprinklerSample: WateringSample_MiniklerFountainMiniSprinkler; // 미니 스피링클러 샘플
  public miniklerTubeSetSample: WateringSample_MiniklerFountainTubeSet; // 미니클러 튜브 셋 샘플
  public miniklerPePipeSample: WateringSample_MiniklerFountainPePipe; // 미니클러관 샘플
  public subPePipeSample: WateringSample_MiniklerFountainSubPePipe; // 보조관 샘플

  // 아이템
  public minikler_miniSprinkler: ItemMiniSprinkler;
  public minikler_miniSprinklerTubeSet: ItemMiniSprinklerTubeSet;
  public minikler_miniSprinklerDropGuard: ItemMiniSprinklerDropGuard;
  public miniklerPipe_pePipe: ItemWateringPipe;
  public miniklerPipe_connectSocket: ItemWateringConnect;
  public miniklerPipe_pePipeEnd: ItemWateringConnect;
  public miniklerPipe_valveSocket: ItemWateringConnect;
  public miniklerHangerGrabber_steelStringGrabber: ItemHolder;
  public miniklerHangerHanger_pePipeHanger: ItemWateringHanger;
  public miniklerHangerHanger_dripStartSaddle: ItemDripBarb;
  public miniklerHangerHanger_wateringSpring: ItemWateringOther;
  public sub_pePipe: ItemWateringPipe;
  public subAndMain_exactT: ItemWateringConnect;
  public subAndMain_differentT: ItemWateringConnect;
  public sub_unionValve: ItemWateringValve;
  public sub_elbow: ItemWateringConnect;
  public sub_differentConnectSocket: ItemWateringConnect;
  public sub_valveSocket: ItemWateringConnect;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wateringBuildingNumber: number = 0; // 관수 동수
  @jsonMember(Number)
  public _miniklerPipeLineNumber: number; // 미니클러관 줄수
  @jsonMember(Number)
  public _miniklerPipeLength: number = 0; // 미니클러관 길이
  @jsonMember(Number)
  public _miniklerInterval: number; // 미니 스프링클러 간격
  @jsonMember(String)
  public _miniklerPipeFixType: string; // 미니클러관 고정 형태
  @jsonMember(Number)
  public _subPipeLength: number = 0; // 보조관 길이
  @jsonMember(Boolean)
  public _dropGuardSelected: boolean; // 낙수방지기 선택

  /**
   * 관수 동수
   */
  public get wateringBuildingNumber(): number {
    return this._wateringBuildingNumber;
  }

  //
  public set wateringBuildingNumber(value: number) {
    this._wateringBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 미니클러관 줄수
   */
  public get miniklerPipeLineNumber(): number {
    return this._miniklerPipeLineNumber;
  }

  //
  public set miniklerPipeLineNumber(value: number) {
    this._miniklerPipeLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_subPipeLength();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 관수 제어부
    this.struct.mainpipeWork.level1.wholePosition.upperFramePart.algorithm_wateringFixLineNumber();
  }

  /**
   * 미니클러관 길이
   */
  public get miniklerPipeLength(): number {
    return this._miniklerPipeLength;
  }

  //
  public set miniklerPipeLength(value: number) {
    this._miniklerPipeLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 미니 스프링클러 간격
   */
  public get miniklerInterval(): number {
    return this._miniklerInterval;
  }

  //
  public set miniklerInterval(value: number) {
    this._miniklerInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 미니클러관 고정 형태
   */
  public get miniklerPipeFixType(): string {
    return this._miniklerPipeFixType;
  }

  //
  public set miniklerPipeFixType(value: string) {
    this._miniklerPipeFixType = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 보조관 길이
   */
  public get subPipeLength(): number {
    return this._subPipeLength;
  }

  //
  public set subPipeLength(value: number) {
    this._subPipeLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 낙수방지기 선택
   */
  public get dropGuardSelected(): boolean {
    return this._dropGuardSelected;
  }

  //
  public set dropGuardSelected(value: boolean) {
    this._dropGuardSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.miniSprinklerSample = new WateringSample_MiniklerFountainMiniSprinkler();
    this.miniklerTubeSetSample = new WateringSample_MiniklerFountainTubeSet();
    this.miniklerPePipeSample = new WateringSample_MiniklerFountainPePipe();
    this.subPePipeSample = new WateringSample_MiniklerFountainSubPePipe();

    this.sampleAC = [
      this.miniSprinklerSample,
      this.miniklerTubeSetSample,
      this.miniklerPePipeSample,
      this.subPePipeSample,
    ];

    // 아이템
    this.minikler_miniSprinkler = new ItemMiniSprinkler();
    this.minikler_miniSprinklerTubeSet = new ItemMiniSprinklerTubeSet();
    this.minikler_miniSprinklerDropGuard = new ItemMiniSprinklerDropGuard();
    this.miniklerPipe_pePipe = new ItemWateringPipe();
    this.miniklerPipe_connectSocket = new ItemWateringConnect();
    this.miniklerPipe_pePipeEnd = new ItemWateringConnect();
    this.miniklerPipe_valveSocket = new ItemWateringConnect();
    this.miniklerHangerGrabber_steelStringGrabber = new ItemHolder();
    this.miniklerHangerHanger_pePipeHanger = new ItemWateringHanger();
    this.miniklerHangerHanger_dripStartSaddle = new ItemDripBarb();
    this.miniklerHangerHanger_wateringSpring = new ItemWateringOther();
    this.sub_pePipe = new ItemWateringPipe();
    this.subAndMain_exactT = new ItemWateringConnect();
    this.subAndMain_differentT = new ItemWateringConnect();
    this.sub_unionValve = new ItemWateringValve();
    this.sub_elbow = new ItemWateringConnect();
    this.sub_differentConnectSocket = new ItemWateringConnect();
    this.sub_valveSocket = new ItemWateringConnect();

    this.itemAC = [
      this.minikler_miniSprinkler,
      this.minikler_miniSprinklerTubeSet,
      this.minikler_miniSprinklerDropGuard,
      this.miniklerPipe_pePipe,
      this.miniklerPipe_connectSocket,
      this.miniklerPipe_pePipeEnd,
      this.miniklerPipe_valveSocket,
      this.miniklerHangerGrabber_steelStringGrabber,
      this.miniklerHangerHanger_pePipeHanger,
      this.miniklerHangerHanger_dripStartSaddle,
      this.miniklerHangerHanger_wateringSpring,
      this.sub_pePipe,
      this.subAndMain_exactT,
      this.subAndMain_differentT,
      this.sub_unionValve,
      this.sub_elbow,
      this.sub_differentConnectSocket,
      this.sub_valveSocket,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.miniSprinklerSample = <WateringSample_MiniklerFountainMiniSprinkler>this.sampleAC[0];
    this.miniklerTubeSetSample = <WateringSample_MiniklerFountainTubeSet>this.sampleAC[1];
    this.miniklerPePipeSample = <WateringSample_MiniklerFountainPePipe>this.sampleAC[2];
    this.subPePipeSample = <WateringSample_MiniklerFountainSubPePipe>this.sampleAC[3];
    // 아이템
    this.minikler_miniSprinkler = <ItemMiniSprinkler>this.itemAC[0];
    this.minikler_miniSprinklerTubeSet = <ItemMiniSprinklerTubeSet>this.itemAC[1];
    this.minikler_miniSprinklerDropGuard = <ItemMiniSprinklerDropGuard>this.itemAC[2];
    this.miniklerPipe_pePipe = <ItemWateringPipe>this.itemAC[3];
    this.miniklerPipe_connectSocket = <ItemWateringConnect>this.itemAC[4];
    this.miniklerPipe_pePipeEnd = <ItemWateringConnect>this.itemAC[5];
    this.miniklerPipe_valveSocket = <ItemWateringConnect>this.itemAC[6];
    this.miniklerHangerGrabber_steelStringGrabber = <ItemHolder>this.itemAC[7];
    this.miniklerHangerHanger_pePipeHanger = <ItemWateringHanger>this.itemAC[8];
    this.miniklerHangerHanger_dripStartSaddle = <ItemDripBarb>this.itemAC[9];
    this.miniklerHangerHanger_wateringSpring = <ItemWateringOther>this.itemAC[10];
    this.sub_pePipe = <ItemWateringPipe>this.itemAC[11];
    this.subAndMain_exactT = <ItemWateringConnect>this.itemAC[12];
    this.subAndMain_differentT = <ItemWateringConnect>this.itemAC[13];
    this.sub_unionValve = <ItemWateringValve>this.itemAC[14];
    this.sub_elbow = <ItemWateringConnect>this.itemAC[15];
    this.sub_differentConnectSocket = <ItemWateringConnect>this.itemAC[16];
    this.sub_valveSocket = <ItemWateringConnect>this.itemAC[17];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param miniklerPipeLineNumber: number 미니클러관 줄수
   * @param miniklerInterval: number 미니 스프링클러 간격
   * @param miniklerPipeFixType: string 미니클러관 고정 형태
   * @param dropGuardSelected: boolean 낙수방지기 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    miniklerPipeLineNumber: number = 0,
    miniklerInterval: number = 0,
    miniklerPipeFixType: string = "",
    dropGuardSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._miniklerPipeLineNumber = miniklerPipeLineNumber;
    this._miniklerInterval = miniklerInterval;
    this._miniklerPipeFixType = miniklerPipeFixType;
    this._dropGuardSelected = dropGuardSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.miniSprinklerSample.setDefaultData(
        0,
        CONST.ITEM_ID_MINI_SPRINKLER,
        CONST.ITEM_NAME_MINI_SPRINKLER,
        "분수 미니클러",
        "일반",
        "네타핌, 스핀넷, 일반, 120L/H, 5~7m",
      );
      this.miniklerTubeSetSample.setDefaultData(
        1,
        CONST.ITEM_ID_MINI_SPRINKLER_TUBE_SET,
        CONST.ITEM_NAME_MINI_SPRINKLER_TUBE_SET,
        "분수 미니클러",
        "일반, 4/7mm, 50cm, 엘디, 네타핌",
      );
      this.miniklerPePipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "미니클러관",
        "연질관, 30mm, 100m",
      );
      this.subPePipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "미니클러관",
        "연질관, 30mm, 100m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.minikler_miniSprinkler.setDefaultData(
        0,
        CONST.ITEM_ID_MINI_SPRINKLER,
        CONST.ITEM_NAME_MINI_SPRINKLER,
        "분수 미니클러",
        "일반",
        "네타핌, 스핀넷, 일반, 120L/H, 5~7m",
      );
      this.minikler_miniSprinklerTubeSet.setDefaultData(
        1,
        CONST.ITEM_ID_MINI_SPRINKLER_TUBE_SET,
        CONST.ITEM_NAME_MINI_SPRINKLER_TUBE_SET,
        "분수 미니클러",
        "일반, 4/7mm, 50cm, 엘디, 네타핌",
      );
      this.minikler_miniSprinklerDropGuard.setDefaultData(
        2,
        CONST.ITEM_ID_MINI_SPRINKLER_DROP_GUARD,
        CONST.ITEM_NAME_MINI_SPRINKLER_DROP_GUARD,
        "분수 미니클러",
        "일반",
        "네타핌, ANTILEAK",
      );
      this.miniklerPipe_pePipe.setDefaultData(
        3,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "미니클러관",
        "연질관, 30mm, 100m",
      );
      this.miniklerPipe_connectSocket.setDefaultData(
        4,
        CONST.ITEM_ID_CONNECT_SOCKET,
        CONST.ITEM_NAME_CONNECT_SOCKET,
        "미니클러관",
        "PE, 일반, 30mm, 30mm",
      );
      this.miniklerPipe_pePipeEnd.setDefaultData(
        5,
        CONST.ITEM_ID_PE_PIPE_END,
        CONST.ITEM_NAME_PE_PIPE_END,
        "미니클러관",
        "PE, 앤드캡, 30mm, -",
      );
      this.miniklerPipe_valveSocket.setDefaultData(
        6,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "미니클러관",
        "PE, 일반, 30mm, -",
      );
      this.miniklerHangerGrabber_steelStringGrabber.setDefaultData(
        7,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "미니클러관 고정",
        "원형, 일반, 42mm, 25mm",
      );
      this.miniklerHangerHanger_pePipeHanger.setDefaultData(
        8,
        CONST.ITEM_ID_PE_PIPE_HANGER,
        CONST.ITEM_NAME_PE_PIPE_HANGER,
        "미니클러관 고정",
        "철사고리, 10cm, -, -",
      );
      this.miniklerHangerHanger_dripStartSaddle.setDefaultData(
        9,
        CONST.ITEM_ID_DRIP_START_SADDLE,
        CONST.ITEM_NAME_DRIP_START_SADDLE,
        "미니클러관 고정",
        "일반, 1구, 30mm, 13mm",
      );
      this.miniklerHangerHanger_wateringSpring.setDefaultData(
        10,
        CONST.ITEM_ID_WATERING_SPRING,
        CONST.ITEM_NAME_WATERING_SPRING,
        "미니클러관 고정",
        "일반",
      );
      this.sub_pePipe.setDefaultData(
        11,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "보조관(주관~미니클러관)",
        "연질관, 30mm, 100m",
      );
      this.subAndMain_exactT.setDefaultData(
        12,
        CONST.ITEM_ID_EXACT_T,
        CONST.ITEM_NAME_EXACT_T,
        "보조관(주관~미니클러관)",
        "PE, 소켓, 30mm, 30mm",
      );
      this.subAndMain_differentT.setDefaultData(
        13,
        CONST.ITEM_ID_DIFFERENT_T,
        CONST.ITEM_NAME_DIFFERENT_T,
        "보조관(주관~미니클러관)",
        "PE, 소켓, 50mm, 30mm",
      );
      this.sub_unionValve.setDefaultData(
        14,
        CONST.ITEM_ID_UNION_VALVE,
        CONST.ITEM_NAME_UNION_VALVE,
        "보조관(주관~미니클러관)",
        "PE, 일반, 30mm, 30mm",
      );
      this.sub_elbow.setDefaultData(
        15,
        CONST.ITEM_ID_ELBOW,
        CONST.ITEM_NAME_ELBOW,
        "보조관(주관~미니클러관)",
        "PE, 소켓, 30mm, 30mm",
      );
      this.sub_differentConnectSocket.setDefaultData(
        16,
        CONST.ITEM_ID_DIFFERENT_CONNECT_SOCKET,
        CONST.ITEM_NAME_DIFFERENT_CONNECT_SOCKET,
        "보조관(주관~미니클러관)",
        "PE, 일반, 30mm, 20mm",
      );
      this.sub_valveSocket.setDefaultData(
        17,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "보조관(주관~미니클러관)",
        "PE, 일반, 30mm, -",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_miniklerPipeLength();
    this.algorithm_subPipeLength();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    this.algorithmSpec_miniklerHangerHanger_pePipeHanger();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;

    /// //////// 선택, 가시성 ///////////

    this.minikler_miniSprinkler.selected = true;
    this.minikler_miniSprinkler.visible = true;
    this.minikler_miniSprinklerTubeSet.selected = true;
    this.minikler_miniSprinklerTubeSet.visible = true;
    this.minikler_miniSprinklerDropGuard.selected = this.dropGuardSelected;
    this.minikler_miniSprinklerDropGuard.visible = this.dropGuardSelected;
    this.miniklerPipe_pePipe.selected = true;
    this.miniklerPipe_pePipe.visible = true;
    this.miniklerPipe_connectSocket.selected = true;
    this.miniklerPipe_connectSocket.visible = true;
    this.miniklerPipe_pePipeEnd.selected = true;
    this.miniklerPipe_pePipeEnd.visible = true;
    this.miniklerPipe_valveSocket.selected = true;
    this.miniklerPipe_valveSocket.visible = true;
    if (this.miniklerPipeFixType === CONST.LB_WATERING_MINIKLER_PIPE_FIX_TYPE_GRABBER) {
      this.miniklerHangerGrabber_steelStringGrabber.selected = true;
      this.miniklerHangerGrabber_steelStringGrabber.visible = true;
      this.miniklerHangerHanger_pePipeHanger.selected = false;
      this.miniklerHangerHanger_pePipeHanger.visible = false;
      this.miniklerHangerHanger_dripStartSaddle.selected = false;
      this.miniklerHangerHanger_dripStartSaddle.visible = false;
      this.miniklerHangerHanger_wateringSpring.selected = false;
      this.miniklerHangerHanger_wateringSpring.visible = false;
    } else if (this.miniklerPipeFixType === CONST.LB_WATERING_MINIKLER_PIPE_FIX_TYPE_HANGER_SPRING) {
      this.miniklerHangerGrabber_steelStringGrabber.selected = false;
      this.miniklerHangerGrabber_steelStringGrabber.visible = false;
      this.miniklerHangerHanger_pePipeHanger.selected = true;
      this.miniklerHangerHanger_pePipeHanger.visible = true;
      this.miniklerHangerHanger_dripStartSaddle.selected = true;
      this.miniklerHangerHanger_dripStartSaddle.visible = true;
      this.miniklerHangerHanger_wateringSpring.selected = true;
      this.miniklerHangerHanger_wateringSpring.visible = true;
    }
    this.sub_pePipe.selected = true;
    this.sub_pePipe.visible = true;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      if (this.subPePipeSample.specDiameter === mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter) {
        this.subAndMain_exactT.selected = true;
        this.subAndMain_exactT.visible = true;
        this.subAndMain_differentT.selected = false;
        this.subAndMain_differentT.visible = false;
      } else {
        this.subAndMain_exactT.selected = false;
        this.subAndMain_exactT.visible = false;
        this.subAndMain_differentT.selected = true;
        this.subAndMain_differentT.visible = true;
      }
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.subAndMain_exactT.selected = false;
      this.subAndMain_exactT.visible = false;
      this.subAndMain_differentT.selected = false;
      this.subAndMain_differentT.visible = false;
    }
    this.sub_unionValve.selected = true;
    this.sub_unionValve.visible = true;
    this.sub_elbow.selected = true;
    this.sub_elbow.visible = true;
    if (this.subPePipeSample.specDiameter !== this.miniklerPePipeSample.specDiameter) {
      this.sub_differentConnectSocket.selected = true;
      this.sub_differentConnectSocket.visible = true;
    } else {
      this.sub_differentConnectSocket.selected = false;
      this.sub_differentConnectSocket.visible = false;
    }
    this.sub_valveSocket.selected = true;
    this.sub_valveSocket.visible = true;

    /// ////////  수량 ///////////

    this.minikler_miniSprinkler.designQuantity =
      Math.ceil(this.miniklerPipeLength / this.miniklerInterval) *
      this.miniklerPipeLineNumber *
      this.wateringBuildingNumber;

    this.minikler_miniSprinklerTubeSet.designQuantity = this.minikler_miniSprinkler.designQuantity;

    this.minikler_miniSprinklerDropGuard.designQuantity = this.minikler_miniSprinkler.designQuantity;

    this.miniklerPipe_pePipe.designQuantity =
      (this.miniklerPipeLength * this.miniklerPipeLineNumber * this.wateringBuildingNumber) /
      this.miniklerPipe_pePipe.specLength;

    // miniklerPipe_connectSocket.designQuantity = 0;

    this.miniklerPipe_pePipeEnd.designQuantity = this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.miniklerPipe_valveSocket.designQuantity = this.miniklerPipe_pePipeEnd.getSelectedQuantity();

    this.miniklerHangerGrabber_steelStringGrabber.designQuantity =
      Math.ceil(this.miniklerPipeLength + 1) * this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.miniklerHangerHanger_pePipeHanger.designQuantity =
      Math.ceil(this.miniklerPipeLength + 1) * this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.miniklerHangerHanger_dripStartSaddle.designQuantity =
      2 * this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.miniklerHangerHanger_wateringSpring.designQuantity =
      4 * this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.sub_pePipe.designQuantity =
      (this.subPipeLength * this.miniklerPipeLineNumber * this.wateringBuildingNumber) / this.sub_pePipe.specLength;

    this.subAndMain_exactT.designQuantity = this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.subAndMain_differentT.designQuantity = this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.sub_unionValve.designQuantity = this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.sub_elbow.designQuantity = this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    this.sub_differentConnectSocket.designQuantity = this.miniklerPipeLineNumber * this.wateringBuildingNumber;

    // sub_valveSocket.designQuantity = 0;
  }

  /**
   * 알고리즘: 관수 동수 <- 관수 동수(관수 제어부)
   */
  public algorithm_wateringBuildingNumber(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    this.wateringBuildingNumber = mainpipePosition.wateringBuildingNumber;
  }

  /**
   * 알고리즘: 미니클러관 길이 <- 기본정보
   */
  public algorithm_miniklerPipeLength(): void {
    this.miniklerPipeLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].length;
  }

  /**
   * 알고리즘: 보조관 길이 <- 기본정보, 형태(주관), 주관2 위치(주관), 미니클러관 줄수
   */
  public algorithm_subPipeLength(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      if (mainpipePosition.peMain2Part.main2Position === CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_LOWER) {
        this.subPipeLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].heightShoulder + 1;
      } else if (mainpipePosition.peMain2Part.main2Position === CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_UPPER) {
        this.subPipeLength = 2.5;
      }
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.subPipeLength = 2.5;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [분수 미니클러] 미니 스프링클러 <- 미니 스피링클러 샘플
   */
  public algorithmSpec_minikler_miniSprinkler(): void {
    this.minikler_miniSprinkler.specs = this.miniSprinklerSample.specs;
    this.minikler_miniSprinkler.brands = this.miniSprinklerSample.brands;
  }

  /**
   * 규격 알고리즘: [분수 미니클러] 미니클러 튜브셋 <- 미니클러 튜브셋 샘플
   */
  public algorithmSpec_minikler_miniSprinklerTubeSet(): void {
    this.minikler_miniSprinklerTubeSet.specs = this.miniklerTubeSetSample.specs;
  }

  /**
   * 규격 알고리즘: [분수 미니클러] 미니클러 낙수방지기 <- 미니 스피링클러 샘플
   */
  public algorithmSpec_minikler_miniSprinklerDropGuard(): void {
    // 하드코딩
    if (this.miniSprinklerSample.brandCompany === CONST.ITEM_BRAND_VALUE_NETAPIM) {
      this.minikler_miniSprinklerDropGuard.brands = "네타핌, ANTILEAK";
    } else if (this.miniSprinklerSample.brandCompany === CONST.ITEM_BRAND_VALUE_DAN) {
      this.minikler_miniSprinklerDropGuard.brands = "DAN, LPD";
    }
  }

  /**
   * 규격 알고리즘: [미니클러관] PE관 <- 미니클러관 샘플
   */
  public algorithmSpec_miniklerPipe_pePipe(): void {
    this.miniklerPipe_pePipe.specs = this.miniklerPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [미니클러관] 연결 소켓 <- 미니클러관 샘플
   */
  public algorithmSpec_miniklerPipe_connectSocket(): void {
    this.miniklerPipe_connectSocket.specDiameter1 = this.miniklerPePipeSample.specDiameter;
    this.miniklerPipe_connectSocket.specDiameter2 = this.miniklerPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [미니클러관] 관 마감 <- 미니클러관 샘플
   */
  public algorithmSpec_miniklerPipe_pePipeEnd(): void {
    this.miniklerPipe_pePipeEnd.specDiameter1 = this.miniklerPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [미니클러관] 밸브 소켓 <- 미니클러관 샘플
   */
  public algorithmSpec_miniklerPipe_valveSocket(): void {
    this.miniklerPipe_valveSocket.specDiameter1 = this.miniklerPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [미니클러관 고정] 강선 조리개 <- 미니클러관 샘플, 지붕 서까래 파이프 샘플(단동,연동,벤로)
   */
  public algorithmSpec_miniklerHangerGrabber_steelStringGrabber(): void {
    this.miniklerHangerGrabber_steelStringGrabber.specCrossSize1 = this.miniklerPePipeSample.specCrossSize;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.basic.levelNumber - 1])
        .trunkPosition; // 안쪽중
      this.miniklerHangerGrabber_steelStringGrabber.specCrossSize2 =
        trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      // 설치 불가능
      const roofPosition: RoofPositionIL = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.basic.levelNumber - 1])
        .roofPosition; // 안쪽중
      this.miniklerHangerGrabber_steelStringGrabber.specCrossSize2 =
        roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 설치 불가능
      this.miniklerHangerGrabber_steelStringGrabber.specCrossSize2 =
        this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [미니클러관 고정] 관 걸이 <- 미니클러관 샘플, 지붕 가로대 파이프 샘플(단동,연동,벤로)
   */
  public algorithmSpec_miniklerHangerHanger_pePipeHanger(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.basic.levelNumber - 1])
        .trunkPosition; // 안쪽중
      this.miniklerHangerHanger_pePipeHanger.specCrossSize =
        trunkPosition.straightPart.straightRoofSubPipeSample.specCrossSize;
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
      this.miniklerHangerHanger_pePipeHanger.specCrossSize =
        mainpipePosition.upperFramePart.wateringFixPipeSample.specCrossSize;
    }
    this.miniklerHangerHanger_pePipeHanger.specDiameter = this.miniklerPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [미니클러관 고정] 점적 새들 <- 미니클러관 샘플
   */
  public algorithmSpec_miniklerHangerHanger_dripStartSaddle(): void {
    this.miniklerHangerHanger_dripStartSaddle.specDiameter1 = this.miniklerPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [미니클러관 고정] 관 스프링 <- 없음
   */
  public algorithmSpec_miniklerHangerHanger_wateringSpring(): void {}

  /**
   * 규격 알고리즘: [보조관(주관~미니클러관)] PE관 <- 보조관 샘플
   */
  public algorithmSpec_sub_pePipe(): void {
    this.sub_pePipe.specs = this.subPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [보조관(주관~미니클러관)] 정티 <- 보조관 샘플
   */
  public algorithmSpec_subAndMain_exactT(): void {
    this.subAndMain_exactT.specDiameter1 = this.subPePipeSample.specDiameter;
    this.subAndMain_exactT.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~미니클러관)] 이경티 <- 보조관 샘플, 중앙 주관2 샘플, 개별 주관2 샘플, 형태(주관)
   */
  public algorithmSpec_subAndMain_differentT(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.subAndMain_differentT.specDiameter1 = mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.subAndMain_differentT.specDiameter1 = mainpipePosition.peMain2Part.main2EachPePipeSample.specDiameter;
    }
    this.subAndMain_differentT.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~미니클러관)] 유니온 밸브 <- 보조관 샘플
   */
  public algorithmSpec_sub_unionValve(): void {
    this.sub_unionValve.specDiameter1 = this.subPePipeSample.specDiameter;
    this.sub_unionValve.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~미니클러관)] 엘보 <- 보조관 샘플
   */
  public algorithmSpec_sub_elbow(): void {
    this.sub_elbow.specDiameter1 = this.subPePipeSample.specDiameter;
    this.sub_elbow.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~미니클러관)] 이경 연결 소켓 <- 보조관 샘플, 미니클러관 샘플
   */
  public algorithmSpec_sub_differentConnectSocket(): void {
    if (this.subPePipeSample.specDiameter !== this.miniklerPePipeSample.specDiameter) {
      this.sub_differentConnectSocket.specDiameter1 = this.subPePipeSample.specDiameter;
      this.sub_differentConnectSocket.specDiameter2 = this.miniklerPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [보조관(주관~미니클러관)] 밸브 소켓 <- 보조관 샘플
   */
  public algorithmSpec_sub_valveSocket(): void {
    this.sub_valveSocket.specDiameter1 = this.subPePipeSample.specDiameter;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
