import { jsonObject } from "typedjson";

import { ItemNonwoven } from "vhows-design/src/object/design/item/list/ItemNonwoven";
import { CoverPart_Nonwoven } from "vhows-design/src/object/design/cover/cover/CoverPart_Nonwoven";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-07
 */
@jsonObject
export class CoverSample_Nonwoven extends ItemNonwoven {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const nonwovenPart: CoverPart_Nonwoven = <CoverPart_Nonwoven>this.part;
    nonwovenPart.algorithmSpec_item();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
