import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 문짝(기성품)
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemDoorMade extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, (폭), (길이)], [롤러크기]
  public _specFeature: string = undefined; // 특성
  public _specWidth: number = undefined; // 폭
  public _specLength: number = undefined; // 길이
  public _specRollerSize: string = undefined; // 롤러크기

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭
   */
  public get specWidth(): number {
    return this._specWidth;
  }
  //
  public set specWidth(value: number) {
    // 일반화된 간격 적용 - 100cm 간격(둘째자리 올림)
    this._specWidth = CommonUtil.roundUp(value, -2);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭 레이블(값+단위기호)
   */
  public get specWidthLabel(): string {
    return this.specWidth + CONST.UNIT_MILLIMETER;
  }
  //
  public set specWidthLabel(value: string) {
    this.specWidth = parseFloat(value);
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 100cm 간격(둘째자리 올림)
    this._specLength = CommonUtil.roundUp(value, -2);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_MILLIMETER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  /**
   * 롤러 크기
   */
  public get specRollerSize(): string {
    return this._specRollerSize;
  }
  //
  public set specRollerSize(value: string) {
    this._specRollerSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.doorMadeCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specWidthLabel = specsArray[1];
    this.specLengthLabel = specsArray[2];
    this.specRollerSize = specsArray[3];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specWidthLabel = specsArray[1];
    this.specLengthLabel = specsArray[2];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specFeature + CONST.DELIMITER_SPEC + this.specWidthLabel + CONST.DELIMITER_SPEC + this.specLengthLabel;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList + CONST.DELIMITER_SPEC + this.specRollerSize;
  }
}
