import { jsonObject } from "typedjson";

import { ItemSkylightMotor } from "vhows-design/src/object/design/item/list/ItemSkylightMotor";
import { SkySwitchPart_Rackpinion } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_Rackpinion";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-04-15
 */
@jsonObject
export class SkySwitchSample_SkylightMotor extends ItemSkylightMotor {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const skylightMotorPart: SkySwitchPart_Rackpinion = <SkySwitchPart_Rackpinion>this.part;
    skylightMotorPart.algorithmSpec_rackpinion_skylightMotor();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
