import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkyCoverPosition } from "vhows-design/src/object/design/skylight/cover/SkyCoverPosition";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { SkySwitchPart_Rackpinion } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_Rackpinion";
import { SkySwitchPart_SupDiagonal } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_SupDiagonal";
import { SkySwitchPart_Axis } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_Axis";
import { SkySwitchPart_FixingNormalClip } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_FixingNormalClip";
import { SkySwitchPart_Manual } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_Manual";
import { SkySwitchPart_Power } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_Power";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-18
 */
@jsonObject({
  knownTypes: [
    SkySwitchPart_Axis,
    SkySwitchPart_FixingNormalClip,
    SkySwitchPart_Manual,
    SkySwitchPart_Power,
    SkySwitchPart_Rackpinion,
    SkySwitchPart_SupDiagonal,
  ],
})
export class SkySwitchPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public manualPart: SkySwitchPart_Manual;
  public powerPart: SkySwitchPart_Power;
  public rackpinionPart: SkySwitchPart_Rackpinion;
  public axisPart: SkySwitchPart_Axis;
  public supDiagonalPart: SkySwitchPart_SupDiagonal;
  public fixingNormalClipPart: SkySwitchPart_FixingNormalClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 개폐 방향값 <- 개폐 방향
   */
  public get switchWayValue(): number {
    if (this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH || this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
      return 2;
    } else if (
      this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE ||
      this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ON
    ) {
      return 1;
    }
    return 0;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _switchWay: string; // 개폐 방향
  @jsonMember(Number)
  public _switchHeight: number; // 개폐 높이
  @jsonMember(String)
  public _supportType: string; // 지지 형태
  @jsonMember(String)
  public _fixingType: string; // 고정(피복) 형태

  /**
   * 개폐 방향
   */
  public get switchWay(): string {
    return this._switchWay;
  }

  public set switchWay(value: string) {
    this._switchWay = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 제어기
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }

    // 천창 골조
    (<SkyFramePosition>this.struct.skyFrameWork.level1.positionAC[this.index]).algorithm_arcWidth();

    // 천창 피복
    (<SkyCoverPosition>this.struct.skyCoverWork.level1.positionAC[this.index]).screennetPart.algorithmPart();
    (<SkyCoverPosition>this.struct.skyCoverWork.level1.positionAC[this.index]).algorithm_lineNumber();
  }

  /**
   * 개폐 높이
   */
  public get switchHeight(): number {
    return this._switchHeight;
  }

  public set switchHeight(value: number) {
    this._switchHeight = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 천창 피복
    (<SkyCoverPosition>this.struct.skyCoverWork.level1.positionAC[this.index]).screennetPart.algorithmPart();
  }

  /**
   * 지지 형태
   */
  public get supportType(): string {
    return this._supportType;
  }

  public set supportType(value: string) {
    this._supportType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationBySupportType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 고정(피복) 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  //
  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();
    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationBySupportType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWork.level1.integratedPosition.algorithm_selectedBySwitcherByBasic();
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_supportType();
    this.algorithm_fixingType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWork.level1.integratedPosition.algorithm_selectedBySwitcherByBasic();
    this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.manualPart = new SkySwitchPart_Manual();
    this.powerPart = new SkySwitchPart_Power();
    this.rackpinionPart = new SkySwitchPart_Rackpinion();
    this.axisPart = new SkySwitchPart_Axis();
    this.supDiagonalPart = new SkySwitchPart_SupDiagonal();
    this.fixingNormalClipPart = new SkySwitchPart_FixingNormalClip();

    this.partAC = [
      this.manualPart,
      this.powerPart,
      this.rackpinionPart,
      this.axisPart,
      this.supDiagonalPart,
      this.fixingNormalClipPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.manualPart = <SkySwitchPart_Manual>this.partAC[0];
    this.powerPart = <SkySwitchPart_Power>this.partAC[1];
    this.rackpinionPart = <SkySwitchPart_Rackpinion>this.partAC[2];
    this.axisPart = <SkySwitchPart_Axis>this.partAC[3];
    this.supDiagonalPart = <SkySwitchPart_SupDiagonal>this.partAC[4];
    this.fixingNormalClipPart = <SkySwitchPart_FixingNormalClip>this.partAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param switchWay: string 개폐 방향
   * @param switchHeight: number 개폐 높이
   * @param supportType: string 지지 형태
   * @param fixingType: string 고정(피복) 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    switchWay: string = "",
    switchHeight: number = 0,
    supportType: string = "",
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._switchWay = switchWay;
    this._switchHeight = switchHeight;
    this._supportType = supportType;
    this._fixingType = fixingType;

    if (this.level.index >= 0) {
      // 천창 수동 개폐기
      this.manualPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_SWITCH_MANUAL,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_SWITCH_MANUAL,
        "",
      );

      // 천창 동력 개폐기
      this.powerPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_SWITCH_POWER,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_SWITCH_POWER,
        "",
      );

      // 천창 랙피니언 개폐
      this.rackpinionPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_RACKPINION,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_SWITCH_RACKPINION,
        "",
      );

      // 천창 개폐 축
      this.axisPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_SWITCH_AXIS,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_SWITCH_AXIS,
        "",
      );

      // 천창 개폐기 지지 - 대각형 지지
      this.supDiagonalPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_SWITCH_SUPPORT_DIAGONAL,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_SWITCH_SUPPORT_DIAGONAL,
        "",
        0.8,
      );

      // 천창 개폐기 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_SWITCH_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_SWITCH_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 5) {
      // 랙피니언
      const rackpinionPart: SkySwitchPart_Rackpinion = new SkySwitchPart_Rackpinion();
      rackpinionPart.setAssociation(design, struct, work, level, this);
      rackpinionPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_RACKPINION,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_SWITCH_RACKPINION,
        "",
      );
      this.partAC.splice(2, 0, rackpinionPart);
      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_switchType();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 선택 <- 선택(천창 골조), 형태(천창 골조)
   */
  public algorithm_selected(): void {
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_CUSTOM) {
      this.selected = false;
    } else {
      this.selected = skyFramePosition.selected;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.manualPart.selected = true;
      this.manualPart.visible = true;
      this.powerPart.selected = false;
      this.powerPart.visible = false;
      this.rackpinionPart.selected = false;
      this.rackpinionPart.visible = false;
      this.axisPart.selected = true;
      this.axisPart.visible = true;
    } else if (this.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
      this.powerPart.selected = true;
      this.powerPart.visible = true;
      this.manualPart.selected = false;
      this.manualPart.visible = false;
      this.rackpinionPart.selected = false;
      this.rackpinionPart.visible = false;
      this.axisPart.selected = true;
      this.axisPart.visible = true;
    } else if (this.type === CONST.LB_SWITCHER_TYPE_RACKPINION) {
      this.powerPart.selected = false;
      this.powerPart.visible = false;
      this.manualPart.selected = false;
      this.manualPart.visible = false;
      this.rackpinionPart.selected = true;
      this.rackpinionPart.visible = true;
      this.axisPart.selected = false;
      this.axisPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 지지 형태
   */
  public algorithm_partActivationBySupportType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL) {
      this.supDiagonalPart.selected = true;
      this.supDiagonalPart.visible = true;
    } else {
      this.supDiagonalPart.selected = false;
      this.supDiagonalPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
      this.fixingNormalClipPart.selected = true;
      this.fixingNormalClipPart.visible = true;
    } else {
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
    }
  }

  /**
   * 알고리즘: 천창 개폐 형태 <- 형태(천창 골조)
   */
  public algorithm_switchType(): void {
    // 외부
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF
    ) {
      this.type = CONST.LB_SWITCHER_TYPE_POWER_WRAP;
    } else if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.type = CONST.LB_SWITCHER_TYPE_RACKPINION;
    }
  }

  /**
   * 알고리즘: 개폐 방향 <- 형태(천창 골조)
   */
  public algorithm_switchWay(): void {
    // 외부
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH
    ) {
      this.switchWay = CONST.LB_SWITCHER_SWITCH_WAY_BOTH;
    } else if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.switchWay = CONST.LB_SWITCHER_SWITCH_WAY_ONE;
    }
  }

  /**
   * 알고리즘: 천창 개폐기 지지 형태 <- 천창 개폐 형태
   */
  public algorithm_supportType(): void {
    if (this.type === CONST.LB_SWITCHER_TYPE_MANUAL || this.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
      this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL;
    } else if (this.type === CONST.LB_SWITCHER_TYPE_RACKPINION) {
      this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE;
    }
  }

  /**
   * 알고리즘: 개폐기 피복 고정 형태 <- 천창 개폐 형태
   */
  public algorithm_fixingType(): void {
    if (this.type === CONST.LB_SWITCHER_TYPE_MANUAL || this.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
      this.fixingType = CONST.LB_FIXING_TYPE_NORMAL_CLIP;
    } else if (this.type === CONST.LB_SWITCHER_TYPE_RACKPINION) {
      this.fixingType = CONST.LB_FIXING_TYPE_NONE;
    }
  }

  /**
   * 알고리즘: 개폐 높이 <- 형태(천창 골조)
   */
  public algorithm_switchHeight(): void {
    // 외부
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
    ) {
      this.switchHeight = 0.6;
    } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.switchHeight = 0.8;
    } else if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.switchHeight = 1.0;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
