import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemBearing } from "vhows-design/src/object/design/item/list/ItemBearing";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { CurSwitchSampleIL_AxisPipe } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_AxisPipe";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { ColumnPartIL_PrismColumn } from "vhows-design/src/object/design/frame/column/ColumnPartIL_PrismColumn";
import { ColumnPartVL_Column } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Column";
import { CurSwitchPartIL_FrameVL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_FrameVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurSwitchSampleIL_AxisPipe],
})
export class CurSwitchPartIL_Axis extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public axisPipeSample: CurSwitchSampleIL_AxisPipe; // 커튼 개폐 축 파이프 샘플

  // 아이템
  public axis_pipe: ItemPipe;
  public axis_pinnedPipe: ItemPipe; // 사용 안함
  public axis_connectionPin: ItemConnector;
  public axisHolder_bearingSet: ItemBearing;
  public axisHolder_saddleHolder: ItemHolder;
  public coverFixing_pipe: ItemPipe;
  public coverFixing_connectionPin: ItemConnector;
  public axis_screw: ItemScrew;
  public axis_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _axisLength: number; // 개폐 축 길이
  @jsonMember(String)
  public _axisHolder: string; // 예인식 개폐 축 고정구
  @jsonMember(String)
  public _fixingType: string; // 예인식 커튼 고정 형태

  /**
   * 개폐 축 길이
   */
  public get axisLength(): number {
    return this._axisLength;
  }

  public set axisLength(value: number) {
    this._axisLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 예인식 개폐 축 고정구
   */
  public get axisHolder(): string {
    return this._axisHolder;
  }

  public set axisHolder(value: string) {
    this._axisHolder = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 예인식 커튼 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘
    this.algorithmPart();

    // 외부
    if (this.position.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
      for (const curCoverPart of (this.struct.curCoverWorkIL.level1 as CurCoverLevelIL).curCoverPosition.partAC) {
        curCoverPart.algorithmPart();
      }
      (this.position as CurSwitchPositionIL).fixingClipSidePart.algorithmPart();
    }
    // if (this.position.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
    //   for (const curCoverPart of (this.struct.curCoverWorkIL.levelAC[this.level.index] as CurCoverLevelIL).curCoverPosition.partAC) {
    //     curCoverPart.algorithmPart();
    //   }
    //   (this.position as CurSwitchPositionIL).fixingClipSidePart.algorithmPart();
    // }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.axisPipeSample = new CurSwitchSampleIL_AxisPipe();

    this.sampleAC = [this.axisPipeSample];

    // 아이템
    this.axis_pipe = new ItemPipe();
    this.axis_pinnedPipe = new ItemPipe();
    this.axis_connectionPin = new ItemConnector();
    this.axisHolder_bearingSet = new ItemBearing();
    this.axisHolder_saddleHolder = new ItemHolder();
    this.coverFixing_pipe = new ItemPipe();
    this.coverFixing_connectionPin = new ItemConnector();
    this.axis_screw = new ItemScrew();
    this.axis_welding = new ItemOtherExpense();

    this.itemAC = [
      this.axis_pipe,
      this.axis_pinnedPipe,
      this.axis_connectionPin,
      this.axisHolder_bearingSet,
      this.axisHolder_saddleHolder,
      this.coverFixing_pipe,
      this.coverFixing_connectionPin,
      this.axis_screw,
      this.axis_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.axisPipeSample = <CurSwitchSampleIL_AxisPipe>this.sampleAC[0];

    // 아이템
    this.axis_pipe = <ItemPipe>this.itemAC[0];
    this.axis_pinnedPipe = <ItemPipe>this.itemAC[1];
    this.axis_connectionPin = <ItemConnector>this.itemAC[2];
    this.axisHolder_bearingSet = <ItemBearing>this.itemAC[3];
    this.axisHolder_saddleHolder = <ItemHolder>this.itemAC[4];
    this.coverFixing_pipe = <ItemPipe>this.itemAC[5];
    this.coverFixing_connectionPin = <ItemConnector>this.itemAC[6];
    this.axis_screw = <ItemScrew>this.itemAC[7];
    this.axis_welding = <ItemOtherExpense>this.itemAC[8];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param axisHolder: string 예인식 개폐 축 고정구
   * @param fixingType: string 예인식 커튼 고정 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    axisHolder: string = "",
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._axisHolder = axisHolder;
    this._fixingType = fixingType;

    // 샘플
    if (this.level.index >= 0) {
      this.axisPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 개폐 축",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.axis_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 개폐 축",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.axis_pinnedPipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 개폐 축",
        "원형, 핀가공, 25.4mm, 1.5T, 6m, -",
      );
      this.axis_connectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 개폐 축",
        "원형, 일반, 32mm, 32mm",
      );
      this.axisHolder_bearingSet.setDefaultData(
        3,
        CONST.ITEM_ID_BEARING_SET,
        CONST.ITEM_NAME_BEARING_SET,
        "커튼 개폐 축",
        "원형, 일반, 48mm, 48mm",
      );
      this.axisHolder_saddleHolder.setDefaultData(
        4,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "커튼 개폐 축",
        "원형, 일반, 48mm, -",
      );
      this.coverFixing_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 피복 고정",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.coverFixing_connectionPin.setDefaultData(
        6,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 피복 고정",
        "원형, 일반, 25mm, 25mm",
      );
      this.axis_screw.setDefaultData(
        7,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "커튼 개폐 축",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.axis_welding.setDefaultData(8, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "커튼 개폐 축", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    if (CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion)) {
      // 아이템
      if (this.itemAC.length === 4) {
        // [커튼 개폐 축] 축수 베어링 세트
        const axisHolder_bearingSet: ItemBearing = new ItemBearing();
        axisHolder_bearingSet.setAssociation(design, struct, work, level, position, this);
        axisHolder_bearingSet.setDefaultData(
          3,
          CONST.ITEM_ID_BEARING_SET,
          CONST.ITEM_NAME_BEARING_SET,
          "커튼 개폐 축",
          "원형, 일반, 48mm, 48mm",
        );
        axisHolder_bearingSet.selected = false;
        axisHolder_bearingSet.visible = false;
        this.itemAC.splice(3, 0, axisHolder_bearingSet);
        // [커튼 개폐 축] 새들 고정구
        const axisHolder_saddleHolder: ItemHolder = new ItemHolder();
        axisHolder_saddleHolder.setAssociation(design, struct, work, level, position, this);
        axisHolder_saddleHolder.setDefaultData(
          4,
          CONST.ITEM_ID_SADDLE_HOLDER,
          CONST.ITEM_NAME_SADDLE_HOLDER,
          "커튼 개폐 축",
          "원형, 일반, 48mm, -",
        );
        axisHolder_saddleHolder.selected = false;
        axisHolder_saddleHolder.visible = false;
        this.itemAC.splice(4, 0, axisHolder_saddleHolder);
        // [커튼 피복 고정] 파이프
        const coverFixing_pipe: ItemPipe = new ItemPipe();
        coverFixing_pipe.setAssociation(design, struct, work, level, position, this);
        coverFixing_pipe.setDefaultData(
          5,
          CONST.ITEM_ID_PIPE,
          CONST.ITEM_NAME_PIPE,
          "커튼 피복 고정",
          "원형, 일반, 25.4mm, 1.5T, 10m, -",
        );
        coverFixing_pipe.selected = false;
        coverFixing_pipe.visible = false;
        this.itemAC.splice(5, 0, coverFixing_pipe);
        // [커튼 피복 고정] 연결핀
        const coverFixing_connectionPin: ItemConnector = new ItemConnector();
        coverFixing_connectionPin.setAssociation(design, struct, work, level, position, this);
        coverFixing_connectionPin.setDefaultData(
          6,
          CONST.ITEM_ID_CONNECTION_PIN,
          CONST.ITEM_NAME_CONNECTION_PIN,
          "커튼 피복 고정",
          "원형, 일반, 25mm, 25mm",
        );
        coverFixing_connectionPin.selected = false;
        coverFixing_connectionPin.visible = false;
        this.itemAC.splice(6, 0, coverFixing_connectionPin);
        // [커튼 개폐 축] 용접
        const axis_welding: ItemOtherExpense = new ItemOtherExpense();
        axis_welding.setAssociation(design, struct, work, level, position, this);
        axis_welding.setDefaultData(8, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "커튼 개폐 축", "일반");
        axis_welding.selected = false;
        axis_welding.visible = false;
        this.itemAC.push(axis_welding);

        // 인덱스 재설정
        CommonUtil.reindexAC(this.itemAC);
      }
    }
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_axisLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curCoverPosition: CurCoverPositionIL = <CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition;

    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 선택, 가시성 ///////////

    // 하드코딩
    this.axis_pipe.checkSelected(true);
    this.axis_pinnedPipe.selected = false;
    this.axis_pinnedPipe.visible = false;
    this.axis_connectionPin.checkSelected(true);
    if (curSwitchPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
      if (this.axisHolder === CONST.LB_CURTAIN_SWITCH_AXIS_HOLDER_BEARING) {
        this.axisHolder_bearingSet.selected = true;
        this.axisHolder_bearingSet.visible = true;
        this.axisHolder_saddleHolder.selected = false;
        this.axisHolder_saddleHolder.visible = false;
      } else if (this.axisHolder === CONST.LB_CURTAIN_SWITCH_AXIS_HOLDER_SADDLE) {
        this.axisHolder_bearingSet.selected = false;
        this.axisHolder_bearingSet.visible = false;
        this.axisHolder_saddleHolder.selected = true;
        this.axisHolder_saddleHolder.visible = true;
      }
      if (this.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP) {
        this.coverFixing_pipe.checkSelected(true);
        this.coverFixing_connectionPin.checkSelected(true);
      } else if (this.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_STRING_CLIP) {
        this.coverFixing_pipe.checkSelected(false);
        this.coverFixing_connectionPin.checkSelected(false);
      }
      this.axis_welding.selected = true;
      this.axis_welding.visible = true;
    } else {
      this.axisHolder_bearingSet.selected = false;
      this.axisHolder_bearingSet.visible = false;
      this.axisHolder_saddleHolder.selected = false;
      this.axisHolder_saddleHolder.visible = false;
      this.coverFixing_pipe.checkSelected(false);
      this.coverFixing_connectionPin.checkSelected(false);
      this.axis_welding.selected = false;
      this.axis_welding.visible = false;
    }
    this.axis_screw.selected = true;
    this.axis_screw.visible = true;

    /// //////// 수량 ///////////

    this.axis_pipe.designQuantity =
      (this.axis_pipe.getConnectedPipeLength(
        this.axisLength,
        curSwitchPosition.switchWayValue,
        this.axis_pipe.specLength / 2,
      ) /
        this.axis_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_AXIS_PIPE;

    this.axis_pinnedPipe.designQuantity = 0;

    if (this.axisLength > this.axisPipeSample.specLength) {
      this.axis_connectionPin.designQuantity =
        this.axis_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.axis_connectionPin.designQuantity = 0;
    }

    this.axisHolder_bearingSet.designQuantity =
      (Math.ceil(this.axisLength / columnPosition.columnInterval) + 1) * curSwitchPosition.switchWayValue;
    if (this.basic.structureName === CONST.LB_STRUCT_VENLO && this.position.label === CONST.LB_POSITION_CEILING) {
      const columnPart: ColumnPartVL_Column = <ColumnPartVL_Column>(
        this.struct.columnWorkVL.level1.columnPosition.prismColumnPart
      );
      const framePart: CurSwitchPartIL_FrameVL = <CurSwitchPartIL_FrameVL>(
        this.struct.curSwitchWorkIL.level1.columnPosition.framePartVL
      );
      this.axisHolder_bearingSet.designQuantity =
        ((columnPart.edgeColumnQuantity +
          columnPart.frontAndBackColumnQuantity +
          columnPart.frontAndBackColumnQuantity) /
          2) *
        framePart.driveAxisLineNumber;
    }

    this.axisHolder_saddleHolder.designQuantity =
      (Math.ceil(this.axisLength / columnPosition.columnInterval) + 1) * curSwitchPosition.switchWayValue;

    this.coverFixing_pipe.designQuantity =
      (this.coverFixing_pipe.getConnectedPipeLength(
        this.axisLength,
        curSwitchPosition.switchWayValue,
        this.coverFixing_pipe.specLength / 2,
      ) /
        this.coverFixing_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_AXIS_PIPE;

    if (this.axisLength > this.coverFixing_pipe.specLength) {
      this.coverFixing_connectionPin.designQuantity =
        this.coverFixing_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.coverFixing_connectionPin.designQuantity = 0;
    }

    this.axis_screw.designQuantity =
      ((this.axis_connectionPin.getSelectedQuantity() * 4 + this.coverFixing_connectionPin.getSelectedQuantity() * 4) /
        this.axis_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    let axis_welding_designQuantity: number = 0;
    if (this.axisHolder_bearingSet.specCrossSize1 === CONST.ITEM_SPEC_VALUE_NONE) {
      axis_welding_designQuantity =
        (Math.ceil(this.axisLength / columnPosition.columnInterval) + 1) * curSwitchPosition.switchWayValue;
    }
    this.axis_welding.designQuantity = axis_welding_designQuantity;
  }

  /**
   * 알고리즘: 개폐 축 길이 <- 길이(기본정보), 폭(기본정보), 동수(기본정보)
   */
  public algorithm_axisLength(): void {
    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.axisLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
    } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
      this.axisLength =
        this.basicLevel.width * this.basic.buildingNumber + this.design.preference.connectedPipeExtraLength;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [커튼 개폐 축] 파이프 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_pipe(): void {
    // 하드코딩
    if (this.axisPipeSample.specMaterial !== CONST.ITEM_SPEC_VALUE_AZM) {
      this.axis_pipe.specs = this.axisPipeSample.specs;
    }
  }

  /**
   * 규격 알고리즘: [커튼 개폐 축] 파이프 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_pinnedPipe(): void {}

  /**
   * 규격 알고리즘: [커튼 개폐 축] 연결핀 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_connectionPin(): void {
    this.axis_connectionPin.specPipeType = this.axisPipeSample.specPipeType;
    this.axis_connectionPin.specCrossSize1 = this.axisPipeSample.specCrossSize;
    this.axis_connectionPin.specCrossSize2 = this.axisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 개폐 축] 축수 베어링 세트 <- 기둥 형태, 기둥 파이프 샘플, 개폐 축 파이프 샘플
   */
  public algorithmSpec_axisHolder_bearingSet(): void {
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    // 하드코딩
    if (
      columnPosition.columnCrossSize === CONST.ITEM_SPEC_VALUE_48_MM ||
      columnPosition.columnCrossSize === CONST.ITEM_SPEC_VALUE_60_MM ||
      columnPosition.columnCrossSize === CONST.ITEM_SPEC_VALUE_60_60_MM
    ) {
      this.axisHolder_bearingSet.specPipeType = columnPosition.columnPipeType;
      this.axisHolder_bearingSet.specCrossSize1 = columnPosition.columnCrossSize;
    } else {
      this.axisHolder_bearingSet.specPipeType = CONST.ITEM_SPEC_VALUE_ANGLE_TYPE;
      this.axisHolder_bearingSet.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
    }
    // 하드코딩
    if (
      this.axisPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM ||
      this.axisPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM
    ) {
      this.axisHolder_bearingSet.specCrossSize2 = this.axisPipeSample.specCrossSize;
    } else {
      this.axisHolder_bearingSet.specCrossSize2 = CONST.ITEM_SPEC_VALUE_32_MM;
    }
  }

  /**
   * 규격 알고리즘: [커튼 개폐 축] 새들 고정구 <- 개폐 축 파이프 샘플(하나 큰 사이즈)
   */
  public algorithmSpec_axisHolder_saddleHolder(): void {
    this.axisHolder_saddleHolder.specPipeType = this.axisPipeSample.specPipeType;
    // 하드코딩
    if (this.axisPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM) {
      this.axisHolder_saddleHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_25_MM;
    } else if (this.axisPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM) {
      this.axisHolder_saddleHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_32_MM;
    } else if (this.axisPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM) {
      this.axisHolder_saddleHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_48_MM;
    }
    this.axisHolder_saddleHolder.specCrossSize2 = CONST.ITEM_SPEC_VALUE_NONE;
  }

  /**
   * 규격 알고리즘: [커튼 피복 고정] 파이프 <- 없음
   */
  public algorithmSpec_coverFixing_pipe(): void {}

  /**
   * 규격 알고리즘: [커튼 피복 고정] 연결핀 <- 없음
   */
  public algorithmSpec_coverFixing_connectionPin(): void {}

  /**
   * 규격 알고리즘: [커튼 개폐 축] 나사 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_screw(): void {
    if (this.axisPipeSample.specMaterial === CONST.ITEM_SPEC_VALUE_NORMAL) {
      this.axis_screw.specs = this.design.preference.defaultScrewCrossNormal;
    } else if (this.axisPipeSample.specMaterial === CONST.ITEM_SPEC_VALUE_AZM) {
      this.axis_screw.specs = this.design.preference.defaultScrewCrossStainless;
    }
  }

  /**
   * 규격 알고리즘: [커튼 개폐 축] 용접 <- 없음
   */
  public algorithmSpec_axis_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
