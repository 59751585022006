import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { SkirtSampleIL_SkirtClip } from "vhows-design/src/object/design/cover/skirt/il/SkirtSampleIL_SkirtClip";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-04
 */
@jsonObject({
  knownTypes: [SkirtSampleIL_SkirtClip],
})
export class SkirtPartIL_FixingSkirtClip extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public skirtClipSample: SkirtSampleIL_SkirtClip; // 치마 클립 샘플

  // 아이템
  public skirtClip_skirtClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _clipInterval: number; // 클립 간격

  /**
   * 클립 간격
   */
  public get clipInterval(): number {
    return this._clipInterval;
  }

  public set clipInterval(value: number) {
    this._clipInterval = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.skirtClipSample = new SkirtSampleIL_SkirtClip();

    this.sampleAC = [this.skirtClipSample];

    // 아이템
    this.skirtClip_skirtClip = new ItemClip();

    this.itemAC = [this.skirtClip_skirtClip];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.skirtClipSample = <SkirtSampleIL_SkirtClip>this.sampleAC[0];

    // 아이템
    this.skirtClip_skirtClip = <ItemClip>this.itemAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param clipInterval: number 클립 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    clipInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._clipInterval = clipInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.skirtClipSample.setDefaultData(
        0,
        CONST.ITEM_ID_SKIRT_CLIP,
        CONST.ITEM_NAME_SKIRT_CLIP,
        "치마 클립",
        "철, 32mm",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.skirtClip_skirtClip.setDefaultData(
        0,
        CONST.ITEM_ID_SKIRT_CLIP,
        CONST.ITEM_NAME_SKIRT_CLIP,
        "치마 클립",
        "철, 32mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_clipInterval();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skirtPosition: SkirtPositionIL = <SkirtPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;
    // 외부
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    );

    /// //////// 선택, 가시성 ///////////

    this.skirtClip_skirtClip.selected = true;
    this.skirtClip_skirtClip.visible = true;

    /// //////// 수량 ///////////

    if (skirtPosition.label === CONST.LB_POSITION_SIDE_COLUMN || skirtPosition.label === CONST.LB_POSITION_SIDE_WING) {
      this.skirtClip_skirtClip.designQuantity =
        Math.ceil(this.basicLevel.length / this.clipInterval) * switcherPosition.switchWayValue;
    } else if (skirtPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          bothEndQuantity = Math.ceil(this.basicLevel.length / this.clipInterval) * 2;
          centralQuantity =
            Math.ceil(this.basicLevel.length / this.clipInterval) *
            switcherPosition.switchWayValue *
            (this.basic.buildingNumber - 2);
          this.skirtClip_skirtClip.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          this.skirtClip_skirtClip.designQuantity =
            Math.ceil(this.basicLevel.length / this.clipInterval) *
            switcherPosition.switchWayValue *
            this.basic.buildingNumber;
        }
      } else {
        this.skirtClip_skirtClip.designQuantity =
          Math.ceil(this.basicLevel.length / this.clipInterval) * switcherPosition.switchWayValue;
      }
    } else if (skirtPosition.label === CONST.LB_POSITION_FRONT) {
      this.skirtClip_skirtClip.designQuantity = Math.ceil(
        (this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber) / this.clipInterval,
      );
    } else if (skirtPosition.label === CONST.LB_POSITION_BACK) {
      this.skirtClip_skirtClip.designQuantity = Math.ceil(
        (this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber) / this.clipInterval,
      );
    }
  }

  /**
   * 알고리즘: 클립 간격 <- 서까래 간격(기초 골조), 샛기둥 간격(마구리), 하우스 폭(기본정보)
   */
  public algorithm_clipInterval(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      // 연동형인 경우
      const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
      const roofPosition: RoofPositionIL = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
      const wingPosition: WingPositionIL = (<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition;
      const endpieceLevel: EndpieceLevelIL = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
      let endpiecePosition: EndpiecePositionIL;
      let studLineNumber: number;

      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
          this.clipInterval = columnPosition.cylinderColumnPart.columnInterval;
        } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.clipInterval = columnPosition.prismColumnPart.columnInterval;
        }
      } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        if (wingPosition.wingRafterPart.wingRafterInterval < 0.8) {
          this.clipInterval = wingPosition.wingRafterPart.wingRafterInterval * 2;
        } else {
          this.clipInterval = wingPosition.wingRafterPart.wingRafterInterval;
        }
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        if (roofPosition.rafterPart.rafterInterval < 0.8) {
          this.clipInterval = roofPosition.rafterPart.rafterInterval * 2;
        } else {
          this.clipInterval = roofPosition.rafterPart.rafterInterval;
        }
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        endpiecePosition = endpieceLevel.frontPosition;
        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          studLineNumber = endpiecePosition.cylinderStudPart.studLineNumber;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          studLineNumber = endpiecePosition.cylinderStudSepPart.studLineNumber;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          studLineNumber = endpiecePosition.prismStudSepPart.studLineNumber;
        }
        this.clipInterval = CommonUtil.roundUp(this.basicLevel.width / studLineNumber, 1);
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        endpiecePosition = endpieceLevel.backPosition;
        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          studLineNumber = endpiecePosition.cylinderStudPart.studLineNumber;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          studLineNumber = endpiecePosition.cylinderStudSepPart.studLineNumber;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          studLineNumber = endpiecePosition.prismStudSepPart.studLineNumber;
        }
        this.clipInterval = CommonUtil.roundUp(this.basicLevel.width / studLineNumber, 1);
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 벤로형인 경우
      const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
      const roofPosition: RoofPositionVL = this.struct.roofWorkVL.level1.roofPosition;
      const wingPosition: WingPositionVL = (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition;
      const endpieceLevel: EndpieceLevelVL = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
      let endpiecePosition: EndpiecePositionVL;
      let studLineNumber: number;

      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.clipInterval = columnPosition.prismColumnPart.columnInterval;
        }
      } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        if (wingPosition.wingRafterPart.wingRafterInterval < 0.8) {
          this.clipInterval = wingPosition.wingRafterPart.wingRafterInterval * 2;
        } else {
          this.clipInterval = wingPosition.wingRafterPart.wingRafterInterval;
        }
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        if (roofPosition.rafterPart.rafterInterval < 0.8) {
          this.clipInterval = roofPosition.rafterPart.rafterInterval * 2;
        } else {
          this.clipInterval = roofPosition.rafterPart.rafterInterval;
        }
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        endpiecePosition = endpieceLevel.frontPosition;
        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          studLineNumber = endpiecePosition.endpiecePart.barLineNumber;
        }
        this.clipInterval = CommonUtil.roundUp(this.basicLevel.width / studLineNumber, 1);
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        endpiecePosition = endpieceLevel.backPosition;
        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          studLineNumber = endpiecePosition.endpiecePart.barLineNumber;
        }
        this.clipInterval = CommonUtil.roundUp(this.basicLevel.width / studLineNumber, 1);
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [치마 클립] 치마 클립 <- 치마 클립 샘플
   */
  public algorithmSpec_skirtClip_skirtClip(): void {
    this.skirtClip_skirtClip.specs = this.skirtClipSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
