import { jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { Item } from "vhows-design/src/object/design/item/Item";
import { UnitPriceModel } from "vhows-design/src/object/item/unitprice/UnitPriceModel";
import { Estimate } from "vhows-design/src/object/design/estimate/Estimate";
import { Design } from "vhows-design/src/object/design/Design";
import { DesignConst } from "vhows-design/src/common/constant/DesignConst";
import { ItemUtil } from "vhows-design/src/object/design/item/ItemUtil";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-04-12
 */
@jsonObject({
  knownTypes: [],
})
export class EstimateItem extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design = null;

  /** 견적 객체 */
  public estimate: Estimate = null;

  /** 단가 모델 */
  public unitPriceModel: UnitPriceModel = null;

  /** 설계품목배열 - 현재 견적 상품과 연관된 설계 품목의 배열 */
  public designItemAC: Item[] = [];

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 선택된 매입 금액 (공급가액) - 선택 해제시  0원 리턴 */
  public get selectedItemSupplyPurchase(): number {
    if (this.selected) return this.itemSupplyPurchase;
    return 0;
  }

  /** 선택된 매입 금액 (부가세) - 선택 해제시  0원 리턴 */
  public get selectedItemVatPurchase(): number {
    if (this.selected) return this.itemVatPurchase;
    return 0;
  }

  /** 선택된 매입 금액 (공급대가) - 선택 해제시  0원 리턴 */
  public get selectedItemPricePurchase(): number {
    if (this.selected) return this.itemPricePurchase;
    return 0;
  }

  /** 선택된 단가적용 금액 (공급가액) - 선택 해제시  0원 리턴 */
  public get selectedItemSupplyUnit(): number {
    if (this.selected) return this.itemSupplyUnit;
    return 0;
  }

  /** 선택된 단가적용 금액 (부가세) - 선택 해제시  0원 리턴 */
  public get selectedItemVatUnit(): number {
    if (this.selected) return this.itemVatUnit;
    return 0;
  }

  /** 선택된 단가적용 금액 (공급대가) - 선택 해제시  0원 리턴 */
  public get selectedItemPriceUnit(): number {
    if (this.selected) return this.itemPriceUnit;
    return 0;
  }

  /** 선택된 배율적용 금액 (공급가액) - 선택 해제시  0원 리턴 */
  public get selectedItemSupplyRated(): number {
    if (this.selected) return this.itemSupplyRated;
    return 0;
  }

  /** 선택된 배율적용 금액 (부가세) - 선택 해제시  0원 리턴 */
  public get selectedItemVatRated(): number {
    if (this.selected) return this.itemVatRated;
    return 0;
  }

  /** 선택된 배율적용 금액 (공급대가) - 선택 해제시  0원 리턴 */
  public get selectedItemPriceRated(): number {
    if (this.selected) return this.itemPriceRated;
    return 0;
  }

  /** 선택된 금액 (공급가액) - 선택 해제시  0원 리턴 */
  public get selectedItemSupply(): number {
    if (this.selected) return this.itemSupply;
    return 0;
  }

  /** 선택된 금액 (부가세) - 선택 해제시  0원 리턴 */
  public get selectedItemVat(): number {
    if (this.selected) return this.itemVat;
    return 0;
  }

  /** 선택된 금액 (공급대가) - 선택 해제시  0원 리턴 */
  public get selectedItemPrice(): number {
    if (this.selected) return this.itemPrice;
    return 0;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 일반

  /** 인덱스 */
  @jsonMember(Number)
  public index: number = undefined;
  /** 선택 */
  @jsonMember(Boolean)
  public selected: boolean = true;
  /** 가용성 */
  @jsonMember(Boolean)
  public enabled: boolean = true;
  /** 가시성 */
  @jsonMember(Boolean)
  public visible: boolean = true;
  /** 견적 형태 (NORMAL / GROUP / BOTH) */
  @jsonMember(Number)
  public estimateType: number = null;

  // 상품

  /** 상품 아이디 */
  @jsonMember(String)
  public productId: string = null;
  /** 상품명 */
  @jsonMember(String)
  public productName: string = null;
  /** 규격 */
  @jsonMember(String)
  public specs: string = null;
  /** 상표 (설계 품목의 상표 리스트와 동일) */
  @jsonMember(String)
  public brands: string = null;
  /** 용도 */
  @jsonMember(String)
  public purpose: string = null;

  // 분류

  /** 분류1 */
  @jsonMember(String)
  public category1: string = null;
  /** 분류2 */
  @jsonMember(String)
  public category2: string = null;
  /** 분류3 */
  @jsonMember(String)
  public category3: string = null;
  /** 분류 순서 */
  @jsonMember(Number)
  public categoryOrder: number = null;
  /** 규격 형태 */
  @jsonMember(String)
  public specsType: string = null;
  /** 상표 형태 */
  @jsonMember(String)
  public brandsType: string = null;

  // 수량

  /** 세트 단위수량 */
  @jsonMember(Number)
  public setUnitQuantity: number = 1;
  /** 설계 수량 */
  @jsonMember(Number)
  public designQuantity: number = 0;
  /** 미가공 견적 수량 */
  @jsonMember(Number)
  public estimateQuantityRaw: number = 0;
  /** 견적 수량 */
  @jsonMember(Number)
  public estimateQuantity: number = 0;
  /** 단위기호 */
  @jsonMember(String)
  public unitSymbol: string = null;

  // 단가

  /** 견적 과세 형태 */
  @jsonMember(String)
  public estimateTaxType: string = null;

  /** 매입가 (공급가액) - 매입가 기준 */
  @jsonMember(Number)
  public sellingSupplyPurchase: number = 0;
  /** 매입 금액 (공급가액) */
  @jsonMember(Number)
  public itemSupplyPurchase: number = 0;
  /** 매입 금액 (부가세) */
  @jsonMember(Number)
  public itemVatPurchase: number = 0;
  /** 매입 금액 (공급대가) */
  @jsonMember(Number)
  public itemPricePurchase: number = 0;

  /** 단가적용 매출가 (공급가액) - 기본단가 100%기준 매출가 */
  @jsonMember(Number)
  public sellingSupplyUnit: number = 0;
  /** 단가적용 금액 (공급가액) */
  @jsonMember(Number)
  public itemSupplyUnit: number = 0;
  /** 단가적용 금액 (부가세) */
  @jsonMember(Number)
  public itemVatUnit: number = 0;
  /** 단가적용 금액 (공급대가) */
  @jsonMember(Number)
  public itemPriceUnit: number = 0;

  /** 배율적용 매출가 (공급가액) - 단가 배율이 적용된 매출가 */
  @jsonMember(Number)
  public sellingSupplyRated: number = 0;
  /** 배율적용 금액 (공급가액) */
  @jsonMember(Number)
  public itemSupplyRated: number = 0;
  /** 배율적용 금액 (부가세) */
  @jsonMember(Number)
  public itemVatRated: number = 0;
  /** 배율적용 금액 (공급대가) */
  @jsonMember(Number)
  public itemPriceRated: number = 0;

  /** 매출가 (공급가액) - 수정된 최종 매출가 */
  @jsonMember(Number)
  public sellingSupply: number = 0;
  /** 금액 (공급가액) */
  @jsonMember(Number)
  public itemSupply: number = 0;
  /** 금액 (부가세) */
  @jsonMember(Number)
  public itemVat: number = 0;
  /** 금액 (공급대가) */
  @jsonMember(Number)
  public itemPrice: number = 0;

  //--------------------------------------------------------------------------
  //
  // View Variables
  //
  //--------------------------------------------------------------------------

  /** designItemAC의 설계 정보가 변경되었는지 여부
   * - 변경된 경우에만, 적용을 눌렀을때 designItemAC에 임시로 저장된 값을 원본에 반영한다.
   */
  public designItemsChanged: boolean = false;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor(estimate: Estimate = null, design: Design = null) {
    super();
    this.estimate = estimate;
    this.design = design;
    this.designItemAC = new Array();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design, estimate: Estimate): void {
    this.design = design;
    this.estimate = estimate;
  }

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * @param origin 원본 객체
   */
  public deepCopy(origin: EstimateItem): void {
    // Variables

    this.design = origin.design; // 깊은 복사x
    this.estimate = origin.estimate; // 깊은 복사x
    this.unitPriceModel = origin.unitPriceModel; // 깊은 복사x
    this.designItemAC = origin.designItemAC; // 깊은 복사x
    // // designItemAC는 견적 수정에서 꼭 필요한 것만 깊은 복사
    // if (origin.designItemAC) {
    //   this.designItemAC = [];
    //   for (let i: number = 0; origin.designItemAC && i < origin.designItemAC.length; i++) {
    //     this.designItemAC[i] = new Item();
    //     this.designItemAC[i].normalEstimateItem = origin.designItemAC[i].normalEstimateItem;
    //     this.designItemAC[i].groupEstimateItem = origin.designItemAC[i].groupEstimateItem;
    //     this.designItemAC[i].work = origin.designItemAC[i].work;
    //     this.designItemAC[i].level = origin.designItemAC[i].level;
    //     this.designItemAC[i].position = origin.designItemAC[i].position;
    //     this.designItemAC[i].part = origin.designItemAC[i].part;
    //     this.designItemAC[i].label = origin.designItemAC[i].label;
    //     this.designItemAC[i].productId = origin.designItemAC[i].productId;
    //     this.designItemAC[i].purpose = origin.designItemAC[i].purpose;
    //     this.designItemAC[i].designQuantity = origin.designItemAC[i].designQuantity;
    //     this.designItemAC[i].isCustomAdded = origin.designItemAC[i].isCustomAdded;
    //   }
    // }

    // 일반
    this.index = origin.index;
    this.selected = origin.selected;
    this.enabled = origin.enabled;
    this.visible = origin.visible;
    this.estimateType = origin.estimateType;
    // 하우스 품목 > 상품
    this.productId = origin.productId;
    this.productName = origin.productName;
    this.specs = origin.specs;
    this.brands = origin.brands;
    this.purpose = origin.purpose;
    // 하우스 품목 > 분류
    this.category1 = origin.category1;
    this.category2 = origin.category2;
    this.category3 = origin.category3;
    this.categoryOrder = origin.categoryOrder;
    this.specsType = origin.specsType;
    this.brandsType = origin.brandsType;
    this.unitSymbol = origin.unitSymbol;
    // 하우스 품목 > 수량
    this.setUnitQuantity = origin.setUnitQuantity;
    this.designQuantity = origin.designQuantity;
    this.estimateQuantityRaw = origin.estimateQuantityRaw;
    this.estimateQuantity = origin.estimateQuantity;
    // 하우스 품목 > 단가
    this.estimateTaxType = origin.estimateTaxType;
    this.sellingSupplyPurchase = origin.sellingSupplyPurchase;
    this.itemSupplyPurchase = origin.itemSupplyPurchase;
    this.itemVatPurchase = origin.itemVatPurchase;
    this.itemPricePurchase = origin.itemPricePurchase;
    this.sellingSupplyUnit = origin.sellingSupplyUnit;
    this.itemSupplyUnit = origin.itemSupplyUnit;
    this.itemVatUnit = origin.itemVatUnit;
    this.itemPriceUnit = origin.itemPriceUnit;
    this.sellingSupplyRated = origin.sellingSupplyRated;
    this.itemSupplyRated = origin.itemSupplyRated;
    this.itemVatRated = origin.itemVatRated;
    this.itemPriceRated = origin.itemPriceRated;
    this.sellingSupply = origin.sellingSupply;
    this.itemSupply = origin.itemSupply;
    this.itemVat = origin.itemVat;
    this.itemPrice = origin.itemPrice;
  }

  /**
   * 기본 데이터 설정 - 중복 자재 절약
   * @param estimate 견적 객체
   */
  public setDefaultData_overlapMaterialSaving(estimate: Estimate): void {
    // 레퍼런스
    this.unitPriceModel = <UnitPriceModel>(
      this.design.itemModel.materialDiscountCostCategoryModel.overlapMaterialSavingProductModel.specCompanyModelAC[0]
        .unitPriceModelAC[0]
    );

    // 일반
    // this.index = 이후에 별도 계산함
    this.selected = true;
    this.enabled = true;
    this.visible = true;
    this.estimateType = CONST.TYPE_ESTIMATE_GROUP;
    // 상품
    this.productId = this.unitPriceModel.productCompanyModel.productModel.productId;
    this.productName = this.unitPriceModel.productCompanyModel.productModel.label;
    this.specs = this.unitPriceModel.specCompanyModel.specModel.label;
    this.brands = this.unitPriceModel.brandCompanyModel.brandModel.label;
    this.purpose = "";
    // 분류
    this.category1 = CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION;
    this.category2 = CONST.LB_OTHER_MATERIAL_WORK;
    this.category3 = CONST.LB_LEVEL_WHOLE;
    this.categoryOrder = CONST.ORDER_BUILDING_WORK;
    this.specsType = this.unitPriceModel.categoryCompanyModel.categoryModel.specsType;
    this.brandsType = this.unitPriceModel.categoryCompanyModel.categoryModel.brandsType;
    this.unitSymbol = this.unitPriceModel.productCompanyModel.productModel.unitSymbol;
    // 수량
    this.setUnitQuantity = this.unitPriceModel.setUnitQuantity;
    this.designQuantity = 1;
    this.estimateQuantityRaw = 1;
    this.estimateQuantity = 1;
    // 단가
    this.estimateTaxType = CONST.LB_ESTIMATE_TAX_TYPE_TAXATION;

    // 그룹 변수명 제거로 인해 주석처리
    // this.sellingSupplyPurchase = estimate.designMaterialSupplyPurchase - estimate.groupDesignMaterialSupplyPurchase;
    // this.itemSupplyPurchase = estimate.designMaterialSupplyPurchase - estimate.groupDesignMaterialSupplyPurchase;
    // this.itemVatPurchase = estimate.designMaterialVatPurchase - estimate.groupDesignMaterialVatPurchase;
    // this.itemPricePurchase = estimate.designMaterialPricePurchase - estimate.groupDesignMaterialPricePurchase;
    // this.sellingSupplyUnit = estimate.designMaterialSupplyUnit - estimate.groupDesignMaterialSupplyUnit;
    // this.itemSupplyUnit = estimate.designMaterialSupplyUnit - estimate.groupDesignMaterialSupplyUnit;
    // this.itemVatUnit = estimate.designMaterialVatUnit - estimate.groupDesignMaterialVatUnit;
    // this.itemPriceUnit = estimate.designMaterialPriceUnit - estimate.groupDesignMaterialPriceUnit;
    // this.sellingSupplyRated = estimate.designMaterialSupplyRated - estimate.groupDesignMaterialSupplyRated;
    // this.itemSupplyRated = estimate.designMaterialSupplyRated - estimate.groupDesignMaterialSupplyRated;
    // this.itemVatRated = estimate.designMaterialVatRated - estimate.groupDesignMaterialVatRated;
    // this.itemPriceRated = estimate.designMaterialPriceRated - estimate.groupDesignMaterialPriceRated;
    // this.sellingSupply = estimate.designMaterialSupply - estimate.groupDesignMaterialSupply;
    // this.itemSupply = estimate.designMaterialSupply - estimate.groupDesignMaterialSupply;
    // this.itemVat = estimate.designMaterialVat - estimate.groupDesignMaterialVat;
    // this.itemPrice = estimate.designMaterialPrice - estimate.groupDesignMaterialPrice;
    this.sellingSupplyPurchase = estimate.designMaterialSupplyPurchase;
    this.itemSupplyPurchase = estimate.designMaterialSupplyPurchase;
    this.itemVatPurchase = estimate.designMaterialVatPurchase;
    this.itemPricePurchase = estimate.designMaterialPricePurchase;
    this.sellingSupplyUnit = estimate.designMaterialSupplyUnit;
    this.itemSupplyUnit = estimate.designMaterialSupplyUnit;
    this.itemVatUnit = estimate.designMaterialVatUnit;
    this.itemPriceUnit = estimate.designMaterialPriceUnit;
    this.sellingSupplyRated = estimate.designMaterialSupplyRated;
    this.itemSupplyRated = estimate.designMaterialSupplyRated;
    this.itemVatRated = estimate.designMaterialVatRated;
    this.itemPriceRated = estimate.designMaterialPriceRated;
    this.sellingSupply = estimate.designMaterialSupply;
    this.itemSupply = estimate.designMaterialSupply;
    this.itemVat = estimate.designMaterialVat;
    this.itemPrice = estimate.designMaterialPrice;
  }
  선;

  /**
   * 기본 데이터 설정 - 자재 할인
   * @param discountSupply 할인 (공급가액)
   * @param discountVat 할인 (부가세)
   * @param discountPrice 할인 (공급대가)
   */
  public setDefaultData_materialDiscount(
    discountSupply: number,
    discountVat: number = 0,
    discountPrice: number = 0,
  ): void {
    // 레퍼런스
    this.unitPriceModel = <UnitPriceModel>(
      this.design.itemModel.materialDiscountCostCategoryModel.materialDiscountProductModel.specCompanyModelAC[0]
        .unitPriceModelAC[0]
    );

    // 일반
    // this.index = 이후에 별도 계산함
    this.selected = true;
    this.enabled = true;
    this.visible = true;
    this.estimateType = CONST.TYPE_ESTIMATE_NORMAL;
    // 상품
    this.productId = this.unitPriceModel.productCompanyModel.productModel.productId;
    this.productName = this.unitPriceModel.productCompanyModel.productModel.label;
    this.specs = this.unitPriceModel.specCompanyModel.specModel.label;
    this.brands = this.unitPriceModel.brandCompanyModel.brandModel.label;
    this.purpose = "";
    // 분류
    this.category1 = `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_OTHER_MATERIAL_WORK}`;
    this.category2 = `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_OTHER_MATERIAL_WORK}`;
    this.category3 = CONST.LB_LEVEL_WHOLE;
    this.categoryOrder = CONST.ORDER_DETAIL_OTHER_COST;
    this.specsType = this.unitPriceModel.categoryCompanyModel.categoryModel.specsType;
    this.brandsType = this.unitPriceModel.categoryCompanyModel.categoryModel.brandsType;
    this.unitSymbol = this.unitPriceModel.productCompanyModel.productModel.unitSymbol;
    // 수량
    this.setUnitQuantity = this.unitPriceModel.setUnitQuantity;
    this.designQuantity = 1;
    this.estimateQuantityRaw = 1;
    this.estimateQuantity = 1;
    // 단가
    this.setEstimateTaxType(); // 견적 과세 형태 지정
    this.sellingSupplyPurchase = 0;
    this.itemSupplyPurchase = 0;
    this.itemVatPurchase = 0;
    this.itemPricePurchase = 0;
    this.sellingSupplyUnit = 0;
    this.itemSupplyUnit = 0;
    this.itemVatUnit = 0;
    this.itemPriceUnit = 0;
    this.sellingSupplyRated = 0;
    this.itemSupplyRated = 0;
    this.itemVatRated = 0;
    this.itemPriceRated = 0;
    this.sellingSupply = discountSupply;
    this.itemSupply = discountSupply;
    this.itemVat = discountVat;
    this.itemPrice = discountPrice;
  }

  //----------------------------------
  // 하우스 견적 - 주요 함수
  //----------------------------------

  /**
   * 분류 재 정의
   */
  public groupingCategoryEstimateItem(designItem: Item, type: string): string {
    switch (type) {
      case "category1":
        if (DesignConst.constructionGrouping.indexOf(designItem.work.category) !== -1) {
          // 칸막이/창문/작업동 변환
          if (DesignConst.constructionWorkGrouping.indexOf(designItem.work.label) !== -1) {
            return `${CONST.CAT_CONSTRUCTION} > ${designItem.work.label} 공사`;
          }
          // 골조/개폐/피복 그룹핑
          if (DesignConst.groupFrameSwitchCover.indexOf(designItem.work.category) !== -1) {
            return `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION}`;
          }
          // 그외 건축 공사 기타 자재 변환
          if (designItem.work.category === CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION) {
            return `${CONST.CAT_CONSTRUCTION} > ${CONST.ITEM_CAT2_NAME_OTHER_MATERIAL}`;
          }

          return `${CONST.CAT_CONSTRUCTION} > ${designItem.work.category}`;
        } else if (designItem.work.category === CONST.LB_BUILDING_CONSTRUCTION) {
          return CONST.LB_BUILDING_CONSTRUCTION;
        } else if (DesignConst.otherFacilConstructionGrouping.indexOf(designItem.work.label) !== -1) {
          return `${CONST.CAT_FACILITIES} > ${designItem.work.label} 공사`;
        } else {
          return `${CONST.CAT_FACILITIES} > ${
            designItem.work.category === CONST.LB_OTHER_FACILITIES_CONSTRUCTION
              ? CONST.LB_OTHER_FACILITIES_WORK
              : designItem.work.category
          }`;
        }
      case "category2":
        if (DesignConst.constructionGrouping.indexOf(designItem.work.category) !== -1) {
          // 칸막이/창문/작업동 변환
          if (DesignConst.constructionWorkGrouping.indexOf(designItem.work.label) !== -1) {
            return `${CONST.CAT_CONSTRUCTION} > ${designItem.work.label} 공사`;
          }
          // 골조/개폐/피복 그룹핑
          if (
            DesignConst.groupFrameSwitchCover.indexOf(designItem.work.category) !== -1 &&
            DesignConst.jajeGrouping.indexOf(designItem.unitPriceModel.categoryCompanyModel.categoryModel.category2) !==
              -1
          ) {
            return `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${designItem.unitPriceModel.categoryCompanyModel.categoryModel.category2}`;
          } else if (
            DesignConst.groupFrameSwitchCover.indexOf(designItem.work.category) !== -1 &&
            DesignConst.jajeGrouping.indexOf(designItem.unitPriceModel.categoryCompanyModel.categoryModel.category2) ===
              -1
          ) {
            return `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${CONST.CAT_FRAME_SWITCH_COVER_ADD_JAJE}`;
          }
          // 그외 건축 공사 기타 자재 변환
          if (designItem.work.category === CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION) {
            return `${CONST.CAT_CONSTRUCTION} > ${CONST.ITEM_CAT2_NAME_OTHER_MATERIAL}`;
          }

          return `${CONST.CAT_CONSTRUCTION} > ${designItem.work.category}`;
        } else if (designItem.work.category === CONST.LB_BUILDING_CONSTRUCTION) {
          return CONST.LB_BUILDING_CONSTRUCTION;
        } else if (DesignConst.otherFacilConstructionGrouping.indexOf(designItem.work.label) !== -1) {
          return `${CONST.CAT_FACILITIES} > ${designItem.work.label} 공사`;
        } else {
          return `${CONST.CAT_FACILITIES} > ${
            designItem.work.category === CONST.LB_OTHER_FACILITIES_CONSTRUCTION
              ? CONST.LB_OTHER_FACILITIES_WORK
              : designItem.work.category
          }`;
        }
      default:
        return "";
    }
  }

  /**
   * 설계품목을 견적품목으로 변환하기
   * @param designItem 설계상품
   * @param estimateType 견적 형태
   */
  public convertDesignItemToEstimateItem(designItem: Item, estimateType: number): void {
    const compactPurpose: string = designItem.purpose.replace(new RegExp(/\s+/g), ""); // 모든 공백제거

    // 레퍼런스
    this.designItemAC.push(designItem);
    this.unitPriceModel = designItem.unitPriceModel;

    // 일반
    // this.index = 이후에 별도 계산함
    this.selected = true;
    this.enabled = false;
    this.visible = true;
    // 상품
    this.productId = designItem.productId;
    this.productName = designItem.label;
    this.specs = designItem.specs;
    this.brands = designItem.brandsList; // 비닐의 '비중'과 같은 상표를 보여주지 않기 위해 상표 대신 '상표 리스트'를 사용함
    this.purpose = compactPurpose;
    this.estimateType = estimateType;
    // 분류
    const category2 = this.groupingCategoryEstimateItem(designItem, "category2");
    this.category1 = this.groupingCategoryEstimateItem(designItem, "category1");
    this.category2 = category2;
    this.category3 = designItem.level.label;
    // this.categoryOrder = designItem.work.order;
    this.categoryOrder = ItemUtil.sortItemDetailJaje(category2, null);
    this.specsType = designItem.categoryCompanyModel.categoryModel.specsType;
    this.brandsType = designItem.categoryCompanyModel.categoryModel.brandsType;
    this.unitSymbol = designItem.productCompanyModel.productModel.unitSymbol;
    // 수량
    if (designItem.unitPriceModel) {
      this.setUnitQuantity = designItem.unitPriceModel.setUnitQuantity;
    } else {
      this.setUnitQuantity = 1;
    }
    this.designQuantity = designItem.designQuantity;
    this.estimateQuantityRaw = designItem.designQuantity;
    // this.estimateQuantity = 이후에 별도 계산함
    // 단가
    // this.estimateTaxType = 이후에 별도 계산함
    this.sellingSupplyPurchase = designItem.getSellingSupply(CONST.ID_ITEM_SELLING_TYPE_PURCHASE);
    // this.itemSupplyPurchase = 이후에 별도 계산함
    // this.itemVatPurchase = 이후에 별도 계산함
    // this.itemPricePurchase = 이후에 별도 계산함
    this.sellingSupplyUnit = designItem.getSellingSupply(CONST.ID_ITEM_SELLING_TYPE_UNIT);
    // this.itemSupplyUnit = 이후에 별도 계산함
    // this.itemVatUnit = 이후에 별도 계산함
    // this.itemPriceUnit = 이후에 별도 계산함
    this.sellingSupplyRated = this.getRatedSellingSupply(this.sellingSupplyUnit);
    // this.itemSupplyRated = 이후에 별도 계산함
    // this.itemVatRated = 이후에 별도 계산함
    // this.itemPriceRated = 이후에 별도 계산함
    this.sellingSupply = this.getEditedSellingSupply(
      this.sellingSupplyRated,
      this.productName,
      this.specs,
      this.brands,
    );
    // this.itemSupply = 이후에 별도 계산함
    // this.itemVat = 이후에 별도 계산함
    // this.itemPrice = 이후에 별도 계산함

    // 테스트
    // if (this.productName === '파이프' && this.specs === '원형, 일반, 31.8mm, 1.5T, 12m, 벤딩') {
    //   console.log('=================');
    //   console.log('this.sellingSupplyUnit: ' + this.sellingSupplyUnit);
    //   console.log('this.sellingSupply: ' + this.sellingSupply);
    // }
  }

  /**
   * 설계품목배열으로부터 설계 수량 및 미가공 견적 수량 계산하기
   * - 설계품목배열이 있는 경우에만 수행가능
   * - 견적상품을 수정할때 사용됨
   */
  public calculateQuantityFromDesignItems(): void {
    if (this.designItemAC && this.designItemAC.length > 0) {
      let designQuantityTemp: number = 0;
      let estimateQuantityRawTemp: number = 0;
      for (const designItem of this.designItemAC) {
        designQuantityTemp += designItem.designQuantityTemp;
        estimateQuantityRawTemp += designItem.designQuantityTemp;
      }
      this.designQuantity = designQuantityTemp;
      this.estimateQuantityRaw = estimateQuantityRawTemp;
    }
  }

  /**
   * 견적 수량 계산하기
   * - 세트 단위수량 적용
   */
  public calculateEstimateQuantity(): void {
    if (this.setUnitQuantity > 0) {
      this.estimateQuantity = CommonUtil.roundUpX(this.estimateQuantityRaw, this.setUnitQuantity);
    } else {
      this.estimateQuantity = 0;
    }
  }

  /**
   * 견적 과세 형태 지정하기
   * - 품목의 과세 형태와 고객의 상태에 따라 달라짐
   */
  public setEstimateTaxType(): void {
    const { productCompanyModel } = this.unitPriceModel.specCompanyModel;

    if (productCompanyModel.taxType === CONST.LB_TAX_TYPE_TAXATION) {
      // 품목의 과세 형태 "과세"
      this.estimateTaxType = CONST.LB_ESTIMATE_TAX_TYPE_TAXATION;
    } else if (productCompanyModel.taxType === CONST.LB_TAX_TYPE_TAXATION_AND_REFUND) {
      // 품목의 과세 형태 "과세+환급"
      if (this.estimate.design.customer && this.estimate.design.customer.agriRegistered) {
        // 고객의 농입인경영체 등록 = 환급
        this.estimateTaxType = CONST.LB_ESTIMATE_TAX_TYPE_REFUND;
      } else {
        // 고객 미등록 또는 농입인경영체 미등록 = 과세
        this.estimateTaxType = CONST.LB_ESTIMATE_TAX_TYPE_TAXATION;
      }
    } else if (productCompanyModel.taxType === CONST.LB_TAX_TYPE_TAXATION_AND_ZERO_RATE) {
      // 품목의 과세 형태 "과세+영세율"
      if (this.estimate.design.customer && this.estimate.design.customer.agriRegistered) {
        // 고객의 농입인경영체 등록 = 영세율
        this.estimateTaxType = CONST.LB_ESTIMATE_TAX_TYPE_ZERO_RATE;
      } else {
        // 고객 미등록 또는 농입인경영체 미등록 = 과세
        this.estimateTaxType = CONST.LB_ESTIMATE_TAX_TYPE_TAXATION;
      }
    } else if (productCompanyModel.taxType === CONST.LB_TAX_TYPE_FREE) {
      // 품목의 과세 형태 "면세"
      this.estimateTaxType = CONST.LB_ESTIMATE_TAX_TYPE_FREE;
    } else {
      // 그외 기본 = 과세
      this.estimateTaxType = CONST.LB_ESTIMATE_TAX_TYPE_TAXATION;
    }
  }

  /**
   * 배율적용된 매출가 가져오기
   * - 배율이 달라졌거나, 과거 견적서에서 수정된 매출가가 있는 경우 반영
   * @param sellingSupplyUnit 단가적용 매출가
   */
  public getRatedSellingSupply(sellingSupplyUnit: number): number {
    let sellingSupplyTemp: number = 0;
    // 단가 배율이 1인 경우 단가 배율을 적용하지 않음
    if (this.estimate.rateUnit === 1) {
      sellingSupplyTemp = sellingSupplyUnit;
    } else {
      // 단가 배율 반영
      sellingSupplyTemp = Math.round(CommonUtil.fixFloat(sellingSupplyUnit * this.estimate.rateUnit));
    }
    return sellingSupplyTemp;
  }

  /**
   * 매출가 가져오기
   * @param sellingSupplyRated 배율적용 매출가
   * @param productName 상품명
   * @param specs 규격
   * @param brands 상표
   */
  public getEditedSellingSupply(
    sellingSupplyRated: number,
    productName: string,
    specs: string,
    brands: string,
  ): number {
    let sellingSupplyTemp: number = sellingSupplyRated;
    // 동일 상품 & 배율적용 매출가가 과거와 동일한 경우, 과거의 매출가를 반영
    for (const estimateItemBefore of this.estimate.normalEstimateItemsBefore) {
      if (
        estimateItemBefore.productName === productName &&
        estimateItemBefore.specs === specs &&
        estimateItemBefore.brands === brands
      ) {
        if (sellingSupplyTemp === estimateItemBefore.sellingSupplyRated) {
          sellingSupplyTemp = estimateItemBefore.sellingSupply;
          break;
        }
      }
    }
    return sellingSupplyTemp;
  }

  /**
   * 자재 금액 계산하기: 견적 과세 형태에 따라 계산이 달라짐
   * - 금액 (공급가액) = 수량 * 매출가 (공급가액)
   * - 금액 (부가세) = 금액 (공급가액) / 10 (내림처리)
   * - 금액 (공급대가) = 금액 (공급가액) + 금액 (부가세)
   */
  public calculateItemPrice(): void {
    if (this.estimateTaxType === CONST.LB_ESTIMATE_TAX_TYPE_TAXATION) {
      // 견적 과세 형태 = 과세
      this.itemSupplyPurchase = this.estimateQuantity * this.sellingSupplyPurchase;
      this.itemVatPurchase = Math.floor(this.itemSupplyPurchase / 10);
      this.itemPricePurchase = this.itemSupplyPurchase + this.itemVatPurchase;
      this.itemSupplyUnit = this.estimateQuantity * this.sellingSupplyUnit;
      this.itemVatUnit = Math.floor(this.itemSupplyUnit / 10);
      this.itemPriceUnit = this.itemSupplyUnit + this.itemVatUnit;
      this.itemSupplyRated = this.estimateQuantity * this.sellingSupplyRated;
      this.itemVatRated = Math.floor(this.itemSupplyRated / 10);
      this.itemPriceRated = this.itemSupplyRated + this.itemVatRated;
      this.itemSupply = this.estimateQuantity * this.sellingSupply;
      this.itemVat = Math.floor(this.itemSupply / 10);
      this.itemPrice = this.itemSupply + this.itemVat;
    } else if (this.estimateTaxType === CONST.LB_ESTIMATE_TAX_TYPE_REFUND) {
      // 견적 과세 형태 = 환급
      this.itemSupplyPurchase = this.estimateQuantity * this.sellingSupplyPurchase;
      this.itemVatPurchase = Math.floor(this.itemSupplyPurchase / 10);
      this.itemPricePurchase = this.itemSupplyPurchase + this.itemVatPurchase;
      this.itemSupplyUnit = this.estimateQuantity * this.sellingSupplyUnit;
      this.itemVatUnit = Math.floor(this.itemSupplyUnit / 10);
      this.itemPriceUnit = this.itemSupplyUnit + this.itemVatUnit;
      this.itemSupplyRated = this.estimateQuantity * this.sellingSupplyRated;
      this.itemVatRated = Math.floor(this.itemSupplyRated / 10);
      this.itemPriceRated = this.itemSupplyRated + this.itemVatRated;
      this.itemSupply = this.estimateQuantity * this.sellingSupply;
      this.itemVat = Math.floor(this.itemSupply / 10);
      this.itemPrice = this.itemSupply + this.itemVat;
    } else if (this.estimateTaxType === CONST.LB_ESTIMATE_TAX_TYPE_ZERO_RATE) {
      // 견적 과세 형태 = 영세율
      this.itemSupplyPurchase = this.estimateQuantity * this.sellingSupplyPurchase;
      this.itemVatPurchase = 0;
      this.itemPricePurchase = this.itemSupplyPurchase + this.itemVatPurchase;
      this.itemSupplyUnit = this.estimateQuantity * this.sellingSupplyUnit;
      this.itemVatUnit = 0;
      this.itemPriceUnit = this.itemSupplyUnit + this.itemVatUnit;
      this.itemSupplyRated = this.estimateQuantity * this.sellingSupplyRated;
      this.itemVatRated = 0;
      this.itemPriceRated = this.itemSupplyRated + this.itemVatRated;
      this.itemSupply = this.estimateQuantity * this.sellingSupply;
      this.itemVat = 0;
      this.itemPrice = this.itemSupply + this.itemVat;
    } else if (this.estimateTaxType === CONST.LB_ESTIMATE_TAX_TYPE_FREE) {
      // 견적 과세 형태 = 면세
      this.itemSupplyPurchase = this.estimateQuantity * this.sellingSupplyPurchase;
      this.itemVatPurchase = 0;
      this.itemPricePurchase = this.itemSupplyPurchase + this.itemVatPurchase;
      this.itemSupplyUnit = this.estimateQuantity * this.sellingSupplyUnit;
      this.itemVatUnit = 0;
      this.itemPriceUnit = this.itemSupplyUnit + this.itemVatUnit;
      this.itemSupplyRated = this.estimateQuantity * this.sellingSupplyRated;
      this.itemVatRated = 0;
      this.itemPriceRated = this.itemSupplyRated + this.itemVatRated;
      this.itemSupply = this.estimateQuantity * this.sellingSupply;
      this.itemVat = 0;
      this.itemPrice = this.itemSupply + this.itemVat;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
