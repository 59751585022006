import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCordWire } from "vhows-design/src/object/design/item/list/ItemCordWire";
import { ItemFountainHose } from "vhows-design/src/object/design/item/list/ItemFountainHose";
import { ItemFountainHoseConnect } from "vhows-design/src/object/design/item/list/ItemFountainHoseConnect";
import { ItemFountainHoseHanger } from "vhows-design/src/object/design/item/list/ItemFountainHoseHanger";
import { Part } from "vhows-design/src/object/design/base/Part";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { WateringSample_HoseUpperFountainHose } from "vhows-design/src/object/design/watering/watering/WateringSample_HoseUpperFountainHose";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [WateringSample_HoseUpperFountainHose],
})
export class WateringPart_HoseUpper extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public fountainHoseSample: WateringSample_HoseUpperFountainHose; // 분수호스 샘플

  // 아이템
  public hose_fountainHose: ItemFountainHose;
  public hose_fountainHoseConnect: ItemFountainHoseConnect;
  public hose_fountainHoseHangerUpper: ItemFountainHoseHanger;
  public hoseLength_cord: ItemCordWire;
  public hoseVertical_cord: ItemCordWire;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wateringBuildingNumber: number = 0; // 관수 동수
  @jsonMember(Number)
  public _hoseLineNumber: number; // 분수호스 줄수
  @jsonMember(Number)
  public _hoseLength: number; // 분수호스 길이
  @jsonMember(Number)
  public _hangerInterval: number; // 분수호스 걸이 간격

  /**
   * 관수 동수
   */
  public get wateringBuildingNumber(): number {
    return this._wateringBuildingNumber;
  }

  //
  public set wateringBuildingNumber(value: number) {
    this._wateringBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 분수호스 줄수
   */
  public get hoseLineNumber(): number {
    return this._hoseLineNumber;
  }

  //
  public set hoseLineNumber(value: number) {
    this._hoseLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<WateringPosition>this.position).hoseConnectPart.algorithm_subPipeLength();
    (<WateringPosition>this.position).hoseConnectPart.algorithmPart();
  }

  /**
   * 분수호스 길이
   */
  public get hoseLength(): number {
    return this._hoseLength;
  }

  //
  public set hoseLength(value: number) {
    this._hoseLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 분수호스 걸이 간격
   */
  public get hangerInterval(): number {
    return this._hangerInterval;
  }

  //
  public set hangerInterval(value: number) {
    this._hangerInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.fountainHoseSample = new WateringSample_HoseUpperFountainHose();

    this.sampleAC = [this.fountainHoseSample];

    // 아이템
    this.hose_fountainHose = new ItemFountainHose();
    this.hose_fountainHoseConnect = new ItemFountainHoseConnect();
    this.hose_fountainHoseHangerUpper = new ItemFountainHoseHanger();
    this.hoseLength_cord = new ItemCordWire();
    this.hoseVertical_cord = new ItemCordWire();

    this.itemAC = [
      this.hose_fountainHose,
      this.hose_fountainHoseConnect,
      this.hose_fountainHoseHangerUpper,
      this.hoseLength_cord,
      this.hoseVertical_cord,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.fountainHoseSample = <WateringSample_HoseUpperFountainHose>this.sampleAC[0];
    // 아이템
    this.hose_fountainHose = <ItemFountainHose>this.itemAC[0];
    this.hose_fountainHoseConnect = <ItemFountainHoseConnect>this.itemAC[1];
    this.hose_fountainHoseHangerUpper = <ItemFountainHoseHanger>this.itemAC[2];
    this.hoseLength_cord = <ItemCordWire>this.itemAC[3];
    this.hoseVertical_cord = <ItemCordWire>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param hoseLineNumber: number 분수호스 줄수
   * @param hangerInterval: number 분수호스 걸이 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    hoseLineNumber: number = 0,
    hangerInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._hoseLineNumber = hoseLineNumber;
    this._hangerInterval = hangerInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.fountainHoseSample.setDefaultData(
        0,
        CONST.ITEM_ID_FOUNTAIN_HOSE,
        CONST.ITEM_NAME_FOUNTAIN_HOSE,
        "고설 분수호스",
        "고설양면, 200m",
        "신농, SNH302, 고설양면, 7cm, 3m, 200m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.hose_fountainHose.setDefaultData(
        0,
        CONST.ITEM_ID_FOUNTAIN_HOSE,
        CONST.ITEM_NAME_FOUNTAIN_HOSE,
        "고설 분수호스",
        "고설양면, 200m",
        "신농, SNH302, 고설양면, 7cm, 3m, 200m",
      );
      this.hose_fountainHoseConnect.setDefaultData(
        1,
        CONST.ITEM_ID_FOUNTAIN_HOSE_CONNECT,
        CONST.ITEM_NAME_FOUNTAIN_HOSE_CONNECT,
        "고설 분수호스",
        "일반, 일자, 2인치",
      );
      this.hose_fountainHoseHangerUpper.setDefaultData(
        2,
        CONST.ITEM_ID_FOUNTAIN_HOSE_HANGER_UPPER,
        CONST.ITEM_NAME_FOUNTAIN_HOSE_HANGER_UPPER,
        "고설 분수호스 고정",
        "플라스틱 타이, -",
      );
      this.hoseLength_cord.setDefaultData(
        3,
        CONST.ITEM_ID_CORD,
        CONST.ITEM_NAME_CORD,
        "고설 분수호스 고정(길이방향)",
        "코드사, 실, 3mm, 800m",
      );
      this.hoseVertical_cord.setDefaultData(
        4,
        CONST.ITEM_ID_CORD,
        CONST.ITEM_NAME_CORD,
        "고설 분수호스 고정(수직방향)",
        "코드사, 실, 1.8mm, 1600m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_hoseLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.hose_fountainHose.selected = true;
    this.hose_fountainHose.visible = true;
    this.hose_fountainHoseConnect.selected = true;
    this.hose_fountainHoseConnect.visible = true;
    this.hose_fountainHoseHangerUpper.selected = true;
    this.hose_fountainHoseHangerUpper.visible = true;
    this.hoseLength_cord.selected = true;
    this.hoseLength_cord.visible = true;
    this.hoseVertical_cord.selected = true;
    this.hoseVertical_cord.visible = true;

    /// ////////  수량 ///////////

    let hoseRatio: number;
    if (this.hose_fountainHose.specLength > this.hoseLength) {
      hoseRatio = Math.floor(this.hose_fountainHose.specLength / this.hoseLength);
    } else {
      hoseRatio = this.hose_fountainHose.specLength / this.hoseLength;
    }
    this.hose_fountainHose.designQuantity = (this.hoseLineNumber * this.wateringBuildingNumber) / hoseRatio;

    //			hose_fountainHoseConnect.designQuantity = 0;

    if (this.hangerInterval !== 0) {
      this.hose_fountainHoseHangerUpper.designQuantity =
        (this.hoseLength / this.hangerInterval + 1) * this.hoseLineNumber * this.wateringBuildingNumber;
    } else {
      this.hose_fountainHoseHangerUpper.designQuantity = 0;
    }

    this.hoseLength_cord.designQuantity =
      (this.hoseLength * this.hoseLineNumber * this.wateringBuildingNumber) / this.hoseLength_cord.specLength;

    if (this.hangerInterval !== 0) {
      this.hoseVertical_cord.designQuantity =
        (this.basicLevel.heightRoofMax *
          (this.hoseLength / this.hangerInterval + 1) *
          this.hoseLineNumber *
          this.wateringBuildingNumber) /
        this.hoseLength_cord.specLength;
    } else {
      this.hoseVertical_cord.designQuantity = 0;
    }
  }

  /**
   * 알고리즘: 관수 동수 <- 관수 동수(관수 제어부)
   */
  public algorithm_wateringBuildingNumber(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    this.wateringBuildingNumber = mainpipePosition.wateringBuildingNumber;
  }

  /**
   * 알고리즘: 분수호스 길이 <- 기본정보
   */
  public algorithm_hoseLength(): void {
    this.hoseLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].length;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [고설 분수호스] 분수호스 <- 분수호스 샘플
   */
  public algorithmSpec_hose_fountainHose(): void {
    this.hose_fountainHose.specsList = this.fountainHoseSample.specsList;
    this.hose_fountainHose.specLength = this.fountainHoseSample.specLength;
    this.hose_fountainHose.brands = this.fountainHoseSample.brands;
  }

  /**
   * 규격 알고리즘: [고설 분수호스] 분수호스 연결 <- 없음
   */
  public algorithmSpec_hose_fountainHoseConnect(): void {}

  /**
   * 규격 알고리즘: [고설 분수호스 고정] 분수호스 고설 걸이 <- 없음
   */
  public algorithmSpec_hose_fountainHoseHangerUpper(): void {}

  /**
   * 규격 알고리즘: [고설 분수호스 고정(길이방향)] 끈 <- 없음
   */
  public algorithmSpec_hoseLength_cord(): void {}

  /**
   * 규격 알고리즘: [고설 분수호스 고정(수직방향)] 끈 <- 없음
   */
  public algorithmSpec_hoseVertical_cord(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
