import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";

/**
 * 정부사업 클래스
 * @author 이덕형
 * @copyright RUNean Inc.
 * @date 2016-07-08
 */
@jsonObject
export class SubsidyService extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 사업명. */
  @jsonMember(String)
  public serviceName: string;

  /** 비용. */
  @jsonMember(String)
  public expense: string;

  /** 주관청 및 담당부서. */
  @jsonMember(String)
  public institute: string;

  /** 담당자 이름. */
  @jsonMember(String)
  public managerName: string;

  /** 담당자 연락처. */
  @jsonMember(String)
  public managerPhone: string;

  /** 노트. */
  @jsonMember(String)
  public note: string;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design): void {
    this.design = design;
  }
}
