import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPartIL } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL";
import { CoverSampleIL_Vinyl } from "vhows-design/src/object/design/cover/cover/il/CoverSampleIL_Vinyl";
import { CoverSampleIL_VinylNormalSkirt } from "vhows-design/src/object/design/cover/cover/il/CoverSampleIL_VinylNormalSkirt";
import { CoverSampleIL_VinylWindbreak } from "vhows-design/src/object/design/cover/cover/il/CoverSampleIL_VinylWindbreak";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-29
 */
@jsonObject({
  knownTypes: [CoverSampleIL_Vinyl, CoverSampleIL_VinylNormalSkirt, CoverSampleIL_VinylWindbreak],
})
export class CoverPartIL_Vinyl extends CoverPartIL {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public vinylSample: CoverSampleIL_Vinyl; // 비닐 샘플
  public normalSkirtVinylSample: CoverSampleIL_VinylNormalSkirt; // 일반 치마 비닐 샘플
  public windbreakVinylSample: CoverSampleIL_VinylWindbreak; // 바람막이 비닐 샘플

  // 아이템
  protected item: ItemVinyl; // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.vinylSample = new CoverSampleIL_Vinyl();
    this.normalSkirtVinylSample = new CoverSampleIL_VinylNormalSkirt();
    this.windbreakVinylSample = new CoverSampleIL_VinylWindbreak();

    this.sampleAC = [this.vinylSample, this.normalSkirtVinylSample, this.windbreakVinylSample];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.vinylSample = <CoverSampleIL_Vinyl>this.sampleAC[0];
    this.normalSkirtVinylSample = <CoverSampleIL_VinylNormalSkirt>this.sampleAC[1];
    this.windbreakVinylSample = <CoverSampleIL_VinylWindbreak>this.sampleAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index === 0) {
      this.vinylSample.setDefaultData(
        0,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "",
        "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.normalSkirtVinylSample.setDefaultData(
        1,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "일반 치마",
        "0.1mm, 120cm, 56m, 일타, 말대(치마), 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.windbreakVinylSample.setDefaultData(
        2,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "바람막이",
        "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
    } else {
      if ((<CoverPositionIL>this.position).scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF) {
        // 2중이상 지붕만 0.08mm
        this.vinylSample.setDefaultData(
          0,
          CONST.ITEM_ID_VINYL,
          CONST.ITEM_NAME_VINYL,
          "",
          "0.08mm, 120cm, 56m, 일타, 박스, 끈 없음",
          "기본회사, 장수, 0.94",
        );
      } else {
        this.vinylSample.setDefaultData(
          0,
          CONST.ITEM_ID_VINYL,
          CONST.ITEM_NAME_VINYL,
          "",
          "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
          "기본회사, 장수, 0.94",
        );
      }
      this.normalSkirtVinylSample.setDefaultData(
        1,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "일반 치마",
        "0.1mm, 120cm, 56m, 일타, 말대(치마), 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.windbreakVinylSample.setDefaultData(
        2,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "바람막이",
        "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      // 일반 치마 비닐 샘플
      const normalSkirtVinylSample: CoverSampleIL_VinylNormalSkirt = new CoverSampleIL_VinylNormalSkirt();
      normalSkirtVinylSample.setAssociation(design, struct, work, level, position, this);
      normalSkirtVinylSample.setDefaultData(
        1,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "일반 치마",
        "0.1mm, 120cm, 56m, 일타, 말대(치마), 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.sampleAC.push(normalSkirtVinylSample);
      // 바람막이 비닐 샘플
      const windbreakVinylSample: CoverSampleIL_VinylWindbreak = new CoverSampleIL_VinylWindbreak();
      windbreakVinylSample.setAssociation(design, struct, work, level, position, this);
      windbreakVinylSample.setDefaultData(
        2,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "바람막이",
        "0.1mm, 120cm, 56m, 일타, 박스, 끈 없음",
        "기본회사, 장수, 0.94",
      );
      this.sampleAC.push(windbreakVinylSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    //----------------------------------
    // 품목
    // 피복객체의 값을 실제 품목에 반영
    //----------------------------------

    // 측면 및 지붕
    if (this.coverPosition.scopeSelectedSide === true || this.coverPosition.scopeSelectedRoof === true) {
      // 2동이상
      if (this.basic.buildingNumber >= 2) {
        // 가변길이 여부
        if (this.basic.isVariableLength === true) {
          // 동수만큼 품목 생성
          for (let i: number = 0; i < this.basic.buildingNumber; i++) {
            // 양끝2동
            if (i === 0 || i === this.basic.buildingNumber - 1) {
              // 양끝동 지붕(+측면)
              if (this.coverEdgeRoof != null) {
                this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverEdgeRoof.purpose}`;
                this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeRoof.width));
                this.item.specLength = this.coverEdgeRoof.lengthAC[i];
                this.item.designQuantity = this.coverEdgeRoof.quantity;

                this.itemAC.push(this.item);
              }

              // 양끝동 지붕(+측면) - 비대칭 천창(짧은쪽)
              if (this.coverEdgeSkyShort != null) {
                this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverEdgeSkyShort.purpose}`;
                this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeSkyShort.width));
                this.item.specLength = this.coverEdgeSkyShort.lengthAC[i];
                this.item.designQuantity = this.coverEdgeSkyShort.quantity;

                this.itemAC.push(this.item);
              }

              // 양끝동 측면
              if (this.coverEdgeSide != null) {
                this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverEdgeSide.purpose}`;
                this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeSide.width));
                this.item.specLength = this.coverEdgeSide.lengthAC[i];
                this.item.designQuantity = this.coverEdgeSide.quantity;

                this.itemAC.push(this.item);
              }
            } else {
              // 중앙동 지붕
              if (this.coverCenterRoof != null) {
                this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverCenterRoof.purpose}`;
                this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverCenterRoof.width));
                this.item.specLength = this.coverCenterRoof.lengthAC[i];
                this.item.designQuantity = this.coverCenterRoof.quantity;

                this.itemAC.push(this.item);
              }

              // 중앙동 지붕 - 비대칭 천창(짧은쪽)
              if (this.coverCenterSkyShort != null) {
                this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

                this.item.purpose = `${i + 1}번동 피복: ${this.coverCenterSkyShort.purpose}`;
                this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverCenterSkyShort.width));
                this.item.specLength = this.coverCenterSkyShort.lengthAC[i];
                this.item.designQuantity = this.coverCenterSkyShort.quantity;

                this.itemAC.push(this.item);
              }
            }
          }
        } else {
          // 양끝동 지붕(+측면)
          if (this.coverEdgeRoof != null) {
            this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

            this.item.purpose = `양끝동 피복: ${this.coverEdgeRoof.purpose}`;
            this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeRoof.width));
            this.item.specLength = this.coverEdgeRoof.length;
            this.item.designQuantity = this.coverEdgeRoof.quantity * 2;

            this.itemAC.push(this.item);
          }

          // 양끝동 지붕(+측면) - 비대칭 천창(짧은쪽)
          if (this.coverEdgeSkyShort != null) {
            this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

            this.item.purpose = `양끝동 피복: ${this.coverEdgeSkyShort.purpose}`;
            this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeSkyShort.width));
            this.item.specLength = this.coverEdgeSkyShort.length;
            this.item.designQuantity = this.coverEdgeSkyShort.quantity * 2;

            this.itemAC.push(this.item);
          }

          // 양끝동 측면
          if (this.coverEdgeSide != null) {
            this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

            this.item.purpose = `양끝동 피복: ${this.coverEdgeSide.purpose}`;
            this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeSide.width));
            this.item.specLength = this.coverEdgeSide.length;
            this.item.designQuantity = this.coverEdgeSide.quantity * 2;

            this.itemAC.push(this.item);
          }

          // 중앙동 지붕
          if (this.coverCenterRoof != null) {
            this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

            this.item.purpose = `중앙동 피복: ${this.coverCenterRoof.purpose}`;
            this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverCenterRoof.width));
            this.item.specLength = this.coverCenterRoof.length;
            this.item.designQuantity = this.coverCenterRoof.quantity * (this.basic.buildingNumber - 2);

            this.itemAC.push(this.item);
          }

          // 중앙동 지붕 - 비대칭 천창(짧은쪽)
          if (this.coverCenterSkyShort != null) {
            this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

            this.item.purpose = `중앙동 피복: ${this.coverCenterSkyShort.purpose}`;
            this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverCenterSkyShort.width));
            this.item.specLength = this.coverCenterSkyShort.length;
            this.item.designQuantity = this.coverCenterSkyShort.quantity * (this.basic.buildingNumber - 2);

            this.itemAC.push(this.item);
          }
        }
      } else {
        // 측면
        if (this.coverEdgeSide != null) {
          this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

          this.item.purpose = this.coverEdgeSide.purpose;
          this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeSide.width));
          this.item.specLength = this.coverEdgeSide.length;
          this.item.designQuantity = this.coverEdgeSide.quantity;

          this.itemAC.push(this.item);
        }

        // 지붕 및 측면
        if (this.coverEdgeRoof != null) {
          this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

          this.item.purpose = this.coverEdgeRoof.purpose;
          this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeRoof.width));
          this.item.specLength = this.coverEdgeRoof.length;
          this.item.designQuantity = this.coverEdgeRoof.quantity;

          this.itemAC.push(this.item);
        }

        // 지붕 및 측면 - 비대칭 천창(짧은쪽)
        if (this.coverEdgeSkyShort != null) {
          this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

          this.item.purpose = this.coverEdgeSkyShort.purpose;
          this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverEdgeSkyShort.width));
          this.item.specLength = this.coverEdgeSkyShort.length;
          this.item.designQuantity = this.coverEdgeSkyShort.quantity;

          this.itemAC.push(this.item);
        }
      }
    }

    // 앞면(+뒷면)
    if (this.coverFront != null) {
      this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

      this.item.purpose = `마구리 피복: ${this.coverFront.purpose}`;
      this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverFront.width));
      this.item.specLength = this.coverFront.length;
      this.item.designQuantity = 1;

      this.itemAC.push(this.item);
    }

    // 뒷면
    if (this.coverBack != null) {
      this.item = ItemVinyl.getNewDesignItemBySample(this, this.vinylSample);

      this.item.purpose = `마구리 피복: ${this.coverBack.purpose}`;
      this.item.specWidth = this.item.getFoldedWidth(CommonUtil.convertM2CM(this.coverBack.width));
      this.item.specLength = this.coverBack.length;
      this.item.designQuantity = 1;

      this.itemAC.push(this.item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);

    /// //////// 외부 ///////////

    // 알고리즘 호출: 피복 파트
    (<CoverLevelIL>this.level).callAlgorithm_coverPartByCover();

    // 알고리즘 호출: 피복 고정 파트
    (<CoverLevelIL>this.level).callAlgorithm_fixingPartByCover();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 아이템 <- 샘플
   */
  public algorithmSpec_item(): void {
    for (const item of this.itemAC as ItemVinyl[]) {
      item.specsList = this.vinylSample.specsList;
      item.specWidthType = this.vinylSample.specWidthType;
      item.specPackingType = this.vinylSample.specPackingType;
      item.specStringType = this.vinylSample.specStringType;
      item.brands = this.vinylSample.brands;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
