import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 미니 스프링클러
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemMiniSprinkler extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성]
  public _specFeature: string = undefined; // 특성

  // 하우스 설계 > 상표 : [회사, 모델, 살수형태, 살수량, 살수직경]
  public _brandModelName: string = undefined; // 모델
  public _brandWateringType: string = undefined; // 살수형태
  public _brandWateringQuantity: string = undefined; // 살수량
  public _brandWateringDiameter: string = undefined; // 살수직경

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  /**
   * 모델
   */
  public get brandModelName(): string {
    return this._brandModelName;
  }
  //
  public set brandModelName(value: string) {
    this._brandModelName = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 살수형태
   */
  public get brandWateringType(): string {
    return this._brandWateringType;
  }
  //
  public set brandWateringType(value: string) {
    this._brandWateringType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 살수량
   */
  public get brandWateringQuantity(): string {
    return this._brandWateringQuantity;
  }
  //
  public set brandWateringQuantity(value: string) {
    this._brandWateringQuantity = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 살수직경
   */
  public get brandWateringDiameter(): string {
    return this._brandWateringDiameter;
  }
  //
  public set brandWateringDiameter(value: string) {
    this._brandWateringDiameter = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.miniSprinklerCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    const brandsArray: string[] = this._brands.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandWateringType = brandsArray[2];
    this.brandWateringQuantity = brandsArray[3];
    this.brandWateringDiameter = brandsArray[4];
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    const brandsArray: string[] = this._brandsList.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandWateringType = brandsArray[2];
    this.brandWateringQuantity = brandsArray[3];
    this.brandWateringDiameter = brandsArray[4];
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList =
      this._brandCompany +
      CONST.DELIMITER_BRAND +
      this._brandModelName +
      CONST.DELIMITER_BRAND +
      this._brandWateringType +
      CONST.DELIMITER_BRAND +
      this._brandWateringQuantity +
      CONST.DELIMITER_BRAND +
      this._brandWateringDiameter;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }
}
