import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { ProductModel } from "vhows-design/src/object/item/product/ProductModel";
import { BrandCompanyModel } from "vhows-design/src/object/item/brand/BrandCompanyModel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-26
 */
@jsonObject({
  knownTypes: [ProductModel],
})
export class BrandModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 상품 모델 */
  @jsonMember(ProductModel)
  public productModel: ProductModel = null;
  /** 조직별 상표 모델 배열 */
  @jsonArrayMember(() => BrandCompanyModel)
  public brandCompanyModelAC: BrandCompanyModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 상표 리스트 (사용 안함)

  /** 상표 회사 */
  public brandCompany: string = null;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 상표 리스트 */
  @jsonMember(String)
  public label: string = null;

  /** 상표 수치 */
  @jsonMember(Number)
  public num1: number = null;

  /** 인덱스 */
  @jsonMember(Number)
  public index: number = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param brandsList 상표 리스트
   * @param num1 상표 수치
   * @param productModel 상품 모델
   * @param brandCompanyModelAC 조직별 상표 모델 배열
   */
  constructor(
    brandsList: string = CONST.ITEM_BRAND_VALUE_DEFAULT,
    num1: number = 0,
    productModel: ProductModel = null,
    brandCompanyModelAC: BrandCompanyModel[] = null,
  ) {
    super();

    this.label = brandsList;
    this.num1 = num1;
    this.productModel = productModel;
    this.brandCompanyModelAC = brandCompanyModelAC || [new BrandCompanyModel()];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 품목 모델
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
