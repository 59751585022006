import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2019-12-09
 */
export class Analysis {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 뷰 데이터

  /** 구조해석 팝업 가시성 */
  public popupVisible: boolean = false;
  /** 구조해석 모드 인덱스 */
  public modeSelectedIndex: number = CONST.ID_ANALYSIS_STRENGTH;
  /** 구조해석 열기 메세지 */
  public openMessage: string = "";

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param design 설계 객체
   */
  constructor(design: Design) {
    this.design = design;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design): void {
    this.design = design;
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {}

  //----------------------------------
  // 하우스 시방
  //----------------------------------

  /**
   * 구조해석 객체 생성하기
   */
  public createAnalysis(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
