import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { ScreennetPositionIL } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { WindbreakPositionIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPositionIL";
import { SwitcherPartIL_Manual } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Manual";
import { SwitcherPartIL_Power } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Power";
import { SwitcherPartIL_Axis } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Axis";
import { SwitcherPartIL_Support } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Support";
import { SwitcherPartIL_FixingNormalClip } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_FixingNormalClip";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject({
  knownTypes: [
    SwitcherPartIL_Axis,
    SwitcherPartIL_FixingNormalClip,
    SwitcherPartIL_Manual,
    SwitcherPartIL_Power,
    SwitcherPartIL_Support,
  ],
})
export class SwitcherPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public coverLevel: CoverLevelIL; // 기초 피복 중수

  public manual1Part: SwitcherPartIL_Manual;
  public manual2Part: SwitcherPartIL_Manual;
  public power1Part: SwitcherPartIL_Power;
  public power2Part: SwitcherPartIL_Power;
  public axisPart: SwitcherPartIL_Axis;
  public supportPart: SwitcherPartIL_Support;
  public fixingNormalClipPart: SwitcherPartIL_FixingNormalClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 개폐 방향값 <- 개폐 방향
   */
  public get switchWayValue(): number {
    if (this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH) {
      return 2;
    } else if (
      this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE ||
      this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ON
    ) {
      return 1;
    }
    return 0;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _switchWay: string = null; // 개폐 방향
  @jsonMember(Number)
  public _switchHeight: number = null; // 개폐 높이
  @jsonMember(Number)
  public _switchOrderMax: number = null; // 개폐 최대 차수
  @jsonMember(Number)
  public _switchStep: number = null; // 개폐 단수
  @jsonMember(String)
  public _supportType: string = null; // 지지 형태
  @jsonMember(String)
  public _support2Type: string = null; // 지지 형태(2차)
  @jsonMember(String)
  public _fixingType: string = null; // 고정(피복) 형태
  @jsonMember(String)
  public _fixing2Type: string = null; // 고정(피복) 형태(2차)

  /**
   * 개폐 방향
   */
  public get switchWay(): string {
    return this._switchWay;
  }

  public set switchWay(value: string) {
    this._switchWay = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 위치
    this.algorithm_switchWayColumnAndWing();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_switchWayByLevel1();
    }

    /// //////// 외부 ///////////

    // 제어기
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }

    // 피복 고정
    (<FixingPositionIL>(
      this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_lineNumber();
    (<FixingPositionIL>this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]).algorithm_status();

    // 치마
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).stringPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingSkirtClipPart.algorithmPart();

    // 바람막이
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.level1.positionAC[this.index]
      )).fixingNormalPadPart.algorithmPart();
      (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.level1.positionAC[this.index]
      )).fixingLaggingPadPart.algorithmPart();
      (<ScreennetPositionIL>this.struct.screennetWorkIL.level1.positionAC[this.index]).fixingWoodPart.algorithmPart();
      (<ScreennetPositionIL>this.struct.screennetWorkIL.level1.positionAC[this.index]).screennetPart.algorithmPart();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 개폐 높이
   */
  public get switchHeight(): number {
    return this._switchHeight;
  }

  public set switchHeight(value: number) {
    this._switchHeight = value;

    // 알고리즘
    this.supportPart.algorithm_supportLength();
    this.supportPart.algorithm_support2Length();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_switchHeightByLevel1();
    }

    /// //////// 외부 ///////////

    // 바람막이
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).windbreakPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPositionIL>this.struct.screennetWorkIL.level1.positionAC[this.index]).screennetPart.algorithmPart();
    }

    // 밴드끈
    if (this.level.index === 0) {
      this.struct.bandstringWorkIL.level1.trunkPosition.bandstringPart.algorithm_bandstringLength();
      this.struct.bandstringWorkIL.level1.trunkPosition.polyPart.algorithm_bandstringLength();
    }
  }

  /**
   * 개폐 단수
   */
  public get switchStep(): number {
    return this._switchStep;
  }

  public set switchStep(value: number) {
    this._switchStep = value;

    // 알고리즘

    // 파트 활성화

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 제어기
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }

    // 피복 고정
    (<FixingPositionIL>(
      this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_lineNumber();
    (<FixingPositionIL>this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]).algorithm_status();

    // 치마
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).stringPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingSkirtClipPart.algorithmPart();

    // 바람막이
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalPadPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingNormalClipPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingLaggingPadPart.algorithmPart();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).fixingWoodPart.algorithmPart();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.level1.positionAC[this.index]
      )).fixingNormalPadPart.algorithmPart();
      (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.level1.positionAC[this.index]
      )).fixingLaggingPadPart.algorithmPart();
      (<ScreennetPositionIL>this.struct.screennetWorkIL.level1.positionAC[this.index]).fixingWoodPart.algorithmPart();
      (<ScreennetPositionIL>this.struct.screennetWorkIL.level1.positionAC[this.index]).screennetPart.algorithmPart();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 개폐 최대 차수
   */
  public get switchOrderMax(): number {
    return this._switchOrderMax;
  }

  public set switchOrderMax(value: number) {
    this._switchOrderMax = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_support2TypeBySwitchOrder();
    this.algorithm_fixing2TypeBySwitchOrder();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 제어기
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }
  }

  /**
   * 지지 형태
   */
  public get supportType(): string {
    return this._supportType;
  }

  public set supportType(value: string) {
    this._supportType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationBySupportType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 지지 형태(2차)
   */
  public get support2Type(): string {
    return this._support2Type;
  }

  public set support2Type(value: string) {
    this._support2Type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationBySupportType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 고정(피복) 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  //
  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 고정(피복)(2차) 형태
   */
  public get fixing2Type(): string {
    return this._fixing2Type;
  }

  //
  public set fixing2Type(value: string) {
    this._fixing2Type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationBySupportType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    // 위치
    this.algorithm_switchWayColumnAndWing();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_selectedByLevel1();
    }

    /// //////// 외부 ///////////
    // 마구리
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.label === CONST.LB_POSITION_FRONT) {
        (<EndpieceLevelIL>(
          this.struct.endpieceWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_gateBuildingNumber();
      } else if (this.label === CONST.LB_POSITION_BACK) {
        (<EndpieceLevelIL>(
          this.struct.endpieceWorkIL.levelAC[this.level.index]
        )).backPosition.algorithm_gateBuildingNumber();
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.label === CONST.LB_POSITION_FRONT) {
        (<EndpieceLevelVL>(
          this.struct.endpieceWorkVL.levelAC[this.level.index]
        )).frontPosition.algorithm_gateBuildingNumber();
      } else if (this.label === CONST.LB_POSITION_BACK) {
        (<EndpieceLevelVL>(
          this.struct.endpieceWorkVL.levelAC[this.level.index]
        )).backPosition.algorithm_gateBuildingNumber();
      }
    }

    // 제어기
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_selectedBySwitcherByBasic();
    if (this.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageSwitcherQuantity();
    }

    // 피복 고정
    (<FixingPositionIL>(
      this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_selectedBySwitcher();
    (<FixingPositionIL>(
      this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_lineNumber();
    (<FixingPositionIL>(
      this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_endpieceColumnLineNumber();
    (<FixingPositionIL>(
      this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_endpieceMiddleLineNumber();
    (<FixingPositionIL>this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]).algorithm_status();

    // 치마
    (<SkirtPositionIL>this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]).algorithm_selected();
    (<SkirtPositionIL>this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]).algorithm_enabled();

    // 바람막이
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_selected();
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_enabled();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.levelAC[this.level.index].positionAC[this.index]
      )).algorithm_selected();
      (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.levelAC[this.level.index].positionAC[this.index]
      )).algorithm_enabled();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_supportTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    this.algorithm_typeByPosition();

    // 다른 중수
    if (this.level.index === 0) {
      this.algorithm_typeByLevel1();
    }

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_selectedBySwitcherByBasic();
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageSwitcherQuantity();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.manual1Part = new SwitcherPartIL_Manual();
    this.manual2Part = new SwitcherPartIL_Manual();
    this.power1Part = new SwitcherPartIL_Power();
    this.power2Part = new SwitcherPartIL_Power();
    this.axisPart = new SwitcherPartIL_Axis();
    this.supportPart = new SwitcherPartIL_Support();
    this.fixingNormalClipPart = new SwitcherPartIL_FixingNormalClip();

    this.partAC = [
      this.manual1Part,
      this.manual2Part,
      this.power1Part,
      this.power2Part,
      this.axisPart,
      this.supportPart,
      this.fixingNormalClipPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.manual1Part = <SwitcherPartIL_Manual>this.partAC[0];
    this.manual2Part = <SwitcherPartIL_Manual>this.partAC[1];
    this.power1Part = <SwitcherPartIL_Power>this.partAC[2];
    this.power2Part = <SwitcherPartIL_Power>this.partAC[3];
    this.axisPart = <SwitcherPartIL_Axis>this.partAC[4];
    this.supportPart = <SwitcherPartIL_Support>this.partAC[5];
    this.fixingNormalClipPart = <SwitcherPartIL_FixingNormalClip>this.partAC[6];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param switchWay: string 개폐 방향
   * @param switchHeight: number 개폐 높이
   * @param switchOrderMax: number 개폐 차수
   * @param 수: number 개폐 단수
   * @param supportType: string 지지 형태
   * @param supportType: string 지지 형태(2차)
   * @param fixingType: string 고정(피복) 형태
   * @param fixing2Type: string 고정(피복) 형태(2차)
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    switchWay: string = "",
    switchHeight: number = 0,
    switchOrderMax: number = 0,
    switchStep: number = 0,
    supportType: string = "",
    support2Type: string = "",
    fixingType: string = "",
    fixing2Type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._switchWay = switchWay;
    this._switchHeight = switchHeight;
    this._switchOrderMax = switchOrderMax;
    this._switchStep = switchStep;
    this._supportType = supportType;
    this._support2Type = support2Type;
    this._fixingType = fixingType;
    this._fixing2Type = fixing2Type;

    if (this.level.index === 0) {
      // 수동 개폐기 1차
      this.manual1Part.setDefaultData(
        CONST.INDEX_IL_SWITCHER_MANUAL1,
        false,
        false,
        false,
        CONST.LB_SWITCHER_MANUAL1,
        "",
      );

      // 수동 개폐기 2차
      this.manual2Part.setDefaultData(
        CONST.INDEX_IL_SWITCHER_MANUAL2,
        false,
        false,
        false,
        CONST.LB_SWITCHER_MANUAL2,
        "",
      );

      // 동력 개폐기 1차
      this.power1Part.setDefaultData(CONST.INDEX_IL_SWITCHER_POWER1, true, false, true, CONST.LB_SWITCHER_POWER1, "");

      // 동력 개폐기 2차
      this.power2Part.setDefaultData(CONST.INDEX_IL_SWITCHER_POWER2, false, false, false, CONST.LB_SWITCHER_POWER2, "");

      // 개폐 축
      this.axisPart.setDefaultData(CONST.INDEX_IL_SWITCHER_AXIS, true, false, true, CONST.LB_SWITCHER_AXIS, "", true);

      // 개폐기 지지 - 수직형 지지
      if (label === CONST.LB_POSITION_SIDE_COLUMN) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          3.5,
          3.5,
        );
      } else if (label === CONST.LB_POSITION_SIDE_WING) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          2.0,
          2.0,
        );
      } else if (label === CONST.LB_POSITION_ROOF) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          2.0,
          2.0,
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          3.5,
          3.5,
        );
      }

      // 개폐기 지지 - 대각형 지지
      if (label === CONST.LB_POSITION_SIDE_COLUMN || label === CONST.LB_POSITION_SIDE_WING) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          2.5,
          2.5,
        );
      } else if (label === CONST.LB_POSITION_ROOF) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          3.0,
          3.0,
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          2.5,
          2.5,
        );
      }

      // 개폐기 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_SWITCHER_FIXING_NORMAL_CLIP,
        true,
        false,
        true,
        CONST.LB_SWITCHER_FIXING_NORMAL_CLIP,
        "",
        0.5,
        0.5,
      );
    } else {
      // 수동 개폐기 1차
      this.manual1Part.setDefaultData(
        CONST.INDEX_IL_SWITCHER_MANUAL1,
        false,
        false,
        false,
        CONST.LB_SWITCHER_MANUAL1,
        "",
      );

      // 수동 개폐기 2차
      this.manual2Part.setDefaultData(
        CONST.INDEX_IL_SWITCHER_MANUAL2,
        false,
        false,
        false,
        CONST.LB_SWITCHER_MANUAL2,
        "",
      );

      // 동력 개폐기 1차
      this.power1Part.setDefaultData(CONST.INDEX_IL_SWITCHER_POWER1, true, false, true, CONST.LB_SWITCHER_POWER1, "");

      // 동력 개폐기 2차
      this.power2Part.setDefaultData(CONST.INDEX_IL_SWITCHER_POWER2, false, false, false, CONST.LB_SWITCHER_POWER2, "");

      // 개폐 축
      this.axisPart.setDefaultData(CONST.INDEX_IL_SWITCHER_AXIS, true, false, true, CONST.LB_SWITCHER_AXIS, "", false);

      // 개폐기 지지 - 수직형 지지
      if (label === CONST.LB_POSITION_SIDE_COLUMN) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          3.5,
          3.5,
        );
      } else if (label === CONST.LB_POSITION_SIDE_WING) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          2.0,
          2.0,
        );
      } else if (label === CONST.LB_POSITION_ROOF) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          4.5,
          4.5,
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_VERTICAL,
          "",
          3.5,
          3.5,
        );
      }

      // 개폐기 지지 - 대각형 지지
      if (label === CONST.LB_POSITION_SIDE_COLUMN || label === CONST.LB_POSITION_SIDE_WING) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          2.5,
          2.5,
        );
      } else if (label === CONST.LB_POSITION_ROOF) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          true,
          false,
          true,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          4.0,
          4.0,
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        this.supportPart.setDefaultData(
          CONST.INDEX_IL_SWITCHER_SUPPORT,
          false,
          false,
          false,
          CONST.LB_SWITCHER_SUPPORT_DIAGONAL,
          "",
          2.5,
          2.5,
        );
      }

      // 개폐기 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_SWITCHER_FIXING_NORMAL_CLIP,
        true,
        false,
        true,
        CONST.LB_SWITCHER_FIXING_NORMAL_CLIP,
        "",
        0.5,
        0.5,
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.init_coverLevel();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 10 && (<Part>this.partAC[5]).label === "수직형 지지") {
      // 개폐기 지지파트
      const supportPart: SwitcherPartIL_Support = new SwitcherPartIL_Support();
      supportPart.setAssociation(design, struct, work, level, this);
      supportPart.setDefaultData(
        CONST.INDEX_IL_SWITCHER_SUPPORT,
        false,
        false,
        false,
        CONST.LB_SWITCHER_SUPPORT,
        "",
        2.0,
        2.0,
      );
      if (this.supportType !== CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE) {
        supportPart._selected = true;
        supportPart._visible = true;
      }
      this.partAC.splice(5, 2, supportPart);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
      // 알고리즘 동작
      supportPart.setDefaultVariable();
      supportPart.algorithmPart();
    }
    if (this.partAC.length === 9 && (<Part>this.partAC[5]).label === "개폐기 지지") {
      // 일반 클립 고정 파트
      const fixingNomalClipPart: SwitcherPartIL_FixingNormalClip = new SwitcherPartIL_FixingNormalClip();
      fixingNomalClipPart.setAssociation(design, struct, work, level, this);
      fixingNomalClipPart.setDefaultData(
        CONST.INDEX_IL_SWITCHER_FIXING_NORMAL_CLIP,
        true,
        false,
        true,
        CONST.LB_SWITCHER_FIXING_NORMAL_CLIP,
        "",
        0.5,
        0.5,
      );
      this.partAC.splice(6, 3, fixingNomalClipPart);
      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
      // 알고리즘 동작
      fixingNomalClipPart.setDefaultVariable();
      fixingNomalClipPart.algorithmPart();
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 상세 피복별 선택 여부
   * @param coverPosition 피복 위치 객체
   */
  public selectedDetail(coverPosition: CoverPositionIL): boolean {
    // 위치별로 개폐가 선택되었다 하더라도, 피복별로 개폐 차수가 0차인 경우 개폐되지 않은 것으로 간주함
    if (this.label === CONST.LB_POSITION_SIDE_COLUMN && coverPosition.switcherOrderSideColumn > 0) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_SIDE_WING && coverPosition.switcherOrderSideWing > 0) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_ROOF && coverPosition.switcherOrderRoof > 0) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_FRONT && coverPosition.switcherOrderFront > 0) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_BACK && coverPosition.switcherOrderBack > 0) {
      return this.selected;
    }
    return false;
  }

  /**
   * 알고리즘: 선택(기둥 위치) <- 선택(커튼 개폐)
   */
  public algorithm_selectedByCurtain(): void {
    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      if (this.design.preference.curtainColumnReplaceFlag === true) {
        this.selected = !this.struct.curSwitchWorkIL.level1.columnPosition.selected;
      }
    }
  }

  /**
   * 알고리즘: 선택(방풍벽 위치) <- 선택(방풍벽)
   */
  public algorithm_seletedByWing(): void {
    if (this.label === CONST.LB_POSITION_SIDE_WING) {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        this.selected = (<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition.selected;
      } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
        this.selected = (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition.selected;
      }
    }
  }

  /**
   * 알고리즘: 선택(지붕 위치) <- 선택(천창 골조)
   */
  public algorithm_selectedBySkylight(): void {
    if (this.level.index === 0 && this.label === CONST.LB_POSITION_ROOF) {
      this.selected = !this.struct.skyFrameWorkIL.level1.skylightPosition.selected;
    }
  }

  /**
   * 알고리즘: 선택(앞뒷면 위치), 가용성(앞뒷면 위치) <- 선택(마구리 골조)
   */
  public algorithm_selectedAndEnabledByEndpiece(): void {
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true) {
        this.enabled = true;
      } else {
        this.enabled = false;
        this.selected = false;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true) {
        this.enabled = true;
      } else {
        this.enabled = false;
        this.selected = false;
      }
    }
  }

  /**
   * 알고리즘: 선택 <- 선택(1중)
   */
  public algorithm_selectedByLevel1(): void {
    // 2중이상에서 완전개폐 등으로 인해 중수간 개폐 선택을 자동으로 지정하지 않음
    // if(level.index == 0)
    // {
    // 	for(let l: number=1; l<CONST.NUM_INTERLOCK_LEVEL_MAX; l++)
    // 	{
    // 		SwitcherPosition(SwitcherLevel(work.levelAC[l]).positionAC.getItemAt(index)).selected = selected;
    // 	}
    // }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_SWITCHER_TYPE_MANUAL) {
      if (this.switchOrderMax === 1) {
        this.manual1Part.selected = true;
        this.manual1Part.visible = true;
        this.manual2Part.selected = false;
        this.manual2Part.visible = false;
      } else if (this.switchOrderMax === 2) {
        this.manual1Part.selected = true;
        this.manual1Part.visible = true;
        this.manual2Part.selected = true;
        this.manual2Part.visible = true;
      }
      this.power1Part.selected = false;
      this.power1Part.visible = false;
      this.power2Part.selected = false;
      this.power2Part.visible = false;
      this.axisPart.selected = true;
      this.axisPart.visible = true;
    } else if (this.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP || this.type === CONST.LB_SWITCHER_TYPE_POWER_TOW) {
      if (this.switchOrderMax === 1) {
        this.power1Part.selected = true;
        this.power1Part.visible = true;
        this.power2Part.selected = false;
        this.power2Part.visible = false;
      } else if (this.switchOrderMax === 2) {
        this.power1Part.selected = true;
        this.power1Part.visible = true;
        this.power2Part.selected = true;
        this.power2Part.visible = true;
      }
      this.manual1Part.selected = false;
      this.manual1Part.visible = false;
      this.manual2Part.selected = false;
      this.manual2Part.visible = false;
      this.axisPart.selected = true;
      this.axisPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 지지 형태
   */
  public algorithm_partActivationBySupportType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL) {
      this.supportPart.visible = true;
      this.supportPart.selected = true;
    } else if (this.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL) {
      this.supportPart.visible = true;
      this.supportPart.selected = true;
    } else {
      this.supportPart.visible = false;
      this.supportPart.selected = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////
    this.fixingNormalClipPart.selected = true;
    this.fixingNormalClipPart.visible = true;
  }

  /**
   * 알고리즘: 지지 형태 <- 형태
   */
  public algorithm_supportTypeByType(): void {
    if (this.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
      if (this.label === CONST.LB_POSITION_SIDE_COLUMN || this.label === CONST.LB_POSITION_SIDE_WING) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL;
      } else if (this.label === CONST.LB_POSITION_ROOF) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL;
      } else if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
      }
    } else if (this.type === CONST.LB_SWITCHER_TYPE_MANUAL) {
      if (this.label === CONST.LB_POSITION_SIDE_COLUMN || this.label === CONST.LB_POSITION_SIDE_WING) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
      } else if (this.label === CONST.LB_POSITION_ROOF) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL;
      } else if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
      }
    } else if (this.type === CONST.LB_SWITCHER_TYPE_POWER_TOW) {
      this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE;
    }
  }

  /**
   * 알고리즘: 지지 형태(2차) <- 개폐 차수
   */
  public algorithm_support2TypeBySwitchOrder(): void {
    if (this.switchOrderMax === CONST.VL_SWITCHER_ORDER_2) {
      this.support2Type = this.supportType;
    }
  }

  /**
   * 알고리즘: 피복 고정 형태(2차) <- 개폐 차수
   */
  public algorithm_fixing2TypeBySwitchOrder(): void {
    if (this.switchOrderMax === CONST.VL_SWITCHER_ORDER_2) {
      this.fixing2Type = this.fixingType;
    }
  }

  /**
   * 알고리즘: 형태(다른 위치) <- 형태(기둥 위치, 방풍벽 위치)
   */
  public algorithm_typeByPosition(): void {
    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      (<SwitcherLevelIL>this.level).wingPosition.type = this.type;
      (<SwitcherLevelIL>this.level).roofPosition.type = this.type;
      (<SwitcherLevelIL>this.level).frontPosition.type = this.type;
      (<SwitcherLevelIL>this.level).backPosition.type = this.type;
    } else if (this.level.index >= 1 && this.label === CONST.LB_POSITION_SIDE_WING) {
      (<SwitcherLevelIL>this.level).columnPosition.type = this.type;
      (<SwitcherLevelIL>this.level).roofPosition.type = this.type;
      (<SwitcherLevelIL>this.level).frontPosition.type = this.type;
      (<SwitcherLevelIL>this.level).backPosition.type = this.type;
    }
  }

  /**
   * 알고리즘: 형태(2중이상) <- 형태(1중/기둥 위치)
   */
  public algorithm_typeByLevel1(): void {
    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      for (let i: number = 1; i < this.struct.switcherWorkIL.levelAC.length; i++) {
        (<SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[i]).wingPosition.type = this.type;
      }
    }
  }

  /**
   * 알고리즘: 개폐 방향(기둥 위치, 방풍벽 위치) <- 개폐 방향(기둥 위치, 방풍벽 위치) <br/>
   * 기둥(측면)과 방풍벽(측면)의 선택과 개폐 방향을 동일하게 유지
   */
  public algorithm_switchWayColumnAndWing(): void {
    if (this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      if (this.switchWay !== (<SwitcherLevelIL>this.level).wingPosition.switchWay) {
        (<SwitcherLevelIL>this.level).wingPosition.switchWay = this.switchWay;
      }
    } else if (this.label === CONST.LB_POSITION_SIDE_WING) {
      if (this.switchWay !== (<SwitcherLevelIL>this.level).columnPosition.switchWay) {
        (<SwitcherLevelIL>this.level).columnPosition.switchWay = this.switchWay;
      }
    }
  }

  /**
   * 알고리즘: 개폐 방향(2중이상/같은 위치) <- 개폐 방향(1중/같은 위치)
   */
  public algorithm_switchWayByLevel1(): void {
    if (this.level.index === 0) {
      for (let i: number = 1; i < this.struct.switcherWorkIL.levelAC.length; i++) {
        (<SwitcherPositionIL>this.struct.switcherWorkIL.levelAC[i].positionAC[this.index]).switchWay = this.switchWay;
      }
    }
  }

  /**
   * 알고리즘: 개폐 높이(2중이상/같은 위치) <- 개폐 높이(1중/같은 위치)
   */
  public algorithm_switchHeightByLevel1(): void {
    if (this.level.index === 0) {
      for (let i: number = 1; i < this.struct.switcherWorkIL.levelAC.length; i++) {
        (<SwitcherPositionIL>this.work.levelAC[i].positionAC[this.index]).switchHeight = this.switchHeight;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 초기화: 기초 피복 중수
   */
  protected init_coverLevel(): void {
    this.coverLevel = <CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index];
  }
}
