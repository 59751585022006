import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { PartitionPart_FixingLaggingPad } from "vhows-design/src/object/design/other/partition/PartitionPart_FixingLaggingPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-04
 */
@jsonObject
export class PartitionSample_LaggingPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingLaggingPadPart: PartitionPart_FixingLaggingPad = <PartitionPart_FixingLaggingPad>this.part;
    fixingLaggingPadPart.algorithmSpec_laggingPad_laggingPad();
    fixingLaggingPadPart.algorithmSpec_laggingPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 광폭 패드 샘플(피복 고정)
   */
  // @override
  public algorithmSpec(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.specs = this.struct.fixingWork.level1.frontPosition.laggingPadPart.laggingPadSample.specs;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
