import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { BuildingWork } from "vhows-design/src/object/design/building/BuildingWork";
import { MaterialWork } from "vhows-design/src/object/design/other/material/MaterialWork";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-05-10
 */
@jsonObject
export class StructOT extends Struct {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 기타 공사
    this.materialWork = new MaterialWork();
    this.buildingWork = new BuildingWork();

    // 최종 workAC
    this.workAC = [this.materialWork, this.buildingWork];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.materialWork = <MaterialWork>this.workAC[CONST.INDEX_OT_OTHER_MATERIAL_WORK];
    this.buildingWork = <BuildingWork>this.workAC[CONST.INDEX_OT_BUILDING_WORK];

    super.setReferenceVariable();
  }

  // @override
  public setDefaultData(): void {
    super.setDefaultData();

    // 기타 공사
    this.materialWork.setDefaultData(
      CONST.INDEX_OT_OTHER_MATERIAL_WORK,
      CONST.ORDER_OTHER_MATERIAL_WORK,
      true,
      false,
      true,
      CONST.LB_OTHER_MATERIAL_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    this.buildingWork.setDefaultData(
      CONST.INDEX_OT_BUILDING_WORK,
      CONST.ORDER_BUILDING_WORK,
      false,
      false,
      true,
      CONST.LB_BUILDING_WORK,
      CONST.LB_BUILDING_CONSTRUCTION,
    );
  }

  // @override
  public restoreLatestObject(design: Design): void {
    if (this.workAC.length === 1) {
      // 시공 작업
      const buildingWork: BuildingWork = new BuildingWork();
      buildingWork.setAssociation(design, this);
      buildingWork.setDefaultData(
        CONST.INDEX_OT_BUILDING_WORK,
        CONST.ORDER_BUILDING_WORK,
        false,
        false,
        true,
        CONST.LB_BUILDING_WORK,
        CONST.LB_BUILDING_CONSTRUCTION,
      );
      buildingWork.selectedEstimate = !design.isSectionEstimate;
      this.workAC.push(buildingWork);
    }

    super.restoreLatestObject(design);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
