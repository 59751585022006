import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPadVinyl } from "vhows-design/src/object/design/item/list/ItemPadVinyl";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { PartitionPosition } from "vhows-design/src/object/design/other/partition/PartitionPosition";
import { PartitionSample_NormalPad } from "vhows-design/src/object/design/other/partition/PartitionSample_NormalPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-04
 */
@jsonObject({
  knownTypes: [PartitionSample_NormalPad],
})
export class PartitionPart_FixingNormalPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalPadSample: PartitionSample_NormalPad; // 일반 패드 샘플

  // 아이템
  public normalPad_normalPad: ItemPad;
  public normalPad_normalPadSpring: ItemPadSpring;
  public normalPad_padConnectionPin: ItemPadConnector;
  public normalPad_padVinyl: ItemPadVinyl;
  public normalPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalPadSample = new PartitionSample_NormalPad();

    this.sampleAC = [this.normalPadSample];

    // 아이템
    this.normalPad_normalPad = new ItemPad();
    this.normalPad_normalPadSpring = new ItemPadSpring();
    this.normalPad_padConnectionPin = new ItemPadConnector();
    this.normalPad_padVinyl = new ItemPadVinyl();
    this.normalPad_screw = new ItemScrew();

    this.itemAC = [
      this.normalPad_normalPad,
      this.normalPad_normalPadSpring,
      this.normalPad_padConnectionPin,
      this.normalPad_padVinyl,
      this.normalPad_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalPadSample = <PartitionSample_NormalPad>this.sampleAC[0];

    // 아이템
    this.normalPad_normalPad = <ItemPad>this.itemAC[0];
    this.normalPad_normalPadSpring = <ItemPadSpring>this.itemAC[1];
    this.normalPad_padConnectionPin = <ItemPadConnector>this.itemAC[2];
    this.normalPad_padVinyl = <ItemPadVinyl>this.itemAC[3];
    this.normalPad_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.normalPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드",
        "칼라 철, 0.6T, 6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalPad_normalPad.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드",
        "칼라 철, 0.6T, 6m",
      );
      this.normalPad_normalPadSpring.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "일반 패드",
        "일반, 2m",
      );
      this.normalPad_padConnectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "일반 패드",
        "칼라 철",
      );
      this.normalPad_padVinyl.setDefaultData(
        3,
        CONST.ITEM_ID_PAD_VINYL,
        CONST.ITEM_NAME_PAD_VINYL,
        "일반 패드",
        "일반, 200m",
      );
      this.normalPad_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "일반 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_normalPad_normalPadSpring();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const partitionPosition: PartitionPosition = <PartitionPosition>this.position;
    // 외부
    let coverLevel: CoverLevel;
    let trunkPosition: TrunkPosition;

    /// //////// 선택, 가시성 ///////////

    this.normalPad_normalPad.selected = true;
    this.normalPad_normalPad.visible = true;
    this.normalPad_normalPadSpring.selected = true;
    this.normalPad_normalPadSpring.visible = true;
    this.normalPad_padConnectionPin.setSelectedByPad(true, this.normalPad_normalPad.specMaterial);
    this.normalPad_padConnectionPin.visible = true;
    this.normalPad_padVinyl.selected = false;
    this.normalPad_padVinyl.visible = true;
    this.normalPad_screw.selected = true;
    this.normalPad_screw.visible = true;

    /// //////// 수치 ///////////

    // 중방방향 줄수
    let lineNumberMiddle: number = 3;
    if (this.basicLevel.heightShoulder >= 2.5) {
      lineNumberMiddle = 4;
    }

    /// //////// 수량 ///////////

    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      coverLevel = <CoverLevel>this.struct.coverWork.levelAC[this.level.index];
      trunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;

      this.normalPad_normalPad.designQuantity =
        ((trunkPosition.rafterPart.rafterTotalLength + this.basicLevel.width * lineNumberMiddle + 2.0 * 2) /
          this.normalPad_normalPad.specLength) *
        partitionPosition.partitionQuantity;

      let normalPad_normalPadSpring_designQuantity: number =
        this.normalPad_normalPad.getSelectedQuantity() *
        (this.normalPad_normalPad.specLength / this.normalPad_normalPadSpring.specLength);
      normalPad_normalPadSpring_designQuantity *= this.design.preference.getPadSpringMultFactor(
        coverLevel.partitionCoverLayer,
      );
      this.normalPad_normalPadSpring.designQuantity =
        normalPad_normalPadSpring_designQuantity * CONST.NUM_EXTRA_RATE_PAD_SPRING;

      this.normalPad_padConnectionPin.designQuantity = this.normalPad_normalPad.getSelectedQuantity();

      this.normalPad_padVinyl.designQuantity =
        (this.normalPad_normalPad.getSelectedQuantity() * this.normalPad_normalPad.specLength) /
        this.normalPad_padVinyl.specLength;

      this.normalPad_screw.designQuantity =
        ((this.normalPad_normalPad.getSelectedQuantity() * 6 +
          this.normalPad_padConnectionPin.getSelectedQuantity() * 2) /
          this.normalPad_screw.specUnitQuantity) *
        CONST.NUM_EXTRA_RATE_SCREW;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 패드] 일반 패드 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPad_normalPad(): void {
    this.normalPad_normalPad.specs = this.normalPadSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 패드] 일반 패드 스프링 <- 일반 패드 스프링 샘플(피복 고정/앞면)
   */
  public algorithmSpec_normalPad_normalPadSpring(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.normalPad_normalPadSpring.specs =
        this.struct.fixingWork.level1.frontPosition.normalPadPart.normalPadSpringSample.specs;
    }
  }

  /**
   * 규격 알고리즘: [일반 패드] 패드 연결핀 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPad_padConnectionPin(): void {
    this.normalPad_padConnectionPin.specMaterial = this.normalPadSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [일반 패드] 패드 비닐 <- 공통 샘플
   */
  public algorithmSpec_normalPad_padVinyl(): void {}

  /**
   * 규격 알고리즘: [일반 패드] 나사 <- 공통 샘플
   */
  public algorithmSpec_normalPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
