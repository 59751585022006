import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemManualSwitcher } from "vhows-design/src/object/design/item/list/ItemManualSwitcher";
import { ItemSwitcherOther } from "vhows-design/src/object/design/item/list/ItemSwitcherOther";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { SwitcherSampleIL_ManualSwitcher } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_ManualSwitcher";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject({
  knownTypes: [SwitcherSampleIL_ManualSwitcher],
})
export class SwitcherPartIL_Manual extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public manualSwitcherSample: SwitcherSampleIL_ManualSwitcher; // 수동 개폐기 샘플

  // 아이템
  public manual_manualSwitcher: ItemManualSwitcher;
  public manual_switcherProtectionRubber: ItemSwitcherOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.manualSwitcherSample = new SwitcherSampleIL_ManualSwitcher();

    this.sampleAC = [this.manualSwitcherSample];

    // 아이템
    this.manual_manualSwitcher = new ItemManualSwitcher();
    this.manual_switcherProtectionRubber = new ItemSwitcherOther();

    this.itemAC = [this.manual_manualSwitcher, this.manual_switcherProtectionRubber];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.manualSwitcherSample = <SwitcherSampleIL_ManualSwitcher>this.sampleAC[0];

    // 아이템
    this.manual_manualSwitcher = <ItemManualSwitcher>this.itemAC[0];
    this.manual_switcherProtectionRubber = <ItemSwitcherOther>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      if (
        this.position.label === CONST.LB_POSITION_SIDE_COLUMN ||
        this.position.label === CONST.LB_POSITION_SIDE_WING
      ) {
        this.manualSwitcherSample.setDefaultData(
          0,
          CONST.ITEM_ID_MANUAL_SWITCHER,
          CONST.ITEM_NAME_MANUAL_SWITCHER,
          "수동 개폐기",
          "측면, 비닐용",
          "황놀부, JHV-A, -",
        );
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.manualSwitcherSample.setDefaultData(
          0,
          CONST.ITEM_ID_MANUAL_SWITCHER,
          CONST.ITEM_NAME_MANUAL_SWITCHER,
          "수동 개폐기",
          "체인, 비닐용",
          "황놀부, JHV-B, 5m",
        );
      } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
        this.manualSwitcherSample.setDefaultData(
          0,
          CONST.ITEM_ID_MANUAL_SWITCHER,
          CONST.ITEM_NAME_MANUAL_SWITCHER,
          "수동 개폐기",
          "측면, 비닐용",
          "황놀부, JHV-A, -",
        );
      }
    }

    // 아이템
    if (this.level.index >= 0) {
      if (
        this.position.label === CONST.LB_POSITION_SIDE_COLUMN ||
        this.position.label === CONST.LB_POSITION_SIDE_WING
      ) {
        this.manual_manualSwitcher.setDefaultData(
          0,
          CONST.ITEM_ID_MANUAL_SWITCHER,
          CONST.ITEM_NAME_MANUAL_SWITCHER,
          "수동 개폐기",
          "측면, 비닐용",
          "황놀부, JHV-A, -",
        );
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.manual_manualSwitcher.setDefaultData(
          0,
          CONST.ITEM_ID_MANUAL_SWITCHER,
          CONST.ITEM_NAME_MANUAL_SWITCHER,
          "수동 개폐기",
          "체인, 비닐용",
          "황놀부, JHV-B, 5m",
        );
      } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
        this.manual_manualSwitcher.setDefaultData(
          0,
          CONST.ITEM_ID_MANUAL_SWITCHER,
          CONST.ITEM_NAME_MANUAL_SWITCHER,
          "수동 개폐기",
          "측면, 비닐용",
          "황놀부, JHV-A, -",
        );
      }
      this.manual_switcherProtectionRubber.setDefaultData(
        1,
        CONST.ITEM_ID_SWITCHER_PROTECTION_RUBBER,
        CONST.ITEM_NAME_SWITCHER_PROTECTION_RUBBER,
        "수동 개폐기",
        "일반",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;

    /// //////// 선택, 가시성 ///////////

    this.manual_manualSwitcher.selected = true;
    this.manual_manualSwitcher.visible = true;
    this.manual_switcherProtectionRubber.selected = this.design.preference.switcherProtectionRubberFlag;
    this.manual_switcherProtectionRubber.visible = true;

    /// //////// 수량 ///////////

    if (
      switcherPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      switcherPosition.label === CONST.LB_POSITION_SIDE_WING
    ) {
      this.manual_manualSwitcher.designQuantity = switcherPosition.switchWayValue;

      if (switcherPosition.switchStep === CONST.VL_SWITCHER_ORDER_2) {
        this.manual_manualSwitcher.designQuantity *= switcherPosition.switchStep;

        this.manual_switcherProtectionRubber.designQuantity =
          this.manual_manualSwitcher.getSelectedQuantity() * switcherPosition.switchStep;
      }
    } else if (switcherPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          bothEndQuantity = 2;
          centralQuantity = switcherPosition.switchWayValue * (this.basic.buildingNumber - 2);
          this.manual_manualSwitcher.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          this.manual_manualSwitcher.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
        }
      } else {
        this.manual_manualSwitcher.designQuantity = switcherPosition.switchWayValue;
      }
    } else if (
      switcherPosition.label === CONST.LB_POSITION_FRONT ||
      switcherPosition.label === CONST.LB_POSITION_BACK
    ) {
      this.manual_manualSwitcher.designQuantity = 1;
    }

    this.manual_switcherProtectionRubber.designQuantity = this.manual_manualSwitcher.getSelectedQuantity();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [수동 개폐기] 수동 개폐기 <- 수동 개폐기 샘플
   */
  public algorithmSpec_manual_manualSwitcher(): void {
    this.manual_manualSwitcher.specs = this.manualSwitcherSample.specs;
    this.manual_manualSwitcher.brands = this.manualSwitcherSample.brands;
  }

  /**
   * 규격 알고리즘: [수동 개폐기] 개폐기 보호고무 <- 없음
   */
  public algorithmSpec_manual_switcherProtectionRubber(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
