import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPlasticGutter } from "vhows-design/src/object/design/item/list/ItemPlasticGutter";
import { ItemUbar } from "vhows-design/src/object/design/item/list/ItemUbar";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { GutterSample_PlasticGutter } from "vhows-design/src/object/design/frame/gutter/GutterSample_PlasticGutter";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-28
 */
@jsonObject({
  knownTypes: [GutterSample_PlasticGutter],
})
export class GutterPart_Plastic extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public plasticGutterSample: GutterSample_PlasticGutter; // 플라스틱 물받이 샘플

  // 아이템
  protected plastic_plasticGutter: ItemPlasticGutter; // 플라스틱 물받이 동적 생성
  public plastic_ubar: ItemUbar;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 길이 AC */
  public specLengthAC: number[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.plasticGutterSample = new GutterSample_PlasticGutter();

    this.sampleAC = [this.plasticGutterSample];

    // 아이템
    // 플라스틱 물받이 동적 생성
    this.plastic_ubar = new ItemUbar();

    this.itemAC = [this.plastic_ubar];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.plasticGutterSample = <GutterSample_PlasticGutter>this.sampleAC[0];

    // 아이템
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemUbar) {
        this.plastic_ubar = <ItemUbar>item;
      }
    }

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index === 0) {
      this.plasticGutterSample.setDefaultData(
        0,
        CONST.ITEM_ID_PLASTIC_GUTTER,
        CONST.ITEM_NAME_PLASTIC_GUTTER,
        "플라스틱 물받이",
        "일반, 1.5T, 60cm, 49m, 타공 없음",
      );
    } else {
      this.plasticGutterSample.setDefaultData(
        0,
        CONST.ITEM_ID_PLASTIC_GUTTER,
        CONST.ITEM_NAME_PLASTIC_GUTTER,
        "플라스틱 물받이",
        "일반, 1.5T, 40cm, 48m, 타공 없음",
      );
    }

    // 아이템
    // 플라스틱 물받이 동적 생성
    if (this.level.index === 0) {
      this.plastic_ubar.setDefaultData(
        1,
        CONST.ITEM_ID_UBAR,
        CONST.ITEM_NAME_UBAR,
        "플라스틱 물받이",
        "원형, 48mm, 1.1m",
      );
    } else {
      this.plastic_ubar.setDefaultData(
        1,
        CONST.ITEM_ID_UBAR,
        CONST.ITEM_NAME_UBAR,
        "플라스틱 물받이",
        "원형, 48mm, 1.1m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 파트 알고리즘 <- 서까래 간격(기초 골조)
   */
  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;

    /// //////// 생성, 선택, 가시성, 수량 ///////////

    if (this.basic.isVariableLength === true) {
      this.specLengthAC = new Array();
      let basicBuilding: BasicBuilding;
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.specLengthAC.push(basicBuilding.length);
      }
    }

    // 플라스틱 물받이
    // 2동이상 && 가변길이인 경우
    if (this.basic.buildingNumber >= 2 && this.basic.isVariableLength === true) {
      // 동수만큼 품목 생성
      let length: number = this.basic.buildingNumber;
      if (this.level.index === 0) {
        length -= 1;
      }
      for (let i: number = 0; i < length; i++) {
        this.plastic_plasticGutter = ItemPlasticGutter.getNewDesignItemBySample(this, this.plasticGutterSample);
        this.plastic_plasticGutter.purpose = `${i + 1}번동 ${this.plastic_plasticGutter.purpose}`;
        if (this.level.index === 0) {
          // 1중일 경우 물받이가 두동사이에 위치해서 두동중 길이가 짧은쪽 동의 길이로 세팅
          let specLength: number = 0;
          const specLength1: number = this.specLengthAC[i];
          const specLength2: number = this.specLengthAC[i + 1];
          if (specLength1 < specLength2) {
            specLength = specLength1;
          } else {
            specLength = specLength2;
          }

          this.plastic_plasticGutter.specLength = specLength + 1;
          this.plastic_plasticGutter.designQuantity = 1;
        } else {
          // 2중 이상
          this.plastic_plasticGutter.specLength = 1 + this.specLengthAC[i];
          this.plastic_plasticGutter.designQuantity = 2;
        }

        this.itemAC.push(this.plastic_plasticGutter);
      }
    } else {
      // 동수만큼 수량 입력
      this.plastic_plasticGutter = ItemPlasticGutter.getNewDesignItemBySample(this, this.plasticGutterSample);
      if (this.level.index === 0) {
        this.plastic_plasticGutter.specLength = this.basicLevel.length + 1;
        this.plastic_plasticGutter.designQuantity = this.basic.buildingNumber - 1;
      } else {
        this.plastic_plasticGutter.specLength = this.basicLevel.length + 1;
        this.plastic_plasticGutter.designQuantity = 2 * this.basic.buildingNumber;
      }

      this.itemAC.push(this.plastic_plasticGutter);
    }

    // 유바
    if (this.basic.levelNumber >= 2 && this.level.index === this.basic.levelNumber - 1) {
      // 유바의 경우, 2중이상에서만 설치되며 마지막 중수에만 설치됨
      this.plastic_ubar.selected = true;
      this.plastic_ubar.visible = true;
    } else {
      this.plastic_ubar.selected = false;
      this.plastic_ubar.visible = false;
    }
    this.plastic_ubar.designQuantity =
      Math.ceil(trunkPosition.rafterPart.rafterInterval / (this.plastic_ubar.specLength - 0.05)) *
      Math.ceil(this.basicLevel.length / trunkPosition.rafterPart.rafterInterval) *
      (this.basic.buildingNumber - 1) *
      CONST.NUM_EXTRA_RATE_UBAR;

    this.itemAC.push(this.plastic_ubar);

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [플라스틱 물받이] 플라스틱 물받이 <- 플라스틱 물받이 샘플
   */
  public algorithmSpec_plastic_plasticGutter(): void {
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemPlasticGutter && item.productId === CONST.ITEM_ID_PLASTIC_GUTTER) {
        (<ItemPlasticGutter>item).specsList = this.plasticGutterSample.specsList;
        (<ItemPlasticGutter>item).specWidth = this.plasticGutterSample.specWidth;
      }
    }
  }

  /**
   * 규격 알고리즘: [플라스틱 물받이] 유바 <- 서까래 파이프
   */
  public algorithmSpec_plastic_ubar(): void {
    const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
    this.plastic_ubar.specPipeType = trunkPosition.rafterPart.rafterPipeSample.specPipeType;
    this.plastic_ubar.specCrossSize = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    // 하드코딩
    this.plastic_ubar.specLength = 1.1;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
