import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { BandstringWorkIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringWorkIL";
import { CoverWorkIL } from "vhows-design/src/object/design/cover/cover/il/CoverWorkIL";
import { FixingWorkIL } from "vhows-design/src/object/design/cover/fixing/il/FixingWorkIL";
import { CurCoverWorkIL } from "vhows-design/src/object/design/curtain/cover/CurCoverWorkIL";
import { CurSwitchWorkIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchWorkIL";
import { ColumnWorkIL } from "vhows-design/src/object/design/frame/column/ColumnWorkIL";
import { EndpieceWorkIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceWorkIL";
import { FoundationWorkIL } from "vhows-design/src/object/design/foundation/il/FoundationWorkIL";
import { GutterWorkIL } from "vhows-design/src/object/design/frame/gutter/il/GutterWorkIL";
import { RoofWorkIL } from "vhows-design/src/object/design/frame/roof/RoofWorkIL";
import { WingWorkIL } from "vhows-design/src/object/design/frame/wing/WingWorkIL";
import { BuildingWork } from "vhows-design/src/object/design/building/BuildingWork";
import { MaterialWork } from "vhows-design/src/object/design/other/material/MaterialWork";
import { RackWork } from "vhows-design/src/object/design/other/rack/RackWork";
import { RailWork } from "vhows-design/src/object/design/other/rail/RailWork";
import { WindowWorkIL } from "vhows-design/src/object/design/other/window/il/WindowWorkIL";
import { SkyCoverWorkIL } from "vhows-design/src/object/design/skylight/cover/il/SkyCoverWorkIL";
import { SkyFrameWorkIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFrameWorkIL";
import { SkySwitchWorkIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchWorkIL";
import { ControllerWorkIL } from "vhows-design/src/object/design/switches/controller/il/ControllerWorkIL";
import { ScreennetWorkIL } from "vhows-design/src/object/design/cover/screennet/il/ScreennetWorkIL";
import { SkirtWorkIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtWorkIL";
import { SwitcherWorkIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherWorkIL";
import { WindbreakWorkIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakWorkIL";
import { VentExhaustWork } from "vhows-design/src/object/design/vent/exhaust/VentExhaustWork";
import { VentInsideWork } from "vhows-design/src/object/design/vent/inside/VentInsideWork";
import { MainpipeWork } from "vhows-design/src/object/design/watering/mainpipe/MainpipeWork";
import { WateringWork } from "vhows-design/src/object/design/watering/watering/WateringWork";
import { TemHeatingWork } from "vhows-design/src/object/design/temperature/heating/TemHeatingWork";
import { TemCoolingWork } from "vhows-design/src/object/design/temperature/cooling/TemCoolingWork";
import { HydSystemWork } from "vhows-design/src/object/design/hydroponics/system/HydSystemWork";
import { HydCultivationWork } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationWork";
import { EnvironmentWork } from "vhows-design/src/object/design/smartfarm/EnvironmentWork";
import { PartitionWorkIL } from "vhows-design/src/object/design/other/partition/li/PartitionWorkIL";
import { WorkroomWork } from "vhows-design/src/object/design/other/workroom/WorkroomWork";
import { Co2Work } from "vhows-design/src/object/design/other/co2/Co2Work";
import { AutosprayWork } from "vhows-design/src/object/design/other/autospray/AutosprayWork";
import { FacilityWork } from "vhows-design/src/object/design/other/facility/FacilityWork";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { CurCoverSideEndpiecePartIL_Quilting } from "vhows-design/src/object/design/curtain/cover/CurCoverSideEndpiecePartIL_Quilting";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-01-07
 */
@jsonObject
export class StructIL extends Struct {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 기초 공사
    this.foundationWorkIL = new FoundationWorkIL();
    // 골조 공사
    this.columnWorkIL = new ColumnWorkIL();
    this.roofWorkIL = new RoofWorkIL();
    this.wingWorkIL = new WingWorkIL();
    this.endpieceWorkIL = new EndpieceWorkIL();
    this.gutterWorkIL = new GutterWorkIL();
    // 개폐 공사
    this.switcherWorkIL = new SwitcherWorkIL();
    this.controllerWorkIL = new ControllerWorkIL();
    // 피복 공사
    this.coverWorkIL = new CoverWorkIL();
    this.fixingWorkIL = new FixingWorkIL();
    this.skirtWorkIL = new SkirtWorkIL();
    this.windbreakWorkIL = new WindbreakWorkIL();
    this.screennetWorkIL = new ScreennetWorkIL();
    this.bandstringWorkIL = new BandstringWorkIL();
    // 커튼 공사
    this.curCoverWorkIL = new CurCoverWorkIL();
    this.curSwitchWorkIL = new CurSwitchWorkIL();
    // 천창 공사
    this.skyFrameWorkIL = new SkyFrameWorkIL();
    this.skySwitchWorkIL = new SkySwitchWorkIL();
    this.skyCoverWorkIL = new SkyCoverWorkIL();
    // 추가 건축 공사
    this.partitionWorkIL = new PartitionWorkIL();
    this.windowWorkIL = new WindowWorkIL();
    this.workroomWork = new WorkroomWork();
    this.materialWork = new MaterialWork();
    // 환기 공사
    this.ventExhaustWork = new VentExhaustWork();
    this.ventInsideWork = new VentInsideWork();
    // 관수 공사
    this.mainpipeWork = new MainpipeWork();
    this.wateringWork = new WateringWork();
    // 양액 공사
    this.hydSystemWork = new HydSystemWork();
    this.hydCultivationWork = new HydCultivationWork();
    // 냉난방 공사
    this.temHeatingWork = new TemHeatingWork();
    this.temCoolingWork = new TemCoolingWork();
    // 스마트팜 공사
    this.environmentWork = new EnvironmentWork();
    // 추가 기계/설비 공사
    this.rackWork = new RackWork();
    this.railWork = new RailWork();
    this.co2Work = new Co2Work();
    this.autosprayWork = new AutosprayWork();
    this.facilityWork = new FacilityWork();
    // 기타
    this.buildingWork = new BuildingWork();

    // 최종 workAC
    this.workAC = [
      this.foundationWorkIL,
      this.columnWorkIL,
      this.roofWorkIL,
      this.wingWorkIL,
      this.endpieceWorkIL,
      this.gutterWorkIL,
      this.switcherWorkIL,
      this.controllerWorkIL,
      this.coverWorkIL,
      this.fixingWorkIL,
      this.skirtWorkIL,
      this.windbreakWorkIL,
      this.screennetWorkIL,
      this.bandstringWorkIL,
      this.curCoverWorkIL,
      this.curSwitchWorkIL,
      this.skyFrameWorkIL,
      this.skySwitchWorkIL,
      this.skyCoverWorkIL,
      this.partitionWorkIL,
      this.windowWorkIL,
      this.workroomWork,
      this.materialWork,
      this.ventExhaustWork,
      this.ventInsideWork,
      this.mainpipeWork,
      this.wateringWork,
      this.hydSystemWork,
      this.hydCultivationWork,
      this.temHeatingWork,
      this.temCoolingWork,
      this.environmentWork,
      this.rackWork,
      this.railWork,
      this.co2Work,
      this.autosprayWork,
      this.facilityWork,
      this.buildingWork,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.foundationWorkIL = <FoundationWorkIL>this.workAC[CONST.INDEX_IL_FOUNDATION_WORK];
    this.columnWorkIL = <ColumnWorkIL>this.workAC[CONST.INDEX_IL_COLUMN_WORK];
    this.roofWorkIL = <RoofWorkIL>this.workAC[CONST.INDEX_IL_ROOF_WORK];
    this.wingWorkIL = <WingWorkIL>this.workAC[CONST.INDEX_IL_WING_WORK];
    this.endpieceWorkIL = <EndpieceWorkIL>this.workAC[CONST.INDEX_IL_ENDPIECE_WORK];
    this.gutterWorkIL = <GutterWorkIL>this.workAC[CONST.INDEX_IL_GUTTER_WORK];
    this.switcherWorkIL = <SwitcherWorkIL>this.workAC[CONST.INDEX_IL_SWITCHER_WORK];
    this.controllerWorkIL = <ControllerWorkIL>this.workAC[CONST.INDEX_IL_CONTROLLER_WORK];
    this.coverWorkIL = <CoverWorkIL>this.workAC[CONST.INDEX_IL_COVER_WORK];
    this.fixingWorkIL = <FixingWorkIL>this.workAC[CONST.INDEX_IL_FIXING_WORK];
    this.skirtWorkIL = <SkirtWorkIL>this.workAC[CONST.INDEX_IL_SKIRT_WORK];
    this.windbreakWorkIL = <WindbreakWorkIL>this.workAC[CONST.INDEX_IL_WINDBREAK_WORK];
    this.screennetWorkIL = <ScreennetWorkIL>this.workAC[CONST.INDEX_IL_SCREENNET_WORK];
    this.bandstringWorkIL = <BandstringWorkIL>this.workAC[CONST.INDEX_IL_BANDSTRING_WORK];
    this.curCoverWorkIL = <CurCoverWorkIL>this.workAC[CONST.INDEX_IL_CURTAIN_COVER_WORK];
    this.curSwitchWorkIL = <CurSwitchWorkIL>this.workAC[CONST.INDEX_IL_CURTAIN_SWITCH_WORK];
    this.skyFrameWorkIL = <SkyFrameWorkIL>this.workAC[CONST.INDEX_IL_SKYLIGHT_FRAME_WORK];
    this.skySwitchWorkIL = <SkySwitchWorkIL>this.workAC[CONST.INDEX_IL_SKYLIGHT_SWITCH_WORK];
    this.skyCoverWorkIL = <SkyCoverWorkIL>this.workAC[CONST.INDEX_IL_SKYLIGHT_COVER_WORK];
    this.partitionWorkIL = <PartitionWorkIL>this.workAC[CONST.INDEX_IL_PARTITION_WORK];
    this.windowWorkIL = <WindowWorkIL>this.workAC[CONST.INDEX_IL_WINDOW_WORK];
    this.workroomWork = <WorkroomWork>this.workAC[CONST.INDEX_IL_WORKROOM_WORK];
    this.materialWork = <MaterialWork>this.workAC[CONST.INDEX_IL_OTHER_MATERIAL_WORK];
    this.ventExhaustWork = <VentExhaustWork>this.workAC[CONST.INDEX_IL_VENT_EXHAUST_WORK];
    this.ventInsideWork = <VentInsideWork>this.workAC[CONST.INDEX_IL_VENT_INSIDE_WORK];
    this.mainpipeWork = <MainpipeWork>this.workAC[CONST.INDEX_IL_WATERING_MAINPIPE_WORK];
    this.wateringWork = <WateringWork>this.workAC[CONST.INDEX_IL_WATERING_WATERING_WORK];
    this.hydSystemWork = <HydSystemWork>this.workAC[CONST.INDEX_IL_HYD_SYSTEM_WORK];
    this.hydCultivationWork = <HydCultivationWork>this.workAC[CONST.INDEX_IL_HYD_CULTIVATION_WORK];
    this.temHeatingWork = <TemHeatingWork>this.workAC[CONST.INDEX_IL_TEM_HEATING_WORK];
    this.temCoolingWork = <TemCoolingWork>this.workAC[CONST.INDEX_IL_TEM_COOLING_WORK];
    this.environmentWork = <EnvironmentWork>this.workAC[CONST.INDEX_IL_ENVIRONMENT_WORK];
    this.rackWork = <RackWork>this.workAC[CONST.INDEX_IL_RACK_WORK];
    this.railWork = <RailWork>this.workAC[CONST.INDEX_IL_RAIL_WORK];
    this.co2Work = <Co2Work>this.workAC[CONST.INDEX_IL_CO2_WORK];
    this.autosprayWork = <AutosprayWork>this.workAC[CONST.INDEX_IL_AUTOSPRAY_WORK];
    this.facilityWork = <FacilityWork>this.workAC[CONST.INDEX_IL_OTHER_FACILITIES_WORK];
    this.buildingWork = <BuildingWork>this.workAC[CONST.INDEX_IL_BUILDING_WORK];

    super.setReferenceVariable();
  }

  // @override
  public setDefaultData(): void {
    super.setDefaultData();

    // 기초 공사
    this.foundationWorkIL.setDefaultData(
      CONST.INDEX_IL_FOUNDATION_WORK,
      CONST.ORDER_FOUNDATION_WORK,
      true,
      true,
      true,
      CONST.LB_FOUNDATION_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    // 골조 공사
    this.columnWorkIL.setDefaultData(
      CONST.INDEX_IL_COLUMN_WORK,
      CONST.ORDER_COLUMN_WORK,
      true,
      false,
      true,
      CONST.LB_COLUMN_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    this.roofWorkIL.setDefaultData(
      CONST.INDEX_IL_ROOF_WORK,
      CONST.ORDER_ROOF_WORK,
      true,
      false,
      true,
      CONST.LB_ROOF_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    this.wingWorkIL.setDefaultData(
      CONST.INDEX_IL_WING_WORK,
      CONST.ORDER_WING_WORK,
      true,
      true,
      true,
      CONST.LB_WING_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    this.endpieceWorkIL.setDefaultData(
      CONST.INDEX_IL_ENDPIECE_WORK,
      CONST.ORDER_ENDPIECE_WORK,
      true,
      true,
      true,
      CONST.LB_ENDPIECE_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    this.gutterWorkIL.setDefaultData(
      CONST.INDEX_IL_GUTTER_WORK,
      CONST.ORDER_GUTTER_WORK,
      true,
      true,
      true,
      CONST.LB_GUTTER_WORK,
      CONST.LB_FRAME_CONSTRUCTION,
    );
    // 개폐 공사
    this.switcherWorkIL.setDefaultData(
      CONST.INDEX_IL_SWITCHER_WORK,
      CONST.ORDER_SWITCHER_WORK,
      true,
      true,
      true,
      CONST.LB_SWITCHER_WORK,
      CONST.LB_SWITCH_CONSTRUCTION,
    );
    this.controllerWorkIL.setDefaultData(
      CONST.INDEX_IL_CONTROLLER_WORK,
      CONST.ORDER_CONTROLLER_WORK,
      true,
      true,
      true,
      CONST.LB_CONTROLLER_WORK,
      CONST.LB_SWITCH_CONSTRUCTION,
    );
    // 피복 공사
    this.coverWorkIL.setDefaultData(
      CONST.INDEX_IL_COVER_WORK,
      CONST.ORDER_COVER_WORK,
      true,
      true,
      true,
      CONST.LB_COVER_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.fixingWorkIL.setDefaultData(
      CONST.INDEX_IL_FIXING_WORK,
      CONST.ORDER_FIXING_WORK,
      true,
      true,
      true,
      CONST.LB_FIXING_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.skirtWorkIL.setDefaultData(
      CONST.INDEX_IL_SKIRT_WORK,
      CONST.ORDER_SKIRT_WORK,
      true,
      true,
      true,
      CONST.LB_SKIRT_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.windbreakWorkIL.setDefaultData(
      CONST.INDEX_IL_WINDBREAK_WORK,
      CONST.ORDER_WINDBREAK_WORK,
      true,
      true,
      true,
      CONST.LB_WINDBREAK_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.screennetWorkIL.setDefaultData(
      CONST.INDEX_IL_SCREENNET_WORK,
      CONST.ORDER_SCREENNET_WORK,
      false,
      false,
      true,
      CONST.LB_SCREENNET_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    this.bandstringWorkIL.setDefaultData(
      CONST.INDEX_IL_BANDSTRING_WORK,
      CONST.ORDER_BANDSTRING_WORK,
      true,
      true,
      true,
      CONST.LB_BANDSTRING_WORK,
      CONST.LB_COVER_CONSTRUCTION,
    );
    // 커튼 공사
    this.curCoverWorkIL.setDefaultData(
      CONST.INDEX_IL_CURTAIN_COVER_WORK,
      CONST.ORDER_CURTAIN_COVER_WORK,
      false,
      false,
      true,
      CONST.LB_CURTAIN_COVER_WORK,
      CONST.LB_CURTAIN_CONSTRUCTION,
    );
    this.curSwitchWorkIL.setDefaultData(
      CONST.INDEX_IL_CURTAIN_SWITCH_WORK,
      CONST.ORDER_CURTAIN_SWITCH_WORK,
      false,
      false,
      true,
      CONST.LB_CURTAIN_SWITCH_WORK,
      CONST.LB_CURTAIN_CONSTRUCTION,
    );
    // 천창 공사
    this.skyFrameWorkIL.setDefaultData(
      CONST.INDEX_IL_SKYLIGHT_FRAME_WORK,
      CONST.ORDER_SKYLIGHT_FRAME_WORK,
      false,
      false,
      true,
      CONST.LB_SKYLIGHT_FRAME_WORK,
      CONST.LB_SKYLIGHT_CONSTRUCTION,
    );
    this.skySwitchWorkIL.setDefaultData(
      CONST.INDEX_IL_SKYLIGHT_SWITCH_WORK,
      CONST.ORDER_SKYLIGHT_SWITCH_WORK,
      false,
      false,
      true,
      CONST.LB_SKYLIGHT_SWITCH_WORK,
      CONST.LB_SKYLIGHT_CONSTRUCTION,
    );
    this.skyCoverWorkIL.setDefaultData(
      CONST.INDEX_IL_SKYLIGHT_COVER_WORK,
      CONST.ORDER_SKYLIGHT_COVER_WORK,
      false,
      false,
      true,
      CONST.LB_SKYLIGHT_COVER_WORK,
      CONST.LB_SKYLIGHT_CONSTRUCTION,
    );
    // 추가 건축 공사
    this.partitionWorkIL.setDefaultData(
      CONST.INDEX_IL_PARTITION_WORK,
      CONST.ORDER_PARTITION_WORK,
      false,
      false,
      true,
      CONST.LB_PARTITION_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    this.windowWorkIL.setDefaultData(
      CONST.INDEX_IL_WINDOW_WORK,
      CONST.ORDER_WINDOW_WORK,
      false,
      false,
      true,
      CONST.LB_WINDOW_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    this.workroomWork.setDefaultData(
      CONST.INDEX_IL_WORKROOM_WORK,
      CONST.ORDER_WORKROMM_WORK,
      false,
      false,
      true,
      CONST.LB_WORKROOM_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    this.materialWork.setDefaultData(
      CONST.INDEX_IL_OTHER_MATERIAL_WORK,
      CONST.ORDER_OTHER_MATERIAL_WORK,
      false,
      false,
      true,
      CONST.LB_OTHER_MATERIAL_WORK,
      CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
    );
    // 환기 공사
    this.ventExhaustWork.setDefaultData(
      CONST.INDEX_IL_VENT_EXHAUST_WORK,
      CONST.ORDER_VENT_EXHAUST_WORK,
      false,
      false,
      true,
      CONST.LB_VENT_EXHAUST_WORK,
      CONST.LB_VENT_CONSTRUCTION,
    );
    this.ventInsideWork.setDefaultData(
      CONST.INDEX_IL_VENT_INSIDE_WORK,
      CONST.ORDER_VENT_INSIDE_WORK,
      false,
      false,
      true,
      CONST.LB_VENT_INSIDE_WORK,
      CONST.LB_VENT_CONSTRUCTION,
    );
    // 관수 공사
    this.mainpipeWork.setDefaultData(
      CONST.INDEX_IL_WATERING_MAINPIPE_WORK,
      CONST.ORDER_WATERING_MAINPIPE_WORK,
      false,
      false,
      true,
      CONST.LB_WATERING_MAINPIPE_WORK,
      CONST.LB_WATERING_CONSTRUCTION,
    );
    this.wateringWork.setDefaultData(
      CONST.INDEX_IL_WATERING_WATERING_WORK,
      CONST.ORDER_WATERING_WATERING_WORK,
      false,
      false,
      true,
      CONST.LB_WATERING_WATERING_WORK,
      CONST.LB_WATERING_CONSTRUCTION,
    );
    // 양액 공사
    this.hydSystemWork.setDefaultData(
      CONST.INDEX_IL_HYD_SYSTEM_WORK,
      CONST.ORDER_HYD_SYSTEM_WORK,
      false,
      false,
      true,
      CONST.LB_HYD_SYSTEM_WORK,
      CONST.LB_HYDROPONICS_CONSTRUCTION,
    );
    this.hydCultivationWork.setDefaultData(
      CONST.INDEX_IL_HYD_CULTIVATION_WORK,
      CONST.ORDER_HYD_CULTIVATION_WORK,
      false,
      false,
      true,
      CONST.LB_HYD_CULTIVATION_WORK,
      CONST.LB_HYDROPONICS_CONSTRUCTION,
    );
    // 냉난방 공사
    this.temHeatingWork.setDefaultData(
      CONST.INDEX_IL_TEM_HEATING_WORK,
      CONST.ORDER_TEM_HEATING_WORK,
      false,
      false,
      true,
      CONST.LB_TEM_HEATING_WORK,
      CONST.LB_TEMPERATURE_CONSTRUCTION,
    );
    this.temCoolingWork.setDefaultData(
      CONST.INDEX_IL_TEM_COOLING_WORK,
      CONST.ORDER_TEM_COOLING_WORK,
      false,
      false,
      true,
      CONST.LB_TEM_COOLING_WORK,
      CONST.LB_TEMPERATURE_CONSTRUCTION,
    );
    // 스마트팜 공사
    this.environmentWork.setDefaultData(
      CONST.INDEX_IL_ENVIRONMENT_WORK,
      CONST.ORDER_ENVIRONMENT_WORK,
      false,
      false,
      true,
      CONST.LB_ENVIRONMENT_WORK,
      CONST.LB_SMARTFARM_CONSTRUCTION,
    );
    // 추가 기계/설비 공사
    this.rackWork.setDefaultData(
      CONST.INDEX_IL_RACK_WORK,
      CONST.ORDER_RACK_WORK,
      false,
      false,
      true,
      CONST.LB_RACK_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    this.railWork.setDefaultData(
      CONST.INDEX_IL_RAIL_WORK,
      CONST.ORDER_RAIL_WORK,
      false,
      false,
      true,
      CONST.LB_RAIL_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    this.co2Work.setDefaultData(
      CONST.INDEX_IL_CO2_WORK,
      CONST.ORDER_CO2_WORK,
      false,
      false,
      true,
      CONST.LB_CO2_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    this.autosprayWork.setDefaultData(
      CONST.INDEX_IL_AUTOSPRAY_WORK,
      CONST.ORDER_AUTOSPRAY_WORK,
      false,
      false,
      true,
      CONST.LB_AUTOSPRAY_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    this.facilityWork.setDefaultData(
      CONST.INDEX_IL_OTHER_FACILITIES_WORK,
      CONST.ORDER_OTHER_FACILITIES_WORK,
      false,
      false,
      true,
      CONST.LB_OTHER_FACILITIES_WORK,
      CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
    );
    // 통합 시공
    this.buildingWork.setDefaultData(
      CONST.INDEX_IL_BUILDING_WORK,
      CONST.ORDER_BUILDING_WORK,
      false,
      false,
      true,
      CONST.LB_BUILDING_WORK,
      CONST.LB_BUILDING_CONSTRUCTION,
    );
  }

  // @override
  public restoreLatestObject(design: Design): void {
    // 작업 순서대로 객체 재구성 - 추가적인 공사만 해당함 (골조/개폐/피복 공사는 해당 없음)
    // workAC에 특정 작업이 빠져있어도 되지만, 최소한 index 순서대로 정렬되어 있어야함
    for (let i: number = 0; i <= CONST.INDEX_IL_LAST_WORK; i++) {
      if (i === CONST.INDEX_IL_CURTAIN_COVER_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_CURTAIN_COVER_WORK)
        ) {
          const curCoverWork: CurCoverWorkIL = new CurCoverWorkIL();
          curCoverWork.setAssociation(design, this);
          curCoverWork.setDefaultData(
            CONST.INDEX_IL_CURTAIN_COVER_WORK,
            CONST.ORDER_CURTAIN_COVER_WORK,
            false,
            false,
            true,
            CONST.LB_CURTAIN_COVER_WORK,
            CONST.LB_CURTAIN_CONSTRUCTION,
          );
          curCoverWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, curCoverWork);
        }
      } else if (i === CONST.INDEX_IL_CURTAIN_SWITCH_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_CURTAIN_SWITCH_WORK)
        ) {
          const curSwitchWork: CurSwitchWorkIL = new CurSwitchWorkIL();
          curSwitchWork.setAssociation(design, this);
          curSwitchWork.setDefaultData(
            CONST.INDEX_IL_CURTAIN_SWITCH_WORK,
            CONST.ORDER_CURTAIN_SWITCH_WORK,
            false,
            false,
            true,
            CONST.LB_CURTAIN_SWITCH_WORK,
            CONST.LB_CURTAIN_CONSTRUCTION,
          );
          curSwitchWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, curSwitchWork);
        }
      } else if (i === CONST.INDEX_IL_SKYLIGHT_FRAME_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_SKYLIGHT_FRAME_WORK)
        ) {
          const skyFrameWork: SkyFrameWorkIL = new SkyFrameWorkIL();
          skyFrameWork.setAssociation(design, this);
          skyFrameWork.setDefaultData(
            CONST.INDEX_IL_SKYLIGHT_FRAME_WORK,
            CONST.ORDER_SKYLIGHT_FRAME_WORK,
            false,
            false,
            true,
            CONST.LB_SKYLIGHT_FRAME_WORK,
            CONST.LB_SKYLIGHT_CONSTRUCTION,
          );
          skyFrameWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, skyFrameWork);
        }
      } else if (i === CONST.INDEX_IL_SKYLIGHT_SWITCH_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_SKYLIGHT_SWITCH_WORK)
        ) {
          const skySwitchWork: SkySwitchWorkIL = new SkySwitchWorkIL();
          skySwitchWork.setAssociation(design, this);
          skySwitchWork.setDefaultData(
            CONST.INDEX_IL_SKYLIGHT_SWITCH_WORK,
            CONST.ORDER_SKYLIGHT_SWITCH_WORK,
            false,
            false,
            true,
            CONST.LB_SKYLIGHT_SWITCH_WORK,
            CONST.LB_SKYLIGHT_CONSTRUCTION,
          );
          skySwitchWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, skySwitchWork);
        }
      } else if (i === CONST.INDEX_IL_SKYLIGHT_COVER_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_SKYLIGHT_COVER_WORK)
        ) {
          const skyCoverWork: SkyCoverWorkIL = new SkyCoverWorkIL();
          skyCoverWork.setAssociation(design, this);
          skyCoverWork.setDefaultData(
            CONST.INDEX_IL_SKYLIGHT_COVER_WORK,
            CONST.ORDER_SKYLIGHT_COVER_WORK,
            false,
            false,
            true,
            CONST.LB_SKYLIGHT_COVER_WORK,
            CONST.LB_SKYLIGHT_CONSTRUCTION,
          );
          skyCoverWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, skyCoverWork);
        }
      } else if (i === CONST.INDEX_IL_PARTITION_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_PARTITION_WORK)) {
          const partitionWork: PartitionWorkIL = new PartitionWorkIL();
          partitionWork.setAssociation(design, this);
          partitionWork.setDefaultData(
            CONST.INDEX_IL_PARTITION_WORK,
            CONST.ORDER_PARTITION_WORK,
            false,
            false,
            true,
            CONST.LB_PARTITION_WORK,
            CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
          );
          partitionWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, partitionWork);
        }
      } else if (i === CONST.INDEX_IL_WINDOW_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_WINDOW_WORK)) {
          const windowWork: WindowWorkIL = new WindowWorkIL();
          windowWork.setAssociation(design, this);
          windowWork.setDefaultData(
            CONST.INDEX_IL_WINDOW_WORK,
            CONST.ORDER_WINDOW_WORK,
            false,
            false,
            true,
            CONST.LB_WINDOW_WORK,
            CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
          );
          windowWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, windowWork);
        }
      } else if (i === CONST.INDEX_IL_WORKROOM_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_WORKROOM_WORK)) {
          const workroomWork: WorkroomWork = new WorkroomWork();
          workroomWork.setAssociation(design, this);
          workroomWork.setDefaultData(
            CONST.INDEX_IL_WORKROOM_WORK,
            CONST.ORDER_WORKROMM_WORK,
            false,
            false,
            true,
            CONST.LB_WORKROOM_WORK,
            CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
          );
          workroomWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, workroomWork);
        }
      } else if (i === CONST.INDEX_IL_OTHER_MATERIAL_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_OTHER_MATERIAL_WORK)
        ) {
          const materialWork: MaterialWork = new MaterialWork();
          materialWork.setAssociation(design, this);
          materialWork.setDefaultData(
            CONST.INDEX_IL_OTHER_MATERIAL_WORK,
            CONST.ORDER_OTHER_MATERIAL_WORK,
            false,
            false,
            true,
            CONST.LB_OTHER_MATERIAL_WORK,
            CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
          );
          materialWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, materialWork);
        }
      } else if (i === CONST.INDEX_IL_VENT_EXHAUST_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_VENT_EXHAUST_WORK)
        ) {
          const ventExhaustWork: VentExhaustWork = new VentExhaustWork();
          ventExhaustWork.setAssociation(design, this);
          ventExhaustWork.setDefaultData(
            CONST.INDEX_IL_VENT_EXHAUST_WORK,
            CONST.ORDER_VENT_EXHAUST_WORK,
            false,
            false,
            true,
            CONST.LB_VENT_EXHAUST_WORK,
            CONST.LB_VENT_CONSTRUCTION,
          );
          ventExhaustWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, ventExhaustWork);
        }
      } else if (i === CONST.INDEX_IL_VENT_INSIDE_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_VENT_INSIDE_WORK)
        ) {
          const ventInsideWork: VentInsideWork = new VentInsideWork();
          ventInsideWork.setAssociation(design, this);
          ventInsideWork.setDefaultData(
            CONST.INDEX_IL_VENT_INSIDE_WORK,
            CONST.ORDER_VENT_INSIDE_WORK,
            false,
            false,
            true,
            CONST.LB_VENT_INSIDE_WORK,
            CONST.LB_VENT_CONSTRUCTION,
          );
          ventInsideWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, ventInsideWork);
        }
      } else if (i === CONST.INDEX_IL_WATERING_MAINPIPE_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_WATERING_MAINPIPE_WORK)
        ) {
          const mainpipeWork: MainpipeWork = new MainpipeWork();
          mainpipeWork.setAssociation(design, this);
          mainpipeWork.setDefaultData(
            CONST.INDEX_IL_WATERING_MAINPIPE_WORK,
            CONST.ORDER_WATERING_MAINPIPE_WORK,
            false,
            false,
            true,
            CONST.LB_WATERING_MAINPIPE_WORK,
            CONST.LB_WATERING_CONSTRUCTION,
          );
          mainpipeWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, mainpipeWork);
        }
      } else if (i === CONST.INDEX_IL_WATERING_WATERING_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_WATERING_WATERING_WORK)
        ) {
          const wateringWork: WateringWork = new WateringWork();
          wateringWork.setAssociation(design, this);
          wateringWork.setDefaultData(
            CONST.INDEX_IL_WATERING_WATERING_WORK,
            CONST.ORDER_WATERING_WATERING_WORK,
            false,
            false,
            true,
            CONST.LB_WATERING_WATERING_WORK,
            CONST.LB_WATERING_CONSTRUCTION,
          );
          wateringWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, wateringWork);
        }
      } else if (i === CONST.INDEX_IL_HYD_SYSTEM_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_HYD_SYSTEM_WORK)) {
          const hydSystemWork: HydSystemWork = new HydSystemWork();
          hydSystemWork.setAssociation(design, this);
          hydSystemWork.setDefaultData(
            CONST.INDEX_IL_HYD_SYSTEM_WORK,
            CONST.ORDER_HYD_SYSTEM_WORK,
            false,
            false,
            true,
            CONST.LB_HYD_SYSTEM_WORK,
            CONST.LB_HYDROPONICS_CONSTRUCTION,
          );
          hydSystemWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, hydSystemWork);
        }
      } else if (i === CONST.INDEX_IL_HYD_CULTIVATION_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_HYD_CULTIVATION_WORK)
        ) {
          const hydCultivationWork: HydCultivationWork = new HydCultivationWork();
          hydCultivationWork.setAssociation(design, this);
          hydCultivationWork.setDefaultData(
            CONST.INDEX_IL_HYD_CULTIVATION_WORK,
            CONST.ORDER_HYD_CULTIVATION_WORK,
            false,
            false,
            true,
            CONST.LB_HYD_CULTIVATION_WORK,
            CONST.LB_HYDROPONICS_CONSTRUCTION,
          );
          hydCultivationWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, hydCultivationWork);
        }
      } else if (i === CONST.INDEX_IL_TEM_HEATING_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_VENT_INSIDE_WORK)
        ) {
          const temHeatingWork: TemHeatingWork = new TemHeatingWork();
          temHeatingWork.setAssociation(design, this);
          temHeatingWork.setDefaultData(
            CONST.INDEX_IL_TEM_HEATING_WORK,
            CONST.ORDER_TEM_HEATING_WORK,
            false,
            false,
            true,
            CONST.LB_TEM_HEATING_WORK,
            CONST.LB_TEMPERATURE_CONSTRUCTION,
          );
          temHeatingWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, temHeatingWork);
        }
      } else if (i === CONST.INDEX_IL_TEM_COOLING_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_TEM_COOLING_WORK)
        ) {
          const temCoolingWork: TemCoolingWork = new TemCoolingWork();
          temCoolingWork.setAssociation(design, this);
          temCoolingWork.setDefaultData(
            CONST.INDEX_IL_TEM_COOLING_WORK,
            CONST.ORDER_TEM_COOLING_WORK,
            false,
            false,
            true,
            CONST.LB_TEM_COOLING_WORK,
            CONST.LB_TEMPERATURE_CONSTRUCTION,
          );
          temCoolingWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, temCoolingWork);
        }
      } else if (i === CONST.INDEX_IL_ENVIRONMENT_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_ENVIRONMENT_WORK)
        ) {
          const environmentWork: EnvironmentWork = new EnvironmentWork();
          environmentWork.setAssociation(design, this);
          environmentWork.setDefaultData(
            CONST.INDEX_IL_ENVIRONMENT_WORK,
            CONST.ORDER_ENVIRONMENT_WORK,
            false,
            false,
            true,
            CONST.LB_ENVIRONMENT_WORK,
            CONST.LB_SMARTFARM_CONSTRUCTION,
          );
          environmentWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, environmentWork);
        }
      } else if (i === CONST.INDEX_IL_RACK_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_RACK_WORK)) {
          const rackWork: RackWork = new RackWork();
          rackWork.setAssociation(design, this);
          rackWork.setDefaultData(
            CONST.INDEX_IL_RACK_WORK,
            CONST.ORDER_RACK_WORK,
            false,
            false,
            true,
            CONST.LB_RACK_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          rackWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, rackWork);
        }
      } else if (i === CONST.INDEX_IL_RAIL_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_RAIL_WORK)) {
          const railWork: RailWork = new RailWork();
          railWork.setAssociation(design, this);
          railWork.setDefaultData(
            CONST.INDEX_IL_RAIL_WORK,
            CONST.ORDER_RAIL_WORK,
            false,
            false,
            true,
            CONST.LB_RAIL_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          railWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, railWork);
        }
      } else if (i === CONST.INDEX_IL_CO2_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_CO2_WORK)) {
          const co2Work: Co2Work = new Co2Work();
          co2Work.setAssociation(design, this);
          co2Work.setDefaultData(
            CONST.INDEX_IL_CO2_WORK,
            CONST.ORDER_CO2_WORK,
            false,
            false,
            true,
            CONST.LB_CO2_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          co2Work.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, co2Work);
        }
      } else if (i === CONST.INDEX_IL_AUTOSPRAY_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_AUTOSPRAY_WORK)) {
          const autosprayWork: AutosprayWork = new AutosprayWork();
          autosprayWork.setAssociation(design, this);
          autosprayWork.setDefaultData(
            CONST.INDEX_IL_AUTOSPRAY_WORK,
            CONST.ORDER_AUTOSPRAY_WORK,
            false,
            false,
            true,
            CONST.LB_AUTOSPRAY_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          autosprayWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, autosprayWork);
        }
      } else if (i === CONST.INDEX_IL_OTHER_FACILITIES_WORK) {
        if (
          this.workAC.length === i ||
          (this.workAC.length > i && this.workAC[i].label !== CONST.LB_OTHER_FACILITIES_WORK)
        ) {
          const facilityWork: FacilityWork = new FacilityWork();
          facilityWork.setAssociation(design, this);
          facilityWork.setDefaultData(
            CONST.INDEX_IL_OTHER_FACILITIES_WORK,
            CONST.ORDER_OTHER_FACILITIES_WORK,
            false,
            false,
            true,
            CONST.LB_OTHER_FACILITIES_WORK,
            CONST.LB_OTHER_FACILITIES_CONSTRUCTION,
          );
          facilityWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, facilityWork);
        }
      } else if (i === CONST.INDEX_IL_BUILDING_WORK) {
        if (this.workAC.length === i || (this.workAC.length > i && this.workAC[i].label !== CONST.LB_BUILDING_WORK)) {
          const buildingWork: BuildingWork = new BuildingWork();
          buildingWork.setAssociation(design, this);
          buildingWork.setDefaultData(
            CONST.INDEX_IL_BUILDING_WORK,
            CONST.ORDER_BUILDING_WORK,
            false,
            false,
            true,
            CONST.LB_BUILDING_WORK,
            CONST.LB_BUILDING_CONSTRUCTION,
          );
          buildingWork.selectedEstimate = !design.isSectionEstimate;
          this.workAC.splice(i, 0, buildingWork);
        }
      }
    }

    super.restoreLatestObject(design);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
