import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemVentilator } from "vhows-design/src/object/design/item/list/ItemVentilator";
import { Part } from "vhows-design/src/object/design/base/Part";
import { VentExhaustSample_EndpieceVentilator } from "vhows-design/src/object/design/vent/exhaust/VentExhaustSample_EndpieceVentilator";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-13
 */
@jsonObject({
  knownTypes: [VentExhaustSample_EndpieceVentilator],
})
export class VentExhaustPart_EndpieceFan extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public ventilatorSample: VentExhaustSample_EndpieceVentilator; // 배기팬 샘플

  // 아이템
  public ventFrontShutterOn_ventilator: ItemVentilator;
  public ventFrontShutterOff_ventilator: ItemVentilator;
  public ventBackShutterOn_ventilator: ItemVentilator;
  public ventBackShutterOff_ventilator: ItemVentilator;
  public vent_electricWire: ItemElectricWire;
  public vent_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _ventBuildingNumber: number; // 환기 동수
  @jsonMember(Number)
  public _ventQuantityFront: number = 0; // 셔터없는 배기팬 수량(앞면)
  @jsonMember(Number)
  public _ventQuantityBack: number = 0; // 셔터없는 배기팬 수량(뒷면)
  @jsonMember(Number)
  public _ventShutterQuantityFront: number = 0; // 셔터부착 배기팬 수량(앞면)
  @jsonMember(Number)
  public _ventShutterQuantityBack: number = 0; // 셔터부착 배기팬 수량(뒷면)

  /**
   * 환기 동수
   */
  public get ventBuildingNumber(): number {
    return this._ventBuildingNumber;
  }

  //
  public set ventBuildingNumber(value: number) {
    this._ventBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_ventShutterQuantityFront();
    this.algorithmPart();
  }

  /**
   * 셔터부착 배기팬 수량(앞면)
   */
  public get ventShutterQuantityFront(): number {
    return this._ventShutterQuantityFront;
  }

  //
  public set ventShutterQuantityFront(value: number) {
    this._ventShutterQuantityFront = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 셔터부착 배기팬 수량(뒷면)
   */
  public get ventShutterQuantityBack(): number {
    return this._ventShutterQuantityBack;
  }

  //
  public set ventShutterQuantityBack(value: number) {
    this._ventShutterQuantityBack = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 셔터없는 배기팬 수량(앞면)
   */
  public get ventQuantityFront(): number {
    return this._ventQuantityFront;
  }

  //
  public set ventQuantityFront(value: number) {
    this._ventQuantityFront = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 셔터없는 배기팬 수량(뒷면)
   */
  public get ventQuantityBack(): number {
    return this._ventQuantityBack;
  }

  //
  public set ventQuantityBack(value: number) {
    this._ventQuantityBack = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.ventilatorSample = new VentExhaustSample_EndpieceVentilator();

    this.sampleAC = [this.ventilatorSample];

    // 아이템
    this.ventFrontShutterOn_ventilator = new ItemVentilator();
    this.ventFrontShutterOff_ventilator = new ItemVentilator();
    this.ventBackShutterOn_ventilator = new ItemVentilator();
    this.ventBackShutterOff_ventilator = new ItemVentilator();
    this.vent_electricWire = new ItemElectricWire();
    this.vent_screw = new ItemScrew();

    this.itemAC = [
      this.ventFrontShutterOn_ventilator,
      this.ventFrontShutterOff_ventilator,
      this.ventBackShutterOn_ventilator,
      this.ventBackShutterOff_ventilator,
      this.vent_electricWire,
      this.vent_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.ventilatorSample = <VentExhaustSample_EndpieceVentilator>this.sampleAC[0];
    // 아이템
    this.ventFrontShutterOn_ventilator = <ItemVentilator>this.itemAC[0];
    this.ventFrontShutterOff_ventilator = <ItemVentilator>this.itemAC[1];
    this.ventBackShutterOn_ventilator = <ItemVentilator>this.itemAC[2];
    this.ventBackShutterOff_ventilator = <ItemVentilator>this.itemAC[3];
    this.vent_electricWire = <ItemElectricWire>this.itemAC[4];
    this.vent_screw = <ItemScrew>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param ventBuildingNumber: number 환기 동수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    ventBuildingNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._ventBuildingNumber = ventBuildingNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.ventilatorSample.setDefaultData(
        0,
        CONST.ITEM_ID_VENTILATOR,
        CONST.ITEM_NAME_VENTILATOR,
        "앞뒷면 배기팬",
        "일반, 셔터부착",
        "엘디, LD-S-181, 110㎥/min, 550*550mm, 450mm, 180W",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.ventFrontShutterOn_ventilator.setDefaultData(
        0,
        CONST.ITEM_ID_VENTILATOR,
        CONST.ITEM_NAME_VENTILATOR,
        "앞면 배기팬",
        "일반, 셔터부착",
        "엘디, LD-S-181, 110㎥/min, 550*550mm, 450mm, 180W",
      );
      this.ventFrontShutterOff_ventilator.setDefaultData(
        1,
        CONST.ITEM_ID_VENTILATOR,
        CONST.ITEM_NAME_VENTILATOR,
        "앞면 배기팬",
        "일반, -",
        "엘디, LD-S-181, 110㎥/min, 550*550mm, 450mm, 180W",
      );
      this.ventBackShutterOn_ventilator.setDefaultData(
        2,
        CONST.ITEM_ID_VENTILATOR,
        CONST.ITEM_NAME_VENTILATOR,
        "뒷면 배기팬",
        "일반, 셔터부착",
        "엘디, LD-S-181, 110㎥/min, 550*550mm, 450mm, 180W",
      );
      this.ventBackShutterOff_ventilator.setDefaultData(
        3,
        CONST.ITEM_ID_VENTILATOR,
        CONST.ITEM_NAME_VENTILATOR,
        "뒷면 배기팬",
        "일반, -",
        "엘디, LD-S-181, 110㎥/min, 550*550mm, 450mm, 180W",
      );
      this.vent_electricWire.setDefaultData(
        4,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "앞뒷면 배기팬",
        "VCTF, 2.5sq, 2C, 100m",
      );
      this.vent_screw.setDefaultData(
        5,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "앞뒷면 배기팬",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_ventBuildingNumber();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_vent_electricWire();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 수치 ///////////

    /// //////// 선택, 가시성 ///////////

    this.ventFrontShutterOn_ventilator.selected = true;
    this.ventFrontShutterOn_ventilator.visible = true;
    this.ventFrontShutterOff_ventilator.selected = true;
    this.ventFrontShutterOff_ventilator.visible = true;
    this.ventBackShutterOn_ventilator.selected = true;
    this.ventBackShutterOn_ventilator.visible = true;
    this.ventBackShutterOff_ventilator.selected = true;
    this.ventBackShutterOff_ventilator.visible = true;
    this.vent_electricWire.selected = true;
    this.vent_electricWire.visible = true;
    this.vent_screw.selected = true;
    this.vent_screw.visible = true;

    /// ////////  수량 ///////////

    this.ventFrontShutterOn_ventilator.designQuantity = this.ventShutterQuantityFront;

    this.ventFrontShutterOff_ventilator.designQuantity = this.ventQuantityFront;

    this.ventBackShutterOn_ventilator.designQuantity = this.ventShutterQuantityBack;

    this.ventBackShutterOff_ventilator.designQuantity = this.ventQuantityBack;

    let frontLength: number = 0;
    if (this.ventQuantityFront > 0) {
      frontLength =
        (this.basicLevel.width + this.basic.buildingInterval) * this.ventBuildingNumber +
        1 * this.basic.levelNumber * this.ventBuildingNumber;
    } else {
      frontLength = 0;
    }
    let backLength: number = 0;
    if (this.ventQuantityBack > 0) {
      backLength =
        (this.basicLevel.width + this.basic.buildingInterval) * this.ventBuildingNumber +
        this.basicLevel.length * this.ventBuildingNumber;
    } else {
      backLength = 0;
    }
    this.vent_electricWire.designQuantity =
      ((frontLength + backLength) / this.vent_electricWire.specLength) * CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;

    this.vent_screw.designQuantity =
      ((this.ventFrontShutterOn_ventilator.getSelectedQuantity() * 4 +
        this.ventFrontShutterOff_ventilator.getSelectedQuantity() * 4) /
        this.vent_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 환기 동수 <- 동수(기본정보)
   */
  public algorithm_ventBuildingNumber(): void {
    this.ventBuildingNumber = this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 셔터부착 배기팬 수량(앞면) <- 환기 동수
   */
  public algorithm_ventShutterQuantityFront(): void {
    this.ventShutterQuantityFront = 1 * this.ventBuildingNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [앞면 배기팬] 배기팬 <- 배기팬 샘플
   */
  public algorithmSpec_ventFrontShutterOn_ventilator(): void {
    this.ventFrontShutterOn_ventilator.specsList = this.ventilatorSample.specsList;
    this.ventFrontShutterOn_ventilator.brands = this.ventilatorSample.brands;
  }

  /**
   * 규격 알고리즘: [앞면 배기팬] 배기팬 <- 배기팬 샘플
   */
  public algorithmSpec_ventFrontShutterOff_ventilator(): void {
    this.ventFrontShutterOff_ventilator.specsList = this.ventilatorSample.specsList;
    this.ventFrontShutterOff_ventilator.brands = this.ventilatorSample.brands;
  }

  /**
   * 규격 알고리즘: [뒷면 배기팬] 배기팬 <- 배기팬 샘플
   */
  public algorithmSpec_ventBackShutterOn_ventilator(): void {
    this.ventBackShutterOn_ventilator.specsList = this.ventilatorSample.specsList;
    this.ventBackShutterOn_ventilator.brands = this.ventilatorSample.brands;
  }

  /**
   * 규격 알고리즘: [뒷면 배기팬] 배기팬 <- 배기팬 샘플
   */
  public algorithmSpec_ventBackShutterOff_ventilator(): void {
    this.ventBackShutterOff_ventilator.specsList = this.ventilatorSample.specsList;
    this.ventBackShutterOff_ventilator.brands = this.ventilatorSample.brands;
  }

  /**
   * 규격 알고리즘: [앞뒷면 배기팬] 전선 <- 환기 시설 전선 샘플(제어기)
   */
  public algorithmSpec_vent_electricWire(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.vent_electricWire.specs =
        this.struct.controllerWork.level1.integratedPosition.controllerPart.ventElectricWireSample.specs;
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.vent_electricWire.specs =
        this.struct.controllerWorkIL.level1.integratedPosition.controllerPart.ventElectricWireSample.specs;
    }
  }

  /**
   * 규격 알고리즘: [앞뒷면 배기팬] 나사 <- 공통 샘플
   */
  public algorithmSpec_vent_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
