import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPadVinyl } from "vhows-design/src/object/design/item/list/ItemPadVinyl";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { FixingSampleIL_NormalPad } from "vhows-design/src/object/design/cover/fixing/il/FixingSampleIL_NormalPad";
import { FixingSampleIL_NormalPadSpring } from "vhows-design/src/object/design/cover/fixing/il/FixingSampleIL_NormalPadSpring";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject({
  knownTypes: [FixingSampleIL_NormalPad, FixingSampleIL_NormalPadSpring],
})
export class FixingPartIL_NormalPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalPadSample: FixingSampleIL_NormalPad; // 일반 패드 샘플
  public normalPadSpringSample: FixingSampleIL_NormalPadSpring; // 일반 패드 스프링 샘플

  // 아이템
  public normalPad_normalPad: ItemPad;
  public normalPad_normalPadSpring: ItemPadSpring;
  public normalPad_padConnectionPin: ItemPadConnector;
  public normalPad_padVinyl: ItemPadVinyl;
  public normalPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalPadSample = new FixingSampleIL_NormalPad();
    this.normalPadSpringSample = new FixingSampleIL_NormalPadSpring();

    this.sampleAC = [this.normalPadSample, this.normalPadSpringSample];

    // 아이템
    this.normalPad_normalPad = new ItemPad();
    this.normalPad_normalPadSpring = new ItemPadSpring();
    this.normalPad_padConnectionPin = new ItemPadConnector();
    this.normalPad_padVinyl = new ItemPadVinyl();
    this.normalPad_screw = new ItemScrew();

    this.itemAC = [
      this.normalPad_normalPad,
      this.normalPad_normalPadSpring,
      this.normalPad_padConnectionPin,
      this.normalPad_padVinyl,
      this.normalPad_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalPadSample = <FixingSampleIL_NormalPad>this.sampleAC[0];
    this.normalPadSpringSample = <FixingSampleIL_NormalPadSpring>this.sampleAC[1];

    // 아이템
    this.normalPad_normalPad = <ItemPad>this.itemAC[0];
    this.normalPad_normalPadSpring = <ItemPadSpring>this.itemAC[1];
    this.normalPad_padConnectionPin = <ItemPadConnector>this.itemAC[2];
    this.normalPad_padVinyl = <ItemPadVinyl>this.itemAC[3];
    this.normalPad_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.normalPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드",
        "칼라 철, 0.6T, 6m",
      );
      this.normalPadSpringSample.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "일반 패드",
        "일반, 2m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalPad_normalPad.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드",
        "칼라 철, 0.6T, 6m",
      );
      this.normalPad_normalPadSpring.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "일반 패드",
        "일반, 2m",
      );
      this.normalPad_padConnectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "일반 패드",
        "칼라 철",
      );
      this.normalPad_padVinyl.setDefaultData(
        3,
        CONST.ITEM_ID_PAD_VINYL,
        CONST.ITEM_NAME_PAD_VINYL,
        "일반 패드",
        "일반, 200m",
      );
      this.normalPad_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "일반 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const fixingPosition: FixingPositionIL = <FixingPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;
    let totalLength: number;
    // 외부
    let wingPosition: WingPositionIL | WingPositionVL;
    let roofPosition: RoofPositionIL | RoofPositionVL;
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      wingPosition = (<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition;
      roofPosition = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      wingPosition = (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition;
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    const coverLevel: CoverLevelIL = <CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index];

    /// //////// 선택, 가시성 ///////////

    this.normalPad_normalPad.selected = true;
    this.normalPad_normalPad.visible = true;
    this.normalPad_normalPadSpring.selected = true;
    this.normalPad_normalPadSpring.visible = true;
    this.normalPad_padConnectionPin.setSelectedByPad(true, this.normalPad_normalPad.specMaterial);
    this.normalPad_padConnectionPin.visible = true;
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        this.normalPad_padVinyl.selected = this.design.basic.padVinylFlag && !wingPosition.selected;
      } else {
        this.normalPad_padVinyl.selected = this.design.basic.padVinylFlag;
      }
    } else {
      this.normalPad_padVinyl.selected = false;
    }
    this.normalPad_padVinyl.visible = true;
    this.normalPad_screw.selected = true;
    this.normalPad_screw.visible = true;

    /// //////// 수량 ///////////

    if (
      fixingPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      fixingPosition.label === CONST.LB_POSITION_SIDE_WING
    ) {
      this.normalPad_normalPad.designQuantity =
        (this.basicLevel.length / this.normalPad_normalPad.specLength) * fixingPosition.lineNumber;
    } else if (fixingPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          bothEndQuantity = (this.basicLevel.length / this.normalPad_normalPad.specLength) * 1 * 2;
          centralQuantity =
            (this.basicLevel.length / this.normalPad_normalPad.specLength) *
            fixingPosition.lineNumber *
            (this.basic.buildingNumber - 2);
          this.normalPad_normalPad.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          this.normalPad_normalPad.designQuantity =
            (this.basicLevel.length / this.normalPad_normalPad.specLength) *
            fixingPosition.lineNumber *
            this.basic.buildingNumber;
        }
      } else {
        this.normalPad_normalPad.designQuantity =
          (this.basicLevel.length / this.normalPad_normalPad.specLength) * fixingPosition.lineNumber;
      }
    } else if (fixingPosition.label === CONST.LB_POSITION_FRONT || fixingPosition.label === CONST.LB_POSITION_BACK) {
      totalLength = 0;
      if (fixingPosition.type === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        totalLength += this.basicLevel.width * fixingPosition.lineNumber * this.basic.buildingNumber;
      }
      if (fixingPosition.endpieceRafterType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        totalLength +=
          roofPosition.rafterPart.rafterTotalLength *
          fixingPosition.endpieceRafterLineNumber *
          this.basic.buildingNumber;
      }
      if (fixingPosition.endpieceColumnType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        totalLength += this.basicLevel.heightShoulder * fixingPosition.endpieceColumnLineNumber;
      }
      if (fixingPosition.endpieceGateType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        if (fixingPosition.label === CONST.LB_POSITION_FRONT) {
          totalLength += 2.0 * fixingPosition.endpieceGateLineNumber * endpieceLevel.frontPosition.gateBuildingNumber;
        } else if (fixingPosition.label === CONST.LB_POSITION_BACK) {
          totalLength += 2.0 * fixingPosition.endpieceGateLineNumber * endpieceLevel.backPosition.gateBuildingNumber;
        }
      }
      totalLength += 0.5 * this.basic.buildingNumber;
      this.normalPad_normalPad.designQuantity = totalLength / this.normalPad_normalPad.specLength;
    }

    let normalPad_normalPadSpring_designQuantity: number =
      this.normalPad_normalPad.getSelectedQuantity() *
      (this.normalPad_normalPad.specLength / this.normalPad_normalPadSpring.specLength);
    normalPad_normalPadSpring_designQuantity *= this.design.preference.getPadSpringMultFactor(
      coverLevel.coverLayers[this.position.index],
    );
    this.normalPad_normalPadSpring.designQuantity =
      normalPad_normalPadSpring_designQuantity * CONST.NUM_EXTRA_RATE_PAD_SPRING;

    this.normalPad_padConnectionPin.designQuantity = this.normalPad_normalPad.getSelectedQuantity();

    this.normalPad_padVinyl.designQuantity =
      (this.normalPad_normalPad.getSelectedQuantity() * this.normalPad_normalPad.specLength) /
      this.normalPad_padVinyl.specLength;

    this.normalPad_screw.designQuantity =
      ((this.normalPad_normalPad.getSelectedQuantity() * 6 +
        this.normalPad_padConnectionPin.getSelectedQuantity() * 2) /
        this.normalPad_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 패드] 일반 패드 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPad_normalPad(): void {
    this.normalPad_normalPad.specs = this.normalPadSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 패드] 일반 패드 스프링 <- 일반 패드 스프링 샘플
   */
  public algorithmSpec_normalPad_normalPadSpring(): void {
    this.normalPad_normalPadSpring.specs = this.normalPadSpringSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 패드] 패드 연결핀 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPad_padConnectionPin(): void {
    this.normalPad_padConnectionPin.specMaterial = this.normalPadSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [일반 패드] 패드 비닐 <- 공통 샘플
   */
  public algorithmSpec_normalPad_padVinyl(): void {}

  /**
   * 규격 알고리즘: [일반 패드] 나사 <- 공통 샘플
   */
  public algorithmSpec_normalPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
