import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWood } from "vhows-design/src/object/design/item/list/ItemWood";
import { WindowPartIL_FixingWood } from "vhows-design/src/object/design/other/window/il/WindowPartIL_FixingWood";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject
export class WindowSampleIL_RectLumber extends ItemWood {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingWoodPart: WindowPartIL_FixingWood = <WindowPartIL_FixingWood>this.part;
    fixingWoodPart.algorithmSpec_wood_rectLumber();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 각재 샘플(피복 고정)
   */
  // @override
  public algorithmSpec(): void {
    if (this.position.label === CONST.LB_POSITION_LEFT || this.position.label === CONST.LB_POSITION_RIGHT) {
      this.specs = this.struct.fixingWorkIL.level1.columnPosition.woodPart.rectLumberSample.specs;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.specs = this.struct.fixingWorkIL.level1.frontPosition.woodPart.rectLumberSample.specs;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.specs = this.struct.fixingWorkIL.level1.backPosition.woodPart.rectLumberSample.specs;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
