import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { FoundationLevelIL } from "vhows-design/src/object/design/foundation/il/FoundationLevelIL";
import { FoundationPositionIL } from "vhows-design/src/object/design/foundation/il/FoundationPositionIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { ColumnPartVL_Column } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Column";
import { ColumnPartVL_Beam } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Beam";
import { ColumnPartVL_Middle } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Middle";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-07
 */
@jsonObject
export class ColumnSampleVL_ColumnPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismColumnPart: ColumnPartVL_Column = <ColumnPartVL_Column>this.part;
    prismColumnPart.algorithmSpec_column_pipe();
    prismColumnPart.columnBracingPipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const prismBeamPart: ColumnPartVL_Beam = (<ColumnPositionVL>this.position).prismBeamPart;
    prismBeamPart.beamPipeSample.algorithmSpec();
    prismBeamPart.algorithmSpec_beamAndColumn_tClamp();

    const prismMiddlePart: ColumnPartVL_Middle = (<ColumnPositionVL>this.position).prismMiddlePart;
    prismMiddlePart.frontAndBackMiddlePipeSample.algorithmSpec();

    // /// //////// 외부 ///////////

    if (this.position.type === CONST.LB_COLUMN_TYPE_PRISM) {
      // 지붕 골조
      for (const roofLevel of this.struct.roofWorkVL.levelAC as RoofLevelVL[]) {
        // 모든 중수
        roofLevel.roofPosition.rafterPart.algorithmPart();
      }

      // 바닥도리 골조
      const foundationPosition: FoundationPositionIL = (<FoundationLevelIL>(
        this.struct.foundationWorkIL.levelAC[this.level.index]
      )).foundationPosition;
      foundationPosition.girthPipePart.algorithmSpec_girthLengthAndColumnPrism_uClamp();
      foundationPosition.girthPipePart.algorithmSpec_girthWidthAndColumnPrism_uClamp();
      //
      //
      // 물받이
      for (const gutterLevel of this.struct.gutterWorkIL.levelAC as GutterLevelIL[]) {
        // 모든 중수
        gutterLevel.gutterPosition.ironPlatePart.algorithmSpec_ironPlate_ubar();
        gutterLevel.gutterPosition.plasticPart.algorithmSpec_plastic_ubar();
      }

      // 피복 고정
      for (const fixingLevel of this.struct.fixingWorkIL.levelAC as FixingLevelIL[]) {
        // 모든 중수
        fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipColumn_normalClip();
        fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipColumn_normalClip();
      }

      // 치마 피복
      for (const skirtLevel of this.struct.skirtWorkIL.levelAC as SkirtLevelIL[]) {
        skirtLevel.columnPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
      }

      // 바람막이 피복
      for (const windbreakLevel of this.struct.windbreakWorkIL.levelAC as WindbreakLevelIL[]) {
        windbreakLevel.columnPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      }

      // 커튼 개폐
      for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
        curSwitchLevel.columnPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
        curSwitchLevel.frontPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
        curSwitchLevel.backPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
        curSwitchLevel.ceilingPosition.motorPart.reducedMotorSample.algorithmSpec();
        curSwitchLevel.ceilingPosition.framePart.algorithmSpec_frameDriveAxis_bearingSet();
        curSwitchLevel.ceilingPosition.framePart.algorithmSpec_frameSupportAxis_uClamp();
      }

      // 운반레일
      if (this.level.index === 0) {
        this.struct.railWork.level1.wholePosition.sideRailPart.algorithmSpec_railAndFrame_sideRailHook();
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 길이(파트)
   */
  // @override
  public algorithmSpec(): void {
    this.specLength = (<ColumnPartVL_Column>this.part).columnLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
