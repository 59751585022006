import { jsonObject } from "typedjson";

import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { BandstringPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPartIL_FixingNormalPad";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject
export class BandstringSampleIL_NormalPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingNormalPadPart: BandstringPartIL_FixingNormalPad = <BandstringPartIL_FixingNormalPad>this.part;
    fixingNormalPadPart.algorithmSpec_normalPad_normalPad();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 일반 패드 샘플(기초 피복 고정)
   */
  // @override
  public algorithmSpec(): void {
    const fixingLevel: FixingLevelIL = this.struct.fixingWorkIL.level1;
    this.specs = fixingLevel.wingPosition.normalPadPart.normalPadSample.specs; // 방풍벽(측면)을 따름
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
