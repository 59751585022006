import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SkyFramePart_Straight } from "vhows-design/src/object/design/skylight/frame/SkyFramePart_Straight";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-18
 */
@jsonObject
export class SkyFrameSample_StraightPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const straightPart: SkyFramePart_Straight = <SkyFramePart_Straight>this.part;
    straightPart.algorithmSpec_skyStraight_pipe();
    straightPart.algorithmSpec_skyStraightAndSkyStraight_connectionPin();
    straightPart.algorithmSpec_skyStraightAndSkyRafter_steelStringGrabber();
    straightPart.algorithmSpec_skyStraightAndSkyRafter_saddleHolder();
    straightPart.algorithmSpec_skyStraightAndSkyRafter_tHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 천창 서까래 파이프 샘플(반천창형), 형태
   */
  // @override
  public algorithmSpec(): void {
    if ((<SkyFramePosition>this.position).type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.specsList = (<SkyFramePosition>this.position).rafterPart.skyRafterPipeSample.specsList;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
