import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Level } from "vhows-design/src/object/design/base/Level";
import { WindowPosition } from "vhows-design/src/object/design/other/window/WindowPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-04
 */
@jsonObject({
  knownTypes: [WindowPosition],
})
export class WindowLevel extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public leftPosition: WindowPosition;
  public rightPosition: WindowPosition;
  public frontPosition: WindowPosition;
  public backPosition: WindowPosition;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  get totalWindowQuantity(): number {
    let totalWindowQuantity: number = 0;

    for (const windowPosition of this.struct.windowWork.level1.positionAC) {
      if (windowPosition.selected) {
        totalWindowQuantity += (windowPosition as WindowPosition).windowQuantity;
      }
    }
    return totalWindowQuantity;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.leftPosition = new WindowPosition();
    this.rightPosition = new WindowPosition();
    this.frontPosition = new WindowPosition();
    this.backPosition = new WindowPosition();

    this.positionAC = [this.leftPosition, this.rightPosition, this.frontPosition, this.backPosition];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.leftPosition = <WindowPosition>this.positionAC[0];
    this.rightPosition = <WindowPosition>this.positionAC[1];
    this.frontPosition = <WindowPosition>this.positionAC[2];
    this.backPosition = <WindowPosition>this.positionAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index === 0) {
      this.leftPosition.setDefaultData(
        0,
        false,
        true,
        true,
        CONST.LB_POSITION_LEFT,
        CONST.LB_WINDOW_TYPE_SASH,
        3,
        10,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.rightPosition.setDefaultData(
        1,
        false,
        true,
        true,
        CONST.LB_POSITION_RIGHT,
        CONST.LB_WINDOW_TYPE_SASH,
        3,
        10,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.frontPosition.setDefaultData(
        2,
        false,
        true,
        true,
        CONST.LB_POSITION_FRONT,
        CONST.LB_WINDOW_TYPE_SASH,
        1,
        10,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
      this.backPosition.setDefaultData(
        3,
        false,
        true,
        true,
        CONST.LB_POSITION_BACK,
        CONST.LB_WINDOW_TYPE_SASH,
        1,
        10,
        CONST.LB_FIXING_TYPE_NORMAL_PAD,
      );
    } else if (index === 1 || index === 2 || index === 3) {
      // 없음
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
