import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemBearing } from "vhows-design/src/object/design/item/list/ItemBearing";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { CurSwitchSampleIL_DriveAxisPipe } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_DriveAxisPipe";
import { CurSwitchSampleIL_SupportAxisPipe } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_SupportAxisPipe";
import { CurSwitchSampleIL_SupportPipe } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_SupportPipe";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { CurSwitchSampleIL_DriveAxisPipeVL } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_DriveAxisPipeVL";
import { ItemCurtainOther } from "vhows-design/src/object/design/item/list/ItemCurtainOther";
import { ItemWireStrain } from "vhows-design/src/object/design/item/list/ItemWireStrain";
import { ItemCordWire } from "vhows-design/src/object/design/item/list/ItemCordWire";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { ColumnPartVL_Column } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Column";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurSwitchSampleIL_DriveAxisPipeVL],
})
export class CurSwitchPartIL_FrameVL extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public driveAxisPipeSample: CurSwitchSampleIL_DriveAxisPipeVL; // 커튼 구동 축 파이프 샘플

  // 아이템
  public frameDriveAxis_pipe: ItemPipe;
  public frameDriveAxis_connectionPin: ItemConnector;
  public frameDriveAxis_bearingSet: ItemBearing;
  public framePocket_screenPincer: ItemCurtainOther;
  public framePocket_coatingWire: ItemCordWire;
  public framePocket_normalClip: ItemClip;
  public frame_screw: ItemScrew;
  public frame_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _axisLength: number; // 개폐 축 길이
  @jsonMember(Number)
  public _driveAxisLineNumber: number; // 구동 축 줄수
  // @jsonMember
  // public _supportAxisLineNumber: number; // 지지 축 줄수
  // @jsonMember
  // public _pocketFixingPipeSelected: boolean = false; // 주머니 지지 파이프 선택

  /**
   * 개폐 축 길이
   */
  public get axisLength(): number {
    return this._axisLength;
  }

  //
  public set axisLength(value: number) {
    this._axisLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 구동 축 줄수
   */
  public get driveAxisLineNumber(): number {
    return this._driveAxisLineNumber;
  }

  //
  public set driveAxisLineNumber(value: number) {
    this._driveAxisLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.driveAxisPipeSample = new CurSwitchSampleIL_DriveAxisPipeVL();

    this.sampleAC = [this.driveAxisPipeSample];

    // 아이템
    this.frameDriveAxis_pipe = new ItemPipe();
    this.frameDriveAxis_connectionPin = new ItemConnector();
    this.frameDriveAxis_bearingSet = new ItemBearing();
    this.framePocket_screenPincer = new ItemCurtainOther();
    this.framePocket_coatingWire = new ItemCordWire();
    this.frame_screw = new ItemScrew();
    this.frame_welding = new ItemOtherExpense();

    this.itemAC = [
      this.frameDriveAxis_pipe,
      this.frameDriveAxis_connectionPin,
      this.frameDriveAxis_bearingSet,
      this.framePocket_screenPincer,
      this.framePocket_coatingWire,
      this.frame_screw,
      this.frame_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.driveAxisPipeSample = <CurSwitchSampleIL_DriveAxisPipeVL>this.sampleAC[0];

    // 아이템
    this.frameDriveAxis_pipe = <ItemPipe>this.itemAC[0];
    this.frameDriveAxis_connectionPin = <ItemConnector>this.itemAC[1];
    this.frameDriveAxis_bearingSet = <ItemBearing>this.itemAC[2];
    this.framePocket_screenPincer = <ItemCurtainOther>this.itemAC[3];
    this.framePocket_coatingWire = <ItemCordWire>this.itemAC[4];
    this.frame_screw = <ItemScrew>this.itemAC[5];
    this.frame_welding = <ItemOtherExpense>this.itemAC[6];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param driveAxisLineNumber: number 구동 축 줄수
   * @param supportAxisLineNumber: number 지지 축 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    driveAxisLineNumber: number = 0,
    supportAxisLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._driveAxisLineNumber = driveAxisLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.driveAxisPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 구동 축",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.frameDriveAxis_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 구동 축",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.frameDriveAxis_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 구동 축",
        "원형, 일반, 48mm, 48mm",
      );
      this.frameDriveAxis_bearingSet.setDefaultData(
        2,
        CONST.ITEM_ID_BEARING_SET,
        CONST.ITEM_NAME_BEARING_SET,
        "커튼 구동 축",
        "원형, 일반, 48mm, 48mm",
      );
      this.framePocket_screenPincer.setDefaultData(
        3,
        CONST.ITEM_ID_SCREEN_PINCER,
        CONST.ITEM_NAME_SCREEN_PINCER,
        "커튼 주머니",
        "일반",
      );
      this.framePocket_coatingWire.setDefaultData(
        4,
        CONST.ITEM_ID_COATING_WIRE,
        CONST.ITEM_NAME_COATING_WIRE,
        "커튼 주머니",
        "코팅줄, 철사, 1.8mm, 2000m",
      );
      this.frame_screw.setDefaultData(
        5,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "커튼 수평 축",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.frame_welding.setDefaultData(6, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "커튼 수평 축", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_axisLength();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_frameDriveAxis_bearingSet();
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;
    // 외부
    const curCoverPosition: CurCoverPositionIL = (<CurCoverLevelIL>this.struct.curCoverWorkIL.level1).curCoverPosition;

    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 선택, 가시성 ///////////

    this.frameDriveAxis_pipe.checkSelected(true);
    this.frameDriveAxis_connectionPin.checkSelected(true);
    this.frameDriveAxis_bearingSet.selected = true;
    this.frameDriveAxis_bearingSet.visible = true;
    this.framePocket_screenPincer.selected = true;
    this.framePocket_screenPincer.visible = true;
    this.framePocket_coatingWire.selected = true;
    this.framePocket_coatingWire.visible = true;
    this.frame_screw.selected = true;
    this.frame_screw.visible = true;
    this.frame_welding.selected = true;
    this.frame_welding.visible = true;

    /// //////// 수량 ///////////

    this.frameDriveAxis_pipe.designQuantity =
      (this.frameDriveAxis_pipe.getConnectedPipeLength(this.axisLength, this.driveAxisLineNumber) /
        this.frameDriveAxis_pipe.specLength) *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_CURTAIN_DRIVE_AXIS_PIPE;

    if (this.axisLength > this.frameDriveAxis_pipe.specLength) {
      this.frameDriveAxis_connectionPin.designQuantity =
        this.frameDriveAxis_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.frameDriveAxis_connectionPin.designQuantity = 0;
    }

    if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const columPart: ColumnPartVL_Column = <ColumnPartVL_Column>(
        this.struct.columnWorkVL.level1.columnPosition.prismColumnPart
      );
      this.frameDriveAxis_bearingSet.designQuantity =
        ((columPart.edgeColumnQuantity +
          columPart.frontAndBackColumnQuantity +
          columPart.frontAndBackStudColumnQuantity) /
          2) *
        curCoverPosition.ceilingLevelValue *
        this.driveAxisLineNumber *
        CONST.NUM_EXTRA_RATE_BEARING_SET;
    } else {
      this.frameDriveAxis_bearingSet.designQuantity =
        Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
        curCoverPosition.ceilingLevelValue *
        this.driveAxisLineNumber *
        CONST.NUM_EXTRA_RATE_BEARING_SET;
    }

    this.framePocket_screenPincer.designQuantity =
      (this.basicLevel.length / 0.3) * 2 * curCoverPosition.ceilingLevelValue * CONST.NUM_EXTRA_RATE_SCREEN_PINCER;

    this.framePocket_coatingWire.designQuantity =
      ((this.basicLevel.length * 4 * 2) / this.framePocket_coatingWire.specLength) * curCoverPosition.ceilingLevelValue;

    this.frame_screw.designQuantity =
      ((this.frameDriveAxis_connectionPin.getSelectedQuantity() * 4) / this.frame_screw.specUnitQuantity) *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_SCREW;

    if (this.frameDriveAxis_bearingSet.specCrossSize1 === CONST.ITEM_SPEC_VALUE_NONE) {
      this.frame_welding.designQuantity = this.frameDriveAxis_bearingSet.getSelectedQuantity();
    } else {
      this.frame_welding.designQuantity = 0;
    }
  }

  /**
   * 알고리즘: 개폐 축 길이 <- 길이(기본정보)
   */
  public algorithm_axisLength(): void {
    this.axisLength = this.basicLevel.width * this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 구동 축 줄수 <- 개폐 분할(위치)
   */
  public algorithm_driveAxisLineNumber(): void {
    this.driveAxisLineNumber = (<CurSwitchPositionIL>this.position).switchDivisionValue;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [커튼 구동 축] 파이프 <- 커튼 구동 축 파이프 샘플
   */
  public algorithmSpec_frameDriveAxis_pipe(): void {
    this.frameDriveAxis_pipe.specs = this.driveAxisPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [커튼 구동 축] 연결핀 <- 커튼 구동 축 파이프 샘플
   */
  public algorithmSpec_frameDriveAxis_connectionPin(): void {
    this.frameDriveAxis_connectionPin.specPipeType = this.driveAxisPipeSample.specPipeType;
    this.frameDriveAxis_connectionPin.specCrossSize1 = this.driveAxisPipeSample.specCrossSize;
    this.frameDriveAxis_connectionPin.specCrossSize2 = this.driveAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 구동 축] 축수 베어링 세트 <- 커튼 구동 축 파이프 샘플, 형태(기둥 골조), 원기둥 파이프 샘플, 각기둥 파이프 샘플
   */
  public algorithmSpec_frameDriveAxis_bearingSet(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.struct.columnWorkIL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.frameDriveAxis_bearingSet.specPipeType =
          this.struct.columnWorkIL.level1.columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
        this.frameDriveAxis_bearingSet.specCrossSize1 =
          this.struct.columnWorkIL.level1.columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      } else if (this.struct.columnWorkIL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.frameDriveAxis_bearingSet.specPipeType =
          this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specPipeType;
        // 하드코딩
        if (
          this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize ===
          CONST.ITEM_SPEC_VALUE_60_60_MM
        ) {
          this.frameDriveAxis_bearingSet.specCrossSize1 =
            this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
        } else {
          this.frameDriveAxis_bearingSet.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
        }
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.struct.columnWorkVL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.frameDriveAxis_bearingSet.specPipeType =
          this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specPipeType;
        // 하드코딩
        if (
          this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize ===
          CONST.ITEM_SPEC_VALUE_60_60_MM
        ) {
          this.frameDriveAxis_bearingSet.specCrossSize1 =
            this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
        } else {
          this.frameDriveAxis_bearingSet.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
        }
      }
    }
    this.frameDriveAxis_bearingSet.specCrossSize2 = this.driveAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 수평 축] 나사 <- 공통 샘플
   */
  public algorithmSpec_axis_screw(): void {}
  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
