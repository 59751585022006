import "@greenlabs/formula-components/formula.css";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { checkCondition, CheckVersionHooks, GoogleAnalytics, QA_FLAG_STAGING } from "@vhows/util";
import ErrorBoundary from "@vhows/util/error-boundary";
import Layout from "components/layout";
import LayoutV2 from "components/layout/layout-v2";
import { ROUTE } from "constants/route";
import "font/pretendard/pretendard-subset.css";
import { useAtomValue } from "jotai";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { ReactElement, ReactNode } from "react";
import "reflect-metadata";
import "styles/globals.css";
import { ErrorPage } from "ui/components/error";
import "ui/dist/esm.css";
import LottieLoading from "../components/common/lottie-loading";
import ToastUi from "../components/common/toast";
import useCheckApp from "../hooks/useCheckApp";
import { designLoadingAtom, setRouter } from "../store/global";

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 100,
      refetchOnWindowFocus: false,
    },
  },
});

function CustomApp({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const router = useRouter();
  const loading = useAtomValue(designLoadingAtom);
  const { isApp } = useCheckApp(pageProps.isApp);

  setRouter(router);

  const checkConditionChildren = () => {
    return checkCondition([
      [
        CheckVersionHooks("4.4.0", QA_FLAG_STAGING),
        !isApp && router.pathname === ROUTE.INDEX ? (
          <ErrorBoundary fallback={<ErrorPage padding={true} />}>
            <Component key="childrenComponent" {...pageProps} />
          </ErrorBoundary>
        ) : (
          <LayoutV2 key="LayoutV2">
            <ErrorBoundary key={router.pathname} fallback={<ErrorPage padding={true} />}>
              <Component {...pageProps} />
            </ErrorBoundary>
          </LayoutV2>
        ),
      ],
      [
        CheckVersionHooks("4.1.0", QA_FLAG_STAGING),

        <LayoutV2 key="LayoutV2">
          <ErrorBoundary key={router.pathname} fallback={<ErrorPage padding={true} />}>
            <Component {...pageProps} />
          </ErrorBoundary>
        </LayoutV2>,
      ],

      [
        CheckVersionHooks("4.0.0", QA_FLAG_STAGING),
        router.pathname !== ROUTE.INDEX ? (
          <Layout key="LayoutV2">
            <ErrorBoundary key={router.pathname} fallback={<ErrorPage padding={true} />}>
              <Component {...pageProps} />
            </ErrorBoundary>
          </Layout>
        ) : (
          <ErrorBoundary fallback={<ErrorPage padding={true} />}>
            <Component key="childrenComponent" {...pageProps} />
          </ErrorBoundary>
        ),
      ],
      [
        CheckVersionHooks("3.0.0", QA_FLAG_STAGING),
        router.pathname !== ROUTE.INDEX ? (
          <Layout key="LayoutV2">
            <ErrorBoundary key={router.pathname} fallback={<ErrorPage padding={true} />}>
              <Component {...pageProps} />
            </ErrorBoundary>
          </Layout>
        ) : (
          <ErrorBoundary fallback={<ErrorPage padding={true} />}>
            <Component key="childrenComponent" {...pageProps} />
          </ErrorBoundary>
        ),
      ],
      [
        <Layout key="LayoutV2">
          <Component {...pageProps} />
        </Layout>,
      ],
    ]);
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_GA_ID && GoogleAnalytics(process.env.NEXT_PUBLIC_GA_ID)}
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          {checkConditionChildren()}
          {loading && <LottieLoading />}
          <ToastUi />
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}
export default CustomApp;
