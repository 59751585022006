import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 분수호스
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemFountainHose extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성], (길이)
  public _specFeature: string = undefined; // 특성
  public _specLength: number = undefined; // 길이

  // 하우스 설계 > 상표 : [회사, 모델, 형태, 지름, 살수폭], {재고길이}
  public _brandModelName: string = undefined; // 모델
  public _brandType: string = undefined; // 형태
  public _brandDiameter: string = undefined; // 지름
  public _brandWateringWidth: string = undefined; // 살수폭
  public _brandLength: number = undefined; // 재고길이

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    this._specLength = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_METER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  public get brandsList(): string {
    return this._brandsList;
  }
  //
  public set brandsList(value: string) {
    if (this._brandsList === value) return;

    this._brandsList = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeEachBrandByBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findUnitPriceModel();
      // 알고리즘

      // 상표 재고길이 찾기
      if (this.brandCompanyModel != null) {
        this.brandLength = this.brandCompanyModel.brandModel.num1;
      }
    }
  }

  /**
   * 모델
   */
  public get brandModelName(): string {
    return this._brandModelName;
  }
  //
  public set brandModelName(value: string) {
    this._brandModelName = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
    this.algorithm_specLengthByBrandModel();
  }

  /**
   * 형태
   */
  public get brandType(): string {
    return this._brandType;
  }
  //
  public set brandType(value: string) {
    this._brandType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 지름
   */
  public get brandDiameter(): string {
    return this._brandDiameter;
  }
  //
  public set brandDiameter(value: string) {
    this._brandDiameter = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 살수폭
   */
  public get brandWateringWidth(): string {
    return this._brandWateringWidth;
  }
  //
  public set brandWateringWidth(value: string) {
    this._brandWateringWidth = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 재고길이
   */
  public get brandLength(): number {
    return this._brandLength;
  }
  //
  public set brandLength(value: number) {
    this._brandLength = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 재고길이 레이블(값+단위기호)
   */
  public get brandLengthLabel(): string {
    return this.brandLength + CONST.UNIT_METER;
  }
  //
  public set brandLengthLabel(value: string) {
    this.brandLength = parseFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.fountainHoseCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 매출가 계산식
    let sellingSupply: number = Math.ceil(Math.ceil(defaultUnitSupply / this.brandLength) * this.specLength);

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specLengthLabel = specsArray[1];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList + CONST.DELIMITER_SPEC + this.specLengthLabel;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    const brandsArray: string[] = this._brands.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandType = brandsArray[2];
    this.brandDiameter = brandsArray[3];
    this.brandWateringWidth = brandsArray[4];
    this.brandLengthLabel = brandsArray[5];
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    const brandsArray: string[] = this._brandsList.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandType = brandsArray[2];
    this.brandDiameter = brandsArray[3];
    this.brandWateringWidth = brandsArray[4];
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList =
      this._brandCompany +
      CONST.DELIMITER_BRAND +
      this._brandModelName +
      CONST.DELIMITER_BRAND +
      this._brandType +
      CONST.DELIMITER_BRAND +
      this._brandDiameter +
      CONST.DELIMITER_BRAND +
      this._brandWateringWidth;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList + CONST.DELIMITER_BRAND + this.brandLengthLabel;
  }

  //----------------------------------
  // 하우스 설계 > 특정 규격 및 상표
  //----------------------------------

  /**
   * 알고리즘: 규격 길이 <- 상표 모델
   */
  protected algorithm_specLengthByBrandModel(): void {
    if (!isNaN(this.brandLength) && this.brandLength !== 0) {
      this.specLength = this.brandLength;
    }
  }
}
