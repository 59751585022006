import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 다이아몬드 클램프
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemDiamondClamp extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [파이프형태, 재질, 기둥단면크기, 보단면크기, 꽂이단면크기, 물받이폭]
  public _specPipeType: string = undefined; // 파이프형태
  public _specMaterial: string = undefined; // 재질
  public _specColumnCrossSize: string = undefined; // 기둥단면크기
  public _specBeamCrossSize: string = undefined; // 보단면크기
  public _specSconceCrossSize: string = undefined; // 꽂이단면크기
  public _specGutterWidth: string = undefined; // 묻받이폭

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 파이프형태
   */
  public get specPipeType(): string {
    return this._specPipeType;
  }
  //
  public set specPipeType(value: string) {
    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 재질
   */
  public get specMaterial(): string {
    return this._specMaterial;
  }
  //
  public set specMaterial(value: string) {
    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 기둥단면크기
   */
  public get specColumnCrossSize(): string {
    return this._specColumnCrossSize;
  }
  //
  public set specColumnCrossSize(value: string) {
    this._specColumnCrossSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 보단면크기
   */
  public get specBeamCrossSize(): string {
    return this._specBeamCrossSize;
  }
  //
  public set specBeamCrossSize(value: string) {
    this._specBeamCrossSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 꽂이단면크기
   */
  public get specSconceCrossSize(): string {
    return this._specSconceCrossSize;
  }
  //
  public set specSconceCrossSize(value: string) {
    this._specSconceCrossSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 물받이폭
   */
  public get specGutterWidth(): string {
    return this._specGutterWidth;
  }
  //
  public set specGutterWidth(value: string) {
    this._specGutterWidth = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.diamondClampCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specMaterial = specsArray[1];
    this.specColumnCrossSize = specsArray[2];
    this.specBeamCrossSize = specsArray[3];
    this.specSconceCrossSize = specsArray[4];
    this.specGutterWidth = specsArray[5];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specMaterial = specsArray[1];
    this.specColumnCrossSize = specsArray[2];
    this.specBeamCrossSize = specsArray[3];
    this.specSconceCrossSize = specsArray[4];
    this.specGutterWidth = specsArray[5];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specPipeType +
      CONST.DELIMITER_SPEC +
      this._specMaterial +
      CONST.DELIMITER_SPEC +
      this._specColumnCrossSize +
      CONST.DELIMITER_SPEC +
      this._specBeamCrossSize +
      CONST.DELIMITER_SPEC +
      this._specSconceCrossSize +
      CONST.DELIMITER_SPEC +
      this._specGutterWidth;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }
}
