import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { ColumnPartIL_SideStraight } from "vhows-design/src/object/design/frame/column/ColumnPartIL_SideStraight";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-16
 */
@jsonObject
export class ColumnSampleIL_SideStraightCSectionSteel extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const sideStraightPart: ColumnPartIL_SideStraight = <ColumnPartIL_SideStraight>this.part;
    sideStraightPart.algorithmSpec_sideStraight_cSectionSteel();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    if (sideStraightPart.sideStraightType === CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_C_SECTION_STEEL) {
      // 피복 고정
      for (const fixingLevel of this.struct.fixingWorkIL.levelAC as FixingLevelIL[]) {
        // 모든 중수
        fixingLevel.columnPosition.normalClipPart.normalClipSample.specCrossSize = this.specCrossSize;
      }

      // 치마 피복
      for (const skirtLevel of this.struct.skirtWorkIL.levelAC as SkirtLevelIL[]) {
        skirtLevel.columnPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
