import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { MainpipePart_UpperFrame } from "vhows-design/src/object/design/watering/mainpipe/MainpipePart_UpperFrame";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject
export class MainpipeSample_UpperWateringFixPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const upperFramePart: MainpipePart_UpperFrame = <MainpipePart_UpperFrame>this.part;
    upperFramePart.algorithmSpec_wateringFix_pipe();
    upperFramePart.algorithmSpec_wateringFix_connectionPin();
    upperFramePart.algorithmSpec_wateringFixAndMiddleCylinder_steelStringGrabber();
    upperFramePart.algorithmSpec_wateringFixAndMiddlePrism_saddleHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 관수 지관부
    const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
    wateringPosition.miniklerFountainPart.algorithmSpec_miniklerHangerHanger_pePipeHanger();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
