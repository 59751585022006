import { jsonObject } from "typedjson";

import { ItemVentHoleSwitchSet } from "vhows-design/src/object/design/item/list/ItemVentHoleSwitchSet";
import { VentExhaustPart_RoofFanManual } from "vhows-design/src/object/design/vent/exhaust/VentExhaustPart_RoofFanManual";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-13
 */
@jsonObject
export class VentExhaustSample_RoofVentHoleSwitchSet extends ItemVentHoleSwitchSet {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const roofFanManualPart: VentExhaustPart_RoofFanManual = <VentExhaustPart_RoofFanManual>this.part;
    roofFanManualPart.algorithmSpec_vent_ventHoleSwitchSet();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 환기구 샘플
   */
  // @override
  public algorithmSpec(): void {
    // 하드코딩
    if ((<VentExhaustPart_RoofFanManual>this.part).ventHoleSample.brandCompany === "대흥산업") {
      this.brands = "대흥산업, 기본 수동, 10개, 95m";
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
