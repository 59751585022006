import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { BandstringPosition } from "vhows-design/src/object/design/cover/bandstring/BandstringPosition";
import { BandstringSample_HookHolder } from "vhows-design/src/object/design/cover/bandstring/BandstringSample_HookHolder";
import { BandstringSample_HookPipe } from "vhows-design/src/object/design/cover/bandstring/BandstringSample_HookPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-06-25
 */
@jsonObject({
  knownTypes: [BandstringSample_HookHolder, BandstringSample_HookPipe],
})
export class BandstringPart_FixingHook extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public hookHolderSample: BandstringSample_HookHolder; // 고리 고정구 샘플
  public hookPipeSample: BandstringSample_HookPipe; // 고리 고정 파이프 샘플

  // 아이템
  public hook_hookHolder: ItemHolder;
  public hook_pipe: ItemPipe;
  public hook_connectionPin: ItemConnector;
  public hook_screw: ItemScrew;
  public hook_normalClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Boolean)
  public _hookPipeSelected: boolean; // 고리+파이프 선택
  @jsonMember(Boolean)
  public _hookPipeClipSelected: boolean; // 고리+파이프+클립 선택

  /**
   * 고리+파이프 선택
   */
  public get hookPipeSelected(): boolean {
    return this._hookPipeSelected;
  }

  //
  public set hookPipeSelected(value: boolean) {
    this._hookPipeSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 고리+파이프+클립 선택
   */
  public get hookPipeClipSelected(): boolean {
    return this._hookPipeClipSelected;
  }

  //
  public set hookPipeClipSelected(value: boolean) {
    this._hookPipeClipSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.hookHolderSample = new BandstringSample_HookHolder();
    this.hookPipeSample = new BandstringSample_HookPipe();

    this.sampleAC = [this.hookHolderSample, this.hookPipeSample];

    // 아이템
    this.hook_hookHolder = new ItemHolder();
    this.hook_pipe = new ItemPipe();
    this.hook_connectionPin = new ItemConnector();
    this.hook_screw = new ItemScrew();
    this.hook_normalClip = new ItemClip();

    this.itemAC = [
      this.hook_hookHolder,
      this.hook_pipe,
      this.hook_connectionPin,
      this.hook_screw,
      this.hook_normalClip,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.hookHolderSample = this.sampleAC[0] as BandstringSample_HookHolder;
    this.hookPipeSample = this.sampleAC[1] as BandstringSample_HookPipe;

    // 아이템
    this.hook_hookHolder = this.itemAC[0] as ItemHolder;
    this.hook_pipe = this.itemAC[1] as ItemPipe;
    this.hook_connectionPin = this.itemAC[2] as ItemConnector;
    this.hook_screw = this.itemAC[3] as ItemScrew;
    this.hook_normalClip = this.itemAC[4] as ItemClip;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param hookPipeSelected: boolean 고리+파이프 선택
   * @param hookPipeClipSelected: boolean 고리+파이프+클립 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    hookPipeSelected: boolean = false,
    hookPipeClipSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._hookPipeSelected = hookPipeSelected;
    this._hookPipeClipSelected = hookPipeClipSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.hookHolderSample.setDefaultData(
        0,
        CONST.ITEM_ID_HOOK_HOLDER,
        CONST.ITEM_NAME_HOOK_HOLDER,
        "고리",
        "원형, 일반, 25mm, -",
      );
      this.hookPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "고리+파이프",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.hook_hookHolder.setDefaultData(
        0,
        CONST.ITEM_ID_HOOK_HOLDER,
        CONST.ITEM_NAME_HOOK_HOLDER,
        "고리",
        "원형, 일반, 25mm, -",
      );
      this.hook_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "고리+파이프",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.hook_connectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "고리+파이프",
        "원형, 일반, 25mm, 25mm",
      );
      this.hook_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "고리+파이프",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.hook_normalClip.setDefaultData(
        4,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "고리+파이프+클립",
        "플라스틱, 25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const bandstringPosition: BandstringPosition = this.position as BandstringPosition;

    /// //////// 선택, 가시성 ///////////

    if (bandstringPosition.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_HOOK) {
      this.hook_hookHolder.selected = true;
      this.hook_hookHolder.visible = true;
      this.hook_pipe.checkSelected(false);
      this.hook_connectionPin.checkSelected(false);
      this.hook_screw.selected = false;
      this.hook_screw.visible = false;
      this.hook_normalClip.selected = false;
      this.hook_normalClip.visible = false;
    } else if (bandstringPosition.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_HOOK_PIPE) {
      this.hook_hookHolder.selected = true;
      this.hook_hookHolder.visible = true;
      this.hook_pipe.checkSelected(true);
      this.hook_connectionPin.checkSelected(true);
      this.hook_screw.selected = true;
      this.hook_screw.visible = true;
      this.hook_normalClip.selected = false;
      this.hook_normalClip.visible = false;
    } else if (bandstringPosition.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_HOOK_PIPE_CLIP) {
      this.hook_hookHolder.selected = true;
      this.hook_hookHolder.visible = true;
      this.hook_pipe.checkSelected(true);
      this.hook_connectionPin.checkSelected(true);
      this.hook_screw.selected = true;
      this.hook_screw.visible = true;
      this.hook_normalClip.selected = true;
      this.hook_normalClip.visible = true;
    }

    /// //////// 수량 ///////////

    this.hook_hookHolder.designQuantity =
      (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 1) *
      2 *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_HOOK_HOLDER;

    this.hook_pipe.designQuantity =
      (Math.ceil(this.basicLevel.length) / this.hook_pipe.specLength) * 2 * this.basic.buildingNumber;

    if (this.basicLevel.length > this.hook_pipe.specLength) {
      this.hook_connectionPin.designQuantity =
        this.hook_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.hook_connectionPin.designQuantity = 0;
    }

    this.hook_screw.designQuantity =
      ((this.hook_connectionPin.getSelectedQuantity() * 2) / this.hook_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.hook_normalClip.designQuantity = (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 1) * 4;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [고리] 고리 고정구 <- 고리 고정구 샘플
   */
  public algorithmSpec_hook_hookHolder(): void {
    this.hook_hookHolder.specs = this.hookHolderSample.specs;
  }

  /**
   * 규격 알고리즘: [고리+파이프] 파이프 <- 고리 고정 파이프 샘플
   */
  public algorithmSpec_hook_pipe(): void {
    this.hook_pipe.specs = this.hookPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [고리+파이프] 연결핀 <- 고리 고정 파이프 샘플
   */
  public algorithmSpec_hook_connectionPin(): void {
    this.hook_connectionPin.specPipeType = this.hookPipeSample.specPipeType;
    this.hook_connectionPin.specCrossSize1 = this.hookPipeSample.specCrossSize;
    this.hook_connectionPin.specCrossSize2 = this.hookPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [고리+파이프] 나사 <- 공통 샘플
   */
  public algorithmSpec_hook_screw(): void {}

  /**
   * 규격 알고리즘: [고리+파이프+클립] 일반 클립 <- 고리 고정 파이프 샘플
   */
  public algorithmSpec_hook_normalClip(): void {
    this.hook_normalClip.specCrossSize = this.hookPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
