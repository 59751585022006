import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { CurSwitchPartIL_Axis } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Axis";
import { CurSwitchPartIL_FixingClipCeiling } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_FixingClipCeiling";
import { CurSwitchPartIL_FixingClipSide } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_FixingClipSide";
import { CurSwitchPartIL_Frame } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Frame";
import { CurSwitchPartIL_Line } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Line";
import { CurSwitchPartIL_Motor } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Motor";
import { CurSwitchPartIL_Power } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Power";
import { CurSwitchPartIL_SupVertical } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_SupVertical";
import { CurSwitchPartIL_FrameVL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_FrameVL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [
    CurSwitchPartIL_Axis,
    CurSwitchPartIL_FixingClipCeiling,
    CurSwitchPartIL_FixingClipSide,
    CurSwitchPartIL_Frame,
    CurSwitchPartIL_FrameVL,
    CurSwitchPartIL_Line,
    CurSwitchPartIL_Motor,
    CurSwitchPartIL_Power,
    CurSwitchPartIL_SupVertical,
  ],
})
export class CurSwitchPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public powerPart: CurSwitchPartIL_Power;
  public motorPart: CurSwitchPartIL_Motor;
  public axisPart: CurSwitchPartIL_Axis;
  public framePart: CurSwitchPartIL_Frame;
  public framePartVL: CurSwitchPartIL_FrameVL;
  public linePart: CurSwitchPartIL_Line;
  public supVerticalPart: CurSwitchPartIL_SupVertical;
  public fixingClipSidePart: CurSwitchPartIL_FixingClipSide;
  public fixingClipCeilingPart: CurSwitchPartIL_FixingClipCeiling;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 개폐 방향값 <- 개폐 방향
   */
  public get switchWayValue(): number {
    if (this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH || this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
      return 2;
    } else if (
      this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE ||
      this.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ON
    ) {
      return 1;
    }
    return 0;
  }

  /**
   * 개폐 분할값 <- 개폐 분할
   */
  public get switchDivisionValue(): number {
    if (this.switchDivision === CONST.LB_SWITCHER_DIVISION_NONE) {
      return 1;
    } else if (this.switchDivision === CONST.LB_SWITCHER_DIVISION_2) {
      return 2;
    } else if (this.switchDivision === CONST.LB_SWITCHER_DIVISION_3) {
      return 3;
    } else if (this.switchDivision === CONST.LB_SWITCHER_DIVISION_4) {
      return 4;
    } else if (this.switchDivision === CONST.LB_SWITCHER_DIVISION_5) {
      return 5;
    }
    return 0;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _switchWay: string; // 개폐 방향
  @jsonMember(String)
  public _switchDivision: string; // 개폐 분할
  @jsonMember(String)
  public _supportType: string; // 지지 형태
  @jsonMember(String)
  public _fixingType: string; // 고정(피복) 형태

  /**
   * 개폐 방향
   */
  public get switchWay(): string {
    return this._switchWay;
  }

  public set switchWay(value: string) {
    this._switchWay = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageCurSwitcherAndMotorQuantity();
  }

  /**
   * 개폐 분할
   */
  public get switchDivision(): string {
    return this._switchDivision;
  }

  public set switchDivision(value: string) {
    this._switchDivision = value;

    /// //////// 선언 ///////////
    const curCoverPosition: CurCoverPositionIL = <CurCoverPositionIL>(
      (this.struct.curCoverWorkIL.levelAC[0] as CurCoverLevelIL).curCoverPosition
    );

    // 알고리즘
    this.framePart.algorithm_driveAxisLineNumber();
    if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_IL) {
      this.framePart.algorithm_supportAxisLineNumber();
    } else if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
      this.framePartVL.algorithm_driveAxisLineNumber();
    }
    this.motorPart.algorithm_availableCurtainArea();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageCurSwitcherAndMotorQuantity();
  }

  /**
   * 지지 형태
   */
  public get supportType(): string {
    return this._supportType;
  }

  public set supportType(value: string) {
    this._supportType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationBySupportType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 고정(피복) 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  //
  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationBySupportType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 커튼 피복
    (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[0]).curCoverPosition.algorithmBasic();
    // (<CurCoverLevelIL> this.struct.curCoverWorkIL.levelAC[this.level.index]).curCoverPosition.algorithmBasic();

    // 개폐기
    if (this.level.index === 0) {
      this.struct.switcherWorkIL.level1.columnPosition.algorithm_selectedByCurtain();
    }

    // 제어기
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_selectedBySwitcherByBasic();
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageCurSwitcherAndMotorQuantity();

    // // 치마 피복  :: 치마피복~방충망까지는 수평커튼 수정 전부터 주석 되어있었음
    // if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
    //   (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).columnPosition.algorithm_selected();
    //   (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).columnPosition.algorithm_enabled();
    // }

    // // 바람막이 피복
    // if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
    //   (<WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index]).columnPosition.algorithm_selected();
    //   (<WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index]).columnPosition.algorithm_enabled();
    // }

    // // 방충망
    // if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
    //   (<ScreennetLevelIL>this.struct.screennetWorkIL.levelAC[this.level.index]).columnPosition.algorithm_enabled();
    // }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_supportType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 커튼 피복
    (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[0]).curCoverPosition.algorithmBasic();
    (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[0]).curCoverPosition.algoritm_fixingType();
    (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[0]).curCoverPosition.normalPadPart.algorithmPart();
    // (<CurCoverLevelIL> this.struct.curCoverWorkIL.levelAC[this.level.index]).curCoverPosition.algorithmBasic();
    // (<CurCoverLevelIL> this.struct.curCoverWorkIL.levelAC[this.level.index]).curCoverPosition.algoritm_fixingType();
    // (<CurCoverLevelIL> this.struct.curCoverWorkIL.levelAC[this.level.index]).curCoverPosition.normalPadPart.algorithmPart();

    // 제어기
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_selectedBySwitcherByBasic();
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageCurSwitcherAndMotorQuantity();

    // 치마 피복
    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      (<SkirtLevelIL>this.struct.skirtWorkIL.level1).columnPosition.algorithm_selected();
      // (<SkirtLevelIL> this.struct.skirtWorkIL.levelAC[this.level.index]).columnPosition.algorithm_selected();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.powerPart = new CurSwitchPartIL_Power();
    this.motorPart = new CurSwitchPartIL_Motor();
    this.axisPart = new CurSwitchPartIL_Axis();
    this.framePart = new CurSwitchPartIL_Frame();
    this.framePartVL = new CurSwitchPartIL_FrameVL();
    this.linePart = new CurSwitchPartIL_Line();
    this.supVerticalPart = new CurSwitchPartIL_SupVertical();
    this.fixingClipSidePart = new CurSwitchPartIL_FixingClipSide();
    this.fixingClipCeilingPart = new CurSwitchPartIL_FixingClipCeiling();

    this.partAC = [
      this.powerPart,
      this.motorPart,
      this.axisPart,
      this.framePart,
      this.framePartVL,
      this.linePart,
      this.supVerticalPart,
      this.fixingClipSidePart,
      this.fixingClipCeilingPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.powerPart = <CurSwitchPartIL_Power>this.partAC[0];
    this.motorPart = <CurSwitchPartIL_Motor>this.partAC[1];
    this.axisPart = <CurSwitchPartIL_Axis>this.partAC[2];
    this.framePart = <CurSwitchPartIL_Frame>this.partAC[3];
    this.framePartVL = <CurSwitchPartIL_FrameVL>this.partAC[4];
    this.linePart = <CurSwitchPartIL_Line>this.partAC[5];
    this.supVerticalPart = <CurSwitchPartIL_SupVertical>this.partAC[6];
    this.fixingClipSidePart = <CurSwitchPartIL_FixingClipSide>this.partAC[7];
    this.fixingClipCeilingPart = <CurSwitchPartIL_FixingClipCeiling>this.partAC[8];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param switchWay: string 개폐 방향
   * @param switchDivision: string 개폐 분할
   * @param supportType: string 지지 형태
   * @param fixingType: string 고정(피복) 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    switchWay: string = "",
    switchDivision: string = "",
    supportType: string = "",
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._switchWay = switchWay;
    this._switchDivision = switchDivision;
    this._supportType = supportType;
    this._fixingType = fixingType;

    if (this.level.index === 0) {
      // 커튼 동력 개폐기
      this.powerPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_POWER,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_POWER,
        "",
      );

      // 커튼 감속 모터
      this.motorPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_MOTOR,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_MOTOR,
        "",
      );

      // 커튼 개폐 축
      this.axisPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_AXIS,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_AXIS,
        "",
        CONST.LB_CURTAIN_SWITCH_AXIS_HOLDER_BEARING,
        CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP,
      );

      // 커튼 수평 축
      this.framePart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_FRAME,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_FRAME,
        "",
        1,
        2,
      );

      // 커튼 수평 축(벤로)
      this.framePartVL.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_FRAME_VL,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_FRAME,
        "",
        1,
        2,
      );

      // 커튼 줄
      if (
        label === CONST.LB_POSITION_SIDE_COLUMN ||
        label === CONST.LB_POSITION_FRONT ||
        label === CONST.LB_POSITION_BACK
      ) {
        this.linePart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_SWITCH_LINE,
          false,
          false,
          false,
          CONST.LB_CURTAIN_SWITCH_LINE,
          "",
          false,
          CONST.LB_CURTAIN_SWITCH_LINE_TYPE_RIPCORD,
          1,
          false,
          CONST.LB_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE,
          3,
        );
      } else if (label === CONST.LB_POSITION_CEILING) {
        this.linePart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_SWITCH_LINE,
          false,
          false,
          false,
          CONST.LB_CURTAIN_SWITCH_LINE,
          "",
          true,
          CONST.LB_CURTAIN_SWITCH_LINE_TYPE_RIPCORD,
          2,
          true,
          CONST.LB_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE,
          3,
        );
      }

      // 커튼 개폐기 지지 - 수직형 지지
      this.supVerticalPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_SUPPORT_VERTICAL,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_SUPPORT_VERTICAL,
        "",
        3.5,
      );

      // 커튼 개폐기 피복 고정 - 클립 고정 (측면)
      this.fixingClipSidePart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_FIXING_CLIP_SIDE,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_FIXING_CLIP,
        "",
        0.5,
      );

      // 커튼 개폐기 피복 고정 - 클립 고정 (천장)
      this.fixingClipCeilingPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_FIXING_CLIP_CEILING,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_FIXING_CLIP,
        "",
        0.5,
        CONST.LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK_AND_HOOK,
      );
    }
    // else {
    //   // 커튼 동력 개폐기
    //   this.powerPart.setDefaultData(
    //     CONST.INDEX_IL_CURTAIN_SWITCH_POWER,
    //     false, false, false,
    //     CONST.LB_CURTAIN_SWITCH_POWER, "",
    //   );
    //
    //   // 커튼 감속 모터
    //   this.motorPart.setDefaultData(
    //     CONST.INDEX_IL_CURTAIN_SWITCH_MOTOR,
    //     false, false, false,
    //     CONST.LB_CURTAIN_SWITCH_MOTOR, "",
    //   );
    //
    //   // 커튼 개폐 축
    //   this.axisPart.setDefaultData(
    //     CONST.INDEX_IL_CURTAIN_SWITCH_AXIS,
    //     false, false, false,
    //     CONST.LB_CURTAIN_SWITCH_AXIS, "",
    //     CONST.LB_CURTAIN_SWITCH_AXIS_HOLDER_BEARING,
    //     CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP,
    //   );
    //
    //   // 커튼 수평 축
    //   this.framePart.setDefaultData(
    //     CONST.INDEX_IL_CURTAIN_SWITCH_FRAME,
    //     false, false, false,
    //     CONST.LB_CURTAIN_SWITCH_FRAME, "",
    //     1, 2,
    //   );
    //
    //   // 커튼 선
    //   if (label === CONST.LB_POSITION_SIDE_COLUMN
    //     || label === CONST.LB_POSITION_FRONT
    //     || label === CONST.LB_POSITION_BACK) {
    //     this.linePart.setDefaultData(
    //       CONST.INDEX_IL_CURTAIN_SWITCH_LINE,
    //       false, false, false,
    //       CONST.LB_CURTAIN_SWITCH_LINE, "",
    //       false,
    //       CONST.LB_CURTAIN_SWITCH_LINE_TYPE_RIPCORD, 2, false,
    //       CONST.LB_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE, 3,
    //     );
    //   } else if (label === CONST.LB_POSITION_CEILING) {
    //     this.linePart.setDefaultData(
    //       CONST.INDEX_IL_CURTAIN_SWITCH_LINE,
    //       false, false, false,
    //       CONST.LB_CURTAIN_SWITCH_LINE, "",
    //       true,
    //       CONST.LB_CURTAIN_SWITCH_LINE_TYPE_RIPCORD, 2, true,
    //       CONST.LB_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE, 3,
    //     );
    //   }
    //
    //   // 커튼 개폐기 지지 - 수직형 지지
    //   this.supVerticalPart.setDefaultData(
    //     CONST.INDEX_IL_CURTAIN_SWITCH_SUPPORT_VERTICAL,
    //     false, false, false,
    //     CONST.LB_CURTAIN_SWITCH_SUPPORT_VERTICAL, "",
    //     3.5,
    //   );
    //
    //   // 커튼 개폐기 피복 고정 - 클립 고정 (측면)
    //   this.fixingClipSidePart.setDefaultData(
    //     CONST.INDEX_IL_CURTAIN_SWITCH_FIXING_CLIP_SIDE,
    //     false, false, false,
    //     CONST.LB_CURTAIN_SWITCH_FIXING_CLIP, "",
    //     0.5,
    //   );
    //
    //   // 커튼 개폐기 피복 고정 - 클립 고정 (천장)
    //   this.fixingClipCeilingPart.setDefaultData(
    //     CONST.INDEX_IL_CURTAIN_SWITCH_FIXING_CLIP_CEILING,
    //     false, false, false,
    //     CONST.LB_CURTAIN_SWITCH_FIXING_CLIP, "",
    //     0.5,
    //     CONST.LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK,
    //   );
    // }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_switchDivision();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 선택, 가용성 <- 선택(커튼 피복), 범위 형태(커튼 피복)
   */
  public algorithm_selectedAndEnabled(): void {
    const curCoverPosition: CurCoverPositionIL = (<CurCoverLevelIL>this.struct.curCoverWorkIL.level1).curCoverPosition;

    if (this.level.index === 0) {
      if (this.label === CONST.LB_POSITION_SIDE_COLUMN || this.label === CONST.LB_POSITION_CEILING) {
        this.selected = curCoverPosition.selected;
        this.enabled = curCoverPosition.selected;
      } else if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
        if (
          curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_SEP_ENDPIECE ||
          curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE ||
          curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_ENDPIECE
        ) {
          this.enabled = curCoverPosition.selected;
        } else {
          this.selected = false;
          this.enabled = false;
        }
      }
    }
    // else {
    //   if (this.label === CONST.LB_POSITION_CEILING) {
    //     this.selected = curCoverPosition.selected;
    //     this.enabled = curCoverPosition.selected;
    //   }
    // }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태, 구조
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    /// //////// 선언 ///////////
    const curCoverPosition: CurCoverPositionIL = <CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition;

    if (this.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
      this.motorPart.selected = false;
      this.motorPart.visible = false;
      this.powerPart.selected = true;
      this.powerPart.visible = true;
      this.framePart.selected = false;
      this.framePart.visible = false;
      this.axisPart.selected = true;
      this.axisPart.visible = true;
      this.linePart.selected = true;
      this.linePart.visible = true;
    } else if (this.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP) {
      this.motorPart.selected = false;
      this.motorPart.visible = false;
      this.powerPart.selected = true;
      this.powerPart.visible = true;
      this.framePart.selected = false;
      this.framePart.visible = false;
      this.axisPart.selected = true;
      this.axisPart.visible = true;
      this.linePart.selected = false;
      this.linePart.visible = false;
    } else if (this.type === CONST.LB_CURTAIN_SWITCH_TYPE_MOTOR_TOW) {
      this.motorPart.selected = true;
      this.motorPart.visible = true;
      this.powerPart.selected = false;
      this.powerPart.visible = false;
      this.framePart.selected = true;
      this.framePart.visible = true;
      this.axisPart.selected = false;
      this.axisPart.visible = false;
      this.linePart.selected = true;
      this.linePart.visible = true;
      if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_IL) {
        this.framePart.selected = true;
        this.framePart.visible = true;
        this.framePartVL.selected = false;
        this.framePartVL.visible = false;
      } else if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
        this.framePart.selected = false;
        this.framePart.visible = false;
        this.framePartVL.selected = true;
        this.framePartVL.visible = true;
      }
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 지지 형태
   */
  public algorithm_partActivationBySupportType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL) {
      this.supVerticalPart.selected = true;
      this.supVerticalPart.visible = true;
    } else {
      this.supVerticalPart.selected = false;
      this.supVerticalPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (
      this.fixingType === CONST.LB_FIXING_TYPE_CLIP ||
      this.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TRUSS_CLIP
    ) {
      if (
        this.label === CONST.LB_POSITION_SIDE_COLUMN ||
        this.label === CONST.LB_POSITION_FRONT ||
        this.label === CONST.LB_POSITION_BACK
      ) {
        this.fixingClipSidePart.selected = true;
        this.fixingClipSidePart.visible = true;
        this.fixingClipCeilingPart.selected = false;
        this.fixingClipCeilingPart.visible = false;
      } else if (this.label === CONST.LB_POSITION_CEILING) {
        this.fixingClipSidePart.selected = false;
        this.fixingClipSidePart.visible = false;
        this.fixingClipCeilingPart.selected = true;
        this.fixingClipCeilingPart.visible = true;
      }
    } else {
      this.fixingClipSidePart.selected = false;
      this.fixingClipSidePart.visible = false;
      this.fixingClipCeilingPart.selected = false;
      this.fixingClipCeilingPart.visible = false;
    }
  }

  /**
   * 알고리즘: 개폐 분할 <- 면적(기본정보), 형태(커튼 피복), 커튼 감속 모터 샘플X
   */
  public algorithm_switchDivision(): void {
    if (this.label === CONST.LB_POSITION_CEILING) {
      const curCoverPosition: CurCoverPositionIL = (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[0])
        .curCoverPosition;
      // const curCoverPosition: CurCoverPositionIL = (<CurCoverLevelIL> this.struct.curCoverWorkIL.levelAC[this.level.index]).curCoverPosition;
      const horsePower: number = parseFloat(this.motorPart.reducedMotorSample.specHorsePower);
      let coverArea: number;
      if (curCoverPosition.type === CONST.LB_COVER_TYPE_SHADENET) {
        // 얇은 커튼
        coverArea = horsePower * CONST.NUM_CURTAIN_HP_PER_AREA_THIN;
      } else {
        // 두꺼운 커튼
        coverArea = horsePower * CONST.NUM_CURTAIN_HP_PER_AREA_THICK;
      }

      const ratio: number = this.basic.area / coverArea;
      if (ratio <= 1) {
        this.switchDivision = CONST.LB_SWITCHER_DIVISION_NONE;
      } else if (ratio <= 2) {
        this.switchDivision = CONST.LB_SWITCHER_DIVISION_2;
      } else if (ratio <= 3) {
        this.switchDivision = CONST.LB_SWITCHER_DIVISION_3;
      } else if (ratio <= 4) {
        this.switchDivision = CONST.LB_SWITCHER_DIVISION_4;
      } else {
        this.switchDivision = CONST.LB_SWITCHER_DIVISION_5;
      }
    }
  }

  /**
   * 알고리즘: 지지 형태 <- 형태
   */
  public algorithm_supportType(): void {
    if (
      this.label === CONST.LB_POSITION_SIDE_COLUMN ||
      this.label === CONST.LB_POSITION_FRONT ||
      this.label === CONST.LB_POSITION_BACK
    ) {
      if (this.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE;
      } else if (this.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP) {
        this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL;
      }
    } else if (this.label === CONST.LB_POSITION_CEILING) {
      this.supportType = CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE;
    }
  }

  /**
   * 알고리즘: 커튼 개폐 피복 고정 형태 <- 축 방향, 위치
   */
  public algoritm_switchCoverFixingType(): void {
    const curCoverPosition: CurCoverPositionIL = <CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition;
    if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL && this.label === CONST.LB_POSITION_CEILING) {
      this.fixingType = CONST.LB_CURTAIN_SWITCH_FIXING_TRUSS_CLIP;
    } else {
      this.fixingType = CONST.LB_CURTAIN_SWITCH_FIXING_CLIP;
    }
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion) && this.partAC.length !== 9) {
      const framePartVL = new CurSwitchPartIL_FrameVL();
      framePartVL.setAssociation(design, struct, work, level, this);
      // 커튼 수평 축(벤로)
      framePartVL.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SWITCH_FRAME_VL,
        false,
        false,
        false,
        CONST.LB_CURTAIN_SWITCH_FRAME,
        "",
        1,
        2,
      );
      this.partAC.splice(4, 0, framePartVL);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
