import { jsonObject } from "typedjson";

import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { CoverPart_Vinyl } from "vhows-design/src/object/design/cover/cover/CoverPart_Vinyl";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-02-10
 */
@jsonObject
export class CoverSample_VinylNormalSkirt extends ItemVinyl {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const vinylPart: CoverPart_Vinyl = <CoverPart_Vinyl>this.part;

    /// //////// 외부 ///////////

    // 치마
    for (const skirtPosition of this.struct.skirtWork.levelAC[this.level.index].positionAC as SkirtPosition[]) {
      skirtPosition.normalPart.algorithmPart();
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <-
   */
  // @override
  public algorithmSpec(): void {}

  /**
   * 규격 알고리즘 : 두께 <- 두께(바람막이 비닐 샘플)
   */
  public algorithmSpec_specThickness(): void {
    if (this.brands === (<CoverPart_Vinyl>this.part).windbreakVinylSample.brands) {
      this.specThickness = (<CoverPart_Vinyl>this.part).windbreakVinylSample.specThickness;
    }
  }

  /**
   * 규격 알고리즘 : 상표 <- 상표(바람막이 비닐 샘플)
   */
  public algorithmSpec_brands(): void {
    if (this.specThickness === (<CoverPart_Vinyl>this.part).windbreakVinylSample.specThickness) {
      this.brands = (<CoverPart_Vinyl>this.part).windbreakVinylSample.brands;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
