import { jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";
import { BrandCompanyModel } from "vhows-design/src/object/item/brand/BrandCompanyModel";
import { BrandModel } from "vhows-design/src/object/item/brand/BrandModel";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { ProductCompanyModel } from "vhows-design/src/object/item/product/ProductCompanyModel";
import { SpecCompanyModel } from "vhows-design/src/object/item/spec/SpecCompanyModel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-26
 */
@jsonObject({
  knownTypes: [],
})
export class UnitPriceModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 품목 모델 */
  public itemModel: ItemModel = null;
  /** 조직별 분류 모델 */
  public categoryCompanyModel: CategoryCompanyModel = null;
  /** 조직별 상품 모델 */
  public productCompanyModel: ProductCompanyModel = null;
  /** 조직별 규격 모델 */
  public specCompanyModel: SpecCompanyModel = null;
  /** 조직별 상표 모델 */
  public brandCompanyModel: BrandCompanyModel = null;

  /** 규격모델 명 */
  public specModelLabel: string = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 상표리스트 레이블 : 설계의 규격 수정에서 사용
   */
  public get label(): string {
    return this.brandCompanyModel.label;
  }

  public set label(value: string) {
    this.brandCompanyModel.label = value;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 규격 인덱스 */
  @jsonMember(Number)
  public specIndex: number = null;
  /** 상표 인덱스 */
  @jsonMember(Number)
  public brandIndex: number = null;
  /** 커스텀 상품코드 : 엑셀 추출용 */
  @jsonMember(String)
  public customId: string = null;
  /** 기본 매입가 (공급가액) */
  @jsonMember(Number)
  public defaultPurchaseSupply: number = 0;
  /** 기본 마진율 */
  @jsonMember(Number)
  public defaultMarginRate: number = 0;
  /** 기본 단가 (공급가액) - 견적에서 사용되는 기준 */
  @jsonMember(Number)
  public defaultUnitSupply: number = 0;
  /** 기본 단가 (공급대가) */
  @jsonMember(Number)
  public defaultUnitPrice: number = 0;
  /** 세트 단위수량 */
  @jsonMember(Number)
  public setUnitQuantity: number = 1;

  /** 조직별 규격모델 아이디 */
  @jsonMember(Number)
  public specCompanyModelId: number = null;

  /** 규격모델 아이디 */
  @jsonMember(Number)
  public specModelId: number = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(
    itemModel: ItemModel,
    categoryCompanyModel: CategoryCompanyModel,
    productCompanyModel: ProductCompanyModel,
  ): void {
    this.itemModel = itemModel;
    this.categoryCompanyModel = categoryCompanyModel;
    this.productCompanyModel = productCompanyModel;
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {}

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 플렉스 컴포넌트에서 사용하기 위해서 상표 리스트를 가져옴
    this.label = (<BrandModel>this.brandCompanyModel.brandModel).label;
  }

  //----------------------------------
  // 품목
  //----------------------------------

  public getDefaultSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT) {
    if (type === CONST.ID_ITEM_SELLING_TYPE_UNIT) {
      return this.defaultUnitSupply;
    }
    return this.defaultPurchaseSupply;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
