import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWaterFilter } from "vhows-design/src/object/design/item/list/ItemWaterFilter";
import { ItemWaterPump } from "vhows-design/src/object/design/item/list/ItemWaterPump";
import { ItemWaterTank } from "vhows-design/src/object/design/item/list/ItemWaterTank";
import { ItemWaterTankFitting } from "vhows-design/src/object/design/item/list/ItemWaterTankFitting";
import { ItemWateringConnect } from "vhows-design/src/object/design/item/list/ItemWateringConnect";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringValve } from "vhows-design/src/object/design/item/list/ItemWateringValve";
import { Part } from "vhows-design/src/object/design/base/Part";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { MainpipeSample_Main1PePipe } from "vhows-design/src/object/design/watering/mainpipe/MainpipeSample_Main1PePipe";
import { MainpipeSample_WaterTank } from "vhows-design/src/object/design/watering/mainpipe/MainpipeSample_WaterTank";
import { MainpipeSample_WaterPump } from "vhows-design/src/object/design/watering/mainpipe/MainpipeSample_WaterPump";
import { MainpipeSample_WaterFilter } from "vhows-design/src/object/design/watering/mainpipe/MainpipeSample_WaterFilter";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [
    MainpipeSample_Main1PePipe,
    MainpipeSample_WaterTank,
    MainpipeSample_WaterPump,
    MainpipeSample_WaterFilter,
  ],
})
export class MainpipePart_PeMain1 extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public main1PePipeSample: MainpipeSample_Main1PePipe; // 주관1 샘플
  public waterTankSample: MainpipeSample_WaterTank; // 물탱크 샘플
  public waterPumpSample: MainpipeSample_WaterPump; // 펌프 샘플
  public waterFilterSample: MainpipeSample_WaterFilter; // 여과기 샘플

  // 아이템
  public main1_pePipe: ItemWateringPipe;
  public waterTank_waterTank: ItemWaterTank;
  public waterTankIn_waterTankFitting: ItemWaterTankFitting;
  public waterTankExit_waterTankFitting: ItemWaterTankFitting;
  public waterTankExit_femaleMaleValve: ItemWateringValve;
  public waterTankOut_waterTankFitting: ItemWaterTankFitting;
  public waterTankOut_nipple: ItemWateringConnect;
  public waterTankOut_femaleBallValve: ItemWateringValve;
  public waterTankOut_elbow: ItemWateringConnect;
  public waterTankOut_valveSocket: ItemWateringConnect;
  public waterPump_waterPump: ItemWaterPump;
  public waterPumpInOut_bushing: ItemWateringConnect;
  public waterPumpInOut_maleElbowSocket: ItemWateringConnect;
  public waterPump_valveSocket: ItemWateringConnect;
  public waterFilter_waterFilter: ItemWaterFilter;
  public waterFilterInOut_socketFemale: ItemWateringConnect;
  public waterFilterIn_bushing: ItemWateringConnect;
  public waterFilterOut_bushing: ItemWateringConnect;
  public waterFilterOut_valveSocket: ItemWateringConnect;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Boolean)
  public _waterTankSelected: boolean; // 물탱크 선택
  @jsonMember(String)
  public _waterTankPosition: string; // 물탱크 위치
  @jsonMember(Number)
  public _waterTankQuantity: number; // 물탱크 수량
  @jsonMember(Boolean)
  public _waterPumpSelected: boolean; // 펌프 선택
  @jsonMember(Boolean)
  public _waterFilterSelected: boolean; // 여과기 선택
  @jsonMember(Number)
  public _main1Length: number; // 주관1 길이

  /**
   * 물탱크 선택
   */
  public get waterTankSelected(): boolean {
    return this._waterTankSelected;
  }

  //
  public set waterTankSelected(value: boolean) {
    this._waterTankSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 물탱크 위치
   */
  public get waterTankPosition(): string {
    return this._waterTankPosition;
  }

  //
  public set waterTankPosition(value: string) {
    this._waterTankPosition = value;

    // 알고리즘
    this.algorithmPart();
    (<MainpipePosition>this.position).peMain2Part.algorithmPart();
  }

  /**
   * 물탱크 위치값
   */
  public get waterTankPositionValue(): number {
    if (this._waterTankPosition === CONST.LB_WATERING_MAINPIPE_WATER_TANK_POSITION_SIDE) {
      return 1;
    } else if (this._waterTankPosition === CONST.LB_WATERING_MAINPIPE_WATER_TANK_POSITION_CENTER) {
      return 2;
    }
    return 0;
  }

  //

  /**
   * 물탱크 수량
   */
  public get waterTankQuantity(): number {
    return this._waterTankQuantity;
  }

  //
  public set waterTankQuantity(value: number) {
    this._waterTankQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_main1Length();
    this.algorithmPart();
  }

  /**
   * 펌프 선택
   */
  public get waterPumpSelected(): boolean {
    return this._waterPumpSelected;
  }

  //
  public set waterPumpSelected(value: boolean) {
    this._waterPumpSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 여과기 선택
   */
  public get waterFilterSelected(): boolean {
    return this._waterFilterSelected;
  }

  //
  public set waterFilterSelected(value: boolean) {
    this._waterFilterSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 주관1 길이
   */
  public get main1Length(): number {
    return this._main1Length;
  }

  //
  public set main1Length(value: number) {
    this._main1Length = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.main1PePipeSample = new MainpipeSample_Main1PePipe();
    this.waterTankSample = new MainpipeSample_WaterTank();
    this.waterPumpSample = new MainpipeSample_WaterPump();
    this.waterFilterSample = new MainpipeSample_WaterFilter();

    this.sampleAC = [this.main1PePipeSample, this.waterTankSample, this.waterPumpSample, this.waterFilterSample];

    // 아이템
    this.main1_pePipe = new ItemWateringPipe();
    this.waterTank_waterTank = new ItemWaterTank();
    this.waterTankIn_waterTankFitting = new ItemWaterTankFitting();
    this.waterTankExit_waterTankFitting = new ItemWaterTankFitting();
    this.waterTankExit_femaleMaleValve = new ItemWateringValve();
    this.waterTankOut_waterTankFitting = new ItemWaterTankFitting();
    this.waterTankOut_nipple = new ItemWateringConnect();
    this.waterTankOut_femaleBallValve = new ItemWateringValve();
    this.waterTankOut_elbow = new ItemWateringConnect();
    this.waterTankOut_valveSocket = new ItemWateringConnect();
    this.waterPump_waterPump = new ItemWaterPump();
    this.waterPumpInOut_bushing = new ItemWateringConnect();
    this.waterPumpInOut_maleElbowSocket = new ItemWateringConnect();
    this.waterPump_valveSocket = new ItemWateringConnect();
    this.waterFilter_waterFilter = new ItemWaterFilter();
    this.waterFilterInOut_socketFemale = new ItemWateringConnect();
    this.waterFilterIn_bushing = new ItemWateringConnect();
    this.waterFilterOut_bushing = new ItemWateringConnect();
    this.waterFilterOut_valveSocket = new ItemWateringConnect();

    this.itemAC = [
      this.main1_pePipe,
      this.waterTank_waterTank,
      this.waterTankIn_waterTankFitting,
      this.waterTankExit_waterTankFitting,
      this.waterTankExit_femaleMaleValve,
      this.waterTankOut_waterTankFitting,
      this.waterTankOut_nipple,
      this.waterTankOut_femaleBallValve,
      this.waterTankOut_elbow,
      this.waterTankOut_valveSocket,
      this.waterPump_waterPump,
      this.waterPumpInOut_bushing,
      this.waterPumpInOut_maleElbowSocket,
      this.waterPump_valveSocket,
      this.waterFilter_waterFilter,
      this.waterFilterInOut_socketFemale,
      this.waterFilterIn_bushing,
      this.waterFilterOut_bushing,
      this.waterFilterOut_valveSocket,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.main1PePipeSample = <MainpipeSample_Main1PePipe>this.sampleAC[0];
    this.waterTankSample = <MainpipeSample_WaterTank>this.sampleAC[1];
    this.waterPumpSample = <MainpipeSample_WaterPump>this.sampleAC[2];
    this.waterFilterSample = <MainpipeSample_WaterFilter>this.sampleAC[3];
    // 아이템
    this.main1_pePipe = <ItemWateringPipe>this.itemAC[0];
    this.waterTank_waterTank = <ItemWaterTank>this.itemAC[1];
    this.waterTankIn_waterTankFitting = <ItemWaterTankFitting>this.itemAC[2];
    this.waterTankExit_waterTankFitting = <ItemWaterTankFitting>this.itemAC[3];
    this.waterTankExit_femaleMaleValve = <ItemWateringValve>this.itemAC[4];
    this.waterTankOut_waterTankFitting = <ItemWaterTankFitting>this.itemAC[5];
    this.waterTankOut_nipple = <ItemWateringConnect>this.itemAC[6];
    this.waterTankOut_femaleBallValve = <ItemWateringValve>this.itemAC[7];
    this.waterTankOut_elbow = <ItemWateringConnect>this.itemAC[8];
    this.waterTankOut_valveSocket = <ItemWateringConnect>this.itemAC[9];
    this.waterPump_waterPump = <ItemWaterPump>this.itemAC[10];
    this.waterPumpInOut_bushing = <ItemWateringConnect>this.itemAC[11];
    this.waterPumpInOut_maleElbowSocket = <ItemWateringConnect>this.itemAC[12];
    this.waterPump_valveSocket = <ItemWateringConnect>this.itemAC[13];
    this.waterFilter_waterFilter = <ItemWaterFilter>this.itemAC[14];
    this.waterFilterInOut_socketFemale = <ItemWateringConnect>this.itemAC[15];
    this.waterFilterIn_bushing = <ItemWateringConnect>this.itemAC[16];
    this.waterFilterOut_bushing = <ItemWateringConnect>this.itemAC[17];
    this.waterFilterOut_valveSocket = <ItemWateringConnect>this.itemAC[18];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param waterTankSelected: boolean 물탱크 선택
   * @param waterTankPosition: string 물탱크 위치
   * @param waterTankQuantity: number 물탱크 수량
   * @param waterPumpSelected: boolean 펌프 선택
   * @param waterFilterSelected: boolean 여과기 선택
   * @param main1Length: number 주관1 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    waterTankSelected: boolean = false,
    waterTankPosition: string = "",
    waterTankQuantity: number = 0,
    waterPumpSelected: boolean = false,
    waterFilterSelected: boolean = false,
    main1Length: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._waterTankSelected = waterTankSelected;
    this._waterTankPosition = waterTankPosition;
    this._waterTankQuantity = waterTankQuantity;
    this._waterPumpSelected = waterPumpSelected;
    this._waterFilterSelected = waterFilterSelected;
    this._main1Length = main1Length;

    // 샘플
    if (this.level.index >= 0) {
      this.main1PePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "주관(관정~여과기)",
        "수도관(단층관), 40mm, 50m",
      );
      this.waterTankSample.setDefaultData(
        1,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "물탱크",
        "일반, 원형, 5ton, 50mm, 40mm, 20mm",
      );
      this.waterPumpSample.setDefaultData(
        2,
        CONST.ITEM_ID_WATER_PUMP,
        CONST.ITEM_NAME_WATER_PUMP,
        "펌프",
        "일반, 1HP",
        "윌로펌프, PW-952M, 1HP압상, 단상, 40mm",
      );
      this.waterFilterSample.setDefaultData(
        3,
        CONST.ITEM_ID_WATER_FILTER,
        CONST.ITEM_NAME_WATER_FILTER,
        "여과기",
        "일반",
        "한슬테크, SAFE, 메쉬(망), 40mm",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.main1_pePipe.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "주관(관정~여과기)",
        "수도관(단층관), 40mm, 50m",
      );
      this.waterTank_waterTank.setDefaultData(
        1,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "물탱크",
        "일반, 원형, 5ton, 50mm, 40mm, 20mm",
      );
      this.waterTankIn_waterTankFitting.setDefaultData(
        2,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "물탱크 입수구",
        "일반, 50mm, -",
      );
      this.waterTankExit_waterTankFitting.setDefaultData(
        3,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "물탱크 퇴수구",
        "일반, 20mm, -",
      );
      this.waterTankExit_femaleMaleValve.setDefaultData(
        4,
        CONST.ITEM_ID_FEMALE_MALE_VALVE,
        CONST.ITEM_NAME_FEMALE_MALE_VALVE,
        "물탱크 퇴수구",
        "PE, 일반, 20mm, 20mm",
      );
      this.waterTankOut_waterTankFitting.setDefaultData(
        5,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "물탱크 출수구",
        "일반, 40mm, -",
      );
      this.waterTankOut_nipple.setDefaultData(
        6,
        CONST.ITEM_ID_NIPPLE,
        CONST.ITEM_NAME_NIPPLE,
        "물탱크 출수구",
        "PE, 단니플, 40mm, 40mm",
      );
      this.waterTankOut_femaleBallValve.setDefaultData(
        7,
        CONST.ITEM_ID_FEMALE_BALL_VALVE,
        CONST.ITEM_NAME_FEMALE_BALL_VALVE,
        "물탱크 출수구",
        "PE, 일반, 40mm, 40mm",
      );
      this.waterTankOut_elbow.setDefaultData(
        8,
        CONST.ITEM_ID_ELBOW,
        CONST.ITEM_NAME_ELBOW,
        "물탱크~펌프",
        "PE, 소켓, 40mm, 40mm",
      );
      this.waterTankOut_valveSocket.setDefaultData(
        9,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "물탱크~펌프",
        "PE, 일반, 40mm, -",
      );
      this.waterPump_waterPump.setDefaultData(
        10,
        CONST.ITEM_ID_WATER_PUMP,
        CONST.ITEM_NAME_WATER_PUMP,
        "펌프",
        "일반, 1HP",
        "윌로펌프, PW-952M, 1HP압상, 단상, 40mm",
      );
      this.waterPumpInOut_bushing.setDefaultData(
        11,
        CONST.ITEM_ID_BUSHING,
        CONST.ITEM_NAME_BUSHING,
        "펌프 입수구/출수구",
        "PE, 일반, 50mm, 40mm",
      );
      this.waterPumpInOut_maleElbowSocket.setDefaultData(
        12,
        CONST.ITEM_ID_MALE_ELBOW_SOCKET,
        CONST.ITEM_NAME_MALE_ELBOW_SOCKET,
        "펌프 입수구/출수구",
        "PE, 일반, 40mm, 40mm",
      );
      this.waterPump_valveSocket.setDefaultData(
        13,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "펌프~여과기",
        "PE, 일반, 40mm, -",
      );
      this.waterFilter_waterFilter.setDefaultData(
        14,
        CONST.ITEM_ID_WATER_FILTER,
        CONST.ITEM_NAME_WATER_FILTER,
        "여과기",
        "일반",
        "한슬테크, SAFE, 메쉬(망), 40mm",
      );
      this.waterFilterInOut_socketFemale.setDefaultData(
        15,
        CONST.ITEM_ID_SOCKET_FEMALE,
        CONST.ITEM_NAME_SOCKET_FEMALE,
        "여과기 입수구/출수구",
        "PE, 일반, 40mm, 40mm",
      );
      this.waterFilterIn_bushing.setDefaultData(
        16,
        CONST.ITEM_ID_BUSHING,
        CONST.ITEM_NAME_BUSHING,
        "여과기 입수구",
        "PE, 일반, 50mm, 40mm",
      );
      this.waterFilterOut_bushing.setDefaultData(
        17,
        CONST.ITEM_ID_BUSHING,
        CONST.ITEM_NAME_BUSHING,
        "여과기 출수구",
        "PE, 일반, 50mm, 40mm",
      );
      this.waterFilterOut_valveSocket.setDefaultData(
        18,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "여과기~끝",
        "PE, 일반, 40mm, -",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const mainpipePosition: MainpipePosition = <MainpipePosition>this.position;

    /// //////// 선택, 가시성 ///////////

    this.main1_pePipe.selected = true;
    this.main1_pePipe.visible = true;
    if (this.waterTankSelected === true) {
      this.waterTank_waterTank.selected = true;
      this.waterTank_waterTank.visible = true;
      this.waterTankIn_waterTankFitting.selected = true;
      this.waterTankIn_waterTankFitting.visible = true;
      this.waterTankExit_waterTankFitting.selected = true;
      this.waterTankExit_waterTankFitting.visible = true;
      this.waterTankExit_femaleMaleValve.selected = true;
      this.waterTankExit_femaleMaleValve.visible = true;
      this.waterTankOut_waterTankFitting.selected = true;
      this.waterTankOut_waterTankFitting.visible = true;
      this.waterTankOut_nipple.selected = true;
      this.waterTankOut_nipple.visible = true;
      this.waterTankOut_femaleBallValve.selected = true;
      this.waterTankOut_femaleBallValve.visible = true;
      this.waterTankOut_elbow.selected = true;
      this.waterTankOut_elbow.visible = true;
      this.waterTankOut_valveSocket.selected = true;
      this.waterTankOut_valveSocket.visible = true;
    } else {
      this.waterTank_waterTank.selected = false;
      this.waterTank_waterTank.visible = false;
      this.waterTankIn_waterTankFitting.selected = false;
      this.waterTankIn_waterTankFitting.visible = false;
      this.waterTankExit_waterTankFitting.selected = false;
      this.waterTankExit_waterTankFitting.visible = false;
      this.waterTankExit_femaleMaleValve.selected = false;
      this.waterTankExit_femaleMaleValve.visible = false;
      this.waterTankOut_waterTankFitting.selected = false;
      this.waterTankOut_waterTankFitting.visible = false;
      this.waterTankOut_nipple.selected = false;
      this.waterTankOut_nipple.visible = false;
      this.waterTankOut_femaleBallValve.selected = false;
      this.waterTankOut_femaleBallValve.visible = false;
      this.waterTankOut_elbow.selected = false;
      this.waterTankOut_elbow.visible = false;
      this.waterTankOut_valveSocket.selected = false;
      this.waterTankOut_valveSocket.visible = false;
    }
    if (this.waterPumpSelected === true) {
      this.waterPump_waterPump.selected = true;
      this.waterPump_waterPump.visible = true;
      if (this.waterPumpSample.brandInOutDiameter !== this.main1PePipeSample.specDiameter) {
        this.waterPumpInOut_bushing.selected = true;
        this.waterPumpInOut_bushing.visible = true;
      } else {
        this.waterPumpInOut_bushing.selected = false;
        this.waterPumpInOut_bushing.visible = false;
      }
      this.waterPumpInOut_maleElbowSocket.selected = true;
      this.waterPumpInOut_maleElbowSocket.visible = true;
      this.waterPump_valveSocket.selected = true;
      this.waterPump_valveSocket.visible = true;
    } else {
      this.waterPump_waterPump.selected = false;
      this.waterPump_waterPump.visible = false;
      this.waterPumpInOut_bushing.selected = false;
      this.waterPumpInOut_bushing.visible = false;
      this.waterPumpInOut_maleElbowSocket.selected = false;
      this.waterPumpInOut_maleElbowSocket.visible = false;
      this.waterPump_valveSocket.selected = false;
      this.waterPump_valveSocket.visible = false;
    }
    if (this.waterFilterSelected === true) {
      this.waterFilter_waterFilter.selected = true;
      this.waterFilter_waterFilter.visible = true;
      this.waterFilterInOut_socketFemale.selected = true;
      this.waterFilterInOut_socketFemale.visible = true;
      if (this.waterFilterSample.brandInOutDiameter !== this.main1PePipeSample.specDiameter) {
        this.waterFilterIn_bushing.selected = true;
        this.waterFilterIn_bushing.visible = true;
      } else {
        this.waterFilterIn_bushing.selected = false;
        this.waterFilterIn_bushing.visible = false;
      }
      if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
        if (
          this.waterFilterSample.brandInOutDiameter !==
          mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter
        ) {
          this.waterFilterOut_bushing.selected = true;
          this.waterFilterOut_bushing.visible = true;
        } else {
          this.waterFilterOut_bushing.selected = false;
          this.waterFilterOut_bushing.visible = false;
        }
      } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
        if (
          this.waterFilterSample.brandInOutDiameter !== mainpipePosition.peMain2Part.main2EachPePipeSample.specDiameter
        ) {
          this.waterFilterOut_bushing.selected = true;
          this.waterFilterOut_bushing.visible = true;
        } else {
          this.waterFilterOut_bushing.selected = false;
          this.waterFilterOut_bushing.visible = false;
        }
      }
      this.waterFilterOut_valveSocket.selected = true;
      this.waterFilterOut_valveSocket.visible = true;
    } else {
      this.waterFilter_waterFilter.selected = false;
      this.waterFilter_waterFilter.visible = false;
      this.waterFilterInOut_socketFemale.selected = false;
      this.waterFilterInOut_socketFemale.visible = false;
      this.waterFilterIn_bushing.selected = false;
      this.waterFilterIn_bushing.visible = false;
      this.waterFilterOut_bushing.selected = false;
      this.waterFilterOut_bushing.visible = false;
      this.waterFilterOut_valveSocket.selected = false;
      this.waterFilterOut_valveSocket.visible = false;
    }

    /// ////////  수량 ///////////

    this.main1_pePipe.designQuantity =
      (this.main1Length * mainpipePosition.wateringWellQuantity) / this.main1_pePipe.specLength;

    this.waterTank_waterTank.designQuantity = this.waterTankQuantity * mainpipePosition.wateringWellQuantity;

    this.waterTankIn_waterTankFitting.designQuantity = 1 * this.waterTankQuantity;

    this.waterTankExit_waterTankFitting.designQuantity = 1 * this.waterTankQuantity;

    this.waterTankExit_femaleMaleValve.designQuantity = this.waterTankQuantity * mainpipePosition.wateringWellQuantity;

    this.waterTankOut_waterTankFitting.designQuantity = 1 * this.waterTankQuantity;

    if (this.waterTankQuantity > 0) {
      this.waterTankOut_nipple.designQuantity = 1 * mainpipePosition.wateringWellQuantity;

      this.waterTankOut_femaleBallValve.designQuantity = 1 * mainpipePosition.wateringWellQuantity;

      this.waterTankOut_elbow.designQuantity = 1 * mainpipePosition.wateringWellQuantity;
    } else {
      this.waterTankOut_nipple.designQuantity = 0;

      this.waterTankOut_femaleBallValve.designQuantity = 0;

      this.waterTankOut_elbow.designQuantity = 0;
    }

    let waterTankConnect: number = 0;
    if (this.waterTankQuantity >= 2) {
      waterTankConnect = this.waterTankQuantity - 1;
    }
    this.waterTankOut_valveSocket.designQuantity =
      waterTankConnect * 2 * mainpipePosition.wateringWellQuantity +
      this.waterTankOut_femaleBallValve.getSelectedQuantity() +
      this.waterTankOut_elbow.getSelectedQuantity();

    this.waterPump_waterPump.designQuantity = 1 * mainpipePosition.wateringWellQuantity;

    this.waterPumpInOut_bushing.designQuantity = 2 * mainpipePosition.wateringWellQuantity;

    this.waterPumpInOut_maleElbowSocket.designQuantity = 2 * mainpipePosition.wateringWellQuantity;

    this.waterPump_valveSocket.designQuantity = this.waterFilter_waterFilter.getSelectedQuantity();

    this.waterFilter_waterFilter.designQuantity = 1 * mainpipePosition.wateringWellQuantity;

    this.waterFilterInOut_socketFemale.designQuantity = 2 * mainpipePosition.wateringWellQuantity;

    this.waterFilterIn_bushing.designQuantity = 1 * mainpipePosition.wateringWellQuantity;

    this.waterFilterOut_bushing.designQuantity = 1 * mainpipePosition.wateringWellQuantity;

    this.waterFilterOut_valveSocket.designQuantity = this.waterFilter_waterFilter.getSelectedQuantity();
  }

  /**
   * 알고리즘: 물탱크 선택 <- 형태(위치)
   */
  public algorithm_waterTankSelected(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.waterTankSelected = true;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.waterTankSelected = false;
    }
  }

  /**
   * 알고리즘: 펌프 선택 <- 형태(위치)
   */
  public algorithm_waterPumpSelected(): void {
    if (
      (<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER ||
      (<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH
    ) {
      this.waterPumpSelected = true;
    }
  }

  /**
   * 알고리즘: 여과기 선택 <- 형태(위치)
   */
  public algorithm_waterFilterSelected(): void {
    if (
      (<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER ||
      (<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH
    ) {
      this.waterFilterSelected = true;
    }
  }

  /**
   * 알고리즘: 주관1 길이 <- 형태(위치), 물탱크 수량
   */
  public algorithm_main1Length(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.main1Length = this.waterTankQuantity * 1 + 1;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.main1Length = 1;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [주관(관정~여과기)] PE관 <- 주관1 샘플
   */
  public algorithmSpec_main1_pePipe(): void {
    this.main1_pePipe.specs = this.main1PePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [물탱크] 물탱크 <- 물탱크 샘플
   */
  public algorithmSpec_waterTank_waterTank(): void {
    this.waterTank_waterTank.specs = this.waterTankSample.specs;
  }

  /**
   * 규격 알고리즘: [물탱크 입수구] 물탱크 피팅 <- 물탱크 샘플
   */
  public algorithmSpec_waterTankIn_waterTankFitting(): void {
    this.waterTankIn_waterTankFitting.specDiameter1 = this.waterTankSample.specInDiameter;
  }

  /**
   * 규격 알고리즘: [물탱크 퇴수구] 물탱크 피팅 <- 물탱크 샘플
   */
  public algorithmSpec_waterTankExit_waterTankFitting(): void {
    this.waterTankExit_waterTankFitting.specDiameter1 = this.waterTankSample.specExitDiameter;
  }

  /**
   * 규격 알고리즘: [물탱크 퇴수구] 암숫나사 밸브 <- 물탱크 샘플
   */
  public algorithmSpec_waterTankExit_femaleMaleValve(): void {
    this.waterTankExit_femaleMaleValve.specDiameter1 = this.waterTankSample.specExitDiameter;
    this.waterTankExit_femaleMaleValve.specDiameter2 = this.waterTankSample.specExitDiameter;
  }

  /**
   * 규격 알고리즘: [물탱크 출수구] 물탱크 피팅 <- 물탱크 샘플
   */
  public algorithmSpec_waterTankOut_waterTankFitting(): void {
    this.waterTankOut_waterTankFitting.specDiameter1 = this.waterTankSample.specOutDiameter;
  }

  /**
   * 규격 알고리즘: [물탱크 출수구] 니플 <- 주관1 샘플
   */
  public algorithmSpec_waterTankOut_nipple(): void {
    this.waterTankOut_nipple.specDiameter1 = this.main1PePipeSample.specDiameter;
    this.waterTankOut_nipple.specDiameter2 = this.main1PePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [물탱크 출수구] 암나사 볼 밸브 <- 주관1 샘플
   */
  public algorithmSpec_waterTankOut_femaleBallValve(): void {
    this.waterTankOut_femaleBallValve.specDiameter1 = this.main1PePipeSample.specDiameter;
    this.waterTankOut_femaleBallValve.specDiameter2 = this.main1PePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [물탱크~펌프] 엘보 <- 주관1 샘플
   */
  public algorithmSpec_waterTankOut_elbow(): void {
    this.waterTankOut_elbow.specDiameter1 = this.main1PePipeSample.specDiameter;
    this.waterTankOut_elbow.specDiameter2 = this.main1PePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [물탱크~펌프] 밸브 소켓 <- 주관1 샘플
   */
  public algorithmSpec_waterTankOut_valveSocket(): void {
    this.waterTankOut_valveSocket.specDiameter1 = this.main1PePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [펌프] 펌프 <- 펌프 샘플
   */
  public algorithmSpec_waterPump_waterPump(): void {
    this.waterPump_waterPump.specs = this.waterPumpSample.specs;
    this.waterPump_waterPump.brands = this.waterPumpSample.brands;
  }

  /**
   * 규격 알고리즘: [펌프 입수구/출수구] 부싱 <- 펌프 샘플, 주관1 샘플
   */
  public algorithmSpec_waterPumpInOut_bushing(): void {
    if (this.waterPumpSample.brandInOutDiameter !== this.main1PePipeSample.specDiameter) {
      this.waterPumpInOut_bushing.specDiameter1 = this.waterPumpSample.brandInOutDiameter;
      this.waterPumpInOut_bushing.specDiameter2 = this.main1PePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [펌프 입수구/출수구] 숫나사 엘보 소켓 <- 주관1 샘플
   */
  public algorithmSpec_waterPumpInOut_maleElbowSocket(): void {
    this.waterPumpInOut_maleElbowSocket.specDiameter1 = this.main1PePipeSample.specDiameter;
    this.waterPumpInOut_maleElbowSocket.specDiameter2 = this.main1PePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [펌프~여과기] 밸브 소켓 <- 주관1 샘플
   */
  public algorithmSpec_waterPump_valveSocket(): void {
    this.waterPump_valveSocket.specDiameter1 = this.main1PePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [여과기] 여과기 <- 여과기 샘플
   */
  public algorithmSpec_waterFilter_waterFilter(): void {
    this.waterFilter_waterFilter.specs = this.waterFilterSample.specs;
    this.waterFilter_waterFilter.brands = this.waterFilterSample.brands;
  }

  /**
   * 규격 알고리즘: [여과기 입수구/출수구] 소켓(암나사) <- 여과기 샘플
   */
  public algorithmSpec_waterFilterInOut_socketFemale(): void {
    this.waterFilterInOut_socketFemale.specDiameter1 = this.waterFilterSample.brandInOutDiameter;
    this.waterFilterInOut_socketFemale.specDiameter2 = this.waterFilterSample.brandInOutDiameter;
  }

  /**
   * 규격 알고리즘: [여과기 입수구] 부싱 <- 여과기 샘플, 주관1 샘플
   */
  public algorithmSpec_waterFilterIn_bushing(): void {
    if (this.waterFilterSample.brandInOutDiameter !== this.main1PePipeSample.specDiameter) {
      this.waterFilterIn_bushing.specDiameter1 = this.waterFilterSample.brandInOutDiameter;
      this.waterFilterIn_bushing.specDiameter2 = this.main1PePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [여과기 출수구] 부싱 <- 여과기 샘플, 중앙 주관2 샘플, 개별 주관2 샘플, 형태(위치)
   */
  public algorithmSpec_waterFilterOut_bushing(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      if (
        this.waterFilterSample.brandInOutDiameter !==
        (<MainpipePosition>this.position).peMain2Part.main2CenterPePipeSample.specDiameter
      ) {
        this.waterFilterOut_bushing.specDiameter1 = this.waterFilterSample.brandInOutDiameter;
        this.waterFilterOut_bushing.specDiameter2 = (<MainpipePosition>(
          this.position
        )).peMain2Part.main2CenterPePipeSample.specDiameter;
      }
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      if (
        this.waterFilterSample.brandInOutDiameter !==
        (<MainpipePosition>this.position).peMain2Part.main2EachPePipeSample.specDiameter
      ) {
        this.waterFilterOut_bushing.specDiameter1 = this.waterFilterSample.brandInOutDiameter;
        this.waterFilterOut_bushing.specDiameter2 = (<MainpipePosition>(
          this.position
        )).peMain2Part.main2EachPePipeSample.specDiameter;
      }
    }
  }

  /**
   * 규격 알고리즘: [여과기~끝] 밸브 소켓 <- 중앙 주관2 샘플, 개별 주관2 샘플, 형태(위치)
   */
  public algorithmSpec_waterFilterOut_valveSocket(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.waterFilterOut_valveSocket.specDiameter1 = (<MainpipePosition>(
        this.position
      )).peMain2Part.main2CenterPePipeSample.specDiameter;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.waterFilterOut_valveSocket.specDiameter1 = (<MainpipePosition>(
        this.position
      )).peMain2Part.main2EachPePipeSample.specDiameter;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
