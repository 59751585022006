import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { RoofPartVL_Rafter } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Rafter";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { EndpieceSampleVL_BarPipe } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_BarPipe";
import { EndpieceSampleVL_BarCenterPipe } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_BarCenterPipe";
import { EndpieceSampleVL_StraightUpperPipe } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_StraightUpperPipe";
import { EndpieceSampleVL_StraightBottomPipe } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_StraightBottomPipe";
import { FoundationPositionIL } from "vhows-design/src/object/design/foundation/il/FoundationPositionIL";
import { EndpieceSampleVL_StraightBottomCSectionSteel } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_StraightBottomCSectionSteel";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject({
  knownTypes: [
    EndpieceSampleVL_BarPipe,
    EndpieceSampleVL_BarCenterPipe,
    EndpieceSampleVL_StraightUpperPipe,
    EndpieceSampleVL_StraightBottomPipe,
    EndpieceSampleVL_StraightBottomCSectionSteel,
  ],
})
export class EndpiecePartVL_Endpiece extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public barPipeSample: EndpieceSampleVL_BarPipe; // 마구리 간살 파이프 샘플
  public barCenterUpperPipeSample: EndpieceSampleVL_BarCenterPipe; // 마구리 간살(중앙상단) 파이프 샘플
  public straightUpperPipeSample: EndpieceSampleVL_StraightUpperPipe; // 마구리 가로대 상단 파이프 샘플
  public straightBottomPipeSample: EndpieceSampleVL_StraightBottomPipe; // 마구리 가로대 하단 파이프 샘플
  public straightBottomCSectionSteelSample: EndpieceSampleVL_StraightBottomCSectionSteel; // 마구리 가로대 하단 C형강 샘플

  // 아이템
  public bar_pipe: ItemPipe;
  public barAndRafterCenter_tHolder: ItemHolder;
  public barAndRafterCenterEndpiece_normalHolder: ItemHolder;
  public barAndRafterCenterRafter_normalHolder: ItemHolder;
  public barAndRafterCenter_boltAndNut: ItemFrameOther;
  public barCenterUpper_pipe: ItemPipe;
  public barCenterUpperAndMiddle_saddleHolder: ItemHolder;
  public barAndRafterDiagonal_diagonalTHolder: ItemHolder;
  public barAndRafterDiagonal_diagonalTClamp: ItemHolder;
  public barAndRafterDiagonalEndpiece_normalHolder: ItemHolder;
  public barAndRafterDiagonalRafter_normalHolder: ItemHolder;
  public barAndRafterDiagonal_boltAndNut: ItemFrameOther;
  public barAndMiddle_saddleHolder: ItemHolder;
  public barAndWidthGirth_saddleHolder: ItemHolder;
  public barAndWidthGirth_fixAsibaClamp: ItemHolder;
  public bar_screw: ItemScrew;
  public straightUpper_pipe: ItemPipe;
  public straightUpperAndRafter_diagonalTHolder: ItemHolder;
  public straightUpperAndRafterStraightUpper_normalHolder: ItemHolder;
  public straightUpperAndRafterRafter_normalHolder: ItemHolder;
  public straightUpperAndRafter_boltAndNut: ItemFrameOther;
  public straightUpperAndBar_saddleHolder: ItemHolder;
  public straightBottom_pipe: ItemPipe;
  public straightBottom_cSectionSteel: ItemPipe;
  public straightBottomAndStraightBottom_connectionPin: ItemConnector;
  public straightBottomAndFrontColumn_saddleHolder: ItemHolder;
  public straightBottomAndColumnEdge_tClamp: ItemHolder;
  public straightBottomAndColumnCenter_crossClamp: ItemHolder;
  public straightBottomAndEndpieceBarBottom_saddleHolder: ItemHolder;
  public straightBottomAndEndpieceBarBottom_crossClamp: ItemHolder;
  public straight_screw: ItemScrew;
  public straight_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _barType: string; // 마구리 간살 형태
  @jsonMember(Number)
  public _barLengthMax: number; // 마구리 간살 최대 길이
  @jsonMember(Number)
  public _totalBarLineNumber: number; // 총 마구리 간살 줄수
  @jsonMember(Number)
  public _barLineNumber: number; // 마구리 간살 줄수
  @jsonMember(Number)
  public _straightLength: number; // 마구리 가로대 길이
  @jsonMember(Number)
  public _straightUpperLineNumber: number; // 마구리 가로대 상단 줄수
  @jsonMember(Number)
  public _straightBottomLineNumber: number; // 마구리 가로대 하단 줄수

  /**
   * 마구리 간살 형태
   */
  public get barType(): string {
    return this._barType;
  }

  //
  public set barType(value: string) {
    this._barType = value;

    // 알고리즘
    this.algorithm_barLineNumber();
    this.algorithm_totalBarLineNumber();
    this.algorithmPart();
  }

  /**
   * 마구리 간살 최대 길이
   */
  public get barLengthMax(): number {
    return this._barLengthMax;
  }

  //
  public set barLengthMax(value: number) {
    this._barLengthMax = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 총 마구리 간살 줄수
   */
  public get totalBarLineNumber(): number {
    return this._totalBarLineNumber;
  }

  //
  public set totalBarLineNumber(value: number) {
    this._totalBarLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 마구리 간살 줄수
   */
  public get barLineNumber(): number {
    return this._barLineNumber;
  }

  //
  public set barLineNumber(value: number) {
    this._barLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalBarLineNumber();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 치마
    if (this.level.index === 0 || this.level.index === 1) {
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        (<SkirtLevelIL>(
          this.struct.skirtWorkIL.levelAC[this.level.index]
        )).frontPosition.fixingSkirtClipPart.algorithm_clipInterval();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        (<SkirtLevelIL>(
          this.struct.skirtWorkIL.levelAC[this.level.index]
        )).backPosition.fixingSkirtClipPart.algorithm_clipInterval();
      }
    }
  }

  /**
   * 마구리 가로대 길이
   */
  public get straightLength(): number {
    return this._straightLength;
  }

  //
  public set straightLength(value: number) {
    this._straightLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 마구리 가로대 상단 줄수
   */
  public get straightUpperLineNumber(): number {
    return this._straightUpperLineNumber;
  }

  //
  public set straightUpperLineNumber(value: number) {
    this._straightUpperLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 마구리 가로대 하단 줄수
   */
  public get straightBottomLineNumber(): number {
    return this._straightBottomLineNumber;
  }

  //
  public set straightBottomLineNumber(value: number) {
    this._straightBottomLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.barPipeSample = new EndpieceSampleVL_BarPipe();
    this.barCenterUpperPipeSample = new EndpieceSampleVL_BarCenterPipe();
    this.straightUpperPipeSample = new EndpieceSampleVL_StraightUpperPipe();
    this.straightBottomPipeSample = new EndpieceSampleVL_StraightBottomPipe();
    this.straightBottomCSectionSteelSample = new EndpieceSampleVL_StraightBottomCSectionSteel();

    this.sampleAC = [
      this.barPipeSample,
      this.barCenterUpperPipeSample,
      this.straightUpperPipeSample,
      this.straightBottomPipeSample,
      this.straightBottomCSectionSteelSample,
    ];

    // 아이템
    this.bar_pipe = new ItemPipe();
    this.barAndRafterCenter_tHolder = new ItemHolder();
    this.barAndRafterCenterEndpiece_normalHolder = new ItemHolder();
    this.barAndRafterCenterRafter_normalHolder = new ItemHolder();
    this.barAndRafterCenter_boltAndNut = new ItemFrameOther();
    this.barCenterUpper_pipe = new ItemPipe();
    this.barCenterUpperAndMiddle_saddleHolder = new ItemHolder();
    this.barAndRafterDiagonal_diagonalTHolder = new ItemHolder();
    this.barAndRafterDiagonal_diagonalTClamp = new ItemHolder();
    this.barAndRafterDiagonalEndpiece_normalHolder = new ItemHolder();
    this.barAndRafterDiagonalRafter_normalHolder = new ItemHolder();
    this.barAndRafterDiagonal_boltAndNut = new ItemFrameOther();
    this.barAndMiddle_saddleHolder = new ItemHolder();
    this.barAndWidthGirth_saddleHolder = new ItemHolder();
    this.barAndWidthGirth_fixAsibaClamp = new ItemHolder();
    this.bar_screw = new ItemScrew();
    this.straightUpper_pipe = new ItemPipe();
    this.straightUpperAndRafter_diagonalTHolder = new ItemHolder();
    this.straightUpperAndRafterStraightUpper_normalHolder = new ItemHolder();
    this.straightUpperAndRafterRafter_normalHolder = new ItemHolder();
    this.straightUpperAndRafter_boltAndNut = new ItemFrameOther();
    this.straightUpperAndBar_saddleHolder = new ItemHolder();
    this.straightBottom_pipe = new ItemPipe();
    this.straightBottom_cSectionSteel = new ItemPipe();
    this.straightBottomAndStraightBottom_connectionPin = new ItemConnector();
    this.straightBottomAndFrontColumn_saddleHolder = new ItemHolder();
    this.straightBottomAndColumnEdge_tClamp = new ItemHolder();
    this.straightBottomAndColumnCenter_crossClamp = new ItemHolder();
    this.straightBottomAndEndpieceBarBottom_saddleHolder = new ItemHolder();
    this.straightBottomAndEndpieceBarBottom_crossClamp = new ItemHolder();
    this.straight_screw = new ItemScrew();
    this.straight_welding = new ItemOtherExpense();

    this.itemAC = [
      this.bar_pipe,
      this.barAndRafterCenter_tHolder,
      this.barAndRafterCenterEndpiece_normalHolder,
      this.barAndRafterCenterRafter_normalHolder,
      this.barAndRafterCenter_boltAndNut,
      this.barCenterUpper_pipe,
      this.barCenterUpperAndMiddle_saddleHolder,
      this.barAndRafterDiagonal_diagonalTHolder,
      this.barAndRafterDiagonal_diagonalTClamp,
      this.barAndRafterDiagonalEndpiece_normalHolder,
      this.barAndRafterDiagonalRafter_normalHolder,
      this.barAndRafterDiagonal_boltAndNut,
      this.barAndMiddle_saddleHolder,
      this.barAndWidthGirth_saddleHolder,
      this.barAndWidthGirth_fixAsibaClamp,
      this.bar_screw,
      this.straightUpper_pipe,
      this.straightUpperAndRafter_diagonalTHolder,
      this.straightUpperAndRafterStraightUpper_normalHolder,
      this.straightUpperAndRafterRafter_normalHolder,
      this.straightUpperAndRafter_boltAndNut,
      this.straightUpperAndBar_saddleHolder,
      this.straightBottom_pipe,
      this.straightBottom_cSectionSteel,
      this.straightBottomAndStraightBottom_connectionPin,
      this.straightBottomAndFrontColumn_saddleHolder,
      this.straightBottomAndColumnEdge_tClamp,
      this.straightBottomAndColumnCenter_crossClamp,
      this.straightBottomAndEndpieceBarBottom_saddleHolder,
      this.straightBottomAndEndpieceBarBottom_crossClamp,
      this.straight_screw,
      this.straight_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.barPipeSample = <EndpieceSampleVL_BarPipe>this.sampleAC[0];
    this.barCenterUpperPipeSample = <EndpieceSampleVL_BarCenterPipe>this.sampleAC[1];
    this.straightUpperPipeSample = <EndpieceSampleVL_StraightUpperPipe>this.sampleAC[2];
    this.straightBottomPipeSample = <EndpieceSampleVL_StraightBottomPipe>this.sampleAC[3];
    this.straightBottomCSectionSteelSample = <EndpieceSampleVL_StraightBottomCSectionSteel>this.sampleAC[4];

    // 아이템
    this.bar_pipe = <ItemPipe>this.itemAC[0];
    this.barAndRafterCenter_tHolder = <ItemHolder>this.itemAC[1];
    this.barAndRafterCenterEndpiece_normalHolder = <ItemHolder>this.itemAC[2];
    this.barAndRafterCenterRafter_normalHolder = <ItemHolder>this.itemAC[3];
    this.barAndRafterCenter_boltAndNut = <ItemFrameOther>this.itemAC[4];
    this.barCenterUpper_pipe = <ItemPipe>this.itemAC[5];
    this.barCenterUpperAndMiddle_saddleHolder = <ItemHolder>this.itemAC[6];
    this.barAndRafterDiagonal_diagonalTHolder = <ItemHolder>this.itemAC[7];
    this.barAndRafterDiagonal_diagonalTClamp = <ItemHolder>this.itemAC[8];
    this.barAndRafterDiagonalEndpiece_normalHolder = <ItemHolder>this.itemAC[9];
    this.barAndRafterDiagonalRafter_normalHolder = <ItemHolder>this.itemAC[10];
    this.barAndRafterDiagonal_boltAndNut = <ItemFrameOther>this.itemAC[11];
    this.barAndMiddle_saddleHolder = <ItemHolder>this.itemAC[12];
    this.barAndWidthGirth_saddleHolder = <ItemHolder>this.itemAC[13];
    this.barAndWidthGirth_fixAsibaClamp = <ItemHolder>this.itemAC[14];
    this.bar_screw = <ItemScrew>this.itemAC[15];
    this.straightUpper_pipe = <ItemPipe>this.itemAC[16];
    this.straightUpperAndRafter_diagonalTHolder = <ItemHolder>this.itemAC[17];
    this.straightUpperAndRafterStraightUpper_normalHolder = <ItemHolder>this.itemAC[18];
    this.straightUpperAndRafterRafter_normalHolder = <ItemHolder>this.itemAC[19];
    this.straightUpperAndRafter_boltAndNut = <ItemFrameOther>this.itemAC[20];
    this.straightUpperAndBar_saddleHolder = <ItemHolder>this.itemAC[21];
    this.straightBottom_pipe = <ItemPipe>this.itemAC[22];
    this.straightBottom_cSectionSteel = <ItemPipe>this.itemAC[23];
    this.straightBottomAndStraightBottom_connectionPin = <ItemConnector>this.itemAC[24];
    this.straightBottomAndFrontColumn_saddleHolder = <ItemHolder>this.itemAC[25];
    this.straightBottomAndColumnEdge_tClamp = <ItemHolder>this.itemAC[26];
    this.straightBottomAndColumnCenter_crossClamp = <ItemHolder>this.itemAC[27];
    this.straightBottomAndEndpieceBarBottom_saddleHolder = <ItemHolder>this.itemAC[28];
    this.straightBottomAndEndpieceBarBottom_crossClamp = <ItemHolder>this.itemAC[29];
    this.straight_screw = <ItemScrew>this.itemAC[30];
    this.straight_welding = <ItemOtherExpense>this.itemAC[31];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param barLineNumber: number 마구리 간살 줄수
   * @param straightUpperLineNumber: number 마구리 가로대 상단 줄수
   * @param straightBottomLineNumber: number 마구리 가로대 하단 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    barType: string = null,
    barLineNumber: number = 0,
    straightUpperLineNumber: number = 0,
    straightBottomLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._barType = barType;
    this._barLineNumber = barLineNumber;
    this._straightUpperLineNumber = straightUpperLineNumber;
    this._straightBottomLineNumber = straightBottomLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.barPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 간살",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.barCenterUpperPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 간살(중앙상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.straightUpperPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 가로대(상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.straightBottomPipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 가로대(하단)",
        "각형, 일반, 75×45mm, 2.1T, 10m, -",
      );
      this.straightBottomCSectionSteelSample.setDefaultData(
        4,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "마구리 가로대(하단)",
        "각형, 일반, 75×45mm, 2.1T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.bar_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 간살",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.barAndRafterCenter_tHolder.setDefaultData(
        1,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "마구리 간살 + 서까래",
        "원형, 일반, 48mm, 32mm",
      );
      this.barAndRafterCenterEndpiece_normalHolder.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 간살 + 서까래 (1)",
        "원형, 일반, 48mm, -",
      );
      this.barAndRafterCenterRafter_normalHolder.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 간살 + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.barAndRafterCenter_boltAndNut.setDefaultData(
        4,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 간살 + 서까래",
        "일반, 8mm",
      );
      this.barCenterUpper_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 간살(중앙상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.barCenterUpperAndMiddle_saddleHolder.setDefaultData(
        6,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 간살(중앙상단) + 전후면 중방",
        "원형, 일반, 32mm, -",
      );
      this.barAndRafterDiagonal_diagonalTHolder.setDefaultData(
        7,
        CONST.ITEM_ID_DIAGONAL_T_HOLDER,
        CONST.ITEM_NAME_DIAGONAL_T_HOLDER,
        "마구리 간살 + 서까래",
        "원형, 일반, 32mm, 32mm",
      );
      this.barAndRafterDiagonal_diagonalTClamp.setDefaultData(
        8,
        CONST.ITEM_ID_DIAGONAL_T_CLAMP,
        CONST.ITEM_NAME_DIAGONAL_T_CLAMP,
        "마구리 간살 + 서까래",
        "원형, 일반, 32mm, 48mm",
      );
      this.barAndRafterDiagonalEndpiece_normalHolder.setDefaultData(
        9,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 간살 + 서까래 (1)",
        "원형, 일반, 48mm, -",
      );
      this.barAndRafterDiagonalRafter_normalHolder.setDefaultData(
        10,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 간살 + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.barAndRafterDiagonal_boltAndNut.setDefaultData(
        11,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 간살 + 서까래",
        "일반, 8mm",
      );
      this.barAndMiddle_saddleHolder.setDefaultData(
        12,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 간살 + 전후면 중방",
        "각형, 일반, 60×30mm, -",
      );
      this.barAndWidthGirth_saddleHolder.setDefaultData(
        13,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 간살 + 폭방향 바닥도리",
        "원형, 일반, 48mm, -",
      );
      this.barAndWidthGirth_fixAsibaClamp.setDefaultData(
        14,
        CONST.ITEM_ID_FIX_ASIBA_CLAMP,
        CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
        "마구리 간살 + 폭방향 바닥도리",
        "원형, 일반, 48mm, 48mm",
      );
      this.bar_screw.setDefaultData(
        15,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 간살",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.straightUpper_pipe.setDefaultData(
        16,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 가로대(상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.straightUpperAndRafter_diagonalTHolder.setDefaultData(
        17,
        CONST.ITEM_ID_DIAGONAL_T_HOLDER,
        CONST.ITEM_NAME_DIAGONAL_T_HOLDER,
        "마구리 가로대(상단) + 서까래",
        "원형, 일반, 32mm, 32mm",
      );
      this.straightUpperAndRafterStraightUpper_normalHolder.setDefaultData(
        18,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 가로대(상단) + 서까래 (1)",
        "원형, 일반, 32mm, -",
      );
      this.straightUpperAndRafterRafter_normalHolder.setDefaultData(
        19,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 가로대(상단) + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.straightUpperAndRafter_boltAndNut.setDefaultData(
        20,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 가로대(상단) + 서까래",
        "일반, 8mm",
      );
      this.straightUpperAndBar_saddleHolder.setDefaultData(
        21,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 가로대(상단) + 마구리 간살(상단)",
        "원형, 일반, 32mm, -",
      );
      this.straightBottom_pipe.setDefaultData(
        22,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 가로대(하단)",
        "각형, 일반, 75×45mm, 2.0T, 10m, -",
      );
      this.straightBottom_cSectionSteel.setDefaultData(
        23,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "마구리 가로대(하단)",
        "각형, 일반, 75×45mm, 2.1T, 10m, -",
      );
      this.straightBottomAndStraightBottom_connectionPin.setDefaultData(
        24,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "마구리 가로대(하단) + 마구리 가로대(하단)",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.straightBottomAndFrontColumn_saddleHolder.setDefaultData(
        25,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 가로대(하단) + 기둥",
        "원형, 일반, 32mm, -",
      );
      this.straightBottomAndColumnEdge_tClamp.setDefaultData(
        26,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "마구리 가로대(하단)(끝) + 골조 기둥",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.straightBottomAndColumnCenter_crossClamp.setDefaultData(
        27,
        CONST.ITEM_ID_CROSS_CLAMP,
        CONST.ITEM_NAME_CROSS_CLAMP,
        "마구리 가로대(하단) + 골조 기둥",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.straightBottomAndEndpieceBarBottom_saddleHolder.setDefaultData(
        28,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 가로대(하단) + 마구리 간살",
        "원형, 일반, 32mm, -",
      );
      this.straightBottomAndEndpieceBarBottom_crossClamp.setDefaultData(
        29,
        CONST.ITEM_ID_CROSS_CLAMP,
        CONST.ITEM_NAME_CROSS_CLAMP,
        "마구리 가로대(하단) + 마구리 간살",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.straight_screw.setDefaultData(
        30,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 가로대",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.straight_welding.setDefaultData(31, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "마구리 가로대", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_barLengthMax();
    this.algorithm_totalBarLineNumber();
    this.algorithm_straightLength();
    super.algorithmBasic();
  }

  /**
   * 파트 알고리즘 <- 형태(기둥 골조), 서까래 파이프 샘플(지붕 골조), 선택(바닥 골조), 형태(바닥 골조), 폭방향 바닥도리 선택(바닥 골조)
   */
  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////
    const endpiecePosition: EndpiecePositionVL = <EndpiecePositionVL>this.position;
    const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
    const roofPartRafter: RoofPartVL_Rafter = this.struct.roofWorkVL.level1.roofPosition.rafterPart;
    const foundationPosition: FoundationPositionIL = this.struct.foundationWorkIL.level1.foundationPosition;

    const rafterValleyQuantity: number = roofPartRafter.rafterValleyQuantity;
    let gateQuantity: number; // 출입동인 경우
    let noGateQuantity: number; // 출입동이 아닌 경우

    /// //////// 수치 ///////////
    // 출입동이 아닌 동수
    let noGateBuildingNumber: number = this.basic.buildingNumber - endpiecePosition.gateBuildingNumber;
    if (noGateBuildingNumber < 0) {
      noGateBuildingNumber = 0;
    }

    /// //////// 선택, 가시성 ///////////
    this.bar_pipe.selected = true;
    this.bar_pipe.visible = true;
    // 중앙 : T 고정구(22/22mm,25/25mm,32/32mm,32/25mm) vs. 일반 고정구
    // 하드코딩
    if (
      this.design.preference.endpieceTholderFlag === true &&
      ((this.bar_pipe.specCrossSize === roofPartRafter.rafterPipeSample.specCrossSize &&
        (this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM ||
          this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
          this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)) ||
        (roofPartRafter.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM &&
          this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM))
    ) {
      this.barAndRafterCenter_tHolder.selected = true;
      this.barAndRafterCenter_tHolder.visible = true;
      this.barAndRafterCenterEndpiece_normalHolder.selected = false;
      this.barAndRafterCenterEndpiece_normalHolder.visible = false;
      this.barAndRafterCenterRafter_normalHolder.selected = false;
      this.barAndRafterCenterRafter_normalHolder.visible = false;
      this.barAndRafterCenter_boltAndNut.selected = false;
      this.barAndRafterCenter_boltAndNut.visible = false;
    } else {
      this.barAndRafterCenter_tHolder.selected = false;
      this.barAndRafterCenter_tHolder.visible = false;
      this.barAndRafterCenterEndpiece_normalHolder.selected = true;
      this.barAndRafterCenterEndpiece_normalHolder.visible = true;
      this.barAndRafterCenterRafter_normalHolder.selected = true;
      this.barAndRafterCenterRafter_normalHolder.visible = true;
      this.barAndRafterCenter_boltAndNut.selected = true;
      this.barAndRafterCenter_boltAndNut.visible = true;
    }

    this.barCenterUpper_pipe.selected = true;
    this.barCenterUpper_pipe.visible = true;
    this.barCenterUpperAndMiddle_saddleHolder.selected = true;
    this.barCenterUpperAndMiddle_saddleHolder.visible = true;

    // 대각 : 대각T 고정구(25/25mm,32/32mm) vs. 대각T 클램프(25/48mm,32/48mm,42/42mm,48/48mm) vs. 일반 고정구
    // 하드코딩
    if (
      this.design.preference.endpieceDiagonalTholderFlag === true &&
      this.bar_pipe.specCrossSize === roofPartRafter.rafterPipeSample.specCrossSize &&
      (this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
        this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)
    ) {
      this.barAndRafterDiagonal_diagonalTHolder.selected = true;
      this.barAndRafterDiagonal_diagonalTHolder.visible = true;
      this.barAndRafterDiagonal_diagonalTClamp.selected = false;
      this.barAndRafterDiagonal_diagonalTClamp.visible = false;
      this.barAndRafterDiagonalEndpiece_normalHolder.selected = false;
      this.barAndRafterDiagonalEndpiece_normalHolder.visible = false;
      this.barAndRafterDiagonalRafter_normalHolder.selected = false;
      this.barAndRafterDiagonalRafter_normalHolder.visible = false;
      this.barAndRafterDiagonal_boltAndNut.selected = false;
      this.barAndRafterDiagonal_boltAndNut.visible = false;
    } else if (
      this.design.preference.endpieceDiagonalTclampFlag === true &&
      ((this.bar_pipe.specCrossSize === roofPartRafter.rafterPipeSample.specCrossSize &&
        (this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
          this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)) ||
        (roofPartRafter.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM &&
          this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) ||
        (roofPartRafter.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM &&
          this.bar_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM))
    ) {
      this.barAndRafterDiagonal_diagonalTHolder.selected = false;
      this.barAndRafterDiagonal_diagonalTHolder.visible = false;
      this.barAndRafterDiagonal_diagonalTClamp.selected = true;
      this.barAndRafterDiagonal_diagonalTClamp.visible = true;
      this.barAndRafterDiagonalEndpiece_normalHolder.selected = false;
      this.barAndRafterDiagonalEndpiece_normalHolder.visible = false;
      this.barAndRafterDiagonalRafter_normalHolder.selected = false;
      this.barAndRafterDiagonalRafter_normalHolder.visible = false;
      this.barAndRafterDiagonal_boltAndNut.selected = false;
      this.barAndRafterDiagonal_boltAndNut.visible = false;
    } else {
      this.barAndRafterDiagonal_diagonalTHolder.selected = false;
      this.barAndRafterDiagonal_diagonalTHolder.visible = false;
      this.barAndRafterDiagonal_diagonalTClamp.selected = false;
      this.barAndRafterDiagonal_diagonalTClamp.visible = false;
      this.barAndRafterDiagonalEndpiece_normalHolder.selected = true;
      this.barAndRafterDiagonalEndpiece_normalHolder.visible = true;
      this.barAndRafterDiagonalRafter_normalHolder.selected = true;
      this.barAndRafterDiagonalRafter_normalHolder.visible = true;
      this.barAndRafterDiagonal_boltAndNut.selected = true;
      this.barAndRafterDiagonal_boltAndNut.visible = true;
    }

    if (columnPosition.prismMiddlePart.frontAndBackMiddleLineNumber !== 0) {
      this.barAndMiddle_saddleHolder.selected = true;
      this.barAndMiddle_saddleHolder.visible = true;
    } else {
      this.barAndMiddle_saddleHolder.selected = false;
      this.barAndMiddle_saddleHolder.visible = false;
    }

    // 마구리 형태 및 폭방향 바닥도리 선택
    if (
      endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE &&
      this.level.index === 0 &&
      foundationPosition.selected === true &&
      (foundationPosition.type === CONST.LB_FOUNDATION_TYPE_GIRTH_PIPE ||
        foundationPosition.type === CONST.LB_FOUNDATION_TYPE_GIRTH_AND_COLUMN_CONCRETE) &&
      foundationPosition.girthPipePart.widthGirthSelected === true
    ) {
      // 하드코딩 : 고정 아시바 클램프 규격
      if (
        (this.barPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM &&
          foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM) ||
        (this.barPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM &&
          (foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
            foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)) ||
        (this.barPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM &&
          (foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM ||
            foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM))
      ) {
        this.barAndWidthGirth_saddleHolder.selected = false;
        this.barAndWidthGirth_saddleHolder.visible = false;
        this.barAndWidthGirth_fixAsibaClamp.selected = true;
        this.barAndWidthGirth_fixAsibaClamp.visible = true;
      } else {
        this.barAndWidthGirth_saddleHolder.selected = true;
        this.barAndWidthGirth_saddleHolder.visible = true;
        this.barAndWidthGirth_fixAsibaClamp.selected = false;
        this.barAndWidthGirth_fixAsibaClamp.visible = false;
      }
    } else {
      this.barAndWidthGirth_saddleHolder.selected = false;
      this.barAndWidthGirth_saddleHolder.visible = false;
      this.barAndWidthGirth_fixAsibaClamp.selected = false;
      this.barAndWidthGirth_fixAsibaClamp.visible = false;
    }
    this.bar_screw.selected = true;
    this.bar_screw.visible = true;

    if (this.straightUpperLineNumber !== 0) {
      this.straightUpper_pipe.selected = true;
      this.straightUpper_pipe.visible = true;
      // 대각 : 대각T 고정구(25/25mm,32/32mm) vs. 일반 고정구
      // 하드코딩
      if (
        this.design.preference.endpieceDiagonalTholderFlag === true &&
        this.straightUpper_pipe.specCrossSize === roofPartRafter.rafterPipeSample.specCrossSize &&
        (this.straightUpper_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
          this.straightUpper_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)
      ) {
        this.straightUpperAndRafter_diagonalTHolder.selected = true;
        this.straightUpperAndRafter_diagonalTHolder.visible = true;
        this.straightUpperAndRafterStraightUpper_normalHolder.selected = false;
        this.straightUpperAndRafterStraightUpper_normalHolder.visible = false;
        this.straightUpperAndRafterRafter_normalHolder.selected = false;
        this.straightUpperAndRafterRafter_normalHolder.visible = false;
        this.straightUpperAndRafter_boltAndNut.selected = false;
        this.straightUpperAndRafter_boltAndNut.visible = false;
      } else {
        this.straightUpperAndRafter_diagonalTHolder.selected = false;
        this.straightUpperAndRafter_diagonalTHolder.visible = false;
        this.straightUpperAndRafterStraightUpper_normalHolder.selected = true;
        this.straightUpperAndRafterStraightUpper_normalHolder.visible = true;
        this.straightUpperAndRafterRafter_normalHolder.selected = true;
        this.straightUpperAndRafterRafter_normalHolder.visible = true;
        this.straightUpperAndRafter_boltAndNut.selected = true;
        this.straightUpperAndRafter_boltAndNut.visible = true;
      }
      this.straightUpperAndBar_saddleHolder.selected = true;
      this.straightUpperAndBar_saddleHolder.visible = true;
    } else {
      this.straightUpper_pipe.selected = false;
      this.straightUpper_pipe.visible = false;
      this.straightUpperAndRafter_diagonalTHolder.selected = false;
      this.straightUpperAndRafter_diagonalTHolder.visible = false;
      this.straightUpperAndRafterStraightUpper_normalHolder.selected = false;
      this.straightUpperAndRafterStraightUpper_normalHolder.visible = false;
      this.straightUpperAndRafterRafter_normalHolder.selected = false;
      this.straightUpperAndRafterRafter_normalHolder.visible = false;
      this.straightUpperAndRafter_boltAndNut.selected = false;
      this.straightUpperAndRafter_boltAndNut.visible = false;
      this.straightUpperAndBar_saddleHolder.selected = false;
      this.straightUpperAndBar_saddleHolder.visible = false;
    }
    if (this.straightBottomLineNumber !== 0) {
      if (
        this.struct.columnWorkVL.level1.columnPosition.sideStraightPart.sideStraightType ===
        CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_PIPE
      ) {
        this.straightBottom_pipe.selected = true;
        this.straightBottom_pipe.visible = true;
        this.straightBottomAndStraightBottom_connectionPin.selected = true;
        this.straightBottomAndStraightBottom_connectionPin.visible = true;
        this.straightBottomAndFrontColumn_saddleHolder.selected = true;
        this.straightBottomAndFrontColumn_saddleHolder.visible = true;
        this.straightBottomAndEndpieceBarBottom_saddleHolder.selected = true;
        this.straightBottomAndEndpieceBarBottom_saddleHolder.visible = true;
        this.straightBottomAndColumnEdge_tClamp.selected = false;
        this.straightBottomAndColumnEdge_tClamp.visible = false;
        this.straightBottomAndColumnCenter_crossClamp.selected = false;
        this.straightBottomAndColumnCenter_crossClamp.visible = false;
        this.straightBottomAndEndpieceBarBottom_crossClamp.selected = false;
        this.straightBottomAndEndpieceBarBottom_crossClamp.visible = false;
        this.straightBottom_cSectionSteel.selected = false;
        this.straightBottom_cSectionSteel.visible = false;
      } else {
        this.straightBottom_cSectionSteel.selected = true;
        this.straightBottom_cSectionSteel.visible = true;
        if (this.design.preference.prismEndpieceWeldingFlag === true) {
          this.straightBottomAndColumnEdge_tClamp.selected = false;
          this.straightBottomAndColumnEdge_tClamp.visible = false;
          this.straightBottomAndColumnCenter_crossClamp.selected = false;
          this.straightBottomAndColumnCenter_crossClamp.visible = false;
          this.straightBottomAndEndpieceBarBottom_crossClamp.selected = false;
          this.straightBottomAndEndpieceBarBottom_crossClamp.visible = false;
        } else {
          this.straightBottomAndColumnEdge_tClamp.selected = true;
          this.straightBottomAndColumnEdge_tClamp.visible = true;
          this.straightBottomAndColumnCenter_crossClamp.selected = true;
          this.straightBottomAndColumnCenter_crossClamp.visible = true;
          this.straightBottomAndEndpieceBarBottom_crossClamp.selected = true;
          this.straightBottomAndEndpieceBarBottom_crossClamp.visible = true;
        }
        this.straightBottom_pipe.selected = false;
        this.straightBottom_pipe.visible = false;
        this.straightBottomAndStraightBottom_connectionPin.selected = false;
        this.straightBottomAndStraightBottom_connectionPin.visible = false;
        this.straightBottomAndFrontColumn_saddleHolder.selected = false;
        this.straightBottomAndFrontColumn_saddleHolder.visible = false;
        this.straightBottomAndEndpieceBarBottom_saddleHolder.selected = false;
        this.straightBottomAndEndpieceBarBottom_saddleHolder.visible = false;
      }
    } else {
      this.straightBottom_pipe.selected = false;
      this.straightBottom_pipe.visible = false;
      this.straightBottomAndStraightBottom_connectionPin.selected = false;
      this.straightBottomAndStraightBottom_connectionPin.visible = false;
      this.straightBottomAndFrontColumn_saddleHolder.selected = true;
      this.straightBottomAndFrontColumn_saddleHolder.visible = true;
      this.straightBottomAndColumnEdge_tClamp.selected = false;
      this.straightBottomAndColumnEdge_tClamp.visible = false;
      this.straightBottomAndColumnCenter_crossClamp.selected = false;
      this.straightBottomAndColumnCenter_crossClamp.visible = false;
      this.straightBottomAndEndpieceBarBottom_saddleHolder.selected = true;
      this.straightBottomAndEndpieceBarBottom_saddleHolder.visible = true;
      this.straightBottomAndEndpieceBarBottom_crossClamp.selected = false;
      this.straightBottomAndEndpieceBarBottom_crossClamp.visible = false;
    }
    this.straight_screw.selected = true;
    this.straight_screw.visible = true;
    this.straight_welding.selected = true;
    this.straight_welding.visible = true;

    /// //////// 수량 ///////////

    if (this.bar_pipe.specLength >= this.barLengthMax) {
      this.bar_pipe.designQuantity =
        (this.totalBarLineNumber / Math.floor(this.bar_pipe.specLength / this.barLengthMax)) *
        CONST.NUM_EXTRA_RATE_ENDPIECE_STUD_PIPE;
    } else {
      this.bar_pipe.designQuantity =
        Math.ceil((this.barLengthMax * this.totalBarLineNumber) / this.bar_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_ENDPIECE_STUD_PIPE;
    }

    this.barAndRafterCenter_tHolder.designQuantity =
      1 * this.basic.buildingNumber * rafterValleyQuantity * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.barAndRafterCenterEndpiece_normalHolder.designQuantity =
      1 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.barAndRafterCenterRafter_normalHolder.designQuantity =
      1 * this.basic.buildingNumber * rafterValleyQuantity * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.barAndRafterCenter_boltAndNut.designQuantity =
      this.barAndRafterCenterEndpiece_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.barCenterUpper_pipe.designQuantity =
      (this.basicLevel.heightRoofMax * endpiecePosition.gateBuildingNumber) / this.barCenterUpper_pipe.specLength;

    this.barCenterUpperAndMiddle_saddleHolder.designQuantity =
      1 * endpiecePosition.gateBuildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.barAndRafterDiagonal_diagonalTHolder.designQuantity =
      (this.barLineNumber - 1) *
      this.basic.buildingNumber *
      rafterValleyQuantity *
      CONST.NUM_EXTRA_RATE_DIAGONAL_T_HOLDER;

    this.barAndRafterDiagonal_diagonalTClamp.designQuantity =
      (this.barLineNumber - 1) *
      this.basic.buildingNumber *
      rafterValleyQuantity *
      CONST.NUM_EXTRA_RATE_DIAGONAL_T_CLAMP;

    this.barAndRafterDiagonalEndpiece_normalHolder.designQuantity =
      (this.barLineNumber - 1) * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.barAndRafterDiagonalRafter_normalHolder.designQuantity =
      (this.barLineNumber - 1) * this.basic.buildingNumber * rafterValleyQuantity * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.barAndRafterDiagonal_boltAndNut.designQuantity =
      this.barAndRafterDiagonalEndpiece_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    gateQuantity = (this.barLineNumber - 1) * 1 * endpiecePosition.gateBuildingNumber;
    noGateQuantity = this.barLineNumber * 1 * noGateBuildingNumber;
    this.barAndMiddle_saddleHolder.designQuantity =
      (gateQuantity + noGateQuantity) * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.barAndWidthGirth_saddleHolder.designQuantity = gateQuantity + noGateQuantity;

    this.barAndWidthGirth_fixAsibaClamp.designQuantity = gateQuantity + noGateQuantity;

    this.bar_screw.designQuantity =
      ((this.barAndRafterCenter_tHolder.getSelectedQuantity() * 3 +
        this.barAndRafterCenterEndpiece_normalHolder.getSelectedQuantity() * 2 +
        this.barAndRafterCenterRafter_normalHolder.getSelectedQuantity() * 2 +
        this.barAndMiddle_saddleHolder.getSelectedQuantity() * 3 +
        this.barCenterUpperAndMiddle_saddleHolder.getSelectedQuantity() * 3 +
        this.barAndRafterDiagonal_diagonalTHolder.getSelectedQuantity() * 3 +
        this.barAndRafterDiagonal_diagonalTClamp.getSelectedQuantity() * 4 +
        this.barAndRafterDiagonalEndpiece_normalHolder.getSelectedQuantity() * 2 +
        this.barAndRafterDiagonalRafter_normalHolder.getSelectedQuantity() * 2 +
        this.barAndWidthGirth_saddleHolder.getSelectedQuantity() * 3) /
        this.bar_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.straightUpper_pipe.designQuantity =
      ((this.basicLevel.width * this.straightUpperLineNumber * this.basic.buildingNumber) /
        this.straightUpper_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE;

    this.straightUpperAndRafter_diagonalTHolder.designQuantity =
      this.straightUpperLineNumber *
      2 *
      this.basic.buildingNumber *
      rafterValleyQuantity *
      CONST.NUM_EXTRA_RATE_DIAGONAL_T_HOLDER;

    this.straightUpperAndRafterStraightUpper_normalHolder.designQuantity =
      this.straightUpperLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.straightUpperAndRafterRafter_normalHolder.designQuantity =
      this.straightUpperLineNumber *
      2 *
      this.basic.buildingNumber *
      rafterValleyQuantity *
      CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.straightUpperAndRafter_boltAndNut.designQuantity =
      this.straightUpperAndRafterStraightUpper_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.straightUpperAndBar_saddleHolder.designQuantity =
      this.straightUpperLineNumber *
      this.barLineNumber *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.straightBottom_pipe.designQuantity =
      (this.straightBottom_pipe.getConnectedPipeLength(this.straightLength, this.straightBottomLineNumber) /
        this.straightBottom_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE;

    if (this.straightLength > this.straightBottomPipeSample.specLength) {
      this.straightBottomAndStraightBottom_connectionPin.designQuantity =
        this.straightBottom_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.straightBottomAndStraightBottom_connectionPin.designQuantity = 0;
    }

    this.straightBottomAndFrontColumn_saddleHolder.designQuantity =
      this.straightBottomLineNumber * (this.basic.buildingNumber + 1);

    if (this.design.preference.prismEndpieceWeldingFlag === true) {
      let middle_welding_designQuantity: number = this.straightBottomLineNumber * 2;
      middle_welding_designQuantity += this.straightBottomLineNumber * (this.basic.buildingNumber - 1);
      middle_welding_designQuantity += this.straightBottomLineNumber * this.barLineNumber * this.basic.buildingNumber;
      this.straight_welding.designQuantity = middle_welding_designQuantity;
    } else {
      this.straightBottomAndColumnEdge_tClamp.designQuantity =
        this.straightBottomLineNumber * 2 * CONST.NUM_EXTRA_RATE_T_CLAMP;

      this.straightBottomAndColumnCenter_crossClamp.designQuantity =
        this.straightBottomLineNumber * (this.basic.buildingNumber - 1) * CONST.NUM_EXTRA_RATE_CROSS_CLAMP;

      this.straightBottomAndEndpieceBarBottom_crossClamp.designQuantity =
        this.straightBottomLineNumber *
        this.barLineNumber *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_CROSS_CLAMP;
    }

    this.straightBottomAndEndpieceBarBottom_saddleHolder.designQuantity =
      this.barLineNumber * this.straightBottomLineNumber * this.basic.buildingNumber;

    this.straightBottom_cSectionSteel.designQuantity =
      (this.straightBottom_cSectionSteel.getConnectedPipeLength(this.straightLength, this.straightBottomLineNumber) /
        this.straightBottom_cSectionSteel.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE;

    this.straight_screw.designQuantity =
      ((this.straightUpperAndBar_saddleHolder.getSelectedQuantity() * 3 +
        this.straightBottomAndStraightBottom_connectionPin.getSelectedQuantity() * 2 +
        this.straightBottomAndColumnEdge_tClamp.getSelectedQuantity() * 4) /
        this.straight_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 마구리 간살 최대 길이 <- 동고(기본정보)
   */
  public algorithm_barLengthMax(): void {
    this.barLengthMax = this.basicLevel.heightMax;
  }

  /**
   * 알고리즘: 총 마구리 간살 줄수 <- 마구리 간살 형태, 마구리 간살 줄수, 출입 동수
   */
  public algorithm_totalBarLineNumber(): void {
    const endpiecePosition: EndpiecePositionVL = <EndpiecePositionVL>this.position;
    let gateQuantity: number; // 출입동인 경우
    let noGateQuantity: number; // 출입동이 아닌 경우

    // 출입동이 아닌 동수
    let noGateBuildingNumber: number = this.basic.buildingNumber - endpiecePosition.gateBuildingNumber;
    if (noGateBuildingNumber < 0) {
      noGateBuildingNumber = 0;
    }
    if (this.barType === CONST.LB_BAR_TYPE_20_INTERLOCK_1) {
      if (this.basic.buildingNumber >= 2) {
        gateQuantity = Math.ceil(
          (this.barLineNumber - 1) * (endpiecePosition.gateBuildingNumber - (endpiecePosition.gateBuildingNumber - 1)),
        );
        noGateQuantity = Math.ceil(this.barLineNumber * (noGateBuildingNumber - (noGateBuildingNumber - 1)));
      } else {
        gateQuantity = Math.ceil((this.barLineNumber - 1) * endpiecePosition.gateBuildingNumber);
        noGateQuantity = Math.ceil(this.barLineNumber * noGateBuildingNumber);
      }
    } else if (this.barType === CONST.LB_BAR_TYPE_08_INTERLOCK_1) {
      if (this.barLineNumber === 0) {
        gateQuantity = 0;
        noGateQuantity = 0;
      } else {
        gateQuantity = Math.ceil((this.barLineNumber - 1) * endpiecePosition.gateBuildingNumber);
        noGateQuantity = Math.ceil(this.barLineNumber * noGateBuildingNumber);
      }
    }
    this.totalBarLineNumber = gateQuantity + noGateQuantity;
  }

  /**
   * 알고리즘: 마구리 간살 줄수 <- 마구리 간살 형태
   */
  public algorithm_barLineNumber(): void {
    if (this.barType === CONST.LB_BAR_TYPE_20_INTERLOCK_1) {
      this.barLineNumber = 5;
    } else if (this.barType === CONST.LB_BAR_TYPE_08_INTERLOCK_1) {
      this.barLineNumber = 0;
    }
  }

  /**
   * 알고리즘: 마구리 가로대 길이 <- 폭(기본정보), 동수(기본정보)
   */
  public algorithm_straightLength(): void {
    this.straightLength =
      this.basicLevel.width * this.basic.buildingNumber + this.design.preference.connectedPipeExtraLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [마구리 간살] 파이프 <- 마구리 간살 파이프 샘플
   */
  public algorithmSpec_bar_pipe(): void {
    this.bar_pipe.specs = this.barPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래] T 고정구 <- 마구리 간살 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterCenter_tHolder(): void {
    this.barAndRafterCenter_tHolder.specPipeType =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.barAndRafterCenter_tHolder.specCrossSize1 =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    this.barAndRafterCenter_tHolder.specCrossSize2 = this.barPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래 (1)] 일반 고정구 <- 마구리 간살 파이프 샘플
   */
  public algorithmSpec_studAndRafterCenterEndpiece_normalHolder(): void {
    this.barAndRafterCenterEndpiece_normalHolder.specPipeType = this.barPipeSample.specPipeType;
    this.barAndRafterCenterEndpiece_normalHolder.specCrossSize1 = this.barPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterCenterRafter_normalHolder(): void {
    this.barAndRafterCenterRafter_normalHolder.specPipeType =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.barAndRafterCenterRafter_normalHolder.specCrossSize1 =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_studAndRafterCenter_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [마구리 간살(중앙상단)] 파이프 <- 마구리 간살(중앙상단) 파이프 샘플
   */
  public algorithmSpec_studCenterUpper_pipe(): void {
    // this.barCenterUpper_pipe.specs = this.studCenterUpperPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 간살(중앙상단) + 중방] 새들 고정구 <- 마구리 간살(중앙상단) 파이프 샘플
   */
  public algorithmSpec_studCenterUpperAndMiddle_saddleHolder(): void {
    // this.barCenterUpperAndMiddle_saddleHolder.specPipeType = this.studCenterUpperPipeSample.specPipeType;
    // this.barCenterUpperAndMiddle_saddleHolder.specCrossSize1 = this.studCenterUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래] 대각T 고정구 <- 마구리 간살 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonal_diagonalTHolder(): void {
    this.barAndRafterDiagonal_diagonalTHolder.specPipeType =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.barAndRafterDiagonal_diagonalTHolder.specCrossSize1 =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    this.barAndRafterDiagonal_diagonalTHolder.specCrossSize2 = this.barPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래] 대각T 클램프 <- 마구리 간살 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonal_diagonalTClamp(): void {
    this.barAndRafterDiagonal_diagonalTClamp.specPipeType =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.barAndRafterDiagonal_diagonalTClamp.specCrossSize1 =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    this.barAndRafterDiagonal_diagonalTClamp.specCrossSize2 = this.barPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래 (1)] 일반 고정구 <- 마구리 간살 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonalEndpiece_normalHolder(): void {
    this.barAndRafterDiagonalEndpiece_normalHolder.specPipeType = this.barPipeSample.specPipeType;
    this.barAndRafterDiagonalEndpiece_normalHolder.specCrossSize1 = this.barPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonalRafter_normalHolder(): void {
    this.barAndRafterDiagonalRafter_normalHolder.specPipeType =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.barAndRafterDiagonalRafter_normalHolder.specCrossSize1 =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_studAndRafterDiagonal_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [마구리 간살 + 전후면 중방] 새들 고정구 <- 마구리 간살 파이프 샘플
   */
  public algorithmSpec_barAndMiddle_saddleHolder(): void {
    this.barAndMiddle_saddleHolder.specPipeType = this.barPipeSample.specPipeType;
    this.barAndMiddle_saddleHolder.specCrossSize1 = this.barPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 폭방향 바닥도리] 새들 고정구 <- 마구리 간살 파이프 샘플
   */
  public algorithmSpec_studAndWidthGirth_saddleHolder(): void {
    this.barAndWidthGirth_saddleHolder.specPipeType = this.barPipeSample.specPipeType;
    this.barAndWidthGirth_saddleHolder.specCrossSize1 = this.barPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살 + 폭방향 바닥도리] 고정 아시바 클램프 <- 마구리 간살 파이프 샘플, 폭방향 바닥도리 파이프 샘플
   */
  public algorithmSpec_studAndWidthGirth_fixAsibaClamp(): void {
    // 마구리 간살 == 48mm
    this.barAndWidthGirth_fixAsibaClamp.specPipeType = this.barPipeSample.specPipeType;
    this.barAndWidthGirth_fixAsibaClamp.specCrossSize1 = this.barPipeSample.specCrossSize;
    this.barAndWidthGirth_fixAsibaClamp.specCrossSize2 =
      this.struct.foundationWorkIL.level1.foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 간살] 나사 <- 공통 샘플
   */
  public algorithmSpec_stud_screw(): void {}

  /**
   * 규격 알고리즘: [마구리 가로대(상단)] 파이프 <- 마구리 가로대(상단) 샘플
   */
  public algorithmSpec_straightUpper_pipe(): void {
    this.straightUpper_pipe.specs = this.straightUpperPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(상단) + 서까래] 대각T 고정구 <- 마구리 가로대(상단) 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightUpperAndRafter_diagonalTHolder(): void {
    this.straightUpperAndRafter_diagonalTHolder.specPipeType = this.straightUpperPipeSample.specPipeType;
    this.straightUpperAndRafter_diagonalTHolder.specCrossSize1 = this.straightUpperPipeSample.specCrossSize;
    this.straightUpperAndRafter_diagonalTHolder.specCrossSize2 =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(상단) + 서까래(1)] 일반 고정구 <- 마구리 가로대(상단) 파이프 샘플
   */
  public algorithmSpec_straightUpperAndRafterStraightUpper_normalHolder(): void {
    this.straightUpperAndRafterStraightUpper_normalHolder.specPipeType = this.straightUpperPipeSample.specPipeType;
    this.straightUpperAndRafterStraightUpper_normalHolder.specCrossSize1 = this.straightUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(상단) + 서까래(2)] 일반 고정구 <- 서까래 파이프 샘플
   */
  public algorithmSpec_straightUpperAndRafterRafter_normalHolder(): void {
    this.straightUpperAndRafterRafter_normalHolder.specPipeType =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.straightUpperAndRafterRafter_normalHolder.specCrossSize1 =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(상단) + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_straightUpperAndRafter_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [마구리 가로대(상단) + 마구리 간살] 새들 고정구 <- 마구리 가로대(상단) 파이프 샘플
   */
  public algorithmSpec_straightUpperAndBar_saddleHolder(): void {
    this.straightUpperAndBar_saddleHolder.specPipeType = this.straightUpperPipeSample.specPipeType;
    this.straightUpperAndBar_saddleHolder.specCrossSize1 = this.straightUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(하단)] 파이프 <- 마구리 가로대 하단 파이프 샘플
   */
  public algorithmSpec_straightBottom_pipe(): void {
    this.straightBottom_pipe.specs = this.straightBottomPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(하단)] 연결핀 <- 마구리 가로대(하단) 샘플
   */
  public algorithmSpec_straightBottomAndStraightBottom_connectionPin(): void {
    this.straightBottomAndStraightBottom_connectionPin.specPipeType = this.straightBottomPipeSample.specPipeType;
    this.straightBottomAndStraightBottom_connectionPin.specCrossSize1 = this.straightBottomPipeSample.specCrossSize;
    this.straightBottomAndStraightBottom_connectionPin.specCrossSize2 = this.straightBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(하단) + 기둥] 새들 고정구 <- 마구리 가로대(하단) 샘플
   */
  public algorithmSpec_straightBottomAndFrontColumn_saddleHolder(): void {
    this.straightBottomAndFrontColumn_saddleHolder.specPipeType = this.straightBottomPipeSample.specPipeType;
    this.straightBottomAndFrontColumn_saddleHolder.specCrossSize1 = this.straightBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(하단) + 측면 기둥] T 클램프  <- 마구리 가로대(하단) 파이프 샘플, 모서리 기둥 파이프 샘플
   */
  public algorithmSpec_straightBottomAndColumnEdge_tClamp(): void {
    this.straightBottomAndColumnEdge_tClamp.specPipeType =
      this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.edgeColumnPipeSample.specPipeType;
    this.straightBottomAndColumnEdge_tClamp.specCrossSize1 = this.straightBottomPipeSample.specCrossSize;
    this.straightBottomAndColumnEdge_tClamp.specCrossSize2 = this.straightBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(하단) + 내부 기둥] 십자 클램프  <- 마구리 가로대(하단) 파이프 샘플, 전후면 기둥 파이프 샘플
   */
  public algorithmSpec_straightBottomAndColumnCenter_crossClamp(): void {
    this.straightBottomAndColumnCenter_crossClamp.specPipeType = this.straightBottomPipeSample.specPipeType;
    this.straightBottomAndColumnCenter_crossClamp.specCrossSize1 = this.straightBottomPipeSample.specCrossSize;
    this.straightBottomAndColumnCenter_crossClamp.specCrossSize2 =
      this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.frontAndBackColumnPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(하단) + 마구리 간살] 새들 고정구 <- 마구리 가로대(하단) 샘플
   */
  public algorithmSpec_straightBottomAndEndpieceBarBottom_saddleHolder(): void {
    this.straightBottomAndEndpieceBarBottom_saddleHolder.specPipeType = this.straightBottomPipeSample.specPipeType;
    this.straightBottomAndEndpieceBarBottom_saddleHolder.specCrossSize1 = this.straightBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대(하단) + 마구리 간살] 십자 클램프  <- 마구리 가로대(하단) 파이프 샘플, 마구리 간살 파이프 샘플
   */
  public algorithmSpec_straightBottomAndEndpieceBarBottom_crossClamp(): void {
    this.straightBottomAndEndpieceBarBottom_crossClamp.specPipeType = this.straightBottomPipeSample.specPipeType;
    this.straightBottomAndEndpieceBarBottom_crossClamp.specCrossSize1 = this.straightBottomPipeSample.specCrossSize;
    this.straightBottomAndEndpieceBarBottom_crossClamp.specCrossSize2 = this.barPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 가로대] 나사  <- 공통 부
   */
  public algorithmSpec_straight_screw(): void {}

  /**
   * 규격 알고리즘: [마구리 가로대] 용접  <- 공통 부품
   */
  public algorithmSpec_straight_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
