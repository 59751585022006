import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-10-12
 */
export class DesignUtil {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  /**
   * 설계 상품의 규격 및 상표 일괄 변경하기
   * - 설계 전체 상품에서 originItem과 같은 상품을 찾아서, editItem의 규격과 상표로 일괄 수정해줌
   * - (선택되지 않은 상품도 변경해줌)
   * @param originItem : productId, specs, brands 필요
   * @param editItem : specs, brands 필요
   * @return 변경된 품목 수
   */
  public static batchChangeDesignItemSpecAndBrand(originItem: Item, editItem: Item): number {
    const count: number = 0;
    const originProductId: string = originItem.productCompanyModel.productModel.productId;
    const originSpecs: string = originItem.specs;
    const originBrands: string = originItem.brands;
    const editSpecs: string = editItem.specs;
    const editBrands: string = editItem.brands;

    // 설계상의 모든 설계품목 조회
    for (const struct of [originItem.design.struct]) {
      if (struct.workAC != null) {
        for (const work of struct.workAC) {
          if (work.levelAC != null) {
            for (const level of work.levelAC) {
              if (level.positionAC != null) {
                for (const position of level.positionAC) {
                  if (position.partAC != null) {
                    for (const part of position.partAC) {
                      if (part.itemAC != null) {
                        for (const item of part.itemAC) {
                          if (
                            item.productCompanyModel.productModel.productId === originProductId &&
                            item.specs === originSpecs &&
                            item.brands === originBrands
                          ) {
                            item.specs = editSpecs;
                            item.brands = editBrands;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return count;
  }

  /**
   * 피복의 두께값 가져오기
   * @param coverType 피복 형태
   */
  public static getCoverThickness(coverType: string): number {
    if (coverType === CONST.LB_COVER_TYPE_VINYL) {
      return CONST.LB_COVER_THICKNESS_VALUE_VINYL;
    }
    if (coverType === CONST.LB_COVER_TYPE_WEAVING_FILM) {
      return CONST.LB_COVER_THICKNESS_VALUE_WEAVING_FILM;
    }
    if (coverType === CONST.LB_COVER_TYPE_SHADENET) {
      return CONST.LB_COVER_THICKNESS_VALUE_SHADENET;
    }
    if (coverType === CONST.LB_COVER_TYPE_TENT) {
      return CONST.LB_COVER_THICKNESS_VALUE_TENT;
    }
    if (coverType === CONST.LB_COVER_TYPE_NONWOVEN) {
      return CONST.LB_COVER_THICKNESS_VALUE_NONWOVEN;
    }
    if (coverType === CONST.LB_COVER_TYPE_CASHMILON) {
      return CONST.LB_COVER_THICKNESS_VALUE_CASHMILON;
    }
    if (coverType === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
      return CONST.LB_COVER_THICKNESS_VALUE_COLOR_LAGGING;
    }
    if (coverType === CONST.LB_COVER_TYPE_LAGGING) {
      return CONST.LB_COVER_THICKNESS_VALUE_LAGGING;
    }
    if (coverType === CONST.LB_COVER_TYPE_QUILTING) {
      return CONST.LB_COVER_THICKNESS_VALUE_QUILTING;
    }
    if (coverType === CONST.LB_COVER_TYPE_SCREEN) {
      return CONST.LB_COVER_THICKNESS_VALUE_SCREEN;
    }
    if (coverType === CONST.LB_COVER_TYPE_MAT) {
      return CONST.LB_COVER_THICKNESS_VALUE_MAT;
    }
    return 0;
  }
}
