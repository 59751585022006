import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDripBarb } from "vhows-design/src/object/design/item/list/ItemDripBarb";
import { ItemDripConnect } from "vhows-design/src/object/design/item/list/ItemDripConnect";
import { ItemDripValve } from "vhows-design/src/object/design/item/list/ItemDripValve";
import { ItemWateringConnect } from "vhows-design/src/object/design/item/list/ItemWateringConnect";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringValve } from "vhows-design/src/object/design/item/list/ItemWateringValve";
import { Part } from "vhows-design/src/object/design/base/Part";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { WateringPart_DripWatering } from "vhows-design/src/object/design/watering/watering/WateringPart_DripWatering";
import { WateringSample_DripConnectPePipe } from "vhows-design/src/object/design/watering/watering/WateringSample_DripConnectPePipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [WateringSample_DripConnectPePipe],
})
export class WateringPart_DripConnect extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public subPePipeSample: WateringSample_DripConnectPePipe; // 보조관 샘플

  // 아이템
  public normalSaddle_pePipeSaddle: ItemWateringConnect;
  public normalSaddle_femaleMaleBallValve: ItemWateringValve;
  public normalSaddle1hole_dripBarb: ItemDripBarb;
  public normalSaddle2hole_dripBarb: ItemDripBarb;
  public normalSaddle3hole_dripBarb: ItemDripBarb;
  public startSaddle1hole_dripStartSaddle: ItemDripBarb;
  public startSaddle2hole_dripStartSaddle: ItemDripBarb;
  public startSaddle3hole_dripStartSaddle: ItemDripBarb;
  public sub_pePipe: ItemWateringPipe;
  public subAndDrip_dripConnectSocket: ItemDripConnect;
  public sub_dripLineValve: ItemDripValve;
  public startValve_dripStartLineValve: ItemDripValve;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _connectType: string; // 연결 형태
  @jsonMember(String)
  public _barbMainType: string; // 주 미늘 형태
  @jsonMember(String)
  public _barbDiameter: string; // 미늘 지름
  @jsonMember(Number)
  public _subPipeLineNumber: number; // 보조관 줄수
  @jsonMember(Number)
  public _subPipeLength: number; // 보조관 길이

  /**
   * 연결 형태
   */
  public get connectType(): string {
    return this._connectType;
  }

  //
  public set connectType(value: string) {
    this._connectType = value;

    // 알고리즘
    this.algorithm_subPipeLength();
    this.algorithmPart();

    // 다른 파트
    (<WateringPosition>this.position).dripWateringPart.algorithm_dripPipeType();
  }

  /**
   * 주 미늘 형태
   */
  public get barbMainType(): string {
    return this._barbMainType;
  }

  //
  public set barbMainType(value: string) {
    this._barbMainType = value;

    // 알고리즘
    this.algorithm_subPipeLineNumber();
    this.algorithmPart();
  }

  /**
   * 미늘 지름
   */
  public get barbDiameter(): string {
    return this._barbDiameter;
  }

  //
  public set barbDiameter(value: string) {
    this._barbDiameter = value;

    // 알고리즘
    this.algorithmSpec_normalSaddle_pePipeSaddle();
    this.algorithmSpec_normalSaddle_femaleMaleBallValve();
    this.algorithmSpec_normalSaddle1hole_dripBarb();
    this.algorithmSpec_normalSaddle2hole_dripBarb();
    this.algorithmSpec_normalSaddle3hole_dripBarb();
    this.algorithmPart();
  }

  /**
   * 보조관 줄수
   */
  public get subPipeLineNumber(): number {
    return this._subPipeLineNumber;
  }

  //
  public set subPipeLineNumber(value: number) {
    this._subPipeLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 보조관 길이
   */
  public get subPipeLength(): number {
    return this._subPipeLength;
  }

  //
  public set subPipeLength(value: number) {
    this._subPipeLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.subPePipeSample = new WateringSample_DripConnectPePipe();

    this.sampleAC = [this.subPePipeSample];

    // 아이템
    this.normalSaddle_pePipeSaddle = new ItemWateringConnect();
    this.normalSaddle_femaleMaleBallValve = new ItemWateringValve();
    this.normalSaddle1hole_dripBarb = new ItemDripBarb();
    this.normalSaddle2hole_dripBarb = new ItemDripBarb();
    this.normalSaddle3hole_dripBarb = new ItemDripBarb();
    this.startSaddle1hole_dripStartSaddle = new ItemDripBarb();
    this.startSaddle2hole_dripStartSaddle = new ItemDripBarb();
    this.startSaddle3hole_dripStartSaddle = new ItemDripBarb();
    this.sub_pePipe = new ItemWateringPipe();
    this.subAndDrip_dripConnectSocket = new ItemDripConnect();
    this.sub_dripLineValve = new ItemDripValve();
    this.startValve_dripStartLineValve = new ItemDripValve();

    this.itemAC = [
      this.normalSaddle_pePipeSaddle,
      this.normalSaddle_femaleMaleBallValve,
      this.normalSaddle1hole_dripBarb,
      this.normalSaddle2hole_dripBarb,
      this.normalSaddle3hole_dripBarb,
      this.startSaddle1hole_dripStartSaddle,
      this.startSaddle2hole_dripStartSaddle,
      this.startSaddle3hole_dripStartSaddle,
      this.sub_pePipe,
      this.subAndDrip_dripConnectSocket,
      this.sub_dripLineValve,
      this.startValve_dripStartLineValve,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.subPePipeSample = <WateringSample_DripConnectPePipe>this.sampleAC[0];
    // 아이템
    this.normalSaddle_pePipeSaddle = <ItemWateringConnect>this.itemAC[0];
    this.normalSaddle_femaleMaleBallValve = <ItemWateringValve>this.itemAC[1];
    this.normalSaddle1hole_dripBarb = <ItemDripBarb>this.itemAC[2];
    this.normalSaddle2hole_dripBarb = <ItemDripBarb>this.itemAC[3];
    this.normalSaddle3hole_dripBarb = <ItemDripBarb>this.itemAC[4];
    this.startSaddle1hole_dripStartSaddle = <ItemDripBarb>this.itemAC[5];
    this.startSaddle2hole_dripStartSaddle = <ItemDripBarb>this.itemAC[6];
    this.startSaddle3hole_dripStartSaddle = <ItemDripBarb>this.itemAC[7];
    this.sub_pePipe = <ItemWateringPipe>this.itemAC[8];
    this.subAndDrip_dripConnectSocket = <ItemDripConnect>this.itemAC[9];
    this.sub_dripLineValve = <ItemDripValve>this.itemAC[10];
    this.startValve_dripStartLineValve = <ItemDripValve>this.itemAC[11];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param connectType: string 연결 형태
   * @param barbMainType: string 주 미늘 형태
   * @param barbDiameter: string 미늘 지름
   * @param subPipeLineNumber: number 보조관 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    connectType: string = "",
    barbMainType: string = "",
    barbDiameter: string = "",
    subPipeLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._connectType = connectType;
    this._barbMainType = barbMainType;
    this._barbDiameter = barbDiameter;
    this._subPipeLineNumber = subPipeLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.subPePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "보조관(주관~점적관)",
        "연질관, 13mm, 200m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalSaddle_pePipeSaddle.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE_SADDLE,
        CONST.ITEM_NAME_PE_PIPE_SADDLE,
        "새들 연결",
        "PE, 일반, 50mm, 20mm",
      );
      this.normalSaddle_femaleMaleBallValve.setDefaultData(
        1,
        CONST.ITEM_ID_FEMALE_MALE_BALL_VALVE,
        CONST.ITEM_NAME_FEMALE_MALE_BALL_VALVE,
        "새들 연결",
        "PE, 일반, 20mm, 20mm",
      );
      this.normalSaddle1hole_dripBarb.setDefaultData(
        2,
        CONST.ITEM_ID_DRIP_BARB,
        CONST.ITEM_NAME_DRIP_BARB,
        "새들 연결(1구)",
        "일반, 1구, 20mm, 13mm",
      );
      this.normalSaddle2hole_dripBarb.setDefaultData(
        3,
        CONST.ITEM_ID_DRIP_BARB,
        CONST.ITEM_NAME_DRIP_BARB,
        "새들 연결(2구)",
        "일반, 2구, 20mm, 13mm",
      );
      this.normalSaddle3hole_dripBarb.setDefaultData(
        4,
        CONST.ITEM_ID_DRIP_BARB,
        CONST.ITEM_NAME_DRIP_BARB,
        "새들 연결(3구)",
        "일반, 3구, 20mm, 13mm",
      );
      this.startSaddle1hole_dripStartSaddle.setDefaultData(
        5,
        CONST.ITEM_ID_DRIP_START_SADDLE,
        CONST.ITEM_NAME_DRIP_START_SADDLE,
        "스타트 새들 연결(1구)",
        "일반, 1구, 50mm, 13mm",
      );
      this.startSaddle2hole_dripStartSaddle.setDefaultData(
        6,
        CONST.ITEM_ID_DRIP_START_SADDLE,
        CONST.ITEM_NAME_DRIP_START_SADDLE,
        "스타트 새들 연결(2구)",
        "일반, 2구, 50mm, 13mm",
      );
      this.startSaddle3hole_dripStartSaddle.setDefaultData(
        7,
        CONST.ITEM_ID_DRIP_START_SADDLE,
        CONST.ITEM_NAME_DRIP_START_SADDLE,
        "스타트 새들 연결(3구)",
        "일반, 3구, 50mm, 13mm",
      );
      this.sub_pePipe.setDefaultData(
        8,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "보조관(주관~점적관)",
        "연질관, 13mm, 200m",
      );
      this.subAndDrip_dripConnectSocket.setDefaultData(
        9,
        CONST.ITEM_ID_DRIP_CONNECT_SOCKET,
        CONST.ITEM_NAME_DRIP_CONNECT_SOCKET,
        "보조관(주관~점적관)",
        "일반, 일자, 13mm",
      );
      this.sub_dripLineValve.setDefaultData(
        10,
        CONST.ITEM_ID_DRIP_LINE_VALVE,
        CONST.ITEM_NAME_DRIP_LINE_VALVE,
        "보조관(주관~점적관)",
        "일반, 밸브, 13mm",
      );
      this.startValve_dripStartLineValve.setDefaultData(
        11,
        CONST.ITEM_ID_DRIP_START_LINE_VALVE,
        CONST.ITEM_NAME_DRIP_START_LINE_VALVE,
        "패스트 라인 밸브 연결",
        "일반, 밸브, 13mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_subPipeLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const dripPipePart: WateringPart_DripWatering = (<WateringPosition>this.position).dripWateringPart;

    /// //////// 선택, 가시성 ///////////

    if (this.connectType === CONST.LB_WATERING_DRIP_CONNECT_TYPE_SADDLE_BALL_VALVE_BARB) {
      this.normalSaddle_pePipeSaddle.selected = true;
      this.normalSaddle_pePipeSaddle.visible = true;
      this.normalSaddle_femaleMaleBallValve.selected = true;
      this.normalSaddle_femaleMaleBallValve.visible = true;
      this.normalSaddle1hole_dripBarb.selected = true;
      this.normalSaddle1hole_dripBarb.visible = true;
      this.normalSaddle2hole_dripBarb.selected = true;
      this.normalSaddle2hole_dripBarb.visible = true;
      this.normalSaddle3hole_dripBarb.selected = true;
      this.normalSaddle3hole_dripBarb.visible = true;
      this.startSaddle1hole_dripStartSaddle.selected = false;
      this.startSaddle1hole_dripStartSaddle.visible = false;
      this.startSaddle2hole_dripStartSaddle.selected = false;
      this.startSaddle2hole_dripStartSaddle.visible = false;
      this.startSaddle3hole_dripStartSaddle.selected = false;
      this.startSaddle3hole_dripStartSaddle.visible = false;
      this.sub_pePipe.selected = true;
      this.sub_pePipe.visible = true;
      this.subAndDrip_dripConnectSocket.selected = true;
      this.subAndDrip_dripConnectSocket.visible = true;
      this.sub_dripLineValve.selected = false;
      this.sub_dripLineValve.visible = false;
      this.startValve_dripStartLineValve.selected = false;
      this.startValve_dripStartLineValve.visible = false;
    } else if (this.connectType === CONST.LB_WATERING_DRIP_CONNECT_TYPE_SADDLE_BARB_LINE_VALVE) {
      this.normalSaddle_pePipeSaddle.selected = true;
      this.normalSaddle_pePipeSaddle.visible = true;
      this.normalSaddle_femaleMaleBallValve.selected = false;
      this.normalSaddle_femaleMaleBallValve.visible = false;
      this.normalSaddle1hole_dripBarb.selected = true;
      this.normalSaddle1hole_dripBarb.visible = true;
      this.normalSaddle2hole_dripBarb.selected = true;
      this.normalSaddle2hole_dripBarb.visible = true;
      this.normalSaddle3hole_dripBarb.selected = true;
      this.normalSaddle3hole_dripBarb.visible = true;
      this.startSaddle1hole_dripStartSaddle.selected = false;
      this.startSaddle1hole_dripStartSaddle.visible = false;
      this.startSaddle2hole_dripStartSaddle.selected = false;
      this.startSaddle2hole_dripStartSaddle.visible = false;
      this.startSaddle3hole_dripStartSaddle.selected = false;
      this.startSaddle3hole_dripStartSaddle.visible = false;
      this.sub_pePipe.selected = true;
      this.sub_pePipe.visible = true;
      this.subAndDrip_dripConnectSocket.selected = false;
      this.subAndDrip_dripConnectSocket.visible = false;
      this.sub_dripLineValve.selected = true;
      this.sub_dripLineValve.visible = true;
      this.startValve_dripStartLineValve.selected = false;
      this.startValve_dripStartLineValve.visible = false;
    } else if (this.connectType === CONST.LB_WATERING_DRIP_CONNECT_TYPE_START_SADDLE_LINE_VALVE) {
      this.normalSaddle_pePipeSaddle.selected = false;
      this.normalSaddle_pePipeSaddle.visible = false;
      this.normalSaddle_femaleMaleBallValve.selected = false;
      this.normalSaddle_femaleMaleBallValve.visible = false;
      this.normalSaddle1hole_dripBarb.selected = false;
      this.normalSaddle1hole_dripBarb.visible = false;
      this.normalSaddle2hole_dripBarb.selected = false;
      this.normalSaddle2hole_dripBarb.visible = false;
      this.normalSaddle3hole_dripBarb.selected = false;
      this.normalSaddle3hole_dripBarb.visible = false;
      this.startSaddle1hole_dripStartSaddle.selected = true;
      this.startSaddle1hole_dripStartSaddle.visible = true;
      this.startSaddle2hole_dripStartSaddle.selected = true;
      this.startSaddle2hole_dripStartSaddle.visible = true;
      this.startSaddle3hole_dripStartSaddle.selected = true;
      this.startSaddle3hole_dripStartSaddle.visible = true;
      this.sub_pePipe.selected = true;
      this.sub_pePipe.visible = true;
      this.subAndDrip_dripConnectSocket.selected = false;
      this.subAndDrip_dripConnectSocket.visible = false;
      this.sub_dripLineValve.selected = true;
      this.sub_dripLineValve.visible = true;
      this.startValve_dripStartLineValve.selected = false;
      this.startValve_dripStartLineValve.visible = false;
    } else if (this.connectType === CONST.LB_WATERING_DRIP_CONNECT_TYPE_START_LINE_VALVE) {
      this.normalSaddle_pePipeSaddle.selected = false;
      this.normalSaddle_pePipeSaddle.visible = false;
      this.normalSaddle_femaleMaleBallValve.selected = false;
      this.normalSaddle_femaleMaleBallValve.visible = false;
      this.normalSaddle1hole_dripBarb.selected = false;
      this.normalSaddle1hole_dripBarb.visible = false;
      this.normalSaddle2hole_dripBarb.selected = false;
      this.normalSaddle2hole_dripBarb.visible = false;
      this.normalSaddle3hole_dripBarb.selected = false;
      this.normalSaddle3hole_dripBarb.visible = false;
      this.startSaddle1hole_dripStartSaddle.selected = false;
      this.startSaddle1hole_dripStartSaddle.visible = false;
      this.startSaddle2hole_dripStartSaddle.selected = false;
      this.startSaddle2hole_dripStartSaddle.visible = false;
      this.startSaddle3hole_dripStartSaddle.selected = false;
      this.startSaddle3hole_dripStartSaddle.visible = false;
      this.sub_pePipe.selected = false;
      this.sub_pePipe.visible = false;
      this.subAndDrip_dripConnectSocket.selected = false;
      this.subAndDrip_dripConnectSocket.visible = false;
      this.sub_dripLineValve.selected = false;
      this.sub_dripLineValve.visible = false;
      this.startValve_dripStartLineValve.selected = true;
      this.startValve_dripStartLineValve.visible = true;
    }

    /// ////////  수치 ///////////

    // 주 미늘 수량
    let barbMain1Quantity: number = 0;
    let barbMain2Quantity: number = 0;
    let barbMain3Quantity: number = 0;
    let barbMainNumber: number;
    if (this.barbMainType === CONST.LB_WATERING_DRIP_BARB_MAIN_TYPE_1_HOLE) {
      barbMainNumber = 1;
      barbMain1Quantity =
        Math.floor(dripPipePart.dripPipeLineNumber / barbMainNumber) * dripPipePart.wateringBuildingNumber;
    } else if (this.barbMainType === CONST.LB_WATERING_DRIP_BARB_MAIN_TYPE_2_HOLE) {
      barbMainNumber = 2;
      barbMain2Quantity =
        Math.floor(dripPipePart.dripPipeLineNumber / barbMainNumber) * dripPipePart.wateringBuildingNumber;
    } else if (this.barbMainType === CONST.LB_WATERING_DRIP_BARB_MAIN_TYPE_3_HOLE) {
      barbMainNumber = 3;
      barbMain3Quantity =
        Math.floor(dripPipePart.dripPipeLineNumber / barbMainNumber) * dripPipePart.wateringBuildingNumber;
    }
    // 보조 미늘 수량
    let barbSub1Quantity: number = 0;
    let barbSub2Quantity: number = 0;
    if (dripPipePart.dripPipeLineNumber % barbMainNumber === 1) {
      barbSub1Quantity = 1 * dripPipePart.wateringBuildingNumber;
    } else if (dripPipePart.dripPipeLineNumber % barbMainNumber === 2) {
      barbSub2Quantity = 1 * dripPipePart.wateringBuildingNumber;
    }

    /// ////////  수량 ///////////

    this.normalSaddle_pePipeSaddle.designQuantity = this.subPipeLineNumber * dripPipePart.wateringBuildingNumber;

    this.normalSaddle_femaleMaleBallValve.designQuantity = this.subPipeLineNumber * dripPipePart.wateringBuildingNumber;

    this.normalSaddle1hole_dripBarb.designQuantity = barbMain1Quantity + barbSub1Quantity;

    this.normalSaddle2hole_dripBarb.designQuantity = barbMain2Quantity + barbSub2Quantity;

    this.normalSaddle3hole_dripBarb.designQuantity = barbMain3Quantity;

    this.startSaddle1hole_dripStartSaddle.designQuantity = barbMain1Quantity + barbSub1Quantity;

    this.startSaddle2hole_dripStartSaddle.designQuantity = barbMain2Quantity + barbSub2Quantity;

    this.startSaddle3hole_dripStartSaddle.designQuantity = barbMain3Quantity;

    this.sub_pePipe.designQuantity =
      (this.subPipeLength * dripPipePart.wateringBuildingNumber) / this.sub_pePipe.specLength;

    this.subAndDrip_dripConnectSocket.designQuantity =
      dripPipePart.dripPipeLineNumber * dripPipePart.wateringBuildingNumber;

    this.sub_dripLineValve.designQuantity = dripPipePart.dripPipeLineNumber * dripPipePart.wateringBuildingNumber;

    this.startValve_dripStartLineValve.designQuantity =
      dripPipePart.dripPipeLineNumber * dripPipePart.wateringBuildingNumber;
  }

  /**
   * 알고리즘: 보조관 줄수 <- 점적관 줄수, 주 미늘 형태
   */
  public algorithm_subPipeLineNumber(): void {
    if (this.barbMainType === CONST.LB_WATERING_DRIP_BARB_MAIN_TYPE_1_HOLE) {
      this.subPipeLineNumber = Math.ceil((<WateringPosition>this.position).dripWateringPart.dripPipeLineNumber / 1);
    } else if (this.barbMainType === CONST.LB_WATERING_DRIP_BARB_MAIN_TYPE_2_HOLE) {
      this.subPipeLineNumber = Math.ceil((<WateringPosition>this.position).dripWateringPart.dripPipeLineNumber / 2);
    } else if (this.barbMainType === CONST.LB_WATERING_DRIP_BARB_MAIN_TYPE_3_HOLE) {
      this.subPipeLineNumber = Math.ceil((<WateringPosition>this.position).dripWateringPart.dripPipeLineNumber / 3);
    }
  }

  /**
   * 알고리즘: 보조관 길이 <- 형태(주관), 주관2 위치(주관), 보조관 줄수
   */
  public algorithm_subPipeLength(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (this.connectType === CONST.LB_WATERING_DRIP_CONNECT_TYPE_START_LINE_VALVE) {
      this.subPipeLength = 0;
    } else {
      if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
        if (mainpipePosition.peMain2Part.main2Position === CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_LOWER) {
          this.subPipeLength = 1 + 1.5 * (<WateringPosition>this.position).dripWateringPart.dripPipeLineNumber;
        } else if (mainpipePosition.peMain2Part.main2Position === CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_UPPER) {
          this.subPipeLength = 1 + 1.5 * (<WateringPosition>this.position).dripWateringPart.dripPipeLineNumber;
        }
      } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
        this.subPipeLength = 1 + 1.5 * (<WateringPosition>this.position).dripWateringPart.dripPipeLineNumber;
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [새들 연결] 관 새들 <- 형태(주관), 주관2 샘플(중앙공급형,개별공급형), 미늘 지름
   */
  public algorithmSpec_normalSaddle_pePipeSaddle(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.normalSaddle_pePipeSaddle.specDiameter1 = mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.normalSaddle_pePipeSaddle.specDiameter1 = mainpipePosition.peMain2Part.main2EachPePipeSample.specDiameter;
    }
    this.normalSaddle_pePipeSaddle.specDiameter2 = this.barbDiameter;
  }

  /**
   * 규격 알고리즘: [새들 연결] 암숫나사 볼 밸브 <- 미늘 지름
   */
  public algorithmSpec_normalSaddle_femaleMaleBallValve(): void {
    this.normalSaddle_femaleMaleBallValve.specDiameter1 = this.barbDiameter;
    this.normalSaddle_femaleMaleBallValve.specDiameter2 = this.barbDiameter;
  }

  /**
   * 규격 알고리즘: [새들 연결(1구)] 점적 미늘 <- 미늘 지름
   */
  public algorithmSpec_normalSaddle1hole_dripBarb(): void {
    this.normalSaddle1hole_dripBarb.specDiameter1 = this.barbDiameter;
  }

  /**
   * 규격 알고리즘: [새들 연결(2구)] 점적 미늘 <- 미늘 지름
   */
  public algorithmSpec_normalSaddle2hole_dripBarb(): void {
    this.normalSaddle2hole_dripBarb.specDiameter1 = this.barbDiameter;
  }

  /**
   * 규격 알고리즘: [새들 연결(3구)] 점적 미늘 <- 미늘 지름
   */
  public algorithmSpec_normalSaddle3hole_dripBarb(): void {
    this.normalSaddle3hole_dripBarb.specDiameter1 = this.barbDiameter;
  }

  /**
   * 규격 알고리즘: [스타트 새들 연결(1구)] 점적 스타트 새들 <- 형태(주관), 주관2 샘플(중앙공급형,개별공급형)
   */
  public algorithmSpec_startSaddle1hole_dripStartSaddle(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.startSaddle1hole_dripStartSaddle.specDiameter1 =
        mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.startSaddle1hole_dripStartSaddle.specDiameter1 =
        mainpipePosition.peMain2Part.main2EachPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [스타트 새들 연결(2구)] 점적 스타트 새들 <- 형태(주관), 주관2 샘플(중앙공급형,개별공급형)
   */
  public algorithmSpec_startSaddle2hole_dripStartSaddle(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.startSaddle2hole_dripStartSaddle.specDiameter1 =
        mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.startSaddle2hole_dripStartSaddle.specDiameter1 =
        mainpipePosition.peMain2Part.main2EachPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [스타트 새들 연결(3구)] 점적 스타트 새들 <- 형태(주관), 주관2 샘플(중앙공급형,개별공급형)
   */
  public algorithmSpec_startSaddle3hole_dripStartSaddle(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.startSaddle3hole_dripStartSaddle.specDiameter1 =
        mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.startSaddle3hole_dripStartSaddle.specDiameter1 =
        mainpipePosition.peMain2Part.main2EachPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [보조관(주관~점적관)] PE관 <- 보조관 샘플
   */
  public algorithmSpec_sub_pePipe(): void {
    this.sub_pePipe.specs = this.subPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [보조관(주관~점적관)] 점적 연결 소켓 <- 없음
   */
  public algorithmSpec_subAndDrip_dripConnectSocket(): void {}

  /**
   * 규격 알고리즘: [보조관(주관~점적관)] 점적 라인 밸브 <- 없음
   */
  public algorithmSpec_sub_dripLineValve(): void {}

  /**
   * 규격 알고리즘: [보조관(주관~점적관)] 점적 스타트 라인 밸브 <- 없음
   */
  public algorithmSpec_startValve_dripStartLineValve(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
