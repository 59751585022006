import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { TrunkPart_Straight } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Straight";
import { TrunkPart_Column } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Column";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-23
 */
@jsonObject({
  knownTypes: [TrunkPart_Rafter, TrunkPart_Straight, TrunkPart_Column],
})
export class TrunkPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public rafterPart: TrunkPart_Rafter;
  public straightPart: TrunkPart_Straight;
  public columnPart: TrunkPart_Column;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  get rafterCrossSize(): string {
    let specCrossSize: string = null;

    for (let i: number = 0; i <= this.struct.trunkWork.levelAC.length; i++) {
      specCrossSize = (<TrunkLevel>this.struct.trunkWork.levelAC[i]).trunkPosition.rafterPart.rafterPipeSample
        .specCrossSize;
      return specCrossSize;
    }
    return specCrossSize;
  }

  get rafterLength(): number {
    let length: number = 0;

    for (let i: number = 0; i <= this.struct.trunkWork.levelAC.length; i++) {
      length = (<TrunkLevel>this.struct.trunkWork.levelAC[i]).trunkPosition.rafterPart.rafterTotalLength;
      return length;
    }
    return length;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _rafterType: string; // 서까래 형태

  /**
   * 서까래 형태
   */
  public get rafterType(): string {
    return this._rafterType;
  }

  public set rafterType(value: string) {
    this._rafterType = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    this._type = value;

    // 다른 중수
    if (this.level.index === 0) {
      this.algorithm_typeByLevel1();
    }

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 마구리 샛기둥 줄수
    this.struct.endpieceWork.level1.frontPosition.studPart.algorithm_studLineNumber();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.rafterPart = new TrunkPart_Rafter();
    this.straightPart = new TrunkPart_Straight();
    this.columnPart = new TrunkPart_Column();

    this.partAC = [this.rafterPart, this.straightPart, this.columnPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.rafterPart = <TrunkPart_Rafter>this.partAC[0];
    this.straightPart = <TrunkPart_Straight>this.partAC[1];
    this.columnPart = <TrunkPart_Column>this.partAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param rafterType: string 서까래 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    rafterType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._rafterType = rafterType;

    if (this.level.index === 0) {
      // 서까래
      this.rafterPart.setDefaultData(
        CONST.INDEX_SG_TRUNK_RAFTER,
        true,
        false,
        true,
        CONST.LB_TRUNK_RAFTER,
        "",
        0.6,
        false,
        1.3,
        2.0,
        false,
        CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_PINNED,
        1.0,
        0.6,
        4,
      );

      // 가로대
      this.straightPart.setDefaultData(
        CONST.INDEX_SG_TRUNK_STRAIGHT,
        true,
        false,
        true,
        CONST.LB_TRUNK_STRAIGHT,
        "",
        3,
        0,
        2,
        0,
        2,
        false,
        CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET,
        1,
        2,
        2,
        false,
      );

      // 기둥
      this.columnPart.setDefaultData(
        CONST.INDEX_SG_TRUNK_COLUMN,
        false,
        false,
        false,
        CONST.LB_TRUNK_COLUMN,
        "",
        3,
        3,
        true,
        true,
        6,
        true,
        true,
      );
    } else {
      // 서까래
      this.rafterPart.setDefaultData(
        CONST.INDEX_SG_TRUNK_RAFTER,
        true,
        false,
        true,
        CONST.LB_TRUNK_RAFTER,
        "",
        1.5,
        false,
        1.3,
        2.0,
        false,
        CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_PINNED,
        1.0,
        2.0,
        0,
      );

      // 가로대
      this.straightPart.setDefaultData(
        CONST.INDEX_SG_TRUNK_STRAIGHT,
        true,
        false,
        true,
        CONST.LB_TRUNK_STRAIGHT,
        "",
        1,
        0,
        2,
        0,
        0,
        false,
        CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET,
        1,
        2,
        0,
        false,
      );

      // 기둥
      this.columnPart.setDefaultData(
        CONST.INDEX_SG_TRUNK_COLUMN,
        false,
        false,
        false,
        CONST.LB_TRUNK_COLUMN,
        "",
        3,
        3,
        true,
        true,
        6,
        true,
        true,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 2) {
      // 기둥 추가
      const columnPart: TrunkPart_Column = new TrunkPart_Column();
      columnPart.setAssociation(design, struct, work, level, this);
      columnPart.setDefaultData(
        CONST.INDEX_SG_TRUNK_COLUMN,
        false,
        false,
        false,
        CONST.LB_TRUNK_COLUMN,
        "",
        3,
        3,
        true,
        true,
        6,
        true,
        true,
      );
      this.partAC.push(columnPart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_TRUNK_TYPE_ARCH) {
      this.rafterPart.selected = true;
      this.rafterPart.visible = true;
      this.straightPart.selected = true;
      this.straightPart.visible = true;
      this.columnPart.selected = false;
      this.columnPart.visible = false;
    } else if (this.type === CONST.LB_TRUNK_TYPE_ARCH_AND_COLUMN) {
      if (this.level.index === 0) {
        this.rafterPart.selected = true;
        this.rafterPart.visible = true;
        this.straightPart.selected = true;
        this.straightPart.visible = true;
        this.columnPart.selected = true;
        this.columnPart.visible = true;
      } else {
        this.rafterPart.selected = true;
        this.rafterPart.visible = true;
        this.straightPart.selected = true;
        this.straightPart.visible = true;
        this.columnPart.selected = false;
        this.columnPart.visible = false;
      }
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(1중)
   */
  public algorithm_typeByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.trunkWork.levelAC.length; l++) {
        (this.work.levelAC[l] as TrunkLevel).trunkPosition.type = this.type;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
