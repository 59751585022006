import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCordWire } from "vhows-design/src/object/design/item/list/ItemCordWire";
import { ItemPlasticGutter } from "vhows-design/src/object/design/item/list/ItemPlasticGutter";
import { ItemWeavingFilm } from "vhows-design/src/object/design/item/list/ItemWeavingFilm";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { WateringSample_ScreenPlasticGutter } from "vhows-design/src/object/design/watering/watering/WateringSample_ScreenPlasticGutter";
import { WateringSample_ScreenWeavingFilm } from "vhows-design/src/object/design/watering/watering/WateringSample_ScreenWeavingFilm";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [WateringSample_ScreenPlasticGutter, WateringSample_ScreenWeavingFilm],
})
export class WateringPart_ScreenGutter extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public weavingFilmSample: WateringSample_ScreenWeavingFilm; // 직조 필름 샘플
  public plasticGutterSample: WateringSample_ScreenPlasticGutter; // 플라스틱 물받이 샘플

  // 아이템
  public screen_weavingFilm: ItemWeavingFilm; // 동적 생성
  public screen_plasticGutter: ItemPlasticGutter; // 동적 생성
  public screen_cord: ItemCordWire;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 길이 AC */
  public specLengthAC: number[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wateringBuildingNumber: number = 0; // 관수 동수
  @jsonMember(String)
  public _screenGutterType: string; // 수막 물받이 형태

  /**
   * 관수 동수
   */
  public get wateringBuildingNumber(): number {
    return this._wateringBuildingNumber;
  }

  //
  public set wateringBuildingNumber(value: number) {
    this._wateringBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 수막 물받이 형태
   */
  public get screenGutterType(): string {
    return this._screenGutterType;
  }

  //
  public set screenGutterType(value: string) {
    this._screenGutterType = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.weavingFilmSample = new WateringSample_ScreenWeavingFilm();
    this.plasticGutterSample = new WateringSample_ScreenPlasticGutter();

    this.sampleAC = [this.weavingFilmSample, this.plasticGutterSample];

    // 아이템
    this.screen_cord = new ItemCordWire();

    this.itemAC = [
      // 동적생성
      this.screen_cord,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.weavingFilmSample = <WateringSample_ScreenWeavingFilm>this.sampleAC[0];
    this.plasticGutterSample = <WateringSample_ScreenPlasticGutter>this.sampleAC[1];
    // 아이템
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemCordWire) {
        this.screen_cord = <ItemCordWire>item;
      }
    }

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param screenGutterType: string 수막 물받이 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    screenGutterType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._screenGutterType = screenGutterType;

    // 샘플
    if (this.level.index >= 0) {
      this.weavingFilmSample.setDefaultData(
        0,
        CONST.ITEM_ID_WEAVING_FILM,
        CONST.ITEM_NAME_WEAVING_FILM,
        "수막 물받이",
        "아그론, 투명, 80cm, 49m, 양끈, 0회",
        "미소텍, 아그론, 400cm간격",
      );
      this.plasticGutterSample.setDefaultData(
        1,
        CONST.ITEM_ID_PLASTIC_GUTTER,
        CONST.ITEM_NAME_PLASTIC_GUTTER,
        "수막 물받이",
        "일반, 0.8T, 100cm, 49m, 타공",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
      this.screen_cord.setDefaultData(
        0,
        CONST.ITEM_ID_CORD,
        CONST.ITEM_NAME_CORD,
        "수막 물받이 고정",
        "코드사, 실, 3mm, 800m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    /// //////// 선언 ///////////

    let length: number;
    let i: number;

    /// //////// 생성, 선택, 가시성, 수량 ///////////

    if (this.basic.isVariableLength === true) {
      this.specLengthAC = new Array();
      let basicBuilding: BasicBuilding;
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.specLengthAC.push(basicBuilding.length);
      }
    }

    if (this.screenGutterType === CONST.LB_WATERING_SCREEN_GUTTER_TYPE_WEAVING_FILM) {
      // 직조 필름
      let screen_weavingFilm: ItemWeavingFilm;
      // 가변길이인 경우
      if (this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        length = this.basic.buildingNumber;
        for (i = 0; i < length; i++) {
          screen_weavingFilm = ItemWeavingFilm.getNewDesignItemBySample(this, this.weavingFilmSample);
          screen_weavingFilm.purpose = `${i + 1}번동 ${screen_weavingFilm.purpose}`;
          screen_weavingFilm.specLength = this.specLengthAC[i];
          screen_weavingFilm.designQuantity = 2;

          this.itemAC.push(screen_weavingFilm);
        }
      } else {
        // 동수만큼 수량 입력
        screen_weavingFilm = ItemWeavingFilm.getNewDesignItemBySample(this, this.weavingFilmSample);
        screen_weavingFilm.specLength = this.basicLevel.length;
        screen_weavingFilm.designQuantity = 2 * this.wateringBuildingNumber;

        this.itemAC.push(screen_weavingFilm);
      }
    } else if (this.screenGutterType === CONST.LB_WATERING_SCREEN_GUTTER_TYPE_PLASTIC) {
      // 플라스틱 물받이
      let screen_plasticGutter: ItemPlasticGutter;
      // 가변길이인 경우
      if (this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        length = this.basic.buildingNumber;
        for (i = 0; i < length; i++) {
          screen_plasticGutter = ItemPlasticGutter.getNewDesignItemBySample(this, this.plasticGutterSample);
          screen_plasticGutter.purpose = `${i + 1}번동 ${screen_plasticGutter.purpose}`;
          screen_plasticGutter.specLength = this.specLengthAC[i];
          screen_plasticGutter.designQuantity = 2;

          this.itemAC.push(screen_plasticGutter);
        }
      } else {
        // 동수만큼 수량 입력
        screen_plasticGutter = ItemPlasticGutter.getNewDesignItemBySample(this, this.plasticGutterSample);
        screen_plasticGutter.specLength = this.basicLevel.length;
        screen_plasticGutter.designQuantity = 2 * this.wateringBuildingNumber;

        this.itemAC.push(screen_plasticGutter);
      }
    }

    // 끈
    if (this.screenGutterType === CONST.LB_WATERING_SCREEN_GUTTER_TYPE_WEAVING_FILM) {
      this.screen_cord.selected = false;
    } else if (this.screenGutterType === CONST.LB_WATERING_SCREEN_GUTTER_TYPE_PLASTIC) {
      this.screen_cord.selected = true;
    }
    this.screen_cord.visible = true;
    this.screen_cord.designQuantity =
      (this.basicLevel.length * 2 * this.wateringBuildingNumber) / this.screen_cord.specLength;

    this.itemAC.push(this.screen_cord);
  }

  /**
   * 알고리즘: 관수 동수 <- 관수 동수(수막 미니클러 파트)
   */
  public algorithm_wateringBuildingNumber(): void {
    this.wateringBuildingNumber = (<WateringPosition>this.position).miniklerScreenPart.wateringBuildingNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [수막 물받이] 직조 필름 <- 직조 필름 샘플
   */
  public algorithmSpec_screen_weavingFilm(): void {
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemWeavingFilm) {
        (<ItemWeavingFilm>item).specsList = this.weavingFilmSample.specsList;
        (<ItemWeavingFilm>item).specWidth = this.weavingFilmSample.specWidth;
        (<ItemWeavingFilm>item).specStringType = this.weavingFilmSample.specStringType;
      }
    }
  }

  /**
   * 규격 알고리즘: [수막 물받이] 플라스틱 물받이 <- 플라스틱 물받이 샘플
   */
  public algorithmSpec_screen_plasticGutter(): void {
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemPlasticGutter) {
        (<ItemPlasticGutter>item).specsList = this.plasticGutterSample.specsList;
        (<ItemPlasticGutter>item).specWidth = this.plasticGutterSample.specWidth;
      }
    }
  }

  /**
   * 규격 알고리즘: [수막 물받이 고정] 끈 <- 없음
   */
  public algorithmSpec_screen_cord(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
