import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SwitcherPartIL_Support } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Support";
import { SwitcherPartIL_Power } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Power";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject
export class SwitcherSampleIL_DiagonalSupAssistPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const supportPart: SwitcherPartIL_Support = <SwitcherPartIL_Support>this.part;
    supportPart.algorithmSpec_diagonalAssist_pipe();
    supportPart.algorithmSpec_diagonal_supportFixingPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const power1Part: SwitcherPartIL_Power = (<SwitcherPositionIL>this.position).power1Part;
    power1Part.powerSwitcherSample.algorithmSpec();
    power1Part.powerSwitcherSample.algorithmSpec_supportCoupling();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<SwitcherPositionIL>this.level.positionAC[p]).supportPart.diagonalSupportAssistPipeSample.specs = this.specs;
    }

    // 다른 중수
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        // 해당 중수의 측면 위치만 바꿔줘도 전체 위치의 규격이 바뀌게됨
        (<SwitcherLevelIL>this.work.levelAC[l]).columnPosition.supportPart.diagonalSupportAssistPipeSample.specs =
          this.specs;
      }
    }

    /// //////// 외부 ///////////

    // 천창 개폐
    if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.struct.skySwitchWorkIL.level1.skylightPosition.supDiagonalPart.diagonalSupportAssistPipeSample.algorithmSpec();
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
