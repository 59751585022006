import { jsonObject } from "typedjson";

import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { CurCoverPartIL_NormalPad } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL_NormalPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject
export class CurCoverSampleIL_NormalPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const normalPadPart: CurCoverPartIL_NormalPad = <CurCoverPartIL_NormalPad>this.part;
    normalPadPart.algorithmSpec_normalPadColumn_normalPad();
    normalPadPart.algorithmSpec_normalPadFront_normalPad();
    normalPadPart.algorithmSpec_normalPadBack_normalPad();
    normalPadPart.algorithmSpec_normalPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 일반 패드 샘플(피복 고정>기둥(측면))
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = this.struct.fixingWorkIL.level1.columnPosition.normalPadPart.normalPadSample.specsList;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
