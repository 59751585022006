import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { TrunkSample_ColumnPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_ColumnPipe";
import { TrunkSample_ColumnBracingPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_ColumnBracingPipe";
import { TrunkSample_ColumnSupPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_ColumnSupPipe";
import { TrunkSample_MiddlePipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_MiddlePipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2021-03-05
 */
@jsonObject({
  knownTypes: [
    TrunkSample_ColumnPipe,
    TrunkSample_ColumnBracingPipe,
    TrunkSample_ColumnSupPipe,
    TrunkSample_MiddlePipe,
  ],
})
export class TrunkPart_Column extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public columnPipeSample: TrunkSample_ColumnPipe; // 기둥 파이프 샘플
  public columnBracingPipeSample: TrunkSample_ColumnBracingPipe; // 기둥 브레싱 파이프 샘플
  public columnSupPipeSample: TrunkSample_ColumnSupPipe; // 기둥 보조 파이프 샘플
  public middlePipeSample: TrunkSample_MiddlePipe; // 중방 파이프 샘플

  // 아이템
  public column_pipe: ItemPipe;
  public columnAndRoofStraight_tClamp: ItemHolder;
  public columnBracing_pipe: ItemPipe;
  public columnAndColumnBracing_rotationAsibaClamp: ItemHolder;
  public columnSup_pipe: ItemPipe;
  public columnSupAndcolumn_middlePairClamp: ItemHolder;
  public columnSupAndRoofSubStraight_tHolder: ItemHolder;
  public middle_pipe: ItemPipe;
  public middle_connectionPin: ItemConnector;
  public middleAndColumn_itemHolder: ItemHolder;
  public middleAndSideStraight_tClamp: ItemHolder;
  public column_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _columnLength: number = 0; // 기둥 길이
  @jsonMember(Number)
  public _columnQuantity: number = 0; // 기둥 수량
  @jsonMember(Number)
  public _columnLineNumber: number = 0; // 기둥 줄수
  @jsonMember(Number)
  public _columnInterval: number = 0; // 기둥 간격
  @jsonMember(Boolean)
  public _columnLengthRoundSelected: boolean = false; // 기둥 길이 반올림 선택
  @jsonMember(Boolean)
  public _columnBracingSelected: boolean = false; // 기둥 브레싱 선택
  @jsonMember(Number)
  public _columnBracingQuantity: number = 0; // 기둥 브레싱 수량
  @jsonMember(Boolean)
  public _columnSupPipeSelected: boolean = false; // 기둥 보조 파이프 설치 선택
  @jsonMember(Boolean)
  public _middleSelected: boolean = false; // 중방 선택
  @jsonMember(Number)
  public _middleLength: number = 0; // 중방 길이
  @jsonMember(Number)
  public _middleLineNumber: number = 0; // 중방 줄수

  /**
   * 기둥 길이
   */
  public get columnLength(): number {
    return this._columnLength;
  }

  public set columnLength(value: number) {
    this._columnLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기둥 수량
   */
  public get columnQuantity(): number {
    return this._columnQuantity;
  }

  public set columnQuantity(value: number) {
    this._columnQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기둥 줄수
   */
  public get columnLineNumber(): number {
    return this._columnLineNumber;
  }

  public set columnLineNumber(value: number) {
    this._columnLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnQuantity();
    this.algorithmPart();
  }

  /**
   * 기둥 간격
   */
  public get columnInterval(): number {
    return this._columnInterval;
  }

  public set columnInterval(value: number) {
    this._columnInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnQuantity();
    this.algorithm_middleLineNumber();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 운반레일
    if (this.level.index === 0) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithm_railHookInterval();
    }
  }

  /**
   * 기둥 길이 반올림 선택
   */
  public get columnLengthRoundSelected(): boolean {
    return this._columnLengthRoundSelected;
  }

  public set columnLengthRoundSelected(value: boolean) {
    this._columnLengthRoundSelected = value;

    // 알고리즘
    this.columnPipeSample.algorithmSpec_specLength();
    this.algorithmPart();
  }

  /**
   * 기둥 브레싱 선택
   */
  public get columnBracingSelected(): boolean {
    return this._columnBracingSelected;
  }

  public set columnBracingSelected(value: boolean) {
    this._columnBracingSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기둥 브레싱 수량
   */
  public get columnBracingQuantity(): number {
    return this._columnBracingQuantity;
  }

  public set columnBracingQuantity(value: number) {
    this._columnBracingQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기둥 보조 파이프 설치 선택
   */
  public get columnSupPipeSelected(): boolean {
    return this._columnSupPipeSelected;
  }

  public set columnSupPipeSelected(value: boolean) {
    this._columnSupPipeSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 선택
   */
  public get middleSelected(): boolean {
    return this._middleSelected;
  }

  public set middleSelected(value: boolean) {
    this._middleSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 길이
   */
  public get middleLength(): number {
    return this._middleLength;
  }

  public set middleLength(value: number) {
    this._middleLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 줄수
   */
  public get middleLineNumber(): number {
    return this._middleLineNumber;
  }

  public set middleLineNumber(value: number) {
    this._middleLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.columnPipeSample = new TrunkSample_ColumnPipe();
    this.columnBracingPipeSample = new TrunkSample_ColumnBracingPipe();
    this.columnSupPipeSample = new TrunkSample_ColumnSupPipe();
    this.middlePipeSample = new TrunkSample_MiddlePipe();

    this.sampleAC = [
      this.columnPipeSample,
      this.columnBracingPipeSample,
      this.columnSupPipeSample,
      this.middlePipeSample,
    ];

    // 아이템
    this.column_pipe = new ItemPipe();
    this.columnAndRoofStraight_tClamp = new ItemHolder();
    this.columnBracing_pipe = new ItemPipe();
    this.columnAndColumnBracing_rotationAsibaClamp = new ItemHolder();
    this.columnSup_pipe = new ItemPipe();
    this.columnSupAndcolumn_middlePairClamp = new ItemHolder();
    this.columnSupAndRoofSubStraight_tHolder = new ItemHolder();
    this.middle_pipe = new ItemPipe();
    this.middle_connectionPin = new ItemConnector();
    this.middleAndColumn_itemHolder = new ItemHolder();
    this.middleAndSideStraight_tClamp = new ItemHolder();
    this.column_screw = new ItemScrew();

    this.itemAC = [
      this.column_pipe,
      this.columnAndRoofStraight_tClamp,
      this.columnBracing_pipe,
      this.columnAndColumnBracing_rotationAsibaClamp,
      this.columnSup_pipe,
      this.columnSupAndcolumn_middlePairClamp,
      this.columnSupAndRoofSubStraight_tHolder,
      this.middle_pipe,
      this.middle_connectionPin,
      this.middleAndColumn_itemHolder,
      this.middleAndSideStraight_tClamp,
      this.column_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.columnPipeSample = <TrunkSample_ColumnPipe>this.sampleAC[0];
    this.columnBracingPipeSample = <TrunkSample_ColumnPipe>this.sampleAC[1];
    this.columnSupPipeSample = <TrunkSample_ColumnPipe>this.sampleAC[2];
    this.middlePipeSample = <TrunkSample_ColumnPipe>this.sampleAC[3];

    // 아이템
    this.column_pipe = <ItemPipe>this.itemAC[0];
    this.columnAndRoofStraight_tClamp = <ItemHolder>this.itemAC[1];
    this.columnBracing_pipe = <ItemPipe>this.itemAC[2];
    this.columnAndColumnBracing_rotationAsibaClamp = <ItemHolder>this.itemAC[3];
    this.columnSup_pipe = <ItemPipe>this.itemAC[4];
    this.columnSupAndcolumn_middlePairClamp = <ItemHolder>this.itemAC[5];
    this.columnSupAndRoofSubStraight_tHolder = <ItemHolder>this.itemAC[6];
    this.middle_pipe = <ItemPipe>this.itemAC[7];
    this.middle_connectionPin = <ItemConnector>this.itemAC[8];
    this.middleAndColumn_itemHolder = <ItemHolder>this.itemAC[9];
    this.middleAndSideStraight_tClamp = <ItemHolder>this.itemAC[10];
    this.column_screw = <ItemScrew>this.itemAC[11];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param columnLineNumber: number 기둥 줄수
   * @param columnInterval: number 기둥 간격
   * @param columnLengthRoundSelected: boolean 기둥 길이 반올림 선택
   * @param columnBracingSelected: boolean 기둥 브레싱 선택
   * @param columnBracingQuantity: number 기둥 브레싱 수량
   * @param columnSupPipeSelected: boolean 기둥 보조 파이프 설치 선택
   * @param middleSelected: boolean 중방 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    columnLineNumber: number = 0,
    columnInterval: number = 0,
    columnLengthRoundSelected: boolean = false,
    columnBracingSelected: boolean = false,
    columnBracingQuantity: number = 0,
    columnSupPipeSelected: boolean = false,
    middleSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._columnLineNumber = columnLineNumber;
    this._columnInterval = columnInterval;
    this._columnLengthRoundSelected = columnLengthRoundSelected;
    this._columnBracingSelected = columnBracingSelected;
    this._columnBracingQuantity = columnBracingQuantity;
    this._columnSupPipeSelected = columnSupPipeSelected;
    this._middleSelected = middleSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.columnPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥",
        "원형, 일반, 48.1mm, 2.1T, 5m, -",
      );
      this.columnBracingPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 브레싱",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.columnSupPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 보조(까치발)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.middlePipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.column_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥",
        "원형, 일반, 48.1mm, 2.1T, 5m, -",
      );
      this.columnAndRoofStraight_tClamp.setDefaultData(
        1,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "기둥 + 지붕 가로대",
        "원형, 일반, 48mm, 48mm",
      );
      this.columnBracing_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 브레싱",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.columnAndColumnBracing_rotationAsibaClamp.setDefaultData(
        3,
        CONST.ITEM_ID_ROTATION_ASIBA_CLAMP,
        CONST.ITEM_NAME_ROTATION_ASIBA_CLAMP,
        "기둥 브레싱 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.columnSup_pipe.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 보조(까치발)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.columnSupAndcolumn_middlePairClamp.setDefaultData(
        5,
        CONST.ITEM_ID_MIDDLE_PAIR_CLAMP,
        CONST.ITEM_NAME_MIDDLE_PAIR_CLAMP,
        "기둥 보조(까치발) + 기둥",
        "원형, 일반, 48mm, 32mm",
      );
      this.columnSupAndRoofSubStraight_tHolder.setDefaultData(
        6,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "기둥 보조(까치발) + 지붕 보조 가로대",
        "원형, 일반, 32mm, 32mm",
      );
      this.middle_pipe.setDefaultData(
        7,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.middle_connectionPin.setDefaultData(
        8,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "중방 + 중방",
        "원형, 일반, 48mm, 48mm",
      );
      this.middleAndColumn_itemHolder.setDefaultData(
        9,
        CONST.ITEM_ID_FIX_ASIBA_CLAMP,
        CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
        "중방 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.middleAndSideStraight_tClamp.setDefaultData(
        10,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "중방 + 측면 가로대",
        "원형, 일반, 48mm, 48mm",
      );
      this.column_screw.setDefaultData(
        11,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "기둥",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_columnLength();
    this.algorithm_columnQuantity();
    this.algorithm_middleLength();
    this.algorithm_middleLineNumber();

    // 외부 (소스코드에서 불러온 경우)

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.column_pipe.selected = true;
    this.column_pipe.visible = true;
    this.columnAndRoofStraight_tClamp.selected = true;
    this.columnAndRoofStraight_tClamp.visible = true;
    this.columnBracing_pipe.selected = true;
    this.columnBracing_pipe.visible = true;
    this.columnAndColumnBracing_rotationAsibaClamp.selected = true;
    this.columnAndColumnBracing_rotationAsibaClamp.visible = true;
    if (this.columnBracingSelected) {
      this.columnBracing_pipe.selected = true;
      this.columnBracing_pipe.visible = true;
      this.columnAndColumnBracing_rotationAsibaClamp.selected = true;
      this.columnAndColumnBracing_rotationAsibaClamp.visible = true;
    } else {
      this.columnBracing_pipe.selected = false;
      this.columnBracing_pipe.visible = false;
      this.columnAndColumnBracing_rotationAsibaClamp.selected = false;
      this.columnAndColumnBracing_rotationAsibaClamp.visible = false;
    }
    if (this.columnSupPipeSelected) {
      this.columnSup_pipe.selected = true;
      this.columnSup_pipe.visible = true;
      this.columnSupAndcolumn_middlePairClamp.selected = true;
      this.columnSupAndcolumn_middlePairClamp.visible = true;
      this.columnSupAndRoofSubStraight_tHolder.selected = true;
      this.columnSupAndRoofSubStraight_tHolder.visible = true;
    } else {
      this.columnSup_pipe.selected = false;
      this.columnSup_pipe.visible = false;
      this.columnSupAndcolumn_middlePairClamp.selected = false;
      this.columnSupAndcolumn_middlePairClamp.visible = false;
      this.columnSupAndRoofSubStraight_tHolder.selected = false;
      this.columnSupAndRoofSubStraight_tHolder.visible = false;
    }
    if (this.middleSelected) {
      this.middle_pipe.selected = true;
      this.middle_pipe.visible = true;
      this.middle_connectionPin.selected = true;
      this.middle_connectionPin.visible = true;
      this.middleAndColumn_itemHolder.selected = true;
      this.middleAndColumn_itemHolder.visible = true;
      this.middleAndSideStraight_tClamp.selected = true;
      this.middleAndSideStraight_tClamp.visible = true;
    } else {
      this.middle_pipe.selected = false;
      this.middle_pipe.visible = false;
      this.middle_connectionPin.selected = false;
      this.middle_connectionPin.visible = false;
      this.middleAndColumn_itemHolder.selected = false;
      this.middleAndColumn_itemHolder.visible = false;
      this.middleAndSideStraight_tClamp.selected = false;
      this.middleAndSideStraight_tClamp.visible = false;
    }
    this.column_screw.selected = true;
    this.column_screw.visible = true;

    /// //////// 수량 ///////////

    if (this.column_pipe.specLength > this.columnLength) {
      this.column_pipe.designQuantity =
        this.columnQuantity / CommonUtil.roundDown(this.column_pipe.specLength / this.columnLength);
    } else {
      this.column_pipe.designQuantity = this.columnQuantity;
    }

    this.columnAndRoofStraight_tClamp.designQuantity = this.columnQuantity * CONST.NUM_EXTRA_RATE_T_CLAMP;

    this.columnBracing_pipe.designQuantity = this.columnBracingQuantity;

    this.columnAndColumnBracing_rotationAsibaClamp.designQuantity =
      Math.ceil(this.columnBracing_pipe.specLength / 2.5) *
      this.columnBracing_pipe.designQuantity *
      CONST.NUM_EXTRA_RATE_ROTATION_ASIBA_CLAMP;

    this.columnSup_pipe.designQuantity =
      ((1 * 2 * this.columnQuantity) / this.columnSup_pipe.specLength - this.columnLineNumber * 2 * 2) *
      CONST.NUM_EXTRA_RATE_COLUMN_SUP_PIPE;

    this.columnSupAndcolumn_middlePairClamp.designQuantity =
      this.columnQuantity * CONST.NUM_EXTRA_RATE_MIDDLE_PAIR_CLAMP;

    this.columnSupAndRoofSubStraight_tHolder.designQuantity = this.columnQuantity * 2 * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.middle_pipe.designQuantity =
      (this.middle_pipe.getConnectedPipeLength(this.middleLength, this.middleLineNumber * this.basic.buildingNumber) /
        this.middle_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_MIDDLE_PIPE;

    if (this.basicLevel.width > this.middle_pipe.specLength) {
      this.middle_connectionPin.designQuantity =
        Math.floor(this.basicLevel.width / this.middle_pipe.specLength) *
        this.middleLineNumber *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.middle_connectionPin.designQuantity = 0;
    }

    this.middleAndColumn_itemHolder.designQuantity =
      this.middleLineNumber * this.columnLineNumber * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_FIX_ASIBA_CLAMP;

    this.middleAndSideStraight_tClamp.designQuantity =
      this.middleLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_T_CLAMP;

    this.column_screw.designQuantity =
      ((this.columnAndRoofStraight_tClamp.getSelectedQuantity() * 3 +
        this.columnSupAndcolumn_middlePairClamp.getSelectedQuantity() * 3 +
        this.columnSupAndRoofSubStraight_tHolder.getSelectedQuantity() * 3 +
        this.middle_connectionPin.getSelectedQuantity() * 2 +
        this.middleAndSideStraight_tClamp.getSelectedQuantity() * 3) /
        this.column_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 기둥 길이 <- 동고(기본정보), 매설 깊이(기본정보)
   */
  public algorithm_columnLength(): void {
    this.columnLength = this.basicLevel.heightMax + this.basicLevel.depthLaying;
  }

  /**
   * 알고리즘: 기둥 수량 <- 기둥 줄수, 하우스 길이(기본정보), 기둥 간격
   */
  public algorithm_columnQuantity(): void {
    this.columnQuantity = this.columnLineNumber * (Math.ceil(this.basicLevel.length / this.columnInterval) + 1);
  }

  /**
   * 알고리즘: 중방 길이 <- 하우스 폭(기본정보)
   */
  public algorithm_middleLength(): void {
    this.middleLength = this.basicLevel.width;
  }

  /**
   * 알고리즘: 중방 줄수 <- 하우스 길이(기본정보), 기둥 간격
   */
  public algorithm_middleLineNumber(): void {
    this.middleLineNumber = Math.ceil(this.basicLevel.length / this.columnInterval) + 1;
  }

  //----------------------------------
  // 품목 상품
  //----------------------------------

  /**
   * 상품 알고리즘: [중방 + 기둥] 고정구 <- 기둥 파이프 샘플
   */
  public algorithmProduct_middleAndColumn_itemHolder(): void {
    // 하드코딩
    if (this.columnPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM) {
      this.middleAndColumn_itemHolder._productId = CONST.ITEM_ID_U_CLAMP;
      this.middleAndColumn_itemHolder._label = CONST.ITEM_NAME_U_CLAMP;
    } else {
      this.middleAndColumn_itemHolder._productId = CONST.ITEM_ID_FIX_ASIBA_CLAMP;
      this.middleAndColumn_itemHolder._label = CONST.ITEM_NAME_FIX_ASIBA_CLAMP;
    }
    this.middleAndColumn_itemHolder.setDefaultVariable();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [기둥] 파이프 <- 기둥 파이프 샘플
   */
  public algorithmSpec_column_pipe(): void {
    this.column_pipe.specs = this.columnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [기둥 + 지붕 가로대] T 클램프 <- 기둥 파이프 샘플, 지붕 가로대 파이프 샘플
   */
  public algorithmSpec_columnAndRoofStraight_tClamp(): void {
    this.columnAndRoofStraight_tClamp.specPipeType = (
      this.position as TrunkPosition
    ).straightPart.straightRoofPipeSample.specPipeType;
    this.columnAndRoofStraight_tClamp.specCrossSize1 = (
      this.position as TrunkPosition
    ).straightPart.straightRoofPipeSample.specCrossSize;
    this.columnAndRoofStraight_tClamp.specCrossSize2 = this.columnPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [기둥 브레싱] 파이프 <- 기둥 브레싱 파이프 샘플
   */
  public algorithmSpec_columnBracing_pipe(): void {
    this.columnBracing_pipe.specs = this.columnBracingPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [기둥 브레싱 + 기둥] 회전 아시바 클램프 <- 기둥 파이프 샘플, 기둥 브레싱 파이프 샘플
   */
  public algorithmSpec_columnAndColumnBracing_rotationAsibaClamp(): void {
    this.columnAndColumnBracing_rotationAsibaClamp.specPipeType = this.columnPipeSample.specPipeType;
    this.columnAndColumnBracing_rotationAsibaClamp.specCrossSize1 = this.columnPipeSample.specCrossSize;
    this.columnAndColumnBracing_rotationAsibaClamp.specCrossSize2 = this.columnBracingPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [기둥 보조] 파이프 <- 기둥 보조 파이프 샘플
   */
  public algorithmSpec_columnSup_pipe(): void {
    this.columnSup_pipe.specs = this.columnSupPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [기둥 보조 + 기둥] 중방쌍반도 <- 기둥 보조 파이프 샘플, 기둥 파이프 샘플
   */
  public algorithmSpec_columnSupAndcolumn_middlePairClamp(): void {
    this.columnSupAndcolumn_middlePairClamp.specPipeType = this.columnPipeSample.specPipeType;
    this.columnSupAndcolumn_middlePairClamp.specCrossSize1 = this.columnPipeSample.specCrossSize;
    this.columnSupAndcolumn_middlePairClamp.specCrossSize2 = this.columnSupPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [기둥 보조 + 지붕 보조 가로대] T 고정구 <- 기둥 보조 파이프 샘플, 지붕 보조 가로대 파이프 샘플
   */
  public algorithmSpec_columnSupAndRoofSubStraight_tHolder(): void {
    this.columnSupAndRoofSubStraight_tHolder.specPipeType = (
      this.position as TrunkPosition
    ).straightPart.straightRoofSubPipeSample.specPipeType;
    this.columnSupAndRoofSubStraight_tHolder.specCrossSize1 = (
      this.position as TrunkPosition
    ).straightPart.straightRoofSubPipeSample.specCrossSize;
    this.columnSupAndRoofSubStraight_tHolder.specCrossSize2 = this.columnSupPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [중방] 파이프 <- 중방 파이프 샘플
   */
  public algorithmSpec_middle_pipe(): void {
    this.middle_pipe.specs = this.middlePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 + 중방] 연결핀 <- 중방 파이프 샘플
   */
  public algorithmSpec_middle_connectionPin(): void {
    this.middle_connectionPin.specPipeType = this.middlePipeSample.specPipeType;
    this.middle_connectionPin.specCrossSize1 = this.middlePipeSample.specCrossSize;
    this.middle_connectionPin.specCrossSize2 = this.middlePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [중방 + 기둥] 고정구 <- 기둥 파이프 샘플, 중방 파이프 샘플
   */
  public algorithmSpec_middleAndColumn_itemHolder(): void {
    this.middleAndColumn_itemHolder.specPipeType = this.columnPipeSample.specPipeType;
    this.middleAndColumn_itemHolder.specCrossSize1 = this.columnPipeSample.specCrossSize;
    this.middleAndColumn_itemHolder.specCrossSize2 = this.middlePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [중방 + 측면 가로대] T 클램프 <- 중방 파이프 샘플, 측면 가로대 파이프 샘플
   */
  public algorithmSpec_middleAndSideStraight_tClamp(): void {
    this.middleAndSideStraight_tClamp.specPipeType = this.middlePipeSample.specPipeType;
    this.middleAndSideStraight_tClamp.specCrossSize1 = this.middlePipeSample.specCrossSize;
    this.middleAndSideStraight_tClamp.specCrossSize2 = (
      this.position as TrunkPosition
    ).straightPart.straightSidePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [기둥] 나사 <- 공통 샘플
   */
  public algorithmSpec_column_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
