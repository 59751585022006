import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 펌프
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemWaterPump extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, 마력범위]
  public _specFeature: string = undefined; // 특성
  public _specHorsePowerRange: string = undefined; // 마력범위

  // 하우스 설계 > 상표 : [회사, 모델, 마력, 변압형태, 입출구지름]
  public _brandModelName: string = undefined; // 모델
  public _brandHorsePower: string = undefined; // 마력
  public _brandTransformType: string = undefined; // 변압형태
  public _brandInOutDiameter: string = undefined; // 입출구지름

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 마력범위
   */
  public get specHorsePowerRange(): string {
    return this._specHorsePowerRange;
  }
  //
  public set specHorsePowerRange(value: string) {
    this._specHorsePowerRange = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  /**
   * 모델
   */
  public get brandModelName(): string {
    return this._brandModelName;
  }
  //
  public set brandModelName(value: string) {
    this._brandModelName = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 마력
   */
  public get brandHorsePower(): string {
    return this._brandHorsePower;
  }
  //
  public set brandHorsePower(value: string) {
    this._brandHorsePower = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 변압형태
   */
  public get brandTransformType(): string {
    return this._brandTransformType;
  }
  //
  public set brandTransformType(value: string) {
    this._brandTransformType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 입출구지름
   */
  public get brandInOutDiameter(): string {
    return this._brandInOutDiameter;
  }
  //
  public set brandInOutDiameter(value: string) {
    this._brandInOutDiameter = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.waterPumpCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specHorsePowerRange = specsArray[1];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specHorsePowerRange = specsArray[1];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature + CONST.DELIMITER_SPEC + this._specHorsePowerRange;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    const brandsArray: string[] = this._brands.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandHorsePower = brandsArray[2];
    this.brandTransformType = brandsArray[3];
    this.brandInOutDiameter = brandsArray[4];
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    const brandsArray: string[] = this._brandsList.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandHorsePower = brandsArray[2];
    this.brandTransformType = brandsArray[3];
    this.brandInOutDiameter = brandsArray[4];
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList =
      this._brandCompany +
      CONST.DELIMITER_BRAND +
      this._brandModelName +
      CONST.DELIMITER_BRAND +
      this._brandHorsePower +
      CONST.DELIMITER_BRAND +
      this._brandTransformType +
      CONST.DELIMITER_BRAND +
      this._brandInOutDiameter;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }
}
