import { jsonObject } from "typedjson";

import { ItemCustomLaborCost } from "vhows-design/src/object/design/item/list/ItemCustomLaborCost";
import { BuildingPart_PerArea } from "vhows-design/src/object/design/building/BuildingPart_PerArea";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-28
 */
@jsonObject
export class BuildingSample_CustomPerAreaLaborCost extends ItemCustomLaborCost {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const perAreaPart: BuildingPart_PerArea = <BuildingPart_PerArea>this.part;
    perAreaPart.algorithmSpec_customPerArea_customLaborCost();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 면적 형태
   */
  // @override
  public algorithmSpec(): void {
    this.specs = (<BuildingPart_PerArea>this.part).areaType;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  protected algorithm_customUnitSupply(): void {
    super.algorithm_customUnitSupply();

    (<BuildingPart_PerArea>this.part).algorithmSpec_customPerArea_customLaborCost();
    (<BuildingPart_PerArea>this.part).algorithm_itemPrice();
  }
}
