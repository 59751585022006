import { jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { ProductModel } from "vhows-design/src/object/item/product/ProductModel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-26
 */
@jsonObject({
  knownTypes: [],
})
export class CategoryModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 조직별 분류 모델 배열 */
  public categoryCompanyModelAC: CategoryCompanyModel[] = null;
  /** 상품 모델 배열 */
  public productModelAC: ProductModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 분류1 */
  @jsonMember(String)
  public category1: string = null;
  /** 분류2 */
  @jsonMember(String)
  public category2: string = null;
  /** 분류3 */
  @jsonMember(String)
  public category3: string = null;
  /** 규격 형태 */
  @jsonMember(String)
  public specsType: string = null;
  /** 상표 형태 */
  @jsonMember(String)
  public brandsType: string = null;
  /** 단가 형태 */
  @jsonMember(String)
  public unitPriceType: string = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param category1 분류1
   * @param category2 분류2
   * @param category3 분류3
   * @param specsType 규격 형태
   * @param brandsType 상표 형태
   * @param unitPriceType 단가 형태
   * @param productModelAC 상품 모델 배열
   * @param categoryCompanyModelAC  조직별 분류 모델 배열
   */
  constructor(
    category1: string = "",
    category2: string = "",
    category3: string = "",
    specsType: string = "",
    brandsType: string = "",
    unitPriceType: string = "",
    productModelAC: ProductModel[] = null,
    categoryCompanyModelAC: CategoryCompanyModel[] = null,
  ) {
    super();

    this.category1 = category1;
    this.category2 = category2;
    this.category3 = category3;
    this.specsType = specsType;
    this.brandsType = brandsType;
    this.unitPriceType = unitPriceType;
    this.productModelAC = productModelAC;
    this.categoryCompanyModelAC = categoryCompanyModelAC;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 규격형태에서 규격 이름 구하기.
   * @return 규격 이름
   */
  public getSpecsName() {
    return this.specsType.replace(/[\\()[\]]/gi, "");
  }

  /**
   * 규격형태에서 규격 리스트 이름 구하기.
   * @return 규격 리스트 이름
   */
  public getSpecsListName() {
    return this.specsType.substring(1, this.specsType.indexOf("]"));
  }

  /**
   * 상표형태에서 상표 이름 구하기.
   * @return 상표 이름
   */
  public getBrandsName() {
    return this.brandsType.replace(/[\\()[\]]/gi, "");
  }

  /**
   * 상표형태에서 상표 리스트 이름 구하기.
   * @return 상표 리스트 이름
   */
  public getBrandsListName() {
    return this.brandsType.substring(1, this.brandsType.indexOf("]"));
  }

  //----------------------------------
  // 품목 모델
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
