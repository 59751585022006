import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemBandstring } from "vhows-design/src/object/design/item/list/ItemBandstring";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ItemUtil } from "vhows-design/src/object/design/item/ItemUtil";
import { ItemInitDataIF } from "vhows-design/src/object/design/item/ItemInterface";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { BandstringPosition } from "vhows-design/src/object/design/cover/bandstring/BandstringPosition";
import { BandstringSample_Bandstring } from "vhows-design/src/object/design/cover/bandstring/BandstringSample_Bandstring";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-08
 */
@jsonObject({
  knownTypes: [BandstringSample_Bandstring],
})
export class BandstringPart_Bandstring extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public bandstringSample: BandstringSample_Bandstring; // 밴드끈 샘플

  // 아이템
  public bandstring_bandstring: ItemBandstring;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _bandstringLength: number; // 밴드끈 길이
  @jsonMember(Number)
  public _totalBandstringLength: number; // 총 밴드끈 길이

  /**
   * 밴드끈 길이
   */
  public get bandstringLength(): number {
    return this._bandstringLength;
  }

  //
  public set bandstringLength(value: number) {
    this._bandstringLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalBandstringLength();
  }

  /**
   * 총 밴드끈 길이
   */
  public get totalBandstringLength(): number {
    return this._totalBandstringLength;
  }

  //
  public set totalBandstringLength(value: number) {
    this._totalBandstringLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.bandstringSample = new BandstringSample_Bandstring();

    this.sampleAC = [this.bandstringSample];

    // 아이템
    this.bandstring_bandstring = new ItemBandstring();

    this.itemAC = [this.bandstring_bandstring];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.bandstringSample = this.sampleAC[0] as BandstringSample_Bandstring;

    // 아이템
    this.bandstring_bandstring = this.itemAC[0] as ItemBandstring;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.bandstringSample.setDefaultData(
        0,
        CONST.ITEM_ID_VINYL_BAND,
        CONST.ITEM_NAME_VINYL_BAND,
        "밴드끈",
        "비닐, 25cm, 200m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.bandstring_bandstring.setDefaultData(
        0,
        CONST.ITEM_ID_VINYL_BAND,
        CONST.ITEM_NAME_VINYL_BAND,
        "밴드끈",
        "비닐, 25cm, 200m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_bandstringLength();
    this.algorithm_totalBandstringLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.bandstring_bandstring.selected = true;
    this.bandstring_bandstring.visible = true;

    /// ////////  수량 ///////////

    this.bandstring_bandstring.designQuantity = this.totalBandstringLength / this.bandstring_bandstring.specLength;
  }

  /**
   * 알고리즘: 밴드끈 길이 <- 최종 서까래 길이(기초 골조), 선택(천창 골조), 서까래 길이(천창 골조), 곡면 폭(천창 골조)
   */
  public algorithm_bandstringLength(): void {
    // 외부
    const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    if (skyFramePosition.selected === false) {
      this.bandstringLength = trunkPosition.rafterPart.rafterTotalLength;
    } else {
      this.bandstringLength =
        trunkPosition.rafterPart.rafterTotalLength +
        (skyFramePosition.rafterPart.rafterLength - skyFramePosition.arcWidth + 1);
    }
  }

  /**
   * 알고리즘: 총 밴드끈 길이 <- 밴드끈 길이, 간격(위치), 길이(기본정보), 동수(기본정보)
   */
  public algorithm_totalBandstringLength(): void {
    const bandstringPosition: BandstringPosition = this.position as BandstringPosition;

    this.totalBandstringLength = Math.ceil(
      this.bandstringLength *
        (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
        this.basic.buildingNumber,
    );
  }

  //----------------------------------
  // 품목 상품
  //----------------------------------

  /**
   * 상품 알고리즘
   */
  public algorithmProduct_bandstring_bandstring(): void {
    const bandstringPosition: BandstringPosition = this.position as BandstringPosition;

    let itemInitData: ItemInitDataIF = null; // 초기 데이터
    if (bandstringPosition.type === CONST.LB_BANDSTRING_TYPE_VINYL) {
      itemInitData = ItemUtil.getInitItemData(CONST.ITEM_ID_VINYL_BAND);
    } else if (bandstringPosition.type === CONST.LB_BANDSTRING_TYPE_NET) {
      itemInitData = ItemUtil.getInitItemData(CONST.ITEM_ID_NET_BAND);
    } else if (bandstringPosition.type === CONST.LB_BANDSTRING_TYPE_WEAVING) {
      itemInitData = ItemUtil.getInitItemData(CONST.ITEM_ID_WEAVING_BAND);
    }

    if (itemInitData) {
      this.bandstring_bandstring._productId = itemInitData.productId;
      this.bandstring_bandstring._label = itemInitData.label;
      this.bandstring_bandstring._specs = itemInitData.specs;
      this.bandstring_bandstring.setDefaultVariable();
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [밴드끈] 밴드끈 <- 밴드끈 샘플
   */
  public algorithmSpec_bandstring_bandstring(): void {
    this.bandstring_bandstring.specs = this.bandstringSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
