import { atom, PrimitiveAtom } from "jotai";
import { atomWithImmer } from "jotai-immer";
import { NextRouter } from "next/router";
import { ERROR_MESSAGE, TOAST_TYPE } from "../constants/common";
import { Design } from "vhows-design/src/object/design/Design";
import { cloneDeep } from "lodash-es";
import { designCounsel } from "constants/design";

interface Toast {
  visible: boolean;
  content: string;
  type: "error" | "success";
}

let _router: NextRouter;

export function setRouter(router: NextRouter) {
  _router = router;
}

export function getRouter() {
  return _router;
}

export const cancelModalAtom = atom(false);
export const farmmorningUserInfoAtom = atom<UserInfo>(null) as PrimitiveAtom<UserInfo>;

// 전역 toast 상태
export const toastAtom = atom<Toast>({
  visible: false,
  content: ERROR_MESSAGE.ERROR,
  type: TOAST_TYPE.ERROR,
});

export const counselProgressAtom = atom<CounselProgress>({
  consultingUuid: null,
  preparationUuid: null,
  lastCounselFlag: false,
});

const design = new Design();

export const designAtom = atom<Design>(design);

export const counselDesignAtom = atomWithImmer<DesignCounsel>(cloneDeep(designCounsel));

export const designLoadingAtom = atom(false);

export const isLoadedDesignAtom = atom(false);
