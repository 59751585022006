/**
 * 조직 상수
 */
export const CompanyConst = {
  //----------------------------------
  // 공통
  //----------------------------------
  // 레이블
  label: {
    FILE_SELECTION: "파일 선택",
  },
  //----------------------------------
  // 화면 변수
  //----------------------------------
  // 조직원 등록 URL
  CREATE_URL: "/companies/employees/create",
  // 접근형태
  accessType: {
    MY: "my",
    COMPANY: "company",
    DEFAULT: "default",
  },
  // 수정 형태
  modifyType: {
    NEW: "등록",
    EDIT: "수정",
  },
  // 통신 형태
  signalType: {
    CREATE: 0,
    UPDATE: 1,
  },
  // 승인 상태
  approvalStatus: {
    APPLICATION_COMPLETED: "신청 완료",
    UNDER_REVIEW: "검토중",
    APPROVED: "승인 완료",
    REFUSAL_OF_APPROVAL: "승인 거절",
  },
  // 프로필 이미지
  profileImage: {
    WIDTH: 256,
    HEIGHT: 256,
    FILE_SIZE: 20971520,
    text: {
      EXCEED_FILE_SIZE: "파일이 20MB를 초과했습니다. 이미지 사이즈를 줄여서 다시 선택해주세요.",
      SMALL_IMAGE_SIZE: "이미지가 너무 작습니다. 가로와 세로가 각각 256px이상의 이미지를 선택해주세요.",
    },
  },
  // 사업자등록증
  businessReg: {
    FILE_SIZE: 15728640,
    text: {
      EXCEED_FILE_SIZE: "파일이 15MB를 초과했습니다. 이미지 사이즈를 줄여서 다시 선택해주세요.",
    },
  },
  // 주민등록증
  residentCard: {
    FILE_SIZE: 15728640,
    text: {
      EXCEED_FILE_SIZE: "파일이 15MB를 초과했습니다. 이미지 사이즈를 줄여서 다시 선택해주세요.",
    },
  },
  //----------------------------------
  // 데이터 변수
  //----------------------------------
  // 재직 상태
  workStatus: {
    EMPLOYMENT: "재직",
    ABSENCE: "휴직",
    RETIRE: "퇴직",
  },
  // 사업자구분(사업자등록여부)
  businessDiv: {
    NONREG_FREELANCER: "미등록/프리랜서",
    REG_INDIVIDUAL: "등록/개인사업자",
    REG_CORPORATE: "등록/법인사업자",
    REG_AGENT: "등록/브랜드대리점",
  },
  // 업무 분야
  workField: {
    BUILDER: "시공사",
    BUILDER_DISTRIBUTER: "시공/유통사",
    BUILDER_MANUFACTURER: "시공/제조사",
    BUILDER_DISTRIBUTER_MANUFACTURER: "시공/유통/제조사",
    DISTRIBUTER: "유통사",
    DISTRIBUTER_MANUFACTURER: "제조/유통사",
    MANUFACTURER: "제조사",
    INSTITUTION: "연구/기관",
  },
  workFieldValue: {
    DISTRIBUTER_BUILDER: 0,
    MANUFACTURER_WHOLESALER: 1,
    PLATFORM: 2,
  },
  // 시설 시공 업무 분야
  workBuildingFields: {
    SINGLE_HOUSE: "단동온실",
    INTERLOCK_HOUSE: "연동온실",
    GLASS_HOUSE: "유리온실",
    PLANT_FACTORY: "식물공장",
    MUSHROOM_FACILITIES: "버섯재배사",
    STOCK_FACILITIES: "축사",
    FISHERY_FACILITIES: "양어/양식장",
    HYDROPONICS_FACILITIES: "수경재배시설",
    SMARTFARM_FACILITIES: "스마트팜시설",
  },
  // 특수 시공 업무 분야
  workBuilding2Fields: {
    ENVIRONMENT_CONTROL: "환경제어시스템",
    HYDROPONICS_CONTROL: "양액제어시스템",
    AUTO_PEST_CONTROL: "자동방제시스템",
    COOLING_HEATING: "냉난방시스템",
    HEAT_EXCHANGE: "열교환시스템",
    AQUAPONICS: "아쿠아포닉스시스템",
    AUTO_GROWTH: "생육자동화시스템",
    AUTO_FISHERY: "양식자동화시스템",
  },
  // 유통 업무 분야
  workDistributingFields: {
    HOUSE_MATERIAL: "온실자재",
    PESTICIDES: "농약",
    FERTILIZER_NUTRITION: "비료/영양제",
    SEED_SEEDLING: "씨앗/모종",
    FARMING_TOOL: "소농기구",
    FARMING_MACHINE: "농기계",
    HARDWARE_TOOL: "철물/공구",
    GARDENING: "조경",
    FEED: "사료",
  },
  // 제조 업무 분야
  workManufacturingFields: {
    FRAME: "골조류",
    COVER: "피복류",
    SWITCHER: "개폐류",
    WATERING: "관수류",
    VENT: "환기류",
    HEATING: "난방류",
    COOLING: "냉방류",
    HEAT_CHANGE: "열교환류",
    PEST_CONTROL: "자동방제류",
    HYDROPONICS: "수경재배류",
    ENVIRONMENT_CONTROL: "환경제어류",
    INPUT: "입력/센서류",
    OUTPUT: "출력장치류",
    NETWORK: "통신장비류",
    GROWTH: "생육장치류",
    FARMING_TOOL: "소농기구류",
    FARMING_MACHINE: "농기계류",
  },
  // 기관 업무 분야
  workInstitutionFields: {
    UNIVERSITY: "대학",
    LABORATORY: "연구소",
    TECH_CENTER: "기술센터",
    PUBLIC: "공공기관",
  },
  // 경력 기간
  workPeriod: {
    PERIOD_1_TO_5: "5년이하",
    PERIOD_5_TO_10: "5-10년",
    PERIOD_10_TO_15: "10-15년",
    PERIOD_15_TO_20: "15-20년",
    PERIOD_20_TO: "20년이상",
  },
  // A/S 기간
  asPeriod: {
    PERIOD_NONE: "없음",
    PERIOD_6_MONTH: "6개월",
    PERIOD_1_YEAR: "1년",
    PERIOD_2_YEAR: "2년",
    PERIOD_3_YEAR_TO: "3년이상",
  },
  // 시공 가능한 최소금액
  minBuildingCost: {
    COST_NONE: "없음",
    COST_200_TO: "200만원이상",
    COST_500_TO: "500만원이상",
    COST_1000_TO: "1000만원이상",
    COST_2000_TO: "2000만원이상",
    COST_4000_TO: "4000만원이상",
  },
  // 자격 요건
  licenses: {
    GREENHOUSE_LICENSE: "온실면허",
    ARCHITECTURAL_DESIGN: "건축설계자격",
    CHEMICALS_MANAGEMENT: "농약판매관리",
  },
  // 서류 제공
  docOffers: {
    STANDARD_CONTRACT: "표준계약서",
    STANDARD_ESTIMATE: "표준견적서",
    COST_STATEMENT: "원가계산서",
    BLUEPRINT: "설계도",
    SPECIFICATION: "시방서",
    PROGRESS_SCHEDULE: "공정표",
    TAX_INVOICE: "세금계산서",
    CASH_RECEIPT: "현금영수증",
    PROFESSIONAL_INSPECTION: "전문감리",
    WARRANTY_BOND: "하자이행증권",
    PERFORMANCE_BOND: "계약이행증권",
  },
  // 부가 서비스
  addServices: {
    GOVERNMENT_SUBSIDY: "정부보조사업",
    GOVERNMENT_LOAN: "정부융자사업",
    SITE_VISIT: "현장견학",
    DIRECT_BUILDING: "직영시공",
    END_CLEANING: "마감청소",
    CULTIVATION_CONSULTING: "재배컨설팅",
    GROWTH_CONSULTING: "생육컨설팅",
    DIRECT_FARM: "직영농장",
    PRODUCT_PURCHASE: "생산물수매/유통",
    DIRECT_STORE: "직영매장",
  },
  // 가입 경로
  joinedRoute: {
    DM: "우편",
    TEXT: "문자",
    NEWS: "기사",
    BLOG: "블로그",
    YOUTUBE: "유투브",
    SNS: "SNS",
    FRIEND: "지인소개",
    SEARCH: "직접검색",
    OTHER: "기타",
  },
} as const;
