import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 문짝
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-30
 */
@jsonObject
export class ItemDoor extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, 파이프단면크기], (폭), (길이)
  public _specFeature: string = undefined; // 특성
  public _specPipeCrossSize: string = undefined; // 파이프단면크기
  public _specWidth: number = undefined; // 폭
  public _specLength: number = undefined; // 길이

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 파이프단면크기 <br/>
   */
  public get specPipeCrossSize(): string {
    return this._specPipeCrossSize;
  }
  //
  public set specPipeCrossSize(value: string) {
    this._specPipeCrossSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭
   */
  public get specWidth(): number {
    return this._specWidth;
  }
  //
  public set specWidth(value: number) {
    // 일반화된 간격 적용 - 100cm 간격(둘째자리 올림)
    this._specWidth = CommonUtil.roundUp(value, -2);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭 레이블(값+단위기호)
   */
  public get specWidthLabel(): string {
    return this.specWidth + CONST.UNIT_MILLIMETER;
  }
  //
  public set specWidthLabel(value: string) {
    this.specWidth = parseFloat(value);
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 100cm 간격(둘째자리 올림)
    this._specLength = CommonUtil.roundUp(value, -2);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_MILLIMETER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.doorCategoryModel;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 문가공 단가
    const doorWorkedUnitSupply: number = this.brandCompanyModel.optionUnitSupply1;
    // 문가공 매출가 계산식
    const doorWorkedSellingSupply: number = doorWorkedUnitSupply;

    // 매출가 계산식
    let sellingSupply: number =
      defaultUnitSupply * (CommonUtil.convertMM2M(this.specWidth) * 4 + CommonUtil.convertMM2M(this.specLength) * 2) +
      doorWorkedSellingSupply;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specPipeCrossSize = specsArray[1];
    this.specWidthLabel = specsArray[2];
    this.specLengthLabel = specsArray[3];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specPipeCrossSize = specsArray[1];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature + CONST.DELIMITER_SPEC + this._specPipeCrossSize;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList + CONST.DELIMITER_SPEC + this.specWidthLabel + CONST.DELIMITER_SPEC + this.specLengthLabel;
  }
}
