import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { MaterialPart_Custom } from "vhows-design/src/object/design/other/material/MaterialPart_Custom";
import { RackPart_Rack } from "vhows-design/src/object/design/other/rack/RackPart_Rack";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-04-18
 */
@jsonObject({
  knownTypes: [RackPart_Rack, MaterialPart_Custom],
})
export class RackPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public rackPart: RackPart_Rack;
  public customPart: MaterialPart_Custom;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  get floorTotalArea(): number {
    let floorTotalArea: number = 0;

    return (floorTotalArea =
      this.rackPart.rackLength * this.rackPart.rackWidth * this.rackLineNumber * this.rackBuildingNumber);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _rackBuildingNumber: number; // 선반 동수
  @jsonMember(Number)
  public _rackLineNumber: number; // 선반 줄수

  /**
   * 선반 동수
   */
  public get rackBuildingNumber(): number {
    return this._rackBuildingNumber;
  }

  //
  public set rackBuildingNumber(value: number) {
    this._rackBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.rackPart.algorithm_frameQuantitiy();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  /**
   * 선반 줄수
   */
  public get rackLineNumber(): number {
    return this._rackLineNumber;
  }

  //
  public set rackLineNumber(value: number) {
    this._rackLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.rackPart.algorithm_frameQuantitiy();
    this.rackPart.algorithm_hallwayWidth();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    this.rackPart.positionType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.rackPart = new RackPart_Rack();
    this.customPart = new MaterialPart_Custom();

    this.partAC = [this.rackPart, this.customPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.rackPart = <RackPart_Rack>this.partAC[0];
    this.customPart = <MaterialPart_Custom>this.partAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param rackLineNumber: number 선반 줄수
   * @param rackBuildingNumber: number 선반 동수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    rackLineNumber: number = 0,
    rackBuildingNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._rackLineNumber = rackLineNumber;
    this._rackBuildingNumber = rackBuildingNumber;

    if (this.level.index >= 0) {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        // 선반 틀
        this.rackPart.setDefaultData(
          CONST.INDEX_CM_RACK_RACK,
          true,
          false,
          true,
          CONST.LB_RACK_RACK,
          "",
          2,
          0.8,
          1.7,
          7,
          0.25,
          0.2,
          4,
          CONST.LB_RACK_FIXED_FRAME_PIPE_RAFTER,
          false,
        );
        // 임의 품목
        this.customPart.setDefaultData(CONST.INDEX_CM_RACK_CUSTOM, false, false, false, CONST.LB_RACK_CUSTOM, "");
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        // 선반 틀
        this.rackPart.setDefaultData(
          CONST.INDEX_CM_RACK_RACK,
          true,
          false,
          true,
          CONST.LB_RACK_RACK,
          "",
          2,
          0.8,
          1.7,
          7,
          0.25,
          0.2,
          4,
          CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE,
          false,
        );
        // 임의 품목
        this.customPart.setDefaultData(CONST.INDEX_CM_RACK_CUSTOM, false, false, false, CONST.LB_RACK_CUSTOM, "");
      }
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_rackBuildingNumber();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_RACK_TYPE_STAND_ALONE_DEFAULT) {
      this.rackPart.selected = true;
      this.rackPart.visible = true;
      this.customPart.selected = false;
      this.customPart.visible = false;
    } else if (this.type === CONST.LB_RACK_TYPE_FRAME_INTEGRATED) {
      this.rackPart.selected = true;
      this.rackPart.visible = true;
      this.customPart.selected = false;
      this.customPart.visible = false;
    } else if (this.type === CONST.LB_RACK_TYPE_CUSTOM) {
      this.rackPart.selected = false;
      this.rackPart.visible = false;
      this.customPart.selected = true;
      this.customPart.visible = true;
    }
  }

  /**
   * 알고리즘: 선반 동수 <- 하우스 동수
   */
  public algorithm_rackBuildingNumber(): void {
    this.rackBuildingNumber = this.basic.buildingNumber;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
