import { jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemSkylightMotor } from "vhows-design/src/object/design/item/list/ItemSkylightMotor";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ItemSkylightSet } from "vhows-design/src/object/design/item/list/ItemSkylightSet";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemBearing } from "vhows-design/src/object/design/item/list/ItemBearing";
import { ItemSwitcherOther } from "vhows-design/src/object/design/item/list/ItemSwitcherOther";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";
import { SkySwitchPositionIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPositionIL";
import { SkySwitchSampleIL_SkylightMotor } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchSampleIL_SkylightMotor";
import { SkySwitchSampleIL_RackpinionSet } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchSampleIL_RackpinionSet";
import { SkySwitchSampleIL_RackpinionAxisPipe } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchSampleIL_RackpinionAxisPipe";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-04-15
 */
@jsonObject({
  knownTypes: [SkySwitchSampleIL_SkylightMotor, SkySwitchSampleIL_RackpinionSet, SkySwitchSampleIL_RackpinionAxisPipe],
})
export class SkySwitchPartIL_Rackpinion extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public skylightMotorSample: SkySwitchSampleIL_SkylightMotor; // 천창 모터 샘플
  public rackpinionRackpinionSetSample: SkySwitchSampleIL_RackpinionSet; // 랙피니언 세트 샘플
  public rackpinionAxisPipeSample: SkySwitchSampleIL_RackpinionAxisPipe; // 천창 개폐 축 파이프 샘플

  // 아이템
  public rackpinion_skylightMotor: ItemSkylightMotor;
  public rackpinion_electricWire: ItemElectricWire;
  public rackpinion_rackpinionSet: ItemSkylightSet;
  public rackpinionAxis_Pipe: ItemPipe;
  public rackpinionAxisAndrackpinionAxis_connectionPin: ItemConnector;
  public rackpinionAaxisHolder_bearingSet: ItemBearing;
  public rackpinion_rubberBar: ItemSwitcherOther;
  public axis_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _rackpinionInterval: number; // 랙피니언 간격

  /**
   * 랙피니언 간격
   */
  public get rackpinionInterval(): number {
    return this._rackpinionInterval;
  }

  //
  public set rackpinionInterval(value: number) {
    this._rackpinionInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.skylightMotorSample = new SkySwitchSampleIL_SkylightMotor();
    this.rackpinionRackpinionSetSample = new SkySwitchSampleIL_RackpinionSet();
    this.rackpinionAxisPipeSample = new SkySwitchSampleIL_RackpinionAxisPipe();

    this.sampleAC = [this.skylightMotorSample, this.rackpinionRackpinionSetSample, this.rackpinionAxisPipeSample];
    // 아이템
    this.rackpinion_skylightMotor = new ItemSkylightMotor();
    this.rackpinion_electricWire = new ItemElectricWire();
    this.rackpinion_rackpinionSet = new ItemSkylightSet();
    this.rackpinionAxis_Pipe = new ItemPipe();
    this.rackpinionAxisAndrackpinionAxis_connectionPin = new ItemConnector();
    this.rackpinionAaxisHolder_bearingSet = new ItemBearing();
    this.rackpinion_rubberBar = new ItemSwitcherOther();
    this.axis_screw = new ItemScrew();

    this.itemAC = [
      this.rackpinion_skylightMotor,
      this.rackpinion_electricWire,
      this.rackpinion_rackpinionSet,
      this.rackpinionAxis_Pipe,
      this.rackpinionAxisAndrackpinionAxis_connectionPin,
      this.rackpinionAaxisHolder_bearingSet,
      this.rackpinion_rubberBar,
      this.axis_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.skylightMotorSample = <SkySwitchSampleIL_SkylightMotor>this.sampleAC[0];
    this.rackpinionRackpinionSetSample = <SkySwitchSampleIL_RackpinionSet>this.sampleAC[1];
    this.rackpinionAxisPipeSample = <SkySwitchSampleIL_RackpinionAxisPipe>this.sampleAC[2];

    // 아이템
    this.rackpinion_skylightMotor = <ItemSkylightMotor>this.itemAC[0];
    this.rackpinion_electricWire = <ItemElectricWire>this.itemAC[1];
    this.rackpinion_rackpinionSet = <ItemSkylightSet>this.itemAC[2];
    this.rackpinionAxis_Pipe = <ItemPipe>this.itemAC[3];
    this.rackpinionAxisAndrackpinionAxis_connectionPin = <ItemConnector>this.itemAC[4];
    this.rackpinionAaxisHolder_bearingSet = <ItemBearing>this.itemAC[5];
    this.rackpinion_rubberBar = <ItemSwitcherOther>this.itemAC[6];
    this.axis_screw = <ItemScrew>this.itemAC[7];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.skylightMotorSample.setDefaultData(
        0,
        CONST.ITEM_ID_SKYLIGHT_MOTOR,
        CONST.ITEM_NAME_SKYLIGHT_MOTOR,
        "천창 개폐",
        "천창용, 34mm, 용접",
        "청오엔지니어링, 81042D, 26kg·m, 200W",
      );

      this.rackpinionRackpinionSetSample.setDefaultData(
        1,
        CONST.ITEM_ID_RACKPINION_SET,
        CONST.ITEM_NAME_RACKPINION_SET,
        "천창 개폐",
        "일반, 32mm, 1m",
      );

      this.rackpinionAxisPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 개폐 축 파이프",
        "원형, 일반, 33.5mm, 2.1T, 6m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.rackpinion_skylightMotor.setDefaultData(
        0,
        CONST.ITEM_ID_SKYLIGHT_MOTOR,
        CONST.ITEM_NAME_SKYLIGHT_MOTOR,
        "천창 개폐",
        "천창용, 34mm, 용접",
        "청오엔지니어링, 81042D, 26kg·m, 200W",
      );

      this.rackpinion_electricWire.setDefaultData(
        1,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "천창 모터 개폐",
        "VCTF, 1.5sq, 2C, 100m",
      );

      this.rackpinion_rackpinionSet.setDefaultData(
        2,
        CONST.ITEM_ID_RACKPINION_SET,
        CONST.ITEM_NAME_RACKPINION_SET,
        "천창 개폐",
        "일반, 32mm, 1m",
      );

      this.rackpinionAxis_Pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 개폐 축",
        "원형, 일반, 33.5mm, 2.1T, 6m, -",
      );

      this.rackpinionAxisAndrackpinionAxis_connectionPin.setDefaultData(
        4,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "천창 개폐 축 + 천창 개폐 축",
        "원형, 일반, 32mm, 32mm",
      );

      this.rackpinionAaxisHolder_bearingSet.setDefaultData(
        5,
        CONST.ITEM_ID_BEARING_SET,
        CONST.ITEM_NAME_BEARING_SET,
        "천창 개폐 축",
        "원형, 일반, 32mm, 32mm",
      );

      this.rackpinion_rubberBar.setDefaultData(
        6,
        CONST.ITEM_ID_RUBBER_BAR,
        CONST.ITEM_NAME_RUBBER_BAR,
        "천창끝",
        "100m",
      );

      this.axis_screw.setDefaultData(
        7,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "천창 개폐",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_rackpinionInterval();
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skySwitchPosition: SkySwitchPositionIL = this.position as SkySwitchPositionIL;
    const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;
    let widthQuanity: number;
    let heightQuantity: number;
    let lengthQuantity: number;

    /// //////// 선택, 가시성 ///////////

    this.rackpinion_skylightMotor.selected = true;
    this.rackpinion_skylightMotor.visible = true;
    this.rackpinion_electricWire.selected = true;
    this.rackpinion_electricWire.visible = true;
    this.rackpinion_rackpinionSet.selected = true;
    this.rackpinion_rackpinionSet.visible = true;
    this.rackpinionAxis_Pipe.selected = true;
    this.rackpinionAxis_Pipe.visible = true;
    this.rackpinionAxisAndrackpinionAxis_connectionPin.selected = true;
    this.rackpinionAxisAndrackpinionAxis_connectionPin.visible = true;
    this.rackpinionAaxisHolder_bearingSet.selected = true;
    this.rackpinionAaxisHolder_bearingSet.visible = true;
    this.rackpinion_rubberBar.selected = false;
    this.rackpinion_rubberBar.visible = false;
    this.axis_screw.selected = false;
    this.axis_screw.visible = false;

    /// //////// 수량 ///////////
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      // 연동형인 경우
      this.rackpinion_skylightMotor.designQuantity = skySwitchPosition.switchWayValue * this.basic.buildingNumber;

      widthQuanity =
        ((this.basicLevel.width + this.basic.buildingInterval) *
          this.basic.buildingNumber *
          (this.basic.buildingNumber + 1)) /
        2;
      heightQuantity = (this.basicLevel.heightMax + 1) * this.basic.buildingNumber;
      lengthQuantity = (this.basicLevel.length / 2) * this.basic.buildingNumber;
      this.rackpinion_electricWire.designQuantity =
        (((widthQuanity + heightQuantity + lengthQuantity) * skySwitchPosition.switchWayValue) /
          this.rackpinion_electricWire.specLength) *
        CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;

      this.rackpinion_rackpinionSet.designQuantity =
        (CommonUtil.roundUp(skyFramePosition.skylightLength / this.rackpinionInterval) + 1) *
        skySwitchPosition.switchWayValue *
        this.basic.buildingNumber;

      this.rackpinionAxis_Pipe.designQuantity =
        this.rackpinionAxis_Pipe.getConnectedPipeLength(
          skyFramePosition.skylightLength,
          this.basic.buildingNumber * skySwitchPosition.switchWayValue,
          this.rackpinionAxis_Pipe.specLength / 2,
        ) / this.rackpinionAxis_Pipe.specLength;

      if (skyFramePosition.skylightLength > this.rackpinionAxis_Pipe.specLength) {
        this.rackpinionAxisAndrackpinionAxis_connectionPin.designQuantity =
          this.rackpinionAxis_Pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
      } else {
        this.rackpinionAxisAndrackpinionAxis_connectionPin.designQuantity = 0;
      }

      this.rackpinionAaxisHolder_bearingSet.designQuantity =
        (CommonUtil.roundUp(skyFramePosition.skylightLength / this.rackpinionInterval) + 1) *
        skySwitchPosition.switchWayValue *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_BEARING_SET;

      const rackpinion_rubberBarSpecLength = parseFloat(this.rackpinion_rubberBar.specsList);
      this.rackpinion_rubberBar.designQuantity =
        (skyFramePosition.skylightLength * skySwitchPosition.switchWayValue * this.basic.buildingNumber) /
        rackpinion_rubberBarSpecLength;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 벤로형인 경우
      const rafterValleyQuantity: number = this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterValleyQuantity;
      this.rackpinion_skylightMotor.designQuantity = skySwitchPosition.switchWayValue * this.basic.buildingNumber;

      widthQuanity =
        ((this.basicLevel.width + this.basic.buildingInterval) *
          this.basic.buildingNumber *
          (this.basic.buildingNumber + 1)) /
        2;
      heightQuantity = (this.basicLevel.heightMax + 1) * this.basic.buildingNumber;
      lengthQuantity = (this.basicLevel.length / 2) * this.basic.buildingNumber;
      this.rackpinion_electricWire.designQuantity =
        (((widthQuanity + heightQuantity + lengthQuantity) * skySwitchPosition.switchWayValue) /
          this.rackpinion_electricWire.specLength) *
        CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;

      this.rackpinion_rackpinionSet.designQuantity =
        (CommonUtil.roundUp(skyFramePosition.skylightLength / this.rackpinionInterval) + 1) *
        skySwitchPosition.switchWayValue *
        this.basic.buildingNumber *
        rafterValleyQuantity;

      this.rackpinionAxis_Pipe.designQuantity =
        this.rackpinionAxis_Pipe.getConnectedPipeLength(
          skyFramePosition.skylightLength,
          skySwitchPosition.switchWayValue * rafterValleyQuantity * this.basic.buildingNumber,
          this.rackpinionAxis_Pipe.specLength / 2,
        ) / this.rackpinionAxis_Pipe.specLength;

      if (skyFramePosition.skylightLength > this.rackpinionAxis_Pipe.specLength) {
        this.rackpinionAxisAndrackpinionAxis_connectionPin.designQuantity =
          this.rackpinionAxis_Pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
      } else {
        this.rackpinionAxisAndrackpinionAxis_connectionPin.designQuantity = 0;
      }

      this.rackpinionAaxisHolder_bearingSet.designQuantity =
        (CommonUtil.roundUp(skyFramePosition.skylightLength / this.rackpinionInterval) + 1) *
        skySwitchPosition.switchWayValue *
        this.basic.buildingNumber *
        rafterValleyQuantity *
        CONST.NUM_EXTRA_RATE_BEARING_SET;

      const rackpinion_rubberBarSpecLength = parseFloat(this.rackpinion_rubberBar.specsList);
      this.rackpinion_rubberBar.designQuantity =
        (skyFramePosition.skylightLength *
          skySwitchPosition.switchWayValue *
          this.basic.buildingNumber *
          rafterValleyQuantity) /
        rackpinion_rubberBarSpecLength;
    }

    this.axis_screw.designQuantity = 0;
  }

  /**
   * 알고리즘: 랙피니언 간격 <- 창살 간격(천창 골조 > 랙피니언 파트)
   */
  public algorithm_rackpinionInterval(): void {
    const frameWindowBarInterval = this.struct.skyFrameWorkIL.level1.skylightPosition.rackpinionPart.windowBarInterval;

    this.rackpinionInterval = frameWindowBarInterval * 2;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 개폐] 천창 모터 <- 천창 모터 샘플, 천창 개폐 축 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_rackpinion_skylightMotor(): void {
    let roofPosition: RoofPositionIL | RoofPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPosition = this.struct.roofWorkIL.level1.roofPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
    }

    this.rackpinion_skylightMotor.specUsage = this.skylightMotorSample.specUsage;
    this.rackpinion_skylightMotor.specAxisCoupling = this.rackpinionAxisPipeSample.specAxisCoupling;
    this.rackpinion_skylightMotor.specSupportSize = roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 모터 개폐] 전선 <- 천창 모터용 전선 샘플  ---확인 필요
   */
  public algorithmSpec_rackpinion_electricWire(): void {
    this.rackpinion_electricWire.specs =
      this.struct.controllerWorkIL.level1.integratedPosition.controllerPart.switcherElectricWireSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 개폐] 랙피니언 세트 <- 랙피니언 세트 샘플
   */
  public algorithmSpec_rackpinion_rackpinionSet(): void {
    this.rackpinion_rackpinionSet.specs = this.rackpinionRackpinionSetSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 개폐 축] 천창 개폐 축 파이프 <- 천창 개폐 축 파이프 샘플
   */
  public algorithmSpec_rackpinionAxis_Pipe(): void {
    this.rackpinionAxis_Pipe.specs = this.rackpinionAxisPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 개폐 축 + 천창 개폐 축] 연결핀 <- 천창 개폐 축 파이프 샘플
   */
  public algorithmSpec_rackpinionAxisAndrackpinionAxis_connectionPin(): void {
    this.rackpinionAxisAndrackpinionAxis_connectionPin.specPipeType = this.rackpinionAxisPipeSample.specPipeType;
    this.rackpinionAxisAndrackpinionAxis_connectionPin.specCrossSize1 = this.rackpinionAxisPipeSample.specCrossSize;
    this.rackpinionAxisAndrackpinionAxis_connectionPin.specCrossSize2 = this.rackpinionAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 개폐 축 + 서까래] 축수 베어링 세트 <- 천창 개폐 축 파이프 샘플
   */
  public algorithmSpec_rackpinionAaxisHolder_bearingSet(): void {
    let roofPosition: RoofPositionIL | RoofPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPosition = this.struct.roofWorkIL.level1.roofPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
    }

    this.rackpinionAaxisHolder_bearingSet.specPipeType = this.rackpinionAxisPipeSample.specPipeType;
    this.rackpinionAaxisHolder_bearingSet.specCrossSize1 = this.rackpinionAxisPipeSample.specCrossSize;
    this.rackpinionAaxisHolder_bearingSet.specCrossSize2 = roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창끝] 고무바
   */
  public algorithmSpec_rackpinion_rubberBar(): void {}

  /**
   * 규격 알고리즘: [천창 개폐] 나사 <- 천창 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
