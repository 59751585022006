import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWateringConnect } from "vhows-design/src/object/design/item/list/ItemWateringConnect";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringValve } from "vhows-design/src/object/design/item/list/ItemWateringValve";
import { Part } from "vhows-design/src/object/design/base/Part";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { WateringSample_HoseConnectPePipe } from "vhows-design/src/object/design/watering/watering/WateringSample_HoseConnectPePipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [WateringSample_HoseConnectPePipe],
})
export class WateringPart_HoseConnect extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public subPePipeSample: WateringSample_HoseConnectPePipe; // 보조관 샘플

  // 아이템
  public sub_pePipe: ItemWateringPipe;
  public subAndMain_exactT: ItemWateringConnect;
  public subAndMain_differentT: ItemWateringConnect;
  public sub_unionValve: ItemWateringValve;
  public sub_exactT: ItemWateringConnect;
  public subAndHose_elbow: ItemWateringConnect;
  public subAndHose_waterScreenConnectSocket: ItemWateringConnect;
  public sub_valveSocket: ItemWateringConnect;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wateringBuildingNumber: number = 0; // 관수 동수
  @jsonMember(Number)
  public _subPipeLength: number; // 보조관 길이

  /**
   * 관수 동수
   */
  public get wateringBuildingNumber(): number {
    return this._wateringBuildingNumber;
  }

  //
  public set wateringBuildingNumber(value: number) {
    this._wateringBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 보조관 길이
   */
  public get subPipeLength(): number {
    return this._subPipeLength;
  }

  //
  public set subPipeLength(value: number) {
    this._subPipeLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.subPePipeSample = new WateringSample_HoseConnectPePipe();

    this.sampleAC = [this.subPePipeSample];

    // 아이템
    this.sub_pePipe = new ItemWateringPipe();
    this.subAndMain_exactT = new ItemWateringConnect();
    this.subAndMain_differentT = new ItemWateringConnect();
    this.sub_unionValve = new ItemWateringValve();
    this.sub_exactT = new ItemWateringConnect();
    this.subAndHose_elbow = new ItemWateringConnect();
    this.subAndHose_waterScreenConnectSocket = new ItemWateringConnect();
    this.sub_valveSocket = new ItemWateringConnect();

    this.itemAC = [
      this.sub_pePipe,
      this.subAndMain_exactT,
      this.subAndMain_differentT,
      this.sub_unionValve,
      this.sub_exactT,
      this.subAndHose_elbow,
      this.subAndHose_waterScreenConnectSocket,
      this.sub_valveSocket,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.subPePipeSample = <WateringSample_HoseConnectPePipe>this.sampleAC[0];
    // 아이템
    this.sub_pePipe = <ItemWateringPipe>this.itemAC[0];
    this.subAndMain_exactT = <ItemWateringConnect>this.itemAC[1];
    this.subAndMain_differentT = <ItemWateringConnect>this.itemAC[2];
    this.sub_unionValve = <ItemWateringValve>this.itemAC[3];
    this.sub_exactT = <ItemWateringConnect>this.itemAC[4];
    this.subAndHose_elbow = <ItemWateringConnect>this.itemAC[5];
    this.subAndHose_waterScreenConnectSocket = <ItemWateringConnect>this.itemAC[6];
    this.sub_valveSocket = <ItemWateringConnect>this.itemAC[7];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.subPePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "보조관(주관~분수호스관)",
        "연질관, 30mm, 100m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.sub_pePipe.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "보조관(주관~분수호스관)",
        "연질관, 30mm, 100m",
      );
      this.subAndMain_exactT.setDefaultData(
        1,
        CONST.ITEM_ID_EXACT_T,
        CONST.ITEM_NAME_EXACT_T,
        "보조관(주관~분수호스관)",
        "PE, 소켓, 30mm, 30mm",
      );
      this.subAndMain_differentT.setDefaultData(
        2,
        CONST.ITEM_ID_DIFFERENT_T,
        CONST.ITEM_NAME_DIFFERENT_T,
        "보조관(주관~분수호스관)",
        "PE, 소켓, 50mm, 30mm",
      );
      this.sub_unionValve.setDefaultData(
        3,
        CONST.ITEM_ID_UNION_VALVE,
        CONST.ITEM_NAME_UNION_VALVE,
        "보조관(주관~분수호스관)",
        "PE, 일반, 30mm, 30mm",
      );
      this.sub_exactT.setDefaultData(
        4,
        CONST.ITEM_ID_EXACT_T,
        CONST.ITEM_NAME_EXACT_T,
        "보조관(주관~분수호스관)",
        "PE, 소켓, 30mm, 30mm",
      );
      this.subAndHose_elbow.setDefaultData(
        5,
        CONST.ITEM_ID_ELBOW,
        CONST.ITEM_NAME_ELBOW,
        "보조관(주관~분수호스관)",
        "PE, 소켓, 30mm, 30mm",
      );
      this.subAndHose_waterScreenConnectSocket.setDefaultData(
        6,
        CONST.ITEM_ID_WATER_SCREEN_CONNECT_SOCKET,
        CONST.ITEM_NAME_WATER_SCREEN_CONNECT_SOCKET,
        "보조관(주관~분수호스관)",
        "PE, 일반, 30mm, -",
      );
      this.sub_valveSocket.setDefaultData(
        7,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "보조관(주관~분수호스관)",
        "PE, 일반, 30mm, -",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_subPipeLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const wateringPosition: WateringPosition = <WateringPosition>this.position;
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;

    /// //////// 선택, 가시성 ///////////

    this.sub_pePipe.selected = true;
    this.sub_pePipe.visible = true;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      if (this.subPePipeSample.specDiameter === mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter) {
        this.subAndMain_exactT.selected = true;
        this.subAndMain_exactT.visible = true;
        this.subAndMain_differentT.selected = false;
        this.subAndMain_differentT.visible = false;
      } else {
        this.subAndMain_exactT.selected = false;
        this.subAndMain_exactT.visible = false;
        this.subAndMain_differentT.selected = true;
        this.subAndMain_differentT.visible = true;
      }
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.subAndMain_exactT.selected = false;
      this.subAndMain_exactT.visible = false;
      this.subAndMain_differentT.selected = false;
      this.subAndMain_differentT.visible = false;
    }
    this.sub_unionValve.selected = true;
    this.sub_unionValve.visible = true;
    this.sub_exactT.selected = true;
    this.sub_exactT.visible = true;
    this.subAndHose_elbow.selected = true;
    this.subAndHose_elbow.visible = true;
    // 설계설정 <----------------- 개발해야함
    this.subAndHose_waterScreenConnectSocket.selected = false;
    this.subAndHose_waterScreenConnectSocket.visible = false;
    this.sub_valveSocket.selected = true;
    this.sub_valveSocket.visible = true;

    /// ////////  수치 ///////////

    let unionValveQuantity: number = 0;
    let exactTQuantity: number = 0;
    let elbowQuantity: number = 0;
    let waterScreenQuantity: number = 0;
    if (wateringPosition.type === CONST.LB_WATERING_TYPE_SCREEN_FOUNTAIN_HOSE) {
      unionValveQuantity += 1;
      elbowQuantity += wateringPosition.hoseScreenPart.hoseLineNumber;
      waterScreenQuantity += wateringPosition.hoseScreenPart.hoseLineNumber;
    }
    if (wateringPosition.upperType === CONST.LB_WATERING_UPPER_TYPE_UPPER_FOUNTAIN_HOSE) {
      unionValveQuantity += 1;
      exactTQuantity += wateringPosition.hoseUpperPart.hoseLineNumber;
      elbowQuantity += wateringPosition.hoseUpperPart.hoseLineNumber;
    } else if (wateringPosition.upperType === CONST.LB_WATERING_UPPER_TYPE_UPPER_SIDE_FOUNTAIN_HOSE) {
      unionValveQuantity += 1;
      exactTQuantity += wateringPosition.hoseUpperPart.hoseLineNumber;
      exactTQuantity += wateringPosition.hoseSidePart.hoseLineNumber;
      elbowQuantity += wateringPosition.hoseUpperPart.hoseLineNumber;
      elbowQuantity += wateringPosition.hoseSidePart.hoseLineNumber;
    }
    if (wateringPosition.lowerType === CONST.LB_WATERING_LOWER_TYPE_LOWER_FOUNTAIN_HOSE) {
      unionValveQuantity += 1;
      exactTQuantity += wateringPosition.hoseLowerPart.hoseLineNumber;
      elbowQuantity += wateringPosition.hoseLowerPart.hoseLineNumber;
    } else if (wateringPosition.lowerType === CONST.LB_WATERING_LOWER_TYPE_MULCHING_FOUNTAIN_HOSE) {
      unionValveQuantity += 1;
      exactTQuantity += wateringPosition.hoseMulchingPart.hoseLineNumber;
      elbowQuantity += wateringPosition.hoseMulchingPart.hoseLineNumber;
    }

    /// ////////  수량 ///////////

    this.sub_pePipe.designQuantity = (this.subPipeLength * this.wateringBuildingNumber) / this.sub_pePipe.specLength;

    this.subAndMain_exactT.designQuantity = 1 * this.wateringBuildingNumber;

    this.subAndMain_differentT.designQuantity = 1 * this.wateringBuildingNumber;

    this.sub_unionValve.designQuantity = unionValveQuantity * this.wateringBuildingNumber;

    this.sub_exactT.designQuantity = exactTQuantity * this.wateringBuildingNumber;

    this.subAndHose_elbow.designQuantity = elbowQuantity * this.wateringBuildingNumber;

    this.subAndHose_waterScreenConnectSocket.designQuantity = waterScreenQuantity * this.wateringBuildingNumber;

    // sub_valveSocket.designQuantity = 0;
  }

  /**
   * 알고리즘: 관수 동수 <- 관수 동수(관수 제어부)
   */
  public algorithm_wateringBuildingNumber(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    this.wateringBuildingNumber = mainpipePosition.wateringBuildingNumber;
  }

  /**
   * 알고리즘: 보조관 길이 <- 기본정보, 각 분수호스 줄수, 형태, 상단 형태, 하단 형태, 형태(주관), 주관2 위치(주관)
   */
  public algorithm_subPipeLength(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    let tempLength: number = 0;
    let commonLength: number = 0;
    let eachLength: number = 0;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      if (mainpipePosition.peMain2Part.main2Position === CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_LOWER) {
        if ((<WateringPosition>this.position).hoseScreenPart.selected === true) {
          tempLength = this.basicLevel.heightMax;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseScreenPart.hoseLineNumber;
        }
        if ((<WateringPosition>this.position).hoseUpperPart.selected === true) {
          tempLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].heightShoulder + 1;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseUpperPart.hoseLineNumber;
        }
        if ((<WateringPosition>this.position).hoseSidePart.selected === true) {
          tempLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].heightShoulder;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength =
            eachLength + (this.basicLevel.width / 2) * (<WateringPosition>this.position).hoseSidePart.hoseLineNumber;
        }
        if ((<WateringPosition>this.position).hoseLowerPart.selected === true) {
          tempLength = 1;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseLowerPart.hoseLineNumber;
        }
        if ((<WateringPosition>this.position).hoseMulchingPart.selected === true) {
          tempLength = 1;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseMulchingPart.hoseLineNumber;
        }
      } else if (mainpipePosition.peMain2Part.main2Position === CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_UPPER) {
        if ((<WateringPosition>this.position).hoseScreenPart.selected === true) {
          tempLength = this.basicLevel.heightRoofMax;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseScreenPart.hoseLineNumber;
        }
        if ((<WateringPosition>this.position).hoseUpperPart.selected === true) {
          tempLength = 1;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseUpperPart.hoseLineNumber;
        }
        if ((<WateringPosition>this.position).hoseSidePart.selected === true) {
          tempLength = 1;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength =
            eachLength + (this.basicLevel.width / 2) * (<WateringPosition>this.position).hoseSidePart.hoseLineNumber;
        }
        if ((<WateringPosition>this.position).hoseLowerPart.selected === true) {
          tempLength = 1;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseLowerPart.hoseLineNumber;
        }
        if ((<WateringPosition>this.position).hoseMulchingPart.selected === true) {
          tempLength = 1;
          if (commonLength < tempLength) {
            commonLength = tempLength;
          }
          eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseMulchingPart.hoseLineNumber;
        }
      }
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      if ((<WateringPosition>this.position).hoseScreenPart.selected === true) {
        commonLength = 0;
        eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseScreenPart.hoseLineNumber;
      }
      if ((<WateringPosition>this.position).hoseUpperPart.selected === true) {
        commonLength = 0;
        eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseUpperPart.hoseLineNumber;
      }
      if ((<WateringPosition>this.position).hoseSidePart.selected === true) {
        commonLength = 0;
        eachLength =
          eachLength + (this.basicLevel.width / 2) * (<WateringPosition>this.position).hoseSidePart.hoseLineNumber;
      }
      if ((<WateringPosition>this.position).hoseLowerPart.selected === true) {
        commonLength = 0;
        eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseLowerPart.hoseLineNumber;
      }
      if ((<WateringPosition>this.position).hoseMulchingPart.selected === true) {
        commonLength = 0;
        eachLength = eachLength + 2.5 * (<WateringPosition>this.position).hoseMulchingPart.hoseLineNumber;
      }
    }
    // 최종 길이
    this.subPipeLength = commonLength + eachLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [보조관(주관~분수호스관)] PE관 <- 보조관 샘플
   */
  public algorithmSpec_sub_pePipe(): void {
    this.sub_pePipe.specs = this.subPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [보조관(주관~분수호스관)]  <- 보조관 샘플
   */
  public algorithmSpec_subAndMain_exactT(): void {
    this.subAndMain_exactT.specDiameter1 = this.subPePipeSample.specDiameter;
    this.subAndMain_exactT.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~분수호스관)]  <- 형태(주관), 주관2 샘플(중앙공급형,개별공급형), 보조관 샘플
   */
  public algorithmSpec_subAndMain_differentT(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.subAndMain_differentT.specDiameter1 = mainpipePosition.peMain2Part.main2CenterPePipeSample.specDiameter;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.subAndMain_differentT.specDiameter1 = mainpipePosition.peMain2Part.main2EachPePipeSample.specDiameter;
    }
    this.subAndMain_differentT.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~분수호스관)] 유니온 밸브 <- 보조관 샘플
   */
  public algorithmSpec_sub_unionValve(): void {
    this.sub_unionValve.specDiameter1 = this.subPePipeSample.specDiameter;
    this.sub_unionValve.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~분수호스관)] 정티 <- 보조관 샘플
   */
  public algorithmSpec_sub_exactT(): void {
    this.sub_exactT.specDiameter1 = this.subPePipeSample.specDiameter;
    this.sub_exactT.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~분수호스관)] 엘보 <- 보조관 샘플
   */
  public algorithmSpec_subAndHose_elbow(): void {
    this.subAndHose_elbow.specDiameter1 = this.subPePipeSample.specDiameter;
    this.subAndHose_elbow.specDiameter2 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~분수호스관)] 수막 연결 소켓 <- 보조관 샘플
   */
  public algorithmSpec_subAndHose_waterScreenConnectSocket(): void {
    this.subAndHose_waterScreenConnectSocket.specDiameter1 = this.subPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘: [보조관(주관~분수호스관)] 밸브 소켓 <- 보조관 샘플
   */
  public algorithmSpec_sub_valveSocket(): void {
    this.sub_valveSocket.specDiameter1 = this.subPePipeSample.specDiameter;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
