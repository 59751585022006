import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";
import { BandstringPosition } from "vhows-design/src/object/design/cover/bandstring/BandstringPosition";
import { BandstringSample_NormalPad } from "vhows-design/src/object/design/cover/bandstring/BandstringSample_NormalPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-06-25
 */
@jsonObject({
  knownTypes: [BandstringSample_NormalPad],
})
export class BandstringPart_FixingNormalPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalPadSample: BandstringSample_NormalPad; // 일반 패드 샘플

  // 아이템
  public normalPad_normalPad: ItemPad;
  public normalPad_normalPadSpring: ItemPadSpring;
  public normalPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalPadSample = new BandstringSample_NormalPad();

    this.sampleAC = [this.normalPadSample];

    // 아이템
    this.normalPad_normalPad = new ItemPad();
    this.normalPad_normalPadSpring = new ItemPadSpring();
    this.normalPad_screw = new ItemScrew();

    this.itemAC = [this.normalPad_normalPad, this.normalPad_normalPadSpring, this.normalPad_screw];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalPadSample = this.sampleAC[0] as BandstringSample_NormalPad;

    // 아이템
    this.normalPad_normalPad = this.itemAC[0] as ItemPad;
    this.normalPad_normalPadSpring = this.itemAC[1] as ItemPadSpring;
    this.normalPad_screw = this.itemAC[2] as ItemScrew;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.normalPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "패드 고정",
        "칼라 철, 0.6T, 6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalPad_normalPad.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드",
        "칼라 철, 0.6T, 6m",
      );
      this.normalPad_normalPadSpring.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "일반 패드",
        "일반, 2m",
      );
      this.normalPad_screw.setDefaultData(
        2,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "일반 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const bandstringPosition: BandstringPosition = this.position as BandstringPosition;
    // 외부
    const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    /// //////// 선택, 가시성 ///////////

    this.normalPad_normalPad.selected = true;
    this.normalPad_normalPad.visible = true;
    this.normalPad_normalPadSpring.selected = true;
    this.normalPad_normalPadSpring.visible = true;
    this.normalPad_screw.selected = true;
    this.normalPad_screw.visible = true;

    /// //////// 수량 ///////////

    this.normalPad_normalPad.designQuantity =
      ((trunkPosition.rafterPart.rafterInterval + 0.1) *
        (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
        2 *
        this.basic.buildingNumber) /
      this.normalPad_normalPad.specLength;

    if (skyFramePosition.selected === false) {
      this.normalPad_normalPadSpring.designQuantity =
        this.normalPad_normalPad.getSelectedQuantity() *
        (this.normalPad_normalPad.specLength / this.normalPad_normalPadSpring.specLength) *
        CONST.NUM_EXTRA_RATE_PAD_SPRING;
    } else {
      this.normalPad_normalPadSpring.designQuantity =
        this.normalPad_normalPad.getSelectedQuantity() *
        (this.normalPad_normalPad.specLength / this.normalPad_normalPadSpring.specLength) *
        2 *
        CONST.NUM_EXTRA_RATE_PAD_SPRING;
    }

    this.normalPad_screw.designQuantity =
      ((this.normalPad_normalPad.getSelectedQuantity() * 12) / this.normalPad_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 패드] 일반 패드 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPad_normalPad(): void {
    this.normalPad_normalPad.specs = this.normalPadSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 패드] 일반 패드 스프링 <- 일반 패드 스프링 샘플(기초 피복 고정)
   */
  public algorithmSpec_normalPad_normalPadSpring(): void {
    const fixingLevel: FixingLevel = this.struct.fixingWork.level1;
    this.normalPad_normalPadSpring.specMaterial =
      fixingLevel.sidePosition.normalPadPart.normalPadSpringSample.specMaterial; // 측면을 따름
  }

  /**
   * 규격 알고리즘: [일반 패드] 나사 <- 공통 샘플
   */
  public algorithmSpec_normalPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
