import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { MainpipeSample_UpperWateringFixPipe } from "vhows-design/src/object/design/watering/mainpipe/MainpipeSample_UpperWateringFixPipe";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { StructIL } from "vhows-design/src/object/design/base/StructIL";
import { StructVL } from "vhows-design/src/object/design/base/StructVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [MainpipeSample_UpperWateringFixPipe],
})
export class MainpipePart_UpperFrame extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public wateringFixPipeSample: MainpipeSample_UpperWateringFixPipe; // 관수 고정 파이프 샘플

  // 아이템
  public wateringFix_pipe: ItemPipe;
  public wateringFix_connectionPin: ItemConnector;
  public wateringFixAndMiddleCylinder_steelStringGrabber: ItemHolder;
  public wateringFixAndMiddlePrism_saddleHolder: ItemHolder;
  public wateringFix_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wateringBuildingNumber: number; // 관수 동수
  @jsonMember(Number)
  public _wateringFixLength: number = 0; // 관수 고정 길이
  @jsonMember(Number)
  public _wateringFixLineNumber: number; // 관수 고정 줄수

  /**
   * 관수 동수
   */
  public get wateringBuildingNumber(): number {
    return this._wateringBuildingNumber;
  }

  //
  public set wateringBuildingNumber(value: number) {
    this._wateringBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 관수 고정 길이
   */
  public get wateringFixLength(): number {
    return this._wateringFixLength;
  }

  //
  public set wateringFixLength(value: number) {
    this._wateringFixLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 관수 고정 줄수
   */
  public get wateringFixLineNumber(): number {
    return this._wateringFixLineNumber;
  }

  //
  public set wateringFixLineNumber(value: number) {
    this._wateringFixLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.wateringFixPipeSample = new MainpipeSample_UpperWateringFixPipe();

    this.sampleAC = [this.wateringFixPipeSample];

    // 아이템
    this.wateringFix_pipe = new ItemPipe();
    this.wateringFix_connectionPin = new ItemConnector();
    this.wateringFixAndMiddleCylinder_steelStringGrabber = new ItemHolder();
    this.wateringFixAndMiddlePrism_saddleHolder = new ItemHolder();
    this.wateringFix_screw = new ItemScrew();

    this.itemAC = [
      this.wateringFix_pipe,
      this.wateringFix_connectionPin,
      this.wateringFixAndMiddleCylinder_steelStringGrabber,
      this.wateringFixAndMiddlePrism_saddleHolder,
      this.wateringFix_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.wateringFixPipeSample = <MainpipeSample_UpperWateringFixPipe>this.sampleAC[0];
    // 아이템
    this.wateringFix_pipe = <ItemPipe>this.itemAC[0];
    this.wateringFix_connectionPin = <ItemConnector>this.itemAC[1];
    this.wateringFixAndMiddleCylinder_steelStringGrabber = <ItemHolder>this.itemAC[2];
    this.wateringFixAndMiddlePrism_saddleHolder = <ItemHolder>this.itemAC[3];
    this.wateringFix_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param wateringBuildingNumber: number 관수 동수
   * @param wateringFixLineNumber: number 관수 고정 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    wateringBuildingNumber: number = 0,
    wateringFixLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._wateringBuildingNumber = wateringBuildingNumber;
    this._wateringFixLineNumber = wateringFixLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.wateringFixPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "관수 고정",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.wateringFix_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "관수 고정",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.wateringFix_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "관수 고정+관수 고정",
        "원형, 일반, 25mm, 25mm",
      );
      this.wateringFixAndMiddleCylinder_steelStringGrabber.setDefaultData(
        2,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "관수 고정+중방",
        "원형, 일반, 48mm, 25mm",
      );
      this.wateringFixAndMiddlePrism_saddleHolder.setDefaultData(
        3,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "관수 고정+중방",
        "원형, 일반, 25mm, -",
      );
      this.wateringFix_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "관수 고정",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_wateringFixLength();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_wateringFixAndMiddleCylinder_steelStringGrabber();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    let columnPosition: ColumnPositionIL | ColumnPositionVL;

    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 선택, 가시성 ///////////

    this.wateringFix_pipe.checkSelected(true);
    this.wateringFix_connectionPin.checkSelected(true);
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.wateringFixAndMiddleCylinder_steelStringGrabber.selected = true;
        this.wateringFixAndMiddleCylinder_steelStringGrabber.visible = true;
        this.wateringFixAndMiddlePrism_saddleHolder.selected = false;
        this.wateringFixAndMiddlePrism_saddleHolder.visible = false;
      } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.wateringFixAndMiddleCylinder_steelStringGrabber.selected = false;
        this.wateringFixAndMiddleCylinder_steelStringGrabber.visible = false;
        this.wateringFixAndMiddlePrism_saddleHolder.selected = true;
        this.wateringFixAndMiddlePrism_saddleHolder.visible = true;
      }
    } else {
      this.wateringFixAndMiddleCylinder_steelStringGrabber.selected = false;
      this.wateringFixAndMiddleCylinder_steelStringGrabber.visible = false;
      this.wateringFixAndMiddlePrism_saddleHolder.selected = false;
      this.wateringFixAndMiddlePrism_saddleHolder.visible = false;
    }

    this.wateringFix_screw.selected = true;
    this.wateringFix_screw.visible = true;

    /// ////////  수량 ///////////

    this.wateringFix_pipe.designQuantity =
      (this.wateringFix_pipe.getConnectedPipeLength(
        this.wateringFixLength,
        this.wateringFixLineNumber * this.wateringBuildingNumber,
      ) /
        this.wateringFix_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    if (this.wateringFixLength > this.wateringFix_pipe.specLength) {
      this.wateringFix_connectionPin.designQuantity =
        this.wateringFix_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.wateringFix_connectionPin.designQuantity = 0;
    }

    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      this.wateringFixAndMiddleCylinder_steelStringGrabber.designQuantity =
        this.struct.columnWorkIL.level1.columnPosition.cylinderMiddlePart.middleLineNumber *
        this.wateringFixLineNumber *
        this.wateringBuildingNumber *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
    }

    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.wateringFixAndMiddlePrism_saddleHolder.designQuantity =
        columnPosition.prismMiddlePart.middleLineNumber *
        this.wateringFixLineNumber *
        this.wateringBuildingNumber *
        CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
    }

    this.wateringFix_screw.designQuantity =
      ((this.wateringFix_connectionPin.getSelectedQuantity() * 2 +
        this.wateringFixAndMiddlePrism_saddleHolder.getSelectedQuantity() * 3) /
        this.wateringFix_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 관수 동수 <- 관수 동수(위치)
   */
  public algorithm_wateringBuildingNumber(): void {
    this.wateringBuildingNumber = (<MainpipePosition>this.position).wateringBuildingNumber;
  }

  /**
   * 알고리즘: 관수 고정 길이 <- 기본정보
   */
  public algorithm_wateringFixLength(): void {
    this.wateringFixLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].length;
  }

  /**
   * 알고리즘: 관수 고정 줄수 <- 미니클러관 줄수(보조관/분수 미니클러)
   */
  public algorithm_wateringFixLineNumber(): void {
    const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
    this.wateringFixLineNumber = wateringPosition.miniklerFountainPart.miniklerPipeLineNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [관수 고정] 파이프 <- 관수 고정 파이프 샘플
   */
  public algorithmSpec_wateringFix_pipe(): void {
    this.wateringFix_pipe.specs = this.wateringFixPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [관수 고정+관수 고정] 고정핀 <- 관수 고정 파이프 샘플
   */
  public algorithmSpec_wateringFix_connectionPin(): void {
    this.wateringFix_connectionPin.specPipeType = this.wateringFixPipeSample.specPipeType;
    this.wateringFix_connectionPin.specCrossSize1 = this.wateringFixPipeSample.specCrossSize;
    this.wateringFix_connectionPin.specCrossSize2 = this.wateringFixPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [관수 고정+중방] 강선 조리개 <- 관수 고정 파이프 샘플, 중방 파이프 샘플(연동)
   */
  public algorithmSpec_wateringFixAndMiddleCylinder_steelStringGrabber(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
      this.wateringFixAndMiddleCylinder_steelStringGrabber.specPipeType =
        columnPosition.cylinderMiddlePart.middlePipeSample.specPipeType;
      this.wateringFixAndMiddleCylinder_steelStringGrabber.specCrossSize1 =
        columnPosition.cylinderMiddlePart.middlePipeSample.specCrossSize;
      this.wateringFixAndMiddleCylinder_steelStringGrabber.specCrossSize2 = this.wateringFixPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [관수 고정+중방] 새들 <- 관수 고정 파이프 샘플
   */
  public algorithmSpec_wateringFixAndMiddlePrism_saddleHolder(): void {
    this.wateringFixAndMiddlePrism_saddleHolder.specPipeType = this.wateringFixPipeSample.specPipeType;
    this.wateringFixAndMiddlePrism_saddleHolder.specCrossSize1 = this.wateringFixPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [관수 고정] 나사 <- 공통 샘플
   */
  public algorithmSpec_wateringFix_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
