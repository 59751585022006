import { ReactNode } from "react";

type InboundButton = {
  /**
   * 버튼 텍스트
   */
  text: string;
  /**
   * 버튼 클릭 여부
   */
  isClicked: boolean;
  /**
   * 클릭시 전달 할 값
   */
  value: string;
  /**
   * 클릭 이벤트
   */
  handleClick: Function;
  id?: string;

  subText?: string;

  checkIcon?: ReactNode;

  className?: string;
  dataCy?: string;
};

export const InboundButton = ({
  id,
  text,
  isClicked,
  value,
  handleClick,
  subText,
  checkIcon,
  className = "",
  dataCy = "",
}: InboundButton) => {
  const state = isClicked ? "Clicked" : "NotClicked";

  const makeButtonClass = (clickedStyle: boolean) => {
    let name = `py-4 border rounded-xl relative ${
      subText ? "px-5 justify-between " : "justify-center "
    } flex  items-center `;
    if (clickedStyle) {
      name += "bg-green-50 border-[#0BB25F] text-[#009454] font-medium";
    } else {
      name += "bg-[#F7F7F7] border-transparent";
    }
    return name;
  };

  const subTextClassName = `${isClicked ? "text-[#3A3B40]" : "text-[#8B8D94]"} text-[13px] font-normal mt-2`;

  return (
    <label id={id} className={`w-full cursor-pointer ${!subText && "text-center"} text-[1.063rem] ${className}`}>
      <input
        data-cy={dataCy}
        type="radio"
        name={text}
        value={value}
        className="absolute hidden"
        onClick={event => {
          handleClick(event);
        }}
        checked={isClicked}
        readOnly={true}
      />
      <div className={makeButtonClass(isClicked)}>
        <div className="flex flex-col">
          <span> {text} </span>
          {subText && <span className={subTextClassName}>{subText}</span>}
        </div>
        {state == "Clicked" && <div className={`${!subText ? "ml-1" : ""}`}>{checkIcon}</div>}
      </div>
    </label>
  );
};
