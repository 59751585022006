import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { WingPartVL_WingRafter } from "vhows-design/src/object/design/frame/wing/vl/WingPartVL_WingRafter";
import { WingPartVL_WingStraight } from "vhows-design/src/object/design/frame/wing/vl/WingPartVL_WingStraight";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class WingSampleVL_WingRafterPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const wingRafterPart: WingPartVL_WingRafter = <WingPartVL_WingRafter>this.part;
    wingRafterPart.algorithmSpec_wingRafter_pipe();
    wingRafterPart.algorithmSpec_wingRafter_pipeBendingExpense();
    wingRafterPart.algorithmSpec_wingRafterAndRoofRafter_connectionPin();
    wingRafterPart.algorithmSpec_wingRafterFirstAndBeam_outerWallClamp();
    wingRafterPart.algorithmSpec_wingRafterSecondAndColumn_secondLevelSoleSconce();
    wingRafterPart.algorithmSpec_wingRafterSecondAndWingStraight_innerTHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const wingStraightPart: WingPartVL_WingStraight = (<WingPositionVL>this.position).wingStraightPart;
    wingStraightPart.algorithmSpec_wingStraightCenterAndWingRafter_steelStringGrabber();
    wingStraightPart.algorithmSpec_wingStraightCenterAndWingRafter_uClamp();
    wingStraightPart.algorithmSpec_floorWingStraightCenterAndWingRafter_steelStringGrabber();
    wingStraightPart.algorithmSpec_floorWingStraightCenterAndWingRafter_uClamp();
    wingStraightPart.algorithmPart();

    /// //////// 외부 ///////////
    // 보
    const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
    columnPosition.prismBeamPart.algorithmSpec_gutterBeamAndWing_archSconce();

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    skirtLevel.wingPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

    // 바람막이 피복
    const windbreakLevel: WindbreakLevelIL = <WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index];
    windbreakLevel.wingPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 방풍벽 서까래 길이(파트)
   */
  // @override
  public algorithmSpec(): void {
    this.specLength = (<WingPartVL_WingRafter>this.part).wingRafterLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
