import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { SkySwitchPosition } from "vhows-design/src/object/design/skylight/switches/SkySwitchPosition";
import { SkyCoverPart_NormalPad } from "vhows-design/src/object/design/skylight/cover/SkyCoverPart_NormalPad";
import { SkyCoverPart_Screennet } from "vhows-design/src/object/design/skylight/cover/SkyCoverPart_Screennet";
import { SkyCoverPart_Vinyl } from "vhows-design/src/object/design/skylight/cover/SkyCoverPart_Vinyl";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-19
 */
@jsonObject({
  knownTypes: [SkyCoverPart_NormalPad, SkyCoverPart_Screennet, SkyCoverPart_Vinyl],
})
export class SkyCoverPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public vinylPart: SkyCoverPart_Vinyl;
  public normalPadPart: SkyCoverPart_NormalPad;
  public screennetPart: SkyCoverPart_Screennet;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _fixingType: string; // 고정 형태
  @jsonMember(Number)
  public _lineNumber: number; // 줄수
  @jsonMember(Number)
  public _skirtHeight: number; // 치마 높이
  @jsonMember(Boolean)
  public _screennetSelected: boolean; // 방충망 선택

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 줄수
   */
  public get lineNumber(): number {
    return this._lineNumber;
  }

  public set lineNumber(value: number) {
    this._lineNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 치마 높이
   */
  public get skirtHeight(): number {
    return this._skirtHeight;
  }

  //
  public set skirtHeight(value: number) {
    this._skirtHeight = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    const skylightLevel: number = this.struct.skyFrameWork.level1.skylightPosition.skylightLevel;
    (<CoverLevel>this.struct.coverWork.levelAC[skylightLevel - 1]).algorithmBasic();
  }

  /**
   * 방충망 여부
   */
  public get screennetSelected(): boolean {
    return this._screennetSelected;
  }

  //
  public set screennetSelected(value: boolean) {
    this._screennetSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByScreennetSelected();

    // 파트 알고리즘 호출
    this.normalPadPart.algorithmPart();
    this.screennetPart.algorithmBasic();
  }

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();
    this.algorithm_screennetSelected();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();
    this.algorithm_partActivationByScreennetSelected();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.vinylPart = new SkyCoverPart_Vinyl();
    this.normalPadPart = new SkyCoverPart_NormalPad();
    this.screennetPart = new SkyCoverPart_Screennet();

    this.partAC = [this.vinylPart, this.normalPadPart, this.screennetPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.vinylPart = <SkyCoverPart_Vinyl>this.partAC[0];
    this.normalPadPart = <SkyCoverPart_NormalPad>this.partAC[1];
    this.screennetPart = <SkyCoverPart_Screennet>this.partAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param fixingType: string 고정 형태
   * @param lineNumber: number 줄수
   * @param skirtHeight: number 치마 높이
   * @param screennetSelected: boolean 방충망 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    fixingType: string = "",
    lineNumber: number = 0,
    skirtHeight: number = 0,
    screennetSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._fixingType = fixingType;
    this._lineNumber = lineNumber;
    this._skirtHeight = skirtHeight;
    this._screennetSelected = screennetSelected;

    if (this.level.index >= 0) {
      // 천창 피복
      this.vinylPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_COVER_SKYLIGTH_COVER,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_COVER_SKYLIGHT_COVER,
        "",
      );

      // 천창 일반 패드 고정
      this.normalPadPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_COVER_FIXING_NORMAL_PAD,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_COVER_FIXING_NORMAL_PAD,
        "",
      );

      // 천창 방충망
      this.screennetPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_COVER_SCREENNET,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_COVER_SCREENNET,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 2) {
      // 천창 방충망
      const screennetPart: SkyCoverPart_Screennet = new SkyCoverPart_Screennet();
      screennetPart.setAssociation(design, struct, work, level, this);
      screennetPart.setDefaultData(
        CONST.INDEX_SG_SKYLIGHT_COVER_SCREENNET,
        false,
        false,
        false,
        CONST.LB_SKYLIGHT_COVER_SCREENNET,
        "",
      );
      this.partAC.push(screennetPart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 선택 <- 선택(천창 골조), 형태(천창 골조)
   */
  public algorithm_selected(): void {
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_CUSTOM) {
      this.selected = false;
    } else {
      this.selected = skyFramePosition.selected;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_COVER_SKYLIGHT_COVER) {
      this.vinylPart.selected = true;
      this.vinylPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
      this.normalPadPart.selected = true;
      this.normalPadPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 방충망 선택
   */
  public algorithm_partActivationByScreennetSelected(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.screennetSelected === true) {
      this.screennetPart.selected = true;
      this.screennetPart.visible = true;
    } else {
      this.screennetPart.selected = false;
      this.screennetPart.visible = false;
    }
  }

  /**
   * 알고리즘: 줄수 <- 형태(천창 골조)
   */
  public algorithm_lineNumber(): void {
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;
    const skySwitchPosition: SkySwitchPosition = this.struct.skySwitchWork.level1.skylightPosition;

    if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
    ) {
      this.lineNumber = 4;
    } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.lineNumber = 3;
    } else if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.lineNumber = 0;
    }
  }

  /**
   * 알고리즘: 방충망 선택 <- 형태(천창 골조), 선택(방충망)
   */
  public algorithm_screennetSelected(): void {
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF
    ) {
      this.screennetSelected = this.struct.screennetWork.level1.sidePosition.selected;
    } else if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.screennetSelected = false;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
