import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemShadenet } from "vhows-design/src/object/design/item/list/ItemShadenet";
import { CurCoverSampleIL_Shadenet } from "vhows-design/src/object/design/curtain/cover/CurCoverSampleIL_Shadenet";
import { CurCoverPartIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurCoverSampleIL_Shadenet],
})
export class CurCoverPartIL_Shadenet extends CurCoverPartIL {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public shadenetSample: CurCoverSampleIL_Shadenet; // 차광망 샘플

  // 아이템

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.shadenetSample = new CurCoverSampleIL_Shadenet();

    this.sampleAC = [this.shadenetSample];

    // 아이템
    this.itemAC = [
      // 동적 생성
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.shadenetSample = <CurCoverSampleIL_Shadenet>this.sampleAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.shadenetSample.setDefaultData(
        0,
        CONST.ITEM_ID_SHADENET,
        CONST.ITEM_NAME_SHADENET,
        "커튼 피복",
        "바둑판, 75%, 흑색, 6m, 56m, 한끈, 끝미싱, 0회",
        "기본회사, 9m간격",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    /// //////// 선언 ///////////

    const axisType = (this.position as CurCoverPositionIL).axisType;

    //----------------------------------
    // 품목
    // 피복객체의 값을 실제 품목에 반영
    //----------------------------------

    let item: ItemShadenet;

    // // 기둥
    // if (this.coverColumn != null) {
    //   item = ItemShadenet.getNewDesignItemBySample(this, this.shadenetSample);
    //   item.purpose = this.coverColumn.purpose;
    //   item.specWidth = this.coverColumn.width;
    //   item.specLength = this.coverColumn.length;
    //   item.specStringType = this.coverColumn.specStringType;
    //   item.designQuantity = 2;
    //   this.itemAC.push(item);
    // }

    // 천장
    if (this.coverCeiling != null) {
      item = ItemShadenet.getNewDesignItemBySample(this, this.shadenetSample);
      item.purpose = `${this.coverCeiling.purpose}(1중)`;
      item.specWidth = this.coverCeiling.width;
      item.specLength = this.coverCeiling.length;
      item.specStringType = this.coverCeiling.specStringType;
      if (axisType === CONST.LB_CURTAIN_AXIS_TYPE_IL) {
        item.designQuantity = 2 * this.basic.buildingNumber;
      } else if (axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
        if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
          const columnInterVal = this.struct.columnWorkVL.level1.columnPosition.columnInterval;
          item.designQuantity = this.basicLevel.length / columnInterVal;
        }
      }
      this.itemAC.push(item);
    }

    // // 앞면
    // if (this.coverFront != null) {
    //   item = ItemShadenet.getNewDesignItemBySample(this, this.shadenetSample);
    //   item.purpose = this.coverFront.purpose;
    //   item.specWidth = this.coverFront.width;
    //   item.specLength = this.coverFront.length;
    //   item.specStringType = this.coverFront.specStringType;
    //   item.designQuantity = 1;
    //   this.itemAC.push(item);
    // }
    //
    // // 뒷면
    // if (this.coverBack != null) {
    //   item = ItemShadenet.getNewDesignItemBySample(this, this.shadenetSample);
    //   item.purpose = this.coverBack.purpose;
    //   item.specWidth = this.coverBack.width;
    //   item.specLength = this.coverBack.length;
    //   item.specStringType = this.coverBack.specStringType;
    //   item.designQuantity = 1;
    //   this.itemAC.push(item);
    // }
    //
    // 주머니
    if (this.coverPocket != null) {
      item = ItemShadenet.getNewDesignItemBySample(this, this.shadenetSample);
      item.purpose = this.coverPocket.purpose;
      item.specWidth = this.coverPocket.width;
      item.specLength = this.coverPocket.length;
      item.specStringType = this.coverPocket.specStringType;
      item.designQuantity = 2;
      this.itemAC.push(item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    if (
      CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion) &&
      ((position as CurCoverPositionIL).type === CONST.LB_LEVEL_1 ||
        (position as CurCoverPositionIL).type === CONST.LB_LEVEL_2 ||
        (position as CurCoverPositionIL).type === CONST.LB_LEVEL_3) &&
      (position as CurCoverPositionIL).ceilingLevel1Cover === CONST.LB_COVER_SHADENET
    ) {
      this.selected = true;
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
