import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { ColumnPartVL_Middle } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Middle";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class ColumnSampleVL_MiddlePipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismMiddlePart: ColumnPartVL_Middle = <ColumnPartVL_Middle>this.part;
    prismMiddlePart.algorithmSpec_middle_pipe();
    prismMiddlePart.algorithmSpec_middleTrussLower_pipe();
    prismMiddlePart.middleBracingPipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////
    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>(
      this.struct.curSwitchWorkIL.level1.ceilingPosition
    );
    curSwitchPosition.fixingClipCeilingPart.algorithmSpec_trussClip();

    // if (this.position.type === CONST.LB_COLUMN_TYPE_PRISM) {
    //   // 마구리 골조
    //   for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) { // 모든 중수
    //     for (const endpiecePosition of endpieceLevel.positionAC as EndpiecePositionIL[]) {
    //       endpiecePosition.cylinderStudPart.algorithmSpec_studAndMiddlePrism_uClamp();
    //       endpiecePosition.prismStudSepPart.algorithmSpec_studBottomAndMiddle_tClamp();
    //     }
    //   }

    // 선반/균상다이
    if (this.level.index === 0) {
      const rackPosition: RackPosition = this.struct.rackWork.level1.wholePosition;
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tClamp();
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame2_normalHolder();
      rackPosition.rackPart.algorithmPart();
    }

    // 운반레일
    if (this.level.index === 0) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithmSpec_railAndFrame_secondLevelRailHook();
    }
    // }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 소기둥 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = (<ColumnPositionVL>this.position).prismColumnPart.smallColumnPipeSample.specsList;
  }

  /**
   * 규격 알고리즘 : 길이 <- 폭(기본정보)
   */
  public algorithmSpec_specLength(): void {
    this.specLength = this.basicLevel.width;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
