import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { ItemTowClip } from "vhows-design/src/object/design/item/list/ItemTowClip";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [],
})
export class CurSwitchPartIL_FixingClipSide extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플

  // 아이템
  public clip_normalClip: ItemClip;
  public clip_hookTowClip: ItemTowClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _clipInterval: number; // 클립 간격

  /**
   * 클립 간격
   */
  public get clipInterval(): number {
    return this._clipInterval;
  }

  public set clipInterval(value: number) {
    this._clipInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플

    this.sampleAC = [];

    // 아이템
    this.clip_normalClip = new ItemClip();
    this.clip_hookTowClip = new ItemTowClip();

    this.itemAC = [this.clip_normalClip, this.clip_hookTowClip];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플

    // 아이템
    this.clip_normalClip = <ItemClip>this.itemAC[0];
    this.clip_hookTowClip = <ItemTowClip>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param clipInterval: number 클립 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    clipInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._clipInterval = clipInterval;

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      this.clip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "커튼 클립 고정",
        "플라스틱, 25mm",
      );
      this.clip_hookTowClip.setDefaultData(
        1,
        CONST.ITEM_ID_HOOK_TOW_CLIP,
        CONST.ITEM_NAME_HOOK_TOW_CLIP,
        "커튼 클립 고정",
        "철",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;
    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 선택, 가시성 ///////////

    if (curSwitchPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP) {
      this.clip_normalClip.selected = true;
      this.clip_normalClip.visible = true;
      this.clip_hookTowClip.selected = false;
      this.clip_hookTowClip.visible = false;
    } else if (curSwitchPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
      if (curSwitchPosition.axisPart.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP) {
        this.clip_normalClip.selected = true;
        this.clip_normalClip.visible = true;
        this.clip_hookTowClip.selected = false;
        this.clip_hookTowClip.visible = false;
      } else if (curSwitchPosition.axisPart.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_STRING_CLIP) {
        this.clip_normalClip.selected = false;
        this.clip_normalClip.visible = false;
        this.clip_hookTowClip.selected = true;
        this.clip_hookTowClip.visible = true;
      }
    }

    /// //////// 수량 ///////////

    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.clip_normalClip.designQuantity =
        Math.ceil(this.basicLevel.length / this.clipInterval) * curSwitchPosition.switchWayValue;

      this.clip_hookTowClip.designQuantity =
        Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
        curSwitchPosition.linePart.towLineNumber *
        curSwitchPosition.switchWayValue;
    } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
      this.clip_normalClip.designQuantity =
        Math.ceil((this.basicLevel.width * this.basic.buildingNumber) / this.clipInterval) *
        curSwitchPosition.switchWayValue;

      this.clip_hookTowClip.designQuantity =
        Math.ceil((this.basicLevel.width * this.basic.buildingNumber) / columnPosition.columnInterval) *
        curSwitchPosition.linePart.towLineNumber *
        curSwitchPosition.switchWayValue;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [커튼 클립 고정] 일반 클립 <- 커튼 개폐 축 파이프 샘플, 커튼 피복 고정 파이프
   */
  public algorithmSpec_clip_normalClip(): void {
    if (this.position.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
      this.clip_normalClip.specCrossSize = (<CurSwitchPositionIL>this.position).axisPart.coverFixing_pipe.specCrossSize;
    } else if (this.position.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP) {
      this.clip_normalClip.specCrossSize = (<CurSwitchPositionIL>this.position).axisPart.axisPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [커튼 클립 고정] 고리 예인 클립 <- 없음
   */
  public algorithmSpec_clip_hookTowClip(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
