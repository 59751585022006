import { useRouter } from "next/router";
import Header from "./header";
import { ROUTE } from "constants/route";
import { Modal } from "components/cost/modal";
import { useAtom } from "jotai";
import { cancelModalAtom } from "store/global";
import { useQueryClient } from "@tanstack/react-query";

type Layout = {
  children?: React.ReactNode;
};

export default function Layout({ children }: Layout) {
  const router = useRouter();
  const [isVisible, setIsVisible] = useAtom(cancelModalAtom);
  const queryClient = useQueryClient();

  const entryType = router.query.entry as string;

  const callbacks = (e: any) => {
    router.back();
  };

  const resetData = () => {
    queryClient.invalidateQueries(["getCostResult"]);
    queryClient.invalidateQueries(["getCounsel"]);
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("uuid");
    setIsVisible(false);
    router.push(ROUTE.INDEX);
  };

  return (
    <>
      <div className={`relative m-auto max-w-2xl flex-col`}>
        <Header callBacks={callbacks}></Header>
        {children}
        {isVisible && (
          <Modal>
            <div className="w-full p-5">
              {entryType ? (
                <h2 className="mt-1 text-[22px] font-bold">
                  시공 상담 신청을
                  <br />
                  중단하시겠습니까?
                </h2>
              ) : (
                <h2 className="mt-1 text-[22px] font-bold">
                  하우스 견적 확인을
                  <br />
                  중단하시겠습니까?
                </h2>
              )}
              <div className="mb-6 mt-2 text-[17px] text-[#8B8D94]">중단 시 작성하신 내용이 저장되지 않습니다.</div>
              <button
                className="mr-2 w-[calc(50%_-_0.25rem)] rounded-xl bg-[#ECECEC] py-3 text-[17px] font-medium leading-7"
                onClick={() => resetData()}
              >
                신청 취소
              </button>
              <button
                className="w-[calc(50%_-_0.25rem)] rounded-xl border border-solid border-transparent bg-[#12B564] py-3 text-[17px] font-medium leading-7 text-white"
                onClick={() => setIsVisible(false)}
              >
                계속 작성
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
