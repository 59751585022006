import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { RackSample_MiddlePipe } from "vhows-design/src/object/design/other/rack/RackSample_MiddlePipe";
import { RackSample_ColumnPipe } from "vhows-design/src/object/design/other/rack/RackSample_ColumnPipe";
import { RackSample_StraightBottomPipe } from "vhows-design/src/object/design/other/rack/RackSample_StraightBottomPipe";
import { RackSample_StraightPipe } from "vhows-design/src/object/design/other/rack/RackSample_StraightPipe";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";
import { RackSample_WidthPipe } from "vhows-design/src/object/design/other/rack/RackSample_WidthPipe";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-04-18
 */
@jsonObject({
  knownTypes: [
    RackSample_ColumnPipe,
    RackSample_MiddlePipe,
    RackSample_StraightBottomPipe,
    RackSample_StraightPipe,
    RackSample_WidthPipe,
  ],
})
export class RackPart_Rack extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public rackMiddlePipeSample: RackSample_MiddlePipe; // 중방 파이프 샘플
  public rackColumnPipeSample: RackSample_ColumnPipe; // 선반 기둥 파이프 샘플
  public rackWidthPipeSample: RackSample_WidthPipe; // 선반 가로 파이프 샘플
  public rackStraightPipeSample: RackSample_StraightPipe; // 선반 직관 파이프 샘플
  public rackStraightBottomPipeSample: RackSample_StraightBottomPipe; // 선반 바닥 직관 파이프 샘플

  // 아이템
  public rackMiddle_pipe: ItemPipe;
  public rackMiddle_connectionPin: ItemConnector;
  public rackMiddleAndRafter_tHolder: ItemHolder;
  public rackMiddleAndRafter1_normalHolder: ItemHolder;
  public rackMiddleAndRafter2_normalHolder: ItemHolder;
  public rackColumn_pipe: ItemPipe;
  public rackColumnAndFrame_tHolder: ItemHolder;
  public rackColumnAndFrame_tClamp: ItemHolder;
  public rackColumnAndFrame1_normalHolder: ItemHolder;
  public rackColumnAndFrame2_normalHolder: ItemHolder;
  public rackWidth_pipe: ItemPipe;
  public rackWidthAndColumn_saddleHolder: ItemHolder;
  public rackWidthAndColumn_tClamp: ItemHolder;
  public rackStraight_pipe: ItemPipe;
  public rackStraight_connectionPin: ItemConnector;
  public rackStraightAndWidth_saddleHolder: ItemHolder;
  public rackStraightAndWidth_steelStringGrabber: ItemHolder;
  public rackStraightBottom_pipe: ItemPipe;
  public rackStraightBottom_connectionPin: ItemConnector;
  public rackStraightBottomAndColumn_steelStringGrabber: ItemHolder;
  public rackStraightBottomAndColumn_fixAsibaClamp: ItemHolder;
  public rack_welding: ItemOtherExpense;
  public rack_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 통로 폭 */
  public hallwayWidth: number;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _frameQuantitiy: number = 0; // 선반 틀 수량
  @jsonMember(Number)
  public _frameInterval: number = 0; // 선반 틀 간격
  @jsonMember(Number)
  public _rackWidth: number = 0; // 선반 폭
  @jsonMember(Number)
  public _rackHeight: number = 0; // 선반 높이
  @jsonMember(Number)
  public _rackLength: number = 0; // 선반 길이
  @jsonMember(Number)
  public _levelNumber: number = 0; // 선반 단수
  @jsonMember(Number)
  public _levelHeight: number = 0; // 선반 단 높이
  @jsonMember(Number)
  public _legHeight: number = 0; // 선반 여분 높이
  @jsonMember(Number)
  public _straightInterval: number = 0; // 선반 직관 간격
  @jsonMember(Number)
  public _straightLineNumber: number = 0; // 선반 직관 줄수
  @jsonMember(Boolean)
  public _straightBottomSelected: boolean; // 선반 바닥 직관 선택
  @jsonMember(String)
  public _columnFixedFrame: string; // 선반 기둥 고정 골조

  /**
   * 선반 틀 수량
   */
  public get frameQuantitiy(): number {
    return this._frameQuantitiy;
  }

  //
  public set frameQuantitiy(value: number) {
    this._frameQuantitiy = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 선반 틀 간격
   */
  public get frameInterval(): number {
    return this._frameInterval;
  }

  //
  public set frameInterval(value: number) {
    this._frameInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_frameQuantitiy();
    this.algorithmPart();
  }

  /**
   * 선반 폭
   */
  public get rackWidth(): number {
    return this._rackWidth;
  }

  //
  public set rackWidth(value: number) {
    // 소수점 첫째자리까지 반올림
    this._rackWidth = CommonUtil.round(value, 1);

    // 알고리즘
    this.algorithm_hallwayWidth();
    this.algorithm_straightInterval();
    this.algorithmPart();
  }

  /**
   * 선반 높이
   */
  public get rackHeight(): number {
    return this._rackHeight;
  }

  //
  public set rackHeight(value: number) {
    this._rackHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_levelHeight();
    this.algorithmPart();
  }

  /**
   * 선반 길이
   */
  public get rackLength(): number {
    return this._rackLength;
  }

  //
  public set rackLength(value: number) {
    this._rackLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_frameQuantitiy();

    /// //////// 외부 ///////////
  }

  /**
   * 선반 단수
   */
  public get levelNumber(): number {
    return this._levelNumber;
  }

  //
  public set levelNumber(value: number) {
    this._levelNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_levelHeight();
    this.algorithmPart();
  }

  /**
   * 선반 단 높이
   */
  public get levelHeight(): number {
    return this._levelHeight;
  }

  //
  public set levelHeight(value: number) {
    this._levelHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 선반 여분 높이
   */
  public get legHeight(): number {
    return this._legHeight;
  }

  //
  public set legHeight(value: number) {
    this._legHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_levelHeight();
    this.algorithmPart();
  }

  /**
   * 선반 직관 간격
   */
  public get straightInterval(): number {
    return this._straightInterval;
  }

  //
  public set straightInterval(value: number) {
    this._straightInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 선반 직관 줄수
   */
  public get straightLineNumber(): number {
    return this._straightLineNumber;
  }

  //
  public set straightLineNumber(value: number) {
    this._straightLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_straightInterval();
    this.algorithmPart();
  }

  /**
   * 선반 바닥 직관 선택
   */
  public get straightBottomSelected(): boolean {
    return this._straightBottomSelected;
  }

  //
  public set straightBottomSelected(value: boolean) {
    this._straightBottomSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 선반 기둥 고정 골조
   */
  public get columnFixedFrame(): string {
    return this._columnFixedFrame;
  }

  //
  public set columnFixedFrame(value: string) {
    this._columnFixedFrame = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.rackMiddlePipeSample = new RackSample_MiddlePipe();
    this.rackColumnPipeSample = new RackSample_ColumnPipe();
    this.rackWidthPipeSample = new RackSample_WidthPipe();
    this.rackStraightPipeSample = new RackSample_StraightPipe();
    this.rackStraightBottomPipeSample = new RackSample_StraightBottomPipe();

    this.sampleAC = [
      this.rackMiddlePipeSample,
      this.rackColumnPipeSample,
      this.rackWidthPipeSample,
      this.rackStraightPipeSample,
      this.rackStraightBottomPipeSample,
    ];

    // 아이템
    this.rackMiddle_pipe = new ItemPipe();
    this.rackMiddle_connectionPin = new ItemConnector();
    this.rackMiddleAndRafter_tHolder = new ItemHolder();
    this.rackMiddleAndRafter1_normalHolder = new ItemHolder();
    this.rackMiddleAndRafter2_normalHolder = new ItemHolder();
    this.rackColumn_pipe = new ItemPipe();
    this.rackColumnAndFrame_tHolder = new ItemHolder();
    this.rackColumnAndFrame_tClamp = new ItemHolder();
    this.rackColumnAndFrame1_normalHolder = new ItemHolder();
    this.rackColumnAndFrame2_normalHolder = new ItemHolder();
    this.rackWidth_pipe = new ItemPipe();
    this.rackWidthAndColumn_saddleHolder = new ItemHolder();
    this.rackWidthAndColumn_tClamp = new ItemHolder();
    this.rackStraight_pipe = new ItemPipe();
    this.rackStraight_connectionPin = new ItemConnector();
    this.rackStraightAndWidth_saddleHolder = new ItemHolder();
    this.rackStraightAndWidth_steelStringGrabber = new ItemHolder();
    this.rackStraightBottom_pipe = new ItemPipe();
    this.rackStraightBottom_connectionPin = new ItemConnector();
    this.rackStraightBottomAndColumn_steelStringGrabber = new ItemHolder();
    this.rackStraightBottomAndColumn_fixAsibaClamp = new ItemHolder();
    this.rack_welding = new ItemOtherExpense();
    this.rack_screw = new ItemScrew();

    this.itemAC = [
      this.rackMiddle_pipe,
      this.rackMiddle_connectionPin,
      this.rackMiddleAndRafter_tHolder,
      this.rackMiddleAndRafter1_normalHolder,
      this.rackMiddleAndRafter2_normalHolder,
      this.rackColumn_pipe,
      this.rackColumnAndFrame_tHolder,
      this.rackColumnAndFrame_tClamp,
      this.rackColumnAndFrame1_normalHolder,
      this.rackColumnAndFrame2_normalHolder,
      this.rackWidth_pipe,
      this.rackWidthAndColumn_saddleHolder,
      this.rackWidthAndColumn_tClamp,
      this.rackStraight_pipe,
      this.rackStraight_connectionPin,
      this.rackStraightAndWidth_saddleHolder,
      this.rackStraightAndWidth_steelStringGrabber,
      this.rackStraightBottom_pipe,
      this.rackStraightBottom_connectionPin,
      this.rackStraightBottomAndColumn_steelStringGrabber,
      this.rackStraightBottomAndColumn_fixAsibaClamp,
      this.rack_welding,
      this.rack_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.rackMiddlePipeSample = <RackSample_MiddlePipe>this.sampleAC[0];
    this.rackColumnPipeSample = <RackSample_ColumnPipe>this.sampleAC[1];
    this.rackWidthPipeSample = <RackSample_WidthPipe>this.sampleAC[2];
    this.rackStraightPipeSample = <RackSample_StraightPipe>this.sampleAC[3];
    this.rackStraightBottomPipeSample = <RackSample_StraightBottomPipe>this.sampleAC[4];
    // 아이템
    this.rackMiddle_pipe = <ItemPipe>this.itemAC[0];
    this.rackMiddle_connectionPin = <ItemConnector>this.itemAC[1];
    this.rackMiddleAndRafter_tHolder = <ItemHolder>this.itemAC[2];
    this.rackMiddleAndRafter1_normalHolder = <ItemHolder>this.itemAC[3];
    this.rackMiddleAndRafter2_normalHolder = <ItemHolder>this.itemAC[4];
    this.rackColumn_pipe = <ItemPipe>this.itemAC[5];
    this.rackColumnAndFrame_tHolder = <ItemHolder>this.itemAC[6];
    this.rackColumnAndFrame_tClamp = <ItemHolder>this.itemAC[7];
    this.rackColumnAndFrame1_normalHolder = <ItemHolder>this.itemAC[8];
    this.rackColumnAndFrame2_normalHolder = <ItemHolder>this.itemAC[9];
    this.rackWidth_pipe = <ItemPipe>this.itemAC[10];
    this.rackWidthAndColumn_saddleHolder = <ItemHolder>this.itemAC[11];
    this.rackWidthAndColumn_tClamp = <ItemHolder>this.itemAC[12];
    this.rackStraight_pipe = <ItemPipe>this.itemAC[13];
    this.rackStraight_connectionPin = <ItemConnector>this.itemAC[14];
    this.rackStraightAndWidth_saddleHolder = <ItemHolder>this.itemAC[15];
    this.rackStraightAndWidth_steelStringGrabber = <ItemHolder>this.itemAC[16];
    this.rackStraightBottom_pipe = <ItemPipe>this.itemAC[17];
    this.rackStraightBottom_connectionPin = <ItemConnector>this.itemAC[18];
    this.rackStraightBottomAndColumn_steelStringGrabber = <ItemHolder>this.itemAC[19];
    this.rackStraightBottomAndColumn_fixAsibaClamp = <ItemHolder>this.itemAC[20];
    this.rack_welding = <ItemOtherExpense>this.itemAC[21];
    this.rack_screw = <ItemScrew>this.itemAC[22];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param frameInterval: number 선반 틀 간격
   * @param rackWidth: number 선반 폭
   * @param rackHeight: number 선반 높이
   * @param levelNumber: number 선반 단수
   * @param levelHeight: number 선반 단 높이
   * @param legHeight: number 선반 하단 높이
   * @param straightLineNumber: number 선반 직관 줄수
   * @param fixedFramePipe: string 선반 기둥 고정 골조
   * @param straightBottomSelected: boolean 선반 바닥 직관 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    frameInterval: number = 0,
    rackWidth: number = 0,
    rackHeight: number = 0,
    levelNumber: number = 0,
    levelHeight: number = 0,
    legHeight: number = 0,
    straightLineNumber: number = 0,
    fixedFramePipe: string = "",
    straightBottomSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._frameInterval = frameInterval;
    this._rackWidth = rackWidth;
    this._rackHeight = rackHeight;
    this._levelNumber = levelNumber;
    this._levelHeight = levelHeight;
    this._legHeight = legHeight;
    this._straightLineNumber = straightLineNumber;
    this._columnFixedFrame = fixedFramePipe;
    this._straightBottomSelected = straightBottomSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.rackMiddlePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.rackColumnPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "선반 기둥",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.rackWidthPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "선반 가로",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.rackStraightPipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "선반 직관",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.rackStraightBottomPipeSample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "선반 바닥 직관",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.rackMiddle_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.rackMiddle_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "중방",
        "원형, 일반, 32mm, 32mm",
      );
      this.rackMiddleAndRafter_tHolder.setDefaultData(
        2,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "중방 + 서까래",
        "원형, 일반, 32mm, 32mm",
      );
      this.rackMiddleAndRafter1_normalHolder.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "중방 + 서까래 (1)",
        "원형, 일반, 32mm, -",
      );
      this.rackMiddleAndRafter2_normalHolder.setDefaultData(
        4,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "중방 + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.rackColumn_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "선반 기둥",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.rackColumnAndFrame_tHolder.setDefaultData(
        6,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "선반 기둥 + 골조",
        "원형, 일반, 32mm, 32mm",
      );
      this.rackColumnAndFrame_tClamp.setDefaultData(
        7,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "선반 기둥 + 골조",
        "원형, 일반, 48mm, 48mm",
      );
      this.rackColumnAndFrame1_normalHolder.setDefaultData(
        8,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "선반 기둥 + 골조 (1)",
        "원형, 일반, 32mm, -",
      );
      this.rackColumnAndFrame2_normalHolder.setDefaultData(
        9,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "선반 기둥 + 골조 (2)",
        "원형, 일반, 32mm, -",
      );
      this.rackWidth_pipe.setDefaultData(
        10,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "선반 가로",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.rackWidthAndColumn_saddleHolder.setDefaultData(
        11,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "선반 가로 + 선반 기둥",
        "원형, 일반, 32mm, -",
      );
      this.rackWidthAndColumn_tClamp.setDefaultData(
        12,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "선반 가로 + 선반 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.rackStraight_pipe.setDefaultData(
        13,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "선반 직관",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.rackStraight_connectionPin.setDefaultData(
        14,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "선반 직관",
        "원형, 일반, 25mm, 25mm",
      );
      this.rackStraightAndWidth_saddleHolder.setDefaultData(
        15,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "선반 직관 + 선반 가로",
        "원형, 일반, 25mm, -",
      );
      this.rackStraightAndWidth_steelStringGrabber.setDefaultData(
        16,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "선반 직관 + 선반 가로",
        "원형, 일반, 25mm, 32mm",
      );
      this.rackStraightBottom_pipe.setDefaultData(
        17,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "선반 바닥 직관",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.rackStraightBottom_connectionPin.setDefaultData(
        18,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "선반 바닥 직관",
        "원형, 일반, 25mm, 25mm",
      );
      this.rackStraightBottomAndColumn_steelStringGrabber.setDefaultData(
        19,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "선반 바닥 직관 + 선반 기둥",
        "원형, 일반, 25mm, 32mm",
      );
      this.rackStraightBottomAndColumn_fixAsibaClamp.setDefaultData(
        20,
        CONST.ITEM_ID_FIX_ASIBA_CLAMP,
        CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
        "선반 바닥 직관 + 선반 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.rack_welding.setDefaultData(21, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "선반", "일반");
      this.rack_screw.setDefaultData(
        22,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "선반",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.algorithm_hallwayWidth();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_rackLength();
    this.algorithm_hallwayWidth();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithm_frameQuantitiy();
    this.algorithm_levelHeight();
    this.algorithm_straightInterval();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const rackPosition: RackPosition = <RackPosition>this.position;
    // 외부
    let trunkPosition: TrunkPosition;
    let columnPosition: ColumnPositionIL;
    let columnPositioinVL: ColumnPositionVL;
    // 기타
    let columnFixedFramePipe: ItemPipe;
    let rackColumnAndFrame_weldingFlag: boolean;
    let rackWidthAndColumn_weldingFlag: boolean;
    let rackStraightAndWidth_weldingFlag: boolean;
    let rackStraightBottomAndColumn_weldingFlag: boolean;

    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      trunkPosition = this.struct.trunkWork.level1.trunkPosition;
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_RAFTER) {
        columnFixedFramePipe = trunkPosition.rafterPart.rafterPipeSample;
      } else if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_STRAIGHT) {
        columnFixedFramePipe = trunkPosition.straightPart.straightRoofPipeSample;
      } else if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        columnFixedFramePipe = this.rackMiddlePipeSample;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
          columnFixedFramePipe = columnPosition.cylinderMiddlePart.middlePipeSample;
        } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          columnFixedFramePipe = columnPosition.prismMiddlePart.middlePipeSample;
        }
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPositioinVL = this.struct.columnWorkVL.level1.columnPosition;
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        if (columnPositioinVL.type === CONST.LB_COLUMN_TYPE_PRISM) {
          columnFixedFramePipe = columnPositioinVL.prismMiddlePart.middlePipeSample;
        }
      }
    }

    /// //////// 선택, 가시성 ///////////

    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      if (rackPosition.type === CONST.LB_RACK_TYPE_FRAME_INTEGRATED) {
        if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
          this.rackMiddle_pipe.selected = true;
          this.rackMiddle_pipe.visible = true;
          this.rackMiddle_connectionPin.selected = true;
          this.rackMiddle_connectionPin.visible = true;
          this.rackMiddleAndRafter_tHolder.selected = false;
          this.rackMiddleAndRafter_tHolder.visible = true;
          this.rackMiddleAndRafter1_normalHolder.selected = true;
          this.rackMiddleAndRafter1_normalHolder.visible = true;
          this.rackMiddleAndRafter2_normalHolder.selected = true;
          this.rackMiddleAndRafter2_normalHolder.visible = true;
        } else {
          this.rackMiddle_pipe.selected = false;
          this.rackMiddle_pipe.visible = false;
          this.rackMiddle_connectionPin.selected = false;
          this.rackMiddle_connectionPin.visible = false;
          this.rackMiddleAndRafter_tHolder.selected = false;
          this.rackMiddleAndRafter_tHolder.visible = false;
          this.rackMiddleAndRafter1_normalHolder.selected = false;
          this.rackMiddleAndRafter1_normalHolder.visible = false;
          this.rackMiddleAndRafter2_normalHolder.selected = false;
          this.rackMiddleAndRafter2_normalHolder.visible = false;
        }
      } else {
        this.rackMiddle_pipe.selected = false;
        this.rackMiddle_pipe.visible = false;
        this.rackMiddle_connectionPin.selected = false;
        this.rackMiddle_connectionPin.visible = false;
        this.rackMiddleAndRafter_tHolder.selected = false;
        this.rackMiddleAndRafter_tHolder.visible = false;
        this.rackMiddleAndRafter1_normalHolder.selected = false;
        this.rackMiddleAndRafter1_normalHolder.visible = false;
        this.rackMiddleAndRafter2_normalHolder.selected = false;
        this.rackMiddleAndRafter2_normalHolder.visible = false;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.rackMiddle_pipe.selected = false;
      this.rackMiddle_pipe.visible = false;
      this.rackMiddle_connectionPin.selected = false;
      this.rackMiddle_connectionPin.visible = false;
      this.rackMiddleAndRafter_tHolder.selected = false;
      this.rackMiddleAndRafter_tHolder.visible = false;
      this.rackMiddleAndRafter1_normalHolder.selected = false;
      this.rackMiddleAndRafter1_normalHolder.visible = false;
      this.rackMiddleAndRafter2_normalHolder.selected = false;
      this.rackMiddleAndRafter2_normalHolder.visible = false;
    }
    this.rackColumn_pipe.selected = true;
    this.rackColumn_pipe.visible = true;
    if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_RAFTER) {
      // 용접(각형) vs. 일반 고정구
      // 하드코딩
      if (
        this.rackColumn_pipe.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE ||
        columnFixedFramePipe.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE
      ) {
        rackColumnAndFrame_weldingFlag = true;
        this.rackColumnAndFrame_tHolder.selected = false;
        this.rackColumnAndFrame_tHolder.visible = false;
        this.rackColumnAndFrame_tClamp.selected = false;
        this.rackColumnAndFrame_tClamp.visible = false;
        this.rackColumnAndFrame1_normalHolder.selected = false;
        this.rackColumnAndFrame1_normalHolder.visible = false;
        this.rackColumnAndFrame2_normalHolder.selected = false;
        this.rackColumnAndFrame2_normalHolder.visible = false;
      } else {
        rackColumnAndFrame_weldingFlag = false;
        this.rackColumnAndFrame_tHolder.selected = false;
        this.rackColumnAndFrame_tHolder.visible = false;
        this.rackColumnAndFrame_tClamp.selected = false;
        this.rackColumnAndFrame_tClamp.visible = false;
        this.rackColumnAndFrame1_normalHolder.selected = true;
        this.rackColumnAndFrame1_normalHolder.visible = true;
        this.rackColumnAndFrame2_normalHolder.selected = true;
        this.rackColumnAndFrame2_normalHolder.visible = true;
      }
    } else {
      // 용접(각형) vs. T 고정구(22/22mm,25/25mm,32/32mm,32/25mm) vs. T 클램프(42/42mm,48/48mm,60/60mm,60/48mm) vs. 일반 고정구
      // 하드코딩
      if (
        this.rackColumn_pipe.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE ||
        columnFixedFramePipe.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE
      ) {
        rackColumnAndFrame_weldingFlag = true;
        this.rackColumnAndFrame_tHolder.selected = false;
        this.rackColumnAndFrame_tHolder.visible = false;
        this.rackColumnAndFrame_tClamp.selected = false;
        this.rackColumnAndFrame_tClamp.visible = false;
        this.rackColumnAndFrame1_normalHolder.selected = false;
        this.rackColumnAndFrame1_normalHolder.visible = false;
        this.rackColumnAndFrame2_normalHolder.selected = false;
        this.rackColumnAndFrame2_normalHolder.visible = false;
      } else if (
        (this.rackColumn_pipe.specCrossSize === columnFixedFramePipe.specCrossSize &&
          (this.rackColumn_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM ||
            this.rackColumn_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
            this.rackColumn_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)) ||
        (columnFixedFramePipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM &&
          this.rackColumn_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM)
      ) {
        rackColumnAndFrame_weldingFlag = false;
        this.rackColumnAndFrame_tHolder.selected = true;
        this.rackColumnAndFrame_tHolder.visible = true;
        this.rackColumnAndFrame_tClamp.selected = false;
        this.rackColumnAndFrame_tClamp.visible = false;
        this.rackColumnAndFrame1_normalHolder.selected = false;
        this.rackColumnAndFrame1_normalHolder.visible = false;
        this.rackColumnAndFrame2_normalHolder.selected = false;
        this.rackColumnAndFrame2_normalHolder.visible = false;
      } else if (
        (this.rackColumn_pipe.specCrossSize === columnFixedFramePipe.specCrossSize &&
          (this.rackColumn_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
            this.rackColumn_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM ||
            this.rackColumn_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM)) ||
        (columnFixedFramePipe.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM &&
          this.rackColumn_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)
      ) {
        rackColumnAndFrame_weldingFlag = false;
        this.rackColumnAndFrame_tHolder.selected = false;
        this.rackColumnAndFrame_tHolder.visible = false;
        this.rackColumnAndFrame_tClamp.selected = true;
        this.rackColumnAndFrame_tClamp.visible = true;
        this.rackColumnAndFrame1_normalHolder.selected = false;
        this.rackColumnAndFrame1_normalHolder.visible = false;
        this.rackColumnAndFrame2_normalHolder.selected = false;
        this.rackColumnAndFrame2_normalHolder.visible = false;
      } else {
        rackColumnAndFrame_weldingFlag = false;
        this.rackColumnAndFrame_tHolder.selected = false;
        this.rackColumnAndFrame_tHolder.visible = false;
        this.rackColumnAndFrame_tClamp.selected = false;
        this.rackColumnAndFrame_tClamp.visible = false;
        this.rackColumnAndFrame1_normalHolder.selected = true;
        this.rackColumnAndFrame1_normalHolder.visible = true;
        this.rackColumnAndFrame2_normalHolder.selected = true;
        this.rackColumnAndFrame2_normalHolder.visible = true;
      }
    }
    this.rackWidth_pipe.selected = true;
    this.rackWidth_pipe.visible = true;
    // 용접(각형) vs. T 클램프(32/32mm,42/42mm,48/48mm) vs. 새들 고정구
    // 하드코딩
    if (
      this.rackWidth_pipe.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE ||
      this.rackColumn_pipe.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE
    ) {
      rackWidthAndColumn_weldingFlag = true;
      this.rackWidthAndColumn_saddleHolder.selected = false;
      this.rackWidthAndColumn_saddleHolder.visible = false;
      this.rackWidthAndColumn_tClamp.selected = false;
      this.rackWidthAndColumn_tClamp.visible = false;
    } else if (
      this.rackWidth_pipe.specCrossSize === this.rackColumn_pipe.specCrossSize &&
      (this.rackWidth_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM ||
        this.rackWidth_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
        this.rackWidth_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)
    ) {
      rackWidthAndColumn_weldingFlag = false;
      this.rackWidthAndColumn_saddleHolder.selected = false;
      this.rackWidthAndColumn_saddleHolder.visible = false;
      this.rackWidthAndColumn_tClamp.selected = true;
      this.rackWidthAndColumn_tClamp.visible = true;
    } else {
      rackWidthAndColumn_weldingFlag = false;
      this.rackWidthAndColumn_saddleHolder.selected = true;
      this.rackWidthAndColumn_saddleHolder.visible = true;
      this.rackWidthAndColumn_tClamp.selected = false;
      this.rackWidthAndColumn_tClamp.visible = false;
    }
    this.rackStraight_pipe.checkSelected(true);
    this.rackStraight_connectionPin.checkSelected(true);
    // 용접(각형) vs. 새들 고정구 && 강선 조리개
    // 하드코딩
    if (
      this.rackStraight_pipe.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE ||
      rackPosition.rackPart.rackWidthPipeSample.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE
    ) {
      rackStraightAndWidth_weldingFlag = true;
      this.rackStraightAndWidth_saddleHolder.selected = false;
      this.rackStraightAndWidth_saddleHolder.visible = false;
      this.rackStraightAndWidth_steelStringGrabber.selected = false;
      this.rackStraightAndWidth_steelStringGrabber.visible = false;
    } else {
      rackStraightAndWidth_weldingFlag = false;
      this.rackStraightAndWidth_saddleHolder.selected = true;
      this.rackStraightAndWidth_saddleHolder.visible = true;
      this.rackStraightAndWidth_steelStringGrabber.selected = true;
      this.rackStraightAndWidth_steelStringGrabber.visible = true;
    }
    if (this.straightBottomSelected === true) {
      this.rackStraightBottom_pipe.checkSelected(true);
      this.rackStraightBottom_connectionPin.checkSelected(true);
      // 용접(각형) vs. 고정 아시바 클램프(42/42mm,48/48mm) vs. 강선 조리개
      // 하드코딩
      if (
        this.rackStraightBottom_pipe.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE ||
        rackPosition.rackPart.rackColumnPipeSample.specPipeType === CONST.ITEM_SPEC_VALUE_ANGLE_TYPE
      ) {
        rackStraightBottomAndColumn_weldingFlag = true;
        this.rackStraightBottomAndColumn_steelStringGrabber.selected = false;
        this.rackStraightBottomAndColumn_steelStringGrabber.visible = false;
        this.rackStraightBottomAndColumn_fixAsibaClamp.selected = false;
        this.rackStraightBottomAndColumn_fixAsibaClamp.visible = false;
      } else if (
        this.rackStraightBottom_pipe.specCrossSize === rackPosition.rackPart.rackColumnPipeSample.specCrossSize &&
        (this.rackStraightBottom_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
          this.rackStraightBottom_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)
      ) {
        rackStraightBottomAndColumn_weldingFlag = false;
        this.rackStraightBottomAndColumn_steelStringGrabber.selected = false;
        this.rackStraightBottomAndColumn_steelStringGrabber.visible = false;
        this.rackStraightBottomAndColumn_fixAsibaClamp.selected = true;
        this.rackStraightBottomAndColumn_fixAsibaClamp.visible = true;
      } else {
        rackStraightBottomAndColumn_weldingFlag = false;
        this.rackStraightBottomAndColumn_steelStringGrabber.selected = true;
        this.rackStraightBottomAndColumn_steelStringGrabber.visible = true;
        this.rackStraightBottomAndColumn_fixAsibaClamp.selected = false;
        this.rackStraightBottomAndColumn_fixAsibaClamp.visible = false;
      }
    } else {
      this.rackStraightBottom_pipe.checkSelected(false);
      this.rackStraightBottom_connectionPin.checkSelected(false);
      rackStraightBottomAndColumn_weldingFlag = false;
      this.rackStraightBottomAndColumn_steelStringGrabber.selected = false;
      this.rackStraightBottomAndColumn_steelStringGrabber.visible = false;
      this.rackStraightBottomAndColumn_fixAsibaClamp.selected = false;
      this.rackStraightBottomAndColumn_fixAsibaClamp.visible = false;
    }
    this.rack_welding.selected = true;
    this.rack_welding.visible = true;
    this.rack_screw.selected = true;
    this.rack_screw.visible = true;

    /// ////////  수치 ///////////

    const innerWidth: number = this.basic.basicLevelAC[this.basic.levelNumber - 1].width;
    const innerHeight: number = this.basic.basicLevelAC[this.basic.levelNumber - 1].heightMax;
    const frameQuantityByLine: number = Math.floor(this.rackLength / this.frameInterval) + 1;

    /// ////////  수량 ///////////

    this.rackMiddle_pipe.designQuantity =
      ((innerWidth * frameQuantityByLine) / this.rackMiddle_pipe.specLength) * rackPosition.rackBuildingNumber;

    this.rackMiddle_connectionPin.designQuantity = this.rackMiddle_pipe.getSelectedQuantity();

    this.rackMiddleAndRafter_tHolder.designQuantity = 2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.rackMiddleAndRafter1_normalHolder.designQuantity =
      2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.rackMiddleAndRafter2_normalHolder.designQuantity =
      2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    if (rackPosition.type === CONST.LB_RACK_TYPE_STAND_ALONE_DEFAULT) {
      this.rackColumn_pipe.designQuantity =
        (2 * this.frameQuantitiy) / Math.floor(this.rackColumn_pipe.specLength / this.rackHeight);
    } else if (rackPosition.type === CONST.LB_RACK_TYPE_FRAME_INTEGRATED) {
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        this.rackColumn_pipe.designQuantity =
          (2 * this.frameQuantitiy) / Math.floor(this.rackColumn_pipe.specLength / this.basicLevel.heightShoulder);
      } else {
        this.rackColumn_pipe.designQuantity =
          (2 * this.frameQuantitiy) / Math.floor(this.rackColumn_pipe.specLength / (innerHeight * 0.9));
      }
    }

    this.rackColumnAndFrame_tHolder.designQuantity = 2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.rackColumnAndFrame_tClamp.designQuantity = 2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_T_CLAMP;

    this.rackColumnAndFrame1_normalHolder.designQuantity = 2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.rackColumnAndFrame2_normalHolder.designQuantity = 2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.rackWidth_pipe.designQuantity =
      (this.levelNumber * this.frameQuantitiy) / Math.floor(this.rackWidth_pipe.specLength / this.rackWidth);

    this.rackWidthAndColumn_saddleHolder.designQuantity =
      2 * this.levelNumber * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.rackWidthAndColumn_tClamp.designQuantity =
      2 * this.levelNumber * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_T_CLAMP;

    this.rackStraight_pipe.designQuantity =
      ((this.rackLength * this.straightLineNumber * this.levelNumber * rackPosition.rackLineNumber) /
        this.rackStraight_pipe.specLength) *
      rackPosition.rackBuildingNumber;

    if (this.rackLength > this.rackStraight_pipe.specLength) {
      this.rackStraight_connectionPin.designQuantity =
        this.rackStraight_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.rackStraight_connectionPin.designQuantity = 0;
    }

    this.rackStraightAndWidth_saddleHolder.designQuantity =
      this.straightLineNumber *
      this.levelNumber *
      2 *
      rackPosition.rackLineNumber *
      rackPosition.rackBuildingNumber *
      CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.rackStraightAndWidth_steelStringGrabber.designQuantity =
      this.straightLineNumber *
      this.levelNumber *
      (this.frameQuantitiy - 2 * rackPosition.rackLineNumber * rackPosition.rackBuildingNumber) *
      CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

    this.rackStraightBottom_pipe.designQuantity =
      ((this.rackLength * 2 * rackPosition.rackLineNumber) / this.rackStraightBottom_pipe.specLength) *
      rackPosition.rackBuildingNumber;

    if (this.rackLength > this.rackStraightBottom_pipe.specLength) {
      this.rackStraightBottom_connectionPin.designQuantity =
        this.rackStraightBottom_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.rackStraightBottom_connectionPin.designQuantity = 0;
    }

    this.rackStraightBottomAndColumn_steelStringGrabber.designQuantity =
      2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

    this.rackStraightBottomAndColumn_fixAsibaClamp.designQuantity =
      2 * this.frameQuantitiy * CONST.NUM_EXTRA_RATE_FIX_ASIBA_CLAMP;

    let rack_welding_designQuantity: number = 0;
    if (rackColumnAndFrame_weldingFlag === true) {
      rack_welding_designQuantity += 2 * this.frameQuantitiy;
    }
    if (rackWidthAndColumn_weldingFlag === true) {
      rack_welding_designQuantity += 2 * this.levelNumber * this.frameQuantitiy;
    }
    if (rackStraightAndWidth_weldingFlag === true) {
      rack_welding_designQuantity += this.straightLineNumber * this.levelNumber * this.frameQuantitiy;
    }
    if (rackStraightBottomAndColumn_weldingFlag === true) {
      rack_welding_designQuantity += 2 * this.frameQuantitiy;
    }
    this.rack_welding.designQuantity = rack_welding_designQuantity;

    this.rack_screw.designQuantity =
      ((this.rackMiddle_connectionPin.getSelectedQuantity() * 2 +
        this.rackMiddleAndRafter_tHolder.getSelectedQuantity() * 3 +
        this.rackMiddleAndRafter1_normalHolder.getSelectedQuantity() * 2 +
        this.rackMiddleAndRafter2_normalHolder.getSelectedQuantity() * 2 +
        this.rackColumnAndFrame_tHolder.getSelectedQuantity() * 3 +
        this.rackColumnAndFrame_tClamp.getSelectedQuantity() * 4 +
        this.rackColumnAndFrame1_normalHolder.getSelectedQuantity() * 2 +
        this.rackColumnAndFrame2_normalHolder.getSelectedQuantity() * 2 +
        this.rackWidthAndColumn_saddleHolder.getSelectedQuantity() * 3 +
        this.rackWidthAndColumn_tClamp.getSelectedQuantity() * 4 +
        this.rackStraight_connectionPin.getSelectedQuantity() * 2 +
        this.rackStraightAndWidth_saddleHolder.getSelectedQuantity() * 3 +
        this.rackStraightBottom_connectionPin.getSelectedQuantity() * 2) /
        this.rack_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 선반 틀 수량 <- 선반 길이, 선반 틀 간격, 선반 줄수, 선반 동수
   */
  public algorithm_frameQuantitiy(): void {
    const rackPosition: RackPosition = <RackPosition>this.position;
    this.frameQuantitiy =
      (Math.floor(this.rackLength / this.frameInterval) + 1) *
      rackPosition.rackLineNumber *
      rackPosition.rackBuildingNumber;
  }

  /**
   * 알고리즘: 선반 단 높이 <- 선반 높이, 선반 단수, 선반 하단 높이
   */
  public algorithm_levelHeight(): void {
    if (this.levelNumber - 1 === 0) {
      this.levelHeight = 0;
      this.legHeight = this.rackHeight;
    } else {
      this.levelHeight = (this.rackHeight - this.legHeight) / (this.levelNumber - 1);
    }
  }

  /**
   * 알고리즘: 선반 길이 <- 하우스 길이
   */
  public algorithm_rackLength(): void {
    this.rackLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].length - 1;
  }

  /**
   * 알고리즘: 선반 직관 간격 <- 선반 폭, 선반 직관 줄수
   */
  public algorithm_straightInterval(): void {
    this.straightInterval = CommonUtil.round(this.rackWidth / (this.straightLineNumber - 1), 2);
  }

  /**
   * 알고리즘: 통로 폭 <- 하우스 폭, 선반 폭, 선반 줄수
   */
  public algorithm_hallwayWidth(): void {
    const innerWidth: number = this.basic.basicLevelAC[this.basic.levelNumber - 1].width;
    this.hallwayWidth = CommonUtil.round(
      (innerWidth - this.rackWidth * (<RackPosition>this.position).rackLineNumber) /
        (<RackPosition>this.position).rackLineNumber,
      2,
    );
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [선반 중방] 파이프 <- 선반 중방 파이프 샘플
   */
  public algorithmSpec_rackMiddle_pipe(): void {
    this.rackMiddle_pipe.specs = this.rackMiddlePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [선반 중방] 연결핀 <- 선반 중방 파이프 샘플
   */
  public algorithmSpec_rackMiddle_connectionPin(): void {
    this.rackMiddle_connectionPin.specPipeType = this.rackMiddlePipeSample.specPipeType;
    this.rackMiddle_connectionPin.specCrossSize1 = this.rackMiddlePipeSample.specCrossSize;
    this.rackMiddle_connectionPin.specCrossSize2 = this.rackMiddlePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 중방 + 서까래] T 고정구 <- 선반 중방 파이프 샘플, 서까래 파이프 샘플(단동)
   */
  public algorithmSpec_rackMiddleAndRafter_tHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
      this.rackMiddleAndRafter_tHolder.specPipeType = trunkPosition.rafterPart.rafterPipeSample.specPipeType;
      this.rackMiddleAndRafter_tHolder.specCrossSize1 = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
      this.rackMiddleAndRafter_tHolder.specCrossSize2 = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [선반 중방 + 서까래 (1)] 일반 고정구 <- 선반 중방 파이프 샘플
   */
  public algorithmSpec_rackMiddleAndRafter1_normalHolder(): void {
    this.rackMiddleAndRafter1_normalHolder.specPipeType = this.rackMiddlePipeSample.specPipeType;
    this.rackMiddleAndRafter1_normalHolder.specCrossSize1 = this.rackMiddlePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 중방 + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플(단동)
   */
  public algorithmSpec_rackMiddleAndRafter2_normalHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
      this.rackMiddleAndRafter2_normalHolder.specPipeType = trunkPosition.rafterPart.rafterPipeSample.specPipeType;
      this.rackMiddleAndRafter2_normalHolder.specCrossSize1 = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [선반 기둥] 파이프 <- 선반 기둥 파이프 샘플
   */
  public algorithmSpec_rackColumn_pipe(): void {
    this.rackColumn_pipe.specs = this.rackColumnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [선반 기둥 + 골조] T 고정구 <- 선반 기둥 파이프 샘플, 선반 기둥 고정 골조, 선반 중방 파이프 샘플, 서까래 파이프 샘플(단동), 가로대 파이프 샘플(단동)
   */
  public algorithmSpec_rackColumnAndFrame_tHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_RAFTER) {
        this.rackColumnAndFrame_tHolder.specPipeType = trunkPosition.rafterPart.rafterPipeSample.specPipeType;
        this.rackColumnAndFrame_tHolder.specCrossSize1 = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
        this.rackColumnAndFrame_tHolder.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
      } else if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_STRAIGHT) {
        this.rackColumnAndFrame_tHolder.specPipeType = trunkPosition.straightPart.straightRoofPipeSample.specPipeType;
        this.rackColumnAndFrame_tHolder.specCrossSize1 =
          trunkPosition.straightPart.straightRoofPipeSample.specCrossSize;
        this.rackColumnAndFrame_tHolder.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
      } else if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        this.rackColumnAndFrame_tHolder.specPipeType = this.rackMiddlePipeSample.specPipeType;
        this.rackColumnAndFrame_tHolder.specCrossSize1 = this.rackMiddlePipeSample.specCrossSize;
        this.rackColumnAndFrame_tHolder.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      // 불가능
    }
  }

  /**
   * 규격 알고리즘: [선반 가로 + 골조] T 클램프 <- 선반 기둥 파이프 샘플, 선반 기둥 고정 골조, 선반 중방 파이프 샘플, 서까래 파이프 샘플(단동), 가로대 파이프 샘플(단동), 기둥 형태(연동,벤로), 중방 파이프 샘플(연동,벤로)
   */
  public algorithmSpec_rackColumnAndFrame_tClamp(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_RAFTER) {
        this.rackColumnAndFrame_tClamp.specPipeType = trunkPosition.rafterPart.rafterPipeSample.specPipeType;
        this.rackColumnAndFrame_tClamp.specCrossSize1 = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
        this.rackColumnAndFrame_tClamp.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
      } else if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_STRAIGHT) {
        this.rackColumnAndFrame_tClamp.specPipeType = trunkPosition.straightPart.straightRoofPipeSample.specPipeType;
        this.rackColumnAndFrame_tClamp.specCrossSize1 = trunkPosition.straightPart.straightRoofPipeSample.specCrossSize;
        this.rackColumnAndFrame_tClamp.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
      } else if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        this.rackColumnAndFrame_tClamp.specPipeType = this.rackMiddlePipeSample.specPipeType;
        this.rackColumnAndFrame_tClamp.specCrossSize1 = this.rackMiddlePipeSample.specCrossSize;
        this.rackColumnAndFrame_tClamp.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
          this.rackColumnAndFrame_tClamp.specPipeType = columnPosition.cylinderMiddlePart.middlePipeSample.specPipeType;
          this.rackColumnAndFrame_tClamp.specCrossSize1 =
            columnPosition.cylinderMiddlePart.middlePipeSample.specCrossSize;
          this.rackColumnAndFrame_tClamp.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
        } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.rackColumnAndFrame_tClamp.specPipeType = columnPosition.prismMiddlePart.middlePipeSample.specPipeType;
          this.rackColumnAndFrame_tClamp.specCrossSize1 = columnPosition.prismMiddlePart.middlePipeSample.specCrossSize;
          this.rackColumnAndFrame_tClamp.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
        }
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.rackColumnAndFrame_tClamp.specPipeType = columnPosition.prismMiddlePart.middlePipeSample.specPipeType;
          this.rackColumnAndFrame_tClamp.specCrossSize1 = columnPosition.prismMiddlePart.middlePipeSample.specCrossSize;
          this.rackColumnAndFrame_tClamp.specCrossSize2 = this.rackColumnPipeSample.specCrossSize;
        }
      }
    }
  }

  /**
   * 규격 알고리즘: [선반 기둥 + 골조 (1)] 일반 고정구 <- 선반 기둥 파이프 샘플
   */
  public algorithmSpec_rackColumnAndFrame1_normalHolder(): void {
    this.rackColumnAndFrame1_normalHolder.specPipeType = this.rackColumnPipeSample.specPipeType;
    this.rackColumnAndFrame1_normalHolder.specCrossSize1 = this.rackColumnPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 기둥 + 골조 (2)] 일반 고정구 <- 선반 기둥 고정 골조, 선반 중방 파이프 샘플, 서까래 파이프 샘플(단동), 가로대 파이프 샘플(단동), 기둥 형태(연동,벤로), 중방 파이프 샘플(연동,벤로)
   */
  public algorithmSpec_rackColumnAndFrame2_normalHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_RAFTER) {
        this.rackColumnAndFrame2_normalHolder.specPipeType = trunkPosition.rafterPart.rafterPipeSample.specPipeType;
        this.rackColumnAndFrame2_normalHolder.specCrossSize1 = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
      } else if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_STRAIGHT) {
        this.rackColumnAndFrame2_normalHolder.specPipeType =
          trunkPosition.straightPart.straightRoofPipeSample.specPipeType;
        this.rackColumnAndFrame2_normalHolder.specCrossSize1 =
          trunkPosition.straightPart.straightRoofPipeSample.specCrossSize;
      } else if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        this.rackColumnAndFrame2_normalHolder.specPipeType = this.rackMiddlePipeSample.specPipeType;
        this.rackColumnAndFrame2_normalHolder.specCrossSize1 = this.rackMiddlePipeSample.specCrossSize;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
          this.rackColumnAndFrame2_normalHolder.specPipeType =
            columnPosition.cylinderMiddlePart.middlePipeSample.specPipeType;
          this.rackColumnAndFrame2_normalHolder.specCrossSize1 =
            columnPosition.cylinderMiddlePart.middlePipeSample.specCrossSize;
        } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.rackColumnAndFrame2_normalHolder.specPipeType =
            columnPosition.prismMiddlePart.middlePipeSample.specPipeType;
          this.rackColumnAndFrame2_normalHolder.specCrossSize1 =
            columnPosition.prismMiddlePart.middlePipeSample.specCrossSize;
        }
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.columnFixedFrame === CONST.LB_RACK_FIXED_FRAME_PIPE_MIDDLE) {
        const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.rackColumnAndFrame2_normalHolder.specPipeType =
            columnPosition.prismMiddlePart.middlePipeSample.specPipeType;
          this.rackColumnAndFrame2_normalHolder.specCrossSize1 =
            columnPosition.prismMiddlePart.middlePipeSample.specCrossSize;
        }
      }
    }
  }

  /**
   * 규격 알고리즘: [선반 가로] 파이프 <- 선반 가로 파이프 샘플
   */
  public algorithmSpec_rackWidth_pipe(): void {
    this.rackWidth_pipe.specs = this.rackWidthPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [선반 가로 + 선반 기둥] 새들 고정구 <- 선반 가로 파이프 샘플
   */
  public algorithmSpec_rackWidthAndColumn_saddleHolder(): void {
    this.rackWidthAndColumn_saddleHolder.specPipeType = this.rackWidthPipeSample.specPipeType;
    this.rackWidthAndColumn_saddleHolder.specCrossSize1 = this.rackWidthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 가로 + 선반 기둥] T 클램프 <- 선반 가로 파이프 샘플, 선반 기둥 파이프 샘플
   */
  public algorithmSpec_rackWidthAndColumn_tClamp(): void {
    this.rackWidthAndColumn_tClamp.specPipeType = this.rackColumnPipeSample.specPipeType;
    this.rackWidthAndColumn_tClamp.specCrossSize1 = this.rackColumnPipeSample.specCrossSize;
    this.rackWidthAndColumn_tClamp.specCrossSize2 = this.rackWidthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 직관] 파이프 <- 선반 직관 파이프 샘플
   */
  public algorithmSpec_rackStraight_pipe(): void {
    this.rackStraight_pipe.specs = this.rackStraightPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [선반 직관] 연결핀 <- 선반 직관 파이프 샘플
   */
  public algorithmSpec_rackStraight_connectionPin(): void {
    this.rackStraight_connectionPin.specPipeType = this.rackStraightPipeSample.specPipeType;
    this.rackStraight_connectionPin.specCrossSize1 = this.rackStraightPipeSample.specCrossSize;
    this.rackStraight_connectionPin.specCrossSize2 = this.rackStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 직관 + 선반 가로] 새들 고정구 <- 선반 직관 파이프 샘플
   */
  public algorithmSpec_rackStraightAndWidth_saddleHolder(): void {
    this.rackStraightAndWidth_saddleHolder.specPipeType = this.rackStraightPipeSample.specPipeType;
    this.rackStraightAndWidth_saddleHolder.specCrossSize1 = this.rackStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 직관 + 선반 가로] 강선 조리개 <- 선반 직관 파이프 샘플, 선반 가로 파이프 샘플
   */
  public algorithmSpec_rackStraightAndWidth_steelStringGrabber(): void {
    this.rackStraightAndWidth_steelStringGrabber.specPipeType = this.rackStraightPipeSample.specPipeType;
    this.rackStraightAndWidth_steelStringGrabber.specCrossSize1 = this.rackStraightPipeSample.specCrossSize;
    this.rackStraightAndWidth_steelStringGrabber.specCrossSize2 = (<RackPosition>(
      this.position
    )).rackPart.rackWidthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 바닥 직관] 파이프 <- 선반 바닥 직관 파이프 샘플
   */
  public algorithmSpec_rackStraightBottom_pipe(): void {
    this.rackStraightBottom_pipe.specs = this.rackStraightBottomPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [선반 바닥 직관] 연결핀 <- 선반 바닥 직관 파이프 샘플
   */
  public algorithmSpec_rackStraightBottom_connectionPin(): void {
    this.rackStraightBottom_connectionPin.specPipeType = this.rackStraightBottomPipeSample.specPipeType;
    this.rackStraightBottom_connectionPin.specCrossSize1 = this.rackStraightBottomPipeSample.specCrossSize;
    this.rackStraightBottom_connectionPin.specCrossSize2 = this.rackStraightBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 바닥 직관 + 선반 기둥] 강선 조리개 <- 선반 바닥 직관 파이프 샘플, 선반 기둥 파이프 샘플
   */
  public algorithmSpec_rackStraightBottomAndColumn_steelStringGrabber(): void {
    this.rackStraightBottomAndColumn_steelStringGrabber.specPipeType = this.rackStraightBottomPipeSample.specPipeType;
    this.rackStraightBottomAndColumn_steelStringGrabber.specCrossSize1 =
      this.rackStraightBottomPipeSample.specCrossSize;
    this.rackStraightBottomAndColumn_steelStringGrabber.specCrossSize2 = (<RackPosition>(
      this.position
    )).rackPart.rackColumnPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반 바닥 직관 + 선반 기둥] 고정 아시바 클램프 <- 선반 바닥 직관 파이프 샘플, 선반 기둥 파이프 샘플
   */
  public algorithmSpec_rackStraightBottomAndColumn_fixAsibaClamp(): void {
    this.rackStraightBottomAndColumn_fixAsibaClamp.specPipeType = (<RackPosition>(
      this.position
    )).rackPart.rackColumnPipeSample.specPipeType;
    this.rackStraightBottomAndColumn_fixAsibaClamp.specCrossSize1 = (<RackPosition>(
      this.position
    )).rackPart.rackColumnPipeSample.specCrossSize;
    this.rackStraightBottomAndColumn_fixAsibaClamp.specCrossSize2 = this.rackStraightBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [선반] 용접 <- 공통 샘플
   */
  public algorithmSpec_rack_welding(): void {}

  /**
   * 규격 알고리즘: [선반] 나사 <- 공통 샘플
   */
  public algorithmSpec_(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
