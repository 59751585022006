import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 통합 제어기
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-08-08
 */
@jsonObject
export class ItemIntegratedController extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  /** 용량 소의 최대값 */
  public static SPEC_VOLUME_MAX_SMALL: number = 4;
  /** 용량 중소의 최대값 */
  public static SPEC_VOLUME_MAX_MIDDLE_SMALL: number = 10;
  /** 용량 중의 최대값 */
  public static SPEC_VOLUME_MAX_MIDDLE: number = 20;
  /** 용량 중대의 최대값 */
  public static SPEC_VOLUME_MAX_MIDDLE_LARGE: number = 30;
  /** 용량 대의 최대값 */
  public static SPEC_VOLUME_MAX_LARGE: number = 40;

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, 용량], ?(통합), ?(비닐), ?(부직), ?(커튼), ?(환기), ?(관수), ?(기타AC)
  public _specFeature: string = undefined; // 특성
  public _specVolume: string = undefined; // 용량
  public _specCombineSwt: number = undefined; // 통합
  public _specVinylSwt: number = undefined; // 비닐
  public _specNonwovenSwt: number = undefined; // 부직
  public _specCurtainSwt: number = undefined; // 커튼
  public _specVentSwt: number = undefined; // 환기
  public _specWateringSwt: number = undefined; // 관수
  public _specOtherAcSwt: number = undefined; // 기타AC

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 용량
   */
  public get specVolume(): string {
    return this._specVolume;
  }
  //
  public set specVolume(value: string) {
    this._specVolume = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 통합
   */
  public get specCombineSwt(): number {
    return this._specCombineSwt;
  }
  //
  public set specCombineSwt(value: number) {
    this._specCombineSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 통합 레이블
   */
  public get specCombineSwtLabel(): string {
    return CONST.ITEM_SPEC_PREFIX_COMBINE + this.specCombineSwt;
  }
  //
  public set specCombineSwtLabel(value: string) {
    this.specCombineSwt = parseFloat(value.substr(CONST.ITEM_SPEC_PREFIX_COMBINE.length));
  }

  /**
   * 비닐
   */
  public get specVinylSwt(): number {
    return this._specVinylSwt;
  }
  //
  public set specVinylSwt(value: number) {
    this._specVinylSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 비닐 레이블
   */
  public get specVinylSwtLabel(): string {
    return CONST.ITEM_SPEC_PREFIX_VINYL + this.specVinylSwt;
  }
  //
  public set specVinylSwtLabel(value: string) {
    this.specVinylSwt = parseFloat(value.substr(CONST.ITEM_SPEC_PREFIX_VINYL.length));
  }

  /**
   * 부직
   */
  public get specNonwovenSwt(): number {
    return this._specNonwovenSwt;
  }
  //
  public set specNonwovenSwt(value: number) {
    this._specNonwovenSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 부직 레이블
   */
  public get specNonwovenSwtLabel(): string {
    return CONST.ITEM_SPEC_PREFIX_NONWOVEN + this.specNonwovenSwt;
  }
  //
  public set specNonwovenSwtLabel(value: string) {
    this.specNonwovenSwt = parseFloat(value.substr(CONST.ITEM_SPEC_PREFIX_NONWOVEN.length));
  }

  /**
   * 커튼
   */
  public get specCurtainSwt(): number {
    return this._specCurtainSwt;
  }
  //
  public set specCurtainSwt(value: number) {
    this._specCurtainSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 커튼 레이블
   */
  public get specCurtainSwtLabel(): string {
    return CONST.ITEM_SPEC_PREFIX_CURTAIN + this.specCurtainSwt;
  }
  //
  public set specCurtainSwtLabel(value: string) {
    this.specCurtainSwt = parseFloat(value.substr(CONST.ITEM_SPEC_PREFIX_CURTAIN.length));
  }

  /**
   * 환기
   */
  public get specVentSwt(): number {
    return this._specVentSwt;
  }
  //
  public set specVentSwt(value: number) {
    this._specVentSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 환기 레이블
   */
  public get specVentSwtLabel(): string {
    return CONST.ITEM_SPEC_PREFIX_VENT + this.specVentSwt;
  }
  //
  public set specVentSwtLabel(value: string) {
    this.specVentSwt = parseFloat(value.substr(CONST.ITEM_SPEC_PREFIX_VENT.length));
  }

  /**
   * 관수
   */
  public get specWateringSwt(): number {
    return this._specWateringSwt;
  }
  //
  public set specWateringSwt(value: number) {
    this._specWateringSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 관수 레이블
   */
  public get specWateringSwtLabel(): string {
    return CONST.ITEM_SPEC_PREFIX_WATERING + this.specWateringSwt;
  }
  //
  public set specWateringSwtLabel(value: string) {
    this.specWateringSwt = parseFloat(value.substr(CONST.ITEM_SPEC_PREFIX_WATERING.length));
  }

  /**
   * 기타AC
   */
  public get specOtherAcSwt(): number {
    return this._specOtherAcSwt;
  }
  //
  public set specOtherAcSwt(value: number) {
    this._specOtherAcSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 기타AC 레이블
   */
  public get specOtherAcSwtLabel(): string {
    return CONST.ITEM_SPEC_PREFIX_OTHER_AC + this.specOtherAcSwt;
  }
  //
  public set specOtherAcSwtLabel(value: string) {
    this.specOtherAcSwt = parseFloat(value.substr(CONST.ITEM_SPEC_PREFIX_OTHER_AC.length));
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.integratedControllerCategoryModel;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 통합 단가
    const combineSwtUnitSupply: number = this.brandCompanyModel.optionUnitSupply1;
    // 통합 매출가 계산식
    const combineSwtSellingSupply: number = combineSwtUnitSupply * this.specCombineSwt;

    // 비닐 단가
    const vinylSwtUnitSupply: number = this.brandCompanyModel.optionUnitSupply2;
    // 비닐 매출가 계산식
    const vinylSwtSellingSupply: number = vinylSwtUnitSupply * this.specVinylSwt;

    // 부직 단가
    const nonwovenSwtUnitSupply: number = this.brandCompanyModel.optionUnitSupply3;
    // 부직 매출가 계산식
    const nonwovenSwtSellingSupply: number = nonwovenSwtUnitSupply * this.specNonwovenSwt;

    // 커튼 단가
    const curtainSwtUnitSupply: number = this.brandCompanyModel.optionUnitSupply4;
    // 커튼 매출가 계산식
    const curtainSwtSellingSupply: number = curtainSwtUnitSupply * this.specCurtainSwt;

    // 환기 단가
    const ventSwtUnitSupply: number = this.brandCompanyModel.optionUnitSupply5;
    // 환기 매출가 계산식
    const ventSwtSellingSupply: number = ventSwtUnitSupply * this.specVentSwt;

    // 관수 단가
    const wateringSwtUnitSupply: number = this.brandCompanyModel.optionUnitSupply6;
    // 관수 매출가 계산식
    const wateringSwtSellingSupply: number = wateringSwtUnitSupply * this.specWateringSwt;

    // 기타AC 단가
    const otherAcSwtUnitSupply: number = this.brandCompanyModel.optionUnitSupply7;
    // 기타AC 매출가 계산식
    const otherAcSwtSellingSupply: number = otherAcSwtUnitSupply * this.specOtherAcSwt;

    // 매출가 계산식
    let sellingSupply: number =
      defaultUnitSupply +
      combineSwtSellingSupply +
      vinylSwtSellingSupply +
      nonwovenSwtSellingSupply +
      curtainSwtSellingSupply +
      ventSwtSellingSupply +
      wateringSwtSellingSupply +
      otherAcSwtSellingSupply;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specVolume = specsArray[1];
    this.specCombineSwtLabel = specsArray[2];
    this.specVinylSwtLabel = specsArray[3];
    this.specNonwovenSwtLabel = specsArray[4];
    this.specCurtainSwtLabel = specsArray[5];
    this.specVentSwtLabel = specsArray[6];
    this.specWateringSwtLabel = specsArray[7];
    this.specOtherAcSwtLabel = specsArray[8];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specVolume = specsArray[1];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature + CONST.DELIMITER_SPEC + this._specVolume;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList +
      CONST.DELIMITER_SPEC +
      this.specCombineSwtLabel +
      CONST.DELIMITER_SPEC +
      this.specVinylSwtLabel +
      CONST.DELIMITER_SPEC +
      this.specNonwovenSwtLabel +
      CONST.DELIMITER_SPEC +
      this.specCurtainSwtLabel +
      CONST.DELIMITER_SPEC +
      this.specVentSwtLabel +
      CONST.DELIMITER_SPEC +
      this.specWateringSwtLabel +
      CONST.DELIMITER_SPEC +
      this.specOtherAcSwtLabel;
  }
}
