import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SkirtLevel } from "vhows-design/src/object/design/cover/skirt/SkirtLevel";
import { TrunkPart_Straight } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Straight";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-26
 */
@jsonObject
export class TrunkSample_StraightSideSubPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const straightPart: TrunkPart_Straight = <TrunkPart_Straight>this.part;
    straightPart.algorithmSpec_straightSideSub_pipe();
    straightPart.algorithmSpec_straightSideSub_connectionPin();
    straightPart.algorithmSpec_straightSideSubAndRafter_steelStringGrabber();
    straightPart.algorithmSpec_straightSideSubAndRafter_saddleHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 치마 피복
    const skirtLevel: SkirtLevel = <SkirtLevel>this.struct.skirtWork.levelAC[this.level.index];
    skirtLevel.sidePosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 가로대 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specs = (<TrunkPart_Straight>this.part).straightPipeSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
