import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { ColumnPartIL_SideStraight } from "vhows-design/src/object/design/frame/column/ColumnPartIL_SideStraight";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WindowPositionIL } from "vhows-design/src/object/design/other/window/il/WindowPositionIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { ColumnPartIL_PrismColumn } from "vhows-design/src/object/design/frame/column/ColumnPartIL_PrismColumn";
import { ColumnPartIL_PrismBeam } from "vhows-design/src/object/design/frame/column/ColumnPartIL_PrismBeam";
import { ColumnPartIL_PrismMiddle } from "vhows-design/src/object/design/frame/column/ColumnPartIL_PrismMiddle";
import { ColumnPartIL_CylinderMiddle } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderMiddle";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";
import { ColumnPartIL_CylinderBeam } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderBeam";
import { ColumnPartIL_CylinderColumn } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderColumn";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-10
 */
@jsonObject({
  knownTypes: [
    ColumnPartIL_CylinderBeam,
    ColumnPartIL_CylinderColumn,
    ColumnPartIL_CylinderMiddle,
    ColumnPartIL_PrismBeam,
    ColumnPartIL_PrismColumn,
    ColumnPartIL_PrismMiddle,
    ColumnPartIL_SideStraight,
  ],
})
export class ColumnPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public cylinderColumnPart: ColumnPartIL_CylinderColumn;
  public cylinderBeamPart: ColumnPartIL_CylinderBeam;
  public cylinderMiddlePart: ColumnPartIL_CylinderMiddle;
  public prismColumnPart: ColumnPartIL_PrismColumn;
  public prismBeamPart: ColumnPartIL_PrismBeam;
  public prismMiddlePart: ColumnPartIL_PrismMiddle;
  public sideStraightPart: ColumnPartIL_SideStraight;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 기둥 길이 <- 형태, 원기둥 길이, 각기둥 길이
   */
  public get columnLength(): number {
    if (this.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      return this.cylinderColumnPart.columnLength;
    } else if (this.type === CONST.LB_COLUMN_TYPE_PRISM) {
      return this.prismColumnPart.columnLength;
    }
    return NaN;
  }

  /**
   * 기둥 간격 <- 형태, 원기둥 간격, 각기둥 간격
   */
  public get columnInterval(): number {
    if (this.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      return this.cylinderColumnPart.columnInterval;
    } else if (this.type === CONST.LB_COLUMN_TYPE_PRISM) {
      return this.prismColumnPart.columnInterval;
    }
    return NaN;
  }

  /**
   * 기둥 수량 <- 형태, 원기둥 수량, 각기둥 수량
   */
  public get columnQuantity(): number {
    if (this.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      return this.cylinderColumnPart.columnQuantity;
    } else if (this.type === CONST.LB_COLUMN_TYPE_PRISM) {
      return this.prismColumnPart.columnQuantity;
    }
    return NaN;
  }

  /**
   * 기둥 파이픠형태 <- 형태, 원기둥 파이프, 각기둥 파이프
   */
  public get columnPipeType(): string {
    if (this.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      return this.cylinderColumnPart.columnPipeSample.specPipeType;
    } else if (this.type === CONST.LB_COLUMN_TYPE_PRISM) {
      return this.prismColumnPart.columnPipeSample.specPipeType;
    }
    return null;
  }

  /**
   * 기둥 단면크기 <- 형태, 원기둥 파이프, 각기둥 파이프
   */
  public get columnCrossSize(): string {
    if (this.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      return this.cylinderColumnPart.columnPipeSample.specCrossSize;
    } else if (this.type === CONST.LB_COLUMN_TYPE_PRISM) {
      return this.prismColumnPart.columnPipeSample.specCrossSize;
    }
    return null;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.sideStraightPart.algorithmPart();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 지붕 골조
    for (const roofLevel of this.struct.roofWorkIL.levelAC as RoofLevelIL[]) {
      roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce();
      roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamEdge_firstLevelSoleSconce();
      roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndColumnEdge_secondLevelSoleSconce();
      roofLevel.roofPosition.rafterPart.algorithm_rafterInterval();
      roofLevel.roofPosition.rafterPart.algorithmPart();
    }

    // 방풍벽 골조
    this.struct.wingWorkIL.level1.wingPosition.wingRafterPart.algorithmSpec_wingRafterFirstAndBeam_outerWallClamp();
    this.struct.wingWorkIL.algorithmBasic();

    // 바닥 골조
    this.struct.foundationWorkIL.level1.foundationPosition.girthPipePart.algorithmPart();
    this.struct.foundationWorkIL.level1.foundationPosition.columnConcretePart.algorithm_moldQuantity();
    this.struct.foundationWorkIL.level1.foundationPosition.columnConcretePart.algorithmPart();

    // 마구리 골조
    for (const endpiecePosition of this.struct.endpieceWorkIL.level1.positionAC as EndpiecePositionIL[]) {
      endpiecePosition.algorithm_typeByColumnType();
    }
    this.struct.endpieceWorkIL.algorithmBasic();

    // 물받이
    for (const gutterLevel of this.struct.gutterWorkIL.levelAC as GutterLevelIL[]) {
      gutterLevel.gutterPosition.ironPlatePart.algorithmSpec_ironPlate_ubar();
      gutterLevel.gutterPosition.ironPlatePart.algorithmPart();
      gutterLevel.gutterPosition.plasticPart.algorithmSpec_plastic_ubar();
      gutterLevel.gutterPosition.plasticPart.algorithmPart();
      gutterLevel.gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_steelStringGrabber();
      gutterLevel.gutterPosition.weavingFilmPart.algorithmPart();
    }

    // 피복 고정
    for (const fixingLevel of this.struct.fixingWorkIL.levelAC as FixingLevelIL[]) {
      fixingLevel.columnPosition.algorithmBasic();
      fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipColumn_normalClip();
      fixingLevel.frontPosition.algorithmBasic();
      fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipColumn_normalClip();
      fixingLevel.backPosition.algorithmBasic();
    }

    // 치마 피복
    for (const skirtLevel of this.struct.skirtWorkIL.levelAC as SkirtLevelIL[]) {
      skirtLevel.columnPosition.fixingSkirtClipPart.algorithm_clipInterval();
      skirtLevel.columnPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
      skirtLevel.columnPosition.algorithmBasic();
    }

    // 바람막이 피복
    for (const windbreakLevel of this.struct.windbreakWorkIL.levelAC as WindbreakLevelIL[]) {
      windbreakLevel.columnPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      windbreakLevel.columnPosition.algorithmBasic();
    }

    // 선반/균상다이
    if (this.level.index === 0) {
      const rackPosition: RackPosition = this.struct.rackWork.level1.wholePosition;
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tClamp();
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame2_normalHolder();
      rackPosition.rackPart.algorithmPart();
    }

    // 커튼 개폐
    for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
      curSwitchLevel.columnPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
      curSwitchLevel.columnPosition.axisPart.algorithmPart();
      curSwitchLevel.columnPosition.linePart.algorithmPart();
      curSwitchLevel.columnPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.frontPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
      curSwitchLevel.frontPosition.axisPart.algorithmPart();
      curSwitchLevel.frontPosition.linePart.algorithmPart();
      curSwitchLevel.frontPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.backPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
      curSwitchLevel.backPosition.axisPart.algorithmPart();
      curSwitchLevel.backPosition.linePart.algorithmPart();
      curSwitchLevel.backPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.ceilingPosition.motorPart.reducedMotorSample.algorithmSpec();
      curSwitchLevel.ceilingPosition.framePart.algorithmSpec_frameDriveAxis_bearingSet();
      curSwitchLevel.ceilingPosition.framePart.algorithmSpec_frameSupportAxis_uClamp();
      curSwitchLevel.ceilingPosition.framePart.algorithmPart();
      curSwitchLevel.ceilingPosition.linePart.algorithmPart();
      curSwitchLevel.ceilingPosition.fixingClipCeilingPart.algorithmPart();
    }
    (<CurSwitchLevelIL>(
      this.struct.curSwitchWorkIL.levelAC[this.level.index]
    )).ceilingPosition.linePart.algorithm_towLineNumberAndSupportLineNumber();

    // 관수 제어부
    if (this.level.index === 0) {
      this.struct.mainpipeWork.level1.wholePosition.upperFramePart.algorithmPart();
    }

    // 창문
    if (this.level.index === 0) {
      for (const windowPosition of this.struct.windowWorkIL.level1.positionAC as WindowPositionIL[]) {
        windowPosition.sashPart.sashFramePipeSample.algorithmSpec();
      }
    }
    // 양액시스템
    if (this.level.index === 0) {
      this.struct.hydCultivationWork.level1.wholePosition.bedPart.algorithm_hallwayWidth();
    }

    // 운반레일
    if (this.level.index === 0) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithm_railHookInterval();
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithmSpec_railAndFrame_secondLevelRailHook();
      this.struct.railWork.level1.wholePosition.sideRailPart.algorithm_railHookInterval();
      this.struct.railWork.level1.wholePosition.sideRailPart.algorithmSpec_railAndFrame_sideRailHook();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.cylinderColumnPart = new ColumnPartIL_CylinderColumn();
    this.cylinderBeamPart = new ColumnPartIL_CylinderBeam();
    this.cylinderMiddlePart = new ColumnPartIL_CylinderMiddle();
    this.prismColumnPart = new ColumnPartIL_PrismColumn();
    this.prismBeamPart = new ColumnPartIL_PrismBeam();
    this.prismMiddlePart = new ColumnPartIL_PrismMiddle();
    this.sideStraightPart = new ColumnPartIL_SideStraight();

    this.partAC = [
      this.cylinderColumnPart,
      this.cylinderBeamPart,
      this.cylinderMiddlePart,
      this.prismColumnPart,
      this.prismBeamPart,
      this.prismMiddlePart,
      this.sideStraightPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.cylinderColumnPart = <ColumnPartIL_CylinderColumn>this.partAC[0];
    this.cylinderBeamPart = <ColumnPartIL_CylinderBeam>this.partAC[1];
    this.cylinderMiddlePart = <ColumnPartIL_CylinderMiddle>this.partAC[2];
    this.prismColumnPart = <ColumnPartIL_PrismColumn>this.partAC[3];
    this.prismBeamPart = <ColumnPartIL_PrismBeam>this.partAC[4];
    this.prismMiddlePart = <ColumnPartIL_PrismMiddle>this.partAC[5];
    this.sideStraightPart = <ColumnPartIL_SideStraight>this.partAC[6];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    if (this.level.index >= 0) {
      // 원형 기둥
      this.cylinderColumnPart.setDefaultData(
        CONST.INDEX_IL_COLUMN_CYLINDER_COLUMN,
        true,
        false,
        true,
        CONST.LB_COLUMN_CYLINDER_COLUMN,
        "",
        3.5,
        2.0,
        true,
        4,
        true,
      );

      // 원형 보
      this.cylinderBeamPart.setDefaultData(
        CONST.INDEX_IL_COLUMN_CYLINDER_BEAM,
        true,
        false,
        true,
        CONST.LB_COLUMN_CYLINDER_BEAM,
        "",
      );

      // 원형 중방
      this.cylinderMiddlePart.setDefaultData(
        CONST.INDEX_IL_COLUMN_CYLINDER_MIDDLE,
        true,
        false,
        true,
        CONST.LB_COLUMN_CYLINDER_MIDDLE,
        "",
        false,
        0.7,
        15,
        true,
        CONST.LB_COLUMN_MIDDLE_SUPPORT_TYPE_WORKED_PIPE,
        1,
        false,
      );

      // 각형 기둥
      this.prismColumnPart.setDefaultData(
        CONST.INDEX_IL_COLUMN_PRISM_COLUMN,
        false,
        false,
        false,
        CONST.LB_COLUMN_PRISM_COLUMN,
        "",
        3.5,
        3.0,
        true,
        true,
        0.4,
        4,
        true,
      );

      // 각형 보
      this.prismBeamPart.setDefaultData(
        CONST.INDEX_IL_COLUMN_PRISM_BEAM,
        false,
        false,
        false,
        CONST.LB_COLUMN_PRISM_BEAM,
        "",
      );

      // 각형 중방
      this.prismMiddlePart.setDefaultData(
        CONST.INDEX_IL_COLUMN_PRISM_MIDDLE,
        false,
        false,
        false,
        CONST.LB_COLUMN_PRISM_MIDDLE,
        "",
        false,
        0.7,
        15,
        true,
        CONST.LB_COLUMN_MIDDLE_SUPPORT_TYPE_WORKED_PIPE,
        1,
        true,
      );

      // 측면 가로대
      this.sideStraightPart.setDefaultData(
        CONST.INDEX_IL_COLUMN_SIDE_STRAIGHT,
        true,
        false,
        true,
        CONST.LB_COLUMN_SIDE_STRAIGHT,
        "",
        2,
        CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_PIPE,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      this.cylinderColumnPart.selected = true;
      this.cylinderColumnPart.visible = true;
      this.cylinderBeamPart.selected = true;
      this.cylinderBeamPart.visible = true;
      this.cylinderMiddlePart.selected = true;
      this.cylinderMiddlePart.visible = true;
      this.prismColumnPart.selected = false;
      this.prismColumnPart.visible = false;
      this.prismBeamPart.selected = false;
      this.prismBeamPart.visible = false;
      this.prismMiddlePart.selected = false;
      this.prismMiddlePart.visible = false;
      this.sideStraightPart.selected = true;
      this.sideStraightPart.visible = true;
    } else if (this.type === CONST.LB_COLUMN_TYPE_PRISM) {
      this.cylinderColumnPart.selected = false;
      this.cylinderColumnPart.visible = false;
      this.cylinderBeamPart.selected = false;
      this.cylinderBeamPart.visible = false;
      this.cylinderMiddlePart.selected = false;
      this.cylinderMiddlePart.visible = false;
      this.prismColumnPart.selected = true;
      this.prismColumnPart.visible = true;
      this.prismBeamPart.selected = true;
      this.prismBeamPart.visible = true;
      this.prismMiddlePart.selected = true;
      this.prismMiddlePart.visible = true;
      this.sideStraightPart.selected = true;
      this.sideStraightPart.visible = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
