import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { EndpiecePartIL_CylinderMiddle } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_CylinderMiddle";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-24
 */
@jsonObject
export class EndpieceSampleIL_CylinderMiddleUpperPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const cylinderMiddlePart: EndpiecePartIL_CylinderMiddle = <EndpiecePartIL_CylinderMiddle>this.part;
    cylinderMiddlePart.algorithmSpec_middleUpper_pipe();
    cylinderMiddlePart.algorithmSpec_middleUpperAndRafter_diagonalTHolder();
    cylinderMiddlePart.algorithmSpec_middleUpperAndRafterEndpiece_normalHolder();
    cylinderMiddlePart.algorithmSpec_middleUpperAndEndpieceStud_saddleHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevelIL>this.level).backPosition.cylinderMiddlePart.middleUpperPipeSample.specs = this.specs;
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 서까래 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specsList;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
