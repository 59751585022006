import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CoverTemp } from "vhows-design/src/object/design/base/CoverTemp";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { CurSwitchWorkIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchWorkIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject
export class CurCoverPartIL extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  protected curCoverPosition: CurCoverPositionIL;
  protected coverColumn: CoverTemp; // 기둥 피복
  protected coverCeiling: CoverTemp; // 천장 피복
  protected coverFront: CoverTemp; // 앞면 피복
  protected coverBack: CoverTemp; // 뒷면 피복
  protected coverPocket: CoverTemp; // 마구리 주머니 피복
  // 외부
  protected curSwitchLevel: CurSwitchLevelIL;
  protected endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 단위값

  /** 단위 커튼 기둥 폭 */
  protected unitColumnWidth: number;
  /** 단위 커튼 기둥 길이 */
  protected unitColumnLength: number;
  /** 단위 커튼 천장 폭 */
  protected unitCeilingWidth: number;
  /** 단위 커튼 천장 길이 */
  protected unitCeilingLength: number;
  /** 단위 커튼 천장 길이 + 마구리 */
  protected unitCeilingLength_endpiece: number;
  /** 단위 커튼 마구리 폭 */
  protected unitEndpieceWidth: number;
  /** 단위 커튼 마구리 길이 */
  protected unitEndpieceLength: number;
  /** 단위 커튼 마구리 길이 + 출입문 */
  protected unitEndpieceLength_gate: number;
  /** 단위 커튼 마구리 길이 + 기둥 바람막이 */
  protected unitEndpieceLength_windbreakColumn: number;
  /** 단위 커튼 주머니 폭 */
  protected unitPocketWidth: number;
  /** 단위 커튼 주머니 길이 */
  protected unitPocketLength: number;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    super.setReferenceVariable();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      this.endpieceLevel = <EndpieceLevelIL>this.struct.workAC[CONST.INDEX_IL_ENDPIECE_WORK].levelAC[0];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      this.endpieceLevel = <EndpieceLevelVL>this.struct.workAC[CONST.INDEX_IL_ENDPIECE_WORK].levelAC[0];
    }

    this.curCoverPosition = <CurCoverPositionIL>this.position;
    this.coverColumn = new CoverTemp();
    this.coverCeiling = new CoverTemp();
    this.coverFront = new CoverTemp();
    this.coverBack = new CoverTemp();
    this.coverPocket = new CoverTemp();

    // 외부
    this.curSwitchLevel = <CurSwitchLevelIL>(
      (this.struct.workAC[CONST.INDEX_IL_CURTAIN_SWITCH_WORK] as CurSwitchWorkIL).levelAC[0]
    );
    // this.curSwitchLevel = <CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index];

    const pocketFixingPipeSelected: boolean = this.curSwitchLevel.ceilingPosition.framePart.pocketFixingPipeSelected;

    //----------------------------------
    // 수치 - 단위값
    //----------------------------------

    this.unitColumnWidth = this.basicLevel.heightShoulder + 0.3;
    this.unitColumnLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].length + 1;

    if (this.curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_IL) {
      // 연동형
      this.unitCeilingWidth = this.basicLevel.width / 2 + this.design.preference.curtainCeilingOverlapWidth;
      this.unitCeilingLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].length; // 보통 주머니 영역이 겹쳐서 여분이 필요없음
      this.unitCeilingLength_endpiece = this.basicLevel.heightShoulder + this.basicLevel.depthLaying - 1;
    } else if (this.curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
      // 벤로형
      this.unitCeilingWidth = columnPosition.columnInterval + 0.3;
      this.unitCeilingLength = this.basicLevel.width * this.basic.buildingNumber + 0.5;
      this.unitCeilingLength_endpiece = 0;
    }

    this.unitEndpieceWidth = this.basicLevel.heightShoulder + 0.3;
    this.unitEndpieceLength = this.basicLevel.width * this.basic.buildingNumber + 1;
    this.unitEndpieceLength_gate = 3.4;
    this.unitEndpieceLength_windbreakColumn = 1.5;

    this.unitPocketWidth = 1.5;
    if (this.curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_IL) {
      // 연동형
      this.unitPocketLength = this.basicLevel.width * this.basic.buildingNumber + 1;
    } else if (this.curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
      // 벤로형
      this.unitPocketLength = this.basicLevel.length;
    }

    //----------------------------------
    // 용도 및 수치 - 피복객체값
    // 피복범위, 개폐여부에 따른 값을 계산하여 피복객체에 할당함
    //----------------------------------

    if (this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_SEP_ENDPIECE) {
      this.coverColumn.purpose = "커튼 피복: 기둥(측면)";
      this.coverColumn.width = this.unitColumnWidth;
      this.coverColumn.length = this.unitColumnLength;

      this.coverCeiling.purpose = "커튼 피복: 천장";
      this.coverCeiling.width = this.unitCeilingWidth;
      this.coverCeiling.length = this.unitCeilingLength;

      this.coverFront.purpose = "커튼 피복: 앞면";
      this.coverFront.width = this.unitEndpieceWidth;
      this.coverFront.length = this.unitEndpieceLength;
      if (this.curSwitchLevel.frontPosition.selected === false) {
        this.coverFront.purpose += "+출입문";
        this.coverFront.length += this.unitEndpieceLength_gate * this.endpieceLevel.frontPosition.gateBuildingNumber;
      }
      if (this.curSwitchLevel.columnPosition.selected === true) {
        this.coverFront.purpose += "+바람막이";
        this.coverFront.length += this.unitEndpieceLength_windbreakColumn * 2;
      }

      this.coverBack.purpose = "커튼 피복: 뒷면";
      this.coverBack.width = this.unitEndpieceWidth;
      this.coverBack.length = this.unitEndpieceLength;
      if (this.curSwitchLevel.backPosition.selected === false) {
        this.coverBack.purpose += "+출입문";
        this.coverBack.length += this.unitEndpieceLength_gate * this.endpieceLevel.backPosition.gateBuildingNumber;
      }
      if (this.curSwitchLevel.columnPosition.selected === true) {
        this.coverBack.purpose += "+바람막이";
        this.coverBack.length += this.unitEndpieceLength_windbreakColumn * 2;
      }

      this.coverPocket.purpose = "커튼 피복: 주머니";
      this.coverPocket.width = this.unitPocketWidth;
      this.coverPocket.length = this.unitPocketLength;
    } else if (this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE) {
      this.coverColumn.purpose = "커튼 피복: 기둥(측면)";
      this.coverColumn.width = this.unitColumnWidth;
      this.coverColumn.length = this.unitColumnLength;

      this.coverCeiling.purpose = "커튼 피복: 천장";
      this.coverCeiling.width = this.unitCeilingWidth;
      this.coverCeiling.length = this.unitCeilingLength;

      this.coverFront.purpose = "커튼 피복: 앞면+주머니";
      this.coverFront.width = this.unitEndpieceWidth + this.unitPocketWidth;
      this.coverFront.length = this.unitEndpieceLength;
      if (this.curSwitchLevel.frontPosition.selected === false) {
        this.coverFront.purpose += "+출입문";
        this.coverFront.length += this.unitEndpieceLength_gate * this.endpieceLevel.frontPosition.gateBuildingNumber;
      }
      if (this.curSwitchLevel.columnPosition.selected === true) {
        this.coverFront.purpose += "+바람막이";
        this.coverFront.length += this.unitEndpieceLength_windbreakColumn * 2;
      }

      this.coverBack.purpose = "커튼 피복: 뒷면+주머니";
      this.coverBack.width = this.unitEndpieceWidth + this.unitPocketWidth;
      this.coverBack.length = this.unitEndpieceLength;
      if (this.curSwitchLevel.backPosition.selected === false) {
        this.coverBack.purpose += "+출입문";
        this.coverBack.length += this.unitEndpieceLength_gate * this.endpieceLevel.backPosition.gateBuildingNumber;
      }
      if (this.curSwitchLevel.columnPosition.selected === true) {
        this.coverBack.purpose += "+바람막이";
        this.coverBack.length += this.unitEndpieceLength_windbreakColumn * 2;
      }

      this.coverPocket = null;
    } else if (this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_ENDPIECE) {
      this.coverColumn.purpose = "커튼 피복: 기둥(측면)";
      this.coverColumn.width = this.unitColumnWidth;
      this.coverColumn.length = this.unitColumnLength;

      this.coverCeiling.purpose = "커튼 피복: 천장";
      this.coverCeiling.width = this.unitCeilingWidth;
      this.coverCeiling.length = this.unitCeilingLength + 1; // 1m 여분 추가

      this.coverFront.purpose = "커튼 피복: 앞면";
      this.coverFront.width = this.unitEndpieceWidth;
      this.coverFront.length = this.unitEndpieceLength;
      if (this.curSwitchLevel.frontPosition.selected === false) {
        this.coverFront.purpose += "+출입문";
        this.coverFront.length += this.unitEndpieceLength_gate * this.endpieceLevel.frontPosition.gateBuildingNumber;
      }
      if (this.curSwitchLevel.columnPosition.selected === true) {
        this.coverFront.purpose += "+바람막이";
        this.coverFront.length += this.unitEndpieceLength_windbreakColumn * 2;
      }

      this.coverBack.purpose = "커튼 피복: 뒷면";
      this.coverBack.width = this.unitEndpieceWidth;
      this.coverBack.length = this.unitEndpieceLength;
      if (this.curSwitchLevel.backPosition.selected === false) {
        this.coverBack.purpose += "+출입문";
        this.coverBack.length += this.unitEndpieceLength_gate * this.endpieceLevel.backPosition.gateBuildingNumber;
      }
      if (this.curSwitchLevel.columnPosition.selected === true) {
        this.coverBack.purpose += "+바람막이";
        this.coverBack.length += this.unitEndpieceLength_windbreakColumn * 2;
      }

      this.coverPocket = null;
    } else if (this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_AND_ENDPIECE) {
      this.coverColumn.purpose = "커튼 피복: 기둥(측면)";
      this.coverColumn.width = this.unitColumnWidth;
      this.coverColumn.length = this.unitColumnLength;

      this.coverCeiling.purpose = "커튼 피복: 천장+마구리";
      this.coverCeiling.width = this.unitCeilingWidth;
      this.coverCeiling.length = this.unitCeilingLength + this.unitCeilingLength_endpiece * 2;

      this.coverFront = null;

      this.coverBack = null;

      this.coverPocket = null;
    } else if (this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_CEILING_AND_ENDPIECE) {
      this.coverColumn = null;

      this.coverCeiling.purpose = "커튼 피복: 천장+마구리";
      this.coverCeiling.width = this.unitCeilingWidth;
      this.coverCeiling.length = this.unitCeilingLength + this.unitCeilingLength_endpiece * 2;

      this.coverFront = null;

      this.coverBack = null;

      this.coverPocket = null;
    } else if (this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_CEILING_SEP_POCKET) {
      this.coverColumn = null;

      this.coverCeiling.purpose = "커튼 피복: 천장";
      this.coverCeiling.width = this.unitCeilingWidth;
      this.coverCeiling.length = this.unitCeilingLength;

      this.coverFront = null;

      this.coverBack = null;

      this.coverPocket.purpose = "커튼 피복: 주머니";
      this.coverPocket.width = this.unitPocketWidth;
      this.coverPocket.length = this.unitPocketLength;
    } else if (this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_CEILING) {
      this.coverColumn = null;

      this.coverCeiling.purpose = "커튼 피복: 천장";
      this.coverCeiling.width = this.unitCeilingWidth;
      this.coverCeiling.length = this.unitCeilingLength;

      this.coverFront = null;

      this.coverBack = null;

      this.coverPocket = null;
    }

    //----------------------------------
    // 규격 - 끈형태
    //----------------------------------

    if (this.coverColumn != null) {
      if (this.curSwitchLevel.columnPosition.selected) {
        if (this.curSwitchLevel.columnPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
          if (
            this.curSwitchLevel.columnPosition.axisPart.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP
          ) {
            this.coverColumn.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
          } else if (
            this.curSwitchLevel.columnPosition.axisPart.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_STRING_CLIP
          ) {
            this.coverColumn.specStringType = CONST.ITEM_SPEC_VALUE_ONE_STRING;
          }
        } else if (this.curSwitchLevel.columnPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP) {
          this.coverColumn.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
        }
      } else {
        this.coverColumn.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
      }
    }
    if (this.coverCeiling != null) {
      this.coverCeiling.specStringType = CONST.ITEM_SPEC_VALUE_ONE_STRING;
    }
    if (this.coverFront != null) {
      if (this.curSwitchLevel.frontPosition.selected) {
        if (this.curSwitchLevel.frontPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
          if (this.curSwitchLevel.frontPosition.axisPart.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP) {
            this.coverFront.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
          } else if (
            this.curSwitchLevel.frontPosition.axisPart.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_STRING_CLIP
          ) {
            this.coverFront.specStringType = CONST.ITEM_SPEC_VALUE_ONE_STRING;
          }
        } else if (this.curSwitchLevel.frontPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP) {
          this.coverFront.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
        }
      } else {
        if (
          this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE &&
          !pocketFixingPipeSelected
        ) {
          this.coverFront.specStringType = CONST.ITEM_SPEC_VALUE_ONE_STRING;
        } else {
          this.coverFront.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
        }
      }
    }
    if (this.coverBack != null) {
      if (this.curSwitchLevel.backPosition.selected) {
        if (this.curSwitchLevel.backPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW) {
          if (this.curSwitchLevel.backPosition.axisPart.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP) {
            this.coverBack.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
          } else if (
            this.curSwitchLevel.backPosition.axisPart.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TYPE_STRING_CLIP
          ) {
            this.coverBack.specStringType = CONST.ITEM_SPEC_VALUE_ONE_STRING;
          }
        } else if (this.curSwitchLevel.backPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP) {
          this.coverBack.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
        }
      } else {
        if (
          this.curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE &&
          !pocketFixingPipeSelected
        ) {
          this.coverBack.specStringType = CONST.ITEM_SPEC_VALUE_ONE_STRING;
        } else {
          this.coverBack.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
        }
      }
    }
    if (this.coverPocket != null) {
      if (pocketFixingPipeSelected) {
        this.coverPocket.specStringType = CONST.ITEM_SPEC_VALUE_STRING_NONE;
      } else {
        this.coverPocket.specStringType = CONST.ITEM_SPEC_VALUE_ONE_STRING;
      }
    }
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
