import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemBracket } from "vhows-design/src/object/design/item/list/ItemBracket";
import { ItemSupportPipe } from "vhows-design/src/object/design/item/list/ItemSupportPipe";
import { ItemRailInterval } from "vhows-design/src/object/design/item/list/ItemRailInterval";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { HydSystemPosition } from "vhows-design/src/object/design/hydroponics/system/HydSystemPosition";
import { HydCultivationPosition } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPosition";
import { HydCultivationSample_GroundRailPipe } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_GroundRailPipe";
import { HydCultivationSample_UBendingTubeRail } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_UBendingTubeRail";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject({
  knownTypes: [HydCultivationSample_GroundRailPipe, HydCultivationSample_UBendingTubeRail],
})
export class HydCultivationPart_GroundRail extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public groundRailPipeSample: HydCultivationSample_GroundRailPipe; // 지면 레일 파이프 샘플

  // 아이템
  public groundRail_pipe: ItemPipe;
  public nomalRail_connectionPin: ItemConnector;
  public groundRail_railSupportPipe: ItemRailInterval;
  public tubeRail_uBendingTubeRail: ItemSupportPipe;
  public tubeRail_bracket: ItemBracket;
  public groundRail_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _totalRailLineNumber: number = 0; // 총 레일 줄수
  @jsonMember(Number)
  public _railLineNumber: number = 0; // 레일 줄수
  @jsonMember(Number)
  public _railLength: number = 0; // 레일 길이
  @jsonMember(Number)
  public _railSupportRollerInterval: number = 0; // 레일 받침대 롤러 간격
  @jsonMember(Number)
  public _railSupportInterval: number = 0; // 레일 받침대 설치 간격

  /**
   * 총 레일 줄수
   */
  public get totalRailLineNumber(): number {
    return this._totalRailLineNumber;
  }

  //
  public set totalRailLineNumber(value: number) {
    this._totalRailLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 레일 줄수
   */
  public get railLineNumber(): number {
    return this._railLineNumber;
  }

  //
  public set railLineNumber(value: number) {
    this._railLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 레일 길이
   */
  public get railLength(): number {
    return this._railLength;
  }

  //
  public set railLength(value: number) {
    this._railLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 레일 받침대 롤러 간격
   */
  public get railSupportRollerInterval(): number {
    return this._railSupportRollerInterval;
  }

  //
  public set railSupportRollerInterval(value: number) {
    this._railSupportRollerInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmSpec_groundRail_railSupportPipe();
    this.algorithmPart();
  }

  /**
   * 레일 받침대 설치 간격
   */
  public get railSupportInterval(): number {
    return this._railSupportInterval;
  }

  //
  public set railSupportInterval(value: number) {
    this._railSupportInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.groundRailPipeSample = new HydCultivationSample_GroundRailPipe();

    this.sampleAC = [this.groundRailPipeSample];

    // 아이템
    this.groundRail_pipe = new ItemPipe();
    this.nomalRail_connectionPin = new ItemConnector();
    this.groundRail_railSupportPipe = new ItemRailInterval();
    this.tubeRail_uBendingTubeRail = new ItemSupportPipe();
    this.tubeRail_bracket = new ItemBracket();
    this.groundRail_screw = new ItemScrew();

    this.itemAC = [
      this.groundRail_pipe,
      this.nomalRail_connectionPin,
      this.groundRail_railSupportPipe,
      this.tubeRail_uBendingTubeRail,
      this.tubeRail_bracket,
      this.groundRail_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.groundRailPipeSample = <HydCultivationSample_GroundRailPipe>this.sampleAC[0];

    // 아이템
    this.groundRail_pipe = <ItemPipe>this.itemAC[0];
    this.nomalRail_connectionPin = <ItemConnector>this.itemAC[1];
    this.groundRail_railSupportPipe = <ItemRailInterval>this.itemAC[2];
    this.tubeRail_uBendingTubeRail = <ItemSupportPipe>this.itemAC[3];
    this.tubeRail_bracket = <ItemBracket>this.itemAC[4];
    this.groundRail_screw = <ItemScrew>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param railLineNumber: number 레일 줄수
   * @param railSupportInterval: number 레일 받침대 설치 간격
   * @param railSupportRollerInterval: number 레일 받침대 설치 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    railLineNumber: number = 0,
    railSupportInterval: number = 0,
    railSupportRollerInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._railLineNumber = railLineNumber;
    this._railSupportInterval = railSupportInterval;
    this._railSupportRollerInterval = railSupportRollerInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.groundRailPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지면 레일",
        "원형, 일반, 40A, 3.2T, 10m, -, ",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.groundRail_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지면 레일",
        "원형, 일반, 40A, 3.2T, 10m, -, ",
      );
      this.nomalRail_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "지면 레일",
        "원형, 일반, 25mm, 25mm",
      );
      this.groundRail_railSupportPipe.setDefaultData(
        2,
        CONST.ITEM_ID_RAIL_SUPPORT_PIPE,
        CONST.ITEM_NAME_RAIL_SUPPORT_PIPE,
        "지면 레일",
        "원형, 일반, 40A, 55cm간격",
      );
      this.tubeRail_uBendingTubeRail.setDefaultData(
        3,
        CONST.ITEM_ID_U_BENDING_TUBE_RAIL,
        CONST.ITEM_NAME_U_BENDING_TUBE_RAIL,
        "지면 레일",
        "원형, 일반, 40A",
      );
      this.tubeRail_bracket.setDefaultData(
        4,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "지면 레일",
        "일반, 3T, 40x50mm",
      );
      this.groundRail_screw.setDefaultData(
        5,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "지면 레일",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////
    const hydCultivationPosition: HydCultivationPosition = this.position as HydCultivationPosition;
    const hydSystemPosition: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;

    /// //////// 선택, 가시성 ///////////

    this.groundRail_pipe.selected = true;
    this.groundRail_pipe.visible = true;
    if (hydCultivationPosition.groundRailType === CONST.LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_NORMAL) {
      this.nomalRail_connectionPin.selected = true;
      this.nomalRail_connectionPin.visible = true;
      this.groundRail_railSupportPipe.selected = true;
      this.groundRail_railSupportPipe.visible = true;
      this.groundRail_screw.selected = true;
      this.groundRail_screw.visible = true;
      this.tubeRail_uBendingTubeRail.selected = false;
      this.tubeRail_uBendingTubeRail.visible = false;
      this.tubeRail_bracket.selected = false;
      this.tubeRail_bracket.visible = false;
    } else if (hydCultivationPosition.groundRailType === CONST.LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_TUBE) {
      this.tubeRail_uBendingTubeRail.selected = true;
      this.tubeRail_uBendingTubeRail.visible = true;
      this.groundRail_railSupportPipe.selected = true;
      this.groundRail_railSupportPipe.visible = true;
      this.tubeRail_bracket.selected = true;
      this.tubeRail_bracket.visible = true;
      this.groundRail_screw.selected = true;
      this.groundRail_screw.visible = true;
      this.nomalRail_connectionPin.selected = false;
      this.nomalRail_connectionPin.visible = false;
    } else {
      this.groundRail_pipe.selected = false;
      this.groundRail_pipe.visible = false;
      this.nomalRail_connectionPin.selected = false;
      this.nomalRail_connectionPin.visible = false;
      this.groundRail_railSupportPipe.selected = false;
      this.groundRail_railSupportPipe.visible = false;
      this.tubeRail_uBendingTubeRail.selected = false;
      this.tubeRail_uBendingTubeRail.visible = false;
      this.tubeRail_bracket.selected = false;
      this.tubeRail_bracket.visible = false;
      this.groundRail_screw.selected = false;
      this.groundRail_screw.visible = false;
    }

    /// ////////  수량 ///////////
    this.groundRail_pipe.designQuantity =
      this.groundRail_pipe.getConnectedPipeLength(this.railLength, this.totalRailLineNumber * 2) /
      this.groundRail_pipe.specLength;

    this.nomalRail_connectionPin.designQuantity =
      this.groundRail_pipe.designQuantity * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;

    this.groundRail_railSupportPipe.designQuantity =
      this.railLength / this.railSupportInterval / this.totalRailLineNumber;

    this.tubeRail_uBendingTubeRail.designQuantity = this.totalRailLineNumber;

    this.tubeRail_bracket.designQuantity = this.tubeRail_uBendingTubeRail.designQuantity * 2;

    this.groundRail_screw.designQuantity =
      ((this.nomalRail_connectionPin.getSelectedQuantity() * 4 +
        this.groundRail_railSupportPipe.getSelectedQuantity() * 2) /
        this.groundRail_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 총 레일 줄수 <- 총 베드 줄수
   */
  public algorithm_totalRailLineNumber(): void {
    const cultivationPositioin: HydCultivationPosition = <HydCultivationPosition>this.position;

    this.totalRailLineNumber = cultivationPositioin.bedPart.totalBedLineNumber;
  }

  /**
   * 알고리즘: 레일 줄수 <- 동당 베드 줄수
   */
  public algorithm_railLineNumber(): void {
    const cultivationPositioin: HydCultivationPosition = <HydCultivationPosition>this.position;
    this.railLineNumber = cultivationPositioin.bedPart.bedLineNumber;
  }

  /**
   * 알고리즘: 레일 길이 <- 재배부 길이
   */
  public algorithm_railLength(): void {
    const hydSystemPosition: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;
    this.railLength = hydSystemPosition.cultivationLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [지면레일] 파이프 <- 지면 레일 파이프 샘플
   */
  public algorithmSpec_groundRail_pipe(): void {
    this.groundRail_pipe.specs = this.groundRailPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [지면레일] 연결핀 <- 지면 레일 파이프 샘플
   */
  public algorithmSpec_nomalRail_connectionPin(): void {
    this.nomalRail_connectionPin.specPipeType = this.groundRailPipeSample.specPipeType;
    this.nomalRail_connectionPin.specCrossSize1 = this.groundRailPipeSample.specCrossSize;
    this.nomalRail_connectionPin.specCrossSize2 = this.groundRailPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [지면레일] 레일 받침대 <- 지면 레일 파이프 샘플
   */
  public algorithmSpec_groundRail_railSupportPipe(): void {
    this.groundRail_railSupportPipe.specPipeType = this.groundRailPipeSample.specPipeType;
    this.groundRail_railSupportPipe.specInterval = this.railSupportRollerInterval;
  }

  /**
   * 규격 알고리즘: [지면레일] U벤딩 튜브레일 <- 지면 레일 파이프 샘플
   */
  public algorithmSpec_tubeRail_uBendingTubeRail(): void {
    this.tubeRail_uBendingTubeRail.specPipeType = this.groundRailPipeSample.specPipeType;
    this.tubeRail_uBendingTubeRail.specMaterial = this.groundRailPipeSample.specMaterial;
    this.tubeRail_uBendingTubeRail.specPipeCrossSize = this.groundRailPipeSample.specPipeCrossSize;
  }

  /**
   * 규격 알고리즘: [지면레일] 브라켓트
   */
  public algorithmSpec_tubeRail_bracket(): void {}

  /**
   * 규격 알고리즘: [지면레일] 나사
   */
  public algorithmSpec_groundRail_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
