import { jsonObject } from "typedjson";

import { ItemHingedDoor } from "vhows-design/src/object/design/item/list/ItemHingedDoor";
import { PartitionPart_Gate } from "vhows-design/src/object/design/other/partition/PartitionPart_Gate";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-12-19
 */
@jsonObject
export class PartitionSample_HingedDoor extends ItemHingedDoor {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const gatePart: PartitionPart_Gate = <PartitionPart_Gate>this.part;
    gatePart.algorithm_doorQuantity();
    gatePart.algorithm_gateWidth();
    gatePart.algorithm_gateHeight();
    gatePart.algorithmSpec_gate_hingedDoor();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 여닫이문 샘플(마구리)
   */
  // @override
  public algorithmSpec(): void {
    this.specs = this.struct.endpieceWork.level1.frontPosition.gatePart.hingedDoorSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
