import { useState, useEffect, useRef } from "react";

type alertProps = {
  timeout?: number;
  content: string;
  isVisible: boolean;
  icon: any;
  classNames?: string;
  callback: (e: boolean) => void;
};

export const Toast = ({ isVisible, timeout = 2500, content, icon, classNames, callback }: alertProps) => {
  const [isLeaving, setIsLeaving] = useState(false);
  const timeoutId = useRef<number | null>(null);
  const visibleTimeOutId = useRef<number | null>(null);

  useEffect(() => {
    function setTimer() {
      timeoutId.current = window.setTimeout(() => {
        setIsLeaving(true);
      }, timeout);

      visibleTimeOutId.current = window.setTimeout(() => {
        callback(false);
      }, timeout + 300);
    }
    setTimer();
    return () => {
      clearTimer();
    };
  }, [isVisible, timeoutId, timeout, callback]);

  const clearTimer = () => {
    if (!(timeoutId.current && visibleTimeOutId.current)) return;

    window.clearTimeout(timeoutId.current);
    window.clearTimeout(visibleTimeOutId.current);

    setIsLeaving(false);
  };

  return (
    <div className="fixed left-1/2  -translate-x-1/2 bottom-[90px] z-[999] w-full max-w-xl px-4">
      {isVisible ? (
        <div
          className={`animate-enter relative w-full flex items-center rounded-[10px] bg-[#1F2024] px-4 py-3 text-white ${
            isLeaving ? "animate-leave" : ""
          } ${classNames}`}
          role="alert"
        >
          {icon}
          <span className="inline">{content}</span>
        </div>
      ) : null}
    </div>
  );
};
