import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPlasticGutter } from "vhows-design/src/object/design/item/list/ItemPlasticGutter";
import { ItemIronPlateGutter } from "vhows-design/src/object/design/item/list/ItemIronPlateGutter";
import { ItemGutterHanger } from "vhows-design/src/object/design/item/list/ItemGutterHanger";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { GutterSample_CondensationPlasticGutter } from "vhows-design/src/object/design/frame/gutter/GutterSample_CondensationPlasticGutter";
import { GutterSample_ConWorkedIronPlateGutter } from "vhows-design/src/object/design/frame/gutter/GutterSample_ConWorkedIronPlateGutter";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-14
 */
@jsonObject({
  knownTypes: [GutterSample_CondensationPlasticGutter, GutterSample_ConWorkedIronPlateGutter],
})
export class GutterPart_CondensationGutter extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public condensationPlasticGutterSample: GutterSample_CondensationPlasticGutter; // 결로 플라스틱 물받이 샘플
  public conWorkedIronPlateGutterSample: GutterSample_ConWorkedIronPlateGutter; // 결로 철판 물받이 샘플

  // 아이템
  public condensationGutter_plasticGutter: ItemPlasticGutter; // 동적 생성
  public condensationGutter_ironPlateGutter: ItemIronPlateGutter; // 동적 생성
  public condensationGutter_gutterHanger: ItemGutterHanger;
  public condensationGutter_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 길이 AC */
  public specLengthAC: number[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _condensationGutterType: string; // 결로받이 형태

  /**
   * 결로받이 형태
   */
  public get condensationGutterType(): string {
    return this._condensationGutterType;
  }

  //
  public set condensationGutterType(value: string) {
    this._condensationGutterType = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.condensationPlasticGutterSample = new GutterSample_CondensationPlasticGutter();
    this.conWorkedIronPlateGutterSample = new GutterSample_ConWorkedIronPlateGutter();

    this.sampleAC = [this.condensationPlasticGutterSample, this.conWorkedIronPlateGutterSample];

    // 아이템
    // 결로 플라스틱 물받이 동적 생성
    // 결로 철판 물받이 동적 생성
    this.condensationGutter_gutterHanger = new ItemGutterHanger();
    this.condensationGutter_screw = new ItemScrew();

    this.itemAC = [this.condensationGutter_gutterHanger, this.condensationGutter_screw];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.condensationPlasticGutterSample = <GutterSample_CondensationPlasticGutter>this.sampleAC[0];
    this.conWorkedIronPlateGutterSample = <GutterSample_ConWorkedIronPlateGutter>this.sampleAC[1];

    // 아이템
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemGutterHanger) {
        this.condensationGutter_gutterHanger = <ItemGutterHanger>item;
      } else if (item instanceof ItemScrew) {
        this.condensationGutter_screw = <ItemScrew>item;
      }
    }

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param condensationGutterType: string 결로받이 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    condensationGutterType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._condensationGutterType = condensationGutterType;

    // 샘플
    if (this.level.index >= 0) {
      this.condensationPlasticGutterSample.setDefaultData(
        0,
        CONST.ITEM_ID_PLASTIC_GUTTER,
        CONST.ITEM_NAME_PLASTIC_GUTTER,
        "결로받이",
        "일반, 0.8T, 25cm, 49m, 타공 없음",
      );
      this.conWorkedIronPlateGutterSample.setDefaultData(
        1,
        CONST.ITEM_ID_IRON_PLATE_GUTTER,
        CONST.ITEM_NAME_IRON_PLATE_GUTTER,
        "결로받이",
        "결로가공, 0.5T, 15cm, 49m",
      );
    }

    // 아이템
    // 결로 플라스틱 물받이 동적 생성
    // 결로 철판 물받이 동적 생성
    if (this.level.index >= 0) {
      this.condensationGutter_gutterHanger.setDefaultData(
        1,
        CONST.ITEM_ID_GUTTER_HANGER,
        CONST.ITEM_NAME_GUTTER_HANGER,
        "결로받이",
        "원형, 일반, 32mm, 25cm",
      );
      this.condensationGutter_screw.setDefaultData(
        2,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "결로받이",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;
    // 기타
    let i: number;
    const length: number = this.basic.buildingNumber;
    let item: Item;
    let gutterLength: number;

    /// //////// 생성, 선택, 가시성, 수량 ///////////

    if (this.basic.isVariableLength === true) {
      this.specLengthAC = new Array();
      let basicBuilding: BasicBuilding;
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.specLengthAC.push(basicBuilding.length);
      }
    }

    if (this.condensationGutterType === CONST.LB_GUTTER_CONDENSATION_TYPE_PLASTIC) {
      // 결로 플라스틱 물받이
      // 2동이상 && 가변길이인 경우
      if (this.basic.buildingNumber >= 2 && this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < length; i++) {
          this.condensationGutter_plasticGutter = ItemPlasticGutter.getNewDesignItemBySample(
            this,
            this.condensationPlasticGutterSample,
          );
          this.condensationGutter_plasticGutter.purpose = `${i + 1}번동 ${
            this.condensationPlasticGutterSample.purpose
          }`;
          if (this.level.index === 0) {
            this.condensationGutter_plasticGutter.specLength = this.specLengthAC[i] + 1;

            if (i === 0 || i === length - 1) {
              this.condensationGutter_plasticGutter.designQuantity = 1;
            } else {
              this.condensationGutter_plasticGutter.designQuantity = 2;
            }
          } else {
            this.condensationGutter_plasticGutter.specLength = this.specLengthAC[i] + 1;
            this.condensationGutter_plasticGutter.designQuantity = 2;
          }
          this.itemAC.push(this.condensationGutter_plasticGutter);
        }
      } else {
        // 동수만큼 수량 입력
        this.condensationGutter_plasticGutter = ItemPlasticGutter.getNewDesignItemBySample(
          this,
          this.condensationPlasticGutterSample,
        );
        if (this.level.index === 0) {
          this.condensationGutter_plasticGutter.specLength = this.basicLevel.length + 1;
          this.condensationGutter_plasticGutter.designQuantity = 2 * (this.basic.buildingNumber - 1);
        } else {
          this.condensationGutter_plasticGutter.specLength = this.basicLevel.length + 1;
          this.condensationGutter_plasticGutter.designQuantity = 2 * this.basic.buildingNumber;
        }
        this.itemAC.push(this.condensationGutter_plasticGutter);
      }
    } else if (this.condensationGutterType === CONST.LB_GUTTER_CONDENSATION_TYPE_CON_WORKED_IRON_PLATE) {
      // 결로 철판 물받이
      // 2동이상 && 가변길이인 경우
      if (this.basic.buildingNumber >= 2 && this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < length; i++) {
          this.condensationGutter_ironPlateGutter = ItemIronPlateGutter.getNewDesignItemBySample(
            this,
            this.conWorkedIronPlateGutterSample,
          );
          this.condensationGutter_ironPlateGutter.purpose = `${i + 1}번동 ${
            this.conWorkedIronPlateGutterSample.purpose
          }`;
          if (this.level.index === 0) {
            this.condensationGutter_ironPlateGutter.specLength = this.specLengthAC[i] + 1;

            if (i === 0 || i === length - 1) {
              this.condensationGutter_ironPlateGutter.designQuantity = 1;
            } else {
              this.condensationGutter_ironPlateGutter.designQuantity = 2;
            }
          } else {
            this.condensationGutter_ironPlateGutter.specLength = this.specLengthAC[i] + 1;
            this.condensationGutter_ironPlateGutter.designQuantity = 2;
          }
          this.itemAC.push(this.condensationGutter_ironPlateGutter);
        }
      } else {
        // 동수만큼 수량 입력
        this.condensationGutter_ironPlateGutter = ItemIronPlateGutter.getNewDesignItemBySample(
          this,
          this.conWorkedIronPlateGutterSample,
        );
        if (this.level.index === 0) {
          this.condensationGutter_ironPlateGutter.specLength = this.basicLevel.length + 1;
          this.condensationGutter_ironPlateGutter.designQuantity = 2 * (this.basic.buildingNumber - 1);
        } else {
          this.condensationGutter_ironPlateGutter.specLength = this.basicLevel.length + 1;
          this.condensationGutter_ironPlateGutter.designQuantity = 2 * this.basic.buildingNumber;
        }
        this.itemAC.push(this.condensationGutter_ironPlateGutter);
      }
    }

    // 정적 품목
    if (this.basic.buildingNumber > 0) {
      if (this.condensationGutterType === CONST.LB_GUTTER_CONDENSATION_TYPE_PLASTIC) {
        this.condensationGutter_gutterHanger.selected = true;
        this.condensationGutter_gutterHanger.visible = true;
        this.condensationGutter_screw.selected = false;
        this.condensationGutter_screw.visible = false;
      } else if (this.condensationGutterType === CONST.LB_GUTTER_CONDENSATION_TYPE_CON_WORKED_IRON_PLATE) {
        this.condensationGutter_gutterHanger.selected = false;
        this.condensationGutter_gutterHanger.visible = false;
        this.condensationGutter_screw.selected = true;
        this.condensationGutter_screw.visible = true;
      }
    } else {
      this.condensationGutter_gutterHanger.selected = false;
      this.condensationGutter_gutterHanger.visible = false;
      this.condensationGutter_screw.selected = false;
      this.condensationGutter_screw.visible = false;
    }

    // 물받이 걸이
    gutterLength = 0;
    for (item of this.itemAC) {
      if (item instanceof ItemPlasticGutter) {
        gutterLength += (<ItemPlasticGutter>item).specLength * (<ItemPlasticGutter>item).getSelectedQuantity();
      }
    }
    this.condensationGutter_gutterHanger.designQuantity =
      (gutterLength / trunkPosition.rafterPart.rafterInterval) * CONST.NUM_EXTRA_RATE_GUTTER_HANGER;
    this.itemAC.push(this.condensationGutter_gutterHanger);

    // 나사
    gutterLength = 0;
    for (item of this.itemAC) {
      if (item instanceof ItemIronPlateGutter) {
        gutterLength += (<ItemIronPlateGutter>item).specLength * (<ItemIronPlateGutter>item).getSelectedQuantity();
      }
    }
    this.condensationGutter_screw.designQuantity =
      (((gutterLength / trunkPosition.rafterPart.rafterInterval) * 2) /
        this.condensationGutter_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
    this.itemAC.push(this.condensationGutter_screw);

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [결로받이] 플라스틱 물받이 <- 결로 플라스틱 물받이 샘플
   */
  public algorithmSpec_condensationGutter_plasticGutter(): void {
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemPlasticGutter && item.productId === CONST.ITEM_ID_PLASTIC_GUTTER) {
        (<ItemPlasticGutter>item).specsList = this.condensationPlasticGutterSample.specsList;
        (<ItemPlasticGutter>item).specWidth = this.condensationPlasticGutterSample.specWidth;
      }
    }
  }

  /**
   * 규격 알고리즘: [결로받이] 철판 물받이 <- 결로 철판 물받이 샘플
   */
  public algorithmSpec_condensationGutter_ironPlateGutter(): void {
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemIronPlateGutter && item.productId === CONST.ITEM_ID_IRON_PLATE_GUTTER) {
        (<ItemIronPlateGutter>item).specsList = this.conWorkedIronPlateGutterSample.specsList;
        (<ItemIronPlateGutter>item).specWidth = this.conWorkedIronPlateGutterSample.specWidth;
      }
    }
  }

  /**
   * 규격 알고리즘: [결로받이] 물받이 걸이 <- 결로 플라스틱 물받이 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_condensationGutter_gutterHanger(): void {
    const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;
    this.condensationGutter_gutterHanger.specPipeType = trunkPosition.rafterPart.rafterPipeSample.specPipeType;
    this.condensationGutter_gutterHanger.specCrossSize = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    this.condensationGutter_gutterHanger.specWidth = this.condensationPlasticGutterSample.specWidth;
  }

  /**
   * 규격 알고리즘: [결로받이] 나사 <- 공통 샘플
   */
  public algorithmSpec_condensationGutter_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
