import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { EndpiecePartIL_PrismStudSep } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_PrismStudSep";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class EndpieceSampleVL_BarCenterPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    // (<EndpiecePositionIL> this.position).prismMiddlePart.middleBottomPipeSample.algorithmSpec();
    // (<EndpiecePositionIL> this.position).prismMiddlePart.algorithmSpec_middleBottomAndEndpieceStudBottom_crossClamp();

    // 다른 위치
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   (<EndpieceLevelIL> this.level).backPosition.prismStudSepPart.studBottomPipeSample.specs = this.specs;
    // }

    /// //////// 외부 ///////////

    // 피복 고정
    // const fixingLevel: FixingLevelIL = <FixingLevelIL> this.struct.fixingWorkIL.levelAC[this.level.index];
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
    // } else if (this.position.label === CONST.LB_POSITION_BACK) {
    //   fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
    // }
    //
    // // 치마 피복
    // const skirtLevel: SkirtLevelIL = <SkirtLevelIL> this.struct.skirtWorkIL.levelAC[this.level.index];
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   skirtLevel.frontPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    // } else if (this.position.label === CONST.LB_POSITION_BACK) {
    //   skirtLevel.backPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    // }
    //
    // // 바람막이 피복
    // const windbreakLevel: WindbreakLevelIL = <WindbreakLevelIL> this.struct.windbreakWorkIL.levelAC[this.level.index];
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   windbreakLevel.frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    // } else if (this.position.label === CONST.LB_POSITION_BACK) {
    //   windbreakLevel.backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    // }
    //
    // // 개폐기
    // const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL> this.struct.switcherWorkIL.levelAC[this.level.index];
    // switcherLevel.columnPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    // switcherLevel.wingPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    // switcherLevel.roofPosition.supportPart.algorithmSpec_diagonal_hookHolder();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플(기둥 골조), 샛기둥 하단 길이(파트)
   */
  // @override
  public algorithmSpec(): void {
    // this.specsList = this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specsList;
    // this.specLength = (<EndpiecePartIL_PrismStudSep> this.part).studBottomLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
