import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { ProductCompanyModel } from "vhows-design/src/object/item/product/ProductCompanyModel";
import { SpecModel } from "vhows-design/src/object/item/spec/SpecModel";
import { UnitPriceModel } from "vhows-design/src/object/item/unitprice/UnitPriceModel";

/**
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject({
  knownTypes: [SpecModel, UnitPriceModel],
})
export class SpecCompanyModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 품목 모델 */
  public itemModel: ItemModel = null;
  /** 분류 모델 */
  public categoryCompanyModel: CategoryCompanyModel = null;
  /** 조직별 상품 모델 */
  public productCompanyModel: ProductCompanyModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 규격리스트 레이블 : 설계의 규격 수정에서 사용
   */
  public get label(): string {
    return this.specModel.label;
  }

  public set label(value: string) {
    this.specModel.label = value;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 규격 모델 */
  @jsonMember(SpecModel)
  public specModel: SpecModel = null;

  /** 단가 모델 배열 */
  @jsonArrayMember(UnitPriceModel)
  public unitPriceModelAC: UnitPriceModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 인덱스 */
  @jsonMember(Number)
  public index: number = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param specModel 규격 모델
   * @param productCompanyModel 조직별 상품 모델A
   * @param unitPriceModelAC 단가 모델 배열
   */
  constructor(
    specModel: SpecModel = null,
    productCompanyModel: ProductCompanyModel = null,
    unitPriceModelAC: UnitPriceModel[] = null,
  ) {
    super();

    this.specModel = specModel;
    this.productCompanyModel = productCompanyModel;
    this.unitPriceModelAC = unitPriceModelAC;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(
    itemModel: ItemModel,
    categoryCompanyModel: CategoryCompanyModel,
    productCompanyModel: ProductCompanyModel,
  ): void {
    this.itemModel = itemModel;
    this.categoryCompanyModel = categoryCompanyModel;
    this.productCompanyModel = productCompanyModel;

    // 자식 연관 (추후 상표쪽과 통합해야함)
    let unitPriceModel: UnitPriceModel;
    for (unitPriceModel of this.unitPriceModelAC) {
      unitPriceModel.setAssociation(itemModel, categoryCompanyModel, productCompanyModel);
      unitPriceModel.specCompanyModel = this;
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 자식 레퍼런스변수
    let unitPriceModel: UnitPriceModel;
    for (unitPriceModel of this.unitPriceModelAC) {
      unitPriceModel.setReferenceVariable();
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 자식 초기화
    let unitPriceModel: UnitPriceModel;
    for (unitPriceModel of this.unitPriceModelAC) {
      unitPriceModel.setDefaultVariable();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
