import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { EndpiecePartVL_Gate } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePartVL_Gate";
import { EndpiecePartVL_Endpiece } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePartVL_Endpiece";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject({
  knownTypes: [EndpiecePartVL_Endpiece, EndpiecePartVL_Gate],
})
export class EndpiecePositionVL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public endpiecePart: EndpiecePartVL_Endpiece;
  public gatePart: EndpiecePartVL_Gate;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 출입문 골조 선택
   */
  public get gateFrameSelected(): boolean {
    if (this.selected === true) {
      if (
        this.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.gateType === CONST.LB_GATE_TYPE_HINGED ||
        this.gateType === CONST.LB_GATE_TYPE_FRAMED
      ) {
        return true;
      } else if (this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME || this.gateType === CONST.LB_GATE_TYPE_NONE) {
        return false;
      }
    }
    return false;
  }

  /**
   * 출입문 피복 선택
   */
  public get gateCoverSelected(): boolean {
    if (this.selected === true) {
      if (this.gateType === CONST.LB_GATE_TYPE_SLIDING || this.gateType === CONST.LB_GATE_TYPE_HINGED) {
        return true;
      } else if (
        this.gateType === CONST.LB_GATE_TYPE_FRAMED ||
        this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME ||
        this.gateType === CONST.LB_GATE_TYPE_NONE
      ) {
        return false;
      }
    }
    return false;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _gateBuildingNumber: number; // 출입 동수
  @jsonMember(Number)
  public _switchBuildingNumber: number; // 개폐 동수
  @jsonMember(String)
  public _gateType: string; // 출입문 형태

  /**
   * 출입 동수
   */
  public get gateBuildingNumber(): number {
    return this._gateBuildingNumber;
  }

  //
  public set gateBuildingNumber(value: number) {
    if (this._gateBuildingNumber === value) return;

    this._gateBuildingNumber = value;

    // 알고리즘
    this.algorithm_gateTypeByGateBuildingNumber();
    this.algorithm_switchBuildingNumber();
    this.endpiecePart.algorithm_totalBarLineNumber();
    this.gatePart.customDoorSample.algorithmQuantity();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_gateBuildingNumberByLevel1();
    }

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();

    // 커튼 피복
    if (this.level.index === 0) {
      (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[0]).curCoverPosition.algorithmBasic();
    }
  }

  /**
   * 개폐 동수
   */
  public get switchBuildingNumber(): number {
    return this._switchBuildingNumber;
  }

  //
  public set switchBuildingNumber(value: number) {
    if (this._switchBuildingNumber === value) return;

    this._switchBuildingNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_switchBuildingNumberByLevel1();
    }

    /// //////// 외부 ///////////

    if (this.label === CONST.LB_POSITION_FRONT) {
      // 개폐기
      (<SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index]).frontPosition.algorithmBasic();

      // 피복 고정
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceColumnLineNumber();

      // 치마 피복
      (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).frontPosition.algorithmBasic();

      // 바람막이 피복
      (<WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index]).frontPosition.algorithmBasic();

      // 방충망
      if (this.level.index === 0) {
        this.struct.screennetWorkIL.level1.frontPosition.algorithmBasic();
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      // 개폐기
      (<SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index]).backPosition.algorithmBasic();

      // 피복 고정
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_endpieceColumnLineNumber();

      // 치마 피복
      (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).backPosition.algorithmBasic();

      // 바람막이 피복
      (<WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index]).backPosition.algorithmBasic();

      // 방충망
      if (this.level.index === 0) {
        this.struct.screennetWorkIL.level1.backPosition.algorithmBasic();
      }
    }
  }

  /**
   * 출입문 형태
   */
  public get gateType(): string {
    return this._gateType;
  }

  //
  public set gateType(value: string) {
    if (this._gateType === value) return;

    this._gateType = value;

    // 알고리즘
    this.algorithm_gateBuildingNumber();
    this.gatePart.algorithm_makingMethod();
    this.gatePart.algorithm_doorWidth();
    this.gatePart.algorithm_doorHeight();
    this.gatePart.algorithm_doorQuantity();
    this.gatePart.algorithm_gateWidth();
    this.gatePart.algorithm_gateHeight();
    this.gatePart.cSectionSteelSample.algorithmSpec();

    // 파트 활성화
    this.algorithm_partActivationByGateType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    if (this.label === CONST.LB_POSITION_FRONT) {
      this.algorithm_gateTypeByPosition();
      this.gatePart.algorithm_makingMethodByPosition();
      this.gatePart.algorithm_doorWidthDoorHeightByPosition();
      this.gatePart.algorithm_doorQuantityByPosition();
    }

    /// //////// 외부 ///////////

    if (this.level.index === 0) {
      // 피복 고정
      if (this.label === CONST.LB_POSITION_FRONT) {
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_endpieceGateLineNumber();
        (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).frontPosition.algorithm_status();
      } else if (this.label === CONST.LB_POSITION_BACK) {
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).backPosition.algorithm_endpieceGateLineNumber();
        (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).backPosition.algorithm_status();
      }

      // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
      (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();

      // 커튼 피복
      this.struct.curCoverWorkIL.level1.curCoverPosition.algorithmBasic();
    }
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByGateType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    if (this.level.index === 0 || this.level.index === 1) {
      if (this.label === CONST.LB_POSITION_FRONT) {
        // 개폐기
        (<SwitcherLevelIL>(
          this.struct.switcherWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_selectedAndEnabledByEndpiece();

        // 피복 고정
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_endpieceColumnLineNumber();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_endpieceMiddleLineNumber();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_endpieceGateLineNumber();
        (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).frontPosition.algorithm_status();

        // 창문
        if (this.level.index === 0) {
          this.struct.windowWorkIL.level1.frontPosition.algorithm_selectedAndEnabledByEndpiece();
        }
      } else if (this.label === CONST.LB_POSITION_BACK) {
        // 개폐기
        (<SwitcherLevelIL>(
          this.struct.switcherWorkIL.levelAC[this.level.index]
        )).backPosition.algorithm_selectedAndEnabledByEndpiece();

        // 피복 고정
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).backPosition.algorithm_endpieceColumnLineNumber();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).backPosition.algorithm_endpieceMiddleLineNumber();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).backPosition.algorithm_endpieceGateLineNumber();
        (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).backPosition.algorithm_status();

        // 창문
        if (this.level.index === 0) {
          this.struct.windowWorkIL.level1.backPosition.algorithm_selectedAndEnabledByEndpiece();
        }
      }
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }
  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 다른 중수
    if (this.level.index === 0) {
      this.algorithm_typeByLevel1();
    }

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    if (this.level.index === 0 || this.level.index === 1) {
      if (this.label === CONST.LB_POSITION_FRONT) {
        // 피복 고정
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_endpieceColumnLineNumber();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_endpieceMiddleLineNumber();
        (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).frontPosition.algorithm_status();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).frontPosition.normalClipPart.normalClipSample.algorithmSpec();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).frontPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();

        // 치마 피복
        (<SkirtLevelIL>(
          this.struct.skirtWorkIL.levelAC[this.level.index]
        )).frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
        (<SkirtLevelIL>(
          this.struct.skirtWorkIL.levelAC[this.level.index]
        )).frontPosition.fixingSkirtClipPart.algorithm_clipInterval();
        (<SkirtLevelIL>(
          this.struct.skirtWorkIL.levelAC[this.level.index]
        )).frontPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

        // 바람막이 피복
        (<WindbreakLevelIL>(
          this.struct.windbreakWorkIL.levelAC[this.level.index]
        )).frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();

        // 개폐기
        (<SwitcherLevelIL>(
          this.struct.switcherWorkIL.levelAC[this.level.index]
        )).columnPosition.supportPart.algorithmSpec_diagonal_hookHolder();
        (<SwitcherLevelIL>(
          this.struct.switcherWorkIL.levelAC[this.level.index]
        )).wingPosition.supportPart.algorithmSpec_diagonal_hookHolder();
        (<SwitcherLevelIL>(
          this.struct.switcherWorkIL.levelAC[this.level.index]
        )).roofPosition.supportPart.algorithmSpec_diagonal_hookHolder();
        (<SwitcherLevelIL>(
          this.struct.switcherWorkIL.levelAC[this.level.index]
        )).columnPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
        (<SwitcherLevelIL>(
          this.struct.switcherWorkIL.levelAC[this.level.index]
        )).wingPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
        (<SwitcherLevelIL>(
          this.struct.switcherWorkIL.levelAC[this.level.index]
        )).roofPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
      } else if (this.label === CONST.LB_POSITION_BACK) {
        // 피복 고정
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).backPosition.algorithm_endpieceColumnLineNumber();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).backPosition.algorithm_endpieceMiddleLineNumber();
        (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).backPosition.algorithm_status();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).backPosition.normalClipPart.normalClipSample.algorithmSpec();
        (<FixingLevelIL>(
          this.struct.fixingWorkIL.levelAC[this.level.index]
        )).backPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();

        // 치마 피복
        (<SkirtLevelIL>(
          this.struct.skirtWorkIL.levelAC[this.level.index]
        )).backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
        (<SkirtLevelIL>(
          this.struct.skirtWorkIL.levelAC[this.level.index]
        )).backPosition.fixingSkirtClipPart.algorithm_clipInterval();
        (<SkirtLevelIL>(
          this.struct.skirtWorkIL.levelAC[this.level.index]
        )).backPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

        // 바람막이 피복
        (<WindbreakLevelIL>(
          this.struct.windbreakWorkIL.levelAC[this.level.index]
        )).backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      }

      // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
      (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------
  /**
   * 생성자
   */
  constructor() {
    super();
    this.endpiecePart = new EndpiecePartVL_Endpiece();
    this.gatePart = new EndpiecePartVL_Gate();

    this.partAC = [this.endpiecePart, this.gatePart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.endpiecePart = <EndpiecePartVL_Endpiece>this.partAC[0];
    this.gatePart = <EndpiecePartVL_Gate>this.partAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param gateBuildingNumber: number 출입 동수
   * @param switchBuildingNumber: number 개폐 동수
   * @param gateType: string 출입문 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    gateBuildingNumber: number = 0,
    switchBuildingNumber: number = 0,
    gateType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._gateBuildingNumber = gateBuildingNumber;
    this._switchBuildingNumber = switchBuildingNumber;
    this._gateType = gateType;

    if (this.level.index >= 0) {
      this.endpiecePart.setDefaultData(
        CONST.INDEX_VL_ENDPIECE_ENDPIECE,
        true,
        true,
        true,
        CONST.LB_ENDPIECE_ENDPIECE,
        "",
        CONST.LB_BAR_TYPE_20_INTERLOCK_1,
        13,
        0,
        4,
      );
      // 마구리 출입문
      this.gatePart.setDefaultData(
        CONST.INDEX_VL_ENDPIECE_GATE,
        true,
        true,
        true,
        CONST.LB_ENDPIECE_GATE,
        "",
        CONST.LB_GATE_MAKING_METHOD_MADE,
        1300,
        2400,
        2,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_gateBuildingNumber();
    this.algorithm_switchBuildingNumber();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화
   */
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////
    this.endpiecePart.selected = true;
    this.endpiecePart.visible = true;
  }

  /**
   * 알고리즘: 파트 활성화 <- 출입문 형태
   */
  public algorithm_partActivationByGateType(): void {
    if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
      this.gatePart.selected = true;
      this.gatePart.visible = true;
    } else {
      this.gatePart.selected = false;
      this.gatePart.visible = false;
    }
  }

  /**
   * 알고리즘: 개폐 동수 <- 출입 동수, 동수(기본정보)
   */
  public algorithm_switchBuildingNumber(): void {
    this.switchBuildingNumber = this.basic.buildingNumber - this.gateBuildingNumber;
  }

  /**
   * 알고리즘: 출입 동수 <- 출입문 형태, 선택(개폐기)
   */
  public algorithm_gateBuildingNumber(): void {
    const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (
        this.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.gateType === CONST.LB_GATE_TYPE_HINGED ||
        this.gateType === CONST.LB_GATE_TYPE_FRAMED ||
        this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME
      ) {
        if (switcherLevel.frontPosition.selected) {
          this.gateBuildingNumber = 1;
        } else {
          this.gateBuildingNumber = 1;
        }
      } else if (this.gateType === CONST.LB_GATE_TYPE_NONE) {
        this.gateBuildingNumber = 0;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (
        this.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.gateType === CONST.LB_GATE_TYPE_HINGED ||
        this.gateType === CONST.LB_GATE_TYPE_FRAMED ||
        this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME
      ) {
        if (switcherLevel.backPosition.selected) {
          this.gateBuildingNumber = 1;
        } else {
          this.gateBuildingNumber = 1;
        }
      } else if (this.gateType === CONST.LB_GATE_TYPE_NONE) {
        this.gateBuildingNumber = 0;
      }
    }
  }

  /**
   * 알고리즘: 출입문 형태 <- 출입 동수
   */
  public algorithm_gateTypeByGateBuildingNumber(): void {
    if (this.gateBuildingNumber === 0) {
      if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
        this.gateType = CONST.LB_GATE_TYPE_NONE;
      }
    } else {
      if (this.gateType === CONST.LB_GATE_TYPE_NONE) {
        this.gateType = CONST.LB_GATE_TYPE_SLIDING;
      }
    }
  }

  /**
   * 알고리즘: 출입문 형태 <- 출입문 형태, 형태
   */
  public algorithm_gateTypeByPosition(): void {
    if (this.label === CONST.LB_POSITION_FRONT) {
      if (this.type === (<EndpieceLevelVL>this.level).backPosition.type) {
        (<EndpieceLevelVL>this.level).backPosition.gateType = this.gateType;
      }
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(1중)
   */
  public algorithm_typeByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.endpieceWorkVL.levelAC.length; l++) {
        if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          (<EndpiecePositionVL>this.work.levelAC[l].positionAC[this.index]).type = CONST.LB_ENDPIECE_TYPE_WHOLE;
        } else {
          (<EndpiecePositionVL>this.work.levelAC[l].positionAC[this.index]).type = this.type;
        }
      }
    }
  }

  /**
   * 알고리즘: 출입 동수 <- 출입 동수(1중)
   */
  public algorithm_gateBuildingNumberByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        const endpiecePosition: EndpiecePositionVL = <EndpiecePositionVL>this.work.levelAC[l].positionAC[this.index];
        endpiecePosition.gateBuildingNumber = this.gateBuildingNumber;
      }
    }
  }

  /**
   * 알고리즘: 개폐 동수 <- 개폐 동수(1중)
   */
  public algorithm_switchBuildingNumberByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        const endpiecePosition: EndpiecePositionVL = <EndpiecePositionVL>this.work.levelAC[l].positionAC[this.index];
        endpiecePosition.switchBuildingNumber = this.switchBuildingNumber;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
