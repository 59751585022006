import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemPipeBendingExpense } from "vhows-design/src/object/design/item/list/ItemPipeBendingExpense";
import { ItemSconce } from "vhows-design/src/object/design/item/list/ItemSconce";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { RoofSampleVL_RafterPipe } from "vhows-design/src/object/design/frame/roof/vl/RoofSampleVL_RafterPipe";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject({
  knownTypes: [RoofSampleVL_RafterPipe],
})
export class RoofPartVL_Rafter extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public rafterPipeSample: RoofSampleVL_RafterPipe; // 서까래 파이프 샘플

  // 아이템
  public rafter_pipe: ItemPipe;
  public rafter_pipeBendingExpense: ItemPipeBendingExpense;
  public rafterAndBeamCenter_firstLevelPairSconce: ItemSconce;
  public rafterAndBeamEdge_firstLevelSoleSconce: ItemSconce;
  public rafter_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 이상적인 서까래 길이
   */
  public get lengthRafterIdeal(): number {
    return this.basicLevel.lengthRafterIdeal;
  }
  //
  public set lengthRafterIdeal(value: number) {
    this.basicLevel.lengthRafterIdeal = value;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _rafterValleyQuantity: number; // 서까래 골 수량
  @jsonMember(Number)
  public _rafterInterval: number; // 서까래 간격
  @jsonMember(Number)
  public _rafterQuantity: number; // 서까래 수량
  @jsonMember(Number)
  public _rafterTotalLength: number; // 최종 서까래 길이
  @jsonMember(Boolean)
  public _rafterLengthRoundSelected: boolean; // 서까래 길이 반올림 선택

  /**
   * 서까래 골 수량
   */
  public get rafterValleyQuantity(): number {
    return this._rafterValleyQuantity;
  }

  //
  public set rafterValleyQuantity(value: number) {
    this._rafterValleyQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_rafterQuantity();
    this.algorithmPart();

    // 다른 파트

    /// //////// 외부 ///////////
    this.basicLevel.algorithm_perimeterRoofIdeal();

    // 물받이
    this.struct.gutterWorkIL.level1.gutterPosition.ironPlatePart.algorithmPart();
    this.struct.gutterWorkIL.level1.gutterPosition.plasticPart.algorithmPart();
    this.struct.gutterWorkIL.level1.gutterPosition.fixingGutterPadPart.algorithmPart();
    this.struct.gutterWorkIL.level1.gutterPosition.fixingScrewPart.algorithmPart();
    this.struct.gutterWorkIL.level1.gutterPosition.drainagePleatedPipePart.algorithm_verticalPiping();
    this.struct.gutterWorkIL.level1.gutterPosition.drainagePVCPipePart.algorithm_verticalPiping();
    this.struct.gutterWorkIL.level1.gutterPosition.condensationGutterPart.algorithmPart();

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 서까래 간격
   */
  public get rafterInterval(): number {
    return this._rafterInterval;
  }

  //
  public set rafterInterval(value: number) {
    this._rafterInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_rafterQuantity();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 방풍벽 골조
    (<WingLevelVL>(
      this.struct.wingWorkVL.levelAC[this.level.index]
    )).wingPosition.wingRafterPart.algorithm_wingRafterInterval();

    // // 물받이
    // if (this.level.index === 0) {
    //   this.struct.gutterWorkIL.level1.gutterPosition.condensationGutterPart.algorithmPart();
    // }

    // 치마
    (<SkirtLevelIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index]
    )).roofPosition.fixingSkirtClipPart.algorithm_clipInterval();

    // 개폐기
    (<SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index]).roofPosition.axisPart.algorithmPart();

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();

    // 밴드끈
    if (this.level.index === 0) {
      for (const bandstringPosition of this.struct.bandstringWorkIL.level1.positionAC as BandstringPositionIL[]) {
        bandstringPosition.algorithm_lineIntervalByRafter();
        bandstringPosition.fixingNormalPadPart.algorithmPart();
      }
    }

    // 관수 지관부
    this.struct.wateringWork.level1.wholePosition.hoseSidePart.algorithm_hangerInterval();
  }

  /**
   * 서까래 수량
   */
  public get rafterQuantity(): number {
    return this._rafterQuantity;
  }

  //
  public set rafterQuantity(value: number) {
    this._rafterQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<RoofPositionVL>this.position).straightPart.algorithmPart();

    /// //////// 외부 ///////////

    // 기둥
    if (this.level.index === 0) {
      this.struct.columnWorkVL.level1.columnPosition.prismBeamPart.algorithmPart();
    }
  }

  /**
   * 최종 서까래 길이
   */
  public get rafterTotalLength(): number {
    return this._rafterTotalLength;
  }

  //
  public set rafterTotalLength(value: number) {
    this._rafterTotalLength = CommonUtil.fixFloat(value);

    /// //////// 외부 ///////////

    // 기본정보
    this.design.basic.basicLevelAC[this.level.index].algorithm_perimeterRoof();

    // 밴드끈
    if (this.level.index === 0) {
      this.struct.bandstringWorkIL.level1.trunkPosition.bandstringPart.algorithmPart();
      this.struct.bandstringWorkIL.level1.trunkPosition.polyPart.algorithmPart();
      this.struct.bandstringWorkIL.level1.trunkPosition.bandstringPart.algorithm_bandstringLength();
      this.struct.bandstringWorkIL.level1.trunkPosition.polyPart.algorithm_bandstringLength();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 서까래 길이 반올림 선택
   */
  public get rafterLengthRoundSelected(): boolean {
    return this._rafterLengthRoundSelected;
  }
  //
  public set rafterLengthRoundSelected(value: boolean) {
    this._rafterLengthRoundSelected = value;

    // 알고리즘
    this.rafterPipeSample.algorithmSpec_specLength();
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.rafterPipeSample = new RoofSampleVL_RafterPipe();

    this.sampleAC = [this.rafterPipeSample];

    // 아이템
    this.rafter_pipe = new ItemPipe();
    this.rafter_pipeBendingExpense = new ItemPipeBendingExpense();
    this.rafterAndBeamCenter_firstLevelPairSconce = new ItemSconce();
    this.rafterAndBeamEdge_firstLevelSoleSconce = new ItemSconce();
    this.rafter_screw = new ItemScrew();

    this.itemAC = [
      this.rafter_pipe,
      this.rafter_pipeBendingExpense,
      this.rafterAndBeamCenter_firstLevelPairSconce,
      this.rafterAndBeamEdge_firstLevelSoleSconce,
      this.rafter_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.rafterPipeSample = <RoofSampleVL_RafterPipe>this.sampleAC[0];

    // 아이템
    this.rafter_pipe = <ItemPipe>this.itemAC[0];
    this.rafter_pipeBendingExpense = <ItemPipeBendingExpense>this.itemAC[1];
    this.rafterAndBeamCenter_firstLevelPairSconce = <ItemSconce>this.itemAC[2];
    this.rafterAndBeamEdge_firstLevelSoleSconce = <ItemSconce>this.itemAC[3];
    this.rafter_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param rafterValleyQuantity: number 서까래 골 수량
   * @param rafterInterval: number 서까래 간격
   * @param rafterLengthRoundSelected: boolean 서까래 길이 반올림 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    rafterValleyQuantity: number = 0,
    rafterInterval: number = 0,
    rafterLengthRoundSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._rafterValleyQuantity = rafterValleyQuantity;
    this._rafterInterval = rafterInterval;
    this._rafterLengthRoundSelected = rafterLengthRoundSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.rafterPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래",
        "원형, 일반, 31.8mm, 1.5T, 4.5m, 벤딩",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.rafter_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래",
        "원형, 일반, 31.8mm, 1.5T, 4.5m, 벤딩",
      );
      this.rafter_pipeBendingExpense.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래",
        "원형, 31.8mm",
      );
      this.rafterAndBeamCenter_firstLevelPairSconce.setDefaultData(
        2,
        CONST.ITEM_ID_FIRST_LEVEL_PAIR_SCONCE,
        CONST.ITEM_NAME_FIRST_LEVEL_PAIR_SCONCE,
        "서까래 + 기둥(중앙)",
        "각형, 일반, 125×75mm, 32mm",
      );
      this.rafterAndBeamEdge_firstLevelSoleSconce.setDefaultData(
        3,
        CONST.ITEM_ID_FIRST_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_FIRST_LEVEL_SOLE_SCONCE,
        "서까래 + 기둥(양끝)",
        "각형, 일반, 125×75mm, 32mm",
      );
      this.rafter_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "서까래",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_rafterTotalLength();
    this.algorithm_rafterQuantity();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
    const wingPosition: WingPositionVL = this.struct.wingWorkVL.level1.wingPosition;

    /// //////// 선택, 가시성 ///////////
    this.rafter_pipe.selected = true;
    this.rafter_pipe.visible = true;
    this.rafter_pipeBendingExpense.selected = true;
    this.rafter_pipeBendingExpense.visible = true;
    if (columnPosition.prismBeamPart.beamType === CONST.LB_COLUMN_BEAM_TYPE_GUTTER_BEAM) {
      this.rafterAndBeamCenter_firstLevelPairSconce.selected = false;
      this.rafterAndBeamCenter_firstLevelPairSconce.visible = true;
      this.rafterAndBeamEdge_firstLevelSoleSconce.selected = false;
      this.rafterAndBeamEdge_firstLevelSoleSconce.visible = true;
      this.rafter_screw.selected = false;
      this.rafter_screw.visible = true;
    } else {
      this.rafterAndBeamCenter_firstLevelPairSconce.selected = true;
      this.rafterAndBeamCenter_firstLevelPairSconce.visible = true;
      if (wingPosition.selected) {
        this.rafterAndBeamEdge_firstLevelSoleSconce.selected = false;
        this.rafterAndBeamEdge_firstLevelSoleSconce.visible = false;
      } else {
        this.rafterAndBeamEdge_firstLevelSoleSconce.selected = true;
        this.rafterAndBeamEdge_firstLevelSoleSconce.visible = true;
      }
      this.rafter_screw.selected = true;
      this.rafter_screw.visible = true;
    }

    /// //////// 용도 ///////////

    /// //////// 수치 ///////////

    // 한동의 서까래 수량
    const rafterQuantityPerBuilding: number = CommonUtil.fixFloat(this.rafterQuantity / this.basic.buildingNumber);
    // 양끝동수
    let edgeBuildingNumber: number = 2;
    if (this.basic.buildingNumber === 1) {
      edgeBuildingNumber = 1;
    }
    // 중앙동수
    const centerBuildingNumber: number = this.basic.buildingNumber - edgeBuildingNumber;

    /// //////// 수량 ///////////

    if (this.level.index === 0) {
      this.rafter_pipe.designQuantity = this.rafterQuantity;

      this.rafter_pipeBendingExpense.designQuantity = this.rafterQuantity;

      this.rafterAndBeamCenter_firstLevelPairSconce.designQuantity =
        rafterQuantityPerBuilding * (this.basic.buildingNumber - 1) * CONST.NUM_EXTRA_RATE_FIRST_LEVEL_PAIR_SCONCE;

      this.rafterAndBeamEdge_firstLevelSoleSconce.designQuantity =
        rafterQuantityPerBuilding * 2 * CONST.NUM_EXTRA_RATE_FIRST_LEVEL_SOLE_SCONCE;

      this.rafter_screw.designQuantity =
        ((this.rafterAndBeamCenter_firstLevelPairSconce.getSelectedQuantity() * 2 +
          this.rafterAndBeamEdge_firstLevelSoleSconce.getSelectedQuantity() * 2) /
          this.rafter_screw.specUnitQuantity) *
        CONST.NUM_EXTRA_RATE_SCREW;
    }
  }

  /**
   * 알고리즘: 서까래 수량 <- 서까래 간격, 서까래 골 수량, 길이(기본정보), 동수(기본정보)
   */
  public algorithm_rafterQuantity(): void {
    this.rafterQuantity =
      (Math.round(this.basicLevel.length / this.rafterInterval) + 1) *
      this.basic.buildingNumber *
      this.rafterValleyQuantity *
      CONST.NUM_EXTRA_RATE_RAFTER_PIPE;
  }

  /**
   * 알고리즘: 최종 서까래 길이 <- 길이(서까래 파이프 샘플)
   */
  public algorithm_rafterTotalLength(): void {
    this.rafterTotalLength = this.rafterPipeSample.specLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [서까래] 파이프 <- 서까래 파이프 샘플
   */
  public algorithmSpec_rafter_pipe(): void {
    this.rafter_pipe.specs = this.rafterPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [서까래] 파이프 벤딩비 <- 서까래 파이프 샘플
   */
  public algorithmSpec_rafter_pipeBendingExpense(): void {
    this.rafter_pipeBendingExpense.specPipeType = this.rafterPipeSample.specPipeType;
    this.rafter_pipeBendingExpense.specPipeCrossSize = this.rafterPipeSample.specPipeCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 + 기둥(중앙)] 일중 쌍꽂이 <- 서까래 파이프 샘플, 보 파이프 샘플
   */
  public algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce(): void {
    const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
    this.rafterAndBeamCenter_firstLevelPairSconce.specPipeType =
      columnPosition.prismBeamPart.beamPipeSample.specPipeType;
    this.rafterAndBeamCenter_firstLevelPairSconce.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
    this.rafterAndBeamCenter_firstLevelPairSconce.specCrossSize1 =
      columnPosition.prismBeamPart.beamPipeSample.specCrossSize;
    this.rafterAndBeamCenter_firstLevelPairSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 + 기둥(양끝)] 일중 외꽂이 <- 서까래 파이프 샘플, 보 파이프 샘플
   */
  public algorithmSpec_rafterAndBeamEdge_firstLevelSoleSconce(): void {
    const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
    this.rafterAndBeamEdge_firstLevelSoleSconce.specPipeType = columnPosition.prismBeamPart.beamPipeSample.specPipeType;
    this.rafterAndBeamEdge_firstLevelSoleSconce.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
    this.rafterAndBeamEdge_firstLevelSoleSconce.specCrossSize1 =
      columnPosition.prismBeamPart.beamPipeSample.specCrossSize;
    this.rafterAndBeamEdge_firstLevelSoleSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래] 나사 <- 공통 샘플
   */
  public algorithmSpec_rafter_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
