import { ArrowLeftIcon } from "ui";
import Image from "next/image";
import { useRouter } from "next/router";
import { ROUTE } from "constants/route";
import { useSetAtom } from "jotai";
import { cancelModalAtom } from "store/global";
import { HomeLineRegular, TextHeadline, CloseLineRegular } from "@greenlabs/formula-components";

type Header = {
  title?: string;
  callBacks: Function;
};

const HeaderV2 = ({ title, callBacks }: Header) => {
  const router = useRouter();
  const setIsVisible = useSetAtom(cancelModalAtom);
  const inVisibleRoutePath = [ROUTE.INDEX, ROUTE.LIST, ROUTE.ACCESS_DENIED];
  const homeIconVisible = [ROUTE.INDEX, ROUTE.LIST];

  const pathTitles = [
    { path: /^[/]$/g, text: "하우스 견적 신청하기" },
    { path: /^[/]list/g, text: "상담 신청 내역" },
    { path: /^[/]detail/g, text: "내역 상세" },
    { path: /^[/]design[/]update/g, text: "설계 정보 수정" },
    { path: /^[/]result/g, text: "시설 설계 결과" },
  ];

  const headerText = pathTitles.find(v => router.pathname.match(v.path)?.length)?.text;

  return (
    <>
      <div className="relative">
        <div
          className={`fixed z-20 m-auto h-14 w-full max-w-2xl bg-white ${
            router.pathname === ROUTE.APPLICATION && "bg-[#F4F8FA]"
          }`}
        >
          <a
            className="absolute h-14 w-14 cursor-pointer p-5 text-center"
            id={"backButton"}
            onClick={(e: any) => {
              callBacks(e);
            }}
          >
            <ArrowLeftIcon fill={"#1F2024"} />
          </a>
          <TextHeadline className="absolute left-1/2 py-4  -translate-x-1/2">{headerText}</TextHeadline>
          {inVisibleRoutePath.indexOf(router.pathname) === -1 && !router.pathname.match(/^[/]detail/g)?.length && (
            <a
              className="absolute right-0 h-14 cursor-pointer w-14 flex justify-center items-center leading-none"
              onClick={() => setIsVisible(true)}
            >
              <CloseLineRegular />
            </a>
          )}

          {homeIconVisible.indexOf(router.pathname) !== -1 && (
            <a
              className="absolute right-0 h-14 w-14 cursor-pointer flex justify-center items-center leading-none"
              onClick={() => router.push(ROUTE.INDEX)}
            >
              <HomeLineRegular />
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderV2;
