import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWateringConnect } from "vhows-design/src/object/design/item/list/ItemWateringConnect";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringValve } from "vhows-design/src/object/design/item/list/ItemWateringValve";
import { Part } from "vhows-design/src/object/design/base/Part";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { MainpipeSample_Main2CenterPePipe } from "vhows-design/src/object/design/watering/mainpipe/MainpipeSample_Main2CenterPePipe";
import { MainpipeSample_Main2EachPePipe } from "vhows-design/src/object/design/watering/mainpipe/MainpipeSample_Main2EachPePipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [MainpipeSample_Main2CenterPePipe, MainpipeSample_Main2EachPePipe],
})
export class MainpipePart_PeMain2 extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public main2CenterPePipeSample: MainpipeSample_Main2CenterPePipe; // 중앙 주관2 샘플
  public main2EachPePipeSample: MainpipeSample_Main2EachPePipe; // 개별 주관2 샘플

  // 아이템
  public main2Center_pePipe: ItemWateringPipe;
  public main2Each_pePipe: ItemWateringPipe;
  public main2_exactT: ItemWateringConnect;
  public main2_unionValve: ItemWateringValve;
  public main2_elbow: ItemWateringConnect;
  public main2_pePipeEnd: ItemWateringConnect;
  public main2_valveSocket: ItemWateringConnect;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _main2LineNumber: number; // 주관2 줄수
  @jsonMember(String)
  public _main2Position: string; // 주관2 위치
  @jsonMember(Number)
  public _main2Length: number = 0; // 주관2 길이

  /**
   * 주관2 줄수
   */
  public get main2LineNumber(): number {
    return this._main2LineNumber;
  }

  //
  public set main2LineNumber(value: number) {
    this._main2LineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_main2Length();
    this.algorithmPart();
  }

  /**
   * 주관2 위치 (첫번째줄)
   */
  public get main2Position(): string {
    return this._main2Position;
  }

  //
  public set main2Position(value: string) {
    this._main2Position = value;

    // 알고리즘
    this.algorithm_main2Length();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 관수 지관부
    this.struct.wateringWork.level1.wholePosition.miniklerScreenPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.miniklerFountainPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.miniklerFogPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.hoseConnectPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.dripConnectPart.algorithm_subPipeLength();
  }

  /**
   * 주관2 길이
   */
  public get main2Length(): number {
    return this._main2Length;
  }

  //
  public set main2Length(value: number) {
    this._main2Length = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.main2CenterPePipeSample = new MainpipeSample_Main2CenterPePipe();
    this.main2EachPePipeSample = new MainpipeSample_Main2EachPePipe();

    this.sampleAC = [this.main2CenterPePipeSample, this.main2EachPePipeSample];

    // 아이템
    this.main2Center_pePipe = new ItemWateringPipe();
    this.main2Each_pePipe = new ItemWateringPipe();
    this.main2_exactT = new ItemWateringConnect();
    this.main2_unionValve = new ItemWateringValve();
    this.main2_elbow = new ItemWateringConnect();
    this.main2_pePipeEnd = new ItemWateringConnect();
    this.main2_valveSocket = new ItemWateringConnect();

    this.itemAC = [
      this.main2Center_pePipe,
      this.main2Each_pePipe,
      this.main2_exactT,
      this.main2_unionValve,
      this.main2_elbow,
      this.main2_pePipeEnd,
      this.main2_valveSocket,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.main2CenterPePipeSample = <MainpipeSample_Main2CenterPePipe>this.sampleAC[0];
    this.main2EachPePipeSample = <MainpipeSample_Main2EachPePipe>this.sampleAC[1];
    // 아이템
    this.main2Center_pePipe = <ItemWateringPipe>this.itemAC[0];
    this.main2Each_pePipe = <ItemWateringPipe>this.itemAC[1];
    this.main2_exactT = <ItemWateringConnect>this.itemAC[2];
    this.main2_unionValve = <ItemWateringValve>this.itemAC[3];
    this.main2_elbow = <ItemWateringConnect>this.itemAC[4];
    this.main2_pePipeEnd = <ItemWateringConnect>this.itemAC[5];
    this.main2_valveSocket = <ItemWateringConnect>this.itemAC[6];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param main2LineNumber: number 주관2 줄수
   * @param main2Position: string 주관2 위치
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    main2LineNumber: number = 0,
    main2Position: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._main2LineNumber = main2LineNumber;
    this._main2Position = main2Position;

    // 샘플
    if (this.level.index >= 0) {
      this.main2CenterPePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "중앙 주관(여과기~끝)",
        "수도관(단층관), 40mm, 50m",
      );
      this.main2EachPePipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "개별 주관(여과기~끝)",
        "연질관, 30mm, 100m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.main2Center_pePipe.setDefaultData(
        0,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "중앙 주관(여과기~끝)",
        "수도관(단층관), 40mm, 50m",
      );
      this.main2Each_pePipe.setDefaultData(
        1,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "개별 주관(여과기~끝)",
        "연질관, 30mm, 100m",
      );
      this.main2_exactT.setDefaultData(
        2,
        CONST.ITEM_ID_EXACT_T,
        CONST.ITEM_NAME_EXACT_T,
        "주관(여과기~끝)",
        "PE, 소켓, 40mm, 40mm",
      );
      this.main2_unionValve.setDefaultData(
        3,
        CONST.ITEM_ID_UNION_VALVE,
        CONST.ITEM_NAME_UNION_VALVE,
        "주관(여과기~끝)",
        "PE, 일반, 40mm, 40mm",
      );
      this.main2_elbow.setDefaultData(
        4,
        CONST.ITEM_ID_ELBOW,
        CONST.ITEM_NAME_ELBOW,
        "주관(여과기~끝)",
        "PE, 소켓, 40mm, 40mm",
      );
      this.main2_pePipeEnd.setDefaultData(
        5,
        CONST.ITEM_ID_PE_PIPE_END,
        CONST.ITEM_NAME_PE_PIPE_END,
        "주관(여과기~끝)",
        "PE, 앤드캡, 40mm, -",
      );
      this.main2_valveSocket.setDefaultData(
        6,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "주관(여과기~끝)",
        "PE, 일반, 40mm, -",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_main2Length();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const mainpipePosition: MainpipePosition = <MainpipePosition>this.position;
    const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;

    /// //////// 선택, 가시성 ///////////

    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.main2Center_pePipe.selected = true;
      this.main2Center_pePipe.visible = true;
      this.main2Each_pePipe.selected = false;
      this.main2Each_pePipe.visible = false;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.main2Center_pePipe.selected = false;
      this.main2Center_pePipe.visible = false;
      this.main2Each_pePipe.selected = true;
      this.main2Each_pePipe.visible = true;
    }
    this.main2_exactT.selected = true;
    this.main2_exactT.visible = true;
    this.main2_unionValve.selected = true;
    this.main2_unionValve.visible = true;
    this.main2_elbow.selected = true;
    this.main2_elbow.visible = true;
    this.main2_pePipeEnd.selected = true;
    this.main2_pePipeEnd.visible = true;
    this.main2_valveSocket.selected = true;
    this.main2_valveSocket.visible = true;

    /// ////////  수량 ///////////

    this.main2Center_pePipe.designQuantity = this.main2Length / this.main2Center_pePipe.specLength;

    this.main2Each_pePipe.designQuantity = this.main2Length / this.main2Each_pePipe.specLength;

    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      if (
        this.main2LineNumber === 1 &&
        mainpipePosition.peMain1Part.waterTankPosition === CONST.LB_WATERING_MAINPIPE_WATER_TANK_POSITION_SIDE
      ) {
        this.main2_exactT.designQuantity = 0;
      } else if (
        this.main2LineNumber === 1 &&
        mainpipePosition.peMain1Part.waterTankPosition === CONST.LB_WATERING_MAINPIPE_WATER_TANK_POSITION_CENTER
      ) {
        this.main2_exactT.designQuantity = 1 * mainpipePosition.wateringWellQuantity;
      } else if (
        this.main2LineNumber === 2 &&
        mainpipePosition.peMain1Part.waterTankPosition === CONST.LB_WATERING_MAINPIPE_WATER_TANK_POSITION_SIDE
      ) {
        this.main2_exactT.designQuantity = 1 * mainpipePosition.wateringWellQuantity;
      } else if (
        this.main2LineNumber === 2 &&
        mainpipePosition.peMain1Part.waterTankPosition === CONST.LB_WATERING_MAINPIPE_WATER_TANK_POSITION_CENTER
      ) {
        this.main2_exactT.designQuantity = 3 * mainpipePosition.wateringWellQuantity;
      }

      this.main2_unionValve.designQuantity =
        1 *
        this.main2LineNumber *
        mainpipePosition.peMain1Part.waterTankPositionValue *
        mainpipePosition.wateringWellQuantity;

      this.main2_elbow.designQuantity =
        2 *
        this.main2LineNumber *
        mainpipePosition.peMain1Part.waterTankPositionValue *
        mainpipePosition.wateringWellQuantity;

      this.main2_pePipeEnd.designQuantity =
        1 *
        this.main2LineNumber *
        mainpipePosition.peMain1Part.waterTankPositionValue *
        mainpipePosition.wateringWellQuantity;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      if (mainpipePosition.wateringBuildingNumber > mainpipePosition.wateringWellQuantity) {
        this.main2_exactT.designQuantity =
          mainpipePosition.wateringBuildingNumber - mainpipePosition.wateringWellQuantity;

        this.main2_elbow.designQuantity =
          (mainpipePosition.wateringBuildingNumber - mainpipePosition.wateringWellQuantity) * 2;
      } else {
        this.main2_exactT.designQuantity = 0;

        this.main2_elbow.designQuantity = 0;
      }

      this.main2_unionValve.designQuantity = 0;

      if (wateringPosition.type === CONST.LB_WATERING_TYPE_NONE) {
        this.main2_pePipeEnd.designQuantity = 1 * mainpipePosition.wateringBuildingNumber;
      } else {
        this.main2_pePipeEnd.designQuantity = 0;
      }
    }

    this.main2_valveSocket.designQuantity = this.main2_pePipeEnd.getSelectedQuantity();
  }

  /**
   * 알고리즘: 주관2 길이 <- 기본정보, 관수 동수, 주관2 줄수, 주관2 위치, 형태(위치), 형태(세부관), 상단 형태(세부관), 하단 형태(세부관), 안개 선택(세부관)
   */
  public algorithm_main2Length(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      if (this.main2Position === CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_LOWER) {
        this.main2Length =
          ((this.basicLevel.width + this.basic.buildingInterval) *
            (<MainpipePosition>this.position).wateringBuildingNumber +
            1) *
          this.main2LineNumber;
      } else if (this.main2Position === CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_UPPER) {
        this.main2Length =
          ((this.basicLevel.width + this.basic.buildingInterval) *
            (<MainpipePosition>this.position).wateringBuildingNumber +
            1) *
            this.main2LineNumber +
          this.basicLevel.heightShoulder;
      }
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
      let oneLength: number = 0;
      if (wateringPosition.type !== CONST.LB_WATERING_TYPE_NONE) {
        oneLength = this.basicLevel.heightMax;
      } else if (wateringPosition.upperType !== CONST.LB_WATERING_UPPER_TYPE_NONE) {
        oneLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].heightShoulder + 1;
      } else if (wateringPosition.fogSelected === true) {
        oneLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].heightShoulder + 1;
      } else if (wateringPosition.lowerType !== CONST.LB_WATERING_LOWER_TYPE_NONE) {
        oneLength = 2.5;
      } else if (wateringPosition.dripType !== CONST.LB_WATERING_DRIP_TYPE_NONE) {
        oneLength = 2.5;
      } else {
        oneLength = 0;
      }
      this.main2Length = oneLength * (<MainpipePosition>this.position).wateringBuildingNumber;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [중앙 주관(여과기~끝)] PE관 <- 중앙 주관2 샘플
   */
  public algorithmSpec_main2Center_pePipe(): void {
    this.main2Center_pePipe.specs = this.main2CenterPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [개별 주관(여과기~끝)] PE관 <- 개별 주관2 샘플
   */
  public algorithmSpec_main2Each_pePipe(): void {
    this.main2Each_pePipe.specs = this.main2EachPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [주관(여과기~끝)] 정티 <- 형태(위치), 중앙 주관2 샘플, 개별 주관2 샘플
   */
  public algorithmSpec_main2_exactT(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.main2_exactT.specDiameter1 = this.main2CenterPePipeSample.specDiameter;
      this.main2_exactT.specDiameter2 = this.main2CenterPePipeSample.specDiameter;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.main2_exactT.specDiameter1 = this.main2EachPePipeSample.specDiameter;
      this.main2_exactT.specDiameter2 = this.main2EachPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [주관(여과기~끝)] 유니온 밸브 <- 형태(위치), 중앙 주관2 샘플, 개별 주관2 샘플
   */
  public algorithmSpec_main2_unionValve(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.main2_unionValve.specDiameter1 = this.main2CenterPePipeSample.specDiameter;
      this.main2_unionValve.specDiameter2 = this.main2CenterPePipeSample.specDiameter;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.main2_unionValve.specDiameter1 = this.main2EachPePipeSample.specDiameter;
      this.main2_unionValve.specDiameter2 = this.main2EachPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [주관(여과기~끝)] 엘보 <- 형태(위치), 중앙 주관2 샘플, 개별 주관2 샘플
   */
  public algorithmSpec_main2_elbow(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.main2_elbow.specDiameter1 = this.main2CenterPePipeSample.specDiameter;
      this.main2_elbow.specDiameter2 = this.main2CenterPePipeSample.specDiameter;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.main2_elbow.specDiameter1 = this.main2EachPePipeSample.specDiameter;
      this.main2_elbow.specDiameter2 = this.main2EachPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [주관(여과기~끝)] 관 마감 <- 형태(위치), 중앙 주관2 샘플, 개별 주관2 샘플
   */
  public algorithmSpec_main2_pePipeEnd(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.main2_pePipeEnd.specDiameter1 = this.main2CenterPePipeSample.specDiameter;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.main2_pePipeEnd.specDiameter1 = this.main2EachPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘: [주관(여과기~끝)] 밸브 소켓 <- 형태(위치), 중앙 주관2 샘플, 개별 주관2 샘플
   */
  public algorithmSpec_main2_valveSocket(): void {
    if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.main2_valveSocket.specDiameter1 = this.main2CenterPePipeSample.specDiameter;
    } else if ((<MainpipePosition>this.position).type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.main2_valveSocket.specDiameter1 = this.main2EachPePipeSample.specDiameter;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
