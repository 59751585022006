import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemBandstring } from "vhows-design/src/object/design/item/list/ItemBandstring";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";
import { BandstringSampleIL_PolyBandstring } from "vhows-design/src/object/design/cover/bandstring/il/BandstringSampleIL_PolyBandstring";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [BandstringSampleIL_PolyBandstring],
})
export class BandstringPartIL_Poly extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public polyBandstringSample: BandstringSampleIL_PolyBandstring; // 폴리 밴드끈 샘플

  // 아이템
  public poly_polyBandstring: ItemBandstring;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _bandstringLength: number; // 밴드끈 길이
  @jsonMember(Number)
  public _totalBandstringLength: number; // 총 밴드끈 길이

  /**
   * 밴드끈 길이
   */
  public get bandstringLength(): number {
    return this._bandstringLength;
  }

  //
  public set bandstringLength(value: number) {
    this._bandstringLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalBandstringLength();
  }

  /**
   * 총 밴드끈 길이
   */
  public get totalBandstringLength(): number {
    return this._totalBandstringLength;
  }

  //
  public set totalBandstringLength(value: number) {
    this._totalBandstringLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.polyBandstringSample = new BandstringSampleIL_PolyBandstring();

    this.sampleAC = [this.polyBandstringSample];

    // 아이템
    this.poly_polyBandstring = new ItemBandstring();

    this.itemAC = [this.poly_polyBandstring];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.polyBandstringSample = this.sampleAC[0] as BandstringSampleIL_PolyBandstring;

    // 아이템
    this.poly_polyBandstring = this.itemAC[0] as ItemBandstring;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.polyBandstringSample.setDefaultData(
        0,
        CONST.ITEM_ID_POLY_BANDSTRING,
        CONST.ITEM_NAME_POLY_BANDSTRING,
        "폴리 밴드끈",
        "폴리끈, 2cm, 500m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.poly_polyBandstring.setDefaultData(
        0,
        CONST.ITEM_ID_POLY_BANDSTRING,
        CONST.ITEM_NAME_POLY_BANDSTRING,
        "폴리 밴드끈",
        "폴리끈, 2cm, 500m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_bandstringLength();
    this.algorithm_totalBandstringLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.poly_polyBandstring.selected = true;
    this.poly_polyBandstring.visible = true;

    /// ////////  수치 ///////////

    /// ////////  수량 ///////////

    this.poly_polyBandstring.designQuantity = this.totalBandstringLength / this.poly_polyBandstring.specLength;
  }

  /**
   * 알고리즘: 밴드끈 길이 <- 최종 서까래 길이(지붕 골조), 선택(방풍벽 골조), 개폐 높이(개폐기), 선택(천창 골조), 서까래 길이(천창 골조), 곡면 폭(천창 골조)
   */
  public algorithm_bandstringLength(): void {
    const bandstringPosition: BandstringPositionIL = this.position as BandstringPositionIL;
    // 외부
    let wingPosition: WingPositionIL | WingPositionVL;
    let roofPosition: RoofPositionIL | RoofPositionVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      wingPosition = this.struct.wingWorkIL.level1.wingPosition;
      roofPosition = this.struct.roofWorkIL.level1.roofPosition;
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      wingPosition = this.struct.wingWorkVL.level1.wingPosition;
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
    }
    const switcherLevel: SwitcherLevelIL = this.struct.switcherWorkIL.level1;
    const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;

    if (bandstringPosition.installLocation === CONST.LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_AND_ROOF) {
      if (skyFramePosition.selected === false) {
        this.bandstringLength = roofPosition.rafterPart.rafterTotalLength;
      } else {
        this.bandstringLength =
          roofPosition.rafterPart.rafterTotalLength +
          (skyFramePosition.rafterPart.rafterLength - skyFramePosition.arcWidth + 1);
      }
    } else if (bandstringPosition.installLocation === CONST.LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE) {
      if (wingPosition.selected === true) {
        this.bandstringLength = Math.sqrt(
          Math.pow(switcherLevel.wingPosition.switchHeight + 0.5, 2) + Math.pow(bandstringPosition.lineInterval, 2),
        );
      } else {
        this.bandstringLength = Math.sqrt(
          Math.pow(switcherLevel.columnPosition.switchHeight + 0.5, 2) + Math.pow(bandstringPosition.lineInterval, 2),
        );
      }
    } else if (bandstringPosition.installLocation === CONST.LB_BANDSTRING_INSTALL_LOCATION_TYPE_ROOF) {
      if (skyFramePosition.selected === false) {
        this.bandstringLength = roofPosition.rafterPart.rafterTotalLength;
      } else {
        this.bandstringLength =
          roofPosition.rafterPart.rafterTotalLength +
          (skyFramePosition.rafterPart.rafterLength - skyFramePosition.arcWidth + 1);
      }
    }

    // if (this.position.label === CONST.LB_POSITION_SIDE_AND_ROOF) {
    //   if (skyFramePosition.selected === false) {
    //     this.bandstringLength = roofPosition.rafterPart.rafterTotalLength;
    //   } else {
    //     this.bandstringLength = roofPosition.rafterPart.rafterTotalLength + (skyFramePosition.rafterPart.rafterLength - skyFramePosition.arcWidth + 1);
    //   }
    // } else if (this.position.label === CONST.LB_POSITION_SIDE) {
    //   if (wingPosition.selected === true) {
    //     this.bandstringLength = Math.sqrt(Math.pow(switcherLevel.wingPosition.switchHeight + 0.5, 2) + Math.pow(bandstringPosition.lineInterval, 2));
    //   } else {
    //     this.bandstringLength = Math.sqrt(Math.pow(switcherLevel.columnPosition.switchHeight + 0.5, 2) + Math.pow(bandstringPosition.lineInterval, 2));
    //   }
    // } else if (this.position.label === CONST.LB_POSITION_ROOF) {
    //   if (skyFramePosition.selected === false) {
    //     this.bandstringLength = roofPosition.rafterPart.rafterTotalLength;
    //   } else {
    //     this.bandstringLength = roofPosition.rafterPart.rafterTotalLength + (skyFramePosition.rafterPart.rafterLength - skyFramePosition.arcWidth + 1);
    //   }
    // }
  }

  /**
   * 알고리즘: 총 밴드끈 길이 <- 밴드끈 길이, 간격(위치), 기둥 길이(기둥 골조), 길이(기본정보), 동수(기본정보)
   */
  public algorithm_totalBandstringLength(): void {
    const bandstringPosition: BandstringPositionIL = this.position as BandstringPositionIL;
    let bothEndQuantity: number;
    let centralQuantity: number;
    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    if (bandstringPosition.installLocation === CONST.LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_AND_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        bothEndQuantity =
          (this.bandstringLength + columnPosition.columnLength) *
          (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
          2;
        centralQuantity =
          this.bandstringLength *
          (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
          (this.basic.buildingNumber - 2);
        this.totalBandstringLength = Math.ceil(bothEndQuantity + centralQuantity);
      } else {
        this.totalBandstringLength = Math.ceil(
          (this.bandstringLength + columnPosition.columnLength * 2) *
            (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3),
        );
      }
    } else if (bandstringPosition.installLocation === CONST.LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_SEP_ROOF) {
      this.totalBandstringLength =
        Math.ceil(
          this.bandstringLength * (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) * 2,
        ) +
        Math.ceil(
          this.bandstringLength *
            (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
            this.basic.buildingNumber,
        );
    } else if (bandstringPosition.installLocation === CONST.LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE) {
      this.totalBandstringLength = Math.ceil(
        this.bandstringLength * (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) * 2,
      );
    } else if (bandstringPosition.installLocation === CONST.LB_BANDSTRING_INSTALL_LOCATION_TYPE_ROOF) {
      this.totalBandstringLength = Math.ceil(
        this.bandstringLength *
          (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
          this.basic.buildingNumber,
      );
    }

    // if (this.position.label === CONST.LB_POSITION_SIDE_AND_ROOF) {
    //   if (this.basic.buildingNumber >= 2) {
    //     bothEndQuantity = (this.bandstringLength + columnPosition.columnLength) * (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) * 2;
    //     centralQuantity = this.bandstringLength * (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) * (this.basic.buildingNumber - 2);
    //     this.totalBandstringLength = Math.ceil(bothEndQuantity + centralQuantity);
    //   } else {
    //     this.totalBandstringLength = Math.ceil((this.bandstringLength + columnPosition.columnLength * 2) * (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3));
    //   }
    // } else if (this.position.label === CONST.LB_POSITION_SIDE) {
    //   this.totalBandstringLength = Math.ceil(this.bandstringLength * (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) * 2);
    // } else if (this.position.label === CONST.LB_POSITION_ROOF) {
    //   this.totalBandstringLength = Math.ceil(this.bandstringLength * (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) * this.basic.buildingNumber);
    // }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [폴리 밴드끈] 폴리 밴드끈 <- 폴리 밴드끈 샘플
   */
  public algorithmSpec_poly_polyBandstring(): void {
    this.poly_polyBandstring.specs = this.polyBandstringSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
