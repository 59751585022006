import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { MaterialPart_Custom } from "vhows-design/src/object/design/other/material/MaterialPart_Custom";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-04
 */
@jsonObject({
  knownTypes: [
    // PartitionPart_Stud,
    // PartitionPart_Middle,
    // PartitionPart_Gate,
    // PartitionPart_Cover,
    // PartitionPart_FixingLaggingPad,
    // PartitionPart_FixingNormalClip,
    // PartitionPart_FixingNormalPad,
    // PartitionPart_FixingWood,
    MaterialPart_Custom,
  ],
})
export class PartitionPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // public studPart: PartitionPart_Stud;
  // public middlePart: PartitionPart_Middle;
  // public gatePart: PartitionPart_Gate;
  // public coverPart: PartitionPart_Cover;
  // public fixingNormalPadPart: PartitionPart_FixingNormalPad;
  // public fixingNormalClipPart: PartitionPart_FixingNormalClip;
  // public fixingLaggingPadPart: PartitionPart_FixingLaggingPad;
  // public fixingWoodPart: PartitionPart_FixingWood;
  public customPart: MaterialPart_Custom;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _partitionQuantity: number = 0; // 파티션 수량
  @jsonMember(String)
  public _gateType: string; // 출입문 형태
  @jsonMember(String)
  public _fixingType: string; // 고정 형태

  /**
   * 파티션 수량
   */
  public get partitionQuantity(): number {
    return this._partitionQuantity;
  }

  //
  public set partitionQuantity(value: number) {
    this._partitionQuantity = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 출입문 형태
   */
  public get gateType(): string {
    return this._gateType;
  }

  //
  public set gateType(value: string) {
    this._gateType = value;

    // 알고리즘
    // this.gatePart.algorithm_makingMethod();
    // this.gatePart.algorithm_doorWidth();
    // this.gatePart.algorithm_doorHeight();
    // this.gatePart.algorithm_doorQuantity();
    // this.gatePart.algorithm_gateWidth();
    // this.gatePart.algorithm_gateHeight();

    // 파트 활성화
    this.algorithm_partActivationByGateType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByGateType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // this.studPart = new PartitionPart_Stud();
    // this.middlePart = new PartitionPart_Middle();
    // this.gatePart = new PartitionPart_Gate();
    // this.coverPart = new PartitionPart_Cover();
    // this.fixingNormalPadPart = new PartitionPart_FixingNormalPad();
    // this.fixingNormalClipPart = new PartitionPart_FixingNormalClip();
    // this.fixingLaggingPadPart = new PartitionPart_FixingLaggingPad();
    // this.fixingWoodPart = new PartitionPart_FixingWood();
    this.customPart = new MaterialPart_Custom();

    this.partAC = [
      // this.studPart,
      // this.middlePart,
      // this.gatePart,
      // this.coverPart,
      // this.fixingNormalPadPart,
      // this.fixingNormalClipPart,
      // this.fixingLaggingPadPart,
      // this.fixingWoodPart,
      this.customPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // this.studPart = <PartitionPart_Stud>this.partAC[0];
    // this.middlePart = <PartitionPart_Middle>this.partAC[1];
    // this.gatePart = <PartitionPart_Gate>this.partAC[2];
    // this.coverPart = <PartitionPart_Cover>this.partAC[3];
    // this.fixingNormalPadPart = <PartitionPart_FixingNormalPad>this.partAC[4];
    // this.fixingNormalClipPart = <PartitionPart_FixingNormalClip>this.partAC[5];
    // this.fixingLaggingPadPart = <PartitionPart_FixingLaggingPad>this.partAC[6];
    // this.fixingWoodPart = <PartitionPart_FixingWood>this.partAC[7];
    this.customPart = <MaterialPart_Custom>this.partAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param partitionQuantity: number 파티션 수량
   * @param gateType: string 출입문 형태
   * @param fixingType: string 고정 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    partitionQuantity: number = 0,
    gateType: string = "",
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._partitionQuantity = partitionQuantity;
    this._gateType = gateType;
    this._fixingType = fixingType;

    if (this.level.index >= 0) {
      // 칸막이 샛기둥
      // this.studPart.setDefaultData(
      //   CONST.INDEX_CM_PARTITION_STUD,
      //   true, false, true,
      //   CONST.LB_PARTITION_STUD, '',
      //   3,
      // );

      // // 칸막이 중방
      // this.middlePart.setDefaultData(
      //   CONST.INDEX_CM_PARTITION_MIDDLE,
      //   true, false, true,
      //   CONST.LB_PARTITION_MIDDLE, '',
      //   1,
      // );

      // // 칸막이 출입문
      // this.gatePart.setDefaultData(
      //   CONST.INDEX_CM_PARTITION_GATE,
      //   true, false, true,
      //   CONST.LB_PARTITION_GATE, '',
      //   CONST.LB_GATE_MAKING_METHOD_MADE, 1300, 2000, 2,
      // );

      // // 칸막이 피복
      // this.coverPart.setDefaultData(
      //   CONST.INDEX_CM_PARTITION_COVER,
      //   true, false, true,
      //   CONST.LB_PARTITION_COVER, '',
      // );

      // // 칸막이 피복 고정 - 일반 패드 고정
      // this.fixingNormalPadPart.setDefaultData(
      //   CONST.INDEX_CM_PARTITION_FIXING_NORMAL_PAD,
      //   true, false, true,
      //   CONST.LB_PARTITION_FIXING_NORMAL_PAD, '',
      // );

      // // 칸막이 피복 고정 - 일반 클립 고정
      // this.fixingNormalClipPart.setDefaultData(
      //   CONST.INDEX_CM_PARTITION_FIXING_NORMAL_CLIP,
      //   false, false, false,
      //   CONST.LB_PARTITION_FIXING_NORMAL_CLIP, '',
      //   0.2,
      // );

      // // 칸막이 피복 고정 - 광폭 패드 고정
      // this.fixingLaggingPadPart.setDefaultData(
      //   CONST.INDEX_CM_PARTITION_FIXING_LAGGING_PAD,
      //   false, false, false,
      //   CONST.LB_PARTITION_FIXING_LAGGING_PAD, '',
      // );

      // // 칸막이 피복 고정 - 목재 고정
      // this.fixingWoodPart.setDefaultData(
      //   CONST.INDEX_CM_PARTITION_FIXING_WOOD,
      //   false, false, false,
      //   CONST.LB_PARTITION_FIXING_WOOD, '',
      // );
      if (this.level.index >= 0) {
        // 임의 파트
        this.customPart.setDefaultData(
          CONST.INDEX_CM_ENVIRONMENT_CUSTOM,
          false,
          false,
          true,
          CONST.LB_ENVIRONMENT_CUSTOM,
          "",
        );
      }
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    // if (this.partAC.length === 10 && (<Part>this.partAC[4]).label === '출입 문틀') {
    // // 칸막이 출입문 - 제작형 문짝
    // const gateMakingDoorPart: PartitionPart_GateMakingDoor = new PartitionPart_GateMakingDoor();
    // gateMakingDoorPart.setAssociation(design, struct, work, level, this);
    // gateMakingDoorPart.setDefaultData(
    //   CONST.INDEX_CM_PARTITION_GATE_MAKING_DOOR,
    //   false, false, false,
    //   CONST.LB_PARTITION_GATE_MAKING_DOOR, '',
    //   CONST.LB_GATE_MAKING_DOOR_MAKING_METHOD_DOOR,
    //   1300, 2000,
    //   2,
    //   CONST.LB_GATE_MAKING_DOOR_PIPE_HOLER_TYPE_T_HOLDER,
    // );
    // if (this.gateType === CONST.LB_GATE_TYPE_SLIDING_MAKING
    //   || this.gateType === CONST.LB_GATE_TYPE_HINGED_MAKING) {
    //   gateMakingDoorPart._selected = true;
    //   gateMakingDoorPart._visible = true;
    // }
    // this.partAC.splice(4, 0, gateMakingDoorPart);
    // 임시 데이터로 대체
    // this.partAC.splice(4, 0, new Part());
  }

  // if (this.partAC.length === 11 && (<Part>this.partAC[5]).label === '출입 문틀') {
  //   // 통합 출입문 파트 추가 및 기존 출입문관련 파트 모두 삭제
  //   const gatePart: PartitionPart_Gate = new PartitionPart_Gate();
  //   gatePart.setAssociation(design, struct, work, level, this);
  //   gatePart.setDefaultData(
  //     CONST.INDEX_CM_PARTITION_GATE,
  //     true, false, true,
  //     CONST.LB_PARTITION_GATE, '',
  //     CONST.LB_GATE_MAKING_METHOD_MADE, 1300, 2000, 2,
  //   );
  //   if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
  //     gatePart._selected = true;
  //     gatePart._visible = true;
  //   }
  //   this.partAC.splice(2, 4, gatePart);

  //   // 인덱스 재설정
  //   CommonUtil.reindexAC(this.partAC);
  //   // 알고리즘 동작
  //   gatePart.setDefaultVariable();
  //   gatePart.algorithmPart();
  // }

  // super.restoreLatestObject(design, struct, work, level);
  // }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    // if (this.type === CONST.LB_PARTITION_TYPE_WHOLE) {
    //   this.studPart.selected = true;
    //   this.studPart.visible = true;
    //   this.middlePart.selected = true;
    //   this.middlePart.visible = true;
    //   this.coverPart.selected = true;
    //   this.coverPart.visible = true;
    // }
    if (this.type === CONST.LB_PARTITION_TYPE_CUSTOM) {
      this.customPart.selected = true;
      this.customPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 출입문 형태
   */
  public algorithm_partActivationByGateType(): void {
    // if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
    //   this.gatePart.selected = true;
    //   this.gatePart.visible = true;
    // } else {
    //   this.gatePart.selected = false;
    //   this.gatePart.visible = false;
    // }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    // if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
    //   this.fixingNormalPadPart.selected = true;
    //   this.fixingNormalPadPart.visible = true;
    //   this.fixingNormalClipPart.selected = false;
    //   this.fixingNormalClipPart.visible = false;
    //   this.fixingLaggingPadPart.selected = false;
    //   this.fixingLaggingPadPart.visible = false;
    //   this.fixingWoodPart.selected = false;
    //   this.fixingWoodPart.visible = false;
    // } else if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
    //   this.fixingNormalPadPart.selected = false;
    //   this.fixingNormalPadPart.visible = false;
    //   this.fixingNormalClipPart.selected = true;
    //   this.fixingNormalClipPart.visible = true;
    //   this.fixingLaggingPadPart.selected = false;
    //   this.fixingLaggingPadPart.visible = false;
    //   this.fixingWoodPart.selected = false;
    //   this.fixingWoodPart.visible = false;
    // } else if (this.fixingType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
    //   this.fixingNormalPadPart.selected = false;
    //   this.fixingNormalPadPart.visible = false;
    //   this.fixingNormalClipPart.selected = false;
    //   this.fixingNormalClipPart.visible = false;
    //   this.fixingLaggingPadPart.selected = true;
    //   this.fixingLaggingPadPart.visible = true;
    //   this.fixingWoodPart.selected = false;
    //   this.fixingWoodPart.visible = false;
    // } else if (this.fixingType === CONST.LB_FIXING_TYPE_WOOD) {
    //   this.fixingNormalPadPart.selected = false;
    //   this.fixingNormalPadPart.visible = false;
    //   this.fixingNormalClipPart.selected = false;
    //   this.fixingNormalClipPart.visible = false;
    //   this.fixingLaggingPadPart.selected = false;
    //   this.fixingLaggingPadPart.visible = false;
    //   this.fixingWoodPart.selected = true;
    //   this.fixingWoodPart.visible = true;
    // } else {
    //   this.fixingNormalPadPart.selected = false;
    //   this.fixingNormalPadPart.visible = false;
    //   this.fixingNormalClipPart.selected = false;
    //   this.fixingNormalClipPart.visible = false;
    //   this.fixingLaggingPadPart.selected = false;
    //   this.fixingLaggingPadPart.visible = false;
    //   this.fixingWoodPart.selected = false;
    //   this.fixingWoodPart.visible = false;
    // }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태(피복 고정)
   */
  public algorithm_fixingTypeByFixing(): void {
    // let fixingLevel: FixingLevel;
    // if (this.design.basic.structureName === CONST.LB_STRUCT_SINGLE
    //   || this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH
    //   || this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF) {
    //   fixingLevel = <FixingLevel>this.struct.fixingWork.levelAC[this.level.index];
    // }
    // if (this.label === CONST.LB_POSITION_WHOLE) {
    //   if (fixingLevel.frontPosition.type === CONST.LB_FIXING_TYPE_NORMAL_PAD
    //     || fixingLevel.frontPosition.type === CONST.LB_FIXING_TYPE_LAGGING_PAD
    //     || fixingLevel.frontPosition.type === CONST.LB_FIXING_TYPE_WOOD) {
    //     // 앞면 피복 고정 형태를 따름
    //     this.fixingType = fixingLevel.frontPosition.type;
    //   }
    // }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
