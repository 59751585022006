import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDripButton } from "vhows-design/src/object/design/item/list/ItemDripButton";
import { ItemDripButtonAppendage } from "vhows-design/src/object/design/item/list/ItemDripButtonAppendage";
import { ItemDripConnect } from "vhows-design/src/object/design/item/list/ItemDripConnect";
import { ItemDripPipe } from "vhows-design/src/object/design/item/list/ItemDripPipe";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringTube } from "vhows-design/src/object/design/item/list/ItemWateringTube";
import { ItemWateringTubeNipple } from "vhows-design/src/object/design/item/list/ItemWateringTubeNipple";
import { Part } from "vhows-design/src/object/design/base/Part";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { WateringSample_DripPipeTape } from "vhows-design/src/object/design/watering/watering/WateringSample_DripPipeTape";
import { WateringSample_DripPipePipe } from "vhows-design/src/object/design/watering/watering/WateringSample_DripPipePipe";
import { WateringSample_DripPePipe } from "vhows-design/src/object/design/watering/watering/WateringSample_DripPePipe";
import { WateringSample_DripButton } from "vhows-design/src/object/design/watering/watering/WateringSample_DripButton";
import { WateringSample_DripButtonTube } from "vhows-design/src/object/design/watering/watering/WateringSample_DripButtonTube";
import { WateringSample_DripButtonDropper } from "vhows-design/src/object/design/watering/watering/WateringSample_DripButtonDropper";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [
    WateringSample_DripPipeTape,
    WateringSample_DripPipePipe,
    WateringSample_DripPePipe,
    WateringSample_DripButton,
    WateringSample_DripButtonTube,
    WateringSample_DripButtonDropper,
  ],
})
export class WateringPart_DripWatering extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public dripPipeTapeSample: WateringSample_DripPipeTape; // 점적관(테이프) 샘플
  public dripPipePipeSample: WateringSample_DripPipePipe; // 점적관(파이프) 샘플
  public dripPePipeSample: WateringSample_DripPePipe; // 점적관(PE) 샘플
  public dripButtonSample: WateringSample_DripButton; // 점적단추 샘플
  public dripButtonTubeSample: WateringSample_DripButtonTube; // 점적단추(튜브) 샘플
  public dripButtonDropperSample: WateringSample_DripButtonDropper; // 점적단추(드롭퍼) 샘플

  // 아이템
  public dripPipeTape_dripPipe: ItemDripPipe;
  public dripPipePipe_dripPipe: ItemDripPipe;
  public dripPipe_dripConnectSocket: ItemDripConnect;
  public dripPePipe_pePipe: ItemWateringPipe;
  public dripPePipe_ldConnect: ItemDripConnect;
  public dripPePipe_dripButton: ItemDripButton;
  public dripPePipeTube_dripButton: ItemDripButton;
  public dripPePipeDropper_dripButton: ItemDripButton;
  public dripPePipeDropper_dripButtonManyHold: ItemDripButtonAppendage;
  public dripPePipe_tube: ItemWateringTube;
  public dripPePipe_tubeNipple: ItemWateringTubeNipple;
  public dripPePipeDropper_dripButtonDropper: ItemDripButtonAppendage;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wateringBuildingNumber: number = 0; // 관수 동수
  @jsonMember(String)
  public _dripPipeType: string; // 점적 형태
  @jsonMember(Number)
  public _dripPipeLineNumber: number; // 점적 줄수
  @jsonMember(Number)
  public _dripPipeLength: number; // 점적 길이
  @jsonMember(Number)
  public _tubeLength: number; // 튜브 길이
  @jsonMember(Number)
  public _dripButtonInterval: number; // 점적단추 간격

  /**
   * 관수 동수
   */
  public get wateringBuildingNumber(): number {
    return this._wateringBuildingNumber;
  }

  //
  public set wateringBuildingNumber(value: number) {
    this._wateringBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<WateringPosition>this.position).dripConnectPart.algorithmPart();
  }

  /**
   * 점적 형태
   */
  public get dripPipeType(): string {
    return this._dripPipeType;
  }

  //
  public set dripPipeType(value: string) {
    this._dripPipeType = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 점적 줄수
   */
  public get dripPipeLineNumber(): number {
    return this._dripPipeLineNumber;
  }

  //
  public set dripPipeLineNumber(value: number) {
    this._dripPipeLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<WateringPosition>this.position).dripConnectPart.algorithm_subPipeLineNumber();
    (<WateringPosition>this.position).dripConnectPart.algorithm_subPipeLength();
    (<WateringPosition>this.position).dripConnectPart.algorithmPart();
  }

  /**
   * 점적 길이
   */
  public get dripPipeLength(): number {
    return this._dripPipeLength;
  }

  //
  public set dripPipeLength(value: number) {
    this._dripPipeLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 튜브 길이
   */
  public get tubeLength(): number {
    return this._tubeLength;
  }

  //
  public set tubeLength(value: number) {
    this._tubeLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 점적단추 간격
   */
  public get dripButtonInterval(): number {
    return this._dripButtonInterval;
  }

  //
  public set dripButtonInterval(value: number) {
    this._dripButtonInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.dripPipeTapeSample = new WateringSample_DripPipeTape();
    this.dripPipePipeSample = new WateringSample_DripPipePipe();
    this.dripPePipeSample = new WateringSample_DripPePipe();
    this.dripButtonSample = new WateringSample_DripButton();
    this.dripButtonTubeSample = new WateringSample_DripButtonTube();
    this.dripButtonDropperSample = new WateringSample_DripButtonDropper();

    this.sampleAC = [
      this.dripPipeTapeSample,
      this.dripPipePipeSample,
      this.dripPePipeSample,
      this.dripButtonSample,
      this.dripButtonTubeSample,
      this.dripButtonDropperSample,
    ];

    // 아이템
    this.dripPipeTape_dripPipe = new ItemDripPipe();
    this.dripPipePipe_dripPipe = new ItemDripPipe();
    this.dripPipe_dripConnectSocket = new ItemDripConnect();
    this.dripPePipe_pePipe = new ItemWateringPipe();
    this.dripPePipe_ldConnect = new ItemDripConnect();
    this.dripPePipe_dripButton = new ItemDripButton();
    this.dripPePipeTube_dripButton = new ItemDripButton();
    this.dripPePipeDropper_dripButton = new ItemDripButton();
    this.dripPePipeDropper_dripButtonManyHold = new ItemDripButtonAppendage();
    this.dripPePipe_tube = new ItemWateringTube();
    this.dripPePipe_tubeNipple = new ItemWateringTubeNipple();
    this.dripPePipeDropper_dripButtonDropper = new ItemDripButtonAppendage();

    this.itemAC = [
      this.dripPipeTape_dripPipe,
      this.dripPipePipe_dripPipe,
      this.dripPipe_dripConnectSocket,
      this.dripPePipe_pePipe,
      this.dripPePipe_ldConnect,
      this.dripPePipe_dripButton,
      this.dripPePipeTube_dripButton,
      this.dripPePipeDropper_dripButton,
      this.dripPePipeDropper_dripButtonManyHold,
      this.dripPePipe_tube,
      this.dripPePipe_tubeNipple,
      this.dripPePipeDropper_dripButtonDropper,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.dripPipeTapeSample = <WateringSample_DripPipeTape>this.sampleAC[0];
    this.dripPipePipeSample = <WateringSample_DripPipePipe>this.sampleAC[1];
    this.dripPePipeSample = <WateringSample_DripPePipe>this.sampleAC[2];
    this.dripButtonSample = <WateringSample_DripButton>this.sampleAC[3];
    this.dripButtonTubeSample = <WateringSample_DripButtonTube>this.sampleAC[4];
    this.dripButtonDropperSample = <WateringSample_DripButtonDropper>this.sampleAC[5];
    // 아이템
    this.dripPipeTape_dripPipe = <ItemDripPipe>this.itemAC[0];
    this.dripPipePipe_dripPipe = <ItemDripPipe>this.itemAC[1];
    this.dripPipe_dripConnectSocket = <ItemDripConnect>this.itemAC[2];
    this.dripPePipe_pePipe = <ItemWateringPipe>this.itemAC[3];
    this.dripPePipe_ldConnect = <ItemDripConnect>this.itemAC[4];
    this.dripPePipe_dripButton = <ItemDripButton>this.itemAC[5];
    this.dripPePipeTube_dripButton = <ItemDripButton>this.itemAC[6];
    this.dripPePipeDropper_dripButton = <ItemDripButton>this.itemAC[7];
    this.dripPePipeDropper_dripButtonManyHold = <ItemDripButtonAppendage>this.itemAC[8];
    this.dripPePipe_tube = <ItemWateringTube>this.itemAC[9];
    this.dripPePipe_tubeNipple = <ItemWateringTubeNipple>this.itemAC[10];
    this.dripPePipeDropper_dripButtonDropper = <ItemDripButtonAppendage>this.itemAC[11];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param dripPipeType: string 점적 형태
   * @param dripPipeLineNumber: number 점적 줄수
   * @param tubeLength: number 튜브 길이
   * @param dripButtonInterval: number 점적단추 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    dripPipeType: string = "",
    dripPipeLineNumber: number = 0,
    tubeLength: number = 0,
    dripButtonInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._dripPipeType = dripPipeType;
    this._dripPipeLineNumber = dripPipeLineNumber;
    this._tubeLength = tubeLength;
    this._dripButtonInterval = dripButtonInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.dripPipeTapeSample.setDefaultData(
        0,
        CONST.ITEM_ID_DRIP_PIPE,
        CONST.ITEM_NAME_DRIP_PIPE,
        "점적관",
        "점적테이프",
        "서원양행, 그린드립, 0.2T, 10cm간격, 500m, 1L/H",
      );
      this.dripPipePipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_DRIP_PIPE,
        CONST.ITEM_NAME_DRIP_PIPE,
        "점적관",
        "점적파이프",
        "신동물산, 관수점적파이프, 1T, 15cm간격, 400m, 2L/H",
      );
      this.dripPePipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "점적관(PE)",
        "연질관, 13mm, 200m",
      );
      this.dripButtonSample.setDefaultData(
        3,
        CONST.ITEM_ID_DRIP_BUTTON,
        CONST.ITEM_NAME_DRIP_BUTTON,
        "점적단추",
        "직접살수",
        "AK, 옥타8, 물량조절, 0~40L/H",
      );
      this.dripButtonTubeSample.setDefaultData(
        4,
        CONST.ITEM_ID_DRIP_BUTTON,
        CONST.ITEM_NAME_DRIP_BUTTON,
        "점적단추",
        "튜브살수",
        "AK, 옥타8, 물량조절, 0~40L/H",
      );
      this.dripButtonDropperSample.setDefaultData(
        5,
        CONST.ITEM_ID_DRIP_BUTTON,
        CONST.ITEM_NAME_DRIP_BUTTON,
        "점적단추",
        "드롭퍼살수",
        "DAN, PC&CNL, 일반, 8L/H",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.dripPipeTape_dripPipe.setDefaultData(
        0,
        CONST.ITEM_ID_DRIP_PIPE,
        CONST.ITEM_NAME_DRIP_PIPE,
        "점적관",
        "점적테이프",
        "서원양행, 그린드립, 0.2T, 10cm간격, 500m, 1L/H",
      );
      this.dripPipePipe_dripPipe.setDefaultData(
        1,
        CONST.ITEM_ID_DRIP_PIPE,
        CONST.ITEM_NAME_DRIP_PIPE,
        "점적관",
        "점적파이프",
        "신동물산, 관수점적파이프, 1T, 15cm간격, 400m, 2L/H",
      );
      this.dripPipe_dripConnectSocket.setDefaultData(
        2,
        CONST.ITEM_ID_DRIP_CONNECT_SOCKET,
        CONST.ITEM_NAME_DRIP_CONNECT_SOCKET,
        "점적관",
        "일반, 일자, 13mm",
      );
      this.dripPePipe_pePipe.setDefaultData(
        3,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "점적관(PE)",
        "연질관, 13mm, 200m",
      );
      this.dripPePipe_ldConnect.setDefaultData(
        4,
        CONST.ITEM_ID_LD_CONNECT,
        CONST.ITEM_NAME_LD_CONNECT,
        "점적관(PE)",
        "일반, 일자, 13mm",
      );
      this.dripPePipe_dripButton.setDefaultData(
        5,
        CONST.ITEM_ID_DRIP_BUTTON,
        CONST.ITEM_NAME_DRIP_BUTTON,
        "점적단추",
        "직접살수",
        "AK, 옥타8, 물량조절, 0~40L/H",
      );
      this.dripPePipeTube_dripButton.setDefaultData(
        6,
        CONST.ITEM_ID_DRIP_BUTTON,
        CONST.ITEM_NAME_DRIP_BUTTON,
        "점적단추",
        "튜브살수",
        "AK, 옥타8, 물량조절, 0~40L/H",
      );
      this.dripPePipeDropper_dripButton.setDefaultData(
        7,
        CONST.ITEM_ID_DRIP_BUTTON,
        CONST.ITEM_NAME_DRIP_BUTTON,
        "점적단추",
        "드롭퍼살수",
        "DAN, PC&CNL, 일반, 8L/H",
      );
      this.dripPePipeDropper_dripButtonManyHold.setDefaultData(
        8,
        CONST.ITEM_ID_DRIP_BUTTON_MANY_HOLD,
        CONST.ITEM_NAME_DRIP_BUTTON_MANY_HOLD,
        "점적단추",
        "일반, 4구, 3/5mm",
      );
      this.dripPePipe_tube.setDefaultData(9, CONST.ITEM_ID_TUBE, CONST.ITEM_NAME_TUBE, "점적단추", "일반, 3/5mm, 200m");
      this.dripPePipe_tubeNipple.setDefaultData(
        10,
        CONST.ITEM_ID_TUBE_NIPPLE,
        CONST.ITEM_NAME_TUBE_NIPPLE,
        "점적단추",
        "일반, 엘디, 3/5mm",
      );
      this.dripPePipeDropper_dripButtonDropper.setDefaultData(
        11,
        CONST.ITEM_ID_DRIP_BUTTON_DROPPER,
        CONST.ITEM_NAME_DRIP_BUTTON_DROPPER,
        "점적단추",
        "일반, ㄱ자, 3/5mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_dripPipeLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    if (this.dripPipeType === CONST.LB_WATERING_DRIP_PIPE_TYPE_DRIP_TAPE) {
      this.dripPipeTape_dripPipe.selected = true;
      this.dripPipeTape_dripPipe.visible = true;
      this.dripPipePipe_dripPipe.selected = false;
      this.dripPipePipe_dripPipe.visible = false;
      this.dripPipe_dripConnectSocket.selected = true;
      this.dripPipe_dripConnectSocket.visible = true;
      this.dripPePipe_pePipe.selected = false;
      this.dripPePipe_pePipe.visible = false;
      this.dripPePipe_ldConnect.selected = false;
      this.dripPePipe_ldConnect.visible = false;
      this.dripPePipe_dripButton.selected = false;
      this.dripPePipe_dripButton.visible = false;
      this.dripPePipeTube_dripButton.selected = false;
      this.dripPePipeTube_dripButton.visible = false;
      this.dripPePipeDropper_dripButton.selected = false;
      this.dripPePipeDropper_dripButton.visible = false;
      this.dripPePipeDropper_dripButtonManyHold.selected = false;
      this.dripPePipeDropper_dripButtonManyHold.visible = false;
      this.dripPePipe_tube.selected = false;
      this.dripPePipe_tube.visible = false;
      this.dripPePipe_tubeNipple.selected = false;
      this.dripPePipe_tubeNipple.visible = false;
      this.dripPePipeDropper_dripButtonDropper.selected = false;
      this.dripPePipeDropper_dripButtonDropper.visible = false;
    } else if (this.dripPipeType === CONST.LB_WATERING_DRIP_PIPE_TYPE_DRIP_PIPE) {
      this.dripPipeTape_dripPipe.selected = false;
      this.dripPipeTape_dripPipe.visible = false;
      this.dripPipePipe_dripPipe.selected = true;
      this.dripPipePipe_dripPipe.visible = true;
      this.dripPipe_dripConnectSocket.selected = true;
      this.dripPipe_dripConnectSocket.visible = true;
      this.dripPePipe_pePipe.selected = false;
      this.dripPePipe_pePipe.visible = false;
      this.dripPePipe_ldConnect.selected = false;
      this.dripPePipe_ldConnect.visible = false;
      this.dripPePipe_dripButton.selected = false;
      this.dripPePipe_dripButton.visible = false;
      this.dripPePipeTube_dripButton.selected = false;
      this.dripPePipeTube_dripButton.visible = false;
      this.dripPePipeDropper_dripButton.selected = false;
      this.dripPePipeDropper_dripButton.visible = false;
      this.dripPePipeDropper_dripButtonManyHold.selected = false;
      this.dripPePipeDropper_dripButtonManyHold.visible = false;
      this.dripPePipe_tube.selected = false;
      this.dripPePipe_tube.visible = false;
      this.dripPePipe_tubeNipple.selected = false;
      this.dripPePipe_tubeNipple.visible = false;
      this.dripPePipeDropper_dripButtonDropper.selected = false;
      this.dripPePipeDropper_dripButtonDropper.visible = false;
    } else if (this.dripPipeType === CONST.LB_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON) {
      this.dripPipeTape_dripPipe.selected = false;
      this.dripPipeTape_dripPipe.visible = false;
      this.dripPipePipe_dripPipe.selected = false;
      this.dripPipePipe_dripPipe.visible = false;
      this.dripPipe_dripConnectSocket.selected = false;
      this.dripPipe_dripConnectSocket.visible = false;
      this.dripPePipe_pePipe.selected = true;
      this.dripPePipe_pePipe.visible = true;
      this.dripPePipe_ldConnect.selected = true;
      this.dripPePipe_ldConnect.visible = true;
      this.dripPePipe_dripButton.selected = true;
      this.dripPePipe_dripButton.visible = true;
      this.dripPePipeTube_dripButton.selected = false;
      this.dripPePipeTube_dripButton.visible = false;
      this.dripPePipeDropper_dripButton.selected = false;
      this.dripPePipeDropper_dripButton.visible = false;
      this.dripPePipeDropper_dripButtonManyHold.selected = false;
      this.dripPePipeDropper_dripButtonManyHold.visible = false;
      this.dripPePipe_tube.selected = false;
      this.dripPePipe_tube.visible = false;
      this.dripPePipe_tubeNipple.selected = false;
      this.dripPePipe_tubeNipple.visible = false;
      this.dripPePipeDropper_dripButtonDropper.selected = false;
      this.dripPePipeDropper_dripButtonDropper.visible = false;
    } else if (this.dripPipeType === CONST.LB_WATERING_DRIP_PIPE_TYPE_TUBE_DRIP_BUTTON) {
      this.dripPipeTape_dripPipe.selected = false;
      this.dripPipeTape_dripPipe.visible = false;
      this.dripPipePipe_dripPipe.selected = false;
      this.dripPipePipe_dripPipe.visible = false;
      this.dripPipe_dripConnectSocket.selected = false;
      this.dripPipe_dripConnectSocket.visible = false;
      this.dripPePipe_pePipe.selected = true;
      this.dripPePipe_pePipe.visible = true;
      this.dripPePipe_ldConnect.selected = true;
      this.dripPePipe_ldConnect.visible = true;
      this.dripPePipe_dripButton.selected = false;
      this.dripPePipe_dripButton.visible = false;
      this.dripPePipeTube_dripButton.selected = true;
      this.dripPePipeTube_dripButton.visible = true;
      this.dripPePipeDropper_dripButton.selected = false;
      this.dripPePipeDropper_dripButton.visible = false;
      this.dripPePipeDropper_dripButtonManyHold.selected = false;
      this.dripPePipeDropper_dripButtonManyHold.visible = false;
      this.dripPePipe_tube.selected = true;
      this.dripPePipe_tube.visible = true;
      this.dripPePipe_tubeNipple.selected = true;
      this.dripPePipe_tubeNipple.visible = true;
      this.dripPePipeDropper_dripButtonDropper.selected = false;
      this.dripPePipeDropper_dripButtonDropper.visible = false;
    } else if (this.dripPipeType === CONST.LB_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON_DROPPER) {
      this.dripPipeTape_dripPipe.selected = false;
      this.dripPipeTape_dripPipe.visible = false;
      this.dripPipePipe_dripPipe.selected = false;
      this.dripPipePipe_dripPipe.visible = false;
      this.dripPipe_dripConnectSocket.selected = false;
      this.dripPipe_dripConnectSocket.visible = false;
      this.dripPePipe_pePipe.selected = true;
      this.dripPePipe_pePipe.visible = true;
      this.dripPePipe_ldConnect.selected = true;
      this.dripPePipe_ldConnect.visible = true;
      this.dripPePipe_dripButton.selected = false;
      this.dripPePipe_dripButton.visible = false;
      this.dripPePipeTube_dripButton.selected = false;
      this.dripPePipeTube_dripButton.visible = false;
      this.dripPePipeDropper_dripButton.selected = true;
      this.dripPePipeDropper_dripButton.visible = true;
      this.dripPePipeDropper_dripButtonManyHold.selected = true;
      this.dripPePipeDropper_dripButtonManyHold.visible = true;
      this.dripPePipe_tube.selected = true;
      this.dripPePipe_tube.visible = true;
      this.dripPePipe_tubeNipple.selected = false;
      this.dripPePipe_tubeNipple.visible = false;
      this.dripPePipeDropper_dripButtonDropper.selected = true;
      this.dripPePipeDropper_dripButtonDropper.visible = true;
    }

    /// ////////  수량 ///////////

    this.dripPipeTape_dripPipe.designQuantity =
      (this.dripPipeLength * this.dripPipeLineNumber * this.wateringBuildingNumber) /
      this.dripPipeTape_dripPipe._brandLength;

    this.dripPipePipe_dripPipe.designQuantity =
      (this.dripPipeLength * this.dripPipeLineNumber * this.wateringBuildingNumber) /
      this.dripPipeTape_dripPipe._brandLength;

    //			dripPipe_dripConnectSocket.designQuantity = 0;

    this.dripPePipe_pePipe.designQuantity =
      (this.dripPipeLength * this.dripPipeLineNumber * this.wateringBuildingNumber) / this.dripPePipe_pePipe.specLength;

    //			dripPePipe_ldConnect.designQuantity = 0;

    this.dripPePipe_dripButton.designQuantity =
      (this.dripPipeLength / this.dripButtonInterval) * this.dripPipeLineNumber * this.wateringBuildingNumber;

    this.dripPePipeTube_dripButton.designQuantity =
      (this.dripPipeLength / this.dripButtonInterval) * this.dripPipeLineNumber * this.wateringBuildingNumber;

    this.dripPePipeDropper_dripButton.designQuantity =
      (this.dripPipeLength / this.dripButtonInterval) * this.dripPipeLineNumber * this.wateringBuildingNumber;

    this.dripPePipeDropper_dripButtonManyHold.designQuantity =
      (this.dripPipeLength / this.dripButtonInterval) * this.dripPipeLineNumber * this.wateringBuildingNumber;

    if (this.dripPipeType === CONST.LB_WATERING_DRIP_PIPE_TYPE_TUBE_DRIP_BUTTON) {
      this.dripPePipe_tube.designQuantity =
        (this.tubeLength * this.dripPePipeTube_dripButton.designQuantity) / this.dripPePipe_tube.specLength;
    } else if (this.dripPipeType === CONST.LB_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON_DROPPER) {
      this.dripPePipe_tube.designQuantity =
        (this.tubeLength *
          this.dripPePipeDropper_dripButton.designQuantity *
          this.dripPePipeDropper_dripButtonManyHold.specHold) /
        this.dripPePipe_tube.specLength;
    }

    this.dripPePipe_tubeNipple.designQuantity =
      (this.dripPipeLength / this.dripButtonInterval) * this.dripPipeLineNumber * this.wateringBuildingNumber;

    this.dripPePipeDropper_dripButtonDropper.designQuantity =
      this.dripPePipeDropper_dripButton.designQuantity * this.dripPePipeDropper_dripButtonManyHold.specHold;
  }

  /**
   * 알고리즘: 점적 형태 <- 점적 연결 형태(점적 연결 파트)
   */
  public algorithm_dripPipeType(): void {
    if (
      (<WateringPosition>this.position).dripConnectPart.connectType ===
      CONST.LB_WATERING_DRIP_CONNECT_TYPE_START_LINE_VALVE
    ) {
      this.dripPipeType = CONST.LB_WATERING_DRIP_PIPE_TYPE_DRIP_TAPE;
    }
  }

  /**
   * 알고리즘: 관수 동수 <- 관수 동수(관수 제어부)
   */
  public algorithm_wateringBuildingNumber(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    this.wateringBuildingNumber = mainpipePosition.wateringBuildingNumber;
  }

  /**
   * 알고리즘: 점적 길이 <- 기본정보
   */
  public algorithm_dripPipeLength(): void {
    this.dripPipeLength = this.basic.basicLevelAC[this.basic.levelNumber - 1].length;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [점적관] 점적관 <- 점적테이프 샘플
   */
  public algorithmSpec_dripPipeTape_dripPipe(): void {
    this.dripPipeTape_dripPipe.specs = this.dripPipeTapeSample.specs;
    this.dripPipeTape_dripPipe.brands = this.dripPipeTapeSample.brands;
  }

  /**
   * 규격 알고리즘: [점적관] 점적관 <- 점적파이프 샘플
   */
  public algorithmSpec_dripPipePipe_dripPipe(): void {
    this.dripPipePipe_dripPipe.specs = this.dripPipePipeSample.specs;
    this.dripPipePipe_dripPipe.brands = this.dripPipePipeSample.brands;
  }

  /**
   * 규격 알고리즘: [점적관] 점적 연결 소켓 <- 없음
   */
  public algorithmSpec_dripPipe_dripConnectSocket(): void {}

  /**
   * 규격 알고리즘: [점적관(PE)] PE관 <- 점적관(PE) 샘플
   */
  public algorithmSpec_dripPePipe_pePipe(): void {
    this.dripPePipe_pePipe.specs = this.dripPePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [점적관(PE)] 엘디 연결 <- 없음
   */
  public algorithmSpec_dripPePipe_ldConnect(): void {}

  /**
   * 규격 알고리즘: [점적단추] 점적단추 <- 점적단추 샘플
   */
  public algorithmSpec_dripPePipe_dripButton(): void {
    this.dripPePipe_dripButton.specs = this.dripButtonSample.specs;
    this.dripPePipe_dripButton.brands = this.dripButtonSample.brands;
  }

  /**
   * 규격 알고리즘: [점적단추] 점적단추 <- 점적단추(튜브) 샘플
   */
  public algorithmSpec_dripPePipeTube_dripButton(): void {
    this.dripPePipeTube_dripButton.specs = this.dripButtonTubeSample.specs;
    this.dripPePipeTube_dripButton.brands = this.dripButtonTubeSample.brands;
  }

  /**
   * 규격 알고리즘: [점적단추] 점적단추 <- 점적단추(드롭퍼) 샘플
   */
  public algorithmSpec_dripPePipeDropper_dripButton(): void {
    this.dripPePipeDropper_dripButton.specs = this.dripButtonDropperSample.specs;
    this.dripPePipeDropper_dripButton.brands = this.dripButtonDropperSample.brands;
  }

  /**
   * 규격 알고리즘: [점적단추] 점적단추 매니홀드 <- 점적단추(드롭퍼) 샘플
   */
  public algorithmSpec_dripPePipeDropper_dripButtonManyHold(): void {
    if (this.dripButtonDropperSample.brandWateringQuantity === CONST.ITEM_SPEC_VALUE_2_LITER_PER_HOUR) {
      this.dripPePipeDropper_dripButtonManyHold.specType = CONST.ITEM_SPEC_VALUE_1_HOLE;
      this.dripPePipeDropper_dripButtonManyHold.specDiameter = CONST.ITEM_SPEC_VALUE_3_SEP_5_MM;
    } else if (this.dripButtonDropperSample.brandWateringQuantity === CONST.ITEM_SPEC_VALUE_4_LITER_PER_HOUR) {
      this.dripPePipeDropper_dripButtonManyHold.specType = CONST.ITEM_SPEC_VALUE_2_HOLE;
      this.dripPePipeDropper_dripButtonManyHold.specDiameter = CONST.ITEM_SPEC_VALUE_3_SEP_5_MM;
    } else if (this.dripButtonDropperSample.brandWateringQuantity === CONST.ITEM_SPEC_VALUE_8_LITER_PER_HOUR) {
      this.dripPePipeDropper_dripButtonManyHold.specType = CONST.ITEM_SPEC_VALUE_4_HOLE;
      this.dripPePipeDropper_dripButtonManyHold.specDiameter = CONST.ITEM_SPEC_VALUE_3_SEP_5_MM;
    } else {
      this.dripPePipeDropper_dripButtonManyHold.specType = CONST.ITEM_SPEC_VALUE_4_HOLE;
      this.dripPePipeDropper_dripButtonManyHold.specDiameter = CONST.ITEM_SPEC_VALUE_3_SEP_5_MM;
    }
  }

  /**
   * 규격 알고리즘: [점적단추] 튜브 <- 없음
   */
  public algorithmSpec_dripPePipe_tube(): void {}

  /**
   * 규격 알고리즘: [점적단추] 튜브 니플 <- 없음
   */
  public algorithmSpec_dripPePipe_tubeNipple(): void {}

  /**
   * 규격 알고리즘: [점적단추] 점적 드롭퍼 <- 없음
   */
  public algorithmSpec_dripPePipeDropper_dripButtonDropper(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
