import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";
import { SkyFramePartIL_Rafter } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePartIL_Rafter";
import { SkyFramePartIL_Straight } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePartIL_Straight";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-22
 */
@jsonObject
export class SkyFrameSampleIL_RafterPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const rafterPart: SkyFramePartIL_Rafter = <SkyFramePartIL_Rafter>this.part;
    rafterPart.algorithmSpec_skyRafter_pipe();
    rafterPart.algorithmSpec_skyRafter_pipeBendingExpense();
    rafterPart.algorithmSpec_skyRafterAndStraight_tHolder();
    rafterPart.algorithmSpec_skyRafterAndSkyColumn_diagonalTHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const straightPart: SkyFramePartIL_Straight = (<SkyFramePositionIL>this.position).straightPart;
    straightPart.skyStraightPipeSample.algorithmSpec();
    straightPart.algorithmSpec_skyStraightAndSkyRafter_steelStringGrabber();
    straightPart.algorithmSpec_skyStraightAndSkyRafter_tHolder();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 서까래 파이프 샘플(지붕 골조), 형태(위치), 천창 서까래 길이, 천창 기둥 길이
   */
  // @override
  public algorithmSpec(): void {
    let roofPosition: RoofPositionIL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPosition = this.struct.roofWorkIL.level1.roofPosition;

      // 하드코딩
      if (roofPosition.straightPart.straightPipeSample.specsList === "원형, 일반, 31.8mm, 1.7T") {
        this.specsList = "원형, 일반, 31.8mm, 1.5T";
      } else {
        this.specsList = roofPosition.straightPart.straightPipeSample.specsList;
      }
      if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH) {
        this.specLength = (<SkyFramePartIL_Rafter>this.part).rafterLength;
      } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE) {
        this.specLength =
          (<SkyFramePartIL_Rafter>this.part).rafterLength + (<SkyFramePartIL_Rafter>this.part).columnLength;
      } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
        this.specLength =
          (<SkyFramePartIL_Rafter>this.part).rafterLength + (<SkyFramePartIL_Rafter>this.part).columnLength;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
