import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { EndpiecePartIL_PrismMiddle } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_PrismMiddle";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-01-10
 */
@jsonObject
export class EndpieceSampleIL_PrismMiddleBottomPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismMiddlePart: EndpiecePartIL_PrismMiddle = <EndpiecePartIL_PrismMiddle>this.part;
    prismMiddlePart.algorithmSpec_middleBottom_pipe();
    prismMiddlePart.algorithmSpec_middleBottomAndMiddleBottom_connectionPin();
    prismMiddlePart.algorithmSpec_middleBottomAndColumnEdge_tClamp();
    prismMiddlePart.algorithmSpec_middleBottomAndColumnCenter_crossClamp();
    prismMiddlePart.algorithmSpec_middleBottomAndEndpieceStudBottom_crossClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevelIL>this.level).backPosition.prismMiddlePart.middleBottomPipeSample.specs = this.specs;
    }

    /// //////// 외부 ///////////

    // 피복 고정
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      fixingLevel.frontPosition.normalClipPart.normalClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      fixingLevel.backPosition.normalClipPart.normalClipSample.algorithmSpec();
    }

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      skirtLevel.frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      skirtLevel.backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 마구리 샛기둥(하단) 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = (<EndpiecePositionIL>this.position).prismStudSepPart.studBottomPipeSample.specsList;
  }

  /**
   * 규격 알고리즘 <- 폭(기본정보), 보 파이프 샘플(기둥 골조)
   */
  public algorithmSpec_specLength(): void {
    if (this.basicLevel.width === 6 || this.basicLevel.width === 7) {
      this.specLength = this.basicLevel.width;
    } else {
      this.specLength = this.struct.columnWorkIL.level1.columnPosition.prismBeamPart.beamPipeSample.specLength;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
