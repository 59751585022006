import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemCordWire } from "vhows-design/src/object/design/item/list/ItemCordWire";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { SwitcherSample_AxisPipe } from "vhows-design/src/object/design/switches/switcher/SwitcherSample_AxisPipe";
import { SwitcherSample_AxisPipe2 } from "vhows-design/src/object/design/switches/switcher/SwitcherSample_AxisPipe2";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-21
 */
@jsonObject({
  knownTypes: [SwitcherSample_AxisPipe, SwitcherSample_AxisPipe2],
})
export class SwitcherPart_Axis extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public coverLevel: CoverLevel; // 기초 피복 중수

  // 샘플
  public axisPipeSample: SwitcherSample_AxisPipe; // 개폐 축 파이프 샘플
  public axisPipe2Sample: SwitcherSample_AxisPipe2; // 개폐 축 파이프 샘플(2차)

  // 아이템
  public axis_pipe: ItemPipe;
  public axis_pinnedPipe: ItemPipe; // 사용 안함
  public axis_connectionPin: ItemConnector;
  public axis2_pipe: ItemPipe;
  public axis2_pinnedPipe: ItemPipe; // 사용 안함
  public axis2_connectionPin: ItemConnector;
  public axisHolder_saddleHolder: ItemHolder;
  public coverFixing_pipe: ItemPipe;
  public coverFixing_connectionPin: ItemConnector;
  public lineTow_ripcord: ItemCordWire;
  public axis_screw: ItemScrew;
  public axis_slopeClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 개폐 최대 차수 - 뷰의 데이터변경 이벤트 호출용 변수 */
  public switchOrderMax: number;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _axisLength: number; // 개폐 축 길이
  @jsonMember(Boolean)
  public _slopeClipSelectedRoof: boolean; // 기울기 클립 선택(지붕)
  @jsonMember(Number)
  public _towLineInterval: number; // 예인선 간격

  /**
   * 개폐 축 길이
   */
  public get axisLength(): number {
    return this._axisLength;
  }

  public set axisLength(value: number) {
    this._axisLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기울기 클립 선택(지붕)
   */
  public get slopeClipSelectedRoof(): boolean {
    return this._slopeClipSelectedRoof;
  }

  //
  public set slopeClipSelectedRoof(value: boolean) {
    this._slopeClipSelectedRoof = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 예인선 간격
   */
  public get towLineInterval(): number {
    return this._towLineInterval;
  }

  //
  public set towLineInterval(value: number) {
    this._towLineInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.axisPipeSample = new SwitcherSample_AxisPipe();
    this.axisPipe2Sample = new SwitcherSample_AxisPipe2();

    this.sampleAC = [this.axisPipeSample];

    // 아이템
    this.axis_pipe = new ItemPipe();
    this.axis_pinnedPipe = new ItemPipe();
    this.axis_connectionPin = new ItemConnector();
    this.axis2_pipe = new ItemPipe();
    this.axis2_pinnedPipe = new ItemPipe();
    this.axis2_connectionPin = new ItemConnector();
    this.axisHolder_saddleHolder = new ItemHolder();
    this.coverFixing_pipe = new ItemPipe();
    this.coverFixing_connectionPin = new ItemConnector();
    this.lineTow_ripcord = new ItemCordWire();
    this.axis_screw = new ItemScrew();
    this.axis_slopeClip = new ItemClip();

    this.itemAC = [
      this.axis_pipe,
      this.axis_pinnedPipe,
      this.axis_connectionPin,
      this.axis2_pipe,
      this.axis2_pinnedPipe,
      this.axis2_connectionPin,
      this.axisHolder_saddleHolder,
      this.coverFixing_pipe,
      this.coverFixing_connectionPin,
      this.lineTow_ripcord,
      this.axis_screw,
      this.axis_slopeClip,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.axisPipeSample = <SwitcherSample_AxisPipe>this.sampleAC[0];
    this.axisPipe2Sample = <SwitcherSample_AxisPipe2>this.sampleAC[1];

    // 아이템
    this.axis_pipe = <ItemPipe>this.itemAC[0];
    this.axis_pinnedPipe = <ItemPipe>this.itemAC[1];
    this.axis_connectionPin = <ItemConnector>this.itemAC[2];
    this.axis2_pipe = <ItemPipe>this.itemAC[3];
    this.axis2_pinnedPipe = <ItemPipe>this.itemAC[4];
    this.axis2_connectionPin = <ItemConnector>this.itemAC[5];
    this.axisHolder_saddleHolder = <ItemHolder>this.itemAC[6];
    this.coverFixing_pipe = <ItemPipe>this.itemAC[7];
    this.coverFixing_connectionPin = <ItemConnector>this.itemAC[8];
    this.lineTow_ripcord = <ItemCordWire>this.itemAC[9];
    this.axis_screw = <ItemScrew>this.itemAC[10];
    this.axis_slopeClip = <ItemClip>this.itemAC[11];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param slopeClipSelectedRoof: boolean 기울기 클립 선택(지붕)
   * @param towLineInterval: number 예인선 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    slopeClipSelectedRoof: boolean = false,
    towLineInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._slopeClipSelectedRoof = slopeClipSelectedRoof;
    this._towLineInterval = towLineInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.axisPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.axisPipe2Sample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.axis_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.axis_pinnedPipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축",
        "원형, 핀가공, 25.4mm, 1.5T, 6m, -",
      );
      this.axis_connectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "개폐 축",
        "원형, 일반, 25mm, 25mm",
      );
      this.axis2_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.axis2_pinnedPipe.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 핀가공, 25.4mm, 1.5T, 6m, -",
      );
      this.axis2_connectionPin.setDefaultData(
        5,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "개폐 축(2차)",
        "원형, 일반, 25mm, 25mm",
      );
      this.axisHolder_saddleHolder.setDefaultData(
        6,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "커튼 개폐 축",
        "원형, 일반, 32mm, -",
      );
      this.coverFixing_pipe.setDefaultData(
        7,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 피복 고정",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.coverFixing_connectionPin.setDefaultData(
        8,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 피복 고정",
        "원형, 일반, 25mm, 25mm",
      );
      this.lineTow_ripcord.setDefaultData(
        9,
        CONST.ITEM_ID_RIPCORD,
        CONST.ITEM_NAME_RIPCORD,
        "커튼 예인선",
        "낙하산줄, 실, 4mm, 1500m",
      );
      this.axis_screw.setDefaultData(
        10,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "개폐 축",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.axis_slopeClip.setDefaultData(
        11,
        CONST.ITEM_ID_SLOPE_CLIP,
        CONST.ITEM_NAME_SLOPE_CLIP,
        "개폐 축",
        "플라스틱, 25mm",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.init_coverLevel();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      // 개폐 축 파이프 샘플(2차) 추가
      const axisPipe2Sample: SwitcherSample_AxisPipe2 = new SwitcherSample_AxisPipe2();
      axisPipe2Sample.setAssociation(design, struct, work, level, position, this);
      axisPipe2Sample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.sampleAC.push(axisPipe2Sample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    // 아이템
    if (this.itemAC.length === 5 && this.itemAC[3] instanceof ItemScrew) {
      // [개폐 축(2차)] 파이프 추가
      const axis2_pipe: ItemPipe = new ItemPipe();
      axis2_pipe.setAssociation(design, struct, work, level, position, this);
      axis2_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.itemAC.splice(3, 0, axis2_pipe);
      // [개폐 축(2차)] 파이프 추가
      const axis2_pinnedPipe: ItemPipe = new ItemPipe();
      axis2_pinnedPipe.setAssociation(design, struct, work, level, position, this);
      axis2_pinnedPipe.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 핀가공, 25.4mm, 1.5T, 6m, -",
      );
      this.itemAC.splice(4, 0, axis2_pinnedPipe);
      // [개폐 축(2차)] 연결핀 추가
      const axis2_connectionPin: ItemConnector = new ItemConnector();
      axis2_connectionPin.setAssociation(design, struct, work, level, position, this);
      axis2_connectionPin.setDefaultData(
        5,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "개폐 축(2차)",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(5, 0, axis2_connectionPin);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 8 && this.itemAC[6] instanceof ItemScrew) {
      // [커튼 개폐 축] 새들 고정구 추가
      const axisHolder_saddleHolder: ItemHolder = new ItemHolder();
      axisHolder_saddleHolder.setAssociation(design, struct, work, level, position, this);
      axisHolder_saddleHolder.setDefaultData(
        6,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "커튼 개폐 축",
        "원형, 일반, 32mm, -",
      );
      axisHolder_saddleHolder.selected = false;
      axisHolder_saddleHolder.visible = false;
      this.itemAC.splice(6, 0, axisHolder_saddleHolder);
      // [커튼 피복 고정] 파이프 추가
      const coverFixing_pipe: ItemPipe = new ItemPipe();
      coverFixing_pipe.setAssociation(design, struct, work, level, position, this);
      coverFixing_pipe.setDefaultData(
        7,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 피복 고정",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      coverFixing_pipe.selected = false;
      coverFixing_pipe.visible = false;
      this.itemAC.splice(7, 0, coverFixing_pipe);
      // [커튼 피복 고정] 연결핀 추가
      const coverFixing_connectionPin: ItemConnector = new ItemConnector();
      coverFixing_connectionPin.setAssociation(design, struct, work, level, position, this);
      coverFixing_connectionPin.setDefaultData(
        8,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 피복 고정",
        "원형, 일반, 25mm, 25mm",
      );
      coverFixing_connectionPin.selected = false;
      coverFixing_connectionPin.visible = false;
      this.itemAC.splice(8, 0, coverFixing_connectionPin);
      // [커튼 예인선] 낙하산 줄 추가
      const lineTow_ripcord: ItemCordWire = new ItemCordWire();
      lineTow_ripcord.setAssociation(design, struct, work, level, position, this);
      lineTow_ripcord.setDefaultData(
        9,
        CONST.ITEM_ID_RIPCORD,
        CONST.ITEM_NAME_RIPCORD,
        "커튼 예인선",
        "낙하산줄, 실, 4mm, 1500m",
      );
      lineTow_ripcord.selected = false;
      lineTow_ripcord.visible = false;
      this.itemAC.splice(9, 0, lineTow_ripcord);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_axisLength();
    this.algorithm_slopeClipSelectedRoof();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const switcherPosition: SwitcherPosition = <SwitcherPosition>this.position;
    this.switchOrderMax = switcherPosition.switchOrderMax;
    // 외부
    const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;

    /// //////// 선택, 가시성 ///////////

    this.axis_pipe.checkSelected(true);
    this.axis_pinnedPipe.checkSelected(false);
    this.axis_connectionPin.checkSelected(true);
    this.axis2_pinnedPipe.checkSelected(false);
    if (switcherPosition.switchOrderMax === CONST.VL_SWITCHER_ORDER_2) {
      this.axis2_pipe.checkSelected(true);
      this.axis2_connectionPin.checkSelected(true);
    } else {
      this.axis2_pipe.checkSelected(false);
      this.axis2_connectionPin.checkSelected(false);
    }
    if (switcherPosition.type === CONST.LB_SWITCHER_TYPE_POWER_WRAP) {
      this.axisHolder_saddleHolder.selected = false;
      this.axisHolder_saddleHolder.visible = false;
      this.coverFixing_pipe.checkSelected(false);
      this.coverFixing_connectionPin.checkSelected(false);
      this.lineTow_ripcord.selected = false;
      this.lineTow_ripcord.visible = false;
    } else if (switcherPosition.type === CONST.LB_SWITCHER_TYPE_POWER_TOW) {
      this.axisHolder_saddleHolder.selected = true;
      this.axisHolder_saddleHolder.visible = true;
      this.coverFixing_pipe.checkSelected(true);
      this.coverFixing_connectionPin.checkSelected(true);
      this.lineTow_ripcord.selected = true;
      this.lineTow_ripcord.visible = true;
    }
    this.axis_screw.selected = true;
    this.axis_screw.visible = true;
    this.axis_slopeClip.selected = this.slopeClipSelectedRoof;
    this.axis_slopeClip.visible = this.slopeClipSelectedRoof;

    /// //////// 수량 ///////////

    if (switcherPosition.label === CONST.LB_POSITION_SIDE || switcherPosition.label === CONST.LB_POSITION_ROOF) {
      // 1차
      this.axis_pipe.designQuantity =
        (this.axis_pipe.getConnectedPipeLength(
          this.axisLength,
          switcherPosition.switchWayValue * this.basic.buildingNumber,
          this.axis_pipe.specLength / 2,
        ) /
          this.axis_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;
      this.axis_pinnedPipe.designQuantity = 0;

      if (this.axisLength > this.axisPipeSample.specLength) {
        this.axis_connectionPin.designQuantity =
          this.axis_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
      } else {
        this.axis_connectionPin.designQuantity = 0;
      }

      // 2차
      this.axis2_pipe.designQuantity =
        (this.axis2_pipe.getConnectedPipeLength(
          this.axisLength,
          switcherPosition.switchWayValue * this.basic.buildingNumber,
          this.axis2_pipe.specLength / 2,
        ) /
          this.axis2_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;
      this.axis2_pinnedPipe.designQuantity = 0;

      if (this.axisLength > this.axisPipe2Sample.specLength) {
        this.axis2_connectionPin.designQuantity =
          this.axis2_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
      } else {
        this.axis2_connectionPin.designQuantity = 0;
      }

      // 예인식
      this.axisHolder_saddleHolder.designQuantity =
        Math.ceil(this.axisLength / this.towLineInterval + 1) *
        switcherPosition.switchWayValue *
        this.basic.buildingNumber;

      this.coverFixing_pipe.designQuantity =
        (this.coverFixing_pipe.getConnectedPipeLength(
          this.axisLength,
          switcherPosition.switchWayValue * this.basic.buildingNumber,
          this.coverFixing_pipe.specLength / 2,
        ) /
          this.coverFixing_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;

      if (this.axisLength > this.coverFixing_pipe.specLength) {
        this.coverFixing_connectionPin.designQuantity =
          this.coverFixing_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
      } else {
        this.coverFixing_connectionPin.designQuantity = 0;
      }

      this.lineTow_ripcord.designQuantity =
        (((this.basicLevel.heightShoulder + 1) *
          Math.ceil(this.basicLevel.length / this.towLineInterval + 1) *
          switcherPosition.switchWayValue *
          this.basic.buildingNumber) /
          this.lineTow_ripcord.specLength) *
        CONST.NUM_EXTRA_RATE_RIPCORD;

      if (switcherPosition.switchStep === CONST.VL_SWITCHER_ORDER_2) {
        // 1차
        this.axis_pipe.designQuantity *= switcherPosition.switchStep;
        this.axis_pinnedPipe.designQuantity *= switcherPosition.switchStep;

        if (this.axisLength > this.axisPipeSample.specLength) {
          this.axis_connectionPin.designQuantity =
            this.axis_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
        } else {
          this.axis_connectionPin.designQuantity = 0;
        }

        // 2차
        this.axis2_pipe.designQuantity *= switcherPosition.switchStep;
        this.axis2_pinnedPipe.designQuantity *= switcherPosition.switchStep;

        if (this.axisLength > this.axisPipe2Sample.specLength) {
          this.axis2_connectionPin.designQuantity =
            this.axis2_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
        } else {
          this.axis2_connectionPin.designQuantity = 0;
        }

        this.axisHolder_saddleHolder.designQuantity *= switcherPosition.switchStep;

        this.coverFixing_pipe.designQuantity *= switcherPosition.switchStep;

        this.coverFixing_connectionPin.designQuantity *= switcherPosition.switchStep;

        this.lineTow_ripcord.designQuantity *= switcherPosition.switchStep;

        this.axis_screw.designQuantity *= switcherPosition.switchStep;
      }
    } else if (
      switcherPosition.label === CONST.LB_POSITION_FRONT ||
      switcherPosition.label === CONST.LB_POSITION_BACK
    ) {
      // 1차
      this.axis_pipe.designQuantity =
        (this.axis_pipe.getConnectedPipeLength(
          this.axisLength,
          switcherPosition.switchWayValue * this.basic.buildingNumber,
          this.axis_pipe.specLength / 2,
        ) /
          this.axis_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;
      this.axis_pinnedPipe.designQuantity = 0;

      this.axis_connectionPin.designQuantity = 0;

      // 2차
      this.axis2_pipe.designQuantity =
        (this.axis2_pipe.getConnectedPipeLength(
          this.axisLength,
          switcherPosition.switchWayValue * this.basic.buildingNumber,
          this.axis2_pipe.specLength / 2,
        ) /
          this.axis2_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;
      this.axis2_pinnedPipe.designQuantity = 0;

      this.axis2_connectionPin.designQuantity = 0;

      // 예인선
      this.axisHolder_saddleHolder.designQuantity =
        Math.ceil(this.axisLength / this.towLineInterval + 1) * this.basic.buildingNumber;

      this.coverFixing_pipe.designQuantity =
        (this.coverFixing_pipe.getConnectedPipeLength(
          this.axisLength,
          this.basic.buildingNumber,
          this.coverFixing_pipe.specLength / 2,
        ) /
          this.coverFixing_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;

      if (this.axisLength > this.coverFixing_pipe.specLength) {
        this.coverFixing_connectionPin.designQuantity =
          this.coverFixing_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
      } else {
        this.coverFixing_connectionPin.designQuantity = 0;
      }

      this.lineTow_ripcord.designQuantity =
        (((this.basicLevel.heightShoulder + 1) *
          Math.ceil(this.basicLevel.width / this.towLineInterval + 1) *
          this.basic.buildingNumber) /
          this.lineTow_ripcord.specLength) *
        CONST.NUM_EXTRA_RATE_RIPCORD;
    }

    this.axis_screw.designQuantity =
      ((this.axis_connectionPin.getSelectedQuantity() * 4 + this.axis2_connectionPin.getSelectedQuantity() * 4) /
        this.axis_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    let slopeClipRafterInterval: number; // 기울기 클립이 설치될 서까래 간격
    if (this.level.index === 0) {
      slopeClipRafterInterval = 3;
    } else {
      slopeClipRafterInterval = 2;
    }
    this.axis_slopeClip.designQuantity =
      (Math.ceil(this.basicLevel.length / (trunkPosition.rafterPart.rafterInterval * slopeClipRafterInterval)) + 1) *
      switcherPosition.switchWayValue *
      this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 개폐 축 길이 <- 길이(기본정보), 폭(기본정보)
   */
  public algorithm_axisLength(): void {
    if (this.position.label === CONST.LB_POSITION_SIDE || this.position.label === CONST.LB_POSITION_ROOF) {
      this.axisLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
    } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
      this.axisLength = this.basicLevel.width + this.design.preference.connectedPipeExtraLength;
    }
  }

  /**
   * 알고리즘: 기울기 클립 선택(지붕) <- 기본정보, 개폐 방향
   */
  public algorithm_slopeClipSelectedRoof(): void {
    if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_ROOF) {
      if (
        (<SwitcherPosition>this.position).switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE &&
        this.design.preference.slopClipFlag === true
      ) {
        this.slopeClipSelectedRoof = true;
      } else {
        this.slopeClipSelectedRoof = false;
      }
    } else {
      this.slopeClipSelectedRoof = false;
    }
  }

  /**
   * 알고리즘: 예인선 간격 <- 서까래 간격
   */
  public algorithm_towLineInterval(): void {
    const trunkPosition: TrunkPosition = (this.struct.trunkWork.levelAC[this.level.index] as TrunkLevel).trunkPosition;

    if (trunkPosition.rafterPart.rafterInterval >= 1) {
      this.towLineInterval = trunkPosition.rafterPart.rafterInterval;
    } else {
      this.towLineInterval = trunkPosition.rafterPart.rafterInterval * 2;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [개폐 축] 파이프 <- 개페 축 파이프 샘플
   */
  public algorithmSpec_axis_pipe(): void {
    this.axis_pipe.specs = this.axisPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [개폐 축] 연결핀 <- 개페 축 파이프 샘플
   */
  public algorithmSpec_axis_connectionPin(): void {
    this.axis_connectionPin.specPipeType = this.axisPipeSample.specPipeType;
    this.axis_connectionPin.specCrossSize1 = this.axisPipeSample.specCrossSize;
    this.axis_connectionPin.specCrossSize2 = this.axisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [개폐 축(2차)] 파이프 <- 개페 축 파이프 샘플(2차)
   */
  public algorithmSpec_axis2_pipe(): void {
    this.axis2_pipe.specs = this.axisPipe2Sample.specs;
  }

  /**
   * 규격 알고리즘: [개폐 축(2차)] 연결핀 <- 개페 축 파이프 샘플(2차)
   */
  public algorithmSpec_axis2_connectionPin(): void {
    this.axis2_connectionPin.specPipeType = this.axisPipe2Sample.specPipeType;
    this.axis2_connectionPin.specCrossSize1 = this.axisPipe2Sample.specCrossSize;
    this.axis2_connectionPin.specCrossSize2 = this.axisPipe2Sample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 개폐 축] 새들 고정구 <- 개폐 축 파이프 샘플(하나 큰 사이즈)
   */
  public algorithmSpec_axisHolder_saddleHolder(): void {
    this.axisHolder_saddleHolder.specPipeType = this.axisPipeSample.specPipeType;
    // 하드코딩
    if (this.axisPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM) {
      this.axisHolder_saddleHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_25_MM;
    } else if (this.axisPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM) {
      this.axisHolder_saddleHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_32_MM;
    } else if (this.axisPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM) {
      this.axisHolder_saddleHolder.specCrossSize1 = CONST.ITEM_SPEC_VALUE_48_MM;
    }
    this.axisHolder_saddleHolder.specCrossSize2 = CONST.ITEM_SPEC_VALUE_NONE;
  }

  /**
   * 규격 알고리즘: [커튼 피복 고정] 파이프 <- 없음
   */
  public algorithmSpec_coverFixing_pipe(): void {}

  /**
   * 규격 알고리즘: [커튼 피복 고정] 연결핀 <- 없음
   */
  public algorithmSpec_coverFixing_connectionPin(): void {}

  /**
   * 규격 알고리즘: [커튼 예인선] 낙하산 줄 <- 없음
   */
  public algorithmSpec_lineTow_ripcord(): void {}

  /**
   * 규격 알고리즘: [개폐 축] 나사 <- 공통 샘플, 개페 축 파이프 샘플, 개페 축 파이프 샘플(2차)
   */
  public algorithmSpec_axis_screw(): void {
    this.axis_screw.specs = this.design.preference.defaultScrewCrossNormal;
  }

  /**
   * 규격 알고리즘: [개폐 축] 기울기 클립 <- 서까래 파이프 샘플
   */
  public algorithmSpec_axis_slopeClip(): void {
    const trunkLevel: TrunkLevel = <TrunkLevel>this.struct.trunkWork.levelAC[this.level.index];
    this.axis_slopeClip.specCrossSize = trunkLevel.trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 초기화: 기초 피복 중수
   */
  protected init_coverLevel(): void {
    this.coverLevel = <CoverLevel>this.struct.coverWork.levelAC[this.level.index];
  }
}
