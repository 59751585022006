import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { RailPart_Carrier } from "vhows-design/src/object/design/other/rail/RailPart_Carrier";
import { RailPart_GroundRail } from "vhows-design/src/object/design/other/rail/RailPart_GroundRail";
import { RailPart_SideRail } from "vhows-design/src/object/design/other/rail/RailPart_SideRail";
import { RailPart_UpperRail } from "vhows-design/src/object/design/other/rail/RailPart_UpperRail";
import { MaterialPart_Custom } from "vhows-design/src/object/design/other/material/MaterialPart_Custom";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-10-29
 */
@jsonObject({
  knownTypes: [RailPart_Carrier, RailPart_GroundRail, RailPart_SideRail, RailPart_UpperRail, MaterialPart_Custom],
})
export class RailPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public upperRailPart: RailPart_UpperRail;
  public sideRailPart: RailPart_SideRail;
  public groundRailPart: RailPart_GroundRail;
  public carrierPart: RailPart_Carrier;
  public customPart: MaterialPart_Custom;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  get totalRailLength(): number {
    let totalRailLength: number = 0;

    if (this.type === CONST.LB_RAIL_TYPE_UPPER_SINGLE) {
      totalRailLength += this.upperRailPart.totalRailLength;
    }
    if (this.sideType === CONST.LB_RAIL_TYPE_SIDE_SINGLE) {
      totalRailLength += this.sideRailPart.totalRailLength;
    }
    if (this.groundType === CONST.LB_RAIL_TYPE_GROUND_DOUBLE || this.type === CONST.LB_RAIL_TYPE_GROUND_SINGLE) {
      totalRailLength += this.groundRailPart.totalRailLength;
    }
    return totalRailLength;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _sideType: string; // 측면 형태
  @jsonMember(String)
  public _groundType: string; // 지면 형태
  @jsonMember(Number)
  public _upperRailBuildingNumber: number; // 상단 레일 동수
  @jsonMember(Number)
  public _sideRailBuildingNumber: number; // 측면 레일 동수
  @jsonMember(Number)
  public _groundRailBuildingNumber: number; // 지면 레일 동수
  @jsonMember(Boolean)
  public _carrierSelected: boolean; // 운반구 선택
  @jsonMember(Boolean)
  public _customSelected: boolean; // 임의 상품 선택

  /**
   * 측면 형태
   */
  public get sideType(): string {
    return this._sideType;
  }
  //
  public set sideType(value: string) {
    this._sideType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationBySideType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  /**
   * 지면 형태
   */
  public get groundType(): string {
    return this._groundType;
  }
  //
  public set groundType(value: string) {
    this._groundType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByGroundType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  /**
   * 상단 레일 동수
   */
  public get upperRailBuildingNumber(): number {
    return this._upperRailBuildingNumber;
  }
  //
  public set upperRailBuildingNumber(value: number) {
    this._upperRailBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.upperRailPart.algorithm_totalRailLineNumber();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  /**
   * 측면 레일 동수
   */
  public get sideRailBuildingNumber(): number {
    return this._sideRailBuildingNumber;
  }
  //
  public set sideRailBuildingNumber(value: number) {
    this._sideRailBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.sideRailPart.algorithm_totalRailLineNumber();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  /**
   * 지면 레일 동수
   */
  public get groundRailBuildingNumber(): number {
    return this._groundRailBuildingNumber;
  }
  //
  public set groundRailBuildingNumber(value: number) {
    this._groundRailBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.groundRailPart.algorithm_totalRailLineNumber();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  /**
   * 운반구 선택
   */
  public get carrierSelected(): boolean {
    return this._carrierSelected;
  }
  //
  public set carrierSelected(value: boolean) {
    this._carrierSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByCarrierSelected();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  /**
   * 임의 상품 선택
   */
  public get customSelected(): boolean {
    return this._customSelected;
  }
  //
  public set customSelected(value: boolean) {
    this._customSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByCustomSelected();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }
  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }
  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.upperRailPart = new RailPart_UpperRail();
    this.sideRailPart = new RailPart_SideRail();
    this.groundRailPart = new RailPart_GroundRail();
    this.carrierPart = new RailPart_Carrier();
    this.customPart = new MaterialPart_Custom();

    this.partAC = [this.upperRailPart, this.sideRailPart, this.groundRailPart, this.carrierPart, this.customPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.upperRailPart = <RailPart_UpperRail>this.partAC[0];
    this.sideRailPart = <RailPart_SideRail>this.partAC[1];
    this.groundRailPart = <RailPart_GroundRail>this.partAC[2];
    this.carrierPart = <RailPart_Carrier>this.partAC[3];
    this.customPart = <MaterialPart_Custom>this.partAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param upperRailBuildingNumber: number 상단 레일 동수
   * @param sideType: string 측면 형태
   * @param sideRailBuildingNumber: number 측면 레일 동수
   * @param groundType: string 지면 형태
   * @param groundRailBuildingNumber: number 지면 레일 동수
   * @param carrierSelected: boolean 운반구 선택
   * @param customSelected: boolean 임의 상품 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    upperRailBuildingNumber: number = 0,
    sideType: string = "",
    sideRailBuildingNumber: number = 0,
    groundType: string = "",
    groundRailBuildingNumber: number = 0,
    carrierSelected: boolean = false,
    customSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._upperRailBuildingNumber = upperRailBuildingNumber;
    this._sideType = sideType;
    this._sideRailBuildingNumber = sideRailBuildingNumber;
    this._groundType = groundType;
    this._groundRailBuildingNumber = groundRailBuildingNumber;
    this._carrierSelected = carrierSelected;
    this._customSelected = customSelected;

    if (this.level.index >= 0) {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        // 상단 레일
        this.upperRailPart.setDefaultData(
          CONST.INDEX_CM_RAIL_UPPER,
          true,
          false,
          true,
          CONST.LB_RAIL_UPPER,
          "",
          1,
          1.0,
          CONST.LB_RAIL_HOOK_FIXED_FRAME_STRAIGHT,
          false,
        );
        // 측면 레일
        this.sideRailPart.setDefaultData(CONST.INDEX_CM_RAIL_SIDE, false, false, false, CONST.LB_RAIL_SIDE, "", 1, 0.6);
        // 지면 레일
        this.groundRailPart.setDefaultData(
          CONST.INDEX_CM_RAIL_GROUND,
          false,
          false,
          false,
          CONST.LB_RAIL_GROUND,
          "",
          1,
          1.5,
        );
        // 운반구
        this.carrierPart.setDefaultData(CONST.INDEX_CM_RAIL_CARRIER, true, false, true, CONST.LB_RAIL_CARRIER, "");
        // 임의 품목
        this.customPart.setDefaultData(CONST.INDEX_CM_RAIL_CUSTOM, false, false, false, CONST.LB_RAIL_CUSTOM, "");
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        // 상단 레일
        this.upperRailPart.setDefaultData(
          CONST.INDEX_CM_RAIL_UPPER,
          true,
          false,
          true,
          CONST.LB_RAIL_UPPER,
          "",
          1,
          2.0,
          CONST.LB_RAIL_HOOK_FIXED_FRAME_MIDDLE,
          false,
        );
        // 측면 레일
        this.sideRailPart.setDefaultData(CONST.INDEX_CM_RAIL_SIDE, false, false, false, CONST.LB_RAIL_SIDE, "", 1, 2.0);
        // 지면 레일
        this.groundRailPart.setDefaultData(
          CONST.INDEX_CM_RAIL_GROUND,
          false,
          false,
          false,
          CONST.LB_RAIL_GROUND,
          "",
          1,
          1.5,
        );
        // 운반구
        this.carrierPart.setDefaultData(CONST.INDEX_CM_RAIL_CARRIER, true, false, true, CONST.LB_RAIL_CARRIER, "");
        // 임의 품목
        this.customPart.setDefaultData(CONST.INDEX_CM_RAIL_CUSTOM, false, false, false, CONST.LB_RAIL_CUSTOM, "");
      }
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_upperRailBuildingNumber();
    this.algorithm_sideRailBuildingNumber();
    this.algorithm_groundRailBuildingNumber();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- (상단) 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_RAIL_TYPE_UPPER_SINGLE) {
      this.upperRailPart.selected = true;
      this.upperRailPart.visible = true;
    } else {
      this.upperRailPart.selected = false;
      this.upperRailPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 측면 형태
   */
  public algorithm_partActivationBySideType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.sideType === CONST.LB_RAIL_TYPE_SIDE_SINGLE) {
      this.sideRailPart.selected = true;
      this.sideRailPart.visible = true;
    } else {
      this.sideRailPart.selected = false;
      this.sideRailPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 지면 형태
   */
  public algorithm_partActivationByGroundType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.groundType === CONST.LB_RAIL_TYPE_GROUND_DOUBLE || this.groundType === CONST.LB_RAIL_TYPE_GROUND_SINGLE) {
      this.groundRailPart.selected = true;
      this.groundRailPart.visible = true;
    } else {
      this.groundRailPart.selected = false;
      this.groundRailPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 운반구 선택
   */
  public algorithm_partActivationByCarrierSelected(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    this.carrierPart.selected = this.carrierSelected;
    this.carrierPart.visible = this.carrierSelected;
  }

  /**
   * 알고리즘: 파트 활성화 <- 임의 상품 선택
   */
  public algorithm_partActivationByCustomSelected(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    this.customPart.selected = this.customSelected;
    this.customPart.visible = this.customSelected;
  }

  /**
   * 알고리즘: 상단 레일 동수 <- 하우스 동수
   */
  public algorithm_upperRailBuildingNumber(): void {
    this.upperRailBuildingNumber = this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 측면 레일 동수 <- 하우스 동수
   */
  public algorithm_sideRailBuildingNumber(): void {
    this.sideRailBuildingNumber = this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 지면 레일 동수 <- 하우스 동수
   */
  public algorithm_groundRailBuildingNumber(): void {
    this.groundRailBuildingNumber = this.basic.buildingNumber;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
