import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Work } from "vhows-design/src/object/design/base/Work";
import { SkyFrameLevel } from "vhows-design/src/object/design/skylight/frame/SkyFrameLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-18
 */
@jsonObject({
  knownTypes: [SkyFrameLevel],
})
export class SkyFrameWork extends Work {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public level1: SkyFrameLevel;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.level1 = new SkyFrameLevel();

    this.levelAC = [this.level1];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.level1 = <SkyFrameLevel>this.levelAC[0];

    super.setReferenceVariable();
  }

  // @override
  public setDefaultData(
    index: number = 0,
    depth: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    category: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, depth, selected, enabled, visible, label, category, buildNote);

    this.level1.setDefaultData(0, false, true, true, CONST.LB_LEVEL_WHOLE);
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
