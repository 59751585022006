import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { RoofPartIL_Rafter } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Rafter";
import { BandstringPartIL_Bandstring } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPartIL_Bandstring";
import { BandstringPartIL_Poly } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPartIL_Poly";
import { BandstringPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPartIL_FixingNormalPad";
import { BandstringPartIL_FixingHook } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPartIL_FixingHook";
import { RoofPartVL_Rafter } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Rafter";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [
    BandstringPartIL_FixingHook,
    BandstringPartIL_FixingNormalPad,
    BandstringPartIL_Poly,
    BandstringPartIL_Bandstring,
  ],
})
export class BandstringPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public bandstringPart: BandstringPartIL_Bandstring;
  public polyPart: BandstringPartIL_Poly;
  public fixingNormalPadPart: BandstringPartIL_FixingNormalPad;
  public fixingHookPart: BandstringPartIL_FixingHook;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _fixingType: string; // 고정 형태
  @jsonMember(Number)
  public _lineInterval: number; // 줄 간격
  @jsonMember(String)
  public _rafterBasedLineNumber: string; // 서까래기준 줄수
  @jsonMember(String)
  public _installLocation: string; // 설치 장소

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  //
  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 줄 간격
   */
  public get lineInterval(): number {
    return this._lineInterval;
  }

  //
  public set lineInterval(value: number) {
    this._lineInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    if (this.label === CONST.LB_POSITION_SIDE) {
      this.bandstringPart.algorithm_bandstringLength();
      this.polyPart.algorithm_bandstringLength();
    }
    this.bandstringPart.algorithm_totalBandstringLength();
    this.polyPart.algorithm_totalBandstringLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 서까래기준 줄수
   */
  public get rafterBasedLineNumber(): string {
    return this._rafterBasedLineNumber;
  }

  //
  public set rafterBasedLineNumber(value: string) {
    this._rafterBasedLineNumber = value;

    // 알고리즘
    this.algorithm_lineIntervalByRafter();
  }

  /**
   * 설치 장소
   */
  public get installLocation(): string {
    return this._installLocation;
  }

  //
  public set installLocation(value: string) {
    if (this._installLocation === value) return;

    this._installLocation = value;

    // 알고리즘

    // 파트 활성화

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_fixingTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.bandstringPart.bandstringSample.algorithmChangeSample();
    this.bandstringPart.algorithmProduct_bandstring_bandstring();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.bandstringPart = new BandstringPartIL_Bandstring();
    this.polyPart = new BandstringPartIL_Poly();
    this.fixingNormalPadPart = new BandstringPartIL_FixingNormalPad();
    this.fixingHookPart = new BandstringPartIL_FixingHook();

    this.partAC = [this.bandstringPart, this.polyPart, this.fixingNormalPadPart, this.fixingHookPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.bandstringPart = this.partAC[0] as BandstringPartIL_Bandstring;
    this.polyPart = this.partAC[1] as BandstringPartIL_Poly;
    this.fixingNormalPadPart = this.partAC[2] as BandstringPartIL_FixingNormalPad;
    this.fixingHookPart = this.partAC[3] as BandstringPartIL_FixingHook;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param installLocation: string 설치 장소
   * @param fixingType: string 고정형태
   * @param lineInterval: number 줄 간격
   * @param rafterBasedLineNumber: string 서까래기준 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    installLocation: string = "",
    fixingType: string = "",
    lineInterval: number = 0,
    rafterBasedLineNumber: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._installLocation = installLocation;
    this._fixingType = fixingType;
    this._lineInterval = lineInterval;
    this._rafterBasedLineNumber = rafterBasedLineNumber;

    if (this.level.index >= 0) {
      // 밴드끈
      this.bandstringPart.setDefaultData(
        CONST.INDEX_IL_BANDSTRING_BANDSTRING,
        true,
        false,
        true,
        CONST.LB_BANDSTRING_BANDSTRING,
        "",
      );

      // 폴리 밴드끈
      this.polyPart.setDefaultData(CONST.INDEX_IL_BANDSTRING_POLY, false, false, false, CONST.LB_BANDSTRING_POLY, "");

      // 밴드끈 고정 - 일반 패드 고정
      this.fixingNormalPadPart.setDefaultData(
        CONST.INDEX_IL_BANDSTRING_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_BANDSTRING_FIXING_NORMAL_PAD,
        "",
      );

      // 밴드끈 고정 - 고리 고정
      this.fixingHookPart.setDefaultData(
        CONST.INDEX_IL_BANDSTRING_FIXING_HOOK,
        false,
        false,
        false,
        CONST.LB_BANDSTRING_FIXING_HOOK,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (
      this.type === CONST.LB_BANDSTRING_TYPE_VINYL ||
      this.type === CONST.LB_BANDSTRING_TYPE_NET ||
      this.type === CONST.LB_BANDSTRING_TYPE_WEAVING
    ) {
      this.bandstringPart.selected = true;
      this.bandstringPart.visible = true;
      this.polyPart.selected = false;
      this.polyPart.visible = false;
    } else if (this.type === CONST.LB_BANDSTRING_TYPE_POLY) {
      this.bandstringPart.selected = false;
      this.bandstringPart.visible = false;
      this.polyPart.selected = true;
      this.polyPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_NORMAL_PAD) {
      this.fixingNormalPadPart.selected = true;
      this.fixingNormalPadPart.visible = true;
      this.fixingHookPart.selected = false;
      this.fixingHookPart.visible = false;
    } else if (this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_HOOK) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingHookPart.selected = true;
      this.fixingHookPart.visible = true;
    } else {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingHookPart.selected = false;
      this.fixingHookPart.visible = false;
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태
   */
  public algorithm_fixingTypeByType(): void {
    if (
      this.type === CONST.LB_BANDSTRING_TYPE_VINYL ||
      this.type === CONST.LB_BANDSTRING_TYPE_NET ||
      this.type === CONST.LB_BANDSTRING_TYPE_WEAVING
    ) {
      this.fixingType = CONST.LB_BANDSTRING_FIXING_TYPE_NORMAL_PAD;
    } else if (this.type === CONST.LB_BANDSTRING_TYPE_POLY) {
      this.fixingType = CONST.LB_BANDSTRING_FIXING_TYPE_HOOK;
    }
  }

  /**
   * 알고리즘: 줄 간격 <- 서까래 기준 줄수, 서까래 간격(지붕 골조)
   */
  public algorithm_lineIntervalByRafter(): void {
    let roofPart_Rafter: RoofPartIL_Rafter | RoofPartVL_Rafter;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPart_Rafter = this.struct.roofWorkIL.level1.roofPosition.rafterPart;
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofPart_Rafter = this.struct.roofWorkVL.level1.roofPosition.rafterPart;
    }
    let interval: number = 2;

    if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_1) {
      interval = roofPart_Rafter.rafterInterval;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_2_1) {
      interval = roofPart_Rafter.rafterInterval * 2;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_3_1) {
      interval = roofPart_Rafter.rafterInterval * 3;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_4_1) {
      interval = roofPart_Rafter.rafterInterval * 4;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_5_1) {
      interval = roofPart_Rafter.rafterInterval * 5;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_6_1) {
      interval = roofPart_Rafter.rafterInterval * 6;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_8_1) {
      interval = roofPart_Rafter.rafterInterval * 8;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_10_1) {
      interval = roofPart_Rafter.rafterInterval * 10;
    }

    this.lineInterval = CommonUtil.roundUp(interval, 1);
  }

  /**
   * 알고리즘: 형태 <- 선택(천창 골조)
   */
  public algorithm_typeBySkylight(): void {
    if (this.struct.skyFrameWorkIL.level1.skylightPosition.selected === true) {
      this.type = CONST.LB_BANDSTRING_TYPE_VINYL;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
