import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { ColumnPartIL_PrismBeam } from "vhows-design/src/object/design/frame/column/ColumnPartIL_PrismBeam";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-15
 */
@jsonObject
export class ColumnSampleIL_PrismBeamPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismBeamPart: ColumnPartIL_PrismBeam = <ColumnPartIL_PrismBeam>this.part;
    prismBeamPart.algorithmSpec_beam_pipe();
    prismBeamPart.algorithmSpec_beamAndBeam_connectionPin();
    prismBeamPart.algorithmSpec_beamAndColumn_tClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    (<ColumnPositionIL>this.position).prismColumnPart.assistColumnPipeSample.algorithmSpec_specLength();
    (<ColumnPositionIL>this.position).prismColumnPart.algorithmSpec_assistColumnAndBeam_tClamp();
    (<ColumnPositionIL>this.position).prismColumnPart.columnBracingPipeSample.algorithmSpec_specLength();
    (<ColumnPositionIL>this.position).prismMiddlePart.middleSupportNormalPipeSample.algorithmSpec();

    /// //////// 외부 ///////////

    if (this.position.type === CONST.LB_COLUMN_TYPE_PRISM) {
      // 지붕 골조
      for (const roofLevel of this.struct.roofWorkIL.levelAC as RoofLevelIL[]) {
        // 모든 중수
        roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce();
        roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamEdgePrism_outerWallClamp();
      }

      // 마구리 골조
      for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) {
        // 모든 중수
        for (const endpiecePosition of endpieceLevel.positionAC as EndpiecePositionIL[]) {
          endpiecePosition.prismMiddlePart.middleBottomPipeSample.algorithmSpec_specLength();
        }
      }

      // 방풍벽 골조
      const wingLevel1: WingLevelIL = this.struct.wingWorkIL.level1;
      wingLevel1.wingPosition.wingRafterPart.algorithmSpec_wingRafterFirstAndBeam_outerWallClamp();
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = (<ColumnPositionIL>this.position).prismColumnPart.columnPipeSample.specsList;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
