import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { PartitionLevel } from "vhows-design/src/object/design/other/partition/PartitionLevel";
import { PartitionPosition } from "vhows-design/src/object/design/other/partition/PartitionPosition";
import { WindowLevel } from "vhows-design/src/object/design/other/window/WindowLevel";
import { ScreennetPosition } from "vhows-design/src/object/design/cover/screennet/ScreennetPosition";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { WindbreakPosition } from "vhows-design/src/object/design/cover/windbreak/WindbreakPosition";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";
import { ScreennetPart_FixingLaggingPad } from "vhows-design/src/object/design/cover/screennet/ScreennetPart_FixingLaggingPad";
import { SkirtPart_FixingLaggingPad } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingLaggingPad";
import { WindbreakPart_FixingLaggingPad } from "vhows-design/src/object/design/cover/windbreak/WindbreakPart_FixingLaggingPad";
import { FixingPart_LaggingPad } from "vhows-design/src/object/design/cover/fixing/FixingPart_LaggingPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-26
 */
@jsonObject
export class FixingSample_LaggingPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const laggingPadPart: FixingPart_LaggingPad = <FixingPart_LaggingPad>this.part;
    laggingPadPart.algorithmSpec_laggingPad_laggingPad();
    laggingPadPart.algorithmSpec_laggingPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<FixingPosition>this.level.positionAC[p]).laggingPadPart.laggingPadSample.specs = this.specs;
    }

    // 다른 중수
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        // 해당 중수의 측면 위치만 바꿔줘도 전체 위치의 규격이 바뀌게됨
        (<FixingLevel>this.work.levelAC[l]).sidePosition.laggingPadPart.laggingPadSample.specs = this.specs;
      }
    }

    /// //////// 외부 ///////////

    // 개폐기
    // const switcherPart_FixingLaggingPad: SwitcherPart_FixingLaggingPad = (<SwitcherPosition>this.struct.switcherWork.levelAC[this.level.index].positionAC[this.position.index]).fixingLaggingPadPart;
    // switcherPart_FixingLaggingPad.laggingPadSample.algorithmSpec();

    // 치마 피복
    const skirtPart_FixingLaggingPad: SkirtPart_FixingLaggingPad = (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingLaggingPadPart;
    skirtPart_FixingLaggingPad.laggingPadSample.algorithmSpec();

    // 바람막이 피복
    const windbreakPart_FixingLaggingPad: WindbreakPart_FixingLaggingPad = (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingLaggingPadPart;
    windbreakPart_FixingLaggingPad.laggingPadSample.algorithmSpec();

    // 방충망
    if (this.level.index === 0) {
      const screennetPart_FixingLaggingPad: ScreennetPart_FixingLaggingPad = (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.position.index]
      )).fixingLaggingPadPart;
      screennetPart_FixingLaggingPad.laggingPadSample.algorithmSpec();
    }

    // 창문
    if (this.level.index === 0) {
      const windowLevel: WindowLevel = <WindowLevel>this.struct.windowWork.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_SIDE) {
        windowLevel.leftPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
        windowLevel.rightPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        windowLevel.frontPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        windowLevel.backPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
      }
    }

    // 칸막이
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        const partitionPosition: PartitionPosition = (<PartitionLevel>(
          this.struct.partitionWork.levelAC[this.level.index]
        )).trunkPosition;
        partitionPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
