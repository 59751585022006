import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { ProductCompanyModel } from "vhows-design/src/object/item/product/ProductCompanyModel";
import { BrandModel } from "vhows-design/src/object/item/brand/BrandModel";
import { UnitPriceModel } from "vhows-design/src/object/item/unitprice/UnitPriceModel";
import { OptionUnitPriceModel } from "vhows-design/src/object/item/unitprice/OptionUnitPriceModel";

/**
 * @author 이덕형
 * @copyright RUNean Inc.
 * @date 2.2.17
 */
@jsonObject({
  knownTypes: [BrandModel, UnitPriceModel, OptionUnitPriceModel],
})
export class BrandCompanyModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 품목 모델 */
  public itemModel: ItemModel = null;
  /** 분류 모델 */
  public categoryCompanyModel: CategoryCompanyModel = null;
  /** 조직별 상품 모델 */
  public productCompanyModel: ProductCompanyModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 상표리스트 레이블 : 설계의 규격 수정에서 사용
   */
  public get label(): string {
    return this.brandModel.label;
  }

  public set label(value: string) {
    this.brandModel.label = value;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 상표 모델 */
  @jsonMember(() => BrandModel)
  public brandModel: BrandModel = null;

  /** 단가 모델 배열 */
  @jsonArrayMember(UnitPriceModel)
  public unitPriceModelAC: UnitPriceModel[] = null;

  /** 옵션 단가 모델 배열 */
  @jsonArrayMember(OptionUnitPriceModel)
  public optionUnitPriceModelAC: OptionUnitPriceModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 인덱스 */
  @jsonMember(Number)
  public index: number = null;

  /** 옵션 단가1 (공급대가) */
  @jsonMember(Number)
  public optionUnitPrice1: number = 0;
  /** 옵션 단가1 (공급가액) */
  @jsonMember(Number)
  public optionUnitSupply1: number = 0;

  /** 옵션 단가2 (공급대가) */
  @jsonMember(Number)
  public optionUnitPrice2: number = 0;
  /** 옵션 단가2 (공급가액) */
  @jsonMember(Number)
  public optionUnitSupply2: number = 0;

  /** 옵션 단가3 (공급대가) */
  @jsonMember(Number)
  public optionUnitPrice3: number = 0;
  /** 옵션 단가3 (공급가액) */
  @jsonMember(Number)
  public optionUnitSupply3: number = 0;

  /** 옵션 단가4 (공급대가) */
  @jsonMember(Number)
  public optionUnitPrice4: number = 0;
  /** 옵션 단가4 (공급가액) */
  @jsonMember(Number)
  public optionUnitSupply4: number = 0;

  /** 옵션 단가5 (공급대가) */
  @jsonMember(Number)
  public optionUnitPrice5: number = 0;
  /** 옵션 단가5 (공급가액) */
  @jsonMember(Number)
  public optionUnitSupply5: number = 0;

  /** 옵션 단가6 (공급대가) */
  @jsonMember(Number)
  public optionUnitPrice6: number = 0;
  /** 옵션 단가6 (공급가액) */
  @jsonMember(Number)
  public optionUnitSupply6: number = 0;

  /** 옵션 단가7 (공급대가) */
  @jsonMember(Number)
  public optionUnitPrice7: number = 0;
  /** 옵션 단가7 (공급가액) */
  @jsonMember(Number)
  public optionUnitSupply7: number = 0;

  // /** 매입가 초기가 리스트 */
  // @jsonArrayMember(Number)
  // public initDefaultPurchaseSupplies: number[] = [];
  //
  // /** 기본단가 초기값 리스트 */
  // @jsonArrayMember(Number)
  // public initDefaultUnitSupplies: number[] = [];
  //
  // /** 마진율 초기값 리스트 */
  // @jsonArrayMember(Number)
  // public initDefaultMarginRates: number[] = [];
  //
  // /** 부가세 포함가 초기값 리스트 */
  // @jsonArrayMember(Number)
  // public initDefaultUnitPrices: number[] = [];
  //
  // /** 세트수량 초기값 리스트 */
  // @jsonArrayMember(Number)
  // public initSetUnitQuantities: number[] = [];
  //
  // /** 기본단가, 세트수량 편집여부 */
  // @jsonArrayMember(Boolean)
  // public unitPriceEditables: boolean[] = [];
  //
  // /**
  //  * 옵션 단가(공급가액) 초기값 리스트.
  //  */
  // @jsonArrayMember(Number)
  // public initOtionUnitSupplies: number[] = [];
  //
  // /**
  //  * 옵션 단가(공급대가) 초기값 리스트.
  //  */
  // @jsonArrayMember(Number)
  // public initOtionUnitPrices: number[] = [];
  //
  // /** 조직별 규격모델 아이디 리스트 */
  // @jsonArrayMember(Number)
  // public specCompanyModelIds: number[] = [];

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor(unitPriceModelAC: UnitPriceModel[] = null, optionUnitPriceModelAC: OptionUnitPriceModel[] = null) {
    super();
    this.unitPriceModelAC = unitPriceModelAC || [];
    this.optionUnitPriceModelAC = optionUnitPriceModelAC || [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(
    itemModel: ItemModel,
    categoryCompanyModel: CategoryCompanyModel,
    productCompanyModel: ProductCompanyModel,
  ): void {
    this.itemModel = itemModel;
    this.categoryCompanyModel = categoryCompanyModel;
    this.productCompanyModel = productCompanyModel;

    // 자식 연관 (기존 단가 모델을 대신하여, 규격 모델 예하의 단가 모델로 재설정)
    for (const specCompanyModel of productCompanyModel.specCompanyModelAC) {
      for (const specUnitPriceModel of specCompanyModel.unitPriceModelAC) {
        for (const index in this.unitPriceModelAC) {
          if (this.unitPriceModelAC[index].id === specUnitPriceModel.id) {
            this.unitPriceModelAC[index] = specUnitPriceModel;
            this.unitPriceModelAC[index].brandCompanyModel = this;
          }
        }
      }
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 자식 레퍼런스변수
    for (const unitPriceModel of this.unitPriceModelAC) {
      unitPriceModel.setReferenceVariable();
    }

    for (const optionUnitPriceModel of this.optionUnitPriceModelAC) {
      optionUnitPriceModel.setReferenceVariable(this);
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 자식 초기화
    let unitPriceModel: UnitPriceModel;
    for (unitPriceModel of this.unitPriceModelAC) {
      unitPriceModel.setDefaultVariable();
    }
  }

  //----------------------------------
  // 품목 모델
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
