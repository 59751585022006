import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCustomLaborCost } from "vhows-design/src/object/design/item/list/ItemCustomLaborCost";
import { Part } from "vhows-design/src/object/design/base/Part";
import { BuildingSample_CustomPerAreaLaborCost } from "vhows-design/src/object/design/building/BuildingSample_CustomPerAreaLaborCost";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-28
 */
@jsonObject({
  knownTypes: [BuildingSample_CustomPerAreaLaborCost],
})
export class BuildingPart_PerArea extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public customPerAreaLaborCostSample: BuildingSample_CustomPerAreaLaborCost; // 임의 면적당 인건비 샘플

  // 아이템
  public customPerArea_customLaborCost: ItemCustomLaborCost;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _areaType: string = undefined; // 면적 형태
  @jsonMember(Number)
  public _itemPrice: number = undefined; // 비용

  /**
   * 면적 형태
   */
  public get areaType(): string {
    return this._areaType;
  }

  //
  public set areaType(value: string) {
    this._areaType = value;

    // 알고리즘
    this.customPerAreaLaborCostSample.algorithmSpec();
    this.algorithm_itemPrice();
    this.algorithmPart();
  }

  /**
   * 비용
   */
  public get itemPrice(): number {
    return this._itemPrice;
  }

  //
  public set itemPrice(value: number) {
    this._itemPrice = CommonUtil.fixFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.customPerAreaLaborCostSample = new BuildingSample_CustomPerAreaLaborCost();

    this.sampleAC = [this.customPerAreaLaborCostSample];

    // 아이템
    this.customPerArea_customLaborCost = new ItemCustomLaborCost();

    this.itemAC = [this.customPerArea_customLaborCost];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.customPerAreaLaborCostSample = <BuildingSample_CustomPerAreaLaborCost>this.sampleAC[0];
    // 아이템
    this.customPerArea_customLaborCost = <ItemCustomLaborCost>this.itemAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param areaType: string 면적 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    areaType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._areaType = areaType;

    // 샘플
    if (this.level.index >= 0) {
      this.customPerAreaLaborCostSample.setDefaultData(0, CONST.ITEM_ID_CUSTOM_LABOR_COST, "시공 인건비", "시공비", "");
      this.customPerAreaLaborCostSample.label = "시공 인건비";
      this.customPerAreaLaborCostSample.specs = CONST.LB_BUILDING_AREA_TYPE_SQUARE_METER;
    }

    // 아이템
    if (this.level.index >= 0) {
      this.customPerArea_customLaborCost.setDefaultData(
        0,
        CONST.ITEM_ID_CUSTOM_LABOR_COST,
        "시공 인건비",
        "시공비",
        "",
      );
      this.customPerArea_customLaborCost.label = "시공 인건비";
      this.customPerArea_customLaborCost.specs = CONST.LB_BUILDING_AREA_TYPE_SQUARE_METER;
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_itemPrice();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.customPerArea_customLaborCost.selected = true;
    this.customPerArea_customLaborCost.visible = true;

    /// ////////  수량 ///////////

    if (this.areaType === CONST.LB_BUILDING_AREA_TYPE_SQUARE_METER) {
      this.customPerArea_customLaborCost.designQuantity = this.basic.area;
    } else if (this.areaType === CONST.LB_BUILDING_AREA_TYPE_PYEONG) {
      this.customPerArea_customLaborCost.designQuantity = this.basic.pyeong;
    }
  }

  /**
   * 알고리즘: 비용 <- 임의 면적당 인건비 샘플, 면적 형태, 면적(기본정보)
   */
  public algorithm_itemPrice(): void {
    if (this.areaType === CONST.LB_BUILDING_AREA_TYPE_SQUARE_METER) {
      this.itemPrice = this.customPerAreaLaborCostSample.customUnitSupply * this.basic.area;
    } else if (this.areaType === CONST.LB_BUILDING_AREA_TYPE_PYEONG) {
      this.itemPrice = this.customPerAreaLaborCostSample.customUnitSupply * this.basic.pyeong;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [시공비] 시공 인건비 <- 임의 면적당 인건비 샘플
   */
  public algorithmSpec_customPerArea_customLaborCost(): void {
    this.customPerArea_customLaborCost.specs = this.customPerAreaLaborCostSample.specs;
    this.customPerArea_customLaborCost.customUnitSupply = this.customPerAreaLaborCostSample.customUnitSupply;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
