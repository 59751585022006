import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { FoundationPositionIL } from "vhows-design/src/object/design/foundation/il/FoundationPositionIL";
import { RoofPartIL_Rafter } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Rafter";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { EndpieceSampleIL_CylinderStudPipe } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceSampleIL_CylinderStudPipe";
import { EndpieceSampleIL_CylinderStudCenterUpperPipe } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceSampleIL_CylinderStudCenterUpperPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-24
 */
@jsonObject({
  knownTypes: [EndpieceSampleIL_CylinderStudPipe, EndpieceSampleIL_CylinderStudCenterUpperPipe],
})
export class EndpiecePartIL_CylinderStud extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public studPipeSample: EndpieceSampleIL_CylinderStudPipe; // 마구리 샛기둥 파이프 샘플
  public studCenterUpperPipeSample: EndpieceSampleIL_CylinderStudCenterUpperPipe; // 마구리 샛기둥(중앙상단) 파이프 샘플

  // 아이템
  public stud_pipe: ItemPipe;
  public studAndRafterCenter_tHolder: ItemHolder;
  public studAndRafterCenterEndpiece_normalHolder: ItemHolder;
  public studAndRafterCenterRafter_normalHolder: ItemHolder;
  public studAndRafterCenter_boltAndNut: ItemFrameOther;
  public studCenterUpper_pipe: ItemPipe;
  public studCenterUpperAndMiddle_saddleHolder: ItemHolder;
  public studAndRafterDiagonal_diagonalTHolder: ItemHolder;
  public studAndRafterDiagonal_diagonalTClamp: ItemHolder;
  public studAndRafterDiagonalEndpiece_normalHolder: ItemHolder;
  public studAndRafterDiagonalRafter_normalHolder: ItemHolder;
  public studAndRafterDiagonal_boltAndNut: ItemFrameOther;
  public studAndMiddleCylinder_saddleHolder: ItemHolder;
  public studAndMiddlePrism_uClamp: ItemHolder;
  public studAndWidthGirth_saddleHolder: ItemHolder;
  public studAndWidthGirth_fixAsibaClamp: ItemHolder;
  public stud_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _studLengthMax: number; // 샛기둥 최대 길이
  @jsonMember(Number)
  public _studLineNumber: number; // 샛기둥 줄수
  @jsonMember(Boolean)
  public _studCenterUpperSelected: boolean; // 중앙상단 샛기둥 선택
  @jsonMember(Number)
  public _studCenterUpperLengthMax: number; // 중앙상단 샛기둥 최대 길이

  /**
   * 샛기둥 최대 길이
   */
  public get studLengthMax(): number {
    return this._studLengthMax;
  }

  //
  public set studLengthMax(value: number) {
    this._studLengthMax = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 샛기둥 줄수
   */
  public get studLineNumber(): number {
    return this._studLineNumber;
  }

  //
  public set studLineNumber(value: number) {
    this._studLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<EndpiecePositionIL>this.position).cylinderMiddlePart.algorithmPart();

    // 다른 위치
    this.algorithm_studLineNumberByPosition();

    /// //////// 외부 ///////////

    // 치마
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).frontPosition.fixingSkirtClipPart.algorithm_clipInterval();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).backPosition.fixingSkirtClipPart.algorithm_clipInterval();
    }
  }

  /**
   * 중앙상단 샛기둥 선택
   */
  public get studCenterUpperSelected(): boolean {
    return this._studCenterUpperSelected;
  }

  public set studCenterUpperSelected(value: boolean) {
    this._studCenterUpperSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중앙상단 샛기둥 최대 길이
   */
  public get studCenterUpperLengthMax(): number {
    return this._studCenterUpperLengthMax;
  }

  public set studCenterUpperLengthMax(value: number) {
    this._studCenterUpperLengthMax = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.studPipeSample = new EndpieceSampleIL_CylinderStudPipe();
    this.studCenterUpperPipeSample = new EndpieceSampleIL_CylinderStudCenterUpperPipe();

    this.sampleAC = [this.studPipeSample, this.studCenterUpperPipeSample];

    // 아이템
    this.stud_pipe = new ItemPipe();
    this.studAndRafterCenter_tHolder = new ItemHolder();
    this.studAndRafterCenterEndpiece_normalHolder = new ItemHolder();
    this.studAndRafterCenterRafter_normalHolder = new ItemHolder();
    this.studAndRafterCenter_boltAndNut = new ItemFrameOther();
    this.studCenterUpper_pipe = new ItemPipe();
    this.studCenterUpperAndMiddle_saddleHolder = new ItemHolder();
    this.studAndRafterDiagonal_diagonalTHolder = new ItemHolder();
    this.studAndRafterDiagonal_diagonalTClamp = new ItemHolder();
    this.studAndRafterDiagonalEndpiece_normalHolder = new ItemHolder();
    this.studAndRafterDiagonalRafter_normalHolder = new ItemHolder();
    this.studAndRafterDiagonal_boltAndNut = new ItemFrameOther();
    this.studAndMiddleCylinder_saddleHolder = new ItemHolder();
    this.studAndMiddlePrism_uClamp = new ItemHolder();
    this.studAndWidthGirth_saddleHolder = new ItemHolder();
    this.studAndWidthGirth_fixAsibaClamp = new ItemHolder();
    this.stud_screw = new ItemScrew();

    this.itemAC = [
      this.stud_pipe,
      this.studAndRafterCenter_tHolder,
      this.studAndRafterCenterEndpiece_normalHolder,
      this.studAndRafterCenterRafter_normalHolder,
      this.studAndRafterCenter_boltAndNut,
      this.studCenterUpper_pipe,
      this.studCenterUpperAndMiddle_saddleHolder,
      this.studAndRafterDiagonal_diagonalTHolder,
      this.studAndRafterDiagonal_diagonalTClamp,
      this.studAndRafterDiagonalEndpiece_normalHolder,
      this.studAndRafterDiagonalRafter_normalHolder,
      this.studAndRafterDiagonal_boltAndNut,
      this.studAndMiddleCylinder_saddleHolder,
      this.studAndMiddlePrism_uClamp,
      this.studAndWidthGirth_saddleHolder,
      this.studAndWidthGirth_fixAsibaClamp,
      this.stud_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.studPipeSample = <EndpieceSampleIL_CylinderStudPipe>this.sampleAC[0];
    this.studCenterUpperPipeSample = <EndpieceSampleIL_CylinderStudCenterUpperPipe>this.sampleAC[1];

    // 아이템
    this.stud_pipe = <ItemPipe>this.itemAC[0];
    this.studAndRafterCenter_tHolder = <ItemHolder>this.itemAC[1];
    this.studAndRafterCenterEndpiece_normalHolder = <ItemHolder>this.itemAC[2];
    this.studAndRafterCenterRafter_normalHolder = <ItemHolder>this.itemAC[3];
    this.studAndRafterCenter_boltAndNut = <ItemFrameOther>this.itemAC[4];
    this.studCenterUpper_pipe = <ItemPipe>this.itemAC[5];
    this.studCenterUpperAndMiddle_saddleHolder = <ItemHolder>this.itemAC[6];
    this.studAndRafterDiagonal_diagonalTHolder = <ItemHolder>this.itemAC[7];
    this.studAndRafterDiagonal_diagonalTClamp = <ItemHolder>this.itemAC[8];
    this.studAndRafterDiagonalEndpiece_normalHolder = <ItemHolder>this.itemAC[9];
    this.studAndRafterDiagonalRafter_normalHolder = <ItemHolder>this.itemAC[10];
    this.studAndRafterDiagonal_boltAndNut = <ItemFrameOther>this.itemAC[11];
    this.studAndMiddleCylinder_saddleHolder = <ItemHolder>this.itemAC[12];
    this.studAndMiddlePrism_uClamp = <ItemHolder>this.itemAC[13];
    this.studAndWidthGirth_saddleHolder = <ItemHolder>this.itemAC[14];
    this.studAndWidthGirth_fixAsibaClamp = <ItemHolder>this.itemAC[15];
    this.stud_screw = <ItemScrew>this.itemAC[16];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param studLineNumber: number 샛기둥 줄수
   * @param studCenterUpperSelected: boolean 중앙상단 샛기둥 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    studLineNumber: number = 0,
    studCenterUpperSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._studLineNumber = studLineNumber;
    this._studCenterUpperSelected = studCenterUpperSelected;

    // 샘플
    if (this.level.index === 0) {
      this.studPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.studCenterUpperPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥(중앙상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
    } else {
      this.studPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.studCenterUpperPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥(중앙상단)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.stud_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.studAndRafterCenter_tHolder.setDefaultData(
        1,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "마구리 샛기둥 + 서까래",
        "원형, 일반, 48mm, 32mm",
      );
      this.studAndRafterCenterEndpiece_normalHolder.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 샛기둥 + 서까래 (1)",
        "원형, 일반, 48mm, -",
      );
      this.studAndRafterCenterRafter_normalHolder.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 샛기둥 + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.studAndRafterCenter_boltAndNut.setDefaultData(
        4,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 샛기둥 + 서까래",
        "일반, 8mm",
      );
      this.studCenterUpper_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 샛기둥(중앙상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.studCenterUpperAndMiddle_saddleHolder.setDefaultData(
        6,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 샛기둥(중앙상단) + 중방",
        "원형, 일반, 32mm, -",
      );
      this.studAndRafterDiagonal_diagonalTHolder.setDefaultData(
        7,
        CONST.ITEM_ID_DIAGONAL_T_HOLDER,
        CONST.ITEM_NAME_DIAGONAL_T_HOLDER,
        "마구리 샛기둥 + 서까래",
        "원형, 일반, 32mm, 32mm",
      );
      this.studAndRafterDiagonal_diagonalTClamp.setDefaultData(
        8,
        CONST.ITEM_ID_DIAGONAL_T_CLAMP,
        CONST.ITEM_NAME_DIAGONAL_T_CLAMP,
        "마구리 샛기둥 + 서까래",
        "원형, 일반, 32mm, 48mm",
      );
      this.studAndRafterDiagonalEndpiece_normalHolder.setDefaultData(
        9,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 샛기둥 + 서까래 (1)",
        "원형, 일반, 48mm, -",
      );
      this.studAndRafterDiagonalRafter_normalHolder.setDefaultData(
        10,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 샛기둥 + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.studAndRafterDiagonal_boltAndNut.setDefaultData(
        11,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 샛기둥 + 서까래",
        "일반, 8mm",
      );
      this.studAndMiddleCylinder_saddleHolder.setDefaultData(
        12,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 샛기둥 + 골조 중방",
        "원형, 일반, 48mm, -",
      );
      this.studAndMiddlePrism_uClamp.setDefaultData(
        13,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "마구리 샛기둥 + 골조 중방",
        "각형, 일반, 60×60mm, 48mm",
      );
      this.studAndWidthGirth_saddleHolder.setDefaultData(
        14,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 샛기둥 + 폭방향 바닥도리",
        "원형, 일반, 48mm, -",
      );
      this.studAndWidthGirth_fixAsibaClamp.setDefaultData(
        15,
        CONST.ITEM_ID_FIX_ASIBA_CLAMP,
        CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
        "마구리 샛기둥 + 폭방향 바닥도리",
        "원형, 일반, 48mm, 48mm",
      );
      this.stud_screw.setDefaultData(
        16,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 샛기둥",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 20 && (<Item>this.itemAC[7]).productId !== CONST.ITEM_ID_DIAGONAL_T_CLAMP) {
      const studAndRafterDiagonal_diagonalTClamp: ItemHolder = new ItemHolder();
      studAndRafterDiagonal_diagonalTClamp.setAssociation(design, struct, work, level, position, this);
      studAndRafterDiagonal_diagonalTClamp.setDefaultData(
        7,
        CONST.ITEM_ID_DIAGONAL_T_CLAMP,
        CONST.ITEM_NAME_DIAGONAL_T_CLAMP,
        "마구리 샛기둥 + 서까래",
        "원형, 일반, 32mm, 48mm",
      );
      studAndRafterDiagonal_diagonalTClamp.selected = false;
      studAndRafterDiagonal_diagonalTClamp.visible = false;
      this.itemAC.splice(7, 0, studAndRafterDiagonal_diagonalTClamp);

      // 중앙상단 부품 위로 올리기
      CommonUtil.changeArrayIndex(this.itemAC, 17, 6);
      CommonUtil.changeArrayIndex(this.itemAC, 18, 7);
      CommonUtil.changeArrayIndex(this.itemAC, 19, 8);
      CommonUtil.changeArrayIndex(this.itemAC, 20, 9);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (
      this.itemAC.length === 21 &&
      (<Item>this.itemAC[5]).productId === CONST.ITEM_ID_SCREW &&
      (<Item>this.itemAC[9]).productId === CONST.ITEM_ID_SCREW &&
      (<Item>this.itemAC[15]).productId === CONST.ITEM_ID_SCREW &&
      (<Item>this.itemAC[17]).productId === CONST.ITEM_ID_SCREW
    ) {
      // 나사 하나로 합치기
      let stud_screw_designQuantity: number;
      stud_screw_designQuantity =
        (<ItemScrew>this.itemAC[5]).getSelectedQuantity() +
        (<ItemScrew>this.itemAC[9]).getSelectedQuantity() +
        (<ItemScrew>this.itemAC[15]).getSelectedQuantity() +
        (<ItemScrew>this.itemAC[17]).getSelectedQuantity() +
        (<ItemScrew>this.itemAC[20]).getSelectedQuantity();
      const stud_screw: ItemScrew = <ItemScrew>this.itemAC[20];
      stud_screw.selected = stud_screw_designQuantity > 0;
      stud_screw.visible = stud_screw_designQuantity > 0;
      stud_screw.purpose = "마구리 샛기둥";
      stud_screw.designQuantity = stud_screw_designQuantity;
      this.itemAC.splice(17, 1);
      this.itemAC.splice(15, 1);
      this.itemAC.splice(9, 1);
      this.itemAC.splice(5, 1);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (
      this.itemAC.length === 17 &&
      (<Item>this.itemAC[6]).productId === CONST.ITEM_ID_T_HOLDER &&
      (<Item>this.itemAC[15]).productId === CONST.ITEM_ID_SADDLE_HOLDER
    ) {
      // [마구리 샛기둥(중앙상단)] T 고정구 삭제
      this.itemAC.splice(6, 1);
      // [마구리 샛기둥 + 폭방향 바닥도리] 새들 고정구 삭제
      const saddleHolder: Item[] = this.itemAC.splice(14, 1);
      // [마구리 샛기둥 + 폭방향 바닥도리] 고정 아시바 클램프 추가
      const studAndWidthGirth_fixAsibaClamp: ItemHolder = new ItemHolder();
      studAndWidthGirth_fixAsibaClamp.setAssociation(design, struct, work, level, position, this);
      studAndWidthGirth_fixAsibaClamp.setDefaultData(
        14,
        CONST.ITEM_ID_FIX_ASIBA_CLAMP,
        CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
        "마구리 샛기둥 + 폭방향 바닥도리",
        "원형, 일반, 48mm, 48mm",
      );
      if (level.index === 0) {
        studAndWidthGirth_fixAsibaClamp.selected = saddleHolder[0].selected;
        studAndWidthGirth_fixAsibaClamp.visible = saddleHolder[0].visible;
        studAndWidthGirth_fixAsibaClamp.designQuantity = saddleHolder[0].designQuantity;
      } else {
        studAndWidthGirth_fixAsibaClamp.selected = false;
        studAndWidthGirth_fixAsibaClamp.visible = false;
      }
      this.itemAC.splice(14, 0, studAndWidthGirth_fixAsibaClamp);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 16 && (<Item>this.itemAC[14]).productId === CONST.ITEM_ID_FIX_ASIBA_CLAMP) {
      const studAndWidthGirth_saddleHolder: ItemHolder = new ItemHolder();
      studAndWidthGirth_saddleHolder.setAssociation(design, struct, work, level, position, this);
      studAndWidthGirth_saddleHolder.setDefaultData(
        14,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 샛기둥 + 폭방향 바닥도리",
        "원형, 일반, 48mm, -",
      );
      studAndWidthGirth_saddleHolder.selected = false;
      studAndWidthGirth_saddleHolder.visible = false;
      this.itemAC.splice(14, 0, studAndWidthGirth_saddleHolder);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_studLengthMax();
    this.algorithm_studCenterUpperLengthMax();
    this.algorithm_studLineNumber();

    super.algorithmBasic();
  }

  /**
   * 파트 알고리즘 <- 형태(기둥 골조), 서까래 파이프 샘플(지붕 골조), 선택(바닥 골조), 형태(바닥 골조), 폭방향 바닥도리 선택(바닥 골조)
   */
  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const endpiecePosition: EndpiecePositionIL = <EndpiecePositionIL>this.position;
    let gateQuantity: number; // 출입동인 경우
    let noGateQuantity: number; // 출입동이 아닌 경우
    // 외부
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    const roofPart_Rafter: RoofPartIL_Rafter = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index])
      .roofPosition.rafterPart;
    const foundationPosition: FoundationPositionIL = this.struct.foundationWorkIL.level1.foundationPosition;

    /// //////// 수치 ///////////

    // 출입동이 아닌 동수
    let noGateBuildingNumber: number = this.basic.buildingNumber - endpiecePosition.gateBuildingNumber;
    if (noGateBuildingNumber < 0) {
      noGateBuildingNumber = 0;
    }

    /// //////// 선택, 가시성 ///////////

    this.stud_pipe.selected = true;
    this.stud_pipe.visible = true;
    // 중앙 : T 고정구(22/22mm,25/25mm,32/32mm,32/25mm) vs. 일반 고정구
    // 하드코딩
    if (
      this.design.preference.endpieceTholderFlag === true &&
      ((this.stud_pipe.specCrossSize === roofPart_Rafter.rafterPipeSample.specCrossSize &&
        (this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM ||
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)) ||
        (roofPart_Rafter.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM &&
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM))
    ) {
      this.studAndRafterCenter_tHolder.selected = true;
      this.studAndRafterCenter_tHolder.visible = true;
      this.studAndRafterCenterEndpiece_normalHolder.selected = false;
      this.studAndRafterCenterEndpiece_normalHolder.visible = false;
      this.studAndRafterCenterRafter_normalHolder.selected = false;
      this.studAndRafterCenterRafter_normalHolder.visible = false;
      this.studAndRafterCenter_boltAndNut.selected = false;
      this.studAndRafterCenter_boltAndNut.visible = false;
    } else {
      this.studAndRafterCenter_tHolder.selected = false;
      this.studAndRafterCenter_tHolder.visible = false;
      this.studAndRafterCenterEndpiece_normalHolder.selected = true;
      this.studAndRafterCenterEndpiece_normalHolder.visible = true;
      this.studAndRafterCenterRafter_normalHolder.selected = true;
      this.studAndRafterCenterRafter_normalHolder.visible = true;
      this.studAndRafterCenter_boltAndNut.selected = true;
      this.studAndRafterCenter_boltAndNut.visible = true;
    }
    // 대각 : 대각T 고정구(25/25mm,32/32mm) vs. 대각T 클램프(25/48mm,32/48mm,42/42mm,48/48mm) vs. 일반 고정구
    // 하드코딩
    if (
      this.design.preference.endpieceDiagonalTholderFlag === true &&
      this.stud_pipe.specCrossSize === roofPart_Rafter.rafterPipeSample.specCrossSize &&
      (this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
        this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)
    ) {
      this.studAndRafterDiagonal_diagonalTHolder.selected = true;
      this.studAndRafterDiagonal_diagonalTHolder.visible = true;
      this.studAndRafterDiagonal_diagonalTClamp.selected = false;
      this.studAndRafterDiagonal_diagonalTClamp.visible = false;
      this.studAndRafterDiagonalEndpiece_normalHolder.selected = false;
      this.studAndRafterDiagonalEndpiece_normalHolder.visible = false;
      this.studAndRafterDiagonalRafter_normalHolder.selected = false;
      this.studAndRafterDiagonalRafter_normalHolder.visible = false;
      this.studAndRafterDiagonal_boltAndNut.selected = false;
      this.studAndRafterDiagonal_boltAndNut.visible = false;
    } else if (
      this.design.preference.endpieceDiagonalTclampFlag === true &&
      ((this.stud_pipe.specCrossSize === roofPart_Rafter.rafterPipeSample.specCrossSize &&
        (this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)) ||
        (roofPart_Rafter.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM &&
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) ||
        (roofPart_Rafter.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM &&
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM))
    ) {
      this.studAndRafterDiagonal_diagonalTHolder.selected = false;
      this.studAndRafterDiagonal_diagonalTHolder.visible = false;
      this.studAndRafterDiagonal_diagonalTClamp.selected = true;
      this.studAndRafterDiagonal_diagonalTClamp.visible = true;
      this.studAndRafterDiagonalEndpiece_normalHolder.selected = false;
      this.studAndRafterDiagonalEndpiece_normalHolder.visible = false;
      this.studAndRafterDiagonalRafter_normalHolder.selected = false;
      this.studAndRafterDiagonalRafter_normalHolder.visible = false;
      this.studAndRafterDiagonal_boltAndNut.selected = false;
      this.studAndRafterDiagonal_boltAndNut.visible = false;
    } else {
      this.studAndRafterDiagonal_diagonalTHolder.selected = false;
      this.studAndRafterDiagonal_diagonalTHolder.visible = false;
      this.studAndRafterDiagonal_diagonalTClamp.selected = false;
      this.studAndRafterDiagonal_diagonalTClamp.visible = false;
      this.studAndRafterDiagonalEndpiece_normalHolder.selected = true;
      this.studAndRafterDiagonalEndpiece_normalHolder.visible = true;
      this.studAndRafterDiagonalRafter_normalHolder.selected = true;
      this.studAndRafterDiagonalRafter_normalHolder.visible = true;
      this.studAndRafterDiagonal_boltAndNut.selected = true;
      this.studAndRafterDiagonal_boltAndNut.visible = true;
    }
    // 중앙상단
    if (this.studCenterUpperSelected === true) {
      this.studCenterUpper_pipe.selected = true;
      this.studCenterUpper_pipe.visible = true;
      this.studCenterUpperAndMiddle_saddleHolder.selected = true;
      this.studCenterUpperAndMiddle_saddleHolder.visible = true;
    } else {
      this.studCenterUpper_pipe.selected = false;
      this.studCenterUpper_pipe.visible = false;
      this.studCenterUpperAndMiddle_saddleHolder.selected = false;
      this.studCenterUpperAndMiddle_saddleHolder.visible = false;
    }
    // 기둥 형태 및 마구리 샛기둥 파이프 규격
    // 하드코딩
    if (
      columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER ||
      this.studPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM ||
      this.studPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM
    ) {
      this.studAndMiddleCylinder_saddleHolder.selected = true;
      this.studAndMiddleCylinder_saddleHolder.visible = true;
      this.studAndMiddlePrism_uClamp.selected = false;
      this.studAndMiddlePrism_uClamp.visible = false;
    } else if (
      columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM &&
      (this.studPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM ||
        this.studPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
        this.studPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)
    ) {
      this.studAndMiddleCylinder_saddleHolder.selected = false;
      this.studAndMiddleCylinder_saddleHolder.visible = false;
      this.studAndMiddlePrism_uClamp.selected = true;
      this.studAndMiddlePrism_uClamp.visible = true;
    }
    // 마구리 형태 및 폭방향 바닥도리 선택
    if (
      endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE &&
      this.level.index === 0 &&
      foundationPosition.selected === true &&
      (foundationPosition.type === CONST.LB_FOUNDATION_TYPE_GIRTH_PIPE ||
        foundationPosition.type === CONST.LB_FOUNDATION_TYPE_GIRTH_AND_COLUMN_CONCRETE) &&
      foundationPosition.girthPipePart.widthGirthSelected === true
    ) {
      // 하드코딩 : 고정 아시바 클램프 규격
      if (
        (this.studPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM &&
          foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM) ||
        (this.studPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM &&
          (foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
            foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM)) ||
        (this.studPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM &&
          (foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM ||
            foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM))
      ) {
        this.studAndWidthGirth_fixAsibaClamp.selected = true;
        this.studAndWidthGirth_fixAsibaClamp.visible = true;
        this.studAndWidthGirth_saddleHolder.selected = false;
        this.studAndWidthGirth_saddleHolder.visible = false;
      } else {
        this.studAndWidthGirth_fixAsibaClamp.selected = false;
        this.studAndWidthGirth_fixAsibaClamp.visible = false;
        this.studAndWidthGirth_saddleHolder.selected = true;
        this.studAndWidthGirth_saddleHolder.visible = true;
      }
    } else {
      this.studAndWidthGirth_saddleHolder.selected = false;
      this.studAndWidthGirth_saddleHolder.visible = false;
      this.studAndWidthGirth_fixAsibaClamp.selected = false;
      this.studAndWidthGirth_fixAsibaClamp.visible = false;
    }
    this.stud_screw.selected = true;
    this.stud_screw.visible = true;

    /// //////// 수량 ///////////

    if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
      if (this.stud_pipe.specLength >= this.studLengthMax) {
        gateQuantity = Math.ceil(
          ((this.studLineNumber - 1) / Math.floor(this.stud_pipe.specLength / this.studLengthMax)) *
            endpiecePosition.gateBuildingNumber,
        );
        noGateQuantity = Math.ceil(
          (this.studLineNumber / Math.floor(this.stud_pipe.specLength / this.studLengthMax)) * noGateBuildingNumber,
        );
      } else {
        gateQuantity =
          Math.ceil((this.studLengthMax * (this.studLineNumber - 1)) / this.stud_pipe.specLength) *
          endpiecePosition.gateBuildingNumber;
        noGateQuantity =
          Math.ceil((this.studLengthMax * this.studLineNumber) / this.stud_pipe.specLength) * noGateBuildingNumber;
      }
      this.stud_pipe.designQuantity = (noGateQuantity + gateQuantity) * CONST.NUM_EXTRA_RATE_ENDPIECE_STUD_PIPE;
    } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      this.stud_pipe.designQuantity =
        ((this.studLengthMax * (this.studLineNumber - 1) * this.basic.buildingNumber) / this.stud_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_ENDPIECE_STUD_PIPE;
    }

    this.studAndRafterCenter_tHolder.designQuantity = 1 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.studAndRafterCenterEndpiece_normalHolder.designQuantity =
      1 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.studAndRafterCenterRafter_normalHolder.designQuantity =
      1 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.studAndRafterCenter_boltAndNut.designQuantity =
      this.studAndRafterCenterEndpiece_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.studCenterUpper_pipe.designQuantity =
      (this.studCenterUpperLengthMax * endpiecePosition.gateBuildingNumber) / this.studCenterUpper_pipe.specLength;

    this.studCenterUpperAndMiddle_saddleHolder.designQuantity =
      1 * endpiecePosition.gateBuildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.studAndRafterDiagonal_diagonalTHolder.designQuantity =
      (this.studLineNumber - 1) * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_DIAGONAL_T_HOLDER;

    this.studAndRafterDiagonal_diagonalTClamp.designQuantity =
      (this.studLineNumber - 1) * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_DIAGONAL_T_CLAMP;

    this.studAndRafterDiagonalEndpiece_normalHolder.designQuantity =
      (this.studLineNumber - 1) * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.studAndRafterDiagonalRafter_normalHolder.designQuantity =
      (this.studLineNumber - 1) * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.studAndRafterDiagonal_boltAndNut.designQuantity =
      this.studAndRafterDiagonalEndpiece_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    gateQuantity = (this.studLineNumber - 1) * 1 * endpiecePosition.gateBuildingNumber;
    noGateQuantity = this.studLineNumber * 1 * noGateBuildingNumber;
    this.studAndMiddleCylinder_saddleHolder.designQuantity =
      (gateQuantity + noGateQuantity) * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    gateQuantity = (this.studLineNumber - 1) * 1 * endpiecePosition.gateBuildingNumber;
    noGateQuantity = this.studLineNumber * 1 * noGateBuildingNumber;
    this.studAndMiddlePrism_uClamp.designQuantity = (gateQuantity + noGateQuantity) * CONST.NUM_EXTRA_RATE_U_CLAMP;

    gateQuantity = (this.studLineNumber - 1) * 1 * endpiecePosition.gateBuildingNumber;
    noGateQuantity = this.studLineNumber * 1 * noGateBuildingNumber;
    this.studAndWidthGirth_fixAsibaClamp.designQuantity = gateQuantity + noGateQuantity;

    this.studAndWidthGirth_saddleHolder.designQuantity = gateQuantity + noGateQuantity;

    this.stud_screw.designQuantity =
      ((this.studAndRafterCenter_tHolder.getSelectedQuantity() * 3 +
        this.studAndRafterCenterEndpiece_normalHolder.getSelectedQuantity() * 2 +
        this.studAndRafterCenterRafter_normalHolder.getSelectedQuantity() * 2 +
        this.studAndMiddleCylinder_saddleHolder.getSelectedQuantity() * 3 +
        this.studCenterUpperAndMiddle_saddleHolder.getSelectedQuantity() * 3 +
        this.studAndRafterDiagonal_diagonalTHolder.getSelectedQuantity() * 3 +
        this.studAndRafterDiagonal_diagonalTClamp.getSelectedQuantity() * 4 +
        this.studAndRafterDiagonalEndpiece_normalHolder.getSelectedQuantity() * 2 +
        this.studAndRafterDiagonalRafter_normalHolder.getSelectedQuantity() * 2 +
        this.studAndWidthGirth_saddleHolder.getSelectedQuantity() * 3) /
        this.stud_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 샛기둥 최대 길이 <- 동고(기본정보), 지붕 높이(기본정보), 형태(포지션)
   */
  public algorithm_studLengthMax(): void {
    const endpiecePosition: EndpiecePositionIL = <EndpiecePositionIL>this.position;

    if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
      this.studLengthMax = this.basicLevel.heightMax;
    } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      this.studLengthMax = this.basicLevel.heightRoofMax;
    }
  }

  /**
   * 알고리즘: 샛기둥 줄수 <- 폭(기본정보)
   */
  public algorithm_studLineNumber(): void {
    if (this.basicLevel.width < 7) {
      this.studLineNumber = 5;
    } else {
      this.studLineNumber = 7;
    }
  }

  /**
   * 알고리즘: 샛기둥 줄수 <- 샛기둥 줄수(위치/앞면)
   */
  public algorithm_studLineNumberByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (this.position.type === (<EndpieceLevelIL>this.level).backPosition.type) {
        (<EndpieceLevelIL>this.level).backPosition.cylinderStudPart.studLineNumber = this.studLineNumber;
      }
    }
  }

  /**
   * 알고리즘: 중앙상단 샛기둥 선택 <- 출입 동수(위치)
   */
  public algorithm_studCenterUpperSelected(): void {
    if ((<EndpiecePositionIL>this.position).gateBuildingNumber > 0) {
      this.studCenterUpperSelected = true;
    } else {
      this.studCenterUpperSelected = false;
    }
  }

  /**
   * 알고리즘: 중앙상단 샛기둥 최대 길이 <- 지붕 높이(기본정보)
   */
  public algorithm_studCenterUpperLengthMax(): void {
    this.studCenterUpperLengthMax = this.basicLevel.heightRoofMax;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [마구리 샛기둥] 파이프 <- 마구리 샛기둥 파이프 샘플
   */
  public algorithmSpec_stud_pipe(): void {
    this.stud_pipe.specs = this.studPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래] T 고정구 <- 마구리 샛기둥 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterCenter_tHolder(): void {
    this.studAndRafterCenter_tHolder.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.studAndRafterCenter_tHolder.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    this.studAndRafterCenter_tHolder.specCrossSize2 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래 (1)] 일반 고정구 <- 마구리 샛기둥 파이프 샘플
   */
  public algorithmSpec_studAndRafterCenterEndpiece_normalHolder(): void {
    this.studAndRafterCenterEndpiece_normalHolder.specPipeType = this.studPipeSample.specPipeType;
    this.studAndRafterCenterEndpiece_normalHolder.specCrossSize1 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterCenterRafter_normalHolder(): void {
    this.studAndRafterCenterRafter_normalHolder.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.studAndRafterCenterRafter_normalHolder.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_studAndRafterCenter_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [마구리 샛기둥(중앙상단)] 파이프 <- 마구리 샛기둥(중앙상단) 파이프 샘플
   */
  public algorithmSpec_studCenterUpper_pipe(): void {
    this.studCenterUpper_pipe.specs = this.studCenterUpperPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥(중앙상단) + 중방] 새들 고정구 <- 마구리 샛기둥(중앙상단) 파이프 샘플
   */
  public algorithmSpec_studCenterUpperAndMiddle_saddleHolder(): void {
    this.studCenterUpperAndMiddle_saddleHolder.specPipeType = this.studCenterUpperPipeSample.specPipeType;
    this.studCenterUpperAndMiddle_saddleHolder.specCrossSize1 = this.studCenterUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래] 대각T 고정구 <- 마구리 샛기둥 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonal_diagonalTHolder(): void {
    this.studAndRafterDiagonal_diagonalTHolder.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.studAndRafterDiagonal_diagonalTHolder.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    this.studAndRafterDiagonal_diagonalTHolder.specCrossSize2 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래] 대각T 클램프 <- 마구리 샛기둥 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonal_diagonalTClamp(): void {
    this.studAndRafterDiagonal_diagonalTClamp.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.studAndRafterDiagonal_diagonalTClamp.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    this.studAndRafterDiagonal_diagonalTClamp.specCrossSize2 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래 (1)] 일반 고정구 <- 마구리 샛기둥 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonalEndpiece_normalHolder(): void {
    this.studAndRafterDiagonalEndpiece_normalHolder.specPipeType = this.studPipeSample.specPipeType;
    this.studAndRafterDiagonalEndpiece_normalHolder.specCrossSize1 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonalRafter_normalHolder(): void {
    this.studAndRafterDiagonalRafter_normalHolder.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.studAndRafterDiagonalRafter_normalHolder.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_studAndRafterDiagonal_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 골조 중방] 새들 고정구 <- 마구리 샛기둥 파이프 샘플
   */
  public algorithmSpec_studAndMiddleCylinder_saddleHolder(): void {
    this.studAndMiddleCylinder_saddleHolder.specPipeType = this.studPipeSample.specPipeType;
    this.studAndMiddleCylinder_saddleHolder.specCrossSize1 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 골조 중방] U 클램프 <- 마구리 샛기둥 파이프 샘플, 중방 파이프 샘플
   */
  public algorithmSpec_studAndMiddlePrism_uClamp(): void {
    // 마구리 샛기둥 == 32mm
    this.studAndMiddlePrism_uClamp.specPipeType =
      this.struct.columnWorkIL.level1.columnPosition.prismMiddlePart.middlePipeSample.specPipeType;
    this.studAndMiddlePrism_uClamp.specCrossSize1 =
      this.struct.columnWorkIL.level1.columnPosition.prismMiddlePart.middlePipeSample.specCrossSize;
    this.studAndMiddlePrism_uClamp.specCrossSize2 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 폭방향 바닥도리] 새들 고정구 <- 마구리 샛기둥 파이프 샘플
   */
  public algorithmSpec_studAndWidthGirth_saddleHolder(): void {
    this.studAndWidthGirth_saddleHolder.specPipeType = this.studPipeSample.specPipeType;
    this.studAndWidthGirth_saddleHolder.specCrossSize1 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥 + 폭방향 바닥도리] 고정 아시바 클램프 <- 마구리 샛기둥 파이프 샘플, 폭방향 바닥도리 파이프 샘플
   */
  public algorithmSpec_studAndWidthGirth_fixAsibaClamp(): void {
    // 마구리 샛기둥 == 48mm
    this.studAndWidthGirth_fixAsibaClamp.specPipeType = this.studPipeSample.specPipeType;
    this.studAndWidthGirth_fixAsibaClamp.specCrossSize1 = this.studPipeSample.specCrossSize;
    this.studAndWidthGirth_fixAsibaClamp.specCrossSize2 =
      this.struct.foundationWorkIL.level1.foundationPosition.girthPipePart.widthGirthPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 샛기둥] 나사 <- 공통 샘플
   */
  public algorithmSpec_stud_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
