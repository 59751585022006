import { jsonMember, jsonObject } from "typedjson";
import { Address } from "vhows-design/src/object/common/Address";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Company } from "vhows-design/src/object/company/Company";

/**
 * @author 권새힘
 * @copyright RUNean Inc.
 */
@jsonObject({
  knownTypes: [Company],
})
export class Customer extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 고객구분
  @jsonMember(Company)
  public company: Company = new Company();

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 공통

  // 고객구분
  @jsonMember(String)
  public customerType: string = null;
  // 고객명/담당자명
  @jsonMember(String)
  public name: string = null;
  // 휴대폰 번호
  @jsonMember(String)
  public mobileNumber: string = null;
  // 주민등록번호/사업자등록번호
  @jsonMember(String)
  public regNumber: string = null;
  // 주소
  @jsonMember(Address)
  public address: Address = new Address();
  // 전화 번호
  @jsonMember(String)
  public phoneNumber: string = null;
  // 프로필사진
  @jsonMember(String)
  public profileImage: string = null;
  // 이메일
  @jsonMember(String)
  public email: string = null;
  // 농업인경영체 등록여부
  @jsonMember(Boolean)
  public agriRegistered: boolean = null;
  // 농업인경영체 등록번호
  @jsonMember(String)
  public agriRegNumber: string = null;
  // 노트
  @jsonMember(String)
  public note: string = null;

  // 개인

  // 성별
  @jsonMember(String)
  public gender: string = null;
  // 생년월일
  @jsonMember(Date)
  public birthdate: Date;

  // 기업

  // 상호명
  @jsonMember(String)
  public companyName: string = null;
  // 대표자명
  @jsonMember(String)
  public ceoName: string = null;
  // 업태
  @jsonMember(String)
  public businessType: string = null;
  // 업종
  @jsonMember(String)
  public businessTypeDetail: string = null;
  // 팩스 번호
  @jsonMember(String)
  public faxNumber: string = null;

  // 시스템

  // 생성자
  @jsonMember(String)
  public creator: string = null;
  // 최종 변경자
  @jsonMember(String)
  public lastUpdater: string = null;
  // 최종 변경 일시
  @jsonMember(Date)
  public lastUpdate: Date = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * @param origin 원본 객체
   */
  public deepCopy(origin: Customer): void {
    this.customerType = origin.customerType;
    this.name = origin.name;
    this.mobileNumber = origin.mobileNumber;
    this.regNumber = origin.regNumber;
    this.address.deepCopy(origin.address);
    this.phoneNumber = origin.phoneNumber;
    this.profileImage = origin.profileImage;
    this.email = origin.email;
    this.agriRegistered = origin.agriRegistered;
    this.agriRegNumber = origin.agriRegNumber;
    this.note = origin.note;

    this.gender = origin.gender;
    this.birthdate = origin.birthdate;

    this.companyName = origin.companyName;
    this.ceoName = origin.ceoName;
    this.businessType = origin.businessType;
    this.businessTypeDetail = origin.businessTypeDetail;
    this.faxNumber = origin.faxNumber;

    this.creator = origin.creator;
    this.lastUpdater = origin.lastUpdater;
    this.lastUpdate = origin.lastUpdate;

    super.deepCopy(origin);
  }
}
