import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCarrier } from "vhows-design/src/object/design/item/list/ItemCarrier";
import { ItemGroundCarrier } from "vhows-design/src/object/design/item/list/ItemGroundCarrier";
import { Part } from "vhows-design/src/object/design/base/Part";
import { RailPosition } from "vhows-design/src/object/design/other/rail/RailPosition";
import { RailSample_UpperCarrier } from "vhows-design/src/object/design/other/rail/RailSample_UpperCarrier";
import { RailSample_SideCarrier } from "vhows-design/src/object/design/other/rail/RailSample_SideCarrier";
import { RailSample_GroundCarrier } from "vhows-design/src/object/design/other/rail/RailSample_GroundCarrier";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-10-29
 */
@jsonObject({
  knownTypes: [RailSample_UpperCarrier, RailSample_SideCarrier, RailSample_GroundCarrier],
})
export class RailPart_Carrier extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public upperCarrierSample: RailSample_UpperCarrier; // 상단 운반구 샘플
  public sideCarrierSample: RailSample_SideCarrier; // 측면 운반구 샘플
  public groundCarrierSample: RailSample_GroundCarrier; // 지면 운반구 샘플

  // 아이템
  public carrier_upperCarrier: ItemCarrier;
  public carrier_sideCarrier: ItemCarrier;
  public carrier_groundCarrier: ItemGroundCarrier;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.upperCarrierSample = new RailSample_UpperCarrier();
    this.sideCarrierSample = new RailSample_SideCarrier();
    this.groundCarrierSample = new RailSample_GroundCarrier();

    this.sampleAC = [this.upperCarrierSample, this.sideCarrierSample, this.groundCarrierSample];

    // 아이템
    this.carrier_upperCarrier = new ItemCarrier();
    this.carrier_sideCarrier = new ItemCarrier();
    this.carrier_groundCarrier = new ItemGroundCarrier();

    this.itemAC = [this.carrier_upperCarrier, this.carrier_sideCarrier, this.carrier_groundCarrier];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.upperCarrierSample = <RailSample_UpperCarrier>this.sampleAC[0];
    this.sideCarrierSample = <RailSample_SideCarrier>this.sampleAC[1];
    this.groundCarrierSample = <RailSample_GroundCarrier>this.sampleAC[2];
    // 아이템
    this.carrier_upperCarrier = <ItemCarrier>this.itemAC[0];
    this.carrier_sideCarrier = <ItemCarrier>this.itemAC[1];
    this.carrier_groundCarrier = <ItemGroundCarrier>this.itemAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.upperCarrierSample.setDefaultData(
        0,
        CONST.ITEM_ID_UPPER_CARRIER,
        CONST.ITEM_NAME_UPPER_CARRIER,
        "운반구",
        "일반, 대, 25mm",
      );
      this.sideCarrierSample.setDefaultData(
        1,
        CONST.ITEM_ID_SIDE_CARRIER,
        CONST.ITEM_NAME_SIDE_CARRIER,
        "운반구",
        "일반, 중, 25mm",
      );
      this.groundCarrierSample.setDefaultData(
        2,
        CONST.ITEM_ID_GROUND_CARRIER,
        CONST.ITEM_NAME_GROUND_CARRIER,
        "운반구",
        "일반, 중, 45cm간격, 25mm",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.carrier_upperCarrier.setDefaultData(
        0,
        CONST.ITEM_ID_UPPER_CARRIER,
        CONST.ITEM_NAME_UPPER_CARRIER,
        "운반구",
        "일반, 대, 25mm",
      );
      this.carrier_sideCarrier.setDefaultData(
        1,
        CONST.ITEM_ID_SIDE_CARRIER,
        CONST.ITEM_NAME_SIDE_CARRIER,
        "운반구",
        "일반, 중, 25mm",
      );
      this.carrier_groundCarrier.setDefaultData(
        2,
        CONST.ITEM_ID_GROUND_CARRIER,
        CONST.ITEM_NAME_GROUND_CARRIER,
        "운반구",
        "일반, 중, 45cm간격, 25mm",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const railPosition: RailPosition = <RailPosition>this.position;

    /// //////// 선택, 가시성 ///////////

    this.carrier_upperCarrier.selected = true;
    this.carrier_upperCarrier.visible = true;
    this.carrier_sideCarrier.selected = true;
    this.carrier_sideCarrier.visible = true;
    this.carrier_groundCarrier.selected = true;
    this.carrier_groundCarrier.visible = true;

    /// ////////  수치 ///////////

    /// ////////  수량 ///////////

    this.carrier_upperCarrier.designQuantity = this.upperCarrierSample.designQuantity;

    this.carrier_sideCarrier.designQuantity = this.sideCarrierSample.designQuantity;

    this.carrier_groundCarrier.designQuantity = this.groundCarrierSample.designQuantity;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [운반구] 상단 운반구 <- 상단 운반구 샘플
   */
  public algorithmSpec_carrier_upperCarrier(): void {
    this.carrier_upperCarrier.specs = this.upperCarrierSample.specs;
  }

  /**
   * 규격 알고리즘: [운반구] 측면 운반구 <- 측면 운반구 샘플
   */
  public algorithmSpec_carrier_sideCarrier(): void {
    this.carrier_sideCarrier.specs = this.sideCarrierSample.specs;
  }

  /**
   * 규격 알고리즘: [운반구] 지면 운반구 <- 지면 운반구 샘플
   */
  public algorithmSpec_carrier_groundCarrier(): void {
    this.carrier_groundCarrier.specs = this.groundCarrierSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
