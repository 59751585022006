import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { ItemPvcConnector } from "vhows-design/src/object/design/item/list/ItemPvcConnector";
import { ItemWaterTank } from "vhows-design/src/object/design/item/list/ItemWaterTank";
import { ItemWaterPump } from "vhows-design/src/object/design/item/list/ItemWaterPump";
import { ItemWaterPumpHose } from "vhows-design/src/object/design/item/list/ItemWaterPumpHose";
import { ItemWaterFilter } from "vhows-design/src/object/design/item/list/ItemWaterFilter";
import { Part } from "vhows-design/src/object/design/base/Part";
import { HydSystemPosition } from "vhows-design/src/object/design/hydroponics/system/HydSystemPosition";
import { HydCultivationPosition } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPosition";
import { HydSystemSample_CatchmentWaterTank } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_CatchmentWaterTank";
import { HydSystemSample_CatchmentWaterPump } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_CatchmentWaterPump";
import { HydSystemSample_DrainMainPvcPipe } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_DrainMainPvcPipe";
import { HydSystemSample_RecoveryWaterTank } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_RecoveryWaterTank";
import { HydSystemSample_RecoveryWaterPump } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_RecoveryWaterPump";
import { HydSystemSample_RecoveryWaterFilter } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_RecoveryWaterFilter";
import { HydSystemSample_RecoveryMainPvcPipe } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_RecoveryMainPvcPipe";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject({
  knownTypes: [
    HydSystemSample_CatchmentWaterTank,
    HydSystemSample_CatchmentWaterPump,
    HydSystemSample_DrainMainPvcPipe,
    HydSystemSample_RecoveryWaterTank,
    HydSystemSample_RecoveryWaterPump,
    HydSystemSample_RecoveryWaterFilter,
    HydSystemSample_RecoveryMainPvcPipe,
  ],
})
export class HydSystemPart_HydDrain extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public catchmentWaterTankSample: HydSystemSample_CatchmentWaterTank; // 집수탱크 샘플
  public catchmentWaterPumpSample: HydSystemSample_CatchmentWaterPump; // 집수탱크 수중 펌프 샘플
  public drainMainPvcPipeSample: HydSystemSample_DrainMainPvcPipe; // 배수 주관 샘플
  public recoveryWaterTankSample: HydSystemSample_RecoveryWaterTank; // 회수탱크 샘플
  public recoveryWaterPumpSample: HydSystemSample_RecoveryWaterPump; // 회수탱크 수중 펌프 샘플
  public recoveryWaterFilterSample: HydSystemSample_RecoveryWaterFilter; // 회수탱크 여과기 샘플
  public recoveryMainPvcPipeSample: HydSystemSample_RecoveryMainPvcPipe; // 회수 주관 샘플

  // 아이템
  public catchmentWaterTank_waterTank: ItemWaterTank;
  public catchmentWaterPump_waterPump: ItemWaterPump;
  public catchmentWaterPump_flexibleHose: ItemWaterPumpHose;
  public drainMain_pvcPipe: ItemDrainPipe;
  public drainMain_pvcEnd: ItemPvcConnector;
  public drainMain_pvcElbow: ItemPvcConnector;
  public drainMain_pvcExactT: ItemPvcConnector;
  public recoveryWaterTank_waterTank: ItemWaterTank;
  public recoveryWaterPump_waterPump: ItemWaterPump;
  public recoveryWaterPump_flexibleHose: ItemWaterPumpHose;
  public recoveryWaterFilter_waterFilter: ItemWaterFilter;
  public recoveryMain_pvcPipe: ItemDrainPipe;
  public recoveryMain_pvcDifferentT: ItemPvcConnector;
  public recoveryMain_pvcEnd: ItemPvcConnector;
  public recoveryMain_pvcElbow: ItemPvcConnector;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _drainMainLength: number = 0; // 배수 주관 길이
  @jsonMember(Number)
  public _recoveryMainLength: number = 0; // 회수 주관 길이
  @jsonMember(Boolean)
  public _waterPumpSelected: boolean; // 수중 펌프 선택
  @jsonMember(Boolean)
  public _recoveryWaterFilterSelected: boolean; // 회수 여과기 선택

  /**
   * 배수 주관 길이
   */
  public get drainMainLength(): number {
    return this._drainMainLength;
  }

  //
  public set drainMainLength(value: number) {
    this._drainMainLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 회수 주관 길이
   */
  public get recoveryMainLength(): number {
    return this._recoveryMainLength;
  }

  //
  public set recoveryMainLength(value: number) {
    this._recoveryMainLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 수중 펌프 선택
   */
  public get waterPumpSelected(): boolean {
    return this._waterPumpSelected;
  }

  //
  public set waterPumpSelected(value: boolean) {
    this._waterPumpSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 회수 여과기 선택
   */
  public get recoveryWaterFilterSelected(): boolean {
    return this._recoveryWaterFilterSelected;
  }

  //
  public set recoveryWaterFilterSelected(value: boolean) {
    this._recoveryWaterFilterSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.catchmentWaterTankSample = new HydSystemSample_CatchmentWaterTank();
    this.catchmentWaterPumpSample = new HydSystemSample_CatchmentWaterPump();
    this.drainMainPvcPipeSample = new HydSystemSample_DrainMainPvcPipe();
    this.recoveryWaterTankSample = new HydSystemSample_RecoveryWaterTank();
    this.recoveryWaterPumpSample = new HydSystemSample_RecoveryWaterPump();
    this.recoveryWaterFilterSample = new HydSystemSample_RecoveryWaterFilter();
    this.recoveryMainPvcPipeSample = new HydSystemSample_RecoveryMainPvcPipe();

    this.sampleAC = [
      this.catchmentWaterTankSample,
      this.catchmentWaterPumpSample,
      this.drainMainPvcPipeSample,
      this.recoveryWaterTankSample,
      this.recoveryWaterPumpSample,
      this.recoveryWaterFilterSample,
      this.recoveryMainPvcPipeSample,
    ];

    // 아이템
    this.catchmentWaterTank_waterTank = new ItemWaterTank();
    this.catchmentWaterPump_waterPump = new ItemWaterPump();
    this.catchmentWaterPump_flexibleHose = new ItemWaterPumpHose();
    this.drainMain_pvcPipe = new ItemDrainPipe();
    this.drainMain_pvcEnd = new ItemPvcConnector();
    this.drainMain_pvcElbow = new ItemPvcConnector();
    this.drainMain_pvcExactT = new ItemPvcConnector();
    this.recoveryWaterTank_waterTank = new ItemWaterTank();
    this.recoveryWaterPump_waterPump = new ItemWaterPump();
    this.recoveryWaterPump_flexibleHose = new ItemWaterPumpHose();
    this.recoveryWaterFilter_waterFilter = new ItemWaterFilter();
    this.recoveryMain_pvcPipe = new ItemDrainPipe();
    this.recoveryMain_pvcDifferentT = new ItemPvcConnector();
    this.recoveryMain_pvcEnd = new ItemPvcConnector();
    this.recoveryMain_pvcElbow = new ItemPvcConnector();

    this.itemAC = [
      this.catchmentWaterTank_waterTank,
      this.catchmentWaterPump_waterPump,
      this.catchmentWaterPump_flexibleHose,
      this.drainMain_pvcPipe,
      this.drainMain_pvcEnd,
      this.drainMain_pvcElbow,
      this.drainMain_pvcExactT,
      this.recoveryWaterTank_waterTank,
      this.recoveryWaterPump_waterPump,
      this.recoveryWaterPump_flexibleHose,
      this.recoveryWaterFilter_waterFilter,
      this.recoveryMain_pvcPipe,
      this.recoveryMain_pvcDifferentT,
      this.recoveryMain_pvcEnd,
      this.recoveryMain_pvcElbow,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.catchmentWaterTankSample = <HydSystemSample_CatchmentWaterTank>this.sampleAC[0];
    this.catchmentWaterPumpSample = <HydSystemSample_CatchmentWaterPump>this.sampleAC[1];
    this.drainMainPvcPipeSample = <HydSystemSample_DrainMainPvcPipe>this.sampleAC[2];
    this.recoveryWaterTankSample = <HydSystemSample_RecoveryWaterTank>this.sampleAC[3];
    this.recoveryWaterPumpSample = <HydSystemSample_RecoveryWaterPump>this.sampleAC[4];
    this.recoveryWaterFilterSample = <HydSystemSample_RecoveryWaterFilter>this.sampleAC[5];
    this.recoveryMainPvcPipeSample = <HydSystemSample_RecoveryMainPvcPipe>this.sampleAC[6];

    // 아이템
    this.catchmentWaterTank_waterTank = <ItemWaterTank>this.itemAC[0];
    this.catchmentWaterPump_waterPump = <ItemWaterPump>this.itemAC[1];
    this.catchmentWaterPump_flexibleHose = <ItemWaterPumpHose>this.itemAC[2];
    this.drainMain_pvcPipe = <ItemDrainPipe>this.itemAC[3];
    this.drainMain_pvcEnd = <ItemPvcConnector>this.itemAC[4];
    this.drainMain_pvcElbow = <ItemPvcConnector>this.itemAC[5];
    this.drainMain_pvcExactT = <ItemPvcConnector>this.itemAC[6];
    this.recoveryWaterTank_waterTank = <ItemWaterTank>this.itemAC[7];
    this.recoveryWaterPump_waterPump = <ItemWaterPump>this.itemAC[8];
    this.recoveryWaterPump_flexibleHose = <ItemWaterPumpHose>this.itemAC[9];
    this.recoveryWaterFilter_waterFilter = <ItemWaterFilter>this.itemAC[10];
    this.recoveryMain_pvcPipe = <ItemDrainPipe>this.itemAC[11];
    this.recoveryMain_pvcDifferentT = <ItemPvcConnector>this.itemAC[12];
    this.recoveryMain_pvcEnd = <ItemPvcConnector>this.itemAC[13];
    this.recoveryMain_pvcElbow = <ItemPvcConnector>this.itemAC[14];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param waterPumpSelected: boolean 수중 펌프 선택
   * @param recoveryWaterFilterSelected: boolean 회수 여과기 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    waterPumpSelected: boolean = false,
    recoveryWaterFilterSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._waterPumpSelected = waterPumpSelected;
    this._recoveryWaterFilterSelected = recoveryWaterFilterSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.catchmentWaterTankSample.setDefaultData(
        0,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "집수탱크",
        "일반, 사각, 0.4ton, -, -, -",
      );
      this.catchmentWaterPumpSample.setDefaultData(
        1,
        CONST.ITEM_ID_WATER_PUMP,
        CONST.ITEM_NAME_WATER_PUMP,
        "집수탱크 수중 펌프",
        "수중, 1HP",
        "윌로펌프, PD-751MA, 1HP, 단상, 50mm",
      );
      this.drainMainPvcPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PVC_PIPE,
        CONST.ITEM_NAME_PVC_PIPE,
        "배수 주관",
        "일반, 100mm, 4m",
      );
      this.recoveryWaterTankSample.setDefaultData(
        3,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "회수탱크",
        "일반, 사각, 0.4ton, -, -, -",
      );
      this.recoveryWaterPumpSample.setDefaultData(
        4,
        CONST.ITEM_ID_WATER_PUMP,
        CONST.ITEM_NAME_WATER_PUMP,
        "회수탱크 수중 펌프",
        "수중, 1HP",
        "윌로펌프, PD-751MA, 1HP, 단상, 50mm",
      );
      this.recoveryWaterFilterSample.setDefaultData(
        5,
        CONST.ITEM_ID_WATER_FILTER,
        CONST.ITEM_NAME_WATER_FILTER,
        "회수탱크 여과기",
        "일반",
        "한슬테크, SAFE, 메쉬(망), 40mm",
      );
      this.recoveryMainPvcPipeSample.setDefaultData(
        6,
        CONST.ITEM_ID_PVC_PIPE,
        CONST.ITEM_NAME_PVC_PIPE,
        "회수 주관",
        "일반, 50mm, 4m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.catchmentWaterTank_waterTank.setDefaultData(
        0,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "집수탱크",
        "일반, 사각, 0.4ton, -, -, -",
      );
      this.catchmentWaterPump_waterPump.setDefaultData(
        1,
        CONST.ITEM_ID_WATER_PUMP,
        CONST.ITEM_NAME_WATER_PUMP,
        "집수탱크 수중 펌프",
        "수중, 1HP",
        "윌로펌프, PD-751MA, 1HP, 단상, 50mm",
      );
      this.catchmentWaterPump_flexibleHose.setDefaultData(
        2,
        CONST.ITEM_ID_FLEXIBLE_HOSE,
        CONST.ITEM_NAME_FLEXIBLE_HOSE,
        "집수탱크 수중 펌프",
        "일반, 30.5mm, 20m",
      );
      this.drainMain_pvcPipe.setDefaultData(
        3,
        CONST.ITEM_ID_PVC_PIPE,
        CONST.ITEM_NAME_PVC_PIPE,
        "배수 주관",
        "일반, 50mm, 4m",
      );
      this.drainMain_pvcEnd.setDefaultData(
        4,
        CONST.ITEM_ID_PVC_END,
        CONST.ITEM_NAME_PVC_END,
        "배수 주관",
        "PVC, 일반, 50mm, -",
      );
      this.drainMain_pvcElbow.setDefaultData(
        5,
        CONST.ITEM_ID_PVC_ELBOW,
        CONST.ITEM_NAME_PVC_ELBOW,
        "배수 주관",
        "PVC, 일반, 50mm, 50mm",
      );
      this.drainMain_pvcExactT.setDefaultData(
        6,
        CONST.ITEM_ID_PVC_EXACT_T,
        CONST.ITEM_NAME_PVC_EXACT_T,
        "배수 주관",
        "PVC, 일반, 50mm, 50mm",
      );
      this.recoveryWaterTank_waterTank.setDefaultData(
        7,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "회수탱크",
        "일반, 사각, 0.4ton, -, -, -",
      );
      this.recoveryWaterPump_waterPump.setDefaultData(
        8,
        CONST.ITEM_ID_WATER_PUMP,
        CONST.ITEM_NAME_WATER_PUMP,
        "회수탱크 수중 펌프",
        "수중, 1HP",
        "윌로펌프, PD-751MA, 1HP, 단상, 50mm",
      );
      this.recoveryWaterPump_flexibleHose.setDefaultData(
        9,
        CONST.ITEM_ID_FLEXIBLE_HOSE,
        CONST.ITEM_NAME_FLEXIBLE_HOSE,
        "회수탱크 수중 펌프",
        "일반, 30.5mm, 20m",
      );
      this.recoveryWaterFilter_waterFilter.setDefaultData(
        10,
        CONST.ITEM_ID_WATER_FILTER,
        CONST.ITEM_NAME_WATER_FILTER,
        "회수탱크 여과기",
        "일반",
        "한슬테크, SAFE, 메쉬(망), 40mm",
      );
      this.recoveryMain_pvcPipe.setDefaultData(
        11,
        CONST.ITEM_ID_PVC_PIPE,
        CONST.ITEM_NAME_PVC_PIPE,
        "회수 주관",
        "일반, 100mm, 4m",
      );
      this.recoveryMain_pvcDifferentT.setDefaultData(
        12,
        CONST.ITEM_ID_PVC_DIFFERENT_T,
        CONST.ITEM_NAME_PVC_DIFFERENT_T,
        "회수 주관",
        "PVC, 일반, 100mm, 50mm",
      );
      this.recoveryMain_pvcEnd.setDefaultData(
        13,
        CONST.ITEM_ID_PVC_END,
        CONST.ITEM_NAME_PVC_END,
        "회수 주관",
        "PVC, 일반, 100mm, -",
      );
      this.recoveryMain_pvcElbow.setDefaultData(
        14,
        CONST.ITEM_ID_PVC_ELBOW,
        CONST.ITEM_NAME_PVC_ELBOW,
        "회수 주관",
        "PVC, 일반, 100mm, 100mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;
    const hydCultivationPosition: HydCultivationPosition = this.struct.hydCultivationWork.level1.wholePosition;

    /// //////// 선택, 가시성 ///////////
    this.catchmentWaterTank_waterTank.selected = true;
    this.catchmentWaterTank_waterTank.visible = true;
    this.catchmentWaterPump_waterPump.selected = true;
    this.catchmentWaterPump_waterPump.visible = true;
    this.catchmentWaterPump_flexibleHose.selected = true;
    this.catchmentWaterPump_flexibleHose.visible = true;
    this.drainMain_pvcPipe.selected = true;
    this.drainMain_pvcPipe.visible = true;
    this.drainMain_pvcEnd.selected = true;
    this.drainMain_pvcEnd.visible = true;
    this.drainMain_pvcElbow.selected = true;
    this.drainMain_pvcElbow.visible = true;
    this.drainMain_pvcExactT.selected = true;
    this.drainMain_pvcExactT.visible = true;
    if (hydSystemPosition.cycleType === CONST.LB_HYD_SYSTEM_CYCLE_TYPE_ACYCLE) {
      this.recoveryWaterTank_waterTank.selected = false;
      this.recoveryWaterTank_waterTank.visible = false;
      this.recoveryWaterPump_waterPump.selected = false;
      this.recoveryWaterPump_waterPump.visible = false;
      this.recoveryWaterPump_flexibleHose.selected = false;
      this.recoveryWaterPump_flexibleHose.visible = false;
      this.recoveryWaterFilter_waterFilter.selected = false;
      this.recoveryWaterFilter_waterFilter.visible = false;
      this.recoveryMain_pvcPipe.selected = false;
      this.recoveryMain_pvcPipe.visible = false;
      this.recoveryMain_pvcDifferentT.selected = false;
      this.recoveryMain_pvcDifferentT.visible = false;
      this.recoveryMain_pvcEnd.selected = false;
      this.recoveryMain_pvcEnd.visible = false;
      this.recoveryMain_pvcElbow.selected = false;
      this.recoveryMain_pvcElbow.visible = false;
    } else if (hydSystemPosition.cycleType === CONST.LB_HYD_SYSTEM_CYCLE_TYPE_CYCLE) {
      this.recoveryWaterTank_waterTank.selected = false;
      this.recoveryWaterTank_waterTank.visible = false;
      this.recoveryWaterPump_waterPump.selected = false;
      this.recoveryWaterPump_waterPump.visible = false;
      this.recoveryWaterPump_flexibleHose.selected = false;
      this.recoveryWaterPump_flexibleHose.visible = false;
      this.recoveryWaterFilter_waterFilter.selected = true;
      this.recoveryWaterFilter_waterFilter.visible = true;
      this.recoveryMain_pvcPipe.selected = true;
      this.recoveryMain_pvcPipe.visible = true;
      this.recoveryMain_pvcDifferentT.selected = true;
      this.recoveryMain_pvcDifferentT.visible = true;
      this.recoveryMain_pvcEnd.selected = true;
      this.recoveryMain_pvcEnd.visible = true;
      this.recoveryMain_pvcElbow.selected = true;
      this.recoveryMain_pvcElbow.visible = true;
    }

    /// ////////  수량 ///////////
    this.catchmentWaterTank_waterTank.designQuantity = this.catchmentWaterTankSample.designQuantity;

    this.catchmentWaterPump_waterPump.designQuantity = this.catchmentWaterPumpSample.designQuantity;

    this.catchmentWaterPump_flexibleHose.designQuantity = this.catchmentWaterPump_waterPump.designQuantity;

    this.drainMain_pvcPipe.designQuantity = this.drainMainLength / this.drainMain_pvcPipe.specLength;

    this.drainMain_pvcEnd.designQuantity = hydCultivationPosition.bedPart.totalBedLineNumber;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.drainMain_pvcElbow.designQuantity = 0;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.drainMain_pvcElbow.designQuantity = hydCultivationPosition.bedPart.totalBedLineNumber * 2;
    }

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.drainMain_pvcExactT.designQuantity = 0;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.drainMain_pvcExactT.designQuantity = hydCultivationPosition.bedPart.totalBedLineNumber * 2;
    }

    this.recoveryWaterTank_waterTank.designQuantity = this.recoveryWaterTankSample.designQuantity;

    this.recoveryWaterPump_waterPump.designQuantity = this.recoveryWaterPumpSample.designQuantity;

    this.recoveryWaterPump_flexibleHose.designQuantity = this.recoveryWaterPump_waterPump.designQuantity;

    this.recoveryWaterFilter_waterFilter.designQuantity = this.recoveryWaterFilterSample.designQuantity;

    this.recoveryMain_pvcPipe.designQuantity = this.recoveryMainLength / this.recoveryMain_pvcPipe.specLength;

    this.recoveryMain_pvcDifferentT.designQuantity = this.catchmentWaterTank_waterTank.designQuantity;

    this.recoveryMain_pvcEnd.designQuantity = this.recoveryMain_pvcDifferentT.designQuantity;

    this.recoveryMain_pvcElbow.designQuantity = this.catchmentWaterTank_waterTank.designQuantity;
  }

  /**
   * 알고리즘: 샘플 수량 <- 양액제어 구역(양액시스템)
   */
  public algorithm_sampleDesignQuantity(): void {
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;

    this.catchmentWaterTankSample.designQuantity = hydSystemPosition.nutrientControlArea;
    this.recoveryWaterTankSample.designQuantity = hydSystemPosition.nutrientControlArea;
  }

  /**
   * 알고리즘: 배수 주관 길이 <-  형태(양액시스템), 재배부 폭(양액시스템), 양액 제어구역(양액시스템), 베드 폭(베드 파트), 베드 높이(베드 파트), 베드 매설 깊이(베드 파트), 총 베드 줄수(베드 파트)
   */
  public algorithm_drainMainLength(): void {
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;
    const hydCultivationPosition: HydCultivationPosition = this.struct.hydCultivationWork.level1.wholePosition;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.drainMainLength = CommonUtil.roundUp(
        hydSystemPosition.cultivationWidth * hydSystemPosition.nutrientControlArea * 1.1,
        1,
      );
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      const bedLength =
        (hydCultivationPosition.bedPart.bedWidth +
          (hydCultivationPosition.bedPart.bedHeight + hydCultivationPosition.bedPart.bedDepthLaying) * 2) *
        hydCultivationPosition.bedPart.totalBedLineNumber;

      this.drainMainLength = CommonUtil.roundUp(
        (hydSystemPosition.cultivationWidth * hydSystemPosition.nutrientControlArea + bedLength) * 1.1,
        1,
      );
    }
  }

  /**
   * 알고리즘: 회수 주관 길이 <- 순환 형태(양액시스템), 재배부 길이(양액시스템), 양액 제어구역(양액시스템)
   */
  public algorithm_recoveryMainLength(): void {
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;

    if (hydSystemPosition.cycleType === CONST.LB_HYD_SYSTEM_CYCLE_TYPE_CYCLE) {
      this.recoveryMainLength = CommonUtil.roundUp(
        hydSystemPosition.cultivationLength * hydSystemPosition.nutrientControlArea * 1.1,
        1,
      );
    } else if (hydSystemPosition.cycleType === CONST.LB_HYD_SYSTEM_CYCLE_TYPE_ACYCLE) {
      this.recoveryMainLength = 0;
    }
  }

  /**
   * 알고리즘: 회수 여과기 선택 <- 순환 형태(양액시스템)
   */
  public algorithm_recoveryWaterFilterSelected(): void {
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;

    if (hydSystemPosition.cycleType === CONST.LB_HYD_SYSTEM_CYCLE_TYPE_ACYCLE) {
      this.recoveryWaterFilterSelected = false;
    } else if (hydSystemPosition.cycleType === CONST.LB_HYD_SYSTEM_CYCLE_TYPE_CYCLE) {
      this.recoveryWaterFilterSelected = true;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 : [집수탱크] 물탱크 <- 집수탱크 샘플
   */
  public algorithmSpec_catchmentWaterTank_waterTank(): void {
    this.catchmentWaterTank_waterTank.specs = this.catchmentWaterTankSample.specs;
  }

  /**
   * 규격 알고리즘 : [집수탱크 수중 펌프] 펌프 <- 집수탱크 수중 펌프 샘플
   */
  public algorithmSpec_catchmentWaterPump_waterPump(): void {
    this.catchmentWaterPump_waterPump.specs = this.catchmentWaterPumpSample.specs;
    this.catchmentWaterPump_waterPump.brands = this.catchmentWaterPumpSample.brands;
  }

  /**
   * 규격 알고리즘 : [집수탱크 수중 펌프] 플렉시블 호스
   */
  public algorithmSpec_catchmentWaterPump_flexibleHose(): void {}

  /**
   * 규격 알고리즘 : [배수 주관] PVC관 <- 배수 주관 샘플
   */
  public algorithmSpec_drainMain_pvcPipe(): void {
    this.drainMain_pvcPipe.specs = this.recoveryMainPvcPipeSample.specs;
  }

  /**
   * 규격 알고리즘 : [배수 주관] PVC 소제구 <- 배수 주관 샘플
   */
  public algorithmSpec_drainMain_pvcEnd(): void {
    this.drainMain_pvcEnd.specDiameter1 = this.recoveryMainPvcPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [배수 주관] PVC 엘보 <- 배수 주관 샘플
   */
  public algorithmSpec_drainMain_pvcElbow(): void {
    this.drainMain_pvcElbow.specDiameter1 = this.recoveryMainPvcPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [배수 주관] 정티 <- 배수 주관 샘플
   */
  public algorithmSpec_drainMain_pvcExactT(): void {
    this.drainMain_pvcExactT.specDiameter1 = this.recoveryMainPvcPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [회수탱크] 물탱크 <- 회수탱크 샘플
   */
  public algorithmSpec_recoveryWaterTank_waterTank(): void {
    this.recoveryWaterTank_waterTank.specs = this.recoveryWaterTankSample.specs;
  }

  /**
   * 규격 알고리즘 : [회수탱크 수중 펌프] 펌프 <- 회수탱크 수중 펌프 샘플
   */
  public algorithmSpec_recoveryWaterPump_waterPump(): void {
    this.recoveryWaterPump_waterPump.specs = this.recoveryWaterPumpSample.specs;
    this.recoveryWaterPump_waterPump.brands = this.recoveryWaterPumpSample.brands;
  }

  /**
   * 규격 알고리즘 : [회수탱크 수중 펌프] 플렉시블 호스
   */
  public algorithmSpec_recoveryWaterPump_flexibleHose(): void {}

  /**
   * 규격 알고리즘 : [회수탱크 여과기] 여과기 <- 회수탱크 여과기 샘플
   */
  public algorithmSpec_recoveryWaterFilter_waterFilter(): void {
    this.recoveryWaterFilter_waterFilter.specs = this.recoveryWaterFilterSample.specs;
    this.recoveryWaterFilter_waterFilter.brands = this.recoveryWaterFilterSample.brands;
  }

  /**
   * 규격 알고리즘 : [회수 주관] PVC관 <- 회수 주관 샘플
   */
  public algorithmSpec_recoveryMain_pvcPipe(): void {
    this.recoveryMain_pvcPipe.specs = this.recoveryMainPvcPipeSample.specs;
  }

  /**
   * 규격 알고리즘 : [회수 주관] PVC 이경티 <- 회수 주관 샘플, 배수 주관 샘플
   */
  public algorithmSpec_recoveryMain_pvcDifferentT(): void {
    if (this.recoveryMainPvcPipeSample.specDiameter !== this.drainMainPvcPipeSample.specDiameter) {
      this.recoveryMain_pvcDifferentT.specDiameter1 = this.drainMainPvcPipeSample.specDiameter;
      this.recoveryMain_pvcDifferentT.specDiameter2 = this.recoveryMainPvcPipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘 : [회수 주관] PVC 소제구 <- 회수 주관 샘플
   */
  public algorithmSpec_recoveryMain_pvcEnd(): void {
    this.recoveryMain_pvcEnd.specDiameter1 = this.drainMainPvcPipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [회수 주관] PVC 엘보 <- 회수 주관 샘플
   */
  public algorithmSpec_recoveryMain_pvcElbow(): void {
    this.recoveryMain_pvcElbow.specDiameter1 = this.drainMainPvcPipeSample.specDiameter;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
