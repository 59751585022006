import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemPipeBendingExpense } from "vhows-design/src/object/design/item/list/ItemPipeBendingExpense";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";
import { SkyFrameSampleIL_RafterPipe } from "vhows-design/src/object/design/skylight/frame/il/SkyFrameSampleIL_RafterPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-22
 */
@jsonObject({
  knownTypes: [SkyFrameSampleIL_RafterPipe],
})
export class SkyFramePartIL_Rafter extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public skyRafterPipeSample: SkyFrameSampleIL_RafterPipe; // 천창 서까래 파이프 샘플

  // 아이템
  public skyRafter_pipe: ItemPipe;
  public skyRafter_pipeBendingExpense: ItemPipeBendingExpense;
  public skyRafterAndStraight_tHolder: ItemHolder;
  public skyRafterAndSkyColumn_diagonalTHolder: ItemHolder;
  public skyRafter_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _rafterInterval: number; // 서까래 간격
  @jsonMember(Number)
  public _rafterLength: number; // 서까래 길이
  @jsonMember(Number)
  public _shoulderHeight: number; // 어깨 높이
  @jsonMember(Number)
  public _columnLength: number; // 기둥 길이
  @jsonMember(Number)
  public _rafterQuantity: number; // 서까래 수량
  @jsonMember(Number)
  public _rafterTotalLength: number = 0; // 최종 서까래 길이

  /**
   * 서까래 간격
   */
  public get rafterInterval(): number {
    return this._rafterInterval;
  }

  //
  public set rafterInterval(value: number) {
    this._rafterInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_rafterQuantity();
    this.algorithmPart();
  }

  /**
   * 서까래 길이
   */
  public get rafterLength(): number {
    return this._rafterLength;
  }

  //
  public set rafterLength(value: number) {
    this._rafterLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.skyRafterPipeSample.algorithmSpec();
    this.algorithm_rafterTotalLength();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 천창 피복
    this.struct.skyCoverWorkIL.level1.skylightPosition.vinylPart.algorithmPart();

    // 밴드끈
    for (const bandstringPosition of this.struct.bandstringWorkIL.level1.positionAC as BandstringPositionIL[]) {
      bandstringPosition.bandstringPart.algorithm_bandstringLength();
      bandstringPosition.polyPart.algorithm_bandstringLength();
    }
  }

  /**
   * 어깨 높이
   */
  public get shoulderHeight(): number {
    return this._shoulderHeight;
  }

  //
  public set shoulderHeight(value: number) {
    this._shoulderHeight = CommonUtil.fixFloat(value);
  }

  /**
   * 기둥 길이
   */
  public get columnLength(): number {
    return this._columnLength;
  }

  //
  public set columnLength(value: number) {
    this._columnLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.skyRafterPipeSample.algorithmSpec();
    this.algorithm_rafterTotalLength();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 천창 피복
    this.struct.skyCoverWorkIL.level1.skylightPosition.vinylPart.algorithmPart();
  }

  /**
   * 서까래 수량
   */
  public get rafterQuantity(): number {
    return this._rafterQuantity;
  }

  //
  public set rafterQuantity(value: number) {
    this._rafterQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<SkyFramePositionIL>this.position).straightPart.algorithmPart();
  }

  /**
   * 최종 서까래 길이
   */
  public get rafterTotalLength(): number {
    return this._rafterTotalLength;
  }

  //
  public set rafterTotalLength(value: number) {
    this._rafterTotalLength = CommonUtil.fixFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.skyRafterPipeSample = new SkyFrameSampleIL_RafterPipe();

    this.sampleAC = [this.skyRafterPipeSample];

    // 아이템
    this.skyRafter_pipe = new ItemPipe();
    this.skyRafter_pipeBendingExpense = new ItemPipeBendingExpense();
    this.skyRafterAndStraight_tHolder = new ItemHolder();
    this.skyRafterAndSkyColumn_diagonalTHolder = new ItemHolder();
    this.skyRafter_screw = new ItemScrew();

    this.itemAC = [
      this.skyRafter_pipe,
      this.skyRafter_pipeBendingExpense,
      this.skyRafterAndStraight_tHolder,
      this.skyRafterAndSkyColumn_diagonalTHolder,
      this.skyRafter_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.skyRafterPipeSample = <SkyFrameSampleIL_RafterPipe>this.sampleAC[0];

    // 아이템
    this.skyRafter_pipe = <ItemPipe>this.itemAC[0];
    this.skyRafter_pipeBendingExpense = <ItemPipeBendingExpense>this.itemAC[1];
    this.skyRafterAndStraight_tHolder = <ItemHolder>this.itemAC[2];
    this.skyRafterAndSkyColumn_diagonalTHolder = <ItemHolder>this.itemAC[3];
    this.skyRafter_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param rafterInterval: number 서까래 간격
   * @param rafterLength: number 서까래 길이
   * @param shoulderHeight: number 어깨 높이
   * @param columnLength: number 기둥 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    rafterInterval: number = 0,
    rafterLength: number = 0,
    shoulderHeight: number = 0,
    columnLength: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._rafterInterval = rafterInterval;
    this._rafterLength = rafterLength;
    this._shoulderHeight = shoulderHeight;
    this._columnLength = columnLength;

    // 샘플
    if (this.level.index >= 0) {
      this.skyRafterPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 서까래",
        "원형, 일반, 25.4mm, 1.5T, 3.3m, 벤딩",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.skyRafter_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 서까래",
        "원형, 일반, 25.4mm, 1.5T, 3.3m, 벤딩",
      );
      this.skyRafter_pipeBendingExpense.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "천창 서까래",
        "원형, 25.4mm",
      );
      this.skyRafterAndStraight_tHolder.setDefaultData(
        2,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "천창 서까래 + 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.skyRafterAndSkyColumn_diagonalTHolder.setDefaultData(
        3,
        CONST.ITEM_ID_DIAGONAL_T_HOLDER,
        CONST.ITEM_NAME_DIAGONAL_T_HOLDER,
        "천창 서까래 + 천창 기둥",
        "원형, 일반, 25mm, 25mm",
      );
      this.skyRafter_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "천창 서까래",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 5 && (<ItemHolder>this.itemAC[3]).productId === CONST.ITEM_ID_SADDLE_HOLDER) {
      // [서까래 + 가로대 (기초 골조 강화)] 새들 고정구 제거
      this.itemAC.splice(3, 1);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 4 && (<ItemHolder>this.itemAC[1]).productId === CONST.ITEM_ID_T_HOLDER) {
      // 기존 규격
      const specsArray: string[] = (<ItemPipe>this.itemAC[0]).specs.split(CONST.DELIMITER_SPEC);
      // [천창 서까래] 파이프 벤딩비
      const skyRafter_pipeBendingExpense: ItemPipeBendingExpense = new ItemPipeBendingExpense();
      skyRafter_pipeBendingExpense.setAssociation(design, struct, work, level, position, this);
      skyRafter_pipeBendingExpense.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "천창 서까래",
        `원형, ${specsArray[2]}`,
      );
      skyRafter_pipeBendingExpense.selected = false;
      skyRafter_pipeBendingExpense.visible = false;
      this.itemAC.splice(1, 0, skyRafter_pipeBendingExpense);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_rafterQuantity();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithm_rafterInterval();
    this.algorithmSpec_skyRafterAndStraight_tHolder();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부

    /// //////// 선택, 가시성 ///////////

    this.skyRafter_pipe.selected = true;
    this.skyRafter_pipe.visible = true;
    if (this.skyRafter_pipe.specBending === CONST.ITEM_SPEC_VALUE_BENDING) {
      this.skyRafter_pipeBendingExpense.selected = this.design.basic.bendingExpenseFlag;
      this.skyRafter_pipeBendingExpense.visible = true;
    } else {
      this.skyRafter_pipeBendingExpense.selected = false;
      this.skyRafter_pipeBendingExpense.visible = false;
    }
    this.skyRafterAndStraight_tHolder.selected = true;
    this.skyRafterAndStraight_tHolder.visible = true;
    if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.skyRafterAndSkyColumn_diagonalTHolder.selected = true;
      this.skyRafterAndSkyColumn_diagonalTHolder.visible = true;
    } else {
      this.skyRafterAndSkyColumn_diagonalTHolder.selected = false;
      this.skyRafterAndSkyColumn_diagonalTHolder.visible = false;
    }
    this.skyRafter_screw.selected = true;
    this.skyRafter_screw.visible = true;

    /// //////// 수치 ///////////

    let rawRafterLength: number;
    if (
      this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
    ) {
      rawRafterLength = this.rafterLength;
    } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      rawRafterLength = this.rafterLength + this.columnLength;
    }

    /// //////// 수량 ///////////

    if (this.skyRafter_pipe.specLength > rawRafterLength) {
      this.skyRafter_pipe.designQuantity =
        this.rafterQuantity / CommonUtil.roundDown(this.skyRafter_pipe.specLength / rawRafterLength);
    } else {
      this.skyRafter_pipe.designQuantity = this.rafterQuantity;
    }

    this.skyRafter_pipeBendingExpense.designQuantity = this.rafterQuantity;

    this.skyRafterAndStraight_tHolder.designQuantity = this.rafterQuantity * 2 * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.skyRafterAndSkyColumn_diagonalTHolder.designQuantity =
      this.rafterQuantity * CONST.NUM_EXTRA_RATE_DIAGONAL_T_HOLDER;

    this.skyRafter_screw.designQuantity =
      ((this.skyRafterAndStraight_tHolder.getSelectedQuantity() * 3 +
        this.skyRafterAndSkyColumn_diagonalTHolder.getSelectedQuantity() * 3) /
        this.skyRafter_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 서까래 간격 <- 서까래 간격(기초 골조), 설치 중수
   */
  public algorithm_rafterInterval(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;
      if (skyFramePosition.skylightLevel < 2) {
        this.rafterInterval = this.struct.roofWorkIL.level1.roofPosition.rafterPart.rafterInterval;
      } else {
        this.rafterInterval =
          (<RoofLevelIL>this.struct.roofWorkIL.levelAC[skyFramePosition.skylightLevel - 1]).roofPosition.rafterPart
            .rafterInterval * 3;
      }
    }
  }

  /**
   * 알고리즘: 서까래 길이, 어깨 높이, 기둥 길이 <- 형태(위치)
   */
  public algorithm_rafterLengthAndSkyColumnLength(): void {
    if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH) {
      this.rafterLength = 3.3;
      this.shoulderHeight = 0.5;
      this.columnLength = 0;
    } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE) {
      this.rafterLength = 3.0;
      this.shoulderHeight = 0;
      this.columnLength = 0.0;
    } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.rafterLength = 2.5;
      this.shoulderHeight = 0;
      this.columnLength = 0.8;
    }
  }

  /**
   * 알고리즘: 서까래 수량 <- 서까래 간격, 길이(기본정보), 동수(기본정보)
   */
  public algorithm_rafterQuantity(): void {
    const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;

    this.rafterQuantity =
      Math.ceil(skyFramePosition.skylightLength / this.rafterInterval + 1) *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_RAFTER_PIPE;
  }

  /**
   * 알고리즘: 최종 서까래 길이 <- 서까래 길이, 기둥 길이
   */
  public algorithm_rafterTotalLength(): void {
    this.rafterTotalLength = this.rafterLength + this.columnLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 서까래] 파이프 <- 천창 서까래 파이프 샘플
   */
  public algorithmSpec_skyRafter_pipe(): void {
    this.skyRafter_pipe.specs = this.skyRafterPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 서까래] 파이프 벤딩비 <- 천창 서까래 파이프 샘플
   */
  public algorithmSpec_skyRafter_pipeBendingExpense(): void {
    this.skyRafter_pipeBendingExpense.specPipeType = this.skyRafterPipeSample.specPipeType;
    this.skyRafter_pipeBendingExpense.specPipeCrossSize = this.skyRafterPipeSample.specPipeCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 서까래 + 가로대] T 고정구 <- 천창 서까래 파이프 샘플, 가로대 파이프 샘플(지붕 골조)
   */
  public algorithmSpec_skyRafterAndStraight_tHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      this.skyRafterAndStraight_tHolder.specPipeType =
        this.struct.roofWorkIL.level1.roofPosition.straightPart.straightPipeSample.specPipeType;
      this.skyRafterAndStraight_tHolder.specCrossSize1 =
        this.struct.roofWorkIL.level1.roofPosition.straightPart.straightPipeSample.specCrossSize;
      this.skyRafterAndStraight_tHolder.specCrossSize2 = this.skyRafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [천창 서까래 + 천창 기둥] 대각T 고정구 <- 천창 서까래 파이프 샘플
   */
  public algorithmSpec_skyRafterAndSkyColumn_diagonalTHolder(): void {
    this.skyRafterAndSkyColumn_diagonalTHolder.specPipeType = this.skyRafterPipeSample.specPipeType;
    this.skyRafterAndSkyColumn_diagonalTHolder.specCrossSize1 = this.skyRafterPipeSample.specCrossSize;
    this.skyRafterAndSkyColumn_diagonalTHolder.specCrossSize2 = this.skyRafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 서까래] 나사 <- 공통 샘플
   */
  public algorithmSpec_skyRafter_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
