import { CONST } from "vhows-design/src/common/constant/CONST";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ItemAirFlowFan } from "vhows-design/src/object/design/item/list/ItemAirFlowFan";
import { ItemBandstring } from "vhows-design/src/object/design/item/list/ItemBandstring";
import { ItemBearing } from "vhows-design/src/object/design/item/list/ItemBearing";
import { ItemBuildingDiscountCost } from "vhows-design/src/object/design/item/list/ItemBuildingDiscountCost";
import { ItemBuildingMis } from "vhows-design/src/object/design/item/list/ItemBuildingMis";
import { ItemCarrier } from "vhows-design/src/object/design/item/list/ItemCarrier";
import { ItemCashmilon } from "vhows-design/src/object/design/item/list/ItemCashmilon";
import { ItemCement } from "vhows-design/src/object/design/item/list/ItemCement";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemControllerSensor } from "vhows-design/src/object/design/item/list/ItemControllerSensor";
import { ItemCordWire } from "vhows-design/src/object/design/item/list/ItemCordWire";
import { ItemCurtainOther } from "vhows-design/src/object/design/item/list/ItemCurtainOther";
import { ItemCustomExpense } from "vhows-design/src/object/design/item/list/ItemCustomExpense";
import { ItemCustomLaborCost } from "vhows-design/src/object/design/item/list/ItemCustomLaborCost";
import { ItemCustomMaterial } from "vhows-design/src/object/design/item/list/ItemCustomMaterial";
import { ItemDiamondClamp } from "vhows-design/src/object/design/item/list/ItemDiamondClamp";
import { ItemDoor } from "vhows-design/src/object/design/item/list/ItemDoor";
import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { ItemDrainPipeHanger } from "vhows-design/src/object/design/item/list/ItemDrainPipeHanger";
import { ItemDrainspoutBox } from "vhows-design/src/object/design/item/list/ItemDrainspoutBox";
import { ItemDripBarb } from "vhows-design/src/object/design/item/list/ItemDripBarb";
import { ItemDripButton } from "vhows-design/src/object/design/item/list/ItemDripButton";
import { ItemDripButtonAppendage } from "vhows-design/src/object/design/item/list/ItemDripButtonAppendage";
import { ItemDripConnect } from "vhows-design/src/object/design/item/list/ItemDripConnect";
import { ItemDripPipe } from "vhows-design/src/object/design/item/list/ItemDripPipe";
import { ItemDripValve } from "vhows-design/src/object/design/item/list/ItemDripValve";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ItemEquipmentExpense } from "vhows-design/src/object/design/item/list/ItemEquipmentExpense";
import { ItemFountainHose } from "vhows-design/src/object/design/item/list/ItemFountainHose";
import { ItemFountainHoseConnect } from "vhows-design/src/object/design/item/list/ItemFountainHoseConnect";
import { ItemFountainHoseHanger } from "vhows-design/src/object/design/item/list/ItemFountainHoseHanger";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemGroundCarrier } from "vhows-design/src/object/design/item/list/ItemGroundCarrier";
import { ItemRailInterval } from "vhows-design/src/object/design/item/list/ItemRailInterval";
import { ItemGutterHanger } from "vhows-design/src/object/design/item/list/ItemGutterHanger";
import { ItemHingedDoor } from "vhows-design/src/object/design/item/list/ItemHingedDoor";
import { ItemIntegratedController } from "vhows-design/src/object/design/item/list/ItemIntegratedController";
import { ItemIronPlateGutter } from "vhows-design/src/object/design/item/list/ItemIronPlateGutter";
import { ItemLagging } from "vhows-design/src/object/design/item/list/ItemLagging";
import { ItemManualSwitcher } from "vhows-design/src/object/design/item/list/ItemManualSwitcher";
import { ItemMat } from "vhows-design/src/object/design/item/list/ItemMat";
import { ItemMaterialDiscountCost } from "vhows-design/src/object/design/item/list/ItemMaterialDiscountCost";
import { ItemMiniSprinkler } from "vhows-design/src/object/design/item/list/ItemMiniSprinkler";
import { ItemMiniSprinklerDropGuard } from "vhows-design/src/object/design/item/list/ItemMiniSprinklerDropGuard";
import { ItemMiniSprinklerTubeSet } from "vhows-design/src/object/design/item/list/ItemMiniSprinklerTubeSet";
import { ItemNonwoven } from "vhows-design/src/object/design/item/list/ItemNonwoven";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPadVinyl } from "vhows-design/src/object/design/item/list/ItemPadVinyl";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemPipeBendingExpense } from "vhows-design/src/object/design/item/list/ItemPipeBendingExpense";
import { ItemPlasticGutter } from "vhows-design/src/object/design/item/list/ItemPlasticGutter";
import { ItemPowerSwitcher } from "vhows-design/src/object/design/item/list/ItemPowerSwitcher";
import { ItemPvcConnector } from "vhows-design/src/object/design/item/list/ItemPvcConnector";
import { ItemQuilting } from "vhows-design/src/object/design/item/list/ItemQuilting";
import { ItemRailHook } from "vhows-design/src/object/design/item/list/ItemRailHook";
import { ItemReducedMotor } from "vhows-design/src/object/design/item/list/ItemReducedMotor";
import { ItemRoller } from "vhows-design/src/object/design/item/list/ItemRoller";
import { ItemSconce } from "vhows-design/src/object/design/item/list/ItemSconce";
import { ItemScreen } from "vhows-design/src/object/design/item/list/ItemScreen";
import { ItemScreennet } from "vhows-design/src/object/design/item/list/ItemScreennet";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemShadenet } from "vhows-design/src/object/design/item/list/ItemShadenet";
import { ItemDoorMade } from "vhows-design/src/object/design/item/list/ItemDoorMade";
import { ItemSteelBar } from "vhows-design/src/object/design/item/list/ItemSteelBar";
import { ItemSteelWire } from "vhows-design/src/object/design/item/list/ItemSteelWire";
import { ItemSupportPipe } from "vhows-design/src/object/design/item/list/ItemSupportPipe";
import { ItemSwitcherAssist } from "vhows-design/src/object/design/item/list/ItemSwitcherAssist";
import { ItemSwitcherOther } from "vhows-design/src/object/design/item/list/ItemSwitcherOther";
import { ItemTent } from "vhows-design/src/object/design/item/list/ItemTent";
import { ItemTowClip } from "vhows-design/src/object/design/item/list/ItemTowClip";
import { ItemUbar } from "vhows-design/src/object/design/item/list/ItemUbar";
import { ItemVentFan } from "vhows-design/src/object/design/item/list/ItemVentFan";
import { ItemVentHole } from "vhows-design/src/object/design/item/list/ItemVentHole";
import { ItemVentHoleSwitchSet } from "vhows-design/src/object/design/item/list/ItemVentHoleSwitchSet";
import { ItemVentilator } from "vhows-design/src/object/design/item/list/ItemVentilator";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { ItemWaterFilter } from "vhows-design/src/object/design/item/list/ItemWaterFilter";
import { ItemWaterPump } from "vhows-design/src/object/design/item/list/ItemWaterPump";
import { ItemWaterPumpConnect } from "vhows-design/src/object/design/item/list/ItemWaterPumpConnect";
import { ItemWaterTank } from "vhows-design/src/object/design/item/list/ItemWaterTank";
import { ItemWaterTankFitting } from "vhows-design/src/object/design/item/list/ItemWaterTankFitting";
import { ItemWateringConnect } from "vhows-design/src/object/design/item/list/ItemWateringConnect";
import { ItemWateringHanger } from "vhows-design/src/object/design/item/list/ItemWateringHanger";
import { ItemWateringOther } from "vhows-design/src/object/design/item/list/ItemWateringOther";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringTool } from "vhows-design/src/object/design/item/list/ItemWateringTool";
import { ItemWateringTube } from "vhows-design/src/object/design/item/list/ItemWateringTube";
import { ItemWateringTubeNipple } from "vhows-design/src/object/design/item/list/ItemWateringTubeNipple";
import { ItemWateringValve } from "vhows-design/src/object/design/item/list/ItemWateringValve";
import { ItemWeavingFilm } from "vhows-design/src/object/design/item/list/ItemWeavingFilm";
import { ItemWindingDrum } from "vhows-design/src/object/design/item/list/ItemWindingDrum";
import { ItemWireStrain } from "vhows-design/src/object/design/item/list/ItemWireStrain";
import { ItemWood } from "vhows-design/src/object/design/item/list/ItemWood";
import { Item } from "vhows-design/src/object/design/item/Item";
import { ProductCompanyModel } from "vhows-design/src/object/item/product/ProductCompanyModel";
import { ItemBoiler } from "vhows-design/src/object/design/item/list/ItemBoiler";
import { ItemHeatingFan } from "vhows-design/src/object/design/item/list/ItemHeatingFan";
import { ItemHeatingTube } from "vhows-design/src/object/design/item/list/ItemHeatingTube";
import { ItemWaterSolution } from "vhows-design/src/object/design/item/list/ItemWaterSolution";
import { ItemInitDataIF } from "vhows-design/src/object/design/item/ItemInterface";
import { ItemInitData } from "vhows-design/src/object/design/item/ItemInitData";
import { ItemSkylightSet } from "vhows-design/src/object/design/item/list/ItemSkylightSet";
import { ItemSkylightMotor } from "vhows-design/src/object/design/item/list/ItemSkylightMotor";
import { ItemNutrientSolution } from "vhows-design/src/object/design/item/list/ItemNutrientSolution";
import { ItemWaterTankController } from "vhows-design/src/object/design/item/list/ItemWaterTankController";
import { ItemFormingBed } from "vhows-design/src/object/design/item/list/ItemFormingBed";
import { ItemStyrofoamBed } from "vhows-design/src/object/design/item/list/ItemStyrofoamBed";
import { ItemNftBed } from "vhows-design/src/object/design/item/list/ItemNftBed";
import { ItemBedAppendage } from "vhows-design/src/object/design/item/list/ItemBedAppendage";
import { ItemGroundCover } from "vhows-design/src/object/design/item/list/ItemGroundCover";
import { ItemGroundCoverAppendage } from "vhows-design/src/object/design/item/list/ItemGroundCoverAppendage";
import { ItemTrellisStringAppendage } from "vhows-design/src/object/design/item/list/ItemTrellisStringAppendage";
import { ItemWaterPumpHose } from "vhows-design/src/object/design/item/list/ItemWaterPumpHose";
import { ItemWaterPumpController } from "vhows-design/src/object/design/item/list/ItemWaterPumpController";
import { ItemBracket } from "vhows-design/src/object/design/item/list/ItemBracket";
import { ItemPvcValve } from "vhows-design/src/object/design/item/list/ItemPvcValve";
import { ItemTrussClip } from "vhows-design/src/object/design/item/list/ItemTrussClip";
import { ItemSashDoor } from "vhows-design/src/object/design/item/list/ItemSashDoor";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-04-27
 */
export class ItemUtil {
  /**
   * 새로운 아이템 만들기
   * @param part 연관시킬 파트 객체
   * @param product '상품아이디'로 새로 만들거나, '기존Item'을 복제하여 만들기
   * @param isCustomAdded 임의 추가 여부
   * @return 자식 Item
   */
  public static createItem(part: Part, product: string | Item, isCustomAdded: boolean = undefined): any {
    let item: Item = null;
    const purpose: string = CONST.LB_ADDED_PRODUCT;
    let productId: string = null;
    let productObject: Item = null;

    // productId 또는 productObject 로 새로운 아이템 만들지 결정
    if (typeof product === "string") {
      productId = product;
    } else {
      productObject = product;
      productId = productObject.productId;
    }

    switch (productId) {
      // 파이프
      case CONST.ITEM_ID_PIPE:
        item = new ItemPipe();
        break;
      case CONST.ITEM_ID_C_SECTION_STEEL:
        item = new ItemPipe();
        break;
      case CONST.ITEM_ID_GUTTER_BEAM:
        item = new ItemPipe();
        break;
      case CONST.ITEM_ID_ROUND_BAR:
        item = new ItemPipe();
        break;
      // 보조 파이프
      case CONST.ITEM_ID_MIDDLE_SUPPORT_PIPE:
        item = new ItemSupportPipe();
        break;
      case CONST.ITEM_ID_U_BENDING_TUBE_RAIL:
        item = new ItemSupportPipe();
        break;
      // 철근
      case CONST.ITEM_ID_STEEL_BAR:
        item = new ItemSteelBar();
        break;
      // 브라켓트
      case CONST.ITEM_ID_BRACKET:
        item = new ItemBracket();
        break;
      // 연결구
      case CONST.ITEM_ID_CONNECTION_PIN:
        item = new ItemConnector();
        break;
      // 고정구
      case CONST.ITEM_ID_NORMAL_HOLDER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_STEEL_STRING_GRABBER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_STEEL_SHEET_GRABBER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_CROSS_STEEL_SHEET_GRABBER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_LINE_SHEET_GRABBER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_U_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_SADDLE_HOLDER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_FIX_ASIBA_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_ROTATION_ASIBA_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_CROSS_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_OUTER_WALL_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_T_HOLDER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_T_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_DIAGONAL_T_HOLDER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_DIAGONAL_T_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_GUTTER_BEAM_HOLDER:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_MIDDLE_PAIR_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_MIDDLE_SOLE_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_MIDDLE_Y_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_MIDDLE_HALF_Y_CLAMP:
        item = new ItemHolder();
        break;
      case CONST.ITEM_ID_HOOK_HOLDER:
        item = new ItemHolder();
        break;
      // 꽂이
      case CONST.ITEM_ID_INNER_T_HOLDER:
        item = new ItemSconce();
        break;
      case CONST.ITEM_ID_FIRST_LEVEL_SOLE_SCONCE:
        item = new ItemSconce();
        break;
      case CONST.ITEM_ID_FIRST_LEVEL_PAIR_SCONCE:
        item = new ItemSconce();
        break;
      case CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE:
        item = new ItemSconce();
        break;
      case CONST.ITEM_ID_SECOND_LEVEL_PAIR_SCONCE:
        item = new ItemSconce();
        break;
      case CONST.ITEM_ID_CONNECTION_SKYLIGHT_SET:
        item = new ItemSconce();
        break;
      case CONST.ITEM_ID_ARCH_SCONCE:
        item = new ItemSconce();
        break;
      // 다이아몬드 클램프
      case CONST.ITEM_ID_DIAMOND_CLAMP:
        item = new ItemDiamondClamp();
        break;
      // 문짝
      case CONST.ITEM_ID_DOOR:
        item = new ItemDoor();
        break;
      // 문짝(기성품)
      case CONST.ITEM_ID_DOOR_MADE:
        item = new ItemDoorMade();
        break;
      // 여닫이 문
      case CONST.ITEM_ID_HINGED_DOOR:
        item = new ItemHingedDoor();
        break;
      // 샷시창문
      case CONST.ITEM_ID_SASH_DOOR:
        item = new ItemSashDoor();
        break;
      // 롤러
      case CONST.ITEM_ID_C_SECTION_STEEL_ROLLER:
        item = new ItemRoller();
        break;
      // 시멘트
      case CONST.ITEM_ID_REMICON:
        item = new ItemCement();
        break;
      // 나사
      case CONST.ITEM_ID_SCREW:
        item = new ItemScrew();
        break;
      // 골조 기타
      case CONST.ITEM_ID_BOLT_AND_NUT:
        item = new ItemFrameOther();
        break;
      case CONST.ITEM_ID_HINGE:
        item = new ItemFrameOther();
        break;
      case CONST.ITEM_ID_KNOB:
        item = new ItemFrameOther();
        break;
      case CONST.ITEM_ID_STAKE:
        item = new ItemFrameOther();
        break;
      case CONST.ITEM_ID_LONG_BOLT:
        item = new ItemFrameOther();
        break;
      case CONST.ITEM_ID_TACKER_PIN:
        item = new ItemFrameOther();
        break;
      case CONST.ITEM_ID_FILM_BAND:
        item = new ItemFrameOther();
        break;
      case CONST.ITEM_ID_WIRE_MESH:
        item = new ItemFrameOther();
        break;
      case CONST.ITEM_ID_TURNBUCKLE:
        item = new ItemFrameOther();
        break;
      // 철판 물받이
      case CONST.ITEM_ID_IRON_PLATE_GUTTER:
        item = new ItemIronPlateGutter();
        break;
      // 플라스틱 물받이
      case CONST.ITEM_ID_PLASTIC_GUTTER:
        item = new ItemPlasticGutter();
        break;
      // 유바
      case CONST.ITEM_ID_UBAR:
        item = new ItemUbar();
        break;
      // 물받이 걸이
      case CONST.ITEM_ID_GUTTER_HANGER:
        item = new ItemGutterHanger();
        break;
      // 수동 개폐기
      case CONST.ITEM_ID_MANUAL_SWITCHER:
        item = new ItemManualSwitcher();
        break;
      // 동력 개폐기
      case CONST.ITEM_ID_POWER_SWITCHER:
        item = new ItemPowerSwitcher();
        break;
      // 천창 모터
      case CONST.ITEM_ID_SKYLIGHT_MOTOR:
        item = new ItemSkylightMotor();
        break;
      // 천창 세트
      case CONST.ITEM_ID_RACKPINION_SET:
        item = new ItemSkylightSet();
        break;
      case CONST.ITEM_ID_VENTPIPEPAD_SET:
        item = new ItemSkylightSet();
        break;
      // 전선
      case CONST.ITEM_ID_ELECTRIC_WIRE:
        item = new ItemElectricWire();
        break;
      // 개폐기 보조
      case CONST.ITEM_ID_AXIS_ROLLER:
        item = new ItemSwitcherAssist();
        break;
      case CONST.ITEM_ID_AXIS_COUPLING:
        item = new ItemSwitcherAssist();
        break;
      case CONST.ITEM_ID_SUPPORT_COUPLING:
        item = new ItemSwitcherAssist();
        break;
      case CONST.ITEM_ID_SUPPORT_FIXING_PIN:
        item = new ItemSwitcherAssist();
        break;
      case CONST.ITEM_ID_LONG_BOLT_HOLDER:
        item = new ItemSwitcherAssist();
        break;
      // 통합 제어기
      case CONST.ITEM_ID_INTEGRATED_CONTROLLER:
        item = new ItemIntegratedController();
        break;
      // 제어기 센서
      case CONST.ITEM_ID_CONTROLLER_TIMER:
        item = new ItemControllerSensor();
        break;
      case CONST.ITEM_ID_CONTROLLER_TEMP_SENSOR:
        item = new ItemControllerSensor();
        break;
      case CONST.ITEM_ID_CONTROLLER_HEAT_SENSOR:
        item = new ItemControllerSensor();
        break;
      case CONST.ITEM_ID_CONTROLLER_TEMP_HUM_SENSOR:
        item = new ItemControllerSensor();
        break;
      case CONST.ITEM_ID_CONTROLLER_RAINDROP_SENSOR:
        item = new ItemControllerSensor();
        break;
      case CONST.ITEM_ID_CONTROLLER_WIND_SENSOR:
        item = new ItemControllerSensor();
        break;
      case CONST.ITEM_ID_CONTROLLER_INSOLATION_SENSOR:
        item = new ItemControllerSensor();
        break;
      // 개폐기 기타
      case CONST.ITEM_ID_SWITCHER_PROTECTION_RUBBER:
        item = new ItemSwitcherOther();
        break;
      // 비닐
      case CONST.ITEM_ID_VINYL:
        item = new ItemVinyl();
        break;
      // 직조 필름
      case CONST.ITEM_ID_WEAVING_FILM:
        item = new ItemWeavingFilm();
        break;
      // 차광망
      case CONST.ITEM_ID_SHADENET:
        item = new ItemShadenet();
        break;
      // 텐텐지
      case CONST.ITEM_ID_TENT:
        item = new ItemTent();
        break;
      // 부직포
      case CONST.ITEM_ID_NONWOVEN:
        item = new ItemNonwoven();
        break;
      // 카시미론
      case CONST.ITEM_ID_CASHMILON:
        item = new ItemCashmilon();
        break;
      // 보온덮개
      case CONST.ITEM_ID_COLOR_LAGGING:
        item = new ItemLagging();
        break;
      case CONST.ITEM_ID_LAGGING:
        item = new ItemLagging();
        break;
      // 다겹 커튼지
      case CONST.ITEM_ID_QUILTING:
        item = new ItemQuilting();
        break;
      // 스크린
      case CONST.ITEM_ID_SCREEN:
        item = new ItemScreen();
        break;
      // 마트
      case CONST.ITEM_ID_MAT:
        item = new ItemMat();
        break;
      // 방충망
      case CONST.ITEM_ID_SCREENNET:
        item = new ItemScreennet();
        break;
      // 패드
      case CONST.ITEM_ID_NORMAL_PAD:
        item = new ItemPad();
        break;
      case CONST.ITEM_ID_LAGGING_PAD:
        item = new ItemPad();
        break;
      case CONST.ITEM_ID_GUTTER_PAD:
        item = new ItemPad();
        break;
      case CONST.ITEM_ID_CONDENSATION_PAD:
        item = new ItemPad();
        break;
      case CONST.ITEM_ID_BAR_PAD:
        item = new ItemPad();
        break;
      // 패드 연결구
      case CONST.ITEM_ID_PAD_CONNECTION_PIN:
        item = new ItemPadConnector();
        break;
      case CONST.ITEM_ID_CONDENSATION_PAD_CONNECTION_PIN:
        item = new ItemPadConnector();
        break;
      // 패드 스프링
      case CONST.ITEM_ID_NORMAL_PAD_SPRING:
        item = new ItemPadSpring();
        break;
      case CONST.ITEM_ID_LAGGING_PAD_SPRING:
        item = new ItemPadSpring();
        break;
      // 패드 비닐
      case CONST.ITEM_ID_PAD_VINYL:
        item = new ItemPadVinyl();
        break;
      // 클립
      case CONST.ITEM_ID_SKIRT_CLIP:
        item = new ItemClip();
        break;
      case CONST.ITEM_ID_NORMAL_CLIP:
        item = new ItemClip();
        break;
      case CONST.ITEM_ID_HANGER_CLIP:
        item = new ItemClip();
        break;
      case CONST.ITEM_ID_SLOPE_CLIP:
        item = new ItemClip();
        break;
      // 목재
      case CONST.ITEM_ID_RECT_LUMBER:
        item = new ItemWood();
        break;
      // 밴드끈
      case CONST.ITEM_ID_NORMAL_BANDSTRING:
        item = new ItemBandstring();
        break;
      case CONST.ITEM_ID_POLY_BANDSTRING:
        item = new ItemBandstring();
        break;
      case CONST.ITEM_ID_VINYL_BAND:
        item = new ItemBandstring();
        break;
      case CONST.ITEM_ID_NET_BAND:
        item = new ItemBandstring();
        break;
      case CONST.ITEM_ID_WEAVING_BAND:
        item = new ItemBandstring();
        break;
      // 강선
      case CONST.ITEM_ID_STEEL_STRING_WIRE:
        item = new ItemSteelWire();
        break;
      // 환풍기
      case CONST.ITEM_ID_VENTILATOR:
        item = new ItemVentilator();
        break;
      // 환기팬
      case CONST.ITEM_ID_VENT_FAN:
        item = new ItemVentFan();
        break;
      // 환기구
      case CONST.ITEM_ID_VENT_HOLE:
        item = new ItemVentHole();
        break;
      // 환기구 개폐 세트
      case CONST.ITEM_ID_VENT_HOLE_SWITCH_SET:
        item = new ItemVentHoleSwitchSet();
        break;
      // 공기 유동팬
      case CONST.ITEM_ID_AIR_FLOW_FAN:
        item = new ItemAirFlowFan();
        break;
      // 감속 모터
      case CONST.ITEM_ID_REDUCED_MOTOR:
        item = new ItemReducedMotor();
        break;
      // 베어링
      case CONST.ITEM_ID_BEARING_SET:
        item = new ItemBearing();
        break;
      // 와인딩 드럼
      case CONST.ITEM_ID_WINDING_DRUM:
        item = new ItemWindingDrum();
        break;
      // 와이어 인장
      case CONST.ITEM_ID_WIRE_STRAINER:
        item = new ItemWireStrain();
        break;
      // 트러스 클립
      case CONST.ITEM_ID_TRUSS_CLIP:
        item = new ItemTrussClip();
        break;
      // 예인 클립
      case CONST.ITEM_ID_STICK_TOW_CLIP:
        item = new ItemTowClip();
        break;
      case CONST.ITEM_ID_HOOK_TOW_CLIP:
        item = new ItemTowClip();
        break;
      // 커튼 기타
      case CONST.ITEM_ID_TOW_ROLLER:
        item = new ItemCurtainOther();
        break;
      case CONST.ITEM_ID_GUIDE_ROLLER:
        item = new ItemCurtainOther();
        break;
      case CONST.ITEM_ID_ROLLER_HOOK:
        item = new ItemCurtainOther();
        break;
      case CONST.ITEM_ID_SCREEN_PINCER:
        item = new ItemCurtainOther();
        break;
      // case CONST.ITEM_ID_WIRE_STRAINER:
      //   item = new ItemCurtainOther();
      //   break;
      // 양액시스템
      case CONST.ITEM_ID_NUTRIENT_SOLUTION:
        item = new ItemNutrientSolution();
        break;
      // 관비기
      case CONST.ITEM_ID_WATER_SOLUTION:
        item = new ItemWaterSolution();
        break;
      // 물탱크
      case CONST.ITEM_ID_WATER_TANK:
        item = new ItemWaterTank();
        break;
      // 물탱크 피팅
      case CONST.ITEM_ID_WATER_TANK_FITTING:
        item = new ItemWaterTankFitting();
        break;
      // 물탱크 조절기
      case CONST.ITEM_ID_NUTRIENT_MIXER:
        item = new ItemWaterTankController();
        break;
      case CONST.ITEM_ID_WATER_LEVEL_CONTROLLER:
        item = new ItemWaterTankController();
        break;
      // 펌프
      case CONST.ITEM_ID_WATER_PUMP:
        item = new ItemWaterPump();
        break;
      // 펌프 연결
      case CONST.ITEM_ID_WATER_PUMP_CONNECT:
        item = new ItemWaterPumpConnect();
        break;
      // 펌프 호스
      case CONST.ITEM_ID_FLEXIBLE_HOSE:
        item = new ItemWaterPumpHose();
        break;
      // 펌프 조절기
      case CONST.ITEM_ID_WATER_PUMP_CONTROLLER:
        item = new ItemWaterPumpController();
        break;
      // 여과기
      case CONST.ITEM_ID_WATER_FILTER:
        item = new ItemWaterFilter();
        break;
      // PE관
      case CONST.ITEM_ID_PE_PIPE:
        item = new ItemWateringPipe();
        break;
      // 관수 밸브
      case CONST.ITEM_ID_ELECTRIC_VALVE:
        item = new ItemWateringValve();
        break;
      case CONST.ITEM_ID_UNION_VALVE:
        item = new ItemWateringValve();
        break;
      case CONST.ITEM_ID_FEMALE_BALL_VALVE:
        item = new ItemWateringValve();
        break;
      case CONST.ITEM_ID_MALE_BALL_VALVE:
        item = new ItemWateringValve();
        break;
      case CONST.ITEM_ID_FEMALE_MALE_BALL_VALVE:
        item = new ItemWateringValve();
        break;
      case CONST.ITEM_ID_FEMALE_MALE_VALVE:
        item = new ItemWateringValve();
        break;
      // 관수 연결
      case CONST.ITEM_ID_NIPPLE:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_SOCKET_FEMALE:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_DIFFERENT_SOCKET_FEMALE:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_CONNECT_SOCKET:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_DIFFERENT_CONNECT_SOCKET:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_ELBOW:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_MALE_ELBOW_SOCKET:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_FEMALE_ELBOW_SOCKET:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_EXACT_T:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_DIFFERENT_T:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_BUSHING:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_VALVE_SOCKET:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_PE_PIPE_END:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_WATER_SCREEN_CONNECT_SOCKET:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_WATER_SCREEN_ELBOW_SOCKET:
        item = new ItemWateringConnect();
        break;
      case CONST.ITEM_ID_PE_PIPE_SADDLE:
        item = new ItemWateringConnect();
        break;
      // 관수 걸이
      case CONST.ITEM_ID_PE_PIPE_HANGER:
        item = new ItemWateringHanger();
        break;
      // 관수 튜브
      case CONST.ITEM_ID_TUBE:
        item = new ItemWateringTube();
        break;
      // 관수 튜브 니플
      case CONST.ITEM_ID_TUBE_NIPPLE:
        item = new ItemWateringTubeNipple();
        break;
      // 미니 스프링클러
      case CONST.ITEM_ID_MINI_SPRINKLER:
        item = new ItemMiniSprinkler();
        break;
      // 미니 스프링클러 낙수방지기
      case CONST.ITEM_ID_MINI_SPRINKLER_DROP_GUARD:
        item = new ItemMiniSprinklerDropGuard();
        break;
      // 미니 스프링클러 튜브셋
      case CONST.ITEM_ID_MINI_SPRINKLER_TUBE_SET:
        item = new ItemMiniSprinklerTubeSet();
        break;
      // 점적관
      case CONST.ITEM_ID_DRIP_PIPE:
        item = new ItemDripPipe();
        break;
      // 점적단추
      case CONST.ITEM_ID_DRIP_BUTTON:
        item = new ItemDripButton();
        break;
      // 점적단추 부속
      case CONST.ITEM_ID_DRIP_BUTTON_MANY_HOLD:
        item = new ItemDripButtonAppendage();
        break;
      case CONST.ITEM_ID_DRIP_BUTTON_DROPPER:
        item = new ItemDripButtonAppendage();
        break;
      // 점적 미늘
      case CONST.ITEM_ID_DRIP_BARB:
        item = new ItemDripBarb();
        break;
      case CONST.ITEM_ID_DRIP_START_SADDLE:
        item = new ItemDripBarb();
        break;
      // 점적 밸브
      case CONST.ITEM_ID_DRIP_LINE_VALVE:
        item = new ItemDripValve();
        break;
      case CONST.ITEM_ID_DRIP_START_LINE_VALVE:
        item = new ItemDripValve();
        break;
      case CONST.ITEM_ID_DRIP_LINE_VALVE_SUPPORT:
        item = new ItemDripValve();
        break;
      // 점적 연결
      case CONST.ITEM_ID_DRIP_CONNECT_SOCKET:
        item = new ItemDripConnect();
        break;
      case CONST.ITEM_ID_DRIP_SUPPORT_END:
        item = new ItemDripConnect();
        break;
      case CONST.ITEM_ID_LD_CONNECT:
        item = new ItemDripConnect();
        break;
      case CONST.ITEM_ID_LD_ELBOW:
        item = new ItemDripConnect();
        break;
      case CONST.ITEM_ID_LD_EXACT_T:
        item = new ItemDripConnect();
        break;
      case CONST.ITEM_ID_LD_END:
        item = new ItemDripConnect();
        break;
      // 분수호스
      case CONST.ITEM_ID_FOUNTAIN_HOSE:
        item = new ItemFountainHose();
        break;
      // 분수호스 연결
      case CONST.ITEM_ID_FOUNTAIN_HOSE_CONNECT:
        item = new ItemFountainHoseConnect();
        break;
      case CONST.ITEM_ID_FOUNTAIN_HOSE_CROSS_CONNECT:
        item = new ItemFountainHoseConnect();
        break;
      case CONST.ITEM_ID_FOUNTAIN_HOSE_END:
        item = new ItemFountainHoseConnect();
        break;
      case CONST.ITEM_ID_FOUNTAIN_HOSE_T_VALVE:
        item = new ItemFountainHoseConnect();
        break;
      // 분수호스 걸이
      case CONST.ITEM_ID_FOUNTAIN_HOSE_HANGER_SCREEN:
        item = new ItemFountainHoseHanger();
        break;
      case CONST.ITEM_ID_FOUNTAIN_HOSE_HANGER_UPPER:
        item = new ItemFountainHoseHanger();
        break;
      case CONST.ITEM_ID_FOUNTAIN_HOSE_HANGER_SIDE:
        item = new ItemFountainHoseHanger();
        break;
      // 관수 공구
      case CONST.ITEM_ID_PE_PIPE_FASTENER:
        item = new ItemWateringTool();
        break;
      case CONST.ITEM_ID_PE_PIPE_PUNCHER:
        item = new ItemWateringTool();
        break;
      // 관수 기타
      case CONST.ITEM_ID_DRIP_ALIGNMENT_PIN:
        item = new ItemWateringOther();
        break;
      case CONST.ITEM_ID_WATERING_SPRING:
        item = new ItemWateringOther();
        break;
      // 배수관
      case CONST.ITEM_ID_THP_PIPE:
        item = new ItemDrainPipe();
        break;
      case CONST.ITEM_ID_PVC_PIPE:
        item = new ItemDrainPipe();
        break;
      // PVC 밸브
      case CONST.ITEM_ID_PVC_FEMALE_BALL_VALVE:
        item = new ItemPvcValve();
        break;
      // PVC 연결
      case CONST.ITEM_ID_PVC_SOCKET:
        item = new ItemPvcConnector();
        break;
      case CONST.ITEM_ID_PVC_ELBOW:
        item = new ItemPvcConnector();
        break;
      case CONST.ITEM_ID_PVC_DEGREE45_ELBOW:
        item = new ItemPvcConnector();
        break;
      case CONST.ITEM_ID_PVC_EXACT_T:
        item = new ItemPvcConnector();
        break;
      case CONST.ITEM_ID_PVC_DIFFERENT_T:
        item = new ItemPvcConnector();
        break;
      case CONST.ITEM_ID_PVC_VALVE_SOCKET:
        item = new ItemPvcConnector();
        break;
      case CONST.ITEM_ID_PVC_END:
        item = new ItemPvcConnector();
        break;
      // 물받이 통
      case CONST.ITEM_ID_DRAINSPOUT_BOX:
        item = new ItemDrainspoutBox();
        break;
      // 배관 잡이쇠
      case CONST.ITEM_ID_DRAIN_PIPE_HANGER:
        item = new ItemDrainPipeHanger();
        break;
      // 포밍 베드
      case CONST.ITEM_ID_FORMING_BED:
        item = new ItemFormingBed();
        break;
      // 스티로폼 베드
      case CONST.ITEM_ID_STYROFOAM_BED:
        item = new ItemStyrofoamBed();
        break;
      // NFT 베드
      case CONST.ITEM_ID_NFT_BED:
        item = new ItemNftBed();
        break;
      // 베드 부속
      case CONST.ITEM_ID_BED_END:
        item = new ItemBedAppendage();
        break;
      case CONST.ITEM_ID_BED_DRAIN:
        item = new ItemBedAppendage();
        break;
      case CONST.ITEM_ID_BED_SUPPORT_CLIP:
        item = new ItemBedAppendage();
        break;
      case CONST.ITEM_ID_BED_HOLDER:
        item = new ItemBedAppendage();
        break;
      case CONST.ITEM_ID_NFT_SUPPLY_DRAIN_SET:
        item = new ItemBedAppendage();
        break;
      case CONST.ITEM_ID_SINK_GUARD_PLATE:
        item = new ItemBedAppendage();
        break;
      // 바닥지
      case CONST.ITEM_ID_GROUND_COVER:
        item = new ItemGroundCover();
        break;
      // 바닥지 부속
      case CONST.ITEM_ID_SACK_PIN:
        item = new ItemGroundCoverAppendage();
        break;
      // 유인줄 부속
      case CONST.ITEM_ID_TRELLIS_HANGER:
        item = new ItemTrellisStringAppendage();
        break;
      case CONST.ITEM_ID_TRELLIS_ROUND_BAR:
        item = new ItemTrellisStringAppendage();
        break;
      // 운반구
      case CONST.ITEM_ID_UPPER_CARRIER:
        item = new ItemCarrier();
        break;
      case CONST.ITEM_ID_SIDE_CARRIER:
        item = new ItemCarrier();
        break;
      // 지면 운반구
      case CONST.ITEM_ID_GROUND_CARRIER:
        item = new ItemGroundCarrier();
        break;
      // 운반 고리
      case CONST.ITEM_ID_RAIL_HOOK:
        item = new ItemRailHook();
        break;
      case CONST.ITEM_ID_SECOND_LEVEL_RAIL_HOOK:
        item = new ItemRailHook();
        break;
      case CONST.ITEM_ID_SIDE_RAIL_HOOK:
        item = new ItemRailHook();
        break;
      // 레일 간격유지
      case CONST.ITEM_ID_RAIL_INTERVAL:
        item = new ItemRailInterval();
        break;
      case CONST.ITEM_ID_RAIL_SUPPORT_PIPE:
        item = new ItemRailInterval();
        break;
      // 보일러
      case CONST.ITEM_ID_BOILER:
        item = new ItemBoiler();
        break;
      // 열풍기
      case CONST.ITEM_ID_HEATING_FAN:
        item = new ItemHeatingFan();
        break;
      // 히팅튜브
      case CONST.ITEM_ID_HEATING_TUBE:
        item = new ItemHeatingTube();
        break;
      // 끈/와이어
      case CONST.ITEM_ID_CORD:
        item = new ItemCordWire();
        break;
      case CONST.ITEM_ID_RIPCORD:
        item = new ItemCordWire();
        break;
      case CONST.ITEM_ID_COATING_WIRE:
        item = new ItemCordWire();
        break;
      // (임의 자재)
      case CONST.ITEM_ID_CUSTOM_MATERIAL:
        item = new ItemCustomMaterial();
        break;
      case CONST.ITEM_ID_CUSTOM_CONTROLLER:
        item = new ItemCustomMaterial();
        break;
      // 공과잡비
      case CONST.ITEM_ID_BUILDING_MIS:
        item = new ItemBuildingMis();
        break;
      // 자재 할인비
      case CONST.ITEM_ID_OVERLAP_MATERIAL_SAVING:
        item = new ItemMaterialDiscountCost();
        break;
      case CONST.ITEM_ID_MATERIAL_DISCOUNT:
        item = new ItemMaterialDiscountCost();
        break;
      // 임의 경비
      case CONST.ITEM_ID_CUSTOM_EXPENSE:
        item = new ItemCustomExpense();
        break;
      // 기타 경비
      case CONST.ITEM_ID_WELDING:
        item = new ItemOtherExpense();
        break;
      // 파이프 벤딩비
      case CONST.ITEM_ID_PIPE_BENDING_EXPENSE:
        item = new ItemPipeBendingExpense();
        break;
      // 장비비
      case CONST.ITEM_ID_EQUIPMENT_EXPENSE:
        item = new ItemEquipmentExpense();
        break;
      // 임의 인건비
      case CONST.ITEM_ID_CUSTOM_LABOR_COST:
        item = new ItemCustomLaborCost();
        break;
      // 시공 할인비
      case CONST.ITEM_ID_BUILDING_DISCOUNT:
        item = new ItemBuildingDiscountCost();
        break;
      default:
        item = new ItemCustomMaterial();
        break;
    }

    // 상품아이디로 초기값 데이터 찾기
    let itemInitData: ItemInitDataIF = this.getInitItemData(productId);
    // 상품아이디로 초기값 데이터를 찾지 못하면 임의 자재로 찾기
    if (itemInitData === undefined) {
      itemInitData = this.getInitItemData(CONST.ITEM_ID_CUSTOM_MATERIAL);
    }
    item.setDefaultData(
      null,
      itemInitData.productId,
      itemInitData.label,
      purpose,
      itemInitData.specs,
      itemInitData.brands,
    );

    // 상품으로 새로만드는 경우, 모든 값 복사
    if (productObject) {
      item.deepCopy(<Item>product);
    }

    // 임의 추가 여부 지정
    if (isCustomAdded) {
      item.isCustomAdded = isCustomAdded;
    }

    // 객체 연관
    item.setAssociation(part.design, part.struct, part.work, part.level, part.position, part);
    item.setReferenceVariable();
    // 기본 변수 설정 (규격/상표 완성 및 모델 연관)
    item.isInit = true;
    item.setDefaultVariable();
    // 알고리즘
    item.algorithmItem();

    return item;
  }

  /**
   * 아이템 초기값 데이터 가져오기
   * @param productId 상품아이디
   * @return 아이템 초기값
   */
  public static getInitItemData(productId: string): ItemInitDataIF {
    const data = ItemInitData.find(e => e.productId === productId);
    return data;
  }

  /**
   * 문자 규격을 숫자 규격으로 변환
   * @param value 문자열 규격
   * @param noneValue 규격이 없다는 것을 표현하는 숫자 (사용하지 않는 경우 NaN)
   * @param prefix 규격의 앞첨자
   * @return 숫자 규격
   */
  public static convertSpecStr2Num(value: string, noneValue: number = NaN, prefix: string = ""): number {
    let numValue: number;
    if (value === CONST.ITEM_SPEC_VALUE_NONE) {
      numValue = noneValue;
    } else if (prefix != null && prefix !== "") {
      numValue = parseFloat(value.substr(prefix.length));
    } else {
      numValue = parseFloat(value);
    }
    return numValue;
  }

  /**
   * 숫자 규격을 문자 규격으로 변환
   * @param value 숫자 규격
   * @param noneValue 규격이 없다는 것을 표현하는 숫자
   * @param unit 규격의 단위
   * @param prefix 규격의 앞첨자
   * @return 숫자 규격
   */
  public static convertSpecNum2Str(
    value: number,
    noneValue: number = NaN,
    unit: string = "",
    prefix: string = "",
  ): string {
    let strValue: string;
    if (!isNaN(noneValue) && value === noneValue) {
      strValue = CONST.ITEM_SPEC_VALUE_NONE;
    } else {
      strValue = prefix + value + unit;
    }
    return strValue;
  }

  /**
   * 상품 필터 - 가용한 모든 상품
   * - 운영자용 기능에서만 활용할 것!
   */
  public static filterProductEnabledALL(
    value: ProductCompanyModel,
    index: number,
    array: ProductCompanyModel[],
  ): boolean {
    return value.productModel.enabled;
  }

  // /**
  //  * 상품 필터 - 가용한 자재 상품
  //  * - 모든 가용한 자재 (공과잡비도 2개이상 추가해도 문제없음)
  //  * - 할인비 등 견적 시스템이 만들어내는 상품은 임의로 추가해서는 안됨
  //  */
  // public static filterProductMaterial(value: ProductCompanyModel, index: number, array: ProductCompanyModel[]): boolean {
  //   if (value.categoryCompanyModel.categoryModel.category1 === CONST.ITEM_CAT1_NAME_MATERIAL) {
  //     // && value.categoryCompanyModel.categoryModel.category3 != CONST.ITEM_CAT3_NAME_CUSTOM_MATERIAL // 임의 자재 제외
  //     // && value.categoryCompanyModel.categoryModel.category3 != CONST.ITEM_CAT3_NAME_BUILDING_MIS) { // 공과잡비 제외
  //     return value.productModel.enabled;
  //   }
  //   return false;
  // }

  /**
   * 상품 필터 - 가용한 자재+경비 상품 추가용
   * - 모든 자재 + 비용> 기타 경비
   * - 자재 할인비, 인건비 등이 포함되지 않음
   */
  public static filterProductMaterialAndExpense(
    value: ProductCompanyModel,
    index: number,
    array: ProductCompanyModel[],
  ): boolean {
    if (
      (value.categoryCompanyModel.categoryModel.category1 === CONST.ITEM_CAT1_NAME_MATERIAL ||
        value.categoryCompanyModel.categoryModel.category2 === CONST.ITEM_CAT2_NAME_OTHER_EXPENSE) &&
      value.productModel.isSystemOnly === false
    ) {
      return value.productModel.enabled;
    }
    return false;
  }

  /**
   * 상품 필터 - 가용한 인건비+경비 상품 추가용
   * - 비용> 기타 인건비 + 비용> 기타 경비
   * - 자재 할인비, 경비, 시공 할인비 등이 포함되지 않음
   */
  public static filterProductLaborCostAndExpense(
    value: ProductCompanyModel,
    index: number,
    array: ProductCompanyModel[],
  ): boolean {
    if (
      (value.categoryCompanyModel.categoryModel.category2 === CONST.ITEM_CAT2_NAME_OTHER_LABOR_COST ||
        value.categoryCompanyModel.categoryModel.category2 === CONST.ITEM_CAT2_NAME_OTHER_EXPENSE) &&
      value.productModel.isSystemOnly === false
    ) {
      return value.productModel.enabled;
    }
    return false;
  }

  // /**
  //  * 상품 필터 - 가용한 임의 자재 상품 추가용
  //  * - 임의 자재를 포함하여, 가용한 모든 기타 자재
  //  * - 할인비 등 견적 시스템이 만들어내는 상품은 임의로 추가해서는 안됨
  //  */
  // public static filterProductCustomMaterial(value: ProductCompanyModel, index: number, array: ProductCompanyModel[]): boolean {
  //   if (value.categoryCompanyModel.categoryModel.category2 === CONST.ITEM_CAT2_NAME_OTHER_MATERIAL) { // 모든 기타 자재
  //     return value.productModel.enabled;
  //   }
  //   return false;
  // }

  // /**
  //  * 상품 필터 - 가용한 임의 경비 상품 추가용
  //  * - 가용한 모든 기타 경비
  //  * - 할인비 등 견적 시스템이 만들어내는 상품은 임의로 추가해서는 안됨
  //  */
  // public static filterProductCustomExpense(value: ProductCompanyModel, index: number, array: ProductCompanyModel[]): boolean {
  //   if (value.categoryCompanyModel.categoryModel.category2 === CONST.ITEM_CAT2_NAME_OTHER_EXPENSE) { // 모든 기타 경비
  //     return value.productModel.enabled;
  //   }
  //   return false;
  // }

  // /**
  //  * 상품 필터 - 가용한 임의 인건비 상품 추가용
  //  * - 가용한 모든 기타 인건비
  //  * - 할인비 등 견적 시스템이 만들어내는 상품은 임의로 추가해서는 안됨
  //  */
  // public static filterProductCustomLaborCost(value: ProductCompanyModel, index: number, array: ProductCompanyModel[]): boolean {
  //   if (value.categoryCompanyModel.categoryModel.category2 === CONST.ITEM_CAT2_NAME_OTHER_LABOR_COST // 모든 기타 인건비
  //     && value.categoryCompanyModel.categoryModel.category3 !== CONST.ITEM_CAT3_NAME_BUILDING_DISCOUNT_COST) { // 시공 할인비 제외
  //     return value.productModel.enabled;
  //   }
  //   return false;
  // }

  /**
   * 상품 정렬 - 상풍아이디 오름차순
   */
  public static sortProductProductID(a: ProductCompanyModel, b: ProductCompanyModel): number {
    if (a.productModel.productId < b.productModel.productId) return -1;
    if (a.productModel.productId > b.productModel.productId) return 1;
    return 0;
  }

  /**
   * 아이템 정렬 - 공사(분류1)
   */
  public static sortItemConstruction(a: any, b: any): number {
    switch (a) {
      case CONST.LB_FOUNDATION_CONSTRUCTION:
        return CONST.ORDER_FOUNDATION_CONSTRUCTION;
      case CONST.LB_FRAME_CONSTRUCTION:
        return CONST.ORDER_FRAME_CONSTRUCTION;
      case CONST.LB_SWITCH_CONSTRUCTION:
        return CONST.ORDER_SWITCH_CONSTRUCTION;
      case CONST.LB_COVER_CONSTRUCTION:
        return CONST.ORDER_COVER_CONSTRUCTION;
      case CONST.LB_CURTAIN_CONSTRUCTION:
        return CONST.ORDER_CURTAIN_CONSTRUCTION;
      case CONST.LB_SKYLIGHT_CONSTRUCTION:
        return CONST.ORDER_SKYLIGHT_CONSTRUCTION;
      case CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION:
        return CONST.ORDER_OTHER_ARCHITECTURE_CONSTRUCTION;
      case CONST.LB_VENT_CONSTRUCTION:
        return CONST.ORDER_VENT_CONSTRUCTION;
      case CONST.LB_WATERING_CONSTRUCTION:
        return CONST.ORDER_WATERING_CONSTRUCTION;
      case CONST.LB_HYDROPONICS_CONSTRUCTION:
        return CONST.ORDER_HYDROPONICS_CONSTRUCTION;
      case CONST.LB_TEMPERATURE_CONSTRUCTION:
        return CONST.ORDER_TEMPERATURE_CONSTRUCTION;
      case CONST.LB_SMARTFARM_CONSTRUCTION:
        return CONST.ORDER_SMARTFARM_CONSTRUCTION;
      case CONST.LB_OTHER_FACILITIES_CONSTRUCTION:
        return CONST.ORDER_OTHER_FACILITIES_CONSTRUCTION;
      case CONST.LB_BUILDING_CONSTRUCTION:
        return CONST.ORDER_BUILDING_CONSTRUCTION;
      // case CONST.LB_OTHER_CONSTRUCTION: return CONST.ORDER_OTHER_CONSTRUCTION;
    }
    return 0;
  }

  /**
   * 아이템 정렬 - 일반(분류1)
   */
  public static sortItemNormalConstruction(a: any, b: any): number {
    switch (a) {
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_FOUNDATION_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_ARCHITECTURE_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_FRAME_SWITCH_COVER_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_CURTAIN_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_CURTAIN_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_SKYLIGHT_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_SKYLIGHT_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_PARTITION_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_PARTITION_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_WINDOW_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_WINDOW_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_WORKROOM_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_WORKROOM_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_OTHER_MATERIAL_WORK}`:
        return CONST.ORDER_NORMAL_OTHER_JAJE;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_VENT_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_VENT_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_WATERING_CONSTRUCTION}`:
        return CONST.ORDER_NORMAL_WATERING_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_HYD_SYSTEM_WORK} 공사`:
        return CONST.ORDER_NORMAL_HYD_SYSTEM_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_HYD_CULTIVATION_WORK} 공사`:
        return CONST.ORDER_NORMAL_HYD_CULTIVATION_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_TEM_HEATING_WORK} 공사`:
        return CONST.ORDER_NORMAL_TEM_HEATING_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_TEM_COOLING_WORK} 공사`:
        return CONST.ORDER_NORMAL_TEM_COOLING_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_ENVIRONMENT_WORK} 공사`:
        return CONST.ORDER_NORMAL_ENVIRONMENT_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_RACK_WORK} 공사`:
        return CONST.ORDER_NORMAL_RACK_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_RAIL_WORK} 공사`:
        return CONST.ORDER_NORMAL_RAIL_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_CO2_WORK} 공사`:
        return CONST.ORDER_NORMAL_CO2_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_AUTOSPRAY_WORK} 공사`:
        return CONST.ORDER_NORMAL_AUTOSPRAY_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_OTHER_FACILITIES_WORK}`:
        return CONST.ORDER_NORMAL_OTHER_FACILITIES_CONSTRUCTION;
      case CONST.LB_BUILDING_CONSTRUCTION:
        return CONST.ORDER_NORMAL_BUILDING_CONSTRUCTION;
    }
    return 0;
  }

  /**
   * 아이템 정렬 - 상세(분류2)
   */
  public static sortItemDetailJaje(a: any, b: any): number {
    switch (a) {
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_FOUNDATION_CONSTRUCTION}`:
        return CONST.ORDER_DETAIL_ARCHITECTURE_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${CONST.ITEM_CAT2_NAME_FRAME_MATERIAL}`:
        return CONST.ORDER_DETAIL_FRAME_MATERIAL;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${CONST.ITEM_CAT2_NAME_FRAME_APPENDAGE_MATERIAL}`:
        return CONST.ORDER_DETAIL_FRAME_APPENDAGE_MATERIAL;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${CONST.ITEM_CAT2_NAME_GUTTER_MATERIAL}`:
        return CONST.ORDER_DETAIL_GUTTER_MATERIAL;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${CONST.ITEM_CAT2_NAME_SWITCH_MATERIAL}`:
        return CONST.ORDER_DETAIL_SWITCH_MATERIAL;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${CONST.ITEM_CAT2_NAME_COVER_MATERIAL}`:
        return CONST.ORDER_DETAIL_COVER_MATERIAL;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${CONST.ITEM_CAT2_NAME_COVER_APPENDAGE_MATERIAL}`:
        return CONST.ORDER_DETAIL_COVER_APPENDAGE_MATERIAL;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_FRAME_SWITCH_COVER_CONSTRUCTION} - ${CONST.CAT_FRAME_SWITCH_COVER_ADD_JAJE}`:
        return CONST.ORDER_DETAIL_FRAME_SWITCH_COVER_ADD_JAJE;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_CURTAIN_CONSTRUCTION}`:
        return CONST.ORDER_DETAIL_CURTAIN_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_SKYLIGHT_CONSTRUCTION}`:
        return CONST.ORDER_DETAIL_SKYLIGHT_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_PARTITION_CONSTRUCTION}`:
        return CONST.ORDER_DETAIL_PARTITION_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_WINDOW_CONSTRUCTION}`:
        return CONST.ORDER_DETAIL_WINDOW_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.CAT_WORKROOM_CONSTRUCTION}`:
        return CONST.ORDER_DETAIL_WORKROOM_CONSTRUCTION;
      case `${CONST.CAT_CONSTRUCTION} > ${CONST.LB_OTHER_MATERIAL_WORK}`:
        return CONST.ORDER_DETAIL_OTHER_COST;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_VENT_CONSTRUCTION}`:
        return CONST.ORDER_DETAIL_VENT_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_WATERING_CONSTRUCTION}`:
        return CONST.ORDER_DETAIL_WATERING_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_HYD_SYSTEM_WORK} 공사`:
        return CONST.ORDER_DETAIL_HYD_SYSTEM_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_HYD_CULTIVATION_WORK} 공사`:
        return CONST.ORDER_DETAIL_HYD_CULTIVATION_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_TEM_HEATING_WORK} 공사`:
        return CONST.ORDER_DETAIL_TEM_HEATING_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_TEM_COOLING_WORK} 공사`:
        return CONST.ORDER_DETAIL_TEM_COOLING_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_ENVIRONMENT_WORK} 공사`:
        return CONST.ORDER_DETAIL_ENVIRONMENT_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_RACK_WORK} 공사`:
        return CONST.ORDER_DETAIL_RACK_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_RAIL_WORK} 공사`:
        return CONST.ORDER_DETAIL_RAIL_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_CO2_WORK} 공사`:
        return CONST.ORDER_DETAIL_CO2_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_AUTOSPRAY_WORK} 공사`:
        return CONST.ORDER_DETAIL_AUTOSPRAY_CONSTRUCTION;
      case `${CONST.CAT_FACILITIES} > ${CONST.LB_OTHER_FACILITIES_WORK}`:
        return CONST.ORDER_DETAIL_OTHER_FACILITIES_CONSTRUCTION;
      case CONST.LB_BUILDING_CONSTRUCTION:
        return CONST.ORDER_DETAIL_BUILDING_CONSTRUCTION;
    }
    return 0;
  }

  /**
   * 아이템 정렬 - 작업(분류2)
   */
  public static sortItemWork(a: any, b: any): number {
    switch (a) {
      case CONST.LB_FOUNDATION_WORK:
        return CONST.ORDER_FOUNDATION_WORK;
      case CONST.LB_TRUNK_WORK:
        return CONST.ORDER_TRUNK_WORK;
      case CONST.LB_COLUMN_WORK:
        return CONST.ORDER_COLUMN_WORK;
      case CONST.LB_ROOF_WORK:
        return CONST.ORDER_ROOF_WORK;
      case CONST.LB_WING_WORK:
        return CONST.ORDER_WING_WORK;
      case CONST.LB_ENDPIECE_WORK:
        return CONST.ORDER_ENDPIECE_WORK;
      case CONST.LB_GUTTER_WORK:
        return CONST.ORDER_GUTTER_WORK;
      case CONST.LB_SWITCHER_WORK:
        return CONST.ORDER_SWITCHER_WORK;
      case CONST.LB_CONTROLLER_WORK:
        return CONST.ORDER_CONTROLLER_WORK;
      case CONST.LB_COVER_WORK:
        return CONST.ORDER_COVER_WORK;
      case CONST.LB_FIXING_WORK:
        return CONST.ORDER_FIXING_WORK;
      case CONST.LB_SKIRT_WORK:
        return CONST.ORDER_SKIRT_WORK;
      case CONST.LB_WINDBREAK_WORK:
        return CONST.ORDER_WINDBREAK_WORK;
      case CONST.LB_SCREENNET_WORK:
        return CONST.ORDER_SCREENNET_WORK;
      case CONST.LB_BANDSTRING_WORK:
        return CONST.ORDER_BANDSTRING_WORK;
      case CONST.LB_CURTAIN_COVER_WORK:
        return CONST.ORDER_CURTAIN_COVER_WORK;
      case CONST.LB_CURTAIN_SWITCH_WORK:
        return CONST.ORDER_CURTAIN_SWITCH_WORK;
      case CONST.LB_SKYLIGHT_FRAME_WORK:
        return CONST.ORDER_SKYLIGHT_FRAME_WORK;
      case CONST.LB_SKYLIGHT_SWITCH_WORK:
        return CONST.ORDER_SKYLIGHT_SWITCH_WORK;
      case CONST.LB_SKYLIGHT_COVER_WORK:
        return CONST.ORDER_SKYLIGHT_COVER_WORK;
      case CONST.LB_PARTITION_WORK:
        return CONST.ORDER_PARTITION_WORK;
      case CONST.LB_WINDOW_WORK:
        return CONST.ORDER_WINDOW_WORK;
      case CONST.LB_WORKROOM_WORK:
        return CONST.ORDER_WORKROMM_WORK;
      case CONST.LB_OTHER_MATERIAL_WORK:
        return CONST.ORDER_OTHER_MATERIAL_WORK;
      case CONST.LB_VENT_EXHAUST_WORK:
        return CONST.ORDER_VENT_EXHAUST_WORK;
      case CONST.LB_VENT_INSIDE_WORK:
        return CONST.ORDER_VENT_INSIDE_WORK;
      case CONST.LB_WATERING_MAINPIPE_WORK:
        return CONST.ORDER_WATERING_MAINPIPE_WORK;
      case CONST.LB_WATERING_WATERING_WORK:
        return CONST.ORDER_WATERING_WATERING_WORK;
      case CONST.LB_HYD_SYSTEM_WORK:
        return CONST.ORDER_HYD_SYSTEM_WORK;
      case CONST.LB_HYD_CULTIVATION_WORK:
        return CONST.ORDER_HYD_CULTIVATION_WORK;
      case CONST.LB_TEM_HEATING_WORK:
        return CONST.ORDER_TEM_HEATING_WORK;
      case CONST.LB_TEM_COOLING_WORK:
        return CONST.ORDER_TEM_COOLING_WORK;
      case CONST.LB_ENVIRONMENT_WORK:
        return CONST.ORDER_ENVIRONMENT_WORK;
      case CONST.LB_RACK_WORK:
        return CONST.ORDER_RACK_WORK;
      case CONST.LB_RAIL_WORK:
        return CONST.ORDER_RAIL_WORK;
      case CONST.LB_CO2_WORK:
        return CONST.ORDER_CO2_WORK;
      case CONST.LB_AUTOSPRAY_WORK:
        return CONST.ORDER_AUTOSPRAY_WORK;
      case CONST.LB_OTHER_FACILITIES_WORK:
        return CONST.ORDER_OTHER_FACILITIES_WORK;
      case CONST.LB_BUILDING_WORK:
        return CONST.ORDER_BUILDING_WORK;
    }
    return 0;
  }
}
