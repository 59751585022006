import { CONST } from "vhows-design/src/common/constant/CONST";
import { PartDataIF } from "vhows-design/src/object/design/DesignInterface";
import { PipeSpecData_Rafter, PipeSpecData_Cylinder } from "vhows-design/src/object/design/item/data/ItemFrameData";
import { DesignConst } from "vhows-design/src/common/constant/DesignConst";

/**
 * 기초 골조 작업 > 파트
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2019-07-29
 */
export const TrunkPartData: PartDataIF[] = [
  {
    //
    // 서까래 파트
    //
    id: CONST.ID_SG_TRUNK_RAFTER,
    label: CONST.LB_TRUNK_RAFTER,
    description: "서까래(밴딩) 간격, 규격, 트러스, 확장 등을 설계",
    collapse: true,
    // 파트 설계요소
    entities: [
      {
        name: "rafterTotalLength",
        type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
        label: "서까래 최종 길이",
        description: "서까래 파이프 및 인발 파이프를 모두 합친 최종 길이",
        readOnly: true,
        data: {
          unit: CONST.UNIT_METER,
          prefix: "",
          desc: "",
        },
        entities: [
          {
            name: "lengthRafterIdeal",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "이상적인 서까래 길이",
            description: "폭, 어깨높이와 매설 깊이 값을 기반으로 가장 알맞은 서까래 길이",
            readOnly: true,
            data: {
              unit: CONST.UNIT_METER,
              prefix: "",
              desc: "",
            },
          },
        ],
      },
      {
        name: "rafterQuantity",
        type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
        label: "서까래 수량",
        description: "비닐하우스 전체에 설치되는 서까래 파이프 수량",
        readOnly: true,
        data: {
          unit: CONST.UNIT_EA,
          prefix: "",
          desc: "",
        },
      },
      {
        name: "rafterPipeSample",
        type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
        label: "서까래 파이프 규격",
        description: "",
        specsList: PipeSpecData_Rafter,
        entities: [
          {
            name: "rafterLengthRoundSelected",
            type: CONST.ID_VIEW_TYPE_RADIO_GROUP,
            label: "파이프 길이 반올림",
            description: "서까래 파이프의 단위길이를 반올림할지 선택",
            data: [
              {
                label: CONST.TEXT_YES,
                value: true,
                desc: "",
              },
              {
                label: CONST.TEXT_NO,
                value: false,
                desc: "",
              },
            ],
          },
          {
            name: "rafterInterval",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "서까래 간격",
            description: "2중이상은 자동으로 1중 간격의 두배로 설계",
            data: {
              min: 0.1,
              max: 20,
              step: 0.05,
              unit: CONST.UNIT_METER,
              prefix: "",
              desc: "",
            },
          },
        ],
      },
      {
        name: "extensionSelected",
        type: CONST.ID_VIEW_TYPE_GROUP_SWITCH,
        label: "서까래 확장",
        description: "서까래 파이프의 편측 또는 양측을 다른 파이프로 확장 설계",
        entities: [
          {
            name: "extensionType",
            type: CONST.ID_VIEW_TYPE_SELECT_BOX,
            label: "확장 형태",
            description: "",
            data: [
              {
                label: CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_PINNED,
                value: CONST.VL_TRUNK_RAFTER_EXTENSION_TYPE_PINNED,
                desc: CONST.DC_TRUNK_RAFTER_EXTENSION_TYPE_PINNED,
              },
              {
                label: CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL,
                value: CONST.VL_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL,
                desc: CONST.DC_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL,
              },
              {
                label: CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM,
                value: CONST.VL_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM,
                desc: CONST.DC_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM,
              },
            ],
          },
          {
            name: "extensionPosition",
            type: CONST.ID_VIEW_TYPE_SELECT_BOX,
            label: "확장 위치",
            description: "",
            data: [
              {
                label: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
                value: CONST.VL_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
                desc: CONST.DC_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
              },
              {
                label: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
                value: CONST.VL_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
                desc: CONST.DC_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
              },
            ],
          },
          {
            name: "extensionLength",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "확장 길이",
            description: "편측 확장길이로 핀가공 파이프의 경우 겹치는 부분 존재",
            data: {
              min: 0.1,
              max: 15,
              step: 0.1,
              unit: CONST.UNIT_METER,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "rafterExtensionPipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "확장 파이프 규격",
            description: "",
            specsList: PipeSpecData_Rafter,
            validFromPart: "extensionType",
            validValuePart: [
              CONST.VL_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL,
              CONST.VL_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM,
            ],
          },
          {
            name: "extensionInterval",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "확장 설치 간격",
            description: "확장 파이프 간격",
            data: {
              min: 0,
              max: 20,
              step: 0.05,
              unit: CONST.UNIT_METER,
              prefix: "",
              desc: "",
            },
            validFromPart: "extensionType",
            validValuePart: [CONST.VL_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM],
          },
        ],
      },
      {
        name: "trussSelected",
        type: CONST.ID_VIEW_TYPE_GROUP_SWITCH,
        label: "서까래 트러스",
        description: "서까래 파이프를 트러스 구조로 시공",
        class: "entity-divider",
        entities: [
          {
            name: "trussMultiple",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "트러스 배율",
            description: "트러스로 인해 추가되는 파이프 비율",
            data: {
              min: 1,
              max: 5,
              step: 0.1,
              unit: CONST.UNIT_MULTIPLE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "trussInterval",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "트러스 설치 간격",
            description: "서까래 트러스의 설치 간격",
            data: {
              min: 0.1,
              max: 20,
              step: 0.05,
              unit: CONST.UNIT_METER,
              prefix: "",
              desc: "",
            },
          },
        ],
      },
      {
        name: "bracingQuantity",
        type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
        label: "서까래 브레싱 수량",
        description: "동당 서까래 브레싱 수량으로, 보통 앞뒤로 2개씩 총 4개 설치",
        data: {
          min: 0,
          max: 100,
          step: 2,
          unit: CONST.UNIT_EA,
          prefix: "",
          desc: "",
        },
        entities: [
          {
            name: "bracingPipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "서까래 브레싱 파이프 규격",
            description: "규격 변경 시, 관련 부속의 규격이 자동으로 수정",
            specsList: PipeSpecData_Cylinder,
          },
        ],
      },
    ],
  },
  {
    //
    // 가로대 파트
    //
    id: CONST.ID_SG_TRUNK_STRAIGHT,
    label: CONST.LB_TRUNK_STRAIGHT,
    description: "가로대(직관) 줄수, 규격, 내재해 조리개 등을 설계",
    collapse: true,
    // 파트 설계요소
    entities: [
      {
        name: "straightLength",
        type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
        label: "가로대 길이",
        description: "설치 여분을 포함한 한 줄의 총 길이",
        readOnly: true,
        data: {
          unit: CONST.UNIT_METER,
          prefix: "",
          desc: "",
        },
      },
      {
        name: "straightPipeSample",
        type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
        label: "가로대 파이프 규격",
        description: "변경 시, 관련 부속의 규격이 자동으로 수정",
        specsList: PipeSpecData_Cylinder,
      },
      {
        name: "straightPositionEditSelected",
        type: CONST.ID_VIEW_TYPE_GROUP_SWITCH,
        label: "가로대 위치별 규격",
        description: "",
        entities: [
          {
            name: "straightRoofPipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "지붕 규격",
            description: "수평 방향 설치, 파이프를 좌우 방향으로 강하게 잡아주어 서까래 간격이 더 잘 보존됨",
            specsList: PipeSpecData_Cylinder,
          },
          {
            name: "straightSidePipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "측면 규격",
            description: "수직 방향 설치, 파이프를 상하 방향으로 더 강하게 잡아주어 가로대 파이프를 밟을 때 유리함",
            specsList: PipeSpecData_Cylinder,
          },
          {
            name: "straightFloorPipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "바닥 규격",
            description: "수직 방향 설치, 파이프를 상하 방향으로 더 강하게 잡아주어 가로대 파이프를 밟을 때 유리함",
            specsList: PipeSpecData_Cylinder,
          },
          {
            name: "straightRoofSubPipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "(지붕 보조 규격)",
            description: "수평 방향 설치, 파이프를 좌우 방향으로 강하게 잡아주어 서까래 간격이 더 잘 보존됨",
            specsList: PipeSpecData_Cylinder,
          },
          {
            name: "straightSideSubPipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "(측면 보조 규격)",
            description: "수직 방향 설치, 파이프를 상하 방향으로 더 강하게 잡아주어 가로대 파이프를 밟을 때 유리함",
            specsList: PipeSpecData_Cylinder,
          },
        ],
      },
      {
        name: "straightLineNumber",
        type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
        label: "가로대 줄수",
        description: "한 동에 설치되는 위치별 가로대 줄수",
        readOnly: true,
        data: {
          unit: CONST.UNIT_LINE,
          prefix: "",
          desc: "",
        },
        entities: [
          {
            name: "straightAlgorithmSelected",
            type: CONST.ID_VIEW_TYPE_GROUP_SWITCH,
            label: "줄수 자동 계산",
            description: "",
          },
          {
            name: "straightLineNumberRoof",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "지붕 줄수",
            readOnly: "straightAlgorithmSelected",
            description: "",
            data: {
              min: 0,
              max: 21,
              step: 1,
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "straightLineNumberSide",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "측면 줄수",
            readOnly: "straightAlgorithmSelected",
            description: "",
            data: {
              min: 0,
              max: 10,
              step: 1,
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "straightLineNumberFloor",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "바닥 줄수",
            readOnly: "straightAlgorithmSelected",
            description: "",
            data: {
              min: 0,
              max: 4,
              step: 1,
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "straightLineNumberRoofSub",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "(지붕 보조 줄수)",
            readOnly: "straightAlgorithmSelected",
            description: "지붕의 정중앙을 기점으로 어깨 높이 이전까지 위치",
            data: {
              min: 0,
              max: 21,
              step: 1,
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "straightLineNumberSideSub",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "(측면 보조 줄수)",
            readOnly: "straightAlgorithmSelected",
            description: "어깨 높이에 2줄을 기점으로 바닥 이전까지 위치",
            data: {
              min: 0,
              max: 10,
              step: 1,
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
        ],
      },
      {
        name: "enhancedGrabberSelected",
        type: CONST.ID_VIEW_TYPE_GROUP_SWITCH,
        label: "내재해/특수 조리개",
        description: "",
        entities: [
          {
            name: "enhancedGrabberType",
            type: CONST.ID_VIEW_TYPE_SELECT_BOX,
            label: "조리개 형태",
            description: "",
            data: [
              {
                label: CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET,
                value: CONST.VL_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET,
                desc: CONST.DC_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET,
              },
              {
                label: CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET,
                value: CONST.VL_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET,
                desc: CONST.DC_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET,
              },
              {
                label: CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET,
                value: CONST.VL_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET,
                desc: CONST.DC_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET,
              },
              {
                label: CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_U_CLAMP,
                value: CONST.VL_TRUNK_ENHANCED_GRABBER_TYPE_U_CLAMP,
                desc: CONST.DC_TRUNK_ENHANCED_GRABBER_TYPE_U_CLAMP,
              },
              {
                label: CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_U_ASIBA_CLAMP,
                value: CONST.VL_TRUNK_ENHANCED_GRABBER_TYPE_U_ASIBA_CLAMP,
                desc: CONST.DC_TRUNK_ENHANCED_GRABBER_TYPE_U_ASIBA_CLAMP,
              },
              {
                label: CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_SADDLE_ASIBA_CLAMP,
                value: CONST.VL_TRUNK_ENHANCED_GRABBER_TYPE_SADDLE_ASIBA_CLAMP,
                desc: CONST.DC_TRUNK_ENHANCED_GRABBER_TYPE_SADDLE_ASIBA_CLAMP,
              },
            ],
          },
          {
            name: "enhancedGrabberLineNumber",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "전체 줄수",
            description: "",
            readOnly: true,
            data: {
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "enhancedGrabberLineNumberRoof",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "지붕 줄수",
            description: "",
            data: {
              min: 0,
              max: 10,
              step: 1,
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "enhancedGrabberLineNumberSide",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "측면 줄수",
            description: "",
            data: {
              min: 0,
              max: 6,
              step: 1,
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "enhancedGrabberLineNumberFloor",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "바닥 줄수",
            description: "",
            data: {
              min: 0,
              max: 2,
              step: 1,
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
        ],
      },
    ],
  },
  {
    //
    // 기둥 파트
    //
    id: CONST.ID_SG_TRUNK_COLUMN,
    label: CONST.LB_TRUNK_COLUMN,
    description: "기둥, 기둥 브레싱, 기둥 보조, 중방 등을 설계",
    collapse: true,
    // 파트 설계요소
    entities: [
      {
        name: "columnLength",
        type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
        label: "기둥 길이",
        description: "",
        readOnly: true,
        data: {
          unit: CONST.UNIT_METER,
          prefix: "",
          desc: "",
        },
      },
      {
        name: "columnQuantity",
        type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
        label: "기둥 수량",
        description: "",
        readOnly: true,
        data: {
          unit: CONST.UNIT_EA,
          prefix: "",
          desc: "",
        },
      },
      {
        name: "columnLineNumber",
        type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
        label: "기둥 줄수",
        description: "",
        data: {
          min: 1,
          max: 5,
          step: 2,
          unit: CONST.UNIT_LINE,
          prefix: "",
          desc: "",
        },
      },
      {
        name: "columnPipeSample",
        type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
        label: "기둥 파이프 규격",
        description: "",
        specsList: PipeSpecData_Cylinder,
        entities: [
          {
            name: "columnLengthRoundSelected",
            type: CONST.ID_VIEW_TYPE_RADIO_GROUP,
            label: "파이프 길이 반올림",
            description: "기둥 파이프의 단위길이를 반올림할지 선택",
            data: [
              {
                label: CONST.TEXT_YES,
                value: true,
                desc: "",
              },
              {
                label: CONST.TEXT_NO,
                value: false,
                desc: "",
              },
            ],
          },
          {
            name: "columnInterval",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "기둥 간격",
            description: "",
            data: {
              min: 0.1,
              max: 10,
              step: 0.1,
              unit: CONST.UNIT_METER,
              prefix: "",
              desc: "",
            },
          },
        ],
      },
      {
        name: "columnBracingSelected",
        type: CONST.ID_VIEW_TYPE_GROUP_SWITCH,
        label: "기둥 브레싱",
        description: "",
        entities: [
          {
            name: "columnBracingQuantity",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "기둥 브레싱 수량",
            description: "동당 기둥 브레싱 수량으로, 보통 기둥 줄수마다 앞뒤에만 만큼 설치",
            data: {
              min: 0,
              max: 100,
              step: 1,
              unit: CONST.UNIT_EA,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "columnBracingPipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "기둥 브레싱 파이프 규격",
            description: "",
            specsList: PipeSpecData_Cylinder,
          },
        ],
      },
      {
        name: "columnSupPipeSelected",
        type: CONST.ID_VIEW_TYPE_GROUP_SWITCH,
        label: "보조(까치발) 파이프",
        description: "",
        entities: [
          {
            name: "columnSupPipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "보조 파이프 규격",
            description: "",
            specsList: PipeSpecData_Cylinder,
          },
        ],
      },
      {
        name: "middleSelected",
        type: CONST.ID_VIEW_TYPE_GROUP_SWITCH,
        label: "중방 파이프",
        description: "",
        entities: [
          {
            name: "middleLength",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "중방 길이",
            description: "",
            readOnly: true,
            data: {
              unit: CONST.UNIT_METER,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "middleLineNumber",
            type: CONST.ID_VIEW_TYPE_NUMBER_BOX,
            label: "중방 줄수",
            description: "",
            readOnly: true,
            data: {
              unit: CONST.UNIT_LINE,
              prefix: "",
              desc: "",
            },
          },
          {
            name: "middlePipeSample",
            type: CONST.ID_VIEW_TYPE_SPEC_LENGTH_BENDING,
            label: "중방 파이프 규격",
            description: "",
            specsList: PipeSpecData_Cylinder,
          },
        ],
      },
    ],
  },
];

export const RafterExtensionData: any = [
  {
    structureName: CONST.LB_STRUCT_DOUBLE_WIDTH,
    specCrossSize: null,
    data: [
      {
        rafterTotalLength: 20,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 10,
      },
      {
        rafterTotalLength: 21,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 11,
      },
      {
        rafterTotalLength: 22,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 11,
        extensionLength: 11,
      },
      {
        rafterTotalLength: 23,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 12,
        extensionLength: 11,
      },
      {
        rafterTotalLength: 24,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 12,
        extensionLength: 12,
      },
      {
        rafterTotalLength: 25,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 13,
        extensionLength: 12,
      },
      {
        rafterTotalLength: 26,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 9,
        extensionLength: 9,
      },
      {
        rafterTotalLength: 27,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 9,
        extensionLength: 9,
      },
      {
        rafterTotalLength: 28,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 10,
        extensionLength: 9,
      },
      {
        rafterTotalLength: 29,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 9,
        extensionLength: 10,
      },
      {
        rafterTotalLength: 30,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 10,
        extensionLength: 10,
      },
      {
        rafterTotalLength: 31,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 11,
        extensionLength: 10,
      },
      {
        rafterTotalLength: 32,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 12,
        extensionLength: 10,
      },
      {
        rafterTotalLength: 33,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 12,
        extensionLength: 11,
      },
      {
        rafterTotalLength: 34,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 12,
        extensionLength: 11,
      },
    ],
  },
  {
    structureName: CONST.LB_STRUCT_SINGLE,
    specCrossSize: DesignConst.ITEM_SPEC_VALUE_40_MM_UNDER,
    data: [
      {
        rafterTotalLength: 9,
        extensionSelected: false,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 9,
        extensionLength: 0,
      },
      {
        rafterTotalLength: 10,
        extensionSelected: false,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 0,
      },
      {
        rafterTotalLength: 11,
        extensionSelected: false,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 11,
        extensionLength: 0,
      },
      {
        rafterTotalLength: 12,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 10,
        extensionLength: 1,
      },
      {
        rafterTotalLength: 13,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 10,
        extensionLength: 1.5,
      },
      {
        rafterTotalLength: 14,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 11,
        extensionLength: 1.5,
      },
      {
        rafterTotalLength: 15,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 11,
        extensionLength: 2,
      },
      {
        rafterTotalLength: 16,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 17,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 7,
      },
      {
        rafterTotalLength: 18,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 8,
      },
      {
        rafterTotalLength: 19,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 9,
      },
      {
        rafterTotalLength: 20,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 10,
      },
      {
        rafterTotalLength: 21,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 10,
        extensionLength: 11,
      },
      {
        rafterTotalLength: 22,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 11,
        extensionLength: 11,
      },
      {
        rafterTotalLength: 23,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 12,
        extensionLength: 11,
      },
      {
        rafterTotalLength: 24,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 12,
        extensionLength: 12,
      },
    ],
  },
  {
    structureName: CONST.LB_STRUCT_SINGLE,
    specCrossSize: DesignConst.ITEM_SPEC_VALUE_40_MM_MORE,
    data: [
      {
        rafterTotalLength: 9,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 6,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 10,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 6,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 11,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 6,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 12,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 6,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 13,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 7,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 14,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 7,
        extensionLength: 7,
      },
      {
        rafterTotalLength: 15,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 7,
        extensionLength: 8,
      },
      {
        rafterTotalLength: 16,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE,
        rafterPipeSpecLength: 8,
        extensionLength: 8,
      },
      {
        rafterTotalLength: 17,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 6,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 18,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 6,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 19,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 7,
        extensionLength: 6,
      },
      {
        rafterTotalLength: 20,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 6,
        extensionLength: 7,
      },
      {
        rafterTotalLength: 21,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 7,
        extensionLength: 7,
      },
      {
        rafterTotalLength: 22,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        extensionLength: 7,
      },
      {
        rafterTotalLength: 23,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 6,
        extensionLength: 8,
      },
      {
        rafterTotalLength: 24,
        extensionSelected: true,
        extensionPosition: CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH,
        rafterPipeSpecLength: 8,
        extensionLength: 8,
      },
    ],
  },
];
