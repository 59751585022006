import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPowerSwitcher } from "vhows-design/src/object/design/item/list/ItemPowerSwitcher";
import { ItemSkylightMotor } from "vhows-design/src/object/design/item/list/ItemSkylightMotor";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkySwitchLevel } from "vhows-design/src/object/design/skylight/switches/SkySwitchLevel";
import { SkySwitchPosition } from "vhows-design/src/object/design/skylight/switches/SkySwitchPosition";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { ControllerPart_Controller } from "vhows-design/src/object/design/switches/controller/ControllerPart_Controller";
import { SwitcherPart_Power } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Power";
import { SkySwitchPart_Rackpinion } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_Rackpinion";
import { Basic } from "vhows-design/src/object/design/basic/Basic";
import { SkySwitchPart_Power } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_Power";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-10-02
 */
@jsonObject({
  knownTypes: [ControllerPart_Controller],
})
export class ControllerPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public controllerPart: ControllerPart_Controller;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _vinylSwitcherQuantity: number = 0; // 비닐 개폐기 수량
  @jsonMember(Number)
  public _nonwovenSwitcherQuantity: number = 0; // 부직포 개폐기 수량
  @jsonMember(Number)
  public _laggingSwitcherQuantity: number = 0; // 보온덮개 개폐기 수량

  /**
   * 비닐 개폐기 수량
   */
  public get vinylSwitcherQuantity(): number {
    return this._vinylSwitcherQuantity;
  }

  public set vinylSwitcherQuantity(value: number) {
    this._vinylSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 부직포 개폐기 수량
   */
  public get nonwovenSwitcherQuantity(): number {
    return this._nonwovenSwitcherQuantity;
  }

  public set nonwovenSwitcherQuantity(value: number) {
    this._nonwovenSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 보온덮개 개폐기 수량
   */
  public get laggingSwitcherQuantity(): number {
    return this._laggingSwitcherQuantity;
  }

  public set laggingSwitcherQuantity(value: number) {
    this._laggingSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      // algorithmBasic();
      // 파트 알고리즘 개별 호출
      let part: Part;
      for (part of this.partAC) {
        part.algorithmBasic();
      }
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    this.controllerPart.positionType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.controllerPart = new ControllerPart_Controller();

    this.partAC = [this.controllerPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.controllerPart = <ControllerPart_Controller>this.partAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    if (this.level.index >= 0) {
      // 제어기
      this.controllerPart.setDefaultData(
        CONST.INDEX_SG_CONTROLLER_DEFAULT,
        true,
        false,
        true,
        CONST.LB_CONTROLLER_DEFAULT,
        "",
        0,
        CONST.LB_CONTROLLER_CONTROLL_TYPE_MANUAL,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_selectedBySwitcherByBasic();
    this.algorithm_usageSwitcherQuantity();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_CONTROLLER_TYPE_CUSTOM) {
      this.controllerPart.selected = true;
      this.controllerPart.visible = true;
    }
  }

  /**
   * 알고리즘: 선택 <- 중수(기본정보), 선택(개폐기), 형태(개폐기), 선택(천창 개폐), 형태(천창 개폐), 규격(동력 개폐기 샘플)
   */
  public algorithm_selectedBySwitcherByBasic(): void {
    const basic: Basic = this.design.basic;
    let isManualAll: boolean = true;

    // 개폐기 작업
    for (const switcherLevel of this.struct.switcherWork.levelAC as SwitcherLevel[]) {
      if (switcherLevel.selected === true) {
        for (const switcherPosition of switcherLevel.positionAC as SwitcherPosition[]) {
          if (switcherPosition.selected === true && switcherPosition.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
            isManualAll = false;
            break;
          }
        }
        if (isManualAll === false) {
          break;
        }
      }
    }
    // 천창 개폐 작업
    if (isManualAll === true) {
      const skySwitchPosition: SkySwitchPosition = this.struct.skySwitchWork.level1.skylightPosition;
      if (skySwitchPosition.selected === true && skySwitchPosition.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
        isManualAll = false;
      }
    }

    if (isManualAll === true) {
      // 모든 형태가 수동이면, 제어기 선택 해제
      this.selected = false;
    } else {
      // 모든 형태가 수동이 아니면, 제어기 선택 체크
      this.selected = true;
    }
  }

  /**
   * 알고리즘: 용도별 개폐기 수량 <- (기본정보), 선택(개폐기), 형태(개폐기), 개폐 방향(개폐기), 최대 차수(개폐기), 규격(동력 개폐기 샘플) <br/>
   *  <- 선택(천창 개폐), 형태(천창 개폐), 개폐 방향(천창 개폐), 규격(동력 개폐기 샘플)
   */
  public algorithm_usageSwitcherQuantity(): void {
    let vinylSwitcher: number = 0;
    let nonwovenSwitcher: number = 0;
    let laggingSwitcher: number = 0;
    let itemPowerSwitcher: ItemPowerSwitcher;
    let skylightMoter: ItemSkylightMotor;

    // 개폐기 작업
    for (const switcherLevel of this.struct.switcherWork.levelAC as SwitcherLevel[]) {
      if (switcherLevel.selected === true) {
        for (const switcherPosition of switcherLevel.positionAC as SwitcherPosition[]) {
          if (switcherPosition.selected === true) {
            const power1Part: SwitcherPart_Power = switcherPosition.power1Part;
            if (power1Part.selected === true) {
              itemPowerSwitcher = power1Part.power_powerSwitcher;
              if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE) {
                nonwovenSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingSwitcher += itemPowerSwitcher.getSelectedQuantity();
              }
            }
            const power2Part: SwitcherPart_Power = switcherPosition.power2Part;
            if (power2Part.selected === true) {
              itemPowerSwitcher = power2Part.power_powerSwitcher;
              if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE) {
                nonwovenSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingSwitcher += itemPowerSwitcher.getSelectedQuantity();
              }
            }
          }
        }
      }
    }

    // 천창 개폐 작업
    for (const skySwitchLevel of this.struct.skySwitchWork.levelAC as SkySwitchLevel[]) {
      if (skySwitchLevel.selected === true) {
        for (const skySwitchPosition of skySwitchLevel.positionAC as SkySwitchPosition[]) {
          if (skySwitchPosition.selected === true) {
            const powerPart: SkySwitchPart_Power = skySwitchPosition.powerPart;
            if (powerPart.selected === true) {
              itemPowerSwitcher = powerPart.power_powerSwitcher;
              if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE) {
                nonwovenSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingSwitcher += itemPowerSwitcher.getSelectedQuantity();
              }
            }
            const rackpinionPart: SkySwitchPart_Rackpinion = skySwitchPosition.rackpinionPart;
            if (rackpinionPart.selected === true) {
              skylightMoter = rackpinionPart.rackpinion_skylightMotor;
              if (skylightMoter.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylSwitcher += skylightMoter.getSelectedQuantity();
              } else if (
                skylightMoter.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE ||
                skylightMoter.specUsage === CONST.ITEM_SPEC_VALUE_SKYLIGHT_USAGE
              ) {
                nonwovenSwitcher += skylightMoter.getSelectedQuantity();
              } else if (skylightMoter.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingSwitcher += skylightMoter.getSelectedQuantity();
              }
            }
          }
        }
      }
    }

    this.vinylSwitcherQuantity = vinylSwitcher;
    this.nonwovenSwitcherQuantity = nonwovenSwitcher;
    this.laggingSwitcherQuantity = laggingSwitcher;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
