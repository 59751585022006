import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 철근
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-02-18
 */
@jsonObject
export class ItemSteelBar extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [형태, 번호]
  public _specSteelBarType: string = undefined; // 형태
  public _specNumber: string = undefined; // 번호

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 형태
   */
  public get specSteelBarType(): string {
    return this._specSteelBarType;
  }
  //
  public set specSteelBarType(value: string) {
    this._specSteelBarType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 번호
   */
  public get specNumber(): string {
    return this._specNumber;
  }
  //
  public set specNumber(value: string) {
    this._specNumber = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.steelBarCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specSteelBarType = specsArray[0];
    this.specNumber = specsArray[1];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specSteelBarType = specsArray[0];
    this.specNumber = specsArray[1];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specSteelBarType + CONST.DELIMITER_SPEC + this._specNumber;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }
}
