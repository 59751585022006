import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { SkirtSample_StringVinyl } from "vhows-design/src/object/design/cover/skirt/SkirtSample_StringVinyl";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-23
 */
@jsonObject({
  knownTypes: [SkirtSample_StringVinyl],
})
export class SkirtPart_String extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public stringVinylSample: SkirtSample_StringVinyl; // 끈 비닐 샘플

  // 아이템
  // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 치마 길이 AC */
  public skirtLengthAC: number[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _skirtWidth: number; // 치마 폭
  @jsonMember(Number)
  public _skirtLength: number; // 치마 길이

  /**
   * 치마 폭
   */
  public get skirtWidth(): number {
    return this._skirtWidth;
  }

  //
  public set skirtWidth(value: number) {
    this._skirtWidth = CommonUtil.fixFloat(value);
  }

  /**
   * 치마 길이
   */
  public get skirtLength(): number {
    return this._skirtLength;
  }

  //
  public set skirtLength(value: number) {
    this._skirtLength = CommonUtil.fixFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.stringVinylSample = new SkirtSample_StringVinyl();

    this.sampleAC = [this.stringVinylSample];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.stringVinylSample = <SkirtSample_StringVinyl>this.sampleAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.stringVinylSample.setDefaultData(
        0,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "끈 치마",
        "0.1mm, 120cm, 200m, 일타, 박스, 한끈",
        "기본회사, 장수, 0.94",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    /// //////// 선언 ///////////

    const skirtPosition: SkirtPosition = <SkirtPosition>this.position;
    // 외부
    const switcherPosition: SwitcherPosition = <SwitcherPosition>(
      this.struct.switcherWork.levelAC[this.level.index].positionAC[this.position.index]
    );
    // 기타
    let item: Item;
    let basicBuilding: BasicBuilding;
    let i: number;

    /// //////// 수치 ///////////

    if (skirtPosition.label === CONST.LB_POSITION_SIDE) {
      this.skirtWidth = skirtPosition.skirtHeight + 0.2;
      this.skirtLength = this.basicLevel.length;
      if (this.basic.isVariableLength === true) {
        this.skirtLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.skirtLengthAC.push(basicBuilding.length);
        }
      }
    } else if (skirtPosition.label === CONST.LB_POSITION_ROOF) {
      if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
        this.skirtWidth = skirtPosition.skirtHeight + 0.2;
      } else {
        this.skirtWidth = skirtPosition.skirtHeight + this.basicLevel.distanceShoulderPad + 0.2;
      }
      this.skirtLength = this.basicLevel.length;
      if (this.basic.isVariableLength === true) {
        this.skirtLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.skirtLengthAC.push(basicBuilding.length);
        }
      }
    } else if (skirtPosition.label === CONST.LB_POSITION_FRONT || skirtPosition.label === CONST.LB_POSITION_BACK) {
      this.skirtWidth = skirtPosition.skirtHeight + 0.2;
      this.skirtLength = this.basicLevel.width * this.basic.buildingNumber;
    }

    /// //////// 생성, 선택, 가시성, 규격, 수량 ///////////

    // 측면
    if (this.position.label === CONST.LB_POSITION_SIDE) {
      if (this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < this.basic.buildingNumber; i++) {
          item = this.getNewSkirtItem(true, i);
          item.purpose = `${i + 1}번동 끈 치마`;
          item.designQuantity = switcherPosition.switchWayValue;

          this.itemAC.push(item);
        }
      } else {
        // 동수만큼 수량 입력
        item = this.getNewSkirtItem();
        item.purpose = "끈 치마";
        item.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < this.basic.buildingNumber; i++) {
          item = this.getNewSkirtItem(true, i);
          item.purpose = `${i + 1}번동 끈 치마`;
          item.designQuantity = switcherPosition.switchWayValue;

          this.itemAC.push(item);
        }
      } else {
        // 동수만큼 수량 입력
        item = this.getNewSkirtItem();
        item.purpose = "끈 치마";
        item.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      item = this.getNewSkirtItem();
      item.purpose = "끈 치마";
      item.designQuantity = 1;

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      item = this.getNewSkirtItem();
      item.purpose = "끈 치마";
      item.designQuantity = 1;

      this.itemAC.push(item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 아이템 <- 샘플
   */
  public algorithmSpec_item(): void {
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemVinyl) {
        (<ItemVinyl>item).specsList = this.stringVinylSample.specsList;
        (<ItemVinyl>item).specWidthType = this.stringVinylSample.specWidthType;
        (<ItemVinyl>item).specPackingType = this.stringVinylSample.specPackingType;
        (<ItemVinyl>item).specStringType = this.stringVinylSample.specStringType;
        (<ItemVinyl>item).brands = this.stringVinylSample.brands;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 새로운 치마 피복 아이템 만들기
   * @param isVariableLength 가변길이 여부
   * @param buildingIndex 동수 인덱스
   * @return 치마 피복 아이템
   */
  private getNewSkirtItem(isVariableLength: boolean = false, buildingIndex: number = 0): ItemVinyl {
    let item: ItemVinyl;
    item = ItemVinyl.getNewDesignItemBySample(this, this.stringVinylSample);

    (<ItemVinyl>item).specWidth = (<ItemVinyl>item).getFoldedWidth(CommonUtil.convertM2CM(this.skirtWidth));

    if (isVariableLength === true) {
      (<ItemVinyl>item).specLength = this.skirtLengthAC[buildingIndex];
    } else {
      (<ItemVinyl>item).specLength = this.skirtLength;
    }
    return item;
  }
}
