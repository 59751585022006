import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { WingPartVL_WingRafter } from "vhows-design/src/object/design/frame/wing/vl/WingPartVL_WingRafter";
import { WingPartVL_WingStraight } from "vhows-design/src/object/design/frame/wing/vl/WingPartVL_WingStraight";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject({
  knownTypes: [WingPartVL_WingRafter, WingPartVL_WingStraight],
})
export class WingPositionVL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public wingRafterPart: WingPartVL_WingRafter;
  public wingStraightPart: WingPartVL_WingStraight;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 지붕 골조
    this.struct.roofWorkVL.level1.roofPosition.rafterPart.algorithmPart();

    // 개폐기
    (<SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index]).wingPosition.algorithm_seletedByWing();

    // 피복 고정
    (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).wingPosition.algorithm_seletedByWing();

    // 밴드끈
    if (this.level.index === 0) {
      this.struct.bandstringWorkIL.level1.trunkPosition.bandstringPart.algorithm_bandstringLength();
      this.struct.bandstringWorkIL.level1.trunkPosition.bandstringPart.algorithm_totalBandstringLength();
      this.struct.bandstringWorkIL.level1.trunkPosition.polyPart.algorithm_bandstringLength();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    for (const coverPosition of this.struct.coverWorkIL.levelAC[this.level.index].positionAC as CoverPositionIL[]) {
      if (this.level.index === 0) {
        coverPosition.algorithm_selectedAtColumn();
      }
      coverPosition.algorithm_selectedAtWing();
      coverPosition.algorithm_scopeSelectedByScopeType();
      coverPosition.algorithm_switcherOrderValue();
      coverPosition.algorithm_skirtCoverSelected();
      coverPosition.algorithm_windbreakCoverSelected();
    }
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.wingRafterPart = new WingPartVL_WingRafter();
    this.wingStraightPart = new WingPartVL_WingStraight();

    this.partAC = [this.wingRafterPart, this.wingStraightPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.wingRafterPart = <WingPartVL_WingRafter>this.partAC[0];
    this.wingStraightPart = <WingPartVL_WingStraight>this.partAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    if (this.level.index === 0) {
      // 서까래
      this.wingRafterPart.setDefaultData(
        CONST.INDEX_IL_WING_RAFTER,
        true,
        false,
        true,
        CONST.LB_WING_RAFTER,
        "",
        0.6,
        4.0,
        true,
        CONST.LB_WING_ENHANCED_TYPE_BENDING,
      );

      if (this.label === CONST.LB_POSITION_SIDE_WING) {
        // 가로대
        this.wingStraightPart.setDefaultData(
          CONST.INDEX_IL_WING_STRAIGHT,
          true,
          false,
          true,
          CONST.LB_WING_STRAIGHT,
          "",
          8,
        );
      } else if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
        // 가로대
        this.wingStraightPart.setDefaultData(
          CONST.INDEX_IL_WING_STRAIGHT,
          true,
          false,
          true,
          CONST.LB_WING_STRAIGHT,
          "",
          3,
        );
      }
    } else {
      // 서까래
      this.wingRafterPart.setDefaultData(
        CONST.INDEX_IL_WING_RAFTER,
        true,
        false,
        true,
        CONST.LB_WING_RAFTER,
        "",
        2.0,
        3.5,
        true,
        CONST.LB_WING_ENHANCED_TYPE_BENDING,
      );

      if (this.label === CONST.LB_POSITION_SIDE_WING) {
        // 가로대
        this.wingStraightPart.setDefaultData(
          CONST.INDEX_IL_WING_STRAIGHT,
          true,
          false,
          true,
          CONST.LB_WING_STRAIGHT,
          "",
          4,
        );
      } else if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
        // 가로대
        this.wingStraightPart.setDefaultData(
          CONST.INDEX_IL_WING_STRAIGHT,
          true,
          false,
          true,
          CONST.LB_WING_STRAIGHT,
          "",
          2,
        );
      }
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태, 양끝동 서까래 확장 선택(지붕 골조)
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_WING_TYPE_DEFAULT || this.type === CONST.LB_WING_TYPE_SIMPLE) {
      this.wingRafterPart.selected = true;
      this.wingRafterPart.visible = true;
      this.wingStraightPart.selected = true;
      this.wingStraightPart.visible = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
