import { jsonObject } from "typedjson";

import { ItemMat } from "vhows-design/src/object/design/item/list/ItemMat";
import { CurCoverPartIL_Mat } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL_Mat";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject
export class CurCoverSampleIL_Mat extends ItemMat {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const matPart: CurCoverPartIL_Mat = <CurCoverPartIL_Mat>this.part;
    for (const item of matPart.itemAC as ItemMat[]) {
      item.specsList = this.specsList;
    }

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
