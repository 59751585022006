import { jsonObject } from "typedjson";

import { ItemWaterTank } from "vhows-design/src/object/design/item/list/ItemWaterTank";
import { MainpipePart_PeMain1 } from "vhows-design/src/object/design/watering/mainpipe/MainpipePart_PeMain1";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject
export class MainpipeSample_WaterTank extends ItemWaterTank {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const peMain1Part: MainpipePart_PeMain1 = <MainpipePart_PeMain1>this.part;
    peMain1Part.algorithmSpec_waterTank_waterTank();
    peMain1Part.algorithmSpec_waterTankIn_waterTankFitting();
    peMain1Part.algorithmSpec_waterTankExit_waterTankFitting();
    peMain1Part.algorithmSpec_waterTankExit_femaleMaleValve();
    peMain1Part.algorithmSpec_waterTankOut_waterTankFitting();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 주관1 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specOutDiameter = (<MainpipePart_PeMain1>this.part).main1PePipeSample.specDiameter;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
