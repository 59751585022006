import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPadVinyl } from "vhows-design/src/object/design/item/list/ItemPadVinyl";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { FixingSample_LaggingPad } from "vhows-design/src/object/design/cover/fixing/FixingSample_LaggingPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-06-15
 */
@jsonObject({
  knownTypes: [FixingSample_LaggingPad],
})
export class FixingPart_LaggingPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public laggingPadSample: FixingSample_LaggingPad; // 광폭 패드 샘플

  // 아이템
  public laggingPad_laggingPad: ItemPad;
  public laggingPad_laggingPadSpring: ItemPadSpring;
  public laggingPad_padConnectionPin: ItemPadConnector;
  public laggingPad_padVinyl: ItemPadVinyl;
  public laggingPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.laggingPadSample = new FixingSample_LaggingPad();

    this.sampleAC = [this.laggingPadSample];

    // 아이템
    this.laggingPad_laggingPad = new ItemPad();
    this.laggingPad_laggingPadSpring = new ItemPadSpring();
    this.laggingPad_padConnectionPin = new ItemPadConnector();
    this.laggingPad_padVinyl = new ItemPadVinyl();
    this.laggingPad_screw = new ItemScrew();

    this.itemAC = [
      this.laggingPad_laggingPad,
      this.laggingPad_laggingPadSpring,
      this.laggingPad_padConnectionPin,
      this.laggingPad_padVinyl,
      this.laggingPad_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.laggingPadSample = <FixingSample_LaggingPad>this.sampleAC[0];

    // 아이템
    this.laggingPad_laggingPad = <ItemPad>this.itemAC[0];
    this.laggingPad_laggingPadSpring = <ItemPadSpring>this.itemAC[1];
    this.laggingPad_padConnectionPin = <ItemPadConnector>this.itemAC[2];
    this.laggingPad_padVinyl = <ItemPadVinyl>this.itemAC[3];
    this.laggingPad_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.laggingPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_LAGGING_PAD,
        CONST.ITEM_NAME_LAGGING_PAD,
        "광폭 패드",
        "칼라 철, 0.7T, 6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.laggingPad_laggingPad.setDefaultData(
        0,
        CONST.ITEM_ID_LAGGING_PAD,
        CONST.ITEM_NAME_LAGGING_PAD,
        "광폭 패드",
        "칼라 철, 0.7T, 6m",
      );
      this.laggingPad_laggingPadSpring.setDefaultData(
        1,
        CONST.ITEM_ID_LAGGING_PAD_SPRING,
        CONST.ITEM_NAME_LAGGING_PAD_SPRING,
        "광폭 패드",
        "일반, 2m",
      );
      this.laggingPad_padConnectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "광폭 패드",
        "칼라 철",
      );
      this.laggingPad_padVinyl.setDefaultData(
        3,
        CONST.ITEM_ID_PAD_VINYL,
        CONST.ITEM_NAME_PAD_VINYL,
        "광폭 패드",
        "일반, 200m",
      );
      this.laggingPad_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "광폭 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const fixingPosition: FixingPosition = <FixingPosition>this.position;
    let totalLength: number;
    // 외부
    const trunkPart_Rafter: TrunkPart_Rafter = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index])
      .trunkPosition.rafterPart;
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];
    const coverLevel: CoverLevel = <CoverLevel>this.struct.coverWork.levelAC[this.level.index];

    /// //////// 선택, 가시성 ///////////

    this.laggingPad_laggingPad.selected = true;
    this.laggingPad_laggingPad.visible = true;
    this.laggingPad_laggingPadSpring.selected = true;
    this.laggingPad_laggingPadSpring.visible = true;
    this.laggingPad_padConnectionPin.selected = true;
    this.laggingPad_padConnectionPin.visible = true;
    if (this.level.index === 0) {
      this.laggingPad_padVinyl.selected = this.design.basic.padVinylFlag;
    } else {
      this.laggingPad_padVinyl.selected = false;
    }
    this.laggingPad_padVinyl.visible = true;
    this.laggingPad_screw.selected = true;
    this.laggingPad_screw.visible = true;

    /// //////// 수량 ///////////

    if (fixingPosition.label === CONST.LB_POSITION_SIDE || fixingPosition.label === CONST.LB_POSITION_ROOF) {
      this.laggingPad_laggingPad.designQuantity =
        (this.basicLevel.length / this.laggingPad_laggingPad.specLength) *
        fixingPosition.lineNumber *
        this.basic.buildingNumber;
    } else if (fixingPosition.label === CONST.LB_POSITION_FRONT || fixingPosition.label === CONST.LB_POSITION_BACK) {
      totalLength = 0;
      if (fixingPosition.type === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        totalLength += this.basicLevel.width * fixingPosition.lineNumber;
      }
      if (fixingPosition.endpieceRafterType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        totalLength += trunkPart_Rafter.rafterTotalLength * fixingPosition.endpieceRafterLineNumber;
      }
      if (fixingPosition.endpieceGateType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        totalLength += 2.0 * fixingPosition.endpieceGateLineNumber;
      }
      totalLength *= this.basic.buildingNumber;
      this.laggingPad_laggingPad.designQuantity = totalLength / this.laggingPad_laggingPad.specLength;
    }

    let laggingPad_laggingPadSpring_designQuantity: number =
      this.laggingPad_laggingPad.getSelectedQuantity() *
      (this.laggingPad_laggingPad.specLength / this.laggingPad_laggingPadSpring.specLength);
    laggingPad_laggingPadSpring_designQuantity *= this.design.preference.getPadSpringMultFactor(
      coverLevel.coverLayers[this.position.index],
    );
    this.laggingPad_laggingPadSpring.designQuantity =
      laggingPad_laggingPadSpring_designQuantity * CONST.NUM_EXTRA_RATE_PAD_SPRING;

    this.laggingPad_padConnectionPin.designQuantity = this.laggingPad_laggingPad.getSelectedQuantity();

    this.laggingPad_padVinyl.designQuantity =
      (this.laggingPad_laggingPad.getSelectedQuantity() * this.laggingPad_laggingPad.specLength) /
      this.laggingPad_padVinyl.specLength;

    this.laggingPad_screw.designQuantity =
      ((this.laggingPad_laggingPad.getSelectedQuantity() * 6 +
        this.laggingPad_padConnectionPin.getSelectedQuantity() * 2) /
        this.laggingPad_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [광폭 패드] 광폭 패드 <- 광폭 패드 샘플
   */
  public algorithmSpec_laggingPad_laggingPad(): void {
    this.laggingPad_laggingPad.specs = this.laggingPadSample.specs;
  }

  /**
   * 규격 알고리즘: [광폭 패드] 광폭 패드 스프링 <- 고정
   */
  public algorithmSpec_laggingPad_laggingPadSpring(): void {}

  /**
   * 규격 알고리즘: [광폭 패드] 패드 연결핀 <- 광폭 패드 샘플
   */
  public algorithmSpec_laggingPad_padConnectionPin(): void {
    this.laggingPad_padConnectionPin.specMaterial = this.laggingPadSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [광폭 패드] 패드 비닐 <- 공통 샘플
   */
  public algorithmSpec_laggingPad_padVinyl(): void {}

  /**
   * 규격 알고리즘: [광폭 패드] 나사 <- 공통 샘플
   */
  public algorithmSpec_laggingPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
