import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { RoofPartIL_Straight } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Straight";
import { RoofPartIL_RoofBracing } from "vhows-design/src/object/design/frame/roof/RoofPartIL_RoofBracing";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { RoofPartIL_Rafter } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Rafter";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-21
 */
@jsonObject({
  knownTypes: [RoofPartIL_Rafter, RoofPartIL_Straight, RoofPartIL_RoofBracing],
})
export class RoofPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public rafterPart: RoofPartIL_Rafter;
  public straightPart: RoofPartIL_Straight;
  public roofBracingPart: RoofPartIL_RoofBracing;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _frontEaveRafterQuantity: number; // 앞면 처마 서까래 수량
  @jsonMember(Number)
  public _frontEaveLength: number; // 앞면 처마 길이
  @jsonMember(Number)
  public _backEaveRafterQuantity: number; // 뒷면 처마 서까래 수량
  @jsonMember(Number)
  public _backEaveLength: number; // 뒷면 처마 길이

  /**
   * 앞면 처마 서까래 수량
   */
  public get frontEaveRafterQuantity(): number {
    return this._frontEaveRafterQuantity;
  }

  //
  public set frontEaveRafterQuantity(value: number) {
    this._frontEaveRafterQuantity = value;

    // 알고리즘
    this.algorithm_frontEaveLength();
    this.rafterPart.algorithm_rafterQuantity();

    /// //////// 외부 ///////////

    if (this.level.index === 0) {
      // 피복 고정
      this.struct.fixingWorkIL.level1.frontPosition.algorithm_endpieceRafterLineNumber();
    }
  }

  /**
   * 앞면 처마 길이
   */
  public get frontEaveLength(): number {
    return this._frontEaveLength;
  }

  //
  public set frontEaveLength(value: number) {
    this._frontEaveLength = value;

    // 알고리즘
    this.straightPart.algorithm_straightLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    if (this.level.index === 0) {
      // 기둥 골조
      this.struct.columnWorkIL.level1.columnPosition.cylinderBeamPart.algorithm_beamLength();
      this.struct.columnWorkIL.level1.columnPosition.prismBeamPart.algorithm_beamLength();

      // 물받이
      this.struct.gutterWorkIL.level1.gutterPosition.ironPlatePart.algorithmPart();
      this.struct.gutterWorkIL.level1.gutterPosition.plasticPart.algorithmPart();
      this.struct.gutterWorkIL.level1.gutterPosition.weavingFilmPart.algorithmPart();

      // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
      (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
    }
  }

  /**
   * 뒷면 처마 서까래 수량
   */
  public get backEaveRafterQuantity(): number {
    return this._backEaveRafterQuantity;
  }

  //
  public set backEaveRafterQuantity(value: number) {
    this._backEaveRafterQuantity = value;

    // 알고리즘
    this.algorithm_backEaveLength();
    this.rafterPart.algorithm_rafterQuantity();

    /// //////// 외부 ///////////

    if (this.level.index === 0) {
      // 피복 고정
      this.struct.fixingWorkIL.level1.backPosition.algorithm_endpieceRafterLineNumber();
    }
  }

  /**
   * 뒷면 처마 길이
   */
  public get backEaveLength(): number {
    return this._backEaveLength;
  }

  //
  public set backEaveLength(value: number) {
    this._backEaveLength = value;

    // 알고리즘
    this.straightPart.algorithm_straightLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    if (this.level.index === 0) {
      // 기둥 골조
      this.struct.columnWorkIL.level1.columnPosition.cylinderBeamPart.algorithm_beamLength();
      this.struct.columnWorkIL.level1.columnPosition.prismBeamPart.algorithm_beamLength();

      // 물받이
      this.struct.gutterWorkIL.level1.gutterPosition.ironPlatePart.algorithmPart();
      this.struct.gutterWorkIL.level1.gutterPosition.plasticPart.algorithmPart();
      this.struct.gutterWorkIL.level1.gutterPosition.weavingFilmPart.algorithmPart();

      // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
      (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
    }
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    // 물받이
    (<GutterLevelIL>this.struct.gutterWorkIL.levelAC[this.level.index]).gutterPosition.algorithm_selectedByRoof();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.rafterPart = new RoofPartIL_Rafter();
    this.straightPart = new RoofPartIL_Straight();
    this.roofBracingPart = new RoofPartIL_RoofBracing();

    this.partAC = [this.rafterPart, this.straightPart, this.roofBracingPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.rafterPart = <RoofPartIL_Rafter>this.partAC[0];
    this.straightPart = <RoofPartIL_Straight>this.partAC[1];
    this.roofBracingPart = <RoofPartIL_RoofBracing>this.partAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param frontEaveRafterQuantity: number 앞면 처마 서까래 수량
   * @param frontEaveLength: number 앞면 처마 길이
   * @param backEaveRafterQuantity: number 뒷면 처마 서까래 수량
   * @param backEaveLength: number 뒷면 처마 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    frontEaveRafterQuantity: number = 0,
    frontEaveLength: number = 0,
    backEaveRafterQuantity: number = 0,
    backEaveLength: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._frontEaveRafterQuantity = frontEaveRafterQuantity;
    this._frontEaveLength = frontEaveLength;
    this._backEaveRafterQuantity = backEaveRafterQuantity;
    this._backEaveLength = backEaveLength;

    if (this.level.index === 0) {
      // 서까래
      this.rafterPart.setDefaultData(
        CONST.INDEX_IL_ROOF_RAFTER,
        true,
        false,
        true,
        CONST.LB_ROOF_RAFTER,
        "",
        0.6,
        false,
        1.3,
        2.0,
        4,
        4,
      );

      // 가로대
      this.straightPart.setDefaultData(
        CONST.INDEX_IL_ROOF_STRAIGHT,
        true,
        false,
        true,
        CONST.LB_ROOF_STRAIGHT,
        "",
        7.0,
        false,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        3.0,
      );

      // 지붕 브레싱
      this.roofBracingPart.setDefaultData(CONST.INDEX_IL_ROOF_BRACING, true, false, true, CONST.LB_ROOF_BRACING, "", 4);
    } else {
      // 서까래
      this.rafterPart.setDefaultData(
        CONST.INDEX_IL_ROOF_RAFTER,
        true,
        false,
        true,
        CONST.LB_ROOF_RAFTER,
        "",
        2.0,
        false,
        1.3,
        2.0,
        3.5,
        3.5,
      );

      // 가로대
      this.straightPart.setDefaultData(
        CONST.INDEX_IL_ROOF_STRAIGHT,
        true,
        false,
        true,
        CONST.LB_ROOF_STRAIGHT,
        "",
        3.0,
        false,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        3.0,
      );

      // 지붕 브레싱
      this.roofBracingPart.setDefaultData(CONST.INDEX_IL_ROOF_BRACING, true, false, true, CONST.LB_ROOF_BRACING, "", 4);
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_ROOF_TYPE_PEACH) {
      this.rafterPart.selected = true;
      this.rafterPart.visible = true;
      this.straightPart.selected = true;
      this.straightPart.visible = true;
      this.roofBracingPart.selected = true;
      this.roofBracingPart.visible = true;
    } else if (this.type === CONST.LB_ROOF_TYPE_ROUND) {
      this.rafterPart.selected = true;
      this.rafterPart.visible = true;
      this.straightPart.selected = true;
      this.straightPart.visible = true;
      this.roofBracingPart.selected = true;
      this.roofBracingPart.visible = true;
    }
  }

  /**
   * 알고리즘: 앞면 처마 길이 <- 서까래 간격(파트)
   */
  public algorithm_frontEaveLength(): void {
    if (this.frontEaveRafterQuantity > 0) {
      this.frontEaveLength = this.frontEaveRafterQuantity * this.rafterPart.rafterInterval;
    } else {
      this.frontEaveLength = 0;
    }
  }

  /**
   * 알고리즘: 뒷면 처마 길이 <- 서까래 간격(파트)
   */
  public algorithm_backEaveLength(): void {
    if (this.backEaveRafterQuantity > 0) {
      this.backEaveLength = this.backEaveRafterQuantity * this.rafterPart.rafterInterval;
    } else {
      this.backEaveLength = 0;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
