import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWateringValve } from "vhows-design/src/object/design/item/list/ItemWateringValve";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { ItemWateringConnect } from "vhows-design/src/object/design/item/list/ItemWateringConnect";
import { ItemNutrientSolution } from "vhows-design/src/object/design/item/list/ItemNutrientSolution";
import { ItemWaterTankController } from "vhows-design/src/object/design/item/list/ItemWaterTankController";
import { ItemWaterTank } from "vhows-design/src/object/design/item/list/ItemWaterTank";
import { ItemWaterTankFitting } from "vhows-design/src/object/design/item/list/ItemWaterTankFitting";
import { ItemWaterPump } from "vhows-design/src/object/design/item/list/ItemWaterPump";
import { ItemWaterFilter } from "vhows-design/src/object/design/item/list/ItemWaterFilter";
import { ItemWaterPumpController } from "vhows-design/src/object/design/item/list/ItemWaterPumpController";
import { ItemDripBarb } from "vhows-design/src/object/design/item/list/ItemDripBarb";
import { ItemDripConnect } from "vhows-design/src/object/design/item/list/ItemDripConnect";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { Part } from "vhows-design/src/object/design/base/Part";
import { HydSystemPosition } from "vhows-design/src/object/design/hydroponics/system/HydSystemPosition";
import { HydSystemSample_NutrientSolution } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_NutrientSolution";
import { HydSystemSample_ControlMainPePipe } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_ControlMainPePipe";
import { HydSystemSample_ControlWaterTank } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_ControlWaterTank";
import { HydSystemSample_ControlWaterPump } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_ControlWaterPump";
import { HydSystemSample_ControlWaterFilter } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_ControlWaterFilter";
import { HydSystemSample_ControlNutrientTank } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_ControlNutrientTank";
import { HydSystemSample_ControlPHTank } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_ControlPHTank";
import { HydSystemSample_ControlNutAndPhFilter } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_ControlNutAndPhFilter";
import { HydSystemSample_ControlSubPePipe } from "vhows-design/src/object/design/hydroponics/system/HydSystemSample_ControlSubPePipe";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject({
  knownTypes: [
    HydSystemSample_NutrientSolution,
    HydSystemSample_ControlMainPePipe,
    HydSystemSample_ControlWaterTank,
    HydSystemSample_ControlWaterPump,
    HydSystemSample_ControlWaterFilter,
    HydSystemSample_ControlNutrientTank,
    HydSystemSample_ControlPHTank,
    HydSystemSample_ControlNutAndPhFilter,
    HydSystemSample_ControlSubPePipe,
  ],
})
export class HydSystemPart_HydControl extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public nutrientSolutionSample: HydSystemSample_NutrientSolution; // 양액시스템 샘플
  public controlMainPePipeSample: HydSystemSample_ControlMainPePipe; // 제어 주관 샘플
  public waterTankSample: HydSystemSample_ControlWaterTank; // 원수탱크 샘플
  public waterPumpSample: HydSystemSample_ControlWaterPump; // 원수탱크 펌프 샘플
  public waterFilterSample: HydSystemSample_ControlWaterFilter; // 원수탱크 여과기 샘플
  public nutrientTankSample: HydSystemSample_ControlNutrientTank; // 양액탱크 샘플
  public phTankSample: HydSystemSample_ControlPHTank; // PH탱크 샘플
  public nutAndPhFilterSample: HydSystemSample_ControlNutAndPhFilter; // 양액/PH탱크 여과기 샘플
  public controlSubPePipeSample: HydSystemSample_ControlSubPePipe; // 제어 보조관 샘플

  // 아이템
  public nutrientSolution: ItemNutrientSolution;
  public controlBox_waterLevelController: ItemWaterTankController;
  public controlBox_nutrientMixer: ItemWaterTankController;
  public controlBox_waterPumpController: ItemWaterPumpController;
  public controlMain_pePipe: ItemWateringPipe;
  public waterTank_waterTank: ItemWaterTank;
  public waterTankIn_waterTankFitting: ItemWaterTankFitting;
  public waterTankExit_waterTankFitting: ItemWaterTankFitting;
  public waterTankOut_waterTankFitting: ItemWaterTankFitting;
  public waterTankOut_nipple: ItemWateringConnect;
  public waterTankOut_femaleBallValve: ItemWateringValve;
  public waterPump_waterPump: ItemWaterPump;
  public waterPump_bushing: ItemWateringConnect;
  public waterPump_maleElbowSocket: ItemWateringConnect;
  public waterFilter_waterFilter: ItemWaterFilter;
  public waterFilter_socketFemale: ItemWateringConnect;
  public controlMain_elbow: ItemWateringConnect;
  public controlMain_exactT: ItemWateringConnect;
  public controlMain_unionValve: ItemWateringValve;
  public nutrientTank_waterTank: ItemWaterTank;
  public phTank_waterTank: ItemWaterTank;
  public nutAndPhTankIn_waterTankFitting: ItemWaterTankFitting;
  public nutAndPhTankExit_waterTankFitting: ItemWaterTankFitting;
  public nutAndPhTankOut_waterTankFitting: ItemWaterTankFitting;
  public nutAndPhTankOut_nipple: ItemWateringConnect;
  public nutAndPhTankOut_femaleBallValve: ItemWateringValve;
  public controlMain_valveSocket: ItemWateringConnect;
  public nutAndPhFilter_wtaerFilter: ItemWaterFilter;
  public nutAndPhFilter_socketFemale: ItemWateringConnect;
  public nutAndPhFilter_dripBarb: ItemDripBarb;
  public controlSub_PePipe: ItemWateringPipe;
  public controlSub_ldElbow: ItemDripConnect;
  public control_electricWire: ItemElectricWire;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _controlMainLength: number = 0; // 제어 주관 길이
  @jsonMember(Number)
  public _controlSubLength: number = 0; // 제어 보조관 길이
  @jsonMember(Boolean)
  public _nutrientSolutionSelected: boolean; // 양액시스템 선택

  /**
   * 제어 주관 길이
   */
  public get controlMainLength(): number {
    return this._controlMainLength;
  }

  //
  public set controlMainLength(value: number) {
    this._controlMainLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 제어 보조관 길이
   */
  public get controlSubLength(): number {
    return this._controlSubLength;
  }

  //
  public set controlSubLength(value: number) {
    this._controlSubLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 양액시스템 선택
   */
  public get nutrientSolutionSelected(): boolean {
    return this._nutrientSolutionSelected;
  }

  //
  public set nutrientSolutionSelected(value: boolean) {
    this._nutrientSolutionSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플

    this.nutrientSolutionSample = new HydSystemSample_NutrientSolution();
    this.controlMainPePipeSample = new HydSystemSample_ControlMainPePipe();
    this.waterTankSample = new HydSystemSample_ControlWaterTank();
    this.waterPumpSample = new HydSystemSample_ControlWaterPump();
    this.waterFilterSample = new HydSystemSample_ControlWaterFilter();
    this.nutrientTankSample = new HydSystemSample_ControlNutrientTank();
    this.phTankSample = new HydSystemSample_ControlPHTank();
    this.nutAndPhFilterSample = new HydSystemSample_ControlNutAndPhFilter();
    this.controlSubPePipeSample = new HydSystemSample_ControlSubPePipe();

    this.sampleAC = [
      this.nutrientSolutionSample,
      this.controlMainPePipeSample,
      this.waterTankSample,
      this.waterPumpSample,
      this.waterFilterSample,
      this.nutrientTankSample,
      this.phTankSample,
      this.nutAndPhFilterSample,
      this.controlSubPePipeSample,
    ];

    // 아이템
    this.nutrientSolution = new ItemNutrientSolution();
    this.controlBox_waterLevelController = new ItemWaterTankController();
    this.controlBox_nutrientMixer = new ItemWaterTankController();
    this.controlBox_waterPumpController = new ItemWaterPumpController();
    this.controlMain_pePipe = new ItemWateringPipe();
    this.waterTank_waterTank = new ItemWaterTank();
    this.waterTankIn_waterTankFitting = new ItemWaterTankFitting();
    this.waterTankExit_waterTankFitting = new ItemWaterTankFitting();
    this.waterTankOut_waterTankFitting = new ItemWaterTankFitting();
    this.waterTankOut_nipple = new ItemWateringConnect();
    this.waterTankOut_femaleBallValve = new ItemWateringValve();
    this.waterPump_waterPump = new ItemWaterPump();
    this.waterPump_bushing = new ItemWateringConnect();
    this.waterPump_maleElbowSocket = new ItemWateringConnect();
    this.waterFilter_waterFilter = new ItemWaterFilter();
    this.waterFilter_socketFemale = new ItemWateringConnect();
    this.controlMain_elbow = new ItemWateringConnect();
    this.controlMain_exactT = new ItemWateringConnect();
    this.controlMain_unionValve = new ItemWateringValve();
    this.nutrientTank_waterTank = new ItemWaterTank();
    this.phTank_waterTank = new ItemWaterTank();
    this.nutAndPhTankIn_waterTankFitting = new ItemWaterTankFitting();
    this.nutAndPhTankExit_waterTankFitting = new ItemWaterTankFitting();
    this.nutAndPhTankOut_waterTankFitting = new ItemWaterTankFitting();
    this.nutAndPhTankOut_nipple = new ItemWateringConnect();
    this.nutAndPhTankOut_femaleBallValve = new ItemWateringValve();
    this.controlMain_valveSocket = new ItemWateringConnect();
    this.nutAndPhFilter_wtaerFilter = new ItemWaterFilter();
    this.nutAndPhFilter_socketFemale = new ItemWateringConnect();
    this.nutAndPhFilter_dripBarb = new ItemDripBarb();
    this.controlSub_PePipe = new ItemWateringPipe();
    this.controlSub_ldElbow = new ItemDripConnect();
    this.control_electricWire = new ItemElectricWire();

    this.itemAC = [
      this.nutrientSolution,
      this.controlBox_waterLevelController,
      this.controlBox_nutrientMixer,
      this.controlBox_waterPumpController,
      this.controlMain_pePipe,
      this.waterTank_waterTank,
      this.waterTankIn_waterTankFitting,
      this.waterTankExit_waterTankFitting,
      this.waterTankOut_waterTankFitting,
      this.waterTankOut_nipple,
      this.waterTankOut_femaleBallValve,
      this.waterPump_waterPump,
      this.waterPump_bushing,
      this.waterPump_maleElbowSocket,
      this.waterFilter_waterFilter,
      this.waterFilter_socketFemale,
      this.controlMain_elbow,
      this.controlMain_exactT,
      this.controlMain_unionValve,
      this.nutrientTank_waterTank,
      this.phTank_waterTank,
      this.nutAndPhTankIn_waterTankFitting,
      this.nutAndPhTankExit_waterTankFitting,
      this.nutAndPhTankOut_waterTankFitting,
      this.nutAndPhTankOut_nipple,
      this.nutAndPhTankOut_femaleBallValve,
      this.controlMain_valveSocket,
      this.nutAndPhFilter_wtaerFilter,
      this.nutAndPhFilter_socketFemale,
      this.nutAndPhFilter_dripBarb,
      this.controlSub_PePipe,
      this.controlSub_ldElbow,
      this.control_electricWire,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.nutrientSolutionSample = <HydSystemSample_NutrientSolution>this.sampleAC[0];
    this.controlMainPePipeSample = <HydSystemSample_ControlMainPePipe>this.sampleAC[1];
    this.waterTankSample = <HydSystemSample_ControlWaterTank>this.sampleAC[2];
    this.waterPumpSample = <HydSystemSample_ControlWaterPump>this.sampleAC[3];
    this.waterFilterSample = <HydSystemSample_ControlWaterFilter>this.sampleAC[4];
    this.nutrientTankSample = <HydSystemSample_ControlNutrientTank>this.sampleAC[5];
    this.phTankSample = <HydSystemSample_ControlPHTank>this.sampleAC[6];
    this.nutAndPhFilterSample = <HydSystemSample_ControlNutAndPhFilter>this.sampleAC[7];
    this.controlSubPePipeSample = <HydSystemSample_ControlSubPePipe>this.sampleAC[8];

    // 아이템
    this.nutrientSolution = <ItemNutrientSolution>this.itemAC[0];
    this.controlBox_waterLevelController = <ItemWaterTankController>this.itemAC[1];
    this.controlBox_nutrientMixer = <ItemWaterTankController>this.itemAC[2];
    this.controlBox_waterPumpController = <ItemWaterPumpController>this.itemAC[3];
    this.controlMain_pePipe = <ItemWateringPipe>this.itemAC[4];
    this.waterTank_waterTank = <ItemWaterTank>this.itemAC[5];
    this.waterTankIn_waterTankFitting = <ItemWaterTankFitting>this.itemAC[6];
    this.waterTankExit_waterTankFitting = <ItemWaterTankFitting>this.itemAC[7];
    this.waterTankOut_waterTankFitting = <ItemWaterTankFitting>this.itemAC[8];
    this.waterTankOut_nipple = <ItemWateringConnect>this.itemAC[9];
    this.waterTankOut_femaleBallValve = <ItemWateringValve>this.itemAC[10];
    this.waterPump_waterPump = <ItemWaterPump>this.itemAC[11];
    this.waterPump_bushing = <ItemWateringConnect>this.itemAC[12];
    this.waterPump_maleElbowSocket = <ItemWateringConnect>this.itemAC[13];
    this.waterFilter_waterFilter = <ItemWaterFilter>this.itemAC[14];
    this.waterFilter_socketFemale = <ItemWateringConnect>this.itemAC[15];
    this.controlMain_elbow = <ItemWateringConnect>this.itemAC[16];
    this.controlMain_exactT = <ItemWateringConnect>this.itemAC[17];
    this.controlMain_unionValve = <ItemWateringValve>this.itemAC[18];
    this.nutrientTank_waterTank = <ItemWaterTank>this.itemAC[19];
    this.phTank_waterTank = <ItemWaterTank>this.itemAC[20];
    this.nutAndPhTankIn_waterTankFitting = <ItemWaterTankFitting>this.itemAC[21];
    this.nutAndPhTankExit_waterTankFitting = <ItemWaterTankFitting>this.itemAC[22];
    this.nutAndPhTankOut_waterTankFitting = <ItemWaterTankFitting>this.itemAC[23];
    this.nutAndPhTankOut_nipple = <ItemWateringConnect>this.itemAC[24];
    this.nutAndPhTankOut_femaleBallValve = <ItemWateringValve>this.itemAC[25];
    this.controlMain_valveSocket = <ItemWateringConnect>this.itemAC[26];
    this.nutAndPhFilter_wtaerFilter = <ItemWaterFilter>this.itemAC[27];
    this.nutAndPhFilter_socketFemale = <ItemWateringConnect>this.itemAC[28];
    this.nutAndPhFilter_dripBarb = <ItemDripBarb>this.itemAC[29];
    this.controlSub_PePipe = <ItemWateringPipe>this.itemAC[30];
    this.controlSub_ldElbow = <ItemDripConnect>this.itemAC[31];
    this.control_electricWire = <ItemElectricWire>this.itemAC[32];
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param nutrientSolutionSelected: boolean 양액시스템 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    nutrientSolutionSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._nutrientSolutionSelected = nutrientSolutionSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.nutrientSolutionSample.setDefaultData(
        0,
        CONST.ITEM_ID_NUTRIENT_SOLUTION,
        CONST.ITEM_NAME_NUTRIENT_SOLUTION,
        "양액시스템",
        "일반",
        "그린랩스, SMART G1",
      );
      this.controlMainPePipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "제어 주관",
        "수도관(이층관/직관), 40mm, 6m",
      );
      this.waterTankSample.setDefaultData(
        2,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "원수탱크",
        "일반, 원형, 5ton, 50mm, 40mm, 20mm",
      );
      this.waterPumpSample.setDefaultData(
        3,
        CONST.ITEM_ID_WATER_PUMP,
        CONST.ITEM_NAME_WATER_PUMP,
        "펌프",
        "일반, 2HP",
        "윌로펌프, PU-1700M, 2HP, 단상, 50mm",
      );
      this.waterFilterSample.setDefaultData(
        4,
        CONST.ITEM_ID_WATER_FILTER,
        CONST.ITEM_NAME_WATER_FILTER,
        "원수탱크 여과기",
        "일반",
        "한슬테크, SAFE, 메쉬(망), 40mm",
      );
      this.nutrientTankSample.setDefaultData(
        5,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "양액탱크",
        "일반, 원형, 1ton, 40mm, 20mm, 20mm",
      );
      this.phTankSample.setDefaultData(
        6,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "PH탱크",
        "일반, 원형, 1ton, 40mm, 20mm, 20mm",
      );
      this.nutAndPhFilterSample.setDefaultData(
        7,
        CONST.ITEM_ID_WATER_FILTER,
        CONST.ITEM_NAME_WATER_FILTER,
        "양액/PH탱크 여과기",
        "일반",
        "한슬테크, SAFE, 메쉬(망), 20mm",
      );
      this.controlSubPePipeSample.setDefaultData(
        8,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "제어 보조관",
        "연질관, 13mm, 200m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.nutrientSolution.setDefaultData(
        0,
        CONST.ITEM_ID_NUTRIENT_SOLUTION,
        CONST.ITEM_NAME_NUTRIENT_SOLUTION,
        "양액시스템",
        "일반",
        "그린랩스, SMART G1",
      );
      this.controlBox_waterLevelController.setDefaultData(
        1,
        CONST.ITEM_ID_WATER_LEVEL_CONTROLLER,
        CONST.ITEM_NAME_WATER_LEVEL_CONTROLLER,
        "컨트롤박스",
        "일반",
      );
      this.controlBox_nutrientMixer.setDefaultData(
        2,
        CONST.ITEM_ID_NUTRIENT_MIXER,
        CONST.ITEM_NAME_NUTRIENT_MIXER,
        "컨트롤박스",
        "일반",
      );
      this.controlBox_waterPumpController.setDefaultData(
        3,
        CONST.ITEM_ID_WATER_PUMP_CONTROLLER,
        CONST.ITEM_NAME_WATER_PUMP_CONTROLLER,
        "컨트롤박스",
        "일반",
      );
      this.controlMain_pePipe.setDefaultData(
        4,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "제어 주관",
        "수도관(이층관/직관), 40mm, 6m",
      );
      this.waterTank_waterTank.setDefaultData(
        5,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "원수탱크",
        "일반, 원형, 5ton, 50mm, 40mm, 20mm",
      );
      this.waterTankIn_waterTankFitting.setDefaultData(
        6,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "원수탱크 입수구",
        "일반, 50mm, -",
      );
      this.waterTankExit_waterTankFitting.setDefaultData(
        7,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "원수탱크 퇴수구",
        "일반, 20mm, -",
      );
      this.waterTankOut_waterTankFitting.setDefaultData(
        8,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "원수탱크 출수구",
        "일반, 40mm, -",
      );
      this.waterTankOut_nipple.setDefaultData(
        9,
        CONST.ITEM_ID_NIPPLE,
        CONST.ITEM_NAME_NIPPLE,
        "원수탱크",
        "PE, 단니플, 40mm, 40mm",
      );
      this.waterTankOut_femaleBallValve.setDefaultData(
        10,
        CONST.ITEM_ID_FEMALE_BALL_VALVE,
        CONST.ITEM_NAME_FEMALE_BALL_VALVE,
        "원수탱크",
        "PE, 일반, 40mm, 40mm",
      );
      this.waterPump_waterPump.setDefaultData(
        11,
        CONST.ITEM_ID_WATER_PUMP,
        CONST.ITEM_NAME_WATER_PUMP,
        "원수탱크 펌프",
        "일반, 2HP",
        "윌로펌프, PU-1700M, 2HP, 단상, 50mm",
      );
      this.waterPump_bushing.setDefaultData(
        12,
        CONST.ITEM_ID_BUSHING,
        CONST.ITEM_NAME_BUSHING,
        "원수탱크 펌프",
        "PE, 일반, 50mm, 40mm",
      );
      this.waterPump_maleElbowSocket.setDefaultData(
        13,
        CONST.ITEM_ID_MALE_ELBOW_SOCKET,
        CONST.ITEM_NAME_MALE_ELBOW_SOCKET,
        "원수탱크 펌프",
        "PE, 일반, 50mm, 50mm",
      );
      this.waterFilter_waterFilter.setDefaultData(
        14,
        CONST.ITEM_ID_WATER_FILTER,
        CONST.ITEM_NAME_WATER_FILTER,
        "원수탱크 여과기",
        "일반",
        "한슬테크, SAFE, 메쉬(망), 40mm",
      );
      this.waterFilter_socketFemale.setDefaultData(
        15,
        CONST.ITEM_ID_SOCKET_FEMALE,
        CONST.ITEM_NAME_SOCKET_FEMALE,
        "원수탱크 여과기",
        "PE, 일반, 40mm, 40mm",
      );
      this.controlMain_valveSocket.setDefaultData(
        16,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "제어 주관",
        "PE, 일반, 40mm, -",
      );
      this.controlMain_elbow.setDefaultData(
        17,
        CONST.ITEM_ID_ELBOW,
        CONST.ITEM_NAME_ELBOW,
        "제어 주관",
        "PE, 소켓, 40mm, 40mm",
      );
      this.controlMain_exactT.setDefaultData(
        18,
        CONST.ITEM_ID_EXACT_T,
        CONST.ITEM_NAME_EXACT_T,
        "제어 주관",
        "PE, 소켓, 40mm, 40mm",
      );
      this.controlMain_unionValve.setDefaultData(
        19,
        CONST.ITEM_ID_UNION_VALVE,
        CONST.ITEM_NAME_UNION_VALVE,
        "제어 주관",
        "PE, 일반, 40mm, 40mm",
      );
      this.nutrientTank_waterTank.setDefaultData(
        20,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "양액탱크",
        "일반, 원형, 1ton, 40mm, 20mm, 20mm",
      );
      this.phTank_waterTank.setDefaultData(
        21,
        CONST.ITEM_ID_WATER_TANK,
        CONST.ITEM_NAME_WATER_TANK,
        "PH탱크",
        "일반, 원형, 1ton, 40mm, 20mm, 20mm",
      );

      this.nutAndPhTankIn_waterTankFitting.setDefaultData(
        22,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "양액/PH탱크 입수구",
        "일반, 40mm, -",
      );
      this.nutAndPhTankExit_waterTankFitting.setDefaultData(
        23,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "양액/PH탱크 퇴수구",
        "일반, 20mm, -",
      );
      this.nutAndPhTankOut_waterTankFitting.setDefaultData(
        24,
        CONST.ITEM_ID_WATER_TANK_FITTING,
        CONST.ITEM_NAME_WATER_TANK_FITTING,
        "양액/PH탱크 출수구",
        "일반, 20mm, -",
      );
      this.nutAndPhTankOut_nipple.setDefaultData(
        25,
        CONST.ITEM_ID_NIPPLE,
        CONST.ITEM_NAME_NIPPLE,
        "양액/PH탱크",
        "PE, 단니플, 20mm, 20mm",
      );
      this.nutAndPhTankOut_femaleBallValve.setDefaultData(
        26,
        CONST.ITEM_ID_FEMALE_BALL_VALVE,
        CONST.ITEM_NAME_FEMALE_BALL_VALVE,
        "양액/PH탱크",
        "PE, 일반, 20mm, 20mm",
      );
      this.controlMain_valveSocket.setDefaultData(
        27,
        CONST.ITEM_ID_VALVE_SOCKET,
        CONST.ITEM_NAME_VALVE_SOCKET,
        "제어 주관",
        "PE, 일반, 40mm, -",
      );
      this.nutAndPhFilter_wtaerFilter.setDefaultData(
        28,
        CONST.ITEM_ID_WATER_FILTER,
        CONST.ITEM_NAME_WATER_FILTER,
        "양액/PH탱크 여과기",
        "일반",
        "한슬테크, SAFE, 메쉬(망), 20mm",
      );
      this.nutAndPhFilter_socketFemale.setDefaultData(
        29,
        CONST.ITEM_ID_SOCKET_FEMALE,
        CONST.ITEM_NAME_SOCKET_FEMALE,
        "양액/PH탱크 여과기",
        "PE, 일반, 40mm, 40mm",
      );
      this.nutAndPhFilter_dripBarb.setDefaultData(
        30,
        CONST.ITEM_ID_DRIP_BARB,
        CONST.ITEM_NAME_DRIP_BARB,
        "양액/PH탱크 여과기",
        "일반, 1구, 20mm, 13mm",
      );
      this.controlSub_PePipe.setDefaultData(
        31,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "제어 보조관",
        "연질관, 13mm, 200m",
      );
      this.controlSub_ldElbow.setDefaultData(
        32,
        CONST.ITEM_ID_LD_ELBOW,
        CONST.ITEM_NAME_LD_ELBOW,
        "제어 보조관",
        "일반, 90도, 13mm",
      );
      this.control_electricWire.setDefaultData(
        33,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "양액시스템",
        "CV, 6sq, 3C, 50m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////
    const hydSystemPosition: HydSystemPosition = <HydSystemPosition>this.position;

    /// //////// 선택, 가시성 ///////////

    this.nutrientSolution.selected = true;
    this.nutrientSolution.visible = true;
    this.controlBox_waterLevelController.selected = true;
    this.controlBox_waterLevelController.visible = true;
    this.controlBox_nutrientMixer.selected = true;
    this.controlBox_nutrientMixer.visible = true;
    this.controlBox_waterPumpController.selected = true;
    this.controlBox_waterPumpController.visible = true;
    this.controlMain_pePipe.selected = true;
    this.controlMain_pePipe.visible = true;
    this.waterTank_waterTank.selected = true;
    this.waterTank_waterTank.visible = true;
    this.waterTankIn_waterTankFitting.selected = true;
    this.waterTankIn_waterTankFitting.visible = true;
    this.waterTankExit_waterTankFitting.selected = true;
    this.waterTankExit_waterTankFitting.visible = true;
    this.waterTankOut_waterTankFitting.selected = true;
    this.waterTankOut_waterTankFitting.visible = true;
    this.waterTankOut_nipple.selected = true;
    this.waterTankOut_nipple.visible = true;
    this.waterTankOut_femaleBallValve.selected = true;
    this.waterTankOut_femaleBallValve.visible = true;
    this.waterPump_waterPump.selected = true;
    this.waterPump_waterPump.visible = true;
    if (this.waterPumpSample.brandInOutDiameter !== this.controlMainPePipeSample.specDiameter) {
      this.waterPump_bushing.selected = true;
      this.waterPump_bushing.visible = true;
    } else {
      this.waterPump_bushing.selected = false;
      this.waterPump_bushing.visible = false;
    }
    this.waterPump_maleElbowSocket.selected = true;
    this.waterPump_maleElbowSocket.visible = true;
    this.waterFilter_waterFilter.selected = true;
    this.waterFilter_waterFilter.visible = true;
    this.waterFilter_socketFemale.selected = true;
    this.waterFilter_socketFemale.visible = true;
    this.controlMain_elbow.selected = true;
    this.controlMain_elbow.visible = true;
    this.controlMain_exactT.selected = true;
    this.controlMain_exactT.visible = true;
    this.controlMain_unionValve.selected = true;
    this.controlMain_unionValve.visible = true;
    this.nutrientTank_waterTank.selected = true;
    this.nutrientTank_waterTank.visible = true;
    this.phTank_waterTank.selected = true;
    this.phTank_waterTank.visible = true;
    this.nutAndPhTankIn_waterTankFitting.selected = true;
    this.nutAndPhTankIn_waterTankFitting.visible = true;
    this.nutAndPhTankExit_waterTankFitting.selected = true;
    this.nutAndPhTankExit_waterTankFitting.visible = true;
    this.nutAndPhTankOut_waterTankFitting.selected = true;
    this.nutAndPhTankOut_waterTankFitting.visible = true;
    this.nutAndPhTankOut_nipple.selected = true;
    this.nutAndPhTankOut_nipple.visible = true;
    this.nutAndPhTankOut_femaleBallValve.selected = true;
    this.nutAndPhTankOut_femaleBallValve.visible = true;
    this.controlMain_valveSocket.selected = true;
    this.controlMain_valveSocket.visible = true;
    this.nutAndPhFilter_wtaerFilter.selected = true;
    this.nutAndPhFilter_wtaerFilter.visible = true;
    this.nutAndPhFilter_socketFemale.selected = true;
    this.nutAndPhFilter_socketFemale.visible = true;
    this.nutAndPhFilter_dripBarb.selected = true;
    this.nutAndPhFilter_dripBarb.visible = true;
    this.controlSub_PePipe.selected = true;
    this.controlSub_PePipe.visible = true;
    this.controlSub_ldElbow.selected = true;
    this.controlSub_ldElbow.visible = true;
    this.control_electricWire.selected = true;
    this.control_electricWire.visible = true;

    /// ////////  수량 ///////////
    this.nutrientSolution.designQuantity = this.nutrientSolutionSample.designQuantity;

    this.controlBox_waterLevelController.designQuantity = 1 * this.nutrientSolutionSample.designQuantity;

    this.controlBox_nutrientMixer.designQuantity =
      this.nutrientTankSample.designQuantity + this.phTankSample.designQuantity;

    this.controlBox_waterPumpController.designQuantity = 1 * this.nutrientSolutionSample.designQuantity;

    this.controlMain_pePipe.designQuantity = this.controlMainLength / this.controlMain_pePipe.specLength;

    this.waterTank_waterTank.designQuantity = this.waterTankSample.designQuantity;

    this.waterTankIn_waterTankFitting.designQuantity = 1 * this.waterTank_waterTank.designQuantity;

    this.waterTankExit_waterTankFitting.designQuantity = 1 * this.waterTank_waterTank.designQuantity;

    this.waterTankOut_waterTankFitting.designQuantity = 1 * this.waterTank_waterTank.designQuantity;

    this.waterTankOut_nipple.designQuantity = 1 * this.waterTank_waterTank.designQuantity;

    this.waterTankOut_femaleBallValve.designQuantity = 1 * this.waterTank_waterTank.designQuantity;

    this.waterPump_waterPump.designQuantity = this.waterPumpSample.designQuantity;

    this.waterPump_bushing.designQuantity = this.waterPump_waterPump.designQuantity * 2;

    this.waterPump_maleElbowSocket.designQuantity = this.waterPump_waterPump.designQuantity;

    this.waterFilter_waterFilter.designQuantity = this.waterFilterSample.designQuantity;

    this.waterFilter_socketFemale.designQuantity = this.waterFilter_waterFilter.designQuantity * 2;

    this.controlMain_elbow.designQuantity =
      this.waterTank_waterTank.designQuantity * 1 +
      this.waterTank_waterTank.designQuantity * 3 +
      (this.nutrientTank_waterTank.designQuantity + this.phTank_waterTank.designQuantity) * 2;

    this.controlMain_exactT.designQuantity =
      this.waterTank_waterTank.designQuantity +
      this.nutrientTank_waterTank.designQuantity +
      this.phTank_waterTank.designQuantity -
      1;

    this.controlMain_unionValve.designQuantity =
      this.waterTank_waterTank.designQuantity +
      this.nutrientTank_waterTank.designQuantity +
      this.phTank_waterTank.designQuantity;

    this.nutrientTank_waterTank.designQuantity = this.nutrientTankSample.designQuantity;

    this.phTank_waterTank.designQuantity = this.phTankSample.designQuantity;

    this.nutAndPhTankIn_waterTankFitting.designQuantity =
      this.nutrientTank_waterTank.designQuantity + this.phTank_waterTank.designQuantity;

    this.nutAndPhTankExit_waterTankFitting.designQuantity =
      this.nutrientTank_waterTank.designQuantity + this.phTank_waterTank.designQuantity;

    this.nutAndPhTankOut_waterTankFitting.designQuantity =
      this.nutrientTank_waterTank.designQuantity + this.phTank_waterTank.designQuantity;

    this.nutAndPhTankOut_nipple.designQuantity =
      this.nutrientTank_waterTank.designQuantity + this.phTank_waterTank.designQuantity;

    this.nutAndPhTankOut_femaleBallValve.designQuantity =
      this.nutrientTank_waterTank.designQuantity + this.phTank_waterTank.designQuantity;

    const nutAndPhTankIn = this.nutrientTank_waterTank.designQuantity + this.phTank_waterTank.designQuantity;
    this.controlMain_valveSocket.designQuantity =
      this.waterTank_waterTank.designQuantity +
      this.waterPump_waterPump.designQuantity +
      this.waterFilter_waterFilter.designQuantity * 2 +
      nutAndPhTankIn;

    this.nutAndPhFilter_wtaerFilter.designQuantity = this.nutAndPhFilterSample.designQuantity;

    this.nutAndPhFilter_socketFemale.designQuantity = this.nutAndPhFilter_wtaerFilter.designQuantity * 2;

    this.nutAndPhFilter_dripBarb.designQuantity = this.nutAndPhFilter_wtaerFilter.designQuantity;

    this.controlSub_PePipe.designQuantity = this.controlSubLength / this.controlSub_PePipe.specLength;

    this.controlSub_ldElbow.designQuantity =
      (this.nutrientTank_waterTank.designQuantity + this.phTank_waterTank.designQuantity) * 3;

    this.control_electricWire.designQuantity =
      (((hydSystemPosition.workroomWidth + hydSystemPosition.workroomLength) *
        2 *
        this.nutrientSolutionSample.designQuantity) /
        this.control_electricWire.specLength) *
      CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;
  }

  /**
   * 알고리즘: 샘플 수량 <- 선택(양액시스템), 양액시스템 샘플 수량
   */
  public algorithm_sampleDesignQuantity(): void {
    if (this.nutrientSolutionSample.designQuantity >= 1) {
      this.waterTankSample.designQuantity = 1 * this.nutrientSolutionSample.designQuantity;
      this.nutrientTankSample.designQuantity = 2 * this.nutrientSolutionSample.designQuantity;
      this.phTankSample.designQuantity = 1 * this.nutrientSolutionSample.designQuantity;
    } else {
      this.nutrientSolutionSample.designQuantity = 1;
      this.waterTankSample.designQuantity = 1 * this.nutrientSolutionSample.designQuantity;
      this.nutrientTankSample.designQuantity = 2 * this.nutrientSolutionSample.designQuantity;
      this.phTankSample.designQuantity = 1 * this.nutrientSolutionSample.designQuantity;
    }
  }

  /**
   * 알고리즘: 제어 주관 길이 <- 원수탱크 샘플 수량, 양액탱크 샘플 수량, PH탱크 샘플 수량, 양액시스템 샘플 수량
   */
  public algorithm_controlMainLength(): void {
    this.controlMainLength =
      (this.waterTankSample.designQuantity +
        this.nutrientTankSample.designQuantity +
        this.phTankSample.designQuantity) *
        6 +
      6 * this.nutrientSolutionSample.designQuantity;
  }

  /**
   * 알고리즘: 제어 보조관 길이 <- 양액탱크 샘플 수량, PH탱크 샘플 수량
   */
  public algorithm_controlSubLength(): void {
    this.controlSubLength = (this.nutrientTankSample.designQuantity + this.phTankSample.designQuantity) * 10;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 : [양액시스템] 양액시스템 <- 양액시스템 샘플
   */
  public algorithmSpec_nutrientSolution(): void {
    this.nutrientSolution.specs = this.nutrientSolutionSample.specs;
  }

  /**
   * 규격 알고리즘 : [컨트롤박스] 수위 조절기
   */
  public algorithmSpec_waterTank_waterLevelController(): void {}

  /**
   * 규격 알고리즘 : [컨트롤박스] 양액 교반기
   */
  public algorithmSpec_nutrientTank_nutrientMixer(): void {}

  /**
   * 규격 알고리즘 : [컨트롤박스] 펌프 조절기
   */
  public algorithmSpec_waterPumpController(): void {}

  /**
   * 규격 알고리즘 : [제어 주관] PE관 <- 제어 주관 샘플
   */
  public algorithmSpec_controlMain_pePipe(): void {
    this.controlMain_pePipe.specs = this.controlMainPePipeSample.specs;
  }

  /**
   * 규격 알고리즘 : [원수탱크] 물탱크 <- 원수탱크 샘플
   */
  public algorithmSpec_waterTank_waterTank(): void {
    this.waterTank_waterTank.specs = this.waterTankSample.specs;
  }

  /**
   * 규격 알고리즘 : [원수탱크 입수구] 물탱크 피팅
   */
  public algorithmSpec_waterTankIn_waterTankFitting(): void {}

  /**
   * 규격 알고리즘 : [원수탱크 퇴수구] 물탱크 피팅
   */
  public algorithmSpec_waterTankExit_waterTankFitting(): void {}

  /**
   * 규격 알고리즘 : [원수탱크 출수구] 물탱크 피팅 <- 제어 주관 샘플
   */
  public algorithmSpec_waterTankOut_waterTankFitting(): void {
    this.waterTankOut_waterTankFitting.specDiameter1 = this.controlMainPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [원수탱크] 니플 <- 제어 주관 샘플
   */
  public algorithmSpec_waterTankOut_nipple(): void {
    this.waterTankOut_nipple.specDiameter1 = this.controlMainPePipeSample.specDiameter;
    this.waterTankOut_nipple.specDiameter2 = this.controlMainPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [원수탱크] 암나사 볼 밸브 <- 제어 주관 샘플
   */
  public algorithmSpec_waterTankOut_femaleBallValve(): void {
    this.waterTankOut_femaleBallValve.specDiameter1 = this.controlMainPePipeSample.specDiameter;
    this.waterTankOut_femaleBallValve.specDiameter2 = this.controlMainPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [원수탱크 펌프] 펌프 <- 원수탱크 펌프 샘플
   */
  public algorithmSpec_waterPump_waterPump(): void {
    this.waterPump_waterPump.specs = this.waterPumpSample.specs;
    this.waterPump_waterPump.brands = this.waterPumpSample.brands;
  }

  /**
   * 규격 알고리즘 : [원수탱크 펌프] 부싱 <- 제어 주관 샘플, 원수탱크 펌프 샘플
   */
  public algorithmSpec_waterPump_bushing(): void {
    if (this.waterPumpSample.brandInOutDiameter !== this.controlMainPePipeSample.specDiameter) {
      this.waterPump_bushing.specDiameter1 = this.waterPumpSample.brandInOutDiameter;
      this.waterPump_bushing.specDiameter2 = this.controlMainPePipeSample.specDiameter;
    }
  }

  /**
   * 규격 알고리즘 : [원수탱크 펌프] 숫나사 엘보 소켓 <- 원수탱크 펌프 샘플
   */
  public algorithmSpec_waterPump_maleElbowSocket(): void {
    this.waterPump_maleElbowSocket.specDiameter1 = this.waterPumpSample.brandInOutDiameter;
    this.waterPump_maleElbowSocket.specDiameter2 = this.waterPumpSample.brandInOutDiameter;
  }

  /**
   * 규격 알고리즘 : [원수탱크 여과기] 여과기 <- 원수탱크 여과기 샘플
   */
  public algorithmSpec_waterFilter_waterFilter(): void {
    this.waterFilter_waterFilter.specs = this.waterFilterSample.specs;
    this.waterFilter_waterFilter.brands = this.waterFilterSample.brands;
  }

  /**
   * 규격 알고리즘 : [원수탱크 여과기] 소켓(암나사) <- 원수탱크 여과기 샘플
   */
  public algorithmSpec_waterFilter_socketFemale(): void {
    this.waterFilter_socketFemale.specDiameter1 = this.waterFilterSample.brandInOutDiameter;
    this.waterFilter_socketFemale.specDiameter2 = this.waterFilterSample.brandInOutDiameter;
  }

  /**
   * 규격 알고리즘 : [제어 주관] 엘보 <- 제어 주관 샘플
   */
  public algorithmSpec_controlMain_elbow(): void {
    this.controlMain_elbow.specDiameter1 = this.controlMainPePipeSample.specDiameter;
    this.controlMain_elbow.specDiameter2 = this.controlMainPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [제어 주관] 정티 <- 제어 주관 샘플
   */
  public algorithmSpec_controlMain_exactT(): void {
    this.controlMain_exactT.specDiameter1 = this.controlMainPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [제어 주관] 유니온 밸브 <- 제어 주관 샘플
   */
  public algorithmSpec_controlMain_unionValve(): void {
    this.controlMain_unionValve.specDiameter1 = this.controlMainPePipeSample.specDiameter;
    this.controlMain_unionValve.specDiameter2 = this.controlMainPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [양액탱크] 물탱크 <- 양액탱크 샘플
   */
  public algorithmSpec_nutrientTank_waterTank(): void {
    this.nutrientTank_waterTank.specs = this.nutrientTankSample.specs;
  }

  /**
   * 규격 알고리즘 : [PH탱크] 물탱크 <- PH탱크 샘플
   */
  public algorithmSpec_phTank_waterTank(): void {
    this.phTank_waterTank.specs = this.phTankSample.specs;
  }

  /**
   * 규격 알고리즘 : [양액/PH탱크 입수구] 물탱크 피팅 <- 제어 주관 샘플
   */
  public algorithmSpec_nutAndPhTankIn_waterTankFitting(): void {
    this.waterTankIn_waterTankFitting.specDiameter1 = this.controlMainPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [양액/PH탱크 퇴수구] 물탱크 피팅
   */
  public algorithmSpec_nutAndPhTankExit_waterTankFitting(): void {}

  /**
   * 규격 알고리즘 : [양액/PH탱크 출수구] 물탱크 피팅
   */
  public algorithmSpec_nutAndPhTankOut_waterTankFitting(): void {}

  /**
   * 규격 알고리즘 : [양액/PH탱크] 니플 <- 양액탱크 샘플 (출수구)
   */
  public algorithmSpec_nutAndPhTankOut_nipple(): void {
    this.nutAndPhTankOut_nipple.specDiameter1 = this.nutrientTankSample.specOutDiameter;
    this.nutAndPhTankOut_nipple.specDiameter2 = this.nutrientTankSample.specOutDiameter;
  }

  /**
   * 규격 알고리즘 : [양액/PH탱크] 암나사 볼 밸브 <- 양액탱크 샘플 (출수구)
   */
  public algorithmSpec_nutAndPhTankOut_femaleBallValve(): void {
    this.nutAndPhTankOut_femaleBallValve.specDiameter1 = this.nutrientTankSample.specOutDiameter;
    this.nutAndPhTankOut_femaleBallValve.specDiameter2 = this.nutrientTankSample.specOutDiameter;
  }

  /**
   * 규격 알고리즘 : [제어 주관] 밸브 소켓 <- 제어 주관 샘플
   */
  public algorithmSpec_controlMain_valveSocket(): void {
    this.controlMain_valveSocket.specDiameter1 = this.controlMainPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [양액/PH탱크 여과기] 여과기 <- 양액/PH탱크 여과기 샘플
   */
  public algorithmSpec_nutAndPhFilter_wtaerFilter(): void {
    this.nutAndPhFilter_wtaerFilter.specs = this.nutAndPhFilterSample.specs;
    this.nutAndPhFilter_wtaerFilter.brands = this.nutAndPhFilterSample.brands;
  }

  /**
   * 규격 알고리즘 : [양액/PH탱크 여과기] 소켓(암나사) <- 양액/PH탱크 여과기 샘플
   */
  public algorithmSpec_nutAndPhFilter_socketFemale(): void {
    this.nutAndPhFilter_socketFemale.specDiameter1 = this.nutAndPhFilterSample.brandInOutDiameter;
    this.nutAndPhFilter_socketFemale.specDiameter2 = this.nutAndPhFilterSample.brandInOutDiameter;
  }

  /**
   * 규격 알고리즘 : [양액/PH탱크 여과기] 점적 미늘 <- 양액/PH탱크 여과기 샘플
   */
  public algorithmSpec_nutAndPhFilter_dripBarb(): void {
    this.nutAndPhFilter_dripBarb.specDiameter1 = this.nutAndPhFilterSample.brandInOutDiameter;
  }

  /**
   * 규격 알고리즘 : [제어 보조관] PE관 <- 제어 보조관 샘플
   */
  public algorithmSpec_nutAndPhSub_PePipe(): void {
    this.controlSub_PePipe.specs = this.controlSubPePipeSample.specs;
  }

  /**
   * 규격 알고리즘 : [양액/Ph탱크~양액시스템] 엘디 엘보 <- 제어 보조관 샘플
   */
  public algorithmSpec_nutAndPhSub_ldElbow(): void {
    this.controlSub_ldElbow.specDiameter = this.controlSubPePipeSample.specDiameter;
  }

  /**
   * 규격 알고리즘 : [양액시스템] 전선
   */
  public algorithmSpec_control_electricWire(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
