import { jsonObject } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ItemUtil } from "vhows-design/src/object/design/item/ItemUtil";

/**
 * 스크린
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-01
 */
@jsonObject
export class ItemScreen extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [재질], (폭), (길이), [끈형태], (폭미싱횟수)
  public _specMaterial: string = undefined; // 재질
  public _specWidth: number = undefined; // 폭
  public _specLength: number = undefined; // 길이
  public _specStringType: string = undefined; // 끈형태
  public _specWidthSewing: number = undefined; // 폭미싱횟수

  // 하우스 설계 > 상표 : [회사, 모델, 차광율, 보온율, 폭미싱간격]
  public _brandModelName: string = undefined; // 모델
  public _brandShadeRatio: string = undefined; // 차광율
  public _brandWarmingRatio: string = undefined; // 보온율
  public _brandWidthSewingInterval: number = undefined; // 폭미싱간격

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 재질
   */
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭
   */
  public get specWidth(): number {
    return this._specWidth;
  }

  //
  public set specWidth(value: number) {
    // 일반화된 간격 적용 - 0.1m 간격(소수점 둘째자리 올림)
    this._specWidth = CommonUtil.roundUp(value, 1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.algorithm_widthByBrand();
      this.algorithm_widthSewingNumber();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭 레이블(값+단위기호)
   */
  public get specWidthLabel(): string {
    return this.specWidth + CONST.UNIT_METER;
  }

  //
  public set specWidthLabel(value: string) {
    this.specWidth = parseFloat(value);
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }

  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 1m 간격(소수점 첫째자리 올림)
    this._specLength = Math.ceil(value);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_METER;
  }

  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  /**
   * 끈형태
   */
  public get specStringType(): string {
    return this._specStringType;
  }

  //
  public set specStringType(value: string) {
    this._specStringType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findStringTypeUnitPriceModel();
    }
  }

  /**
   * 폭미싱횟수
   */
  public get specWidthSewing(): number {
    return this._specWidthSewing;
  }

  //
  public set specWidthSewing(value: number) {
    this._specWidthSewing = Math.max(value, 0);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭미싱횟수 레이블(값+단위기호)
   */
  public get specWidthSewingLabel(): string {
    return this.specWidthSewing + CONST.UNIT_TH;
  }

  //
  public set specWidthSewingLabel(value: string) {
    this.specWidthSewing = parseFloat(value);
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  public get brandsList(): string {
    return this._brandsList;
  }

  //
  public set brandsList(value: string) {
    if (this._brandsList === value) return;

    this._brandsList = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeEachBrandByBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }

    if (this.isInit === false) {
      // 모델 찾기
      this.findUnitPriceModel();
      this.findStringTypeUnitPriceModel();
      // 알고리즘
    }
  }

  // @override
  public get brandCompany(): string {
    return this._brandCompany;
  }

  //
  public set brandCompany(value: string) {
    this._brandCompany = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.algorithm_widthByBrand();
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 모델
   */
  public get brandModelName(): string {
    return this._brandModelName;
  }

  //
  public set brandModelName(value: string) {
    this._brandModelName = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 차광율
   */
  public get brandShadeRatio(): string {
    return this._brandShadeRatio;
  }

  //
  public set brandShadeRatio(value: string) {
    this._brandShadeRatio = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 보온율
   */
  public get brandWarmingRatio(): string {
    return this._brandWarmingRatio;
  }

  //
  public set brandWarmingRatio(value: string) {
    this._brandWarmingRatio = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 폭미싱간격
   */
  public get brandWidthSewingInterval(): number {
    return this._brandWidthSewingInterval;
  }

  //
  public set brandWidthSewingInterval(value: number) {
    this._brandWidthSewingInterval = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.algorithm_widthSewingNumber();
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 폭미싱간격 레이블(값+단위기호)
   */
  public get brandWidthSewingIntervalLabel(): string {
    return ItemUtil.convertSpecNum2Str(this.brandWidthSewingInterval, 0, CONST.UNIT_METER_INTERVAL);
  }

  //
  public set brandWidthSewingIntervalLabel(value: string) {
    this.brandWidthSewingInterval = ItemUtil.convertSpecStr2Num(value, 0);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setDefaultVariable(): void {
    super.setDefaultVariable();

    // 모델 찾기
    this.findStringTypeUnitPriceModel();
  }

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.screenCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmItem(): void {
    super.algorithmItem();

    this.algorithm_widthSewingNumber();
  }

  /**
   * 샘플로 새로운 설계 아이템 만들기
   * @param part 아이템이 사용될 파트
   * @param sample 규격과 상표를 참고할 샘플
   * @return 새 아이템
   */
  public static getNewDesignItemBySample(part: Part, sample: ItemScreen): ItemScreen {
    let item: ItemScreen;
    item = new ItemScreen();
    item.setAssociation(part.design, part.struct, part.work, part.level, part.position, part);
    item.setDefaultData(NaN, sample.productId, sample.label, sample.purpose, sample.specs, sample.brands);
    item.setReferenceVariable();
    item.setDefaultVariable();
    item.setDefaultModel();
    item.algorithmItem();

    item.selected = true;
    item.visible = true;

    return item;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 끈형태 단가
    const stringTypeUnitSupply: number = this.stringTypeUnitPriceModel.optionUnitSupply;
    // 끈형태 매출가 계산식
    const stringTypeSellingSupply: number = stringTypeUnitSupply * this.specLength;

    // 폭미싱 단가
    const widthSewingUnitSupply: number = this.brandCompanyModel.optionUnitSupply1;
    // 폭미싱 매출가 계산식
    const widthSewingSellingSupply: number = widthSewingUnitSupply * this.specWidthSewing * this.specLength;

    // 매출가 계산식
    let sellingSupply: number =
      defaultUnitSupply * this.specWidth * this.specLength + stringTypeSellingSupply + widthSewingSellingSupply;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specMaterial = specsArray[0];
    this.specWidthLabel = specsArray[1];
    this.specLengthLabel = specsArray[2];
    this.specStringType = specsArray[3];
    this.specWidthSewingLabel = specsArray[4];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specMaterial = specsArray[0];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specMaterial;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList +
      CONST.DELIMITER_SPEC +
      this.specWidthLabel +
      CONST.DELIMITER_SPEC +
      this.specLengthLabel +
      CONST.DELIMITER_SPEC +
      this._specStringType +
      CONST.DELIMITER_SPEC +
      this.specWidthSewingLabel;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    const brandsArray: string[] = this._brands.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandShadeRatio = brandsArray[2];
    this.brandWarmingRatio = brandsArray[3];
    this.brandWidthSewingIntervalLabel = brandsArray[4];
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    const brandsArray: string[] = this._brandsList.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandShadeRatio = brandsArray[2];
    this.brandWarmingRatio = brandsArray[3];
    this.brandWidthSewingIntervalLabel = brandsArray[4];
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList =
      this._brandCompany +
      CONST.DELIMITER_BRAND +
      this._brandModelName +
      CONST.DELIMITER_BRAND +
      this._brandShadeRatio +
      CONST.DELIMITER_BRAND +
      this._brandWarmingRatio +
      CONST.DELIMITER_BRAND +
      this.brandWidthSewingIntervalLabel;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }

  //----------------------------------
  // 하우스 설계 > 특정 규격 및 상표
  //----------------------------------

  /**
   * 알고리즘: 폭미싱횟수 <- 폭, 폭미싱간격
   */
  protected algorithm_widthSewingNumber(): void {
    if (!isNaN(this.brandWidthSewingInterval)) {
      if (this.brandWidthSewingInterval <= 0) {
        this.specWidthSewing = 0;
      } else {
        this.specWidthSewing = Math.ceil(this.specWidth / this.brandWidthSewingInterval) - 1;
      }
    }
  }

  /**
   * 알고리즘: 폭 <- 폭, 상표
   */
  protected algorithm_widthByBrand(): void {
    // 하드코딩
    if (this.brandCompany === "에이스그린") {
      this.isSpecError = false;
      let width: number = this.specWidth;
      // 에이스그린의 폭 범위
      if (width <= 4) {
        width = 4;
      } else if (width <= 4.3) {
        width = 4.3;
      } else if (width <= 4.7) {
        width = 4.7;
      } else {
        // 오류 출력
        this.isSpecError = true;
      }
      this.specWidth = width;
    }
  }

  //----------------------------------
  // 품목 모델
  //----------------------------------

  /**
   * 끈형태 단가 모델 찾기
   */
  protected findStringTypeUnitPriceModel(): void {
    if (this.productCompanyModel != null) {
      this.stringTypeUnitPriceModel = this.productCompanyModel.getOptionUnitPriceModel(
        this.specStringType,
        this.brandsList,
      );
    }
  }
}
