import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { RoofSampleIL_StraightPipe } from "vhows-design/src/object/design/frame/roof/RoofSampleIL_StraightPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject({
  knownTypes: [RoofSampleIL_StraightPipe],
})
export class RoofPartIL_Straight extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public straightPipeSample: RoofSampleIL_StraightPipe; // 가로대 파이프 샘플

  // 아이템
  public straight_pipe: ItemPipe;
  public straight_connectionPin: ItemConnector;
  public straightAndRafter_steelStringGrabber: ItemHolder;
  public straightAndRafter_steelSheetGrabber: ItemHolder;
  public straightAndRafter_crossSteelSheetGrabber: ItemHolder;
  public straightAndRafter_lineSheetGrabber: ItemHolder;
  public straightAndRafter_saddleHolder: ItemHolder;
  public straight_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _straightLength: number; // 가로대 길이
  @jsonMember(Number)
  public _straightLineNumber: number; // 가로대 줄수

  @jsonMember(Boolean)
  public _enhancedGrabberSelected: boolean; // 내재해 조리개 선택
  @jsonMember(String)
  public _enhancedGrabberType: string; // 내재해 조리개 형태
  @jsonMember(Number)
  public _enhancedGrabberLineNumber: number; // 내재해 조리개 줄수

  /**
   * 가로대 길이
   */
  public get straightLength(): number {
    return this._straightLength;
  }

  //
  public set straightLength(value: number) {
    this._straightLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 가로대 줄수
   */
  public get straightLineNumber(): number {
    return this._straightLineNumber;
  }

  //
  public set straightLineNumber(value: number) {
    this._straightLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 내재해 조리개 선택
   */
  public get enhancedGrabberSelected(): boolean {
    return this._enhancedGrabberSelected;
  }

  //
  public set enhancedGrabberSelected(value: boolean) {
    this._enhancedGrabberSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 내재해 조리개 형태
   */
  public get enhancedGrabberType(): string {
    return this._enhancedGrabberType;
  }

  //
  public set enhancedGrabberType(value: string) {
    this._enhancedGrabberType = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 내재해 조리개 줄수
   */
  public get enhancedGrabberLineNumber(): number {
    return this._enhancedGrabberLineNumber;
  }

  //
  public set enhancedGrabberLineNumber(value: number) {
    this._enhancedGrabberLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.straightPipeSample = new RoofSampleIL_StraightPipe();

    this.sampleAC = [this.straightPipeSample];

    // 아이템
    this.straight_pipe = new ItemPipe();
    this.straight_connectionPin = new ItemConnector();
    this.straightAndRafter_steelStringGrabber = new ItemHolder();
    this.straightAndRafter_steelSheetGrabber = new ItemHolder();
    this.straightAndRafter_crossSteelSheetGrabber = new ItemHolder();
    this.straightAndRafter_lineSheetGrabber = new ItemHolder();
    this.straightAndRafter_saddleHolder = new ItemHolder();
    this.straight_screw = new ItemScrew();

    this.itemAC = [
      this.straight_pipe,
      this.straight_connectionPin,
      this.straightAndRafter_steelStringGrabber,
      this.straightAndRafter_steelSheetGrabber,
      this.straightAndRafter_crossSteelSheetGrabber,
      this.straightAndRafter_lineSheetGrabber,
      this.straightAndRafter_saddleHolder,
      this.straight_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.straightPipeSample = <RoofSampleIL_StraightPipe>this.sampleAC[0];

    // 아이템
    this.straight_pipe = <ItemPipe>this.itemAC[0];
    this.straight_connectionPin = <ItemConnector>this.itemAC[1];
    this.straightAndRafter_steelStringGrabber = <ItemHolder>this.itemAC[2];
    this.straightAndRafter_steelSheetGrabber = <ItemHolder>this.itemAC[3];
    this.straightAndRafter_crossSteelSheetGrabber = <ItemHolder>this.itemAC[4];
    this.straightAndRafter_lineSheetGrabber = <ItemHolder>this.itemAC[5];
    this.straightAndRafter_saddleHolder = <ItemHolder>this.itemAC[6];
    this.straight_screw = <ItemScrew>this.itemAC[7];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param straightLineNumber: number 가로대 줄수
   * @param enhancedGrabberSelected: boolean 내재해 조리개 선택
   * @param enhancedGrabberType: string 내재해 조리개 형태
   * @param enhancedGrabberLineNumber: number 내재해 조리개 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    straightLineNumber: number = 0,
    enhancedGrabberSelected: boolean = false,
    enhancedGrabberType: string = "",
    enhancedGrabberLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._straightLineNumber = straightLineNumber;
    this._enhancedGrabberSelected = enhancedGrabberSelected;
    this._enhancedGrabberType = enhancedGrabberType;
    this._enhancedGrabberLineNumber = enhancedGrabberLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.straightPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.straight_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straight_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightAndRafter_steelStringGrabber.setDefaultData(
        2,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "가로대 + 서까래",
        "원형, 일반, 25mm, 32mm",
      );
      this.straightAndRafter_steelSheetGrabber.setDefaultData(
        3,
        CONST.ITEM_ID_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        "가로대 + 서까래",
        "원형, 일반, 32mm, 25mm",
      );
      this.straightAndRafter_crossSteelSheetGrabber.setDefaultData(
        4,
        CONST.ITEM_ID_CROSS_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_CROSS_STEEL_SHEET_GRABBER,
        "가로대 + 서까래",
        "원형, 일반, 32mm, 25mm",
      );
      this.straightAndRafter_lineSheetGrabber.setDefaultData(
        5,
        CONST.ITEM_ID_LINE_SHEET_GRABBER,
        CONST.ITEM_NAME_LINE_SHEET_GRABBER,
        "가로대 + 서까래",
        "원형, 일반, 25mm, 32mm",
      );
      this.straightAndRafter_saddleHolder.setDefaultData(
        6,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "가로대(끝) + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.straight_screw.setDefaultData(
        7,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "가로대",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 8 && (<Item>this.itemAC[2]).productId === CONST.ITEM_ID_SCREW) {
      // 나사 하나로 합치기
      let screwQuantity: number;
      screwQuantity =
        (<ItemScrew>this.itemAC[2]).getSelectedQuantity() + (<ItemScrew>this.itemAC[7]).getSelectedQuantity();
      (<ItemScrew>this.itemAC[7]).selected = screwQuantity > 0;
      (<ItemScrew>this.itemAC[7]).visible = screwQuantity > 0;
      (<ItemScrew>this.itemAC[7]).purpose = "가로대";
      (<ItemScrew>this.itemAC[7]).designQuantity = screwQuantity;
      this.itemAC.splice(2, 1);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 7 && (<Item>this.itemAC[5]).productId === CONST.ITEM_ID_SADDLE_HOLDER) {
      // [가로대 + 서까래] 선판 조리개
      const straightAndRafter_lineSheetGrabber: ItemHolder = new ItemHolder();
      straightAndRafter_lineSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightAndRafter_lineSheetGrabber.setDefaultData(
        5,
        CONST.ITEM_ID_LINE_SHEET_GRABBER,
        CONST.ITEM_NAME_LINE_SHEET_GRABBER,
        "가로대 + 서까래",
        "원형, 일반, 25mm, 32mm",
      );
      this.itemAC.splice(5, 0, straightAndRafter_lineSheetGrabber);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_straightLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.straight_pipe.checkSelected(true);
    this.straight_connectionPin.checkSelected(true);
    this.straightAndRafter_steelStringGrabber.selected = true;
    this.straightAndRafter_steelStringGrabber.visible = true;
    if (this.enhancedGrabberSelected === true) {
      if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET) {
        this.straightAndRafter_steelSheetGrabber.selected = true;
        this.straightAndRafter_steelSheetGrabber.visible = true;
        this.straightAndRafter_crossSteelSheetGrabber.selected = false;
        this.straightAndRafter_crossSteelSheetGrabber.visible = false;
        this.straightAndRafter_lineSheetGrabber.selected = false;
        this.straightAndRafter_lineSheetGrabber.visible = false;
      } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET) {
        this.straightAndRafter_steelSheetGrabber.selected = false;
        this.straightAndRafter_steelSheetGrabber.visible = false;
        this.straightAndRafter_crossSteelSheetGrabber.selected = true;
        this.straightAndRafter_crossSteelSheetGrabber.visible = true;
        this.straightAndRafter_lineSheetGrabber.selected = false;
        this.straightAndRafter_lineSheetGrabber.visible = false;
      } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET) {
        this.straightAndRafter_steelSheetGrabber.selected = false;
        this.straightAndRafter_steelSheetGrabber.visible = false;
        this.straightAndRafter_crossSteelSheetGrabber.selected = false;
        this.straightAndRafter_crossSteelSheetGrabber.visible = false;
        this.straightAndRafter_lineSheetGrabber.selected = true;
        this.straightAndRafter_lineSheetGrabber.visible = true;
      }
    } else {
      this.straightAndRafter_steelSheetGrabber.selected = false;
      this.straightAndRafter_steelSheetGrabber.visible = false;
      this.straightAndRafter_crossSteelSheetGrabber.selected = false;
      this.straightAndRafter_crossSteelSheetGrabber.visible = false;
      this.straightAndRafter_lineSheetGrabber.selected = false;
      this.straightAndRafter_lineSheetGrabber.visible = false;
    }
    this.straightAndRafter_saddleHolder.selected = true;
    this.straightAndRafter_saddleHolder.visible = true;
    this.straight_screw.selected = true;
    this.straight_screw.visible = true;

    /// //////// 수량 ///////////

    this.straight_pipe.designQuantity =
      (this.straight_pipe.getConnectedPipeLength(
        this.straightLength,
        this.straightLineNumber * this.basic.buildingNumber,
      ) /
        this.straight_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    if (this.straightLength > this.straight_pipe.specLength) {
      this.straight_connectionPin.designQuantity =
        this.straight_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.straight_connectionPin.designQuantity = 0;
    }

    if (this.enhancedGrabberSelected === true) {
      if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET) {
        this.straightAndRafter_steelSheetGrabber.designQuantity =
          (<RoofPositionIL>this.position).rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumber *
          CONST.NUM_EXTRA_RATE_STEEL_SHEET_GRABBER;
        this.straightAndRafter_steelStringGrabber.designQuantity =
          (<RoofPositionIL>this.position).rafterPart.rafterQuantity *
          (this.straightLineNumber - this.enhancedGrabberLineNumber) *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
      } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET) {
        this.straightAndRafter_crossSteelSheetGrabber.designQuantity =
          (<RoofPositionIL>this.position).rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumber *
          CONST.NUM_EXTRA_RATE_CROSS_STEEL_SHEET_GRABBER;
        this.straightAndRafter_steelStringGrabber.designQuantity =
          (<RoofPositionIL>this.position).rafterPart.rafterQuantity *
          this.straightLineNumber *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
      } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET) {
        this.straightAndRafter_lineSheetGrabber.designQuantity =
          (<RoofPositionIL>this.position).rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumber *
          CONST.NUM_EXTRA_RATE_LINE_SHEET_GRABBER;
        this.straightAndRafter_steelStringGrabber.designQuantity =
          (<RoofPositionIL>this.position).rafterPart.rafterQuantity *
          (this.straightLineNumber - this.enhancedGrabberLineNumber) *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
      }
    } else {
      this.straightAndRafter_steelStringGrabber.designQuantity =
        (<RoofPositionIL>this.position).rafterPart.rafterQuantity *
        this.straightLineNumber *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
    }

    this.straightAndRafter_saddleHolder.designQuantity =
      this.straightLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.straight_screw.designQuantity =
      ((this.straight_connectionPin.getSelectedQuantity() * 2 +
        this.straightAndRafter_saddleHolder.getSelectedQuantity() * 3) /
        this.straight_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 가로대 길이 <- 길이(기본정보), 앞면/뒷면 처마 길이(지붕 골조/1중)
   */
  public algorithm_straightLength(): void {
    if (this.level.index === 0) {
      this.straightLength =
        this.basicLevel.length +
        this.struct.roofWorkIL.level1.roofPosition.frontEaveLength +
        this.struct.roofWorkIL.level1.roofPosition.backEaveLength +
        this.design.preference.connectedPipeExtraLength;
    } else {
      this.straightLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [가로대] 파이프 <- 가로대 파이프 샘플
   */
  public algorithmSpec_straight_pipe(): void {
    this.straight_pipe.specs = this.straightPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [가로대] 연결핀 <- 가로대 파이프 샘플
   */
  public algorithmSpec_straight_connectionPin(): void {
    this.straight_connectionPin.specPipeType = this.straightPipeSample.specPipeType;
    this.straight_connectionPin.specCrossSize1 = this.straightPipeSample.specCrossSize;
    this.straight_connectionPin.specCrossSize2 = this.straightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [가로대 + 서까래] 강선 조리개 <- 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightAndRafter_steelStringGrabber(): void {
    this.straightAndRafter_steelStringGrabber.specPipeType = this.straightPipeSample.specPipeType;
    this.straightAndRafter_steelStringGrabber.specCrossSize1 = this.straightPipeSample.specCrossSize;
    this.straightAndRafter_steelStringGrabber.specCrossSize2 = (<RoofPositionIL>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [가로대 + 서까래] 강판 조리개 <- 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightAndRafter_steelSheetGrabber(): void {
    this.straightAndRafter_steelSheetGrabber.specPipeType = (<RoofPositionIL>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightAndRafter_steelSheetGrabber.specCrossSize1 = (<RoofPositionIL>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightAndRafter_steelSheetGrabber.specCrossSize2 = this.straightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [가로대 + 서까래] 십자 강판 조리개 <- 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightAndRafter_crossSteelSheetGrabber(): void {
    this.straightAndRafter_crossSteelSheetGrabber.specPipeType = (<RoofPositionIL>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightAndRafter_crossSteelSheetGrabber.specCrossSize1 = (<RoofPositionIL>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightAndRafter_crossSteelSheetGrabber.specCrossSize2 = this.straightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [가로대 + 서까래] 선판 조리개 <- 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightAndRafter_lineSheetGrabber(): void {
    this.straightAndRafter_lineSheetGrabber.specPipeType = (<RoofPositionIL>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightAndRafter_lineSheetGrabber.specCrossSize1 = (<RoofPositionIL>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightAndRafter_lineSheetGrabber.specCrossSize2 = this.straightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [가로대(끝) + 서까래] 새들 고정구 <- 가로대 파이프 샘플
   */
  public algorithmSpec_straightAndRafter_saddleHolder(): void {
    this.straightAndRafter_saddleHolder.specPipeType = this.straightPipeSample.specPipeType;
    this.straightAndRafter_saddleHolder.specCrossSize1 = this.straightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [가로대] 나사 <- 공통 샘플
   */
  public algorithmSpec_straight_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
