import { jsonObject } from "typedjson";

import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { FixingPartIL_CondensationPad } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_CondensationPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject
export class FixingSampleIL_CondensationPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const condensationPadPart: FixingPartIL_CondensationPad = <FixingPartIL_CondensationPad>this.part;
    condensationPadPart.algorithmSpec_condensationPad_condensationPad();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
