import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemRoller } from "vhows-design/src/object/design/item/list/ItemRoller";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemDoorMade } from "vhows-design/src/object/design/item/list/ItemDoorMade";
import { ItemHingedDoor } from "vhows-design/src/object/design/item/list/ItemHingedDoor";
import { ItemCustomMaterial } from "vhows-design/src/object/design/item/list/ItemCustomMaterial";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { EndpieceSampleVL_SlidingDoor } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_SlidingDoor";
import { EndpieceSampleVL_HingedDoor } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_HingedDoor";
import { EndpieceSampleVL_CustomDoor } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_CustomDoor";
import { EndpieceSampleVL_CSectionSteel } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_CSectionSteel";
import { EndpieceSampleVL_DoorFramePipe } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceSampleVL_DoorFramePipe";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject({
  knownTypes: [
    EndpieceSampleVL_SlidingDoor,
    EndpieceSampleVL_HingedDoor,
    EndpieceSampleVL_CustomDoor,
    EndpieceSampleVL_CSectionSteel,
    EndpieceSampleVL_DoorFramePipe,
  ],
})
export class EndpiecePartVL_Gate extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public slidingDoorSample: EndpieceSampleVL_SlidingDoor; // 미닫이문 샘플
  public hingedDoorSample: EndpieceSampleVL_HingedDoor; // 여닫이문 샘플
  public customDoorSample: EndpieceSampleVL_CustomDoor; // 임의문짝 샘플
  public cSectionSteelSample: EndpieceSampleVL_CSectionSteel; // C형강 샘플
  public doorFramePipeSample: EndpieceSampleVL_DoorFramePipe; // 문틀 파이프 샘플

  // 아이템
  public gate_doorMade: ItemDoorMade;
  public gate_hingedDoor: ItemHingedDoor;
  public gate_customDoor: ItemCustomMaterial;
  public gate_cSectionSteel: ItemPipe;
  public gate_cSectionSteelRoller: ItemRoller;
  public gate_hinge: ItemFrameOther;
  public gate_knob: ItemFrameOther;
  public gate_normalPadSpring: ItemPadSpring;
  public gateFrame_pipe: ItemPipe;
  public gate_welding: ItemOtherExpense;
  public gate_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _gateWidth: number; // 출입문 폭
  @jsonMember(Number)
  public _gateHeight: number; // 출입문 높이
  @jsonMember(String)
  public _makingMethod: string; // 제작 방법
  @jsonMember(Number)
  public _doorWidth: number; // 문짝 폭
  @jsonMember(Number)
  public _doorHeight: number; // 문짝 높이
  @jsonMember(Number)
  public _doorQuantity: number; // 문짝 개수
  @jsonMember(Number)
  public _framePipeLength: number; // 문틀 파이프 길이

  /**
   * 출입문 폭
   */
  public get gateWidth(): number {
    return this._gateWidth;
  }

  //
  public set gateWidth(value: number) {
    this._gateWidth = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_framePipeLength();
    this.algorithmPart();
  }

  /**
   * 출입문 높이
   */
  public get gateHeight(): number {
    return this._gateHeight;
  }

  //
  public set gateHeight(value: number) {
    this._gateHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.doorFramePipeSample.algorithmSpec();
    this.algorithm_framePipeLength();
    this.algorithmPart();
  }

  /**
   * 제작 방법
   */
  public get makingMethod(): string {
    return this._makingMethod;
  }

  //
  public set makingMethod(value: string) {
    this._makingMethod = value;

    // 알고리즘
    this.algorithm_doorQuantity();
    this.algorithmPart();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.algorithm_makingMethodByPosition();
      this.algorithm_doorWidthDoorHeightByPosition();
      // this.algorithm_doorQuantityByPosition();
    }
  }

  /**
   * 문짝 폭
   */
  public get doorWidth(): number {
    return this._doorWidth;
  }

  //
  public set doorWidth(value: number) {
    this._doorWidth = CommonUtil.fixFloat(value);

    // 알고리즘
    this.customDoorSample.algorithmSpec();
    this.cSectionSteelSample.algorithmSpec();
    this.algorithm_gateWidth();
    this.algorithm_gateHeight();
    this.algorithmPart();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.algorithm_doorWidthDoorHeightByPosition();
    }

    /// //////// 외부 ///////////

    // // 칸막이
    // if (this.level.index === 0) {
    //   if (this.position.label === CONST.LB_POSITION_FRONT) {
    //     this.struct.partitionWork.level1.trunkPosition.gatePart.algorithm_doorWidth();
    //   }
    // }
  }

  /**
   * 문짝 높이
   */
  public get doorHeight(): number {
    return this._doorHeight;
  }

  //
  public set doorHeight(value: number) {
    this._doorHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.customDoorSample.algorithmSpec();
    this.algorithm_gateWidth();
    this.algorithm_gateHeight();
    this.algorithmPart();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.algorithm_doorWidthDoorHeightByPosition();
    }

    /// //////// 외부 ///////////

    // // 칸막이
    // if (this.level.index === 0) {
    //   if (this.position.label === CONST.LB_POSITION_FRONT) {
    //     this.struct.partitionWork.level1.trunkPosition.gatePart.algorithm_doorHeight();
    //   }
    // }
  }

  /**
   * 문짝 개수
   */
  public get doorQuantity(): number {
    return this._doorQuantity;
  }

  //
  public set doorQuantity(value: number) {
    this._doorQuantity = value;

    // 알고리즘
    this.customDoorSample.algorithmQuantity();
    this.cSectionSteelSample.algorithmSpec();
    this.algorithm_gateWidth();
    this.algorithm_gateHeight();
    this.algorithmPart();

    // 다른 위치
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   this.algorithm_doorQuantityByPosition();
    // }
    if (this.level.index === 0) {
      this.algorithm_doorQuantityByLevel();
    }
  }

  /**
   * 문틀 길이
   */
  public get framePipeLength(): number {
    return this._framePipeLength;
  }

  public set framePipeLength(value: number) {
    this._framePipeLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.slidingDoorSample = new EndpieceSampleVL_SlidingDoor();
    this.hingedDoorSample = new EndpieceSampleVL_HingedDoor();
    this.customDoorSample = new EndpieceSampleVL_CustomDoor();
    this.cSectionSteelSample = new EndpieceSampleVL_CSectionSteel();
    this.doorFramePipeSample = new EndpieceSampleVL_DoorFramePipe();

    this.sampleAC = [
      this.slidingDoorSample,
      this.hingedDoorSample,
      this.customDoorSample,
      this.cSectionSteelSample,
      this.doorFramePipeSample,
    ];

    // 아이템
    this.gate_doorMade = new ItemDoorMade();
    this.gate_hingedDoor = new ItemHingedDoor();
    this.gate_customDoor = new ItemCustomMaterial();
    this.gate_cSectionSteel = new ItemPipe();
    this.gate_cSectionSteelRoller = new ItemRoller();
    this.gate_hinge = new ItemFrameOther();
    this.gate_knob = new ItemFrameOther();
    this.gate_normalPadSpring = new ItemPadSpring();
    this.gateFrame_pipe = new ItemPipe();
    this.gate_welding = new ItemOtherExpense();
    this.gate_screw = new ItemScrew();

    this.itemAC = [
      this.gate_doorMade,
      this.gate_hingedDoor,
      this.gate_customDoor,
      this.gate_cSectionSteel,
      this.gate_cSectionSteelRoller,
      this.gate_hinge,
      this.gate_knob,
      this.gate_normalPadSpring,
      this.gateFrame_pipe,
      this.gate_welding,
      this.gate_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.slidingDoorSample = <EndpieceSampleVL_SlidingDoor>this.sampleAC[0];
    this.hingedDoorSample = <EndpieceSampleVL_HingedDoor>this.sampleAC[1];
    this.customDoorSample = <EndpieceSampleVL_CustomDoor>this.sampleAC[2];
    this.cSectionSteelSample = <EndpieceSampleVL_CSectionSteel>this.sampleAC[3];
    this.doorFramePipeSample = <EndpieceSampleVL_DoorFramePipe>this.sampleAC[4];

    // 아이템
    this.gate_doorMade = <ItemDoorMade>this.itemAC[0];
    this.gate_hingedDoor = <ItemHingedDoor>this.itemAC[1];
    this.gate_customDoor = <ItemCustomMaterial>this.itemAC[2];
    this.gate_cSectionSteel = <ItemPipe>this.itemAC[3];
    this.gate_cSectionSteelRoller = <ItemRoller>this.itemAC[4];
    this.gate_hinge = <ItemFrameOther>this.itemAC[5];
    this.gate_knob = <ItemFrameOther>this.itemAC[6];
    this.gate_normalPadSpring = <ItemPadSpring>this.itemAC[7];
    this.gateFrame_pipe = <ItemPipe>this.itemAC[8];
    this.gate_welding = <ItemOtherExpense>this.itemAC[9];
    this.gate_screw = <ItemScrew>this.itemAC[10];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param makingMethod: string 제작 방법
   * @param doorWidth: number 문짝 폭
   * @param doorHeight: number 문짝 높이
   * @param doorQuantity: number 문짝 개수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    makingMethod: string = "",
    doorWidth: number = 0,
    doorHeight: number = 0,
    doorQuantity: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._makingMethod = makingMethod;
    this._doorWidth = doorWidth;
    this._doorHeight = doorHeight;
    this._doorQuantity = doorQuantity;
    this._gateWidth = doorWidth * doorQuantity;
    this._gateHeight = doorHeight;
    this._framePipeLength = 0;

    // 샘플
    if (this.level.index >= 0) {
      this.slidingDoorSample.setDefaultData(
        0,
        CONST.ITEM_ID_DOOR_MADE,
        CONST.ITEM_NAME_DOOR_MADE,
        "출입문",
        "일반, 1300mm, 2400mm, 60mm",
      );
      this.hingedDoorSample.setDefaultData(
        1,
        CONST.ITEM_ID_HINGED_DOOR,
        CONST.ITEM_NAME_HINGED_DOOR,
        "출입문",
        "외문, 1300mm, 2000mm",
      );
      this.customDoorSample.setDefaultData(2, CONST.ITEM_ID_CUSTOM_CONTROLLER, "(문짝)", "출입문", "1300mm*2400mm");
      this.customDoorSample._designQuantity = 1;
      this.cSectionSteelSample.setDefaultData(
        3,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "출입문",
        "각형, 일반, 60×30mm, 1.8T, 5m, -",
      );
      this.doorFramePipeSample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "출입문틀",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.gate_doorMade.setDefaultData(
        0,
        CONST.ITEM_ID_DOOR_MADE,
        CONST.ITEM_NAME_DOOR_MADE,
        "출입문",
        "일반, 1300mm, 2400mm, 60mm",
      );
      this.gate_hingedDoor.setDefaultData(
        1,
        CONST.ITEM_ID_HINGED_DOOR,
        CONST.ITEM_NAME_HINGED_DOOR,
        "출입문",
        "외문, 1300mm, 2000mm",
      );
      this.gate_customDoor.setDefaultData(2, CONST.ITEM_ID_CUSTOM_CONTROLLER, "(문짝)", "출입문", "1300mm*2400mm");
      this.gate_cSectionSteel.setDefaultData(
        3,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "출입문",
        "각형, 일반, 60×30mm, 1.8T, 5m, -",
      );
      this.gate_cSectionSteelRoller.setDefaultData(
        4,
        CONST.ITEM_ID_C_SECTION_STEEL_ROLLER,
        CONST.ITEM_NAME_C_SECTION_STEEL_ROLLER,
        "출입문",
        "일반, 60mm",
      );
      this.gate_hinge.setDefaultData(5, CONST.ITEM_ID_HINGE, CONST.ITEM_NAME_HINGE, "출입문", "평형");
      this.gate_knob.setDefaultData(6, CONST.ITEM_ID_KNOB, CONST.ITEM_NAME_KNOB, "출입문", "ㄷ자");
      this.gate_normalPadSpring.setDefaultData(
        7,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "출입문",
        "일반, 2m",
      );
      this.gateFrame_pipe.setDefaultData(
        8,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "출입문틀",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.gate_welding.setDefaultData(9, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "출입문", "일반");
      this.gate_screw.setDefaultData(
        10,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "출입문",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_doorWidth();
    this.algorithm_doorHeight();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const endpiecePosition: EndpiecePositionVL = <EndpiecePositionVL>this.position;

    /// //////// 선택, 가시성 ///////////

    if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SLIDING) {
      if (this.makingMethod === CONST.LB_GATE_MAKING_METHOD_MADE) {
        this.gate_doorMade.selected = true;
        this.gate_doorMade.visible = true;
        this.gate_hingedDoor.selected = false;
        this.gate_hingedDoor.visible = false;
        this.gate_customDoor.selected = false;
        this.gate_customDoor.visible = false;
        this.gate_cSectionSteel.selected = true;
        this.gate_cSectionSteel.visible = true;
        this.gate_cSectionSteelRoller.selected = true;
        this.gate_cSectionSteelRoller.visible = true;
        this.gate_hinge.selected = false;
        this.gate_hinge.visible = false;
        this.gate_knob.selected = true;
        this.gate_knob.visible = true;
        this.gate_normalPadSpring.selected = true;
        this.gate_normalPadSpring.visible = true;
      } else {
        this.gate_doorMade.selected = false;
        this.gate_doorMade.visible = false;
        this.gate_hingedDoor.selected = false;
        this.gate_hingedDoor.visible = false;
        this.gate_customDoor.selected = true;
        this.gate_customDoor.visible = true;
        this.gate_cSectionSteel.selected = true;
        this.gate_cSectionSteel.visible = true;
        this.gate_cSectionSteelRoller.selected = true;
        this.gate_cSectionSteelRoller.visible = true;
        this.gate_hinge.selected = false;
        this.gate_hinge.visible = false;
        this.gate_knob.selected = true;
        this.gate_knob.visible = true;
        this.gate_normalPadSpring.selected = true;
        this.gate_normalPadSpring.visible = true;
      }
      this.gateFrame_pipe.selected = false;
      this.gateFrame_pipe.visible = true;
      this.gate_welding.selected = false;
      this.gate_welding.visible = true;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_HINGED) {
      if (this.makingMethod === CONST.LB_GATE_MAKING_METHOD_MADE) {
        this.gate_doorMade.selected = false;
        this.gate_doorMade.visible = false;
        this.gate_hingedDoor.selected = true;
        this.gate_hingedDoor.visible = true;
        this.gate_customDoor.selected = false;
        this.gate_customDoor.visible = false;
        this.gate_cSectionSteel.selected = false;
        this.gate_cSectionSteel.visible = false;
        this.gate_cSectionSteelRoller.selected = false;
        this.gate_cSectionSteelRoller.visible = false;
        this.gate_hinge.selected = true;
        this.gate_hinge.visible = true;
        this.gate_knob.selected = true;
        this.gate_knob.visible = true;
        this.gate_normalPadSpring.selected = true;
        this.gate_normalPadSpring.visible = true;
      } else {
        this.gate_doorMade.selected = false;
        this.gate_doorMade.visible = false;
        this.gate_hingedDoor.selected = false;
        this.gate_hingedDoor.visible = false;
        this.gate_customDoor.selected = true;
        this.gate_customDoor.visible = true;
        this.gate_cSectionSteel.selected = false;
        this.gate_cSectionSteel.visible = false;
        this.gate_cSectionSteelRoller.selected = false;
        this.gate_cSectionSteelRoller.visible = false;
        this.gate_hinge.selected = true;
        this.gate_hinge.visible = true;
        this.gate_knob.selected = true;
        this.gate_knob.visible = true;
        this.gate_normalPadSpring.selected = true;
        this.gate_normalPadSpring.visible = true;
      }
      this.gateFrame_pipe.selected = false;
      this.gateFrame_pipe.visible = true;
      this.gate_welding.selected = false;
      this.gate_welding.visible = true;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_FRAMED) {
      this.gate_doorMade.selected = false;
      this.gate_doorMade.visible = false;
      this.gate_hingedDoor.selected = false;
      this.gate_hingedDoor.visible = false;
      this.gate_customDoor.selected = false;
      this.gate_customDoor.visible = false;
      this.gate_cSectionSteel.selected = false;
      this.gate_cSectionSteel.visible = false;
      this.gate_cSectionSteelRoller.selected = false;
      this.gate_cSectionSteelRoller.visible = false;
      this.gate_hinge.selected = false;
      this.gate_hinge.visible = false;
      this.gate_knob.selected = false;
      this.gate_knob.visible = false;
      this.gate_normalPadSpring.selected = false;
      this.gate_normalPadSpring.visible = false;
      this.gateFrame_pipe.selected = false;
      this.gateFrame_pipe.visible = true;
      this.gate_welding.selected = false;
      this.gate_welding.visible = true;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SASH_FRAME) {
      this.gate_doorMade.selected = false;
      this.gate_doorMade.visible = false;
      this.gate_hingedDoor.selected = false;
      this.gate_hingedDoor.visible = false;
      this.gate_customDoor.selected = false;
      this.gate_customDoor.visible = false;
      this.gate_cSectionSteel.selected = false;
      this.gate_cSectionSteel.visible = false;
      this.gate_cSectionSteelRoller.selected = false;
      this.gate_cSectionSteelRoller.visible = false;
      this.gate_hinge.selected = false;
      this.gate_hinge.visible = false;
      this.gate_knob.selected = false;
      this.gate_knob.visible = false;
      this.gate_normalPadSpring.selected = false;
      this.gate_normalPadSpring.visible = false;
      this.gateFrame_pipe.selected = true;
      this.gateFrame_pipe.visible = true;
      this.gate_welding.selected = true;
      this.gate_welding.visible = true;
    } else {
      this.gate_doorMade.selected = false;
      this.gate_doorMade.visible = false;
      this.gate_hingedDoor.selected = false;
      this.gate_hingedDoor.visible = false;
      this.gate_customDoor.selected = false;
      this.gate_customDoor.visible = false;
      this.gate_cSectionSteel.selected = false;
      this.gate_cSectionSteel.visible = false;
      this.gate_cSectionSteelRoller.selected = false;
      this.gate_cSectionSteelRoller.visible = false;
      this.gate_hinge.selected = false;
      this.gate_hinge.visible = false;
      this.gate_knob.selected = false;
      this.gate_knob.visible = false;
      this.gate_normalPadSpring.selected = false;
      this.gate_normalPadSpring.visible = false;
      this.gateFrame_pipe.selected = false;
      this.gateFrame_pipe.visible = false;
      this.gate_welding.selected = false;
      this.gate_welding.visible = false;
    }
    this.gate_screw.selected = true;
    this.gate_screw.visible = true;

    /// //////// 수량 ///////////

    this.gate_doorMade.designQuantity = this.doorQuantity * endpiecePosition.gateBuildingNumber;

    this.gate_hingedDoor.designQuantity = endpiecePosition.gateBuildingNumber;

    this.gate_customDoor.designQuantity = this.customDoorSample.designQuantity;

    const minSlideWidth: number = CommonUtil.convertMM2M(this.gateWidth) * 2 - 0.1 * this.doorQuantity;
    let gateWidthRatio: number = CommonUtil.roundDown(this.cSectionSteelSample.specLength / minSlideWidth);
    if (gateWidthRatio === 0) {
      gateWidthRatio = 1;
    }
    this.gate_cSectionSteel.designQuantity =
      (CommonUtil.roundUpX(minSlideWidth, this.cSectionSteelSample.specLength / gateWidthRatio) *
        endpiecePosition.gateBuildingNumber) /
      this.cSectionSteelSample.specLength;

    this.gate_cSectionSteelRoller.designQuantity = 2 * this.doorQuantity * endpiecePosition.gateBuildingNumber;

    this.gate_hinge.designQuantity =
      Math.ceil(CommonUtil.convertMM2M(this.gateHeight)) * this.doorQuantity * endpiecePosition.gateBuildingNumber;

    this.gate_knob.designQuantity = 2 * this.doorQuantity * endpiecePosition.gateBuildingNumber;

    const gate_normalPadSpring_designQuantity: number =
      ((CommonUtil.convertMM2M(this.gateWidth) * 4 + CommonUtil.convertMM2M(this.gateHeight) * 2) /
        this.gate_normalPadSpring.specLength) *
      endpiecePosition.gateBuildingNumber;
    this.gate_normalPadSpring.designQuantity = gate_normalPadSpring_designQuantity * CONST.NUM_EXTRA_RATE_PAD_SPRING;

    this.gateFrame_pipe.designQuantity = this.framePipeLength / this.gateFrame_pipe.specLength;

    this.gate_welding.designQuantity =
      (this.framePipeLength * endpiecePosition.gateBuildingNumber) / CONST.NUM_INVERVAL_WELDING;

    this.gate_screw.designQuantity =
      ((this.gate_cSectionSteel.getSelectedQuantity() * this.gate_cSectionSteel.specLength) /
        this.gate_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 제작 방법 <- 출입문 형태(위치)
   */
  public algorithm_makingMethod(): void {
    const endpiecePosition: EndpiecePositionVL = this.position as EndpiecePositionVL;

    if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SLIDING) {
      this.makingMethod = CONST.LB_GATE_MAKING_METHOD_MADE;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_HINGED) {
      this.makingMethod = CONST.LB_GATE_MAKING_METHOD_MADE;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_FRAMED) {
      this.makingMethod = CONST.LB_GATE_MAKING_METHOD_ORDER;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SASH_FRAME) {
      this.makingMethod = CONST.LB_GATE_MAKING_METHOD_ORDER;
    }
  }

  /**
   * 알고리즘: 출입문 폭 <- 출입문 형태(위치), 문짝 개수, 미닫이문 샘플, 여닫이문 샘플
   */
  public algorithm_gateWidth(): void {
    const endpiecePosition: EndpiecePositionVL = this.position as EndpiecePositionVL;

    if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SLIDING) {
      if (this.makingMethod === CONST.LB_GATE_MAKING_METHOD_MADE) {
        this.gateWidth = this.slidingDoorSample.specWidth * this.doorQuantity;
      } else {
        this.gateWidth = this.doorWidth * this.doorQuantity;
      }
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_HINGED) {
      if (this.makingMethod === CONST.LB_GATE_MAKING_METHOD_MADE) {
        this.gateWidth = this.hingedDoorSample.specWidth * this.doorQuantity;
      } else {
        this.gateWidth = this.doorWidth * this.doorQuantity;
      }
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_FRAMED) {
      this.gateWidth = this.doorWidth * this.doorQuantity;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SASH_FRAME) {
      this.gateWidth = this.doorWidth * this.doorQuantity;
    } else {
      this.gateWidth = 0;
    }
  }

  /**
   * 알고리즘: 출입문 높이 <- 출입문 형태(위치), 미닫이문 샘플, 여닫이문 샘플
   */
  public algorithm_gateHeight(): void {
    const endpiecePosition: EndpiecePositionVL = this.position as EndpiecePositionVL;

    if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SLIDING) {
      if (this.makingMethod === CONST.LB_GATE_MAKING_METHOD_MADE) {
        this.gateHeight = this.slidingDoorSample.specLength;
      } else {
        this.gateHeight = this.doorHeight;
      }
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_HINGED) {
      if (this.makingMethod === CONST.LB_GATE_MAKING_METHOD_MADE) {
        this.gateHeight = this.hingedDoorSample.specLength;
      } else {
        this.gateHeight = this.doorHeight;
      }
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_FRAMED) {
      this.gateHeight = this.doorHeight;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SASH_FRAME) {
      this.gateHeight = this.doorHeight;
    } else {
      this.gateHeight = 0;
    }
  }

  /**
   * 알고리즘: 문짝 폭 <- 출입문 형태(위치), 하우스 폭
   */
  public algorithm_doorWidth(): void {
    const endpiecePosition: EndpiecePositionVL = this.position as EndpiecePositionVL;

    // 하드코딩
    if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SLIDING) {
      this.doorWidth = 1300;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_HINGED) {
      this.doorWidth = 1300;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_FRAMED) {
      this.doorWidth = 1300;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SASH_FRAME) {
      this.doorWidth = CommonUtil.convertM2MM(this.basicLevel.width);
    } else {
      this.doorWidth = 0;
    }
  }

  /**
   * 알고리즘: 문짝 높이 <- 출입문 형태(위치), 하우스 측고
   */
  public algorithm_doorHeight(): void {
    const endpiecePosition: EndpiecePositionVL = this.position as EndpiecePositionVL;

    // 하드코딩
    if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SLIDING) {
      this.doorHeight = 2400;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_HINGED) {
      this.doorHeight = 2400;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_FRAMED) {
      this.doorHeight = 2400;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SASH_FRAME) {
      this.doorHeight = CommonUtil.convertM2MM(this.basicLevel.heightShoulder);
    } else {
      this.doorHeight = 0;
    }
  }

  /**
   * 알고리즘: 문짝 개수 <- 출입문 형태(위치), 제작 방법, 여닫이문 샘플
   */
  public algorithm_doorQuantity(): void {
    const endpiecePosition: EndpiecePositionVL = this.position as EndpiecePositionVL;

    if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_SLIDING) {
      this.doorQuantity = 2;
    } else if (endpiecePosition.gateType === CONST.LB_GATE_TYPE_HINGED) {
      if (this.makingMethod === CONST.LB_GATE_MAKING_METHOD_MADE) {
        if (this.doorQuantity !== this.hingedDoorSample.specDoorQuantity) {
          this.doorQuantity = this.hingedDoorSample.specDoorQuantity;
        }
      } else {
        this.doorQuantity = 2;
      }
    } else if (
      endpiecePosition.gateType === CONST.LB_GATE_TYPE_FRAMED ||
      endpiecePosition.gateType === CONST.LB_GATE_TYPE_SASH_FRAME
    ) {
      this.doorQuantity = 1;
    } else {
      this.doorQuantity = 0;
    }
  }

  /**
   * 알고리즘: 문틀 파이프 길이 <- 문틀 폭, 문틀 높이
   */
  public algorithm_framePipeLength(): void {
    const totalWidth: number = this.gateWidth * 2;
    const totalHeight: number = this.gateHeight * 2;
    this.framePipeLength =
      CommonUtil.convertMM2M(totalWidth + totalHeight) * (this.position as EndpiecePositionVL).gateBuildingNumber;
  }

  /**
   * 알고리즘: 제작 방법 <- 제작 방법, 출입문 형태(위치)
   */
  public algorithm_makingMethodByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if ((this.position as EndpiecePositionVL).gateType === (this.level as EndpieceLevelVL).backPosition.gateType) {
        (<EndpieceLevelVL>this.level).backPosition.gatePart.makingMethod = this.makingMethod;
      }
    }
  }

  /**
   * 알고리즘: 문짝 폭, 문짝 높이 <- 문짝 폭, 문짝 높이, 제작 방법, 출입문 형태(위치)
   */
  public algorithm_doorWidthDoorHeightByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (
        (this.position as EndpiecePositionVL).gateType === (this.level as EndpieceLevelVL).backPosition.gateType &&
        this.makingMethod === (this.level as EndpieceLevelVL).backPosition.gatePart.makingMethod
      ) {
        (<EndpieceLevelVL>this.level).backPosition.gatePart.doorWidth = this.doorWidth;
        (<EndpieceLevelVL>this.level).backPosition.gatePart.doorHeight = this.doorHeight;
      }
    }
  }

  /**
   * 알고리즘: 문짝 개수 <- 문짝 개수, 제작 방법, 출입문 형태(위치)
   */
  public algorithm_doorQuantityByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (
        (this.position as EndpiecePositionVL).gateType === (this.level as EndpieceLevelVL).backPosition.gateType &&
        this.makingMethod === (this.level as EndpieceLevelVL).backPosition.gatePart.makingMethod
      ) {
        (<EndpieceLevelVL>this.level).backPosition.gatePart.doorQuantity = this.doorQuantity;
      }
    }
  }

  /**
   * 알고리즘: 문짝 개수 <- 문짝 개수, 출입문 형태(위치)
   */
  public algorithm_doorQuantityByLevel(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.basic.levelNumber; l++) {
        for (let j: number = 0; j < this.work.levelAC[l].positionAC.length; j++) {
          if (this.position.label === this.work.levelAC[l].positionAC[j].label) {
            // @ts-ignore
            const endpiecePosition: EndpiecePositionVL = (<EndpieceLevelVL>this.work.levelAC[l]).positionAC[j];
            endpiecePosition.gatePart.doorQuantity = this.doorQuantity;
          }
        }
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [출입문] 문짝(기성품) <- 미닫이문 샘플
   */
  public algorithmSpec_gate_doorMade(): void {
    this.gate_doorMade.specs = this.slidingDoorSample.specs;
  }

  /**
   * 규격 알고리즘: [출입문] 여닫이 문 <- 여닫이문 샘플
   */
  public algorithmSpec_gate_hingedDoor(): void {
    this.gate_hingedDoor.specs = this.hingedDoorSample.specs;
  }

  /**
   * 규격 알고리즘: [출입문] (임의 문짝) <- 임의 문짝 샘플
   */
  public algorithmSpec_gate_customDoor(): void {
    this.gate_customDoor.specs = this.customDoorSample.specs;
    this.gate_customDoor.customUnitSupply = this.customDoorSample.customUnitSupply;
  }

  /**
   * 규격 알고리즘: [출입문] C형강 <- C형강 샘플
   */
  public algorithmSpec_gate_cSectionSteel(): void {
    this.gate_cSectionSteel.specs = this.cSectionSteelSample.specs;
  }

  /**
   * 규격 알고리즘: [출입문] C형강 롤러 <- C형강 샘플(미닫이 출입문 파트)
   */
  public algorithmSpec_gate_cSectionSteelRoller(): void {
    this.gate_cSectionSteelRoller.specSize = this.cSectionSteelSample.specRollerSize;
  }

  /**
   * 규격 알고리즘: [출입문] 경첩 <- 공통 샘플
   */
  public algorithmSpec_gate_hinge(): void {}

  /**
   * 규격 알고리즘: [출입문] 손잡이 <- 공통 샘플
   */
  public algorithmSpec_gate_knob(): void {}

  /**
   * 규격 알고리즘: [출입문] 일반 패드 스프링 <- 일반 패드 스프링 샘플(피복 고정)
   */
  public algorithmSpec_sliding_normalPadSpring(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.gate_normalPadSpring.specMaterial = (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.normalPadPart.normalPadSpringSample.specMaterial;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.gate_normalPadSpring.specMaterial = (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.normalPadPart.normalPadSpringSample.specMaterial;
    }
  }

  /**
   * 규격 알고리즘: [출입문틀] 파이프 <- 출입문틀 파이프 샘플
   */
  public algorithmSpec_gateFrame_pipe(): void {
    this.gateFrame_pipe.specs = this.doorFramePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [출입문] 용접 <- 공통 샘플
   */
  public algorithmSpec_gate_welding(): void {}

  /**
   * 규격 알고리즘: [출입문] 나사 <- 공통 샘플
   */
  public algorithmSpec_gate_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
