import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemPipeBendingExpense } from "vhows-design/src/object/design/item/list/ItemPipeBendingExpense";
import { ItemSconce } from "vhows-design/src/object/design/item/list/ItemSconce";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { WingWorkIL } from "vhows-design/src/object/design/frame/wing/WingWorkIL";
import { WingSampleIL_WingRafterPipe } from "vhows-design/src/object/design/frame/wing/WingSampleIL_WingRafterPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject({
  knownTypes: [WingSampleIL_WingRafterPipe],
})
export class WingPartIL_WingRafter extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public wingRafterPipeSample: WingSampleIL_WingRafterPipe; // 방풍벽 서까래 파이프 샘플

  // 아이템
  public wingRafter_pipe: ItemPipe;
  public wingRafter_pipeBendingExpense: ItemPipeBendingExpense;
  public wingRafterAndRoofRafter_connectionPin: ItemConnector;
  public wingRafterFirstAndBeam_outerWallClamp: ItemHolder;
  public wingRafterSecondAndBeamCylinder_secondLevelPairSconce: ItemSconce;
  public wingRafterSecondAndBeamPrism_secondLevelSoleSconce: ItemSconce;
  public wingRafterSecondAndWingStraight_innerTHolder: ItemSconce;
  public wingRafter_screw: ItemScrew;
  public wingRafter_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wingRafterInterval: number; // 방풍벽 서까래 간격
  @jsonMember(Number)
  public _wingRafterLength: number; // 방풍벽 서까래 길이
  @jsonMember(Number)
  public _wingRafterQuantity: number = 0; // 방풍벽 서까래 수량

  /**
   * 방풍벽 서까래 간격
   */
  public get wingRafterInterval(): number {
    return this._wingRafterInterval;
  }

  //
  public set wingRafterInterval(value: number) {
    this._wingRafterInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_wingRafterQuantity();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevelIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index]
    )).wingPosition.fixingSkirtClipPart.algorithm_clipInterval();
  }

  /**
   * 방풍벽 서까래 길이
   */
  public get wingRafterLength(): number {
    return this._wingRafterLength;
  }

  //
  public set wingRafterLength(value: number) {
    this._wingRafterLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.wingRafterPipeSample.algorithmSpec();
    this.algorithmPart();

    // // 중수
    // if (this.level.index === 0) {
    //   this.algorithm_wingRafterLengthByLevel1();
    // }

    /// //////// 외부 ///////////

    // 지붕 골조
    (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.algorithm_edgeRafterExtensionLength();

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 방풍벽 서까래 수량
   */
  public get wingRafterQuantity(): number {
    return this._wingRafterQuantity;
  }

  //
  public set wingRafterQuantity(value: number) {
    this._wingRafterQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<WingPositionIL>this.position).wingStraightPart.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.wingRafterPipeSample = new WingSampleIL_WingRafterPipe();

    this.sampleAC = [this.wingRafterPipeSample];

    // 아이템
    this.wingRafter_pipe = new ItemPipe();
    this.wingRafter_pipeBendingExpense = new ItemPipeBendingExpense();
    this.wingRafterAndRoofRafter_connectionPin = new ItemConnector();
    this.wingRafterFirstAndBeam_outerWallClamp = new ItemHolder();
    this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce = new ItemSconce();
    this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce = new ItemSconce();
    this.wingRafterSecondAndWingStraight_innerTHolder = new ItemSconce();
    this.wingRafter_screw = new ItemScrew();
    this.wingRafter_welding = new ItemOtherExpense();

    this.itemAC = [
      this.wingRafter_pipe,
      this.wingRafter_pipeBendingExpense,
      this.wingRafterAndRoofRafter_connectionPin,
      this.wingRafterFirstAndBeam_outerWallClamp,
      this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce,
      this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce,
      this.wingRafterSecondAndWingStraight_innerTHolder,
      this.wingRafter_screw,
      this.wingRafter_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.wingRafterPipeSample = <WingSampleIL_WingRafterPipe>this.sampleAC[0];

    // 아이템
    this.wingRafter_pipe = <ItemPipe>this.itemAC[0];
    this.wingRafter_pipeBendingExpense = <ItemPipeBendingExpense>this.itemAC[1];
    this.wingRafterAndRoofRafter_connectionPin = <ItemConnector>this.itemAC[2];
    this.wingRafterFirstAndBeam_outerWallClamp = <ItemHolder>this.itemAC[3];
    this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce = <ItemSconce>this.itemAC[4];
    this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce = <ItemSconce>this.itemAC[5];
    this.wingRafterSecondAndWingStraight_innerTHolder = <ItemSconce>this.itemAC[6];
    this.wingRafter_screw = <ItemScrew>this.itemAC[7];
    this.wingRafter_welding = <ItemOtherExpense>this.itemAC[8];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param wingRafterInterval: number 방풍벽 서까래 간격
   * @param wingRafterLength: number 방풍벽 서까래 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    wingRafterInterval: number = 0,
    wingRafterLength: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._wingRafterInterval = wingRafterInterval;
    this._wingRafterLength = wingRafterLength;

    // 샘플
    if (this.level.index === 0) {
      this.wingRafterPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "방풍벽 서까래",
        "원형, 일반, 31.8mm, 1.5T, 4m, 벤딩",
      );
    } else {
      this.wingRafterPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "방풍벽 서까래",
        "원형, 일반, 25.4mm, 1.5T, 3.5m, 벤딩",
      );
    }

    // 아이템
    if (this.level.index === 0) {
      this.wingRafter_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "방풍벽 서까래",
        "원형, 일반, 31.8mm, 1.5T, 4m, 벤딩",
      );
      this.wingRafter_pipeBendingExpense.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "방풍벽 서까래",
        "원형, 31.8mm",
      );
    } else {
      this.wingRafter_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "방풍벽 서까래",
        "원형, 일반, 25.4mm, 1.5T, 3.5m, 벤딩",
      );
      this.wingRafter_pipeBendingExpense.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "방풍벽 서까래",
        "원형, 25.4mm",
      );
    }
    this.wingRafterAndRoofRafter_connectionPin.setDefaultData(
      2,
      CONST.ITEM_ID_CONNECTION_PIN,
      CONST.ITEM_NAME_CONNECTION_PIN,
      "방풍벽 서까래 + 지붕 서까래",
      "원형, 일반, 32mm, 32mm",
    );
    this.wingRafterFirstAndBeam_outerWallClamp.setDefaultData(
      3,
      CONST.ITEM_ID_OUTER_WALL_CLAMP,
      CONST.ITEM_NAME_OUTER_WALL_CLAMP,
      "방풍벽 서까래(1중) + 보",
      "원형, 일반, 48mm, 32mm",
    );
    this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.setDefaultData(
      4,
      CONST.ITEM_ID_SECOND_LEVEL_PAIR_SCONCE,
      CONST.ITEM_NAME_SECOND_LEVEL_PAIR_SCONCE,
      "방풍벽 서까래(2,3중) + 기둥",
      "원형, 일반, 48mm, 25mm",
    );
    this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.setDefaultData(
      5,
      CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE,
      CONST.ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE,
      "방풍벽 서까래(2,3중) + 기둥",
      "각형, 일반, 60×60mm, 25mm",
    );
    this.wingRafterSecondAndWingStraight_innerTHolder.setDefaultData(
      6,
      CONST.ITEM_ID_INNER_T_HOLDER,
      CONST.ITEM_NAME_INNER_T_HOLDER,
      "방풍벽 서까래(2,3중) + 방풍벽 가로대",
      "원형, 일반, 25mm, 25mm",
    );
    this.wingRafter_screw.setDefaultData(
      7,
      CONST.ITEM_ID_SCREW,
      CONST.ITEM_NAME_SCREW,
      "방풍벽 서까래",
      "십자, 일반, 8/9*13mm, 1000개",
    );
    this.wingRafter_welding.setDefaultData(8, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "방풍벽 서까래", "일반");
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 5 && (<Item>this.itemAC[1]).productId === CONST.ITEM_ID_CROSS_CLAMP) {
      // 십자 클램프 제거
      const wingRafterFirstAndBeam_crossClamp: Item[] = this.itemAC.splice(1, 1);
      // 외벽 클램프 추가
      const wingRafterFirstAndBeam_outerWallClamp: ItemHolder = new ItemHolder();
      wingRafterFirstAndBeam_outerWallClamp.setAssociation(design, struct, work, level, position, this);
      wingRafterFirstAndBeam_outerWallClamp.setDefaultData(
        1,
        CONST.ITEM_ID_OUTER_WALL_CLAMP,
        CONST.ITEM_NAME_OUTER_WALL_CLAMP,
        wingRafterFirstAndBeam_crossClamp[0].purpose,
        wingRafterFirstAndBeam_crossClamp[0].specs,
      );
      wingRafterFirstAndBeam_outerWallClamp.designQuantity = wingRafterFirstAndBeam_crossClamp[0].designQuantity;
      wingRafterFirstAndBeam_outerWallClamp.selected = wingRafterFirstAndBeam_crossClamp[0].selected;
      wingRafterFirstAndBeam_outerWallClamp.visible = wingRafterFirstAndBeam_crossClamp[0].visible;
      this.itemAC.splice(1, 0, wingRafterFirstAndBeam_outerWallClamp);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 5 && (<Item>this.itemAC[2]).productId === CONST.ITEM_ID_SCREW) {
      // 나사
      (<Item>this.itemAC[4]).purpose = "방풍벽 서까래";
      const screw_designQuantity: number =
        (<Item>this.itemAC[2]).getSelectedQuantity() + (<Item>this.itemAC[4]).getSelectedQuantity();
      (<Item>this.itemAC[4]).selected = true;
      (<Item>this.itemAC[4]).visible = true;
      (<Item>this.itemAC[4]).designQuantity = screw_designQuantity;
      // 중간 나사 제거
      this.itemAC.splice(2, 1);

      // [방풍벽 서까래 + 지붕 서까래] 연결핀
      const wingRafterAndRoofRafter_connectionPin: ItemConnector = new ItemConnector();
      wingRafterAndRoofRafter_connectionPin.setAssociation(design, struct, work, level, position, this);
      wingRafterAndRoofRafter_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "방풍벽 서까래 + 지붕 서까래",
        "원형, 일반, 32mm, 32mm",
      );
      wingRafterAndRoofRafter_connectionPin.selected = false;
      wingRafterAndRoofRafter_connectionPin.visible = false;
      this.itemAC.splice(1, 0, wingRafterAndRoofRafter_connectionPin);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 5 && (<Item>this.itemAC[4]).productId === CONST.ITEM_ID_SCREW) {
      // [방풍벽 서까래(2,3중) + 기둥] 이중 외꽂이
      const wingRafterSecondAndBeamPrism_secondLevelSoleSconce: ItemSconce = new ItemSconce();
      wingRafterSecondAndBeamPrism_secondLevelSoleSconce.setAssociation(design, struct, work, level, position, this);
      wingRafterSecondAndBeamPrism_secondLevelSoleSconce.setDefaultData(
        4,
        CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE,
        "방풍벽 서까래(2,3중) + 기둥",
        "각형, 일반, 60×60mm, 25mm",
      );
      wingRafterSecondAndBeamPrism_secondLevelSoleSconce.selected = false;
      wingRafterSecondAndBeamPrism_secondLevelSoleSconce.visible = false;
      this.itemAC.splice(4, 0, wingRafterSecondAndBeamPrism_secondLevelSoleSconce);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 6 && (<ItemConnector>this.itemAC[1]).productId === CONST.ITEM_ID_CONNECTION_PIN) {
      // 기존 규격
      const specsArray: any[] = (<ItemPipe>this.itemAC[0]).specs.split(CONST.DELIMITER_SPEC);
      // [방풍벽 서까래] 파이프 벤딩비
      const wingRafter_pipeBendingExpense: ItemPipeBendingExpense = new ItemPipeBendingExpense();
      wingRafter_pipeBendingExpense.setAssociation(design, struct, work, level, position, this);
      wingRafter_pipeBendingExpense.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "방풍벽 서까래",
        `원형, ${specsArray[2]}`,
      );
      wingRafter_pipeBendingExpense.selected = false;
      wingRafter_pipeBendingExpense.visible = false;
      this.itemAC.splice(1, 0, wingRafter_pipeBendingExpense);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 7 && (<Item>this.itemAC[6]).productId === CONST.ITEM_ID_SCREW) {
      // [방풍벽 서까래] 용접
      const wingRafter_welding: ItemOtherExpense = new ItemOtherExpense();
      wingRafter_welding.setAssociation(design, struct, work, level, position, this);
      wingRafter_welding.setDefaultData(7, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "방풍벽 서까래", "일반");
      wingRafter_welding.selected = false;
      wingRafter_welding.visible = false;
      this.itemAC.push(wingRafter_welding);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 8 && (<Item>this.itemAC[6]).productId === CONST.ITEM_ID_SCREW) {
      // 속T 고정구 추가
      const wingRafterSecondAndWingStraight_innerTHolder: ItemSconce = new ItemSconce();
      wingRafterSecondAndWingStraight_innerTHolder.setAssociation(design, struct, work, level, position, this);
      wingRafterSecondAndWingStraight_innerTHolder.setDefaultData(
        6,
        CONST.ITEM_ID_INNER_T_HOLDER,
        CONST.ITEM_NAME_INNER_T_HOLDER,
        "방풍벽 서까래(2,3중) + 방풍벽 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      wingRafterSecondAndWingStraight_innerTHolder.selected = false;
      wingRafterSecondAndWingStraight_innerTHolder.visible = false;
      this.itemAC.splice(6, 0, wingRafterSecondAndWingStraight_innerTHolder);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_wingRafterLength();
    this.algorithm_wingRafterQuantity();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;

    /// //////// 선택, 가시성 ///////////

    this.wingRafter_pipe.selected = true;
    this.wingRafter_pipe.visible = true;
    if (this.wingRafter_pipe.specBending === CONST.ITEM_SPEC_VALUE_BENDING) {
      this.wingRafter_pipeBendingExpense.selected = this.design.basic.bendingExpenseFlag;
      this.wingRafter_pipeBendingExpense.visible = true;
    } else {
      this.wingRafter_pipeBendingExpense.selected = false;
      this.wingRafter_pipeBendingExpense.visible = false;
    }

    if (this.position.type === CONST.LB_WING_TYPE_DEFAULT) {
      if (this.level.index === 0) {
        if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
          if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
            this.wingRafterAndRoofRafter_connectionPin.selected = false;
            this.wingRafterAndRoofRafter_connectionPin.visible = false;
          } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
            this.wingRafterAndRoofRafter_connectionPin.selected = true;
            this.wingRafterAndRoofRafter_connectionPin.visible = true;
          }
          this.wingRafterFirstAndBeam_outerWallClamp.selected = true;
          this.wingRafterFirstAndBeam_outerWallClamp.visible = true;
          this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.selected = false;
          this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.visible = false;
          this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.selected = false;
          this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.visible = false;
        } else {
          this.wingRafterAndRoofRafter_connectionPin.selected = false;
          this.wingRafterAndRoofRafter_connectionPin.visible = false;
          this.wingRafterFirstAndBeam_outerWallClamp.selected = false;
          this.wingRafterFirstAndBeam_outerWallClamp.visible = false;
          this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.selected = false;
          this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.visible = false;
          this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.selected = false;
          this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.visible = false;
        }
      } else if (this.level.index >= 1) {
        if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
          this.wingRafterAndRoofRafter_connectionPin.selected = false;
          this.wingRafterAndRoofRafter_connectionPin.visible = false;
          this.wingRafterFirstAndBeam_outerWallClamp.selected = false;
          this.wingRafterFirstAndBeam_outerWallClamp.visible = false;
          if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
            this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.selected = true;
            this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.visible = true;
            this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.selected = false;
            this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.visible = false;
          } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
            this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.selected = false;
            this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.visible = false;
            this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.selected = true;
            this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.visible = true;
          }
        } else {
          this.wingRafterAndRoofRafter_connectionPin.selected = false;
          this.wingRafterAndRoofRafter_connectionPin.visible = false;
          this.wingRafterFirstAndBeam_outerWallClamp.selected = false;
          this.wingRafterFirstAndBeam_outerWallClamp.visible = false;
          this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.selected = false;
          this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.visible = false;
          this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.selected = false;
          this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.visible = false;
        }
      }
      this.wingRafterSecondAndWingStraight_innerTHolder.selected = false;
      this.wingRafterSecondAndWingStraight_innerTHolder.visible = false;
    }

    if (this.position.type === CONST.LB_WING_TYPE_SIMPLE) {
      if (this.level.index === 0) {
        this.wingRafterSecondAndWingStraight_innerTHolder.selected = false;
        this.wingRafterSecondAndWingStraight_innerTHolder.visible = false;
      } else if (this.level.index >= 1) {
        this.wingRafterSecondAndWingStraight_innerTHolder.selected = true;
        this.wingRafterSecondAndWingStraight_innerTHolder.visible = true;
      }
      this.wingRafterAndRoofRafter_connectionPin.selected = false;
      this.wingRafterAndRoofRafter_connectionPin.visible = false;
      this.wingRafterFirstAndBeam_outerWallClamp.selected = false;
      this.wingRafterFirstAndBeam_outerWallClamp.visible = false;
      this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.selected = false;
      this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.visible = false;
      this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.selected = false;
      this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.visible = false;
    }

    if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
      this.wingRafter_screw.selected = true;
      this.wingRafter_screw.visible = true;
      this.wingRafter_welding.selected = false;
      this.wingRafter_welding.visible = true;
    } else {
      this.wingRafter_screw.selected = false;
      this.wingRafter_screw.visible = true;
      this.wingRafter_welding.selected = true;
      this.wingRafter_welding.visible = true;
    }

    /// //////// 수량 ///////////

    if (this.wingRafter_pipe.specLength > this.wingRafterLength) {
      this.wingRafter_pipe.designQuantity =
        this.wingRafterQuantity / Math.floor(this.wingRafter_pipe.specLength / this.wingRafterLength);
    } else {
      this.wingRafter_pipe.designQuantity = this.wingRafterQuantity;
    }

    this.wingRafter_pipeBendingExpense.designQuantity = this.wingRafterQuantity;

    this.wingRafterAndRoofRafter_connectionPin.designQuantity =
      this.wingRafterQuantity * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;

    this.wingRafterFirstAndBeam_outerWallClamp.designQuantity =
      this.wingRafterQuantity * CONST.NUM_EXTRA_RATE_OUTER_WALL_CLAMP;

    this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.designQuantity =
      this.wingRafterQuantity * CONST.NUM_EXTRA_RATE_SECOND_LEVEL_PAIR_SCONCE;

    this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.designQuantity =
      this.wingRafterQuantity * 2 * CONST.NUM_EXTRA_RATE_SECOND_LEVEL_PAIR_SCONCE;

    this.wingRafterSecondAndWingStraight_innerTHolder.designQuantity =
      this.wingRafterQuantity * CONST.NUM_EXTRA_RATE_INNER_T_HOLDER;

    this.wingRafter_screw.designQuantity =
      ((this.wingRafterAndRoofRafter_connectionPin.getSelectedQuantity() * 2 +
        this.wingRafterFirstAndBeam_outerWallClamp.getSelectedQuantity() * 2 +
        this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.getSelectedQuantity() * 3 +
        this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.getSelectedQuantity() * 3 +
        this.wingRafterSecondAndWingStraight_innerTHolder.getSelectedQuantity() * 2) /
        this.wingRafter_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.wingRafter_welding.designQuantity = this.wingRafterQuantity;
  }

  /**
   * 알고리즘: 방풍벽 서까래 간격 <- 서까래 간격(지붕 골조)
   */
  public algorithm_wingRafterInterval(): void {
    // 외부
    const roofPosition: RoofPositionIL = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;

    this.wingRafterInterval = roofPosition.rafterPart.rafterInterval;
  }

  /**
   * 알고리즘: 방풍벽 서까래 길이(1중) <- 선택(중수), 측고(기본정보), 방풍벽 폭(기본정보)
   */
  public algorithm_wingRafterLength(): void {
    let wingWidth: number = 0;

    if (this.level.index === 0) {
      // 1중 방풍벽 서까래 길이는 기본정보의 방풍벽 폭이나 암묵적인 값(1.5m)으로 계산
      if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        if (this.basic.wingLeft > 0) {
          // 우측은 좌측과 동일시
          wingWidth = this.basic.wingLeft;
        } else {
          wingWidth = 1.5; // 암묵적인 기본값
        }
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        if (this.basic.wingFront > 0) {
          wingWidth = this.basic.wingFront;
        } else {
          wingWidth = 1.5; // 암묵적인 기본값
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        if (this.basic.wingBack > 0) {
          wingWidth = this.basic.wingBack;
        } else {
          wingWidth = 1.5; // 암묵적인 기본값
        }
      }
      this.wingRafterLength = CommonUtil.roundX(
        Math.sqrt(Math.pow(this.basicLevel.heightShoulder, 2) + Math.pow(wingWidth, 2)) + 0.5,
        0.5,
      );
    } else {
      // 2중이상 방풍벽 서까래 길이는 1중 방풍벽 서까래 길이를 기반으로 계산 (0.5씩 빼주기)
      this.wingRafterLength =
        ((this.work as WingWorkIL).levelAC[this.level.index - 1].positionAC[this.position.index] as WingPositionIL)
          .wingRafterPart.wingRafterLength - 0.5;
    }
  }

  /**
   * 알고리즘: 방풍벽 서까래 수량 <- 방풍벽 서까래 간격, 길이(기본정보), 폭(기본정보), 동수(기본정보)
   */
  public algorithm_wingRafterQuantity(): void {
    if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
      this.wingRafterQuantity =
        (Math.ceil(this.basicLevel.length / this.wingRafterInterval) + 1) * 2 * CONST.NUM_EXTRA_RATE_RAFTER_PIPE;
    } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
      this.wingRafterQuantity =
        (Math.ceil((this.basicLevel.width * this.basic.buildingNumber) / this.wingRafterInterval) + 5) *
        CONST.NUM_EXTRA_RATE_RAFTER_PIPE;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [방풍벽 서까래] 파이프 <- 방풍벽 서까래 파이프 샘플
   */
  public algorithmSpec_wingRafter_pipe(): void {
    this.wingRafter_pipe.specs = this.wingRafterPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [방풍벽 서까래] 파이프 벤딩비 <- 방풍벽 서까래 파이프 샘플
   */
  public algorithmSpec_wingRafter_pipeBendingExpense(): void {
    this.wingRafter_pipeBendingExpense.specPipeType = this.wingRafterPipeSample.specPipeType;
    this.wingRafter_pipeBendingExpense.specPipeCrossSize = this.wingRafterPipeSample.specPipeCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 서까래 + 지붕 서까래] 연결핀 <- 방풍벽 서까래 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_wingRafterAndRoofRafter_connectionPin(): void {
    this.wingRafterAndRoofRafter_connectionPin.specPipeType = this.wingRafterPipeSample.specPipeType;
    this.wingRafterAndRoofRafter_connectionPin.specCrossSize1 = this.wingRafterPipeSample.specCrossSize;
    this.wingRafterAndRoofRafter_connectionPin.specCrossSize2 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 서까래(1중) + 보] 외벽 클램프 <- 방풍벽 서까래 파이프 샘플, 형태(기둥 골조), 보 파이프 샘플
   */
  public algorithmSpec_wingRafterFirstAndBeam_outerWallClamp(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      this.wingRafterFirstAndBeam_outerWallClamp.specPipeType =
        columnPosition.cylinderBeamPart.beamPipeSample.specPipeType;
      this.wingRafterFirstAndBeam_outerWallClamp.specCrossSize1 =
        columnPosition.cylinderBeamPart.beamPipeSample.specCrossSize;
      this.wingRafterFirstAndBeam_outerWallClamp.specCrossSize2 = this.wingRafterPipeSample.specCrossSize;
    } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
      this.wingRafterFirstAndBeam_outerWallClamp.specPipeType =
        columnPosition.prismBeamPart.beamPipeSample.specPipeType;
      this.wingRafterFirstAndBeam_outerWallClamp.specCrossSize1 =
        columnPosition.prismBeamPart.beamPipeSample.specCrossSize;
      this.wingRafterFirstAndBeam_outerWallClamp.specCrossSize2 = this.wingRafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [방풍벽 서까래(2,3중) + 기둥] 이중 쌍꽂이 <- 방풍벽 서까래 파이프 샘플, 기둥 파이프 샘플(원형)
   */
  public algorithmSpec_wingRafterSecondAndBeamCylinder_secondLevelPairSconce(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.specPipeType =
      columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
    this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.specCrossSize1 =
      columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
    this.wingRafterSecondAndBeamCylinder_secondLevelPairSconce.specCrossSize2 = this.wingRafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 서까래(2,3중) + 기둥] 이중 외꽂이 <- 방풍벽 서까래 파이프 샘플, 기둥 파이프 샘플(각형)
   */
  public algorithmSpec_wingRafterSecondAndBeamPrism_secondLevelSoleSconce(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.specPipeType =
      columnPosition.prismColumnPart.columnPipeSample.specPipeType;
    this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.specCrossSize1 =
      columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
    this.wingRafterSecondAndBeamPrism_secondLevelSoleSconce.specCrossSize2 = this.wingRafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 서까래(2,3중) + 방풍벽 가로대] 속T 고정구 <- 방풍벽 서까래 파이프 샘플, 방풍벽 가로대 샘플
   */
  public algorithmSpec_wingRafterSecondAndWingStraight_innerTHolder(): void {
    this.wingRafterSecondAndWingStraight_innerTHolder.specPipeType = (<WingPositionIL>(
      this.position
    )).wingStraightPart.wingStraightPipeSample.specPipeType;
    this.wingRafterSecondAndWingStraight_innerTHolder.specCrossSize1 = (<WingPositionIL>(
      this.position
    )).wingStraightPart.wingStraightPipeSample.specCrossSize;
    this.wingRafterSecondAndWingStraight_innerTHolder.specCrossSize2 = this.wingRafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 서까래] 나사 <- 공통 샘플
   */
  public algorithmSpec_wingRafter_screw(): void {}

  /**
   * 규격 알고리즘: [방풍벽 서까래] 용접 <- 공통 샘플
   */
  public algorithmSpec_wingRafter_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
