import { CONST } from "vhows-design/src/common/constant/CONST";
import { SpecCompanyDataIF } from "vhows-design/src/object/design/DesignInterface";

/**
 * 파이프 규격 - 원형 (가로대, 브레싱)
 */
export const PipeSpecData_Cylinder: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 33.5mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 42.2mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 40A, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 파이프 규격 - 환봉
 */
export const PipeSpecData_RoundBar: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "환봉, 일반, 12mm, -",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 파이프 규격 - 원형 모든 규격
 */
export const PipeSpecData_CylinderAll: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 33.5mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 42.2mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 59.9mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 59.9mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 50A, 3.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 축가공, 40A, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 축가공, 50A, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 축가공, 50A, 3.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 파이프 규격 - 원형 (22~32)
 */
export const PipeSpecData_CylinderSmall: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
];

/**
 * 파이프 규격 - 서까래
 */
export const PipeSpecData_Rafter: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 33.5mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 42.2mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 59.9mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 59.9mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
];

/**
 * 파이프 규격 - 개폐 축
 */
export const PipeSpecData_Axis: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 33.5mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 50A, 3.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 축가공, 40A, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 축가공, 50A, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 축가공, 50A, 3.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 파이프 규격 - 원형 기둥
 */
export const PipeSpecData_CylinderColumn: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 59.9mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 59.9mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 파이프 규격 - 각형 (샷시틀)
 */
export const PipeSpecData_Prism: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "각형, 일반, 25×25mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 25×25mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 30×30mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 40×20mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 40×40mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 50×30mm, 2.0T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×45mm, 2.0T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×45mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 파이프 규격 - 각형 (기둥, 보, 중방 등)
 */
export const PipeSpecData_PrismColumn: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "각형, 일반, 50×30mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 50×30mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×45mm, 2.0T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×45mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 파이프 규격 - 혼합
 */
export const PipeSpecData_Mix: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "각형, 일반, 25×25mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 25×25mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 25×25mm, 1.6T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 30×30mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 40×20mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 40×40mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 50×30mm, 2.0T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 33.5mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 42.2mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
];

/**
 * 파이프 규격 - 창틀
 */
export const PipeSpecData_Window: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 22.2mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 33.5mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 42.2mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 59.9mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 59.9mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 30×30mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 40×20mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 40×40mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 50×30mm, 2.0T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×100mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×75mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 125×125mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 마구리 간살 규격
 */
export const BarSpecData: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 33.5mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 42.2mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 48.1mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 30×30mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 40×20mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 40×40mm, 1.4T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 50×30mm, 2.0T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×60mm, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.3T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×75mm, 2.9T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 마구리 가로대(하단) 규격
 */
export const StraightBottomSpecData: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 25.4mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.5T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm, 1.7T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }, { label: "벤딩" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 60×30mm, 1.8T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×45mm, 1.8T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×45mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 1.8T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * C형강 규격 (출입문)
 */
export const CSectionSteelSpecData: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "각형, 일반, 60×30mm, 1.8T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×45mm, 1.8T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 75×45mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 1.8T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
  {
    specModel: {
      label: "각형, 일반, 100×50mm, 2.1T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 물받이거터 보
 */
export const GutterBeamPipeSpecData: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "거터형, 알루미늄, 100x60mm, 2.0T",
      numSpec1: {
        min: 1,
        max: 30,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * 중방 받침대 규격 - 원형
 */
export const MiddleSupportPipeSpecData_Cylinder: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 25.4mm",
    },
  },
  {
    specModel: {
      label: "원형, 일반, 31.8mm",
    },
  },
];

/**
 * 중방 받침대 규격 - 각형
 */
export const MiddleSupportPipeSpecData_Prism: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "각형, 일반, 60×60mm",
    },
  },
];

/**
 * 일반 레일 규격 - 지면 레일
 */
export const NomalRailPipeSpecData: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 40A, 3.2T",
      numSpec1: {
        min: 1,
        max: 15,
        step: 0.1,
        unit: CONST.UNIT_METER,
        prefix: "",
      },
      optionSpec1: [{ label: "-" }],
    },
  },
];

/**
 * U벤딩 튜브레일 규격 - 지면 레일
 */
export const UBendingTubeRailSpecData: SpecCompanyDataIF[] = [
  {
    specModel: {
      label: "원형, 일반, 40A",
    },
  },
];
