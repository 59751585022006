import { jsonObject } from "typedjson";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { HydSystemPosition } from "vhows-design/src/object/design/hydroponics/system/HydSystemPosition";
import { HydSystemPart_HydSupply } from "vhows-design/src/object/design/hydroponics/system/HydSystemPart_HydSupply";
import { HydSystemPart_HydControl } from "vhows-design/src/object/design/hydroponics/system/HydSystemPart_HydControl";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject
export class HydSystemSample_ControlMainPePipe extends ItemWateringPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const controlPart: HydSystemPart_HydControl = <HydSystemPart_HydControl>this.part;
    controlPart.algorithmSpec_controlMain_pePipe();
    controlPart.algorithmSpec_waterTankOut_waterTankFitting();
    controlPart.algorithmSpec_waterTankOut_nipple();
    controlPart.algorithmSpec_waterTankOut_femaleBallValve();
    controlPart.algorithmSpec_waterPump_bushing();
    controlPart.algorithmSpec_controlMain_valveSocket();
    controlPart.algorithmSpec_controlMain_elbow();
    controlPart.algorithmSpec_controlMain_exactT();
    controlPart.algorithmSpec_controlMain_unionValve();
    controlPart.algorithmSpec_nutAndPhTankIn_waterTankFitting();

    // 다른 파트
    const supplyPart: HydSystemPart_HydSupply = (<HydSystemPosition>this.position).hydSupplyPart;
    supplyPart.algorithmSpec_supplyMainA_pePipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
