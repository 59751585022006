import { TextBody } from "@greenlabs/formula-components";

const LottieLoading = () => {
  return (
    <div className="z-40 fixed max-w-2xl left-1/2  -translate-x-1/2  top-0 bg-black opacity-80 h-screen w-full flex flex-col justify-center items-center">
      <img src={"/images/lottie-loading.gif"} width="100" height="100" alt={"gif"} />
      <TextBody size="sm" weight="regular" color="white" className="text-center">
        지금 설계 결과를 확인하는 중이에요
        <br />
        잠시만 기다려주세요
      </TextBody>
    </div>
  );
};

export default LottieLoading;
