import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { CoverTemp } from "vhows-design/src/object/design/base/CoverTemp";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { SkyCoverPosition } from "vhows-design/src/object/design/skylight/cover/SkyCoverPosition";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { SkirtLevel } from "vhows-design/src/object/design/cover/skirt/SkirtLevel";
import { WindbreakLevel } from "vhows-design/src/object/design/cover/windbreak/WindbreakLevel";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-07-07
 */
@jsonObject
export class CoverPart extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  protected coverPosition: CoverPosition;
  protected coverSide: CoverTemp; // 측면 피복
  protected coverSideSkyShort: CoverTemp; // 측면(+측면+마구리) - 비대칭 천창(짧은쪽)
  protected coverRoof: CoverTemp; // 지붕(+측면+마구리) 피복
  protected coverRoofSkyShort: CoverTemp; // 지붕(+측면+마구리) - 비대칭 천창(짧은쪽)
  protected coverOther: CoverTemp; // 특수 피복
  protected coverFront: CoverTemp; // 앞면 피복
  protected coverBack: CoverTemp; // 뒷면 피복
  // 외부
  protected trunkPosition: TrunkPosition;
  protected endpieceLevel: EndpieceLevel;
  protected switcherLevel: SwitcherLevel;
  protected skirtLevel: SkirtLevel;
  protected windbreakLevel: WindbreakLevel;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 기본

  protected endpieceIncludedExceed: number;
  protected cashmilonStyleOverlap: number;

  // 단위값

  /** 단위 측면 폭(개폐안함) */
  protected unitSideWidthClose: number;
  /** 단위 측면 폭(개폐) */
  protected unitSideWidthOpen: number;
  /** 단위 측면 폭 + 측면 치마 */
  protected unitSideWidth_sideSkirt: number;
  /** 단위 측면 폭 + 지붕 치마 */
  protected unitSideWidth_roofSkirt: number;
  /** 단위 측면 폭 + 측면 치마 단수용 */
  protected unitSideWidth_sideSkirtStep: number;
  /** 단위 측면 길이 */
  protected unitSideLength: number;
  /** 단위 측면 길이 AC */
  protected unitSideLengthAC: number[];

  /** 단위 지붕 폭(개폐안함) */
  protected unitRoofWidthClose: number;
  /** 단위 지붕 폭(개폐) */
  protected unitRoofWidthOpen: number;
  /** 단위 지붕 길이 */
  protected unitRoofLength: number;
  /** 단위 지붕 길이 AC */
  protected unitRoofLengthAC: number[];
  /** 단위 지붕 길이 + 마구리 출입문 */
  protected unitRoofLength_endpieceGate: number;
  /** 단위 지붕 길이 + 마구리 바람막이 */
  protected unitRoofLength_endpieceWindbreak: number;
  /** 단위 지붕 길이 + 측면 바람막이 */
  protected unitRoofLength_sideWindbreak: number;
  /** 단위 지붕 길이 + 지붕 바람막이 */
  protected unitRoofLength_roofWindbreak: number;

  /** 단위 광폭중앙 폭 */
  protected unitDoubleCenterWidth: number;
  /** 단위 광폭중앙 길이 */
  protected unitDoubleCenterLength: number;
  /** 단위 광폭중앙 길이 AC */
  protected unitDoubleCenterLengthAC: number[];

  /** 단위 마구리 하단 폭 */
  protected unitEndpieceLowerWidth: number;
  /** 단위 마구리 하단 폭 + 앞면 치마 */
  protected unitEndpieceLowerWidth_frontSkirt: number;
  /** 단위 마구리 하단 폭 + 뒷면 치마 */
  protected unitEndpieceLowerWidth_backSkirt: number;
  /** 단위 마구리 하단 길이 */
  protected unitEndpieceLowerLength: number;
  /** 단위 마구리 상단 폭 */
  protected unitEndpieceUpperWidth: number;
  /** 단위 마구리 상단 길이 */
  protected unitEndpieceUpperLength: number;
  /** 단위 마구리 길이 + 마구리 출입문 */
  protected unitEndpieceLength_endpieceGate: number;
  /** 단위 마구리 길이 + 마구리 바람막이 */
  protected unitEndpieceLength_endpieceWindbreak: number;
  /** 단위 마구리 길이 + 측면 바람막이 */
  protected unitEndpieceLength_sideWindbreak: number;
  /** 단위 마구리 길이 + 지붕 바람막이 */
  protected unitEndpieceLength_roofWindbreak: number;

  // 실제값

  protected widthSideCloseIncluded: number;
  protected widthSideOpenIncluded: number;
  protected widthSideCloseSeperated: number;
  protected widthSideOpenSeperated: number;
  protected lengthSide: number;
  protected lengthSideAC: number[];

  protected widthRoofClose: number;
  protected widthRoofOpen: number;
  protected lengthRoof: number;
  protected lengthRoofAC: number[];

  protected widthDoubleCenter: number;
  protected lengthDoubleCenter: number;
  protected lengthDoubleCenterAC: number[];

  protected lengthTrunk: number;
  protected lengthTrunkAC: number[];

  protected widthFront: number;
  protected lengthFront: number;
  protected widthBack: number;
  protected lengthBack: number;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    super.setReferenceVariable();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    this.coverPosition = <CoverPosition>this.position;
    this.coverSide = new CoverTemp();
    this.coverSideSkyShort = null;
    this.coverRoof = new CoverTemp();
    this.coverRoofSkyShort = null;
    this.coverOther = null;
    this.coverFront = new CoverTemp();
    this.coverBack = new CoverTemp();
    // 외부
    this.trunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;
    this.endpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];
    this.switcherLevel = <SwitcherLevel>this.struct.switcherWork.levelAC[this.level.index];
    this.skirtLevel = <SkirtLevel>this.struct.skirtWork.levelAC[this.level.index];
    this.windbreakLevel = <WindbreakLevel>this.struct.windbreakWork.levelAC[this.level.index];
    // 기타
    let i: number;
    let basicBuilding: BasicBuilding;

    //----------------------------------
    // 수치 - 단위값
    //----------------------------------

    // 기본
    this.endpieceIncludedExceed = 0.25;
    this.cashmilonStyleOverlap = this.design.preference.cashmilonStyleOverlapLength;

    // 측면
    this.unitSideWidthClose =
      this.basicLevel.depthLaying + this.basicLevel.heightShoulder - this.basicLevel.distanceShoulderPad;
    if (this.design.preference.reduceOpenedCoverWidthFlag === true) {
      this.unitSideWidthOpen = this.unitSideWidthClose - 0.2;
    } else {
      this.unitSideWidthOpen = this.unitSideWidthClose;
    }
    this.unitSideLength = this.basicLevel.length + 1.0;
    if (this.basic.isVariableLength === true) {
      this.unitSideLengthAC = new Array();
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.unitSideLengthAC.push(basicBuilding.length + 1.0);
      }
    }

    // 측면 + @
    this.unitSideWidth_sideSkirt = this.skirtLevel.sidePosition.skirtHeight + 0.3;
    this.unitSideWidth_roofSkirt = this.skirtLevel.roofPosition.skirtHeight + this.basicLevel.distanceShoulderPad;
    this.unitSideWidth_sideSkirtStep = 0.6;

    // 지붕
    this.unitRoofWidthClose =
      this.trunkPosition.rafterPart.rafterTotalLength / 2 -
      (this.basicLevel.depthLaying + this.basicLevel.heightShoulder) +
      this.basicLevel.distanceShoulderPad;
    this.unitRoofWidthOpen = this.unitRoofWidthClose - this.basicLevel.distanceShoulderPad + 0.5;
    this.unitRoofLength = this.basicLevel.length + 1.0;
    if (this.basic.isVariableLength === true) {
      this.unitRoofLengthAC = new Array();
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.unitRoofLengthAC.push(basicBuilding.length + 1.0);
      }
    }

    // 광폭중앙
    this.unitDoubleCenterWidth = 1.2;
    this.unitDoubleCenterLength = this.basicLevel.length + 1;
    if (this.basic.isVariableLength === true) {
      this.unitDoubleCenterLengthAC = new Array();
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.unitDoubleCenterLengthAC.push(basicBuilding.length);
      }
    }

    // 지붕 + @
    this.unitRoofLength_endpieceGate = 0; // 반영 안함
    this.unitRoofLength_endpieceWindbreak = 0; // 반영 안함
    this.unitRoofLength_sideWindbreak = 0; // 반영 안함
    if (this.level.index === 0) {
      this.unitRoofLength_roofWindbreak = this.trunkPosition.rafterPart.rafterInterval * 2 + 0.6;
    } else {
      this.unitRoofLength_roofWindbreak = this.trunkPosition.rafterPart.rafterInterval + 0.6;
    }
    // 마구리
    this.unitEndpieceLowerWidth = this.basicLevel.depthLaying + this.basicLevel.heightShoulder;
    this.unitEndpieceLowerLength = this.basicLevel.width + 1;
    this.unitEndpieceUpperWidth =
      this.basicLevel.heightMax + this.basicLevel.depthLaying - this.unitEndpieceLowerWidth + 0.4;
    this.unitEndpieceUpperLength = this.unitEndpieceLowerLength;

    // 마구리 + @
    this.unitEndpieceLength_endpieceGate = 3;
    this.unitEndpieceLowerWidth_frontSkirt = this.skirtLevel.frontPosition.skirtHeight + 0.3;
    this.unitEndpieceLowerWidth_backSkirt = this.skirtLevel.backPosition.skirtHeight + 0.3;
    this.unitEndpieceLength_endpieceWindbreak = 1 + 0.6;
    if (this.level.index === 0) {
      this.unitEndpieceLength_sideWindbreak = this.trunkPosition.rafterPart.rafterInterval * 2 + 0.6;
    } else {
      this.unitEndpieceLength_sideWindbreak = this.trunkPosition.rafterPart.rafterInterval + 0.6;
    }
    if (this.level.index === 0) {
      this.unitEndpieceLength_roofWindbreak = this.trunkPosition.rafterPart.rafterInterval * 2 + 0.6;
    } else {
      this.unitEndpieceLength_roofWindbreak = this.trunkPosition.rafterPart.rafterInterval + 0.6;
    }

    //----------------------------------
    // 수치 - 실제값
    //----------------------------------

    // 앞면 마구리
    if (this.switcherLevel.frontPosition.selectedDetail(this.coverPosition) === false) {
      if (this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
        this.widthFront = this.unitEndpieceUpperWidth;
      } else {
        this.widthFront = this.unitEndpieceLowerWidth + this.unitEndpieceUpperWidth;
      }
    } else {
      this.widthFront = this.unitEndpieceLowerWidth + this.unitEndpieceUpperWidth;
    }
    this.lengthFront = this.unitEndpieceLowerLength;
    // 앞면 마구리 + 마구리 출입문
    if (
      this.endpieceLevel.frontPosition.selected === true &&
      (this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_HINGED)
    ) {
      this.lengthFront += this.unitEndpieceLength_endpieceGate;
      this.coverFront.gateQuantity = 1;
    }
    // 앞면 마구리 + 마구리 치마
    if (
      this.skirtLevel.frontPosition.selectedDetail(this.coverPosition) === true &&
      this.skirtLevel.frontPosition.type === CONST.LB_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER
    ) {
      this.widthFront += this.unitEndpieceLowerWidth_frontSkirt;
      this.coverFront.skirtIncluded = true;
    }
    // 앞면 마구리 + 마구리 바람막이
    if (
      this.windbreakLevel.frontPosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.frontPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      this.lengthFront += this.unitEndpieceLength_endpieceWindbreak * 2;
      this.coverFront.windbreakIncluded = true;
    }

    // 뒷면 마구리
    if (this.switcherLevel.backPosition.selectedDetail(this.coverPosition) === false) {
      if (this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
        this.widthBack = this.unitEndpieceUpperWidth;
      } else {
        this.widthBack = this.unitEndpieceLowerWidth + this.unitEndpieceUpperWidth;
      }
    } else {
      this.widthBack = this.unitEndpieceLowerWidth + this.unitEndpieceUpperWidth;
    }
    this.lengthBack = this.unitEndpieceLowerLength;
    // 뒷면 마구리 + 마구리 출입문
    if (
      this.endpieceLevel.backPosition.selected &&
      (this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_HINGED)
    ) {
      this.lengthBack += this.unitEndpieceLength_endpieceGate;
      this.coverBack.gateQuantity = 1;
    }
    // 뒷면 마구리 + 마구리 치마
    if (
      this.skirtLevel.backPosition.selectedDetail(this.coverPosition) === true &&
      this.skirtLevel.backPosition.type === CONST.LB_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER
    ) {
      this.widthBack += this.unitEndpieceLowerWidth_backSkirt;
      this.coverBack.skirtIncluded = true;
    }
    // 뒷면 마구리 + 마구리 바람막이
    if (
      this.windbreakLevel.backPosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.backPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      this.lengthBack += this.unitEndpieceLength_endpieceWindbreak * 2;
      this.coverBack.windbreakIncluded = true;
    }

    // 마구리 + 측면 바람막이
    if (
      this.windbreakLevel.sidePosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.sidePosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      if (this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
        this.coverSide.frontIncluded = true;
        this.coverSide.backIncluded = true;
        this.widthFront += this.unitEndpieceLength_roofWindbreak;
        this.coverFront.windbreakIncluded = true;
        this.widthBack += this.unitEndpieceLength_roofWindbreak;
        this.coverBack.windbreakIncluded = true;
      } else {
        this.lengthFront += this.unitEndpieceLength_sideWindbreak * 2;
        this.coverFront.windbreakIncluded = true;
        this.lengthBack += this.unitEndpieceLength_sideWindbreak * 2;
        this.coverBack.windbreakIncluded = true;
      }
    }
    // 마구리 + 지붕 바람막이
    if (
      this.windbreakLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.roofPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      this.widthFront += this.unitEndpieceLength_roofWindbreak;
      this.coverFront.windbreakIncluded = true;
      this.widthBack += this.unitEndpieceLength_roofWindbreak;
      this.coverBack.windbreakIncluded = true;
    }

    // 측면
    this.widthSideCloseIncluded = this.unitSideWidthClose;
    this.widthSideOpenIncluded = this.unitSideWidthOpen;
    this.widthSideCloseSeperated = this.unitSideWidthClose;
    this.widthSideOpenSeperated = this.unitSideWidthOpen;
    // 측면 + 측면 치마
    if (
      this.skirtLevel.sidePosition.selectedDetail(this.coverPosition) === true &&
      this.skirtLevel.sidePosition.type === CONST.LB_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER
    ) {
      this.widthSideCloseIncluded += this.unitSideWidth_sideSkirt;
      this.widthSideOpenIncluded += this.unitSideWidth_sideSkirt;
      this.widthSideCloseSeperated += this.unitSideWidth_sideSkirt;
      this.widthSideOpenSeperated += this.unitSideWidth_sideSkirt;
      this.coverSide.skirtIncluded = true;
    }
    // 측면 + 지붕 치마
    if (
      this.skirtLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
      this.skirtLevel.roofPosition.type === CONST.LB_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER
    ) {
      this.widthSideCloseSeperated += this.unitSideWidth_roofSkirt;
      this.widthSideOpenSeperated += this.unitSideWidth_roofSkirt;
      this.coverSide.skirtIncluded = true;
    }
    this.lengthSide = this.unitSideLength;
    if (this.basic.isVariableLength === true) {
      this.lengthSideAC = new Array();
      let unitSideLengthTemp: number;
      for (unitSideLengthTemp of this.unitSideLengthAC) {
        this.lengthSideAC.push(unitSideLengthTemp);
      }
    }

    // 지붕
    this.widthRoofClose = this.unitRoofWidthClose;
    this.widthRoofOpen = this.unitRoofWidthOpen;
    this.lengthRoof = this.unitRoofLength;
    if (this.basic.isVariableLength === true) {
      this.lengthRoofAC = new Array();
      let unitRoofLengthTemp: number;
      for (unitRoofLengthTemp of this.unitRoofLengthAC) {
        this.lengthRoofAC.push(unitRoofLengthTemp);
      }
    }

    // 광폭 중앙
    this.widthDoubleCenter = this.unitDoubleCenterWidth;
    this.lengthDoubleCenter = this.unitDoubleCenterLength;
    if (this.basic.isVariableLength === true) {
      this.unitDoubleCenterLengthAC = new Array();
      let unitDoubleCenterLengthTemp: number;
      for (unitDoubleCenterLengthTemp of this.unitDoubleCenterLengthAC) {
        this.lengthDoubleCenterAC.push(unitDoubleCenterLengthTemp);
      }
    }
    // 지붕 + 앞면 마구리
    if (this.coverPosition.scopeSelectedFrontIncluded === true) {
      // 지붕 + 앞면 마구리
      this.lengthRoof += this.widthFront - this.endpieceIncludedExceed;
      this.coverRoof.frontIncluded = true;
      if (this.basic.isVariableLength === true) {
        for (i = 0; i < this.lengthRoofAC.length; i++) {
          this.lengthRoofAC[i] += this.widthFront - this.endpieceIncludedExceed;
        }
      }
      // 지붕 + 앞면 마구리 출입문
      if (
        this.endpieceLevel.frontPosition.selected === true &&
        (this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
          this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_HINGED)
      ) {
        this.lengthRoof += this.unitRoofLength_endpieceGate;
        this.coverRoof.gateQuantity = 1;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceGate;
          }
        }
      }
      // 지붕 + 앞면 마구리 치마 -> 앞면 폭에 이미 반영됨
      // 지붕 + 앞면 마구리 바람막이
      if (
        this.windbreakLevel.frontPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.frontPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_endpieceWindbreak * 2;
        this.coverRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceWindbreak * 2;
          }
        }
      }
      // 지붕 + (앞면)측면 바람막이
      if (
        this.windbreakLevel.sidePosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.sidePosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_sideWindbreak;
        this.coverRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_sideWindbreak;
          }
        }
      }
      // 지붕 + (앞면)지붕 바람막이 -> 앞면 폭에 이미 반영됨
    }
    // 지붕 + 뒷면 마구리
    if (this.coverPosition.scopeSelectedBackIncluded === true) {
      // 지붕 + 뒷면 마구리
      this.lengthRoof += this.widthBack - this.endpieceIncludedExceed;
      this.coverRoof.backIncluded = true;
      if (this.basic.isVariableLength === true) {
        for (i = 0; i < this.lengthRoofAC.length; i++) {
          this.lengthRoofAC[i] += this.widthBack - this.endpieceIncludedExceed;
        }
      }
      // 지붕 + 뒷면 마구리 출입문
      if (
        this.endpieceLevel.backPosition.selected &&
        (this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
          this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_HINGED)
      ) {
        this.lengthRoof += this.unitRoofLength_endpieceGate;
        this.coverRoof.gateQuantity = 1;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceGate;
          }
        }
      }
      // 지붕 + 뒷면 마구리 치마 -> 앞면 폭에 이미 반영됨
      // 지붕 + 뒷면 마구리 바람막이
      if (
        this.windbreakLevel.backPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.backPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_endpieceWindbreak * 2;
        this.coverRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceWindbreak * 2;
          }
        }
      }
      // 지붕 + (뒷면)측면 바람막이
      if (
        this.windbreakLevel.sidePosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.sidePosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_sideWindbreak;
        this.coverRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_sideWindbreak;
          }
        }
      }
      // 지붕 + (뒷면)지붕 바람막이 -> 앞면 폭에 이미 반영됨
    }
    // 측면+지붕
    this.lengthTrunk = this.lengthSide;
    if (this.lengthTrunk < this.lengthRoof) {
      // 더 큰값
      this.lengthTrunk = this.lengthRoof;
    }
    if (this.basic.isVariableLength === true) {
      this.lengthTrunkAC = new Array();
      let lengthSideTemp: number;
      for (lengthSideTemp of this.lengthSideAC) {
        this.lengthTrunkAC.push(lengthSideTemp);
      }
      for (i = 0; i < this.lengthTrunkAC.length; i++) {
        if (this.lengthTrunkAC[i] < this.lengthRoofAC[i]) {
          this.lengthTrunkAC[i] = this.lengthRoofAC[i];
        }
      }
    }

    //----------------------------------
    // 용도 및 수치 - 피복객체값
    // 피복범위, 개폐여부, 개폐방향에 따른 값을 계산하여 피복객체에 할당함
    //----------------------------------

    /// /////////////////////////////////
    // 피복범위: "광폭중앙 / 지붕+측면 / 마구리"
    if (
      this.coverPosition.scopeSelectedDoubleCenter === true &&
      this.coverPosition.scopeSelectedRoof === true &&
      this.coverPosition.scopeSelectedSide === true
    ) {
      const switchStep = this.switcherLevel.sidePosition.switchStep;
      this.coverOther = new CoverTemp();
      this.coverOther.purpose = "광폭중앙";
      this.coverOther.width = this.widthDoubleCenter * 2;
      this.coverOther.length = this.lengthDoubleCenter;
      this.coverOther.lengthAC = this.lengthDoubleCenterAC;
      this.coverOther.quantity = 1;
      if (this.switcherLevel.sidePosition.selectedDetail(this.coverPosition) === false) {
        // 개폐방향: 지붕(무관)+측면(고정)
        this.coverSide = null;

        this.coverRoof.purpose = "지붕+측면";
        this.coverRoof.width =
          (this.widthSideCloseIncluded * 2 + this.widthRoofClose * 2 - this.widthDoubleCenter * 2) / 2;
        this.coverRoof.length = this.lengthTrunk;
        this.coverRoof.lengthAC = this.lengthTrunkAC;
        this.coverRoof.quantity = 2;
      } else if (this.switcherLevel.sidePosition.selectedDetail(this.coverPosition) === true) {
        // 개폐방향: 지붕(무관)+측면(편측)
        if (this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
          if (this.switcherLevel.sidePosition.switchStep <= 1) {
            this.coverSide = null;

            this.coverRoof.purpose = "지붕+측면(편측개폐)";
            this.coverRoof.width =
              (this.widthSideCloseIncluded +
                this.widthRoofClose * 2 +
                this.widthSideOpenIncluded -
                this.widthDoubleCenter * 2) /
              2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 2;
          } else {
            this.coverSide.purpose = "측면(편측개폐)";
            this.coverSide.width = this.widthSideOpenIncluded / switchStep + this.unitSideWidth_sideSkirtStep;
            this.coverSide.length = this.lengthTrunk;
            this.coverSide.lengthAC = this.lengthTrunkAC;
            this.coverSide.quantity = 2;

            this.coverRoof.purpose = "지붕+측면(편측개폐)";
            this.coverRoof.width =
              (this.widthSideCloseIncluded +
                this.widthRoofClose * 2 +
                this.widthSideOpenIncluded / switchStep -
                this.widthDoubleCenter * 2) /
              2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 2;
          }
        } else if (
          this.switcherLevel.sidePosition.switchWay === CONST.VL_SWITCHER_SWITCH_WAY_BOTH ||
          this.switcherLevel.sidePosition.switchWay === CONST.VL_SWITCHER_SWITCH_WAY_FULL
        ) {
          // 개폐방향: 지붕(무관)+측면(양측/완전)
          if (this.switcherLevel.sidePosition.switchStep <= 1) {
            this.coverSide = null;

            this.coverRoof.purpose = "지붕+측면(양측개폐)";
            this.coverRoof.width =
              (this.widthSideOpenIncluded * 2 + this.widthRoofClose * 2 - this.widthDoubleCenter * 2) / 2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 2;
          } else {
            this.coverSide.purpose = "측면(양측개폐)";
            this.coverSide.width = this.widthSideOpenIncluded / switchStep + this.unitSideWidth_sideSkirtStep;
            this.coverSide.length = this.lengthTrunk;
            this.coverSide.lengthAC = this.lengthTrunkAC;
            this.coverSide.quantity = 2;

            this.coverRoof.purpose = "지붕+측면(양측개폐)";
            this.coverRoof.width =
              ((this.widthSideOpenIncluded / switchStep) * 2 + this.widthRoofClose * 2 - this.widthDoubleCenter * 2) /
              2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 2;
          }
        }
      }
      /// /////////////////////////////////
      // 피복범위: 지붕+측면 || 지붕+측면+마구리
    } else if (this.coverPosition.scopeSelectedRoof === true && this.coverPosition.scopeSelectedSide === true) {
      const switchStep = this.switcherLevel.sidePosition.switchStep;
      // 서까래 확장: 기둥+보 파이프
      if (
        this.trunkPosition.rafterPart.extensionSelected === true &&
        this.trunkPosition.rafterPart.extensionType === CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM
      ) {
        if (this.trunkPosition.rafterPart.extensionPosition === CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE) {
          if (this.level.index === 0) {
            this.coverSide.purpose = "편측측면";
            this.coverSide.width = this.widthSideCloseSeperated;
            this.coverSide.length = this.lengthSide;
            this.coverSide.lengthAC = this.lengthSideAC;
            this.coverSide.quantity = 1;

            this.coverRoof.purpose = "지붕+편측측면";
            this.coverRoof.width = this.widthSideCloseIncluded + this.widthRoofClose + this.widthRoofOpen;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 1;
          } else {
            this.coverSide = null;

            this.coverRoof.purpose = "지붕+편측측면";
            this.coverRoof.width = this.widthSideCloseIncluded + this.widthRoofClose + this.widthRoofOpen;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 1;
          }
        } else if (this.trunkPosition.rafterPart.extensionPosition === CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH) {
          if (this.level.index === 0) {
            this.coverSide.purpose = "측면";
            this.coverSide.width = this.widthSideCloseSeperated;
            this.coverSide.length = this.lengthSide;
            this.coverSide.lengthAC = this.lengthSideAC;
            this.coverSide.quantity = 2;

            this.coverRoof.purpose = "지붕";
            this.coverRoof.width = this.widthRoofOpen * 2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 1;
          } else {
            this.coverSide = null;

            this.coverRoof.purpose = "지붕";
            this.coverRoof.width = this.widthRoofOpen * 2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 1;
          }
        }
      } else {
        // 개폐여부: 지붕(고정)+측면(고정)
        if (
          this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === false &&
          this.switcherLevel.sidePosition.selectedDetail(this.coverPosition) === false
        ) {
          this.coverSide = null;

          this.coverRoof.purpose = "지붕+측면";
          this.coverRoof.width = (this.widthSideCloseIncluded + this.widthRoofClose) * 2;
          this.coverRoof.length = this.lengthTrunk;
          this.coverRoof.lengthAC = this.lengthTrunkAC;
          this.coverRoof.quantity = 1;
        } else if (
          this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === false &&
          this.switcherLevel.sidePosition.selectedDetail(this.coverPosition) === true
        ) {
          // 개폐방향: 지붕(고정)+측면(개폐)
          if (this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
            // 지붕(고정)+측면(편측)
            if (this.switcherLevel.sidePosition.switchStep <= 1) {
              this.coverSide = null;

              this.coverRoof.purpose = "지붕+측면(측면개폐)";
              this.coverRoof.width = this.widthSideOpenIncluded + this.widthRoofClose * 2 + this.widthSideCloseIncluded;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;
            } else {
              this.coverSide.purpose = "측면(편측개폐)";
              this.coverSide.width = this.widthSideOpenIncluded / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverSide.length = this.lengthTrunk;
              this.coverSide.lengthAC = this.lengthTrunkAC;
              this.coverSide.quantity = 1;

              this.coverRoof.purpose = "지붕+측면(편측개폐)";
              this.coverRoof.width =
                this.widthSideOpenIncluded / switchStep + this.widthRoofClose * 2 + this.widthSideCloseIncluded;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;
            }
            this.coverOther = null;
          } else if (this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH) {
            // 지붕(고정)+측면(양측)
            if (this.switcherLevel.sidePosition.switchStep <= 1) {
              this.coverSide = null;

              this.coverRoof.purpose = "지붕+측면(양측개폐)";
              this.coverRoof.width = (this.widthSideOpenIncluded + this.widthRoofClose) * 2;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;
            } else {
              this.coverSide.purpose = "측면(양측개폐)";
              this.coverSide.width = this.widthSideOpenIncluded / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverSide.length = this.lengthTrunk;
              this.coverSide.lengthAC = this.lengthTrunkAC;
              this.coverSide.quantity = 2;

              this.coverRoof.purpose = "지붕+측면(양측개폐)";
              this.coverRoof.width =
                (this.widthSideOpenIncluded / switchStep + this.unitSideWidth_sideSkirtStep) * 2 +
                this.widthRoofClose * 2;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;
            }
            this.coverOther = null;
          } else if (this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
            // 지붕(고정)+측면(완전)
            if (this.switcherLevel.sidePosition.switchStep <= 1) {
              this.coverSide.purpose = "측면지붕(완전개폐)";
              this.coverSide.width =
                (this.widthRoofOpen + this.widthSideCloseIncluded) * 2 -
                (this.skirtLevel.sidePosition.skirtHeight - this.skirtLevel.roofPosition.skirtHeight) * 2;
              this.coverSide.length = this.lengthTrunk;
              this.coverSide.lengthAC = this.lengthTrunkAC;
              this.coverSide.quantity = 1;

              this.coverRoof = null;
            } else {
              this.coverSide.purpose = "측면(양측개폐)";
              this.coverSide.width = this.widthSideOpenIncluded / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverSide.length = this.lengthTrunk;
              this.coverSide.lengthAC = this.lengthTrunkAC;
              this.coverSide.quantity = 2;

              this.coverRoof.purpose = "지붕+측면(양측개폐)";
              this.coverRoof.width =
                (this.widthRoofOpen + this.widthSideOpenIncluded / switchStep) * 2 -
                (this.skirtLevel.sidePosition.skirtHeight - this.skirtLevel.roofPosition.skirtHeight) * 2;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;
            }
            this.coverOther = null;
          }
        } else if (
          this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
          this.switcherLevel.sidePosition.selectedDetail(this.coverPosition) === false
        ) {
          // 개폐방향: 지붕(개폐)+측면(고정)
          if (this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
            this.coverSide.purpose = "편측측면";
            this.coverSide.width = this.widthSideCloseSeperated;
            this.coverSide.length = this.lengthSide;
            this.coverSide.lengthAC = this.lengthSideAC;
            this.coverSide.quantity = 1;

            this.coverRoof.purpose = "지붕(편측개폐)+편측측면";
            this.coverRoof.width = this.widthSideCloseIncluded + this.widthRoofClose + this.widthRoofOpen;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 1;
          } else if (this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH) {
            this.coverSide.purpose = "측면";
            this.coverSide.width = this.widthSideCloseSeperated;
            this.coverSide.length = this.lengthSide;
            this.coverSide.lengthAC = this.lengthSideAC;
            this.coverSide.quantity = 2;

            this.coverRoof.purpose = "지붕(양측개폐)";
            this.coverRoof.width = this.widthRoofOpen * 2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 1;
          } else if (this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
            // this.coverSide = null;
            // this.coverRoof.purpose = '측면지붕(완전개폐)';
            // this.coverRoof.width = (this.widthSideOpenIncluded + this.widthRoofClose) * 2 - ((this.skirtLevel.roofPosition.skirtHeight - this.skirtLevel.sidePosition.skirtHeight) * 2);
            // this.coverRoof.length = this.lengthTrunk;
            // this.coverRoof.lengthAC = this.lengthTrunkAC;
            // this.coverRoof.quantity = 1;
          }
        } else if (
          this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
          this.switcherLevel.sidePosition.selectedDetail(this.coverPosition) === true
        ) {
          // 개폐방향: 지붕(개폐)+측면(개폐)
          if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE
          ) {
            // 지붕(편측)+측면(편측)
            this.coverSide.purpose = "편측측면";
            this.coverSide.width = this.widthSideCloseSeperated;
            this.coverSide.length = this.lengthSide;
            this.coverSide.lengthAC = this.lengthSideAC;
            this.coverSide.quantity = 1;
            if (this.switcherLevel.sidePosition.switchStep <= 1) {
              this.coverRoof.purpose = "편측측면(개폐)+지붕(편측개폐)";
              this.coverRoof.width = this.widthSideOpenIncluded + this.widthRoofClose + this.widthRoofOpen;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;

              this.coverOther = null;
            } else {
              this.coverOther = new CoverTemp();
              this.coverOther.purpose = "편측측면(개폐)";
              this.coverOther.width = this.widthSideOpenIncluded / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverOther.length = this.lengthTrunk;
              this.coverOther.lengthAC = this.lengthTrunkAC;
              this.coverOther.quantity = 1;

              this.coverRoof.purpose = "편측측면(개폐)+지붕(편측개폐)";
              this.coverRoof.width = this.widthSideOpenIncluded / switchStep + this.widthRoofClose + this.widthRoofOpen;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;
            }
          } else if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH
          ) {
            // 지붕(편측)+측면(양측)
            if (this.switcherLevel.sidePosition.switchStep <= 1) {
              this.coverSide.purpose = "편측측면(개폐)";
              this.coverSide.width = this.widthSideOpenSeperated;
              this.coverSide.length = this.lengthSide;
              this.coverSide.lengthAC = this.lengthSideAC;
              this.coverSide.quantity = 1;

              this.coverOther = null;

              this.coverRoof.purpose = "편측측면(개폐)+지붕(편측개폐)";
              this.coverRoof.width = this.widthSideOpenIncluded + this.widthRoofClose + this.widthRoofOpen;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;
            } else {
              this.coverSide.purpose = "편측측면(개폐)";
              this.coverSide.width =
                (this.widthSideOpenSeperated - this.unitSideWidth_roofSkirt) / switchStep +
                this.unitSideWidth_sideSkirtStep;
              this.coverSide.length = this.lengthSide;
              this.coverSide.lengthAC = this.lengthSideAC;
              this.coverSide.quantity = 1 * switchStep;

              this.coverOther = new CoverTemp();
              this.coverOther.purpose = "편측측면(개폐)";
              this.coverOther.width = (this.widthSideOpenSeperated - this.unitSideWidth_roofSkirt) / switchStep;
              this.coverOther.length = this.lengthSide;
              this.coverOther.lengthAC = this.lengthSideAC;
              this.coverOther.quantity = 1;

              this.coverRoof.purpose = "편측측면(개폐)+지붕(편측개폐)";
              this.coverRoof.width =
                this.widthSideOpenSeperated / switchStep + this.widthRoofClose + this.widthRoofOpen;
              this.coverRoof.length = this.lengthTrunk;
              this.coverRoof.lengthAC = this.lengthTrunkAC;
              this.coverRoof.quantity = 1;
            }
          } else if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE
          ) {
            // 지붕(양측)+측면(편측)
            if (this.switcherLevel.sidePosition.switchStep <= 1) {
              this.coverSide.purpose = "측면(편측개폐)";
              this.coverSide.width = this.widthSideCloseSeperated; // 측면고정으로 계산
              this.coverSide.length = this.lengthSide;
              this.coverSide.lengthAC = this.lengthSideAC;
              this.coverSide.quantity = 2;

              this.coverOther = null;
            } else {
              this.coverSide.purpose = "측면(편측개폐)";
              this.coverSide.width = this.widthSideCloseSeperated / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverSide.length = this.lengthSide;
              this.coverSide.lengthAC = this.lengthSideAC;
              this.coverSide.quantity = 2;

              this.coverOther = new CoverTemp();
              this.coverOther.purpose = "편측측면(개폐)";
              this.coverOther.width = this.widthSideCloseSeperated / switchStep;
              this.coverOther.length = this.lengthSide;
              this.coverOther.lengthAC = this.lengthSideAC;
              this.coverOther.quantity = 2;
            }

            this.coverRoof.purpose = "지붕(양측개폐)";
            this.coverRoof.width = this.widthRoofOpen * 2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 1;
          } else if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH
          ) {
            // 지붕(양측)+측면(양측)
            if (this.switcherLevel.sidePosition.switchStep <= 1) {
              this.coverSide.purpose = "측면(양측개폐)";
              this.coverSide.width = this.widthSideOpenSeperated;
              this.coverSide.length = this.lengthSide;
              this.coverSide.lengthAC = this.lengthSideAC;
              this.coverSide.quantity = 2;

              this.coverOther = null;
            } else {
              this.coverSide.purpose = "측면(양측개폐)";
              this.coverSide.width = this.widthSideOpenSeperated / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverSide.length = this.lengthSide;
              this.coverSide.lengthAC = this.lengthSideAC;
              this.coverSide.quantity = 2;

              this.coverOther = new CoverTemp();
              this.coverOther.purpose = "측면(양측개폐)";
              this.coverOther.width = this.widthSideOpenSeperated / switchStep;
              this.coverOther.length = this.lengthSide;
              this.coverOther.lengthAC = this.lengthSideAC;
              this.coverOther.quantity = 2;
            }

            this.coverRoof.purpose = "지붕(양측개폐)";
            this.coverRoof.width = this.widthRoofOpen * 2;
            this.coverRoof.length = this.lengthTrunk;
            this.coverRoof.lengthAC = this.lengthTrunkAC;
            this.coverRoof.quantity = 1;
          } else if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE
          ) {
            // this.coverSide.purpose = '측면(편측개폐)';
            // this.coverSide.width = this.widthSideCloseSeperated; // 측면고정으로 계산
            // this.coverSide.length = this.lengthSide;
            // this.coverSide.lengthAC = this.lengthSideAC;
            // this.coverSide.quantity = 2;
            // this.coverRoof.purpose = '측면지붕(완전개폐)';
            // this.coverRoof.width = (this.widthSideOpenIncluded + this.widthRoofClose) * 2 - ((this.skirtLevel.roofPosition.skirtHeight - this.skirtLevel.sidePosition.skirtHeight) * 2);
            // this.coverRoof.length = this.lengthTrunk;
            // this.coverRoof.lengthAC = this.lengthTrunkAC;
            // this.coverRoof.quantity = 1;
          } else if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH
          ) {
            // this.coverSide.purpose = '측면(양측개폐)';
            // this.coverSide.width = this.widthSideOpenSeperated;
            // this.coverSide.length = this.lengthSide;
            // this.coverSide.lengthAC = this.lengthSideAC;
            // this.coverSide.quantity = 2;
            // this.coverRoof.purpose = '측면지붕(완전개폐)';
            // this.coverRoof.width = (this.widthSideOpenIncluded + this.widthRoofClose) * 2 - ((this.skirtLevel.roofPosition.skirtHeight - this.skirtLevel.sidePosition.skirtHeight) * 2);
            // this.coverRoof.length = this.lengthTrunk;
            // this.coverRoof.lengthAC = this.lengthTrunkAC;
            // this.coverRoof.quantity = 1;
          } else if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL
          ) {
            this.coverSide.purpose = "측면지붕(완전개폐)";
            this.coverSide.width =
              (this.widthSideCloseSeperated + this.widthRoofOpen) * 2 -
              (this.skirtLevel.sidePosition.skirtHeight - this.skirtLevel.roofPosition.skirtHeight) * 2;
            this.coverSide.length = this.lengthTrunk;
            this.coverSide.lengthAC = this.lengthTrunkAC;
            this.coverSide.quantity = 1;

            this.coverRoof.purpose = "지붕(편측개폐)";
            this.coverRoof.width = this.widthRoofClose; // 지붕고정으로 계산
            this.coverRoof.length = this.lengthRoof;
            this.coverRoof.lengthAC = this.lengthRoofAC;
            this.coverRoof.quantity = 2;
          } else if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL
          ) {
            this.coverSide.purpose = "측면지붕(완전개폐)";
            this.coverSide.width =
              (this.widthSideCloseIncluded + this.widthRoofOpen) * 2 -
              (this.skirtLevel.sidePosition.skirtHeight - this.skirtLevel.roofPosition.skirtHeight) * 2;
            this.coverSide.length = this.lengthTrunk;
            this.coverSide.lengthAC = this.lengthTrunkAC;
            this.coverSide.quantity = 1;

            this.coverRoof.purpose = "지붕(양측개폐)";
            this.coverRoof.width = this.widthSideOpenSeperated;
            this.coverRoof.length = this.lengthSide;
            this.coverRoof.lengthAC = this.lengthSideAC;
            this.coverRoof.quantity = 2;
          } else if (
            this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL &&
            this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL
          ) {
            // this.coverSide.purpose = '측면지붕(완전개폐)';
            // this.coverSide.width = (this.widthSideCloseIncluded + this.widthRoofOpen) * 2 - ((this.skirtLevel.sidePosition.skirtHeight - this.skirtLevel.roofPosition.skirtHeight) * 2);
            // this.coverSide.length = this.lengthTrunk;
            // this.coverSide.lengthAC = this.lengthTrunkAC;
            // this.coverSide.quantity = 1;
            // this.coverRoof.purpose = '측면지붕(완전개폐)';
            // this.coverRoof.width = (this.widthSideOpenIncluded + this.widthRoofClose) * 2 - ((this.skirtLevel.roofPosition.skirtHeight - this.skirtLevel.sidePosition.skirtHeight) * 2);
            // this.coverRoof.length = this.lengthTrunk;
            // this.coverRoof.lengthAC = this.lengthTrunkAC;
            // this.coverRoof.quantity = 1;
          }
        }
      }
    } else if (this.coverPosition.scopeSelectedSide === false && this.coverPosition.scopeSelectedRoof === true) {
      // 개폐여부: 지붕(고정)
      if (this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === false) {
        this.coverSide = null;

        this.coverRoof.purpose = "지붕";
        this.coverRoof.width = this.widthRoofClose * 2;
        // + (고정) 기본 여분
        this.coverRoof.width += 0.25 * 2;
        this.coverRoof.length = this.lengthRoof;
        this.coverRoof.lengthAC = this.lengthRoofAC;
        // + (고정) 앞면 출입문 여분
        if (
          this.endpieceLevel.frontPosition.selected === true &&
          (this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
            this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_HINGED)
        ) {
          this.coverRoof.length += 0.5;
          if (this.basic.isVariableLength === true) {
            for (i = 0; i < this.coverRoof.lengthAC.length; i++) {
              this.coverRoof.lengthAC[i] += 0.5;
            }
          }
        }
        // + (고정) 뒷면 출입문 여분
        if (
          this.endpieceLevel.backPosition.selected &&
          (this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
            this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_HINGED)
        ) {
          this.coverRoof.length += 0.5;
          if (this.basic.isVariableLength === true) {
            for (i = 0; i < this.coverRoof.lengthAC.length; i++) {
              this.coverRoof.lengthAC[i] += 0.5;
            }
          }
        }
        this.coverRoof.quantity = 1;
      } else if (this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === true) {
        // 개폐방향: 지붕(개폐)
        if (this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
          this.coverSide = null;

          this.coverRoof.purpose = "지붕(편측개폐)";
          this.coverRoof.width = this.widthRoofClose + this.widthRoofOpen;
          this.coverRoof.length = this.lengthRoof;
          this.coverRoof.lengthAC = this.lengthRoofAC;
          this.coverRoof.quantity = 1;
        } else if (this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH) {
          this.coverSide = null;

          this.coverRoof.purpose = "지붕(양측개폐)";
          this.coverRoof.width = this.widthRoofOpen * 2;
          this.coverRoof.length = this.lengthRoof;
          this.coverRoof.lengthAC = this.lengthRoofAC;
          this.coverRoof.quantity = 1;
        } else if (this.switcherLevel.roofPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
          // this.coverSide = null;
          // this.coverRoof.purpose = '측면지붕(완전개폐)';
          // this.coverRoof.width = (this.widthSideOpenIncluded + this.widthRoofClose) * 2 - ((this.skirtLevel.roofPosition.skirtHeight - this.skirtLevel.sidePosition.skirtHeight) * 2);
          // this.coverRoof.length = this.lengthTrunk;
          // this.coverRoof.lengthAC = this.lengthTrunkAC;
          // this.coverRoof.quantity = 1;
        }
      }
    } else if (this.coverPosition.scopeSelectedSide === true && this.coverPosition.scopeSelectedRoof === false) {
      const switchStep = this.switcherLevel.sidePosition.switchStep;
      // 개폐여부: 측면(고정)
      if (this.switcherLevel.sidePosition.selectedDetail(this.coverPosition) === false) {
        this.coverSide.purpose = "측면";
        this.coverSide.width = this.widthSideCloseSeperated;
        this.coverSide.length = this.lengthSide;
        this.coverSide.lengthAC = this.lengthSideAC;
        this.coverSide.quantity = 2;

        this.coverRoof = null;
      } else if (this.switcherLevel.sidePosition.selectedDetail(this.coverPosition) === true) {
        // 개폐방향: 측면(개폐)
        if (this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
          this.coverSide.purpose = "측면(편측개폐)";
          this.coverSide.width = this.widthSideCloseSeperated; // 측면고정으로 계산함
          this.coverSide.length = this.lengthSide;
          this.coverSide.lengthAC = this.lengthSideAC;
          this.coverSide.quantity = 2;

          this.coverRoof = null;
        } else if (this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH) {
          if (this.switcherLevel.sidePosition.switchStep <= 1) {
            this.coverSide.purpose = "측면(양측개폐)";
            this.coverSide.width = this.widthSideOpenSeperated;
            this.coverSide.length = this.lengthSide;
            this.coverSide.lengthAC = this.lengthSideAC;
            this.coverSide.quantity = 2;

            this.coverOther = null;
          } else {
            this.coverSide.purpose = "측면(양측개폐)";
            this.coverSide.width = this.widthSideOpenSeperated / switchStep + this.unitSideWidth_sideSkirtStep;
            this.coverSide.length = this.lengthSide;
            this.coverSide.lengthAC = this.lengthSideAC;
            this.coverSide.quantity = 2;

            this.coverOther = new CoverTemp();
            this.coverSide.purpose = "측면(양측개폐)";
            this.coverSide.width = this.widthSideOpenSeperated / switchStep;
            this.coverSide.length = this.lengthSide;
            this.coverSide.lengthAC = this.lengthSideAC;
            this.coverSide.quantity = 2;
          }

          this.coverRoof = null;
        } else if (this.switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
          this.coverSide.purpose = "측면지붕(완전개폐)";
          this.coverSide.width =
            (this.widthSideCloseIncluded + this.widthRoofOpen) * 2 -
            (this.skirtLevel.sidePosition.skirtHeight - this.skirtLevel.roofPosition.skirtHeight) * 2;
          this.coverSide.length = this.lengthTrunk;
          this.coverSide.lengthAC = this.lengthTrunkAC;
          this.coverSide.quantity = 1;

          this.coverRoof = null;
        }
      }
    } else {
      this.coverSide = null;
      this.coverRoof = null;
    }

    /// /////////////////////////////////
    // 피복범위: 마구리 앞면
    if (this.coverPosition.scopeSelectedFront === true && !this.coverPosition.scopeSelectedFrontIncluded) {
      // 개폐여부: 앞면(고정)
      if (this.switcherLevel.frontPosition.selectedDetail(this.coverPosition) === false) {
        if (this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.coverFront.purpose = "앞면(전체형)";
        } else if (this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.coverFront.purpose = "앞면(상단형)";
        }
      } else if (this.switcherLevel.frontPosition.selectedDetail(this.coverPosition) === true) {
        if (this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.coverFront.purpose = "앞면(전체형/개폐)";
        } else if (this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.coverFront.purpose = "앞면(상단형/개폐)";
        }
      }
      this.coverFront.width = this.widthFront;
      this.coverFront.length = this.lengthFront * this.basic.buildingNumber;
    } else {
      this.coverFront = null;
    }

    /// /////////////////////////////////
    // 피복범위: 마구리 뒷면
    if (this.coverPosition.scopeSelectedBack === true && !this.coverPosition.scopeSelectedBackIncluded) {
      // 개폐여부: 뒷면(고정)
      if (this.switcherLevel.backPosition.selectedDetail(this.coverPosition) === false) {
        if (this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.coverBack.purpose = "뒷면(전체형)";
        } else if (this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.coverBack.purpose = "뒷면(상단형)";
        }
      } else if (this.switcherLevel.backPosition.selectedDetail(this.coverPosition) === true) {
        if (this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.coverBack.purpose = "뒷면(전체형/개폐)";
        } else if (this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.coverBack.purpose = "뒷면(상단형/개폐)";
        }
      }
      this.coverBack.width = this.widthBack;
      this.coverBack.length = this.lengthBack * this.basic.buildingNumber;
    } else {
      this.coverBack = null;
    }

    //----------------------------------
    // 추가 용도
    // 바람막이, 형태 등 추가적인 설계 용도 넣기
    //----------------------------------

    // 측면 피복 객체
    if (this.switcherLevel.sidePosition.switchStep <= 1) {
      if (this.coverSide != null && this.coverPosition.scopeSelectedDoubleCenter === false) {
        if (this.coverSide.frontIncluded === true) {
          this.coverSide.purpose += "+앞면(";
          this.coverSide.purpose += this.endpieceLevel.frontPosition.type;
          if (this.switcherLevel.frontPosition.selectedDetail(this.coverPosition) === false) {
            this.coverSide.purpose += ")";
          } else {
            this.coverSide.purpose += "/개폐)";
          }
        }
        if (this.coverSide.backIncluded === true) {
          this.coverSide.purpose += "+뒷면(";
          this.coverSide.purpose += this.endpieceLevel.backPosition.type;
          if (this.switcherLevel.backPosition.selectedDetail(this.coverPosition) === false) {
            this.coverSide.purpose += ")";
          } else {
            this.coverSide.purpose += "/개폐)";
          }
        }
        if (this.coverSide.skirtIncluded === true) {
          this.coverSide.purpose += "+지붕치마";
        }
        if (this.coverSide.windbreakIncluded === true) {
          this.coverSide.purpose += "+바람막이";
        }
      }
    }
    // 지붕 피복 객체
    if (this.coverRoof != null) {
      if (this.coverRoof.frontIncluded === true) {
        this.coverRoof.purpose += "+앞면(";
        this.coverRoof.purpose += this.endpieceLevel.frontPosition.type;
        if (this.switcherLevel.frontPosition.selectedDetail(this.coverPosition) === false) {
          this.coverRoof.purpose += ")";
        } else {
          this.coverRoof.purpose += "/개폐)";
        }
      }
      if (this.coverRoof.backIncluded === true) {
        this.coverRoof.purpose += "+뒷면(";
        this.coverRoof.purpose += this.endpieceLevel.backPosition.type;
        if (this.switcherLevel.backPosition.selectedDetail(this.coverPosition) === false) {
          this.coverRoof.purpose += ")";
        } else {
          this.coverRoof.purpose += "/개폐)";
        }
      }
      if (this.coverRoof.windbreakIncluded === true) {
        this.coverRoof.purpose += "+바람막이";
      }
    }

    // 앞면 피복 객체
    if (this.coverFront != null) {
      // 출입문
      if (this.coverFront.gateQuantity > 0) {
        this.coverFront.purpose += "+출입문";
      }
      // 바람막이
      if (this.coverFront.windbreakIncluded === true) {
        this.coverFront.purpose += "+바람막이";
      }
    }

    // 뒷면 피복 객체
    if (this.coverBack != null) {
      // 출입문
      if (this.coverBack.gateQuantity > 0) {
        this.coverBack.purpose += "+출입문";
      }
      // 바람막이
      if (this.coverBack.windbreakIncluded === true) {
        this.coverBack.purpose += "+바람막이";
      }
    }

    //----------------------------------
    // 추가 작업
    //----------------------------------

    // 앞면과 뒷면의 피복 합치기
    if (
      this.design.preference.extendFrontBackCoverFlag === true &&
      this.coverFront != null &&
      this.coverBack != null &&
      this.coverFront.width === this.coverBack.width
    ) {
      this.coverFront.purpose = `${this.coverFront.purpose}+${this.coverBack.purpose}`;
      this.coverFront.width = this.coverFront.width;
      this.coverFront.length = this.coverFront.length + this.coverBack.length;
      this.coverBack = null;
    }

    // 천창 공사로 인한 지붕 피복 나누기
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;
    if (this.level.index === skyFramePosition.skylightLevel - 1 && this.coverPosition.scopeSelectedRoof === true) {
      if (skyFramePosition.selected === true) {
        const skyCoverPosition: SkyCoverPosition = this.struct.skyCoverWork.level1.skylightPosition;
        let extraSkySkirtHeight: number;

        if (
          skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
          skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
        ) {
          extraSkySkirtHeight = skyCoverPosition.skirtHeight + 0.2;

          if (this.coverSide) {
            // 측면(완전개폐)
            this.coverSide.purpose = `(천창)${this.coverSide.purpose}`;
            this.coverSide.width = this.coverSide.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            this.coverSide.quantity = this.coverSide.quantity * 2;
          }

          if (this.coverRoof) {
            // 지붕
            this.coverRoof.purpose = `(천창)${this.coverRoof.purpose}`;
            this.coverRoof.width = this.coverRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            this.coverRoof.quantity = this.coverRoof.quantity * 2;
          }
        } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
          extraSkySkirtHeight = skyCoverPosition.skirtHeight + 0.2;

          if (this.coverSide) {
            // 측면(완전개폐) - 짧은쪽
            this.coverSideSkyShort = new CoverTemp();
            this.coverSideSkyShort.deepCopy(this.coverSide);
            this.coverSideSkyShort.purpose = `(천창)${this.coverSideSkyShort.purpose}`;
            this.coverSideSkyShort.width =
              this.coverSide.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            // 측면(완전개폐) - 긴쪽
            this.coverSide.purpose = `(천창)${this.coverSide.purpose}`;
            this.coverSide.width =
              this.coverSide.width / 2 - skyFramePosition.arcWidth / 2 + skyFramePosition.rafterPart.rafterLength + 0.3;
          }
          if (this.coverRoof) {
            // 지붕 - 짧은쪽
            this.coverRoofSkyShort = new CoverTemp();
            this.coverRoofSkyShort.deepCopy(this.coverRoof);
            this.coverRoofSkyShort.purpose = `(천창)${this.coverRoofSkyShort.purpose}`;
            this.coverRoofSkyShort.width =
              this.coverRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            // 지붕 - 긴쪽
            this.coverRoof.purpose = `(천창)${this.coverRoof.purpose}`;
            this.coverRoof.width =
              this.coverRoof.width / 2 - skyFramePosition.arcWidth / 2 + skyFramePosition.rafterPart.rafterLength + 0.3;
          }
        } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH) {
          if (this.coverSide) {
            // 측면(완전개폐)
            this.coverSide.purpose = `(천창)${this.coverSide.purpose}`;
            this.coverSide.width = this.coverSide.width / 2 - skyFramePosition.arcWidth / 2;
            this.coverSide.quantity = this.coverSide.quantity * 2;
          }

          if (this.coverRoof) {
            // 지붕
            this.coverRoof.purpose = `(천창)${this.coverRoof.purpose}`;
            this.coverRoof.width = this.coverRoof.width / 2 - skyFramePosition.arcWidth / 2;
            this.coverRoof.quantity = this.coverRoof.quantity * 2;
          }
        } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE) {
          if (this.coverSide) {
            // 측면(완전개폐) - 짧은쪽
            this.coverSideSkyShort = new CoverTemp();
            this.coverSideSkyShort.deepCopy(this.coverSide);
            this.coverSideSkyShort.purpose = `(천창)${this.coverSideSkyShort.purpose}`;
            this.coverSideSkyShort.width = this.coverSide.width / 2 - skyFramePosition.arcWidth / 2;
            // 측면(완전개폐) - 긴쪽
            this.coverSide.purpose = `(천창)${this.coverSide.purpose}`;
            this.coverSide.width = this.coverSide.width / 2;
          }
          if (this.coverRoof) {
            // 지붕 - 짧은쪽
            this.coverRoofSkyShort = new CoverTemp();
            this.coverRoofSkyShort.deepCopy(this.coverRoof);
            this.coverRoofSkyShort.purpose = `(천창)${this.coverRoofSkyShort.purpose}`;
            this.coverRoofSkyShort.width = this.coverRoof.width / 2 - skyFramePosition.arcWidth / 2;
            // 지붕 - 긴쪽
            this.coverRoof.purpose = `(천창)${this.coverRoof.purpose}`;
            this.coverRoof.width = this.coverRoof.width / 2;
          }
        } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_CUSTOM) {
          extraSkySkirtHeight = 0.3;

          if (this.coverSide) {
            // 측면(완전개폐)
            this.coverSide.purpose = `(천창)${this.coverSide.purpose}`;
            this.coverSide.width = this.coverSide.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            this.coverSide.quantity = this.coverSide.quantity * 2;
          }
          if (this.coverRoof) {
            // 지붕
            this.coverRoof.purpose = `(천창)${this.coverRoof.purpose}`;
            this.coverRoof.width = this.coverRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            this.coverRoof.quantity = this.coverRoof.quantity * 2;
          }
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
