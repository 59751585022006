import { jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { EndpiecePartVL_Endpiece } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePartVL_Endpiece";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class EndpieceSampleVL_BarPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }
  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////
    const endpiecePart: EndpiecePartVL_Endpiece = <EndpiecePartVL_Endpiece>this.part;
    endpiecePart.algorithmSpec_bar_pipe();
    endpiecePart.algorithmSpec_studAndRafterCenter_tHolder();
    endpiecePart.algorithmSpec_studAndRafterCenterEndpiece_normalHolder();
    endpiecePart.algorithmSpec_studAndRafterDiagonal_diagonalTHolder();
    endpiecePart.algorithmSpec_studAndRafterDiagonal_diagonalTClamp();
    endpiecePart.algorithmSpec_studAndRafterDiagonalEndpiece_normalHolder();
    endpiecePart.algorithmSpec_barAndMiddle_saddleHolder();
    endpiecePart.algorithmSpec_studAndWidthGirth_saddleHolder();
    endpiecePart.algorithmSpec_studAndWidthGirth_fixAsibaClamp();
    endpiecePart.algorithmSpec_straightBottomAndEndpieceBarBottom_crossClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    // (<EndpiecePositionIL> this.position).prismMiddlePart.middleBottomPipeSample.algorithmSpec();
    // (<EndpiecePositionIL> this.position).prismMiddlePart.algorithmSpec_middleBottomAndEndpieceStudBottom_crossClamp();

    // 다른 위치
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   (<EndpieceLevelIL> this.level).backPosition.prismStudSepPart.studBottomPipeSample.specs = this.specs;
    // }

    /// //////// 외부 ///////////

    // 피복 고정
    // const fixingLevel: FixingLevelIL = <FixingLevelIL> this.struct.fixingWorkIL.levelAC[this.level.index];
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
    // } else if (this.position.label === CONST.LB_POSITION_BACK) {
    //   fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
    // }

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      skirtLevel.frontPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      skirtLevel.backPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    }

    // 바람막이 피복
    const windbreakLevel: WindbreakLevelIL = <WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      windbreakLevel.frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      windbreakLevel.backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    }

    // 개폐기
    const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];
    switcherLevel.columnPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.wingPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.roofPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.columnPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
    switcherLevel.wingPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
    switcherLevel.roofPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 지붕 서까래 파이프
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specsList;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
