import { jsonObject } from "typedjson";

import { ItemWeavingFilm } from "vhows-design/src/object/design/item/list/ItemWeavingFilm";
import { CoverPart_WeavingFilm } from "vhows-design/src/object/design/cover/cover/CoverPart_WeavingFilm";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-02
 */
@jsonObject
export class CoverSample_WeavingFilm extends ItemWeavingFilm {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const weavingFilmPart: CoverPart_WeavingFilm = <CoverPart_WeavingFilm>this.part;
    weavingFilmPart.algorithmSpec_item();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
