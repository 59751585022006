import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { ColumnPartVL_Beam } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Beam";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 오은헤
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class ColumnSampleVL_GutterBeamPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismBeamPart: ColumnPartVL_Beam = <ColumnPartVL_Beam>this.part;
    prismBeamPart.algorithmSpec_beam_gutterBeam();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    /// //////// 외부 ///////////
    //
    // if (this.position.type === CONST.LB_COLUMN_TYPE_PRISM) {
    //   // 지붕 골조
    //   for (const roofLevel of this.struct.roofWorkIL.levelAC as RoofLevelVL[]) { // 모든 중수
    //     roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce();
    //     roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamEdgePrism_outerWallClamp();
    //   }
    //
    //   // 마구리 골조
    //   for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) { // 모든 중수
    //     for (const endpiecePosition of endpieceLevel.positionAC as EndpiecePositionIL[]) {
    //       endpiecePosition.prismMiddlePart.middleBottomPipeSample.algorithmSpec_specLength();
    //     }
    //   }
    //
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    // this.specsList = (<ColumnPositionVL> this.position).prismColumnPart.columnPipeSample.specsList;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
