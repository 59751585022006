import { jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Address } from "vhows-design/src/object/common/Address";
import { Period } from "vhows-design/src/object/common/Period";
import { Company } from "vhows-design/src/object/company/Company";
import { Customer } from "vhows-design/src/object/customer/Customer";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { StructIL } from "vhows-design/src/object/design/base/StructIL";
import { StructOT } from "vhows-design/src/object/design/base/StructOT";
import { StructSG } from "vhows-design/src/object/design/base/StructSG";
import { StructVL } from "vhows-design/src/object/design/base/StructVL";
import { Basic } from "vhows-design/src/object/design/basic/Basic";
import { Preference } from "vhows-design/src/object/preference/Preference";
import { SubsidyService } from "vhows-design/src/object/design/basic/SubsidyService";
import { Estimate } from "vhows-design/src/object/design/estimate/Estimate";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";
import { Analysis } from "vhows-design/src/object/analysis/Analysis";
import { Review } from "vhows-design/src/object/design/review/Review";
import { BusinessUser } from "vhows-design/src/object/user/BusinessUser";
import { Material } from "vhows-design/src/object/design/material/Material";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-01-07
 */
@jsonObject({
  knownTypes: [
    ItemModel,
    Preference,
    Basic,
    SubsidyService,
    Struct,
    Estimate,
    Company,
    BusinessUser,
    Customer,
    Address,
    Period,
    StructSG,
    StructIL,
    StructOT,
    StructVL,
    ItemModel,
    Company,
    BusinessUser,
    Customer,
    Review,
    Analysis,
    Review,
    Basic,
    Estimate,
    SubsidyService,
    Preference,
  ],
})
export class Design extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 단동 구조
  public single: StructSG = null;
  // 연동 구조
  public interlock: StructIL = null;
  // 벤로 구조
  public venlo: StructVL = null;
  // 기타 구조
  public other: StructOT = null;

  // 구조검토 객체
  public analysis: Analysis = null;
  // 품목검토 객체
  public review: Review = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 계약 완료 가능 여부
  public contractEnabled: boolean = true;
  // 계약 완료 저장 여부
  public isSaveForContract: boolean = false;
  // 저장 가능 여부
  public saveEnabled: boolean = true;
  // 수정 가능 여부
  public editEnabled: boolean = true;
  // 현재 화면상에서 선택된 중수 인덱스
  public currentLevelIndex: number = 0;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 품목 모델
  @jsonMember(ItemModel, { serializer: value => undefined })
  public itemModel: ItemModel = null;
  // 설정 객체
  @jsonMember(Preference, { serializer: value => undefined })
  public preference: Preference = null;

  // 기본정보
  @jsonMember(Basic)
  public basic: Basic = null;
  // 정부사업정보
  @jsonMember(SubsidyService)
  public subsidyService: SubsidyService = new SubsidyService();
  // 구조
  @jsonMember(Struct)
  public struct: Struct = null;
  // 견적
  @jsonMember(Estimate)
  public estimate: Estimate = null;
  // 자재
  public material: Material = null;

  // 조직
  @jsonMember(Company)
  public company: Company = null;
  // 견적자
  @jsonMember(BusinessUser)
  public estimator: BusinessUser = null;
  // 고객
  @jsonMember(Customer)
  public customer: Customer = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 설계

  // 설계번호 : 사용자에게 보여주기 위한 설계 아이디(아직 사용안함)
  @jsonMember(String)
  public designCode: string = null;
  // 제목
  @jsonMember(String)
  public title: string = null;
  // 용도/작물
  @jsonMember(String)
  public usageAndCrop: string = null;
  // 노트
  @jsonMember(String)
  public note: string = null;
  // 템플릿명
  @jsonMember(String)
  public templateName: string = null;
  // 템플릿 설명
  @jsonMember(String)
  public templateExplain: string = null;
  // 템플릿 이미지
  @jsonMember(String)
  public templateImg: string = null;
  // 진행 상태
  @jsonMember(String)
  public progressStatus: string = null;
  // 설계 상태
  private _designStatus: string = null;
  /**
   * 설계 상태
   */
  @jsonMember(String)
  public get designStatus(): string {
    return this._designStatus;
  }

  public set designStatus(value: string) {
    this._designStatus = value;

    if (this._designStatus === CONST.LB_DESIGN_STATUS_WRITE) {
      this.editEnabled = true;
    } else {
      this.editEnabled = false;
    }
  }

  // 삭제 여부
  @jsonMember(Boolean)
  public isDeleted: boolean = false;
  // 템플릿 여부
  @jsonMember(Boolean)
  public isTemplate: boolean = false;
  // 기본 템플릿 여부
  @jsonMember(Boolean)
  public isDefault: boolean = false;
  // 부분견적 여부
  @jsonMember(Boolean)
  public isSectionEstimate: boolean = false;
  // 표기 형식
  @jsonMember(String)
  public markType: string = null;
  /** 표기 날짜 */
  @jsonMember(Date)
  public markDate: Date = null;
  /** 공개 날짜. */
  @jsonMember(Date)
  public openDate: Date = null;

  // 공사

  // 현장 주소.
  @jsonMember(Address)
  public buildingAddress: Address = new Address();
  // 공사 기간.
  @jsonMember(Period)
  public buildingPeriod: Period = new Period();
  // 차량진입여부
  @jsonMember(String)
  public vehicleEntry: string = null;
  // 전기가용여부
  @jsonMember(String)
  public electricAvailable: string = null;
  // 지반평탄 여부
  @jsonMember(String)
  public groundFlat: string = null;

  // 시스템

  // 생성자
  @jsonMember(String)
  public creator: string = null;
  // 설계 수정자
  @jsonMember(String)
  public designUpdater: string = null;
  // 최종 변경자
  @jsonMember(String)
  public lastUpdater: string = null;
  // 최종 변경일시 : 설계, 견적 등 관련된 모든 데이터에 대한 가장 최근의 변경 일시
  @jsonMember(Date)
  public lastUpdate: Date = null;
  // DB 버전
  @jsonMember(String)
  public dbVersion: string = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor(
    subsidyService: SubsidyService = null,
    estimate: Estimate = null,
    buildingAddress: Address = null,
    buildingPeriod: Period = null,
    material: Material = null,
  ) {
    super();
    this.subsidyService = subsidyService;
    this.estimate = estimate;
    this.buildingAddress = buildingAddress;
    this.buildingPeriod = buildingPeriod;
    this.material = material;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(): void {
    // 설정
    if (this.preference) {
      this.preference.setAssociation(this);
    }

    // 기본정보
    if (this.basic) {
      this.basic.setAssociation(this);
    }

    // 정부사업
    if (this.subsidyService) {
      this.subsidyService.setAssociation(this);
    }

    // 구조
    if (this.struct) {
      this.struct.setAssociation(this);
    }

    // 견적
    if (this.estimate) {
      this.estimate.setAssociation(this);
    }

    if (this.material) {
      this.material.setAssociation(this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    if (this.struct) {
      // 구조
      if (this.struct instanceof StructSG) {
        this.single = <StructSG>this.struct;
        this.interlock = null;
        this.venlo = null;
        this.other = null;
      } else if (this.struct instanceof StructIL) {
        this.interlock = <StructIL>this.struct;
        this.single = null;
        this.venlo = null;
        this.other = null;
      } else if (this.struct instanceof StructVL) {
        this.venlo = <StructVL>this.struct;
        this.interlock = null;
        this.single = null;
        this.other = null;
      } else {
        this.other = <StructOT>this.struct;
        this.single = null;
        this.interlock = null;
        this.venlo = null;
      }
      this.struct.setReferenceVariable();
    }

    // 견적
    if (this.estimate) {
      this.estimate.setReferenceVariable();
    }
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {
    this.designCode = null;
    this.note = "";
    this.progressStatus = CONST.LB_PROGRESS_STATUS_DESIGNING_ESTIMATING;
    this.designStatus = CONST.LB_DESIGN_STATUS_WRITE;

    // 구조
    this.struct.setDefaultData();
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    this.currentLevelIndex = 0; // 현재 중수 인덱스
    this.setSaveEnabled(); // 저장 가용성 설정
    this.setContractEnabled(); // 계약완료 가용성 설정

    // 기본정보
    if (this.basic) {
      this.basic.setDefaultVariable();
    }
    // 구조
    if (this.struct) {
      this.struct.setDefaultVariable();
    }

    // 설계 객체가 새로 만들어지던, 불러오던 사용되는 객체로,
    // DB에 저장되지 않기 때문에 초기화시에 생성해줌
    this.analysis = new Analysis(this);
    this.review = new Review(this);
  }

  /**
   * 기본 모델 설정: 데이터베이스를 대신함
   */
  public setDefaultModel(): void {
    // 기본정보
    this.basic.setDefaultModel();

    // 구조
    this.struct.setDefaultModel();
  }

  /**
   * 저장된 아이디값 세팅
   */
  public setSavedId(savedDesign: Design): void {
    if (savedDesign) {
      this.id = savedDesign.id;

      // 기본정보
      if (this.basic && savedDesign.basic) {
        this.basic.setSavedId(savedDesign.basic);
      }

      // 정부사업
      if (this.subsidyService && savedDesign.subsidyService) {
        this.subsidyService.id = savedDesign.subsidyService.id;
      }

      // 구조
      if (this.struct && savedDesign.struct) {
        this.struct.setSavedId(savedDesign.struct);
      }

      // 견적
      if (this.estimate && savedDesign.estimate) {
        this.estimate.setSavedId(savedDesign.estimate);
      }
    }
  }

  /**
   * 최신 객체로 복원
   */
  public restoreLatestObject(): void {
    // 기본정보
    this.basic.restoreLatestObject(this);

    // 구조
    this.struct.restoreLatestObject(this);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  public algorithmBasic(): void {
    // 기본정보
    this.basic.algorithmBasic();

    // 구조
    this.struct.algorithmBasic();
  }

  /**
   * 저장관련 가용성 설정
   */
  public setSaveEnabled(): void {
    // 설계 상태에 따른 설정
    if (this.designStatus === CONST.LB_DESIGN_STATUS_WRITE) {
      this.saveEnabled = true;
    } else {
      this.saveEnabled = false;
    }
  }

  /**
   * 계약완료 가용성 설정
   */
  public setContractEnabled(): void {
    // 설계 상태에 따른 설정
    if (this.designStatus === CONST.LB_DESIGN_STATUS_WRITE) {
      // 고객여부에 따른 설정
      if (this.customer) {
        this.contractEnabled = true;
      } else {
        this.contractEnabled = false;
      }
    } else {
      this.contractEnabled = false;
    }
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  /**
   * 새로운 견적 만들기
   */
  public newEstimate(): void {
    this.estimate = new Estimate(this);
  }

  /**
   * 견적서 열기
   */
  public openEstimate() {
    if (this.review.reviewOK()) {
      // 부적합 품목이 없는 경우
      if (this.designStatus === CONST.LB_DESIGN_STATUS_WRITE) {
        // 설계상태가 쓰기일 경우
        let estimateFlag: boolean;
        if (this.estimate == null) {
          // 견적을 처음할 경우, 새로운 견적 생성하기
          this.newEstimate();
          estimateFlag = this.estimate.makeEstimateFromDesign(this);
          this.estimate.openMessage = CONST.TEXT_CREATED_FIRST_PRICE_ESTIMATE;
        } else {
          // 저장된 견적이 있을 경우, 견적서 만들기
          estimateFlag = this.estimate.makeEstimateFromDesign(this, this.estimate.totalPrice);
          if (estimateFlag) {
            this.estimate.openMessage = CONST.TEXT_MAKE_ESTIMATE_CHANGED;
          } else {
            this.estimate.openMessage = CONST.TEXT_MAKE_ESTIMATE_NO_CHANGED;
          }
        }
        this.estimate.popupVisible = true;
      } else {
        // 설계상태가 쓰기가 아닌 경우 (읽기전용, 삭제 등)
        if (this.estimate) {
          this.estimate.loadEstimateReadOnly(this);
          this.estimate.popupVisible = true;
          this.estimate.openMessage = CONST.TEXT_LOAD_COMPLATED_ESTIMATE;
        } else {
          this.estimate.openMessage = CONST.TEXT_NOT_EXIST_ESTIMATE;
        }
      }
    } else {
      // 부적합 품목이 있는 경우
      this.review.modeSelectedIndex = CONST.ID_REVIEW_UNFIT;
      this.review.openMessage = CONST.TEXT_EXIST_UNFIT_ITEM_NEEDED_REVIEW;
      this.review.popupVisible = true;
    }
  }

  /**
   * 자재내역 열기
   */
  public openMaterial() {
    if (this.material == null) {
      this.newMaterial();
    }
    this.material.makeMaterialFromEstimate(this, true);
    this.material.openMessage = CONST.TEXT_MAKE_MATERIAL;
    this.material.popupVisible = true;
  }

  //----------------------------------
  // 하우스 자재
  //----------------------------------

  /**
   * 새로운 자재내역 만들기
   */
  public newMaterial(): void {
    this.material = new Material(this, this.estimate);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
