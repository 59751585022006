import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";
import { ProductCompanyModel } from "vhows-design/src/object/item/product/ProductCompanyModel";
import { CategoryModel } from "vhows-design/src/object/item/category/CategoryModel";

/**
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject({
  knownTypes: [CategoryModel, ProductCompanyModel],
})
export class CategoryCompanyModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 품목 모델 */
  public itemModel: ItemModel = null;

  // 골조 자재 > 파이프
  public pipeProductModel: ProductCompanyModel = null;
  public cccSectionSteelProductModel: ProductCompanyModel = null;
  public gutterBeamProductModel: ProductCompanyModel = null;
  public roundBarProductModel: ProductCompanyModel = null;
  // 골조 자재 > 보조 파이프
  public middleSupportPipeProductModel: ProductCompanyModel = null;
  public uBendingTubeRailProductModel: ProductCompanyModel = null;
  // 골조 자재 > 철근
  public steelBarProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 브라켓트
  public bracketProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 연결구
  public connectionPinProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 고정구
  public normalHolderProductModel: ProductCompanyModel = null;
  public steelStringGrabberProductModel: ProductCompanyModel = null;
  public steelSheetGrabberProductModel: ProductCompanyModel = null;
  public crossSteelSheetGrabberProductModel: ProductCompanyModel = null;
  public lineSheetGrabberProductModel: ProductCompanyModel = null;
  public uclampProductModel: ProductCompanyModel = null;
  public saddleHolderProductModel: ProductCompanyModel = null;
  public fixAsibaClampProductModel: ProductCompanyModel = null;
  public rotationAsibaClampProductModel: ProductCompanyModel = null;
  public crossClampProductModel: ProductCompanyModel = null;
  public outerWallClampProductModel: ProductCompanyModel = null;
  public tholderProductModel: ProductCompanyModel = null;
  public tclampProductModel: ProductCompanyModel = null;
  public diagonalTHolderProductModel: ProductCompanyModel = null;
  public diagonalTClampProductModel: ProductCompanyModel = null;
  public gutterBeamHolderProductModel: ProductCompanyModel = null;
  public middlePairClampProductModel: ProductCompanyModel = null;
  public middleSoleClampProductModel: ProductCompanyModel = null;
  public middleYClampProductModel: ProductCompanyModel = null;
  public middleHalfYClampProductModel: ProductCompanyModel = null;
  public hookHolderProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 꽂이
  public innerTHolderProductModel: ProductCompanyModel = null;
  public firstLevelSoleSconceProductModel: ProductCompanyModel = null;
  public firstLevelPairSconceProductModel: ProductCompanyModel = null;
  public secondLevelSoleSconceProductModel: ProductCompanyModel = null;
  public secondLevelPairSconceProductModel: ProductCompanyModel = null;
  public connectionSkylightSetProductModel: ProductCompanyModel = null;
  public archSconceProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 다이아몬드 클램프
  public diamondClampProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 문짝
  public doorProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 문짝(기성품)
  public doorMadeProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 여닫이 문
  public hingedDoorProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 샷시창문
  public sashDoorProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 롤러
  public csectionSteelRollerProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 시멘트
  public concreteProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 나사
  public screwProductModel: ProductCompanyModel = null;
  // 골조 부속 자재 > 골조 기타
  public boltAndNutProductModel: ProductCompanyModel = null;
  public hingeProductModel: ProductCompanyModel = null;
  public knobProductModel: ProductCompanyModel = null;
  public stakeProductModel: ProductCompanyModel = null;
  public longBoltProductModel: ProductCompanyModel = null;
  public tackerPinProductModel: ProductCompanyModel = null;
  public filmBandProductModel: ProductCompanyModel = null;
  public wireMeshProductModel: ProductCompanyModel = null;
  public turnbuckleProductModel: ProductCompanyModel = null;

  // 물받이 자재 > 철판 물받이
  public ironPlateGutterProductModel: ProductCompanyModel = null;
  // 물받이 자재 > 플라스틱 물받이
  public plasticGutterProductModel: ProductCompanyModel = null;
  // 물받이 자재 > 유바
  public ubarProductModel: ProductCompanyModel = null;
  // 물받이 자재 > 물받이 걸이
  public gutterHangerProductModel: ProductCompanyModel = null;

  // 개폐기 자재 > 수동 개폐기
  public manualSwitcherProductModel: ProductCompanyModel = null;
  // 개폐기 자재 > 동력 개폐기
  public powerSwitcherProductModel: ProductCompanyModel = null;
  // 개폐기 자재 > 천창 모터
  public skylightMotorProductModel: ProductCompanyModel = null;
  // 개폐기 자재 > 천창 세트
  public rackpinionSetProductModel: ProductCompanyModel = null;
  public ventpipepadSetProductModel: ProductCompanyModel = null;
  // 개폐기 자재 > 전선
  public electricWireProductModel: ProductCompanyModel = null;
  // 개폐기 자재 > 개폐기 보조
  public axisRollerProductModel: ProductCompanyModel = null;
  public axisCouplingProductModel: ProductCompanyModel = null;
  public supportCouplingProductModel: ProductCompanyModel = null;
  public supportFixingPinProductModel: ProductCompanyModel = null;
  public longBoltHolderProductModel: ProductCompanyModel = null;
  // 개폐기 자재 > 통합 제어기
  public integratedControllerProductModel: ProductCompanyModel = null;
  // 개폐기 자재 > 제어기 센서
  public controllerTimerProductModel: ProductCompanyModel = null;
  public controllerTempSensorProductModel: ProductCompanyModel = null;
  public controllerHeatSensorProductModel: ProductCompanyModel = null;
  public controllerTempHumSensorProductModel: ProductCompanyModel = null;
  public controllerRaindropSensorProductModel: ProductCompanyModel = null;
  public controllerWindSensorProductModel: ProductCompanyModel = null;
  public controllerInsolationSensorProductModel: ProductCompanyModel = null;
  // 개폐기 자재 > 개폐기 기타
  public switcherProtectionRubberProductModel: ProductCompanyModel = null;

  // 피복 자재 > 비닐
  public vinylProductModel: ProductCompanyModel = null;
  // 피복 자재 > 직조 필름
  public weavingFilmProductModel: ProductCompanyModel = null;
  // 피복 자재 > 차광망
  public shadenetProductModel: ProductCompanyModel = null;
  // 피복 자재 > 텐텐지
  public tentProductModel: ProductCompanyModel = null;
  // 피복 자재 > 부직포
  public nonwovenProductModel: ProductCompanyModel = null;
  // 피복 자재 > 카시미론
  public cashmilonProductModel: ProductCompanyModel = null;
  // 피복 자재 > 칼라 보온덮개
  public colorLaggingProductModel: ProductCompanyModel = null;
  // 피복 자재 > 보온덮개
  public laggingProductModel: ProductCompanyModel = null;
  // 피복 자재 > 다겹 커튼지
  public quiltingProductModel: ProductCompanyModel = null;
  // 피복 자재 > 스크린
  public screenProductModel: ProductCompanyModel = null;
  // 피복 자재 > 마트
  public matProductModel: ProductCompanyModel = null;
  // 피복 자재 > 방충망
  public screennetProductModel: ProductCompanyModel = null;

  // 피복 부속 자재 > 패드
  public normalPadProductModel: ProductCompanyModel = null;
  public laggingPadProductModel: ProductCompanyModel = null;
  public gutterPadProductModel: ProductCompanyModel = null;
  public condensationPadProductModel: ProductCompanyModel = null;
  public normalPinnedPadProductModel: ProductCompanyModel = null;
  public barPadProductModel: ProductCompanyModel = null;
  // 피복 부속 자재 > 패드 연결구
  public padConnectionPinProductModel: ProductCompanyModel = null;
  public condensationPadConnectionPinProductModel: ProductCompanyModel = null;
  // 피복 부속 자재 > 패드 스프링
  public normalPadSpringProductModel: ProductCompanyModel = null;
  public laggingPadSpringProductModel: ProductCompanyModel = null;
  // 피복 부속 자재 > 패드 비닐
  public padVinylProductModel: ProductCompanyModel = null;
  // 피복 부속 자재 > 클립
  public skirtClipProductModel: ProductCompanyModel = null;
  public normalClipProductModel: ProductCompanyModel = null;
  public hangerClipProductModel: ProductCompanyModel = null;
  public slopeClipProductModel: ProductCompanyModel = null;
  // 피복 부속 자재 > 목재
  public rectLumberProductModel: ProductCompanyModel = null;
  // 피복 부속 자재 > 밴드끈
  public normalBandstringProductModel: ProductCompanyModel = null;
  public polyBandstringProductModel: ProductCompanyModel = null;
  public vinylBandProductModel: ProductCompanyModel = null;
  public netBandProductModel: ProductCompanyModel = null;
  public weavingBandProductModel: ProductCompanyModel = null;
  // 피복 부속 자재 > 강선
  public steelStringWireProductModel: ProductCompanyModel = null;

  // 환기 자재 > 환풍기
  public ventilatorProductModel: ProductCompanyModel = null;
  // 환기 자재 > 환기팬
  public ventFanProductModel: ProductCompanyModel = null;
  // 환기 자재 > 환기구
  public ventHoleProductModel: ProductCompanyModel = null;
  // 환기 자재 > 환기구 개폐 세트
  public ventHoleSwitchSetProductModel: ProductCompanyModel = null;
  // 환기 자재 > 공기 유동팬
  public airFlowFanProductModel: ProductCompanyModel = null;

  // 커튼 자재 > 감속 모터
  public reducedMotorProductModel: ProductCompanyModel = null;
  // 커튼 자재 > 베어링
  public bearingSetProductModel: ProductCompanyModel = null;
  // 커튼 자재 > 와인딩 드럼
  public windingDrumProductModel: ProductCompanyModel = null;
  // 커튼 자재 > 와이어 인장
  public wireStrainerProductModel: ProductCompanyModel = null;
  // 커튼 자재 > 트러스 클립
  public trussClipProductModel: ProductCompanyModel = null;
  // 커튼 자재 > 예인 클립
  public stickTowClipProductModel: ProductCompanyModel = null;
  public hookTowClipProductModel: ProductCompanyModel = null;
  // 커튼 자재 > 커튼 기타
  public towRollerProductModel: ProductCompanyModel = null;
  public guideRollerProductModel: ProductCompanyModel = null;
  public rollerHookProductModel: ProductCompanyModel = null;
  public screenPincerProductModel: ProductCompanyModel = null;

  // 관수 자재 > 양액시스템
  public nutrientSolutionProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관비기
  public waterSolutionProductModel: ProductCompanyModel = null;
  // 관수 자재 > 물탱크
  public waterTankProductModel: ProductCompanyModel = null;
  // 관수 자재 > 물탱크 피팅
  public waterTankFittingProductModel: ProductCompanyModel = null;
  // 관수 자재 > 물탱크 조절기
  public nutrientMixerProductModel: ProductCompanyModel = null;
  public waterLevelControllerProductModel: ProductCompanyModel = null;
  // 관수 자재 > 펌프
  public waterPumpProductModel: ProductCompanyModel = null;
  // 관수 자재 > 펌프 연결
  public waterPumpConnectProductModel: ProductCompanyModel = null;
  // 관수 자재 > 펌프 호스
  public waterPumpHoseProductModel: ProductCompanyModel = null;
  // 관수 자재 > 펌프 조절기
  public waterPumpControllerProductModel: ProductCompanyModel = null;
  // 관수 자재 > 여과기
  public waterFilterProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관수관
  public pePipeProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관수 밸브
  public electricValveProductModel: ProductCompanyModel = null;
  public unionValveProductModel: ProductCompanyModel = null;
  public femaleBallValveProductModel: ProductCompanyModel = null;
  public maleBallValveProductModel: ProductCompanyModel = null;
  public femaleMaleBallValveProductModel: ProductCompanyModel = null;
  public femaleMaleValveProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관수 연결
  public nippleProductModel: ProductCompanyModel = null;
  public socketFemaleProductModel: ProductCompanyModel = null;
  public differentSocketFemaleProductModel: ProductCompanyModel = null;
  public connectSocketProductModel: ProductCompanyModel = null;
  public differentConnectSocketProductModel: ProductCompanyModel = null;
  public elbowProductModel: ProductCompanyModel = null;
  public maleElbowSocketProductModel: ProductCompanyModel = null;
  public femaleElbowSocketProductModel: ProductCompanyModel = null;
  public exactTProductModel: ProductCompanyModel = null;
  public differentTProductModel: ProductCompanyModel = null;
  public bushingProductModel: ProductCompanyModel = null;
  public valveSocketProductModel: ProductCompanyModel = null;
  public pePipeEndProductModel: ProductCompanyModel = null;
  public waterScreenConnectSocketProductModel: ProductCompanyModel = null;
  public waterScreenElbowSocketProductModel: ProductCompanyModel = null;
  public pePipeSaddleProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관수 걸이
  public pePipeHangerProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관수 튜브
  public tubeProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관수 튜브 니플
  public tubeNippleProductModel: ProductCompanyModel = null;
  // 관수 자재 > 미니 스프링클러
  public miniSprinklerProductModel: ProductCompanyModel = null;
  // 관수 자재 > 미니 스프링클러 낙수방지기
  public miniSprinklerDropGuardProductModel: ProductCompanyModel = null;
  // 관수 자재 > 미니 스프링클러 튜브셋
  public miniSprinklerTubeSetProductModel: ProductCompanyModel = null;
  // 관수 자재 > 점적관
  public dripPipeProductModel: ProductCompanyModel = null;
  // 관수 자재 > 점적단추
  public dripButtonProductModel: ProductCompanyModel = null;
  // 관수 자재 > 점적단추 부속
  public dripButtonManyHoldProductModel: ProductCompanyModel = null;
  public dripButtonDropperProductModel: ProductCompanyModel = null;
  // 관수 자재 > 점적 미늘
  public dripBarbProductModel: ProductCompanyModel = null;
  public dripStartSaddleProductModel: ProductCompanyModel = null;
  // 관수 자재 > 점적 밸브
  public dripLineValveProductModel: ProductCompanyModel = null;
  public dripStartLineValveProductModel: ProductCompanyModel = null;
  public dripLineValveSupportProductModel: ProductCompanyModel = null;
  // 관수 자재 > 점적 연결
  public dripConnectSocketProductModel: ProductCompanyModel = null;
  public dripSupportEndProductModel: ProductCompanyModel = null;
  public ldConnectProductModel: ProductCompanyModel = null;
  public ldElbowProductModel: ProductCompanyModel = null;
  public ldExactTProductModel: ProductCompanyModel = null;
  public ldEndProductModel: ProductCompanyModel = null;
  // 관수 자재 > 분수호스
  public fountainHoseProductModel: ProductCompanyModel = null;
  // 관수 자재 > 분수호스 연결
  public fountainHoseConnectProductModel: ProductCompanyModel = null;
  public fountainHoseCrossConnectProductModel: ProductCompanyModel = null;
  public fountainHoseEndProductModel: ProductCompanyModel = null;
  public fountainHoseTValveProductModel: ProductCompanyModel = null;
  // 관수 자재 > 분수호스 걸이
  public fountainHoseHangerScreenProductModel: ProductCompanyModel = null;
  public fountainHoseHangerUpperProductModel: ProductCompanyModel = null;
  public fountainHoseHangerSideProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관수 공구
  public pePipeFastenerProductModel: ProductCompanyModel = null;
  public pePipePuncherProductModel: ProductCompanyModel = null;
  // 관수 자재 > 관수 기타
  public dripAlignmentPinProductModel: ProductCompanyModel = null;
  public wateringSpringProductModel: ProductCompanyModel = null;

  // 배수 자재 > 배수관
  public pleatedPipeProductModel: ProductCompanyModel = null;
  public pvcPipeProductModel: ProductCompanyModel = null;
  // 배수 자재 > PVC 밸브
  public pvcFemaleBallValveProductModel: ProductCompanyModel = null;
  // 배수 자재 > PVC 연결
  public pvcSocketProductModel: ProductCompanyModel = null;
  public pvcElbowProductModel: ProductCompanyModel = null;
  public pvcDgree45ElbowProductModel: ProductCompanyModel = null;
  public pvcExactTProductModel: ProductCompanyModel = null;
  public pvcDifferentTProductModel: ProductCompanyModel = null;
  public pvcValveSocketProductModel: ProductCompanyModel = null;
  public pvcEndProductModel: ProductCompanyModel = null;
  // 배수 자재 > 물받이통
  public drainspoutBoxProductModel: ProductCompanyModel = null;
  // 배수 자재 > 배관 잡이쇠
  public drainPipeHangerProductModel: ProductCompanyModel = null;

  // 재배대 자재 > 포밍 베드
  public formingBedProductModel: ProductCompanyModel = null;
  // 재배대 자재 > 스티로폼 베드
  public styrofoamBedProductModel: ProductCompanyModel = null;
  // 재배대 자재 > NFT 베드
  public nftBedProductModel: ProductCompanyModel = null;
  // 재배대 자재 > 베드 부속
  public bedEndProductModel: ProductCompanyModel = null;
  public bedDrainProductModel: ProductCompanyModel = null;
  public bedSupportClipProductModel: ProductCompanyModel = null;
  public bedHolderProductModel: ProductCompanyModel = null;
  public nftSupplyDrainSetProductModel: ProductCompanyModel = null;
  public sinkGuardPlateProductModel: ProductCompanyModel = null;
  // 재배대 자재 > 바닥지
  public groundCoverProductModel: ProductCompanyModel = null;
  // 재배대 자재 > 바닥지 부속
  public sackPinProductModel: ProductCompanyModel = null;
  // 재배대 자재 > 유인줄 부속
  public trellisHangerProductModel: ProductCompanyModel = null;
  public trellisRoundBarProductModel: ProductCompanyModel = null;

  // 운반 자재 > 운반구
  public upperCarrierProductModel: ProductCompanyModel = null;
  public sideCarrierProductModel: ProductCompanyModel = null;
  // 운반 자재 > 지면 운반구
  public groundCarrierProductModel: ProductCompanyModel = null;
  // 운반 자재 > 운반 고리
  public railHookProductModel: ProductCompanyModel = null;
  public secondLevelRailHookProductModel: ProductCompanyModel = null;
  public sideRailHookProductModel: ProductCompanyModel = null;
  // 운반 자재 > 지면 운반구
  public railIntervalProductModel: ProductCompanyModel = null;
  public railSupportPipeProductModel: ProductCompanyModel = null;

  // 냉난방 자재 > 보일러
  public boilerProductModel: ProductCompanyModel = null;
  // 냉난방 자재 > 열풍기
  public heatingFanProductModel: ProductCompanyModel = null;
  // 냉난방 자재 > 히팅튜브
  public heatingTubeProductModel: ProductCompanyModel = null;

  // 잡 자재 > 끈/와이어
  public cordProductModel: ProductCompanyModel = null;
  public ripcordProductModel: ProductCompanyModel = null;
  public coatingWireProductModel: ProductCompanyModel = null;

  // 기타 자재 > 임의 자재
  public customMaterialModel: ProductCompanyModel = null;
  public customControllerModel: ProductCompanyModel = null;
  // 기타 자재 > 공과잡비
  public buildingMisProductModel: ProductCompanyModel = null;

  // 기타 자재비 > 자재 할인비
  public overlapMaterialSavingProductModel: ProductCompanyModel = null;
  public materialDiscountProductModel: ProductCompanyModel = null;

  // 기타 경비 > 임의 경비
  public customExpenseModel: ProductCompanyModel = null;
  // 기타 경비 > 기타 경비
  public weldingProductModel: ProductCompanyModel = null;
  // 기타 경비 > 파이프 벤딩비
  public pipeBendingExpenseProductModel: ProductCompanyModel = null;
  // 기타 경비 > 장비비
  public equipmentExpenseProductModel: ProductCompanyModel = null;

  // 기타 인건비 > 임의 인건비
  public customLaborCostModel: ProductCompanyModel = null;
  // 기타 인건비 > 기타 인건비
  public buildingLaborCostProductModel: ProductCompanyModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 분류 모델 */
  @jsonMember(CategoryModel)
  public categoryModel: CategoryModel = null;

  /** 조직별 상품 모델 배열 */
  @jsonArrayMember(() => ProductCompanyModel)
  public productCompanyModelAC: ProductCompanyModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param itemModel 품목 모델
   * @param categoryModel 분류 모델
   * @param productCompanyModelAC 조직별 상품 모델 배열
   */
  constructor(
    itemModel: ItemModel = null,
    categoryModel: CategoryModel = null,
    productCompanyModelAC: ProductCompanyModel[] = null,
  ) {
    super();

    this.itemModel = itemModel;
    this.categoryModel = categoryModel;
    this.productCompanyModelAC = productCompanyModelAC;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(itemModel: ItemModel): void {
    this.itemModel = itemModel;

    // 자식 연관
    let productCompanyModel: ProductCompanyModel;
    for (productCompanyModel of this.productCompanyModelAC) {
      productCompanyModel.setAssociation(itemModel, this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    switch (this.categoryModel.id) {
      case 1:
        // 골조 자재 > 파이프
        this.pipeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.cccSectionSteelProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.gutterBeamProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.roundBarProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        break;
      case 2:
        // 골조 자재 > 보조 파이프
        this.middleSupportPipeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.uBendingTubeRailProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      // 골조 자재 > 철근
      case 102:
        this.steelBarProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 116:
        // 골조 부속 자재 > 브라켓트
        this.bracketProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 3:
        // 골조 부속 자재 > 연결구
        this.connectionPinProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 4:
        // 골조 부속 자재 > 고정구
        this.normalHolderProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.steelStringGrabberProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.steelSheetGrabberProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.crossSteelSheetGrabberProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.lineSheetGrabberProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.uclampProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        this.saddleHolderProductModel = <ProductCompanyModel>this.productCompanyModelAC[6];
        this.fixAsibaClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[7];
        this.rotationAsibaClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[8];
        this.crossClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[9];
        this.outerWallClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[10];
        this.tholderProductModel = <ProductCompanyModel>this.productCompanyModelAC[11];
        this.tclampProductModel = <ProductCompanyModel>this.productCompanyModelAC[12];
        this.diagonalTHolderProductModel = <ProductCompanyModel>this.productCompanyModelAC[13];
        this.diagonalTClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[14];
        this.gutterBeamHolderProductModel = <ProductCompanyModel>this.productCompanyModelAC[20];
        this.middlePairClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[15];
        this.middleSoleClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[16];
        this.middleYClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[17];
        this.middleHalfYClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[18];
        this.hookHolderProductModel = <ProductCompanyModel>this.productCompanyModelAC[19];
        break;
      case 6:
        // 골조 부속 자재 > 꽂이
        this.innerTHolderProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.firstLevelSoleSconceProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.firstLevelPairSconceProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.secondLevelSoleSconceProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.secondLevelPairSconceProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.connectionSkylightSetProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        this.archSconceProductModel = <ProductCompanyModel>this.productCompanyModelAC[6];
        break;
      case 95:
        // 골조 부속 자재 > 다이아몬드클램프
        this.diamondClampProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 7:
        // 골조 부속 자재 > 문짝
        this.doorProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 61:
        // 골조 부속 자재 > 문짝(기성품]
        this.doorMadeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 62:
        // 골조 부속 자재 > 여닫이 문
        this.hingedDoorProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 119: // <----- 마지막
        // 골조 부속 자재 > 샷시창문
        this.sashDoorProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 56:
        // 골조 부속 자재 > 롤러
        this.csectionSteelRollerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 10:
        // 골조 부속 자재 > 시멘트
        this.concreteProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 9:
        // 골조 부속 자재 > 나사
        this.screwProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 11:
        // 골조 부속 자재 > 골조 기타
        this.boltAndNutProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.hingeProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.knobProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.stakeProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.longBoltProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.tackerPinProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        this.filmBandProductModel = <ProductCompanyModel>this.productCompanyModelAC[6];
        this.wireMeshProductModel = <ProductCompanyModel>this.productCompanyModelAC[7];
        this.turnbuckleProductModel = <ProductCompanyModel>this.productCompanyModelAC[8];
        break;
      case 12:
        // 물받이 자재 > 철판 물받이
        this.ironPlateGutterProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 13:
        // 물받이 자재 > 플라스틱 물받이
        this.plasticGutterProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 15:
        // 물받이 자재 > 유바
        this.ubarProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 57:
        // 물받이 자재 > 물받이 걸이
        this.gutterHangerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 16:
        // 개폐기 자재 > 수동 개폐기
        this.manualSwitcherProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 17:
        // 개폐기 자재 > 동력 개폐기
        this.powerSwitcherProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 104:
        // 개폐기 자재 > 천창 모터
        this.skylightMotorProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 103:
        // 개폐기 자재 > 천창 세트
        this.rackpinionSetProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.ventpipepadSetProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 19:
        // 개폐기 자재 > 전선
        this.electricWireProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 20:
        // 개폐기 자재 > 개폐기 보조
        this.axisRollerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.axisCouplingProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.supportCouplingProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.supportFixingPinProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.longBoltHolderProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        break;
      case 18:
        // 개폐기 자재 > 통합 제어기
        this.integratedControllerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 96:
        // 개폐기 자재 > 제어기 센서
        this.controllerTimerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.controllerTempSensorProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.controllerHeatSensorProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.controllerTempHumSensorProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.controllerRaindropSensorProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.controllerWindSensorProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        this.controllerInsolationSensorProductModel = <ProductCompanyModel>this.productCompanyModelAC[6];
        break;
      case 21:
        // 개폐기 자재 > 개폐기 기타
        this.switcherProtectionRubberProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 22:
        // 피복 자재 > 연질필름
        this.vinylProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 14:
        // 피복 자재  > 직조 필름
        this.weavingFilmProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 23:
        // 피복 자재 > 차광망
        this.shadenetProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 24:
        // 피복 자재 > 텐텐지
        this.tentProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 25:
        // 피복 자재 > 부직포
        this.nonwovenProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 26:
        // 피복 자재 > 카시미론
        this.cashmilonProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 27:
        // 피복 자재 > 보온덮개
        this.colorLaggingProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.laggingProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 28:
        // 피복 자재 > 다겹 커튼지
        this.quiltingProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 29:
        // 피복 자재 > 스크린
        this.screenProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 30:
        // 피복 자재 > 마트
        this.matProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 31:
        // 피복 자재 > 방충망
        this.screennetProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 32:
        // 피복 부속 자재 > 패드
        this.normalPadProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.laggingPadProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.gutterPadProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.condensationPadProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.normalPinnedPadProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.barPadProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        break;
      case 33:
        // 피복 부속 자재 > 패드 연결구
        this.padConnectionPinProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.condensationPadConnectionPinProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 34:
        // 피복 부속 자재 > 패드 스프링
        this.normalPadSpringProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.laggingPadSpringProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 35:
        // 피복 부속 자재 > 패드 비닐
        this.padVinylProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 36:
        // 피복 부속 자재 > 클립
        this.skirtClipProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.normalClipProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.hangerClipProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.slopeClipProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        break;
      case 37:
        // 피복 부속 자재 > 목재
        this.rectLumberProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 38:
        // 피복 부속 자재 > 밴드끈
        this.normalBandstringProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.polyBandstringProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.vinylBandProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.netBandProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.weavingBandProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        break;
      case 40:
        // 피복 부속 자재 > 강선
        this.steelStringWireProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 88:
        // 환기 자재 > 환풍기
        this.ventilatorProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 89:
        // 환기 자재 > 환기팬
        this.ventFanProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 90:
        // 환기 자재 > 환기구
        this.ventHoleProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 91:
        // 환기 자재 > 환기구 개폐 세트
        this.ventHoleSwitchSetProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 92:
        // 환기 자재 > 공기 유동팬
        this.airFlowFanProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 45:
        // 커튼 > 감속 모터
        this.reducedMotorProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 46:
        // 커튼 > 베어링
        this.bearingSetProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 47:
        // 커튼 > 와인딩 드럼
        this.windingDrumProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 48:
        // 커튼 > 와이어 인장
        this.wireStrainerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 118:
        // 커튼 > 트러스 클립
        this.trussClipProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 50:
        // 커튼 > 예인 클립
        this.stickTowClipProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.hookTowClipProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 51:
        // 커튼 > 커튼 기타
        this.towRollerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.guideRollerProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.rollerHookProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.screenPincerProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        break;
      case 105:
        // 관수 자재 > 양액시스템
        this.nutrientSolutionProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 101:
        // 관수 자재 > 관비기
        this.waterSolutionProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 63:
        // 관수 자재 > 물탱크
        this.waterTankProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 85:
        // 관수 자재 > 물탱크 피팅
        this.waterTankFittingProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 106:
        // 관수 자재 > 물탱크 조절기
        this.nutrientMixerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.waterLevelControllerProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 64:
        // 관수 자재 > 펌프
        this.waterPumpProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 65:
        // 관수 자재 > 펌프 연결
        this.waterPumpConnectProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 107:
        // 관수 자재 > 펌프 호스
        this.waterPumpHoseProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 108:
        // 관수 자재 > 펌프 조절기
        this.waterPumpControllerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 66:
        // 관수 자재 > 여과기
        this.waterFilterProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 41:
        // 관수 자재 > 관수관
        this.pePipeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 68:
        // 관수 자재 > 관수 밸브
        this.electricValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.unionValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.femaleBallValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.maleBallValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.femaleMaleBallValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.femaleMaleValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        break;
      case 67:
        // 관수 자재 > 관수 연결
        this.nippleProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.socketFemaleProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.differentSocketFemaleProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.connectSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.differentConnectSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.elbowProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        this.maleElbowSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[6];
        this.femaleElbowSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[7];
        this.exactTProductModel = <ProductCompanyModel>this.productCompanyModelAC[8];
        this.differentTProductModel = <ProductCompanyModel>this.productCompanyModelAC[9];
        this.bushingProductModel = <ProductCompanyModel>this.productCompanyModelAC[10];
        this.valveSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[11];
        this.pePipeEndProductModel = <ProductCompanyModel>this.productCompanyModelAC[12];
        this.waterScreenConnectSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[13];
        this.waterScreenElbowSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[14];
        this.pePipeSaddleProductModel = <ProductCompanyModel>this.productCompanyModelAC[15];
        break;
      case 70:
        // 관수 자재 > 관 걸이
        this.pePipeHangerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 71:
        // 관수 자재 > 튜브
        this.tubeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 72:
        // 관수 자재 > 튜브 니플
        this.tubeNippleProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 73:
        // 관수 자재 > 미니 스프링클러
        this.miniSprinklerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 74:
        // 관수 자재 > 미니 스프링클러 낙수방지기
        this.miniSprinklerDropGuardProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 75:
        // 관수 자재 > 미니 스프링클러 튜브셋
        this.miniSprinklerTubeSetProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 76:
        // 관수 자재 > 점적관
        this.dripPipeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 77:
        // 관수 자재 > 점적단추
        this.dripButtonProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 78:
        // 관수 자재 > 점적단추 부속
        this.dripButtonManyHoldProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.dripButtonDropperProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 69:
        // 관수 자재 > 점적 미늘
        this.dripBarbProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.dripStartSaddleProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 87:
        // 관수 자재 > 점적 밸브
        this.dripLineValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.dripStartLineValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.dripLineValveSupportProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        break;
      case 86:
        // 관수 자재 > 점적 연결
        this.dripConnectSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.dripSupportEndProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        this.ldConnectProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.ldElbowProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.ldExactTProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.ldEndProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        break;
      case 79:
        // 관수 자재 > 분수호스
        this.fountainHoseProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 80:
        // 관수 자재 > 분수호스 연결
        this.fountainHoseConnectProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.fountainHoseCrossConnectProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.fountainHoseEndProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.fountainHoseTValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        break;
      case 81:
        // 관수 자재 > 분수호스 걸이
        this.fountainHoseHangerScreenProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.fountainHoseHangerUpperProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.fountainHoseHangerSideProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        break;
      case 82:
        // 관수 자재 > 관수 공구
        this.pePipeFastenerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.pePipePuncherProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 83:
        // 관수 자재 > 관수 기타
        this.dripAlignmentPinProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.wateringSpringProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 8:
        // 배수 자재 > 배수관
        this.pleatedPipeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.pvcPipeProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 117:
        // 배수 자재 > PVC 밸브
        this.pvcFemaleBallValveProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 58:
        // 배수 자재 > PVC 연결구
        this.pvcSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.pvcElbowProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.pvcDgree45ElbowProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.pvcExactTProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.pvcDifferentTProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.pvcValveSocketProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        this.pvcEndProductModel = <ProductCompanyModel>this.productCompanyModelAC[6];
        break;
      case 59:
        // 배수 자재 > 물받이 홈통
        this.drainspoutBoxProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 60:
        // 배수 자재 > 배관 잡이쇠
        this.drainPipeHangerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 109:
        // 재배대 자재 > 포밍 베드
        this.formingBedProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 110:
        // 재배대 자재 > 스티로폼 베드
        this.styrofoamBedProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 111:
        // 재배대 자재 > NFT 베드
        this.nftBedProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 112:
        // 재배대 자재 > 베드 부속
        this.bedEndProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.bedDrainProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.bedSupportClipProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        this.bedHolderProductModel = <ProductCompanyModel>this.productCompanyModelAC[3];
        this.nftSupplyDrainSetProductModel = <ProductCompanyModel>this.productCompanyModelAC[4];
        this.sinkGuardPlateProductModel = <ProductCompanyModel>this.productCompanyModelAC[5];
        break;
      case 113:
        // 재배대 자재 > 바닥지
        this.groundCoverProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 114:
        // 재배대 자재 > 바닥지 부속
        this.sackPinProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 115:
        // 재배대 자재 > 유인줄 부속
        this.trellisHangerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.trellisRoundBarProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 97:
        // 운반 자재 > 운반구
        this.upperCarrierProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.sideCarrierProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 98:
        // 운반 자재 > 지면 운반구
        this.groundCarrierProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 99:
        // 운반 자재 > 운반 고리
        this.railHookProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.secondLevelRailHookProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.sideRailHookProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        break;
      case 100:
        // 운반 자재 > 레일 간격유지
        this.railIntervalProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.railSupportPipeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 5:
        // 냉난방 자재 > 보일러
        this.boilerProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 39:
        // 냉난방 자재 > 열풍기
        this.heatingFanProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 49:
        // 냉난방 자재 > 히팅튜브
        this.heatingTubeProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 84:
        // 잡 자재 > 끈
        this.cordProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.ripcordProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        this.coatingWireProductModel = <ProductCompanyModel>this.productCompanyModelAC[2];
        break;
      case 42:
        // 기타 자재 > 임의 자재
        this.customMaterialModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.customControllerModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 52:
        // 기타 자재 > 공과잡비
        this.buildingMisProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 44:
        // 기타 자재비 > 자재 할인비
        this.overlapMaterialSavingProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        this.materialDiscountProductModel = <ProductCompanyModel>this.productCompanyModelAC[1];
        break;
      case 53:
        // 기타 경비 > 임의 경비
        this.customExpenseModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 54:
        // 기타 경비 > 기타 경비
        this.weldingProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 93:
        // 기타 경비 > 파이프 벤딩비
        this.pipeBendingExpenseProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 94:
        // 기타 경비 > 장비비
        this.equipmentExpenseProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 55:
        // 기타 인건비 > 임의 인건비
        this.customLaborCostModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      case 43:
        // 기타 인건비 > 기타 인건비
        this.buildingLaborCostProductModel = <ProductCompanyModel>this.productCompanyModelAC[0];
        break;
      default:
        break;
    }

    // 자식 레퍼런스변수
    let productCompanyModel: ProductCompanyModel;
    for (productCompanyModel of this.productCompanyModelAC) {
      productCompanyModel.setReferenceVariable();
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 자식 초기화
    let productCompanyModel: ProductCompanyModel;
    for (productCompanyModel of this.productCompanyModelAC) {
      productCompanyModel.setDefaultVariable();
    }
  }

  //----------------------------------
  // 품목 모델
  //----------------------------------

  /**
   * 상품 모델 가져오기
   * @param productId 상품 아이디
   * @return 상품 모델
   */
  public getProductModel(productId: string): ProductCompanyModel {
    for (const productCompanyModel of this.productCompanyModelAC) {
      if (productCompanyModel.productModel.productId === productId) {
        return productCompanyModel;
      }
    }

    return null;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
