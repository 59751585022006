import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemWood } from "vhows-design/src/object/design/item/list/ItemWood";
import { Part } from "vhows-design/src/object/design/base/Part";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { SkirtSampleIL_RectLumber } from "vhows-design/src/object/design/cover/skirt/il/SkirtSampleIL_RectLumber";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject({
  knownTypes: [SkirtSampleIL_RectLumber],
})
export class SkirtPartIL_FixingWood extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public rectLumberSample: SkirtSampleIL_RectLumber; // 각재 샘플

  // 아이템
  public wood_rectLumber: ItemWood;
  public wood_tackerPinShort: ItemFrameOther;
  public wood_tackerPinLong: ItemFrameOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.rectLumberSample = new SkirtSampleIL_RectLumber();

    this.sampleAC = [this.rectLumberSample];

    // 아이템
    this.wood_rectLumber = new ItemWood();
    this.wood_tackerPinShort = new ItemFrameOther();
    this.wood_tackerPinLong = new ItemFrameOther();

    this.itemAC = [this.wood_rectLumber, this.wood_tackerPinShort, this.wood_tackerPinLong];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.rectLumberSample = <SkirtSampleIL_RectLumber>this.sampleAC[0];

    // 아이템
    this.wood_rectLumber = <ItemWood>this.itemAC[0];
    this.wood_tackerPinShort = <ItemFrameOther>this.itemAC[1];
    this.wood_tackerPinLong = <ItemFrameOther>this.itemAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.rectLumberSample.setDefaultData(
        0,
        CONST.ITEM_ID_RECT_LUMBER,
        CONST.ITEM_NAME_RECT_LUMBER,
        "목재",
        "일반, 40×40mm, 3.6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.wood_rectLumber.setDefaultData(
        0,
        CONST.ITEM_ID_RECT_LUMBER,
        CONST.ITEM_NAME_RECT_LUMBER,
        "목재",
        "일반, 40×40mm, 3.6m",
      );
      this.wood_tackerPinShort.setDefaultData(
        1,
        CONST.ITEM_ID_TACKER_PIN,
        CONST.ITEM_NAME_TACKER_PIN,
        "목재",
        "ㄷ자, 22mm",
      );
      this.wood_tackerPinLong.setDefaultData(
        2,
        CONST.ITEM_ID_TACKER_PIN,
        CONST.ITEM_NAME_TACKER_PIN,
        "목재",
        "일자, 64mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skirtPosition: SkirtPositionIL = <SkirtPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;
    // 외부
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    );

    /// //////// 선택, 가시성 ///////////

    this.wood_rectLumber.selected = true;
    this.wood_rectLumber.visible = true;
    this.wood_tackerPinShort.selected = false;
    this.wood_tackerPinShort.visible = true;
    this.wood_tackerPinLong.selected = false;
    this.wood_tackerPinLong.visible = true;

    /// //////// 수량 ///////////

    if (skirtPosition.label === CONST.LB_POSITION_SIDE_COLUMN || skirtPosition.label === CONST.LB_POSITION_SIDE_WING) {
      this.wood_rectLumber.designQuantity =
        (this.basicLevel.length / this.wood_rectLumber.specLength) * switcherPosition.switchWayValue;
    } else if (skirtPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          bothEndQuantity = (this.basicLevel.length / this.wood_rectLumber.specLength) * 2;
          centralQuantity =
            (this.basicLevel.length / this.wood_rectLumber.specLength) *
            switcherPosition.switchWayValue *
            (this.basic.buildingNumber - 2);
          this.wood_rectLumber.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          this.wood_rectLumber.designQuantity =
            (this.basicLevel.length / this.wood_rectLumber.specLength) *
            switcherPosition.switchWayValue *
            this.basic.buildingNumber;
        }
      } else {
        this.wood_rectLumber.designQuantity =
          (this.basicLevel.length / this.wood_rectLumber.specLength) * switcherPosition.switchWayValue;
      }
    } else if (skirtPosition.label === CONST.LB_POSITION_FRONT) {
      this.wood_rectLumber.designQuantity =
        (this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber) / this.wood_rectLumber.specLength;
    } else if (skirtPosition.label === CONST.LB_POSITION_BACK) {
      this.wood_rectLumber.designQuantity =
        (this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber) / this.wood_rectLumber.specLength;
    }

    // wood_tackerPinShort.designQuantity = 0;

    // wood_tackerPinLong.designQuantity = 0;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [목재] 각재 <- 각재 샘플
   */
  public algorithmSpec_wood_rectLumber(): void {
    this.wood_rectLumber.specs = this.rectLumberSample.specs;
  }

  /**
   * 규격 알고리즘: [목재] 타카핀 <- 공통 샘플
   */
  public algorithmSpec_woodTackerShort_tackerPin(): void {}

  /**
   * 규격 알고리즘: [목재] 타카핀 <- 공통 샘플
   */
  public algorithmSpec_woodTackerLong_tackerPin(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
