import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Work } from "vhows-design/src/object/design/base/Work";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-01
 */
@jsonObject({
  knownTypes: [EndpieceLevel],
})
export class EndpieceWork extends Work {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public level1: EndpieceLevel;
  public level2: EndpieceLevel;
  public level3: EndpieceLevel;
  public level4: EndpieceLevel;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.level1 = new EndpieceLevel();
    this.level2 = new EndpieceLevel();
    this.level3 = new EndpieceLevel();
    this.level4 = new EndpieceLevel();

    this.levelAC = [this.level1, this.level2, this.level3, this.level4];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.level1 = <EndpieceLevel>this.levelAC[0];
    this.level2 = <EndpieceLevel>this.levelAC[1];
    this.level3 = <EndpieceLevel>this.levelAC[2];
    this.level4 = <EndpieceLevel>this.levelAC[3];

    super.setReferenceVariable();
  }

  // @override
  public setDefaultData(
    index: number = 0,
    depth: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    category: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, depth, selected, enabled, visible, label, category, buildNote);

    this.level1.setDefaultData(0, true, true, true, CONST.LB_LEVEL_1);
    this.level2.setDefaultData(1, true, false, true, CONST.LB_LEVEL_2);
    this.level3.setDefaultData(2, true, false, true, CONST.LB_LEVEL_3);
    this.level4.setDefaultData(3, true, false, true, CONST.LB_LEVEL_4);
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
