import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemSconce } from "vhows-design/src/object/design/item/list/ItemSconce";
import { ItemSkylightSet } from "vhows-design/src/object/design/item/list/ItemSkylightSet";
import { Part } from "vhows-design/src/object/design/base/Part";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { SkySwitchPosition } from "vhows-design/src/object/design/skylight/switches/SkySwitchPosition";
import { SkyFrameSample_RidgeCSectionSteel } from "vhows-design/src/object/design/skylight/frame/SkyFrameSample_RidgeCSectionSteel";
import { SkyFrameSample_VentpipepadSet } from "vhows-design/src/object/design/skylight/frame/SkyFrameSample_VentpipepadSet";
import { SkyFrameSample_WindowBarPipe } from "vhows-design/src/object/design/skylight/frame/SkyFrameSample_WindowBarPipe";
import { SkyFrameSample_WindowEdgePipe } from "vhows-design/src/object/design/skylight/frame/SkyFrameSample_WindowEdgePipe";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-04-15
 */
@jsonObject({
  knownTypes: [
    SkyFrameSample_RidgeCSectionSteel,
    SkyFrameSample_VentpipepadSet,
    SkyFrameSample_WindowBarPipe,
    SkyFrameSample_WindowEdgePipe,
  ],
})
export class SkyFramePart_Rackpinion extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public skyRidgeCSectionSteelSample: SkyFrameSample_RidgeCSectionSteel; // 천창 용마루 C형강 샘플
  public ventpipepadSetSample: SkyFrameSample_VentpipepadSet; // 환기창파이프패드 세트 샘플
  public skyWindowEdgePipeSample: SkyFrameSample_WindowEdgePipe; // 천창끝 파이프 샘플
  public skyWindowBarPipeSample: SkyFrameSample_WindowBarPipe; // 천창살 파이프 샘플

  // 아이템
  public skyRidge_CSectionSteel: ItemPipe;
  public skyRidgeAndRidge_connectionPin: ItemConnector;
  public skyRidgeAndRafter_saddleHolder: ItemHolder;
  public skyRidgeAndWindowBar_connectionSkylightSet: ItemSconce;
  public rackpinion_ventpipepadSet: ItemSkylightSet;
  public skyWindowEdge_pipe: ItemPipe;
  public skyWindowEdgeAndWindowEdge_connectionPin: ItemConnector;
  public skyWindowBar_pipe: ItemPipe;
  public skyWindowEdgeAndWindowBar_tHolder: ItemHolder;
  public rackpinion_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _windowBarLength: number; // 천창살 길이
  @jsonMember(Number)
  public _windowBarInterval: number; // 천창살 간격

  /**
   * 천창살 길이
   */
  public get windowBarLength(): number {
    return this._windowBarLength;
  }
  //
  public set windowBarLength(value: number) {
    this._windowBarLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    /// //////// 외부 ///////////
    this.struct.skyCoverWork.level1.skylightPosition.normalPadPart.algorithmPart();
  }

  /**
   * 천창살 간격
   */
  public get windowBarInterval(): number {
    return this._windowBarInterval;
  }
  //
  public set windowBarInterval(value: number) {
    this._windowBarInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 천창 개폐
    this.struct.skySwitchWork.level1.skylightPosition.rackpinionPart.algorithm_rackpinionInterval();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.skyRidgeCSectionSteelSample = new SkyFrameSample_RidgeCSectionSteel();
    this.ventpipepadSetSample = new SkyFrameSample_VentpipepadSet();
    this.skyWindowEdgePipeSample = new SkyFrameSample_WindowEdgePipe();
    this.skyWindowBarPipeSample = new SkyFrameSample_WindowBarPipe();

    this.sampleAC = [
      this.skyRidgeCSectionSteelSample,
      this.ventpipepadSetSample,
      this.skyWindowEdgePipeSample,
      this.skyWindowBarPipeSample,
    ];

    // 아이템
    this.skyRidge_CSectionSteel = new ItemPipe();
    this.skyRidgeAndRidge_connectionPin = new ItemConnector();
    this.skyRidgeAndRafter_saddleHolder = new ItemHolder();
    this.skyRidgeAndWindowBar_connectionSkylightSet = new ItemSconce();
    this.rackpinion_ventpipepadSet = new ItemSkylightSet();
    this.skyWindowEdge_pipe = new ItemPipe();
    this.skyWindowEdgeAndWindowEdge_connectionPin = new ItemConnector();
    this.skyWindowBar_pipe = new ItemPipe();
    this.skyWindowEdgeAndWindowBar_tHolder = new ItemHolder();
    this.rackpinion_screw = new ItemScrew();

    this.itemAC = [
      this.skyRidge_CSectionSteel,
      this.skyRidgeAndRidge_connectionPin,
      this.skyRidgeAndRafter_saddleHolder,
      this.skyRidgeAndWindowBar_connectionSkylightSet,
      this.rackpinion_ventpipepadSet,
      this.skyWindowEdge_pipe,
      this.skyWindowEdgeAndWindowEdge_connectionPin,
      this.skyWindowBar_pipe,
      this.skyWindowEdgeAndWindowBar_tHolder,
      this.rackpinion_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.skyRidgeCSectionSteelSample = <SkyFrameSample_RidgeCSectionSteel>this.sampleAC[0];
    this.ventpipepadSetSample = <SkyFrameSample_VentpipepadSet>this.sampleAC[1];
    this.skyWindowEdgePipeSample = <SkyFrameSample_WindowEdgePipe>this.sampleAC[2];
    this.skyWindowBarPipeSample = <SkyFrameSample_WindowBarPipe>this.sampleAC[3];

    // 아이템
    this.skyRidge_CSectionSteel = <ItemPipe>this.itemAC[0];
    this.skyRidgeAndRidge_connectionPin = <ItemConnector>this.itemAC[1];
    this.skyRidgeAndRafter_saddleHolder = <ItemHolder>this.itemAC[2];
    this.skyRidgeAndWindowBar_connectionSkylightSet = <ItemSconce>this.itemAC[3];
    this.rackpinion_ventpipepadSet = <ItemSkylightSet>this.itemAC[4];
    this.skyWindowEdge_pipe = <ItemPipe>this.itemAC[5];
    this.skyWindowEdgeAndWindowEdge_connectionPin = <ItemConnector>this.itemAC[6];
    this.skyWindowBar_pipe = <ItemPipe>this.itemAC[7];
    this.skyWindowEdgeAndWindowBar_tHolder = <ItemHolder>this.itemAC[8];
    this.rackpinion_screw = <ItemScrew>this.itemAC[9];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param windowBarLength: number 천창살 길이
   * @param windowBarInterval: number 천창살 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    windowBarLength: number = 0,
    windowBarInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._windowBarLength = windowBarLength;
    this._windowBarInterval = windowBarInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.skyRidgeCSectionSteelSample.setDefaultData(
        0,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "천창 용마루",
        "각형, 일반, 60×30mm, 1.8T, 6m, -",
      );

      this.ventpipepadSetSample.setDefaultData(
        1,
        CONST.ITEM_ID_VENTPIPEPAD_SET,
        CONST.ITEM_NAME_VENTPIPEPAD_SET,
        "천창",
        "알루미늄, 25×25mm, 6m",
      );

      this.skyWindowEdgePipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창끝",
        "각형, 일반, 40×20mm, 1.4T, 6m, -",
      );

      this.skyWindowBarPipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창살",
        "각형, 일반, 25×25mm, 1.4T, 6m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.skyRidge_CSectionSteel.setDefaultData(
        0,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "천창 용마루",
        "각형, 일반, 60×30mm, 1.8T, 6m, -",
      );
      this.skyRidgeAndRidge_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "천창 용마루 + 천창 용마루",
        "각형, 일반, 60x30mm, 60x30mm",
      );
      this.skyRidgeAndRafter_saddleHolder.setDefaultData(
        2,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "천창 용마루 + 서까래",
        "원형, 일반, 60x30mm, -",
      );
      this.skyRidgeAndWindowBar_connectionSkylightSet.setDefaultData(
        3,
        CONST.ITEM_ID_CONNECTION_SKYLIGHT_SET,
        CONST.ITEM_NAME_CONNECTION_SKYLIGHT_SET,
        "천창 용마루 + 천창살",
        "각형, 외꽂이, 60x30mm, 25×25mm",
      );

      this.rackpinion_ventpipepadSet.setDefaultData(
        4,
        CONST.ITEM_ID_VENTPIPEPAD_SET,
        CONST.ITEM_NAME_VENTPIPEPAD_SET,
        "천창",
        "알루미늄, 25×25mm, 6m",
      );
      this.skyWindowEdge_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창끝",
        "각형, 일반, 40×20mm, 1.4T, 6m, -",
      );
      this.skyWindowEdgeAndWindowEdge_connectionPin.setDefaultData(
        6,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "천창끝 + 천창끝",
        "각형, 일반, 40×20mm, 40×20mm",
      );
      this.skyWindowBar_pipe.setDefaultData(
        7,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창살",
        "각형, 일반, 25×25mm, 1.4T, 6m, -",
      );
      this.skyWindowEdgeAndWindowBar_tHolder.setDefaultData(
        8,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "천창끝 + 천창살",
        "각형, 일반, 40×20mm, 25×25mm",
      );
      this.rackpinion_screw.setDefaultData(
        9,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "천창",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skyFramePosition: SkyFramePosition = this.position as SkyFramePosition;
    const skySwitchesPosition: SkySwitchPosition = this.struct.skySwitchWork.level1.skylightPosition;
    const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;

    /// //////// 선택, 가시성 ///////////

    this.skyRidge_CSectionSteel.selected = true;
    this.skyRidge_CSectionSteel.visible = true;
    this.skyRidgeAndRidge_connectionPin.selected = true;
    this.skyRidgeAndRidge_connectionPin.visible = true;
    this.skyRidgeAndRafter_saddleHolder.selected = true;
    this.skyRidgeAndRafter_saddleHolder.visible = true;
    this.skyRidgeAndWindowBar_connectionSkylightSet.selected = true;
    this.skyRidgeAndWindowBar_connectionSkylightSet.visible = true;
    this.rackpinion_ventpipepadSet.selected = true;
    this.rackpinion_ventpipepadSet.visible = true;
    this.skyWindowEdge_pipe.selected = false;
    this.skyWindowEdge_pipe.visible = false;
    this.skyWindowEdgeAndWindowEdge_connectionPin.selected = false;
    this.skyWindowEdgeAndWindowEdge_connectionPin.visible = false;
    this.skyWindowBar_pipe.selected = true;
    this.skyWindowBar_pipe.visible = true;
    this.skyWindowEdgeAndWindowBar_tHolder.selected = false;
    this.skyWindowEdgeAndWindowBar_tHolder.visible = false;
    this.rackpinion_screw.selected = false;
    this.rackpinion_screw.visible = false;

    /// //////// 수량 ///////////

    this.skyRidge_CSectionSteel.designQuantity =
      this.skyRidge_CSectionSteel.getConnectedPipeLength(
        skyFramePosition.skylightLength,
        this.basic.buildingNumber,
        this.skyRidge_CSectionSteel.specLength / 2,
      ) / this.skyRidge_CSectionSteel.specLength;

    if (skyFramePosition.skylightLength > this.skyRidge_CSectionSteel.specLength) {
      this.skyRidgeAndRidge_connectionPin.designQuantity =
        this.skyRidge_CSectionSteel.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.skyRidgeAndRidge_connectionPin.designQuantity = 0;
    }

    this.skyRidgeAndRafter_saddleHolder.designQuantity =
      (CommonUtil.roundUp(skyFramePosition.skylightLength / trunkPosition.rafterPart.rafterInterval) + 1) *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.skyRidgeAndWindowBar_connectionSkylightSet.designQuantity =
      (CommonUtil.roundUp(skyFramePosition.skylightLength / this.windowBarInterval) + 1) *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_CONNECTION_SKYLIGHT_SET;

    this.rackpinion_ventpipepadSet.designQuantity =
      CommonUtil.roundUp(skyFramePosition.skylightLength / this.rackpinion_ventpipepadSet.specLength) *
      skySwitchesPosition.switchWayValue *
      this.basic.buildingNumber;

    this.skyWindowEdge_pipe.designQuantity =
      this.skyWindowEdge_pipe.getConnectedPipeLength(
        skyFramePosition.skylightLength,
        this.basic.buildingNumber * skySwitchesPosition.switchWayValue,
        this.skyWindowEdge_pipe.specLength / 2,
      ) / this.skyWindowEdge_pipe.specLength;

    if (skyFramePosition.skylightLength > this.skyWindowEdge_pipe.specLength) {
      this.skyWindowEdgeAndWindowEdge_connectionPin.designQuantity =
        this.skyWindowEdge_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.skyWindowEdgeAndWindowEdge_connectionPin.designQuantity = 0;
    }

    this.skyWindowBar_pipe.designQuantity =
      (this.windowBarLength *
        (CommonUtil.roundUp(skyFramePosition.skylightLength / this.windowBarInterval) + 1) *
        this.basic.buildingNumber *
        skySwitchesPosition.switchWayValue) /
      this.skyWindowBar_pipe.specLength;

    this.skyWindowEdgeAndWindowBar_tHolder.designQuantity =
      (CommonUtil.roundUp(skyFramePosition.skylightLength / this.windowBarInterval) + 1) *
      this.basic.buildingNumber *
      skySwitchesPosition.switchWayValue *
      CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.rackpinion_screw.designQuantity = 0;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 용마루] C형강 <- 천창 용마루 C형강 샘플
   */
  public algorithmSpec_skyRidge_CSectionSteel(): void {
    this.skyRidge_CSectionSteel.specs = this.skyRidgeCSectionSteelSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 용마루] 연결핀 <- 천창 용마루 C형강 샘플
   */
  public algorithmSpec_skyRidgeAndRidge_connectionPin(): void {
    this.skyRidgeAndRidge_connectionPin.specPipeType = this.skyRidgeCSectionSteelSample.specPipeType;
    this.skyRidgeAndRidge_connectionPin.specCrossSize1 = this.skyRidgeCSectionSteelSample.specCrossSize;
    this.skyRidgeAndRidge_connectionPin.specCrossSize2 = this.skyRidgeCSectionSteelSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 용마루 + 서까래] 새들 고정구 <- 천창 용마루 C형강 샘플
   */
  public algorithmSpec_skyRidgeAndRafter_saddleHolder(): void {
    this.skyRidgeAndRafter_saddleHolder.specPipeType = this.skyRidgeCSectionSteelSample.specPipeType;
    this.skyRidgeAndRafter_saddleHolder.specCrossSize1 = this.skyRidgeCSectionSteelSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 용마루 + 천창살] 연결창 세트 <- 천창 용마루 C형강 샘플, 천창 형태
   */
  public algorithmSpec_skyRidgeAndWindowBar_connectionSkylightSet(): void {
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    this.skyRidgeAndWindowBar_connectionSkylightSet.specPipeType = this.skyRidgeCSectionSteelSample.specPipeType;
    if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE) {
      this.skyRidgeAndWindowBar_connectionSkylightSet.specMaterial = CONST.ITEM_SPEC_VALUE_SINGLE_SCONCE;
    } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH) {
      this.skyRidgeAndWindowBar_connectionSkylightSet.specMaterial = CONST.ITEM_SPEC_VALUE_DOUBLE_SCONCE;
    }
    this.skyRidgeAndWindowBar_connectionSkylightSet.specCrossSize1 = this.skyRidgeCSectionSteelSample.specCrossSize;
    this.skyRidgeAndWindowBar_connectionSkylightSet.specCrossSize2 = this.skyWindowBarPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창] 환기창파이프패드 세트 <- 환기창파이프패드 세트 샘플
   */
  public algorithmSpec_rackpinion_ventpipepadSet(): void {
    this.rackpinion_ventpipepadSet.specs = this.ventpipepadSetSample.specs;
  }

  /**
   * 규격 알고리즘: [천창끝] 파이프 <- 천창끝 파이프 샘플
   */
  public algorithmSpec_skyWindowEdge_pipe(): void {
    this.skyWindowEdge_pipe.specs = this.skyWindowEdgePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [천창끝] 연결핀 <- 천창끝 파이프 샘플
   */
  public algorithmSpec_skyWindowEdgeAndWindowEdge_connectionPin(): void {
    this.skyWindowEdgeAndWindowEdge_connectionPin.specPipeType = this.skyWindowEdge_pipe.specPipeType;
    this.skyWindowEdgeAndWindowEdge_connectionPin.specCrossSize1 = this.skyWindowEdge_pipe.specCrossSize;
    this.skyWindowEdgeAndWindowEdge_connectionPin.specCrossSize2 = this.skyWindowEdge_pipe.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창살] 파이프 <- 천창살 파이프 샘플
   */
  public algorithmSpec_skyWindowBar_pipe(): void {
    this.skyWindowBar_pipe.specs = this.skyWindowBarPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [천창끝 + 천창살] T 고정구 <- 천창끝 파이프 샘플, 천창살 파이프 샘플
   */
  public algorithmSpec_skyWindowEdgeAndWindowBar_tHolder(): void {
    this.skyWindowEdgeAndWindowBar_tHolder.specPipeType = this.skyWindowEdgePipeSample.specPipeType;
    this.skyWindowEdgeAndWindowBar_tHolder.specCrossSize1 = this.skyWindowEdgePipeSample.specCrossSize;
    this.skyWindowEdgeAndWindowBar_tHolder.specCrossSize2 = this.skyWindowBarPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [랙피니언] 나사 <- 공통 샘플
   */
  public algorithmSpec_rackpinion_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
