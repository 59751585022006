import { jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Co2Position } from "vhows-design/src/object/design/other/co2/Co2Position";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-07-20
 */
@jsonObject({
  knownTypes: [Co2Position],
})
export class Co2Level extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public wholePosition: Co2Position;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.wholePosition = new Co2Position();

    this.positionAC = [this.wholePosition];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.wholePosition = <Co2Position>this.positionAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index === 0 || index === 1 || index === 2 || index === 3) {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        this.wholePosition.setDefaultData(0, false, true, true, CONST.LB_POSITION_WHOLE, CONST.LB_CO2_TYPE_CUSTOM);
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        this.wholePosition.setDefaultData(0, false, true, true, CONST.LB_POSITION_WHOLE, CONST.LB_CO2_TYPE_CUSTOM);
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
