import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";
import { WindowPartIL_Sash } from "vhows-design/src/object/design/other/window/il/WindowPartIL_Sash";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject
export class WindowSampleIL_SashFramePipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const sashPart: WindowPartIL_Sash = <WindowPartIL_Sash>this.part;
    sashPart.algorithmSpec_sashFrame_pipe();
    sashPart.algorithmSpec_sashFrameHeight_pipe();
    sashPart.algorithmSpec_sashFrame_tHolder();
    sashPart.algorithmSpec_sashFrame_tClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevelIL>this.level).rightPosition.sashPart.sashFramePipeSample.specs = this.specs;
      (<WindowLevelIL>this.level).frontPosition.sashPart.sashFramePipeSample.specs = this.specs;
      (<WindowLevelIL>this.level).backPosition.sashPart.sashFramePipeSample.specs = this.specs;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevelIL>this.level).backPosition.sashPart.sashFramePipeSample.specs = this.specs;
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플(기둥 골조), 기둥 형태(기둥 골조), 설치 형태
   */
  // @override
  public algorithmSpec(): void {
    if ((<WindowPartIL_Sash>this.part).installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_STRAIGHT) {
      // 하드코딩
      this.specsList = "각형, 일반, 40×20mm, 1.4T";
    } else if ((<WindowPartIL_Sash>this.part).installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER) {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
          this.specsList = columnPosition.cylinderColumnPart.columnPipeSample.specsList;
        } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.specsList = columnPosition.prismColumnPart.columnPipeSample.specsList;
        }
      } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
        const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          // 측면 기둥
          this.specsList = columnPosition.prismColumnPart.sideColumnPipeSample.specsList;
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
