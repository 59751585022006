import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { GutterPartIL_CondensationGutter } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_CondensationGutter";
import { GutterPartIL_DrainagePleatedPipe } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_DrainagePleatedPipe";
import { GutterPartIL_DrainagePVCPipe } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_DrainagePVCPipe";
import { GutterPartIL_FixingGutterPad } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_FixingGutterPad";
import { GutterPartIL_FixingNormalClip } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_FixingNormalClip";
import { GutterPartIL_FixingScrew } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_FixingScrew";
import { GutterPartIL_IronPlate } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_IronPlate";
import { GutterPartIL_Plastic } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_Plastic";
import { GutterPartIL_WeavingFilm } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_WeavingFilm";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-28
 */
@jsonObject({
  knownTypes: [
    GutterPartIL_CondensationGutter,
    GutterPartIL_DrainagePleatedPipe,
    GutterPartIL_DrainagePVCPipe,
    GutterPartIL_FixingGutterPad,
    GutterPartIL_FixingNormalClip,
    GutterPartIL_FixingScrew,
    GutterPartIL_IronPlate,
    GutterPartIL_Plastic,
    GutterPartIL_WeavingFilm,
  ],
})
export class GutterPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public ironPlatePart: GutterPartIL_IronPlate;
  public plasticPart: GutterPartIL_Plastic;
  public weavingFilmPart: GutterPartIL_WeavingFilm;
  public fixingGutterPadPart: GutterPartIL_FixingGutterPad;
  public fixingScrewPart: GutterPartIL_FixingScrew;
  public fixingNormalClipPart: GutterPartIL_FixingNormalClip;
  public drainagePleatedPipePart: GutterPartIL_DrainagePleatedPipe;
  public drainagePVCPipePart: GutterPartIL_DrainagePVCPipe;
  public condensationGutterPart: GutterPartIL_CondensationGutter;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  get totalGutterLength(): number {
    let totalGutterLength: number = 0;

    for (let i: number = 0; i < this.struct.gutterWorkIL.levelAC.length; i++) {
      if (this.struct.gutterWorkIL.level1.selected) {
        totalGutterLength += (this.basicLevel.length + 1) * (this.basic.buildingNumber - 1);
      } else if (this.struct.gutterWorkIL.levelAC[i].selected) {
        totalGutterLength += (this.basicLevel.length + 1) * (this.basic.buildingNumber * 2);
      }
    }
    return totalGutterLength;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _fixingType: string; // 물받이 고정 형태
  @jsonMember(String)
  public _drainageType: string; // 물받이 배수 형태
  @jsonMember(Boolean)
  public _condensationSelected: boolean; // 결로받이 선택

  /**
   * 물받이 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  //
  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 물받이 배수 형태
   */
  public get drainageType(): string {
    return this._drainageType;
  }

  //
  public set drainageType(value: string) {
    this._drainageType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByDrainageType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 결로받이 선택
   */
  public get condensationSelected(): boolean {
    return this._condensationSelected;
  }

  //
  public set condensationSelected(value: boolean) {
    this._condensationSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByCondensationGutterSelected();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_fixingTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.ironPlatePart = new GutterPartIL_IronPlate();
    this.plasticPart = new GutterPartIL_Plastic();
    this.weavingFilmPart = new GutterPartIL_WeavingFilm();
    this.fixingGutterPadPart = new GutterPartIL_FixingGutterPad();
    this.fixingScrewPart = new GutterPartIL_FixingScrew();
    this.fixingNormalClipPart = new GutterPartIL_FixingNormalClip();
    this.drainagePleatedPipePart = new GutterPartIL_DrainagePleatedPipe();
    this.drainagePVCPipePart = new GutterPartIL_DrainagePVCPipe();
    this.condensationGutterPart = new GutterPartIL_CondensationGutter();

    this.partAC = [
      this.ironPlatePart,
      this.plasticPart,
      this.weavingFilmPart,
      this.fixingGutterPadPart,
      this.fixingScrewPart,
      this.fixingNormalClipPart,
      this.drainagePleatedPipePart,
      this.drainagePVCPipePart,
      this.condensationGutterPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.ironPlatePart = <GutterPartIL_IronPlate>this.partAC[0];
    this.plasticPart = <GutterPartIL_Plastic>this.partAC[1];
    this.weavingFilmPart = <GutterPartIL_WeavingFilm>this.partAC[2];
    this.fixingGutterPadPart = <GutterPartIL_FixingGutterPad>this.partAC[3];
    this.fixingScrewPart = <GutterPartIL_FixingScrew>this.partAC[4];
    this.fixingNormalClipPart = <GutterPartIL_FixingNormalClip>this.partAC[5];
    this.drainagePleatedPipePart = <GutterPartIL_DrainagePleatedPipe>this.partAC[6];
    this.drainagePVCPipePart = <GutterPartIL_DrainagePVCPipe>this.partAC[7];
    this.condensationGutterPart = <GutterPartIL_CondensationGutter>this.partAC[8];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param fixingType: string 물받이 고정 형태
   * @param drainageType: string 물받이 배수 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    fixingType: string = "",
    drainageType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._fixingType = fixingType;
    this._drainageType = drainageType;

    if (this.level.index === 0) {
      // 철판 물받이
      this.ironPlatePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_IRON_PLATE,
        true,
        false,
        true,
        CONST.LB_GUTTER_IRON_PLATE,
        "",
      );

      // 플라스틱 물받이
      this.plasticPart.setDefaultData(CONST.INDEX_IL_GUTTER_PLASTIC, false, false, false, CONST.LB_GUTTER_PLASTIC, "");

      // 직조필름 물받이
      this.weavingFilmPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_WEAVING_FILM,
        false,
        false,
        false,
        CONST.LB_GUTTER_WEAVING_FILM,
        "",
      );

      // 물받이 고정 - 물받이 패드
      this.fixingGutterPadPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_GUTTER_PAD,
        true,
        false,
        true,
        CONST.LB_GUTTER_FIXING_GUTTER_PAD,
        "",
      );

      // 물받이 고정 - 나사
      this.fixingScrewPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_SCREW,
        false,
        false,
        false,
        CONST.LB_GUTTER_FIXING_SCREW,
        "",
      );

      // 물받이 고정 - 일반 클립
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_GUTTER_FIXING_NORMAL_CLIP,
        "",
      );

      // 물받이 배수 - 주름관
      this.drainagePleatedPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PLEATED_PIPE,
        true,
        false,
        true,
        CONST.LB_GUTTER_DRAINAGE_PLEATED_PIPE,
        "",
      );

      // 물받이 배수 - PVC관
      this.drainagePVCPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PVC_PIPE,
        false,
        false,
        false,
        CONST.LB_GUTTER_DRAINAGE_PVC_PIPE,
        "",
      );

      // 결로받이
      this.condensationGutterPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_CONDENSATION,
        false,
        false,
        false,
        CONST.LB_GUTTER_CONDENSATION,
        "",
        CONST.LB_GUTTER_CONDENSATION_TYPE_PLASTIC,
      );
    } else {
      // 철판 물받이
      this.ironPlatePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_IRON_PLATE,
        true,
        false,
        true,
        CONST.LB_GUTTER_IRON_PLATE,
        "",
      );

      // 플라스틱 물받이
      this.plasticPart.setDefaultData(CONST.INDEX_IL_GUTTER_PLASTIC, false, false, false, CONST.LB_GUTTER_PLASTIC, "");

      // 직조필름 물받이
      this.weavingFilmPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_WEAVING_FILM,
        false,
        false,
        false,
        CONST.LB_GUTTER_WEAVING_FILM,
        "",
      );

      // 물받이 고정 - 물받이 패드
      this.fixingGutterPadPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_GUTTER_PAD,
        false,
        false,
        false,
        CONST.LB_GUTTER_FIXING_GUTTER_PAD,
        "",
      );

      // 물받이 고정 - 나사
      this.fixingScrewPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_SCREW,
        true,
        false,
        true,
        CONST.LB_GUTTER_FIXING_SCREW,
        "",
      );

      // 물받이 고정 - 일반 클립
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_GUTTER_FIXING_NORMAL_CLIP,
        "",
      );

      // 물받이 배수 - 주름관
      this.drainagePleatedPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PLEATED_PIPE,
        false,
        false,
        false,
        CONST.LB_GUTTER_DRAINAGE_PLEATED_PIPE,
        "",
      );

      // 물받이 배수 - PVC관
      this.drainagePVCPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PVC_PIPE,
        false,
        false,
        false,
        CONST.LB_GUTTER_DRAINAGE_PVC_PIPE,
        "",
      );

      // 결로받이
      this.condensationGutterPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_CONDENSATION,
        false,
        false,
        false,
        CONST.LB_GUTTER_CONDENSATION,
        "",
        CONST.LB_GUTTER_CONDENSATION_TYPE_PLASTIC,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 6) {
      // 물받이 배수 - 주름관
      const drainagePleatedPipePart: GutterPartIL_DrainagePleatedPipe = new GutterPartIL_DrainagePleatedPipe();
      drainagePleatedPipePart.setAssociation(design, struct, work, level, this);
      drainagePleatedPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PLEATED_PIPE,
        false,
        false,
        false,
        CONST.LB_GUTTER_DRAINAGE_PLEATED_PIPE,
        "",
      );
      this.partAC.push(drainagePleatedPipePart);

      // 결로받이 파트 추가
      const condensationGutterPart: GutterPartIL_CondensationGutter = new GutterPartIL_CondensationGutter();
      condensationGutterPart.setAssociation(design, struct, work, level, this);
      condensationGutterPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_CONDENSATION,
        false,
        false,
        false,
        CONST.LB_GUTTER_CONDENSATION,
        "",
        CONST.LB_GUTTER_CONDENSATION_TYPE_PLASTIC,
      );
      this.partAC.push(condensationGutterPart);
    }
    if (this.partAC.length === 8 && (<Part>this.partAC[7]).label !== CONST.LB_GUTTER_DRAINAGE_PVC_PIPE) {
      // 물받이 배수 - PVC관
      const drainagePVCPipePart: GutterPartIL_DrainagePVCPipe = new GutterPartIL_DrainagePVCPipe();
      drainagePVCPipePart.setAssociation(design, struct, work, level, this);
      drainagePVCPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PVC_PIPE,
        false,
        false,
        false,
        CONST.LB_GUTTER_DRAINAGE_PVC_PIPE,
        "",
      );
      this.partAC.splice(7, 0, drainagePVCPipePart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_selectedByBuildingNumber();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 선택 <- 동수(기본정보)
   */
  public algorithm_selectedByBuildingNumber(): void {
    if (this.level.index === 0 && this.basic.buildingNumber === 1) {
      this.selected = false;
    }
    // else {
    //   this.selected = true;
    // }
  }

  /**
   * 알고리즘: 선택 <- 지붕 골조
   */
  public algorithm_selectedByRoof(): void {
    let roofPosition: RoofPositionIL | RoofPositionVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPosition = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
    }

    for (let i: number = 0; i <= this.struct.roofWorkIL.levelAC.length; i++) {
      this.selected = roofPosition.selected;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_GUTTER_TYPE_IRON_PLATE) {
      this.ironPlatePart.selected = true;
      this.ironPlatePart.visible = true;
      this.plasticPart.selected = false;
      this.plasticPart.visible = false;
      this.weavingFilmPart.selected = false;
      this.weavingFilmPart.visible = false;
    } else if (this.type === CONST.LB_GUTTER_TYPE_PLASTIC) {
      this.ironPlatePart.selected = false;
      this.ironPlatePart.visible = false;
      this.plasticPart.selected = true;
      this.plasticPart.visible = true;
      this.weavingFilmPart.selected = false;
      this.weavingFilmPart.visible = false;
    } else if (this.type === CONST.LB_GUTTER_TYPE_WEAVING_FILM) {
      this.ironPlatePart.selected = false;
      this.ironPlatePart.visible = false;
      this.plasticPart.selected = false;
      this.plasticPart.visible = false;
      this.weavingFilmPart.selected = true;
      this.weavingFilmPart.visible = true;
    } else if (this.type === CONST.LB_GUTTER_TYPE_NONE) {
      this.ironPlatePart.selected = false;
      this.ironPlatePart.visible = false;
      this.plasticPart.selected = false;
      this.plasticPart.visible = false;
      this.weavingFilmPart.selected = false;
      this.weavingFilmPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 물받이 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    if (this.fixingType === CONST.LB_GUTTER_FIXING_TYPE_GUTTER_PAD) {
      this.fixingGutterPadPart.selected = true;
      this.fixingGutterPadPart.visible = true;
      this.fixingScrewPart.selected = false;
      this.fixingScrewPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_GUTTER_FIXING_TYPE_SCREW) {
      this.fixingGutterPadPart.selected = false;
      this.fixingGutterPadPart.visible = false;
      this.fixingScrewPart.selected = true;
      this.fixingScrewPart.visible = true;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_GUTTER_FIXING_TYPE_NORMAL_CLIP) {
      this.fixingGutterPadPart.selected = false;
      this.fixingGutterPadPart.visible = false;
      this.fixingScrewPart.selected = false;
      this.fixingScrewPart.visible = false;
      this.fixingNormalClipPart.selected = true;
      this.fixingNormalClipPart.visible = true;
    } else if (this.fixingType === CONST.LB_GUTTER_FIXING_TYPE_NONE) {
      this.fixingGutterPadPart.selected = false;
      this.fixingGutterPadPart.visible = false;
      this.fixingScrewPart.selected = false;
      this.fixingScrewPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 물받이 배수 형태
   */
  public algorithm_partActivationByDrainageType(): void {
    if (this.drainageType === CONST.LB_GUTTER_DRAINAGE_TYPE_PLEATED_PIPE) {
      this.drainagePleatedPipePart.selected = true;
      this.drainagePleatedPipePart.visible = true;
      this.drainagePVCPipePart.selected = false;
      this.drainagePVCPipePart.visible = false;
    } else if (this.drainageType === CONST.LB_GUTTER_DRAINAGE_TYPE_PVC_PIPE) {
      this.drainagePleatedPipePart.selected = false;
      this.drainagePleatedPipePart.visible = false;
      this.drainagePVCPipePart.selected = true;
      this.drainagePVCPipePart.visible = true;
    } else if (
      this.drainageType === CONST.LB_GUTTER_DRAINAGE_TYPE_IRON_PLATE_EXTENTION ||
      this.drainageType === CONST.LB_GUTTER_DRAINAGE_TYPE_NONE
    ) {
      this.drainagePleatedPipePart.selected = false;
      this.drainagePleatedPipePart.visible = false;
      this.drainagePVCPipePart.selected = false;
      this.drainagePVCPipePart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 결로받이 선택
   */
  public algorithm_partActivationByCondensationGutterSelected(): void {
    if (this.condensationSelected === true) {
      this.condensationGutterPart.selected = true;
      this.condensationGutterPart.visible = true;
    } else {
      this.condensationGutterPart.selected = false;
      this.condensationGutterPart.visible = false;
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태
   */
  public algorithm_fixingTypeByType(): void {
    if (this.level.index === 0) {
      this.fixingType = CONST.LB_GUTTER_FIXING_TYPE_GUTTER_PAD;
    } else {
      if (this.type === CONST.LB_GUTTER_TYPE_WEAVING_FILM) {
        this.fixingType = CONST.LB_GUTTER_FIXING_TYPE_NORMAL_CLIP;
      } else if (this.type === CONST.LB_GUTTER_TYPE_NONE) {
        this.fixingType = CONST.LB_GUTTER_FIXING_TYPE_NONE;
      } else {
        this.fixingType = CONST.LB_GUTTER_FIXING_TYPE_SCREW;
      }
    }
  }

  /**
   * 알고리즘: 형태, 고정 형태 <- 기둥 골조 보 형태
   */
  public algorithm_TypeByBeamType(): void {
    if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
      if (columnPosition.prismBeamPart.beamType === CONST.LB_COLUMN_BEAM_TYPE_GUTTER_BEAM) {
        this.type = CONST.LB_GUTTER_TYPE_NONE;
        this.fixingType = CONST.LB_GUTTER_FIXING_TYPE_NONE;
      } else {
        this.type = CONST.LB_GUTTER_TYPE_IRON_PLATE;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
