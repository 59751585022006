import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Level } from "vhows-design/src/object/design/base/Level";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurCoverPositionIL],
})
export class CurCoverLevelIL extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public curCoverPosition: CurCoverPositionIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.curCoverPosition = new CurCoverPositionIL();

    this.positionAC = [this.curCoverPosition];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.curCoverPosition = <CurCoverPositionIL>this.positionAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index === 0) {
      if (this.basic.structureName !== CONST.LB_STRUCT_VENLO) {
        this.curCoverPosition.setDefaultData(
          0,
          false,
          true,
          true,
          CONST.LB_POSITION_CEILING_FRONT_BACK_LEFT_RIGHT,
          CONST.LB_LEVEL_1,
          CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE,
          CONST.LB_CURTAIN_AXIS_TYPE_IL,
          CONST.LB_COVER_SCREEN,
          CONST.LB_COVER_SCREEN,
          CONST.LB_COVER_SCREEN,
          CONST.LB_FIXING_TYPE_NORMAL_PAD,
        );
      } else {
        this.curCoverPosition.setDefaultData(
          0,
          false,
          true,
          true,
          CONST.LB_POSITION_CEILING_FRONT_BACK_LEFT_RIGHT,
          CONST.LB_LEVEL_1,
          CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_ENDPIECE,
          CONST.LB_CURTAIN_AXIS_TYPE_IL,
          CONST.LB_COVER_SCREEN,
          CONST.LB_COVER_SCREEN,
          CONST.LB_COVER_SCREEN,
          CONST.LB_FIXING_TYPE_NORMAL_PAD,
        );
      }
    } else if (index === 1 || index === 2 || index === 3) {
    }
    //   this.curCoverPosition.setDefaultData(
    //     0, false, true, true, CONST.LB_POSITION_CEILING,
    //     CONST.LB_COVER_TYPE_SCREEN,
    //     CONST.LB_COVER_SCOPE_TYPE_CEILING,
    //     CONST.LB_FIXING_TYPE_NONE,
    //   );
    // }
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work): void {
    super.restoreLatestObject(design, struct, work);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
