import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { ItemTowClip } from "vhows-design/src/object/design/item/list/ItemTowClip";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { ItemTrussClip } from "vhows-design/src/object/design/item/list/ItemTrussClip";
import { ColumnPartVL_Middle } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Middle";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { CurSwitchPartIL_FrameVL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_FrameVL";
import { Position } from "vhows-design/src/object/design/base/Position";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [],
})
export class CurSwitchPartIL_FixingClipCeiling extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플

  // 아이템
  public clipSupportAxis_normalClip: ItemClip;
  public clipSupport_normalClip: ItemClip;
  public clip_trussClip: ItemTrussClip;
  public clip_stickTowClip: ItemTowClip;
  public clip_hookTowClip: ItemTowClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _clipInterval: number; // 클립 간격
  @jsonMember(String)
  public _towClipType: string; // 예인 클립 형태

  /**
   * 클립 간격
   */
  public get clipInterval(): number {
    return this._clipInterval;
  }

  public set clipInterval(value: number) {
    this._clipInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 예인 클립 형태
   */
  public get towClipType(): string {
    return this._towClipType;
  }

  //
  public set towClipType(value: string) {
    this._towClipType = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플

    this.sampleAC = [];

    // 아이템
    this.clipSupportAxis_normalClip = new ItemClip();
    this.clipSupport_normalClip = new ItemClip();
    this.clip_trussClip = new ItemTrussClip();
    this.clip_stickTowClip = new ItemTowClip();
    this.clip_hookTowClip = new ItemTowClip();

    this.itemAC = [
      this.clipSupportAxis_normalClip,
      this.clipSupport_normalClip,
      this.clip_trussClip,
      this.clip_stickTowClip,
      this.clip_hookTowClip,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플

    // 아이템
    this.clipSupportAxis_normalClip = <ItemClip>this.itemAC[0];
    this.clipSupport_normalClip = <ItemClip>this.itemAC[1];
    this.clip_trussClip = <ItemTrussClip>this.itemAC[2];
    this.clip_stickTowClip = <ItemTowClip>this.itemAC[3];
    this.clip_hookTowClip = <ItemTowClip>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param clipInterval: number 클립 간격
   * @param towClipType: string 예인 클립 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    clipInterval: number = 0,
    towClipType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._clipInterval = clipInterval;
    this._towClipType = towClipType;

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      this.clipSupportAxis_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "커튼 클립 고정(커튼 지지 축 파이프)",
        "플라스틱, 32mm",
      );
      this.clipSupport_normalClip.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "커튼 클립 고정(커튼 지지 파이프)",
        "플라스틱, 25mm",
      );
      this.clip_trussClip.setDefaultData(
        2,
        CONST.ITEM_ID_TRUSS_CLIP,
        CONST.ITEM_NAME_TRUSS_CLIP,
        "커튼 클립 고정",
        "각형, 일반, 30mm",
      );
      this.clip_stickTowClip.setDefaultData(
        3,
        CONST.ITEM_ID_STICK_TOW_CLIP,
        CONST.ITEM_NAME_STICK_TOW_CLIP,
        "커튼 클립 고정",
        "플라스틱",
      );
      this.clip_hookTowClip.setDefaultData(
        4,
        CONST.ITEM_ID_HOOK_TOW_CLIP,
        CONST.ITEM_NAME_HOOK_TOW_CLIP,
        "커튼 클립 고정(하단)",
        "철",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    if (CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion) && this.itemAC.length !== 5) {
      const clip_trussClip = new ItemTrussClip();
      clip_trussClip.setAssociation(design, struct, work, level, position, this);
      clip_trussClip.setDefaultData(
        2,
        CONST.ITEM_ID_TRUSS_CLIP,
        CONST.ITEM_NAME_TRUSS_CLIP,
        "커튼 클립 고정",
        "각형, 일반, 30mm",
      );
      this.itemAC.splice(2, 0, clip_trussClip);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curCoverPosition: CurCoverPositionIL = <CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition;

    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;
    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 선택, 가시성 ///////////

    this.clipSupportAxis_normalClip.selected = true;
    this.clipSupportAxis_normalClip.visible = true;
    this.clipSupport_normalClip.selected = true;
    this.clipSupport_normalClip.visible = true;
    this.clip_trussClip.selected = false;
    this.clip_trussClip.visible = false;
    if (this.towClipType === CONST.LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK) {
      this.clip_stickTowClip.selected = true;
      this.clip_stickTowClip.visible = true;
      this.clip_hookTowClip.selected = false;
      this.clip_hookTowClip.visible = false;
    } else if (this.towClipType === CONST.LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK_AND_HOOK) {
      this.clip_stickTowClip.selected = true;
      this.clip_stickTowClip.visible = true;
      this.clip_hookTowClip.selected = true;
      this.clip_hookTowClip.visible = true;
    }

    if (curSwitchPosition.fixingType === CONST.LB_CURTAIN_SWITCH_FIXING_TRUSS_CLIP) {
      this.clipSupportAxis_normalClip.selected = false;
      this.clipSupportAxis_normalClip.visible = false;
      this.clipSupport_normalClip.selected = false;
      this.clipSupport_normalClip.visible = false;
      this.clip_trussClip.selected = true;
      this.clip_trussClip.visible = true;

      if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_IL) {
        this.clip_trussClip.selected = false;
      }
    }

    /// //////// 수량 ///////////

    this.clipSupportAxis_normalClip.designQuantity =
      Math.ceil(this.basicLevel.length / this.clipInterval) *
      curSwitchPosition.framePart.supportAxisLineNumber *
      curCoverPosition.ceilingLevelValue;

    let clipSupport_normalClip_designQuantity: number =
      Math.ceil(this.basicLevel.length / this.clipInterval) *
      (this.basic.buildingNumber + 1 - curSwitchPosition.framePart.supportAxisLineNumber);
    if (curSwitchPosition.framePart.pocketFixingPipeSelected) {
      clipSupport_normalClip_designQuantity +=
        Math.ceil((this.basicLevel.width * this.basic.buildingNumber) / this.clipInterval) * 2;
    }
    this.clipSupport_normalClip.designQuantity = clipSupport_normalClip_designQuantity;

    if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
      if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
        this.clip_trussClip.designQuantity =
          Math.ceil(this.basicLevel.width / this.clipInterval + 1) *
          (columnPosition.prismMiddlePart.middle_pipe.getSelectedQuantity() +
            this.struct.columnWorkVL.level1.columnPosition.prismMiddlePart.frontAndBackMiddle_pipe.getSelectedQuantity()) *
          curCoverPosition.ceilingLevelValue *
          CONST.NUM_EXTRA_RATE_TRUSS_CLIP;
      } else {
        this.clip_trussClip.designQuantity =
          Math.ceil(this.basicLevel.width / this.clipInterval + 1) *
          columnPosition.prismMiddlePart.middle_pipe.getSelectedQuantity() *
          curCoverPosition.ceilingLevelValue *
          CONST.NUM_EXTRA_RATE_TRUSS_CLIP;
      }
    }

    if (this.towClipType === CONST.LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK) {
      if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_IL) {
        this.clip_stickTowClip.designQuantity =
          curSwitchPosition.linePart.towLineNumber *
          2 *
          Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
          curCoverPosition.ceilingLevelValue *
          this.basic.buildingNumber *
          CONST.NUM_EXTRA_RATE_TOW_CLIP;
      } else if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
        this.clip_stickTowClip.designQuantity =
          curSwitchPosition.linePart.towLineNumber *
          Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
          curCoverPosition.ceilingLevelValue *
          this.basic.buildingNumber *
          CONST.NUM_EXTRA_RATE_TOW_CLIP;
      }
      this.clip_hookTowClip.designQuantity = 0;
    } else if (this.towClipType === CONST.LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK_AND_HOOK) {
      this.clip_stickTowClip.designQuantity =
        curSwitchPosition.linePart.towLineNumber *
        Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
        curCoverPosition.ceilingLevelValue *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_TOW_CLIP;

      this.clip_hookTowClip.designQuantity =
        curSwitchPosition.linePart.towLineNumber *
        Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
        curCoverPosition.ceilingLevelValue *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_TOW_CLIP;
    }
  }

  /**
   * 알고리즘: 예인 클립 형태 <- 축 방향
   */
  public algoritm_towClipType(): void {
    const curCoverPosition: CurCoverPositionIL = <CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition;
    if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
      this.towClipType = CONST.LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK;
    } else {
      this.towClipType = CONST.LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK_AND_HOOK;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [커튼 클립 고정(커튼 지지 축 파이프)] 일반 클립 <- 커튼 지지 축 파이프 샘플
   */
  public algorithmSpec_clipSupportAxis_normalClip(): void {
    this.clipSupportAxis_normalClip.specCrossSize = (<CurSwitchPositionIL>(
      this.position
    )).framePart.supportAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 클립 고정(커튼 지지 파이프)] 일반 클립 <- 커튼 지지 파이프 샘플
   */
  public algorithmSpec_clipSupport_normalClip(): void {
    this.clipSupport_normalClip.specCrossSize = (<CurSwitchPositionIL>(
      this.position
    )).framePart.supportPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 트러스 클립 고정] 트러스 클립 <- 트러스 파이프 샘플
   */
  public algorithmSpec_trussClip(): void {
    if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const middleColumnPart: ColumnPartVL_Middle = <ColumnPartVL_Middle>(
        this.struct.columnWorkVL.level1.columnPosition.prismMiddlePart
      );
      // 하드코딩
      if (middleColumnPart.middlePipeSample.specCrossSize === "75x45mm") {
        this.clip_trussClip.specCrossSize = "45mm";
      } else if (middleColumnPart.middlePipeSample.specCrossSize === "50x30mm") {
        this.clip_trussClip.specCrossSize = "30mm";
      } else {
        this.clip_trussClip.specCrossSize = "45mm";
      }
    }
  }

  /**
   * 규격 알고리즘: [커튼 클립 고정] 막대 예인 클립 <- 없음
   */
  public algorithmSpec_clip_stickTowClip(): void {}

  /**
   * 규격 알고리즘: [커튼 클립 고정(하단)] 고리 예인 클립 <- 없음
   */
  public algorithmSpec_clip_hookTowClip(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
