import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { WindowPartIL_FixingLaggingPad } from "vhows-design/src/object/design/other/window/il/WindowPartIL_FixingLaggingPad";
import { WindowPartIL_FixingNormalPad } from "vhows-design/src/object/design/other/window/il/WindowPartIL_FixingNormalPad";
import { WindowPartIL_FixingWood } from "vhows-design/src/object/design/other/window/il/WindowPartIL_FixingWood";
import { WindowPartIL_Hinged } from "vhows-design/src/object/design/other/window/il/WindowPartIL_Hinged";
import { WindowPartIL_Sash } from "vhows-design/src/object/design/other/window/il/WindowPartIL_Sash";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [
    WindowPartIL_FixingLaggingPad,
    WindowPartIL_FixingNormalPad,
    WindowPartIL_FixingWood,
    WindowPartIL_Hinged,
    WindowPartIL_Sash,
  ],
})
export class WindowPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public hingedPart: WindowPartIL_Hinged;
  public sashPart: WindowPartIL_Sash;
  public fixingNormalPadPart: WindowPartIL_FixingNormalPad;
  public fixingLaggingPadPart: WindowPartIL_FixingLaggingPad;
  public fixingWoodPart: WindowPartIL_FixingWood;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _windowQuantity: number; // 창문 수량
  @jsonMember(Number)
  public _windowInterval: number; // 창문 간격
  @jsonMember(String)
  public _fixingType: string; // 고정 형태

  /**
   * 창문 수량
   */
  public get windowQuantity(): number {
    return this._windowQuantity;
  }

  //
  public set windowQuantity(value: number) {
    this._windowQuantity = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 창문 간격
   */
  public get windowInterval(): number {
    return this._windowInterval;
  }

  //
  public set windowInterval(value: number) {
    this._windowInterval = value;

    // 알고리즘
    this.algorithm_windowQuantity();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 위치
    this.algorithm_typeByPosition();

    /// //////// 외부 ///////////
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.hingedPart = new WindowPartIL_Hinged();
    this.sashPart = new WindowPartIL_Sash();
    this.fixingNormalPadPart = new WindowPartIL_FixingNormalPad();
    this.fixingLaggingPadPart = new WindowPartIL_FixingLaggingPad();
    this.fixingWoodPart = new WindowPartIL_FixingWood();

    this.partAC = [
      this.hingedPart,
      this.sashPart,
      this.fixingNormalPadPart,
      this.fixingLaggingPadPart,
      this.fixingWoodPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.hingedPart = <WindowPartIL_Hinged>this.partAC[0];
    this.sashPart = <WindowPartIL_Sash>this.partAC[1];
    this.fixingNormalPadPart = <WindowPartIL_FixingNormalPad>this.partAC[2];
    this.fixingLaggingPadPart = <WindowPartIL_FixingLaggingPad>this.partAC[3];
    this.fixingWoodPart = <WindowPartIL_FixingWood>this.partAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param windowQuantity: number 창문 수량
   * @param fixingType: string 고정 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    windowQuantity: number = 0,
    windowInterval: number = 0,
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._windowQuantity = windowQuantity;
    this._windowInterval = windowInterval;
    this._fixingType = fixingType;

    if (this.level.index >= 0) {
      // 여닫이창문
      this.hingedPart.setDefaultData(
        CONST.INDEX_CM_WINDOW_HINGED,
        true,
        false,
        true,
        CONST.LB_WINDOW_HINGED,
        "",
        2000,
        1000,
      );

      // 샷시창틀
      this.sashPart.setDefaultData(
        CONST.INDEX_CM_WINDOW_SASH,
        false,
        false,
        false,
        CONST.LB_WINDOW_SASH,
        "",
        CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER,
        1000,
        1000,
      );

      // 창문주변 피복 고정 - 일반 패드 고정
      this.fixingNormalPadPart.setDefaultData(
        CONST.INDEX_CM_WINDOW_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_WINDOW_FIXING_NORMAL_PAD,
        "",
      );

      // 창문주변 피복 고정 - 광폭 패드 고정
      this.fixingLaggingPadPart.setDefaultData(
        CONST.INDEX_CM_WINDOW_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_WINDOW_FIXING_LAGGING_PAD,
        "",
      );

      // 창문주변 피복 고정 - 목재 고정
      this.fixingWoodPart.setDefaultData(
        CONST.INDEX_CM_WINDOW_FIXING_WOOD,
        false,
        false,
        false,
        CONST.LB_WINDOW_FIXING_WOOD,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_windowQuantity();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 선택(앞뒷면 위치), 가용성(앞뒷면 위치) <- 선택(마구리 골조)
   */
  public algorithm_selectedAndEnabledByEndpiece(): void {
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true) {
        this.enabled = true;
      } else {
        this.enabled = false;
        this.selected = false;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true) {
        this.enabled = true;
      } else {
        this.enabled = false;
        this.selected = false;
      }
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_WINDOW_TYPE_HINGED) {
      this.hingedPart.selected = true;
      this.hingedPart.visible = true;
      this.sashPart.selected = false;
      this.sashPart.visible = false;
    } else if (this.type === CONST.LB_WINDOW_TYPE_SASH) {
      this.hingedPart.selected = false;
      this.hingedPart.visible = false;
      this.sashPart.selected = true;
      this.sashPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
      this.fixingNormalPadPart.selected = true;
      this.fixingNormalPadPart.visible = true;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingLaggingPadPart.selected = true;
      this.fixingLaggingPadPart.visible = true;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_WOOD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = true;
      this.fixingWoodPart.visible = true;
    } else {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태(피복 고정)
   */
  public algorithm_fixingTypeByFixing(): void {
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];

    if (this.label === CONST.LB_POSITION_LEFT || this.label === CONST.LB_POSITION_RIGHT) {
      if (
        fixingLevel.columnPosition.type === CONST.LB_FIXING_TYPE_NORMAL_PAD ||
        fixingLevel.columnPosition.type === CONST.LB_FIXING_TYPE_LAGGING_PAD ||
        fixingLevel.columnPosition.type === CONST.LB_FIXING_TYPE_WOOD
      ) {
        // 좌측, 우측은 측면 피복 고정 형태를 따름
        this.fixingType = fixingLevel.columnPosition.type;
      }
    } else if (this.label === CONST.LB_POSITION_FRONT) {
      if (
        fixingLevel.frontPosition.type === CONST.LB_FIXING_TYPE_NORMAL_PAD ||
        fixingLevel.frontPosition.type === CONST.LB_FIXING_TYPE_LAGGING_PAD ||
        fixingLevel.frontPosition.type === CONST.LB_FIXING_TYPE_WOOD
      ) {
        // 앞면은 앞면 피복 고정 형태를 따름
        this.fixingType = fixingLevel.frontPosition.type;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (
        fixingLevel.backPosition.type === CONST.LB_FIXING_TYPE_NORMAL_PAD ||
        fixingLevel.backPosition.type === CONST.LB_FIXING_TYPE_LAGGING_PAD ||
        fixingLevel.backPosition.type === CONST.LB_FIXING_TYPE_WOOD
      ) {
        // 뒷면은 뒷면 피복 고정 형태를 따름
        this.fixingType = fixingLevel.backPosition.type;
      }
    }
  }

  /**
   * 알고리즘: 창문 수량 <- 길이(기본정보), 동수(기본정보)
   */
  public algorithm_windowQuantity(): void {
    if (this.label === CONST.LB_POSITION_LEFT || this.label === CONST.LB_POSITION_RIGHT) {
      const windowQuantityPerBuilding = Math.floor(this.basicLevel.length / this.windowInterval);
      if (this.windowInterval === 0 || windowQuantityPerBuilding <= 1) {
        this.windowQuantity = 1;
      } else {
        this.windowQuantity = windowQuantityPerBuilding;
      }
    } else if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
      this.windowQuantity = this.basic.buildingNumber;
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(포지션)
   */
  public algorithm_typeByPosition(): void {
    if (this.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevelIL>this.level).rightPosition.type = this.type;
      (<WindowLevelIL>this.level).frontPosition.type = this.type;
      (<WindowLevelIL>this.level).backPosition.type = this.type;
    } else if (this.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevelIL>this.level).backPosition.type = this.type;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
