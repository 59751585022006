import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { ColumnPartIL_PrismMiddle } from "vhows-design/src/object/design/frame/column/ColumnPartIL_PrismMiddle";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-15
 */
@jsonObject
export class ColumnSampleIL_PrismMiddlePipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismMiddlePart: ColumnPartIL_PrismMiddle = <ColumnPartIL_PrismMiddle>this.part;
    prismMiddlePart.algorithmSpec_middle_pipe();
    prismMiddlePart.algorithmSpec_middleTrussLower_pipe();
    prismMiddlePart.algorithmSpec_middleAndMiddle_connectionPin();
    prismMiddlePart.algorithmSpec_middleAndColumnCenter_crossClamp();
    prismMiddlePart.algorithmSpec_middleAndColumnEdge_tClamp();
    prismMiddlePart.middleSupportWorkedPipeSample.algorithmSpec();
    prismMiddlePart.middleBracingPipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    if (this.position.type === CONST.LB_COLUMN_TYPE_PRISM) {
      // 마구리 골조
      for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) {
        // 모든 중수
        for (const endpiecePosition of endpieceLevel.positionAC as EndpiecePositionIL[]) {
          endpiecePosition.cylinderStudPart.algorithmSpec_studAndMiddlePrism_uClamp();
          endpiecePosition.prismStudSepPart.algorithmSpec_studBottomAndMiddle_tClamp();
        }
      }

      // 선반/균상다이
      if (this.level.index === 0) {
        const rackPosition: RackPosition = this.struct.rackWork.level1.wholePosition;
        rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tClamp();
        rackPosition.rackPart.algorithmSpec_rackColumnAndFrame2_normalHolder();
        rackPosition.rackPart.algorithmPart();
      }

      // 운반레일
      if (this.level.index === 0) {
        this.struct.railWork.level1.wholePosition.upperRailPart.algorithmSpec_railAndFrame_secondLevelRailHook();
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = (<ColumnPositionIL>this.position).prismColumnPart.columnPipeSample.specsList;
  }

  /**
   * 규격 알고리즘 : 길이 <- 폭(기본정보)
   */
  public algorithmSpec_specLength(): void {
    this.specLength = this.basicLevel.width;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
