import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { Part } from "vhows-design/src/object/design/base/Part";
import { BandstringSampleIL_HookHolder } from "vhows-design/src/object/design/cover/bandstring/il/BandstringSampleIL_HookHolder";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";
import { BandstringLevelIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [BandstringSampleIL_HookHolder],
})
export class BandstringPartIL_FixingHook extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public hookHolderSample: BandstringSampleIL_HookHolder; // 고리 고정구 샘플

  // 아이템
  public hook_hookHolder: ItemHolder;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.hookHolderSample = new BandstringSampleIL_HookHolder();

    this.sampleAC = [this.hookHolderSample];

    // 아이템
    this.hook_hookHolder = new ItemHolder();

    this.itemAC = [this.hook_hookHolder];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.hookHolderSample = this.sampleAC[0] as BandstringSampleIL_HookHolder;

    // 아이템
    this.hook_hookHolder = this.itemAC[0] as ItemHolder;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.hookHolderSample.setDefaultData(
        0,
        CONST.ITEM_ID_HOOK_HOLDER,
        CONST.ITEM_NAME_HOOK_HOLDER,
        "고리",
        "원형, 일반, 25mm, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.hook_hookHolder.setDefaultData(
        0,
        CONST.ITEM_ID_HOOK_HOLDER,
        CONST.ITEM_NAME_HOOK_HOLDER,
        "고리",
        "원형, 일반, 25mm, -",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const bandstringPosition: BandstringPositionIL = <BandstringPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.hook_hookHolder.selected = true;
    this.hook_hookHolder.visible = true;

    /// //////// 수량 ///////////

    if (this.position.label === CONST.LB_POSITION_SIDE_AND_ROOF) {
      this.hook_hookHolder.designQuantity =
        (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
        2 *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_HOOK_HOLDER;
    } else if (this.position.label === CONST.LB_POSITION_SIDE) {
      if (
        (<BandstringLevelIL>this.level).trunkPosition.selected === true &&
        (<BandstringLevelIL>this.level).trunkPosition.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_HOOK
      ) {
        this.hook_hookHolder.designQuantity =
          (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
          1 *
          this.basic.buildingNumber *
          CONST.NUM_EXTRA_RATE_HOOK_HOLDER;
      } else {
        this.hook_hookHolder.designQuantity =
          (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
          2 *
          this.basic.buildingNumber *
          CONST.NUM_EXTRA_RATE_HOOK_HOLDER;
      }
    } else if (this.position.label === CONST.LB_POSITION_ROOF) {
      this.hook_hookHolder.designQuantity =
        (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 3) *
        2 *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_HOOK_HOLDER;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [고리] 고리 고정구 <- 고리 고정구 샘플
   */
  public algorithmSpec_hook_hookHolder(): void {
    this.hook_hookHolder.specs = this.hookHolderSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
