import { jsonMember, jsonObject } from "typedjson";
import { Company } from "vhows-design/src/object/company/Company";
import { User } from "vhows-design/src/object/user/User";
import { UserConst } from "vhows-design/src/common/constant/UserConst";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-04-15
 */
@jsonObject({
  knownTypes: [Company],
})
export class BusinessUser extends User {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 회사
  @jsonMember(Company)
  public company: Company = new Company();

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 비즈니스유저 아이디
  @jsonMember(Number)
  public id: number = null;
  // 회사 휴대폰번호
  @jsonMember(String)
  public companyMobileNumber: string = null;
  // 회사 전화번호
  @jsonMember(String)
  public companyPhoneNumber: string = null;
  // 회사 팩스번호
  @jsonMember(String)
  public companyFaxNumber: string = null;
  // 부서
  @jsonMember(String)
  public memberDept: string = null;
  // 직급
  @jsonMember(String)
  public memberRank: string = null;
  // 직책
  @jsonMember(String)
  public memberDuty: string = null;
  // 업무 상태
  @jsonMember(String)
  public workStatus: string = UserConst.workStatus.EMPLOYMENT;

  //----------------------------------
  // DTO 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor(company: Company = null) {
    super();
    if (company) {
      this.company = company;
    } else {
      this.company = new Company();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setAssociation(): void {
    super.setAssociation();

    this.company.setAssociation();
  }

  // @override
  public setDefaultData(): void {
    super.setDefaultData();

    this.companyMobileNumber = "";
    this.companyPhoneNumber = "070-8623-3385";
    this.companyFaxNumber = "";
    this.memberDept = "";
    this.memberRank = "";
    this.memberDuty = "";
    this.workStatus = "";

    this.company.setDefaultData();
  }
}
