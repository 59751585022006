import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemPipeBendingExpense } from "vhows-design/src/object/design/item/list/ItemPipeBendingExpense";
import { ItemSconce } from "vhows-design/src/object/design/item/list/ItemSconce";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { SkirtLevel } from "vhows-design/src/object/design/cover/skirt/SkirtLevel";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { PartitionLevel } from "vhows-design/src/object/design/other/partition/PartitionLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { BandstringLevel } from "vhows-design/src/object/design/cover/bandstring/BandstringLevel";
import { WindowPosition } from "vhows-design/src/object/design/other/window/WindowPosition";
import { GutterLevel } from "vhows-design/src/object/design/frame/gutter/GutterLevel";
import { TrunkSample_RafterPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_RafterPipe";
import { TrunkSample_RafterExtensionPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_RafterExtensionPipe";
import { TrunkSample_BracingPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_BracingPipe";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { RafterExtensionData } from "vhows-design/src/object/design/frame/trunk/TrunkPartData";
import notify from "devextreme/ui/notify";
import { DesignConst } from "vhows-design/src/common/constant/DesignConst";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-23
 */
@jsonObject({
  knownTypes: [TrunkSample_RafterPipe, TrunkSample_RafterExtensionPipe, TrunkSample_BracingPipe],
})
export class TrunkPart_Rafter extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public rafterPipeSample: TrunkSample_RafterPipe; // 서까래 파이프 샘플
  public rafterExtensionPipeSample: TrunkSample_RafterExtensionPipe; // 서까래 확장 파이프 샘플
  public bracingPipeSample: TrunkSample_BracingPipe; // 브레싱 파이프 샘플

  // 아이템
  public rafter_pipe: ItemPipe;
  public rafter_pipeBendingExpense: ItemPipeBendingExpense;
  public extensionPinned_pipe: ItemPipe;
  public extensionNormal_pipe: ItemPipe;
  public extension_pipeBendingExpense: ItemPipeBendingExpense;
  public extensionNormal_connectionPin: ItemConnector;
  public extensionColumn_pipe: ItemPipe;
  public extensionBeam_pipe: ItemPipe;
  public extensionBeam_connectionPin: ItemConnector;
  public extensionBeamAndFrame_saddleHolder: ItemHolder;
  public extensionBeamAndColumn_tHolder: ItemHolder;
  public extensionBeamAndColumn_tClamp: ItemHolder;
  public extensionBeamAndRafter_tHolder: ItemHolder;
  public extensionBeamAndRafter_firstLevelSoleSconce: ItemSconce;
  public extensionBeamAndRafter_secondLevelSoleSconce: ItemSconce;
  public bracing_pipe: ItemPipe;
  public bracingAndRafter_itemHolder: ItemHolder;
  public rafter_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 이상적인 서까래 길이
   */
  public get lengthRafterIdeal(): number {
    return this.basicLevel.lengthRafterIdeal;
  }

  //
  public set lengthRafterIdeal(value: number) {
    this.basicLevel.lengthRafterIdeal = value;

    this.rafterPipeSample.algorithmSpec_specLength();
    this.rafterPipeSample.algorithmSpec_specLengthByRafterExtension();
    if (this.level.index >= 1) {
      this.rafterPipeSample.algorithmSpec_specLengthByTotalRafterLength();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _rafterInterval: number; // 서까래 간격
  @jsonMember(Number)
  public _rafterTotalLength: number; // 최종 서까래 길이
  @jsonMember(Number)
  public _rafterQuantity: number; // 서까래 수량
  @jsonMember(Boolean)
  public _rafterLengthRoundSelected: boolean; // 서까래 길이 반올림 선택

  @jsonMember(Boolean)
  public _trussSelected: boolean; // 서까래 트러스 선택
  @jsonMember(Number)
  public _trussMultiple: number; // 서까래 트러스 배율
  @jsonMember(Number)
  public _trussInterval: number; // 서까래 트러스 간격

  @jsonMember(Boolean)
  public _extensionSelected: boolean; // 서까래 확장 선택
  @jsonMember(String)
  public _extensionPosition: string; // 서까래 확장 위치
  @jsonMember(String)
  public _extensionType: string; // 서까래 확장 형태
  @jsonMember(Number)
  public _extensionLength: number; // 서까래 확장 길이
  @jsonMember(Number)
  public _extensionQuantity: number; // 서까래 확장 수량
  @jsonMember(Number)
  public _extensionInterval: number; // 서까래 확장 간격

  @jsonMember(Number)
  public _bracingQuantity: number; // 브레싱 수량

  /**
   * 서까래 간격
   */
  public get rafterInterval(): number {
    return this._rafterInterval;
  }

  //
  public set rafterInterval(value: number) {
    this._rafterInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_rafterQuantity();
    this.algorithm_extensionInterval();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 물받이
    for (const gutterLevel of this.struct.gutterWork.levelAC as GutterLevel[]) {
      gutterLevel.gutterPosition.ironPlatePart.algorithmPart();
      gutterLevel.gutterPosition.plasticPart.algorithmPart();
      gutterLevel.gutterPosition.weavingFilmPart.algorithmPart();
    }

    if (this.level.index === 0) {
      this.struct.gutterWork.level1.gutterPosition.condensationGutterPart.algorithmPart();
    }

    // 치마
    (<SkirtLevel>(
      this.struct.skirtWork.levelAC[this.level.index]
    )).sidePosition.fixingSkirtClipPart.algorithm_clipInterval();
    (<SkirtLevel>(
      this.struct.skirtWork.levelAC[this.level.index]
    )).roofPosition.fixingSkirtClipPart.algorithm_clipInterval();

    // 개폐기
    (<SwitcherLevel>(
      this.struct.switcherWork.levelAC[this.level.index]
    )).sidePosition.axisPart.algorithm_towLineInterval();
    (<SwitcherLevel>this.struct.switcherWork.levelAC[this.level.index]).roofPosition.axisPart.algorithmPart();

    // 밴드끈
    if (this.level.index === 0) {
      this.struct.bandstringWork.level1.trunkPosition.algorithm_lineIntervalByRafter();
      this.struct.bandstringWork.level1.trunkPosition.fixingNormalPadPart.algorithmPart();
    }

    // 창문
    if (this.level.index === 0) {
      for (const windowPosition of this.struct.windowWork.level1.positionAC as WindowPosition[]) {
        windowPosition.sashPart.algorithmPart();
      }
    }

    // 천창 골조
    if (this.level.index === 0) {
      this.struct.skyFrameWork.level1.skylightPosition.rafterPart.algorithm_rafterInterval();
    }

    // 관수 지관부
    this.struct.wateringWork.level1.wholePosition.hoseSidePart.algorithm_hangerInterval();

    // 운반레일
    if (this.level.index === this.basic.levelNumber - 1) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithm_railHookInterval();
      this.struct.railWork.level1.wholePosition.sideRailPart.algorithm_railHookInterval();
    }
  }

  /**
   * 최종 서까래 길이
   */
  public get rafterTotalLength(): number {
    return this._rafterTotalLength;
  }

  //
  public set rafterTotalLength(value: number) {
    this._rafterTotalLength = CommonUtil.fixFloat(value);

    // 중수
    this.algorithm_rafterTotalLengthByLevel(); // 가장 먼저 동작시켜야함

    // 알고리즘
    if (this.rafterTotalLength !== value) {
      this.algorithm_rafterExtension();
    }

    /// //////// 외부 ///////////

    // 기본정보
    this.design.basic.basicLevelAC[this.level.index].algorithm_perimeterRoof();

    // 개폐기
    (<SwitcherLevel>(
      this.struct.switcherWork.levelAC[this.level.index]
    )).roofPosition.algorithm_switchHeightWithSwitchWayFull();
    (<SwitcherLevel>(
      this.struct.switcherWork.levelAC[this.level.index]
    )).sidePosition.algorithm_switchHeightWithSwitchWayFull();
    (<SwitcherLevel>(
      this.struct.switcherWork.levelAC[this.level.index]
    )).sidePosition.supportPart.rafterSupportPipeSample.algorithmSpec();
    (<SwitcherLevel>(
      this.struct.switcherWork.levelAC[this.level.index]
    )).roofPosition.supportPart.rafterSupportPipeSample.algorithmSpec();

    // 밴드끈
    if (this.level.index === 0) {
      (<BandstringLevel>(
        this.struct.bandstringWork.levelAC[this.level.index]
      )).trunkPosition.bandstringPart.algorithm_bandstringLength();
      (<BandstringLevel>(
        this.struct.bandstringWork.levelAC[this.level.index]
      )).trunkPosition.normalPart.algorithm_bandstringLength();
      (<BandstringLevel>(
        this.struct.bandstringWork.levelAC[this.level.index]
      )).trunkPosition.polyPart.algorithm_bandstringLength();
    }

    // 칸막이
    if (this.level.index === 0) {
      (<PartitionLevel>(
        this.struct.partitionWork.levelAC[this.level.index]
      )).trunkPosition.fixingNormalPadPart.algorithmPart();
      (<PartitionLevel>(
        this.struct.partitionWork.levelAC[this.level.index]
      )).trunkPosition.fixingLaggingPadPart.algorithmPart();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 서까래 수량
   */
  public get rafterQuantity(): number {
    return this._rafterQuantity;
  }

  //
  public set rafterQuantity(value: number) {
    this._rafterQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<TrunkPosition>this.position).straightPart.algorithmPart();

    /// //////// 외부 ///////////

    // 천창 골조
    if (this.level.index === 0) {
      this.struct.skyFrameWork.level1.skylightPosition.enhancedPart.algorithmPart();
    }
  }

  /**
   * 서까래 길이 반올림 선택
   */
  public get rafterLengthRoundSelected(): boolean {
    return this._rafterLengthRoundSelected;
  }

  //
  public set rafterLengthRoundSelected(value: boolean) {
    this._rafterLengthRoundSelected = value;

    // 알고리즘
    this.rafterPipeSample.algorithmSpec_specLength();
    this.algorithmPart();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_rafterLengthRoundSelectedByLevel1();
    }
  }

  /**
   * 서까래 트러스 선택
   */
  public get trussSelected(): boolean {
    return this._trussSelected;
  }

  //
  public set trussSelected(value: boolean) {
    this._trussSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 서까래 트러스 배율
   */
  public get trussMultiple(): number {
    return this._trussMultiple;
  }

  //
  public set trussMultiple(value: number) {
    this._trussMultiple = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 서까래 트러스 간격
   */
  public get trussInterval(): number {
    return this._trussInterval;
  }

  //
  public set trussInterval(value: number) {
    this._trussInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 서까래 확장 선택
   */
  public get extensionSelected(): boolean {
    return this._extensionSelected;
  }

  //
  public set extensionSelected(value: boolean) {
    this._extensionSelected = value;

    // 알고리즘
    this.algorithm_rafterLengthRoundSelectedByExtensionSelected();
    this.rafterPipeSample.algorithmSpec_specLengthByRafterExtension();
    this.algorithm_rafterTotalLength();
    this.algorithmPart();
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 서까래 확장 위치
   */
  public get extensionPosition(): string {
    return this._extensionPosition;
  }

  //
  public set extensionPosition(value: string) {
    this._extensionPosition = value;

    // 알고리즘
    this.algorithm_extensionQuantity();
    this.algorithmPart();
    this.rafterPipeSample.algorithmSpec_specLengthByRafterExtension();
    this.algorithm_rafterTotalLength();

    // 중수
    this.algorithm_extensionPositionLevel1();

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  //
  /**
   * 서까래 확장 위치값 (편측=1, 양측=2)
   */
  public get extensionPositionValue(): number {
    if (this.extensionPosition === CONST.LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE) {
      return 1;
    }
    return 2;
  }

  /**
   * 서까래 확장 형태
   */
  public get extensionType(): string {
    return this._extensionType;
  }

  //
  public set extensionType(value: string) {
    this._extensionType = value;

    // 알고리즘
    this.algorithm_extensionInterval();
    this.rafterExtensionPipeSample.algorithmSpec();
    this.algorithmPart();
    this.rafterExtensionPipeSample.algorithmSpec_level();

    // 중수
    this.algorithm_extensionTypeLevel1();

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 서까래 확장 길이
   */
  public get extensionLength(): number {
    return this._extensionLength;
  }

  //
  public set extensionLength(value: number) {
    this._extensionLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
    this.rafterPipeSample.algorithmSpec_specLengthByRafterExtension();
    this.algorithm_rafterTotalLength();
    this.algorithmSpec_extensionPinned_pipe();
    this.rafterExtensionPipeSample.algorithmSpec();
  }

  /**
   * 서까래 확장 수량
   */
  public get extensionQuantity(): number {
    return this._extensionQuantity;
  }

  //
  public set extensionQuantity(value: number) {
    this._extensionQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 서까래 확장 간격
   */
  public get extensionInterval(): number {
    return this._extensionInterval;
  }

  //
  public set extensionInterval(value: number) {
    this._extensionInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_extensionQuantity();
    this.algorithmPart();
  }

  /**
   * 브레싱 수량
   */
  public get bracingQuantity(): number {
    return this._bracingQuantity;
  }

  public set bracingQuantity(value: number) {
    this._bracingQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 중수
    if (this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH && this.level.index === 0) {
      this.algorithm_bracingQuantityByLength();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.rafterPipeSample = new TrunkSample_RafterPipe();
    this.rafterExtensionPipeSample = new TrunkSample_RafterExtensionPipe();
    this.bracingPipeSample = new TrunkSample_BracingPipe();

    this.sampleAC = [this.rafterPipeSample, this.rafterExtensionPipeSample, this.bracingPipeSample];

    // 아이템
    this.rafter_pipe = new ItemPipe();
    this.rafter_pipeBendingExpense = new ItemPipeBendingExpense();
    this.extensionPinned_pipe = new ItemPipe();
    this.extensionNormal_pipe = new ItemPipe();
    this.extension_pipeBendingExpense = new ItemPipeBendingExpense();
    this.extensionNormal_connectionPin = new ItemConnector();
    this.extensionColumn_pipe = new ItemPipe();
    this.extensionBeam_pipe = new ItemPipe();
    this.extensionBeam_connectionPin = new ItemConnector();
    this.extensionBeamAndFrame_saddleHolder = new ItemHolder();
    this.extensionBeamAndColumn_tHolder = new ItemHolder();
    this.extensionBeamAndColumn_tClamp = new ItemHolder();
    this.extensionBeamAndRafter_tHolder = new ItemHolder();
    this.extensionBeamAndRafter_firstLevelSoleSconce = new ItemSconce();
    this.extensionBeamAndRafter_secondLevelSoleSconce = new ItemSconce();
    this.bracing_pipe = new ItemPipe();
    this.bracingAndRafter_itemHolder = new ItemHolder();
    this.rafter_screw = new ItemScrew();

    this.itemAC = [
      this.rafter_pipe,
      this.rafter_pipeBendingExpense,
      this.extensionPinned_pipe,
      this.extensionNormal_pipe,
      this.extension_pipeBendingExpense,
      this.extensionNormal_connectionPin,
      this.extensionColumn_pipe,
      this.extensionBeam_pipe,
      this.extensionBeam_connectionPin,
      this.extensionBeamAndFrame_saddleHolder,
      this.extensionBeamAndColumn_tHolder,
      this.extensionBeamAndColumn_tClamp,
      this.extensionBeamAndRafter_tHolder,
      this.extensionBeamAndRafter_firstLevelSoleSconce,
      this.extensionBeamAndRafter_secondLevelSoleSconce,
      this.bracing_pipe,
      this.bracingAndRafter_itemHolder,
      this.rafter_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.rafterPipeSample = <TrunkSample_RafterPipe>this.sampleAC[0];
    this.rafterExtensionPipeSample = <TrunkSample_RafterExtensionPipe>this.sampleAC[1];
    this.bracingPipeSample = <TrunkSample_BracingPipe>this.sampleAC[2];

    // 아이템
    this.rafter_pipe = <ItemPipe>this.itemAC[0];
    this.rafter_pipeBendingExpense = <ItemPipeBendingExpense>this.itemAC[1];
    this.extensionPinned_pipe = <ItemPipe>this.itemAC[2];
    this.extensionNormal_pipe = <ItemPipe>this.itemAC[3];
    this.extension_pipeBendingExpense = <ItemPipeBendingExpense>this.itemAC[4];
    this.extensionNormal_connectionPin = <ItemConnector>this.itemAC[5];
    this.extensionColumn_pipe = <ItemPipe>this.itemAC[6];
    this.extensionBeam_pipe = <ItemPipe>this.itemAC[7];
    this.extensionBeam_connectionPin = <ItemConnector>this.itemAC[8];
    this.extensionBeamAndFrame_saddleHolder = <ItemHolder>this.itemAC[9];
    this.extensionBeamAndColumn_tHolder = <ItemHolder>this.itemAC[10];
    this.extensionBeamAndColumn_tClamp = <ItemHolder>this.itemAC[11];
    this.extensionBeamAndRafter_tHolder = <ItemHolder>this.itemAC[12];
    this.extensionBeamAndRafter_firstLevelSoleSconce = <ItemSconce>this.itemAC[13];
    this.extensionBeamAndRafter_secondLevelSoleSconce = <ItemSconce>this.itemAC[14];
    this.bracing_pipe = <ItemPipe>this.itemAC[15];
    this.bracingAndRafter_itemHolder = <ItemHolder>this.itemAC[16];
    this.rafter_screw = <ItemScrew>this.itemAC[17];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param rafterInterval: number 서까래 간격
   * @param trussSelected: boolean 트러스 선택
   * @param trussMultiple: number 트러스 배율
   * @param trussInterval: number 트러스 간격
   * @param extensionSelected: boolean 확장 선택
   * @param extensionPosition: string 서까래 확장 위치
   * @param extensionType: string 확장 형태
   * @param extensionLength: number 확장 길이
   * @param extensionInterval: number 확장 간격
   * @param bracingQuantity: number 브레싱 수량
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    rafterInterval: number = 0,
    trussSelected: boolean = false,
    trussMultiple: number = 0,
    trussInterval: number = 0,
    extensionSelected: boolean = false,
    extensionPosition: string = "",
    extensionType: string = "",
    extensionLength: number = 0,
    extensionInterval: number = 0,
    bracingQuantity: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._rafterInterval = rafterInterval;
    this._trussSelected = trussSelected;
    this._trussMultiple = trussMultiple;
    this._trussInterval = trussInterval;
    this._extensionSelected = extensionSelected;
    this._extensionPosition = extensionPosition;
    this._extensionType = extensionType;
    this._extensionLength = extensionLength;
    this._extensionInterval = extensionInterval;
    this._bracingQuantity = bracingQuantity;

    // 샘플
    if (this.level.index === 0) {
      this.rafterPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래",
        "원형, 일반, 25.4mm, 1.5T, 10.4m, 벤딩",
      );
      this.rafterExtensionPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래 확장",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.bracingPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "브레싱",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    } else {
      this.rafterPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래",
        "원형, 일반, 22.2mm, 1.2T, 9.4m, 벤딩",
      );
      this.rafterExtensionPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래 확장",
        "원형, 일반, 22.2mm, 1.2T, 10m, -",
      );
      this.bracingPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "브레싱",
        "원형, 일반, 22.2mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.rafter_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래",
        "원형, 일반, 25.4mm, 1.5T, 10.4m, 벤딩",
      );
      this.rafter_pipeBendingExpense.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래",
        "원형, 25.4mm",
      );
      this.extensionPinned_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래 확장",
        "원형, 핀가공, 25.4mm, 1.5T, 1m, -",
      );
      this.extensionNormal_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래 확장",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.rafter_pipeBendingExpense.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래 확장",
        "원형, 25.4mm",
      );
      this.extensionNormal_connectionPin.setDefaultData(
        5,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "서까래 확장",
        "원형, 일반, 25mm, 25mm",
      );
      this.extensionColumn_pipe.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "확장 기둥",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.extensionBeam_pipe.setDefaultData(
        7,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "확장 보",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.extensionBeam_connectionPin.setDefaultData(
        8,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "확장 보",
        "원형, 일반, 48mm, 48mm",
      );
      this.extensionBeamAndFrame_saddleHolder.setDefaultData(
        9,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "확장 보 + 골조",
        "원형, 일반, 48mm, -",
      );
      this.extensionBeamAndColumn_tHolder.setDefaultData(
        10,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "확장 보 + 확장 기둥",
        "원형, 일반, 25mm, 25mm",
      );
      this.extensionBeamAndColumn_tClamp.setDefaultData(
        11,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "확장 보 + 확장 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.extensionBeamAndRafter_tHolder.setDefaultData(
        12,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "확장 보 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.extensionBeamAndRafter_firstLevelSoleSconce.setDefaultData(
        13,
        CONST.ITEM_ID_FIRST_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_FIRST_LEVEL_SOLE_SCONCE,
        "확장 보 + 서까래",
        "원형, 호주식, 48mm, 32mm",
      );
      this.extensionBeamAndRafter_secondLevelSoleSconce.setDefaultData(
        14,
        CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE,
        "확장 보 + 서까래",
        "원형, 일반, 48mm, 25mm",
      );
      this.bracing_pipe.setDefaultData(
        15,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "브레싱",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.bracingAndRafter_itemHolder.setDefaultData(
        16,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "브레싱 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.rafter_screw.setDefaultData(
        17,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "서까래",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      // 서까래 확장 파이프 샘플
      const rafterExtensionPipeSample: TrunkSample_RafterExtensionPipe = new TrunkSample_RafterExtensionPipe();
      rafterExtensionPipeSample.setAssociation(design, struct, work, level, position, this);
      rafterExtensionPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래 확장",
        (<ItemPipe>this.itemAC[3]).specs,
      );
      this.sampleAC.push(rafterExtensionPipeSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }
    if (this.sampleAC.length === 2) {
      // 브레싱 파이프 샘플
      const bracingPipeSample: TrunkSample_BracingPipe = new TrunkSample_BracingPipe();
      bracingPipeSample.setAssociation(design, struct, work, level, position, this);
      bracingPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "브레싱",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.sampleAC.push(bracingPipeSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    // 아이템
    if (this.itemAC.length === 6 && (<Item>this.itemAC[2]).productId === CONST.ITEM_ID_SCREW) {
      // 나사 하나로 합치기
      const rafter_screw: ItemScrew = <ItemScrew>this.itemAC[5];
      rafter_screw.designQuantity =
        rafter_screw.getSelectedQuantity() + (<ItemScrew>this.itemAC[2]).getSelectedQuantity();
      rafter_screw.selected = true;
      rafter_screw.visible = true;
      rafter_screw.purpose = "서까래";
      this.itemAC.splice(2, 1);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 5 && (<Item>this.itemAC[1]).productId === CONST.ITEM_ID_PIPE) {
      // 기존 규격
      const specsArray: any[] = (<ItemPipe>this.itemAC[0]).specs.split(CONST.DELIMITER_SPEC);
      // [서까래] 파이프 벤딩비
      const rafter_pipeBendingExpense: ItemPipeBendingExpense = new ItemPipeBendingExpense();
      rafter_pipeBendingExpense.setAssociation(design, struct, work, level, position, this);
      rafter_pipeBendingExpense.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래",
        `원형, ${specsArray[2]}`,
      );
      rafter_pipeBendingExpense.selected = false;
      rafter_pipeBendingExpense.visible = false;
      this.itemAC.splice(1, 0, rafter_pipeBendingExpense);
      // [서까래 확장] 파이프 벤딩비
      const rafterExtension_pipeBendingExpense: ItemPipeBendingExpense = new ItemPipeBendingExpense();
      rafterExtension_pipeBendingExpense.setAssociation(design, struct, work, level, position, this);
      rafterExtension_pipeBendingExpense.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래 확장",
        `원형, ${specsArray[2]}`,
      );
      rafterExtension_pipeBendingExpense.selected = false;
      rafterExtension_pipeBendingExpense.visible = false;
      this.itemAC.splice(4, 0, rafterExtension_pipeBendingExpense);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 7 && (<Item>this.itemAC[6]).productId === CONST.ITEM_ID_SCREW) {
      // [기둥] 파이프
      const extensionColumn_pipe: ItemPipe = new ItemPipe();
      extensionColumn_pipe.setAssociation(design, struct, work, level, position, this);
      extensionColumn_pipe.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "확장 기둥",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.itemAC.splice(6, 0, extensionColumn_pipe);
      // [보] 파이프
      const extensionBeam_pipe: ItemPipe = new ItemPipe();
      extensionBeam_pipe.setAssociation(design, struct, work, level, position, this);
      extensionBeam_pipe.setDefaultData(
        7,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "확장 보",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.itemAC.splice(7, 0, extensionBeam_pipe);
      // [보] 연결핀
      const extensionBeam_connectionPin: ItemConnector = new ItemConnector();
      extensionBeam_connectionPin.setAssociation(design, struct, work, level, position, this);
      extensionBeam_connectionPin.setDefaultData(
        8,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "확장 보",
        "원형, 일반, 48mm, 48mm",
      );
      this.itemAC.splice(8, 0, extensionBeam_connectionPin);
      // [보 + 골조] 새들 고정구
      const extensionBeamAndFrame_saddleHolder: ItemHolder = new ItemHolder();
      extensionBeamAndFrame_saddleHolder.setAssociation(design, struct, work, level, position, this);
      extensionBeamAndFrame_saddleHolder.setDefaultData(
        9,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "확장 보 + 골조",
        "원형, 일반, 48mm, -",
      );
      this.itemAC.splice(9, 0, extensionBeamAndFrame_saddleHolder);
      // [보 + 기둥] T 고정구
      const extensionBeamAndColumn_tHolder: ItemHolder = new ItemHolder();
      extensionBeamAndColumn_tHolder.setAssociation(design, struct, work, level, position, this);
      extensionBeamAndColumn_tHolder.setDefaultData(
        10,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "확장 보 + 확장 기둥",
        "원형, 일반, 32mm, 32mm",
      );
      this.itemAC.splice(10, 0, extensionBeamAndColumn_tHolder);
      // [보 + 기둥] T 클램프
      const extensionBeamAndColumn_tClamp: ItemHolder = new ItemHolder();
      extensionBeamAndColumn_tClamp.setAssociation(design, struct, work, level, position, this);
      extensionBeamAndColumn_tClamp.setDefaultData(
        11,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "확장 보 + 확장 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.itemAC.splice(11, 0, extensionBeamAndColumn_tClamp);
      // [보 + 서까래] T 고정구
      const extensionBeamAndRafter_tHolder: ItemHolder = new ItemHolder();
      extensionBeamAndRafter_tHolder.setAssociation(design, struct, work, level, position, this);
      extensionBeamAndRafter_tHolder.setDefaultData(
        12,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "확장 보 + 서까래",
        "원형, 일반, 32mm, 32mm",
      );
      this.itemAC.splice(12, 0, extensionBeamAndRafter_tHolder);
      // [보 + 서까래] 일중 외꽂이
      const extensionBeamAndRafter_firstLevelSoleSconce: ItemSconce = new ItemSconce();
      extensionBeamAndRafter_firstLevelSoleSconce.setAssociation(design, struct, work, level, position, this);
      extensionBeamAndRafter_firstLevelSoleSconce.setDefaultData(
        13,
        CONST.ITEM_ID_FIRST_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_FIRST_LEVEL_SOLE_SCONCE,
        "확장 보 + 서까래",
        "원형, 호주식, 48mm, 32mm",
      );
      this.itemAC.splice(13, 0, extensionBeamAndRafter_firstLevelSoleSconce);
      // [보 + 서까래] 이중 외꽂이
      const extensionBeamAndRafter_secondLevelSoleSconce: ItemSconce = new ItemSconce();
      extensionBeamAndRafter_secondLevelSoleSconce.setAssociation(design, struct, work, level, position, this);
      extensionBeamAndRafter_secondLevelSoleSconce.setDefaultData(
        14,
        CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE,
        "확장 보 + 서까래",
        "원형, 일반, 48mm, 25mm",
      );
      this.itemAC.splice(14, 0, extensionBeamAndRafter_secondLevelSoleSconce);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 16 && (<Item>this.itemAC[15]).productId === CONST.ITEM_ID_SCREW) {
      // [브레싱] 파이프
      const bracing_pipe: ItemPipe = new ItemPipe();
      bracing_pipe.setAssociation(design, struct, work, level, position, this);
      bracing_pipe.setDefaultData(
        15,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "브레싱",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      bracing_pipe.selected = true;
      bracing_pipe.visible = true;
      bracing_pipe.designQuantity = this.bracingQuantity * design.basic.buildingNumber;
      this.itemAC.splice(15, 0, bracing_pipe);
      // [브레싱 + 서까래] {고정구}
      const bracingAndRafter_itemHolder: ItemHolder = new ItemHolder();
      bracingAndRafter_itemHolder.setAssociation(design, struct, work, level, position, this);
      bracingAndRafter_itemHolder.setDefaultData(
        16,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "브레싱 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      bracingAndRafter_itemHolder.selected = true;
      bracingAndRafter_itemHolder.visible = true;
      bracingAndRafter_itemHolder.designQuantity = this.bracingQuantity * design.basic.buildingNumber * 20.6;
      this.itemAC.splice(16, 0, bracingAndRafter_itemHolder);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_rafterTotalLength();
    this.algorithm_rafterQuantity();
    this.algorithm_extensionQuantity();
    this.algorithm_bracingQuantityByLength();

    super.algorithmBasic();
    for (let l: number = 0; l < this.work.levelAC.length; l++) {
      (<TrunkLevel>this.work.levelAC[l]).trunkPosition.rafterPart.algorithm_rafterExtension();
    }
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.rafter_pipe.selected = true;
    this.rafter_pipe.visible = true;
    if (this.rafter_pipe.specBending === CONST.ITEM_SPEC_VALUE_BENDING) {
      this.rafter_pipeBendingExpense.selected = this.design.basic.bendingExpenseFlag;
      this.rafter_pipeBendingExpense.visible = true;
    } else {
      this.rafter_pipeBendingExpense.selected = false;
      this.rafter_pipeBendingExpense.visible = false;
    }
    if (this.extensionSelected === true) {
      if (this.extensionType === CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_PINNED) {
        this.extensionPinned_pipe.selected = true;
        this.extensionPinned_pipe.visible = true;
        this.extensionNormal_pipe.selected = false;
        this.extensionNormal_pipe.visible = false;
        this.extensionNormal_connectionPin.selected = false;
        this.extensionNormal_connectionPin.visible = false;
        this.extension_pipeBendingExpense.selected = false;
        this.extension_pipeBendingExpense.visible = true;
        this.extensionColumn_pipe.selected = false;
        this.extensionColumn_pipe.visible = false;
        this.extensionBeam_pipe.checkSelected(false);
        this.extensionBeam_connectionPin.checkSelected(false);
        this.extensionBeam_connectionPin.visible = false;
        this.extensionBeamAndFrame_saddleHolder.selected = false;
        this.extensionBeamAndFrame_saddleHolder.visible = false;
        this.extensionBeamAndColumn_tHolder.selected = false;
        this.extensionBeamAndColumn_tHolder.visible = false;
        this.extensionBeamAndColumn_tClamp.selected = false;
        this.extensionBeamAndColumn_tClamp.visible = false;
        this.extensionBeamAndRafter_tHolder.selected = false;
        this.extensionBeamAndRafter_tHolder.visible = false;
        this.extensionBeamAndRafter_firstLevelSoleSconce.selected = false;
        this.extensionBeamAndRafter_firstLevelSoleSconce.visible = false;
        this.extensionBeamAndRafter_secondLevelSoleSconce.selected = false;
        this.extensionBeamAndRafter_secondLevelSoleSconce.visible = false;
      } else if (this.extensionType === CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL) {
        this.extensionPinned_pipe.selected = false;
        this.extensionPinned_pipe.visible = false;
        this.extensionNormal_pipe.selected = true;
        this.extensionNormal_pipe.visible = true;
        this.extensionNormal_connectionPin.selected = true;
        this.extensionNormal_connectionPin.visible = true;
        if (this.extensionNormal_pipe.specBending === CONST.ITEM_SPEC_VALUE_BENDING) {
          this.extension_pipeBendingExpense.selected = this.design.basic.bendingExpenseFlag;
          this.extension_pipeBendingExpense.visible = true;
        } else {
          this.extension_pipeBendingExpense.selected = false;
          this.extension_pipeBendingExpense.visible = true;
        }
        this.extensionColumn_pipe.selected = false;
        this.extensionColumn_pipe.visible = false;
        this.extensionBeam_pipe.checkSelected(false);
        this.extensionBeam_connectionPin.checkSelected(false);
        this.extensionBeamAndFrame_saddleHolder.selected = false;
        this.extensionBeamAndFrame_saddleHolder.visible = false;
        this.extensionBeamAndColumn_tHolder.selected = false;
        this.extensionBeamAndColumn_tHolder.visible = false;
        this.extensionBeamAndColumn_tClamp.selected = false;
        this.extensionBeamAndColumn_tClamp.visible = false;
        this.extensionBeamAndRafter_tHolder.selected = false;
        this.extensionBeamAndRafter_tHolder.visible = false;
        this.extensionBeamAndRafter_firstLevelSoleSconce.selected = false;
        this.extensionBeamAndRafter_firstLevelSoleSconce.visible = false;
        this.extensionBeamAndRafter_secondLevelSoleSconce.selected = false;
        this.extensionBeamAndRafter_secondLevelSoleSconce.visible = false;
      } else if (this.extensionType === CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM) {
        this.extensionPinned_pipe.selected = false;
        this.extensionPinned_pipe.visible = false;
        this.extensionNormal_pipe.selected = false;
        this.extensionNormal_pipe.visible = false;
        this.extensionNormal_connectionPin.selected = false;
        this.extensionNormal_connectionPin.visible = false;
        this.extension_pipeBendingExpense.selected = false;
        this.extension_pipeBendingExpense.visible = false;
        this.extensionColumn_pipe.selected = true;
        this.extensionColumn_pipe.visible = true;
        this.extensionBeam_pipe.checkSelected(true);
        this.extensionBeam_connectionPin.checkSelected(true);
        this.extensionBeamAndFrame_saddleHolder.selected = true;
        this.extensionBeamAndFrame_saddleHolder.visible = true;
        if (this.level.index === 0) {
          // [보 + 기둥] : T 고정구(22/22mm,25/25mm,32/32mm) vs. T 클램프(42/42mm,48/48mm)
          // [보 + 서까래] : T 고정구(22/22mm,25/25mm,32/32mm) vs. 일중 외꽂이&이중 외꽂이(42/42mm,48/48mm)
          // 하드코딩
          if (
            this.extensionBeam_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM ||
            this.extensionBeam_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
            this.extensionBeam_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM
          ) {
            this.extensionBeamAndColumn_tHolder.selected = true;
            this.extensionBeamAndColumn_tHolder.visible = true;
            this.extensionBeamAndColumn_tClamp.selected = false;
            this.extensionBeamAndColumn_tClamp.visible = false;
            this.extensionBeamAndRafter_tHolder.selected = true;
            this.extensionBeamAndRafter_tHolder.visible = true;
            this.extensionBeamAndRafter_firstLevelSoleSconce.selected = false;
            this.extensionBeamAndRafter_firstLevelSoleSconce.visible = false;
            this.extensionBeamAndRafter_secondLevelSoleSconce.selected = false;
            this.extensionBeamAndRafter_secondLevelSoleSconce.visible = false;
          } else {
            this.extensionBeamAndColumn_tHolder.selected = false;
            this.extensionBeamAndColumn_tHolder.visible = false;
            this.extensionBeamAndColumn_tClamp.selected = true;
            this.extensionBeamAndColumn_tClamp.visible = true;
            this.extensionBeamAndRafter_tHolder.selected = false;
            this.extensionBeamAndRafter_tHolder.visible = false;
            this.extensionBeamAndRafter_firstLevelSoleSconce.selected = true;
            this.extensionBeamAndRafter_firstLevelSoleSconce.visible = true;
            this.extensionBeamAndRafter_secondLevelSoleSconce.selected = false;
            this.extensionBeamAndRafter_secondLevelSoleSconce.visible = false;
          }
        } else {
          // [보 + 서까래] : T 고정구(22/22mm,25/25mm,32/32mm) vs. 일중 외꽂이&이중 외꽂이(42/42mm,48/48mm)
          // 하드코딩
          if (
            this.extensionBeam_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM ||
            this.extensionBeam_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
            this.extensionBeam_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM
          ) {
            this.extensionBeamAndColumn_tHolder.selected = false;
            this.extensionBeamAndColumn_tHolder.visible = false;
            this.extensionBeamAndColumn_tClamp.selected = false;
            this.extensionBeamAndColumn_tClamp.visible = false;
            this.extensionBeamAndRafter_tHolder.selected = true;
            this.extensionBeamAndRafter_tHolder.visible = true;
            this.extensionBeamAndRafter_firstLevelSoleSconce.selected = false;
            this.extensionBeamAndRafter_firstLevelSoleSconce.visible = false;
            this.extensionBeamAndRafter_secondLevelSoleSconce.selected = false;
            this.extensionBeamAndRafter_secondLevelSoleSconce.visible = false;
          } else {
            this.extensionBeamAndColumn_tHolder.selected = false;
            this.extensionBeamAndColumn_tHolder.visible = false;
            this.extensionBeamAndColumn_tClamp.selected = false;
            this.extensionBeamAndColumn_tClamp.visible = false;
            this.extensionBeamAndRafter_tHolder.selected = false;
            this.extensionBeamAndRafter_tHolder.visible = false;
            this.extensionBeamAndRafter_firstLevelSoleSconce.selected = false;
            this.extensionBeamAndRafter_firstLevelSoleSconce.visible = false;
            this.extensionBeamAndRafter_secondLevelSoleSconce.selected = true;
            this.extensionBeamAndRafter_secondLevelSoleSconce.visible = true;
          }
        }
      }
    } else {
      this.extensionPinned_pipe.selected = false;
      this.extensionPinned_pipe.visible = false;
      this.extensionNormal_pipe.selected = false;
      this.extensionNormal_pipe.visible = false;
      this.extensionNormal_connectionPin.selected = false;
      this.extensionNormal_connectionPin.visible = false;
      this.extension_pipeBendingExpense.selected = false;
      this.extension_pipeBendingExpense.visible = false;
      this.extensionColumn_pipe.selected = false;
      this.extensionColumn_pipe.visible = false;
      this.extensionBeam_pipe.selected = false;
      this.extensionBeam_pipe.visible = false;
      this.extensionBeam_connectionPin.selected = false;
      this.extensionBeam_connectionPin.visible = false;
      this.extensionBeamAndFrame_saddleHolder.selected = false;
      this.extensionBeamAndFrame_saddleHolder.visible = false;
      this.extensionBeamAndColumn_tHolder.selected = false;
      this.extensionBeamAndColumn_tHolder.visible = false;
      this.extensionBeamAndColumn_tClamp.selected = false;
      this.extensionBeamAndColumn_tClamp.visible = false;
      this.extensionBeamAndRafter_tHolder.selected = false;
      this.extensionBeamAndRafter_tHolder.visible = false;
      this.extensionBeamAndRafter_firstLevelSoleSconce.selected = false;
      this.extensionBeamAndRafter_firstLevelSoleSconce.visible = false;
      this.extensionBeamAndRafter_secondLevelSoleSconce.selected = false;
      this.extensionBeamAndRafter_secondLevelSoleSconce.visible = false;
    }
    this.bracing_pipe.selected = true;
    this.bracing_pipe.visible = true;
    this.bracingAndRafter_itemHolder.selected = true;
    this.bracingAndRafter_itemHolder.visible = true;
    this.rafter_screw.selected = true;
    this.rafter_screw.visible = true;

    /// //////// 용도 ///////////

    if (this.trussSelected === true) {
      this.rafter_pipe.purpose = `서까래 (트러스 ${this.trussInterval}m간격)`;
    } else {
      this.rafter_pipe.purpose = "서까래";
    }

    /// //////// 수량 ///////////

    let rafter_pipe_designQuantity: number = 0;
    rafter_pipe_designQuantity = this.rafterQuantity;
    if (this.trussSelected === true) {
      rafter_pipe_designQuantity +=
        Math.round(this.basicLevel.length / this.trussInterval + 1) * this.basic.buildingNumber * this.trussMultiple;
    }
    this.rafter_pipe.designQuantity = rafter_pipe_designQuantity;

    this.rafter_pipeBendingExpense.designQuantity = this.rafterQuantity;

    this.extensionPinned_pipe.designQuantity = this.extensionQuantity;

    let rafterExtensionNormal_pipe_designQuantity: number = this.extensionQuantity;
    if (this.extensionNormal_pipe.specLength > this.extensionLength) {
      rafterExtensionNormal_pipe_designQuantity =
        rafterExtensionNormal_pipe_designQuantity /
        Math.floor(this.extensionNormal_pipe.specLength / this.extensionLength);
    }
    this.extensionNormal_pipe.designQuantity = rafterExtensionNormal_pipe_designQuantity;

    this.extension_pipeBendingExpense.designQuantity = this.extensionQuantity;

    this.extensionNormal_connectionPin.designQuantity = this.extensionQuantity * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;

    let extensionColumn_pipe_designQuantity: number = this.extensionQuantity;
    if (this.extensionColumn_pipe.specLength > this.extensionLength) {
      extensionColumn_pipe_designQuantity =
        extensionColumn_pipe_designQuantity / Math.floor(this.extensionColumn_pipe.specLength / this.extensionLength);
    }
    this.extensionColumn_pipe.designQuantity = extensionColumn_pipe_designQuantity;

    if (this.extensionQuantity > 0) {
      this.extensionBeam_pipe.designQuantity =
        (this.extensionBeam_pipe.getConnectedPipeLength(
          this.basicLevel.length,
          this.extensionPositionValue * this.basic.buildingNumber,
        ) /
          this.extensionBeam_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_BEAM_PIPE;
    } else {
      this.extensionBeam_pipe.designQuantity = 0;
    }

    if (this.basicLevel.length > this.extensionBeam_pipe.specLength) {
      this.extensionBeam_connectionPin.designQuantity =
        this.extensionBeam_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.extensionBeam_connectionPin.designQuantity = 0;
    }

    if (this.extensionQuantity > 0) {
      this.extensionBeamAndFrame_saddleHolder.designQuantity =
        Math.ceil((this.basicLevel.length * this.extensionPositionValue * this.basic.buildingNumber) / 1.5) *
        CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
    } else {
      this.extensionBeamAndFrame_saddleHolder.designQuantity = 0;
    }

    this.extensionBeamAndColumn_tHolder.designQuantity = this.extensionQuantity * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.extensionBeamAndColumn_tClamp.designQuantity = this.extensionQuantity * CONST.NUM_EXTRA_RATE_T_CLAMP;

    this.extensionBeamAndRafter_tHolder.designQuantity =
      this.rafterQuantity * this.extensionPositionValue * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.extensionBeamAndRafter_firstLevelSoleSconce.designQuantity =
      this.rafterQuantity * this.extensionPositionValue * CONST.NUM_EXTRA_RATE_FIRST_LEVEL_SOLE_SCONCE;

    this.extensionBeamAndRafter_secondLevelSoleSconce.designQuantity =
      this.rafterQuantity * this.extensionPositionValue * CONST.NUM_EXTRA_RATE_SECOND_LEVEL_SOLE_SCONCE;

    this.bracing_pipe.designQuantity = this.bracingQuantity * this.basic.buildingNumber;

    if (this.bracingAndRafter_itemHolder.productId === CONST.ITEM_ID_STEEL_STRING_GRABBER) {
      this.bracingAndRafter_itemHolder.designQuantity =
        Math.floor(this.bracing_pipe.specLength * 2) *
        this.bracing_pipe.getSelectedQuantity() *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
    } else if (this.bracingAndRafter_itemHolder.productId === CONST.ITEM_ID_ROTATION_ASIBA_CLAMP) {
      this.bracingAndRafter_itemHolder.designQuantity =
        4 * this.bracing_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_ROTATION_ASIBA_CLAMP;
    }

    this.rafter_screw.designQuantity =
      ((this.extensionPinned_pipe.getSelectedQuantity() * 2 +
        this.extensionNormal_connectionPin.getSelectedQuantity() * 4 +
        this.extensionBeam_connectionPin.getSelectedQuantity() * 2 +
        this.extensionBeamAndFrame_saddleHolder.getSelectedQuantity() * 3 +
        this.extensionBeamAndColumn_tHolder.getSelectedQuantity() * 3 +
        this.extensionBeamAndColumn_tClamp.getSelectedQuantity() * 3 +
        this.extensionBeamAndRafter_tHolder.getSelectedQuantity() * 3 +
        this.extensionBeamAndRafter_firstLevelSoleSconce.getSelectedQuantity() * 2 +
        this.extensionBeamAndRafter_secondLevelSoleSconce.getSelectedQuantity() * 3) /
        this.rafter_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 최종 서까래 길이 <- 확장 선택, 확장 길이, 확장 위치값, 길이(서까래 파이프 샘플)
   */
  public algorithm_rafterTotalLength(): void {
    if (this.extensionSelected === true) {
      this.rafterTotalLength = this.rafterPipeSample.specLength + this.extensionLength * this.extensionPositionValue;
    } else {
      this.rafterTotalLength = this.rafterPipeSample.specLength;
    }
  }

  /**
   * 알고리즘: 서까래 수량 <- 서까래 간격, 길이(기본정보), 동수(기본정보)
   */
  public algorithm_rafterQuantity(): void {
    this.rafterQuantity =
      Math.round(this.basicLevel.length / this.rafterInterval + 1) *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_RAFTER_PIPE;
  }

  /**
   * 알고리즘: 서까래 간격 <- 서까래 간격(1중)
   */
  public algorithm_rafterIntervalByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < CONST.NUM_SINGLE_LEVEL_MAX; l++) {
        (<TrunkLevel>this.work.levelAC[l]).trunkPosition.rafterPart.rafterInterval = this.rafterInterval * 2;
      }
    }
  }

  /**
   * 알고리즘: 최종 서까래 길이 <- 최종 서까래 길이(중수)
   */
  public algorithm_rafterTotalLengthByLevel(): void {
    for (let l: number = this.level.index; l < CONST.NUM_SINGLE_LEVEL_MAX - 1; l++) {
      (<TrunkLevel>this.work.levelAC[l + 1]).trunkPosition.rafterPart.rafterTotalLength =
        (<TrunkLevel>this.work.levelAC[l]).trunkPosition.rafterPart.rafterTotalLength - 1.0;
    }
  }

  /**
   * 알고리즘: 서까래 길이 반올림 선택 <- 서까래 길이 반올림 선택(1중)
   */
  public algorithm_rafterLengthRoundSelectedByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        (<TrunkLevel>this.work.levelAC[l]).trunkPosition.rafterPart.rafterLengthRoundSelected =
          this.rafterLengthRoundSelected;
      }
    }
  }

  /**
   * 알고리즘: 서까래 길이 반올림 선택 <- 확장 선택
   */
  public algorithm_rafterLengthRoundSelectedByExtensionSelected(): void {
    // 확장이 헷갈린다고 서까래 길이 반올림울 해제하는 것은 더 헷갈림을 초래할듯..
    // if (this.extensionSelected) {
    //   this.rafterLengthRoundSelected = false;
    // }
  }

  /**
   * 알고리즘: 확장 형태 <- 서까래 파이프 샘플
   */
  public algorithm_extensionType(): void {
    // 하드코딩
    if (
      this.rafterPipeSample.specPipeCrossSize === CONST.ITEM_SPEC_VALUE_33_MM_PIPE ||
      this.rafterPipeSample.specPipeCrossSize === CONST.ITEM_SPEC_VALUE_42_MM_PIPE ||
      this.rafterPipeSample.specPipeCrossSize === CONST.ITEM_SPEC_VALUE_48_MM_PIPE
    ) {
      this.extensionType = CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL;
    } else {
      // 22~32mm는 핀가공 파이프 또는 기둥+보 파이프
    }
  }

  /**
   * 알고리즘: 확장 형태 <- 확장 형태(1중)
   */
  public algorithm_extensionTypeLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.trunkWork.levelAC.length; l++) {
        const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[l]).trunkPosition;
        trunkPosition.rafterPart.extensionType = this.struct.trunkWork.level1.trunkPosition.rafterPart.extensionType;
      }
    }
  }

  /**
   * 알고리즘: 확장 위치 <- 확장 위치(1중)
   */
  public algorithm_extensionPositionLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.trunkWork.levelAC.length; l++) {
        const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[l]).trunkPosition;
        trunkPosition.rafterPart.extensionPosition =
          this.struct.trunkWork.level1.trunkPosition.rafterPart.extensionPosition;
      }
    }
  }

  /**
   * 알고리즘: 확장 수량 <- 확장 위치, 확장 간격, 길이(기본정보), 동수(기본정보)
   */
  public algorithm_extensionQuantity(): void {
    if (this.extensionInterval > 0) {
      this.extensionQuantity =
        Math.ceil(this.basicLevel.length / this.extensionInterval + 1) *
        this.extensionPositionValue *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_RAFTER_PIPE;
    } else {
      this.extensionQuantity = 0;
    }
  }

  /**
   * 알고리즘: 확장 간격 <- 확장 형태, 서까래 간격
   */
  public algorithm_extensionInterval(): void {
    if (this.level.index === 0) {
      if (this.extensionType !== CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM) {
        this.extensionInterval = this.rafterInterval;
      }
    } else {
      if (this.extensionType === CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM) {
        this.extensionInterval = 0;
      }
    }
  }

  /**
   * 알고리즘: 브레싱 수량 <- 하우스 길이(기본 정보), 브레싱 수량(1중)
   */
  public algorithm_bracingQuantityByLength(): void {
    if (this.level.index === 0) {
      if (this.basic.structureName === CONST.LB_STRUCT_SINGLE) {
        if (this.basicLevel.length > 20) {
          this.bracingQuantity = 4;
        } else {
          this.bracingQuantity = 2;
        }
      }
      for (let l: number = 1; l < this.struct.trunkWork.levelAC.length; l++) {
        const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[l]).trunkPosition;
        trunkPosition.rafterPart.bracingQuantity = this.bracingQuantity;
      }
    }
  }

  public extractRafterExtensionData(structureName: string, specPipeCrossSize: string) {
    let struct;
    if (structureName === CONST.LB_STRUCT_DOUBLE_WIDTH) {
      struct = RafterExtensionData.find(v => v.structureName === this.basic.structureName);
    } else if (structureName === CONST.LB_STRUCT_SINGLE) {
      if (specPipeCrossSize < CONST.ITEM_SPEC_VALUE_40_MM) {
        struct = RafterExtensionData.find(
          v =>
            v.structureName === this.basic.structureName && v.specCrossSize === DesignConst.ITEM_SPEC_VALUE_40_MM_UNDER,
        );
      } else {
        struct = RafterExtensionData.find(
          v =>
            v.structureName === this.basic.structureName && v.specCrossSize === DesignConst.ITEM_SPEC_VALUE_40_MM_MORE,
        );
      }
    }
    return struct;
  }

  /**
   * 알고리즘: 확장 형태, 확장 선택, 확장 위치, 확장 길이 <- 서까래 최종 길이, 서까래 파이프 샘플
   */
  public algorithm_rafterExtension(): void {
    let data;
    let struct;
    struct = this.extractRafterExtensionData(this.basic.structureName, this.rafterPipeSample.specPipeCrossSize);

    if (this.rafterPipeSample.specPipeCrossSize < CONST.ITEM_SPEC_VALUE_40_MM) {
      this.extensionType = CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_PINNED;
    } else {
      this.extensionType = CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL;
    }

    data = struct.data.find(v => v.rafterTotalLength === CommonUtil.round(this.lengthRafterIdeal));

    if (data) {
      this.rafterPipeSample.specLength = data.rafterPipeSpecLength;
      this.extensionSelected = data.extensionSelected;
      this.extensionPosition = data.extensionPosition;
      this.extensionLength = data.extensionLength;
    }
  }

  //----------------------------------
  // 품목 상품
  //----------------------------------

  /**
   * 상품 알고리즘: [브레싱 + 서까래] 고정구 <- 브레싱 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmProduct_bracingAndRafter_itemHolder(): void {
    if (
      this.bracingPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM &&
      this.rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM
    ) {
      this.bracingAndRafter_itemHolder._productId = CONST.ITEM_ID_ROTATION_ASIBA_CLAMP;
      this.bracingAndRafter_itemHolder._label = CONST.ITEM_NAME_ROTATION_ASIBA_CLAMP;
    } else {
      this.bracingAndRafter_itemHolder._productId = CONST.ITEM_ID_STEEL_STRING_GRABBER;
      this.bracingAndRafter_itemHolder._label = CONST.ITEM_NAME_STEEL_STRING_GRABBER;
    }
    this.bracingAndRafter_itemHolder.setDefaultVariable();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [서까래] 파이프 <- 서까래 파이프 샘플
   */
  public algorithmSpec_rafter_pipe(): void {
    this.rafter_pipe.specs = this.rafterPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [서까래] 파이프 벤딩비 <- 서까래 파이프 샘플
   */
  public algorithmSpec_rafter_pipeBendingExpense(): void {
    this.rafter_pipeBendingExpense.specPipeType = this.rafterPipeSample.specPipeType;
    this.rafter_pipeBendingExpense.specPipeCrossSize = this.rafterPipeSample.specPipeCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 확장] 파이프 <- 서까래 파이프 샘플, 확장 길이
   */
  public algorithmSpec_extensionPinned_pipe(): void {
    this.extensionPinned_pipe.specPipeType = this.rafterPipeSample.specPipeType;
    // 하드코딩
    this.extensionPinned_pipe.specMaterial = CONST.ITEM_SPEC_VALUE_PINNED;
    this.extensionPinned_pipe.specPipeCrossSize = this.rafterPipeSample.specPipeCrossSize;
    this.extensionPinned_pipe.specThickness = this.rafterPipeSample.specThickness;
    this.extensionPinned_pipe.specLength = this.extensionLength;
    this.extensionPinned_pipe.specBending = this.rafterPipeSample.specBending;
  }

  /**
   * 규격 알고리즘: [서까래 확장] 파이프 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extensionNormal_pipe(): void {
    this.extensionNormal_pipe.specs = this.rafterExtensionPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [서까래 확장] 파이프 벤딩비 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extension_pipeBendingExpense(): void {
    this.extension_pipeBendingExpense.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extension_pipeBendingExpense.specPipeCrossSize = this.rafterExtensionPipeSample.specPipeCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 확장] 연결핀 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extensionNormal_connectionPin(): void {
    this.extensionNormal_connectionPin.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extensionNormal_connectionPin.specCrossSize1 = this.rafterExtensionPipeSample.specCrossSize;
    this.extensionNormal_connectionPin.specCrossSize2 = this.rafterExtensionPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [기둥] 파이프 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extensionColumn_pipe(): void {
    this.extensionColumn_pipe.specsList = this.rafterExtensionPipeSample.specsList;
    this.extensionColumn_pipe.specLength = this.rafterExtensionPipeSample.specLength;
  }

  /**
   * 규격 알고리즘: [보] 파이프 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extensionBeam_pipe(): void {
    this.extensionBeam_pipe.specsList = this.rafterExtensionPipeSample.specsList;
    this.extensionBeam_pipe.specLength = this.rafterExtensionPipeSample.specLength;
  }

  /**
   * 규격 알고리즘: [보] 연결핀 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extensionBeam_connectionPin(): void {
    this.extensionBeam_connectionPin.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extensionBeam_connectionPin.specCrossSize1 = this.rafterExtensionPipeSample.specCrossSize;
    this.extensionBeam_connectionPin.specCrossSize2 = this.rafterExtensionPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 골조] 새들 고정구 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extensionBeamAndFrame_saddleHolder(): void {
    this.extensionBeamAndFrame_saddleHolder.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extensionBeamAndFrame_saddleHolder.specCrossSize1 = this.rafterExtensionPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 기둥] T 고정구 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extensionBeamAndColumn_tHolder(): void {
    this.extensionBeamAndColumn_tHolder.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extensionBeamAndColumn_tHolder.specCrossSize1 = this.rafterExtensionPipeSample.specCrossSize;
    this.extensionBeamAndColumn_tHolder.specCrossSize2 = this.rafterExtensionPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 기둥] T 클램프 <- 서까래 확장 파이프 샘플
   */
  public algorithmSpec_extensionBeamAndColumn_tClamp(): void {
    this.extensionBeamAndColumn_tClamp.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extensionBeamAndColumn_tClamp.specCrossSize1 = this.rafterExtensionPipeSample.specCrossSize;
    this.extensionBeamAndColumn_tClamp.specCrossSize2 = this.rafterExtensionPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 서까래] T 고정구 <- 서까래 확장 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_extensionBeamAndRafter_tHolder(): void {
    this.extensionBeamAndRafter_tHolder.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extensionBeamAndRafter_tHolder.specCrossSize1 = this.rafterExtensionPipeSample.specCrossSize;
    this.extensionBeamAndRafter_tHolder.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 서까래] 일중 외꽂이 <- 서까래 확장 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_extensionBeamAndRafter_firstLevelSoleSconce(): void {
    this.extensionBeamAndRafter_firstLevelSoleSconce.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extensionBeamAndRafter_firstLevelSoleSconce.specCrossSize1 = this.rafterExtensionPipeSample.specCrossSize;
    this.extensionBeamAndRafter_firstLevelSoleSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 서까래] 이중 외꽂이 <- 서까래 확장 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_extensionBeamAndRafter_secondLevelSoleSconce(): void {
    this.extensionBeamAndRafter_secondLevelSoleSconce.specPipeType = this.rafterExtensionPipeSample.specPipeType;
    this.extensionBeamAndRafter_secondLevelSoleSconce.specCrossSize1 = this.rafterExtensionPipeSample.specCrossSize;
    this.extensionBeamAndRafter_secondLevelSoleSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [브레싱] 파이프 <- 브레싱 파이프 샘플
   */
  public algorithmSpec_bracing_pipe(): void {
    this.bracing_pipe.specs = this.bracingPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [브레싱 + 서까래] 고정구 <- 브레싱 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_bracingAndRafter_itemHolder(): void {
    this.bracingAndRafter_itemHolder.specPipeType = this.bracingPipeSample.specPipeType;
    this.bracingAndRafter_itemHolder.specCrossSize1 = this.bracingPipeSample.specCrossSize;
    this.bracingAndRafter_itemHolder.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래] 나사 <- 공통 샘플
   */
  public algorithmSpec_rafter_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
