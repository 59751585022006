import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";

/**
 * 주소 클래스
 * @author 이덕형
 * @copyright RUNean Inc.
 * @date 2016-07-08
 */
@jsonObject
export class Address {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 도로명주소 + 상세주소를 리턴
   */
  public get addressRoadDetail(): string {
    let address: string = "";
    if (this.road) {
      address += this.road;
    }
    if (this.detail) {
      address += (` ${this.detail}`);
    }
    return address;
  }
  //
  public set addressRoadDetail(value: string) {
  }

  /**
   * 지번주소 + 상세주소를 리턴
   */
  public get addressJibeonDetail() {
    let address: string = "";
    if (this.jibeon) {
      address += this.jibeon;
    }
    if (this.detail) {
      address += ` ${this.detail}`;
    }
    return address;
  }
  //
  public set addressJibeonDetail(value: string) {
  }

  /**
   * 우편번호 + 도로명주소를 리턴
   */
  public get addressPostcodeRoad(): string {
    let address: string = "";
    if (this.postcode) {
      address += `(${this.postcode}) `;
    }
    if (this.road) {
      address += this.road;
    }
    return address;
  }
  //
  public set addressPostcodeRoad(value: string) {
  }

  /**
   * 우편번호 + 지번주소를 리턴
   */
  public get addressPostcodeJibeon() {
    let address: string = "";
    if (this.postcode) {
      address += `(${this.postcode}) `;
    }
    if (this.jibeon) {
      address += this.jibeon;
    }
    return address;
  }
  //
  public set addressPostcodeJibeon(value: string) {
  }

  /**
   * 우편번호 + 도로명주소 + 상세주소를 리턴
   */
  public get addressPostcodeRoadDetail(): string {
    let address: string = "";
    if (this.postcode) {
      address += `(${this.postcode}) `;
    }
    address += this.addressRoadDetail;
    return address;
  }
  //
  public set addressPostcodeRoadDetail(value: string) {
  }

  /**
   * 우편번호 + 지번주소 + 상세주소를 리턴
   */
  public get addressPostcodeJibeonDetail() {
    let address: string = "";
    if (this.postcode) {
      address += `(${this.postcode}) `;
    }
    address += this.addressJibeonDetail;
    return address;
  }
  //
  public set addressPostcodeJibeonDetail(value: string) {
  }

  /**
   * 우편번호 + 대표주소 리턴
   */
  public get addressMain(): string {
    let address: string = "";
    if (this.road) {
      address += this.addressPostcodeRoad;
    } else {
      address += this.addressPostcodeJibeon;
    }
    return address;
  }
  //
  public set addressMain(value: string) {
  }

  /**
   * 우편번호 + 대표주소 + 상세주소 리턴
   */
  public get addressMainDetail(): string {
    let address: string = "";
    if (this.road) {
      address += this.addressPostcodeRoadDetail;
    } else {
      address += this.addressPostcodeJibeonDetail;
    }
    return address;
  }
  //
  public set addressMainDetail(value: string) {
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 우편번호. */
  @jsonMember(String)
  public postcode: string = null;
  /** 도로명 주소. */
  @jsonMember(String)
  public road: string = null;
  /** 지번 주소. */
  @jsonMember(String)
  public jibeon: string = null;
  /** 상세 주소. */
  @jsonMember(String)
  public detail: string = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(): void {
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {
    this.postcode = "";
    this.road = "경기도 안양시 브이하우스";
    this.jibeon = "경기도 안양시 브이하우스";
    this.detail = "";
  }

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * @param origin 원본 객체
   */
  public deepCopy(origin: Address): void {
    this.postcode = origin.postcode;
    this.road = origin.road;
    this.jibeon = origin.jibeon;
    this.detail = origin.detail;
  }
}
