import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { TrunkSample_StraightFloorPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_StraightFloorPipe";
import { TrunkSample_StraightPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_StraightPipe";
import { TrunkSample_StraightRoofPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_StraightRoofPipe";
import { TrunkSample_StraightRoofSubPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_StraightRoofSubPipe";
import { TrunkSample_StraightSidePipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_StraightSidePipe";
import { TrunkSample_StraightSideSubPipe } from "vhows-design/src/object/design/frame/trunk/TrunkSample_StraightSideSubPipe";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-23
 */
@jsonObject({
  knownTypes: [
    TrunkSample_StraightFloorPipe,
    TrunkSample_StraightPipe,
    TrunkSample_StraightRoofPipe,
    TrunkSample_StraightRoofSubPipe,
    TrunkSample_StraightSidePipe,
    TrunkSample_StraightSideSubPipe,
  ],
})
export class TrunkPart_Straight extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public straightPipeSample: TrunkSample_StraightPipe; // 가로대 파이프 샘플
  public straightRoofPipeSample: TrunkSample_StraightRoofPipe; // 지붕 가로대 파이프 샘플
  public straightRoofSubPipeSample: TrunkSample_StraightRoofSubPipe; // 지붕보조 가로대 파이프 샘플
  public straightSidePipeSample: TrunkSample_StraightSidePipe; // 측면 가로대 파이프 샘플
  public straightSideSubPipeSample: TrunkSample_StraightSideSubPipe; // 측면보조 가로대 파이프 샘플
  public straightFloorPipeSample: TrunkSample_StraightFloorPipe; // 바닥 가로대 파이프 샘플

  // 아이템
  public straightRoof_pipe: ItemPipe;
  public straightRoof_connectionPin: ItemConnector;
  public straightRoofAndRafter_steelStringGrabber: ItemHolder;
  public straightRoofAndRafter_itemHolder: ItemHolder;
  public straightRoofAndRafter_saddleHolder: ItemHolder;
  public straightRoofSub_pipe: ItemPipe;
  public straightRoofSub_connectionPin: ItemConnector;
  public straightRoofSubAndRafter_steelStringGrabber: ItemHolder;
  public straightRoofSubAndRafter_saddleHolder: ItemHolder;
  public straightSide_pipe: ItemPipe;
  public straightSide_connectionPin: ItemConnector;
  public straightSideAndRafter_steelStringGrabber: ItemHolder;
  public straightSideAndRafter_itemHolder: ItemHolder;
  public straightSideAndRafter_saddleHolder: ItemHolder;
  public straightSideSub_pipe: ItemPipe;
  public straightSideSub_connectionPin: ItemConnector;
  public straightSideSubAndRafter_steelStringGrabber: ItemHolder;
  public straightSideSubAndRafter_saddleHolder: ItemHolder;
  public straightFloor_pipe: ItemPipe;
  public straightFloor_connectionPin: ItemConnector;
  public straightFloorAndRafter_steelStringGrabber: ItemHolder;
  public straightFloorAndRafter_itemHolder: ItemHolder;
  public straightFloorAndRafter_saddleHolder: ItemHolder;
  public straight_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 가로대 줄수(전체)
   */
  public get straightLineNumber(): number {
    return (
      this._straightLineNumberRoof +
      this._straightLineNumberRoofSub +
      this._straightLineNumberSide +
      this._straightLineNumberSideSub +
      this._straightLineNumberFloor
    );
  }

  //
  public set straightLineNumber(value: number) {}

  /**
   * 내재해 조리개 줄수(전체)
   */
  public get enhancedGrabberLineNumber(): number {
    return (
      this._enhancedGrabberLineNumberRoof + this._enhancedGrabberLineNumberSide + this._enhancedGrabberLineNumberFloor
    );
  }

  //
  public set enhancedGrabberLineNumber(value: number) {}

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _straightLength: number; // 가로대 길이
  @jsonMember(Number)
  public _straightLineNumberRoof: number; // 가로대 줄수(지붕)
  @jsonMember(Number)
  public _straightLineNumberRoofSub: number; // 가로대 줄수(지붕보조)
  @jsonMember(Number)
  public _straightLineNumberSide: number; // 가로대 줄수(측면)
  @jsonMember(Number)
  public _straightLineNumberSideSub: number; // 가로대 줄수(측면보조)
  @jsonMember(Number)
  public _straightLineNumberFloor: number; // 가로대 줄수(바닥)
  @jsonMember(Boolean)
  public _enhancedGrabberSelected: boolean; // 내재해 조리개 선택
  @jsonMember(String)
  public _enhancedGrabberType: string; // 내재해 조리개 형태
  @jsonMember(Number)
  public _enhancedGrabberLineNumberRoof: number; // 내재해 조리개 줄수(지붕)
  @jsonMember(Number)
  public _enhancedGrabberLineNumberSide: number; // 내재해 조리개 줄수(측면)
  @jsonMember(Number)
  public _enhancedGrabberLineNumberFloor: number; // 내재해 조리개 줄수(바닥)
  @jsonMember(Boolean)
  public _straightPositionEditSelected: boolean; // 가로대 위치별 수정 선택
  @jsonMember(Boolean)
  public _straightAlgorithmSelected: boolean; // 가로대 줄수 자동 계산 선택

  /**
   * 가로대 길이
   */
  public get straightLength(): number {
    return this._straightLength;
  }

  //
  public set straightLength(value: number) {
    this._straightLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 가로대 줄수(지붕)
   */
  public get straightLineNumberRoof(): number {
    return this._straightLineNumberRoof;
  }

  //
  public set straightLineNumberRoof(value: number) {
    this._straightLineNumberRoof = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_enhancedGrabberLineNumberRoofMain();
    this.algorithmPart();
  }

  /**
   * 가로대 줄수(지붕보조)
   */
  public get straightLineNumberRoofSub(): number {
    return this._straightLineNumberRoofSub;
  }

  //
  public set straightLineNumberRoofSub(value: number) {
    this._straightLineNumberRoofSub = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 가로대 줄수(측면)
   */
  public get straightLineNumberSide(): number {
    return this._straightLineNumberSide;
  }

  //
  public set straightLineNumberSide(value: number) {
    this._straightLineNumberSide = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_enhancedGrabberLineNumberSideMain();
    this.algorithmPart();
  }

  /**
   * 가로대 줄수(측면보조)
   */
  public get straightLineNumberSideSub(): number {
    return this._straightLineNumberSideSub;
  }

  //
  public set straightLineNumberSideSub(value: number) {
    this._straightLineNumberSideSub = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 가로대 줄수(바닥)
   */
  public get straightLineNumberFloor(): number {
    return this._straightLineNumberFloor;
  }

  //
  public set straightLineNumberFloor(value: number) {
    this._straightLineNumberFloor = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_enhancedGrabberLineNumberFloor();
    this.algorithmPart();
  }

  /**
   * 내재해 조리개 선택
   */
  public get enhancedGrabberSelected(): boolean {
    return this._enhancedGrabberSelected;
  }

  //
  public set enhancedGrabberSelected(value: boolean) {
    this._enhancedGrabberSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 내재해 조리개 형태
   */
  public get enhancedGrabberType(): string {
    return this._enhancedGrabberType;
  }

  //
  public set enhancedGrabberType(value: string) {
    this._enhancedGrabberType = value;

    // 알고리즘
    this.algorithm_enhancedGrabberLineNumber();
    this.algorithmProduct_straightAndRafter_itemHolder();
    this.algorithmPart();
  }

  /**
   * 내재해 조리개 줄수(지붕)
   */
  public get enhancedGrabberLineNumberRoof(): number {
    return this._enhancedGrabberLineNumberRoof;
  }

  //
  public set enhancedGrabberLineNumberRoof(value: number) {
    this._enhancedGrabberLineNumberRoof = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 내재해 조리개 줄수(측면)
   */
  public get enhancedGrabberLineNumberSide(): number {
    return this._enhancedGrabberLineNumberSide;
  }

  //
  public set enhancedGrabberLineNumberSide(value: number) {
    this._enhancedGrabberLineNumberSide = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 내재해 조리개 줄수(바닥)
   */
  public get enhancedGrabberLineNumberFloor(): number {
    return this._enhancedGrabberLineNumberFloor;
  }

  //
  public set enhancedGrabberLineNumberFloor(value: number) {
    this._enhancedGrabberLineNumberFloor = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 가로대 위치별 수정 선택
   */
  public get straightPositionEditSelected(): boolean {
    return this._straightPositionEditSelected;
  }

  //
  public set straightPositionEditSelected(value: boolean) {
    this._straightPositionEditSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 가로대 줄수 자동 계산 선택
   */
  public get straightAlgorithmSelected(): boolean {
    return this._straightAlgorithmSelected;
  }

  //
  public set straightAlgorithmSelected(value: boolean) {
    this._straightAlgorithmSelected = value;

    // 알고리즘
    this.algorithm_straightAlgorithmSelected();
    this.algorithm_straightAlgorithmSelectedByLevel1();
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.straightPipeSample = new TrunkSample_StraightPipe();
    this.straightRoofPipeSample = new TrunkSample_StraightRoofPipe();
    this.straightRoofSubPipeSample = new TrunkSample_StraightRoofSubPipe();
    this.straightSidePipeSample = new TrunkSample_StraightSidePipe();
    this.straightSideSubPipeSample = new TrunkSample_StraightSideSubPipe();
    this.straightFloorPipeSample = new TrunkSample_StraightFloorPipe();

    this.sampleAC = [
      this.straightPipeSample,
      this.straightRoofPipeSample,
      this.straightRoofSubPipeSample,
      this.straightSidePipeSample,
      this.straightSideSubPipeSample,
      this.straightFloorPipeSample,
    ];

    // 아이템
    this.straightRoof_pipe = new ItemPipe();
    this.straightRoof_connectionPin = new ItemConnector();
    this.straightRoofAndRafter_steelStringGrabber = new ItemHolder();
    this.straightRoofAndRafter_itemHolder = new ItemHolder();
    this.straightRoofAndRafter_saddleHolder = new ItemHolder();
    this.straightRoofSub_pipe = new ItemPipe();
    this.straightRoofSub_connectionPin = new ItemConnector();
    this.straightRoofSubAndRafter_steelStringGrabber = new ItemHolder();
    this.straightRoofSubAndRafter_saddleHolder = new ItemHolder();
    this.straightSide_pipe = new ItemPipe();
    this.straightSide_connectionPin = new ItemConnector();
    this.straightSideAndRafter_steelStringGrabber = new ItemHolder();
    this.straightSideAndRafter_itemHolder = new ItemHolder();
    this.straightSideAndRafter_saddleHolder = new ItemHolder();
    this.straightSideSub_pipe = new ItemPipe();
    this.straightSideSub_connectionPin = new ItemConnector();
    this.straightSideSubAndRafter_steelStringGrabber = new ItemHolder();
    this.straightSideSubAndRafter_saddleHolder = new ItemHolder();
    this.straightFloor_pipe = new ItemPipe();
    this.straightFloor_connectionPin = new ItemConnector();
    this.straightFloorAndRafter_steelStringGrabber = new ItemHolder();
    this.straightFloorAndRafter_itemHolder = new ItemHolder();
    this.straightFloorAndRafter_saddleHolder = new ItemHolder();
    this.straight_screw = new ItemScrew();

    this.itemAC = [
      this.straightRoof_pipe,
      this.straightRoof_connectionPin,
      this.straightRoofAndRafter_steelStringGrabber,
      this.straightRoofAndRafter_itemHolder,
      this.straightRoofAndRafter_saddleHolder,
      this.straightRoofSub_pipe,
      this.straightRoofSub_connectionPin,
      this.straightRoofSubAndRafter_steelStringGrabber,
      this.straightRoofSubAndRafter_saddleHolder,
      this.straightSide_pipe,
      this.straightSide_connectionPin,
      this.straightSideAndRafter_steelStringGrabber,
      this.straightSideAndRafter_itemHolder,
      this.straightSideAndRafter_saddleHolder,
      this.straightSideSub_pipe,
      this.straightSideSub_connectionPin,
      this.straightSideSubAndRafter_steelStringGrabber,
      this.straightSideSubAndRafter_saddleHolder,
      this.straightFloor_pipe,
      this.straightFloor_connectionPin,
      this.straightFloorAndRafter_steelStringGrabber,
      this.straightFloorAndRafter_itemHolder,
      this.straightFloorAndRafter_saddleHolder,
      this.straight_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.straightPipeSample = <TrunkSample_StraightPipe>this.sampleAC[0];
    this.straightRoofPipeSample = <TrunkSample_StraightRoofPipe>this.sampleAC[1];
    this.straightRoofSubPipeSample = <TrunkSample_StraightRoofSubPipe>this.sampleAC[2];
    this.straightSidePipeSample = <TrunkSample_StraightSidePipe>this.sampleAC[3];
    this.straightSideSubPipeSample = <TrunkSample_StraightSideSubPipe>this.sampleAC[4];
    this.straightFloorPipeSample = <TrunkSample_StraightFloorPipe>this.sampleAC[5];

    // 아이템
    this.straightRoof_pipe = <ItemPipe>this.itemAC[0];
    this.straightRoof_connectionPin = <ItemConnector>this.itemAC[1];
    this.straightRoofAndRafter_steelStringGrabber = <ItemHolder>this.itemAC[2];
    this.straightRoofAndRafter_itemHolder = <ItemHolder>this.itemAC[3];
    this.straightRoofAndRafter_saddleHolder = <ItemHolder>this.itemAC[4];
    this.straightRoofSub_pipe = <ItemPipe>this.itemAC[5];
    this.straightRoofSub_connectionPin = <ItemConnector>this.itemAC[6];
    this.straightRoofSubAndRafter_steelStringGrabber = <ItemHolder>this.itemAC[7];
    this.straightRoofSubAndRafter_saddleHolder = <ItemHolder>this.itemAC[8];
    this.straightSide_pipe = <ItemPipe>this.itemAC[9];
    this.straightSide_connectionPin = <ItemConnector>this.itemAC[10];
    this.straightSideAndRafter_steelStringGrabber = <ItemHolder>this.itemAC[11];
    this.straightSideAndRafter_itemHolder = <ItemHolder>this.itemAC[12];
    this.straightSideAndRafter_saddleHolder = <ItemHolder>this.itemAC[13];
    this.straightSideSub_pipe = <ItemPipe>this.itemAC[14];
    this.straightSideSub_connectionPin = <ItemConnector>this.itemAC[15];
    this.straightSideSubAndRafter_steelStringGrabber = <ItemHolder>this.itemAC[16];
    this.straightSideSubAndRafter_saddleHolder = <ItemHolder>this.itemAC[17];
    this.straightFloor_pipe = <ItemPipe>this.itemAC[18];
    this.straightFloor_connectionPin = <ItemConnector>this.itemAC[19];
    this.straightFloorAndRafter_steelStringGrabber = <ItemHolder>this.itemAC[20];
    this.straightFloorAndRafter_itemHolder = <ItemHolder>this.itemAC[21];
    this.straightFloorAndRafter_saddleHolder = <ItemHolder>this.itemAC[22];
    this.straight_screw = <ItemScrew>this.itemAC[23];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param straightLineNumberRoof: number 가로대 줄수(지붕)
   * @param straightLineNumberRoofSub: number 가로대 줄수(지붕보조)
   * @param straightLineNumberSide: number 가로대 줄수(측면)
   * @param straightLineNumberSideSub: number 가로대 줄수(측면보조)
   * @param straightLineNumberFloor: number 가로대 줄수(바닥)
   * @param enhancedGrabberSelected: boolean 내재해 조리개 선택
   * @param enhancedGrabberType: string 내재해 조리개 형태
   * @param enhancedGrabberLineNumberRoof: number 내재해 조리개 줄수(지붕)
   * @param enhancedGrabberLineNumberSide: number 내재해 조리개 줄수(측면)
   * @param enhancedGrabberLineNumberFloor: number 내재해 조리개 줄수(바닥)
   * @param straightPositionEditSelected: boolean 가로대 위치별 수정 선택
   * @param straightAlgorithmSelected: boolean 가로대 줄수 자동 계산
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    straightLineNumberRoof: number = 0,
    straightLineNumberRoofSub: number = 0,
    straightLineNumberSide: number = 0,
    straightLineNumberSideSub: number = 0,
    straightLineNumberFloor: number = 0,
    enhancedGrabberSelected: boolean = false,
    enhancedGrabberType: string = "",
    enhancedGrabberLineNumberRoof: number = 0,
    enhancedGrabberLineNumberSide: number = 0,
    enhancedGrabberLineNumberFloor: number = 0,
    straightPositionEditSelected: boolean = false,
    straightAlgorithmSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._straightLineNumberRoof = straightLineNumberRoof;
    this._straightLineNumberRoofSub = straightLineNumberRoofSub;
    this._straightLineNumberSide = straightLineNumberSide;
    this._straightLineNumberSideSub = straightLineNumberSideSub;
    this._straightLineNumberFloor = straightLineNumberFloor;
    this._enhancedGrabberSelected = enhancedGrabberSelected;
    this._enhancedGrabberType = enhancedGrabberType;
    this._enhancedGrabberLineNumberRoof = enhancedGrabberLineNumberRoof;
    this._enhancedGrabberLineNumberSide = enhancedGrabberLineNumberSide;
    this._enhancedGrabberLineNumberFloor = enhancedGrabberLineNumberFloor;
    this._straightPositionEditSelected = straightPositionEditSelected;
    this._straightAlgorithmSelected = straightAlgorithmSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.straightPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightRoofPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightRoofSubPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕보조 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightSidePipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 측면 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightSideSubPipeSample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면보조 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightFloorPipeSample.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "바닥 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.straightRoof_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightRoof_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "지붕 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightRoofAndRafter_steelStringGrabber.setDefaultData(
        2,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "지붕 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightRoofAndRafter_itemHolder.setDefaultData(
        3,
        CONST.ITEM_ID_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        "지붕 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightRoofAndRafter_saddleHolder.setDefaultData(
        4,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "지붕 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.straightRoofSub_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕보조 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightRoofSub_connectionPin.setDefaultData(
        6,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "지붕보조 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightRoofSubAndRafter_steelStringGrabber.setDefaultData(
        7,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "지붕보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightRoofSubAndRafter_saddleHolder.setDefaultData(
        8,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "지붕보조 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.straightSide_pipe.setDefaultData(
        9,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightSide_connectionPin.setDefaultData(
        10,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "측면 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightSideAndRafter_steelStringGrabber.setDefaultData(
        11,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "측면 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightSideAndRafter_itemHolder.setDefaultData(
        12,
        CONST.ITEM_ID_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        "측면 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightSideAndRafter_saddleHolder.setDefaultData(
        13,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "측면 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.straightSideSub_pipe.setDefaultData(
        14,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면보조 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightSideSub_connectionPin.setDefaultData(
        15,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "측면보조 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightSideSubAndRafter_steelStringGrabber.setDefaultData(
        16,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "측면보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightSideSubAndRafter_saddleHolder.setDefaultData(
        17,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "측면보조 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.straightFloor_pipe.setDefaultData(
        18,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "바닥 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.straightFloor_connectionPin.setDefaultData(
        19,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "바닥 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightFloorAndRafter_steelStringGrabber.setDefaultData(
        20,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "바닥 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightFloorAndRafter_itemHolder.setDefaultData(
        21,
        CONST.ITEM_ID_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        "바닥 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.straightFloorAndRafter_saddleHolder.setDefaultData(
        22,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "바닥 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.straight_screw.setDefaultData(
        23,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "가로대",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      // 지붕 가로대 파이프 샘플
      const straightRoofPipeSample: TrunkSample_StraightRoofPipe = new TrunkSample_StraightRoofPipe();
      straightRoofPipeSample.setAssociation(design, struct, work, level, position, this);
      straightRoofPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.sampleAC.push(straightRoofPipeSample);
      // 지붕보조 가로대 파이프 샘플
      const straightRoofSubPipeSample: TrunkSample_StraightRoofSubPipe = new TrunkSample_StraightRoofSubPipe();
      straightRoofSubPipeSample.setAssociation(design, struct, work, level, position, this);
      straightRoofSubPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕보조 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.sampleAC.push(straightRoofSubPipeSample);
      // 측면 가로대 파이프 샘플
      const straightSidePipeSample: TrunkSample_StraightSidePipe = new TrunkSample_StraightSidePipe();
      straightSidePipeSample.setAssociation(design, struct, work, level, position, this);
      straightSidePipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.sampleAC.push(straightSidePipeSample);
      // 측면보조 가로대 파이프 샘플
      const straightSideSubPipeSample: TrunkSample_StraightSideSubPipe = new TrunkSample_StraightSideSubPipe();
      straightSideSubPipeSample.setAssociation(design, struct, work, level, position, this);
      straightSideSubPipeSample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면보조 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.sampleAC.push(straightSideSubPipeSample);
      // 바닥 가로대 파이프 샘플
      const straightFloorPipeSample: TrunkSample_StraightFloorPipe = new TrunkSample_StraightFloorPipe();
      straightFloorPipeSample.setAssociation(design, struct, work, level, position, this);
      straightFloorPipeSample.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "바닥 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.sampleAC.push(straightFloorPipeSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    // 아이템
    if (this.itemAC.length === 14) {
      // 기존 나사 하나로 합치기
      const straight_screw: ItemScrew = <ItemScrew>this.itemAC[13];
      straight_screw.purpose = "가로대";
      straight_screw.designQuantity = straight_screw.designQuantity + (<ItemScrew>this.itemAC[2]).getSelectedQuantity();
      this.itemAC.splice(2, 1);
      // 기존 새들 위치 이동
      CommonUtil.changeArrayIndex(this.itemAC, 11, 5);
      // [지붕 가로대] 파이프
      (<Item>this.itemAC[0]).purpose = "지붕 가로대";
      // [지붕 가로대] 연결핀
      (<Item>this.itemAC[1]).purpose = "지붕 가로대";
      // [지붕 가로대 + 서까래] 강선 조리개
      (<Item>this.itemAC[2]).purpose = "지붕 가로대 + 서까래";
      // [지붕 가로대 + 서까래] 강판 조리개
      (<Item>this.itemAC[3]).purpose = "지붕 가로대 + 서까래";
      // [지붕 가로대 + 서까래] 십자 강판 조리개
      (<Item>this.itemAC[4]).purpose = "지붕 가로대 + 서까래";
      // [지붕 가로대 + 서까래] U 클램프
      const straightRoofAndRafter_uClamp: ItemHolder = new ItemHolder();
      straightRoofAndRafter_uClamp.setAssociation(design, struct, work, level, position, this);
      straightRoofAndRafter_uClamp.setDefaultData(
        5,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "지붕 가로대 + 서까래",
        "원형, 일반, 32mm, 25mm",
      );
      this.itemAC.splice(5, 0, straightRoofAndRafter_uClamp);
      // [지붕 가로대 + 서까래] 새들 고정구
      (<Item>this.itemAC[6]).purpose = "지붕 가로대 + 서까래";
      // [지붕보조 가로대] 파이프
      const straightRoofSub_pipe: ItemPipe = new ItemPipe();
      straightRoofSub_pipe.setAssociation(design, struct, work, level, position, this);
      straightRoofSub_pipe.setDefaultData(
        7,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지붕보조 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.itemAC.splice(7, 0, straightRoofSub_pipe);
      // [지붕보조 가로대] 연결핀
      const straightRoofSub_connectionPin: ItemConnector = new ItemConnector();
      straightRoofSub_connectionPin.setAssociation(design, struct, work, level, position, this);
      straightRoofSub_connectionPin.setDefaultData(
        8,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "지붕보조 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(8, 0, straightRoofSub_connectionPin);
      // [지붕보조 가로대 + 서까래] 강선 조리개
      const straightRoofSubAndRafter_steelStringGrabber: ItemHolder = new ItemHolder();
      straightRoofSubAndRafter_steelStringGrabber.setAssociation(design, struct, work, level, position, this);
      straightRoofSubAndRafter_steelStringGrabber.setDefaultData(
        9,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "지붕보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(9, 0, straightRoofSubAndRafter_steelStringGrabber);
      // [지붕보조 가로대 + 서까래] 강판 조리개
      const straightRoofSubAndRafter_steelSheetGrabber: ItemHolder = new ItemHolder();
      straightRoofSubAndRafter_steelSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightRoofSubAndRafter_steelSheetGrabber.setDefaultData(
        10,
        CONST.ITEM_ID_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        "지붕보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(10, 0, straightRoofSubAndRafter_steelSheetGrabber);
      // [지붕보조 가로대 + 서까래] 십자 강판 조리개
      const straightRoofSubAndRafter_crossSteelSheetGrabber: ItemHolder = new ItemHolder();
      straightRoofSubAndRafter_crossSteelSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightRoofSubAndRafter_crossSteelSheetGrabber.setDefaultData(
        11,
        CONST.ITEM_ID_CROSS_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_CROSS_STEEL_SHEET_GRABBER,
        "지붕보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(11, 0, straightRoofSubAndRafter_crossSteelSheetGrabber);
      // [지붕보조 가로대 + 서까래] U 클램프
      const straightRoofSubAndRafter_uClamp: ItemHolder = new ItemHolder();
      straightRoofSubAndRafter_uClamp.setAssociation(design, struct, work, level, position, this);
      straightRoofSubAndRafter_uClamp.setDefaultData(
        12,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "지붕보조 가로대 + 서까래",
        "원형, 일반, 32mm, 25mm",
      );
      this.itemAC.splice(12, 0, straightRoofSubAndRafter_uClamp);
      // [지붕보조 가로대 + 서까래] 새들 고정구
      const straightRoofSubAndRafter_saddleHolder: ItemHolder = new ItemHolder();
      straightRoofSubAndRafter_saddleHolder.setAssociation(design, struct, work, level, position, this);
      straightRoofSubAndRafter_saddleHolder.setDefaultData(
        13,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "지붕보조 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.itemAC.splice(13, 0, straightRoofSubAndRafter_saddleHolder);
      // [측면 가로대] 파이프
      const straightSide_pipe: ItemPipe = new ItemPipe();
      straightSide_pipe.setAssociation(design, struct, work, level, position, this);
      straightSide_pipe.setDefaultData(
        14,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.itemAC.splice(14, 0, straightSide_pipe);
      // [측면 가로대] 연결핀
      const straightSide_connectionPin: ItemConnector = new ItemConnector();
      straightSide_connectionPin.setAssociation(design, struct, work, level, position, this);
      straightSide_connectionPin.setDefaultData(
        15,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "측면 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(15, 0, straightSide_connectionPin);
      // [측면 가로대 + 서까래] 강선 조리개
      (<Item>this.itemAC[16]).purpose = "측면 가로대 + 서까래";
      // [측면 가로대 + 서까래] 강판 조리개
      (<Item>this.itemAC[17]).purpose = "측면 가로대 + 서까래";
      // [측면 가로대 + 서까래] 십자 강판 조리개
      (<Item>this.itemAC[18]).purpose = "측면 가로대 + 서까래";
      // [측면 가로대 + 서까래] U 클램프
      const straightSideAndRafter_uClamp: ItemHolder = new ItemHolder();
      straightSideAndRafter_uClamp.setAssociation(design, struct, work, level, position, this);
      straightSideAndRafter_uClamp.setDefaultData(
        19,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "측면 가로대 + 서까래",
        "원형, 일반, 32mm, 25mm",
      );
      this.itemAC.splice(19, 0, straightSideAndRafter_uClamp);
      // [측면 가로대 + 서까래] 새들 고정구
      const straightSideAndRafter_saddleHolder: ItemHolder = new ItemHolder();
      straightSideAndRafter_saddleHolder.setAssociation(design, struct, work, level, position, this);
      straightSideAndRafter_saddleHolder.setDefaultData(
        20,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "측면 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.itemAC.splice(20, 0, straightSideAndRafter_saddleHolder);
      // [측면보조 가로대] 파이프
      const straightSideSub_pipe: ItemPipe = new ItemPipe();
      straightSideSub_pipe.setAssociation(design, struct, work, level, position, this);
      straightSideSub_pipe.setDefaultData(
        21,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면보조 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.itemAC.splice(21, 0, straightSideSub_pipe);
      // [측면보조 가로대] 연결핀
      const straightSideSub_connectionPin: ItemConnector = new ItemConnector();
      straightSideSub_connectionPin.setAssociation(design, struct, work, level, position, this);
      straightSideSub_connectionPin.setDefaultData(
        22,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "측면보조 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(22, 0, straightSideSub_connectionPin);
      // [측면보조 가로대 + 서까래] 강선 조리개
      const straightSideSubAndRafter_steelStringGrabber: ItemHolder = new ItemHolder();
      straightSideSubAndRafter_steelStringGrabber.setAssociation(design, struct, work, level, position, this);
      straightSideSubAndRafter_steelStringGrabber.setDefaultData(
        23,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "측면보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(23, 0, straightSideSubAndRafter_steelStringGrabber);
      // [측면보조 가로대 + 서까래] 강판 조리개
      const straightSideSubAndRafter_steelSheetGrabber: ItemHolder = new ItemHolder();
      straightSideSubAndRafter_steelSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightSideSubAndRafter_steelSheetGrabber.setDefaultData(
        24,
        CONST.ITEM_ID_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
        "측면보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(24, 0, straightSideSubAndRafter_steelSheetGrabber);
      // [측면보조 가로대 + 서까래] 십자 강판 조리개
      const straightSideSubAndRafter_crossSteelSheetGrabber: ItemHolder = new ItemHolder();
      straightSideSubAndRafter_crossSteelSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightSideSubAndRafter_crossSteelSheetGrabber.setDefaultData(
        25,
        CONST.ITEM_ID_CROSS_STEEL_SHEET_GRABBER,
        CONST.ITEM_NAME_CROSS_STEEL_SHEET_GRABBER,
        "측면보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(25, 0, straightSideSubAndRafter_crossSteelSheetGrabber);
      // [측면보조 가로대 + 서까래] U 클램프
      const straightSideSubAndRafter_uClamp: ItemHolder = new ItemHolder();
      straightSideSubAndRafter_uClamp.setAssociation(design, struct, work, level, position, this);
      straightSideSubAndRafter_uClamp.setDefaultData(
        26,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "측면보조 가로대 + 서까래",
        "원형, 일반, 32mm, 25mm",
      );
      this.itemAC.splice(26, 0, straightSideSubAndRafter_uClamp);
      // [측면보조 가로대 + 서까래] 새들 고정구
      const straightSideSubAndRafter_saddleHolder: ItemHolder = new ItemHolder();
      straightSideSubAndRafter_saddleHolder.setAssociation(design, struct, work, level, position, this);
      straightSideSubAndRafter_saddleHolder.setDefaultData(
        27,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "측면보조 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.itemAC.splice(27, 0, straightSideSubAndRafter_saddleHolder);
      // [바닥 가로대] 파이프
      const straightFloor_pipe: ItemPipe = new ItemPipe();
      straightFloor_pipe.setAssociation(design, struct, work, level, position, this);
      straightFloor_pipe.setDefaultData(
        28,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "바닥 가로대",
        (<ItemPipe>this.sampleAC[0]).specs,
      );
      this.itemAC.splice(28, 0, straightFloor_pipe);
      // [바닥 가로대] 연결핀
      const straightFloor_connectionPin: ItemConnector = new ItemConnector();
      straightFloor_connectionPin.setAssociation(design, struct, work, level, position, this);
      straightFloor_connectionPin.setDefaultData(
        29,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "바닥 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(29, 0, straightFloor_connectionPin);
      // [바닥 가로대 + 서까래] 강선 조리개
      (<Item>this.itemAC[30]).purpose = "바닥 가로대 + 서까래";
      // [바닥 가로대 + 서까래] 강판 조리개
      (<Item>this.itemAC[31]).purpose = "바닥 가로대 + 서까래";
      // [바닥 가로대 + 서까래] 십자 강판 조리개
      (<Item>this.itemAC[32]).purpose = "바닥 가로대 + 서까래";
      // [바닥 가로대 + 서까래] U 클램프
      const straightFloorAndRafter_uClamp: ItemHolder = new ItemHolder();
      straightFloorAndRafter_uClamp.setAssociation(design, struct, work, level, position, this);
      straightFloorAndRafter_uClamp.setDefaultData(
        33,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "바닥 가로대 + 서까래",
        "원형, 일반, 32mm, 25mm",
      );
      this.itemAC.splice(33, 0, straightFloorAndRafter_uClamp);
      // [바닥 가로대 + 서까래] 새들 고정구
      const straightFloorAndRafter_saddleHolder: ItemHolder = new ItemHolder();
      straightFloorAndRafter_saddleHolder.setAssociation(design, struct, work, level, position, this);
      straightFloorAndRafter_saddleHolder.setDefaultData(
        34,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "바닥 가로대 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.itemAC.splice(34, 0, straightFloorAndRafter_saddleHolder);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (
      this.itemAC.length === 36 &&
      this.itemAC[5] instanceof ItemHolder &&
      this.itemAC[13] instanceof ItemHolder &&
      this.itemAC[21] instanceof ItemPipe &&
      this.itemAC[29] instanceof ItemConnector
    ) {
      // [지붕 가로대 + 서까래] 선판 조리개
      const straightRoofAndRafter_lineSheetGrabber: ItemHolder = new ItemHolder();
      straightRoofAndRafter_lineSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightRoofAndRafter_lineSheetGrabber.setDefaultData(
        5,
        CONST.ITEM_ID_LINE_SHEET_GRABBER,
        CONST.ITEM_NAME_LINE_SHEET_GRABBER,
        "지붕 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(5, 0, straightRoofAndRafter_lineSheetGrabber);
      // [지붕보조 가로대 + 서까래] 선판 조리개
      const straightRoofSubAndRafter_lineSheetGrabber: ItemHolder = new ItemHolder();
      straightRoofSubAndRafter_lineSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightRoofSubAndRafter_lineSheetGrabber.setDefaultData(
        13,
        CONST.ITEM_ID_LINE_SHEET_GRABBER,
        CONST.ITEM_NAME_LINE_SHEET_GRABBER,
        "지붕보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(13, 0, straightRoofSubAndRafter_lineSheetGrabber);
      // [측면 가로대 + 서까래] 선판 조리개
      const straightSideAndRafter_lineSheetGrabber: ItemHolder = new ItemHolder();
      straightSideAndRafter_lineSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightSideAndRafter_lineSheetGrabber.setDefaultData(
        21,
        CONST.ITEM_ID_LINE_SHEET_GRABBER,
        CONST.ITEM_NAME_LINE_SHEET_GRABBER,
        "측면 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(21, 0, straightSideAndRafter_lineSheetGrabber);
      // [측면보조 가로대 + 서까래] 선판 조리개
      const straightSideSubAndRafter_lineSheetGrabber: ItemHolder = new ItemHolder();
      straightSideSubAndRafter_lineSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightSideSubAndRafter_lineSheetGrabber.setDefaultData(
        29,
        CONST.ITEM_ID_LINE_SHEET_GRABBER,
        CONST.ITEM_NAME_LINE_SHEET_GRABBER,
        "측면보조 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(29, 0, straightSideSubAndRafter_lineSheetGrabber);
      // [바닥 가로대 + 서까래] 선판 조리개
      const straightFloorAndRafter_lineSheetGrabber: ItemHolder = new ItemHolder();
      straightFloorAndRafter_lineSheetGrabber.setAssociation(design, struct, work, level, position, this);
      straightFloorAndRafter_lineSheetGrabber.setDefaultData(
        37,
        CONST.ITEM_ID_LINE_SHEET_GRABBER,
        CONST.ITEM_NAME_LINE_SHEET_GRABBER,
        "바닥 가로대 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(37, 0, straightFloorAndRafter_lineSheetGrabber);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (
      this.itemAC.length === 41 &&
      this.itemAC[27] instanceof ItemHolder &&
      this.itemAC[28] instanceof ItemHolder &&
      this.itemAC[29] instanceof ItemHolder &&
      this.itemAC[30] instanceof ItemHolder &&
      this.itemAC[35] instanceof ItemHolder &&
      this.itemAC[36] instanceof ItemHolder &&
      this.itemAC[37] instanceof ItemHolder &&
      this.itemAC[38] instanceof ItemHolder
    ) {
      // [바닥 가로대 + 서까래] 내재해 조리개 1개로 합침
      this.itemAC.splice(36, 3);
      // [측면보조 가로대 + 서까래] 내재해 조리개 모두 제거
      this.itemAC.splice(27, 4);
      // [측면 가로대 + 서까래] 내재해 조리개 1개로 합침
      this.itemAC.splice(20, 3);
      // [지붕보조 가로대 + 서까래] 내재해 조리개 모두 제거
      this.itemAC.splice(11, 4);
      // [지붕 가로대 + 서까래] 내재해 조리개 1개로 합침
      this.itemAC.splice(4, 3);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_straightLength();
    this.algorithm_straightAlgorithmSelected();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const trunkPosition: TrunkPosition = <TrunkPosition>this.position;

    /// //////// 선택, 가시성 ///////////

    this.straightRoof_pipe.checkSelected(true);
    this.straightRoof_connectionPin.checkSelected(true);
    this.straightRoofAndRafter_steelStringGrabber.selected = true;
    this.straightRoofAndRafter_steelStringGrabber.visible = true;
    this.straightLineNumberRoofSub > 0
      ? this.straightRoofSub_pipe.checkSelected(true)
      : this.straightRoofSub_pipe.checkSelected(false);
    this.straightLineNumberRoofSub > 0
      ? this.straightRoofSub_connectionPin.checkSelected(true)
      : this.straightRoofSub_connectionPin.checkSelected(false);
    this.straightRoofSubAndRafter_steelStringGrabber.selected = this.straightLineNumberRoofSub > 0;
    this.straightRoofSubAndRafter_steelStringGrabber.visible = this.straightLineNumberRoofSub > 0;
    this.straightSide_pipe.checkSelected(true);
    this.straightSide_connectionPin.checkSelected(true);
    this.straightSideAndRafter_steelStringGrabber.selected = true;
    this.straightSideAndRafter_steelStringGrabber.visible = true;
    this.straightSideSub_pipe.checkSelected(true);
    this.straightLineNumberSideSub > 0
      ? this.straightSideSub_connectionPin.checkSelected(true)
      : this.straightSideSub_connectionPin.checkSelected(false);
    this.straightSideSubAndRafter_steelStringGrabber.selected = this.straightLineNumberSideSub > 0;
    this.straightSideSubAndRafter_steelStringGrabber.visible = this.straightLineNumberSideSub > 0;
    this.straightFloor_pipe.checkSelected(true);
    this.straightFloor_connectionPin.checkSelected(true);
    this.straightFloorAndRafter_steelStringGrabber.selected = true;
    this.straightFloorAndRafter_steelStringGrabber.visible = true;
    if (this.enhancedGrabberSelected === true) {
      this.straightRoofAndRafter_itemHolder.selected = true;
      this.straightRoofAndRafter_itemHolder.visible = true;
      this.straightSideAndRafter_itemHolder.selected = true;
      this.straightSideAndRafter_itemHolder.visible = true;
      this.straightFloorAndRafter_itemHolder.selected = true;
      this.straightFloorAndRafter_itemHolder.visible = true;
    } else {
      this.straightRoofAndRafter_itemHolder.selected = false;
      this.straightRoofAndRafter_itemHolder.visible = false;
      this.straightSideAndRafter_itemHolder.selected = false;
      this.straightSideAndRafter_itemHolder.visible = false;
      this.straightFloorAndRafter_itemHolder.selected = false;
      this.straightFloorAndRafter_itemHolder.visible = false;
    }
    this.straightRoofAndRafter_saddleHolder.selected = true;
    this.straightRoofAndRafter_saddleHolder.visible = true;
    this.straightRoofSubAndRafter_saddleHolder.selected = this.straightLineNumberRoofSub > 0;
    this.straightRoofSubAndRafter_saddleHolder.visible = this.straightLineNumberRoofSub > 0;
    this.straightSideAndRafter_saddleHolder.selected = true;
    this.straightSideAndRafter_saddleHolder.visible = true;
    this.straightSideSubAndRafter_saddleHolder.selected = this.straightLineNumberSideSub > 0;
    this.straightSideSubAndRafter_saddleHolder.visible = this.straightLineNumberSideSub > 0;
    this.straightFloorAndRafter_saddleHolder.selected = true;
    this.straightFloorAndRafter_saddleHolder.visible = true;
    this.straight_screw.selected = true;
    this.straight_screw.visible = true;

    /// //////// 수치 ///////////

    // 가로대 줄수
    const straightLineNumber: number =
      this.straightLineNumberRoof +
      this.straightLineNumberRoofSub +
      this.straightLineNumberSide +
      this.straightLineNumberSideSub +
      this.straightLineNumberFloor;
    // 내재해 조리개 줄수
    const enhancedGrabberLineNumber: number =
      this.enhancedGrabberLineNumberRoof + this.enhancedGrabberLineNumberSide + this.enhancedGrabberLineNumberFloor;
    // 줄수 차이
    let diffLineNumberRoof: number = this.straightLineNumberRoof - this.enhancedGrabberLineNumberRoof;
    if (diffLineNumberRoof < 0) {
      diffLineNumberRoof = 0;
    }
    let diffLineNumberSide: number = this.straightLineNumberSide - this.enhancedGrabberLineNumberSide;
    if (diffLineNumberSide < 0) {
      diffLineNumberSide = 0;
    }
    let diffLineNumberFloor: number = this.straightLineNumberFloor - this.enhancedGrabberLineNumberFloor;
    if (diffLineNumberFloor < 0) {
      diffLineNumberFloor = 0;
    }

    /// //////// 수량 ///////////

    // 파이프
    this.straightRoof_pipe.designQuantity =
      (this.straightRoof_pipe.getConnectedPipeLength(
        this.straightLength,
        this.straightLineNumberRoof * this.basic.buildingNumber,
      ) /
        this.straightRoof_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    this.straightRoofSub_pipe.designQuantity =
      (this.straightRoofSub_pipe.getConnectedPipeLength(
        this.straightLength,
        this.straightLineNumberRoofSub * this.basic.buildingNumber,
      ) /
        this.straightRoofSub_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    this.straightSide_pipe.designQuantity =
      (this.straightSide_pipe.getConnectedPipeLength(
        this.straightLength,
        this.straightLineNumberSide * this.basic.buildingNumber,
      ) /
        this.straightSide_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    this.straightSideSub_pipe.designQuantity =
      (this.straightSideSub_pipe.getConnectedPipeLength(
        this.straightLength,
        this.straightLineNumberSideSub * this.basic.buildingNumber,
      ) /
        this.straightSideSub_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    this.straightFloor_pipe.designQuantity =
      (this.straightFloor_pipe.getConnectedPipeLength(
        this.straightLength,
        this.straightLineNumberFloor * this.basic.buildingNumber,
      ) /
        this.straightFloor_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    // 연결핀
    if (this.straightLength > this.straightRoof_pipe.specLength) {
      this.straightRoof_connectionPin.designQuantity =
        this.straightRoof_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.straightRoof_connectionPin.designQuantity = 0;
    }

    if (this.straightLength > this.straightRoofSub_pipe.specLength) {
      this.straightRoofSub_connectionPin.designQuantity =
        this.straightRoofSub_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.straightRoofSub_connectionPin.designQuantity = 0;
    }

    if (this.straightLength > this.straightSide_pipe.specLength) {
      this.straightSide_connectionPin.designQuantity =
        this.straightSide_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.straightSide_connectionPin.designQuantity = 0;
    }

    if (this.straightLength > this.straightSideSub_pipe.specLength) {
      this.straightSideSub_connectionPin.designQuantity =
        this.straightSideSub_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.straightSideSub_connectionPin.designQuantity = 0;
    }

    if (this.straightLength > this.straightFloor_pipe.specLength) {
      this.straightFloor_connectionPin.designQuantity =
        this.straightFloor_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.straightFloor_connectionPin.designQuantity = 0;
    }

    // 조리개
    if (this.enhancedGrabberSelected === true) {
      if (
        this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET ||
        this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET ||
        this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_U_CLAMP ||
        this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_U_ASIBA_CLAMP
      ) {
        this.straightRoofAndRafter_itemHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberRoof *
          CONST.NUM_EXTRA_RATE_ENHANCED_GRABBER;
        this.straightRoofAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity * diffLineNumberRoof * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightRoofAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberRoof * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightRoofSubAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberRoofSub *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightRoofSubAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberRoofSub * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightSideAndRafter_itemHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberSide *
          CONST.NUM_EXTRA_RATE_ENHANCED_GRABBER;
        this.straightSideAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity * diffLineNumberSide * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightSideAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberSide * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightSideSubAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberSideSub *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightSideSubAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberSideSub * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightFloorAndRafter_itemHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberFloor *
          CONST.NUM_EXTRA_RATE_ENHANCED_GRABBER;
        this.straightFloorAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity * diffLineNumberFloor * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightFloorAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberFloor * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
      } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET) {
        this.straightRoofAndRafter_itemHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberRoof *
          CONST.NUM_EXTRA_RATE_ENHANCED_GRABBER;
        this.straightRoofAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberRoof *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightRoofAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberRoof * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightRoofSubAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberRoofSub *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightRoofSubAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberRoofSub * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightSideAndRafter_itemHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberSide *
          CONST.NUM_EXTRA_RATE_ENHANCED_GRABBER;
        this.straightSideAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberSide *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightSideAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberSide * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightSideSubAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberSideSub *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightSideSubAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberSideSub * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightFloorAndRafter_itemHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberFloor *
          CONST.NUM_EXTRA_RATE_ENHANCED_GRABBER;
        this.straightFloorAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberFloor *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightFloorAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberFloor * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
      } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_SADDLE_ASIBA_CLAMP) {
        this.straightRoofAndRafter_itemHolder.designQuantity = 0;
        this.straightRoofAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity * diffLineNumberRoof * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightRoofAndRafter_saddleHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberRoof *
          CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightRoofSubAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberRoofSub *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightRoofSubAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberRoofSub * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightSideAndRafter_itemHolder.designQuantity = 0;
        this.straightSideAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity * diffLineNumberSide * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightSideAndRafter_saddleHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberSide *
          CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightSideSubAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.straightLineNumberSideSub *
          CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightSideSubAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberSideSub * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

        this.straightFloorAndRafter_itemHolder.designQuantity =
          trunkPosition.rafterPart.rafterQuantity *
          this.enhancedGrabberLineNumberFloor *
          CONST.NUM_EXTRA_RATE_ENHANCED_GRABBER;
        this.straightFloorAndRafter_steelStringGrabber.designQuantity =
          trunkPosition.rafterPart.rafterQuantity * diffLineNumberFloor * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        this.straightFloorAndRafter_saddleHolder.designQuantity =
          this.straightLineNumberFloor * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
      }
    } else {
      this.straightRoofAndRafter_steelStringGrabber.designQuantity =
        trunkPosition.rafterPart.rafterQuantity *
        this.straightLineNumberRoof *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
      this.straightRoofAndRafter_saddleHolder.designQuantity =
        this.straightLineNumberRoof * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

      this.straightRoofSubAndRafter_steelStringGrabber.designQuantity =
        trunkPosition.rafterPart.rafterQuantity *
        this.straightLineNumberRoofSub *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
      this.straightRoofSubAndRafter_saddleHolder.designQuantity =
        this.straightLineNumberRoofSub * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

      this.straightSideAndRafter_steelStringGrabber.designQuantity =
        trunkPosition.rafterPart.rafterQuantity *
        this.straightLineNumberSide *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
      this.straightSideAndRafter_saddleHolder.designQuantity =
        this.straightLineNumberSide * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

      this.straightSideSubAndRafter_steelStringGrabber.designQuantity =
        trunkPosition.rafterPart.rafterQuantity *
        this.straightLineNumberSideSub *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
      this.straightSideSubAndRafter_saddleHolder.designQuantity =
        this.straightLineNumberSideSub * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

      this.straightFloorAndRafter_steelStringGrabber.designQuantity =
        trunkPosition.rafterPart.rafterQuantity *
        this.straightLineNumberFloor *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
      this.straightFloorAndRafter_saddleHolder.designQuantity =
        this.straightLineNumberFloor * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
    }

    // 나사
    this.straight_screw.designQuantity =
      ((this.straightRoof_connectionPin.getSelectedQuantity() * 2 +
        this.straightRoofSub_connectionPin.getSelectedQuantity() * 2 +
        this.straightSide_connectionPin.getSelectedQuantity() * 2 +
        this.straightSideSub_connectionPin.getSelectedQuantity() * 2 +
        this.straightFloor_connectionPin.getSelectedQuantity() * 2 +
        this.straightRoofAndRafter_saddleHolder.getSelectedQuantity() * 3 +
        this.straightRoofSubAndRafter_saddleHolder.getSelectedQuantity() * 3 +
        this.straightSideAndRafter_saddleHolder.getSelectedQuantity() * 3 +
        this.straightSideSubAndRafter_saddleHolder.getSelectedQuantity() * 3 +
        this.straightFloorAndRafter_saddleHolder.getSelectedQuantity() * 3) /
        this.straight_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 가로대 길이 <- 길이(기본정보)
   */
  public algorithm_straightLength(): void {
    this.straightLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
  }

  /**
   * 알고리즘: 내재해 조리개 줄수 <- 내재해 조리개 형태
   */
  public algorithm_enhancedGrabberLineNumber(): void {
    if (
      this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET ||
      this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET ||
      this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET
    ) {
      this.enhancedGrabberLineNumberRoof = this.straightLineNumberRoof >= 1 ? 1 : this.straightLineNumberRoof;
      this.enhancedGrabberLineNumberSide = this.straightLineNumberSide >= 2 ? 2 : this.straightLineNumberSide;
      this.enhancedGrabberLineNumberFloor = this.straightLineNumberFloor >= 2 ? 2 : this.straightLineNumberFloor;
    } else {
      this.enhancedGrabberLineNumberRoof = 0;
      this.enhancedGrabberLineNumberSide = this.straightLineNumberSide >= 2 ? 2 : this.straightLineNumberSide;
      this.enhancedGrabberLineNumberFloor = this.straightLineNumberFloor >= 2 ? 2 : this.straightLineNumberFloor;
    }
  }

  /**
   * 알고리즘: 내재해 조리개 줄수(지붕) <- 가로대 줄수(지붕)
   */
  public algorithm_enhancedGrabberLineNumberRoofMain(): void {
    if (this.straightLineNumberRoof < this.enhancedGrabberLineNumberRoof) {
      this.enhancedGrabberLineNumberRoof = this.straightLineNumberRoof;
    }
  }

  /**
   * 알고리즘: 내재해 조리개 줄수(측면) <- 가로대 줄수(측면)
   */
  public algorithm_enhancedGrabberLineNumberSideMain(): void {
    if (this.straightLineNumberSide < this.enhancedGrabberLineNumberSide) {
      this.enhancedGrabberLineNumberSide = this.straightLineNumberSide;
    }
  }

  /**
   * 알고리즘: 내재해 조리개 줄수(바닥) <- 가로대 줄수(바닥)
   */
  public algorithm_enhancedGrabberLineNumberFloor(): void {
    if (this.straightLineNumberFloor < this.enhancedGrabberLineNumberFloor) {
      this.enhancedGrabberLineNumberFloor = this.straightLineNumberFloor;
    }
  }

  /**
   * 알고리즘: 가로대 줄수 <- 가로대 줄수 자동 계산 선택, 지붕 둘레(기본 정보), 측고(기본 정보)
   */
  public algorithm_straightAlgorithmSelected(): void {
    if (this.straightAlgorithmSelected) {
      const cacl: number = Math.floor(this.basicLevel.perimeterRoof) / 1 - 2;
      if (this.basicLevel.index === 0) {
        this.straightLineNumberRoof = Math.floor(this.basicLevel.perimeterRoof) % 2 === 0 ? cacl + 1 : cacl;
        if (this.basicLevel.heightShoulder >= 1.8) {
          this.straightLineNumberSide = 4;
        } else {
          this.straightLineNumberSide = 2;
        }
        this.straightLineNumberFloor = 2;
      } else {
        this.straightLineNumberRoof = 3;
        this.straightLineNumberSide = 2;
        this.straightLineNumberFloor = 0;
      }
    }
  }

  /**
   * 알고리즘: 가로대 줄수 자동 계산 선택 <- 가로대 줄수 자동 계산 선택(1중)
   */
  public algorithm_straightAlgorithmSelectedByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.trunkWork.levelAC.length; l++) {
        (<TrunkLevel>this.work.levelAC[l]).trunkPosition.straightPart.straightAlgorithmSelected =
          this.straightAlgorithmSelected;
      }
    }
  }

  //----------------------------------
  // 품목 상품
  //----------------------------------

  /**
   * 상품 알고리즘: [가로대 + 서까래] 고정구 <- 내재해 조리개 형태
   */
  public algorithmProduct_straightAndRafter_itemHolder(): void {
    if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET) {
      this.straightRoofAndRafter_itemHolder._productId = CONST.ITEM_ID_STEEL_SHEET_GRABBER;
      this.straightRoofAndRafter_itemHolder._label = CONST.ITEM_NAME_STEEL_SHEET_GRABBER;
      this.straightSideAndRafter_itemHolder._productId = CONST.ITEM_ID_STEEL_SHEET_GRABBER;
      this.straightSideAndRafter_itemHolder._label = CONST.ITEM_NAME_STEEL_SHEET_GRABBER;
      this.straightFloorAndRafter_itemHolder._productId = CONST.ITEM_ID_STEEL_SHEET_GRABBER;
      this.straightFloorAndRafter_itemHolder._label = CONST.ITEM_NAME_STEEL_SHEET_GRABBER;
    } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET) {
      this.straightRoofAndRafter_itemHolder._productId = CONST.ITEM_ID_CROSS_STEEL_SHEET_GRABBER;
      this.straightRoofAndRafter_itemHolder._label = CONST.ITEM_NAME_CROSS_STEEL_SHEET_GRABBER;
      this.straightSideAndRafter_itemHolder._productId = CONST.ITEM_ID_CROSS_STEEL_SHEET_GRABBER;
      this.straightSideAndRafter_itemHolder._label = CONST.ITEM_NAME_CROSS_STEEL_SHEET_GRABBER;
      this.straightFloorAndRafter_itemHolder._productId = CONST.ITEM_ID_CROSS_STEEL_SHEET_GRABBER;
      this.straightFloorAndRafter_itemHolder._label = CONST.ITEM_NAME_CROSS_STEEL_SHEET_GRABBER;
    } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET) {
      this.straightRoofAndRafter_itemHolder._productId = CONST.ITEM_ID_LINE_SHEET_GRABBER;
      this.straightRoofAndRafter_itemHolder._label = CONST.ITEM_NAME_LINE_SHEET_GRABBER;
      this.straightSideAndRafter_itemHolder._productId = CONST.ITEM_ID_LINE_SHEET_GRABBER;
      this.straightSideAndRafter_itemHolder._label = CONST.ITEM_NAME_LINE_SHEET_GRABBER;
      this.straightFloorAndRafter_itemHolder._productId = CONST.ITEM_ID_LINE_SHEET_GRABBER;
      this.straightFloorAndRafter_itemHolder._label = CONST.ITEM_NAME_LINE_SHEET_GRABBER;
    } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_U_CLAMP) {
      this.straightRoofAndRafter_itemHolder._productId = CONST.ITEM_ID_U_CLAMP;
      this.straightRoofAndRafter_itemHolder._label = CONST.ITEM_NAME_U_CLAMP;
      this.straightSideAndRafter_itemHolder._productId = CONST.ITEM_ID_U_CLAMP;
      this.straightSideAndRafter_itemHolder._label = CONST.ITEM_NAME_U_CLAMP;
      this.straightFloorAndRafter_itemHolder._productId = CONST.ITEM_ID_U_CLAMP;
      this.straightFloorAndRafter_itemHolder._label = CONST.ITEM_NAME_U_CLAMP;
    } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_U_ASIBA_CLAMP) {
      this.straightRoofAndRafter_itemHolder._productId = CONST.ITEM_ID_U_CLAMP;
      this.straightRoofAndRafter_itemHolder._label = CONST.ITEM_NAME_U_CLAMP;
      this.straightSideAndRafter_itemHolder._productId = CONST.ITEM_ID_U_CLAMP;
      this.straightSideAndRafter_itemHolder._label = CONST.ITEM_NAME_U_CLAMP;
      this.straightFloorAndRafter_itemHolder._productId = CONST.ITEM_ID_FIX_ASIBA_CLAMP;
      this.straightFloorAndRafter_itemHolder._label = CONST.ITEM_NAME_FIX_ASIBA_CLAMP;
    } else if (this.enhancedGrabberType === CONST.LB_TRUNK_ENHANCED_GRABBER_TYPE_SADDLE_ASIBA_CLAMP) {
      this.straightRoofAndRafter_itemHolder._productId = CONST.ITEM_ID_U_CLAMP;
      this.straightRoofAndRafter_itemHolder._label = CONST.ITEM_NAME_U_CLAMP;
      this.straightSideAndRafter_itemHolder._productId = CONST.ITEM_ID_U_CLAMP;
      this.straightSideAndRafter_itemHolder._label = CONST.ITEM_NAME_U_CLAMP;
      this.straightFloorAndRafter_itemHolder._productId = CONST.ITEM_ID_FIX_ASIBA_CLAMP;
      this.straightFloorAndRafter_itemHolder._label = CONST.ITEM_NAME_FIX_ASIBA_CLAMP;
    }
    this.straightRoofAndRafter_itemHolder.setDefaultVariable();
    this.straightSideAndRafter_itemHolder.setDefaultVariable();
    this.straightFloorAndRafter_itemHolder.setDefaultVariable();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [지붕 가로대] 파이프 <- 지붕 가로대 파이프 샘플
   */
  public algorithmSpec_straightRoof_pipe(): void {
    this.straightRoof_pipe.specs = this.straightRoofPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [지붕 가로대] 연결핀 <- 지붕 가로대 파이프 샘플
   */
  public algorithmSpec_straightRoof_connectionPin(): void {
    this.straightRoof_connectionPin.specPipeType = this.straightRoofPipeSample.specPipeType;
    this.straightRoof_connectionPin.specCrossSize1 = this.straightRoofPipeSample.specCrossSize;
    this.straightRoof_connectionPin.specCrossSize2 = this.straightRoofPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [지붕 가로대 + 서까래] 강선 조리개 <- 지붕 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightRoofAndRafter_steelStringGrabber(): void {
    this.straightRoofAndRafter_steelStringGrabber.specPipeType = this.straightRoofPipeSample.specPipeType;
    this.straightRoofAndRafter_steelStringGrabber.specCrossSize1 = this.straightRoofPipeSample.specCrossSize;
    this.straightRoofAndRafter_steelStringGrabber.specCrossSize2 = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [지붕 가로대 + 서까래] 고정구 <- 지붕 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightRoofAndRafter_itemHolder(): void {
    this.straightRoofAndRafter_itemHolder.specPipeType = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightRoofAndRafter_itemHolder.specCrossSize1 = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightRoofAndRafter_itemHolder.specCrossSize2 = this.straightRoofPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [지붕 가로대 + 서까래] 새들 고정구 <- 지붕 가로대 파이프 샘플
   */
  public algorithmSpec_straightRoofAndRafter_saddleHolder(): void {
    this.straightRoofAndRafter_saddleHolder.specPipeType = this.straightRoofPipeSample.specPipeType;
    this.straightRoofAndRafter_saddleHolder.specCrossSize1 = this.straightRoofPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [지붕보조 가로대] 파이프 <- 지붕보조 가로대 파이프 샘플
   */
  public algorithmSpec_straightRoofSub_pipe(): void {
    this.straightRoofSub_pipe.specs = this.straightRoofSubPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [지붕보조 가로대] 연결핀 <- 지붕보조 가로대 파이프 샘플
   */
  public algorithmSpec_straightRoofSub_connectionPin(): void {
    this.straightRoofSub_connectionPin.specPipeType = this.straightRoofSubPipeSample.specPipeType;
    this.straightRoofSub_connectionPin.specCrossSize1 = this.straightRoofSubPipeSample.specCrossSize;
    this.straightRoofSub_connectionPin.specCrossSize2 = this.straightRoofSubPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [지붕보조 가로대 + 서까래] 강선 조리개 <- 지붕보조 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightRoofSubAndRafter_steelStringGrabber(): void {
    this.straightRoofSubAndRafter_steelStringGrabber.specPipeType = this.straightRoofSubPipeSample.specPipeType;
    this.straightRoofSubAndRafter_steelStringGrabber.specCrossSize1 = this.straightRoofSubPipeSample.specCrossSize;
    this.straightRoofSubAndRafter_steelStringGrabber.specCrossSize2 = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [지붕보조 가로대 + 서까래] 새들 고정구 <- 지붕보조 가로대 파이프 샘플
   */
  public algorithmSpec_straightRoofSubAndRafter_saddleHolder(): void {
    this.straightRoofSubAndRafter_saddleHolder.specPipeType = this.straightRoofSubPipeSample.specPipeType;
    this.straightRoofSubAndRafter_saddleHolder.specCrossSize1 = this.straightRoofSubPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면 가로대] 파이프 <- 측면 가로대 파이프 샘플
   */
  public algorithmSpec_straightSide_pipe(): void {
    this.straightSide_pipe.specs = this.straightSidePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [측면 가로대] 연결핀 <- 측면 가로대 파이프 샘플
   */
  public algorithmSpec_straightSide_connectionPin(): void {
    this.straightSide_connectionPin.specPipeType = this.straightSidePipeSample.specPipeType;
    this.straightSide_connectionPin.specCrossSize1 = this.straightSidePipeSample.specCrossSize;
    this.straightSide_connectionPin.specCrossSize2 = this.straightSidePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면 가로대 + 서까래] 강선 조리개 <- 측면 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightSideAndRafter_steelStringGrabber(): void {
    this.straightSideAndRafter_steelStringGrabber.specPipeType = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightSideAndRafter_steelStringGrabber.specCrossSize1 = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightSideAndRafter_steelStringGrabber.specCrossSize2 = this.straightSidePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면 가로대 + 서까래] 고정구 <- 측면 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightSideAndRafter_itemHolder(): void {
    this.straightSideAndRafter_itemHolder.specPipeType = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightSideAndRafter_itemHolder.specCrossSize1 = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightSideAndRafter_itemHolder.specCrossSize2 = this.straightSidePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면 가로대 + 서까래] 새들 고정구 <- 측면 가로대 파이프 샘플
   */
  public algorithmSpec_straightSideAndRafter_saddleHolder(): void {
    this.straightSideAndRafter_saddleHolder.specPipeType = this.straightSidePipeSample.specPipeType;
    this.straightSideAndRafter_saddleHolder.specCrossSize1 = this.straightSidePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면보조 가로대] 파이프 <- 측면보조 가로대 파이프 샘플
   */
  public algorithmSpec_straightSideSub_pipe(): void {
    this.straightSideSub_pipe.specs = this.straightSideSubPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [측면보조 가로대] 연결핀 <- 측면보조 가로대 파이프 샘플
   */
  public algorithmSpec_straightSideSub_connectionPin(): void {
    this.straightSideSub_connectionPin.specPipeType = this.straightSideSubPipeSample.specPipeType;
    this.straightSideSub_connectionPin.specCrossSize1 = this.straightSideSubPipeSample.specCrossSize;
    this.straightSideSub_connectionPin.specCrossSize2 = this.straightSideSubPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면보조 가로대 + 서까래] 강선 조리개 <- 측면보조 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightSideSubAndRafter_steelStringGrabber(): void {
    this.straightSideSubAndRafter_steelStringGrabber.specPipeType = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightSideSubAndRafter_steelStringGrabber.specCrossSize1 = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightSideSubAndRafter_steelStringGrabber.specCrossSize2 = this.straightSideSubPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면보조 가로대 + 서까래] 새들 고정구 <- 측면보조 가로대 파이프 샘플
   */
  public algorithmSpec_straightSideSubAndRafter_saddleHolder(): void {
    this.straightSideSubAndRafter_saddleHolder.specPipeType = this.straightSideSubPipeSample.specPipeType;
    this.straightSideSubAndRafter_saddleHolder.specCrossSize1 = this.straightSideSubPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [바닥 가로대] 파이프 <- 바닥 가로대 파이프 샘플
   */
  public algorithmSpec_straightFloor_pipe(): void {
    this.straightFloor_pipe.specs = this.straightFloorPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [바닥 가로대] 연결핀 <- 바닥 가로대 파이프 샘플
   */
  public algorithmSpec_straightFloor_connectionPin(): void {
    this.straightFloor_connectionPin.specPipeType = this.straightFloorPipeSample.specPipeType;
    this.straightFloor_connectionPin.specCrossSize1 = this.straightFloorPipeSample.specCrossSize;
    this.straightFloor_connectionPin.specCrossSize2 = this.straightFloorPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [바닥 가로대 + 서까래] 강선 조리개 <- 바닥 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightFloorAndRafter_steelStringGrabber(): void {
    this.straightFloorAndRafter_steelStringGrabber.specPipeType = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightFloorAndRafter_steelStringGrabber.specCrossSize1 = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightFloorAndRafter_steelStringGrabber.specCrossSize2 = this.straightFloorPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [바닥 가로대 + 서까래] 고정구 <- 바닥 가로대 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_straightFloorAndRafter_itemHolder(): void {
    this.straightFloorAndRafter_itemHolder.specPipeType = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specPipeType;
    this.straightFloorAndRafter_itemHolder.specCrossSize1 = (<TrunkPosition>(
      this.position
    )).rafterPart.rafterPipeSample.specCrossSize;
    this.straightFloorAndRafter_itemHolder.specCrossSize2 = this.straightFloorPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [바닥 가로대 + 서까래] 새들 고정구 <- 바닥 가로대 파이프 샘플
   */
  public algorithmSpec_straightFloorAndRafter_saddleHolder(): void {
    this.straightFloorAndRafter_saddleHolder.specPipeType = this.straightFloorPipeSample.specPipeType;
    this.straightFloorAndRafter_saddleHolder.specCrossSize1 = this.straightFloorPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [가로대] 나사 <- 공통 샘플
   */
  public algorithmSpec_straight_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
