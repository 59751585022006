import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { MaterialPart_Custom } from "vhows-design/src/object/design/other/material/MaterialPart_Custom";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { MainpipePart_PeMain2 } from "vhows-design/src/object/design/watering/mainpipe/MainpipePart_PeMain2";
import { MainpipePart_UpperFrame } from "vhows-design/src/object/design/watering/mainpipe/MainpipePart_UpperFrame";
import { MainpipePart_PeMain1 } from "vhows-design/src/object/design/watering/mainpipe/MainpipePart_PeMain1";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [MainpipePart_PeMain1, MainpipePart_PeMain2, MaterialPart_Custom, MainpipePart_UpperFrame],
})
export class MainpipePosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public peMain1Part: MainpipePart_PeMain1;
  public peMain2Part: MainpipePart_PeMain2;
  public customPart: MaterialPart_Custom;
  public upperFramePart: MainpipePart_UpperFrame;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wateringBuildingNumber: number; // 관수 동수
  @jsonMember(Number)
  public _wateringWellQuantity: number; // 관정 수량
  @jsonMember(Boolean)
  public _upperFrameSelected: boolean; // 상단관수 골조 선택

  /**
   * 관수 동수
   */
  public get wateringBuildingNumber(): number {
    return this._wateringBuildingNumber;
  }

  //
  public set wateringBuildingNumber(value: number) {
    this._wateringBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_wateringWellQuantity();
    this.peMain2Part.algorithm_main2Length();
    this.upperFramePart.algorithm_wateringBuildingNumber();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 관수 지관부
    this.struct.wateringWork.level1.wholePosition.miniklerScreenPart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.miniklerFountainPart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.miniklerFogPart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.hoseScreenPart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.hoseUpperPart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.hoseSidePart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.hoseLowerPart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.hoseMulchingPart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.hoseConnectPart.algorithm_wateringBuildingNumber();
    this.struct.wateringWork.level1.wholePosition.dripWateringPart.algorithm_wateringBuildingNumber();
  }

  /**
   * 관정 수량
   */
  public get wateringWellQuantity(): number {
    return this._wateringWellQuantity;
  }

  //
  public set wateringWellQuantity(value: number) {
    this._wateringWellQuantity = CommonUtil.fixFloat(value);

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  /**
   * 상단관수 골조 선택
   */
  public get upperFrameSelected(): boolean {
    return this._upperFrameSelected;
  }

  //
  public set upperFrameSelected(value: boolean) {
    this._upperFrameSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByUpperFrameSelected();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 관수 지관부
    this.struct.wateringWork.level1.wholePosition.algorithm_selected();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_wateringWellQuantity();
    this.algorithm_upperFrameSelected();
    this.peMain1Part.main1PePipeSample.algorithmSpec();
    this.peMain1Part.algorithm_waterTankSelected();
    this.peMain1Part.algorithm_waterPumpSelected();
    this.peMain1Part.algorithm_waterFilterSelected();
    this.peMain1Part.algorithm_main1Length();
    this.peMain1Part.algorithmSpec_waterFilterOut_bushing();
    this.peMain1Part.algorithmSpec_waterFilterOut_valveSocket();
    this.peMain2Part.algorithm_main2Length();
    this.peMain2Part.algorithmSpec_main2_exactT();
    this.peMain2Part.algorithmSpec_main2_unionValve();
    this.peMain2Part.algorithmSpec_main2_elbow();
    this.peMain2Part.algorithmSpec_main2_pePipeEnd();
    this.peMain2Part.algorithmSpec_main2_valveSocket();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 관수 지관부
    this.struct.wateringWork.level1.wholePosition.algorithm_selected();
    this.struct.wateringWork.level1.wholePosition.miniklerScreenPart.subPePipeSample.algorithmSpec();
    this.struct.wateringWork.level1.wholePosition.miniklerScreenPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.miniklerScreenPart.algorithmPart();
    this.struct.wateringWork.level1.wholePosition.miniklerFountainPart.subPePipeSample.algorithmSpec();
    this.struct.wateringWork.level1.wholePosition.miniklerFountainPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.miniklerFountainPart.algorithmPart();
    this.struct.wateringWork.level1.wholePosition.miniklerFogPart.subPePipeSample.algorithmSpec();
    this.struct.wateringWork.level1.wholePosition.miniklerFogPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.miniklerFogPart.algorithmPart();
    this.struct.wateringWork.level1.wholePosition.hoseConnectPart.subPePipeSample.algorithmSpec();
    this.struct.wateringWork.level1.wholePosition.hoseConnectPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.hoseConnectPart.algorithmSpec_subAndMain_differentT();
    this.struct.wateringWork.level1.wholePosition.hoseConnectPart.algorithmPart();
    this.struct.wateringWork.level1.wholePosition.dripConnectPart.algorithm_subPipeLength();
    this.struct.wateringWork.level1.wholePosition.dripConnectPart.algorithmSpec_normalSaddle_pePipeSaddle();
    this.struct.wateringWork.level1.wholePosition.dripConnectPart.algorithmSpec_startSaddle1hole_dripStartSaddle();
    this.struct.wateringWork.level1.wholePosition.dripConnectPart.algorithmSpec_startSaddle2hole_dripStartSaddle();
    this.struct.wateringWork.level1.wholePosition.dripConnectPart.algorithmSpec_startSaddle3hole_dripStartSaddle();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.peMain1Part = new MainpipePart_PeMain1();
    this.peMain2Part = new MainpipePart_PeMain2();
    this.customPart = new MaterialPart_Custom();
    this.upperFramePart = new MainpipePart_UpperFrame();

    this.partAC = [this.peMain1Part, this.peMain2Part, this.customPart, this.upperFramePart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.peMain1Part = <MainpipePart_PeMain1>this.partAC[0];
    this.peMain2Part = <MainpipePart_PeMain2>this.partAC[1];
    this.customPart = <MaterialPart_Custom>this.partAC[2];
    this.upperFramePart = <MainpipePart_UpperFrame>this.partAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param wateringBuildingNumber: number 관수 동수
   * @param wateringWellQuantity: number 관정 수량
   * @param upperFrameSelected: boolean 상단관수 골조 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    wateringBuildingNumber: number = 0,
    wateringWellQuantity: number = 0,
    upperFrameSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._wateringBuildingNumber = wateringBuildingNumber;
    this._wateringWellQuantity = wateringWellQuantity;
    this._upperFrameSelected = upperFrameSelected;

    if (this.level.index >= 0) {
      // PE 주관1
      this.peMain1Part.setDefaultData(
        CONST.INDEX_CM_WATERING_MAINPIPE_PE_MAIN1,
        true,
        false,
        true,
        CONST.LB_WATERING_MAINPIPE_PE_MAIN1,
        "",
        true,
        CONST.LB_WATERING_MAINPIPE_WATER_TANK_POSITION_SIDE,
        1,
        true,
        true,
        2,
      );

      // PE 주관2
      this.peMain2Part.setDefaultData(
        CONST.INDEX_CM_WATERING_MAINPIPE_PE_MAIN2,
        true,
        false,
        true,
        CONST.LB_WATERING_MAINPIPE_PE_MAIN2,
        "",
        1,
        CONST.LB_WATERING_MAINPIPE_MAIN2_POSITION_LOWER,
      );

      // 임의 품목
      this.customPart.setDefaultData(
        CONST.INDEX_CM_WATERING_MAINPIPE_CUSTOM,
        false,
        false,
        false,
        CONST.LB_WATERING_MAINPIPE_CUSTOM,
        "",
      );

      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        // 상단관수 골조
        this.upperFramePart.setDefaultData(
          CONST.INDEX_CM_WATERING_MAINPIPE_UPPER_FRAME,
          false,
          false,
          false,
          CONST.LB_WATERING_MAINPIPE_UPPER_FRAME,
          "",
          1,
          2,
        );
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        // 상단관수 골조
        this.upperFramePart.setDefaultData(
          CONST.INDEX_CM_WATERING_MAINPIPE_UPPER_FRAME,
          true,
          false,
          true,
          CONST.LB_WATERING_MAINPIPE_UPPER_FRAME,
          "",
          3,
          2,
        );
      }
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_wateringBuildingNumber();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (
      this.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER ||
      this.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH
    ) {
      this.peMain1Part.selected = true;
      this.peMain1Part.visible = true;
      this.peMain2Part.selected = true;
      this.peMain2Part.visible = true;
      this.customPart.selected = false;
      this.customPart.visible = false;
    } else if (this.type === CONST.LB_WATERING_MAINPIPE_TYPE_CUSTOM) {
      this.peMain1Part.selected = false;
      this.peMain1Part.visible = false;
      this.peMain2Part.selected = false;
      this.peMain2Part.visible = false;
      this.customPart.selected = true;
      this.customPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 상단관수 골조 선택
   */
  public algorithm_partActivationByUpperFrameSelected(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      if (this.upperFrameSelected === true) {
        this.upperFramePart.selected = true;
        this.upperFramePart.visible = true;
      } else {
        this.upperFramePart.selected = false;
        this.upperFramePart.visible = false;
      }
    }
  }

  /**
   * 알고리즘: 관수 동수 <- 하우스 동수
   */
  public algorithm_wateringBuildingNumber(): void {
    this.wateringBuildingNumber = this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 관정 수량 <- 형태, 관수 동수
   */
  public algorithm_wateringWellQuantity(): void {
    if (this.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.wateringWellQuantity = 1;
    } else if (this.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.wateringWellQuantity = this.wateringBuildingNumber;
    }
  }

  /**
   * 알고리즘: 상단관수 골조 선택 <- 형태, 상단 형태(세부관)
   */
  public algorithm_upperFrameSelected(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
      if (
        this.type !== CONST.LB_WATERING_MAINPIPE_TYPE_CUSTOM &&
        wateringPosition.upperType === CONST.LB_WATERING_UPPER_TYPE_FOUNTAIN_MINIKLER
      ) {
        this.upperFrameSelected = true;
      } else {
        this.upperFrameSelected = false;
      }
    } else {
      this.upperFrameSelected = false;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
