import { jsonMember, jsonObject } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemBearing } from "vhows-design/src/object/design/item/list/ItemBearing";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { CurSwitchSampleIL_DriveAxisPipe } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_DriveAxisPipe";
import { CurSwitchSampleIL_SupportAxisPipe } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_SupportAxisPipe";
import { CurSwitchSampleIL_SupportPipe } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_SupportPipe";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurSwitchSampleIL_DriveAxisPipe, CurSwitchSampleIL_SupportAxisPipe, CurSwitchSampleIL_SupportPipe],
})
export class CurSwitchPartIL_Frame extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public driveAxisPipeSample: CurSwitchSampleIL_DriveAxisPipe; // 커튼 구동 축 파이프 샘플
  public supportAxisPipeSample: CurSwitchSampleIL_SupportAxisPipe; // 커튼 지지 축 파이프 샘플
  public supportPipeSample: CurSwitchSampleIL_SupportPipe; // 커튼 지지 파이프 샘플

  // 아이템
  public frameDriveAxis_pipe: ItemPipe;
  public frameDriveAxis_connectionPin: ItemConnector;
  public frameDriveAxis_bearingSet: ItemBearing;
  public frameSupportAxis_pipe: ItemPipe;
  public frameSupportAxis_connectionPin: ItemConnector;
  public frameSupportAxis_uClamp: ItemHolder;
  public frameSupport_pipe: ItemPipe;
  public frameSupport_connectionPin: ItemConnector;
  public frameSupport_saddleHolder: ItemHolder;
  public framePocket_pipe: ItemPipe;
  public framePocket_connectionPin: ItemConnector;
  public framePocket_saddleHolder: ItemHolder;
  public frame_screw: ItemScrew;
  public frame_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _axisLength: number; // 개폐 축 길이
  @jsonMember(Number)
  public _driveAxisLineNumber: number; // 구동 축 줄수
  @jsonMember(Number)
  public _supportAxisLineNumber: number; // 지지 축 줄수
  @jsonMember(Boolean)
  public _pocketFixingPipeSelected: boolean = false; // 주머니 지지 파이프 선택

  /**
   * 개폐 축 길이
   */
  public get axisLength(): number {
    return this._axisLength;
  }

  //
  public set axisLength(value: number) {
    this._axisLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 구동 축 줄수
   */
  public get driveAxisLineNumber(): number {
    return this._driveAxisLineNumber;
  }

  //
  public set driveAxisLineNumber(value: number) {
    this._driveAxisLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 지지 축 줄수
   */
  public get supportAxisLineNumber(): number {
    return this._supportAxisLineNumber;
  }

  //
  public set supportAxisLineNumber(value: number) {
    this._supportAxisLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    if ((<CurSwitchPositionIL>this.position).label === CONST.LB_POSITION_CEILING) {
      (<CurSwitchPositionIL>this.position).fixingClipCeilingPart.algorithmPart();
    }
  }

  /**
   * 주머니 지지 파이프 선택
   */
  public get pocketFixingPipeSelected(): boolean {
    return this._pocketFixingPipeSelected;
  }

  //
  public set pocketFixingPipeSelected(value: boolean) {
    this._pocketFixingPipeSelected = value;

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    if ((<CurSwitchPositionIL>this.position).label === CONST.LB_POSITION_CEILING) {
      (<CurSwitchPositionIL>this.position).fixingClipCeilingPart.algorithmPart();
    }

    /// //////// 외부 ///////////

    // 커튼 피복
    for (const curCoverPosition of this.struct.curCoverWorkIL.level1.positionAC as CurCoverPositionIL[]) {
      curCoverPosition.algorithmBasic();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.driveAxisPipeSample = new CurSwitchSampleIL_DriveAxisPipe();
    this.supportAxisPipeSample = new CurSwitchSampleIL_SupportAxisPipe();
    this.supportPipeSample = new CurSwitchSampleIL_SupportPipe();

    this.sampleAC = [this.driveAxisPipeSample, this.supportAxisPipeSample, this.supportPipeSample];

    // 아이템
    this.frameDriveAxis_pipe = new ItemPipe();
    this.frameDriveAxis_connectionPin = new ItemConnector();
    this.frameDriveAxis_bearingSet = new ItemBearing();
    this.frameSupportAxis_pipe = new ItemPipe();
    this.frameSupportAxis_connectionPin = new ItemConnector();
    this.frameSupportAxis_uClamp = new ItemHolder();
    this.frameSupport_pipe = new ItemPipe();
    this.frameSupport_connectionPin = new ItemConnector();
    this.frameSupport_saddleHolder = new ItemHolder();
    this.framePocket_pipe = new ItemPipe();
    this.framePocket_connectionPin = new ItemConnector();
    this.framePocket_saddleHolder = new ItemHolder();
    this.frame_screw = new ItemScrew();
    this.frame_welding = new ItemOtherExpense();

    this.itemAC = [
      this.frameDriveAxis_pipe,
      this.frameDriveAxis_connectionPin,
      this.frameDriveAxis_bearingSet,
      this.frameSupportAxis_pipe,
      this.frameSupportAxis_connectionPin,
      this.frameSupportAxis_uClamp,
      this.frameSupport_pipe,
      this.frameSupport_connectionPin,
      this.frameSupport_saddleHolder,
      this.framePocket_pipe,
      this.framePocket_connectionPin,
      this.framePocket_saddleHolder,
      this.frame_screw,
      this.frame_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.driveAxisPipeSample = <CurSwitchSampleIL_DriveAxisPipe>this.sampleAC[0];
    this.supportAxisPipeSample = <CurSwitchSampleIL_SupportAxisPipe>this.sampleAC[1];
    this.supportPipeSample = <CurSwitchSampleIL_SupportPipe>this.sampleAC[2];

    // 아이템
    this.frameDriveAxis_pipe = <ItemPipe>this.itemAC[0];
    this.frameDriveAxis_connectionPin = <ItemConnector>this.itemAC[1];
    this.frameDriveAxis_bearingSet = <ItemBearing>this.itemAC[2];
    this.frameSupportAxis_pipe = <ItemPipe>this.itemAC[3];
    this.frameSupportAxis_connectionPin = <ItemConnector>this.itemAC[4];
    this.frameSupportAxis_uClamp = <ItemHolder>this.itemAC[5];
    this.frameSupport_pipe = <ItemPipe>this.itemAC[6];
    this.frameSupport_connectionPin = <ItemConnector>this.itemAC[7];
    this.frameSupport_saddleHolder = <ItemHolder>this.itemAC[8];
    this.framePocket_pipe = <ItemPipe>this.itemAC[9];
    this.framePocket_connectionPin = <ItemConnector>this.itemAC[10];
    this.framePocket_saddleHolder = <ItemHolder>this.itemAC[11];
    this.frame_screw = <ItemScrew>this.itemAC[12];
    this.frame_welding = <ItemOtherExpense>this.itemAC[13];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param driveAxisLineNumber: number 구동 축 줄수
   * @param supportAxisLineNumber: number 지지 축 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    driveAxisLineNumber: number = 0,
    supportAxisLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._driveAxisLineNumber = driveAxisLineNumber;
    this._supportAxisLineNumber = supportAxisLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.driveAxisPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 구동 축",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.supportAxisPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 지지 축",
        "원형, 일반, 33.5mm, 2.1T, 6m, -",
      );
      this.supportPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.frameDriveAxis_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 구동 축",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.frameDriveAxis_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 구동 축",
        "원형, 일반, 48mm, 48mm",
      );
      this.frameDriveAxis_bearingSet.setDefaultData(
        2,
        CONST.ITEM_ID_BEARING_SET,
        CONST.ITEM_NAME_BEARING_SET,
        "커튼 구동 축",
        "원형, 일반, 48mm, 48mm",
      );
      this.frameSupportAxis_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 지지 축",
        "원형, 일반, 33.5mm, 2.1T, 6m, -",
      );
      this.frameSupportAxis_connectionPin.setDefaultData(
        4,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 지지 축",
        "원형, 일반, 32mm, 32mm",
      );
      this.frameSupportAxis_uClamp.setDefaultData(
        5,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "커튼 지지 축",
        "원형, 일반, 48mm, 32mm",
      );
      this.frameSupport_pipe.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.frameSupport_connectionPin.setDefaultData(
        7,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 지지",
        "원형, 일반, 25mm, 25mm",
      );
      this.frameSupport_saddleHolder.setDefaultData(
        8,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "커튼 지지",
        "원형, 일반, 25mm, -",
      );
      this.framePocket_pipe.setDefaultData(
        9,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 주머니 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.framePocket_connectionPin.setDefaultData(
        10,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "커튼 주머니 지지",
        "원형, 일반, 25mm, 25mm",
      );
      this.framePocket_saddleHolder.setDefaultData(
        11,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "커튼 주머니 지지",
        "원형, 일반, 25mm, -",
      );
      this.frame_screw.setDefaultData(
        12,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "커튼 수평 축",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.frame_welding.setDefaultData(13, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "커튼 수평 축", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    if (CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion)) {
      // 아이템
      if (this.itemAC.length === 13) {
        // [커튼 수평 축] 용접 추가
        const frame_welding: ItemOtherExpense = new ItemOtherExpense();
        frame_welding.setAssociation(design, struct, work, level, position, this);
        frame_welding.setDefaultData(13, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "커튼 수평 축", "일반");
        frame_welding.selected = true;
        frame_welding.visible = true;
        frame_welding.designQuantity = 0;
        this.itemAC.push(frame_welding);

        // 인덱스 재설정
        CommonUtil.reindexAC(this.itemAC);
      }
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_axisLength();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_frameDriveAxis_bearingSet();
    this.algorithmSpec_frameSupportAxis_uClamp();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;
    // 외부
    const curCoverPosition: CurCoverPositionIL = (<CurCoverLevelIL>this.struct.curCoverWorkIL.level1).curCoverPosition;

    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 선택, 가시성 ///////////

    this.frameDriveAxis_pipe.checkSelected(true);
    this.frameDriveAxis_connectionPin.checkSelected(true);
    this.frameDriveAxis_bearingSet.selected = true;
    this.frameDriveAxis_bearingSet.visible = true;
    this.frameSupportAxis_pipe.checkSelected(true);
    this.frameSupportAxis_connectionPin.checkSelected(true);
    this.frameSupportAxis_uClamp.selected = true;
    this.frameSupportAxis_uClamp.visible = true;
    this.frameSupport_pipe.checkSelected(true);
    this.frameSupport_connectionPin.checkSelected(true);
    this.frameSupport_saddleHolder.selected = true;
    this.frameSupport_saddleHolder.visible = true;
    if (this.pocketFixingPipeSelected) {
      this.framePocket_pipe.checkSelected(true);
      this.framePocket_connectionPin.checkSelected(true);
      this.framePocket_saddleHolder.selected = true;
      this.framePocket_saddleHolder.visible = true;
    } else {
      this.framePocket_pipe.checkSelected(false);
      this.framePocket_connectionPin.checkSelected(false);
      this.framePocket_saddleHolder.selected = false;
      this.framePocket_saddleHolder.visible = false;
    }
    this.frame_screw.selected = true;
    this.frame_screw.visible = true;
    this.frame_welding.selected = true;
    this.frame_welding.visible = true;

    /// //////// 수량 ///////////

    this.frameDriveAxis_pipe.designQuantity =
      (this.frameDriveAxis_pipe.getConnectedPipeLength(this.axisLength, this.driveAxisLineNumber) /
        this.frameDriveAxis_pipe.specLength) *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_CURTAIN_DRIVE_AXIS_PIPE;

    if (this.axisLength > this.frameDriveAxis_pipe.specLength) {
      this.frameDriveAxis_connectionPin.designQuantity =
        this.frameDriveAxis_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.frameDriveAxis_connectionPin.designQuantity = 0;
    }

    this.frameDriveAxis_bearingSet.designQuantity =
      Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
      this.driveAxisLineNumber *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_BEARING_SET;

    this.frameSupportAxis_pipe.designQuantity =
      (this.frameSupportAxis_pipe.getConnectedPipeLength(this.axisLength, this.supportAxisLineNumber) /
        this.frameSupportAxis_pipe.specLength) *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_CURTAIN_SUPPORT_AXIS_PIPE;

    if (this.axisLength > this.frameSupportAxis_pipe.specLength) {
      this.frameSupportAxis_connectionPin.designQuantity =
        this.frameSupportAxis_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.frameSupportAxis_connectionPin.designQuantity = 0;
    }

    this.frameSupportAxis_uClamp.designQuantity =
      Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
      this.supportAxisLineNumber *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_U_CLAMP;

    this.frameSupport_pipe.designQuantity =
      (this.frameSupport_pipe.getConnectedPipeLength(
        this.axisLength,
        this.basic.buildingNumber + 1 - this.supportAxisLineNumber,
      ) /
        this.frameSupport_pipe.specLength) *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_CURTAIN_SUPPORT_PIPE;

    if (this.axisLength > this.frameSupport_pipe.specLength) {
      this.frameSupport_connectionPin.designQuantity =
        this.frameSupport_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.frameSupport_connectionPin.designQuantity = 0;
    }

    this.frameSupport_saddleHolder.designQuantity =
      Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
      (this.basic.buildingNumber + 1 - this.supportAxisLineNumber) *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.framePocket_pipe.designQuantity =
      (this.framePocket_pipe.getConnectedPipeLength(this.basicLevel.width * this.basic.buildingNumber, 2) /
        this.framePocket_pipe.specLength) *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_CURTAIN_SUPPORT_PIPE;

    if (this.axisLength > this.framePocket_pipe.specLength) {
      this.framePocket_connectionPin.designQuantity =
        this.framePocket_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.framePocket_connectionPin.designQuantity = 0;
    }

    this.framePocket_saddleHolder.designQuantity =
      (this.basic.buildingNumber + 1) * 2 * curCoverPosition.ceilingLevelValue * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.frame_screw.designQuantity =
      ((this.frameDriveAxis_connectionPin.getSelectedQuantity() * 4 +
        this.frameSupportAxis_connectionPin.getSelectedQuantity() * 4 +
        this.frameSupport_connectionPin.getSelectedQuantity() * 4 +
        this.framePocket_connectionPin.getSelectedQuantity() * 4 +
        this.frameSupport_saddleHolder.getSelectedQuantity() * 3 +
        this.framePocket_saddleHolder.getSelectedQuantity() * 3) /
        this.frame_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    if (this.frameDriveAxis_bearingSet.specCrossSize1 === CONST.ITEM_SPEC_VALUE_NONE) {
      this.frame_welding.designQuantity = this.frameDriveAxis_bearingSet.getSelectedQuantity();
    } else {
      this.frame_welding.designQuantity = 0;
    }
  }

  /**
   * 알고리즘: 개폐 축 길이 <- 길이(기본정보)
   */
  public algorithm_axisLength(): void {
    this.axisLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;

    if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      this.axisLength = this.basicLevel.width;
    }
  }

  /**
   * 알고리즘: 구동 축 줄수 <- 개폐 분할(위치)
   */
  public algorithm_driveAxisLineNumber(): void {
    this.driveAxisLineNumber = (<CurSwitchPositionIL>this.position).switchDivisionValue;
  }

  /**
   * 알고리즘: 지지 축 줄수 <- 개폐 분할(위치)
   */
  public algorithm_supportAxisLineNumber(): void {
    this.supportAxisLineNumber = (<CurSwitchPositionIL>this.position).switchDivisionValue + 1;
  }

  /**
   * 알고리즘: 주머니 지지 파이프 선택 <- 커튼 범위(커튼 피복)
   */
  public algorithm_pocketFixingPipeSelected(): void {
    const curCoverPosition: CurCoverPositionIL = (<CurCoverLevelIL>this.struct.curCoverWorkIL.level1).curCoverPosition;

    if (
      curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_SEP_ENDPIECE ||
      curCoverPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE
    ) {
      // this.pocketFixingPipeSelected = true; // 설치해도 되고, 안해도 됨 (안하는 경우 커튼 한끈 설정!)
    } else {
      this.pocketFixingPipeSelected = false;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [커튼 구동 축] 파이프 <- 커튼 구동 축 파이프 샘플
   */
  public algorithmSpec_frameDriveAxis_pipe(): void {
    this.frameDriveAxis_pipe.specs = this.driveAxisPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [커튼 구동 축] 연결핀 <- 커튼 구동 축 파이프 샘플
   */
  public algorithmSpec_frameDriveAxis_connectionPin(): void {
    this.frameDriveAxis_connectionPin.specPipeType = this.driveAxisPipeSample.specPipeType;
    this.frameDriveAxis_connectionPin.specCrossSize1 = this.driveAxisPipeSample.specCrossSize;
    this.frameDriveAxis_connectionPin.specCrossSize2 = this.driveAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 구동 축] 축수 베어링 세트 <- 커튼 구동 축 파이프 샘플, 형태(기둥 골조), 원기둥 파이프 샘플, 각기둥 파이프 샘플
   */
  public algorithmSpec_frameDriveAxis_bearingSet(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.struct.columnWorkIL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.frameDriveAxis_bearingSet.specPipeType =
          this.struct.columnWorkIL.level1.columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
        this.frameDriveAxis_bearingSet.specCrossSize1 =
          this.struct.columnWorkIL.level1.columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      } else if (this.struct.columnWorkIL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.frameDriveAxis_bearingSet.specPipeType =
          this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specPipeType;
        // 하드코딩
        if (
          this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize ===
          CONST.ITEM_SPEC_VALUE_60_60_MM
        ) {
          this.frameDriveAxis_bearingSet.specCrossSize1 =
            this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
        } else {
          this.frameDriveAxis_bearingSet.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
        }
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.struct.columnWorkVL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.frameDriveAxis_bearingSet.specPipeType =
          this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specPipeType;
        // 하드코딩
        if (
          this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize ===
          CONST.ITEM_SPEC_VALUE_60_60_MM
        ) {
          this.frameDriveAxis_bearingSet.specCrossSize1 =
            this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
        } else {
          this.frameDriveAxis_bearingSet.specCrossSize1 = CONST.ITEM_SPEC_VALUE_NONE;
        }
      }
    }
    this.frameDriveAxis_bearingSet.specCrossSize2 = this.driveAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 지지 축] 파이프 <- 커튼 지지 축 파이프 샘플
   */
  public algorithmSpec_frameSupportAxis_pipe(): void {
    this.frameSupportAxis_pipe.specs = this.supportAxisPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [커튼 지지 축] 연결핀 <- 커튼 지지 축 파이프 샘플
   */
  public algorithmSpec_frameSupportAxis_connectionPin(): void {
    this.frameSupportAxis_connectionPin.specPipeType = this.supportAxisPipeSample.specPipeType;
    this.frameSupportAxis_connectionPin.specCrossSize1 = this.supportAxisPipeSample.specCrossSize;
    this.frameSupportAxis_connectionPin.specCrossSize2 = this.supportAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 지지 축] U 클램프 <- 커튼 지지 축 파이프 샘플, 형태(기둥 골조), 원형 기둥 파이프 샘플, 각형 기둥 파이프 샘플
   */
  public algorithmSpec_frameSupportAxis_uClamp(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.struct.columnWorkIL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.frameSupportAxis_uClamp.specPipeType =
          this.struct.columnWorkIL.level1.columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
        this.frameSupportAxis_uClamp.specCrossSize1 =
          this.struct.columnWorkIL.level1.columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      } else if (this.struct.columnWorkIL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.frameSupportAxis_uClamp.specPipeType =
          this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specPipeType;
        this.frameSupportAxis_uClamp.specCrossSize1 =
          this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.struct.columnWorkVL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.frameSupportAxis_uClamp.specPipeType =
          this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specPipeType;
        this.frameSupportAxis_uClamp.specCrossSize1 =
          this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
      }
    }
    this.frameSupportAxis_uClamp.specCrossSize2 = this.supportAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 지지] 파이프 <- 커튼 지지 파이프 샘플
   */
  public algorithmSpec_frameSupport_pipe(): void {
    this.frameSupport_pipe.specs = this.supportPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [커튼 지지] 연결핀 <- 커튼 지지 파이프 샘플
   */
  public algorithmSpec_frameSupport_connectionPin(): void {
    this.frameSupport_connectionPin.specPipeType = this.supportPipeSample.specPipeType;
    this.frameSupport_connectionPin.specCrossSize1 = this.supportPipeSample.specCrossSize;
    this.frameSupport_connectionPin.specCrossSize2 = this.supportPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 지지] 새들 고정구 <- 커튼 지지 파이프 샘플
   */
  public algorithmSpec_frameSupport_saddleHolder(): void {
    this.frameSupport_saddleHolder.specPipeType = this.supportPipeSample.specPipeType;
    this.frameSupport_saddleHolder.specCrossSize1 = this.supportPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 주머니 지지] 파이프 <- 커튼 지지 파이프 샘플
   */
  public algorithmSpec_framePocket_pipe(): void {
    this.framePocket_pipe.specs = this.supportPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [커튼 주머니 지지] 연결핀 <- 커튼 지지 파이프 샘플
   */
  public algorithmSpec_framePocket_connectionPin(): void {
    this.framePocket_connectionPin.specPipeType = this.supportPipeSample.specPipeType;
    this.framePocket_connectionPin.specCrossSize1 = this.supportPipeSample.specCrossSize;
    this.framePocket_connectionPin.specCrossSize2 = this.supportPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 주머니 지지] 새들 고정구 <- 커튼 지지 파이프 샘플
   */
  public algorithmSpec_framePocket_saddleHolder(): void {
    this.framePocket_saddleHolder.specPipeType = this.supportPipeSample.specPipeType;
    this.framePocket_saddleHolder.specCrossSize1 = this.supportPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 수평 축] 나사 <- 공통 샘플
   */
  public algorithmSpec_axis_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
