import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCashmilon } from "vhows-design/src/object/design/item/list/ItemCashmilon";
import { ItemLagging } from "vhows-design/src/object/design/item/list/ItemLagging";
import { ItemNonwoven } from "vhows-design/src/object/design/item/list/ItemNonwoven";
import { ItemQuilting } from "vhows-design/src/object/design/item/list/ItemQuilting";
import { ItemScreen } from "vhows-design/src/object/design/item/list/ItemScreen";
import { ItemShadenet } from "vhows-design/src/object/design/item/list/ItemShadenet";
import { ItemTent } from "vhows-design/src/object/design/item/list/ItemTent";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { ItemWeavingFilm } from "vhows-design/src/object/design/item/list/ItemWeavingFilm";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject({
  knownTypes: [],
})
export class SkirtPartIL_Normal extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public coverLevel: CoverLevelIL; // 기초 피복 중수

  // 샘플

  // 아이템
  // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 치마 길이 AC */
  public skirtLengthAC: number[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _skirtWidth: number; // 치마 폭
  @jsonMember(Number)
  public _skirtLength: number; // 치마 길이

  /**
   * 치마 폭
   */
  public get skirtWidth(): number {
    return this._skirtWidth;
  }

  //
  public set skirtWidth(value: number) {
    this._skirtWidth = CommonUtil.fixFloat(value);
  }

  /**
   * 치마 길이
   */
  public get skirtLength(): number {
    return this._skirtLength;
  }

  //
  public set skirtLength(value: number) {
    this._skirtLength = CommonUtil.fixFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.init_coverLevel();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    /// //////// 선언 ///////////

    const skirtPosition: SkirtPositionIL = <SkirtPositionIL>this.position;
    // 외부
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    // 기타
    let basicBuilding: BasicBuilding;

    /// //////// 수치 ///////////

    // 치마 하나의 폭과 길이
    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN || this.position.label === CONST.LB_POSITION_SIDE_WING) {
      this.skirtWidth = skirtPosition.skirtHeight + this.design.preference.skirtCoverLayingDepth;
      this.skirtLength = this.basicLevel.length;
      if (this.basic.isVariableLength === true) {
        this.skirtLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.skirtLengthAC.push(basicBuilding.length);
        }
      }
    } else if (this.position.label === CONST.LB_POSITION_ROOF) {
      // 2동이상
      if (this.basic.buildingNumber >= 2) {
        this.skirtWidth = skirtPosition.skirtHeight + 0.2;
      } else {
        if (this.level.index === 0) {
          // 1동&1중만 어깨패드 거리에 영향
          this.skirtWidth = skirtPosition.skirtHeight + this.basicLevel.distanceShoulderPad + 0.1;
        } else {
          this.skirtWidth = skirtPosition.skirtHeight + 0.2;
        }
      }
      this.skirtLength = this.basicLevel.length;
      if (this.basic.isVariableLength === true) {
        this.skirtLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.skirtLengthAC.push(basicBuilding.length);
        }
      }
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.skirtWidth = skirtPosition.skirtHeight + this.design.preference.skirtCoverLayingDepth;
      this.skirtLength = this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.skirtWidth = skirtPosition.skirtHeight + this.design.preference.skirtCoverLayingDepth;
      this.skirtLength = this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber;
    }

    /// //////// 생성, 선택, 가시성, 규격, 수량 ///////////

    for (const coverPosition of this.coverLevel.positionAC as CoverPositionIL[]) {
      if (
        coverPosition.type === CONST.LB_COVER_TYPE_VINYL ||
        coverPosition.type === CONST.LB_COVER_TYPE_WEAVING_FILM ||
        coverPosition.type === CONST.LB_COVER_TYPE_SHADENET ||
        coverPosition.type === CONST.LB_COVER_TYPE_TENT ||
        coverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN ||
        coverPosition.type === CONST.LB_COVER_TYPE_QUILTING ||
        coverPosition.type === CONST.LB_COVER_TYPE_SCREEN ||
        coverPosition.type === CONST.LB_COVER_TYPE_MAT
      ) {
        // 비닐류
        this.makeSkirtVinylStyle(coverPosition);
      } else if (
        coverPosition.type === CONST.LB_COVER_TYPE_CASHMILON ||
        coverPosition.type === CONST.LB_COVER_TYPE_COLOR_LAGGING ||
        coverPosition.type === CONST.LB_COVER_TYPE_LAGGING
      ) {
        // 카시미론류
        this.makeSkirtCashmilonStyle(coverPosition);
      }
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 초기화: 기초 피복 중수
   */
  protected init_coverLevel(): void {
    this.coverLevel = <CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index];
  }

  /**
   * 비닐류 치마 만들기
   */
  protected makeSkirtVinylStyle(coverPosition: CoverPositionIL): void {
    // 외부
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    );
    // 기타
    let item: Item;
    let i: number;

    // 기둥(측면)
    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN && coverPosition.skirtCoverSideColumn === true) {
      // 2동이상 && 가변길이인 경우
      if (this.basic.buildingNumber >= 2 && this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < this.basic.buildingNumber; i++) {
          // 양끝2동
          if (i === 0 || i === this.basic.buildingNumber - 1) {
            item = this.getNewSkirtItem(coverPosition, true, i);
            item.purpose = `일반 치마> ${coverPosition.label}`;
            item.designQuantity = 1;

            this.itemAC.push(item);
          }
        }
      } else {
        // 개폐 방향값만큼 수량 입력
        item = this.getNewSkirtItem(coverPosition);
        item.purpose = `일반 치마> ${coverPosition.label}`;
        item.designQuantity = switcherPosition.switchWayValue;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_SIDE_WING && coverPosition.skirtCoverSideWing === true) {
      // 2동이상 && 가변길이인 경우
      if (this.basic.buildingNumber >= 2 && this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < this.basic.buildingNumber; i++) {
          // 양끝2동
          if (i === 0 || i === this.basic.buildingNumber - 1) {
            item = this.getNewSkirtItem(coverPosition, true, i);
            item.purpose = `일반 치마> ${coverPosition.label}`;
            item.designQuantity = 1;

            this.itemAC.push(item);
          }
        }
      } else {
        // 개폐 방향값만큼 수량 입력
        item = this.getNewSkirtItem(coverPosition);
        item.purpose = `일반 치마> ${coverPosition.label}`;
        item.designQuantity = switcherPosition.switchWayValue;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_ROOF && coverPosition.skirtCoverRoof === true) {
      // 2동이상
      if (this.basic.buildingNumber >= 2) {
        // 가변길이인 경우
        if (this.basic.isVariableLength === true) {
          // 동수만큼 품목 생성
          for (i = 0; i < this.basic.buildingNumber; i++) {
            // 양끝2동
            if (i === 0 || i === this.basic.buildingNumber - 1) {
              item = this.getNewSkirtItem(coverPosition, true, i);
              item.purpose = `${i + 1}번동 일반 치마> ${coverPosition.label}`;
              if (this.level.index === 0) {
                item.designQuantity = 1;
              } else {
                item.designQuantity = switcherPosition.switchWayValue;
              }

              this.itemAC.push(item);
            } else {
              item = this.getNewSkirtItem(coverPosition, true, i);
              item.purpose = `${i + 1}번동 일반 치마> ${coverPosition.label}`;
              item.designQuantity = switcherPosition.switchWayValue;

              this.itemAC.push(item);
            }
          }
        } else {
          // 동수만큼 수량 입력
          item = this.getNewSkirtItem(coverPosition);
          item.purpose = `일반 치마> ${coverPosition.label}`;
          if (this.level.index === 0) {
            item.designQuantity = 2 + switcherPosition.switchWayValue * (this.basic.buildingNumber - 2);
          } else {
            item.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          }

          this.itemAC.push(item);
        }
      } else {
        item = this.getNewSkirtItem(coverPosition);
        item.purpose = `일반 치마> ${coverPosition.label}`;
        item.designQuantity = switcherPosition.switchWayValue;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_FRONT && coverPosition.skirtCoverFront === true) {
      item = this.getNewSkirtItem(coverPosition);
      item.purpose = `일반 치마> ${coverPosition.label}`;
      item.designQuantity = 1;

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_BACK && coverPosition.skirtCoverBack === true) {
      item = this.getNewSkirtItem(coverPosition);
      item.purpose = `일반 치마> ${coverPosition.label}`;
      item.designQuantity = 1;

      this.itemAC.push(item);
    }
  }

  /**
   * 카시미론류 치마 만들기
   */
  protected makeSkirtCashmilonStyle(coverPosition: CoverPositionIL): void {
    // 외부
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    );
    // 기타
    let item: any;
    const overlapSize: number = this.design.preference.cashmilonStyleOverlapLength;
    let bothEndQuantity: number;
    let centralQuantity: number;

    // 기둥(측면)
    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN && coverPosition.skirtCoverSideColumn === true) {
      item = this.getNewSkirtItem(coverPosition);
      item.purpose = `일반 치마> ${coverPosition.label}`;
      // 길이방향 설치
      item.designQuantity = (this.skirtLength / (item.specLength - overlapSize)) * switcherPosition.switchWayValue;

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_SIDE_WING && coverPosition.skirtCoverSideWing === true) {
      item = this.getNewSkirtItem(coverPosition);
      item.purpose = `일반 치마> ${coverPosition.label}`;
      // 길이방향 설치
      item.designQuantity = (this.skirtLength / (item.specLength - overlapSize)) * switcherPosition.switchWayValue;

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_ROOF && coverPosition.skirtCoverRoof === true) {
      // 2동이상
      if (this.basic.buildingNumber >= 2) {
        item = this.getNewSkirtItem(coverPosition);
        item.purpose = `일반 치마> ${coverPosition.label}`;
        if (this.level.index === 0) {
          // 길이방향 설치
          bothEndQuantity = (this.skirtLength / (item.specLength - overlapSize)) * 2;
          centralQuantity =
            (this.skirtLength / (item.specLength - overlapSize)) *
            (switcherPosition.switchWayValue * (this.basic.buildingNumber - 2));
          item.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          // 길이방향 설치
          item.designQuantity =
            (this.skirtLength / (item.specLength - overlapSize)) *
            (switcherPosition.switchWayValue * this.basic.buildingNumber);
        }

        this.itemAC.push(item);
      } else {
        item = this.getNewSkirtItem(coverPosition);
        item.purpose = `일반 치마> ${coverPosition.label}`;
        // 길이방향 설치
        item.designQuantity = (this.skirtLength / (item.specLength - overlapSize)) * switcherPosition.switchWayValue;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_FRONT && coverPosition.skirtCoverFront === true) {
      item = this.getNewSkirtItem(coverPosition);
      item.purpose = `일반 치마> ${coverPosition.label}`;
      // 길이방향 설치
      item.designQuantity = this.skirtLength / (item.specLength - overlapSize);

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_BACK && coverPosition.skirtCoverBack === true) {
      item = this.getNewSkirtItem(coverPosition);
      item.purpose = `일반 치마> ${coverPosition.label}`;
      // 길이방향 설치
      item.designQuantity = this.skirtLength / (item.specLength - overlapSize);

      this.itemAC.push(item);
    }
  }

  /**
   * 새로운 치마 피복 아이템 만들기
   * @param coverPosition 기초 피복 위치 객체
   * @param isVariableLength 가변길이 여부
   * @param buildingIndex 동수 인덱스
   * @return 치마 피복 아이템
   */
  private getNewSkirtItem(
    coverPosition: CoverPositionIL,
    isVariableLength: boolean = false,
    buildingIndex: number = 0,
  ): Item {
    let item: Item;

    if (coverPosition.type === CONST.LB_COVER_TYPE_VINYL) {
      item = ItemVinyl.getNewDesignItemBySample(this, coverPosition.vinylPart.normalSkirtVinylSample);

      if (this.design.preference.skirtVinylOneBurstFitFlag === true) {
        (<ItemVinyl>item).specWidth = CommonUtil.convertM2CM(this.skirtWidth);
      } else {
        (<ItemVinyl>item).specWidth = (<ItemVinyl>item).getFoldedWidth(CommonUtil.convertM2CM(this.skirtWidth));
      }
      if (isVariableLength === true) {
        (<ItemVinyl>item).specLength = this.skirtLengthAC[buildingIndex];
      } else {
        (<ItemVinyl>item).specLength = this.skirtLength;
      }
      (<ItemVinyl>item).specPackingType = CONST.ITEM_SPEC_VALUE_ROLLING_ROD;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_WEAVING_FILM) {
      item = ItemWeavingFilm.getNewDesignItemBySample(this, coverPosition.weavingFilmPart.weavingFilmSample);

      (<ItemWeavingFilm>item).specWidth = CommonUtil.convertM2CM(this.skirtWidth);
      if (isVariableLength === true) {
        (<ItemWeavingFilm>item).specLength = this.skirtLengthAC[buildingIndex];
      } else {
        (<ItemWeavingFilm>item).specLength = this.skirtLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_SHADENET) {
      item = ItemShadenet.getNewDesignItemBySample(this, coverPosition.shadenetPart.shadenetSample);

      (<ItemShadenet>item).specWidth = this.skirtWidth;
      if (isVariableLength === true) {
        (<ItemShadenet>item).specLength = (<ItemShadenet>item).getAjustedSpecLength(this.skirtLengthAC[buildingIndex]);
      } else {
        (<ItemShadenet>item).specLength = (<ItemShadenet>item).getAjustedSpecLength(this.skirtLength);
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_TENT) {
      item = ItemTent.getNewDesignItemBySample(this, coverPosition.tentPart.tentSample);

      (<ItemTent>item).specWidth = this.skirtWidth;
      if (isVariableLength === true) {
        (<ItemTent>item).specLength = this.skirtLengthAC[buildingIndex];
      } else {
        (<ItemTent>item).specLength = this.skirtLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN) {
      item = ItemNonwoven.getNewDesignItemBySample(this, coverPosition.nonwovenPart.nonwovenSample);

      (<ItemNonwoven>item).specWidth = this.skirtWidth;
      if (isVariableLength === true) {
        (<ItemNonwoven>item).specLength = this.skirtLengthAC[buildingIndex];
      } else {
        (<ItemNonwoven>item).specLength = this.skirtLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_CASHMILON) {
      item = ItemCashmilon.getNewDesignItemBySample(this, coverPosition.cashmilonPart.cashmilonSample);

      (<ItemCashmilon>item).specWidth = this.skirtWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
      item = ItemLagging.getNewDesignItemBySample(this, coverPosition.colorLaggingPart.colorLaggingSample);

      (<ItemLagging>item).specWidth = this.skirtWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_LAGGING) {
      item = ItemLagging.getNewDesignItemBySample(this, coverPosition.laggingPart.laggingSample);

      (<ItemLagging>item).specWidth = this.skirtWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_QUILTING) {
      item = ItemQuilting.getNewDesignItemBySample(this, coverPosition.quiltingPart.quiltingSample);

      (<ItemQuilting>item).specWidth = this.skirtWidth;

      if (isVariableLength === true) {
        (<ItemQuilting>item).specLength = this.skirtLengthAC[buildingIndex];
      } else {
        (<ItemQuilting>item).specLength = this.skirtLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_SCREEN) {
      item = ItemScreen.getNewDesignItemBySample(this, coverPosition.screenPart.screenSample);

      (<ItemScreen>item).specWidth = this.skirtWidth;

      if (isVariableLength === true) {
        (<ItemScreen>item).specLength = this.skirtLengthAC[buildingIndex];
      } else {
        (<ItemScreen>item).specLength = this.skirtLength;
      }
    }

    return item;
  }
}
