import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { ColumnSampleVL_SideStraightPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_SideStraightPipe";
import { ColumnSampleVL_SideStraightCSectionSteel } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_SideStraightCSectionSteel";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-08
 */
@jsonObject({
  knownTypes: [ColumnSampleVL_SideStraightPipe, ColumnSampleVL_SideStraightCSectionSteel],
})
export class ColumnPartVL_SideStraight extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public sideStraightPipeSample: ColumnSampleVL_SideStraightPipe; // 측면 가로대 파이프 샘플
  public sideStraightCSectionSteelSample: ColumnSampleVL_SideStraightCSectionSteel; // 측면 가로대 C형강 샘플

  // 아이템
  public sideStraight_pipe: ItemPipe;
  public sideStraight_cSectionSteel: ItemPipe;
  public sideStraightAndSideStraight_connectionPin: ItemConnector;
  public sideStraightAndColumn_saddleHolder: ItemHolder;
  public sideStraight_screw: ItemScrew;
  public sideStraight_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _sideStraightLength: number; // 측면 가로대 길이
  @jsonMember(Number)
  public _sideStraightLineNumber: number; // 측면 가로대 줄수
  @jsonMember(String)
  public _sideStraightType: string; // 측면 가로대 형태

  /**
   * 측면 가로대 길이
   */
  public get sideStraightLength(): number {
    return this._sideStraightLength;
  }

  //
  public set sideStraightLength(value: number) {
    this._sideStraightLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 측면 가로대 줄수
   */
  public get sideStraightLineNumber(): number {
    return this._sideStraightLineNumber;
  }

  //
  public set sideStraightLineNumber(value: number) {
    this._sideStraightLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 측면 가로대 형태
   */
  public get sideStraightType(): string {
    return this._sideStraightType;
  }

  //
  public set sideStraightType(value: string) {
    this._sideStraightType = value;

    // 알고리즘
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 마구리
    for (const endpieceLevel of this.struct.endpieceWorkVL.levelAC as EndpieceLevelVL[]) {
      for (const endpiecePosition of endpieceLevel.positionAC as EndpiecePositionVL[]) {
        endpiecePosition.endpiecePart.straightBottomPipeSample.algorithmSpec();
      }
    }

    // 피복 고정
    for (const fixingLevel of this.struct.fixingWorkIL.levelAC as FixingLevelIL[]) {
      fixingLevel.columnPosition.normalClipPart.normalClipSample.algorithmSpec();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sideStraightPipeSample = new ColumnSampleVL_SideStraightPipe();
    this.sideStraightCSectionSteelSample = new ColumnSampleVL_SideStraightCSectionSteel();

    this.sampleAC = [this.sideStraightPipeSample, this.sideStraightCSectionSteelSample];

    // 아이템
    this.sideStraight_pipe = new ItemPipe();
    this.sideStraight_cSectionSteel = new ItemPipe();
    this.sideStraightAndSideStraight_connectionPin = new ItemConnector();
    this.sideStraightAndColumn_saddleHolder = new ItemHolder();
    this.sideStraight_screw = new ItemScrew();
    this.sideStraight_welding = new ItemOtherExpense();

    this.itemAC = [
      this.sideStraight_pipe,
      this.sideStraight_cSectionSteel,
      this.sideStraightAndSideStraight_connectionPin,
      this.sideStraightAndColumn_saddleHolder,
      this.sideStraight_screw,
      this.sideStraight_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.sideStraightPipeSample = <ColumnSampleVL_SideStraightPipe>this.sampleAC[0];
    this.sideStraightCSectionSteelSample = <ColumnSampleVL_SideStraightCSectionSteel>this.sampleAC[1];

    // 아이템
    this.sideStraight_pipe = <ItemPipe>this.itemAC[0];
    this.sideStraight_cSectionSteel = <ItemPipe>this.itemAC[1];
    this.sideStraightAndSideStraight_connectionPin = <ItemConnector>this.itemAC[2];
    this.sideStraightAndColumn_saddleHolder = <ItemHolder>this.itemAC[3];
    this.sideStraight_screw = <ItemScrew>this.itemAC[4];
    this.sideStraight_welding = <ItemOtherExpense>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param sideStraightLineNumber: number 측면 가로대 줄수
   * @param sideStraightType: string 측면 가로대 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    sideStraightLineNumber: number = 0,
    sideStraightType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._sideStraightLineNumber = sideStraightLineNumber;
    this._sideStraightType = sideStraightType;

    // 샘플
    if (this.level.index >= 0) {
      this.sideStraightPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 가로대",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.sideStraightCSectionSteelSample.setDefaultData(
        1,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "측면 가로대",
        "각형, 일반, 75×45mm, 2.1T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.sideStraight_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "측면 가로대",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.sideStraight_cSectionSteel.setDefaultData(
        1,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "측면 가로대",
        "각형, 일반, 75×45mm, 2.1T, 10m, -",
      );
      this.sideStraightAndSideStraight_connectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "측면 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.sideStraightAndColumn_saddleHolder.setDefaultData(
        3,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "측면 가로대 + 기둥",
        "원형, 일반, 25mm, -",
      );
      this.sideStraight_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "측면 가로대",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.sideStraight_welding.setDefaultData(
        5,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "측면 가로대",
        "일반",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_sideStraightLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    if (this.sideStraightType === CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_PIPE) {
      this.sideStraight_pipe.checkSelected(true);
      this.sideStraight_cSectionSteel.selected = false;
      this.sideStraight_cSectionSteel.visible = false;
      this.sideStraightAndSideStraight_connectionPin.checkSelected(true);
      this.sideStraightAndColumn_saddleHolder.selected = true;
      this.sideStraightAndColumn_saddleHolder.visible = true;
      this.sideStraight_screw.selected = true;
      this.sideStraight_screw.visible = true;
      this.sideStraight_welding.selected = false;
      this.sideStraight_welding.visible = false;
    } else if (this.sideStraightType === CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_C_SECTION_STEEL) {
      this.sideStraight_pipe.checkSelected(false);
      this.sideStraight_cSectionSteel.selected = true;
      this.sideStraight_cSectionSteel.visible = true;
      this.sideStraightAndSideStraight_connectionPin.checkSelected(false);
      this.sideStraightAndColumn_saddleHolder.selected = false;
      this.sideStraightAndColumn_saddleHolder.visible = false;
      this.sideStraight_screw.selected = false;
      this.sideStraight_screw.visible = false;
      this.sideStraight_welding.selected = true;
      this.sideStraight_welding.visible = true;
    }

    /// //////// 수치 ///////////

    let oneLineColumnQuantity: number =
      Math.floor(this.basicLevel.length / (<ColumnPositionVL>this.position).prismColumnPart.columnInterval) + 1;
    if ((<ColumnPositionVL>this.position).type === CONST.LB_COLUMN_TYPE_PRISM) {
      oneLineColumnQuantity = oneLineColumnQuantity * 2 - 1;
    }
    let upperSideStraightLineNumber: number = 0;
    if (this.sideStraightLineNumber === 3) {
      upperSideStraightLineNumber = 1;
    } else if (this.sideStraightLineNumber >= 4) {
      upperSideStraightLineNumber = 2;
    }

    /// //////// 수량 ///////////

    this.sideStraight_pipe.designQuantity =
      (this.sideStraight_pipe.getConnectedPipeLength(this.sideStraightLength, this.sideStraightLineNumber) /
        this.sideStraight_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    this.sideStraight_cSectionSteel.designQuantity =
      (this.sideStraight_cSectionSteel.getConnectedPipeLength(this.sideStraightLength, this.sideStraightLineNumber) /
        this.sideStraight_cSectionSteel.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    if (this.sideStraightLength > this.sideStraight_pipe.specLength) {
      this.sideStraightAndSideStraight_connectionPin.designQuantity =
        this.sideStraight_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.sideStraightAndSideStraight_connectionPin.designQuantity = 0;
    }

    this.sideStraightAndColumn_saddleHolder.designQuantity =
      this.sideStraightLineNumber * (oneLineColumnQuantity * 2) * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.sideStraight_screw.designQuantity =
      ((this.sideStraightAndSideStraight_connectionPin.getSelectedQuantity() * 2 +
        this.sideStraightAndColumn_saddleHolder.getSelectedQuantity() * 3) /
        this.sideStraight_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.sideStraight_welding.designQuantity = this.sideStraightLineNumber * (oneLineColumnQuantity * 2);
  }

  /**
   * 알고리즘: 측면 가로대 길이 <- 길이(기본정보)
   */
  public algorithm_sideStraightLength(): void {
    this.sideStraightLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [측면 가로대] 파이프 <- 측면 가로대 파이프 샘플
   */
  public algorithmSpec_sideStraight_pipe(): void {
    this.sideStraight_pipe.specs = this.sideStraightPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [측면 가로대] C형강 <- 측면 가로대 C형강 샘플
   */
  public algorithmSpec_sideStraight_cSectionSteel(): void {
    this.sideStraight_cSectionSteel.specs = this.sideStraightCSectionSteelSample.specs;
  }

  /**
   * 규격 알고리즘: [측면 가로대] 연결핀 <- 측면 가로대 파이프 샘플
   */
  public algorithmSpec_sideStraightAndSideStraight_connectionPin(): void {
    this.sideStraightAndSideStraight_connectionPin.specPipeType = this.sideStraightPipeSample.specPipeType;
    this.sideStraightAndSideStraight_connectionPin.specCrossSize1 = this.sideStraightPipeSample.specCrossSize;
    this.sideStraightAndSideStraight_connectionPin.specCrossSize2 = this.sideStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면 가로대 + 기둥] 새들 고정구 <- 측면 가로대 파이프 샘플
   */
  public algorithmSpec_sideStraightAndColumn_saddleHolder(): void {
    this.sideStraightAndColumn_saddleHolder.specPipeType = this.sideStraightPipeSample.specPipeType;
    this.sideStraightAndColumn_saddleHolder.specCrossSize1 = this.sideStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [측면 가로대] 나사 <- 공통 샘플
   */
  public algorithmSpec_sideStraight_screw(): void {}

  /**
   * 규격 알고리즘: [측면 가로대] 용접 <- 공통 샘플
   */
  public algorithmSpec_sideStraight_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
