import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCordWire } from "vhows-design/src/object/design/item/list/ItemCordWire";
import { ItemCurtainOther } from "vhows-design/src/object/design/item/list/ItemCurtainOther";
import { ItemWindingDrum } from "vhows-design/src/object/design/item/list/ItemWindingDrum";
import { ItemWireStrain } from "vhows-design/src/object/design/item/list/ItemWireStrain";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [],
})
export class CurSwitchPartIL_Line extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플

  // 아이템
  public lineTow_windingDrum: ItemWindingDrum;
  public lineTow_ripcord: ItemCordWire;
  public lineTow_coatingWire: ItemCordWire;
  public lineTow_towRoller: ItemCurtainOther;
  public lineTow_rollerHook: ItemCurtainOther;
  public lineSupport_ripcord: ItemCordWire;
  public lineSupport_coatingWire: ItemCordWire;
  public lineGuide_coatingWire: ItemCordWire;
  public lineGuide_wireStrainer: ItemWireStrain;
  public lineGuide_guideRoller: ItemCurtainOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Boolean)
  public _windingDrumSelected: boolean; // 와인딩 드럼 선택
  @jsonMember(String)
  public _towLineType: string; // 예인선 형태
  @jsonMember(Number)
  public _towLineNumber: number; // 예인선 줄수
  @jsonMember(Boolean)
  public _towRollerSelected: boolean; // 예인 롤러 선택
  @jsonMember(String)
  public _supportLineType: string; // 받침선 형태
  @jsonMember(Number)
  public _supportLineNumber: number; // 받침선 줄수

  /**
   * 와인딩 드럼 선택
   */
  public get windingDrumSelected(): boolean {
    return this._windingDrumSelected;
  }

  //
  public set windingDrumSelected(value: boolean) {
    this._windingDrumSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 예인선 형태
   */
  public get towLineType(): string {
    return this._towLineType;
  }

  //
  public set towLineType(value: string) {
    this._towLineType = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 예인선 줄수
   */
  public get towLineNumber(): number {
    return this._towLineNumber;
  }

  //
  public set towLineNumber(value: number) {
    this._towLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    if ((<CurSwitchPositionIL>this.position).label === CONST.LB_POSITION_SIDE_COLUMN) {
      (<CurSwitchPositionIL>this.position).fixingClipSidePart.algorithmPart();
    }
    if ((this.position as CurSwitchPositionIL).label === CONST.LB_POSITION_CEILING) {
      (this.position as CurSwitchPositionIL).fixingClipCeilingPart.algorithmPart();
    }
  }

  /**
   * 예인 롤러 선택
   */
  public get towRollerSelected(): boolean {
    return this._towRollerSelected;
  }

  //
  public set towRollerSelected(value: boolean) {
    this._towRollerSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 받침선 형태
   */
  public get supportLineType(): string {
    return this._supportLineType;
  }

  //
  public set supportLineType(value: string) {
    this._supportLineType = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 받침선 줄수
   */
  public get supportLineNumber(): number {
    return this._supportLineNumber;
  }

  //
  public set supportLineNumber(value: number) {
    this._supportLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플

    this.sampleAC = [];

    // 아이템
    this.lineTow_windingDrum = new ItemWindingDrum();
    this.lineTow_ripcord = new ItemCordWire();
    this.lineTow_coatingWire = new ItemCordWire();
    this.lineTow_towRoller = new ItemCurtainOther();
    this.lineTow_rollerHook = new ItemCurtainOther();
    this.lineSupport_ripcord = new ItemCordWire();
    this.lineSupport_coatingWire = new ItemCordWire();
    this.lineGuide_coatingWire = new ItemCordWire();
    this.lineGuide_wireStrainer = new ItemWireStrain();
    this.lineGuide_guideRoller = new ItemCurtainOther();

    this.itemAC = [
      this.lineTow_windingDrum,
      this.lineTow_ripcord,
      this.lineTow_coatingWire,
      this.lineTow_towRoller,
      this.lineTow_rollerHook,
      this.lineSupport_ripcord,
      this.lineSupport_coatingWire,
      this.lineGuide_coatingWire,
      this.lineGuide_wireStrainer,
      this.lineGuide_guideRoller,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플

    // 아이템
    this.lineTow_windingDrum = <ItemWindingDrum>this.itemAC[0];
    this.lineTow_ripcord = <ItemCordWire>this.itemAC[1];
    this.lineTow_coatingWire = <ItemCordWire>this.itemAC[2];
    this.lineTow_towRoller = <ItemCurtainOther>this.itemAC[3];
    this.lineTow_rollerHook = <ItemCurtainOther>this.itemAC[4];
    this.lineSupport_ripcord = <ItemCordWire>this.itemAC[5];
    this.lineSupport_coatingWire = <ItemCordWire>this.itemAC[6];
    this.lineGuide_coatingWire = <ItemCordWire>this.itemAC[7];
    this.lineGuide_wireStrainer = <ItemWireStrain>this.itemAC[8];
    this.lineGuide_guideRoller = <ItemCurtainOther>this.itemAC[9];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param windingDrumSelected: boolean 와인딩 드럼 선택
   * @param towLineType: string 예인선 형태
   * @param towLineNumber: number 예인선 줄수
   * @param towRollerSelected: boolean 예인 롤러 선택
   * @param supportLineType: string 받침선 형태
   * @param supportLineNumber: number 받침선 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    windingDrumSelected: boolean = false,
    towLineType: string = "",
    towLineNumber: number = 0,
    towRollerSelected: boolean = false,
    supportLineType: string = "",
    supportLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._windingDrumSelected = windingDrumSelected;
    this._towLineType = towLineType;
    this._towLineNumber = towLineNumber;
    this._towRollerSelected = towRollerSelected;
    this._supportLineType = supportLineType;
    this._supportLineNumber = supportLineNumber;

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      this.lineTow_windingDrum.setDefaultData(
        0,
        CONST.ITEM_ID_WINDING_DRUM,
        CONST.ITEM_NAME_WINDING_DRUM,
        "커튼 예인선",
        "일반, 48mm, 100mm",
      );
      this.lineTow_ripcord.setDefaultData(
        1,
        CONST.ITEM_ID_RIPCORD,
        CONST.ITEM_NAME_RIPCORD,
        "커튼 예인선",
        "낙하산줄, 실, 4mm, 1500m",
      );
      this.lineTow_coatingWire.setDefaultData(
        2,
        CONST.ITEM_ID_COATING_WIRE,
        CONST.ITEM_NAME_COATING_WIRE,
        "커튼 예인선",
        "코팅줄, 철사, 2.8mm, 2000m",
      );
      this.lineTow_towRoller.setDefaultData(
        3,
        CONST.ITEM_ID_TOW_ROLLER,
        CONST.ITEM_NAME_TOW_ROLLER,
        "커튼 예인선",
        "일반, 소",
      );
      this.lineTow_rollerHook.setDefaultData(
        4,
        CONST.ITEM_ID_ROLLER_HOOK,
        CONST.ITEM_NAME_ROLLER_HOOK,
        "커튼 예인선",
        "일반",
      );
      this.lineSupport_ripcord.setDefaultData(
        5,
        CONST.ITEM_ID_RIPCORD,
        CONST.ITEM_NAME_RIPCORD,
        "커튼 받침선",
        "낙하산줄, 실, 3.5mm, 2000m",
      );
      this.lineSupport_coatingWire.setDefaultData(
        6,
        CONST.ITEM_ID_COATING_WIRE,
        CONST.ITEM_NAME_COATING_WIRE,
        "커튼 받침선",
        "코팅줄, 철사, 1.8mm, 2000m",
      );
      this.lineGuide_coatingWire.setDefaultData(
        7,
        CONST.ITEM_ID_COATING_WIRE,
        CONST.ITEM_NAME_COATING_WIRE,
        "커튼 가이드선",
        "코팅줄, 철사, 4.2mm, 500m",
      );
      this.lineGuide_wireStrainer.setDefaultData(
        8,
        CONST.ITEM_ID_WIRE_STRAINER,
        CONST.ITEM_NAME_WIRE_STRAINER,
        "커튼 가이드선",
        "원형, 일반, 32mm",
      );
      this.lineGuide_guideRoller.setDefaultData(
        9,
        CONST.ITEM_ID_GUIDE_ROLLER,
        CONST.ITEM_NAME_GUIDE_ROLLER,
        "커튼 가이드선",
        "일반",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();

    this.algorithm_towLineNumberAndSupportLineNumber();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    const curCoverPosition: CurCoverPositionIL = <CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition;
    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    if (this.windingDrumSelected === true) {
      this.lineTow_windingDrum.selected = true;
      this.lineTow_windingDrum.visible = true;
    } else {
      this.lineTow_windingDrum.selected = false;
      this.lineTow_windingDrum.visible = false;
    }
    if (this.towLineType === CONST.LB_CURTAIN_SWITCH_LINE_TYPE_RIPCORD) {
      this.lineTow_ripcord.selected = true;
      this.lineTow_ripcord.visible = true;
      this.lineTow_coatingWire.selected = false;
      this.lineTow_coatingWire.visible = false;
    } else if (this.towLineType === CONST.LB_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE) {
      this.lineTow_ripcord.selected = false;
      this.lineTow_ripcord.visible = false;
      this.lineTow_coatingWire.selected = true;
      this.lineTow_coatingWire.visible = true;
    }
    if (this.towRollerSelected) {
      this.lineTow_towRoller.selected = true;
      this.lineTow_towRoller.visible = true;
      this.lineTow_rollerHook.selected = true;
      this.lineTow_rollerHook.visible = true;
    } else {
      this.lineTow_towRoller.selected = false;
      this.lineTow_towRoller.visible = false;
      this.lineTow_rollerHook.selected = false;
      this.lineTow_rollerHook.visible = false;
    }
    if (this.position.label === CONST.LB_POSITION_CEILING) {
      if (this.supportLineType === CONST.LB_CURTAIN_SWITCH_LINE_TYPE_RIPCORD) {
        this.lineSupport_ripcord.selected = true;
        this.lineSupport_ripcord.visible = true;
        this.lineSupport_coatingWire.selected = false;
        this.lineSupport_coatingWire.visible = false;
      } else if (this.supportLineType === CONST.LB_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE) {
        this.lineSupport_ripcord.selected = false;
        this.lineSupport_ripcord.visible = false;
        this.lineSupport_coatingWire.selected = true;
        this.lineSupport_coatingWire.visible = true;
      }
      this.lineGuide_coatingWire.selected = true;
      this.lineGuide_coatingWire.visible = true;
      this.lineGuide_wireStrainer.selected = true;
      this.lineGuide_wireStrainer.visible = true;
      this.lineGuide_guideRoller.selected = true;
      this.lineGuide_guideRoller.visible = true;
    } else {
      this.lineSupport_ripcord.selected = false;
      this.lineSupport_ripcord.visible = false;
      this.lineSupport_coatingWire.selected = false;
      this.lineSupport_coatingWire.visible = false;
      this.lineGuide_coatingWire.selected = false;
      this.lineGuide_coatingWire.visible = false;
      this.lineGuide_wireStrainer.selected = false;
      this.lineGuide_wireStrainer.visible = false;
      this.lineGuide_guideRoller.selected = false;
      this.lineGuide_guideRoller.visible = false;
    }

    /// //////// 수치 ///////////

    let switchLength: number = 0; // 개폐 길이
    let lineLength: number = 0; // 선 길이
    let towRollerMultiple: number = 0; // 예일 롤러 배수
    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
      switchLength = this.basicLevel.length;
      lineLength = this.basicLevel.heightShoulder * 2;
      towRollerMultiple = 1;
    } else if (this.position.label === CONST.LB_POSITION_CEILING) {
      switchLength = this.basicLevel.length;
      lineLength =
        (this.basicLevel.heightShoulder * curSwitchPosition.switchDivisionValue +
          this.basicLevel.width * this.basic.buildingNumber) *
        2;
      towRollerMultiple = 3;

      if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
        lineLength =
          (this.basicLevel.heightShoulder * curSwitchPosition.switchDivisionValue + this.basicLevel.length) * 2;
      }
    } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
      switchLength = this.basicLevel.width * this.basic.buildingNumber;
      lineLength = this.basicLevel.heightShoulder * 2;
      towRollerMultiple = 1;
    }

    /// //////// 수량 ///////////

    this.lineTow_windingDrum.designQuantity =
      Math.ceil(switchLength / columnPosition.columnInterval) *
      this.towLineNumber *
      curSwitchPosition.switchWayValue *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_WINDING_DRUM;
    if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
      this.lineTow_windingDrum.designQuantity =
        this.towLineNumber *
        curSwitchPosition.switchWayValue *
        curSwitchPosition.switchDivisionValue *
        curCoverPosition.ceilingLevelValue *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_WINDING_DRUM;
    }

    this.lineTow_ripcord.designQuantity =
      ((lineLength *
        Math.ceil(switchLength / columnPosition.columnInterval) *
        this.towLineNumber *
        curSwitchPosition.switchWayValue) /
        this.lineTow_ripcord.specLength) *
      CONST.NUM_EXTRA_RATE_RIPCORD;

    this.lineTow_coatingWire.designQuantity =
      ((lineLength *
        Math.ceil(switchLength / columnPosition.columnInterval) *
        this.towLineNumber *
        curSwitchPosition.switchWayValue) /
        this.lineSupport_coatingWire.specLength) *
      CONST.NUM_EXTRA_RATE_COATING_WIRE;

    this.lineTow_towRoller.designQuantity =
      this.lineTow_windingDrum.designQuantity * towRollerMultiple * CONST.NUM_EXTRA_RATE_TOW_ROLLER;

    this.lineTow_rollerHook.designQuantity =
      this.lineTow_windingDrum.designQuantity * towRollerMultiple * CONST.NUM_EXTRA_RATE_ROLLER_HOOK;

    if (this.position.label === CONST.LB_POSITION_CEILING) {
      this.lineTow_ripcord.designQuantity *= curCoverPosition.ceilingLevelValue;

      this.lineTow_coatingWire.designQuantity *= curCoverPosition.ceilingLevelValue;

      this.lineSupport_ripcord.designQuantity =
        (((this.basicLevel.width * this.basic.buildingNumber + 1) *
          Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
          this.supportLineNumber) /
          this.lineSupport_ripcord.specLength) *
        curCoverPosition.ceilingLevelValue *
        CONST.NUM_EXTRA_RATE_RIPCORD;

      this.lineSupport_coatingWire.designQuantity =
        (((this.basicLevel.width * this.basic.buildingNumber + 1) *
          Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
          this.supportLineNumber) /
          this.lineSupport_coatingWire.specLength) *
        curCoverPosition.ceilingLevelValue *
        CONST.NUM_EXTRA_RATE_COATING_WIRE;

      this.lineGuide_coatingWire.designQuantity =
        (((this.basicLevel.width * this.basic.buildingNumber + 1) * 2 * 2) / this.lineGuide_coatingWire.specLength) *
        curCoverPosition.ceilingLevelValue *
        CONST.NUM_EXTRA_RATE_COATING_WIRE;

      this.lineGuide_wireStrainer.designQuantity =
        4 * curCoverPosition.ceilingLevelValue * CONST.NUM_EXTRA_RATE_WIRE_STRAINER;

      this.lineGuide_guideRoller.designQuantity =
        2 * 2 * this.basic.buildingNumber * curCoverPosition.ceilingLevelValue * CONST.NUM_EXTRA_RATE_GUIDE_ROLLER;

      if (curCoverPosition.axisType === CONST.LB_CURTAIN_AXIS_TYPE_VL) {
        this.lineTow_ripcord.designQuantity =
          ((lineLength * this.towLineNumber * this.basic.buildingNumber) / this.lineTow_ripcord.specLength) *
          curCoverPosition.ceilingLevelValue *
          CONST.NUM_EXTRA_RATE_RIPCORD;

        this.lineTow_coatingWire.designQuantity =
          ((lineLength * this.towLineNumber * this.basic.buildingNumber) / this.lineTow_coatingWire.specLength) *
          curCoverPosition.ceilingLevelValue *
          CONST.NUM_EXTRA_RATE_COATING_WIRE;

        this.lineSupport_ripcord.designQuantity =
          (((this.basicLevel.length + 1) * this.supportLineNumber * this.basic.buildingNumber) /
            this.lineSupport_ripcord.specLength) *
          curCoverPosition.ceilingLevelValue *
          CONST.NUM_EXTRA_RATE_RIPCORD;

        this.lineSupport_coatingWire.designQuantity =
          (((this.basicLevel.length + 1) * this.supportLineNumber * this.basic.buildingNumber) /
            this.lineSupport_coatingWire.specLength) *
          curCoverPosition.ceilingLevelValue *
          CONST.NUM_EXTRA_RATE_COATING_WIRE;

        this.lineGuide_coatingWire.designQuantity =
          (((this.basicLevel.length + 1) * 2 * 2 * this.basic.buildingNumber) / this.lineGuide_coatingWire.specLength) *
          curCoverPosition.ceilingLevelValue *
          CONST.NUM_EXTRA_RATE_COATING_WIRE;

        this.lineGuide_guideRoller.designQuantity =
          Math.ceil(this.basicLevel.length / columnPosition.columnInterval) *
          2 *
          this.basic.buildingNumber *
          curCoverPosition.ceilingLevelValue *
          CONST.NUM_EXTRA_RATE_GUIDE_ROLLER;
      }
    } else {
      this.lineSupport_ripcord.designQuantity = 0;

      this.lineSupport_coatingWire.designQuantity = 0;

      this.lineGuide_coatingWire.designQuantity = 0;

      this.lineGuide_wireStrainer.designQuantity = 0;

      this.lineGuide_guideRoller.designQuantity = 0;
    }
  }

  /**
   * 알고리즘: 와인딩 드럼 선택 <- 감속 모터 샘플
   */
  public algorithm_windingDrumSelected(): void {
    if ((<CurSwitchPositionIL>this.position).label === CONST.LB_POSITION_CEILING) {
      if (
        (<CurSwitchPositionIL>this.position).motorPart.reducedMotorSample.specWindingType ===
        CONST.ITEM_SPEC_VALUE_DRUM_TYPE
      ) {
        this.windingDrumSelected = true;
      } else {
        this.windingDrumSelected = false;
      }
    }
  }

  /**
   * 알고리즘: 예인선 줄수, 받침선 줄수 <- 형태(기둥 골조), 기둥 간격(기둥 골조)
   */
  public algorithm_towLineNumberAndSupportLineNumber(): void {
    /// //////// 선언 ///////////
    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    if ((<CurSwitchPositionIL>this.position).label === CONST.LB_POSITION_CEILING) {
      // 천장
      if (
        (<CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition).axisType ===
        CONST.LB_CURTAIN_AXIS_TYPE_IL
      ) {
        // 연동
        if (columnPosition.columnInterval < 3) {
          this.towLineNumber = 2;
          this.supportLineNumber = 3;
        } else {
          this.towLineNumber = 3;
          this.supportLineNumber = 4;
        }
      } else if (
        (<CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition).axisType ===
        CONST.LB_CURTAIN_AXIS_TYPE_VL
      ) {
        // 벤로
        if (this.basicLevel.width < 9) {
          this.towLineNumber = 8;
          this.supportLineNumber = 9;
        } else {
          this.towLineNumber = 10;
          this.supportLineNumber = 11;
        }
      }
    } else {
      // 기둥, 앞면, 뒷면
      if (
        (<CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition).axisType ===
        CONST.LB_CURTAIN_AXIS_TYPE_IL
      ) {
        this.towLineNumber = 1;
      } else if (
        (<CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition).axisType ===
        CONST.LB_CURTAIN_AXIS_TYPE_VL
      ) {
        this.towLineNumber = Math.ceil(columnPosition.columnInterval / 2);
      }
      this.supportLineNumber = 0;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [커튼 예인선] 와인딩 드럼 <- 커튼 구동 축 파이프 샘플
   */
  public algorithmSpec_lineTow_windingDrum(): void {
    this.lineTow_windingDrum.specCrossSize1 = (<CurSwitchPositionIL>(
      this.position
    )).framePart.driveAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 예인선] 낙하산 줄 <- 없음
   */
  public algorithmSpec_lineTow_ripcord(): void {}

  /**
   * 규격 알고리즘: [커튼 예인선] 코팅 와이어 <- 없음
   */
  public algorithmSpec_lineTow_coatingWire(): void {}

  /**
   * 규격 알고리즘: [커튼 예인선] 예인 롤러 <- 공통 샘플
   */
  public algorithmSpec_lineTow_towRoller(): void {}

  /**
   * 규격 알고리즘: [커튼 예인선] 롤러 고리 <- 공통 샘플
   */
  public algorithmSpec_lineTow_rollerHook(): void {}

  /**
   * 규격 알고리즘: [커튼 받침선] 낙하산 줄 <- 없음
   */
  public algorithmSpec_lineSupport_ripcord(): void {}

  /**
   * 규격 알고리즘: [커튼 받침선] 코팅 와이어 <- 없음
   */
  public algorithmSpec_lineSupport_coatingWire(): void {}

  /**
   * 규격 알고리즘: [커튼 가이드선] 코팅 와이어 <- 없음
   */
  public algorithmSpec_lineGuide_coatingWire(): void {}

  /**
   * 규격 알고리즘: [커튼 가이드선] 와이어 인장기 <- 커튼 지지 축 파이프
   */
  public algorithmSpec_lineGuide_wireStrainer(): void {
    this.lineGuide_wireStrainer.specCrossSize = (<CurSwitchPositionIL>(
      this.position
    )).framePart.supportAxisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [커튼 가이드선] 가이드 롤러 <- 공통 샘플
   */
  public algorithmSpec_lineGuide_guideRoller(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
