import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurSwitchPartIL_SupVertical } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_SupVertical";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject
export class CurSwitchSampleIL_VerticalSupPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const supportPart: CurSwitchPartIL_SupVertical = <CurSwitchPartIL_SupVertical>this.part;
    supportPart.algorithmSpec_verticalSupport_pipe();
    supportPart.algorithmSpec_verticalSupport_axisRoller();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    (<CurSwitchPositionIL>this.position).powerPart.powerSwitcherSample.algorithmSpec();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<CurSwitchPositionIL>this.level.positionAC[p]).supVerticalPart.verticalSupportPipeSample.specs = this.specs;
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 수직형 지지 파이프 샘플(개폐기)
   */
  // @override
  public algorithmSpec(): void {
    this.specs = this.struct.switcherWorkIL.level1.columnPosition.supportPart.verticalSupportPipeSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
