import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPadVinyl } from "vhows-design/src/object/design/item/list/ItemPadVinyl";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { SkySwitchPositionIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPositionIL";
import { SkyCoverSampleIL_NormalPad } from "vhows-design/src/object/design/skylight/cover/il/SkyCoverSampleIL_NormalPad";
import { SkyCoverPositionIL } from "vhows-design/src/object/design/skylight/cover/il/SkyCoverPositionIL";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-22
 */
@jsonObject({
  knownTypes: [SkyCoverSampleIL_NormalPad],
})
export class SkyCoverPartIL_NormalPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalPadSample: SkyCoverSampleIL_NormalPad; // 일반 패드 샘플

  // 아이템
  public normalPad_normalPad: ItemPad;
  public normalPad_barPad: ItemPad;
  public normalPad_normalPadSpring: ItemPadSpring;
  public normalPad_padConnectionPin: ItemPadConnector;
  public normalPad_padVinyl: ItemPadVinyl;
  public normalPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalPadSample = new SkyCoverSampleIL_NormalPad();

    this.sampleAC = [this.normalPadSample];

    // 아이템
    this.normalPad_normalPad = new ItemPad();
    this.normalPad_barPad = new ItemPad();
    this.normalPad_normalPadSpring = new ItemPadSpring();
    this.normalPad_padConnectionPin = new ItemPadConnector();
    this.normalPad_padVinyl = new ItemPadVinyl();
    this.normalPad_screw = new ItemScrew();

    this.itemAC = [
      this.normalPad_normalPad,
      this.normalPad_barPad,
      this.normalPad_normalPadSpring,
      this.normalPad_padConnectionPin,
      this.normalPad_padVinyl,
      this.normalPad_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalPadSample = <SkyCoverSampleIL_NormalPad>this.sampleAC[0];

    // 아이템
    this.normalPad_normalPad = <ItemPad>this.itemAC[0];
    this.normalPad_barPad = <ItemPad>this.itemAC[1];
    this.normalPad_normalPadSpring = <ItemPadSpring>this.itemAC[2];
    this.normalPad_padConnectionPin = <ItemPadConnector>this.itemAC[3];
    this.normalPad_padVinyl = <ItemPadVinyl>this.itemAC[4];
    this.normalPad_screw = <ItemScrew>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.normalPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드",
        "칼라 철, 0.6T, 6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalPad_normalPad.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드",
        "칼라 철, 0.6T, 6m",
      );
      this.normalPad_barPad.setDefaultData(
        1,
        CONST.ITEM_ID_BAR_PAD,
        CONST.ITEM_NAME_BAR_PAD,
        "간살 패드",
        "고내식, 0.7T, 0.75m",
      );
      this.normalPad_normalPadSpring.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "일반 패드",
        "일반, 2m",
      );
      this.normalPad_padConnectionPin.setDefaultData(
        3,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "일반 패드",
        "칼라 철",
      );
      this.normalPad_padVinyl.setDefaultData(
        4,
        CONST.ITEM_ID_PAD_VINYL,
        CONST.ITEM_NAME_PAD_VINYL,
        "일반 패드",
        "일반, 200m",
      );
      this.normalPad_screw.setDefaultData(
        5,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "일반 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    if (this.itemAC.length === 5 && (<ItemPadSpring>this.itemAC[1]).productId === CONST.ITEM_ID_NORMAL_PAD_SPRING) {
      const normalPad_barPad: ItemPad = new ItemPad();
      normalPad_barPad.setAssociation(design, struct, work, level, position, this);
      normalPad_barPad.setDefaultData(
        1,
        CONST.ITEM_ID_BAR_PAD,
        CONST.ITEM_NAME_BAR_PAD,
        "간살 패드",
        "고내식, 0.7T, 0.75m",
      );
      this.itemAC.splice(1, 0, normalPad_barPad);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_normalPad_normalPadSpring();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skyCoverPosition: SkyCoverPositionIL = <SkyCoverPositionIL>this.position;
    const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;
    const skySwitchPosition: SkySwitchPositionIL = this.struct.skySwitchWorkIL.level1.skylightPosition;

    /// //////// 선택, 가시성 ///////////

    this.normalPad_normalPad.selected = true;
    this.normalPad_normalPad.visible = true;
    if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
    ) {
      this.normalPad_barPad.selected = false;
      this.normalPad_barPad.visible = false;
      this.normalPad_padConnectionPin.setSelectedByPad(true, this.normalPad_normalPad.specMaterial);
      this.normalPad_padConnectionPin.visible = true;
    } else if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.normalPad_barPad.selected = true;
      this.normalPad_barPad.visible = true;
      this.normalPad_padConnectionPin.selected = false;
      this.normalPad_padConnectionPin.visible = false;
    }
    this.normalPad_normalPadSpring.selected = true;
    this.normalPad_normalPadSpring.visible = true;
    this.normalPad_padVinyl.selected = this.design.basic.padVinylFlag;
    this.normalPad_padVinyl.visible = true;
    this.normalPad_screw.selected = true;
    this.normalPad_screw.visible = true;

    /// //////// 수량 ///////////

    const skylightLength = skyFramePosition.skylightLength;
    const rackpinionInterval = skySwitchPosition.rackpinionPart.rackpinionInterval;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      // 연동형인 경우
      this.normalPad_normalPad.designQuantity =
        Math.ceil(skylightLength / this.normalPad_normalPad.specLength) *
        skyCoverPosition.lineNumber *
        this.basic.buildingNumber;

      this.normalPad_padConnectionPin.designQuantity = this.normalPad_normalPad.getSelectedQuantity();

      this.normalPad_barPad.designQuantity =
        (Math.ceil(skylightLength / rackpinionInterval) + 1) *
        skySwitchPosition.switchWayValue *
        this.basic.buildingNumber;

      if (
        skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
        skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
      ) {
        const windowEdge = skylightLength * skySwitchPosition.switchWayValue * this.basic.buildingNumber;
        const windowBar = this.normalPad_barPad.getSelectedQuantity() * this.normalPad_barPad.specLength;

        this.normalPad_normalPadSpring.designQuantity =
          ((windowEdge + windowBar) / this.normalPad_normalPadSpring.specLength) * CONST.NUM_EXTRA_RATE_PAD_SPRING;
        this.normalPad_padVinyl.designQuantity = (windowEdge + windowBar) / this.normalPad_padVinyl.specLength;
      } else {
        this.normalPad_normalPadSpring.designQuantity =
          this.normalPad_normalPad.getSelectedQuantity() *
          (this.normalPad_normalPad.specLength / this.normalPad_normalPadSpring.specLength) *
          CONST.NUM_EXTRA_RATE_PAD_SPRING;
        this.normalPad_padVinyl.designQuantity =
          (this.normalPad_normalPad.getSelectedQuantity() * this.normalPad_normalPad.specLength) /
          this.normalPad_padVinyl.specLength;
      }

      if (skyCoverPosition.screennetSelected === true) {
        this.normalPad_normalPadSpring.designQuantity +=
          (Math.ceil(skylightLength / this.normalPad_normalPadSpring.specLength) *
            2 *
            skySwitchPosition.switchWayValue) /
          2;
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 벤로형인 경우
      const rafterValleyQuantity: number = this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterValleyQuantity;
      this.normalPad_normalPad.designQuantity =
        Math.ceil(skylightLength / this.normalPad_normalPad.specLength) *
        skyCoverPosition.lineNumber *
        this.basic.buildingNumber *
        rafterValleyQuantity;

      this.normalPad_padConnectionPin.designQuantity = this.normalPad_normalPad.getSelectedQuantity();

      this.normalPad_barPad.designQuantity =
        (Math.ceil(skylightLength / rackpinionInterval) + 1) *
        skySwitchPosition.switchWayValue *
        this.basic.buildingNumber *
        rafterValleyQuantity;

      if (
        skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
        skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
      ) {
        const windowEdge =
          skylightLength * skySwitchPosition.switchWayValue * this.basic.buildingNumber * rafterValleyQuantity;
        const windowBar = this.normalPad_barPad.getSelectedQuantity() * this.normalPad_barPad.specLength;

        this.normalPad_normalPadSpring.designQuantity =
          ((windowEdge + windowBar) / this.normalPad_normalPadSpring.specLength) * CONST.NUM_EXTRA_RATE_PAD_SPRING;
        this.normalPad_padVinyl.designQuantity = (windowEdge + windowBar) / this.normalPad_padVinyl.specLength;
      }
      if (skyCoverPosition.screennetSelected === true) {
        this.normalPad_normalPadSpring.designQuantity +=
          (Math.ceil(skylightLength / this.normalPad_normalPadSpring.specLength) *
            2 *
            skySwitchPosition.switchWayValue) /
          2;
      }
    }

    this.normalPad_screw.designQuantity =
      ((this.normalPad_normalPad.getSelectedQuantity() * 6 +
        this.normalPad_barPad.getSelectedQuantity() * 2 +
        this.normalPad_padConnectionPin.getSelectedQuantity() * 2) /
        this.normalPad_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 패드 고정] 일반 패드 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPad_normalPad(): void {
    this.normalPad_normalPad.specs = this.normalPadSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 패드 고정] 일반 패드 스프링 <- 일반 패드 스프링 샘플(피복 고정>기둥(측면))
   */
  public algorithmSpec_normalPad_normalPadSpring(): void {
    this.normalPad_normalPadSpring.specMaterial =
      this.struct.fixingWorkIL.level1.columnPosition.normalPadPart.normalPadSpringSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [일반 패드 고정] 패드 연결핀 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPad_padConnectionPin(): void {
    this.normalPad_padConnectionPin.specMaterial = this.normalPadSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [일반 패드 고정] 패드 비닐 <- 공통 샘플
   */
  public algorithmSpec_normalPad_padVinyl(): void {}

  /**
   * 규격 알고리즘: [일반 패드 고정] 나사 <- 공통 샘플
   */
  public algorithmSpec_normalPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
