import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-28
 */
@jsonObject
export class TrunkSample_RafterExtensionPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    rafterPart.algorithmSpec_extensionNormal_pipe();
    rafterPart.algorithmSpec_extension_pipeBendingExpense();
    rafterPart.algorithmSpec_extensionNormal_connectionPin();
    rafterPart.algorithmSpec_extensionColumn_pipe();
    rafterPart.algorithmSpec_extensionBeam_pipe();
    rafterPart.algorithmSpec_extensionBeam_connectionPin();
    rafterPart.algorithmSpec_extensionBeamAndFrame_saddleHolder();
    rafterPart.algorithmSpec_extensionBeamAndColumn_tHolder();
    rafterPart.algorithmSpec_extensionBeamAndColumn_tClamp();
    rafterPart.algorithmSpec_extensionBeamAndRafter_tHolder();
    rafterPart.algorithmSpec_extensionBeamAndRafter_firstLevelSoleSconce();
    rafterPart.algorithmSpec_extensionBeamAndRafter_secondLevelSoleSconce();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 중수
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.trunkWork.levelAC.length; l++) {
        (<TrunkLevel>(
          this.struct.trunkWork.levelAC[l]
        )).trunkPosition.rafterPart.rafterExtensionPipeSample.algorithmSpec_level();
      }
    }

    /// //////// 외부 ///////////
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 서까래 파이프 샘플, 확장 형태, 확장 길이
   */
  // @override
  public algorithmSpec(): void {
    if ((<TrunkPart_Rafter>this.part).extensionType !== CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM) {
      this.specsList = (<TrunkPart_Rafter>this.part).rafterPipeSample.specsList;
      this.specLength = (<TrunkPart_Rafter>this.part).extensionLength;
    }
  }

  /**
   * 규격 알고리즘 <- 서까래 확장 파이프 샘플(중수), 확장 형태
   */
  public algorithmSpec_level(): void {
    if ((<TrunkPart_Rafter>this.part).extensionType === CONST.LB_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM) {
      if (this.level.index !== 0) {
        this.specsList = this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterExtensionPipeSample.specsList;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
