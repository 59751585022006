import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Level } from "vhows-design/src/object/design/base/Level";
import { EndpiecePosition } from "vhows-design/src/object/design/frame/endpiece/EndpiecePosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-01
 */
@jsonObject({
  knownTypes: [EndpiecePosition],
})
export class EndpieceLevel extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public frontPosition: EndpiecePosition;
  public backPosition: EndpiecePosition;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.frontPosition = new EndpiecePosition();
    this.backPosition = new EndpiecePosition();

    this.positionAC = [this.frontPosition, this.backPosition];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.frontPosition = <EndpiecePosition>this.positionAC[0];
    this.backPosition = <EndpiecePosition>this.positionAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index === 0) {
      this.frontPosition.setDefaultData(
        0,
        true,
        true,
        true,
        CONST.LB_POSITION_FRONT,
        CONST.LB_ENDPIECE_TYPE_WHOLE,
        CONST.LB_GATE_TYPE_SLIDING,
      );
      this.backPosition.setDefaultData(
        1,
        true,
        true,
        true,
        CONST.LB_POSITION_BACK,
        CONST.LB_ENDPIECE_TYPE_WHOLE,
        CONST.LB_GATE_TYPE_SLIDING,
      );
    } else if (index === 1 || index === 2 || index === 3) {
      this.frontPosition.setDefaultData(
        0,
        true,
        true,
        true,
        CONST.LB_POSITION_FRONT,
        CONST.LB_ENDPIECE_TYPE_WHOLE,
        CONST.LB_GATE_TYPE_FRAMED,
      );
      this.backPosition.setDefaultData(
        1,
        true,
        true,
        true,
        CONST.LB_POSITION_BACK,
        CONST.LB_ENDPIECE_TYPE_WHOLE,
        CONST.LB_GATE_TYPE_FRAMED,
      );
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
