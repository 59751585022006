import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 패드 연결구
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-01
 */
@jsonObject
export class ItemPadConnector extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [재질]
  public _specMaterial: string = undefined; // 재질

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 재질
   */
  public get specMaterial(): string {
    return this._specMaterial;
  }
  //
  public set specMaterial(value: string) {
    if (
      value === CONST.ITEM_SPEC_VALUE_COLOR_IRON ||
      value === CONST.ITEM_SPEC_VALUE_ALUMINUM ||
      value === CONST.ITEM_SPEC_VALUE_PLASTIC
    ) {
      this._specMaterial = value;
    } else {
      this._specMaterial = CONST.ITEM_SPEC_VALUE_COLOR_IRON;
    }

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.padConnectorCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  public setSelectedByPad(value: boolean, padSpecMaterial: string): void {
    if (this.part == null) {
      this.selected = value;
      return;
    }
    // 패드 재질 및 설게 설정에 따른 선택 변경
    if (padSpecMaterial === CONST.ITEM_SPEC_VALUE_ZINC) {
      if (this.part.design.preference.pinWorkedNormalPadZincFlag === true) {
        this.selected = false;
      } else {
        this.selected = value;
      }
    } else if (padSpecMaterial === CONST.ITEM_SPEC_VALUE_COLOR_IRON) {
      if (this.part.design.preference.pinWorkedNormalPadColorIronFlag === true) {
        this.selected = false;
      } else {
        this.selected = value;
      }
    } else if (padSpecMaterial === CONST.ITEM_SPEC_VALUE_ALUMINUM) {
      this.selected = value;
    } else if (padSpecMaterial === CONST.ITEM_SPEC_VALUE_CORROSION) {
      this.selected = false;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specMaterial = specsArray[0];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specMaterial = specsArray[0];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specMaterial;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }
}
