import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { ProductModel } from "vhows-design/src/object/item/product/ProductModel";
import { SpecCompanyModel } from "vhows-design/src/object/item/spec/SpecCompanyModel";
import { OptionSpecModel } from "vhows-design/src/object/item/spec/option/OptionSpecModel";
import { NumericSpecModel } from "vhows-design/src/object/item/spec/numeric/NumericSpecModel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-26
 */
@jsonObject({
  knownTypes: [ProductModel, NumericSpecModel, OptionSpecModel],
})
export class SpecModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 상품 모델 */
  @jsonMember(ProductModel)
  public productModel: ProductModel = null;
  /** 조직별 규격 모델 배열 */
  public specCompanyModelAC: SpecCompanyModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 수치형 규격1 */
  @jsonMember(NumericSpecModel)
  public numSpec1: NumericSpecModel = null;
  /** 수치형 규격2 */
  @jsonMember(NumericSpecModel)
  public numSpec2: NumericSpecModel = null;
  /** 수치형 규격3 */
  @jsonMember(NumericSpecModel)
  public numSpec3: NumericSpecModel = null;
  /** 수치형 규격4 */
  @jsonMember(NumericSpecModel)
  public numSpec4: NumericSpecModel = null;
  /** 수치형 규격5 */
  @jsonMember(NumericSpecModel)
  public numSpec5: NumericSpecModel = null;
  /** 수치형 규격6 */
  @jsonMember(NumericSpecModel)
  public numSpec6: NumericSpecModel = null;
  /** 수치형 규격7 */
  @jsonMember(NumericSpecModel)
  public numSpec7: NumericSpecModel = null;

  /** 옵형 규격1 배열 */
  @jsonArrayMember(OptionSpecModel)
  public optionSpec1: OptionSpecModel[] = null;
  /** 옵션형 규격2 배열 */
  @jsonArrayMember(OptionSpecModel)
  public optionSpec2: OptionSpecModel[] = null;
  /** 옵션형 규격3 배열 */
  @jsonArrayMember(OptionSpecModel)
  public optionSpec3: OptionSpecModel[] = null;
  /** 옵션형 규격4 배열 */
  @jsonArrayMember(OptionSpecModel)
  public optionSpec4: OptionSpecModel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 인덱스 */
  @jsonMember(Number)
  public index: number = null;

  /** 규격 리스트 */
  @jsonMember(String)
  public label: string = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param specsList 규격 리스트
   * @param productModel 상품
   * @param specCompanyModelAC 조직별 규격 모델 배열
   * @param numSpec1 수치형 규격1
   * @param numSpec2 수치형 규격2
   * @param numSpec3 수치형 규격3
   * @param numSpec4 수치형 규격4
   * @param numSpec5 수치형 규격5
   * @param numSpec6 수치형 규격6
   * @param numSpec7 수치형 규격7
   * @param optionSpec1 옵션 규격1 배열
   * @param optionSpec2 옵션 규격2 배열
   * @param optionSpec3 옵션 규격3 배열
   * @param optionSpec4 옵션 규격4 배열
   */
  constructor(
    specsList: string = "",
    productModel: ProductModel = null,
    specCompanyModelAC: SpecCompanyModel[] = null,
    numSpec1: NumericSpecModel = null,
    numSpec2: NumericSpecModel = null,
    numSpec3: NumericSpecModel = null,
    numSpec4: NumericSpecModel = null,
    numSpec5: NumericSpecModel = null,
    numSpec6: NumericSpecModel = null,
    numSpec7: NumericSpecModel = null,
    optionSpec1: OptionSpecModel[] = null,
    optionSpec2: OptionSpecModel[] = null,
    optionSpec3: OptionSpecModel[] = null,
    optionSpec4: OptionSpecModel[] = null,
  ) {
    super();

    this.label = specsList;
    this.productModel = productModel;
    this.specCompanyModelAC = specCompanyModelAC;
    this.numSpec1 = numSpec1;
    this.numSpec2 = numSpec2;
    this.numSpec3 = numSpec3;
    this.numSpec4 = numSpec4;
    this.numSpec5 = numSpec5;
    this.numSpec6 = numSpec6;
    this.numSpec7 = numSpec7;
    this.optionSpec1 = optionSpec1;
    this.optionSpec2 = optionSpec2;
    this.optionSpec3 = optionSpec3;
    this.optionSpec4 = optionSpec4;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /** 규격 타입 메타에 따른 규격 초기 값 셋팅 */
  public setDefaultVariables(div, seq) {
    if (div === "num") {
      this[`numSpec${seq}`] = new NumericSpecModel();
    } else if (div === "option") {
      this[`optionSpec${seq}`] = Array();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
