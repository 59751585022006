import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";
import { SkyFrameSampleIL_StraightPipe } from "vhows-design/src/object/design/skylight/frame/il/SkyFrameSampleIL_StraightPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-22
 */
@jsonObject({
  knownTypes: [SkyFrameSampleIL_StraightPipe],
})
export class SkyFramePartIL_Straight extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public skyStraightPipeSample: SkyFrameSampleIL_StraightPipe; // 천창 가로대 파이프 샘플

  // 아이템
  public skyStraight_pipe: ItemPipe;
  public skyStraightAndSkyStraight_connectionPin: ItemConnector;
  public skyStraightAndSkyRafter_steelStringGrabber: ItemHolder;
  public skyStraightAndSkyRafter_saddleHolder: ItemHolder;
  public skyStraightAndSkyRafter_tHolder: ItemHolder;
  public skyStraight_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _straightLength: number; // 가로대 길이
  @jsonMember(Number)
  public _straightLineNumber: number; // 가로대 줄수

  /**
   * 가로대 길이
   */
  public get straightLength(): number {
    return this._straightLength;
  }

  //
  public set straightLength(value: number) {
    this._straightLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 가로대 줄수
   */
  public get straightLineNumber(): number {
    return this._straightLineNumber;
  }

  //
  public set straightLineNumber(value: number) {
    this._straightLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.skyStraightPipeSample = new SkyFrameSampleIL_StraightPipe();

    this.sampleAC = [this.skyStraightPipeSample];

    // 아이템
    this.skyStraight_pipe = new ItemPipe();
    this.skyStraightAndSkyStraight_connectionPin = new ItemConnector();
    this.skyStraightAndSkyRafter_steelStringGrabber = new ItemHolder();
    this.skyStraightAndSkyRafter_saddleHolder = new ItemHolder();
    this.skyStraightAndSkyRafter_tHolder = new ItemHolder();
    this.skyStraight_screw = new ItemScrew();

    this.itemAC = [
      this.skyStraight_pipe,
      this.skyStraightAndSkyStraight_connectionPin,
      this.skyStraightAndSkyRafter_steelStringGrabber,
      this.skyStraightAndSkyRafter_saddleHolder,
      this.skyStraightAndSkyRafter_tHolder,
      this.skyStraight_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.skyStraightPipeSample = <SkyFrameSampleIL_StraightPipe>this.sampleAC[0];

    // 아이템
    this.skyStraight_pipe = <ItemPipe>this.itemAC[0];
    this.skyStraightAndSkyStraight_connectionPin = <ItemConnector>this.itemAC[1];
    this.skyStraightAndSkyRafter_steelStringGrabber = <ItemHolder>this.itemAC[2];
    this.skyStraightAndSkyRafter_saddleHolder = <ItemHolder>this.itemAC[3];
    this.skyStraightAndSkyRafter_tHolder = <ItemHolder>this.itemAC[4];
    this.skyStraight_screw = <ItemScrew>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param straightLineNumber: number 가로대 줄수 (straightLineNumber)
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    straightLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._straightLineNumber = straightLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.skyStraightPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.skyStraight_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 가로대",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.skyStraightAndSkyStraight_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "천창 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.skyStraightAndSkyRafter_steelStringGrabber.setDefaultData(
        2,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "천창 가로대 + 천창 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.skyStraightAndSkyRafter_saddleHolder.setDefaultData(
        3,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "천창 가로대(끝) + 천창 서까래",
        "원형, 일반, 25mm, -",
      );
      this.skyStraightAndSkyRafter_tHolder.setDefaultData(
        4,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "천창 가로대 + 천창 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.skyStraight_screw.setDefaultData(
        5,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "천창 가로대",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_straightLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skyFramePosition: SkyFramePositionIL = <SkyFramePositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.skyStraight_pipe.checkSelected(true);
    this.skyStraightAndSkyStraight_connectionPin.checkSelected(true);
    this.skyStraightAndSkyRafter_steelStringGrabber.selected = true;
    this.skyStraightAndSkyRafter_steelStringGrabber.visible = true;
    this.skyStraightAndSkyRafter_saddleHolder.selected = true;
    this.skyStraightAndSkyRafter_saddleHolder.visible = true;
    if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.skyStraightAndSkyRafter_tHolder.selected = true;
      this.skyStraightAndSkyRafter_tHolder.visible = true;
    } else {
      this.skyStraightAndSkyRafter_tHolder.selected = false;
      this.skyStraightAndSkyRafter_tHolder.visible = false;
    }
    this.skyStraight_screw.selected = true;
    this.skyStraight_screw.visible = true;

    /// //////// 수량 ///////////

    this.skyStraight_pipe.designQuantity =
      (this.skyStraight_pipe.getConnectedPipeLength(
        this.straightLength,
        this.straightLineNumber * this.basic.buildingNumber,
      ) /
        this.skyStraight_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    if (this.straightLength > this.skyStraight_pipe.specLength) {
      this.skyStraightAndSkyStraight_connectionPin.designQuantity =
        this.skyStraight_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.skyStraightAndSkyStraight_connectionPin.designQuantity = 0;
    }

    if (
      this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
    ) {
      this.skyStraightAndSkyRafter_steelStringGrabber.designQuantity =
        this.straightLineNumber *
        (skyFramePosition.rafterPart.rafterQuantity - 2) *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

      this.skyStraightAndSkyRafter_saddleHolder.designQuantity =
        this.straightLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
    } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.skyStraightAndSkyRafter_steelStringGrabber.designQuantity =
        (this.straightLineNumber - 1) *
        (skyFramePosition.rafterPart.rafterQuantity - 2) *
        CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

      this.skyStraightAndSkyRafter_saddleHolder.designQuantity =
        (this.straightLineNumber - 1) * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
    }

    this.skyStraightAndSkyRafter_tHolder.designQuantity =
      1 * skyFramePosition.rafterPart.rafterQuantity * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.skyStraight_screw.designQuantity =
      ((this.skyStraightAndSkyStraight_connectionPin.getSelectedQuantity() * 2 +
        this.skyStraightAndSkyRafter_saddleHolder.getSelectedQuantity() * 3 +
        this.skyStraightAndSkyRafter_tHolder.getSelectedQuantity() * 3) /
        this.skyStraight_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 가로대 길이 <- 길이(기본정보)
   */
  public algorithm_straightLength(): void {
    const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;

    this.straightLength = skyFramePosition.skylightLength + this.design.preference.connectedPipeExtraLength;
  }

  /**
   * 알고리즘: 가로대 줄수 <- 형태(위치)
   */
  public algorithm_straightLineNumber(): void {
    if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH) {
      this.straightLineNumber = 3;
    } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE) {
      this.straightLineNumber = 1;
    } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.straightLineNumber = 2;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 가로대] 파이프 <- 천창 가로대 파이프 샘플
   */
  public algorithmSpec_skyStraight_pipe(): void {
    this.skyStraight_pipe.specs = this.skyStraightPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 가로대] 연결핀 <- 천창 가로대 파이프 샘플
   */
  public algorithmSpec_skyStraightAndSkyStraight_connectionPin(): void {
    this.skyStraightAndSkyStraight_connectionPin.specPipeType = this.skyStraightPipeSample.specPipeType;
    this.skyStraightAndSkyStraight_connectionPin.specCrossSize1 = this.skyStraightPipeSample.specCrossSize;
    this.skyStraightAndSkyStraight_connectionPin.specCrossSize2 = this.skyStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 가로대 + 천창 서까래] 강선 조리개 <- 천창 가로대 파이프 샘플, 천창 서까래 파이프 샘플
   */
  public algorithmSpec_skyStraightAndSkyRafter_steelStringGrabber(): void {
    this.skyStraightAndSkyRafter_steelStringGrabber.specPipeType = this.skyStraightPipeSample.specPipeType;
    this.skyStraightAndSkyRafter_steelStringGrabber.specCrossSize1 = this.skyStraightPipeSample.specCrossSize;
    this.skyStraightAndSkyRafter_steelStringGrabber.specCrossSize2 = (<SkyFramePositionIL>(
      this.position
    )).rafterPart.skyRafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 가로대(끝) + 천창 서까래] 새들 고정구 <- 천창 가로대 파이프 샘플
   */
  public algorithmSpec_skyStraightAndSkyRafter_saddleHolder(): void {
    this.skyStraightAndSkyRafter_saddleHolder.specPipeType = this.skyStraightPipeSample.specPipeType;
    this.skyStraightAndSkyRafter_saddleHolder.specCrossSize1 = this.skyStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 가로대 + 천창 서까래] T 고정구 <- 천창 가로대 파이프 샘플, 천창 서까래 파이프 샘플
   */
  public algorithmSpec_skyStraightAndSkyRafter_tHolder(): void {
    this.skyStraightAndSkyRafter_tHolder.specPipeType = this.skyStraightPipeSample.specPipeType;
    this.skyStraightAndSkyRafter_tHolder.specCrossSize1 = this.skyStraightPipeSample.specCrossSize;
    this.skyStraightAndSkyRafter_tHolder.specCrossSize2 = (<SkyFramePositionIL>(
      this.position
    )).rafterPart.skyRafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 가로대] 나사 <- 공통 샘플
   */
  public algorithmSpec_skyStraight_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
