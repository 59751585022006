import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { WateringPart_MiniklerScreen } from "vhows-design/src/object/design/watering/watering/WateringPart_MiniklerScreen";
import { WateringPart_DripConnect } from "vhows-design/src/object/design/watering/watering/WateringPart_DripConnect";
import { WateringPart_DripWatering } from "vhows-design/src/object/design/watering/watering/WateringPart_DripWatering";
import { WateringPart_HoseConnect } from "vhows-design/src/object/design/watering/watering/WateringPart_HoseConnect";
import { WateringPart_HoseLower } from "vhows-design/src/object/design/watering/watering/WateringPart_HoseLower";
import { WateringPart_HoseMulching } from "vhows-design/src/object/design/watering/watering/WateringPart_HoseMulching";
import { WateringPart_HoseScreen } from "vhows-design/src/object/design/watering/watering/WateringPart_HoseScreen";
import { WateringPart_HoseSide } from "vhows-design/src/object/design/watering/watering/WateringPart_HoseSide";
import { WateringPart_HoseUpper } from "vhows-design/src/object/design/watering/watering/WateringPart_HoseUpper";
import { WateringPart_MiniklerFog } from "vhows-design/src/object/design/watering/watering/WateringPart_MiniklerFog";
import { WateringPart_MiniklerFountain } from "vhows-design/src/object/design/watering/watering/WateringPart_MiniklerFountain";
import { WateringPart_ScreenGutter } from "vhows-design/src/object/design/watering/watering/WateringPart_ScreenGutter";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject({
  knownTypes: [
    WateringPart_DripConnect,
    WateringPart_DripWatering,
    WateringPart_HoseConnect,
    WateringPart_HoseLower,
    WateringPart_HoseMulching,
    WateringPart_HoseScreen,
    WateringPart_HoseSide,
    WateringPart_HoseUpper,
    WateringPart_MiniklerFog,
    WateringPart_MiniklerFountain,
    WateringPart_MiniklerScreen,
    WateringPart_ScreenGutter,
  ],
})
export class WateringPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public miniklerScreenPart: WateringPart_MiniklerScreen;
  public miniklerFountainPart: WateringPart_MiniklerFountain;
  public miniklerFogPart: WateringPart_MiniklerFog;
  public hoseScreenPart: WateringPart_HoseScreen;
  public hoseUpperPart: WateringPart_HoseUpper;
  public hoseSidePart: WateringPart_HoseSide;
  public hoseLowerPart: WateringPart_HoseLower;
  public hoseMulchingPart: WateringPart_HoseMulching;
  public hoseConnectPart: WateringPart_HoseConnect;
  public dripWateringPart: WateringPart_DripWatering;
  public dripConnectPart: WateringPart_DripConnect;
  public screenGutterPart: WateringPart_ScreenGutter;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _upperType: string; // 상단 형태
  @jsonMember(String)
  public _lowerType: string; // 하단 형태
  @jsonMember(String)
  public _dripType: string; // 점적 형태
  @jsonMember(Boolean)
  public _fogSelected: boolean; // 안개 선택
  @jsonMember(Boolean)
  public _screenGutterSelected: boolean; // 수막 물받이 선택

  /**
   * 상단 형태
   */
  public get upperType(): string {
    return this._upperType;
  }

  //
  public set upperType(value: string) {
    this._upperType = value;

    // 알고리즘
    this.hoseConnectPart.algorithmPart();

    // 파트 활성화
    this.algorithm_partActivationByUpperType();

    // 알고리즘
    this.hoseConnectPart.algorithm_subPipeLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 관수 제어부
    this.struct.mainpipeWork.level1.wholePosition.algorithm_upperFrameSelected();
    this.struct.mainpipeWork.level1.wholePosition.peMain2Part.algorithm_main2Length();
  }

  /**
   * 하단 형태
   */
  public get lowerType(): string {
    return this._lowerType;
  }

  //
  public set lowerType(value: string) {
    this._lowerType = value;

    // 알고리즘
    this.hoseConnectPart.algorithmPart();

    // 파트 활성화
    this.algorithm_partActivationByLowerType();

    // 알고리즘
    this.hoseConnectPart.algorithm_subPipeLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 관수 제어부
    this.struct.mainpipeWork.level1.wholePosition.peMain2Part.algorithm_main2Length();
  }

  /**
   * 점적 형태
   */
  public get dripType(): string {
    return this._dripType;
  }

  //
  public set dripType(value: string) {
    this._dripType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByDripType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 관수 제어부
    this.struct.mainpipeWork.level1.wholePosition.peMain2Part.algorithm_main2Length();
  }

  /**
   * 안개 선택
   */
  public get fogSelected(): boolean {
    return this._fogSelected;
  }

  //
  public set fogSelected(value: boolean) {
    this._fogSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFogSelected();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 관수 제어부
    this.struct.mainpipeWork.level1.wholePosition.peMain2Part.algorithm_main2Length();
  }

  /**
   * 수막 물받이 선택
   */
  public get screenGutterSelected(): boolean {
    return this._screenGutterSelected;
  }

  //
  public set screenGutterSelected(value: boolean) {
    this._screenGutterSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByScreenGutterSelected();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_screenGutterSelected();
    this.hoseConnectPart.algorithmPart();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 알고리즘
    this.hoseConnectPart.algorithm_subPipeLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 관수 제어부
    this.struct.mainpipeWork.level1.wholePosition.peMain2Part.algorithm_main2Length();
    this.struct.mainpipeWork.level1.wholePosition.peMain2Part.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.miniklerScreenPart = new WateringPart_MiniklerScreen();
    this.miniklerFountainPart = new WateringPart_MiniklerFountain();
    this.miniklerFogPart = new WateringPart_MiniklerFog();
    this.hoseScreenPart = new WateringPart_HoseScreen();
    this.hoseUpperPart = new WateringPart_HoseUpper();
    this.hoseSidePart = new WateringPart_HoseSide();
    this.hoseLowerPart = new WateringPart_HoseLower();
    this.hoseMulchingPart = new WateringPart_HoseMulching();
    this.hoseConnectPart = new WateringPart_HoseConnect();
    this.dripWateringPart = new WateringPart_DripWatering();
    this.dripConnectPart = new WateringPart_DripConnect();
    this.screenGutterPart = new WateringPart_ScreenGutter();

    this.partAC = [
      this.miniklerScreenPart,
      this.miniklerFountainPart,
      this.miniklerFogPart,
      this.hoseScreenPart,
      this.hoseUpperPart,
      this.hoseSidePart,
      this.hoseLowerPart,
      this.hoseMulchingPart,
      this.hoseConnectPart,
      this.dripWateringPart,
      this.dripConnectPart,
      this.screenGutterPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.miniklerScreenPart = <WateringPart_MiniklerScreen>this.partAC[0];
    this.miniklerFountainPart = <WateringPart_MiniklerFountain>this.partAC[1];
    this.miniklerFogPart = <WateringPart_MiniklerFog>this.partAC[2];
    this.hoseScreenPart = <WateringPart_HoseScreen>this.partAC[3];
    this.hoseUpperPart = <WateringPart_HoseUpper>this.partAC[4];
    this.hoseSidePart = <WateringPart_HoseSide>this.partAC[5];
    this.hoseLowerPart = <WateringPart_HoseLower>this.partAC[6];
    this.hoseMulchingPart = <WateringPart_HoseMulching>this.partAC[7];
    this.hoseConnectPart = <WateringPart_HoseConnect>this.partAC[8];
    this.dripWateringPart = <WateringPart_DripWatering>this.partAC[9];
    this.dripConnectPart = <WateringPart_DripConnect>this.partAC[10];
    this.screenGutterPart = <WateringPart_ScreenGutter>this.partAC[11];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param upperType: string 상단 형태
   * @param lowerType: string 하단 형태
   * @param dripType: string 점적 형태
   * @param fogSelected: string 안개 선택
   * @param screenGutterSelected: boolean 수막 물받이 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    upperType: string = "",
    lowerType: string = "",
    dripType: string = "",
    fogSelected: boolean = false,
    screenGutterSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._upperType = upperType;
    this._lowerType = lowerType;
    this._dripType = dripType;
    this._fogSelected = fogSelected;
    this._screenGutterSelected = screenGutterSelected;

    if (this.level.index >= 0) {
      // 수막 미니클러
      this.miniklerScreenPart.setDefaultData(
        CONST.INDEX_CM_WATERING_WATERING_MINIKLER_SCREEN,
        false,
        false,
        false,
        CONST.LB_WATERING_WATERING_MINIKLER_SCREEN,
        "",
        1,
        1.0,
        CONST.LB_WATERING_MINIKLER_PIPE_FIX_TYPE_HANGER_SPRING,
      );

      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        // 분수 미니클러
        this.miniklerFountainPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_MINIKLER_FOUNTAIN,
          false,
          false,
          false,
          CONST.LB_WATERING_WATERING_MINIKLER_FOUNTAIN,
          "",
          2,
          1.5,
          CONST.LB_WATERING_MINIKLER_PIPE_FIX_TYPE_HANGER_SPRING,
          true,
        );
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        // 분수 미니클러
        this.miniklerFountainPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_MINIKLER_FOUNTAIN,
          true,
          false,
          true,
          CONST.LB_WATERING_WATERING_MINIKLER_FOUNTAIN,
          "",
          2,
          1.5,
          CONST.LB_WATERING_MINIKLER_PIPE_FIX_TYPE_HANGER_SPRING,
          true,
        );
      }

      // 안개(포그) 미니클러
      this.miniklerFogPart.setDefaultData(
        CONST.INDEX_CM_WATERING_WATERING_MINIKLER_FOG,
        false,
        false,
        false,
        CONST.LB_WATERING_WATERING_MINIKLER_FOG,
        "",
        1,
        1.5,
        CONST.LB_WATERING_MINIKLER_PIPE_FIX_TYPE_HANGER_SPRING,
      );

      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        // 수막 분수호스
        this.hoseScreenPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_HOSE_SCREEN,
          true,
          false,
          true,
          CONST.LB_WATERING_WATERING_HOSE_SCREEN,
          "",
          1,
          0.5,
        );

        // 고설 분수호스
        this.hoseUpperPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_HOSE_UPPER,
          true,
          false,
          true,
          CONST.LB_WATERING_WATERING_HOSE_UPPER,
          "",
          2,
          0.5,
        );
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        // 수막 분수호스
        this.hoseScreenPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_HOSE_SCREEN,
          false,
          false,
          false,
          CONST.LB_WATERING_WATERING_HOSE_SCREEN,
          "",
          1,
          0.5,
        );

        // 고설 분수호스
        this.hoseUpperPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_HOSE_UPPER,
          false,
          false,
          false,
          CONST.LB_WATERING_WATERING_HOSE_UPPER,
          "",
          3,
          0.5,
        );
      }

      // 측설 분수호스
      this.hoseSidePart.setDefaultData(
        CONST.INDEX_CM_WATERING_WATERING_HOSE_SIDE,
        false,
        false,
        false,
        CONST.LB_WATERING_WATERING_HOSE_SIDE,
        "",
        2,
        1.4,
      );

      // 저설 분수호스
      this.hoseLowerPart.setDefaultData(
        CONST.INDEX_CM_WATERING_WATERING_HOSE_LOWER,
        false,
        false,
        false,
        CONST.LB_WATERING_WATERING_HOSE_LOWER,
        "",
        2,
      );

      // 관수(멀칭) 분수호스
      this.hoseMulchingPart.setDefaultData(
        CONST.INDEX_CM_WATERING_WATERING_HOSE_MULCHING,
        false,
        false,
        false,
        CONST.LB_WATERING_WATERING_HOSE_MULCHING,
        "",
        2,
      );

      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        // 분수호스 통합연결
        this.hoseConnectPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_HOSE_CONNECT,
          true,
          false,
          true,
          CONST.LB_WATERING_WATERING_HOSE_CONNECT,
          "",
        );
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        // 분수호스 통합연결
        this.hoseConnectPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_HOSE_CONNECT,
          false,
          false,
          false,
          CONST.LB_WATERING_WATERING_HOSE_CONNECT,
          "",
        );
      }

      // 점적관수
      this.dripWateringPart.setDefaultData(
        CONST.INDEX_CM_WATERING_WATERING_DRIP_WATERING,
        false,
        false,
        false,
        CONST.LB_WATERING_WATERING_DRIP_WATERING,
        "",
        CONST.LB_WATERING_DRIP_PIPE_TYPE_DRIP_TAPE,
        6,
        0.5,
        1.0,
      );

      // 점적관수 연결
      this.dripConnectPart.setDefaultData(
        CONST.INDEX_CM_WATERING_WATERING_DRIP_CONNECT,
        false,
        false,
        false,
        CONST.LB_WATERING_WATERING_DRIP_CONNECT,
        "",
        CONST.LB_WATERING_DRIP_CONNECT_TYPE_SADDLE_BARB_LINE_VALVE,
        CONST.LB_WATERING_DRIP_BARB_MAIN_TYPE_3_HOLE,
        CONST.ITEM_SPEC_VALUE_20_MM,
        2,
      );

      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        // 수막 물받이
        this.screenGutterPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_SCREEN_GUTTER,
          true,
          false,
          true,
          CONST.LB_WATERING_WATERING_SCREEN_GUTTER,
          "",
          CONST.LB_WATERING_SCREEN_GUTTER_TYPE_WEAVING_FILM,
        );
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        // 수막 물받이
        this.screenGutterPart.setDefaultData(
          CONST.INDEX_CM_WATERING_WATERING_SCREEN_GUTTER,
          false,
          false,
          false,
          CONST.LB_WATERING_WATERING_SCREEN_GUTTER,
          "",
          CONST.LB_WATERING_SCREEN_GUTTER_TYPE_WEAVING_FILM,
        );
      }
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 선택 <- 선택(주관), 형태(주관)
   */
  public algorithm_selected(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;

    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_CUSTOM) {
      this.selected = false;
    } else {
      this.selected = mainpipePosition.selected;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_WATERING_TYPE_SCREEN_MINIKLER) {
      this.miniklerScreenPart.selected = true;
      this.miniklerScreenPart.visible = true;
      this.hoseScreenPart.selected = false;
      this.hoseScreenPart.visible = false;
    } else if (this.type === CONST.LB_WATERING_TYPE_SCREEN_FOUNTAIN_HOSE) {
      this.miniklerScreenPart.selected = false;
      this.miniklerScreenPart.visible = false;
      this.hoseScreenPart.selected = true;
      this.hoseScreenPart.visible = true;
    } else {
      this.miniklerScreenPart.selected = false;
      this.miniklerScreenPart.visible = false;
      this.hoseScreenPart.selected = false;
      this.hoseScreenPart.visible = false;
    }
    this.algorithm_partActivationByFountainHose();
  }

  /**
   * 알고리즘: 파트 활성화 <- 상단 형태
   */
  public algorithm_partActivationByUpperType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.upperType === CONST.LB_WATERING_UPPER_TYPE_FOUNTAIN_MINIKLER) {
      this.miniklerFountainPart.selected = true;
      this.miniklerFountainPart.visible = true;
      this.hoseUpperPart.selected = false;
      this.hoseUpperPart.visible = false;
      this.hoseSidePart.selected = false;
      this.hoseSidePart.visible = false;
    } else if (this.upperType === CONST.LB_WATERING_UPPER_TYPE_UPPER_FOUNTAIN_HOSE) {
      this.miniklerFountainPart.selected = false;
      this.miniklerFountainPart.visible = false;
      this.hoseUpperPart.selected = true;
      this.hoseUpperPart.visible = true;
      this.hoseSidePart.selected = false;
      this.hoseSidePart.visible = false;
    } else if (this.upperType === CONST.LB_WATERING_UPPER_TYPE_UPPER_SIDE_FOUNTAIN_HOSE) {
      this.miniklerFountainPart.selected = false;
      this.miniklerFountainPart.visible = false;
      this.hoseUpperPart.selected = true;
      this.hoseUpperPart.visible = true;
      this.hoseSidePart.selected = true;
      this.hoseSidePart.visible = true;
    } else {
      this.miniklerFountainPart.selected = false;
      this.miniklerFountainPart.visible = false;
      this.hoseUpperPart.selected = false;
      this.hoseUpperPart.visible = false;
      this.hoseSidePart.selected = false;
      this.hoseSidePart.visible = false;
    }
    this.algorithm_partActivationByFountainHose();
  }

  /**
   * 알고리즘: 파트 활성화 <- 하단 형태
   */
  public algorithm_partActivationByLowerType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.lowerType === CONST.LB_WATERING_LOWER_TYPE_LOWER_FOUNTAIN_HOSE) {
      this.hoseLowerPart.selected = true;
      this.hoseLowerPart.visible = true;
      this.hoseMulchingPart.selected = false;
      this.hoseMulchingPart.visible = false;
    } else if (this.lowerType === CONST.LB_WATERING_LOWER_TYPE_MULCHING_FOUNTAIN_HOSE) {
      this.hoseLowerPart.selected = false;
      this.hoseLowerPart.visible = false;
      this.hoseMulchingPart.selected = true;
      this.hoseMulchingPart.visible = true;
    } else {
      this.hoseLowerPart.selected = false;
      this.hoseLowerPart.visible = false;
      this.hoseMulchingPart.selected = false;
      this.hoseMulchingPart.visible = false;
    }
    this.algorithm_partActivationByFountainHose();
  }

  /**
   * 알고리즘: 파트 활성화 <- 분수호스(형태, 상단 형태, 하단 형태)
   */
  public algorithm_partActivationByFountainHose(): void {
    if (
      this.hoseScreenPart.selected === true ||
      this.hoseUpperPart.selected === true ||
      this.hoseSidePart.selected === true ||
      this.hoseLowerPart.selected === true ||
      this.hoseMulchingPart.selected === true
    ) {
      this.hoseConnectPart.selected = true;
      this.hoseConnectPart.visible = true;
    } else {
      this.hoseConnectPart.selected = false;
      this.hoseConnectPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 점적 형태
   */
  public algorithm_partActivationByDripType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.dripType === CONST.LB_WATERING_DRIP_TYPE_DRIP_WATERING) {
      this.dripWateringPart.selected = true;
      this.dripWateringPart.visible = true;
      this.dripConnectPart.selected = true;
      this.dripConnectPart.visible = true;
    } else {
      this.dripWateringPart.selected = false;
      this.dripWateringPart.visible = false;
      this.dripConnectPart.selected = false;
      this.dripConnectPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 안개(포그) 선택
   */
  public algorithm_partActivationByFogSelected(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fogSelected === true) {
      this.miniklerFogPart.selected = true;
      this.miniklerFogPart.visible = true;
    } else {
      this.miniklerFogPart.selected = false;
      this.miniklerFogPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 수막 물받이 선택
   */
  public algorithm_partActivationByScreenGutterSelected(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      if (this.screenGutterSelected === true) {
        this.screenGutterPart.selected = true;
        this.screenGutterPart.visible = true;
      } else {
        this.screenGutterPart.selected = false;
        this.screenGutterPart.visible = false;
      }
    }
  }

  /**
   * 알고리즘: 수막 물받이 선택 <- 형태
   */
  public algorithm_screenGutterSelected(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      if (this.type === CONST.LB_WATERING_TYPE_NONE) {
        this.screenGutterSelected = false;
      } else {
        this.screenGutterSelected = true;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.screenGutterSelected = false;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
