import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject({
  knownTypes: [WingPositionIL],
})
export class WingLevelIL extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public wingPosition: WingPositionIL;
  public frontPosition: WingPositionIL;
  public backPosition: WingPositionIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.algorithm_enabled();
    this.work.algorithm_selectedFromLevel();

    /// //////// 외부 ///////////

    // 기초 피복
    for (const coverPosition of this.struct.coverWorkIL.levelAC[this.index].positionAC as CoverPositionIL[]) {
      coverPosition.algorithm_scopeSelectedByScopeType();
    }
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.index]).algorithmBasic(); // 치마와 바람막이의 피복 알고리즘도 이어서 호출됨
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.wingPosition = new WingPositionIL();
    this.frontPosition = new WingPositionIL();
    this.backPosition = new WingPositionIL();

    this.positionAC = [this.wingPosition, this.frontPosition, this.backPosition];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.wingPosition = <WingPositionIL>this.positionAC[0];
    this.frontPosition = <WingPositionIL>this.positionAC[1];
    this.backPosition = <WingPositionIL>this.positionAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index >= 0) {
      this.wingPosition.setDefaultData(0, true, true, true, CONST.LB_POSITION_SIDE_WING, CONST.LB_WING_TYPE_DEFAULT);
      this.frontPosition.setDefaultData(1, false, true, true, CONST.LB_POSITION_FRONT, CONST.LB_WING_TYPE_DEFAULT);
      this.backPosition.setDefaultData(2, false, true, true, CONST.LB_POSITION_BACK, CONST.LB_WING_TYPE_DEFAULT);
    }
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work): void {
    if (this.positionAC.length === 1) {
      // 앞면
      const frontPosition: WingPositionIL = new WingPositionIL();
      frontPosition.setAssociation(design, struct, work, this);
      frontPosition.setDefaultData(1, false, true, true, CONST.LB_POSITION_FRONT, CONST.LB_WING_TYPE_DEFAULT);
      this.positionAC.push(frontPosition);
      // 뒷면
      const backPosition: WingPositionIL = new WingPositionIL();
      backPosition.setAssociation(design, struct, work, this);
      backPosition.setDefaultData(2, false, true, true, CONST.LB_POSITION_BACK, CONST.LB_WING_TYPE_DEFAULT);
      this.positionAC.push(backPosition);
    }

    super.restoreLatestObject(design, struct, work);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
