/**
 * 설계 관련 상수
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-01-07
 */
export class CONST {
  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 설계 관련
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 주요 레이블
  //----------------------------------

  public static LB_DESIGN_MODE_DESIGN: string = "design";
  public static LB_DESIGN_MODE_TEMPLATE: string = "template";

  public static LB_ESTIMATE_MODE_ESTIMATE: string = "estimate";
  public static LB_ESTIMATE_MODE_ESTIMATE_REVIEW: string = "estimate_review";

  public static LB_MODIFY_TYPE_CREATE: string = "create";
  public static LB_MODIFY_TYPE_UPDATE: string = "update";

  public static LB_AFTER_ACTION_ESTIMATE: string = "estimate";

  public static LB_DESIGN_STATUS_WRITE: string = "쓰기";
  public static LB_DESIGN_STATUS_READ_ONLY: string = "읽기전용";
  public static LB_DESIGN_STATUS_DELETED: string = "삭제";

  public static LB_PROGRESS_STATUS_DESIGNING_ESTIMATING: string = "설계/견적중";
  public static LB_PROGRESS_STATUS_CONTRACT_COMPLETED: string = "계약완료";
  public static LB_PROGRESS_STATUS_BUILDING: string = "시공중";
  public static LB_PROGRESS_STATUS_COMPLETED: string = "시공완료";
  public static LB_PROGRESS_STATUS_STOPPED: string = "중단";

  public static LB_FRACTION_TYPE_ROUND: string = "반올림";
  public static LB_FRACTION_TYPE_ROUND_UP: string = "올림";
  public static LB_FRACTION_TYPE_ROUND_DOWN: string = "내림";

  public static LB_CONSTRUCTION: string = "공사";
  public static LB_WORK: string = "작업";
  public static LB_LEVEL: string = "중수";
  public static LB_POSITION: string = "위치";
  public static LB_PART: string = "파트";
  public static LB_ITEM: string = "품목";

  //----------------------------------
  // 주요 아이디
  //----------------------------------

  //----------------------------------
  // 주요 수치
  //----------------------------------

  public static NUM_LEVEL_MAX: number = 4;
  public static NUM_SINGLE_LEVEL_MAX: number = 4;
  public static NUM_INTERLOCK_LEVEL_MAX: number = 3;
  public static NUM_VENLO_LEVEL_MAX: number = 2;
  public static NUM_DEPTH_0: number = 0;
  public static NUM_DEPTH_1: number = 1;
  public static NUM_DEPTH_2: number = 2;
  public static NUM_VARIABLE_LENGTH_BUILDING_MAX: number = 20; // 가변 길이 최대 동수
  public static NUM_COVER_POSITION_MAX: number = 10; // 최대 피복 겹수
  public static NUM_CURTAIN_HP_PER_AREA_THIN: number = 2000; // 얇은 커튼의 마력 당 면적 (2000m^2 = 약 600평)
  public static NUM_CURTAIN_HP_PER_AREA_THICK: number = 1000; // 얇은 커튼의 마력 당 면적 (1000m^2 = 약 300평)
  public static NUM_INVERVAL_WELDING: number = 0.5; // 용접 간격
  public static NUM_RACK_WAY_GAP: number = 0.4; // 선반 통로 간격

  // 설계 여분율
  public static NUM_EXTRA_RATE_RAFTER_PIPE: number = 1.0; // 서까래 파이프 여분율
  public static NUM_EXTRA_RATE_STRAIGHT_PIPE: number = 1.02; // 가로대 파이프 여분율
  public static NUM_EXTRA_RATE_COLUMN_PIPE: number = 1.0; // 기둥 파이프 여분율
  public static NUM_EXTRA_RATE_COLUMN_SUP_PIPE: number = 1.0; // 기둥 보조 파이프 여분율
  public static NUM_EXTRA_RATE_BEAM_PIPE: number = 1.03; // 보 파이프 여분율
  public static NUM_EXTRA_RATE_MIDDLE_PIPE: number = 1.0; // 중방 파이프 여분율
  public static NUM_EXTRA_RATE_MIDDLE_TRUSS_DIAGONAL_PIPE: number = 1.0; // 중방 트러스 사재 파이프 여분율
  public static NUM_EXTRA_RATE_FLOOR_GIRTH_PIPE: number = 1.0; // 바닥도리 파이프 여분율
  public static NUM_EXTRA_RATE_ENDPIECE_STUD_PIPE: number = 1.0; // 마구리 샛기둥 파이프 여분율
  public static NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE: number = 1.05; // 마구리 중방 파이프 여분율
  public static NUM_EXTRA_RATE_AXIS_PIPE: number = 1.0; // 개폐 축 파이프 여분율
  public static NUM_EXTRA_RATE_CURTAIN_DRIVE_AXIS_PIPE: number = 1.0; // 커튼 구동 축 파이프 여분율
  public static NUM_EXTRA_RATE_CURTAIN_SUPPORT_AXIS_PIPE: number = 1.0; // 커튼 지지 축 파이프 여분율
  public static NUM_EXTRA_RATE_CURTAIN_SUPPORT_PIPE: number = 1.0; // 커튼 지지 파이프 여분율
  public static NUM_EXTRA_RATE_BRACKET: number = 1.02; // 브라켓트 여분율
  public static NUM_EXTRA_RATE_SCREEN_PINCER: number = 1.0; // 스크린 집게 여분율
  public static NUM_EXTRA_RATE_CONNECTION_PIN: number = 1.0; // 연결핀 여분율
  public static NUM_EXTRA_RATE_NORMAL_HOLDER: number = 1.15; // 일반 고정구 여분율
  public static NUM_EXTRA_RATE_T_HOLDER: number = 1.15; // T 고정구 여분율
  public static NUM_EXTRA_RATE_DIAGONAL_T_HOLDER: number = 1.15; // 대각T 고정구 여분율
  public static NUM_EXTRA_RATE_HOOK_HOLDER: number = 1.05; // 고리 고정구 여분율
  public static NUM_EXTRA_RATE_SADDLE_HOLDER: number = 1.2; // 새들 고정구 여분율
  public static NUM_EXTRA_RATE_STEEL_STRING_GRABBER: number = 1.03; // 강선 조리개 여분율
  public static NUM_EXTRA_RATE_ENHANCED_GRABBER: number = 1.02; // 내재해 조리개 여분율
  public static NUM_EXTRA_RATE_STEEL_SHEET_GRABBER: number = 1.02; // 강판 조리개 여분율
  public static NUM_EXTRA_RATE_CROSS_STEEL_SHEET_GRABBER: number = 1.02; // 십자 강판 조리개 여분율
  public static NUM_EXTRA_RATE_LINE_SHEET_GRABBER: number = 1.02; // 강판 조리개 여분율
  public static NUM_EXTRA_RATE_T_CLAMP: number = 1.02; // T 클램프 여분율
  public static NUM_EXTRA_RATE_U_CLAMP: number = 1.02; // U 클램프 여분율
  public static NUM_EXTRA_RATE_DIAGONAL_T_CLAMP: number = 1.15; // 대각T 클램프 여분율
  public static NUM_EXTRA_RATE_GUTTER_BEAM_HOLDER: number = 1.02; // 물받이거터 보 고정구 여분율
  public static NUM_EXTRA_RATE_FIX_ASIBA_CLAMP: number = 1.02; // 고정 아시바 클램프 여분율
  public static NUM_EXTRA_RATE_ROTATION_ASIBA_CLAMP: number = 1.02; // 회전 아시바 클램프 여분율
  public static NUM_EXTRA_RATE_CROSS_CLAMP: number = 1.02; // 십자 클램프 여분율
  public static NUM_EXTRA_RATE_OUTER_WALL_CLAMP: number = 1.02; // 외벽 클램프 여분율
  public static NUM_EXTRA_RATE_MIDDLE_PAIR_CLAMP: number = 1.02; // 중방쌍반도 여분율
  public static NUM_EXTRA_RATE_MIDDLE_SOLE_CLAMP: number = 1.02; // 중방외반도 여분율
  public static NUM_EXTRA_RATE_MIDDLE_Y_CLAMP: number = 1.02; // 중방Y 클램프 여분율
  public static NUM_EXTRA_RATE_MIDDLE_HALF_Y_CLAMP: number = 1.02; // 중방반Y 클램프 여분율
  public static NUM_EXTRA_RATE_FIRST_LEVEL_SOLE_SCONCE: number = 1.02; // 일중 외꽂이 여분율
  public static NUM_EXTRA_RATE_FIRST_LEVEL_PAIR_SCONCE: number = 1.02; // 일중 쌍꽂이 여분율
  public static NUM_EXTRA_RATE_SECOND_LEVEL_SOLE_SCONCE: number = 1.02; // 이중 외꽂이 여분율
  public static NUM_EXTRA_RATE_SECOND_LEVEL_PAIR_SCONCE: number = 1.02; // 이중 쌍꽂이 여분율
  public static NUM_EXTRA_RATE_CONNECTION_SKYLIGHT_SET: number = 1.02; // 연결창 세트 여분율
  public static NUM_EXTRA_RATE_CONNECTION_ARCH_SCONCE: number = 1.02; // 이중 쌍꽂이 여분율
  public static NUM_EXTRA_RATE_INNER_T_HOLDER: number = 1.02; // 속T 고정구 여분율
  public static NUM_EXTRA_RATE_SCREW: number = 1.2; // 나사 여분율
  public static NUM_EXTRA_RATE_CONCRETE: number = 1.3; // 콘크리트(부피) 여분율
  public static NUM_EXTRA_RATE_BOLT_AND_NUT: number = 1.0; // 볼트&너트 여분율
  public static NUM_EXTRA_RATE_TURNBUCKLE: number = 1.02; // 턴버클 여분율
  public static NUM_EXTRA_RATE_UBAR: number = 1.0; // 유바 여분율
  public static NUM_EXTRA_RATE_GUTTER_HANGER: number = 1.1; // 물받이 걸이 여분율
  public static NUM_EXTRA_RATE_ELECTRIC_WIRE: number = 1.2; // 전선(길이) 여분율
  public static NUM_EXTRA_RATE_PAD_SPRING: number = 1.0; // 패드 스프링 여분율
  public static NUM_EXTRA_RATE_BEARING_SET: number = 1.0; // 축수 배어링 세트 여분율
  public static NUM_EXTRA_RATE_WINDING_DRUM: number = 1.0; // 와인딩 드럼여분율
  public static NUM_EXTRA_RATE_WIRE_STRAINER: number = 1.0; // 와이어 인장기 여분율
  public static NUM_EXTRA_RATE_RIPCORD: number = 1.0; // 낙하산 줄 여분율
  public static NUM_EXTRA_RATE_COATING_WIRE: number = 1.0; // 코팅 와이어 여분율
  public static NUM_EXTRA_RATE_TRUSS_CLIP: number = 1.0; // 트러스 클립 여분율
  public static NUM_EXTRA_RATE_TOW_CLIP: number = 1.0; // 예인 클립 여분율
  public static NUM_EXTRA_RATE_TOW_ROLLER: number = 1.0; // 예인 롤러 여분율
  public static NUM_EXTRA_RATE_GUIDE_ROLLER: number = 1.0; // 가이드 롤러 여분율
  public static NUM_EXTRA_RATE_ROLLER_HOOK: number = 1.0; // 롤러 고리 여분율
  public static NUM_EXTRA_RATE_RAIL_HOOK: number = 1.01; // 운반 고리 여분율
  public static NUM_EXTRA_RATE_RAIL_INTERVAL: number = 1.01; // 레일 간격유지 여분율

  //----------------------------------
  // 구조 레이블, 아이디, 인덱스
  //----------------------------------

  public static LB_STRUCT_SINGLE: string = "단동";
  public static LB_STRUCT_DOUBLE_WIDTH: string = "광폭";
  public static LB_STRUCT_RAIN_PROOF: string = "비가림";
  public static LB_STRUCT_INTERLOCK: string = "연동";
  public static LB_STRUCT_ANGULAR_IL: string = "각연동";
  public static LB_STRUCT_VENLO: string = "벤로";
  public static LB_STRUCT_OTHER: string = "기타";

  public static ID_STRUCT_SINGLE: string = "sg";
  public static ID_STRUCT_DOUBLE_WIDTH: string = "dw";
  public static ID_STRUCT_RAIN_PROOF: string = "rp";
  public static ID_STRUCT_INTERLOCK: string = "il";
  public static ID_STRUCT_ANGULAR_IL: string = "ai";
  public static ID_STRUCT_VENLO: string = "vl";
  public static ID_STRUCT_OTHER: string = "ot";

  public static INDEX_IL_LAST_LEVEL: number = 2;

  //----------------------------------
  // 기본정보 레이블, 아이디
  //----------------------------------

  public static LB_BASIC: string = "기본 정보";

  public static ID_BASIC: string = "bs";

  //----------------------------------
  // 공사 레이블, 아이디, 순서
  //----------------------------------

  public static LB_ARCHITECTURE_CONSTRUCTION: string = "건축 공사";
  public static LB_FOUNDATION_CONSTRUCTION: string = "기초 공사";
  public static LB_FRAME_CONSTRUCTION: string = "골조 공사";
  public static LB_SWITCH_CONSTRUCTION: string = "개폐 공사";
  public static LB_COVER_CONSTRUCTION: string = "피복 공사";
  public static LB_CURTAIN_CONSTRUCTION: string = "수평커튼 공사";
  public static LB_SKYLIGHT_CONSTRUCTION: string = "천창개폐 공사";
  public static LB_OTHER_ARCHITECTURE_CONSTRUCTION: string = "그외 건축 공사";
  public static LB_FACILITIES_CONSTRUCTION: string = "설비 공사";
  public static LB_VENT_CONSTRUCTION: string = "환기 공사";
  public static LB_WATERING_CONSTRUCTION: string = "관수 공사";
  public static LB_HYDROPONICS_CONSTRUCTION: string = "양액 공사";
  public static LB_TEMPERATURE_CONSTRUCTION: string = "냉난방 공사";
  public static LB_SMARTFARM_CONSTRUCTION: string = "스마트팜 공사";
  public static LB_OTHER_FACILITIES_CONSTRUCTION: string = "그외 기계/설비 공사";
  public static LB_BUILDING_CONSTRUCTION: string = "통합 시공";
  public static LB_OTHER_CONSTRUCTION: string = "기타";

  public static ID_ARCHITECTURE_CONSTRUCTION: string = "arc";
  public static ID_FOUNDATION_CONSTRUCTION: string = "fdt";
  public static ID_FRAME_CONSTRUCTION: string = "frm";
  public static ID_SWITCH_CONSTRUCTION: string = "swt";
  public static ID_COVER_CONSTRUCTION: string = "cvr";
  public static ID_CURTAIN_CONSTRUCTION: string = "cur";
  public static ID_SKYLIGHT_CONSTRUCTION: string = "sky";
  public static ID_OTHER_ARCHITECTURE_CONSTRUCTION: string = "oac";
  public static ID_FACILITIES_CONSTRUCTION: string = "fac";
  public static ID_VENT_CONSTRUCTION: string = "ven";
  public static ID_WATERING_CONSTRUCTION: string = "wat";
  public static ID_HYDROPONICS_CONSTRUCTION: string = "hyd";
  public static ID_TEMPERATURE_CONSTRUCTION: string = "tem";
  public static ID_SMARTFARM_CONSTRUCTION: string = "sma";
  public static ID_OTHER_FACILITIES_CONSTRUCTION: string = "ofc";
  public static ID_BUILDING_CONSTRUCTION: string = "bdg";
  public static ID_OTHER_CONSTRUCTION: string = "otr";

  public static ORDER_ARCHITECTURE_CONSTRUCTION: number = 0;
  public static ORDER_FOUNDATION_CONSTRUCTION: number = 10;
  public static ORDER_FRAME_CONSTRUCTION: number = 20;
  public static ORDER_SWITCH_CONSTRUCTION: number = 30;
  public static ORDER_COVER_CONSTRUCTION: number = 40;
  public static ORDER_CURTAIN_CONSTRUCTION: number = 50;
  public static ORDER_SKYLIGHT_CONSTRUCTION: number = 60;
  public static ORDER_OTHER_ARCHITECTURE_CONSTRUCTION: number = 70;
  public static ORDER_FACILITIES_CONSTRUCTION: number = 1000;
  public static ORDER_VENT_CONSTRUCTION: number = 1010;
  public static ORDER_WATERING_CONSTRUCTION: number = 1020;
  public static ORDER_HYDROPONICS_CONSTRUCTION: number = 1030;
  public static ORDER_TEMPERATURE_CONSTRUCTION: number = 1040;
  public static ORDER_SMARTFARM_CONSTRUCTION: number = 1050;
  public static ORDER_OTHER_FACILITIES_CONSTRUCTION: number = 1060;
  public static ORDER_BUILDING_CONSTRUCTION: number = 2000;
  public static ORDER_OTHER_CONSTRUCTION: number = 2010;

  //----------------------------------
  // 작업 레이블
  //----------------------------------

  public static LB_FOUNDATION_WORK: string = "기초";
  public static LB_TRUNK_WORK: string = "기초 골조";
  public static LB_COLUMN_WORK: string = "기둥 골조";
  public static LB_ROOF_WORK: string = "지붕 골조";
  public static LB_WING_WORK: string = "방풍벽 골조";
  public static LB_ENDPIECE_WORK: string = "마구리 골조";
  public static LB_GUTTER_WORK: string = "물받이";
  public static LB_SWITCHER_WORK: string = "개폐기";
  public static LB_CONTROLLER_WORK: string = "제어기";
  public static LB_COVER_WORK: string = "기초 피복";
  public static LB_FIXING_WORK: string = "피복 고정";
  public static LB_SKIRT_WORK: string = "치마 피복";
  public static LB_WINDBREAK_WORK: string = "바람막이 피복";
  public static LB_SCREENNET_WORK: string = "방충망";
  public static LB_BANDSTRING_WORK: string = "밴드끈";
  public static LB_CURTAIN_COVER_WORK: string = "커튼 피복";
  public static LB_CURTAIN_SWITCH_WORK: string = "커튼 개폐";
  public static LB_SKYLIGHT_FRAME_WORK: string = "천창 골조";
  public static LB_SKYLIGHT_SWITCH_WORK: string = "천창 개폐";
  public static LB_SKYLIGHT_COVER_WORK: string = "천창 피복";
  public static LB_PARTITION_WORK: string = "칸막이";
  public static LB_WINDOW_WORK: string = "창문";
  public static LB_WORKROOM_WORK: string = "작업동";
  public static LB_OTHER_MATERIAL_WORK: string = "기타 자재";
  public static LB_VENT_EXHAUST_WORK: string = "외부 배기";
  public static LB_VENT_INSIDE_WORK: string = "내부 환기";
  public static LB_WATERING_MAINPIPE_WORK: string = "관수 제어부";
  public static LB_WATERING_WATERING_WORK: string = "관수 지관부";
  public static LB_HYD_SYSTEM_WORK: string = "양액 제어부";
  public static LB_HYD_CULTIVATION_WORK: string = "양액 베드부";
  public static LB_TEM_HEATING_WORK: string = "난방";
  public static LB_TEM_COOLING_WORK: string = "냉방";
  public static LB_ENVIRONMENT_WORK: string = "복합환경제어";
  public static LB_RACK_WORK: string = "선반/균상다이";
  public static LB_RAIL_WORK: string = "운반레일";
  public static LB_CO2_WORK: string = "CO2";
  public static LB_AUTOSPRAY_WORK: string = "자동방제기";
  public static LB_OTHER_FACILITIES_WORK: string = "기타 기계/설비";
  public static LB_BUILDING_WORK: string = "통합 시공비";

  //----------------------------------
  // 작업 아이디
  // ----------------------------------비

  // 단동 구조
  public static ID_SG_FOUNDATION_WORK: string = "sg_foun";
  public static ID_SG_TRUNK_WORK: string = "sg_trnk";
  public static ID_SG_ENDPIECE_WORK: string = "sg_endp";
  public static ID_SG_GUTTER_WORK: string = "sg_gutr";
  public static ID_SG_SWITCHER_WORK: string = "sg_swit";
  public static ID_SG_CONTROLLER_WORK: string = "sg_cont";
  public static ID_SG_COVER_WORK: string = "sg_covr";
  public static ID_SG_FIXING_WORK: string = "sg_fixg";
  public static ID_SG_SKIRT_WORK: string = "sg_skrt";
  public static ID_SG_WINDBREAK_WORK: string = "sg_wind";
  public static ID_SG_SCREENNET_WORK: string = "sg_scrn";
  public static ID_SG_BANDSTRING_WORK: string = "sg_band";

  // 연동, 벤로 구조
  public static ID_IL_FOUNDATION_WORK: string = "il_foun";
  public static ID_IL_COLUMN_WORK: string = "il_coln";
  public static ID_IL_ROOF_WORK: string = "il_roof";
  public static ID_IL_WING_WORK: string = "il_wing";
  public static ID_IL_ENDPIECE_WORK: string = "il_endp";
  public static ID_IL_GUTTER_WORK: string = "il_gutr";
  public static ID_IL_SWITCHER_WORK: string = "il_swit";
  public static ID_IL_CONTROLLER_WORK: string = "il_cont";
  public static ID_IL_COVER_WORK: string = "il_covr";
  public static ID_IL_FIXING_WORK: string = "il_fixg";
  public static ID_IL_SKIRT_WORK: string = "il_skrt";
  public static ID_IL_WINDBREAK_WORK: string = "il_wind";
  public static ID_IL_SCREENNET_WORK: string = "il_scrn";
  public static ID_IL_BANDSTRING_WORK: string = "il_band";

  // 공통 구조
  public static ID_CM_CURTAIN_COVER_WORK: string = "cm_curcvr";
  public static ID_CM_CURTAIN_SWITCH_WORK: string = "cm_curswt";
  public static ID_CM_SKYLIGHT_FRAME_WORK: string = "cm_skyfrm";
  public static ID_CM_SKYLIGHT_SWITCH_WORK: string = "cm_skyswt";
  public static ID_CM_SKYLIGHT_COVER_WORK: string = "cm_skycvr";
  public static ID_CM_PARTITION_WORK: string = "cm_part";
  public static ID_CM_WINDOW_WORK: string = "cm_wind";
  public static ID_CM_WORKROOM_WORK: string = "cm_wkroom";
  public static ID_CM_OTHER_MATERIAL_WORK: string = "cm_othmtr";
  public static ID_CM_VENT_EXHAUST_WORK: string = "cm_venext";
  public static ID_CM_VENT_INSIDE_WORK: string = "cm_venins";
  public static ID_CM_WATERING_MAINPIPE_WORK: string = "cm_watman";
  public static ID_CM_WATERING_WATERING_WORK: string = "cm_watdet";
  public static ID_CM_HYD_SYSTEM_WORK: string = "cm_hydsys";
  public static ID_CM_HYD_CULTIVATION_WORK: string = "cm_hydcul";
  public static ID_CM_TEM_HEATING_WORK: string = "cm_temhet";
  public static ID_CM_TEM_COOLING_WORK: string = "cm_temcol";
  public static ID_CM_ENVIRONMENT_WORK: string = "cm_comenv";
  public static ID_CM_RACK_WORK: string = "cm_rack";
  public static ID_CM_RAIL_WORK: string = "cm_rail";
  public static ID_CM_CO2_WORK: string = "cm_co2";
  public static ID_CM_AUTOSPRAY_WORK: string = "cm_atospr";
  public static ID_CM_OTHER_FACILITIES_WORK: string = "cm_othfac";
  public static ID_CM_BUILDING_WORK: string = "cm_build";

  //----------------------------------
  // 작업 인덱스(객체 순서, 뷰 배치 순서)
  //----------------------------------

  // 단동 구조
  public static INDEX_SG_FOUNDATION_WORK: number = 0;
  public static INDEX_SG_TRUNK_WORK: number = 1;
  public static INDEX_SG_ENDPIECE_WORK: number = 2;
  public static INDEX_SG_GUTTER_WORK: number = 3;
  public static INDEX_SG_SWITCHER_WORK: number = 4;
  public static INDEX_SG_CONTROLLER_WORK: number = 5;
  public static INDEX_SG_COVER_WORK: number = 6;
  public static INDEX_SG_FIXING_WORK: number = 7;
  public static INDEX_SG_SKIRT_WORK: number = 8;
  public static INDEX_SG_WINDBREAK_WORK: number = 9;
  public static INDEX_SG_SCREENNET_WORK: number = 10;
  public static INDEX_SG_BANDSTRING_WORK: number = 11;
  public static INDEX_SG_SKYLIGHT_FRAME_WORK: number = 12;
  public static INDEX_SG_SKYLIGHT_SWITCH_WORK: number = 13;
  public static INDEX_SG_SKYLIGHT_COVER_WORK: number = 14;
  public static INDEX_SG_PARTITION_WORK: number = 15;
  public static INDEX_SG_WINDOW_WORK: number = 16;
  public static INDEX_SG_WORKROOM_WORK: number = 17;
  public static INDEX_SG_OTHER_MATERIAL_WORK: number = 18;
  public static INDEX_SG_VENT_EXHAUST_WORK: number = 19;
  public static INDEX_SG_VENT_INSIDE_WORK: number = 20;
  public static INDEX_SG_WATERING_MAINPIPE_WORK: number = 21;
  public static INDEX_SG_WATERING_WATERING_WORK: number = 22;
  public static INDEX_SG_HYD_SYSTEM_WORK: number = 23;
  public static INDEX_SG_HYD_CULTIVATION_WORK: number = 24;
  public static INDEX_SG_TEM_HEATING_WORK: number = 25;
  public static INDEX_SG_TEM_COOLING_WORK: number = 26;
  public static INDEX_SG_ENVIRONMENT_WORK: number = 27;
  public static INDEX_SG_RACK_WORK: number = 28;
  public static INDEX_SG_RAIL_WORK: number = 29;
  public static INDEX_SG_CO2_WORK: number = 30;
  public static INDEX_SG_AUTOSPRAY_WORK: number = 31;
  public static INDEX_SG_OTHER_FACILITIES_WORK: number = 32;
  public static INDEX_SG_BUILDING_WORK: number = 33;

  public static INDEX_SG_LAST_WORK: number = 33;

  // 연동, 벤로 구조
  public static INDEX_IL_FOUNDATION_WORK: number = 0;
  public static INDEX_IL_COLUMN_WORK: number = 1;
  public static INDEX_IL_ROOF_WORK: number = 2;
  public static INDEX_IL_WING_WORK: number = 3;
  public static INDEX_IL_ENDPIECE_WORK: number = 4;
  public static INDEX_IL_GUTTER_WORK: number = 5;
  public static INDEX_IL_SWITCHER_WORK: number = 6;
  public static INDEX_IL_CONTROLLER_WORK: number = 7;
  public static INDEX_IL_COVER_WORK: number = 8;
  public static INDEX_IL_FIXING_WORK: number = 9;
  public static INDEX_IL_SKIRT_WORK: number = 10;
  public static INDEX_IL_WINDBREAK_WORK: number = 11;
  public static INDEX_IL_SCREENNET_WORK: number = 12;
  public static INDEX_IL_BANDSTRING_WORK: number = 13;
  public static INDEX_IL_CURTAIN_COVER_WORK: number = 14;
  public static INDEX_IL_CURTAIN_SWITCH_WORK: number = 15;
  public static INDEX_IL_SKYLIGHT_FRAME_WORK: number = 16;
  public static INDEX_IL_SKYLIGHT_SWITCH_WORK: number = 17;
  public static INDEX_IL_SKYLIGHT_COVER_WORK: number = 18;
  public static INDEX_IL_PARTITION_WORK: number = 19;
  public static INDEX_IL_WINDOW_WORK: number = 20;
  public static INDEX_IL_WORKROOM_WORK: number = 21;
  public static INDEX_IL_OTHER_MATERIAL_WORK: number = 22;
  public static INDEX_IL_VENT_EXHAUST_WORK: number = 23;
  public static INDEX_IL_VENT_INSIDE_WORK: number = 24;
  public static INDEX_IL_WATERING_MAINPIPE_WORK: number = 25;
  public static INDEX_IL_WATERING_WATERING_WORK: number = 26;
  public static INDEX_IL_HYD_SYSTEM_WORK: number = 27;
  public static INDEX_IL_HYD_CULTIVATION_WORK: number = 28;
  public static INDEX_IL_TEM_HEATING_WORK: number = 29;
  public static INDEX_IL_TEM_COOLING_WORK: number = 30;
  public static INDEX_IL_ENVIRONMENT_WORK: number = 31;
  public static INDEX_IL_RACK_WORK: number = 32;
  public static INDEX_IL_RAIL_WORK: number = 33;
  public static INDEX_IL_CO2_WORK: number = 34;
  public static INDEX_IL_AUTOSPRAY_WORK: number = 35;
  public static INDEX_IL_OTHER_FACILITIES_WORK: number = 36;
  public static INDEX_IL_BUILDING_WORK: number = 37;

  public static INDEX_IL_LAST_WORK: number = 37;

  // 기타 구조
  public static INDEX_OT_OTHER_MATERIAL_WORK: number = 0;
  public static INDEX_OT_BUILDING_WORK: number = 1;

  //----------------------------------
  // 작업 순서(견적 상품 순서)
  //----------------------------------

  public static ORDER_FOUNDATION_WORK: number = 1010;
  public static ORDER_TRUNK_WORK: number = 2010;
  public static ORDER_COLUMN_WORK: number = 2020;
  public static ORDER_ROOF_WORK: number = 2030;
  public static ORDER_WING_WORK: number = 2040;
  public static ORDER_ENDPIECE_WORK: number = 2050;
  public static ORDER_GUTTER_WORK: number = 2060;
  public static ORDER_SWITCHER_WORK: number = 3010;
  public static ORDER_CONTROLLER_WORK: number = 3020;
  public static ORDER_COVER_WORK: number = 4010;
  public static ORDER_FIXING_WORK: number = 4020;
  public static ORDER_SKIRT_WORK: number = 4030;
  public static ORDER_WINDBREAK_WORK: number = 4040;
  public static ORDER_SCREENNET_WORK: number = 4050;
  public static ORDER_BANDSTRING_WORK: number = 4060;
  public static ORDER_CURTAIN_COVER_WORK: number = 5010;
  public static ORDER_CURTAIN_SWITCH_WORK: number = 5020;
  public static ORDER_SKYLIGHT_FRAME_WORK: number = 6010;
  public static ORDER_SKYLIGHT_SWITCH_WORK: number = 6020;
  public static ORDER_SKYLIGHT_COVER_WORK: number = 6030;
  public static ORDER_PARTITION_WORK: number = 7010;
  public static ORDER_WINDOW_WORK: number = 7020;
  public static ORDER_WORKROMM_WORK: number = 7030;
  public static ORDER_OTHER_MATERIAL_WORK: number = 7040;
  public static ORDER_VENT_EXHAUST_WORK: number = 8010;
  public static ORDER_VENT_INSIDE_WORK: number = 8020;
  public static ORDER_WATERING_MAINPIPE_WORK: number = 9010;
  public static ORDER_WATERING_WATERING_WORK: number = 9020;
  public static ORDER_HYD_SYSTEM_WORK: number = 10010;
  public static ORDER_HYD_CULTIVATION_WORK: number = 10020;
  public static ORDER_TEM_HEATING_WORK: number = 11010;
  public static ORDER_TEM_COOLING_WORK: number = 11020;
  public static ORDER_ENVIRONMENT_WORK: number = 12010;
  public static ORDER_RACK_WORK: number = 13010;
  public static ORDER_RAIL_WORK: number = 13020;
  public static ORDER_CO2_WORK: number = 13030;
  public static ORDER_AUTOSPRAY_WORK: number = 13040;
  public static ORDER_OTHER_FACILITIES_WORK: number = 13050;
  public static ORDER_BUILDING_WORK: number = 14020;

  //----------------------------------
  // 견적서 단순화 그룹핑
  //----------------------------------
  public static CAT_CONSTRUCTION = "건축";
  public static CAT_FACILITIES = "설비";
  public static CAT_FRAME_SWITCH_COVER_CONSTRUCTION = "골조/피복 공사";
  public static CAT_PARTITION_CONSTRUCTION = "칸막이 공사";
  public static CAT_WINDOW_CONSTRUCTION = "창문 공사";
  public static CAT_WORKROOM_CONSTRUCTION = "작업동 공사";
  public static CAT_FRAME_SWITCH_COVER_ADD_JAJE = "추가 자재";

  //----------------------------------
  // 일반 견적 그룹핑
  //----------------------------------

  public static ORDER_NORMAL_ARCHITECTURE_CONSTRUCTION = 0;
  public static ORDER_NORMAL_FRAME_SWITCH_COVER_CONSTRUCTION = 10;
  public static ORDER_NORMAL_CURTAIN_CONSTRUCTION = 20;
  public static ORDER_NORMAL_SKYLIGHT_CONSTRUCTION = 30;
  public static ORDER_NORMAL_PARTITION_CONSTRUCTION = 40;
  public static ORDER_NORMAL_WINDOW_CONSTRUCTION = 50;
  public static ORDER_NORMAL_WORKROOM_CONSTRUCTION = 60;
  public static ORDER_NORMAL_OTHER_JAJE = 70;
  public static ORDER_NORMAL_VENT_CONSTRUCTION = 100;
  public static ORDER_NORMAL_WATERING_CONSTRUCTION = 110;
  public static ORDER_NORMAL_HYD_SYSTEM_CONSTRUCTION = 120;
  public static ORDER_NORMAL_HYD_CULTIVATION_CONSTRUCTION = 130;
  public static ORDER_NORMAL_TEM_HEATING_CONSTRUCTION = 140;
  public static ORDER_NORMAL_TEM_COOLING_CONSTRUCTION = 150;
  public static ORDER_NORMAL_ENVIRONMENT_CONSTRUCTION = 160;
  public static ORDER_NORMAL_RACK_CONSTRUCTION = 170;
  public static ORDER_NORMAL_RAIL_CONSTRUCTION = 180;
  public static ORDER_NORMAL_CO2_CONSTRUCTION = 190;
  public static ORDER_NORMAL_AUTOSPRAY_CONSTRUCTION = 200;
  public static ORDER_NORMAL_OTHER_FACILITIES_CONSTRUCTION = 210;
  public static ORDER_NORMAL_BUILDING_CONSTRUCTION = 220;

  //----------------------------------
  // 상세 견적 그룹핑
  //----------------------------------

  public static ORDER_DETAIL_ARCHITECTURE_CONSTRUCTION = 990;
  public static ORDER_DETAIL_FRAME_MATERIAL = 1000;
  public static ORDER_DETAIL_FRAME_APPENDAGE_MATERIAL = 1010;
  public static ORDER_DETAIL_GUTTER_MATERIAL = 1020;
  public static ORDER_DETAIL_SWITCH_MATERIAL = 1030;
  public static ORDER_DETAIL_COVER_MATERIAL = 1040;
  public static ORDER_DETAIL_COVER_APPENDAGE_MATERIAL = 1050;
  public static ORDER_DETAIL_FRAME_SWITCH_COVER_ADD_JAJE = 1060;
  public static ORDER_DETAIL_CURTAIN_CONSTRUCTION = 1070;
  public static ORDER_DETAIL_SKYLIGHT_CONSTRUCTION = 1080;
  public static ORDER_DETAIL_PARTITION_CONSTRUCTION = 1090;
  public static ORDER_DETAIL_WINDOW_CONSTRUCTION = 1100;
  public static ORDER_DETAIL_WORKROOM_CONSTRUCTION = 1110;
  public static ORDER_DETAIL_OTHER_COST = 1120;
  public static ORDER_DETAIL_VENT_CONSTRUCTION = 2000;
  public static ORDER_DETAIL_WATERING_CONSTRUCTION = 2010;
  public static ORDER_DETAIL_HYD_SYSTEM_CONSTRUCTION = 2020;
  public static ORDER_DETAIL_HYD_CULTIVATION_CONSTRUCTION = 2030;
  public static ORDER_DETAIL_TEM_HEATING_CONSTRUCTION = 2040;
  public static ORDER_DETAIL_TEM_COOLING_CONSTRUCTION = 2050;
  public static ORDER_DETAIL_ENVIRONMENT_CONSTRUCTION = 2060;
  public static ORDER_DETAIL_RACK_CONSTRUCTION = 2070;
  public static ORDER_DETAIL_RAIL_CONSTRUCTION = 2080;
  public static ORDER_DETAIL_CO2_CONSTRUCTION = 2090;
  public static ORDER_DETAIL_AUTOSPRAY_CONSTRUCTION = 2100;
  public static ORDER_DETAIL_OTHER_FACILITIES_CONSTRUCTION = 2110;
  public static ORDER_DETAIL_BUILDING_CONSTRUCTION = 2120;

  //----------------------------------
  // 중수 레이블
  //----------------------------------

  public static LB_LEVEL_1: string = "1중";
  public static VL_LEVEL_1: string = "1중";
  public static DC_LEVEL_1: string = "";
  public static LB_LEVEL_2: string = "2중";
  public static VL_LEVEL_2: string = "2중";
  public static DC_LEVEL_2: string = "";
  public static LB_LEVEL_3: string = "3중";
  public static VL_LEVEL_3: string = "3중";
  public static DC_LEVEL_3: string = "";
  public static LB_LEVEL_4: string = "4중";
  public static VL_LEVEL_4: string = "4중";
  public static DC_LEVEL_4: string = "";
  public static LB_LEVEL_WHOLE: string = "전체";
  public static VL_LEVEL_WHOLE: string = "전체";
  public static DC_LEVEL_WHOLE: string = "";

  //----------------------------------
  // 위치 레이블
  //----------------------------------

  public static LB_POSITION_WHOLE: string = "전체";
  public static LB_POSITION_NOWHERE: string = "없음";
  public static LB_POSITION_SIDE: string = "측면";
  public static LB_POSITION_SIDE_COLUMN: string = "기둥";
  public static LB_POSITION_SIDE_WING: string = "방풍벽";
  public static LB_POSITION_SIDE_AND_ROOF: string = "측면+지붕";
  public static LB_POSITION_ROOF: string = "지붕";
  public static LB_POSITION_DOUBLE_CENTER: string = "광폭중앙";
  public static LB_POSITION_FLOOR: string = "바닥";
  public static LB_POSITION_SKYLIGHT: string = "천창";
  public static LB_POSITION_ENDPIECE: string = "마구리";
  public static LB_POSITION_FRONT: string = "앞면";
  public static LB_POSITION_BACK: string = "뒷면";
  public static LB_POSITION_FRONT_BACK: string = "앞뒤";
  public static LB_POSITION_LEFT: string = "좌측";
  public static LB_POSITION_RIGHT: string = "우측";
  public static LB_POSITION_LEFT_RIGHT: string = "좌우";
  public static LB_POSITION_CEILING: string = "천장";
  public static LB_POSITION_CEILING_FRONT_BACK_LEFT_RIGHT: string = "천장+앞뒤/좌우";
  public static LB_POSITION_COVER: string = "번";
  public static LB_POSITION_COVER_1: string = `1${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_2: string = `2${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_3: string = `3${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_4: string = `4${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_5: string = `5${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_6: string = `6${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_7: string = `7${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_8: string = `8${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_9: string = `9${CONST.LB_POSITION_COVER}`;
  public static LB_POSITION_COVER_10: string = `10${CONST.LB_POSITION_COVER}`;

  //----------------------------------
  // 위치 아이디
  //----------------------------------

  public static ID_POSITION_WHOLE: string = "whol";
  public static ID_POSITION_NOWHERE: string = "nowh";
  public static ID_POSITION_SIDE: string = "side";
  public static ID_POSITION_SIDE_COLUMN: string = "colm";
  public static ID_POSITION_SIDE_WING: string = "wing";
  public static ID_POSITION_SIDE_AND_ROOF: string = "siro";
  public static ID_POSITION_SIDE_COLUMN_CEILING_FRONT_BACK: string = "ccfb";
  public static ID_POSITION_ROOF: string = "roof";
  public static ID_POSITION_FLOOR: string = "flor";
  public static ID_POSITION_CEILING: string = "ceil";
  public static ID_POSITION_CEILING_FRONT_BACK: string = "cefb";
  public static ID_POSITION_SKYLIGHT: string = "skyl";
  public static ID_POSITION_ENDPIECE: string = "endp";
  public static ID_POSITION_FRONT: string = "frnt";
  public static ID_POSITION_BACK: string = "back";
  public static ID_POSITION_FRONT_BACK: string = "frba";
  public static ID_POSITION_LEFT: string = "left";
  public static ID_POSITION_RIGHT: string = "rigt";
  public static ID_POSITION_LEFT_RIGHT: string = "leri";
  public static ID_POSITION_COVER: string = "pcv";
  public static ID_POSITION_COVER_1: string = `1${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_2: string = `2${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_3: string = `3${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_4: string = `4${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_5: string = `5${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_6: string = `6${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_7: string = `7${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_8: string = `8${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_9: string = `9${CONST.ID_POSITION_COVER}`;
  public static ID_POSITION_COVER_10: string = `10${CONST.ID_POSITION_COVER}`;

  //----------------------------------
  // 파트 레이블
  //----------------------------------

  // 기초
  public static LB_FOUNDATION_GIRTH_PIPE: string = "바닥도리 파이프";
  public static LB_FOUNDATION_COLUMN_CONCRETE: string = "기둥 콘크리트";
  public static LB_FOUNDATION_CUSTOM: string = "임의 품목";

  // 기초 골조
  public static LB_TRUNK_RAFTER: string = "서까래";
  public static LB_TRUNK_STRAIGHT: string = "가로대";
  public static LB_TRUNK_COLUMN: string = "기둥";

  // 기둥 골조
  public static LB_COLUMN_CYLINDER_COLUMN: string = "원형 기둥";
  public static LB_COLUMN_CYLINDER_BEAM: string = "원형 보";
  public static LB_COLUMN_CYLINDER_MIDDLE: string = "원형 중방";
  public static LB_COLUMN_PRISM_COLUMN: string = "각형 기둥";
  public static LB_COLUMN_PRISM_BEAM: string = "각형 보";
  public static LB_COLUMN_PRISM_MIDDLE: string = "각형 중방";
  public static LB_COLUMN_SIDE_STRAIGHT: string = "측면 가로대";

  // 지붕 골조
  public static LB_ROOF_RAFTER: string = "서까래";
  public static LB_ROOF_STRAIGHT: string = "가로대";
  public static LB_ROOF_BRACING: string = "지붕 브레싱";

  // 방풍벽 골조
  public static LB_WING_RAFTER: string = "방풍벽 서까래";
  public static LB_WING_STRAIGHT: string = "방풍벽 가로대/브레싱";

  // 마구리 골조
  public static LB_ENDPIECE_STUD: string = "마구리 샛기둥";
  public static LB_ENDPIECE_MIDDLE: string = "마구리 중방";
  public static LB_ENDPIECE_CYLINDER_STUD: string = "원형 마구리 샛기둥";
  public static LB_ENDPIECE_CYLINDER_STUD_SEP: string = "원형 마구리 샛기둥 상하단";
  public static LB_ENDPIECE_CYLINDER_MIDDLE: string = "원형 마구리 중방";
  public static LB_ENDPIECE_PRISM_STUD_SEP: string = "각형 마구리 샛기둥 상하단";
  public static LB_ENDPIECE_PRISM_MIDDLE: string = "각형 마구리 중방";
  public static LB_ENDPIECE_ENDPIECE: string = "마구리";
  public static LB_ENDPIECE_GATE: string = "출입문";

  // 물받이
  public static LB_GUTTER_IRON_PLATE: string = "철판 물받이";
  public static LB_GUTTER_PLASTIC: string = "플라스틱 물받이";
  public static LB_GUTTER_WEAVING_FILM: string = "직조필름 물받이";
  public static LB_GUTTER_FIXING_GUTTER_PAD: string = "물받이 패드 고정";
  public static LB_GUTTER_FIXING_SCREW: string = "나사 고정";
  public static LB_GUTTER_FIXING_NORMAL_CLIP: string = "일반 클립 고정";
  public static LB_GUTTER_DRAINAGE_PLEATED_PIPE: string = "주름관 배수";
  public static LB_GUTTER_DRAINAGE_PVC_PIPE: string = "PVC관 배수";
  public static LB_GUTTER_CONDENSATION: string = "결로받이";
  public static LB_GUTTER_CUSTOM: string = "임의 품목";

  // 개폐기
  public static LB_SWITCHER_MANUAL1: string = "수동 개폐기";
  public static LB_SWITCHER_MANUAL2: string = "수동 개폐기(2차)";
  public static LB_SWITCHER_POWER1: string = "동력 개폐기";
  public static LB_SWITCHER_POWER2: string = "동력 개폐기(2차)";
  public static LB_SWITCHER_AXIS: string = "개폐 축";
  public static LB_SWITCHER_SUPPORT: string = "개폐기 지지";
  public static LB_SWITCHER_SUPPORT_VERTICAL: string = "수직형 지지";
  public static LB_SWITCHER_SUPPORT_DIAGONAL: string = "대각형 지지";
  public static LB_SWITCHER_SUPPORT_RAFTER: string = "서까래형 지지";
  public static LB_SWITCHER_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_SWITCHER_FIXING_NORMAL_CLIP: string = "일반 클립 고정";
  public static LB_SWITCHER_FIXING_LAGGING_PAD: string = "광폭 패드 고정";

  // 제어기
  public static LB_CONTROLLER_DEFAULT: string = "제어기";

  // 기초 피복
  public static LB_COVER_SKYLIGHT_COVER: string = "일반 피복";
  public static LB_COVER_VINYL: string = "비닐";
  public static LB_COVER_WEAVING_FILM: string = "직조 필름";
  public static LB_COVER_SHADENET: string = "차광망";
  public static LB_COVER_TENT: string = "텐텐지";
  public static LB_COVER_NONWOVEN: string = "부직포";
  public static LB_COVER_CASHMILON: string = "카시미론";
  public static LB_COVER_LAGGING_COLOR: string = "칼라 보온덮개";
  public static LB_COVER_LAGGING: string = "보온덮개";
  public static LB_COVER_QUILTING: string = "다겹 커튼지";
  public static LB_COVER_SCREEN: string = "스크린";
  public static LB_COVER_MAT: string = "마트";

  // 피복 고정
  public static LB_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_FIXING_NORMAL_CLIP: string = "일반 클립 고정";
  public static LB_FIXING_LAGGING_PAD: string = "광폭 패드 고정";
  public static LB_FIXING_WOOD: string = "목재 고정";
  public static LB_FIXING_CONDENSATION_PAD: string = "결로 패드 고정";

  // 치마 피복
  public static LB_SKIRT_NORMAL: string = "일반 치마 피복";
  public static LB_SKIRT_STRING: string = "끈 치마 피복";
  public static LB_SKIRT_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_SKIRT_FIXING_NORMAL_CLIP: string = "일반 클립 고정";
  public static LB_SKIRT_FIXING_LAGGING_PAD: string = "광폭 패드 고정";
  public static LB_SKIRT_FIXING_WOOD: string = "목재 고정";
  public static LB_SKIRT_FIXING_SKIRT_CLIP: string = "치마 클립 고정";

  // 바람막이 피복
  public static LB_WINDBREAK_DEFAULT: string = "바람막이 피복";
  public static LB_WINDBREAK_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_WINDBREAK_FIXING_NORMAL_CLIP: string = "일반 클립 고정";
  public static LB_WINDBREAK_FIXING_LAGGING_PAD: string = "광폭 패드 고정";
  public static LB_WINDBREAK_FIXING_WOOD: string = "목재 고정";

  // 방충망
  public static LB_SCREENNET_DEFAULT: string = "방충망";
  public static LB_SCREENNET_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_SCREENNET_FIXING_LAGGING_PAD: string = "광폭 패드 고정";
  public static LB_SCREENNET_FIXING_WOOD: string = "목재 고정";

  // 밴드끈
  public static LB_BANDSTRING_BANDSTRING: string = "밴드끈";
  public static LB_BANDSTRING_NORMAL: string = "일반 밴드끈";
  public static LB_BANDSTRING_POLY: string = "폴리 밴드끈";
  public static LB_BANDSTRING_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_BANDSTRING_FIXING_STAKE: string = "말뚝 고정";
  public static LB_BANDSTRING_FIXING_HOOK: string = "고리 고정";
  public static LB_BANDSTRING_ENHANCED_STAKE: string = "바닥파이프 보강 말뚝";

  // 커튼 피복
  public static LB_CURTAIN_COVER_QUILTING: string = "커튼 다겹 커튼지";
  public static LB_CURTAIN_COVER_NONWOVEN: string = "커튼 부직포";
  public static LB_CURTAIN_COVER_MAT: string = "커튼 마트";
  public static LB_CURTAIN_COVER_SHADENET: string = "커튼 차광망";
  public static LB_CURTAIN_COVER_SCREEN: string = "커튼 스크린";
  public static LB_CURTAIN_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_CURTAIN_CEILING_LEVEL_1: string = "1중 천장";
  public static LB_CURTAIN_CEILING_LEVEL_2: string = "2중 천장";
  public static LB_CURTAIN_CEILING_LEVEL_3: string = "3중 천장";
  public static LB_CURTAIN_AXIS_TYPE_IL: string = "연동형";
  public static VL_CURTAIN_AXIS_TYPE_IL: string = "연동형";
  public static DC_CURTAIN_AXIS_TYPE_IL: string = "";
  public static LB_CURTAIN_AXIS_TYPE_VL: string = "벤로형";
  public static VL_CURTAIN_AXIS_TYPE_VL: string = "벤로형";
  public static DC_CURTAIN_AXIS_TYPE_VL: string = "";

  // 커튼 개폐
  public static LB_CURTAIN_SWITCH_POWER: string = "커튼 동력 개폐기";
  public static LB_CURTAIN_SWITCH_MOTOR: string = "커튼 감속 모터";
  public static LB_CURTAIN_SWITCH_AXIS: string = "커튼 개폐 축";
  public static LB_CURTAIN_SWITCH_FRAME: string = "커튼 수평 축";
  public static LB_CURTAIN_SWITCH_LINE: string = "커튼 선";
  public static LB_CURTAIN_SWITCH_SUPPORT_VERTICAL: string = "수직형 지지";
  public static LB_CURTAIN_SWITCH_FIXING_CLIP: string = "클립 고정";
  public static LB_CURTAIN_SWITCH_FIXING_TRUSS_CLIP: string = "트러스 클립 고정";
  public static VL_CURTAIN_SWITCH_FIXING_TRUSS_CLIP: string = "트러스 클립 고정";
  public static DC_CURTAIN_SWITCH_FIXING_TRUSS_CLIP: string = "";

  // 천창 골조
  public static LB_SKYLIGHT_FRAME_RAFTER: string = "천창 서까래";
  public static LB_SKYLIGHT_FRAME_STRAIGHT: string = "천창 가로대";
  public static LB_SKYLIGHT_FRAME_ENHANCED: string = "골조 보강";
  public static LB_SKYLIGHT_FRAME_RACKPINION: string = "천창 랙피니언 골조";
  public static LB_SKYLIGHT_FRAME_CUSTOM: string = "임의 품목";

  // 천창 개폐
  public static LB_SKYLIGHT_SWITCH_MANUAL: string = "천창 수동 개폐기";
  public static LB_SKYLIGHT_SWITCH_POWER: string = "천창 동력 개폐기";
  public static LB_SKYLIGHT_SWITCH_RACKPINION: string = "천창 랙피니언 개폐";
  public static LB_SKYLIGHT_SWITCH_AXIS: string = "천창 개폐 축";
  public static LB_SKYLIGHT_SWITCH_SUPPORT_DIAGONAL: string = "대각형 지지";
  public static LB_SKYLIGHT_SWITCH_FIXING_NORMAL_CLIP: string = "일반 클립 고정";

  // 천창 피복
  public static LB_SKYLIGHT_COVER_VINYL: string = "천창 비닐";
  public static LB_SKYLIGHT_COVER_SKYLIGHT_COVER: string = "천창 피복";
  public static LB_SKYLIGHT_COVER_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_SKYLIGHT_COVER_SCREENNET: string = "천창 방충망";

  // 환기 외부 배기
  public static LB_VENT_EXHAUST_ENDPIECE_FAN: string = "앞뒷면 배기팬";
  public static LB_VENT_EXHAUST_ROOF_FAN_POWER: string = "지붕 환기팬(동력)";
  public static LB_VENT_EXHAUST_ROOF_FAN_MANUAL: string = "지붕 환기구(수동)";
  public static LB_VENT_EXHAUST_CUSTOM: string = "임의 품목";

  // 환기 내부 환기
  public static LB_VENT_INSIDE_AIR_FLOW_FAN: string = "내부 유동팬";
  public static LB_VENT_INSIDE_CUSTOM: string = "임의 품목";

  // 칸막이
  public static LB_PARTITION_STUD: string = "칸막이 샛기둥";
  public static LB_PARTITION_MIDDLE: string = "칸막이 중방";
  public static LB_PARTITION_GATE: string = "칸막이 출입문";
  public static LB_PARTITION_COVER: string = "칸막이 피복";
  public static LB_PARTITION_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_PARTITION_FIXING_NORMAL_CLIP: string = "일반 클립 고정";
  public static LB_PARTITION_FIXING_LAGGING_PAD: string = "광폭 패드 고정";
  public static LB_PARTITION_FIXING_WOOD: string = "목재 고정";
  public static LB_PARTITION_CUSTOM: string = "임의 품목";

  // 창문
  public static LB_WINDOW_HINGED: string = "여닫이창문";
  public static LB_WINDOW_SASH: string = "샷시창문";
  public static LB_WINDOW_FIXING_NORMAL_PAD: string = "일반 패드 고정";
  public static LB_WINDOW_FIXING_LAGGING_PAD: string = "광폭 패드 고정";
  public static LB_WINDOW_FIXING_WOOD: string = "목재 고정";

  // 작업동틀
  public static LB_WORKROOM_CUSTOM: string = "임의 품목";

  // 기타 자재
  public static LB_MATERIAL_CUSTOM: string = "임의 품목";

  // 관수 제어부
  public static LB_WATERING_MAINPIPE_PE_MAIN1: string = "PE 주관1(관정~여과기)";
  public static LB_WATERING_MAINPIPE_PE_MAIN2: string = "PE 주관2(여과기~끝)";
  public static LB_WATERING_MAINPIPE_CUSTOM: string = "임의 품목";
  public static LB_WATERING_MAINPIPE_UPPER_FRAME: string = "상단관수 골조";

  // 관수 지관부
  public static LB_WATERING_WATERING_MINIKLER_SCREEN: string = "수막 미니클러";
  public static LB_WATERING_WATERING_MINIKLER_FOUNTAIN: string = "분수 미니클러";
  public static LB_WATERING_WATERING_MINIKLER_FOG: string = "안개(포그) 미니클러";
  public static LB_WATERING_WATERING_HOSE_SCREEN: string = "수막 분수호스";
  public static LB_WATERING_WATERING_HOSE_UPPER: string = "고설 분수호스";
  public static LB_WATERING_WATERING_HOSE_SIDE: string = "측설 분수호스";
  public static LB_WATERING_WATERING_HOSE_LOWER: string = "저설 분수호스";
  public static LB_WATERING_WATERING_HOSE_MULCHING: string = "관수(멀칭) 분수호스";
  public static LB_WATERING_WATERING_HOSE_CONNECT: string = "분수호스 통합연결";
  public static LB_WATERING_WATERING_DRIP_WATERING: string = "점적관수";
  public static LB_WATERING_WATERING_DRIP_CONNECT: string = "점적관수 연결";
  public static LB_WATERING_WATERING_SCREEN_GUTTER: string = "수막 물받이";

  // 양액 제어부
  public static LB_HYD_SYSTEM_CONTROL: string = "양액 제어";
  public static LB_HYD_SYSTEM_SUPPLY: string = "양액 공급";
  public static LB_HYD_SYSTEM_DRAIN: string = "양액 배수/회수";
  public static LB_HYD_SYSTEM_CUSTOM: string = "임의 품목";

  // 양액 베드부
  public static LB_HYD_CULTIVATION_BED: string = "베드";
  public static LB_HYD_CULTIVATION_CULTIVATION_MATERIAL: string = "재배자재";
  public static LB_HYD_CULTIVATION_GROUND_RAIL: string = "지면 레일";
  public static LB_HYD_CULTIVATION_CUSTOM: string = "임의 품목";

  // 냉난방 난방
  public static LB_TEM_HEATING_BOILER: string = "보일러";
  public static LB_TEM_HEATING_FAN: string = "열풍기";
  public static LB_TEM_HEATING_TUBE: string = "히팅튜브";

  // 냉난방 냉방
  public static LB_TEM_COOLING_CUSTOM: string = "임의 품목";

  // 스마트팜 복합환경제어
  public static LB_ENVIRONMENT_CUSTOM: string = "임의 품목";

  // 운반레일
  public static LB_RAIL_UPPER: string = "상단 레일";
  public static LB_RAIL_SIDE: string = "측면 레일";
  public static LB_RAIL_GROUND: string = "지면 레일";
  public static LB_RAIL_CARRIER: string = "운반구";
  public static LB_RAIL_CUSTOM: string = "임의 품목";

  // 선반/균상다이
  public static LB_RACK_RACK: string = "선반/균상다이";
  public static LB_RACK_CUSTOM: string = "임의 품목";

  // CO2
  public static LB_CO2_CUSTOM: string = "임의 품목";

  // 자동방제기
  public static LB_AUTOSPRAY_CUSTOM: string = "임의 품목";

  // 기타 기계/설비
  public static LB_OTHER_FACILITIES_CUSTOM: string = "임의 품목";

  // 통합 시공비
  public static LB_BUILDING_CUSTOM: string = "임의 비용";
  public static LB_BUILDING_PER_AREA: string = "면적당 비용";

  //----------------------------------
  // 파트 아이디 - 단동
  //----------------------------------

  // 기초
  public static ID_SG_FOUNDATION_CUSTOM: string = "sg_foud_custom";

  // 기초 골조
  public static ID_SG_TRUNK_RAFTER: string = "sg_trnk_rafter";
  public static ID_SG_TRUNK_STRAIGHT: string = "sg_trnk_straight";
  public static ID_SG_TRUNK_COLUMN: string = "sg_trnk_column";

  // 마구리 골조
  public static ID_SG_ENDPIECE_STUD: string = "sg_endp_stud";
  public static ID_SG_ENDPIECE_MIDDLE: string = "sg_endp_middle";
  public static ID_SG_ENDPIECE_GATE: string = "sg_endp_gate";

  // 물받이
  public static ID_SG_GUTTER_CUSTOM: string = "sg_gutr_custom";

  // 개폐기
  public static ID_SG_SWITCHER_MANUAL1: string = "sg_swit_manual1";
  public static ID_SG_SWITCHER_MANUAL2: string = "sg_swit_manual2";
  public static ID_SG_SWITCHER_POWER1: string = "sg_swit_power1";
  public static ID_SG_SWITCHER_POWER2: string = "sg_swit_power2";
  public static ID_SG_SWITCHER_AXIS: string = "sg_swit_axis";
  public static ID_SG_SWITCHER_SUPPORT: string = "sg_swit_support";
  public static ID_SG_SWITCHER_SUPPORT_VERTICAL: string = "sg_swit_support_vertical";
  public static ID_SG_SWITCHER_SUPPORT_DIAGONAL: string = "sg_swit_support_diagonal";
  public static ID_SG_SWITCHER_SUPPORT_RAFTER: string = "sg_swit_support_rafter";
  public static ID_SG_SWITCHER_FIXING_NORMAL_PAD: string = "sg_swit_fixing_normal_pad";
  public static ID_SG_SWITCHER_FIXING_NORMAL_CLIP: string = "sg_swit_fixing_normal_clip";
  public static ID_SG_SWITCHER_FIXING_LAGGING_PAD: string = "sg_swit_fixing_lagging_pad";

  // 제어기
  public static ID_SG_CONTROLLER_DEFAULT: string = "sg_cont_default";

  // 기초 피복
  public static ID_SG_COVER_VINYL: string = "sg_covr_vinyl";
  public static ID_SG_COVER_WEAVING_FILM: string = "sg_covr_weaving_film";
  public static ID_SG_COVER_SHADENET: string = "sg_covr_shadenet";
  public static ID_SG_COVER_TENT: string = "sg_covr_tent";
  public static ID_SG_COVER_NONWOVEN: string = "sg_covr_nonwoven";
  public static ID_SG_COVER_CASHMILON: string = "sg_covr_cashmilon";
  public static ID_SG_COVER_LAGGING_COLOR: string = "sg_covr_lagging_color";
  public static ID_SG_COVER_LAGGING: string = "sg_covr_lagging";
  public static ID_SG_COVER_QUILTING: string = "sg_covr_quilting";
  public static ID_SG_COVER_SCREEN: string = "sg_covr_screen";
  public static ID_SG_COVER_MAT: string = "sg_covr_mat";

  // 피복 고정
  public static ID_SG_FIXING_NORMAL_PAD: string = "sg_fixg_normal_pad";
  public static ID_SG_FIXING_NORMAL_CLIP: string = "sg_fixg_normal_clip";
  public static ID_SG_FIXING_LAGGING_PAD: string = "sg_fixg_lagging_pad";
  public static ID_SG_FIXING_WOOD: string = "sg_fixg_wood";

  // 치마 피복
  public static ID_SG_SKIRT_NORMAL: string = "sg_skrt_normal";
  public static ID_SG_SKIRT_STRING: string = "sg_skrt_string";
  public static ID_SG_SKIRT_FIXING_NORMAL_PAD: string = "sg_skrt_fixing_normal_pad";
  public static ID_SG_SKIRT_FIXING_NORMAL_CLIP: string = "sg_skrt_fixing_normal_clip";
  public static ID_SG_SKIRT_FIXING_LAGGING_PAD: string = "sg_skrt_fixing_lagging_pad";
  public static ID_SG_SKIRT_FIXING_WOOD: string = "sg_skrt_fixing_wood";
  public static ID_SG_SKIRT_FIXING_SKIRT_CLIP: string = "sg_skrt_fixing_skirt_clip";

  // 바람막이 피복
  public static ID_SG_WINDBREAK_DEFAULT: string = "sg_wind_default";
  public static ID_SG_WINDBREAK_FIXING_NORMAL_PAD: string = "sg_wind_fixing_normal_pad";
  public static ID_SG_WINDBREAK_FIXING_NORMAL_CLIP: string = "sg_wind_fixing_normal_clip";
  public static ID_SG_WINDBREAK_FIXING_LAGGING_PAD: string = "sg_wind_fixing_lagging_pad";
  public static ID_SG_WINDBREAK_FIXING_WOOD: string = "sg_wind_fixing_wood";

  // 방충망
  public static ID_SG_SCREENNET_DEFAULT: string = "sg_scrn_default";
  public static ID_SG_SCREENNET_FIXING_NORMAL_PAD: string = "sg_scrn_fixing_normal_pad";
  public static ID_SG_SCREENNET_FIXING_LAGGING_PAD: string = "sg_scrn_fixing_lagging_pad";
  public static ID_SG_SCREENNET_FIXING_WOOD: string = "sg_scrn_fixing_wood";

  // 밴드끈
  public static ID_SG_BANDSTRING_BANDSTRING: string = "sg_bandstring";
  public static ID_SG_BANDSTRING_NORMAL: string = "sg_band_normal";
  public static ID_SG_BANDSTRING_POLY: string = "sg_band_poly";
  public static ID_SG_BANDSTRING_FIXING_NORMAL_PAD: string = "sg_band_fixing_normal_pad";
  public static ID_SG_BANDSTRING_FIXING_STAKE: string = "sg_band_fixing_stake";
  public static ID_SG_BANDSTRING_FIXING_HOOK: string = "sg_band_fixing_hook";
  public static ID_SG_BANDSTRING_ENHANCED_STAKE: string = "sg_band_enhanced_stake";

  //----------------------------------
  // 파트 아이디 - 연동
  //----------------------------------

  // 기초
  public static ID_IL_FOUNDATION_GIRTH_PIPE: string = "il_foud_girth_pipe";
  public static ID_IL_FOUNDATION_COLUMN_CONCRETE: string = "il_foud_column_concrete";

  // 기둥 골조
  public static ID_IL_COLUMN_CYLINDER_COLUMN: string = "il_coln_cylinder_column";
  public static ID_IL_COLUMN_CYLINDER_BEAM: string = "il_coln_cylinder_beam";
  public static ID_IL_COLUMN_CYLINDER_MIDDLE: string = "il_coln_cylinder_middle";
  public static ID_IL_COLUMN_PRISM_COLUMN: string = "il_coln_prism_column";
  public static ID_IL_COLUMN_PRISM_BEAM: string = "il_coln_prism_beam";
  public static ID_IL_COLUMN_PRISM_MIDDLE: string = "il_coln_prism_middle";
  public static ID_IL_COLUMN_SIDE_STRAIGHT: string = "il_coln_side_straight";

  // 지붕 골조
  public static ID_IL_ROOF_RAFTER: string = "il_roof_rafter";
  public static ID_IL_ROOF_STRAIGHT: string = "il_roof_straight";
  public static ID_IL_ROOF_BRACING: string = "il_roof_bracing";

  // 방풍벽 골조
  public static ID_IL_WING_RAFTER: string = "il_wing_rafter";
  public static ID_IL_WING_STRAIGHT: string = "il_wing_straight";

  // 마구리 골조
  public static ID_IL_ENDPIECE_CYLINDER_STUD: string = "il_endp_cylinder_stud";
  public static ID_IL_ENDPIECE_CYLINDER_STUD_SEP: string = "il_endp_cylinder_stud_sep";
  public static ID_IL_ENDPIECE_CYLINDER_MIDDLE: string = "il_endp_cylinder_middle";
  public static ID_IL_ENDPIECE_PRISM_STUD_SEP: string = "il_endp_prism_stud_sep";
  public static ID_IL_ENDPIECE_PRISM_MIDDLE: string = "il_endp_prism_middle";
  public static ID_IL_ENDPIECE_ENDPIECE: string = "il_endp_endp";
  public static ID_IL_ENDPIECE_GATE: string = "il_endp_gate";

  // 물받이
  public static ID_IL_GUTTER_IRON_PLATE: string = "il_gutr_iron_plate";
  public static ID_IL_GUTTER_PLASTIC: string = "il_gutr_plastic";
  public static ID_IL_GUTTER_WEAVING_FILM: string = "il_gutr_weavingFilm";
  public static ID_IL_GUTTER_FIXING_GUTTER_PAD: string = "il_gutr_fixing_gutter_pad";
  public static ID_IL_GUTTER_FIXING_SCREW: string = "il_gutr_fixing_screw";
  public static ID_IL_GUTTER_FIXING_NORMAL_CLIP: string = "il_gutr_fixing_normal_clip";
  public static ID_IL_GUTTER_DRAINAGE_PLEATED_PIPE: string = "il_gutr_drainage_pleated_pipe";
  public static ID_IL_GUTTER_DRAINAGE_PVC_PIPE: string = "il_gutr_drainage_pvc_pipe";
  public static ID_IL_GUTTER_CONDENSATION: string = "il_gutr_condensation";

  // 개폐기
  public static ID_IL_SWITCHER_MANUAL1: string = "il_swit_manual1";
  public static ID_IL_SWITCHER_MANUAL2: string = "il_swit_manual2";
  public static ID_IL_SWITCHER_POWER1: string = "il_swit_power1";
  public static ID_IL_SWITCHER_POWER2: string = "il_swit_power2";
  public static ID_IL_SWITCHER_AXIS: string = "il_swit_axis";
  public static ID_IL_SWITCHER_SUPPORT: string = "il_swit_support";
  public static ID_IL_SWITCHER_SUPPORT_VERTICAL: string = "il_swit_support_vertical";
  public static ID_IL_SWITCHER_SUPPORT_DIAGONAL: string = "il_swit_support_diagonal";
  public static ID_IL_SWITCHER_FIXING_NORMAL_PAD: string = "il_swit_fixing_normal_pad";
  public static ID_IL_SWITCHER_FIXING_NORMAL_CLIP: string = "il_swit_fixing_normal_clip";
  public static ID_IL_SWITCHER_FIXING_LAGGING_PAD: string = "il_swit_fixing_lagging_pad";

  // 제어기
  public static ID_IL_CONTROLLER_DEFAULT: string = "il_cont_default";

  // 기초 피복
  public static ID_IL_COVER_VINYL: string = "il_covr_vinyl";
  public static ID_IL_COVER_WEAVING_FILM: string = "il_covr_weaving_film";
  public static ID_IL_COVER_SHADENET: string = "il_covr_shadenet";
  public static ID_IL_COVER_TENT: string = "il_covr_tent";
  public static ID_IL_COVER_NONWOVEN: string = "il_covr_nonwoven";
  public static ID_IL_COVER_CASHMILON: string = "il_covr_cashmilon";
  public static ID_IL_COVER_LAGGING_COLOR: string = "il_covr_lagging_color";
  public static ID_IL_COVER_LAGGING: string = "il_covr_lagging";
  public static ID_IL_COVER_QUILTING: string = "il_covr_quilting";
  public static ID_IL_COVER_SCREEN: string = "il_covr_screen";

  // 피복 고정
  public static ID_IL_FIXING_NORMAL_PAD: string = "il_fixg_normal_pad";
  public static ID_IL_FIXING_NORMAL_CLIP: string = "il_fixg_normal_clip";
  public static ID_IL_FIXING_LAGGING_PAD: string = "il_fixg_lagging_pad";
  public static ID_IL_FIXING_WOOD: string = "il_fixg_wood";
  public static ID_IL_FIXING_CONDENSATION_PAD: string = "il_fixg_condensation_pad";

  // 치마 피복
  public static ID_IL_SKIRT_NORMAL: string = "il_skrt_normal";
  public static ID_IL_SKIRT_STRING: string = "il_skrt_string";
  public static ID_IL_SKIRT_FIXING_NORMAL_PAD: string = "il_skrt_fixing_normal_pad";
  public static ID_IL_SKIRT_FIXING_NORMAL_CLIP: string = "il_skrt_fixing_normal_clip";
  public static ID_IL_SKIRT_FIXING_LAGGING_PAD: string = "il_skrt_fixing_lagging_pad";
  public static ID_IL_SKIRT_FIXING_WOOD: string = "il_skrt_fixing_wood";
  public static ID_IL_SKIRT_FIXING_SKIRT_CLIP: string = "il_skrt_fixing_skirt_clip";

  // 바람막이 피복
  public static ID_IL_WINDBREAK_DEFAULT: string = "il_wind_default";
  public static ID_IL_WINDBREAK_FIXING_NORMAL_PAD: string = "il_wind_fixing_normal_pad";
  public static ID_IL_WINDBREAK_FIXING_NORMAL_CLIP: string = "il_wind_fixing_normal_clip";
  public static ID_IL_WINDBREAK_FIXING_LAGGING_PAD: string = "il_wind_fixing_lagging_pad";
  public static ID_IL_WINDBREAK_FIXING_WOOD: string = "il_wind_fixing_wood";

  // 방충망
  public static ID_IL_SCREENNET_DEFAULT: string = "il_scrn_default";
  public static ID_IL_SCREENNET_FIXING_NORMAL_PAD: string = "il_scrn_fixing_normal_pad";
  public static ID_IL_SCREENNET_FIXING_LAGGING_PAD: string = "il_scrn_fixing_lagging_pad";
  public static ID_IL_SCREENNET_FIXING_WOOD: string = "il_scrn_fixing_wood";

  // 밴드끈
  public static ID_IL_BANDSTRING_BANDSTRING: string = "il_bandstring";
  public static ID_IL_BANDSTRING_POLY: string = "il_band_poly";
  public static ID_IL_BANDSTRING_FIXING_NORMAL_PAD: string = "il_band_fixing_normal_pad";
  public static ID_IL_BANDSTRING_FIXING_HOOK: string = "il_band_fixing_hook";

  //----------------------------------
  // 파트 아이디 - 공통 구조
  //----------------------------------

  // 커튼 피복
  public static ID_CM_CURTAIN_COVER_QUILTING: string = "cm_curcvr_quilting";
  public static ID_CM_CURTAIN_COVER_NONWOVEN: string = "cm_curcvr_nonwoven";
  public static ID_CM_CURTAIN_COVER_MAT: string = "cm_curcvr_mat";
  public static ID_CM_CURTAIN_COVER_SHADENET: string = "cm_curcvr_shadenet";
  public static ID_CM_CURTAIN_COVER_SCREEN: string = "cm_curcvr_screen";
  public static ID_CM_CURTAIN_FIXING_NORMAL_PAD: string = "cm_curcvr_fixing_normal_pad";
  public static ID_CM_CURTAIN_SIDE_ENDPIECE_COVER_QUILTING: string = "cm_cursidenp_quilting";
  public static ID_CM_CURTAIN_CEILING_LEVEL2_COVER_QUILTING: string = "cm_curceilv2cvr_quilting";
  public static ID_CM_CURTAIN_CEILING_LEVEL2_COVER_SHADENET: string = "cm_curceilv2cvr_shadenet";
  public static ID_CM_CURTAIN_CEILING_LEVEL2_COVER_SCREEN: string = "cm_curceilv2cvr_screen";
  public static ID_CM_CURTAIN_CEILING_LEVEL3_COVER_QUILTING: string = "cm_curceilv3cvr_quilting";
  public static ID_CM_CURTAIN_CEILING_LEVEL3_COVER_SHADENET: string = "cm_curceilv3cvr_shadenet";
  public static ID_CM_CURTAIN_CEILING_LEVEL3_COVER_SCREEN: string = "cm_curceilv3cvr_screen";

  // 커튼 개폐
  public static ID_CM_CURTAIN_SWITCH_POWER: string = "cm_curswt_power";
  public static ID_CM_CURTAIN_SWITCH_MOTOR: string = "cm_curswt_motor";
  public static ID_CM_CURTAIN_SWITCH_AXIS: string = "cm_curswt_axis";
  public static ID_CM_CURTAIN_SWITCH_FRAME: string = "cm_curswt_frame";
  public static ID_CM_CURTAIN_SWITCH_FRAME_VL: string = "cm_curswt_frame_vl";
  public static ID_CM_CURTAIN_SWITCH_LINE: string = "cm_curswt_line";
  public static ID_CM_CURTAIN_SWITCH_SUPPORT_VERTICAL: string = "cm_curswt_support_vertical";
  public static ID_CM_CURTAIN_SWITCH_FIXING_CLIP_SIDE: string = "cm_curswt_fixing_clip_side";
  public static ID_CM_CURTAIN_SWITCH_FIXING_CLIP_CEILING: string = "cm_curswt_fixing_clip_ceiling";

  // 천창 골조
  public static ID_CM_SKYLIGHT_FRAME_RAFTER: string = "cm_skyfrm_rafter";
  public static ID_CM_SKYLIGHT_FRAME_STRAIGHT: string = "cm_skyfrm_straight";
  public static ID_CM_SKYLIGHT_FRAME_ENHANCED: string = "cm_skyfrm_enhanced";
  public static ID_CM_SKYLIGHT_FRAME_RACKPINION: string = "cm_skyfrm_rackpinion";
  public static ID_CM_SKYLIGHT_FRAME_CUSTOM: string = "cm_skyfrm_custom";

  // 천창 개폐
  public static ID_CM_SKYLIGHT_SWITCH_MANUAL: string = "cm_skyswt_manual";
  public static ID_CM_SKYLIGHT_SWITCH_POWER: string = "cm_skyswt_power";
  public static ID_CM_SKYLIGHT_SWITCH_RACKPINION: string = "cm_skyswt_rackpinion";
  public static ID_CM_SKYLIGHT_SWITCH_AXIS: string = "cm_skyswt_axis";
  public static ID_CM_SKYLIGHT_SWITCH_SUPPORT_DIAGONAL: string = "cm_skyswt_support_diagonal";
  public static ID_CM_SKYLIGHT_SWITCH_FIXING_NORMAL_CLIP: string = "cm_skyswt_fixing_normal_clip";

  // 천창 피복
  public static ID_CM_SKYLIGHT_COVER_VINYL: string = "cm_skycvr_vinyl";
  public static ID_CM_SKYLIGHT_COVER_SKYLIGHT_COVER: string = "cm_skycvr_skylight_cover";
  public static ID_CM_SKYLIGHT_COVER_FIXING_NORMAL_PAD: string = "cm_skycvr_fixing_normal_pad";
  public static ID_CM_SKYLIGHT_COVER_SCREENNET: string = "cm_skycvr_screennet";

  // 환기 외부 배기
  public static ID_CM_VENT_EXHAUST_ENDPIECE_FAN: string = "cm_venext_endpiece_fan";
  public static ID_CM_VENT_EXHAUST_ROOF_FAN_POWER: string = "cm_venext_roof_fan_power";
  public static ID_CM_VENT_EXHAUST_ROOF_FAN_MANUAL: string = "cm_venext_roof_fan_manual";
  public static ID_CM_VENT_EXHAUST_CUSTOM: string = "cm_venext_custom";

  // 환기 내부 환기
  public static ID_CM_VENT_INSIDE_AIR_FLOW_FAN: string = "cm_venins_air_flow_fan";
  public static ID_CM_VENT_INSIDE_CUSTOM: string = "cm_venins_custom";

  // 관수 제어부
  public static ID_CM_WATERING_MAINPIPE_PE_MAIN1: string = "cm_watman_pe_main1";
  public static ID_CM_WATERING_MAINPIPE_PE_MAIN2: string = "cm_watman_pe_main2";
  public static ID_CM_WATERING_MAINPIPE_CUSTOM: string = "cm_watman_custom";
  public static ID_CM_WATERING_MAINPIPE_UPPER_FRAME: string = "cm_watman_upper_frame";

  // 관수 지관부
  public static ID_CM_WATERING_WATERING_MINIKLER_SCREEN: string = "cm_watdet_minikler_screen";
  public static ID_CM_WATERING_WATERING_MINIKLER_FOUNTAIN: string = "cm_watdet_minikler_fountain";
  public static ID_CM_WATERING_WATERING_MINIKLER_FOG: string = "cm_watdet_minikler_fog";
  public static ID_CM_WATERING_WATERING_HOSE_SCREEN: string = "cm_watdet_hose_screen";
  public static ID_CM_WATERING_WATERING_HOSE_UPPER: string = "cm_watdet_hose_upper";
  public static ID_CM_WATERING_WATERING_HOSE_SIDE: string = "cm_watdet_hose_side";
  public static ID_CM_WATERING_WATERING_HOSE_LOWER: string = "cm_watdet_hose_lower";
  public static ID_CM_WATERING_WATERING_HOSE_MULCHING: string = "cm_watdet_hose_mulching";
  public static ID_CM_WATERING_WATERING_HOSE_CONNECT: string = "cm_watdet_hose_connect";
  public static ID_CM_WATERING_WATERING_DRIP_WATERING: string = "cm_watdet_drip_watering";
  public static ID_CM_WATERING_WATERING_DRIP_CONNECT: string = "cm_watdet_drip_connect";
  public static ID_CM_WATERING_WATERING_SCREEN_GUTTER: string = "cm_watdet_screen_gutter";

  // 양액 제어부
  public static ID_CM_HYD_SYSTEM_CONTROL: string = "cm_hydsys_control";
  public static ID_CM_HYD_SYSTEM_SUPPLY: string = "cm_hydsys_supply";
  public static ID_CM_HYD_SYSTEM_DRAIN: string = "cm_hydsys_drain";
  public static ID_CM_HYD_SYSTEM_CUSTOM: string = "cm_hydsys_custom";

  // 양액 베드부
  public static ID_CM_HYD_CULTIVATION_BED: string = "cm_hydcul_bed";
  public static ID_CM_HYD_CULTIVATION_CULTIVATION_MATERIAL: string = "cm_hydcul_cultivation_material";
  public static ID_CM_HYD_CULTIVATION_GROUND_RAIL: string = "cm_hydcul_ground_rail";
  public static ID_CM_HYD_CULTIVATION_CUSTOM: string = "cm_hydcul_custom";

  // 냉난방 난방
  public static ID_CM_TEM_HEATING_BOILER: string = "cm_temhet_boiler";
  public static ID_CM_TEM_HEATING_FAN: string = "cm_temhet_heating_fan";
  public static ID_CM_TEM_HEATING_TUBE: string = "cm_temhet_heating_tube";

  // 냉난방 냉방
  public static ID_CM_TEM_COOLING_CUSTOM: string = "cm_temcol_custom";

  // 스마트팜 복합환경제어
  public static ID_CM_ENVIRONMENT_CUSTOM: string = "cm_comenv_custom";

  // 칸막이
  public static ID_CM_PARTITION_CUSTOM: string = "cm_part_custom";
  public static ID_CM_PARTITION_STUD: string = "cm_part_stud";
  public static ID_CM_PARTITION_MIDDLE: string = "cm_part_middle";
  public static ID_CM_PARTITION_GATE: string = "cm_part_gate";
  public static ID_CM_PARTITION_COVER: string = "cm_part_cover";
  public static ID_CM_PARTITION_FIXING_NORMAL_PAD: string = "cm_part_fixing_normal_pad";
  public static ID_CM_PARTITION_FIXING_NORMAL_CLIP: string = "cm_part_fixing_normal_clip";
  public static ID_CM_PARTITION_FIXING_LAGGING_PAD: string = "cm_part_fixing_lagging_pad";
  public static ID_CM_PARTITION_FIXING_WOOD: string = "cm_part_fixing_wood";

  // 창문
  public static ID_CM_WINDOW_HINGED: string = "cm_wind_hinged";
  public static ID_CM_WINDOW_SASH: string = "cm_wind_sash";
  public static ID_CM_WINDOW_FIXING_NORMAL_PAD: string = "cm_wind_fixing_normal_pad";
  public static ID_CM_WINDOW_FIXING_LAGGING_PAD: string = "cm_wind_fixing_lagging_pad";
  public static ID_CM_WINDOW_FIXING_WOOD: string = "cm_wind_fixing_wood";

  // 작업동
  public static ID_CM_WORKROOM_CUSTOM: string = "cm_wkrom_custom";

  // 기타 자재
  public static ID_CM_MATERIAL_CUSTOM: string = "cm_matr_custom";

  // 선반/균상다이
  public static ID_CM_RACK_RACK: string = "cm_rack_rack";
  public static ID_CM_RACK_CUSTOM: string = "cm_rack_custom";

  // 운반레일
  public static ID_CM_RAIL_UPPER: string = "cm_rail_upper";
  public static ID_CM_RAIL_SIDE: string = "cm_rail_side";
  public static ID_CM_RAIL_GROUND: string = "cm_rail_ground";
  public static ID_CM_RAIL_CARRIER: string = "cm_rail_carrier";
  public static ID_CM_RAIL_CUSTOM: string = "cm_rail_custom";

  // CO2
  public static ID_CM_CO2_CUSTOM: string = "cm_co2_custom";

  // 자동방제기
  public static ID_CM_AUTOSPRAY_CUSTOM: string = "cm_spry_custom";

  // 자동방제기
  public static ID_CM_FACILITY_CUSTOM: string = "cm_faci_custom";

  // 통합 시공비
  public static ID_CM_BUILDING_CUSTOM: string = "cm_buil_custom";
  public static ID_CM_BUILDING_PER_AREA: string = "cm_buil_per_area";

  //----------------------------------
  // 파트 인덱스 - 단동
  //----------------------------------

  // 기초
  public static INDEX_SG_FOUNDATION_CUSTOM: number = 0;

  // 기초 골조
  public static INDEX_SG_TRUNK_RAFTER: number = 0;
  public static INDEX_SG_TRUNK_STRAIGHT: number = 1;
  public static INDEX_SG_TRUNK_COLUMN: number = 2;

  // 마구리 골조
  public static INDEX_SG_ENDPIECE_STUD: number = 0;
  public static INDEX_SG_ENDPIECE_MIDDLE: number = 1;
  public static INDEX_SG_ENDPIECE_GATE: number = 2;

  // 물받이
  public static INDEX_SG_GUTTER_CUSTOM: number = 0;

  // 개폐기
  public static INDEX_SG_SWITCHER_MANUAL1: number = 0;
  public static INDEX_SG_SWITCHER_MANUAL2: number = 1;
  public static INDEX_SG_SWITCHER_POWER1: number = 2;
  public static INDEX_SG_SWITCHER_POWER2: number = 3;
  public static INDEX_SG_SWITCHER_AXIS: number = 4;
  public static INDEX_SG_SWITCHER_SUPPORT: number = 5;
  public static INDEX_SG_SWITCHER_FIXING_NORMAL_CLIP: number = 6;

  // 제어기
  public static INDEX_SG_CONTROLLER_DEFAULT: number = 0;

  // 기초 피복
  public static INDEX_SG_COVER_VINYL: number = 0;
  public static INDEX_SG_COVER_WEAVING_FILM: number = 1;
  public static INDEX_SG_COVER_SHADENET: number = 2;
  public static INDEX_SG_COVER_TENT: number = 3;
  public static INDEX_SG_COVER_NONWOVEN: number = 4;
  public static INDEX_SG_COVER_CASHMILON: number = 5;
  public static INDEX_SG_COVER_LAGGING_COLOR: number = 6;
  public static INDEX_SG_COVER_LAGGING: number = 7;
  public static INDEX_SG_COVER_QUILTING: number = 8;
  public static INDEX_SG_COVER_SCREEN: number = 9;
  public static INDEX_SG_COVER_MAT: number = 10;

  // 피복 고정
  public static INDEX_SG_FIXING_NORMAL_PAD: number = 0;
  public static INDEX_SG_FIXING_NORMAL_CLIP: number = 1;
  public static INDEX_SG_FIXING_LAGGING_PAD: number = 2;
  public static INDEX_SG_FIXING_WOOD: number = 3;

  // 치마 피복
  public static INDEX_SG_SKIRT_NORMAL: number = 0;
  public static INDEX_SG_SKIRT_STRING: number = 1;
  public static INDEX_SG_SKIRT_FIXING_NORMAL_PAD: number = 2;
  public static INDEX_SG_SKIRT_FIXING_NORMAL_CLIP: number = 3;
  public static INDEX_SG_SKIRT_FIXING_LAGGING_PAD: number = 4;
  public static INDEX_SG_SKIRT_FIXING_WOOD: number = 5;
  public static INDEX_SG_SKIRT_FIXING_SKIRT_CLIP: number = 6;

  // 바람막이 피복
  public static INDEX_SG_WINDBREAK_DEFAULT: number = 0;
  public static INDEX_SG_WINDBREAK_FIXING_NORMAL_PAD: number = 1;
  public static INDEX_SG_WINDBREAK_FIXING_NORMAL_CLIP: number = 2;
  public static INDEX_SG_WINDBREAK_FIXING_LAGGING_PAD: number = 3;
  public static INDEX_SG_WINDBREAK_FIXING_WOOD: number = 4;

  // 방충망
  public static INDEX_SG_SCREENNET_DEFAULT: number = 0;
  public static INDEX_SG_SCREENNET_FIXING_NORMAL_PAD: number = 1;
  public static INDEX_SG_SCREENNET_FIXING_LAGGING_PAD: number = 2;
  public static INDEX_SG_SCREENNET_FIXING_WOOD: number = 3;

  // 밴드끈
  public static INDEX_SG_BANDSTRING_BANDSTRING: number = 0;
  public static INDEX_SG_BANDSTRING_NORMAL: number = 1;
  public static INDEX_SG_BANDSTRING_POLY: number = 2;
  public static INDEX_SG_BANDSTRING_FIXING_NORMAL_PAD: number = 3;
  public static INDEX_SG_BANDSTRING_FIXING_STAKE: number = 4;
  public static INDEX_SG_BANDSTRING_FIXING_HOOK: number = 5;
  public static INDEX_SG_BANDSTRING_ENHANCED_STAKE: number = 6;

  // 천창 골조
  public static INDEX_SG_SKYLIGHT_FRAME_RAFTER: number = 0;
  public static INDEX_SG_SKYLIGHT_FRAME_STRAIGHT: number = 1;
  public static INDEX_SG_SKYLIGHT_FRAME_ENHANCED: number = 2;
  public static INDEX_SG_SKYLIGHT_FRAME_RACKPINION: number = 3;
  public static INDEX_SG_SKYLIGHT_FRAME_CUSTOM: number = 4;

  // 천창 개폐
  public static INDEX_SG_SKYLIGHT_SWITCH_MANUAL: number = 0;
  public static INDEX_SG_SKYLIGHT_SWITCH_POWER: number = 1;
  public static INDEX_SG_SKYLIGHT_RACKPINION: number = 2;
  public static INDEX_SG_SKYLIGHT_SWITCH_AXIS: number = 3;
  public static INDEX_SG_SKYLIGHT_SWITCH_SUPPORT_DIAGONAL: number = 4;
  public static INDEX_SG_SKYLIGHT_SWITCH_FIXING_NORMAL_CLIP: number = 5;

  // 천창 피복
  public static INDEX_SG_SKYLIGHT_COVER_SKYLIGTH_COVER: number = 0;
  public static INDEX_SG_SKYLIGHT_COVER_FIXING_NORMAL_PAD: number = 1;
  public static INDEX_SG_SKYLIGHT_COVER_SCREENNET: number = 2;

  //----------------------------------
  // 파트 인덱스 - 연동
  //----------------------------------

  // 기초
  public static INDEX_IL_FOUNDATION_GIRTH_PIPE: number = 0;
  public static INDEX_IL_FOUNDATION_COLUMN_CONCRETE: number = 1;

  // 기둥 골조
  public static INDEX_IL_COLUMN_CYLINDER_COLUMN: number = 0;
  public static INDEX_IL_COLUMN_CYLINDER_BEAM: number = 1;
  public static INDEX_IL_COLUMN_CYLINDER_MIDDLE: number = 2;
  public static INDEX_IL_COLUMN_PRISM_COLUMN: number = 3;
  public static INDEX_IL_COLUMN_PRISM_BEAM: number = 4;
  public static INDEX_IL_COLUMN_PRISM_MIDDLE: number = 5;
  public static INDEX_IL_COLUMN_SIDE_STRAIGHT: number = 6;

  // 지붕 골조
  public static INDEX_IL_ROOF_RAFTER: number = 0;
  public static INDEX_IL_ROOF_STRAIGHT: number = 1;
  public static INDEX_IL_ROOF_BRACING: number = 2;

  // 방풍벽 골조
  public static INDEX_IL_WING_RAFTER: number = 0;
  public static INDEX_IL_WING_STRAIGHT: number = 1;

  // 마구리 골조
  public static INDEX_IL_ENDPIECE_CYLINDER_STUD: number = 0;
  public static INDEX_IL_ENDPIECE_CYLINDER_STUD_SEP: number = 1;
  public static INDEX_IL_ENDPIECE_CYLINDER_MIDDLE: number = 2;
  public static INDEX_IL_ENDPIECE_PRISM_STUD_SEP: number = 3;
  public static INDEX_IL_ENDPIECE_PRISM_MIDDLE: number = 4;
  public static INDEX_IL_ENDPIECE_GATE: number = 5;

  // 물받이
  public static INDEX_IL_GUTTER_IRON_PLATE: number = 0;
  public static INDEX_IL_GUTTER_PLASTIC: number = 1;
  public static INDEX_IL_GUTTER_WEAVING_FILM: number = 2;
  public static INDEX_IL_GUTTER_FIXING_GUTTER_PAD: number = 3;
  public static INDEX_IL_GUTTER_FIXING_SCREW: number = 4;
  public static INDEX_IL_GUTTER_FIXING_NORMAL_CLIP: number = 5;
  public static INDEX_IL_GUTTER_DRAINAGE_PLEATED_PIPE: number = 6;
  public static INDEX_IL_GUTTER_DRAINAGE_PVC_PIPE: number = 7;
  public static INDEX_IL_GUTTER_CONDENSATION: number = 8;

  // 개폐기
  public static INDEX_IL_SWITCHER_MANUAL1: number = 0;
  public static INDEX_IL_SWITCHER_MANUAL2: number = 1;
  public static INDEX_IL_SWITCHER_POWER1: number = 2;
  public static INDEX_IL_SWITCHER_POWER2: number = 3;
  public static INDEX_IL_SWITCHER_AXIS: number = 4;
  public static INDEX_IL_SWITCHER_SUPPORT: number = 5;
  public static INDEX_IL_SWITCHER_FIXING_NORMAL_CLIP: number = 6;

  // 제어기
  public static INDEX_IL_CONTROLLER_DEFAULT: number = 0;

  // 기초 피복
  public static INDEX_IL_COVER_VINYL: number = 0;
  public static INDEX_IL_COVER_WEAVING_FILM: number = 1;
  public static INDEX_IL_COVER_SHADENET: number = 2;
  public static INDEX_IL_COVER_TENT: number = 3;
  public static INDEX_IL_COVER_NONWOVEN: number = 4;
  public static INDEX_IL_COVER_CASHMILON: number = 5;
  public static INDEX_IL_COVER_LAGGING_COLOR: number = 6;
  public static INDEX_IL_COVER_LAGGING: number = 7;
  public static INDEX_IL_COVER_QUILTING: number = 8;
  public static INDEX_IL_COVER_SCREEN: number = 9;

  // 피복 고정
  public static INDEX_IL_FIXING_NORMAL_PAD: number = 0;
  public static INDEX_IL_FIXING_NORMAL_CLIP: number = 1;
  public static INDEX_IL_FIXING_LAGGING_PAD: number = 2;
  public static INDEX_IL_FIXING_WOOD: number = 3;
  public static INDEX_IL_FIXING_CONDENSATION_PAD: number = 4;

  // 치마 피복
  public static INDEX_IL_SKIRT_NORMAL: number = 0;
  public static INDEX_IL_SKIRT_STRING: number = 1;
  public static INDEX_IL_SKIRT_FIXING_NORMAL_PAD: number = 2;
  public static INDEX_IL_SKIRT_FIXING_NORMAL_CLIP: number = 3;
  public static INDEX_IL_SKIRT_FIXING_LAGGING_PAD: number = 4;
  public static INDEX_IL_SKIRT_FIXING_WOOD: number = 5;
  public static INDEX_IL_SKIRT_FIXING_SKIRT_CLIP: number = 6;

  // 바람막이 피복
  public static INDEX_IL_WINDBREAK_DEFAULT: number = 0;
  public static INDEX_IL_WINDBREAK_FIXING_NORMAL_PAD: number = 1;
  public static INDEX_IL_WINDBREAK_FIXING_NORMAL_CLIP: number = 2;
  public static INDEX_IL_WINDBREAK_FIXING_LAGGING_PAD: number = 3;
  public static INDEX_IL_WINDBREAK_FIXING_WOOD: number = 4;

  // 방충망
  public static INDEX_IL_SCREENNET_DEFAULT: number = 0;
  public static INDEX_IL_SCREENNET_FIXING_NORMAL_PAD: number = 1;
  public static INDEX_IL_SCREENNET_FIXING_LAGGING_PAD: number = 2;
  public static INDEX_IL_SCREENNET_FIXING_WOOD: number = 3;

  // 밴드끈
  public static INDEX_IL_BANDSTRING_BANDSTRING: number = 0;
  public static INDEX_IL_BANDSTRING_POLY: number = 1;
  public static INDEX_IL_BANDSTRING_FIXING_NORMAL_PAD: number = 2;
  public static INDEX_IL_BANDSTRING_FIXING_HOOK: number = 3;

  // 커튼 피복
  public static INDEX_IL_CURTAIN_SIDE_ENDPIECE_QUILTING: number = 0;
  public static INDEX_IL_CURTAIN_COVER_QUILTING: number = 1;
  public static INDEX_IL_CURTAIN_COVER_NONWOVEN: number = 2;
  public static INDEX_IL_CURTAIN_COVER_MAT: number = 3;
  public static INDEX_IL_CURTAIN_COVER_SHADENET: number = 4;
  public static INDEX_IL_CURTAIN_COVER_SCREEN: number = 5;
  public static INDEX_IL_CURTAIN_CEILING_LEVEL2_QUILTING: number = 6;
  public static INDEX_IL_CURTAIN_CEILING_LEVEL2_SHADENET: number = 7;
  public static INDEX_IL_CURTAIN_CEILING_LEVEL2_SCREEN: number = 8;
  public static INDEX_IL_CURTAIN_CEILING_LEVEL3_QUILTING: number = 9;
  public static INDEX_IL_CURTAIN_CEILING_LEVEL3_SHADENET: number = 10;
  public static INDEX_IL_CURTAIN_CEILING_LEVEL3_SCREEN: number = 11;
  public static INDEX_IL_CURTAIN_FIXING_NORMAL_PAD: number = 12;

  // 커튼 개폐
  public static INDEX_IL_CURTAIN_SWITCH_POWER: number = 0;
  public static INDEX_IL_CURTAIN_SWITCH_MOTOR: number = 1;
  public static INDEX_IL_CURTAIN_SWITCH_AXIS: number = 2;
  public static INDEX_IL_CURTAIN_SWITCH_FRAME: number = 3;
  public static INDEX_IL_CURTAIN_SWITCH_FRAME_VL: number = 4;
  public static INDEX_IL_CURTAIN_SWITCH_LINE: number = 5;
  public static INDEX_IL_CURTAIN_SWITCH_SUPPORT_VERTICAL: number = 6;
  public static INDEX_IL_CURTAIN_SWITCH_FIXING_CLIP_SIDE: number = 7;
  public static INDEX_IL_CURTAIN_SWITCH_FIXING_CLIP_CEILING: number = 8;

  // 천창 골조
  public static INDEX_IL_SKYLIGHT_FRAME_RAFTER: number = 0;
  public static INDEX_IL_SKYLIGHT_FRAME_STRAIGHT: number = 1;
  public static INDEX_IL_SKYLIGHT_FRAME_ENHANCED: number = 2;
  public static INDEX_IL_SKYLIGHT_FRAME_RACKPINION: number = 3;
  public static INDEX_IL_SKYLIGHT_FRAME_CUSTOM: number = 4;

  // 천창 개폐
  public static INDEX_IL_SKYLIGHT_SWITCH_MANUAL: number = 0;
  public static INDEX_IL_SKYLIGHT_SWITCH_POWER: number = 1;
  public static INDEX_IL_SKYLIGHT_SWITCH_RACKPINION: number = 2;
  public static INDEX_IL_SKYLIGHT_SWITCH_AXIS: number = 3;
  public static INDEX_IL_SKYLIGHT_SWITCH_SUPPORT_DIAGONAL: number = 4;
  public static INDEX_IL_SKYLIGHT_SWITCH_FIXING_NORMAL_CLIP: number = 5;

  // 천창 피복
  public static INDEX_IL_SKYLIGHT_COVER_SKYLIGHT_COVER: number = 0;
  public static INDEX_IL_SKYLIGHT_COVER_FIXING_NORMAL_PAD: number = 1;
  public static INDEX_IL_SKYLIGHT_COVER_SCREENNET: number = 2;

  //----------------------------------
  // 파트 인덱스 - 벤로 (나머지 영역은 연동 인덱스 사용)
  //---------------------------------

  // 기둥 골조
  public static INDEX_VL_COLUMN_PRISM_COLUMN: number = 0;
  public static INDEX_VL_COLUMN_PRISM_BEAM: number = 1;
  public static INDEX_VL_COLUMN_PRISM_MIDDLE: number = 2;
  public static INDEX_VL_COLUMN_SIDE_STRAIGHT: number = 3;

  // 지붕 골조
  public static INDEX_VL_ROOF_RAFTER: number = 0;
  public static INDEX_VL_ROOF_STRAIGHT: number = 1;
  public static INDEX_VL_ROOF_BRACING: number = 2;

  // 방풍벽 골조
  public static INDEX_VL_WING_RAFTER: number = 0;
  public static INDEX_VL_WING_STRAIGHT: number = 1;

  // 마구리 골조
  public static INDEX_VL_ENDPIECE_ENDPIECE: number = 0;
  public static INDEX_VL_ENDPIECE_GATE: number = 1;

  //----------------------------------
  // 파트 인덱스 - 공통 구조
  //----------------------------------

  // 칸막이
  public static INDEX_CM_PARTITION_STUD: number = 0;
  public static INDEX_CM_PARTITION_MIDDLE: number = 1;
  public static INDEX_CM_PARTITION_GATE: number = 2;
  public static INDEX_CM_PARTITION_COVER: number = 3;
  public static INDEX_CM_PARTITION_FIXING_NORMAL_PAD: number = 4;
  public static INDEX_CM_PARTITION_FIXING_NORMAL_CLIP: number = 5;
  public static INDEX_CM_PARTITION_FIXING_LAGGING_PAD: number = 6;
  public static INDEX_CM_PARTITION_FIXING_WOOD: number = 7;

  // 창문
  public static INDEX_CM_WINDOW_HINGED: number = 0;
  public static INDEX_CM_WINDOW_SASH: number = 1;
  public static INDEX_CM_WINDOW_FIXING_NORMAL_PAD: number = 2;
  public static INDEX_CM_WINDOW_FIXING_LAGGING_PAD: number = 3;
  public static INDEX_CM_WINDOW_FIXING_WOOD: number = 4;

  // 작업동
  public static INDEX_CM_WORKROOM_CUSTOM: number = 0;

  // 기타 자재
  public static INDEX_CM_MATERIAL_CUSTOM: number = 0;

  // 환기 외부 배기
  public static INDEX_CM_VENT_EXHAUST_ENDPIECE_FAN: number = 0;
  public static INDEX_CM_VENT_EXHAUST_ROOF_FAN_POWER: number = 1;
  public static INDEX_CM_VENT_EXHAUST_ROOF_FAN_MANUAL: number = 2;
  public static INDEX_CM_VENT_EXHAUST_CUSTOM: number = 3;

  // 환기 내부 환기
  public static INDEX_CM_VENT_INSIDE_AIR_FLOW_FAN: number = 0;
  public static INDEX_CM_VENT_INSIDE_CUSTOM: number = 1;

  // 관수 제어부
  public static INDEX_CM_WATERING_MAINPIPE_PE_MAIN1: number = 0;
  public static INDEX_CM_WATERING_MAINPIPE_PE_MAIN2: number = 1;
  public static INDEX_CM_WATERING_MAINPIPE_CUSTOM: number = 2;
  public static INDEX_CM_WATERING_MAINPIPE_UPPER_FRAME: number = 3;

  // 관수 지관부
  public static INDEX_CM_WATERING_WATERING_MINIKLER_SCREEN: number = 0;
  public static INDEX_CM_WATERING_WATERING_MINIKLER_FOUNTAIN: number = 1;
  public static INDEX_CM_WATERING_WATERING_MINIKLER_FOG: number = 2;
  public static INDEX_CM_WATERING_WATERING_HOSE_SCREEN: number = 3;
  public static INDEX_CM_WATERING_WATERING_HOSE_UPPER: number = 4;
  public static INDEX_CM_WATERING_WATERING_HOSE_SIDE: number = 5;
  public static INDEX_CM_WATERING_WATERING_HOSE_LOWER: number = 6;
  public static INDEX_CM_WATERING_WATERING_HOSE_MULCHING: number = 7;
  public static INDEX_CM_WATERING_WATERING_HOSE_CONNECT: number = 8;
  public static INDEX_CM_WATERING_WATERING_DRIP_WATERING: number = 9;
  public static INDEX_CM_WATERING_WATERING_DRIP_CONNECT: number = 10;
  public static INDEX_CM_WATERING_WATERING_SCREEN_GUTTER: number = 11;

  // 양액 제어부
  public static INDEX_CM_HYD_SYSTEM_CONTROL: number = 0;
  public static INDEX_CM_HYD_SYSTEM_SUPPLY: number = 1;
  public static INDEX_CM_HYD_SYSTEM_DRAIN: number = 2;
  public static INDEX_CM_HYD_SYSTEM_CUSTOM: number = 3;

  // 양액 베드부
  public static INDEX_CM_HYD_CULTIVATION_BED: number = 0;
  public static INDEX_CM_HYD_CULTIVATION_CULTIVATION_MATERIAL: number = 1;
  public static INDEX_CM_HYD_CULTIVATION_GROUND_RAIL: number = 2;
  public static INDEX_CM_HYD_CULTIVATION_CUSTOM: number = 3;

  // 냉난방 난방
  public static INDEX_CM_TEM_HEATING_BOILER: number = 0;
  public static INDEX_CM_TEM_HEATING_FAN: number = 1;
  public static INDEX_CM_TEM_HEATING_TUBE: number = 2;

  // 냉난방 냉방
  public static INDEX_CM_TEM_COOLING_CUSTOM: number = 0;

  // 스마트팜 복합환경제어
  public static INDEX_CM_ENVIRONMENT_CUSTOM: number = 0;

  // 선반/균상다이
  public static INDEX_CM_RACK_RACK: number = 0;
  public static INDEX_CM_RACK_CUSTOM: number = 1;

  // 운반레일
  public static INDEX_CM_RAIL_UPPER: number = 0;
  public static INDEX_CM_RAIL_SIDE: number = 1;
  public static INDEX_CM_RAIL_GROUND: number = 2;
  public static INDEX_CM_RAIL_CARRIER: number = 3;
  public static INDEX_CM_RAIL_CUSTOM: number = 4;

  // CO2
  public static INDEX_CM_CO2_CUSTOM: number = 0;

  // 자동방제기
  public static INDEX_CM_AUTOSPRAY_CUSTOM: number = 0;

  // 기타 기계/설비
  public static INDEX_CM_OTHER_FACILITIES_CUSTOM: number = 0;

  // 통합 시공비
  public static INDEX_CM_BUILDING_CUSTOM: number = 0;
  public static INDEX_CM_BUILDING_PER_AREA: number = 1;

  //----------------------------------
  // 위치/파트 변수 레이블/값/설명
  //----------------------------------

  // 기초
  public static LB_FOUNDATION_TYPE_GIRTH_PIPE: string = "바닥도리 파이프";
  public static VL_FOUNDATION_TYPE_GIRTH_PIPE: string = "바닥도리 파이프";
  public static DC_FOUNDATION_TYPE_GIRTH_PIPE: string = "";
  public static LB_FOUNDATION_TYPE_COLUMN_CONCRETE: string = "기둥 콘크리트";
  public static VL_FOUNDATION_TYPE_COLUMN_CONCRETE: string = "기둥 콘크리트";
  public static DC_FOUNDATION_TYPE_COLUMN_CONCRETE: string = "";
  public static LB_FOUNDATION_TYPE_GIRTH_AND_COLUMN_CONCRETE: string = "바닥도리+기둥 콘크리트";
  public static VL_FOUNDATION_TYPE_GIRTH_AND_COLUMN_CONCRETE: string = "바닥도리+기둥 콘크리트";
  public static DC_FOUNDATION_TYPE_GIRTH_AND_COLUMN_CONCRETE: string = "";
  public static LB_FOUNDATION_TYPE_CUSTOM: string = "임의 형태";
  public static VL_FOUNDATION_TYPE_CUSTOM: string = "임의 형태";
  public static DC_FOUNDATION_TYPE_CUSTOM: string = "";

  public static LB_CONCRETE_MOLD_DIAMETER_250: string = "250mm";
  public static VL_CONCRETE_MOLD_DIAMETER_250: string = "250mm";
  public static DC_CONCRETE_MOLD_DIAMETER_250: string = "";
  public static LB_CONCRETE_MOLD_DIAMETER_300: string = "300mm";
  public static VL_CONCRETE_MOLD_DIAMETER_300: string = "300mm";
  public static DC_CONCRETE_MOLD_DIAMETER_300: string = "";
  public static LB_CONCRETE_MOLD_DIAMETER_500: string = "500mm";
  public static VL_CONCRETE_MOLD_DIAMETER_500: string = "500mm";
  public static DC_CONCRETE_MOLD_DIAMETER_500: string = "";
  public static LB_CONCRETE_MOLD_DIAMETER_600: string = "600mm";
  public static VL_CONCRETE_MOLD_DIAMETER_600: string = "600mm";
  public static DC_CONCRETE_MOLD_DIAMETER_600: string = "";

  // 기초 골조
  public static LB_TRUNK_TYPE_ARCH: string = "아치형";
  public static VL_TRUNK_TYPE_ARCH: string = "아치형";
  public static DC_TRUNK_TYPE_ARCH: string = "";
  public static LB_TRUNK_TYPE_ARCH_AND_COLUMN: string = "아치+기둥형";
  public static VL_TRUNK_TYPE_ARCH_AND_COLUMN: string = "아치+기둥형";
  public static DC_TRUNK_TYPE_ARCH_AND_COLUMN: string = "";

  public static LB_TRUNK_RAFTER_TYPE_PEACH: string = "복숭아형";
  public static VL_TRUNK_RAFTER_TYPE_PEACH: string = "복숭아형";
  public static DC_TRUNK_RAFTER_TYPE_PEACH: string = "";
  public static LB_TRUNK_RAFTER_TYPE_ROUND: string = "둥근형";
  public static VL_TRUNK_RAFTER_TYPE_ROUND: string = "둥근형";
  public static DC_TRUNK_RAFTER_TYPE_ROUND: string = "";
  public static LB_TRUNK_RAFTER_TYPE_3_4: string = "3/4형";
  public static VL_TRUNK_RAFTER_TYPE_3_4: string = "3/4형";
  public static DC_TRUNK_RAFTER_TYPE_3_4: string = "";

  public static LB_TRUNK_RAFTER_EXTENSION_TYPE_PINNED: string = "핀가공 파이프";
  public static VL_TRUNK_RAFTER_EXTENSION_TYPE_PINNED: string = "핀가공 파이프";
  public static DC_TRUNK_RAFTER_EXTENSION_TYPE_PINNED: string = "";
  public static LB_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL: string = "일반 파이프";
  public static VL_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL: string = "일반 파이프";
  public static DC_TRUNK_RAFTER_EXTENSION_TYPE_NORMAL: string = "";
  public static LB_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM: string = "기둥+보 파이프";
  public static VL_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM: string = "기둥+보 파이프";
  public static DC_TRUNK_RAFTER_EXTENSION_TYPE_COLUMN_BEAM: string = "";

  public static LB_TRUNK_RAFTER_EXTENSION_POSITION_BOTH: string = "양측";
  public static VL_TRUNK_RAFTER_EXTENSION_POSITION_BOTH: string = "양측";
  public static DC_TRUNK_RAFTER_EXTENSION_POSITION_BOTH: string = "";
  public static LB_TRUNK_RAFTER_EXTENSION_POSITION_ONE: string = "편측";
  public static VL_TRUNK_RAFTER_EXTENSION_POSITION_ONE: string = "편측";
  public static DC_TRUNK_RAFTER_EXTENSION_POSITION_ONE: string = "";

  public static LB_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET: string = "강판 조리개";
  public static VL_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET: string = "강판 조리개";
  public static DC_TRUNK_ENHANCED_GRABBER_TYPE_STEEL_SHEET: string = "";
  public static LB_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET: string = "십자 강판 조리개";
  public static VL_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET: string = "십자 강판 조리개";
  public static DC_TRUNK_ENHANCED_GRABBER_TYPE_CROSS_STEEL_SHEET: string = "";
  public static LB_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET: string = "선판 조리개";
  public static VL_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET: string = "선판 조리개";
  public static DC_TRUNK_ENHANCED_GRABBER_TYPE_LINE_SHEET: string = "";
  public static LB_TRUNK_ENHANCED_GRABBER_TYPE_U_CLAMP: string = "U 클램프";
  public static VL_TRUNK_ENHANCED_GRABBER_TYPE_U_CLAMP: string = "U 클램프";
  public static DC_TRUNK_ENHANCED_GRABBER_TYPE_U_CLAMP: string = "";
  public static LB_TRUNK_ENHANCED_GRABBER_TYPE_U_ASIBA_CLAMP: string = "U+아시바 클램프";
  public static VL_TRUNK_ENHANCED_GRABBER_TYPE_U_ASIBA_CLAMP: string = "U+아시바 클램프";
  public static DC_TRUNK_ENHANCED_GRABBER_TYPE_U_ASIBA_CLAMP: string = "";
  public static LB_TRUNK_ENHANCED_GRABBER_TYPE_SADDLE_ASIBA_CLAMP: string = "새들+아시바 클램프";
  public static VL_TRUNK_ENHANCED_GRABBER_TYPE_SADDLE_ASIBA_CLAMP: string = "새들+아시바 클램프";
  public static DC_TRUNK_ENHANCED_GRABBER_TYPE_SADDLE_ASIBA_CLAMP: string = "";

  // 기둥 골조
  public static LB_COLUMN_TYPE_CYLINDER: string = "원기둥형";
  public static VL_COLUMN_TYPE_CYLINDER: string = "원기둥형";
  public static DC_COLUMN_TYPE_CYLINDER: string = "";
  public static LB_COLUMN_TYPE_PRISM: string = "각기둥형";
  public static VL_COLUMN_TYPE_PRISM: string = "각기둥형";
  public static DC_COLUMN_TYPE_PRISM: string = "";

  public static LB_COLUMN_BRACING_TYPE_X_BRACING: string = "X자 환봉";
  public static VL_COLUMN_BRACING_TYPE_X_BRACING: string = "X자 환봉";
  public static DC_COLUMN_BRACING_TYPE_X_BRACING: string = "";
  public static LB_COLUMN_BRACING_TYPE_DIAGONAL_PIPE: string = "대각 파이프";
  public static VL_COLUMN_BRACING_TYPE_DIAGONAL_PIPE: string = "대각 파이프";
  public static DC_COLUMN_BRACING_TYPE_DIAGONAL_PIPE: string = "";

  public static LB_COLUMN_BEAM_TYPE_GUTTER_BEAM: string = "물받이거터 보";
  public static VL_COLUMN_BEAM_TYPE_GUTTER_BEAM: string = "물받이거터 보";
  public static DC_COLUMN_BEAM_TYPE_GUTTER_BEAM: string = "";
  public static LB_COLUMN_BEAM_TYPE_NORMAL_PIPE: string = "일반 파이프";
  public static VL_COLUMN_BEAM_TYPE_NORMAL_PIPE: string = "일반 파이프";
  public static DC_COLUMN_BEAM_TYPE_NORMAL_PIPE: string = "";

  public static LB_COLUMN_MIDDLE_SUPPORT_TYPE_WORKED_PIPE: string = "가공된 파이프";
  public static VL_COLUMN_MIDDLE_SUPPORT_TYPE_WORKED_PIPE: string = "가공된 파이프";
  public static DC_COLUMN_MIDDLE_SUPPORT_TYPE_WORKED_PIPE: string = "";
  public static LB_COLUMN_MIDDLE_SUPPORT_TYPE_NORMAL_PIPE_AND_ENHANCED_CLAMP: string = "일반 파이프+내재해 클램프";
  public static VL_COLUMN_MIDDLE_SUPPORT_TYPE_NORMAL_PIPE_AND_ENHANCED_CLAMP: string = "일반 파이프+내재해 클램프";
  public static DC_COLUMN_MIDDLE_SUPPORT_TYPE_NORMAL_PIPE_AND_ENHANCED_CLAMP: string = "";
  public static LB_COLUMN_MIDDLE_SUPPORT_TYPE_NORMAL_PIPE_AND_WELDING: string = "일반 파이프+용접";
  public static VL_COLUMN_MIDDLE_SUPPORT_TYPE_NORMAL_PIPE_AND_WELDING: string = "일반 파이프+용접";
  public static DC_COLUMN_MIDDLE_SUPPORT_TYPE_NORMAL_PIPE_AND_WELDING: string = "";

  public static LB_COLUMN_MIDDLE_BRACING_TYPE_X_BRACING: string = "X자 환봉";
  public static VL_COLUMN_MIDDLE_BRACING_TYPE_X_BRACING: string = "X자 환봉";
  public static DC_COLUMN_MIDDLE_BRACING_TYPE_X_BRACING: string = "";
  public static LB_COLUMN_MIDDLE_BRACING_TYPE_DIAGONAL_PIPE: string = "대각 파이프";
  public static VL_COLUMN_MIDDLE_BRACING_TYPE_DIAGONAL_PIPE: string = "대각 파이프";
  public static DC_COLUMN_MIDDLE_BRACING_TYPE_DIAGONAL_PIPE: string = "";

  public static LB_COLUMN_SIDE_STRAIGHT_TYPE_PIPE: string = "파이프";
  public static VL_COLUMN_SIDE_STRAIGHT_TYPE_PIPE: string = "파이프";
  public static DC_COLUMN_SIDE_STRAIGHT_TYPE_PIPE: string = "";
  public static LB_COLUMN_SIDE_STRAIGHT_TYPE_C_SECTION_STEEL: string = "C형강";
  public static VL_COLUMN_SIDE_STRAIGHT_TYPE_C_SECTION_STEEL: string = "C형강";
  public static DC_COLUMN_SIDE_STRAIGHT_TYPE_C_SECTION_STEEL: string = "";

  // 지붕 골조
  public static LB_ROOF_TYPE_PEACH: string = "복숭아형";
  public static VL_ROOF_TYPE_PEACH: string = "복숭아형";
  public static DC_ROOF_TYPE_PEACH: string = "";
  public static LB_ROOF_TYPE_ROUND: string = "둥근형";
  public static VL_ROOF_TYPE_ROUND: string = "둥근형";
  public static DC_ROOF_TYPE_ROUND: string = "";

  // 방풍벽 골조
  public static LB_WING_TYPE_DEFAULT: string = "기본";
  public static VL_WING_TYPE_DEFAULT: string = "기본";
  public static DC_WING_TYPE_DEFAULT: string = "";
  public static LB_WING_TYPE_SIMPLE: string = "간이형";
  public static VL_WING_TYPE_SIMPLE: string = "간이형";
  public static DC_WING_TYPE_SIMPLE: string = "";

  public static LB_WING_ENHANCED_TYPE_BENDING: string = "벤딩형";
  public static VL_WING_ENHANCED_TYPE_BENDING: string = "벤딩형";
  public static DC_WING_ENHANCED_TYPE_BENDING: string = "";

  // 마구리 골조
  public static LB_ENDPIECE_TYPE_WHOLE: string = "전체형"; // (원형)
  public static VL_ENDPIECE_TYPE_WHOLE: string = "전체형"; // (원형)
  public static DC_ENDPIECE_TYPE_WHOLE: string = ""; // (원형)
  public static LB_ENDPIECE_TYPE_UPPER: string = "상단형"; // (원형)
  public static VL_ENDPIECE_TYPE_UPPER: string = "상단형"; // (원형)
  public static DC_ENDPIECE_TYPE_UPPER: string = ""; // (원형)
  public static LB_ENDPIECE_TYPE_UPPER_BOTTOM: string = "상단+하단형"; // (원형)
  public static VL_ENDPIECE_TYPE_UPPER_BOTTOM: string = "상단+하단형"; // (원형)
  public static DC_ENDPIECE_TYPE_UPPER_BOTTOM: string = ""; // (원형)
  public static LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM: string = "상단+하단형(각기둥)";
  public static VL_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM: string = "상단+하단형(각기둥)";
  public static DC_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM: string = "";

  public static LB_BAR_TYPE_20_INTERLOCK_1: string = "20-연동-1형";
  public static VL_BAR_TYPE_20_INTERLOCK_1: string = "20-연동-1형";
  public static DC_BAR_TYPE_20_INTERLOCK_1: string = "";
  public static LB_BAR_TYPE_08_INTERLOCK_1: string = "08-연동-1형";
  public static VL_BAR_TYPE_08_INTERLOCK_1: string = "08-연동-1형";
  public static DC_BAR_TYPE_08_INTERLOCK_1: string = "";

  public static LB_GATE_TYPE_SLIDING: string = "미닫이문";
  public static VL_GATE_TYPE_SLIDING: string = "미닫이문";
  public static DC_GATE_TYPE_SLIDING: string = "";
  public static LB_GATE_TYPE_HINGED: string = "여닫이문";
  public static VL_GATE_TYPE_HINGED: string = "여닫이문";
  public static DC_GATE_TYPE_HINGED: string = "";
  public static LB_GATE_TYPE_FRAMED: string = "출입문틀";
  public static VL_GATE_TYPE_FRAMED: string = "출입문틀";
  public static DC_GATE_TYPE_FRAMED: string = "";
  public static LB_GATE_TYPE_SASH_FRAME: string = "전면 샷시틀";
  public static VL_GATE_TYPE_SASH_FRAME: string = "전면 샷시틀";
  public static DC_GATE_TYPE_SASH_FRAME: string = "";
  public static LB_GATE_TYPE_NONE: string = "없음(막음)";
  public static VL_GATE_TYPE_NONE: string = "없음(막음)";
  public static DC_GATE_TYPE_NONE: string = "";

  public static LB_GATE_MAKING_METHOD_MADE: string = "기성품";
  public static VL_GATE_MAKING_METHOD_MADE: string = "기성품";
  public static DC_GATE_MAKING_METHOD_MADE: string = "";
  public static LB_GATE_MAKING_METHOD_ORDER: string = "주문품";
  public static VL_GATE_MAKING_METHOD_ORDER: string = "주문품";
  public static DC_GATE_MAKING_METHOD_ORDER: string = "";

  // 물받이
  public static LB_GUTTER_TYPE_IRON_PLATE: string = "철판 물받이";
  public static VL_GUTTER_TYPE_IRON_PLATE: string = "철판 물받이";
  public static DC_GUTTER_TYPE_IRON_PLATE: string = "";
  public static LB_GUTTER_TYPE_PLASTIC: string = "플라스틱 물받이";
  public static VL_GUTTER_TYPE_PLASTIC: string = "플라스틱 물받이";
  public static DC_GUTTER_TYPE_PLASTIC: string = "";
  public static LB_GUTTER_TYPE_WEAVING_FILM: string = "직조필름 물받이";
  public static VL_GUTTER_TYPE_WEAVING_FILM: string = "직조필름 물받이";
  public static DC_GUTTER_TYPE_WEAVING_FILM: string = "";
  public static LB_GUTTER_TYPE_CUSTOM: string = "임의 형태";
  public static VL_GUTTER_TYPE_CUSTOM: string = "임의 형태";
  public static DC_GUTTER_TYPE_CUSTOM: string = "";
  public static LB_GUTTER_TYPE_NONE: string = "없음";
  public static VL_GUTTER_TYPE_NONE: string = "없음";
  public static DC_GUTTER_TYPE_NONE: string = "";

  public static LB_GUTTER_FIXING_TYPE_GUTTER_PAD: string = "물받이 패드 고정";
  public static VL_GUTTER_FIXING_TYPE_GUTTER_PAD: string = "물받이 패드 고정";
  public static DC_GUTTER_FIXING_TYPE_GUTTER_PAD: string = "";
  public static LB_GUTTER_FIXING_TYPE_NORMAL_CLIP: string = "일반 클립 고정";
  public static VL_GUTTER_FIXING_TYPE_NORMAL_CLIP: string = "일반 클립 고정";
  public static DC_GUTTER_FIXING_TYPE_NORMAL_CLIP: string = "";
  public static LB_GUTTER_FIXING_TYPE_SCREW: string = "나사 고정";
  public static VL_GUTTER_FIXING_TYPE_SCREW: string = "나사 고정";
  public static DC_GUTTER_FIXING_TYPE_SCREW: string = "";
  public static LB_GUTTER_FIXING_TYPE_NONE: string = "없음";
  public static VL_GUTTER_FIXING_TYPE_NONE: string = "없음";
  public static DC_GUTTER_FIXING_TYPE_NONE: string = "";

  public static LB_GUTTER_DRAINAGE_TYPE_NONE: string = "배수 없음";
  public static VL_GUTTER_DRAINAGE_TYPE_NONE: string = "배수 없음";
  public static DC_GUTTER_DRAINAGE_TYPE_NONE: string = "";
  public static LB_GUTTER_DRAINAGE_TYPE_PLEATED_PIPE: string = "주름관 배수";
  public static VL_GUTTER_DRAINAGE_TYPE_PLEATED_PIPE: string = "주름관 배수";
  public static DC_GUTTER_DRAINAGE_TYPE_PLEATED_PIPE: string = "";
  public static LB_GUTTER_DRAINAGE_TYPE_PVC_PIPE: string = "PVC관 배수";
  public static VL_GUTTER_DRAINAGE_TYPE_PVC_PIPE: string = "PVC관 배수";
  public static DC_GUTTER_DRAINAGE_TYPE_PVC_PIPE: string = "";
  public static LB_GUTTER_DRAINAGE_TYPE_IRON_PLATE_EXTENTION: string = "철판 물받이 연장";
  public static VL_GUTTER_DRAINAGE_TYPE_IRON_PLATE_EXTENTION: string = "철판 물받이 연장";
  public static DC_GUTTER_DRAINAGE_TYPE_IRON_PLATE_EXTENTION: string = "";

  public static LB_GUTTER_CONDENSATION_TYPE_PLASTIC: string = "플라스틱";
  public static VL_GUTTER_CONDENSATION_TYPE_PLASTIC: string = "플라스틱";
  public static DC_GUTTER_CONDENSATION_TYPE_PLASTIC: string = "";
  public static LB_GUTTER_CONDENSATION_TYPE_CON_WORKED_IRON_PLATE: string = "결로가공 철판";
  public static VL_GUTTER_CONDENSATION_TYPE_CON_WORKED_IRON_PLATE: string = "결로가공 철판";
  public static DC_GUTTER_CONDENSATION_TYPE_CON_WORKED_IRON_PLATE: string = "";

  // 개폐기
  public static LB_SWITCHER_TYPE_POWER_WRAP: string = "권취식 동력 개폐";
  public static VL_SWITCHER_TYPE_POWER_WRAP: string = "권취식 동력 개폐";
  public static DC_SWITCHER_TYPE_POWER_WRAP: string = "";
  public static LB_SWITCHER_TYPE_POWER_TOW: string = "예인식 동력 개폐";
  public static VL_SWITCHER_TYPE_POWER_TOW: string = "예인식 동력 개폐";
  public static DC_SWITCHER_TYPE_POWER_TOW: string = "";
  public static LB_SWITCHER_TYPE_RACKPINION: string = "랙피니언 개폐";
  public static VL_SWITCHER_TYPE_RACKPINION: string = "랙피니언 개폐";
  public static DC_SWITCHER_TYPE_RACKPINION: string = "";
  public static LB_SWITCHER_TYPE_MANUAL: string = "수동 개폐";
  public static VL_SWITCHER_TYPE_MANUAL: string = "수동 개폐";
  public static DC_SWITCHER_TYPE_MANUAL: string = "";

  public static LB_SWITCHER_SWITCH_WAY_NO: string = "개폐없음";
  public static VL_SWITCHER_SWITCH_WAY_NO: string = "개폐없음";
  public static DC_SWITCHER_SWITCH_WAY_NO: string = "";
  public static LB_SWITCHER_SWITCH_WAY_ON: string = "개폐";
  public static VL_SWITCHER_SWITCH_WAY_ON: string = "개폐";
  public static DC_SWITCHER_SWITCH_WAY_ON: string = "";
  public static LB_SWITCHER_SWITCH_WAY_ONE: string = "편측개폐";
  public static VL_SWITCHER_SWITCH_WAY_ONE: string = "편측개폐";
  public static DC_SWITCHER_SWITCH_WAY_ONE: string = "";
  public static LB_SWITCHER_SWITCH_WAY_BOTH: string = "양측개폐";
  public static VL_SWITCHER_SWITCH_WAY_BOTH: string = "양측개폐";
  public static DC_SWITCHER_SWITCH_WAY_BOTH: string = "";
  public static LB_SWITCHER_SWITCH_WAY_FULL: string = "완전개폐";
  public static VL_SWITCHER_SWITCH_WAY_FULL: string = "완전개폐";
  public static DC_SWITCHER_SWITCH_WAY_FULL: string = "";

  public static LB_SWITCHER_SUPPPORT_TYPE_NONE: string = "없음";
  public static VL_SWITCHER_SUPPPORT_TYPE_NONE: string = "없음";
  public static DC_SWITCHER_SUPPPORT_TYPE_NONE: string = "";
  public static LB_SWITCHER_SUPPPORT_TYPE_VERTICAL: string = "수직형 지지";
  public static VL_SWITCHER_SUPPPORT_TYPE_VERTICAL: string = "수직형 지지";
  public static DC_SWITCHER_SUPPPORT_TYPE_VERTICAL: string = "";
  public static LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL: string = "대각형 지지";
  public static VL_SWITCHER_SUPPPORT_TYPE_DIAGONAL: string = "대각형 지지";
  public static DC_SWITCHER_SUPPPORT_TYPE_DIAGONAL: string = "";
  public static LB_SWITCHER_SUPPPORT_TYPE_RAFTER: string = "서까래형 지지";
  public static LB_SWITCHER_SUPPORT_TYPE_SUPPORT: string = "개폐기 지지";
  public static VL_SWITCHER_SUPPPORT_TYPE_RAFTER: string = "서까래형 지지";
  public static DC_SWITCHER_SUPPPORT_TYPE_RAFTER: string = "";

  public static LB_SWITCHER_ORDER_0: string = "0차";
  public static VL_SWITCHER_ORDER_0: number = 0;
  public static DC_SWITCHER_ORDER_0: string = "";
  public static LB_SWITCHER_ORDER_1: string = "1차";
  public static VL_SWITCHER_ORDER_1: number = 1;
  public static DC_SWITCHER_ORDER_1: string = "";
  public static LB_SWITCHER_ORDER_2: string = "2차";
  public static VL_SWITCHER_ORDER_2: number = 2;
  public static DC_SWITCHER_ORDER_2: string = "";

  public static LB_SWITCHER_STEP_0: string = "0차";
  public static VL_SWITCHER_STEP_0: number = 0;
  public static DC_SWITCHER_STEP_0: string = "";
  public static LB_SWITCHER_STEP_1: string = "1차";
  public static VL_SWITCHER_STEP_1: number = 1;
  public static DC_SWITCHER_STEP_1: string = "";
  public static LB_SWITCHER_STEP_2: string = "2차";
  public static VL_SWITCHER_STEP_2: number = 2;
  public static DC_SWITCHER_STEP_2: string = "";

  public static LB_SWITCHER_DIVISION_NONE: string = "없음";
  public static VL_SWITCHER_DIVISION_NONE: string = "없음";
  public static DC_SWITCHER_DIVISION_NONE: string = "";
  public static LB_SWITCHER_DIVISION_2: string = "2등분";
  public static VL_SWITCHER_DIVISION_2: string = "2등분";
  public static DC_SWITCHER_DIVISION_2: string = "";
  public static LB_SWITCHER_DIVISION_3: string = "3등분";
  public static VL_SWITCHER_DIVISION_3: string = "3등분";
  public static DC_SWITCHER_DIVISION_3: string = "";
  public static LB_SWITCHER_DIVISION_4: string = "4등분";
  public static VL_SWITCHER_DIVISION_4: string = "4등분";
  public static DC_SWITCHER_DIVISION_4: string = "";
  public static LB_SWITCHER_DIVISION_5: string = "5등분";
  public static VL_SWITCHER_DIVISION_5: string = "5등분";
  public static DC_SWITCHER_DIVISION_5: string = "";

  // 제어기
  public static LB_CONTROLLER_TYPE_INTEGRATED: string = "통합 제어기";
  public static VL_CONTROLLER_TYPE_INTEGRATED: string = "통합 제어기";
  public static DC_CONTROLLER_TYPE_INTEGRATED: string = "";
  public static LB_CONTROLLER_TYPE_CUSTOM: string = "임의 형태";
  public static VL_CONTROLLER_TYPE_CUSTOM: string = "임의 형태";
  public static DC_CONTROLLER_TYPE_CUSTOM: string = "";

  public static LB_CONTROLLER_CONTROLL_TYPE_MANUAL: string = "수동 제어";
  public static VL_CONTROLLER_CONTROLL_TYPE_MANUAL: string = "수동 제어";
  public static DC_CONTROLLER_CONTROLL_TYPE_MANUAL: string = "";
  public static LB_CONTROLLER_CONTROLL_TYPE_SEMI_AUTO: string = "반자동 제어";
  public static VL_CONTROLLER_CONTROLL_TYPE_SEMI_AUTO: string = "반자동 제어";
  public static DC_CONTROLLER_CONTROLL_TYPE_SEMI_AUTO: string = "";
  public static LB_CONTROLLER_CONTROLL_TYPE_AUTO: string = "자동 제어";
  public static VL_CONTROLLER_CONTROLL_TYPE_AUTO: string = "자동 제어";
  public static DC_CONTROLLER_CONTROLL_TYPE_AUTO: string = "";

  // 기초 피복
  public static LB_COVER_TYPE_VINYL: string = "비닐";
  public static VL_COVER_TYPE_VINYL: string = "비닐";
  public static DC_COVER_TYPE_VINYL: string = "";
  public static LB_COVER_TYPE_WEAVING_FILM: string = "직조 필름";
  public static VL_COVER_TYPE_WEAVING_FILM: string = "직조 필름";
  public static DC_COVER_TYPE_WEAVING_FILM: string = "";
  public static LB_COVER_TYPE_SHADENET: string = "차광망";
  public static VL_COVER_TYPE_SHADENET: string = "차광망";
  public static DC_COVER_TYPE_SHADENET: string = "";
  public static LB_COVER_TYPE_TENT: string = "텐텐지";
  public static VL_COVER_TYPE_TENT: string = "텐텐지";
  public static DC_COVER_TYPE_TENT: string = "";
  public static LB_COVER_TYPE_NONWOVEN: string = "부직포";
  public static VL_COVER_TYPE_NONWOVEN: string = "부직포";
  public static DC_COVER_TYPE_NONWOVEN: string = "";
  public static LB_COVER_TYPE_CASHMILON: string = "카시미론";
  public static VL_COVER_TYPE_CASHMILON: string = "카시미론";
  public static DC_COVER_TYPE_CASHMILON: string = "";
  public static LB_COVER_TYPE_COLOR_LAGGING: string = "칼라 보온덮개";
  public static VL_COVER_TYPE_COLOR_LAGGING: string = "칼라 보온덮개";
  public static DC_COVER_TYPE_COLOR_LAGGING: string = "";
  public static LB_COVER_TYPE_LAGGING: string = "보온덮개";
  public static VL_COVER_TYPE_LAGGING: string = "보온덮개";
  public static DC_COVER_TYPE_LAGGING: string = "";
  public static LB_COVER_TYPE_QUILTING: string = "다겹 커튼지";
  public static VL_COVER_TYPE_QUILTING: string = "다겹 커튼지";
  public static DC_COVER_TYPE_QUILTING: string = "";
  public static LB_COVER_TYPE_SCREEN: string = "스크린";
  public static VL_COVER_TYPE_SCREEN: string = "스크린";
  public static DC_COVER_TYPE_SCREEN: string = "";
  public static LB_COVER_TYPE_MAT: string = "마트";
  public static VL_COVER_TYPE_MAT: string = "마트";
  public static DC_COVER_TYPE_MAT: string = "";

  public static LB_COVER_THICKNESS_VALUE_VINYL: number = 1;
  public static LB_COVER_THICKNESS_VALUE_WEAVING_FILM: number = 1;
  public static LB_COVER_THICKNESS_VALUE_SHADENET: number = 1;
  public static LB_COVER_THICKNESS_VALUE_TENT: number = 1;
  public static LB_COVER_THICKNESS_VALUE_NONWOVEN: number = 3;
  public static LB_COVER_THICKNESS_VALUE_CASHMILON: number = 3;
  public static LB_COVER_THICKNESS_VALUE_COLOR_LAGGING: number = 3;
  public static LB_COVER_THICKNESS_VALUE_LAGGING: number = 6;
  public static LB_COVER_THICKNESS_VALUE_QUILTING: number = 6;
  public static LB_COVER_THICKNESS_VALUE_SCREEN: number = 2;
  public static LB_COVER_THICKNESS_VALUE_MAT: number = 1;

  public static LB_COVER_SWITCHER_USAGE_VALUE_VINYL: number = 1;
  public static LB_COVER_SWITCHER_USAGE_VALUE_NONWOVEN: number = 3;
  public static LB_COVER_SWITCHER_USAGE_VALUE_LAGGING: number = 6;
  public static LB_COVER_SWITCHER_USAGE_VALUE_QUILTING: number = 10;

  public static LB_COVER_SCOPE_TYPE_TRUNK: string = `${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_SIDE}`;
  public static VL_COVER_SCOPE_TYPE_TRUNK: string = `${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_SIDE}`;
  public static DC_COVER_SCOPE_TYPE_TRUNK: string = "";
  public static LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE: string = `${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_SIDE}+${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE: string = `${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_SIDE}+${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE: string = `${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_SIDE} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE: string = `${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_SIDE} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_ROOF: string = CONST.LB_POSITION_ROOF;
  public static VL_COVER_SCOPE_TYPE_ROOF: string = CONST.LB_POSITION_ROOF;
  public static DC_COVER_SCOPE_TYPE_ROOF: string = "";
  public static LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE: string = `${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE: string = `${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE: string = `${CONST.LB_POSITION_ROOF} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE: string = `${CONST.LB_POSITION_ROOF} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_ENDPIECE: string = CONST.LB_POSITION_ENDPIECE;
  public static VL_COVER_SCOPE_TYPE_ENDPIECE: string = CONST.LB_POSITION_ENDPIECE;
  public static DC_COVER_SCOPE_TYPE_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_SIDE: string = CONST.LB_POSITION_SIDE;
  public static VL_COVER_SCOPE_TYPE_SIDE: string = CONST.LB_POSITION_SIDE;
  public static DC_COVER_SCOPE_TYPE_SIDE: string = "";
  public static LB_COVER_SCOPE_TYPE_DOUBLE_SEP_TRUNK_SEP_ENDPIECE: string = `${CONST.LB_POSITION_DOUBLE_CENTER} / ${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_SIDE} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_DOUBLE_SEP_TRUNK_SEP_ENDPIECE: string = `${CONST.LB_POSITION_DOUBLE_CENTER} / ${CONST.LB_POSITION_ROOF}+${CONST.LB_POSITION_SIDE} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_DOUBLE_SEP_TRUNK_SEP_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_SIDE_SEP_ENDPIECE: string = `${CONST.LB_POSITION_SIDE} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_SIDE_SEP_ENDPIECE: string = `${CONST.LB_POSITION_SIDE} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_SIDE_SEP_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_COLUMN: string = `${CONST.LB_POSITION_SIDE_COLUMN}(측면)`;
  public static VL_COVER_SCOPE_TYPE_COLUMN: string = `${CONST.LB_POSITION_SIDE_COLUMN}(측면)`;
  public static DC_COVER_SCOPE_TYPE_COLUMN: string = "";
  public static LB_COVER_SCOPE_TYPE_WING: string = `${CONST.LB_POSITION_SIDE_WING}(측면)`;
  public static VL_COVER_SCOPE_TYPE_WING: string = `${CONST.LB_POSITION_SIDE_WING}(측면)`;
  public static DC_COVER_SCOPE_TYPE_WING: string = "";
  public static LB_COVER_SCOPE_TYPE_CEILING: string = CONST.LB_POSITION_CEILING;
  public static VL_COVER_SCOPE_TYPE_CEILING: string = CONST.LB_POSITION_CEILING;
  public static DC_COVER_SCOPE_TYPE_CEILING: string = "";
  public static LB_COVER_SCOPE_TYPE_CEILING_SEP_POCKET: string = `${CONST.LB_POSITION_CEILING} / 주머니`;
  public static VL_COVER_SCOPE_TYPE_CEILING_SEP_POCKET: string = `${CONST.LB_POSITION_CEILING} / 주머니`;
  public static DC_COVER_SCOPE_TYPE_CEILING_SEP_POCKET: string = "";
  public static LB_COVER_SCOPE_TYPE_CEILING_AND_ENDPIECE: string = `${CONST.LB_POSITION_CEILING}+${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_CEILING_AND_ENDPIECE: string = `${CONST.LB_POSITION_CEILING}+${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_CEILING_AND_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_CEILING_SEP_ENDPIECE: string = `${CONST.LB_POSITION_CEILING} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_CEILING_SEP_ENDPIECE: string = `${CONST.LB_POSITION_CEILING} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_CEILING_SEP_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_AND_ENDPIECE: string = `${CONST.LB_POSITION_SIDE_COLUMN} / ${CONST.LB_POSITION_CEILING}+${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_AND_ENDPIECE: string = `${CONST.LB_POSITION_SIDE_COLUMN} / ${CONST.LB_POSITION_CEILING}+${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_AND_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_ENDPIECE: string = `${CONST.LB_POSITION_SIDE_COLUMN} / ${CONST.LB_POSITION_CEILING} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_ENDPIECE: string = `${CONST.LB_POSITION_SIDE_COLUMN} / ${CONST.LB_POSITION_CEILING} / ${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE: string = `${CONST.LB_POSITION_SIDE_COLUMN} / ${CONST.LB_POSITION_CEILING} / 주머니+${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE: string = `${CONST.LB_POSITION_SIDE_COLUMN} / ${CONST.LB_POSITION_CEILING} / 주머니+${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE: string = "";
  public static LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_SEP_ENDPIECE: string = `${CONST.LB_POSITION_SIDE_COLUMN} / ${CONST.LB_POSITION_CEILING} / 주머니 / ${CONST.LB_POSITION_ENDPIECE}`;
  public static VL_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_SEP_ENDPIECE: string = `${CONST.LB_POSITION_SIDE_COLUMN} / ${CONST.LB_POSITION_CEILING} / 주머니 / ${CONST.LB_POSITION_ENDPIECE}`;
  public static DC_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_SEP_ENDPIECE: string = "";

  // 피복 고정
  public static LB_FIXING_TYPE_NORMAL_PAD: string = "일반 패드 고정";
  public static VL_FIXING_TYPE_NORMAL_PAD: string = "일반 패드 고정";
  public static DC_FIXING_TYPE_NORMAL_PAD: string = "";
  public static LB_FIXING_TYPE_LAGGING_PAD: string = "광폭 패드 고정";
  public static VL_FIXING_TYPE_LAGGING_PAD: string = "광폭 패드 고정";
  public static DC_FIXING_TYPE_LAGGING_PAD: string = "";
  public static LB_FIXING_TYPE_CONDENSATION_PAD: string = "결로 패드 고정";
  public static VL_FIXING_TYPE_CONDENSATION_PAD: string = "결로 패드 고정";
  public static DC_FIXING_TYPE_CONDENSATION_PAD: string = "";
  public static LB_FIXING_TYPE_WOOD: string = "목재 고정";
  public static VL_FIXING_TYPE_WOOD: string = "목재 고정";
  public static DC_FIXING_TYPE_WOOD: string = "";
  public static LB_FIXING_TYPE_CLIP: string = "클립 고정";
  public static VL_FIXING_TYPE_CLIP: string = "클립 고정";
  public static DC_FIXING_TYPE_CLIP: string = "";
  public static LB_FIXING_TYPE_NORMAL_CLIP: string = "일반 클립 고정";
  public static VL_FIXING_TYPE_NORMAL_CLIP: string = "일반 클립 고정";
  public static DC_FIXING_TYPE_NORMAL_CLIP: string = "";
  public static LB_FIXING_TYPE_SKIRT_CLIP: string = "치마 클립 고정";
  public static VL_FIXING_TYPE_SKIRT_CLIP: string = "치마 클립 고정";
  public static DC_FIXING_TYPE_SKIRT_CLIP: string = "";
  public static LB_FIXING_TYPE_TOW_CLIP: string = "예인 클립 고정";
  public static VL_FIXING_TYPE_TOW_CLIP: string = "예인 클립 고정";
  public static DC_FIXING_TYPE_TOW_CLIP: string = "";
  public static LB_FIXING_TYPE_NONE: string = "고정 안함";
  public static VL_FIXING_TYPE_NONE: string = "고정 안함";
  public static DC_FIXING_TYPE_NONE: string = "";
  public static LB_FIXING_TYPE_NO: string = "없음";
  public static VL_FIXING_TYPE_NO: string = "없음";
  public static DC_FIXING_TYPE_NO: string = "";

  // 치마 피복
  public static LB_SKIRT_TYPE_NORMAL: string = "일반 치마";
  public static VL_SKIRT_TYPE_NORMAL: string = "일반 치마";
  public static DC_SKIRT_TYPE_NORMAL: string = "";
  public static LB_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER: string = "일반 치마(기초피복 연장)";
  public static VL_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER: string = "일반 치마(기초피복 연장)";
  public static DC_SKIRT_TYPE_NORMAL_EXTEND_BASIC_COVER: string = "";
  public static LB_SKIRT_TYPE_STRING: string = "끈 치마";
  public static VL_SKIRT_TYPE_STRING: string = "끈 치마";
  public static DC_SKIRT_TYPE_STRING: string = "";

  // 바람막이 피복
  public static LB_WINDBREAK_TYPE_DEFAULT: string = "기본";
  public static VL_WINDBREAK_TYPE_DEFAULT: string = "기본";
  public static DC_WINDBREAK_TYPE_DEFAULT: string = "";
  public static LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER: string = "기본(기초피복 연장)";
  public static VL_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER: string = "기본(기초피복 연장)";
  public static DC_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER: string = "";
  public static LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE: string = "기본(1중측면 연장)";
  public static VL_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE: string = "기본(1중측면 연장)";
  public static DC_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE: string = "";

  // 방충망
  public static LB_SCREENNET_TYPE_DEFAULT: string = "기본";
  public static VL_SCREENNET_TYPE_DEFAULT: string = "기본";
  public static DC_SCREENNET_TYPE_DEFAULT: string = "";

  // 밴드끈
  public static LB_BANDSTRING_TYPE_NORMAL: string = "일반 밴드끈";
  public static VL_BANDSTRING_TYPE_NORMAL: string = "일반 밴드끈";
  public static DC_BANDSTRING_TYPE_NORMAL: string = "";
  public static LB_BANDSTRING_TYPE_POLY: string = "폴리 밴드끈";
  public static VL_BANDSTRING_TYPE_POLY: string = "폴리 밴드끈";
  public static DC_BANDSTRING_TYPE_POLY: string = "";
  public static LB_BANDSTRING_TYPE_VINYL: string = "비닐 밴드끈";
  public static VL_BANDSTRING_TYPE_VINYL: string = "비닐 밴드끈";
  public static DC_BANDSTRING_TYPE_VINYL: string = "";
  public static LB_BANDSTRING_TYPE_NET: string = "망 밴드끈";
  public static VL_BANDSTRING_TYPE_NET: string = "망 밴드끈";
  public static DC_BANDSTRING_TYPE_NET: string = "";
  public static LB_BANDSTRING_TYPE_WEAVING: string = "직조 밴드끈";
  public static VL_BANDSTRING_TYPE_WEAVING: string = "직조 밴드끈";
  public static DC_BANDSTRING_TYPE_WEAVING: string = "";

  public static LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_AND_ROOF: string = CONST.LB_POSITION_SIDE_AND_ROOF;
  public static VL_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_AND_ROOF: string = CONST.LB_POSITION_SIDE_AND_ROOF;
  public static DC_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_AND_ROOF: string = "";
  public static LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_SEP_ROOF: string = `${CONST.LB_POSITION_SIDE} / ${CONST.LB_POSITION_ROOF}`;
  public static VL_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_SEP_ROOF: string = `${CONST.LB_POSITION_SIDE} / ${CONST.LB_POSITION_ROOF}`;
  public static DC_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_SEP_ROOF: string = "";
  public static LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE: string = CONST.LB_POSITION_SIDE;
  public static VL_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE: string = CONST.LB_POSITION_SIDE;
  public static DC_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE: string = "";
  public static LB_BANDSTRING_INSTALL_LOCATION_TYPE_ROOF: string = CONST.LB_POSITION_ROOF;
  public static VL_BANDSTRING_INSTALL_LOCATION_TYPE_ROOF: string = CONST.LB_POSITION_ROOF;
  public static DC_BANDSTRING_INSTALL_LOCATION_TYPE_ROOF: string = "";

  public static LB_BANDSTRING_FIXING_TYPE_NONE: string = "없음";
  public static VL_BANDSTRING_FIXING_TYPE_NONE: string = "없음";
  public static DC_BANDSTRING_FIXING_TYPE_NONE: string = "";
  public static LB_BANDSTRING_FIXING_TYPE_STAKE: string = "말뚝 고정";
  public static VL_BANDSTRING_FIXING_TYPE_STAKE: string = "말뚝 고정";
  public static DC_BANDSTRING_FIXING_TYPE_STAKE: string = "";
  public static LB_BANDSTRING_FIXING_TYPE_STAKE_WIRE: string = "말뚝+강선 고정";
  public static VL_BANDSTRING_FIXING_TYPE_STAKE_WIRE: string = "말뚝+강선 고정";
  public static DC_BANDSTRING_FIXING_TYPE_STAKE_WIRE: string = "";
  public static LB_BANDSTRING_FIXING_TYPE_HOOK: string = "고리 고정";
  public static VL_BANDSTRING_FIXING_TYPE_HOOK: string = "고리 고정";
  public static DC_BANDSTRING_FIXING_TYPE_HOOK: string = "";
  public static LB_BANDSTRING_FIXING_TYPE_HOOK_PIPE: string = "고리+파이프 고정";
  public static VL_BANDSTRING_FIXING_TYPE_HOOK_PIPE: string = "고리+파이프 고정";
  public static DC_BANDSTRING_FIXING_TYPE_HOOK_PIPE: string = "";
  public static LB_BANDSTRING_FIXING_TYPE_HOOK_PIPE_CLIP: string = "고리+파이프+클립 고정";
  public static VL_BANDSTRING_FIXING_TYPE_HOOK_PIPE_CLIP: string = "고리+파이프+클립 고정";
  public static DC_BANDSTRING_FIXING_TYPE_HOOK_PIPE_CLIP: string = "";
  public static LB_BANDSTRING_FIXING_TYPE_NORMAL_PAD: string = "일반 패드 고정";
  public static VL_BANDSTRING_FIXING_TYPE_NORMAL_PAD: string = "일반 패드 고정";
  public static DC_BANDSTRING_FIXING_TYPE_NORMAL_PAD: string = "";

  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_2: string = "1칸에 2줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_2: string = "1칸에 2줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_2: string = "";
  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_1: string = "1칸에 1줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_1: string = "1칸에 1줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_1: string = "";
  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_2_1: string = "2칸에 1줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_2_1: string = "2칸에 1줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_2_1: string = "";
  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_3_1: string = "3칸에 1줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_3_1: string = "3칸에 1줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_3_1: string = "";
  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_4_1: string = "4칸에 1줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_4_1: string = "4칸에 1줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_4_1: string = "";
  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_5_1: string = "5칸에 1줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_5_1: string = "5칸에 1줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_5_1: string = "";
  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_6_1: string = "6칸에 1줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_6_1: string = "6칸에 1줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_6_1: string = "";
  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_8_1: string = "8칸에 1줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_8_1: string = "8칸에 1줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_8_1: string = "";
  public static LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_10_1: string = "10칸에 1줄";
  public static VL_BANDSTRING_RAFTER_BASED_LINE_NUMBER_10_1: string = "10칸에 1줄";
  public static DC_BANDSTRING_RAFTER_BASED_LINE_NUMBER_10_1: string = "";

  // 커튼 개폐
  public static LB_CURTAIN_SWITCH_TYPE_POWER_WRAP: string = "권취식 동력 개폐";
  public static VL_CURTAIN_SWITCH_TYPE_POWER_WRAP: string = "권취식 동력 개폐";
  public static DC_CURTAIN_SWITCH_TYPE_POWER_WRAP: string = "";
  public static LB_CURTAIN_SWITCH_TYPE_POWER_TOW: string = "예인식 동력 개폐";
  public static VL_CURTAIN_SWITCH_TYPE_POWER_TOW: string = "예인식 동력 개폐";
  public static DC_CURTAIN_SWITCH_TYPE_POWER_TOW: string = "";
  public static LB_CURTAIN_SWITCH_TYPE_MOTOR_WRAP: string = "권취식 감속 모터";
  public static VL_CURTAIN_SWITCH_TYPE_MOTOR_WRAP: string = "권취식 감속 모터";
  public static DC_CURTAIN_SWITCH_TYPE_MOTOR_WRAP: string = "";
  public static LB_CURTAIN_SWITCH_TYPE_MOTOR_TOW: string = "예인식 감속 모터";
  public static VL_CURTAIN_SWITCH_TYPE_MOTOR_TOW: string = "예인식 감속 모터";
  public static DC_CURTAIN_SWITCH_TYPE_MOTOR_TOW: string = "";

  public static LB_CURTAIN_SWITCH_AXIS_HOLDER_BEARING: string = "축수 베어링";
  public static VL_CURTAIN_SWITCH_AXIS_HOLDER_BEARING: string = "축수 베어링";
  public static DC_CURTAIN_SWITCH_AXIS_HOLDER_BEARING: string = "";
  public static LB_CURTAIN_SWITCH_AXIS_HOLDER_SADDLE: string = "새들 고정구";
  public static VL_CURTAIN_SWITCH_AXIS_HOLDER_SADDLE: string = "새들 고정구";
  public static DC_CURTAIN_SWITCH_AXIS_HOLDER_SADDLE: string = "";
  public static LB_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP: string = "파이프+일반 클립";
  public static VL_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP: string = "파이프+일반 클립";
  public static DC_CURTAIN_SWITCH_FIXING_TYPE_PIPE_CLIP: string = "";
  public static LB_CURTAIN_SWITCH_FIXING_TYPE_STRING_CLIP: string = "한끈+고리 예인 클립";
  public static VL_CURTAIN_SWITCH_FIXING_TYPE_STRING_CLIP: string = "한끈+고리 예인 클립";
  public static DC_CURTAIN_SWITCH_FIXING_TYPE_STRING_CLIP: string = "";
  public static LB_CURTAIN_SWITCH_LINE_TYPE_RIPCORD: string = "낙하산 줄";
  public static VL_CURTAIN_SWITCH_LINE_TYPE_RIPCORD: string = "낙하산 줄";
  public static DC_CURTAIN_SWITCH_LINE_TYPE_RIPCORD: string = "";
  public static LB_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE: string = "코팅 와이어";
  public static VL_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE: string = "코팅 와이어";
  public static DC_CURTAIN_SWITCH_LINE_TYPE_COATING_WIRE: string = "";
  public static LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK: string = "막대형";
  public static VL_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK: string = "막대형";
  public static DC_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK: string = "";
  public static LB_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK_AND_HOOK: string = "막대형+고리형";
  public static VL_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK_AND_HOOK: string = "막대형+고리형";
  public static DC_CURTAIN_SWITCH_TOW_CLIP_TYPE_STICK_AND_HOOK: string = "";

  // 천창 골조
  public static LB_SKYLIGHT_FRAME_TYPE_ARCH: string = "아치형";
  public static VL_SKYLIGHT_FRAME_TYPE_ARCH: string = "아치형";
  public static DC_SKYLIGHT_FRAME_TYPE_ARCH: string = "";
  public static LB_SKYLIGHT_FRAME_TYPE_TRIANGLE: string = "삼각형";
  public static VL_SKYLIGHT_FRAME_TYPE_TRIANGLE: string = "삼각형";
  public static DC_SKYLIGHT_FRAME_TYPE_TRIANGLE: string = "";
  public static LB_SKYLIGHT_FRAME_TYPE_HALF: string = "반천창형";
  public static VL_SKYLIGHT_FRAME_TYPE_HALF: string = "반천창형";
  public static DC_SKYLIGHT_FRAME_TYPE_HALF: string = "";
  public static LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH: string = "랙피니언(양개형)";
  public static VL_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH: string = "랙피니언(양개형)";
  public static DC_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH: string = "";
  public static LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE: string = "랙피니언(편개형)";
  public static VL_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE: string = "랙피니언(편개형)";
  public static DC_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE: string = "";
  public static LB_SKYLIGHT_FRAME_TYPE_CUSTOM: string = "임의 형태";
  public static VL_SKYLIGHT_FRAME_TYPE_CUSTOM: string = "임의 형태";
  public static DC_SKYLIGHT_FRAME_TYPE_CUSTOM: string = "";

  public static LB_SKYLIGHT_FRAME_ENHANCED_TYPE_NONE: string = "보강 없음";
  public static VL_SKYLIGHT_FRAME_ENHANCED_TYPE_NONE: string = "보강 없음";
  public static DC_SKYLIGHT_FRAME_ENHANCED_TYPE_NONE: string = "";
  public static LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE: string = "새들 보강";
  public static VL_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE: string = "새들 보강";
  public static DC_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE: string = "";
  public static LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_CONDENSATION_PAD: string = "새들+결로 패드 보강";
  public static VL_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_CONDENSATION_PAD: string = "새들+결로 패드 보강";
  public static DC_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_CONDENSATION_PAD: string = "";
  public static LB_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_NORMAL_PAD: string = "새들+일반 패드 보강";
  public static VL_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_NORMAL_PAD: string = "새들+일반 패드 보강";
  public static DC_SKYLIGHT_FRAME_ENHANCED_TYPE_SADDLE_NORMAL_PAD: string = "";

  // 천창 피복
  public static LB_SKYLIGHT_COVER_TYPE_NORMAL: string = "일반 피복";
  public static VL_SKYLIGHT_COVER_TYPE_NORMAL: string = "일반 피복";
  public static DC_SKYLIGHT_COVER_TYPE_NORMAL: string = "";

  // 칸막이
  public static LB_PARTITION_TYPE_WHOLE: string = "전체형";
  public static VL_PARTITION_TYPE_WHOLE: string = "전체형";
  public static DC_PARTITION_TYPE_WHOLE: string = "";
  public static LB_PARTITION_TYPE_CUSTOM: string = "임의 형태";
  public static VL_PARTITION_TYPE_CUSTOM: string = "임의 형태";
  public static DC_PARTITION_TYPE_CUSTOM: string = "";

  // 창문
  public static LB_WINDOW_TYPE_HINGED: string = "여닫이창문";
  public static VL_WINDOW_TYPE_HINGED: string = "여닫이창문";
  public static DC_WINDOW_TYPE_HINGED: string = "";
  public static LB_WINDOW_TYPE_SASH: string = "샷시창문";
  public static VL_WINDOW_TYPE_SASH: string = "샷시창문";
  public static DC_WINDOW_TYPE_SASH: string = "";

  public static LB_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER: string = "서까래/기둥 사이 설치";
  public static VL_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER: string = "서까래/기둥 사이 설치";
  public static DC_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER: string = "";
  public static LB_WINDOW_SASH_FRAME_INSTALL_TYPE_STRAIGHT: string = "가로대 2줄 설치";
  public static VL_WINDOW_SASH_FRAME_INSTALL_TYPE_STRAIGHT: string = "가로대 2줄 설치";
  public static DC_WINDOW_SASH_FRAME_INSTALL_TYPE_STRAIGHT: string = "";

  // 작업동
  public static LB_WORKROOM_TYPE_CUSTOM: string = "임의 형태";
  public static VL_WORKROOM_TYPE_CUSTOM: string = "임의 형태";
  public static DC_WORKROOM_TYPE_CUSTOM: string = "";

  // 기타 자재
  public static LB_MATERIAL_TYPE_CUSTOM: string = "임의 품목";
  public static VL_MATERIAL_TYPE_CUSTOM: string = "임의 품목";
  public static DC_MATERIAL_TYPE_CUSTOM: string = "";

  // 환기 외부
  public static LB_VENT_EXHAUST_TYPE_FAN: string = "배기팬";
  public static VL_VENT_EXHAUST_TYPE_FAN: string = "배기팬";
  public static DC_VENT_EXHAUST_TYPE_FAN: string = "";
  public static LB_VENT_EXHAUST_TYPE_CUSTOM: string = "임의 형태";
  public static VL_VENT_EXHAUST_TYPE_CUSTOM: string = "임의 형태";
  public static DC_VENT_EXHAUST_TYPE_CUSTOM: string = "";
  public static LB_VENT_EXHAUST_TYPE_NONE: string = "설치 안함";
  public static VL_VENT_EXHAUST_TYPE_NONE: string = "설치 안함";
  public static DC_VENT_EXHAUST_TYPE_NONE: string = "";

  public static LB_VENT_EXHAUST_ROOF_TYPE_FAN_POWER: string = "환기팬(동력)";
  public static VL_VENT_EXHAUST_ROOF_TYPE_FAN_POWER: string = "환기팬(동력)";
  public static DC_VENT_EXHAUST_ROOF_TYPE_FAN_POWER: string = "";
  public static LB_VENT_EXHAUST_ROOF_TYPE_FAN_MANUAL: string = "환기구(수동)";
  public static VL_VENT_EXHAUST_ROOF_TYPE_FAN_MANUAL: string = "환기구(수동)";
  public static DC_VENT_EXHAUST_ROOF_TYPE_FAN_MANUAL: string = "";
  public static LB_VENT_EXHAUST_ROOF_TYPE_CUSTOM: string = "임의 형태";
  public static VL_VENT_EXHAUST_ROOF_TYPE_CUSTOM: string = "임의 형태";
  public static DC_VENT_EXHAUST_ROOF_TYPE_CUSTOM: string = "";
  public static LB_VENT_EXHAUST_ROOF_TYPE_NONE: string = "설치 안함";
  public static VL_VENT_EXHAUST_ROOF_TYPE_NONE: string = "설치 안함";
  public static DC_VENT_EXHAUST_ROOF_TYPE_NONE: string = "";

  // 환기 내부
  public static LB_VENT_INSIDE_TYPE_AIR_FLOW_FAN: string = "공기 유동팬";
  public static VL_VENT_INSIDE_TYPE_AIR_FLOW_FAN: string = "공기 유동팬";
  public static DC_VENT_INSIDE_TYPE_AIR_FLOW_FAN: string = "";
  public static LB_VENT_INSIDE_TYPE_CUSTOM: string = "임의 형태";
  public static VL_VENT_INSIDE_TYPE_CUSTOM: string = "임의 형태";
  public static DC_VENT_INSIDE_TYPE_CUSTOM: string = "";

  // 관수 제어부
  public static LB_WATERING_MAINPIPE_TYPE_PE_CENTER: string = "PE관-중앙공급형";
  public static VL_WATERING_MAINPIPE_TYPE_PE_CENTER: string = "PE관-중앙공급형";
  public static DC_WATERING_MAINPIPE_TYPE_PE_CENTER: string = "";
  public static LB_WATERING_MAINPIPE_TYPE_PE_EACH: string = "PE관-개별공급형";
  public static VL_WATERING_MAINPIPE_TYPE_PE_EACH: string = "PE관-개별공급형";
  public static DC_WATERING_MAINPIPE_TYPE_PE_EACH: string = "";
  public static LB_WATERING_MAINPIPE_TYPE_CUSTOM: string = "임의 형태";
  public static VL_WATERING_MAINPIPE_TYPE_CUSTOM: string = "임의 형태";
  public static DC_WATERING_MAINPIPE_TYPE_CUSTOM: string = "";

  public static LB_WATERING_MAINPIPE_WATER_TANK_POSITION_SIDE: string = "측면";
  public static VL_WATERING_MAINPIPE_WATER_TANK_POSITION_SIDE: string = "측면";
  public static DC_WATERING_MAINPIPE_WATER_TANK_POSITION_SIDE: string = "";
  public static LB_WATERING_MAINPIPE_WATER_TANK_POSITION_CENTER: string = "중앙";
  public static VL_WATERING_MAINPIPE_WATER_TANK_POSITION_CENTER: string = "중앙";
  public static DC_WATERING_MAINPIPE_WATER_TANK_POSITION_CENTER: string = "";

  public static LB_WATERING_MAINPIPE_MAIN2_POSITION_LOWER: string = "하단";
  public static VL_WATERING_MAINPIPE_MAIN2_POSITION_LOWER: string = "하단";
  public static DC_WATERING_MAINPIPE_MAIN2_POSITION_LOWER: string = "";
  public static LB_WATERING_MAINPIPE_MAIN2_POSITION_UPPER: string = "상단";
  public static VL_WATERING_MAINPIPE_MAIN2_POSITION_UPPER: string = "상단";
  public static DC_WATERING_MAINPIPE_MAIN2_POSITION_UPPER: string = "";

  // 관수 지관부
  public static LB_WATERING_TYPE_SCREEN_MINIKLER: string = "수막 미니클러";
  public static VL_WATERING_TYPE_SCREEN_MINIKLER: string = "수막 미니클러";
  public static DC_WATERING_TYPE_SCREEN_MINIKLER: string = "";
  public static LB_WATERING_TYPE_SCREEN_FOUNTAIN_HOSE: string = "수막 분수호스";
  public static VL_WATERING_TYPE_SCREEN_FOUNTAIN_HOSE: string = "수막 분수호스";
  public static DC_WATERING_TYPE_SCREEN_FOUNTAIN_HOSE: string = "";
  public static LB_WATERING_TYPE_NONE: string = "설치 안함";
  public static VL_WATERING_TYPE_NONE: string = "설치 안함";
  public static DC_WATERING_TYPE_NONE: string = "";

  public static LB_WATERING_UPPER_TYPE_FOUNTAIN_MINIKLER: string = "분수 미니클러";
  public static VL_WATERING_UPPER_TYPE_FOUNTAIN_MINIKLER: string = "분수 미니클러";
  public static DC_WATERING_UPPER_TYPE_FOUNTAIN_MINIKLER: string = "";
  public static LB_WATERING_UPPER_TYPE_UPPER_FOUNTAIN_HOSE: string = "고설 분수호스";
  public static VL_WATERING_UPPER_TYPE_UPPER_FOUNTAIN_HOSE: string = "고설 분수호스";
  public static DC_WATERING_UPPER_TYPE_UPPER_FOUNTAIN_HOSE: string = "";
  public static LB_WATERING_UPPER_TYPE_UPPER_SIDE_FOUNTAIN_HOSE: string = "고설+측설 분수호스";
  public static VL_WATERING_UPPER_TYPE_UPPER_SIDE_FOUNTAIN_HOSE: string = "고설+측설 분수호스";
  public static DC_WATERING_UPPER_TYPE_UPPER_SIDE_FOUNTAIN_HOSE: string = "";
  public static LB_WATERING_UPPER_TYPE_NONE: string = "설치 안함";
  public static VL_WATERING_UPPER_TYPE_NONE: string = "설치 안함";
  public static DC_WATERING_UPPER_TYPE_NONE: string = "";

  public static LB_WATERING_LOWER_TYPE_LOWER_FOUNTAIN_HOSE: string = "저설 분수호스";
  public static VL_WATERING_LOWER_TYPE_LOWER_FOUNTAIN_HOSE: string = "저설 분수호스";
  public static DC_WATERING_LOWER_TYPE_LOWER_FOUNTAIN_HOSE: string = "";
  public static LB_WATERING_LOWER_TYPE_MULCHING_FOUNTAIN_HOSE: string = "관수(멀칭) 분수호스";
  public static VL_WATERING_LOWER_TYPE_MULCHING_FOUNTAIN_HOSE: string = "관수(멀칭) 분수호스";
  public static DC_WATERING_LOWER_TYPE_MULCHING_FOUNTAIN_HOSE: string = "";
  public static LB_WATERING_LOWER_TYPE_NONE: string = "설치 안함";
  public static VL_WATERING_LOWER_TYPE_NONE: string = "설치 안함";
  public static DC_WATERING_LOWER_TYPE_NONE: string = "";

  public static LB_WATERING_DRIP_TYPE_DRIP_WATERING: string = "점적관수";
  public static VL_WATERING_DRIP_TYPE_DRIP_WATERING: string = "점적관수";
  public static DC_WATERING_DRIP_TYPE_DRIP_WATERING: string = "";
  public static LB_WATERING_DRIP_TYPE_NONE: string = "설치 안함";
  public static VL_WATERING_DRIP_TYPE_NONE: string = "설치 안함";
  public static DC_WATERING_DRIP_TYPE_NONE: string = "";

  public static LB_WATERING_MINIKLER_PIPE_FIX_TYPE_HANGER_SPRING: string = "관 걸이+스프링 고정";
  public static VL_WATERING_MINIKLER_PIPE_FIX_TYPE_HANGER_SPRING: string = "관 걸이+스프링 고정";
  public static DC_WATERING_MINIKLER_PIPE_FIX_TYPE_HANGER_SPRING: string = "";
  public static LB_WATERING_MINIKLER_PIPE_FIX_TYPE_GRABBER: string = "강선 조리개 고정";
  public static VL_WATERING_MINIKLER_PIPE_FIX_TYPE_GRABBER: string = "강선 조리개 고정";
  public static DC_WATERING_MINIKLER_PIPE_FIX_TYPE_GRABBER: string = "";

  public static LB_WATERING_DRIP_PIPE_TYPE_DRIP_TAPE: string = "점적테이프";
  public static VL_WATERING_DRIP_PIPE_TYPE_DRIP_TAPE: string = "점적테이프";
  public static DC_WATERING_DRIP_PIPE_TYPE_DRIP_TAPE: string = "";
  public static LB_WATERING_DRIP_PIPE_TYPE_DRIP_PIPE: string = "점적파이프";
  public static VL_WATERING_DRIP_PIPE_TYPE_DRIP_PIPE: string = "점적파이프";
  public static DC_WATERING_DRIP_PIPE_TYPE_DRIP_PIPE: string = "";
  public static LB_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON: string = "점적단추";
  public static VL_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON: string = "점적단추";
  public static DC_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON: string = "";
  public static LB_WATERING_DRIP_PIPE_TYPE_TUBE_DRIP_BUTTON: string = "튜브+점적단추";
  public static VL_WATERING_DRIP_PIPE_TYPE_TUBE_DRIP_BUTTON: string = "튜브+점적단추";
  public static DC_WATERING_DRIP_PIPE_TYPE_TUBE_DRIP_BUTTON: string = "";
  public static LB_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON_DROPPER: string = "점적단추+드롭퍼";
  public static VL_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON_DROPPER: string = "점적단추+드롭퍼";
  public static DC_WATERING_DRIP_PIPE_TYPE_DRIP_BUTTON_DROPPER: string = "";

  public static LB_WATERING_DRIP_CONNECT_TYPE_SADDLE_BALL_VALVE_BARB: string = "새들+볼 밸브+미늘";
  public static VL_WATERING_DRIP_CONNECT_TYPE_SADDLE_BALL_VALVE_BARB: string = "새들+볼 밸브+미늘";
  public static DC_WATERING_DRIP_CONNECT_TYPE_SADDLE_BALL_VALVE_BARB: string = "";
  public static LB_WATERING_DRIP_CONNECT_TYPE_SADDLE_BARB_LINE_VALVE: string = "새들+미늘+라인 밸브";
  public static VL_WATERING_DRIP_CONNECT_TYPE_SADDLE_BARB_LINE_VALVE: string = "새들+미늘+라인 밸브";
  public static DC_WATERING_DRIP_CONNECT_TYPE_SADDLE_BARB_LINE_VALVE: string = "";
  public static LB_WATERING_DRIP_CONNECT_TYPE_START_SADDLE_LINE_VALVE: string = "스타트 새들+라인 밸브";
  public static VL_WATERING_DRIP_CONNECT_TYPE_START_SADDLE_LINE_VALVE: string = "스타트 새들+라인 밸브";
  public static DC_WATERING_DRIP_CONNECT_TYPE_START_SADDLE_LINE_VALVE: string = "";
  public static LB_WATERING_DRIP_CONNECT_TYPE_START_LINE_VALVE: string = "스타트 라인 밸브";
  public static VL_WATERING_DRIP_CONNECT_TYPE_START_LINE_VALVE: string = "스타트 라인 밸브";
  public static DC_WATERING_DRIP_CONNECT_TYPE_START_LINE_VALVE: string = "";

  public static LB_WATERING_DRIP_BARB_MAIN_TYPE_1_HOLE: string = "1구";
  public static VL_WATERING_DRIP_BARB_MAIN_TYPE_1_HOLE: string = "1구";
  public static DC_WATERING_DRIP_BARB_MAIN_TYPE_1_HOLE: string = "";
  public static LB_WATERING_DRIP_BARB_MAIN_TYPE_2_HOLE: string = "2구";
  public static VL_WATERING_DRIP_BARB_MAIN_TYPE_2_HOLE: string = "2구";
  public static DC_WATERING_DRIP_BARB_MAIN_TYPE_2_HOLE: string = "";
  public static LB_WATERING_DRIP_BARB_MAIN_TYPE_3_HOLE: string = "3구";
  public static VL_WATERING_DRIP_BARB_MAIN_TYPE_3_HOLE: string = "3구";
  public static DC_WATERING_DRIP_BARB_MAIN_TYPE_3_HOLE: string = "";

  public static LB_WATERING_SCREEN_GUTTER_TYPE_WEAVING_FILM: string = "직조 필름 물받이";
  public static VL_WATERING_SCREEN_GUTTER_TYPE_WEAVING_FILM: string = "직조 필름 물받이";
  public static DC_WATERING_SCREEN_GUTTER_TYPE_WEAVING_FILM: string = "";
  public static LB_WATERING_SCREEN_GUTTER_TYPE_PLASTIC: string = "플라스틱 물받이";
  public static VL_WATERING_SCREEN_GUTTER_TYPE_PLASTIC: string = "플라스틱 물받이";
  public static DC_WATERING_SCREEN_GUTTER_TYPE_PLASTIC: string = "";

  // 양액 제어부
  public static LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE: string = "고형배지경";
  public static VL_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE: string = "고형배지경";
  public static DC_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE: string = "";
  public static LB_HYD_SYSTEM_TYPE_NFT: string = "NFT";
  public static VL_HYD_SYSTEM_TYPE_NFT: string = "NFT";
  public static DC_HYD_SYSTEM_TYPE_NFT: string = "";
  public static LB_HYD_SYSTEM_TYPE_DFT: string = "DFT";
  public static VL_HYD_SYSTEM_TYPE_DFT: string = "DFT";
  public static DC_HYD_SYSTEM_TYPE_DFT: string = "";
  public static LB_HYD_SYSTEM_TYPE_CUSTOM: string = "임의 형태";
  public static VL_HYD_SYSTEM_TYPE_CUSTOM: string = "임의 형태";
  public static DC_HYD_SYSTEM_TYPE_CUSTOM: string = "";

  public static LB_HYD_SYSTEM_CYCLE_TYPE_ACYCLE: string = "비순환형";
  public static VL_HYD_SYSTEM_CYCLE_TYPE_ACYCLE: string = "비순환형";
  public static DC_HYD_SYSTEM_CYCLE_TYPE_ACYCLE: string = "";
  public static LB_HYD_SYSTEM_CYCLE_TYPE_CYCLE: string = "순환형";
  public static VL_HYD_SYSTEM_CYCLE_TYPE_CYCLE: string = "순환형";
  public static DC_HYD_SYSTEM_CYCLE_TYPE_CYCLE: string = "";

  // 양액 베드부
  public static LB_HYD_CULTIVATION_TYPE_STANDING_GUTTER: string = "스텐딩 거터";
  public static VL_HYD_CULTIVATION_TYPE_STANDING_GUTTER: string = "스텐딩 거터";
  public static DC_HYD_CULTIVATION_TYPE_STANDING_GUTTER: string = "";
  public static LB_HYD_CULTIVATION_TYPE_HANGING_GUTTER_FIXING: string = "헹잉 거터(고정형)";
  public static VL_HYD_CULTIVATION_TYPE_HANGING_GUTTER_FIXING: string = "헹잉 거터(고정형)";
  public static DC_HYD_CULTIVATION_TYPE_HANGING_GUTTER_FIXING: string = "";
  public static LB_HYD_CULTIVATION_TYPE_HANGING_GUTTER_MOVE: string = "헹잉 거터(이동형)";
  public static VL_HYD_CULTIVATION_TYPE_HANGING_GUTTER_MOVE: string = "헹잉 거터(이동형)";
  public static DC_HYD_CULTIVATION_TYPE_HANGING_GUTTER_MOVE: string = "";
  public static LB_HYD_CULTIVATION_TYPE_STYROFOAM_BED: string = "스티로폼 베드";
  public static VL_HYD_CULTIVATION_TYPE_STYROFOAM_BED: string = "스티로폼 베드";
  public static DC_HYD_CULTIVATION_TYPE_STYROFOAM_BED: string = "";
  public static LB_HYD_CULTIVATION_TYPE_NFT_BED: string = "NFT 베드";
  public static VL_HYD_CULTIVATION_TYPE_NFT_BED: string = "NFT 베드";
  public static DC_HYD_CULTIVATION_TYPE_NFT_BED: string = "";
  public static LB_HYD_CULTIVATION_TYPE_CUSTOM: string = "임의 형태";
  public static VL_HYD_CULTIVATION_TYPE_CUSTOM: string = "임의 형태";
  public static DC_HYD_CULTIVATION_TYPE_CUSTOM: string = "";

  public static LB_HYD_CULTIVATION_CULTIVATION_WAY_LENGTH: string = "길이 방향";
  public static VL_HYD_CULTIVATION_CULTIVATION_WAY_LENGTH: string = "길이 방향";
  public static DC_HYD_CULTIVATION_CULTIVATION_WAY_LENGTH: string = "";
  public static LB_HYD_CULTIVATION_CULTIVATION_WAY_WIDTH: string = "폭 방향";
  public static VL_HYD_CULTIVATION_CULTIVATION_WAY_WIDTH: string = "폭 방향";
  public static DC_HYD_CULTIVATION_CULTIVATION_WAY_WIDTH: string = "";

  public static LB_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_NONE: string = "없음";
  public static VL_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_NONE: string = "없음";
  public static DC_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_NONE: string = "";
  public static LB_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_UPPER_TRELLIS: string = "고설 유인줄";
  public static VL_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_UPPER_TRELLIS: string = "고설 유인줄";
  public static DC_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_UPPER_TRELLIS: string = "";

  public static LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_NONE: string = "없음";
  public static VL_HYD_CULTIVATION_GROUND_RAIL_TYPE_NONE: string = "없음";
  public static DC_HYD_CULTIVATION_GROUND_RAIL_TYPE_NONE: string = "";
  public static LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_NORMAL: string = "일반 레일";
  public static VL_HYD_CULTIVATION_GROUND_RAIL_TYPE_NORMAL: string = "일반 레일";
  public static DC_HYD_CULTIVATION_GROUND_RAIL_TYPE_NORMAL: string = "";
  public static LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_TUBE: string = "튜브 레일";
  public static VL_HYD_CULTIVATION_GROUND_RAIL_TYPE_TUBE: string = "튜브 레일";
  public static DC_HYD_CULTIVATION_GROUND_RAIL_TYPE_TUBE: string = "";

  // 냉난방 난방
  public static LB_TEM_HEATING_TYPE_CUSTOM: string = "임의 형태";
  public static VL_TEM_HEATING_TYPE_CUSTOM: string = "임의 형태";
  public static DC_TEM_HEATING_TYPE_CUSTOM: string = "";

  public static LB_TEM_HEATING_KEEPING_TYPE_1L_THIN: string = "1중피복";
  public static VL_TEM_HEATING_KEEPING_TYPE_1L_THIN: string = "1중피복";
  public static DC_TEM_HEATING_KEEPING_TYPE_1L_THIN: string = "";
  public static LB_TEM_HEATING_KEEPING_TYPE_2L_THIN: string = "2중피복";
  public static VL_TEM_HEATING_KEEPING_TYPE_2L_THIN: string = "2중피복";
  public static DC_TEM_HEATING_KEEPING_TYPE_2L_THIN: string = "";
  public static LB_TEM_HEATING_KEEPING_TYPE_3L_THIN: string = "3중피복";
  public static VL_TEM_HEATING_KEEPING_TYPE_3L_THIN: string = "3중피복";
  public static DC_TEM_HEATING_KEEPING_TYPE_3L_THIN: string = "";
  public static LB_TEM_HEATING_KEEPING_TYPE_2L_THICK: string = "2중보온피복";
  public static VL_TEM_HEATING_KEEPING_TYPE_2L_THICK: string = "2중보온피복";
  public static DC_TEM_HEATING_KEEPING_TYPE_2L_THICK: string = "";
  public static LB_TEM_HEATING_KEEPING_TYPE_3L_THICK: string = "3중보온피복";
  public static VL_TEM_HEATING_KEEPING_TYPE_3L_THICK: string = "3중보온피복";
  public static DC_TEM_HEATING_KEEPING_TYPE_3L_THICK: string = "";
  public static LB_TEM_HEATING_KEEPING_TYPE_1L_THIN_1L_CURTAIN: string = "1중피복+1중커튼";
  public static VL_TEM_HEATING_KEEPING_TYPE_1L_THIN_1L_CURTAIN: string = "1중피복+1중커튼";
  public static DC_TEM_HEATING_KEEPING_TYPE_1L_THIN_1L_CURTAIN: string = "";
  public static LB_TEM_HEATING_KEEPING_TYPE_1L_THIN_2L_CURTAIN: string = "1중피복+2중커튼";
  public static VL_TEM_HEATING_KEEPING_TYPE_1L_THIN_2L_CURTAIN: string = "1중피복+2중커튼";
  public static DC_TEM_HEATING_KEEPING_TYPE_1L_THIN_2L_CURTAIN: string = "";
  public static LB_TEM_HEATING_KEEPING_TYPE_1L_THIN_3L_CURTAIN: string = "1중피복+3중커튼";
  public static VL_TEM_HEATING_KEEPING_TYPE_1L_THIN_3L_CURTAIN: string = "1중피복+3중커튼";
  public static DC_TEM_HEATING_KEEPING_TYPE_1L_THIN_3L_CURTAIN: string = "";
  public static LB_TEM_HEATING_KEEPING_TYPE_WATER_SCREEN: string = "수막";
  public static VL_TEM_HEATING_KEEPING_TYPE_WATER_SCREEN: string = "수막";
  public static DC_TEM_HEATING_KEEPING_TYPE_WATER_SCREEN: string = "";

  // 냉난방 냉방
  public static LB_TEM_COOLING_TYPE_CUSTOM: string = "임의 형태";
  public static VL_TEM_COOLING_TYPE_CUSTOM: string = "임의 형태";
  public static DC_TEM_COOLING_TYPE_CUSTOM: string = "";

  // 스마트팜 복합환경제어
  public static LB_ENVIRONMENT_TYPE_CUSTOM: string = "임의 형태";
  public static VL_ENVIRONMENT_TYPE_CUSTOM: string = "임의 형태";
  public static DC_ENVIRONMENT_TYPE_CUSTOM: string = "";

  // 운반레일
  public static LB_RAIL_TYPE_UPPER_SINGLE: string = "상단 싱글 레일";
  public static VL_RAIL_TYPE_UPPER_SINGLE: string = "상단 싱글 레일";
  public static DC_RAIL_TYPE_UPPER_SINGLE: string = "";
  public static LB_RAIL_TYPE_SIDE_SINGLE: string = "측면 싱글 레일";
  public static VL_RAIL_TYPE_SIDE_SINGLE: string = "측면 싱글 레일";
  public static DC_RAIL_TYPE_SIDE_SINGLE: string = "";
  public static LB_RAIL_TYPE_GROUND_DOUBLE: string = "지면 더블 레일";
  public static VL_RAIL_TYPE_GROUND_DOUBLE: string = "지면 더블 레일";
  public static DC_RAIL_TYPE_GROUND_DOUBLE: string = "";
  public static LB_RAIL_TYPE_GROUND_SINGLE: string = "지면 싱글 레일";
  public static VL_RAIL_TYPE_GROUND_SINGLE: string = "지면 싱글 레일";
  public static DC_RAIL_TYPE_GROUND_SINGLE: string = "";
  public static LB_RAIL_TYPE_NONE: string = "없음";
  public static VL_RAIL_TYPE_NONE: string = "없음";
  public static DC_RAIL_TYPE_NONE: string = "";

  public static LB_RAIL_HOOK_FIXED_FRAME_STRAIGHT: string = "가로대 파이프";
  public static VL_RAIL_HOOK_FIXED_FRAME_STRAIGHT: string = "가로대 파이프";
  public static DC_RAIL_HOOK_FIXED_FRAME_STRAIGHT: string = "";
  public static LB_RAIL_HOOK_FIXED_FRAME_RAFTER: string = "서까래 파이프";
  public static VL_RAIL_HOOK_FIXED_FRAME_RAFTER: string = "서까래 파이프";
  public static DC_RAIL_HOOK_FIXED_FRAME_RAFTER: string = "";
  public static LB_RAIL_HOOK_FIXED_FRAME_MIDDLE: string = "중방 파이프";
  public static VL_RAIL_HOOK_FIXED_FRAME_MIDDLE: string = "중방 파이프";
  public static DC_RAIL_HOOK_FIXED_FRAME_MIDDLE: string = "";

  // 선반/균상다이
  public static LB_RACK_TYPE_STAND_ALONE_DEFAULT: string = "독립형 기본";
  public static VL_RACK_TYPE_STAND_ALONE_DEFAULT: string = "독립형 기본";
  public static DC_RACK_TYPE_STAND_ALONE_DEFAULT: string = "";
  public static LB_RACK_TYPE_FRAME_INTEGRATED: string = "골조 일체형";
  public static VL_RACK_TYPE_FRAME_INTEGRATED: string = "골조 일체형";
  public static DC_RACK_TYPE_FRAME_INTEGRATED: string = "";
  public static LB_RACK_TYPE_CUSTOM: string = "임의 형태";
  public static VL_RACK_TYPE_CUSTOM: string = "임의 형태";
  public static DC_RACK_TYPE_CUSTOM: string = "";

  public static LB_RACK_FIXED_FRAME_PIPE_RAFTER: string = "서까래 파이프";
  public static VL_RACK_FIXED_FRAME_PIPE_RAFTER: string = "서까래 파이프";
  public static DC_RACK_FIXED_FRAME_PIPE_RAFTER: string = "";
  public static LB_RACK_FIXED_FRAME_PIPE_STRAIGHT: string = "가로대 파이프";
  public static VL_RACK_FIXED_FRAME_PIPE_STRAIGHT: string = "가로대 파이프";
  public static DC_RACK_FIXED_FRAME_PIPE_STRAIGHT: string = "";
  public static LB_RACK_FIXED_FRAME_PIPE_MIDDLE: string = "중방 파이프";
  public static VL_RACK_FIXED_FRAME_PIPE_MIDDLE: string = "중방 파이프";
  public static DC_RACK_FIXED_FRAME_PIPE_MIDDLE: string = "";

  // CO2
  public static LB_CO2_TYPE_CUSTOM: string = "임의 형태";
  public static VL_CO2_TYPE_CUSTOM: string = "임의 형태";
  public static DC_CO2_TYPE_CUSTOM: string = "";

  // 자동방제기
  public static LB_AUTOSPRAY_TYPE_CUSTOM: string = "임의 형태";
  public static VL_AUTOSPRAY_TYPE_CUSTOM: string = "임의 형태";
  public static DC_AUTOSPRAY_TYPE_CUSTOM: string = "";

  // 기타 기계/설비
  public static LB_OTHER_FACILITIES_TYPE_CUSTOM: string = "임의 형태";
  public static VL_OTHER_FACILITIES_TYPE_CUSTOM: string = "임의 형태";
  public static DC_OTHER_FACILITIES_TYPE_CUSTOM: string = "";

  // 통합 시공비
  public static LB_BUILDING_TYPE_CUSTOM: string = "임의 비용";
  public static VL_BUILDING_TYPE_CUSTOM: string = "임의 비용";
  public static DC_BUILDING_TYPE_CUSTOM: string = "";
  public static LB_BUILDING_TYPE_PER_AREA: string = "면적당 비용";
  public static VL_BUILDING_TYPE_PER_AREA: string = "면적당 비용";
  public static DC_BUILDING_TYPE_PER_AREA: string = "";

  public static LB_BUILDING_AREA_TYPE_SQUARE_METER: string = "㎡당 비용";
  public static VL_BUILDING_AREA_TYPE_SQUARE_METER: string = "㎡당 비용";
  public static DC_BUILDING_AREA_TYPE_SQUARE_METER: string = "";
  public static LB_BUILDING_AREA_TYPE_PYEONG: string = "평당 비용";
  public static VL_BUILDING_AREA_TYPE_PYEONG: string = "평당 비용";
  public static DC_BUILDING_AREA_TYPE_PYEONG: string = "";

  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 예상 시공비 관련
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레이블
  //----------------------------------

  // 단동
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_22MM_0M: number = 10000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_25MM_0M: number = 10000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_32MM_0M: number = 10000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_42MM_16M: number = 25000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_42MM_17M: number = 37000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_48MM_16M: number = 25000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_48MM_17M: number = 37000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_22MM_0M: number = 4000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_25MM_0M: number = 4000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_32MM_0M: number = 4000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_42MM_16M: number = 6000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_42MM_17M: number = 8000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_48MM_16M: number = 6000;
  public static LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_48MM_17M: number = 8000;
  public static LB_BUILDING_COST_SG_TRUNK_ENHANCED_GRABBER_SELECT: number = 3500;

  public static LB_BUILDING_COST_SG_GUTTER_TOTAL_LENGTH: number = 1200;

  public static LB_BUILDING_COST_SG_SWITCHES_LEVEL1_SWITCH_TYPE_MANUAL: number = 0;
  public static LB_BUILDING_COST_SG_SWITCHES_LEVEL1_SWITCH_TYPE_POWER: number = 2000;
  public static LB_BUILDING_COST_SG_SWITCHES_LEVEL2_SWITCH_TYPE_MANUAL: number = 1000;
  public static LB_BUILDING_COST_SG_SWITCHES_LEVEL2_SWITCH_TYPE_POWER: number = 1000;
  public static LB_BUILDING_COST_SG_SWITCHES_SWITCH_LEVEL_LEVEL1: number = 0;
  public static LB_BUILDING_COST_SG_SWITCHES_SWITCH_LEVEL_LEVEL2: number = 5000;

  public static LB_BUILDING_COST_SG_COVER_COVER_TYPE_VINYL_1: number = 2000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_VINYL_2: number = 1000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_WEAVING_FILM_2: number = 1000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_SHADENET_2: number = 1000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_TENT_2: number = 1000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_CASHMILON_2: number = 2000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_LAGGING_2: number = 3000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_NONWOVEN_2: number = 3000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_QUILTING_2: number = 3000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_SCREEN_2: number = 3000;
  public static LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_MAT_2: number = 3000;

  public static LB_BUILDING_COST_SG_SKYLIGHT_FRAME_TYPE_ARCH: number = 4000;
  public static LB_BUILDING_COST_SG_SKYLIGHT_FRAME_TYPE_TRIANGLE: number = 4000;
  public static LB_BUILDING_COST_SG_SKYLIGHT_FRAME_TYPE_HALF: number = 4000;
  public static LB_BUILDING_COST_SG_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_BOTH: number = 10000;
  public static LB_BUILDING_COST_SG_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_ONE: number = 5000;
  public static LB_BUILDING_COST_SG_SKYLIGHT_COVER_SELECT: number = 1000;

  public static LB_BUILDING_COST_SG_PARTITION_QUANTITY: number = 150000;

  public static LB_BUILDING_COST_SG_WINDOW_QUANTITY: number = 5000;

  public static LB_BUILDING_COST_SG_WORKROOM_AREA: number = 0;

  // 연동, 각연동
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_48MM: number = 20000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_60MM: number = 26000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_60x60MM: number = 26000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_75x75MM: number = 36000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_100x50MM: number = 36000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_125x75MM: number = 42000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_48MM: number = 3000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_60MM: number = 3000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_60x60MM: number = 3000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_75x75MM: number = 3000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_100x50MM: number = 3000;
  public static LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_125x75MM: number = 3000;

  public static LB_BUILDING_COST_IL_ROOF_ENHANCED_GRABBER_SELECT: number = 2000;

  public static LB_BUILDING_COST_IL_GUTTER_TOTAL_LENGTH: number = 1200;

  public static LB_BUILDING_COST_IL_SWITCHES_LEVEL1_SWITCH_TYPE_MANUAL: number = 0;
  public static LB_BUILDING_COST_IL_SWITCHES_LEVEL1_SWITCH_TYPE_POWER: number = 2000;
  public static LB_BUILDING_COST_IL_SWITCHES_LEVEL2_SWITCH_TYPE_MANUAL: number = 1000;
  public static LB_BUILDING_COST_IL_SWITCHES_LEVEL2_SWITCH_TYPE_POWER: number = 1000;
  public static LB_BUILDING_COST_IL_SWITCHES_SWITCH_LEVEL_LEVEL1: number = 0;
  public static LB_BUILDING_COST_IL_SWITCHES_SWITCH_LEVEL_LEVEL2: number = 5000;

  public static LB_BUILDING_COST_IL_COVER_COVER_TYPE_VINYL_1: number = 2000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_VINYL_2: number = 1000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_WEAVING_FILM_2: number = 1000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_SHADENET_2: number = 1000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_TENT_2: number = 1000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_CASHMILON_2: number = 2000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_LAGGING_2: number = 3000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_NONWOVEN_2: number = 3000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_QUILTING_2: number = 3000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_SCREEN_2: number = 3000;
  public static LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_MAT_2: number = 3000;

  public static LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_QUILTING: number = 7000;
  public static LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_SCREEN: number = 5000;
  public static LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_NONWOVEN: number = 5000;
  public static LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_MAT: number = 5000;
  public static LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_SHADENET: number = 5000;
  public static LB_BUILDING_COST_IL_CURTAIN_SWITCH_SELECT: number = 5000;

  public static LB_BUILDING_COST_IL_SKYLIGHT_FRAME_TYPE_ARCH: number = 4000;
  public static LB_BUILDING_COST_IL_SKYLIGHT_FRAME_TYPE_TRIANGLE: number = 4000;
  public static LB_BUILDING_COST_IL_SKYLIGHT_FRAME_TYPE_HALF: number = 4000;
  public static LB_BUILDING_COST_IL_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_BOTH: number = 10000;
  public static LB_BUILDING_COST_IL_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_ONE: number = 5000;
  public static LB_BUILDING_COST_IL_SKYLIGHT_COVER_SELECT: number = 1000;

  public static LB_BUILDING_COST_IL_PARTITION_QUANTITY: number = 150000;

  public static LB_BUILDING_COST_IL_WINDOW_QUANTITY: number = 5000;

  public static LB_BUILDING_COST_IL_WORKROOM_AREA: number = 0;

  // 벤로
  public static LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_50x30MM: number = 23000;
  public static LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_60x60MM: number = 32000;
  public static LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_75x45MM: number = 32000;
  public static LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_75x75MM: number = 45000;
  public static LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_100x50MM: number = 45000;
  public static LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_100x100MM: number = 52000;
  public static LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_125x75MM: number = 52000;
  public static LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_125x125MM: number = 61000;

  public static LB_BUILDING_COST_VL_ROOF_ENHANCED_GRABBER_SELECT: number = 2500;

  public static LB_BUILDING_COST_VL_RAFTER_VALLEY_QUANTITY_1: number = 0;
  public static LB_BUILDING_COST_VL_RAFTER_VALLEY_QUANTITY_2: number = 0;
  public static LB_BUILDING_COST_VL_RAFTER_VALLEY_QUANTITY_3: number = 1500;

  public static LB_BUILDING_COST_VL_GUTTER_TOTAL_LENGTH: number = 1500;

  public static LB_BUILDING_COST_VL_SWITCHES_LEVEL1_SWITCH_TYPE_MANUAL: number = 0;
  public static LB_BUILDING_COST_VL_SWITCHES_LEVEL1_SWITCH_TYPE_POWER: number = 2500;
  public static LB_BUILDING_COST_VL_SWITCHES_LEVEL2_SWITCH_TYPE_MANUAL: number = 1500;
  public static LB_BUILDING_COST_VL_SWITCHES_LEVEL2_SWITCH_TYPE_POWER: number = 1500;
  public static LB_BUILDING_COST_VL_SWITCHES_SWITCH_LEVEL_LEVEL1: number = 0;
  public static LB_BUILDING_COST_VL_SWITCHES_SWITCH_LEVEL_LEVEL2: number = 6200;

  public static LB_BUILDING_COST_VL_COVER_COVER_TYPE_VINYL_1: number = 2500;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_VINYL_2: number = 1500;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_WEAVING_FILM_2: number = 1500;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_SHADENET_2: number = 1500;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_TENT_2: number = 1500;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_CASHMILON_2: number = 2500;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_LAGGING_2: number = 4000;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_NONWOVEN_2: number = 4000;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_QUILTING_2: number = 4000;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_SCREEN_2: number = 4000;
  public static LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_MAT_2: number = 4000;

  public static LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_QUILTING: number = 9000;
  public static LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_SCREEN: number = 6500;
  public static LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_NONWOVEN: number = 6500;
  public static LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_MAT: number = 6500;
  public static LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_SHADENET: number = 6500;
  public static LB_BUILDING_COST_VL_CURTAIN_SWITCH_SELECT: number = 6500;

  public static LB_BUILDING_COST_VL_SKYLIGHT_FRAME_TYPE_ARCH: number = 5000;
  public static LB_BUILDING_COST_VL_SKYLIGHT_FRAME_TYPE_TRIANGLE: number = 5000;
  public static LB_BUILDING_COST_VL_SKYLIGHT_FRAME_TYPE_HALF: number = 5000;
  public static LB_BUILDING_COST_VL_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_BOTH: number = 13000;
  public static LB_BUILDING_COST_VL_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_ONE: number = 7000;
  public static LB_BUILDING_COST_VL_SKYLIGHT_COVER_SELECT: number = 2000;

  public static LB_BUILDING_COST_VL_PARTITION_QUANTITY: number = 200000;

  public static LB_BUILDING_COST_VL_WINDOW_QUANTITY: number = 7000;

  public static LB_BUILDING_COST_VL_WORKROOM_AREA: number = 0;
  // 공통
  public static LB_BUILDING_COST_CM_VENT_EXHAUST_ENDPIECE_FAN: number = 0;
  public static LB_BUILDING_COST_CM_VENT_EXHAUST_ROOF_TYPE_FAN_POWER: number = 0;
  public static LB_BUILDING_COST_CM_VENT_EXHAUST_ROOF_TYPE_FAN_MANUAL: number = 0;
  public static LB_BUILDING_COST_CM_VENT_INSIDE_AIR_FLOW_FAN_SELECT: number = 0;

  public static LB_BUILDING_COST_CM_WATERING_MAINPIPE_TYPE_CENTER: number = 100000;
  public static LB_BUILDING_COST_CM_WATERING_MAINPIPE_TYPE_EACH: number = 300000;
  public static LB_BUILDING_COST_CM_WATERING_MAINPIPE_TYPE_SCREEN_FOUNTAIN_HOSE: number = 0;
  public static LB_BUILDING_COST_CM_WATERING_WATERING_TYPE_SCREEN_MINIKLER: number = 3000;
  public static LB_BUILDING_COST_CM_WATERING_SCREEN_GUTTER_SELECT: number = 600;
  public static LB_BUILDING_COST_CM_WATERING_UPPER_TYPE_FOUNTAIN_MINIKLER: number = 3000;
  public static LB_BUILDING_COST_CM_WATERING_UPPER_TYPE_UPPER_FOUNTAIN_HOSE: number = 0;
  public static LB_BUILDING_COST_CM_WATERING_UPPER_TYPE_UPPER_SIDE_FOUNTAIN_HOSE: number = 0;
  public static LB_BUILDING_COST_CM_WATERING_FOG_SELECT: number = 3000;
  public static LB_BUILDING_COST_CM_WATERING_LOWER_TYPE_LOWER_FOUNTAIN_HOSE: number = 0;
  public static LB_BUILDING_COST_CM_WATERING_LOWER_TYPE_MULCHING_FOUNTAIN_HOSE: number = 0;
  public static LB_BUILDING_COST_CM_WATERING_WATERING_DRIP_TYPE_DRIP_WATERING: number = 1000;

  public static LB_BUILDING_COST_CM_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE: number = 7000;
  public static LB_BUILDING_COST_CM_HYD_SYSTEM_TYPE_NFT: number = 7000;
  public static LB_BUILDING_COST_CM_HYD_SYSTEM_TYPE_DFT: number = 7000;
  public static LB_BUILDING_COST_CM_HYD_SYSTEM_CYCLE_TYPE_CYCLE: number = 8000;
  public static LB_BUILDING_COST_CM_HYD_SYSTEM_CYCLE_TYPE_ACYCLE: number = 6000;
  public static LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_STANDING_GUTTER: number = 9000;
  public static LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_HANGING_GUTTER_FIXING: number = 10000;
  public static LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_HANGING_GUTTER_MOVE: number = 20000;
  public static LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_STYROFOAM_BED: number = 7000;
  public static LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_NFT_BED: number = 18000;
  public static LB_BUILDING_COST_CM_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_UPPER_TRELLIS: number = 1500;
  public static LB_BUILDING_COST_CM_HYD_CULTIVATION_CULTIVATION_GROUND_RAIL_TYPE_NORMAL: number = 1000;
  public static LB_BUILDING_COST_CM_HYD_CULTIVATION_CULTIVATION_GROUND_RAIL_TYPE_TUBE: number = 1500;

  public static LB_BUILDING_COST_CM_RACK_FLOOR_TOTAL_AREA: number = 93000;

  public static LB_BUILDING_COST_CM_RAIL_TOTAL_RAIL_LENGTH: number = 0;
  public static LB_BUILDING_COST_CM_RAIL_UPPER_RAIL_TOTAL_LENGTH: number = 1000;
  public static LB_BUILDING_COST_CM_RAIL_SIDE_RAIL_TOTAL_LENGTH: number = 1000;
  public static LB_BUILDING_COST_CM_RAIL_GROUND_RAIL_TOTAL_LENGTH: number = 1000;

  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 견적 관련
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레이블
  //----------------------------------

  public static LB_ESTIMATE_STATUS_WRITE: string = "쓰기";
  public static LB_ESTIMATE_STATUS_READ_ONLY: string = "읽기전용";

  public static LB_ESTIMATE_TAX_TYPE_TAXATION: string = "과세";
  public static LB_ESTIMATE_TAX_TYPE_REFUND: string = "환급";
  public static LB_ESTIMATE_TAX_TYPE_ZERO_RATE: string = "영세율";
  public static LB_ESTIMATE_TAX_TYPE_FREE: string = "면세";

  //----------------------------------
  // 아이디
  //----------------------------------

  public static ID_ESTIMATE_NORMAL: number = 0;
  public static ID_ESTIMATE_WORK: number = 1;
  public static ID_ESTIMATE_SUMMARY: number = 2;

  // 견적 배열 형태
  public static ID_ESTIMATE_ARRAY_NORMAL: number = 0;
  public static ID_ESTIMATE_ARRAY_GROUP: number = 1;

  // 견적 아이템 형태
  public static TYPE_ESTIMATE_NORMAL: number = 0;
  public static TYPE_ESTIMATE_GROUP: number = 1;
  public static TYPE_ESTIMATE_BOTH: number = 2;

  //----------------------------------
  // 수치
  //----------------------------------

  //----------------------------------
  // 그 외
  //----------------------------------

  public static INDEX_ESTIMATE_NORMAL_VIEW: number = 0;
  public static INDEX_ESTIMATE_SUMMARY_VIEW: number = 1;
  public static INDEX_ESTIMATE_WORK_VIEW: number = 2;

  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 부분견적 관련
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레이블
  //----------------------------------

  //----------------------------------
  // 아이디
  //----------------------------------

  //----------------------------------
  // 수치
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 검토 관련
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레이블
  //----------------------------------

  //----------------------------------
  // 아이디
  //----------------------------------

  public static ID_REVIEW_UNFIT: number = 0;
  public static ID_REVIEW_ALL: number = 1;

  //----------------------------------
  // 수치
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 구조해석 관련
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레이블
  //----------------------------------

  //----------------------------------
  // 아이디
  //----------------------------------

  public static ID_ANALYSIS_STRENGTH: number = 0;

  //----------------------------------
  // 수치
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 품목 ITEM
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레이블
  //----------------------------------

  public static LB_ADDED_PRODUCT: string = "추가 상품";

  public static LB_COST_TYPE_MATERIAL: string = "재료비";
  public static LB_COST_TYPE_LABOR: string = "노무비";
  public static LB_COST_TYPE_EXPENSE: string = "경비";

  public static LB_TAX_TYPE_TAXATION: string = "과세";
  public static LB_TAX_TYPE_TAXATION_AND_REFUND: string = "과세+환급";
  public static LB_TAX_TYPE_TAXATION_AND_ZERO_RATE: string = "과세+영세율";
  public static LB_TAX_TYPE_FREE: string = "면세";

  //----------------------------------
  // 아이디
  //----------------------------------

  // 상품 데이터 형태
  public static ID_ITEM_PRODUCT_DATA_TYPE_MATERIAL: number = 5;
  public static ID_ITEM_PRODUCT_DATA_TYPE_LABOR_COST: number = 6;

  // 상품 추가 형태
  public static ID_ITEM_PRODUCT_ADD_TYPE_MATERIAL: number = 1;
  public static ID_ITEM_PRODUCT_ADD_TYPE_CUSTOM_MATERIAL: number = 2;
  public static ID_ITEM_PRODUCT_ADD_TYPE_CUSTOM_EXPENSE: number = 3;
  public static ID_ITEM_PRODUCT_ADD_TYPE_CUSTOM_LABOR_COST: number = 4;

  // 매출가 형태
  public static ID_ITEM_SELLING_TYPE_UNIT: number = 0;
  public static ID_ITEM_SELLING_TYPE_PURCHASE: number = 1;

  //----------------------------------
  // 수치
  //----------------------------------

  //----------------------------------
  // 카테고리 레이블
  //----------------------------------

  // 카테고리1
  public static ITEM_CAT1_NAME_MATERIAL: string = "자재";
  public static ITEM_CAT1_NAME_COST: string = "비용";

  // 카테고리2
  public static ITEM_CAT2_NAME_FRAME_MATERIAL: string = "골조 자재";
  public static ITEM_CAT2_NAME_FRAME_APPENDAGE_MATERIAL: string = "골조 부속 자재";
  public static ITEM_CAT2_NAME_GUTTER_MATERIAL: string = "물받이 자재";
  public static ITEM_CAT2_NAME_SWITCH_MATERIAL: string = "개폐 자재";
  public static ITEM_CAT2_NAME_COVER_MATERIAL: string = "피복 자재";
  public static ITEM_CAT2_NAME_COVER_APPENDAGE_MATERIAL: string = "피복 부속 자재";
  public static ITEM_CAT2_NAME_OTHER_COST: string = "기타 비용";
  public static ITEM_CAT2_NAME_VENT_MATERIAL: string = "환기 자재";
  public static ITEM_CAT2_NAME_CURTAIN_MATERIAL: string = "커튼 자재";
  public static ITEM_CAT2_NAME_WATERING_MATERIAL: string = "관수 자재";
  public static ITEM_CAT2_NAME_DRAINAGE_MATERIAL: string = "배수 자재";
  public static ITEM_CAT2_NAME_CULTIVATION_SUPPORT: string = "재배대 자재";
  public static ITEM_CAT2_NAME_RAIL_MATERIAL: string = "운반 자재";
  public static ITEM_CAT2_NAME_HEATING_COOLING_MATERIAL: string = "냉난방 자재";
  public static ITEM_CAT2_NAME_MISCELLANEOUS_MATERIAL: string = "잡 자재";
  public static ITEM_CAT2_NAME_OTHER_MATERIAL: string = "기타 자재";
  public static ITEM_CAT2_NAME_MATERIAL_DISCOUNT_COST: string = "자재 할인비";
  public static ITEM_CAT2_NAME_OTHER_EXPENSE: string = "기타 경비";
  public static ITEM_CAT2_NAME_OTHER_LABOR_COST: string = "기타 인건비";
  public static ITEM_CAT2_NAME_BUILDING_DISCOUNT_COST: string = "시공 할인비";

  // 카테고리3
  public static ITEM_CAT3_NAME_PIPE: string = "파이프";
  public static ITEM_CAT3_NAME_SUPPORT_PIPE: string = "보조 파이프";
  public static ITEM_CAT3_NAME_STEEL_BAR: string = "철근";

  public static ITEM_CAT3_NAME_CONNECTOR: string = "연결구";
  public static ITEM_CAT3_NAME_HOLDER: string = "고정구";
  public static ITEM_CAT3_NAME_SCONCE: string = "꽂이";
  public static ITEM_CAT3_NAME_DIAMOND_CLAMP: string = "다이아몬드 클램프";
  public static ITEM_CAT3_NAME_DOOR: string = "문짝";
  public static ITEM_CAT3_NAME_DOOR_MADE: string = "문짝(기성품)";
  public static ITEM_CAT3_NAME_HINGED_DOOR: string = "여닫이 문";
  public static ITEM_CAT3_NAME_ROLLER: string = "롤러";
  public static ITEM_CAT3_NAME_CEMENT: string = "시멘트";
  public static ITEM_CAT3_NAME_SCREW: string = "나사";
  public static ITEM_CAT3_NAME_FRAME_OTHER: string = "골조 기타";

  public static ITEM_CAT3_NAME_IRON_PLATE_GUTTER: string = "철판 물받이";
  public static ITEM_CAT3_NAME_PLASTIC_GUTTER: string = "플라스틱 물받이";
  public static ITEM_CAT3_NAME_UBAR: string = "유바";
  public static ITEM_CAT3_NAME_GUTTER_HANGER: string = "물받이 걸이";

  public static ITEM_CAT3_NAME_MANUAL_SWITCHER: string = "수동 개폐기";
  public static ITEM_CAT3_NAME_POWER_SWITCHER: string = "동력 개폐기";
  public static ITEM_CAT3_NAME_SKYLIGHT_MOTOR: string = "천창 모터";
  public static ITEM_CAT3_NAME_SKYLIGHT_SET: string = "천창 세트";
  public static ITEM_CAT3_NAME_ELECTRIC_WIRE: string = "전선";
  public static ITEM_CAT3_NAME_SWITCHER_ASSIST: string = "개폐기 보조";
  public static ITEM_CAT3_NAME_INTEGRATED_CONTROLLER: string = "통합 제어기";
  public static ITEM_CAT3_NAME_CONTROLLER_SENSOR: string = "제어기 센서";
  public static ITEM_CAT3_NAME_SWITCHER_OTHER: string = "개폐기 기타";

  public static ITEM_CAT3_NAME_VINYL: string = "비닐";
  public static ITEM_CAT3_NAME_WEAVING_FILM: string = "직조 필름";
  public static ITEM_CAT3_NAME_SHADENET: string = "차광망";
  public static ITEM_CAT3_NAME_TENT: string = "텐텐지";
  public static ITEM_CAT3_NAME_NONWOVEN: string = "부직포";
  public static ITEM_CAT3_NAME_CASHMILON: string = "카시미론";
  public static ITEM_CAT3_NAME_LAGGING: string = "보온덮개";
  public static ITEM_CAT3_NAME_QUILTING: string = "다겹 커튼지";
  public static ITEM_CAT3_NAME_SCREEN: string = "스크린";
  public static ITEM_CAT3_NAME_MAT: string = "마트";
  public static ITEM_CAT3_NAME_SCREENNET: string = "방충망";

  public static ITEM_CAT3_NAME_PAD: string = "패드";
  public static ITEM_CAT3_NAME_PAD_CONNECTOR: string = "패드 연결구";
  public static ITEM_CAT3_NAME_PAD_SPRING: string = "패드 스프링";
  public static ITEM_CAT3_NAME_PAD_VINYL: string = "패드 비닐";
  public static ITEM_CAT3_NAME_CLIP: string = "클립";
  public static ITEM_CAT3_NAME_WOOD: string = "목재";
  public static ITEM_CAT3_NAME_BANDSTRING: string = "밴드끈";
  public static ITEM_CAT3_NAME_STEEL_WIRE: string = "강선";

  public static ITEM_CAT3_NAME_VENTILATOR: string = "환풍기";
  public static ITEM_CAT3_NAME_VENT_FAN: string = "환기팬";
  public static ITEM_CAT3_NAME_VENT_HOLE: string = "환기구";
  public static ITEM_CAT3_NAME_VENT_HOLE_SWITCH_SET: string = "환기구 개폐 세트";
  public static ITEM_CAT3_NAME_AIR_FLOW_FAN: string = "공기 유동팬";

  public static ITEM_CAT3_NAME_REDUCED_MOTOR: string = "감속 모터";
  public static ITEM_CAT3_NAME_BEARING: string = "베어링";
  public static ITEM_CAT3_NAME_WINDING_DRUM: string = "와인딩 드럼";
  public static ITEM_CAT3_NAME_WIRE_STRAIN: string = "와이어 인장";
  public static ITEM_CAT3_NAME_TOW_CLIP: string = "예인 클립";
  public static ITEM_CAT3_NAME_CURTAIN_OTHER: string = "커튼 기타";

  public static ITEM_CAT3_NAME_NUTRIENT_SOLUTION: string = "양액시스템";
  public static ITEM_CAT3_NAME_WATER_SOLUTION: string = "관비기";
  public static ITEM_CAT3_NAME_WATER_TANK: string = "물탱크";
  public static ITEM_CAT3_NAME_WATER_TANK_FITTING: string = "물탱크 피팅";
  public static ITEM_CAT3_NAME_WATER_TANK_CONTROLLER: string = "물탱크 조절기";
  public static ITEM_CAT3_NAME_WATER_PUMP: string = "펌프";
  public static ITEM_CAT3_NAME_WATER_PUMP_CONNECT: string = "펌프 연결";
  public static ITEM_CAT3_NAME_WATER_FILTER: string = "여과기";
  public static ITEM_CAT3_NAME_WATERING_PIPE: string = "관수관";
  public static ITEM_CAT3_NAME_WATERING_VALVE: string = "관수 밸브";
  public static ITEM_CAT3_NAME_WATERING_CONNECT: string = "관수 연결";
  public static ITEM_CAT3_NAME_WATERING_HANGER: string = "관수 걸이";
  public static ITEM_CAT3_NAME_WATERING_TUBE: string = "관수 튜브";
  public static ITEM_CAT3_NAME_WATERING_TUBE_NIPPLE: string = "관수 튜브 니플";
  public static ITEM_CAT3_NAME_MINI_SPRINKLER: string = "미니 스프링클러";
  public static ITEM_CAT3_NAME_MINI_SPRINKLER_DROP_GUARD: string = "미니 스프링클러 낙수방지기";
  public static ITEM_CAT3_NAME_MINI_SPRINKLER_TUBE_SET: string = "미니 스프링클러 튜브셋";
  public static ITEM_CAT3_NAME_DRIP_PIPE: string = "점적관";
  public static ITEM_CAT3_NAME_DRIP_BUTTON: string = "점적단추";
  public static ITEM_CAT3_NAME_DRIP_BUTTON_APPENDAGE: string = "점적단추 부속";
  public static ITEM_CAT3_NAME_DRIP_BARB: string = "점적 미늘";
  public static ITEM_CAT3_NAME_DRIP_VALVE: string = "점적 밸브";
  public static ITEM_CAT3_NAME_DRIP_CONNECT: string = "점적 연결";
  public static ITEM_CAT3_NAME_FOUNTAIN_HOSE: string = "분수호스";
  public static ITEM_CAT3_NAME_FOUNTAIN_HOSE_CONNECT: string = "분수호스 연결";
  public static ITEM_CAT3_NAME_FOUNTAIN_HOSE_HANGER: string = "분수호스 걸이";
  public static ITEM_CAT3_NAME_WATERING_TOOL: string = "관수 공구";
  public static ITEM_CAT3_NAME_WATERING_OTHER: string = "관수 기타";

  public static ITEM_CAT3_NAME_DRAIN_PIPE: string = "배수관";
  public static ITEM_CAT3_NAME_PVC_VALVE: string = "PVC 밸브";
  public static ITEM_CAT3_NAME_PVC_CONNECTOR: string = "PVC 연결";
  public static ITEM_CAT3_NAME_DRAINSPOUT_BOX: string = "물받이통";
  public static ITEM_CAT3_NAME_DRAIN_PIPE_HANGER: string = "배관 잡이쇠";

  public static ITEM_CAT3_NAME_FORMING_BED: string = "포밍 베드";
  public static ITEM_CAT3_NAME_STYROFOAM_BED: string = "스티로폼 베드";
  public static ITEM_CAT3_NAME_NFT_BED: string = "NFT 베드";
  public static ITEM_CAT3_NAME_BED_APPENDAGE: string = "베드 부속";
  public static ITEM_CAT3_NAME_GROUND_COVER: string = "바닥지";
  public static ITEM_CAT3_NAME_GROUND_COVER_APPENDAGE: string = "바닥지 부속";
  public static ITEM_CAT3_NAME_TRELLIS_STRING_APPENDAGE: string = "유인줄 부속";

  public static ITEM_CAT3_NAME_CARRIER: string = "운반구";
  public static ITEM_CAT3_NAME_GROUND_CARRIER: string = "지면 운반구";
  public static ITEM_CAT3_NAME_RAIL_HOOK: string = "운반 고리";
  public static ITEM_CAT3_NAME_RAIL_INTERVAL: string = "레일 간격유지";

  public static ITEM_CAT3_NAME_BOILER: string = "보일러";
  public static ITEM_CAT3_NAME_HEATING_FAN: string = "열풍기";
  public static ITEM_CAT3_NAME_HEATING_TUBE: string = "히팅튜브";

  public static ITEM_CAT3_NAME_CORD_WIRE: string = "끈/와이어";

  public static ITEM_CAT3_NAME_CUSTOM_MATERIAL: string = "임의 자재";
  public static ITEM_CAT3_NAME_BUILDING_MIS: string = "공과잡비";

  public static ITEM_CAT3_NAME_MATERIAL_DISCOUNT_COST: string = "자재 할인비";

  public static ITEM_CAT3_NAME_CUSTOM_EXPENSE: string = "임의 경비";
  public static ITEM_CAT3_NAME_OTHER_EXPENSE: string = "기타 경비";
  public static ITEM_CAT3_NAME_PIPE_BENDING_EXPENSE: string = "파이프 벤딩비";
  public static ITEM_CAT3_NAME_EQUIPMENT_EXPENSE: string = "장비비";

  public static ITEM_CAT3_NAME_CUSTOM_LABOR_COST: string = "임의 인건비";
  public static ITEM_CAT3_NAME_BUILDING_DISCOUNT_COST: string = "시공 할인비";

  //----------------------------------
  // 카테고리 아이디
  //----------------------------------

  // 카테고리1
  public static ITEM_CAT1_ID_MATERIAL: string = "1";
  public static ITEM_CAT1_ID_COST: string = "6";

  // 카테고리2
  public static ITEM_CAT2_ID_FRAME_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}01`;
  public static ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}02`;
  public static ITEM_CAT2_ID_GUTTER_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}03`;
  public static ITEM_CAT2_ID_SWITCHER_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}04`;
  public static ITEM_CAT2_ID_COVER_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}05`;
  public static ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}06`;
  public static ITEM_CAT2_ID_VENT_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}07`;
  public static ITEM_CAT2_ID_CURTAIN_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}08`;
  public static ITEM_CAT2_ID_WATERING_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}11`;
  public static ITEM_CAT2_ID_DRAINAGE_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}12`;
  public static ITEM_CAT2_ID_CULTIVATION_SUPPORT: string = `${CONST.ITEM_CAT1_ID_MATERIAL}13`;
  public static ITEM_CAT2_ID_RAIL_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}31`;
  public static ITEM_CAT2_ID_HEATING_COOLING_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}41`;
  public static ITEM_CAT2_ID_MISCELLANEOUS_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}81`;
  public static ITEM_CAT2_ID_OTHER_MATERIAL: string = `${CONST.ITEM_CAT1_ID_MATERIAL}99`;
  public static ITEM_CAT2_ID_OTHER_MATERIAL_COST: string = `${CONST.ITEM_CAT1_ID_COST}69`;
  public static ITEM_CAT2_ID_OTHER_EXPENSE: string = `${CONST.ITEM_CAT1_ID_COST}79`;
  public static ITEM_CAT2_ID_OTHER_LABOR_COST: string = `${CONST.ITEM_CAT1_ID_COST}89`;

  // 카테고리3
  public static ITEM_CAT3_ID_PIPE: string = `${CONST.ITEM_CAT2_ID_FRAME_MATERIAL}01`;
  public static ITEM_CAT3_ID_SUPPORT_PIPE: string = `${CONST.ITEM_CAT2_ID_FRAME_MATERIAL}02`;
  public static ITEM_CAT3_ID_STEEL_BAR: string = `${CONST.ITEM_CAT2_ID_FRAME_MATERIAL}11`;

  public static ITEM_CAT3_ID_BRACKET: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}00`;
  public static ITEM_CAT3_ID_CONNECTOR: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}01`;
  public static ITEM_CAT3_ID_HOLDER: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}02`;
  public static ITEM_CAT3_ID_SCONCE: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}04`;
  public static ITEM_CAT3_ID_DIAMOND_CLAMP: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}11`;
  public static ITEM_CAT3_ID_DOOR: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}21`;
  public static ITEM_CAT3_ID_DOOR_MADE: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}22`;
  public static ITEM_CAT3_ID_HINGED_DOOR: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}23`;
  public static ITEM_CAT3_ID_SASH_DOOR: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}24`;
  public static ITEM_CAT3_ID_ROLLER: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}26`;
  public static ITEM_CAT3_ID_CEMENT: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}71`;
  public static ITEM_CAT3_ID_SCREW: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}81`;
  public static ITEM_CAT3_ID_FRAME_OTHER: string = `${CONST.ITEM_CAT2_ID_FRAME_APPENDAGE_MATERIAL}99`;

  public static ITEM_CAT3_ID_IRON_PLATE_GUTTER: string = `${CONST.ITEM_CAT2_ID_GUTTER_MATERIAL}01`;
  public static ITEM_CAT3_ID_PLASTIC_GUTTER: string = `${CONST.ITEM_CAT2_ID_GUTTER_MATERIAL}02`;
  public static ITEM_CAT3_ID_UBAR: string = `${CONST.ITEM_CAT2_ID_GUTTER_MATERIAL}04`;
  public static ITEM_CAT3_ID_GUTTER_HANGER: string = `${CONST.ITEM_CAT2_ID_GUTTER_MATERIAL}05`;

  public static ITEM_CAT3_ID_MANUAL_SWITCHER: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}01`;
  public static ITEM_CAT3_ID_POWER_SWITCHER: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}02`;
  public static ITEM_CAT3_ID_SKYLIGHT_MOTOR: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}03`;
  public static ITEM_CAT3_ID_SKYLIGHT_SET: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}06`;
  public static ITEM_CAT3_ID_ELECTRIC_WIRE: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}04`;
  public static ITEM_CAT3_ID_SWITCHER_ASSIST: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}05`;
  public static ITEM_CAT3_ID_INTEGRATED_CONTROLLER: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}91`;
  public static ITEM_CAT3_ID_CONTROLLER_SENSOR: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}96`;
  public static ITEM_CAT3_ID_SWITCHER_OTHER: string = `${CONST.ITEM_CAT2_ID_SWITCHER_MATERIAL}99`;

  public static ITEM_CAT3_ID_VINYL: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}01`;
  public static ITEM_CAT3_ID_WEAVING_FILM: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}03`;
  public static ITEM_CAT3_ID_SHADENET: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}05`;
  public static ITEM_CAT3_ID_TENT: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}07`;
  public static ITEM_CAT3_ID_NONWOVEN: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}09`;
  public static ITEM_CAT3_ID_CASHMILON: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}31`;
  public static ITEM_CAT3_ID_LAGGING: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}33`;
  public static ITEM_CAT3_ID_QUILTING: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}51`;
  public static ITEM_CAT3_ID_SCREEN: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}53`;
  public static ITEM_CAT3_ID_MAT: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}55`;
  public static ITEM_CAT3_ID_SCREENNET: string = `${CONST.ITEM_CAT2_ID_COVER_MATERIAL}71`;

  public static ITEM_CAT3_ID_PAD: string = `${CONST.ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL}01`;
  public static ITEM_CAT3_ID_PAD_CONNECTOR: string = `${CONST.ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL}02`;
  public static ITEM_CAT3_ID_PAD_SPRING: string = `${CONST.ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL}03`;
  public static ITEM_CAT3_ID_PAD_VINYL: string = `${CONST.ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL}04`;
  public static ITEM_CAT3_ID_CLIP: string = `${CONST.ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL}05`;
  public static ITEM_CAT3_ID_WOOD: string = `${CONST.ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL}06`;
  public static ITEM_CAT3_ID_BANDSTRING: string = `${CONST.ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL}07`;
  public static ITEM_CAT3_ID_STEEL_WIRE: string = `${CONST.ITEM_CAT2_ID_COVER_APPENDAGE_MATERIAL}31`;

  public static ITEM_CAT3_ID_VENTILATOR: string = `${CONST.ITEM_CAT2_ID_VENT_MATERIAL}01`;
  public static ITEM_CAT3_ID_VENT_FAN: string = `${CONST.ITEM_CAT2_ID_VENT_MATERIAL}11`;
  public static ITEM_CAT3_ID_VENT_HOLE: string = `${CONST.ITEM_CAT2_ID_VENT_MATERIAL}21`;
  public static ITEM_CAT3_ID_VENT_HOLE_SWITCH_SET: string = `${CONST.ITEM_CAT2_ID_VENT_MATERIAL}23`;
  public static ITEM_CAT3_ID_AIR_FLOW_FAN: string = `${CONST.ITEM_CAT2_ID_VENT_MATERIAL}31`;

  public static ITEM_CAT3_ID_REDUCED_MOTOR: string = `${CONST.ITEM_CAT2_ID_CURTAIN_MATERIAL}01`;
  public static ITEM_CAT3_ID_BEARING: string = `${CONST.ITEM_CAT2_ID_CURTAIN_MATERIAL}11`;
  public static ITEM_CAT3_ID_DRUM: string = `${CONST.ITEM_CAT2_ID_CURTAIN_MATERIAL}12`;
  public static ITEM_CAT3_ID_WIRE_STRAIN: string = `${CONST.ITEM_CAT2_ID_CURTAIN_MATERIAL}13`;
  public static ITEM_CAT3_ID_TRUSS_CLIP: string = `${CONST.ITEM_CAT2_ID_CURTAIN_MATERIAL}21`;
  public static ITEM_CAT3_ID_TOW_CLIP: string = `${CONST.ITEM_CAT2_ID_CURTAIN_MATERIAL}31`;
  public static ITEM_CAT3_ID_CURTAIN_OTHER: string = `${CONST.ITEM_CAT2_ID_CURTAIN_MATERIAL}99`;

  public static ITEM_CAT3_ID_NUTRIENT_SOLUTION: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}01`;
  public static ITEM_CAT3_ID_WATER_SOLUTION: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}04`;
  public static ITEM_CAT3_ID_WATER_TANK: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}07`;
  public static ITEM_CAT3_ID_WATER_TANK_FITTING: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}09`;
  public static ITEM_CAT3_ID_WATER_TANK_CONTROLLER: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}11`;
  public static ITEM_CAT3_ID_WATER_PUMP: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}16`;
  public static ITEM_CAT3_ID_WATER_PUMP_CONNECT: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}18`;
  public static ITEM_CAT3_ID_WATER_PUMP_HOSE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}20`;
  public static ITEM_CAT3_ID_WATER_PUMP_CONTROLLER: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}22`;
  public static ITEM_CAT3_ID_WATER_FILTER: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}26`;
  public static ITEM_CAT3_ID_WATERING_PIPE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}35`;
  public static ITEM_CAT3_ID_WATERING_VALVE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}38`;
  public static ITEM_CAT3_ID_WATERING_CONNECT: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}40`;
  public static ITEM_CAT3_ID_WATERING_HANGER: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}44`;
  public static ITEM_CAT3_ID_WATERING_TUBE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}46`;
  public static ITEM_CAT3_ID_WATERING_TUBE_NIPPLE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}48`;
  public static ITEM_CAT3_ID_MINI_SPRINKLER: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}51`;
  public static ITEM_CAT3_ID_MINI_SPRINKLER_DROP_GUARD: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}53`;
  public static ITEM_CAT3_ID_MINI_SPRINKLER_TUBE_SET: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}55`;
  public static ITEM_CAT3_ID_DRIP_PIPE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}61`;
  public static ITEM_CAT3_ID_DRIP_BUTTON: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}63`;
  public static ITEM_CAT3_ID_DRIP_BUTTON_APPENDAGE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}65`;
  public static ITEM_CAT3_ID_DRIP_BARB: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}67`;
  public static ITEM_CAT3_ID_DRIP_VALVE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}69`;
  public static ITEM_CAT3_ID_DRIP_CONNECT: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}71`;
  public static ITEM_CAT3_ID_FOUNTAIN_HOSE: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}75`;
  public static ITEM_CAT3_ID_FOUNTAIN_HOSE_CONNECT: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}77`;
  public static ITEM_CAT3_ID_FOUNTAIN_HOSE_HANGER: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}79`;
  public static ITEM_CAT3_ID_WATERING_TOOL: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}91`;
  public static ITEM_CAT3_ID_WATERING_OTHER: string = `${CONST.ITEM_CAT2_ID_WATERING_MATERIAL}99`;

  public static ITEM_CAT3_ID_DRAIN_PIPE: string = `${CONST.ITEM_CAT2_ID_DRAINAGE_MATERIAL}11`;
  public static ITEM_CAT3_ID_PVC_VALVE: string = `${CONST.ITEM_CAT2_ID_DRAINAGE_MATERIAL}14`;
  public static ITEM_CAT3_ID_PVC_CONNECTOR: string = `${CONST.ITEM_CAT2_ID_DRAINAGE_MATERIAL}17`;
  public static ITEM_CAT3_ID_DRAINSPOUT_BOX: string = `${CONST.ITEM_CAT2_ID_DRAINAGE_MATERIAL}41`;
  public static ITEM_CAT3_ID_DRAIN_PIPE_HANGER: string = `${CONST.ITEM_CAT2_ID_DRAINAGE_MATERIAL}44`;

  public static ITEM_CAT3_ID_FORMING_BED: string = `${CONST.ITEM_CAT2_ID_CULTIVATION_SUPPORT}01`;
  public static ITEM_CAT3_ID_STYROFOAM_BED: string = `${CONST.ITEM_CAT2_ID_CULTIVATION_SUPPORT}06`;
  public static ITEM_CAT3_ID_NFT_BED: string = `${CONST.ITEM_CAT2_ID_CULTIVATION_SUPPORT}11`;
  public static ITEM_CAT3_ID_BED_APPENDAGE: string = `${CONST.ITEM_CAT2_ID_CULTIVATION_SUPPORT}21`;
  public static ITEM_CAT3_ID_GROUND_COVER: string = `${CONST.ITEM_CAT2_ID_CULTIVATION_SUPPORT}41`;
  public static ITEM_CAT3_ID_GROUND_COVER_APPENDAGE: string = `${CONST.ITEM_CAT2_ID_CULTIVATION_SUPPORT}46`;
  public static ITEM_CAT3_ID_TRELLIS_STRING_APPENDAGE: string = `${CONST.ITEM_CAT2_ID_CULTIVATION_SUPPORT}56`;

  public static ITEM_CAT3_ID_CARRIER: string = `${CONST.ITEM_CAT2_ID_RAIL_MATERIAL}01`;
  public static ITEM_CAT3_ID_GROUND_CARRIER: string = `${CONST.ITEM_CAT2_ID_RAIL_MATERIAL}03`;
  public static ITEM_CAT3_ID_RAIL_HOOK: string = `${CONST.ITEM_CAT2_ID_RAIL_MATERIAL}11`;
  public static ITEM_CAT3_ID_RAIL_INTERVAL: string = `${CONST.ITEM_CAT2_ID_RAIL_MATERIAL}31`;

  public static ITEM_CAT3_ID_BOILER: string = `${CONST.ITEM_CAT2_ID_HEATING_COOLING_MATERIAL}01`;
  public static ITEM_CAT3_ID_HEATING_FAN: string = `${CONST.ITEM_CAT2_ID_HEATING_COOLING_MATERIAL}51`;
  public static ITEM_CAT3_ID_HEATING_TUBE: string = `${CONST.ITEM_CAT2_ID_HEATING_COOLING_MATERIAL}61`;

  public static ITEM_CAT3_ID_CORD_WIRE: string = `${CONST.ITEM_CAT2_ID_MISCELLANEOUS_MATERIAL}01`;

  public static ITEM_CAT3_ID_CUSTOM_MATERIAL: string = `${CONST.ITEM_CAT2_ID_OTHER_MATERIAL}01`;
  public static ITEM_CAT3_ID_BUILDING_MIS: string = `${CONST.ITEM_CAT2_ID_OTHER_MATERIAL}91`;

  public static ITEM_CAT3_ID_MATERIAL_DISCOUNT_COST: string = `${CONST.ITEM_CAT2_ID_OTHER_MATERIAL_COST}11`;

  public static ITEM_CAT3_ID_CUSTOM_EXPENSE: string = `${CONST.ITEM_CAT2_ID_OTHER_EXPENSE}01`;
  public static ITEM_CAT3_ID_OTHER_EXPENSE: string = `${CONST.ITEM_CAT2_ID_OTHER_EXPENSE}11`;
  public static ITEM_CAT3_ID_PIPE_BENDING_EXPENSE: string = `${CONST.ITEM_CAT2_ID_OTHER_EXPENSE}21`;
  public static ITEM_CAT3_ID_EQUIPMENT_EXPENSE: string = `${CONST.ITEM_CAT2_ID_OTHER_EXPENSE}31`;

  public static ITEM_CAT3_ID_CUSTOM_LABOR_COST: string = `${CONST.ITEM_CAT2_ID_OTHER_LABOR_COST}01`;
  public static ITEM_CAT3_ID_BUILDING_DISCOUNT_COST: string = `${CONST.ITEM_CAT2_ID_OTHER_LABOR_COST}91`;

  //----------------------------------
  // 상품명
  //----------------------------------

  //
  public static ITEM_NAME_PIPE: string = "파이프";
  public static ITEM_NAME_C_SECTION_STEEL: string = "C형강";
  public static ITEM_NAME_GUTTER_BEAM: string = "물받이거터 보";
  public static ITEM_NAME_ROUND_BAR: string = "환봉";

  public static ITEM_NAME_MIDDLE_SUPPORT_PIPE: string = "중방 받침대";
  public static ITEM_NAME_U_BENDING_TUBE_RAIL: string = "U벤딩 튜브레일";

  public static ITEM_NAME_STEEL_BAR: string = "철근";

  //
  public static ITEM_NAME_BRACKET: string = "브라켓트";

  public static ITEM_NAME_CONNECTION_PIN: string = "연결핀";

  public static ITEM_NAME_NORMAL_HOLDER: string = "일반 고정구";
  public static ITEM_NAME_STEEL_STRING_GRABBER: string = "강선 조리개";
  public static ITEM_NAME_STEEL_SHEET_GRABBER: string = "강판 조리개";
  public static ITEM_NAME_CROSS_STEEL_SHEET_GRABBER: string = "십자 강판 조리개";
  public static ITEM_NAME_LINE_SHEET_GRABBER: string = "선판 조리개";
  public static ITEM_NAME_U_CLAMP: string = "U 클램프";
  public static ITEM_NAME_SADDLE_HOLDER: string = "새들 고정구";
  public static ITEM_NAME_FIX_ASIBA_CLAMP: string = "고정 아시바 클램프";
  public static ITEM_NAME_ROTATION_ASIBA_CLAMP: string = "회전 아시바 클램프";
  public static ITEM_NAME_CROSS_CLAMP: string = "십자 클램프";
  public static ITEM_NAME_OUTER_WALL_CLAMP: string = "외벽 클램프";
  public static ITEM_NAME_T_HOLDER: string = "T 고정구";
  public static ITEM_NAME_T_CLAMP: string = "T 클램프";
  public static ITEM_NAME_DIAGONAL_T_HOLDER: string = "대각T 고정구";
  public static ITEM_NAME_DIAGONAL_T_CLAMP: string = "대각T 클램프";
  public static ITEM_NAME_GUTTER_BEAM_HOLDER: string = "물받이거터 보 고정구";
  public static ITEM_NAME_MIDDLE_PAIR_CLAMP: string = "중방쌍반도";
  public static ITEM_NAME_MIDDLE_SOLE_CLAMP: string = "중방외반도";
  public static ITEM_NAME_MIDDLE_Y_CLAMP: string = "중방Y 클램프";
  public static ITEM_NAME_MIDDLE_HALF_Y_CLAMP: string = "중방반Y 클램프";
  public static ITEM_NAME_HOOK_HOLDER: string = "고리 고정구";

  public static ITEM_NAME_INNER_T_HOLDER: string = "속T 고정구";
  public static ITEM_NAME_FIRST_LEVEL_SOLE_SCONCE: string = "일중 외꽂이";
  public static ITEM_NAME_FIRST_LEVEL_PAIR_SCONCE: string = "일중 쌍꽂이";
  public static ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE: string = "이중 외꽂이";
  public static ITEM_NAME_SECOND_LEVEL_PAIR_SCONCE: string = "이중 쌍꽂이";
  public static ITEM_NAME_CONNECTION_SKYLIGHT_SET: string = "연결창 세트";
  public static ITEM_NAME_ARCH_SCONCE: string = "아치 꽂이";

  public static ITEM_NAME_DIAMOND_CLAMP: string = "다이아몬드 클램프";

  public static ITEM_NAME_DOOR: string = "문짝";

  public static ITEM_NAME_DOOR_MADE: string = "문짝(기성품)";

  public static ITEM_NAME_HINGED_DOOR: string = "여닫이 문";

  public static ITEM_NAME_SASH_DOOR: string = "샷시창문";

  public static ITEM_NAME_C_SECTION_STEEL_ROLLER: string = "C형강 롤러";

  public static ITEM_NAME_REMICON: string = "레미콘";

  public static ITEM_NAME_SCREW: string = "나사";

  public static ITEM_NAME_BOLT_AND_NUT: string = "볼트&너트";
  public static ITEM_NAME_HINGE: string = "경첩";
  public static ITEM_NAME_KNOB: string = "손잡이";
  public static ITEM_NAME_STAKE: string = "말뚝";
  public static ITEM_NAME_LONG_BOLT: string = "장볼트";
  public static ITEM_NAME_TACKER_PIN: string = "타카핀";
  public static ITEM_NAME_FILM_BAND: string = "필름 밴드";
  public static ITEM_NAME_WIRE_MESH: string = "와이어 메쉬";
  public static ITEM_NAME_TURNBUCKLE: string = "턴버클";

  //
  public static ITEM_NAME_IRON_PLATE_GUTTER: string = "철판 물받이";

  public static ITEM_NAME_PLASTIC_GUTTER: string = "플라스틱 물받이";

  public static ITEM_NAME_UBAR: string = "유바";

  public static ITEM_NAME_GUTTER_HANGER: string = "물받이 걸이";

  //
  public static ITEM_NAME_MANUAL_SWITCHER: string = "수동 개폐기";

  public static ITEM_NAME_POWER_SWITCHER: string = "동력 개폐기";

  public static ITEM_NAME_SKYLIGHT_MOTOR: string = "천창 모터";

  public static ITEM_NAME_RACKPINION_SET: string = "랙피니언 세트";
  public static ITEM_NAME_VENTPIPEPAD_SET: string = "환기창파이프패드 세트";

  public static ITEM_NAME_ELECTRIC_WIRE: string = "전선";

  public static ITEM_NAME_AXIS_ROLLER: string = "축 롤러";
  public static ITEM_NAME_AXIS_COUPLING: string = "축 커플링";
  public static ITEM_NAME_SUPPORT_COUPLING: string = "지지 커플링";
  public static ITEM_NAME_SUPPORT_FIXING_PIN: string = "지지 고정핀";
  public static ITEM_NAME_LONG_BOLT_HOLDER: string = "장볼트 고정구";

  public static ITEM_NAME_INTEGRATED_CONTROLLER: string = "통합 제어기";

  public static ITEM_NAME_CONTROLLER_TIMER: string = "제어기-타이머";
  public static ITEM_NAME_CONTROLLER_TEMP_SENSOR: string = "제어기-온도센서";
  public static ITEM_NAME_CONTROLLER_HEAT_SENSOR: string = "제어기-고온경보기";
  public static ITEM_NAME_CONTROLLER_TEMP_HUM_SENSOR: string = "제어기-습도센서";
  public static ITEM_NAME_CONTROLLER_RAINDROP_SENSOR: string = "제어기-우적센서";
  public static ITEM_NAME_CONTROLLER_WIND_SENSOR: string = "제어기-풍량센서";
  public static ITEM_NAME_CONTROLLER_INSOLATION_SENSOR: string = "제어기-일사량센서";

  public static ITEM_NAME_SWITCHER_PROTECTION_RUBBER: string = "개폐기 보호고무";
  public static ITEM_NAME_RUBBER_BAR: string = "고무바";

  //
  public static ITEM_NAME_VINYL: string = "비닐";

  public static ITEM_NAME_WEAVING_FILM: string = "직조 필름";

  public static ITEM_NAME_SHADENET: string = "차광망";

  public static ITEM_NAME_TENT: string = "텐텐지";

  public static ITEM_NAME_NONWOVEN: string = "부직포";

  public static ITEM_NAME_CASHMILON: string = "카시미론";

  public static ITEM_NAME_COLOR_LAGGING: string = "칼라 보온덮개";
  public static ITEM_NAME_LAGGING: string = "보온덮개";

  public static ITEM_NAME_QUILTING: string = "다겹 커튼지";

  public static ITEM_NAME_SCREEN: string = "스크린";

  public static ITEM_NAME_MAT: string = "마트";

  public static ITEM_NAME_SCREENNET: string = "방충망";

  //
  public static ITEM_NAME_NORMAL_PAD: string = "일반 패드";
  public static ITEM_NAME_LAGGING_PAD: string = "광폭 패드";
  public static ITEM_NAME_GUTTER_PAD: string = "물받이 패드";
  public static ITEM_NAME_CONDENSATION_PAD: string = "결로 패드";
  public static ITEM_NAME_BAR_PAD: string = "간살 패드";

  public static ITEM_NAME_PAD_CONNECTION_PIN: string = "패드 연결핀";
  public static ITEM_NAME_CONDENSATION_PAD_CONNECTION_PIN: string = "결로 패드 연결핀";

  public static ITEM_NAME_NORMAL_PAD_SPRING: string = "일반 패드 스프링";
  public static ITEM_NAME_LAGGING_PAD_SPRING: string = "광폭 패드 스프링";

  public static ITEM_NAME_PAD_VINYL: string = "패드 비닐";

  public static ITEM_NAME_SKIRT_CLIP: string = "치마 클립";
  public static ITEM_NAME_NORMAL_CLIP: string = "일반 클립";
  public static ITEM_NAME_HANGER_CLIP: string = "걸고리 클립";
  public static ITEM_NAME_SLOPE_CLIP: string = "기울기 클립";

  public static ITEM_NAME_RECT_LUMBER: string = "각재";

  public static ITEM_NAME_NORMAL_BANDSTRING: string = "일반 밴드끈";
  public static ITEM_NAME_POLY_BANDSTRING: string = "폴리 밴드끈";
  public static ITEM_NAME_VINYL_BAND: string = "비닐 밴드";
  public static ITEM_NAME_NET_BAND: string = "망 밴드";
  public static ITEM_NAME_WEAVING_BAND: string = "직조 밴드";

  public static ITEM_NAME_STEEL_STRING_WIRE: string = "강선줄";

  //
  public static ITEM_NAME_VENTILATOR: string = "배기팬";

  public static ITEM_NAME_VENT_FAN: string = "환기팬(지붕)";
  public static ITEM_NAME_VENT_HOLE: string = "환기구(지붕)";

  public static ITEM_NAME_VENT_HOLE_SWITCH_SET: string = "환기구 개폐 세트";

  public static ITEM_NAME_AIR_FLOW_FAN: string = "공기 유동팬";

  //
  public static ITEM_NAME_REDUCED_MOTOR: string = "감속 모터";

  public static ITEM_NAME_BEARING_SET: string = "축수 베어링 세트";

  public static ITEM_NAME_WINDING_DRUM: string = "와인딩 드럼";

  public static ITEM_NAME_WIRE_STRAINER: string = "와이어 인장기";

  public static ITEM_NAME_TRUSS_CLIP: string = "트러스 클립";

  public static ITEM_NAME_STICK_TOW_CLIP: string = "막대 예인 클립";
  public static ITEM_NAME_HOOK_TOW_CLIP: string = "고리 예인 클립";

  public static ITEM_NAME_TOW_ROLLER: string = "예인 롤러";
  public static ITEM_NAME_GUIDE_ROLLER: string = "가이드 롤러";
  public static ITEM_NAME_ROLLER_HOOK: string = "롤러 고리";
  public static ITEM_NAME_SCREEN_PINCER: string = "스크린 집게";

  //
  public static ITEM_NAME_NUTRIENT_SOLUTION: string = "양액시스템";

  public static ITEM_NAME_WATER_SOLUTION: string = "관비기";

  public static ITEM_NAME_WATER_TANK: string = "물탱크";

  public static ITEM_NAME_WATER_TANK_FITTING: string = "물탱크 피팅";

  public static ITEM_NAME_NUTRIENT_MIXER: string = "양액 교반기";
  public static ITEM_NAME_WATER_LEVEL_CONTROLLER: string = "수위 조절기";

  public static ITEM_NAME_WATER_PUMP: string = "펌프";

  public static ITEM_NAME_WATER_PUMP_CONNECT: string = "펌프 연결";

  public static ITEM_NAME_FLEXIBLE_HOSE: string = "플렉시블 호스";

  public static ITEM_NAME_WATER_PUMP_CONTROLLER: string = "펌프 조절기";

  public static ITEM_NAME_WATER_FILTER: string = "여과기";

  public static ITEM_NAME_PE_PIPE: string = "PE관";

  public static ITEM_NAME_ELECTRIC_VALVE: string = "전자 밸브";
  public static ITEM_NAME_UNION_VALVE: string = "유니온 밸브";
  public static ITEM_NAME_FEMALE_BALL_VALVE: string = "암나사 볼 밸브";
  public static ITEM_NAME_MALE_BALL_VALVE: string = "숫나사 볼 밸브";
  public static ITEM_NAME_FEMALE_MALE_BALL_VALVE: string = "암숫나사 볼 밸브";
  public static ITEM_NAME_FEMALE_MALE_VALVE: string = "암숫나사 밸브";

  public static ITEM_NAME_NIPPLE: string = "니플";
  public static ITEM_NAME_SOCKET_FEMALE: string = "소켓(암나사)";
  public static ITEM_NAME_DIFFERENT_SOCKET_FEMALE: string = "이경 소켓(암나사)";
  public static ITEM_NAME_CONNECT_SOCKET: string = "연결 소켓";
  public static ITEM_NAME_DIFFERENT_CONNECT_SOCKET: string = "이경 연결 소켓";
  public static ITEM_NAME_ELBOW: string = "엘보";
  public static ITEM_NAME_MALE_ELBOW_SOCKET: string = "숫나사 엘보 소켓";
  public static ITEM_NAME_FEMALE_ELBOW_SOCKET: string = "암나사 엘보 소켓";
  public static ITEM_NAME_EXACT_T: string = "정티";
  public static ITEM_NAME_DIFFERENT_T: string = "이경티";
  public static ITEM_NAME_BUSHING: string = "부싱";
  public static ITEM_NAME_VALVE_SOCKET: string = "밸브 소켓";
  public static ITEM_NAME_PE_PIPE_END: string = "관 마감";
  public static ITEM_NAME_WATER_SCREEN_CONNECT_SOCKET: string = "수막 연결 소켓";
  public static ITEM_NAME_WATER_SCREEN_ELBOW_SOCKET: string = "수막 엘보 소켓";
  public static ITEM_NAME_PE_PIPE_SADDLE: string = "관 새들";

  public static ITEM_NAME_PE_PIPE_HANGER: string = "관 걸이";

  public static ITEM_NAME_TUBE: string = "튜브";

  public static ITEM_NAME_TUBE_NIPPLE: string = "튜브 니플";

  public static ITEM_NAME_MINI_SPRINKLER: string = "미니 스프링클러";

  public static ITEM_NAME_MINI_SPRINKLER_DROP_GUARD: string = "미니클러 낙수방지기";

  public static ITEM_NAME_MINI_SPRINKLER_TUBE_SET: string = "미니클러 튜브셋";

  public static ITEM_NAME_DRIP_PIPE: string = "점적관";

  public static ITEM_NAME_DRIP_BUTTON: string = "점적단추";

  public static ITEM_NAME_DRIP_BUTTON_MANY_HOLD: string = "점적단추 매니홀드";
  public static ITEM_NAME_DRIP_BUTTON_DROPPER: string = "점적단추 드롭퍼";

  public static ITEM_NAME_DRIP_BARB: string = "점적 미늘";
  public static ITEM_NAME_DRIP_START_SADDLE: string = "점적 스타트 새들";

  public static ITEM_NAME_DRIP_LINE_VALVE: string = "점적 라인 밸브";
  public static ITEM_NAME_DRIP_START_LINE_VALVE: string = "점적 스타트 라인 밸브";
  public static ITEM_NAME_DRIP_LINE_VALVE_SUPPORT: string = "점적 라인 밸브 지주대";

  public static ITEM_NAME_DRIP_CONNECT_SOCKET: string = "점적 연결 소켓";
  public static ITEM_NAME_DRIP_SUPPORT_END: string = "점적 지주 마감";
  public static ITEM_NAME_LD_CONNECT: string = "엘디 연결";
  public static ITEM_NAME_LD_ELBOW: string = "엘디 엘보";
  public static ITEM_NAME_LD_EXACT_T: string = "엘디 정T";
  public static ITEM_NAME_LD_END: string = "엘디 마감";

  public static ITEM_NAME_FOUNTAIN_HOSE: string = "분수호스";

  public static ITEM_NAME_FOUNTAIN_HOSE_CONNECT: string = "분수호스 연결";
  public static ITEM_NAME_FOUNTAIN_HOSE_CROSS_CONNECT: string = "분수호스 십자 연결";
  public static ITEM_NAME_FOUNTAIN_HOSE_END: string = "분수호스 마감";
  public static ITEM_NAME_FOUNTAIN_HOSE_T_VALVE: string = "분수호스 티 밸브";

  public static ITEM_NAME_FOUNTAIN_HOSE_HANGER_SCREEN: string = "분수호스 수막 걸이";
  public static ITEM_NAME_FOUNTAIN_HOSE_HANGER_UPPER: string = "분수호스 고설 걸이";
  public static ITEM_NAME_FOUNTAIN_HOSE_HANGER_SIDE: string = "분수호스 측설 걸이";

  public static ITEM_NAME_PE_PIPE_FASTENER: string = "관 조임구";
  public static ITEM_NAME_PE_PIPE_PUNCHER: string = "관 펀치기";

  public static ITEM_NAME_DRIP_ALIGNMENT_PIN: string = "점적 고정핀";
  public static ITEM_NAME_WATERING_SPRING: string = "관수 스프링";

  //
  public static ITEM_NAME_THP_PIPE: string = "THP관";
  public static ITEM_NAME_PVC_PIPE: string = "PVC관";

  public static ITEM_NAME_PVC_FEMALE_BALL_VALVE: string = "PVC 암나사 볼 밸브";

  public static ITEM_NAME_PVC_SOCKET: string = "PVC 소켓";
  public static ITEM_NAME_PVC_ELBOW: string = "PVC 엘보";
  public static ITEM_NAME_PVC_DEGREE45_ELBOW: string = "PVC 45도 엘보";
  public static ITEM_NAME_PVC_EXACT_T: string = "PVC 정티";
  public static ITEM_NAME_PVC_DIFFERENT_T: string = "PVC 이경티";
  public static ITEM_NAME_PVC_VALVE_SOCKET: string = "PVC 밸브 소켓";
  public static ITEM_NAME_PVC_END: string = "PVC 소제구";

  public static ITEM_NAME_DRAINSPOUT_BOX: string = "물받이통";

  public static ITEM_NAME_DRAIN_PIPE_HANGER: string = "배관 잡이쇠";

  //
  public static ITEM_NAME_FORMING_BED: string = "포밍 베드";

  public static ITEM_NAME_STYROFOAM_BED: string = "스티로폼 베드";

  public static ITEM_NAME_NFT_BED: string = "NFT 베드";

  public static ITEM_NAME_BED_END: string = "베드 마감";
  public static ITEM_NAME_BED_DRAIN: string = "베드 배수구";
  public static ITEM_NAME_BED_SUPPORT_CLIP: string = "베드 보강클립";
  public static ITEM_NAME_BED_HOLDER: string = "베드 고정구";
  public static ITEM_NAME_NFT_SUPPLY_DRAIN_SET: string = "NFT 급/배수구 세트";
  public static ITEM_NAME_SINK_GUARD_PLATE: string = "침하방지판";

  public static ITEM_NAME_GROUND_COVER: string = "바닥지";

  public static ITEM_NAME_SACK_PIN: string = "마대핀";

  public static ITEM_NAME_TRELLIS_HANGER: string = "유인줄 걸이";
  public static ITEM_NAME_TRELLIS_ROUND_BAR: string = "유인줄 환봉";

  //
  public static ITEM_NAME_UPPER_CARRIER: string = "상단 운반구";
  public static ITEM_NAME_SIDE_CARRIER: string = "측면 운반구";

  public static ITEM_NAME_GROUND_CARRIER: string = "지면 운반구";

  public static ITEM_NAME_RAIL_HOOK: string = "운반 고리";
  public static ITEM_NAME_SECOND_LEVEL_RAIL_HOOK: string = "이중 운반 고리";
  public static ITEM_NAME_SIDE_RAIL_HOOK: string = "측면 운반 고리";

  public static ITEM_NAME_RAIL_INTERVAL: string = "레일 간격유지";
  public static ITEM_NAME_RAIL_SUPPORT_PIPE: string = "레일 받침대";

  //
  public static ITEM_NAME_BOILER: string = "보일러";

  public static ITEM_NAME_HEATING_FAN: string = "열풍기";

  public static ITEM_NAME_HEATING_TUBE: string = "히팅튜브";

  //
  public static ITEM_NAME_CORD: string = "끈";
  public static ITEM_NAME_RIPCORD: string = "낙하산 줄";
  public static ITEM_NAME_COATING_WIRE: string = "코팅 와이어";

  //
  public static ITEM_NAME_CUSTOM_MATERIAL: string = "(임의 자재)";
  public static ITEM_NAME_CUSTOM_CONTROLLER: string = "(임의 제어기)";

  public static ITEM_NAME_BUILDING_MIS: string = "공과잡비";

  //
  public static ITEM_NAME_OVERLAP_MATERIAL_SAVING: string = "중복 자재 절약";
  public static ITEM_NAME_MATERIAL_DISCOUNT: string = "자재 할인";

  //
  public static ITEM_NAME_CUSTOM_EXPENSE: string = "(임의 경비)";

  public static ITEM_NAME_WELDING: string = "용접비";

  public static ITEM_NAME_PIPE_BENDING_EXPENSE: string = "파이프 벤딩비";

  public static ITEM_NAME_EQUIPMENT_EXPENSE: string = "장비비";

  //
  public static ITEM_NAME_CUSTOM_LABOR_COST: string = "(임의 인건비)";

  public static ITEM_NAME_BUILDING_DISCOUNT: string = "시공 할인";

  //----------------------------------
  // 상품 아이디
  //----------------------------------

  //
  public static ITEM_ID_PIPE: string = `${CONST.ITEM_CAT3_ID_PIPE}001`;
  public static ITEM_ID_C_SECTION_STEEL: string = `${CONST.ITEM_CAT3_ID_PIPE}011`;
  public static ITEM_ID_GUTTER_BEAM: string = `${CONST.ITEM_CAT3_ID_PIPE}031`;
  public static ITEM_ID_ROUND_BAR: string = `${CONST.ITEM_CAT3_ID_PIPE}051`;

  public static ITEM_ID_MIDDLE_SUPPORT_PIPE: string = `${CONST.ITEM_CAT3_ID_SUPPORT_PIPE}001`;
  public static ITEM_ID_U_BENDING_TUBE_RAIL: string = `${CONST.ITEM_CAT3_ID_SUPPORT_PIPE}041`;

  public static ITEM_ID_STEEL_BAR: string = `${CONST.ITEM_CAT3_ID_STEEL_BAR}001`;

  //
  public static ITEM_ID_BRACKET: string = `${CONST.ITEM_CAT3_ID_BRACKET}001`;

  public static ITEM_ID_CONNECTION_PIN: string = `${CONST.ITEM_CAT3_ID_CONNECTOR}001`;

  public static ITEM_ID_NORMAL_HOLDER: string = `${CONST.ITEM_CAT3_ID_HOLDER}001`;
  public static ITEM_ID_STEEL_STRING_GRABBER: string = `${CONST.ITEM_CAT3_ID_HOLDER}005`;
  public static ITEM_ID_STEEL_SHEET_GRABBER: string = `${CONST.ITEM_CAT3_ID_HOLDER}007`;
  public static ITEM_ID_CROSS_STEEL_SHEET_GRABBER: string = `${CONST.ITEM_CAT3_ID_HOLDER}009`;
  public static ITEM_ID_LINE_SHEET_GRABBER: string = `${CONST.ITEM_CAT3_ID_HOLDER}011`;
  public static ITEM_ID_U_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}015`;
  public static ITEM_ID_SADDLE_HOLDER: string = `${CONST.ITEM_CAT3_ID_HOLDER}021`;
  public static ITEM_ID_FIX_ASIBA_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}023`;
  public static ITEM_ID_ROTATION_ASIBA_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}025`;
  public static ITEM_ID_CROSS_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}031`;
  public static ITEM_ID_OUTER_WALL_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}035`;
  public static ITEM_ID_T_HOLDER: string = `${CONST.ITEM_CAT3_ID_HOLDER}041`;
  public static ITEM_ID_T_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}043`;
  public static ITEM_ID_DIAGONAL_T_HOLDER: string = `${CONST.ITEM_CAT3_ID_HOLDER}045`;
  public static ITEM_ID_DIAGONAL_T_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}047`;
  public static ITEM_ID_GUTTER_BEAM_HOLDER: string = `${CONST.ITEM_CAT3_ID_HOLDER}051`;
  public static ITEM_ID_MIDDLE_PAIR_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}061`;
  public static ITEM_ID_MIDDLE_SOLE_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}063`;
  public static ITEM_ID_MIDDLE_Y_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}065`;
  public static ITEM_ID_MIDDLE_HALF_Y_CLAMP: string = `${CONST.ITEM_CAT3_ID_HOLDER}067`;
  public static ITEM_ID_HOOK_HOLDER: string = `${CONST.ITEM_CAT3_ID_HOLDER}081`;

  public static ITEM_ID_INNER_T_HOLDER: string = `${CONST.ITEM_CAT3_ID_SCONCE}000`;
  public static ITEM_ID_FIRST_LEVEL_SOLE_SCONCE: string = `${CONST.ITEM_CAT3_ID_SCONCE}001`;
  public static ITEM_ID_FIRST_LEVEL_PAIR_SCONCE: string = `${CONST.ITEM_CAT3_ID_SCONCE}002`;
  public static ITEM_ID_SECOND_LEVEL_SOLE_SCONCE: string = `${CONST.ITEM_CAT3_ID_SCONCE}003`;
  public static ITEM_ID_SECOND_LEVEL_PAIR_SCONCE: string = `${CONST.ITEM_CAT3_ID_SCONCE}004`;
  public static ITEM_ID_CONNECTION_SKYLIGHT_SET: string = `${CONST.ITEM_CAT3_ID_SCONCE}005`;
  public static ITEM_ID_ARCH_SCONCE: string = `${CONST.ITEM_CAT3_ID_SCONCE}006`;

  public static ITEM_ID_DIAMOND_CLAMP: string = `${CONST.ITEM_CAT3_ID_DIAMOND_CLAMP}001`;

  public static ITEM_ID_DOOR: string = `${CONST.ITEM_CAT3_ID_DOOR}001`;

  public static ITEM_ID_DOOR_MADE: string = `${CONST.ITEM_CAT3_ID_DOOR_MADE}001`;

  public static ITEM_ID_HINGED_DOOR: string = `${CONST.ITEM_CAT3_ID_HINGED_DOOR}001`;

  public static ITEM_ID_SASH_DOOR: string = `${CONST.ITEM_CAT3_ID_SASH_DOOR}001`;

  public static ITEM_ID_C_SECTION_STEEL_ROLLER: string = `${CONST.ITEM_CAT3_ID_ROLLER}001`;

  public static ITEM_ID_REMICON: string = `${CONST.ITEM_CAT3_ID_CEMENT}001`;

  public static ITEM_ID_SCREW: string = `${CONST.ITEM_CAT3_ID_SCREW}001`;

  public static ITEM_ID_BOLT_AND_NUT: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}001`;
  public static ITEM_ID_HINGE: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}002`;
  public static ITEM_ID_KNOB: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}004`;
  public static ITEM_ID_STAKE: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}005`;
  public static ITEM_ID_LONG_BOLT: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}006`;
  public static ITEM_ID_TACKER_PIN: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}007`;
  public static ITEM_ID_FILM_BAND: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}008`;
  public static ITEM_ID_WIRE_MESH: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}009`;
  public static ITEM_ID_TURNBUCKLE: string = `${CONST.ITEM_CAT3_ID_FRAME_OTHER}010`;

  //
  public static ITEM_ID_IRON_PLATE_GUTTER: string = `${CONST.ITEM_CAT3_ID_IRON_PLATE_GUTTER}001`;

  public static ITEM_ID_PLASTIC_GUTTER: string = `${CONST.ITEM_CAT3_ID_PLASTIC_GUTTER}001`;

  public static ITEM_ID_UBAR: string = `${CONST.ITEM_CAT3_ID_UBAR}001`;

  public static ITEM_ID_GUTTER_HANGER: string = `${CONST.ITEM_CAT3_ID_GUTTER_HANGER}001`;

  //
  public static ITEM_ID_MANUAL_SWITCHER: string = `${CONST.ITEM_CAT3_ID_MANUAL_SWITCHER}001`;

  public static ITEM_ID_POWER_SWITCHER: string = `${CONST.ITEM_CAT3_ID_POWER_SWITCHER}001`;

  public static ITEM_ID_SKYLIGHT_MOTOR: string = `${CONST.ITEM_CAT3_ID_SKYLIGHT_MOTOR}001`;

  public static ITEM_ID_RACKPINION_SET: string = `${CONST.ITEM_CAT3_ID_SKYLIGHT_SET}001`;
  public static ITEM_ID_VENTPIPEPAD_SET: string = `${CONST.ITEM_CAT3_ID_SKYLIGHT_SET}011`;

  public static ITEM_ID_ELECTRIC_WIRE: string = `${CONST.ITEM_CAT3_ID_ELECTRIC_WIRE}001`;

  public static ITEM_ID_AXIS_ROLLER: string = `${CONST.ITEM_CAT3_ID_SWITCHER_ASSIST}001`;
  public static ITEM_ID_AXIS_COUPLING: string = `${CONST.ITEM_CAT3_ID_SWITCHER_ASSIST}002`;
  public static ITEM_ID_SUPPORT_COUPLING: string = `${CONST.ITEM_CAT3_ID_SWITCHER_ASSIST}003`;
  public static ITEM_ID_SUPPORT_FIXING_PIN: string = `${CONST.ITEM_CAT3_ID_SWITCHER_ASSIST}004`;
  public static ITEM_ID_LONG_BOLT_HOLDER: string = `${CONST.ITEM_CAT3_ID_SWITCHER_ASSIST}005`;

  public static ITEM_ID_INTEGRATED_CONTROLLER: string = `${CONST.ITEM_CAT3_ID_INTEGRATED_CONTROLLER}001`;

  public static ITEM_ID_CONTROLLER_TIMER: string = `${CONST.ITEM_CAT3_ID_CONTROLLER_SENSOR}001`;
  public static ITEM_ID_CONTROLLER_TEMP_SENSOR: string = `${CONST.ITEM_CAT3_ID_CONTROLLER_SENSOR}011`;
  public static ITEM_ID_CONTROLLER_HEAT_SENSOR: string = `${CONST.ITEM_CAT3_ID_CONTROLLER_SENSOR}021`;
  public static ITEM_ID_CONTROLLER_TEMP_HUM_SENSOR: string = `${CONST.ITEM_CAT3_ID_CONTROLLER_SENSOR}031`;
  public static ITEM_ID_CONTROLLER_RAINDROP_SENSOR: string = `${CONST.ITEM_CAT3_ID_CONTROLLER_SENSOR}041`;
  public static ITEM_ID_CONTROLLER_WIND_SENSOR: string = `${CONST.ITEM_CAT3_ID_CONTROLLER_SENSOR}051`;
  public static ITEM_ID_CONTROLLER_INSOLATION_SENSOR: string = `${CONST.ITEM_CAT3_ID_CONTROLLER_SENSOR}061`;

  public static ITEM_ID_SWITCHER_PROTECTION_RUBBER: string = `${CONST.ITEM_CAT3_ID_SWITCHER_OTHER}001`;
  public static ITEM_ID_RUBBER_BAR: string = `${CONST.ITEM_CAT3_ID_SWITCHER_OTHER}002`;

  //
  public static ITEM_ID_VINYL: string = `${CONST.ITEM_CAT3_ID_VINYL}001`;

  public static ITEM_ID_WEAVING_FILM: string = `${CONST.ITEM_CAT3_ID_WEAVING_FILM}001`;

  public static ITEM_ID_SHADENET: string = `${CONST.ITEM_CAT3_ID_SHADENET}001`;

  public static ITEM_ID_TENT: string = `${CONST.ITEM_CAT3_ID_TENT}001`;

  public static ITEM_ID_NONWOVEN: string = `${CONST.ITEM_CAT3_ID_NONWOVEN}001`;

  public static ITEM_ID_CASHMILON: string = `${CONST.ITEM_CAT3_ID_CASHMILON}001`;

  public static ITEM_ID_COLOR_LAGGING: string = `${CONST.ITEM_CAT3_ID_LAGGING}001`;
  public static ITEM_ID_LAGGING: string = `${CONST.ITEM_CAT3_ID_LAGGING}002`;

  public static ITEM_ID_QUILTING: string = `${CONST.ITEM_CAT3_ID_QUILTING}001`;

  public static ITEM_ID_SCREEN: string = `${CONST.ITEM_CAT3_ID_SCREEN}001`;

  public static ITEM_ID_MAT: string = `${CONST.ITEM_CAT3_ID_MAT}001`;

  public static ITEM_ID_SCREENNET: string = `${CONST.ITEM_CAT3_ID_SCREENNET}001`;

  //
  public static ITEM_ID_NORMAL_PAD: string = `${CONST.ITEM_CAT3_ID_PAD}001`;
  public static ITEM_ID_LAGGING_PAD: string = `${CONST.ITEM_CAT3_ID_PAD}002`;
  public static ITEM_ID_GUTTER_PAD: string = `${CONST.ITEM_CAT3_ID_PAD}003`;
  public static ITEM_ID_CONDENSATION_PAD: string = `${CONST.ITEM_CAT3_ID_PAD}004`;
  public static ITEM_ID_BAR_PAD: string = `${CONST.ITEM_CAT3_ID_PAD}021`;

  public static ITEM_ID_PAD_CONNECTION_PIN: string = `${CONST.ITEM_CAT3_ID_PAD_CONNECTOR}001`;
  public static ITEM_ID_CONDENSATION_PAD_CONNECTION_PIN: string = `${CONST.ITEM_CAT3_ID_PAD_CONNECTOR}002`;

  public static ITEM_ID_NORMAL_PAD_SPRING: string = `${CONST.ITEM_CAT3_ID_PAD_SPRING}001`;
  public static ITEM_ID_LAGGING_PAD_SPRING: string = `${CONST.ITEM_CAT3_ID_PAD_SPRING}002`;

  public static ITEM_ID_PAD_VINYL: string = `${CONST.ITEM_CAT3_ID_PAD_VINYL}001`;

  public static ITEM_ID_SKIRT_CLIP: string = `${CONST.ITEM_CAT3_ID_CLIP}001`;
  public static ITEM_ID_NORMAL_CLIP: string = `${CONST.ITEM_CAT3_ID_CLIP}002`;
  public static ITEM_ID_HANGER_CLIP: string = `${CONST.ITEM_CAT3_ID_CLIP}003`;
  public static ITEM_ID_SLOPE_CLIP: string = `${CONST.ITEM_CAT3_ID_CLIP}004`;

  public static ITEM_ID_RECT_LUMBER: string = `${CONST.ITEM_CAT3_ID_WOOD}001`;

  public static ITEM_ID_NORMAL_BANDSTRING: string = `${CONST.ITEM_CAT3_ID_BANDSTRING}001`;
  public static ITEM_ID_POLY_BANDSTRING: string = `${CONST.ITEM_CAT3_ID_BANDSTRING}002`;
  public static ITEM_ID_VINYL_BAND: string = `${CONST.ITEM_CAT3_ID_BANDSTRING}011`;
  public static ITEM_ID_NET_BAND: string = `${CONST.ITEM_CAT3_ID_BANDSTRING}021`;
  public static ITEM_ID_WEAVING_BAND: string = `${CONST.ITEM_CAT3_ID_BANDSTRING}031`;

  public static ITEM_ID_STEEL_STRING_WIRE: string = `${CONST.ITEM_CAT3_ID_STEEL_WIRE}001`;

  //
  public static ITEM_ID_VENTILATOR: string = `${CONST.ITEM_CAT3_ID_VENTILATOR}001`;

  public static ITEM_ID_VENT_FAN: string = `${CONST.ITEM_CAT3_ID_VENT_FAN}001`;

  public static ITEM_ID_VENT_HOLE: string = `${CONST.ITEM_CAT3_ID_VENT_HOLE}001`;

  public static ITEM_ID_VENT_HOLE_SWITCH_SET: string = `${CONST.ITEM_CAT3_ID_VENT_HOLE_SWITCH_SET}001`;

  public static ITEM_ID_AIR_FLOW_FAN: string = `${CONST.ITEM_CAT3_ID_AIR_FLOW_FAN}001`;

  //
  public static ITEM_ID_REDUCED_MOTOR: string = `${CONST.ITEM_CAT3_ID_REDUCED_MOTOR}001`;

  public static ITEM_ID_BEARING_SET: string = `${CONST.ITEM_CAT3_ID_BEARING}001`;

  public static ITEM_ID_WINDING_DRUM: string = `${CONST.ITEM_CAT3_ID_DRUM}001`;

  public static ITEM_ID_WIRE_STRAINER: string = `${CONST.ITEM_CAT3_ID_WIRE_STRAIN}001`;

  public static ITEM_ID_TRUSS_CLIP: string = `${CONST.ITEM_CAT3_ID_TRUSS_CLIP}001`;

  public static ITEM_ID_STICK_TOW_CLIP: string = `${CONST.ITEM_CAT3_ID_TOW_CLIP}001`;
  public static ITEM_ID_HOOK_TOW_CLIP: string = `${CONST.ITEM_CAT3_ID_TOW_CLIP}002`;

  public static ITEM_ID_TOW_ROLLER: string = `${CONST.ITEM_CAT3_ID_CURTAIN_OTHER}001`;
  public static ITEM_ID_GUIDE_ROLLER: string = `${CONST.ITEM_CAT3_ID_CURTAIN_OTHER}002`;
  public static ITEM_ID_ROLLER_HOOK: string = `${CONST.ITEM_CAT3_ID_CURTAIN_OTHER}011`;
  public static ITEM_ID_SCREEN_PINCER: string = `${CONST.ITEM_CAT3_ID_CURTAIN_OTHER}021`;

  //
  public static ITEM_ID_NUTRIENT_SOLUTION: string = `${CONST.ITEM_CAT3_ID_NUTRIENT_SOLUTION}001`;

  public static ITEM_ID_WATER_SOLUTION: string = `${CONST.ITEM_CAT3_ID_WATER_SOLUTION}001`;

  public static ITEM_ID_WATER_TANK: string = `${CONST.ITEM_CAT3_ID_WATER_TANK}001`;

  public static ITEM_ID_WATER_TANK_FITTING: string = `${CONST.ITEM_CAT3_ID_WATER_TANK_FITTING}001`;

  public static ITEM_ID_NUTRIENT_MIXER: string = `${CONST.ITEM_CAT3_ID_WATER_TANK_CONTROLLER}001`;
  public static ITEM_ID_WATER_LEVEL_CONTROLLER: string = `${CONST.ITEM_CAT3_ID_WATER_TANK_CONTROLLER}011`;

  public static ITEM_ID_WATER_PUMP: string = `${CONST.ITEM_CAT3_ID_WATER_PUMP}001`;

  public static ITEM_ID_WATER_PUMP_CONNECT: string = `${CONST.ITEM_CAT3_ID_WATER_PUMP_CONNECT}001`;

  public static ITEM_ID_FLEXIBLE_HOSE: string = `${CONST.ITEM_CAT3_ID_WATER_PUMP_HOSE}001`;

  public static ITEM_ID_WATER_PUMP_CONTROLLER: string = `${CONST.ITEM_CAT3_ID_WATER_PUMP_CONTROLLER}001`;

  public static ITEM_ID_WATER_FILTER: string = `${CONST.ITEM_CAT3_ID_WATER_FILTER}001`;

  public static ITEM_ID_PE_PIPE: string = `${CONST.ITEM_CAT3_ID_WATERING_PIPE}001`;

  public static ITEM_ID_ELECTRIC_VALVE: string = `${CONST.ITEM_CAT3_ID_WATERING_VALVE}000`;
  public static ITEM_ID_UNION_VALVE: string = `${CONST.ITEM_CAT3_ID_WATERING_VALVE}001`;
  public static ITEM_ID_FEMALE_BALL_VALVE: string = `${CONST.ITEM_CAT3_ID_WATERING_VALVE}011`;
  public static ITEM_ID_MALE_BALL_VALVE: string = `${CONST.ITEM_CAT3_ID_WATERING_VALVE}016`;
  public static ITEM_ID_FEMALE_MALE_BALL_VALVE: string = `${CONST.ITEM_CAT3_ID_WATERING_VALVE}021`;
  public static ITEM_ID_FEMALE_MALE_VALVE: string = `${CONST.ITEM_CAT3_ID_WATERING_VALVE}031`;

  public static ITEM_ID_NIPPLE: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}001`;
  public static ITEM_ID_SOCKET_FEMALE: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}011`;
  public static ITEM_ID_DIFFERENT_SOCKET_FEMALE: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}016`;
  public static ITEM_ID_CONNECT_SOCKET: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}021`;
  public static ITEM_ID_DIFFERENT_CONNECT_SOCKET: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}026`;
  public static ITEM_ID_ELBOW: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}031`;
  public static ITEM_ID_MALE_ELBOW_SOCKET: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}036`;
  public static ITEM_ID_FEMALE_ELBOW_SOCKET: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}037`;
  public static ITEM_ID_EXACT_T: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}041`;
  public static ITEM_ID_DIFFERENT_T: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}046`;
  public static ITEM_ID_BUSHING: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}051`;
  public static ITEM_ID_VALVE_SOCKET: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}061`;
  public static ITEM_ID_PE_PIPE_END: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}066`;
  public static ITEM_ID_WATER_SCREEN_CONNECT_SOCKET: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}069`;
  public static ITEM_ID_WATER_SCREEN_ELBOW_SOCKET: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}070`;
  public static ITEM_ID_PE_PIPE_SADDLE: string = `${CONST.ITEM_CAT3_ID_WATERING_CONNECT}071`;

  public static ITEM_ID_PE_PIPE_HANGER: string = `${CONST.ITEM_CAT3_ID_WATERING_HANGER}001`;

  public static ITEM_ID_TUBE: string = `${CONST.ITEM_CAT3_ID_WATERING_TUBE}001`;

  public static ITEM_ID_TUBE_NIPPLE: string = `${CONST.ITEM_CAT3_ID_WATERING_TUBE_NIPPLE}001`;

  public static ITEM_ID_MINI_SPRINKLER: string = `${CONST.ITEM_CAT3_ID_MINI_SPRINKLER}001`;

  public static ITEM_ID_MINI_SPRINKLER_DROP_GUARD: string = `${CONST.ITEM_CAT3_ID_MINI_SPRINKLER_DROP_GUARD}001`;

  public static ITEM_ID_MINI_SPRINKLER_TUBE_SET: string = `${CONST.ITEM_CAT3_ID_MINI_SPRINKLER_TUBE_SET}001`;

  public static ITEM_ID_DRIP_PIPE: string = `${CONST.ITEM_CAT3_ID_DRIP_PIPE}001`;

  public static ITEM_ID_DRIP_BUTTON: string = `${CONST.ITEM_CAT3_ID_DRIP_BUTTON}001`;

  public static ITEM_ID_DRIP_BUTTON_MANY_HOLD: string = `${CONST.ITEM_CAT3_ID_DRIP_BUTTON_APPENDAGE}001`;
  public static ITEM_ID_DRIP_BUTTON_DROPPER: string = `${CONST.ITEM_CAT3_ID_DRIP_BUTTON_APPENDAGE}011`;

  public static ITEM_ID_DRIP_BARB: string = `${CONST.ITEM_CAT3_ID_DRIP_BARB}001`;
  public static ITEM_ID_DRIP_START_SADDLE: string = `${CONST.ITEM_CAT3_ID_DRIP_BARB}011`;

  public static ITEM_ID_DRIP_LINE_VALVE: string = `${CONST.ITEM_CAT3_ID_DRIP_VALVE}001`;
  public static ITEM_ID_DRIP_START_LINE_VALVE: string = `${CONST.ITEM_CAT3_ID_DRIP_VALVE}011`;
  public static ITEM_ID_DRIP_LINE_VALVE_SUPPORT: string = `${CONST.ITEM_CAT3_ID_DRIP_VALVE}021`;

  public static ITEM_ID_DRIP_CONNECT_SOCKET: string = `${CONST.ITEM_CAT3_ID_DRIP_CONNECT}001`;
  public static ITEM_ID_DRIP_SUPPORT_END: string = `${CONST.ITEM_CAT3_ID_DRIP_CONNECT}002`;
  public static ITEM_ID_LD_CONNECT: string = `${CONST.ITEM_CAT3_ID_DRIP_CONNECT}011`;
  public static ITEM_ID_LD_ELBOW: string = `${CONST.ITEM_CAT3_ID_DRIP_CONNECT}013`;
  public static ITEM_ID_LD_EXACT_T: string = `${CONST.ITEM_CAT3_ID_DRIP_CONNECT}015`;
  public static ITEM_ID_LD_END: string = `${CONST.ITEM_CAT3_ID_DRIP_CONNECT}017`;

  public static ITEM_ID_FOUNTAIN_HOSE: string = `${CONST.ITEM_CAT3_ID_FOUNTAIN_HOSE}001`;

  public static ITEM_ID_FOUNTAIN_HOSE_CONNECT: string = `${CONST.ITEM_CAT3_ID_FOUNTAIN_HOSE_CONNECT}001`;
  public static ITEM_ID_FOUNTAIN_HOSE_CROSS_CONNECT: string = `${CONST.ITEM_CAT3_ID_FOUNTAIN_HOSE_CONNECT}006`;
  public static ITEM_ID_FOUNTAIN_HOSE_END: string = `${CONST.ITEM_CAT3_ID_FOUNTAIN_HOSE_CONNECT}011`;
  public static ITEM_ID_FOUNTAIN_HOSE_T_VALVE: string = `${CONST.ITEM_CAT3_ID_FOUNTAIN_HOSE_CONNECT}021`;

  public static ITEM_ID_FOUNTAIN_HOSE_HANGER_SCREEN: string = `${CONST.ITEM_CAT3_ID_FOUNTAIN_HOSE_HANGER}001`;
  public static ITEM_ID_FOUNTAIN_HOSE_HANGER_UPPER: string = `${CONST.ITEM_CAT3_ID_FOUNTAIN_HOSE_HANGER}011`;
  public static ITEM_ID_FOUNTAIN_HOSE_HANGER_SIDE: string = `${CONST.ITEM_CAT3_ID_FOUNTAIN_HOSE_HANGER}021`;

  public static ITEM_ID_PE_PIPE_FASTENER: string = `${CONST.ITEM_CAT3_ID_WATERING_TOOL}001`;
  public static ITEM_ID_PE_PIPE_PUNCHER: string = `${CONST.ITEM_CAT3_ID_WATERING_TOOL}011`;

  public static ITEM_ID_DRIP_ALIGNMENT_PIN: string = `${CONST.ITEM_CAT3_ID_WATERING_OTHER}001`;
  public static ITEM_ID_WATERING_SPRING: string = `${CONST.ITEM_CAT3_ID_WATERING_OTHER}011`;

  //
  public static ITEM_ID_THP_PIPE: string = `${CONST.ITEM_CAT3_ID_DRAIN_PIPE}001`;
  public static ITEM_ID_PVC_PIPE: string = `${CONST.ITEM_CAT3_ID_DRAIN_PIPE}011`;

  public static ITEM_ID_PVC_FEMALE_BALL_VALVE: string = `${CONST.ITEM_CAT3_ID_PVC_VALVE}011`;

  public static ITEM_ID_PVC_SOCKET: string = `${CONST.ITEM_CAT3_ID_PVC_CONNECTOR}001`;
  public static ITEM_ID_PVC_ELBOW: string = `${CONST.ITEM_CAT3_ID_PVC_CONNECTOR}011`;
  public static ITEM_ID_PVC_DEGREE45_ELBOW: string = `${CONST.ITEM_CAT3_ID_PVC_CONNECTOR}016`;
  public static ITEM_ID_PVC_EXACT_T: string = `${CONST.ITEM_CAT3_ID_PVC_CONNECTOR}021`;
  public static ITEM_ID_PVC_DIFFERENT_T: string = `${CONST.ITEM_CAT3_ID_PVC_CONNECTOR}026`;
  public static ITEM_ID_PVC_VALVE_SOCKET: string = `${CONST.ITEM_CAT3_ID_PVC_CONNECTOR}081`;
  public static ITEM_ID_PVC_END: string = `${CONST.ITEM_CAT3_ID_PVC_CONNECTOR}091`;

  public static ITEM_ID_DRAINSPOUT_BOX: string = `${CONST.ITEM_CAT3_ID_DRAINSPOUT_BOX}001`;

  public static ITEM_ID_DRAIN_PIPE_HANGER: string = `${CONST.ITEM_CAT3_ID_DRAIN_PIPE_HANGER}001`;

  //
  public static ITEM_ID_FORMING_BED: string = `${CONST.ITEM_CAT3_ID_FORMING_BED}001`;

  public static ITEM_ID_STYROFOAM_BED: string = `${CONST.ITEM_CAT3_ID_STYROFOAM_BED}001`;

  public static ITEM_ID_NFT_BED: string = `${CONST.ITEM_CAT3_ID_NFT_BED}001`;

  public static ITEM_ID_BED_END: string = `${CONST.ITEM_CAT3_ID_BED_APPENDAGE}001`;
  public static ITEM_ID_BED_DRAIN: string = `${CONST.ITEM_CAT3_ID_BED_APPENDAGE}006`;
  public static ITEM_ID_BED_SUPPORT_CLIP: string = `${CONST.ITEM_CAT3_ID_BED_APPENDAGE}011`;
  public static ITEM_ID_BED_HOLDER: string = `${CONST.ITEM_CAT3_ID_BED_APPENDAGE}016`;
  public static ITEM_ID_NFT_SUPPLY_DRAIN_SET: string = `${CONST.ITEM_CAT3_ID_BED_APPENDAGE}021`;
  public static ITEM_ID_SINK_GUARD_PLATE: string = `${CONST.ITEM_CAT3_ID_BED_APPENDAGE}031`;

  public static ITEM_ID_GROUND_COVER: string = `${CONST.ITEM_CAT3_ID_GROUND_COVER}001`;

  public static ITEM_ID_SACK_PIN: string = `${CONST.ITEM_CAT3_ID_GROUND_COVER_APPENDAGE}001`;

  public static ITEM_ID_TRELLIS_HANGER: string = `${CONST.ITEM_CAT3_ID_TRELLIS_STRING_APPENDAGE}001`;
  public static ITEM_ID_TRELLIS_ROUND_BAR: string = `${CONST.ITEM_CAT3_ID_TRELLIS_STRING_APPENDAGE}011`;

  //
  public static ITEM_ID_UPPER_CARRIER: string = `${CONST.ITEM_CAT3_ID_CARRIER}001`;
  public static ITEM_ID_SIDE_CARRIER: string = `${CONST.ITEM_CAT3_ID_CARRIER}011`;

  public static ITEM_ID_GROUND_CARRIER: string = `${CONST.ITEM_CAT3_ID_GROUND_CARRIER}001`;

  public static ITEM_ID_RAIL_HOOK: string = `${CONST.ITEM_CAT3_ID_RAIL_HOOK}001`;
  public static ITEM_ID_SECOND_LEVEL_RAIL_HOOK: string = `${CONST.ITEM_CAT3_ID_RAIL_HOOK}011`;
  public static ITEM_ID_SIDE_RAIL_HOOK: string = `${CONST.ITEM_CAT3_ID_RAIL_HOOK}021`;

  public static ITEM_ID_RAIL_INTERVAL: string = `${CONST.ITEM_CAT3_ID_RAIL_INTERVAL}001`;
  public static ITEM_ID_RAIL_SUPPORT_PIPE: string = `${CONST.ITEM_CAT3_ID_RAIL_INTERVAL}011`;

  //
  public static ITEM_ID_BOILER: string = `${CONST.ITEM_CAT3_ID_BOILER}001`;

  public static ITEM_ID_HEATING_FAN: string = `${CONST.ITEM_CAT3_ID_HEATING_FAN}001`;

  public static ITEM_ID_HEATING_TUBE: string = `${CONST.ITEM_CAT3_ID_HEATING_TUBE}001`;

  //
  public static ITEM_ID_CORD: string = `${CONST.ITEM_CAT3_ID_CORD_WIRE}001`;
  public static ITEM_ID_RIPCORD: string = `${CONST.ITEM_CAT3_ID_CORD_WIRE}011`;
  public static ITEM_ID_COATING_WIRE: string = `${CONST.ITEM_CAT3_ID_CORD_WIRE}021`;

  //
  public static ITEM_ID_CUSTOM_MATERIAL: string = `${CONST.ITEM_CAT3_ID_CUSTOM_MATERIAL}001`;
  public static ITEM_ID_CUSTOM_CONTROLLER: string = `${CONST.ITEM_CAT3_ID_CUSTOM_MATERIAL}002`;

  public static ITEM_ID_BUILDING_MIS: string = `${CONST.ITEM_CAT3_ID_BUILDING_MIS}001`;

  //
  public static ITEM_ID_OVERLAP_MATERIAL_SAVING: string = `${CONST.ITEM_CAT3_ID_MATERIAL_DISCOUNT_COST}001`;
  public static ITEM_ID_MATERIAL_DISCOUNT: string = `${CONST.ITEM_CAT3_ID_MATERIAL_DISCOUNT_COST}011`;

  //
  public static ITEM_ID_CUSTOM_EXPENSE: string = `${CONST.ITEM_CAT3_ID_CUSTOM_EXPENSE}001`;

  public static ITEM_ID_WELDING: string = `${CONST.ITEM_CAT3_ID_OTHER_EXPENSE}001`;

  public static ITEM_ID_PIPE_BENDING_EXPENSE: string = `${CONST.ITEM_CAT3_ID_PIPE_BENDING_EXPENSE}001`;

  public static ITEM_ID_EQUIPMENT_EXPENSE: string = `${CONST.ITEM_CAT3_ID_EQUIPMENT_EXPENSE}001`;

  //
  public static ITEM_ID_CUSTOM_LABOR_COST: string = `${CONST.ITEM_CAT3_ID_CUSTOM_LABOR_COST}001`;

  public static ITEM_ID_BUILDING_DISCOUNT: string = `${CONST.ITEM_CAT3_ID_BUILDING_DISCOUNT_COST}001`;

  // 모델이 없는 상품
  public static ITEM_ID_NO_UNIT_PRICE_MODEL: string = "19999999";
  // ...

  // //----------------------------------
  // // 상품별 규격 리스트 레이블
  // // - CAT3과 동일하게 분류됨
  // //----------------------------------

  // public static ITEM_SPECS_LIST_NAME_PIPE: string = '파이프형태, 재질, 파이프단면크기, 두께';
  // public static ITEM_SPECS_LIST_NAME_SUPPORT_PIPE: string = '파이프형태, 재질, 파이프단면크기';

  // public static ITEM_SPECS_LIST_NAME_CONNECTOR: string = '파이프형태, 재질, 단면크기1, 단면크기2';
  // public static ITEM_SPECS_LIST_NAME_HOLDER: string = '파이프형태, 재질, 단면크기1, 단면크기2';
  // public static ITEM_SPECS_LIST_NAME_SCONCE: string = '파이프형태, 재질, 단면크기1, 단면크기2';
  // public static ITEM_SPECS_LIST_NAME_DIAMOND_CLAMP: string = '파이프형태, 재질, 기둥단면크기, 보단면크기, 꽂이단면크기, 물받이폭';
  // public static ITEM_SPECS_LIST_NAME_DOOR: string = '특성, 파이프단면크기';
  // public static ITEM_SPECS_LIST_NAME_DOOR_MADE: string = '특성, 파이프단면크기, 폭, 길이';
  // public static ITEM_SPECS_LIST_NAME_HINGED_DOOR: string = '특성, 파이프단면크기, 폭, 길이';
  // public static ITEM_SPECS_LIST_NAME_ROLLER: string = '재질, 크기';
  // public static ITEM_SPECS_LIST_NAME_CEMENT: string = '재질';
  // public static ITEM_SPECS_LIST_NAME_SCREW: string = '형태, 재질, 크기, 단위수량';
  // public static ITEM_SPECS_LIST_NAME_FRAME_OTHER: string = '규격';

  // public static ITEM_SPECS_LIST_NAME_IRON_PLATE_GUTTER: string = '두께';
  // public static ITEM_SPECS_LIST_NAME_PLASTIC_GUTTER: string = '두께';
  // public static ITEM_SPECS_LIST_NAME_UBAR: string = '파이프형태, 단면크기, 길이';
  // public static ITEM_SPECS_LIST_NAME_GUTTER_HANGER: string = '파이프형태, 재질, 단면크기, 폭';
  // public static ITEM_SPECS_LIST_NAME_DRAIN_PIPE: string = '지름, 길이';
  // public static ITEM_SPECS_LIST_NAME_PVC_CONNECTOR: string = '재질, 지름';
  // public static ITEM_SPECS_LIST_NAME_DRAINSPOUT_BOX: string = '재질, 지름';
  // public static ITEM_SPECS_LIST_NAME_DRAIN_PIPE_HANGER: string = '재질, 지름';

  // public static ITEM_SPECS_LIST_NAME_MANUAL_SWITCHER: string = '개폐형태, 용도';
  // public static ITEM_SPECS_LIST_NAME_POWER_SWITCHER: string = '용도';
  // public static ITEM_SPECS_LIST_NAME_ELECTRIC_WIRE: string = '재질, 면적, 내선, 길이';
  // public static ITEM_SPECS_LIST_NAME_SWITCHER_ASSIST: string = '단면크기';
  // public static ITEM_SPECS_LIST_NAME_INTEGRATED_CONTROLLER: string = '특성, 용량';
  // public static ITEM_SPECS_LIST_NAME_CONTROLLER_SENSOR: string = '규격';
  // public static ITEM_SPECS_LIST_NAME_SWITCHER_OTHER: string = '규격';

  // public static ITEM_SPECS_LIST_NAME_VINYL: string = '두께';
  // public static ITEM_SPECS_LIST_NAME_WEAVING_FILM: string = '특성, 색상';
  // public static ITEM_SPECS_LIST_NAME_SHADENET: string = '재질, 차광율, 색상';
  // public static ITEM_SPECS_LIST_NAME_TENT: string = '재질';
  // public static ITEM_SPECS_LIST_NAME_NONWOVEN: string = '재질, 색상';
  // public static ITEM_SPECS_LIST_NAME_CASHMILON: string = '재질, 폭';
  // public static ITEM_SPECS_LIST_NAME_LAGGING: string = '재질, 폭';
  // public static ITEM_SPECS_LIST_NAME_QUILTING: string = '조합';
  // public static ITEM_SPECS_LIST_NAME_SCREEN: string = '재질';
  // public static ITEM_SPECS_LIST_NAME_MAT: string = '재질';
  // public static ITEM_SPECS_LIST_NAME_SCREENNET: string = '재질';

  // public static ITEM_SPECS_LIST_NAME_PAD: string = '재질, 두께, 길이';
  // public static ITEM_SPECS_LIST_NAME_PAD_CONNECTOR: string = '재질';
  // public static ITEM_SPECS_LIST_NAME_PAD_SPRING: string = '재질, 길이';
  // public static ITEM_SPECS_LIST_NAME_PAD_VINYL: string = '길이';
  // public static ITEM_SPECS_LIST_NAME_CLIP: string = '재질, 단면크기';
  // public static ITEM_SPECS_LIST_NAME_WOOD: string = '재질, 단면크기, 길이';
  // public static ITEM_SPECS_LIST_NAME_BANDSTRING: string = '특성, 폭, 길이';
  // public static ITEM_SPECS_LIST_NAME_STEEL_WIRE: string = '재질, 번호, 두께, 중량, 길이';

  // public static ITEM_SPECS_LIST_NAME_VENTILATOR: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_VENT_FAN: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_VENT_HOLE: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_VENT_HOLE_SWITCH_SET: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_AIR_FLOW_FAN: string = '특성';

  // public static ITEM_SPECS_LIST_NAME_REDUCED_MOTOR: string = '마력, 감는방식, 변압형태';
  // public static ITEM_SPECS_LIST_NAME_BEARING: string = '파이프형태, 재질, 단면크기1, 단면크기2';
  // public static ITEM_SPECS_LIST_NAME_DRUM: string = '재질, 단면크기1, 단면크기2';
  // public static ITEM_SPECS_LIST_NAME_WIRE_STRAIN: string = '파이프형태, 재질, 단면크기';
  // public static ITEM_SPECS_LIST_NAME_TOW_CLIP: string = '재질';
  // public static ITEM_SPECS_LIST_NAME_CURTAIN_OTHER: string = '규격';

  // public static ITEM_SPECS_LIST_NAME_BOILER: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_HEATING_FAN: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_HEATING_TUBE: string = '특성';

  // public static ITEM_SPECS_LIST_NAME_NUTRIENT_SOLUTION: string = '특성';

  // public static ITEM_SPECS_LIST_NAME_WATER_SOLUTION: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_WATER_TANK: string = '특성, 형태, 용량';
  // public static ITEM_SPECS_LIST_NAME_WATER_TANK_FITTING: string = '특성, 지름1, 지름2';
  // public static ITEM_SPECS_LIST_NAME_WATER_PUMP: string = '특성, 마력범위';
  // public static ITEM_SPECS_LIST_NAME_WATER_PUMP_CONNECT: string = '특성, 지름1, 지름2';
  // public static ITEM_SPECS_LIST_NAME_WATER_FILTER: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_WATERING_PIPE: string = '특성, 지름, 길이';
  // public static ITEM_SPECS_LIST_NAME_WATERING_CONNECT: string = '재질, 특성, 지름1, 지름2';
  // public static ITEM_SPECS_LIST_NAME_WATERING_VALVE: string = '재질, 특성, 지름1, 지름2';
  // public static ITEM_SPECS_LIST_NAME_WATERING_HANGER: string = '특성, 길이, 단면크기, 지름';
  // public static ITEM_SPECS_LIST_NAME_WATERING_TUBE: string = '특성, 지름, 길이';
  // public static ITEM_SPECS_LIST_NAME_WATERING_TUBE_NIPPLE: string = '특성, 니플형태, 지름';
  // public static ITEM_SPECS_LIST_NAME_MINI_SPRINKLER: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_MINI_SPRINKLER_DROP_GUARD: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_MINI_SPRINKLER_TUBE_SET: string = '특성, 지름';
  // public static ITEM_SPECS_LIST_NAME_DRIP_PIPE: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_DRIP_BUTTON: string = '특성';
  // public static ITEM_SPECS_LIST_NAME_DRIP_BUTTON_APPENDAGE: string = '특성, 형태, 지름';
  // public static ITEM_SPECS_LIST_NAME_DRIP_BARB: string = '특성, 형태, 지름1, 지름2';
  // public static ITEM_SPECS_LIST_NAME_FOUNTAIN_HOSE: string = '특성, 길이';
  // public static ITEM_SPECS_LIST_NAME_FOUNTAIN_HOSE_CONNECT: string = '특성, 형태, 지름';
  // public static ITEM_SPECS_LIST_NAME_FOUNTAIN_HOSE_HANGER: string = '특성, 단면크기';
  // public static ITEM_SPECS_LIST_NAME_WATERING_TOOL: string = '특성, 지름';
  // public static ITEM_SPECS_LIST_NAME_WATERING_OTHER: string = '규격';

  // public static ITEM_SPECS_LIST_NAME_CARRIER: string = '특성, 크기';
  // public static ITEM_SPECS_LIST_NAME_GROUND_CARRIER: string = '특성, 크기';
  // public static ITEM_SPECS_LIST_NAME_RAIL_HOOK: string = '파이프형태, 특성, 단면크기1, 단면크기2, 길이';
  // public static ITEM_SPECS_LIST_NAME_GROUND_RAIL_INTERVAL: string = '파이프형태, 특성, 단면크기';

  // public static ITEM_SPECS_LIST_NAME_CORD_WIRE: string = '특성, 재질, 두께, 길이';

  // public static ITEM_SPECS_LIST_NAME_CUSTOM: string = '규격';

  // public static ITEM_SPECS_LIST_NAME_ADDITIONAL_COST: string = '규격';
  // public static ITEM_SPECS_LIST_NAME_DISCOUNT_COST: string = '규격';

  // public static ITEM_SPECS_LIST_NAME_PIPE_BENDING_EXPENSE: string = '파이프형태, 파이프단면크기';

  // public static ITEM_SPECS_LIST_NAME_EQUIPMENT_EXPENSE: string = '장비유형';

  // //----------------------------------
  // // 상품별 규격 레이블
  // // - CAT3과 동일하게 분류됨
  // //----------------------------------

  // public static ITEM_SPECS_NAME_PIPE: string = CONST.ITEM_SPECS_LIST_NAME_PIPE + ', 길이, 벤딩';
  // public static ITEM_SPECS_NAME_SUPPORT_PIPE: string = CONST.ITEM_SPECS_LIST_NAME_SUPPORT_PIPE;

  // public static ITEM_SPECS_NAME_CONNECTOR: string = CONST.ITEM_SPECS_LIST_NAME_CONNECTOR;
  // public static ITEM_SPECS_NAME_HOLDER: string = CONST.ITEM_SPECS_LIST_NAME_HOLDER;
  // public static ITEM_SPECS_NAME_SCONCE: string = CONST.ITEM_SPECS_LIST_NAME_SCONCE;
  // public static ITEM_SPECS_NAME_DIAMOND_CLAMP: string = CONST.ITEM_SPECS_LIST_NAME_DIAMOND_CLAMP;
  // public static ITEM_SPECS_NAME_DOOR: string = CONST.ITEM_SPECS_LIST_NAME_DOOR + ', 폭, 길이';
  // public static ITEM_SPECS_NAME_DOOR_MADE: string = CONST.ITEM_SPECS_LIST_NAME_DOOR_MADE + ', 롤러크기';
  // public static ITEM_SPECS_NAME_HINGED_DOOR: string = CONST.ITEM_SPECS_LIST_NAME_HINGED_DOOR;
  // public static ITEM_SPECS_NAME_ROLLER: string = CONST.ITEM_SPECS_LIST_NAME_ROLLER;
  // public static ITEM_SPECS_NAME_CEMENT: string = CONST.ITEM_SPECS_LIST_NAME_CEMENT;
  // public static ITEM_SPECS_NAME_SCREW: string = CONST.ITEM_SPECS_LIST_NAME_SCREW;
  // public static ITEM_SPECS_NAME_FRAME_OTHER: string = CONST.ITEM_SPECS_LIST_NAME_FRAME_OTHER;

  // public static ITEM_SPECS_NAME_IRON_PLATE_GUTTER: string = CONST.ITEM_SPECS_LIST_NAME_IRON_PLATE_GUTTER + ', 폭, 길이';
  // public static ITEM_SPECS_NAME_PLASTIC_GUTTER: string = CONST.ITEM_SPECS_LIST_NAME_PLASTIC_GUTTER + ', 폭, 길이, 타공';
  // public static ITEM_SPECS_NAME_UBAR: string = CONST.ITEM_SPECS_LIST_NAME_UBAR;
  // public static ITEM_SPECS_NAME_GUTTER_HANGER: string = CONST.ITEM_SPECS_LIST_NAME_GUTTER_HANGER;
  // public static ITEM_SPECS_NAME_DRAIN_PIPE: string = CONST.ITEM_SPECS_LIST_NAME_DRAIN_PIPE;
  // public static ITEM_SPECS_NAME_PVC_CONNECTOR: string = CONST.ITEM_SPECS_LIST_NAME_PVC_CONNECTOR;
  // public static ITEM_SPECS_NAME_DRAINSPOUT_BOX: string = CONST.ITEM_SPECS_LIST_NAME_DRAINSPOUT_BOX;
  // public static ITEM_SPECS_NAME_DRAIN_PIPE_HANGER: string = CONST.ITEM_SPECS_LIST_NAME_DRAIN_PIPE_HANGER;

  // public static ITEM_SPECS_NAME_MANUAL_SWITCHER: string = CONST.ITEM_SPECS_LIST_NAME_MANUAL_SWITCHER;
  // public static ITEM_SPECS_NAME_POWER_SWITCHER: string = CONST.ITEM_SPECS_LIST_NAME_POWER_SWITCHER + ', 축커플링, 지지커플링, 지지고정핀, 장볼트고정구';
  // public static ITEM_SPECS_NAME_ELECTRIC_WIRE: string = CONST.ITEM_SPECS_LIST_NAME_ELECTRIC_WIRE;
  // public static ITEM_SPECS_NAME_SWITCHER_ASSIST: string = CONST.ITEM_SPECS_LIST_NAME_SWITCHER_ASSIST;
  // public static ITEM_SPECS_NAME_INTEGRATED_CONTROLLER: string = CONST.ITEM_SPECS_LIST_NAME_INTEGRATED_CONTROLLER + ', 통합, 비닐, 부직, 커튼, 환기, 관수, 기타AC';
  // public static ITEM_SPECS_NAME_CONTROLLER_SENSOR: string = CONST.ITEM_SPECS_LIST_NAME_CONTROLLER_SENSOR;
  // public static ITEM_SPECS_NAME_SWITCHER_OTHER: string = CONST.ITEM_SPECS_LIST_NAME_SWITCHER_OTHER;

  // public static ITEM_SPECS_NAME_VINYL: string = CONST.ITEM_SPECS_LIST_NAME_VINYL + ', 폭, 길이, 폭형태, 포장형태, 끈형태';
  // public static ITEM_SPECS_NAME_WEAVING_FILM: string = CONST.ITEM_SPECS_LIST_NAME_WEAVING_FILM + ', 폭, 길이, 끈형태, 폭미싱횟수';
  // public static ITEM_SPECS_NAME_SHADENET: string = CONST.ITEM_SPECS_LIST_NAME_SHADENET + ', 폭, 길이, 끈형태, 끝미싱, 폭미싱횟수';
  // public static ITEM_SPECS_NAME_TENT: string = CONST.ITEM_SPECS_LIST_NAME_TENT + ', 폭, 길이, 폭미싱횟수';
  // public static ITEM_SPECS_NAME_NONWOVEN: string = CONST.ITEM_SPECS_LIST_NAME_NONWOVEN + ', 폭, 길이, 끈형태, 폭미싱횟수';
  // public static ITEM_SPECS_NAME_CASHMILON: string = CONST.ITEM_SPECS_LIST_NAME_CASHMILON + ', 길이';
  // public static ITEM_SPECS_NAME_LAGGING: string = CONST.ITEM_SPECS_LIST_NAME_LAGGING + ', 길이';
  // public static ITEM_SPECS_NAME_QUILTING: string = CONST.ITEM_SPECS_LIST_NAME_QUILTING + ', 폭, 길이, 끈형태, 폭미싱횟수';
  // public static ITEM_SPECS_NAME_SCREEN: string = CONST.ITEM_SPECS_LIST_NAME_SCREEN + ', 폭, 길이, 끈형태, 폭미싱횟수';
  // public static ITEM_SPECS_NAME_MAT: string = CONST.ITEM_SPECS_LIST_NAME_MAT + ', 폭, 길이, 끈형태';
  // public static ITEM_SPECS_NAME_SCREENNET: string = CONST.ITEM_SPECS_LIST_NAME_SCREENNET + ', 폭, 길이';

  // public static ITEM_SPECS_NAME_PAD: string = CONST.ITEM_SPECS_LIST_NAME_PAD;
  // public static ITEM_SPECS_NAME_PAD_CONNECTOR: string = CONST.ITEM_SPECS_LIST_NAME_PAD_CONNECTOR;
  // public static ITEM_SPECS_NAME_PAD_SPRING: string = CONST.ITEM_SPECS_LIST_NAME_PAD_SPRING;
  // public static ITEM_SPECS_NAME_PAD_VINYL: string = CONST.ITEM_SPECS_LIST_NAME_PAD_VINYL;
  // public static ITEM_SPECS_NAME_CLIP: string = CONST.ITEM_SPECS_LIST_NAME_CLIP;
  // public static ITEM_SPECS_NAME_WOOD: string = CONST.ITEM_SPECS_LIST_NAME_WOOD;
  // public static ITEM_SPECS_NAME_BANDSTRING: string = CONST.ITEM_SPECS_LIST_NAME_BANDSTRING;
  // public static ITEM_SPECS_NAME_STEEL_WIRE: string = CONST.ITEM_SPECS_LIST_NAME_STEEL_WIRE;

  // public static ITEM_SPECS_NAME_VENTILATOR: string = CONST.ITEM_SPECS_LIST_NAME_VENTILATOR + ', 셔터';
  // public static ITEM_SPECS_NAME_VENT_FAN: string = CONST.ITEM_SPECS_LIST_NAME_VENT_FAN;
  // public static ITEM_SPECS_NAME_VENT_HOLE: string = CONST.ITEM_SPECS_LIST_NAME_VENT_HOLE;
  // public static ITEM_SPECS_NAME_VENT_HOLE_SWITCH_SET: string = CONST.ITEM_SPECS_LIST_NAME_VENT_HOLE_SWITCH_SET;
  // public static ITEM_SPECS_NAME_AIR_FLOW_FAN: string = CONST.ITEM_SPECS_LIST_NAME_AIR_FLOW_FAN;

  // public static ITEM_SPECS_NAME_REDUCED_MOTOR: string = CONST.ITEM_SPECS_LIST_NAME_REDUCED_MOTOR + ', 축커플링, 지지크기';
  // public static ITEM_SPECS_NAME_BEARING: string = CONST.ITEM_SPECS_LIST_NAME_BEARING;
  // public static ITEM_SPECS_NAME_DRUM: string = CONST.ITEM_SPECS_LIST_NAME_DRUM;
  // public static ITEM_SPECS_NAME_WIRE_STRAIN: string = CONST.ITEM_SPECS_LIST_NAME_WIRE_STRAIN;
  // public static ITEM_SPECS_NAME_TOW_CLIP: string = CONST.ITEM_SPECS_LIST_NAME_TOW_CLIP;
  // public static ITEM_SPECS_NAME_CURTAIN_OTHER: string = CONST.ITEM_SPECS_LIST_NAME_CURTAIN_OTHER;

  // public static ITEM_SPECS_NAME_BOILER: string = CONST.ITEM_SPECS_LIST_NAME_BOILER;
  // public static ITEM_SPECS_NAME_HEATING_FAN: string = CONST.ITEM_SPECS_LIST_NAME_HEATING_FAN;
  // public static ITEM_SPECS_NAME_HEATING_TUBE: string = CONST.ITEM_SPECS_LIST_NAME_HEATING_TUBE;

  // public static ITEM_SPECS_NAME_NUTRIENT_SOLUTION: string = CONST.ITEM_SPECS_LIST_NAME_NUTRIENT_SOLUTION;

  // public static ITEM_SPECS_NAME_WATER_SOLUTION: string = CONST.ITEM_SPECS_LIST_NAME_WATER_SOLUTION;
  // public static ITEM_SPECS_NAME_WATER_TANK: string = CONST.ITEM_SPECS_LIST_NAME_WATER_TANK + ', 입수구지름, 출수구지름, 퇴수구지름';
  // public static ITEM_SPECS_NAME_WATER_TANK_FITTING: string = CONST.ITEM_SPECS_LIST_NAME_WATER_TANK_FITTING;
  // public static ITEM_SPECS_NAME_WATER_PUMP: string = CONST.ITEM_SPECS_LIST_NAME_WATER_PUMP;
  // public static ITEM_SPECS_NAME_WATER_PUMP_CONNECT: string = CONST.ITEM_SPECS_LIST_NAME_WATER_PUMP_CONNECT;
  // public static ITEM_SPECS_NAME_WATER_FILTER: string = CONST.ITEM_SPECS_LIST_NAME_WATER_FILTER;
  // public static ITEM_SPECS_NAME_WATERING_PIPE: string = CONST.ITEM_SPECS_LIST_NAME_WATERING_PIPE;
  // public static ITEM_SPECS_NAME_WATERING_CONNECT: string = CONST.ITEM_SPECS_LIST_NAME_WATERING_CONNECT;
  // public static ITEM_SPECS_NAME_WATERING_VALVE: string = CONST.ITEM_SPECS_LIST_NAME_WATERING_VALVE;
  // public static ITEM_SPECS_NAME_WATERING_HANGER: string = CONST.ITEM_SPECS_LIST_NAME_WATERING_HANGER;
  // public static ITEM_SPECS_NAME_WATERING_TUBE: string = CONST.ITEM_SPECS_LIST_NAME_WATERING_TUBE;
  // public static ITEM_SPECS_NAME_WATERING_TUBE_NIPPLE: string = CONST.ITEM_SPECS_LIST_NAME_WATERING_TUBE_NIPPLE;
  // public static ITEM_SPECS_NAME_MINI_SPRINKLER: string = CONST.ITEM_SPECS_LIST_NAME_MINI_SPRINKLER;
  // public static ITEM_SPECS_NAME_MINI_SPRINKLER_DROP_GUARD: string = CONST.ITEM_SPECS_LIST_NAME_MINI_SPRINKLER_DROP_GUARD;
  // public static ITEM_SPECS_NAME_MINI_SPRINKLER_TUBE_SET: string = CONST.ITEM_SPECS_LIST_NAME_MINI_SPRINKLER_TUBE_SET + ', 길이, 니플형태, 연결형태';
  // public static ITEM_SPECS_NAME_DRIP_PIPE: string = CONST.ITEM_SPECS_LIST_NAME_DRIP_PIPE;
  // public static ITEM_SPECS_NAME_DRIP_BUTTON: string = CONST.ITEM_SPECS_LIST_NAME_DRIP_BUTTON;
  // public static ITEM_SPECS_NAME_DRIP_BUTTON_APPENDAGE: string = CONST.ITEM_SPECS_LIST_NAME_DRIP_BUTTON_APPENDAGE;
  // public static ITEM_SPECS_NAME_DRIP_BARB: string = CONST.ITEM_SPECS_LIST_NAME_DRIP_BARB;
  // public static ITEM_SPECS_NAME_FOUNTAIN_HOSE: string = CONST.ITEM_SPECS_LIST_NAME_FOUNTAIN_HOSE;
  // public static ITEM_SPECS_NAME_FOUNTAIN_HOSE_CONNECT: string = CONST.ITEM_SPECS_LIST_NAME_FOUNTAIN_HOSE_CONNECT;
  // public static ITEM_SPECS_NAME_FOUNTAIN_HOSE_HANGER: string = CONST.ITEM_SPECS_LIST_NAME_FOUNTAIN_HOSE_HANGER;
  // public static ITEM_SPECS_NAME_WATERING_TOOL: string = CONST.ITEM_SPECS_LIST_NAME_WATERING_TOOL;
  // public static ITEM_SPECS_NAME_WATERING_OTHER: string = CONST.ITEM_SPECS_LIST_NAME_WATERING_OTHER;

  // public static ITEM_SPECS_NAME_CARRIER: string = CONST.ITEM_SPECS_LIST_NAME_CARRIER + ', 롤러크기 ';
  // public static ITEM_SPECS_NAME_GROUND_CARRIER: string = CONST.ITEM_SPECS_LIST_NAME_GROUND_CARRIER + ', 롤러간격, 롤러크기';
  // public static ITEM_SPECS_NAME_RAIL_HOOK: string = CONST.ITEM_SPECS_LIST_NAME_RAIL_HOOK;
  // public static ITEM_SPECS_NAME_GROUND_RAIL_INTERVAL: string = CONST.ITEM_SPECS_LIST_NAME_GROUND_RAIL_INTERVAL;

  // public static ITEM_SPECS_NAME_CORD_WIRE: string = CONST.ITEM_SPECS_LIST_NAME_CORD_WIRE;

  // public static ITEM_SPECS_NAME_CUSTOM: string = CONST.ITEM_SPECS_LIST_NAME_CUSTOM;

  // public static ITEM_SPECS_NAME_ADDITIONAL_COST: string = CONST.ITEM_SPECS_LIST_NAME_ADDITIONAL_COST;
  // public static ITEM_SPECS_NAME_DISCOUNT_COST: string = CONST.ITEM_SPECS_LIST_NAME_DISCOUNT_COST;

  // public static ITEM_SPECS_NAME_PIPE_BENDING_EXPENSE: string = CONST.ITEM_SPECS_LIST_NAME_PIPE_BENDING_EXPENSE;

  // public static ITEM_SPECS_NAME_EQUIPMENT_EXPENSE: string = CONST.ITEM_SPECS_LIST_NAME_EQUIPMENT_EXPENSE;

  // //----------------------------------
  // // 상품별 상표 리스트 레이블
  // // - CAT3과 동일하게 분류됨
  // //----------------------------------

  // public static ITEM_BRANDS_LIST_NAME_DEFAULT: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_PIPE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_SUPPORT_PIPE: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_CONNECTOR: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_HOLDER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_SCONCE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DIAMOND_CLAMP: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DOOR: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DOOR_MADE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_HINGED_DOOR: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_ROLLER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_CEMENT: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_SCREW: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_FRAME_OTHER: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_IRON_PLATE_GUTTER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_PLASTIC_GUTTER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_UBAR: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_GUTTER_HANGER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DRAIN_PIPE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_PVC_CONNECTOR: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DRAINSPOUT_BOX: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DRAIN_PIPE_HANGER: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_MANUAL_SWITCHER: string = '회사, 모델명, 체인길이';
  // public static ITEM_BRANDS_LIST_NAME_POWER_SWITCHER: string = '회사, 모델명, 토크, 개폐길이';
  // public static ITEM_BRANDS_LIST_NAME_ELECTRIC_WIRE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_SWITCHER_ASSIST: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_INTEGRATED_CONTROLLER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_CONTROLLER_SENSOR: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_SWITCHER_OTHER: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_VINYL: string = '회사, 모델명';
  // public static ITEM_BRANDS_LIST_NAME_WEAVING_FILM: string = '회사, 폭미싱간격';
  // public static ITEM_BRANDS_LIST_NAME_SHADENET: string = '회사, 폭미싱간격';
  // public static ITEM_BRANDS_LIST_NAME_TENT: string = '회사, 폭미싱간격';
  // public static ITEM_BRANDS_LIST_NAME_NONWOVEN: string = '회사, 폭미싱간격';
  // public static ITEM_BRANDS_LIST_NAME_CASHMILON: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_LAGGING: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_QUILTING: string = '회사, 폭미싱간격';
  // public static ITEM_BRANDS_LIST_NAME_SCREEN: string = '회사, 모델, 차광율, 보온율, 폭미싱간격';
  // public static ITEM_BRANDS_LIST_NAME_MAT: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_SCREENNET: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_PAD: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_PAD_CONNECTOR: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_PAD_SPRING: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_PAD_VINYL: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_CLIP: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WOOD: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_BANDSTRING: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_STEEL_WIRE: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_VENTILATOR: string = '회사, 모델, 최대풍량, 크기, 팬지름, 소비전력';
  // public static ITEM_BRANDS_LIST_NAME_VENT_FAN: string = '회사, 모델, 최대풍량, 크기, 팬지름, 소비전력';
  // public static ITEM_BRANDS_LIST_NAME_VENT_HOLE: string = '회사, 모델, 크기, 특성';
  // public static ITEM_BRANDS_LIST_NAME_VENT_HOLE_SWITCH_SET: string = '회사, 모델, 볼트수량, 와이어길이';
  // public static ITEM_BRANDS_LIST_NAME_AIR_FLOW_FAN: string = '회사, 모델, 최대풍량, 크기, 팬지름, 소비전력';

  // public static ITEM_BRANDS_LIST_NAME_REDUCED_MOTOR: string = '회사, 모델, 와인딩크기';
  // public static ITEM_BRANDS_LIST_NAME_BEARING: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DRUM: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WIRE_STRAIN: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_TOW_CLIP: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_CURTAIN_OTHER: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_BOILER: string = '회사, 모델, 연료형태, 발열량, 연료소모량';
  // public static ITEM_BRANDS_LIST_NAME_HEATING_FAN: string = '회사, 모델, 연료형태, 발열량, 연료소모량, 범위';
  // public static ITEM_BRANDS_LIST_NAME_HEATING_TUBE: string = '회사, 모델, 연료소모량, 단면크기, 길이';

  // public static ITEM_BRANDS_LIST_NAME_NUTRIENT_SOLUTION: string = '회사, 모델';

  // public static ITEM_BRANDS_LIST_NAME_WATER_SOLUTION: string = '회사, 모델, 제어구역';
  // public static ITEM_BRANDS_LIST_NAME_WATER_TANK: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATER_TANK_FITTING: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATER_PUMP: string = '회사, 모델, 마력, 변압형태, 입출구지름';
  // public static ITEM_BRANDS_LIST_NAME_WATER_PUMP_CONNECT: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATER_FILTER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATERING_PIPE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATERING_CONNECT: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATERING_VALVE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATERING_HANGER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATERING_TUBE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATERING_TUBE_NIPPLE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_MINI_SPRINKLER: string = '회사, 모델, 살수형태, 살수량, 살수직경';
  // public static ITEM_BRANDS_LIST_NAME_MINI_SPRINKLER_DROP_GUARD: string = '회사, 모델';
  // public static ITEM_BRANDS_LIST_NAME_MINI_SPRINKLER_TUBE_SET: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DRIP_PIPE: string = '회사, 모델, 두께, 점적간격, 길이, 살수량';
  // public static ITEM_BRANDS_LIST_NAME_DRIP_BUTTON: string = '회사, 모델, 살수형태, 살수량';
  // public static ITEM_BRANDS_LIST_NAME_DRIP_BUTTON_APPENDAGE: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_DRIP_BARB: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_FOUNTAIN_HOSE: string = '회사, 모델, 형태, 지름, 살수폭';
  // public static ITEM_BRANDS_LIST_NAME_FOUNTAIN_HOSE_CONNECT: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_FOUNTAIN_HOSE_HANGER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATERING_TOOL: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_WATERING_OTHER: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_CARRIER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_GROUND_CARRIER: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_RAIL_HOOK: string = '회사';
  // public static ITEM_BRANDS_LIST_NAME_GROUND_RAIL_INTERVAL: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_CORD_WIRE: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_CUSTOM: string = '';

  // public static ITEM_BRANDS_LIST_NAME_ADDITIONAL_COST: string = '';
  // public static ITEM_BRANDS_LIST_NAME_DISCOUNT_COST: string = '';

  // public static ITEM_BRANDS_LIST_NAME_PIPE_BENDING_EXPENSE: string = '회사';

  // public static ITEM_BRANDS_LIST_NAME_EQUIPMENT_EXPENSE: string = '회사';

  // //----------------------------------
  // // 상품별 상표 레이블
  // // - CAT3과 동일하게 분류됨
  // //----------------------------------

  // public static ITEM_BRANDS_NAME_DEFAULT: string = '회사';

  // public static ITEM_BRANDS_NAME_PIPE: string = CONST.ITEM_BRANDS_LIST_NAME_PIPE;
  // public static ITEM_BRANDS_NAME_SUPPORT_PIPE: string = CONST.ITEM_BRANDS_LIST_NAME_SUPPORT_PIPE;

  // public static ITEM_BRANDS_NAME_CONNECTOR: string = CONST.ITEM_BRANDS_LIST_NAME_CONNECTOR;
  // public static ITEM_BRANDS_NAME_HOLDER: string = CONST.ITEM_BRANDS_LIST_NAME_HOLDER;
  // public static ITEM_BRANDS_NAME_SCONCE: string = CONST.ITEM_BRANDS_LIST_NAME_SCONCE;
  // public static ITEM_BRANDS_NAME_DIAMOND_CLAMP: string = CONST.ITEM_BRANDS_LIST_NAME_DIAMOND_CLAMP;
  // public static ITEM_BRANDS_NAME_DOOR: string = CONST.ITEM_BRANDS_LIST_NAME_DOOR;
  // public static ITEM_BRANDS_NAME_DOOR_MADE: string = CONST.ITEM_BRANDS_LIST_NAME_DOOR_MADE;
  // public static ITEM_BRANDS_NAME_HINGED_DOOR: string = CONST.ITEM_BRANDS_LIST_NAME_HINGED_DOOR;
  // public static ITEM_BRANDS_NAME_ROLLER: string = CONST.ITEM_BRANDS_LIST_NAME_ROLLER;
  // public static ITEM_BRANDS_NAME_CEMENT: string = CONST.ITEM_BRANDS_LIST_NAME_CEMENT;
  // public static ITEM_BRANDS_NAME_SCREW: string = CONST.ITEM_BRANDS_LIST_NAME_SCREW;
  // public static ITEM_BRANDS_NAME_FRAME_OTHER: string = CONST.ITEM_BRANDS_LIST_NAME_FRAME_OTHER;

  // public static ITEM_BRANDS_NAME_IRON_PLATE_GUTTER: string = CONST.ITEM_BRANDS_LIST_NAME_IRON_PLATE_GUTTER;
  // public static ITEM_BRANDS_NAME_PLASTIC_GUTTER: string = CONST.ITEM_BRANDS_LIST_NAME_PLASTIC_GUTTER;
  // public static ITEM_BRANDS_NAME_UBAR: string = CONST.ITEM_BRANDS_LIST_NAME_UBAR;
  // public static ITEM_BRANDS_NAME_GUTTER_HANGER: string = CONST.ITEM_BRANDS_LIST_NAME_UBAR;
  // public static ITEM_BRANDS_NAME_DRAIN_PIPE: string = CONST.ITEM_BRANDS_LIST_NAME_DRAIN_PIPE;
  // public static ITEM_BRANDS_NAME_PVC_CONNECTOR: string = CONST.ITEM_BRANDS_LIST_NAME_PVC_CONNECTOR;
  // public static ITEM_BRANDS_NAME_DRAINSPOUT_BOX: string = CONST.ITEM_BRANDS_LIST_NAME_DRAINSPOUT_BOX;
  // public static ITEM_BRANDS_NAME_DRAIN_PIPE_HANGER: string = CONST.ITEM_BRANDS_LIST_NAME_DRAIN_PIPE_HANGER;

  // public static ITEM_BRANDS_NAME_MANUAL_SWITCHER: string = CONST.ITEM_BRANDS_LIST_NAME_MANUAL_SWITCHER;
  // public static ITEM_BRANDS_NAME_POWER_SWITCHER: string = CONST.ITEM_BRANDS_LIST_NAME_POWER_SWITCHER;
  // public static ITEM_BRANDS_NAME_ELECTRIC_WIRE: string = CONST.ITEM_BRANDS_LIST_NAME_ELECTRIC_WIRE;
  // public static ITEM_BRANDS_NAME_SWITCHER_ASSIST: string = CONST.ITEM_BRANDS_LIST_NAME_SWITCHER_ASSIST;
  // public static ITEM_BRANDS_NAME_INTEGRATED_CONTROLLER: string = CONST.ITEM_BRANDS_LIST_NAME_INTEGRATED_CONTROLLER;
  // public static ITEM_BRANDS_NAME_CONTROLLER_SENSOR: string = CONST.ITEM_BRANDS_LIST_NAME_CONTROLLER_SENSOR;
  // public static ITEM_BRANDS_NAME_SWITCHER_OTHER: string = CONST.ITEM_BRANDS_LIST_NAME_SWITCHER_OTHER;

  // public static ITEM_BRANDS_NAME_VINYL: string = CONST.ITEM_BRANDS_LIST_NAME_VINYL + ', 비중';
  // public static ITEM_BRANDS_NAME_WEAVING_FILM: string = CONST.ITEM_BRANDS_LIST_NAME_WEAVING_FILM;
  // public static ITEM_BRANDS_NAME_SHADENET: string = CONST.ITEM_BRANDS_LIST_NAME_SHADENET;
  // public static ITEM_BRANDS_NAME_TENT: string = CONST.ITEM_BRANDS_LIST_NAME_TENT;
  // public static ITEM_BRANDS_NAME_NONWOVEN: string = CONST.ITEM_BRANDS_LIST_NAME_NONWOVEN;
  // public static ITEM_BRANDS_NAME_CASHMILON: string = CONST.ITEM_BRANDS_LIST_NAME_CASHMILON;
  // public static ITEM_BRANDS_NAME_LAGGING: string = CONST.ITEM_BRANDS_LIST_NAME_LAGGING;
  // public static ITEM_BRANDS_NAME_QUILTING: string = CONST.ITEM_BRANDS_LIST_NAME_QUILTING;
  // public static ITEM_BRANDS_NAME_SCREEN: string = CONST.ITEM_BRANDS_LIST_NAME_SCREEN;
  // public static ITEM_BRANDS_NAME_MAT: string = CONST.ITEM_BRANDS_LIST_NAME_MAT;
  // public static ITEM_BRANDS_NAME_SCREENNET: string = CONST.ITEM_BRANDS_LIST_NAME_SCREENNET;

  // public static ITEM_BRANDS_NAME_PAD: string = CONST.ITEM_BRANDS_LIST_NAME_PAD;
  // public static ITEM_BRANDS_NAME_PAD_CONNECTOR: string = CONST.ITEM_BRANDS_LIST_NAME_PAD_CONNECTOR;
  // public static ITEM_BRANDS_NAME_PAD_SPRING: string = CONST.ITEM_BRANDS_LIST_NAME_PAD_SPRING;
  // public static ITEM_BRANDS_NAME_PAD_VINYL: string = CONST.ITEM_BRANDS_LIST_NAME_PAD_VINYL;
  // public static ITEM_BRANDS_NAME_CLIP: string = CONST.ITEM_BRANDS_LIST_NAME_CLIP;
  // public static ITEM_BRANDS_NAME_WOOD: string = CONST.ITEM_BRANDS_LIST_NAME_WOOD;
  // public static ITEM_BRANDS_NAME_BANDSTRING: string = CONST.ITEM_BRANDS_LIST_NAME_BANDSTRING;
  // public static ITEM_BRANDS_NAME_STEEL_WIRE: string = CONST.ITEM_BRANDS_LIST_NAME_STEEL_WIRE;

  // public static ITEM_BRANDS_NAME_VENTILATOR: string = CONST.ITEM_BRANDS_LIST_NAME_VENTILATOR;
  // public static ITEM_BRANDS_NAME_VENT_FAN: string = CONST.ITEM_BRANDS_LIST_NAME_VENT_FAN;
  // public static ITEM_BRANDS_NAME_VENT_HOLE: string = CONST.ITEM_BRANDS_LIST_NAME_VENT_HOLE;
  // public static ITEM_BRANDS_NAME_VENT_HOLE_SWITCH_SET: string = CONST.ITEM_BRANDS_LIST_NAME_VENT_HOLE_SWITCH_SET;
  // public static ITEM_BRANDS_NAME_AIR_FLOW_FAN: string = CONST.ITEM_BRANDS_LIST_NAME_AIR_FLOW_FAN;

  // public static ITEM_BRANDS_NAME_REDUCED_MOTOR: string = CONST.ITEM_BRANDS_LIST_NAME_REDUCED_MOTOR;
  // public static ITEM_BRANDS_NAME_BEARING: string = CONST.ITEM_BRANDS_LIST_NAME_BEARING;
  // public static ITEM_BRANDS_NAME_DRUM: string = CONST.ITEM_BRANDS_LIST_NAME_DRUM;
  // public static ITEM_BRANDS_NAME_WIRE_STRAIN: string = CONST.ITEM_BRANDS_LIST_NAME_WIRE_STRAIN;
  // public static ITEM_BRANDS_NAME_TOW_CLIP: string = CONST.ITEM_BRANDS_LIST_NAME_TOW_CLIP;
  // public static ITEM_BRANDS_NAME_CURTAIN_OTHER: string = CONST.ITEM_BRANDS_LIST_NAME_CURTAIN_OTHER;

  // public static ITEM_BRANDS_NAME_BOILER: string = CONST.ITEM_BRANDS_LIST_NAME_BOILER;
  // public static ITEM_BRANDS_NAME_HEATING_FAN: string = CONST.ITEM_BRANDS_LIST_NAME_HEATING_FAN;
  // public static ITEM_BRANDS_NAME_HEATING_TUBE: string = CONST.ITEM_BRANDS_LIST_NAME_HEATING_TUBE;

  // public static ITEM_BRANDS_NAME_NUTRIENT_SOLUTION: string = CONST.ITEM_BRANDS_LIST_NAME_NUTRIENT_SOLUTION;

  // public static ITEM_BRANDS_NAME_WATER_SOLUTION: string = CONST.ITEM_BRANDS_LIST_NAME_WATER_SOLUTION;
  // public static ITEM_BRANDS_NAME_WATER_TANK: string = CONST.ITEM_BRANDS_LIST_NAME_WATER_TANK;
  // public static ITEM_BRANDS_NAME_WATER_TANK_FITTING: string = CONST.ITEM_BRANDS_LIST_NAME_WATER_TANK_FITTING;
  // public static ITEM_BRANDS_NAME_WATER_PUMP: string = CONST.ITEM_BRANDS_LIST_NAME_WATER_PUMP;
  // public static ITEM_BRANDS_NAME_WATER_PUMP_CONNECT: string = CONST.ITEM_BRANDS_LIST_NAME_WATER_PUMP_CONNECT;
  // public static ITEM_BRANDS_NAME_WATER_FILTER: string = CONST.ITEM_BRANDS_LIST_NAME_WATER_FILTER;
  // public static ITEM_BRANDS_NAME_WATERING_PIPE: string = CONST.ITEM_BRANDS_LIST_NAME_WATERING_PIPE;
  // public static ITEM_BRANDS_NAME_WATERING_CONNECT: string = CONST.ITEM_BRANDS_LIST_NAME_WATERING_CONNECT;
  // public static ITEM_BRANDS_NAME_WATERING_VALVE: string = CONST.ITEM_BRANDS_LIST_NAME_WATERING_VALVE;
  // public static ITEM_BRANDS_NAME_WATERING_HANGER: string = CONST.ITEM_BRANDS_LIST_NAME_WATERING_HANGER;
  // public static ITEM_BRANDS_NAME_WATERING_TUBE: string = CONST.ITEM_BRANDS_LIST_NAME_WATERING_TUBE;
  // public static ITEM_BRANDS_NAME_WATERING_TUBE_NIPPLE: string = CONST.ITEM_BRANDS_LIST_NAME_WATERING_TUBE_NIPPLE;
  // public static ITEM_BRANDS_NAME_MINI_SPRINKLER: string = CONST.ITEM_BRANDS_LIST_NAME_MINI_SPRINKLER;
  // public static ITEM_BRANDS_NAME_MINI_SPRINKLER_DROP_GUARD: string = CONST.ITEM_BRANDS_LIST_NAME_MINI_SPRINKLER_DROP_GUARD;
  // public static ITEM_BRANDS_NAME_MINI_SPRINKLER_TUBE_SET: string = CONST.ITEM_BRANDS_LIST_NAME_MINI_SPRINKLER_TUBE_SET;
  // public static ITEM_BRANDS_NAME_DRIP_PIPE: string = CONST.ITEM_BRANDS_LIST_NAME_DRIP_PIPE;
  // public static ITEM_BRANDS_NAME_DRIP_BUTTON: string = CONST.ITEM_BRANDS_LIST_NAME_DRIP_BUTTON;
  // public static ITEM_BRANDS_NAME_DRIP_BUTTON_APPENDAGE: string = CONST.ITEM_BRANDS_LIST_NAME_DRIP_BUTTON_APPENDAGE;
  // public static ITEM_BRANDS_NAME_DRIP_BARB: string = CONST.ITEM_BRANDS_LIST_NAME_DRIP_BARB;
  // public static ITEM_BRANDS_NAME_FOUNTAIN_HOSE: string = CONST.ITEM_BRANDS_LIST_NAME_FOUNTAIN_HOSE + ', 재고길이';
  // public static ITEM_BRANDS_NAME_FOUNTAIN_HOSE_CONNECT: string = CONST.ITEM_BRANDS_LIST_NAME_FOUNTAIN_HOSE_CONNECT;
  // public static ITEM_BRANDS_NAME_FOUNTAIN_HOSE_HANGER: string = CONST.ITEM_BRANDS_LIST_NAME_FOUNTAIN_HOSE_HANGER;
  // public static ITEM_BRANDS_NAME_WATERING_TOOL: string = CONST.ITEM_BRANDS_LIST_NAME_WATERING_TOOL;
  // public static ITEM_BRANDS_NAME_WATERING_OTHER: string = CONST.ITEM_BRANDS_LIST_NAME_WATERING_OTHER;

  // public static ITEM_BRANDS_NAME_CARRIER: string = CONST.ITEM_BRANDS_NAME_DEFAULT;
  // public static ITEM_BRANDS_NAME_GROUND_CARRIER: string = CONST.ITEM_BRANDS_NAME_DEFAULT;
  // public static ITEM_BRANDS_NAME_RAIL_HOOK: string = CONST.ITEM_BRANDS_NAME_DEFAULT;
  // public static ITEM_BRANDS_NAME_GROUND_RAIL_INTERVAL: string = CONST.ITEM_BRANDS_NAME_DEFAULT;

  // public static ITEM_BRANDS_NAME_CORD_WIRE: string = CONST.ITEM_BRANDS_LIST_NAME_CORD_WIRE;

  // public static ITEM_BRANDS_NAME_CUSTOM: string = CONST.ITEM_BRANDS_LIST_NAME_CUSTOM;

  // public static ITEM_BRANDS_NAME_ADDITIONAL_COST: string = CONST.ITEM_BRANDS_LIST_NAME_ADDITIONAL_COST;
  // public static ITEM_BRANDS_NAME_DISCOUNT_COST: string = CONST.ITEM_BRANDS_LIST_NAME_DISCOUNT_COST;

  // public static ITEM_BRANDS_NAME_PIPE_BENDING_EXPENSE: string = CONST.ITEM_BRANDS_LIST_NAME_PIPE_BENDING_EXPENSE;

  // public static ITEM_BRANDS_NAME_EQUIPMENT_EXPENSE: string = CONST.ITEM_BRANDS_LIST_NAME_EQUIPMENT_EXPENSE;

  //----------------------------------
  // 규격 레이블
  //----------------------------------

  public static ITEM_SPEC_NAME_PIPE_TYPE: string = "파이프형태";
  public static ITEM_SPEC_NAME_MATERIAL: string = "재질";

  //----------------------------------
  // 규격 앞첨자
  //----------------------------------

  public static ITEM_SPEC_PREFIX_COMBINE: string = "통합";
  public static ITEM_SPEC_PREFIX_VINYL: string = "비닐";
  public static ITEM_SPEC_PREFIX_NONWOVEN: string = "부직";
  public static ITEM_SPEC_PREFIX_CURTAIN: string = "커튼";
  public static ITEM_SPEC_PREFIX_VENT: string = "환기";
  public static ITEM_SPEC_PREFIX_WATERING: string = "관수";
  public static ITEM_SPEC_PREFIX_OTHER_AC: string = "기타AC";

  //----------------------------------
  // 규격 값
  //----------------------------------

  public static ITEM_SPEC_VALUE_NORMAL: string = "일반";
  public static ITEM_SPEC_VALUE_NONE: string = "-";
  public static ITEM_SPEC_VALUE_BOX: string = "박스";
  public static ITEM_SPEC_VALUE_ROLLING_ROD: string = "말대(치마)";
  public static ITEM_SPEC_VALUE_SPREAD: string = "편";
  public static ITEM_SPEC_VALUE_ONE_BURST: string = "일타";
  public static ITEM_SPEC_VALUE_BOTH_BURST: string = "양타";
  public static ITEM_SPEC_VALUE_ONE_STRING: string = "한끈";
  public static ITEM_SPEC_VALUE_BOTH_STRING: string = "양끈";
  public static ITEM_SPEC_VALUE_STRING_NONE: string = "끈 없음";
  public static ITEM_SPEC_VALUE_END_SEWING: string = "끝미싱";
  public static ITEM_SPEC_VALUE_END_SEWING_NONE: string = "끝미싱 없음";
  public static ITEM_SPEC_VALUE_VINYL_USAGE: string = "비닐용";
  public static ITEM_SPEC_VALUE_NONWOVEN_USAGE: string = "부직포용";
  public static ITEM_SPEC_VALUE_LAGGING_USAGE: string = "보온덮개용";
  public static ITEM_SPEC_VALUE_LAGGING_QUILTING: string = "담요용";
  public static ITEM_SPEC_VALUE_SKYLIGHT_USAGE: string = "천창용";
  public static ITEM_SPEC_VALUE_SINGLE_PHASE: string = "단상";
  public static ITEM_SPEC_VALUE_THREE_PHASE: string = "삼상";
  public static ITEM_SPEC_VALUE_POWER: string = "동력";
  public static ITEM_SPEC_VALUE_AUTO: string = "자동";
  public static ITEM_SPEC_VALUE_MANUAL: string = "수동";
  public static ITEM_SPEC_VALUE_NO_POWER: string = "무동력";
  public static ITEM_SPEC_VALUE_HOJU_TYPE: string = "호주식";
  public static ITEM_SPEC_VALUE_CLAMP_TYPE: string = "클래프식";
  public static ITEM_SPEC_VALUE_FLAT_HOLDER: string = "평금구";
  public static ITEM_SPEC_VALUE_CIRCLE_TYPE: string = "원형";
  public static ITEM_SPEC_VALUE_ANGLE_TYPE: string = "각형";
  public static ITEM_SPEC_VALUE_FREE_TYPE: string = "자유형";
  public static ITEM_SPEC_VALUE_13_MM: string = "13mm";
  public static ITEM_SPEC_VALUE_16_MM: string = "16mm";
  public static ITEM_SPEC_VALUE_20_MM: string = "20mm";
  public static ITEM_SPEC_VALUE_22_MM: string = "22mm";
  public static ITEM_SPEC_VALUE_25_MM: string = "25mm";
  public static ITEM_SPEC_VALUE_30_MM: string = "30mm";
  public static ITEM_SPEC_VALUE_32_MM: string = "32mm";
  public static ITEM_SPEC_VALUE_40_MM: string = "40mm";
  public static ITEM_SPEC_VALUE_42_MM: string = "42mm";
  public static ITEM_SPEC_VALUE_48_MM: string = "48mm";
  public static ITEM_SPEC_VALUE_50_MM: string = "50mm";
  public static ITEM_SPEC_VALUE_60_MM: string = "60mm";
  public static ITEM_SPEC_VALUE_65_MM: string = "65mm";
  public static ITEM_SPEC_VALUE_75_MM: string = "75mm";
  public static ITEM_SPEC_VALUE_100_MM: string = "100mm";
  public static ITEM_SPEC_VALUE_22_MM_PIPE: string = "22.2mm";
  public static ITEM_SPEC_VALUE_25_MM_PIPE: string = "25.4mm";
  public static ITEM_SPEC_VALUE_32_MM_PIPE: string = "31.8mm";
  public static ITEM_SPEC_VALUE_33_MM_PIPE: string = "33.5mm";
  public static ITEM_SPEC_VALUE_42_MM_PIPE: string = "42.2mm";
  public static ITEM_SPEC_VALUE_48_MM_PIPE: string = "48.1mm";
  public static ITEM_SPEC_VALUE_60_MM_PIPE: string = "59.9mm";
  public static ITEM_SPEC_VALUE_25_25_MM: string = "25×25mm";
  public static ITEM_SPEC_VALUE_30_30_MM: string = "30×30mm";
  public static ITEM_SPEC_VALUE_40_20_MM: string = "40×20mm";
  public static ITEM_SPEC_VALUE_40_40_MM: string = "40×40mm";
  public static ITEM_SPEC_VALUE_50_30_MM: string = "50×30mm";
  public static ITEM_SPEC_VALUE_60_30_MM: string = "60×30mm";
  public static ITEM_SPEC_VALUE_60_60_MM: string = "60×60mm";
  public static ITEM_SPEC_VALUE_75_45_MM: string = "75×45mm";
  public static ITEM_SPEC_VALUE_75_75_MM: string = "75×75mm";
  public static ITEM_SPEC_VALUE_100_50_MM: string = "100×50mm";
  public static ITEM_SPEC_VALUE_100_100_MM: string = "100×100mm";
  public static ITEM_SPEC_VALUE_125_75_MM: string = "125×75mm";
  public static ITEM_SPEC_VALUE_125_125_MM: string = "125×125mm";
  public static ITEM_SPEC_VALUE_40_A: string = "40A";
  public static ITEM_SPEC_VALUE_1_2_T: string = "1.2T";
  public static ITEM_SPEC_VALUE_1_4_T: string = "1.4T";
  public static ITEM_SPEC_VALUE_1_5_T: string = "1.5T";
  public static ITEM_SPEC_VALUE_1_7_T: string = "1.7T";
  public static ITEM_SPEC_VALUE_1_8_T: string = "1.8T";
  public static ITEM_SPEC_VALUE_1_9_T: string = "1.9T";
  public static ITEM_SPEC_VALUE_2_1_T: string = "2.1T";
  public static ITEM_SPEC_VALUE_2_3_T: string = "2.3T";
  public static ITEM_SPEC_VALUE_2_9_T: string = "2.9T";
  public static ITEM_SPEC_VALUE_3_2_T: string = "3.2T";
  public static ITEM_SPEC_VALUE_0_M: string = "0m";
  public static ITEM_SPEC_VALUE_16_M: string = "16m";
  public static ITEM_SPEC_VALUE_17_M: string = "17m";
  public static ITEM_SPEC_VALUE_AZM: string = "AZM";
  public static ITEM_SPEC_VALUE_BENDING: string = "벤딩";
  public static ITEM_SPEC_VALUE_PINNED: string = "핀가공";
  public static ITEM_SPEC_VALUE_AXIS: string = "축가공";
  public static ITEM_SPEC_VALUE_PAD_WORKED: string = "패드가공";
  public static ITEM_SPEC_VALUE_DRUM_TYPE: string = "드럼식";
  public static ITEM_SPEC_VALUE_CROSS: string = "십자";
  public static ITEM_SPEC_VALUE_HEXAGON: string = "육각";
  public static ITEM_SPEC_VALUE_STAINLESS: string = "스텐레스";
  public static ITEM_SPEC_VALUE_ZINC: string = "아연";
  public static ITEM_SPEC_VALUE_COLOR_IRON: string = "칼라 철";
  public static ITEM_SPEC_VALUE_ALUMINUM: string = "알루미늄";
  public static ITEM_SPEC_VALUE_CORROSION: string = "고내식";
  public static ITEM_SPEC_VALUE_PLASTIC: string = "플라스틱";
  public static ITEM_SPEC_VALUE_WIRE_RING: string = "철사고리";
  public static ITEM_SPEC_VALUE_DUCK_RING: string = "오리고리";
  public static ITEM_SPEC_VALUE_BOTH_SIDE: string = "양측";
  public static ITEM_SPEC_VALUE_ONE_SIDE: string = "편측";
  public static ITEM_SPEC_VALUE_WATER_SCREEN_BOTH_SIDE: string = "수막양면";
  public static ITEM_SPEC_VALUE_WATER_SCREEN_ONE_SIDE: string = "수막편측";
  public static ITEM_SPEC_VALUE_2_LITER_PER_HOUR: string = "2L/H";
  public static ITEM_SPEC_VALUE_4_LITER_PER_HOUR: string = "4L/H";
  public static ITEM_SPEC_VALUE_8_LITER_PER_HOUR: string = "8L/H";
  public static ITEM_SPEC_VALUE_1_HOLE: string = "1구";
  public static ITEM_SPEC_VALUE_2_HOLE: string = "2구";
  public static ITEM_SPEC_VALUE_4_HOLE: string = "4구";
  public static ITEM_SPEC_VALUE_3_SEP_5_MM: string = "3/5mm";
  public static ITEM_SPEC_VALUE_4_SEP_7_MM: string = "3/5mm";
  public static ITEM_SPEC_VALUE_5_SEP_8_MM: string = "3/5mm";
  public static ITEM_SPEC_VALUE_DIRECT_WATERING: string = "직접살수";
  public static ITEM_SPEC_VALUE_TUBE_WATERING: string = "튜브살수";
  public static ITEM_SPEC_VALUE_DROPPER_WATERING: string = "드롭퍼살수";
  public static ITEM_SPEC_VALUE_SPINNET: string = "스핀넷";
  public static ITEM_SPEC_VALUE_SPINNET_TYPE: string = "스핀넷형";
  public static ITEM_SPEC_VALUE_UD: string = "UD";
  public static ITEM_SPEC_VALUE_UD_TYPE: string = "UD형";
  public static ITEM_SPEC_VALUE_SMALL: string = "소";
  public static ITEM_SPEC_VALUE_MIDDLE_SMALL: string = "중소";
  public static ITEM_SPEC_VALUE_MIDDLE: string = "중";
  public static ITEM_SPEC_VALUE_MIDDLE_LARGE: string = "중대";
  public static ITEM_SPEC_VALUE_LARGE: string = "대";
  public static ITEM_SPEC_VALUE_SINGLE_DOOR: string = "외문";
  public static ITEM_SPEC_VALUE_DOUBLE_DOOR: string = "쌍문";
  public static ITEM_SPEC_VALUE_SINGLE_SCONCE: string = "외꽂이";
  public static ITEM_SPEC_VALUE_DOUBLE_SCONCE: string = "쌍꽂이";
  public static ITEM_SPEC_VALUE_CHAIN: string = "체인";

  //----------------------------------
  // 상표 값
  //----------------------------------

  public static ITEM_BRAND_VALUE_DEFAULT: string = "기본회사";
  public static ITEM_BRAND_VALUE_NONE: string = "해당 없음";
  public static ITEM_BRAND_VALUE_NETAPIM: string = "네타핌";
  public static ITEM_BRAND_VALUE_DAN: string = "DAN";

  //----------------------------------
  // 기타
  //----------------------------------

  // 폭미싱 간격
  public static ITEM_VALUE_WIDTH_SEWING_INTERVAL_GUTTER_COVER: number = 400;
  public static ITEM_VALUE_WIDTH_SEWING_INTERVAL_SHADENET: number = 9;
  public static ITEM_VALUE_WIDTH_SEWING_INTERVAL_TENT: number = 2;
  public static ITEM_VALUE_WIDTH_SEWING_INTERVAL_NONWOVEN: number = 4;
  public static ITEM_VALUE_WIDTH_SEWING_INTERVAL_QUILTING: number = 5;
  public static ITEM_VALUE_WIDTH_SEWING_INTERVAL_SCREEN: number = 4;

  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 기타
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 아이디
  //----------------------------------

  // 통신 방식
  public static ID_SIGNAL_DESIGN_SAVE: number = 0;
  public static ID_SIGNAL_DESIGN_SAVE_AS: number = 1;
  public static ID_SIGNAL_DESIGN_SAVE_AND_EXIT: number = 2;
  public static ID_SIGNAL_DESIGN_SAVE_AND_BLUEPRINT: number = 3;
  public static ID_SIGNAL_DESIGN_SAVE_AND_SPECIFICATION: number = 4;
  public static ID_SIGNAL_DESIGN_SAVE_AND_MATERIAL: number = 5;

  public static ID_SIGNAL_EXPERT_SIGNUP: number = 0;
  public static ID_SIGNAL_EXPERT_CREATE: number = 1;
  public static ID_SIGNAL_EXPERT_UPDATE: number = 2;

  public static ID_SIGNAL_SIMEST_CREATE: number = 0;
  public static ID_SIGNAL_SIMEST_UPDATE: number = 1;

  public static ID_SIGNAL_STARTUP_CREATE: number = 0;
  public static ID_SIGNAL_STARTUP_UPDATE: number = 1;

  // 드랍다운 버튼
  public static ID_DROPDOWN_DELETE: number = 100;
  public static ID_DROPDOWN_SECTION_ESTIMATE: number = 101;
  public static ID_DROPDOWN_SECTION_REVIEW: number = 106;
  public static ID_DROPDOWN_BATCH_APPLY: number = 102;

  public static ID_DROPDOWN_ADD_ITEM_CONSTRUCTION_TOTAL: number = 103;
  public static ID_DROPDOWN_ADD_ITEM_CONSTRUCTION_TYPE: number = 104;
  public static ID_DROPDOWN_ADD_ITEM_CONSTRUCTION_NAME: number = 105;

  // 메뉴
  public static ID_MENU_FILE: string = "file";
  public static ID_MENU_OPEN: string = "open";
  public static ID_MENU_SAVE: string = "save";
  public static ID_MENU_SAVE_AS: string = "save_as";
  public static ID_MENU_CUSTOMER_REG: string = "customer_reg";
  public static ID_MENU_CUSTOMER_DEL: string = "customer_del";
  public static ID_MENU_DELETE: string = "delete";
  public static ID_MENU_EDIT: string = "edit";
  public static ID_MENU_REDO: string = "redo";
  public static ID_MENU_UNDO: string = "undo";
  public static ID_MENU_VIEW: string = "view";
  public static ID_MENU_BASIC_INFO: string = "basic_info";
  public static ID_MENU_ESTIMATE: string = "estimate";
  public static ID_MENU_BLUEPRINT: string = "blueprint";
  public static ID_MENU_SPECIFICATION: string = "specification";
  public static ID_MENU_MATERIAL: string = "material";
  public static ID_MENU_DESIGN_DETAIL: string = "design_detail";
  public static ID_MENU_HELP: string = "help";
  public static ID_MENU_PREFERENCE: string = "preference";
  public static ID_MENU_INFO: string = "info";

  // 뷰 컴포넌트 타입
  public static ID_VIEW_TYPE_GROUP: number = 1;
  public static ID_VIEW_TYPE_GROUP_SWITCH: number = 2;
  public static ID_VIEW_TYPE_SELECT_BOX: number = 11;
  public static ID_VIEW_TYPE_NUMBER_BOX: number = 12;
  public static ID_VIEW_TYPE_CHECK_BOX: number = 13;
  public static ID_VIEW_TYPE_RADIO_GROUP: number = 14;
  public static ID_VIEW_TYPE_TEXT_BOX: number = 15;
  public static ID_VIEW_TYPE_CUSTOM_NUMERIC_DOUBLE: number = 101;
  public static ID_VIEW_TYPE_CUSTOM_AREA_PYEONG: number = 102;
  public static ID_VIEW_TYPE_POSITION_SWITCH_ORDER: number = 201;
  public static ID_VIEW_TYPE_POSITION_SWITCHER_STATE: number = 202;
  public static ID_VIEW_TYPE_POSITION_CURTAIN_MOTOR_STATE: number = 203;
  public static ID_VIEW_TYPE_POSITION_OTHER_EQUIPMENT_STATE: number = 204;
  public static ID_VIEW_TYPE_POSITION_ESTIMATE_BUILDING_COST: number = 205;
  public static ID_VIEW_TYPE_PART_ITEM: number = 301;
  public static ID_VIEW_TYPE_PART_COVER_SELECT: number = 302;
  public static ID_VIEW_TYPE_SPEC_CUSTOM: number = 401;
  public static ID_VIEW_TYPE_SPEC_DEFAULT: number = 402;
  public static ID_VIEW_TYPE_SPEC_AXISCOUPLING_SUPPORTSIZE: number = 403;
  public static ID_VIEW_TYPE_SPEC_IN_OUT_EXIT: number = 404;
  public static ID_VIEW_TYPE_SPEC_INTEGRATED_CONTROLLER: number = 405;
  public static ID_VIEW_TYPE_SPEC_LENGTH: number = 406;
  public static ID_VIEW_TYPE_SPEC_LENGTH_BENDING: number = 407;
  public static ID_VIEW_TYPE_SPEC_LENGTH_NIPPLETYPE_CONNECTIONTYPE: number = 408;
  public static ID_VIEW_TYPE_SPEC_PERCENT: number = 409;
  public static ID_VIEW_TYPE_SPEC_ROLLERINTERVAL: number = 410;
  public static ID_VIEW_TYPE_SPEC_ROLLERINTERVAL_SIZE: number = 411;
  public static ID_VIEW_TYPE_SPEC_ROLLERSIZE: number = 412;
  public static ID_VIEW_TYPE_SPEC_SHUTTER: number = 413;
  public static ID_VIEW_TYPE_SPEC_SWITCHER_ASSIST: number = 414;
  public static ID_VIEW_TYPE_SPEC_WIDTH_LENGTH: number = 415;
  public static ID_VIEW_TYPE_SPEC_WIDTH_LENGTH_PUNCH: number = 416;
  public static ID_VIEW_TYPE_SPEC_WIDTH_LENGTH_STRINGTYPE: number = 417;
  public static ID_VIEW_TYPE_SPEC_WIDTH_LENGTH_STRINGTYPE_ENDSEWING_WIDTHSEWING: number = 418;
  public static ID_VIEW_TYPE_SPEC_WIDTH_LENGTH_STRINGTYPE_WIDTHSEWING: number = 419;
  public static ID_VIEW_TYPE_SPEC_WIDTH_LENGTH_WIDTHSEWING: number = 420;
  public static ID_VIEW_TYPE_SPEC_WIDTH_LENGTH_WIDTHTYPE_PACKINGTYPE_STRINGTYPE: number = 421;

  //----------------------------------
  // 레이블
  //----------------------------------

  //----------------------------------
  // 수치
  //----------------------------------

  // 버튼
  public static NUM_BUTTON_WIDTH_2_WORDS: number = 50;
  public static NUM_BUTTON_WIDTH_3_WORDS: number = 62;
  public static NUM_BUTTON_HEIGHT: number = 30;
  public static NUM_LEVEL_TAB_BUTTON_OFFSET_INIT: number = 220; // 중수 탭버튼 옵셋 초기값

  // 문자열 길이
  public static NUM_TEXT_LENGTH_TITLE: number = 30; // 제목
  public static NUM_TEXT_LENGTH_TEMPLATE_NAME: number = 30; // 템플릿명
  public static NUM_TEXT_LENGTH_TEMPLATE_EXPLAIN: number = 255; // 템플릿 설명
  public static NUM_TEXT_LENGTH_HOUSE_USAGE_AND_CROP: number = 30; // 용도/작물
  public static NUM_TEXT_LENGTH_MARK_TYPE: number = 30; // 표기 형식
  public static NUM_TEXT_LENGTH_NOTE: number = 2000; // 노트
  public static NUM_TEXT_LENGTH_CUSTOM_PRODUCT_NAME: number = 30; // 임의 상품명
  public static NUM_TEXT_LENGTH_CUSTOM_SPECS: number = 50; // 임의 상품 규격
  public static NUM_TEXT_LENGTH_CUSTOM_PURPOSE: number = 100; // 임의 상품 용도
  public static NUM_TEXT_LENGTH_WORK_BUILD_NOTE: number = 1000; // 작업의 시공방법
  public static NUM_TEXT_LENGTH_ITEM_BUILD_NOTE: number = 200; // 아이템의 시공방법
  public static NUM_TEXT_LENGTH_ESTIMATE_UPPER_MEMO: number = 60; // 견적서 상단 메모

  //----------------------------------
  // 색상
  //----------------------------------

  public static COLOR_DEFAULT: number = 0x000000; // 검정
  public static COLOR_ERROR: number = 0xff0000; // 빨강

  //----------------------------------
  // 구분자
  //----------------------------------

  public static DELIMITER_SPEC: string = ", ";
  public static DELIMITER_BRAND: string = ", ";
  public static DELIMITER_CATEGORY: string = "> ";
  public static DELIMITER_NUMBER: string = ",";
  public static DELIMITER_COVER: string = ", ";
  public static DELIMITER_CUSTOM_PRODUCT: string = "*";
  public static DELIMITER_LIST: string = " - ";
  public static DELIMITER_PURPOSE: string = ", ";

  //----------------------------------
  // prefix
  //----------------------------------

  public static PREFIX_WIDTH: string = "폭";
  public static PREFIX_LENGTH: string = "길이";
  public static PREFIX_HEIGHT: string = "높이";

  //----------------------------------
  // 단위기호
  //----------------------------------

  public static UNIT_METER: string = "m";
  public static UNIT_CENTIMETER: string = "cm";
  public static UNIT_MILLIMETER: string = "mm";
  public static UNIT_METER_INTERVAL: string = "m간격";
  public static UNIT_CENTIMETER_INTERVAL: string = "cm간격";
  public static UNIT_MILLIMETER_INTERVAL: string = "mm간격";
  public static UNIT_SQUARE_METER: string = "㎡";
  public static UNIT_SQUARE_CENTIMETER: string = "㎠";
  public static UNIT_SQUARE_MILLIMETER: string = "㎟";
  public static UNIT_CUBIC_METER: string = "㎥";
  public static UNIT_CUBIC_CENTIMETER: string = "㎤";
  public static UNIT_CUBIC_MILLIMETER: string = "㎣";
  public static UNIT_GRAM: string = "g";
  public static UNIT_KILOGRAM: string = "kg";
  public static UNIT_MILLIGRAM: string = "mg";
  public static UNIT_THICKNESS: string = "T";
  public static UNIT_CALORY: string = "cal";
  public static UNIT_KILO_CALORY: string = "kcal";
  public static UNIT_KILO_CALORY_PER_HOUR: string = "kcal/h";
  public static UNIT_WATT: string = "W";
  public static UNIT_KILO_WATT: string = "kW";
  public static UNIT_KILO_WATT_PER_HOUR: string = "kW/h";
  public static UNIT_DGREE_CELSIUS: string = "℃";
  public static UNIT_EA: string = "개";
  public static UNIT_BOX: string = "박스";
  public static UNIT_CARTON: string = "곽";
  public static UNIT_SYMBOL_SET: string = "세트";
  public static UNIT_ROLL: string = "롤";
  public static UNIT_LINE: string = "줄";
  public static UNIT_TH: string = "회";
  public static UNIT_BUILDING: string = "동";
  public static UNIT_LEVEL: string = "중";
  public static UNIT_PYEONG: string = "평";
  public static UNIT_ORDER: string = "차";
  public static UNIT_PLASTIC_BAG: string = "봉";
  public static UNIT_PERCENT: string = "%";
  public static UNIT_WON: string = "원";
  public static UNIT_WON_PER_SQUARE_METER: string = "원/㎡";
  public static UNIT_WON_PER_PYEONG: string = "원/평";
  public static UNIT_PLACE: string = "곳";
  public static UNIT_DAYS: string = "일";
  public static UNIT_DAN: string = "단";
  public static UNIT_MULTIPLE: string = "배";
  public static UNIT_PAIR: string = "짝";
  public static UNIT_AREA: string = "구역";
  public static UNIT_SPACE: string = "칸";

  //----------------------------------
  // 정규표현식
  //----------------------------------

  public static REG_CRLF: RegExp = /\r\n/g;
  public static REG_CR: RegExp = /\r/g;

  //----------------------------------
  // 글 (정리해야함)
  //----------------------------------

  public static TEXT_WARNING: string = "주의";
  public static TEXT_ALERT: string = "경고!";
  public static TEXT_YES: string = "예";
  public static TEXT_NO: string = "아니오";
  public static TEXT_OK: string = "확인";
  public static TEXT_CANCEL: string = "취소";
  public static TEXT_NONE: string = "없음";
  public static TEXT_DESIGN: string = "설계";
  public static TEXT_TEMPLATE: string = "템플릿";
  public static TEXT_ESTIMATE: string = "견적";
  public static TEXT_SECTION_ESTIMATE: string = "부분견적";
  public static TEXT_VHOWS_SERVED: string = "브이하우스(vhows.com) 설계/견적 프로그램 제공";
  public static TEXT_SAVE_FAILED: string = "저장 실패";
  public static TEXT_CANNOT_ADD_COVERS: string = "피복을 더이상 추가할 수 없습니다.";
  public static TEXT_CANNOT_DELETE_COVERS: string = "피복을 더이상 삭제할 수 없습니다.";
  public static TEXT_DO_YOU_DELETE: string = "삭제하시겠습니까?";
  public static TEXT_DO_YOU_CONTINUE: string = "계속하시겠습니까?";
  public static TEXT_SELECT_STRUCTURE: string = "구조를 선택해주세요.";
  public static TEXT_CHANGE_STRUCTURE: string = `구조를 변경하면 모든 값이 초기화됩니다. ${CONST.TEXT_DO_YOU_CONTINUE}`;
  public static TEXT_SAVING: string = "저장 중입니다...";
  public static TEXT_SAVE_SUCCESS: string = "저장이 완료되었습니다.";
  public static TEXT_SAVE_FAIL: string = "저장에 실패했습니다.\n다시 시도해주세요.";
  public static TEXT_COMPLEATED_LOADING: string = "불러오기를 완료했습니다.";
  public static TEXT_CHECK_AND_COMPLETE_CONTRACT: string = "비용을 확인하고, 계약완료를 눌러주세요.";
  public static TEXT_CANNOT_EDIT_IN_STATUS: string = "상태에서는 수정을 할 수 없습니다.";
  public static TEXT_LOAD_FAIL: string = "불러오기에 실패했습니다.\n다시 시도해주세요.";
  public static TEXT_DATA_SETTING: string = "데이터 세팅 중입니다...";
  public static TEXT_DATA_SETTING_FAIL: string = "데이터 세팅에 실패했습니다.";
  public static TEXT_LOADING_DESIGN: string = "설계를 불러오는 중입니다...";
  public static TEXT_LOADING_DESIGN_TEMPLATE: string = "설계 템플릿을 불러오는 중입니다...";
  public static TEXT_EXCEED_MAX_TEMPLATE_COUNT: string = "최대 템플릿 생성 개수를 초과하여 등록할 수 없습니다.";
  public static TEXT_SAVING_INITIAL_DESIGN: string = "최초 설계를 저장 중입니다. 종료하지 마세요.";
  public static TEXT_SAVING_INITIAL_DESIGN_ESTIMATE: string = "최초 설계와 견적을 저장 중입니다. 종료하지 마세요.";
  public static TEXT_SAVING_INITIAL_DESIGN_TEMPLATE: string = "최초 설계 템플릿을 저장 중입니다. 종료하지 마세요.";
  public static TEXT_SAVING_DESIGN: string = "설계를 저장 중입니다. 종료하지 마세요.";
  public static TEXT_SAVING_DESIGN_TEMPLATE: string = "설계 템플릿을 저장 중입니다. 종료하지 마세요.";
  public static TEXT_SAVING_DESIGN_ESTIMATE: string = "설계와 견적을 저장 중입니다. 종료하지 마세요.";
  public static TEXT_SAVING_COMPLETE_CONTRACT: string = "계약완료로 인한 저장 중입니다. 종료하지 마세요.";
  public static TEXT_NEW_ESTIMATE: string = "견적 중입니다...";
  public static TEXT_LOADING_ESTIMATE: string = "견적을 불러오는 중입니다...";
  public static TEXT_IS_RE_ESTIMATE: string = "설계 변경사항을 확인 중입니다...";
  public static TEXT_SAVING_ESTIMATE: string = "견적을 저장 중입니다...";
  public static TEXT_SAVING_ESTIMATE_FAIL: string = "견적 저장에 실패했습니다.\n다시 시도해주세요.";
  public static TEXT_NOT_EXIST_SPEC: string = "품목에 등록되지 않은 규격입니다.";
  public static TEXT_NOT_EXIST_BRAND: string = "품목에 등록되지 않은 상표입니다.";
  public static TEXT_NOT_EXIST_UNIT_PRICE: string = "품목에 등록되지 않은 단가입니다.\n직접 입력해주세요.";
  public static TEXT_EDITED_RATE_AND_INIT_DISCOUNT_RATE: string = "배율이 수정되어 할인율을 초기화합니다.";
  public static TEXT_EXCEED_MAX_DISCOUNT_RATE: string = "최대 할인율을 초과하여, 다시 계산했습니다.";
  public static TEXT_SELECT_PRODUCT: string = "상품을 선택해주세요.";
  public static TEXT_INPUT_PRODUCT_NAME: string = "올바른 상품명을 입력해주세요.";
  public static TEXT_INPUT_SPEC_AND_BRAND: string = "올바른 규격/상표를 입력해주세요.";
  public static TEXT_YOU_CAN_DELETE_CUSTOM_PRODUCT: string = "임의로 추가한 상품만 삭제할 수 있습니다.";
  public static TEXT_EXIST_UNFIT_ITEM_NEEDED_REVIEW: string = "부적합한 품목이 존재하여 검토가 필요합니다.";
  public static TEXT_EXIST_UNFIT_ITEM_CHECK_SPEC: string =
    "부적합한 품목이 존재하여 견적을 진행할 수 없습니다.\n해당 영역의 설계를 다시하거나, 규격 또는 수량을 확인해주세요.";
  public static TEXT_CHANGED_AND_RECREATE_ITEM_LIST: string = "변경으로 인해, 상품 목록을 다시 만들었습니다.";
  public static TEXT_NOT_EXIST_UNFIT_SPEC_ITEM: string = "부적합한 품목이 존재하지 않습니다.";
  public static TEXT_CONTRACT_COMPLETED: string = `현재의 설계 및 견적 정보가 저장되고, 더이상 변경할 수 없게됩니다.\n또한, 저장이 완료되면 자동으로 종료됩니다.\n${CONST.TEXT_DO_YOU_CONTINUE}`;
  public static TEXT_CANNOT_CONTRACT_COMPLETED: string = "고객을 등록하지 않으면 계약완료를 할 수 없습니다.";
  public static TEXT_NOT_EXIST_CUSTOMER: string = `${CONST.TEXT_CANNOT_CONTRACT_COMPLETED}\n고객을 등록 하시겠습니까?`;
  public static TEXT_REG_CUSTOMER_AND_CONTRACT: string = `${CONST.TEXT_CANNOT_CONTRACT_COMPLETED}\n고객을 등록하고 계약완료 하시겠습니까?`;
  public static TEXT_NOT_EXIST_ESTIMATE: string = "저장된 견적서가 존재하지 않습니다.";
  public static TEXT_LOAD_COMPLATED_ESTIMATE: string = "저장된 견적서를 불러왔습니다.";
  public static TEXT_CREATED_FIRST_PRICE_ESTIMATE: string =
    "견적서가 최초로 생성됐습니다.\n견적서를 유지하고 싶은 경우, 저장해주세요.";
  public static TEXT_MAKE_ESTIMATE_CHANGED: string = "견적을 다시한 결과, 총 금액이 변경되었습니다.";
  public static TEXT_MAKE_ESTIMATE_NO_CHANGED: string = "견적을 다시한 결과, 총 금액이 변경되지 않았습니다.";
  public static TEXT_CAN_SELECT_VARIABLE_LENGTH: string = `${CONST.NUM_VARIABLE_LENGTH_BUILDING_MAX}동 이하만 가변 길이를 선택할 수 있습니다.`;
  public static TEXT_ESTIMATE_ERROR_AND_UNIT_PRICE_CHANGED: string =
    "위 견적은 시공 방법에 따라 오차가 존재할 수 있으며, 계약 시점에 따라 단가가 변경될 수 있습니다.";
  public static TEXT_CAN_CHANGE_CUSTOM_UNIT_PRICE: string = "임의 상품만 단가를 임의로 입력할 수 있습니다.";
  public static TEXT_BATCH_CHANGE_DESIGN_ITEM_SPEC_AND_BRAND: string =
    "설계 전체에서 동일한 규격/상표를 일괄적으로 변경합니다.";
  public static TEXT_FEATURE_TESTING_BE_CAREFUL: string = "이 기능은 테스트 중입니다.\n유의해서 사용하세요!";
  public static TEXT_NO_SAVING_EXIT: string = "저장하지 않은 내용은 삭제됩니다.\n지금 종료하시겠습니까?";
  public static TEXT_UNFIT_PRODUCT_CHANGE_SPEC: string =
    "부적합 상품이란, 상품의 규격(상표)이 품목 관리에 존재하지 않는 경우에 발생합니다.\n상품을 더블클릭해서 규격(상표)를 변경하거나, 선택 해제한 후에 다시 견적을 시도해주세요.";
  public static TEXT_WARNING_FORCE_LEVEL_SELECT: string = "기본정보의 하우스 중수를 벗어났습니다!";
  public static TEXT_WARNING_FORCE_LEVEL_SELECT_2: string =
    "(스위치를 껏다가 다시 켜면 강제로 활성화되고, 다른 작업에 영향을 줄 수도 있습니다.)";
  public static TEXT_WARNING_ESTIMATE_ITEM_MODIFY: string =
    '아래 주의 사항을 확인해주세요.\n- "단가"를 변경한 경우, 동일상품의 모든 단가가 변경됩니다.\n- "설계 수량"을 변경한 경우, 견적서 전체가 재견적됩니다.\n- 총 자재비가 바뀐 경우, 공과잡비가 자동으로 재계산됩니다.\n\n 계속하시겠습니까?';
  public static TEXT_WARNING_PESTIMATE_NOT_EXIST_PURCHASE: string = "매입가가 없어 정확한 수익률을 계산할 수 없습니다.";
  public static TEXT_MAKE_MATERIAL: string = "견적을 통해 자재 내역을 생성하였습니다.";

  //----------------------------------
  // 툴팁 (정리해야함)
  //----------------------------------

  public static TOOLTIP_DESIGN_NUMBER: string = "설계 번호입니다.";
  public static TOOLTIP_DESIGN_TITLE: string = "설계 제목을 입력하세요.";
  public static TOOLTIP_DESIGN_PROGRESS_STATUS: string = "설계 진행 상태입니다.";
  public static TOOLTIP_DESIGN_TEMPLATE_NAME: string = "템플릿 제목을 입력하세요.";
  public static TOOLTIP_DESIGN_TEMPLATE_EXPLAIN: string = "템플릿 설명을 입력하세요.";
  public static TOOLTIP_DESIGN_USAGE_AND_CROP: string = "작물명이나 창고 등 목적";
  public static TOOLTIP_DESIGN_MARK_TYPE: string = "시방서에 출력되는 하우스 형식(규격)입니다.";
  public static TOOLTIP_DESIGN_MARK_DATE: string = "설계 일시를 대신하여 표기 날짜가 출력됩니다.";
  public static TOOLTIP_DESIGN_NOTE: string = "자유롭게 내용을 입력하세요.";

  public static TOOLTIP_BASIC_STRUCTURE: string = "하우스의 구조 (템플릿 선택 시 결정됨)";
  public static TOOLTIP_BASIC_LEVEL_NUMBER: string = "하우스의 중수 입력";
  public static TOOLTIP_BASIC_LEVEL_INTERVAL: string = "중과 중간의 간격 입력";
  public static TOOLTIP_BASIC_BUILDING_NUMBER: string = "하우스의 동수 입력";
  public static TOOLTIP_BASIC_BUILDING_INTERVAL: string = "동과 동간의 간격 입력";
  public static TOOLTIP_BASIC_AREA: string = "하우스의 면적";
  public static TOOLTIP_BASIC_WIDTH: string = "하우스 한 동의 폭 입력";
  public static TOOLTIP_BASIC_LENGTH: string = "하우스 한 동의 길이 입력";
  public static TOOLTIP_BASIC_IS_VARIABLE_LENGTH: string = "길이가 동마다 다른 경우 선택";
  public static TOOLTIP_BASIC_IS_VARIABLE_LENGTH_SG: string = `${CONST.TOOLTIP_BASIC_IS_VARIABLE_LENGTH}\n(기초 피복, 치마 피복 등에 영향)`;
  public static TOOLTIP_BASIC_IS_VARIABLE_LENGTH_IL: string = `${CONST.TOOLTIP_BASIC_IS_VARIABLE_LENGTH}\n(기초 피복, 치마 피복, 물받이 등에 영향)`;
  public static TOOLTIP_BASIC_HEIGHT_SHOULDER: string = "지면부터 하우스 어깨까지의 높이 입력";
  public static TOOLTIP_BASIC_DISTANCE_SHOULDER_PAD: string = "하우스 어깨부터 어깨패드가 설치될 거리 입력";
  public static TOOLTIP_BASIC_DEPTH_LAYING: string = "땅 속에 매설되는 깊이 입력";
  public static TOOLTIP_BASIC_HEIGHT_ROOF_MAX: string =
    "지붕이 삼각형이라고 가정했을때, 어깨부터의 높이 최대값\n(변경전>>변경후)";
  public static TOOLTIP_BASIC_HEIGHT_MAX: string =
    "지붕이 삼격형이라고 가정했을때, 지면부터의 높이 최대값\n(변경전>>변경후)";
  public static TOOLTIP_BASIC_PERIMETER_ROOF: string = "이상적인 지붕 호의 둘레 길이\n(변경전>>변경후)";
  public static TOOLTIP_BASIC_LENGTH_RAFTER: string = "이상적인 서까래 하나의 길이\n(변경전>>변경후)";
  public static TOOLTIP_BASIC_BENDING_EXPENSE: string =
    "파이프 벤딩비를 별도로 산출할지 선택합니다.\n(벤딩 파이프: 서까래, 천창 서까래, 방풍벽 서까래)";
  public static TOOLTIP_BASIC_PAD_VINYL: string =
    "패드 비닐을 설치할지 선택합니다.\n보통 피복이 2겹이하인 경우 필요합니다.";
  public static TOOLTIP_BASIC_BUILDING_SELECTED: string =
    "업체에서 시공까지 하는 경우 선택합니다.\n밴딩비와 공과잡비, 시공 작업에 영향을 줍니다.";
  public static TOOLTIP_BASIC_SUBSIDY_SELECTED: string = "정부 정부사업인 경우 선택합니다.";
  public static TOOLTIP_BASIC_APPLY_DO_ALGORITHM: string = "적용을 누르면, 설계 알고리즘이 동작합니다.";
  public static TOOLTIP_BASIC_APPLY_NO_ALGORITHM: string = "적용을 누르면, 알고리즘 동작없이 변경사항만 적용됩니다.";

  public static TOOLTIP_BUILDING_VEHICLE_ENTRY: string =
    "현장에 운송트럭 진입이 가능한 경우 선택합니다.\n - 3.5톤: 400본, 11m이하\n - 5톤: 700~1000본, 11m이하\n - 11톤: 1300~1800본, 13m이하";
  public static TOOLTIP_BUILDING_ELECTRIC_AVAILABLE: string =
    "현장에 전기 이용이 가능한 경우 선택합니다.\n - 전원 콘센트 연결이 가능해야합니다.\n - 전기가 없으면 발전기대여료가 청구됩니다.";
  public static TOOLTIP_BUILDING_GROUND_FLAT: string =
    "현장에 지반 평탄화가 된 경우 선택합니다.\n - 레벨기로 측정하여 맨앞뒤의 오차가 10cm이하여야 합니다.\n - 오차가 심하면 장비대여료 및 인건비가 추가될 수 있습니다.";

  public static TOOLTIP_FOUNDATION_LENGTH_GIRTH_CENTER_SELECTED: string =
    "중앙동 기둥에도 길이방향 바닥도리를 할지 여부\n - 선택: 중앙동과 양끝동 기둥 모두에 길이방향 바닥도리 설치\n - 해제: 양끝동 기둥에만 길이방향 바닥도리 설치";
  public static TOOLTIP_FOUNDATION_COLUMN_CONCRETE_VOLUME_REAL: string =
    "거푸집 부피 * 기둥 수량 * 여분율이 반영된 부피";
  public static TOOLTIP_FOUNDATION_COLUMN_CONCRETE_MOLD_VOLUME: string = "거푸집 하나의 부피";
  public static TOOLTIP_FOUNDATION_COLUMN_CONCRETE_STRENGTHEN_PIPE: string =
    "기둥이 콘크리트에 더 단단히 고정되도록 거푸집 안쪽에 설치되는 파이프";

  public static TOOLTIP_TRUNK_ROOF_BRACING_QUANTITY: string = "한 동에 설치되는 브레싱 파이프 개수";

  public static TOOLTIP_COLUMN_SECOND_ENDPIECE_COLUMN_SELECTED: string =
    "온실 앞뒷면에 기둥, 중방, 서까래를 하나씩 더 추가로 설치\n - 2중 마구리 역할이 가능함";
  public static TOOLTIP_COLUMN_SIDE_ENHANCED_COLUMN_SELECTED: string =
    "온실 측면 양끝의 기둥 사이에 기둥을 하나씩 더 추가로 설치\n - 양측면 구조 보강";
  public static TOOLTIP_COLUMN_ASSIST_COLUMN_SELECTED: string =
    "기둥 간격이 너무 넓은 경우, 2/3중 서까래를 고정하기 위한 30cm가량의 짧은 기둥 설치";

  public static TOOLTIP_COLUMN_MIDDLE_TRUSS_MULTIPLE: string =
    "트러스를 만들기위한 추가 파이프 배율\n - 간이형: 폭20cm, 1m간격 수직 트러스는 1.2배\n - 정규형: 폭40cm, 수직+대각 트러스는 2.4배";

  public static TOOLTIP_COLUMN_BRACING_CENTER_SELECTED: string =
    "중앙동 기둥에도 브레싱을 할지 여부\n - 선택: 중앙동과 양끝동 기둥 모두에 브레싱 설치\n - 해제: 양끝동 기둥에만 브레싱 설치";
  public static TOOLTIP_COLUMN_BRACING_QUANTITY: string = "기둥 한 줄에 설치되는 브레싱 파이프 개수";

  public static TOOLTIP_COLUMN_SIDE_STRAIGHT_LENGTH: string = "설치 여분을 포함한 한 줄의 총 길이";
  public static TOOLTIP_COLUMN_SIDE_STRAIGHT_LINE_NUMBER: string = "양 측면에 설치되는 가로대 파이프의 전체 줄수";

  public static TOOLTIP_ROOF_EDGE_RAFTER_EXTENSION: string =
    "양끝동의 지붕 서까래를 방풍벽 서까래까지 한번에 내려서 설치함";
  public static TOOLTIP_ROOF_EDGE_RAFTER_IMPRINT: string =
    "양끝동의 방풍벽까지 확장된 서까래를 나눠서 핀가공 파이프를 설치함";
  public static TOOLTIP_ROOF_BRACING_QUANTITY: string = "한 동에 설치되는 브레싱 파이프 개수";

  public static TOOLTIP_WING_STRAIGHT_LENGTH: string = "설치 여분을 포함한 한 줄의 총 길이";
  public static TOOLTIP_WING_STRAIGHT_LINE_NUMBER: string = "양 측면에 설치되는 가로대 파이프의 전체 줄수";

  public static TOOLTIP_ENDPIECE_SLIDING_INSTALL_WAY: string =
    "미닫이 출입문을 비닐하우스의 안쪽에 설치할지, 바깥쪽에 설치할지 선택합니다.";
  public static TOOLTIP_ENDPIECE_SLIDING_LOWER_ENHANCE_SELECTED: string =
    "미닫이 출입문 하단에 C형강을 추가로 설치할지를 선택합니다.";
  public static TOOLTIP_ENDPIECE_MAKING_DOOR_MIDDLE_LINE_NUMBER: string =
    "문짝의 가로방향 중방의 줄 수를 선택합니다. 여닫이 문짝인 경우, 줄수만큼 경첩이 견적됩니다.";

  public static TOOLTIP_GUTTER_PAD_WORKED_SELECTED: string =
    "물받이에 물받이 패드가 가공된 제품을 사용\n(물받이 패드와 패드 연결핀을 별도로 견적하지 않음)";

  public static TOOLTIP_SWITCHER_SWITCH_ORDER_MAX: string =
    "개폐기를 겹처서 배치할 최대 차수\n - 1차: 개폐기를 1겹으로 배치함\n - 2차: 개폐기를 2겹으로 배치함(ex.차광망을 2차에 물림)";
  public static TOOLTIP_SWITCHER_SWITCH_HEIGHT: string = "개폐하는 영역의 높이로써, 치마 높이까지 포함된 값";
  public static TOOLTIP_SWITCHER_COVER_SWITCH_ORDER: string =
    "피복을 몇 번째 차수의 개폐기에 물릴지 선택\n - 0차: 피복을 개폐기에 물리지 않음(위치에 따라 분리될 피복이 합쳐짐)\n - 1~2차: 1차 또는 2차 개폐기에 물림\n (2차는 최대 개폐 차수가 2차이어야 선택가능함)";
  public static TOOLTIP_SWITCHER_SLOPE_CLIP_SELECTED: string =
    "지붕 치마 패드에 피복이 걸리지 않도록, 추가적으로 기울기 클립을 설치합니다.";

  public static TOOLTIP_COVER_SCOPE_TYPE: string =
    "피복의 범위\n - 위치 / 위치: 두 위치를 분리하여 견적\n - 위치+위치: 위치간에 최대한 연결하여 견적";
  public static TOOLTIP_COVER_SCOPE_TYPE_INTERLOCK: string =
    "피복의 범위\n - 위치 / 위치: 두 위치를 분리하여 견적\n - 위치+위치: 위치간에 최대한 연결하여 견적\n (+마구리는 1동에서만 동작함)";
  public static TOOLTIP_COVER_FIXED_LENGTH_ROOF: string =
    "지붕쪽 피복의 단위길이를 고정시킬지, 실제 길이를 사용할지 여부\n - 선택: 샘플의 단위길이로 고정\n - 해제: 피복의 실제 길이 사용\n(측면과 마구리 피복은 무조건 샘플의 단위길이로 고정함)";

  public static TOOLTIP_FIXING_LINE_NUMBER_PER_BUILDING: string = "한 동에서 줄수";
  public static TOOLTIP_FIXING_LINE_NUMBER_PER_HOUSE: string = "하우스 전체에서 줄수";

  public static TOOLTIP_BANDSTRING_ENHANCED_STAKE_SELECTED: string =
    "바닥 가로대 파이프에 대각방향으로 설치하는 말뚝.\n하우스 전체를 고정해주는 역할을 함.";

  public static TOOLTIP_WINDOW_QUANTITY_PER_BUILDING: string = "한 동에서 수량";
  public static TOOLTIP_WINDOW_QUANTITY_PER_HOUSE: string = "하우스 전체에서 수량";

  public static TOOLTIP_CURTAIN_SWITCH_STEP: string =
    "개폐 영역을 분리\n - 1단: 개폐 영역을 나누지 않고 한번에 개폐함\n - 2단: 개폐 영역을 둘로 나눠서 각각 개폐함(면적이 넓은 경우 사용)";
  public static TOOLTIP_CURTAIN_SWITCH_AVAILABLE_CUTAIN_AREA: string =
    "수용 가능한 최대 커튼 면적\n - 커튼 피복의 종류, 감속 모터의 마력, 개폐 분할에 의해 결정됨";

  // 견적
  public static TOOLTIP_ESTIMATE_TOOLBAR_BUTTON_PRINT: string = "프린터로 인쇄를 합니다.";
  public static TOOLTIP_ESTIMATE_TOOLBAR_BUTTON_PRINT_SAVE: string =
    "프린터로 인쇄를 합니다.\n버튼을 누르면 설계와 견적이 자동 저장됩니다.";
  public static TOOLTIP_ESTIMATE_TOOLBAR_BUTTON_CLOSE: string = "견적 창을 닫습니다.";
  public static TOOLTIP_ESTIMATE_TOOLBAR_BUTTON_CLOSE_SAVE: string =
    "견적 창을 닫습니다.\n버튼을 누르면 설계와 견적이 자동 저장됩니다.";
  public static TOOLTIP_ESTIMATE_TOOLBAR_BUTTON_CONTRACT_COMPLETE: string =
    "저장 후, 진행상태를 계약완료로 바꿉니다.\n계약완료는 고객이 등록되어야 할 수 있습니다.";

  //----------------------------------
  // 쿠키 https://github.com/cmp-cc/vue-cookies#readme
  //----------------------------------

  /** 쿠키 공통 값 */
  public static COOKIE_VALUE_FALSE: string = "0";
  public static COOKIE_VALUE_TRUE: string = "1";
  public static COOKIE_VALUE_1DAY: string = "1day";

  /** 설계 공지를 보여줄지 결정 */
  public static COOKIE_KEY_DESIGN_NOTICE_DISABLED: string = "disDesignNotice";
  /** 견적 유형 가시성 */
  public static COOKIE_KEY_ESTIMATE_TYPE_VISIBLE: string = "etTPV";
  /** 견적 정보 가시성 */
  public static COOKIE_KEY_ESTIMATE_CUSTOMER_INFO_VISIBLE: string = "etCSTIV";
  public static COOKIE_KEY_ESTIMATE_BUILDING_INFO_VISIBLE: string = "etBLDIV";
  public static COOKIE_KEY_ESTIMATE_DESIGN_INFO_VISIBLE: string = "etDSNIV";
  /** 견적 컬럼 가시성 */
  public static COOKIE_KEY_ESTIMATE_BRANDS_COLUMN_VISIBLE: string = "etBRDCV";
  public static COOKIE_KEY_ESTIMATE_ITEM_SUPPLY_COLUMN_VISIBLE: string = "etISPCV";
  public static COOKIE_KEY_ESTIMATE_ITEM_VAT_COLUMN_VISIBLE: string = "etIVTCV";
  public static COOKIE_KEY_ESTIMATE_ITEM_PRICE_COLUMN_VISIBLE: string = "etIPRCV";
  public static COOKIE_KEY_ESTIMATE_TAX_TYPE_COLUMN_VISIBLE: string = "etTTPCV";
  public static COOKIE_KEY_ESTIMATE_PURPOSE_COLUMN_VISIBLE: string = "etPPSCV";
  public static COOKIE_KEY_ESTIMATE_MEMO_COLUMN_VISIBLE: string = "etMEMCV";
  public static COOKIE_KEY_ESTIMATE_TOTAL_SUPPLY_COLUMN_VISIBLE: string = "etTSPCV";
  public static COOKIE_KEY_ESTIMATE_TOTAL_VAT_COLUMN_VISIBLE: string = "etTVTCV";
  public static COOKIE_KEY_ESTIMATE_TOTAL_PRICE_COLUMN_VISIBLE: string = "etTPRCV";

  /** 자재 정보 가시성 */
  public static COOKIE_KEY_MATERIAL_CUSTOMER_INFO_VISIBLE: string = "mtCSTIV";
  public static COOKIE_KEY_MATERIAL_BUILDING_INFO_VISIBLE: string = "mtBLDIV";

  /** 자재 컬럼 가시성 */
  public static COOKIE_KEY_MATERIAL_BRANDS_COLUMN_VISIBLE: string = "mtBRDCV";
  public static COOKIE_KEY_MATERIAL_PURPOSE_COLUMN_VISIBLE: string = "mtPPSCV";

  //--------------------------------------------------------------------------
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // 개발용
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 개발 주요 상수
  //----------------------------------

  /** 최초에 설계 저장하기 (개발 모드시 자동 false) */
  public static DEV_SAVE_INIT: boolean = true;

  /** 견적 프린트시 설계 저장하기 (개발 모드시 자동 false)  */
  public static DEV_SAVE_ESTIMATE_PRINT: boolean = false;

  //----------------------------------
  // 개발 환경
  //----------------------------------

  // 개발환경 서버 정보
  public static DEV_SERVER_DEV_URL_8081: string = "localhost:8081"; // 로컬 개발 서버(8081)
  public static DEV_SERVER_DEV_URL: string = "localhost:8080"; // 로컬 개발 서버
  public static DEV_SERVER_RUN_URL: string = "vhows.com"; // 제품 서버

  // 개발환경 파라미터

  /**
   * 새로운 설계: CONST.LB_MODIFY_TYPE_CREATE
   * 수정: CONST.LB_MODIFY_TYPE_UPDATE
   * 견적: CONST.LB_AFTER_ACTION_ESTIMATE
   */
  public static DEV_INIT_DESIGN_STATE: string = CONST.LB_MODIFY_TYPE_CREATE;

  /**
   * 설계: CONST.LB_DESIGN_MODE_DESIGN;
   * 템플릿: CONST.LB_DESIGN_MODE_TEMPLATE;
   */
  public static DEV_INIT_DESIGN_MODE: string = CONST.LB_DESIGN_MODE_DESIGN;
  /**
   * 단동 템플릿: 182(기본25), 183(기본32), 229(창고25), 185(창고32), 1156(창고48), 184(지붕편측32), 230,1173,1174,1175(내재해07-1~4), 1176,1179,231,1180,1252,1253,1254(내재해10-1~7), 1255(내재해12-1)
   * 연동 템플릿: 186(원기둥48), 800(원기둥60), 187(각기둥60*60), 791(각기둥75*75), 188(판매장), 281(창고)
   * 기타 템플릿: 189(기타)
   * 설계 불러오기: 119(단동), 59(연동), 220(기타)
   */
  public static DEV_INIT_DESIGN_ID: number = 186;
  /** 1(브이하우스랩), NaN(기본조직) */
  public static DEV_INIT_COMPANY_ID: number = 1;
  /** true(운영자가 조직별 설계중), false(조직내 설계중) */
  public static DEV_INIT_IS_COMPANY_URI: boolean = false;

  //----------------------------------
  // 응용 프로그램
  //----------------------------------

  /**
   * 버전
   */
  public static APP_VERSION: string = "v4.4.1";
  /**
   * 인바운드 버전
   */
  public static INBOUND_VERSION: string = "v1.0.0";

  /** 업데이트 필요 */
  public static APP_UPDATE: number = 1;
  /** 같은 버전 */
  public static APP_SAME_VERSION: number = 0;
}
