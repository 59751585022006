import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCashmilon } from "vhows-design/src/object/design/item/list/ItemCashmilon";
import { ItemLagging } from "vhows-design/src/object/design/item/list/ItemLagging";
import { ItemMat } from "vhows-design/src/object/design/item/list/ItemMat";
import { ItemNonwoven } from "vhows-design/src/object/design/item/list/ItemNonwoven";
import { ItemQuilting } from "vhows-design/src/object/design/item/list/ItemQuilting";
import { ItemScreen } from "vhows-design/src/object/design/item/list/ItemScreen";
import { ItemShadenet } from "vhows-design/src/object/design/item/list/ItemShadenet";
import { ItemTent } from "vhows-design/src/object/design/item/list/ItemTent";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { ItemWeavingFilm } from "vhows-design/src/object/design/item/list/ItemWeavingFilm";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-26
 */
@jsonObject({
  knownTypes: [],
  name: "WindbreakPart_Windbreak",
})
export class WindbreakPart_Windbreak extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public coverLevel: CoverLevel; // 기초 피복 중수

  // 샘플

  // 아이템
  // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _windbreakWidth: number; // 바람막이 폭
  @jsonMember(Number)
  public _windbreakLength: number; // 바람막이 길이
  @jsonMember(Number)
  public _windbreakTotalLength: number; // 위치별 바람막이 총 길이

  /**
   * 바람막이 폭
   */
  public get windbreakWidth(): number {
    return this._windbreakWidth;
  }

  //
  public set windbreakWidth(value: number) {
    this._windbreakWidth = CommonUtil.fixFloat(value);
  }

  /**
   * 바람막이 길이
   */
  public get windbreakLength(): number {
    return this._windbreakLength;
  }

  //
  public set windbreakLength(value: number) {
    this._windbreakLength = CommonUtil.fixFloat(value);
  }

  /**
   * 위치별 바람막이 총 길이
   */
  public get windbreakTotalLength(): number {
    return this._windbreakTotalLength;
  }

  //
  public set windbreakTotalLength(value: number) {
    this._windbreakTotalLength = CommonUtil.fixFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.init_coverLevel();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    const trunkPart_Rafter: TrunkPart_Rafter = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index])
      .trunkPosition.rafterPart;
    const switcherPosition: SwitcherPosition = <SwitcherPosition>(
      this.struct.switcherWork.levelAC[this.level.index].positionAC[this.position.index]
    );
    const skirtPosition: SkirtPosition = <SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.position.index]
    );

    /// //////// 수치 ///////////

    // 바람막이 하나의 폭과 길이, 총 길이
    if (this.level.index === 0) {
      // 1중
      if (this.position.label === CONST.LB_POSITION_SIDE) {
        this.windbreakWidth = trunkPart_Rafter.rafterInterval * 2 + 0.6;
        this.windbreakLength = switcherPosition.switchHeight + 0.6;
        this.windbreakTotalLength =
          this.windbreakLength * 2 * switcherPosition.switchWayValue * this.basic.buildingNumber;
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.windbreakWidth = trunkPart_Rafter.rafterInterval * 2 + 0.6;
        this.windbreakLength = switcherPosition.switchHeight - skirtPosition.skirtHeight + 0.6;
        this.windbreakTotalLength =
          this.windbreakLength * 2 * switcherPosition.switchWayValue * this.basic.buildingNumber;
      } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
        this.windbreakWidth = 1 + 0.6;
        this.windbreakLength = switcherPosition.switchHeight + 0.6;
        this.windbreakTotalLength = this.windbreakLength * 2 * this.basic.buildingNumber;
      }
    } else {
      // 2중이상
      if (this.position.label === CONST.LB_POSITION_SIDE) {
        this.windbreakWidth = trunkPart_Rafter.rafterInterval + 0.6;
        this.windbreakLength = switcherPosition.switchHeight + 0.6;
        this.windbreakTotalLength =
          this.windbreakLength * 2 * switcherPosition.switchWayValue * this.basic.buildingNumber;
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.windbreakWidth = trunkPart_Rafter.rafterInterval + 0.6;
        this.windbreakLength = switcherPosition.switchHeight - skirtPosition.skirtHeight + 0.6;
        this.windbreakTotalLength =
          this.windbreakLength * 2 * switcherPosition.switchWayValue * this.basic.buildingNumber;
      } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
        this.windbreakWidth = 1 + 0.6;
        this.windbreakLength = switcherPosition.switchHeight + 0.6;
        this.windbreakTotalLength = this.windbreakLength * 2 * this.basic.buildingNumber;
      }
    }

    /// //////// 생성, 선택, 가시성, 규격, 수량 ///////////

    for (const coverPosition of this.coverLevel.positionAC as CoverPosition[]) {
      if (
        coverPosition.type === CONST.LB_COVER_TYPE_VINYL ||
        coverPosition.type === CONST.LB_COVER_TYPE_WEAVING_FILM ||
        coverPosition.type === CONST.LB_COVER_TYPE_SHADENET ||
        coverPosition.type === CONST.LB_COVER_TYPE_TENT ||
        coverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN ||
        coverPosition.type === CONST.LB_COVER_TYPE_QUILTING ||
        coverPosition.type === CONST.LB_COVER_TYPE_SCREEN ||
        coverPosition.type === CONST.LB_COVER_TYPE_MAT
      ) {
        // 비닐류
        this.makeWindbreakVinylStyle(coverPosition);
      } else if (
        coverPosition.type === CONST.LB_COVER_TYPE_CASHMILON ||
        coverPosition.type === CONST.LB_COVER_TYPE_COLOR_LAGGING ||
        coverPosition.type === CONST.LB_COVER_TYPE_LAGGING
      ) {
        // 카시미론류
        this.makeWindbreakCashmilonStyle(coverPosition);
      }
    }

    /// //////// 기본(1중측면 연장) 형태 ///////////

    // 모든 바람막이 피복 아이템을 1중 측면으로 모으기
    if (this.position.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE) {
      // 1중 측면 통합
      if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_SIDE) {
        const itemTempAC: Item[] = [...this.itemAC];
        this.itemAC.splice(0, this.itemAC.length);
        let itemTemp: Item;
        for (itemTemp of itemTempAC) {
          itemTemp.purpose = "길이연장된 바람막이";
          // 품목 합치기
          WindbreakPart_Windbreak.extendWindbreakLengthToItemAC(this.itemAC, itemTemp);
        }
      } else {
        if (this.level.index < this.basic.levelNumber && this.position.selected === true) {
          let item: Item;
          for (item of this.itemAC) {
            if (item.selected === true) {
              item.purpose = "길이연장된 바람막이";
              // 품목 합치기
              WindbreakPart_Windbreak.extendWindbreakLengthToItemAC(
                this.struct.windbreakWork.level1.sidePosition.windbreakPart.itemAC,
                item,
              );
            }
          }
          this.itemAC.splice(0, this.itemAC.length);
        }
      }
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  /**
   * 바람막이 품목의 피복을 길이로 연장하기 (동일 상품이 없는 경우, 새로 추가)
   * @param addItemAC 합칠 품목AC
   * @param additem 합칠 상품
   */
  public static extendWindbreakLengthToItemAC(addItemAC: Item[], addItem: any): void {
    let item: any;
    for (item of addItemAC) {
      if (item.label === addItem.label && item.specsList === addItem.specsList && item.brands === addItem.brands) {
        // 비닐류
        if (
          addItem instanceof ItemVinyl ||
          addItem instanceof ItemWeavingFilm ||
          addItem instanceof ItemShadenet ||
          addItem instanceof ItemTent ||
          addItem instanceof ItemNonwoven ||
          addItem instanceof ItemQuilting ||
          addItem instanceof ItemScreen ||
          addItem instanceof ItemMat
        ) {
          if (item.specWidth < addItem.specWidth) {
            item.specWidth = addItem.specWidth;
          }
          item.specLength += addItem.specLength;
          return;
        } else if (addItem instanceof ItemCashmilon || addItem instanceof ItemLagging) {
          item.designQuantity += addItem.getSelectedQuantity();
          return;
        }
      }
    }
    // 동일한 상품이 없는 경우, 새 상품 추가
    addItemAC.push(addItem);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 초기화: 기초 피복 중수
   */
  protected init_coverLevel(): void {
    this.coverLevel = <CoverLevel>this.struct.coverWork.levelAC[this.level.index];
  }

  /**
   * 비닐류 바람막이 만들기
   */
  protected makeWindbreakVinylStyle(coverPosition: CoverPosition): void {
    let item: Item;

    // 측면
    if (this.position.label === CONST.LB_POSITION_SIDE && coverPosition.windbreakCoverSide === true) {
      item = this.getNewWindbreakItem(coverPosition);
      item.selected = coverPosition.windbreakCoverSide;

      item.purpose = `${coverPosition.index + 1}번 바람막이`;
      item.designQuantity = 1;

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_ROOF && coverPosition.windbreakCoverRoof === true) {
      item = this.getNewWindbreakItem(coverPosition);
      item.selected = coverPosition.windbreakCoverRoof;

      item.purpose = `${coverPosition.index + 1}번 바람막이`;
      item.designQuantity = 1;

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_FRONT && coverPosition.windbreakCoverFront === true) {
      item = this.getNewWindbreakItem(coverPosition);
      item.selected = coverPosition.windbreakCoverFront;

      item.purpose = `${coverPosition.index + 1}번 바람막이`;
      item.designQuantity = 1;

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_BACK && coverPosition.windbreakCoverBack === true) {
      item = this.getNewWindbreakItem(coverPosition);
      item.selected = coverPosition.windbreakCoverBack;

      item.purpose = `${coverPosition.index + 1}번 바람막이`;
      item.designQuantity = 1;

      this.itemAC.push(item);
    }
  }

  /**
   * 카시미론류 바람막이 만들기
   */
  protected makeWindbreakCashmilonStyle(coverPosition: CoverPosition): void {
    let item: any;
    const overlapSize: number = this.design.preference.cashmilonStyleOverlapLength;

    // 측면
    if (this.position.label === CONST.LB_POSITION_SIDE && coverPosition.windbreakCoverSide === true) {
      item = this.getNewWindbreakItem(coverPosition);
      item.selected = coverPosition.windbreakCoverSide;

      item.purpose = `${coverPosition.index + 1}번 바람막이`;
      // 길이방향 설치
      item.designQuantity = this.windbreakTotalLength / (item.specLength - overlapSize);

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_ROOF && coverPosition.windbreakCoverRoof === true) {
      item = this.getNewWindbreakItem(coverPosition);
      item.selected = coverPosition.windbreakCoverRoof;

      item.purpose = `${coverPosition.index + 1}번 바람막이`;
      // 길이방향 설치
      item.designQuantity = this.windbreakTotalLength / (item.specLength - overlapSize);

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_FRONT && coverPosition.windbreakCoverFront === true) {
      item = this.getNewWindbreakItem(coverPosition);
      item.selected = coverPosition.windbreakCoverFront;

      item.purpose = `${coverPosition.index + 1}번 바람막이`;
      // 길이방향 설치
      item.designQuantity = this.windbreakTotalLength / (item.specLength - overlapSize);

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_BACK && coverPosition.windbreakCoverBack === true) {
      item = this.getNewWindbreakItem(coverPosition);
      item.selected = coverPosition.windbreakCoverBack;

      item.purpose = `${coverPosition.index + 1}번 바람막이`;
      // 길이방향 설치
      item.designQuantity = this.windbreakTotalLength / (item.specLength - overlapSize);

      this.itemAC.push(item);
    }
  }

  /**
   * 새로운 바람막이 피복 아이템 만들기
   * @param coverPosition 기초 피복 위치 객체
   * @return 바람막이 피복 아이템
   */
  private getNewWindbreakItem(coverPosition: CoverPosition): Item {
    let item: Item;

    if (coverPosition.type === CONST.LB_COVER_TYPE_VINYL) {
      item = ItemVinyl.getNewDesignItemBySample(this, coverPosition.vinylPart.windbreakVinylSample);

      (<ItemVinyl>item).specWidth = (<ItemVinyl>item).getFoldedWidth(CommonUtil.convertM2CM(this.windbreakWidth));
      (<ItemVinyl>item).specLength = this.windbreakTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_WEAVING_FILM) {
      item = ItemWeavingFilm.getNewDesignItemBySample(this, coverPosition.weavingFilmPart.weavingFilmSample);

      (<ItemWeavingFilm>item).specWidth = CommonUtil.convertM2CM(this.windbreakWidth);
      (<ItemWeavingFilm>item).specLength = this.windbreakTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_SHADENET) {
      item = ItemShadenet.getNewDesignItemBySample(this, coverPosition.shadenetPart.shadenetSample);

      (<ItemShadenet>item).specWidth = this.windbreakWidth;
      (<ItemShadenet>item).specLength = (<ItemShadenet>item).getAjustedSpecLength(this.windbreakTotalLength);
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_TENT) {
      item = ItemTent.getNewDesignItemBySample(this, coverPosition.tentPart.tentSample);

      (<ItemTent>item).specWidth = this.windbreakWidth;
      (<ItemTent>item).specLength = this.windbreakTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN) {
      item = ItemNonwoven.getNewDesignItemBySample(this, coverPosition.nonwovenPart.nonwovenSample);

      (<ItemNonwoven>item).specWidth = this.windbreakWidth;
      (<ItemNonwoven>item).specLength = this.windbreakTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_CASHMILON) {
      item = ItemCashmilon.getNewDesignItemBySample(this, coverPosition.cashmilonPart.cashmilonSample);

      (<ItemCashmilon>item).specWidth = this.windbreakWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
      item = ItemLagging.getNewDesignItemBySample(this, coverPosition.colorLaggingPart.colorLaggingSample);

      (<ItemLagging>item).specWidth = this.windbreakWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_LAGGING) {
      item = ItemLagging.getNewDesignItemBySample(this, coverPosition.laggingPart.laggingSample);

      (<ItemLagging>item).specWidth = this.windbreakWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_QUILTING) {
      item = ItemQuilting.getNewDesignItemBySample(this, coverPosition.quiltingPart.quiltingSample);

      (<ItemQuilting>item).specWidth = this.windbreakWidth;
      (<ItemQuilting>item).specLength = this.windbreakTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_SCREEN) {
      item = ItemScreen.getNewDesignItemBySample(this, coverPosition.screenPart.screenSample);

      (<ItemScreen>item).specWidth = this.windbreakWidth;
      (<ItemScreen>item).specLength = this.windbreakTotalLength;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_MAT) {
      item = ItemMat.getNewDesignItemBySample(this, coverPosition.matPart.matSample);

      (<ItemMat>item).specWidth = this.windbreakWidth;
      (<ItemMat>item).specLength = this.windbreakTotalLength;
    }

    return item;
  }
}
