import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Item } from "vhows-design/src/object/design/item/Item";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-04-29
 */
export class Review {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;
  /** 전체 품목 배열 */
  public allItemAC: Item[];
  /** 부적합 품목 배열 */
  public unfitItemAC: Item[];

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 뷰 데이터

  /** 품목검토 팝업 가시성 */
  public popupVisible: boolean = false;
  /** 품목검토 모드 인덱스 */
  public modeSelectedIndex: number = CONST.ID_REVIEW_UNFIT;
  /** 품목검토 열기 메세지 */
  public openMessage: string = "";

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param design 설계 객체
   */
  constructor(design: Design) {
    this.design = design;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design): void {
    this.design = design;
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {}

  //----------------------------------
  // 하우스 시방
  //----------------------------------

  /**
   * 품목검토 객체 생성하기 <br/>
   * design 객체가 연관되있다는 전제로 사용
   */
  public createReview(): void {
    // 품목 생성
    this.allItemAC = this.getAllReviewItems();
    this.unfitItemAC = this.getUnfitReviewItems();
  }

  /**
   * 적합성에 문제 없는지 여부
   * @return 적합여부
   */
  public reviewOK(): boolean {
    if (this.getLastUnfitItemACLength() === 0) {
      return true;
    }
    return false;
  }

  /**
   * 검토 품목 삭제하기
   * @param item 품목
   */
  public removeReviewItem(item: Item): void {
    if (this.allItemAC != null) {
      this.allItemAC.forEach((it, index) => {
        if (item === it) this.allItemAC.splice(index, 1);
      });
    }
    if (this.unfitItemAC != null) {
      this.unfitItemAC.forEach((it, index) => {
        if (item === it) this.unfitItemAC.splice(index, 1);
      });
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  /**
   * 검토할 전체 품목 가져오기 <br/>
   * design의 모든 파트 품목을 뒤져서 가시성이 있는 모든 품목을 반환함
   * @return 부적합 품목AC
   */
  protected getAllReviewItems(): Item[] {
    const allItemAC: Item[] = new Array();

    // 설계상의 모든 아이템 조회
    let work: Work;
    for (work of this.design.struct.workAC) {
      if (work.selected && work.levelAC != null) {
        let level: Level;
        for (level of work.levelAC) {
          if (level.selected && level.positionAC != null) {
            let position: Position;
            for (position of level.positionAC) {
              if (position.selected && position.partAC != null) {
                let part: Part;
                for (part of position.partAC) {
                  if (part.selected && part.itemAC != null) {
                    let item: Item;
                    for (item of part.itemAC) {
                      // 전체 품목AC
                      if (item.visible === true) {
                        // 데이터 그룹핑을 위한 이름 지정하기
                        item.constructionName = work.category;
                        item.workName = work.label;
                        item.levelName = level.label;
                        item.positionName = `${position.label} ${CONST.LB_POSITION}`;
                        item.partName = `${part.label} ${CONST.LB_PART}`;

                        allItemAC.push(item);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return allItemAC;
  }

  /**
   * 부적합 품목 가져오기
   * @summary 모든 품목을 뒤져서, 아래와 같은 부적합 품목을 반환함 (수량 0이상 선택된 상품에 한함)
   * - 단가모델이 null인 경우 (규격의 부적합)
   * - 수량이 NaN인 경우 (오류)
   * @return 부적합 품목AC
   */
  protected getUnfitReviewItems(): Item[] {
    const unfitItemAC: Item[] = new Array();

    // 설계상의 모든 아이템 조회
    let work: Work;
    for (work of this.design.struct.workAC) {
      if (work.selected && work.levelAC != null) {
        let level: Level;
        for (level of work.levelAC) {
          if (level.selected && level.positionAC != null) {
            let position: Position;
            for (position of level.positionAC) {
              if (position.selected && position.partAC != null) {
                let part: Part;
                for (part of position.partAC) {
                  if (part.selected && part.itemAC != null) {
                    let item: Item;
                    for (item of part.itemAC) {
                      // 부적합 품목AC
                      if (
                        item.visible === true &&
                        item.selectedByQuantity === true &&
                        (item.unitPriceModel == null ||
                          isNaN(item.designQuantity) === true ||
                          item.isSpecError === true)
                      ) {
                        // 데이터 그룹핑을 위한 이름 지정하기
                        item.constructionName = work.category;
                        item.workName = work.label;
                        item.levelName = level.label;
                        item.positionName = `${position.label} ${CONST.LB_POSITION}`;
                        item.partName = `${part.label} ${CONST.LB_PART}`;

                        unfitItemAC.push(item);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return unfitItemAC;
  }

  /**
   * 최신의 부적합 품목의 길이 구하기 <br/>
   * design 객체가 연관되있다는 전제로 사용
   * @return 부적합 품목수
   */
  protected getLastUnfitItemACLength(): number {
    // 부적합 품목 생성
    this.unfitItemAC = this.getUnfitReviewItems();

    return this.unfitItemAC.length;
  }
}
