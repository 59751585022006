import { jsonObject } from "typedjson";

import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { ScreennetPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPartIL_FixingNormalPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject
export class ScreennetSampleIL_NormalPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingNormalPadPart: ScreennetPartIL_FixingNormalPad = <ScreennetPartIL_FixingNormalPad>this.part;
    fixingNormalPadPart.algorithmSpec_normalPad_normalPad();
    fixingNormalPadPart.algorithmSpec_normalPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 일반 패드 샘플(기초 피복 고정)
   */
  // @override
  public algorithmSpec(): void {
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];
    this.specs = (<FixingPositionIL>fixingLevel.positionAC[this.position.index]).normalPadPart.normalPadSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
