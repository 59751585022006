import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { DesignUtil } from "vhows-design/src/object/design/DesignUtil";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPartIL_Vinyl } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Vinyl";
import { CoverPartIL_WeavingFilm } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_WeavingFilm";
import { CoverPartIL_Shadenet } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Shadenet";
import { CoverPartIL_Tent } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Tent";
import { CoverPartIL_Nonwoven } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Nonwoven";
import { CoverPartIL_Cashmilon } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Cashmilon";
import { CoverPartIL_ColorLagging } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_ColorLagging";
import { CoverPartIL_Lagging } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Lagging";
import { CoverPartIL_Quilting } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Quilting";
import { CoverPartIL_Screen } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Screen";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { WingWorkIL } from "vhows-design/src/object/design/frame/wing/WingWorkIL";
import { WingWorkVL } from "vhows-design/src/object/design/frame/wing/vl/WingWorkVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-29
 */
@jsonObject({
  knownTypes: [
    CoverPartIL_Cashmilon,
    CoverPartIL_ColorLagging,
    CoverPartIL_Lagging,
    CoverPartIL_Nonwoven,
    CoverPartIL_Quilting,
    CoverPartIL_Screen,
    CoverPartIL_Shadenet,
    CoverPartIL_Tent,
    CoverPartIL_Vinyl,
    CoverPartIL_WeavingFilm,
  ],
})
export class CoverPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public vinylPart: CoverPartIL_Vinyl;
  public weavingFilmPart: CoverPartIL_WeavingFilm;
  public shadenetPart: CoverPartIL_Shadenet;
  public tentPart: CoverPartIL_Tent;
  public nonwovenPart: CoverPartIL_Nonwoven;
  public cashmilonPart: CoverPartIL_Cashmilon;
  public colorLaggingPart: CoverPartIL_ColorLagging;
  public laggingPart: CoverPartIL_Lagging;
  public quiltingPart: CoverPartIL_Quilting;
  public screenPart: CoverPartIL_Screen;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 범위 선택 - 측면 */
  public scopeSelectedSide: boolean;

  /** 범위 선택 - 측면 기둥 */
  public scopeSelectedSideColumn: boolean;

  /** 범위 선택 - 측면 방풍벽 */
  public scopeSelectedSideWing: boolean;

  /** 범위 선택 - 지붕 */
  public scopeSelectedRoof: boolean;

  /** 범위 선택 - 앞면 */
  public scopeSelectedFront: boolean;

  /** 범위 선택 - 뒷면 */
  public scopeSelectedBack: boolean;

  /** 범위 선택 - 앞면 포함형 */
  public scopeSelectedFrontIncluded: boolean;

  /** 범위 선택 - 뒷면 포함형 */
  public scopeSelectedBackIncluded: boolean;

  /**
   * 측면 범위가 방풍벽인지 여부 <br/>
   * true: 방풍벽 범위, false: 기둥 범위
   */
  public isScopeSideWing: boolean;

  /**
   * 피복 형태별 두께값
   */
  public get thicknessValue(): number {
    return DesignUtil.getCoverThickness(this.type);
  }

  public set thicknessValue(value: number) {}

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _scopeType: string; // 범위 형태
  @jsonMember(Number)
  public _switcherOrderSideColumn: number; // 개폐 차수 - 기둥(측면)
  @jsonMember(Number)
  public _switcherOrderSideWing: number; // 개폐 차수 - 방풍벽(측면)
  @jsonMember(Number)
  public _switcherOrderRoof: number; // 개폐 차수 - 지붕
  @jsonMember(Number)
  public _switcherOrderFront: number; // 개폐 차수 - 앞면
  @jsonMember(Number)
  public _switcherOrderBack: number; // 개폐 차수 - 뒷면
  @jsonMember(Boolean)
  public _skirtCoverSideColumn: boolean; // 치마 피복 - 기둥(측면)
  @jsonMember(Boolean)
  public _skirtCoverSideWing: boolean; // 치마 피복 - 방풍벽(측면)
  @jsonMember(Boolean)
  public _skirtCoverRoof: boolean; // 치마 피복 - 지붕
  @jsonMember(Boolean)
  public _skirtCoverFront: boolean; // 치마 피복 - 앞면
  @jsonMember(Boolean)
  public _skirtCoverBack: boolean; // 치마 피복 - 뒷면
  @jsonMember(Boolean)
  public _windbreakCoverSideColumn: boolean; // 바람막이 피복 - 기둥(측면)
  @jsonMember(Boolean)
  public _windbreakCoverSideWing: boolean; // 바람막이 피복 - 방풍벽(측면)
  @jsonMember(Boolean)
  public _windbreakCoverRoof: boolean; // 바람막이 피복 - 지붕
  @jsonMember(Boolean)
  public _windbreakCoverFront: boolean; // 바람막이 피복 - 앞면
  @jsonMember(Boolean)
  public _windbreakCoverBack: boolean; // 바람막이 피복 - 뒷면
  @jsonMember(Boolean)
  public _skylightCover: boolean; // 천창 피복

  /**
   * 범위 형태
   */
  public get scopeType(): string {
    return this._scopeType;
  }

  public set scopeType(value: string) {
    this._scopeType = value;

    // 알고리즘
    this.algorithm_scopeSelectedByScopeType();
    this.algorithm_switcherOrderValue();
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    this.algorithm_skylightCoverSelected();
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 기둥(측면)
   */
  public get switcherOrderSideColumn(): number {
    return this._switcherOrderSideColumn;
  }

  public set switcherOrderSideColumn(value: number) {
    this._switcherOrderSideColumn = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 방풍벽(측면)
   */
  public get switcherOrderSideWing(): number {
    return this._switcherOrderSideWing;
  }

  public set switcherOrderSideWing(value: number) {
    this._switcherOrderSideWing = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 지붕
   */
  public get switcherOrderRoof(): number {
    return this._switcherOrderRoof;
  }

  public set switcherOrderRoof(value: number) {
    this._switcherOrderRoof = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 앞면
   */
  public get switcherOrderFront(): number {
    return this._switcherOrderFront;
  }

  public set switcherOrderFront(value: number) {
    this._switcherOrderFront = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 뒷면
   */
  public get switcherOrderBack(): number {
    return this._switcherOrderBack;
  }

  public set switcherOrderBack(value: number) {
    this._switcherOrderBack = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 치마 피복 - 기둥(측면)
   */
  public get skirtCoverSideColumn(): boolean {
    return this._skirtCoverSideColumn;
  }

  public set skirtCoverSideColumn(value: boolean) {
    this._skirtCoverSideColumn = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).columnPosition.normalPart.algorithmPart();
    (<SkirtLevelIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index]
    )).columnPosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevelIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index]
    )).columnPosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 치마 피복 - 방풍벽(측면)
   */
  public get skirtCoverSideWing(): boolean {
    return this._skirtCoverSideWing;
  }

  public set skirtCoverSideWing(value: boolean) {
    this._skirtCoverSideWing = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).wingPosition.normalPart.algorithmPart();
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).wingPosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).wingPosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 치마 피복 - 지붕
   */
  public get skirtCoverRoof(): boolean {
    return this._skirtCoverRoof;
  }

  public set skirtCoverRoof(value: boolean) {
    this._skirtCoverRoof = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).roofPosition.normalPart.algorithmPart();
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).roofPosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).roofPosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 치마 피복 - 앞면
   */
  public get skirtCoverFront(): boolean {
    return this._skirtCoverFront;
  }

  public set skirtCoverFront(value: boolean) {
    this._skirtCoverFront = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).frontPosition.normalPart.algorithmPart();
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).frontPosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevelIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index]
    )).frontPosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 치마 피복 - 뒷면
   */
  public get skirtCoverBack(): boolean {
    return this._skirtCoverBack;
  }

  public set skirtCoverBack(value: boolean) {
    this._skirtCoverBack = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).backPosition.normalPart.algorithmPart();
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).backPosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).backPosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 바람막이 피복 - 기둥(측면)
   */
  public get windbreakCoverSideColumn(): boolean {
    return this._windbreakCoverSideColumn;
  }

  public set windbreakCoverSideColumn(value: boolean) {
    this._windbreakCoverSideColumn = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWorkIL.algorithmBasic();
  }

  /**
   * 바람막이 피복 - 방풍벽(측면)
   */
  public get windbreakCoverSideWing(): boolean {
    return this._windbreakCoverSideWing;
  }

  public set windbreakCoverSideWing(value: boolean) {
    this._windbreakCoverSideWing = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWorkIL.algorithmBasic();
  }

  /**
   * 바람막이 피복 - 지붕
   */
  public get windbreakCoverRoof(): boolean {
    return this._windbreakCoverRoof;
  }

  public set windbreakCoverRoof(value: boolean) {
    this._windbreakCoverRoof = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWorkIL.algorithmBasic();
  }

  /**
   * 바람막이 피복 - 앞면
   */
  public get windbreakCoverFront(): boolean {
    return this._windbreakCoverFront;
  }

  public set windbreakCoverFront(value: boolean) {
    this._windbreakCoverFront = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWorkIL.algorithmBasic();
  }

  /**
   * 바람막이 피복 - 뒷면
   */
  public get windbreakCoverBack(): boolean {
    return this._windbreakCoverBack;
  }

  public set windbreakCoverBack(value: boolean) {
    this._windbreakCoverBack = value;

    // 알고리즘
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWorkIL.algorithmBasic();
  }

  /**
   * 천창 피복
   */
  public get skylightCover(): boolean {
    return this._skylightCover;
  }

  public set skylightCover(value: boolean) {
    this._skylightCover = value;

    // 알고리즘
    // (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 천창 피복
    if (this.level.index === 0) {
      this.struct.skyCoverWorkIL.level1.skylightPosition.vinylPart.algorithmPart();
    }
  }

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();
    this.algorithm_switcherOrderValue();
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    this.algorithm_skylightCoverSelected();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    // 중수
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_scopeTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    (<CoverLevelIL>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 알고리즘 호출: 피복 파트
    (<CoverLevelIL>this.level).callAlgorithm_coverPartByCover();

    // 알고리즘 호출: 피복 고정 파트
    (<CoverLevelIL>this.level).callAlgorithm_fixingPartByCover();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.vinylPart = new CoverPartIL_Vinyl();
    this.weavingFilmPart = new CoverPartIL_WeavingFilm();
    this.shadenetPart = new CoverPartIL_Shadenet();
    this.tentPart = new CoverPartIL_Tent();
    this.nonwovenPart = new CoverPartIL_Nonwoven();
    this.cashmilonPart = new CoverPartIL_Cashmilon();
    this.colorLaggingPart = new CoverPartIL_ColorLagging();
    this.laggingPart = new CoverPartIL_Lagging();
    this.quiltingPart = new CoverPartIL_Quilting();
    this.screenPart = new CoverPartIL_Screen();

    this.partAC = [
      this.vinylPart,
      this.weavingFilmPart,
      this.shadenetPart,
      this.tentPart,
      this.nonwovenPart,
      this.cashmilonPart,
      this.colorLaggingPart,
      this.laggingPart,
      this.quiltingPart,
      this.screenPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.vinylPart = <CoverPartIL_Vinyl>this.partAC[0];
    this.weavingFilmPart = <CoverPartIL_WeavingFilm>this.partAC[1];
    this.shadenetPart = <CoverPartIL_Shadenet>this.partAC[2];
    this.tentPart = <CoverPartIL_Tent>this.partAC[3];
    this.nonwovenPart = <CoverPartIL_Nonwoven>this.partAC[4];
    this.cashmilonPart = <CoverPartIL_Cashmilon>this.partAC[5];
    this.colorLaggingPart = <CoverPartIL_ColorLagging>this.partAC[6];
    this.laggingPart = <CoverPartIL_Lagging>this.partAC[7];
    this.quiltingPart = <CoverPartIL_Quilting>this.partAC[8];
    this.screenPart = <CoverPartIL_Screen>this.partAC[9];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param scopeType: string 범위 형태
   * @param switcherOrderSideColumn: number 개폐 차수 - 기둥(측면)
   * @param switcherOrderSideWing: number 개폐 차수 - 방풍벽(측면)
   * @param switcherOrderRoof: number 개폐 차수 - 지붕
   * @param switcherOrderFront: number 개폐 차수 - 앞면
   * @param switcherOrderBack: number 개폐 차수 - 뒷면
   * @param skirtCoverSideColumn: boolean 치마 피복 - 기둥(측면)
   * @param skirtCoverSideWing: boolean 치마 피복 - 방풍벽(측면)
   * @param skirtCoverRoof: boolean 치마 피복 - 지붕
   * @param skirtCoverFront: boolean 치마 피복 - 앞면
   * @param skirtCoverBack: boolean 치마 피복 - 뒷면
   * @param windbreakCoverSideColumn: boolean 바람막이 피복 - 기둥(측면)
   * @param windbreakCoverSideWing: boolean 바람막이 피복 - 방풍벽(측면)
   * @param windbreakCoverRoof: boolean 바람막이 피복 - 지붕
   * @param windbreakCoverFront: boolean 바람막이 피복 - 앞면
   * @param windbreakCoverBack: boolean 바람막이 피복 - 뒷면
   * @param skylightCover: boolean 천창 피복
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    scopeType: string = "",
    switcherOrderSideColumn: number = 0,
    switcherOrderSideWing: number = 0,
    switcherOrderRoof: number = 0,
    switcherOrderFront: number = 0,
    switcherOrderBack: number = 0,
    skirtCoverSideColumn: boolean = false,
    skirtCoverSideWing: boolean = false,
    skirtCoverRoof: boolean = false,
    skirtCoverFront: boolean = false,
    skirtCoverBack: boolean = false,
    windbreakCoverSideColumn: boolean = false,
    windbreakCoverSideWing: boolean = false,
    windbreakCoverRoof: boolean = false,
    windbreakCoverFront: boolean = false,
    windbreakCoverBack: boolean = false,
    skylightCover: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._scopeType = scopeType;
    this._switcherOrderSideColumn = switcherOrderSideColumn;
    this._switcherOrderSideWing = switcherOrderSideWing;
    this._switcherOrderRoof = switcherOrderRoof;
    this._switcherOrderFront = switcherOrderFront;
    this._switcherOrderBack = switcherOrderBack;
    this._skirtCoverSideColumn = skirtCoverSideColumn;
    this._skirtCoverSideWing = skirtCoverSideWing;
    this._skirtCoverRoof = skirtCoverRoof;
    this._skirtCoverFront = skirtCoverFront;
    this._skirtCoverBack = skirtCoverBack;
    this._windbreakCoverSideColumn = windbreakCoverSideColumn;
    this._windbreakCoverSideWing = windbreakCoverSideWing;
    this._windbreakCoverRoof = windbreakCoverRoof;
    this._windbreakCoverFront = windbreakCoverFront;
    this._windbreakCoverBack = windbreakCoverBack;
    this._skylightCover = skylightCover;

    if (this.level.index >= 0) {
      // 비닐
      if (type === CONST.LB_COVER_TYPE_VINYL) {
        this.vinylPart.setDefaultData(CONST.INDEX_IL_COVER_VINYL, true, false, true, CONST.LB_COVER_VINYL, "");
      } else {
        this.vinylPart.setDefaultData(CONST.INDEX_IL_COVER_VINYL, false, false, false, CONST.LB_COVER_VINYL, "");
      }

      // 직조 필름
      if (type === CONST.LB_COVER_TYPE_WEAVING_FILM) {
        this.weavingFilmPart.setDefaultData(
          CONST.INDEX_IL_COVER_WEAVING_FILM,
          true,
          false,
          true,
          CONST.LB_COVER_WEAVING_FILM,
          "",
        );
      } else {
        this.weavingFilmPart.setDefaultData(
          CONST.INDEX_IL_COVER_WEAVING_FILM,
          false,
          false,
          false,
          CONST.LB_COVER_WEAVING_FILM,
          "",
        );
      }

      // 차광망
      if (type === CONST.LB_COVER_TYPE_SHADENET) {
        this.shadenetPart.setDefaultData(CONST.INDEX_IL_COVER_SHADENET, true, false, true, CONST.LB_COVER_SHADENET, "");
      } else {
        this.shadenetPart.setDefaultData(
          CONST.INDEX_IL_COVER_SHADENET,
          false,
          false,
          false,
          CONST.LB_COVER_SHADENET,
          "",
        );
      }

      // 텐텐지
      if (type === CONST.LB_COVER_TYPE_TENT) {
        this.tentPart.setDefaultData(CONST.INDEX_IL_COVER_TENT, true, false, true, CONST.LB_COVER_TENT, "");
      } else {
        this.tentPart.setDefaultData(CONST.INDEX_IL_COVER_TENT, false, false, false, CONST.LB_COVER_TENT, "");
      }

      // 부직포
      if (type === CONST.LB_COVER_TYPE_NONWOVEN) {
        this.nonwovenPart.setDefaultData(CONST.INDEX_IL_COVER_NONWOVEN, true, false, true, CONST.LB_COVER_NONWOVEN, "");
      } else {
        this.nonwovenPart.setDefaultData(
          CONST.INDEX_IL_COVER_NONWOVEN,
          false,
          false,
          false,
          CONST.LB_COVER_NONWOVEN,
          "",
        );
      }

      // 카시미론
      if (type === CONST.LB_COVER_TYPE_CASHMILON) {
        this.cashmilonPart.setDefaultData(
          CONST.INDEX_IL_COVER_CASHMILON,
          true,
          false,
          true,
          CONST.LB_COVER_CASHMILON,
          "",
          false,
        );
      } else {
        this.cashmilonPart.setDefaultData(
          CONST.INDEX_IL_COVER_CASHMILON,
          false,
          false,
          false,
          CONST.LB_COVER_CASHMILON,
          "",
          false,
        );
      }

      // 칼라 보온덮개
      if (type === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
        this.colorLaggingPart.setDefaultData(
          CONST.INDEX_IL_COVER_LAGGING_COLOR,
          true,
          false,
          true,
          CONST.LB_COVER_LAGGING_COLOR,
          "",
          false,
        );
      } else {
        this.colorLaggingPart.setDefaultData(
          CONST.INDEX_IL_COVER_LAGGING_COLOR,
          false,
          false,
          false,
          CONST.LB_COVER_LAGGING_COLOR,
          "",
          false,
        );
      }

      // 보온덮개
      if (type === CONST.LB_COVER_TYPE_LAGGING) {
        this.laggingPart.setDefaultData(
          CONST.INDEX_IL_COVER_LAGGING,
          true,
          false,
          true,
          CONST.LB_COVER_LAGGING,
          "",
          false,
        );
      } else {
        this.laggingPart.setDefaultData(
          CONST.INDEX_IL_COVER_LAGGING,
          false,
          false,
          false,
          CONST.LB_COVER_LAGGING,
          "",
          false,
        );
      }

      // 다겹 커튼지
      if (type === CONST.LB_COVER_TYPE_QUILTING) {
        this.quiltingPart.setDefaultData(CONST.INDEX_IL_COVER_QUILTING, true, false, true, CONST.LB_COVER_QUILTING, "");
      } else {
        this.quiltingPart.setDefaultData(
          CONST.INDEX_IL_COVER_QUILTING,
          false,
          false,
          false,
          CONST.LB_COVER_QUILTING,
          "",
        );
      }

      // 스크린
      if (type === CONST.LB_COVER_TYPE_SCREEN) {
        this.screenPart.setDefaultData(CONST.INDEX_IL_COVER_SCREEN, true, false, true, CONST.LB_COVER_SCREEN, "");
      } else {
        this.screenPart.setDefaultData(CONST.INDEX_IL_COVER_SCREEN, false, false, false, CONST.LB_COVER_SCREEN, "");
      }
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.algorithm_scopeSelectedByScopeType();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 7) {
      // 직조 필름
      const weavingFilmPart: CoverPartIL_WeavingFilm = new CoverPartIL_WeavingFilm();
      weavingFilmPart.setAssociation(design, struct, work, level, this);
      weavingFilmPart.setDefaultData(
        CONST.INDEX_IL_COVER_WEAVING_FILM,
        false,
        false,
        false,
        CONST.LB_COVER_WEAVING_FILM,
        "",
      );
      this.partAC.splice(1, 0, weavingFilmPart);
    }
    if (this.partAC.length === 8) {
      // 다겹 커튼지
      const quiltingPart: CoverPartIL_Quilting = new CoverPartIL_Quilting();
      quiltingPart.setAssociation(design, struct, work, level, this);
      quiltingPart.setDefaultData(CONST.INDEX_IL_COVER_QUILTING, false, false, false, CONST.LB_COVER_QUILTING, "");
      this.partAC.push(quiltingPart);
      // 스크린
      const screenPart: CoverPartIL_Screen = new CoverPartIL_Screen();
      screenPart.setAssociation(design, struct, work, level, this);
      screenPart.setDefaultData(CONST.INDEX_IL_COVER_SCREEN, false, false, false, CONST.LB_COVER_SCREEN, "");
      this.partAC.push(screenPart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_scopeTypeByType();
    this.algorithm_scopeSelectedByScopeType();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 선택(기둥 범위) <- 선택(방풍벽), 선택(커튼 피복)
   */
  public algorithm_selectedAtColumn(): void {
    let wingPosition: WingPositionIL | WingPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      wingPosition = this.struct.wingWorkIL.level1.wingPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      wingPosition = this.struct.wingWorkVL.level1.wingPosition;
    }
    const curCoverPosition: CurCoverPositionIL = this.struct.curCoverWorkIL.level1.curCoverPosition;

    if (this.level.index === 0 && this.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN) {
      if (wingPosition.selected === true) {
        if (this.design.preference.curtainColumnReplaceFlag === true) {
          this.selected = !curCoverPosition.selected;
        } else {
          this.selected = true;
        }
      } else {
        this.selected = false;
      }
    }
  }

  /**
   * 알고리즘: 선택(방풍벽(측면) 범위) <- 선택(방풍벽)
   */
  public algorithm_selectedAtWing(): void {
    const coverLevel: CoverLevelIL = <CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index];
    let wingPosition: WingPositionIL | WingPositionVL;
    let wingWork: WingWorkIL | WingWorkVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      wingWork = this.struct.wingWorkIL;
      wingPosition = (<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      wingWork = this.struct.wingWorkVL;
      wingPosition = (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition;
    }

    for (let l: number = 0; l <= wingWork.levelAC.length; l++) {
      for (let i: number = 0; i <= coverLevel.coverLayers[this.index]; i++) {
        if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_WING) {
          this.selected = wingPosition.selected;
        } else {
          this.selected = this.selected;
        }
      }
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    // 일단 모두 false
    let part: Part;
    for (part of this.partAC) {
      part.selected = false;
      part.visible = false;
    }
    // 해당 파트만 true
    if (this.type === CONST.LB_COVER_TYPE_VINYL) {
      this.vinylPart.selected = true;
      this.vinylPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_WEAVING_FILM) {
      this.weavingFilmPart.selected = true;
      this.weavingFilmPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_SHADENET) {
      this.shadenetPart.selected = true;
      this.shadenetPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_TENT) {
      this.tentPart.selected = true;
      this.tentPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_NONWOVEN) {
      this.nonwovenPart.selected = true;
      this.nonwovenPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_CASHMILON) {
      this.cashmilonPart.selected = true;
      this.cashmilonPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
      this.colorLaggingPart.selected = true;
      this.colorLaggingPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_LAGGING) {
      this.laggingPart.selected = true;
      this.laggingPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_QUILTING) {
      this.quiltingPart.selected = true;
      this.quiltingPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_SCREEN) {
      this.screenPart.selected = true;
      this.screenPart.visible = true;
    }
  }

  /**
   * 알고리즘: 위치별 범위 선택 <- 범위 형태, 선택(방풍벽), 동수(기본정보) <br/>
   * 피복의 범위 형태에 따라서 측면, 지붕, 앞면, 뒷면의 선택을 결정해줌 <br/>
   * 특히, 측면의 경우는 기둥과 방풍벽으로 나뉘는게 이는 별도 함수에서 결정함.
   */
  public algorithm_scopeSelectedByScopeType(): void {
    if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK) {
      this.scopeSelectedSide = true;
      this.algorithm_scopeSelectedSideDetail();
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = false;
      this.scopeSelectedBack = false;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE) {
      this.scopeSelectedSide = true;
      this.algorithm_scopeSelectedSideDetail();
      this.scopeSelectedRoof = true;
      if (this.basic.buildingNumber >= 2) {
        this.scopeSelectedFront = true;
        this.scopeSelectedBack = true;
        this.scopeSelectedFrontIncluded = false;
        this.scopeSelectedBackIncluded = false;
      } else {
        this.scopeSelectedFront = true;
        this.scopeSelectedBack = true;
        this.scopeSelectedFrontIncluded = true;
        this.scopeSelectedBackIncluded = true;
      }
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE) {
      this.scopeSelectedSide = true;
      this.algorithm_scopeSelectedSideDetail();
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF) {
      this.scopeSelectedSide = false;
      this.scopeSelectedSideColumn = false;
      this.scopeSelectedSideWing = false;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = false;
      this.scopeSelectedBack = false;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE) {
      this.scopeSelectedSide = false;
      this.scopeSelectedSideColumn = false;
      this.scopeSelectedSideWing = false;
      this.scopeSelectedRoof = true;
      if (this.basic.buildingNumber >= 2) {
        this.scopeSelectedFront = true;
        this.scopeSelectedBack = true;
        this.scopeSelectedFrontIncluded = false;
        this.scopeSelectedBackIncluded = false;
      } else {
        this.scopeSelectedFront = true;
        this.scopeSelectedBack = true;
        this.scopeSelectedFrontIncluded = true;
        this.scopeSelectedBackIncluded = true;
      }
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE) {
      this.scopeSelectedSide = false;
      this.scopeSelectedSideColumn = false;
      this.scopeSelectedSideWing = false;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_WING) {
      this.scopeSelectedSide = true;
      this.scopeSelectedSideColumn = false;
      this.scopeSelectedSideWing = true;
      this.scopeSelectedRoof = false;
      this.scopeSelectedFront = false;
      this.scopeSelectedBack = false;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN) {
      this.scopeSelectedSide = true;
      this.scopeSelectedSideColumn = true;
      this.scopeSelectedSideWing = false;
      this.scopeSelectedRoof = false;
      this.scopeSelectedFront = false;
      this.scopeSelectedBack = false;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_SIDE_SEP_ENDPIECE) {
      this.scopeSelectedSide = true;
      this.algorithm_scopeSelectedSideDetail();
      this.scopeSelectedRoof = false;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ENDPIECE) {
      this.scopeSelectedSide = false;
      this.scopeSelectedSideColumn = false;
      this.scopeSelectedSideWing = false;
      this.scopeSelectedRoof = false;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
    }
  }

  /**
   * 알고리즘: 측면 범위 선택 <- 범위 형태 <br/>
   * 피복의 범위 형태 중, '측면'이 기둥(측면)인지 방풍벽(측면)인지 결정함. <br/>
   * 결정하는 방법은 현재 피복의 중수를 기준으로 방풍벽이 설치된 중수를 비교함.
   */
  protected algorithm_scopeSelectedSideDetail(): void {
    // 추후 확인
    let wingWorkIL: WingWorkIL;
    let wingWorkVL: WingWorkVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      wingWorkIL = this.struct.wingWorkIL;
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      wingWorkVL = this.struct.wingWorkVL;
    }

    if (this.scopeSelectedSide === true) {
      if (
        this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        if (this.level.index === 0) {
          if (wingWorkIL.level1.wingPosition.selected === true) {
            this.scopeSelectedSideWing = true;
            this.scopeSelectedSideColumn = false;
          } else {
            this.scopeSelectedSideWing = false;
            this.scopeSelectedSideColumn = true;
          }
        } else if (this.level.index === 1) {
          if (wingWorkIL.level2.wingPosition.selected === true) {
            this.scopeSelectedSideWing = true;
            this.scopeSelectedSideColumn = false;
          } else {
            if (wingWorkIL.level1.wingPosition.selected === true) {
              this.scopeSelectedSideWing = true;
              this.scopeSelectedSideColumn = false;
            } else {
              this.scopeSelectedSideWing = false;
              this.scopeSelectedSideColumn = true;
            }
          }
        } else if (this.level.index === 2) {
          if (wingWorkIL.level3.wingPosition.selected === true) {
            this.scopeSelectedSideWing = true;
            this.scopeSelectedSideColumn = false;
          } else {
            if (wingWorkIL.level2.wingPosition.selected === true) {
              this.scopeSelectedSideWing = true;
              this.scopeSelectedSideColumn = false;
            } else {
              if (wingWorkIL.level1.wingPosition.selected === true) {
                this.scopeSelectedSideWing = true;
                this.scopeSelectedSideColumn = false;
              } else {
                this.scopeSelectedSideWing = false;
                this.scopeSelectedSideColumn = true;
              }
            }
          }
        }
      } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
        if (this.level.index === 0) {
          if (wingWorkVL.level1.wingPosition.selected === true) {
            this.scopeSelectedSideWing = true;
            this.scopeSelectedSideColumn = false;
          } else {
            this.scopeSelectedSideWing = false;
            this.scopeSelectedSideColumn = true;
          }
        } else if (this.level.index === 1) {
          if (wingWorkVL.level2.wingPosition.selected === true) {
            this.scopeSelectedSideWing = true;
            this.scopeSelectedSideColumn = false;
          } else {
            if (wingWorkVL.level1.wingPosition.selected === true) {
              this.scopeSelectedSideWing = true;
              this.scopeSelectedSideColumn = false;
            } else {
              this.scopeSelectedSideWing = false;
              this.scopeSelectedSideColumn = true;
            }
          }
        }
      }
    } else {
      this.scopeSelectedSideWing = false;
      this.scopeSelectedSideColumn = false;
    }
  }

  /**
   * 알고리즘: 개폐 차수 <- 선택, 범위 형태
   */
  public algorithm_switcherOrderValue(): void {
    this.switcherOrderSideColumn = this.selected && this.scopeSelectedSideColumn ? 1 : 0;
    this.switcherOrderSideWing = this.selected && this.scopeSelectedSideWing ? 1 : 0;
    this.switcherOrderRoof = this.selected && this.scopeSelectedRoof ? 1 : 0;
    this.switcherOrderFront = this.selected && this.scopeSelectedFront ? 1 : 0;
    this.switcherOrderBack = this.selected && this.scopeSelectedBack ? 1 : 0;
  }

  /**
   * 알고리즘: 치마 피복 선택 <- 선택, 범위 형태, 개폐 차수
   */
  public algorithm_skirtCoverSelected(): void {
    this.skirtCoverSideColumn = this.selected && this.scopeSelectedSideColumn && this.switcherOrderSideColumn > 0;
    this.skirtCoverSideWing = this.selected && this.scopeSelectedSideWing && this.switcherOrderSideWing > 0;
    this.skirtCoverRoof = this.selected && this.scopeSelectedRoof && this.switcherOrderRoof > 0;
    this.skirtCoverFront = this.selected && this.scopeSelectedFront && this.switcherOrderFront > 0;
    this.skirtCoverBack = this.selected && this.scopeSelectedBack && this.switcherOrderBack > 0;
  }

  /**
   * 알고리즘: 바람막이 피복 선택 <- 선택, 범위 형태, 개폐 차수
   */
  public algorithm_windbreakCoverSelected(): void {
    this.windbreakCoverSideColumn = this.selected && this.scopeSelectedSideColumn && this.switcherOrderSideColumn > 0;
    this.windbreakCoverSideWing = this.selected && this.scopeSelectedSideWing && this.switcherOrderSideWing > 0;
    this.windbreakCoverRoof = this.selected && this.scopeSelectedRoof && this.switcherOrderRoof > 0;
    this.windbreakCoverFront = this.selected && this.scopeSelectedFront && this.switcherOrderFront > 0;
    this.windbreakCoverBack = this.selected && this.scopeSelectedBack && this.switcherOrderBack > 0;
  }

  /**
   * 알고리즘: 천창 피복 선택 <- 선택, 범위 형태, 위치
   */
  public algorithm_skylightCoverSelected(): void {
    this.skylightCover = this.selected && this.scopeSelectedRoof;
  }

  /**
   * 알고리즘: 범위 형태 <- 형태
   */
  public algorithm_scopeTypeByType(): void {
    if (this.basic.buildingNumber === 1) {
      if (
        this.type === CONST.LB_COVER_TYPE_CASHMILON ||
        this.type === CONST.LB_COVER_TYPE_COLOR_LAGGING ||
        this.type === CONST.LB_COVER_TYPE_LAGGING
      ) {
        if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE) {
          this.scopeType = CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE;
        } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE) {
          this.scopeType = CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE;
        }
      } else {
        if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE) {
          this.scopeType = CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE;
        } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE) {
          this.scopeType = CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE;
        }
      }
    } else {
      if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE;
      } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE;
      }
    }
  }

  /**
   * 알고리즘: 범위 형태 <- 선택(천창 골조)
   */
  public algorithm_scopeTypeBySkylight(): void {
    const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;

    if (skyFramePosition.selected === true) {
      // 마구리 피복을 분리
      if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE;
      } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
