import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ItemPowerSwitcher } from "vhows-design/src/object/design/item/list/ItemPowerSwitcher";
import { ItemSwitcherOther } from "vhows-design/src/object/design/item/list/ItemSwitcherOther";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkySwitchPosition } from "vhows-design/src/object/design/skylight/switches/SkySwitchPosition";
import { SkySwitchSample_PowerSwitcher } from "vhows-design/src/object/design/skylight/switches/SkySwitchSample_PowerSwitcher";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-18
 */
@jsonObject({
  knownTypes: [SkySwitchSample_PowerSwitcher],
})
export class SkySwitchPart_Power extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public powerSwitcherSample: SkySwitchSample_PowerSwitcher; // 동력 개폐기 샘플

  // 아이템
  public power_powerSwitcher: ItemPowerSwitcher;
  public power_switcherProtectionRubber: ItemSwitcherOther;
  public power_electricWire: ItemElectricWire;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.powerSwitcherSample = new SkySwitchSample_PowerSwitcher();

    this.sampleAC = [this.powerSwitcherSample];

    // 아이템
    this.power_powerSwitcher = new ItemPowerSwitcher();
    this.power_switcherProtectionRubber = new ItemSwitcherOther();
    this.power_electricWire = new ItemElectricWire();

    this.itemAC = [this.power_powerSwitcher, this.power_switcherProtectionRubber, this.power_electricWire];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.powerSwitcherSample = <SkySwitchSample_PowerSwitcher>this.sampleAC[0];

    // 아이템
    this.power_powerSwitcher = <ItemPowerSwitcher>this.itemAC[0];
    this.power_switcherProtectionRubber = <ItemSwitcherOther>this.itemAC[1];
    this.power_electricWire = <ItemElectricWire>this.itemAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.powerSwitcherSample.setDefaultData(
        0,
        CONST.ITEM_ID_POWER_SWITCHER,
        CONST.ITEM_NAME_POWER_SWITCHER,
        "천창 동력 개폐",
        "비닐용, 25mm, 25mm, 32mm, 25mm",
        "청오, CODM 81024, 4.5kg·m, 4m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.power_powerSwitcher.setDefaultData(
        0,
        CONST.ITEM_ID_POWER_SWITCHER,
        CONST.ITEM_NAME_POWER_SWITCHER,
        "천창 동력 개폐",
        "비닐용, 25mm, 25mm, 32mm, 25mm",
        "청오, CODM 81024, 4.5kg·m, 4m",
      );
      this.power_switcherProtectionRubber.setDefaultData(
        1,
        CONST.ITEM_ID_SWITCHER_PROTECTION_RUBBER,
        CONST.ITEM_NAME_SWITCHER_PROTECTION_RUBBER,
        "천창 동력 개폐",
        "일반",
      );
      this.power_electricWire.setDefaultData(
        2,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "천창 동력 개폐",
        "VCTF, 1.5sq, 2C, 100m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skySwitchPosition: SkySwitchPosition = <SkySwitchPosition>this.position;
    let widthQuanity: number;
    let heightQuantity: number;

    /// //////// 선택, 가시성 ///////////

    this.power_powerSwitcher.selected = true;
    this.power_powerSwitcher.visible = true;
    this.power_switcherProtectionRubber.selected = this.design.preference.switcherProtectionRubberFlag;
    this.power_switcherProtectionRubber.visible = true;
    this.power_electricWire.selected = true;
    this.power_electricWire.visible = true;

    /// //////// 수량 ///////////

    this.power_powerSwitcher.designQuantity = skySwitchPosition.switchWayValue * this.basic.buildingNumber;

    this.power_switcherProtectionRubber.designQuantity = skySwitchPosition.switchWayValue * this.basic.buildingNumber;

    widthQuanity =
      ((this.basicLevel.width + this.basic.buildingInterval) *
        this.basic.buildingNumber *
        (this.basic.buildingNumber + 1)) /
      2;
    heightQuantity = (this.basicLevel.heightMax + 0.5) * this.basic.buildingNumber;
    this.power_electricWire.designQuantity =
      (((widthQuanity + heightQuantity) * skySwitchPosition.switchWayValue) / this.power_electricWire.specLength) *
      CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 동력 개폐] 동력 개폐기 <- 동력 개폐기 샘플
   */
  public algorithmSpec_power_powerSwitcher(): void {
    this.power_powerSwitcher.specs = this.powerSwitcherSample.specs;
    this.power_powerSwitcher.brands = this.powerSwitcherSample.brands;
  }

  /**
   * 규격 알고리즘: [천창 동력 개폐] 개폐기 보호고무 <- 공통 샘플
   */
  public algorithmSpec_power_switcherProtectionRubber(): void {}

  /**
   * 규격 알고리즘: [천창 동력 개폐] 전선 <- 동력 개폐기용 전선 샘플
   */
  public algorithmSpec_power_electricWire(): void {
    this.power_electricWire.specs =
      this.struct.controllerWork.level1.integratedPosition.controllerPart.switcherElectricWireSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
