import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { SkyFramePart_Rafter } from "vhows-design/src/object/design/skylight/frame/SkyFramePart_Rafter";
import { SkyFramePart_Straight } from "vhows-design/src/object/design/skylight/frame/SkyFramePart_Straight";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-18
 */
@jsonObject
export class SkyFrameSample_RafterPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const rafterPart: SkyFramePart_Rafter = <SkyFramePart_Rafter>this.part;
    rafterPart.algorithmSpec_skyRafter_pipe();
    rafterPart.algorithmSpec_skyRafter_pipeBendingExpense();
    rafterPart.algorithmSpec_skyRafterAndStraight_tHolder();
    rafterPart.algorithmSpec_skyRafterAndSkyColumn_diagonalTHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const straightPart: SkyFramePart_Straight = (<SkyFramePosition>this.position).straightPart;
    straightPart.skyStraightPipeSample.algorithmSpec();
    straightPart.algorithmSpec_skyStraightAndSkyRafter_steelStringGrabber();
    straightPart.algorithmSpec_skyStraightAndSkyRafter_tHolder();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 가로대 파이프 샘플(기초 골조), 형태(위치), 천창 서까래 길이, 천창 기둥 길이
   */
  // @override
  public algorithmSpec(): void {
    const trunkPosition: TrunkPosition = this.struct.trunkWork.level1.trunkPosition;
    // 하드코딩
    if (trunkPosition.straightPart.straightRoofSubPipeSample.specsList === "원형, 일반, 31.8mm, 1.7T") {
      this.specsList = "원형, 일반, 31.8mm, 1.5T";
    } else {
      this.specsList = trunkPosition.straightPart.straightRoofSubPipeSample.specsList;
    }
    if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH) {
      this.specLength = (<SkyFramePart_Rafter>this.part).rafterLength;
    } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE) {
      this.specLength = (<SkyFramePart_Rafter>this.part).rafterLength + (<SkyFramePart_Rafter>this.part).columnLength;
    } else if (this.position.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.specLength = (<SkyFramePart_Rafter>this.part).rafterLength + (<SkyFramePart_Rafter>this.part).columnLength;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
