import { ArrowLeftIcon } from "ui";
import Image from "next/image";
import { useRouter } from "next/router";
import { ROUTE } from "constants/route";
import { useSetAtom } from "jotai";
import { cancelModalAtom } from "store/global";
import { checkVersion, QA_FLAG_STAGING } from "@vhows/util";

type Header = {
  title?: string;
  callBacks: Function;
};

const Header = ({ title, callBacks }: Header) => {
  const router = useRouter();
  const setIsVisible = useSetAtom(cancelModalAtom);
  const inVisibleRoutePath = checkVersion("3.0.0", QA_FLAG_STAGING)
    ? [ROUTE.INDEX, ROUTE.RESULT]
    : [ROUTE.TYPE, ROUTE.INDEX];

  return (
    <>
      <div className="relative">
        <div
          className={`fixed z-20 m-auto h-14 w-full max-w-2xl bg-white ${
            router.pathname === ROUTE.APPLICATION && "bg-[#F4F8FA]"
          }`}
        >
          <a
            className="absolute h-14 w-14 cursor-pointer p-5 text-center"
            id={"backButton"}
            onClick={(e: any) => {
              callBacks(e);
            }}
          >
            <ArrowLeftIcon fill={"#1F2024"} />
          </a>
          <div className="absolute left-1/2 w-full -translate-x-1/2 py-3.5 text-center text-xl font-bold text-white"></div>
          {inVisibleRoutePath.indexOf(router.pathname) === -1 && (
            <a className="absolute right-0 h-14 cursor-pointer p-5 leading-none" onClick={() => setIsVisible(true)}>
              <Image src="/icons/close-icon.svg" alt="close" width="14" height="14"></Image>
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
