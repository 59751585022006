import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { MaterialPart_Custom } from "vhows-design/src/object/design/other/material/MaterialPart_Custom";
import { HydCultivationPosition } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPosition";
import { HydSystemPart_HydSupply } from "vhows-design/src/object/design/hydroponics/system/HydSystemPart_HydSupply";
import { HydSystemPart_HydDrain } from "vhows-design/src/object/design/hydroponics/system/HydSystemPart_HydDrain";
import { HydSystemPart_HydControl } from "vhows-design/src/object/design/hydroponics/system/HydSystemPart_HydControl";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2020-02-21
 */
@jsonObject({
  knownTypes: [HydSystemPart_HydControl, HydSystemPart_HydSupply, HydSystemPart_HydDrain, MaterialPart_Custom],
})
export class HydSystemPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public hydcontrolPart: HydSystemPart_HydControl;
  public hydSupplyPart: HydSystemPart_HydSupply;
  public hydDrainPart: HydSystemPart_HydDrain;
  public customPart: MaterialPart_Custom;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _nutrientControlArea: number = 0; // 양액 제어구역
  @jsonMember(String)
  public _cycleType: string; // 순환 형태
  @jsonMember(Number)
  public _cultivationArea: number = 0; // 재배부 면적
  @jsonMember(Number)
  public _cultivationWidth: number = 0; // 재배부 폭
  @jsonMember(Number)
  public _cultivationLength: number = 0; // 재배부 길이
  @jsonMember(Number)
  public _workroomArea: number = 0; // 작업부 면적
  @jsonMember(Number)
  public _workroomWidth: number = 0; // 작업부 폭
  @jsonMember(Number)
  public _workroomLength: number = 0; // 작업부 길이

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 양액 제어구역
   */
  public get nutrientControlArea(): number {
    return this._nutrientControlArea;
  }

  //
  public set nutrientControlArea(value: number) {
    this._nutrientControlArea = value;

    // 알고리즘
    this.hydSupplyPart.algorithm_supplyMainALength();
    this.hydSupplyPart.algorithm_supplyMainBLength();
    this.hydDrainPart.algorithm_sampleDesignQuantity();
    this.hydDrainPart.algorithm_drainMainLength();
    this.hydDrainPart.algorithm_recoveryMainLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 순환 형태
   */
  public get cycleType(): string {
    return this._cycleType;
  }

  //
  public set cycleType(value: string) {
    this._cycleType = value;

    // 알고리즘
    this.hydDrainPart.algorithm_recoveryWaterFilterSelected();
    this.hydDrainPart.algorithm_recoveryMainLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 재배부 면적
   */
  public get cultivationArea(): number {
    return this._cultivationArea;
  }

  //
  public set cultivationArea(value: number) {
    this._cultivationArea = value;

    // 알고리즘
    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 재배부 폭
   */
  public get cultivationWidth(): number {
    return this._cultivationWidth;
  }

  //
  public set cultivationWidth(value: number) {
    this._cultivationWidth = value;

    // 알고리즘
    this.algorithm_cultivationArea();
    this.hydSupplyPart.algorithm_supplyMainALength();
    this.hydSupplyPart.algorithm_supplyMainBLength();
    this.hydDrainPart.algorithm_drainMainLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 재배부 길이
   */
  public get cultivationLength(): number {
    return this._cultivationLength;
  }

  //
  public set cultivationLength(value: number) {
    if (value === this._cultivationLength) {
      return;
    }
    this._cultivationLength = value;

    // 알고리즘
    this.algorithm_cultivationArea();
    this.hydSupplyPart.algorithm_supplyMainALength();
    this.hydDrainPart.algorithm_recoveryMainLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
    this.algorithm_workroomLength();

    /// //////// 외부 ///////////

    // 양액 베드부
    this.struct.hydCultivationWork.level1.wholePosition.bedPart.algorithm_nftBedSpaceNumber();
    this.struct.hydCultivationWork.level1.wholePosition.bedPart.algorithm_bedLength();
    this.struct.hydCultivationWork.level1.wholePosition.cultivationPart.algorithm_trellisLength();
    this.struct.hydCultivationWork.level1.wholePosition.groundRailPart.algorithm_railLength();
  }

  /**
   * 작업부 면적
   */
  public get workroomArea(): number {
    return this._workroomArea;
  }

  //
  public set workroomArea(value: number) {
    this._workroomArea = value;

    // 알고리즘
    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 작업부 폭
   */
  public get workroomWidth(): number {
    return this._workroomWidth;
  }

  //
  public set workroomWidth(value: number) {
    this._workroomWidth = value;

    // 알고리즘
    this.algorithm_workroomArea();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 작업부 길이
   */
  public get workroomLength(): number {
    return this._workroomLength;
  }

  //
  public set workroomLength(value: number) {
    if (value === this._workroomLength) {
      return;
    }
    this._workroomLength = value;

    // 알고리즘
    this.algorithm_workroomArea();
    this.algorithm_cultivationLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 파트
  }

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 양액 제어부
    this.struct.hydSystemWork.level1.wholePosition.hydcontrolPart.algorithm_sampleDesignQuantity();
    this.struct.hydSystemWork.level1.wholePosition.hydDrainPart.algorithm_sampleDesignQuantity();

    // 양액 베드부
    this.struct.hydCultivationWork.level1.wholePosition.algorithm_selected();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_cycleType();
    this.hydSupplyPart.algorithm_supplyMainBLength();
    this.hydDrainPart.algorithm_drainMainLength();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 양액 베드부
    this.struct.hydCultivationWork.level1.wholePosition.algorithm_bedType();
    this.struct.hydCultivationWork.level1.wholePosition.bedPart.algorithm_bedInfo();
    this.struct.hydCultivationWork.level1.wholePosition.bedPart.algorithm_bedLength();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.hydcontrolPart = new HydSystemPart_HydControl();
    this.hydSupplyPart = new HydSystemPart_HydSupply();
    this.hydDrainPart = new HydSystemPart_HydDrain();
    this.customPart = new MaterialPart_Custom();

    this.partAC = [this.hydcontrolPart, this.hydSupplyPart, this.hydDrainPart, this.customPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.hydcontrolPart = <HydSystemPart_HydControl>this.partAC[0];
    this.hydSupplyPart = <HydSystemPart_HydSupply>this.partAC[1];
    this.hydDrainPart = <HydSystemPart_HydDrain>this.partAC[2];
    this.customPart = <MaterialPart_Custom>this.partAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param nutrientControlArea: number 양액 제어구역
   * @param cycleType: string 순환 형태
   * @param workroomLength: number 작업부 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    nutrientControlArea: number = 0,
    cycleType: string = "",
    workroomLength: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._nutrientControlArea = nutrientControlArea;
    this._cycleType = cycleType;
    this._workroomLength = workroomLength;

    if (this.level.index >= 0) {
      // 양액제어 파트
      this.hydcontrolPart.setDefaultData(
        CONST.INDEX_CM_HYD_SYSTEM_CONTROL,
        true,
        true,
        true,
        CONST.LB_HYD_SYSTEM_CONTROL,
        "",
        true,
      );
      // 양액공급 파트
      this.hydSupplyPart.setDefaultData(
        CONST.INDEX_CM_HYD_SYSTEM_SUPPLY,
        true,
        true,
        true,
        CONST.LB_HYD_SYSTEM_SUPPLY,
        "",
        1,
      );
      // 양액배수/회수 파트
      this.hydDrainPart.setDefaultData(
        CONST.INDEX_CM_HYD_SYSTEM_DRAIN,
        true,
        true,
        true,
        CONST.LB_HYD_SYSTEM_DRAIN,
        "",
        true,
        false,
      );
      // 임의 파트
      this.customPart.setDefaultData(
        CONST.INDEX_CM_HYD_SYSTEM_CUSTOM,
        false,
        false,
        true,
        CONST.LB_HYD_SYSTEM_CUSTOM,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_workroomWidth();
    this.algorithm_cultivationWidth();
    this.algorithm_cultivationLength();
    this.algorithm_workroomLength();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////
    if (this.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE || this.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.hydcontrolPart.selected = true;
      this.hydcontrolPart.visible = true;
      this.hydSupplyPart.selected = true;
      this.hydSupplyPart.visible = true;
      this.hydDrainPart.selected = true;
      this.hydDrainPart.visible = true;
      this.customPart.selected = false;
      this.customPart.visible = false;
    } else {
      this.customPart.selected = true;
      this.customPart.visible = true;
      this.hydcontrolPart.selected = false;
      this.hydcontrolPart.visible = false;
      this.hydSupplyPart.selected = false;
      this.hydSupplyPart.visible = false;
      this.hydDrainPart.selected = false;
      this.hydDrainPart.visible = false;
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(베드)
   */
  public algorithm_nutrientType(): void {
    const hydCultivationPosition: HydCultivationPosition = this.struct.hydCultivationWork.level1.wholePosition;

    if (
      hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_STANDING_GUTTER ||
      hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_STYROFOAM_BED
    ) {
      this.type = CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE;
    } else if (hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_NFT_BED) {
      this.type = CONST.LB_HYD_SYSTEM_TYPE_NFT;
    }
  }

  /**
   * 알고리즘: 순환 형태 <- 형태
   */
  public algorithm_cycleType(): void {
    if (this.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.cycleType = CONST.LB_HYD_SYSTEM_CYCLE_TYPE_ACYCLE;
    } else if (this.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.cycleType = CONST.LB_HYD_SYSTEM_CYCLE_TYPE_CYCLE;
    } else if (this.type === CONST.LB_HYD_SYSTEM_TYPE_DFT) {
    }
  }

  /**
   * 알고리즘: 재배부 면적 <- 재배부 폭, 재배부 길이
   */
  public algorithm_cultivationArea(): void {
    this.cultivationArea = CommonUtil.roundUp(this.cultivationWidth * this.cultivationLength);
  }

  /**
   * 알고리즘: 재배부 폭 <- 하우스 폭(기본정보), 하우스 동수(기본정보)
   */
  public algorithm_cultivationWidth(): void {
    this.cultivationWidth = this.basicLevel.width * this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 재배부 길이 <- 하우스 길이(기본정보), 작업부 길이
   */
  public algorithm_cultivationLength(): void {
    this.cultivationLength = this.basicLevel.length - this.workroomLength;
  }

  /**
   * 알고리즘: 작업부 면적 <- 작업부 폭, 작업부 길이
   */
  public algorithm_workroomArea(): void {
    this.workroomArea = CommonUtil.roundUp(this.workroomWidth * this.workroomLength);
  }

  /**
   * 알고리즘: 작업부 폭 <- 하우스 폭(기본정보), 하우스 동수(기본정보)
   */
  public algorithm_workroomWidth(): void {
    this.workroomWidth = this.basicLevel.width * this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 작업부 길이 <- 하우스 길이(기본정보), 재배부 길이
   */
  public algorithm_workroomLength(): void {
    this.workroomLength = this.basicLevel.length - this.cultivationLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
