import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemWeavingFilm } from "vhows-design/src/object/design/item/list/ItemWeavingFilm";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { GutterSampleIL_WeavingFilm } from "vhows-design/src/object/design/frame/gutter/il/GutterSampleIL_WeavingFilm";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-28
 */
@jsonObject({
  knownTypes: [GutterSampleIL_WeavingFilm],
})
export class GutterPartIL_WeavingFilm extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public weavingFilmSample: GutterSampleIL_WeavingFilm; // 직조필름 샘플

  // 아이템
  protected weavingFilm_weavingFilm: ItemWeavingFilm; // 직조 필름 동적 생성
  public weavingFilm_pipe: ItemPipe;
  public weavingFilm_connectionPin: ItemConnector;
  public weavingFilm_screw: ItemScrew;
  public weavingFilm_steelStringGrabber: ItemHolder;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 길이 AC */
  public specLengthAC: number[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.weavingFilmSample = new GutterSampleIL_WeavingFilm();

    this.sampleAC = [this.weavingFilmSample];

    // 아이템
    // 직조 필름 동적 생성
    this.weavingFilm_pipe = new ItemPipe();
    this.weavingFilm_connectionPin = new ItemConnector();
    this.weavingFilm_screw = new ItemScrew();
    this.weavingFilm_steelStringGrabber = new ItemHolder();

    this.itemAC = [
      this.weavingFilm_pipe,
      this.weavingFilm_connectionPin,
      this.weavingFilm_screw,
      this.weavingFilm_steelStringGrabber,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.weavingFilmSample = <GutterSampleIL_WeavingFilm>this.sampleAC[0];

    // 아이템
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemPipe) {
        this.weavingFilm_pipe = <ItemPipe>item;
      } else if (item instanceof ItemConnector) {
        this.weavingFilm_connectionPin = <ItemConnector>item;
      } else if (item instanceof ItemScrew) {
        this.weavingFilm_screw = <ItemScrew>item;
      } else if (item instanceof ItemHolder) {
        this.weavingFilm_steelStringGrabber = <ItemHolder>item;
      }
    }

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index === 0) {
      this.weavingFilmSample.setDefaultData(
        0,
        CONST.ITEM_ID_WEAVING_FILM,
        CONST.ITEM_NAME_WEAVING_FILM,
        "직조필름 물받이",
        "아그론, 투명, 60cm, 49m, 끈 없음, 0회",
        "미소텍, 아그론, 400cm간격",
      );
    } else {
      this.weavingFilmSample.setDefaultData(
        0,
        CONST.ITEM_ID_WEAVING_FILM,
        CONST.ITEM_NAME_WEAVING_FILM,
        "직조필름 물받이",
        "아그론, 투명, 60cm, 49m, 끈 없음, 0회",
        "미소텍, 아그론, 400cm간격",
      );
    }

    // 아이템
    // 직조 필름 동적 생성
    if (this.level.index === 0) {
      this.weavingFilm_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "직조필름 물받이",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.weavingFilm_connectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "직조필름 물받이",
        "원형, 일반, 25mm, 25mm",
      );
      this.weavingFilm_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "직조필름 물받이",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.weavingFilm_steelStringGrabber.setDefaultData(
        4,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "직조필름 물받이 + 기둥",
        "원형, 일반, 48mm, 25mm",
      );
    } else {
      this.weavingFilm_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "직조필름 물받이",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.weavingFilm_connectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "직조필름 물받이",
        "원형, 일반, 25mm, 25mm",
      );
      this.weavingFilm_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "직조필름 물받이",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.weavingFilm_steelStringGrabber.setDefaultData(
        4,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "직조필름 물받이 + 기둥",
        "원형, 일반, 48mm, 25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 파트 알고리즘 <- 기둥 간격(기둥 골조), 앞면/뒷면 처마 길이(지붕 골조/1중)
   */
  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 생성, 선택, 가시성, 수량 ///////////

    if (this.basic.isVariableLength === true) {
      this.specLengthAC = new Array();
      let basicBuilding: BasicBuilding;
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.specLengthAC.push(basicBuilding.length);
      }
    }
    let roofPositionEaveLength: number = 0;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPositionEaveLength =
        this.struct.roofWorkIL.level1.roofPosition.frontEaveLength +
        this.struct.roofWorkIL.level1.roofPosition.backEaveLength;
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofPositionEaveLength = 0;
    }

    // 직조 필름
    // 2동이상 && 가변길이인 경우
    if (this.basic.buildingNumber >= 2 && this.basic.isVariableLength === true) {
      // 동수만큼 품목 생성
      let length: number = this.basic.buildingNumber;
      if (this.level.index === 0) {
        length -= 1;
      }
      for (let i: number = 0; i < length; i++) {
        this.weavingFilm_weavingFilm = ItemWeavingFilm.getNewDesignItemBySample(this, this.weavingFilmSample);
        this.weavingFilm_weavingFilm.purpose = `${i + 1}번동 ${this.weavingFilm_weavingFilm.purpose}`;
        if (this.level.index === 0) {
          // 1중일 경우 물받이가 두동사이에 위치해서 두동중 길이가 짧은쪽 동의 길이로 세팅
          let specLength: number = 0;
          const specLength1: number = this.specLengthAC[i];
          const specLength2: number = this.specLengthAC[i + 1];
          if (specLength1 < specLength2) {
            specLength = specLength1;
          } else {
            specLength = specLength2;
          }

          this.weavingFilm_weavingFilm.specLength = specLength + 1 + roofPositionEaveLength;
          this.weavingFilm_weavingFilm.designQuantity = 1;
        } else {
          // 2중 이상
          this.weavingFilm_weavingFilm.specLength = 1 + this.specLengthAC[i];
          this.weavingFilm_weavingFilm.designQuantity = 2;
        }

        this.itemAC.push(this.weavingFilm_weavingFilm);
      }
    } else {
      // 동수만큼 수량 입력
      this.weavingFilm_weavingFilm = ItemWeavingFilm.getNewDesignItemBySample(this, this.weavingFilmSample);
      if (this.level.index === 0) {
        this.weavingFilm_weavingFilm.specLength = this.basicLevel.length + 1 + roofPositionEaveLength;
        this.weavingFilm_weavingFilm.designQuantity = this.basic.buildingNumber - 1;
      } else {
        this.weavingFilm_weavingFilm.specLength = this.basicLevel.length + 1;
        this.weavingFilm_weavingFilm.designQuantity = 2 * this.basic.buildingNumber;
      }

      this.itemAC.push(this.weavingFilm_weavingFilm);
    }

    // 파이프
    if (this.level.index === 0) {
      this.weavingFilm_pipe.selected = false;
      this.weavingFilm_pipe.visible = false;
    } else {
      this.weavingFilm_pipe.selected = true;
      this.weavingFilm_pipe.visible = true;
    }
    this.weavingFilm_pipe.designQuantity =
      (CommonUtil.roundUpX(
        this.basicLevel.length + this.design.preference.connectedPipeExtraLength,
        this.weavingFilm_pipe.specLength / 2,
      ) /
        this.weavingFilm_pipe.specLength) *
      (this.basic.buildingNumber + 1);

    this.itemAC.push(this.weavingFilm_pipe);

    // 연결핀
    this.weavingFilm_connectionPin.selected = true;
    this.weavingFilm_connectionPin.visible = true;

    if (this.basicLevel.length + this.design.preference.connectedPipeExtraLength > this.weavingFilm_pipe.specLength) {
      this.weavingFilm_connectionPin.designQuantity =
        this.weavingFilm_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.weavingFilm_connectionPin.designQuantity = 0;
    }

    this.itemAC.push(this.weavingFilm_connectionPin);

    // 나사
    this.weavingFilm_screw.selected = true;
    this.weavingFilm_screw.visible = true;

    this.weavingFilm_screw.designQuantity =
      ((this.weavingFilm_connectionPin.getSelectedQuantity() * 2) / this.weavingFilm_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.itemAC.push(this.weavingFilm_screw);

    // 강선조리개
    this.weavingFilm_steelStringGrabber.selected = true;
    this.weavingFilm_steelStringGrabber.visible = true;

    this.weavingFilm_steelStringGrabber.designQuantity =
      (Math.ceil(this.basicLevel.length / columnPosition.columnInterval) + 3) *
      (this.basic.buildingNumber + 1) *
      CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

    this.itemAC.push(this.weavingFilm_steelStringGrabber);

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [직조필름 물받이] 직조 필름 <- 직조필름 샘플
   */
  public algorithmSpec_weavingFilm_weavingFilm(): void {
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemWeavingFilm && item.productId === CONST.ITEM_ID_WEAVING_FILM) {
        (<ItemWeavingFilm>item).specsList = this.weavingFilmSample.specsList;
        (<ItemWeavingFilm>item).specWidth = this.weavingFilmSample.specWidth;
      }
    }
  }

  /**
   * 규격 알고리즘: [직조필름 물받이] 파이프 <- 지붕 가로대 파이프 샘플
   */
  public algorithmSpec_weavingFilm_pipe(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const roofPosition: RoofPositionIL = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
      this.weavingFilm_pipe.specs = roofPosition.straightPart.straightPipeSample.specs;
    }
  }

  /**
   * 규격 알고리즘: [직조필름 물받이] 연결핀 <- 지붕 가로대 파이프 샘플
   */
  public algorithmSpec_weavingFilm_connectionPin(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const roofPosition: RoofPositionIL = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
      this.weavingFilm_connectionPin.specPipeType = roofPosition.straightPart.straightPipeSample.specPipeType;
      this.weavingFilm_connectionPin.specCrossSize1 = roofPosition.straightPart.straightPipeSample.specCrossSize;
      this.weavingFilm_connectionPin.specCrossSize2 = roofPosition.straightPart.straightPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [직조필름 물받이] 나사 <- 공통 샘플
   */
  public algorithmSpec_weavingFilm_screw(): void {}

  /**
   * 규격 알고리즘: [직조필름 물받이 + 기둥] 강선 조리개 <- 형태(기둥 골조), 기둥 파이프 샘플, 지붕 가로대 파이프 샘플
   */
  public algorithmSpec_weavingFilm_steelStringGrabber(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const roofPosition: RoofPositionIL = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
      const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
      if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.weavingFilm_steelStringGrabber.specPipeType =
          columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
        this.weavingFilm_steelStringGrabber.specCrossSize1 =
          columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
        this.weavingFilm_steelStringGrabber.specCrossSize2 = roofPosition.straightPart.straightPipeSample.specCrossSize;
      } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.weavingFilm_steelStringGrabber.specPipeType = columnPosition.prismColumnPart.columnPipeSample.specPipeType;
        this.weavingFilm_steelStringGrabber.specCrossSize1 =
          columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
        this.weavingFilm_steelStringGrabber.specCrossSize2 = roofPosition.straightPart.straightPipeSample.specCrossSize;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
