import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 시공 할인비
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2019-11-29
 */
@jsonObject
export class ItemBuildingDiscountCost extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.buildingDiscountCostCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    // 회사 사용 안함
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    // 회사 사용 안함
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList = this._brands;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }
}
