import { jsonObject } from "typedjson";

import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { SkySwitchLevel } from "vhows-design/src/object/design/skylight/switches/SkySwitchLevel";
import { SkySwitchPosition } from "vhows-design/src/object/design/skylight/switches/SkySwitchPosition";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { ControllerPart_Controller } from "vhows-design/src/object/design/switches/controller/ControllerPart_Controller";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-09
 */
@jsonObject
export class ControllerSample_SwitcherElectricWire extends ItemElectricWire {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const controllerPart: ControllerPart_Controller = <ControllerPart_Controller>this.part;

    // 파트 알고리즘 호출
    // part.algorithmPart();

    /// //////// 외부 ///////////

    // 개폐기
    for (const switcherLevel of this.struct.switcherWork.levelAC as SwitcherLevel[]) {
      for (const switcherPosition of switcherLevel.positionAC as SwitcherPosition[]) {
        switcherPosition.power1Part.algorithmSpec_power_electricWire();
        switcherPosition.power2Part.algorithmSpec_power_electricWire();
      }
    }

    // 천창 개폐
    for (const skySwitchLevel of this.struct.skySwitchWork.levelAC as SkySwitchLevel[]) {
      for (const skySwitchPosition of skySwitchLevel.positionAC as SkySwitchPosition[]) {
        skySwitchPosition.powerPart.algorithmSpec_power_electricWire();
        skySwitchPosition.rackpinionPart.algorithmSpec_rackpinion_electricWire();
      }
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
