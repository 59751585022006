import { jsonObject, jsonMember, jsonArrayMember, TypedJSON } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Work } from "vhows-design/src/object/design/base/Work";
import { BandstringWork } from "vhows-design/src/object/design/cover/bandstring/BandstringWork";
import { BandstringWorkIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringWorkIL";
import { CoverWork } from "vhows-design/src/object/design/cover/cover/CoverWork";
import { CoverWorkIL } from "vhows-design/src/object/design/cover/cover/il/CoverWorkIL";
import { FixingWork } from "vhows-design/src/object/design/cover/fixing/FixingWork";
import { FixingWorkIL } from "vhows-design/src/object/design/cover/fixing/il/FixingWorkIL";
import { CurCoverWorkIL } from "vhows-design/src/object/design/curtain/cover/CurCoverWorkIL";
import { CurSwitchWorkIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchWorkIL";
import { ColumnWorkIL } from "vhows-design/src/object/design/frame/column/ColumnWorkIL";
import { EndpieceWork } from "vhows-design/src/object/design/frame/endpiece/EndpieceWork";
import { EndpieceWorkIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceWorkIL";
import { FoundationWorkIL } from "vhows-design/src/object/design/foundation/il/FoundationWorkIL";
import { GutterWorkIL } from "vhows-design/src/object/design/frame/gutter/il/GutterWorkIL";
import { RoofWorkIL } from "vhows-design/src/object/design/frame/roof/RoofWorkIL";
import { TrunkWork } from "vhows-design/src/object/design/frame/trunk/TrunkWork";
import { WingWorkIL } from "vhows-design/src/object/design/frame/wing/WingWorkIL";
import { BuildingWork } from "vhows-design/src/object/design/building/BuildingWork";
import { MaterialWork } from "vhows-design/src/object/design/other/material/MaterialWork";
import { PartitionWork } from "vhows-design/src/object/design/other/partition/PartitionWork";
import { RackWork } from "vhows-design/src/object/design/other/rack/RackWork";
import { RailWork } from "vhows-design/src/object/design/other/rail/RailWork";
import { WindowWork } from "vhows-design/src/object/design/other/window/WindowWork";
import { WindowWorkIL } from "vhows-design/src/object/design/other/window/il/WindowWorkIL";
import { SkyCoverWork } from "vhows-design/src/object/design/skylight/cover/SkyCoverWork";
import { SkyCoverWorkIL } from "vhows-design/src/object/design/skylight/cover/il/SkyCoverWorkIL";
import { SkyFrameWork } from "vhows-design/src/object/design/skylight/frame/SkyFrameWork";
import { SkyFrameWorkIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFrameWorkIL";
import { SkySwitchWork } from "vhows-design/src/object/design/skylight/switches/SkySwitchWork";
import { SkySwitchWorkIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchWorkIL";
import { ControllerWork } from "vhows-design/src/object/design/switches/controller/ControllerWork";
import { ControllerWorkIL } from "vhows-design/src/object/design/switches/controller/il/ControllerWorkIL";
import { ScreennetWork } from "vhows-design/src/object/design/cover/screennet/ScreennetWork";
import { ScreennetWorkIL } from "vhows-design/src/object/design/cover/screennet/il/ScreennetWorkIL";
import { SkirtWork } from "vhows-design/src/object/design/cover/skirt/SkirtWork";
import { SkirtWorkIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtWorkIL";
import { SwitcherWork } from "vhows-design/src/object/design/switches/switcher/SwitcherWork";
import { SwitcherWorkIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherWorkIL";
import { WindbreakWork } from "vhows-design/src/object/design/cover/windbreak/WindbreakWork";
import { WindbreakWorkIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakWorkIL";
import { VentExhaustWork } from "vhows-design/src/object/design/vent/exhaust/VentExhaustWork";
import { VentInsideWork } from "vhows-design/src/object/design/vent/inside/VentInsideWork";
import { MainpipeWork } from "vhows-design/src/object/design/watering/mainpipe/MainpipeWork";
import { WateringWork } from "vhows-design/src/object/design/watering/watering/WateringWork";
import { TemHeatingWork } from "vhows-design/src/object/design/temperature/heating/TemHeatingWork";
import { TemCoolingWork } from "vhows-design/src/object/design/temperature/cooling/TemCoolingWork";
import { HydSystemWork } from "vhows-design/src/object/design/hydroponics/system/HydSystemWork";
import { EnvironmentWork } from "vhows-design/src/object/design/smartfarm/EnvironmentWork";
import { HydCultivationWork } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationWork";
import { PartitionWorkIL } from "vhows-design/src/object/design/other/partition/li/PartitionWorkIL";
import { WorkroomWork } from "vhows-design/src/object/design/other/workroom/WorkroomWork";
import { Co2Work } from "vhows-design/src/object/design/other/co2/Co2Work";
import { AutosprayWork } from "vhows-design/src/object/design/other/autospray/AutosprayWork";
import { FacilityWork } from "vhows-design/src/object/design/other/facility/FacilityWork";
import { GutterWork } from "vhows-design/src/object/design/frame/gutter/GutterWork";
import { FoundationWork } from "vhows-design/src/object/design/foundation/FoundationWork";
import { EndpieceWorkVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceWorkVL";
import { RoofWorkVL } from "vhows-design/src/object/design/frame/roof/vl/RoofWorkVL";
import { ColumnLevelVL } from "vhows-design/src/object/design/frame/column/vl/ColumnLevelVL";
import { ColumnWorkVL } from "vhows-design/src/object/design/frame/column/vl/ColumnWorkVL";
import { WingWorkVL } from "vhows-design/src/object/design/frame/wing/vl/WingWorkVL";

/**
 * 작업 객체를 위한, 임의 JSON 시리얼라이저 <br/>
 * - 골조/피복/개폐 공사를 제외한 선택되지 않은 작업은 제외함
 */
function workCustomJsonSerializer(workAC: Work[]) {
  return TypedJSON.toPlainArray(
    workAC.filter(
      work =>
        work.category === CONST.LB_FOUNDATION_CONSTRUCTION ||
        work.category === CONST.LB_FRAME_CONSTRUCTION ||
        work.category === CONST.LB_SWITCH_CONSTRUCTION ||
        work.category === CONST.LB_COVER_CONSTRUCTION ||
        work.selected,
    ),
    Work,
  );
}

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-25
 */
@jsonObject({
  knownTypes: [
    FoundationWork,
    TrunkWork,
    EndpieceWork,
    GutterWork,
    CoverWork,
    FixingWork,
    BandstringWork,
    SwitcherWork,
    ControllerWork,
    SkirtWork,
    WindbreakWork,
    ScreennetWork,
    SkyFrameWork,
    SkySwitchWork,
    SkyCoverWork,
    WindowWork,
    PartitionWork,
    FoundationWorkIL,
    ColumnWorkIL,
    RoofWorkIL,
    WingWorkIL,
    EndpieceWorkIL,
    GutterWorkIL,
    CoverWorkIL,
    FixingWorkIL,
    BandstringWorkIL,
    SwitcherWorkIL,
    ControllerWorkIL,
    SkirtWorkIL,
    WindbreakWorkIL,
    ScreennetWorkIL,
    CurCoverWorkIL,
    CurSwitchWorkIL,
    SkyFrameWorkIL,
    SkySwitchWorkIL,
    SkyCoverWorkIL,
    PartitionWorkIL,
    WindowWorkIL,
    WorkroomWork,
    MaterialWork,
    VentExhaustWork,
    VentInsideWork,
    TemHeatingWork,
    TemCoolingWork,
    EnvironmentWork,
    HydSystemWork,
    HydCultivationWork,
    MainpipeWork,
    WateringWork,
    RackWork,
    RailWork,
    Co2Work,
    AutosprayWork,
    FacilityWork,
    BuildingWork,
    ColumnWorkVL,
    RoofWorkVL,
    WingWorkVL,
    EndpieceWorkVL,
  ],
})
export class Struct extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;

  // 기초 공사
  public foundationWork: FoundationWork;
  public foundationWorkIL: FoundationWorkIL;
  // 골조 공사
  public trunkWork: TrunkWork;
  public endpieceWork: EndpieceWork;
  public endpieceWorkIL: EndpieceWorkIL;
  public endpieceWorkVL: EndpieceWorkVL;
  public columnWorkIL: ColumnWorkIL;
  public columnWorkVL: ColumnWorkVL;
  public roofWorkIL: RoofWorkIL;
  public roofWorkVL: RoofWorkVL;
  public wingWorkIL: WingWorkIL;
  public wingWorkVL: WingWorkVL;
  public gutterWork: GutterWork;
  public gutterWorkIL: GutterWorkIL;
  // 개폐 공사
  public switcherWork: SwitcherWork;
  public switcherWorkIL: SwitcherWorkIL;
  public controllerWork: ControllerWork;
  public controllerWorkIL: ControllerWorkIL;
  // 피복 공사
  public coverWork: CoverWork;
  public coverWorkIL: CoverWorkIL;
  public fixingWork: FixingWork;
  public fixingWorkIL: FixingWorkIL;
  public skirtWork: SkirtWork;
  public skirtWorkIL: SkirtWorkIL;
  public windbreakWork: WindbreakWork;
  public windbreakWorkIL: WindbreakWorkIL;
  public screennetWork: ScreennetWork;
  public screennetWorkIL: ScreennetWorkIL;
  public bandstringWork: BandstringWork;
  public bandstringWorkIL: BandstringWorkIL;
  // 커튼 공사
  public curCoverWorkIL: CurCoverWorkIL;
  public curSwitchWorkIL: CurSwitchWorkIL;
  // 천창 공사
  public skyFrameWork: SkyFrameWork;
  public skyFrameWorkIL: SkyFrameWorkIL;
  public skySwitchWork: SkySwitchWork;
  public skySwitchWorkIL: SkySwitchWorkIL;
  public skyCoverWork: SkyCoverWork;
  public skyCoverWorkIL: SkyCoverWorkIL;
  // 추가 건축 공사
  public partitionWork: PartitionWork;
  public partitionWorkIL: PartitionWorkIL;
  public windowWork: WindowWork;
  public windowWorkIL: WindowWorkIL;
  public workroomWork: WorkroomWork;
  public materialWork: MaterialWork;
  // 환기 공사
  public ventExhaustWork: VentExhaustWork;
  public ventInsideWork: VentInsideWork;
  // 관수 공사
  public mainpipeWork: MainpipeWork;
  public wateringWork: WateringWork;
  // 양액 공사
  public hydSystemWork: HydSystemWork;
  public hydCultivationWork: HydCultivationWork;
  // 냉난방 공사
  public temHeatingWork: TemHeatingWork;
  public temCoolingWork: TemCoolingWork;
  // 스마트팜 공사
  public environmentWork: EnvironmentWork;
  // 추가 기계/설비 공사
  public rackWork: RackWork;
  public railWork: RailWork;
  public co2Work: Co2Work;
  public autosprayWork: AutosprayWork;
  public facilityWork: FacilityWork;
  // 기타
  public buildingWork: BuildingWork;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 형식적 명칭 */
  public get formalLabel(): string {
    return this.design.id.toString();
  }
  //
  public set formalLabel(value: string) {}

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 작업AC */
  @jsonArrayMember(Work, { serializer: workCustomJsonSerializer })
  public workAC: Work[];

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Boolean)
  public _selected: boolean; // 선택
  @jsonMember(Boolean)
  public _selectedEstimate: boolean = true; // 견적 선택

  /**
   * 선택
   */
  public get selected(): boolean {
    return this._selected;
  }
  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;
  }

  /**
   * 견적 선택
   */
  public get selectedEstimate(): boolean {
    return this._selectedEstimate;
  }
  //
  public set selectedEstimate(value: boolean) {
    this._selectedEstimate = value;
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design): void {
    this.design = design;

    // 작업
    let work: Work;
    for (work of this.workAC) {
      work.setAssociation(design, this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 작업
    let work: Work;
    for (work of this.workAC) {
      work.setReferenceVariable();
    }
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {
    // 추후 확인 - 벤로형 구조 추가 때문에 추가함
    // 작업
    let work: Work;
    for (work of this.workAC) {
      work.setDefaultData();
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 작업
    let work: Work;
    for (work of this.workAC) {
      work.setDefaultVariable();
    }
  }

  /**
   * 기본 모델 설정: 데이터베이스를 대신함
   */
  public setDefaultModel(): void {
    // 작업
    let work: Work;
    for (work of this.workAC) {
      work.setDefaultModel();
    }
  }

  /**
   * 저장된 아이디값 세팅
   */
  public setSavedId(savedStructure: Struct): void {
    if (savedStructure != null) {
      this.id = savedStructure.id;

      if (this.workAC != null && savedStructure.workAC != null) {
        for (let i: number = 0; i < this.workAC.length; i++) {
          const work: Work = this.workAC[i];

          // 작업 인덱스로 비교하여 아이디 세팅시키기 (동적 생성되는 작업이 있어 플렉스상 배열과 저장된 결과 배열이 다르기 때문)
          for (let j: number = 0; j < savedStructure.workAC.length; j++) {
            const savedWork: Work = savedStructure.workAC[j];
            if (work.index === savedWork._index) {
              work.setSavedId(savedWork);
              break;
            }
          }
        }
      }
    }
  }

  /**
   * 최신 객체로 복원
   */
  public restoreLatestObject(design: Design): void {
    // 작업
    let work: Work;
    for (work of this.workAC) {
      work.restoreLatestObject(design, this);
    }
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  public algorithmBasic(): void {
    // 작업
    let work: Work;
    for (work of this.workAC) {
      work.algorithmBasic();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
