import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Basic } from "vhows-design/src/object/design/basic/Basic";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";

/**
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class BasicBuilding extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 기본정보 객체 */
  public basic: Basic;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 깊은 복사중 여부 */
  public isDeepCopying: boolean = false;

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 중수별 기본정보 객체 */
  public basicLevel: BasicLevel;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _index: number; // 인덱스
  @jsonMember(Number)
  public _buildingNumber: number; // 동수
  @jsonMember(Number)
  public _length: number; // 길이

  /**
   * 인덱스 <br/>
   */
  public get index(): number {
    return this._index;
  }
  //
  public set index(value: number) {
    this._index = value;
  }

  /**
   * 동수
   */
  public get buildingNumber(): number {
    return this._buildingNumber;
  }
  //
  public set buildingNumber(value: number) {
    this._buildingNumber = value;
  }

  /**
   * 길이
   */
  public get length(): number {
    return this._length;
  }
  //
  public set length(value: number) {
    this._length = CommonUtil.fixFloat(value);

    if (this.isDeepCopying === false) {
      // 가변길이일 경우 수행
      if (this.basic.isVariableLength === true) {
        this.basic.algorithm_lengthByLevelBuilding(this.basicLevel.index, this.index);
        this.basicLevel.algorithm_averageLength();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(basic: Basic, basicLevel: BasicLevel): void {
    this.basic = basic;
    this.basicLevel = basicLevel;
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {}

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index 동수인덱스
   * @param length 길이
   */
  public setDefaultData(index: number, buildingNumber: number, length: number): void {
    this._index = index;
    this._buildingNumber = buildingNumber;
    this._length = length;
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {}

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * @param origin 원본 객체
   */
  public deepCopy(origin: BasicBuilding): void {
    this.isDeepCopying = true;

    // Remote Variables
    // 아이디, 수정일시 등은 복사안함
    this._index = origin.index;
    this._buildingNumber = origin.buildingNumber;
    this._length = origin.length;

    this.isDeepCopying = false;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
