import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemRailHook } from "vhows-design/src/object/design/item/list/ItemRailHook";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";
import { RailPosition } from "vhows-design/src/object/design/other/rail/RailPosition";
import { RailSample_UpperRailPipe } from "vhows-design/src/object/design/other/rail/RailSample_UpperRailPipe";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-10-29
 */
@jsonObject({
  knownTypes: [RailSample_UpperRailPipe],
})
export class RailPart_UpperRail extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public railPipeSample: RailSample_UpperRailPipe; // 레일 파이프 샘플

  // 아이템
  public rail_pipe: ItemPipe;
  public rail_connectionPin: ItemConnector;
  public railAndFrame_railHook: ItemRailHook;
  public railAndFrame_secondLevelRailHook: ItemRailHook;
  public rail_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _totalRailLength: number = 0; // 총 레일 길이
  @jsonMember(Number)
  public _railLength: number = 0; // 레일 길이
  @jsonMember(Number)
  public _totalRailLineNumber: number = 0; // 총 레일 줄수
  @jsonMember(Number)
  public _railLineNumber: number = 0; // 레일 줄수
  @jsonMember(Number)
  public _railHookInterval: number = 0; // 운반 고리 간격
  @jsonMember(String)
  public _railHookFixedFrame: string; // 운반 고리 고정 골조
  @jsonMember(Boolean)
  public _cyclicRailSelected: boolean; // 순환형 레일 선택

  /**
   * 총 레일 길이
   */
  public get totalRailLength(): number {
    return this._totalRailLength;
  }

  //
  public set totalRailLength(value: number) {
    this._totalRailLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 레일 길이
   */
  public get railLength(): number {
    return this._railLength;
  }

  //
  public set railLength(value: number) {
    this._railLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalRailLength();
    this.algorithmPart();
  }

  /**
   * 총 레일 줄수
   */
  public get totalRailLineNumber(): number {
    return this._totalRailLineNumber;
  }

  //
  public set totalRailLineNumber(value: number) {
    this._totalRailLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalRailLength();
    this.algorithmPart();
  }

  /**
   * 레일 줄수
   */
  public get railLineNumber(): number {
    return this._railLineNumber;
  }

  //
  public set railLineNumber(value: number) {
    this._railLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalRailLineNumber();
    this.algorithmPart();
  }

  /**
   * 운반 고리 간격
   */
  public get railHookInterval(): number {
    return this._railHookInterval;
  }

  //
  public set railHookInterval(value: number) {
    this._railHookInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 운반 고리 고정 골조
   */
  public get railHookFixedFrame(): string {
    return this._railHookFixedFrame;
  }

  //
  public set railHookFixedFrame(value: string) {
    this._railHookFixedFrame = value;

    // 알고리즘
    this.algorithm_railHookInterval();
    this.algorithmPart();
  }

  /**
   * 순환형 레일 선택
   */
  public get cyclicRailSelected(): boolean {
    return this._cyclicRailSelected;
  }

  //
  public set cyclicRailSelected(value: boolean) {
    this._cyclicRailSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.railPipeSample = new RailSample_UpperRailPipe();

    this.sampleAC = [this.railPipeSample];

    // 아이템
    this.rail_pipe = new ItemPipe();
    this.rail_connectionPin = new ItemConnector();
    this.railAndFrame_railHook = new ItemRailHook();
    this.railAndFrame_secondLevelRailHook = new ItemRailHook();
    this.rail_screw = new ItemScrew();

    this.itemAC = [
      this.rail_pipe,
      this.rail_connectionPin,
      this.railAndFrame_railHook,
      this.railAndFrame_secondLevelRailHook,
      this.rail_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.railPipeSample = <RailSample_UpperRailPipe>this.sampleAC[0];
    // 아이템
    this.rail_pipe = <ItemPipe>this.itemAC[0];
    this.rail_connectionPin = <ItemConnector>this.itemAC[1];
    this.railAndFrame_railHook = <ItemRailHook>this.itemAC[2];
    this.railAndFrame_secondLevelRailHook = <ItemRailHook>this.itemAC[3];
    this.rail_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param railLineNumber: number 레일 줄수
   * @param railHookInterval: number 운반 고리 간격
   * @param railHookFixedFrame: string 운반 고리 고정 골조
   * @param cyclicRailSelected: boolean 순환형 레일 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    railLineNumber: number = 0,
    railHookInterval: number = 0,
    railHookFixedFrame: string = "",
    cyclicRailSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._railLineNumber = railLineNumber;
    this._railHookInterval = railHookInterval;
    this._railHookFixedFrame = railHookFixedFrame;
    this._cyclicRailSelected = cyclicRailSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.railPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "상단 레일",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.rail_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "상단 레일",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.rail_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "상단 레일",
        "원형, 일반, 25mm, 25mm",
      );
      this.railAndFrame_railHook.setDefaultData(
        2,
        CONST.ITEM_ID_RAIL_HOOK,
        CONST.ITEM_NAME_RAIL_HOOK,
        "상단 레일 + 골조",
        "원형, 일반, 25mm, 25mm, 중",
      );
      this.railAndFrame_secondLevelRailHook.setDefaultData(
        3,
        CONST.ITEM_ID_SECOND_LEVEL_RAIL_HOOK,
        CONST.ITEM_NAME_SECOND_LEVEL_RAIL_HOOK,
        "상단 레일 + 골조",
        "원형, 일반, 25mm, 25mm, -",
      );
      this.rail_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "상단 레일",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_railLength();
    this.algorithm_railHookFixedFrame();
    this.algorithm_railHookInterval();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithm_totalRailLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const railPosition: RailPosition = <RailPosition>this.position;

    /// //////// 선택, 가시성 ///////////

    this.rail_pipe.checkSelected(true);
    this.rail_connectionPin.checkSelected(true);
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      if (this.railHookFixedFrame === CONST.LB_RAIL_HOOK_FIXED_FRAME_STRAIGHT) {
        this.railAndFrame_railHook.selected = true;
        this.railAndFrame_railHook.visible = true;
        this.railAndFrame_secondLevelRailHook.selected = false;
        this.railAndFrame_secondLevelRailHook.visible = false;
      } else if (
        this.railHookFixedFrame === CONST.LB_RAIL_HOOK_FIXED_FRAME_RAFTER ||
        this.railHookFixedFrame === CONST.LB_RAIL_HOOK_FIXED_FRAME_MIDDLE
      ) {
        this.railAndFrame_railHook.selected = false;
        this.railAndFrame_railHook.visible = false;
        this.railAndFrame_secondLevelRailHook.selected = true;
        this.railAndFrame_secondLevelRailHook.visible = true;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.railAndFrame_railHook.selected = false;
      this.railAndFrame_railHook.visible = false;
      this.railAndFrame_secondLevelRailHook.selected = true;
      this.railAndFrame_secondLevelRailHook.visible = true;
    }
    this.rail_screw.selected = true;
    this.rail_screw.visible = true;

    /// ////////  수치 ///////////

    /// ////////  수량 ///////////

    this.rail_pipe.designQuantity =
      this.rail_pipe.getConnectedPipeLength(this.railLength, this.totalRailLineNumber) / this.rail_pipe.specLength;

    this.rail_connectionPin.designQuantity = this.rail_pipe.getSelectedQuantity();

    let railHook_designQuantity: number = (this.railLength / this.railHookInterval + 1) * this.totalRailLineNumber;
    if (this.cyclicRailSelected === true) {
      railHook_designQuantity += 3 * this.totalRailLineNumber;
    }
    this.railAndFrame_railHook.designQuantity = railHook_designQuantity * CONST.NUM_EXTRA_RATE_RAIL_HOOK;
    this.railAndFrame_secondLevelRailHook.designQuantity = railHook_designQuantity * CONST.NUM_EXTRA_RATE_RAIL_HOOK;

    this.rail_screw.designQuantity =
      ((this.rail_connectionPin.getSelectedQuantity() * 4 +
        this.railAndFrame_railHook.getSelectedQuantity() * 4 +
        this.railAndFrame_secondLevelRailHook.getSelectedQuantity() * 4) /
        this.rail_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 총 레일 길이 <- 레일 길이, 총 레일 줄수
   */
  public algorithm_totalRailLength(): void {
    const railPosition: RailPosition = <RailPosition>this.position;
    this.totalRailLength = this.railLength * this.totalRailLineNumber;
  }

  /**
   * 알고리즘: 레일 길이 <- 하우스 길이, 하우스 중수
   */
  public algorithm_railLength(): void {
    const basicLevelLast: BasicLevel = <BasicLevel>this.basic.basicLevelAC[this.basic.levelNumber - 1];
    this.railLength = basicLevelLast.length;
  }

  /**
   * 알고리즘: 총 레일 줄수 <- 레일 줄수, 상단 레일 동수
   */
  public algorithm_totalRailLineNumber(): void {
    this.totalRailLineNumber = Math.ceil(this.railLineNumber * (<RailPosition>this.position).upperRailBuildingNumber);
  }

  /**
   * 알고리즘: 운반 고리 고정 골조 <- 하우스 중수
   */
  public algorithm_railHookFixedFrame(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      if (this.basic.levelNumber === 1) {
        this.railHookFixedFrame = CONST.LB_RAIL_HOOK_FIXED_FRAME_STRAIGHT;
      } else {
        this.railHookFixedFrame = CONST.LB_RAIL_HOOK_FIXED_FRAME_RAFTER;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.railHookFixedFrame = CONST.LB_RAIL_HOOK_FIXED_FRAME_MIDDLE;
    }
  }

  /**
   * 알고리즘: 운반 고리 간격 <- 운반 고리 고정 골조, 서까래 간격(단동), 기둥 간격(연동,벤로), 기둥 형태(연동,벤로)
   */
  public algorithm_railHookInterval(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.basic.levelNumber - 1])
        .trunkPosition;
      if (this.railHookFixedFrame === CONST.LB_RAIL_HOOK_FIXED_FRAME_STRAIGHT) {
        this.railHookInterval = 1;
      } else if (this.railHookFixedFrame === CONST.LB_RAIL_HOOK_FIXED_FRAME_RAFTER) {
        this.railHookInterval = trunkPosition.rafterPart.rafterInterval;
      } else if (this.railHookFixedFrame === CONST.LB_RAIL_HOOK_FIXED_FRAME_MIDDLE) {
        this.railHookInterval = trunkPosition.columnPart.columnInterval;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const columPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
      if (columPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.railHookInterval = columPosition.cylinderColumnPart.columnInterval;
      } else if (columPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.railHookInterval = columPosition.prismColumnPart.columnInterval;
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const columPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
      if (columPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.railHookInterval = columPosition.prismColumnPart.columnInterval;
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [상단 레일] 파이프 <- 레일 파이프 샘플
   */
  public algorithmSpec_rail_pipe(): void {
    this.rail_pipe.specs = this.railPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [상단 레일] 연결핀 <- 레일 파이프 샘플
   */
  public algorithmSpec_rail_connectionPin(): void {
    this.rail_connectionPin.specPipeType = this.railPipeSample.specPipeType;
    this.rail_connectionPin.specCrossSize1 = this.railPipeSample.specCrossSize;
    this.rail_connectionPin.specCrossSize2 = this.railPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [상단 레일 + 골조] 운반 고리 <- 레일 파이프 샘플, 지붕 가로대 파이프 샘플(단동)
   */
  public algorithmSpec_railAndFrame_railHook(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.basic.levelNumber - 1])
        .trunkPosition;
      this.railAndFrame_railHook.specPipeType = trunkPosition.straightPart.straightRoofPipeSample.specPipeType;
      this.railAndFrame_railHook.specCrossSize1 = trunkPosition.straightPart.straightRoofPipeSample.specCrossSize;
      this.railAndFrame_railHook.specCrossSize2 = this.railPipeSample.specCrossSize;
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
    }
  }

  /**
   * 규격 알고리즘: [상단 레일 + 골조] 이중 운반 고리 <- 레일 파이프 샘플, 서까래 파이프 샘플(단동), 중방 파이프 샘플(연동,벤로), 기둥 형태(연동,벤로)
   */
  public algorithmSpec_railAndFrame_secondLevelRailHook(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.basic.levelNumber - 1])
        .trunkPosition;
      if (this.railHookFixedFrame === CONST.LB_RAIL_HOOK_FIXED_FRAME_RAFTER) {
        this.railAndFrame_secondLevelRailHook.specPipeType = trunkPosition.rafterPart.rafterPipeSample.specPipeType;
        this.railAndFrame_secondLevelRailHook.specCrossSize1 = trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
        this.railAndFrame_secondLevelRailHook.specCrossSize2 = this.railPipeSample.specCrossSize;
      } else if (this.railHookFixedFrame === CONST.LB_RAIL_HOOK_FIXED_FRAME_MIDDLE) {
        this.railAndFrame_secondLevelRailHook.specPipeType = trunkPosition.columnPart.middlePipeSample.specPipeType;
        this.railAndFrame_secondLevelRailHook.specCrossSize1 = trunkPosition.columnPart.middlePipeSample.specCrossSize;
        this.railAndFrame_secondLevelRailHook.specCrossSize2 = this.railPipeSample.specCrossSize;
      }
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const columPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
      if (columPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.railAndFrame_secondLevelRailHook.specPipeType =
          columPosition.cylinderMiddlePart.middlePipeSample.specPipeType;
        this.railAndFrame_secondLevelRailHook.specCrossSize1 =
          columPosition.cylinderMiddlePart.middlePipeSample.specCrossSize;
        this.railAndFrame_secondLevelRailHook.specCrossSize2 = this.railPipeSample.specCrossSize;
      } else if (columPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.railAndFrame_secondLevelRailHook.specPipeType =
          columPosition.prismMiddlePart.middlePipeSample.specPipeType;
        this.railAndFrame_secondLevelRailHook.specCrossSize1 =
          columPosition.prismMiddlePart.middlePipeSample.specCrossSize;
        this.railAndFrame_secondLevelRailHook.specCrossSize2 = this.railPipeSample.specCrossSize;
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const columPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
      if (columPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.railAndFrame_secondLevelRailHook.specPipeType =
          columPosition.prismMiddlePart.middlePipeSample.specPipeType;
        this.railAndFrame_secondLevelRailHook.specCrossSize1 =
          columPosition.prismMiddlePart.middlePipeSample.specCrossSize;
        this.railAndFrame_secondLevelRailHook.specCrossSize2 = this.railPipeSample.specCrossSize;
      }
    }
  }

  /**
   * 규격 알고리즘: [상단 레일] 나사 <- 공통 샘플
   */
  public algorithmSpec_rail_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
