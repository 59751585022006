import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WingPartIL_WingRafter } from "vhows-design/src/object/design/frame/wing/WingPartIL_WingRafter";
import { WingPartIL_WingStraight } from "vhows-design/src/object/design/frame/wing/WingPartIL_WingStraight";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject
export class WingSampleIL_WingRafterPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const wingRafterPart: WingPartIL_WingRafter = <WingPartIL_WingRafter>this.part;
    wingRafterPart.algorithmSpec_wingRafter_pipe();
    wingRafterPart.algorithmSpec_wingRafter_pipeBendingExpense();
    wingRafterPart.algorithmSpec_wingRafterAndRoofRafter_connectionPin();
    wingRafterPart.algorithmSpec_wingRafterFirstAndBeam_outerWallClamp();
    wingRafterPart.algorithmSpec_wingRafterSecondAndBeamCylinder_secondLevelPairSconce();
    wingRafterPart.algorithmSpec_wingRafterSecondAndBeamPrism_secondLevelSoleSconce();
    wingRafterPart.algorithmSpec_wingRafterSecondAndWingStraight_innerTHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const wingStraightPart: WingPartIL_WingStraight = (<WingPositionIL>this.position).wingStraightPart;
    wingStraightPart.algorithmSpec_wingStraightCenterAndWingRafter_steelStringGrabber();
    wingStraightPart.algorithmSpec_floorWingStraightCenterAndWingRafter_steelStringGrabber();

    /// //////// 외부 ///////////

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    skirtLevel.wingPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

    // 바람막이 피복
    const windbreakLevel: WindbreakLevelIL = <WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index];
    windbreakLevel.wingPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 방풍벽 서까래 길이(파트)
   */
  // @override
  public algorithmSpec(): void {
    this.specLength = (<WingPartIL_WingRafter>this.part).wingRafterLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
