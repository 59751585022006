import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemManualSwitcher } from "vhows-design/src/object/design/item/list/ItemManualSwitcher";
import { SwitcherPartIL_Manual } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Manual";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject
export class SwitcherSampleIL_ManualSwitcher extends ItemManualSwitcher {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const manualPart: SwitcherPartIL_Manual = <SwitcherPartIL_Manual>this.part;
    manualPart.algorithmSpec_manual_manualSwitcher();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  /**
   * 규격 알고리즘 <- 선택(기초 피복), 범위 형태(기초 피복), 형태(기초 피복), 피복 추가(기초 피복), 피복 삭제(기초 피복)
   */
  public algorithmSpec_specUsage(): void {
    const coverLevel: CoverLevelIL = <CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index];
    if (this.part.label === CONST.LB_SWITCHER_MANUAL1) {
      if (coverLevel.coverThicknesses1[this.position.index] < CONST.LB_COVER_SWITCHER_USAGE_VALUE_NONWOVEN) {
        this.specUsage = CONST.ITEM_SPEC_VALUE_VINYL_USAGE;
      } else {
        this.specUsage = CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE;
      }
    } else if (this.part.label === CONST.LB_SWITCHER_MANUAL2) {
      if (coverLevel.coverThicknesses2[this.position.index] < CONST.LB_COVER_SWITCHER_USAGE_VALUE_NONWOVEN) {
        this.specUsage = CONST.ITEM_SPEC_VALUE_VINYL_USAGE;
      } else {
        this.specUsage = CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
