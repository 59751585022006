import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CompanyConst } from "vhows-design/src/common/constant/CompanyConst";
import { Charge } from "vhows-design/src/object/charge/Charge";
import { Preference } from "vhows-design/src/object/preference/Preference";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Address } from "vhows-design/src/object/common/Address";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-04-15
 */
@jsonObject({
  knownTypes: [Charge],
})
export class Company extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 설계 설정
  public preference: Preference = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  public agreeAge: boolean = false;
  public agreeTerms: boolean = false;
  public agreePrivacy: boolean = false;
  public agreeLegal: boolean = false;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  @jsonMember(Charge)
  public charge: Charge = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 사업자 및 업체 정보

  // 전문가 아이디
  @jsonMember(String)
  public expertId: string = null;
  // 대표 상호명
  @jsonMember(String)
  public companyName: string = null;
  // 등록 상호명
  @jsonMember(String)
  public regName: string = null;
  // 대표자
  @jsonMember(String)
  public ceoName: string = null;
  // 사업자번호
  @jsonMember(String)
  public regNumber: string = null;
  // 사업자구분(사업자등록여부)
  @jsonMember(Number)
  public businessDiv: number = 0;
  // 업태
  @jsonMember(String)
  public businessType: string = null;
  // 업종
  @jsonMember(String)
  public businessTypeDetail: string = null;
  // 주소
  @jsonMember(Address)
  public address: Address = new Address();
  // 대표 전화번호
  @jsonMember(String)
  public phoneNumber: string = null;
  // 대표 팩스번호
  @jsonMember(String)
  public faxNumber: string = null;
  // 대표 이메일
  @jsonMember(String)
  public email: string = null;
  // 세금계산서용 이메일
  @jsonMember(String)
  public taxEmail: string = null;
  // 대표 홈페이지
  @jsonMember(String)
  public homepageUrl: string = null;
  // 블로그
  @jsonMember(String)
  public blogUrl: string = null;
  // 프로필 이미지 경로
  @jsonMember(String)
  public profileImageSrc: string = null;
  // 사업자등록증 이미지 경로
  @jsonMember(String)
  public regNumberFileUrl: string = null;
  // 주민등록증 이미지 경로
  @jsonMember(String)
  public residentCardImageSrc: string = null;

  // 서비스 정보

  // 업무 분야
  @jsonMember(Number)
  public workField: number = null;
  // 시설 시공 업무 분야
  @jsonArrayMember(Number)
  public workBuildingFields: number[] = [];
  // 기계/설비 시공
  @jsonArrayMember(Number)
  public workEquipmentFields: number[] = [];
  // 온실자재 도매
  @jsonArrayMember(Number)
  public workGreenhouseMaterialFields: number[] = [];
  // 재배자재 도매
  @jsonArrayMember(Number)
  public workPlantingMaterialFields: number[] = [];
  // 철물공구
  @jsonArrayMember(Number)
  public workHardwareToolsFields: number[] = [];
  // 농기계
  @jsonArrayMember(Number)
  public workFarmingMachineFields: number[] = [];
  // 연간 매출 규모
  @jsonMember(Number)
  public annualSalesSize: number = 0;
  // 특수 시공 업무 분야
  @jsonArrayMember(String)
  public workBuilding2Fields: string[] = [];
  // 유통 업무 분야
  @jsonArrayMember(String)
  public workDistributingFields: string[] = [];
  // 제조 업무 분야
  @jsonArrayMember(String)
  public workManufacturingFields: string[] = [];
  // 기관 업무 분야
  @jsonArrayMember(String)
  public workInstitutionFields: string[] = [];
  // 경력 기간
  @jsonMember(String)
  public workPeriod: string = null;
  // A/S 기간
  @jsonMember(String)
  public asPeriod: string = null;
  // 시공 가능한 최소금액
  @jsonMember(String)
  public minBuildingCost: string = null;
  // 자격 요건
  @jsonArrayMember(Number)
  public licenses: number[] = [];
  // 서류 제공
  @jsonArrayMember(Number)
  public docOffers: number[] = [];
  // 부가 서비스
  @jsonArrayMember(Number)
  public addServices: number[] = [];
  // 서비스 지역
  @jsonArrayMember(String)
  public serviceAreas: string[] = null;
  // 전국 시공
  @jsonMember(Boolean)
  public wholeCountry: boolean = null;
  // 수출 경험 유무
  @jsonMember(Boolean)
  public exportExperience: boolean = null;
  // 수출 지역
  @jsonArrayMember(String)
  public exportAreas: string[] = null;
  // 업체 소개
  @jsonMember(String)
  public intro: string = null;
  z;
  // 기타 정보

  // 페이지 공개
  @jsonMember(Boolean)
  public pageOpened: boolean = true;
  // 전문성 인증
  @jsonMember(Boolean)
  public expertAuth: boolean = false;
  // 가입 경로
  @jsonMember(Number)
  public joinedRoute: number = null;
  // 만14세 이상
  @jsonMember(Boolean)
  public agressTermsFourteen: boolean = false;
  // 이용약관 동의
  @jsonMember(Boolean)
  public agreeTermsService: boolean = false;
  // 개인정보취급방침 동의
  @jsonMember(Boolean)
  public agreePrivacyPolicy: boolean = false;
  // 마케팅 활용 동의
  @jsonMember(Boolean)
  public agreeMarketing: boolean = false;

  // 관리 영역

  // 최대 직원수
  @jsonMember(Number)
  public maxEmployee: number = 10;
  // 등록 직원수
  @jsonMember(Number)
  public registeredEmployee: number = null;
  // 노트
  @jsonMember(String)
  public note: string = null;
  // 승인 상태
  @jsonMember(Number)
  public approvalStatus: number = null;
  // 생성 일시
  @jsonMember(Date)
  public createDate: Date = null;
  // 최종 변경 일시
  @jsonMember(Date)
  public lastUpdate: Date = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
    this.address = new Address();
    this.charge = new Charge();
    // 전문설계 설정 조회시 에러 발생하여 주석 처리함.
    // this.preference = new Preference();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(): void {
    this.address.setAssociation();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {
    this.companyName = "브이하우스";
    this.ceoName = "김평화";
    this.regNumber = "(조직 정보 없음)";
    this.businessType = "서비스업";
    this.businessTypeDetail = "소프트웨어개발";
    this.phoneNumber = "";
    this.faxNumber = "";
    this.homepageUrl = "vhows.com";
    this.maxEmployee = 0;

    this.address.setDefaultData();
    this.preference.setDefaultData();
  }

  //----------------------------------
  // 조직 관련
  //----------------------------------

  /**
   * workField에 따라 하위 분야를 초기화
   */
  public initializeWorkFields(workField: string) {
    if (workField === CompanyConst.workField.BUILDER) {
      this.workDistributingFields = [];
      this.workManufacturingFields = [];
      this.workInstitutionFields = [];
    } else if (workField === CompanyConst.workField.BUILDER_DISTRIBUTER) {
      this.workManufacturingFields = [];
      this.workInstitutionFields = [];
    } else if (workField === CompanyConst.workField.BUILDER_MANUFACTURER) {
      this.workDistributingFields = [];
      this.workInstitutionFields = [];
    } else if (workField === CompanyConst.workField.BUILDER_DISTRIBUTER_MANUFACTURER) {
      this.workInstitutionFields = [];
    } else if (workField === CompanyConst.workField.DISTRIBUTER) {
      this.workBuildingFields = [];
      this.workBuilding2Fields = [];
      this.workManufacturingFields = [];
      this.workInstitutionFields = [];
    } else if (workField === CompanyConst.workField.DISTRIBUTER_MANUFACTURER) {
      this.workBuildingFields = [];
      this.workBuilding2Fields = [];
      this.workInstitutionFields = [];
    } else if (workField === CompanyConst.workField.MANUFACTURER) {
      this.workBuildingFields = [];
      this.workBuilding2Fields = [];
      this.workDistributingFields = [];
      this.workInstitutionFields = [];
    } else if (workField === CompanyConst.workField.INSTITUTION) {
      this.workBuildingFields = [];
      this.workBuilding2Fields = [];
      this.workDistributingFields = [];
      this.workManufacturingFields = [];
    }
  }

  /**
   * workField에 따라 하위 분야를 초기화 - 조직
   */
  public initializeWorkFieldsForCompany(workField: number) {
    if (workField === CompanyConst.workFieldValue.DISTRIBUTER_BUILDER) {
      this.workGreenhouseMaterialFields = [];
      this.workPlantingMaterialFields = [];
      this.workHardwareToolsFields = [];
      this.workFarmingMachineFields = [];
    } else if (workField === CompanyConst.workFieldValue.MANUFACTURER_WHOLESALER) {
      this.workBuildingFields = [];
      this.workEquipmentFields = [];
      this.licenses = [];
      this.docOffers = [];
      this.addServices = [];
      this.serviceAreas = [];
    } else if (workField === CompanyConst.workFieldValue.PLATFORM) {
      this.workBuildingFields = [];
      this.workEquipmentFields = [];
      this.workGreenhouseMaterialFields = [];
      this.workPlantingMaterialFields = [];
      this.workHardwareToolsFields = [];
      this.workFarmingMachineFields = [];
      this.licenses = [];
      this.docOffers = [];
      this.addServices = [];
      this.serviceAreas = [];
    }
  }
}
