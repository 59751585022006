import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";
import { ScreennetPositionIL } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { WindbreakPositionIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPositionIL";
import { FixingPartIL_NormalPad } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_NormalPad";
import { BandstringLevelIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringLevelIL";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";
import { ScreennetPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPartIL_FixingNormalPad";
import { SkirtPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingNormalPad";
import { WindbreakPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPartIL_FixingNormalPad";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject
export class FixingSampleIL_NormalPadSpring extends ItemPadSpring {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const normalPadPart: FixingPartIL_NormalPad = <FixingPartIL_NormalPad>this.part;
    normalPadPart.algorithmSpec_normalPad_normalPadSpring();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<FixingPositionIL>this.level.positionAC[p]).normalPadPart.normalPadSpringSample.specMaterial = this.specMaterial;
    }

    /// //////// 외부 ///////////

    // 마구리
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const endpieceLevel: EndpieceLevelIL = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        endpieceLevel.frontPosition.gatePart.algorithmSpec_sliding_normalPadSpring();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        endpieceLevel.backPosition.gatePart.algorithmSpec_sliding_normalPadSpring();
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const endpieceLevel: EndpieceLevelVL = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        endpieceLevel.frontPosition.gatePart.algorithmSpec_sliding_normalPadSpring();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        endpieceLevel.backPosition.gatePart.algorithmSpec_sliding_normalPadSpring();
      }
    }

    // 밴드끈
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        const bandstringLevel: BandstringLevelIL = <BandstringLevelIL>(
          this.struct.bandstringWorkIL.levelAC[this.level.index]
        );
        for (const bandstringPosition of bandstringLevel.positionAC as BandstringPositionIL[]) {
          bandstringPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
        }
      }
    }

    // // 개폐기
    // const switcherPart_FixingNormalPad: SwitcherPartIL_FixingNormalPad = (<SwitcherPositionIL>this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]).fixingNormalPadPart;
    // switcherPart_FixingNormalPad.algorithmSpec_normalPad_normalPadSpring();

    // 치마 피복
    const skirtPart_FixingNormalPad: SkirtPartIL_FixingNormalPad = (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingNormalPadPart;
    skirtPart_FixingNormalPad.algorithmSpec_normalPad_padConnectionPin();

    // 바람막이 피복
    const windbreakPart_FixingNormalPad: WindbreakPartIL_FixingNormalPad = (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingNormalPadPart;
    windbreakPart_FixingNormalPad.algorithmSpec_normalPad_normalPadSpring();

    // 방충망
    if (this.level.index === 0) {
      const screennetPart_FixingNormalPad: ScreennetPartIL_FixingNormalPad = (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.levelAC[this.level.index].positionAC[this.position.index]
      )).fixingNormalPadPart;
      screennetPart_FixingNormalPad.algorithmSpec_normalPad_normalPadSpring();
    }

    // 창문
    if (this.level.index === 0) {
      const windowLevel: WindowLevelIL = <WindowLevelIL>this.struct.windowWorkIL.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        windowLevel.leftPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
        windowLevel.rightPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        windowLevel.frontPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        windowLevel.backPosition.fixingNormalPadPart.algorithmSpec_normalPad_normalPadSpring();
      }
    }

    // 천창 피복
    if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.struct.skyCoverWorkIL.level1.skylightPosition.normalPadPart.algorithmSpec_normalPad_normalPadSpring();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
