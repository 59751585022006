import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ItemReducedMotor } from "vhows-design/src/object/design/item/list/ItemReducedMotor";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { CurSwitchSampleIL_ReducedMotor } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_ReducedMotor";
import { CurCoverWorkIL } from "vhows-design/src/object/design/curtain/cover/CurCoverWorkIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurSwitchSampleIL_ReducedMotor],
})
export class CurSwitchPartIL_Motor extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public reducedMotorSample: CurSwitchSampleIL_ReducedMotor; // 감속 모터 샘플

  // 아이템
  public motor_reducedMotor: ItemReducedMotor;
  public motorPower_electricWire: ItemElectricWire;
  public motorControl_electricWire: ItemElectricWire;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _availableCurtainArea: number; // 수용 커튼 면적

  /**
   * 수용 커튼 면적 (읽기전용)
   */
  public get availableCurtainArea(): number {
    return this._availableCurtainArea;
  }

  //
  public set availableCurtainArea(value: number) {
    this._availableCurtainArea = CommonUtil.fixFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.reducedMotorSample = new CurSwitchSampleIL_ReducedMotor();

    this.sampleAC = [this.reducedMotorSample];

    // 아이템
    this.motor_reducedMotor = new ItemReducedMotor();
    this.motorPower_electricWire = new ItemElectricWire();
    this.motorControl_electricWire = new ItemElectricWire();

    this.itemAC = [this.motor_reducedMotor, this.motorPower_electricWire, this.motorControl_electricWire];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.reducedMotorSample = <CurSwitchSampleIL_ReducedMotor>this.sampleAC[0];

    // 아이템
    this.motor_reducedMotor = <ItemReducedMotor>this.itemAC[0];
    this.motorPower_electricWire = <ItemElectricWire>this.itemAC[1];
    this.motorControl_electricWire = <ItemElectricWire>this.itemAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.reducedMotorSample.setDefaultData(
        0,
        CONST.ITEM_ID_REDUCED_MOTOR,
        CONST.ITEM_NAME_REDUCED_MOTOR,
        "커튼 감속 모터",
        "2hp, 드럼식, 삼상, 48mm, 48mm",
        "청오, COAM 87072, 100/80mm",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.motor_reducedMotor.setDefaultData(
        0,
        CONST.ITEM_ID_REDUCED_MOTOR,
        CONST.ITEM_NAME_REDUCED_MOTOR,
        "커튼 감속 모터",
        "2hp, 드럼식, 삼상, 48mm, 48mm",
        "청오, COAM 87072, 100/80mm",
      );
      this.motorPower_electricWire.setDefaultData(
        1,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "커튼 감속 모터 전원공급",
        "VCTF, 2.5sq, 3C, 100m",
      );
      this.motorControl_electricWire.setDefaultData(
        2,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "커튼 감속 모터 제어",
        "VCTF, 1.5sq, 3C, 100m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    if (CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion)) {
      // 아이템
      if (this.itemAC.length === 2) {
        // [커튼 감속 모터 전원공급] 전선 추가
        const motorPower_electricWire: ItemElectricWire = new ItemElectricWire();
        motorPower_electricWire.setAssociation(design, struct, work, level, position, this);
        motorPower_electricWire.setDefaultData(
          1,
          CONST.ITEM_ID_ELECTRIC_WIRE,
          CONST.ITEM_NAME_ELECTRIC_WIRE,
          "커튼 감속 모터 전원공급",
          "VCTF, 2.5sq, 3C, 100m",
        );
        motorPower_electricWire.selected = true;
        motorPower_electricWire.visible = true;
        this.itemAC.splice(1, 0, motorPower_electricWire);

        // 인덱스 재설정
        CommonUtil.reindexAC(this.itemAC);
      }
    }
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_motorPower_electricWire();
    this.algorithmSpec_motorControl_electricWire();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curCoverPosition: CurCoverPositionIL = <CurCoverPositionIL>this.struct.curCoverWorkIL.level1.curCoverPosition;

    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;
    let widthQuanity: number;
    let heightQuantity: number;
    let lengthQuantity: number;

    /// //////// 선택, 가시성 ///////////

    this.motor_reducedMotor.selected = true;
    this.motor_reducedMotor.visible = true;
    this.motorPower_electricWire.selected = true;
    this.motorPower_electricWire.visible = true;
    this.motorControl_electricWire.selected = true;
    this.motorControl_electricWire.visible = true;

    /// //////// 수량 ///////////

    this.motor_reducedMotor.designQuantity =
      1 * curSwitchPosition.switchDivisionValue * curCoverPosition.ceilingLevelValue;

    widthQuanity = (this.basicLevel.width + this.basic.buildingInterval) * this.basic.buildingNumber;
    heightQuantity = this.basicLevel.heightShoulder;
    lengthQuantity = this.basicLevel.length / 2;
    this.motorPower_electricWire.designQuantity =
      (((widthQuanity + heightQuantity + lengthQuantity) * curSwitchPosition.switchDivisionValue) /
        this.motorPower_electricWire.specLength) *
      curCoverPosition.ceilingLevelValue *
      CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;
    this.motorControl_electricWire.designQuantity =
      this.motorPower_electricWire.designQuantity * curCoverPosition.ceilingLevelValue;
  }

  /**
   * 알고리즘: 수용 커튼 면적 <- 커튼 감속 모터 샘플, 개폐 분할값(위치), 형태(커튼 피복)
   */
  public algorithm_availableCurtainArea(): void {
    if (this.position.label === CONST.LB_POSITION_CEILING) {
      const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;
      const curCoverPosition: CurCoverPositionIL = (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[0])
        .curCoverPosition;
      // const curCoverPosition: CurCoverPositionIL = (<CurCoverLevelIL> this.struct.curCoverWorkIL.levelAC[this.level.index]).curCoverPosition;
      const horsePower: number = parseFloat(this.reducedMotorSample.specHorsePower);

      if (curCoverPosition.type === CONST.LB_COVER_TYPE_SHADENET) {
        // 얇은 커튼
        this.availableCurtainArea =
          horsePower * curSwitchPosition.switchDivisionValue * CONST.NUM_CURTAIN_HP_PER_AREA_THIN;
      } else {
        // 두꺼운 커튼
        this.availableCurtainArea =
          horsePower * curSwitchPosition.switchDivisionValue * CONST.NUM_CURTAIN_HP_PER_AREA_THICK;
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [커튼 감속 모터] 감속 모터 <- 감속 모터 샘플
   */
  public algorithmSpec_motor_reducedMotor(): void {
    this.motor_reducedMotor.specs = this.reducedMotorSample.specs;
    this.motor_reducedMotor.brands = this.reducedMotorSample.brands;
  }

  /**
   * 규격 알고리즘: [커튼 감속 모터 전원공급] 전선 <- 감속 모터용 전선 샘플(제어기)
   */
  public algorithmSpec_motorPower_electricWire(): void {
    this.motorPower_electricWire.specs =
      this.struct.controllerWorkIL.level1.integratedPosition.controllerPart.motorPowerElectricWireSample.specs;
  }

  /**
   * 규격 알고리즘: [커튼 감속 모터 제어] 전선 <- 감속 모터용 전선 샘플(제어기)
   */
  public algorithmSpec_motorControl_electricWire(): void {
    this.motorControl_electricWire.specs =
      this.struct.controllerWorkIL.level1.integratedPosition.controllerPart.motorControlElectricWireSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
