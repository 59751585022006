import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Work } from "vhows-design/src/object/design/base/Work";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-21
 */
@jsonObject({
  knownTypes: [RoofLevelIL],
})
export class RoofWorkIL extends Work {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public level1: RoofLevelIL;
  public level2: RoofLevelIL;
  public level3: RoofLevelIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.level1 = new RoofLevelIL();
    this.level2 = new RoofLevelIL();
    this.level3 = new RoofLevelIL();

    this.levelAC = [this.level1, this.level2, this.level3];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.level1 = <RoofLevelIL>this.levelAC[0];
    this.level2 = <RoofLevelIL>this.levelAC[1];
    this.level3 = <RoofLevelIL>this.levelAC[2];

    super.setReferenceVariable();
  }

  // @override
  public setDefaultData(
    index: number = 0,
    depth: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    category: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, depth, selected, enabled, visible, label, category, buildNote);

    this.level1.setDefaultData(0, true, true, true, CONST.LB_LEVEL_1);
    this.level2.setDefaultData(1, true, true, true, CONST.LB_LEVEL_2);
    this.level3.setDefaultData(2, true, true, true, CONST.LB_LEVEL_3);
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
