import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemIntegratedController } from "vhows-design/src/object/design/item/list/ItemIntegratedController";
import { ControllerPart_Controller } from "vhows-design/src/object/design/switches/controller/ControllerPart_Controller";
import { ControllerPosition } from "vhows-design/src/object/design/switches/controller/ControllerPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-08-14
 */
@jsonObject
export class ControllerSample_IntegratedController extends ItemIntegratedController {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 용도
   */
  // @override
  public get purpose(): string {
    return this._purpose;
  }

  //
  public set purpose(value: string) {
    this._purpose = value;

    // 알고리즘
    if (this.isInit === false) {
      (<ControllerPart_Controller>this.part).algorithmSpec_controller_integratedController();
    }
  }

  /**
   * 비닐
   */
  // @override
  public get specVinylSwt(): number {
    return this._specVinylSwt;
  }

  //
  public set specVinylSwt(value: number) {
    this._specVinylSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      this.algorithmSpec_specVolume();
    }
  }

  /**
   * 부직
   */
  // @override
  public get specNonwovenSwt(): number {
    return this._specNonwovenSwt;
  }

  //
  public set specNonwovenSwt(value: number) {
    this._specNonwovenSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      this.algorithmSpec_specVolume();
    }
  }

  /**
   * 커튼
   */
  // @override
  public get specCurtainSwt(): number {
    return this._specCurtainSwt;
  }

  //
  public set specCurtainSwt(value: number) {
    this._specCurtainSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      this.algorithmSpec_specVolume();
    }
  }

  /**
   * 환기
   */
  // @override
  public get specVentSwt(): number {
    return this._specVentSwt;
  }

  //
  public set specVentSwt(value: number) {
    this._specVentSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      this.algorithmSpec_specVolume();
    }
  }

  /**
   * 관수
   */
  // @override
  public get specWateringSwt(): number {
    return this._specWateringSwt;
  }

  //
  public set specWateringSwt(value: number) {
    this._specWateringSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      this.algorithmSpec_specVolume();
    }
  }

  /**
   * 기타AC
   */
  // @override
  public get specOtherAcSwt(): number {
    return this._specOtherAcSwt;
  }

  //
  public set specOtherAcSwt(value: number) {
    this._specOtherAcSwt = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      this.algorithmSpec_specVolume();
    }
  }

  /**
   * 설계 수량
   */
  // @override
  public get designQuantity(): number {
    return this._designQuantity;
  }

  //
  public set designQuantity(value: number) {
    if (value < 0) {
      this._designQuantity = 0;
    } else {
      this._designQuantity = CommonUtil.roundUp(CommonUtil.fixFloat(value), 1);
    }

    // 알고리즘
    if (this.isInit === false) {
      // 파트 알고리즘 호출
      this.part.algorithmPart();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const controllerPart: ControllerPart_Controller = <ControllerPart_Controller>this.part;
    controllerPart.algorithmSpec_controller_integratedController();
    controllerPart.algorithm_sensorsQuantity();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 제어 형태, 작업 및 용도별 개폐기 수량(위치)
   */
  // @override
  public algorithmSpec(): void {
    const controllerPart: ControllerPart_Controller = <ControllerPart_Controller>this.part;
    const controllerPosition: ControllerPosition = <ControllerPosition>this.position;

    // 비닐 스위치
    this.specVinylSwt = controllerPosition.vinylSwitcherQuantity;
    // 부직 스위치
    this.specNonwovenSwt = controllerPosition.nonwovenSwitcherQuantity + controllerPosition.laggingSwitcherQuantity;
    // 통합 스위치
    if (controllerPart.controllerType === CONST.LB_CONTROLLER_CONTROLL_TYPE_MANUAL) {
      this.specCombineSwt = 0;
    } else {
      this.specCombineSwt = this.basic.levelNumber;
    }
  }

  /**
   * 규격 알고리즘: 용량 <- 모든 개별 스위치 수량, 여분 스위치 수량(파트)
   */
  public algorithmSpec_specVolume(): void {
    const controllerPart: ControllerPart_Controller = <ControllerPart_Controller>this.part;

    // 용량
    let totalSwt: number = 0;
    totalSwt =
      this.specVinylSwt +
      controllerPart.spareSwitchVinyl +
      this.specNonwovenSwt +
      controllerPart.spareSwitchNonwoven +
      this.specCurtainSwt +
      controllerPart.spareSwitchCurtain +
      this.specVentSwt +
      this.specWateringSwt +
      this.specOtherAcSwt;
    if (totalSwt <= ItemIntegratedController.SPEC_VOLUME_MAX_SMALL) {
      this.specVolume = CONST.ITEM_SPEC_VALUE_SMALL;
    } else if (totalSwt <= ItemIntegratedController.SPEC_VOLUME_MAX_MIDDLE_SMALL) {
      this.specVolume = CONST.ITEM_SPEC_VALUE_MIDDLE_SMALL;
    } else if (totalSwt <= ItemIntegratedController.SPEC_VOLUME_MAX_MIDDLE) {
      this.specVolume = CONST.ITEM_SPEC_VALUE_MIDDLE;
    } else if (totalSwt <= ItemIntegratedController.SPEC_VOLUME_MAX_MIDDLE_LARGE) {
      this.specVolume = CONST.ITEM_SPEC_VALUE_MIDDLE_LARGE;
    } else {
      this.specVolume = CONST.ITEM_SPEC_VALUE_LARGE;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
