import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { WindowPositionIL } from "vhows-design/src/object/design/other/window/il/WindowPositionIL";
import { WindowSampleIL_SashFramePipe } from "vhows-design/src/object/design/other/window/il/WindowSampleIL_SashFramePipe";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";
import { WindowSampleIL_SashDoor } from "vhows-design/src/object/design/other/window/il/WindowSampleIL_SashDoor";
import { ItemSashDoor } from "vhows-design/src/object/design/item/list/ItemSashDoor";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [WindowSampleIL_SashDoor, WindowSampleIL_SashFramePipe],
})
export class WindowPartIL_Sash extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public sashDoorSample: WindowSampleIL_SashDoor; // 샷시창문 샘플
  public sashFramePipeSample: WindowSampleIL_SashFramePipe; // 샷시창틀 파이프 샘플

  // 아이템
  public sash_door: ItemSashDoor;
  public sashFrame_pipe: ItemPipe;
  public sashFrameHeight_pipe: ItemPipe;
  public sashFrame_cSectionSteel: ItemPipe;
  public sashFrame_tHolder: ItemHolder;
  public sashFrame_tClamp: ItemHolder;
  public sashFrame_welding: ItemOtherExpense;
  public sashFrame_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _installType: string; // 설치 형태
  @jsonMember(Number)
  public _frameWidth: number; // 창틀 폭
  @jsonMember(Number)
  public _frameHeight: number; // 창틀 높이
  @jsonMember(Number)
  public _frameStraightLength: number; // 창틀 가로대 길이
  @jsonMember(Boolean)
  public _sashDoorSelected: boolean; // 샷시창문 선택

  /**
   * 설치 형태
   */
  public get installType(): string {
    return this._installType;
  }

  public set installType(value: string) {
    this._installType = value;

    // 알고리즘
    this.sashFramePipeSample.algorithmSpec();
    this.algorithmPart();

    // 다른 위치
    this.algorithm_installTypeByPosition();
  }

  /**
   * 창틀 폭
   */
  public get frameWidth(): number {
    return this._frameWidth;
  }

  public set frameWidth(value: number) {
    this._frameWidth = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<WindowPositionIL>this.position).fixingNormalPadPart.algorithmPart();

    // 다른 위치
    this.algorithm_frameSizeByPosition();
  }

  /**
   * 창틀 높이
   */
  public get frameHeight(): number {
    return this._frameHeight;
  }

  public set frameHeight(value: number) {
    this._frameHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<WindowPositionIL>this.position).fixingNormalPadPart.algorithmPart();

    // 다른 위치
    this.algorithm_frameSizeByPosition();
  }

  /**
   * 창틀 가로대 길이
   */
  public get frameStraightLength(): number {
    return this._frameStraightLength;
  }

  //
  public set frameStraightLength(value: number) {
    this._frameStraightLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 샷시창문 선택
   */
  public get sashDoorSelected(): boolean {
    return this._sashDoorSelected;
  }

  //
  public set sashDoorSelected(value: boolean) {
    this._sashDoorSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sashDoorSample = new WindowSampleIL_SashDoor();
    this.sashFramePipeSample = new WindowSampleIL_SashFramePipe();

    this.sampleAC = [this.sashDoorSample, this.sashFramePipeSample];

    // 아이템
    this.sash_door = new ItemSashDoor();
    this.sashFrame_pipe = new ItemPipe();
    this.sashFrameHeight_pipe = new ItemPipe();
    this.sashFrame_cSectionSteel = new ItemPipe();
    this.sashFrame_tHolder = new ItemHolder();
    this.sashFrame_tClamp = new ItemHolder();
    this.sashFrame_welding = new ItemOtherExpense();
    this.sashFrame_screw = new ItemScrew();

    this.itemAC = [
      this.sash_door,
      this.sashFrame_pipe,
      this.sashFrameHeight_pipe,
      this.sashFrame_cSectionSteel,
      this.sashFrame_tHolder,
      this.sashFrame_tClamp,
      this.sashFrame_welding,
      this.sashFrame_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.sashDoorSample = <WindowSampleIL_SashDoor>this.sampleAC[0];
    this.sashFramePipeSample = <WindowSampleIL_SashFramePipe>this.sampleAC[1];

    // 아이템
    this.sash_door = <ItemSashDoor>this.itemAC[0];
    this.sashFrame_pipe = <ItemPipe>this.itemAC[1];
    this.sashFrameHeight_pipe = <ItemPipe>this.itemAC[2];
    this.sashFrame_cSectionSteel = <ItemPipe>this.itemAC[3];
    this.sashFrame_tHolder = <ItemHolder>this.itemAC[4];
    this.sashFrame_tClamp = <ItemHolder>this.itemAC[5];
    this.sashFrame_welding = <ItemOtherExpense>this.itemAC[6];
    this.sashFrame_screw = <ItemScrew>this.itemAC[7];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param installType: string 설치 형태
   * @param frameWidth: number 창틀 폭
   * @param frameHeight: number 창틀 높이
   * @param sashDoorSelected: boolean 샷시창문 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    installType: string = "",
    frameWidth: number = 0,
    frameHeight: number = 0,
    sashDoorSelected: boolean = true,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._installType = installType;
    this._frameWidth = frameWidth;
    this._frameHeight = frameHeight;
    this._sashDoorSelected = sashDoorSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.sashDoorSample.setDefaultData(
        0,
        CONST.ITEM_ID_SASH_DOOR,
        CONST.ITEM_NAME_SASH_DOOR,
        "샷시창문",
        "단창, 1000mm, 1000mm",
      );
      this.sashFramePipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "샷시창틀",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.sash_door.setDefaultData(
        0,
        CONST.ITEM_ID_SASH_DOOR,
        CONST.ITEM_NAME_SASH_DOOR,
        "샷시창문",
        "단창, 1000mm, 1000mm",
      );
      this.sashFrame_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "샷시창틀",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.sashFrameHeight_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "샷시창틀 높이방향",
        "원형, 일반, 48.1mm, 2.1T, 6m, -",
      );
      this.sashFrame_cSectionSteel.setDefaultData(
        3,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "샷시창틀",
        "각형, 일반, 60×30mm, 1.8T, 5m, -",
      );
      this.sashFrame_tHolder.setDefaultData(
        4,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "샷시창틀",
        "원형, 일반, 25mm, 25mm",
      );
      this.sashFrame_tClamp.setDefaultData(
        5,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "샷시창틀",
        "원형, 일반, 48mm, 48mm",
      );
      this.sashFrame_welding.setDefaultData(6, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "샷시창틀", "일반");
      this.sashFrame_screw.setDefaultData(
        7,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "샷시창틀",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      // 샷시창문 샘플
      const sashDoorSample: WindowSampleIL_SashDoor = new WindowSampleIL_SashDoor();
      sashDoorSample.setAssociation(design, struct, work, level, position, this);
      sashDoorSample.setDefaultData(
        1,
        CONST.ITEM_ID_SASH_DOOR,
        CONST.ITEM_NAME_SASH_DOOR,
        "샷시창문",
        "단창, 1000mm, 1000mm",
      );
      this.sampleAC.splice(0, 0, sashDoorSample);
      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }
    // 아이템
    if (this.itemAC.length === 1) {
      // [샷시창틀 높이방향] 파이프
      const sashFrameHeight_pipe: ItemPipe = new ItemPipe();
      sashFrameHeight_pipe.setAssociation(design, struct, work, level, position, this);
      sashFrameHeight_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "샷시창틀 높이방향",
        (<ItemPipe>this.itemAC[0]).specs,
      );
      sashFrameHeight_pipe.selected = false;
      sashFrameHeight_pipe.visible = false;
      this.itemAC.push(sashFrameHeight_pipe);
      // [샷시창틀] 용접
      const sashFrame_welding: ItemOtherExpense = new ItemOtherExpense();
      sashFrame_welding.setAssociation(design, struct, work, level, position, this);
      sashFrame_welding.setDefaultData(2, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "샷시창틀", "일반");
      sashFrame_welding.selected = false;
      sashFrame_welding.visible = false;
      this.itemAC.push(sashFrame_welding);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 3) {
      // [샷시창틀] C형강
      const sashFrame_cSectionSteel: ItemPipe = new ItemPipe();
      sashFrame_cSectionSteel.setAssociation(design, struct, work, level, position, this);
      sashFrame_cSectionSteel.setDefaultData(
        2,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "샷시창틀",
        "각형, 일반, 60×30mm, 1.8T, 5m, -",
      );
      this.itemAC.splice(2, 0, sashFrame_cSectionSteel);
      // [샷시창틀] T 고정구
      const sashFrame_tHolder: ItemHolder = new ItemHolder();
      sashFrame_tHolder.setAssociation(design, struct, work, level, position, this);
      sashFrame_tHolder.setDefaultData(
        3,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "샷시창틀",
        "원형, 일반, 32mm, 32mm",
      );
      this.itemAC.splice(3, 0, sashFrame_tHolder);
      // [샷시창틀] T 클램프
      const sashFrame_tClamp: ItemHolder = new ItemHolder();
      sashFrame_tClamp.setAssociation(design, struct, work, level, position, this);
      sashFrame_tClamp.setDefaultData(
        4,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "샷시창틀",
        "원형, 일반, 48mm, 48mm",
      );
      this.itemAC.splice(4, 0, sashFrame_tClamp);
      // [샷시창틀] 나사
      const sashFrame_screw: ItemScrew = new ItemScrew();
      sashFrame_screw.setAssociation(design, struct, work, level, position, this);
      sashFrame_screw.setDefaultData(
        6,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "샷시창틀",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.itemAC.push(sashFrame_screw);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 7 && this.itemAC[0].productId === CONST.ITEM_ID_PIPE) {
      // 샷시창문
      const sash_door: ItemSashDoor = new ItemSashDoor();
      sash_door.setAssociation(design, struct, work, level, position, this);
      sash_door.setDefaultData(
        0,
        CONST.ITEM_ID_SASH_DOOR,
        CONST.ITEM_NAME_SASH_DOOR,
        "샷시창문",
        "단창, 1000mm, 1000mm",
      );
      sash_door.selected = false;
      this.itemAC.splice(0, 0, sash_door);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_frameStraightLength();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_sashFrame_tHolder();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const windowPosition: WindowPositionIL = <WindowPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    if (this.sashDoorSelected) {
      this.sash_door.selected = true;
    } else {
      this.sash_door.selected = false;
    }
    this.sash_door.visible = true;
    this.sashFrame_pipe.selected = true;
    this.sashFrame_pipe.visible = true;
    if (this.installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER) {
      this.sashFrameHeight_pipe.selected = false;
      this.sashFrameHeight_pipe.visible = false;
      // 하드코딩 : C형강, T 고정구, T 클램프
      if (
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM
      ) {
        this.sashFrame_cSectionSteel.selected = false;
        this.sashFrame_cSectionSteel.visible = true;
        this.sashFrame_tHolder.selected = true;
        this.sashFrame_tHolder.visible = true;
        this.sashFrame_tClamp.selected = false;
        this.sashFrame_tClamp.visible = false;
        this.sashFrame_welding.selected = false;
        this.sashFrame_welding.visible = true;
      } else if (
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_60_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_75_75_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_100_50_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_100_100_MM ||
        this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_125_75_MM
      ) {
        this.sashFrame_cSectionSteel.selected = false;
        this.sashFrame_cSectionSteel.visible = true;
        this.sashFrame_tHolder.selected = false;
        this.sashFrame_tHolder.visible = false;
        this.sashFrame_tClamp.selected = true;
        this.sashFrame_tClamp.visible = true;
        this.sashFrame_welding.selected = false;
        this.sashFrame_welding.visible = true;
      } else {
        this.sashFrame_cSectionSteel.selected = false;
        this.sashFrame_cSectionSteel.visible = true;
        this.sashFrame_tHolder.selected = false;
        this.sashFrame_tHolder.visible = false;
        this.sashFrame_tClamp.selected = false;
        this.sashFrame_tClamp.visible = false;
        this.sashFrame_welding.selected = true;
        this.sashFrame_welding.visible = true;
      }
    } else if (this.installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_STRAIGHT) {
      this.sashFrameHeight_pipe.selected = true;
      this.sashFrameHeight_pipe.visible = true;
      this.sashFrame_cSectionSteel.selected = false;
      this.sashFrame_cSectionSteel.visible = true;
      this.sashFrame_tHolder.selected = false;
      this.sashFrame_tHolder.visible = false;
      this.sashFrame_tClamp.selected = false;
      this.sashFrame_tClamp.visible = false;
      this.sashFrame_welding.selected = true;
      this.sashFrame_welding.visible = true;
    }
    this.sashFrame_screw.selected = true;
    this.sashFrame_screw.visible = true;

    /// //////// 수치 ///////////

    let widthLength: number;
    let heightLength: number;
    if (this.installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER) {
      widthLength = CommonUtil.convertMM2M(this.frameWidth) * windowPosition.windowQuantity;
    } else if (this.installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_STRAIGHT) {
      widthLength = this.sashFrame_pipe.getConnectedPipeLength(this.frameStraightLength, 2);
    }
    heightLength = CommonUtil.convertMM2M(this.frameHeight) * windowPosition.windowQuantity;

    /// //////// 수량 ///////////

    this.sash_door.designQuantity = windowPosition.windowQuantity;

    if (this.installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_RAFTER) {
      this.sashFrame_pipe.designQuantity = (widthLength * 2 + heightLength) / this.sashFrame_pipe.specLength;
    } else if (this.installType === CONST.LB_WINDOW_SASH_FRAME_INSTALL_TYPE_STRAIGHT) {
      this.sashFrame_pipe.designQuantity = widthLength / this.sashFrame_pipe.specLength;
    }

    this.sashFrameHeight_pipe.designQuantity = (heightLength * 2) / this.sashFrameHeight_pipe.specLength;

    this.sashFrame_cSectionSteel.designQuantity =
      (widthLength * 2 + heightLength * 2) / this.sashFrame_cSectionSteel.specLength;

    const sashFrame_tHolder_designQuantity: number = 6 * windowPosition.windowQuantity;
    this.sashFrame_tHolder.designQuantity = sashFrame_tHolder_designQuantity * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.sashFrame_tClamp.designQuantity = sashFrame_tHolder_designQuantity * CONST.NUM_EXTRA_RATE_T_CLAMP;

    this.sashFrame_welding.designQuantity = (widthLength + heightLength * 2) / CONST.NUM_INVERVAL_WELDING;

    this.sashFrame_screw.designQuantity =
      ((this.sashFrame_tHolder.getSelectedQuantity() * 3 + this.sashFrame_tClamp.getSelectedQuantity() * 4) /
        this.sashFrame_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 설치 형태 <- 다른 위치의 설치 형태
   */
  public algorithm_installTypeByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevelIL>this.level).rightPosition.sashPart.installType = this.installType;
      (<WindowLevelIL>this.level).frontPosition.sashPart.installType = this.installType;
      (<WindowLevelIL>this.level).backPosition.sashPart.installType = this.installType;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevelIL>this.level).backPosition.sashPart.installType = this.installType;
    }
  }

  /**
   * 알고리즘: 창틀 폭 <- 샷시창문 샘플 폭
   */
  public algorithm_frameWidth(): void {
    if (this.sashDoorSelected) {
      this.frameWidth = this.sashDoorSample.specWidth;
    }
  }

  /**
   * 알고리즘: 창틀 높이 <- 샷시창문 샘플 높이
   */
  public algorithm_frameHeight(): void {
    if (this.sashDoorSelected) {
      this.frameHeight = this.sashDoorSample.specLength;
    }
  }

  /**
   * 알고리즘: 창틀 크기 <- 창틀 크기(포지션)
   */
  public algorithm_frameSizeByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevelIL>this.level).rightPosition.sashPart.frameWidth = this.frameWidth;
      (<WindowLevelIL>this.level).rightPosition.sashPart.frameHeight = this.frameHeight;
      (<WindowLevelIL>this.level).frontPosition.sashPart.frameWidth = this.frameWidth;
      (<WindowLevelIL>this.level).frontPosition.sashPart.frameHeight = this.frameHeight;
      (<WindowLevelIL>this.level).backPosition.sashPart.frameWidth = this.frameWidth;
      (<WindowLevelIL>this.level).backPosition.sashPart.frameHeight = this.frameHeight;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevelIL>this.level).backPosition.sashPart.frameWidth = this.frameWidth;
      (<WindowLevelIL>this.level).backPosition.sashPart.frameHeight = this.frameHeight;
    }
  }

  /**
   * 알고리즘: 창틀 가로대 길이 <- 하우스 길이(기본정보), 하우스 폭(기본정보), 하우스 동수(기본정보)
   */
  public algorithm_frameStraightLength(): void {
    if (this.position.label === CONST.LB_POSITION_LEFT || this.position.label === CONST.LB_POSITION_RIGHT) {
      this.frameStraightLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
    } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
      this.frameStraightLength =
        this.basicLevel.width * this.basic.buildingNumber + this.design.preference.connectedPipeExtraLength;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [샷시창문] 문 <- 샷시창문 샘플
   */
  public algorithmSpec_sash_door(): void {
    this.sash_door.specs = this.sashDoorSample.specs;
  }

  /**
   * 규격 알고리즘: [샷시창틀] 파이프 <- 샷시창틀 파이프 샘플
   */
  public algorithmSpec_sashFrame_pipe(): void {
    this.sashFrame_pipe.specs = this.sashFramePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [샷시창틀] 파이프 <- 샷시창틀 파이프 샘플
   */
  public algorithmSpec_sashFrameHeight_pipe(): void {
    this.sashFrameHeight_pipe.specs = this.sashFramePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [샷시창틀]  <- 없음
   */
  public algorithmSpec_sashFrame_cSectionSteel(): void {}

  /**
   * 규격 알고리즘: [샷시창틀] T 고정구 <- 샷시창틀 파이프 샘플
   */
  public algorithmSpec_sashFrame_tHolder(): void {}

  /**
   * 규격 알고리즘: [샷시창틀] T 클램프 <- 샷시창틀 파이프 샘플
   */
  public algorithmSpec_sashFrame_tClamp(): void {
    // 하드코딩
    if (
      this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_42_MM ||
      this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM ||
      this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM ||
      this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_60_MM ||
      this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_75_75_MM ||
      this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_100_50_MM ||
      this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_100_100_MM ||
      this.sashFramePipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_125_75_MM
    ) {
      this.sashFrame_tClamp.specPipeType = this.sashFramePipeSample.specPipeType;
      this.sashFrame_tClamp.specCrossSize1 = this.sashFramePipeSample.specCrossSize;
      this.sashFrame_tClamp.specCrossSize2 = this.sashFramePipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [샷시창틀] 용접 <- 공통 샘플
   */
  public algorithmSpec_sashFrame_welding(): void {}

  /**
   * 규격 알고리즘: [샷시창틀] 나사 <- 공통 샘플
   */
  public algorithmSpec_sashFrame_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
