import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { SkirtLevel } from "vhows-design/src/object/design/cover/skirt/SkirtLevel";
import { DesignUtil } from "vhows-design/src/object/design/DesignUtil";
import { PartitionLevel } from "vhows-design/src/object/design/other/partition/PartitionLevel";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { CoverPart_Vinyl } from "vhows-design/src/object/design/cover/cover/CoverPart_Vinyl";
import { CoverPart_WeavingFilm } from "vhows-design/src/object/design/cover/cover/CoverPart_WeavingFilm";
import { CoverPart_Shadenet } from "vhows-design/src/object/design/cover/cover/CoverPart_Shadenet";
import { CoverPart_Tent } from "vhows-design/src/object/design/cover/cover/CoverPart_Tent";
import { CoverPart_Nonwoven } from "vhows-design/src/object/design/cover/cover/CoverPart_Nonwoven";
import { CoverPart_Cashmilon } from "vhows-design/src/object/design/cover/cover/CoverPart_Cashmilon";
import { CoverPart_ColorLagging } from "vhows-design/src/object/design/cover/cover/CoverPart_ColorLagging";
import { CoverPart_Lagging } from "vhows-design/src/object/design/cover/cover/CoverPart_Lagging";
import { CoverPart_Quilting } from "vhows-design/src/object/design/cover/cover/CoverPart_Quilting";
import { CoverPart_Screen } from "vhows-design/src/object/design/cover/cover/CoverPart_Screen";
import { CoverPart_Mat } from "vhows-design/src/object/design/cover/cover/CoverPart_Mat";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-03
 */
@jsonObject({
  knownTypes: [
    CoverPart_Cashmilon,
    CoverPart_ColorLagging,
    CoverPart_Lagging,
    CoverPart_Mat,
    CoverPart_Nonwoven,
    CoverPart_Quilting,
    CoverPart_Screen,
    CoverPart_Shadenet,
    CoverPart_Tent,
    CoverPart_Vinyl,
    CoverPart_WeavingFilm,
  ],
})
export class CoverPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public vinylPart: CoverPart_Vinyl;
  public weavingFilmPart: CoverPart_WeavingFilm;
  public shadenetPart: CoverPart_Shadenet;
  public tentPart: CoverPart_Tent;
  public nonwovenPart: CoverPart_Nonwoven;
  public cashmilonPart: CoverPart_Cashmilon;
  public colorLaggingPart: CoverPart_ColorLagging;
  public laggingPart: CoverPart_Lagging;
  public quiltingPart: CoverPart_Quilting;
  public screenPart: CoverPart_Screen;
  public matPart: CoverPart_Mat;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 범위 선택 - 측면 */
  public scopeSelectedSide: boolean;

  /** 범위 선택 - 지붕 */
  public scopeSelectedRoof: boolean;

  /** 범위 선택 - 앞면 */
  public scopeSelectedFront: boolean;

  /** 범위 선택 - 뒷면 */
  public scopeSelectedBack: boolean;

  /** 범위 선택 - 앞면 포함형 */
  public scopeSelectedFrontIncluded: boolean;

  /** 범위 선택 - 뒷면 포함형 */
  public scopeSelectedBackIncluded: boolean;

  /** 범위 선택 - 광폭중앙 */
  public scopeSelectedDoubleCenter: boolean;

  /**
   * 피복 형태별 두께값
   */
  public get thicknessValue(): number {
    return DesignUtil.getCoverThickness(this.type);
  }

  public set thicknessValue(value: number) {}

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _scopeType: string; // 범위 형태
  @jsonMember(Number)
  public _switcherOrderSide: number; // 개폐 차수 - 측면
  @jsonMember(Number)
  public _switcherOrderRoof: number; // 개폐 차수 - 지붕
  @jsonMember(Number)
  public _switcherOrderFront: number; // 개폐 차수 - 앞면
  @jsonMember(Number)
  public _switcherOrderBack: number; // 개폐 차수 - 뒷면
  @jsonMember(Boolean)
  public _skirtCoverSide: boolean; // 치마 피복 - 측면
  @jsonMember(Boolean)
  public _skirtCoverRoof: boolean; // 치마 피복 - 지붕
  @jsonMember(Boolean)
  public _skirtCoverFront: boolean; // 치마 피복 - 앞면
  @jsonMember(Boolean)
  public _skirtCoverBack: boolean; // 치마 피복 - 뒷면
  @jsonMember(Boolean)
  public _windbreakCoverSide: boolean; // 바람막이 피복 - 측면
  @jsonMember(Boolean)
  public _windbreakCoverRoof: boolean; // 바람막이 피복 - 지붕
  @jsonMember(Boolean)
  public _windbreakCoverFront: boolean; // 바람막이 피복 - 앞면
  @jsonMember(Boolean)
  public _windbreakCoverBack: boolean; // 바람막이 피복 - 뒷면
  @jsonMember(Boolean)
  public _partitionCoverTrunk: boolean; // 칸막이 피복 - 몸통
  @jsonMember(Boolean)
  public _skylightCover: boolean; // 천창 피복

  /**
   * 범위 형태
   */
  public get scopeType(): string {
    return this._scopeType;
  }

  public set scopeType(value: string) {
    this._scopeType = value;

    // 알고리즘
    this.algorithm_scopeSelectedByScopeType();
    this.algorithm_switcherOrderValue();
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    this.algorithm_partitionCoverSelected();
    this.algorithm_skylightCoverSelected();
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 측면
   */
  public get switcherOrderSide(): number {
    return this._switcherOrderSide;
  }

  public set switcherOrderSide(value: number) {
    this._switcherOrderSide = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 지붕
   */
  public get switcherOrderRoof(): number {
    return this._switcherOrderRoof;
  }

  public set switcherOrderRoof(value: number) {
    this._switcherOrderRoof = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 앞면
   */
  public get switcherOrderFront(): number {
    return this._switcherOrderFront;
  }

  public set switcherOrderFront(value: number) {
    this._switcherOrderFront = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 개폐 차수 - 뒷면
   */
  public get switcherOrderBack(): number {
    return this._switcherOrderBack;
  }

  public set switcherOrderBack(value: number) {
    this._switcherOrderBack = value;

    // 알고리즘
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 치마 피복 - 측면
   */
  public get skirtCoverSide(): boolean {
    return this._skirtCoverSide;
  }

  public set skirtCoverSide(value: boolean) {
    this._skirtCoverSide = value;

    // 알고리즘
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).sidePosition.normalPart.algorithmPart();
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).sidePosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).sidePosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 치마 피복 - 지붕
   */
  public get skirtCoverRoof(): boolean {
    return this._skirtCoverRoof;
  }

  public set skirtCoverRoof(value: boolean) {
    this._skirtCoverRoof = value;

    // 알고리즘
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).roofPosition.normalPart.algorithmPart();
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).roofPosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).roofPosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 치마 피복 - 앞면
   */
  public get skirtCoverFront(): boolean {
    return this._skirtCoverFront;
  }

  public set skirtCoverFront(value: boolean) {
    this._skirtCoverFront = value;

    // 알고리즘
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).frontPosition.normalPart.algorithmPart();
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).frontPosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).frontPosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 치마 피복 - 뒷면
   */
  public get skirtCoverBack(): boolean {
    return this._skirtCoverBack;
  }

  public set skirtCoverBack(value: boolean) {
    this._skirtCoverBack = value;

    // 알고리즘
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).backPosition.normalPart.algorithmPart();
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).backPosition.fixingNormalPadPart.algorithmPart();
    (<SkirtLevel>this.struct.skirtWork.levelAC[this.level.index]).backPosition.fixingLaggingPadPart.algorithmPart();
  }

  /**
   * 바람막이 피복 - 측면
   */
  public get windbreakCoverSide(): boolean {
    return this._windbreakCoverSide;
  }

  public set windbreakCoverSide(value: boolean) {
    this._windbreakCoverSide = value;

    // 알고리즘
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWork.algorithmBasic();
  }

  /**
   * 바람막이 피복 - 지붕
   */
  public get windbreakCoverRoof(): boolean {
    return this._windbreakCoverRoof;
  }

  public set windbreakCoverRoof(value: boolean) {
    this._windbreakCoverRoof = value;

    // 알고리즘
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWork.algorithmBasic();
  }

  /**
   * 바람막이 피복 - 앞면
   */
  public get windbreakCoverFront(): boolean {
    return this._windbreakCoverFront;
  }

  public set windbreakCoverFront(value: boolean) {
    this._windbreakCoverFront = value;

    // 알고리즘
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWork.algorithmBasic();
  }

  /**
   * 바람막이 피복 - 뒷면
   */
  public get windbreakCoverBack(): boolean {
    return this._windbreakCoverBack;
  }

  public set windbreakCoverBack(value: boolean) {
    this._windbreakCoverBack = value;

    // 알고리즘
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 바람막이
    this.struct.windbreakWork.algorithmBasic();
  }

  /**
   * 칸막이 피복 - 몸통
   */
  public get partitionCoverTrunk(): boolean {
    return this._partitionCoverTrunk;
  }

  public set partitionCoverTrunk(value: boolean) {
    this._partitionCoverTrunk = value;

    /// //////// 외부 ///////////

    // 칸막이 피복
    if (this.level.index === 0) {
      (<PartitionLevel>this.struct.partitionWork.levelAC[this.level.index]).trunkPosition.coverPart.algorithmPart();
      (<PartitionLevel>(
        this.struct.partitionWork.levelAC[this.level.index]
      )).trunkPosition.fixingNormalPadPart.algorithmPart();
      (<PartitionLevel>(
        this.struct.partitionWork.levelAC[this.level.index]
      )).trunkPosition.fixingLaggingPadPart.algorithmPart();
    }
  }

  /**
   * 천창 피복
   */
  public get skylightCover(): boolean {
    return this._skylightCover;
  }

  public set skylightCover(value: boolean) {
    this._skylightCover = value;

    // 알고리즘
    // (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 천창 피복
    // this.struct.skyCoverWork.algorithmBasic();
    if (this.level.index === 0) {
      this.struct.skyCoverWork.level1.skylightPosition.vinylPart.algorithmPart();
      // this.struct.skyCoverWork.level1.skylightPosition.normalPadPart.algorithmPart();
    }
  }

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();
    this.algorithm_switcherOrderValue();
    this.algorithm_skirtCoverSelected();
    this.algorithm_windbreakCoverSelected();
    this.algorithm_partitionCoverSelected();
    this.algorithm_skylightCoverSelected();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    // 중수
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_scopeTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    (<CoverLevel>this.level).algorithm_coverLayersAndTypes();

    /// //////// 외부 ///////////

    // 알고리즘 호출: 피복 파트
    (<CoverLevel>this.level).callAlgorithm_coverPartByCover();

    // 알고리즘 호출: 피복 고정 파트
    (<CoverLevel>this.level).callAlgorithm_fixingPartByCover();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.vinylPart = new CoverPart_Vinyl();
    this.weavingFilmPart = new CoverPart_WeavingFilm();
    this.shadenetPart = new CoverPart_Shadenet();
    this.tentPart = new CoverPart_Tent();
    this.nonwovenPart = new CoverPart_Nonwoven();
    this.cashmilonPart = new CoverPart_Cashmilon();
    this.colorLaggingPart = new CoverPart_ColorLagging();
    this.laggingPart = new CoverPart_Lagging();
    this.quiltingPart = new CoverPart_Quilting();
    this.screenPart = new CoverPart_Screen();
    this.matPart = new CoverPart_Mat();

    this.partAC = [
      this.vinylPart,
      this.weavingFilmPart,
      this.shadenetPart,
      this.tentPart,
      this.nonwovenPart,
      this.cashmilonPart,
      this.colorLaggingPart,
      this.laggingPart,
      this.quiltingPart,
      this.screenPart,
      this.matPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.vinylPart = <CoverPart_Vinyl>this.partAC[0];
    this.weavingFilmPart = <CoverPart_WeavingFilm>this.partAC[1];
    this.shadenetPart = <CoverPart_Shadenet>this.partAC[2];
    this.tentPart = <CoverPart_Tent>this.partAC[3];
    this.nonwovenPart = <CoverPart_Nonwoven>this.partAC[4];
    this.cashmilonPart = <CoverPart_Cashmilon>this.partAC[5];
    this.colorLaggingPart = <CoverPart_ColorLagging>this.partAC[6];
    this.laggingPart = <CoverPart_Lagging>this.partAC[7];
    this.quiltingPart = <CoverPart_Quilting>this.partAC[8];
    this.screenPart = <CoverPart_Screen>this.partAC[9];
    this.matPart = <CoverPart_Mat>this.partAC[10];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param scopeType: string 범위 형태
   * @param switcherOrderSide: number 개폐 차수 - 측면
   * @param switcherOrderRoof: number 개폐 차수 - 지붕
   * @param switcherOrderFront: number 개폐 차수 - 앞면
   * @param switcherOrderBack: number 개폐 차수 - 뒷면
   * @param skirtCoverSide: boolean 치마 피복 - 측면
   * @param skirtCoverRoof: boolean 치마 피복 - 지붕
   * @param skirtCoverFront: boolean 치마 피복 - 앞면
   * @param skirtCoverBack: boolean 치마 피복 - 뒷면
   * @param windbreakCoverSide: boolean 바람막이 피복 - 측면
   * @param windbreakCoverRoof: boolean 바람막이 피복 - 지붕
   * @param windbreakCoverFront: boolean 바람막이 피복 - 앞면
   * @param windbreakCoverBack: boolean 바람막이 피복 - 뒷면
   * @param partitionCoverTrunk: boolean 칸막이 피복 - 몸통
   * @param skylightCover: boolean 천창 피복
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    scopeType: string = "",
    switcherOrderSide: number = 0,
    switcherOrderRoof: number = 0,
    switcherOrderFront: number = 0,
    switcherOrderBack: number = 0,
    skirtCoverSide: boolean = false,
    skirtCoverRoof: boolean = false,
    skirtCoverFront: boolean = false,
    skirtCoverBack: boolean = false,
    windbreakCoverSide: boolean = false,
    windbreakCoverRoof: boolean = false,
    windbreakCoverFront: boolean = false,
    windbreakCoverBack: boolean = false,
    partitionCoverTrunk: boolean = false,
    skylightCover: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._scopeType = scopeType;
    this._switcherOrderSide = switcherOrderSide;
    this._switcherOrderRoof = switcherOrderRoof;
    this._switcherOrderFront = switcherOrderFront;
    this._switcherOrderBack = switcherOrderBack;
    this._skirtCoverSide = skirtCoverSide;
    this._skirtCoverRoof = skirtCoverRoof;
    this._skirtCoverFront = skirtCoverFront;
    this._skirtCoverBack = skirtCoverBack;
    this._windbreakCoverSide = windbreakCoverSide;
    this._windbreakCoverRoof = windbreakCoverRoof;
    this._windbreakCoverFront = windbreakCoverFront;
    this._windbreakCoverBack = windbreakCoverBack;
    this._partitionCoverTrunk = partitionCoverTrunk;
    this._skylightCover = skylightCover;

    if (this.level.index >= 0) {
      // 비닐
      if (type === CONST.LB_COVER_TYPE_VINYL) {
        this.vinylPart.setDefaultData(CONST.INDEX_SG_COVER_VINYL, true, false, true, CONST.LB_COVER_VINYL, "");
      } else {
        this.vinylPart.setDefaultData(CONST.INDEX_SG_COVER_VINYL, false, false, false, CONST.LB_COVER_VINYL, "");
      }

      // 직조 필름
      if (type === CONST.LB_COVER_TYPE_WEAVING_FILM) {
        this.weavingFilmPart.setDefaultData(
          CONST.INDEX_SG_COVER_WEAVING_FILM,
          true,
          false,
          true,
          CONST.LB_COVER_WEAVING_FILM,
          "",
        );
      } else {
        this.weavingFilmPart.setDefaultData(
          CONST.INDEX_SG_COVER_WEAVING_FILM,
          false,
          false,
          false,
          CONST.LB_COVER_WEAVING_FILM,
          "",
        );
      }

      // 차광망
      if (type === CONST.LB_COVER_TYPE_SHADENET) {
        this.shadenetPart.setDefaultData(CONST.INDEX_SG_COVER_SHADENET, true, false, true, CONST.LB_COVER_SHADENET, "");
      } else {
        this.shadenetPart.setDefaultData(
          CONST.INDEX_SG_COVER_SHADENET,
          false,
          false,
          false,
          CONST.LB_COVER_SHADENET,
          "",
        );
      }

      // 텐텐지
      if (type === CONST.LB_COVER_TYPE_TENT) {
        this.tentPart.setDefaultData(CONST.INDEX_SG_COVER_TENT, true, false, true, CONST.LB_COVER_TENT, "");
      } else {
        this.tentPart.setDefaultData(CONST.INDEX_SG_COVER_TENT, false, false, false, CONST.LB_COVER_TENT, "");
      }

      // 부직포
      if (type === CONST.LB_COVER_TYPE_NONWOVEN) {
        this.nonwovenPart.setDefaultData(CONST.INDEX_SG_COVER_NONWOVEN, true, false, true, CONST.LB_COVER_NONWOVEN, "");
      } else {
        this.nonwovenPart.setDefaultData(
          CONST.INDEX_SG_COVER_NONWOVEN,
          false,
          false,
          false,
          CONST.LB_COVER_NONWOVEN,
          "",
        );
      }

      // 카시미론
      if (type === CONST.LB_COVER_TYPE_CASHMILON) {
        this.cashmilonPart.setDefaultData(
          CONST.INDEX_SG_COVER_CASHMILON,
          true,
          false,
          true,
          CONST.LB_COVER_CASHMILON,
          "",
          false,
        );
      } else {
        this.cashmilonPart.setDefaultData(
          CONST.INDEX_SG_COVER_CASHMILON,
          false,
          false,
          false,
          CONST.LB_COVER_CASHMILON,
          "",
          false,
        );
      }

      // 칼라 보온덮개
      if (type === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
        this.colorLaggingPart.setDefaultData(
          CONST.INDEX_SG_COVER_LAGGING_COLOR,
          true,
          false,
          true,
          CONST.LB_COVER_LAGGING_COLOR,
          "",
          false,
        );
      } else {
        this.colorLaggingPart.setDefaultData(
          CONST.INDEX_SG_COVER_LAGGING_COLOR,
          false,
          false,
          false,
          CONST.LB_COVER_LAGGING_COLOR,
          "",
          false,
        );
      }

      // 보온덮개
      if (type === CONST.LB_COVER_TYPE_LAGGING) {
        this.laggingPart.setDefaultData(
          CONST.INDEX_SG_COVER_LAGGING,
          true,
          false,
          true,
          CONST.LB_COVER_LAGGING,
          "",
          false,
        );
      } else {
        this.laggingPart.setDefaultData(
          CONST.INDEX_SG_COVER_LAGGING,
          false,
          false,
          false,
          CONST.LB_COVER_LAGGING,
          "",
          false,
        );
      }

      // 다겹 커튼지
      if (type === CONST.LB_COVER_TYPE_QUILTING) {
        this.quiltingPart.setDefaultData(CONST.INDEX_SG_COVER_QUILTING, true, false, true, CONST.LB_COVER_QUILTING, "");
      } else {
        this.quiltingPart.setDefaultData(
          CONST.INDEX_SG_COVER_QUILTING,
          false,
          false,
          false,
          CONST.LB_COVER_QUILTING,
          "",
        );
      }

      // 스크린
      if (type === CONST.LB_COVER_TYPE_SCREEN) {
        this.screenPart.setDefaultData(CONST.INDEX_SG_COVER_SCREEN, true, false, true, CONST.LB_COVER_SCREEN, "");
      } else {
        this.screenPart.setDefaultData(CONST.INDEX_SG_COVER_SCREEN, false, false, false, CONST.LB_COVER_SCREEN, "");
      }

      // 마트
      if (type === CONST.LB_COVER_TYPE_MAT) {
        this.matPart.setDefaultData(CONST.INDEX_SG_COVER_MAT, true, false, true, CONST.LB_COVER_MAT, "");
      } else {
        this.matPart.setDefaultData(CONST.INDEX_SG_COVER_MAT, false, false, false, CONST.LB_COVER_MAT, "");
      }
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.algorithm_scopeSelectedByScopeType();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 10) {
      // 직조 필름
      const weavingFilmPart: CoverPart_WeavingFilm = new CoverPart_WeavingFilm();
      weavingFilmPart.setAssociation(design, struct, work, level, this);
      weavingFilmPart.setDefaultData(
        CONST.INDEX_SG_COVER_WEAVING_FILM,
        false,
        false,
        false,
        CONST.LB_COVER_WEAVING_FILM,
        "",
      );
      this.partAC.splice(1, 0, weavingFilmPart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    // 일단 모두 false
    let part: Part;
    for (part of this.partAC) {
      part.selected = false;
      part.visible = false;
    }
    // 해당 파트만 true
    if (this.type === CONST.LB_COVER_TYPE_VINYL) {
      this.vinylPart.selected = true;
      this.vinylPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_WEAVING_FILM) {
      this.weavingFilmPart.selected = true;
      this.weavingFilmPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_SHADENET) {
      this.shadenetPart.selected = true;
      this.shadenetPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_TENT) {
      this.tentPart.selected = true;
      this.tentPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_NONWOVEN) {
      this.nonwovenPart.selected = true;
      this.nonwovenPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_CASHMILON) {
      this.cashmilonPart.selected = true;
      this.cashmilonPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
      this.colorLaggingPart.selected = true;
      this.colorLaggingPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_LAGGING) {
      this.laggingPart.selected = true;
      this.laggingPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_QUILTING) {
      this.quiltingPart.selected = true;
      this.quiltingPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_SCREEN) {
      this.screenPart.selected = true;
      this.screenPart.visible = true;
    } else if (this.type === CONST.LB_COVER_TYPE_MAT) {
      this.matPart.selected = true;
      this.matPart.visible = true;
    }
  }

  /**
   * 알고리즘: 위치별 범위 선택 <- 범위 형태<br/>
   * 피복의 범위 형태에 따라서 측면, 지붕, 앞면, 뒷면의 선택을 결정해줌
   */
  public algorithm_scopeSelectedByScopeType(): void {
    if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK) {
      this.scopeSelectedSide = true;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = false;
      this.scopeSelectedBack = false;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE) {
      this.scopeSelectedSide = true;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = true;
      this.scopeSelectedBackIncluded = true;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE) {
      this.scopeSelectedSide = true;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF) {
      this.scopeSelectedSide = false;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = false;
      this.scopeSelectedBack = false;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE) {
      this.scopeSelectedSide = false;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = true;
      this.scopeSelectedBackIncluded = true;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE) {
      this.scopeSelectedSide = false;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_SIDE) {
      this.scopeSelectedSide = true;
      this.scopeSelectedRoof = false;
      this.scopeSelectedFront = false;
      this.scopeSelectedBack = false;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_SIDE_SEP_ENDPIECE) {
      this.scopeSelectedSide = true;
      this.scopeSelectedRoof = false;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ENDPIECE) {
      this.scopeSelectedSide = false;
      this.scopeSelectedRoof = false;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
      this.scopeSelectedDoubleCenter = false;
    } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_DOUBLE_SEP_TRUNK_SEP_ENDPIECE) {
      this.scopeSelectedSide = true;
      this.scopeSelectedRoof = true;
      this.scopeSelectedFront = true;
      this.scopeSelectedBack = true;
      this.scopeSelectedFrontIncluded = false;
      this.scopeSelectedBackIncluded = false;
      this.scopeSelectedDoubleCenter = true;
    }
  }

  /**
   * 알고리즘: 개폐 차수 <- 선택, 범위 형태
   */
  public algorithm_switcherOrderValue(): void {
    this.switcherOrderSide = this.selected && this.scopeSelectedSide ? 1 : 0;
    this.switcherOrderRoof = this.selected && this.scopeSelectedRoof ? 1 : 0;
    this.switcherOrderFront = this.selected && this.scopeSelectedFront ? 1 : 0;
    this.switcherOrderBack = this.selected && this.scopeSelectedBack ? 1 : 0;
  }

  /**
   * 알고리즘: 치마 피복 선택<- 선택, 범위 형태, 개폐 차수
   */
  public algorithm_skirtCoverSelected(): void {
    this.skirtCoverSide = this.selected && this.scopeSelectedSide && this.switcherOrderSide > 0;
    this.skirtCoverRoof = this.selected && this.scopeSelectedRoof && this.switcherOrderRoof > 0;
    this.skirtCoverFront = this.selected && this.scopeSelectedFront && this.switcherOrderFront > 0;
    this.skirtCoverBack = this.selected && this.scopeSelectedBack && this.switcherOrderBack > 0;
  }

  /**
   * 알고리즘: 바람막이 피복 선택 <- 선택, 범위 형태, 개폐 차수
   */
  public algorithm_windbreakCoverSelected(): void {
    this.windbreakCoverSide = this.selected && this.scopeSelectedSide && this.switcherOrderSide > 0;
    this.windbreakCoverRoof = this.selected && this.scopeSelectedRoof && this.switcherOrderRoof > 0;
    this.windbreakCoverFront = this.selected && this.scopeSelectedFront && this.switcherOrderFront > 0;
    this.windbreakCoverBack = this.selected && this.scopeSelectedBack && this.switcherOrderBack > 0;
  }

  /**
   * 알고리즘: 칸막이 피복 선택 <- 선택, 범위 형태
   */
  public algorithm_partitionCoverSelected(): void {
    this.partitionCoverTrunk = this.selected && (this.scopeSelectedRoof || this.scopeSelectedFront);
  }

  /**
   * 알고리즘: 천창 피복 선택 <- 선택, 범위 형태, 위치
   */
  public algorithm_skylightCoverSelected(): void {
    this.skylightCover = this.selected && this.scopeSelectedRoof;
  }

  /**
   * 알고리즘: 범위 형태 <- 형태
   */
  public algorithm_scopeTypeByType(): void {
    if (
      this.type === CONST.LB_COVER_TYPE_CASHMILON ||
      this.type === CONST.LB_COVER_TYPE_COLOR_LAGGING ||
      this.type === CONST.LB_COVER_TYPE_LAGGING
    ) {
      if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE;
      } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE;
      }
    } else {
      if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE;
      } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE;
      }
    }
  }

  /**
   * 알고리즘: 범위 형태 <- 선택(천창 골조)
   */
  public algorithm_scopeTypeBySkylight(): void {
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    if (skyFramePosition.selected === true) {
      // 마구리 피복을 분리
      if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_TRUNK_AND_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_TRUNK_SEP_ENDPIECE;
      } else if (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_ROOF_AND_ENDPIECE) {
        this.scopeType = CONST.LB_COVER_SCOPE_TYPE_ROOF_SEP_ENDPIECE;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
