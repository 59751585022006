import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemControllerSensor } from "vhows-design/src/object/design/item/list/ItemControllerSensor";
import { ItemCustomMaterial } from "vhows-design/src/object/design/item/list/ItemCustomMaterial";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ItemIntegratedController } from "vhows-design/src/object/design/item/list/ItemIntegratedController";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ControllerSampleIL_ControllerHeatSensor } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_ControllerHeatSensor";
import { ControllerSampleIL_ControllerInsolationSensor } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_ControllerInsolationSensor";
import { ControllerSampleIL_ControllerRaindropSensor } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_ControllerRaindropSensor";
import { ControllerSampleIL_ControllerTempHumSensor } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_ControllerTempHumSensor";
import { ControllerSampleIL_ControllerTempSensor } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_ControllerTempSensor";
import { ControllerSampleIL_ControllerTimer } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_ControllerTimer";
import { ControllerSampleIL_ControllerWindSensor } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_ControllerWindSensor";
import { ControllerSampleIL_CustomController } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_CustomController";
import { ControllerSampleIL_IncomingElectricWire } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_IncomingElectricWire";
import { ControllerSampleIL_IntegratedController } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_IntegratedController";
import { ControllerSampleIL_MotorControlElectricWire } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_MotorControlElectricWire";
import { ControllerSampleIL_MotorPowerElectricWire } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_MotorPowerElectricWire";
import { ControllerSampleIL_SwitcherElectricWire } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_SwitcherElectricWire";
import { ControllerSampleIL_VentElectricWire } from "vhows-design/src/object/design/switches/controller/il/ControllerSampleIL_VentElectricWire";
import { ControllerPositionIL } from "vhows-design/src/object/design/switches/controller/il/ControllerPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [
    ControllerSampleIL_ControllerHeatSensor,
    ControllerSampleIL_ControllerInsolationSensor,
    ControllerSampleIL_ControllerRaindropSensor,
    ControllerSampleIL_ControllerTempHumSensor,
    ControllerSampleIL_ControllerTempSensor,
    ControllerSampleIL_ControllerTimer,
    ControllerSampleIL_ControllerWindSensor,
    ControllerSampleIL_CustomController,
    ControllerSampleIL_IncomingElectricWire,
    ControllerSampleIL_IntegratedController,
    ControllerSampleIL_MotorControlElectricWire,
    ControllerSampleIL_MotorPowerElectricWire,
    ControllerSampleIL_SwitcherElectricWire,
    ControllerSampleIL_VentElectricWire,
  ],
})
export class ControllerPartIL_Controller extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public integratedControllerSample: ControllerSampleIL_IntegratedController; // 통합 제어기 샘플
  public controllerTimerSample: ControllerSampleIL_ControllerTimer; // 제어기-타이머 샘플
  public controllerTempSensorSample: ControllerSampleIL_ControllerTempSensor; // 제어기-온도센서 샘플
  public controllerHeatSensorSample: ControllerSampleIL_ControllerHeatSensor; // 제어기-고온경보기 샘플
  public controllerTempHumSensorSample: ControllerSampleIL_ControllerTempHumSensor; // 제어기-습도센서 샘플
  public controllerRaindropSensorSample: ControllerSampleIL_ControllerRaindropSensor; // 제어기-우적센서 샘플
  public controllerWindSensorSample: ControllerSampleIL_ControllerWindSensor; // 제어기-풍량센서 샘플
  public controllerInsolationSensorSample: ControllerSampleIL_ControllerInsolationSensor; // 제어기-일사량센서 샘플
  public customControllerSample: ControllerSampleIL_CustomController; // 임의 제어기 샘플
  public incomingElectricWireSample: ControllerSampleIL_IncomingElectricWire; // 인입 전선 샘플
  public switcherElectricWireSample: ControllerSampleIL_SwitcherElectricWire; // 동력 개폐기 전선 샘플
  public motorPowerElectricWireSample: ControllerSampleIL_MotorPowerElectricWire; // 감속 모터 전원공급 전선 샘플
  public motorControlElectricWireSample: ControllerSampleIL_MotorControlElectricWire; // 감속 모터 제어 전선 샘플
  public ventElectricWireSample: ControllerSampleIL_VentElectricWire; // 환기 시설 전선 샘플

  // 아이템
  public controller_integratedController: ItemIntegratedController;
  public controllerSensor_controllerTimer: ItemControllerSensor;
  public controllerSensor_controllerTempSensor: ItemControllerSensor;
  public controllerSensor_controllerHeatSensor: ItemControllerSensor;
  public controllerSensor_controllerTempHumSensor: ItemControllerSensor;
  public controllerSensor_controllerRaindropSensor: ItemControllerSensor;
  public controllerSensor_controllerWindSensor: ItemControllerSensor;
  public controllerSensor_controllerInsolationSensor: ItemControllerSensor;
  public controllerCustom_customController: ItemCustomMaterial;
  public controllerIncoming_electricWire: ItemElectricWire;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _incomingLength: number; // 인입 길이
  @jsonMember(String)
  public _controllerType: string; // 제어 형태
  @jsonMember(Number)
  public _spareSwitchVinyl: number = 0; // 비닐 여분 스위치
  @jsonMember(Number)
  public _spareSwitchNonwoven: number = 0; // 부직 여분 스위치
  @jsonMember(Number)
  public _spareSwitchCurtain: number = 0; // 커튼 여분 스위치

  /**
   * 인입 길이
   */
  public get incomingLength(): number {
    return this._incomingLength;
  }

  //
  public set incomingLength(value: number) {
    this._incomingLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 제어 형태
   */
  public get controllerType(): string {
    return this._controllerType;
  }

  //
  public set controllerType(value: string) {
    this._controllerType = value;

    // 알고리즘
    this.algorithm_sensorsQuantity();
    this.integratedControllerSample.algorithmSpec();
    this.algorithmPart();
  }

  /**
   * 비닐 여분 스위치
   */
  public get spareSwitchVinyl(): number {
    return this._spareSwitchVinyl;
  }

  //
  public set spareSwitchVinyl(value: number) {
    this._spareSwitchVinyl = CommonUtil.fixFloat(value);

    // 알고리즘
    this.integratedControllerSample.algorithmSpec_specVolume();
    this.algorithmSpec_controller_integratedController();
  }

  /**
   * 부직 여분 스위치
   */
  public get spareSwitchNonwoven(): number {
    return this._spareSwitchNonwoven;
  }

  //
  public set spareSwitchNonwoven(value: number) {
    this._spareSwitchNonwoven = CommonUtil.fixFloat(value);

    // 알고리즘
    this.integratedControllerSample.algorithmSpec_specVolume();
    this.algorithmSpec_controller_integratedController();
  }

  /**
   * 커튼 여분 스위치
   */
  public get spareSwitchCurtain(): number {
    return this._spareSwitchCurtain;
  }

  //
  public set spareSwitchCurtain(value: number) {
    this._spareSwitchCurtain = CommonUtil.fixFloat(value);

    // 알고리즘
    this.integratedControllerSample.algorithmSpec_specVolume();
    this.algorithmSpec_controller_integratedController();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.integratedControllerSample = new ControllerSampleIL_IntegratedController();
    this.controllerTimerSample = new ControllerSampleIL_ControllerTimer();
    this.controllerTempSensorSample = new ControllerSampleIL_ControllerTempSensor();
    this.controllerHeatSensorSample = new ControllerSampleIL_ControllerHeatSensor();
    this.controllerTempHumSensorSample = new ControllerSampleIL_ControllerTempHumSensor();
    this.controllerRaindropSensorSample = new ControllerSampleIL_ControllerRaindropSensor();
    this.controllerWindSensorSample = new ControllerSampleIL_ControllerWindSensor();
    this.controllerInsolationSensorSample = new ControllerSampleIL_ControllerInsolationSensor();
    this.customControllerSample = new ControllerSampleIL_CustomController();
    this.incomingElectricWireSample = new ControllerSampleIL_IncomingElectricWire();
    this.switcherElectricWireSample = new ControllerSampleIL_SwitcherElectricWire();
    this.motorPowerElectricWireSample = new ControllerSampleIL_MotorPowerElectricWire();
    this.motorControlElectricWireSample = new ControllerSampleIL_MotorControlElectricWire();
    this.ventElectricWireSample = new ControllerSampleIL_VentElectricWire();

    this.sampleAC = [
      this.integratedControllerSample,
      this.controllerTimerSample,
      this.controllerTempSensorSample,
      this.controllerHeatSensorSample,
      this.controllerTempHumSensorSample,
      this.controllerRaindropSensorSample,
      this.controllerWindSensorSample,
      this.controllerInsolationSensorSample,
      this.customControllerSample,
      this.incomingElectricWireSample,
      this.switcherElectricWireSample,
      this.motorPowerElectricWireSample,
      this.motorControlElectricWireSample,
      this.ventElectricWireSample,
    ];

    // 아이템
    this.controller_integratedController = new ItemIntegratedController();
    this.controllerSensor_controllerTimer = new ItemControllerSensor();
    this.controllerSensor_controllerTempSensor = new ItemControllerSensor();
    this.controllerSensor_controllerHeatSensor = new ItemControllerSensor();
    this.controllerSensor_controllerTempHumSensor = new ItemControllerSensor();
    this.controllerSensor_controllerRaindropSensor = new ItemControllerSensor();
    this.controllerSensor_controllerWindSensor = new ItemControllerSensor();
    this.controllerSensor_controllerInsolationSensor = new ItemControllerSensor();
    this.controllerCustom_customController = new ItemCustomMaterial();
    this.controllerIncoming_electricWire = new ItemElectricWire();

    this.itemAC = [
      this.controller_integratedController,
      this.controllerSensor_controllerTimer,
      this.controllerSensor_controllerTempSensor,
      this.controllerSensor_controllerHeatSensor,
      this.controllerSensor_controllerTempHumSensor,
      this.controllerSensor_controllerRaindropSensor,
      this.controllerSensor_controllerWindSensor,
      this.controllerSensor_controllerInsolationSensor,
      this.controllerCustom_customController,
      this.controllerIncoming_electricWire,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.integratedControllerSample = <ControllerSampleIL_IntegratedController>this.sampleAC[0];
    this.controllerTimerSample = <ControllerSampleIL_ControllerTimer>this.sampleAC[1];
    this.controllerTempSensorSample = <ControllerSampleIL_ControllerTempSensor>this.sampleAC[2];
    this.controllerHeatSensorSample = <ControllerSampleIL_ControllerHeatSensor>this.sampleAC[3];
    this.controllerTempHumSensorSample = <ControllerSampleIL_ControllerTempHumSensor>this.sampleAC[4];
    this.controllerRaindropSensorSample = <ControllerSampleIL_ControllerRaindropSensor>this.sampleAC[5];
    this.controllerWindSensorSample = <ControllerSampleIL_ControllerWindSensor>this.sampleAC[6];
    this.controllerInsolationSensorSample = <ControllerSampleIL_ControllerInsolationSensor>this.sampleAC[7];
    this.customControllerSample = <ControllerSampleIL_CustomController>this.sampleAC[8];
    this.incomingElectricWireSample = <ControllerSampleIL_IncomingElectricWire>this.sampleAC[9];
    this.switcherElectricWireSample = <ControllerSampleIL_SwitcherElectricWire>this.sampleAC[10];
    this.motorPowerElectricWireSample = <ControllerSampleIL_MotorPowerElectricWire>this.sampleAC[11];
    this.motorControlElectricWireSample = <ControllerSampleIL_MotorControlElectricWire>this.sampleAC[12];
    this.ventElectricWireSample = <ControllerSampleIL_VentElectricWire>this.sampleAC[13];

    // 아이템
    this.controller_integratedController = <ItemIntegratedController>this.itemAC[0];
    this.controllerSensor_controllerTimer = <ItemControllerSensor>this.itemAC[1];
    this.controllerSensor_controllerTempSensor = <ItemControllerSensor>this.itemAC[2];
    this.controllerSensor_controllerHeatSensor = <ItemControllerSensor>this.itemAC[3];
    this.controllerSensor_controllerTempHumSensor = <ItemControllerSensor>this.itemAC[4];
    this.controllerSensor_controllerRaindropSensor = <ItemControllerSensor>this.itemAC[5];
    this.controllerSensor_controllerWindSensor = <ItemControllerSensor>this.itemAC[6];
    this.controllerSensor_controllerInsolationSensor = <ItemControllerSensor>this.itemAC[7];
    this.controllerCustom_customController = <ItemCustomMaterial>this.itemAC[8];
    this.controllerIncoming_electricWire = <ItemElectricWire>this.itemAC[9];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param incomingLength: number 인입 길이
   * @param controllerType: string 제어 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    incomingLength: number = 0,
    controllerType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._incomingLength = incomingLength;
    this._controllerType = controllerType;

    // 샘플
    if (this.level.index >= 0) {
      this.integratedControllerSample.setDefaultData(
        0,
        CONST.ITEM_ID_INTEGRATED_CONTROLLER,
        CONST.ITEM_NAME_INTEGRATED_CONTROLLER,
        "",
        "일반, 중, 통합2, 비닐16, 부직0, 커튼0, 환기0, 관수0, 기타AC0",
      );
      this.integratedControllerSample._designQuantity = 1;
      this.controllerTimerSample.setDefaultData(
        1,
        CONST.ITEM_ID_CONTROLLER_TIMER,
        CONST.ITEM_NAME_CONTROLLER_TIMER,
        "제어센서",
        "일반",
      );
      this.controllerTempSensorSample.setDefaultData(
        2,
        CONST.ITEM_ID_CONTROLLER_TEMP_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_TEMP_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerHeatSensorSample.setDefaultData(
        3,
        CONST.ITEM_ID_CONTROLLER_HEAT_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_HEAT_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerTempHumSensorSample.setDefaultData(
        4,
        CONST.ITEM_ID_CONTROLLER_TEMP_HUM_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_TEMP_HUM_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerRaindropSensorSample.setDefaultData(
        5,
        CONST.ITEM_ID_CONTROLLER_RAINDROP_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_RAINDROP_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerWindSensorSample.setDefaultData(
        6,
        CONST.ITEM_ID_CONTROLLER_WIND_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_WIND_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerInsolationSensorSample.setDefaultData(
        7,
        CONST.ITEM_ID_CONTROLLER_INSOLATION_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_INSOLATION_SENSOR,
        "제어센서",
        "일반",
      );
      this.customControllerSample.setDefaultData(8, CONST.ITEM_ID_CUSTOM_CONTROLLER, "임의 제어기", "제어기", "");
      this.customControllerSample._designQuantity = 1;
      this.incomingElectricWireSample.setDefaultData(
        9,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "인입",
        "CV, 6sq, 2C, 50m",
      );
      this.switcherElectricWireSample.setDefaultData(
        10,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "동력 개폐기",
        "VCTF, 1.5sq, 2C, 100m",
      );
      this.motorPowerElectricWireSample.setDefaultData(
        11,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "커튼 감속 모터 전원공급",
        "VCTF, 2.5sq, 3C, 100m",
      );
      this.motorControlElectricWireSample.setDefaultData(
        12,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "커튼 감속 모터 제어",
        "VCTF, 1.5sq, 3C, 100m",
      );
      this.ventElectricWireSample.setDefaultData(
        13,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "환기 시설",
        "VCTF, 2.5sq, 2C, 100m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.controller_integratedController.setDefaultData(
        0,
        CONST.ITEM_ID_INTEGRATED_CONTROLLER,
        CONST.ITEM_NAME_INTEGRATED_CONTROLLER,
        "통합2, 비닐16",
        "일반, 중, 통합2, 비닐16, 부직0, 커튼0, 환기0, 관수0, 기타AC0",
      );
      this.controllerSensor_controllerTimer.setDefaultData(
        1,
        CONST.ITEM_ID_CONTROLLER_TIMER,
        CONST.ITEM_NAME_CONTROLLER_TIMER,
        "제어센서",
        "일반",
      );
      this.controllerSensor_controllerTempSensor.setDefaultData(
        2,
        CONST.ITEM_ID_CONTROLLER_TEMP_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_TEMP_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerSensor_controllerHeatSensor.setDefaultData(
        3,
        CONST.ITEM_ID_CONTROLLER_HEAT_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_HEAT_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerSensor_controllerTempHumSensor.setDefaultData(
        4,
        CONST.ITEM_ID_CONTROLLER_TEMP_HUM_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_TEMP_HUM_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerSensor_controllerRaindropSensor.setDefaultData(
        5,
        CONST.ITEM_ID_CONTROLLER_RAINDROP_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_RAINDROP_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerSensor_controllerWindSensor.setDefaultData(
        6,
        CONST.ITEM_ID_CONTROLLER_WIND_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_WIND_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerSensor_controllerInsolationSensor.setDefaultData(
        7,
        CONST.ITEM_ID_CONTROLLER_INSOLATION_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_INSOLATION_SENSOR,
        "제어센서",
        "일반",
      );
      this.controllerCustom_customController.setDefaultData(
        8,
        CONST.ITEM_ID_CUSTOM_CONTROLLER,
        "임의 제어기",
        "제어기",
        "",
      );
      this.controllerIncoming_electricWire.setDefaultData(
        9,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "인입",
        "CV, 6sq, 2C, 50m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      // 동력 개폐기 전선 샘플 추가
      const switcherElectricWireSample: ControllerSampleIL_SwitcherElectricWire =
        new ControllerSampleIL_SwitcherElectricWire();
      switcherElectricWireSample.setAssociation(design, struct, work, level, position, this);
      switcherElectricWireSample.setDefaultData(
        1,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "동력 개폐기",
        "VCTF, 1.5sq, 2C, 100m",
      );
      this.sampleAC.push(switcherElectricWireSample);
      // 감속 모터 제어 전선 샘플 추가
      const motorElectricWireSample: ControllerSampleIL_MotorControlElectricWire =
        new ControllerSampleIL_MotorControlElectricWire();
      motorElectricWireSample.setAssociation(design, struct, work, level, position, this);
      motorElectricWireSample.setDefaultData(
        2,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "커튼 감속 모터 제어",
        "VCTF, 1.5sq, 3C, 100m",
      );
      this.sampleAC.push(motorElectricWireSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }
    if (this.sampleAC.length === 3) {
      // 인입 전선 샘플 추가
      const incomingElectricWireSample: ControllerSampleIL_IncomingElectricWire =
        new ControllerSampleIL_IncomingElectricWire();
      incomingElectricWireSample.setAssociation(design, struct, work, level, position, this);
      incomingElectricWireSample.setDefaultData(
        1,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "인입",
        "CV, 6sq, 2C, 50m",
      );
      this.sampleAC.splice(1, 0, incomingElectricWireSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }
    if (this.sampleAC.length === 4) {
      // 감속 모터 전원공급 전선 샘플 추가
      const motorPowerElectricWireSample: ControllerSampleIL_MotorPowerElectricWire =
        new ControllerSampleIL_MotorPowerElectricWire();
      motorPowerElectricWireSample.setAssociation(design, struct, work, level, position, this);
      motorPowerElectricWireSample.setDefaultData(
        3,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "커튼 감속 모터 전원공급",
        "VCTF, 2.5sq, 3C, 100m",
      );
      this.sampleAC.splice(3, 0, motorPowerElectricWireSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }
    if (this.sampleAC.length === 5) {
      // 환기 시설 전선 샘플 추가
      const ventElectricWireSample: ControllerSampleIL_VentElectricWire = new ControllerSampleIL_VentElectricWire();
      ventElectricWireSample.setAssociation(design, struct, work, level, position, this);
      ventElectricWireSample.setDefaultData(
        5,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "환기 시설",
        "VCTF, 2.5sq, 2C, 100m",
      );
      this.sampleAC.push(ventElectricWireSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }
    if (this.sampleAC.length === 6) {
      // 통합 제어기 샘플 추가
      const integratedControllerSample: ControllerSampleIL_IntegratedController =
        new ControllerSampleIL_IntegratedController();
      integratedControllerSample.setAssociation(design, struct, work, level, position, this);
      integratedControllerSample.setDefaultData(
        0,
        CONST.ITEM_ID_INTEGRATED_CONTROLLER,
        CONST.ITEM_NAME_INTEGRATED_CONTROLLER,
        "",
        "일반, 중, 통합2, 비닐16, 부직0, 커튼0, 환기0, 관수0, 기타AC0",
      );
      this.sampleAC.splice(0, 0, integratedControllerSample);
      // 제어기-타이머 샘플 추가
      const controllerTimerSample: ControllerSampleIL_ControllerTimer = new ControllerSampleIL_ControllerTimer();
      controllerTimerSample.setAssociation(design, struct, work, level, position, this);
      controllerTimerSample.setDefaultData(
        1,
        CONST.ITEM_ID_CONTROLLER_TIMER,
        CONST.ITEM_NAME_CONTROLLER_TIMER,
        "제어센서",
        "일반",
      );
      this.sampleAC.splice(1, 0, controllerTimerSample);
      // 제어기-온도센서 샘플 추가
      const controllerTempSensorSample: ControllerSampleIL_ControllerTempSensor =
        new ControllerSampleIL_ControllerTempSensor();
      controllerTempSensorSample.setAssociation(design, struct, work, level, position, this);
      controllerTempSensorSample.setDefaultData(
        2,
        CONST.ITEM_ID_CONTROLLER_TEMP_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_TEMP_SENSOR,
        "제어센서",
        "일반",
      );
      this.sampleAC.splice(2, 0, controllerTempSensorSample);
      // 제어기-고온경보기 샘플 추가
      const controllerHeatSensorSample: ControllerSampleIL_ControllerHeatSensor =
        new ControllerSampleIL_ControllerHeatSensor();
      controllerHeatSensorSample.setAssociation(design, struct, work, level, position, this);
      controllerHeatSensorSample.setDefaultData(
        3,
        CONST.ITEM_ID_CONTROLLER_HEAT_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_HEAT_SENSOR,
        "제어센서",
        "일반",
      );
      this.sampleAC.splice(3, 0, controllerHeatSensorSample);
      // 제어기-습도센서 샘플 추가
      const controllerTempHumSensorSample: ControllerSampleIL_ControllerTempHumSensor =
        new ControllerSampleIL_ControllerTempHumSensor();
      controllerTempHumSensorSample.setAssociation(design, struct, work, level, position, this);
      controllerTempHumSensorSample.setDefaultData(
        4,
        CONST.ITEM_ID_CONTROLLER_TEMP_HUM_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_TEMP_HUM_SENSOR,
        "제어센서",
        "일반",
      );
      this.sampleAC.splice(4, 0, controllerTempHumSensorSample);
      // 제어기-우적센서 샘플 추가
      const controllerRaindropSensorSample: ControllerSampleIL_ControllerRaindropSensor =
        new ControllerSampleIL_ControllerRaindropSensor();
      controllerRaindropSensorSample.setAssociation(design, struct, work, level, position, this);
      controllerRaindropSensorSample.setDefaultData(
        5,
        CONST.ITEM_ID_CONTROLLER_RAINDROP_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_RAINDROP_SENSOR,
        "제어센서",
        "일반",
      );
      this.sampleAC.splice(5, 0, controllerRaindropSensorSample);
      // 제어기-풍량센서 샘플 추가
      const controllerWindSensorSample: ControllerSampleIL_ControllerWindSensor =
        new ControllerSampleIL_ControllerWindSensor();
      controllerWindSensorSample.setAssociation(design, struct, work, level, position, this);
      controllerWindSensorSample.setDefaultData(
        6,
        CONST.ITEM_ID_CONTROLLER_WIND_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_WIND_SENSOR,
        "제어센서",
        "일반",
      );
      this.sampleAC.splice(6, 0, controllerWindSensorSample);
      // 제어기-일사량센서 샘플 추가
      const controllerInsolationSensorSample: ControllerSampleIL_ControllerInsolationSensor =
        new ControllerSampleIL_ControllerInsolationSensor();
      controllerInsolationSensorSample.setAssociation(design, struct, work, level, position, this);
      controllerInsolationSensorSample.setDefaultData(
        7,
        CONST.ITEM_ID_CONTROLLER_INSOLATION_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_INSOLATION_SENSOR,
        "제어센서",
        "일반",
      );
      this.sampleAC.splice(7, 0, controllerInsolationSensorSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    // 아이템
    if (this.itemAC.length === 1) {
      // [인입] 전선 추가
      const controllerIncoming_electricWire: ItemElectricWire = new ItemElectricWire();
      controllerIncoming_electricWire.setAssociation(design, struct, work, level, position, this);
      controllerIncoming_electricWire.setDefaultData(
        1,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "인입",
        "CV, 6sq, 2C, 50m",
      );
      controllerIncoming_electricWire.selected = true;
      controllerIncoming_electricWire.visible = true;
      this.itemAC.push(controllerIncoming_electricWire);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 2) {
      // [] 통합 제어기
      const controller_integratedController: ItemIntegratedController = new ItemIntegratedController();
      controller_integratedController.setAssociation(design, struct, work, level, position, this);
      controller_integratedController.setDefaultData(
        0,
        CONST.ITEM_ID_INTEGRATED_CONTROLLER,
        CONST.ITEM_NAME_INTEGRATED_CONTROLLER,
        "통합2, 비닐16",
        "일반, 중, 통합2, 비닐16, 부직0, 커튼0, 환기0, 관수0, 기타AC0",
      );
      controller_integratedController.selected = false;
      controller_integratedController.visible = false;
      this.itemAC.splice(0, 0, controller_integratedController);
      // [제어센서] 제어기-타이머
      const controllerSensor_controllerTimer: ItemControllerSensor = new ItemControllerSensor();
      controllerSensor_controllerTimer.setAssociation(design, struct, work, level, position, this);
      controllerSensor_controllerTimer.setDefaultData(
        1,
        CONST.ITEM_ID_CONTROLLER_TIMER,
        CONST.ITEM_NAME_CONTROLLER_TIMER,
        "제어센서",
        "일반",
      );
      controllerSensor_controllerTimer.selected = false;
      controllerSensor_controllerTimer.visible = false;
      this.itemAC.splice(1, 0, controllerSensor_controllerTimer);
      // [제어센서] 제어기-온도센서
      const controllerSensor_controllerTempSensor: ItemControllerSensor = new ItemControllerSensor();
      controllerSensor_controllerTempSensor.setAssociation(design, struct, work, level, position, this);
      controllerSensor_controllerTempSensor.setDefaultData(
        2,
        CONST.ITEM_ID_CONTROLLER_TEMP_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_TEMP_SENSOR,
        "제어센서",
        "일반",
      );
      controllerSensor_controllerTempSensor.selected = false;
      controllerSensor_controllerTempSensor.visible = false;
      this.itemAC.splice(2, 0, controllerSensor_controllerTempSensor);
      // [제어센서] 제어기-고온경보기
      const controllerSensor_controllerHeatSensor: ItemControllerSensor = new ItemControllerSensor();
      controllerSensor_controllerHeatSensor.setAssociation(design, struct, work, level, position, this);
      controllerSensor_controllerHeatSensor.setDefaultData(
        3,
        CONST.ITEM_ID_CONTROLLER_HEAT_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_HEAT_SENSOR,
        "제어센서",
        "일반",
      );
      controllerSensor_controllerHeatSensor.selected = false;
      controllerSensor_controllerHeatSensor.visible = false;
      this.itemAC.splice(3, 0, controllerSensor_controllerHeatSensor);
      // [제어센서] 제어기-습도센서
      const controllerSensor_controllerTempHumSensor: ItemControllerSensor = new ItemControllerSensor();
      controllerSensor_controllerTempHumSensor.setAssociation(design, struct, work, level, position, this);
      controllerSensor_controllerTempHumSensor.setDefaultData(
        4,
        CONST.ITEM_ID_CONTROLLER_TEMP_HUM_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_TEMP_HUM_SENSOR,
        "제어센서",
        "일반",
      );
      controllerSensor_controllerTempHumSensor.selected = false;
      controllerSensor_controllerTempHumSensor.visible = false;
      this.itemAC.splice(4, 0, controllerSensor_controllerTempHumSensor);
      // [제어센서] 제어기-우적센서
      const controllerSensor_controllerRaindropSensor: ItemControllerSensor = new ItemControllerSensor();
      controllerSensor_controllerRaindropSensor.setAssociation(design, struct, work, level, position, this);
      controllerSensor_controllerRaindropSensor.setDefaultData(
        5,
        CONST.ITEM_ID_CONTROLLER_RAINDROP_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_RAINDROP_SENSOR,
        "제어센서",
        "일반",
      );
      controllerSensor_controllerRaindropSensor.selected = false;
      controllerSensor_controllerRaindropSensor.visible = false;
      this.itemAC.splice(5, 0, controllerSensor_controllerRaindropSensor);
      // [제어센서] 제어기-풍량센서
      const controllerSensor_controllerWindSensor: ItemControllerSensor = new ItemControllerSensor();
      controllerSensor_controllerWindSensor.setAssociation(design, struct, work, level, position, this);
      controllerSensor_controllerWindSensor.setDefaultData(
        6,
        CONST.ITEM_ID_CONTROLLER_WIND_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_WIND_SENSOR,
        "제어센서",
        "일반",
      );
      controllerSensor_controllerWindSensor.selected = false;
      controllerSensor_controllerWindSensor.visible = false;
      this.itemAC.splice(6, 0, controllerSensor_controllerWindSensor);
      // [제어센서] 제어기-일사량센서
      const controllerSensor_controllerInsolationSensor: ItemControllerSensor = new ItemControllerSensor();
      controllerSensor_controllerInsolationSensor.setAssociation(design, struct, work, level, position, this);
      controllerSensor_controllerInsolationSensor.setDefaultData(
        7,
        CONST.ITEM_ID_CONTROLLER_INSOLATION_SENSOR,
        CONST.ITEM_NAME_CONTROLLER_INSOLATION_SENSOR,
        "제어센서",
        "일반",
      );
      controllerSensor_controllerInsolationSensor.selected = false;
      controllerSensor_controllerInsolationSensor.visible = false;
      this.itemAC.splice(7, 0, controllerSensor_controllerInsolationSensor);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const controlloerPosition: ControllerPositionIL = <ControllerPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    if (controlloerPosition.type === CONST.LB_CONTROLLER_TYPE_INTEGRATED) {
      this.controller_integratedController.selected = true;
      this.controller_integratedController.visible = true;
      this.controllerSensor_controllerTimer.selected = true;
      this.controllerSensor_controllerTimer.visible = true;
      this.controllerSensor_controllerTempSensor.selected = true;
      this.controllerSensor_controllerTempSensor.visible = true;
      this.controllerSensor_controllerHeatSensor.selected = true;
      this.controllerSensor_controllerHeatSensor.visible = true;
      this.controllerSensor_controllerTempHumSensor.selected = true;
      this.controllerSensor_controllerTempHumSensor.visible = true;
      this.controllerSensor_controllerRaindropSensor.selected = true;
      this.controllerSensor_controllerRaindropSensor.visible = true;
      this.controllerSensor_controllerWindSensor.selected = true;
      this.controllerSensor_controllerWindSensor.visible = true;
      this.controllerSensor_controllerInsolationSensor.selected = true;
      this.controllerSensor_controllerInsolationSensor.visible = true;
      this.controllerCustom_customController.selected = false;
      this.controllerCustom_customController.visible = false;
    } else if (controlloerPosition.type === CONST.LB_CONTROLLER_TYPE_CUSTOM) {
      this.controller_integratedController.selected = false;
      this.controller_integratedController.visible = false;
      this.controllerSensor_controllerTimer.selected = false;
      this.controllerSensor_controllerTimer.visible = false;
      this.controllerSensor_controllerTempSensor.selected = false;
      this.controllerSensor_controllerTempSensor.visible = false;
      this.controllerSensor_controllerHeatSensor.selected = false;
      this.controllerSensor_controllerHeatSensor.visible = false;
      this.controllerSensor_controllerTempHumSensor.selected = false;
      this.controllerSensor_controllerTempHumSensor.visible = false;
      this.controllerSensor_controllerRaindropSensor.selected = false;
      this.controllerSensor_controllerRaindropSensor.visible = false;
      this.controllerSensor_controllerWindSensor.selected = false;
      this.controllerSensor_controllerWindSensor.visible = false;
      this.controllerSensor_controllerInsolationSensor.selected = false;
      this.controllerSensor_controllerInsolationSensor.visible = false;
      this.controllerCustom_customController.selected = true;
      this.controllerCustom_customController.visible = true;
    }
    this.controllerIncoming_electricWire.selected = true;
    this.controllerIncoming_electricWire.visible = true;

    /// ////////  수량 ///////////

    this.controller_integratedController.designQuantity = this.integratedControllerSample.designQuantity;

    this.controllerSensor_controllerTimer.designQuantity = this.controllerTimerSample.designQuantity;

    this.controllerSensor_controllerTempSensor.designQuantity = this.controllerTempSensorSample.designQuantity;

    this.controllerSensor_controllerHeatSensor.designQuantity = this.controllerHeatSensorSample.designQuantity;

    this.controllerSensor_controllerTempHumSensor.designQuantity = this.controllerTempHumSensorSample.designQuantity;

    this.controllerSensor_controllerRaindropSensor.designQuantity = this.controllerRaindropSensorSample.designQuantity;

    this.controllerSensor_controllerWindSensor.designQuantity = this.controllerWindSensorSample.designQuantity;

    this.controllerSensor_controllerInsolationSensor.designQuantity =
      this.controllerInsolationSensorSample.designQuantity;

    this.controllerCustom_customController.designQuantity = this.customControllerSample.designQuantity;

    this.controllerIncoming_electricWire.designQuantity =
      this.incomingLength / this.controllerIncoming_electricWire.specLength;
  }

  /**
   * 알고리즘: 각종 센서류 수량 <- 제어 형태, 통합 제어기 샘플
   */
  public algorithm_sensorsQuantity(): void {
    if (this.controllerType === CONST.LB_CONTROLLER_CONTROLL_TYPE_MANUAL) {
      this.controllerTimerSample.designQuantity = 0;
      this.controllerTempSensorSample.designQuantity = 0;
      this.controllerHeatSensorSample.designQuantity = 0;
      this.controllerTempHumSensorSample.designQuantity = 0;
      this.controllerRaindropSensorSample.designQuantity = 0;
      this.controllerWindSensorSample.designQuantity = 0;
      this.controllerInsolationSensorSample.designQuantity = 0;
    } else if (this.controllerType === CONST.LB_CONTROLLER_CONTROLL_TYPE_SEMI_AUTO) {
      this.controllerTimerSample.designQuantity = this.integratedControllerSample.specCombineSwt;
      this.controllerTempSensorSample.designQuantity = 0;
      this.controllerHeatSensorSample.designQuantity = 0;
      this.controllerTempHumSensorSample.designQuantity = 0;
      this.controllerRaindropSensorSample.designQuantity = 0;
      this.controllerWindSensorSample.designQuantity = 0;
      this.controllerInsolationSensorSample.designQuantity = 0;
    } else if (this.controllerType === CONST.LB_CONTROLLER_CONTROLL_TYPE_AUTO) {
      this.controllerTimerSample.designQuantity = this.integratedControllerSample.specCombineSwt;
      this.controllerTempSensorSample.designQuantity = 1;
      this.controllerHeatSensorSample.designQuantity = 1;
      this.controllerTempHumSensorSample.designQuantity = 0;
      this.controllerRaindropSensorSample.designQuantity = 1;
      this.controllerWindSensorSample.designQuantity = 0;
      this.controllerInsolationSensorSample.designQuantity = 0;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 통합 제어기 <- 통합 제어기 샘플, 비닐 여분 스위치, 부직 여분 스위치, 커튼 여분 스위치
   */
  public algorithmSpec_controller_integratedController(): void {
    this.controller_integratedController.specsList = this.integratedControllerSample.specsList;
    this.controller_integratedController.specCombineSwt = this.integratedControllerSample.specCombineSwt;
    this.controller_integratedController.specVinylSwt =
      this.integratedControllerSample.specVinylSwt + this.spareSwitchVinyl;
    this.controller_integratedController.specNonwovenSwt =
      this.integratedControllerSample.specNonwovenSwt + this.spareSwitchNonwoven;
    this.controller_integratedController.specCurtainSwt =
      this.integratedControllerSample.specCurtainSwt + this.spareSwitchCurtain;
    this.controller_integratedController.specVentSwt = this.integratedControllerSample.specVentSwt;
    this.controller_integratedController.specWateringSwt = this.integratedControllerSample.specWateringSwt;
    this.controller_integratedController.specOtherAcSwt = this.integratedControllerSample.specOtherAcSwt;

    // 용도
    let purpose: string = "";
    if (this.integratedControllerSample.specCombineSwt > 0) {
      purpose += this.integratedControllerSample.specCombineSwtLabel;
      purpose += CONST.DELIMITER_SPEC;
    }
    if (this.integratedControllerSample.specVinylSwt > 0 || this.spareSwitchVinyl > 0) {
      purpose += this.integratedControllerSample.specVinylSwtLabel;
      if (this.spareSwitchVinyl > 0) {
        purpose += `+${this.spareSwitchVinyl}`;
      }
      purpose += CONST.DELIMITER_SPEC;
    }
    if (this.integratedControllerSample.specNonwovenSwt > 0 || this.spareSwitchNonwoven > 0) {
      purpose += this.integratedControllerSample.specNonwovenSwtLabel;
      if (this.spareSwitchNonwoven > 0) {
        purpose += `+${this.spareSwitchNonwoven}`;
      }
      purpose += CONST.DELIMITER_SPEC;
    }
    if (this.integratedControllerSample.specCurtainSwt > 0 || this.spareSwitchCurtain > 0) {
      purpose += this.integratedControllerSample.specCurtainSwtLabel;
      if (this.spareSwitchCurtain > 0) {
        purpose += `+${this.spareSwitchCurtain}`;
      }
      purpose += CONST.DELIMITER_SPEC;
    }
    if (this.integratedControllerSample.specVentSwt > 0) {
      purpose += this.integratedControllerSample.specVentSwtLabel;
      purpose += CONST.DELIMITER_SPEC;
    }
    if (this.integratedControllerSample.specWateringSwt > 0) {
      purpose += this.integratedControllerSample.specWateringSwtLabel;
      purpose += CONST.DELIMITER_SPEC;
    }
    if (this.integratedControllerSample.specOtherAcSwt > 0) {
      purpose += this.integratedControllerSample.specOtherAcSwtLabel;
      purpose += CONST.DELIMITER_SPEC;
    }
    if (this.integratedControllerSample.purpose !== "") {
      purpose += this.integratedControllerSample.purpose;
      purpose += CONST.DELIMITER_SPEC;
    }
    const length: number = CONST.DELIMITER_SPEC.length;
    if (purpose.length > length) {
      purpose = purpose.substr(0, purpose.length - length);
    }
    this.controller_integratedController.purpose = purpose;
  }

  /**
   * 규격 알고리즘: [제어센서] 제어기-타이머 <- 제어기-타이머 샘플
   */
  public algorithmSpec_controllerSensor_controllerTimer(): void {
    this.controllerSensor_controllerTimer.specs = this.controllerTimerSample.specs;
  }

  /**
   * 규격 알고리즘: [제어센서] 제어기-온도센서 <- 제어기-온도센서 샘플
   */
  public algorithmSpec_controllerSensor_controllerTempSensor(): void {
    this.controllerSensor_controllerTempSensor.specs = this.controllerTempSensorSample.specs;
  }

  /**
   * 규격 알고리즘: [제어센서] 제어기-고온경보기 <- 제어기-고온경보기 샘플
   */
  public algorithmSpec_controllerSensor_controllerHeatSensor(): void {
    this.controllerSensor_controllerHeatSensor.specs = this.controllerHeatSensorSample.specs;
  }

  /**
   * 규격 알고리즘: [제어센서] 제어기-습도센서 <- 제어기-습도센서 샘플
   */
  public algorithmSpec_controllerSensor_controllerTempHumSensor(): void {
    this.controllerSensor_controllerTempHumSensor.specs = this.controllerTempHumSensorSample.specs;
  }

  /**
   * 규격 알고리즘: [제어센서] 제어기-우적센서 <- 제어기-우적센서 샘플
   */
  public algorithmSpec_controllerSensor_controllerRaindropSensor(): void {
    this.controllerSensor_controllerRaindropSensor.specs = this.controllerRaindropSensorSample.specs;
  }

  /**
   * 규격 알고리즘: [제어센서] 제어기-풍량센서 <- 제어기-풍량센서 샘플
   */
  public algorithmSpec_controllerSensor_controllerWindSensor(): void {
    this.controllerSensor_controllerWindSensor.specs = this.controllerWindSensorSample.specs;
  }

  /**
   * 규격 알고리즘: [제어센서] 제어기-일사량센서 <- 제어기-일사량센서 샘플
   */
  public algorithmSpec_controllerSensor_controllerInsolationSensor(): void {
    this.controllerSensor_controllerInsolationSensor.specs = this.controllerInsolationSensorSample.specs;
  }

  /**
   * 규격 알고리즘: [제어기] 임의 제어기 <- 임의 제어기 샘플
   */
  public algorithmSpec_controllerCustom_customController(): void {
    // (추후) 임의 규격에 개폐기 용도와 수량 등을 자동으로 입력해주기
    this.controllerCustom_customController.specs = this.customControllerSample.specs;
    this.controllerCustom_customController.customUnitSupply = this.customControllerSample.customUnitSupply;
  }

  /**
   * 규격 알고리즘: [인입] 전선 <- 인입 전선 샘플
   */
  public algorithmSpec_controllerIncoming_electricWire(): void {
    this.controllerIncoming_electricWire.specs = this.incomingElectricWireSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
