import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 전선
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-01
 */
@jsonObject
export class ItemElectricWire extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [재질, 면적, 내선, (길이)]
  public _specMaterial: string = undefined; // 재질
  public _specArea: string = undefined; // 면적
  public _specInternalLine: string = undefined; // 내선
  public _specLength: number = undefined; // 길이

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 재질
   */
  public get specMaterial(): string {
    return this._specMaterial;
  }
  //
  public set specMaterial(value: string) {
    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 면적
   */
  public get specArea(): string {
    return this._specArea;
  }
  //
  public set specArea(value: string) {
    this._specArea = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 내선
   */
  public get specInternalLine(): string {
    return this._specInternalLine;
  }
  //
  public set specInternalLine(value: string) {
    this._specInternalLine = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    this._specLength = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_METER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.electricWireCategoryModel;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getEstimateQuantityRaw(designQuantity: number): number {
    return CommonUtil.roundUp(designQuantity / this.specLength, 3);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specMaterial = specsArray[0];
    this.specArea = specsArray[1];
    this.specInternalLine = specsArray[2];
    this.specLengthLabel = specsArray[3];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specMaterial = specsArray[0];
    this.specArea = specsArray[1];
    this.specInternalLine = specsArray[2];
    this.specLengthLabel = specsArray[3];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specMaterial +
      CONST.DELIMITER_SPEC +
      this._specArea +
      CONST.DELIMITER_SPEC +
      this._specInternalLine +
      CONST.DELIMITER_SPEC +
      this.specLengthLabel;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }
}
