import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { SkirtPartIL_FixingNormalClip } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingNormalClip";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-04
 */
@jsonObject
export class SkirtSampleIL_NormalClip extends ItemClip {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingNormalClipPart: SkirtPartIL_FixingNormalClip = <SkirtPartIL_FixingNormalClip>this.part;
    fixingNormalClipPart.algorithmSpec_normalClip_normalClip();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 측면 가로대 파이프(기둥), 방풍벽 가로대 파이프(방풍벽), 가로대 파이프(지붕), 형태(마구리), 마구리 중방 파이프(마구리)
   */
  // @override
  public algorithmSpec(): void {
    let endpiecePosition: EndpiecePositionIL | EndpiecePositionVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      // 연동형인 경우
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        this.specCrossSize =
          this.struct.columnWorkIL.level1.columnPosition.sideStraightPart.sideStraightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        this.specCrossSize = (<WingLevelIL>(
          this.struct.wingWorkIL.levelAC[this.level.index]
        )).wingPosition.wingStraightPart.wingStraightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.specCrossSize = (<RoofLevelIL>(
          this.struct.roofWorkIL.levelAC[this.level.index]
        )).roofPosition.straightPart.straightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        endpiecePosition = (<EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index]).frontPosition;

        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpiecePosition.cylinderMiddlePart.middleBottomPipeSample.specCrossSize;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          this.specCrossSize = endpiecePosition.cylinderMiddlePart.middleBottomPipeSample.specCrossSize;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.specCrossSize = endpiecePosition.prismMiddlePart.middleBottomPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        endpiecePosition = (<EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index]).backPosition;

        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpiecePosition.cylinderMiddlePart.middleBottomPipeSample.specCrossSize;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          this.specCrossSize = endpiecePosition.cylinderMiddlePart.middleBottomPipeSample.specCrossSize;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.specCrossSize = endpiecePosition.prismMiddlePart.middleBottomPipeSample.specCrossSize;
        }
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 벤로형인 경우
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        this.specCrossSize =
          this.struct.columnWorkVL.level1.columnPosition.sideStraightPart.sideStraightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        this.specCrossSize = (<WingLevelIL>(
          this.struct.wingWorkVL.levelAC[this.level.index]
        )).wingPosition.wingStraightPart.wingStraightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.specCrossSize = this.struct.roofWorkVL.level1.roofPosition.straightPart.straightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        endpiecePosition = (<EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index]).frontPosition;
        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpiecePosition.endpiecePart.straightBottomPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        endpiecePosition = (<EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index]).backPosition;
        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpiecePosition.endpiecePart.straightBottomPipeSample.specCrossSize;
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
