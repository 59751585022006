import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { BandstringPart_FixingHook } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_FixingHook";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-06
 */
@jsonObject
export class BandstringSample_HookHolder extends ItemHolder {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingHookPart: BandstringPart_FixingHook = <BandstringPart_FixingHook>this.part;
    fixingHookPart.algorithmSpec_hook_hookHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 서까래 파이프 샘플(지붕 골조)
   */
  // @override
  public algorithmSpec(): void {
    if (this.specPipeType === CONST.ITEM_SPEC_VALUE_CIRCLE_TYPE) {
      this.specCrossSize1 = this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
