import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { SkirtPartIL_FixingSkirtClip } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingSkirtClip";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-04
 */
@jsonObject
export class SkirtSampleIL_SkirtClip extends ItemClip {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingSkirtClipPart: SkirtPartIL_FixingSkirtClip = <SkirtPartIL_FixingSkirtClip>this.part;
    fixingSkirtClipPart.algorithmSpec_skirtClip_skirtClip();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 형태(기둥), 기둥 파이프(기둥), 방풍벽 서까래 파이프(방풍벽), 서까래 파이프(지붕), 형태(마구리), 마구리 샛기둥 파이프(마구리)
   */
  // @override
  public algorithmSpec(): void {
    let endpiecePosition: EndpiecePositionIL | EndpiecePositionVL;

    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      // 연동형인 경우
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
          this.specCrossSize = columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
        } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.specCrossSize = columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        this.specCrossSize = (<WingLevelIL>(
          this.struct.wingWorkIL.levelAC[this.level.index]
        )).wingPosition.wingRafterPart.wingRafterPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.specCrossSize = (<RoofLevelIL>(
          this.struct.roofWorkIL.levelAC[this.level.index]
        )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        endpiecePosition = (<EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index]).frontPosition;

        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpiecePosition.cylinderStudPart.studPipeSample.specCrossSize;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          this.specCrossSize = endpiecePosition.cylinderStudSepPart.studBottomPipeSample.specCrossSize;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.specCrossSize = endpiecePosition.prismStudSepPart.studBottomPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        endpiecePosition = (<EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index]).backPosition;

        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpiecePosition.cylinderStudPart.studPipeSample.specCrossSize;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          this.specCrossSize = endpiecePosition.cylinderStudSepPart.studBottomPipeSample.specCrossSize;
        } else if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.specCrossSize = endpiecePosition.prismStudSepPart.studBottomPipeSample.specCrossSize;
        }
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 벤로형인 경우
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.specCrossSize = columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        this.specCrossSize = (<WingLevelVL>(
          this.struct.wingWorkVL.levelAC[this.level.index]
        )).wingPosition.wingRafterPart.wingRafterPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.specCrossSize = (<RoofLevelVL>(
          this.struct.roofWorkVL.levelAC[this.level.index]
        )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        endpiecePosition = (<EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index]).frontPosition;
        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpiecePosition.endpiecePart.barPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        endpiecePosition = (<EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index]).backPosition;
        if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpiecePosition.endpiecePart.barPipeSample.specCrossSize;
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
