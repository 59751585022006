import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCustomLaborCost } from "vhows-design/src/object/design/item/list/ItemCustomLaborCost";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { BuildingPart_Custom } from "vhows-design/src/object/design/building/BuildingPart_Custom";
import { BuildingPart_PerArea } from "vhows-design/src/object/design/building/BuildingPart_PerArea";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { GutterLevel } from "vhows-design/src/object/design/frame/gutter/GutterLevel";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { RoofLevelVL } from "../frame/roof/vl/RoofLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-08-10
 */
@jsonObject({
  knownTypes: [BuildingPart_Custom, BuildingPart_PerArea],
})
export class BuildingPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public customPart: BuildingPart_Custom;
  public perAreaPart: BuildingPart_PerArea;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 공사 시공비 배열 */
  public constructionBuildingCosts: any[] = [];

  /** 전체 예상 시공비 */
  public get preBuildingCost(): number {
    let totalCost: number = 0;
    this.constructionBuildingCosts.forEach(buildingCost => {
      totalCost += buildingCost.preCost;
    });
    return totalCost;
  }

  /** 큰 공사별 예상 시공비 */
  // public preBigBuildingCost(type: string): number {
  //   let totalCost: number = 0;
  //   this.constructionBuildingCosts.forEach(buildingCost => {
  //     if (buildingCost.constructionType === type) {
  //       totalCost += buildingCost.preCost;
  //     }
  //   });
  //   return totalCost;
  // }
  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.customPart = new BuildingPart_Custom();
    this.perAreaPart = new BuildingPart_PerArea();

    this.partAC = [this.customPart, this.perAreaPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.customPart = <BuildingPart_Custom>this.partAC[0];
    this.perAreaPart = <BuildingPart_PerArea>this.partAC[1];
    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    if (this.level.index >= 0) {
      // 임의 비용
      this.customPart.setDefaultData(CONST.INDEX_CM_BUILDING_CUSTOM, true, false, true, CONST.LB_BUILDING_CUSTOM, "");

      // 면적당 비용
      this.perAreaPart.setDefaultData(
        CONST.INDEX_CM_BUILDING_PER_AREA,
        false,
        false,
        false,
        CONST.LB_BUILDING_PER_AREA,
        "",
        CONST.LB_BUILDING_AREA_TYPE_SQUARE_METER,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 1) {
      // 면적당 비용
      const perAreaPart: BuildingPart_PerArea = new BuildingPart_PerArea();
      perAreaPart.setAssociation(design, struct, work, level, this);
      perAreaPart.setDefaultData(
        CONST.INDEX_CM_BUILDING_PER_AREA,
        false,
        false,
        false,
        CONST.LB_BUILDING_PER_AREA,
        "",
        CONST.LB_BUILDING_AREA_TYPE_SQUARE_METER,
      );
      this.partAC.push(perAreaPart);
    }
    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_selected();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_BUILDING_TYPE_CUSTOM) {
      this.customPart.selected = true;
      this.customPart.visible = true;
      this.perAreaPart.selected = false;
      this.perAreaPart.visible = false;
    } else if (this.type === CONST.LB_BUILDING_TYPE_PER_AREA) {
      this.customPart.selected = false;
      this.customPart.visible = false;
      this.perAreaPart.selected = true;
      this.perAreaPart.visible = true;
    }
  }

  /**
   * 알고리즘: 선택 <- 시공 여부(기본정보)
   */
  public algorithm_selected(): void {
    this.selected = this.basic.buildingSelected;
  }

  /**
   * 알고리즘: 예상 인건비 <- 면적.. 및 설계 전반
   */
  public algorithm_predictLaborCost(): void {
    this.constructionBuildingCosts = new Array();
    // 단위
    const pyeong: number = this.basic.pyeong; // 면적(평)
    const building: number = this.basic.buildingNumber; // 동
    const cultivationPyeong: number = Math.ceil(
      this.struct.hydSystemWork.level1.wholePosition.cultivationArea * 0.3025,
    ); // 재배부평
    const rackFloorPyeong: number = this.struct.rackWork.level1.wholePosition.floorTotalArea * 0.3025; // 선반바닥평당
    const totalRailLength: number = this.struct.railWork.level1.wholePosition.totalRailLength; // 레일길이

    // 증가율(23.07 기준)
    const rateOfIncrease: number = 1.4;

    // 단동
    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      // 골조 공사 > 기초 골조 > 서까래 파이프
      let preFrameCostTemp: number = 0;
      if (this.struct.trunkWork.selected) {
        for (const trunkLevel of this.struct.trunkWork.levelAC) {
          const rafterCrossSize: string = (trunkLevel as TrunkLevel).trunkPosition.rafterCrossSize;
          const rafterLength: number = (trunkLevel as TrunkLevel).trunkPosition.rafterLength;
          if (trunkLevel.selected) {
            if (trunkLevel.index === 0) {
              if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_22_MM && rafterLength >= 0) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_22MM_0M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_25_MM && rafterLength >= 0) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_25MM_0M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_32_MM && rafterLength >= 0) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_32MM_0M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_42_MM && rafterLength <= 16) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_42MM_16M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_42_MM && rafterLength > 17) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_42MM_17M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_48_MM && rafterLength <= 16) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_48MM_16M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_48_MM && rafterLength > 17) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL1_RAFTER_48MM_17M;
              }
            } else {
              if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_22_MM && rafterLength >= 0) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_22MM_0M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_25_MM && rafterLength >= 0) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_25MM_0M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_32_MM && rafterLength >= 0) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_32MM_0M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_42_MM && rafterLength <= 16) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_42MM_16M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_42_MM && rafterLength > 17) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_42MM_17M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_48_MM && rafterLength <= 16) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_48MM_16M;
              } else if (rafterCrossSize === CONST.ITEM_SPEC_VALUE_48_MM && rafterLength > 17) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_LEVEL2_RAFTER_48MM_17M;
              }
            }
          }
        }
        // 골조 공사 > 기초 골조 > 내재해조리개
        for (const trunkLevel of this.struct.trunkWork.levelAC) {
          if (trunkLevel.selected && (trunkLevel as TrunkLevel).trunkPosition.straightPart.enhancedGrabberSelected) {
            preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_TRUNK_ENHANCED_GRABBER_SELECT;
          }
        }
      }
      // 골조 공사 > 물받이
      if (this.struct.gutterWork.selected) {
        for (const gutterLevel of this.struct.gutterWork.levelAC) {
          const totalGutterLength = (gutterLevel as GutterLevel).gutterPosition.totalGutterLength;
          if (gutterLevel.selected) {
            preFrameCostTemp += totalGutterLength * CONST.LB_BUILDING_COST_SG_GUTTER_TOTAL_LENGTH;
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.trunkWork.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_FRAME_CONSTRUCTION,
          preCost: preFrameCostTemp,
          developing: false,
        });
      }

      // 개폐 공사 > 개폐기
      let preSwitchCostTemp: number = 0;
      if (this.struct.switcherWork.selected) {
        for (const switcherLevel of this.struct.switcherWork.levelAC) {
          if (switcherLevel.selected) {
            if (switcherLevel.index === 0) {
              for (const switcherPosition of switcherLevel.positionAC) {
                const switchType: string = (switcherPosition as SwitcherPosition).type;
                if (switcherPosition.selected) {
                  if (switchType === CONST.LB_SWITCHER_TYPE_MANUAL) {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_LEVEL1_SWITCH_TYPE_MANUAL;
                  } else {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_LEVEL1_SWITCH_TYPE_POWER;
                  }
                }
              }
            } else {
              for (const switcherPosition of switcherLevel.positionAC) {
                const switchType: string = (switcherPosition as SwitcherPosition).type;
                if (switcherPosition.selected) {
                  if (switchType === CONST.LB_SWITCHER_TYPE_MANUAL) {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_LEVEL2_SWITCH_TYPE_MANUAL;
                  } else {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_LEVEL2_SWITCH_TYPE_POWER;
                  }
                }
              }
            }

            // 개폐 단수
            for (const switcherPosition of switcherLevel.positionAC) {
              const switchStep: number = (switcherPosition as SwitcherPosition).switchStep;
              if (switcherPosition.selected) {
                if (switchStep === CONST.VL_SWITCHER_STEP_1) {
                  preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_SWITCH_LEVEL_LEVEL1;
                } else if (switchStep === CONST.VL_SWITCHER_STEP_2) {
                  preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_SWITCH_LEVEL_LEVEL2;
                }
              }
            }
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.switcherWork.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_SWITCH_CONSTRUCTION,
          preCost: preSwitchCostTemp,
          developing: false,
        });
      }

      // 피복 공사 > 기초 피복
      let preCoverCostTemp: number = 0;
      if (this.struct.coverWork.selected) {
        for (const coverLevel of this.struct.coverWork.levelAC) {
          if (coverLevel.selected) {
            if (coverLevel.index === 0) {
              for (const coverPosition of coverLevel.positionAC) {
                if (coverPosition.selected && (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_VINYL) {
                  preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_COVER_COVER_TYPE_VINYL_1;
                }
              }
            } else {
              for (const coverPosition of coverLevel.positionAC) {
                if (coverPosition.selected) {
                  if (
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_VINYL ||
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_WEAVING_FILM ||
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_SHADENET ||
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_TENT
                  ) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_VINYL_2;
                  } else if ((coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_CASHMILON) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_CASHMILON_2;
                  } else if (
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_LAGGING ||
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_NONWOVEN ||
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_QUILTING ||
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_SCREEN ||
                    (coverPosition as CoverPosition).type === CONST.LB_COVER_TYPE_MAT
                  ) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_COVER_ADD_COVER_TYPE_LAGGING_2;
                  }
                }
              }
            }
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.coverWork.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_COVER_CONSTRUCTION,
          preCost: preCoverCostTemp,
          developing: false,
        });
      }

      // 천창개폐 > 천창 골조
      let preSkylightCostTemp: number = 0;
      const skylightType: string = this.struct.skyFrameWork.level1.skylightPosition.type;

      if (this.struct.skyFrameWork.selected) {
        if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SKYLIGHT_FRAME_TYPE_ARCH;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SKYLIGHT_FRAME_TYPE_HALF;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SKYLIGHT_FRAME_TYPE_TRIANGLE;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_ONE;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_BOTH;
        }
      }
      // 천창개폐 > 천창 피복
      if (this.struct.skyCoverWork.selected) {
        preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SKYLIGHT_COVER_SELECT;
      }
      // 공사 시공비 배열화
      if (this.struct.skyFrameWork.selected || this.struct.skyCoverWork.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_SKYLIGHT_CONSTRUCTION,
          preCost: preSkylightCostTemp,
          developing: false,
        });
      }

      // 칸막이 공사
      let prePartitionCostTemp: number = 0;
      const partitionQuantity: number = this.struct.partitionWork.level1.trunkPosition.partitionQuantity;
      if (this.struct.partitionWork.selected) {
        prePartitionCostTemp += partitionQuantity * CONST.LB_BUILDING_COST_SG_PARTITION_QUANTITY;
      }
      // 공사 시공비 배열화
      if (this.struct.partitionWork.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: `${CONST.LB_PARTITION_WORK} 공사`,
          preCost: prePartitionCostTemp,
          developing: false,
        });
      }

      // 창문 공사
      let preWindowCostTemp: number = 0;
      const windowQuantity: number = this.struct.windowWork.level1.totalWindowQuantity;
      if (this.struct.windowWork.selected) {
        preWindowCostTemp += windowQuantity * CONST.LB_BUILDING_COST_SG_WINDOW_QUANTITY;
      }
      // 공사 시공비 배열화
      if (this.struct.windowWork.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: `${CONST.LB_WINDOW_WORK} 공사`,
          preCost: preWindowCostTemp,
          developing: false,
        });
      }

      // 연동, 각연동
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      // 골조 공사 > 기둥 골조
      let preFrameCostTemp: number = 0;
      const columnCrossSize: string = this.struct.columnWorkIL.level1.columnPosition.columnCrossSize;
      if (this.struct.columnWorkIL.selected) {
        for (const roofLevel of this.struct.roofWorkIL.levelAC) {
          if (roofLevel.selected) {
            if (roofLevel.index === 0) {
              if (columnCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_48MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_60_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_60MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_60_60_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_60x60MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_75_75_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_75x75MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_100_50_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_100x50MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_125_75_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_125x75MM;
              }
            } else {
              if (columnCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_48MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_60_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_60MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_60_60_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_60x60MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_75_75_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_75x75MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_100_50_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_100x50MM;
              } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_125_75_MM) {
                preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COLUMN_LEVEL2_COLUMN_SPECCROSSSIZE_125x75MM;
              }
            }
          }
        }
      }
      // 골조 공사 > 지붕 골조 > 내재해조리개
      if (this.struct.roofWorkIL.selected) {
        for (const roofLevel of this.struct.roofWorkIL.levelAC) {
          if (roofLevel.selected && (roofLevel as RoofLevelIL).roofPosition.straightPart.enhancedGrabberSelected) {
            preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_ROOF_ENHANCED_GRABBER_SELECT;
          }
        }
      }
      // 골조 공사 > 물받이
      if (this.struct.gutterWorkIL.selected) {
        for (const gutterLevel of this.struct.gutterWorkIL.levelAC) {
          const totalGutterLength = (gutterLevel as GutterLevelIL).gutterPosition.totalGutterLength;
          if (gutterLevel.selected) {
            preFrameCostTemp += totalGutterLength * CONST.LB_BUILDING_COST_IL_GUTTER_TOTAL_LENGTH;
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.columnWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_FRAME_CONSTRUCTION,
          preCost: preFrameCostTemp,
          developing: false,
        });
      }

      // 개폐 공사 > 개폐기
      let preSwitchCostTemp: number = 0;
      if (this.struct.switcherWorkIL.selected) {
        for (const switcherLevel of this.struct.switcherWorkIL.levelAC) {
          if (switcherLevel.selected) {
            if (switcherLevel.index === 0) {
              for (const switcherPosition of switcherLevel.positionAC) {
                const switchType: string = (switcherPosition as SwitcherPositionIL).type;
                if (switcherPosition.selected) {
                  if (switchType === CONST.LB_SWITCHER_TYPE_MANUAL) {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SWITCHES_LEVEL1_SWITCH_TYPE_MANUAL;
                  } else {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SWITCHES_LEVEL1_SWITCH_TYPE_POWER;
                  }
                }
              }
            } else {
              for (const switcherPosition of switcherLevel.positionAC) {
                const switchType: string = (switcherPosition as SwitcherPositionIL).type;
                if (switcherPosition.selected) {
                  if (switchType === CONST.LB_SWITCHER_TYPE_MANUAL) {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SWITCHES_LEVEL2_SWITCH_TYPE_MANUAL;
                  } else {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SWITCHES_LEVEL2_SWITCH_TYPE_POWER;
                  }
                }
              }
            }

            // 개폐 단수
            for (const switcherPosition of switcherLevel.positionAC) {
              const switchStep: number = (switcherPosition as SwitcherPositionIL).switchStep;
              if (switcherPosition.selected) {
                if (switchStep === CONST.VL_SWITCHER_STEP_1) {
                  preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_SWITCH_LEVEL_LEVEL1;
                } else if (switchStep === CONST.VL_SWITCHER_STEP_2) {
                  preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_SWITCH_LEVEL_LEVEL2;
                }
              }
            }
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.switcherWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_SWITCH_CONSTRUCTION,
          preCost: preSwitchCostTemp,
          developing: false,
        });
      }

      // 피복 공사 > 기초 피복
      let preCoverCostTemp: number = 0;
      if (this.struct.coverWorkIL.selected) {
        for (const coverLevel of this.struct.coverWorkIL.levelAC) {
          if (coverLevel.selected) {
            if (coverLevel.index === 0) {
              for (const coverPosition of coverLevel.positionAC) {
                if (coverPosition.selected && (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_VINYL) {
                  preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COVER_COVER_TYPE_VINYL_1;
                }
              }
            } else {
              for (const coverPosition of coverLevel.positionAC) {
                if (coverPosition.selected) {
                  if (
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_VINYL ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_WEAVING_FILM ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_SHADENET ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_TENT
                  ) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_VINYL_2;
                  } else if ((coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_CASHMILON) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_CASHMILON_2;
                  } else if (
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_LAGGING ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_NONWOVEN ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_QUILTING ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_SCREEN ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_MAT
                  ) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_COVER_ADD_COVER_TYPE_LAGGING_2;
                  }
                }
              }
            }
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.coverWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_COVER_CONSTRUCTION,
          preCost: preCoverCostTemp,
          developing: false,
        });
      }

      // 수평커튼 공사 > 커튼 피복
      let preCurtainCostTemp: number = 0;
      if (this.struct.curCoverWorkIL.selected) {
        for (const curCoverLevel of this.struct.curCoverWorkIL.levelAC) {
          if (curCoverLevel.selected) {
            if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_QUILTING) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_QUILTING;
            } else if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_SCREEN) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_SCREEN;
            } else if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_NONWOVEN;
            } else if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_MAT) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_MAT;
            } else if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_SHADENET) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_CURTAIN_COVER_TYPE_SHADENET;
            }
          }
        }
      }
      // 수평커튼 공사 > 커튼 개폐
      if (this.struct.curSwitchWorkIL.selected) {
        for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC) {
          if (curSwitchLevel.selected) {
            preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_CURTAIN_SWITCH_SELECT;
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.curCoverWorkIL.selected || this.struct.curSwitchWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_CURTAIN_CONSTRUCTION,
          preCost: preCurtainCostTemp,
          developing: false,
        });
      }

      // 천창개폐 > 천창 골조
      let preSkylightCostTemp: number = 0;
      const skylightType: string = this.struct.skyFrameWorkIL.level1.skylightPosition.type;

      if (this.struct.skyFrameWorkIL.selected) {
        if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SKYLIGHT_FRAME_TYPE_ARCH;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SKYLIGHT_FRAME_TYPE_HALF;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SKYLIGHT_FRAME_TYPE_TRIANGLE;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_ONE;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_BOTH;
        }
      }
      // 천창개폐 > 천창 피복
      if (this.struct.skyCoverWorkIL.selected) {
        preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_IL_SKYLIGHT_COVER_SELECT;
      }
      // 공사 시공비 배열화
      if (this.struct.skyFrameWorkIL.selected || this.struct.skyCoverWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_SKYLIGHT_CONSTRUCTION,
          preCost: preSkylightCostTemp,
          developing: false,
        });
      }

      // 칸막이 공사
      let prePartitionCostTemp: number = 0;
      const partitionQuantity: number = this.struct.partitionWorkIL.level1.wholePosition.partitionQuantity;
      if (this.struct.partitionWorkIL.selected) {
        prePartitionCostTemp += partitionQuantity * CONST.LB_BUILDING_COST_IL_PARTITION_QUANTITY;
      }
      // 공사 시공비 배열화
      if (this.struct.partitionWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: `${CONST.LB_PARTITION_WORK} 공사`,
          preCost: prePartitionCostTemp,
          developing: true,
        });
      }

      // 창문 공사
      let preWindowCostTemp: number = 0;
      const windowQuantity: number = this.struct.windowWorkIL.level1.totalWindowQuantity;
      if (this.struct.windowWorkIL.selected) {
        preWindowCostTemp += windowQuantity * CONST.LB_BUILDING_COST_IL_WINDOW_QUANTITY;
      }
      // 공사 시공비 배열화
      if (this.struct.windowWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: `${CONST.LB_WINDOW_WORK} 공사`,
          preCost: preWindowCostTemp,
          developing: false,
        });
      }
      // 벤로
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 골조 공사 > 기둥 골조
      let preFrameCostTemp: number = 0;
      const columnCrossSize: string = this.struct.columnWorkVL.level1.columnPosition.columnCrossSize;
      if (
        this.struct.columnWorkVL.selected &&
        this.struct.columnWorkVL.levelAC[0].index === 0 &&
        this.struct.columnWorkVL.levelAC[0].selected
      ) {
        if (columnCrossSize === CONST.ITEM_SPEC_VALUE_50_30_MM) {
          preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_50x30MM;
        } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_60_60_MM) {
          preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_60x60MM;
        } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_75_45_MM) {
          preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_75x45MM;
        } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_75_75_MM) {
          preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_75x75MM;
        } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_100_50_MM) {
          preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_100x50MM;
        } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_100_100_MM) {
          preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_100x100MM;
        } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_125_75_MM) {
          preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_125x75MM;
        } else if (columnCrossSize === CONST.ITEM_SPEC_VALUE_125_125_MM) {
          preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COLUMN_LEVEL1_COLUMN_SPECCROSSSIZE_125x125MM;
        }
      }
      if (this.struct.roofWorkVL.selected) {
        for (const roofLevel of this.struct.roofWorkVL.levelAC) {
          // 골조 공사 > 지붕 골조 > 내재해조리개
          if (roofLevel.selected && (roofLevel as RoofLevelVL).roofPosition.straightPart.enhancedGrabberSelected) {
            preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_ROOF_ENHANCED_GRABBER_SELECT;
          }
          if (roofLevel.index === 0) {
            // 골조 공사 > 지붕 골조 > 서까래 골
            const rafterValleyQuantity = (roofLevel as RoofLevelVL).roofPosition.rafterPart.rafterValleyQuantity;
            if (rafterValleyQuantity === 1) {
              preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_RAFTER_VALLEY_QUANTITY_1;
            } else if (rafterValleyQuantity === 2) {
              preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_RAFTER_VALLEY_QUANTITY_2;
            } else if (rafterValleyQuantity === 3) {
              preFrameCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_RAFTER_VALLEY_QUANTITY_3;
            }
          }
        }
      }
      // 골조 공사 > 물받이
      if (this.struct.gutterWorkIL.selected) {
        for (const gutterLevel of this.struct.gutterWorkIL.levelAC) {
          const totalGutterLength = (gutterLevel as GutterLevelIL).gutterPosition.totalGutterLength;
          if (gutterLevel.selected) {
            preFrameCostTemp += totalGutterLength * CONST.LB_BUILDING_COST_VL_GUTTER_TOTAL_LENGTH;
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.columnWorkVL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_FRAME_CONSTRUCTION,
          preCost: preFrameCostTemp,
          developing: false,
        });
      }

      // 개폐 공사 > 개폐기
      let preSwitchCostTemp: number = 0;
      if (this.struct.switcherWorkIL.selected) {
        for (const switcherLevel of this.struct.switcherWorkIL.levelAC) {
          if (switcherLevel.selected) {
            if (switcherLevel.index === 0) {
              for (const switcherPosition of switcherLevel.positionAC) {
                const switchType: string = (switcherPosition as SwitcherPositionIL).type;
                if (switcherPosition.selected) {
                  if (switchType === CONST.LB_SWITCHER_TYPE_MANUAL) {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SWITCHES_LEVEL1_SWITCH_TYPE_MANUAL;
                  } else {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SWITCHES_LEVEL1_SWITCH_TYPE_POWER;
                  }
                }
              }
            } else {
              for (const switcherPosition of switcherLevel.positionAC) {
                const switchType: string = (switcherPosition as SwitcherPositionIL).type;
                if (switcherPosition.selected) {
                  if (switchType === CONST.LB_SWITCHER_TYPE_MANUAL) {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SWITCHES_LEVEL2_SWITCH_TYPE_MANUAL;
                  } else {
                    preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SWITCHES_LEVEL2_SWITCH_TYPE_POWER;
                  }
                }
              }
            }

            // 개폐 단수
            for (const switcherPosition of switcherLevel.positionAC) {
              const switchStep: number = (switcherPosition as SwitcherPositionIL).switchStep;
              if (switcherPosition.selected) {
                if (switchStep === CONST.VL_SWITCHER_STEP_1) {
                  preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_SWITCH_LEVEL_LEVEL1;
                } else if (switchStep === CONST.VL_SWITCHER_STEP_2) {
                  preSwitchCostTemp += pyeong * CONST.LB_BUILDING_COST_SG_SWITCHES_SWITCH_LEVEL_LEVEL2;
                }
              }
            }
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.switcherWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_SWITCH_CONSTRUCTION,
          preCost: preSwitchCostTemp,
          developing: false,
        });
      }

      // 피복 공사 > 기초 피복
      let preCoverCostTemp: number = 0;
      if (this.struct.coverWorkIL.selected) {
        for (const coverLevel of this.struct.coverWorkIL.levelAC) {
          if (coverLevel.selected) {
            if (coverLevel.index === 0) {
              for (const coverPosition of coverLevel.positionAC) {
                if (coverPosition.selected && (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_VINYL) {
                  preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COVER_COVER_TYPE_VINYL_1;
                }
              }
            } else {
              for (const coverPosition of coverLevel.positionAC) {
                if (coverPosition.selected) {
                  if (
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_VINYL ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_WEAVING_FILM ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_SHADENET ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_TENT
                  ) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_VINYL_2;
                  } else if ((coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_CASHMILON) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_CASHMILON_2;
                  } else if (
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_LAGGING ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_NONWOVEN ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_QUILTING ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_SCREEN ||
                    (coverPosition as CoverPositionIL).type === CONST.LB_COVER_TYPE_MAT
                  ) {
                    preCoverCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_COVER_ADD_COVER_TYPE_LAGGING_2;
                  }
                }
              }
            }
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.coverWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_COVER_CONSTRUCTION,
          preCost: preCoverCostTemp,
          developing: false,
        });
      }

      // 수평커튼 공사 > 커튼 피복
      let preCurtainCostTemp: number = 0;
      if (this.struct.curCoverWorkIL.selected) {
        for (const curCoverLevel of this.struct.curCoverWorkIL.levelAC) {
          if (curCoverLevel.selected) {
            if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_QUILTING) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_QUILTING;
            } else if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_SCREEN) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_SCREEN;
            } else if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_NONWOVEN;
            } else if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_MAT) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_MAT;
            } else if ((curCoverLevel as CurCoverLevelIL).curCoverPosition.type === CONST.LB_COVER_TYPE_SHADENET) {
              preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_CURTAIN_COVER_TYPE_SHADENET;
            }
          }
        }
      }
      // 수평커튼 공사 > 커튼 개폐
      if (this.struct.curSwitchWorkIL.selected) {
        for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC) {
          if (curSwitchLevel.selected) {
            preCurtainCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_CURTAIN_SWITCH_SELECT;
          }
        }
      }
      // 공사 시공비 배열화
      if (this.struct.curCoverWorkIL.selected || this.struct.curSwitchWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_CURTAIN_CONSTRUCTION,
          preCost: preCurtainCostTemp,
          developing: false,
        });
      }

      // 천창개폐 > 천창 골조
      let preSkylightCostTemp: number = 0;
      const skylightType: string = this.struct.skyFrameWorkIL.level1.skylightPosition.type;

      if (this.struct.skyFrameWorkIL.selected) {
        if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SKYLIGHT_FRAME_TYPE_ARCH;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SKYLIGHT_FRAME_TYPE_HALF;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SKYLIGHT_FRAME_TYPE_TRIANGLE;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_ONE;
        } else if (skylightType === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH) {
          preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SKYLIGHT_SKYLIGHT_TYPE_RACKPINION_WAY_BOTH;
        }
      }
      // 천창개폐 > 천창 피복
      if (this.struct.skyCoverWorkIL.selected) {
        preSkylightCostTemp += pyeong * CONST.LB_BUILDING_COST_VL_SKYLIGHT_COVER_SELECT;
      }
      // 공사 시공비 배열화
      if (this.struct.skyFrameWorkIL.selected || this.struct.skyCoverWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: CONST.LB_SKYLIGHT_CONSTRUCTION,
          preCost: preSkylightCostTemp,
          developing: false,
        });
      }

      // 칸막이 공사
      let prePartitionCostTemp: number = 0;
      const partitionQuantity: number = this.struct.partitionWorkIL.level1.wholePosition.partitionQuantity;
      if (this.struct.partitionWorkIL.selected) {
        prePartitionCostTemp += partitionQuantity * CONST.LB_BUILDING_COST_VL_PARTITION_QUANTITY;
      }
      // 공사 시공비 배열화
      if (this.struct.partitionWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: `${CONST.LB_PARTITION_WORK} 공사`,
          preCost: prePartitionCostTemp,
          developing: true,
        });
      }

      // 창문 공사
      let preWindowCostTemp: number = 0;
      const windowQuantity: number = this.struct.windowWorkIL.level1.totalWindowQuantity;
      if (this.struct.windowWorkIL.selected) {
        preWindowCostTemp += windowQuantity * CONST.LB_BUILDING_COST_VL_WINDOW_QUANTITY;
      }
      // 공사 시공비 배열화
      if (this.struct.windowWorkIL.selected) {
        this.constructionBuildingCosts.push({
          constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
          constructionName: `${CONST.LB_WINDOW_WORK} 공사`,
          preCost: preWindowCostTemp,
          developing: false,
        });
      }
    }

    // 공통

    // 작업동 공사
    let workroomCostTemp: number = 0;
    if (this.struct.workroomWork.selected) {
      workroomCostTemp += 0;
    }
    // 공사 시공비 배열화
    if (this.struct.workroomWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_ARCHITECTURE_CONSTRUCTION,
        constructionName: `${CONST.LB_WORKROOM_WORK} 공사`,
        preCost: workroomCostTemp,
        developing: true,
      });
    }

    // 환기 공사 > 외부 배기 공사
    let preVentExhaustCostTemp: number = 0;
    const ventExhaustFanType: string = this.struct.ventExhaustWork.level1.wholePosition.type;
    const ventExhaustRoofType: string = this.struct.ventExhaustWork.level1.wholePosition.roofType;
    if (this.struct.ventExhaustWork.selected) {
      if (ventExhaustFanType === CONST.LB_VENT_EXHAUST_ENDPIECE_FAN) {
        preVentExhaustCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_VENT_EXHAUST_ENDPIECE_FAN;
      }
      if (ventExhaustRoofType === CONST.LB_VENT_EXHAUST_ROOF_FAN_POWER) {
        preVentExhaustCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_VENT_EXHAUST_ROOF_TYPE_FAN_POWER;
      } else if (ventExhaustRoofType === CONST.LB_VENT_EXHAUST_ROOF_FAN_MANUAL) {
        preVentExhaustCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_VENT_EXHAUST_ROOF_TYPE_FAN_MANUAL;
      }
    }
    // 공사 시공비 배열화
    if (this.struct.ventExhaustWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_VENT_EXHAUST_WORK} 공사`,
        preCost: preVentExhaustCostTemp,
        developing: false,
      });
    }

    // 환기 공사 > 내부 환기 공사
    let preVentInsideCostTemp: number = 0;
    const ventInsideFanType: string = this.struct.ventInsideWork.level1.wholePosition.type;
    if (this.struct.ventInsideWork.selected) {
      if (ventInsideFanType === CONST.LB_VENT_INSIDE_AIR_FLOW_FAN) {
        preVentInsideCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_VENT_INSIDE_AIR_FLOW_FAN_SELECT;
      }
    }
    // 공사 시공비 배열화
    if (this.struct.ventInsideWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_VENT_INSIDE_WORK} 공사`,
        preCost: preVentInsideCostTemp,
        developing: false,
      });
    }

    // 관수 공사 > 관수 제어부
    let preWateringCostTemp: number = 0;
    const waterMainpipeType: string = this.struct.mainpipeWork.level1.wholePosition.type;
    if (this.struct.mainpipeWork.selected) {
      if (waterMainpipeType === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
        preWateringCostTemp += building * CONST.LB_BUILDING_COST_CM_WATERING_MAINPIPE_TYPE_CENTER;
      } else if (waterMainpipeType === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
        preWateringCostTemp += building * CONST.LB_BUILDING_COST_CM_WATERING_MAINPIPE_TYPE_EACH;
      }
    }
    // 관수 공사 > 관수 지관부
    const wateringScreenType: string = this.struct.wateringWork.level1.wholePosition.type;
    const wateringScreenGutterSelected: boolean = this.struct.wateringWork.level1.wholePosition.screenGutterSelected;
    const upperType: string = this.struct.wateringWork.level1.wholePosition.upperType;
    const fogSelected: boolean = this.struct.wateringWork.level1.wholePosition.fogSelected;
    const lowerType: string = this.struct.wateringWork.level1.wholePosition.lowerType;
    const wateringDripType: string = this.struct.wateringWork.level1.wholePosition.dripType;
    if (this.struct.wateringWork.selected) {
      // 수막 형태
      if (wateringScreenType === CONST.LB_WATERING_TYPE_SCREEN_FOUNTAIN_HOSE) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_MAINPIPE_TYPE_SCREEN_FOUNTAIN_HOSE;
      } else if (wateringScreenType === CONST.LB_WATERING_TYPE_SCREEN_MINIKLER) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_WATERING_TYPE_SCREEN_MINIKLER;
      }
      // 수막 물받이
      if (wateringScreenGutterSelected) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_SCREEN_GUTTER_SELECT;
      }
      // 상단 형태
      if (upperType === CONST.LB_WATERING_UPPER_TYPE_FOUNTAIN_MINIKLER) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_UPPER_TYPE_FOUNTAIN_MINIKLER;
      } else if (upperType === CONST.LB_WATERING_UPPER_TYPE_UPPER_FOUNTAIN_HOSE) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_UPPER_TYPE_UPPER_FOUNTAIN_HOSE;
      } else if (upperType === CONST.LB_WATERING_UPPER_TYPE_UPPER_SIDE_FOUNTAIN_HOSE) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_UPPER_TYPE_UPPER_SIDE_FOUNTAIN_HOSE;
      }
      // 상단 안개(포그)
      if (fogSelected) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_FOG_SELECT;
      }
      // 하단 형태
      if (lowerType === CONST.LB_WATERING_LOWER_TYPE_LOWER_FOUNTAIN_HOSE) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_LOWER_TYPE_LOWER_FOUNTAIN_HOSE;
      } else if (lowerType === CONST.LB_WATERING_LOWER_TYPE_MULCHING_FOUNTAIN_HOSE) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_LOWER_TYPE_MULCHING_FOUNTAIN_HOSE;
      }
      // 점적 형태
      if (wateringDripType === CONST.LB_WATERING_DRIP_TYPE_DRIP_WATERING) {
        preWateringCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_WATERING_WATERING_DRIP_TYPE_DRIP_WATERING;
      }
    }
    // 공사 시공비 배열화
    if (this.struct.mainpipeWork.selected || this.struct.wateringWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: CONST.LB_WATERING_CONSTRUCTION,
        preCost: preWateringCostTemp,
        developing: false,
      });
    }

    // 양액 공사 > 양액 제어부
    let preHydroponicsCostTemp: number = 0;
    const hydSystemType: string = this.struct.hydSystemWork.level1.wholePosition.type;
    const hydSystemCycleType: string = this.struct.hydSystemWork.level1.wholePosition.cycleType;
    if (this.struct.hydSystemWork.selected) {
      if (hydSystemType === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
        preHydroponicsCostTemp += cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE;
      } else if (hydSystemType === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
        preHydroponicsCostTemp += cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_SYSTEM_TYPE_NFT;
      } else if (hydSystemType === CONST.LB_HYD_SYSTEM_TYPE_DFT) {
        preHydroponicsCostTemp += cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_SYSTEM_TYPE_DFT;
      }
      if (hydSystemCycleType === CONST.LB_HYD_SYSTEM_CYCLE_TYPE_ACYCLE) {
        preHydroponicsCostTemp += cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_SYSTEM_CYCLE_TYPE_ACYCLE;
      } else if (hydSystemCycleType === CONST.LB_HYD_SYSTEM_CYCLE_TYPE_CYCLE) {
        preHydroponicsCostTemp += cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_SYSTEM_CYCLE_TYPE_CYCLE;
      }
    }
    // 양액 공사 > 양액 베드부
    const hydCultivationType: string = this.struct.hydCultivationWork.level1.wholePosition.type;
    const cultivationMaterialType: string = this.struct.hydCultivationWork.level1.wholePosition.cultivationMaterialType;
    const groundRailType: string = this.struct.hydCultivationWork.level1.wholePosition.groundRailType;
    if (this.struct.hydCultivationWork.selected) {
      if (hydCultivationType === CONST.LB_HYD_CULTIVATION_TYPE_STANDING_GUTTER) {
        preHydroponicsCostTemp += cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_STANDING_GUTTER;
      } else if (hydCultivationType === CONST.LB_HYD_CULTIVATION_TYPE_HANGING_GUTTER_FIXING) {
        preHydroponicsCostTemp +=
          cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_HANGING_GUTTER_FIXING;
      } else if (hydCultivationType === CONST.LB_HYD_CULTIVATION_TYPE_HANGING_GUTTER_MOVE) {
        preHydroponicsCostTemp +=
          cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_HANGING_GUTTER_MOVE;
      } else if (hydCultivationType === CONST.LB_HYD_CULTIVATION_TYPE_STYROFOAM_BED) {
        preHydroponicsCostTemp += cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_STYROFOAM_BED;
      } else if (hydCultivationType === CONST.LB_HYD_CULTIVATION_TYPE_NFT_BED) {
        preHydroponicsCostTemp += cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_CULTIVATION_TYPE_NFT_BED;
      }
      if (cultivationMaterialType === CONST.LB_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_UPPER_TRELLIS) {
        preHydroponicsCostTemp +=
          cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_UPPER_TRELLIS;
      }
      if (groundRailType === CONST.LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_NORMAL) {
        preHydroponicsCostTemp +=
          cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_CULTIVATION_CULTIVATION_GROUND_RAIL_TYPE_NORMAL;
      } else if (groundRailType === CONST.LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_TUBE) {
        preHydroponicsCostTemp +=
          cultivationPyeong * CONST.LB_BUILDING_COST_CM_HYD_CULTIVATION_CULTIVATION_GROUND_RAIL_TYPE_TUBE;
      }
    }
    // 공사 시공비 배열화
    if (this.struct.hydSystemWork.selected || this.struct.hydCultivationWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: CONST.LB_HYDROPONICS_CONSTRUCTION,
        preCost: preHydroponicsCostTemp,
        developing: false,
      });
    }

    // 냉난방 공사 > 난방 공사
    let preTemHeatingCostTemp: number = 0;
    if (this.struct.temHeatingWork.selected) {
      preTemHeatingCostTemp += 0;
    }
    // 공사 시공비 배열화
    if (this.struct.temHeatingWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_TEM_HEATING_WORK} 공사`,
        preCost: preTemHeatingCostTemp,
        developing: true,
      });
    }

    // 냉난방 공사 > 냉방 공사
    let preTemCoolingCostTemp: number = 0;
    if (this.struct.temCoolingWork.selected) {
      preTemCoolingCostTemp += 0;
    }
    // 공사 시공비 배열화
    if (this.struct.temCoolingWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_TEM_COOLING_WORK} 공사`,
        preCost: preTemCoolingCostTemp,
        developing: true,
      });
    }

    // 스마트팜 공사
    let preSmartfarmCostTemp: number = 0;
    if (this.struct.environmentWork.selected) {
      preSmartfarmCostTemp += 0;
    }
    // 공사 시공비 배열화
    if (this.struct.environmentWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_ENVIRONMENT_WORK} 공사`,
        preCost: preSmartfarmCostTemp,
        developing: true,
      });
    }

    // 선반/균상다이 공사
    let preRackCostTemp: number = 0;
    if (this.struct.rackWork.selected) {
      // preRackCostTemp += rackFloorPyeong * CONST.LB_BUILDING_COST_CM_RACK_FLOOR_TOTAL_AREA;
      preRackCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_RACK_FLOOR_TOTAL_AREA;
    }
    // 공사 시공비 배열화
    if (this.struct.rackWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_RACK_WORK} 공사`,
        preCost: preRackCostTemp,
        developing: false,
      });
    }

    // 운반레일 공사
    let preRailCostTemp: number = 0;
    if (this.struct.railWork.selected) {
      // preRailCostTemp += totalRailLength * CONST.LB_BUILDING_COST_CM_RAIL_TOTAL_RAIL_LENGTH;
      if (this.struct.railWork.level1.wholePosition.type === CONST.VL_RAIL_TYPE_UPPER_SINGLE) {
        preRailCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_RAIL_UPPER_RAIL_TOTAL_LENGTH;
      }
      if (this.struct.railWork.level1.wholePosition.sideType === CONST.VL_RAIL_TYPE_SIDE_SINGLE) {
        preRailCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_RAIL_SIDE_RAIL_TOTAL_LENGTH;
      }
      if (
        this.struct.railWork.level1.wholePosition.groundType === CONST.VL_RAIL_TYPE_GROUND_DOUBLE ||
        this.struct.railWork.level1.wholePosition.groundType === CONST.VL_RAIL_TYPE_GROUND_SINGLE
      ) {
        preRailCostTemp += pyeong * CONST.LB_BUILDING_COST_CM_RAIL_GROUND_RAIL_TOTAL_LENGTH;
      }
    }
    // 공사 시공비 배열화
    if (this.struct.railWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_RAIL_WORK} 공사`,
        preCost: preRailCostTemp,
        developing: false,
      });
    }

    // CO2 공사
    let preCo2CostTemp: number = 0;
    if (this.struct.co2Work.selected) {
      preCo2CostTemp += 0;
    }
    // 공사 시공비 배열화
    if (this.struct.co2Work.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_CO2_WORK} 공사`,
        preCost: preCo2CostTemp,
        developing: true,
      });
    }

    // 자동방제기 공사
    let preAutosprayCostTemp: number = 0;
    if (this.struct.autosprayWork.selected) {
      preAutosprayCostTemp += 0;
    }
    // 공사 시공비 배열화
    if (this.struct.autosprayWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_AUTOSPRAY_WORK} 공사`,
        preCost: preAutosprayCostTemp,
        developing: true,
      });
    }

    // 기타 기계/설비 공사
    let preOtherFacilitiesCostTemp: number = 0;
    if (this.struct.facilityWork.selected) {
      preOtherFacilitiesCostTemp += 0;
    }
    // 공사 시공비 배열화
    if (this.struct.facilityWork.selected) {
      this.constructionBuildingCosts.push({
        constructionType: CONST.LB_FACILITIES_CONSTRUCTION,
        constructionName: `${CONST.LB_OTHER_FACILITIES_WORK} 공사`,
        preCost: preOtherFacilitiesCostTemp,
        developing: true,
      });
    }

    // 가중치 적용하여 원본 대입
    let weight: number = 1;
    if (pyeong < 70) {
      weight = 1.1;
    } else if (pyeong < 120) {
      weight = 1.07;
    } else if (pyeong < 300) {
      weight = 1.05;
    } else if (pyeong < 500) {
      weight = 1.02;
    } else if (pyeong < 1000) {
      weight = 1;
    } else if (pyeong < 3000) {
      weight = 0.98;
    } else {
      weight = 0.97;
    }

    this.constructionBuildingCosts.forEach(buildingCost => {
      buildingCost.preCost = buildingCost.preCost * weight * rateOfIncrease;
    });
  }

  /**
   * 알고리즘: 전체 예상 시공비
   */
  public addPredictBuildingTotalItem(): void {
    if (this.preBuildingCost) {
      // 동적 생성 : 품목AC 초기화
      this.customPart.itemAC = new Array();
      // 전체 예상 시공비 아이템 추가
      const itemCustomLaborCost: ItemCustomLaborCost = new ItemCustomLaborCost();
      itemCustomLaborCost.setAssociation(this.design, this.struct, this.work, this.level, this, this.customPart);
      itemCustomLaborCost.setDefaultData(
        1,
        CONST.ITEM_ID_CUSTOM_LABOR_COST,
        "전체 공사 시공비",
        "전체 공사 시공비",
        "",
        "",
        CONST.LB_COST_TYPE_LABOR,
      );
      itemCustomLaborCost.setReferenceVariable();
      itemCustomLaborCost.setDefaultVariable();
      itemCustomLaborCost.setDefaultModel();
      itemCustomLaborCost.algorithmItem();
      itemCustomLaborCost.isCustomAdded = true;
      itemCustomLaborCost.designQuantity = 1;
      itemCustomLaborCost.customUnitSupply = this.preBuildingCost;
      itemCustomLaborCost.customPurchaseSupply = this.preBuildingCost;
      this.customPart.itemAC.push(itemCustomLaborCost);
    }
  }

  /**
   * 알고리즘: 큰 공사별 예상 시공비
   */
  public addPredictBigBuildingItems(): void {
    if (this.constructionBuildingCosts) {
      // 동적 생성 : 품목AC 초기화
      this.customPart.itemAC = new Array();
      // 큰 공사별 예상 시공비 아이템 추가
      const itemCustomLaborCost: ItemCustomLaborCost = new ItemCustomLaborCost();
      this.constructionBuildingCosts.forEach((buildingCost, index) => {
        itemCustomLaborCost.setAssociation(this.design, this.struct, this.work, this.level, this, this.customPart);
        itemCustomLaborCost.setDefaultData(
          index,
          CONST.ITEM_ID_CUSTOM_LABOR_COST,
          `${buildingCost.constructionType} 시공비`,
          `${buildingCost.constructionType} 시공비`,
          "",
          "",
          CONST.LB_COST_TYPE_LABOR,
        );
        itemCustomLaborCost.setReferenceVariable();
        itemCustomLaborCost.setDefaultVariable();
        itemCustomLaborCost.setDefaultModel();
        itemCustomLaborCost.algorithmItem();
        itemCustomLaborCost.isCustomAdded = true;
        itemCustomLaborCost.designQuantity = 1;
        itemCustomLaborCost.customUnitSupply += buildingCost.preCost;
        itemCustomLaborCost.customPurchaseSupply += buildingCost.preCost;
        // itemCustomLaborCost.customUnitSupply = this.preBigBuildingCost(buildingCost.constructionType);
        // itemCustomLaborCost.customPurchaseSupply = this.preBigBuildingCost(buildingCost.constructionType);
      });
    }
  }

  /**
   * 알고리즘: 공사별 예상 시공비
   */
  public addPredictBuildingItems(): void {
    if (this.constructionBuildingCosts) {
      // 동적 생성 : 품목AC 초기화
      this.customPart.itemAC = new Array();
      // 공사별 예상 시공비 아이템 추가
      this.constructionBuildingCosts.forEach((buildingCost, index) => {
        const itemCustomLaborCost: ItemCustomLaborCost = new ItemCustomLaborCost();
        itemCustomLaborCost.setAssociation(this.design, this.struct, this.work, this.level, this, this.customPart);
        itemCustomLaborCost.setDefaultData(
          index,
          CONST.ITEM_ID_CUSTOM_LABOR_COST,
          `${buildingCost.constructionName} 시공비`,
          `${buildingCost.constructionName} 시공비`,
          "",
          "",
          CONST.LB_COST_TYPE_LABOR,
        );
        itemCustomLaborCost.setReferenceVariable();
        itemCustomLaborCost.setDefaultVariable();
        itemCustomLaborCost.setDefaultModel();
        itemCustomLaborCost.algorithmItem();
        itemCustomLaborCost.isCustomAdded = true;
        itemCustomLaborCost.designQuantity = 1;
        itemCustomLaborCost.customUnitSupply = buildingCost.preCost;
        itemCustomLaborCost.customPurchaseSupply = buildingCost.preCost;
        this.customPart.itemAC.push(itemCustomLaborCost);
      });
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
