import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 배수관
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-01
 */
@jsonObject
export class ItemDrainPipe extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, 지름, (길이)]
  public _specFeature: string = undefined; // 특성
  public _specDiameter: string = undefined; // 지름
  public _specLength: number = undefined; // 길이

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 지름
   */
  public get specDiameter(): string {
    return this._specDiameter;
  }
  //
  public set specDiameter(value: string) {
    this._specDiameter = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    this._specLength = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_METER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.drainPipeCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specDiameter = specsArray[1];
    this.specLengthLabel = specsArray[2];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specDiameter = specsArray[1];
    this.specLengthLabel = specsArray[2];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specFeature + CONST.DELIMITER_SPEC + this._specDiameter + CONST.DELIMITER_SPEC + this.specLengthLabel;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }
}
