import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPadVinyl } from "vhows-design/src/object/design/item/list/ItemPadVinyl";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { FixingSampleIL_CondensationPad } from "vhows-design/src/object/design/cover/fixing/il/FixingSampleIL_CondensationPad";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject({
  knownTypes: [FixingSampleIL_CondensationPad],
})
export class FixingPartIL_CondensationPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public condensationPadSample: FixingSampleIL_CondensationPad; // 결로 패드 샘플

  // 아이템
  public condensationPad_condensationPad: ItemPad;
  public condensationPad_normalPadSpring: ItemPadSpring;
  public condensationPad_condensationPadConnectionPin: ItemPadConnector;
  public condensationPad_padVinyl: ItemPadVinyl;
  public condensationPad_pePipe: ItemWateringPipe;
  public condensationPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.condensationPadSample = new FixingSampleIL_CondensationPad();

    this.sampleAC = [this.condensationPadSample];

    // 아이템
    this.condensationPad_condensationPad = new ItemPad();
    this.condensationPad_normalPadSpring = new ItemPadSpring();
    this.condensationPad_condensationPadConnectionPin = new ItemPadConnector();
    this.condensationPad_padVinyl = new ItemPadVinyl();
    this.condensationPad_pePipe = new ItemWateringPipe();
    this.condensationPad_screw = new ItemScrew();

    this.itemAC = [
      this.condensationPad_condensationPad,
      this.condensationPad_normalPadSpring,
      this.condensationPad_condensationPadConnectionPin,
      this.condensationPad_padVinyl,
      this.condensationPad_pePipe,
      this.condensationPad_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.condensationPadSample = <FixingSampleIL_CondensationPad>this.sampleAC[0];

    // 아이템
    this.condensationPad_condensationPad = <ItemPad>this.itemAC[0];
    this.condensationPad_normalPadSpring = <ItemPadSpring>this.itemAC[1];
    this.condensationPad_condensationPadConnectionPin = <ItemPadConnector>this.itemAC[2];
    this.condensationPad_padVinyl = <ItemPadVinyl>this.itemAC[3];
    this.condensationPad_pePipe = <ItemWateringPipe>this.itemAC[4];
    this.condensationPad_screw = <ItemScrew>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.condensationPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_CONDENSATION_PAD,
        CONST.ITEM_NAME_CONDENSATION_PAD,
        "결로 패드",
        "알루미늄, 1T, 5m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.condensationPad_condensationPad.setDefaultData(
        0,
        CONST.ITEM_ID_CONDENSATION_PAD,
        CONST.ITEM_NAME_CONDENSATION_PAD,
        "결로 패드",
        "알루미늄, 1T, 5m",
      );
      this.condensationPad_normalPadSpring.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "결로 패드",
        "일반, 2m",
      );
      this.condensationPad_condensationPadConnectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONDENSATION_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_CONDENSATION_PAD_CONNECTION_PIN,
        "결로 패드",
        "플라스틱",
      );
      this.condensationPad_padVinyl.setDefaultData(
        3,
        CONST.ITEM_ID_PAD_VINYL,
        CONST.ITEM_NAME_PAD_VINYL,
        "결로 패드",
        "일반, 200m",
      );
      this.condensationPad_pePipe.setDefaultData(
        4,
        CONST.ITEM_ID_PE_PIPE,
        CONST.ITEM_NAME_PE_PIPE,
        "결로 패드",
        "연질관, 13mm, 200m",
      );
      this.condensationPad_screw.setDefaultData(
        5,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "결로 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 4) {
      // 순서 변경을 위한, 기존 아이템들의 아이디 삭제
      let item: Item;
      for (item of this.itemAC) {
        item.id = NaN;
      }

      const condensationPad_normalPadSpring: ItemPadSpring = new ItemPadSpring();
      condensationPad_normalPadSpring.setAssociation(design, struct, work, level, position, this);
      condensationPad_normalPadSpring.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "결로 패드",
        "일반, 2m",
      );
      condensationPad_normalPadSpring.selected = false;
      condensationPad_normalPadSpring.visible = false;
      this.itemAC.splice(1, 0, condensationPad_normalPadSpring);

      const condensationPad_padVinyl: ItemPadVinyl = new ItemPadVinyl();
      condensationPad_padVinyl.setAssociation(design, struct, work, level, position, this);
      condensationPad_padVinyl.setDefaultData(
        3,
        CONST.ITEM_ID_PAD_VINYL,
        CONST.ITEM_NAME_PAD_VINYL,
        "결로 패드",
        "일반, 200m",
      );
      condensationPad_padVinyl.selected = false;
      condensationPad_padVinyl.visible = false;
      this.itemAC.splice(3, 0, condensationPad_padVinyl);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const fixingPosition: FixingPositionIL = <FixingPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;
    let totalLength: number;
    // 외부
    let wingPosition: WingPositionIL | WingPositionVL;
    let roofPosition: RoofPositionIL | RoofPositionVL;
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      wingPosition = (<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition;
      roofPosition = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      wingPosition = (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition;
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    const coverLevel: CoverLevelIL = <CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index];

    /// //////// 선택, 가시성 ///////////

    this.condensationPad_condensationPad.selected = true;
    this.condensationPad_condensationPad.visible = true;
    this.condensationPad_normalPadSpring.selected = true;
    this.condensationPad_normalPadSpring.visible = true;
    this.condensationPad_condensationPadConnectionPin.selected = true;
    this.condensationPad_condensationPadConnectionPin.visible = true;
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        this.condensationPad_padVinyl.selected = this.design.basic.padVinylFlag && !wingPosition.selected;
      } else {
        this.condensationPad_padVinyl.selected = this.design.basic.padVinylFlag;
      }
    } else {
      this.condensationPad_padVinyl.selected = false;
    }
    this.condensationPad_padVinyl.visible = true;
    this.condensationPad_pePipe.selected = true;
    this.condensationPad_pePipe.visible = true;
    this.condensationPad_screw.selected = true;
    this.condensationPad_screw.visible = true;

    /// //////// 수치 ///////////

    /// //////// 수량 ///////////

    if (
      fixingPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      fixingPosition.label === CONST.LB_POSITION_SIDE_WING
    ) {
      this.condensationPad_condensationPad.designQuantity =
        (this.basicLevel.length / this.condensationPad_condensationPad.specLength) * fixingPosition.lineNumber;
    } else if (fixingPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          bothEndQuantity = (this.basicLevel.length / this.condensationPad_condensationPad.specLength) * 1 * 2;
          centralQuantity =
            (this.basicLevel.length / this.condensationPad_condensationPad.specLength) *
            fixingPosition.lineNumber *
            (this.basic.buildingNumber - 2);
          this.condensationPad_condensationPad.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          this.condensationPad_condensationPad.designQuantity =
            (this.basicLevel.length / this.condensationPad_condensationPad.specLength) *
            fixingPosition.lineNumber *
            this.basic.buildingNumber;
        }
      } else {
        this.condensationPad_condensationPad.designQuantity =
          (this.basicLevel.length / this.condensationPad_condensationPad.specLength) * fixingPosition.lineNumber;
      }
    } else if (fixingPosition.label === CONST.LB_POSITION_FRONT || fixingPosition.label === CONST.LB_POSITION_BACK) {
      totalLength = 0;
      if (fixingPosition.type === CONST.LB_FIXING_TYPE_CONDENSATION_PAD) {
        totalLength += this.basicLevel.width * fixingPosition.lineNumber * this.basic.buildingNumber;
      }
      if (fixingPosition.endpieceRafterType === CONST.LB_FIXING_TYPE_CONDENSATION_PAD) {
        totalLength +=
          roofPosition.rafterPart.rafterTotalLength *
          fixingPosition.endpieceRafterLineNumber *
          this.basic.buildingNumber;
      }
      if (fixingPosition.endpieceColumnType === CONST.LB_FIXING_TYPE_CONDENSATION_PAD) {
        totalLength += this.basicLevel.heightShoulder * fixingPosition.endpieceColumnLineNumber;
      }
      if (fixingPosition.endpieceGateType === CONST.LB_FIXING_TYPE_CONDENSATION_PAD) {
        if (fixingPosition.label === CONST.LB_POSITION_FRONT) {
          totalLength += 2.0 * fixingPosition.endpieceGateLineNumber * endpieceLevel.frontPosition.gateBuildingNumber;
        } else if (fixingPosition.label === CONST.LB_POSITION_BACK) {
          totalLength += 2.0 * fixingPosition.endpieceGateLineNumber * endpieceLevel.backPosition.gateBuildingNumber;
        }
      }
      totalLength += 0.5 * this.basic.buildingNumber;
      this.condensationPad_condensationPad.designQuantity =
        totalLength / this.condensationPad_condensationPad.specLength;
    }

    let condensationPad_normalPadSpring_designQuantity: number =
      this.condensationPad_condensationPad.getSelectedQuantity() *
      (this.condensationPad_condensationPad.specLength / this.condensationPad_normalPadSpring.specLength);
    condensationPad_normalPadSpring_designQuantity *= this.design.preference.getPadSpringMultFactor(
      coverLevel.coverLayers[this.position.index],
    );
    this.condensationPad_normalPadSpring.designQuantity =
      condensationPad_normalPadSpring_designQuantity * CONST.NUM_EXTRA_RATE_PAD_SPRING;

    this.condensationPad_condensationPadConnectionPin.designQuantity =
      this.condensationPad_condensationPad.getSelectedQuantity();

    this.condensationPad_padVinyl.designQuantity =
      (this.condensationPad_condensationPad.getSelectedQuantity() * this.condensationPad_condensationPad.specLength) /
      this.condensationPad_padVinyl.specLength;

    this.condensationPad_pePipe.designQuantity =
      (this.condensationPad_condensationPadConnectionPin.getSelectedQuantity() * 2) /
      this.condensationPad_pePipe.specLength;

    this.condensationPad_screw.designQuantity =
      ((this.condensationPad_condensationPad.getSelectedQuantity() * 6 +
        this.condensationPad_condensationPadConnectionPin.getSelectedQuantity() * 2) /
        this.condensationPad_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [결로 패드] 결로 패드 <- 결로 패드 샘플
   */
  public algorithmSpec_condensationPad_condensationPad(): void {
    this.condensationPad_condensationPad.specs = this.condensationPadSample.specs;
  }

  /**
   * 규격 알고리즘: [결로 패드] 일반 패드 스프링 <- 고정
   */
  public algorithmSpec_condensationPad_normalPadSpring(): void {}

  /**
   * 규격 알고리즘: [결로 패드] 결로 패드 연결핀 <- 고정
   */
  public algorithmSpec_condensationPad_condensationPadConnectionPin(): void {}

  /**
   * 규격 알고리즘: [결로 패드] 패드 비닐 <- 공통 샘플
   */
  public algorithmSpec_condensationPad_padVinyl(): void {}

  /**
   * 규격 알고리즘: [결로 패드] PE관 <- 고정
   */
  public algorithmSpec_condensationPad_pePipe(): void {}

  /**
   * 규격 알고리즘: [결로 패드] 나사 <- 공통 샘플
   */
  public algorithmSpec_condensationPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
