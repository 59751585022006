import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 바닥지
 * @author 오은혜
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemGroundCover extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, 색상], (폭), (길이)
  public _specFeature: string = undefined; // 특성
  public _specColor: string = undefined; // 색상
  public _specWidth: number = undefined; // 폭
  public _specLength: number = undefined; // 길이

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 색상
   */
  public get specColor(): string {
    return this._specColor;
  }
  //
  public set specColor(value: string) {
    this._specColor = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭
   */
  public get specWidth(): number {
    return this._specWidth;
  }
  //
  public set specWidth(value: number) {
    // 일반화된 간격 적용 - 0.1m 간격(0.1배수로 올림)
    this._specWidth = CommonUtil.roundUpX(value, 0.1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 폭 레이블(값+단위기호)
   */
  public get specWidthLabel(): string {
    return this.specWidth + CONST.UNIT_METER;
  }
  //
  public set specWidthLabel(value: string) {
    this.specWidth = parseFloat(value);
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 0.1m 간격(0.1배수로 올림)
    this._specLength = CommonUtil.roundUpX(value, 0.1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_METER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.groundCoverCategoryModel;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 매출가 계산식
    let sellingSupply: number = defaultUnitSupply * this.specWidth * this.specLength;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specColor = specsArray[1];
    this.specWidthLabel = specsArray[2];
    this.specLengthLabel = specsArray[3];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specColor = specsArray[1];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature + CONST.DELIMITER_SPEC + this._specColor;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList + CONST.DELIMITER_SPEC + this.specWidthLabel + CONST.DELIMITER_SPEC + this.specLengthLabel;
  }
}
