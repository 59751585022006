import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 천창 모터
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-04-15
 */
@jsonObject
export class ItemSkylightMotor extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [용도], [축커플링], [지지크기]
  public _specUsage: string = undefined; // 용도
  public _specAxisCoupling: string = undefined; // 축커플링
  public _specSupportSize: string = undefined; // 지지크기

  // 하우스 설계 > 상표 : [회사, 모델명, 토크, 소비전력]
  public _brandModelName: string = undefined; // 모델명
  public _brandTorque: string = undefined; // 토크
  public _brandPowerConsumption: string = undefined; // 소비전력

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 용도
   */
  public get specUsage(): string {
    return this._specUsage;
  }
  //
  public set specUsage(value: string) {
    this._specUsage = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 축커플링
   */
  public get specAxisCoupling(): string {
    return this._specAxisCoupling;
  }
  //
  public set specAxisCoupling(value: string) {
    this._specAxisCoupling = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 지지크기
   */
  public get specSupportSize(): string {
    return this._specSupportSize;
  }
  //
  public set specSupportSize(value: string) {
    this._specSupportSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  /**
   * 모델명
   */
  public get brandModelName(): string {
    return this._brandModelName;
  }
  //
  public set brandModelName(value: string) {
    this._brandModelName = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 토크
   */
  public get brandTorque(): string {
    return this._brandTorque;
  }
  //
  public set brandTorque(value: string) {
    this._brandTorque = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 소비전력
   */
  public get brandPowerConsumption(): string {
    return this._brandPowerConsumption;
  }
  //
  public set brandPowerConsumption(value: string) {
    this._brandPowerConsumption = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.skylightMotorCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specUsage = specsArray[0];
    this.specAxisCoupling = specsArray[1];
    this.specSupportSize = specsArray[2];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specUsage = specsArray[0];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specUsage;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList + CONST.DELIMITER_SPEC + this._specAxisCoupling + CONST.DELIMITER_SPEC + this._specSupportSize;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    const brandsArray: string[] = this._brands.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandTorque = brandsArray[2];
    this.brandPowerConsumption = brandsArray[3];
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    const brandsArray: string[] = this._brandsList.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandTorque = brandsArray[2];
    this.brandPowerConsumption = brandsArray[3];
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList =
      this._brandCompany +
      CONST.DELIMITER_BRAND +
      this._brandModelName +
      CONST.DELIMITER_BRAND +
      this._brandTorque +
      CONST.DELIMITER_BRAND +
      this._brandPowerConsumption;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }
}
