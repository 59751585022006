import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemBracket } from "vhows-design/src/object/design/item/list/ItemBracket";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { ColumnSampleVL_MiddlePipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_MiddlePipe";
import { ColumnSampleVL_MiddleTrussDiagonalPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_MiddleTrussDiagonalPipe";
import { ColumnSampleVL_MiddleBracingPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_MiddleBracingPipe";
import { ColumnSampleVL_FrontAndBackMiddlePipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_FrontAndBackMiddlePipe";
import { ColumnSampleVL_MiddleTrussBeamCSectionSteel } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_MiddleTrussBeamCSectionSteel";
import { ColumnSampleVL_MiddleBracingRoundBar } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_MiddleBracingRoundBar";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-08
 */
@jsonObject({
  knownTypes: [
    ColumnSampleVL_MiddlePipe,
    ColumnSampleVL_MiddleTrussDiagonalPipe,
    ColumnSampleVL_MiddleTrussBeamCSectionSteel,
    ColumnSampleVL_FrontAndBackMiddlePipe,
    ColumnSampleVL_MiddleBracingRoundBar,
    ColumnSampleVL_MiddleBracingPipe,
  ],
})
export class ColumnPartVL_Middle extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public middlePipeSample: ColumnSampleVL_MiddlePipe; // 중방 트러스 상현재 파이프 샘플, 중방 트러스 하현재 파이프 샘플
  public middleTrussDiagonalPipeSample: ColumnSampleVL_MiddleTrussDiagonalPipe; // 중방 트러스 사재 파이프 샘플
  public middleTrussBeamCSectionSteelSample: ColumnSampleVL_MiddleTrussBeamCSectionSteel; // 중방 트러스 보
  public frontAndBackMiddlePipeSample: ColumnSampleVL_FrontAndBackMiddlePipe; // 전후면 중방 파이프 샘플
  public middleBracingRoundBarSample: ColumnSampleVL_MiddleBracingRoundBar; // 중방 브레싱 환봉 샘플
  public middleBracingPipeSample: ColumnSampleVL_MiddleBracingPipe; // 중방 브레싱 파이프 샘플

  // 아이템
  public middle_pipe: ItemPipe;
  public middleTrussLower_pipe: ItemPipe;
  public middleTrussDiagonal_pipe: ItemPipe;
  public middleTrussBeam_cSectionSteel: ItemPipe;
  public middleTruss_bracket: ItemBracket;
  public middleTruss_boltAndNut: ItemFrameOther;
  public smallColumnAndMiddleTruss_bracket: ItemBracket;
  public smallColumnAndMiddleTruss_boltAndNut: ItemFrameOther;
  public frontAndBackMiddle_pipe: ItemPipe;
  public middleBracing_roundBar: ItemPipe;
  public middleAndRoundBar_bracket: ItemBracket;
  public roundBarAndBracket_turnbuckle: ItemFrameOther;
  public middleAndBracket_boltAndNut: ItemFrameOther;
  public middleBracing_pipe: ItemPipe;
  public middle_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _middleLength: number; // 중방 길이
  @jsonMember(Number)
  public _middleLineNumber: number; // 중방 줄수
  @jsonMember(Number)
  public _trussDiagonalLength: number; // 중방 트러스 사재 길이
  @jsonMember(Number)
  public _trussDiagonalQuantity: number; // 중방 트러스 사재 수량
  @jsonMember(Number)
  public _frontAndBackMiddleLineNumber: number; // 전후면 중방 줄수
  @jsonMember(String)
  public _middleBracingType: string; // 중방 브레싱 형태
  @jsonMember(Number)
  public _middleBracingQuantity: number; // 중방 브레싱 수량
  @jsonMember(Number)
  public _middleBracingTotalQuantity: number; // 중방 브레싱 전체 수량

  /**
   * 중방 길이
   */
  public get middleLength(): number {
    return this._middleLength;
  }

  //
  public set middleLength(value: number) {
    this._middleLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 줄수
   */
  public get middleLineNumber(): number {
    return this._middleLineNumber;
  }

  //
  public set middleLineNumber(value: number) {
    this._middleLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 관수 제어부
    this.struct.mainpipeWork.level1.wholePosition.upperFramePart.algorithmPart();
  }

  /**
   * 중방 트러스 사재 길이
   */
  public get trussDiagonalLength(): number {
    return this._trussDiagonalLength;
  }

  //
  public set trussDiagonalLength(value: number) {
    this._trussDiagonalLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.middleTrussDiagonalPipeSample.algorithmSpec_specLength();
    this.algorithmPart();
  }

  /**
   * 중방 트러스 사재 수량
   */
  public get trussDiagonalQuantity(): number {
    return this._trussDiagonalQuantity;
  }

  //
  public set trussDiagonalQuantity(value: number) {
    this._trussDiagonalQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 전후면 중방 줄수
   */
  public get frontAndBackMiddleLineNumber(): number {
    return this._frontAndBackMiddleLineNumber;
  }

  //
  public set frontAndBackMiddleLineNumber(value: number) {
    this._frontAndBackMiddleLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 브레싱 형태
   */
  public get middleBracingType(): string {
    return this._middleBracingType;
  }

  //
  public set middleBracingType(value: string) {
    this._middleBracingType = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 브레싱 수량
   */
  public get middleBracingQuantity(): number {
    return this._middleBracingQuantity;
  }

  //
  public set middleBracingQuantity(value: number) {
    this._middleBracingQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_middleBracingTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 중방 브레싱 전체 수량
   */
  public get middleBracingTotalQuantity(): number {
    return this._middleBracingTotalQuantity;
  }
  //
  public set middleBracingTotalQuantity(value: number) {
    this._middleBracingTotalQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.middlePipeSample = new ColumnSampleVL_MiddlePipe();
    this.middleTrussDiagonalPipeSample = new ColumnSampleVL_MiddleTrussDiagonalPipe();
    this.middleTrussBeamCSectionSteelSample = new ColumnSampleVL_MiddleTrussBeamCSectionSteel();
    this.frontAndBackMiddlePipeSample = new ColumnSampleVL_FrontAndBackMiddlePipe();
    this.middleBracingRoundBarSample = new ColumnSampleVL_MiddleBracingRoundBar();
    this.middleBracingPipeSample = new ColumnSampleVL_MiddleBracingPipe();

    this.sampleAC = [
      this.middlePipeSample,
      this.middleTrussDiagonalPipeSample,
      this.middleTrussBeamCSectionSteelSample,
      this.frontAndBackMiddlePipeSample,
      this.middleBracingRoundBarSample,
      this.middleBracingPipeSample,
    ];

    // 아이템
    this.middle_pipe = new ItemPipe();
    this.middleTrussLower_pipe = new ItemPipe();
    this.middleTrussDiagonal_pipe = new ItemPipe();
    this.middleTrussBeam_cSectionSteel = new ItemPipe();
    this.middleTruss_bracket = new ItemBracket();
    this.middleTruss_boltAndNut = new ItemFrameOther();
    this.smallColumnAndMiddleTruss_bracket = new ItemBracket();
    this.smallColumnAndMiddleTruss_boltAndNut = new ItemFrameOther();
    this.frontAndBackMiddle_pipe = new ItemPipe();
    this.middleBracing_roundBar = new ItemPipe();
    this.middleAndRoundBar_bracket = new ItemBracket();
    this.roundBarAndBracket_turnbuckle = new ItemFrameOther();
    this.middleAndBracket_boltAndNut = new ItemFrameOther();
    this.middleBracing_pipe = new ItemPipe();
    this.middle_welding = new ItemOtherExpense();

    this.itemAC = [
      this.middle_pipe,
      this.middleTrussLower_pipe,
      this.middleTrussDiagonal_pipe,
      this.middleTrussBeam_cSectionSteel,
      this.middleTruss_bracket,
      this.middleTruss_boltAndNut,
      this.smallColumnAndMiddleTruss_bracket,
      this.smallColumnAndMiddleTruss_boltAndNut,
      this.frontAndBackMiddle_pipe,
      this.middleBracing_roundBar,
      this.middleAndRoundBar_bracket,
      this.roundBarAndBracket_turnbuckle,
      this.middleAndBracket_boltAndNut,
      this.middleBracing_pipe,
      this.middle_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.middlePipeSample = <ColumnSampleVL_MiddlePipe>this.sampleAC[0];
    this.middleTrussDiagonalPipeSample = <ColumnSampleVL_MiddleTrussDiagonalPipe>this.sampleAC[1];
    this.middleTrussBeamCSectionSteelSample = <ColumnSampleVL_MiddleTrussBeamCSectionSteel>this.sampleAC[2];
    this.frontAndBackMiddlePipeSample = <ColumnSampleVL_FrontAndBackMiddlePipe>this.sampleAC[3];
    this.middleBracingRoundBarSample = <ColumnSampleVL_MiddleBracingRoundBar>this.sampleAC[4];
    this.middleBracingPipeSample = <ColumnSampleVL_MiddleBracingPipe>this.sampleAC[5];

    // 아이템
    this.middle_pipe = <ItemPipe>this.itemAC[0];
    this.middleTrussLower_pipe = <ItemPipe>this.itemAC[1];
    this.middleTrussDiagonal_pipe = <ItemPipe>this.itemAC[2];
    this.middleTrussBeam_cSectionSteel = <ItemPipe>this.itemAC[3];
    this.middleTruss_bracket = <ItemBracket>this.itemAC[4];
    this.middleTruss_boltAndNut = <ItemFrameOther>this.itemAC[5];
    this.smallColumnAndMiddleTruss_bracket = <ItemBracket>this.itemAC[6];
    this.smallColumnAndMiddleTruss_boltAndNut = <ItemFrameOther>this.itemAC[7];
    this.frontAndBackMiddle_pipe = <ItemPipe>this.itemAC[8];
    this.middleBracing_roundBar = <ItemPipe>this.itemAC[9];
    this.middleAndRoundBar_bracket = <ItemBracket>this.itemAC[10];
    this.roundBarAndBracket_turnbuckle = <ItemFrameOther>this.itemAC[11];
    this.middleAndBracket_boltAndNut = <ItemFrameOther>this.itemAC[12];
    this.middleBracing_pipe = <ItemPipe>this.itemAC[13];
    this.middle_welding = <ItemOtherExpense>this.itemAC[14];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param trussDiagonalLength: number 중방 트러스 사재 길이
   * @param trussDiagonalQuantity: number 중방 트러스 사재 수량
   * @param frontAndBackMiddleLineNumber: number 전후면 중방 줄수
   * @param middleBracingType: string 중방 브레싱 형태
   * @param middleBracingQuantity: number 중방 브레싱 수량
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    trussSelected: boolean = false,
    trussDiagonalLength: number = 0,
    trussDiagonalQuantity: number = 0,
    frontAndBackMiddleLineNumber: number = 0,
    middleBracingType: string = "",
    middleBracingQuantity: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._trussDiagonalLength = trussDiagonalLength;
    this._trussDiagonalQuantity = trussDiagonalQuantity;
    this._frontAndBackMiddleLineNumber = frontAndBackMiddleLineNumber;
    this._middleBracingType = middleBracingType;
    this._middleBracingQuantity = middleBracingQuantity;

    // 샘플
    if (this.level.index >= 0) {
      this.middlePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "각형, 일반, 75×45mm, 2.3T, 8m, -",
      );
      this.middleTrussDiagonalPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 사재",
        "각형, 일반, 25×25mm, 1.4T, 0.7m, -",
      );
      this.middleTrussBeamCSectionSteelSample.setDefaultData(
        2,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "중방 트러스 보",
        "각형, 일반, 75×45mm, 2.1T, 10m, -",
      );
      this.frontAndBackMiddlePipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "전후면 중방",
        "각형, 일반, 125×75mm, 2.1T, 8m, -",
      );
      this.middleBracingRoundBarSample.setDefaultData(
        4,
        CONST.ITEM_ID_ROUND_BAR,
        CONST.ITEM_NAME_ROUND_BAR,
        "중방 브레싱",
        "환봉, 일반, 12mm, -, 6m, -",
      );
      this.middleBracingPipeSample.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 브레싱",
        "각형, 일반, 75×45mm, 2.3T, 8m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.middle_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 트러스 상현재",
        "각형, 일반, 75×45mm, 2.3T, 8m, -",
      );
      this.middleTrussLower_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 트러스 하현재",
        "각형, 일반, 75×45mm, 2.3T, 8m, -",
      );
      this.middleTrussDiagonal_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 트러스 사재",
        "각형, 일반, 25×25mm, 1.4T, 0.7m, -",
      );
      this.middleTrussBeam_cSectionSteel.setDefaultData(
        3,
        CONST.ITEM_ID_C_SECTION_STEEL,
        CONST.ITEM_NAME_C_SECTION_STEEL,
        "중방 트러스 보",
        "각형, 일반, 75×45mm, 2.1T, 10m, -",
      );
      this.middleTruss_bracket.setDefaultData(
        4,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "중방 트러스",
        "일반, 9T, 75×600mm",
      );
      this.middleTruss_boltAndNut.setDefaultData(
        5,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "중방 트러스",
        "육각, M16*180mm",
      );
      this.smallColumnAndMiddleTruss_bracket.setDefaultData(
        6,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "중방 트러스 + 소기둥",
        "일반, 9T, 75×45mm",
      );
      this.smallColumnAndMiddleTruss_boltAndNut.setDefaultData(
        7,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "중방 트러스 + 소기둥",
        "육각, M16*180mm",
      );
      this.frontAndBackMiddle_pipe.setDefaultData(
        8,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "전후면 중방",
        "각형, 일반, 125×75mm, 2.1T, 8m, -",
      );
      this.middleBracing_roundBar.setDefaultData(
        9,
        CONST.ITEM_ID_ROUND_BAR,
        CONST.ITEM_NAME_ROUND_BAR,
        "중방 브레싱",
        "환봉, 일반, 12mm, -, 6m, -",
      );
      this.middleAndRoundBar_bracket.setDefaultData(
        10,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "중방 브레싱",
        "일반, 9T, 75×45mm",
      );
      this.roundBarAndBracket_turnbuckle.setDefaultData(
        11,
        CONST.ITEM_ID_TURNBUCKLE,
        CONST.ITEM_NAME_TURNBUCKLE,
        "중방 브레싱",
        "12mm",
      );
      this.middleAndBracket_boltAndNut.setDefaultData(
        12,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "중방 트러스",
        "육각, M16*180mm",
      );
      this.middleBracing_pipe.setDefaultData(
        13,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 브레싱",
        "각형, 일반, 75×45mm, 2.3T, 8m, -",
      );
      this.middle_welding.setDefaultData(14, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "중방", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_middleLength();
    this.algorithm_middleLineNumber();
    this.algorithm_middleBracingTotalQuantity();
    this.frontAndBackMiddlePipeSample.algorithmSpec_specLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////
    const columnPoisiton: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;

    /// //////// 선택, 가시성 ///////////

    this.middle_pipe.selected = true;
    this.middle_pipe.visible = true;
    this.middleTrussLower_pipe.selected = true;
    this.middleTrussLower_pipe.visible = true;
    this.middleTrussDiagonal_pipe.selected = true;
    this.middleTrussDiagonal_pipe.visible = true;
    this.middleTrussBeam_cSectionSteel.selected = true;
    this.middleTrussBeam_cSectionSteel.visible = true;
    this.middleTruss_bracket.selected = true;
    this.middleTruss_bracket.visible = true;
    this.middleTruss_boltAndNut.selected = true;
    this.middleTruss_boltAndNut.visible = true;
    this.smallColumnAndMiddleTruss_bracket.selected = true;
    this.smallColumnAndMiddleTruss_bracket.visible = true;
    this.smallColumnAndMiddleTruss_boltAndNut.selected = true;
    this.smallColumnAndMiddleTruss_boltAndNut.visible = true;
    this.frontAndBackMiddle_pipe.selected = true;
    this.frontAndBackMiddle_pipe.visible = true;
    if (this.middleBracingType === CONST.LB_COLUMN_MIDDLE_BRACING_TYPE_X_BRACING) {
      this.middleBracing_roundBar.selected = true;
      this.middleBracing_roundBar.visible = true;
      this.middleAndRoundBar_bracket.selected = true;
      this.middleAndRoundBar_bracket.visible = true;
      this.roundBarAndBracket_turnbuckle.selected = true;
      this.roundBarAndBracket_turnbuckle.visible = true;
      this.middleAndBracket_boltAndNut.selected = true;
      this.middleAndBracket_boltAndNut.visible = true;
      this.middleBracing_pipe.selected = false;
      this.middleBracing_pipe.visible = false;
    } else {
      this.middleBracing_pipe.selected = true;
      this.middleBracing_pipe.visible = true;
      this.middleBracing_roundBar.selected = false;
      this.middleBracing_roundBar.visible = false;
      this.middleAndRoundBar_bracket.selected = false;
      this.middleAndRoundBar_bracket.visible = false;
      this.roundBarAndBracket_turnbuckle.selected = false;
      this.roundBarAndBracket_turnbuckle.visible = false;
      this.middleAndBracket_boltAndNut.selected = false;
      this.middleAndBracket_boltAndNut.visible = false;
    }
    this.middleAndBracket_boltAndNut.selected = true;
    this.middleAndBracket_boltAndNut.visible = true;
    this.middle_welding.selected = true;
    this.middle_welding.visible = true;

    /// //////// 수량 ///////////

    this.middle_pipe.designQuantity =
      (this.middle_pipe.getConnectedPipeLength(this.middleLength, this.middleLineNumber) /
        this.middle_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_MIDDLE_PIPE;

    this.middleTrussLower_pipe.designQuantity =
      (this.middleTrussLower_pipe.getConnectedPipeLength(this.middleLength, this.middleLineNumber) /
        this.middleTrussLower_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_MIDDLE_PIPE;

    this.middleTrussDiagonal_pipe.designQuantity =
      this.trussDiagonalQuantity *
      this.middleLineNumber *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_MIDDLE_TRUSS_DIAGONAL_PIPE;

    this.middleTrussBeam_cSectionSteel.designQuantity =
      (this.middleTrussBeam_cSectionSteel.getConnectedPipeLength(this.basicLevel.length, this.basic.buildingNumber) /
        this.middleTrussBeam_cSectionSteel.specLength) *
      CONST.NUM_EXTRA_RATE_MIDDLE_PIPE;

    this.middleTruss_bracket.designQuantity = this.middleLineNumber * 2 * CONST.NUM_EXTRA_RATE_BRACKET;

    this.middleTruss_boltAndNut.designQuantity = this.middleLineNumber * 4 * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.smallColumnAndMiddleTruss_bracket.designQuantity =
      columnPoisiton.prismColumnPart.smallColumnQuantity * CONST.NUM_EXTRA_RATE_BRACKET;

    this.smallColumnAndMiddleTruss_boltAndNut.designQuantity =
      columnPoisiton.prismColumnPart.smallColumnQuantity * 4 * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.frontAndBackMiddle_pipe.designQuantity = this.frontAndBackMiddleLineNumber * 2 * this.basic.buildingNumber;

    this.middleBracing_roundBar.designQuantity = this.middleBracingTotalQuantity * 2;

    this.middleAndRoundBar_bracket.designQuantity =
      this.middleBracing_roundBar.getSelectedQuantity() * 2 * CONST.NUM_EXTRA_RATE_BRACKET;

    this.roundBarAndBracket_turnbuckle.designQuantity =
      this.middleBracing_roundBar.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_TURNBUCKLE;

    this.middleAndBracket_boltAndNut.designQuantity =
      this.middleAndRoundBar_bracket.getSelectedQuantity() * 4 * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.middleBracing_pipe.designQuantity = this.middleBracingTotalQuantity;

    this.middle_welding.designQuantity =
      this.middleTrussDiagonal_pipe.getSelectedQuantity() * 2 +
      this.middleTrussBeam_cSectionSteel.getSelectedQuantity() * 2 +
      this.frontAndBackMiddle_pipe.getSelectedQuantity() * 2 +
      this.middleBracing_pipe.getSelectedQuantity() * 2;
  }

  /**
   * 알고리즘: 중방 길이 <- 폭(기본정보), 동수(기본정보)
   */
  public algorithm_middleLength(): void {
    this.middleLength = this.basicLevel.width * this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 중방 줄수 <- 기둥 간격, 길이(기본정보)
   */
  public algorithm_middleLineNumber(): void {
    this.middleLineNumber =
      Math.ceil(this.basicLevel.length / (<ColumnPositionVL>this.position).prismColumnPart.columnInterval) - 1;
  }

  /**
   * 알고리즘: 중방 브레싱 전체 수량 <- 중방 브레싱 수량, 하우스 동수(기본정보)
   */
  public algorithm_middleBracingTotalQuantity(): void {
    this.middleBracingTotalQuantity = this.middleBracingQuantity * this.basic.buildingNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [중방 트러스 상현재] 파이프 <- 중방 파이프 샘플
   */
  public algorithmSpec_middle_pipe(): void {
    this.middle_pipe.specs = this.middlePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 트러스 하현재] 파이프 <- 중방 파이프 샘플
   */
  public algorithmSpec_middleTrussLower_pipe(): void {
    this.middleTrussLower_pipe.specs = this.middlePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 트러스] 파이프 <- 중방 트러스 사재 파이프 샘플
   */
  public algorithmSpec_middleTrussDiagonal_pipe(): void {
    this.middleTrussDiagonal_pipe.specs = this.middleTrussDiagonalPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 트러스 보] C형강 <- 중방 트러스 보 C형강 샘플
   */
  public algorithmSpec_middleTrussBeam_cSectionSteel(): void {
    // this.middleTrussBeam_cSectionSteel.specs = this.middleTrussBeamCSectionSteelSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 트러스] 브라켓트
   */
  public algorithmSpec_middleTruss_bracket(): void {}

  /**
   * 규격 알고리즘: [중방 트러스] 볼트&너트
   */
  public algorithmSpec_middleTruss_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [중방 트러스 + 소기둥] 브라켓트
   */
  public algorithmSpec_smallColumnAndMiddleTruss_bracket(): void {}

  /**
   * 규격 알고리즘: [중방 트러스 + 소기둥] 볼트&너트
   */
  public algorithmSpec_smallColumnAndMiddleTruss_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [전후면 중방] 파이프 <- 전후면 중 파이프 샘플
   */
  public algorithmSpec_frontAndBackMiddle_pipe(): void {
    this.frontAndBackMiddle_pipe.specs = this.frontAndBackMiddlePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 브레싱] 환봉 <- 중방 브레싱 환봉 샘플
   */
  public algorithmSpec_middleBracing_roundBar(): void {
    this.middleBracing_roundBar.specs = this.middleBracingRoundBarSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 브레싱] 브라켓트
   */
  public algorithmSpec_middleAndRoundBar_bracket(): void {}

  /**
   * 규격 알고리즘: [중방 브레싱] 턴버클
   */
  public algorithmSpec_roundBarAndBracket_turnbuckle(): void {
    this.roundBarAndBracket_turnbuckle.specs = this.middleBracingRoundBarSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [중방 브레싱] 볼트&너트
   */
  public algorithmSpec_middleAndBracket_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [중방 브레싱] 파이프 <- 중방 브레싱 파이프 샘플
   */
  public algorithmSpec_middleBracing_pipe(): void {
    this.middleBracing_pipe.specs = this.middleBracingPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방] 용접 <- 공통 샘플
   */
  public algorithmSpec_middle_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
