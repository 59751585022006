import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWood } from "vhows-design/src/object/design/item/list/ItemWood";
import { PartitionLevel } from "vhows-design/src/object/design/other/partition/PartitionLevel";
import { PartitionPosition } from "vhows-design/src/object/design/other/partition/PartitionPosition";
import { WindowLevel } from "vhows-design/src/object/design/other/window/WindowLevel";
import { ScreennetPosition } from "vhows-design/src/object/design/cover/screennet/ScreennetPosition";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { WindbreakPosition } from "vhows-design/src/object/design/cover/windbreak/WindbreakPosition";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";
import { ScreennetPart_FixingWood } from "vhows-design/src/object/design/cover/screennet/ScreennetPart_FixingWood";
import { SkirtPart_FixingWood } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingWood";
import { WindbreakPart_FixingWood } from "vhows-design/src/object/design/cover/windbreak/WindbreakPart_FixingWood";
import { FixingPart_Wood } from "vhows-design/src/object/design/cover/fixing/FixingPart_Wood";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-25
 */
@jsonObject
export class FixingSample_RectLumber extends ItemWood {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const woodPart: FixingPart_Wood = <FixingPart_Wood>this.part;
    woodPart.algorithmSpec_wood_rectLumber();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<FixingPosition>this.level.positionAC[p]).woodPart.rectLumberSample.specs = this.specs;
    }

    // 다른 중수
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        // 해당 중수의 측면 위치만 바꿔줘도 전체 위치의 규격이 바뀌게됨
        (<FixingLevel>this.work.levelAC[l]).sidePosition.woodPart.rectLumberSample.specs = this.specs;
      }
    }

    /// //////// 외부 ///////////

    // 치마 피복
    const skirtPart_FixingWood: SkirtPart_FixingWood = (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingWoodPart;
    skirtPart_FixingWood.rectLumberSample.algorithmSpec();

    // 바람막이 피복
    const windbreakPart_FixingWood: WindbreakPart_FixingWood = (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingWoodPart;
    windbreakPart_FixingWood.rectLumberSample.algorithmSpec();

    // 방충망
    if (this.level.index === 0) {
      const screennetPart_FixingWood: ScreennetPart_FixingWood = (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.position.index]
      )).fixingWoodPart;
      screennetPart_FixingWood.rectLumberSample.algorithmSpec();
    }

    // 창문
    if (this.level.index === 0) {
      const windowLevel: WindowLevel = <WindowLevel>this.struct.windowWork.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_SIDE) {
        windowLevel.leftPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
        windowLevel.rightPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        windowLevel.frontPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        windowLevel.backPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
      }
    }

    // 칸막이
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        const partitionPosition: PartitionPosition = (<PartitionLevel>(
          this.struct.partitionWork.levelAC[this.level.index]
        )).trunkPosition;
        partitionPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
