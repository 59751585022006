import { jsonObject } from "typedjson";

import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { ControllerPartIL_Controller } from "vhows-design/src/object/design/switches/controller/il/ControllerPartIL_Controller";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-09
 */
@jsonObject
export class ControllerSampleIL_MotorControlElectricWire extends ItemElectricWire {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const controllerPart: ControllerPartIL_Controller = <ControllerPartIL_Controller>this.part;

    // 파트 알고리즘 호출
    // part.algorithmPart();

    /// //////// 외부 ///////////

    // 커튼 개폐
    for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
      for (const curSwitchPosition of curSwitchLevel.positionAC as CurSwitchPositionIL[]) {
        curSwitchPosition.motorPart.algorithmSpec_motorControl_electricWire();
      }
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 감속 모터 샘플(커튼 개폐)
   */
  // @override
  public algorithmSpec(): void {
    // specInternalLine = InterlockStructure(struct).curSwitchWork.level1.ceilingPosition.motorPart.reducedMotorSample.specInternalLine;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
