import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Part } from "vhows-design/src/object/design/base/Part";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { CoverTemp } from "vhows-design/src/object/design/base/CoverTemp";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { SkyCoverPositionIL } from "vhows-design/src/object/design/skylight/cover/il/SkyCoverPositionIL";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-29
 */
@jsonObject
export class CoverPartIL extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  protected coverPosition: CoverPositionIL;
  protected coverEdgeRoof: CoverTemp; // 양끝동(또는 1동) 지붕(+측면) 피복
  protected coverEdgeSkyShort: CoverTemp; // 양끝동(또는 1동) 지붕(+측면) 피복 - 비대칭 천창(짧은쪽)
  protected coverEdgeSide: CoverTemp; // 양끝동(또는 1동) 측면 피복
  protected coverCenterRoof: CoverTemp; // 중앙동 지붕 피복
  protected coverCenterSkyShort: CoverTemp; // 중앙동 지붕 피복 - 비대칭 천창(짧은쪽)
  protected coverFront: CoverTemp; // 앞면 피복
  protected coverBack: CoverTemp; // 뒷면 피복
  // 외부
  protected columnPosition: ColumnPositionIL | ColumnPositionVL;
  protected wingPosition: WingPositionIL | WingPositionVL;
  protected roofPosition: RoofPositionIL | RoofPositionVL;
  protected endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
  protected skirtLevel: SkirtLevelIL;
  protected windbreakLevel: WindbreakLevelIL;
  protected switcherLevel: SwitcherLevelIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 기본

  protected endpieceIncludedExceed: number;
  protected cashmilonStyleOverlap: number;

  // 단위값

  /** 단위 기둥 폭(개폐안함) */
  protected unitColumnWidthClose: number;
  /** 단위 기둥 폭(개폐) */
  protected unitColumnWidthOpen: number;
  /** 단위 기둥 길이 */
  protected unitColumnLength: number;
  /** 단위 기둥 길이 AC */
  protected unitColumnLengthAC: number[];

  /** 단위 방풍벽 폭(개폐안함) */
  protected unitWingWidthClose: number;
  /** 단위 방풍벽 폭(개폐) */
  protected unitWingWidthOpen: number;
  /** 단위 방풍벽 길이 */
  protected unitWingLength: number;
  /** 단위 방풍벽 길이 AC */
  protected unitWingLengthAC: number[];

  /** 단위 측면 폭 + 측면 치마 단수용 */
  protected unitSideWidth_sideSkirtStep: number;

  /** 단위 지붕 양끝 폭(개폐안함) */
  protected unitRoofEdgeWidthClose: number;
  /** 단위 지붕 양끝 폭(개폐) */
  protected unitRoofEdgeWidthOpen: number;
  /** 단위 지붕 양끝 길이 */
  protected unitRoofEdgeLength: number;
  /** 단위 지붕 양끝 길이 AC */
  protected unitRoofEdgeLengthAC: number[];
  /** 단위 지붕 중앙 폭(개폐안함) */
  protected unitRoofCenterWidthClose: number;
  /** 단위 지붕 중앙 폭(개폐) */
  protected unitRoofCenterWidthOpen: number;
  /** 단위 지붕 중앙 길이 */
  protected unitRoofCenterLength: number;
  /** 단위 지붕 중앙 길이 AC */
  protected unitRoofCenterLengthAC: number[];
  /** 단위 지붕 길이 + 마구리 출입문 */
  protected unitRoofLength_endpieceGate: number;
  /** 단위 지붕 길이 + 방풍벽 */
  protected unitRoofLength_endpieceWing: number;
  /** 단위 지붕 길이 + 마구리 바람막이 */
  protected unitRoofLength_endpieceWindbreak: number;
  /** 단위 지붕 길이 + 측면 바람막이 */
  protected unitRoofLength_sideWindbreak: number;
  /** 단위 지붕 길이 + 지붕 바람막이 */
  protected unitRoofLength_roofWindbreak: number;
  /** 단위 지붕 길이 + 앞면 처마 */
  protected unitRoofLength_eaveFront: number;
  /** 단위 지붕 길이 + 뒷면 처마 */
  protected unitRoofLength_eaveBack: number;

  /** 단위 마구리 하단 폭 */
  protected unitEndpieceLowerWidth: number;
  /** 단위 마구리 하단 길이 */
  protected unitEndpieceLowerLength: number;
  /** 단위 마구리 상단 폭 */
  protected unitEndpieceUpperWidth: number;
  /** 단위 마구리 상단 길이 */
  protected unitEndpieceUpperLength: number;
  /** 단위 마구리 길이 + 출입구 */
  protected unitEndpieceLength_endpieceGate: number;
  /** 단위 마구리 길이 + 방풍벽 */
  protected unitEndpieceLength_endpieceWing: number;
  /** 단위 마구리 길이 + 바람막이 */
  protected unitEndpieceLength_endpieceWindbreak: number;
  /** 단위 마구리 길이 + 측면 바람막이 */
  protected unitEndpieceLength_sideWindbreak: number;
  /** 단위 마구리 길이 + 지붕 바람막이 */
  protected unitEndpieceLength_roofWindbreak: number;

  // 실제값

  protected widthSideClose: number;
  protected widthSideOpen: number;
  protected lengthSide: number;
  protected lengthSideAC: number[];

  protected widthRoofEdgeClose: number;
  protected widthRoofEdgeOpen: number;
  protected widthRoofCenterClose: number;
  protected widthRoofCenterOpen: number;
  protected lengthRoof: number;
  protected lengthRoofAC: number[];

  protected lengthTrunk: number;
  protected lengthTrunkAC: number[];

  protected widthFront: number;
  protected lengthFront: number;
  protected widthBack: number;
  protected lengthBack: number;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    super.setReferenceVariable();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    this.coverPosition = <CoverPositionIL>this.position;
    this.coverEdgeSide = new CoverTemp();
    this.coverEdgeRoof = new CoverTemp();
    this.coverEdgeSkyShort = null;
    this.coverCenterRoof = new CoverTemp();
    this.coverCenterSkyShort = null;
    this.coverFront = new CoverTemp();
    this.coverBack = new CoverTemp();
    // 외부

    let rafterValleyQuantity: number = 0;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      this.columnPosition = this.struct.columnWorkIL.level1.columnPosition;
      this.wingPosition = (<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition;
      this.roofPosition = this.struct.roofWorkIL.level1.roofPosition;
      this.endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      this.columnPosition = this.struct.columnWorkVL.level1.columnPosition;
      this.wingPosition = (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition;
      this.roofPosition = this.struct.roofWorkVL.level1.roofPosition;
      this.endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
      rafterValleyQuantity = this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterValleyQuantity;
    }
    this.skirtLevel = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    this.windbreakLevel = <WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index];
    this.switcherLevel = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];
    // 기타
    let i: number;
    let basicBuilding: BasicBuilding;

    //----------------------------------
    // 수치 - 단위값
    //----------------------------------

    // 기본
    this.endpieceIncludedExceed = 0.25;
    this.cashmilonStyleOverlap = this.design.preference.cashmilonStyleOverlapLength;

    // 측면(기둥)
    if (this.level.index === 0 && this.wingPosition.selected) {
      this.unitColumnWidthClose = this.columnPosition.columnLength - this.basicLevel.distanceShoulderPad;
    } else {
      this.unitColumnWidthClose = this.columnPosition.columnLength;
    }
    if (this.design.preference.reduceOpenedCoverWidthFlag === true) {
      this.unitColumnWidthOpen = this.unitColumnWidthClose - 0.2;
    } else {
      this.unitColumnWidthOpen = this.unitColumnWidthClose;
    }
    this.unitColumnLength = this.basicLevel.length + 1;
    if (this.basic.isVariableLength === true) {
      this.unitColumnLengthAC = new Array();
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.unitColumnLengthAC.push(basicBuilding.length + 1.0);
      }
    }

    // 측면(방풍벽)
    if (this.level.index === 0) {
      this.unitWingWidthClose = this.wingPosition.wingRafterPart.wingRafterLength - this.basicLevel.distanceShoulderPad;
    } else {
      this.unitWingWidthClose = this.wingPosition.wingRafterPart.wingRafterLength;
    }
    if (this.design.preference.reduceOpenedCoverWidthFlag === true) {
      this.unitWingWidthOpen = this.unitWingWidthClose - 0.2;
    } else {
      this.unitWingWidthOpen = this.unitWingWidthClose;
    }
    this.unitWingLength = this.basicLevel.length + 1;
    if (this.basic.isVariableLength === true) {
      this.unitWingLengthAC = new Array();
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.unitWingLengthAC.push(basicBuilding.length + 1.0);
      }
    }

    // 측면 + @
    this.unitSideWidth_sideSkirtStep = 0.7;

    // 지붕
    this.unitRoofEdgeWidthClose =
      this.roofPosition.rafterPart.rafterTotalLength / 2 + this.basicLevel.distanceShoulderPad;
    this.unitRoofEdgeWidthOpen = this.unitRoofEdgeWidthClose - this.basicLevel.distanceShoulderPad + 0.5;
    this.unitRoofEdgeLength = this.basicLevel.length + 1;
    if (this.basic.isVariableLength === true) {
      this.unitRoofEdgeLengthAC = new Array();
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.unitRoofEdgeLengthAC.push(basicBuilding.length + 1.0);
      }
    }
    this.unitRoofCenterWidthClose = this.roofPosition.rafterPart.rafterTotalLength / 2;
    if (this.design.preference.reduceOpenedCoverWidthFlag === true) {
      this.unitRoofCenterWidthOpen = this.unitRoofCenterWidthClose - 0.2;
    } else {
      this.unitRoofCenterWidthOpen = this.unitRoofCenterWidthClose;
    }
    this.unitRoofCenterLength = this.basicLevel.length + 1;
    if (this.basic.isVariableLength === true) {
      this.unitRoofCenterLengthAC = new Array();
      for (basicBuilding of this.basicLevel.basicBuildingAC) {
        this.unitRoofCenterLengthAC.push(basicBuilding.length + 1.0);
      }
    }

    // 지붕 + @
    this.unitRoofLength_endpieceGate = 0; // 반영 안함
    this.unitRoofLength_endpieceWing = 0; // 반영 안함
    this.unitRoofLength_endpieceWindbreak = 0; // 반영 안함
    this.unitRoofLength_sideWindbreak = 0; // 반영 안함
    if (this.level.index === 0) {
      this.unitRoofLength_roofWindbreak = this.roofPosition.rafterPart.rafterInterval * 2 + 0.6;
    } else {
      this.unitRoofLength_roofWindbreak = this.roofPosition.rafterPart.rafterInterval + 0.6;
    }
    if (this.level.index === 0) {
      if (
        this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        this.unitRoofLength_eaveFront = this.struct.roofWorkIL.level1.roofPosition.frontEaveLength;
        this.unitRoofLength_eaveBack = this.struct.roofWorkIL.level1.roofPosition.backEaveLength;
      }
    }

    // 마구리
    this.unitEndpieceLowerWidth = this.columnPosition.columnLength;
    this.unitEndpieceLowerLength = this.basicLevel.width * this.basic.buildingNumber + 1;
    this.unitEndpieceUpperWidth =
      this.basicLevel.heightMax + this.basicLevel.depthLaying - this.columnPosition.columnLength + 0.2;
    this.unitEndpieceUpperLength = this.unitEndpieceLowerLength;

    // 마구리 + @
    this.unitEndpieceLength_endpieceGate = 0.5; // 폭이 매우 넓기 때문에 길이를 짧게 더함
    this.unitEndpieceLength_endpieceWing = 2 + 0.3;
    this.unitEndpieceLength_endpieceWindbreak = 0; // 반영 안함
    if (this.level.index === 0) {
      this.unitEndpieceLength_sideWindbreak = this.roofPosition.rafterPart.rafterInterval * 2 + 0.6;
    } else {
      this.unitEndpieceLength_sideWindbreak = this.roofPosition.rafterPart.rafterInterval + 0.6;
    }
    if (this.level.index === 0) {
      this.unitEndpieceLength_roofWindbreak = this.roofPosition.rafterPart.rafterInterval * 2 + 0.6;
    } else {
      this.unitEndpieceLength_roofWindbreak = this.roofPosition.rafterPart.rafterInterval * 2 + 0.6;
    }

    //----------------------------------
    // 수치 - 실제값
    //----------------------------------

    // 앞면 마구리
    if (this.switcherLevel.frontPosition.selectedDetail(this.coverPosition) === false) {
      if (this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
        this.widthFront = this.unitEndpieceUpperWidth;
      } else {
        this.widthFront = this.unitEndpieceLowerWidth + this.unitEndpieceUpperWidth;
      }
    } else {
      this.widthFront = this.unitEndpieceLowerWidth; // 하단 폭으로 하되, 길이를 1.5배로 견적
    }
    this.lengthFront = this.unitEndpieceUpperLength;
    // 앞면 마구리 + 마구리 출입문
    if (
      this.endpieceLevel.frontPosition.selected &&
      (this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_HINGED) &&
      this.endpieceLevel.frontPosition.gateBuildingNumber > 0
    ) {
      this.lengthFront += this.unitEndpieceLength_endpieceGate * this.endpieceLevel.frontPosition.gateBuildingNumber;
      this.coverFront.gateQuantity = this.endpieceLevel.frontPosition.gateBuildingNumber;
    }
    // 앞면 마구리 + 마구리 방풍벽
    if (this.wingPosition.selected === true) {
      this.lengthFront += this.unitEndpieceLength_endpieceWing * 2;
      this.coverFront.wingSelected = true; // 체크는 하지만, 굳이 화면에 보여주지 않음
    }
    // 앞면 마구리 + 마구리 바람막이
    if (
      this.windbreakLevel.frontPosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.frontPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      this.lengthFront += this.unitEndpieceLength_endpieceWindbreak * 2;
      this.coverFront.windbreakIncluded = true;
    }

    // 뒷면 마구리
    if (this.switcherLevel.backPosition.selectedDetail(this.coverPosition) === false) {
      if (this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
        this.widthBack = this.unitEndpieceUpperWidth;
      } else {
        this.widthBack = this.unitEndpieceLowerWidth + this.unitEndpieceUpperWidth;
      }
    } else {
      this.widthBack = this.unitEndpieceLowerWidth; // 하단 폭으로 하되, 길이를 1.5배로 견적
    }
    this.lengthBack = this.unitEndpieceUpperLength;
    // 뒷면 마구리 + 마구리 출입문
    if (
      this.endpieceLevel.backPosition.selected &&
      (this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_HINGED) &&
      this.endpieceLevel.backPosition.gateBuildingNumber > 0
    ) {
      this.lengthBack += this.unitEndpieceLength_endpieceGate * this.endpieceLevel.backPosition.gateBuildingNumber;
      this.coverBack.gateQuantity = this.endpieceLevel.backPosition.gateBuildingNumber;
    }

    // 뒷면 마구리 + 마구리 방풍벽
    if (this.wingPosition.selected === true) {
      this.lengthBack += this.unitEndpieceLength_endpieceWing * 2;
      this.coverBack.wingSelected = true; // 체크는 하지만, 굳이 화면에 보여주지 않음
    }
    // 뒷면 마구리 + 마구리 바람막이
    if (
      this.windbreakLevel.backPosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.backPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      this.lengthBack += this.unitEndpieceLength_endpieceWindbreak * 2;
      this.coverBack.windbreakIncluded = true;
    }

    // 마구리 + 방풍벽 바람막이
    if (
      this.windbreakLevel.wingPosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.wingPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      this.lengthFront += this.unitEndpieceLength_sideWindbreak * 2;
      this.coverFront.windbreakIncluded = true;
      this.lengthBack += this.unitEndpieceLength_sideWindbreak * 2;
      this.coverBack.windbreakIncluded = true;
    } else if (
      this.windbreakLevel.columnPosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.columnPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      this.lengthFront += this.unitEndpieceLength_sideWindbreak * 2;
      this.coverFront.windbreakIncluded = true;
      this.lengthBack += this.unitEndpieceLength_sideWindbreak * 2;
      this.coverBack.windbreakIncluded = true;
    }
    // 마구리 + 지붕 바람막이
    if (
      this.windbreakLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
      this.windbreakLevel.roofPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
    ) {
      this.widthFront += this.unitEndpieceLength_roofWindbreak;
      this.coverFront.windbreakIncluded = true;
      this.widthBack += this.unitEndpieceLength_roofWindbreak;
      this.coverBack.windbreakIncluded = true;
    }

    // 측면
    if (this.coverPosition.scopeSelectedSideWing === true) {
      this.widthSideClose = this.unitWingWidthClose;
      this.widthSideOpen = this.unitWingWidthOpen;
      this.lengthSide = this.unitWingLength;
      if (this.basic.isVariableLength === true) {
        this.lengthSideAC = new Array();
        let unitWingLengthTemp: number;
        for (unitWingLengthTemp of this.unitWingLengthAC) {
          this.lengthSideAC.push(unitWingLengthTemp);
        }
      }
    } else if (this.coverPosition.scopeSelectedSideColumn === true) {
      this.widthSideClose = this.unitColumnWidthClose;
      this.widthSideOpen = this.unitColumnWidthOpen;
      this.lengthSide = this.unitColumnLength;
      if (this.basic.isVariableLength === true) {
        this.lengthSideAC = new Array();
        let unitColumnLengthTemp: number;
        for (unitColumnLengthTemp of this.unitColumnLengthAC) {
          this.lengthSideAC.push(unitColumnLengthTemp);
        }
      }
    } else {
      this.widthSideClose = 0;
      this.widthSideOpen = 0;
      this.lengthSide = 0;

      if (this.basic.isVariableLength === true) {
        this.lengthSideAC = new Array();
        for (i = 0; i < this.basic.buildingNumber; i++) {
          this.lengthSideAC.push(0);
        }
      }
    }

    // 지붕
    this.widthRoofEdgeClose = this.unitRoofEdgeWidthClose;
    this.widthRoofEdgeOpen = this.unitRoofEdgeWidthOpen;
    this.widthRoofCenterClose = this.unitRoofCenterWidthClose;
    this.widthRoofCenterOpen = this.unitRoofCenterWidthOpen;
    this.lengthRoof = this.unitRoofEdgeLength;
    if (this.basic.isVariableLength === true) {
      this.lengthRoofAC = new Array();
      let unitRoofEdgeLengthTemp: number;
      for (unitRoofEdgeLengthTemp of this.unitRoofEdgeLengthAC) {
        this.lengthRoofAC.push(unitRoofEdgeLengthTemp);
      }
    }

    // 지붕 + 앞면 마구리
    if (this.coverPosition.scopeSelectedFrontIncluded === true) {
      // 지붕 + 앞면 마구리
      this.lengthRoof += this.widthFront - this.endpieceIncludedExceed;
      this.coverEdgeRoof.frontIncluded = true;
      if (this.basic.isVariableLength === true) {
        for (i = 0; i < this.lengthRoofAC.length; i++) {
          this.lengthRoofAC[i] += this.widthFront - this.endpieceIncludedExceed;
        }
      }

      // 지붕 + 앞면 마구리 출입문
      if (
        this.endpieceLevel.frontPosition.selected === true &&
        (this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
          this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_HINGED) &&
        this.endpieceLevel.frontPosition.gateBuildingNumber > 0
      ) {
        this.lengthRoof += this.unitRoofLength_endpieceGate;
        this.coverEdgeRoof.gateQuantity = this.endpieceLevel.frontPosition.gateBuildingNumber;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceGate;
          }
        }
      }

      // 지붕 + 앞면 마구리 방풍벽
      if (this.wingPosition.selected === true) {
        this.lengthRoof += this.unitRoofLength_endpieceWing * 2;
        this.coverEdgeRoof.wingSelected = true; // 체크는 하지만, 굳이 화면에 보여주지 않음
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceWing * 2;
          }
        }
      }
      // 지붕 + 앞면 마구리 바람막이
      if (
        this.windbreakLevel.frontPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.frontPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_endpieceWindbreak * 2;
        this.coverEdgeRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceWindbreak * 2;
          }
        }
      }
      // 지붕 + 방풍벽 바람막이
      if (
        this.windbreakLevel.wingPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.wingPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_sideWindbreak;
        this.coverEdgeRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_sideWindbreak;
          }
        }
      } else if (
        this.windbreakLevel.columnPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.columnPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_sideWindbreak;
        this.coverEdgeRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_sideWindbreak;
          }
        }
      }
      // 지붕 + 지붕 바람막이
      if (
        this.windbreakLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.roofPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_roofWindbreak;
        this.coverEdgeRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_roofWindbreak;
          }
        }
      }
    }
    // 지붕 + 뒷면 마구리
    if (this.coverPosition.scopeSelectedBackIncluded === true) {
      // 지붕 + 뒷면 마구리
      this.lengthRoof += this.widthBack - this.endpieceIncludedExceed;
      this.coverEdgeRoof.backIncluded = true;
      if (this.basic.isVariableLength === true) {
        for (i = 0; i < this.lengthRoofAC.length; i++) {
          this.lengthRoofAC[i] += this.widthBack - this.endpieceIncludedExceed;
        }
      }

      // 지붕 + 뒷면 마구리 출입문
      if (
        this.endpieceLevel.backPosition.selected === true &&
        (this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
          this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_HINGED) &&
        this.endpieceLevel.backPosition.gateBuildingNumber > 0
      ) {
        this.lengthRoof += this.unitRoofLength_endpieceGate;
        this.coverEdgeRoof.gateQuantity = this.endpieceLevel.backPosition.gateBuildingNumber;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceGate;
          }
        }
      }

      // 지붕 + 뒷면 마구리 방풍벽
      if (this.wingPosition.selected === true) {
        this.lengthRoof += this.unitRoofLength_endpieceWing * 2;
        this.coverEdgeRoof.wingSelected = true; // 체크는 하지만, 굳이 화면에 보여주지 않음
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceWing * 2;
          }
        }
      }
      // 지붕 + 뒷면 마구리 바람막이
      if (
        this.windbreakLevel.backPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.backPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_endpieceWindbreak * 2;
        this.coverEdgeRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_endpieceWindbreak * 2;
          }
        }
      }
      // 지붕 + 방풍벽 바람막이
      if (
        this.windbreakLevel.wingPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.wingPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_sideWindbreak;
        this.coverEdgeRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_sideWindbreak;
          }
        }
      } else if (
        this.windbreakLevel.columnPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.columnPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_sideWindbreak;
        this.coverEdgeRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_sideWindbreak;
          }
        }
      }
      // 지붕 + 지붕 바람막이
      if (
        this.windbreakLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
        this.windbreakLevel.roofPosition.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER
      ) {
        this.lengthRoof += this.unitRoofLength_roofWindbreak;
        this.coverEdgeRoof.windbreakIncluded = true;
        if (this.basic.isVariableLength === true) {
          for (i = 0; i < this.lengthRoofAC.length; i++) {
            this.lengthRoofAC[i] += this.unitRoofLength_roofWindbreak;
          }
        }
      }
    }
    // 지붕 + 앞면 처마
    if (this.unitRoofLength_eaveFront > 0) {
      this.lengthRoof += this.unitRoofLength_eaveFront;
      this.coverEdgeRoof.eaveSelected = true;
      this.coverCenterRoof.eaveSelected = true;
    }
    // 지붕 + 뒷면 처마
    if (this.unitRoofLength_eaveBack > 0) {
      this.lengthRoof += this.unitRoofLength_eaveBack;
      this.coverEdgeRoof.eaveSelected = true;
      this.coverCenterRoof.eaveSelected = true;
    }

    // 측면+지붕
    this.lengthTrunk = this.lengthSide;
    if (this.lengthSide < this.lengthRoof) {
      // 더 큰값
      this.lengthTrunk = this.lengthRoof;
    }
    if (this.basic.isVariableLength === true) {
      this.lengthTrunkAC = new Array();
      let lengthSideTemp: number;
      for (lengthSideTemp of this.lengthSideAC) {
        this.lengthTrunkAC.push(lengthSideTemp);
      }
      for (i = 0; i < this.lengthTrunkAC.length; i++) {
        if (this.lengthTrunkAC[i] < this.lengthRoofAC[i]) {
          this.lengthTrunkAC[i] = this.lengthRoofAC[i];
        }
      }
    }

    //----------------------------------
    // 용도 및 수치 - 피복객체값
    // 피복범위, 개폐여부, 개폐방향에 따른 값을 계산하여 피복객체에 할당함
    //----------------------------------

    /// /////////////////////////////////
    // 피복범위: 지붕+측면 || 지붕+측면+마구리
    if (this.coverPosition.scopeSelectedRoof === true && this.coverPosition.scopeSelectedSide === true) {
      const switchStep = this.switcherLevel.wingPosition.switchStep;
      // 개폐여부: 1.지붕(고정)+측면(고정)
      if (
        this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === false &&
        this.switcherLevel.wingPosition.selectedDetail(this.coverPosition) === false &&
        this.switcherLevel.columnPosition.selectedDetail(this.coverPosition) === false
      ) {
        if (this.basic.buildingNumber >= 2) {
          if (this.level.index === 0) {
            if (
              this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
              this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
            ) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕+측면";
              this.coverEdgeRoof.width = this.widthSideClose + this.widthRoofEdgeClose + this.widthRoofCenterClose;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕+측면";
              this.coverEdgeRoof.width =
                this.widthSideClose +
                this.widthRoofEdgeClose +
                this.widthRoofCenterClose * (2 * rafterValleyQuantity - 1);
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕";
              this.coverCenterRoof.width =
                (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            if (
              this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
              this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
            ) {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕";
              this.coverCenterRoof.width =
                (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          }
        } else {
          if (this.level.index === 0) {
            if (
              this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
              this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
            ) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕+측면";
              this.coverEdgeRoof.width = (this.widthSideClose + this.widthRoofEdgeClose) * 2;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕+측면";
              this.coverEdgeRoof.width =
                (this.widthSideClose + this.widthRoofEdgeClose + this.widthRoofCenterClose) * rafterValleyQuantity;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          } else {
            if (
              this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
              this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
            ) {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofEdgeClose;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = (this.widthRoofEdgeClose + this.widthRoofEdgeClose) * rafterValleyQuantity;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        }
      } else if (
        this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === false &&
        (this.switcherLevel.wingPosition.selectedDetail(this.coverPosition) === true ||
          this.switcherLevel.columnPosition.selectedDetail(this.coverPosition) === true)
      ) {
        // 개폐방향: 2-1.지붕(고정)+측면(편측개폐)
        if (this.switcherLevel.wingPosition.switchWayValue === 1) {
          // 지붕(고정)+측면(편측개폐)
          if (this.basic.buildingNumber >= 2) {
            // 2동이상
            if (this.level.index === 0) {
              // 1중
              if (
                this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
                this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
              ) {
                if (this.switcherLevel.wingPosition.switchStep <= 1) {
                  this.coverEdgeSide = null;
                } else {
                  this.coverEdgeSide.purpose = "측면(편측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1;
                }
                this.coverEdgeRoof.purpose = "지붕+측면(편측개폐)";
                this.coverEdgeRoof.width =
                  this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofCenterClose;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof.purpose = "지붕";
                this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
                this.coverCenterRoof.length = this.lengthTrunk;
                this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
                this.coverCenterRoof.quantity = 1;
              } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
                if (this.switcherLevel.wingPosition.switchStep <= 1) {
                  this.coverEdgeSide = null;
                } else {
                  this.coverEdgeSide.purpose = "측면(편측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1;
                }
                this.coverEdgeRoof.purpose = "지붕+측면(편측개폐)";
                this.coverEdgeRoof.width =
                  this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofCenterClose * 3;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof.purpose = "지붕";
                this.coverCenterRoof.width =
                  (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
                this.coverCenterRoof.length = this.lengthTrunk;
                this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
                this.coverCenterRoof.quantity = 1;
              }
            } else {
              // 2중이상
              if (
                this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
                this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
              ) {
                if (this.switcherLevel.wingPosition.switchStep <= 1) {
                  this.coverEdgeSide.purpose = "측면(편측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1;
                } else {
                  this.coverEdgeSide.purpose = "측면(편측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1 * switchStep;
                }
                this.coverEdgeRoof.purpose = "지붕";
                this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof.purpose = "지붕";
                this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
                this.coverCenterRoof.length = this.lengthTrunk;
                this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
                this.coverCenterRoof.quantity = 1;
              } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
                if (this.switcherLevel.wingPosition.switchStep <= 1) {
                  this.coverEdgeSide.purpose = "측면(편측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1;
                } else {
                  this.coverEdgeSide.purpose = "측면(편측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1 * switchStep;
                }
                this.coverEdgeRoof.purpose = "지붕";
                this.coverEdgeRoof.width =
                  (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof.purpose = "지붕";
                this.coverCenterRoof.width =
                  (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
                this.coverCenterRoof.length = this.lengthTrunk;
                this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
                this.coverCenterRoof.quantity = 1;
              }
            }
          } else {
            // 1동만
            if (this.level.index === 0) {
              // 1중
              if (
                this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
                this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
              ) {
                if (this.switcherLevel.wingPosition.switchStep > 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = (this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep) * 2;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1 * switchStep;
                } else {
                  this.coverEdgeSide = null;
                }
                this.coverEdgeRoof.purpose = "지붕+측면(양측개폐)";
                this.coverEdgeRoof.width = (this.widthSideOpen / switchStep + this.widthRoofEdgeClose) * 2;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof = null;
              } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
                if (this.switcherLevel.wingPosition.switchStep > 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = (this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep) * 2;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1 * switchStep;
                } else {
                  this.coverEdgeSide = null;
                }
                this.coverEdgeRoof.purpose = "지붕+측면(양측개폐)";
                this.coverEdgeRoof.width =
                  (this.widthSideClose + this.widthRoofEdgeClose + this.widthRoofCenterClose) * rafterValleyQuantity;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof = null;
              }
            } else {
              // 2중이상
              if (
                this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
                this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
              ) {
                if (this.switcherLevel.wingPosition.switchStep > 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = (this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep) * 2;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 2 * switchStep;
                } else {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 2;
                }
                this.coverEdgeRoof.purpose = "지붕";
                this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofEdgeClose;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof = null;
              } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
                if (this.switcherLevel.wingPosition.switchStep > 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = (this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep) * 2;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 2 * switchStep;
                } else {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 2;
                }
                this.coverEdgeRoof.purpose = "지붕";
                this.coverEdgeRoof.width =
                  this.widthRoofEdgeClose + this.widthRoofEdgeClose + this.widthRoofCenterClose * rafterValleyQuantity;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof = null;
              }
            }
          }
        } else if (this.switcherLevel.wingPosition.switchWayValue === 2) {
          // 지붕(고정)+측면(양측개폐)
          if (this.basic.buildingNumber >= 2) {
            // 2동이상
            if (this.level.index === 0) {
              // 1중
              if (
                this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
                this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
              ) {
                if (this.switcherLevel.wingPosition.switchStep <= 1) {
                  this.coverEdgeSide = null;
                } else {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1;
                }
                this.coverEdgeRoof.purpose = "지붕+측면(양측개폐)";
                this.coverEdgeRoof.width =
                  this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofCenterClose;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof.purpose = "지붕";
                this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
                this.coverCenterRoof.length = this.lengthTrunk;
                this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
                this.coverCenterRoof.quantity = 1;
              } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
                if (this.switcherLevel.wingPosition.switchStep <= 1) {
                  this.coverEdgeSide = null;
                } else {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1;
                }
                this.coverEdgeRoof.purpose = "지붕+측면(양측개폐)";
                this.coverEdgeRoof.width =
                  this.widthSideOpen / switchStep +
                  this.widthRoofEdgeClose +
                  this.widthRoofCenterClose * (2 * rafterValleyQuantity - 1);
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof.purpose = "지붕";
                this.coverCenterRoof.width =
                  (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
                this.coverCenterRoof.length = this.lengthTrunk;
                this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
                this.coverCenterRoof.quantity = 1;
              }
            } else {
              // 2중이상
              if (
                this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
                this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
              ) {
                if (this.switcherLevel.wingPosition.switchStep <= 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1;
                } else {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1 * switchStep;
                }
                this.coverEdgeRoof.purpose = "지붕";
                this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof.purpose = "지붕";
                this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
                this.coverCenterRoof.length = this.lengthTrunk;
                this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
                this.coverCenterRoof.quantity = 1;
              } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
                if (this.switcherLevel.wingPosition.switchStep <= 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1;
                } else {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1 * switchStep;
                }
                this.coverEdgeRoof.purpose = "지붕";
                this.coverEdgeRoof.width =
                  (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof.purpose = "지붕";
                this.coverCenterRoof.width =
                  (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
                this.coverCenterRoof.length = this.lengthTrunk;
                this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
                this.coverCenterRoof.quantity = 1;
              }
            }
          } else {
            // 1동만
            if (this.level.index === 0) {
              // 1중
              if (
                this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
                this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
              ) {
                if (this.switcherLevel.wingPosition.switchStep > 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = (this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep) * 2;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1 * switchStep;
                } else {
                  this.coverEdgeSide = null;
                }
                this.coverEdgeRoof.purpose = "지붕+측면(양측개폐)";
                this.coverEdgeRoof.width = (this.widthSideOpen / switchStep + this.widthRoofEdgeClose) * 2;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof = null;
              } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
                if (this.switcherLevel.wingPosition.switchStep > 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = (this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep) * 2;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 1 * switchStep;
                } else {
                  this.coverEdgeSide = null;
                }
                this.coverEdgeRoof.purpose = "지붕+측면(양측개폐)";
                this.coverEdgeRoof.width =
                  (this.widthSideOpen / switchStep + this.widthRoofEdgeClose) * rafterValleyQuantity;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof = null;
              }
            } else {
              // 2중이상
              if (
                this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
                this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
              ) {
                if (this.switcherLevel.wingPosition.switchStep > 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = (this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep) * 2;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 2 * switchStep;
                } else {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 2;
                }
                this.coverEdgeRoof.purpose = "지붕";
                this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofEdgeClose;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof = null;
              } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
                if (this.switcherLevel.wingPosition.switchStep > 1) {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = (this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep) * 2;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 2 * switchStep;
                } else {
                  this.coverEdgeSide.purpose = "측면(양측개폐)";
                  this.coverEdgeSide.width = this.widthSideOpen;
                  this.coverEdgeSide.length = this.lengthTrunk;
                  this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                  this.coverEdgeSide.quantity = 2;
                }
                this.coverEdgeRoof.purpose = "지붕";
                this.coverEdgeRoof.width = (this.widthRoofEdgeClose + this.widthRoofEdgeClose) * rafterValleyQuantity;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
                this.coverCenterRoof = null;
              }
            }
          }
        }
      } else if (
        this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
        this.switcherLevel.wingPosition.selectedDetail(this.coverPosition) === false &&
        this.switcherLevel.columnPosition.selectedDetail(this.coverPosition) === false
      ) {
        // 개폐방향: 3-1.지붕(편측개폐)+측면(고정)
        if (this.switcherLevel.roofPosition.switchWayValue === 1) {
          if (this.basic.buildingNumber >= 2) {
            if (this.level.index === 0) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(편측개폐)+측면";
              this.coverEdgeRoof.width = this.widthSideClose + this.widthRoofEdgeClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(편측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(편측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            if (this.level.index === 0) {
              this.coverEdgeSide.purpose = "편측측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof.purpose = "편측측면+지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthSideClose + this.widthRoofEdgeClose + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        } else if (this.switcherLevel.roofPosition.switchWayValue === 2) {
          if (this.basic.buildingNumber >= 2) {
            if (this.level.index === 0) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(양측개폐)+측면";
              this.coverEdgeRoof.width = this.widthSideClose + this.widthRoofEdgeClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(양측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(양측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            if (this.level.index === 0) {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeOpen + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else {
              this.coverEdgeSide.purpose = "측면";
              this.coverEdgeSide.width = this.widthSideClose;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeOpen + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        }
      } else if (
        this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === true &&
        (this.switcherLevel.wingPosition.selectedDetail(this.coverPosition) === true ||
          this.switcherLevel.columnPosition.selectedDetail(this.coverPosition) === true)
      ) {
        // 개폐방향: 4-1.지붕(편측개폐)+측면(편측개폐)
        if (
          this.switcherLevel.roofPosition.switchWayValue === 1 &&
          this.switcherLevel.wingPosition.switchWayValue === 1
        ) {
          if (this.basic.buildingNumber >= 2) {
            if (this.level.index === 0) {
              if (this.switcherLevel.wingPosition.switchStep > 1) {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              } else {
                this.coverEdgeSide = null;
              }
              this.coverEdgeRoof.purpose = "지붕(편측개폐)+측면(편측개폐)";
              this.coverEdgeRoof.width =
                this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(편측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else {
              if (this.switcherLevel.wingPosition.switchStep > 1) {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              } else {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              }
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(편측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            if (this.switcherLevel.wingPosition.switchStep > 1) {
              this.coverEdgeSide.purpose = "편측측면(개폐)";
              this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1 * switchStep;
            } else {
              this.coverEdgeSide.purpose = "편측측면(개폐)";
              this.coverEdgeSide.width = this.widthSideOpen;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1 * switchStep;
            }
            if (this.level.index === 0) {
              this.coverEdgeRoof.purpose = "편측측면(개폐)+지붕(편측개폐)";
              this.coverEdgeRoof.width =
                this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else {
              if (this.switcherLevel.wingPosition.switchStep > 1) {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2 * switchStep;
              } else {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
              }
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        } else if (
          this.switcherLevel.roofPosition.switchWayValue === 1 &&
          this.switcherLevel.wingPosition.switchWayValue === 2
        ) {
          // 지붕(편측개폐) + 측면(양측개폐)
          if (this.basic.buildingNumber >= 2) {
            if (this.level.index === 0) {
              // 1중
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide = null;
              } else {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              }
              this.coverEdgeRoof.purpose = "지붕(편측개폐)+측면(양측개폐)";
              this.coverEdgeRoof.width =
                this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(편측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else {
              // 2중이상
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              } else {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              }
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(편측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            if (this.switcherLevel.wingPosition.switchStep > 1) {
              this.coverEdgeSide.purpose = "편측측면(개폐)";
              this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1 * switchStep;
            } else {
              this.coverEdgeSide.purpose = "편측측면(개폐)";
              this.coverEdgeSide.width = this.widthSideOpen;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1 * switchStep;
            }
            if (this.level.index === 0) {
              this.coverEdgeRoof.purpose = "편측측면(개폐)+지붕(편측개폐)";
              this.coverEdgeRoof.width =
                this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else {
              if (this.switcherLevel.wingPosition.switchStep > 1) {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2 * switchStep;
              } else {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
              }
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        } else if (
          this.switcherLevel.roofPosition.switchWayValue === 2 &&
          this.switcherLevel.wingPosition.switchWayValue === 1
        ) {
          // 지붕(양측개폐) + 측면(편측개폐)
          if (this.basic.buildingNumber >= 2) {
            if (this.level.index === 0) {
              // 1중
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide = null;
              } else {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              }
              this.coverEdgeRoof.purpose = "지붕(양측개폐)+측면(편측개폐)";
              this.coverEdgeRoof.width =
                this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(양측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else {
              // 2중이상
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              } else {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1 * switchStep;
              }
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(양측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            // 1동만
            if (this.level.index === 0) {
              // 1중
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
              } else {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2 * switchStep;
              }
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeOpen + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else {
              // 2중이상
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
              } else {
                this.coverEdgeSide.purpose = "측면(편측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2 * switchStep;
              }
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeOpen + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        } else if (
          this.switcherLevel.roofPosition.switchWayValue === 2 &&
          this.switcherLevel.wingPosition.switchWayValue === 2
        ) {
          // 지붕(양측개폐) + 측면(양측개폐)
          if (this.basic.buildingNumber >= 2) {
            if (this.level.index === 0) {
              // 1중
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide = null;
              } else {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              }
              this.coverEdgeRoof.purpose = "지붕(양측개폐)+측면(양측개폐)";
              this.coverEdgeRoof.width =
                this.widthSideOpen / switchStep + this.widthRoofEdgeClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(양측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else {
              // 2중이상
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
              } else {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1 * switchStep;
              }
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(양측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            // 1동만
            if (this.level.index === 0) {
              // 1중
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
              } else {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2 * switchStep;
              }
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeOpen + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else {
              // 2중이상
              if (this.switcherLevel.wingPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
              } else {
                this.coverEdgeSide.purpose = "측면(양측개폐)";
                this.coverEdgeSide.width = this.widthSideOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2 * switchStep;
              }
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeOpen + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        }
      }
    } else if (this.coverPosition.scopeSelectedRoof === true && this.coverPosition.scopeSelectedSide === false) {
      // 개폐여부: 지붕(고정)
      if (this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === false) {
        if (this.basic.buildingNumber >= 2) {
          if (this.level.index === 0) {
            if (
              this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
              this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
            ) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofCenterClose;
              // + (고정) 기본 여분
              this.coverEdgeRoof.width += 0.25;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofCenterClose * 3;
              // + (고정) 기본 여분
              this.coverEdgeRoof.width += 0.25;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕";
              this.coverCenterRoof.width =
                (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            this.coverEdgeSide = null;
            this.coverEdgeRoof.purpose = "지붕";
            this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
            this.coverEdgeRoof.length = this.lengthTrunk;
            this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
            this.coverEdgeRoof.quantity = 1;
            this.coverCenterRoof.purpose = "지붕";
            this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
            this.coverCenterRoof.length = this.lengthTrunk;
            this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
            this.coverCenterRoof.quantity = 1;
          }
        } else {
          if (this.level.index === 0) {
            if (
              this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
              this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
            ) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofEdgeClose;
              // + (고정) 기본 여분
              this.coverEdgeRoof.width += 0.25 * 2;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = (this.widthRoofEdgeClose + this.widthRoofEdgeClose) * rafterValleyQuantity;
              // + (고정) 기본 여분
              this.coverEdgeRoof.width += 0.25 * 2;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          } else {
            if (
              this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
              this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
            ) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterClose;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕";
              this.coverEdgeRoof.width = (this.widthRoofCenterClose + this.widthRoofCenterClose) * rafterValleyQuantity;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        }
        // + (고정) 앞면 출입문 여분
        if (
          this.endpieceLevel.frontPosition.selected === true &&
          (this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
            this.endpieceLevel.frontPosition.gateType === CONST.LB_GATE_TYPE_HINGED) &&
          this.endpieceLevel.frontPosition.gateBuildingNumber > 0
        ) {
          if (this.coverEdgeRoof != null) {
            this.coverEdgeRoof.length += 0.5;
            if (this.basic.isVariableLength === true) {
              for (i = 0; i < this.coverEdgeRoof.lengthAC.length; i++) {
                this.coverEdgeRoof.lengthAC[i] += 0.5;
              }
            }
          }
          if (this.coverCenterRoof != null) {
            this.coverCenterRoof.length += 0.5;
            if (this.basic.isVariableLength === true) {
              for (i = 0; i < this.coverCenterRoof.lengthAC.length; i++) {
                this.coverCenterRoof.lengthAC[i] += 0.5;
              }
            }
          }
        }

        // + (고정) 뒷면 출입문 여분
        if (
          this.endpieceLevel.backPosition.selected &&
          (this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_SLIDING ||
            this.endpieceLevel.backPosition.gateType === CONST.LB_GATE_TYPE_HINGED) &&
          this.endpieceLevel.backPosition.gateBuildingNumber > 0
        ) {
          if (this.coverEdgeRoof != null) {
            this.coverEdgeRoof.length += 0.5;
            if (this.basic.isVariableLength === true) {
              for (i = 0; i < this.coverEdgeRoof.lengthAC.length; i++) {
                this.coverEdgeRoof.lengthAC[i] += 0.5;
              }
            }
          }
          if (this.coverCenterRoof != null) {
            this.coverCenterRoof.length += 0.5;
            if (this.basic.isVariableLength === true) {
              for (i = 0; i < this.coverCenterRoof.lengthAC.length; i++) {
                this.coverCenterRoof.lengthAC[i] += 0.5;
              }
            }
          }
        }
      } else if (this.switcherLevel.roofPosition.selectedDetail(this.coverPosition) === true) {
        // 개폐방향: 지붕(편측개폐)
        if (this.switcherLevel.roofPosition.switchWayValue === 1) {
          if (this.basic.buildingNumber >= 2) {
            if (this.level.index === 0) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(편측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(편측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            if (this.level.index === 0) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(편측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        } else if (this.switcherLevel.roofPosition.switchWayValue === 2) {
          if (this.basic.buildingNumber >= 2) {
            if (this.level.index === 0) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeClose + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(양측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            } else {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof.purpose = "지붕(양측개폐)";
              this.coverCenterRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverCenterRoof.length = this.lengthTrunk;
              this.coverCenterRoof.lengthAC = this.lengthTrunkAC;
              this.coverCenterRoof.quantity = 1;
            }
          } else {
            if (this.level.index === 0) {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofEdgeOpen + this.widthRoofEdgeOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            } else {
              this.coverEdgeSide = null;
              this.coverEdgeRoof.purpose = "지붕(양측개폐)";
              this.coverEdgeRoof.width = this.widthRoofCenterOpen + this.widthRoofCenterOpen;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
              this.coverCenterRoof = null;
            }
          }
        }
      }
    } else if (
      this.coverPosition.scopeSelectedRoof === false &&
      this.coverPosition.scopeSelectedSide === true &&
      this.coverPosition.scopeSelectedSideWing === true
    ) {
      const switchStep = this.switcherLevel.wingPosition.switchStep;
      // 개폐여부: 방풍벽(고정)
      if (this.switcherLevel.wingPosition.selectedDetail(this.coverPosition) === false) {
        if (this.basic.buildingNumber >= 2) {
          this.coverEdgeSide.purpose = "방풍벽";
          this.coverEdgeSide.width = this.unitWingWidthClose;
          this.coverEdgeSide.length = this.lengthTrunk;
          this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
          this.coverEdgeSide.quantity = 1;
          this.coverEdgeRoof = null;
          this.coverCenterRoof = null;
        } else {
          this.coverEdgeSide.purpose = "방풍벽";
          this.coverEdgeSide.width = this.unitWingWidthClose;
          this.coverEdgeSide.length = this.lengthTrunk;
          this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
          this.coverEdgeSide.quantity = 2;
          this.coverEdgeRoof = null;
          this.coverCenterRoof = null;
        }
      } else if (this.switcherLevel.wingPosition.selectedDetail(this.coverPosition) === true) {
        // 개폐방향: 방풍벽(편측개폐)
        if (this.switcherLevel.wingPosition.switchWayValue === 1) {
          // 개폐방향: 방풍벽(편측개폐)
          if (this.basic.buildingNumber >= 2) {
            if (this.switcherLevel.wingPosition.switchStep <= 1) {
              this.coverEdgeSide.purpose = "방풍벽(편측개폐)";
              this.coverEdgeSide.width = this.unitWingWidthOpen;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof = null;
            } else {
              this.coverEdgeSide.purpose = "방풍벽(편측개폐)";
              this.coverEdgeSide.width = this.unitWingWidthOpen / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof.purpose = "방풍벽(편측개폐)";
              this.coverEdgeRoof.width = this.unitWingWidthOpen / switchStep;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
            }
            this.coverCenterRoof = null;
          } else {
            // 1동만
            if (this.switcherLevel.wingPosition.switchStep <= 1) {
              this.coverEdgeSide.purpose = "방풍벽(편측개폐)";
              this.coverEdgeSide.width = this.unitWingWidthOpen;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof = null;
            } else {
              this.coverEdgeSide.purpose = "방풍벽(편측개폐)";
              this.coverEdgeSide.width = this.unitWingWidthOpen / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof.purpose = "방풍벽(편측개폐)";
              this.coverEdgeRoof.width = this.unitWingWidthOpen / switchStep;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 2;
            }
            this.coverCenterRoof = null;
          }
        } else if (this.switcherLevel.wingPosition.switchWayValue === 2) {
          // 개폐방향: 방풍벽(양측개폐)
          if (this.basic.buildingNumber >= 2) {
            if (this.switcherLevel.wingPosition.switchStep <= 1) {
              this.coverEdgeSide.purpose = "방풍벽(양측개폐)";
              this.coverEdgeSide.width = this.unitWingWidthOpen;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof = null;
            } else {
              this.coverEdgeSide.purpose = "방풍벽(양측개폐)";
              this.coverEdgeSide.width = this.unitWingWidthOpen / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 1;
              this.coverEdgeRoof.purpose = "방풍벽(양측개폐)";
              this.coverEdgeRoof.width = this.unitWingWidthOpen / switchStep;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 1;
            }
            this.coverCenterRoof = null;
          } else {
            // 1동만
            if (this.switcherLevel.wingPosition.switchStep <= 1) {
              this.coverEdgeSide.purpose = "방풍벽(양측개폐)";
              this.coverEdgeSide.width = this.unitWingWidthOpen;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof = null;
            } else {
              this.coverEdgeSide.purpose = "방풍벽(양측개폐)";
              this.coverEdgeSide.width = this.unitWingWidthOpen / switchStep + this.unitSideWidth_sideSkirtStep;
              this.coverEdgeSide.length = this.lengthTrunk;
              this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
              this.coverEdgeSide.quantity = 2;
              this.coverEdgeRoof.purpose = "방풍벽(양측개폐)";
              this.coverEdgeRoof.width = this.unitWingWidthOpen / switchStep;
              this.coverEdgeRoof.length = this.lengthTrunk;
              this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
              this.coverEdgeRoof.quantity = 2;
            }
            this.coverCenterRoof = null;
          }
        }
      }
    } else if (
      this.coverPosition.scopeSelectedRoof === false &&
      this.coverPosition.scopeSelectedSide === true &&
      this.coverPosition.scopeSelectedSideColumn === true
    ) {
      const switchStep = this.switcherLevel.columnPosition.switchStep;
      // 1중
      if (this.level.index === 0) {
        // 개폐여부: 기둥(고정)
        if (this.switcherLevel.columnPosition.selectedDetail(this.coverPosition) === false) {
          if (this.basic.buildingNumber >= 2) {
            this.coverEdgeSide.purpose = "기둥";
            this.coverEdgeSide.width = this.unitColumnWidthClose;
            this.coverEdgeSide.length = this.lengthTrunk;
            this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
            this.coverEdgeSide.quantity = 1;
            this.coverEdgeRoof = null;
            this.coverCenterRoof = null;
          } else {
            this.coverEdgeSide.purpose = "기둥";
            this.coverEdgeSide.width = this.unitColumnWidthClose;
            this.coverEdgeSide.length = this.lengthTrunk;
            this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
            this.coverEdgeSide.quantity = 2;
            this.coverEdgeRoof = null;
            this.coverCenterRoof = null;
          }
        } else if (this.switcherLevel.columnPosition.selectedDetail(this.coverPosition) === true) {
          // 개폐방향: 기둥(편측개폐)
          if (this.switcherLevel.columnPosition.switchWayValue === 1) {
            if (this.basic.buildingNumber >= 2) {
              if (this.switcherLevel.columnPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "기둥(편측개폐)";
                this.coverEdgeSide.width = this.unitColumnWidthOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
                this.coverEdgeRoof = null;
              } else {
                this.coverEdgeSide.purpose = "기둥(편측개폐)";
                this.coverEdgeSide.width = this.unitColumnWidthOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
                this.coverEdgeRoof.purpose = "기둥(편측개폐)";
                this.coverEdgeRoof.width = this.unitColumnWidthOpen / switchStep;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
              }
              this.coverCenterRoof = null;
            } else {
              if (this.switcherLevel.columnPosition.switchStep > 1) {
                this.coverEdgeSide.purpose = "기둥(편측개폐)";
                this.coverEdgeSide.width = this.unitColumnWidthOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
                this.coverEdgeRoof.purpose = "기둥(편측개폐)";
                this.coverEdgeRoof.width = this.unitColumnWidthOpen / switchStep;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 2;
              } else {
                this.coverEdgeSide.purpose = "기둥(편측개폐)";
                this.coverEdgeSide.width = this.unitColumnWidthOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
                this.coverEdgeRoof = null;
              }
              this.coverCenterRoof = null;
            }
          } else if (this.switcherLevel.columnPosition.switchWayValue === 2) {
            // 개폐방향: 기둥(양측개폐)
            if (this.basic.buildingNumber >= 2) {
              if (this.switcherLevel.columnPosition.switchStep <= 1) {
                this.coverEdgeSide.purpose = "기둥(양측개폐)";
                this.coverEdgeSide.width = this.unitColumnWidthOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
                this.coverEdgeRoof = null;
              } else {
                this.coverEdgeSide.purpose = "기둥(양측개폐)";
                this.coverEdgeSide.width = this.unitColumnWidthOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 1;
                this.coverEdgeRoof.purpose = "기둥(양측개폐)";
                this.coverEdgeRoof.width = this.unitColumnWidthOpen / switchStep;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 1;
              }
              this.coverCenterRoof = null;
            } else {
              if (this.switcherLevel.columnPosition.switchStep > 1) {
                this.coverEdgeSide.purpose = "기둥(양측개폐)";
                this.coverEdgeSide.width = this.unitColumnWidthOpen / switchStep + this.unitSideWidth_sideSkirtStep;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
                this.coverEdgeRoof.purpose = "기둥(양측개폐)";
                this.coverEdgeRoof.width = this.unitColumnWidthOpen / switchStep;
                this.coverEdgeRoof.length = this.lengthTrunk;
                this.coverEdgeRoof.lengthAC = this.lengthTrunkAC;
                this.coverEdgeRoof.quantity = 2;
              } else {
                this.coverEdgeSide.purpose = "기둥(양측개폐)";
                this.coverEdgeSide.width = this.unitColumnWidthOpen;
                this.coverEdgeSide.length = this.lengthTrunk;
                this.coverEdgeSide.lengthAC = this.lengthTrunkAC;
                this.coverEdgeSide.quantity = 2;
                this.coverEdgeRoof = null;
              }
              this.coverCenterRoof = null;
            }
          }
        }
      } else {
        this.coverEdgeSide = null;
        this.coverEdgeRoof = null;
        this.coverCenterRoof = null;
      }
    }

    /// /////////////////////////////////
    // 피복범위: 마구리 앞면
    if (this.coverPosition.scopeSelectedFront === true && !this.coverPosition.scopeSelectedFrontIncluded) {
      // 개폐여부: 앞면(고정)
      if (this.switcherLevel.frontPosition.selectedDetail(this.coverPosition) === false) {
        // 골조형태: 전체형
        if (
          this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
          this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM ||
          this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM
        ) {
          this.coverFront.purpose = "앞면";
          this.coverFront.width = this.widthFront;
          this.coverFront.length = this.lengthFront;
        } else if (this.endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.coverFront.purpose = "앞면상단";
          this.coverFront.width = this.widthFront;
          this.coverFront.length = this.lengthFront;
        } else {
          this.coverFront.purpose = "앞면하단(개폐)+앞면상단";
          this.coverFront.width = this.widthFront; // 하단 폭
          this.coverFront.length = this.lengthFront + this.lengthFront / 2 + 1; // 길이를 1.5배 견적
        }
      }
    } else {
      this.coverFront = null;
    }

    /// /////////////////////////////////
    // 피복범위: 마구리 뒷면
    if (this.coverPosition.scopeSelectedBack === true && !this.coverPosition.scopeSelectedBackIncluded) {
      // 개폐여부: 뒷면(고정)
      if (this.switcherLevel.backPosition.selectedDetail(this.coverPosition) === false) {
        // 골조형태: 전체형
        if (
          this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
          this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM ||
          this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM
        ) {
          this.coverBack.purpose = "뒷면";
          this.coverBack.width = this.widthBack;
          this.coverBack.length = this.lengthBack;
        } else if (this.endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.coverBack.purpose = "뒷면상단";
          this.coverBack.width = this.widthBack;
          this.coverBack.length = this.lengthBack;
        }
      } else {
        this.coverBack.purpose = "뒷면하단(개폐)+뒷면상단";
        this.coverBack.width = this.widthBack; // 하단 폭
        this.coverBack.length = this.lengthBack + this.lengthBack / 2 + 1; // 길이를 1.5배 견적
      }
    } else {
      this.coverBack = null;
    }

    //----------------------------------
    // 추가 용도
    // 바람막이, 형태 등 추가적인 설계 용도 넣기
    //----------------------------------

    // 양끝동 측면 피복 객체
    if (this.coverEdgeSide != null) {
      if (this.coverEdgeSide.windbreakIncluded === true) {
        this.coverEdgeSide.purpose += "+바람막이";
      }
    }

    // 양끝동 지붕 또는 지붕+측면 피복 객체
    if (this.coverEdgeRoof != null) {
      if (this.coverEdgeRoof.frontIncluded === true) {
        this.coverEdgeRoof.purpose += "+앞면(";
        this.coverEdgeRoof.purpose += this.endpieceLevel.frontPosition.type;

        if (this.switcherLevel.frontPosition.selectedDetail(this.coverPosition) === false) {
          this.coverEdgeRoof.purpose += ")";
        } else {
          this.coverEdgeRoof.purpose += "/개폐)";
        }
      }
      if (this.coverEdgeRoof.backIncluded === true) {
        this.coverEdgeRoof.purpose += "+뒷면(";
        this.coverEdgeRoof.purpose += this.endpieceLevel.backPosition.type;

        if (this.switcherLevel.backPosition.selectedDetail(this.coverPosition) === false) {
          this.coverEdgeRoof.purpose += ")";
        } else {
          this.coverEdgeRoof.purpose += "/개폐)";
        }
      }
      if (this.coverEdgeRoof.windbreakIncluded === true) {
        this.coverEdgeRoof.purpose += "+바람막이";
      }
      if (this.coverEdgeRoof.eaveSelected === true) {
        this.coverEdgeRoof.purpose += "+처마";
      }
    }

    // 중앙동 지붕 피복 객체
    if (this.coverCenterRoof != null) {
      if (this.coverCenterRoof.windbreakIncluded === true) {
        this.coverCenterRoof.purpose += "+바람막이";
      }
      if (this.coverCenterRoof.eaveSelected === true) {
        this.coverCenterRoof.purpose += "+처마";
      }
    }

    // 앞면 피복 객체
    if (this.coverFront != null) {
      // 출입문
      if (this.coverFront.gateQuantity > 0) {
        this.coverFront.purpose += `+출입문${this.coverFront.gateQuantity}동`;
      }
      // 바람막이
      if (this.coverFront.windbreakIncluded === true) {
        this.coverFront.purpose += "+바람막이";
      }
    }

    // 뒷면 피복 객체
    if (this.coverBack != null) {
      // 출입문
      if (this.coverBack.gateQuantity > 0) {
        this.coverBack.purpose += `+출입문${this.coverBack.gateQuantity}동`;
      }
      // 바람막이
      if (this.coverBack.windbreakIncluded === true) {
        this.coverBack.purpose += "+바람막이";
      }
    }

    //----------------------------------
    // 추가 작업
    //----------------------------------

    // 앞면과 뒷면의 피복 합치기
    if (
      this.design.preference.extendFrontBackCoverFlag === true &&
      this.coverFront != null &&
      this.coverBack != null &&
      this.coverFront.width === this.coverBack.width &&
      this.coverFront.length < 40
    ) {
      this.coverFront.purpose = `${this.coverFront.purpose} | ${this.coverBack.purpose}`;
      this.coverFront.width = this.coverFront.width;
      this.coverFront.length = this.coverFront.length + this.coverBack.length;
      this.coverBack = null;
    }

    // 천창 공사로 인한 지붕 피복 나누기
    if (this.level.index === 0 && this.coverPosition.scopeSelectedRoof === true) {
      const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;
      if (skyFramePosition.selected === true) {
        const skyCoverPosition: SkyCoverPositionIL = this.struct.skyCoverWorkIL.level1.skylightPosition;
        let extraSkySkirtHeight: number;

        if (
          skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
          skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
        ) {
          extraSkySkirtHeight = skyCoverPosition.skirtHeight + 0.2;

          if (this.basic.buildingNumber >= 2) {
            // 중앙동
            this.coverCenterRoof.purpose = `(천창)${this.coverCenterRoof.purpose}`;
            this.coverCenterRoof.width =
              this.coverCenterRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            this.coverCenterRoof.quantity = this.coverCenterRoof.quantity * 2;

            // 양끝동 - 짧은쪽
            this.coverEdgeSkyShort = new CoverTemp();
            this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
            this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
            this.coverEdgeSkyShort.width = this.coverCenterRoof.width;

            // 양끝동 - 긴쪽
            this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
            this.coverEdgeRoof.width =
              this.coverEdgeRoof.width -
              (this.coverCenterRoof.width + skyFramePosition.arcWidth) +
              extraSkySkirtHeight * 2;
          } else {
            // 지붕
            this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
            this.coverEdgeRoof.width =
              this.coverEdgeRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            this.coverEdgeRoof.quantity = this.coverEdgeRoof.quantity * 2;
          }
        } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
          extraSkySkirtHeight = skyCoverPosition.skirtHeight + 0.2;

          if (this.basic.buildingNumber >= 2) {
            // 중앙동 - 짧은쪽
            this.coverCenterSkyShort = new CoverTemp();
            this.coverCenterSkyShort.deepCopy(this.coverCenterRoof);
            this.coverCenterSkyShort.purpose = `(천창)${this.coverCenterSkyShort.purpose}`;
            this.coverCenterSkyShort.width =
              this.coverCenterRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;

            // 중앙동 - 긴쪽
            this.coverCenterRoof.purpose = `(천창)${this.coverCenterRoof.purpose}`;
            this.coverCenterRoof.width =
              this.coverCenterRoof.width / 2 -
              skyFramePosition.arcWidth / 2 +
              skyFramePosition.rafterPart.rafterLength +
              0.3;

            // 양끝동 - 짧은쪽
            this.coverEdgeSkyShort = new CoverTemp();
            this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
            this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
            this.coverEdgeSkyShort.width = this.coverCenterSkyShort.width;

            // 양끝동 - 긴쪽
            this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
            this.coverEdgeRoof.width =
              this.coverEdgeRoof.width -
              (this.coverCenterSkyShort.width + skyFramePosition.arcWidth) +
              extraSkySkirtHeight +
              skyFramePosition.rafterPart.rafterLength +
              0.3;
          } else {
            // 지붕 - 짧은쪽
            this.coverEdgeSkyShort = new CoverTemp();
            this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
            this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
            this.coverEdgeSkyShort.width =
              this.coverEdgeRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;

            // 지붕 - 긴쪽
            this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
            this.coverEdgeRoof.width =
              this.coverEdgeRoof.width / 2 -
              skyFramePosition.arcWidth / 2 +
              skyFramePosition.rafterPart.rafterLength +
              0.3;
          }
        } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH) {
          if (
            this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
            this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
          ) {
            if (this.basic.buildingNumber >= 2) {
              // 중앙동
              this.coverCenterRoof.purpose = `(천창)${this.coverCenterRoof.purpose}`;
              this.coverCenterRoof.width = this.coverCenterRoof.width / 2 - skyFramePosition.arcWidth / 2;
              this.coverCenterRoof.quantity = this.coverCenterRoof.quantity * 2;

              // 양끝동 - 짧은쪽
              this.coverEdgeSkyShort = new CoverTemp();
              this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
              this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
              this.coverEdgeSkyShort.width = this.coverCenterRoof.width;

              // 양끝동 - 긴쪽
              this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeRoof.width =
                this.coverEdgeRoof.width - (this.coverCenterRoof.width + skyFramePosition.arcWidth);
            } else {
              // 지붕
              this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeRoof.width = this.coverEdgeRoof.width / 2 - skyFramePosition.arcWidth / 2;
              this.coverEdgeRoof.quantity = this.coverEdgeRoof.quantity * 2;
            }
          } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
            if (this.basic.buildingNumber >= 2) {
              // 중앙동
              this.coverCenterRoof.purpose = `(천창)${this.coverCenterRoof.purpose}`;
              this.coverCenterRoof.width = this.coverCenterRoof.width / 4 - skyFramePosition.arcWidth / 2;
              this.coverCenterRoof.quantity = this.coverCenterRoof.quantity * 2 * rafterValleyQuantity;

              // 양끝동 - 짧은쪽
              this.coverEdgeSkyShort = new CoverTemp();
              this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
              this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
              this.coverEdgeSkyShort.width = this.coverCenterRoof.width;
              this.coverEdgeSkyShort.quantity = this.coverEdgeSkyShort.quantity * (2 * rafterValleyQuantity - 1);

              // 양끝동 - 긴쪽
              this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeRoof.width =
                this.coverEdgeRoof.width - this.widthRoofCenterClose * 3 - skyFramePosition.arcWidth / 2;
            } else {
              // 지붕 - 짧은쪽
              this.coverEdgeSkyShort = new CoverTemp();
              this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
              this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeSkyShort.width = this.widthRoofCenterClose;
              this.coverEdgeSkyShort.quantity = rafterValleyQuantity - 1;

              // 지붕 - 긴쪽
              this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeRoof.width =
                this.coverEdgeRoof.width - this.widthRoofCenterClose * 3 - skyFramePosition.arcWidth / 2;
              this.coverEdgeRoof.quantity = 2;
            }
          }
        } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE) {
          if (
            this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
            this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
          ) {
            if (this.basic.buildingNumber >= 2) {
              // 중앙동 - 짧은쪽
              this.coverCenterSkyShort = new CoverTemp();
              this.coverCenterSkyShort.deepCopy(this.coverCenterRoof);
              this.coverCenterSkyShort.purpose = `(천창)${this.coverCenterSkyShort.purpose}`;
              this.coverCenterSkyShort.width = this.coverCenterRoof.width / 2 - skyFramePosition.arcWidth / 2;

              // 중앙동 - 긴쪽
              this.coverCenterRoof.purpose = `(천창)${this.coverCenterRoof.purpose}`;
              this.coverCenterRoof.width = this.coverCenterRoof.width / 2;

              // 양끝동 - 짧은쪽
              this.coverEdgeSkyShort = new CoverTemp();
              this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
              this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
              this.coverEdgeSkyShort.width = this.coverCenterSkyShort.width;

              // 양끝동 - 긴쪽
              this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeRoof.width = this.coverCenterRoof.width / 2;
            } else {
              // 지붕 - 짧은쪽
              this.coverEdgeSkyShort = new CoverTemp();
              this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
              this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
              this.coverEdgeSkyShort.width = this.coverEdgeRoof.width / 2 - skyFramePosition.arcWidth / 2;

              // 지붕 - 긴쪽
              this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeRoof.width = this.coverEdgeRoof.width / 2;
            }
          } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
            if (this.basic.buildingNumber >= 2) {
              // 중앙동 - 짧은쪽
              this.coverCenterSkyShort = new CoverTemp();
              this.coverCenterSkyShort.deepCopy(this.coverCenterRoof);
              this.coverCenterSkyShort.purpose = `(천창)${this.coverCenterSkyShort.purpose}`;
              this.coverCenterSkyShort.width = this.coverCenterRoof.width / 2 - skyFramePosition.arcWidth;
              this.coverCenterSkyShort.quantity = this.coverCenterSkyShort.quantity * rafterValleyQuantity;

              // 중앙동 - 긴쪽
              this.coverCenterRoof.purpose = `(천창)${this.coverCenterRoof.purpose}`;
              this.coverCenterRoof.width = this.coverCenterRoof.width / 2 + skyFramePosition.arcWidth;
              this.coverCenterRoof.quantity = this.coverCenterRoof.quantity * rafterValleyQuantity;

              // 양끝동 - 짧은쪽
              this.coverEdgeSkyShort = new CoverTemp();
              this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
              this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
              this.coverEdgeSkyShort.width = this.coverCenterRoof.width / 2 - skyFramePosition.arcWidth / 2;
              this.coverEdgeSkyShort.quantity = 2 * rafterValleyQuantity - 3;

              // 양끝동 - 중앙(변수만 측면 사용함)
              this.coverEdgeSide = new CoverTemp();
              this.coverEdgeSide.deepCopy(this.coverEdgeRoof);
              this.coverEdgeSide.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeSide.width = this.coverCenterRoof.width / 2 + skyFramePosition.arcWidth;
              this.coverEdgeSide.quantity = rafterValleyQuantity - 1;

              // 양끝동 - 긴쪽
              this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeRoof.width = this.coverCenterRoof.width;
              this.coverEdgeRoof.quantity = 1;
            } else {
              // 지붕 - 짧은쪽
              this.coverEdgeSkyShort = new CoverTemp();
              this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
              this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
              this.coverEdgeSkyShort.width = this.widthRoofCenterClose;
              this.coverEdgeSkyShort.quantity = this.coverEdgeRoof.quantity * rafterValleyQuantity;

              // 지붕 - 긴쪽
              this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
              this.coverEdgeRoof.width =
                this.coverEdgeRoof.width / 2 - this.widthRoofCenterClose + skyFramePosition.arcWidth;
              this.coverEdgeRoof.quantity = this.coverEdgeRoof.quantity * rafterValleyQuantity;
            }
          }
        } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_CUSTOM) {
          extraSkySkirtHeight = 0.3;

          if (this.basic.buildingNumber >= 2) {
            // 중앙동
            this.coverCenterRoof.purpose = `(천창)${this.coverCenterRoof.purpose}`;
            this.coverCenterRoof.width =
              this.coverCenterRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            this.coverCenterRoof.quantity = this.coverCenterRoof.quantity * 2;

            // 양끝동 - 짧은쪽
            this.coverEdgeSkyShort = new CoverTemp();
            this.coverEdgeSkyShort.deepCopy(this.coverEdgeRoof);
            this.coverEdgeSkyShort.purpose = `(천창)${this.coverEdgeSkyShort.purpose}`;
            this.coverEdgeSkyShort.width = this.coverCenterRoof.width;

            // 양끝동 - 긴쪽
            this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
            this.coverEdgeRoof.width =
              this.coverEdgeRoof.width -
              (this.coverCenterRoof.width + skyFramePosition.arcWidth) +
              extraSkySkirtHeight * 2;
          } else {
            // 지붕
            this.coverEdgeRoof.purpose = `(천창)${this.coverEdgeRoof.purpose}`;
            this.coverEdgeRoof.width =
              this.coverEdgeRoof.width / 2 - skyFramePosition.arcWidth / 2 + extraSkySkirtHeight;
            this.coverEdgeRoof.quantity = this.coverEdgeRoof.quantity * 2;
          }
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
