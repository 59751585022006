import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDoor } from "vhows-design/src/object/design/item/list/ItemDoor";
import { WindowPartIL_Sash } from "vhows-design/src/object/design/other/window/il/WindowPartIL_Sash";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";
import { ItemSashDoor } from "vhows-design/src/object/design/item/list/ItemSashDoor";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2023-04-26
 */
@jsonObject
export class WindowSampleIL_SashDoor extends ItemSashDoor {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const sashPart: WindowPartIL_Sash = <WindowPartIL_Sash>this.part;
    sashPart.algorithmSpec_sash_door();
    sashPart.algorithm_frameWidth();
    sashPart.algorithm_frameHeight();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevelIL>this.level).rightPosition.sashPart.sashDoorSample.specs = this.specs;
      (<WindowLevelIL>this.level).frontPosition.sashPart.sashDoorSample.specs = this.specs;
      (<WindowLevelIL>this.level).backPosition.sashPart.sashDoorSample.specs = this.specs;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevelIL>this.level).backPosition.sashPart.sashDoorSample.specs = this.specs;
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
