import { useCallback, useEffect, useRef } from "react";
import "./date-picker.css";

type DatePicker = {
  /**
   * 날짜 초기값
   */
  date?: Date | number | null;
  /**
   * 날짜 표기 방식 (date / month / year)
   */
  type?: string;
  /**
   * 날짜 제한 범위
   */
  ranges?: Array<Array<Date | number>>;
  /**
   * 표기 언어 (en / ko)
   */
  language?: string;
  /**
   * 날짜 표기 포맷
   */
  format?: string;
  /**
   * datePicker 를 항상 표기 할지 여부
   */
  showAlways?: boolean;
  /**
   * 일주일의 시작 요일 (ex. Mon / Sun )
   */
  weekStartDay?: string;
  placeholder?: string;
  inputStyle?: string;
  containerStyle?: string;
  pickerStyle?: string;
  onChange: Function;
};

export const DatePicker = ({
  date,
  type,
  ranges,
  language,
  format,
  showAlways,
  weekStartDay,
  placeholder,
  inputStyle,
  containerStyle,
  pickerStyle,
  onChange,
}: DatePicker) => {
  const ref = useRef(null);

  useEffect(() => {
    // @ts-ignore
    if (process.browser) {
      const TuiDatePicker = require("tui-date-picker");
      const picker = new TuiDatePicker("#tui-date-picker-container", {
        date: date || null,
        type: type || "date",
        language: language || "en",
        selectableRanges: ranges,
        input: {
          element: "#tui-date-picker-target",
          format: format || "yyyy-MM-dd",
        },
        showAlways: showAlways || false,
        weekStartDay: weekStartDay || "Sun",
      });

      picker.on("change", () => onChange(picker.getDate()));

      return () => {
        picker.off("change");
      };
    }
    // @ts-ignore
  }, [process.browser]);

  // @ts-ignore
  const removeFocusEffect = useCallback(_ => {
    // @ts-ignore
    ref.current.blur();
  });

  return (
    <>
      <div className={`${containerStyle} tui-datepicker-input tui-has-focus`}>
        <input
          className={inputStyle}
          id="tui-date-picker-target"
          placeholder={placeholder}
          ref={ref}
          // @ts-ignore
          onFocus={removeFocusEffect}
          aria-label="Date-Time"
        />
        <span className="tui-ico-date"></span>
      </div>
      <div id="tui-date-picker-container" className={pickerStyle} />
    </>
  );
};
