import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { SkirtLevel } from "vhows-design/src/object/design/cover/skirt/SkirtLevel";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { EndpiecePart_Stud } from "vhows-design/src/object/design/frame/endpiece/EndpiecePart_Stud";
import { WindbreakLevel } from "vhows-design/src/object/design/cover/windbreak/WindbreakLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-01
 */
@jsonObject
export class EndpieceSample_StudPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const studPart: EndpiecePart_Stud = <EndpiecePart_Stud>this.part;
    studPart.algorithmSpec_stud_pipe();
    studPart.algorithmSpec_studAndRafterCenter_tHolder();
    studPart.algorithmSpec_studAndRafterCenterEndpiece_normalHolder();
    studPart.algorithmSpec_studAndRafterDiagonal_diagonalTHolder();
    studPart.algorithmSpec_studAndRafterDiagonal_diagonalTClamp();
    studPart.algorithmSpec_studAndRafterDiagonalEndpiece_normalHolder();
    studPart.studCenterUpperPipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevel>this.level).backPosition.studPart.studPipeSample.specs = this.specs;
    }

    /// //////// 외부 ///////////

    // 피복 고정
    const fixingLevel: FixingLevel = <FixingLevel>this.struct.fixingWork.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
    }

    // 치마 피복
    const skirtLevel: SkirtLevel = <SkirtLevel>this.struct.skirtWork.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      skirtLevel.frontPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      skirtLevel.backPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    }

    // 바람막이 피복
    const windbreakLevel: WindbreakLevel = <WindbreakLevel>this.struct.windbreakWork.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      windbreakLevel.frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      windbreakLevel.backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    }

    // 개폐기
    const switcherLevel: SwitcherLevel = <SwitcherLevel>this.struct.switcherWork.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      switcherLevel.sidePosition.supportPart.algorithmSpec_diagonal_hookHolder();
      switcherLevel.roofPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    }

    // 천창 개폐
    if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_FRONT) {
      this.struct.skySwitchWork.level1.skylightPosition.supDiagonalPart.algorithmSpec_diagonalSupport_hookHolder();
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 서까래 파이프 샘플(기초 골조)
   */
  // @override
  public algorithmSpec(): void {
    const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;
    this.specsList = trunkPosition.rafterPart.rafterPipeSample.specsList;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
