import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ItemVentFan } from "vhows-design/src/object/design/item/list/ItemVentFan";
import { Part } from "vhows-design/src/object/design/base/Part";
import { VentExhaustSample_RoofVentFan } from "vhows-design/src/object/design/vent/exhaust/VentExhaustSample_RoofVentFan";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-13
 */
@jsonObject({
  knownTypes: [VentExhaustSample_RoofVentFan],
})
export class VentExhaustPart_RoofFanPower extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public ventFanSample: VentExhaustSample_RoofVentFan; // 환기팬 샘플

  // 아이템
  public vent_ventFan: ItemVentFan;
  public vent_electricWire: ItemElectricWire;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 환풍구 수량
   */
  public get ventQuantity(): number {
    if (this._ventBuildingNumber === 0) {
      return 0;
    }
    return CommonUtil.round(this._ventTotalQuantity / this._ventBuildingNumber, 1);
  }

  //
  public set ventQuantity(value: number) {}

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _ventBuildingNumber: number; // 환기 동수
  @jsonMember(Number)
  public _ventTotalQuantity: number = 0; // 환풍구 전체 수량
  @jsonMember(Number)
  public _ventInterval: number; // 환풍구 간격
  @jsonMember(Number)
  public _ventLineNumber: number; // 환풍구 줄수

  /**
   * 환기 동수
   */
  public get ventBuildingNumber(): number {
    return this._ventBuildingNumber;
  }

  //
  public set ventBuildingNumber(value: number) {
    this._ventBuildingNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_ventTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 환풍구 전체 수량
   */
  public get ventTotalQuantity(): number {
    return this._ventTotalQuantity;
  }

  //
  public set ventTotalQuantity(value: number) {
    this._ventTotalQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 환풍구 간격
   */
  public get ventInterval(): number {
    return this._ventInterval;
  }

  //
  public set ventInterval(value: number) {
    this._ventInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_ventTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 환풍구 줄수
   */
  public get ventLineNumber(): number {
    return this._ventLineNumber;
  }

  //
  public set ventLineNumber(value: number) {
    this._ventLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_ventTotalQuantity();
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.ventFanSample = new VentExhaustSample_RoofVentFan();

    this.sampleAC = [this.ventFanSample];

    // 아이템
    this.vent_ventFan = new ItemVentFan();
    this.vent_electricWire = new ItemElectricWire();

    this.itemAC = [this.vent_ventFan, this.vent_electricWire];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.ventFanSample = <VentExhaustSample_RoofVentFan>this.sampleAC[0];
    // 아이템
    this.vent_ventFan = <ItemVentFan>this.itemAC[0];
    this.vent_electricWire = <ItemElectricWire>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param ventBuildingNumber: number 환기 동수
   * @param ventInterval: number 환풍구 간격
   * @param ventLineNumber: number 환풍구 줄수
   * @param ventFrontBackMargin: number 앞뒷면 여유 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    ventBuildingNumber: number = 0,
    ventInterval: number = 0,
    ventLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._ventBuildingNumber = ventBuildingNumber;
    this._ventInterval = ventInterval;
    this._ventLineNumber = ventLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.ventFanSample.setDefaultData(
        0,
        CONST.ITEM_ID_VENT_FAN,
        CONST.ITEM_NAME_VENT_FAN,
        "지붕 환기팬",
        "일반",
        "동서그린산업, DS-500(단동형), 50㎥/min, 500mm, 400mm, 200W",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.vent_ventFan.setDefaultData(
        0,
        CONST.ITEM_ID_VENT_FAN,
        CONST.ITEM_NAME_VENT_FAN,
        "지붕 환기팬",
        "일반",
        "동서그린산업, DS-500(단동형), 50㎥/min, 500mm, 400mm, 200W",
      );
      this.vent_electricWire.setDefaultData(
        1,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "지붕 환기팬",
        "VCTF, 2.5sq, 2C, 100m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_ventBuildingNumber();
    this.algorithm_ventTotalQuantity();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_vent_electricWire();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.vent_ventFan.selected = true;
    this.vent_ventFan.visible = true;
    this.vent_electricWire.selected = true;
    this.vent_electricWire.visible = true;

    /// ////////  수량 ///////////

    this.vent_ventFan.designQuantity = this.ventTotalQuantity;

    if (this.ventTotalQuantity > 0) {
      this.vent_electricWire.designQuantity =
        (((this.basicLevel.width + this.basic.buildingInterval) * this.ventBuildingNumber +
          this.basicLevel.length * this.ventBuildingNumber) /
          this.vent_electricWire.specLength) *
        CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;
    } else {
      this.vent_electricWire.designQuantity = 0;
    }
  }

  /**
   * 알고리즘: 환기 동수 <- 동수(기본정보)
   */
  public algorithm_ventBuildingNumber(): void {
    this.ventBuildingNumber = this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 환풍구 전체 수량 <- 길이(기본정보), (환풍구 간격), 환기 동수, 환풍구 줄수, 앞뒷면 여유 길이
   */
  public algorithm_ventTotalQuantity(): void {
    const ventQuantityPerBuilding: number = Math.floor(this.basicLevel.length / this.ventInterval);
    if (this.ventInterval === 0 || ventQuantityPerBuilding <= 1) {
      this.ventTotalQuantity = 1 * this.ventLineNumber * this.ventBuildingNumber;
    } else {
      this.ventTotalQuantity = ventQuantityPerBuilding * this.ventLineNumber * this.ventBuildingNumber;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [지붕 환기팬] 환기팬 <- 환기팬 샘플
   */
  public algorithmSpec_vent_ventFan(): void {
    this.vent_ventFan.specs = this.ventFanSample.specs;
    this.vent_ventFan.brands = this.ventFanSample.brands;
  }

  /**
   * 규격 알고리즘: [지붕 환기팬] 전선 <- 환기 시설 전선 샘플(제어기)
   */
  public algorithmSpec_vent_electricWire(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.vent_electricWire.specs =
        this.struct.controllerWork.level1.integratedPosition.controllerPart.ventElectricWireSample.specs;
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.vent_electricWire.specs =
        this.struct.controllerWorkIL.level1.integratedPosition.controllerPart.ventElectricWireSample.specs;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
