import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";
import { TrunkPart_Straight } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Straight";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";
import { TrunkPart_Column } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Column";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-26
 */
@jsonObject
export class TrunkSample_StraightRoofPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const straightPart: TrunkPart_Straight = <TrunkPart_Straight>this.part;
    straightPart.algorithmSpec_straightRoof_pipe();
    straightPart.algorithmSpec_straightRoof_connectionPin();
    straightPart.algorithmSpec_straightRoofAndRafter_steelStringGrabber();
    straightPart.algorithmSpec_straightRoofAndRafter_itemHolder();
    straightPart.algorithmSpec_straightRoofAndRafter_saddleHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const columnPart: TrunkPart_Column = (this.position as TrunkPosition).columnPart;
    columnPart.algorithmSpec_columnAndRoofStraight_tClamp();

    /// //////// 외부 ///////////

    // 피복 고정
    const fixingLevel: FixingLevel = <FixingLevel>this.struct.fixingWork.levelAC[this.level.index];
    fixingLevel.roofPosition.normalClipPart.normalClipSample.algorithmSpec();

    // 선반/균상다이
    if (this.level.index === 0) {
      const rackPosition: RackPosition = this.struct.rackWork.level1.wholePosition;
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tHolder();
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tClamp();
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame2_normalHolder();
      rackPosition.rackPart.algorithmPart();
    }

    // 관수 지관부
    if (this.level.index === 0) {
      const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
      wateringPosition.miniklerScreenPart.algorithmSpec_miniklerHangerHanger_pePipeHanger();
      wateringPosition.miniklerFogPart.algorithmSpec_miniklerHangerHanger_pePipeHanger();
      wateringPosition.hoseScreenPart.algorithmSpec_hose_fountainHoseHangerScreen();
    }

    // 운반레일
    if (this.level.index === this.basic.levelNumber - 1) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithmSpec_railAndFrame_railHook();
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 가로대 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specs = (<TrunkPart_Straight>this.part).straightPipeSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
