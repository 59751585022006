import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemScreennet } from "vhows-design/src/object/design/item/list/ItemScreennet";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkySwitchPosition } from "vhows-design/src/object/design/skylight/switches/SkySwitchPosition";
import { SkyCoverSample_Screennet } from "vhows-design/src/object/design/skylight/cover/SkyCoverSample_Screennet";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-25
 */
@jsonObject({
  knownTypes: [SkyCoverSample_Screennet],
})
export class SkyCoverPart_Screennet extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public skyScreennetSample: SkyCoverSample_Screennet; // 방충망 샘플

  // 아이템
  public skyScreennet_screennet: ItemScreennet;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _screennetWidth: number = 0; // 방충망 폭
  @jsonMember(Number)
  public _screennetLength: number = 0; // 방충망 길이
  @jsonMember(Number)
  public _screennetQuantity: number = 0; // 방충망 수량

  /**
   * 방충망 폭
   */
  public get screennetWidth(): number {
    return this._screennetWidth;
  }
  //
  public set screennetWidth(value: number) {
    this._screennetWidth = CommonUtil.fixFloat(value);

    // 알고리즘
    this.skyScreennetSample.algorithmSpec();
  }

  /**
   * 방충망 길이
   */
  public get screennetLength(): number {
    return this._screennetLength;
  }
  //
  public set screennetLength(value: number) {
    this._screennetLength = CommonUtil.fixFloat(value);

    // 알고리즘
  }

  /**
   * 방충망 수량
   */
  public get screennetQuantity(): number {
    return this._screennetQuantity;
  }
  //
  public set screennetQuantity(value: number) {
    this._screennetQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.skyScreennetSample = new SkyCoverSample_Screennet();

    this.sampleAC = [this.skyScreennetSample];

    // 아이템
    this.skyScreennet_screennet = new ItemScreennet();

    this.itemAC = [this.skyScreennet_screennet];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.skyScreennetSample = <SkyCoverSample_Screennet>this.sampleAC[0];

    // 아이템
    this.skyScreennet_screennet = <ItemScreennet>this.itemAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.skyScreennetSample.setDefaultData(
        0,
        CONST.ITEM_ID_SCREENNET,
        CONST.ITEM_NAME_SCREENNET,
        "천창 방충망",
        "일반, 1.5m, 100m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.skyScreennet_screennet.setDefaultData(
        0,
        CONST.ITEM_ID_SCREENNET,
        CONST.ITEM_NAME_SCREENNET,
        "천창 방충망",
        "일반, 1.5m, 100m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;
    const skySwitchPosition: SkySwitchPosition = this.struct.skySwitchWork.level1.skylightPosition;

    /// //////// 선택, 가시성 ///////////

    if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.skyScreennet_screennet.selected = false;
      this.skyScreennet_screennet.visible = true;
    } else {
      this.skyScreennet_screennet.selected = true;
      this.skyScreennet_screennet.visible = true;
    }

    /// //////// 수치 ///////////

    this.screennetWidth = skySwitchPosition.switchHeight;
    this.screennetLength = skyFramePosition.skylightLength;
    this.screennetQuantity = skySwitchPosition.switchWayValue * this.basic.buildingNumber;

    /// //////// 수량 ///////////

    let rateWidth: number = 1;
    let rateLength: number = 1;
    if (this.screennetWidth > 0) {
      rateWidth = Math.floor(this.skyScreennetSample.specWidth / this.screennetWidth);
      if (rateWidth <= 0) {
        rateWidth = 1;
      }
    }
    if (this.screennetLength > 0) {
      rateLength = this.skyScreennetSample.specLength / this.screennetLength;
    }
    this.skyScreennet_screennet.designQuantity = this.screennetQuantity / rateWidth / rateLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 방충망] 방충망 <- 천창 방충망 샘플
   */
  public algorithmSpec_skyScreennet_screennet(): void {
    this.skyScreennet_screennet.specs = this.skyScreennetSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
