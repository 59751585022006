import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Item } from "vhows-design/src/object/design/item/Item";
import { ItemBuildingMis } from "vhows-design/src/object/design/item/list/ItemBuildingMis";
import { DesignConst } from "vhows-design/src/common/constant/DesignConst";
import { MaterialItem } from "vhows-design/src/object/design/material/MaterialItem";
import { Estimate } from "vhows-design/src/object/design/estimate/Estimate";
import { EstimateItem } from "vhows-design/src/object/design/estimate/EstimateItem";

@jsonObject({
  knownTypes: [],
})
export class Material extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;

  /** 견적 객체 */
  public estimate: Estimate;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 자재 배열 */
  @jsonArrayMember(MaterialItem)
  public materialItemAC: MaterialItem[] = [];

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // View Variables
  //
  //--------------------------------------------------------------------------

  /** 팝업 가시성 */
  public popupVisible: boolean = false;
  /** 열기 메세지 */
  public openMessage: string = "";

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param design 설계 객체
   */
  constructor(design: Design = null, estimate: Estimate = null) {
    super();
    this.design = design;
    this.estimate = estimate;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design): void {
    this.design = design;
    for (const materialItem of this.materialItemAC) {
      materialItem.setAssociation(design, this);
    }
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {}

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * - 복사 후 꼭 견적품목 배열을 재정렬 해줘야함
   * @param origin 원본 객체
   */
  public deepCopy(origin: Material): void {
    // Variables

    this.design = origin.design; // 깊은 복사x

    // Remote Variables

    // 일반 자재품목 깊은 복사
    let i: number;
    for (i = 0; origin.materialItemAC && i < origin.materialItemAC.length; i++) {
      this.materialItemAC[i] = new MaterialItem(this, this.design);
      this.materialItemAC[i].deepCopy(origin.materialItemAC[i]);
    }
    if (this.materialItemAC[i]) {
      // 줄어든 항목이 있다면 삭제 (ex.할인 상품이 없어진 경우)
      this.materialItemAC.splice(i, 1000);
    }
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  /**
   * 견적서로 자재 만들기
   * - 설계가 바뀌던 바뀌지 않던, 견적은 항상 새로 만든다는 것을 유념할 것
   * @param design 설계 객체
   * @param isPopup
   */
  public makeMaterialFromEstimate(design: Design, isPopup: boolean) {
    // 데이터 초기화
    this.materialItemAC = new Array();
    //
    // 1.설계품목으로 견적품목 생성
    //
    if (isPopup) {
      this.estimate.makeEstimateFromDesign(design, this.estimate.totalPrice);
    }
    //
    // 2. 견적 품목으로 자재품목 생성
    //
    for (const item of this.estimate.normalEstimateItemAC) {
      this.mergeEstimateItemToMaterialItem(item);
    }
    //
    // 3. 자재 수량 계산
    //
    this.calculateEachMaterialItem(this.materialItemAC);
    //
    // 4. 정렬
    //
    this.materialItemAC.sort((a, b) => (a.productId < b.productId ? -1 : a.productId > b.productId ? 1 : 0));
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 자재 품목
  //----------------------------------

  /**
   * 견적상품을 자재품목배열로 병합하기
   * @param item 병합할 설계품목
   */
  protected mergeEstimateItemToMaterialItem(item: EstimateItem): void {
    let materialItemTemp: MaterialItem;
    const estimateQuantityRaw: number = item.designQuantity;
    // 비용 품목
    const costItems = [
      CONST.ITEM_NAME_BUILDING_MIS,
      CONST.ITEM_NAME_OVERLAP_MATERIAL_SAVING,
      CONST.ITEM_NAME_MATERIAL_DISCOUNT,
      CONST.ITEM_NAME_CUSTOM_EXPENSE,
      CONST.ITEM_NAME_WELDING,
      CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
      CONST.ITEM_NAME_EQUIPMENT_EXPENSE,
      CONST.ITEM_NAME_CUSTOM_LABOR_COST,
      CONST.ITEM_NAME_BUILDING_DISCOUNT,
      "운반비",
      "시공 인건비",
    ];

    // 미가공 견적 수량이 0인 경우 무시함
    if (estimateQuantityRaw === 0) {
      return;
    }

    if (costItems.indexOf(item.productName) !== -1) {
      return;
    }

    // 동일한 상품 합치기 : 그 외의 모든 상품
    const { designQuantity } = item;

    // 동일 상품은 수량을 더하기
    for (const materialItemExist of this.materialItemAC) {
      // 일반 견적의 동일 상품 기준 = 상품명, 규격, 상표 리스트가 같고 공사, 작업명이 같은 것 or 골조/개폐/피복 공사 인 것
      if (
        materialItemExist.productName === item.productName &&
        materialItemExist.specs === item.specs &&
        materialItemExist.brands === item.brands
      ) {
        // 수량을 더하기
        materialItemExist.designQuantity = CommonUtil.fixFloat(materialItemExist.designQuantity + designQuantity);
        materialItemExist.estimateQuantityRaw = CommonUtil.fixFloat(
          materialItemExist.estimateQuantityRaw + estimateQuantityRaw,
        );

        // 용도가 기존에 없는 용도인 경우 추가
        if (
          materialItemExist.purpose.indexOf(item.purpose) < 0 &&
          materialItemExist.purpose.indexOf(item.purpose + CONST.DELIMITER_PURPOSE) < 0 &&
          materialItemExist.purpose.indexOf(CONST.DELIMITER_PURPOSE + item.purpose) < 0
        ) {
          materialItemExist.purpose += CONST.DELIMITER_PURPOSE + item.purpose;
        }

        return;
      }
    }

    // 동일한 상품이 없으면 새로 추가
    materialItemTemp = new MaterialItem(this, this.design);
    materialItemTemp.convertEstimateItemToMaterialItem(item);
    this.materialItemAC.push(materialItemTemp);
  }

  //----------------------------------
  // 자재 수량
  //----------------------------------

  /**
   * 모든 개별 자재품목의 수량 계산하기
   * - 수량
   * @param materialItems 자재품목 배열
   */
  protected calculateEachMaterialItem(materialItems: MaterialItem[]): void {
    for (const materialItem of materialItems) {
      // 견적 수량 계산
      materialItem.calculateEstimateQuantity();
      // 견적 과세 형태 지정
    }
  }
}
