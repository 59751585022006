import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Item } from "vhows-design/src/object/design/item/Item";
import { ItemBuildingMis } from "vhows-design/src/object/design/item/list/ItemBuildingMis";
import { EstimateItem } from "vhows-design/src/object/design/estimate/EstimateItem";
import { DesignConst } from "vhows-design/src/common/constant/DesignConst";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-04-12
 */
@jsonObject({
  knownTypes: [],
})
export class Estimate extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;

  /** 공과잡비 견적품목 배열 <--- TODO 단일 품목으로 변경!!!!!!!!!! */
  public buildingMisEstimateItems: EstimateItem[] = [];
  /** 자재 할인 견적품목 */
  public materialDiscountEstimateItem: EstimateItem = null;
  /** 시공 할인 견적품목 */
  public buildingDiscountEstimateItem: EstimateItem = null;
  /** 중복 자재 절약 견적품목 */
  public overlapMaterialSavingEstimateItem: EstimateItem = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 일반견적배열 설계 자재비

  /** 매입 설계 자재비 (공급가액) */
  public designMaterialSupplyPurchase: number = 0;
  /** 매입 설계 자재비 (부가세) */
  public designMaterialVatPurchase: number = 0;
  /** 매입 설계 자재비 (공급대가) */
  public designMaterialPricePurchase: number = 0;

  /** 단가적용 설계 자재비 (공급가액) */
  public designMaterialSupplyUnit: number = 0;
  /** 단가적용 설계 자재비 (부가세) */
  public designMaterialVatUnit: number = 0;
  /** 단가적용 설계 자재비 (공급대가) */
  public designMaterialPriceUnit: number = 0;

  /** 배율적용 설계 자재비 (공급가액) */
  public designMaterialSupplyRated: number = 0;
  /** 배율적용 설계 자재비 (부가세) */
  public designMaterialVatRated: number = 0;
  /** 배율적용 설계 자재비 (공급대가) */
  public designMaterialPriceRated: number = 0;

  /** 설계 자재비 (공급가액) */
  public designMaterialSupply: number = 0;
  /** 설계 자재비 (부가세) */
  public designMaterialVat: number = 0;
  /** 설계 자재비 (공급대가) */
  public designMaterialPrice: number = 0;

  // 그룹견적배열 설계 자재비

  // /** 매입 그룹 설계 자재비 (공급가액) */
  // public groupDesignMaterialSupplyPurchase: number = 0;
  // /** 매입 그룹 설계 자재비 (부가세) */
  // public groupDesignMaterialVatPurchase: number = 0;
  // /** 매입 그룹 설계 자재비 (공급대가) */
  // public groupDesignMaterialPricePurchase: number = 0;
  //
  // /** 단가적용 그룹 설계 자재비 (공급가액) */
  // public groupDesignMaterialSupplyUnit: number = 0;
  // /** 단가적용 그룹 설계 자재비 (부가세) */
  // public groupDesignMaterialVatUnit: number = 0;
  // /** 단가적용 그룹 설계 자재비 (공급대가) */
  // public groupDesignMaterialPriceUnit: number = 0;
  //
  // /** 배율적용 그룹 설계 자재비 (공급가액) */
  // public groupDesignMaterialSupplyRated: number = 0;
  // /** 배율적용 그룹 설계 자재비 (부가세) */
  // public groupDesignMaterialVatRated: number = 0;
  // /** 배율적용 그룹 설계 자재비 (공급대가) */
  // public groupDesignMaterialPriceRated: number = 0;
  //
  // /** 그룹 설계 자재비 (공급가액) */
  // public groupDesignMaterialSupply: number = 0;
  // /** 그룹 설계 자재비 (부가세) */
  // public groupDesignMaterialVat: number = 0;
  // /** 그룹 설계 자재비 (공급대가) */
  // public groupDesignMaterialPrice: number = 0;

  // 직전 데이터

  /** 직전 일반 견적품목 배열 */
  public normalEstimateItemsBefore: EstimateItem[] = [];
  /** 직전 그룹 견적품목 배열 */
  // public groupEstimateItemsBefore: EstimateItem[] = [];

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 일반 견적품목 배열 */
  @jsonArrayMember(EstimateItem)
  public normalEstimateItemAC: EstimateItem[] = [];
  /** 그룹 견적품목 배열 */
  @jsonArrayMember(EstimateItem)
  public groupEstimateItemAC: EstimateItem[] = [];

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 견적 상태 */
  @jsonMember(String)
  public estimateStatus: string = null;
  /** 상단 메모1 */
  @jsonMember(String)
  public upperMemo1: string = null;

  // 총 재료비

  /** 매입 총 재료비 (공급가액) */
  @jsonMember(Number)
  public totalMaterialCostSupplyPurchase: number = 0;
  /** 매입 총 재료비 (부가세) */
  @jsonMember(Number)
  public totalMaterialCostVatPurchase: number = 0;
  /** 매입 총 재료비 (공급대가) */
  @jsonMember(Number)
  public totalMaterialCostPricePurchase: number = 0;

  /** 단가적용 총 재료비 (공급가액) */
  @jsonMember(Number)
  public totalMaterialCostSupplyUnit: number = 0;
  /** 단가적용 총 재료비 (부가세) */
  @jsonMember(Number)
  public totalMaterialCostVatUnit: number = 0;
  /** 단가적용 총 재료비 (공급대가) */
  @jsonMember(Number)
  public totalMaterialCostPriceUnit: number = 0;

  /** 배율적용 총 재료비 (공급가액) */
  @jsonMember(Number)
  public totalMaterialCostSupplyRated: number = 0;
  /** 배율적용 총 재료비 (부가세) */
  @jsonMember(Number)
  public totalMaterialCostVatRated: number = 0;
  /** 배율적용 총 재료비 (공급대가) */
  @jsonMember(Number)
  public totalMaterialCostPriceRated: number = 0;

  /** 총 재료비 (공급가액) */
  @jsonMember(Number)
  public totalMaterialCostSupply: number = 0;
  /** 총 재료비 (부가세) */
  @jsonMember(Number)
  public totalMaterialCostVat: number = 0;
  /** 총 재료비 (공급대가) */
  @jsonMember(Number)
  public totalMaterialCostPrice: number = 0;

  // 총 경비

  /** 매입 총 경비 (공급가액) */
  @jsonMember(Number)
  public totalExpenseSupplyPurchase: number = 0;
  /** 매입 총 경비 (부가세) */
  @jsonMember(Number)
  public totalExpenseVatPurchase: number = 0;
  /** 매입 총 경비 (공급대가) */
  @jsonMember(Number)
  public totalExpensePricePurchase: number = 0;

  /** 단가적용 총 경비 (공급가액) */
  @jsonMember(Number)
  public totalExpenseSupplyUnit: number = 0;
  /** 단가적용 총 경비 (부가세) */
  @jsonMember(Number)
  public totalExpenseVatUnit: number = 0;
  /** 단가적용 총 경비 (공급대가) */
  @jsonMember(Number)
  public totalExpensePriceUnit: number = 0;

  /** 배율적용 총 경비 (공급가액) */
  @jsonMember(Number)
  public totalExpenseSupplyRated: number = 0;
  /** 배율적용 총 경비 (부가세) */
  @jsonMember(Number)
  public totalExpenseVatRated: number = 0;
  /** 배율적용 총 경비 (공급대가) */
  @jsonMember(Number)
  public totalExpensePriceRated: number = 0;

  /** 총 경비 (공급가액) */
  @jsonMember(Number)
  public totalExpenseSupply: number = 0;
  /** 총 경비 (부가세) */
  @jsonMember(Number)
  public totalExpenseVat: number = 0;
  /** 총 경비 (공급대가) */
  @jsonMember(Number)
  public totalExpensePrice: number = 0;

  // 총 노무비

  /** 매입 총 노무비 (공급가액) */
  @jsonMember(Number)
  public totalLaborCostSupplyPurchase: number = 0;
  /** 매입 총 노무비 (부가세) */
  @jsonMember(Number)
  public totalLaborCostVatPurchase: number = 0;
  /** 매입 총 노무비 (공급대가) */
  @jsonMember(Number)
  public totalLaborCostPricePurchase: number = 0;

  /** 단가적용 총 노무비 (공급가액) */
  @jsonMember(Number)
  public totalLaborCostSupplyUnit: number = 0;
  /** 단가적용 총 노무비 (부가세) */
  @jsonMember(Number)
  public totalLaborCostVatUnit: number = 0;
  /** 단가적용 총 노무비 (공급대가) */
  @jsonMember(Number)
  public totalLaborCostPriceUnit: number = 0;

  /** 배율적용 총 노무비 (공급가액) */
  @jsonMember(Number)
  public totalLaborCostSupplyRated: number = 0;
  /** 배율적용 총 노무비 (부가세) */
  @jsonMember(Number)
  public totalLaborCostVatRated: number = 0;
  /** 배율적용 총 노무비 (공급대가) */
  @jsonMember(Number)
  public totalLaborCostPriceRated: number = 0;

  /** 총 노무비 (공급가액) */
  @jsonMember(Number)
  public totalLaborCostSupply: number = 0;
  /** 총 노무비 (부가세) */
  @jsonMember(Number)
  public totalLaborCostVat: number = 0;
  /** 총 노무비 (공급대가) */
  @jsonMember(Number)
  public totalLaborCostPrice: number = 0;

  // 총 자재비

  /** 매입 총 자재비 (공급가액) */
  @jsonMember(Number)
  public totalMaterialSupplyPurchase: number = 0;
  /** 매입 총 자재비 (부가세) */
  @jsonMember(Number)
  public totalMaterialVatPurchase: number = 0;
  /** 매입 총 자재비 (공급대가) */
  @jsonMember(Number)
  public totalMaterialPricePurchase: number = 0;

  /** 단가적용 총 자재비 (공급가액) */
  @jsonMember(Number)
  public totalMaterialSupplyUnit: number = 0;
  /** 단가적용 총 자재비 (부가세) */
  @jsonMember(Number)
  public totalMaterialVatUnit: number = 0;
  /** 단가적용 총 자재비 (공급대가) */
  @jsonMember(Number)
  public totalMaterialPriceUnit: number = 0;

  /** 배율적용 총 자재비 (공급가액) */
  @jsonMember(Number)
  public totalMaterialSupplyRated: number = 0;
  /** 배율적용 총 자재비 (부가세) */
  @jsonMember(Number)
  public totalMaterialVatRated: number = 0;
  /** 배율적용 총 자재비 (공급대가) */
  @jsonMember(Number)
  public totalMaterialPriceRated: number = 0;

  /** 총 자재비 (공급가액) */
  @jsonMember(Number)
  public totalMaterialSupply: number = 0;
  /** 총 자재비 (부가세) */
  @jsonMember(Number)
  public totalMaterialVat: number = 0;
  /** 총 자재비 (공급대가) */
  @jsonMember(Number)
  public totalMaterialPrice: number = 0;

  // 총 시공비

  /** 매입 총 시공비 (공급가액) */
  @jsonMember(Number)
  public totalBuildingSupplyPurchase: number = 0;
  /** 매입 총 시공비 (부가세) */
  @jsonMember(Number)
  public totalBuildingVatPurchase: number = 0;
  /** 매입 총 시공비 (공급대가) */
  @jsonMember(Number)
  public totalBuildingPricePurchase: number = 0;

  /** 단가적용 총 시공비 (공급가액) */
  @jsonMember(Number)
  public totalBuildingSupplyUnit: number = 0;
  /** 단가적용 총 시공비 (부가세) */
  @jsonMember(Number)
  public totalBuildingVatUnit: number = 0;
  /** 단가적용 총 시공비 (공급대가) */
  @jsonMember(Number)
  public totalBuildingPriceUnit: number = 0;

  /** 배율적용 총 시공비 (공급가액) */
  @jsonMember(Number)
  public totalBuildingSupplyRated: number = 0;
  /** 배율적용 총 시공비 (부가세) */
  @jsonMember(Number)
  public totalBuildingVatRated: number = 0;
  /** 배율적용 총 시공비 (공급대가) */
  @jsonMember(Number)
  public totalBuildingPriceRated: number = 0;

  /** 총 시공비 (공급가액) */
  @jsonMember(Number)
  public totalBuildingSupply: number = 0;
  /** 총 시공비 (부가세) */
  @jsonMember(Number)
  public totalBuildingVat: number = 0;
  /** 총 시공비 (공급대가) */
  @jsonMember(Number)
  public totalBuildingPrice: number = 0;

  // 총 금액

  /** 매입 총 금액 (공급가액) */
  @jsonMember(Number)
  public totalSupplyPurchase: number = 0;
  /** 매입 총 금액 (부가세) */
  @jsonMember(Number)
  public totalVatPurchase: number = 0;
  /** 매입 총 금액 (공급대가) */
  @jsonMember(Number)
  public totalPricePurchase: number = 0;

  /** 단가적용 총 금액 (공급가액) */
  @jsonMember(Number)
  public totalSupplyUnit: number = 0;
  /** 단가적용 총 금액 (부가세) */
  @jsonMember(Number)
  public totalVatUnit: number = 0;
  /** 단가적용 총 금액 (공급대가) */
  @jsonMember(Number)
  public totalPriceUnit: number = 0;

  /** 배율적용 총 금액 (공급가액) */
  @jsonMember(Number)
  public totalSupplyRated: number = 0;
  /** 배율적용 총 금액 (부가세) */
  @jsonMember(Number)
  public totalVatRated: number = 0;
  /** 배율적용 총 금액 (공급대가) */
  @jsonMember(Number)
  public totalPriceRated: number = 0;

  /** 총 금액 (공급가액) */
  @jsonMember(Number)
  public totalSupply: number = 0;
  /** 총 금액 (부가세) */
  @jsonMember(Number)
  public totalVat: number = 0;
  /** 총 금액 (공급대가) */
  @jsonMember(Number)
  public totalPrice: number = 0;

  // 비율

  /** 최종 배율 */
  @jsonMember(Number)
  public rateFinal: number = 1;
  /** 단가 배율 */
  @jsonMember(Number)
  public rateUnit: number = 1;
  /** 대외용 할인율 - 배율이 적용된 매출가 기준의 할인율 */
  @jsonMember(Number)
  public rateDiscount: number = 0;

  // 시스템

  /** 변경일시 */
  @jsonMember(Date)
  public lastUpdate: Date;

  //--------------------------------------------------------------------------
  //
  // View Variables
  //
  //--------------------------------------------------------------------------

  /** 견적 팝업 가시성 */
  public popupVisible: boolean = false;
  /** 견적 모드 인덱스 */
  public modeSelectedIndex: number = CONST.ID_ESTIMATE_WORK;
  /** 견적 열기 메세지 */
  public openMessage: string = "";

  /** 최종 배율 페선트 */
  public get rateFinalPercent(): string {
    return `${CommonUtil.round(this.rateFinal * 100, 1)}%`;
  }

  /** 단가 배율 페선트 */
  public get rateUnitPercent(): string {
    return `${CommonUtil.round(this.rateUnit * 100, 1)}%`;
  }

  /** 대외용 할인율 퍼센트 */
  public get rateDiscountPercent(): string {
    return `${CommonUtil.round(this.rateDiscount * 100, 1)}%`;
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   * @param design 설계 객체
   */
  constructor(design: Design = null) {
    super();
    this.design = design;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design): void {
    this.design = design;
    for (const estimateItem of this.normalEstimateItemAC) {
      estimateItem.setAssociation(design, this);
    }
    // for (const estimateItem of this.groupEstimateItemAC) {
    //   estimateItem.setAssociation(design, this);
    // }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 일반 견적품목
    if (this.normalEstimateItemAC) {
      this.buildingMisEstimateItems = [];
      for (const estimateItem of this.normalEstimateItemAC) {
        if (estimateItem.productId === CONST.ITEM_ID_BUILDING_MIS) {
          // 공과잡비 (2개이상 가능)
          this.buildingMisEstimateItems.push(estimateItem);
        } else if (estimateItem.productId === CONST.ITEM_ID_MATERIAL_DISCOUNT) {
          // 자재 할인 (1개만 가능)
          this.materialDiscountEstimateItem = estimateItem;
        } else if (estimateItem.productId === CONST.ITEM_ID_BUILDING_DISCOUNT) {
          // 시공 할인 (1개만 가능)
          this.buildingDiscountEstimateItem = estimateItem;
        }
      }
    }

    // 그룹 견적품목
    // 공과잡비, 자재할인, 시공할인 견적상품은 두 배열에 같은 객체가 들어가도록 연관시킴
    // if (this.groupEstimateItemAC) {
    //   for (let i = 0; i < this.groupEstimateItemAC.length; i++) {
    //     if (this.groupEstimateItemAC[i].productId === CONST.ITEM_ID_BUILDING_MIS) {
    //       // 공과잡비 (2개이상 가능)
    //       this.groupEstimateItemAC.splice(i, 1, this.buildingMisEstimateItems[0]);
    //     } else if (this.groupEstimateItemAC[i].productId === CONST.ITEM_ID_MATERIAL_DISCOUNT) {
    //       // 자재 할인 (1개만 가능)
    //       this.groupEstimateItemAC.splice(i, 1, this.materialDiscountEstimateItem);
    //     } else if (this.groupEstimateItemAC[i].productId === CONST.ITEM_ID_BUILDING_DISCOUNT) {
    //       // 시공 할인 (1개만 가능)
    //       this.groupEstimateItemAC.splice(i, 1, this.buildingDiscountEstimateItem);
    //     } else if (this.groupEstimateItemAC[i].productId === CONST.ITEM_ID_OVERLAP_MATERIAL_SAVING) {
    //       // 중복 자재 절약
    //       this.overlapMaterialSavingEstimateItem = this.groupEstimateItemAC[i];
    //     }
    //   }
    // }
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {}

  /**
   * 저장된 아이디값 세팅
   */
  public setSavedId(savedEstimate: Estimate): void {
    this.id = savedEstimate.id;

    // 일반 견적
    if (this.normalEstimateItemAC && savedEstimate.normalEstimateItemAC) {
      for (let i: number = 0; i < this.normalEstimateItemAC.length; i++) {
        const normalEstimateItem: EstimateItem = this.normalEstimateItemAC[i];

        for (let j: number = 0; j < savedEstimate.normalEstimateItemAC.length; j++) {
          const savedNormalEstimateItem: EstimateItem = savedEstimate.normalEstimateItemAC[j];
          if (normalEstimateItem.index === savedNormalEstimateItem.index) {
            normalEstimateItem.id = savedNormalEstimateItem.id;
            break;
          }
        }
      }
    }

    // 그룹 견적
    // if (this.groupEstimateItemAC && savedEstimate.groupEstimateItemAC) {
    //   for (let k: number = 0; k < this.groupEstimateItemAC.length; k++) {
    //     const groupEstimateItem: EstimateItem = this.groupEstimateItemAC[k];
    //
    //     for (let l: number = 0; l < savedEstimate.groupEstimateItemAC.length; l++) {
    //       const savedGroupEstimateItem: EstimateItem = savedEstimate.groupEstimateItemAC[l];
    //       if (groupEstimateItem.index === savedGroupEstimateItem.index) {
    //         groupEstimateItem.id = savedGroupEstimateItem.id;
    //         break;
    //       }
    //     }
    //   }
    // }
  }

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * - 복사 후 꼭 견적품목 배열을 재정렬 해줘야함
   * @param origin 원본 객체
   */
  public deepCopy(origin: Estimate): void {
    // Variables

    this.design = origin.design; // 깊은 복사x
    // this.buildingMisEstimateItems = null; // 레퍼런스 변수 설정에서
    // this.materialDiscountEstimateItem = null; // 레퍼런스 변수 설정에서
    // this.buildingDiscountEstimateItem = null; // 레퍼런스 변수 설정에서
    // this.overlapMaterialSavingEstimateItem = null; // 레퍼런스 변수 설정에서

    // 일반견적배열 설계 자재비
    this.designMaterialSupplyPurchase = origin.designMaterialSupplyPurchase;
    this.designMaterialVatPurchase = origin.designMaterialVatPurchase;
    this.designMaterialPricePurchase = origin.designMaterialPricePurchase;

    this.designMaterialSupplyUnit = origin.designMaterialSupplyUnit;
    this.designMaterialVatUnit = origin.designMaterialVatUnit;
    this.designMaterialPriceUnit = origin.designMaterialPriceUnit;

    this.designMaterialSupplyRated = origin.designMaterialSupplyRated;
    this.designMaterialVatRated = origin.designMaterialVatRated;
    this.designMaterialPriceRated = origin.designMaterialPriceRated;

    this.designMaterialSupply = origin.designMaterialSupply;
    this.designMaterialVat = origin.designMaterialVat;
    this.designMaterialPrice = origin.designMaterialPrice;

    // 그룹견적배열 설계 자재비
    // this.groupDesignMaterialSupplyPurchase = origin.groupDesignMaterialSupplyPurchase;
    // this.groupDesignMaterialVatPurchase = origin.groupDesignMaterialVatPurchase;
    // this.groupDesignMaterialPricePurchase = origin.groupDesignMaterialPricePurchase;
    //
    // this.groupDesignMaterialSupplyUnit = origin.groupDesignMaterialSupplyUnit;
    // this.groupDesignMaterialVatUnit = origin.groupDesignMaterialVatUnit;
    // this.groupDesignMaterialPriceUnit = origin.groupDesignMaterialPriceUnit;
    //
    // this.groupDesignMaterialSupplyRated = origin.groupDesignMaterialSupplyRated;
    // this.groupDesignMaterialVatRated = origin.groupDesignMaterialVatRated;
    // this.groupDesignMaterialPriceRated = origin.groupDesignMaterialPriceRated;
    //
    // this.groupDesignMaterialSupply = origin.groupDesignMaterialSupply;
    // this.groupDesignMaterialVat = origin.groupDesignMaterialVat;
    // this.groupDesignMaterialPrice = origin.groupDesignMaterialPrice;

    // Remote Variables

    // 일반 견적품목과 그룹 견적품목 깊은 복사
    let i: number;
    for (i = 0; origin.normalEstimateItemAC && i < origin.normalEstimateItemAC.length; i++) {
      this.normalEstimateItemAC[i] = new EstimateItem(this, this.design);
      this.normalEstimateItemAC[i].deepCopy(origin.normalEstimateItemAC[i]);
    }
    if (this.normalEstimateItemAC[i]) {
      // 줄어든 항목이 있다면 삭제 (ex.할인 상품이 없어진 경우)
      this.normalEstimateItemAC.splice(i, 1000);
    }
    // for (i = 0; origin.groupEstimateItemAC && i < origin.groupEstimateItemAC.length; i++) {
    //   this.groupEstimateItemAC[i] = new EstimateItem(this, this.design);
    //   this.groupEstimateItemAC[i].deepCopy(origin.groupEstimateItemAC[i]);
    // }
    // if (this.groupEstimateItemAC[i]) {
    //   // 줄어든 항목이 있다면 삭제 (ex.할인 상품이 없어진 경우)
    //   this.groupEstimateItemAC.splice(i, 1000);
    // }

    this.estimateStatus = origin.estimateStatus;
    this.upperMemo1 = origin.upperMemo1;

    // 총 재료비
    this.totalMaterialCostSupplyPurchase = origin.totalMaterialCostSupplyPurchase;
    this.totalMaterialCostVatPurchase = origin.totalMaterialCostVatPurchase;
    this.totalMaterialCostPricePurchase = origin.totalMaterialCostPricePurchase;

    this.totalMaterialCostSupplyUnit = origin.totalMaterialCostSupplyUnit;
    this.totalMaterialCostVatUnit = origin.totalMaterialCostVatUnit;
    this.totalMaterialCostPriceUnit = origin.totalMaterialCostPriceUnit;

    this.totalMaterialCostSupplyRated = origin.totalMaterialCostSupplyRated;
    this.totalMaterialCostVatRated = origin.totalMaterialCostVatRated;
    this.totalMaterialCostPriceRated = origin.totalMaterialCostPriceRated;

    this.totalMaterialCostSupply = origin.totalMaterialCostSupply;
    this.totalMaterialCostVat = origin.totalMaterialCostVat;
    this.totalMaterialCostPrice = origin.totalMaterialCostPrice;

    // 총 경비
    this.totalExpenseSupplyPurchase = origin.totalExpenseSupplyPurchase;
    this.totalExpenseVatPurchase = origin.totalExpenseVatPurchase;
    this.totalExpensePricePurchase = origin.totalExpensePricePurchase;

    this.totalExpenseSupplyUnit = origin.totalExpenseSupplyUnit;
    this.totalExpenseVatUnit = origin.totalExpenseVatUnit;
    this.totalExpensePriceUnit = origin.totalExpensePriceUnit;

    this.totalExpenseSupplyRated = origin.totalExpenseSupplyRated;
    this.totalExpenseVatRated = origin.totalExpenseVatRated;
    this.totalExpensePriceRated = origin.totalExpensePriceRated;

    this.totalExpenseSupply = origin.totalExpenseSupply;
    this.totalExpenseVat = origin.totalExpenseVat;
    this.totalExpensePrice = origin.totalExpensePrice;

    // 총 노무비
    this.totalLaborCostSupplyPurchase = origin.totalLaborCostSupplyPurchase;
    this.totalLaborCostVatPurchase = origin.totalLaborCostVatPurchase;
    this.totalLaborCostPricePurchase = origin.totalLaborCostPricePurchase;

    this.totalLaborCostSupplyUnit = origin.totalLaborCostSupplyUnit;
    this.totalLaborCostVatUnit = origin.totalLaborCostVatUnit;
    this.totalLaborCostPriceUnit = origin.totalLaborCostPriceUnit;

    this.totalLaborCostSupplyRated = origin.totalLaborCostSupplyRated;
    this.totalLaborCostVatRated = origin.totalLaborCostVatRated;
    this.totalLaborCostPriceRated = origin.totalLaborCostPriceRated;

    this.totalLaborCostSupply = origin.totalLaborCostSupply;
    this.totalLaborCostVat = origin.totalLaborCostVat;
    this.totalLaborCostPrice = origin.totalLaborCostPrice;

    // 총 자재비

    this.totalMaterialSupplyPurchase = origin.totalMaterialSupplyPurchase;
    this.totalMaterialVatPurchase = origin.totalMaterialVatPurchase;
    this.totalMaterialPricePurchase = origin.totalMaterialPricePurchase;

    this.totalMaterialSupplyUnit = origin.totalMaterialSupplyUnit;
    this.totalMaterialVatUnit = origin.totalMaterialVatUnit;
    this.totalMaterialPriceUnit = origin.totalMaterialPriceUnit;

    this.totalMaterialSupplyRated = origin.totalMaterialSupplyRated;
    this.totalMaterialVatRated = origin.totalMaterialVatRated;
    this.totalMaterialPriceRated = origin.totalMaterialPriceRated;

    this.totalMaterialSupply = origin.totalMaterialSupply;
    this.totalMaterialVat = origin.totalMaterialVat;
    this.totalMaterialPrice = origin.totalMaterialPrice;

    // 총 시공비

    this.totalBuildingSupplyPurchase = origin.totalBuildingSupplyPurchase;
    this.totalBuildingVatPurchase = origin.totalBuildingVatPurchase;
    this.totalBuildingPricePurchase = origin.totalBuildingPricePurchase;

    this.totalBuildingSupplyUnit = origin.totalBuildingSupplyUnit;
    this.totalBuildingVatUnit = origin.totalBuildingVatUnit;
    this.totalBuildingPriceUnit = origin.totalBuildingPriceUnit;

    this.totalBuildingSupplyRated = origin.totalBuildingSupplyRated;
    this.totalBuildingVatRated = origin.totalBuildingVatRated;
    this.totalBuildingPriceRated = origin.totalBuildingPriceRated;

    this.totalBuildingSupply = origin.totalBuildingSupply;
    this.totalBuildingVat = origin.totalBuildingVat;
    this.totalBuildingPrice = origin.totalBuildingPrice;

    // 총 금액

    this.totalSupplyPurchase = origin.totalSupplyPurchase;
    this.totalVatPurchase = origin.totalVatPurchase;
    this.totalPricePurchase = origin.totalPricePurchase;

    this.totalSupplyUnit = origin.totalSupplyUnit;
    this.totalVatUnit = origin.totalVatUnit;
    this.totalPriceUnit = origin.totalPriceUnit;

    this.totalSupplyRated = origin.totalSupplyRated;
    this.totalVatRated = origin.totalVatRated;
    this.totalPriceRated = origin.totalPriceRated;

    this.totalSupply = origin.totalSupply;
    this.totalVat = origin.totalVat;
    this.totalPrice = origin.totalPrice;

    // 비율
    this.rateFinal = origin.rateFinal;
    this.rateUnit = origin.rateUnit;
    this.rateDiscount = origin.rateDiscount;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  /**
   * 설계로 견적서 만들기
   * - 설계가 바뀌던 바뀌지 않던, 견적은 항상 새로 만든다는 것을 유념할 것
   * @param design 설계 객체
   * @param totalPriceBefore 직전 총 금액: 설계가 변경되었는지 확인 (null인 경우 첫 견적으로 간주)
   * @return 재견적을 하여 비용이 바뀐 경우 true, 첫 견적 또는 비용이 바뀌지 않은 경우 false
   */
  public makeEstimateFromDesign(design: Design, totalPriceBefore: number = null): boolean {
    //
    // [전처리 단계]
    //
    // 직전 데이터 저장
    this.normalEstimateItemsBefore = this.normalEstimateItemAC;
    // this.groupEstimateItemsBefore = this.groupEstimateItemAC;
    // 데이터 초기화
    this.normalEstimateItemAC = new Array();
    this.groupEstimateItemAC = new Array();
    this.buildingMisEstimateItems = new Array();
    //
    // 1.설계품목으로 견적품목 생성
    //
    this.makeEstimateItemsFromDesign(design);
    //
    // 2.개별 견적품목 금액 계산
    //
    this.calculateEachEstimateItem(this.normalEstimateItemAC);
    // this.calculateEachEstimateItem(this.groupEstimateItemAC);
    //
    // 3.자재비 계산
    //
    // 3-1.설계 자재비만 먼저 계산 (공과잡비 계산과 중복 절약 처리를 위해 선행)
    this.calculateDesignMaterialPrice();
    // 3-2.공과잡비 상품 계산
    this.calculateBuildingMisItemPrice();
    // 3-3.중복 자재 절약 상품 계산
    this.calculateOverlapSavingItemPrice();
    // 3-4.직전 견적서의 자재 할인 상품 추가
    this.addMaterialDiscountEstimateItem();
    //
    // 4.견적서 총 금액 계산
    //
    this.calculateEstimateTotalPrice();
    //
    // [후처리 단계]
    //
    // 견적이 바뀌었는지 판단 (자재 할인 상품 처리)
    const returnFlag: boolean = this.decideEstimateChanging(totalPriceBefore);
    // 최종 배율 계산
    this.calculateRateTotal();
    // 대외용 할인율 계산
    this.calculateRateDiscount();
    // 견적품목 순서 재정렬
    this.rearrangeEstimateItems();

    return returnFlag;
  }

  /**
   * 견적서 읽기 전용으로 불러오기
   * @param design 설계 객체
   */
  public loadEstimateReadOnly(design: Design): void {
    // 견적품목 재배치
    this.rearrangeEstimateItems();
  }

  /**
   * 특정 상품의 매출가 변경에 따른 전체 금액 재계산하기
   * - 견적내에 동일한 상품의 매출가를 모두 동일하게 변경해줌
   * @param sampleEstimateItem 단가를 동일하게 변경할 표본 견적 상품 (null인 경우, 전체 금액 계산만 수행)
   * @param estimateArrayType 견적 배열 형태 - NORMAL / GROUP (그룹 견적배열이 수정되는 경우, 그룹 설계 자재비로 총 금액 계산)
   */
  public recalculateEstimatePrice(
    sampleEstimateItem: EstimateItem = null,
    estimateArrayType: number = CONST.ID_ESTIMATE_ARRAY_NORMAL,
  ): void {
    // 표본 견적 상품이 있는 경우, 견적 전체에 동일한 상품의 단가 통일 및 금액 계산
    // 일반견적품목과 그룹견적품목을 일치시키는 역할도함
    // 상품아이디, 상품명, 규격, 상표, 단가기준 매출가가 같은 경우 동일 상품 취급
    // (단가기준 매출가는 임의 상품 때문에 넣은 조건)
    if (sampleEstimateItem) {
      for (const estimateItem of this.normalEstimateItemAC) {
        if (
          estimateItem.productId === sampleEstimateItem.productId &&
          estimateItem.productName === sampleEstimateItem.productName &&
          estimateItem.specs === sampleEstimateItem.specs &&
          estimateItem.brands === sampleEstimateItem.brands &&
          estimateItem.sellingSupplyUnit === sampleEstimateItem.sellingSupplyUnit
        ) {
          estimateItem.sellingSupply = sampleEstimateItem.sellingSupply;
          estimateItem.calculateItemPrice();
        }
      }
      // for (const estimateItem of this.groupEstimateItemAC) {
      //   if (
      //     estimateItem.productId === sampleEstimateItem.productId &&
      //     estimateItem.productName === sampleEstimateItem.productName &&
      //     estimateItem.specs === sampleEstimateItem.specs &&
      //     estimateItem.brands === sampleEstimateItem.brands &&
      //     estimateItem.sellingSupplyUnit === sampleEstimateItem.sellingSupplyUnit
      //   ) {
      //     estimateItem.sellingSupply = sampleEstimateItem.sellingSupply;
      //     estimateItem.calculateItemPrice();
      //   }
      // }
    }
    // 그룹 견적배열이 수정되는 경우, 그룹 설계 자재비로 총 금액 계산
    let overlapEstimateItemTemp: EstimateItem = null;
    if (estimateArrayType === CONST.ID_ESTIMATE_ARRAY_GROUP) {
      overlapEstimateItemTemp = new EstimateItem(this, this.design);
      overlapEstimateItemTemp.deepCopy(this.overlapMaterialSavingEstimateItem);
    }

    // 설계 자재비 계산
    this.calculateDesignMaterialPrice();
    // 공과잡비 계산
    this.calculateBuildingMisItemPrice();
    // 중복 자재 절약 처리
    this.calculateOverlapSavingItemPrice();
    // 견적서 총 금액 계산
    this.calculateEstimateTotalPrice();
    // 최종 배율 계산
    this.calculateRateTotal();
    // 대외용 할인율 계산
    this.calculateRateDiscount();
  }

  /**
   * 견적품목 추가하기
   * - 추가 후, 재배치 및 총 금액 계산
   * @param estimateItem 견적품목
   * @param estimateType 견적 형태
   */
  public addEstimateItem(estimateItem: EstimateItem, estimateType: number = CONST.TYPE_ESTIMATE_BOTH): void {
    if (estimateType === CONST.TYPE_ESTIMATE_BOTH) {
      this.normalEstimateItemAC.push(estimateItem);
      // this.groupEstimateItemAC.push(estimateItem);
    } else if (estimateType === CONST.TYPE_ESTIMATE_NORMAL) {
      this.normalEstimateItemAC.push(estimateItem);
      // } else if (estimateType === CONST.TYPE_ESTIMATE_GROUP) {
      //   this.groupEstimateItemAC.push(estimateItem);
    }
    // 견적품목 재배치
    this.rearrangeEstimateItems();
    // 견적서 총 금액 계산
    this.calculateEstimateTotalPrice();
    // 최종 배율 계산
    this.calculateRateTotal();
    // 대외용 할인율 계산
    this.calculateRateDiscount();
  }

  /**
   * 견적품목 삭제하기
   * - 삭제 후, 재배치 및 총 금액 계산
   * @param estimateItem 견적품목
   * @param estimateType 견적 형태
   */
  public removeEstimateItem(estimateItem: EstimateItem, estimateType: number = CONST.TYPE_ESTIMATE_BOTH): void {
    if (estimateType === CONST.TYPE_ESTIMATE_BOTH) {
      this.normalEstimateItemAC.forEach((it, index) => {
        if (estimateItem === it) this.normalEstimateItemAC.splice(index, 1);
      });
      // this.groupEstimateItemAC.forEach((it, index) => {
      //   if (estimateItem === it) this.groupEstimateItemAC.splice(index, 1);
      // });
    } else if (estimateType === CONST.TYPE_ESTIMATE_NORMAL) {
      this.normalEstimateItemAC.forEach((it, index) => {
        if (estimateItem === it) this.normalEstimateItemAC.splice(index, 1);
      });
      // } else if (estimateType === CONST.TYPE_ESTIMATE_GROUP) {
      //   this.groupEstimateItemAC.forEach((it, index) => {
      //     if (estimateItem === it) this.groupEstimateItemAC.splice(index, 1);
      //   });
    }
    // 견적품목 재배치
    this.rearrangeEstimateItems();
    // 견적서 총 금액 계산
    this.calculateEstimateTotalPrice();
    // 최종 배율 계산
    this.calculateRateTotal();
    // 대외용 할인율 계산
    this.calculateRateDiscount();
  }

  /**
   * 견적품목 인덱스로 삭제하기
   * - 삭제 후, 재배치 및 총 금액 계산
   * @param index 인덱스
   * @param estimateType 견적 형태
   */
  public removeEstimateItemAt(index: number, estimateType: number = CONST.TYPE_ESTIMATE_BOTH): void {
    if (estimateType === CONST.TYPE_ESTIMATE_BOTH) {
      this.normalEstimateItemAC.splice(index, 1);
      // this.groupEstimateItemAC.splice(index, 1);
    } else if (estimateType === CONST.TYPE_ESTIMATE_NORMAL) {
      this.normalEstimateItemAC.splice(index, 1);
      // } else if (estimateType === CONST.TYPE_ESTIMATE_GROUP) {
      //   this.groupEstimateItemAC.splice(index, 1);
    }
    // 견적품목 재배치
    this.rearrangeEstimateItems();
    // 견적서 총 금액 계산
    this.calculateEstimateTotalPrice();
    // 최종 배율 계산
    this.calculateRateTotal();
    // 대외용 할인율 계산
    this.calculateRateDiscount();
  }

  /**
   * 견적품목 재배치하기
   * - 정렬 및 인덱싱
   */
  public rearrangeEstimateItems(): void {
    // if (this.normalEstimateItemAC && this.groupEstimateItemAC) {
    if (this.normalEstimateItemAC) {
      // 일반 견적품목 정렬 및 인덱싱
      this.sortEstimateItems(this.normalEstimateItemAC, CONST.TYPE_ESTIMATE_NORMAL);
      for (let i = 0; i < this.normalEstimateItemAC.length; i++) {
        this.normalEstimateItemAC[i].index = i; // BOTH 상품의 인덱스는 잘못됨
      }
      // 그룹 견적품목 정렬 및 인덱싱
      // this.sortEstimateItems(this.groupEstimateItemAC, CONST.TYPE_ESTIMATE_GROUP);
      // for (let i = 0; i < this.groupEstimateItemAC.length; i++) {
      //   this.groupEstimateItemAC[i].index = i; // BOTH 상품의 인덱스는 잘못됨
      // }
    }
  }

  /**
   * 자재 할인 견적품목 설정하기
   * @param discountSupply 할인 금액 (음수: 금액만큼 할인, 0이상: 할인품목 제거)
   * @param discountVat 할인 금액 (음수: 금액만큼 할인, 0이상: 할인품목 제거)
   * @param discountPrice 할인 금액 (음수: 금액만큼 할인, 0이상: 할인품목 제거)
   */
  public setMaterialDiscountEstimateItem(
    discountSupply: number,
    discountVat: number = 0,
    discountPrice: number = 0,
  ): void {
    if (discountPrice < 0) {
      // 할인 금액이 0보다 작은 경우, 할인 적용
      if (!this.materialDiscountEstimateItem) {
        // 할인 견적상품이 없는 경우, 상품 만들어서 추가
        this.materialDiscountEstimateItem = new EstimateItem(this, this.design);
        this.materialDiscountEstimateItem.setDefaultData_materialDiscount(discountSupply, discountVat, discountPrice);
        this.addEstimateItem(this.materialDiscountEstimateItem);
      } else {
        // 할인 견적상품이 있는 경우, 금액만 변경
        this.materialDiscountEstimateItem.setDefaultData_materialDiscount(discountSupply, discountVat, discountPrice);
        // 견적품목 재배치
        this.rearrangeEstimateItems();
        // 견적서 총 금액 계산
        this.calculateEstimateTotalPrice();
        // 최종 배율 계산
        this.calculateRateTotal();
        // 대외용 할인율 계산
        this.calculateRateDiscount();
      }
    } else {
      // 할인 금액이 0과 같거나 큰 경우, 할인 견적상품 모두 제거
      this.normalEstimateItemAC.forEach((it, index) => {
        if (it === this.materialDiscountEstimateItem) {
          this.normalEstimateItemAC.splice(index, 1);
        }
      });
      // this.groupEstimateItemAC.forEach((it, index) => {
      //   if (it === this.materialDiscountEstimateItem) {
      //     this.groupEstimateItemAC.splice(index, 1);
      //   }
      // });
      this.materialDiscountEstimateItem = null;
      // 견적품목 재배치
      this.rearrangeEstimateItems();
      // 견적서 총 금액 계산
      this.calculateEstimateTotalPrice();
      // 최종 배율 계산
      this.calculateRateTotal();
      // 대외용 할인율 계산
      this.calculateRateDiscount();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 견적 품목
  //----------------------------------

  /**
   * 설계품목으로 견적품목 만들기
   * @param design 설계 객체
   */
  protected makeEstimateItemsFromDesign(design: Design): void {
    // 설계상의 모든 설계품목 조회
    if (design.isSectionEstimate) {
      // 부분견적인 경우,
      for (const struct of [design.struct]) {
        if (struct.workAC && struct.selected && struct.selectedEstimate) {
          for (const work of struct.workAC) {
            if (work.levelAC && work.selected && work.selectedEstimate) {
              for (const level of work.levelAC) {
                if (level.positionAC && level.selected && level.selectedEstimate) {
                  for (const position of level.positionAC) {
                    if (position.partAC && position.selected && position.selectedEstimate) {
                      for (const part of position.partAC) {
                        if (part.itemAC && part.selected && part.selectedEstimate) {
                          for (const item of part.itemAC) {
                            if (item.selected && item.selectedEstimate) {
                              // 단가 모델 검사
                              // item.checkUnitPriceModel();

                              // 설계품목을 견적품목화하여 견적품목AC에 합치기
                              this.mergeDesignItemToEstimateItem(item);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      // 부분견적이 아닌 경우,
      for (const struct of [design.struct]) {
        if (struct.workAC && struct.selected) {
          for (const work of struct.workAC) {
            if (work.levelAC && work.selected) {
              for (const level of work.levelAC) {
                if (level.positionAC && level.selected) {
                  for (const position of level.positionAC) {
                    if (position.partAC && position.selected) {
                      for (const part of position.partAC) {
                        if (part.itemAC && part.selected) {
                          for (const item of part.itemAC) {
                            if (item.selected) {
                              // 단가 모델 검사
                              // item.checkUnitPriceModel();

                              // 설계품목을 견적품목화하여 견적품목AC에 합치기
                              this.mergeDesignItemToEstimateItem(item);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * 설계상품을 견적품목배열로 병합하기
   * @param item 병합할 설계품목
   */
  protected mergeDesignItemToEstimateItem(item: Item): void {
    let estimateItemTemp: EstimateItem;
    const estimateQuantityRaw: number = item.designQuantity;

    // 미가공 견적 수량이 0인 경우 무시함
    if (estimateQuantityRaw === 0) {
      return;
    }

    // 무조건 새로 추가 : 임의 상품 또는 시스템 상품
    if (item.productCompanyModel.productModel.isCustomItem || item.productCompanyModel.productModel.isSystemOnly) {
      // 설계품목을 견적품목으로 변환
      estimateItemTemp = new EstimateItem(this, this.design);
      estimateItemTemp.convertDesignItemToEstimateItem(item, CONST.TYPE_ESTIMATE_NORMAL);
      // 견적품목AC에 추가
      this.normalEstimateItemAC.push(estimateItemTemp);
      // this.groupEstimateItemAC.push(estimateItemTemp);
      // 공과잡비 별도구분
      if (item.productCompanyModel.productModel.productId === CONST.ITEM_ID_BUILDING_MIS) {
        this.buildingMisEstimateItems.push(estimateItemTemp);
      }
      return;
    }

    // 동일한 상품 합치기 : 그 외의 모든 상품
    this.mergeItemToNormalEstimateItem(item);
    // this.mergeItemToGroupEstimateItem(item);
  }

  /**
   * 상품을 일반 견적품목배열로 병합하기
   * @param item 병합할 설계품목
   */
  protected mergeItemToNormalEstimateItem(item: Item): void {
    let estimateItemTemp: EstimateItem;
    const { designQuantity } = item;
    const estimateQuantityRaw: number = item.designQuantity;
    const compactPurpose: string = item.purpose.replace(new RegExp(/\s+/g), ""); // 모든 공백제거

    // 동일 상품은 수량을 더하기
    for (const estimateItemExist of this.normalEstimateItemAC) {
      // 일반 견적의 동일 상품 기준 = 상품명, 규격, 상표 리스트가 같고 공사, 작업명이 같은 것 or 골조/개폐/피복 공사 인 것
      if (
        (estimateItemExist.productName === item.label &&
          estimateItemExist.specs === item.specs &&
          estimateItemExist.brands === item.brandsList &&
          DesignConst.groupFrameSwitchCover.indexOf(item.work.category) !== -1) ||
        (estimateItemExist.productName === item.label &&
          estimateItemExist.specs === item.specs &&
          estimateItemExist.brands === item.brandsList &&
          estimateItemExist.category1 === estimateItemExist.groupingCategoryEstimateItem(item, "category1") &&
          estimateItemExist.category2 === estimateItemExist.groupingCategoryEstimateItem(item, "category2"))
      ) {
        // 수량을 더하기
        estimateItemExist.designQuantity = CommonUtil.fixFloat(estimateItemExist.designQuantity + designQuantity);
        estimateItemExist.estimateQuantityRaw = CommonUtil.fixFloat(
          estimateItemExist.estimateQuantityRaw + estimateQuantityRaw,
        );
        estimateItemExist.designItemAC.push(item);

        // 용도가 기존에 없는 용도인 경우 추가
        if (
          estimateItemExist.purpose.indexOf(compactPurpose) < 0 &&
          estimateItemExist.purpose.indexOf(compactPurpose + CONST.DELIMITER_PURPOSE) < 0 &&
          estimateItemExist.purpose.indexOf(CONST.DELIMITER_PURPOSE + compactPurpose) < 0
        ) {
          estimateItemExist.purpose += CONST.DELIMITER_PURPOSE + compactPurpose;
        }

        return;
      }
    }

    // 동일한 상품이 없으면 새로 추가
    estimateItemTemp = new EstimateItem(this, this.design);
    estimateItemTemp.convertDesignItemToEstimateItem(item, CONST.TYPE_ESTIMATE_NORMAL);
    this.normalEstimateItemAC.push(estimateItemTemp);
  }

  /**
   * 상품을 그룹 견적품목배열로 병합하기
   * @param item 병합할 설계품목
   */
  // protected mergeItemToGroupEstimateItem(item: Item): void {
  //   let estimateItemTemp: EstimateItem;
  //   const { designQuantity } = item;
  //   // const estimateQuantityRaw: number = item.getEstimateQuantityRaw(item.designQuantity);
  //   const estimateQuantityRaw: number = item.designQuantity;
  //   const compactPurpose: string = item.purpose.replace(new RegExp(/\s+/g), ""); // 모든 공백제거
  //
  //   // 동일 상품은 수량을 더하기
  //   for (const estimateItemExist of this.groupEstimateItemAC) {
  //     // 그룹 견적의 동일 상품 기준 = 작업명, 중수명, 상품명, 규격, 상표 리스트가 같은 것
  //     if (
  //       estimateItemExist.category1 === item.work.category &&
  //       estimateItemExist.category2 === item.work.label &&
  //       estimateItemExist.category3 === item.level.label &&
  //       estimateItemExist.productName === item.label &&
  //       estimateItemExist.specs === item.specs &&
  //       estimateItemExist.brands === item.brandsList
  //     ) {
  //       // 특정 상품의 용도별 구분 기준 = 용도가 같은 것
  //       if (
  //         ((this.design.preference.purposePipeFlag === true &&
  //           (item.productId === CONST.ITEM_ID_PIPE || item.productId === CONST.ITEM_ID_PIPE_BENDING_EXPENSE)) ||
  //           (this.design.preference.purposeNormalHolderFlag === true &&
  //             item.productId === CONST.ITEM_ID_NORMAL_HOLDER)) &&
  //         estimateItemExist.purpose !== compactPurpose
  //       ) {
  //         continue;
  //       }
  //
  //       // 수량을 더하기
  //       estimateItemExist.designQuantity = CommonUtil.fixFloat(estimateItemExist.designQuantity + designQuantity);
  //       estimateItemExist.estimateQuantityRaw = CommonUtil.fixFloat(
  //         estimateItemExist.estimateQuantityRaw + estimateQuantityRaw,
  //       );
  //       estimateItemExist.designItemAC.push(item);
  //
  //       // 용도가 기존에 없는 용도인 경우 추가
  //       if (
  //         estimateItemExist.purpose.indexOf(compactPurpose) < 0 &&
  //         estimateItemExist.purpose.indexOf(compactPurpose + CONST.DELIMITER_PURPOSE) < 0 &&
  //         estimateItemExist.purpose.indexOf(CONST.DELIMITER_PURPOSE + compactPurpose) < 0
  //       ) {
  //         estimateItemExist.purpose += CONST.DELIMITER_PURPOSE + compactPurpose;
  //       }
  //
  //       return;
  //     }
  //   }
  //
  //   // 동일한 상품이 없으면 새로 추가
  //   estimateItemTemp = new EstimateItem(this, this.design);
  //   estimateItemTemp.convertDesignItemToEstimateItem(item, CONST.TYPE_ESTIMATE_GROUP);
  //   this.groupEstimateItemAC.push(estimateItemTemp);
  // }

  /**
   * 공과잡비 계산하기 (설계 자재비 계산이 선행되어야함)
   * - 공과잡비 = 설계 자재비 * 공과잡비 비율
   */
  protected calculateBuildingMisItemPrice(): void {
    // 공과잡비의 매출가 계산
    for (const buildingMisEstimateItem of this.buildingMisEstimateItems) {
      // 매입가나 매출가 수정이 있어도, 모두 단가 100%기준으로 동일하게 적용
      buildingMisEstimateItem.sellingSupplyPurchase = (<ItemBuildingMis>(
        buildingMisEstimateItem.designItemAC[0]
      )).getSellingSupply(null, this.designMaterialSupplyPurchase);
      buildingMisEstimateItem.sellingSupplyUnit = (<ItemBuildingMis>(
        buildingMisEstimateItem.designItemAC[0]
      )).getSellingSupply(null, this.designMaterialSupplyUnit);
      buildingMisEstimateItem.sellingSupplyRated = (<ItemBuildingMis>(
        buildingMisEstimateItem.designItemAC[0]
      )).getSellingSupply(null, this.designMaterialSupplyRated);
      buildingMisEstimateItem.sellingSupply = (<ItemBuildingMis>(
        buildingMisEstimateItem.designItemAC[0]
      )).getSellingSupply(null, this.designMaterialSupply);
    }
    // 공과잡비의 개별 견적품목 금액 계산
    this.calculateEachEstimateItem(this.buildingMisEstimateItems);
  }

  /**
   * 중복 자재 절약 견적품목 계산하기 (설계 자재비 계산이 선행되어야함)
   */
  protected calculateOverlapSavingItemPrice(): void {
    if (this.overlapMaterialSavingEstimateItem === null) {
      this.overlapMaterialSavingEstimateItem = new EstimateItem(this, this.design);
    }
    // 기본 데이터 재설정 (항상 전체 데이터 최신화)
    this.overlapMaterialSavingEstimateItem.setDefaultData_overlapMaterialSaving(this);
    // 그룹 견적품목 배열에 없는 경우 추가
    // if (this.groupEstimateItemAC.indexOf(this.overlapMaterialSavingEstimateItem) === -1) {
    //   this.groupEstimateItemAC.push(this.overlapMaterialSavingEstimateItem);
    // }
  }

  /**
   * 직전 자재 할인 견적품목 추가하기
   * - 직전 견적서에 있는 경우에만 자재 할인 견적상품을 추가함
   */
  protected addMaterialDiscountEstimateItem(): void {
    if (this.materialDiscountEstimateItem) {
      // 기본 데이터 재설정 (항상 전체 데이터 최신화)
      this.materialDiscountEstimateItem.setDefaultData_materialDiscount(
        this.materialDiscountEstimateItem.itemSupply,
        this.materialDiscountEstimateItem.itemVat,
        this.materialDiscountEstimateItem.itemPrice,
      );
      // 일반/그룹 견적품목 배열에 추가
      this.normalEstimateItemAC.push(this.materialDiscountEstimateItem);
      // this.groupEstimateItemAC.push(this.materialDiscountEstimateItem);
    }
  }

  //----------------------------------
  // 견적 비용
  //----------------------------------

  /**
   * 모든 개별 견적품목의 금액 계산하기
   * - 견적 수량, 견적 과세 형태, 금액
   * @param estimateItems 견적품목 배열
   */
  protected calculateEachEstimateItem(estimateItems: EstimateItem[]): void {
    for (const estimateItem of estimateItems) {
      // 견적 수량 계산
      estimateItem.calculateEstimateQuantity();
      // 견적 과세 형태 지정
      estimateItem.setEstimateTaxType();
      // 금액 계산
      estimateItem.calculateItemPrice();
    }
  }

  /**
   * 설계 자재비 계산하기
   * - 설계 자재비 = 공과잡비를 제외한 총 자재비의 합
   * - 그룹 설계 자재비 = 그룹 견적 아이템의 설계 자재비 (중복 할인비를 계산에 활용됨)
   */
  protected calculateDesignMaterialPrice(): void {
    // 설계 자재비
    let designMaterialSupplyPurchaseTemp: number = 0;
    let designMaterialVatPurchaseTemp: number = 0;
    let designMaterialPricePurchaseTemp: number = 0;
    let designMaterialSupplyUnitTemp: number = 0;
    let designMaterialVatUnitTemp: number = 0;
    let designMaterialPriceUnitTemp: number = 0;
    let designMaterialSupplyRatedTemp: number = 0;
    let designMaterialVatRatedTemp: number = 0;
    let designMaterialPriceRatedTemp: number = 0;
    let designMaterialSupplyTemp: number = 0;
    let designMaterialVatTemp: number = 0;
    let designMaterialPriceTemp: number = 0;
    // 그룹 설계 자재비
    let groupDesignMaterialSupplyPurchaseTemp: number = 0;
    let groupDesignMaterialVatPurchaseTemp: number = 0;
    let groupDesignMaterialPricePurchaseTemp: number = 0;
    let groupDesignMaterialSupplyUnitTemp: number = 0;
    let groupDesignMaterialVatUnitTemp: number = 0;
    let groupDesignMaterialPriceUnitTemp: number = 0;
    let groupDesignMaterialSupplyRatedTemp: number = 0;
    let groupDesignMaterialVatRatedTemp: number = 0;
    let groupDesignMaterialPriceRatedTemp: number = 0;
    let groupDesignMaterialSupplyTemp: number = 0;
    let groupDesignMaterialVatTemp: number = 0;
    let groupDesignMaterialPriceTemp: number = 0;

    // 일반 견적품목
    for (const estimateItem of this.normalEstimateItemAC) {
      // 자재 카테고리1 && 공과잡비 제외
      if (
        estimateItem.unitPriceModel.categoryCompanyModel.categoryModel.category1 === CONST.ITEM_CAT1_NAME_MATERIAL &&
        estimateItem.unitPriceModel.categoryCompanyModel.categoryModel.category3 !== CONST.ITEM_CAT3_NAME_BUILDING_MIS
      ) {
        designMaterialSupplyPurchaseTemp += estimateItem.selectedItemSupplyPurchase;
        designMaterialVatPurchaseTemp += estimateItem.selectedItemVatPurchase;
        designMaterialPricePurchaseTemp += estimateItem.selectedItemPricePurchase;
        designMaterialSupplyUnitTemp += estimateItem.selectedItemSupplyUnit;
        designMaterialVatUnitTemp += estimateItem.selectedItemVatUnit;
        designMaterialPriceUnitTemp += estimateItem.selectedItemPriceUnit;
        designMaterialSupplyRatedTemp += estimateItem.selectedItemSupplyRated;
        designMaterialVatRatedTemp += estimateItem.selectedItemVatRated;
        designMaterialPriceRatedTemp += estimateItem.selectedItemPriceRated;
        designMaterialSupplyTemp += estimateItem.selectedItemSupply;
        designMaterialVatTemp += estimateItem.selectedItemVat;
        designMaterialPriceTemp += estimateItem.selectedItemPrice;
      }
    }
    this.designMaterialSupplyPurchase = designMaterialSupplyPurchaseTemp;
    this.designMaterialVatPurchase = designMaterialVatPurchaseTemp;
    this.designMaterialPricePurchase = designMaterialPricePurchaseTemp;
    this.designMaterialSupplyUnit = designMaterialSupplyUnitTemp;
    this.designMaterialVatUnit = designMaterialVatUnitTemp;
    this.designMaterialPriceUnit = designMaterialPriceUnitTemp;
    this.designMaterialSupplyRated = designMaterialSupplyRatedTemp;
    this.designMaterialVatRated = designMaterialVatRatedTemp;
    this.designMaterialPriceRated = designMaterialPriceRatedTemp;
    this.designMaterialSupply = designMaterialSupplyTemp;
    this.designMaterialVat = designMaterialVatTemp;
    this.designMaterialPrice = designMaterialPriceTemp;

    // 그룹 견적품목
    // for (const estimateItem of this.groupEstimateItemAC) {
    //   // 자재 카테고리1 && 공과잡비 제외
    //   if (
    //     estimateItem.unitPriceModel.categoryCompanyModel.categoryModel.category1 === CONST.ITEM_CAT1_NAME_MATERIAL &&
    //     estimateItem.unitPriceModel.categoryCompanyModel.categoryModel.category3 !== CONST.ITEM_CAT3_NAME_BUILDING_MIS
    //   ) {
    //     groupDesignMaterialSupplyPurchaseTemp += estimateItem.selectedItemSupplyPurchase;
    //     groupDesignMaterialVatPurchaseTemp += estimateItem.selectedItemVatPurchase;
    //     groupDesignMaterialPricePurchaseTemp += estimateItem.selectedItemPricePurchase;
    //     groupDesignMaterialSupplyUnitTemp += estimateItem.selectedItemSupplyUnit;
    //     groupDesignMaterialVatUnitTemp += estimateItem.selectedItemVatUnit;
    //     groupDesignMaterialPriceUnitTemp += estimateItem.selectedItemPriceUnit;
    //     groupDesignMaterialSupplyRatedTemp += estimateItem.selectedItemSupplyRated;
    //     groupDesignMaterialVatRatedTemp += estimateItem.selectedItemVatRated;
    //     groupDesignMaterialPriceRatedTemp += estimateItem.selectedItemPriceRated;
    //     groupDesignMaterialSupplyTemp += estimateItem.selectedItemSupply;
    //     groupDesignMaterialVatTemp += estimateItem.selectedItemVat;
    //     groupDesignMaterialPriceTemp += estimateItem.selectedItemPrice;
    //   }
    // }
    // this.groupDesignMaterialSupplyPurchase = groupDesignMaterialSupplyPurchaseTemp;
    // this.groupDesignMaterialVatPurchase = groupDesignMaterialVatPurchaseTemp;
    // this.groupDesignMaterialPricePurchase = groupDesignMaterialPricePurchaseTemp;
    // this.groupDesignMaterialSupplyUnit = groupDesignMaterialSupplyUnitTemp;
    // this.groupDesignMaterialVatUnit = groupDesignMaterialVatUnitTemp;
    // this.groupDesignMaterialPriceUnit = groupDesignMaterialPriceUnitTemp;
    // this.groupDesignMaterialSupplyRated = groupDesignMaterialSupplyRatedTemp;
    // this.groupDesignMaterialVatRated = groupDesignMaterialVatRatedTemp;
    // this.groupDesignMaterialPriceRated = groupDesignMaterialPriceRatedTemp;
    // this.groupDesignMaterialSupply = groupDesignMaterialSupplyTemp;
    // this.groupDesignMaterialVat = groupDesignMaterialVatTemp;
    // this.groupDesignMaterialPrice = groupDesignMaterialPriceTemp;
  }

  /**
   * 견적서 총 금액 계산하기 (설계 자재비 계산이 선행되어야함)
   * @param overlapEstimateItem 중복 견적 상품이 있는 경우, 그룹 설계 자재비로 총금액 계산함
   */
  protected calculateEstimateTotalPrice(overlapEstimateItem: EstimateItem = null): void {
    //
    // 1.총 재료비 = 설계 자재비 + 공과잡비 + (자재 할인)
    // - 매입기준 총 재료비 = 매입기준 설계 자재비 + 매입기준 공과잡비
    // - 단가적용 총 재료비 = 단가적용 설계 자재비 + 단가적용 공과잡비
    // - 배율적용 총 재료비 = 배율적용 설계 자재비 + 배율적용 공과잡비
    // - 총 재료비 = 설계 자재비 + 공과잡비 + 자재 할인
    //
    // + 설계 자재비
    // if (!overlapEstimateItem) {
    this.totalMaterialCostSupplyPurchase = this.designMaterialSupplyPurchase;
    this.totalMaterialCostVatPurchase = this.designMaterialVatPurchase;
    this.totalMaterialCostPricePurchase = this.designMaterialPricePurchase;
    this.totalMaterialCostSupplyUnit = this.designMaterialSupplyUnit;
    this.totalMaterialCostVatUnit = this.designMaterialVatUnit;
    this.totalMaterialCostPriceUnit = this.designMaterialPriceUnit;
    this.totalMaterialCostSupplyRated = this.designMaterialSupplyRated;
    this.totalMaterialCostVatRated = this.designMaterialVatRated;
    this.totalMaterialCostPriceRated = this.designMaterialPriceRated;
    this.totalMaterialCostSupply = this.designMaterialSupply;
    this.totalMaterialCostVat = this.designMaterialVat;
    this.totalMaterialCostPrice = this.designMaterialPrice;
    // } else {
    //   this.totalMaterialCostSupplyPurchase = this.groupDesignMaterialSupplyPurchase;
    //   this.totalMaterialCostVatPurchase = this.groupDesignMaterialVatPurchase;
    //   this.totalMaterialCostPricePurchase = this.groupDesignMaterialPricePurchase;
    //   this.totalMaterialCostSupplyUnit = this.groupDesignMaterialSupplyUnit;
    //   this.totalMaterialCostVatUnit = this.groupDesignMaterialVatUnit;
    //   this.totalMaterialCostPriceUnit = this.groupDesignMaterialPriceUnit;
    //   this.totalMaterialCostSupplyRated = this.groupDesignMaterialSupplyRated;
    //   this.totalMaterialCostVatRated = this.groupDesignMaterialVatRated;
    //   this.totalMaterialCostPriceRated = this.groupDesignMaterialPriceRated;
    //   this.totalMaterialCostSupply = this.groupDesignMaterialSupply;
    //   this.totalMaterialCostVat = this.groupDesignMaterialVat;
    //   this.totalMaterialCostPrice = this.groupDesignMaterialPrice;
    // }
    // + 공과잡비
    for (const buildingMisEstimateItem of this.buildingMisEstimateItems) {
      this.totalMaterialCostSupplyPurchase += buildingMisEstimateItem.selectedItemSupplyPurchase;
      this.totalMaterialCostVatPurchase += buildingMisEstimateItem.selectedItemVatPurchase;
      this.totalMaterialCostPricePurchase += buildingMisEstimateItem.selectedItemPricePurchase;
      this.totalMaterialCostSupplyUnit += buildingMisEstimateItem.selectedItemSupplyUnit;
      this.totalMaterialCostVatUnit += buildingMisEstimateItem.selectedItemVatUnit;
      this.totalMaterialCostPriceUnit += buildingMisEstimateItem.selectedItemPriceUnit;
      this.totalMaterialCostSupplyRated += buildingMisEstimateItem.selectedItemSupplyRated;
      this.totalMaterialCostVatRated += buildingMisEstimateItem.selectedItemVatRated;
      this.totalMaterialCostPriceRated += buildingMisEstimateItem.selectedItemPriceRated;
      this.totalMaterialCostSupply += buildingMisEstimateItem.selectedItemSupply;
      this.totalMaterialCostVat += buildingMisEstimateItem.selectedItemVat;
      this.totalMaterialCostPrice += buildingMisEstimateItem.selectedItemPrice;
    }
    // + 자재 할인 (총 재료비만 계산)
    if (this.materialDiscountEstimateItem) {
      this.totalMaterialCostSupply += this.materialDiscountEstimateItem.selectedItemSupply;
      this.totalMaterialCostVat += this.materialDiscountEstimateItem.selectedItemVat;
      this.totalMaterialCostPrice += this.materialDiscountEstimateItem.selectedItemPrice;
    }
    // // + 중복 자재 할인 (그룹 설계 자재비로 계산시, 중복 자재 할인비용을 빼줌)
    // if (overlapEstimateItem) {
    //   this.totalMaterialCostSupplyPurchase += overlapEstimateItem.selectedItemSupplyPurchase;
    //   this.totalMaterialCostVatPurchase += overlapEstimateItem.selectedItemVatPurchase;
    //   this.totalMaterialCostPricePurchase += overlapEstimateItem.selectedItemPricePurchase;
    //   this.totalMaterialCostSupplyUnit += overlapEstimateItem.selectedItemSupplyUnit;
    //   this.totalMaterialCostVatUnit += overlapEstimateItem.selectedItemVatUnit;
    //   this.totalMaterialCostPriceUnit += overlapEstimateItem.selectedItemPriceUnit;
    //   this.totalMaterialCostSupplyRated += overlapEstimateItem.selectedItemSupplyRated;
    //   this.totalMaterialCostVatRated += overlapEstimateItem.selectedItemVatRated;
    //   this.totalMaterialCostPriceRated += overlapEstimateItem.selectedItemPriceRated;
    //   this.totalMaterialCostSupply += overlapEstimateItem.selectedItemSupply;
    //   this.totalMaterialCostVat += overlapEstimateItem.selectedItemVat;
    //   this.totalMaterialCostPrice += overlapEstimateItem.selectedItemPrice;
    // }
    //
    // 2.총 경비 = 총 경비(자재비) + 총 경비(시공비)
    //
    // + 총 경비(자재비)
    let totalExpenseSupplyPurchaseMaterial: number = 0;
    let totalExpenseVatPurchaseMaterial: number = 0;
    let totalExpensePricePurchaseMaterial: number = 0;
    let totalExpenseSupplyUnitMaterial: number = 0;
    let totalExpenseVatUnitMaterial: number = 0;
    let totalExpensePriceUnitMaterial: number = 0;
    let totalExpenseSupplyRatedMaterial: number = 0;
    let totalExpenseVatRatedMaterial: number = 0;
    let totalExpensePriceRatedMaterial: number = 0;
    let totalExpenseSupplyMaterial: number = 0;
    let totalExpenseVatMaterial: number = 0;
    let totalExpensePriceMaterial: number = 0;
    for (const estimateItem of this.normalEstimateItemAC) {
      // 기타 경비 카테고리2 && 시공 작업 제외
      if (
        estimateItem.unitPriceModel.categoryCompanyModel.categoryModel.category2 ===
          CONST.ITEM_CAT2_NAME_OTHER_EXPENSE &&
        estimateItem.category2 !== CONST.LB_BUILDING_WORK
      ) {
        totalExpenseSupplyPurchaseMaterial += estimateItem.selectedItemSupplyPurchase;
        totalExpenseVatPurchaseMaterial += estimateItem.selectedItemVatPurchase;
        totalExpensePricePurchaseMaterial += estimateItem.selectedItemPricePurchase;
        totalExpenseSupplyUnitMaterial += estimateItem.selectedItemSupplyUnit;
        totalExpenseVatUnitMaterial += estimateItem.selectedItemVatUnit;
        totalExpensePriceUnitMaterial += estimateItem.selectedItemPriceUnit;
        totalExpenseSupplyRatedMaterial += estimateItem.selectedItemSupplyRated;
        totalExpenseVatRatedMaterial += estimateItem.selectedItemVatRated;
        totalExpensePriceRatedMaterial += estimateItem.selectedItemPriceRated;
        totalExpenseSupplyMaterial += estimateItem.selectedItemSupply;
        totalExpenseVatMaterial += estimateItem.selectedItemVat;
        totalExpensePriceMaterial += estimateItem.selectedItemPrice;
      }
    }
    // + 총 경비(시공비)
    let totalExpenseSupplyPurchaseBuilding: number = 0;
    let totalExpenseVatPurchaseBuilding: number = 0;
    let totalExpensePricePurchaseBuilding: number = 0;
    let totalExpenseSupplyUnitBuilding: number = 0;
    let totalExpenseVatUnitBuilding: number = 0;
    let totalExpensePriceUnitBuilding: number = 0;
    let totalExpenseSupplyRatedBuilding: number = 0;
    let totalExpenseVatRatedBuilding: number = 0;
    let totalExpensePriceRatedBuilding: number = 0;
    let totalExpenseSupplyBuilding: number = 0;
    let totalExpenseVatBuilding: number = 0;
    let totalExpensePriceBuilding: number = 0;
    for (const estimateItem of this.normalEstimateItemAC) {
      // 기타 경비 카테고리2 && 시공 작업
      if (
        estimateItem.unitPriceModel.categoryCompanyModel.categoryModel.category2 ===
          CONST.ITEM_CAT2_NAME_OTHER_EXPENSE &&
        estimateItem.category2 === CONST.LB_BUILDING_WORK
      ) {
        totalExpenseSupplyPurchaseBuilding += estimateItem.selectedItemSupplyPurchase;
        totalExpenseVatPurchaseBuilding += estimateItem.selectedItemVatPurchase;
        totalExpensePricePurchaseBuilding += estimateItem.selectedItemPricePurchase;
        totalExpenseSupplyUnitBuilding += estimateItem.selectedItemSupplyUnit;
        totalExpenseVatUnitBuilding += estimateItem.selectedItemVatUnit;
        totalExpensePriceUnitBuilding += estimateItem.selectedItemPriceUnit;
        totalExpenseSupplyRatedBuilding += estimateItem.selectedItemSupplyRated;
        totalExpenseVatRatedBuilding += estimateItem.selectedItemVatRated;
        totalExpensePriceRatedBuilding += estimateItem.selectedItemPriceRated;
        totalExpenseSupplyBuilding += estimateItem.selectedItemSupply;
        totalExpenseVatBuilding += estimateItem.selectedItemVat;
        totalExpensePriceBuilding += estimateItem.selectedItemPrice;
      }
    }
    //
    // 3.총 노무비
    //
    this.totalLaborCostSupplyPurchase = 0;
    this.totalLaborCostVatPurchase = 0;
    this.totalLaborCostPricePurchase = 0;
    this.totalLaborCostSupplyUnit = 0;
    this.totalLaborCostVatUnit = 0;
    this.totalLaborCostPriceUnit = 0;
    this.totalLaborCostSupplyRated = 0;
    this.totalLaborCostVatRated = 0;
    this.totalLaborCostPriceRated = 0;
    this.totalLaborCostSupply = 0;
    this.totalLaborCostVat = 0;
    this.totalLaborCostPrice = 0;
    for (const estimateItem of this.normalEstimateItemAC) {
      // 기타 인건비 카테고리2
      if (
        estimateItem.unitPriceModel.categoryCompanyModel.categoryModel.category2 ===
        CONST.ITEM_CAT2_NAME_OTHER_LABOR_COST
      ) {
        this.totalLaborCostSupplyPurchase += estimateItem.selectedItemSupplyPurchase;
        this.totalLaborCostVatPurchase += estimateItem.selectedItemVatPurchase;
        this.totalLaborCostPricePurchase += estimateItem.selectedItemPricePurchase;
        this.totalLaborCostSupplyUnit += estimateItem.selectedItemSupplyUnit;
        this.totalLaborCostVatUnit += estimateItem.selectedItemVatUnit;
        this.totalLaborCostPriceUnit += estimateItem.selectedItemPriceUnit;
        this.totalLaborCostSupplyRated += estimateItem.selectedItemSupplyRated;
        this.totalLaborCostVatRated += estimateItem.selectedItemVatRated;
        this.totalLaborCostPriceRated += estimateItem.selectedItemPriceRated;
        this.totalLaborCostSupply += estimateItem.selectedItemSupply;
        this.totalLaborCostVat += estimateItem.selectedItemVat;
        this.totalLaborCostPrice += estimateItem.selectedItemPrice;
      }
    }

    //
    // A.총 자재비 = 총 재료비 + 총 경비(자재비)
    //
    this.totalMaterialSupplyPurchase = this.totalMaterialCostSupplyPurchase + totalExpenseSupplyPurchaseMaterial;
    this.totalMaterialVatPurchase = this.totalMaterialCostVatPurchase + totalExpenseVatPurchaseMaterial;
    this.totalMaterialPricePurchase = this.totalMaterialCostPricePurchase + totalExpensePricePurchaseMaterial;
    this.totalMaterialSupplyUnit = this.totalMaterialCostSupplyUnit + totalExpenseSupplyUnitMaterial;
    this.totalMaterialVatUnit = this.totalMaterialCostVatUnit + totalExpenseVatUnitMaterial;
    this.totalMaterialPriceUnit = this.totalMaterialCostPriceUnit + totalExpensePriceUnitMaterial;
    this.totalMaterialSupplyRated = this.totalMaterialCostSupplyRated + totalExpenseSupplyRatedMaterial;
    this.totalMaterialVatRated = this.totalMaterialCostVatRated + totalExpenseVatRatedMaterial;
    this.totalMaterialPriceRated = this.totalMaterialCostPriceRated + totalExpensePriceRatedMaterial;
    this.totalMaterialSupply = this.totalMaterialCostSupply + totalExpenseSupplyMaterial;
    this.totalMaterialVat = this.totalMaterialCostVat + totalExpenseVatMaterial;
    this.totalMaterialPrice = this.totalMaterialCostPrice + totalExpensePriceMaterial;
    //
    // B.총 시공비 = 총 노무비 + 총 경비(시공비)
    //
    this.totalBuildingSupplyPurchase = this.totalLaborCostSupplyPurchase + totalExpenseSupplyPurchaseBuilding;
    this.totalBuildingVatPurchase = this.totalLaborCostVatPurchase + totalExpenseVatPurchaseBuilding;
    this.totalBuildingPricePurchase = this.totalLaborCostPricePurchase + totalExpensePricePurchaseBuilding;
    this.totalBuildingSupplyUnit = this.totalLaborCostSupplyUnit + totalExpenseSupplyUnitBuilding;
    this.totalBuildingVatUnit = this.totalLaborCostVatUnit + totalExpenseVatUnitBuilding;
    this.totalBuildingPriceUnit = this.totalLaborCostPriceUnit + totalExpensePriceUnitBuilding;
    this.totalBuildingSupplyRated = this.totalLaborCostSupplyRated + totalExpenseSupplyRatedBuilding;
    this.totalBuildingVatRated = this.totalLaborCostVatRated + totalExpenseVatRatedBuilding;
    this.totalBuildingPriceRated = this.totalLaborCostPriceRated + totalExpensePriceRatedBuilding;
    this.totalBuildingSupply = this.totalLaborCostSupply + totalExpenseSupplyBuilding;
    this.totalBuildingVat = this.totalLaborCostVat + totalExpenseVatBuilding;
    this.totalBuildingPrice = this.totalLaborCostPrice + totalExpensePriceBuilding;

    //
    // C.총 금액 = 총 자재비 + 총 시공비
    //
    this.totalSupplyPurchase = this.totalMaterialSupplyPurchase + this.totalBuildingSupplyPurchase;
    this.totalVatPurchase = this.totalMaterialVatPurchase + this.totalBuildingVatPurchase;
    this.totalPricePurchase = this.totalMaterialPricePurchase + this.totalBuildingPricePurchase;
    this.totalSupplyUnit = this.totalMaterialSupplyUnit + this.totalBuildingSupplyUnit;
    this.totalVatUnit = this.totalMaterialVatUnit + this.totalBuildingVatUnit;
    this.totalPriceUnit = this.totalMaterialPriceUnit + this.totalBuildingPriceUnit;
    this.totalSupplyRated = this.totalMaterialSupplyRated + this.totalBuildingSupplyRated;
    this.totalVatRated = this.totalMaterialVatRated + this.totalBuildingVatRated;
    this.totalPriceRated = this.totalMaterialPriceRated + this.totalBuildingPriceRated;
    this.totalSupply = this.totalMaterialSupply + this.totalBuildingSupply;
    this.totalVat = this.totalMaterialVat + this.totalBuildingVat;
    this.totalPrice = this.totalMaterialPrice + this.totalBuildingPrice;
  }

  /**
   * 견적서가 바뀌었는지 판단하기
   * - 직전 견적 총 금액과 비교
   * - 견적이 바뀐경우, 할인 상품 제거
   */
  public decideEstimateChanging(totalPriceBefore: number = null): boolean {
    if (!totalPriceBefore) {
      // 직전 견적이 없는 경우
      return false;
    }
    if (totalPriceBefore === this.totalPrice) {
      // 직전 견적이 있고, 비용이 바뀌지 않은 경우
      return false;
    }
    // 직전 견적이 있고, 비용이 바뀐 경우
    this.setMaterialDiscountEstimateItem(0); // 자재 할인 제거
    return true;
  }

  /**
   * 최종 배율 계산하기
   * - 최종 배율 = 총 금액 / 단가적용 총 금액
   */
  public calculateRateTotal() {
    this.rateFinal = CommonUtil.round(CommonUtil.fixFloat(this.totalPrice / this.totalPriceUnit), 3);
  }

  /**
   * 대외용 할인율 계산하기
   * - 할인율 = 1 - 할인된 총 금액 / 배율적용 총 금액
   * @param discountedPrice 할인된 총 금액
   * @param totalPriceRated 배율적용 총 금액
   */
  public calculateRateDiscount(discountedPrice: number = null, totalPriceRated: number = null) {
    if (discountedPrice && totalPriceRated) {
      this.rateDiscount = CommonUtil.round(CommonUtil.fixFloat(1 - discountedPrice / totalPriceRated), 3);
    } else {
      // 할인 상품으로 추정
      if (this.materialDiscountEstimateItem) {
        this.rateDiscount = CommonUtil.round(
          CommonUtil.fixFloat(
            -this.materialDiscountEstimateItem.selectedItemPrice /
              (this.totalPrice - this.materialDiscountEstimateItem.selectedItemPrice),
          ),
          3,
        );
      } else {
        this.rateDiscount = 0;
      }
    }
  }

  //----------------------------------
  // 견적 기타
  //----------------------------------

  /**
   * 견적품목 기본 정렬하기
   * @param estimateItems 견적품목 배열
   * @param estimateType 견적 형태
   */
  protected sortEstimateItems(estimateItems: EstimateItem[], estimateType: number = CONST.TYPE_ESTIMATE_NORMAL): void {
    if (estimateType === CONST.TYPE_ESTIMATE_NORMAL) {
      // 일반 견적품목
      // estimateItems.sort((a, b) => (a.productId < b.productId ? -1 : a.productId > b.productId ? 1 : 0));
      estimateItems.sort((a, b) =>
        a.categoryOrder < b.categoryOrder ? -1 : a.categoryOrder > b.categoryOrder ? 1 : 0,
      );
    } else if (estimateType === CONST.TYPE_ESTIMATE_GROUP) {
      // 그룹 견적품목
      // (일단 정렬 안함)
    }
  }
}
