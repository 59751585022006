/**
 * 사용자 상수
 */
export const UserConst = {
  //----------------------------------
  // DB Value
  //----------------------------------
  // customerType
  CUSTOMER_TYPE_PERSONAL: "개인고객",
  CUSTOMER_TYPE_ENTERPRISE: "기업고객",
  // gender
  GENDER_MALE: "남",
  GENDER_FEMALE: "여",
  //----------------------------------
  // 공통
  //----------------------------------
  //----------------------------------
  // 화면 변수
  //----------------------------------
  // 재직 상태
  workStatus: {
    EMPLOYMENT: "재직",
    ABSENCE: "휴직",
    RETIRE: "퇴직",
  },
  // 정책 형태
  policyType: {
    TERMS: "이용약관",
    PRIVACY: "개인정보취급방침",
    LEGAL: "법적고지",
    MARKETING: "이벤트/마케팅 활용 동의안",
  },
  // 유저 형태
  userType: {
    BUSINESS: "business",
    EMPLOYEE: "employee",
    CUSTOMER: "customer",
  },
  //----------------------------------
  // 데이터 변수
  //----------------------------------
  // 고객 형태
  customerType: {
    PERSONAL: "개인고객",
    ENTERPRISE: "기업고객",
  },
  // 계층 형태
  classType: {
    BUSINESS_USER: "비즈니스사용자",
    PERSONAL_CUSTOMER: "개인고객",
    ENTERPRISE_CUSTOMER: "기업고객",
  },
  // 역할
  role: {
    CUSTOMER: "고객",
    ESTIMATOR: "견적자",
    MANAGER: "관리자",
    ADMIN: "운영자",
  },
  // 계정 상태
  accountStatus: {
    NORMAL: "정상",
    LOCKED: "정지",
    TEMP: "임시",
    DELETED: "삭제",
    LEAVE: "탈퇴",
    EXPIRED: "계정만료",
    CREDENTIAL_EXPIRED: "신용만료",
  },
  // 역할 아이디
  roleId: {
    CUSTOMER: 1,
    ESTIMATOR: 2,
    MANAGER: 3,
    ADMIN: 4,
  },
  // 역할 설명
  description: {
    CUSTOMER: "고객사용자 역할",
    ESTIMATOR: "견적자 역할",
    MANAGER: "관리자 역할",
    ADMIN: "운영자 역할",
  },
} as const;
