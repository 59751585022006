import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 레일 간격유지
 * @author 이덕형
 * @copyright RUNean Inc.
 * @date 2018-08-30
 */
@jsonObject
export class ItemRailInterval extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [파이프형태, 특성, 단면크기], (롤러간격)
  public _specPipeType: string = undefined; // 특성
  public _specFeature: string = undefined; // 특성
  public _specCrossSize: string = undefined; // 단면크기
  public _specInterval: number = undefined; // 롤러간격

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 파이프형태
   */
  public get specPipeType(): string {
    return this._specPipeType;
  }
  //
  public set specPipeType(value: string) {
    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 단면크기
   */
  public get specCrossSize(): string {
    return this._specCrossSize;
  }
  //
  public set specCrossSize(value: string) {
    this._specCrossSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 간격
   */
  public get specInterval(): number {
    return this._specInterval;
  }
  public set specInterval(value: number) {
    // 일반화된 간격 적용 - 0.05m 간격(0.05배수로 올림)
    this._specInterval = CommonUtil.roundUpX(value, 0.05);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 간격 레이블(값+단위기호)
   */
  public get specIntervalLabel(): string {
    return this.specInterval + CONST.UNIT_CENTIMETER_INTERVAL;
  }
  //
  public set specIntervalLabel(value: string) {
    this.specInterval = parseFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.railIntervalCategoryModel;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    // 매출가 계산식
    let sellingSupply: number = defaultUnitSupply * this.specInterval;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specFeature = specsArray[1];
    this.specCrossSize = specsArray[2];
    this.specIntervalLabel = specsArray[3];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specFeature = specsArray[1];
    this.specCrossSize = specsArray[2];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this.specPipeType + CONST.DELIMITER_SPEC + this.specFeature + CONST.DELIMITER_SPEC + this.specCrossSize;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList + CONST.DELIMITER_SPEC + this.specIntervalLabel;
  }
}
