import { jsonObject } from "typedjson";

import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { MainpipePart_PeMain2 } from "vhows-design/src/object/design/watering/mainpipe/MainpipePart_PeMain2";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject
export class MainpipeSample_Main2EachPePipe extends ItemWateringPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const peMain2Part: MainpipePart_PeMain2 = <MainpipePart_PeMain2>this.part;
    peMain2Part.algorithmSpec_main2Each_pePipe();
    peMain2Part.algorithmSpec_main2_exactT();
    peMain2Part.algorithmSpec_main2_unionValve();
    peMain2Part.algorithmSpec_main2_elbow();
    peMain2Part.algorithmSpec_main2_pePipeEnd();
    peMain2Part.algorithmSpec_main2_valveSocket();

    // 다른 파트
    (<MainpipePosition>this.position).peMain1Part.algorithmSpec_waterFilterOut_bushing();
    (<MainpipePosition>this.position).peMain1Part.algorithmSpec_waterFilterOut_valveSocket();
    (<MainpipePosition>this.position).peMain1Part.algorithmPart();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 관수 지관부
    const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
    wateringPosition.miniklerScreenPart.subPePipeSample.algorithmSpec();
    wateringPosition.miniklerScreenPart.algorithmSpec_subAndMain_differentT();
    wateringPosition.miniklerFountainPart.subPePipeSample.algorithmSpec();
    wateringPosition.miniklerFountainPart.algorithmSpec_subAndMain_differentT();
    wateringPosition.miniklerFogPart.subPePipeSample.algorithmSpec();
    wateringPosition.miniklerFogPart.algorithmSpec_subAndMain_differentT();
    wateringPosition.hoseConnectPart.subPePipeSample.algorithmSpec();
    wateringPosition.hoseConnectPart.algorithmSpec_subAndMain_differentT();
    wateringPosition.dripConnectPart.algorithmSpec_normalSaddle_pePipeSaddle();
    wateringPosition.dripConnectPart.algorithmSpec_startSaddle1hole_dripStartSaddle();
    wateringPosition.dripConnectPart.algorithmSpec_startSaddle2hole_dripStartSaddle();
    wateringPosition.dripConnectPart.algorithmSpec_startSaddle3hole_dripStartSaddle();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
