export const designCounsel: DesignCounsel = {
  outerCover: "",
  innerCover: "",
  curtain: "",
  side: "",
  roof: "",
  ventExhaust: "",
  ventInside: "",
  window: {
    type: "",
    count: 0,
  },
  front: {
    type: "",
    bay: 0,
    count: 0,
  },
  back: {
    type: "",
    bay: 0,
    count: 0,
  },
};
