import { jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { GutterPart_CondensationGutter } from "vhows-design/src/object/design/frame/gutter/GutterPart_CondensationGutter";
import { GutterPart_DrainagePleatedPipe } from "vhows-design/src/object/design/frame/gutter/GutterPart_DrainagePleatedPipe";
import { GutterPart_DrainagePVCPipe } from "vhows-design/src/object/design/frame/gutter/GutterPart_DrainagePVCPipe";
import { GutterPart_FixingGutterPad } from "vhows-design/src/object/design/frame/gutter/GutterPart_FixingGutterPad";
import { GutterPart_FixingNormalClip } from "vhows-design/src/object/design/frame/gutter/GutterPart_FixingNormalClip";
import { GutterPart_FixingScrew } from "vhows-design/src/object/design/frame/gutter/GutterPart_FixingScrew";
import { GutterPart_IronPlate } from "vhows-design/src/object/design/frame/gutter/GutterPart_IronPlate";
import { GutterPart_Plastic } from "vhows-design/src/object/design/frame/gutter/GutterPart_Plastic";
import { GutterPart_WeavingFilm } from "vhows-design/src/object/design/frame/gutter/GutterPart_WeavingFilm";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-07-20
 */
@jsonObject({
  knownTypes: [
    GutterPart_CondensationGutter,
    GutterPart_DrainagePleatedPipe,
    GutterPart_DrainagePVCPipe,
    GutterPart_FixingGutterPad,
    GutterPart_FixingNormalClip,
    GutterPart_FixingScrew,
    GutterPart_IronPlate,
    GutterPart_Plastic,
    GutterPart_WeavingFilm,
  ],
})
export class GutterPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public ironPlatePart: GutterPart_IronPlate;
  public plasticPart: GutterPart_Plastic;
  public weavingFilmPart: GutterPart_WeavingFilm;
  public fixingGutterPadPart: GutterPart_FixingGutterPad;
  public fixingScrewPart: GutterPart_FixingScrew;
  public fixingNormalClipPart: GutterPart_FixingNormalClip;
  public drainagePleatedPipePart: GutterPart_DrainagePleatedPipe;
  public drainagePVCPipePart: GutterPart_DrainagePVCPipe;
  public condensationGutterPart: GutterPart_CondensationGutter;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  get totalGutterLength(): number {
    let totalGutterLength: number = 0;

    for (let i: number = 0; i < this.struct.gutterWork.levelAC.length; i++) {
      if (this.struct.gutterWork.level1.selected) {
        totalGutterLength += (this.basicLevel.length + 1) * (this.basic.buildingNumber - 1);
      } else if (this.struct.gutterWork.levelAC[i].selected) {
        totalGutterLength += (this.basicLevel.length + 1) * (this.basic.buildingNumber * 2);
      }
    }
    return totalGutterLength;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _fixingType: string; // 물받이 고정 형태
  @jsonMember(String)
  public _drainageType: string; // 물받이 배수 형태
  @jsonMember(Boolean)
  public _condensationSelected: boolean = false; // 결로받이 선택

  /**
   * 물받이 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  //
  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 물받이 배수 형태
   */
  public get drainageType(): string {
    return this._drainageType;
  }

  //
  public set drainageType(value: string) {
    this._drainageType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByDrainageType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 결로받이 선택
   */
  public get condensationSelected(): boolean {
    return this._condensationSelected;
  }

  //
  public set condensationSelected(value: boolean) {
    this._condensationSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByCondensationGutterSelected();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;
    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_fixingTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
    this.ironPlatePart = new GutterPart_IronPlate();
    this.plasticPart = new GutterPart_Plastic();
    this.weavingFilmPart = new GutterPart_WeavingFilm();
    this.fixingGutterPadPart = new GutterPart_FixingGutterPad();
    this.fixingScrewPart = new GutterPart_FixingScrew();
    this.fixingNormalClipPart = new GutterPart_FixingNormalClip();
    this.drainagePleatedPipePart = new GutterPart_DrainagePleatedPipe();
    this.drainagePVCPipePart = new GutterPart_DrainagePVCPipe();
    this.condensationGutterPart = new GutterPart_CondensationGutter();

    this.partAC = [
      this.ironPlatePart,
      this.plasticPart,
      this.weavingFilmPart,
      this.fixingGutterPadPart,
      this.fixingScrewPart,
      this.fixingNormalClipPart,
      this.drainagePleatedPipePart,
      this.drainagePVCPipePart,
      this.condensationGutterPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.ironPlatePart = <GutterPart_IronPlate>this.partAC[0];
    this.plasticPart = <GutterPart_Plastic>this.partAC[1];
    this.weavingFilmPart = <GutterPart_WeavingFilm>this.partAC[2];
    this.fixingGutterPadPart = <GutterPart_FixingGutterPad>this.partAC[3];
    this.fixingScrewPart = <GutterPart_FixingScrew>this.partAC[4];
    this.fixingNormalClipPart = <GutterPart_FixingNormalClip>this.partAC[5];
    this.drainagePleatedPipePart = <GutterPart_DrainagePleatedPipe>this.partAC[6];
    this.drainagePVCPipePart = <GutterPart_DrainagePVCPipe>this.partAC[7];
    this.condensationGutterPart = <GutterPart_CondensationGutter>this.partAC[8];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param fixingType: string 물받이 고정 형태
   * @param drainageType: string 물받이 배수 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    fixingType: string = "",
    drainageType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._fixingType = fixingType;
    this._drainageType = drainageType;

    if (this.level.index === 0) {
      // 철판 물받이
      this.ironPlatePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_IRON_PLATE,
        true,
        false,
        true,
        CONST.LB_GUTTER_IRON_PLATE,
        "",
      );

      // 플라스틱 물받이
      this.plasticPart.setDefaultData(CONST.INDEX_IL_GUTTER_PLASTIC, false, false, false, CONST.LB_GUTTER_PLASTIC, "");

      // 직조필름 물받이
      this.weavingFilmPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_WEAVING_FILM,
        false,
        false,
        false,
        CONST.LB_GUTTER_WEAVING_FILM,
        "",
      );

      // 물받이 고정 - 물받이 패드
      this.fixingGutterPadPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_GUTTER_PAD,
        true,
        false,
        true,
        CONST.LB_GUTTER_FIXING_GUTTER_PAD,
        "",
      );

      // 물받이 고정 - 나사
      this.fixingScrewPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_SCREW,
        false,
        false,
        false,
        CONST.LB_GUTTER_FIXING_SCREW,
        "",
      );

      // 물받이 고정 - 일반 클립
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_GUTTER_FIXING_NORMAL_CLIP,
        "",
      );

      // 물받이 배수 - 주름관
      this.drainagePleatedPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PLEATED_PIPE,
        true,
        false,
        true,
        CONST.LB_GUTTER_DRAINAGE_PLEATED_PIPE,
        "",
      );

      // 물받이 배수 - PVC관
      this.drainagePVCPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PVC_PIPE,
        false,
        false,
        false,
        CONST.LB_GUTTER_DRAINAGE_PVC_PIPE,
        "",
      );

      // 결로받이
      this.condensationGutterPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_CONDENSATION,
        false,
        false,
        false,
        CONST.LB_GUTTER_CONDENSATION,
        "",
        CONST.LB_GUTTER_CONDENSATION_TYPE_PLASTIC,
      );
    } else {
      // 철판 물받이
      this.ironPlatePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_IRON_PLATE,
        true,
        false,
        true,
        CONST.LB_GUTTER_IRON_PLATE,
        "",
      );

      // 플라스틱 물받이
      this.plasticPart.setDefaultData(CONST.INDEX_IL_GUTTER_PLASTIC, false, false, false, CONST.LB_GUTTER_PLASTIC, "");

      // 직조필름 물받이
      this.weavingFilmPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_WEAVING_FILM,
        false,
        false,
        false,
        CONST.LB_GUTTER_WEAVING_FILM,
        "",
      );

      // 물받이 고정 - 물받이 패드
      this.fixingGutterPadPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_GUTTER_PAD,
        false,
        false,
        false,
        CONST.LB_GUTTER_FIXING_GUTTER_PAD,
        "",
      );

      // 물받이 고정 - 나사
      this.fixingScrewPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_SCREW,
        true,
        false,
        true,
        CONST.LB_GUTTER_FIXING_SCREW,
        "",
      );

      // 물받이 고정 - 일반 클립
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_GUTTER_FIXING_NORMAL_CLIP,
        "",
      );

      // 물받이 배수 - 주름관
      this.drainagePleatedPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PLEATED_PIPE,
        false,
        false,
        false,
        CONST.LB_GUTTER_DRAINAGE_PLEATED_PIPE,
        "",
      );

      // 물받이 배수 - PVC관
      this.drainagePVCPipePart.setDefaultData(
        CONST.INDEX_IL_GUTTER_DRAINAGE_PVC_PIPE,
        false,
        false,
        false,
        CONST.LB_GUTTER_DRAINAGE_PVC_PIPE,
        "",
      );

      // 결로받이
      this.condensationGutterPart.setDefaultData(
        CONST.INDEX_IL_GUTTER_CONDENSATION,
        false,
        false,
        false,
        CONST.LB_GUTTER_CONDENSATION,
        "",
        CONST.LB_GUTTER_CONDENSATION_TYPE_PLASTIC,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_selectedByBuildingNumber();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 선택 <- 동수(기본정보)
   */
  public algorithm_selectedByBuildingNumber(): void {
    // if (this.level.index === 0
    //   && this.basic.buildingNumber === 1) {
    //   this.selected = false;
    // }
    // // else {
    //   this.selected = true;
    // }
  }

  /**
   * 알고리즘: 선택 <- 기초 골조
   */
  // public algorithm_selectedByTrunk(): void {
  //   const trunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;

  //   for (let i: number = 0; i <= this.struct.trunkWork.levelAC.length; i++) {
  //     this.selected = trunkPosition.selected;
  //   }
  // }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_GUTTER_TYPE_IRON_PLATE) {
      this.ironPlatePart.selected = true;
      this.ironPlatePart.visible = true;
      this.plasticPart.selected = false;
      this.plasticPart.visible = false;
      this.weavingFilmPart.selected = false;
      this.weavingFilmPart.visible = false;
    } else if (this.type === CONST.LB_GUTTER_TYPE_PLASTIC) {
      this.ironPlatePart.selected = false;
      this.ironPlatePart.visible = false;
      this.plasticPart.selected = true;
      this.plasticPart.visible = true;
      this.weavingFilmPart.selected = false;
      this.weavingFilmPart.visible = false;
    } else if (this.type === CONST.LB_GUTTER_TYPE_WEAVING_FILM) {
      this.ironPlatePart.selected = false;
      this.ironPlatePart.visible = false;
      this.plasticPart.selected = false;
      this.plasticPart.visible = false;
      this.weavingFilmPart.selected = true;
      this.weavingFilmPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 물받이 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    if (this.fixingType === CONST.LB_GUTTER_FIXING_TYPE_GUTTER_PAD) {
      this.fixingGutterPadPart.selected = true;
      this.fixingGutterPadPart.visible = true;
      this.fixingScrewPart.selected = false;
      this.fixingScrewPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_GUTTER_FIXING_TYPE_SCREW) {
      this.fixingGutterPadPart.selected = false;
      this.fixingGutterPadPart.visible = false;
      this.fixingScrewPart.selected = true;
      this.fixingScrewPart.visible = true;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_GUTTER_FIXING_TYPE_NORMAL_CLIP) {
      this.fixingGutterPadPart.selected = false;
      this.fixingGutterPadPart.visible = false;
      this.fixingScrewPart.selected = false;
      this.fixingScrewPart.visible = false;
      this.fixingNormalClipPart.selected = true;
      this.fixingNormalClipPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 물받이 배수 형태
   */
  public algorithm_partActivationByDrainageType(): void {
    if (this.drainageType === CONST.LB_GUTTER_DRAINAGE_TYPE_PLEATED_PIPE) {
      this.drainagePleatedPipePart.selected = true;
      this.drainagePleatedPipePart.visible = true;
      this.drainagePVCPipePart.selected = false;
      this.drainagePVCPipePart.visible = false;
    } else if (this.drainageType === CONST.LB_GUTTER_DRAINAGE_TYPE_PVC_PIPE) {
      this.drainagePleatedPipePart.selected = false;
      this.drainagePleatedPipePart.visible = false;
      this.drainagePVCPipePart.selected = true;
      this.drainagePVCPipePart.visible = true;
    } else if (
      this.drainageType === CONST.LB_GUTTER_DRAINAGE_TYPE_IRON_PLATE_EXTENTION ||
      this.drainageType === CONST.LB_GUTTER_DRAINAGE_TYPE_NONE
    ) {
      this.drainagePleatedPipePart.selected = false;
      this.drainagePleatedPipePart.visible = false;
      this.drainagePVCPipePart.selected = false;
      this.drainagePVCPipePart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 결로받이 선택
   */
  public algorithm_partActivationByCondensationGutterSelected(): void {
    if (this.condensationSelected === true) {
      this.condensationGutterPart.selected = true;
      this.condensationGutterPart.visible = true;
    } else {
      this.condensationGutterPart.selected = false;
      this.condensationGutterPart.visible = false;
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태
   */
  public algorithm_fixingTypeByType(): void {
    if (this.level.index === 0) {
      this.fixingType = CONST.LB_GUTTER_FIXING_TYPE_GUTTER_PAD;
    } else {
      if (this.type === CONST.LB_GUTTER_TYPE_WEAVING_FILM) {
        this.fixingType = CONST.LB_GUTTER_FIXING_TYPE_NORMAL_CLIP;
      } else {
        this.fixingType = CONST.LB_GUTTER_FIXING_TYPE_SCREW;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
