import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemRailInterval } from "vhows-design/src/object/design/item/list/ItemRailInterval";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";
import { RailPosition } from "vhows-design/src/object/design/other/rail/RailPosition";
import { RailSample_GroundRailPipe } from "vhows-design/src/object/design/other/rail/RailSample_GroundRailPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-10-29
 */
@jsonObject({
  knownTypes: [RailSample_GroundRailPipe],
})
export class RailPart_GroundRail extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public railPipeSample: RailSample_GroundRailPipe; // 레일 파이프 샘플

  // 아이템
  public rail_pipe: ItemPipe;
  public rail_connectionPin: ItemConnector;
  public rail_railInterval: ItemRailInterval;
  public rail_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _totalRailLength: number = 0; // 총 레일 길이
  @jsonMember(Number)
  public _railLength: number = 0; // 레일 길이
  @jsonMember(Number)
  public _totalRailLineNumber: number = 0; // 총 레일 줄수
  @jsonMember(Number)
  public _railLineNumber: number = 0; // 레일 줄수
  @jsonMember(Number)
  public _railHookInterval: number = 0; // 운반 고리 간격

  /**
   * 총 레일 길이
   */
  public get totalRailLength(): number {
    return this._totalRailLength;
  }

  //
  public set totalRailLength(value: number) {
    this._totalRailLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 레일 길이
   */
  public get railLength(): number {
    return this._railLength;
  }

  //
  public set railLength(value: number) {
    this._railLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalRailLength();
    this.algorithmPart();
  }

  /**
   * 총 레일 줄수
   */
  public get totalRailLineNumber(): number {
    return this._totalRailLineNumber;
  }

  //
  public set totalRailLineNumber(value: number) {
    this._totalRailLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalRailLength();
    this.algorithmPart();
  }

  /**
   * 레일 줄수
   */
  public get railLineNumber(): number {
    return this._railLineNumber;
  }

  //
  public set railLineNumber(value: number) {
    this._railLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalRailLineNumber();
    this.algorithmPart();
  }

  /**
   * 운반 고리 간격
   */
  public get railHookInterval(): number {
    return this._railHookInterval;
  }

  //
  public set railHookInterval(value: number) {
    this._railHookInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.railPipeSample = new RailSample_GroundRailPipe();

    this.sampleAC = [this.railPipeSample];

    // 아이템
    this.rail_pipe = new ItemPipe();
    this.rail_connectionPin = new ItemConnector();
    this.rail_railInterval = new ItemRailInterval();
    this.rail_screw = new ItemScrew();

    this.itemAC = [this.rail_pipe, this.rail_connectionPin, this.rail_railInterval, this.rail_screw];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.railPipeSample = <RailSample_GroundRailPipe>this.sampleAC[0];
    // 아이템
    this.rail_pipe = <ItemPipe>this.itemAC[0];
    this.rail_connectionPin = <ItemConnector>this.itemAC[1];
    this.rail_railInterval = <ItemRailInterval>this.itemAC[2];
    this.rail_screw = <ItemScrew>this.itemAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param railLineNumber: number 레일 줄수
   * @param railHookInterval: number 운반 고리 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    railLineNumber: number = 0,
    railHookInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._railLineNumber = railLineNumber;
    this._railHookInterval = railHookInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.railPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지면 레일",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.rail_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "지면 레일",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.rail_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "지면 레일",
        "원형, 일반, 25mm, 25mm",
      );
      this.rail_railInterval.setDefaultData(
        2,
        CONST.ITEM_ID_RAIL_INTERVAL,
        CONST.ITEM_NAME_RAIL_INTERVAL,
        "지면 레일",
        "원형, 일반, 25mm, 45cm간격",
      );
      this.rail_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "지면 레일",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_railLength();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithm_totalRailLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const railPosition: RailPosition = <RailPosition>this.position;
    // 외부

    /// //////// 선택, 가시성 ///////////

    this.rail_pipe.checkSelected(true);
    this.rail_connectionPin.checkSelected(true);
    if (railPosition.groundType === CONST.LB_RAIL_TYPE_GROUND_DOUBLE) {
      this.rail_railInterval.selected = true;
      this.rail_railInterval.visible = true;
    } else if (railPosition.groundType === CONST.LB_RAIL_TYPE_GROUND_SINGLE) {
      this.rail_railInterval.selected = false;
      this.rail_railInterval.visible = false;
    }
    this.rail_screw.selected = true;
    this.rail_screw.visible = true;

    /// ////////  수치 ///////////

    let double: number = 1;
    if (railPosition.groundType === CONST.LB_RAIL_TYPE_GROUND_DOUBLE) {
      double = 2;
    }

    /// ////////  수량 ///////////

    this.rail_pipe.designQuantity =
      this.rail_pipe.getConnectedPipeLength(this.railLength * double, this.totalRailLineNumber) /
      this.rail_pipe.specLength;

    this.rail_connectionPin.designQuantity = this.rail_pipe.getSelectedQuantity();

    const railInterval_designQuantity: number =
      (this.railLength / this.railHookInterval + 1) * this.totalRailLineNumber;
    this.rail_railInterval.designQuantity = railInterval_designQuantity * CONST.NUM_EXTRA_RATE_RAIL_INTERVAL;

    this.rail_screw.designQuantity =
      ((this.rail_connectionPin.getSelectedQuantity() * 4 + this.rail_railInterval.getSelectedQuantity() * 2) /
        this.rail_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 총 레일 길이 <- 레일 길이, 총 레일 줄수
   */
  public algorithm_totalRailLength(): void {
    const railPosition: RailPosition = <RailPosition>this.position;
    this.totalRailLength = this.railLength * this.totalRailLineNumber;
  }

  /**
   * 알고리즘: 레일 길이 <- 하우스 길이, 하우스 중수
   */
  public algorithm_railLength(): void {
    const basicLevelLast: BasicLevel = <BasicLevel>this.basic.basicLevelAC[this.basic.levelNumber - 1];
    this.railLength = basicLevelLast.length;
  }

  /**
   * 알고리즘: 총 레일 줄수 <- 레일 줄수, 지면 레일 동수
   */
  public algorithm_totalRailLineNumber(): void {
    this.totalRailLineNumber = Math.ceil(this.railLineNumber * (<RailPosition>this.position).groundRailBuildingNumber);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [지면 레일] 파이프 <- 레일 파이프 샘플
   */
  public algorithmSpec_rail_pipe(): void {
    this.rail_pipe.specs = this.railPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [지면 레일] 연결핀 <- 레일 파이프 샘플
   */
  public algorithmSpec_rail_connectionPin(): void {
    this.rail_connectionPin.specPipeType = this.railPipeSample.specPipeType;
    this.rail_connectionPin.specCrossSize1 = this.railPipeSample.specCrossSize;
    this.rail_connectionPin.specCrossSize2 = this.railPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [지면 레일] 레일 간격유지 <- 레일 파이프 샘플, 지면 운반구 샘플
   */
  public algorithmSpec_rail_railInterval(): void {
    this.rail_railInterval.specPipeType = this.railPipeSample.specPipeType;
    this.rail_railInterval.specCrossSize = this.railPipeSample.specCrossSize;
    this.rail_railInterval.specInterval = (<RailPosition>this.position).carrierPart.groundCarrierSample.specInterval;
  }

  /**
   * 규격 알고리즘: [지면 레일] 나사 <- 공통 샘플
   */
  public algorithmSpec_rail_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
