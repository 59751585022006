import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { SkirtSampleIL_NormalClip } from "vhows-design/src/object/design/cover/skirt/il/SkirtSampleIL_NormalClip";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-04
 */
@jsonObject({
  knownTypes: [SkirtSampleIL_NormalClip],
})
export class SkirtPartIL_FixingNormalClip extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalClipSample: SkirtSampleIL_NormalClip; // 일반 클립 샘플

  // 아이템
  public normalClip_normalClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _clipInterval: number; // 클립 간격

  /**
   * 클립 간격
   */
  public get clipInterval(): number {
    return this._clipInterval;
  }

  public set clipInterval(value: number) {
    this._clipInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalClipSample = new SkirtSampleIL_NormalClip();

    this.sampleAC = [this.normalClipSample];

    // 아이템
    this.normalClip_normalClip = new ItemClip();

    this.itemAC = [this.normalClip_normalClip];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalClipSample = <SkirtSampleIL_NormalClip>this.sampleAC[0];

    // 아이템
    this.normalClip_normalClip = <ItemClip>this.itemAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param clipInterval: number 클립 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    clipInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._clipInterval = clipInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.normalClipSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalClip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skirtPosition: SkirtPositionIL = <SkirtPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;
    // 외부
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    );

    /// //////// 선택, 가시성 ///////////

    this.normalClip_normalClip.selected = true;
    this.normalClip_normalClip.visible = true;

    /// //////// 수량 ///////////

    if (skirtPosition.label === CONST.LB_POSITION_SIDE_COLUMN || skirtPosition.label === CONST.LB_POSITION_SIDE_WING) {
      this.normalClip_normalClip.designQuantity =
        Math.ceil(this.basicLevel.length / this.clipInterval) * switcherPosition.switchWayValue;
    } else if (skirtPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          bothEndQuantity = Math.ceil(this.basicLevel.length / this.clipInterval) * 2;
          centralQuantity =
            Math.ceil(this.basicLevel.length / this.clipInterval) *
            switcherPosition.switchWayValue *
            (this.basic.buildingNumber - 2);
          this.normalClip_normalClip.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          this.normalClip_normalClip.designQuantity =
            Math.ceil(this.basicLevel.length / this.clipInterval) *
            switcherPosition.switchWayValue *
            this.basic.buildingNumber;
        }
      } else {
        this.normalClip_normalClip.designQuantity =
          Math.ceil(this.basicLevel.length / this.clipInterval) * switcherPosition.switchWayValue;
      }
    } else if (skirtPosition.label === CONST.LB_POSITION_FRONT) {
      this.normalClip_normalClip.designQuantity = Math.ceil(
        (this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber) / this.clipInterval,
      );
    } else if (skirtPosition.label === CONST.LB_POSITION_BACK) {
      this.normalClip_normalClip.designQuantity = Math.ceil(
        (this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber) / this.clipInterval,
      );
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 클립] 일반 클립 <- 일반 클립 샘플
   */
  public algorithmSpec_normalClip_normalClip(): void {
    this.normalClip_normalClip.specs = this.normalClipSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
