import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { WateringPart_MiniklerFountain } from "vhows-design/src/object/design/watering/watering/WateringPart_MiniklerFountain";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject
export class WateringSample_MiniklerFountainSubPePipe extends ItemWateringPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const miniklerFountainPart: WateringPart_MiniklerFountain = <WateringPart_MiniklerFountain>this.part;
    miniklerFountainPart.algorithmSpec_sub_pePipe();
    miniklerFountainPart.algorithmSpec_subAndMain_exactT();
    miniklerFountainPart.algorithmSpec_subAndMain_differentT();
    miniklerFountainPart.algorithmSpec_sub_unionValve();
    miniklerFountainPart.algorithmSpec_sub_elbow();
    miniklerFountainPart.algorithmSpec_sub_differentConnectSocket();
    miniklerFountainPart.algorithmSpec_sub_valveSocket();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 형태(주관), 미니클러관 샘플, 개별 주관2(주관)
   */
  // @override
  public algorithmSpec(): void {
    const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
    if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_CENTER) {
      this.specs = (<WateringPart_MiniklerFountain>this.part).miniklerPePipeSample.specs;
    } else if (mainpipePosition.type === CONST.LB_WATERING_MAINPIPE_TYPE_PE_EACH) {
      this.specs = mainpipePosition.peMain2Part.main2EachPePipeSample.specs;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
