import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemBandstring } from "vhows-design/src/object/design/item/list/ItemBandstring";
import { ItemUtil } from "vhows-design/src/object/design/item/ItemUtil";
import { ItemInitDataIF } from "vhows-design/src/object/design/item/ItemInterface";
import { BandstringPosition } from "vhows-design/src/object/design/cover/bandstring/BandstringPosition";
import { BandstringPart_Bandstring } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_Bandstring";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-27
 */
@jsonObject
export class BandstringSample_Bandstring extends ItemBandstring {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////
    const bandstringPart: BandstringPart_Bandstring = <BandstringPart_Bandstring>this.part;
    bandstringPart.algorithmSpec_bandstring_bandstring();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 품목 상품
  //----------------------------------

  /**
   * 샘플 변경 알고리즘
   */
  public algorithmChangeSample(): void {
    const bandstringPosition: BandstringPosition = this.position as BandstringPosition;

    let itemInitData: ItemInitDataIF = null; // 초기 데이터
    if (bandstringPosition.type === CONST.LB_BANDSTRING_TYPE_VINYL) {
      itemInitData = ItemUtil.getInitItemData(CONST.ITEM_ID_VINYL_BAND);
    } else if (bandstringPosition.type === CONST.LB_BANDSTRING_TYPE_NET) {
      itemInitData = ItemUtil.getInitItemData(CONST.ITEM_ID_NET_BAND);
    } else if (bandstringPosition.type === CONST.LB_BANDSTRING_TYPE_WEAVING) {
      itemInitData = ItemUtil.getInitItemData(CONST.ITEM_ID_WEAVING_BAND);
    }

    if (itemInitData) {
      this._productId = itemInitData.productId;
      this._label = itemInitData.label;
      this._specs = itemInitData.specs;
      this.setDefaultVariable();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
