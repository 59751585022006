import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { PartitionLevel } from "vhows-design/src/object/design/other/partition/PartitionLevel";
import { PartitionPosition } from "vhows-design/src/object/design/other/partition/PartitionPosition";
import { WindowLevel } from "vhows-design/src/object/design/other/window/WindowLevel";
import { BandstringLevel } from "vhows-design/src/object/design/cover/bandstring/BandstringLevel";
import { BandstringPart_FixingNormalPad } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_FixingNormalPad";
import { ScreennetPart_FixingNormalPad } from "vhows-design/src/object/design/cover/screennet/ScreennetPart_FixingNormalPad";
import { ScreennetPosition } from "vhows-design/src/object/design/cover/screennet/ScreennetPosition";
import { SkirtPart_FixingNormalPad } from "vhows-design/src/object/design/cover/skirt/SkirtPart_FixingNormalPad";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { WindbreakPart_FixingNormalPad } from "vhows-design/src/object/design/cover/windbreak/WindbreakPart_FixingNormalPad";
import { WindbreakPosition } from "vhows-design/src/object/design/cover/windbreak/WindbreakPosition";
import { FixingPart_NormalPad } from "vhows-design/src/object/design/cover/fixing/FixingPart_NormalPad";
import { FixingPosition } from "vhows-design/src/object/design/cover/fixing/FixingPosition";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-26
 */
@jsonObject
export class FixingSample_NormalPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const normalPadPart: FixingPart_NormalPad = <FixingPart_NormalPad>this.part;
    normalPadPart.algorithmSpec_normalPad_normalPad();
    normalPadPart.algorithmSpec_normalPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<FixingPosition>this.level.positionAC[p]).normalPadPart.normalPadSample.specs = this.specs;
    }

    // 다른 중수
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        // 해당 중수의 측면 위치만 바꿔줘도 전체 위치의 규격이 바뀌게됨
        (<FixingLevel>this.work.levelAC[l]).sidePosition.normalPadPart.normalPadSample.specs = this.specs;
      }
    }

    /// //////// 외부 ///////////

    // 밴드끈
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_SIDE) {
        const bandstringPart_FixingNormalPad: BandstringPart_FixingNormalPad = (<BandstringLevel>(
          this.struct.bandstringWork.levelAC[this.level.index]
        )).trunkPosition.fixingNormalPadPart;
        bandstringPart_FixingNormalPad.normalPadSample.algorithmSpec();
      }
    }

    // 개폐기
    // const switcherPart_FixingNormalPad: SwitcherPart_FixingNormalPad = (<SwitcherPosition>this.struct.switcherWork.levelAC[this.level.index].positionAC[this.position.index]).fixingNormalPadPart;
    // switcherPart_FixingNormalPad.normalPadSample.algorithmSpec();

    // 치마 피복
    const skirtPart_FixingNormalPad: SkirtPart_FixingNormalPad = (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingNormalPadPart;
    skirtPart_FixingNormalPad.normalPadSample.algorithmSpec();

    // 바람막이 피복
    const windbreakPart_FixingNormalPad: WindbreakPart_FixingNormalPad = (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingNormalPadPart;
    windbreakPart_FixingNormalPad.normalPadSample.algorithmSpec();

    // 방충망
    if (this.level.index === 0) {
      const screennetPart_FixingNormalPad: ScreennetPart_FixingNormalPad = (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.position.index]
      )).fixingNormalPadPart;
      screennetPart_FixingNormalPad.normalPadSample.algorithmSpec();
    }

    // 창문
    if (this.level.index === 0) {
      const windowLevel: WindowLevel = <WindowLevel>this.struct.windowWork.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_SIDE) {
        windowLevel.leftPosition.fixingNormalPadPart.normalPadSample.algorithmSpec();
        windowLevel.rightPosition.fixingNormalPadPart.normalPadSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        windowLevel.frontPosition.fixingNormalPadPart.normalPadSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        windowLevel.backPosition.fixingNormalPadPart.normalPadSample.algorithmSpec();
      }
    }

    // 칸막이
    if (this.level.index === 0) {
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        const partitionPosition: PartitionPosition = (<PartitionLevel>(
          this.struct.partitionWork.levelAC[this.level.index]
        )).trunkPosition;
        partitionPosition.fixingNormalPadPart.normalPadSample.algorithmSpec();
      }
    }

    // 천창 피복
    if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_SIDE) {
      this.struct.skyFrameWork.level1.skylightPosition.enhancedPart.enhancedNormalPadSample.algorithmSpec();
      this.struct.skyCoverWork.level1.skylightPosition.normalPadPart.normalPadSample.algorithmSpec();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
