import { jsonObject } from "typedjson";

import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { CoverPart_Vinyl } from "vhows-design/src/object/design/cover/cover/CoverPart_Vinyl";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-06
 */
@jsonObject
export class CoverSample_Vinyl extends ItemVinyl {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const vinylPart: CoverPart_Vinyl = <CoverPart_Vinyl>this.part;
    vinylPart.algorithmSpec_item();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 바람막이 피복 (치마까지 연속호출됨)
    vinylPart.windbreakVinylSample.algorithmSpec_specThickness();
    vinylPart.windbreakVinylSample.algorithmSpec_brands();

    // 천창 피복
    if (this.level.index === 0 && this.position.index === 0) {
      // this.struct.skyCoverWork.level1.skylightPosition.vinylPart.vinylSample.algorithmSpec();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
