import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 공과잡비
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-23
 */
@jsonObject
export class ItemBuildingMis extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [기준], (백분율)
  public _specReference: string = undefined; // 기준
  public _specPercent: number = undefined; // 백분율

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 기준
   */
  public get specReference(): string {
    return this._specReference;
  }
  //
  public set specReference(value: string) {
    this._specReference = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 백분율
   */
  public get specPercent(): number {
    return this._specPercent;
  }
  //
  public set specPercent(value: number) {
    // 일반화된 간격 적용 - 0.1% 간격(소수점 둘째자리 올림)
    this._specPercent = CommonUtil.roundUp(value, 1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 백분율 레이블(값+단위기호)
   */
  public get specPercentLabel(): string {
    return this.specPercent + CONST.UNIT_PERCENT;
  }
  //
  public set specPercentLabel(value: string) {
    this.specPercent = parseFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.buildingMisCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가 (없음)

    // 설계 자재비
    let designMaterialSupply: number = 0;
    if (otherCost != null) {
      designMaterialSupply = otherCost;
    }

    // 매출가 계산식
    let sellingSupply: number = Math.ceil(designMaterialSupply * CommonUtil.convertPercent2Rate(this.specPercent));

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specReference = specsArray[0];
    this.specPercentLabel = specsArray[1];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specReference = specsArray[0];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specReference;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList + CONST.DELIMITER_SPEC + this.specPercentLabel;
  }
}
