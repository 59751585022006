import { jsonObject } from "typedjson";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ColumnPartIL_PrismMiddle } from "vhows-design/src/object/design/frame/column/ColumnPartIL_PrismMiddle";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-15
 */
@jsonObject
export class ColumnSampleIL_PrismMiddleTrussDiagonalPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismMiddlePart: ColumnPartIL_PrismMiddle = <ColumnPartIL_PrismMiddle>this.part;
    prismMiddlePart.algorithmSpec_middleTrussDiagonal_pipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 : 길이 <- 트러스 사재 길이
   */
  public algorithmSpec_specLength(): void {
    this.specLength = (<ColumnPositionIL>this.position).prismMiddlePart.trussDiagonalLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
