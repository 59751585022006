import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ItemControllerSensor } from "vhows-design/src/object/design/item/list/ItemControllerSensor";
import { ControllerPart_Controller } from "vhows-design/src/object/design/switches/controller/ControllerPart_Controller";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-08-14
 */
@jsonObject
export class ControllerSample_ControllerRaindropSensor extends ItemControllerSensor {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  /**
   * 설계 수량
   */
  // @override
  public get designQuantity(): number {
    return this._designQuantity;
  }

  //
  public set designQuantity(value: number) {
    if (value < 0) {
      this._designQuantity = 0;
    } else {
      this._designQuantity = CommonUtil.roundUp(CommonUtil.fixFloat(value), 1);
    }

    // 알고리즘
    if (this.isInit === false) {
      // 파트 알고리즘 호출
      this.part.algorithmPart();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const controllerPart: ControllerPart_Controller = <ControllerPart_Controller>this.part;
    controllerPart.algorithmSpec_controllerSensor_controllerRaindropSensor();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
