import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemReducedMotor } from "vhows-design/src/object/design/item/list/ItemReducedMotor";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurSwitchPartIL_Motor } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Motor";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject
export class CurSwitchSampleIL_ReducedMotor extends ItemReducedMotor {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const motorPart: CurSwitchPartIL_Motor = <CurSwitchPartIL_Motor>this.part;
    motorPart.algorithmSpec_motor_reducedMotor();
    motorPart.algorithm_availableCurtainArea();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 파트
    (<CurSwitchPositionIL>this.position).linePart.algorithm_windingDrumSelected();

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWorkIL.level1.integratedPosition.algorithm_usageCurSwitcherAndMotorQuantity();
    if (this.level.index === 0) {
      this.struct.controllerWorkIL.level1.integratedPosition.controllerPart.motorPowerElectricWireSample.algorithmSpec();
      this.struct.controllerWorkIL.level1.integratedPosition.controllerPart.motorControlElectricWireSample.algorithmSpec();
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 구동 축 파이프 샘플, 형태(기둥 골조), 원기둥 파이프 샘플, 각기둥 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specAxisCoupling = (<CurSwitchPositionIL>this.position).framePart.driveAxisPipeSample.specCrossSize;

    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.struct.columnWorkIL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.specSupportSize =
          this.struct.columnWorkIL.level1.columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      } else if (this.struct.columnWorkIL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.specSupportSize =
          this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      if (this.struct.columnWorkVL.level1.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.specSupportSize =
          this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
