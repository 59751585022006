import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemNonwoven } from "vhows-design/src/object/design/item/list/ItemNonwoven";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { CoverPart } from "vhows-design/src/object/design/cover/cover/CoverPart";
import { CoverSample_Nonwoven } from "vhows-design/src/object/design/cover/cover/CoverSample_Nonwoven";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-08
 */
@jsonObject({
  knownTypes: [CoverSample_Nonwoven],
})
export class CoverPart_Nonwoven extends CoverPart {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public nonwovenSample: CoverSample_Nonwoven; // 부직포 샘플

  // 아이템
  protected item: ItemNonwoven; // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.nonwovenSample = new CoverSample_Nonwoven();

    this.sampleAC = [this.nonwovenSample];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.nonwovenSample = <CoverSample_Nonwoven>this.sampleAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.nonwovenSample.setDefaultData(
        0,
        CONST.ITEM_ID_NONWOVEN,
        CONST.ITEM_NAME_NONWOVEN,
        "지붕+측면",
        "200g, 흰색, 6m, 56m, 끈 없음, 1회",
        "기본회사, 4m간격",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    //----------------------------------
    // 품목
    // 피복객체의 값을 실제 품목에 반영
    //----------------------------------

    // 가변길이인 경우
    if (this.basic.isVariableLength === true) {
      // 동수만큼 품목 생성
      for (let i: number = 0; i < this.basic.buildingNumber; i++) {
        // 측면
        if (this.coverSide != null) {
          this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

          this.item.purpose = `${i + 1}번동 피복: ${this.coverSide.purpose}`;
          this.item.specWidth = this.coverSide.width;
          this.item.specLength = this.coverSide.lengthAC[i];
          this.item.designQuantity = this.coverSide.quantity;

          this.itemAC.push(this.item);
        }

        // 측면(+측면+마구리) - 비대칭 천창(짧은쪽)
        if (this.coverSideSkyShort != null) {
          this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

          this.item.purpose = `${i + 1}번동 피복: ${this.coverSideSkyShort.purpose}`;
          this.item.specWidth = this.coverSideSkyShort.width;
          this.item.specLength = this.coverSideSkyShort.lengthAC[i];
          this.item.designQuantity = this.coverSideSkyShort.quantity;

          this.itemAC.push(this.item);
        }

        // 지붕(+측면+마구리)
        if (this.coverRoof != null) {
          this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

          this.item.purpose = `${i + 1}번동 피복: ${this.coverRoof.purpose}`;
          this.item.specWidth = this.coverRoof.width;
          this.item.specLength = this.coverRoof.lengthAC[i];
          this.item.designQuantity = this.coverRoof.quantity;

          this.itemAC.push(this.item);
        }

        // 지붕(+측면+마구리) - 비대칭 천창(짧은쪽)
        if (this.coverRoofSkyShort != null) {
          this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

          this.item.purpose = `${i + 1}번동 피복: ${this.coverRoofSkyShort.purpose}`;
          this.item.specWidth = this.coverRoofSkyShort.width;
          this.item.specLength = this.coverRoofSkyShort.lengthAC[i];
          this.item.designQuantity = this.coverRoofSkyShort.quantity;

          this.itemAC.push(this.item);
        }
      }
    } else {
      // 측면
      if (this.coverSide != null) {
        this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

        this.item.purpose = `피복: ${this.coverSide.purpose}`;
        this.item.specWidth = this.coverSide.width;
        this.item.specLength = this.coverSide.length;
        this.item.designQuantity = this.coverSide.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }

      // 측면(+측면+마구리) - 비대칭 천창(짧은쪽)
      if (this.coverSideSkyShort != null) {
        this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

        this.item.purpose = `피복: ${this.coverSideSkyShort.purpose}`;
        this.item.specWidth = this.coverSideSkyShort.width;
        this.item.specLength = this.coverSideSkyShort.length;
        this.item.designQuantity = this.coverSideSkyShort.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }

      // 지붕(+측면+마구리)
      if (this.coverRoof != null) {
        this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

        this.item.purpose = `피복: ${this.coverRoof.purpose}`;
        this.item.specWidth = this.coverRoof.width;
        this.item.specLength = this.coverRoof.length;
        this.item.designQuantity = this.coverRoof.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }

      // 지붕(+측면+마구리) - 비대칭 천창(짧은쪽)
      if (this.coverRoofSkyShort != null) {
        this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

        this.item.purpose = `피복: ${this.coverRoofSkyShort.purpose}`;
        this.item.specWidth = this.coverRoofSkyShort.width;
        this.item.specLength = this.coverRoofSkyShort.length;
        this.item.designQuantity = this.coverRoofSkyShort.quantity * this.basic.buildingNumber;

        this.itemAC.push(this.item);
      }
    }

    // 앞면(+뒷면)
    if (this.coverFront != null) {
      this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

      this.item.purpose = `마구리 피복: ${this.coverFront.purpose}`;
      this.item.specWidth = this.coverFront.width;
      this.item.specLength = this.coverFront.length;
      this.item.designQuantity = 1;

      this.itemAC.push(this.item);
    }

    // 뒷면
    if (this.coverBack != null) {
      this.item = ItemNonwoven.getNewDesignItemBySample(this, this.nonwovenSample);

      this.item.purpose = `마구리 피복: ${this.coverBack.purpose}`;
      this.item.specWidth = this.coverBack.width;
      this.item.specLength = this.coverBack.length;
      this.item.designQuantity = 1;

      this.itemAC.push(this.item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);

    /// //////// 외부 ///////////

    // 알고리즘 호출: 피복 파트
    (<CoverLevel>this.level).callAlgorithm_coverPartByCover();

    // 알고리즘 호출: 피복 고정 파트
    (<CoverLevel>this.level).callAlgorithm_fixingPartByCover();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 아이템 <- 샘플
   */
  public algorithmSpec_item(): void {
    for (const item of this.itemAC as ItemNonwoven[]) {
      item.specsList = this.nonwovenSample.specsList;
      item.specStringType = this.nonwovenSample.specStringType;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
