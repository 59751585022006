import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { WindowLevel } from "vhows-design/src/object/design/other/window/WindowLevel";
import { WindowPart_Hinged } from "vhows-design/src/object/design/other/window/WindowPart_Hinged";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-06
 */
@jsonObject
export class WindowSample_HingedFramePipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const hingedPart: WindowPart_Hinged = <WindowPart_Hinged>this.part;
    hingedPart.algorithmSpec_hinged_pipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevel>this.level).rightPosition.hingedPart.hingedFramePipeSample.specs = this.specs;
      (<WindowLevel>this.level).frontPosition.hingedPart.hingedFramePipeSample.specs = this.specs;
      (<WindowLevel>this.level).backPosition.hingedPart.hingedFramePipeSample.specs = this.specs;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevel>this.level).backPosition.hingedPart.hingedFramePipeSample.specs = this.specs;
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 가로대 파이프 샘플(기초 골조)
   */
  // @override
  public algorithmSpec(): void {
    this.specs = this.struct.trunkWork.level1.trunkPosition.straightPart.straightSidePipeSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
