import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { WindbreakPart_FixingNormalClip } from "vhows-design/src/object/design/cover/windbreak/WindbreakPart_FixingNormalClip";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-27
 */
@jsonObject
export class WindbreakSample_NormalClip extends ItemClip {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingNormalClipPart: WindbreakPart_FixingNormalClip = <WindbreakPart_FixingNormalClip>this.part;
    fixingNormalClipPart.algorithmSpec_normalclip_normalClip();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 서까래 파이프(기초 골조), 마구리 샛기둥 파이프(마구리)
   */
  // @override
  public algorithmSpec(): void {
    if (this.position.label === CONST.LB_POSITION_SIDE || this.position.label === CONST.LB_POSITION_ROOF) {
      this.specCrossSize = (<TrunkLevel>(
        this.struct.trunkWork.levelAC[this.level.index]
      )).trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.specCrossSize = (<EndpieceLevel>(
        this.struct.endpieceWork.levelAC[this.level.index]
      )).frontPosition.studPart.studPipeSample.specCrossSize;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.specCrossSize = (<EndpieceLevel>(
        this.struct.endpieceWork.levelAC[this.level.index]
      )).backPosition.studPart.studPipeSample.specCrossSize;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
