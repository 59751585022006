import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { EndpiecePart_Stud } from "vhows-design/src/object/design/frame/endpiece/EndpiecePart_Stud";
import { EndpiecePart_Middle } from "vhows-design/src/object/design/frame/endpiece/EndpiecePart_Middle";
import { EndpiecePart_Gate } from "vhows-design/src/object/design/frame/endpiece/EndpiecePart_Gate";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-01
 */
@jsonObject({
  knownTypes: [EndpiecePart_Stud, EndpiecePart_Middle, EndpiecePart_Gate],
})
export class EndpiecePosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public studPart: EndpiecePart_Stud;
  public middlePart: EndpiecePart_Middle;
  public gatePart: EndpiecePart_Gate;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 출입문 골조 선택
   */
  public get gateFrameSelected(): boolean {
    if (this.selected === true) {
      if (
        this.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.gateType === CONST.LB_GATE_TYPE_HINGED ||
        this.gateType === CONST.LB_GATE_TYPE_FRAMED
      ) {
        return true;
      } else if (this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME || this.gateType === CONST.LB_GATE_TYPE_NONE) {
        return false;
      }
    }
    return false;
  }

  /**
   * 출입문 피복 선택
   */
  public get gateCoverSelected(): boolean {
    if (this.selected === true) {
      if (this.gateType === CONST.LB_GATE_TYPE_SLIDING || this.gateType === CONST.LB_GATE_TYPE_HINGED) {
        return true;
      } else if (
        this.gateType === CONST.LB_GATE_TYPE_FRAMED ||
        this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME ||
        this.gateType === CONST.LB_GATE_TYPE_NONE
      ) {
        return false;
      }
    }
    return false;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _gateType: string; // 출입문 형태

  /**
   * 출입문 형태
   */
  public get gateType(): string {
    return this._gateType;
  }

  //
  public set gateType(value: string) {
    if (this._gateType === value) return;

    this._gateType = value;

    // 알고리즘
    this.studPart.algorithm_studCenterUpperSelected();
    this.gatePart.algorithm_makingMethod();
    this.gatePart.algorithm_doorWidth();
    this.gatePart.algorithm_doorHeight();
    this.gatePart.algorithm_doorQuantity();
    this.gatePart.algorithm_gateWidth();
    this.gatePart.algorithm_gateHeight();
    this.gatePart.cSectionSteelSample.algorithmSpec();

    // 파트 활성화
    this.algorithm_partActivationByGateType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    if (this.label === CONST.LB_POSITION_FRONT) {
      this.algorithm_gateTypeByPosition();
      this.gatePart.algorithm_makingMethodByPosition();
      this.gatePart.algorithm_doorWidthDoorHeightByPosition();
      this.gatePart.algorithm_doorQuantityByPosition();
    }

    /// //////// 외부 ///////////

    // 피복 고정
    if (this.label === CONST.LB_POSITION_FRONT) {
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).frontPosition.algorithm_endpieceGateLineNumber();
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).frontPosition.algorithm_status();
    } else if (this.label === CONST.LB_POSITION_BACK) {
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).backPosition.algorithm_endpieceGateLineNumber();
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).backPosition.algorithm_status();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByGateType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    if (this.label === CONST.LB_POSITION_FRONT) {
      // 개폐기
      (<SwitcherLevel>(
        this.struct.switcherWork.levelAC[this.level.index]
      )).frontPosition.algorithm_selectedAndEnabledByEndpiece();

      // 피복 고정
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).frontPosition.algorithm_endpieceGateLineNumber();
      (<FixingLevel>(
        this.struct.fixingWork.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceMiddleLineNumber();
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).frontPosition.algorithm_status();

      // 창문
      if (this.level.index === 0) {
        this.struct.windowWork.level1.frontPosition.algorithm_selectedAndEnabledByEndpiece();
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      // 개폐기
      (<SwitcherLevel>(
        this.struct.switcherWork.levelAC[this.level.index]
      )).backPosition.algorithm_selectedAndEnabledByEndpiece();

      // 피복 고정
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).backPosition.algorithm_endpieceGateLineNumber();
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).backPosition.algorithm_endpieceMiddleLineNumber();
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).backPosition.algorithm_status();

      // 창문
      if (this.level.index === 0) {
        this.struct.windowWork.level1.backPosition.algorithm_selectedAndEnabledByEndpiece();
      }
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_gateTypeByType();
    this.middlePart.algorithm_middleLineNumber();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    if (this.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevel>this.level).backPosition.algorithm_gateTypeByPosition();
    }

    // 다른 중수
    if (this.level.index === 0) {
      this.algorithm_typeByLevel1();
    }

    /// //////// 외부 ///////////

    // 피복 고정
    if (this.label === CONST.LB_POSITION_FRONT) {
      (<FixingLevel>(
        this.struct.fixingWork.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceMiddleLineNumber();
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).frontPosition.algorithm_status();
    } else if (this.label === CONST.LB_POSITION_BACK) {
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).backPosition.algorithm_endpieceMiddleLineNumber();
      (<FixingLevel>this.struct.fixingWork.levelAC[this.level.index]).backPosition.algorithm_status();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevel>this.struct.coverWork.levelAC[this.level.index]).algorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.studPart = new EndpiecePart_Stud();
    this.middlePart = new EndpiecePart_Middle();
    this.gatePart = new EndpiecePart_Gate();

    this.partAC = [this.studPart, this.middlePart, this.gatePart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.studPart = <EndpiecePart_Stud>this.partAC[0];
    this.middlePart = <EndpiecePart_Middle>this.partAC[1];
    this.gatePart = <EndpiecePart_Gate>this.partAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param gateType: string 출입문 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    gateType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._gateType = gateType;

    if (this.level.index >= 0) {
      // 마구리 샛기둥
      this.studPart.setDefaultData(
        CONST.INDEX_SG_ENDPIECE_STUD,
        true,
        false,
        true,
        CONST.LB_ENDPIECE_STUD,
        "",
        5,
        true,
      );

      // 마구리 중방
      this.middlePart.setDefaultData(
        CONST.INDEX_SG_ENDPIECE_MIDDLE,
        true,
        false,
        true,
        CONST.LB_ENDPIECE_MIDDLE,
        "",
        0,
      );

      // 마구리 출입문
      this.gatePart.setDefaultData(
        CONST.INDEX_SG_ENDPIECE_GATE,
        true,
        false,
        true,
        CONST.LB_ENDPIECE_GATE,
        "",
        CONST.LB_GATE_MAKING_METHOD_MADE,
        1300,
        2000,
        2,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 5 && (<Part>this.partAC[4]).label === "출입 문틀") {
      // // 마구리 출입문 - 제작형 문짝
      // const gateMakingDoorPart: EndpiecePart_GateMakingDoor = new EndpiecePart_GateMakingDoor();
      // gateMakingDoorPart.setAssociation(design, struct, work, level, this);
      // gateMakingDoorPart.setDefaultData(
      //   CONST.INDEX_SG_ENDPIECE_GATE_MAKING_DOOR,
      //   false, false, false,
      //   CONST.LB_ENDPIECE_GATE_MAKING_DOOR, '',
      //   CONST.LB_GATE_MAKING_DOOR_MAKING_METHOD_DOOR,
      //   1300, 2000,
      //   2,
      //   CONST.LB_GATE_MAKING_DOOR_PIPE_HOLER_TYPE_T_HOLDER,
      // );
      // if (this.gateType === CONST.LB_GATE_TYPE_SLIDING_MAKING
      //   || this.gateType === CONST.LB_GATE_TYPE_HINGED_MAKING) {
      //   gateMakingDoorPart._selected = true;
      //   gateMakingDoorPart._visible = true;
      // }
      // this.partAC.splice(4, 0, gateMakingDoorPart);

      // 임시 데이터로 대체
      this.partAC.splice(4, 0, new Part());
    }
    if (this.partAC.length === 6 && (<Part>this.partAC[5]).label === "출입 문틀") {
      // // 마구리 출입문 - 전면 샷시틀
      // const gateSashFramePart: EndpiecePart_GateSashFrame = new EndpiecePart_GateSashFrame();
      // gateSashFramePart.setAssociation(design, struct, work, level, this);
      // gateSashFramePart.setDefaultData(
      //   CONST.INDEX_SG_ENDPIECE_GATE_SASH_FRAME,
      //   false, false, false,
      //   CONST.LB_ENDPIECE_GATE_SASH_FRAME, '',
      //   6000, 2000,
      // );
      // this.partAC.push(gateSashFramePart);

      // 임시 데이터로 대체
      this.partAC.push(new Part());
    }
    if (this.partAC.length === 7 && (<Part>this.partAC[5]).label === "출입 문틀") {
      // 통합 출입문 파트 추가 및 기존 출입문관련 파트 모두 삭제
      const gatePart: EndpiecePart_Gate = new EndpiecePart_Gate();
      gatePart.setAssociation(design, struct, work, level, this);
      gatePart.setDefaultData(
        CONST.INDEX_SG_ENDPIECE_GATE,
        false,
        false,
        false,
        CONST.LB_ENDPIECE_GATE,
        "",
        CONST.LB_GATE_MAKING_METHOD_MADE,
        1300,
        2000,
        2,
      );
      if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
        gatePart._selected = true;
        gatePart._visible = true;
      }
      this.partAC.splice(2, 5, gatePart);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
      // 알고리즘 동작
      gatePart.setDefaultVariable();
      gatePart.algorithmPart();
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
      this.studPart.selected = true;
      this.studPart.visible = true;
      this.middlePart.selected = true;
      this.middlePart.visible = true;
    } else if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      this.studPart.selected = true;
      this.studPart.visible = true;
      this.middlePart.selected = true;
      this.middlePart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 출입문 형태
   */
  public algorithm_partActivationByGateType(): void {
    if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
      this.gatePart.selected = true;
      this.gatePart.visible = true;
    } else {
      this.gatePart.selected = false;
      this.gatePart.visible = false;
    }
  }

  /**
   * 알고리즘: 형태, 문 형태 <- 선택(개폐기)
   */
  public algorithm_typeAndGateType(): void {
    const switcherLevel: SwitcherLevel = <SwitcherLevel>this.struct.switcherWork.levelAC[this.level.index];

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (switcherLevel.frontPosition.selected === true) {
        this.type = CONST.LB_ENDPIECE_TYPE_WHOLE;
        this.gateType = CONST.LB_GATE_TYPE_NONE;
      } else {
        this.algorithm_gateTypeByType();
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (switcherLevel.backPosition.selected === true) {
        this.type = CONST.LB_ENDPIECE_TYPE_WHOLE;
        this.gateType = CONST.LB_GATE_TYPE_NONE;
      } else {
        this.algorithm_gateTypeByType();
      }
    }
  }

  /**
   * 알고리즘: 출입문 형태 <- 형태
   */
  public algorithm_gateTypeByType(): void {
    if (this.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
      if (this.gateType === CONST.LB_GATE_TYPE_NONE || this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME) {
        if (this.level.index === 0) {
          this.gateType = CONST.LB_GATE_TYPE_SLIDING;
        } else {
          this.gateType = CONST.LB_GATE_TYPE_FRAMED;
        }
      }
    } else if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      if (this.gateType !== CONST.LB_GATE_TYPE_SASH_FRAME) {
        this.gateType = CONST.LB_GATE_TYPE_NONE;
      }
    }
  }

  /**
   * 알고리즘: 출입문 형태 <- 출입문 형태, 형태
   */
  public algorithm_gateTypeByPosition(): void {
    if (this.label === CONST.LB_POSITION_FRONT) {
      if (this.type === (<EndpieceLevel>this.level).backPosition.type) {
        (<EndpieceLevel>this.level).backPosition.gateType = this.gateType;
      }
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(1중)
   */
  public algorithm_typeByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.endpieceWork.levelAC.length; l++) {
        (<EndpiecePosition>this.work.levelAC[l].positionAC[this.index]).type = this.type;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
