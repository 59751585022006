import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemPipeBendingExpense } from "vhows-design/src/object/design/item/list/ItemPipeBendingExpense";
import { ItemSconce } from "vhows-design/src/object/design/item/list/ItemSconce";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { BandstringLevelIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringLevelIL";
import { RoofSampleIL_RafterPipe } from "vhows-design/src/object/design/frame/roof/RoofSampleIL_RafterPipe";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-21
 */
@jsonObject({
  knownTypes: [RoofSampleIL_RafterPipe],
})
export class RoofPartIL_Rafter extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public rafterPipeSample: RoofSampleIL_RafterPipe; // 서까래 파이프 샘플

  // 아이템
  public rafter_pipe: ItemPipe;
  public rafterEdge_pipe: ItemPipe;
  public rafter_pipeBendingExpense: ItemPipeBendingExpense;
  public rafterEdgePinned_pipe: ItemPipe;
  public rafterEdgePinned_pipeBendingExpense: ItemPipeBendingExpense;
  public rafterAndBeamCenter_firstLevelPairSconce: ItemSconce;
  public rafterAndColumnCenterCylinder_secondLevelPairSconce: ItemSconce;
  public rafterAndColumnCenterPrism_secondLevelSoleSconce: ItemSconce;
  public rafterAndBeamEdge_firstLevelSoleSconce: ItemSconce;
  public rafterAndColumnEdge_secondLevelSoleSconce: ItemSconce;
  public rafterAndBeamEdgeCylinder_steelStringGrabber: ItemHolder;
  public rafterAndBeamEdgeCylinder_uClamp: ItemHolder;
  public rafterAndBeamEdgePrism_outerWallClamp: ItemHolder;
  public rafter_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 이상적인 서까래 길이
   */
  public get lengthRafterIdeal(): number {
    return this.basicLevel.lengthRafterIdeal;
  }

  //
  public set lengthRafterIdeal(value: number) {
    this.basicLevel.lengthRafterIdeal = value;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _rafterInterval: number; // 서까래 간격
  @jsonMember(Number)
  public _rafterQuantity: number; // 서까래 수량
  @jsonMember(Number)
  public _rafterTotalLength: number; // 최종 서까래 길이
  @jsonMember(Boolean)
  public _rafterLengthRoundSelected: boolean; // 서까래 길이 반올림 선택

  @jsonMember(Boolean)
  public _trussSelected: boolean; // 서까래 트러스 선택
  @jsonMember(Number)
  public _trussMultiple: number; // 서까래 트러스 배율
  @jsonMember(Number)
  public _trussInterval: number; // 서까래 트러스 간격

  @jsonMember(Boolean)
  public _edgeRafterExtensionSelected: boolean; // 양끝동 서까래 확장 선택
  @jsonMember(Number)
  public _edgeRafterTotalLength: number; // 양끝동 최종 서까래 길이
  @jsonMember(Number)
  public _edgeRafterExtensionLength: number; // 양끝동 서까래 확장 길이
  @jsonMember(Boolean)
  public _edgeRafterImprintSelected: boolean; // 양끝동 서까래 인발 선택
  @jsonMember(Number)
  public _edgeRafterImprintLength: number; // 양끝동 서까래 인발 길이

  /**
   * 서까래 간격
   */
  public get rafterInterval(): number {
    return this._rafterInterval;
  }

  //
  public set rafterInterval(value: number) {
    this._rafterInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_rafterQuantity();
    (<RoofPositionIL>this.position).algorithm_frontEaveLength();
    (<RoofPositionIL>this.position).algorithm_backEaveLength();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 방풍벽 골조
    (<WingLevelIL>(
      this.struct.wingWorkIL.levelAC[this.level.index]
    )).wingPosition.wingRafterPart.algorithm_wingRafterInterval();

    // 물받이
    if (this.level.index === 0) {
      this.struct.gutterWorkIL.level1.gutterPosition.condensationGutterPart.algorithmPart();
    }

    // 치마
    (<SkirtLevelIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index]
    )).roofPosition.fixingSkirtClipPart.algorithm_clipInterval();

    // 개폐기
    (<SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index]).roofPosition.axisPart.algorithmPart();

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();

    // 밴드끈
    if (this.level.index === 0) {
      for (const bandstringPosition of this.struct.bandstringWorkIL.level1.positionAC as BandstringPositionIL[]) {
        bandstringPosition.algorithm_lineIntervalByRafter();
        bandstringPosition.fixingNormalPadPart.algorithmPart();
      }
    }

    // 천창 골조
    if (this.level.index === 0) {
      this.struct.skyFrameWorkIL.level1.skylightPosition.rafterPart.algorithm_rafterInterval();
    }

    // 관수 지관부
    this.struct.wateringWork.level1.wholePosition.hoseSidePart.algorithm_hangerInterval();
  }

  /**
   * 서까래 수량
   */
  public get rafterQuantity(): number {
    return this._rafterQuantity;
  }

  //
  public set rafterQuantity(value: number) {
    this._rafterQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<RoofPositionIL>this.position).straightPart.algorithmPart();

    /// //////// 외부 ///////////

    // 천창 골조
    if (this.level.index === 0) {
      this.struct.skyFrameWorkIL.level1.skylightPosition.enhancedPart.algorithmPart();
    }
  }

  /**
   * 최종 서까래 길이
   */
  public get rafterTotalLength(): number {
    return this._rafterTotalLength;
  }

  //
  public set rafterTotalLength(value: number) {
    this._rafterTotalLength = CommonUtil.fixFloat(value);

    /// //////// 외부 ///////////

    // 기본정보
    this.design.basic.basicLevelAC[this.level.index].algorithm_perimeterRoof();

    // 밴드끈
    if (this.level.index === 0) {
      const bandstringLevel: BandstringLevelIL = <BandstringLevelIL>(
        this.struct.bandstringWorkIL.levelAC[this.level.index]
      );

      bandstringLevel.trunkPosition.bandstringPart.algorithmPart();
      bandstringLevel.trunkPosition.bandstringPart.algorithm_bandstringLength();
      bandstringLevel.trunkPosition.polyPart.algorithmPart();
      bandstringLevel.trunkPosition.polyPart.algorithm_bandstringLength();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 서까래 길이 반올림 선택
   */
  public get rafterLengthRoundSelected(): boolean {
    return this._rafterLengthRoundSelected;
  }

  //
  public set rafterLengthRoundSelected(value: boolean) {
    this._rafterLengthRoundSelected = value;

    // 알고리즘
    this.rafterPipeSample.algorithmSpec_specLength();
    this.algorithmPart();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_rafterLengthRoundSelectedByLevel1();
    }
  }

  /**
   * 서까래 트러스 선택
   */
  public get trussSelected(): boolean {
    return this._trussSelected;
  }

  //
  public set trussSelected(value: boolean) {
    this._trussSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 서까래 트러스 배율
   */
  public get trussMultiple(): number {
    return this._trussMultiple;
  }

  //
  public set trussMultiple(value: number) {
    this._trussMultiple = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 서까래 트러스 간격
   */
  public get trussInterval(): number {
    return this._trussInterval;
  }

  //
  public set trussInterval(value: number) {
    this._trussInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 양끝동 서까래 확장 선택
   */
  public get edgeRafterExtensionSelected(): boolean {
    return this._edgeRafterExtensionSelected;
  }

  //
  public set edgeRafterExtensionSelected(value: boolean) {
    this._edgeRafterExtensionSelected = value;

    // 알고리즘
    this.algorithm_edgeTotalRafterLength();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 방풍벽 골조
    if (this.level.index === 0) {
      this.struct.wingWorkIL.level1.wingPosition.algorithm_partActivationByType();
    }
  }

  /**
   * 양끝동 최종 서까래 길이
   */
  public get edgeRafterTotalLength(): number {
    return this._edgeRafterTotalLength;
  }

  //
  public set edgeRafterTotalLength(value: number) {
    this._edgeRafterTotalLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmSpec_rafterEdge_pipe();
  }

  /**
   * 양끝동 서까래 확장 길이
   */
  public get edgeRafterExtensionLength(): number {
    return this._edgeRafterExtensionLength;
  }

  //
  public set edgeRafterExtensionLength(value: number) {
    this._edgeRafterExtensionLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_edgeTotalRafterLength();
    this.algorithm_edgeRafterImprintLength();
    this.algorithmPart();
  }

  /**
   * 양끝동 서까래 인발 선택
   */
  public get edgeRafterImprintSelected(): boolean {
    return this._edgeRafterImprintSelected;
  }

  //
  public set edgeRafterImprintSelected(value: boolean) {
    this._edgeRafterImprintSelected = value;

    // 알고리즘
    this.algorithmSpec_rafterEdge_pipe();
    this.algorithmPart();
  }

  /**
   * 양끝동 서까래 인발 길이
   */
  public get edgeRafterImprintLength(): number {
    return this._edgeRafterImprintLength;
  }

  //
  public set edgeRafterImprintLength(value: number) {
    this._edgeRafterImprintLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmSpec_rafterEdge_pipe();
    this.algorithmSpec_rafterEdgePinned_pipe();
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.rafterPipeSample = new RoofSampleIL_RafterPipe();

    this.sampleAC = [this.rafterPipeSample];

    // 아이템
    this.rafter_pipe = new ItemPipe();
    this.rafterEdge_pipe = new ItemPipe();
    this.rafter_pipeBendingExpense = new ItemPipeBendingExpense();
    this.rafterEdgePinned_pipe = new ItemPipe();
    this.rafterEdgePinned_pipeBendingExpense = new ItemPipeBendingExpense();
    this.rafterAndBeamCenter_firstLevelPairSconce = new ItemSconce();
    this.rafterAndColumnCenterCylinder_secondLevelPairSconce = new ItemSconce();
    this.rafterAndColumnCenterPrism_secondLevelSoleSconce = new ItemSconce();
    this.rafterAndBeamEdge_firstLevelSoleSconce = new ItemSconce();
    this.rafterAndColumnEdge_secondLevelSoleSconce = new ItemSconce();
    this.rafterAndBeamEdgeCylinder_steelStringGrabber = new ItemHolder();
    this.rafterAndBeamEdgeCylinder_uClamp = new ItemHolder();
    this.rafterAndBeamEdgePrism_outerWallClamp = new ItemHolder();
    this.rafter_screw = new ItemScrew();

    this.itemAC = [
      this.rafter_pipe,
      this.rafterEdge_pipe,
      this.rafter_pipeBendingExpense,
      this.rafterEdgePinned_pipe,
      this.rafterEdgePinned_pipeBendingExpense,
      this.rafterAndBeamCenter_firstLevelPairSconce,
      this.rafterAndColumnCenterCylinder_secondLevelPairSconce,
      this.rafterAndColumnCenterPrism_secondLevelSoleSconce,
      this.rafterAndBeamEdge_firstLevelSoleSconce,
      this.rafterAndColumnEdge_secondLevelSoleSconce,
      this.rafterAndBeamEdgeCylinder_steelStringGrabber,
      this.rafterAndBeamEdgeCylinder_uClamp,
      this.rafterAndBeamEdgePrism_outerWallClamp,
      this.rafter_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.rafterPipeSample = <RoofSampleIL_RafterPipe>this.sampleAC[0];

    // 아이템
    this.rafter_pipe = <ItemPipe>this.itemAC[0];
    this.rafterEdge_pipe = <ItemPipe>this.itemAC[1];
    this.rafter_pipeBendingExpense = <ItemPipeBendingExpense>this.itemAC[2];
    this.rafterEdgePinned_pipe = <ItemPipe>this.itemAC[3];
    this.rafterEdgePinned_pipeBendingExpense = <ItemPipeBendingExpense>this.itemAC[4];
    this.rafterAndBeamCenter_firstLevelPairSconce = <ItemSconce>this.itemAC[5];
    this.rafterAndColumnCenterCylinder_secondLevelPairSconce = <ItemSconce>this.itemAC[6];
    this.rafterAndColumnCenterPrism_secondLevelSoleSconce = <ItemSconce>this.itemAC[7];
    this.rafterAndBeamEdge_firstLevelSoleSconce = <ItemSconce>this.itemAC[8];
    this.rafterAndColumnEdge_secondLevelSoleSconce = <ItemSconce>this.itemAC[9];
    this.rafterAndBeamEdgeCylinder_steelStringGrabber = <ItemHolder>this.itemAC[10];
    this.rafterAndBeamEdgeCylinder_uClamp = <ItemHolder>this.itemAC[11];
    this.rafterAndBeamEdgePrism_outerWallClamp = <ItemHolder>this.itemAC[12];
    this.rafter_screw = <ItemScrew>this.itemAC[13];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param rafterInterval: number 서까래 간격
   * @param trussSelected: boolean 트러스 선택
   * @param trussMultiple: number 트러스 배율
   * @param trussInterval: number 트러스 간격
   * @param edgeRafterExtensionLength: number 양끝동 서까래 확장 길이
   * @param edgeRafterImprintLength: number 양끝동 서까래 인발 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    rafterInterval: number = 0,
    trussSelected: boolean = false,
    trussMultiple: number = 0,
    trussInterval: number = 0,
    edgeRafterExtensionLength: number = 0,
    edgeRafterImprintLength: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._rafterInterval = rafterInterval;
    this._trussSelected = trussSelected;
    this._trussMultiple = trussMultiple;
    this._trussInterval = trussInterval;
    this._edgeRafterExtensionLength = edgeRafterExtensionLength;
    this._edgeRafterImprintLength = edgeRafterImprintLength;

    // 샘플
    if (this.level.index >= 0) {
      this.rafterPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래",
        "원형, 일반, 31.8mm, 1.5T, 8m, 벤딩",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.rafter_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래",
        "원형, 일반, 31.8mm, 1.5T, 8m, 벤딩",
      );
      this.rafterEdge_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래(양끝)",
        "원형, 일반, 31.8mm, 1.5T, 12m, 벤딩",
      );
      this.rafter_pipeBendingExpense.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래",
        "원형, 31.8mm",
      );
      this.rafterEdgePinned_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래 확장(양끝)",
        "원형, 핀가공, 31.8mm, 1.5T, 4m, 벤딩",
      );
      this.rafter_pipeBendingExpense.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래 확장(양끝)",
        "원형, 31.8mm",
      );
      this.rafterAndBeamCenter_firstLevelPairSconce.setDefaultData(
        5,
        CONST.ITEM_ID_FIRST_LEVEL_PAIR_SCONCE,
        CONST.ITEM_NAME_FIRST_LEVEL_PAIR_SCONCE,
        "서까래 + 기둥(중앙)",
        "원형, 호주식, 48mm, 32mm",
      );
      this.rafterAndColumnCenterCylinder_secondLevelPairSconce.setDefaultData(
        6,
        CONST.ITEM_ID_SECOND_LEVEL_PAIR_SCONCE,
        CONST.ITEM_NAME_SECOND_LEVEL_PAIR_SCONCE,
        "서까래 + 기둥(중앙)",
        "원형, 일반, 48mm, 25mm",
      );
      this.rafterAndColumnCenterPrism_secondLevelSoleSconce.setDefaultData(
        7,
        CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE,
        "서까래 + 기둥(중앙)",
        "각형, 일반, 60×60mm, 25mm",
      );
      this.rafterAndBeamEdge_firstLevelSoleSconce.setDefaultData(
        8,
        CONST.ITEM_ID_FIRST_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_FIRST_LEVEL_SOLE_SCONCE,
        "서까래 + 기둥(양끝)",
        "원형, 호주식, 48mm, 32mm",
      );
      this.rafterAndColumnEdge_secondLevelSoleSconce.setDefaultData(
        9,
        CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE,
        "서까래 + 기둥(양끝)",
        "원형, 일반, 48mm, 25mm",
      );
      this.rafterAndBeamEdgeCylinder_steelStringGrabber.setDefaultData(
        10,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "서까래 확장 + 보(양끝)",
        "원형, 일반, 48mm, 32mm",
      );
      this.rafterAndBeamEdgeCylinder_uClamp.setDefaultData(
        11,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "서까래 확장 + 보(양끝)",
        "원형, 일반, 60mm, 32mm",
      );
      this.rafterAndBeamEdgePrism_outerWallClamp.setDefaultData(
        12,
        CONST.ITEM_ID_OUTER_WALL_CLAMP,
        CONST.ITEM_NAME_OUTER_WALL_CLAMP,
        "서까래 확장 + 보(양끝)",
        "각형, 일반, 60×60mm, 32mm",
      );
      this.rafter_screw.setDefaultData(
        13,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "서까래",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 7 && (<Item>this.itemAC[3]).productId === CONST.ITEM_ID_SCREW) {
      // 나사
      (<Item>this.itemAC[6]).purpose = "서까래";
      const screw_designQuantity: number =
        (<Item>this.itemAC[3]).getSelectedQuantity() + (<Item>this.itemAC[6]).getSelectedQuantity();
      (<Item>this.itemAC[6]).selected = true;
      (<Item>this.itemAC[6]).visible = true;
      (<Item>this.itemAC[6]).designQuantity = screw_designQuantity;
      // 중간 나사 제거
      this.itemAC.splice(3, 1);

      // [서까래(양끝)] 파이프
      const rafterEdge_pipe: ItemPipe = new ItemPipe();
      rafterEdge_pipe.setAssociation(design, struct, work, level, position, this);
      rafterEdge_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래(양끝)",
        "원형, 일반, 31.8mm, 1.5T, 12m, -",
      );
      rafterEdge_pipe.selected = false;
      rafterEdge_pipe.visible = false;
      this.itemAC.splice(1, 0, rafterEdge_pipe);
      // [서까래 확장(양끝)] 파이프
      const rafterEdgePinned_pipe: ItemPipe = new ItemPipe();
      rafterEdgePinned_pipe.setAssociation(design, struct, work, level, position, this);
      rafterEdgePinned_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "서까래 확장(양끝)",
        "원형, 핀가공, 31.8mm, 1.5T, 4m, -",
      );
      rafterEdgePinned_pipe.selected = false;
      rafterEdgePinned_pipe.visible = false;
      this.itemAC.splice(2, 0, rafterEdgePinned_pipe);
      // [서까래 확장 + 보(양끝)] 강선 조리개
      const rafterAndBeamEdgeCylinder_steelStringGrabber: ItemHolder = new ItemHolder();
      rafterAndBeamEdgeCylinder_steelStringGrabber.setAssociation(design, struct, work, level, position, this);
      rafterAndBeamEdgeCylinder_steelStringGrabber.setDefaultData(
        7,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "서까래 확장 + 보(양끝)",
        "원형, 일반, 48mm, 32mm",
      );
      rafterAndBeamEdgeCylinder_steelStringGrabber.selected = false;
      rafterAndBeamEdgeCylinder_steelStringGrabber.visible = false;
      this.itemAC.splice(7, 0, rafterAndBeamEdgeCylinder_steelStringGrabber);
      // [서까래 확장 + 보(양끝)] 외벽 클램프
      const rafterAndBeamEdgePrism_outerWallClamp: ItemHolder = new ItemHolder();
      rafterAndBeamEdgePrism_outerWallClamp.setAssociation(design, struct, work, level, position, this);
      rafterAndBeamEdgePrism_outerWallClamp.setDefaultData(
        8,
        CONST.ITEM_ID_OUTER_WALL_CLAMP,
        CONST.ITEM_NAME_OUTER_WALL_CLAMP,
        "서까래 확장 + 보(양끝)",
        "각형, 일반, 60×60mm, 32mm",
      );
      rafterAndBeamEdgePrism_outerWallClamp.selected = false;
      rafterAndBeamEdgePrism_outerWallClamp.visible = false;
      this.itemAC.splice(8, 0, rafterAndBeamEdgePrism_outerWallClamp);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 10 && (<Item>this.itemAC[5]).productId === CONST.ITEM_ID_FIRST_LEVEL_SOLE_SCONCE) {
      // [서까래 + 기둥(중앙)] 이중 외꽂이
      const rafterAndColumnCenterPrism_secondLevelSoleSconce: ItemSconce = new ItemSconce();
      rafterAndColumnCenterPrism_secondLevelSoleSconce.setAssociation(design, struct, work, level, position, this);
      rafterAndColumnCenterPrism_secondLevelSoleSconce.setDefaultData(
        5,
        CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE,
        CONST.ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE,
        "서까래 + 기둥(중앙)",
        "각형, 일반, 60×60mm, 25mm",
      );
      rafterAndColumnCenterPrism_secondLevelSoleSconce.selected = false;
      rafterAndColumnCenterPrism_secondLevelSoleSconce.visible = false;
      this.itemAC.splice(5, 0, rafterAndColumnCenterPrism_secondLevelSoleSconce);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 11 && (<Item>this.itemAC[9]).productId !== CONST.ITEM_ID_U_CLAMP) {
      // [서까래 확장 + 보(양끝)] U 클램프 추가
      const rafterAndBeamEdgeCylinder_uClamp: ItemHolder = new ItemHolder();
      rafterAndBeamEdgeCylinder_uClamp.setAssociation(design, struct, work, level, position, this);
      rafterAndBeamEdgeCylinder_uClamp.setDefaultData(
        9,
        CONST.ITEM_ID_U_CLAMP,
        CONST.ITEM_NAME_U_CLAMP,
        "서까래 확장 + 보(양끝)",
        "원형, 일반, 60mm, 32mm",
      );
      rafterAndBeamEdgeCylinder_uClamp.selected = false;
      rafterAndBeamEdgeCylinder_uClamp.visible = false;
      this.itemAC.splice(9, 0, rafterAndBeamEdgeCylinder_uClamp);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (
      this.itemAC.length === 12 &&
      (<ItemPipe>this.itemAC[2]).productId === CONST.ITEM_ID_PIPE &&
      (<ItemSconce>this.itemAC[4]).productId === CONST.ITEM_ID_SECOND_LEVEL_PAIR_SCONCE
    ) {
      // 기존 규격
      const specsArray: any[] = (<ItemPipe>this.itemAC[0]).specs.split(CONST.DELIMITER_SPEC);
      // [서까래] 파이프 벤딩비
      const rafter_pipeBendingExpense: ItemPipeBendingExpense = new ItemPipeBendingExpense();
      rafter_pipeBendingExpense.setAssociation(design, struct, work, level, position, this);
      rafter_pipeBendingExpense.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래",
        `원형, ${specsArray[2]}`,
      );
      rafter_pipeBendingExpense.selected = false;
      rafter_pipeBendingExpense.visible = false;
      this.itemAC.splice(2, 0, rafter_pipeBendingExpense);
      // [서까래 확장(양끝)] 파이프 벤딩비
      const rafterEdgePinned_pipeBendingExpense: ItemPipeBendingExpense = new ItemPipeBendingExpense();
      rafterEdgePinned_pipeBendingExpense.setAssociation(design, struct, work, level, position, this);
      rafterEdgePinned_pipeBendingExpense.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
        CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
        "서까래 확장(양끝)",
        `원형, ${specsArray[2]}`,
      );
      rafterEdgePinned_pipeBendingExpense.selected = false;
      rafterEdgePinned_pipeBendingExpense.visible = false;
      this.itemAC.splice(4, 0, rafterEdgePinned_pipeBendingExpense);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_rafterTotalLength();
    this.algorithm_rafterQuantity();
    this.algorithm_edgeTotalRafterLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;

    /// //////// 선택, 가시성 ///////////

    this.rafter_pipe.selected = true;
    this.rafter_pipe.visible = true;
    if (this.rafter_pipe.specBending === CONST.ITEM_SPEC_VALUE_BENDING) {
      this.rafter_pipeBendingExpense.selected = this.design.basic.bendingExpenseFlag;
      this.rafter_pipeBendingExpense.visible = true;
    } else {
      this.rafter_pipeBendingExpense.selected = false;
      this.rafter_pipeBendingExpense.visible = false;
    }
    if (this.level.index === 0) {
      if (this.edgeRafterExtensionSelected === true) {
        this.rafterEdge_pipe.selected = true;
        this.rafterEdge_pipe.visible = true;
        if (this.edgeRafterImprintSelected === true) {
          this.rafterEdgePinned_pipe.selected = true;
          this.rafterEdgePinned_pipe.visible = true;
          if (this.rafterEdgePinned_pipe.specBending === CONST.ITEM_SPEC_VALUE_BENDING) {
            this.rafterEdgePinned_pipeBendingExpense.selected = this.design.basic.bendingExpenseFlag;
            this.rafterEdgePinned_pipeBendingExpense.visible = true;
          } else {
            this.rafterEdgePinned_pipeBendingExpense.selected = false;
            this.rafterEdgePinned_pipeBendingExpense.visible = false;
          }
        } else {
          this.rafterEdgePinned_pipe.selected = false;
          this.rafterEdgePinned_pipe.visible = false;
          this.rafterEdgePinned_pipeBendingExpense.selected = false;
          this.rafterEdgePinned_pipeBendingExpense.visible = false;
        }
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
          if (columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) {
            this.rafterAndBeamEdgeCylinder_steelStringGrabber.selected = true;
            this.rafterAndBeamEdgeCylinder_steelStringGrabber.visible = true;
            this.rafterAndBeamEdgeCylinder_uClamp.selected = false;
            this.rafterAndBeamEdgeCylinder_uClamp.visible = true;
          } else if (columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM) {
            this.rafterAndBeamEdgeCylinder_steelStringGrabber.selected = false;
            this.rafterAndBeamEdgeCylinder_steelStringGrabber.visible = true;
            this.rafterAndBeamEdgeCylinder_uClamp.selected = true;
            this.rafterAndBeamEdgeCylinder_uClamp.visible = true;
          }
          this.rafterAndBeamEdgePrism_outerWallClamp.selected = false;
          this.rafterAndBeamEdgePrism_outerWallClamp.visible = false;
        } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.rafterAndBeamEdgeCylinder_steelStringGrabber.selected = false;
          this.rafterAndBeamEdgeCylinder_steelStringGrabber.visible = false;
          this.rafterAndBeamEdgeCylinder_uClamp.selected = false;
          this.rafterAndBeamEdgeCylinder_uClamp.visible = false;
          this.rafterAndBeamEdgePrism_outerWallClamp.selected = true;
          this.rafterAndBeamEdgePrism_outerWallClamp.visible = true;
        }
      } else {
        this.rafterEdge_pipe.selected = false;
        this.rafterEdge_pipe.visible = false;
        this.rafterEdgePinned_pipe.selected = false;
        this.rafterEdgePinned_pipe.visible = false;
        this.rafterEdgePinned_pipeBendingExpense.selected = false;
        this.rafterEdgePinned_pipeBendingExpense.visible = false;
        this.rafterAndBeamEdgeCylinder_steelStringGrabber.selected = false;
        this.rafterAndBeamEdgeCylinder_steelStringGrabber.visible = false;
        this.rafterAndBeamEdgeCylinder_uClamp.selected = false;
        this.rafterAndBeamEdgeCylinder_uClamp.visible = false;
        this.rafterAndBeamEdgePrism_outerWallClamp.selected = false;
        this.rafterAndBeamEdgePrism_outerWallClamp.visible = false;
      }
      this.rafterAndBeamCenter_firstLevelPairSconce.selected = true;
      this.rafterAndBeamCenter_firstLevelPairSconce.visible = true;
      this.rafterAndColumnCenterCylinder_secondLevelPairSconce.selected = false;
      this.rafterAndColumnCenterCylinder_secondLevelPairSconce.visible = false;
      this.rafterAndColumnCenterPrism_secondLevelSoleSconce.selected = false;
      this.rafterAndColumnCenterPrism_secondLevelSoleSconce.visible = false;
      if ((<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition.selected === false) {
        if (this.edgeRafterExtensionSelected === true) {
          this.rafterAndBeamEdge_firstLevelSoleSconce.selected = false;
          this.rafterAndBeamEdge_firstLevelSoleSconce.visible = false;
        } else {
          this.rafterAndBeamEdge_firstLevelSoleSconce.selected = true;
          this.rafterAndBeamEdge_firstLevelSoleSconce.visible = true;
        }
      } else {
        this.rafterAndBeamEdge_firstLevelSoleSconce.selected = false;
        this.rafterAndBeamEdge_firstLevelSoleSconce.visible = false;
      }
      this.rafterAndColumnEdge_secondLevelSoleSconce.selected = false;
      this.rafterAndColumnEdge_secondLevelSoleSconce.visible = false;
    } else {
      this.rafterEdge_pipe.selected = false;
      this.rafterEdge_pipe.visible = false;
      this.rafterEdgePinned_pipe.selected = false;
      this.rafterEdgePinned_pipe.visible = false;
      this.rafterEdgePinned_pipeBendingExpense.selected = false;
      this.rafterEdgePinned_pipeBendingExpense.visible = false;
      this.rafterAndBeamCenter_firstLevelPairSconce.selected = false;
      this.rafterAndBeamCenter_firstLevelPairSconce.visible = false;
      if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.rafterAndColumnCenterCylinder_secondLevelPairSconce.selected = true;
        this.rafterAndColumnCenterCylinder_secondLevelPairSconce.visible = true;
        this.rafterAndColumnCenterPrism_secondLevelSoleSconce.selected = false;
        this.rafterAndColumnCenterPrism_secondLevelSoleSconce.visible = false;
      } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.rafterAndColumnCenterCylinder_secondLevelPairSconce.selected = false;
        this.rafterAndColumnCenterCylinder_secondLevelPairSconce.visible = false;
        this.rafterAndColumnCenterPrism_secondLevelSoleSconce.selected = true;
        this.rafterAndColumnCenterPrism_secondLevelSoleSconce.visible = true;
      }
      this.rafterAndBeamEdge_firstLevelSoleSconce.selected = false;
      this.rafterAndBeamEdge_firstLevelSoleSconce.visible = false;
      if ((<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition.selected === false) {
        this.rafterAndColumnEdge_secondLevelSoleSconce.selected = true;
        this.rafterAndColumnEdge_secondLevelSoleSconce.visible = true;
      } else {
        this.rafterAndColumnEdge_secondLevelSoleSconce.selected = false;
        this.rafterAndColumnEdge_secondLevelSoleSconce.visible = false;
      }
      this.rafterAndBeamEdgeCylinder_steelStringGrabber.selected = false;
      this.rafterAndBeamEdgeCylinder_steelStringGrabber.visible = false;
      this.rafterAndBeamEdgeCylinder_uClamp.selected = false;
      this.rafterAndBeamEdgeCylinder_uClamp.visible = false;
      this.rafterAndBeamEdgePrism_outerWallClamp.selected = false;
      this.rafterAndBeamEdgePrism_outerWallClamp.visible = false;
    }
    this.rafter_screw.selected = true;
    this.rafter_screw.visible = true;

    /// //////// 용도 ///////////

    if (this.trussSelected === true) {
      this.rafter_pipe.purpose = `서까래 (트러스 ${this.trussInterval}m간격)`;
    } else {
      this.rafter_pipe.purpose = "서까래";
    }

    /// //////// 수치 ///////////

    // 한동의 서까래 수량
    const rafterQuantityPerBuilding: number = CommonUtil.fixFloat(this.rafterQuantity / this.basic.buildingNumber);
    // 양끝동수
    let edgeBuildingNumber: number = 2;
    if (this.basic.buildingNumber === 1) {
      edgeBuildingNumber = 1;
    }
    // 중앙동수
    const centerBuildingNumber: number = this.basic.buildingNumber - edgeBuildingNumber;

    /// //////// 수량 ///////////

    if (this.level.index === 0) {
      if (this.edgeRafterExtensionSelected === true) {
        let rafter_pipe_designQuantity: number = 0;
        rafter_pipe_designQuantity = this.rafterQuantity - rafterQuantityPerBuilding * edgeBuildingNumber;
        if (this.trussSelected === true) {
          rafter_pipe_designQuantity +=
            Math.round(this.basicLevel.length / this.trussInterval + 1) *
            (this.basic.buildingNumber - edgeBuildingNumber) *
            this.trussMultiple;
        }
        this.rafter_pipe.designQuantity = rafter_pipe_designQuantity;

        let rafterEdge_pipe_designQuantity: number = 0;
        rafterEdge_pipe_designQuantity = rafterQuantityPerBuilding * edgeBuildingNumber;
        if (this.trussSelected === true) {
          rafterEdge_pipe_designQuantity +=
            Math.round(this.basicLevel.length / this.trussInterval + 1) * edgeBuildingNumber * this.trussMultiple;
        }
        this.rafterEdge_pipe.designQuantity = rafterEdge_pipe_designQuantity;
      } else {
        let rafter_pipe_designQuantity: number = 0;
        rafter_pipe_designQuantity = this.rafterQuantity;
        if (this.trussSelected === true) {
          rafter_pipe_designQuantity +=
            Math.round(this.basicLevel.length / this.trussInterval + 1) *
            this.basic.buildingNumber *
            this.trussMultiple;
        }
        this.rafter_pipe.designQuantity = rafter_pipe_designQuantity;

        this.rafterEdge_pipe.designQuantity = 0;
      }

      this.rafter_pipeBendingExpense.designQuantity = this.rafterQuantity;

      this.rafterEdgePinned_pipe.designQuantity = rafterQuantityPerBuilding * 2;

      this.rafterEdgePinned_pipeBendingExpense.designQuantity = rafterQuantityPerBuilding * 2;

      this.rafterAndBeamCenter_firstLevelPairSconce.designQuantity =
        rafterQuantityPerBuilding * (this.basic.buildingNumber - 1) * CONST.NUM_EXTRA_RATE_FIRST_LEVEL_PAIR_SCONCE;

      this.rafterAndBeamEdge_firstLevelSoleSconce.designQuantity =
        rafterQuantityPerBuilding * 2 * CONST.NUM_EXTRA_RATE_FIRST_LEVEL_SOLE_SCONCE;

      this.rafterAndBeamEdgeCylinder_steelStringGrabber.designQuantity =
        rafterQuantityPerBuilding * 2 * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

      this.rafterAndBeamEdgeCylinder_uClamp.designQuantity =
        rafterQuantityPerBuilding * 2 * CONST.NUM_EXTRA_RATE_U_CLAMP;

      this.rafterAndBeamEdgePrism_outerWallClamp.designQuantity =
        rafterQuantityPerBuilding * 2 * CONST.NUM_EXTRA_RATE_OUTER_WALL_CLAMP;
    } else {
      this.rafter_pipe.designQuantity = this.rafterQuantity;

      this.rafter_pipeBendingExpense.designQuantity = this.rafterQuantity;

      this.rafterAndColumnCenterCylinder_secondLevelPairSconce.designQuantity =
        rafterQuantityPerBuilding * (this.basic.buildingNumber - 1) * CONST.NUM_EXTRA_RATE_SECOND_LEVEL_PAIR_SCONCE;

      this.rafterAndColumnCenterPrism_secondLevelSoleSconce.designQuantity =
        rafterQuantityPerBuilding * (this.basic.buildingNumber - 1) * 2 * CONST.NUM_EXTRA_RATE_SECOND_LEVEL_SOLE_SCONCE;

      this.rafterAndColumnEdge_secondLevelSoleSconce.designQuantity =
        rafterQuantityPerBuilding * 2 * CONST.NUM_EXTRA_RATE_SECOND_LEVEL_SOLE_SCONCE;
    }

    this.rafter_screw.designQuantity =
      ((this.rafterAndBeamCenter_firstLevelPairSconce.getSelectedQuantity() * 2 +
        this.rafterAndBeamEdge_firstLevelSoleSconce.getSelectedQuantity() * 2 +
        this.rafterAndColumnCenterCylinder_secondLevelPairSconce.getSelectedQuantity() * 3 +
        this.rafterAndColumnCenterPrism_secondLevelSoleSconce.getSelectedQuantity() * 3 +
        this.rafterAndColumnEdge_secondLevelSoleSconce.getSelectedQuantity() * 3) /
        this.rafter_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 서까래 간격 <- 형태(기둥 골조), 기둥 간격(기둥 골조), 달대 선택(기둥 골조)
   */
  public algorithm_rafterInterval(): void {
    if (this.level.index >= 1) {
      // 외부
      const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;

      if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.rafterInterval = columnPosition.cylinderColumnPart.columnInterval;
      } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        if (columnPosition.prismColumnPart.assistColumnSelected === true) {
          this.rafterInterval = columnPosition.prismColumnPart.columnInterval / 2;
        } else {
          this.rafterInterval = columnPosition.prismColumnPart.columnInterval;
        }
      }
    }
  }

  /**
   * 알고리즘: 서까래 수량 <- 서까래 간격, 길이(기본정보), 동수(기본정보), 앞면/뒷면 처마 서까래 수량(위치)
   */
  public algorithm_rafterQuantity(): void {
    if (this.level.index === 0) {
      this.rafterQuantity =
        (Math.round(this.basicLevel.length / this.rafterInterval) +
          1 +
          (<RoofPositionIL>this.position).frontEaveRafterQuantity +
          (<RoofPositionIL>this.position).backEaveRafterQuantity) *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_RAFTER_PIPE;
    } else if (this.level.index >= 1) {
      this.rafterQuantity =
        (Math.round(this.basicLevel.length / this.rafterInterval) + 1) *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_RAFTER_PIPE;
    }
  }

  /**
   * 알고리즘: 최종 서까래 길이 <- 길이(서까래 파이프 샘플)
   */
  public algorithm_rafterTotalLength(): void {
    this.rafterTotalLength = this.rafterPipeSample.specLength;
  }

  /**
   * 알고리즘: 서까래 길이 반올림 선택 <- 서까래 길이 반올림 선택(1중)
   */
  public algorithm_rafterLengthRoundSelectedByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        (<RoofLevelIL>this.work.levelAC[l]).roofPosition.rafterPart.rafterLengthRoundSelected =
          this.rafterLengthRoundSelected;
      }
    }
  }

  /**
   * 알고리즘: 양끝동 최종 서까래 길이 <- 길이(서까래 파이프 샘플), 양끝동 서까래 확장 선택, 양끝동 서까래 확장 길이
   */
  public algorithm_edgeTotalRafterLength(): void {
    if (this.edgeRafterExtensionSelected === true) {
      this.edgeRafterTotalLength = this.rafterPipeSample.specLength + this.edgeRafterExtensionLength;
    } else {
      this.edgeRafterTotalLength = this.rafterPipeSample.specLength;
    }
  }

  /**
   * 알고리즘: 양끝동 서까래 확장 길이 <- 방풍벽 서까래 길이(방풍벽 골조)
   */
  public algorithm_edgeRafterExtensionLength(): void {
    this.edgeRafterExtensionLength = (<WingLevelIL>(
      this.struct.wingWorkIL.levelAC[this.level.index]
    )).wingPosition.wingRafterPart.wingRafterLength;
  }

  /**
   * 알고리즘: 양끝동 서까래 인발 길이 <- 양끝동 서까래 확장 길이
   */
  public algorithm_edgeRafterImprintLength(): void {
    this.edgeRafterImprintLength = this.edgeRafterExtensionLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [서까래] 파이프 <- 서까래 파이프 샘플
   */
  public algorithmSpec_rafter_pipe(): void {
    this.rafter_pipe.specs = this.rafterPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [서까래(양끝)] 파이프 <- 서까래 파이프 샘플, 양끝동 최종 서까래 길이, 양끝동 서까래 인발 선택, 양끝동 서까래 인발 길이
   */
  public algorithmSpec_rafterEdge_pipe(): void {
    this.rafterEdge_pipe.specsList = this.rafterPipeSample.specsList;
    if (this.edgeRafterImprintSelected === true) {
      this.rafterEdge_pipe.specLength = this.edgeRafterTotalLength - this.edgeRafterImprintLength;
    } else {
      this.rafterEdge_pipe.specLength = this.edgeRafterTotalLength;
    }
  }

  /**
   * 규격 알고리즘: [서까래] 파이프 벤딩비 <- 서까래 파이프 샘플
   */
  public algorithmSpec_rafter_pipeBendingExpense(): void {
    this.rafter_pipeBendingExpense.specPipeType = this.rafterPipeSample.specPipeType;
    this.rafter_pipeBendingExpense.specPipeCrossSize = this.rafterPipeSample.specPipeCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 확장(양끝)] 파이프 <- 서까래 파이프 샘플, 서까래 인발 길이
   */
  public algorithmSpec_rafterEdgePinned_pipe(): void {
    this.rafterEdgePinned_pipe.specPipeType = this.rafterPipeSample.specPipeType;
    // 하드코딩
    this.rafterEdgePinned_pipe.specMaterial = CONST.ITEM_SPEC_VALUE_PINNED;
    this.rafterEdgePinned_pipe.specPipeCrossSize = this.rafterPipeSample.specPipeCrossSize;
    this.rafterEdgePinned_pipe.specThickness = this.rafterPipeSample.specThickness;
    this.rafterEdgePinned_pipe.specLength = this.edgeRafterImprintLength;
  }

  /**
   * 규격 알고리즘: [서까래 확장(양끝)] 파이프 벤딩비 <- 서까래 파이프 샘플
   */
  public algorithmSpec_rafterEdgePinned_pipeBendingExpense(): void {
    this.rafterEdgePinned_pipeBendingExpense.specPipeType = this.rafterPipeSample.specPipeType;
    this.rafterEdgePinned_pipeBendingExpense.specPipeCrossSize = this.rafterPipeSample.specPipeCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 + 기둥(중앙)] 일중 쌍꽂이 <- 서까래 파이프 샘플, 형태(기둥 골조), 보 파이프 샘플
   */
  public algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      this.rafterAndBeamCenter_firstLevelPairSconce.specPipeType =
        columnPosition.cylinderBeamPart.beamPipeSample.specPipeType;
      if (columnPosition.cylinderBeamPart.beamPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) {
        this.rafterAndBeamCenter_firstLevelPairSconce.specMaterial = CONST.ITEM_SPEC_VALUE_HOJU_TYPE;
      } else {
        this.rafterAndBeamCenter_firstLevelPairSconce.specMaterial = CONST.ITEM_SPEC_VALUE_CLAMP_TYPE;
      }
      this.rafterAndBeamCenter_firstLevelPairSconce.specCrossSize1 =
        columnPosition.cylinderBeamPart.beamPipeSample.specCrossSize;
      this.rafterAndBeamCenter_firstLevelPairSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
    } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
      this.rafterAndBeamCenter_firstLevelPairSconce.specPipeType =
        columnPosition.prismBeamPart.beamPipeSample.specPipeType;
      this.rafterAndBeamCenter_firstLevelPairSconce.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
      this.rafterAndBeamCenter_firstLevelPairSconce.specCrossSize1 =
        columnPosition.prismBeamPart.beamPipeSample.specCrossSize;
      this.rafterAndBeamCenter_firstLevelPairSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [서까래 + 기둥(중앙)] 이중 쌍꽂이 <- 서까래 파이프 샘플, 기둥 파이프 샘플(원형)
   */
  public algorithmSpec_rafterAndColumnCenterCylinder_secondLevelPairSconce(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    this.rafterAndColumnCenterCylinder_secondLevelPairSconce.specPipeType =
      columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
    this.rafterAndColumnCenterCylinder_secondLevelPairSconce.specCrossSize1 =
      columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
    this.rafterAndColumnCenterCylinder_secondLevelPairSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 + 기둥(중앙)] 이중 외꽂이 <- 서까래 파이프 샘플, 기둥 파이프 샘플(각형)
   */
  public algorithmSpec_rafterAndColumnCenterPrism_secondLevelSoleSconce(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    this.rafterAndColumnCenterPrism_secondLevelSoleSconce.specPipeType =
      columnPosition.prismColumnPart.columnPipeSample.specPipeType;
    this.rafterAndColumnCenterPrism_secondLevelSoleSconce.specCrossSize1 =
      columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
    this.rafterAndColumnCenterPrism_secondLevelSoleSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 + 기둥(양끝)] 일중 외꽂이 <- 서까래 파이프 샘플, 형태(기둥 골조), 보 파이프 샘플
   */
  public algorithmSpec_rafterAndBeamEdge_firstLevelSoleSconce(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      this.rafterAndBeamEdge_firstLevelSoleSconce.specPipeType =
        columnPosition.cylinderBeamPart.beamPipeSample.specPipeType;
      if (columnPosition.cylinderBeamPart.beamPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) {
        this.rafterAndBeamEdge_firstLevelSoleSconce.specMaterial = CONST.ITEM_SPEC_VALUE_HOJU_TYPE;
      } else {
        this.rafterAndBeamEdge_firstLevelSoleSconce.specMaterial = CONST.ITEM_SPEC_VALUE_CLAMP_TYPE;
      }
      this.rafterAndBeamEdge_firstLevelSoleSconce.specCrossSize1 =
        columnPosition.cylinderBeamPart.beamPipeSample.specCrossSize;
      this.rafterAndBeamEdge_firstLevelSoleSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
    } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
      this.rafterAndBeamEdge_firstLevelSoleSconce.specPipeType =
        columnPosition.prismBeamPart.beamPipeSample.specPipeType;
      this.rafterAndBeamEdge_firstLevelSoleSconce.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
      this.rafterAndBeamEdge_firstLevelSoleSconce.specCrossSize1 =
        columnPosition.prismBeamPart.beamPipeSample.specCrossSize;
      this.rafterAndBeamEdge_firstLevelSoleSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [서까래 + 기둥(양끝)] 이중 외꽂이 <- 서까래 파이프 샘플, 형태(기둥 골조), 기둥 파이프 샘플
   */
  public algorithmSpec_rafterAndColumnEdge_secondLevelSoleSconce(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      this.rafterAndColumnEdge_secondLevelSoleSconce.specPipeType =
        columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
      this.rafterAndColumnEdge_secondLevelSoleSconce.specCrossSize1 =
        columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      this.rafterAndColumnEdge_secondLevelSoleSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
    } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
      this.rafterAndColumnEdge_secondLevelSoleSconce.specPipeType =
        columnPosition.prismColumnPart.columnPipeSample.specPipeType;
      this.rafterAndColumnEdge_secondLevelSoleSconce.specCrossSize1 =
        columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
      this.rafterAndColumnEdge_secondLevelSoleSconce.specCrossSize2 = this.rafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [서까래 확장 + 보(양끝)] 강선 조리개 <- 보 파이프 샘플(원형), 서까래 파이프 샘플
   */
  public algorithmSpec_rafterAndBeamEdgeCylinder_steelStringGrabber(): void {
    this.rafterAndBeamEdgeCylinder_steelStringGrabber.specPipeType =
      this.struct.columnWorkIL.level1.columnPosition.cylinderBeamPart.beamPipeSample.specPipeType;
    this.rafterAndBeamEdgeCylinder_steelStringGrabber.specCrossSize1 =
      this.struct.columnWorkIL.level1.columnPosition.cylinderBeamPart.beamPipeSample.specCrossSize;
    this.rafterAndBeamEdgeCylinder_steelStringGrabber.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 확장 + 보(양끝)] U 클램프 <- 보 파이프 샘플(원형), 서까래 파이프 샘플
   */
  public algorithmSpec_rafterAndBeamEdgeCylinder_uClamp(): void {
    this.rafterAndBeamEdgeCylinder_uClamp.specPipeType =
      this.struct.columnWorkIL.level1.columnPosition.cylinderBeamPart.beamPipeSample.specPipeType;
    this.rafterAndBeamEdgeCylinder_uClamp.specCrossSize1 =
      this.struct.columnWorkIL.level1.columnPosition.cylinderBeamPart.beamPipeSample.specCrossSize;
    this.rafterAndBeamEdgeCylinder_uClamp.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래 확장 + 보(양끝)] 외벽 클램프 <- 보 파이프 샘플(각형), 서까래 파이프 샘플
   */
  public algorithmSpec_rafterAndBeamEdgePrism_outerWallClamp(): void {
    this.rafterAndBeamEdgePrism_outerWallClamp.specPipeType =
      this.struct.columnWorkIL.level1.columnPosition.prismBeamPart.beamPipeSample.specPipeType;
    this.rafterAndBeamEdgePrism_outerWallClamp.specCrossSize1 =
      this.struct.columnWorkIL.level1.columnPosition.prismBeamPart.beamPipeSample.specCrossSize;
    this.rafterAndBeamEdgePrism_outerWallClamp.specCrossSize2 = this.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [서까래] 나사 <- 공통 샘플
   */
  public algorithmSpec_rafter_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
