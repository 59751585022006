import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { TemHeatingPart_HeatingTube } from "vhows-design/src/object/design/temperature/heating/TemHeatingPart_HeatingTube";
import { TemHeatingPart_HeatingFan } from "vhows-design/src/object/design/temperature/heating/TemHeatingPart_HeatingFan";
import { TemHeatingPart_Boiler } from "vhows-design/src/object/design/temperature/heating/TemHeatingPart_Boiler";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2020-02-21
 */
@jsonObject({
  knownTypes: [TemHeatingPart_Boiler, TemHeatingPart_HeatingFan, TemHeatingPart_HeatingTube],
})
export class TemHeatingPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public biolerPart: TemHeatingPart_Boiler;
  public heatingFanPart: TemHeatingPart_HeatingFan;
  public heatingTubePart: TemHeatingPart_HeatingTube;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _heatingCapacity: number = null; // 난방 용량
  @jsonMember(Number)
  public _heatingPower: number = null; // 난방 전력
  @jsonMember(Number)
  public _houseSurface: number = null; // 온실 표면적
  @jsonMember(String)
  public _keepingType: string = null; // 보온 형태
  @jsonMember(Number)
  public _heatingLoad: number = null; // 난방부하계수
  @jsonMember(Number)
  public _heatReduce: number = null; // 열절감율
  @jsonMember(Number)
  public _settingTemperature: number = null; // 설정온도
  @jsonMember(Number)
  public _outsideTemperature: number = null; // 외부기온

  /**
   * 난방 용량
   */
  public get heatingCapacity(): number {
    return this._heatingCapacity;
  }

  //
  public set heatingCapacity(value: number) {
    this._heatingCapacity = CommonUtil.fixFloat(value);

    // 알고리즘
  }

  /**
   * 난방 전력
   */
  public get heatingPower(): number {
    return this._heatingPower;
  }

  //
  public set heatingPower(value: number) {
    this._heatingPower = CommonUtil.fixFloat(value);

    // 알고리즘
  }

  /**
   * 온실 표면적
   */
  public get houseSurface(): number {
    return this._houseSurface;
  }

  //
  public set houseSurface(value: number) {
    this._houseSurface = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_heatingCapacityAndPower();
  }

  /**
   * 보온 형태
   */
  public get keepingType(): string {
    return this._keepingType;
  }

  //
  public set keepingType(value: string) {
    this._keepingType = value;

    // 알고리즘
    this.algorithm_heatingLoadAndHeatReduce();
    this.algorithm_heatingCapacityAndPower();
  }

  /**
   * 난방부하계수
   */
  public get heatingLoad(): number {
    return this._heatingLoad;
  }

  //
  public set heatingLoad(value: number) {
    this._heatingLoad = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_heatingCapacityAndPower();
  }

  /**
   * 열절감율
   */
  public get heatReduce(): number {
    return this._heatReduce;
  }

  //
  public set heatReduce(value: number) {
    this._heatReduce = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_heatingCapacityAndPower();
  }

  /**
   * 설정온도
   */
  public get settingTemperature(): number {
    return this._settingTemperature;
  }

  //
  public set settingTemperature(value: number) {
    this._settingTemperature = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_heatingCapacityAndPower();
  }

  /**
   * 외부기온
   */
  public get outsideTemperature(): number {
    return this._outsideTemperature;
  }

  //
  public set outsideTemperature(value: number) {
    this._outsideTemperature = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_heatingCapacityAndPower();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.biolerPart = new TemHeatingPart_Boiler();
    this.heatingFanPart = new TemHeatingPart_HeatingFan();
    this.heatingTubePart = new TemHeatingPart_HeatingTube();

    this.partAC = [this.biolerPart, this.heatingFanPart, this.heatingTubePart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.biolerPart = <TemHeatingPart_Boiler>this.partAC[0];
    this.heatingFanPart = <TemHeatingPart_HeatingFan>this.partAC[1];
    this.heatingTubePart = <TemHeatingPart_HeatingTube>this.partAC[2];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param keepingType: string 보온 형태
   * @param settingTemperature: number 설정온도
   * @param outsideTemperature: number 외부기온
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    keepingType: string = "",
    settingTemperature: number = 0,
    outsideTemperature: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._keepingType = keepingType;
    this._settingTemperature = settingTemperature;
    this._outsideTemperature = outsideTemperature;

    if (this.level.index >= 0) {
      // 보일러
      this.biolerPart.setDefaultData(
        CONST.INDEX_CM_TEM_HEATING_BOILER,
        false,
        false,
        false,
        CONST.LB_TEM_HEATING_BOILER,
        "",
      );

      // 열풍기
      this.heatingFanPart.setDefaultData(
        CONST.INDEX_CM_TEM_HEATING_FAN,
        true,
        false,
        true,
        CONST.LB_TEM_HEATING_FAN,
        "",
      );

      // 히팅튜브
      this.heatingTubePart.setDefaultData(
        CONST.INDEX_CM_TEM_HEATING_TUBE,
        true,
        false,
        true,
        CONST.LB_TEM_HEATING_TUBE,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_houseSurface();
    this.algorithm_heatingLoadAndHeatReduce();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태, 지붕 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    this.biolerPart.selected = false;
    this.biolerPart.visible = false;
    this.heatingFanPart.selected = true;
    this.heatingFanPart.visible = true;
    this.heatingTubePart.selected = true;
    this.heatingTubePart.visible = true;
    // if (this.type === CONST.LB_VENT_EXHAUST_TYPE_FAN) {
    //   this.biolerPart.selected = true;
    //   this.biolerPart.visible = true;
    // } else {
    //   this.biolerPart.selected = false;
    //   this.biolerPart.visible = false;
    // }
  }

  /**
   * 알고리즘: 온실 표면적 <- 구조(기본정보), 면적(기본정보)
   */
  public algorithm_houseSurface(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.houseSurface = Math.round(this.basic.area * 1.7);
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.houseSurface = Math.round(this.basic.area * 1.3);
    }
  }

  /**
   * 알고리즘: 난방부하계수, 열절감율 <- 보온 형태
   */
  public algorithm_heatingLoadAndHeatReduce(): void {
    if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_1L_THIN) {
      this.heatingLoad = 5.7;
      this.heatReduce = 15;
    } else if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_2L_THIN) {
      this.heatingLoad = 4.9;
      this.heatReduce = 25;
    } else if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_3L_THIN) {
      this.heatingLoad = 4.5;
      this.heatReduce = 30;
    } else if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_2L_THICK) {
      this.heatingLoad = 3.3;
      this.heatReduce = 45;
    } else if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_3L_THICK) {
      this.heatingLoad = 3;
      this.heatReduce = 55;
    } else if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_1L_THIN_1L_CURTAIN) {
      this.heatingLoad = 3.1;
      this.heatReduce = 45;
    } else if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_1L_THIN_2L_CURTAIN) {
      this.heatingLoad = 2.8;
      this.heatReduce = 55;
    } else if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_1L_THIN_3L_CURTAIN) {
      this.heatingLoad = 2;
      this.heatReduce = 65;
    } else if (this.keepingType === CONST.LB_TEM_HEATING_KEEPING_TYPE_WATER_SCREEN) {
      this.heatingLoad = 3;
      this.heatReduce = 70;
    }
  }

  /**
   * 알고리즘: 난방 용량, 난방 전력 <- 온실 표면적, 난방부하계수, 열절감율, 설정온도, 외부기온
   */
  public algorithm_heatingCapacityAndPower(): void {
    this.heatingCapacity = Math.round(
      this.houseSurface *
        this.heatingLoad *
        (this.settingTemperature - this.outsideTemperature) *
        (1 - this.heatReduce * 0.01),
    );
    this.heatingPower = Math.round(this.heatingCapacity / 860);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
