import { jsonObject } from "typedjson";

import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { GutterPartIL_FixingGutterPad } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_FixingGutterPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-28
 */
@jsonObject
export class GutterSampleIL_GutterPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingGutterPadPart: GutterPartIL_FixingGutterPad = <GutterPartIL_FixingGutterPad>this.part;
    fixingGutterPadPart.algorithmSpec_gutterPad_gutterPad();
    fixingGutterPadPart.algorithmSpec_gutterPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }
}
