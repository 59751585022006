import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { RoofPartIL_Straight } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Straight";
import { RoofPartIL_RoofBracing } from "vhows-design/src/object/design/frame/roof/RoofPartIL_RoofBracing";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { RoofPartIL_Rafter } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Rafter";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject
export class RoofSampleIL_RafterPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // 길이
  // @override
  public get specLength(): number {
    return this._specLength;
  }

  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 0.1m 간격(소수점 둘째자리 올림)
    this._specLength = CommonUtil.roundUp(value, 1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      (<RoofPartIL_Rafter>this.part).algorithm_rafterTotalLength();
      (<RoofPartIL_Rafter>this.part).algorithm_edgeTotalRafterLength();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const rafterPart: RoofPartIL_Rafter = <RoofPartIL_Rafter>this.part;
    rafterPart.algorithmSpec_rafter_pipe();
    rafterPart.algorithmSpec_rafterEdge_pipe();
    rafterPart.algorithmSpec_rafter_pipeBendingExpense();
    rafterPart.algorithmSpec_rafterEdgePinned_pipe();
    rafterPart.algorithmSpec_rafterEdgePinned_pipeBendingExpense();
    rafterPart.algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce();
    rafterPart.algorithmSpec_rafterAndColumnCenterCylinder_secondLevelPairSconce();
    rafterPart.algorithmSpec_rafterAndColumnCenterPrism_secondLevelSoleSconce();
    rafterPart.algorithmSpec_rafterAndBeamEdge_firstLevelSoleSconce();
    rafterPart.algorithmSpec_rafterAndColumnEdge_secondLevelSoleSconce();
    rafterPart.algorithmSpec_rafterAndBeamEdgeCylinder_steelStringGrabber();
    rafterPart.algorithmSpec_rafterAndBeamEdgeCylinder_uClamp();
    rafterPart.algorithmSpec_rafterAndBeamEdgePrism_outerWallClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const roofStraightPart: RoofPartIL_Straight = (<RoofPositionIL>this.position).straightPart;
    roofStraightPart.algorithmSpec_straightAndRafter_steelStringGrabber();
    roofStraightPart.algorithmSpec_straightAndRafter_steelSheetGrabber();
    roofStraightPart.algorithmSpec_straightAndRafter_crossSteelSheetGrabber();
    roofStraightPart.algorithmSpec_straightAndRafter_lineSheetGrabber();

    const roofBracingPart: RoofPartIL_RoofBracing = (<RoofPositionIL>this.position).roofBracingPart;
    roofBracingPart.algorithmSpec_roofBracingAndRafter_steelStringGrabber();

    /// //////// 외부 ///////////

    // 방풍벽
    if (this.level.index === 0 || this.level.index === 1) {
      const wingPosition: WingPositionIL = (<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition;
      wingPosition.wingRafterPart.wingRafterPipeSample.specsList = this.specsList;
      wingPosition.wingRafterPart.algorithmSpec_wingRafterAndRoofRafter_connectionPin();
    }

    // 마구리
    for (const endpiecePosition of this.struct.endpieceWorkIL.levelAC[this.level.index]
      .positionAC as EndpiecePositionIL[]) {
      endpiecePosition.cylinderStudPart.studPipeSample.algorithmSpec();
      endpiecePosition.cylinderStudPart.algorithmSpec_studAndRafterCenter_tHolder();
      endpiecePosition.cylinderStudPart.algorithmSpec_studAndRafterCenterRafter_normalHolder();
      endpiecePosition.cylinderStudPart.algorithmSpec_studAndRafterDiagonal_diagonalTHolder();
      endpiecePosition.cylinderStudPart.algorithmSpec_studAndRafterDiagonal_diagonalTClamp();
      endpiecePosition.cylinderStudPart.algorithmSpec_studAndRafterDiagonalRafter_normalHolder();
      endpiecePosition.cylinderStudPart.algorithmPart();
      endpiecePosition.cylinderStudSepPart.studUpperPipeSample.algorithmSpec();
      endpiecePosition.cylinderStudSepPart.algorithmSpec_studUpperAndRafterCenter_tHolder();
      endpiecePosition.cylinderStudSepPart.algorithmSpec_studUpperAndRafterDiagonal_diagonalTHolder();
      endpiecePosition.cylinderStudSepPart.algorithmSpec_studUpperAndRafterRafter_normalHolder();
      endpiecePosition.cylinderStudSepPart.algorithmPart();
      endpiecePosition.cylinderMiddlePart.middleUpperPipeSample.algorithmSpec();
      endpiecePosition.cylinderMiddlePart.algorithmSpec_middleUpperAndRafter_diagonalTHolder();
      endpiecePosition.cylinderMiddlePart.algorithmSpec_middleUpperAndRafterRafter_normalHolder();
      endpiecePosition.cylinderMiddlePart.middleBottomPipeSample.algorithmSpec();
      endpiecePosition.cylinderMiddlePart.algorithmPart();
      endpiecePosition.prismStudSepPart.studUpperPipeSample.algorithmSpec();
      endpiecePosition.prismStudSepPart.algorithmSpec_studUpperAndRafterRafter_normalHolder();
      endpiecePosition.prismStudSepPart.algorithmSpec_studUpperAndRafterDiagonal_diagonalTClamp();
      endpiecePosition.prismStudSepPart.algorithmPart();
      endpiecePosition.prismMiddlePart.middleUpperPipeSample.algorithmSpec();
      endpiecePosition.prismMiddlePart.algorithmSpec_middleUpperAndRafterRafter_normalHolder();
    }

    // 물받이
    const gutterLevel: GutterLevelIL = <GutterLevelIL>this.struct.gutterWorkIL.levelAC[this.level.index];
    gutterLevel.gutterPosition.condensationGutterPart.algorithmSpec_condensationGutter_gutterHanger();

    // 밴드끈
    if (this.level.index === 0) {
      for (const bandstringPosition of this.struct.bandstringWorkIL.levelAC[this.level.index]
        .positionAC as BandstringPositionIL[]) {
        bandstringPosition.fixingHookPart.hookHolderSample.algorithmSpec();
      }
    }

    // 개폐기
    const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];
    switcherLevel.roofPosition.axisPart.algorithmSpec_axis_slopeClip();

    // 피복 고정
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];
    fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipRafter_normalClip();
    fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipRafter_normalClip();

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    skirtLevel.roofPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

    // 바람막이 피복
    const windbreakLevel: WindbreakLevelIL = <WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index];
    windbreakLevel.roofPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();

    // 천창 골조
    if (this.level.index === 0) {
      this.struct.skyFrameWorkIL.level1.skylightPosition.enhancedPart.algorithmSpec_enhanced_saddleHolder();
    }

    // 천창 개폐
    this.struct.skySwitchWorkIL.level1.skylightPosition.rackpinionPart.algorithmSpec_rackpinion_skylightMotor();
    this.struct.skySwitchWorkIL.level1.skylightPosition.rackpinionPart.algorithmSpec_rackpinionAaxisHolder_bearingSet();

    // 관수 지관부
    const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
    wateringPosition.miniklerScreenPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.miniklerFountainPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.miniklerFogPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.hoseScreenPart.algorithmSpec_hose_fountainHoseHangerScreen();
    wateringPosition.hoseSidePart.algorithmSpec_hose_fountainHoseHangerSide();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  /**
   * 규격 알고리즘: 단위길이 <- 서까래 길이 이상값(기본정보), 서까래 길이 반올림 선택
   */
  public algorithmSpec_specLength(): void {
    const rafterPart: RoofPartIL_Rafter = <RoofPartIL_Rafter>this.part;
    let length: number;

    length = this.basicLevel.lengthRafterIdeal;

    if (rafterPart.rafterLengthRoundSelected === true) {
      length = Math.round(length);
    }

    this.specLength = length;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
