import { useEffect, useState } from "react";

const useCheckApp = (initialIsApp = false) => {
  const [isApp, setIsApp] = useState(initialIsApp || false);

  useEffect(() => {
    //@ts-ignore
    const isRn = window.ReactNativeWebView;

    setIsApp(!!isRn);
  }, []);

  return { isApp };
};

export default useCheckApp;
