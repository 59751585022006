import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { ColumnSampleVL_BeamPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_BeamPipe";
import { ItemSconce } from "vhows-design/src/object/design/item/list/ItemSconce";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ColumnSampleVL_GutterBeamPipe } from "vhows-design/src/object/design/frame/column/vl/ColumnSampleVL_GutterBeamPipe";
import { ItemBracket } from "vhows-design/src/object/design/item/list/ItemBracket";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { RoofPartVL_Rafter } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Rafter";
import { WingPartVL_WingRafter } from "vhows-design/src/object/design/frame/wing/vl/WingPartVL_WingRafter";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-08
 */
@jsonObject({
  knownTypes: [ColumnSampleVL_BeamPipe, ColumnSampleVL_GutterBeamPipe],
})
export class ColumnPartVL_Beam extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public beamPipeSample: ColumnSampleVL_BeamPipe; // 보 파이프 샘플
  public gutterBeamSample: ColumnSampleVL_GutterBeamPipe; // 물받이거터 보 샘플

  // 아이템
  public beam_pipe: ItemPipe;
  public beamAndBeam_connectionPin: ItemConnector;
  public beamAndBeam_screw: ItemScrew;
  // public beamAndColumn_tClamp: ItemHolder;
  // public beamAndEdgeColumn_tClamp: ItemHolder;
  // public beamAndSideColumn_tClamp: ItemHolder;
  // public beamAndSideStudColumn_tClamp: ItemHolder;
  // public beamAndFrontAndBackColumn_tClamp: ItemHolder;
  // public beamAndSmallColumn_tClamp: ItemHolder;
  // public beamAndColumn_screw: ItemScrew;
  public beam_gutterBeam: ItemPipe;
  public gutterBeamAndRoof_archSconce: ItemSconce;
  public gutterBeamAndWing_archSconce: ItemSconce;
  public gutterBeamAndColumn_bracket: ItemBracket;
  public gutterBeamAndBracket_gutterBeamHolder: ItemHolder;
  public beam_boltAndNut: ItemFrameOther;
  public beam_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _beamType: string; // 보 형태
  @jsonMember(Number)
  public _beamLength: number; // 보 길이
  @jsonMember(Number)
  public _beamLineNumber: number; // 보 줄수

  /**
   * 보 형태
   */
  public get beamType(): string {
    return this._beamType;
  }

  //
  public set beamType(value: string) {
    this._beamType = value;

    // 알고리즘
    this.algorithmPart();
    /// //////// 외부 ///////////

    // 지붕 골조
    this.struct.roofWorkVL.level1.roofPosition.rafterPart.algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce();
    this.struct.roofWorkVL.level1.roofPosition.rafterPart.algorithmSpec_rafterAndBeamEdge_firstLevelSoleSconce();
    this.struct.roofWorkVL.level1.roofPosition.rafterPart.algorithmPart();

    // 방풍벽
    (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition.wingRafterPart.algorithmPart();

    // 물받이
    this.struct.gutterWorkIL.level1.gutterPosition.algorithm_TypeByBeamType();
  }

  /**
   * 보 길이
   */
  public get beamLength(): number {
    return this._beamLength;
  }

  //
  public set beamLength(value: number) {
    this._beamLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 보 줄수
   */
  public get beamLineNumber(): number {
    return this._beamLineNumber;
  }

  //
  public set beamLineNumber(value: number) {
    this._beamLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.beamPipeSample = new ColumnSampleVL_BeamPipe();
    this.gutterBeamSample = new ColumnSampleVL_BeamPipe();

    this.sampleAC = [this.beamPipeSample, this.gutterBeamSample];

    // 아이템
    this.beam_pipe = new ItemPipe();
    this.beamAndBeam_connectionPin = new ItemConnector();
    this.beamAndBeam_screw = new ItemScrew();
    // this.beamAndColumn_tClamp = new ItemHolder();
    // this.beamAndEdgeColumn_tClamp = new ItemHolder();
    // this.beamAndSideColumn_tClamp = new ItemHolder();
    // this.beamAndSideStudColumn_tClamp = new ItemHolder();
    // this.beamAndFrontAndBackColumn_tClamp = new ItemHolder();
    // this.beamAndSmallColumn_tClamp = new ItemHolder();
    // this.beamAndColumn_screw = new ItemScrew();
    this.beam_gutterBeam = new ItemPipe();
    this.gutterBeamAndRoof_archSconce = new ItemSconce();
    this.gutterBeamAndWing_archSconce = new ItemSconce();
    this.gutterBeamAndColumn_bracket = new ItemBracket();
    this.gutterBeamAndBracket_gutterBeamHolder = new ItemHolder();
    this.beam_boltAndNut = new ItemFrameOther();
    this.beam_welding = new ItemOtherExpense();

    this.itemAC = [
      this.beam_pipe,
      this.beamAndBeam_connectionPin,
      this.beamAndBeam_screw,
      // this.beamAndColumn_tClamp,
      // this.beamAndEdgeColumn_tClamp,
      // this.beamAndSideColumn_tClamp,
      // this.beamAndSideStudColumn_tClamp,
      // this.beamAndFrontAndBackColumn_tClamp,
      // this.beamAndSmallColumn_tClamp,
      // this.beamAndColumn_screw,
      this.beam_gutterBeam,
      this.gutterBeamAndRoof_archSconce,
      this.gutterBeamAndWing_archSconce,
      this.gutterBeamAndColumn_bracket,
      this.gutterBeamAndBracket_gutterBeamHolder,
      this.beam_boltAndNut,
      this.beam_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.beamPipeSample = <ColumnSampleVL_BeamPipe>this.sampleAC[0];
    this.gutterBeamSample = <ColumnSampleVL_BeamPipe>this.sampleAC[1];

    // 아이템
    this.beam_pipe = <ItemPipe>this.itemAC[0];
    this.beamAndBeam_connectionPin = <ItemConnector>this.itemAC[1];
    this.beamAndBeam_screw = <ItemScrew>this.itemAC[2];
    // this.beamAndColumn_tClamp = <ItemHolder> this.itemAC[3];
    // this.beamAndEdgeColumn_tClamp = <ItemHolder> this.itemAC[4];
    // this.beamAndSideColumn_tClamp = <ItemHolder> this.itemAC[5];
    // this.beamAndSideStudColumn_tClamp = <ItemHolder> this.itemAC[6];
    // this.beamAndFrontAndBackColumn_tClamp = <ItemHolder> this.itemAC[7];
    // this.beamAndSmallColumn_tClamp = <ItemHolder> this.itemAC[8];
    // this.beamAndColumn_screw = <ItemScrew> this.itemAC[3];
    this.beam_gutterBeam = <ItemPipe>this.itemAC[3];
    this.gutterBeamAndRoof_archSconce = <ItemSconce>this.itemAC[4];
    this.gutterBeamAndWing_archSconce = <ItemSconce>this.itemAC[5];
    this.gutterBeamAndColumn_bracket = <ItemBracket>this.itemAC[6];
    this.gutterBeamAndBracket_gutterBeamHolder = <ItemHolder>this.itemAC[7];
    this.beam_boltAndNut = <ItemFrameOther>this.itemAC[8];
    this.beam_welding = <ItemOtherExpense>this.itemAC[9];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param beamType: string 보 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    beamType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._beamType = beamType;

    // 샘플
    if (this.level.index >= 0) {
      this.beamPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "보",
        "각형, 일반, 125×75mm, 2.1T, 5m, -",
      );
      this.gutterBeamSample.setDefaultData(
        1,
        CONST.ITEM_ID_GUTTER_BEAM,
        CONST.ITEM_NAME_GUTTER_BEAM,
        "보",
        "거터형, 알루미늄, 100x60mm, 2.0T, 8m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.beam_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "보",
        "각형, 일반, 125×75mm, 2.1T, 5m, -",
      );
      this.beamAndBeam_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "보 + 보",
        "각형, 일반, 125×75mm, 125×75mm",
      );
      this.beamAndBeam_screw.setDefaultData(
        2,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "보 + 보",
        "육각, 일반, 14*25mm, 500개",
      );
      // this.beamAndColumn_tClamp.setDefaultData(
      //   3,
      //   CONST.ITEM_ID_T_CLAMP,
      //   CONST.ITEM_NAME_T_CLAMP,
      //   "보 + 내부 기둥",
      //   "각형, 일반, 125×75mm, 125×75mm",
      // );
      // this.beamAndEdgeColumn_tClamp.setDefaultData(
      //   4,
      //   CONST.ITEM_ID_T_CLAMP,
      //   CONST.ITEM_NAME_T_CLAMP,
      //   "보 + 모서리 기둥",
      //   "각형, 일반, 125×125mm, 125×125mm",
      // );
      // this.beamAndSideColumn_tClamp.setDefaultData(
      //   5,
      //   CONST.ITEM_ID_T_CLAMP,
      //   CONST.ITEM_NAME_T_CLAMP,
      //   "보 + 측면 기둥",
      //   "각형, 일반, 125×75mm, 125×75mm",
      // );
      // this.beamAndSideStudColumn_tClamp.setDefaultData(
      //   6,
      //   CONST.ITEM_ID_T_CLAMP,
      //   CONST.ITEM_NAME_T_CLAMP,
      //   "보 + 측면 샛기둥",
      //   "각형, 일반, 125×75mm, 125×75mm",
      // );
      // this.beamAndFrontAndBackColumn_tClamp.setDefaultData(
      //   7,
      //   CONST.ITEM_ID_T_CLAMP,
      //   CONST.ITEM_NAME_T_CLAMP,
      //   "보 + 전후면 기둥",
      //   "각형, 일반, 125×125mm, 125×125mm",
      // );
      // this.beamAndSmallColumn_tClamp.setDefaultData(
      //   8,
      //   CONST.ITEM_ID_T_CLAMP,
      //   CONST.ITEM_NAME_T_CLAMP,
      //   "보 + 소기둥",
      //   "각형, 일반, 75×45mm, 75×45mm",
      // );
      // this.beamAndColumn_screw.setDefaultData(
      //   3,
      //   CONST.ITEM_ID_SCREW,
      //   CONST.ITEM_NAME_SCREW,
      //   "보 + 기둥",
      //   "십자, 일반, 8/9*13mm, 1000개",
      // );
      this.beam_gutterBeam.setDefaultData(
        3,
        CONST.ITEM_ID_GUTTER_BEAM,
        CONST.ITEM_NAME_GUTTER_BEAM,
        "보",
        "거터형, 알루미늄, 100x60mm, 2.0T, 8m, -",
      );
      this.gutterBeamAndRoof_archSconce.setDefaultData(
        4,
        CONST.ITEM_ID_ARCH_SCONCE,
        CONST.ITEM_NAME_ARCH_SCONCE,
        "보 + 지붕 서까래",
        "원형, 일반, 32mm, -",
      );
      this.gutterBeamAndWing_archSconce.setDefaultData(
        5,
        CONST.ITEM_ID_ARCH_SCONCE,
        CONST.ITEM_NAME_ARCH_SCONCE,
        "보 + 방풍벽 서까래",
        "원형, 일반, 60mm, -",
      );
      this.gutterBeamAndColumn_bracket.setDefaultData(
        6,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "보 + 기둥",
        "일반, 9T, 75×75mm",
      );
      this.gutterBeamAndBracket_gutterBeamHolder.setDefaultData(
        7,
        CONST.ITEM_ID_GUTTER_BEAM_HOLDER,
        CONST.ITEM_NAME_GUTTER_BEAM_HOLDER,
        "보 + 기둥",
        "거터형, 알루미늄, 75mm, -",
      );
      this.beam_boltAndNut.setDefaultData(
        8,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "보",
        "육각, M12*140mm",
      );
      this.beam_welding.setDefaultData(9, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "보", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_beamLength();
    this.algorithm_rafterInterval();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const columnPosition: ColumnPositionVL = <ColumnPositionVL>this.position;
    /// //////// 선택, 가시성 ///////////

    if (this.beamType === CONST.LB_COLUMN_BEAM_TYPE_GUTTER_BEAM) {
      this.beam_gutterBeam.selected = true;
      this.beam_gutterBeam.visible = true;
      this.gutterBeamAndRoof_archSconce.selected = true;
      this.gutterBeamAndRoof_archSconce.visible = true;
      this.gutterBeamAndWing_archSconce.selected = true;
      this.gutterBeamAndWing_archSconce.visible = true;
      this.gutterBeamAndColumn_bracket.selected = true;
      this.gutterBeamAndColumn_bracket.visible = true;
      this.gutterBeamAndBracket_gutterBeamHolder.selected = true;
      this.gutterBeamAndBracket_gutterBeamHolder.visible = true;
      this.beam_boltAndNut.selected = true;
      this.beam_boltAndNut.visible = true;
      this.beam_pipe.selected = false;
      this.beam_pipe.visible = false;
      this.beamAndBeam_connectionPin.selected = false;
      this.beamAndBeam_connectionPin.visible = false;
      this.beamAndBeam_screw.selected = false;
      this.beamAndBeam_screw.visible = false;
      // this.beamAndColumn_tClamp.selected = false;
      // this.beamAndColumn_tClamp.visible = false;
      // this.beamAndEdgeColumn_tClamp.selected = false;
      // this.beamAndEdgeColumn_tClamp.visible = false;
      // this.beamAndSideColumn_tClamp.selected = false;
      // this.beamAndSideColumn_tClamp.visible = false;
      // this.beamAndSideStudColumn_tClamp.selected = false;
      // this.beamAndSideStudColumn_tClamp.visible = false;
      // this.beamAndFrontAndBackColumn_tClamp.selected = false;
      // this.beamAndFrontAndBackColumn_tClamp.visible = false;
      // this.beamAndSmallColumn_tClamp.selected = false;
      // this.beamAndSmallColumn_tClamp.visible = false;
      // this.beamAndColumn_screw.selected = false;
      // this.beamAndColumn_screw.visible = false;
    } else {
      this.beam_pipe.selected = true;
      this.beam_pipe.visible = true;
      this.beamAndBeam_connectionPin.selected = true;
      this.beamAndBeam_connectionPin.visible = true;
      this.beamAndBeam_screw.selected = true;
      this.beamAndBeam_screw.visible = true;
      // this.beamAndColumn_tClamp.selected = true;
      // this.beamAndColumn_tClamp.visible = true;
      // this.beamAndEdgeColumn_tClamp.selected = true;
      // this.beamAndEdgeColumn_tClamp.visible = true;
      // this.beamAndSideColumn_tClamp.selected = true;
      // this.beamAndSideColumn_tClamp.visible = true;
      // this.beamAndSideStudColumn_tClamp.selected = true;
      // this.beamAndSideStudColumn_tClamp.visible = true;
      // this.beamAndFrontAndBackColumn_tClamp.selected = true;
      // this.beamAndFrontAndBackColumn_tClamp.visible = true;
      // this.beamAndSmallColumn_tClamp.selected = true;
      // this.beamAndSmallColumn_tClamp.visible = true;
      // this.beamAndColumn_screw.selected = true;
      // this.beamAndColumn_screw.visible = true;
      this.beam_gutterBeam.selected = false;
      this.beam_gutterBeam.visible = false;
      this.gutterBeamAndRoof_archSconce.selected = false;
      this.gutterBeamAndRoof_archSconce.visible = false;
      this.gutterBeamAndWing_archSconce.selected = false;
      this.gutterBeamAndWing_archSconce.visible = false;
      this.gutterBeamAndColumn_bracket.selected = false;
      this.gutterBeamAndColumn_bracket.visible = false;
      this.gutterBeamAndBracket_gutterBeamHolder.selected = false;
      this.gutterBeamAndBracket_gutterBeamHolder.visible = false;
      this.beam_boltAndNut.selected = false;
      this.beam_boltAndNut.visible = false;
    }
    this.beam_welding.selected = true;
    this.beam_welding.visible = true;

    /// //////// 수량 ///////////

    this.beam_pipe.designQuantity =
      (this.beam_pipe.getConnectedPipeLength(this.beamLength, this.beamLineNumber) / this.beam_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_BEAM_PIPE;

    if (this.beamLength > this.beam_pipe.specLength) {
      this.beamAndBeam_connectionPin.designQuantity =
        this.beam_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.beamAndBeam_connectionPin.designQuantity = 0;
    }

    this.beamAndBeam_screw.designQuantity =
      ((this.beamAndBeam_connectionPin.getSelectedQuantity() * 4) / this.beamAndBeam_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    // this.beamAndColumn_tClamp.designQuantity = columnPosition.prismColumnPart.columnQuantity * ((CONST.NUM_EXTRA_RATE_T_CLAMP));
    //
    // this.beamAndEdgeColumn_tClamp.designQuantity = columnPosition.prismColumnPart.edgeColumnQuantity * ((CONST.NUM_EXTRA_RATE_T_CLAMP));
    //
    // this.beamAndSideColumn_tClamp.designQuantity = columnPosition.prismColumnPart.sideColumnQuantity * ((CONST.NUM_EXTRA_RATE_T_CLAMP));
    //
    // this.beamAndSideStudColumn_tClamp.designQuantity = columnPosition.prismColumnPart.sideStudColumnQuantity * ((CONST.NUM_EXTRA_RATE_T_CLAMP));
    //
    // this.beamAndFrontAndBackColumn_tClamp.designQuantity = columnPosition.prismColumnPart.frontAndBackColumnQuantity * ((CONST.NUM_EXTRA_RATE_T_CLAMP));
    //
    // this.beamAndSmallColumn_tClamp.designQuantity = columnPosition.prismColumnPart.smallColumnQuantity * ((CONST.NUM_EXTRA_RATE_T_CLAMP));
    //
    // let tClampQuantity: number = this.beamAndColumn_tClamp.getSelectedQuantity()
    // + this.beamAndEdgeColumn_tClamp.getSelectedQuantity()
    // + this.beamAndSideColumn_tClamp.getSelectedQuantity()
    // + this.beamAndSideStudColumn_tClamp.getSelectedQuantity()
    // + this.beamAndFrontAndBackColumn_tClamp.getSelectedQuantity()
    // + this.beamAndSmallColumn_tClamp.getSelectedQuantity();

    // this.beamAndColumn_screw.designQuantity = tClampQuantity * 4 / this.beamAndBeam_screw.specUnitQuantity * ((CONST.NUM_EXTRA_RATE_SCREW));

    this.beam_gutterBeam.designQuantity =
      (this.beam_gutterBeam.getConnectedPipeLength(this.beamLength, this.beamLineNumber) /
        this.beam_gutterBeam.specLength) *
      CONST.NUM_EXTRA_RATE_BEAM_PIPE;

    this.gutterBeamAndRoof_archSconce.designQuantity =
      this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterQuantity *
      2 *
      CONST.NUM_EXTRA_RATE_CONNECTION_ARCH_SCONCE;

    this.gutterBeamAndWing_archSconce.designQuantity =
      this.struct.wingWorkVL.level1.wingPosition.wingRafterPart.wingRafterQuantity *
      CONST.NUM_EXTRA_RATE_CONNECTION_ARCH_SCONCE;

    this.gutterBeamAndColumn_bracket.designQuantity =
      columnPosition.prismColumnPart.columnTotalQuantity * CONST.NUM_EXTRA_RATE_BRACKET;

    this.gutterBeamAndBracket_gutterBeamHolder.designQuantity =
      columnPosition.prismColumnPart.columnTotalQuantity * CONST.NUM_EXTRA_RATE_GUTTER_BEAM_HOLDER;

    this.beam_boltAndNut.designQuantity =
      this.gutterBeamAndColumn_bracket.getSelectedQuantity() * 8 +
      this.gutterBeamAndColumn_bracket.getSelectedQuantity() * 4 * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    if (this.beamType === CONST.LB_COLUMN_BEAM_TYPE_GUTTER_BEAM) {
      this.beam_welding.designQuantity = 0;
    } else {
      let columnQuantity: number =
        columnPosition.prismColumnPart.columnQuantity +
        columnPosition.prismColumnPart.edgeColumnQuantity +
        columnPosition.prismColumnPart.sideColumnQuantity +
        columnPosition.prismColumnPart.sideStudColumnQuantity +
        columnPosition.prismColumnPart.frontAndBackColumnQuantity;
      this.beam_welding.designQuantity = columnQuantity;
    }
  }

  /**
   * 알고리즘: 보 길이 <- 길이(기본정보)
   */
  public algorithm_beamLength(): void {
    this.beamLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
  }

  /**
   * 알고리즘: 보 줄수 <- 동수(기본정보)
   */
  public algorithm_rafterInterval(): void {
    this.beamLineNumber = this.basic.buildingNumber * 2 + 1;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [보] 파이프 <- 보 파이프 샘플
   */
  public algorithmSpec_beam_pipe(): void {
    this.beam_pipe.specs = this.beamPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [보 + 보] 연결핀 <- 보 파이프 샘플
   */
  public algorithmSpec_beamAndBeam_connectionPin(): void {
    this.beamAndBeam_connectionPin.specPipeType = this.beamPipeSample.specPipeType;
    this.beamAndBeam_connectionPin.specCrossSize1 = this.beamPipeSample.specCrossSize;
    this.beamAndBeam_connectionPin.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 보] 나사 <- 공통 샘플
   */
  public algorithmSpec_beamAndBeam_screw(): void {}

  /**
   * 규격 알고리즘: [보 + 내부 기둥] T 클램프 <- 보 파이프 샘플, 내부 기둥 파이프 샘플
   */
  public algorithmSpec_beamAndColumn_tClamp(): void {
    // this.beamAndColumn_tClamp.specPipeType = (<ColumnPositionVL> this.position).prismColumnPart.columnPipeSample.specPipeType;
    // this.beamAndColumn_tClamp.specCrossSize1 = (<ColumnPositionVL> this.position).prismColumnPart.columnPipeSample.specCrossSize;
    // this.beamAndColumn_tClamp.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 모서리 기둥] T 클램프 <- 보 파이프 샘플, 모서리 기둥 파이프 샘플
   */
  public algorithmSpec_beamAndEdgeColumn_tClamp(): void {
    // this.beamAndEdgeColumn_tClamp.specPipeType = (<ColumnPositionVL> this.position).prismColumnPart.edgeColumnPipeSample.specPipeType;
    // this.beamAndEdgeColumn_tClamp.specCrossSize1 = (<ColumnPositionVL> this.position).prismColumnPart.edgeColumnPipeSample.specCrossSize;
    // this.beamAndEdgeColumn_tClamp.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 측면 기둥] T 클램프 <- 보 파이프 샘플, 측면 기둥 파이프 샘플
   */
  public algorithmSpec_beamAndSideColumn_tClamp(): void {
    // this.beamAndSideColumn_tClamp.specPipeType = (<ColumnPositionVL> this.position).prismColumnPart.sideColumnPipeSample.specPipeType;
    // this.beamAndSideColumn_tClamp.specCrossSize1 = (<ColumnPositionVL> this.position).prismColumnPart.sideColumnPipeSample.specCrossSize;
    // this.beamAndSideColumn_tClamp.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 측면 샛기둥] T 클램프 <- 보 파이프 샘플, 측면 샛기둥 파이프 샘플
   */
  public algorithmSpec_beamAndSideStudColumn_tClamp(): void {
    // this.beamAndSideStudColumn_tClamp.specPipeType = (<ColumnPositionVL> this.position).prismColumnPart.sideStudColumnPipeSample.specPipeType;
    // this.beamAndSideStudColumn_tClamp.specCrossSize1 = (<ColumnPositionVL> this.position).prismColumnPart.sideStudColumnPipeSample.specCrossSize;
    // this.beamAndSideStudColumn_tClamp.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 전후면 기둥] T 클램프 <- 보 파이프 샘플, 전후면 기둥 파이프 샘플
   */
  public algorithmSpec_beamAndFrontAndBackColumn_tClamp(): void {
    // this.beamAndFrontAndBackColumn_tClamp.specPipeType = (<ColumnPositionVL> this.position).prismColumnPart.frontAndBackColumnPipeSample.specPipeType;
    // this.beamAndFrontAndBackColumn_tClamp.specCrossSize1 = (<ColumnPositionVL> this.position).prismColumnPart.frontAndBackColumnPipeSample.specCrossSize;
    // this.beamAndFrontAndBackColumn_tClamp.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 소기둥] T 클램프 <- 보 파이프 샘플, 소기둥 파이프 샘플
   */
  public algorithmSpec_beamAndSmallColumn_tClamp(): void {
    // this.beamAndSmallColumn_tClamp.specPipeType = (<ColumnPositionVL> this.position).prismColumnPart.smallColumnPipeSample.specPipeType;
    // this.beamAndSmallColumn_tClamp.specCrossSize1 = (<ColumnPositionVL> this.position).prismColumnPart.smallColumnPipeSample.specCrossSize;
    // this.beamAndSmallColumn_tClamp.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 기둥] 나사 <- 공통 샘플
   */
  public algorithmSpec_beamAndColumn_screw(): void {}

  /**
   * 규격 알고리즘: [보] 파이프 <- 물받이거터 보 샘플
   */
  public algorithmSpec_beam_gutterBeam(): void {
    this.beam_gutterBeam.specs = this.gutterBeamSample.specs;
  }

  /**
   * 규격 알고리즘: [보 + 지붕 서까래] 아치 꽂이 - 물받이거터 보 샘플, 지붕 서까래 파이프 샘플
   */
  public algorithmSpec_gutterBeamAndRoof_archSconce(): void {
    const rafterPart: RoofPartVL_Rafter = this.struct.roofWorkVL.level1.roofPosition.rafterPart;
    this.gutterBeamAndRoof_archSconce.specPipeType = rafterPart.rafterPipeSample.specPipeType;
    this.gutterBeamAndRoof_archSconce.specCrossSize1 = rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 방풍벽 서까래] 아치 꽂이 - 물받이거터 보 샘플, 방풍벽 서까래 파이프 샘플
   */
  public algorithmSpec_gutterBeamAndWing_archSconce(): void {
    const wingRafterPart: WingPartVL_WingRafter = this.struct.wingWorkVL.level1.wingPosition.wingRafterPart;
    this.gutterBeamAndRoof_archSconce.specPipeType = wingRafterPart.wingRafterPipeSample.specPipeType;
    this.gutterBeamAndRoof_archSconce.specCrossSize1 = wingRafterPart.wingRafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 기둥] 브라켓트
   */
  public algorithmSpec_gutterBeamAndColumn_bracket(): void {}

  /**
   * 규격 알고리즘: [보 + 기둥] 물받이거터 보 고정구 <- 물받이거터 보 샘플
   */
  public algorithmSpec_gutterBeamAndBracket_gutterBeamHolder(): void {}

  /**
   * 규격 알고리즘: [보 + 기둥] 볼트&너트
   */
  public algorithmSpec_beam_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [공통 부품] 용접
   */
  public algorithmSpec_beam_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
