import { useAtom } from "jotai";
import { NoticeLineRegular, CheckCircleFill } from "@greenlabs/formula-components";
import { Toast } from "ui";

import { ERROR_MESSAGE, TOAST_TYPE } from "constants/common";
import { toastAtom } from "store/global";

const ToastUi = () => {
  const [toast, setToast] = useAtom(toastAtom);
  const handleToastInVisible = (e: boolean) => {
    setToast({ visible: e, type: TOAST_TYPE.ERROR, content: ERROR_MESSAGE.ERROR });
  };

  const ToastIcon = () => {
    switch (toast.type) {
      case TOAST_TYPE.ERROR:
        return <NoticeLineRegular color="red-70" className="mr-2.5" />;
      case TOAST_TYPE.SUCCESS:
        return <CheckCircleFill className="mr-2.5" color="primary-contents" />;
    }
  };

  return <Toast content={toast.content} icon={ToastIcon()} callback={handleToastInVisible} isVisible={toast.visible} />;
};

export default ToastUi;
