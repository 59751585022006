import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { FixingPart_NormalClip } from "vhows-design/src/object/design/cover/fixing/FixingPart_NormalClip";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-26
 */
@jsonObject
export class FixingSample_NormalClip extends ItemClip {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const normalClipPart: FixingPart_NormalClip = <FixingPart_NormalClip>this.part;
    normalClipPart.algorithmSpec_normalClip_normalClip();
    normalClipPart.algorithmSpec_normalClipMiddle_normalClip();
    normalClipPart.algorithmSpec_normalClipRafter_normalClip();
    normalClipPart.algorithmSpec_normalClipGate_normalClip();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 측면 가로대 파이프 샘플, 지붕 가로대 파이프 샘플, 마구리 중방 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    const trunkLevel: TrunkLevel = <TrunkLevel>this.struct.trunkWork.levelAC[this.level.index];
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_SIDE) {
      this.specCrossSize = trunkLevel.trunkPosition.straightPart.straightSidePipeSample.specCrossSize;
    } else if (this.position.label === CONST.LB_POSITION_ROOF) {
      this.specCrossSize = trunkLevel.trunkPosition.straightPart.straightRoofPipeSample.specCrossSize;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.specCrossSize = endpieceLevel.frontPosition.middlePart.middlePipeSample.specCrossSize;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.specCrossSize = endpieceLevel.backPosition.middlePart.middlePipeSample.specCrossSize;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
