import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCashmilon } from "vhows-design/src/object/design/item/list/ItemCashmilon";
import { ItemLagging } from "vhows-design/src/object/design/item/list/ItemLagging";
import { ItemMat } from "vhows-design/src/object/design/item/list/ItemMat";
import { ItemNonwoven } from "vhows-design/src/object/design/item/list/ItemNonwoven";
import { ItemQuilting } from "vhows-design/src/object/design/item/list/ItemQuilting";
import { ItemScreen } from "vhows-design/src/object/design/item/list/ItemScreen";
import { ItemShadenet } from "vhows-design/src/object/design/item/list/ItemShadenet";
import { ItemTent } from "vhows-design/src/object/design/item/list/ItemTent";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { ItemWeavingFilm } from "vhows-design/src/object/design/item/list/ItemWeavingFilm";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { CoverPosition } from "vhows-design/src/object/design/cover/cover/CoverPosition";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-19
 */
@jsonObject({
  knownTypes: [],
})
export class SkyCoverPart_Vinyl extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public coverLevel: CoverLevel;

  public skylightLevel: number;

  // 샘플
  // public vinylSample: SkyCoverSample_Vinyl; // 비닐 샘플

  // 아이템

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 피복 길이 AC */
  public coverLengthAC: number[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _coverWidth: number = 0; // 피복 폭
  @jsonMember(Number)
  public _coverLength: number = 0; // 피복 길이

  /**
   * 피복 폭
   */
  public get coverWidth(): number {
    return this._coverWidth;
  }

  //
  public set coverWidth(value: number) {
    this._coverWidth = CommonUtil.fixFloat(value);
  }

  /**
   * 피복 길이
   */
  public get coverLength(): number {
    return this._coverLength;
  }

  //
  public set coverLength(value: number) {
    this._coverLength = CommonUtil.fixFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.init_coverLevel();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();
    /// //////// 선언 ///////////

    // 외부
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    // 기타
    let basicBuilding: BasicBuilding;

    /// //////// 수치 ///////////

    // 천창 피복 하나의 폭과 길이
    if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_ARCH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_TRIANGLE
    ) {
      this.coverWidth = skyFramePosition.rafterPart.rafterLength;
      this.coverLength = skyFramePosition.skylightLength + 2;
      if (this.basic.isVariableLength === true) {
        this.coverLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.coverLengthAC.push(basicBuilding.length + 2);
        }
      }
    } else if (skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_HALF) {
      this.coverWidth = skyFramePosition.rafterPart.columnLength;
      this.coverLength = skyFramePosition.skylightLength;
      if (this.basic.isVariableLength === true) {
        this.coverLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.coverLengthAC.push(basicBuilding.length);
        }
      }
    } else if (
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_BOTH ||
      skyFramePosition.type === CONST.LB_SKYLIGHT_FRAME_TYPE_RACKPINION_WAY_ONE
    ) {
      this.coverWidth = skyFramePosition.arcWidth;
      this.coverLength = skyFramePosition.skylightLength;
      if (this.basic.isVariableLength === true) {
        this.coverLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.coverLengthAC.push(skyFramePosition.skylightLength);
        }
      }
    }

    /// //////// 생성, 선택, 가시성, 규격, 수량 ///////////
    for (const coverPosition of this.coverLevel.positionAC as CoverPosition[]) {
      if (
        coverPosition.type === CONST.LB_COVER_TYPE_VINYL ||
        coverPosition.type === CONST.LB_COVER_TYPE_WEAVING_FILM ||
        coverPosition.type === CONST.LB_COVER_TYPE_SHADENET ||
        coverPosition.type === CONST.LB_COVER_TYPE_TENT ||
        coverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN ||
        coverPosition.type === CONST.LB_COVER_TYPE_QUILTING ||
        coverPosition.type === CONST.LB_COVER_TYPE_SCREEN ||
        coverPosition.type === CONST.LB_COVER_TYPE_MAT
      ) {
        // 비닐류
        this.makeSkylightVinylStyle(coverPosition);
      } else if (
        coverPosition.type === CONST.LB_COVER_TYPE_CASHMILON ||
        coverPosition.type === CONST.LB_COVER_TYPE_COLOR_LAGGING ||
        coverPosition.type === CONST.LB_COVER_TYPE_LAGGING
      ) {
        // 카시미론류
        this.makeSkylightCashmilonStyle(coverPosition);
      }
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  /**
   * 초기화: 기초 피복 중수 <- 설치 중수
   */
  public init_coverLevel(): void {
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;
    this.coverLevel = <CoverLevel>this.struct.coverWork.levelAC[skyFramePosition.skylightLevel - 1];
  }

  /**
   * 비닐류 피복 만들기
   */
  protected makeSkylightVinylStyle(coverPosition: CoverPosition): void {
    // 외부
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;
    const switcherPosition: SwitcherPosition = <SwitcherPosition>(
      this.struct.switcherWork.levelAC[skyFramePosition.skylightLevel - 1].positionAC[this.position.index]
    );
    // 기타
    let item: Item;
    let i: number;
    if (this.position.label === CONST.LB_POSITION_SKYLIGHT) {
      if (this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < this.basic.buildingNumber; i++) {
          item = this.getNewSkylightItem(coverPosition, true, i);
          item.purpose = `${i + 1}번동 천창 피복> ${coverPosition.label}`;
          item.designQuantity = this.basic.buildingNumber;
          this.itemAC.push(item);
        }
      } else {
        // 동수만큼 수량 입력
        item = this.getNewSkylightItem(coverPosition);
        item.purpose = `천창 피복> ${coverPosition.label}`;
        item.designQuantity = this.basic.buildingNumber;
        this.itemAC.push(item);
      }
    }
  }

  /**
   * 카시미론류 피복 만들기
   */
  protected makeSkylightCashmilonStyle(coverPosition: CoverPosition): void {
    // 외부
    const skyFramePosition: SkyFramePosition = <SkyFramePosition>this.struct.skyFrameWork.level1.skylightPosition;

    // 기타
    let item: any;
    const overlapSize: number = this.design.preference.cashmilonStyleOverlapLength;

    if (this.position.label === CONST.LB_POSITION_SKYLIGHT) {
      item = this.getNewSkylightItem(coverPosition);
      item.purpose = `천창 피복> ${coverPosition.label}`;
      // 길이방향 설치
      item.designQuantity = (this.coverLength / (item.specLength - overlapSize)) * this.basic.buildingNumber;

      this.itemAC.push(item);
    }
  }

  /**
   * 새로운 천창 피복 아이템 만들기
   * @param coverPosition 기초 피복 위치 객체
   * @param isVariableLength 가변길이 여부
   * @param buildingIndex 동수 인덱스
   * @return 천창 피복 아이템
   */
  private getNewSkylightItem(
    coverPosition: CoverPosition,
    isVariableLength: boolean = false,
    buildingIndex: number = 0,
  ): Item {
    let item: Item;

    if (coverPosition.type === CONST.LB_COVER_TYPE_VINYL) {
      item = ItemVinyl.getNewDesignItemBySample(this, coverPosition.vinylPart.vinylSample);
      (<ItemVinyl>item).specWidth = (<ItemVinyl>item).getFoldedWidth(CommonUtil.convertM2CM(this.coverWidth));
      if (isVariableLength === true) {
        (<ItemVinyl>item).specLength = this.coverLengthAC[buildingIndex];
      } else {
        (<ItemVinyl>item).specLength = this.coverLength;
      }
      (<ItemVinyl>item).specPackingType = CONST.ITEM_SPEC_VALUE_BOX;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_WEAVING_FILM) {
      item = ItemWeavingFilm.getNewDesignItemBySample(this, coverPosition.weavingFilmPart.weavingFilmSample);

      (<ItemWeavingFilm>item).specWidth = CommonUtil.convertM2CM(this.coverWidth);
      if (isVariableLength === true) {
        (<ItemWeavingFilm>item).specLength = this.coverLengthAC[buildingIndex];
      } else {
        (<ItemWeavingFilm>item).specLength = this.coverLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_SHADENET) {
      item = ItemShadenet.getNewDesignItemBySample(this, coverPosition.shadenetPart.shadenetSample);

      (<ItemShadenet>item).specWidth = this.coverWidth;
      if (isVariableLength === true) {
        (<ItemShadenet>item).specLength = (<ItemShadenet>item).getAjustedSpecLength(this.coverLengthAC[buildingIndex]);
      } else {
        (<ItemShadenet>item).specLength = (<ItemShadenet>item).getAjustedSpecLength(this.coverLength);
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_TENT) {
      item = ItemTent.getNewDesignItemBySample(this, coverPosition.tentPart.tentSample);

      (<ItemTent>item).specWidth = this.coverWidth;
      if (isVariableLength === true) {
        (<ItemTent>item).specLength = this.coverLengthAC[buildingIndex];
      } else {
        (<ItemTent>item).specLength = this.coverLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_NONWOVEN) {
      item = ItemNonwoven.getNewDesignItemBySample(this, coverPosition.nonwovenPart.nonwovenSample);

      (<ItemNonwoven>item).specWidth = this.coverWidth;
      if (isVariableLength === true) {
        (<ItemNonwoven>item).specLength = this.coverLengthAC[buildingIndex];
      } else {
        (<ItemNonwoven>item).specLength = this.coverLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_CASHMILON) {
      item = ItemCashmilon.getNewDesignItemBySample(this, coverPosition.cashmilonPart.cashmilonSample);

      (<ItemCashmilon>item).specWidth = this.coverWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_COLOR_LAGGING) {
      item = ItemLagging.getNewDesignItemBySample(this, coverPosition.colorLaggingPart.colorLaggingSample);

      (<ItemLagging>item).specWidth = this.coverWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_LAGGING) {
      item = ItemLagging.getNewDesignItemBySample(this, coverPosition.laggingPart.laggingSample);

      (<ItemLagging>item).specWidth = this.coverWidth;
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_QUILTING) {
      item = ItemQuilting.getNewDesignItemBySample(this, coverPosition.quiltingPart.quiltingSample);

      (<ItemQuilting>item).specWidth = this.coverWidth;

      if (isVariableLength === true) {
        (<ItemQuilting>item).specLength = this.coverLengthAC[buildingIndex];
      } else {
        (<ItemQuilting>item).specLength = this.coverLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_SCREEN) {
      item = ItemScreen.getNewDesignItemBySample(this, coverPosition.screenPart.screenSample);

      (<ItemScreen>item).specWidth = this.coverWidth;

      if (isVariableLength === true) {
        (<ItemScreen>item).specLength = this.coverLengthAC[buildingIndex];
      } else {
        (<ItemScreen>item).specLength = this.coverLength;
      }
    } else if (coverPosition.type === CONST.LB_COVER_TYPE_MAT) {
      item = ItemMat.getNewDesignItemBySample(this, coverPosition.matPart.matSample);

      (<ItemMat>item).specWidth = this.coverWidth;

      if (isVariableLength === true) {
        (<ItemMat>item).specLength = this.coverLengthAC[buildingIndex];
      } else {
        (<ItemMat>item).specLength = this.coverLength;
      }
    }

    return item;
  }
}
