import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { SkyFramePositionIL } from "vhows-design/src/object/design/skylight/frame/il/SkyFramePositionIL";
import { SkySwitchPositionIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPositionIL";
import { SkySwitchSampleIL_NormalClip } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchSampleIL_NormalClip";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-22
 */
@jsonObject({
  knownTypes: [SkySwitchSampleIL_NormalClip],
})
export class SkySwitchPartIL_FixingNormalClip extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalClipSample: SkySwitchSampleIL_NormalClip; // 일반 클립 샘플

  // 아이템
  public normalclip_normalClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _clipInterval: number; // 클립 간격

  /**
   * 클립 간격
   */
  public get clipInterval(): number {
    return this._clipInterval;
  }

  public set clipInterval(value: number) {
    this._clipInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalClipSample = new SkySwitchSampleIL_NormalClip();

    this.sampleAC = [this.normalClipSample];

    // 아이템
    this.normalclip_normalClip = new ItemClip();

    this.itemAC = [this.normalclip_normalClip];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalClipSample = <SkySwitchSampleIL_NormalClip>this.sampleAC[0];

    // 아이템
    this.normalclip_normalClip = <ItemClip>this.itemAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param clipInterval: number 클립 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    clipInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._clipInterval = clipInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.normalClipSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "천창 일반 클립 고정",
        "플라스틱, 25mm",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalclip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "천창 일반 클립 고정",
        "플라스틱, 25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skyFramePosition: SkyFramePositionIL = this.struct.skyFrameWorkIL.level1.skylightPosition;
    const skySwitchPosition: SkySwitchPositionIL = <SkySwitchPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.normalclip_normalClip.selected = true;
    this.normalclip_normalClip.visible = true;

    /// //////// 수량 ///////////

    this.normalclip_normalClip.designQuantity =
      Math.ceil(skyFramePosition.skylightLength / this.clipInterval) *
      skySwitchPosition.switchWayValue *
      this.basic.buildingNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 클립 고정] 일반 클립 <- 일반 클립 샘플
   */
  public algorithmSpec_normalclip_normalClip(): void {
    this.normalclip_normalClip.specs = this.normalClipSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
