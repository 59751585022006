import { useRef, useEffect } from "react";

type Modal = {
  children: React.ReactElement;
  addClass?: string;
};

export const Modal = ({ children, addClass = "" }: Modal) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const clickOutsideCallbackFn = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      // const target = e.target;
      // const isModalClick = !modalRef.current ? true : modalRef.current.contains(target);
    };

    document.addEventListener("click", clickOutsideCallbackFn);

    return () => {
      document.removeEventListener("click", clickOutsideCallbackFn);
    };
  }, [modalRef.current]);

  return (
    <div className={`fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center z-40` + addClass}>
      <div key="dim" className="fixed inset-0 bg-black opacity-30" />
      <div
        key="modal"
        className="z-50 flex h-auto w-11/12 max-w-xl items-center justify-center rounded-2xl bg-white shadow"
        ref={modalRef}
      >
        {children ? children : null}
      </div>
    </div>
  );
};
