import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { HydCultivationPosition } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPosition";
import { HydCultivationPart_GroundRail } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPart_GroundRail";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject
export class HydCultivationSample_GroundRailPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const railPart: HydCultivationPart_GroundRail = <HydCultivationPart_GroundRail>this.part;
    railPart.algorithmSpec_groundRail_pipe();
    railPart.algorithmSpec_nomalRail_connectionPin();
    railPart.algorithmSpec_groundRail_railSupportPipe();
    railPart.algorithmSpec_tubeRail_uBendingTubeRail();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 지면레일 형태
   */
  // @override
  public algorithmSpec(): void {
    const hydCultivationPosition: HydCultivationPosition = <HydCultivationPosition>this.position;
    if (hydCultivationPosition.groundRailType === CONST.LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_NORMAL) {
      this.specPipeCrossSize = CONST.ITEM_SPEC_VALUE_48_MM_PIPE;
      this.specThickness = CONST.ITEM_SPEC_VALUE_2_1_T;
    } else {
      this.specPipeCrossSize = CONST.ITEM_SPEC_VALUE_40_A;
      this.specThickness = CONST.ITEM_SPEC_VALUE_3_2_T;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
