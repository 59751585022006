import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";
import { MainpipePosition } from "vhows-design/src/object/design/watering/mainpipe/MainpipePosition";
import { ColumnPartIL_CylinderMiddle } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderMiddle";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-15
 */
@jsonObject
export class ColumnSampleIL_CylinderMiddlePipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const cylinderMiddlePart: ColumnPartIL_CylinderMiddle = <ColumnPartIL_CylinderMiddle>this.part;
    cylinderMiddlePart.algorithmSpec_middle_pipe();
    cylinderMiddlePart.algorithmSpec_middleTrussLower_pipe();
    cylinderMiddlePart.algorithmSpec_middleAndMiddle_connectionPin();
    cylinderMiddlePart.algorithmSpec_middleAndColumn_fixAsibaClamp();
    cylinderMiddlePart.middleBracingPipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    if (this.position.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      // 마구리 골조
      for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) {
        // 모든 중수
        for (const endpiecePosition of endpieceLevel.positionAC as EndpiecePositionIL[]) {
          endpiecePosition.cylinderStudSepPart.algorithmSpec_studBottomAndMiddle_tClamp();
        }
      }

      // 선반/균상다이
      if (this.level.index === 0) {
        const rackPosition: RackPosition = this.struct.rackWork.level1.wholePosition;
        rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tClamp();
        rackPosition.rackPart.algorithmSpec_rackColumnAndFrame2_normalHolder();
        rackPosition.rackPart.algorithmPart();
      }

      // 관수 제어부
      if (this.level.index === 0) {
        const mainpipePosition: MainpipePosition = this.struct.mainpipeWork.level1.wholePosition;
        mainpipePosition.upperFramePart.algorithmSpec_wateringFixAndMiddleCylinder_steelStringGrabber();
      }

      // 운반레일
      if (this.level.index === 0) {
        this.struct.railWork.level1.wholePosition.upperRailPart.algorithmSpec_railAndFrame_secondLevelRailHook();
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플, 보 파이프 샘플, 폭(기본정보)
   */
  // @override
  public algorithmSpec(): void {
    if (
      (<ColumnPositionIL>this.position).cylinderColumnPart.columnPipeSample.specCrossSize ===
      CONST.ITEM_SPEC_VALUE_48_MM
    ) {
      this.specsList = (<ColumnPositionIL>this.position).cylinderColumnPart.columnPipeSample.specsList;
    } else if (
      (<ColumnPositionIL>this.position).cylinderColumnPart.columnPipeSample.specCrossSize ===
      CONST.ITEM_SPEC_VALUE_60_MM
    ) {
      this.specsList = (<ColumnPositionIL>this.position).cylinderBeamPart.beamPipeSample.specsList;
    }
  }

  /**
   * 규격 알고리즘 : 길이 <- 폭(기본정보)
   */
  public algorithmSpec_specLength(): void {
    this.specLength = this.basicLevel.width;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
