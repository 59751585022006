import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 연결구
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-02-02
 */
@jsonObject
export class ItemConnector extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [파이프형태, 재질, 단면크기1, 단면크기2]
  public _specPipeType: string = undefined; // 파이프형태
  public _specMaterial: string = undefined; // 재질
  public _specCrossSize1: string = undefined; // 단면크기1
  public _specCrossSize2: string = undefined; // 단면크기2

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 파이프형태
   */
  public get specPipeType(): string {
    return this._specPipeType;
  }
  //
  public set specPipeType(value: string) {
    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 재질
   */
  public get specMaterial(): string {
    return this._specMaterial;
  }
  //
  public set specMaterial(value: string) {
    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 단면크기1
   */
  public get specCrossSize1(): string {
    return this._specCrossSize1;
  }
  //
  public set specCrossSize1(value: string) {
    this._specCrossSize1 = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 단면크기2
   */
  public get specCrossSize2(): string {
    return this._specCrossSize2;
  }
  //
  public set specCrossSize2(value: string) {
    this._specCrossSize2 = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.connectorCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 선택 및 가시성 체크
   * @param selected 사용 여부
   * @param visible 가시성
   */
  // @override
  public checkSelected(selected: boolean, visible: boolean = true): void {
    if (selected) {
      this.selected = !this.design.preference.connectedPipePinWorkedFlag;
    } else {
      this.selected = selected;
    }
    this.visible = visible;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specMaterial = specsArray[1];
    this.specCrossSize1 = specsArray[2];
    this.specCrossSize2 = specsArray[3];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specMaterial = specsArray[1];
    this.specCrossSize1 = specsArray[2];
    this.specCrossSize2 = specsArray[3];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specPipeType +
      CONST.DELIMITER_SPEC +
      this._specMaterial +
      CONST.DELIMITER_SPEC +
      this._specCrossSize1 +
      CONST.DELIMITER_SPEC +
      this._specCrossSize2;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }
}
