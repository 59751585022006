import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHingedDoor } from "vhows-design/src/object/design/item/list/ItemHingedDoor";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { EndpiecePartVL_Gate } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePartVL_Gate";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class EndpieceSampleVL_HingedDoor extends ItemHingedDoor {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const gatePart: EndpiecePartVL_Gate = <EndpiecePartVL_Gate>this.part;
    gatePart.algorithm_doorQuantity();
    gatePart.algorithm_gateWidth();
    gatePart.algorithm_gateHeight();
    gatePart.algorithmSpec_gate_hingedDoor();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<EndpieceLevelVL>this.level).backPosition.gatePart.hingedDoorSample.specs = this.specs;
    }

    /// //////// 외부 ///////////

    // // 칸막이
    // if (this.level.index === 0) {
    //   if (this.position.label === CONST.LB_POSITION_FRONT) {
    //     this.struct.partitionWork.level1.trunkPosition.gatePart.hingedDoorSample.algorithmSpec();
    //   }
    // }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
