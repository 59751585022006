import { AnyT, jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Address } from "vhows-design/src/object/common/Address";
import { UserConst } from "vhows-design/src/common/constant/UserConst";
import { Role } from "vhows-design/src/object/user/Role";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-04-15
 */
@jsonObject({
  knownTypes: [],
})
export class User extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 비밀번호 확인
  @jsonMember(String)
  public passwordConfirm: string = null;
  // 기존 비밀번호
  @jsonMember(String)
  public oldPassword: string = null;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 사용자 아이디
  @jsonMember(String)
  public username: string = null;
  // 사용자 비밀번호
  @jsonMember(String)
  public password: string = null;
  // 사용자 이름
  @jsonMember(String)
  public name: string = null;
  // 성별
  @jsonMember(String)
  public gender: string = null;
  // 생년월일
  @jsonMember(Date)
  public birthdate: Date = null;
  // 주민등록번호/사업자등록번호
  @jsonMember(String)
  public regNumber: string = null;
  // 이메일
  @jsonMember(String)
  public email: string = null;
  // 주소
  @jsonMember(Address)
  public address: Address = null;
  // 휴대폰번호
  @jsonMember(String)
  public mobileNumber: string = null;
  // 프로필사진
  @jsonMember(String)
  public profileImage: string = null;
  // 전화번호
  @jsonMember(String)
  public phoneNumber: string = null;
  // 클래스타입
  @jsonMember(String)
  public classType: string = null;
  // 역할
  @jsonMember(String)
  public role: string = null;
  // 역할
  @jsonMember(AnyT)
  public roles: Role[] = [];
  // 계정 상태
  @jsonMember(String)
  public accountStatus: string = UserConst.accountStatus.NORMAL;
  // 인증 상태
  @jsonMember(Boolean)
  public authStatus: boolean = false;
  // 이메일 인증 상태
  @jsonMember(Boolean)
  public emailAuthStatus: boolean = false;
  // 휴대폰 인증 상태
  @jsonMember(Boolean)
  public mobileAuthStatus: boolean = false;
  // 비밀번호 변경일시
  @jsonMember(Date)
  public passwordLastUpdate: Date = null;
  // 최근 로그인 일시
  @jsonMember(Date)
  public lastLoginDate: Date = null;
  // 최종 변경일시
  @jsonMember(Date)
  public lastUpdate: Date = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
    this.address = new Address();
    this.roles = [new Role()];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(): void {}

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   */
  public setDefaultData(): void {
    this.username = "";
    this.name = "김평화";
    this.gender = "";
    this.birthdate = null;
    this.regNumber = "";
    this.email = "";
    this.address = null;
    this.mobileNumber = "070-8623-3385";
    this.phoneNumber = "";
    this.classType = "";
    this.accountStatus = "";
    this.authStatus = false;
  }
}
