import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ItemPowerSwitcher } from "vhows-design/src/object/design/item/list/ItemPowerSwitcher";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurSwitchSampleIL_PowerSwitcher } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_PowerSwitcher";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurSwitchSampleIL_PowerSwitcher],
})
export class CurSwitchPartIL_Power extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public powerSwitcherSample: CurSwitchSampleIL_PowerSwitcher; // 커튼 동력 개폐기 샘플

  // 아이템
  public power_powerSwitcher: ItemPowerSwitcher;
  public power_electricWire: ItemElectricWire;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.powerSwitcherSample = new CurSwitchSampleIL_PowerSwitcher();

    this.sampleAC = [this.powerSwitcherSample];

    // 아이템
    this.power_powerSwitcher = new ItemPowerSwitcher();
    this.power_electricWire = new ItemElectricWire();

    this.itemAC = [this.power_powerSwitcher, this.power_electricWire];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.powerSwitcherSample = <CurSwitchSampleIL_PowerSwitcher>this.sampleAC[0];

    // 아이템
    this.power_powerSwitcher = <ItemPowerSwitcher>this.itemAC[0];
    this.power_electricWire = <ItemElectricWire>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.powerSwitcherSample.setDefaultData(
        0,
        CONST.ITEM_ID_POWER_SWITCHER,
        CONST.ITEM_NAME_POWER_SWITCHER,
        "커튼 동력 개폐",
        "부직포용, 25mm, 25mm, -, -",
        "청오, CODM 81034, 16kg·m, 4m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.power_powerSwitcher.setDefaultData(
        0,
        CONST.ITEM_ID_POWER_SWITCHER,
        CONST.ITEM_NAME_POWER_SWITCHER,
        "커튼 동력 개폐",
        "부직포용, 25mm, 25mm, -, -",
        "청오, CODM 81034, 16kg·m, 4m",
      );
      this.power_electricWire.setDefaultData(
        1,
        CONST.ITEM_ID_ELECTRIC_WIRE,
        CONST.ITEM_NAME_ELECTRIC_WIRE,
        "커튼 동력 개폐",
        "VCTF, 1.5sq, 2C, 100m",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_power_electricWire();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;
    let widthQuanity: number;
    let heightQuantity: number;
    let lengthQuantity: number;

    /// //////// 선택, 가시성 ///////////

    this.power_powerSwitcher.selected = true;
    this.power_powerSwitcher.visible = true;
    this.power_electricWire.selected = true;
    this.power_electricWire.visible = true;

    /// //////// 수량 ///////////

    this.power_powerSwitcher.designQuantity = curSwitchPosition.switchWayValue;

    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
      widthQuanity = (this.basicLevel.width * this.basic.buildingNumber) / 2;
      heightQuantity = this.basicLevel.heightShoulder;
      this.power_electricWire.designQuantity =
        (((widthQuanity + heightQuantity) * curSwitchPosition.switchWayValue) / this.power_electricWire.specLength) *
        CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      widthQuanity = (this.basicLevel.width * this.basic.buildingNumber) / 2;
      heightQuantity = this.basicLevel.heightShoulder;
      this.power_electricWire.designQuantity =
        ((widthQuanity + heightQuantity) / this.power_electricWire.specLength) * CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      widthQuanity = (this.basicLevel.width * this.basic.buildingNumber) / 2;
      heightQuantity = this.basicLevel.heightShoulder;
      lengthQuantity = this.basicLevel.length;
      this.power_electricWire.designQuantity =
        ((widthQuanity + heightQuantity + lengthQuantity) / this.power_electricWire.specLength) *
        CONST.NUM_EXTRA_RATE_ELECTRIC_WIRE;
    }
  }

  /**
   * 규격 알고리즘: [커튼 동력 개폐] 동력 개폐기 <- 커튼 동력 개폐기 샘플
   */
  public algorithmSpec_power_powerSwitcher(): void {
    this.power_powerSwitcher.specs = this.powerSwitcherSample.specs;
    this.power_powerSwitcher.brands = this.powerSwitcherSample.brands;
  }

  /**
   * 규격 알고리즘: [커튼 동력 개폐] 전선 <- 동력 개폐기용 전선 샘플(제어기)
   */
  public algorithmSpec_power_electricWire(): void {
    this.power_electricWire.specs =
      this.struct.controllerWorkIL.level1.integratedPosition.controllerPart.switcherElectricWireSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
