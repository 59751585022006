import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCashmilon } from "vhows-design/src/object/design/item/list/ItemCashmilon";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { CoverPart } from "vhows-design/src/object/design/cover/cover/CoverPart";
import { CoverSample_Cashmilon } from "vhows-design/src/object/design/cover/cover/CoverSample_Cashmilon";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-08
 */
@jsonObject({
  knownTypes: [CoverSample_Cashmilon],
})
export class CoverPart_Cashmilon extends CoverPart {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public cashmilonSample: CoverSample_Cashmilon; // 카시미론 샘플

  // 아이템
  protected item: ItemCashmilon; // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Boolean)
  public _fixedLengthRoof: boolean; // 단위길이 고정(지붕)

  /**
   * 단위길이 고정(지붕)
   */
  public get fixedLengthRoof(): boolean {
    return this._fixedLengthRoof;
  }

  //
  public set fixedLengthRoof(value: boolean) {
    this._fixedLengthRoof = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.cashmilonSample = new CoverSample_Cashmilon();

    this.sampleAC = [this.cashmilonSample];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.cashmilonSample = <CoverSample_Cashmilon>this.sampleAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param fixedLengthRoof: boolean 단위길이 고정(지붕)
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    fixedLengthRoof: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._fixedLengthRoof = fixedLengthRoof;

    // 샘플
    if (this.level.index >= 0) {
      this.cashmilonSample.setDefaultData(
        0,
        CONST.ITEM_ID_CASHMILON,
        CONST.ITEM_NAME_CASHMILON,
        "지붕+측면",
        "6온스, 2.3m, 24m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    //----------------------------------
    // 품목
    // 피복객체의 값을 실제 품목에 반영
    //----------------------------------

    // 측면
    if (this.coverSide != null) {
      this.item = ItemCashmilon.getNewDesignItemBySample(this, this.cashmilonSample);

      this.item.purpose = `피복: ${this.coverSide.purpose}`;
      this.item.specWidth = this.cashmilonSample.specWidth;
      this.item.specLength = this.cashmilonSample.specLength;
      this.item.designQuantity =
        (this.lengthSide / (this.item.specWidth - this.cashmilonStyleOverlap)) *
        (this.coverSide.quantity * this.basic.buildingNumber) *
        (this.coverSide.width / this.cashmilonSample.specLength);

      this.itemAC.push(this.item);
    }

    // 측면(+측면+마구리) - 비대칭 천창(짧은쪽)
    if (this.coverSideSkyShort != null) {
      this.item = ItemCashmilon.getNewDesignItemBySample(this, this.cashmilonSample);

      this.item.purpose = `피복: ${this.coverSideSkyShort.purpose}`;
      this.item.specWidth = this.cashmilonSample.specWidth;
      if (this.fixedLengthRoof === true) {
        this.item.specLength = this.cashmilonSample.specLength;
        this.item.designQuantity =
          (this.lengthRoof / (this.item.specWidth - this.cashmilonStyleOverlap)) *
          (this.coverSideSkyShort.quantity * this.basic.buildingNumber) *
          (this.coverSideSkyShort.width / this.cashmilonSample.specLength);
      } else {
        this.item.specLength = this.coverSideSkyShort.width;
        this.item.designQuantity =
          (this.lengthRoof / (this.item.specWidth - this.cashmilonStyleOverlap)) *
          (this.coverSideSkyShort.quantity * this.basic.buildingNumber);
      }

      this.itemAC.push(this.item);
    }

    // 지붕(+측면+마구리)
    if (this.coverRoof != null) {
      this.item = ItemCashmilon.getNewDesignItemBySample(this, this.cashmilonSample);

      this.item.purpose = `피복: ${this.coverRoof.purpose}`;
      this.item.specWidth = this.cashmilonSample.specWidth;
      if (this.fixedLengthRoof === true) {
        this.item.specLength = this.cashmilonSample.specLength;
        this.item.designQuantity =
          (this.lengthRoof / (this.item.specWidth - this.cashmilonStyleOverlap)) *
          (this.coverRoof.quantity * this.basic.buildingNumber) *
          (this.coverRoof.width / this.cashmilonSample.specLength);
      } else {
        this.item.specLength = this.coverRoof.width;
        this.item.designQuantity =
          (this.lengthRoof / (this.item.specWidth - this.cashmilonStyleOverlap)) *
          (this.coverRoof.quantity * this.basic.buildingNumber);
      }

      this.itemAC.push(this.item);
    }

    // 지붕(+측면+마구리) - 비대칭 천창(짧은쪽)
    if (this.coverRoofSkyShort != null) {
      this.item = ItemCashmilon.getNewDesignItemBySample(this, this.cashmilonSample);

      this.item.purpose = `피복: ${this.coverRoofSkyShort.purpose}`;
      this.item.specWidth = this.cashmilonSample.specWidth;
      if (this.fixedLengthRoof === true) {
        this.item.specLength = this.cashmilonSample.specLength;
        this.item.designQuantity =
          (this.lengthRoof / (this.item.specWidth - this.cashmilonStyleOverlap)) *
          (this.coverRoofSkyShort.quantity * this.basic.buildingNumber) *
          (this.coverRoofSkyShort.width / this.cashmilonSample.specLength);
      } else {
        this.item.specLength = this.coverRoofSkyShort.width;
        this.item.designQuantity =
          (this.lengthRoof / (this.item.specWidth - this.cashmilonStyleOverlap)) *
          (this.coverRoofSkyShort.quantity * this.basic.buildingNumber);
      }

      this.itemAC.push(this.item);
    }

    // 앞면(+뒷면)
    if (this.coverFront != null) {
      this.item = ItemCashmilon.getNewDesignItemBySample(this, this.cashmilonSample);

      this.item.purpose = `마구리 피복: ${this.coverFront.purpose}`;
      // 면적계산 설치
      this.item.specWidth = this.cashmilonSample.specWidth;
      this.item.specLength = this.cashmilonSample.specLength;
      this.item.designQuantity =
        (this.coverFront.width * this.coverFront.length) /
        ((this.item.specWidth - this.cashmilonStyleOverlap) * this.item.specLength);

      this.itemAC.push(this.item);
    }

    // 뒷면
    if (this.coverBack != null) {
      this.item = ItemCashmilon.getNewDesignItemBySample(this, this.cashmilonSample);

      this.item.purpose = `마구리 피복: ${this.coverBack.purpose}`;
      // 면적계산 설치
      this.item.specWidth = this.cashmilonSample.specWidth;
      this.item.specLength = this.cashmilonSample.specLength;
      this.item.designQuantity =
        (this.coverBack.width * this.coverBack.length) /
        ((this.item.specWidth - this.cashmilonStyleOverlap) * this.item.specLength);

      this.itemAC.push(this.item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);

    /// //////// 외부 ///////////

    // 알고리즘 호출: 피복 파트
    (<CoverLevel>this.level).callAlgorithm_coverPartByCover();

    // 알고리즘 호출: 피복 고정 파트
    (<CoverLevel>this.level).callAlgorithm_fixingPartByCover();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 아이템 <- 샘플
   */
  public algorithmSpec_item(): void {
    for (const item of this.itemAC as ItemCashmilon[]) {
      item.specsList = this.cashmilonSample.specsList;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
