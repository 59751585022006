import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPartIL_Rafter } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Rafter";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { EndpieceSampleIL_CylinderMiddleBottomPipe } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceSampleIL_CylinderMiddleBottomPipe";
import { EndpieceSampleIL_CylinderMiddleUpperPipe } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceSampleIL_CylinderMiddleUpperPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-24
 */
@jsonObject({
  knownTypes: [EndpieceSampleIL_CylinderMiddleBottomPipe, EndpieceSampleIL_CylinderMiddleUpperPipe],
})
export class EndpiecePartIL_CylinderMiddle extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public middleUpperPipeSample: EndpieceSampleIL_CylinderMiddleUpperPipe; // 마구리 중방(상단) 파이프 샘플
  public middleBottomPipeSample: EndpieceSampleIL_CylinderMiddleBottomPipe; // 마구리 중방(하단) 파이프 샘플

  // 아이템
  public middleUpper_pipe: ItemPipe;
  public middleUpperAndRafter_diagonalTHolder: ItemHolder;
  public middleUpperAndRafterEndpiece_normalHolder: ItemHolder;
  public middleUpperAndRafterRafter_normalHolder: ItemHolder;
  public middleUpperAndRafter_boltAndNut: ItemFrameOther;
  public middleUpperAndEndpieceStud_saddleHolder: ItemHolder;
  public middleBottom_pipe: ItemPipe;
  public middleBottomAndMiddleBottom_connectionPin: ItemConnector;
  public middleBottomAndColumn_saddleHolder: ItemHolder;
  public middleBottomAndEndpieceStud_saddleHolder: ItemHolder;
  public middle_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  public middleBottomSelected: boolean; // 중방 하단 선택

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _middleLength: number; // 중방 길이
  @jsonMember(Number)
  public _middleUpperLineNumber: number; // 중방 상단 줄수
  @jsonMember(Number)
  public _middleBottomLineNumber: number; // 중방 하단 줄수

  /**
   * 중방 길이
   */
  public get middleLength(): number {
    return this._middleLength;
  }

  //
  public set middleLength(value: number) {
    this._middleLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 상단 줄수
   */
  public get middleUpperLineNumber(): number {
    return this._middleUpperLineNumber;
  }

  //
  public set middleUpperLineNumber(value: number) {
    this._middleUpperLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 위치
    this.algorithm_middleUpperLineNumberByPosition();
  }

  /**
   * 중방 하단 줄수
   */
  public get middleBottomLineNumber(): number {
    return this._middleBottomLineNumber;
  }

  //
  public set middleBottomLineNumber(value: number) {
    this._middleBottomLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 위치
    this.algorithm_middleBottomLineNumberByPosition();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.middleUpperPipeSample = new EndpieceSampleIL_CylinderMiddleUpperPipe();
    this.middleBottomPipeSample = new EndpieceSampleIL_CylinderMiddleBottomPipe();

    this.sampleAC = [this.middleUpperPipeSample, this.middleBottomPipeSample];

    // 아이템
    this.middleUpper_pipe = new ItemPipe();
    this.middleUpperAndRafter_diagonalTHolder = new ItemHolder();
    this.middleUpperAndRafterEndpiece_normalHolder = new ItemHolder();
    this.middleUpperAndRafterRafter_normalHolder = new ItemHolder();
    this.middleUpperAndRafter_boltAndNut = new ItemFrameOther();
    this.middleUpperAndEndpieceStud_saddleHolder = new ItemHolder();
    this.middleBottom_pipe = new ItemPipe();
    this.middleBottomAndMiddleBottom_connectionPin = new ItemConnector();
    this.middleBottomAndColumn_saddleHolder = new ItemHolder();
    this.middleBottomAndEndpieceStud_saddleHolder = new ItemHolder();

    this.itemAC = [
      this.middleUpper_pipe,
      this.middleUpperAndRafter_diagonalTHolder,
      this.middleUpperAndRafterEndpiece_normalHolder,
      this.middleUpperAndRafterRafter_normalHolder,
      this.middleUpperAndRafter_boltAndNut,
      this.middleUpperAndEndpieceStud_saddleHolder,
      this.middleBottom_pipe,
      this.middleBottomAndMiddleBottom_connectionPin,
      this.middleBottomAndColumn_saddleHolder,
      this.middleBottomAndEndpieceStud_saddleHolder,
      this.middle_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.middleUpperPipeSample = <EndpieceSampleIL_CylinderMiddleUpperPipe>this.sampleAC[0];
    this.middleBottomPipeSample = <EndpieceSampleIL_CylinderMiddleBottomPipe>this.sampleAC[1];

    // 아이템
    this.middleUpper_pipe = <ItemPipe>this.itemAC[0];
    this.middleUpperAndRafter_diagonalTHolder = <ItemHolder>this.itemAC[1];
    this.middleUpperAndRafterEndpiece_normalHolder = <ItemHolder>this.itemAC[2];
    this.middleUpperAndRafterRafter_normalHolder = <ItemHolder>this.itemAC[3];
    this.middleUpperAndRafter_boltAndNut = <ItemFrameOther>this.itemAC[4];
    this.middleUpperAndEndpieceStud_saddleHolder = <ItemHolder>this.itemAC[5];
    this.middleBottom_pipe = <ItemPipe>this.itemAC[6];
    this.middleBottomAndMiddleBottom_connectionPin = <ItemConnector>this.itemAC[7];
    this.middleBottomAndColumn_saddleHolder = <ItemHolder>this.itemAC[8];
    this.middleBottomAndEndpieceStud_saddleHolder = <ItemHolder>this.itemAC[9];
    this.middle_screw = <ItemScrew>this.itemAC[10];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param middleUpperLineNumber: number 중방 상단 줄수
   * @param middleBottomLineNumber: number 중방 하단 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    middleUpperLineNumber: number = 0,
    middleBottomLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._middleUpperLineNumber = middleUpperLineNumber;
    this._middleBottomLineNumber = middleBottomLineNumber;

    // 샘플
    if (this.level.index === 0) {
      this.middleUpperPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.middleBottomPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(하단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
    } else {
      this.middleUpperPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(상단)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.middleBottomPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(하단)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index === 0) {
      this.middleUpper_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.middleUpperAndRafter_diagonalTHolder.setDefaultData(
        1,
        CONST.ITEM_ID_DIAGONAL_T_HOLDER,
        CONST.ITEM_NAME_DIAGONAL_T_HOLDER,
        "마구리 중방(상단) + 서까래",
        "원형, 일반, 32mm, 32mm",
      );
      this.middleUpperAndRafterEndpiece_normalHolder.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 중방(상단) + 서까래 (1)",
        "원형, 일반, 32mm, -",
      );
      this.middleUpperAndRafterRafter_normalHolder.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 중방(상단) + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.middleUpperAndRafter_boltAndNut.setDefaultData(
        4,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 중방(상단) + 서까래",
        "일반, 8mm",
      );
      this.middleUpperAndEndpieceStud_saddleHolder.setDefaultData(
        5,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방(상단) + 마구리 샛기둥",
        "원형, 일반, 32mm, -",
      );
      this.middleBottom_pipe.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(하단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.middleBottomAndMiddleBottom_connectionPin.setDefaultData(
        7,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "마구리 중방(하단) + 마구리 중방(하단)",
        "원형, 일반, 32mm, 32mm",
      );
      this.middleBottomAndColumn_saddleHolder.setDefaultData(
        8,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방(하단) + 기둥",
        "원형, 일반, 32mm, -",
      );
      this.middleBottomAndEndpieceStud_saddleHolder.setDefaultData(
        9,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방(하단) + 마구리 샛기둥",
        "원형, 일반, 32mm, -",
      );
      this.middle_screw.setDefaultData(
        10,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 중방",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    } else {
      this.middleUpper_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(상단)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.middleUpperAndRafter_diagonalTHolder.setDefaultData(
        1,
        CONST.ITEM_ID_DIAGONAL_T_HOLDER,
        CONST.ITEM_NAME_DIAGONAL_T_HOLDER,
        "마구리 중방(상단) + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.middleUpperAndRafterEndpiece_normalHolder.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 중방(상단) + 서까래 (1)",
        "원형, 일반, 25mm, -",
      );
      this.middleUpperAndRafterRafter_normalHolder.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 중방(상단) + 서까래 (2)",
        "원형, 일반, 25mm, -",
      );
      this.middleUpperAndRafter_boltAndNut.setDefaultData(
        4,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 중방(상단) + 서까래",
        "일반, 8mm",
      );
      this.middleUpperAndEndpieceStud_saddleHolder.setDefaultData(
        5,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방(상단) + 마구리 샛기둥",
        "원형, 일반, 25mm, -",
      );
      this.middleBottom_pipe.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(하단)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.middleBottomAndMiddleBottom_connectionPin.setDefaultData(
        7,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "마구리 중방(하단) + 마구리 중방(하단)",
        "원형, 일반, 25mm, 25mm",
      );
      this.middleBottomAndColumn_saddleHolder.setDefaultData(
        8,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방(하단) + 기둥",
        "원형, 일반, 25mm, -",
      );
      this.middleBottomAndEndpieceStud_saddleHolder.setDefaultData(
        9,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방(하단) + 마구리 샛기둥",
        "원형, 일반, 25mm, -",
      );
      this.middle_screw.setDefaultData(
        10,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 중방",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.algorithm_middleBottomSelected();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 12 && (<ItemScrew>this.itemAC[2]).productId === CONST.ITEM_ID_SCREW) {
      // 나사 하나로 합치기
      let middle_screw_designQuantity: number;
      middle_screw_designQuantity =
        (<ItemScrew>this.itemAC[2]).getSelectedQuantity() +
        (<ItemScrew>this.itemAC[4]).getSelectedQuantity() +
        (<ItemScrew>this.itemAC[7]).getSelectedQuantity() +
        (<ItemScrew>this.itemAC[9]).getSelectedQuantity() +
        (<ItemScrew>this.itemAC[11]).getSelectedQuantity();
      const middle_screw: ItemScrew = <ItemScrew>this.itemAC[11];
      middle_screw.selected = middle_screw_designQuantity > 0;
      middle_screw.visible = middle_screw_designQuantity > 0;
      middle_screw.purpose = "마구리 중방";
      middle_screw.designQuantity = middle_screw_designQuantity;
      this.itemAC.splice(9, 1);
      this.itemAC.splice(7, 1);
      this.itemAC.splice(4, 1);
      this.itemAC.splice(2, 1);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 8 && (<ItemHolder>this.itemAC[2]).productId === CONST.ITEM_ID_SADDLE_HOLDER) {
      // [마구리 중방(상단) + 서까래 (1)] 일반 고정구
      const middleUpperAndRafterEndpiece_normalHolder: ItemHolder = new ItemHolder();
      middleUpperAndRafterEndpiece_normalHolder.setAssociation(design, struct, work, level, position, this);
      middleUpperAndRafterEndpiece_normalHolder.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 중방(상단) + 서까래 (1)",
        "원형, 일반, 32mm, -",
      );
      middleUpperAndRafterEndpiece_normalHolder.selected = false;
      middleUpperAndRafterEndpiece_normalHolder.visible = false;
      this.itemAC.splice(2, 0, middleUpperAndRafterEndpiece_normalHolder);
      // [마구리 중방(상단) + 서까래 (2)] 일반 고정구
      const middleUpperAndRafterRafter_normalHolder: ItemHolder = new ItemHolder();
      middleUpperAndRafterRafter_normalHolder.setAssociation(design, struct, work, level, position, this);
      middleUpperAndRafterRafter_normalHolder.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 중방(상단) + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      middleUpperAndRafterRafter_normalHolder.selected = false;
      middleUpperAndRafterRafter_normalHolder.visible = false;
      this.itemAC.splice(3, 0, middleUpperAndRafterRafter_normalHolder);
      // [마구리 중방(상단) + 서까래] 볼트&너트
      const middleUpperAndRafter_boltAndNut: ItemFrameOther = new ItemFrameOther();
      middleUpperAndRafter_boltAndNut.setAssociation(design, struct, work, level, position, this);
      middleUpperAndRafter_boltAndNut.setDefaultData(
        4,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 중방(상단) + 서까래",
        "일반, 8mm",
      );
      middleUpperAndRafter_boltAndNut.selected = false;
      middleUpperAndRafter_boltAndNut.visible = false;
      this.itemAC.splice(4, 0, middleUpperAndRafter_boltAndNut);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_middleLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const endpiecePosition: EndpiecePositionIL = <EndpiecePositionIL>this.position;
    // 외부
    const roofPart_Rafter: RoofPartIL_Rafter = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index])
      .roofPosition.rafterPart;

    /// //////// 선택, 가시성 ///////////

    this.middle_screw.selected = false;
    this.middle_screw.visible = false;
    // 상단
    if (this.middleUpperLineNumber !== 0) {
      this.middleUpper_pipe.selected = true;
      this.middleUpper_pipe.visible = true;
      // 대각 : 대각T 고정구(25/25mm,32/32mm) vs. 일반 고정구
      // 하드코딩
      if (
        this.design.preference.endpieceDiagonalTholderFlag === true &&
        this.middleUpper_pipe.specCrossSize === roofPart_Rafter.rafterPipeSample.specCrossSize &&
        (this.middleUpper_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
          this.middleUpper_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)
      ) {
        this.middleUpperAndRafter_diagonalTHolder.selected = true;
        this.middleUpperAndRafter_diagonalTHolder.visible = true;
        this.middleUpperAndRafterEndpiece_normalHolder.selected = false;
        this.middleUpperAndRafterEndpiece_normalHolder.visible = false;
        this.middleUpperAndRafterRafter_normalHolder.selected = false;
        this.middleUpperAndRafterRafter_normalHolder.visible = false;
        this.middleUpperAndRafter_boltAndNut.selected = false;
        this.middleUpperAndRafter_boltAndNut.visible = false;
      } else {
        this.middleUpperAndRafter_diagonalTHolder.selected = false;
        this.middleUpperAndRafter_diagonalTHolder.visible = false;
        this.middleUpperAndRafterEndpiece_normalHolder.selected = true;
        this.middleUpperAndRafterEndpiece_normalHolder.visible = true;
        this.middleUpperAndRafterRafter_normalHolder.selected = true;
        this.middleUpperAndRafterRafter_normalHolder.visible = true;
        this.middleUpperAndRafter_boltAndNut.selected = true;
        this.middleUpperAndRafter_boltAndNut.visible = true;
      }
      this.middleUpperAndEndpieceStud_saddleHolder.selected = true;
      this.middleUpperAndEndpieceStud_saddleHolder.visible = true;
      this.middle_screw.selected = true;
      this.middle_screw.visible = true;
    } else {
      this.middleUpper_pipe.selected = false;
      this.middleUpper_pipe.visible = false;
      this.middleUpperAndRafter_diagonalTHolder.selected = false;
      this.middleUpperAndRafter_diagonalTHolder.visible = false;
      this.middleUpperAndRafterEndpiece_normalHolder.selected = false;
      this.middleUpperAndRafterEndpiece_normalHolder.visible = false;
      this.middleUpperAndRafterRafter_normalHolder.selected = false;
      this.middleUpperAndRafterRafter_normalHolder.visible = false;
      this.middleUpperAndRafter_boltAndNut.selected = false;
      this.middleUpperAndRafter_boltAndNut.visible = false;
      this.middleUpperAndEndpieceStud_saddleHolder.selected = false;
      this.middleUpperAndEndpieceStud_saddleHolder.visible = false;
    }
    // 하단
    if (endpiecePosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      this.middleBottom_pipe.selected = false;
      this.middleBottom_pipe.visible = false;
      this.middleBottomAndMiddleBottom_connectionPin.selected = false;
      this.middleBottomAndMiddleBottom_connectionPin.visible = false;
      this.middleBottomAndColumn_saddleHolder.selected = false;
      this.middleBottomAndColumn_saddleHolder.visible = false;
      this.middleBottomAndEndpieceStud_saddleHolder.selected = false;
      this.middleBottomAndEndpieceStud_saddleHolder.visible = false;
    } else {
      if (this.middleBottomLineNumber !== 0) {
        this.middleBottom_pipe.selected = true;
        this.middleBottom_pipe.visible = true;
        this.middleBottomAndMiddleBottom_connectionPin.selected = true;
        this.middleBottomAndMiddleBottom_connectionPin.visible = true;
        this.middleBottomAndColumn_saddleHolder.selected = true;
        this.middleBottomAndColumn_saddleHolder.visible = true;
        this.middleBottomAndEndpieceStud_saddleHolder.selected = true;
        this.middleBottomAndEndpieceStud_saddleHolder.visible = true;
        this.middle_screw.selected = true;
        this.middle_screw.visible = true;
      } else {
        this.middleBottom_pipe.selected = false;
        this.middleBottom_pipe.visible = false;
        this.middleBottomAndMiddleBottom_connectionPin.selected = false;
        this.middleBottomAndMiddleBottom_connectionPin.visible = false;
        this.middleBottomAndColumn_saddleHolder.selected = false;
        this.middleBottomAndColumn_saddleHolder.visible = false;
        this.middleBottomAndEndpieceStud_saddleHolder.selected = false;
        this.middleBottomAndEndpieceStud_saddleHolder.visible = false;
      }
    }

    /// //////// 수량 ///////////

    this.middleUpper_pipe.designQuantity =
      ((this.basicLevel.width * this.middleUpperLineNumber * this.basic.buildingNumber) /
        this.middleUpper_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE;

    this.middleUpperAndRafter_diagonalTHolder.designQuantity =
      this.middleUpperLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_DIAGONAL_T_HOLDER;

    this.middleUpperAndRafterEndpiece_normalHolder.designQuantity =
      this.middleUpperLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.middleUpperAndRafterRafter_normalHolder.designQuantity =
      this.middleUpperLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.middleUpperAndRafter_boltAndNut.designQuantity =
      this.middleUpperAndRafterEndpiece_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.middleUpperAndEndpieceStud_saddleHolder.designQuantity =
      endpiecePosition.cylinderStudPart.studLineNumber *
      this.middleUpperLineNumber *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.middleBottom_pipe.designQuantity =
      (this.middleBottom_pipe.getConnectedPipeLength(this.middleLength, this.middleBottomLineNumber) /
        this.middleBottom_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE;

    if (this.middleLength > this.middleBottomPipeSample.specLength) {
      this.middleBottomAndMiddleBottom_connectionPin.designQuantity =
        this.middleBottom_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.middleBottomAndMiddleBottom_connectionPin.designQuantity = 0;
    }

    this.middleBottomAndColumn_saddleHolder.designQuantity =
      this.middleBottomLineNumber * (this.basic.buildingNumber + 1);

    this.middleBottomAndEndpieceStud_saddleHolder.designQuantity =
      endpiecePosition.cylinderStudPart.studLineNumber * this.middleBottomLineNumber * this.basic.buildingNumber;

    this.middle_screw.designQuantity =
      ((this.middleUpperAndRafter_diagonalTHolder.getSelectedQuantity() * 3 +
        this.middleUpperAndEndpieceStud_saddleHolder.getSelectedQuantity() * 3 +
        this.middleBottomAndMiddleBottom_connectionPin.getSelectedQuantity() * 2 +
        this.middleBottomAndColumn_saddleHolder.getSelectedQuantity() * 3 +
        this.middleBottomAndEndpieceStud_saddleHolder.getSelectedQuantity() * 3) /
        this.middle_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 중방 길이 <- 폭(기본정보), 동수(기본정보)
   */
  public algorithm_middleLength(): void {
    this.middleLength =
      this.basicLevel.width * this.basic.buildingNumber + this.design.preference.connectedPipeExtraLength;
  }

  /**
   * 알고리즘: 중방 상단 줄수 <- 중방 상단 줄수(위치/앞면)
   */
  public algorithm_middleUpperLineNumberByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (this.position.type === (<EndpieceLevelIL>this.level).backPosition.type) {
        (<EndpieceLevelIL>this.level).backPosition.cylinderMiddlePart.middleUpperLineNumber =
          this.middleUpperLineNumber;
      }
    }
  }

  /**
   * 알고리즘: 중방 하단 줄수 <- 중방 하단 줄수(위치/앞면)
   */
  public algorithm_middleBottomLineNumberByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (this.position.type === (<EndpieceLevelIL>this.level).backPosition.type) {
        (<EndpieceLevelIL>this.level).backPosition.cylinderMiddlePart.middleBottomLineNumber =
          this.middleBottomLineNumber;
      }
    }
  }

  /**
   * 알고리즘: 중방 하단 선택 <- 형태(위치)
   */
  public algorithm_middleBottomSelected(): void {
    if (this.position.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      this.middleBottomSelected = false;
    } else {
      this.middleBottomSelected = true;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [마구리 중방(상단)] 파이프 <- 마구리 중방(상단) 파이프 샘플
   */
  public algorithmSpec_middleUpper_pipe(): void {
    this.middleUpper_pipe.specs = this.middleUpperPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 서까래] 대각T 고정구 <- 마구리 중방(상단) 파이프 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_middleUpperAndRafter_diagonalTHolder(): void {
    this.middleUpperAndRafter_diagonalTHolder.specPipeType = this.middleUpperPipeSample.specPipeType;
    this.middleUpperAndRafter_diagonalTHolder.specCrossSize1 = this.middleUpperPipeSample.specCrossSize;
    this.middleUpperAndRafter_diagonalTHolder.specCrossSize2 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 서까래 (1)] 일반 고정구 <- 마구리 중방(상단) 파이프 샘플
   */
  public algorithmSpec_middleUpperAndRafterEndpiece_normalHolder(): void {
    this.middleUpperAndRafterEndpiece_normalHolder.specPipeType = this.middleUpperPipeSample.specPipeType;
    this.middleUpperAndRafterEndpiece_normalHolder.specCrossSize1 = this.middleUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플
   */
  public algorithmSpec_middleUpperAndRafterRafter_normalHolder(): void {
    this.middleUpperAndRafterRafter_normalHolder.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.middleUpperAndRafterRafter_normalHolder.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_middleUpperAndRafter_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 마구리 샛기둥] 새들 고정구 <- 마구리 중방(상단) 파이프 샘플
   */
  public algorithmSpec_middleUpperAndEndpieceStud_saddleHolder(): void {
    this.middleUpperAndEndpieceStud_saddleHolder.specPipeType = this.middleUpperPipeSample.specPipeType;
    this.middleUpperAndEndpieceStud_saddleHolder.specCrossSize1 = this.middleUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단)] 파이프 <- 마구리 중방(하단) 파이프 샘플
   */
  public algorithmSpec_middleBottom_pipe(): void {
    this.middleBottom_pipe.specs = this.middleBottomPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단) + 마구리 중방(하단)] 연결핀 <- 마구리 중방(하단) 파이프 샘플
   */
  public algorithmSpec_middleBottomAndMiddleBottom_connectionPin(): void {
    this.middleBottomAndMiddleBottom_connectionPin.specPipeType = this.middleBottomPipeSample.specPipeType;
    this.middleBottomAndMiddleBottom_connectionPin.specCrossSize1 = this.middleBottomPipeSample.specCrossSize;
    this.middleBottomAndMiddleBottom_connectionPin.specCrossSize2 = this.middleBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단) + 기둥] 새들 고정구 <- 마구리 중방(하단) 파이프 샘플
   */
  public algorithmSpec_middleBottomAndColumn_saddleHolder(): void {
    this.middleBottomAndColumn_saddleHolder.specPipeType = this.middleBottomPipeSample.specPipeType;
    this.middleBottomAndColumn_saddleHolder.specCrossSize1 = this.middleBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단) + 마구리 샛기둥] 새들 고정구 <- 마구리 중방(하단) 파이프 샘플
   */
  public algorithmSpec_middleBottomAndEndpieceStud_saddleHolder(): void {
    this.middleBottomAndEndpieceStud_saddleHolder.specPipeType = this.middleBottomPipeSample.specPipeType;
    this.middleBottomAndEndpieceStud_saddleHolder.specCrossSize1 = this.middleBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방] 나사 <- 공통 샘플
   */
  public algorithmSpec_middle_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
