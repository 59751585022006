import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SwitcherPartIL_Axis } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Axis";
import { SwitcherPartIL_Power } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Power";
import { SwitcherPartIL_FixingNormalClip } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_FixingNormalClip";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject
export class SwitcherSampleIL_AxisPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const axisPart: SwitcherPartIL_Axis = <SwitcherPartIL_Axis>this.part;
    axisPart.algorithmSpec_axis_pipe();
    axisPart.algorithmSpec_axis_connectionPin();
    axisPart.algorithmSpec_axis_screw();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const power1Part: SwitcherPartIL_Power = (<SwitcherPositionIL>this.position).power1Part;
    power1Part.powerSwitcherSample.algorithmSpec();

    const fixingClip: SwitcherPartIL_FixingNormalClip = (<SwitcherPositionIL>this.position).fixingNormalClipPart;
    fixingClip.normalClipSample.algorithmSpec();

    // 다른 위치
    if (this.position.index === 0) {
      for (let p: number = 1; p < this.level.positionAC.length; p++) {
        (<SwitcherPositionIL>this.level.positionAC[p]).axisPart.axisPipeSample.specs = this.specs;
      }
    }

    // 다른 중수
    for (let l: number = this.level.index; l < this.work.levelAC.length; l++) {
      // 어떤 중수든 보다 큰 중수에 영향을 줌
      (<SwitcherPositionIL>this.work.levelAC[l].positionAC[this.position.index]).axisPart.axisPipeSample.specs =
        this.specs;
    }

    /// //////// 외부 ///////////

    // 천창 개폐
    if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.struct.skySwitchWorkIL.level1.skylightPosition.axisPart.axisPipeSample.algorithmSpec();
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
