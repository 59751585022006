import { jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { BrandCompanyModel } from "vhows-design/src/object/item/brand/BrandCompanyModel";
import { OptionSpecModel } from "vhows-design/src/object/item/spec/option/OptionSpecModel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-26
 */
@jsonObject({
  knownTypes: [OptionSpecModel],
})
export class OptionUnitPriceModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 조직별 상표 모델 */
  public brandCompanyModel: BrandCompanyModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 옵션 규격 모델 */
  @jsonMember(OptionSpecModel)
  public optionSpecModel: OptionSpecModel = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 옵션 단가 (공급대가) */
  @jsonMember(Number)
  public optionUnitPrice: number = 0;
  /** 옵션 단가 (공급가액) */
  @jsonMember(Number)
  public optionUnitSupply: number = 0;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(brandCompanyModel: BrandCompanyModel): void {
    this.brandCompanyModel = brandCompanyModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
