import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-28
 */
@jsonObject({
  knownTypes: [],
})
export class GutterPartIL_FixingScrew extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플

  // 아이템
  public screw_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    // 아이템
    this.screw_screw = new ItemScrew();

    this.itemAC = [this.screw_screw];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 아이템
    this.screw_screw = <ItemScrew>this.itemAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index === 0) {
    } else {
    }

    // 아이템
    if (this.level.index === 0) {
      this.screw_screw.setDefaultData(
        0,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "나사",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    } else {
      this.screw_screw.setDefaultData(
        0,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "나사",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.screw_screw.selected = true;
    this.screw_screw.visible = true;

    /// //////// 수량 ///////////

    if (this.level.index === 0) {
      if (
        this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        this.screw_screw.designQuantity =
          (((this.basicLevel.length / 0.6) * 2 * (this.basic.buildingNumber - 1)) / this.screw_screw.specUnitQuantity) *
          CONST.NUM_EXTRA_RATE_SCREW;
      } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
        const rafterValleyQuantity: number = this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterValleyQuantity;
        this.screw_screw.designQuantity =
          (((this.basicLevel.length / 0.6) * 2 * (this.basic.buildingNumber * rafterValleyQuantity - 1)) /
            this.screw_screw.specUnitQuantity) *
          CONST.NUM_EXTRA_RATE_SCREW;
      }
    } else {
      this.screw_screw.designQuantity =
        (((this.basicLevel.length / 2) * 2 * 2 * this.basic.buildingNumber) / this.screw_screw.specUnitQuantity) *
        CONST.NUM_EXTRA_RATE_SCREW;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [나사] 나사 <- 공통 샘플
   */
  public algorithmSpec_screw_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
