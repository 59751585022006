import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { EndpiecePosition } from "vhows-design/src/object/design/frame/endpiece/EndpiecePosition";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { EndpieceSample_MiddlePipe } from "vhows-design/src/object/design/frame/endpiece/EndpieceSample_MiddlePipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-04-01
 */
@jsonObject({
  knownTypes: [EndpieceSample_MiddlePipe],
})
export class EndpiecePart_Middle extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public middlePipeSample: EndpieceSample_MiddlePipe; // 중방 파이프 샘플

  // 아이템
  public middle_pipe: ItemPipe;
  public middleAndRafter_saddleHolder: ItemHolder;
  public middleAndEndpieceStud_saddleHolder: ItemHolder;
  public middle_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _middleLength: number; // 중방 길이
  @jsonMember(Number)
  public _middleLineNumber: number; // 중방 줄수

  /**
   * 중방 길이
   */
  public get middleLength(): number {
    return this._middleLength;
  }

  //
  public set middleLength(value: number) {
    this._middleLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 줄수
   */
  public get middleLineNumber(): number {
    return this._middleLineNumber;
  }

  //
  public set middleLineNumber(value: number) {
    this._middleLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 위치
    this.algorithm_middleLineNumberByPosition();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.middlePipeSample = new EndpieceSample_MiddlePipe();

    this.sampleAC = [this.middlePipeSample];

    // 아이템
    this.middle_pipe = new ItemPipe();
    this.middleAndRafter_saddleHolder = new ItemHolder();
    this.middleAndEndpieceStud_saddleHolder = new ItemHolder();
    this.middle_screw = new ItemScrew();

    this.itemAC = [
      this.middle_pipe,
      this.middleAndRafter_saddleHolder,
      this.middleAndEndpieceStud_saddleHolder,
      this.middle_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.middlePipeSample = <EndpieceSample_MiddlePipe>this.sampleAC[0];

    // 아이템
    this.middle_pipe = <ItemPipe>this.itemAC[0];
    this.middleAndRafter_saddleHolder = <ItemHolder>this.itemAC[1];
    this.middleAndEndpieceStud_saddleHolder = <ItemHolder>this.itemAC[2];
    this.middle_screw = <ItemScrew>this.itemAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param middleLineNumber: number 중방 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    middleLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._middleLineNumber = middleLineNumber;

    // 샘플
    if (this.level.index === 0) {
      this.middlePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    } else {
      this.middlePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방",
        "원형, 일반, 22.2mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index === 0) {
      this.middle_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.middleAndRafter_saddleHolder.setDefaultData(
        1,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방 + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.middleAndEndpieceStud_saddleHolder.setDefaultData(
        2,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방 + 마구리 샛기둥",
        "원형, 일반, 25mm, -",
      );
      this.middle_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 중방",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    } else {
      this.middle_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방",
        "원형, 일반, 22.2mm, 1.2T, 10m, -",
      );
      this.middleAndRafter_saddleHolder.setDefaultData(
        1,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방 + 서까래",
        "원형, 일반, 22mm, -",
      );
      this.middleAndEndpieceStud_saddleHolder.setDefaultData(
        2,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방 + 마구리 샛기둥",
        "원형, 일반, 22mm, -",
      );
      this.middle_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 중방",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 5) {
      // 나사 하나로 합치기
      let middle_screw_designQuantity: number;
      middle_screw_designQuantity =
        (<ItemScrew>this.itemAC[2]).getSelectedQuantity() + (<ItemScrew>this.itemAC[4]).getSelectedQuantity();
      const middle_screw: ItemScrew = <ItemScrew>this.itemAC[4];
      middle_screw.selected = middle_screw_designQuantity > 0;
      middle_screw.visible = middle_screw_designQuantity > 0;
      middle_screw.purpose = "마구리 중방";
      middle_screw.designQuantity = middle_screw_designQuantity;
      this.itemAC.splice(2, 1);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_middleLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const endpiecePosition: EndpiecePosition = <EndpiecePosition>this.position;

    /// //////// 선택, 가시성 ///////////

    this.middle_pipe.selected = true;
    this.middle_pipe.visible = true;
    this.middleAndRafter_saddleHolder.selected = true;
    this.middleAndRafter_saddleHolder.visible = true;
    this.middleAndEndpieceStud_saddleHolder.selected = true;
    this.middleAndEndpieceStud_saddleHolder.visible = true;
    this.middle_screw.selected = true;
    this.middle_screw.visible = true;

    /// //////// 수량 ///////////

    this.middle_pipe.designQuantity =
      ((this.middleLength * this.middleLineNumber * this.basic.buildingNumber) / this.middlePipeSample.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE;

    this.middleAndRafter_saddleHolder.designQuantity =
      this.middleLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.middleAndEndpieceStud_saddleHolder.designQuantity =
      endpiecePosition.studPart.studLineNumber *
      this.middleLineNumber *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.middle_screw.designQuantity =
      ((this.middleAndRafter_saddleHolder.getSelectedQuantity() * 3 +
        this.middleAndEndpieceStud_saddleHolder.getSelectedQuantity() * 3) /
        this.middle_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 중방 길이 <- 폭(기본정보)
   */
  public algorithm_middleLength(): void {
    this.middleLength = this.basicLevel.width;
  }

  /**
   * 알고리즘: 중방 줄수 <- 형태(위치)
   */
  public algorithm_middleLineNumber(): void {
    if (this.position.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
      this.middleLineNumber = 0;
    } else if (this.position.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      this.middleLineNumber = 1;
    }
  }

  /**
   * 알고리즘: 중방 줄수 <- 중방 줄수(위치/앞면)
   */
  public algorithm_middleLineNumberByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (this.position.type === (<EndpieceLevel>this.level).backPosition.type) {
        (<EndpieceLevel>this.level).backPosition.middlePart.middleLineNumber = this.middleLineNumber;
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [마구리 중방] 파이프 <- 마구리 중방 파이프 샘플
   */
  public algorithmSpec_middle_pipe(): void {
    this.middle_pipe.specs = this.middlePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 중방 + 서까래] 새들 고정구 <- 마구리 중방 파이프 샘플
   */
  public algorithmSpec_middleAndRafter_saddleHolder(): void {
    this.middleAndRafter_saddleHolder.specPipeType = this.middlePipeSample.specPipeType;
    this.middleAndRafter_saddleHolder.specCrossSize1 = this.middlePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방 + 마구리 샛기둥] 새들 고정구 <- 마구리 중방 파이프 샘플
   */
  public algorithmSpec_middleAndEndpieceStud_saddleHolder(): void {
    this.middleAndEndpieceStud_saddleHolder.specPipeType = this.middlePipeSample.specPipeType;
    this.middleAndEndpieceStud_saddleHolder.specCrossSize1 = this.middlePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방] 나사 <- 공통 샘플
   */
  public algorithmSpec_middle_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
