import { jsonObject } from "typedjson";

import { ItemTent } from "vhows-design/src/object/design/item/list/ItemTent";
import { CoverPartIL_Tent } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Tent";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-29
 */
@jsonObject
export class CoverSampleIL_Tent extends ItemTent {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const tentPart: CoverPartIL_Tent = <CoverPartIL_Tent>this.part;
    tentPart.algorithmSpec_item();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
