import { jsonObject } from "typedjson";

import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { SwitcherPartIL_FixingNormalClip } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_FixingNormalClip";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject
export class SwitcherSampleIL_NormalClip extends ItemClip {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingNormalClipPart: SwitcherPartIL_FixingNormalClip = <SwitcherPartIL_FixingNormalClip>this.part;
    fixingNormalClipPart.algorithmSpec_normalClip_normalClip();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 개폐기 축 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specCrossSize = (<SwitcherPositionIL>this.position).axisPart.axisPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
