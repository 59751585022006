import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { ColumnLevelIL } from "vhows-design/src/object/design/frame/column/ColumnLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { FixingSampleIL_NormalClip } from "vhows-design/src/object/design/cover/fixing/il/FixingSampleIL_NormalClip";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { ColumnLevelVL } from "vhows-design/src/object/design/frame/column/vl/ColumnLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject({
  knownTypes: [FixingSampleIL_NormalClip],
})
export class FixingPartIL_NormalClip extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalClipSample: FixingSampleIL_NormalClip; // 일반 클립 샘플

  // 아이템
  public normalClip_normalClip: ItemClip;
  public normalClipMiddle_normalClip: ItemClip;
  public normalClipRafter_normalClip: ItemClip;
  public normalClipColumn_normalClip: ItemClip;
  public normalClipGate_normalClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _clipInterval: number; // 클립 간격

  /**
   * 클립 간격
   */
  public get clipInterval(): number {
    return this._clipInterval;
  }

  //
  public set clipInterval(value: number) {
    this._clipInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalClipSample = new FixingSampleIL_NormalClip();

    this.sampleAC = [this.normalClipSample];

    // 아이템
    this.normalClip_normalClip = new ItemClip();
    this.normalClipMiddle_normalClip = new ItemClip();
    this.normalClipRafter_normalClip = new ItemClip();
    this.normalClipColumn_normalClip = new ItemClip();
    this.normalClipGate_normalClip = new ItemClip();

    this.itemAC = [
      this.normalClip_normalClip,
      this.normalClipMiddle_normalClip,
      this.normalClipRafter_normalClip,
      this.normalClipColumn_normalClip,
      this.normalClipGate_normalClip,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalClipSample = <FixingSampleIL_NormalClip>this.sampleAC[0];

    // 아이템
    this.normalClip_normalClip = <ItemClip>this.itemAC[0];
    this.normalClipMiddle_normalClip = <ItemClip>this.itemAC[1];
    this.normalClipRafter_normalClip = <ItemClip>this.itemAC[2];
    this.normalClipColumn_normalClip = <ItemClip>this.itemAC[3];
    this.normalClipGate_normalClip = <ItemClip>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param clipInterval: number 클립 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    clipInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._clipInterval = clipInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.normalClipSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
    }

    // 아이템
    if (this.level.index === 0) {
      this.normalClip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
      this.normalClipMiddle_normalClip.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(중방방향)",
        "플라스틱, 25mm",
      );
      this.normalClipRafter_normalClip.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(서까래쪽)",
        "플라스틱, 32mm",
      );
      this.normalClipColumn_normalClip.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(기둥쪽)",
        "플라스틱, 48mm",
      );
      this.normalClipGate_normalClip.setDefaultData(
        4,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(문주위)",
        "플라스틱, 48mm",
      );
    } else {
      this.normalClip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
      this.normalClipMiddle_normalClip.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(중방방향)",
        "플라스틱, 25mm",
      );
      this.normalClipRafter_normalClip.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(서까래쪽)",
        "플라스틱, 25mm",
      );
      this.normalClipColumn_normalClip.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(기둥쪽)",
        "플라스틱, 48mm",
      );
      this.normalClipGate_normalClip.setDefaultData(
        4,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립(문주위)",
        "플라스틱, 32mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const fixingPosition: FixingPositionIL = <FixingPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;
    // 외부
    let roofPosition: RoofPositionIL | RoofPositionVL;
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPosition = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }

    /// //////// 선택, 가시성 ///////////

    if (
      fixingPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      fixingPosition.label === CONST.LB_POSITION_SIDE_WING ||
      fixingPosition.label === CONST.LB_POSITION_ROOF
    ) {
      this.normalClip_normalClip.selected = true;
      this.normalClip_normalClip.visible = true;
      this.normalClipMiddle_normalClip.selected = false;
      this.normalClipMiddle_normalClip.visible = false;
      this.normalClipRafter_normalClip.selected = false;
      this.normalClipRafter_normalClip.visible = false;
      this.normalClipColumn_normalClip.selected = false;
      this.normalClipColumn_normalClip.visible = false;
      this.normalClipGate_normalClip.selected = false;
      this.normalClipGate_normalClip.visible = false;
    } else if (fixingPosition.label === CONST.LB_POSITION_FRONT || fixingPosition.label === CONST.LB_POSITION_BACK) {
      this.normalClip_normalClip.selected = false;
      this.normalClip_normalClip.visible = false;
      // 중방방향
      if (fixingPosition.type === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipMiddle_normalClip.selected = true;
        this.normalClipMiddle_normalClip.visible = true;
      } else {
        this.normalClipMiddle_normalClip.selected = false;
        this.normalClipMiddle_normalClip.visible = false;
      }
      // 서까래쪽
      if (fixingPosition.endpieceRafterType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipRafter_normalClip.selected = true;
        this.normalClipRafter_normalClip.visible = true;
      } else {
        this.normalClipRafter_normalClip.selected = false;
        this.normalClipRafter_normalClip.visible = false;
      }
      // 기둥쪽
      if (fixingPosition.endpieceColumnType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipColumn_normalClip.selected = true;
        this.normalClipColumn_normalClip.visible = true;
      } else {
        this.normalClipColumn_normalClip.selected = false;
        this.normalClipColumn_normalClip.visible = false;
      }
      // 문주위
      if (fixingPosition.endpieceGateType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipGate_normalClip.selected = true;
        this.normalClipGate_normalClip.visible = true;
      } else {
        this.normalClipGate_normalClip.selected = false;
        this.normalClipGate_normalClip.visible = false;
      }
    }

    /// //////// 수량 ///////////

    if (
      fixingPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      fixingPosition.label === CONST.LB_POSITION_SIDE_WING
    ) {
      this.normalClip_normalClip.designQuantity =
        Math.ceil(this.basicLevel.length / this.clipInterval) * fixingPosition.lineNumber;
    } else if (fixingPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          bothEndQuantity = Math.ceil(this.basicLevel.length / this.clipInterval) * 1 * 2;
          centralQuantity =
            Math.ceil(this.basicLevel.length / this.clipInterval) *
            fixingPosition.lineNumber *
            (this.basic.buildingNumber - 2);
          this.normalClip_normalClip.designQuantity = bothEndQuantity + centralQuantity;
        } else {
          this.normalClip_normalClip.designQuantity =
            Math.ceil(this.basicLevel.length / this.clipInterval) *
            fixingPosition.lineNumber *
            this.basic.buildingNumber;
        }
      } else {
        this.normalClip_normalClip.designQuantity =
          Math.ceil(this.basicLevel.length / this.clipInterval) * fixingPosition.lineNumber;
      }
    } else if (fixingPosition.label === CONST.LB_POSITION_FRONT || fixingPosition.label === CONST.LB_POSITION_BACK) {
      if (fixingPosition.type === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipMiddle_normalClip.designQuantity =
          Math.ceil(this.basicLevel.width / this.clipInterval) * fixingPosition.lineNumber * this.basic.buildingNumber;
      }
      if (fixingPosition.endpieceRafterType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipRafter_normalClip.designQuantity =
          Math.ceil(roofPosition.rafterPart.rafterTotalLength / this.clipInterval) *
          fixingPosition.endpieceRafterLineNumber *
          this.basic.buildingNumber;
      }
      if (fixingPosition.endpieceColumnType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipColumn_normalClip.designQuantity =
          Math.ceil(this.basicLevel.heightShoulder / this.clipInterval) * fixingPosition.endpieceColumnLineNumber;
      }
      if (fixingPosition.endpieceGateType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        if (fixingPosition.label === CONST.LB_POSITION_FRONT) {
          this.normalClipGate_normalClip.designQuantity =
            Math.ceil(2.0 / this.clipInterval) *
            fixingPosition.endpieceGateLineNumber *
            endpieceLevel.frontPosition.gateBuildingNumber;
        } else if (fixingPosition.label === CONST.LB_POSITION_BACK) {
          this.normalClipGate_normalClip.designQuantity =
            Math.ceil(2.0 / this.clipInterval) *
            fixingPosition.endpieceGateLineNumber *
            endpieceLevel.backPosition.gateBuildingNumber;
        }
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 클립] 일반 클립 <- 일반 클립 샘플
   */
  public algorithmSpec_normalClip_normalClip(): void {
    this.normalClip_normalClip.specs = this.normalClipSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 클립(중방방향)] 일반 클립 <- 일반 클립 샘플
   */
  public algorithmSpec_normalClipMiddle_normalClip(): void {
    this.normalClipMiddle_normalClip.specs = this.normalClipSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 클립(서까래쪽)] 일반 클립 <- 일반 클립 샘플, 서까래 파이프 샘플
   */
  public algorithmSpec_normalClipRafter_normalClip(): void {
    let roofLevel: RoofLevelIL | RoofLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofLevel = <RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofLevel = this.struct.roofWorkVL.level1;
    }
    this.normalClipRafter_normalClip.specMaterial = this.normalClipSample.specMaterial;
    this.normalClipRafter_normalClip.specCrossSize = roofLevel.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [일반 클립(기둥쪽)] 일반 클립 <- 일반 클립 샘플, 형태(기둥 골조), 기둥 파이프 샘플
   */
  public algorithmSpec_normalClipColumn_normalClip(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const columnLevel: ColumnLevelIL = this.struct.columnWorkIL.level1;
      this.normalClipColumn_normalClip.specMaterial = this.normalClipSample.specMaterial;
      if (columnLevel.columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.normalClipColumn_normalClip.specCrossSize =
          columnLevel.columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      } else if (columnLevel.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.normalClipColumn_normalClip.specCrossSize =
          columnLevel.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const columnLevel: ColumnLevelVL = this.struct.columnWorkVL.level1;
      this.normalClipColumn_normalClip.specMaterial = this.normalClipSample.specMaterial;
      if (columnLevel.columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.normalClipColumn_normalClip.specCrossSize =
          columnLevel.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
      }
    }
  }

  /**
   * 규격 알고리즘: [일반 클립(문주위)] 일반 클립 <- 일반 클립 샘플, 형태(마구리 골조), 마구리 샛기둥(하단) 파이프
   */
  public algorithmSpec_normalClipGate_normalClip(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const endpieceLevel: EndpieceLevelIL = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
      this.normalClipGate_normalClip.specMaterial = this.normalClipSample.specMaterial;
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.normalClipGate_normalClip.specCrossSize =
            endpieceLevel.frontPosition.prismStudSepPart.studBottomPipeSample.specCrossSize;
        } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          this.normalClipGate_normalClip.specCrossSize =
            endpieceLevel.frontPosition.cylinderStudSepPart.studBottomPipeSample.specCrossSize;
        } else {
          this.normalClipGate_normalClip.specCrossSize =
            endpieceLevel.frontPosition.cylinderStudPart.studPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.normalClipGate_normalClip.specCrossSize =
            endpieceLevel.backPosition.prismStudSepPart.studBottomPipeSample.specCrossSize;
        } else if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          this.normalClipGate_normalClip.specCrossSize =
            endpieceLevel.backPosition.cylinderStudSepPart.studBottomPipeSample.specCrossSize;
        } else {
          this.normalClipGate_normalClip.specCrossSize =
            endpieceLevel.backPosition.cylinderStudPart.studPipeSample.specCrossSize;
        }
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const endpieceLevel: EndpieceLevelVL = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
      this.normalClipGate_normalClip.specMaterial = this.normalClipSample.specMaterial;
      if (this.position.label === CONST.LB_POSITION_FRONT) {
        if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.normalClipGate_normalClip.specCrossSize =
            endpieceLevel.frontPosition.endpiecePart.straightBottomPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.normalClipGate_normalClip.specCrossSize =
            endpieceLevel.backPosition.endpiecePart.straightBottomPipeSample.specCrossSize;
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
