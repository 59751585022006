import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemInitDataIF } from "vhows-design/src/object/design/item/ItemInterface";

/**
 * 아이템 초기 데이터
 * @author 이덕형
 * @copyright RUNean Inc.
 */
export const ItemInitData: ItemInitDataIF[] = [
  // 파이프
  {
    productId: CONST.ITEM_ID_PIPE,
    label: CONST.ITEM_NAME_PIPE,
    specs: "원형, 일반, 25.4mm, 1.5T, 10m, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_C_SECTION_STEEL,
    label: CONST.ITEM_NAME_C_SECTION_STEEL,
    specs: "각형, 일반, 60×30mm, 1.8T, 5m, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_GUTTER_BEAM,
    label: CONST.ITEM_NAME_GUTTER_BEAM,
    specs: "거터형, 알루미늄, 100x60mm, 2.0T, 8m, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_ROUND_BAR,
    label: CONST.ITEM_NAME_ROUND_BAR,
    specs: "환봉, 일반, 12mm, -, 6m, -",
    brands: "기본회사",
  },
  // 보조 파이프
  {
    productId: CONST.ITEM_ID_MIDDLE_SUPPORT_PIPE,
    label: CONST.ITEM_NAME_MIDDLE_SUPPORT_PIPE,
    specs: "원형, 일반, 31.8mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_U_BENDING_TUBE_RAIL,
    label: CONST.ITEM_NAME_U_BENDING_TUBE_RAIL,
    specs: "원형, 일반, 40A",
    brands: "기본회사",
  },
  // 철근
  {
    productId: CONST.ITEM_ID_STEEL_BAR,
    label: CONST.ITEM_NAME_STEEL_BAR,
    specs: "이형, D-10",
    brands: "기본회사",
  },
  // 브라켓트
  {
    productId: CONST.ITEM_ID_BRACKET,
    label: CONST.ITEM_NAME_BRACKET,
    specs: "일반, 3T, 40x50mm",
    brands: "기본회사",
  },
  // 연결구
  {
    productId: CONST.ITEM_ID_CONNECTION_PIN,
    label: CONST.ITEM_NAME_CONNECTION_PIN,
    specs: "원형, 일반, 25mm, 25mm",
    brands: "기본회사",
  },
  // 고정구
  {
    productId: CONST.ITEM_ID_NORMAL_HOLDER,
    label: CONST.ITEM_NAME_NORMAL_HOLDER,
    specs: "원형, 일반, 25mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_STEEL_STRING_GRABBER,
    label: CONST.ITEM_NAME_STEEL_STRING_GRABBER,
    specs: "원형, 일반, 32mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_STEEL_SHEET_GRABBER,
    label: CONST.ITEM_NAME_STEEL_SHEET_GRABBER,
    specs: "원형, 일반, 32mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CROSS_STEEL_SHEET_GRABBER,
    label: CONST.ITEM_NAME_CROSS_STEEL_SHEET_GRABBER,
    specs: "원형, 일반, 32mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LINE_SHEET_GRABBER,
    label: CONST.ITEM_NAME_LINE_SHEET_GRABBER,
    specs: "원형, 일반, 32mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_U_CLAMP,
    label: CONST.ITEM_NAME_U_CLAMP,
    specs: "원형, 일반, 32mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SADDLE_HOLDER,
    label: CONST.ITEM_NAME_SADDLE_HOLDER,
    specs: "원형, 일반, 25mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FIX_ASIBA_CLAMP,
    label: CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
    specs: "원형, 일반, 48mm, 48mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_ROTATION_ASIBA_CLAMP,
    label: CONST.ITEM_NAME_ROTATION_ASIBA_CLAMP,
    specs: "원형, 일반, 48mm, 48mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CROSS_CLAMP,
    label: CONST.ITEM_NAME_CROSS_CLAMP,
    specs: "각형, 일반, 60×60mm, 60×60mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_OUTER_WALL_CLAMP,
    label: CONST.ITEM_NAME_OUTER_WALL_CLAMP,
    specs: "원형, 일반, 48mm, 32mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_T_HOLDER,
    label: CONST.ITEM_NAME_T_HOLDER,
    specs: "원형, 일반, 32mm, 32mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_T_CLAMP,
    label: CONST.ITEM_NAME_T_CLAMP,
    specs: "원형, 일반, 32mm, 32mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DIAGONAL_T_HOLDER,
    label: CONST.ITEM_NAME_DIAGONAL_T_HOLDER,
    specs: "원형, 일반, 32mm, 32mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DIAGONAL_T_CLAMP,
    label: CONST.ITEM_NAME_DIAGONAL_T_CLAMP,
    specs: "원형, 일반, 32mm, 48mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_GUTTER_BEAM_HOLDER,
    label: CONST.ITEM_NAME_GUTTER_BEAM_HOLDER,
    specs: "거터형, 알루미늄, 75mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_MIDDLE_PAIR_CLAMP,
    label: CONST.ITEM_NAME_MIDDLE_PAIR_CLAMP,
    specs: "원형, 일반, 48mm, 32mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_MIDDLE_SOLE_CLAMP,
    label: CONST.ITEM_NAME_MIDDLE_SOLE_CLAMP,
    specs: "원형, 일반, 48mm, 32mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_MIDDLE_Y_CLAMP,
    label: CONST.ITEM_NAME_MIDDLE_Y_CLAMP,
    specs: "각형, 일반, 60×60mm, 60×60mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_MIDDLE_HALF_Y_CLAMP,
    label: CONST.ITEM_NAME_MIDDLE_HALF_Y_CLAMP,
    specs: "각형, 일반, 60×60mm, 60×60mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_HOOK_HOLDER,
    label: CONST.ITEM_NAME_HOOK_HOLDER,
    specs: "원형, 일반, 25mm, -",
    brands: "기본회사",
  },
  // 꽂이
  {
    productId: CONST.ITEM_ID_INNER_T_HOLDER,
    label: CONST.ITEM_NAME_INNER_T_HOLDER,
    specs: "원형, 일반, 25mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FIRST_LEVEL_SOLE_SCONCE,
    label: CONST.ITEM_NAME_FIRST_LEVEL_SOLE_SCONCE,
    specs: "원형, 클램프식, 48mm, 32mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FIRST_LEVEL_PAIR_SCONCE,
    label: CONST.ITEM_NAME_FIRST_LEVEL_PAIR_SCONCE,
    specs: "원형, 클램프식, 48mm, 32mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SECOND_LEVEL_SOLE_SCONCE,
    label: CONST.ITEM_NAME_SECOND_LEVEL_SOLE_SCONCE,
    specs: "원형, 일반, 48mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SECOND_LEVEL_PAIR_SCONCE,
    label: CONST.ITEM_NAME_SECOND_LEVEL_PAIR_SCONCE,
    specs: "원형, 일반, 48mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONNECTION_SKYLIGHT_SET,
    label: CONST.ITEM_NAME_CONNECTION_SKYLIGHT_SET,
    specs: "각형, 외꽂이, 60×30mm, 25×25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_ARCH_SCONCE,
    label: CONST.ITEM_NAME_ARCH_SCONCE,
    specs: "원형, 일반, 32mm, -",
    brands: "기본회사",
  },
  // 다이아몬드 클램프
  {
    productId: CONST.ITEM_ID_DIAMOND_CLAMP,
    label: CONST.ITEM_NAME_DIAMOND_CLAMP,
    specs: "원형, 일반, 48mm, 48mm, 25mm, 45cm",
    brands: "기본회사",
  },
  // 문짝
  {
    productId: CONST.ITEM_ID_DOOR,
    label: CONST.ITEM_NAME_DOOR,
    specs: "일반, 25×25mm, 1300mm, 2000mm",
    brands: "기본회사",
  },
  // 문짝(기성품)
  {
    productId: CONST.ITEM_ID_DOOR_MADE,
    label: CONST.ITEM_NAME_DOOR_MADE,
    specs: "일반, 1300mm, 2000mm, 60mm",
    brands: "기본회사",
  },
  // 여닫이 문
  {
    productId: CONST.ITEM_ID_HINGED_DOOR,
    label: CONST.ITEM_NAME_HINGED_DOOR,
    specs: "외문, 1300mm, 2000mm",
    brands: "기본회사",
  },
  // 샷시창문
  {
    productId: CONST.ITEM_ID_SASH_DOOR,
    label: CONST.ITEM_NAME_SASH_DOOR,
    specs: "단창, 1000mm, 1000mm",
    brands: "기본회사",
  },
  // 롤러
  {
    productId: CONST.ITEM_ID_C_SECTION_STEEL_ROLLER,
    label: CONST.ITEM_NAME_C_SECTION_STEEL_ROLLER,
    specs: "일반, 50mm",
    brands: "기본회사",
  },
  // 시멘트
  {
    productId: CONST.ITEM_ID_REMICON,
    label: CONST.ITEM_NAME_REMICON,
    specs: "25-21-120",
    brands: "기본회사",
  },
  // 나사
  {
    productId: CONST.ITEM_ID_SCREW,
    label: CONST.ITEM_NAME_SCREW,
    specs: "십자, 일반, 8/9*13mm, 1000개",
    brands: "기본회사",
  },
  // 골조 기타
  {
    productId: CONST.ITEM_ID_BOLT_AND_NUT,
    label: CONST.ITEM_NAME_BOLT_AND_NUT,
    specs: "일반, 8mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_HINGE,
    label: CONST.ITEM_NAME_HINGE,
    specs: "평형",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_KNOB,
    label: CONST.ITEM_NAME_KNOB,
    specs: "ㄷ자",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_STAKE,
    label: CONST.ITEM_NAME_STAKE,
    specs: "철제, 중",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LONG_BOLT,
    label: CONST.ITEM_NAME_LONG_BOLT,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_TACKER_PIN,
    label: CONST.ITEM_NAME_TACKER_PIN,
    specs: "일자, 64mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FILM_BAND,
    label: CONST.ITEM_NAME_FILM_BAND,
    specs: "중형",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_WIRE_MESH,
    label: CONST.ITEM_NAME_WIRE_MESH,
    specs: "#10",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_TURNBUCKLE,
    label: CONST.ITEM_NAME_TURNBUCKLE,
    specs: "12mm",
    brands: "기본회사",
  },
  // 철판 물받이
  {
    productId: CONST.ITEM_ID_IRON_PLATE_GUTTER,
    label: CONST.ITEM_NAME_IRON_PLATE_GUTTER,
    specs: "일반, 0.45T, 60cm, 50m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PLASTIC_GUTTER,
    label: CONST.ITEM_NAME_PLASTIC_GUTTER,
    specs: "일반, 1.5T, 60cm, 50m, 타공 없음",
    brands: "기본회사",
  },
  // 유바
  {
    productId: CONST.ITEM_ID_UBAR,
    label: CONST.ITEM_NAME_UBAR,
    specs: "원형, 48mm, 1.1m",
    brands: "기본회사",
  },
  // 물받이 걸이
  {
    productId: CONST.ITEM_ID_GUTTER_HANGER,
    label: CONST.ITEM_NAME_GUTTER_HANGER,
    specs: "원형, 일반, 32mm, 25cm",
    brands: "기본회사",
  },
  // 수동 개폐기
  {
    productId: CONST.ITEM_ID_MANUAL_SWITCHER,
    label: CONST.ITEM_NAME_MANUAL_SWITCHER,
    specs: "측면, 비닐용",
    brands: "황놀부, JHV-A, -",
  },
  // 동력 개폐기
  {
    productId: CONST.ITEM_ID_POWER_SWITCHER,
    label: CONST.ITEM_NAME_POWER_SWITCHER,
    specs: "비닐용, 25mm, 25mm, 32mm, 25mm",
    brands: "청오, CODM 81024, 4.5kg·m, 4m",
  },
  // 천창 모터
  {
    productId: CONST.ITEM_ID_SKYLIGHT_MOTOR,
    label: CONST.ITEM_NAME_SKYLIGHT_MOTOR,
    specs: "천창용, 32mm, 용접",
    brands: "청오엔지니어링, 81042D, 26kg·m, 200W",
  },
  // 천창 세트
  {
    productId: CONST.ITEM_ID_RACKPINION_SET,
    label: CONST.ITEM_NAME_RACKPINION_SET,
    specs: "일반, 32mm, 1m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_VENTPIPEPAD_SET,
    label: CONST.ITEM_NAME_VENTPIPEPAD_SET,
    specs: "알루미늄, 25×25mm, 6m",
    brands: "기본회사",
  },
  // 전선
  {
    productId: CONST.ITEM_ID_ELECTRIC_WIRE,
    label: CONST.ITEM_NAME_ELECTRIC_WIRE,
    specs: "VCTF, 1.5sq, 2C, 100m",
    brands: "기본회사",
  },
  // 개폐기 보조
  {
    productId: CONST.ITEM_ID_AXIS_ROLLER,
    label: CONST.ITEM_NAME_AXIS_ROLLER,
    specs: "25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_AXIS_COUPLING,
    label: CONST.ITEM_NAME_AXIS_COUPLING,
    specs: "25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SUPPORT_COUPLING,
    label: CONST.ITEM_NAME_SUPPORT_COUPLING,
    specs: "25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SUPPORT_FIXING_PIN,
    label: CONST.ITEM_NAME_SUPPORT_FIXING_PIN,
    specs: "25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LONG_BOLT_HOLDER,
    label: CONST.ITEM_NAME_LONG_BOLT_HOLDER,
    specs: "25mm",
    brands: "기본회사",
  },
  // 통합 제어기
  {
    productId: CONST.ITEM_ID_INTEGRATED_CONTROLLER,
    label: CONST.ITEM_NAME_INTEGRATED_CONTROLLER,
    specs: "일반, 소, 통합0, 비닐2, 부직0, 커튼0, 환기0, 관수0, 기타AC0",
    brands: "기본회사",
  },
  // 제어기 센서
  {
    productId: CONST.ITEM_ID_CONTROLLER_TIMER,
    label: CONST.ITEM_NAME_CONTROLLER_TIMER,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONTROLLER_TEMP_SENSOR,
    label: CONST.ITEM_NAME_CONTROLLER_TEMP_SENSOR,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONTROLLER_HEAT_SENSOR,
    label: CONST.ITEM_NAME_CONTROLLER_HEAT_SENSOR,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONTROLLER_TEMP_HUM_SENSOR,
    label: CONST.ITEM_NAME_CONTROLLER_TEMP_HUM_SENSOR,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONTROLLER_RAINDROP_SENSOR,
    label: CONST.ITEM_NAME_CONTROLLER_RAINDROP_SENSOR,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONTROLLER_WIND_SENSOR,
    label: CONST.ITEM_NAME_CONTROLLER_WIND_SENSOR,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONTROLLER_INSOLATION_SENSOR,
    label: CONST.ITEM_NAME_CONTROLLER_INSOLATION_SENSOR,
    specs: "일반",
    brands: "기본회사",
  },
  // 개폐기 기타
  {
    productId: CONST.ITEM_ID_SWITCHER_PROTECTION_RUBBER,
    label: CONST.ITEM_NAME_SWITCHER_PROTECTION_RUBBER,
    specs: "일반",
    brands: "기본회사",
  },
  // 비닐
  {
    productId: CONST.ITEM_ID_VINYL,
    label: CONST.ITEM_NAME_VINYL,
    specs: "0.1mm, 120cm, 50m, 일타, 박스, 끈 없음",
    brands: "기본회사, 장수, 0.94",
  },
  // 직조 필름
  {
    productId: CONST.ITEM_ID_WEAVING_FILM,
    label: CONST.ITEM_NAME_WEAVING_FILM,
    specs: "파라모, 투명, 6m, 50m, 끈 없음, 1회",
    brands: "기본회사, 파라모, 400cm간격",
  },
  // 차광망
  {
    productId: CONST.ITEM_ID_SHADENET,
    label: CONST.ITEM_NAME_SHADENET,
    specs: "사중직, 95%, 흑색, 6m, 50m, 끈 없음, 끝미싱, 0회",
    brands: "기본회사, 9m간격",
  },
  // 텐텐지
  {
    productId: CONST.ITEM_ID_TENT,
    label: CONST.ITEM_NAME_TENT,
    specs: "일반, 6m, 50m, 3회",
    brands: "기본회사, 2m간격",
  },
  // 부직포
  {
    productId: CONST.ITEM_ID_NONWOVEN,
    label: CONST.ITEM_NAME_NONWOVEN,
    specs: "200g, 흰색, 6m, 50m, 끈 없음, 1회",
    brands: "기본회사, 4m간격",
  },
  // 카시미론
  {
    productId: CONST.ITEM_ID_CASHMILON,
    label: CONST.ITEM_NAME_CASHMILON,
    specs: "6온스, 2.3m, 24m",
    brands: "기본회사",
  },
  // 보온덮개
  {
    productId: CONST.ITEM_ID_COLOR_LAGGING,
    label: CONST.ITEM_NAME_COLOR_LAGGING,
    specs: "A급, 2.4m, 20m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LAGGING,
    label: CONST.ITEM_NAME_LAGGING,
    specs: "A급, 1.8m, 15m",
    brands: "기본회사",
  },
  // 다겹 커튼지
  {
    productId: CONST.ITEM_ID_QUILTING,
    label: CONST.ITEM_NAME_QUILTING,
    specs: "5겹:마트+부직+솜(4온)+부직+마트, 6m, 50m, 끈 없음, 1회",
    brands: "기본회사, 5m간격",
  },
  // 스크린
  {
    productId: CONST.ITEM_ID_SCREEN,
    label: CONST.ITEM_NAME_SCREEN,
    specs: "알루미늄, 6m, 50m, 끈 없음, 1회",
    brands: "부전, BJ100U, 99%, 45%, 4m간격",
  },
  // 마트
  {
    productId: CONST.ITEM_ID_MAT,
    label: CONST.ITEM_NAME_MAT,
    specs: "300/300, 6m, 50m, 끈 없음",
    brands: "기본회사",
  },
  // 방충망
  {
    productId: CONST.ITEM_ID_SCREENNET,
    label: CONST.ITEM_NAME_SCREENNET,
    specs: "일반, 1.5m, 100m",
    brands: "기본회사",
  },
  // 패드
  {
    productId: CONST.ITEM_ID_NORMAL_PAD,
    label: CONST.ITEM_NAME_NORMAL_PAD,
    specs: "칼라 철, 0.6T, 6m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LAGGING_PAD,
    label: CONST.ITEM_NAME_LAGGING_PAD,
    specs: "칼라 철, 0.7T, 6m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_GUTTER_PAD,
    label: CONST.ITEM_NAME_GUTTER_PAD,
    specs: "칼라 철, 0.7T, 6m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONDENSATION_PAD,
    label: CONST.ITEM_NAME_CONDENSATION_PAD,
    specs: "알루미늄, 1T, 5m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_BAR_PAD,
    label: CONST.ITEM_NAME_BAR_PAD,
    specs: "고내식, 0.7T, 0.75m",
    brands: "기본회사",
  },
  // 패드 연결구
  {
    productId: CONST.ITEM_ID_PAD_CONNECTION_PIN,
    label: CONST.ITEM_NAME_PAD_CONNECTION_PIN,
    specs: "칼라 철",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONDENSATION_PAD_CONNECTION_PIN,
    label: CONST.ITEM_NAME_CONDENSATION_PAD_CONNECTION_PIN,
    specs: "플라스틱",
    brands: "기본회사",
  },
  // 패드 스프링
  {
    productId: CONST.ITEM_ID_NORMAL_PAD_SPRING,
    label: CONST.ITEM_NAME_NORMAL_PAD_SPRING,
    specs: "일반, 2m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LAGGING_PAD_SPRING,
    label: CONST.ITEM_NAME_LAGGING_PAD_SPRING,
    specs: "일반, 2m",
    brands: "기본회사",
  },
  // 패드 비닐
  {
    productId: CONST.ITEM_ID_PAD_VINYL,
    label: CONST.ITEM_NAME_PAD_VINYL,
    specs: "일반, 200m",
    brands: "기본회사",
  },
  // 클립
  {
    productId: CONST.ITEM_ID_SKIRT_CLIP,
    label: CONST.ITEM_NAME_SKIRT_CLIP,
    specs: "철, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_NORMAL_CLIP,
    label: CONST.ITEM_NAME_NORMAL_CLIP,
    specs: "플라스틱, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_HANGER_CLIP,
    label: CONST.ITEM_NAME_HANGER_CLIP,
    specs: "플라스틱, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SLOPE_CLIP,
    label: CONST.ITEM_NAME_SLOPE_CLIP,
    specs: "플라스틱, 32mm",
    brands: "기본회사",
  },
  // 목재
  {
    productId: CONST.ITEM_ID_RECT_LUMBER,
    label: CONST.ITEM_NAME_RECT_LUMBER,
    specs: "일반, 40×40mm, 3.6m",
    brands: "기본회사",
  },
  // 밴드끈
  {
    productId: CONST.ITEM_ID_NORMAL_BANDSTRING,
    label: CONST.ITEM_NAME_NORMAL_BANDSTRING,
    specs: "일반끈, 1.1cm, 200m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_POLY_BANDSTRING,
    label: CONST.ITEM_NAME_POLY_BANDSTRING,
    specs: "폴리끈, 2cm, 500m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_VINYL_BAND,
    label: CONST.ITEM_NAME_VINYL_BAND,
    specs: "비닐, 25cm, 200m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_NET_BAND,
    label: CONST.ITEM_NAME_NET_BAND,
    specs: "망, 30cm, 100m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_WEAVING_BAND,
    label: CONST.ITEM_NAME_WEAVING_BAND,
    specs: "직조, 20cm, 200m",
    brands: "기본회사",
  },
  // 강선
  {
    productId: CONST.ITEM_ID_STEEL_STRING_WIRE,
    label: CONST.ITEM_NAME_STEEL_STRING_WIRE,
    specs: "강선8번(50kg), 아연, 4mm, 490m",
    brands: "기본회사",
  },
  // 환풍기
  {
    productId: CONST.ITEM_ID_VENTILATOR,
    label: CONST.ITEM_NAME_VENTILATOR,
    specs: "일반, 셔터부착",
    brands: "엘디, LD-S-181, 110㎥/min, 550*550mm, 450mm, 180W",
  },
  // 환기팬
  {
    productId: CONST.ITEM_ID_VENT_FAN,
    label: CONST.ITEM_NAME_VENT_FAN,
    specs: "일반",
    brands: "동서그린산업, DS-500(단동형), 50㎥/min, 500mm, 400mm, 200W",
  },
  // 환기구
  {
    productId: CONST.ITEM_ID_VENT_HOLE,
    label: CONST.ITEM_NAME_VENT_HOLE,
    specs: "일반",
    brands: "대흥산업, 기본, 600mm, 수동",
  },
  // 환기구 개폐 세트
  {
    productId: CONST.ITEM_ID_VENT_HOLE_SWITCH_SET,
    label: CONST.ITEM_NAME_VENT_HOLE_SWITCH_SET,
    specs: "일반",
    brands: "대흥산업, 기본, 10개, 95m",
  },
  // 공기 유동팬
  {
    productId: CONST.ITEM_ID_AIR_FLOW_FAN,
    label: CONST.ITEM_NAME_AIR_FLOW_FAN,
    specs: "일반",
    brands: "동서그린산업, DS-900-1-A, 30㎥/h, 400mm, 320mm, 60W",
  },
  // 감속 모터
  {
    productId: CONST.ITEM_ID_REDUCED_MOTOR,
    label: CONST.ITEM_NAME_REDUCED_MOTOR,
    specs: "2hp, 드럼식, 삼상, 48mm, 48mm",
    brands: "청오, COAM 87072, 100/80mm",
  },
  // 베어링
  {
    productId: CONST.ITEM_ID_BEARING_SET,
    label: CONST.ITEM_NAME_BEARING_SET,
    specs: "원형, 일반, 48mm, 48mm",
    brands: "기본회사",
  },
  // 와인딩 드럼
  {
    productId: CONST.ITEM_ID_WINDING_DRUM,
    label: CONST.ITEM_NAME_WINDING_DRUM,
    specs: "일반, 48mm, 100mm",
    brands: "기본회사",
  },
  // 와이어 인장
  {
    productId: CONST.ITEM_ID_WIRE_STRAINER,
    label: CONST.ITEM_NAME_WIRE_STRAINER,
    specs: "원형, 일반, 32mm",
    brands: "기본회사",
  },
  // 트러스 클립
  {
    productId: CONST.ITEM_ID_TRUSS_CLIP,
    label: CONST.ITEM_NAME_TRUSS_CLIP,
    specs: "각형, 일반, 30mm",
    brands: "기본회사",
  },
  // 예인 클립
  {
    productId: CONST.ITEM_ID_STICK_TOW_CLIP,
    label: CONST.ITEM_NAME_STICK_TOW_CLIP,
    specs: "플라스틱",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_HOOK_TOW_CLIP,
    label: CONST.ITEM_NAME_HOOK_TOW_CLIP,
    specs: "철",
    brands: "기본회사",
  },
  // 커튼 기타
  {
    productId: CONST.ITEM_ID_TOW_ROLLER,
    label: CONST.ITEM_NAME_TOW_ROLLER,
    specs: "일반, 소",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_GUIDE_ROLLER,
    label: CONST.ITEM_NAME_GUIDE_ROLLER,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_ROLLER_HOOK,
    label: CONST.ITEM_NAME_ROLLER_HOOK,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SCREEN_PINCER,
    label: CONST.ITEM_NAME_SCREEN_PINCER,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_WIRE_STRAINER,
    label: CONST.ITEM_NAME_WIRE_STRAINER,
    specs: "원형, 일반, 32mm",
    brands: "기본회사",
  },
  // 보일러
  {
    productId: CONST.ITEM_ID_BOILER,
    label: CONST.ITEM_NAME_BOILER,
    specs: "일반",
    brands: "기본회사, 기본모델, -, -, -",
  },
  // 열풍기
  {
    productId: CONST.ITEM_ID_HEATING_FAN,
    label: CONST.ITEM_NAME_HEATING_FAN,
    specs: "일반",
    brands: "삼일엔지니어링, SIF-110, 등유, 11000kcal/h, 1.1l/h, 15~25평",
  },
  // 히팅튜브
  {
    productId: CONST.ITEM_ID_HEATING_TUBE,
    label: CONST.ITEM_NAME_HEATING_TUBE,
    specs: "일반",
    brands: "블루에너지, HPS-2200A, 2.2kW, 25mm, 24.5m",
  },
  // 양액시스템
  {
    productId: CONST.ITEM_ID_NUTRIENT_SOLUTION,
    label: CONST.ITEM_NAME_NUTRIENT_SOLUTION,
    specs: "일반",
    brands: "그린랩스, SMART G1",
  },
  // 관비기
  {
    productId: CONST.ITEM_ID_WATER_SOLUTION,
    label: CONST.ITEM_NAME_WATER_SOLUTION,
    specs: "일반",
    brands: "레인버드, ESP8LXME, 8구역",
  },
  // 물탱크
  {
    productId: CONST.ITEM_ID_WATER_TANK,
    label: CONST.ITEM_NAME_WATER_TANK,
    specs: "일반, 원형, 5ton, 50mm, 40mm, 20mm",
    brands: "기본회사",
  },
  // 물탱크 피팅
  {
    productId: CONST.ITEM_ID_WATER_TANK_FITTING,
    label: CONST.ITEM_NAME_WATER_TANK_FITTING,
    specs: "일반, 20mm, -",
    brands: "기본회사",
  },
  // 물탱크 조절기
  {
    productId: CONST.ITEM_ID_NUTRIENT_MIXER,
    label: CONST.ITEM_NAME_NUTRIENT_MIXER,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_WATER_LEVEL_CONTROLLER,
    label: CONST.ITEM_NAME_WATER_LEVEL_CONTROLLER,
    specs: "일반",
    brands: "기본회사",
  },
  // 펌프
  {
    productId: CONST.ITEM_ID_WATER_PUMP,
    label: CONST.ITEM_NAME_WATER_PUMP,
    specs: "일반, 1HP",
    brands: "윌로펌프, PW-952M, 1HP압상, 단상, 40mm",
  },
  // 펌프 연결
  {
    productId: CONST.ITEM_ID_WATER_PUMP_CONNECT,
    label: CONST.ITEM_NAME_WATER_PUMP_CONNECT,
    specs: "일자, 40mm, 2인치",
    brands: "기본회사",
  },
  // 펌프 호스
  {
    productId: CONST.ITEM_ID_FLEXIBLE_HOSE,
    label: CONST.ITEM_NAME_FLEXIBLE_HOSE,
    specs: "일반, 30.5mm, 20m",
    brands: "기본회사",
  },
  // 펌프 조절기
  {
    productId: CONST.ITEM_ID_WATER_PUMP_CONTROLLER,
    label: CONST.ITEM_NAME_WATER_PUMP_CONTROLLER,
    specs: "일반",
    brands: "기본회사",
  },
  // 여과기
  {
    productId: CONST.ITEM_ID_WATER_FILTER,
    label: CONST.ITEM_NAME_WATER_FILTER,
    specs: "일반",
    brands: "한슬테크, SAFE, 메쉬(망), 40mm",
  },
  // PE관
  {
    productId: CONST.ITEM_ID_PE_PIPE,
    label: CONST.ITEM_NAME_PE_PIPE,
    specs: "수도관(단층관), 40mm, 50m",
    brands: "기본회사",
  },
  // 관수연결
  {
    productId: CONST.ITEM_ID_NIPPLE,
    label: CONST.ITEM_NAME_NIPPLE,
    specs: "PE, 단니플, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SOCKET_FEMALE,
    label: CONST.ITEM_NAME_SOCKET_FEMALE,
    specs: "PE, 일반, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DIFFERENT_SOCKET_FEMALE,
    label: CONST.ITEM_NAME_DIFFERENT_SOCKET_FEMALE,
    specs: "PE, 일반, 50mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_CONNECT_SOCKET,
    label: CONST.ITEM_NAME_CONNECT_SOCKET,
    specs: "PE, 일반, 30mm, 30mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DIFFERENT_CONNECT_SOCKET,
    label: CONST.ITEM_NAME_DIFFERENT_CONNECT_SOCKET,
    specs: "PE, 일반, 40mm, 30mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_ELBOW,
    label: CONST.ITEM_NAME_ELBOW,
    specs: "PE, 소켓, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_MALE_ELBOW_SOCKET,
    label: CONST.ITEM_NAME_MALE_ELBOW_SOCKET,
    specs: "PE, 일반, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FEMALE_ELBOW_SOCKET,
    label: CONST.ITEM_NAME_FEMALE_ELBOW_SOCKET,
    specs: "PE, 일반, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_EXACT_T,
    label: CONST.ITEM_NAME_EXACT_T,
    specs: "PE, 소켓, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DIFFERENT_T,
    label: CONST.ITEM_NAME_DIFFERENT_T,
    specs: "PE, 소켓, 50mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_BUSHING,
    label: CONST.ITEM_NAME_BUSHING,
    specs: "PE, 일반, 50mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_VALVE_SOCKET,
    label: CONST.ITEM_NAME_VALVE_SOCKET,
    specs: "PE, 일반, 40mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PE_PIPE_END,
    label: CONST.ITEM_NAME_PE_PIPE_END,
    specs: "PE, 앤드캡, 40mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_WATER_SCREEN_CONNECT_SOCKET,
    label: CONST.ITEM_NAME_WATER_SCREEN_CONNECT_SOCKET,
    specs: "PE, 일반, 30mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_WATER_SCREEN_ELBOW_SOCKET,
    label: CONST.ITEM_NAME_WATER_SCREEN_ELBOW_SOCKET,
    specs: "PE, 일반, 30mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PE_PIPE_SADDLE,
    label: CONST.ITEM_NAME_PE_PIPE_SADDLE,
    specs: "PE, 일반, 50mm, 20mm",
    brands: "기본회사",
  },
  // 관수 밸브
  {
    productId: CONST.ITEM_ID_ELECTRIC_VALVE,
    label: CONST.ITEM_NAME_ELECTRIC_VALVE,
    specs: "PE, 암나사, 25mm, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_UNION_VALVE,
    label: CONST.ITEM_NAME_UNION_VALVE,
    specs: "PE, 일반, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FEMALE_BALL_VALVE,
    label: CONST.ITEM_NAME_FEMALE_BALL_VALVE,
    specs: "PE, 일반, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_MALE_BALL_VALVE,
    label: CONST.ITEM_NAME_MALE_BALL_VALVE,
    specs: "PE, 일반, 40mm, 40mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FEMALE_MALE_BALL_VALVE,
    label: CONST.ITEM_NAME_FEMALE_MALE_BALL_VALVE,
    specs: "PE, 일반, 20mm, 20mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FEMALE_MALE_VALVE,
    label: CONST.ITEM_NAME_FEMALE_MALE_VALVE,
    specs: "PE, 일반, 20mm, 20mm",
    brands: "기본회사",
  },
  // 관수 걸이
  {
    productId: CONST.ITEM_ID_PE_PIPE_HANGER,
    label: CONST.ITEM_NAME_PE_PIPE_HANGER,
    specs: "철사고리, 10cm, -, -",
    brands: "기본회사",
  },
  // 관수 튜브
  {
    productId: CONST.ITEM_ID_TUBE,
    label: CONST.ITEM_NAME_TUBE,
    specs: "일반, 3/5mm, 200m",
    brands: "기본회사",
  },
  // 관수 튜브 니플
  {
    productId: CONST.ITEM_ID_TUBE_NIPPLE,
    label: CONST.ITEM_NAME_TUBE_NIPPLE,
    specs: "일반, 엘디, 3/5mm",
    brands: "기본회사",
  },
  // 미니 스프링클러
  {
    productId: CONST.ITEM_ID_MINI_SPRINKLER,
    label: CONST.ITEM_NAME_MINI_SPRINKLER,
    specs: "일반",
    brands: "네타핌, 스핀넷, 일반, 120L/H, 5~7m",
  },
  // 미니 스프링클러 낙수방지기
  {
    productId: CONST.ITEM_ID_MINI_SPRINKLER_DROP_GUARD,
    label: CONST.ITEM_NAME_MINI_SPRINKLER_DROP_GUARD,
    specs: "일반",
    brands: "네타핌, ANTILEAK",
  },
  // 미니 스프링클러 튜브셋
  {
    productId: CONST.ITEM_ID_MINI_SPRINKLER_TUBE_SET,
    label: CONST.ITEM_NAME_MINI_SPRINKLER_TUBE_SET,
    specs: "일반, 4/7mm, 50cm, 엘디, 네타핌",
    brands: "기본회사",
  },
  // 점적관
  {
    productId: CONST.ITEM_ID_DRIP_PIPE,
    label: CONST.ITEM_NAME_DRIP_PIPE,
    specs: "점적테이프",
    brands: "서원양행, 그린드립, 0.2T, 10cm간격, 500m, 1L/H",
  },
  // 점적단추
  {
    productId: CONST.ITEM_ID_DRIP_BUTTON,
    label: CONST.ITEM_NAME_DRIP_BUTTON,
    specs: "직접살수",
    brands: "AK, 옥타8, 물량조절, 0~40L/H",
  },
  // 점적단추 부속
  {
    productId: CONST.ITEM_ID_DRIP_BUTTON_MANY_HOLD,
    label: CONST.ITEM_NAME_DRIP_BUTTON_MANY_HOLD,
    specs: "일반, 4구, 3/5mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DRIP_BUTTON_DROPPER,
    label: CONST.ITEM_NAME_DRIP_BUTTON_DROPPER,
    specs: "일반, ㄱ자, 3/5mm",
    brands: "기본회사",
  },
  // 점적 미늘
  {
    productId: CONST.ITEM_ID_DRIP_BARB,
    label: CONST.ITEM_NAME_DRIP_BARB,
    specs: "일반, 1구, 20mm, 13mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DRIP_START_SADDLE,
    label: CONST.ITEM_NAME_DRIP_START_SADDLE,
    specs: "일반, 1구, 50mm, 13mm",
    brands: "기본회사",
  },
  // 점적 연결
  {
    productId: CONST.ITEM_ID_DRIP_CONNECT_SOCKET,
    label: CONST.ITEM_NAME_DRIP_CONNECT_SOCKET,
    specs: "일반, 일자, 13mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DRIP_SUPPORT_END,
    label: CONST.ITEM_NAME_DRIP_SUPPORT_END,
    specs: "일반, 링, 13mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LD_CONNECT,
    label: CONST.ITEM_NAME_LD_CONNECT,
    specs: "일반, 일자, 13mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LD_ELBOW,
    label: CONST.ITEM_NAME_LD_ELBOW,
    specs: "일반, 90도, 13mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LD_EXACT_T,
    label: CONST.ITEM_NAME_LD_EXACT_T,
    specs: "일반, T자, 13mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_LD_END,
    label: CONST.ITEM_NAME_LD_END,
    specs: "일반, 8자고리, 13mm",
    brands: "기본회사",
  },
  // 점적 밸브
  {
    productId: CONST.ITEM_ID_DRIP_LINE_VALVE,
    label: CONST.ITEM_NAME_DRIP_LINE_VALVE,
    specs: "일반, 밸브, 13mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DRIP_START_LINE_VALVE,
    label: CONST.ITEM_NAME_DRIP_START_LINE_VALVE,
    specs: "일반, 밸브, 13mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_DRIP_LINE_VALVE_SUPPORT,
    label: CONST.ITEM_NAME_DRIP_LINE_VALVE_SUPPORT,
    specs: "일반, 지주대, 13mm",
    brands: "기본회사",
  },
  // 분수호스
  {
    productId: CONST.ITEM_ID_FOUNTAIN_HOSE,
    label: CONST.ITEM_NAME_FOUNTAIN_HOSE,
    specs: "수막양면, 200m",
    brands: "신농, SNH501, 수막양면, 7cm, 0.2m, 200m",
  },
  // 분수호스 연결
  {
    productId: CONST.ITEM_ID_FOUNTAIN_HOSE_CONNECT,
    label: CONST.ITEM_NAME_FOUNTAIN_HOSE_CONNECT,
    specs: "일반, 일자, 2인치",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FOUNTAIN_HOSE_CROSS_CONNECT,
    label: CONST.ITEM_NAME_FOUNTAIN_HOSE_CROSS_CONNECT,
    specs: "일반, 십자, 2인치",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FOUNTAIN_HOSE_END,
    label: CONST.ITEM_NAME_FOUNTAIN_HOSE_END,
    specs: "일반, -, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FOUNTAIN_HOSE_T_VALVE,
    label: CONST.ITEM_NAME_FOUNTAIN_HOSE_T_VALVE,
    specs: "일반, T자, 2인치",
    brands: "기본회사",
  },
  // 분수호스 걸이
  {
    productId: CONST.ITEM_ID_FOUNTAIN_HOSE_HANGER_SCREEN,
    label: CONST.ITEM_NAME_FOUNTAIN_HOSE_HANGER_SCREEN,
    specs: "양측, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FOUNTAIN_HOSE_HANGER_UPPER,
    label: CONST.ITEM_NAME_FOUNTAIN_HOSE_HANGER_UPPER,
    specs: "플라스틱 타이, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_FOUNTAIN_HOSE_HANGER_SIDE,
    label: CONST.ITEM_NAME_FOUNTAIN_HOSE_HANGER_SIDE,
    specs: "일반, 32mm",
    brands: "기본회사",
  },
  // 관수 공구
  {
    productId: CONST.ITEM_ID_PE_PIPE_FASTENER,
    label: CONST.ITEM_NAME_PE_PIPE_FASTENER,
    specs: "일반, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PE_PIPE_PUNCHER,
    label: CONST.ITEM_NAME_PE_PIPE_PUNCHER,
    specs: "일반, 3/5mm",
    brands: "기본회사",
  },
  // 관수 기타
  {
    productId: CONST.ITEM_ID_DRIP_ALIGNMENT_PIN,
    label: CONST.ITEM_NAME_DRIP_ALIGNMENT_PIN,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_WATERING_SPRING,
    label: CONST.ITEM_NAME_WATERING_SPRING,
    specs: "일반",
    brands: "기본회사",
  },
  // 배수관
  {
    productId: CONST.ITEM_ID_THP_PIPE,
    label: CONST.ITEM_NAME_THP_PIPE,
    specs: "일반, 250mm, 4m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PVC_PIPE,
    label: CONST.ITEM_NAME_PVC_PIPE,
    specs: "일반, 50mm, 4m",
    brands: "기본회사",
  },
  // PVC 밸브
  {
    productId: CONST.ITEM_ID_PVC_FEMALE_BALL_VALVE,
    label: CONST.ITEM_NAME_PVC_FEMALE_BALL_VALVE,
    specs: "PVC, 일반, 50mm, 50mm",
    brands: "기본회사",
  },
  // PVC 연결
  {
    productId: CONST.ITEM_ID_PVC_SOCKET,
    label: CONST.ITEM_NAME_PVC_SOCKET,
    specs: "PVC, 일반, 50mm, 50mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PVC_ELBOW,
    label: CONST.ITEM_NAME_PVC_ELBOW,
    specs: "PVC, 일반, 50mm, 50mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PVC_DEGREE45_ELBOW,
    label: CONST.ITEM_NAME_PVC_DEGREE45_ELBOW,
    specs: "PVC, 일반, 50mm, 50mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PVC_EXACT_T,
    label: CONST.ITEM_NAME_PVC_EXACT_T,
    specs: "PVC, 일반, 50mm, 50mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PVC_DIFFERENT_T,
    label: CONST.ITEM_NAME_PVC_DIFFERENT_T,
    specs: "PVC, 일반, 100mm, 50mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PVC_VALVE_SOCKET,
    label: CONST.ITEM_NAME_PVC_VALVE_SOCKET,
    specs: "PVC, 일반, 50mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_PVC_END,
    label: CONST.ITEM_NAME_PVC_END,
    specs: "PVC, 일반, 50mm, -",
    brands: "기본회사",
  },
  // 물받이 통
  {
    productId: CONST.ITEM_ID_DRAINSPOUT_BOX,
    label: CONST.ITEM_NAME_DRAINSPOUT_BOX,
    specs: "일반, 50mm",
    brands: "기본회사",
  },
  // 배관 잡이쇠
  {
    productId: CONST.ITEM_ID_DRAIN_PIPE_HANGER,
    label: CONST.ITEM_NAME_DRAIN_PIPE_HANGER,
    specs: "일반, 50mm",
    brands: "기본회사",
  },
  // 포밍 베드
  {
    productId: CONST.ITEM_ID_FORMING_BED,
    label: CONST.ITEM_NAME_FORMING_BED,
    specs: "일반, 0.6T, 20cm, 11cm",
    brands: "기본회사",
  },
  // 스티로폼 베드
  {
    productId: CONST.ITEM_ID_STYROFOAM_BED,
    label: CONST.ITEM_NAME_STYROFOAM_BED,
    specs: "일반, 20cm, 1m",
    brands: "기본회사",
  },
  // NFT 베드
  {
    productId: CONST.ITEM_ID_NFT_BED,
    label: CONST.ITEM_NAME_NFT_BED,
    specs: "일반, 9cm, 20m",
    brands: "기본회사",
  },
  // 베드 부속
  {
    productId: CONST.ITEM_ID_BED_END,
    label: CONST.ITEM_NAME_BED_END,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_BED_DRAIN,
    label: CONST.ITEM_NAME_BED_DRAIN,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_BED_SUPPORT_CLIP,
    label: CONST.ITEM_NAME_BED_SUPPORT_CLIP,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_BED_HOLDER,
    label: CONST.ITEM_NAME_BED_HOLDER,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_NFT_SUPPLY_DRAIN_SET,
    label: CONST.ITEM_NAME_NFT_SUPPLY_DRAIN_SET,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SINK_GUARD_PLATE,
    label: CONST.ITEM_NAME_SINK_GUARD_PLATE,
    specs: "일반",
    brands: "기본회사",
  },
  // 바닥지
  {
    productId: CONST.ITEM_ID_GROUND_COVER,
    label: CONST.ITEM_NAME_GROUND_COVER,
    specs: "탄탄매트, 검/흰색, 9m, 50m",
    brands: "기본회사",
  },
  // 바닥지 부속
  {
    productId: CONST.ITEM_ID_SACK_PIN,
    label: CONST.ITEM_NAME_SACK_PIN,
    specs: "일반",
    brands: "기본회사",
  },
  // 유인줄 부속
  {
    productId: CONST.ITEM_ID_TRELLIS_HANGER,
    label: CONST.ITEM_NAME_TRELLIS_HANGER,
    specs: "일반",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_TRELLIS_ROUND_BAR,
    label: CONST.ITEM_NAME_TRELLIS_ROUND_BAR,
    specs: "일반",
    brands: "기본회사",
  },
  // 운반구
  {
    productId: CONST.ITEM_ID_UPPER_CARRIER,
    label: CONST.ITEM_NAME_UPPER_CARRIER,
    specs: "일반, 대, 25mm",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SIDE_CARRIER,
    label: CONST.ITEM_NAME_SIDE_CARRIER,
    specs: "일반, 중, 25mm",
    brands: "기본회사",
  },
  // 지면 운반구
  {
    productId: CONST.ITEM_ID_GROUND_CARRIER,
    label: CONST.ITEM_NAME_GROUND_CARRIER,
    specs: "일반, 중, 45cm간격, 25mm",
    brands: "기본회사",
  },
  // 운반 고리
  {
    productId: CONST.ITEM_ID_RAIL_HOOK,
    label: CONST.ITEM_NAME_RAIL_HOOK,
    specs: "원형, 일반, 25mm, 25mm, 중",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SECOND_LEVEL_RAIL_HOOK,
    label: CONST.ITEM_NAME_SECOND_LEVEL_RAIL_HOOK,
    specs: "원형, 일반, 25mm, 25mm, -",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_SIDE_RAIL_HOOK,
    label: CONST.ITEM_NAME_SIDE_RAIL_HOOK,
    specs: "원형, 일반, 25mm, 25mm, -",
    brands: "기본회사",
  },
  // 레일 간격유지
  {
    productId: CONST.ITEM_ID_RAIL_INTERVAL,
    label: CONST.ITEM_NAME_RAIL_INTERVAL,
    specs: "원형, 일반, 25mm, 45cm간격",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_RAIL_SUPPORT_PIPE,
    label: CONST.ITEM_NAME_RAIL_SUPPORT_PIPE,
    specs: "원형, 일반, 40A, 20cm간격",
    brands: "기본회사",
  },
  // 끈/와이어
  {
    productId: CONST.ITEM_ID_CORD,
    label: CONST.ITEM_NAME_CORD,
    specs: "코드사, 실, 3mm, 800m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_RIPCORD,
    label: CONST.ITEM_NAME_RIPCORD,
    specs: "낙하산줄, 실, 4mm, 1500m",
    brands: "기본회사",
  },
  {
    productId: CONST.ITEM_ID_COATING_WIRE,
    label: CONST.ITEM_NAME_COATING_WIRE,
    specs: "코팅줄, 철사, 2.8mm, 2000m",
    brands: "기본회사",
  },
  // (임의 자재)
  {
    productId: CONST.ITEM_ID_CUSTOM_MATERIAL,
    label: CONST.ITEM_NAME_CUSTOM_MATERIAL,
    specs: "",
    brands: "",
  },
  {
    productId: CONST.ITEM_ID_CUSTOM_CONTROLLER,
    label: CONST.ITEM_NAME_CUSTOM_CONTROLLER,
    specs: "",
    brands: "",
  },
  // 공과잡비
  {
    productId: CONST.ITEM_ID_BUILDING_MIS,
    label: CONST.ITEM_NAME_BUILDING_MIS,
    specs: "총 자재비 기준, 3%",
    brands: "",
  },
  // 자재 할인비
  {
    productId: CONST.ITEM_ID_OVERLAP_MATERIAL_SAVING,
    label: CONST.ITEM_NAME_OVERLAP_MATERIAL_SAVING,
    specs: "",
    brands: "",
  },
  {
    productId: CONST.ITEM_ID_MATERIAL_DISCOUNT,
    label: CONST.ITEM_NAME_MATERIAL_DISCOUNT,
    specs: "",
    brands: "",
  },
  // 임의 경비
  {
    productId: CONST.ITEM_ID_CUSTOM_EXPENSE,
    label: CONST.ITEM_NAME_CUSTOM_EXPENSE,
    specs: "",
    brands: "",
  },
  // 기타 경비
  {
    productId: CONST.ITEM_ID_WELDING,
    label: CONST.ITEM_NAME_WELDING,
    specs: "일반",
    brands: "기본회사",
  },
  // 파이프 벤딩비
  {
    productId: CONST.ITEM_ID_PIPE_BENDING_EXPENSE,
    label: CONST.ITEM_NAME_PIPE_BENDING_EXPENSE,
    specs: "원형, 31.8mm",
    brands: "기본회사",
  },
  // 장비비
  {
    productId: CONST.ITEM_ID_EQUIPMENT_EXPENSE,
    label: CONST.ITEM_NAME_EQUIPMENT_EXPENSE,
    specs: "포크레인",
    brands: "기본회사",
  },
  // 임의 인건비
  {
    productId: CONST.ITEM_ID_CUSTOM_LABOR_COST,
    label: CONST.ITEM_NAME_CUSTOM_LABOR_COST,
    specs: "",
    brands: "",
  },
  // 시공 할인비
  {
    productId: CONST.ITEM_ID_BUILDING_DISCOUNT,
    label: CONST.ITEM_NAME_BUILDING_DISCOUNT,
    specs: "",
    brands: "",
  },

  // 임의자재
  {
    productId: CONST.ITEM_ID_CUSTOM_MATERIAL,
    label: CONST.ITEM_NAME_CUSTOM_MATERIAL,
    specs: "",
    brands: "",
  },
];
