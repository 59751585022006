import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { ItemSupportPipe } from "vhows-design/src/object/design/item/list/ItemSupportPipe";
import { ItemBracket } from "vhows-design/src/object/design/item/list/ItemBracket";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { ColumnSampleIL_CylinderMiddlePipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_CylinderMiddlePipe";
import { ColumnSampleIL_CylinderMiddleTrussDiagonalPipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_CylinderMiddleTrussDiagonalPipe";
import { ColumnSampleIL_CylinderMiddleSupWorkedPipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_CylinderMiddleSupWorkedPipe";
import { ColumnSampleIL_CylinderMiddleSupNormalPipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_CylinderMiddleSupNormalPipe";
import { ColumnSampleIL_CylinderMiddleBracingPipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_CylinderMiddleBracingPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-14
 */
@jsonObject({
  knownTypes: [
    ColumnSampleIL_CylinderMiddlePipe,
    ColumnSampleIL_CylinderMiddleTrussDiagonalPipe,
    ColumnSampleIL_CylinderMiddleSupWorkedPipe,
    ColumnSampleIL_CylinderMiddleSupNormalPipe,
    ColumnSampleIL_CylinderMiddleBracingPipe,
  ],
})
export class ColumnPartIL_CylinderMiddle extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public middlePipeSample: ColumnSampleIL_CylinderMiddlePipe; // 중방 파이프 샘플, 중방 트러스 상현재 파이프 샘플, 중방 트러스 하현재 파이프 샘플
  public middleTrussDiagonalPipeSample: ColumnSampleIL_CylinderMiddleTrussDiagonalPipe; // 중방 트러스 사재 파이프 샘플
  public middleSupportWorkedPipeSample: ColumnSampleIL_CylinderMiddleSupWorkedPipe; // 중방 받침대 샘플
  public middleSupportNormalPipeSample: ColumnSampleIL_CylinderMiddleSupNormalPipe; // 중방 받침대 파이프 샘플
  public middleBracingPipeSample: ColumnSampleIL_CylinderMiddleBracingPipe; // 중방 브레싱 파이프 샘플

  // 아이템
  public middle_pipe: ItemPipe;
  public middleTrussLower_pipe: ItemPipe;
  public middleTrussDiagonal_pipe: ItemPipe;
  public middleAndMiddle_connectionPin: ItemConnector;
  public middleAndMiddle_screw: ItemScrew;
  public middleAndColumn_fixAsibaClamp: ItemHolder;
  public middleTruss_bracket: ItemBracket;
  public middleTruss_boltAndNut: ItemFrameOther;
  public middleSupportWorked_middleSupportPipe: ItemSupportPipe;
  public middleSupportWorked_screw: ItemScrew;
  public middleSupportNormal_pipe: ItemPipe;
  public middleSupportNormal_middlePairClamp: ItemHolder;
  public middleSupportNormal_middleSoleClamp: ItemHolder;
  public middleSupportNormal_welding: ItemOtherExpense;
  public middleBracing_pipe: ItemPipe;
  public middle_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _middleLength: number; // 중방 길이
  @jsonMember(Number)
  public _middleLineNumber: number; // 중방 줄수

  @jsonMember(Boolean)
  public _trussSelected: boolean = false; // 중방 트러스 선택
  @jsonMember(Number)
  public _trussDiagonalLength: number; // 중방 트러스 사재 길이
  @jsonMember(Number)
  public _trussDiagonalQuantity: number; // 중방 트러스 사재 수량

  @jsonMember(Boolean)
  public _middleSupportSelected: boolean; // 중방 받침대 선택
  @jsonMember(String)
  public _middleSupportType: string; // 중방 받침대 형태
  @jsonMember(Number)
  public _middleSupportNormalLength: number; // 일반 중방 받침대 길이

  @jsonMember(Boolean)
  public _middleBracingSelected: boolean = false; // 중방 브레싱 선택

  /**
   * 중방 길이
   */
  public get middleLength(): number {
    return this._middleLength;
  }

  //
  public set middleLength(value: number) {
    this._middleLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 줄수
   */
  public get middleLineNumber(): number {
    return this._middleLineNumber;
  }

  //
  public set middleLineNumber(value: number) {
    this._middleLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 관수 제어부
    this.struct.mainpipeWork.level1.wholePosition.upperFramePart.algorithmPart();
  }

  /**
   * 중방 트러스 선택
   */
  public get trussSelected(): boolean {
    return this._trussSelected;
  }

  //
  public set trussSelected(value: boolean) {
    this._trussSelected = value;

    // 알고리즘
    this.algorithm_middleSupportSelected();
    this.algorithmSpec_middleTrussLower_pipe();
    this.algorithmPart();
  }

  /**
   * 중방 트러스 사재 길이
   */
  public get trussDiagonalLength(): number {
    return this._trussDiagonalLength;
  }

  //
  public set trussDiagonalLength(value: number) {
    this._trussDiagonalLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.middleTrussDiagonalPipeSample.algorithmSpec_specLength();
    this.algorithmPart();
  }

  /**
   * 중방 트러스 사재 수량
   */
  public get trussDiagonalQuantity(): number {
    return this._trussDiagonalQuantity;
  }

  //
  public set trussDiagonalQuantity(value: number) {
    this._trussDiagonalQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 받침대 선택
   */
  public get middleSupportSelected(): boolean {
    return this._middleSupportSelected;
  }

  //
  public set middleSupportSelected(value: boolean) {
    this._middleSupportSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 받침대 형태
   */
  public get middleSupportType(): string {
    return this._middleSupportType;
  }

  //
  public set middleSupportType(value: string) {
    this._middleSupportType = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 일반 중방 받침대 길이
   */
  public get middleSupportNormalLength(): number {
    return this._middleSupportNormalLength;
  }

  //
  public set middleSupportNormalLength(value: number) {
    this._middleSupportNormalLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 브레싱 선택
   */
  public get middleBracingSelected(): boolean {
    return this._middleBracingSelected;
  }

  //
  public set middleBracingSelected(value: boolean) {
    this._middleBracingSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.middlePipeSample = new ColumnSampleIL_CylinderMiddlePipe();
    this.middleTrussDiagonalPipeSample = new ColumnSampleIL_CylinderMiddleTrussDiagonalPipe();
    this.middleSupportWorkedPipeSample = new ColumnSampleIL_CylinderMiddleSupWorkedPipe();
    this.middleSupportNormalPipeSample = new ColumnSampleIL_CylinderMiddleSupNormalPipe();
    this.middleBracingPipeSample = new ColumnSampleIL_CylinderMiddleBracingPipe();

    this.sampleAC = [
      this.middlePipeSample,
      this.middleTrussDiagonalPipeSample,
      this.middleSupportWorkedPipeSample,
      this.middleSupportNormalPipeSample,
      this.middleBracingPipeSample,
    ];

    // 아이템
    this.middle_pipe = new ItemPipe();
    this.middleTrussLower_pipe = new ItemPipe();
    this.middleTrussDiagonal_pipe = new ItemPipe();
    this.middleAndMiddle_connectionPin = new ItemConnector();
    this.middleAndMiddle_screw = new ItemScrew();
    this.middleAndColumn_fixAsibaClamp = new ItemHolder();
    this.middleTruss_bracket = new ItemBracket();
    this.middleTruss_boltAndNut = new ItemFrameOther();
    this.middleSupportWorked_middleSupportPipe = new ItemSupportPipe();
    this.middleSupportWorked_screw = new ItemScrew();
    this.middleSupportNormal_pipe = new ItemPipe();
    this.middleSupportNormal_middlePairClamp = new ItemHolder();
    this.middleSupportNormal_middleSoleClamp = new ItemHolder();
    this.middleSupportNormal_welding = new ItemOtherExpense();
    this.middleBracing_pipe = new ItemPipe();
    this.middle_welding = new ItemOtherExpense();

    this.itemAC = [
      this.middle_pipe,
      this.middleTrussLower_pipe,
      this.middleTrussDiagonal_pipe,
      this.middleAndMiddle_connectionPin,
      this.middleAndMiddle_screw,
      this.middleAndColumn_fixAsibaClamp,
      this.middleTruss_bracket,
      this.middleTruss_boltAndNut,
      this.middleSupportWorked_middleSupportPipe,
      this.middleSupportWorked_screw,
      this.middleSupportNormal_pipe,
      this.middleSupportNormal_middlePairClamp,
      this.middleSupportNormal_middleSoleClamp,
      this.middleSupportNormal_welding,
      this.middleBracing_pipe,
      this.middle_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.middlePipeSample = <ColumnSampleIL_CylinderMiddlePipe>this.sampleAC[0];
    this.middleTrussDiagonalPipeSample = <ColumnSampleIL_CylinderMiddleTrussDiagonalPipe>this.sampleAC[1];
    this.middleSupportWorkedPipeSample = <ColumnSampleIL_CylinderMiddleSupWorkedPipe>this.sampleAC[2];
    this.middleSupportNormalPipeSample = <ColumnSampleIL_CylinderMiddleSupNormalPipe>this.sampleAC[3];
    this.middleBracingPipeSample = <ColumnSampleIL_CylinderMiddleBracingPipe>this.sampleAC[4];

    // 아이템
    this.middle_pipe = <ItemPipe>this.itemAC[0];
    this.middleTrussLower_pipe = <ItemPipe>this.itemAC[1];
    this.middleTrussDiagonal_pipe = <ItemPipe>this.itemAC[2];
    this.middleAndMiddle_connectionPin = <ItemConnector>this.itemAC[3];
    this.middleAndMiddle_screw = <ItemScrew>this.itemAC[4];
    this.middleAndColumn_fixAsibaClamp = <ItemHolder>this.itemAC[5];
    this.middleTruss_bracket = <ItemBracket>this.itemAC[6];
    this.middleTruss_boltAndNut = <ItemFrameOther>this.itemAC[7];
    this.middleSupportWorked_middleSupportPipe = <ItemSupportPipe>this.itemAC[8];
    this.middleSupportWorked_screw = <ItemScrew>this.itemAC[9];
    this.middleSupportNormal_pipe = <ItemPipe>this.itemAC[10];
    this.middleSupportNormal_middlePairClamp = <ItemHolder>this.itemAC[11];
    this.middleSupportNormal_middleSoleClamp = <ItemHolder>this.itemAC[12];
    this.middleSupportNormal_welding = <ItemOtherExpense>this.itemAC[13];
    this.middleBracing_pipe = <ItemPipe>this.itemAC[14];
    this.middle_welding = <ItemOtherExpense>this.itemAC[15];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param trussSelected: boolean 중방 트러스 선택
   * @param trussDiagonalLength: number 중방 트러스 사재 길이
   * @param trussDiagonalQuantity: number 중방 트러스 사재 수량
   * @param middleSupportSelected: boolean 중방 받침대 선택
   * @param middleSupportType: string 중방 받침대 형태
   * @param middleSupportNormalLength: number 일반 중방 받침대 길이
   * @param middleBracingSelected: boolean 중방 브레싱 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    trussSelected: boolean = false,
    trussDiagonalLength: number = 0,
    trussDiagonalQuantity: number = 0,
    middleSupportSelected: boolean = false,
    middleSupportType: string = "",
    middleSupportNormalLength: number = 0,
    middleBracingSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._trussSelected = trussSelected;
    this._trussDiagonalLength = trussDiagonalLength;
    this._trussDiagonalQuantity = trussDiagonalQuantity;
    this._middleSupportSelected = middleSupportSelected;
    this._middleSupportType = middleSupportType;
    this._middleSupportNormalLength = middleSupportNormalLength;
    this._middleBracingSelected = middleBracingSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.middlePipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.middleTrussDiagonalPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "원형, 일반, 22.2mm, 1.2T, 0.7m, -",
      );
      this.middleSupportWorkedPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_MIDDLE_SUPPORT_PIPE,
        CONST.ITEM_NAME_MIDDLE_SUPPORT_PIPE,
        "중방받침대",
        "원형, 일반, 31.8mm",
      );
      this.middleSupportNormalPipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방받침대",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.middleBracingPipeSample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 브레싱",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.middle_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.middleTrussLower_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 트러스 하현재",
        "각형, 일반, 60×60mm, 2.1T, 7m, -",
      );
      this.middleTrussDiagonal_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 트러스 사재",
        "원형, 일반, 22.2mm, 1.2T, 0.7m, -",
      );
      this.middleAndMiddle_connectionPin.setDefaultData(
        3,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "중방 + 중방",
        "원형, 일반, 48mm, 48mm",
      );
      this.middleAndMiddle_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "중방 + 중방",
        "육각, 일반, 14*25mm, 500개",
      );
      this.middleAndColumn_fixAsibaClamp.setDefaultData(
        5,
        CONST.ITEM_ID_FIX_ASIBA_CLAMP,
        CONST.ITEM_NAME_FIX_ASIBA_CLAMP,
        "중방 + 기둥",
        "원형, 일반, 48mm, 48mm",
      );
      this.middleTruss_bracket.setDefaultData(
        6,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "중방 트러스",
        "일반, 9T, 75×600mm",
      );
      this.middleTruss_boltAndNut.setDefaultData(
        7,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "중방 트러스",
        "육각, M16*180mm",
      );
      this.middleSupportWorked_middleSupportPipe.setDefaultData(
        8,
        CONST.ITEM_ID_MIDDLE_SUPPORT_PIPE,
        CONST.ITEM_NAME_MIDDLE_SUPPORT_PIPE,
        "중방받침대",
        "원형, 일반, 31.8mm",
      );
      this.middleSupportWorked_screw.setDefaultData(
        9,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "중방받침대 + 중방/기둥",
        "육각, 일반, 14*25mm, 500개",
      );
      this.middleSupportNormal_pipe.setDefaultData(
        10,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방받침대",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.middleSupportNormal_middlePairClamp.setDefaultData(
        11,
        CONST.ITEM_ID_MIDDLE_PAIR_CLAMP,
        CONST.ITEM_NAME_MIDDLE_PAIR_CLAMP,
        "중방받침대 + 중방/기둥",
        "원형, 일반, 48mm, 32mm",
      );
      this.middleSupportNormal_middleSoleClamp.setDefaultData(
        12,
        CONST.ITEM_ID_MIDDLE_SOLE_CLAMP,
        CONST.ITEM_NAME_MIDDLE_SOLE_CLAMP,
        "중방받침대 + 중방/기둥",
        "원형, 일반, 48mm, 32mm",
      );
      this.middleSupportNormal_welding.setDefaultData(
        13,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "중방받침대 + 중방/기둥",
        "일반",
      );
      this.middleBracing_pipe.setDefaultData(
        14,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 브레싱",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.middle_welding.setDefaultData(15, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "중방", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 2) {
      // 일반 중방 받침대 샘플 추가
      const middleSupportNormalPipeSample: ColumnSampleIL_CylinderMiddleSupNormalPipe =
        new ColumnSampleIL_CylinderMiddleSupNormalPipe();
      middleSupportNormalPipeSample.setAssociation(design, struct, work, level, position, this);
      middleSupportNormalPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방받침대",
        "원형, 일반, 31.8mm, 1.5T, 10m",
      );
      this.sampleAC.push(middleSupportNormalPipeSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }
    if (this.sampleAC.length === 3) {
      // 중방 트러스 사재 파이프 샘플 추가
      const middleTrussDiagonalPipeSample: ColumnSampleIL_CylinderMiddleTrussDiagonalPipe =
        new ColumnSampleIL_CylinderMiddleTrussDiagonalPipe();
      middleTrussDiagonalPipeSample.setAssociation(design, struct, work, level, position, this);
      middleTrussDiagonalPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방",
        "원형, 일반, 22.2mm, 1.2T, 0.7m, -",
      );
      this.sampleAC.splice(1, 0, middleTrussDiagonalPipeSample);
      // 중방 브레싱 파이프 샘플 추가
      const middleBracingPipeSample: ColumnSampleIL_CylinderMiddleBracingPipe =
        new ColumnSampleIL_CylinderMiddleBracingPipe();
      middleBracingPipeSample.setAssociation(design, struct, work, level, position, this);
      middleBracingPipeSample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 브레싱",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      this.sampleAC.splice(4, 0, middleBracingPipeSample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    // 아이템
    if (this.itemAC.length === 6) {
      // 중방받침대 파이프 추가
      const middleSupportNormal_pipe: ItemPipe = new ItemPipe();
      middleSupportNormal_pipe.setAssociation(design, struct, work, level, position, this);
      middleSupportNormal_pipe.setDefaultData(
        6,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방받침대",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      middleSupportNormal_pipe.selected = false;
      middleSupportNormal_pipe.visible = false;
      this.itemAC.push(middleSupportNormal_pipe);
      // 중방받침대 중방쌍반도 추가
      const middleSupportNormal_middlePairClamp: ItemHolder = new ItemHolder();
      middleSupportNormal_middlePairClamp.setAssociation(design, struct, work, level, position, this);
      middleSupportNormal_middlePairClamp.setDefaultData(
        7,
        CONST.ITEM_ID_MIDDLE_PAIR_CLAMP,
        CONST.ITEM_NAME_MIDDLE_PAIR_CLAMP,
        "중방받침대 + 중방/기둥",
        "원형, 일반, 48mm, 32mm",
      );
      middleSupportNormal_middlePairClamp.selected = false;
      middleSupportNormal_middlePairClamp.visible = false;
      this.itemAC.push(middleSupportNormal_middlePairClamp);
      // 중방받침대 중방외반도 추가
      const middleSupportNormal_middleSoleClamp: ItemHolder = new ItemHolder();
      middleSupportNormal_middleSoleClamp.setAssociation(design, struct, work, level, position, this);
      middleSupportNormal_middleSoleClamp.setDefaultData(
        8,
        CONST.ITEM_ID_MIDDLE_SOLE_CLAMP,
        CONST.ITEM_NAME_MIDDLE_SOLE_CLAMP,
        "중방받침대 + 중방/기둥",
        "원형, 일반, 48mm, 32mm",
      );
      middleSupportNormal_middleSoleClamp.selected = false;
      middleSupportNormal_middleSoleClamp.visible = false;
      this.itemAC.push(middleSupportNormal_middleSoleClamp);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 9) {
      // 용접 추가
      const middleSupportNormal_welding: ItemOtherExpense = new ItemOtherExpense();
      middleSupportNormal_welding.setAssociation(design, struct, work, level, position, this);
      middleSupportNormal_welding.setDefaultData(
        9,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "중방받침대 + 중방/기둥",
        "일반",
      );
      middleSupportNormal_welding.selected = false;
      middleSupportNormal_welding.visible = false;
      this.itemAC.push(middleSupportNormal_welding);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }
    if (this.itemAC.length === 10 && (<Item>this.itemAC[1]).productId === CONST.ITEM_ID_CONNECTION_PIN) {
      // 중방 트러스 하현재 파이프 추가
      const middleTrussLower_pipe: ItemPipe = new ItemPipe();
      middleTrussLower_pipe.setAssociation(design, struct, work, level, position, this);
      middleTrussLower_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 트러스 하현재",
        "각형, 일반, 60×60mm, 2.1T, 7m, -",
      );
      middleTrussLower_pipe.selected = false;
      middleTrussLower_pipe.visible = false;
      this.itemAC.splice(1, 0, middleTrussLower_pipe);
      // 중방 트러스 사재 파이프 추가
      const middleTrussDiagonal_pipe: ItemPipe = new ItemPipe();
      middleTrussDiagonal_pipe.setAssociation(design, struct, work, level, position, this);
      middleTrussDiagonal_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 트러스 사재",
        "원형, 일반, 22.2mm, 1.2T, 0.7m, -",
      );
      middleTrussDiagonal_pipe.selected = false;
      middleTrussDiagonal_pipe.visible = false;
      this.itemAC.splice(2, 0, middleTrussDiagonal_pipe);
      // 중방 트러스 브라켓트 추가
      const middleTruss_bracket: ItemBracket = new ItemBracket();
      middleTruss_bracket.setAssociation(design, struct, work, level, position, this);
      middleTruss_bracket.setDefaultData(
        6,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "중방 트러스",
        "일반, 9T, 75×600mm",
      );
      middleTruss_bracket.selected = false;
      middleTruss_bracket.visible = false;
      this.itemAC.splice(6, 0, middleTruss_bracket);
      // 중방 트러스 볼트&너트 추가
      const middleTruss_boltAndNut: ItemFrameOther = new ItemFrameOther();
      middleTruss_boltAndNut.setAssociation(design, struct, work, level, position, this);
      middleTruss_boltAndNut.setDefaultData(
        7,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "중방 트러스",
        "육각, M16*180mm",
      );
      middleTruss_boltAndNut.selected = false;
      middleTruss_boltAndNut.visible = false;
      this.itemAC.splice(7, 0, middleTruss_boltAndNut);
      // 중방 브레싱 파이프 추가
      const middleBracing_pipe: ItemPipe = new ItemPipe();
      middleBracing_pipe.setAssociation(design, struct, work, level, position, this);
      middleBracing_pipe.setDefaultData(
        14,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "중방 브레싱",
        "원형, 일반, 48.1mm, 2.1T, 7m, -",
      );
      middleBracing_pipe.selected = false;
      middleBracing_pipe.visible = false;
      this.itemAC.splice(14, 0, middleBracing_pipe);
      // 중방 용접 추가
      const middle_welding: ItemOtherExpense = new ItemOtherExpense();
      middle_welding.setAssociation(design, struct, work, level, position, this);
      middle_welding.setDefaultData(15, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "중방", "일반");
      middle_welding.selected = false;
      middle_welding.visible = false;
      this.itemAC.splice(15, 0, middle_welding);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_middleLength();
    this.algorithm_middleLineNumber();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.middle_pipe.selected = true;
    this.middle_pipe.visible = true;
    this.middleAndMiddle_connectionPin.selected = false;
    this.middleAndMiddle_connectionPin.visible = true;
    this.middleAndMiddle_screw.selected = false;
    this.middleAndMiddle_screw.visible = true;
    this.middleAndColumn_fixAsibaClamp.selected = true;
    this.middleAndColumn_fixAsibaClamp.visible = true;
    if (this.trussSelected === true) {
      this.middleTrussLower_pipe.selected = true;
      this.middleTrussLower_pipe.visible = true;
      this.middleTrussDiagonal_pipe.selected = true;
      this.middleTrussDiagonal_pipe.visible = true;
      this.middleTruss_bracket.selected = true;
      this.middleTruss_bracket.visible = true;
      this.middleTruss_boltAndNut.selected = true;
      this.middleTruss_boltAndNut.visible = true;
      this.middleAndColumn_fixAsibaClamp.selected = false;
      this.middleAndColumn_fixAsibaClamp.visible = false;
    } else {
      this.middleTrussLower_pipe.selected = false;
      this.middleTrussLower_pipe.visible = false;
      this.middleTrussDiagonal_pipe.selected = false;
      this.middleTrussDiagonal_pipe.visible = false;
      this.middleTruss_bracket.selected = false;
      this.middleTruss_bracket.visible = false;
      this.middleTruss_boltAndNut.selected = false;
      this.middleTruss_boltAndNut.visible = false;
    }
    if (this.middleSupportSelected === true) {
      if (this.middleSupportType === CONST.LB_COLUMN_MIDDLE_SUPPORT_TYPE_WORKED_PIPE) {
        this.middleSupportWorked_middleSupportPipe.selected = true;
        this.middleSupportWorked_middleSupportPipe.visible = true;
        this.middleSupportWorked_screw.selected = true;
        this.middleSupportWorked_screw.visible = true;
        this.middleSupportNormal_pipe.selected = false;
        this.middleSupportNormal_pipe.visible = false;
        this.middleSupportNormal_middlePairClamp.selected = false;
        this.middleSupportNormal_middlePairClamp.visible = false;
        this.middleSupportNormal_middleSoleClamp.selected = false;
        this.middleSupportNormal_middleSoleClamp.visible = false;
        this.middleSupportNormal_welding.selected = false;
        this.middleSupportNormal_welding.visible = false;
      } else if (this.middleSupportType === CONST.LB_COLUMN_MIDDLE_SUPPORT_TYPE_NORMAL_PIPE_AND_ENHANCED_CLAMP) {
        this.middleSupportWorked_middleSupportPipe.selected = false;
        this.middleSupportWorked_middleSupportPipe.visible = false;
        this.middleSupportWorked_screw.selected = false;
        this.middleSupportWorked_screw.visible = false;
        this.middleSupportNormal_pipe.selected = true;
        this.middleSupportNormal_pipe.visible = true;
        this.middleSupportNormal_middlePairClamp.selected = true;
        this.middleSupportNormal_middlePairClamp.visible = true;
        this.middleSupportNormal_middleSoleClamp.selected = true;
        this.middleSupportNormal_middleSoleClamp.visible = true;
        this.middleSupportNormal_welding.selected = false;
        this.middleSupportNormal_welding.visible = false;
      } else if (this.middleSupportType === CONST.LB_COLUMN_MIDDLE_SUPPORT_TYPE_NORMAL_PIPE_AND_WELDING) {
        this.middleSupportWorked_middleSupportPipe.selected = false;
        this.middleSupportWorked_middleSupportPipe.visible = false;
        this.middleSupportWorked_screw.selected = false;
        this.middleSupportWorked_screw.visible = false;
        this.middleSupportNormal_pipe.selected = true;
        this.middleSupportNormal_pipe.visible = true;
        this.middleSupportNormal_middlePairClamp.selected = false;
        this.middleSupportNormal_middlePairClamp.visible = false;
        this.middleSupportNormal_middleSoleClamp.selected = false;
        this.middleSupportNormal_middleSoleClamp.visible = false;
        this.middleSupportNormal_welding.selected = true;
        this.middleSupportNormal_welding.visible = true;
      }
    } else {
      this.middleSupportWorked_middleSupportPipe.selected = false;
      this.middleSupportWorked_middleSupportPipe.visible = false;
      this.middleSupportWorked_screw.selected = false;
      this.middleSupportWorked_screw.visible = false;
      this.middleSupportNormal_pipe.selected = false;
      this.middleSupportNormal_pipe.visible = false;
      this.middleSupportNormal_middlePairClamp.selected = false;
      this.middleSupportNormal_middlePairClamp.visible = false;
      this.middleSupportNormal_middleSoleClamp.selected = false;
      this.middleSupportNormal_middleSoleClamp.visible = false;
      this.middleSupportNormal_welding.selected = false;
      this.middleSupportNormal_welding.visible = false;
    }
    if (this.middleBracingSelected === true) {
      this.middleBracing_pipe.selected = true;
      this.middleBracing_pipe.visible = true;
    } else {
      this.middleBracing_pipe.selected = false;
      this.middleBracing_pipe.visible = false;
    }
    this.middle_welding.selected = true;
    this.middle_welding.visible = true;

    /// //////// 용도 ///////////

    if (this.trussSelected === true) {
      this.middle_pipe.purpose = "중방 트러스 상현재";
    } else {
      this.middle_pipe.purpose = "중방";
    }

    /// //////// 수량 ///////////

    this.middle_pipe.designQuantity =
      (this.middle_pipe.getConnectedPipeLength(this.middleLength, this.middleLineNumber) /
        this.middle_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_MIDDLE_PIPE;

    this.middleTrussLower_pipe.designQuantity = this.middle_pipe.designQuantity;

    this.middleTrussDiagonal_pipe.designQuantity =
      this.trussDiagonalQuantity * this.middleLineNumber * CONST.NUM_EXTRA_RATE_MIDDLE_PIPE;

    if (this.middleLength * this.basic.buildingNumber > this.middle_pipe.specLength) {
      this.middleAndMiddle_connectionPin.designQuantity =
        this.middle_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
      if (this.trussSelected === true) {
        this.middleAndMiddle_connectionPin.designQuantity +=
          this.middleTrussLower_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
      }
    } else {
      this.middleAndMiddle_connectionPin.designQuantity = 0;
    }

    this.middleAndMiddle_screw.designQuantity =
      ((this.middleAndMiddle_connectionPin.getSelectedQuantity() * 4) / this.middleAndMiddle_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.middleAndColumn_fixAsibaClamp.designQuantity =
      this.middleLineNumber * (this.basic.buildingNumber + 1) * CONST.NUM_EXTRA_RATE_FIX_ASIBA_CLAMP;

    this.middleTruss_bracket.designQuantity = this.middleLineNumber * 4;

    this.middleTruss_boltAndNut.designQuantity = this.trussDiagonalQuantity * 6;

    this.middleSupportWorked_middleSupportPipe.designQuantity = this.middleLineNumber * 2 * this.basic.buildingNumber;

    this.middleSupportWorked_screw.designQuantity =
      ((this.middleSupportWorked_middleSupportPipe.getSelectedQuantity() * 4) /
        this.middleAndMiddle_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.middleSupportNormal_pipe.designQuantity =
      (this.middleLineNumber * 2 * this.basic.buildingNumber) /
      CommonUtil.roundUp(this.middleSupportNormal_pipe.specLength / this.middleSupportNormalLength);

    this.middleSupportNormal_middlePairClamp.designQuantity =
      this.middleLineNumber * (this.basic.buildingNumber - 1) * CONST.NUM_EXTRA_RATE_MIDDLE_PAIR_CLAMP;

    this.middleSupportNormal_middleSoleClamp.designQuantity =
      (this.middleLineNumber * 2 * this.basic.buildingNumber + this.middleLineNumber * 2) *
      CONST.NUM_EXTRA_RATE_MIDDLE_SOLE_CLAMP;

    this.middleBracing_pipe.designQuantity = 2 * this.basic.buildingNumber;

    this.middleSupportNormal_welding.designQuantity = this.middleLineNumber * 4 * this.basic.buildingNumber;

    let middle_welding_designQuantity: number = 0;
    if (this.trussSelected === true) {
      middle_welding_designQuantity += this.trussDiagonalQuantity * 2;
    }
    middle_welding_designQuantity += this.middleBracing_pipe.getSelectedQuantity() * 2;
    this.middle_welding.designQuantity = middle_welding_designQuantity;
  }

  /**
   * 알고리즘: 중방 길이 <- 폭(기본정보), 동수(기본정보)
   */
  public algorithm_middleLength(): void {
    this.middleLength = this.basicLevel.width * this.basic.buildingNumber;
  }

  /**
   * 알고리즘: 중방 줄수 <- 기둥 간격(원형 기둥 파트), 2중 마구리 기둥 선택(원형 기둥 파트), 길이(기본정보)
   */
  public algorithm_middleLineNumber(): void {
    let middleLineNumberTemp: number =
      Math.ceil(this.basicLevel.length / (<ColumnPositionIL>this.position).cylinderColumnPart.columnInterval) + 1;

    if ((<ColumnPositionIL>this.position).cylinderColumnPart.secondEndpieceColumnSelected === true) {
      middleLineNumberTemp += 2;
    }
    this.middleLineNumber = middleLineNumberTemp;
  }

  /**
   * 알고리즘: 중방 받침대 선택 <- 중방 트러스 선택
   */
  public algorithm_middleSupportSelected(): void {
    this.middleSupportSelected = !this.trussSelected;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [중방] 파이프 <- 중방 파이프 샘플
   */
  public algorithmSpec_middle_pipe(): void {
    this.middle_pipe.specs = this.middlePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 트러스 하현재] 파이프 <- 중방 파이프 샘플
   */
  public algorithmSpec_middleTrussLower_pipe(): void {
    this.middleTrussLower_pipe.specs = this.middlePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 트러스] 파이프 <- 중방 트러스 사재 파이프 샘플
   */
  public algorithmSpec_middleTrussDiagonal_pipe(): void {
    this.middleTrussDiagonal_pipe.specs = this.middleTrussDiagonalPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방 + 중방] 연결핀 <- 중방 파이프 샘플
   */
  public algorithmSpec_middleAndMiddle_connectionPin(): void {
    this.middleAndMiddle_connectionPin.specPipeType = this.middlePipeSample.specPipeType;
    this.middleAndMiddle_connectionPin.specCrossSize1 = this.middlePipeSample.specCrossSize;
    this.middleAndMiddle_connectionPin.specCrossSize2 = this.middlePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [중방 + 중방] 나사 <- 공통 샘플
   */
  public algorithmSpec_middleAndMiddle_screw(): void {}

  /**
   * 규격 알고리즘: [중방 + 기둥] 고정 아시바 클램프 <- 중방 파이프 샘플, 기둥 파이프 샘플
   */
  public algorithmSpec_middleAndColumn_fixAsibaClamp(): void {
    this.middleAndColumn_fixAsibaClamp.specPipeType = (<ColumnPositionIL>(
      this.position
    )).cylinderColumnPart.columnPipeSample.specPipeType;
    this.middleAndColumn_fixAsibaClamp.specCrossSize1 = (<ColumnPositionIL>(
      this.position
    )).cylinderColumnPart.columnPipeSample.specCrossSize;
    this.middleAndColumn_fixAsibaClamp.specCrossSize2 = this.middlePipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [중방 트러스] 브라켓트
   */
  public algorithmSpec_middleTruss_bracket(): void {}

  /**
   * 규격 알고리즘: [중방 트러스] 볼트&너트
   */
  public algorithmSpec_middleTruss_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [중방받침대] 중방 받침대 <- 중방 받침대 샘플
   */
  public algorithmSpec_middleSupportWorked_middleSupportPipe(): void {
    this.middleSupportWorked_middleSupportPipe.specs = this.middleSupportWorkedPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방받침대 + 중방/기둥] 나사 <- 공통 샘플
   */
  public algorithmSpec_middleSupportWorked_screw(): void {}

  /**
   * 규격 알고리즘: [중방받침대] 파이프 <- 중방 받침 파이프 샘플
   */
  public algorithmSpec_middleSupportNormal_pipe(): void {
    this.middleSupportNormal_pipe.specs = this.middleSupportNormalPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방받침대 + 중방/기둥] 중방쌍반도 <- 중방 받침 파이프 샘플, 기둥 파이프 샘플
   */
  public algorithmSpec_middleSupportNormal_middlePairClamp(): void {
    this.middleSupportNormal_middlePairClamp.specPipeType = (<ColumnPositionIL>(
      this.position
    )).cylinderColumnPart.columnPipeSample.specPipeType;
    this.middleSupportNormal_middlePairClamp.specCrossSize1 = (<ColumnPositionIL>(
      this.position
    )).cylinderColumnPart.columnPipeSample.specCrossSize;
    this.middleSupportNormal_middlePairClamp.specCrossSize2 = this.middleSupportNormalPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [중방받침대 + 중방/기둥] 중방외반도 <- 중방 받침 파이프 샘플, 기둥 파이프 샘플
   */
  public algorithmSpec_middleSupportNormal_middleSoleClamp(): void {
    this.middleSupportNormal_middleSoleClamp.specPipeType = (<ColumnPositionIL>(
      this.position
    )).cylinderColumnPart.columnPipeSample.specPipeType;
    this.middleSupportNormal_middleSoleClamp.specCrossSize1 = (<ColumnPositionIL>(
      this.position
    )).cylinderColumnPart.columnPipeSample.specCrossSize;
    this.middleSupportNormal_middleSoleClamp.specCrossSize2 = this.middleSupportNormalPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [중방받침대 + 중방/기둥] 용접 <- 공통 샘플
   */
  public algorithmSpec_middleSupportNormal_welding(): void {}

  /**
   * 규격 알고리즘: [중방 브레싱] 파이프 <- 중방 브레싱 파이프 샘플
   */
  public algorithmSpec_middleBracing_pipe(): void {
    this.middleBracing_pipe.specs = this.middleBracingPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [중방] 용접 <- 공통 샘플
   */
  public algorithmSpec_middle_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
