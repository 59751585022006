import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPowerSwitcher } from "vhows-design/src/object/design/item/list/ItemPowerSwitcher";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { SwitcherPart_Power } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Power";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-20
 */
@jsonObject
export class SwitcherSample_PowerSwitcher extends ItemPowerSwitcher {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const powerPart: SwitcherPart_Power = <SwitcherPart_Power>this.part;
    powerPart.algorithmSpec_power_powerSwitcher();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 제어기
    this.struct.controllerWork.level1.integratedPosition.algorithm_usageSwitcherQuantity();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 개폐 축 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    if (this.part.label === CONST.LB_SWITCHER_POWER1) {
      this.specAxisCoupling = (<SwitcherPosition>this.position).axisPart.axisPipeSample.specCrossSize;
      this.specSupportFixingPin = (<SwitcherPosition>(
        this.position
      )).supportPart.diagonalSupportAssistPipeSample.specCrossSize;
    } else if (this.part.label === CONST.LB_SWITCHER_POWER2) {
      this.specAxisCoupling = (<SwitcherPosition>this.position).axisPart.axisPipe2Sample.specCrossSize;
      this.specSupportFixingPin = (<SwitcherPosition>(
        this.position
      )).supportPart.diagonalSupportAssistPipe2Sample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [지지커플링] <- 지지 형태, 개폐기 지지 파이프 샘플, 개폐기 지지 보조 파이프 샘플
   */
  public algorithmSpec_supportCoupling(): void {
    if ((<SwitcherPosition>this.position).supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL) {
      this.specSupportCoupling = (<SwitcherPosition>this.position).supportPart.verticalSupportPipeSample.specCrossSize;
    } else if ((<SwitcherPosition>this.position).supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL) {
      this.specSupportCoupling = (<SwitcherPosition>this.position).supportPart.diagonalSupportPipeSample.specCrossSize;
    } else if ((<SwitcherPosition>this.position).supportType === CONST.LB_SWITCHER_SUPPPORT_TYPE_RAFTER) {
      this.specSupportCoupling = (<SwitcherPosition>this.position).supportPart.rafterSupportPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [지지커플링] <- 지지 형태(2차), 개폐기 지지 파이프 샘플(2차), 개폐기 지지 보조 파이프 샘플(2차)
   */
  public algorithmSpec_support2Coupling(): void {
    if ((<SwitcherPosition>this.position).support2Type === CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL) {
      this.specSupportCoupling = (<SwitcherPosition>this.position).supportPart.verticalSupportPipe2Sample.specCrossSize;
    } else if ((<SwitcherPosition>this.position).support2Type === CONST.LB_SWITCHER_SUPPPORT_TYPE_DIAGONAL) {
      this.specSupportCoupling = (<SwitcherPosition>this.position).supportPart.diagonalSupportPipe2Sample.specCrossSize;
    } else if ((<SwitcherPosition>this.position).support2Type === CONST.LB_SWITCHER_SUPPPORT_TYPE_RAFTER) {
      this.specSupportCoupling = (<SwitcherPosition>this.position).supportPart.rafterSupportPipe2Sample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘 <- 선택(기초 피복), 범위 형태(기초 피복), 형태(기초 피복), 피복 추가(기초 피복), 피복 삭제(기초 피복)
   */
  public algorithmSpec_specUsage(): void {
    const coverLevel: CoverLevel = <CoverLevel>this.struct.coverWork.levelAC[this.level.index];
    if (this.part.label === CONST.LB_SWITCHER_POWER1) {
      if (this.specUsage !== CONST.ITEM_SPEC_VALUE_LAGGING_QUILTING) {
        if (coverLevel.coverThicknesses1[this.position.index] < CONST.LB_COVER_SWITCHER_USAGE_VALUE_NONWOVEN) {
          this.specUsage = CONST.ITEM_SPEC_VALUE_VINYL_USAGE;
        } else if (coverLevel.coverThicknesses1[this.position.index] < CONST.LB_COVER_SWITCHER_USAGE_VALUE_LAGGING) {
          this.specUsage = CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE;
        } else {
          this.specUsage = CONST.ITEM_SPEC_VALUE_LAGGING_USAGE;
        }
      } else {
        this.specUsage = CONST.ITEM_SPEC_VALUE_LAGGING_QUILTING;
      }
    } else if (this.part.label === CONST.LB_SWITCHER_POWER2) {
      if (this.specUsage !== CONST.ITEM_SPEC_VALUE_LAGGING_QUILTING) {
        if (coverLevel.coverThicknesses2[this.position.index] < CONST.LB_COVER_SWITCHER_USAGE_VALUE_NONWOVEN) {
          this.specUsage = CONST.ITEM_SPEC_VALUE_VINYL_USAGE;
        } else if (coverLevel.coverThicknesses2[this.position.index] < CONST.LB_COVER_SWITCHER_USAGE_VALUE_LAGGING) {
          this.specUsage = CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE;
        } else {
          this.specUsage = CONST.ITEM_SPEC_VALUE_LAGGING_USAGE;
        }
      } else {
        this.specUsage = CONST.ITEM_SPEC_VALUE_LAGGING_QUILTING;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
