import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemLagging } from "vhows-design/src/object/design/item/list/ItemLagging";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPartIL } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL";
import { CoverSampleIL_ColorLagging } from "vhows-design/src/object/design/cover/cover/il/CoverSampleIL_ColorLagging";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-29
 */
@jsonObject({
  knownTypes: [CoverSampleIL_ColorLagging],
})
export class CoverPartIL_ColorLagging extends CoverPartIL {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public colorLaggingSample: CoverSampleIL_ColorLagging; // 칼라 보온덮개 샘플

  // 아이템
  protected item: ItemLagging; // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Boolean)
  public _fixedLengthRoof: boolean; // 단위길이 고정(지붕)

  /**
   * 단위길이 고정(지붕)
   */
  public get fixedLengthRoof(): boolean {
    return this._fixedLengthRoof;
  }

  //
  public set fixedLengthRoof(value: boolean) {
    this._fixedLengthRoof = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.colorLaggingSample = new CoverSampleIL_ColorLagging();

    this.sampleAC = [this.colorLaggingSample];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.colorLaggingSample = <CoverSampleIL_ColorLagging>this.sampleAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param fixedLengthRoof: boolean 단위길이 고정(지붕)
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    fixedLengthRoof: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._fixedLengthRoof = fixedLengthRoof;

    // 샘플
    if (this.level.index >= 0) {
      this.colorLaggingSample.setDefaultData(
        0,
        CONST.ITEM_ID_COLOR_LAGGING,
        CONST.ITEM_NAME_COLOR_LAGGING,
        "",
        "A급, 2.4m, 20m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    super.algorithmPart();

    //----------------------------------
    // 품목
    // 피복객체의 값을 실제 품목에 반영
    //----------------------------------

    // 측면 및 지붕
    if (this.coverPosition.scopeSelectedSide === true || this.coverPosition.scopeSelectedRoof === true) {
      // 2동이상
      if (this.basic.buildingNumber >= 2) {
        // 양끝동 지붕(+측면)
        if (this.coverEdgeRoof != null) {
          this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

          this.item.purpose = `양끝동 피복: ${this.coverEdgeRoof.purpose}`;
          this.item.specWidth = this.colorLaggingSample.specWidth;
          if (this.fixedLengthRoof === true) {
            this.item.specLength = this.colorLaggingSample.specLength;
            this.item.designQuantity =
              (this.coverEdgeRoof.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              (this.coverEdgeRoof.quantity * 2) *
              (this.coverEdgeRoof.width / this.colorLaggingSample.specLength);
          } else {
            this.item.specLength = this.coverEdgeRoof.width;
            this.item.designQuantity =
              (this.coverEdgeRoof.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              (this.coverEdgeRoof.quantity * 2);
          }

          this.itemAC.push(this.item);
        }

        // 양끝동 지붕(+측면) - 비대칭 천창(짧은쪽)
        if (this.coverEdgeSkyShort != null) {
          this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

          this.item.purpose = `양끝동 피복: ${this.coverEdgeSkyShort.purpose}`;
          this.item.specWidth = this.colorLaggingSample.specWidth;
          if (this.fixedLengthRoof === true) {
            this.item.specLength = this.colorLaggingSample.specLength;
            this.item.designQuantity =
              (this.coverEdgeSkyShort.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              (this.coverEdgeSkyShort.quantity * 2) *
              (this.coverEdgeSkyShort.width / this.colorLaggingSample.specLength);
          } else {
            this.item.specLength = this.coverEdgeSkyShort.width;
            this.item.designQuantity =
              (this.coverEdgeSkyShort.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              (this.coverEdgeSkyShort.quantity * 2);
          }

          this.itemAC.push(this.item);
        }

        // 양끝동 측면
        if (this.coverEdgeSide != null) {
          this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

          this.item.purpose = `양끝동 피복: ${this.coverEdgeSide.purpose}`;
          this.item.specWidth = this.colorLaggingSample.specWidth;
          this.item.specLength = this.colorLaggingSample.specLength;
          this.item.designQuantity =
            (this.coverEdgeSide.length / (this.item.specLength - this.cashmilonStyleOverlap)) *
            (this.coverEdgeSide.quantity * 2) *
            (this.coverEdgeSide.width / this.colorLaggingSample.specLength);

          this.itemAC.push(this.item);
        }

        // 중앙동 지붕
        if (this.coverCenterRoof != null) {
          this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

          this.item.purpose = `중앙동 피복: ${this.coverCenterRoof.purpose}`;
          this.item.specWidth = this.colorLaggingSample.specWidth;
          if (this.fixedLengthRoof === true) {
            this.item.specLength = this.colorLaggingSample.specLength;
            this.item.designQuantity =
              (this.coverCenterRoof.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              (this.coverCenterRoof.quantity * (this.basic.buildingNumber - 2)) *
              (this.coverCenterRoof.width / this.colorLaggingSample.specLength);
          } else {
            this.item.specLength = this.coverCenterRoof.width;
            this.item.designQuantity =
              (this.coverCenterRoof.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              (this.coverCenterRoof.quantity * (this.basic.buildingNumber - 2));
          }

          this.itemAC.push(this.item);
        }

        // 중앙동 지붕 - 비대칭 천창(짧은쪽)
        if (this.coverCenterSkyShort != null) {
          this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

          this.item.purpose = `중앙동 피복: ${this.coverCenterSkyShort.purpose}`;
          this.item.specWidth = this.colorLaggingSample.specWidth;
          if (this.fixedLengthRoof === true) {
            this.item.specLength = this.colorLaggingSample.specLength;
            this.item.designQuantity =
              (this.coverCenterSkyShort.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              (this.coverCenterSkyShort.quantity * (this.basic.buildingNumber - 2)) *
              (this.coverCenterSkyShort.width / this.colorLaggingSample.specLength);
          } else {
            this.item.specLength = this.coverCenterSkyShort.width;
            this.item.designQuantity =
              (this.coverCenterSkyShort.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              (this.coverCenterSkyShort.quantity * (this.basic.buildingNumber - 2));
          }

          this.itemAC.push(this.item);
        }
      } else {
        // 측면
        if (this.coverEdgeSide != null) {
          this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

          this.item.purpose = this.coverEdgeSide.purpose;
          this.item.specWidth = this.colorLaggingSample.specWidth;
          this.item.specLength = this.colorLaggingSample.specLength;
          this.item.designQuantity =
            (this.coverEdgeSide.length / (this.item.specLength - this.cashmilonStyleOverlap)) *
            this.coverEdgeSide.quantity *
            (this.coverEdgeSide.width / this.colorLaggingSample.specLength);

          this.itemAC.push(this.item);
        }

        // 지붕 및 측면
        if (this.coverEdgeRoof != null) {
          this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

          this.item.purpose = this.coverEdgeRoof.purpose;
          this.item.specWidth = this.colorLaggingSample.specWidth;
          if (this.fixedLengthRoof === true) {
            this.item.specLength = this.colorLaggingSample.specLength;
            this.item.designQuantity =
              (this.coverEdgeRoof.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              this.coverEdgeRoof.quantity *
              (this.coverEdgeRoof.width / this.colorLaggingSample.specLength);
          } else {
            this.item.specLength = this.coverEdgeRoof.width;
            this.item.designQuantity =
              (this.coverEdgeRoof.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              this.coverEdgeRoof.quantity;
          }

          this.itemAC.push(this.item);
        }

        // 지붕 및 측면 - 비대칭 천창(짧은쪽)
        if (this.coverEdgeSkyShort != null) {
          this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

          this.item.purpose = this.coverEdgeSkyShort.purpose;
          this.item.specWidth = this.colorLaggingSample.specWidth;
          if (this.fixedLengthRoof === true) {
            this.item.specLength = this.colorLaggingSample.specLength;
            this.item.designQuantity =
              (this.coverEdgeSkyShort.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              this.coverEdgeSkyShort.quantity *
              (this.coverEdgeSkyShort.width / this.colorLaggingSample.specLength);
          } else {
            this.item.specLength = this.coverEdgeSkyShort.width;
            this.item.designQuantity =
              (this.coverEdgeSkyShort.length / (this.item.specWidth - this.cashmilonStyleOverlap)) *
              this.coverEdgeSkyShort.quantity;
          }

          this.itemAC.push(this.item);
        }
      }
    }

    // 앞면(+뒷면)
    if (this.coverFront != null) {
      this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

      this.item.purpose = `마구리 피복: ${this.coverFront.purpose}`;
      // 면적계산 설치
      this.item.specWidth = this.colorLaggingSample.specWidth;
      this.item.specLength = this.colorLaggingSample.specLength;
      this.item.designQuantity =
        (this.coverFront.width * this.coverFront.length) /
        ((this.item.specWidth - this.cashmilonStyleOverlap) * this.item.specLength);

      this.itemAC.push(this.item);
    }

    // 뒷면
    if (this.coverBack != null) {
      this.item = ItemLagging.getNewDesignItemBySample(this, this.colorLaggingSample);

      this.item.purpose = `마구리 피복: ${this.coverBack.purpose}`;
      // 면적계산 설치
      this.item.specWidth = this.colorLaggingSample.specWidth;
      this.item.specLength = this.colorLaggingSample.specLength;
      this.item.designQuantity =
        (this.coverBack.width * this.coverBack.length) /
        ((this.item.specWidth - this.cashmilonStyleOverlap) * this.item.specLength);

      this.itemAC.push(this.item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);

    /// //////// 외부 ///////////

    // 알고리즘 호출: 피복 파트
    (<CoverLevelIL>this.level).callAlgorithm_coverPartByCover();

    // 알고리즘 호출: 피복 고정 파트
    (<CoverLevelIL>this.level).callAlgorithm_fixingPartByCover();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 아이템 <- 샘플
   */
  public algorithmSpec_item(): void {
    for (const item of this.itemAC as ItemLagging[]) {
      item.specsList = this.colorLaggingSample.specsList;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
