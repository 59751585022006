import { jsonObject } from "typedjson";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { WingPartVL_WingStraight } from "vhows-design/src/object/design/frame/wing/vl/WingPartVL_WingStraight";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class WingSampleVL_WingStraightPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const wingStraightPart: WingPartVL_WingStraight = <WingPartVL_WingStraight>this.part;
    wingStraightPart.algorithmSpec_wingStraight_pipe();
    wingStraightPart.algorithmSpec_wingStraightAndWingStraight_connectionPin();
    wingStraightPart.algorithmSpec_wingStraightCenterAndWingRafter_steelStringGrabber();
    wingStraightPart.algorithmSpec_wingStraightCenterAndWingRafter_uClamp();
    wingStraightPart.algorithmSpec_floorWingStraightCenterAndWingRafter_steelStringGrabber();
    wingStraightPart.algorithmSpec_floorWingStraightCenterAndWingRafter_uClamp();
    wingStraightPart.algorithmSpec_wingStraightEdgeAndWingRafter_saddleHolder();
    wingStraightPart.algorithmPart();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const wingPosition: WingPositionVL = this.position as WingPositionVL;
    wingPosition.wingRafterPart.algorithmSpec_wingRafterSecondAndWingStraight_innerTHolder();
    wingPosition.wingRafterPart.algorithmSpec_wingRafterEnhanced_uClamp();

    /// //////// 외부 ///////////

    // 피복 고정
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];
    fixingLevel.wingPosition.normalClipPart.normalClipSample.algorithmSpec();

    // // 치마 피복
    // const skirtLevel: SkirtLevelIL = <SkirtLevelIL> this.struct.skirtWorkIL.levelAC[this.level.index];
    // skirtLevel.wingPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
