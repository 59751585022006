import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { ColumnPartVL_SideStraight } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_SideStraight";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-08
 */
@jsonObject
export class ColumnSampleVL_SideStraightPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const sideStraightPart: ColumnPartVL_SideStraight = <ColumnPartVL_SideStraight>this.part;
    sideStraightPart.algorithmSpec_sideStraight_pipe();
    sideStraightPart.algorithmSpec_sideStraightAndSideStraight_connectionPin();
    sideStraightPart.algorithmSpec_sideStraightAndColumn_saddleHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    if (sideStraightPart.sideStraightType === CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_PIPE) {
      // 피복 고정
      for (const fixingLevel of this.struct.fixingWorkIL.levelAC as FixingLevelIL[]) {
        // 모든 중수
        fixingLevel.columnPosition.normalClipPart.normalClipSample.algorithmSpec();
      }

      // 치마 피복
      for (const skirtLevel of this.struct.skirtWorkIL.levelAC as SkirtLevelIL[]) {
        skirtLevel.columnPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      }

      // 마구리
      for (const endpieceLevel of this.struct.endpieceWorkVL.levelAC as EndpieceLevelVL[]) {
        for (const endpiecePosition of endpieceLevel.positionAC as EndpiecePositionVL[]) {
          endpiecePosition.endpiecePart.straightBottomPipeSample.algorithmSpec();
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
