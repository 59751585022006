import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { Part } from "vhows-design/src/object/design/base/Part";
import { BandstringPosition } from "vhows-design/src/object/design/cover/bandstring/BandstringPosition";
import { BandstringSample_EnhancedStake } from "vhows-design/src/object/design/cover/bandstring/BandstringSample_EnhancedStake";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-03-12
 */
@jsonObject({
  knownTypes: [BandstringSample_EnhancedStake],
})
export class BandstringPart_EnhancedStake extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public enhancedStakeSample: BandstringSample_EnhancedStake; // 바닥파이프 보강 말뚝 샘플

  // 아이템
  public enhanced_stake: ItemFrameOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _stakeInterval: number; // 말뚝 간격

  /**
   * 말뚝 간격
   */
  public get stakeInterval(): number {
    return this._stakeInterval;
  }

  //
  public set stakeInterval(value: number) {
    this._stakeInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.enhancedStakeSample = new BandstringSample_EnhancedStake();

    this.sampleAC = [this.enhancedStakeSample];

    // 아이템
    this.enhanced_stake = new ItemFrameOther();

    this.itemAC = [this.enhanced_stake];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.enhancedStakeSample = this.sampleAC[0] as BandstringSample_EnhancedStake;

    // 아이템
    this.enhanced_stake = this.itemAC[0] as ItemFrameOther;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param stakeInterval: number 말뚝 간격
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    stakeInterval: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._stakeInterval = stakeInterval;

    // 샘플
    if (this.level.index >= 0) {
      this.enhancedStakeSample.setDefaultData(
        0,
        CONST.ITEM_ID_STAKE,
        CONST.ITEM_NAME_STAKE,
        "바닥파이프 보강",
        "철제, 중",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.enhanced_stake.setDefaultData(0, CONST.ITEM_ID_STAKE, CONST.ITEM_NAME_STAKE, "바닥파이프 보강", "철제, 중");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const bandstringPosition: BandstringPosition = this.position as BandstringPosition;

    /// //////// 선택, 가시성 ///////////

    this.enhanced_stake.selected = true;
    this.enhanced_stake.visible = true;

    /// //////// 수량  ///////////

    this.enhanced_stake.designQuantity =
      (Math.ceil(this.basicLevel.length / this.stakeInterval) + 1) * 2 * this.basic.buildingNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [바닥파이프 보강] 말뚝 <- 바닥파이프 보강 말뚝 샘플
   */
  public algorithmSpec_enhanced_stake(): void {
    this.enhanced_stake.specs = this.enhancedStakeSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
