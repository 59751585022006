import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkySwitchPosition } from "vhows-design/src/object/design/skylight/switches/SkySwitchPosition";
import { SkyFramePosition } from "vhows-design/src/object/design/skylight/frame/SkyFramePosition";
import { SkySwitchSample_AxisPipe } from "vhows-design/src/object/design/skylight/switches/SkySwitchSample_AxisPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-18
 */
@jsonObject({
  knownTypes: [SkySwitchSample_AxisPipe],
})
export class SkySwitchPart_Axis extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public axisPipeSample: SkySwitchSample_AxisPipe; // 개폐 축 파이프 샘플

  // 아이템
  public axis_pipe: ItemPipe;
  public axis_pinnedPipe: ItemPipe; // 사용 안함
  public axis_connectionPin: ItemConnector;
  public axis_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _axisLength: number; // 개폐 축 길이

  /**
   * 개폐 축 길이
   */
  public get axisLength(): number {
    return this._axisLength;
  }

  public set axisLength(value: number) {
    this._axisLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.axisPipeSample = new SkySwitchSample_AxisPipe();

    this.sampleAC = [this.axisPipeSample];

    // 아이템
    this.axis_pipe = new ItemPipe();
    this.axis_pinnedPipe = new ItemPipe();
    this.axis_connectionPin = new ItemConnector();
    this.axis_screw = new ItemScrew();

    this.itemAC = [this.axis_pipe, this.axis_pinnedPipe, this.axis_connectionPin, this.axis_screw];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.axisPipeSample = <SkySwitchSample_AxisPipe>this.sampleAC[0];

    // 아이템
    this.axis_pipe = <ItemPipe>this.itemAC[0];
    this.axis_pinnedPipe = <ItemPipe>this.itemAC[1];
    this.axis_connectionPin = <ItemConnector>this.itemAC[2];
    this.axis_screw = <ItemScrew>this.itemAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.axisPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 개폐 축",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.axis_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 개폐 축",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.axis_pinnedPipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 개폐 축",
        "원형, 핀가공, 25.4mm, 1.5T, 6m, -",
      );
      this.axis_connectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "천창 개폐 축",
        "원형, 일반, 25mm, 25mm",
      );
      this.axis_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "천창 개폐 축",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_axisLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skySwitchPosition: SkySwitchPosition = <SkySwitchPosition>this.position;

    /// //////// 선택, 가시성 ///////////

    this.axis_pipe.checkSelected(true);
    this.axis_pinnedPipe.selected = false;
    this.axis_pinnedPipe.visible = false;
    this.axis_connectionPin.checkSelected(true);
    this.axis_screw.selected = true;
    this.axis_screw.visible = true;

    /// //////// 수량 ///////////

    this.axis_pipe.designQuantity =
      (this.axis_pipe.getConnectedPipeLength(
        this.axisLength,
        skySwitchPosition.switchWayValue * this.basic.buildingNumber,
        this.axis_pipe.specLength / 2,
      ) /
        this.axis_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_AXIS_PIPE;
    this.axis_pinnedPipe.designQuantity = 0;

    if (this.axisLength > this.axisPipeSample.specLength) {
      this.axis_connectionPin.designQuantity =
        this.axis_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.axis_connectionPin.designQuantity = 0;
    }

    this.axis_screw.designQuantity =
      ((this.axis_connectionPin.getSelectedQuantity() * 4) / this.axis_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 개폐 축 길이 <- 길이(기본정보)
   */
  public algorithm_axisLength(): void {
    const skyFramePosition: SkyFramePosition = this.struct.skyFrameWork.level1.skylightPosition;

    this.axisLength = skyFramePosition.skylightLength + this.design.preference.connectedPipeExtraLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 개폐 축] 파이프 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_pipe(): void {
    this.axis_pipe.specs = this.axisPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 개폐 축] 파이프 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_pinnedPipe(): void {}

  /**
   * 규격 알고리즘: [천창 개폐 축] 연결핀 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_connectionPin(): void {
    this.axis_connectionPin.specPipeType = this.axisPipeSample.specPipeType;
    this.axis_connectionPin.specCrossSize1 = this.axisPipeSample.specCrossSize;
    this.axis_connectionPin.specCrossSize2 = this.axisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 개폐 축] 나사 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_screw(): void {
    this.axis_screw.specs = this.design.preference.defaultScrewCrossNormal;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
