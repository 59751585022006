import { jsonObject } from "typedjson";

import { ItemQuilting } from "vhows-design/src/object/design/item/list/ItemQuilting";
import { CoverPartIL_Quilting } from "vhows-design/src/object/design/cover/cover/il/CoverPartIL_Quilting";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-12-18
 */
@jsonObject
export class CoverSampleIL_Quilting extends ItemQuilting {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const quiltingPart: CoverPartIL_Quilting = <CoverPartIL_Quilting>this.part;
    quiltingPart.algorithmSpec_item();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
