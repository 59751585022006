import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { DesignUtil } from "vhows-design/src/object/design/DesignUtil";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { CurCoverPartIL_Mat } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL_Mat";
import { CurCoverPartIL_NormalPad } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL_NormalPad";
import { CurCoverPartIL_Quilting } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL_Quilting";
import { CurCoverPartIL_Screen } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL_Screen";
import { CurCoverPartIL_Shadenet } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL_Shadenet";
import { CurCoverPartIL_Nonwoven } from "vhows-design/src/object/design/curtain/cover/CurCoverPartIL_Nonwoven";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { CurCoverSideEndpiecePartIL_Quilting } from "vhows-design/src/object/design/curtain/cover/CurCoverSideEndpiecePartIL_Quilting";
import { CurCoverCeilingLevel2PartIL_Quilting } from "vhows-design/src/object/design/curtain/cover/CurCoverCeilingLevel2PartIL_Quilting";
import { CurCoverCeilingLevel2PartIL_Shadenet } from "vhows-design/src/object/design/curtain/cover/CurCoverCeilingLevel2PartIL_Shadenet";
import { CurCoverCeilingLevel2PartIL_Screen } from "vhows-design/src/object/design/curtain/cover/CurCoverCeilingLevel2PartIL_Screen";
import { CurCoverCeilingLevel3PartIL_Quilting } from "vhows-design/src/object/design/curtain/cover/CurCoverCeilingLevel3PartIL_Quilting";
import { CurCoverCeilingLevel3PartIL_Shadenet } from "vhows-design/src/object/design/curtain/cover/CurCoverCeilingLevel3PartIL_Shadenet";
import { CurCoverCeilingLevel3PartIL_Screen } from "vhows-design/src/object/design/curtain/cover/CurCoverCeilingLevel3PartIL_Screen";
import { CurSwitchPartIL_Line } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Line";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CurSwitchWorkIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchWorkIL";
import { EndpieceWorkIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceWorkIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [
    CurCoverSideEndpiecePartIL_Quilting,
    CurCoverPartIL_Mat,
    CurCoverPartIL_Nonwoven,
    CurCoverPartIL_Quilting,
    CurCoverPartIL_Screen,
    CurCoverPartIL_Shadenet,
    CurCoverCeilingLevel2PartIL_Quilting,
    CurCoverCeilingLevel2PartIL_Shadenet,
    CurCoverCeilingLevel2PartIL_Screen,
    CurCoverCeilingLevel3PartIL_Quilting,
    CurCoverCeilingLevel3PartIL_Shadenet,
    CurCoverCeilingLevel3PartIL_Screen,
    CurCoverPartIL_NormalPad,
  ],
})
export class CurCoverPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public sideEndpiecePart: CurCoverSideEndpiecePartIL_Quilting; // 커튼 다겹 커튼지(좌우+앞뒤) 파트
  public quiltingPart: CurCoverPartIL_Quilting; // 커튼 다겹 커튼지(1중 천장) 파트
  public nonwovenPart: CurCoverPartIL_Nonwoven; // 커튼 부직포(1중 천장) 파트
  public matPart: CurCoverPartIL_Mat; // 커튼 마트(1중 천장) 파트
  public shadenetPart: CurCoverPartIL_Shadenet; // 커튼 차광망(1중 천장) 파트
  public screenPart: CurCoverPartIL_Screen; // 커튼 스크린(1중 천장) 파트
  public ceilingLevel2QuiltingPart: CurCoverCeilingLevel2PartIL_Quilting; // 커튼 다겹 커튼지(2중 천장) 파트
  public ceilingLevel2ShadenetPart: CurCoverCeilingLevel2PartIL_Shadenet; // 커튼 차광망(2중 천장) 파트
  public ceilingLevel2ScreenPart: CurCoverCeilingLevel2PartIL_Screen; // 커튼 스크린(2중 천장) 파트
  public ceilingLevel3QuiltingPart: CurCoverCeilingLevel3PartIL_Quilting; // 커튼 다겹 커튼지(3중 천장) 파트
  public ceilingLevel3ShadenetPart: CurCoverCeilingLevel3PartIL_Shadenet; // 커튼 차광망(3중 천장) 파트
  public ceilingLevel3ScreenPart: CurCoverCeilingLevel3PartIL_Screen; // 커튼 스크린(3중 천장) 파트
  public normalPadPart: CurCoverPartIL_NormalPad; // 일반 패드 고정 파트

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 피복 형태별 두께값
   */
  public get thicknessValue(): number {
    return DesignUtil.getCoverThickness(this.type);
  }

  public set thicknessValue(value: number) {}

  /**
   * 천장 중수 값
   */
  public get ceilingLevelValue() {
    if (this.type === CONST.LB_LEVEL_1) {
      return 1;
    } else if (this.type === CONST.LB_LEVEL_2) {
      return 2;
    } else if (this.type === CONST.LB_LEVEL_3) {
      return 3;
    } else {
      return 0;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _scopeType: string; // 범위 형태
  @jsonMember(String)
  public _axisType: string; // 축 형태
  @jsonMember(String)
  public _ceilingLevel1Cover: string; // 1중 천장 피복
  @jsonMember(String)
  public _ceilingLevel2Cover: string; // 2중 천장 피복
  @jsonMember(String)
  public _ceilingLevel3Cover: string; // 3중 천장 피복
  @jsonMember(String)
  public _fixingType: string; // 피복 고정 형태

  /**
   * 범위 형태
   */
  public get scopeType(): string {
    return this._scopeType;
  }

  public set scopeType(value: string) {
    this._scopeType = value;

    // 알고리즘
    this.algoritm_fixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 파트 활성화
    this.algorithm_partActivationByType();

    /// //////// 외부 ///////////

    // 커튼 개폐
    for (const curSwitchPosition of this.struct.curSwitchWorkIL.levelAC[0].positionAC as CurSwitchPositionIL[]) {
      curSwitchPosition.algorithm_selectedAndEnabled();
    }
    (
      this.struct.curSwitchWorkIL.level1 as CurSwitchLevelIL
    ).ceilingPosition.framePart.algorithm_pocketFixingPipeSelected();
    // // 커튼 개폐
    // for (const curSwitchPosition of this.struct.curSwitchWorkIL.levelAC[this.level.index].positionAC as CurSwitchPositionIL[]) {
    //   curSwitchPosition.algorithm_selectedAndEnabled();
    // }
    // (this.struct.curSwitchWorkIL.levelAC[this.level.index] as CurSwitchLevelIL).ceilingPosition.framePart.algorithm_pocketFixingPipeSelected();
  }

  /**
   * 축 형태
   */
  public get axisType(): string {
    return this._axisType;
  }

  public set axisType(value: string) {
    this._axisType = value;

    // 알고리즘
    this.algoritm_fixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 파트 활성화
    this.algorithm_partActivationByType();

    /// //////// 외부 ///////////
    const curSwitchCeilingPosition = (this.struct.curSwitchWorkIL.levelAC[0] as CurSwitchLevelIL)
      .ceilingPosition as CurSwitchPositionIL;
    curSwitchCeilingPosition.algorithm_partActivationByType();
    curSwitchCeilingPosition.algoritm_switchCoverFixingType();
    curSwitchCeilingPosition.linePart.algorithm_towLineNumberAndSupportLineNumber();
    curSwitchCeilingPosition.fixingClipCeilingPart.algorithmPart();
    curSwitchCeilingPosition.fixingClipCeilingPart.algoritm_towClipType();
  }

  /**
   * 1중 천장 피복
   */
  // @override
  public get ceilingLevel1Cover(): string {
    return this._ceilingLevel1Cover;
  }

  //
  public set ceilingLevel1Cover(value: string) {
    this._ceilingLevel1Cover = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 커튼 개폐
    (<CurSwitchLevelIL>this.struct.curSwitchWorkIL.levelAC[0]).ceilingPosition.algorithm_switchDivision();
    (<CurSwitchLevelIL>(
      this.struct.curSwitchWorkIL.levelAC[0]
    )).ceilingPosition.motorPart.algorithm_availableCurtainArea();
    // (<CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index]).ceilingPosition.algorithm_switchDivision();
    // (<CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index]).ceilingPosition.motorPart.algorithm_availableCurtainArea();
  }

  /**
   * 2중 천장 피복
   */
  // @override
  public get ceilingLevel2Cover(): string {
    return this._ceilingLevel2Cover;
  }

  //
  public set ceilingLevel2Cover(value: string) {
    this._ceilingLevel2Cover = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 커튼 개폐
    (<CurSwitchLevelIL>this.struct.curSwitchWorkIL.levelAC[0]).ceilingPosition.algorithm_switchDivision();
    (<CurSwitchLevelIL>(
      this.struct.curSwitchWorkIL.levelAC[0]
    )).ceilingPosition.motorPart.algorithm_availableCurtainArea();
    // (<CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index]).ceilingPosition.algorithm_switchDivision();
    // (<CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index]).ceilingPosition.motorPart.algorithm_availableCurtainArea();
  }

  /**
   * 3중 천장 피복
   */
  // @override
  public get ceilingLevel3Cover(): string {
    return this._ceilingLevel3Cover;
  }

  //
  public set ceilingLevel3Cover(value: string) {
    this._ceilingLevel3Cover = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 커튼 개폐
    (<CurSwitchLevelIL>this.struct.curSwitchWorkIL.levelAC[0]).ceilingPosition.algorithm_switchDivision();
    (<CurSwitchLevelIL>(
      this.struct.curSwitchWorkIL.levelAC[0]
    )).ceilingPosition.motorPart.algorithm_availableCurtainArea();
    // (<CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index]).ceilingPosition.algorithm_switchDivision();
    // (<CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index]).ceilingPosition.motorPart.algorithm_availableCurtainArea();
  }

  /**
   * 피복 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    // // 중수
    // this.algoritm_selectedByLevel();

    /// //////// 외부 ///////////

    // 커튼 개폐
    for (const curSwitchPosition of this.struct.curSwitchWorkIL.levelAC[0].positionAC as CurSwitchPositionIL[]) {
      curSwitchPosition.algorithm_selectedAndEnabled();
    }

    // 기초 피복
    if (this.level.index === 0) {
      for (const coverPosition of this.struct.coverWorkIL.levelAC[0].positionAC as CoverPositionIL[]) {
        coverPosition.algorithm_selectedAtColumn();
      }
    }

    // 기초 피복 고정
    if (this.level.index === 0) {
      this.struct.fixingWorkIL.level1.columnPosition.algorithm_selectedByCurtain();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 커튼 개폐
    const curSwitchCeilingPosition: CurSwitchPositionIL = (this.struct.curSwitchWorkIL.levelAC[0] as CurSwitchLevelIL)
      .ceilingPosition as CurSwitchPositionIL;
    curSwitchCeilingPosition.algorithm_switchDivision();
    curSwitchCeilingPosition.motorPart.algorithm_availableCurtainArea();
    curSwitchCeilingPosition.framePart.algorithmPart();
    curSwitchCeilingPosition.framePartVL.algorithmPart();
    curSwitchCeilingPosition.linePart.algorithmPart();
    curSwitchCeilingPosition.fixingClipCeilingPart.algorithmPart();
    // (<CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index]).ceilingPosition.algorithm_switchDivision();
    // (<CurSwitchLevelIL> this.struct.curSwitchWorkIL.levelAC[this.level.index]).ceilingPosition.motorPart.algorithm_availableCurtainArea();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.sideEndpiecePart = new CurCoverSideEndpiecePartIL_Quilting();
    this.quiltingPart = new CurCoverPartIL_Quilting();
    this.nonwovenPart = new CurCoverPartIL_Nonwoven();
    this.matPart = new CurCoverPartIL_Mat();
    this.shadenetPart = new CurCoverPartIL_Shadenet();
    this.screenPart = new CurCoverPartIL_Screen();
    this.ceilingLevel2QuiltingPart = new CurCoverCeilingLevel2PartIL_Quilting();
    this.ceilingLevel2ShadenetPart = new CurCoverCeilingLevel2PartIL_Shadenet();
    this.ceilingLevel2ScreenPart = new CurCoverCeilingLevel2PartIL_Screen();
    this.ceilingLevel3QuiltingPart = new CurCoverCeilingLevel3PartIL_Quilting();
    this.ceilingLevel3ShadenetPart = new CurCoverCeilingLevel3PartIL_Shadenet();
    this.ceilingLevel3ScreenPart = new CurCoverCeilingLevel3PartIL_Screen();
    this.normalPadPart = new CurCoverPartIL_NormalPad();

    this.partAC = [
      this.sideEndpiecePart,
      this.quiltingPart,
      this.nonwovenPart,
      this.matPart,
      this.shadenetPart,
      this.screenPart,
      this.ceilingLevel2QuiltingPart,
      this.ceilingLevel2ShadenetPart,
      this.ceilingLevel2ScreenPart,
      this.ceilingLevel3QuiltingPart,
      this.ceilingLevel3ShadenetPart,
      this.ceilingLevel3ScreenPart,
      this.normalPadPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.sideEndpiecePart = <CurCoverSideEndpiecePartIL_Quilting>this.partAC[0];
    this.quiltingPart = <CurCoverPartIL_Quilting>this.partAC[1];
    this.nonwovenPart = <CurCoverPartIL_Nonwoven>this.partAC[2];
    this.matPart = <CurCoverPartIL_Mat>this.partAC[3];
    this.shadenetPart = <CurCoverPartIL_Shadenet>this.partAC[4];
    this.screenPart = <CurCoverPartIL_Screen>this.partAC[5];
    this.ceilingLevel2QuiltingPart = <CurCoverCeilingLevel2PartIL_Quilting>this.partAC[6];
    this.ceilingLevel2ShadenetPart = <CurCoverCeilingLevel2PartIL_Shadenet>this.partAC[7];
    this.ceilingLevel2ScreenPart = <CurCoverCeilingLevel2PartIL_Screen>this.partAC[8];
    this.ceilingLevel3QuiltingPart = <CurCoverCeilingLevel3PartIL_Quilting>this.partAC[9];
    this.ceilingLevel3ShadenetPart = <CurCoverCeilingLevel3PartIL_Shadenet>this.partAC[10];
    this.ceilingLevel3ScreenPart = <CurCoverCeilingLevel3PartIL_Screen>this.partAC[11];
    this.normalPadPart = <CurCoverPartIL_NormalPad>this.partAC[12];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param scopeType: string 범위 형태
   * @param axisType: string 축 형태
   * @param ceilingLevel1Cover: string 1중 천장 피복
   * @param ceilingLevel2Cover: string 2중 천장 피복
   * @param ceilingLevel3Cover: string 3중 천장 피복
   * @param fixingType: string 피복 고정 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    scopeType: string = "",
    axisType: string = "",
    ceilingLevel1Cover: string = "",
    ceilingLevel2Cover: string = "",
    ceilingLevel3Cover: string = "",
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._scopeType = scopeType;
    this._axisType = axisType;
    this._ceilingLevel1Cover = ceilingLevel1Cover;
    this._ceilingLevel2Cover = ceilingLevel2Cover;
    this._ceilingLevel3Cover = ceilingLevel3Cover;
    this._fixingType = fixingType;

    if (this.level.index >= 0) {
      // 커튼 다겹 커튼지(좌우+앞뒤)
      this.sideEndpiecePart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_SIDE_ENDPIECE_QUILTING,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_QUILTING}(좌우+앞뒤)`,
        "",
      );

      // 커튼 다겹 커튼지
      this.quiltingPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_COVER_QUILTING,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_QUILTING}(1중 천장)`,
        "",
      );

      // 커튼 부직포
      this.nonwovenPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_COVER_NONWOVEN,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_NONWOVEN}(1중 천장)`,
        "",
      );

      // 커튼 마트
      this.matPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_COVER_MAT,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_MAT}(1중 천장)`,
        "",
      );

      // 커튼 차광망
      this.shadenetPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_COVER_SHADENET,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_SHADENET}(1중 천장)`,
        "",
      );

      // 커튼 스크린
      this.screenPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_COVER_SCREEN,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_SCREEN}(1중 천장)`,
        "",
      );

      // 커튼 다겹 커튼지(2중 천장)
      this.ceilingLevel2QuiltingPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_CEILING_LEVEL2_QUILTING,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_QUILTING}(2중 천장)`,
        "",
      );

      // 커튼 차광망(2중 천장)
      this.ceilingLevel2ShadenetPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_CEILING_LEVEL2_SHADENET,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_SHADENET}(2중 천장)`,
        "",
      );

      // 커튼 스크린(2중 천장)
      this.ceilingLevel2ScreenPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_CEILING_LEVEL2_SCREEN,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_SCREEN}(2중 천장)`,
        "",
      );

      // 커튼 다겹 커튼지(3중 천장)
      this.ceilingLevel3QuiltingPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_CEILING_LEVEL3_QUILTING,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_QUILTING}(3중 천장)`,
        "",
      );

      // 커튼 차광망(3중 천장)
      this.ceilingLevel3ShadenetPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_CEILING_LEVEL3_SHADENET,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_SHADENET}(3중 천장)`,
        "",
      );

      // 커튼 스크린(3중 천장)
      this.ceilingLevel3ScreenPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_CEILING_LEVEL3_SCREEN,
        false,
        false,
        false,
        `${CONST.LB_CURTAIN_COVER_SCREEN}(3중 천장)`,
        "",
      );

      // 커튼 피복 고정 - 일반 패드 고정
      this.normalPadPart.setDefaultData(
        CONST.INDEX_IL_CURTAIN_FIXING_NORMAL_PAD,
        false,
        false,
        false,
        CONST.LB_CURTAIN_FIXING_NORMAL_PAD,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion) && this.selected) {
      if (this.partAC.length === 6) {
        const tempPartAC = this.partAC;
        const normalPadPart = tempPartAC[tempPartAC.length - 1];

        this.partAC = [];

        (
          (struct.workAC[CONST.INDEX_IL_CURTAIN_SWITCH_WORK] as CurSwitchWorkIL).levelAC[0] as CurSwitchLevelIL
        ).setReferenceVariable();
        (
          (struct.workAC[CONST.INDEX_IL_ENDPIECE_WORK] as EndpieceWorkIL).levelAC[0] as EndpieceLevelIL
        ).setReferenceVariable();

        // 커튼 다겹 커튼지(좌우+앞뒤)
        const sideEndpiecePart: CurCoverSideEndpiecePartIL_Quilting = new CurCoverSideEndpiecePartIL_Quilting();
        sideEndpiecePart.setAssociation(design, struct, work, level, this);
        sideEndpiecePart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_SIDE_ENDPIECE_QUILTING,
          false,
          false,
          true,
          `${CONST.LB_CURTAIN_COVER_QUILTING}(좌우+앞뒤)`,
          "",
        );
        sideEndpiecePart.algorithmPart();
        this.partAC.push(sideEndpiecePart);

        // 커튼 다겹 커튼지
        const quiltingPart: CurCoverPartIL_Quilting = new CurCoverPartIL_Quilting();
        quiltingPart.setAssociation(design, struct, work, level, this);
        quiltingPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_COVER_QUILTING,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_QUILTING}(1중 천장)`,
          "",
        );
        this.partAC.push(quiltingPart);

        // 커튼 부직포
        const nonwovenPart: CurCoverPartIL_Nonwoven = new CurCoverPartIL_Nonwoven();
        nonwovenPart.setAssociation(design, struct, work, level, this);
        nonwovenPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_COVER_NONWOVEN,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_NONWOVEN}(1중 천장)`,
          "",
        );
        this.partAC.push(nonwovenPart);

        // 커튼 마트
        const matPart: CurCoverPartIL_Mat = new CurCoverPartIL_Mat();
        matPart.setAssociation(design, struct, work, level, this);
        matPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_COVER_MAT,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_MAT}(1중 천장)`,
          "",
        );
        this.partAC.push(matPart);

        // 커튼 차광망
        const shadenetPart: CurCoverPartIL_Shadenet = new CurCoverPartIL_Shadenet();
        shadenetPart.setAssociation(design, struct, work, level, this);
        shadenetPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_COVER_SHADENET,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_SHADENET}(1중 천장)`,
          "",
        );
        this.partAC.push(shadenetPart);

        // 커튼 스크린
        const screenPart: CurCoverPartIL_Screen = new CurCoverPartIL_Screen();
        screenPart.setAssociation(design, struct, work, level, this);
        screenPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_COVER_SCREEN,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_SCREEN}(1중 천장)`,
          "",
        );
        this.partAC.push(screenPart);

        // 커튼 다겹 커튼지(2중 천장)
        const ceilingLevel2QuiltingPart = new CurCoverCeilingLevel2PartIL_Quilting();
        ceilingLevel2QuiltingPart.setAssociation(design, struct, work, level, this);
        ceilingLevel2QuiltingPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_CEILING_LEVEL2_QUILTING,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_QUILTING}(2중 천장)`,
          "",
        );
        this.partAC.push(ceilingLevel2QuiltingPart);

        // 커튼 차광망(2중 천장)
        const ceilingLevel2ShadenetPart = new CurCoverCeilingLevel2PartIL_Shadenet();
        ceilingLevel2ShadenetPart.setAssociation(design, struct, work, level, this);
        ceilingLevel2ShadenetPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_CEILING_LEVEL2_SHADENET,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_SHADENET}(2중 천장)`,
          "",
        );
        this.partAC.push(ceilingLevel2ShadenetPart);

        // 커튼 스크린(2중 천장)
        const ceilingLevel2ScreenPart = new CurCoverCeilingLevel2PartIL_Screen();
        ceilingLevel2ScreenPart.setAssociation(design, struct, work, level, this);
        ceilingLevel2ScreenPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_CEILING_LEVEL2_SCREEN,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_SCREEN}(2중 천장)`,
          "",
        );
        this.partAC.push(ceilingLevel2ScreenPart);

        // 커튼 다겹 커튼지(3중 천장)
        const ceilingLevel3QuiltingPart = new CurCoverCeilingLevel3PartIL_Quilting();
        ceilingLevel3QuiltingPart.setAssociation(design, struct, work, level, this);
        ceilingLevel3QuiltingPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_CEILING_LEVEL3_QUILTING,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_QUILTING}(3중 천장)`,
          "",
        );
        this.partAC.push(ceilingLevel3QuiltingPart);

        // 커튼 차광망(3중 천장)
        const ceilingLevel3ShadenetPart = new CurCoverCeilingLevel3PartIL_Shadenet();
        ceilingLevel3ShadenetPart.setAssociation(design, struct, work, level, this);
        ceilingLevel3ShadenetPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_CEILING_LEVEL3_SHADENET,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_SHADENET}(3중 천장)`,
          "",
        );
        this.partAC.push(ceilingLevel3ShadenetPart);

        // 커튼 스크린(3중 천장)
        const ceilingLevel3ScreenPart = new CurCoverCeilingLevel3PartIL_Screen();
        ceilingLevel3ScreenPart.setAssociation(design, struct, work, level, this);
        ceilingLevel3ScreenPart.setDefaultData(
          CONST.INDEX_IL_CURTAIN_CEILING_LEVEL3_SCREEN,
          false,
          false,
          false,
          `${CONST.LB_CURTAIN_COVER_SCREEN}(3중 천장)`,
          "",
        );
        this.partAC.push(ceilingLevel3ScreenPart);

        // 패드 고정
        this.partAC.push(normalPadPart);

        // 인덱스 재설정
        CommonUtil.reindexAC(this.partAC);
      }
    }
    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////
    this.quiltingPart.selected = false;
    this.quiltingPart.visible = false;
    this.nonwovenPart.selected = false;
    this.nonwovenPart.visible = false;
    this.matPart.selected = false;
    this.matPart.visible = false;
    this.shadenetPart.selected = false;
    this.shadenetPart.visible = false;
    this.screenPart.selected = false;
    this.screenPart.visible = false;
    this.sideEndpiecePart.selected = true;
    this.sideEndpiecePart.visible = true;
    this.ceilingLevel2QuiltingPart.selected = false;
    this.ceilingLevel2QuiltingPart.visible = false;
    this.ceilingLevel2ShadenetPart.selected = false;
    this.ceilingLevel2ShadenetPart.visible = false;
    this.ceilingLevel2ScreenPart.selected = false;
    this.ceilingLevel2ScreenPart.visible = false;
    this.ceilingLevel3QuiltingPart.selected = false;
    this.ceilingLevel3QuiltingPart.visible = false;
    this.ceilingLevel3ShadenetPart.selected = false;
    this.ceilingLevel3ShadenetPart.visible = false;
    this.ceilingLevel3ScreenPart.selected = false;
    this.ceilingLevel3ScreenPart.visible = false;

    // 좌우 + 앞뒤
    if (
      this.scopeType === CONST.LB_COVER_SCOPE_TYPE_CEILING ||
      this.scopeType === CONST.LB_COVER_SCOPE_TYPE_CEILING_SEP_POCKET ||
      this.scopeType === CONST.LB_COVER_SCOPE_TYPE_CEILING_AND_ENDPIECE
    ) {
      this.sideEndpiecePart.selected = false;
      this.sideEndpiecePart.visible = false;
    }

    // 1중 천장
    if (this.type === CONST.LB_LEVEL_1 || this.type === CONST.LB_LEVEL_2 || this.type === CONST.LB_LEVEL_3) {
      if (this.ceilingLevel1Cover === CONST.LB_COVER_TYPE_QUILTING) {
        this.quiltingPart.selected = true;
        this.quiltingPart.visible = true;
      } else if (this.ceilingLevel1Cover === CONST.LB_COVER_TYPE_NONWOVEN) {
        this.nonwovenPart.selected = true;
        this.nonwovenPart.visible = true;
      } else if (this.ceilingLevel1Cover === CONST.LB_COVER_TYPE_MAT) {
        this.matPart.selected = true;
        this.matPart.visible = true;
      } else if (this.ceilingLevel1Cover === CONST.LB_COVER_TYPE_SHADENET) {
        this.shadenetPart.selected = true;
        this.shadenetPart.visible = true;
      } else if (this.ceilingLevel1Cover === CONST.LB_COVER_TYPE_SCREEN) {
        this.screenPart.selected = true;
        this.screenPart.visible = true;
      }
    }

    // 2중 천장
    if (this.type === CONST.LB_LEVEL_2 || this.type === CONST.LB_LEVEL_3) {
      if (this.ceilingLevel2Cover === CONST.LB_COVER_TYPE_QUILTING) {
        this.ceilingLevel2QuiltingPart.selected = true;
        this.ceilingLevel2QuiltingPart.visible = true;
      } else if (this.ceilingLevel2Cover === CONST.LB_COVER_TYPE_SHADENET) {
        this.ceilingLevel2ShadenetPart.selected = true;
        this.ceilingLevel2ShadenetPart.visible = true;
      } else if (this.ceilingLevel2Cover === CONST.LB_COVER_TYPE_SCREEN) {
        this.ceilingLevel2ScreenPart.selected = true;
        this.ceilingLevel2ScreenPart.visible = true;
      }
    }

    // 3중 천장
    if (this.type === CONST.LB_LEVEL_3) {
      if (this.ceilingLevel3Cover === CONST.LB_COVER_TYPE_QUILTING) {
        this.ceilingLevel3QuiltingPart.selected = true;
        this.ceilingLevel3QuiltingPart.visible = true;
      } else if (this.ceilingLevel3Cover === CONST.LB_COVER_TYPE_SHADENET) {
        this.ceilingLevel3ShadenetPart.selected = true;
        this.ceilingLevel3ShadenetPart.visible = true;
      } else if (this.ceilingLevel3Cover === CONST.LB_COVER_TYPE_SCREEN) {
        this.ceilingLevel3ScreenPart.selected = true;
        this.ceilingLevel3ScreenPart.visible = true;
      }
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
      this.normalPadPart.selected = true;
      this.normalPadPart.visible = true;
    } else {
      this.normalPadPart.selected = false;
      this.normalPadPart.visible = false;
    }
  }

  // /**
  //  * 알고리즘: 선택 <- 선택(중수)
  //  */
  // public algoritm_selectedByLevel(): void {
  //   let curCoverPosition: CurCoverPositionIL;
  //   let l: number;
  //
  //   if (this.selected === true) {
  //     // 낮은 중수에 영향
  //     for (l = this.level.index - 1; l >= 0; l--) {
  //       curCoverPosition = (<CurCoverLevelIL> this.work.levelAC[l]).curCoverPosition;
  //
  //       curCoverPosition.selected = this.selected;
  //     }
  //   } else {
  //     // 높은 중수에 영향
  //     for (l = this.level.index + 1; l < this.work.levelAC.length; l++) {
  //       curCoverPosition = (<CurCoverLevelIL> this.work.levelAC[l]).curCoverPosition;
  //
  //       curCoverPosition.selected = this.selected;
  //     }
  //   }
  // }

  /**
   * 알고리즘: 피복 고정 형태 <- 범위 형태, 형태(커튼 개폐>기둥)
   */
  public algoritm_fixingType(): void {
    const curSwitchLevel: CurSwitchLevelIL = <CurSwitchLevelIL>this.struct.curSwitchWorkIL.levelAC[this.level.index];
    if (this.level.index === 0) {
      if (
        (this.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_AND_ENDPIECE ||
          this.scopeType === CONST.LB_COVER_SCOPE_TYPE_CEILING_AND_ENDPIECE) &&
        curSwitchLevel.columnPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW
      ) {
        this.fixingType = CONST.LB_FIXING_TYPE_NONE;
      } else {
        this.fixingType = CONST.LB_FIXING_TYPE_NORMAL_PAD;
      }
    }
  }
  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
