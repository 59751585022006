import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { WindowPart_FixingNormalPad } from "vhows-design/src/object/design/other/window/WindowPart_FixingNormalPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-04
 */
@jsonObject
export class WindowSample_NormalPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const fixingNormalPadPart: WindowPart_FixingNormalPad = <WindowPart_FixingNormalPad>this.part;
    fixingNormalPadPart.algorithmSpec_normalPad_normalPad();
    fixingNormalPadPart.algorithmSpec_normalPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 일반 패드 샘플(피복 고정)
   */
  // @override
  public algorithmSpec(): void {
    if (this.position.label === CONST.LB_POSITION_LEFT || this.position.label === CONST.LB_POSITION_RIGHT) {
      this.specs = this.struct.fixingWork.level1.sidePosition.normalPadPart.normalPadSample.specs;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.specs = this.struct.fixingWork.level1.frontPosition.normalPadPart.normalPadSample.specs;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.specs = this.struct.fixingWork.level1.backPosition.normalPadPart.normalPadSample.specs;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
