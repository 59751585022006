import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemCement } from "vhows-design/src/object/design/item/list/ItemCement";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject({
  knownTypes: [],
})
export class FoundationPartIL_ColumnConcrete extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플

  // 아이템
  public columnConcrete_remicon: ItemCement;
  public columnConcrete_thpPipe: ItemDrainPipe;
  public columnConcreteStrengthen_pipe: ItemPipe;
  public columnConcreteStrengthen_steelStringGrabber: ItemHolder;
  public columnConcreteStrengthen_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _concreteVolume: number; // 콘크리트 부피
  @jsonMember(Number)
  public _concreteVolumeReal: number; // 실제 콘크리트 부피
  @jsonMember(String)
  public _moldDiameter: string; // 거푸집 지름
  @jsonMember(Number)
  public _moldHeight: number; // 거푸집 높이
  @jsonMember(Number)
  public _moldVolume: number; // 거푸집 부피
  @jsonMember(Number)
  public _moldQuantity: number; // 거푸집 수량
  @jsonMember(Boolean)
  public _strengthenPipeSelected: boolean; // 보강 파이프 선택
  @jsonMember(Number)
  public _strengthenPipeLength: number; // 보강 파이프 길이

  /**
   * 콘크리트 부피
   */
  public get concreteVolume(): number {
    return this._concreteVolume;
  }

  //
  public set concreteVolume(value: number) {
    this._concreteVolume = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 실제 콘크리트 부피
   */
  public get concreteVolumeReal(): number {
    return this._concreteVolumeReal;
  }

  //
  public set concreteVolumeReal(value: number) {
    this._concreteVolumeReal = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_concreteVolume();
    this.algorithmPart();
  }

  /**
   * 거푸집 지름
   */
  public get moldDiameter(): string {
    return this._moldDiameter;
  }

  //
  public set moldDiameter(value: string) {
    this._moldDiameter = value;

    // 알고리즘
    this.algorithmSpec_columnConcrete_thpPipe();
    this.algorithm_moldVolume();
    this.algorithmPart();
  }

  /**
   * 거푸집 높이
   */
  public get moldHeight(): number {
    return this._moldHeight;
  }

  //
  public set moldHeight(value: number) {
    this._moldHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_moldVolume();
    this.algorithmPart();
  }

  /**
   * 거푸집 부피
   */
  public get moldVolume(): number {
    return this._moldVolume;
  }

  //
  public set moldVolume(value: number) {
    this._moldVolume = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_concreteVolumeReal();
    this.algorithmPart();
  }

  /**
   * 거푸집 수량
   */
  public get moldQuantity(): number {
    return this._moldQuantity;
  }

  //
  public set moldQuantity(value: number) {
    this._moldQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_concreteVolumeReal();
    this.algorithmPart();
  }

  /**
   * 보강 파이프 선택
   */
  public get strengthenPipeSelected(): boolean {
    return this._strengthenPipeSelected;
  }

  //
  public set strengthenPipeSelected(value: boolean) {
    this._strengthenPipeSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 보강 파이프 길이
   */
  public get strengthenPipeLength(): number {
    return this._strengthenPipeLength;
  }

  //
  public set strengthenPipeLength(value: number) {
    this._strengthenPipeLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    // 아이템
    this.columnConcrete_remicon = new ItemCement();
    this.columnConcrete_thpPipe = new ItemDrainPipe();
    this.columnConcreteStrengthen_pipe = new ItemPipe();
    this.columnConcreteStrengthen_steelStringGrabber = new ItemHolder();
    this.columnConcreteStrengthen_welding = new ItemOtherExpense();

    this.itemAC = [
      this.columnConcrete_remicon,
      this.columnConcrete_thpPipe,
      this.columnConcreteStrengthen_pipe,
      this.columnConcreteStrengthen_steelStringGrabber,
      this.columnConcreteStrengthen_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 아이템
    this.columnConcrete_remicon = <ItemCement>this.itemAC[0];
    this.columnConcrete_thpPipe = <ItemDrainPipe>this.itemAC[1];
    this.columnConcreteStrengthen_pipe = <ItemPipe>this.itemAC[2];
    this.columnConcreteStrengthen_steelStringGrabber = <ItemHolder>this.itemAC[3];
    this.columnConcreteStrengthen_welding = <ItemOtherExpense>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param moldDiameter: string 거푸집 지름
   * @param moldHeight: number 거푸집 높이
   * @param strengthenPipeSelected: boolean 보강 파이프 선택
   * @param strengthenPipeLength: number 보강 파이프 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    moldDiameter: string = "",
    moldHeight: number = 0,
    strengthenPipeSelected: boolean = false,
    strengthenPipeLength: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._moldDiameter = moldDiameter;
    this._moldHeight = moldHeight;
    this._strengthenPipeSelected = strengthenPipeSelected;
    this._strengthenPipeLength = strengthenPipeLength;

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      this.columnConcrete_remicon.setDefaultData(
        0,
        CONST.ITEM_ID_REMICON,
        CONST.ITEM_NAME_REMICON,
        "기둥 콘크리트",
        "25-21-120",
      );
      this.columnConcrete_thpPipe.setDefaultData(
        1,
        CONST.ITEM_ID_THP_PIPE,
        CONST.ITEM_NAME_THP_PIPE,
        "기둥 콘크리트",
        "일반, 250mm, 4m",
      );
      this.columnConcreteStrengthen_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 콘크리트 보강",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.columnConcreteStrengthen_steelStringGrabber.setDefaultData(
        3,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "기둥 콘크리트 보강 + 기둥",
        "원형, 일반, 48mm, 25mm",
      );
      this.columnConcreteStrengthen_welding.setDefaultData(
        4,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "기둥 콘크리트 보강 + 각형 기둥",
        "일반",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 2) {
      const columnConcreteStrengthen_pipe: ItemPipe = new ItemPipe();
      columnConcreteStrengthen_pipe.setAssociation(design, struct, work, level, position, this);
      columnConcreteStrengthen_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 콘크리트 보강",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      columnConcreteStrengthen_pipe.selected = false;
      columnConcreteStrengthen_pipe.visible = false;
      this.itemAC.push(columnConcreteStrengthen_pipe);

      const columnConcreteStrengthen_steelStringGrabber: ItemHolder = new ItemHolder();
      columnConcreteStrengthen_steelStringGrabber.setAssociation(design, struct, work, level, position, this);
      columnConcreteStrengthen_steelStringGrabber.setDefaultData(
        2,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "기둥 콘크리트 보강 + 원형 기둥",
        "원형, 일반, 48mm, 25mm",
      );
      columnConcreteStrengthen_steelStringGrabber.selected = false;
      columnConcreteStrengthen_steelStringGrabber.visible = false;
      this.itemAC.push(columnConcreteStrengthen_steelStringGrabber);

      const columnConcreteStrengthen_welding: ItemOtherExpense = new ItemOtherExpense();
      columnConcreteStrengthen_welding.setAssociation(design, struct, work, level, position, this);
      columnConcreteStrengthen_welding.setDefaultData(
        3,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "기둥 콘크리트 보강 + 각형 기둥",
        "일반",
      );
      columnConcreteStrengthen_welding.selected = false;
      columnConcreteStrengthen_welding.visible = false;
      this.itemAC.push(columnConcreteStrengthen_welding);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_moldVolume();

    super.algorithmBasic();
  }

  /**
   * 파트 알고리즘 <- 형태(기둥 골조)
   */
  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    // 외부
    let columnPosition: ColumnPositionIL | ColumnPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      columnPosition = this.struct.columnWorkVL.level1.columnPosition;
    }

    /// //////// 선택, 가시성 ///////////

    this.columnConcrete_remicon.selected = true;
    this.columnConcrete_remicon.visible = true;
    this.columnConcrete_thpPipe.selected = true;
    this.columnConcrete_thpPipe.visible = true;
    if (this.strengthenPipeSelected === true) {
      this.columnConcreteStrengthen_pipe.selected = true;
      this.columnConcreteStrengthen_pipe.visible = true;
      if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
        this.columnConcreteStrengthen_steelStringGrabber.selected = true;
        this.columnConcreteStrengthen_steelStringGrabber.visible = true;
        this.columnConcreteStrengthen_welding.selected = false;
        this.columnConcreteStrengthen_welding.visible = false;
      } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
        this.columnConcreteStrengthen_steelStringGrabber.selected = false;
        this.columnConcreteStrengthen_steelStringGrabber.visible = false;
        this.columnConcreteStrengthen_welding.selected = true;
        this.columnConcreteStrengthen_welding.visible = true;
      }
    } else {
      this.columnConcreteStrengthen_pipe.selected = false;
      this.columnConcreteStrengthen_pipe.visible = false;
      this.columnConcreteStrengthen_steelStringGrabber.selected = false;
      this.columnConcreteStrengthen_steelStringGrabber.visible = false;
      this.columnConcreteStrengthen_welding.selected = false;
      this.columnConcreteStrengthen_welding.visible = false;
    }

    /// //////// 수량 ///////////

    this.columnConcrete_remicon.designQuantity = this.concreteVolume;

    this.columnConcrete_thpPipe.designQuantity =
      (this.moldQuantity * this.moldHeight) / this.columnConcrete_thpPipe.specLength;

    this.columnConcreteStrengthen_pipe.designQuantity =
      this.moldQuantity / Math.floor(this.columnConcreteStrengthen_pipe.specLength / this.strengthenPipeLength);

    this.columnConcreteStrengthen_steelStringGrabber.designQuantity =
      this.moldQuantity * Math.floor(this.strengthenPipeLength / 0.2) * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;

    this.columnConcreteStrengthen_welding.designQuantity =
      this.moldQuantity * Math.floor(this.strengthenPipeLength / 0.2);
  }

  /**
   * 알고리즘: 실제 콘크리트 부피 <- 거푸집 부피, 거푸집 수량
   */
  public algorithm_concreteVolumeReal(): void {
    this.concreteVolumeReal = CommonUtil.roundUp(
      this.moldQuantity * this.moldVolume * CONST.NUM_EXTRA_RATE_CONCRETE,
      1,
    );
  }

  /**
   * 알고리즘: 콘크리트 부피 <- 실제 콘크리트 부피
   */
  public algorithm_concreteVolume(): void {
    this.concreteVolume = Math.ceil(this.concreteVolumeReal);
  }

  /**
   * 알고리즘: 거푸집 부피 <- 거푸집 지름, 거푸집 높이
   */
  public algorithm_moldVolume(): void {
    this.moldVolume = CommonUtil.roundUp(
      Math.pow(CommonUtil.convertMM2M(parseFloat(this.moldDiameter)) / 2, 2) * 3.14 * this.moldHeight,
      3,
    );
  }

  /**
   * 알고리즘: 거푸집 수량 <- 형태(기둥 골조), 기둥 수량(기둥 골조)
   */
  public algorithm_moldQuantity(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      this.moldQuantity = this.struct.columnWorkIL.level1.columnPosition.columnQuantity;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      this.moldQuantity =
        this.struct.columnWorkVL.level1.columnPosition.columnTotalQuantity -
        this.struct.columnWorkVL.level1.columnPosition.prismColumnPart.smallColumnQuantity;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [기둥 콘크리트] 레미콘 <- 없음
   */
  public algorithmSpec_columnConcrete_remicon(): void {}

  /**
   * 규격 알고리즘: [기둥 콘크리트] THP관 <- 거푸집 지름
   */
  public algorithmSpec_columnConcrete_thpPipe(): void {
    // 규격 지름
    this.columnConcrete_thpPipe.specDiameter = this.moldDiameter;
  }

  /**
   * 규격 알고리즘: [기둥 콘크리트 보강] 파이프 <- 고정
   */
  public algorithmSpec_columnConcreteStrengthen_pipe(): void {}

  /**
   * 규격 알고리즘: [기둥 콘크리트 보강 + 기둥] 강선 조리개 <- 기둥 파이프 샘플(원형), [기둥 콘크리트 보강] 파이프
   */
  public algorithmSpec_columnConcreteStrengthen_steelStringGrabber(): void {
    let columnPosition: ColumnPositionIL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      columnPosition = this.struct.columnWorkIL.level1.columnPosition;
    }
    if (columnPosition && columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      this.columnConcreteStrengthen_steelStringGrabber.specPipeType =
        columnPosition.cylinderColumnPart.columnPipeSample.specPipeType;
      this.columnConcreteStrengthen_steelStringGrabber.specCrossSize1 =
        columnPosition.cylinderColumnPart.columnPipeSample.specCrossSize;
      this.columnConcreteStrengthen_steelStringGrabber.specCrossSize2 =
        this.columnConcreteStrengthen_pipe.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [기둥 콘크리트 보강 + 각형 기둥] 용접 <- 공통 샘플
   */
  public algorithmSpec_columnConcreteStrengthen_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
