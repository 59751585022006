import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemTrellisStringAppendage } from "vhows-design/src/object/design/item/list/ItemTrellisStringAppendage";
import { ItemCordWire } from "vhows-design/src/object/design/item/list/ItemCordWire";
import { ItemBracket } from "vhows-design/src/object/design/item/list/ItemBracket";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { Part } from "vhows-design/src/object/design/base/Part";
import { HydCultivationPosition } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPosition";
import { HydSystemPosition } from "vhows-design/src/object/design/hydroponics/system/HydSystemPosition";
import { HydCultivationSample_CoatingWire } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_CoatingWire";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject({
  knownTypes: [HydCultivationSample_CoatingWire],
})
export class HydCultivationPart_CultivationMaterial extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public coatingWireSample: HydCultivationSample_CoatingWire; // 코팅 와이어 샘플

  // 아이템
  public cultivation_trellisRoundBar: ItemTrellisStringAppendage;
  public cultivation_trellisHanger: ItemTrellisStringAppendage;
  public cultivation_coatingWire: ItemCordWire;
  public cultivation_bracket: ItemBracket;
  public cultivation_boltAndNut: ItemFrameOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _totalTrellisLength: number = 0; // 총 유인줄 길이
  @jsonMember(Number)
  public _trellisLength: number = 0; // 유인줄 길이
  @jsonMember(Number)
  public _trellisLineNumber: number = 0; // 유인줄 줄수

  /**
   * 총 유인줄 길이
   */
  public get totalTrellisLength(): number {
    return this._totalTrellisLength;
  }

  //
  public set totalTrellisLength(value: number) {
    this._totalTrellisLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 유인줄 길이
   */
  public get trellisLength(): number {
    return this._trellisLength;
  }

  //
  public set trellisLength(value: number) {
    this._trellisLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalTrellisLength();
    this.algorithmPart();
  }

  /**
   * 유인줄 줄수
   */
  public get trellisLineNumber(): number {
    return this._trellisLineNumber;
  }

  //
  public set trellisLineNumber(value: number) {
    this._trellisLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalTrellisLength();
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.coatingWireSample = new HydCultivationSample_CoatingWire();

    this.sampleAC = [this.coatingWireSample];

    // 아이템
    this.cultivation_coatingWire = new ItemCordWire();
    this.cultivation_trellisHanger = new ItemTrellisStringAppendage();
    this.cultivation_trellisRoundBar = new ItemTrellisStringAppendage();
    this.cultivation_bracket = new ItemBracket();
    this.cultivation_boltAndNut = new ItemFrameOther();

    this.itemAC = [
      this.cultivation_coatingWire,
      this.cultivation_trellisHanger,
      this.cultivation_trellisRoundBar,
      this.cultivation_bracket,
      this.cultivation_boltAndNut,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.coatingWireSample = <HydCultivationSample_CoatingWire>this.sampleAC[0];

    // 아이템
    this.cultivation_coatingWire = <ItemCordWire>this.itemAC[0];
    this.cultivation_trellisHanger = <ItemTrellisStringAppendage>this.itemAC[1];
    this.cultivation_trellisRoundBar = <ItemTrellisStringAppendage>this.itemAC[2];
    this.cultivation_bracket = <ItemBracket>this.itemAC[3];
    this.cultivation_boltAndNut = <ItemFrameOther>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param trellisLineNumber: number 유인줄 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    trellisLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._trellisLineNumber = trellisLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.coatingWireSample.setDefaultData(
        0,
        CONST.ITEM_ID_COATING_WIRE,
        CONST.ITEM_NAME_COATING_WIRE,
        "재배자재 부속",
        "백색코팅줄, 철사, 3.5mm, 500m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.cultivation_coatingWire.setDefaultData(
        0,
        CONST.ITEM_ID_COATING_WIRE,
        CONST.ITEM_NAME_COATING_WIRE,
        "재배자재 부속",
        "백색코팅줄, 철사, 3.5mm, 500m",
      );
      this.cultivation_trellisHanger.setDefaultData(
        1,
        CONST.ITEM_ID_TRELLIS_HANGER,
        CONST.ITEM_NAME_TRELLIS_HANGER,
        "재배자재 부속",
        "일반",
      );
      this.cultivation_trellisRoundBar.setDefaultData(
        2,
        CONST.ITEM_ID_TRELLIS_ROUND_BAR,
        CONST.ITEM_NAME_TRELLIS_ROUND_BAR,
        "재배자재 부속",
        "일반",
      );
      this.cultivation_bracket.setDefaultData(
        3,
        CONST.ITEM_ID_BRACKET,
        CONST.ITEM_NAME_BRACKET,
        "재배자재 부속",
        "일반, 6T, 50x140mm",
      );
      this.cultivation_boltAndNut.setDefaultData(
        4,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "재배자재 부속",
        "육각, M12*140mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_totalTrellisLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////
    const hydCultivationPosition: HydCultivationPosition = <HydCultivationPosition>this.position;

    /// //////// 선택, 가시성 ///////////
    this.cultivation_coatingWire.selected = true;
    this.cultivation_coatingWire.visible = true;
    this.cultivation_trellisHanger.selected = true;
    this.cultivation_trellisHanger.visible = true;
    this.cultivation_trellisRoundBar.selected = true;
    this.cultivation_trellisRoundBar.visible = true;
    this.cultivation_bracket.selected = true;
    this.cultivation_bracket.visible = true;
    this.cultivation_boltAndNut.selected = true;
    this.cultivation_boltAndNut.visible = true;

    /// ////////  수량 ///////////
    this.cultivation_coatingWire.designQuantity = this.totalTrellisLength / this.cultivation_coatingWire.specLength;

    this.cultivation_trellisHanger.designQuantity =
      this.totalTrellisLength / hydCultivationPosition.bedPart.cropInterval;

    if (hydCultivationPosition.cultivationWay === CONST.LB_HYD_CULTIVATION_CULTIVATION_WAY_LENGTH) {
      this.cultivation_trellisRoundBar.designQuantity = 2 * 2 * this.basic.buildingNumber;
    } else if (hydCultivationPosition.cultivationWay === CONST.LB_HYD_CULTIVATION_CULTIVATION_WAY_WIDTH) {
    }

    this.cultivation_bracket.designQuantity = this.cultivation_trellisRoundBar.designQuantity * 2;

    this.cultivation_boltAndNut.designQuantity = this.cultivation_bracket.designQuantity * 2;
  }

  /**
   * 총 유인줄 길이 <- 유인줄 길이, 유인줄 줄수, 하우스 동수
   */
  public algorithm_totalTrellisLength(): void {
    this.totalTrellisLength = this.trellisLength * this.trellisLineNumber * this.basic.buildingNumber;
  }

  /**
   * 유인줄 길이 <- 재배부 길이(양액시스템)
   */
  public algorithm_trellisLength(): void {
    const hydSystemPositioin: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;

    this.trellisLength = hydSystemPositioin.cultivationLength;
  }

  /**
   * 유인줄 줄수 <- 동당 베드 줄수(베드 파트)
   */
  public algorithm_trellisLineNumber(): void {
    const hydCultivationPosition: HydCultivationPosition = <HydCultivationPosition>this.position;

    this.trellisLineNumber = hydCultivationPosition.bedPart.bedLineNumber * 2;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [재배자재 부속] 코팅 와이어 <- 코팅 와이어 샘플
   */
  public algorithmSpec_cultivation_coatingWire(): void {
    this.cultivation_coatingWire.specs = this.coatingWireSample.specs;
  }

  /**
   * 규격 알고리즘: [재배자재 부속] 유인줄 걸이
   */
  public algorithmSpec_cultivation_trellisHanger(): void {}

  /**
   * 규격 알고리즘: [재배자재 부속] 유인줄 환봉
   */
  public algorithmSpec_cultivation_trellisRoundBar(): void {}

  /**
   * 규격 알고리즘: [재배자재 부속] 브라켓트
   */
  public algorithmSpec_cultivation_bracket(): void {}

  /**
   * 규격 알고리즘: [재배자재 부속] 볼트&너트
   */
  public algorithmSpec_cultivation_boltAndNut(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
