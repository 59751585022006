import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { HydSystemPart_HydDrain } from "vhows-design/src/object/design/hydroponics/system/HydSystemPart_HydDrain";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject
export class HydSystemSample_RecoveryMainPvcPipe extends ItemDrainPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const drainPart: HydSystemPart_HydDrain = <HydSystemPart_HydDrain>this.part;
    drainPart.algorithmSpec_drainMain_pvcPipe();
    drainPart.algorithmSpec_drainMain_pvcEnd();
    drainPart.algorithmSpec_drainMain_pvcElbow();
    drainPart.algorithmSpec_drainMain_pvcExactT();
    drainPart.algorithmSpec_recoveryMain_pvcDifferentT();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
