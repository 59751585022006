import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFormingBed } from "vhows-design/src/object/design/item/list/ItemFormingBed";
import { ItemStyrofoamBed } from "vhows-design/src/object/design/item/list/ItemStyrofoamBed";
import { ItemBedAppendage } from "vhows-design/src/object/design/item/list/ItemBedAppendage";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemGroundCover } from "vhows-design/src/object/design/item/list/ItemGroundCover";
import { ItemGroundCoverAppendage } from "vhows-design/src/object/design/item/list/ItemGroundCoverAppendage";
import { ItemNftBed } from "vhows-design/src/object/design/item/list/ItemNftBed";
import { ItemSconce } from "vhows-design/src/object/design/item/list/ItemSconce";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { HydSystemPosition } from "vhows-design/src/object/design/hydroponics/system/HydSystemPosition";
import { HydCultivationPosition } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPosition";
import { HydCultivationSample_FormingBed } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_FormingBed";
import { HydCultivationSample_StyrofoamBed } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_StyrofoamBed";
import { HydCultivationSample_NftBed } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_NftBed";
import { HydCultivationSample_BedColumnPipe } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_BedColumnPipe";
import { HydCultivationSample_BedSupportPipe } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_BedSupportPipe";
import { HydCultivationSample_BedBeamPipe } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationSample_BedBeamPipe";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject({
  knownTypes: [
    HydCultivationSample_FormingBed,
    HydCultivationSample_StyrofoamBed,
    HydCultivationSample_NftBed,
    HydCultivationSample_BedColumnPipe,
    HydCultivationSample_BedSupportPipe,
    HydCultivationSample_BedBeamPipe,
  ],
})
export class HydCultivationPart_Bed extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public formingBedSample: HydCultivationSample_FormingBed; // 포밍 베드 샘플
  public styrofoamBedSample: HydCultivationSample_StyrofoamBed; // 스티로폼 베드 샘플
  public nftBedSample: HydCultivationSample_NftBed; // NFT 베드 샘플
  public bedColumnPipeSample: HydCultivationSample_BedColumnPipe; // 베드 기둥 파이프 샘플
  public bedSupportPipeSample: HydCultivationSample_BedSupportPipe; // 베드 받침 파이프 샘플
  public bedBeamPipeSample: HydCultivationSample_BedBeamPipe; // 베드 보 파이프 샘플

  // 아이템
  public bed_formingBed: ItemFormingBed;
  public formingBed_bedEnd: ItemBedAppendage;
  public formingBed_bedDrain: ItemBedAppendage;
  public formingBed_bedSupportClip: ItemBedAppendage;
  public bed_styrofoamBed: ItemStyrofoamBed;
  public bedBeam_pipe: ItemPipe;
  public bed_nftBed: ItemNftBed;
  public nftBed_bedHolder: ItemBedAppendage;
  public nftBed_nftSupplyDrainSet: ItemBedAppendage;
  public nftBed_innerTHolder: ItemSconce;
  public bedColumn_pipe: ItemPipe;
  public bedSupport_pipe: ItemPipe;
  public bed_sinkGuardPlate: ItemBedAppendage;
  public bed_groundCover: ItemGroundCover;
  public bed_sackPin: ItemGroundCoverAppendage;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _totalBedLineNumber: number = 0; // 총 베드 줄수
  @jsonMember(Number)
  public _bedLineNumber: number = 0; // 동당 베드 줄수
  @jsonMember(Number)
  public _linePerNftBed: number = 0; // NFT 베드당 라인
  @jsonMember(Number)
  public _nftLineInterval: number = 0; // NFT 라인 간격
  @jsonMember(Number)
  public _nftBedSpaceNumber: number = 0; // NFT 베드 칸수
  @jsonMember(Number)
  public _bedLength: number = 0; // 베드 길이
  @jsonMember(Number)
  public _bedWidth: number = 0; // 베드 폭
  @jsonMember(Number)
  public _hallwayWidth: number; // 통로 폭
  @jsonMember(Number)
  public _bedHeight: number; // 베드 높이
  @jsonMember(Number)
  public _bedDepthLaying: number; // 베드 매설 깊이
  @jsonMember(Number)
  public _cropInterval: number = 0; // 작물 간격

  /**
   * 총 베드 줄수
   */
  public get totalBedLineNumber(): number {
    return this._totalBedLineNumber;
  }

  //
  public set totalBedLineNumber(value: number) {
    this._totalBedLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<HydCultivationPosition>this.position).groundRailPart.algorithm_totalRailLineNumber();

    /// //////// 외부 ///////////

    // 양액 제어부
    this.struct.hydSystemWork.level1.wholePosition.hydSupplyPart.algorithm_totalSupplySubLineNumber();
    this.struct.hydSystemWork.level1.wholePosition.hydSupplyPart.algorithm_supplyMainBLength();
    this.struct.hydSystemWork.level1.wholePosition.hydDrainPart.algorithm_drainMainLength();
  }

  /**
   * 동당 베드 줄수
   */
  public get bedLineNumber(): number {
    return this._bedLineNumber;
  }

  //
  public set bedLineNumber(value: number) {
    this._bedLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_totalBedLineNumber();
    this.algorithm_hallwayWidth();
    this.algorithmPart();

    // 다른 파트
    (<HydCultivationPosition>this.position).cultivationPart.algorithm_trellisLineNumber();
    (<HydCultivationPosition>this.position).groundRailPart.algorithm_railLineNumber();
  }

  /**
   * NFT 베드당 라인
   */
  public get linePerNftBed(): number {
    return this._linePerNftBed;
  }

  //
  public set linePerNftBed(value: number) {
    this._linePerNftBed = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_bedWidth();
    this.algorithmPart();
  }

  /**
   * NFT 라인 간격
   */
  public get nftLineInterval(): number {
    return this._nftLineInterval;
  }

  //
  public set nftLineInterval(value: number) {
    this._nftLineInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_bedWidth();
    this.algorithmPart();
  }

  /**
   * NFT 베드 칸수
   */
  public get nftBedSpaceNumber(): number {
    return this._nftBedSpaceNumber;
  }

  //
  public set nftBedSpaceNumber(value: number) {
    this._nftBedSpaceNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_bedLength();
    this.algorithmPart();
  }

  /**
   * 베드 길이
   */
  public get bedLength(): number {
    return this._bedLength;
  }

  //
  public set bedLength(value: number) {
    this._bedLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 양액 제어부
    this.struct.hydSystemWork.level1.wholePosition.hydSupplyPart.algorithm_subLengthPerBed();
  }

  /**
   * 베드 폭
   */
  public get bedWidth(): number {
    return this._bedWidth;
  }

  //
  public set bedWidth(value: number) {
    this._bedWidth = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_hallwayWidth();
    this.algorithmSpec_bedSupport_pipe();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 양액 제어부
    this.struct.hydSystemWork.level1.wholePosition.hydSupplyPart.algorithm_supplyMainBLength();
    this.struct.hydSystemWork.level1.wholePosition.hydDrainPart.algorithm_drainMainLength();
  }

  /**
   * 통로 폭
   */
  public get hallwayWidth(): number {
    return this._hallwayWidth;
  }

  //
  public set hallwayWidth(value: number) {
    this._hallwayWidth = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 베드 높이
   */
  public get bedHeight(): number {
    return this._bedHeight;
  }

  //
  public set bedHeight(value: number) {
    this._bedHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmSpec_bedColumn_pipe();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 양액 제어부
    this.struct.hydSystemWork.level1.wholePosition.hydSupplyPart.algorithm_supplyMainBLength();
    this.struct.hydSystemWork.level1.wholePosition.hydDrainPart.algorithm_drainMainLength();
  }

  /**
   * 베드 매설 깊이
   */
  public get bedDepthLaying(): number {
    return this._bedDepthLaying;
  }

  //
  public set bedDepthLaying(value: number) {
    this._bedDepthLaying = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmSpec_bedColumn_pipe();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 양액 제어부
    this.struct.hydSystemWork.level1.wholePosition.hydSupplyPart.algorithm_supplyMainBLength();
    this.struct.hydSystemWork.level1.wholePosition.hydDrainPart.algorithm_drainMainLength();
  }

  /**
   * 작물 간격
   */
  public get cropInterval(): number {
    return this._cropInterval;
  }

  //
  public set cropInterval(value: number) {
    this._cropInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.formingBedSample = new HydCultivationSample_FormingBed();
    this.styrofoamBedSample = new HydCultivationSample_StyrofoamBed();
    this.nftBedSample = new HydCultivationSample_NftBed();
    this.bedColumnPipeSample = new HydCultivationSample_BedColumnPipe();
    this.bedSupportPipeSample = new HydCultivationSample_BedSupportPipe();
    this.bedBeamPipeSample = new HydCultivationSample_BedBeamPipe();

    this.sampleAC = [
      this.formingBedSample,
      this.styrofoamBedSample,
      this.nftBedSample,
      this.bedColumnPipeSample,
      this.bedSupportPipeSample,
      this.bedBeamPipeSample,
    ];

    // 아이템
    this.bed_formingBed = new ItemFormingBed();
    this.formingBed_bedEnd = new ItemBedAppendage();
    this.formingBed_bedDrain = new ItemBedAppendage();
    this.formingBed_bedSupportClip = new ItemBedAppendage();
    this.bed_styrofoamBed = new ItemStyrofoamBed();
    this.bedBeam_pipe = new ItemPipe();
    this.bed_nftBed = new ItemNftBed();
    this.nftBed_bedHolder = new ItemBedAppendage();
    this.nftBed_nftSupplyDrainSet = new ItemBedAppendage();
    this.nftBed_innerTHolder = new ItemSconce();
    this.bedColumn_pipe = new ItemPipe();
    this.bedSupport_pipe = new ItemPipe();
    this.bed_sinkGuardPlate = new ItemBedAppendage();
    this.bed_groundCover = new ItemGroundCover();
    this.bed_sackPin = new ItemGroundCoverAppendage();

    this.itemAC = [
      this.bed_formingBed,
      this.formingBed_bedEnd,
      this.formingBed_bedDrain,
      this.formingBed_bedSupportClip,
      this.bed_styrofoamBed,
      this.bedBeam_pipe,
      this.bed_nftBed,
      this.nftBed_bedHolder,
      this.nftBed_nftSupplyDrainSet,
      this.nftBed_innerTHolder,
      this.bedColumn_pipe,
      this.bedSupport_pipe,
      this.bed_sinkGuardPlate,
      this.bed_groundCover,
      this.bed_sackPin,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.formingBedSample = <HydCultivationSample_FormingBed>this.sampleAC[0];
    this.styrofoamBedSample = <HydCultivationSample_StyrofoamBed>this.sampleAC[1];
    this.nftBedSample = <HydCultivationSample_NftBed>this.sampleAC[2];
    this.bedColumnPipeSample = <HydCultivationSample_BedColumnPipe>this.sampleAC[3];
    this.bedSupportPipeSample = <HydCultivationSample_BedSupportPipe>this.sampleAC[4];
    this.bedBeamPipeSample = <HydCultivationSample_BedBeamPipe>this.sampleAC[5];
    // 아이템
    this.bed_formingBed = <ItemFormingBed>this.itemAC[0];
    this.formingBed_bedEnd = <ItemBedAppendage>this.itemAC[1];
    this.formingBed_bedDrain = <ItemBedAppendage>this.itemAC[2];
    this.formingBed_bedSupportClip = <ItemBedAppendage>this.itemAC[3];
    this.bed_styrofoamBed = <ItemStyrofoamBed>this.itemAC[4];
    this.bedBeam_pipe = <ItemPipe>this.itemAC[5];
    this.bed_nftBed = <ItemNftBed>this.itemAC[6];
    this.nftBed_bedHolder = <ItemBedAppendage>this.itemAC[7];
    this.nftBed_nftSupplyDrainSet = <ItemBedAppendage>this.itemAC[8];
    this.nftBed_innerTHolder = <ItemSconce>this.itemAC[9];
    this.bedColumn_pipe = <ItemPipe>this.itemAC[10];
    this.bedSupport_pipe = <ItemPipe>this.itemAC[11];
    this.bed_sinkGuardPlate = <ItemBedAppendage>this.itemAC[12];
    this.bed_groundCover = <ItemGroundCover>this.itemAC[13];
    this.bed_sackPin = <ItemGroundCoverAppendage>this.itemAC[14];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param bedLineNumber: number 동당 베드 줄수
   * @param linePerNftBed: number NFT 베드당 라인
   * @param nftLineInterval: number NFT 라인 간격
   * @param cropInterval: number 작물 간격
   * @param bedHeight: number 베드 높이
   * @param bedDepthLaying: number 베드 매설 깊이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    bedLineNumber: number = 0,
    linePerNftBed: number = 0,
    nftLineInterval: number = 0,
    cropInterval: number = 0,
    bedHeight: number = 0,
    bedDepthLaying: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._bedLineNumber = bedLineNumber;
    this._linePerNftBed = linePerNftBed;
    this._nftLineInterval = nftLineInterval;
    this._cropInterval = cropInterval;
    this._bedHeight = bedHeight;
    this._bedDepthLaying = bedDepthLaying;

    // 샘플
    if (this.level.index >= 0) {
      this.formingBedSample.setDefaultData(
        0,
        CONST.ITEM_ID_FORMING_BED,
        CONST.ITEM_NAME_FORMING_BED,
        "포밍 베드",
        "일반, 0.6T, 20cm, 11cm",
      );
      this.styrofoamBedSample.setDefaultData(
        1,
        CONST.ITEM_ID_STYROFOAM_BED,
        CONST.ITEM_NAME_STYROFOAM_BED,
        "스티로폼 베드",
        "일반, 20cm, 1m",
      );
      this.nftBedSample.setDefaultData(2, CONST.ITEM_ID_NFT_BED, CONST.ITEM_NAME_NFT_BED, "NFT 베드", "일반, 9cm, 20m");
      this.bedColumnPipeSample.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "베드 기둥",
        "원형, 일반, 25.4mm, 1.5T, 1m, -",
      );
      this.bedSupportPipeSample.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "베드 받침",
        "원형, 일반, 25.4mm, 1.5T, 1m, -",
      );
      this.bedBeamPipeSample.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "베드 보",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.bed_formingBed.setDefaultData(
        0,
        CONST.ITEM_ID_FORMING_BED,
        CONST.ITEM_NAME_FORMING_BED,
        "포밍 베드",
        "일반, 0.6T, 20cm, 11cm",
      );
      this.formingBed_bedEnd.setDefaultData(1, CONST.ITEM_ID_BED_END, CONST.ITEM_NAME_BED_END, "포밍 베드", "일반");
      this.formingBed_bedDrain.setDefaultData(
        2,
        CONST.ITEM_ID_BED_DRAIN,
        CONST.ITEM_NAME_BED_DRAIN,
        "포밍 베드",
        "일반",
      );
      this.formingBed_bedSupportClip.setDefaultData(
        3,
        CONST.ITEM_ID_BED_SUPPORT_CLIP,
        CONST.ITEM_NAME_BED_SUPPORT_CLIP,
        "포밍 베드",
        "일반",
      );
      this.bed_styrofoamBed.setDefaultData(
        4,
        CONST.ITEM_ID_STYROFOAM_BED,
        CONST.ITEM_NAME_STYROFOAM_BED,
        "스티로폼 베드",
        "일반, 20cm, 1m",
      );
      this.bedBeam_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "베드 보",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.bed_nftBed.setDefaultData(6, CONST.ITEM_ID_NFT_BED, CONST.ITEM_NAME_NFT_BED, "NFT 베드", "일반, 9cm, 20m");
      this.nftBed_bedHolder.setDefaultData(7, CONST.ITEM_ID_BED_HOLDER, CONST.ITEM_NAME_BED_HOLDER, "NFT 베드", "일반");
      this.nftBed_nftSupplyDrainSet.setDefaultData(
        8,
        CONST.ITEM_ID_NFT_SUPPLY_DRAIN_SET,
        CONST.ITEM_NAME_NFT_SUPPLY_DRAIN_SET,
        "NFT 베드",
        "일반",
      );
      this.nftBed_innerTHolder.setDefaultData(
        9,
        CONST.ITEM_ID_INNER_T_HOLDER,
        CONST.ITEM_NAME_INNER_T_HOLDER,
        "NFT 베드",
        "원형, 일반, 25mm, 25mm",
      );
      this.bedColumn_pipe.setDefaultData(
        10,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "베드 기둥",
        "원형, 일반, 25.4mm, 1.5T, 1m, -",
      );
      this.bedSupport_pipe.setDefaultData(
        11,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "베드 받침",
        "원형, 일반, 25.4mm, 1.5T, 1m, -",
      );
      this.bed_sinkGuardPlate.setDefaultData(
        12,
        CONST.ITEM_ID_SINK_GUARD_PLATE,
        CONST.ITEM_NAME_SINK_GUARD_PLATE,
        "베드 바닥",
        "일반",
      );
      this.bed_groundCover.setDefaultData(
        13,
        CONST.ITEM_ID_GROUND_COVER,
        CONST.ITEM_NAME_GROUND_COVER,
        "베드 바닥",
        "탄탄매트, 검/흰색, 9m, 50m",
      );
      this.bed_sackPin.setDefaultData(14, CONST.ITEM_ID_SACK_PIN, CONST.ITEM_NAME_SACK_PIN, "베드 바닥", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_totalBedLineNumber();
    this.algorithm_hallwayWidth();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////
    const hydCultivationPosition: HydCultivationPosition = this.position as HydCultivationPosition;
    const hydSystemPosition: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;
    /// //////// 선택, 가시성 ///////////
    if (hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_STANDING_GUTTER) {
      this.bed_formingBed.selected = true;
      this.bed_formingBed.visible = true;
      this.formingBed_bedEnd.selected = true;
      this.formingBed_bedEnd.visible = true;
      this.formingBed_bedDrain.selected = true;
      this.formingBed_bedDrain.visible = true;
      this.formingBed_bedSupportClip.selected = true;
      this.formingBed_bedSupportClip.visible = true;
      this.bed_styrofoamBed.selected = false;
      this.bed_styrofoamBed.visible = false;
      this.bedBeam_pipe.selected = false;
      this.bedBeam_pipe.visible = false;
      this.bed_nftBed.selected = false;
      this.bed_nftBed.visible = false;
      this.nftBed_bedHolder.selected = false;
      this.nftBed_bedHolder.visible = false;
      this.nftBed_nftSupplyDrainSet.selected = false;
      this.nftBed_nftSupplyDrainSet.visible = false;
      this.nftBed_innerTHolder.selected = false;
      this.nftBed_innerTHolder.visible = false;
    } else if (hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_STYROFOAM_BED) {
      this.bed_styrofoamBed.selected = true;
      this.bed_styrofoamBed.visible = true;
      this.bedBeam_pipe.selected = true;
      this.bedBeam_pipe.visible = true;
      this.bed_formingBed.selected = false;
      this.bed_formingBed.visible = false;
      this.formingBed_bedEnd.selected = false;
      this.formingBed_bedEnd.visible = false;
      this.formingBed_bedDrain.selected = false;
      this.formingBed_bedDrain.visible = false;
      this.formingBed_bedSupportClip.selected = false;
      this.formingBed_bedSupportClip.visible = false;
      this.bed_nftBed.selected = false;
      this.bed_nftBed.visible = false;
      this.nftBed_bedHolder.selected = false;
      this.nftBed_bedHolder.visible = false;
      this.nftBed_nftSupplyDrainSet.selected = false;
      this.nftBed_nftSupplyDrainSet.visible = false;
      this.nftBed_innerTHolder.selected = false;
      this.nftBed_innerTHolder.visible = false;
    } else if (hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_NFT_BED) {
      this.bed_nftBed.selected = true;
      this.bed_nftBed.visible = true;
      this.nftBed_bedHolder.selected = true;
      this.nftBed_bedHolder.visible = true;
      this.nftBed_nftSupplyDrainSet.selected = true;
      this.nftBed_nftSupplyDrainSet.visible = true;
      this.nftBed_innerTHolder.selected = true;
      this.nftBed_innerTHolder.visible = true;
      this.bed_formingBed.selected = false;
      this.bed_formingBed.visible = false;
      this.formingBed_bedEnd.selected = false;
      this.formingBed_bedEnd.visible = false;
      this.formingBed_bedDrain.selected = false;
      this.formingBed_bedDrain.visible = false;
      this.formingBed_bedSupportClip.selected = false;
      this.formingBed_bedSupportClip.visible = false;
      this.bed_styrofoamBed.selected = false;
      this.bed_styrofoamBed.visible = false;
      this.bedBeam_pipe.selected = false;
      this.bedBeam_pipe.visible = false;
    }
    this.bedColumn_pipe.selected = true;
    this.bedColumn_pipe.visible = true;
    this.bedSupport_pipe.selected = true;
    this.bedSupport_pipe.visible = true;
    this.bed_sinkGuardPlate.selected = true;
    this.bed_sinkGuardPlate.visible = true;
    this.bed_groundCover.selected = true;
    this.bed_groundCover.visible = true;
    this.bed_sackPin.selected = true;
    this.bed_sackPin.visible = true;

    /// ////////  수량 ///////////
    this.bed_formingBed.designQuantity = CommonUtil.roundUp(this.bedLength * this.totalBedLineNumber);

    this.formingBed_bedEnd.designQuantity = this.totalBedLineNumber;

    this.formingBed_bedDrain.designQuantity = this.totalBedLineNumber;

    this.formingBed_bedSupportClip.designQuantity =
      (CommonUtil.roundUp(this.bedLength / 3) + 1) * this.totalBedLineNumber;

    this.bed_styrofoamBed.designQuantity = CommonUtil.roundUp(
      (this.bedLength * this.totalBedLineNumber) / this.bed_styrofoamBed.specLength,
    );

    this.bedBeam_pipe.designQuantity =
      CommonUtil.roundUp((this.bedLength * this.totalBedLineNumber) / this.bedBeam_pipe.specLength) * 2;

    this.bed_nftBed.designQuantity = this.linePerNftBed * this.totalBedLineNumber * this.nftBedSpaceNumber;

    this.nftBed_bedHolder.designQuantity = (CommonUtil.roundUp(this.bedLength / 3) + 1) * this.totalBedLineNumber * 2;

    this.nftBed_nftSupplyDrainSet.designQuantity = this.bed_nftBed.designQuantity;

    this.nftBed_innerTHolder.designQuantity =
      (CommonUtil.roundUp(this.bedLength / 3) + 1) * this.totalBedLineNumber * 2;

    this.bedColumn_pipe.designQuantity =
      ((CommonUtil.roundUp(this.bedLength / 3) + 1) * this.totalBedLineNumber * 2) / this.bedColumn_pipe.specLength;

    this.bedSupport_pipe.designQuantity =
      ((CommonUtil.roundUp(this.bedLength / 3) + 1) * this.totalBedLineNumber) / this.bedColumn_pipe.specLength;

    this.bed_sinkGuardPlate.designQuantity = (CommonUtil.roundUp(this.bedLength / 3) + 1) * this.totalBedLineNumber * 2;

    this.bed_groundCover.designQuantity = 1;

    this.bed_sackPin.designQuantity = 1;
  }

  /**
   * 알고리즘: 총 베드 줄수 <- 동당 베드 줄수, 하우스 동수
   */
  public algorithm_totalBedLineNumber(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.totalBedLineNumber = this.bedLineNumber * this.basic.buildingNumber;
    } else if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.design.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.totalBedLineNumber = this.bedLineNumber * this.basic.buildingNumber - 1;
    }
  }

  /**
   * 알고리즘: 동당 베드 줄수, NFT 베드당 라인, NFT 라인 간격, 작물 간격 <- 형태(양액시스템)
   */
  public algorithm_bedInfo(): void {
    const hydSystemPosition: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.bedLineNumber = 5;
      this.linePerNftBed = 0;
      this.nftLineInterval = 0;
      this.cropInterval = 10;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.bedLineNumber = 4;
      this.linePerNftBed = 6;
      this.nftLineInterval = 5;
      this.cropInterval = 30;
    }
  }

  /**
   * 알고리즘: NFT 베드 칸수 <- 재배대 방향(재배대), 재배부 길이(양액시스템), NFT 베드 샘플 단위길이
   */
  public algorithm_nftBedSpaceNumber(): void {
    const hydCultivationPosition: HydCultivationPosition = this.position as HydCultivationPosition;
    const hydSystemPosition: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;

    if (hydCultivationPosition.cultivationWay === CONST.LB_HYD_CULTIVATION_CULTIVATION_WAY_LENGTH) {
      this.nftBedSpaceNumber = CommonUtil.roundDown(hydSystemPosition.cultivationLength / this.nftBedSample.specLength);
    } else if (hydCultivationPosition.cultivationWay === CONST.LB_HYD_CULTIVATION_CULTIVATION_WAY_WIDTH) {
      this.nftBedSpaceNumber = CommonUtil.roundDown(hydSystemPosition.cultivationWidth / this.nftBedSample.specLength);
    }
  }

  /**
   * 알고리즘: 베드 길이 <- 형태(양액시스템), 재배부 길이(양액시스템), NFT 베드 칸수, NFT 베드 샘플 단위길이
   */
  public algorithm_bedLength(): void {
    const hydSystemPosition: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.bedLength = hydSystemPosition.cultivationLength;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.bedLength = this.nftBedSample.specLength * this.nftBedSpaceNumber;
    }
  }

  /**
   * 알고리즘: 베드 폭 <- 베드 샘플, NFT 베드당 라인, NFT 라인 간격
   */
  public algorithm_bedWidth(): void {
    const hydCultivationPosition: HydCultivationPosition = this.position as HydCultivationPosition;

    if (hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_STANDING_GUTTER) {
      this.bedWidth = CommonUtil.convertCM2M(this.formingBedSample.specWidth);
    } else if (hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_STYROFOAM_BED) {
      this.bedWidth = CommonUtil.convertCM2M(this.styrofoamBedSample.specWidth);
    } else if (hydCultivationPosition.type === CONST.LB_HYD_CULTIVATION_TYPE_NFT_BED) {
      this.bedWidth = CommonUtil.convertCM2M(
        this.nftBedSample.specWidth * this.linePerNftBed + this.nftLineInterval * (this.linePerNftBed - 1),
      );
    }
  }

  /**
   * 알고리즘: 통로 폭 <- 재배대 방향, 하우스 폭(기본 정보), 기둥 간격(연동,벤로), 기둥 형태(연동,벤로), 동당 베드 줄수, 베드 폭
   */
  public algorithm_hallwayWidth(): void {
    const hydCultivationPosition: HydCultivationPosition = this.position as HydCultivationPosition;

    if (hydCultivationPosition.cultivationWay === CONST.LB_HYD_CULTIVATION_CULTIVATION_WAY_LENGTH) {
      this.hallwayWidth = this.basicLevel.width / this.bedLineNumber - this.bedWidth;
    } else {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
          this.hallwayWidth = columnPosition.cylinderColumnPart.columnInterval / this.bedLineNumber - this.bedWidth;
        } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.hallwayWidth = columnPosition.prismColumnPart.columnInterval / this.bedLineNumber - this.bedWidth;
        }
      } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
        const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
        if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
          this.hallwayWidth = columnPosition.prismColumnPart.columnInterval / this.bedLineNumber - this.bedWidth;
        }
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [포밍 베드] 포밍 베드 <- 포밍 베드 샘플
   */
  public algorithmSpec_bed_formingBed(): void {
    this.bed_formingBed.specs = this.formingBedSample.specs;
  }

  /**
   * 규격 알고리즘: [포밍 베드] 베드 마감
   */
  public algorithmSpec_formingBed_bedEnd(): void {}

  /**
   * 규격 알고리즘: [포밍 베드] 베드 배수구
   */
  public algorithmSpec_formingBed_bedDrain(): void {}

  /**
   * 규격 알고리즘: [포밍 베드] 베드 보강클립
   */
  public algorithmSpec_formingBed_bedSupportClip(): void {}

  /**
   * 규격 알고리즘: [스티로폼 베드] 스티로폼 베드 <- 스티로폼 베드 샘플
   */
  public algorithmSpec_bed_styrofoamBed(): void {
    this.bed_styrofoamBed.specs = this.styrofoamBedSample.specs;
  }

  /**
   * 규격 알고리즘: [베드 보] 파이프 <- 베드 보 파이프 샘플
   */
  public algorithmSpec_bedBeam_pipe(): void {
    this.bedBeam_pipe.specs = this.bedBeamPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [NFT 베드] NFT 베드 <- NFT 베드 샘플
   */
  public algorithmSpec_bed_nftBed(): void {
    this.bed_nftBed.specs = this.nftBedSample.specs;
  }

  /**
   * 규격 알고리즘: [NFT 베드] 베드 고정구
   */
  public algorithmSpec_nftBed_bedHolder(): void {}

  /**
   * 규격 알고리즘: [NFT 베드] NFT 급/배수구 세트
   */
  public algorithmSpec_nftBed_nftSupplyDrainSet(): void {}

  /**
   * 규격 알고리즘: [NFT 베드] 속T 고정구 <- 베드 기둥 파이프 샘플, 베드 받침 파이프 샘플
   */
  public algorithmSpec_nftBed_innerTHolder(): void {
    this.nftBed_innerTHolder.specPipeType = this.bedSupportPipeSample.specPipeType;
    this.nftBed_innerTHolder.specCrossSize1 = this.bedSupportPipeSample.specCrossSize;
    this.nftBed_innerTHolder.specCrossSize2 = this.bedColumnPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [베드 기둥] 파이프 <- 베드 기둥 파이프 샘플, 베드 높이, 베드 매설 깊이
   */
  public algorithmSpec_bedColumn_pipe(): void {
    this.bedSupport_pipe.specsList = this.bedColumnPipeSample.specsList;
    this.bedSupport_pipe.specLength = this.bedHeight + this.bedDepthLaying;
  }

  /**
   * 규격 알고리즘: [베드 받침] 파이프 <- 베드 받침 파이프 샘플, 베드 폭
   */
  public algorithmSpec_bedSupport_pipe(): void {
    this.bedSupport_pipe.specsList = this.bedSupportPipeSample.specsList;
    this.bedSupport_pipe.specLength = this.bedWidth;
  }

  /**
   * 규격 알고리즘: [베드 바닥] 침하방지판
   */
  public algorithmSpec_bed_sinkGuardPlate(): void {}

  /**
   * 규격 알고리즘: [베드 바닥] 바닥지 <- 바닥지 샘플
   */
  public algorithmSpec_bed_groundCover(): void {}

  /**
   * 규격 알고리즘: [베드 바닥] 마대핀
   */
  public algorithmSpec_bed_sackPin(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
