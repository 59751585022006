import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { WindbreakPartIL_Windbreak } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPartIL_Windbreak";
import { WindbreakPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPartIL_FixingNormalPad";
import { WindbreakPartIL_FixingNormalClip } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPartIL_FixingNormalClip";
import { WindbreakPartIL_FixingLaggingPad } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPartIL_FixingLaggingPad";
import { WindbreakPartIL_FixingWood } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPartIL_FixingWood";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [
    WindbreakPartIL_FixingLaggingPad,
    WindbreakPartIL_FixingNormalClip,
    WindbreakPartIL_FixingNormalPad,
    WindbreakPartIL_FixingWood,
    WindbreakPartIL_Windbreak,
  ],
})
export class WindbreakPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public windbreakPart: WindbreakPartIL_Windbreak;
  public fixingNormalPadPart: WindbreakPartIL_FixingNormalPad;
  public fixingNormalClipPart: WindbreakPartIL_FixingNormalClip;
  public fixingLaggingPadPart: WindbreakPartIL_FixingLaggingPad;
  public fixingWoodPart: WindbreakPartIL_FixingWood;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _fixingType: string; // 고정 형태

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 위치
    this.algorithm_typeByPosition();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_typeByLevel1();
    }

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.windbreakPart = new WindbreakPartIL_Windbreak();
    this.fixingNormalPadPart = new WindbreakPartIL_FixingNormalPad();
    this.fixingNormalClipPart = new WindbreakPartIL_FixingNormalClip();
    this.fixingLaggingPadPart = new WindbreakPartIL_FixingLaggingPad();
    this.fixingWoodPart = new WindbreakPartIL_FixingWood();

    this.partAC = [
      this.windbreakPart,
      this.fixingNormalPadPart,
      this.fixingNormalClipPart,
      this.fixingLaggingPadPart,
      this.fixingWoodPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.windbreakPart = <WindbreakPartIL_Windbreak>this.partAC[0];
    this.fixingNormalPadPart = <WindbreakPartIL_FixingNormalPad>this.partAC[1];
    this.fixingNormalClipPart = <WindbreakPartIL_FixingNormalClip>this.partAC[2];
    this.fixingLaggingPadPart = <WindbreakPartIL_FixingLaggingPad>this.partAC[3];
    this.fixingWoodPart = <WindbreakPartIL_FixingWood>this.partAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param fixingType: string 고정 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._fixingType = fixingType;

    if (this.level.index >= 0) {
      // 바람막이 피복
      if (
        label === CONST.LB_POSITION_SIDE_COLUMN ||
        label === CONST.LB_POSITION_SIDE_WING ||
        label === CONST.LB_POSITION_ROOF
      ) {
        this.windbreakPart.setDefaultData(
          CONST.INDEX_IL_WINDBREAK_DEFAULT,
          true,
          false,
          true,
          CONST.LB_WINDBREAK_DEFAULT,
          "",
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        this.windbreakPart.setDefaultData(
          CONST.INDEX_IL_WINDBREAK_DEFAULT,
          false,
          false,
          false,
          CONST.LB_WINDBREAK_DEFAULT,
          "",
        );
      }

      // 바람막이 피복 고정 - 일반 패드 고정
      this.fixingNormalPadPart.setDefaultData(
        CONST.INDEX_IL_WINDBREAK_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_WINDBREAK_FIXING_NORMAL_PAD,
        "",
      );

      // 바람막이 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_WINDBREAK_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_WINDBREAK_FIXING_NORMAL_CLIP,
        "",
        0.2,
      );

      // 바람막이 피복 고정 - 광폭 패드 고정
      this.fixingLaggingPadPart.setDefaultData(
        CONST.INDEX_IL_WINDBREAK_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_WINDBREAK_FIXING_LAGGING_PAD,
        "",
      );

      // 바람막이 피복 고정 - 목재 고정
      this.fixingWoodPart.setDefaultData(
        CONST.INDEX_IL_WINDBREAK_FIXING_WOOD,
        false,
        false,
        false,
        CONST.LB_WINDBREAK_FIXING_WOOD,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 상세 피복별 선택 여부
   * @param coverPosition 피복 위치 객체
   */
  public selectedDetail(coverPosition: CoverPositionIL): boolean {
    // 위치별로 바람막이가 선택되었다 하더라도, 피복별로 바람막이가 선택되지 않는 경우가 우선
    if (this.label === CONST.LB_POSITION_SIDE_COLUMN && coverPosition.windbreakCoverSideColumn === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_SIDE_WING && coverPosition.windbreakCoverSideWing === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_ROOF && coverPosition.windbreakCoverRoof === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_FRONT && coverPosition.windbreakCoverFront === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_BACK && coverPosition.windbreakCoverBack === true) {
      return this.selected;
    }
    return false;
  }

  /**
   * 알고리즘: 선택 <- 선택(개폐기), 선택(커튼 개폐)
   */
  public algorithm_selected(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );
    const curSwitchLevel: CurSwitchLevelIL = this.struct.curSwitchWorkIL.level1;

    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.selected = switcherPosition.selected || curSwitchLevel.columnPosition.selected;
    } else {
      this.selected = switcherPosition.selected;
    }
  }

  /**
   * 알고리즘: 가용성 <- 선택(개폐기), 선택(커튼 개폐)
   */
  public algorithm_enabled(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );
    const curSwitchLevel: CurSwitchLevelIL = this.struct.curSwitchWorkIL.level1;

    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.enabled = switcherPosition.selected || curSwitchLevel.columnPosition.selected;
    } else {
      this.enabled = switcherPosition.selected;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (
      this.type === CONST.LB_WINDBREAK_TYPE_DEFAULT ||
      this.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_FIRST_SIDE
    ) {
      this.windbreakPart.selected = true;
      this.windbreakPart.visible = true;
    } else if (this.type === CONST.LB_WINDBREAK_TYPE_DEFAULT_EXTEND_BASIC_COVER) {
      this.windbreakPart.selected = false;
      this.windbreakPart.visible = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택 ///////////

    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
      this.fixingNormalPadPart.selected = true;
      this.fixingNormalPadPart.visible = true;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = true;
      this.fixingNormalClipPart.visible = true;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = true;
      this.fixingLaggingPadPart.visible = true;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_WOOD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = true;
      this.fixingWoodPart.visible = true;
    } else {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(위치)
   */
  public algorithm_typeByPosition(): void {
    if (this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      (<WindbreakLevelIL>this.level).wingPosition.type = this.type;
      (<WindbreakLevelIL>this.level).roofPosition.type = this.type;
    } else if (this.label === CONST.LB_POSITION_FRONT) {
      (<WindbreakLevelIL>this.level).backPosition.type = this.type;
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(1중)
   */
  public algorithm_typeByLevel1(): void {
    if (this.level.index === 0) {
      let l: number;
      if (this.label === CONST.LB_POSITION_SIDE_COLUMN) {
        for (l = 1; l < this.struct.windbreakWorkIL.levelAC.length; l++) {
          (<WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[l]).columnPosition.type = this.type;
        }
      } else if (this.label === CONST.LB_POSITION_FRONT) {
        for (l = 1; l < this.struct.windbreakWorkIL.levelAC.length; l++) {
          (<WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[l]).frontPosition.type = this.type;
        }
      }
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태(피복 고정)
   */
  public algorithm_fixingTypeByFixing(): void {
    const fixingPosition: FixingPositionIL = <FixingPositionIL>(
      this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
    );

    if (
      fixingPosition.type === CONST.LB_FIXING_TYPE_NORMAL_PAD ||
      fixingPosition.type === CONST.LB_FIXING_TYPE_LAGGING_PAD ||
      fixingPosition.type === CONST.LB_FIXING_TYPE_WOOD ||
      fixingPosition.type === CONST.LB_FIXING_TYPE_NORMAL_CLIP
    ) {
      this.fixingType = fixingPosition.type;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
