import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { ItemSupportPipe } from "vhows-design/src/object/design/item/list/ItemSupportPipe";
import { ColumnPartIL_CylinderMiddle } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderMiddle";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-15
 */
@jsonObject
export class ColumnSampleIL_CylinderMiddleSupWorkedPipe extends ItemSupportPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const cylinderMiddlePart: ColumnPartIL_CylinderMiddle = <ColumnPartIL_CylinderMiddle>this.part;
    cylinderMiddlePart.algorithmSpec_middleSupportWorked_middleSupportPipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
