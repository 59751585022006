import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { ColumnSampleIL_PrismColumnPipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_PrismColumnPipe";
import { ColumnSampleIL_PrismAssistColumnPipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_PrismAssistColumnPipe";
import { ColumnSampleIL_PrismColumnBracingPipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_PrismColumnBracingPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-14
 */
@jsonObject({
  knownTypes: [
    ColumnSampleIL_PrismColumnPipe,
    ColumnSampleIL_PrismAssistColumnPipe,
    ColumnSampleIL_PrismColumnBracingPipe,
  ],
})
export class ColumnPartIL_PrismColumn extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public columnPipeSample: ColumnSampleIL_PrismColumnPipe; // 기둥 파이프 샘플
  public assistColumnPipeSample: ColumnSampleIL_PrismAssistColumnPipe; // 달대(보조 기둥) 파이프 샘플
  public columnBracingPipeSample: ColumnSampleIL_PrismColumnBracingPipe; // 기둥 브레싱 파이프 샘플

  // 아이템
  public column_pipe: ItemPipe;
  public assistColumn_pipe: ItemPipe;
  public assistColumnAndBeam_tClamp: ItemHolder;
  public assistColumnAndBeam_screw: ItemScrew;
  public columnBracing_pipe: ItemPipe;
  public columnBracingAndColumn_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _columnLength: number; // 기둥 길이
  @jsonMember(Number)
  public _columnInterval: number; // 기둥 간격
  @jsonMember(Number)
  public _columnQuantity: number; // 기둥 수량

  @jsonMember(Boolean)
  public _secondEndpieceColumnSelected: boolean; // 2중 마구리 샛기둥 선택
  @jsonMember(Boolean)
  public _sideEnhancedColumnSelected: boolean; // 양측면 강화 샛기둥 선택

  @jsonMember(Boolean)
  public _assistColumnSelected: boolean; // 달대 선택
  @jsonMember(Number)
  public _assistColumnLength: number; // 달대 길이
  @jsonMember(Number)
  public _assistColumnQuantity: number; // 달대 수량

  @jsonMember(Number)
  public _columnBracingQuantity: number; // 기둥 브레싱 수량
  @jsonMember(Number)
  public _columnBracingTotalQuantity: number; // 기둥 브레싱 전체 수량
  @jsonMember(Boolean)
  public _centerColumnBracingSelected: boolean; // 중앙동 기둥 브레싱 선택

  /**
   * 기둥 길이
   */
  public get columnLength(): number {
    return this._columnLength;
  }

  //
  public set columnLength(value: number) {
    this._columnLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.columnPipeSample.algorithmSpec();
    this.algorithmPart();

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();

    // 밴드끈
    if (this.level.index === 0) {
      this.struct.bandstringWorkIL.level1.trunkPosition.bandstringPart.algorithm_totalBandstringLength();
      this.struct.bandstringWorkIL.level1.trunkPosition.polyPart.algorithm_totalBandstringLength();
    }

    // 커튼 개폐
    for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
      curSwitchLevel.columnPosition.linePart.algorithmPart();
      curSwitchLevel.ceilingPosition.linePart.algorithmPart();
    }
  }

  /**
   * 기둥 간격
   */
  public get columnInterval(): number {
    return this._columnInterval;
  }

  //
  public set columnInterval(value: number) {
    this._columnInterval = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnQuantity();
    this.algorithm_assistColumnSelected();
    this.algorithm_assistColumnQuantity();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionIL>this.position).prismMiddlePart.algorithm_middleLineNumber();
    (<ColumnPositionIL>this.position).sideStraightPart.algorithmPart();

    /// //////// 외부 ///////////

    // 지붕 골조
    for (const roofLevel of this.struct.roofWorkIL.levelAC as RoofLevelIL[]) {
      roofLevel.roofPosition.rafterPart.algorithm_rafterInterval();
    }

    // 물받이
    for (const gutterLevel of this.struct.gutterWorkIL.levelAC as GutterLevelIL[]) {
      gutterLevel.gutterPosition.ironPlatePart.algorithmPart();
      gutterLevel.gutterPosition.plasticPart.algorithmPart();
      gutterLevel.gutterPosition.weavingFilmPart.algorithmPart();
    }

    // 치마
    (<SkirtLevelIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index]
    )).columnPosition.fixingSkirtClipPart.algorithm_clipInterval();

    // 커튼 개폐
    for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
      curSwitchLevel.columnPosition.axisPart.algorithmPart();
      curSwitchLevel.columnPosition.linePart.algorithmPart();
      curSwitchLevel.columnPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.frontPosition.axisPart.algorithmPart();
      curSwitchLevel.frontPosition.linePart.algorithmPart();
      curSwitchLevel.frontPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.backPosition.axisPart.algorithmPart();
      curSwitchLevel.backPosition.linePart.algorithmPart();
      curSwitchLevel.backPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.ceilingPosition.framePart.algorithmPart();
      curSwitchLevel.ceilingPosition.linePart.algorithmPart();
      curSwitchLevel.ceilingPosition.fixingClipCeilingPart.algorithmPart();
    }
    (<CurSwitchLevelIL>(
      this.struct.curSwitchWorkIL.levelAC[this.level.index]
    )).ceilingPosition.linePart.algorithm_towLineNumberAndSupportLineNumber();

    // 양액시스템
    if (this.level.index === 0) {
      this.struct.hydCultivationWork.level1.wholePosition.bedPart.algorithm_hallwayWidth();
    }
    // 운반레일
    if (this.level.index === 0) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithm_railHookInterval();
      this.struct.railWork.level1.wholePosition.sideRailPart.algorithm_railHookInterval();
    }
  }

  /**
   * 기둥 수량
   */
  public get columnQuantity(): number {
    return this._columnQuantity;
  }

  //
  public set columnQuantity(value: number) {
    this._columnQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionIL>this.position).prismBeamPart.algorithmPart();
    (<ColumnPositionIL>this.position).prismMiddlePart.algorithmPart();

    /// //////// 외부 ///////////

    // 바닥 골조
    this.struct.foundationWorkIL.level1.foundationPosition.girthPipePart.algorithmPart();
    this.struct.foundationWorkIL.level1.foundationPosition.columnConcretePart.algorithm_moldQuantity();
  }

  /**
   * 2중 마구리 샛기둥 선택
   */
  public get secondEndpieceColumnSelected(): boolean {
    return this._secondEndpieceColumnSelected;
  }

  //
  public set secondEndpieceColumnSelected(value: boolean) {
    this._secondEndpieceColumnSelected = value;

    // 알고리즘
    this.algorithm_columnQuantity();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionIL>this.position).prismMiddlePart.algorithm_middleLineNumber();
  }

  /**
   * 양측면 강화 샛기둥 선택
   */
  public get sideEnhancedColumnSelected(): boolean {
    return this._sideEnhancedColumnSelected;
  }

  //
  public set sideEnhancedColumnSelected(value: boolean) {
    this._sideEnhancedColumnSelected = value;

    // 알고리즘
    this.algorithm_columnQuantity();
    this.algorithm_assistColumnQuantity();
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionIL>this.position).sideStraightPart.algorithmPart();
  }

  /**
   * 달대 선택
   */
  public get assistColumnSelected(): boolean {
    return this._assistColumnSelected;
  }

  //
  public set assistColumnSelected(value: boolean) {
    this._assistColumnSelected = value;

    // 알고리즘
    this.algorithmPart();

    // 다른 파트
    (<ColumnPositionIL>this.position).sideStraightPart.algorithmPart();

    /// //////// 외부 ///////////

    // 지붕 골조
    for (const roofLevel of this.struct.roofWorkIL.levelAC as RoofLevelIL[]) {
      roofLevel.roofPosition.rafterPart.algorithm_rafterInterval();
    }
  }

  /**
   * 달대 길이
   */
  public get assistColumnLength(): number {
    return this._assistColumnLength;
  }

  //
  public set assistColumnLength(value: number) {
    this._assistColumnLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 달대 수량
   */
  public get assistColumnQuantity(): number {
    return this._assistColumnQuantity;
  }

  //
  public set assistColumnQuantity(value: number) {
    this._assistColumnQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기둥 브레싱 수량
   */
  public get columnBracingQuantity(): number {
    return this._columnBracingQuantity;
  }

  public set columnBracingQuantity(value: number) {
    this._columnBracingQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithm_columnBracingTotalQuantity();
    this.algorithmPart();
  }

  /**
   * 기둥 브레싱 전체 수량
   */
  public get columnBracingTotalQuantity(): number {
    return this._columnBracingTotalQuantity;
  }

  public set columnBracingTotalQuantity(value: number) {
    this._columnBracingTotalQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중앙동 기둥 브레싱 선택
   */
  public get centerColumnBracingSelected(): boolean {
    return this._centerColumnBracingSelected;
  }

  //
  public set centerColumnBracingSelected(value: boolean) {
    this._centerColumnBracingSelected = value;

    // 알고리즘
    this.algorithm_columnBracingTotalQuantity();
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.columnPipeSample = new ColumnSampleIL_PrismColumnPipe();
    this.assistColumnPipeSample = new ColumnSampleIL_PrismAssistColumnPipe();
    this.columnBracingPipeSample = new ColumnSampleIL_PrismColumnBracingPipe();

    this.sampleAC = [this.columnPipeSample, this.assistColumnPipeSample, this.columnBracingPipeSample];

    // 아이템
    this.column_pipe = new ItemPipe();
    this.assistColumn_pipe = new ItemPipe();
    this.assistColumnAndBeam_tClamp = new ItemHolder();
    this.assistColumnAndBeam_screw = new ItemScrew();
    this.columnBracing_pipe = new ItemPipe();
    this.columnBracingAndColumn_welding = new ItemOtherExpense();

    this.itemAC = [
      this.column_pipe,
      this.assistColumn_pipe,
      this.assistColumnAndBeam_tClamp,
      this.assistColumnAndBeam_screw,
      this.columnBracing_pipe,
      this.columnBracingAndColumn_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.columnPipeSample = <ColumnSampleIL_PrismColumnPipe>this.sampleAC[0];
    this.assistColumnPipeSample = <ColumnSampleIL_PrismAssistColumnPipe>this.sampleAC[1];
    this.columnBracingPipeSample = <ColumnSampleIL_PrismColumnBracingPipe>this.sampleAC[2];

    // 아이템
    this.column_pipe = <ItemPipe>this.itemAC[0];
    this.assistColumn_pipe = <ItemPipe>this.itemAC[1];
    this.assistColumnAndBeam_tClamp = <ItemHolder>this.itemAC[2];
    this.assistColumnAndBeam_screw = <ItemScrew>this.itemAC[3];
    this.columnBracing_pipe = <ItemPipe>this.itemAC[4];
    this.columnBracingAndColumn_welding = <ItemOtherExpense>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param columnLength: number 기둥 길이
   * @param columnInterval: number 기둥 간격
   * @param secondEndpieceColumnSelected: boolean 2중 마구리 샛기둥 선택
   * @param assistColumnSelected: boolean 달대 선택
   * @param assistColumnLength: number 달대 길이
   * @param columnBracingQuantity: number 기둥 브레싱 수량
   * @param centerColumnBracingSelected: boolean 중앙동 기둥 브레싱 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    columnLength: number = 0,
    columnInterval: number = 0,
    secondEndpieceColumnSelected: boolean = false,
    assistColumnSelected: boolean = false,
    assistColumnLength: number = 0,
    columnBracingQuantity: number = 0,
    centerColumnBracingSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._columnLength = columnLength;
    this._columnInterval = columnInterval;
    this._secondEndpieceColumnSelected = secondEndpieceColumnSelected;
    this._assistColumnSelected = assistColumnSelected;
    this._assistColumnLength = assistColumnLength;
    this._columnBracingQuantity = columnBracingQuantity;
    this._centerColumnBracingSelected = centerColumnBracingSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.columnPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥",
        "각형, 일반, 60×60mm, 2.1T, 3.5m, -",
      );
      this.assistColumnPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "달대",
        "각형, 일반, 60×60mm, 2.1T, 6m, -",
      );
      this.columnBracingPipeSample.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 브레싱",
        "각형, 일반, 60×60mm, 2.1T, 6m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.column_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥",
        "각형, 일반, 60×60mm, 2.1T, 3.5m, -",
      );
      this.assistColumn_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "달대",
        "각형, 일반, 60×60mm, 2.1T, 6m, -",
      );
      this.assistColumnAndBeam_tClamp.setDefaultData(
        2,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "달대 + 보",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.assistColumnAndBeam_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "달대 + 보",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.columnBracing_pipe.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "기둥 브레싱",
        "각형, 일반, 60×60mm, 2.1T, 6m, -",
      );
      this.columnBracingAndColumn_welding.setDefaultData(
        5,
        CONST.ITEM_ID_WELDING,
        CONST.ITEM_NAME_WELDING,
        "기둥 브레싱 + 기둥",
        "일반",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_columnLength();
    this.algorithm_secondEndpieceColumnSelected();
    this.algorithm_columnQuantity();
    this.algorithm_assistColumnSelected();
    this.algorithm_assistColumnLength();
    this.algorithm_assistColumnQuantity();
    this.algorithm_columnBracingTotalQuantity();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.column_pipe.selected = true;
    this.column_pipe.visible = true;
    if (this.assistColumnSelected === true) {
      this.assistColumn_pipe.selected = true;
      this.assistColumn_pipe.visible = true;
      this.assistColumnAndBeam_tClamp.selected = true;
      this.assistColumnAndBeam_tClamp.visible = true;
      this.assistColumnAndBeam_screw.selected = true;
      this.assistColumnAndBeam_screw.visible = true;
    } else {
      this.assistColumn_pipe.selected = false;
      this.assistColumn_pipe.visible = false;
      this.assistColumnAndBeam_tClamp.selected = false;
      this.assistColumnAndBeam_tClamp.visible = false;
      this.assistColumnAndBeam_screw.selected = false;
      this.assistColumnAndBeam_screw.visible = false;
    }
    this.columnBracing_pipe.selected = true;
    this.columnBracing_pipe.visible = true;
    this.columnBracingAndColumn_welding.selected = true;
    this.columnBracingAndColumn_welding.visible = true;

    /// //////// 수량 ///////////

    if (this.column_pipe.specLength > this.columnLength) {
      this.column_pipe.designQuantity =
        this.columnQuantity / Math.floor(this.column_pipe.specLength / this.columnLength);
    } else {
      this.column_pipe.designQuantity = this.columnQuantity;
    }

    if (this.assistColumn_pipe.specLength > this.assistColumnLength) {
      this.assistColumn_pipe.designQuantity =
        this.assistColumnQuantity / Math.floor(this.assistColumn_pipe.specLength / this.assistColumnLength);
    } else {
      this.assistColumn_pipe.designQuantity = this.assistColumnQuantity;
    }

    this.assistColumnAndBeam_tClamp.designQuantity = this.assistColumnQuantity * CONST.NUM_EXTRA_RATE_T_CLAMP;

    this.assistColumnAndBeam_screw.designQuantity =
      ((this.assistColumnAndBeam_tClamp.getSelectedQuantity() * 4) / this.assistColumnAndBeam_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.columnBracing_pipe.designQuantity = this.columnBracingTotalQuantity;

    this.columnBracingAndColumn_welding.designQuantity = this.columnBracingTotalQuantity * 3;
  }

  /**
   * 알고리즘: 기둥 길이 <- 측고(기본정보), 매설(기본정보)
   */
  public algorithm_columnLength(): void {
    this.columnLength = this.basicLevel.heightShoulder + this.basicLevel.depthLaying;
  }

  /**
   * 알고리즘: 2중 마구리 샛기둥 선택 <- 중수(기본정보), 선택(마구리 골조)
   */
  public algorithm_secondEndpieceColumnSelected(): void {
    if (
      this.basic.levelNumber >= 2 &&
      (this.struct.endpieceWorkIL.level2.frontPosition.selected === true ||
        this.struct.endpieceWorkIL.level2.backPosition.selected === true)
    ) {
      this.secondEndpieceColumnSelected = true;
    } else {
      this.secondEndpieceColumnSelected = false;
    }
  }

  /**
   * 알고리즘: 기둥 수량 <- 기둥 간격, 2중 마구리 샛기둥 선택, 측면 강화 샛기둥 선택, 길이(기본정보), 동수(기본정보)
   */
  public algorithm_columnQuantity(): void {
    let columnQuantityTemp: number =
      (Math.floor(this.basicLevel.length / this.columnInterval) + 1) *
      (this.basic.buildingNumber + 1) *
      CONST.NUM_EXTRA_RATE_COLUMN_PIPE;

    if (this.secondEndpieceColumnSelected === true) {
      columnQuantityTemp += 2 * (this.basic.buildingNumber + 1);
    }
    if (this.sideEnhancedColumnSelected === true) {
      columnQuantityTemp += Math.floor(this.basicLevel.length / this.columnInterval) * 2;
      if (this.secondEndpieceColumnSelected === true) {
        columnQuantityTemp -= 4;
      }
    }

    this.columnQuantity = columnQuantityTemp;
  }

  /**
   * 알고리즘: 달대 선택 <- 기둥 간격, 중수(기본정보)
   */
  public algorithm_assistColumnSelected(): void {
    if (this.basic.levelNumber >= 2 && this.columnInterval >= 3) {
      this.assistColumnSelected = true;
    } else {
      this.assistColumnSelected = false;
    }
  }

  /**
   * 알고리즘: 달대 길이 <- 중수(기본정보)
   */
  public algorithm_assistColumnLength(): void {
    if (this.basic.levelNumber >= 2) {
      this.assistColumnLength = 0.4 * (this.basic.levelNumber - 1);
    }
  }

  /**
   * 알고리즘: 달대 수량 <- 기둥 간격, 측면 강화 샛기둥 선택, 길이(기본정보), 동수(기본정보)
   */
  public algorithm_assistColumnQuantity(): void {
    let assistColumnQuantityTemp: number =
      Math.floor(this.basicLevel.length / this.columnInterval) *
      (this.basic.buildingNumber + 1) *
      CONST.NUM_EXTRA_RATE_COLUMN_PIPE;

    if (this.sideEnhancedColumnSelected === true) {
      assistColumnQuantityTemp -= Math.floor(this.basicLevel.length / this.columnInterval) * 2;
    }

    this.assistColumnQuantity = assistColumnQuantityTemp;
  }

  /**
   * 알고리즘: 기둥 브레싱 전체 수량 <- 기둥 브레싱 수량, 동수(기본정보), 중앙동 기둥 브레싱 선택
   */
  public algorithm_columnBracingTotalQuantity(): void {
    // 브레싱 기둥 줄수 계산
    let bracingColumn: number;
    if (this.centerColumnBracingSelected === true) {
      bracingColumn = this.basic.buildingNumber + 1;
    } else {
      bracingColumn = 2;
    }

    this.columnBracingTotalQuantity = this.columnBracingQuantity * bracingColumn;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [기둥] 파이프 <- 기둥 파이프 샘플
   */
  public algorithmSpec_column_pipe(): void {
    this.column_pipe.specs = this.columnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [달대] 파이프 <- 달대 파이프 샘플
   */
  public algorithmSpec_assistColumn_pipe(): void {
    this.assistColumn_pipe.specs = this.assistColumnPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [달대 + 보] T 클램프 <- 달대 파이프 샘플, 보 파이프 샘플
   */
  public algorithmSpec_assistColumnAndBeam_tClamp(): void {
    this.assistColumnAndBeam_tClamp.specPipeType = this.assistColumnPipeSample.specPipeType;
    this.assistColumnAndBeam_tClamp.specCrossSize1 = this.assistColumnPipeSample.specCrossSize;
    this.assistColumnAndBeam_tClamp.specCrossSize2 = (<ColumnPositionIL>(
      this.position
    )).prismBeamPart.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [달대 + 보] 나사 <- 공통 샘플
   */
  public algorithmSpec_assistColumnAndBeam_screw(): void {}

  /**
   * 규격 알고리즘: [기둥 브레싱] 파이프 <- 기둥 브레싱 파이프 샘플
   */
  public algorithmSpec_columnBracing_pipe(): void {
    this.columnBracing_pipe.specs = this.columnBracingPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [기둥 브레싱 + 기둥] 용접 <- 공통 샘플
   */
  public algorithmSpec_columnBracingAndColumn_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
