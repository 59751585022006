import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { ColumnSampleIL_PrismBeamPipe } from "vhows-design/src/object/design/frame/column/ColumnSampleIL_PrismBeamPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-14
 */
@jsonObject({
  knownTypes: [ColumnSampleIL_PrismBeamPipe],
})
export class ColumnPartIL_PrismBeam extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public beamPipeSample: ColumnSampleIL_PrismBeamPipe; // 보 파이프 샘플

  // 아이템
  public beam_pipe: ItemPipe;
  public beamAndBeam_connectionPin: ItemConnector;
  public beamAndBeam_screw: ItemScrew;
  public beamAndColumn_tClamp: ItemHolder;
  public beamAndColumn_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _beamLength: number; // 보 길이
  @jsonMember(Number)
  public _beamLineNumber: number; // 보 줄수

  /**
   * 보 길이
   */
  public get beamLength(): number {
    return this._beamLength;
  }

  //
  public set beamLength(value: number) {
    this._beamLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 보 줄수
   */
  public get beamLineNumber(): number {
    return this._beamLineNumber;
  }

  //
  public set beamLineNumber(value: number) {
    this._beamLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.beamPipeSample = new ColumnSampleIL_PrismBeamPipe();

    this.sampleAC = [this.beamPipeSample];

    // 아이템
    this.beam_pipe = new ItemPipe();
    this.beamAndBeam_connectionPin = new ItemConnector();
    this.beamAndBeam_screw = new ItemScrew();
    this.beamAndColumn_tClamp = new ItemHolder();
    this.beamAndColumn_screw = new ItemScrew();

    this.itemAC = [
      this.beam_pipe,
      this.beamAndBeam_connectionPin,
      this.beamAndBeam_screw,
      this.beamAndColumn_tClamp,
      this.beamAndColumn_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.beamPipeSample = <ColumnSampleIL_PrismBeamPipe>this.sampleAC[0];

    // 아이템
    this.beam_pipe = <ItemPipe>this.itemAC[0];
    this.beamAndBeam_connectionPin = <ItemConnector>this.itemAC[1];
    this.beamAndBeam_screw = <ItemScrew>this.itemAC[2];
    this.beamAndColumn_tClamp = <ItemHolder>this.itemAC[3];
    this.beamAndColumn_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.beamPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "보",
        "각형, 일반, 60×60mm, 2.1T, 6m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.beam_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "보",
        "각형, 일반, 60×60mm, 2.1T, 6m, -",
      );
      this.beamAndBeam_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "보 + 보",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.beamAndBeam_screw.setDefaultData(
        2,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "보 + 보",
        "육각, 일반, 14*25mm, 500개",
      );
      this.beamAndColumn_tClamp.setDefaultData(
        3,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "보 + 기둥",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.beamAndColumn_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "보 + 기둥",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_beamLength();
    this.algorithm_rafterInterval();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const columnPosition: ColumnPositionIL = <ColumnPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.beam_pipe.selected = true;
    this.beam_pipe.visible = true;
    this.beamAndBeam_connectionPin.selected = true;
    this.beamAndBeam_connectionPin.visible = true;
    this.beamAndBeam_screw.selected = true;
    this.beamAndBeam_screw.visible = true;
    this.beamAndColumn_tClamp.selected = true;
    this.beamAndColumn_tClamp.visible = true;
    this.beamAndColumn_screw.selected = true;
    this.beamAndColumn_screw.visible = true;

    /// //////// 수량 ///////////

    this.beam_pipe.designQuantity =
      (this.beam_pipe.getConnectedPipeLength(this.beamLength, this.beamLineNumber) / this.beam_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_BEAM_PIPE;

    if (this.beamLength > this.beam_pipe.specLength) {
      this.beamAndBeam_connectionPin.designQuantity =
        this.beam_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.beamAndBeam_connectionPin.designQuantity = 0;
    }

    this.beamAndBeam_screw.designQuantity =
      ((this.beamAndBeam_connectionPin.getSelectedQuantity() * 4) / this.beamAndBeam_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    this.beamAndColumn_tClamp.designQuantity =
      columnPosition.prismColumnPart.columnQuantity * CONST.NUM_EXTRA_RATE_T_CLAMP;

    this.beamAndColumn_screw.designQuantity =
      ((this.beamAndColumn_tClamp.getSelectedQuantity() * 4) / this.beamAndBeam_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 보 길이 <- 길이(기본정보), 앞면/뒷면 처마 길이(지붕 골조/1중)
   */
  public algorithm_beamLength(): void {
    if (this.level.index === 0) {
      this.beamLength =
        this.basicLevel.length +
        this.struct.roofWorkIL.level1.roofPosition.frontEaveLength +
        this.struct.roofWorkIL.level1.roofPosition.backEaveLength +
        this.design.preference.connectedPipeExtraLength;
    } else {
      this.beamLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
    }
  }

  /**
   * 알고리즘: 보 줄수 <- 동수(기본정보)
   */
  public algorithm_rafterInterval(): void {
    this.beamLineNumber = this.basic.buildingNumber + 1;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [보] 파이프 <- 보 파이프 샘플
   */
  public algorithmSpec_beam_pipe(): void {
    this.beam_pipe.specs = this.beamPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [보 + 보] 연결핀 <- 보 파이프 샘플
   */
  public algorithmSpec_beamAndBeam_connectionPin(): void {
    this.beamAndBeam_connectionPin.specPipeType = this.beamPipeSample.specPipeType;
    this.beamAndBeam_connectionPin.specCrossSize1 = this.beamPipeSample.specCrossSize;
    this.beamAndBeam_connectionPin.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 보] 나사 <- 공통 샘플
   */
  public algorithmSpec_beamAndBeam_screw(): void {}

  /**
   * 규격 알고리즘: [보 + 기둥] T 클램프 <- 보 파이프 샘플, 기둥 파이프 샘플
   */
  public algorithmSpec_beamAndColumn_tClamp(): void {
    this.beamAndColumn_tClamp.specPipeType = (<ColumnPositionIL>(
      this.position
    )).prismColumnPart.columnPipeSample.specPipeType;
    this.beamAndColumn_tClamp.specCrossSize1 = (<ColumnPositionIL>(
      this.position
    )).prismColumnPart.columnPipeSample.specCrossSize;
    this.beamAndColumn_tClamp.specCrossSize2 = this.beamPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [보 + 기둥] 나사 <- 공통 샘플
   */
  public algorithmSpec_beamAndColumn_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
