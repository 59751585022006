import { jsonObject } from "typedjson";

import { ItemElectricWire } from "vhows-design/src/object/design/item/list/ItemElectricWire";
import { ControllerPart_Controller } from "vhows-design/src/object/design/switches/controller/ControllerPart_Controller";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-13
 */
@jsonObject
export class ControllerSample_IncomingElectricWire extends ItemElectricWire {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const controllerPart: ControllerPart_Controller = <ControllerPart_Controller>this.part;
    controllerPart.algorithmSpec_controllerIncoming_electricWire();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
