import { jsonObject } from "typedjson";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WingPartIL_WingStraight } from "vhows-design/src/object/design/frame/wing/WingPartIL_WingStraight";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject
export class WingSampleIL_WingStraightPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const wingStraightPart: WingPartIL_WingStraight = <WingPartIL_WingStraight>this.part;
    wingStraightPart.algorithmSpec_wingStraight_pipe();
    wingStraightPart.algorithmSpec_wingStraightAndWingStraight_connectionPin();
    wingStraightPart.algorithmSpec_wingStraightCenterAndWingRafter_steelStringGrabber();
    wingStraightPart.algorithmSpec_floorWingStraightCenterAndWingRafter_steelStringGrabber();
    wingStraightPart.algorithmSpec_wingStraightEdgeAndWingRafter_saddleHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const wingPosition: WingPositionIL = this.position as WingPositionIL;
    wingPosition.wingRafterPart.algorithmSpec_wingRafterSecondAndWingStraight_innerTHolder();

    /// //////// 외부 ///////////

    // 피복 고정
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];
    fixingLevel.wingPosition.normalClipPart.normalClipSample.algorithmSpec();

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    skirtLevel.wingPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
