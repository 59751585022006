import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemSteelWire } from "vhows-design/src/object/design/item/list/ItemSteelWire";
import { Part } from "vhows-design/src/object/design/base/Part";
import { BandstringPosition } from "vhows-design/src/object/design/cover/bandstring/BandstringPosition";
import { BandstringSample_Stake } from "vhows-design/src/object/design/cover/bandstring/BandstringSample_Stake";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-06-25
 */
@jsonObject({
  knownTypes: [BandstringSample_Stake],
})
export class BandstringPart_FixingStake extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public stakeSample: BandstringSample_Stake; // 말뚝 샘플

  // 아이템
  public stake_stake: ItemFrameOther;
  public stake_steelStringWire: ItemSteelWire;
  public stake_pipe: ItemPipe;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Boolean)
  public _stakeWireSelected: boolean; // 말뚝+강선 선택

  /**
   * 말뚝+강선 선택
   */
  public get stakeWireSelected(): boolean {
    return this._stakeWireSelected;
  }

  //
  public set stakeWireSelected(value: boolean) {
    this._stakeWireSelected = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.stakeSample = new BandstringSample_Stake();

    this.sampleAC = [this.stakeSample];

    // 아이템
    this.stake_stake = new ItemFrameOther();
    this.stake_steelStringWire = new ItemSteelWire();
    this.stake_pipe = new ItemPipe();

    this.itemAC = [this.stake_stake, this.stake_steelStringWire, this.stake_pipe];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.stakeSample = this.sampleAC[0] as BandstringSample_Stake;

    // 아이템
    this.stake_stake = this.itemAC[0] as ItemFrameOther;
    this.stake_steelStringWire = this.itemAC[1] as ItemSteelWire;
    this.stake_pipe = this.itemAC[2] as ItemPipe;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param stakeWireSelected: boolean 말뚝+강선 선택
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    stakeWireSelected: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._stakeWireSelected = stakeWireSelected;

    // 샘플
    if (this.level.index >= 0) {
      this.stakeSample.setDefaultData(0, CONST.ITEM_ID_STAKE, CONST.ITEM_NAME_STAKE, "말뚝 고정", "철제, 중");
    }

    // 아이템
    if (this.level.index >= 0) {
      this.stake_stake.setDefaultData(0, CONST.ITEM_ID_STAKE, CONST.ITEM_NAME_STAKE, "말뚝", "철제, 중");
      this.stake_steelStringWire.setDefaultData(
        1,
        CONST.ITEM_ID_STEEL_STRING_WIRE,
        CONST.ITEM_NAME_STEEL_STRING_WIRE,
        "말뚝+강선",
        "강선8번(50kg), 아연, 4mm, 490m",
      );
      this.stake_pipe.setDefaultData(
        2,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "말뚝+강선",
        "원형, 일반, 48.1mm, 2.1T, 8m, -",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const bandstringPosition: BandstringPosition = this.position as BandstringPosition;

    /// //////// 선택, 가시성 ///////////

    if (bandstringPosition.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_STAKE) {
      this.stake_stake.selected = true;
      this.stake_stake.visible = true;
      this.stake_steelStringWire.selected = false;
      this.stake_steelStringWire.visible = false;
      this.stake_pipe.selected = false;
      this.stake_pipe.visible = false;
    } else if (bandstringPosition.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_STAKE_WIRE) {
      this.stake_stake.selected = true;
      this.stake_stake.visible = true;
      this.stake_steelStringWire.selected = true;
      this.stake_steelStringWire.visible = true;
      this.stake_pipe.selected = true;
      this.stake_pipe.visible = true;
    }

    /// //////// 수량  ///////////

    this.stake_stake.designQuantity =
      (Math.ceil(this.basicLevel.length / bandstringPosition.lineInterval) + 1) * 2 * this.basic.buildingNumber;

    this.stake_steelStringWire.designQuantity =
      (2 * this.basic.buildingNumber) /
      CommonUtil.roundDown(this.stake_steelStringWire.specLength / this.basicLevel.length);

    this.stake_pipe.designQuantity = (1 * 4 * this.basic.buildingNumber) / this.stake_pipe.specLength;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [말뚝] 말뚝 <- 말뚝 샘플
   */
  public algorithmSpec_stake_stake(): void {
    this.stake_stake.specs = this.stakeSample.specs;
  }

  /**
   * 규격 알고리즘: [말뚝+강선] 강선줄 <- 공통 샘플
   */
  public algorithmSpec_stake_steelStringWire(): void {}

  /**
   * 규격 알고리즘: [말뚝+강선] 파이프 <- 고정
   */
  public algorithmSpec_stake_pipe(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
