import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";
import { ScreennetPositionIL } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { WindbreakPositionIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPositionIL";
import { FixingPartIL_LaggingPad } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_LaggingPad";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { WindbreakPartIL_FixingLaggingPad } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPartIL_FixingLaggingPad";
import { SkirtPartIL_FixingLaggingPad } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingLaggingPad";
import { ScreennetPartIL_FixingLaggingPad } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPartIL_FixingLaggingPad";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject
export class FixingSampleIL_LaggingPad extends ItemPad {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const laggingPadPart: FixingPartIL_LaggingPad = <FixingPartIL_LaggingPad>this.part;
    laggingPadPart.algorithmSpec_laggingPad_laggingPad();
    laggingPadPart.algorithmSpec_laggingPad_padConnectionPin();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<FixingPositionIL>this.level.positionAC[p]).laggingPadPart.laggingPadSample.specs = this.specs;
    }

    // 다른 중수
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        // 해당 중수의 측면 위치만 바꿔줘도 전체 위치의 규격이 바뀌게됨
        (<FixingLevelIL>this.work.levelAC[l]).columnPosition.laggingPadPart.laggingPadSample.specs = this.specs;
      }
    }

    /// //////// 외부 ///////////

    // 개폐기
    // const switcherPart_FixingLaggingPad: SwitcherPartIL_FixingLaggingPad = (<SwitcherPositionIL>this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]).fixingLaggingPadPart;
    // switcherPart_FixingLaggingPad.laggingPadSample.algorithmSpec();

    // 치마 피복
    const skirtPart_FixingLaggingPad: SkirtPartIL_FixingLaggingPad = (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingLaggingPadPart;
    skirtPart_FixingLaggingPad.laggingPadSample.algorithmSpec();

    // 바람막이 피복
    const windbreakPart_FixingLaggingPad: WindbreakPartIL_FixingLaggingPad = (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingLaggingPadPart;
    windbreakPart_FixingLaggingPad.laggingPadSample.algorithmSpec();

    // 방충망
    if (this.level.index === 0) {
      const screennetPart_FixingLaggingPad: ScreennetPartIL_FixingLaggingPad = (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.levelAC[this.level.index].positionAC[this.position.index]
      )).fixingLaggingPadPart;
      screennetPart_FixingLaggingPad.laggingPadSample.algorithmSpec();
    }

    // 창문
    if (this.level.index === 0) {
      const windowLevel: WindowLevelIL = <WindowLevelIL>this.struct.windowWorkIL.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        windowLevel.leftPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
        windowLevel.rightPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        windowLevel.frontPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        windowLevel.backPosition.fixingLaggingPadPart.laggingPadSample.algorithmSpec();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
