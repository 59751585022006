import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { EndpieceSampleIL_PrismMiddleUpperPipe } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceSampleIL_PrismMiddleUpperPipe";
import { EndpieceSampleIL_PrismMiddleBottomPipe } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceSampleIL_PrismMiddleBottomPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-01-10
 */
@jsonObject({
  knownTypes: [EndpieceSampleIL_PrismMiddleUpperPipe, EndpieceSampleIL_PrismMiddleBottomPipe],
})
export class EndpiecePartIL_PrismMiddle extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public middleUpperPipeSample: EndpieceSampleIL_PrismMiddleUpperPipe; // 마구리 중방(상단) 파이프 샘플
  public middleBottomPipeSample: EndpieceSampleIL_PrismMiddleBottomPipe; // 마구리 중방(하단) 파이프 샘플

  // 아이템
  public middleUpper_pipe: ItemPipe;
  public middleUpperAndRafterEndpiece_normalHolder: ItemHolder;
  public middleUpperAndRafterRafter_normalHolder: ItemHolder;
  public middleUpperAndRafter_boltAndNut: ItemFrameOther;
  public middleUpperAndEndpieceStudUpper_saddleHolder: ItemHolder;
  public middleBottom_pipe: ItemPipe;
  public middleBottomAndMiddleBottom_connectionPin: ItemConnector;
  public middleBottomAndColumnEdge_tClamp: ItemHolder;
  public middleBottomAndColumnCenter_crossClamp: ItemHolder;
  public middleBottomAndEndpieceStudBottom_crossClamp: ItemHolder;
  public middle_screw: ItemScrew;
  public middle_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _middleLength: number; // 중방 길이
  @jsonMember(Number)
  public _middleUpperLineNumber: number; // 중방 상단 줄수
  @jsonMember(Number)
  public _middleBottomLineNumber: number; // 중방 하단 줄수

  /**
   * 중방 길이
   */
  public get middleLength(): number {
    return this._middleLength;
  }

  //
  public set middleLength(value: number) {
    this._middleLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 중방 상단 줄수
   */
  public get middleUpperLineNumber(): number {
    return this._middleUpperLineNumber;
  }

  //
  public set middleUpperLineNumber(value: number) {
    this._middleUpperLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 위치
    this.algorithm_middleUpperLineNumberByPosition();
  }

  /**
   * 중방 하단 줄수
   */
  public get middleBottomLineNumber(): number {
    return this._middleBottomLineNumber;
  }

  //
  public set middleBottomLineNumber(value: number) {
    this._middleBottomLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();

    // 다른 위치
    this.algorithm_middleBottomLineNumberByPosition();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.middleUpperPipeSample = new EndpieceSampleIL_PrismMiddleUpperPipe();
    this.middleBottomPipeSample = new EndpieceSampleIL_PrismMiddleBottomPipe();

    this.sampleAC = [this.middleUpperPipeSample, this.middleBottomPipeSample];

    // 아이템
    this.middleUpper_pipe = new ItemPipe();
    this.middleUpperAndRafterEndpiece_normalHolder = new ItemHolder();
    this.middleUpperAndRafterRafter_normalHolder = new ItemHolder();
    this.middleUpperAndRafter_boltAndNut = new ItemFrameOther();
    this.middleUpperAndEndpieceStudUpper_saddleHolder = new ItemHolder();
    this.middleBottom_pipe = new ItemPipe();
    this.middleBottomAndMiddleBottom_connectionPin = new ItemConnector();
    this.middleBottomAndColumnEdge_tClamp = new ItemHolder();
    this.middleBottomAndColumnCenter_crossClamp = new ItemHolder();
    this.middleBottomAndEndpieceStudBottom_crossClamp = new ItemHolder();
    this.middle_screw = new ItemScrew();
    this.middle_welding = new ItemOtherExpense();

    this.itemAC = [
      this.middleUpper_pipe,
      this.middleUpperAndRafterEndpiece_normalHolder,
      this.middleUpperAndRafterRafter_normalHolder,
      this.middleUpperAndRafter_boltAndNut,
      this.middleUpperAndEndpieceStudUpper_saddleHolder,
      this.middleBottom_pipe,
      this.middleBottomAndMiddleBottom_connectionPin,
      this.middleBottomAndColumnEdge_tClamp,
      this.middleBottomAndColumnCenter_crossClamp,
      this.middleBottomAndEndpieceStudBottom_crossClamp,
      this.middle_screw,
      this.middle_welding,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.middleUpperPipeSample = <EndpieceSampleIL_PrismMiddleUpperPipe>this.sampleAC[0];
    this.middleBottomPipeSample = <EndpieceSampleIL_PrismMiddleBottomPipe>this.sampleAC[1];

    // 아이템
    this.middleUpper_pipe = <ItemPipe>this.itemAC[0];
    this.middleUpperAndRafterEndpiece_normalHolder = <ItemHolder>this.itemAC[1];
    this.middleUpperAndRafterRafter_normalHolder = <ItemHolder>this.itemAC[2];
    this.middleUpperAndRafter_boltAndNut = <ItemFrameOther>this.itemAC[3];
    this.middleUpperAndEndpieceStudUpper_saddleHolder = <ItemHolder>this.itemAC[4];
    this.middleBottom_pipe = <ItemPipe>this.itemAC[5];
    this.middleBottomAndMiddleBottom_connectionPin = <ItemConnector>this.itemAC[6];
    this.middleBottomAndColumnEdge_tClamp = <ItemHolder>this.itemAC[7];
    this.middleBottomAndColumnCenter_crossClamp = <ItemHolder>this.itemAC[8];
    this.middleBottomAndEndpieceStudBottom_crossClamp = <ItemHolder>this.itemAC[9];
    this.middle_screw = <ItemScrew>this.itemAC[10];
    this.middle_welding = <ItemOtherExpense>this.itemAC[11];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param middleUpperLineNumber: number 중방 상단 줄수
   * @param middleBottomLineNumber: number 중방 하단 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    middleUpperLineNumber: number = 0,
    middleBottomLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._middleUpperLineNumber = middleUpperLineNumber;
    this._middleBottomLineNumber = middleBottomLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.middleUpperPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.middleBottomPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(하단)",
        "각형, 일반, 60×60mm, 2.1T, 7m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.middleUpper_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(상단)",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.middleUpperAndRafterEndpiece_normalHolder.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 중방(상단) + 서까래 (1)",
        "원형, 일반, 32mm, -",
      );
      this.middleUpperAndRafterRafter_normalHolder.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "마구리 중방(상단) + 서까래 (2)",
        "원형, 일반, 32mm, -",
      );
      this.middleUpperAndRafter_boltAndNut.setDefaultData(
        3,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "마구리 중방(상단) + 서까래",
        "일반, 8mm",
      );
      this.middleUpperAndEndpieceStudUpper_saddleHolder.setDefaultData(
        4,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "마구리 중방(상단) + 마구리 샛기둥(상단)",
        "원형, 일반, 32mm, -",
      );
      this.middleBottom_pipe.setDefaultData(
        5,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "마구리 중방(하단)",
        "각형, 일반, 60×60mm, 2.1T, 7m, -",
      );
      this.middleBottomAndMiddleBottom_connectionPin.setDefaultData(
        6,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "마구리 중방(하단) + 마구리 중방(하단)",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.middleBottomAndColumnEdge_tClamp.setDefaultData(
        7,
        CONST.ITEM_ID_T_CLAMP,
        CONST.ITEM_NAME_T_CLAMP,
        "마구리 중방(하단)(끝) + 골조 기둥",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.middleBottomAndColumnCenter_crossClamp.setDefaultData(
        8,
        CONST.ITEM_ID_CROSS_CLAMP,
        CONST.ITEM_NAME_CROSS_CLAMP,
        "마구리 중방(하단) + 골조 기둥",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.middleBottomAndEndpieceStudBottom_crossClamp.setDefaultData(
        9,
        CONST.ITEM_ID_CROSS_CLAMP,
        CONST.ITEM_NAME_CROSS_CLAMP,
        "마구리 중방(하단) + 마구리 샛기둥(하단)",
        "각형, 일반, 60×60mm, 60×60mm",
      );
      this.middle_screw.setDefaultData(
        10,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "마구리 중방",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.middle_welding.setDefaultData(11, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "마구리 중방", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_middleLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const endpiecePosition: EndpiecePositionIL = <EndpiecePositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.middle_screw.selected = false;
    this.middle_screw.visible = false;
    // 상단
    if (this.middleUpperLineNumber !== 0) {
      this.middleUpper_pipe.selected = true;
      this.middleUpper_pipe.visible = true;
      this.middleUpperAndRafterEndpiece_normalHolder.selected = true;
      this.middleUpperAndRafterEndpiece_normalHolder.visible = true;
      this.middleUpperAndRafterRafter_normalHolder.selected = true;
      this.middleUpperAndRafterRafter_normalHolder.visible = true;
      this.middleUpperAndRafter_boltAndNut.selected = true;
      this.middleUpperAndRafter_boltAndNut.visible = true;
      this.middleUpperAndEndpieceStudUpper_saddleHolder.selected = true;
      this.middleUpperAndEndpieceStudUpper_saddleHolder.visible = true;
      this.middle_screw.selected = true;
      this.middle_screw.visible = true;
    } else {
      this.middleUpper_pipe.selected = false;
      this.middleUpper_pipe.visible = false;
      this.middleUpperAndRafterEndpiece_normalHolder.selected = false;
      this.middleUpperAndRafterEndpiece_normalHolder.visible = false;
      this.middleUpperAndRafterRafter_normalHolder.selected = false;
      this.middleUpperAndRafterRafter_normalHolder.visible = false;
      this.middleUpperAndRafter_boltAndNut.selected = false;
      this.middleUpperAndRafter_boltAndNut.visible = false;
      this.middleUpperAndEndpieceStudUpper_saddleHolder.selected = false;
      this.middleUpperAndEndpieceStudUpper_saddleHolder.visible = false;
    }
    // 하단
    if (this.middleBottomLineNumber !== 0) {
      this.middleBottom_pipe.selected = true;
      this.middleBottom_pipe.visible = true;
      this.middleBottomAndMiddleBottom_connectionPin.selected = true;
      this.middleBottomAndMiddleBottom_connectionPin.visible = true;
      this.middle_screw.selected = true;
      this.middle_screw.visible = true;
      if (this.design.preference.prismEndpieceWeldingFlag === true) {
        this.middle_welding.selected = true;
        this.middle_welding.visible = true;
        this.middleBottomAndColumnEdge_tClamp.selected = false;
        this.middleBottomAndColumnEdge_tClamp.visible = false;
        this.middleBottomAndColumnCenter_crossClamp.selected = false;
        this.middleBottomAndColumnCenter_crossClamp.visible = false;
        this.middleBottomAndEndpieceStudBottom_crossClamp.selected = false;
        this.middleBottomAndEndpieceStudBottom_crossClamp.visible = false;
      } else {
        this.middle_welding.selected = false;
        this.middle_welding.visible = false;
        this.middleBottomAndColumnEdge_tClamp.selected = true;
        this.middleBottomAndColumnEdge_tClamp.visible = true;
        this.middleBottomAndColumnCenter_crossClamp.selected = true;
        this.middleBottomAndColumnCenter_crossClamp.visible = true;
        this.middleBottomAndEndpieceStudBottom_crossClamp.selected = true;
        this.middleBottomAndEndpieceStudBottom_crossClamp.visible = true;
      }
    } else {
      this.middleBottom_pipe.selected = false;
      this.middleBottom_pipe.visible = false;
      this.middleBottomAndMiddleBottom_connectionPin.selected = false;
      this.middleBottomAndMiddleBottom_connectionPin.visible = false;
      this.middle_welding.selected = false;
      this.middle_welding.visible = false;
      this.middleBottomAndColumnEdge_tClamp.selected = false;
      this.middleBottomAndColumnEdge_tClamp.visible = false;
      this.middleBottomAndColumnCenter_crossClamp.selected = false;
      this.middleBottomAndColumnCenter_crossClamp.visible = false;
      this.middleBottomAndEndpieceStudBottom_crossClamp.selected = false;
      this.middleBottomAndEndpieceStudBottom_crossClamp.visible = false;
    }

    /// //////// 수량 ///////////

    this.middleUpper_pipe.designQuantity =
      ((this.basicLevel.width * this.middleUpperLineNumber * this.basic.buildingNumber) /
        this.middleUpper_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE;

    this.middleUpperAndRafterEndpiece_normalHolder.designQuantity =
      this.middleUpperLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.middleUpperAndRafterRafter_normalHolder.designQuantity =
      this.middleUpperLineNumber * 2 * this.basic.buildingNumber * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.middleUpperAndRafter_boltAndNut.designQuantity =
      this.middleUpperAndRafterEndpiece_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.middleUpperAndEndpieceStudUpper_saddleHolder.designQuantity =
      this.middleUpperLineNumber *
      endpiecePosition.prismStudSepPart.studLineNumber *
      this.basic.buildingNumber *
      CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;

    this.middleBottom_pipe.designQuantity =
      (this.middleBottom_pipe.getConnectedPipeLength(this.middleLength, this.middleBottomLineNumber) /
        this.middleBottom_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_ENDPIECE_MIDDLE_PIPE;

    if (this.middleLength > this.middleBottomPipeSample.specLength) {
      this.middleBottomAndMiddleBottom_connectionPin.designQuantity =
        this.middleBottom_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.middleBottomAndMiddleBottom_connectionPin.designQuantity = 0;
    }

    if (this.design.preference.prismEndpieceWeldingFlag === true) {
      let middle_welding_designQuantity: number = this.middleBottomLineNumber * 2;
      middle_welding_designQuantity += this.middleBottomLineNumber * (this.basic.buildingNumber - 1);
      middle_welding_designQuantity +=
        this.middleBottomLineNumber * endpiecePosition.prismStudSepPart.studLineNumber * this.basic.buildingNumber;
      this.middle_welding.designQuantity = middle_welding_designQuantity;
    } else {
      this.middleBottomAndColumnEdge_tClamp.designQuantity =
        this.middleBottomLineNumber * 2 * CONST.NUM_EXTRA_RATE_T_CLAMP;

      this.middleBottomAndColumnCenter_crossClamp.designQuantity =
        this.middleBottomLineNumber * (this.basic.buildingNumber - 1) * CONST.NUM_EXTRA_RATE_CROSS_CLAMP;

      this.middleBottomAndEndpieceStudBottom_crossClamp.designQuantity =
        this.middleBottomLineNumber *
        endpiecePosition.prismStudSepPart.studLineNumber *
        this.basic.buildingNumber *
        CONST.NUM_EXTRA_RATE_CROSS_CLAMP;
    }

    this.middle_screw.designQuantity =
      ((this.middleUpperAndEndpieceStudUpper_saddleHolder.getSelectedQuantity() * 3 +
        this.middleBottomAndMiddleBottom_connectionPin.getSelectedQuantity() * 2 +
        this.middleBottomAndColumnEdge_tClamp.getSelectedQuantity() * 4) /
        this.middle_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 중방 길이 <- 폭(기본정보), 동수(기본정보)
   */
  public algorithm_middleLength(): void {
    this.middleLength =
      this.basicLevel.width * this.basic.buildingNumber + this.design.preference.connectedPipeExtraLength;
  }

  /**
   * 알고리즘: 중방 상단 줄수 <- 중방 상단 줄수(위치/앞면)
   */
  public algorithm_middleUpperLineNumberByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (this.position.type === (<EndpieceLevelIL>this.level).backPosition.type) {
        (<EndpieceLevelIL>this.level).backPosition.prismMiddlePart.middleUpperLineNumber = this.middleUpperLineNumber;
      }
    }
  }

  /**
   * 알고리즘: 중방 하단 줄수 <- 중방 하단 줄수(위치/앞면)
   */
  public algorithm_middleBottomLineNumberByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      if (this.position.type === (<EndpieceLevelIL>this.level).backPosition.type) {
        (<EndpieceLevelIL>this.level).backPosition.prismMiddlePart.middleBottomLineNumber = this.middleBottomLineNumber;
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [마구리 중방(상단)] 파이프 <- 마구리 중방(상단) 파이프 샘플
   */
  public algorithmSpec_middleUpper_pipe(): void {
    this.middleUpper_pipe.specs = this.middleUpperPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 서까래 (1)] 일반 고정구 <- 마구리 중방(상단) 파이프 샘플
   */
  public algorithmSpec_middleUpperAndRafterEndpiece_normalHolder(): void {
    this.middleUpperAndRafterEndpiece_normalHolder.specPipeType = this.middleUpperPipeSample.specPipeType;
    this.middleUpperAndRafterEndpiece_normalHolder.specCrossSize1 = this.middleUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플
   */
  public algorithmSpec_middleUpperAndRafterRafter_normalHolder(): void {
    this.middleUpperAndRafterRafter_normalHolder.specPipeType = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specPipeType;
    this.middleUpperAndRafterRafter_normalHolder.specCrossSize1 = (<RoofLevelIL>(
      this.struct.roofWorkIL.levelAC[this.level.index]
    )).roofPosition.rafterPart.rafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_middleUpperAndRafter_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [마구리 중방(상단) + 마구리 샛기둥(상단)] 새들 고정구 <- 마구리 중방(상단) 파이프 샘플
   */
  public algorithmSpec_middleUpperAndEndpieceStudUpper_saddleHolder(): void {
    this.middleUpperAndEndpieceStudUpper_saddleHolder.specPipeType = this.middleUpperPipeSample.specPipeType;
    this.middleUpperAndEndpieceStudUpper_saddleHolder.specCrossSize1 = this.middleUpperPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단)] 파이프 <- 마구리 중방(하단) 파이프 샘플
   */
  public algorithmSpec_middleBottom_pipe(): void {
    this.middleBottom_pipe.specs = this.middleBottomPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단) + 마구리 중방(하단)] 연결핀 <- 마구리 중방(하단) 파이프 샘플
   */
  public algorithmSpec_middleBottomAndMiddleBottom_connectionPin(): void {
    this.middleBottomAndMiddleBottom_connectionPin.specPipeType = this.middleBottomPipeSample.specPipeType;
    this.middleBottomAndMiddleBottom_connectionPin.specCrossSize1 = this.middleBottomPipeSample.specCrossSize;
    this.middleBottomAndMiddleBottom_connectionPin.specCrossSize2 = this.middleBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단)(끝) + 골조 기둥] T 클램프 <- 마구리 중방(하단) 파이프 샘플, 기둥 파이프
   */
  public algorithmSpec_middleBottomAndColumnEdge_tClamp(): void {
    this.middleBottomAndColumnEdge_tClamp.specPipeType =
      this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specPipeType;
    this.middleBottomAndColumnEdge_tClamp.specCrossSize1 =
      this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
    this.middleBottomAndColumnEdge_tClamp.specCrossSize2 = this.middleBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단) + 골조 기둥] 십자 클램프 <- 마구리 중방(하단) 파이프 샘플, 기둥 파이프
   */
  public algorithmSpec_middleBottomAndColumnCenter_crossClamp(): void {
    this.middleBottomAndColumnCenter_crossClamp.specPipeType = this.middleBottomPipeSample.specPipeType;
    this.middleBottomAndColumnCenter_crossClamp.specCrossSize1 = this.middleBottomPipeSample.specCrossSize;
    this.middleBottomAndColumnCenter_crossClamp.specCrossSize2 =
      this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.columnPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방(하단) + 마구리 샛기둥(하단)] 십자 클램프 <- 마구리 중방(하단) 파이프 샘플, 마구리 샛기둥(하단) 파이프 샘플
   */
  public algorithmSpec_middleBottomAndEndpieceStudBottom_crossClamp(): void {
    this.middleBottomAndEndpieceStudBottom_crossClamp.specPipeType = this.middleBottomPipeSample.specPipeType;
    this.middleBottomAndEndpieceStudBottom_crossClamp.specCrossSize1 = this.middleBottomPipeSample.specCrossSize;
    this.middleBottomAndEndpieceStudBottom_crossClamp.specCrossSize2 = (<EndpiecePositionIL>(
      this.position
    )).prismStudSepPart.studBottomPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [마구리 중방] 나사 <- 공통 샘플
   */
  public algorithmSpec_middle_screw(): void {}

  /**
   * 규격 알고리즘: [마구리 중방] 용접 <- 공통 샘플
   */
  public algorithmSpec_middle_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
