import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePartIL_CylinderMiddle } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_CylinderMiddle";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { EndpiecePartIL_CylinderStudSep } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_CylinderStudSep";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CurCoverLevelIL } from "vhows-design/src/object/design/curtain/cover/CurCoverLevelIL";
import { EndpiecePartIL_CylinderStud } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_CylinderStud";
import { EndpiecePartIL_PrismMiddle } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_PrismMiddle";
import { EndpiecePartIL_PrismStudSep } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_PrismStudSep";
import { EndpiecePartIL_Gate } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePartIL_Gate";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-23
 */
@jsonObject({
  knownTypes: [
    EndpiecePartIL_CylinderMiddle,
    EndpiecePartIL_CylinderStud,
    EndpiecePartIL_CylinderStudSep,
    EndpiecePartIL_PrismMiddle,
    EndpiecePartIL_PrismStudSep,
    EndpiecePartIL_Gate,
  ],
})
export class EndpiecePositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public cylinderStudPart: EndpiecePartIL_CylinderStud;
  public cylinderStudSepPart: EndpiecePartIL_CylinderStudSep;
  public cylinderMiddlePart: EndpiecePartIL_CylinderMiddle;
  public prismStudSepPart: EndpiecePartIL_PrismStudSep;
  public prismMiddlePart: EndpiecePartIL_PrismMiddle;
  public gatePart: EndpiecePartIL_Gate;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 출입문 골조 선택
   */
  public get gateFrameSelected(): boolean {
    if (this.selected === true) {
      if (
        this.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.gateType === CONST.LB_GATE_TYPE_HINGED ||
        this.gateType === CONST.LB_GATE_TYPE_FRAMED
      ) {
        return true;
      } else if (this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME || this.gateType === CONST.LB_GATE_TYPE_NONE) {
        return false;
      }
    }
    return false;
  }

  /**
   * 출입문 피복 선택
   */
  public get gateCoverSelected(): boolean {
    if (this.selected === true) {
      if (this.gateType === CONST.LB_GATE_TYPE_SLIDING || this.gateType === CONST.LB_GATE_TYPE_HINGED) {
        return true;
      } else if (
        this.gateType === CONST.LB_GATE_TYPE_FRAMED ||
        this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME ||
        this.gateType === CONST.LB_GATE_TYPE_NONE
      ) {
        return false;
      }
    }
    return false;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _gateBuildingNumber: number; // 출입 동수
  @jsonMember(Number)
  public _switchBuildingNumber: number; // 개폐 동수
  @jsonMember(String)
  public _gateType: string; // 출입문 형태

  /**
   * 출입 동수
   */
  public get gateBuildingNumber(): number {
    return this._gateBuildingNumber;
  }

  //
  public set gateBuildingNumber(value: number) {
    if (this._gateBuildingNumber === value) return;

    this._gateBuildingNumber = value;

    // 알고리즘
    this.algorithm_gateTypeByGateBuildingNumber();
    this.algorithm_switchBuildingNumber();
    this.cylinderStudPart.algorithm_studCenterUpperSelected();
    this.gatePart.customDoorSample.algorithmQuantity();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_gateBuildingNumberByLevel1();
    }

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();

    // 커튼 피복
    if (this.level.index === 0) {
      (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[0]).curCoverPosition.algorithmBasic();
    }
    // if (this.level.index === 0 || this.level.index === 1) {
    //   (<CurCoverLevelIL> this.struct.curCoverWorkIL.levelAC[this.level.index]).curCoverPosition.algorithmBasic();
    // }
  }

  /**
   * 개폐 동수
   */
  public get switchBuildingNumber(): number {
    return this._switchBuildingNumber;
  }

  //
  public set switchBuildingNumber(value: number) {
    if (this._switchBuildingNumber === value) return;

    this._switchBuildingNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    if (this.level.index === 0) {
      this.algorithm_switchBuildingNumberByLevel1();
    }

    /// //////// 외부 ///////////

    if (this.label === CONST.LB_POSITION_FRONT) {
      // 개폐기
      (<SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index]).frontPosition.algorithmBasic();

      // 피복 고정
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceColumnLineNumber();

      // 치마 피복
      (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).frontPosition.algorithmBasic();

      // 바람막이 피복
      (<WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index]).frontPosition.algorithmBasic();

      // 방충망
      if (this.level.index === 0) {
        this.struct.screennetWorkIL.level1.frontPosition.algorithmBasic();
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      // 개폐기
      (<SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index]).backPosition.algorithmBasic();

      // 피복 고정
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_endpieceColumnLineNumber();

      // 치마 피복
      (<SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index]).backPosition.algorithmBasic();

      // 바람막이 피복
      (<WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index]).backPosition.algorithmBasic();

      // 방충망
      if (this.level.index === 0) {
        this.struct.screennetWorkIL.level1.backPosition.algorithmBasic();
      }
    }
  }

  /**
   * 출입문 형태
   */
  public get gateType(): string {
    return this._gateType;
  }

  //
  public set gateType(value: string) {
    if (this._gateType === value) return;

    this._gateType = value;

    // 알고리즘
    this.algorithm_gateBuildingNumber();
    this.gatePart.algorithm_makingMethod();
    this.gatePart.algorithm_doorWidth();
    this.gatePart.algorithm_doorHeight();
    this.gatePart.algorithm_doorQuantity();
    this.gatePart.algorithm_gateWidth();
    this.gatePart.algorithm_gateHeight();
    this.gatePart.cSectionSteelSample.algorithmSpec();

    // 파트 활성화
    this.algorithm_partActivationByGateType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    if (this.label === CONST.LB_POSITION_FRONT) {
      this.algorithm_gateTypeByPosition();
      this.gatePart.algorithm_makingMethodByPosition();
      this.gatePart.algorithm_doorWidthDoorHeightByPosition();
      this.gatePart.algorithm_doorQuantityByPosition();
    }

    /// //////// 외부 ///////////

    // 피복 고정
    if (this.label === CONST.LB_POSITION_FRONT) {
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceGateLineNumber();
      (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).frontPosition.algorithm_status();
    } else if (this.label === CONST.LB_POSITION_BACK) {
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_endpieceGateLineNumber();
      (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).backPosition.algorithm_status();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();

    // 커튼 피복
    if (this.level.index === 0) {
      (<CurCoverLevelIL>this.struct.curCoverWorkIL.levelAC[this.level.index]).curCoverPosition.algorithmBasic();
    }
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByGateType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 기둥
    this.struct.columnWorkIL.level1.columnPosition.cylinderColumnPart.algorithm_secondEndpieceColumnSelected();
    this.struct.columnWorkIL.level1.columnPosition.prismColumnPart.algorithm_secondEndpieceColumnSelected();

    if (this.label === CONST.LB_POSITION_FRONT) {
      // 개폐기
      (<SwitcherLevelIL>(
        this.struct.switcherWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_selectedAndEnabledByEndpiece();

      // 피복 고정
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceColumnLineNumber();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceMiddleLineNumber();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceGateLineNumber();
      (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).frontPosition.algorithm_status();

      // 창문
      if (this.level.index === 0) {
        this.struct.windowWorkIL.level1.frontPosition.algorithm_selectedAndEnabledByEndpiece();
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      // 개폐기
      (<SwitcherLevelIL>(
        this.struct.switcherWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_selectedAndEnabledByEndpiece();

      // 피복 고정
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_endpieceColumnLineNumber();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_endpieceMiddleLineNumber();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_endpieceGateLineNumber();
      (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).backPosition.algorithm_status();

      // 창문
      if (this.level.index === 0) {
        this.struct.windowWorkIL.level1.backPosition.algorithm_selectedAndEnabledByEndpiece();
      }
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 다른 중수
    if (this.level.index === 0) {
      this.algorithm_typeByLevel1();
    }

    // 알고리즘
    this.algorithm_gateTypeByType();
    this.cylinderStudPart.studPipeSample.algorithmSpec();
    this.cylinderStudPart.algorithm_studLengthMax();
    this.cylinderMiddlePart.algorithm_middleBottomSelected();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    if (this.label === CONST.LB_POSITION_FRONT) {
      this.algorithm_gateTypeByPosition();
    }

    /// //////// 외부 ///////////

    if (this.label === CONST.LB_POSITION_FRONT) {
      // 피복 고정
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceColumnLineNumber();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.algorithm_endpieceMiddleLineNumber();
      (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).frontPosition.algorithm_status();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.normalClipPart.normalClipSample.algorithmSpec();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).frontPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();

      // 치마 피복
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).frontPosition.fixingSkirtClipPart.algorithm_clipInterval();
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).frontPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

      // 바람막이 피복
      (<WindbreakLevelIL>(
        this.struct.windbreakWorkIL.levelAC[this.level.index]
      )).frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();

      // 개폐기
      (<SwitcherLevelIL>(
        this.struct.switcherWorkIL.levelAC[this.level.index]
      )).columnPosition.supportPart.algorithmSpec_diagonal_hookHolder();
      (<SwitcherLevelIL>(
        this.struct.switcherWorkIL.levelAC[this.level.index]
      )).wingPosition.supportPart.algorithmSpec_diagonal_hookHolder();
      (<SwitcherLevelIL>(
        this.struct.switcherWorkIL.levelAC[this.level.index]
      )).roofPosition.supportPart.algorithmSpec_diagonal_hookHolder();
      (<SwitcherLevelIL>(
        this.struct.switcherWorkIL.levelAC[this.level.index]
      )).columnPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
      (<SwitcherLevelIL>(
        this.struct.switcherWorkIL.levelAC[this.level.index]
      )).wingPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
      (<SwitcherLevelIL>(
        this.struct.switcherWorkIL.levelAC[this.level.index]
      )).roofPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
    } else if (this.label === CONST.LB_POSITION_BACK) {
      // 피복 고정
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_endpieceColumnLineNumber();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.algorithm_endpieceMiddleLineNumber();
      (<FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index]).backPosition.algorithm_status();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.normalClipPart.normalClipSample.algorithmSpec();
      (<FixingLevelIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index]
      )).backPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();

      // 치마 피복
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).backPosition.fixingSkirtClipPart.algorithm_clipInterval();
      (<SkirtLevelIL>(
        this.struct.skirtWorkIL.levelAC[this.level.index]
      )).backPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

      // 바람막이 피복
      (<WindbreakLevelIL>(
        this.struct.windbreakWorkIL.levelAC[this.level.index]
      )).backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    }

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.cylinderStudPart = new EndpiecePartIL_CylinderStud();
    this.cylinderStudSepPart = new EndpiecePartIL_CylinderStudSep();
    this.cylinderMiddlePart = new EndpiecePartIL_CylinderMiddle();
    this.prismStudSepPart = new EndpiecePartIL_PrismStudSep();
    this.prismMiddlePart = new EndpiecePartIL_PrismMiddle();
    this.gatePart = new EndpiecePartIL_Gate();

    this.partAC = [
      this.cylinderStudPart,
      this.cylinderStudSepPart,
      this.cylinderMiddlePart,
      this.prismStudSepPart,
      this.prismMiddlePart,
      this.gatePart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.cylinderStudPart = <EndpiecePartIL_CylinderStud>this.partAC[0];
    this.cylinderStudSepPart = <EndpiecePartIL_CylinderStudSep>this.partAC[1];
    this.cylinderMiddlePart = <EndpiecePartIL_CylinderMiddle>this.partAC[2];
    this.prismStudSepPart = <EndpiecePartIL_PrismStudSep>this.partAC[3];
    this.prismMiddlePart = <EndpiecePartIL_PrismMiddle>this.partAC[4];
    this.gatePart = <EndpiecePartIL_Gate>this.partAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param gateBuildingNumber: number 출입 동수
   * @param switchBuildingNumber: number 개폐 동수
   * @param gateType: string 출입문 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    gateBuildingNumber: number = 0,
    switchBuildingNumber: number = 0,
    gateType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._gateBuildingNumber = gateBuildingNumber;
    this._switchBuildingNumber = switchBuildingNumber;
    this._gateType = gateType;

    if (this.level.index >= 0) {
      // 원형 마구리 샛기둥
      this.cylinderStudPart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_CYLINDER_STUD,
        true,
        false,
        true,
        CONST.LB_ENDPIECE_CYLINDER_STUD,
        "",
        7,
        true,
      );

      // 원형 마구리 샛기둥 상하단
      this.cylinderStudSepPart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_CYLINDER_STUD_SEP,
        false,
        false,
        false,
        CONST.LB_ENDPIECE_CYLINDER_STUD_SEP,
        "",
        7,
      );

      // 원형 마구리 중방
      this.cylinderMiddlePart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_CYLINDER_MIDDLE,
        true,
        false,
        true,
        CONST.LB_ENDPIECE_CYLINDER_MIDDLE,
        "",
        0,
        0,
      );

      // 각형 마구리 샛기둥 상하단
      this.prismStudSepPart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_PRISM_STUD_SEP,
        false,
        false,
        false,
        CONST.LB_ENDPIECE_PRISM_STUD_SEP,
        "",
        7,
      );

      // 각형 마구리 중방
      this.prismMiddlePart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_PRISM_MIDDLE,
        false,
        false,
        false,
        CONST.LB_ENDPIECE_PRISM_MIDDLE,
        "",
        0,
        0,
      );

      // 마구리 출입문
      this.gatePart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_GATE,
        true,
        false,
        true,
        CONST.LB_ENDPIECE_GATE,
        "",
        CONST.LB_GATE_MAKING_METHOD_MADE,
        1300,
        2400,
        2,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 5 && (<Part>this.partAC[4]).label === "출입 문틀") {
      // // 마구리 출입문 - 전면 샷시틀
      // const gateSashFramePart: EndpiecePartIL_GateSashFrame = new EndpiecePartIL_GateSashFrame();
      // gateSashFramePart.setAssociation(design, struct, work, level, this);
      // gateSashFramePart.setDefaultData(
      //   CONST.INDEX_IL_ENDPIECE_GATE_SASH_FRAME,
      //   false, false, false,
      //   CONST.LB_ENDPIECE_GATE_SASH_FRAME, '',
      //   7000, 3000,
      // );
      // this.partAC.push(gateSashFramePart);

      // 임시 데이터로 대체
      this.partAC.push(new Part());
    }
    if (this.partAC.length === 6 && (<Part>this.partAC[4]).label === "출입 문틀") {
      // 각형 마구리 샛기둥 상하단
      const prismStudSepPart: EndpiecePartIL_PrismStudSep = new EndpiecePartIL_PrismStudSep();
      prismStudSepPart.setAssociation(design, struct, work, level, this);
      prismStudSepPart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_PRISM_STUD_SEP,
        false,
        false,
        false,
        CONST.LB_ENDPIECE_PRISM_STUD_SEP,
        "",
        7,
      );
      this.partAC.splice(2, 0, prismStudSepPart);
      // 각형 마구리 중방
      const prismMiddlePart: EndpiecePartIL_PrismMiddle = new EndpiecePartIL_PrismMiddle();
      prismMiddlePart.setAssociation(design, struct, work, level, this);
      prismMiddlePart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_PRISM_MIDDLE,
        false,
        false,
        false,
        CONST.LB_ENDPIECE_PRISM_MIDDLE,
        "",
        0,
        0,
      );
      this.partAC.splice(3, 0, prismMiddlePart);
    }
    if (this.partAC.length === 8 && (<Part>this.partAC[6]).label === "출입 문틀") {
      // 원형 마구리 샛기둥 상하단
      const cylinderStudSepPart: EndpiecePartIL_CylinderStudSep = new EndpiecePartIL_CylinderStudSep();
      cylinderStudSepPart.setAssociation(design, struct, work, level, this);
      cylinderStudSepPart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_CYLINDER_STUD_SEP,
        false,
        false,
        false,
        CONST.LB_ENDPIECE_CYLINDER_STUD_SEP,
        "",
        7,
      );
      this.partAC.splice(1, 0, cylinderStudSepPart);
    }
    if (this.partAC.length === 9 && (<Part>this.partAC[7]).label === "출입 문틀") {
      // // 마구리 출입문 - 제작형 문짝
      // const gateMakingDoorPart: EndpiecePartIL_GateMakingDoor = new EndpiecePartIL_GateMakingDoor();
      // gateMakingDoorPart.setAssociation(design, struct, work, level, this);
      // gateMakingDoorPart.setDefaultData(
      //   CONST.INDEX_IL_ENDPIECE_GATE_MAKING_DOOR,
      //   false, false, false,
      //   CONST.LB_ENDPIECE_GATE_MAKING_DOOR, '',
      //   CONST.LB_GATE_MAKING_DOOR_MAKING_METHOD_DOOR,
      //   1300, 2400,
      //   2,
      //   CONST.LB_GATE_MAKING_DOOR_PIPE_HOLER_TYPE_T_HOLDER,
      // );
      // if (this.gateType === CONST.LB_GATE_TYPE_SLIDING_MAKING
      //   || this.gateType === CONST.LB_GATE_TYPE_HINGED_MAKING) {
      //   gateMakingDoorPart._selected = true;
      //   gateMakingDoorPart._visible = true;
      // }
      // this.partAC.splice(7, 0, gateMakingDoorPart);

      // 임시 데이터로 대체
      this.partAC.splice(7, 0, new Part());
    }
    if (this.partAC.length === 10 && (<Part>this.partAC[8]).label === "출입 문틀") {
      // 통합 출입문 파트 추가 및 기존 출입문관련 파트 모두 삭제
      const gatePart: EndpiecePartIL_Gate = new EndpiecePartIL_Gate();
      gatePart.setAssociation(design, struct, work, level, this);
      gatePart.setDefaultData(
        CONST.INDEX_IL_ENDPIECE_GATE,
        true,
        false,
        true,
        CONST.LB_ENDPIECE_GATE,
        "",
        CONST.LB_GATE_MAKING_METHOD_MADE,
        1300,
        2400,
        2,
      );
      if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
        gatePart._selected = true;
        gatePart._visible = true;
      }
      this.partAC.splice(5, 5, gatePart);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.partAC);
      // 알고리즘 동작
      gatePart.setDefaultVariable();
      gatePart.algorithmPart();
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_gateBuildingNumber();
    this.algorithm_switchBuildingNumber();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_ENDPIECE_TYPE_WHOLE || this.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      this.cylinderStudPart.selected = true;
      this.cylinderStudPart.visible = true;
      this.cylinderStudSepPart.selected = false;
      this.cylinderStudSepPart.visible = false;
      this.cylinderMiddlePart.selected = true;
      this.cylinderMiddlePart.visible = true;
      this.prismStudSepPart.selected = false;
      this.prismStudSepPart.visible = false;
      this.prismMiddlePart.selected = false;
      this.prismMiddlePart.visible = false;
    } else if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
      this.cylinderStudPart.selected = false;
      this.cylinderStudPart.visible = false;
      this.cylinderStudSepPart.selected = true;
      this.cylinderStudSepPart.visible = true;
      this.cylinderMiddlePart.selected = true;
      this.cylinderMiddlePart.visible = true;
      this.prismStudSepPart.selected = false;
      this.prismStudSepPart.visible = false;
      this.prismMiddlePart.selected = false;
      this.prismMiddlePart.visible = false;
    } else if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
      this.cylinderStudPart.selected = false;
      this.cylinderStudPart.visible = false;
      this.cylinderStudSepPart.selected = false;
      this.cylinderStudSepPart.visible = false;
      this.cylinderMiddlePart.selected = false;
      this.cylinderMiddlePart.visible = false;
      this.prismStudSepPart.selected = true;
      this.prismStudSepPart.visible = true;
      this.prismMiddlePart.selected = true;
      this.prismMiddlePart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 출입문 형태
   */
  public algorithm_partActivationByGateType(): void {
    if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
      this.gatePart.selected = true;
      this.gatePart.visible = true;
    } else {
      this.gatePart.selected = false;
      this.gatePart.visible = false;
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(기둥 골조)<br/>
   *  - 상단+하단형 <-> 상단+하단형(각기둥) 간의 변경
   */
  public algorithm_typeByColumnType(): void {
    const columnPosition: ColumnPositionIL = this.struct.columnWorkIL.level1.columnPosition;
    if (columnPosition.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
        this.type = CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM;
      }
    } else if (columnPosition.type === CONST.LB_COLUMN_TYPE_PRISM) {
      if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
        this.type = CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM;
      }
    }
  }

  /**
   * 알고리즘: 개폐 동수 <- 출입 동수, 동수(기본정보)
   */
  public algorithm_switchBuildingNumber(): void {
    this.switchBuildingNumber = this.basic.buildingNumber - this.gateBuildingNumber;
  }

  /**
   * 알고리즘: 출입 동수 <- 출입문 형태, 선택(개폐기)
   */
  public algorithm_gateBuildingNumber(): void {
    const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (
        this.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.gateType === CONST.LB_GATE_TYPE_HINGED ||
        this.gateType === CONST.LB_GATE_TYPE_FRAMED ||
        this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME
      ) {
        if (switcherLevel.frontPosition.selected) {
          this.gateBuildingNumber = 1;
        } else {
          this.gateBuildingNumber = 1;
        }
      } else if (this.gateType === CONST.LB_GATE_TYPE_NONE) {
        this.gateBuildingNumber = 0;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (
        this.gateType === CONST.LB_GATE_TYPE_SLIDING ||
        this.gateType === CONST.LB_GATE_TYPE_HINGED ||
        this.gateType === CONST.LB_GATE_TYPE_FRAMED ||
        this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME
      ) {
        if (switcherLevel.backPosition.selected) {
          this.gateBuildingNumber = 1;
        } else {
          this.gateBuildingNumber = 1;
        }
      } else if (this.gateType === CONST.LB_GATE_TYPE_NONE) {
        this.gateBuildingNumber = 0;
      }
    }
  }

  /**
   * 알고리즘: 출입문 형태 <- 형태
   */
  public algorithm_gateTypeByType(): void {
    if (
      this.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
      this.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM ||
      this.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM
    ) {
      if (this.gateType === CONST.LB_GATE_TYPE_NONE || this.gateType === CONST.LB_GATE_TYPE_SASH_FRAME) {
        this.gateType = CONST.LB_GATE_TYPE_SLIDING;
      }
    } else if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
      if (this.gateType !== CONST.LB_GATE_TYPE_SASH_FRAME) {
        this.gateType = CONST.LB_GATE_TYPE_NONE;
      }
    }
  }

  /**
   * 알고리즘: 출입문 형태 <- 출입 동수
   */
  public algorithm_gateTypeByGateBuildingNumber(): void {
    if (this.gateBuildingNumber === 0) {
      if (this.gateType !== CONST.LB_GATE_TYPE_NONE) {
        this.gateType = CONST.LB_GATE_TYPE_NONE;
      }
    } else {
      if (this.gateType === CONST.LB_GATE_TYPE_NONE) {
        this.gateType = CONST.LB_GATE_TYPE_SLIDING;
      }
    }
  }

  /**
   * 알고리즘: 출입문 형태 <- 출입문 형태, 형태
   */
  public algorithm_gateTypeByPosition(): void {
    if (this.label === CONST.LB_POSITION_FRONT) {
      if (this.type === (<EndpieceLevelIL>this.level).backPosition.type) {
        (<EndpieceLevelIL>this.level).backPosition.gateType = this.gateType;
      }
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(1중)
   */
  public algorithm_typeByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.struct.endpieceWorkIL.levelAC.length; l++) {
        if (this.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
          (<EndpiecePositionIL>this.work.levelAC[l].positionAC[this.index]).type = CONST.LB_ENDPIECE_TYPE_WHOLE;
        } else {
          (<EndpiecePositionIL>this.work.levelAC[l].positionAC[this.index]).type = this.type;
        }
      }
    }
  }

  /**
   * 알고리즘: 출입 동수 <- 출입 동수(1중)
   */
  public algorithm_gateBuildingNumberByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        const endpiecePosition: EndpiecePositionIL = <EndpiecePositionIL>this.work.levelAC[l].positionAC[this.index];
        endpiecePosition.gateBuildingNumber = this.gateBuildingNumber;
      }
    }
  }

  /**
   * 알고리즘: 개폐 동수 <- 개폐 동수(1중)
   */
  public algorithm_switchBuildingNumberByLevel1(): void {
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        const endpiecePosition: EndpiecePositionIL = <EndpiecePositionIL>this.work.levelAC[l].positionAC[this.index];
        endpiecePosition.switchBuildingNumber = this.switchBuildingNumber;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
