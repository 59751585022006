import { CONST } from "vhows-design/src/common/constant/CONST";

export enum FACILITY_TYPE {
  WAREHOUSE = "F01", // 농막/창고/반반 하우스
  RAIN_PROOF = "F02", // 우산형 비가림 시설
  SINGLE = "F03", // 단동 하우스
  INTERLOCK = "F04", // 연동 하우스
  VENLO = "F05", // 벤로 하우스
  GLASS = "F06", // 유리 온실
  DOUBLE_WIDTH = "F07", // 밀양식 광폭 하우스
  OUTDOORS_IRRIGATION = "F08", // 노지 시설/관수
  REPAIR = "F09", // 교체/업그레이드
  OTHER = "F10", // 기타
  SMARTFARM = "F11", // 스마트팜
}

/**
 * 설계 상수
 */
export const DesignConst = {
  //----------------------------------
  // 공통
  //----------------------------------
  //----------------------------------
  // 화면 변수
  //----------------------------------
  // 접근형태
  accessType: {
    MY: "my",
    COMPANY: "company",
    DEFAULT: "default",
  },
  // 수정 형태
  modifyType: {
    CREATE: "create",
    UPDATE: "update",
  },
  // 부분 견적
  SECTION_ESTIMATE: "부분 견적",
  // 담당자 없음
  ESTIMATOR_NONE: "없음",
  // 견적 정보 없음
  ESTIMATE_NONE: "견적 정보가 없습니다.",
  //----------------------------------
  // 데이터 변수
  //----------------------------------
  designMode: {
    DESIGN: "design",
    TEMPLATE: "template",
  },
  estimateMode: {
    ESTIMATE: "estimate",
    ESTIMATE_REVIEW: "estimateReview",
  },
  progressStatus: {
    DESIGNING_ESTIMATING: "설계/견적중",
    CONTRACT_COMPLETED: "계약완료",
    BUILDING: "시공중",
    COMPLETED: "완료",
    STOPPED: "중단",
  },
  constructionGrouping: [
    CONST.LB_FOUNDATION_CONSTRUCTION,
    CONST.LB_FRAME_CONSTRUCTION,
    CONST.LB_SWITCH_CONSTRUCTION,
    CONST.LB_COVER_CONSTRUCTION,
    CONST.LB_CURTAIN_CONSTRUCTION,
    CONST.LB_SKYLIGHT_CONSTRUCTION,
    CONST.LB_OTHER_ARCHITECTURE_CONSTRUCTION,
  ],
  groupFrameSwitchCover: [CONST.LB_FRAME_CONSTRUCTION, CONST.LB_SWITCH_CONSTRUCTION, CONST.LB_COVER_CONSTRUCTION],
  jajeGrouping: [
    CONST.ITEM_CAT2_NAME_FRAME_MATERIAL,
    CONST.ITEM_CAT2_NAME_FRAME_APPENDAGE_MATERIAL,
    CONST.ITEM_CAT2_NAME_GUTTER_MATERIAL,
    CONST.ITEM_CAT2_NAME_SWITCH_MATERIAL,
    CONST.ITEM_CAT2_NAME_COVER_MATERIAL,
    CONST.ITEM_CAT2_NAME_COVER_APPENDAGE_MATERIAL,
  ],
  constructionWorkGrouping: [CONST.LB_PARTITION_WORK, CONST.LB_WINDOW_WORK, CONST.LB_WORKROOM_WORK],
  otherFacilConstructionGrouping: [
    CONST.LB_RACK_WORK,
    CONST.LB_RAIL_WORK,
    CONST.LB_CO2_WORK,
    CONST.LB_AUTOSPRAY_WORK,
    CONST.LB_ENVIRONMENT_WORK,
    CONST.LB_TEM_COOLING_WORK,
    CONST.LB_TEM_HEATING_WORK,
    CONST.LB_HYD_SYSTEM_WORK,
    CONST.LB_HYD_CULTIVATION_WORK,
  ],

  //----------------------------------
  // 구조 레이블, 아이디, 인덱스
  //----------------------------------

  LB_STRUCT_SINGLE: "단동",
  LB_STRUCT_DOUBLE_WIDTH: "광폭",
  LB_STRUCT_RAIN_PROOF: "비가림",
  LB_STRUCT_INTERLOCK: "연동",
  LB_STRUCT_ANGULAR_IL: "각연동",
  LB_STRUCT_VENLO: "벤로",
  LB_STRUCT_OTHER: "기타",

  ID_STRUCT_SINGLE: "sg",
  ID_STRUCT_DOUBLE_WIDTH: "dw",
  ID_STRUCT_RAIN_PROOF: "rp",
  ID_STRUCT_INTERLOCK: "il",
  ID_STRUCT_ANGULAR_IL: "ai",
  ID_STRUCT_VENLO: "vl",
  ID_STRUCT_OTHER: "ot",

  ITEM_SPEC_VALUE_40_MM_UNDER: "40mm 미만",
  ITEM_SPEC_VALUE_40_MM_MORE: "40mm 이상",
} as const;

//----------------------------------
// 설계 아이템 코드
//----------------------------------

export const DesignItemNumber = {
  foundation: {
    foundation: "A001000000",
  },
  frame: {
    frame: "A002000000",
    gate_front: {
      none: "A002001001",
      sliding: "A002001002",
      hinged: "A002001003",
    },
    gate_back: {
      none: "A002002001",
      sliding: "A002002002",
      hinged: "A002002003",
    },
  },
  cover: {
    cover: "A003000000",
    outerCover: {
      none: "A003003001",
      vinyl: "A003003004",
      cover2: "A003003005",
      cover1: "A003003006",
      cover4: "A003003007",
      cover5: "A003003008",
    },
    innerCover: {
      none: "A003004001",
      vinyl: "A003004004",
      vinyl_nonwoven: "A003004009",
      vinyl_quilting: "A003004010",
    },
  },
  switch: {
    sideswitch: {
      sideswitch: "A004005000",
      none: "A004005001",
      switcher_manual: "A004005015",
      switcher_power: "A004005016",
    },
  },
  curcover: {
    curcover: "B005006000",
    none: "B005006001",
    quilting: "B005006011",
    quilting_shadenet: "B005006012",
    quilting_screen: "B005006013",
    quilting_screen_screen: "B005006014",
  },
  skylight: {
    roofswitch: {
      roofswitch: "B006007000",
      none: "B006007001",
      switcher_power: "B006007016",
      skylight_frame_half: "B006007018",
      skylight_frame_rackpinion_way_both: "B006007019",
    },
  },
  ventexhaust: {
    none: "C007008001",
    vent_exhaust_fan: "C007008020",
    vent_exhaust_roof_fan_manual: "C007008017",
  },
  ventinside: {
    none: "C008009001",
    vent_inside_air_flow_fan: "C008009021",
  },
  window: {
    none: "C009010001",
    window_sash_frame: "C009010022",
    window_hinged: "C009010023",
  },
  watering_fog: {
    watering_fog: "D010000000",
  },
  watering_sprinkler: { watering_sprinkler: "D011000000" },
  watering_drip: { watering_drip: "D012000000" },
  watering_screen: { watering_screen: "D013000000" },
  hydroponics_substrate_culture: { hydroponics_substrate_culture: "E014000000" },
  hydroponics_nft: { hydroponics_nft: "E015000000" },
  heating_warm_water: { heating_warm_water: "F016000000" },
  heating_steam: { heating_steam: "F017000000" },
  heating_warm_air: { heating_warm_air: "F018000000" },
  heating_direct_tube: { heating_direct_tube: "F019000000" },
  heating_ground_pump: { heating_ground_pump: "F020000000" },
  water_tank_chiller: { water_tank_chiller: "F021000000" },
  airconditioner: { airconditioner: "F022000000" },
  environment: { environment: "G023000000" },
  co2: { co2: "G024000000" },
  autospray: { autospray: "G025000000" },
  workroom: { workroom: "Z026000000" },
  rack: { rack: "Z027000000" },
  rail: { rail: "Z028000000" },
  ground_flat: { ground_flat: "Z029000000" },
  concrete: { concrete: "Z030000000" },
  washroom: { washroom: "Z031000000" },
  container: { container: "Z032000000" },
};

export const DesignItemNumber_V2 = {
  foundation: {
    foundation: "A001000000",
  },
  frame: {
    frame: "A002000000",
    gate_front: {
      none: "A002001001",
      sliding: "A002001002",
      hinged: "A002001003",
    },
    gate_back: {
      none: "A002002001",
      sliding: "A002002002",
      hinged: "A002002003",
    },
  },
  cover: {
    cover: "A003000000",
    outerCover: {
      none: "A003003001",
      vinyl: "A003003004",
      cover2: "A003003005",
      cover1: "A003003006",
      cover4: "A003003007",
      cover5: "A003003008",
    },
    innerCover: {
      none: "A003004001",
      vinyl: "A003004004",
      vinyl_nonwoven: "A003004009",
      vinyl_quilting: "A003004010",
    },
  },
  switch: {
    sideswitch: {
      sideswitch: "A004005000",
      none: "A004005001",
      switcher_manual: "A004005015",
      switcher_power: "A004005016",
    },
  },
  curcover: {
    curcover: "B005006000",
    none: "B005006001",
    quilting: "B005006011",
    quilting_shadenet: "B005006012",
    quilting_screen: "B005006013",
    quilting_screen_screen: "B005006014",
  },
  skylight: {
    roofswitch: {
      roofswitch: "B006007000",
      none: "B006007001",
      switcher_power: "B006007016",
      skylight_frame_half: "B006007018",
      skylight_frame_rackpinion_way_both: "B006007019",
    },
  },
  ventexhaust: {
    none: "C007008001",
    vent_exhaust_fan: "C007008020",
    vent_exhaust_roof_fan_manual: "C007008017",
  },
  ventinside: {
    none: "C008009001",
    vent_inside_air_flow_fan: "C008009021",
  },
  window: {
    none: "C009010001",
    window_sash_frame: "C009010022",
    window_hinged: "C009010023",
  },
  watering_fog: {
    watering_fog: "D010000000",
  },
  watering_sprinkler: { watering_sprinkler: "D011000000" },
  watering_drip: { watering_drip: "D012000000" },
  watering_screen: { watering_screen: "D013000000" },
  hydroponics_substrate_culture: { hydroponics_substrate_culture: "E014000000" },
  hydroponics_nft: { hydroponics_nft: "E015000000" },
  heating_warm_water: { heating_warm_water: "F016000000" },
  heating_steam: { heating_steam: "F017000000" },
  heating_warm_air: { heating_warm_air: "F018000000" },
  heating_direct_tube: { heating_direct_tube: "F019000000" },
  heating_ground_pump: { heating_ground_pump: "F020000000" },
  water_tank_chiller: { water_tank_chiller: "F021000000" },
  airconditioner: { airconditioner: "F022000000" },
  environment: { environment: "G023000000" },
  co2: { co2: "G024000000" },
  autospray: { autospray: "G025000000" },
  workroom: { workroom: "Z026000000" },
  rack: { rack: "Z027000000" },
  rail: { rail: "Z028000000" },
  ground_flat: { ground_flat: "Z029000000" },
  concrete: { concrete: "Z030000000" },
  washroom: { washroom: "Z031000000" },
  container: { container: "Z032000000" },
};
