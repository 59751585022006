import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { ColumnPartVL_Middle } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Middle";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-08
 */
@jsonObject
export class ColumnSampleVL_FrontAndBackMiddlePipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const prismMiddlePart: ColumnPartVL_Middle = <ColumnPartVL_Middle>this.part;
    prismMiddlePart.algorithmSpec_frontAndBackMiddle_pipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 내부 기둥 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = (<ColumnPositionVL>this.position).prismColumnPart.columnPipeSample.specsList;
  }

  /**
   * 규격 알고리즘 : 길이 <- 폭(기본정보)
   */
  public algorithmSpec_specLength(): void {
    this.specLength = this.basicLevel.width;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
