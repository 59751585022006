import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemManualSwitcher } from "vhows-design/src/object/design/item/list/ItemManualSwitcher";
import { CoverLevel } from "vhows-design/src/object/design/cover/cover/CoverLevel";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { SwitcherPart_Manual } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Manual";
import { SwitcherPart_Support } from "vhows-design/src/object/design/switches/switcher/SwitcherPart_Support";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-20
 */
@jsonObject
export class SwitcherSample_ManualSwitcher extends ItemManualSwitcher {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const manualPart: SwitcherPart_Manual = <SwitcherPart_Manual>this.part;
    manualPart.algorithmSpec_manual_manualSwitcher();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const switcherPart_Support: SwitcherPart_Support = (<SwitcherPosition>this.position).supportPart;
    switcherPart_Support.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 개폐 방향
   */
  // @override
  public algorithmSpec(): void {
    const switcherPosition: SwitcherPosition = this.position as SwitcherPosition;
    if (switcherPosition.label === CONST.LB_POSITION_SIDE) {
      if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
        this.specSwitchType = "체인";
      } else {
        this.specSwitchType = "측면";
      }
    }
  }

  /**
   * 규격 알고리즘 <- 선택(기초 피복), 범위 형태(기초 피복), 형태(기초 피복), 피복 추가(기초 피복), 피복 삭제(기초 피복)
   */
  public algorithmSpec_specUsage(): void {
    const coverLevel: CoverLevel = <CoverLevel>this.struct.coverWork.levelAC[this.level.index];
    if (this.part.label === CONST.LB_SWITCHER_MANUAL1) {
      if (coverLevel.coverThicknesses1[this.position.index] < CONST.LB_COVER_SWITCHER_USAGE_VALUE_NONWOVEN) {
        this.specUsage = CONST.ITEM_SPEC_VALUE_VINYL_USAGE;
      } else {
        this.specUsage = CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE;
      }
    } else if (this.part.label === CONST.LB_SWITCHER_MANUAL2) {
      if (coverLevel.coverThicknesses2[this.position.index] < CONST.LB_COVER_SWITCHER_USAGE_VALUE_NONWOVEN) {
        this.specUsage = CONST.ITEM_SPEC_VALUE_VINYL_USAGE;
      } else {
        this.specUsage = CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
