import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { EndpiecePartVL_Endpiece } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePartVL_Endpiece";
import { ColumnPartVL_SideStraight } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_SideStraight";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class EndpieceSampleVL_StraightBottomPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();

    super.algorithmBasic();
  }
  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////
    const endpiecePart: EndpiecePartVL_Endpiece = <EndpiecePartVL_Endpiece>this.part;
    endpiecePart.algorithmSpec_straightBottom_pipe();
    endpiecePart.algorithmSpec_straightBottomAndStraightBottom_connectionPin();
    endpiecePart.algorithmSpec_straightBottomAndFrontColumn_saddleHolder();
    endpiecePart.algorithmSpec_straightBottomAndColumnCenter_crossClamp();
    endpiecePart.algorithmSpec_straightBottomAndEndpieceBarBottom_saddleHolder();
    endpiecePart.algorithmSpec_straightBottomAndEndpieceBarBottom_crossClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    // (<EndpiecePositionIL> this.position).prismMiddlePart.middleBottomPipeSample.algorithmSpec();
    // (<EndpiecePositionIL> this.position).prismMiddlePart.algorithmSpec_middleBottomAndEndpieceStudBottom_crossClamp();

    // 다른 위치
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   (<EndpieceLevelIL> this.level).backPosition.prismStudSepPart.studBottomPipeSample.specs = this.specs;
    // }

    /// //////// 외부 ///////////

    // 피복 고정
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
      fixingLevel.frontPosition.normalClipPart.normalClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipGate_normalClip();
      fixingLevel.backPosition.normalClipPart.normalClipSample.algorithmSpec();
    }

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      skirtLevel.frontPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      skirtLevel.backPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
    }

    // // 바람막이 피복
    // const windbreakLevel: WindbreakLevelIL = <WindbreakLevelIL> this.struct.windbreakWorkIL.levelAC[this.level.index];
    // if (this.position.label === CONST.LB_POSITION_FRONT) {
    //   windbreakLevel.frontPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    // } else if (this.position.label === CONST.LB_POSITION_BACK) {
    //   windbreakLevel.backPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
    // }

    // 개폐기
    const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];
    switcherLevel.columnPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.wingPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.roofPosition.supportPart.algorithmSpec_diagonal_hookHolder();
    switcherLevel.columnPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
    switcherLevel.wingPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
    switcherLevel.roofPosition.supportPart.algorithmSpec_diagonal2_hookHolder();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 측면 가로대 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    const sideStraightPart: ColumnPartVL_SideStraight = this.struct.columnWorkVL.level1.columnPosition.sideStraightPart;
    if (sideStraightPart.sideStraightType === CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_PIPE) {
      this.specsList = sideStraightPart.sideStraightPipeSample.specsList;
    } else {
      this.specsList = sideStraightPart.sideStraightCSectionSteelSample.specsList;
    }
  }

  /**
   * 규격 알고리즘 <- 폭(기본정보), 보 파이프 샘플(기둥 골조)
   */
  public algorithmSpec_specLength(): void {
    if (this.basicLevel.width === 6 || this.basicLevel.width === 7) {
      this.specLength = this.basicLevel.width;
    } else {
      this.specLength = this.struct.columnWorkVL.level1.columnPosition.prismBeamPart.beamPipeSample.specLength;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
