import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Work } from "vhows-design/src/object/design/base/Work";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";
import { StructIL } from "vhows-design/src/object/design/base/StructIL";
import { StructVL } from "vhows-design/src/object/design/base/StructVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-09
 */
@jsonObject
export class Basic extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 깊은 복사중 여부 */
  public isDeepCopying: boolean = false;

  /**
   * 적용 변경 여부<br/>
   * 적용 버튼에 영향을 미치는 변경이 발생한 경우
   */
  public isApplyChanged: boolean = false;

  /**
   * 설계 변경 여부<br/>
   * 설계에 영향을 미치는 변경이 발생한 경우, 알고리즘이 동작함
   */
  public isDesignChanged: boolean = false;

  /**
   * 기본정보 팝업 가시성
   */
  public popupVisible: boolean = false;

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 중수별 기본정보AC */
  @jsonArrayMember(BasicLevel)
  public basicLevelAC: BasicLevel[] = null;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _structureName: string = null; // 구조명
  @jsonMember(Number)
  public _buildingNumber: number = 0; // 동수
  @jsonMember(Number)
  public _levelNumber: number = 0; // 중수
  @jsonMember(Number)
  public _buildingInterval: number = 0; // 동 간격
  @jsonMember(Number)
  public _levelInterval: number = 0; // 중 간격
  @jsonMember(Number)
  public _area: number = 0; // 면적
  @jsonMember(Number)
  public _pyeong: number = 0; // 평
  @jsonMember(Number)
  public _wingLeft: number = 0; // 방풍벽 좌
  @jsonMember(Number)
  public _wingRight: number = 0; // 방풍벽 우
  @jsonMember(Number)
  public _wingFront: number = 0; // 방풍벽 앞
  @jsonMember(Number)
  public _wingBack: number = 0; // 방풍벽 뒤
  @jsonMember(Boolean)
  public _isVariableLength: boolean = false; // 가변길이 여부

  @jsonMember(Boolean)
  public _bendingExpenseFlag: boolean = false; // 벤딩비 플래그
  @jsonMember(Boolean)
  public _padVinylFlag: boolean = false; // 패드 비닐 플래그

  @jsonMember(Boolean)
  public _buildingSelected: boolean = false; // 시공 여부
  @jsonMember(Boolean)
  public _subsidySelected: boolean = false; // 정부사업 여부

  /**
   * 구조명
   */
  public get structureName(): string {
    return this._structureName;
  }
  //
  public set structureName(value: string) {
    this._structureName = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      if (this.design.struct instanceof StructIL || this.design.struct instanceof StructVL) {
        for (let i = 0; i <= this.basicLevelAC.length - 2; i++) {
          this.basicLevelAC[i].algorithm_perimeterRoof();
          this.basicLevelAC[i].lengthRafter;
          this.basicLevelAC[i].algorithm_perimeterRoofIdeal();
        }
      } else {
        for (const basicLevel of this.basicLevelAC) {
          basicLevel.algorithm_perimeterRoof();
          basicLevel.algorithm_perimeterRoofIdeal();
        }
      }
    }
  }

  /**
   * 동수
   */
  public get buildingNumber(): number {
    return this._buildingNumber;
  }
  //
  public set buildingNumber(value: number) {
    this._buildingNumber = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      this.algorithm_isVariableLengthByBuildingNumber();
      for (const basicLevel of this.basicLevelAC) {
        basicLevel.algorithm_basicBuildingAC(this, this.isVariableLength);
        // 가변길이일 경우 수행
        if (this.isVariableLength === true) {
          basicLevel.algorithm_averageLength();
        }
      }
      this.algorithm_area();
    }
  }

  /**
   * 중수
   * - 단동은 1~4, 연동은 1~3의 값을 가짐
   * - 주의: LevelNumber는 1부터 시작하고, Level 객체의 index는 0부터 시작함
   */
  public get levelNumber(): number {
    return this._levelNumber;
  }
  //
  public set levelNumber(value: number) {
    this._levelNumber = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;
    }
  }

  /**
   * 동간 간격
   */
  public get buildingInterval(): number {
    return this._buildingInterval;
  }
  //
  public set buildingInterval(value: number) {
    this._buildingInterval = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;
    }
  }

  /**
   * 중간 간격
   */
  public get levelInterval(): number {
    return this._levelInterval;
  }
  //
  public set levelInterval(value: number) {
    this._levelInterval = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      this.algorithm_widthByLevel();
      this.algorithm_heightShoulderByLevel();
    }
  }

  /**
   * 면적
   */
  public get area(): number {
    return this._area;
  }
  //
  public set area(value: number) {
    this._area = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;
    }
  }

  /**
   * 면적 (방풍제외)
   */
  public get areaNoWing(): number {
    return CommonUtil.roundUp(this.basicLevelAC[0].width * this.basicLevelAC[0].length * this.buildingNumber);
  }

  /**
   * 평
   */
  public get pyeong(): number {
    return this._pyeong;
  }
  //
  public set pyeong(value: number) {
    this._pyeong = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;
    }
  }

  /**
   * 평 (방풍제외)
   */
  public get pyeongNoWing(): number {
    return CommonUtil.roundUp(this.basicLevelAC[0].width * this.basicLevelAC[0].length * this.buildingNumber * 0.3025);
  }

  /**
   * 방풍벽 좌
   */
  public get wingLeft(): number {
    return this._wingLeft;
  }
  //
  public set wingLeft(value: number) {
    this._wingLeft = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      this.wingRight = this._wingLeft;
      this.algorithm_area();
    }
  }

  /**
   * 방풍벽 우
   */
  public get wingRight(): number {
    return this._wingRight;
  }
  //
  public set wingRight(value: number) {
    this._wingRight = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      this.algorithm_area();
    }
  }

  /**
   * 방풍벽 앞
   */
  public get wingFront(): number {
    return this._wingFront;
  }
  //
  public set wingFront(value: number) {
    this._wingFront = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      this.wingBack = this._wingFront;
      this.algorithm_area();
    }
  }

  /**
   * 방풍벽 뒤
   */
  public get wingBack(): number {
    return this._wingBack;
  }
  //
  public set wingBack(value: number) {
    this._wingBack = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      this.algorithm_area();
    }
  }

  /**
   * 가변길이 여부<br/>
   * 동마다 길이가 다른지 여부
   */
  public get isVariableLength(): boolean {
    return this._isVariableLength;
  }
  //
  public set isVariableLength(value: boolean) {
    this._isVariableLength = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      for (const basicLevel of this.basicLevelAC) {
        basicLevel.algorithm_basicBuildingAC(this, value);
      }
    }
  }

  /**
   * 벤딩비 플래그
   */
  public get bendingExpenseFlag(): boolean {
    return this._bendingExpenseFlag;
  }
  //
  public set bendingExpenseFlag(value: boolean) {
    this._bendingExpenseFlag = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;
    }
  }

  /**
   * 패드 비닐 플래그
   */
  public get padVinylFlag(): boolean {
    return this._padVinylFlag;
  }
  //
  public set padVinylFlag(value: boolean) {
    this._padVinylFlag = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;
    }
  }

  /**
   * 시공 여부
   */
  public get buildingSelected(): boolean {
    return this._buildingSelected;
  }
  //
  public set buildingSelected(value: boolean) {
    this._buildingSelected = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
      this.isDesignChanged = true;

      // 알고리즘
      this.algorithm_bendingExpenseFlag();
    }
  }

  /**
   * 정부사업 여부
   */
  public get subsidySelected(): boolean {
    return this._subsidySelected;
  }
  //
  public set subsidySelected(value: boolean) {
    this._subsidySelected = value;

    if (this.isDeepCopying === false) {
      this.isApplyChanged = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.basicLevelAC = [new BasicLevel(), new BasicLevel(), new BasicLevel(), new BasicLevel()];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design): void {
    this.design = design;

    for (const basicLevel of this.basicLevelAC) {
      basicLevel.setAssociation(design, this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    for (const basicLevel of this.basicLevelAC) {
      basicLevel.setReferenceVariable();
    }
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param struct 구조명
   */
  public setDefaultData(struct: string = CONST.LB_STRUCT_SINGLE): void {
    if (struct === CONST.LB_STRUCT_SINGLE) {
      // 25mm 단동
      this._structureName = CONST.LB_STRUCT_SINGLE;
      this._buildingNumber = 1;
      this._levelNumber = 1;
      this._buildingInterval = 1.0;
      this._levelInterval = 0.25;
      this._area = 314;
      this._pyeong = 95;
      this._isVariableLength = false;
      this._bendingExpenseFlag = true;
      this._padVinylFlag = true;
      this._buildingSelected = false;
      this._subsidySelected = false;

      this.basicLevelAC[0].setDefaultData(0, 5.7, 55.0, 1.5, 0.3, 0.3, 6.4, 1.4, 2.9, 10.0);
      this.basicLevelAC[1].setDefaultData(1, 5.2, 54.0, 1.3, 0.3, 0.3, 5.8, 1.2, 2.5, 9.0);
      this.basicLevelAC[2].setDefaultData(2, 4.7, 53.0, 1.1, 0.3, 0.3, 5.2, 1.1, 2.2, 8.0);
      this.basicLevelAC[3].setDefaultData(3, 4.2, 52.0, 0.9, 0.3, 0.3, 4.6, 0.9, 1.8, 7.0);
    } else if (struct === CONST.LB_STRUCT_DOUBLE_WIDTH) {
      // 광폭
      this._structureName = CONST.LB_STRUCT_DOUBLE_WIDTH;
      this._buildingNumber = 1;
      this._levelNumber = 1;
      this._buildingInterval = 1.0;
      this._levelInterval = 0.25;
      this._area = 314;
      this._pyeong = 95;
      this._isVariableLength = false;
      this._bendingExpenseFlag = true;
      this._padVinylFlag = true;
      this._buildingSelected = false;
      this._subsidySelected = false;

      this.basicLevelAC[0].setDefaultData(0, 5.7, 55.0, 1.5, 0.3, 0.3, 6.4, 1.4, 2.9, 10.0);
      this.basicLevelAC[1].setDefaultData(1, 5.2, 54.0, 1.3, 0.3, 0.3, 5.8, 1.2, 2.5, 9.0);
      this.basicLevelAC[2].setDefaultData(2, 4.7, 53.0, 1.1, 0.3, 0.3, 5.2, 1.1, 2.2, 8.0);
      this.basicLevelAC[3].setDefaultData(3, 4.2, 52.0, 0.9, 0.3, 0.3, 4.6, 0.9, 1.8, 7.0);
    } else if (struct === CONST.LB_STRUCT_RAIN_PROOF) {
      // 비가림
      this._structureName = CONST.LB_STRUCT_RAIN_PROOF;
      this._buildingNumber = 1;
      this._levelNumber = 1;
      this._buildingInterval = 1.0;
      this._levelInterval = 0.25;
      this._area = 314;
      this._pyeong = 95;
      this._isVariableLength = false;
      this._bendingExpenseFlag = true;
      this._padVinylFlag = true;
      this._buildingSelected = false;
      this._subsidySelected = false;

      this.basicLevelAC[0].setDefaultData(0, 5.7, 55.0, 1.5, 0.3, 0.3, 6.4, 1.4, 2.9, 10.0);
      this.basicLevelAC[1].setDefaultData(1, 5.2, 54.0, 1.3, 0.3, 0.3, 5.8, 1.2, 2.5, 9.0);
      this.basicLevelAC[2].setDefaultData(2, 4.7, 53.0, 1.1, 0.3, 0.3, 5.2, 1.1, 2.2, 8.0);
      this.basicLevelAC[3].setDefaultData(3, 4.2, 52.0, 0.9, 0.3, 0.3, 4.6, 0.9, 1.8, 7.0);
    } else if (struct === CONST.LB_STRUCT_INTERLOCK) {
      // 48mm 연동
      this._structureName = CONST.LB_STRUCT_INTERLOCK;
      this._buildingNumber = 3;
      this._levelNumber = 2;
      this._buildingInterval = 0;
      this._levelInterval = 0.25;
      this._area = 1008;
      this._pyeong = 305;
      this._isVariableLength = false;
      this._bendingExpenseFlag = true;
      this._padVinylFlag = true;
      this._buildingSelected = false;
      this._subsidySelected = false;

      this.basicLevelAC[0].setDefaultData(0, 7.0, 48.0, 3.0, 0.5, 0.5, 8.0, 1.9, 4.9, 8.0);
      this.basicLevelAC[1].setDefaultData(1, 7.0, 46.0, 2.7, 0.5, 0.5, 8.0, 1.9, 4.6, 8.0);
      this.basicLevelAC[2].setDefaultData(2, 7.0, 44.0, 2.4, 0.5, 0.5, 8.0, 1.9, 4.4, 8.0);
    } else if (struct === CONST.LB_STRUCT_ANGULAR_IL) {
      // 각연동
      this._structureName = CONST.LB_STRUCT_ANGULAR_IL;
      this._buildingNumber = 3;
      this._levelNumber = 2;
      this._buildingInterval = 0;
      this._levelInterval = 0.25;
      this._area = 1008;
      this._pyeong = 305;
      this._isVariableLength = false;
      this._bendingExpenseFlag = true;
      this._padVinylFlag = true;
      this._buildingSelected = false;
      this._subsidySelected = false;

      this.basicLevelAC[0].setDefaultData(0, 7.0, 48.0, 3.0, 0.5, 0.5, 8.0, 1.9, 4.9, 8.0);
      this.basicLevelAC[1].setDefaultData(1, 7.0, 46.0, 2.7, 0.5, 0.5, 8.0, 1.9, 4.6, 8.0);
      this.basicLevelAC[2].setDefaultData(2, 7.0, 44.0, 2.4, 0.5, 0.5, 8.0, 1.9, 4.4, 8.0);
    } else if (struct === CONST.LB_STRUCT_VENLO) {
      // 벤로
      this._structureName = CONST.LB_STRUCT_VENLO;
      this._buildingNumber = 3;
      this._levelNumber = 1;
      this._buildingInterval = 0;
      this._levelInterval = 0.25;
      this._area = 1008;
      this._pyeong = 305;
      this._isVariableLength = false;
      this._bendingExpenseFlag = true;
      this._padVinylFlag = true;
      this._buildingSelected = false;
      this._subsidySelected = false;

      this.basicLevelAC[0].setDefaultData(0, 8.0, 48.0, 3.0, 0.5, 0.5, 8.0, 1.9, 4.9, 8.0);
      this.basicLevelAC[1].setDefaultData(1, 8.0, 46.0, 0.0, 0.5, 0.5, 8.0, 1.9, 4.6, 8.0);
    } else if (struct === CONST.LB_STRUCT_OTHER) {
      // 기타
      this._structureName = CONST.LB_STRUCT_OTHER;
      this._buildingNumber = 1;
      this._levelNumber = 1;
      this._buildingInterval = 1.0;
      this._levelInterval = 0.25;
      this._area = 330;
      this._pyeong = 100;
      this._isVariableLength = false;
      this._bendingExpenseFlag = false;
      this._padVinylFlag = false;
      this._buildingSelected = false;
      this._subsidySelected = false;

      this.basicLevelAC[0].setDefaultData(0, 6.0, 55.0, 1.0, 0.1, 0.1, 0, 0, 0, 0);
      this.basicLevelAC[1].setDefaultData(1, 6.0, 55.0, 1.0, 0.1, 0.1, 0, 0, 0, 0);
      this.basicLevelAC[2].setDefaultData(2, 6.0, 55.0, 1.0, 0.1, 0.1, 0, 0, 0, 0);
      this.basicLevelAC[3].setDefaultData(3, 6.0, 55.0, 1.0, 0.1, 0.1, 0, 0, 0, 0);
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    for (const basicLevel of this.basicLevelAC) {
      basicLevel.setDefaultVariable();
    }
  }

  /**
   * 기본 모델 설정: 데이터베이스를 대신함
   */
  public setDefaultModel(): void {}

  /**
   * 저장된 아이디값 세팅
   */
  public setSavedId(savedBasic: Basic): void {
    if (savedBasic != null) {
      this.id = savedBasic.id;

      if (this.basicLevelAC != null && savedBasic.basicLevelAC != null) {
        for (let i: number = 0; i < this.basicLevelAC.length; i++) {
          // 기존 배열길이만큼 할당하되 저장된 배열길이가 더 작을경우는 할당하지 않음
          if (i < savedBasic.basicLevelAC.length) {
            this.basicLevelAC[i].setSavedId(savedBasic.basicLevelAC[i]);
          }
        }
      }
    }
  }

  /**
   * 최신 객체로 복원
   */
  public restoreLatestObject(design: Design): void {
    if (
      design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      this.basicLevelAC.splice(3, 1);
    } else if (design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      this.basicLevelAC.splice(2, 2);
    }
  }

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * @param origin 원본 객체
   */
  public deepCopy(origin: Basic): void {
    this.isDeepCopying = true;

    // Remote Variables
    // 아이디, 수정일시 등은 복사안함
    this._structureName = origin.structureName;
    this._buildingNumber = origin.buildingNumber;
    this._levelNumber = origin.levelNumber;
    this._buildingInterval = origin.buildingInterval;
    this._levelInterval = origin.levelInterval;
    this._area = origin.area;
    this._pyeong = origin.pyeong;
    this._wingLeft = origin.wingLeft;
    this._wingRight = origin.wingRight;
    this._wingFront = origin.wingFront;
    this._wingBack = origin.wingBack;
    this._isVariableLength = origin.isVariableLength;

    this._bendingExpenseFlag = origin.bendingExpenseFlag;
    this._padVinylFlag = origin.padVinylFlag;

    this._buildingSelected = origin.buildingSelected;
    this._subsidySelected = origin.subsidySelected;

    // 중수별 기본정보
    for (let i: number = 0; origin.basicLevelAC && i < origin.basicLevelAC.length; i++) {
      this.basicLevelAC[i].deepCopy(origin.basicLevelAC[i]);
    }

    this.isDeepCopying = false;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  public algorithmBasic(): void {
    this.algorithm_levelActivation();

    for (const basicLevel of this.basicLevelAC) {
      basicLevel.algorithmBasic();
    }
  }

  /**
   * 알고리즘: 중수 활성화 <- 중수(기본정보)
   */
  public algorithm_levelActivation(): void {
    for (const work of this.design.struct.workAC) {
      this.changeLevelActivation(work);
    }
  }

  /**
   * 알고리즘: 면적 <- 폭, 길이, 동수
   */
  public algorithm_area(): void {
    this.area = CommonUtil.roundUp(
      (this.basicLevelAC[0].width * this.buildingNumber + (this.wingLeft + this.wingRight)) *
        (this.basicLevelAC[0].length + (this.wingFront + this.wingBack)),
      0,
    );
    this.pyeong = CommonUtil.roundUp(this.area * 0.3025, 0);
  }

  /**
   * 알고리즘: 폭 <- 폭, 중 간격
   * @param levelIndex 중수 인덱스
   */
  public algorithm_widthByLevel(levelIndex: number = 0): void {
    let i: number;
    if (
      this.structureName === CONST.LB_STRUCT_SINGLE ||
      this.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      for (i = levelIndex; i < CONST.NUM_SINGLE_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].width = this.basicLevelAC[i].width - this.levelInterval * 2;
      }
    } else if (this.structureName === CONST.LB_STRUCT_INTERLOCK || this.structureName === CONST.LB_STRUCT_ANGULAR_IL) {
      for (i = levelIndex; i < CONST.NUM_INTERLOCK_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].width = this.basicLevelAC[i].width;
      }
    } else if (this.structureName === CONST.LB_STRUCT_VENLO) {
      for (i = levelIndex; i < CONST.NUM_VENLO_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].width = this.basicLevelAC[i].width;
      }
    } else {
    }
  }

  /**
   * 알고리즘: 길이 <- 길이
   * @param levelIndex 중수 인덱스
   */
  public algorithm_lengthByLevel(levelIndex: number = 0): void {
    let i: number;
    if (
      this.structureName === CONST.LB_STRUCT_SINGLE ||
      this.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      for (i = levelIndex; i < CONST.NUM_SINGLE_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].length = this.basicLevelAC[i].length - 1.0;
      }
    } else if (this.structureName === CONST.LB_STRUCT_INTERLOCK || this.structureName === CONST.LB_STRUCT_ANGULAR_IL) {
      for (i = levelIndex; i < CONST.NUM_INTERLOCK_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].length = this.basicLevelAC[i].length - 2.0;
      }
    } else if (this.structureName === CONST.LB_STRUCT_VENLO) {
      for (i = levelIndex; i < CONST.NUM_VENLO_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].length = this.basicLevelAC[i].length - 2.0;
      }
    } else {
    }
  }

  /**
   * 알고리즘: 측고 <- 측고, 중 간격
   * @param levelIndex 중수 인덱스
   */
  public algorithm_heightShoulderByLevel(levelIndex: number = 0): void {
    let i: number;
    if (
      this.structureName === CONST.LB_STRUCT_SINGLE ||
      this.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      for (i = levelIndex; i < CONST.NUM_SINGLE_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].heightShoulder = this.basicLevelAC[i].heightShoulder - 0.2;
      }
    } else if (this.structureName === CONST.LB_STRUCT_INTERLOCK || this.structureName === CONST.LB_STRUCT_ANGULAR_IL) {
      for (i = levelIndex; i < CONST.NUM_INTERLOCK_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].heightShoulder = this.basicLevelAC[i].heightShoulder - this.levelInterval;
      }
    } else if (this.structureName === CONST.LB_STRUCT_VENLO) {
      for (i = levelIndex; i < CONST.NUM_VENLO_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].heightShoulder = this.basicLevelAC[i].heightShoulder - this.levelInterval;
      }
    } else {
    }
  }

  /**
   * 알고리즘: 매설 <- 매설(1중)
   */
  public algorithm_depthLayingByLevel1(): void {
    if (
      this.structureName === CONST.LB_STRUCT_SINGLE ||
      this.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.basicLevelAC[1].depthLaying = this.basicLevelAC[0].depthLaying;
      this.basicLevelAC[2].depthLaying = this.basicLevelAC[1].depthLaying;
      this.basicLevelAC[3].depthLaying = this.basicLevelAC[2].depthLaying;
    } else if (this.structureName === CONST.LB_STRUCT_INTERLOCK || this.structureName === CONST.LB_STRUCT_ANGULAR_IL) {
      this.basicLevelAC[1].depthLaying = this.basicLevelAC[0].depthLaying;
      this.basicLevelAC[2].depthLaying = this.basicLevelAC[1].depthLaying;
    } else if (this.structureName === CONST.LB_STRUCT_VENLO) {
      this.basicLevelAC[1].depthLaying = this.basicLevelAC[0].depthLaying;
    } else {
    }
  }

  /**
   * 알고리즘: 가변길이 여부 <- 동수
   */
  public algorithm_isVariableLengthByBuildingNumber(): void {
    if (this.buildingNumber === 1 || this.buildingNumber > CONST.NUM_VARIABLE_LENGTH_BUILDING_MAX) {
      this.isVariableLength = false;
    }
  }

  /**
   * 알고리즘: 길이 <- 동수별 길이
   * @param levelIndex 중수 인덱스
   */
  public algorithm_lengthByLevelBuilding(levelIndex: number = 0, buildingIndex: number = 0): void {
    let i: number;
    if (
      this.structureName === CONST.LB_STRUCT_SINGLE ||
      this.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      for (i = levelIndex; i < CONST.NUM_SINGLE_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].basicBuildingAC[buildingIndex].length =
          this.basicLevelAC[i].basicBuildingAC[buildingIndex].length - 1.0;
      }
    } else if (this.structureName === CONST.LB_STRUCT_INTERLOCK || this.structureName === CONST.LB_STRUCT_ANGULAR_IL) {
      for (i = levelIndex; i < CONST.NUM_INTERLOCK_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].basicBuildingAC[buildingIndex].length =
          this.basicLevelAC[i].basicBuildingAC[buildingIndex].length - 1.0;
      }
    } else if (this.structureName === CONST.LB_STRUCT_VENLO) {
      for (i = levelIndex; i < CONST.NUM_VENLO_LEVEL_MAX - 1; i++) {
        this.basicLevelAC[i + 1].basicBuildingAC[buildingIndex].length =
          this.basicLevelAC[i].basicBuildingAC[buildingIndex].length - 1.0;
      }
    } else {
    }
  }

  /**
   * 알고리즘: 벤딩비 플래그 <- 시공 여부
   */
  public algorithm_bendingExpenseFlag(): void {
    if (this.buildingSelected === false) {
      // 시공을 하지 않는 경우, 파이프 벤딩비를 산출해야함
      this.bendingExpenseFlag = true;
    } else {
      // 시공을 하는 경우, 파이프 벤딩비를 산출하는 것은 운송 규모(3.5톤차 기준 약70개)에 따라 달라짐
      // - 벤딩 파이프의 양이 한 트럭미만인 경우, 벤딩비 산출함 - 유통사에서 벤딩하여 제공
      // - 벤딩 파이프의 양이 한 트럭이상인 경우, 벤딩비 산출안함 - 시공사가 현장에서 벤딩 (시공비 증가)
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 작업별 중수 선택 변경하기
   * @param work 작업
   */
  protected changeLevelActivation(work: Work): void {
    const curCoverWorkIL = this.design.struct.curCoverWorkIL;
    const curSwitchWorkIL = this.design.struct.curSwitchWorkIL;

    if (work.levelAC != null && work.levelAC.length > 0) {
      for (let i: number = 0; i < work.levelAC.length; i++) {
        // (연동)커튼 피복, 커튼 개폐인 경우 기본 정보 중수 영향받지 않게 함
        if (work === curCoverWorkIL || work === curSwitchWorkIL) {
          work.levelAC[i].algorithm_selectedByPosition();
        } else {
          if (this.levelNumber > i) {
            work.levelAC[i].algorithm_selectedByPosition();
          } else {
            work.levelAC[i].selected = false;
          }
        }
      }
    }
  }
}
