import { jsonObject } from "typedjson";

import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { GutterPartIL_DrainagePVCPipe } from "vhows-design/src/object/design/frame/gutter/il/GutterPartIL_DrainagePVCPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-11-20
 */
@jsonObject
export class GutterSampleIL_DrainagePVCPipe extends ItemDrainPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const drainagePVCPipePart: GutterPartIL_DrainagePVCPipe = <GutterPartIL_DrainagePVCPipe>this.part;
    drainagePVCPipePart.algorithm_pvcPipe_pvcPipe();
    drainagePVCPipePart.algorithm_pvcPipe_pvcSocket();
    drainagePVCPipePart.algorithm_pvcPipe_pvcElbow();
    drainagePVCPipePart.algorithm_pvcPipe_pvcDegree45Elbow();
    drainagePVCPipePart.algorithm_pvcPipe_pvcExactT();
    drainagePVCPipePart.algorithm_pvcPipe_drainspoutBox();
    drainagePVCPipePart.algorithm_pvcPipe_drainPipeHanger();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }
}
