import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { RoofPartVL_Straight } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Straight";
import { RoofPartVL_RoofBracing } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_RoofBracing";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { RoofPartVL_Rafter } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Rafter";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { ColumnPositionVL } from "vhows-design/src/object/design/frame/column/vl/ColumnPositionVL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-11
 */
@jsonObject
export class RoofSampleVL_RafterPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // 길이
  // @override
  public get specLength(): number {
    return this._specLength;
  }

  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 0.1m 간격(소수점 둘째자리 올림)
    this._specLength = CommonUtil.roundUp(value, 1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      (<RoofPartVL_Rafter>this.part).algorithm_rafterTotalLength();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const rafterPart: RoofPartVL_Rafter = <RoofPartVL_Rafter>this.part;
    rafterPart.algorithmSpec_rafter_pipe();
    rafterPart.algorithmSpec_rafter_pipeBendingExpense();
    rafterPart.algorithmSpec_rafterAndBeamCenter_firstLevelPairSconce();
    rafterPart.algorithmSpec_rafterAndBeamEdge_firstLevelSoleSconce();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const roofStraightPart: RoofPartVL_Straight = (<RoofPositionVL>this.position).straightPart;
    roofStraightPart.algorithmSpec_straightAndRafter_steelStringGrabber();
    roofStraightPart.algorithmSpec_straightAndRafter_steelSheetGrabber();
    roofStraightPart.algorithmSpec_straightAndRafter_crossSteelSheetGrabber();
    roofStraightPart.algorithmSpec_straightAndRafter_lineSheetGrabber();

    const roofBracingPart: RoofPartVL_RoofBracing = (<RoofPositionVL>this.position).roofBracingPart;
    roofBracingPart.algorithmSpec_roofBracingAndRafter_steelStringGrabber();

    /// //////// 외부 ///////////

    // 보
    const columnPosition: ColumnPositionVL = this.struct.columnWorkVL.level1.columnPosition;
    columnPosition.prismBeamPart.algorithmSpec_gutterBeamAndRoof_archSconce();

    // 방풍벽
    if (this.level.index === 0 || this.level.index === 1) {
      const wingPosition: WingPositionVL = (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition;
      // wingPosition.wingRafterPart.wingRafterPipeSample.specsList = this.specsList;
      wingPosition.wingRafterPart.algorithmSpec_wingRafterAndRoofRafter_connectionPin();
      wingPosition.wingRafterPart.algorithmPart();
    }

    // 마구리
    for (const endpiecePosition of this.struct.endpieceWorkVL.levelAC[this.level.index]
      .positionAC as EndpiecePositionVL[]) {
      endpiecePosition.endpiecePart.barPipeSample.algorithmSpec();
      endpiecePosition.endpiecePart.algorithmSpec_studAndRafterCenter_tHolder();
      endpiecePosition.endpiecePart.algorithmSpec_studAndRafterCenterRafter_normalHolder();
      endpiecePosition.endpiecePart.algorithmSpec_studAndRafterDiagonal_diagonalTHolder();
      endpiecePosition.endpiecePart.algorithmSpec_studAndRafterDiagonal_diagonalTClamp();
      endpiecePosition.endpiecePart.algorithmSpec_studAndRafterDiagonalRafter_normalHolder();
      endpiecePosition.endpiecePart.algorithmSpec_straightUpperAndRafter_diagonalTHolder();
      endpiecePosition.endpiecePart.algorithmSpec_straightUpperAndRafterRafter_normalHolder();
    }

    // 물받이
    const gutterLevel: GutterLevelIL = <GutterLevelIL>this.struct.gutterWorkIL.levelAC[this.level.index];
    gutterLevel.gutterPosition.condensationGutterPart.algorithmSpec_condensationGutter_gutterHanger();

    // 밴드끈
    if (this.level.index === 0) {
      for (const bandstringPosition of this.struct.bandstringWorkIL.levelAC[this.level.index]
        .positionAC as BandstringPositionIL[]) {
        bandstringPosition.fixingHookPart.hookHolderSample.algorithmSpec();
      }
    }

    // 개폐기
    const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];
    switcherLevel.roofPosition.axisPart.algorithmSpec_axis_slopeClip();
    //
    // 피복 고정
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.struct.fixingWorkIL.levelAC[this.level.index];
    fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipRafter_normalClip();
    fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipRafter_normalClip();

    // 치마 피복
    const skirtLevel: SkirtLevelIL = <SkirtLevelIL>this.struct.skirtWorkIL.levelAC[this.level.index];
    skirtLevel.roofPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();

    // 바람막이 피복
    const windbreakLevel: WindbreakLevelIL = <WindbreakLevelIL>this.struct.windbreakWorkIL.levelAC[this.level.index];
    windbreakLevel.roofPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();

    // 천창 개폐
    this.struct.skySwitchWorkIL.level1.skylightPosition.rackpinionPart.algorithmSpec_rackpinion_skylightMotor();
    this.struct.skySwitchWorkIL.level1.skylightPosition.rackpinionPart.algorithmSpec_rackpinionAaxisHolder_bearingSet();

    // 관수 지관부
    const wateringPosition: WateringPosition = this.struct.wateringWork.level1.wholePosition;
    wateringPosition.miniklerScreenPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.miniklerFountainPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.miniklerFogPart.algorithmSpec_miniklerHangerGrabber_steelStringGrabber();
    wateringPosition.hoseScreenPart.algorithmSpec_hose_fountainHoseHangerScreen();
    wateringPosition.hoseSidePart.algorithmSpec_hose_fountainHoseHangerSide();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  /**
   * 규격 알고리즘: 단위길이 <- 서까래 길이 이상값(기본정보), 서까래 길이 반올림 선택
   */
  public algorithmSpec_specLength(): void {
    const rafterPart: RoofPartVL_Rafter = <RoofPartVL_Rafter>this.part;
    let length: number;

    length = this.basicLevel.lengthRafterIdeal;

    if (rafterPart.rafterLengthRoundSelected === true) {
      length = Math.round(length);
    }

    this.specLength = length;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
