import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemSwitcherAssist } from "vhows-design/src/object/design/item/list/ItemSwitcherAssist";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { CurSwitchSampleIL_VerticalSupPipe } from "vhows-design/src/object/design/curtain/switches/CurSwitchSampleIL_VerticalSupPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurSwitchSampleIL_VerticalSupPipe],
})
export class CurSwitchPartIL_SupVertical extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public verticalSupportPipeSample: CurSwitchSampleIL_VerticalSupPipe; // 커튼 수직형 지지 파이프 샘플

  // 아이템
  public verticalSupport_pipe: ItemPipe;
  public verticalSupport_axisRoller: ItemSwitcherAssist;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _supportLength: number; // 지지 길이

  /**
   * 지지 길이
   */
  public get supportLength(): number {
    return this._supportLength;
  }

  //
  public set supportLength(value: number) {
    this._supportLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.verticalSupportPipeSample = new CurSwitchSampleIL_VerticalSupPipe();

    this.sampleAC = [this.verticalSupportPipeSample];

    // 아이템
    this.verticalSupport_pipe = new ItemPipe();
    this.verticalSupport_axisRoller = new ItemSwitcherAssist();

    this.itemAC = [this.verticalSupport_pipe, this.verticalSupport_axisRoller];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.verticalSupportPipeSample = <CurSwitchSampleIL_VerticalSupPipe>this.sampleAC[0];

    // 아이템
    this.verticalSupport_pipe = <ItemPipe>this.itemAC[0];
    this.verticalSupport_axisRoller = <ItemSwitcherAssist>this.itemAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param supportLength: number 지지 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    supportLength: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._supportLength = supportLength;

    // 샘플
    if (this.level.index >= 0) {
      this.verticalSupportPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 수직형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.verticalSupport_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "커튼 수직형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.verticalSupport_axisRoller.setDefaultData(
        1,
        CONST.ITEM_ID_AXIS_ROLLER,
        CONST.ITEM_NAME_AXIS_ROLLER,
        "커튼 수직형 지지",
        "25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curSwitchPosition: CurSwitchPositionIL = <CurSwitchPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.verticalSupport_pipe.selected = true;
    this.verticalSupport_pipe.visible = true;
    this.verticalSupport_axisRoller.selected = true;
    this.verticalSupport_axisRoller.visible = true;

    /// //////// 수량 ///////////

    this.verticalSupport_pipe.designQuantity =
      (this.supportLength * curSwitchPosition.switchWayValue) / this.verticalSupport_pipe.specLength;

    this.verticalSupport_axisRoller.designQuantity = curSwitchPosition.switchWayValue;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [커튼 수직형 지지] 파이프 <- 커튼 수직형 지지 파이프 샘플
   */
  public algorithmSpec_verticalSupport_pipe(): void {
    this.verticalSupport_pipe.specs = this.verticalSupportPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [커튼 수직형 지지] 축 롤러 <- 커튼 수직형 지지 파이프 샘플
   */
  public algorithmSpec_verticalSupport_axisRoller(): void {
    this.verticalSupport_axisRoller.specCrossSize = this.verticalSupportPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
