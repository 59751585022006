import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemScreennet } from "vhows-design/src/object/design/item/list/ItemScreennet";
import { ScreennetPart_Screennet } from "vhows-design/src/object/design/cover/screennet/ScreennetPart_Screennet";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-03-17
 */
@jsonObject
export class ScreennetSample_Screennet extends ItemScreennet {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const screennetPart: ScreennetPart_Screennet = <ScreennetPart_Screennet>this.part;
    screennetPart.algorithmSpec_screennet_screennet();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 천창 피복
    if (this.position.label === CONST.LB_POSITION_SIDE) {
      this.struct.skyCoverWork.level1.skylightPosition.screennetPart.skyScreennetSample.algorithmSpec();
    }
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 방충망 폭(위치)
   */
  // @override
  public algorithmSpec(): void {
    // 하드코딩
    // 폭 1.5m보다 큰 경우만 따르고, 작은 경우는 초기값(1.5m)으로 고정
    if ((<ScreennetPart_Screennet>this.part).screennetWidth > 1.5) {
      this.specWidth = CommonUtil.roundUpX((<ScreennetPart_Screennet>this.part).screennetWidth, 0.5);
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
