import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { SwitcherSampleIL_AxisPipe } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_AxisPipe";
import { SwitcherSampleIL_AxisPipe2 } from "vhows-design/src/object/design/switches/switcher/il/SwitcherSampleIL_AxisPipe2";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject({
  knownTypes: [SwitcherSampleIL_AxisPipe, SwitcherSampleIL_AxisPipe2],
})
export class SwitcherPartIL_Axis extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public coverLevel: CoverLevelIL; // 기초 피복 중수

  // 샘플
  public axisPipeSample: SwitcherSampleIL_AxisPipe; // 개폐 축 파이프 샘플
  public axisPipe2Sample: SwitcherSampleIL_AxisPipe2; // 개폐 축 파이프 샘플(2차)

  // 아이템
  public axis_pipe: ItemPipe;
  public axis_pinnedPipe: ItemPipe; // 사용 안함
  public axis_connectionPin: ItemConnector;
  public axis2_pipe: ItemPipe;
  public axis2_pinnedPipe: ItemPipe; // 사용 안함
  public axis2_connectionPin: ItemConnector;
  public axis_screw: ItemScrew;
  public axis_slopeClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 개폐 최대 차수 - 뷰의 데이터변경 이벤트 호출용 변수 */
  public switchOrderMax: number;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _axisLength: number; // 개폐 축 길이
  @jsonMember(Boolean)
  public _slopeClipSelectedRoof: boolean; // 기울기 클립 선택(지붕)

  /**
   * 개폐 축 길이
   */
  public get axisLength(): number {
    return this._axisLength;
  }

  public set axisLength(value: number) {
    this._axisLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 기울기 클립 선택(지붕)
   */
  public get slopeClipSelectedRoof(): boolean {
    return this._slopeClipSelectedRoof;
  }

  //
  public set slopeClipSelectedRoof(value: boolean) {
    this._slopeClipSelectedRoof = value;

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.axisPipeSample = new SwitcherSampleIL_AxisPipe();
    this.axisPipe2Sample = new SwitcherSampleIL_AxisPipe2();

    this.sampleAC = [this.axisPipeSample, this.axisPipe2Sample];

    // 아이템
    this.axis_pipe = new ItemPipe();
    this.axis_pinnedPipe = new ItemPipe();
    this.axis_connectionPin = new ItemConnector();
    this.axis2_pipe = new ItemPipe();
    this.axis2_pinnedPipe = new ItemPipe();
    this.axis2_connectionPin = new ItemConnector();
    this.axis_screw = new ItemScrew();
    this.axis_slopeClip = new ItemClip();

    this.itemAC = [
      this.axis_pipe,
      this.axis_pinnedPipe,
      this.axis_connectionPin,
      this.axis2_pipe,
      this.axis2_pinnedPipe,
      this.axis2_connectionPin,
      this.axis_screw,
      this.axis_slopeClip,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.axisPipeSample = <SwitcherSampleIL_AxisPipe>this.sampleAC[0];
    this.axisPipe2Sample = <SwitcherSampleIL_AxisPipe2>this.sampleAC[1];

    // 아이템
    this.axis_pipe = <ItemPipe>this.itemAC[0];
    this.axis_pinnedPipe = <ItemPipe>this.itemAC[1];
    this.axis_connectionPin = <ItemConnector>this.itemAC[2];
    this.axis2_pipe = <ItemPipe>this.itemAC[3];
    this.axis2_pinnedPipe = <ItemPipe>this.itemAC[4];
    this.axis2_connectionPin = <ItemConnector>this.itemAC[5];
    this.axis_screw = <ItemScrew>this.itemAC[6];
    this.axis_slopeClip = <ItemClip>this.itemAC[7];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param slopeClipSelectedRoof: boolean 기울기 클립 선택(지붕)
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    slopeClipSelectedRoof: boolean = false,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._slopeClipSelectedRoof = slopeClipSelectedRoof;

    // 샘플
    if (this.level.index >= 0) {
      this.axisPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.axisPipe2Sample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.axis_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.axis_pinnedPipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축",
        "원형, 핀가공, 25.4mm, 1.5T, 6m, -",
      );
      this.axis_connectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "개폐 축",
        "원형, 일반, 25mm, 25mm",
      );
      this.axis2_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.axis2_pinnedPipe.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 핀가공, 25.4mm, 1.5T, 6m, -",
      );
      this.axis2_connectionPin.setDefaultData(
        5,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "개폐 축(2차)",
        "원형, 일반, 25mm, 25mm",
      );
      this.axis_screw.setDefaultData(
        6,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "개폐 축",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.axis_slopeClip.setDefaultData(
        7,
        CONST.ITEM_ID_SLOPE_CLIP,
        CONST.ITEM_NAME_SLOPE_CLIP,
        "개폐 축",
        "플라스틱, 32mm",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.init_coverLevel();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 샘플
    if (this.sampleAC.length === 1) {
      // 개폐 축 파이프 샘플(2차) 추가
      const axisPipe2Sample: SwitcherSampleIL_AxisPipe2 = new SwitcherSampleIL_AxisPipe2();
      axisPipe2Sample.setAssociation(design, struct, work, level, position, this);
      axisPipe2Sample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.sampleAC.push(axisPipe2Sample);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.sampleAC);
    }

    // 아이템
    if (this.itemAC.length === 5 && this.itemAC[3] instanceof ItemScrew) {
      // [개폐 축(2차)] 파이프 추가
      const axis2_pipe: ItemPipe = new ItemPipe();
      axis2_pipe.setAssociation(design, struct, work, level, position, this);
      axis2_pipe.setDefaultData(
        3,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.itemAC.splice(3, 0, axis2_pipe);
      // [개폐 축(2차)] 파이프 추가
      const axis2_pinnedPipe: ItemPipe = new ItemPipe();
      axis2_pinnedPipe.setAssociation(design, struct, work, level, position, this);
      axis2_pinnedPipe.setDefaultData(
        4,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "개폐 축(2차)",
        "원형, 핀가공, 25.4mm, 1.5T, 6m, -",
      );
      this.itemAC.splice(4, 0, axis2_pinnedPipe);
      // [개폐 축(2차)] 연결핀 추가
      const axis2_connectionPin: ItemConnector = new ItemConnector();
      axis2_connectionPin.setAssociation(design, struct, work, level, position, this);
      axis2_connectionPin.setDefaultData(
        5,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "개폐 축(2차)",
        "원형, 일반, 25mm, 25mm",
      );
      this.itemAC.splice(5, 0, axis2_connectionPin);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_axisLength();
    this.algorithm_slopeClipSelectedRoof();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>this.position;
    this.switchOrderMax = switcherPosition.switchOrderMax;
    let bothEndQuantity: number;
    let centralQuantity: number;
    // 외부
    let roofPosition: RoofPositionIL | RoofPositionVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      roofPosition = (<RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index]).roofPosition;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      roofPosition = this.struct.roofWorkVL.level1.roofPosition;
    }

    /// //////// 선택, 가시성 ///////////

    this.axis_pipe.checkSelected(true);
    this.axis_pinnedPipe.selected = false;
    this.axis_pinnedPipe.visible = false;
    this.axis_connectionPin.checkSelected(true);
    this.axis2_pinnedPipe.checkSelected(true);
    if (switcherPosition.switchOrderMax === CONST.VL_SWITCHER_ORDER_2) {
      this.axis2_pipe.checkSelected(true);
      this.axis2_connectionPin.checkSelected(true);
    } else {
      this.axis2_pipe.checkSelected(false);
      this.axis2_connectionPin.checkSelected(false);
    }
    this.axis_screw.selected = true;
    this.axis_screw.visible = true;
    this.axis_slopeClip.selected = this.slopeClipSelectedRoof;
    this.axis_slopeClip.visible = this.slopeClipSelectedRoof;

    /// //////// 수량 ///////////

    if (
      switcherPosition.label === CONST.LB_POSITION_SIDE_COLUMN ||
      switcherPosition.label === CONST.LB_POSITION_SIDE_WING
    ) {
      // 1차
      this.axis_pipe.designQuantity =
        (this.axis_pipe.getConnectedPipeLength(
          this.axisLength,
          switcherPosition.switchWayValue,
          this.axis_pipe.specLength / 2,
        ) /
          this.axis_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;
      this.axis_pinnedPipe.designQuantity = 0;

      // 2차
      this.axis2_pipe.designQuantity =
        (this.axis2_pipe.getConnectedPipeLength(
          this.axisLength,
          switcherPosition.switchWayValue,
          this.axis2_pipe.specLength / 2,
        ) /
          this.axis2_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;
      this.axis2_pinnedPipe.designQuantity = 0;

      if (switcherPosition.switchStep === CONST.VL_SWITCHER_ORDER_2) {
        this.axis_pipe.designQuantity *= switcherPosition.switchStep;
        this.axis2_pipe.designQuantity *= switcherPosition.switchStep;

        // 1차
        if (this.axisLength > this.axisPipeSample.specLength) {
          this.axis_connectionPin.designQuantity =
            this.axis_pipe.getSelectedQuantity() * switcherPosition.switchStep * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
        } else {
          this.axis_connectionPin.designQuantity = 0;
        }

        // 2차
        if (this.axisLength > this.axisPipe2Sample.specLength) {
          this.axis2_connectionPin.designQuantity =
            this.axis2_pipe.getSelectedQuantity() * switcherPosition.switchStep * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
        } else {
          this.axis2_connectionPin.designQuantity = 0;
        }

        this.axis_screw.designQuantity =
          ((this.axis_connectionPin.getSelectedQuantity() * 4 + this.axis2_connectionPin.getSelectedQuantity() * 4) /
            this.axis_screw.specUnitQuantity) *
          switcherPosition.switchStep *
          CONST.NUM_EXTRA_RATE_SCREW;
      }
    } else if (switcherPosition.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        if (this.level.index === 0) {
          // 1차
          bothEndQuantity =
            this.axis_pipe.getConnectedPipeLength(this.axisLength, 2, this.axis_pipe.specLength / 2) /
            this.axis_pipe.specLength;
          centralQuantity =
            this.axis_pipe.getConnectedPipeLength(
              this.axisLength,
              switcherPosition.switchWayValue * (this.basic.buildingNumber - 2),
              this.axis_pipe.specLength / 2,
            ) / this.axis_pipe.specLength;
          this.axis_pipe.designQuantity = (bothEndQuantity + centralQuantity) * CONST.NUM_EXTRA_RATE_AXIS_PIPE;
          this.axis_pinnedPipe.designQuantity = 0;

          // 2차
          bothEndQuantity =
            this.axis2_pipe.getConnectedPipeLength(this.axisLength, 2, this.axis2_pipe.specLength / 2) /
            this.axis2_pipe.specLength;
          centralQuantity =
            this.axis2_pipe.getConnectedPipeLength(
              this.axisLength,
              switcherPosition.switchWayValue * (this.basic.buildingNumber - 2),
              this.axis2_pipe.specLength / 2,
            ) / this.axis2_pipe.specLength;
          this.axis2_pipe.designQuantity = (bothEndQuantity + centralQuantity) * CONST.NUM_EXTRA_RATE_AXIS_PIPE;
          this.axis2_pinnedPipe.designQuantity = 0;
        } else {
          // 1차
          this.axis_pipe.designQuantity =
            (this.axis_pipe.getConnectedPipeLength(
              this.axisLength,
              switcherPosition.switchWayValue * this.basic.buildingNumber,
              this.axis_pipe.specLength / 2,
            ) /
              this.axis_pipe.specLength) *
            CONST.NUM_EXTRA_RATE_AXIS_PIPE;
          this.axis_pinnedPipe.designQuantity = 0;

          // 2차
          this.axis2_pipe.designQuantity =
            (this.axis2_pipe.getConnectedPipeLength(
              this.axisLength,
              switcherPosition.switchWayValue * this.basic.buildingNumber,
              this.axis2_pipe.specLength / 2,
            ) /
              this.axis2_pipe.specLength) *
            CONST.NUM_EXTRA_RATE_AXIS_PIPE;
          this.axis2_pinnedPipe.designQuantity = 0;
        }
      } else {
        // 1차
        this.axis_pipe.designQuantity =
          (this.axis_pipe.getConnectedPipeLength(
            this.axisLength,
            switcherPosition.switchWayValue,
            this.axis_pipe.specLength / 2,
          ) /
            this.axis_pipe.specLength) *
          CONST.NUM_EXTRA_RATE_AXIS_PIPE;
        this.axis_pinnedPipe.designQuantity = 0;

        // 1차
        this.axis2_pipe.designQuantity =
          (this.axis2_pipe.getConnectedPipeLength(
            this.axisLength,
            switcherPosition.switchWayValue,
            this.axis2_pipe.specLength / 2,
          ) /
            this.axis2_pipe.specLength) *
          CONST.NUM_EXTRA_RATE_AXIS_PIPE;
        this.axis2_pinnedPipe.designQuantity = 0;
      }
    } else if (
      switcherPosition.label === CONST.LB_POSITION_FRONT ||
      switcherPosition.label === CONST.LB_POSITION_BACK
    ) {
      // 1차
      this.axis_pipe.designQuantity =
        (this.axis_pipe.getConnectedPipeLength(this.axisLength, 1, this.axis_pipe.specLength / 2) /
          this.axis_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;
      this.axis_pinnedPipe.designQuantity = 0;

      // 2차
      this.axis2_pipe.designQuantity =
        (this.axis2_pipe.getConnectedPipeLength(this.axisLength, 1, this.axis2_pipe.specLength / 2) /
          this.axis2_pipe.specLength) *
        CONST.NUM_EXTRA_RATE_AXIS_PIPE;
      this.axis2_pinnedPipe.designQuantity = 0;
    }

    // 1차
    if (this.axisLength > this.axisPipeSample.specLength) {
      this.axis_connectionPin.designQuantity =
        this.axis_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.axis_connectionPin.designQuantity = 0;
    }

    // 2차
    if (this.axisLength > this.axisPipe2Sample.specLength) {
      this.axis2_connectionPin.designQuantity =
        this.axis2_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.axis2_connectionPin.designQuantity = 0;
    }

    this.axis_screw.designQuantity =
      ((this.axis_connectionPin.getSelectedQuantity() * 4 + this.axis2_connectionPin.getSelectedQuantity() * 4) /
        this.axis_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;

    let slopeClipRafterInterval: number; // 기울기 클립이 설치될 서까래 간격
    if (this.level.index === 0) {
      slopeClipRafterInterval = 3;
      bothEndQuantity =
        (Math.ceil(this.basicLevel.length / (roofPosition.rafterPart.rafterInterval * slopeClipRafterInterval)) + 1) *
        2;
      centralQuantity =
        (Math.ceil(this.basicLevel.length / (roofPosition.rafterPart.rafterInterval * slopeClipRafterInterval)) + 1) *
        switcherPosition.switchWayValue *
        (this.basic.buildingNumber - 2);
      this.axis_slopeClip.designQuantity = bothEndQuantity + centralQuantity;
    } else {
      slopeClipRafterInterval = 2;
      this.axis_slopeClip.designQuantity =
        (Math.ceil(this.basicLevel.length / (roofPosition.rafterPart.rafterInterval * slopeClipRafterInterval)) + 1) *
        switcherPosition.switchWayValue *
        this.basic.buildingNumber;
    }
  }

  /**
   * 알고리즘: 개폐 축 길이 <- 길이(기본정보), 폭(기본정보), 개폐 동수(마구리 골조)
   */
  public algorithm_axisLength(): void {
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    if (
      this.position.label === CONST.LB_POSITION_SIDE_COLUMN ||
      this.position.label === CONST.LB_POSITION_SIDE_WING ||
      this.position.label === CONST.LB_POSITION_ROOF
    ) {
      this.axisLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.axisLength =
        this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber +
        this.design.preference.connectedPipeExtraLength;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.axisLength =
        this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber +
        this.design.preference.connectedPipeExtraLength;
    }
  }

  /**
   * 알고리즘: 기울기 클립 선택(지붕) <- 기본정보
   */
  public algorithm_slopeClipSelectedRoof(): void {
    if (this.level.index === 0 && this.position.label === CONST.LB_POSITION_ROOF) {
      if (this.design.preference.slopClipFlag === true) {
        this.slopeClipSelectedRoof = true;
      } else {
        this.slopeClipSelectedRoof = false;
      }
    } else {
      this.slopeClipSelectedRoof = false;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [개폐 축] 파이프 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_pipe(): void {
    this.axis_pipe.specs = this.axisPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [개폐 축] 연결핀 <- 개폐 축 파이프 샘플
   */
  public algorithmSpec_axis_connectionPin(): void {
    this.axis_connectionPin.specPipeType = this.axisPipeSample.specPipeType;
    this.axis_connectionPin.specCrossSize1 = this.axisPipeSample.specCrossSize;
    this.axis_connectionPin.specCrossSize2 = this.axisPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [개폐 축(2차)] 파이프 <- 개페 축 파이프 샘플(2차)
   */
  public algorithmSpec_axis2_pipe(): void {
    this.axis2_pipe.specs = this.axisPipe2Sample.specs;
  }

  /**
   * 규격 알고리즘: [개폐 축(2차)] 연결핀 <- 개페 축 파이프 샘플(2차)
   */
  public algorithmSpec_axis2_connectionPin(): void {
    this.axis2_connectionPin.specPipeType = this.axisPipe2Sample.specPipeType;
    this.axis2_connectionPin.specCrossSize1 = this.axisPipe2Sample.specCrossSize;
    this.axis2_connectionPin.specCrossSize2 = this.axisPipe2Sample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [개폐 축] 나사 <- 공통 샘플, 개페 축 파이프 샘플, 개페 축 파이프 샘플(2차)
   */
  public algorithmSpec_axis_screw(): void {
    this.axis_screw.specs = this.design.preference.defaultScrewCrossNormal;
  }

  /**
   * 규격 알고리즘: [개폐 축] 기울기 클립 <- 서까래 파이프 샘플
   */
  public algorithmSpec_axis_slopeClip(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const roofLevel: RoofLevelIL = <RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index];
      this.axis_slopeClip.specCrossSize = roofLevel.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      this.axis_slopeClip.specCrossSize =
        this.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.specCrossSize;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 초기화: 기초 피복 중수
   */
  protected init_coverLevel(): void {
    this.coverLevel = <CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index];
  }
}
