import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Level } from "vhows-design/src/object/design/base/Level";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurSwitchPositionIL],
})
export class CurSwitchLevelIL extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public columnPosition: CurSwitchPositionIL;
  public ceilingPosition: CurSwitchPositionIL;
  public frontPosition: CurSwitchPositionIL;
  public backPosition: CurSwitchPositionIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.columnPosition = new CurSwitchPositionIL();
    this.ceilingPosition = new CurSwitchPositionIL();
    this.frontPosition = new CurSwitchPositionIL();
    this.backPosition = new CurSwitchPositionIL();

    this.positionAC = [this.columnPosition, this.ceilingPosition, this.frontPosition, this.backPosition];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.columnPosition = <CurSwitchPositionIL>this.positionAC[0];
    this.ceilingPosition = <CurSwitchPositionIL>this.positionAC[1];
    this.frontPosition = <CurSwitchPositionIL>this.positionAC[2];
    this.backPosition = <CurSwitchPositionIL>this.positionAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index === 0) {
      this.columnPosition.setDefaultData(
        0,
        false,
        false,
        true,
        CONST.LB_POSITION_SIDE_COLUMN,
        CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW,
        CONST.LB_SWITCHER_SWITCH_WAY_BOTH,
        CONST.LB_SWITCHER_DIVISION_NONE,
        CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE,
        CONST.LB_FIXING_TYPE_CLIP,
      );
      this.ceilingPosition.setDefaultData(
        1,
        false,
        false,
        true,
        CONST.LB_POSITION_CEILING,
        CONST.LB_CURTAIN_SWITCH_TYPE_MOTOR_TOW,
        CONST.LB_SWITCHER_SWITCH_WAY_ON,
        CONST.LB_SWITCHER_DIVISION_NONE,
        CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE,
        CONST.LB_FIXING_TYPE_CLIP,
      );
      this.frontPosition.setDefaultData(
        2,
        false,
        false,
        true,
        CONST.LB_POSITION_FRONT,
        CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP,
        CONST.LB_SWITCHER_SWITCH_WAY_ON,
        CONST.LB_SWITCHER_DIVISION_NONE,
        CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL,
        CONST.LB_FIXING_TYPE_CLIP,
      );
      this.backPosition.setDefaultData(
        3,
        false,
        false,
        true,
        CONST.LB_POSITION_BACK,
        CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW,
        CONST.LB_SWITCHER_SWITCH_WAY_ON,
        CONST.LB_SWITCHER_DIVISION_NONE,
        CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL,
        CONST.LB_FIXING_TYPE_CLIP,
      );
    } else if (index === 1 || index === 2 || index === 3) {
    }

    //   this.columnPosition.setDefaultData(
    //     0, false, false, true, CONST.LB_POSITION_SIDE_COLUMN, CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW,
    //     CONST.LB_SWITCHER_SWITCH_WAY_BOTH,
    //     CONST.LB_SWITCHER_DIVISION_NONE,
    //     CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE,
    //     CONST.LB_FIXING_TYPE_CLIP,
    //   );
    //   this.ceilingPosition.setDefaultData(
    //     1, false, true, true, CONST.LB_POSITION_CEILING, CONST.LB_CURTAIN_SWITCH_TYPE_MOTOR_TOW,
    //     CONST.LB_SWITCHER_SWITCH_WAY_ON,
    //     CONST.LB_SWITCHER_DIVISION_NONE,
    //     CONST.LB_SWITCHER_SUPPPORT_TYPE_NONE,
    //     CONST.LB_FIXING_TYPE_CLIP,
    //   );
    //   this.frontPosition.setDefaultData(
    //     2, false, false, true, CONST.LB_POSITION_FRONT, CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP,
    //     CONST.LB_SWITCHER_SWITCH_WAY_ON,
    //     CONST.LB_SWITCHER_DIVISION_NONE,
    //     CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL,
    //     CONST.LB_FIXING_TYPE_CLIP,
    //   );
    //   this.backPosition.setDefaultData(
    //     3, false, false, true, CONST.LB_POSITION_BACK, CONST.LB_CURTAIN_SWITCH_TYPE_POWER_TOW,
    //     CONST.LB_SWITCHER_SWITCH_WAY_ON,
    //     CONST.LB_SWITCHER_DIVISION_NONE,
    //     CONST.LB_SWITCHER_SUPPPORT_TYPE_VERTICAL,
    //     CONST.LB_FIXING_TYPE_CLIP,
    //   );
    // }
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work): void {
    super.restoreLatestObject(design, struct, work);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
