import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Level } from "vhows-design/src/object/design/base/Level";
import { BandstringPositionIL } from "vhows-design/src/object/design/cover/bandstring/il/BandstringPositionIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [BandstringPositionIL],
})
export class BandstringLevelIL extends Level {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public trunkPosition: BandstringPositionIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.trunkPosition = new BandstringPositionIL();

    this.positionAC = [this.trunkPosition];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.trunkPosition = this.positionAC[0] as BandstringPositionIL;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label);

    if (index === 0) {
      this.trunkPosition.setDefaultData(
        0,
        true,
        true,
        true,
        CONST.LB_POSITION_WHOLE,
        CONST.LB_BANDSTRING_TYPE_VINYL,
        CONST.LB_BANDSTRING_INSTALL_LOCATION_TYPE_SIDE_AND_ROOF,
        CONST.LB_BANDSTRING_FIXING_TYPE_NORMAL_PAD,
        3.6,
        CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_6_1,
      );
    } else if (index === 1 || index === 2) {
      // 없음
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
