import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { SwitcherLevel } from "vhows-design/src/object/design/switches/switcher/SwitcherLevel";
import { EndpieceLevel } from "vhows-design/src/object/design/frame/endpiece/EndpieceLevel";
import { PartitionLevel } from "vhows-design/src/object/design/other/partition/PartitionLevel";
import { WindowLevel } from "vhows-design/src/object/design/other/window/WindowLevel";
import { ScreennetPosition } from "vhows-design/src/object/design/cover/screennet/ScreennetPosition";
import { SkirtPosition } from "vhows-design/src/object/design/cover/skirt/SkirtPosition";
import { WindbreakPosition } from "vhows-design/src/object/design/cover/windbreak/WindbreakPosition";
import { FixingPart_NormalPad } from "vhows-design/src/object/design/cover/fixing/FixingPart_NormalPad";
import { FixingLevel } from "vhows-design/src/object/design/cover/fixing/FixingLevel";
import { SwitcherPosition } from "vhows-design/src/object/design/switches/switcher/SwitcherPosition";
import { FixingPart_Wood } from "vhows-design/src/object/design/cover/fixing/FixingPart_Wood";
import { FixingPart_LaggingPad } from "vhows-design/src/object/design/cover/fixing/FixingPart_LaggingPad";
import { FixingPart_NormalClip } from "vhows-design/src/object/design/cover/fixing/FixingPart_NormalClip";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-26
 */
@jsonObject({
  knownTypes: [FixingPart_LaggingPad, FixingPart_NormalClip, FixingPart_NormalPad, FixingPart_Wood],
})
export class FixingPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public normalPadPart: FixingPart_NormalPad;
  public normalClipPart: FixingPart_NormalClip;
  public laggingPadPart: FixingPart_LaggingPad;
  public woodPart: FixingPart_Wood;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  public status: string; // 상태

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _lineNumber: number; // 줄수 또는 마구리 중방방향 줄수(앞/뒷면)
  @jsonMember(String)
  public _endpieceRafterType: string; // 마구리 서까래쪽 형태
  @jsonMember(Number)
  public _endpieceRafterLineNumber: number; // 마구리 서까래쪽 줄수
  @jsonMember(String)
  public _endpieceGateType: string; // 마구리 문주위 형태
  @jsonMember(Number)
  public _endpieceGateLineNumber: number; // 마구리 문주위 줄수

  /**
   * 줄수 또는 마구리 중방방향 줄수(앞/뒷면)
   */
  public get lineNumber(): number {
    return this._lineNumber;
  }

  public set lineNumber(value: number) {
    this._lineNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 서까래쪽 형태
   */
  public get endpieceRafterType(): string {
    return this._endpieceRafterType;
  }

  public set endpieceRafterType(value: string) {
    this._endpieceRafterType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 서까래쪽 줄수
   */
  public get endpieceRafterLineNumber(): number {
    return this._endpieceRafterLineNumber;
  }

  public set endpieceRafterLineNumber(value: number) {
    this._endpieceRafterLineNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 문주위 형태
   */
  public get endpieceGateType(): string {
    return this._endpieceGateType;
  }

  public set endpieceGateType(value: string) {
    this._endpieceGateType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 문주위 줄수
   */
  public get endpieceGateLineNumber(): number {
    return this._endpieceGateLineNumber;
  }

  public set endpieceGateLineNumber(value: number) {
    this._endpieceGateLineNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태 또는 마구리 중방방향 형태(앞,뒷면)
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_endpieceTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 다른 위치
    this.algorithm_typeByPosition();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtPosition>(
      this.struct.skirtWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_fixingTypeByFixing();

    // 바람막이
    (<WindbreakPosition>(
      this.struct.windbreakWork.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_fixingTypeByFixing();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPosition>(
        this.struct.screennetWork.levelAC[this.level.index].positionAC[this.index]
      )).algorithm_fixingTypeByFixing();
    }

    // 창문
    if (this.level.index === 0) {
      if (this.label === CONST.LB_POSITION_SIDE) {
        (<WindowLevel>this.struct.windowWork.levelAC[this.level.index]).leftPosition.algorithm_fixingTypeByFixing();
        (<WindowLevel>this.struct.windowWork.levelAC[this.level.index]).rightPosition.algorithm_fixingTypeByFixing();
      } else if (this.label === CONST.LB_POSITION_FRONT) {
        (<WindowLevel>this.struct.windowWork.levelAC[this.level.index]).frontPosition.algorithm_fixingTypeByFixing();
      } else if (this.label === CONST.LB_POSITION_BACK) {
        (<WindowLevel>this.struct.windowWork.levelAC[this.level.index]).backPosition.algorithm_fixingTypeByFixing();
      }
    }

    // 칸막이
    if (this.level.index === 0) {
      if (this.label === CONST.LB_POSITION_SIDE) {
        (<PartitionLevel>(
          this.struct.partitionWork.levelAC[this.level.index]
        )).trunkPosition.algorithm_fixingTypeByFixing();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.normalPadPart = new FixingPart_NormalPad();
    this.normalClipPart = new FixingPart_NormalClip();
    this.laggingPadPart = new FixingPart_LaggingPad();
    this.woodPart = new FixingPart_Wood();

    this.partAC = [this.normalPadPart, this.normalClipPart, this.laggingPadPart, this.woodPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.normalPadPart = <FixingPart_NormalPad>this.partAC[0];
    this.normalClipPart = <FixingPart_NormalClip>this.partAC[1];
    this.laggingPadPart = <FixingPart_LaggingPad>this.partAC[2];
    this.woodPart = <FixingPart_Wood>this.partAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param lineNumber: number 줄수 또는 마구리 중방방향 줄수(앞/뒷면)
   * @param endpieceRafterType: string 마구리 서까래쪽 형태
   * @param endpieceRafterLineNumber: number 마구리 서까래쪽 줄수
   * @param endpieceGateType: string 마구리 문주위 형태
   * @param endpieceGateLineNumber: number 마구리 문주위 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    lineNumber: number = 0,
    endpieceRafterType: string = "",
    endpieceRafterLineNumber: number = 0,
    endpieceGateType: string = "",
    endpieceGateLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._lineNumber = lineNumber;
    this._endpieceRafterType = endpieceRafterType;
    this._endpieceRafterLineNumber = endpieceRafterLineNumber;
    this._endpieceGateType = endpieceGateType;
    this._endpieceGateLineNumber = endpieceGateLineNumber;

    if (this.level.index === 0) {
      // 일반 패드 고정
      this.normalPadPart.setDefaultData(
        CONST.INDEX_SG_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_FIXING_NORMAL_PAD,
        "",
      );

      // 일반 클립 고정
      if (label === CONST.LB_POSITION_SIDE || label === CONST.LB_POSITION_ROOF) {
        this.normalClipPart.setDefaultData(
          CONST.INDEX_SG_FIXING_NORMAL_CLIP,
          false,
          false,
          false,
          CONST.LB_FIXING_NORMAL_CLIP,
          "",
          0.5,
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        this.normalClipPart.setDefaultData(
          CONST.INDEX_SG_FIXING_NORMAL_CLIP,
          false,
          false,
          false,
          CONST.LB_FIXING_NORMAL_CLIP,
          "",
          0.2,
        );
      }

      // 광폭 패드 고정
      this.laggingPadPart.setDefaultData(
        CONST.INDEX_SG_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_FIXING_LAGGING_PAD,
        "",
      );

      // 목재 고정
      this.woodPart.setDefaultData(CONST.INDEX_SG_FIXING_WOOD, false, false, false, CONST.LB_FIXING_WOOD, "");
    } else {
      // 일반 패드 고정
      this.normalPadPart.setDefaultData(
        CONST.INDEX_SG_FIXING_NORMAL_PAD,
        false,
        false,
        false,
        CONST.LB_FIXING_NORMAL_PAD,
        "",
      );

      // 일반 클립 고정
      if (label === CONST.LB_POSITION_SIDE || label === CONST.LB_POSITION_ROOF) {
        this.normalClipPart.setDefaultData(
          CONST.INDEX_SG_FIXING_NORMAL_CLIP,
          true,
          false,
          true,
          CONST.LB_FIXING_NORMAL_CLIP,
          "",
          0.5,
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        this.normalClipPart.setDefaultData(
          CONST.INDEX_SG_FIXING_NORMAL_CLIP,
          true,
          false,
          true,
          CONST.LB_FIXING_NORMAL_CLIP,
          "",
          0.2,
        );
      }

      // 광폭 패드 고정
      this.laggingPadPart.setDefaultData(
        CONST.INDEX_SG_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_FIXING_LAGGING_PAD,
        "",
      );

      // 목재 고정
      this.woodPart.setDefaultData(CONST.INDEX_SG_FIXING_WOOD, false, false, false, CONST.LB_FIXING_WOOD, "");
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.algorithm_status();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.label === CONST.LB_POSITION_SIDE || this.label === CONST.LB_POSITION_ROOF) {
      if (this.type === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
        this.normalClipPart.selected = false;
        this.normalClipPart.visible = false;
        this.laggingPadPart.selected = false;
        this.laggingPadPart.visible = false;
        this.woodPart.selected = false;
        this.woodPart.visible = false;
      } else if (this.type === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalPadPart.selected = false;
        this.normalPadPart.visible = false;
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
        this.laggingPadPart.selected = false;
        this.laggingPadPart.visible = false;
        this.woodPart.selected = false;
        this.woodPart.visible = false;
      } else if (this.type === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.normalPadPart.selected = false;
        this.normalPadPart.visible = false;
        this.normalClipPart.selected = false;
        this.normalClipPart.visible = false;
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
        this.woodPart.selected = false;
        this.woodPart.visible = false;
      } else if (this.type === CONST.LB_FIXING_TYPE_WOOD) {
        this.normalPadPart.selected = false;
        this.normalPadPart.visible = false;
        this.normalClipPart.selected = false;
        this.normalClipPart.visible = false;
        this.laggingPadPart.selected = false;
        this.laggingPadPart.visible = false;
        this.woodPart.selected = true;
        this.woodPart.visible = true;
      }
    } else if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
      this.normalPadPart.selected = false;
      this.normalPadPart.visible = false;
      this.normalClipPart.selected = false;
      this.normalClipPart.visible = false;
      this.laggingPadPart.selected = false;
      this.laggingPadPart.visible = false;
      this.woodPart.selected = false;
      this.woodPart.visible = false;
      // 마구리 중방방향
      if (this.type === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
      } else if (this.type === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
      } else if (this.type === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
      } else if (this.type === CONST.LB_FIXING_TYPE_WOOD) {
        this.woodPart.selected = true;
        this.woodPart.visible = true;
      }
      // 마구리 서까래쪽
      if (this.endpieceRafterType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
      } else if (this.endpieceRafterType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
      } else if (this.endpieceRafterType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
      } else if (this.endpieceRafterType === CONST.LB_FIXING_TYPE_WOOD) {
        this.woodPart.selected = true;
        this.woodPart.visible = true;
      }
      // 마구리 문주위
      if (this.endpieceGateType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
      } else if (this.endpieceGateType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
      } else if (this.endpieceGateType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
      } else if (this.endpieceGateType === CONST.LB_FIXING_TYPE_WOOD) {
        this.woodPart.selected = true;
        this.woodPart.visible = true;
      }
    }
  }

  /**
   * 알고리즘: 선택(지붕 위치) <- 선택(개폐기), 개폐 방향(개폐기)
   */
  public algorithm_selectedBySwitcher(): void {
    const fixingLevel: FixingLevel = <FixingLevel>this.level;
    const switcherLevel: SwitcherLevel = <SwitcherLevel>this.struct.switcherWork.levelAC[this.level.index];
    if (this.label === CONST.LB_POSITION_SIDE) {
      this.selected = switcherLevel.sidePosition.selected;

      // 측면이 완전개폐인 경우
      if (
        switcherLevel.sidePosition.selected === true &&
        switcherLevel.sidePosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL
      ) {
        fixingLevel.roofPosition.selected = true;
        fixingLevel.sidePosition.selected = false;
      } else {
        fixingLevel.roofPosition.selected = false;
        fixingLevel.sidePosition.selected = true;
      }
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(위치)
   */
  public algorithm_typeByPosition(): void {
    if (this.label === CONST.LB_POSITION_SIDE) {
      (<FixingLevel>this.level).roofPosition.type = this.type;
      (<FixingLevel>this.level).frontPosition.type = this.type;
      (<FixingLevel>this.level).backPosition.type = this.type;
    } else if (this.label === CONST.LB_POSITION_FRONT) {
      (<FixingLevel>this.level).backPosition.type = this.type;
    }
  }

  /**
   * 알고리즘: 마구리쪽 형태 <- 형태
   */
  public algorithm_endpieceTypeByType(): void {
    if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
      this.endpieceRafterType = this.type;
      this.endpieceGateType = this.type;
    }
  }

  /**
   * 알고리즘: 줄수(측면+지붕 위치) <- 선택(개폐기), 개폐 방향(개폐기)
   */
  public algorithm_lineNumber(): void {
    const switcherPosition: SwitcherPosition = <SwitcherPosition>(
      this.struct.switcherWork.levelAC[this.level.index].positionAC[this.index]
    );
    if (this.label === CONST.LB_POSITION_SIDE) {
      if (switcherPosition.selected === true) {
        if (
          switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH ||
          switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL
        ) {
          this.lineNumber = 2 * switcherPosition.switchStep;
        } else if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
          this.lineNumber = 3 * switcherPosition.switchStep;
        }
      } else {
        this.lineNumber = 4;
      }
    } else if (this.label === CONST.LB_POSITION_ROOF) {
      if (switcherPosition.selected === true) {
        if (
          switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH ||
          switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL
        ) {
          this.lineNumber = 1;
        } else if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
          this.lineNumber = 1;
        }
      } else if (switcherPosition.selected === false) {
        if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_FULL) {
          this.lineNumber = 1;
        }
      } else {
        this.lineNumber = 0;
      }
    }
  }

  /**
   * 알고리즘: 마구리 중방방향 줄수 <- 선택(마구리), 형태(마구리), 선택(개폐기)
   */
  public algorithm_endpieceMiddleLineNumber(): void {
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];
    const switcherPosition: SwitcherPosition = <SwitcherPosition>(
      this.struct.switcherWork.levelAC[this.level.index].positionAC[this.index]
    );

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true) {
        if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          if (switcherPosition.selected === true) {
            this.lineNumber = 1;
          } else {
            this.lineNumber = 3;
          }
        } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.lineNumber = 1;
        }
      } else {
        this.lineNumber = 0;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true) {
        if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          if (switcherPosition.selected === true) {
            this.lineNumber = 1;
          } else {
            this.lineNumber = 3;
          }
        } else if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.lineNumber = 1;
        }
      } else {
        this.lineNumber = 0;
      }
    }
  }

  /**
   * 알고리즘: 마구리 문주위 줄수 <- 선택(마구리), 출입문 형태(마구리)
   */
  public algorithm_endpieceGateLineNumber(): void {
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true && endpieceLevel.frontPosition.gateFrameSelected === true) {
        this.endpieceGateLineNumber = 2;
      } else {
        this.endpieceGateLineNumber = 0;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true && endpieceLevel.backPosition.gateFrameSelected === true) {
        this.endpieceGateLineNumber = 2;
      } else {
        this.endpieceGateLineNumber = 0;
      }
    }
  }

  /**
   * 알고리즘: 상태 <- 선택(마구리), 형태(마구리), 출입문 형태(마구리), 선택(개폐기), 개폐 방향(개폐기)
   */
  public algorithm_status(): void {
    const endpieceLevel: EndpieceLevel = <EndpieceLevel>this.struct.endpieceWork.levelAC[this.level.index];
    const switcherPosition: SwitcherPosition = <SwitcherPosition>(
      this.struct.switcherWork.levelAC[this.level.index].positionAC[this.index]
    );
    this.status = "";

    // 개폐 여부
    if (switcherPosition.selected === true) {
      this.status = CommonUtil.addDelimiter(this.status, ", ");
      this.status = switcherPosition.switchWay;
    } else {
      this.status = CommonUtil.addDelimiter(this.status, ", ");
      this.status = CONST.LB_SWITCHER_SWITCH_WAY_NO;
    }
    // 마구리 골조 형태 및 출입문 여부
    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true) {
        this.status += " (";
        this.status += endpieceLevel.frontPosition.type;

        if (endpieceLevel.frontPosition.gateType !== CONST.LB_GATE_TYPE_NONE) {
          this.status = CommonUtil.addDelimiter(this.status, ", ");
          this.status += endpieceLevel.frontPosition.gateType;
        }
        this.status += ")";
      } else {
        this.status = `${CONST.LB_ENDPIECE_WORK} ${CONST.TEXT_NONE}`;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true) {
        this.status += " (";
        this.status += endpieceLevel.backPosition.type;

        if (endpieceLevel.backPosition.gateType !== CONST.LB_GATE_TYPE_NONE) {
          this.status = CommonUtil.addDelimiter(this.status, ", ");
          this.status += endpieceLevel.backPosition.gateType;
        }
        this.status += ")";
      } else {
        this.status = `${CONST.LB_ENDPIECE_WORK} ${CONST.TEXT_NONE}`;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
