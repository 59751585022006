import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { FixingPartIL_NormalClip } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_NormalClip";
import { ColumnLevelIL } from "vhows-design/src/object/design/frame/column/ColumnLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { ColumnLevelVL } from "vhows-design/src/object/design/frame/column/vl/ColumnLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject
export class FixingSampleIL_NormalClip extends ItemClip {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const normalClipPart: FixingPartIL_NormalClip = <FixingPartIL_NormalClip>this.part;
    normalClipPart.algorithmSpec_normalClip_normalClip();
    normalClipPart.algorithmSpec_normalClipMiddle_normalClip();
    normalClipPart.algorithmSpec_normalClipRafter_normalClip();
    normalClipPart.algorithmSpec_normalClipColumn_normalClip();
    normalClipPart.algorithmSpec_normalClipGate_normalClip();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 측면 가로대 형태(기둥 골조), 측면 가로대 파이프 샘플, 방풍벽 가로대 파이프 샘플, 지붕 가로대 파이프 샘플, 형태(마구리 골조), 마구리 중방(하단) 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      // 연동형인 경우
      const columnLevel: ColumnLevelIL = this.struct.columnWorkIL.level1;
      const wingLevel: WingLevelIL = <WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index];
      const roofLevel: RoofLevelIL = <RoofLevelIL>this.struct.roofWorkIL.levelAC[this.level.index];
      const endpieceLevel: EndpieceLevelIL = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];

      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        if (columnLevel.columnPosition.sideStraightPart.sideStraightType === CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_PIPE) {
          this.specCrossSize = columnLevel.columnPosition.sideStraightPart.sideStraightPipeSample.specCrossSize;
        } else if (
          columnLevel.columnPosition.sideStraightPart.sideStraightType ===
          CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_C_SECTION_STEEL
        ) {
          this.specCrossSize =
            columnLevel.columnPosition.sideStraightPart.sideStraightCSectionSteelSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        this.specCrossSize = wingLevel.wingPosition.wingStraightPart.wingStraightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.specCrossSize = roofLevel.roofPosition.straightPart.straightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        if (
          endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
          endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM
        ) {
          this.specCrossSize = endpieceLevel.frontPosition.cylinderMiddlePart.middleBottomPipeSample.specCrossSize;
        } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.specCrossSize = endpieceLevel.frontPosition.prismMiddlePart.middleBottomPipeSample.specCrossSize;
        } else {
          this.specCrossSize = endpieceLevel.frontPosition.cylinderMiddlePart.middleBottomPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        if (
          endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
          endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM
        ) {
          this.specCrossSize = endpieceLevel.backPosition.cylinderMiddlePart.middleBottomPipeSample.specCrossSize;
        } else if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
          this.specCrossSize = endpieceLevel.backPosition.prismMiddlePart.middleBottomPipeSample.specCrossSize;
        } else {
          this.specCrossSize = endpieceLevel.backPosition.cylinderMiddlePart.middleBottomPipeSample.specCrossSize;
        }
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 벤로형인 경우
      const columnLevel: ColumnLevelVL = this.struct.columnWorkVL.level1;
      const wingLevel: WingLevelVL = <WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index];
      const roofLevel: RoofLevelVL = this.struct.roofWorkVL.level1;
      const endpieceLevel: EndpieceLevelVL = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];

      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        if (columnLevel.columnPosition.sideStraightPart.sideStraightType === CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_PIPE) {
          this.specCrossSize = columnLevel.columnPosition.sideStraightPart.sideStraightPipeSample.specCrossSize;
        } else if (
          columnLevel.columnPosition.sideStraightPart.sideStraightType ===
          CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_C_SECTION_STEEL
        ) {
          this.specCrossSize =
            columnLevel.columnPosition.sideStraightPart.sideStraightCSectionSteelSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        this.specCrossSize = wingLevel.wingPosition.wingStraightPart.wingStraightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_ROOF) {
        this.specCrossSize = roofLevel.roofPosition.straightPart.straightPipeSample.specCrossSize;
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpieceLevel.frontPosition.endpiecePart.straightBottomPipeSample.specCrossSize;
        }
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE) {
          this.specCrossSize = endpieceLevel.backPosition.endpiecePart.straightBottomPipeSample.specCrossSize;
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
