import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Basic } from "vhows-design/src/object/design/basic/Basic";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Position } from "vhows-design/src/object/design/base/Position";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-16
 */
@jsonObject({
  knownTypes: [Position],
})
export class Level extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;
  /** 기본정보 객체 */
  public basic: Basic;
  /** 중수별 기본정보 객체 */
  public basicLevel: BasicLevel;
  /** 구조 객체 */
  public struct: Struct;
  /** 작업 객체 */
  public work: Work;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 형식적 명칭 */
  public get formalLabel(): string {
    return `${CONST.LB_LEVEL}: ${this.label}`;
  }

  //
  public set formalLabel(value: string) {}

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 위치AC */
  @jsonArrayMember(Position)
  public positionAC: Position[];

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _index: number; // 인덱스
  @jsonMember(Boolean)
  public _selected: boolean; // 선택
  @jsonMember(Boolean)
  public _selectedEstimate: boolean = true; // 견적 선택
  @jsonMember(Boolean)
  public _enabled: boolean; // 가용성
  @jsonMember(Boolean)
  public _visible: boolean; // 가시성 (사용 안하고 있음)
  @jsonMember(String)
  public _label: string; // 명칭

  /**
   * 인덱스 <br/>
   * 현재 중수-1 값을 갖는 고유번호.
   */
  public get index(): number {
    return this._index;
  }

  //
  public set index(value: number) {
    this._index = value;
  }

  /**
   * 선택
   */
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.algorithm_enabled();
    this.work.algorithm_selectedFromLevel();
  }

  /**
   * 견적 선택
   */
  public get selectedEstimate(): boolean {
    return this._selectedEstimate;
  }

  //
  public set selectedEstimate(value: boolean) {
    this._selectedEstimate = value;
  }

  /**
   * 가용성
   */
  public get enabled(): boolean {
    return this._enabled;
  }

  //
  public set enabled(value: boolean) {
    this._enabled = value;
  }

  /**
   * 가시성 (사용 안하고 있음)
   */
  public get visible(): boolean {
    return this._visible;
  }

  //
  public set visible(value: boolean) {
    this._visible = value;
  }

  /**
   * 명칭
   */
  public get label(): string {
    return this._label;
  }

  //
  public set label(value: string) {
    this._label = value;
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design, struct: Struct, work: Work): void {
    this.design = design;
    this.struct = struct;
    this.work = work;
    if (design.basic != null) {
      this.basic = design.basic;
      this.basicLevel = this.basic.basicLevelAC[work.levelAC.indexOf(this)];
    }

    // 위치
    let position: Position;
    for (position of this.positionAC) {
      position.setAssociation(design, struct, work, this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 위치
    let position: Position;
    for (position of this.positionAC) {
      position.setReferenceVariable();
    }
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스: 현재 중수
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   */
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
  ): void {
    this._index = index;
    this._selected = selected;
    this._enabled = enabled;
    this._visible = visible;
    this._label = label;

    // 추후 확인 - 벤로형 구조 추가 때문에 추가함
    // 위치
    let position: Position;
    for (position of this.positionAC) {
      position.setDefaultData();
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 위치
    let position: Position;
    for (position of this.positionAC) {
      position.setDefaultVariable();
    }
  }

  /**
   * 기본 모델 설정: 데이터베이스를 대신함
   */
  public setDefaultModel(): void {
    // 위치
    let position: Position;
    for (position of this.positionAC) {
      position.setDefaultModel();
    }
  }

  /**
   * 저장된 아이디값 세팅
   */
  public setSavedId(savedLevel: Level): void {
    if (savedLevel != null) {
      this.id = savedLevel.id;

      if (this.positionAC != null && savedLevel.positionAC != null) {
        for (let i: number = 0; i < this.positionAC.length; i++) {
          // 기존 배열길이만큼 할당하되 저장된 배열길이가 더 작을경우는 할당하지 않음
          if (i < savedLevel.positionAC.length) {
            this.positionAC[i].setSavedId(savedLevel.positionAC[i]);
          }
        }
      }
    }
  }

  /**
   * 최신 객체로 복원
   */
  public restoreLatestObject(design: Design, struct: Struct, work: Work): void {
    // 위치
    let position: Position;
    for (position of this.positionAC) {
      position.restoreLatestObject(design, struct, work, this);
    }
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  public algorithmBasic(): void {
    this.algorithm_enabled();

    // 작업
    let position: Position;
    for (position of this.positionAC) {
      position.algorithmBasic();
    }
  }

  /**
   * 선택 <- 위치 객체의 선택
   */
  public algorithm_selectedByPosition(): void {
    let isUnselectedAll: boolean = true;

    // 전체 위치AC 뒤지기
    let position: Position;
    for (position of this.positionAC) {
      if (position.selected === true) {
        isUnselectedAll = false;
        break;
      }
    }

    if (isUnselectedAll === true) {
      this.selected = false;
    } else {
      this.selected = true;
    }
  }

  /**
   * 가용성 <- 선택, 중수(기본정보)
   */
  public algorithm_enabled(): void {
    if (this.selected === true) {
      this.enabled = true;
    } else {
      if (this.basic.levelNumber > this.index) {
        this.enabled = true;
      } else {
        this.enabled = false;
      }
    }
  }

  /**
   * 위치 추가하기
   * @param refPosition 참조할 위치
   * @param maxLength 위치 배열의 최대 길이
   */
  public addPosition(refPosition: Position, maxLength: number = CONST.NUM_COVER_POSITION_MAX): Position {
    // 상속
    return null;
  }

  /**
   * 위치 삭제하기
   * @param position 참조할 위치
   * @param minLength 위치 배열의 최소 길이
   */
  public deletePosition(position: Position, minLength: number = 1): boolean {
    // 상속
    return false;
  }

  /**
   * 위치 이동하기
   * @param position 이동시킬 위치
   * @param toIndex 이동할 위치 인덱스
   */
  public movePosition(position: Position, toIndex: number): boolean {
    const fromIndex: number = position.index;
    if (toIndex < 0 || toIndex >= this.positionAC.length) {
      return false;
    }
    this.positionAC.splice(fromIndex, 1);
    this.positionAC.splice(toIndex, 0, position);

    this.reindexPosition();
    return true;
  }

  /**
   * 위치 인덱스 재설정
   */
  public reindexPosition() {
    this.positionAC.forEach((value: Position, index: number) => {
      value.index = index;
      value.label = index + 1 + CONST.LB_POSITION_COVER;
    });
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
