import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 물탱크 피팅
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemWaterTankFitting extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성, 지름1, 지름2]
  public _specFeature: string = undefined; // 특성
  public _specDiameter1: string = undefined; // 지름1
  public _specDiameter2: string = undefined; // 지름2

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 지름1
   */
  public get specDiameter1(): string {
    return this._specDiameter1;
  }
  //
  public set specDiameter1(value: string) {
    this._specDiameter1 = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 지름2
   */
  public get specDiameter2(): string {
    return this._specDiameter2;
  }
  //
  public set specDiameter2(value: string) {
    this._specDiameter2 = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.waterTankFittingCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specDiameter1 = specsArray[1];
    this.specDiameter2 = specsArray[2];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
    this.specDiameter1 = specsArray[1];
    this.specDiameter2 = specsArray[2];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specFeature + CONST.DELIMITER_SPEC + this._specDiameter1 + CONST.DELIMITER_SPEC + this._specDiameter2;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }
}
