import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { ItemDrainPipe } from "vhows-design/src/object/design/item/list/ItemDrainPipe";
import { HydSystemPart_HydSupply } from "vhows-design/src/object/design/hydroponics/system/HydSystemPart_HydSupply";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2021-05-27
 */
@jsonObject
export class HydSystemSample_SupplyMainPvcPipe extends ItemDrainPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const supplyPart: HydSystemPart_HydSupply = <HydSystemPart_HydSupply>this.part;
    supplyPart.algorithmSpec_supplyMainB_pvcPipe();
    supplyPart.algorithmSpec_supplyMainB_pvcFemaleBallValve();
    supplyPart.algorithmSpec_supplyMainB_pvcEnd();
    supplyPart.algorithmSpec_supplyMainB_pvcExactT();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
