import { jsonObject } from "typedjson";

import { ItemVentFan } from "vhows-design/src/object/design/item/list/ItemVentFan";
import { VentExhaustPart_RoofFanPower } from "vhows-design/src/object/design/vent/exhaust/VentExhaustPart_RoofFanPower";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-13
 */
@jsonObject
export class VentExhaustSample_RoofVentFan extends ItemVentFan {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const roofFanPowerPart: VentExhaustPart_RoofFanPower = <VentExhaustPart_RoofFanPower>this.part;
    roofFanPowerPart.algorithmSpec_vent_ventFan();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
