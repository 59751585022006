import { jsonObject } from "typedjson";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-31
 */
@jsonObject
export class TrunkSample_BracingPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const rafterPart: TrunkPart_Rafter = <TrunkPart_Rafter>this.part;
    rafterPart.algorithmSpec_bracing_pipe();
    rafterPart.algorithmProduct_bracingAndRafter_itemHolder();
    rafterPart.algorithmSpec_bracingAndRafter_itemHolder();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 가로대 파이프 샘플
   * - 더 이상 브레싱 파이프 규격을 자동으로 변경시키지 않기로 결정.
   * - 광폭구조에서 브레싱 파이프 규격이 기존의 단동 브레싱 파이프 규격과 맞지 않음.
   */
  // @override
  public algorithmSpec(): void {
    // // 하드코딩
    // if (parseFloat((<TrunkPosition>this.position).straightPart.straightPipeSample.specPipeCrossSize) <= parseFloat(CONST.ITEM_SPEC_VALUE_25_MM_PIPE)) {
    //   // ~25.4mm이하 파이프는 가로대를 따름
    //   this.specs = (<TrunkPosition>this.position).straightPart.straightPipeSample.specs;
    // } else if (parseFloat((<TrunkPosition>this.position).straightPart.straightPipeSample.specPipeCrossSize) <= parseFloat(CONST.ITEM_SPEC_VALUE_33_MM_PIPE)) {
    //   // ~33.5mm이하 파이프는 25.4mm로 고정
    //   this.specPipeCrossSize = CONST.ITEM_SPEC_VALUE_25_MM_PIPE;
    //   this.specThickness = CONST.ITEM_SPEC_VALUE_1_5_T;
    //   this.specLength = 10;
    // } else {
    //   // 그 이상의 파이프는 31.8mm 파이프 고정
    //   this.specPipeCrossSize = CONST.ITEM_SPEC_VALUE_32_MM_PIPE;
    //   this.specThickness = CONST.ITEM_SPEC_VALUE_1_5_T;
    //   this.specLength = 10;
    // }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
