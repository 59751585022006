import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { PartitionPosition } from "vhows-design/src/object/design/other/partition/PartitionPosition";
import { PartitionSample_StudPipe } from "vhows-design/src/object/design/other/partition/PartitionSample_StudPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-08-04
 */
@jsonObject({
  knownTypes: [PartitionSample_StudPipe],
})
export class PartitionPart_Stud extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public studPipeSample: PartitionSample_StudPipe; // 샛기둥 파이프 샘플

  // 아이템
  public stud_pipe: ItemPipe;
  public studAndRafterCenter_tHolder: ItemHolder;
  public studAndRafterCenterPartition_normalHolder: ItemHolder;
  public studAndRafterCenterRafter_normalHolder: ItemHolder;
  public studAndRafterCenter_boltAndNut: ItemFrameOther;
  public studAndRafterDiagonal_diagonalTHolder: ItemHolder;
  public studAndRafterDiagonaPartition_normalHolder: ItemHolder;
  public studAndRafterDiagonalRafter_normalHolder: ItemHolder;
  public studAndRafterDiagonal_boltAndNut: ItemFrameOther;
  public stud_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _studLengthMax: number = 0; // 샛기둥 최대 길이
  @jsonMember(Number)
  public _studLineNumber: number = 0; // 샛기둥 줄수

  /**
   * 샛기둥 최대 길이
   */
  public get studLengthMax(): number {
    return this._studLengthMax;
  }

  //
  public set studLengthMax(value: number) {
    this._studLengthMax = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 샛기둥 줄수
   */
  public get studLineNumber(): number {
    return this._studLineNumber;
  }

  //
  public set studLineNumber(value: number) {
    this._studLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.studPipeSample = new PartitionSample_StudPipe();

    this.sampleAC = [this.studPipeSample];

    // 아이템
    this.stud_pipe = new ItemPipe();
    this.studAndRafterCenter_tHolder = new ItemHolder();
    this.studAndRafterCenterPartition_normalHolder = new ItemHolder();
    this.studAndRafterCenterRafter_normalHolder = new ItemHolder();
    this.studAndRafterCenter_boltAndNut = new ItemFrameOther();
    this.studAndRafterDiagonal_diagonalTHolder = new ItemHolder();
    this.studAndRafterDiagonaPartition_normalHolder = new ItemHolder();
    this.studAndRafterDiagonalRafter_normalHolder = new ItemHolder();
    this.studAndRafterDiagonal_boltAndNut = new ItemFrameOther();
    this.stud_screw = new ItemScrew();

    this.itemAC = [
      this.stud_pipe,
      this.studAndRafterCenter_tHolder,
      this.studAndRafterCenterPartition_normalHolder,
      this.studAndRafterCenterRafter_normalHolder,
      this.studAndRafterCenter_boltAndNut,
      this.studAndRafterDiagonal_diagonalTHolder,
      this.studAndRafterDiagonaPartition_normalHolder,
      this.studAndRafterDiagonalRafter_normalHolder,
      this.studAndRafterDiagonal_boltAndNut,
      this.stud_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.studPipeSample = <PartitionSample_StudPipe>this.sampleAC[0];

    // 아이템
    this.stud_pipe = <ItemPipe>this.itemAC[0];
    this.studAndRafterCenter_tHolder = <ItemHolder>this.itemAC[1];
    this.studAndRafterCenterPartition_normalHolder = <ItemHolder>this.itemAC[2];
    this.studAndRafterCenterRafter_normalHolder = <ItemHolder>this.itemAC[3];
    this.studAndRafterCenter_boltAndNut = <ItemFrameOther>this.itemAC[4];
    this.studAndRafterDiagonal_diagonalTHolder = <ItemHolder>this.itemAC[5];
    this.studAndRafterDiagonaPartition_normalHolder = <ItemHolder>this.itemAC[6];
    this.studAndRafterDiagonalRafter_normalHolder = <ItemHolder>this.itemAC[7];
    this.studAndRafterDiagonal_boltAndNut = <ItemFrameOther>this.itemAC[8];
    this.stud_screw = <ItemScrew>this.itemAC[9];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param studLineNumber: number 샛기둥 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    studLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._studLineNumber = studLineNumber;

    // 샘플
    if (this.level.index >= 0) {
      this.studPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "칸막이 샛기둥",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.stud_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "칸막이 샛기둥",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.studAndRafterCenter_tHolder.setDefaultData(
        1,
        CONST.ITEM_ID_T_HOLDER,
        CONST.ITEM_NAME_T_HOLDER,
        "칸막이 샛기둥 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.studAndRafterCenterPartition_normalHolder.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "칸막이 샛기둥 + 서까래 (1)",
        "원형, 일반, 25mm, -",
      );
      this.studAndRafterCenterRafter_normalHolder.setDefaultData(
        3,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "칸막이 샛기둥 + 서까래 (2)",
        "원형, 일반, 25mm, -",
      );
      this.studAndRafterCenter_boltAndNut.setDefaultData(
        4,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "칸막이 샛기둥 + 서까래",
        "일반, 8mm",
      );
      this.studAndRafterDiagonal_diagonalTHolder.setDefaultData(
        5,
        CONST.ITEM_ID_DIAGONAL_T_HOLDER,
        CONST.ITEM_NAME_DIAGONAL_T_HOLDER,
        "칸막이 샛기둥 + 서까래",
        "원형, 일반, 25mm, 25mm",
      );
      this.studAndRafterDiagonaPartition_normalHolder.setDefaultData(
        6,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "칸막이 샛기둥 + 서까래 (1)",
        "원형, 일반, 25mm, -",
      );
      this.studAndRafterDiagonalRafter_normalHolder.setDefaultData(
        7,
        CONST.ITEM_ID_NORMAL_HOLDER,
        CONST.ITEM_NAME_NORMAL_HOLDER,
        "칸막이 샛기둥 + 서까래 (2)",
        "원형, 일반, 25mm, -",
      );
      this.studAndRafterDiagonal_boltAndNut.setDefaultData(
        8,
        CONST.ITEM_ID_BOLT_AND_NUT,
        CONST.ITEM_NAME_BOLT_AND_NUT,
        "칸막이 샛기둥 + 서까래",
        "일반, 8mm",
      );
      this.stud_screw.setDefaultData(
        9,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "칸막이 샛기둥",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (
      this.itemAC.length === 11 &&
      (<Item>this.itemAC[5]).productId === CONST.ITEM_ID_SCREW &&
      (<Item>this.itemAC[10]).productId === CONST.ITEM_ID_SCREW
    ) {
      // 나사 하나로 합치기
      let stud_screw_designQuantity: number;
      stud_screw_designQuantity =
        (<ItemScrew>this.itemAC[5]).getSelectedQuantity() + (<ItemScrew>this.itemAC[10]).getSelectedQuantity();
      const stud_screw: ItemScrew = <ItemScrew>this.itemAC[10];
      stud_screw.selected = stud_screw_designQuantity > 0;
      stud_screw.visible = stud_screw_designQuantity > 0;
      stud_screw.purpose = "칸막이 샛기둥";
      stud_screw.designQuantity = stud_screw_designQuantity;
      this.itemAC.splice(5, 1);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_studLengthMax();

    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_studAndRafterCenter_tHolder();
    this.algorithmSpec_studAndRafterCenterRafter_normalHolder();
    this.algorithmSpec_studAndRafterDiagonal_diagonalTHolder();
    this.algorithmSpec_studAndRafterDiagonalRafter_normalHolder();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const partitionPosition: PartitionPosition = <PartitionPosition>this.position;
    // 외부
    let trunkPosition: TrunkPosition;
    let rafterPipeSample: ItemPipe;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      trunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;
      rafterPipeSample = trunkPosition.rafterPart.rafterPipeSample;
    }

    /// //////// 선택, 가시성 ///////////

    this.stud_pipe.selected = true;
    this.stud_pipe.visible = true;
    // 중앙 : T 고정구(22/22mm,25/25mm,32/32mm,32/25mm) vs. 일반 고정구
    // 하드코딩
    if (
      this.design.preference.endpieceTholderFlag === true &&
      ((this.stud_pipe.specCrossSize === rafterPipeSample.specCrossSize &&
        (this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_22_MM ||
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)) ||
        (rafterPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM &&
          this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM))
    ) {
      this.studAndRafterCenter_tHolder.selected = true;
      this.studAndRafterCenter_tHolder.visible = true;
      this.studAndRafterCenterPartition_normalHolder.selected = false;
      this.studAndRafterCenterPartition_normalHolder.visible = false;
      this.studAndRafterCenterRafter_normalHolder.selected = false;
      this.studAndRafterCenterRafter_normalHolder.visible = false;
      this.studAndRafterCenter_boltAndNut.selected = false;
      this.studAndRafterCenter_boltAndNut.visible = false;
    } else {
      this.studAndRafterCenter_tHolder.selected = false;
      this.studAndRafterCenter_tHolder.visible = false;
      this.studAndRafterCenterPartition_normalHolder.selected = true;
      this.studAndRafterCenterPartition_normalHolder.visible = true;
      this.studAndRafterCenterRafter_normalHolder.selected = true;
      this.studAndRafterCenterRafter_normalHolder.visible = true;
      this.studAndRafterCenter_boltAndNut.selected = true;
      this.studAndRafterCenter_boltAndNut.visible = true;
    }
    // 대각 : 대각T 고정구(25/25mm,32/32mm) vs. 일반 고정구
    // 하드코딩
    if (
      this.design.preference.endpieceDiagonalTholderFlag === true &&
      this.stud_pipe.specCrossSize === rafterPipeSample.specCrossSize &&
      (this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_25_MM ||
        this.stud_pipe.specCrossSize === CONST.ITEM_SPEC_VALUE_32_MM)
    ) {
      this.studAndRafterDiagonal_diagonalTHolder.selected = true;
      this.studAndRafterDiagonal_diagonalTHolder.visible = true;
      this.studAndRafterDiagonaPartition_normalHolder.selected = false;
      this.studAndRafterDiagonaPartition_normalHolder.visible = false;
      this.studAndRafterDiagonalRafter_normalHolder.selected = false;
      this.studAndRafterDiagonalRafter_normalHolder.visible = false;
      this.studAndRafterDiagonal_boltAndNut.selected = false;
      this.studAndRafterDiagonal_boltAndNut.visible = false;
    } else {
      this.studAndRafterDiagonal_diagonalTHolder.selected = false;
      this.studAndRafterDiagonal_diagonalTHolder.visible = false;
      this.studAndRafterDiagonaPartition_normalHolder.selected = true;
      this.studAndRafterDiagonaPartition_normalHolder.visible = true;
      this.studAndRafterDiagonalRafter_normalHolder.selected = true;
      this.studAndRafterDiagonalRafter_normalHolder.visible = true;
      this.studAndRafterDiagonal_boltAndNut.selected = true;
      this.studAndRafterDiagonal_boltAndNut.visible = true;
    }
    this.stud_screw.selected = true;
    this.stud_screw.visible = true;

    /// //////// 수량 ///////////

    this.stud_pipe.designQuantity =
      ((this.studLengthMax * this.studLineNumber) / this.studPipeSample.specLength) *
      partitionPosition.partitionQuantity *
      CONST.NUM_EXTRA_RATE_ENDPIECE_STUD_PIPE;

    this.studAndRafterCenter_tHolder.designQuantity =
      1 * partitionPosition.partitionQuantity * CONST.NUM_EXTRA_RATE_T_HOLDER;

    this.studAndRafterCenterPartition_normalHolder.designQuantity = 1 * partitionPosition.partitionQuantity;

    this.studAndRafterCenterRafter_normalHolder.designQuantity = 1 * partitionPosition.partitionQuantity;

    this.studAndRafterCenter_boltAndNut.designQuantity =
      this.studAndRafterCenterPartition_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.studAndRafterDiagonal_diagonalTHolder.designQuantity =
      (this.studLineNumber - 1) * partitionPosition.partitionQuantity * CONST.NUM_EXTRA_RATE_DIAGONAL_T_HOLDER;

    this.studAndRafterDiagonaPartition_normalHolder.designQuantity =
      (this.studLineNumber - 1) * partitionPosition.partitionQuantity * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.studAndRafterDiagonalRafter_normalHolder.designQuantity =
      (this.studLineNumber - 1) * partitionPosition.partitionQuantity * CONST.NUM_EXTRA_RATE_NORMAL_HOLDER;

    this.studAndRafterDiagonal_boltAndNut.designQuantity =
      this.studAndRafterDiagonaPartition_normalHolder.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_BOLT_AND_NUT;

    this.stud_screw.designQuantity =
      ((this.studAndRafterCenter_tHolder.getSelectedQuantity() * 3 +
        this.studAndRafterCenterPartition_normalHolder.getSelectedQuantity() * 2 +
        this.studAndRafterCenterRafter_normalHolder.getSelectedQuantity() * 2 +
        this.studAndRafterDiagonal_diagonalTHolder.getSelectedQuantity() * 3 +
        this.studAndRafterDiagonaPartition_normalHolder.getSelectedQuantity() * 2 +
        this.studAndRafterDiagonalRafter_normalHolder.getSelectedQuantity() * 2) /
        this.stud_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 샛기둥 최대 길이 <- 동고(기본정보)
   */
  public algorithm_studLengthMax(): void {
    this.studLengthMax = this.basicLevel.heightMax;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [칸막이 샛기둥] 파이프 <- 칸막이 샛기둥 파이프 샘플
   */
  public algorithmSpec_stud_pipe(): void {
    this.stud_pipe.specs = this.studPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래] T 고정구 <- 칸막이 샛기둥 파이프 샘플, 서까래 파이프 샘플(기초 골조)
   */
  public algorithmSpec_studAndRafterCenter_tHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.studAndRafterCenter_tHolder.specPipeType =
        this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specPipeType;
      this.studAndRafterCenter_tHolder.specCrossSize1 =
        this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
      this.studAndRafterCenter_tHolder.specCrossSize2 = this.studPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래 (1)] 일반 고정구 <- 칸막이 샛기둥 파이프 샘플
   */
  public algorithmSpec_studAndRafterCenterPartition_normalHolder(): void {
    this.studAndRafterCenterPartition_normalHolder.specPipeType = this.studPipeSample.specPipeType;
    this.studAndRafterCenterPartition_normalHolder.specCrossSize1 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플(기초 골조)
   */
  public algorithmSpec_studAndRafterCenterRafter_normalHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.studAndRafterCenterRafter_normalHolder.specPipeType =
        this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specPipeType;
      this.studAndRafterCenterRafter_normalHolder.specCrossSize1 =
        this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_studAndRafterCenter_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래] 나사 <- 공통 샘플
   */
  public algorithmSpec_studAndRafterCenter_screw(): void {}

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래] 대각T 고정구 <- 칸막이 샛기둥 파이프 샘플, 서까래 파이프 샘플(기초 골조)
   */
  public algorithmSpec_studAndRafterDiagonal_diagonalTHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.studAndRafterDiagonal_diagonalTHolder.specPipeType =
        this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specPipeType;
      this.studAndRafterDiagonal_diagonalTHolder.specCrossSize1 =
        this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
      this.studAndRafterDiagonal_diagonalTHolder.specCrossSize2 = this.studPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래 (1)] 일반 고정구 <- 칸막이 샛기둥 파이프 샘플
   */
  public algorithmSpec_studAndRafterDiagonaPartition_normalHolder(): void {
    this.studAndRafterDiagonaPartition_normalHolder.specPipeType = this.studPipeSample.specPipeType;
    this.studAndRafterDiagonaPartition_normalHolder.specCrossSize1 = this.studPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래 (2)] 일반 고정구 <- 서까래 파이프 샘플(기초 골조)
   */
  public algorithmSpec_studAndRafterDiagonalRafter_normalHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.studAndRafterDiagonalRafter_normalHolder.specPipeType =
        this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specPipeType;
      this.studAndRafterDiagonalRafter_normalHolder.specCrossSize1 =
        this.struct.trunkWork.level1.trunkPosition.rafterPart.rafterPipeSample.specCrossSize;
    }
  }

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래] 볼트&너트 <- 공통 샘플
   */
  public algorithmSpec_studAndRafterDiagonal_boltAndNut(): void {}

  /**
   * 규격 알고리즘: [칸막이 샛기둥 + 서까래] 나사 <- 공통 샘플
   */
  public algorithmSpec_stud_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
