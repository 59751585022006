import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { CurCoverSampleIL_NormalPad } from "vhows-design/src/object/design/curtain/cover/CurCoverSampleIL_NormalPad";
import { CurCoverPositionIL } from "vhows-design/src/object/design/curtain/cover/CurCoverPositionIL";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurCoverSampleIL_NormalPad],
})
export class CurCoverPartIL_NormalPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public normalPadSample: CurCoverSampleIL_NormalPad; // 일반 패드 샘플

  // 아이템
  public normalPadColumn_normalPad: ItemPad;
  public normalPadFront_normalPad: ItemPad;
  public normalPadBack_normalPad: ItemPad;
  public normalPad_padConnectionPin: ItemPadConnector;
  public normalPad_normalPadSpring: ItemPadSpring;
  public normalPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.normalPadSample = new CurCoverSampleIL_NormalPad();

    this.sampleAC = [this.normalPadSample];

    // 아이템
    this.normalPadColumn_normalPad = new ItemPad();
    this.normalPadFront_normalPad = new ItemPad();
    this.normalPadBack_normalPad = new ItemPad();
    this.normalPad_padConnectionPin = new ItemPadConnector();
    this.normalPad_normalPadSpring = new ItemPadSpring();
    this.normalPad_screw = new ItemScrew();

    this.itemAC = [
      this.normalPadColumn_normalPad,
      this.normalPadFront_normalPad,
      this.normalPadBack_normalPad,
      this.normalPad_padConnectionPin,
      this.normalPad_normalPadSpring,
      this.normalPad_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.normalPadSample = <CurCoverSampleIL_NormalPad>this.sampleAC[0];

    // 아이템
    this.normalPadColumn_normalPad = <ItemPad>this.itemAC[0];
    this.normalPadFront_normalPad = <ItemPad>this.itemAC[1];
    this.normalPadBack_normalPad = <ItemPad>this.itemAC[2];
    this.normalPad_padConnectionPin = <ItemPadConnector>this.itemAC[3];
    this.normalPad_normalPadSpring = <ItemPadSpring>this.itemAC[4];
    this.normalPad_screw = <ItemScrew>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.normalPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드 고정",
        "칼라 철, 0.6T, 6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.normalPadColumn_normalPad.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드 고정(기둥)",
        "칼라 철, 0.6T, 6m",
      );
      this.normalPadFront_normalPad.setDefaultData(
        1,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드 고정(앞면)",
        "칼라 철, 0.6T, 6m",
      );
      this.normalPadBack_normalPad.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_PAD,
        CONST.ITEM_NAME_NORMAL_PAD,
        "일반 패드 고정(뒷면)",
        "칼라 철, 0.6T, 6m",
      );
      this.normalPad_padConnectionPin.setDefaultData(
        3,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "일반 패드 고정",
        "칼라 철",
      );
      this.normalPad_normalPadSpring.setDefaultData(
        4,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "일반 패드 고정",
        "일반, 2m",
      );
      this.normalPad_screw.setDefaultData(
        5,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "일반 패드 고정",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const curtaionPosition: CurCoverPositionIL = <CurCoverPositionIL>this.position;
    let pocketLength: number;
    let upperLength: number;
    let lowerLength: number;
    let columnLength: number;
    let gateLength: number;
    // 외부
    const curSwitchLevel: CurSwitchLevelIL = <CurSwitchLevelIL>this.struct.curSwitchWorkIL.levelAC[this.level.index];

    let frontPosition: EndpiecePositionIL | EndpiecePositionVL;
    let backPosition: EndpiecePositionIL | EndpiecePositionVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      frontPosition = (<EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index]).frontPosition;
      backPosition = (<EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index]).backPosition;
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      frontPosition = (<EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index]).frontPosition;
      backPosition = (<EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index]).backPosition;
    }

    /// //////// 선택, 가시성 ///////////

    if (curSwitchLevel.columnPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP) {
      this.normalPadColumn_normalPad.selected = true;
      this.normalPadColumn_normalPad.visible = true;
    } else {
      this.normalPadColumn_normalPad.selected = false;
      this.normalPadColumn_normalPad.visible = false;
    }
    if (
      curtaionPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_ENDPIECE ||
      curtaionPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_POCKET_SEP_ENDPIECE ||
      curtaionPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_SEP_ENDPIECE ||
      curtaionPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_COLUMN_SEP_CEILING_AND_ENDPIECE ||
      curtaionPosition.scopeType === CONST.LB_COVER_SCOPE_TYPE_CEILING_AND_ENDPIECE
    ) {
      this.normalPadFront_normalPad.selected = true;
      this.normalPadFront_normalPad.visible = true;
      this.normalPadBack_normalPad.selected = true;
      this.normalPadBack_normalPad.visible = true;
    } else {
      this.normalPadFront_normalPad.selected = false;
      this.normalPadFront_normalPad.visible = false;
      this.normalPadBack_normalPad.selected = false;
      this.normalPadBack_normalPad.visible = false;
    }
    if (
      this.normalPadColumn_normalPad.selected === true ||
      this.normalPadFront_normalPad.selected === true ||
      this.normalPadBack_normalPad.selected === true
    ) {
      this.normalPad_normalPadSpring.selected = true;
      this.normalPad_normalPadSpring.visible = true;
      this.normalPad_padConnectionPin.setSelectedByPad(true, this.normalPadColumn_normalPad.specMaterial);
      this.normalPad_padConnectionPin.visible = true;
      this.normalPad_screw.selected = true;
      this.normalPad_screw.visible = true;
    } else {
      this.normalPad_normalPadSpring.selected = false;
      this.normalPad_normalPadSpring.visible = false;
      this.normalPad_padConnectionPin.setSelectedByPad(false, this.normalPadColumn_normalPad.specMaterial);
      this.normalPad_padConnectionPin.visible = false;
      this.normalPad_screw.selected = false;
      this.normalPad_screw.visible = false;
    }

    /// //////// 수량 ///////////

    this.normalPadColumn_normalPad.designQuantity =
      (this.basicLevel.length * 2) / this.normalPadColumn_normalPad.specLength;

    pocketLength = this.basicLevel.width * this.basic.buildingNumber;
    upperLength = this.basicLevel.width * this.basic.buildingNumber;
    lowerLength = this.basicLevel.width * this.basic.buildingNumber;
    columnLength = this.basicLevel.heightShoulder * (this.basic.buildingNumber + 1);
    // 하드코딩
    gateLength = (1.3 * 4 + 2.4 * 3) * frontPosition.gatePart.doorQuantity * frontPosition.gateBuildingNumber;
    if (curSwitchLevel.frontPosition.selected === true) {
      this.normalPadFront_normalPad.designQuantity =
        (pocketLength + upperLength) / this.normalPadFront_normalPad.specLength;
    } else {
      this.normalPadFront_normalPad.designQuantity =
        (pocketLength + upperLength + lowerLength + columnLength + gateLength) /
        this.normalPadFront_normalPad.specLength;
    }

    // 하드코딩
    gateLength = (1.3 * 4 + 2.4 * 3) * backPosition.gatePart.doorQuantity * backPosition.gateBuildingNumber;
    if (curSwitchLevel.backPosition.selected === true) {
      this.normalPadBack_normalPad.designQuantity =
        (pocketLength + upperLength) / this.normalPadBack_normalPad.specLength;
    } else {
      this.normalPadBack_normalPad.designQuantity =
        (pocketLength + upperLength + lowerLength + columnLength + gateLength) /
        this.normalPadBack_normalPad.specLength;
    }

    this.normalPad_padConnectionPin.designQuantity =
      this.normalPadColumn_normalPad.getSelectedQuantity() +
      this.normalPadFront_normalPad.getSelectedQuantity() +
      this.normalPadBack_normalPad.getSelectedQuantity();

    let normalPad_normalPadSpring_designQuantity: number;
    normalPad_normalPadSpring_designQuantity =
      (this.normalPadColumn_normalPad.getSelectedQuantity() * this.normalPadColumn_normalPad.specLength) /
      this.normalPad_normalPadSpring.specLength;
    normalPad_normalPadSpring_designQuantity +=
      (this.normalPadFront_normalPad.getSelectedQuantity() * this.normalPadFront_normalPad.specLength) /
      this.normalPad_normalPadSpring.specLength;
    normalPad_normalPadSpring_designQuantity +=
      (this.normalPadBack_normalPad.getSelectedQuantity() * this.normalPadBack_normalPad.specLength) /
      this.normalPad_normalPadSpring.specLength;
    this.normalPad_normalPadSpring.designQuantity =
      normalPad_normalPadSpring_designQuantity * CONST.NUM_EXTRA_RATE_PAD_SPRING;

    this.normalPad_screw.designQuantity =
      ((this.normalPadColumn_normalPad.getSelectedQuantity() * 6 +
        this.normalPadFront_normalPad.getSelectedQuantity() * 6 +
        this.normalPadBack_normalPad.getSelectedQuantity() * 6 +
        this.normalPad_padConnectionPin.getSelectedQuantity() * 2) /
        this.normalPad_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 패드 고정(기둥)] 일반 패드 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPadColumn_normalPad(): void {
    this.normalPadColumn_normalPad.specs = this.normalPadSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 패드 고정(앞면)] 일반 패드 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPadFront_normalPad(): void {
    this.normalPadFront_normalPad.specs = this.normalPadSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 패드 고정(뒷면)] 일반 패드 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPadBack_normalPad(): void {
    this.normalPadBack_normalPad.specs = this.normalPadSample.specs;
  }

  /**
   * 규격 알고리즘: [일반 패드 고정] 일반 패드 스프링 <- 일반 패드 스프링 샘플(피복 고정>기둥(측면))
   */
  public algorithmSpec_normalPad_normalPadSpring(): void {
    this.normalPad_normalPadSpring.specMaterial =
      this.struct.fixingWorkIL.level1.columnPosition.normalPadPart.normalPadSpringSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [일반 패드 고정] 패드 연결핀 <- 일반 패드 샘플
   */
  public algorithmSpec_normalPad_padConnectionPin(): void {
    this.normalPad_padConnectionPin.specMaterial = this.normalPadSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [일반 패드 고정] 나사 <- 공통 샘플
   */
  public algorithmSpec_normalPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
