import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemConnector } from "vhows-design/src/object/design/item/list/ItemConnector";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WingSampleIL_WingStraightPipe } from "vhows-design/src/object/design/frame/wing/WingSampleIL_WingStraightPipe";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject({
  knownTypes: [WingSampleIL_WingStraightPipe],
})
export class WingPartIL_WingStraight extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public wingStraightPipeSample: WingSampleIL_WingStraightPipe; // 방풍벽 가로대 파이프 샘플

  // 아이템
  public wingStraight_pipe: ItemPipe;
  public wingStraightAndWingStraight_connectionPin: ItemConnector;
  public wingStraightCenterAndWingRafter_steelStringGrabber: ItemHolder;
  public floorWingStraightCenterAndWingRafter_steelStringGrabber: ItemHolder;
  public wingStraightEdgeAndWingRafter_saddleHolder: ItemHolder;
  public wingStraight_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _wingStraightLength: number = 0; // 방풍벽 가로대 길이
  @jsonMember(Number)
  public _wingStraightLineNumber: number; // 방풍벽 가로대 줄수

  /**
   * 방풍벽 가로대 길이
   */
  public get wingStraightLength(): number {
    return this._wingStraightLength;
  }

  //
  public set wingStraightLength(value: number) {
    this._wingStraightLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  /**
   * 방풍벽 가로대 줄수
   */
  public get wingStraightLineNumber(): number {
    return this._wingStraightLineNumber;
  }

  //
  public set wingStraightLineNumber(value: number) {
    this._wingStraightLineNumber = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.wingStraightPipeSample = new WingSampleIL_WingStraightPipe();

    this.sampleAC = [this.wingStraightPipeSample];

    // 아이템
    this.wingStraight_pipe = new ItemPipe();
    this.wingStraightAndWingStraight_connectionPin = new ItemConnector();
    this.wingStraightCenterAndWingRafter_steelStringGrabber = new ItemHolder();
    this.floorWingStraightCenterAndWingRafter_steelStringGrabber = new ItemHolder();
    this.wingStraightEdgeAndWingRafter_saddleHolder = new ItemHolder();
    this.wingStraight_screw = new ItemScrew();

    this.itemAC = [
      this.wingStraight_pipe,
      this.wingStraightAndWingStraight_connectionPin,
      this.wingStraightCenterAndWingRafter_steelStringGrabber,
      this.floorWingStraightCenterAndWingRafter_steelStringGrabber,
      this.wingStraightEdgeAndWingRafter_saddleHolder,
      this.wingStraight_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.wingStraightPipeSample = <WingSampleIL_WingStraightPipe>this.sampleAC[0];

    // 아이템
    this.wingStraight_pipe = <ItemPipe>this.itemAC[0];
    this.wingStraightAndWingStraight_connectionPin = <ItemConnector>this.itemAC[1];
    this.wingStraightCenterAndWingRafter_steelStringGrabber = <ItemHolder>this.itemAC[2];
    this.floorWingStraightCenterAndWingRafter_steelStringGrabber = <ItemHolder>this.itemAC[3];
    this.wingStraightEdgeAndWingRafter_saddleHolder = <ItemHolder>this.itemAC[4];
    this.wingStraight_screw = <ItemScrew>this.itemAC[5];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param wingStraightLineNumber: number 방풍벽 가로대 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    wingStraightLineNumber: number = 0,
    wingBracingQuantity: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._wingStraightLineNumber = wingStraightLineNumber;

    // 샘플
    if (this.level.index === 0) {
      this.wingStraightPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "방풍벽 가로대/브레싱",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
    } else {
      this.wingStraightPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "방풍벽 가로대/브레싱",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.wingStraight_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "방풍벽 가로대/브레싱",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.wingStraightAndWingStraight_connectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_CONNECTION_PIN,
        CONST.ITEM_NAME_CONNECTION_PIN,
        "방풍벽 가로대",
        "원형, 일반, 25mm, 25mm",
      );
      this.wingStraightCenterAndWingRafter_steelStringGrabber.setDefaultData(
        2,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "방풍벽 가로대 + 서까래",
        "원형, 일반, 32mm, 25mm",
      );
      this.floorWingStraightCenterAndWingRafter_steelStringGrabber.setDefaultData(
        3,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "방풍벽 바닥 가로대 + 서까래",
        "원형, 일반, 25mm, 32mm",
      );
      this.wingStraightEdgeAndWingRafter_saddleHolder.setDefaultData(
        4,
        CONST.ITEM_ID_SADDLE_HOLDER,
        CONST.ITEM_NAME_SADDLE_HOLDER,
        "방풍벽 가로대(끝) + 서까래",
        "원형, 일반, 25mm, -",
      );
      this.wingStraight_screw.setDefaultData(
        5,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "방풍벽 가로대",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 6 && (<Item>this.itemAC[2]).productId === CONST.ITEM_ID_SCREW) {
      // 나사 하나로 합치기
      let screwQuantity: number;
      screwQuantity =
        (<ItemScrew>this.itemAC[2]).getSelectedQuantity() + (<ItemScrew>this.itemAC[5]).getSelectedQuantity();
      (<ItemScrew>this.itemAC[5]).selected = screwQuantity > 0;
      (<ItemScrew>this.itemAC[5]).visible = screwQuantity > 0;
      (<ItemScrew>this.itemAC[5]).purpose = "방풍벽 가로대";
      (<ItemScrew>this.itemAC[5]).designQuantity = screwQuantity;
      this.itemAC.splice(2, 1);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    if (this.itemAC.length === 5 && (<Item>this.itemAC[4]).productId === CONST.ITEM_ID_SCREW) {
      // 강선 조리개 추가
      const floorWingStraightCenterAndWingRafter_steelStringGrabber: ItemHolder = new ItemHolder();
      floorWingStraightCenterAndWingRafter_steelStringGrabber.setAssociation(
        design,
        struct,
        work,
        level,
        position,
        this,
      );
      floorWingStraightCenterAndWingRafter_steelStringGrabber.setDefaultData(
        3,
        CONST.ITEM_ID_STEEL_STRING_GRABBER,
        CONST.ITEM_NAME_STEEL_STRING_GRABBER,
        "방풍벽 바닥 가로대 + 서까래",
        "원형, 일반, 25mm, 32mm",
      );
      this.itemAC.splice(3, 0, floorWingStraightCenterAndWingRafter_steelStringGrabber);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_wingStraightLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const wingPosition: WingPositionIL = <WingPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.wingStraight_pipe.checkSelected(true);
    this.wingStraightAndWingStraight_connectionPin.checkSelected(true);
    this.wingStraightCenterAndWingRafter_steelStringGrabber.selected = true;
    this.wingStraightCenterAndWingRafter_steelStringGrabber.visible = true;
    this.floorWingStraightCenterAndWingRafter_steelStringGrabber.selected = true;
    this.floorWingStraightCenterAndWingRafter_steelStringGrabber.visible = true;
    this.wingStraightEdgeAndWingRafter_saddleHolder.selected = true;
    this.wingStraightEdgeAndWingRafter_saddleHolder.visible = true;
    this.wingStraight_screw.selected = true;
    this.wingStraight_screw.visible = true;

    /// //////// 수치 ///////////

    let wingBracingLength: number = 0;
    if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
      wingBracingLength = 5 * 4; // 5m * 4개
    } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
      wingBracingLength = 5 * 2; // 5m * 2개
    }

    /// //////// 수량 ///////////

    this.wingStraight_pipe.designQuantity =
      ((this.wingStraight_pipe.getConnectedPipeLength(this.wingStraightLength, this.wingStraightLineNumber) +
        wingBracingLength) /
        this.wingStraight_pipe.specLength) *
      CONST.NUM_EXTRA_RATE_STRAIGHT_PIPE;

    if (this.wingStraightLength > this.wingStraight_pipe.specLength) {
      this.wingStraightAndWingStraight_connectionPin.designQuantity =
        this.wingStraight_pipe.getSelectedQuantity() * CONST.NUM_EXTRA_RATE_CONNECTION_PIN;
    } else {
      this.wingStraightAndWingStraight_connectionPin.designQuantity = 0;
    }

    if (this.position.type === CONST.LB_WING_TYPE_DEFAULT) {
      if (this.level.index === 0) {
        if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
          if (this.wingStraightLineNumber > 2) {
            this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity =
              wingPosition.wingRafterPart.wingRafterQuantity *
              ((this.wingStraightLineNumber - 2) / 2) *
              CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
          } else {
            this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity = 0;
          }
          this.floorWingStraightCenterAndWingRafter_steelStringGrabber.designQuantity =
            wingPosition.wingRafterPart.wingRafterQuantity * (2 / 2) * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
          if (this.wingStraightLineNumber > 1) {
            this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity =
              wingPosition.wingRafterPart.wingRafterQuantity *
              (this.wingStraightLineNumber - 1) *
              CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
          } else {
            this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity = 0;
          }
          this.floorWingStraightCenterAndWingRafter_steelStringGrabber.designQuantity =
            wingPosition.wingRafterPart.wingRafterQuantity * 1 * CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        }
      } else {
        if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
          this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity =
            ((wingPosition.wingRafterPart.wingRafterQuantity * this.wingStraightLineNumber) / 2) *
            CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
          this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity =
            wingPosition.wingRafterPart.wingRafterQuantity *
            this.wingStraightLineNumber *
            CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
        }
      }
    } else if (this.position.type === CONST.LB_WING_TYPE_SIMPLE) {
      if (this.level.index === 0) {
        this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity = 0;
        this.floorWingStraightCenterAndWingRafter_steelStringGrabber.designQuantity = 0;
      } else {
        if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
          if (this.wingStraightLineNumber > 2) {
            this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity =
              wingPosition.wingRafterPart.wingRafterQuantity *
              ((this.wingStraightLineNumber - 2) / 2) *
              CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
          } else {
            this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity = 0;
          }
          this.floorWingStraightCenterAndWingRafter_steelStringGrabber.designQuantity = 0;
        } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
          if (this.wingStraightLineNumber > 1) {
            this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity =
              wingPosition.wingRafterPart.wingRafterQuantity *
              (this.wingStraightLineNumber - 1) *
              CONST.NUM_EXTRA_RATE_STEEL_STRING_GRABBER;
          } else {
            this.wingStraightCenterAndWingRafter_steelStringGrabber.designQuantity = 0;
          }
          this.floorWingStraightCenterAndWingRafter_steelStringGrabber.designQuantity = 0;
        }
      }
    }

    if (this.position.type === CONST.LB_WING_TYPE_DEFAULT) {
      this.wingStraightEdgeAndWingRafter_saddleHolder.designQuantity =
        this.wingStraightLineNumber * 2 * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
    } else if (this.position.type === CONST.LB_WING_TYPE_SIMPLE) {
      if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
        this.wingStraightEdgeAndWingRafter_saddleHolder.designQuantity =
          (this.basicLevel.length / 2) * 2 * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
      } else if (this.position.label === CONST.LB_POSITION_FRONT || this.position.label === CONST.LB_POSITION_BACK) {
        this.wingStraightEdgeAndWingRafter_saddleHolder.designQuantity =
          ((this.basicLevel.width * this.basic.buildingNumber) / 2) * 2 * CONST.NUM_EXTRA_RATE_SADDLE_HOLDER;
      }
    }

    this.wingStraight_screw.designQuantity =
      ((this.wingStraightAndWingStraight_connectionPin.getSelectedQuantity() * 2 +
        this.wingStraightEdgeAndWingRafter_saddleHolder.getSelectedQuantity() * 3) /
        this.wingStraight_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  /**
   * 알고리즘: 방풍벽 가로대 길이 <- 길이(기본정보)
   */
  public algorithm_wingStraightLength(): void {
    if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
      this.wingStraightLength = this.basicLevel.length + this.design.preference.connectedPipeExtraLength;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.wingStraightLength =
        this.basicLevel.width * this.basic.buildingNumber +
        this.basic.wingLeft +
        this.basic.wingRight +
        this.basic.wingFront * 2 +
        this.design.preference.connectedPipeExtraLength;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.wingStraightLength =
        this.basicLevel.width * this.basic.buildingNumber +
        this.basic.wingLeft +
        this.basic.wingRight +
        this.basic.wingBack * 2 +
        this.design.preference.connectedPipeExtraLength;
    }
  }

  /**
   * 알고리즘: 방풍벽 가로대 줄수 <- 위치
   */
  public algorithm_wingStraightLineNumber(): void {
    if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.wingStraightLineNumber = Math.round(
        (this.level as WingLevelIL).wingPosition.wingStraightPart.wingStraightLineNumber / 2,
      );
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.wingStraightLineNumber = (this.level as WingLevelIL).frontPosition.wingStraightPart.wingStraightLineNumber;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [방풍벽 가로대/브레싱] 파이프 <- 방풍벽 가로대 파이프 샘플
   */
  public algorithmSpec_wingStraight_pipe(): void {
    this.wingStraight_pipe.specs = this.wingStraightPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [방풍벽 가로대] 연결핀 <- 방풍벽 가로대 파이프 샘플
   */
  public algorithmSpec_wingStraightAndWingStraight_connectionPin(): void {
    this.wingStraightAndWingStraight_connectionPin.specPipeType = this.wingStraightPipeSample.specPipeType;
    this.wingStraightAndWingStraight_connectionPin.specCrossSize1 = this.wingStraightPipeSample.specCrossSize;
    this.wingStraightAndWingStraight_connectionPin.specCrossSize2 = this.wingStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 가로대 + 서까래] 강선 조리개 <- 방풍벽 가로대 파이프 샘플, 방풍벽 서까래 파이프 샘플
   */
  public algorithmSpec_wingStraightCenterAndWingRafter_steelStringGrabber(): void {
    this.wingStraightCenterAndWingRafter_steelStringGrabber.specPipeType = (<WingPositionIL>(
      this.position
    )).wingRafterPart.wingRafterPipeSample.specPipeType;
    this.wingStraightCenterAndWingRafter_steelStringGrabber.specCrossSize1 = (<WingPositionIL>(
      this.position
    )).wingRafterPart.wingRafterPipeSample.specCrossSize;
    this.wingStraightCenterAndWingRafter_steelStringGrabber.specCrossSize2 = this.wingStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 바닥 가로대 + 서까래] 강선 조리개 <- 방풍벽 서까래 파이프 샘플, 방풍벽 가로대 파이프 샘플
   */
  public algorithmSpec_floorWingStraightCenterAndWingRafter_steelStringGrabber(): void {
    this.floorWingStraightCenterAndWingRafter_steelStringGrabber.specPipeType =
      this.wingStraightPipeSample.specPipeType;
    this.floorWingStraightCenterAndWingRafter_steelStringGrabber.specCrossSize1 =
      this.wingStraightPipeSample.specCrossSize;
    this.floorWingStraightCenterAndWingRafter_steelStringGrabber.specCrossSize2 = (<WingPositionIL>(
      this.position
    )).wingRafterPart.wingRafterPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 가로대(끝) + 서까래] 새들 고정구 <- 방풍벽 가로대 파이프 샘플
   */
  public algorithmSpec_wingStraightEdgeAndWingRafter_saddleHolder(): void {
    this.wingStraightEdgeAndWingRafter_saddleHolder.specPipeType = this.wingStraightPipeSample.specPipeType;
    this.wingStraightEdgeAndWingRafter_saddleHolder.specCrossSize1 = this.wingStraightPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [방풍벽 가로대] 나사 <- 공통 샘플
   */
  public algorithmSpec_wingStraight_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
