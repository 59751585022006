import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { MaterialPart_Custom } from "vhows-design/src/object/design/other/material/MaterialPart_Custom";
import { HydSystemPosition } from "vhows-design/src/object/design/hydroponics/system/HydSystemPosition";
import { HydCultivationPart_Bed } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPart_Bed";
import { HydCultivationPart_CultivationMaterial } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPart_CultivationMaterial";
import { HydCultivationPart_GroundRail } from "vhows-design/src/object/design/hydroponics/cultivation/HydCultivationPart_GroundRail";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2020-02-21
 */
@jsonObject({
  knownTypes: [
    HydCultivationPart_Bed,
    HydCultivationPart_CultivationMaterial,
    HydCultivationPart_GroundRail,
    MaterialPart_Custom,
  ],
})
export class HydCultivationPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public bedPart: HydCultivationPart_Bed;
  public cultivationPart: HydCultivationPart_CultivationMaterial;
  public groundRailPart: HydCultivationPart_GroundRail;
  public customPart: MaterialPart_Custom;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _cultivationWay: string; // 재배대 방향
  @jsonMember(String)
  public _cultivationMaterialType: string; // 재배자재 형태
  @jsonMember(String)
  public _groundRailType: string; // 지면레일 형태

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 재배대 방향
   */
  public get cultivationWay(): string {
    return this._cultivationWay;
  }

  //
  public set cultivationWay(value: string) {
    this._cultivationWay = value;

    // 알고리즘
    this.bedPart.algorithm_hallwayWidth();
    this.bedPart.algorithm_nftBedSpaceNumber();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 재배자재 형태
   */
  public get cultivationMaterialType(): string {
    return this._cultivationMaterialType;
  }

  //
  public set cultivationMaterialType(value: string) {
    this._cultivationMaterialType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 지면레일 형태
   */
  public get groundRailType(): string {
    return this._groundRailType;
  }

  //
  public set groundRailType(value: string) {
    this._groundRailType = value;

    // 알고리즘
    this.groundRailPart.groundRailPipeSample.algorithmSpec();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 양액 제어부
    this.struct.hydSystemWork.level1.wholePosition.algorithm_nutrientType();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.bedPart = new HydCultivationPart_Bed();
    this.cultivationPart = new HydCultivationPart_CultivationMaterial();
    this.groundRailPart = new HydCultivationPart_GroundRail();
    this.customPart = new MaterialPart_Custom();

    this.partAC = [this.bedPart, this.cultivationPart, this.groundRailPart, this.customPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.bedPart = <HydCultivationPart_Bed>this.partAC[0];
    this.cultivationPart = <HydCultivationPart_CultivationMaterial>this.partAC[1];
    this.groundRailPart = <HydCultivationPart_GroundRail>this.partAC[2];
    this.customPart = <MaterialPart_Custom>this.partAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param cultivationWay: string 재배대 방향
   * @param cultivationMaterialType: string 재배자재 형태
   * @param groundRailType: string 지면레일 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    cultivationWay: string = "",
    cultivationMaterialType: string = "",
    groundRailType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._cultivationWay = cultivationWay;
    this._cultivationMaterialType = cultivationMaterialType;
    this._groundRailType = groundRailType;

    if (this.level.index >= 0) {
      // 베드 파트
      this.bedPart.setDefaultData(
        CONST.INDEX_CM_HYD_CULTIVATION_BED,
        false,
        false,
        false,
        CONST.LB_HYD_CULTIVATION_BED,
        "",
        5,
        0,
        0,
        10,
        0.8,
        0.2,
      );
      // 재배자재 파트
      this.cultivationPart.setDefaultData(
        CONST.INDEX_CM_HYD_CULTIVATION_CULTIVATION_MATERIAL,
        false,
        false,
        false,
        CONST.LB_HYD_CULTIVATION_CULTIVATION_MATERIAL,
        "",
        5,
      );
      // 지면레일 파트
      this.groundRailPart.setDefaultData(
        CONST.INDEX_CM_HYD_CULTIVATION_GROUND_RAIL,
        false,
        false,
        false,
        CONST.LB_HYD_CULTIVATION_GROUND_RAIL,
        "",
        5,
        1.5,
        55,
      );
      // 임의 파트
      this.customPart.setDefaultData(
        CONST.INDEX_CM_HYD_CULTIVATION_CUSTOM,
        false,
        false,
        true,
        CONST.LB_HYD_CULTIVATION_CUSTOM,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  /**
   * 알고리즘: 선택 <- 선택(양액시스템), 형태(양액시스템)
   */
  public algorithm_selected(): void {
    const hydSystemPosition: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_CUSTOM) {
      this.selected = false;
    } else {
      this.selected = hydSystemPosition.selected;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태, 재배자재 형태, 지면레일 형태
   */
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////
    if (this.type !== CONST.LB_HYD_CULTIVATION_TYPE_CUSTOM) {
      this.bedPart.selected = true;
      this.bedPart.visible = true;
      if (this.cultivationMaterialType === CONST.LB_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_NONE) {
        this.cultivationPart.selected = false;
        this.cultivationPart.visible = false;
      } else if (this.cultivationMaterialType === CONST.LB_HYD_CULTIVATION_CULTIVATION_MATERIAL_TYPE_UPPER_TRELLIS) {
        this.cultivationPart.selected = true;
        this.cultivationPart.visible = true;
      }
      if (this.groundRailType === CONST.LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_NONE) {
        this.groundRailPart.selected = false;
        this.groundRailPart.visible = false;
      } else if (
        this.groundRailType === CONST.LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_NORMAL ||
        this.groundRailType === CONST.LB_HYD_CULTIVATION_GROUND_RAIL_TYPE_TUBE
      ) {
        this.groundRailPart.selected = true;
        this.groundRailPart.visible = true;
      }
      this.customPart.selected = false;
      this.customPart.visible = false;
    } else {
      this.customPart.selected = true;
      this.customPart.visible = true;
      this.bedPart.selected = false;
      this.bedPart.visible = false;
      this.cultivationPart.selected = false;
      this.cultivationPart.visible = false;
      this.groundRailPart.selected = false;
      this.groundRailPart.visible = false;
    }
  }

  /**
   * 알고리즘: 베드 형태 <- 형태(양액시스템)
   */
  public algorithm_bedType(): void {
    const hydSystemPosition: HydSystemPosition = this.struct.hydSystemWork.level1.wholePosition;

    if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_SUBSTRATE_CULTURE) {
      this.type = CONST.LB_HYD_CULTIVATION_TYPE_STANDING_GUTTER;
    } else if (hydSystemPosition.type === CONST.LB_HYD_SYSTEM_TYPE_NFT) {
      this.type = CONST.LB_HYD_CULTIVATION_TYPE_NFT_BED;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
