import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { FoundationPartIL_GirthPipe } from "vhows-design/src/object/design/foundation/il/FoundationPartIL_GirthPipe";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { EndpiecePositionVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpiecePositionVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject
export class FoundationSampleIL_WidthGirthPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const girthPipePart: FoundationPartIL_GirthPipe = <FoundationPartIL_GirthPipe>this.part;
    girthPipePart.algorithmSpec_girthWidth_pipe();
    girthPipePart.algorithmSpec_girthWidth_connectionPin();
    girthPipePart.algorithmSpec_girthWidthAndColumnCylinder_fixAsibaClamp();
    girthPipePart.algorithmSpec_girthWidthAndColumnCylinder_steelStringGrabber();
    girthPipePart.algorithmSpec_girthWidthAndColumnPrism_uClamp();

    girthPipePart.algorithm_widthGirthQuantity();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    /// //////// 외부 ///////////

    // 마구리 골조
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      for (const endpiecePosition of this.struct.endpieceWorkIL.level1.positionAC as EndpiecePositionIL[]) {
        endpiecePosition.cylinderStudPart.algorithmSpec_studAndWidthGirth_fixAsibaClamp();
        endpiecePosition.cylinderStudPart.algorithmPart();
        endpiecePosition.cylinderStudSepPart.algorithmSpec_studBottomAndGirth_fixAsibaClamp();
        endpiecePosition.cylinderStudSepPart.algorithmPart();
        endpiecePosition.prismStudSepPart.algorithmSpec_studBottomAndGirth_uClamp();
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      for (const endpiecePosition of this.struct.endpieceWorkVL.level1.positionAC as EndpiecePositionVL[]) {
        endpiecePosition.endpiecePart.algorithmSpec_studAndWidthGirth_fixAsibaClamp();
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 길이방향 바닥도리 파이프 샘플, 폭(기본정보)
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = (<FoundationPartIL_GirthPipe>this.part).lengthGirthPipeSample.specsList;
  }

  /**
   * 규격 알고리즘 <- 길이방향 바닥도리 파이프 샘플, 폭(기본정보)
   */
  public algorithmSpec_specLength(): void {
    if (parseFloat(this.specThickness) >= 1.9 && (this.basicLevel.width === 6 || this.basicLevel.width === 7)) {
      this.specLength = this.basicLevel.width;
    } else {
      this.specLength = (<FoundationPartIL_GirthPipe>this.part).lengthGirthPipeSample.specLength;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
