import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 환기팬
 * @author 이덕형
 * @copyright RUNean Inc.
 */
@jsonObject
export class ItemVentFan extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [특성]
  public _specFeature: string = undefined; // 특성

  // 하우스 설계 > 상표 : [회사, 모델, 최대풍량, 크기, 팬지름, 소비전력]
  public _brandModelName: string = undefined; // 모델명
  public _brandMaxAirVolume: string = undefined; // 최대풍량
  public _brandSize: string = undefined; // 크기
  public _brandFanDiameter: string = undefined; // 팬지름
  public _brandPowerConsumption: string = undefined; // 소비전력

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 특성
   */
  public get specFeature(): string {
    return this._specFeature;
  }
  //
  public set specFeature(value: string) {
    this._specFeature = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  /**
   * 모델명
   */
  public get brandModelName(): string {
    return this._brandModelName;
  }
  //
  public set brandModelName(value: string) {
    this._brandModelName = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 최대풍량
   */
  public get brandMaxAirVolume(): string {
    return this._brandMaxAirVolume;
  }
  //
  public set brandMaxAirVolume(value: string) {
    this._brandMaxAirVolume = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 크기
   */
  public get brandSize(): string {
    return this._brandSize;
  }
  //
  public set brandSize(value: string) {
    this._brandSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 팬지름
   */
  public get brandFanDiameter(): string {
    return this._brandFanDiameter;
  }
  //
  public set brandFanDiameter(value: string) {
    this._brandFanDiameter = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  /**
   * 소비전력
   */
  public get brandPowerConsumption(): string {
    return this._brandPowerConsumption;
  }
  //
  public set brandPowerConsumption(value: string) {
    this._brandPowerConsumption = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeBrandsList();
      this.makeBrands();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.ventFanCategoryModel;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specFeature = specsArray[0];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList = this._specFeature;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    const brandsArray: string[] = this._brands.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandMaxAirVolume = brandsArray[2];
    this.brandSize = brandsArray[3];
    this.brandFanDiameter = brandsArray[4];
    this.brandPowerConsumption = brandsArray[5];
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    const brandsArray: string[] = this._brandsList.split(CONST.DELIMITER_BRAND);
    this.brandCompany = brandsArray[0];
    this.brandModelName = brandsArray[1];
    this.brandMaxAirVolume = brandsArray[2];
    this.brandSize = brandsArray[3];
    this.brandFanDiameter = brandsArray[4];
    this.brandPowerConsumption = brandsArray[5];
  }

  // @override
  protected makeBrandsList(): void {
    this.brandsList =
      this._brandCompany +
      CONST.DELIMITER_BRAND +
      this._brandModelName +
      CONST.DELIMITER_BRAND +
      this._brandMaxAirVolume +
      CONST.DELIMITER_BRAND +
      this._brandSize +
      CONST.DELIMITER_BRAND +
      this._brandFanDiameter +
      CONST.DELIMITER_BRAND +
      this._brandPowerConsumption;
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }
}
