import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemDoor } from "vhows-design/src/object/design/item/list/ItemDoor";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemOtherExpense } from "vhows-design/src/object/design/item/list/ItemOtherExpense";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { WindowPositionIL } from "vhows-design/src/object/design/other/window/il/WindowPositionIL";
import { WindowSampleIL_HingedDoor } from "vhows-design/src/object/design/other/window/il/WindowSampleIL_HingedDoor";
import { WindowSampleIL_HingedFramePipe } from "vhows-design/src/object/design/other/window/il/WindowSampleIL_HingedFramePipe";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [WindowSampleIL_HingedDoor, WindowSampleIL_HingedFramePipe],
})
export class WindowPartIL_Hinged extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public hingedDoorSample: WindowSampleIL_HingedDoor; // 여닫이문 샘플
  public hingedFramePipeSample: WindowSampleIL_HingedFramePipe; // 여닫이 창틀 파이프 샘플

  // 아이템
  public hinged_door: ItemDoor;
  public hinged_pipe: ItemPipe;
  public hinged_hinge: ItemFrameOther;
  public hinged_screw: ItemScrew;
  public hinged_welding: ItemOtherExpense;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _frameWidth: number; // 창틀 폭
  @jsonMember(Number)
  public _frameHeight: number; // 창틀 높이

  /**
   * 창틀 폭
   */
  public get frameWidth(): number {
    return this._frameWidth;
  }

  public set frameWidth(value: number) {
    this._frameWidth = CommonUtil.fixFloat(value);

    // 알고리즘
    this.hingedDoorSample.algorithmSpec();
    this.algorithmPart();

    // 다른 파트
    (<WindowPositionIL>this.position).fixingNormalPadPart.algorithmPart();

    // 다른 위치
    this.algorithm_frameSizeByPosition();
  }

  /**
   * 창틀 높이
   */
  public get frameHeight(): number {
    return this._frameHeight;
  }

  public set frameHeight(value: number) {
    this._frameHeight = CommonUtil.fixFloat(value);

    // 알고리즘
    this.hingedDoorSample.algorithmSpec();
    this.algorithmPart();

    // 다른 파트
    (<WindowPositionIL>this.position).fixingNormalPadPart.algorithmPart();

    // 다른 위치
    this.algorithm_frameSizeByPosition();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.hingedDoorSample = new WindowSampleIL_HingedDoor();
    this.hingedFramePipeSample = new WindowSampleIL_HingedFramePipe();

    this.sampleAC = [this.hingedDoorSample, this.hingedFramePipeSample];

    // 아이템
    this.hinged_door = new ItemDoor();
    this.hinged_pipe = new ItemPipe();
    this.hinged_hinge = new ItemFrameOther();
    this.hinged_screw = new ItemScrew();
    this.hinged_welding = new ItemOtherExpense();

    this.itemAC = [this.hinged_door, this.hinged_pipe, this.hinged_hinge, this.hinged_screw, this.hinged_welding];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.hingedDoorSample = <WindowSampleIL_HingedDoor>this.sampleAC[0];
    this.hingedFramePipeSample = <WindowSampleIL_HingedFramePipe>this.sampleAC[1];

    // 아이템
    this.hinged_door = <ItemDoor>this.itemAC[0];
    this.hinged_pipe = <ItemPipe>this.itemAC[1];
    this.hinged_hinge = <ItemFrameOther>this.itemAC[2];
    this.hinged_screw = <ItemScrew>this.itemAC[3];
    this.hinged_welding = <ItemOtherExpense>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param buildNote: string 시공 방법
   * @param frameWidth: number 창틀 폭
   * @param frameHeight: number 창틀 높이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    frameWidth: number = 0,
    frameHeight: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._frameWidth = frameWidth;
    this._frameHeight = frameHeight;

    // 샘플
    if (this.level.index >= 0) {
      this.hingedDoorSample.setDefaultData(
        0,
        CONST.ITEM_ID_DOOR,
        CONST.ITEM_NAME_DOOR,
        "여닫이창문",
        "일반, 25×25mm, 1000mm, 2000mm",
      );
      this.hingedFramePipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "창틀",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.hinged_door.setDefaultData(
        0,
        CONST.ITEM_ID_DOOR,
        CONST.ITEM_NAME_DOOR,
        "여닫이창문",
        "일반, 25×25mm, 1000mm, 2000mm",
      );
      this.hinged_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "창틀",
        "원형, 일반, 25.4mm, 1.2T, 10m, -",
      );
      this.hinged_hinge.setDefaultData(2, CONST.ITEM_ID_HINGE, CONST.ITEM_NAME_HINGE, "여닫이창문", "평형");
      this.hinged_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "여닫이창문",
        "십자, 일반, 8/9*13mm, 1000개",
      );
      this.hinged_welding.setDefaultData(4, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "여닫이창문", "일반");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level, position: Position): void {
    // 아이템
    if (this.itemAC.length === 4) {
      const hinged_welding: ItemOtherExpense = new ItemOtherExpense();
      hinged_welding.setAssociation(design, struct, work, level, position, this);
      hinged_welding.setDefaultData(4, CONST.ITEM_ID_WELDING, CONST.ITEM_NAME_WELDING, "여닫이창문", "일반");
      hinged_welding.selected = false;
      hinged_welding.visible = false;
      this.itemAC.push(hinged_welding);

      // 인덱스 재설정
      CommonUtil.reindexAC(this.itemAC);
    }

    super.restoreLatestObject(design, struct, work, level, position);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const windowPosition: WindowPositionIL = <WindowPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.hinged_door.selected = true;
    this.hinged_door.visible = true;
    this.hinged_pipe.selected = true;
    this.hinged_pipe.visible = true;
    this.hinged_hinge.selected = true;
    this.hinged_hinge.visible = true;
    this.hinged_screw.selected = true;
    this.hinged_screw.visible = true;
    this.hinged_welding.selected = true;
    this.hinged_welding.visible = true;

    /// //////// 수치 ///////////

    const framePipeLength: number = CommonUtil.convertMM2M(this.frameWidth + this.frameHeight) * 2;

    /// //////// 수량 ///////////

    this.hinged_door.designQuantity = windowPosition.windowQuantity;

    this.hinged_pipe.designQuantity = (framePipeLength * windowPosition.windowQuantity) / this.hinged_pipe.specLength;

    this.hinged_hinge.designQuantity = this.hinged_door.getSelectedQuantity() * 2;

    this.hinged_screw.designQuantity =
      ((this.hinged_hinge.getSelectedQuantity() * 4) / this.hinged_screw.specUnitQuantity) * CONST.NUM_EXTRA_RATE_SCREW;

    this.hinged_welding.designQuantity =
      (framePipeLength * windowPosition.windowQuantity * this.basic.buildingNumber) / CONST.NUM_INVERVAL_WELDING;
  }

  /**
   * 알고리즘: 창틀 크기 <- 창틀 크기(포지션)
   */
  public algorithm_frameSizeByPosition(): void {
    if (this.position.label === CONST.LB_POSITION_LEFT) {
      (<WindowLevelIL>this.level).rightPosition.hingedPart.frameWidth = this.frameWidth;
      (<WindowLevelIL>this.level).rightPosition.hingedPart.frameHeight = this.frameHeight;
      (<WindowLevelIL>this.level).frontPosition.hingedPart.frameWidth = this.frameWidth;
      (<WindowLevelIL>this.level).frontPosition.hingedPart.frameHeight = this.frameHeight;
      (<WindowLevelIL>this.level).backPosition.hingedPart.frameWidth = this.frameWidth;
      (<WindowLevelIL>this.level).backPosition.hingedPart.frameHeight = this.frameHeight;
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      (<WindowLevelIL>this.level).backPosition.hingedPart.frameWidth = this.frameWidth;
      (<WindowLevelIL>this.level).backPosition.hingedPart.frameHeight = this.frameHeight;
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [여닫이창문] 문 <- 여닫이창문 샘플
   */
  public algorithmSpec_hinged_door(): void {
    this.hinged_door.specs = this.hingedDoorSample.specs;
  }

  /**
   * 규격 알고리즘: [창틀] 파이프 <- 여닫이 창틀 파이프 샘플
   */
  public algorithmSpec_hinged_pipe(): void {
    this.hinged_pipe.specs = this.hingedFramePipeSample.specs;
  }

  /**
   * 규격 알고리즘: [여닫이창문] 경첩 <- 공통 샘플
   */
  public algorithmSpec_hinged_hinge(): void {}

  /**
   * 규격 알고리즘: [여닫이창문] 나사 <- 공통 샘플
   */
  public algorithmSpec_hinged_screw(): void {}

  /**
   * 규격 알고리즘: [여닫이창문] 용접 <- 공통 샘플
   */
  public algorithmSpec_hinged_welding(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
