import { jsonObject } from "typedjson";

import { ItemWateringPipe } from "vhows-design/src/object/design/item/list/ItemWateringPipe";
import { WateringPosition } from "vhows-design/src/object/design/watering/watering/WateringPosition";
import { WateringPart_DripConnect } from "vhows-design/src/object/design/watering/watering/WateringPart_DripConnect";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-01-10
 */
@jsonObject
export class WateringSample_DripConnectPePipe extends ItemWateringPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const dripConnectPart: WateringPart_DripConnect = <WateringPart_DripConnect>this.part;
    dripConnectPart.algorithmSpec_sub_pePipe();

    // 다른 파트
    (<WateringPosition>this.position).dripWateringPart.dripPePipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 없음
   */
  // @override
  public algorithmSpec(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
