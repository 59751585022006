import { jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { BandstringPart_FixingHook } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_FixingHook";
import { BandstringPart_EnhancedStake } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_EnhancedStake";
import { BandstringPart_FixingNormalPad } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_FixingNormalPad";
import { BandstringPart_FixingStake } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_FixingStake";
import { BandstringPart_Normal } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_Normal";
import { BandstringPart_Poly } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_Poly";
import { BandstringPart_Bandstring } from "vhows-design/src/object/design/cover/bandstring/BandstringPart_Bandstring";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-05-08
 */
@jsonObject({
  knownTypes: [
    BandstringPart_EnhancedStake,
    BandstringPart_FixingHook,
    BandstringPart_FixingNormalPad,
    BandstringPart_FixingStake,
    BandstringPart_Normal,
    BandstringPart_Poly,
    BandstringPart_Bandstring,
  ],
})
export class BandstringPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public bandstringPart: BandstringPart_Bandstring;
  public normalPart: BandstringPart_Normal;
  public polyPart: BandstringPart_Poly;
  public fixingNormalPadPart: BandstringPart_FixingNormalPad;
  public fixingStakePart: BandstringPart_FixingStake;
  public fixingHookPart: BandstringPart_FixingHook;
  public enhancedStakePart: BandstringPart_EnhancedStake;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _fixingType: string; // 고정 형태
  @jsonMember(Number)
  public _lineInterval: number; // 줄 간격
  @jsonMember(String)
  public _rafterBasedLineNumber: string; // 서까래기준 줄수
  @jsonMember(String)
  public _installLocation: string; // 설치 장소
  @jsonMember(Boolean)
  public _enhancedStakeSelected: boolean; // 보강 말뚝 선택

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘
    this.algorithm_enhancedStakeSelected();

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 줄 간격
   */
  public get lineInterval(): number {
    return this._lineInterval;
  }

  //
  public set lineInterval(value: number) {
    this._lineInterval = value;

    // 알고리즘
    this.bandstringPart.algorithm_totalBandstringLength();
    this.normalPart.algorithm_totalBandstringLength();
    this.polyPart.algorithm_totalBandstringLength();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 서까래기준 줄수
   */
  public get rafterBasedLineNumber(): string {
    return this._rafterBasedLineNumber;
  }

  //
  public set rafterBasedLineNumber(value: string) {
    this._rafterBasedLineNumber = value;

    // 알고리즘
    this.algorithm_lineIntervalByRafter();
  }

  /**
   * 설치 장소
   */
  public get installLocation(): string {
    return this._installLocation;
  }

  //
  public set installLocation(value: string) {
    if (this._installLocation === value) return;

    this._installLocation = value;

    // 알고리즘

    // 파트 활성화

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 보강 말뚝 선택
   */
  public get enhancedStakeSelected(): boolean {
    return this._enhancedStakeSelected;
  }

  //
  public set enhancedStakeSelected(value: boolean) {
    this._enhancedStakeSelected = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByEnhancedStakeSelected();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_fixingTypeByType();
    this.algorithm_rafterBasedLineNumberByType();
    this.bandstringPart.bandstringSample.algorithmChangeSample();
    this.bandstringPart.algorithmProduct_bandstring_bandstring();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.bandstringPart = new BandstringPart_Bandstring();
    this.normalPart = new BandstringPart_Normal();
    this.polyPart = new BandstringPart_Poly();
    this.fixingNormalPadPart = new BandstringPart_FixingNormalPad();
    this.fixingStakePart = new BandstringPart_FixingStake();
    this.fixingHookPart = new BandstringPart_FixingHook();
    this.enhancedStakePart = new BandstringPart_EnhancedStake();

    this.partAC = [
      this.bandstringPart,
      this.normalPart,
      this.polyPart,
      this.fixingNormalPadPart,
      this.fixingStakePart,
      this.fixingHookPart,
      this.enhancedStakePart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.bandstringPart = this.partAC[0] as BandstringPart_Bandstring;
    this.normalPart = this.partAC[1] as BandstringPart_Normal;
    this.polyPart = this.partAC[2] as BandstringPart_Poly;
    this.fixingNormalPadPart = this.partAC[3] as BandstringPart_FixingNormalPad;
    this.fixingStakePart = this.partAC[4] as BandstringPart_FixingStake;
    this.fixingHookPart = this.partAC[5] as BandstringPart_FixingHook;
    this.enhancedStakePart = this.partAC[6] as BandstringPart_EnhancedStake;

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param installLocation: string 설치 장소
   * @param fixingType: string 고정형태
   * @param lineInterval: number 줄 간격
   * @param arrafterBasedLineNumber: string 서까래기준 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    installLocation: string = "",
    fixingType: string = "",
    lineInterval: number = 0,
    rafterBasedLineNumber: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._installLocation = installLocation;
    this._fixingType = fixingType;
    this._lineInterval = lineInterval;
    this._rafterBasedLineNumber = rafterBasedLineNumber;

    if (this.level.index >= 0) {
      // 밴드끈
      this.bandstringPart.setDefaultData(
        CONST.INDEX_SG_BANDSTRING_BANDSTRING,
        true,
        false,
        true,
        CONST.LB_BANDSTRING_BANDSTRING,
        "",
      );

      // 일반 밴드끈
      this.normalPart.setDefaultData(
        CONST.INDEX_SG_BANDSTRING_NORMAL,
        false,
        false,
        false,
        CONST.LB_BANDSTRING_NORMAL,
        "",
      );

      // 폴리 밴드끈
      this.polyPart.setDefaultData(CONST.INDEX_SG_BANDSTRING_POLY, false, false, false, CONST.LB_BANDSTRING_POLY, "");

      // 밴드끈 고정 - 일반 패드 고정
      this.fixingNormalPadPart.setDefaultData(
        CONST.INDEX_SG_BANDSTRING_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_BANDSTRING_FIXING_NORMAL_PAD,
        "",
      );

      // 밴드끈 고정 - 말뚝 고정
      this.fixingStakePart.setDefaultData(
        CONST.INDEX_SG_BANDSTRING_FIXING_STAKE,
        false,
        false,
        false,
        CONST.LB_BANDSTRING_FIXING_STAKE,
        "",
        false,
      );

      // 밴드끈 고정 - 고리 고정
      this.fixingHookPart.setDefaultData(
        CONST.INDEX_SG_BANDSTRING_FIXING_HOOK,
        false,
        false,
        false,
        CONST.LB_BANDSTRING_FIXING_HOOK,
        "",
        false,
        false,
      );

      // 바닥보강 말뚝
      this.enhancedStakePart.setDefaultData(
        CONST.INDEX_SG_BANDSTRING_ENHANCED_STAKE,
        false,
        false,
        false,
        CONST.LB_BANDSTRING_ENHANCED_STAKE,
        "",
        4.0,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 6) {
      // 바닥보강 말뚝
      const enhancedStakePart: BandstringPart_EnhancedStake = new BandstringPart_EnhancedStake();
      enhancedStakePart.setAssociation(design, struct, work, level, this);
      enhancedStakePart.setDefaultData(
        CONST.INDEX_SG_BANDSTRING_ENHANCED_STAKE,
        false,
        false,
        false,
        CONST.LB_BANDSTRING_ENHANCED_STAKE,
        "",
        4.0,
      );
      this.partAC.push(enhancedStakePart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (
      this.type === CONST.LB_BANDSTRING_TYPE_VINYL ||
      this.type === CONST.LB_BANDSTRING_TYPE_NET ||
      this.type === CONST.LB_BANDSTRING_TYPE_WEAVING
    ) {
      this.bandstringPart.selected = true;
      this.bandstringPart.visible = true;
      this.normalPart.selected = false;
      this.normalPart.visible = false;
      this.polyPart.selected = false;
      this.polyPart.visible = false;
    } else if (this.type === CONST.LB_BANDSTRING_TYPE_NORMAL) {
      this.bandstringPart.selected = false;
      this.bandstringPart.visible = false;
      this.normalPart.selected = true;
      this.normalPart.visible = true;
      this.polyPart.selected = false;
      this.polyPart.visible = false;
    } else if (this.type === CONST.LB_BANDSTRING_TYPE_POLY) {
      this.bandstringPart.selected = false;
      this.bandstringPart.visible = false;
      this.normalPart.selected = false;
      this.normalPart.visible = false;
      this.polyPart.selected = true;
      this.polyPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_NORMAL_PAD) {
      this.fixingNormalPadPart.selected = true;
      this.fixingNormalPadPart.visible = true;
      this.fixingStakePart.selected = false;
      this.fixingStakePart.visible = false;
      this.fixingStakePart.stakeWireSelected = false;
      this.fixingHookPart.selected = false;
      this.fixingHookPart.visible = false;
      this.fixingHookPart.hookPipeSelected = false;
      this.fixingHookPart.hookPipeClipSelected = false;
    } else if (this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_STAKE) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingStakePart.selected = true;
      this.fixingStakePart.visible = true;
      this.fixingStakePart.stakeWireSelected = false;
      this.fixingHookPart.selected = false;
      this.fixingHookPart.visible = false;
      this.fixingHookPart.hookPipeSelected = false;
      this.fixingHookPart.hookPipeClipSelected = false;
    } else if (this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_STAKE_WIRE) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingStakePart.selected = true;
      this.fixingStakePart.visible = true;
      this.fixingStakePart.stakeWireSelected = true;
      this.fixingHookPart.selected = false;
      this.fixingHookPart.visible = false;
      this.fixingHookPart.hookPipeSelected = false;
      this.fixingHookPart.hookPipeClipSelected = false;
    } else if (this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_HOOK) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingStakePart.selected = false;
      this.fixingStakePart.visible = false;
      this.fixingStakePart.stakeWireSelected = false;
      this.fixingHookPart.selected = true;
      this.fixingHookPart.visible = true;
      this.fixingHookPart.hookPipeSelected = false;
      this.fixingHookPart.hookPipeClipSelected = false;
    } else if (this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_HOOK_PIPE) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingStakePart.selected = false;
      this.fixingStakePart.visible = false;
      this.fixingStakePart.stakeWireSelected = false;
      this.fixingHookPart.selected = true;
      this.fixingHookPart.visible = true;
      this.fixingHookPart.hookPipeSelected = true;
      this.fixingHookPart.hookPipeClipSelected = false;
    } else if (this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_HOOK_PIPE_CLIP) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingStakePart.selected = false;
      this.fixingStakePart.visible = false;
      this.fixingStakePart.stakeWireSelected = false;
      this.fixingHookPart.selected = true;
      this.fixingHookPart.visible = true;
      this.fixingHookPart.hookPipeSelected = false;
      this.fixingHookPart.hookPipeClipSelected = true;
    } else {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingStakePart.selected = false;
      this.fixingStakePart.visible = false;
      this.fixingStakePart.stakeWireSelected = false;
      this.fixingHookPart.selected = false;
      this.fixingHookPart.visible = false;
      this.fixingHookPart.hookPipeSelected = false;
      this.fixingHookPart.hookPipeClipSelected = false;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 보강 말뚝 선택
   */
  public algorithm_partActivationByEnhancedStakeSelected(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.enhancedStakeSelected === true) {
      this.enhancedStakePart.selected = true;
      this.enhancedStakePart.visible = true;
    } else {
      this.enhancedStakePart.selected = false;
      this.enhancedStakePart.visible = false;
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태
   */
  public algorithm_fixingTypeByType(): void {
    if (
      this.type === CONST.LB_BANDSTRING_TYPE_VINYL ||
      this.type === CONST.LB_BANDSTRING_TYPE_NET ||
      this.type === CONST.LB_BANDSTRING_TYPE_WEAVING
    ) {
      this.fixingType = CONST.LB_BANDSTRING_FIXING_TYPE_NORMAL_PAD;
    } else if (this.type === CONST.LB_BANDSTRING_TYPE_NORMAL) {
      this.fixingType = CONST.LB_BANDSTRING_FIXING_TYPE_STAKE;
    } else if (this.type === CONST.LB_BANDSTRING_TYPE_POLY) {
      this.fixingType = CONST.LB_BANDSTRING_FIXING_TYPE_STAKE;
    }
  }

  /**
   * 알고리즘: 서까래 기준 줄수 <- 형태
   */
  public algorithm_rafterBasedLineNumberByType(): void {
    if (
      this.type === CONST.LB_BANDSTRING_TYPE_VINYL ||
      this.type === CONST.LB_BANDSTRING_TYPE_NET ||
      this.type === CONST.LB_BANDSTRING_TYPE_WEAVING
    ) {
      this.rafterBasedLineNumber = CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_5_1;
    } else if (this.type === CONST.LB_BANDSTRING_TYPE_NORMAL) {
      this.rafterBasedLineNumber = CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_2_1;
    } else if (this.type === CONST.LB_BANDSTRING_TYPE_POLY) {
      this.rafterBasedLineNumber = CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_2_1;
    }
  }

  /**
   * 알고리즘: 줄 간격 <- 서까래 기준 줄수, 서까래 간격(기초 골조)
   */
  public algorithm_lineIntervalByRafter(): void {
    const trunkPart_Rafter: TrunkPart_Rafter = this.struct.trunkWork.level1.trunkPosition.rafterPart;
    let interval: number = 2;

    if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_2) {
      interval = trunkPart_Rafter.rafterInterval / 2;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_1_1) {
      interval = trunkPart_Rafter.rafterInterval;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_2_1) {
      interval = trunkPart_Rafter.rafterInterval * 2;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_3_1) {
      interval = trunkPart_Rafter.rafterInterval * 3;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_4_1) {
      interval = trunkPart_Rafter.rafterInterval * 4;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_5_1) {
      interval = trunkPart_Rafter.rafterInterval * 5;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_6_1) {
      interval = trunkPart_Rafter.rafterInterval * 6;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_8_1) {
      interval = trunkPart_Rafter.rafterInterval * 8;
    } else if (this.rafterBasedLineNumber === CONST.LB_BANDSTRING_RAFTER_BASED_LINE_NUMBER_10_1) {
      interval = trunkPart_Rafter.rafterInterval * 10;
    }

    this.lineInterval = CommonUtil.roundUp(interval, 1);
  }

  /**
   * 알고리즘: 형태 <- 선택(천창 골조)
   */
  public algorithm_typeBySkylight(): void {
    if (this.struct.skyFrameWork.level1.skylightPosition.selected === true) {
      this.type = CONST.LB_BANDSTRING_TYPE_VINYL;
    }
  }

  /**
   * 알고리즘: 보강 말뜩 형태 <- 고정 형태
   */
  public algorithm_enhancedStakeSelected(): void {
    if (
      this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_STAKE ||
      this.fixingType === CONST.LB_BANDSTRING_FIXING_TYPE_STAKE_WIRE
    ) {
      this.enhancedStakeSelected = false;
    } else {
      this.enhancedStakeSelected = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
