import { jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { TrunkPart_Column } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Column";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2021-03-05
 */
@jsonObject
export class TrunkSample_ColumnPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 재정의
  //----------------------------------

  // @override
  public get specPipeType(): string {
    return this._specPipeType;
  }

  //
  public set specPipeType(value: string) {
    if (this._specPipeType === value) return;

    this.algorithmSpec_defaultSpecLength(value, null, null);

    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specMaterial(): string {
    return this._specMaterial;
  }

  //
  public set specMaterial(value: string) {
    if (this._specMaterial === value) return;

    this.algorithmSpec_defaultSpecLength(null, value, null);

    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // @override
  public get specThickness(): string {
    return this._specThickness;
  }

  //
  public set specThickness(value: string) {
    if (this._specThickness === value) return;

    this.algorithmSpec_defaultSpecLength(null, null, value);

    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  // 길이
  // @override
  public get specLength(): number {
    return this._specLength;
  }

  //
  public set specLength(value: number) {
    if (isNaN(value) || this._specLength === CommonUtil.roundUp(value, 1)) return;

    // 일반화된 간격 적용 - 0.1m 간격(소수점 둘째자리 올림)
    this._specLength = CommonUtil.roundUp(value, 1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }

    // 알고리즘
    if (this.isInit === false) {
      this.algorithmSample_specLength();
      (<TrunkPart_Column>this.part).algorithm_columnLength();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  // @override
  public algorithmBasic(): void {
    this.algorithmSpec_specLength();
    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const columnPart: TrunkPart_Column = <TrunkPart_Column>this.part;
    columnPart.algorithmSpec_column_pipe();
    columnPart.algorithmSpec_columnAndRoofStraight_tClamp();
    columnPart.algorithmSpec_columnAndColumnBracing_rotationAsibaClamp();
    columnPart.algorithmSpec_columnSupAndcolumn_middlePairClamp();
    columnPart.algorithmSpec_middleAndColumn_itemHolder();
    columnPart.algorithmProduct_middleAndColumn_itemHolder();
    columnPart.columnBracingPipeSample.algorithmSpec();
    columnPart.middlePipeSample.algorithmSpec();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  /**
   * 샘플 알고리즘: 규격 길이 <br/>
   *  - 규격리스트와 규격 길이를 분리함
   */
  public algorithmSample_specLength(): void {
    /// //////// 내부 ///////////

    const columnPart: TrunkPart_Column = <TrunkPart_Column>this.part;
    columnPart.algorithmSpec_column_pipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: 단위길이 <- 기둥 길이, 기둥 길이 반올림 선택
   */
  public algorithmSpec_specLength(): void {
    const columnPart: TrunkPart_Column = <TrunkPart_Column>this.part;
    let length: number;

    length = columnPart.columnLength;

    if (columnPart.columnLengthRoundSelected === true) {
      length = Math.round(length);
    }

    this.specLength = length;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
