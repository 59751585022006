import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";

/**
 * 기간 클래스
 * @author 이덕형
 * @copyright RUNean Inc.
 * @date 2016-08-01
 */
@jsonObject
export class Period {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 시작일. */
  @jsonMember(Date)
  public startDate: Date = null;
  /** 종료일. */
  @jsonMember(Date)
  public endDate: Date = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------
}
