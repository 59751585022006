import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemFrameOther } from "vhows-design/src/object/design/item/list/ItemFrameOther";
import { ItemHolder } from "vhows-design/src/object/design/item/list/ItemHolder";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ItemSwitcherAssist } from "vhows-design/src/object/design/item/list/ItemSwitcherAssist";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { SkySwitchPositionIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPositionIL";
import { SkySwitchSampleIL_DiagonalSupPipe } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchSampleIL_DiagonalSupPipe";
import { SkySwitchSampleIL_DiagonalSupAssistPipe } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchSampleIL_DiagonalSupAssistPipe";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-22
 */
@jsonObject({
  knownTypes: [SkySwitchSampleIL_DiagonalSupPipe, SkySwitchSampleIL_DiagonalSupAssistPipe],
})
export class SkySwitchPartIL_SupDiagonal extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public diagonalSupportPipeSample: SkySwitchSampleIL_DiagonalSupPipe; // 대각형 지지 파이프 샘플
  public diagonalSupportAssistPipeSample: SkySwitchSampleIL_DiagonalSupAssistPipe; // 대각형 지지 보조 파이프 샘플

  // 아이템
  public diagonalSupport_pipe: ItemPipe;
  public diagonalSupportAssist_pipe: ItemPipe;
  public diagonalSupport_hookHolder: ItemHolder;
  public diagonalSupport_supportFixingPin: ItemSwitcherAssist;
  public diagonalSupport_longBolt: ItemFrameOther;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _supportLength: number; // 지지 길이

  /**
   * 지지 길이
   */
  public get supportLength(): number {
    return this._supportLength;
  }

  //
  public set supportLength(value: number) {
    this._supportLength = CommonUtil.fixFloat(value);

    // 알고리즘
    this.algorithmPart();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.diagonalSupportPipeSample = new SkySwitchSampleIL_DiagonalSupPipe();
    this.diagonalSupportAssistPipeSample = new SkySwitchSampleIL_DiagonalSupAssistPipe();

    this.sampleAC = [this.diagonalSupportPipeSample, this.diagonalSupportAssistPipeSample];

    // 아이템
    this.diagonalSupport_pipe = new ItemPipe();
    this.diagonalSupportAssist_pipe = new ItemPipe();
    this.diagonalSupport_hookHolder = new ItemHolder();
    this.diagonalSupport_supportFixingPin = new ItemSwitcherAssist();
    this.diagonalSupport_longBolt = new ItemFrameOther();

    this.itemAC = [
      this.diagonalSupport_pipe,
      this.diagonalSupportAssist_pipe,
      this.diagonalSupport_hookHolder,
      this.diagonalSupport_supportFixingPin,
      this.diagonalSupport_longBolt,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.diagonalSupportPipeSample = <SkySwitchSampleIL_DiagonalSupPipe>this.sampleAC[0];
    this.diagonalSupportAssistPipeSample = <SkySwitchSampleIL_DiagonalSupAssistPipe>this.sampleAC[1];

    // 아이템
    this.diagonalSupport_pipe = <ItemPipe>this.itemAC[0];
    this.diagonalSupportAssist_pipe = <ItemPipe>this.itemAC[1];
    this.diagonalSupport_hookHolder = <ItemHolder>this.itemAC[2];
    this.diagonalSupport_supportFixingPin = <ItemSwitcherAssist>this.itemAC[3];
    this.diagonalSupport_longBolt = <ItemFrameOther>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   * @param supportLength: number 지지 길이
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
    supportLength: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    this._supportLength = supportLength;

    // 샘플
    if (this.level.index >= 0) {
      this.diagonalSupportPipeSample.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 대각형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.diagonalSupportAssistPipeSample.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 대각형 지지",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.diagonalSupport_pipe.setDefaultData(
        0,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 대각형 지지",
        "원형, 일반, 25.4mm, 1.5T, 10m, -",
      );
      this.diagonalSupportAssist_pipe.setDefaultData(
        1,
        CONST.ITEM_ID_PIPE,
        CONST.ITEM_NAME_PIPE,
        "천창 대각형 지지 보조",
        "원형, 일반, 31.8mm, 1.5T, 10m, -",
      );
      this.diagonalSupport_hookHolder.setDefaultData(
        2,
        CONST.ITEM_ID_HOOK_HOLDER,
        CONST.ITEM_NAME_HOOK_HOLDER,
        "천창 대각형 지지",
        "원형, 일반, 48mm, -",
      );
      this.diagonalSupport_supportFixingPin.setDefaultData(
        3,
        CONST.ITEM_ID_SUPPORT_FIXING_PIN,
        CONST.ITEM_NAME_SUPPORT_FIXING_PIN,
        "천창 대각형 지지",
        "32mm",
      );
      this.diagonalSupport_longBolt.setDefaultData(
        4,
        CONST.ITEM_ID_LONG_BOLT,
        CONST.ITEM_NAME_LONG_BOLT,
        "천창 대각형 지지",
        "일반",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec_diagonalSupport_hookHolder();

    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const skySwitchPosition: SkySwitchPositionIL = <SkySwitchPositionIL>this.position;

    /// //////// 선택, 가시성 ///////////

    this.diagonalSupport_pipe.selected = true;
    this.diagonalSupport_pipe.visible = true;
    this.diagonalSupportAssist_pipe.selected = false;
    this.diagonalSupportAssist_pipe.visible = false;
    this.diagonalSupport_hookHolder.selected = true;
    this.diagonalSupport_hookHolder.visible = true;
    if (skySwitchPosition.type === CONST.LB_SWITCHER_TYPE_MANUAL) {
      this.diagonalSupport_supportFixingPin.selected = true;
      this.diagonalSupport_supportFixingPin.visible = true;
      this.diagonalSupport_longBolt.selected = true;
      this.diagonalSupport_longBolt.visible = true;
    } else {
      this.diagonalSupport_supportFixingPin.selected = false;
      this.diagonalSupport_supportFixingPin.visible = false;
      this.diagonalSupport_longBolt.selected = false;
      this.diagonalSupport_longBolt.visible = false;
    }

    /// //////// 수량 ///////////

    this.diagonalSupport_pipe.designQuantity =
      (this.supportLength * skySwitchPosition.switchWayValue * this.basic.buildingNumber) /
      this.diagonalSupport_pipe.specLength;

    this.diagonalSupportAssist_pipe.designQuantity =
      (this.supportLength * skySwitchPosition.switchWayValue * this.basic.buildingNumber) /
      this.diagonalSupportAssist_pipe.specLength;

    this.diagonalSupport_hookHolder.designQuantity = skySwitchPosition.switchWayValue * this.basic.buildingNumber;

    this.diagonalSupport_supportFixingPin.designQuantity = skySwitchPosition.switchWayValue * this.basic.buildingNumber;

    this.diagonalSupport_longBolt.designQuantity = skySwitchPosition.switchWayValue * this.basic.buildingNumber;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [천창 대각형 지지] 파이프 <- 대각형 지지 파이프 샘플
   */
  public algorithmSpec_diagonalSupport_pipe(): void {
    this.diagonalSupport_pipe.specs = this.diagonalSupportPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 대각형 지지 보조] 파이프 <- 대각형 지지 보조 파이프 샘플
   */
  public algorithmSpec_diagonalSupportAssist_pipe(): void {
    this.diagonalSupportAssist_pipe.specs = this.diagonalSupportAssistPipeSample.specs;
  }

  /**
   * 규격 알고리즘: [천창 대각형 지지] 고리 고정구 <- 마구리 샛기둥 파이프 샘플(마구리>앞면)
   */
  public algorithmSpec_diagonalSupport_hookHolder(): void {
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (
        this.struct.endpieceWorkIL.level1.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
        this.struct.endpieceWorkIL.level1.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER
      ) {
        this.diagonalSupport_hookHolder.specPipeType =
          this.struct.endpieceWorkIL.level1.frontPosition.cylinderStudPart.studPipeSample.specPipeType;
        this.diagonalSupport_hookHolder.specCrossSize1 =
          this.struct.endpieceWorkIL.level1.frontPosition.cylinderStudPart.studPipeSample.specCrossSize;
      } else if (this.struct.endpieceWorkIL.level1.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM) {
        this.diagonalSupport_hookHolder.specPipeType =
          this.struct.endpieceWorkIL.level1.frontPosition.cylinderStudSepPart.studUpperPipeSample.specPipeType;
        this.diagonalSupport_hookHolder.specCrossSize1 =
          this.struct.endpieceWorkIL.level1.frontPosition.cylinderStudSepPart.studUpperPipeSample.specCrossSize;
      } else if (this.struct.endpieceWorkIL.level1.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM) {
        this.diagonalSupport_hookHolder.specPipeType =
          this.struct.endpieceWorkIL.level1.frontPosition.prismStudSepPart.studUpperPipeSample.specPipeType;
        this.diagonalSupport_hookHolder.specCrossSize1 =
          this.struct.endpieceWorkIL.level1.frontPosition.prismStudSepPart.studUpperPipeSample.specCrossSize;
      }
    }
  }

  /**
   * 규격 알고리즘: [천창 대각형 지지] 지지 고정핀 <- 대각형 지지 보조 파이프 샘플
   */
  public algorithmSpec_diagonalSupport_supportFixingPin(): void {
    this.diagonalSupport_supportFixingPin.specCrossSize = this.diagonalSupportAssistPipeSample.specCrossSize;
  }

  /**
   * 규격 알고리즘: [천창 대각형 지지] 장볼트 <- 공통 샘플
   */
  public algorithmSpec_diagonalSupport_longBolt(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
