import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 파이프
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-02-02
 */
@jsonObject
export class ItemPipe extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [파이프형태, 재질, 파이프단면크기, 두께], (길이)
  public _specPipeType: string = undefined; // 파이프형태
  public _specMaterial: string = undefined; // 재질
  public _specPipeCrossSize: string = undefined; // 파이프단면크기
  public _specThickness: string = undefined; // 두께
  public _specLength: number = undefined; // 길이
  public _specBending: string = undefined; // 벤딩

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 파이프형태
   */
  public get specPipeType(): string {
    return this._specPipeType;
  }
  //
  public set specPipeType(value: string) {
    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 재질
   */
  public get specMaterial(): string {
    return this._specMaterial;
  }
  //
  public set specMaterial(value: string) {
    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 파이프단면크기 <br/>
   * 예) 25.4mm, 48.1mm, 25A, 60×60mm 등
   */
  public get specPipeCrossSize(): string {
    return this._specPipeCrossSize;
  }
  //
  public set specPipeCrossSize(value: string) {
    this._specPipeCrossSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 단면크기 (파이프단면크기 기반) <br/>
   * 연결구, 고정구 등과 호환되는 소수점이하가 없는 단면크기
   */
  public get specCrossSize(): string {
    let crossSize: string = "";
    switch (this._specPipeCrossSize) {
      case "22.2mm":
        crossSize = "22mm";
        break;
      case "25.4mm":
        crossSize = "25mm";
        break;
      case "31.8mm":
        crossSize = "32mm";
        break;
      case "33.5mm":
        crossSize = "32mm";
        break;
      case "42.2mm":
        crossSize = "42mm";
        break;
      case "48.1mm":
        crossSize = "48mm";
        break;
      case "59.9mm":
        crossSize = "60mm";
        break;
      case "60.5mm":
        crossSize = "60mm";
        break;
      case "25A":
        crossSize = "32mm";
        break;
      case "40A":
        crossSize = "48mm";
        break;
      case "50A":
        crossSize = "60mm";
        break;
      case "65A":
        crossSize = "75mm";
        break;
      case "100A":
        crossSize = "115mm";
        break;
      default:
        // 단면크기가 없는 경우, 파이프단면크기 그대로 반환
        crossSize = this._specPipeCrossSize;
        break;
    }
    return crossSize;
  }

  /**
   * 축커플링 (파이프단면크기 기반) <br/>
   * 개폐기 모터의 축커플링 크기 (33.5mm일때 34mm 사용)
   */
  public get specAxisCoupling(): string {
    let crossSize: string = "";
    switch (this._specPipeCrossSize) {
      case "22.2mm":
        crossSize = "22mm";
        break;
      case "25.4mm":
        crossSize = "25mm";
        break;
      case "31.8mm":
        crossSize = "32mm";
        break;
      case "33.5mm":
        crossSize = "34mm";
        break;
      case "42.2mm":
        crossSize = "42mm";
        break;
      case "48.1mm":
        crossSize = "48mm";
        break;
      case "59.9mm":
        crossSize = "60mm";
        break;
      case "60.5mm":
        crossSize = "60mm";
        break;
      case "25A":
        crossSize = "32mm";
        break;
      case "40A":
        crossSize = "48mm";
        break;
      case "50A":
        crossSize = "60mm";
        break;
      case "65A":
        crossSize = "75mm";
        break;
      case "100A":
        crossSize = "115mm";
        break;
      default:
        // 단면크기가 없는 경우, 파이프단면크기 그대로 반환
        crossSize = this._specPipeCrossSize;
        break;
    }
    return crossSize;
  }

  /**
   * 롤러크기 <br/>
   * C형강에만 해당
   */
  public get specRollerSize(): string {
    let rollerSize: string = "";
    if (this.productId === CONST.ITEM_ID_C_SECTION_STEEL) {
      switch (this._specPipeCrossSize) {
        case CONST.ITEM_SPEC_VALUE_60_30_MM:
          rollerSize = CONST.ITEM_SPEC_VALUE_60_MM;
          break;
        case CONST.ITEM_SPEC_VALUE_75_45_MM:
          rollerSize = CONST.ITEM_SPEC_VALUE_75_MM;
          break;
        case CONST.ITEM_SPEC_VALUE_100_50_MM:
          rollerSize = CONST.ITEM_SPEC_VALUE_100_MM;
          break;
        default:
          // 단면크기가 없는 경우, 60mm 반환
          rollerSize = CONST.ITEM_SPEC_VALUE_60_MM;
          break;
      }
    }
    return rollerSize;
  }

  /**
   * 두께
   */
  public get specThickness(): string {
    return this._specThickness;
  }
  //
  public set specThickness(value: string) {
    this._specThickness = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이
   */
  public get specLength(): number {
    return this._specLength;
  }
  //
  public set specLength(value: number) {
    // 일반화된 간격 적용 - 0.1m 간격(소수점 둘째자리 올림)
    this._specLength = CommonUtil.roundUp(value, 1);

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 길이 레이블(값+단위기호)
   */
  public get specLengthLabel(): string {
    return this.specLength + CONST.UNIT_METER;
  }
  //
  public set specLengthLabel(value: string) {
    this.specLength = parseFloat(value);
  }

  /**
   * 벤딩
   */
  public get specBending(): string {
    return this._specBending;
  }
  //
  public set specBending(value: string) {
    this._specBending = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.pipeCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 선택 및 가시성 체크
   * @param selected 사용 여부
   * @param visible 가시성
   */
  // @override
  public checkSelected(selected: boolean, visible: boolean = true): void {
    this.selected = selected;
    this.visible = visible;
    if (selected) {
      if (this.design.preference.connectedPipePinWorkedFlag) {
        this.specMaterial = CONST.ITEM_SPEC_VALUE_PINNED;
      } else if (this.specMaterial === CONST.ITEM_SPEC_VALUE_AXIS) {
        this.specMaterial = CONST.ITEM_SPEC_VALUE_AXIS;
      } else {
        this.specMaterial = CONST.ITEM_SPEC_VALUE_NORMAL;
      }
    }
  }

  /**
   * 연결형 파이프 길이
   * @param lineLength 한줄의 길이
   * @param lineNumber 줄수
   * @param leastLength 사용가능한 최소 길이
   * @return 총 길이
   */
  public getConnectedPipeLength(lineLength: number, lineNumber: number, leastLength: number = 1.5): number {
    let totalLength: number = 0;
    const unitLength: number = this.specLength;

    for (let i: number = 0; i < lineNumber; i++) {
      totalLength += lineLength;
      if (unitLength - (totalLength % unitLength) < leastLength) {
        totalLength = CommonUtil.roundUpX(totalLength, unitLength);
      }
    }
    return totalLength;
  }

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    const defaultUnitSupply: number = this.unitPriceModel.getDefaultSupply(type);

    let sellingSupply: number;

    // 파이프
    if (this.productId === CONST.ITEM_ID_PIPE) {
      // 절단 단가
      // 하드코딩
      let cuttingUnitSupply: number;
      if (this.specLength < 4) {
        if (parseFloat(this.specPipeCrossSize) < 41.8) {
          cuttingUnitSupply = this.brandCompanyModel.optionUnitSupply1;
        } else {
          cuttingUnitSupply = this.brandCompanyModel.optionUnitSupply2;
        }
      } else {
        cuttingUnitSupply = 0;
      }
      // 절단 매출가 계산식
      const cuttingSellingSupply: number = cuttingUnitSupply;

      // 매출가 계산식
      sellingSupply = defaultUnitSupply * this.specLength + cuttingSellingSupply;
    } else if (this.productId === CONST.ITEM_ID_PIPE && this.specMaterial === CONST.ITEM_SPEC_VALUE_PINNED) {
      // 핀가공 단가
      // 하드코딩
      let pinWorkedUnitSupply: number;
      if (this.specLength < 3) {
        pinWorkedUnitSupply = this.brandCompanyModel.optionUnitSupply3;
      } else {
        pinWorkedUnitSupply = this.brandCompanyModel.optionUnitSupply4;
      }
      // 핀가공 매출가 계산식
      const pinWorkedSellingSupply: number = pinWorkedUnitSupply;

      // 매출가 계산식
      sellingSupply = defaultUnitSupply * this.specLength + pinWorkedSellingSupply;
    } else if (this.productId === CONST.ITEM_ID_C_SECTION_STEEL) {
      sellingSupply = defaultUnitSupply * this.specLength;
    } else if (this.productId === CONST.ITEM_ID_GUTTER_BEAM) {
      sellingSupply = defaultUnitSupply * this.specLength;
    } else if (this.productId === CONST.ITEM_ID_ROUND_BAR) {
      sellingSupply = defaultUnitSupply * this.specLength;
    }
    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specMaterial = specsArray[1];
    this.specPipeCrossSize = specsArray[2];
    this.specThickness = specsArray[3];
    this.specLengthLabel = specsArray[4];
    this.specBending = specsArray[5];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specMaterial = specsArray[1];
    this.specPipeCrossSize = specsArray[2];
    this.specThickness = specsArray[3];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specPipeType +
      CONST.DELIMITER_SPEC +
      this._specMaterial +
      CONST.DELIMITER_SPEC +
      this._specPipeCrossSize +
      CONST.DELIMITER_SPEC +
      this._specThickness;
  }

  // @override
  protected makeSpecs(): void {
    this.specs =
      this._specsList + CONST.DELIMITER_SPEC + this.specLengthLabel + CONST.DELIMITER_SPEC + this._specBending;
  }

  //----------------------------------
  // 하우스 설계 > 특정 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 : 기본 단위길이 설정 <- 파이프형태, 재질, 두께<br/>
   *  - 모든 파이프에 해당하는 것은 아니며, 보통 가로대와 같은 연결형 파이프에 해당함
   */
  protected algorithmSpec_defaultSpecLength(
    specPipeTypeParam: string,
    specMaterialParam: string,
    specThicknessParam: string,
  ): void {
    if (this.isInit === false) {
      const specPipeTypeValue: string = specPipeTypeParam != null ? specPipeTypeParam : this.specPipeType;
      const specMaterialValue: string = specMaterialParam != null ? specMaterialParam : this.specMaterial;
      const specThicknessValue: string = specThicknessParam != null ? specThicknessParam : this.specThickness;

      if (specPipeTypeValue != null || specMaterialValue != null || specThicknessValue != null) {
        // 하드코딩
        if (this.productId === CONST.ITEM_ID_C_SECTION_STEEL) {
          // C형강
          this.specLength = 10.0;
        } else {
          // 파이프 & 핀가공 파이프
          if (specPipeTypeValue === CONST.ITEM_SPEC_VALUE_CIRCLE_TYPE) {
            if (specMaterialValue === CONST.ITEM_SPEC_VALUE_AZM) {
              this.specLength = 6;
            } else if (parseFloat(specThicknessValue) < 1.9) {
              this.specLength = 10;
            } else {
              this.specLength = 6;
            }
          } else {
            this.specLength = 6;
          }
        }
      }
    }
  }
}
