import { jsonObject } from "typedjson";

import { ItemScreennet } from "vhows-design/src/object/design/item/list/ItemScreennet";
import { SkyCoverPart_Screennet } from "vhows-design/src/object/design/skylight/cover/SkyCoverPart_Screennet";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-25
 */
@jsonObject
export class SkyCoverSample_Screennet extends ItemScreennet {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const screennetPart: SkyCoverPart_Screennet = <SkyCoverPart_Screennet>this.part;
    screennetPart.algorithmSpec_skyScreennet_screennet();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 방충망 폭(위치), 방충망 샘플(방충망>측면)
   */
  // @override
  public algorithmSpec(): void {
    this.specsList = this.struct.screennetWork.level1.sidePosition.screennetPart.screennetSample.specsList;
    // 하드코딩
    if ((<SkyCoverPart_Screennet>this.part).screennetWidth > 1.5) {
      this.specWidth = (<SkyCoverPart_Screennet>this.part).screennetWidth;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
