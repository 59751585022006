import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { SkirtPartIL_FixingNormalPad } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingNormalPad";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { CoverPositionIL } from "vhows-design/src/object/design/cover/cover/il/CoverPositionIL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { SkirtPartIL_FixingNormalClip } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingNormalClip";
import { SkirtPartIL_FixingLaggingPad } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingLaggingPad";
import { SkirtPartIL_FixingSkirtClip } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingSkirtClip";
import { SkirtPartIL_FixingWood } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingWood";
import { SkirtPartIL_Normal } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_Normal";
import { SkirtPartIL_String } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_String";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-31
 */
@jsonObject({
  knownTypes: [
    SkirtPartIL_FixingLaggingPad,
    SkirtPartIL_FixingNormalClip,
    SkirtPartIL_FixingNormalPad,
    SkirtPartIL_FixingSkirtClip,
    SkirtPartIL_FixingWood,
    SkirtPartIL_Normal,
    SkirtPartIL_String,
  ],
})
export class SkirtPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public normalPart: SkirtPartIL_Normal;
  public stringPart: SkirtPartIL_String;
  public fixingNormalPadPart: SkirtPartIL_FixingNormalPad;
  public fixingNormalClipPart: SkirtPartIL_FixingNormalClip;
  public fixingLaggingPadPart: SkirtPartIL_FixingLaggingPad;
  public fixingWoodPart: SkirtPartIL_FixingWood;
  public fixingSkirtClipPart: SkirtPartIL_FixingSkirtClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _skirtHeight: number; // 치마 높이
  @jsonMember(String)
  public _fixingType: string; // 고정 형태

  /**
   * 치마 높이
   */
  public get skirtHeight(): number {
    return this._skirtHeight;
  }

  public set skirtHeight(value: number) {
    this._skirtHeight = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 중수
    this.algorithm_skirtHeightByLevel();

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 고정 형태
   */
  public get fixingType(): string {
    return this._fixingType;
  }

  public set fixingType(value: string) {
    this._fixingType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByFixingType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();
    this.algorithm_partActivationByFixingType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 기초 피복 : 관련 피복 및 피복 고정의 파트 알고리즘도 이어서 호출됨
    (<CoverLevelIL>this.struct.coverWorkIL.levelAC[this.level.index]).algorithmBasic();
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_fixingTypeByFixing();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.normalPart = new SkirtPartIL_Normal();
    this.stringPart = new SkirtPartIL_String();
    this.fixingNormalPadPart = new SkirtPartIL_FixingNormalPad();
    this.fixingNormalClipPart = new SkirtPartIL_FixingNormalClip();
    this.fixingLaggingPadPart = new SkirtPartIL_FixingLaggingPad();
    this.fixingWoodPart = new SkirtPartIL_FixingWood();
    this.fixingSkirtClipPart = new SkirtPartIL_FixingSkirtClip();

    this.partAC = [
      this.normalPart,
      this.stringPart,
      this.fixingNormalPadPart,
      this.fixingNormalClipPart,
      this.fixingLaggingPadPart,
      this.fixingWoodPart,
      this.fixingSkirtClipPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.normalPart = <SkirtPartIL_Normal>this.partAC[0];
    this.stringPart = <SkirtPartIL_String>this.partAC[1];
    this.fixingNormalPadPart = <SkirtPartIL_FixingNormalPad>this.partAC[2];
    this.fixingNormalClipPart = <SkirtPartIL_FixingNormalClip>this.partAC[3];
    this.fixingLaggingPadPart = <SkirtPartIL_FixingLaggingPad>this.partAC[4];
    this.fixingWoodPart = <SkirtPartIL_FixingWood>this.partAC[5];
    this.fixingSkirtClipPart = <SkirtPartIL_FixingSkirtClip>this.partAC[6];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param skirtHeight: number 치마 높이
   * @param fixingType: string 고정 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    skirtHeight: number = 0,
    fixingType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._skirtHeight = skirtHeight;
    this._fixingType = fixingType;

    if (this.level.index === 0) {
      // 일반 치마 피복
      this.normalPart.setDefaultData(CONST.INDEX_IL_SKIRT_NORMAL, true, false, true, CONST.LB_SKIRT_NORMAL, "");

      // 끈 치마 피복
      this.stringPart.setDefaultData(CONST.INDEX_IL_SKIRT_STRING, false, false, false, CONST.LB_SKIRT_STRING, "");

      // 치마 피복 고정 - 일반 패드 고정
      this.fixingNormalPadPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_SKIRT_FIXING_NORMAL_PAD,
        "",
      );

      // 치마 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );

      // 치마 피복 고정 - 광폭 패드 고정
      this.fixingLaggingPadPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_LAGGING_PAD,
        "",
      );

      // 치마 피복 고정 - 목재 고정
      this.fixingWoodPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_WOOD,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_WOOD,
        "",
      );

      // 치마 피복 고정 - 치마 클립 고정
      this.fixingSkirtClipPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_SKIRT_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_SKIRT_CLIP,
        "",
        1.2,
      );
    } else {
      // 일반 치마 피복
      this.normalPart.setDefaultData(CONST.INDEX_IL_SKIRT_NORMAL, true, false, true, CONST.LB_SKIRT_NORMAL, "");

      // 끈 치마 피복
      this.stringPart.setDefaultData(CONST.INDEX_IL_SKIRT_STRING, false, false, false, CONST.LB_SKIRT_STRING, "");

      // 치마 피복 고정 - 일반 패드 고정
      this.fixingNormalPadPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_SKIRT_FIXING_NORMAL_PAD,
        "",
      );

      // 치마 피복 고정 - 일반 클립 고정
      this.fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );

      // 치마 피복 고정 - 광폭 패드 고정
      this.fixingLaggingPadPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_LAGGING_PAD,
        "",
      );

      // 치마 피복 고정 - 목재 고정
      this.fixingWoodPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_WOOD,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_WOOD,
        "",
      );

      // 치마 피복 고정 - 치마 클립 고정
      this.fixingSkirtClipPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_SKIRT_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_SKIRT_CLIP,
        "",
        1.2,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 4) {
      // 끈 치마 피복
      const stringPart: SkirtPartIL_String = new SkirtPartIL_String();
      stringPart.setAssociation(design, struct, work, level, this);
      stringPart.setDefaultData(CONST.INDEX_IL_SKIRT_STRING, false, false, false, CONST.LB_SKIRT_STRING, "");
      this.partAC.splice(1, 0, stringPart);
    }
    if (this.partAC.length === 5) {
      // 치마 피복 고정 - 일반 클립 고정
      const fixingNormalClipPart: SkirtPartIL_FixingNormalClip = new SkirtPartIL_FixingNormalClip();
      fixingNormalClipPart.setAssociation(design, struct, work, level, this);
      fixingNormalClipPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_NORMAL_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_NORMAL_CLIP,
        "",
        0.5,
      );
      this.partAC.splice(3, 0, fixingNormalClipPart);
    }
    if (this.partAC.length === 6) {
      // 치마 피복 고정 - 치마 클립 고정
      const fixingSkirtClipPart: SkirtPartIL_FixingSkirtClip = new SkirtPartIL_FixingSkirtClip();
      fixingSkirtClipPart.setAssociation(design, struct, work, level, this);
      fixingSkirtClipPart.setDefaultData(
        CONST.INDEX_IL_SKIRT_FIXING_SKIRT_CLIP,
        false,
        false,
        false,
        CONST.LB_SKIRT_FIXING_SKIRT_CLIP,
        "",
        1.2,
      );
      this.partAC.push(fixingSkirtClipPart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 상세 피복별 선택 여부
   * @param coverPosition 피복 위치 객체
   */
  public selectedDetail(coverPosition: CoverPositionIL): boolean {
    // 위치별로 치마가 선택되었다 하더라도, 피복별로 치마가 선택되지 않은 경우가 우선
    if (this.label === CONST.LB_POSITION_SIDE_COLUMN && coverPosition.skirtCoverSideColumn === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_SIDE_WING && coverPosition.skirtCoverSideWing === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_ROOF && coverPosition.skirtCoverRoof === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_FRONT && coverPosition.skirtCoverFront === true) {
      return this.selected;
    } else if (this.label === CONST.LB_POSITION_BACK && coverPosition.skirtCoverBack === true) {
      return this.selected;
    }
    return false;
  }

  /**
   * 알고리즘: 선택 <- 선택(개폐기), 선택(커튼 개폐), 형태(커튼 개폐)
   */
  public algorithm_selected(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );
    const curSwitchLevel: CurSwitchLevelIL = this.struct.curSwitchWorkIL.level1;

    if (this.level.index === 0) {
      if (this.label === CONST.LB_POSITION_SIDE_COLUMN) {
        this.selected =
          switcherPosition.selected ||
          (curSwitchLevel.columnPosition.selected &&
            curSwitchLevel.columnPosition.type === CONST.LB_CURTAIN_SWITCH_TYPE_POWER_WRAP);
      } else {
        this.selected = switcherPosition.selected;
      }
    } else {
      if (this.label === CONST.LB_POSITION_ROOF) {
        // 2중 이상의 지붕은 치마를 설치하지 않는 것이 기본 (물받이가 치마 역할을 함)
      } else {
        this.selected = switcherPosition.selected;
      }
    }
  }

  /**
   * 알고리즘: 가용성 <- 선택(개폐기), 선택(커튼 개폐)
   */
  public algorithm_enabled(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );
    const curSwitchLevel: CurSwitchLevelIL = this.struct.curSwitchWorkIL.level1;

    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      this.enabled = switcherPosition.selected || curSwitchLevel.columnPosition.selected;
    } else {
      this.enabled = switcherPosition.selected;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_SKIRT_TYPE_NORMAL) {
      this.normalPart.selected = true;
      this.normalPart.visible = true;
      this.stringPart.selected = false;
      this.stringPart.visible = false;
    } else if (this.type === CONST.LB_SKIRT_TYPE_STRING) {
      this.normalPart.selected = false;
      this.normalPart.visible = false;
      this.stringPart.selected = true;
      this.stringPart.visible = true;
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 고정 형태
   */
  public algorithm_partActivationByFixingType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
      this.fixingNormalPadPart.selected = true;
      this.fixingNormalPadPart.visible = true;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = true;
      this.fixingNormalClipPart.visible = true;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = true;
      this.fixingLaggingPadPart.visible = true;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_WOOD) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = true;
      this.fixingWoodPart.visible = true;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    } else if (this.fixingType === CONST.LB_FIXING_TYPE_SKIRT_CLIP) {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = true;
      this.fixingSkirtClipPart.visible = true;
    } else {
      this.fixingNormalPadPart.selected = false;
      this.fixingNormalPadPart.visible = false;
      this.fixingNormalClipPart.selected = false;
      this.fixingNormalClipPart.visible = false;
      this.fixingLaggingPadPart.selected = false;
      this.fixingLaggingPadPart.visible = false;
      this.fixingWoodPart.selected = false;
      this.fixingWoodPart.visible = false;
      this.fixingSkirtClipPart.selected = false;
      this.fixingSkirtClipPart.visible = false;
    }
  }

  /**
   * 알고리즘: 고정 형태 <- 형태, 형태(피복 고정)
   */
  public algorithm_fixingTypeByFixing(): void {
    if (this.type === CONST.LB_SKIRT_TYPE_NORMAL) {
      const fixingPosition: FixingPositionIL = <FixingPositionIL>(
        this.struct.fixingWorkIL.levelAC[this.level.index].positionAC[this.index]
      );

      this.fixingType = fixingPosition.type;
    } else if (this.type === CONST.LB_SKIRT_TYPE_STRING) {
      this.fixingType = CONST.LB_FIXING_TYPE_SKIRT_CLIP;
    }
  }

  /**
   * 알고리즘: 치마 높이 <- 치마 높이(중수)
   */
  public algorithm_skirtHeightByLevel(): void {
    const switcherLevelCurrent: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];

    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      for (let l: number = this.level.index + 1; l < CONST.NUM_INTERLOCK_LEVEL_MAX; l++) {
        const switcherLevelN: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[l];

        // 현재 중수와 반영할 중수의 개폐 방향이 같은 경우에만, 치마 높이를 반영함
        if (
          (<SwitcherPositionIL>switcherLevelCurrent.positionAC[this.index]).switchWay ===
          (<SwitcherPositionIL>switcherLevelN.positionAC[this.index]).switchWay
        ) {
          (<SkirtPositionIL>this.work.levelAC[l].positionAC[this.index]).skirtHeight = this.skirtHeight;
        }
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      for (let l: number = this.level.index + 1; l < CONST.NUM_VENLO_LEVEL_MAX; l++) {
        const switcherLevelN: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[l];

        // 현재 중수와 반영할 중수의 개폐 방향이 같은 경우에만, 치마 높이를 반영함
        if (
          (<SwitcherPositionIL>switcherLevelCurrent.positionAC[this.index]).switchWay ===
          (<SwitcherPositionIL>switcherLevelN.positionAC[this.index]).switchWay
        ) {
          (<SkirtPositionIL>this.work.levelAC[l].positionAC[this.index]).skirtHeight = this.skirtHeight;
        }
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
