import { jsonMember, jsonObject } from "typedjson";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-26
 */
@jsonObject({
  knownTypes: [],
})
export class NumericSpecModel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 앞첨자. */
  @jsonMember(String)
  public prefix: string = null;
  /** 최소값. */
  @jsonMember(Number)
  public min: number = null;
  /** 최대값. */
  @jsonMember(Number)
  public max: number = null;
  /** 간격값. */
  @jsonMember(Number)
  public step: number = null;
  /** 단위. */
  @jsonMember(String)
  public unit: string = null;

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor(prefix: string = "", min: number = 0, max: number = 0, step: number = 0, unit: string = "") {
    super();

    this.prefix = prefix;
    this.min = min;
    this.max = max;
    this.step = step;
    this.unit = unit;
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
