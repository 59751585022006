import { useQueryClient } from "@tanstack/react-query";
import { checkVersion, QA_FLAG_STAGING, reactNativePostMessage } from "@vhows/util";
import { Modal } from "components/cost/modal";
import { ROUTE } from "constants/route";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { cancelModalAtom } from "store/global";
import HeaderV2 from "./header/header-v2";

type Layout = {
  children?: React.ReactNode;
};

export default function Layout({ children }: Layout) {
  const router = useRouter();
  const [isVisible, setIsVisible] = useAtom(cancelModalAtom);
  const queryClient = useQueryClient();

  const entryType = router.query.entry as string;

  const callbacks = (e: any) => {
    if (router.pathname === ROUTE.INDEX) {
      reactNativePostMessage("CLOSE");
    } else {
      router.back();
    }
  };

  const resetData = () => {
    queryClient.invalidateQueries(["getCostResult"]);
    queryClient.invalidateQueries(["getCounsel"]);
    queryClient.invalidateQueries(["getB2CCounsel"]);
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("uuid");
    setIsVisible(false);
    router.push(ROUTE.INDEX);
  };

  const modalTItle = checkVersion("4.2.0", QA_FLAG_STAGING) ? "견적 요청을" : " 하우스 견적 상담 신청을";

  return (
    <>
      <div className={`relative m-auto max-w-2xl flex-col`}>
        <HeaderV2 callBacks={callbacks}></HeaderV2>
        {children}
        {isVisible && (
          <Modal>
            <div className="w-full p-5">
              <h2 className="mt-1 text-[22px] font-bold mb-6">
                {modalTItle}
                <br />
                중단하시겠어요?
              </h2>

              <button
                className="mr-2 w-[calc(50%_-_0.25rem)] rounded-xl bg-[#ECECEC] py-3 text-[17px] font-medium leading-7"
                onClick={() => resetData()}
              >
                나가기
              </button>
              <button
                className="w-[calc(50%_-_0.25rem)] rounded-xl border border-solid border-transparent bg-[#12B564] py-3 text-[17px] font-medium leading-7 text-white"
                onClick={() => setIsVisible(false)}
              >
                계속 작성
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
