import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";
import { ScreennetPositionIL } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { WindbreakPositionIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPositionIL";
import { FixingPartIL_NormalPad } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_NormalPad";
import { FixingPartIL_NormalClip } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_NormalClip";
import { FixingPartIL_LaggingPad } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_LaggingPad";
import { FixingPartIL_Wood } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_Wood";
import { FixingPartIL_CondensationPad } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_CondensationPad";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { WingPositionIL } from "vhows-design/src/object/design/frame/wing/WingPositionIL";
import { WingPositionVL } from "vhows-design/src/object/design/frame/wing/vl/WingPositionVL";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WingLevelVL } from "vhows-design/src/object/design/frame/wing/vl/WingLevelVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject({
  knownTypes: [
    FixingPartIL_CondensationPad,
    FixingPartIL_LaggingPad,
    FixingPartIL_NormalClip,
    FixingPartIL_NormalPad,
    FixingPartIL_Wood,
  ],
})
export class FixingPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public normalPadPart: FixingPartIL_NormalPad;
  public normalClipPart: FixingPartIL_NormalClip;
  public laggingPadPart: FixingPartIL_LaggingPad;
  public woodPart: FixingPartIL_Wood;
  public condensationPadPart: FixingPartIL_CondensationPad;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 상태 */
  public status: string;

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _lineNumber: number; // 줄수 또는 마구리 중방방향 줄수(앞/뒷면)
  @jsonMember(String)
  public _endpieceRafterType: string; // 마구리 서까래쪽 형태
  @jsonMember(Number)
  public _endpieceRafterLineNumber: number; // 마구리 서까래쪽 줄수
  @jsonMember(String)
  public _endpieceColumnType: string; // 마구리 기둥쪽 형태
  @jsonMember(Number)
  public _endpieceColumnLineNumber: number; // 마구리 기둥쪽 줄수
  @jsonMember(String)
  public _endpieceGateType: string; // 마구리 문주위 형태
  @jsonMember(Number)
  public _endpieceGateLineNumber: number; // 마구리 문주위 줄수

  /**
   * 줄수 또는 마구리 중방방향 줄수(앞/뒷면)
   */
  public get lineNumber(): number {
    return this._lineNumber;
  }

  public set lineNumber(value: number) {
    this._lineNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 서까래쪽 형태
   */
  public get endpieceRafterType(): string {
    return this._endpieceRafterType;
  }

  public set endpieceRafterType(value: string) {
    this._endpieceRafterType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 서까래쪽 줄수
   */
  public get endpieceRafterLineNumber(): number {
    return this._endpieceRafterLineNumber;
  }

  public set endpieceRafterLineNumber(value: number) {
    this._endpieceRafterLineNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 기둥쪽 형태
   */
  public get endpieceColumnType(): string {
    return this._endpieceColumnType;
  }

  public set endpieceColumnType(value: string) {
    this._endpieceColumnType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 기둥쪽 줄수
   */
  public get endpieceColumnLineNumber(): number {
    return this._endpieceColumnLineNumber;
  }

  public set endpieceColumnLineNumber(value: number) {
    this._endpieceColumnLineNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 문주위 형태
   */
  public get endpieceGateType(): string {
    return this._endpieceGateType;
  }

  public set endpieceGateType(value: string) {
    this._endpieceGateType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  /**
   * 마구리 문주위 줄수
   */
  public get endpieceGateLineNumber(): number {
    return this._endpieceGateLineNumber;
  }

  public set endpieceGateLineNumber(value: number) {
    this._endpieceGateLineNumber = value;

    // 알고리즘

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //----------------------------------
  // 재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태 또는 마구리 중방방향 형태(앞,뒷면)
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.algorithm_endpieceTypeByType();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    // 위치
    this.algorithm_typeByPosition();

    /// //////// 외부 ///////////

    // 치마
    (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_fixingTypeByFixing();

    // 바람막이
    (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.index]
    )).algorithm_fixingTypeByFixing();

    // 방충망
    if (this.level.index === 0) {
      (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.levelAC[this.level.index].positionAC[this.index]
      )).algorithm_fixingTypeByFixing();
    }

    // 창문
    if (this.level.index === 0) {
      if (this.label === CONST.LB_POSITION_SIDE_COLUMN) {
        (<WindowLevelIL>this.struct.windowWorkIL.levelAC[this.level.index]).leftPosition.algorithm_fixingTypeByFixing();
        (<WindowLevelIL>(
          this.struct.windowWorkIL.levelAC[this.level.index]
        )).rightPosition.algorithm_fixingTypeByFixing();
      } else if (this.label === CONST.LB_POSITION_FRONT) {
        (<WindowLevelIL>(
          this.struct.windowWorkIL.levelAC[this.level.index]
        )).frontPosition.algorithm_fixingTypeByFixing();
      } else if (this.label === CONST.LB_POSITION_BACK) {
        (<WindowLevelIL>this.struct.windowWorkIL.levelAC[this.level.index]).backPosition.algorithm_fixingTypeByFixing();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.normalPadPart = new FixingPartIL_NormalPad();
    this.normalClipPart = new FixingPartIL_NormalClip();
    this.laggingPadPart = new FixingPartIL_LaggingPad();
    this.woodPart = new FixingPartIL_Wood();
    this.condensationPadPart = new FixingPartIL_CondensationPad();

    this.partAC = [
      this.normalPadPart,
      this.normalClipPart,
      this.laggingPadPart,
      this.woodPart,
      this.condensationPadPart,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.normalPadPart = <FixingPartIL_NormalPad>this.partAC[0];
    this.normalClipPart = <FixingPartIL_NormalClip>this.partAC[1];
    this.laggingPadPart = <FixingPartIL_LaggingPad>this.partAC[2];
    this.woodPart = <FixingPartIL_Wood>this.partAC[3];
    this.condensationPadPart = <FixingPartIL_CondensationPad>this.partAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param lineNumber: number 줄수 또는 마구리 중방방향 줄수(앞/뒷면)
   * @param endpieceRafterType: string 마구리 서까래쪽 형태
   * @param endpieceRafterLineNumber: number 마구리 서까래쪽 줄수
   * @param endpieceColumnType: string 마구리 기둥쪽 형태
   * @param endpieceColumnLineNumber: number 마구리 기둥쪽 줄수
   * @param endpieceGateType: string 마구리 문주위 형태
   * @param endpieceGateLineNumber: number 마구리 문주위 줄수
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    lineNumber: number = 0,
    endpieceRafterType: string = "",
    endpieceRafterLineNumber: number = 0,
    endpieceColumnType: string = "",
    endpieceColumnLineNumber: number = 0,
    endpieceGateType: string = "",
    endpieceGateLineNumber: number = 0,
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._lineNumber = lineNumber;
    this._endpieceRafterType = endpieceRafterType;
    this._endpieceRafterLineNumber = endpieceRafterLineNumber;
    this._endpieceColumnType = endpieceColumnType;
    this._endpieceColumnLineNumber = endpieceColumnLineNumber;
    this._endpieceGateType = endpieceGateType;
    this._endpieceGateLineNumber = endpieceGateLineNumber;

    if (this.level.index >= 0) {
      // 일반 패드 고정
      this.normalPadPart.setDefaultData(
        CONST.INDEX_IL_FIXING_NORMAL_PAD,
        true,
        false,
        true,
        CONST.LB_FIXING_NORMAL_PAD,
        "",
      );

      // 일반 클립 고정
      if (
        label === CONST.LB_POSITION_SIDE_COLUMN ||
        label === CONST.LB_POSITION_SIDE_WING ||
        label === CONST.LB_POSITION_ROOF
      ) {
        this.normalClipPart.setDefaultData(
          CONST.INDEX_IL_FIXING_NORMAL_CLIP,
          false,
          false,
          false,
          CONST.LB_FIXING_NORMAL_CLIP,
          "",
          0.5,
        );
      } else if (label === CONST.LB_POSITION_FRONT || label === CONST.LB_POSITION_BACK) {
        this.normalClipPart.setDefaultData(
          CONST.INDEX_IL_FIXING_NORMAL_CLIP,
          false,
          false,
          false,
          CONST.LB_FIXING_NORMAL_CLIP,
          "",
          0.2,
        );
      }

      // 광폭 패드 고정
      this.laggingPadPart.setDefaultData(
        CONST.INDEX_IL_FIXING_LAGGING_PAD,
        false,
        false,
        false,
        CONST.LB_FIXING_LAGGING_PAD,
        "",
      );

      // 목재 고정
      this.woodPart.setDefaultData(CONST.INDEX_IL_FIXING_WOOD, false, false, false, CONST.LB_FIXING_WOOD, "");

      // 결로 패드 고정
      this.condensationPadPart.setDefaultData(
        CONST.INDEX_IL_FIXING_CONDENSATION_PAD,
        false,
        false,
        false,
        CONST.LB_FIXING_CONDENSATION_PAD,
        "",
      );
    }
  }

  // @override
  public setDefaultVariable(): void {
    this.algorithm_status();

    super.setDefaultVariable();
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    this.algorithm_endpieceColumnLineNumber();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (
      this.label === CONST.LB_POSITION_SIDE_COLUMN ||
      this.label === CONST.LB_POSITION_SIDE_WING ||
      this.label === CONST.LB_POSITION_ROOF
    ) {
      if (this.type === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
        this.normalClipPart.selected = false;
        this.normalClipPart.visible = false;
        this.laggingPadPart.selected = false;
        this.laggingPadPart.visible = false;
        this.woodPart.selected = false;
        this.woodPart.visible = false;
        this.condensationPadPart.selected = false;
        this.condensationPadPart.visible = false;
      } else if (this.type === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalPadPart.selected = false;
        this.normalPadPart.visible = false;
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
        this.laggingPadPart.selected = false;
        this.laggingPadPart.visible = false;
        this.woodPart.selected = false;
        this.woodPart.visible = false;
        this.condensationPadPart.selected = false;
        this.condensationPadPart.visible = false;
      } else if (this.type === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.normalPadPart.selected = false;
        this.normalPadPart.visible = false;
        this.normalClipPart.selected = false;
        this.normalClipPart.visible = false;
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
        this.woodPart.selected = false;
        this.woodPart.visible = false;
        this.condensationPadPart.selected = false;
        this.condensationPadPart.visible = false;
      } else if (this.type === CONST.LB_FIXING_TYPE_WOOD) {
        this.normalPadPart.selected = false;
        this.normalPadPart.visible = false;
        this.normalClipPart.selected = false;
        this.normalClipPart.visible = false;
        this.laggingPadPart.selected = false;
        this.laggingPadPart.visible = false;
        this.woodPart.selected = true;
        this.woodPart.visible = true;
        this.condensationPadPart.selected = false;
        this.condensationPadPart.visible = false;
      } else if (this.type === CONST.LB_FIXING_TYPE_CONDENSATION_PAD) {
        this.normalPadPart.selected = false;
        this.normalPadPart.visible = false;
        this.normalClipPart.selected = false;
        this.normalClipPart.visible = false;
        this.laggingPadPart.selected = false;
        this.laggingPadPart.visible = false;
        this.woodPart.selected = false;
        this.woodPart.visible = false;
        this.condensationPadPart.selected = true;
        this.condensationPadPart.visible = true;
      }
    } else if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
      this.normalPadPart.selected = false;
      this.normalPadPart.visible = false;
      this.normalClipPart.selected = false;
      this.normalClipPart.visible = false;
      this.laggingPadPart.selected = false;
      this.laggingPadPart.visible = false;
      this.woodPart.selected = false;
      this.woodPart.visible = false;
      this.condensationPadPart.selected = false;
      this.condensationPadPart.visible = false;
      // 마구리 중방방향
      if (this.type === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
      } else if (this.type === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
      } else if (this.type === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
      } else if (this.type === CONST.LB_FIXING_TYPE_WOOD) {
        this.woodPart.selected = true;
        this.woodPart.visible = true;
      }
      // 마구리 서까래쪽
      if (this.endpieceRafterType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
      } else if (this.endpieceRafterType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
      } else if (this.endpieceRafterType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
      } else if (this.endpieceRafterType === CONST.LB_FIXING_TYPE_WOOD) {
        this.woodPart.selected = true;
        this.woodPart.visible = true;
      }
      // 마구리 기둥쪽
      if (this.endpieceColumnType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
      } else if (this.endpieceColumnType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
      } else if (this.endpieceColumnType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
      } else if (this.endpieceColumnType === CONST.LB_FIXING_TYPE_WOOD) {
        this.woodPart.selected = true;
        this.woodPart.visible = true;
      }
      // 마구리 문주위
      if (this.endpieceGateType === CONST.LB_FIXING_TYPE_NORMAL_PAD) {
        this.normalPadPart.selected = true;
        this.normalPadPart.visible = true;
      } else if (this.endpieceGateType === CONST.LB_FIXING_TYPE_NORMAL_CLIP) {
        this.normalClipPart.selected = true;
        this.normalClipPart.visible = true;
      } else if (this.endpieceGateType === CONST.LB_FIXING_TYPE_LAGGING_PAD) {
        this.laggingPadPart.selected = true;
        this.laggingPadPart.visible = true;
      } else if (this.endpieceGateType === CONST.LB_FIXING_TYPE_WOOD) {
        this.woodPart.selected = true;
        this.woodPart.visible = true;
      }
    }
  }

  /**
   * 알고리즘: 선택(기둥 위치) <- 선택(커튼 피복)
   */
  public algorithm_selectedByCurtain(): void {
    if (this.level.index === 0 && this.label === CONST.LB_POSITION_SIDE_COLUMN) {
      if (this.design.preference.curtainColumnReplaceFlag === true) {
        this.selected = !this.struct.curCoverWorkIL.level1.curCoverPosition.selected;
      }
    }
  }

  /**
   * 알고리즘: 선택(방풍벽 위치) <- 선택(방풍벽)
   */
  public algorithm_seletedByWing(): void {
    if (this.label === CONST.LB_POSITION_SIDE_WING) {
      if (
        this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        this.selected = (<WingLevelIL>this.struct.wingWorkIL.levelAC[this.level.index]).wingPosition.selected;
      } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
        this.selected = (<WingLevelVL>this.struct.wingWorkVL.levelAC[this.level.index]).wingPosition.selected;
      }
    }
  }

  /**
   * 알고리즘: 선택(지붕 위치) <- 선택(개폐기)
   */
  public algorithm_selectedBySwitcher(): void {
    const fixingLevel: FixingLevelIL = <FixingLevelIL>this.level;
    const switcherLevel: SwitcherLevelIL = <SwitcherLevelIL>this.struct.switcherWorkIL.levelAC[this.level.index];

    if (this.label === CONST.LB_POSITION_ROOF) {
      this.selected = switcherLevel.roofPosition.selected;
    }
  }

  /**
   * 알고리즘: 형태 <- 형태(위치)
   */
  public algorithm_typeByPosition(): void {
    if (this.level.index === 0) {
      if (this.label === CONST.LB_POSITION_SIDE_COLUMN) {
        (<FixingLevelIL>this.level).wingPosition.type = this.type;
        (<FixingLevelIL>this.level).roofPosition.type = this.type;
        (<FixingLevelIL>this.level).frontPosition.type = this.type;
        (<FixingLevelIL>this.level).backPosition.type = this.type;
      } else if (this.label === CONST.LB_POSITION_FRONT) {
        (<FixingLevelIL>this.level).backPosition.type = this.type;
      }
    } else {
      if (this.label === CONST.LB_POSITION_SIDE_WING) {
        (<FixingLevelIL>this.level).columnPosition.type = this.type;
        (<FixingLevelIL>this.level).roofPosition.type = this.type;
        (<FixingLevelIL>this.level).frontPosition.type = this.type;
        (<FixingLevelIL>this.level).backPosition.type = this.type;
      } else if (this.label === CONST.LB_POSITION_FRONT) {
        (<FixingLevelIL>this.level).backPosition.type = this.type;
      }
    }
  }

  /**
   * 알고리즘: 마구리쪽 형태 <- 형태
   */
  public algorithm_endpieceTypeByType(): void {
    if (this.label === CONST.LB_POSITION_FRONT || this.label === CONST.LB_POSITION_BACK) {
      this.endpieceRafterType = this.type;
      this.endpieceColumnType = this.type;
      this.endpieceGateType = this.type;
    }
  }

  /**
   * 알고리즘: 줄수(측면+지붕 위치) <- 선택(개폐기), 개폐 방향(개폐기)
   */
  public algorithm_lineNumber(): void {
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );

    if (this.label === CONST.LB_POSITION_SIDE_COLUMN || this.label === CONST.LB_POSITION_SIDE_WING) {
      if (switcherPosition.selected === true) {
        if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH) {
          this.lineNumber = 2 * switcherPosition.switchStep;
        } else if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
          this.lineNumber = 3 * switcherPosition.switchStep;
        }
      } else {
        this.lineNumber = 4;
      }
    } else if (this.label === CONST.LB_POSITION_ROOF) {
      if (switcherPosition.selected === true) {
        if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_BOTH) {
          this.lineNumber = 2;
        } else if (switcherPosition.switchWay === CONST.LB_SWITCHER_SWITCH_WAY_ONE) {
          this.lineNumber = 1;
        }
      } else {
        this.lineNumber = 0;
      }
    }
  }

  /**
   * 알고리즘: 마구리 중방방향 줄수 <- 선택(마구리), 형태(마구리), 선택(개폐기)
   */
  public algorithm_endpieceMiddleLineNumber(): void {
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }

    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true) {
        if (
          endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
          endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM ||
          endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM
        ) {
          if (switcherPosition.selected === true) {
            this.lineNumber = 1;
          } else {
            this.lineNumber = 3;
          }
        } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.lineNumber = 1;
        }
      } else {
        this.lineNumber = 0;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true) {
        if (
          endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
          endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM ||
          endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM
        ) {
          if (switcherPosition.selected === true) {
            this.lineNumber = 1;
          } else {
            this.lineNumber = 3;
          }
        } else if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.lineNumber = 1;
        }
      } else {
        this.lineNumber = 0;
      }
    }
  }

  /**
   * 알고리즘: 마구리 서까래쪽 줄수 <- 앞면/뒷면 처마 서까래 수량(지붕 골조/1중)
   */
  public algorithm_endpieceRafterLineNumber(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      const roofPosition: RoofPositionIL = this.struct.roofWorkIL.level1.roofPosition;

      if (this.level.index === 0) {
        if (this.label === CONST.LB_POSITION_FRONT) {
          if (roofPosition.frontEaveRafterQuantity > 0) {
            this.endpieceRafterLineNumber = 2;
          } else {
            this.endpieceRafterLineNumber = 1;
          }
        } else if (this.label === CONST.LB_POSITION_BACK) {
          if (roofPosition.backEaveRafterQuantity > 0) {
            this.endpieceRafterLineNumber = 2;
          } else {
            this.endpieceRafterLineNumber = 1;
          }
        }
      }
    }
  }

  /**
   * 알고리즘: 마구리 기둥쪽 줄수 <- 선택(마구리), 형태(마구리), 개폐 동수(마구리), 선택(개폐기), 동수(기본정보)
   */
  public algorithm_endpieceColumnLineNumber(): void {
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true) {
        if (
          endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
          endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM ||
          endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM
        ) {
          if (switcherPosition.selected === true) {
            this.endpieceColumnLineNumber =
              2 + (this.basic.buildingNumber - endpieceLevel.frontPosition.switchBuildingNumber);
          } else {
            this.endpieceColumnLineNumber = this.basic.buildingNumber + 1;
          }
        } else if (endpieceLevel.frontPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.endpieceColumnLineNumber = 2;
        }
      } else {
        this.endpieceColumnLineNumber = 2;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true) {
        if (
          endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_WHOLE ||
          endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM ||
          endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER_BOTTOM_PRISM
        ) {
          if (switcherPosition.selected === true) {
            this.endpieceColumnLineNumber =
              2 + (this.basic.buildingNumber - endpieceLevel.backPosition.switchBuildingNumber);
          } else {
            this.endpieceColumnLineNumber = this.basic.buildingNumber + 1;
          }
        } else if (endpieceLevel.backPosition.type === CONST.LB_ENDPIECE_TYPE_UPPER) {
          this.endpieceColumnLineNumber = 2;
        }
      } else {
        this.endpieceColumnLineNumber = 2;
      }
    }
  }

  /**
   * 알고리즘: 마구리 문주위 줄수 <- 선택(마구리), 출입문 형태(마구리)
   */
  public algorithm_endpieceGateLineNumber(): void {
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }

    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true && endpieceLevel.frontPosition.gateFrameSelected === true) {
        this.endpieceGateLineNumber = 2;
      } else {
        this.endpieceGateLineNumber = 0;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true && endpieceLevel.backPosition.gateFrameSelected === true) {
        this.endpieceGateLineNumber = 2;
      } else {
        this.endpieceGateLineNumber = 0;
      }
    }
  }

  /**
   * 알고리즘: 상태 <- 선택(마구리), 형태(마구리), 출입문 형태(마구리), 선택(개폐기), 개폐 방향(개폐기)
   */
  public algorithm_status(): void {
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }

    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.index]
    );
    this.status = "";

    // 개폐 여부
    if (switcherPosition.selected === true) {
      this.status = switcherPosition.switchWay;
    } else {
      this.status = CONST.LB_SWITCHER_SWITCH_WAY_NO;
    }
    // 마구리 골조 형태 및 출입문 여부
    if (this.label === CONST.LB_POSITION_FRONT) {
      if (endpieceLevel.frontPosition.selected === true) {
        this.status += " (";
        this.status += endpieceLevel.frontPosition.type;

        if (endpieceLevel.frontPosition.gateType !== CONST.LB_GATE_TYPE_NONE) {
          this.status = CommonUtil.addDelimiter(this.status, ", ");
          this.status += endpieceLevel.frontPosition.gateType;
        }
        this.status += ")";
      } else {
        this.status = `${CONST.LB_ENDPIECE_WORK} ${CONST.TEXT_NONE}`;
      }
    } else if (this.label === CONST.LB_POSITION_BACK) {
      if (endpieceLevel.backPosition.selected === true) {
        this.status += " (";
        this.status += endpieceLevel.backPosition.type;

        if (endpieceLevel.backPosition.gateType !== CONST.LB_GATE_TYPE_NONE) {
          this.status = CommonUtil.addDelimiter(this.status, ", ");
          this.status += endpieceLevel.backPosition.gateType;
        }
        this.status += ")";
      } else {
        this.status = `${CONST.LB_ENDPIECE_WORK} ${CONST.TEXT_NONE}`;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
