import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Basic } from "vhows-design/src/object/design/basic/Basic";
import { BasicLevel } from "vhows-design/src/object/design/basic/BasicLevel";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Part } from "vhows-design/src/object/design/base/Part";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-23
 */
@jsonObject({
  knownTypes: [Part],
})
export class Position extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;
  /** 기본정보 객체 */
  public basic: Basic;
  /** 중수별 기본정보 객체 */
  public basicLevel: BasicLevel;
  /** 구조 객체 */
  public struct: Struct;
  /** 작업 객체 */
  public work: Work;
  /** 중수 객체 */
  public level: Level;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 형식적 명칭 */
  public get formalLabel(): string {
    return `${CONST.LB_POSITION}: ${this.label}`;
  }

  //
  public set formalLabel(value: string) {}

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 파트AC */
  @jsonArrayMember(Part)
  public partAC: Part[];

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _index: number; // 인덱스
  @jsonMember(Boolean)
  public _selected: boolean; // 선택
  @jsonMember(Boolean)
  public _selectedEstimate: boolean = true; // 견적 선택
  @jsonMember(Boolean)
  public _enabled: boolean; // 가용성 (선택 버튼 가용성)
  @jsonMember(Boolean)
  public _visible: boolean; // 가시성
  @jsonMember(String)
  public _label: string; // 명칭
  @jsonMember(String)
  public _type: string; // 형태

  /**
   * 인덱스 <br/>
   * 현재 위치의 고유번호이고, 타 작업간의 동일한 위치에 대한 순서가 유지되야함.
   */
  public get index(): number {
    return this._index;
  }

  //
  public set index(value: number) {
    this._index = value;
  }

  /**
   * 선택
   */
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 상속용
  }

  /**
   * 견적 선택
   */
  public get selectedEstimate(): boolean {
    return this._selectedEstimate;
  }

  //
  public set selectedEstimate(value: boolean) {
    this._selectedEstimate = value;
  }

  /**
   * 가용성 (선택 버튼 가용성)
   */
  public get enabled(): boolean {
    return this._enabled;
  }

  //
  public set enabled(value: boolean) {
    this._enabled = value;
  }

  /**
   * 가시성
   */
  public get visible(): boolean {
    return this._visible;
  }

  //
  public set visible(value: boolean) {
    this._visible = value;
  }

  /**
   * 명칭
   */
  public get label(): string {
    return this._label;
  }

  //
  public set label(value: string) {
    this._label = value;
  }

  /**
   * 형태
   */
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 상속용
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design, struct: Struct, work: Work, level: Level): void {
    this.design = design;
    this.struct = struct;
    this.work = work;
    this.level = level;
    if (design.basic != null) {
      this.basic = design.basic;
      this.basicLevel = this.basic.basicLevelAC[work.levelAC.indexOf(level)];
    }

    // 파트
    let part: Part;
    for (part of this.partAC) {
      part.setAssociation(design, struct, work, level, this);
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {
    // 파트
    let part: Part;
    for (part of this.partAC) {
      part.setReferenceVariable();
    }
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    this._index = index;
    this._selected = selected;
    this._enabled = enabled;
    this._visible = visible;
    this._label = label;
    this._type = type;

    // 추후 확인 - 벤로형 구조 추가 때문에 추가함
    // 파트
    let part: Part;
    for (part of this.partAC) {
      part.setDefaultData();
    }
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {
    // 파트
    let part: Part;
    for (part of this.partAC) {
      part.setDefaultVariable();
    }

    // 가시성 필터링
    // this.partAC.filterFunction = CommonUtil.fiterFunction_visible;
    // this.partAC.refresh();
  }

  /**
   * 기본 모델 설정: 데이터베이스를 대신함
   */
  public setDefaultModel(): void {
    // 파트
    let part: Part;
    for (part of this.partAC) {
      part.setDefaultModel();
    }
  }

  /**
   * 저장된 아이디값 세팅
   */
  public setSavedId(savedPosition: Position): void {
    if (savedPosition != null) {
      this.id = savedPosition.id;

      if (this.partAC != null && savedPosition.partAC != null) {
        for (let i: number = 0; i < this.partAC.length; i++) {
          // 기존 배열길이만큼 할당하되 저장된 배열길이가 더 작을경우는 할당하지 않음
          if (i < savedPosition.partAC.length) {
            this.partAC[i].setSavedId(savedPosition.partAC[i]);
          }
        }
      }
    }
  }

  /**
   * 최신 객체로 복원
   */
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    // 파트
    let part: Part;
    for (part of this.partAC) {
      part.restoreLatestObject(design, struct, work, level, this);
    }
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  public algorithmBasic(): void {
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 하위 파트의 기본 정보 알고리즘 호출
   */
  protected callPartAlgorithmBasic(): void {
    let part: Part;
    for (part of this.partAC) {
      part.algorithmBasic();
    }
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  public algorithm_partActivationByType(): void {
    // 상속용
  }
}
