import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemClip } from "vhows-design/src/object/design/item/list/ItemClip";
import { Part } from "vhows-design/src/object/design/base/Part";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-28
 */
@jsonObject({
  knownTypes: [],
})
export class GutterPart_FixingNormalClip extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플

  // 아이템
  public normalClip_normalClip: ItemClip;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    // 아이템
    this.normalClip_normalClip = new ItemClip();

    this.itemAC = [this.normalClip_normalClip];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 아이템
    this.normalClip_normalClip = <ItemClip>this.itemAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index === 0) {
    } else {
    }

    // 아이템
    if (this.level.index === 0) {
      this.normalClip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
    } else {
      this.normalClip_normalClip.setDefaultData(
        0,
        CONST.ITEM_ID_NORMAL_CLIP,
        CONST.ITEM_NAME_NORMAL_CLIP,
        "일반 클립",
        "플라스틱, 25mm",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    this.normalClip_normalClip.selected = true;
    this.normalClip_normalClip.visible = true;

    /// //////// 수량 ///////////

    if (this.level.index === 0) {
      this.normalClip_normalClip.designQuantity = (this.basicLevel.length / 0.5) * (this.basic.buildingNumber + 1);
    } else {
      this.normalClip_normalClip.designQuantity = (this.basicLevel.length / 0.5) * (this.basic.buildingNumber + 1);
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [일반 클립] 일반 클립 <- 가로대 파이프 샘플
   */
  public algorithmSpec_normalClip_normalClip(): void {
    const trunkPosition: TrunkPosition = (<TrunkLevel>this.struct.trunkWork.levelAC[this.level.index]).trunkPosition;
    this.normalClip_normalClip.specCrossSize = trunkPosition.straightPart.straightPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
