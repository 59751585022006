import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 보조 파이프
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-02-05
 */
@jsonObject
export class ItemSupportPipe extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  // 하우스 설계 > 규격 : [파이프형태, 재질, 파이프단면크기]
  public _specPipeType: string = undefined; // 파이프형태
  public _specMaterial: string = undefined; // 재질
  public _specPipeCrossSize: string = undefined; // 파이프 단면크기

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  /**
   * 파이프형태
   */
  public get specPipeType(): string {
    return this._specPipeType;
  }
  //
  public set specPipeType(value: string) {
    this._specPipeType = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 재질
   */
  public get specMaterial(): string {
    return this._specMaterial;
  }
  //
  public set specMaterial(value: string) {
    this._specMaterial = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 파이프 단면크기
   */
  public get specPipeCrossSize(): string {
    return this._specPipeCrossSize;
  }
  //
  public set specPipeCrossSize(value: string) {
    this._specPipeCrossSize = value;

    if (this.isCombine === true) {
      this.isCombine = false;
      this.makeSpecsList();
      this.makeSpecs();
      this.isCombine = true;
    }
  }

  /**
   * 단면크기 <br/>
   * 연결구, 고정구 등과 호환되는 소수점이하가 없는 단면크기
   */
  public get specCrossSize(): string {
    let crossSize: string = "";
    switch (this._specPipeCrossSize) {
      case "22.2mm":
        crossSize = "22mm";
        break;
      case "25.4mm":
        crossSize = "25mm";
        break;
      case "31.8mm":
        crossSize = "32mm";
        break;
      case "33.5mm":
        crossSize = "32mm";
        break;
      case "42.2mm":
        crossSize = "42mm";
        break;
      case "48.1mm":
        crossSize = "48mm";
        break;
      case "59.9mm":
        crossSize = "60mm";
        break;
      case "60.5mm":
        crossSize = "60mm";
        break;
      case "25A":
        crossSize = "32mm";
        break;
      case "40A":
        crossSize = "48mm";
        break;
      case "50A":
        crossSize = "60mm";
        break;
      case "65A":
        crossSize = "75mm";
        break;
      case "100A":
        crossSize = "115mm";
        break;
      default:
        // 단면크기가 없는 경우, 파이프단면크기 그대로 반환
        crossSize = this._specPipeCrossSize;
        break;
    }
    return crossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.supportPipeCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  // @override
  protected makeEachSpecBySpecs(): void {
    const specsArray: string[] = this._specs.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specMaterial = specsArray[1];
    this.specPipeCrossSize = specsArray[2];
  }

  // @override
  protected makeEachSpecBySpecsList(): void {
    const specsArray: string[] = this._specsList.split(CONST.DELIMITER_SPEC);
    this.specPipeType = specsArray[0];
    this.specMaterial = specsArray[1];
    this.specPipeCrossSize = specsArray[2];
  }

  // @override
  protected makeSpecsList(): void {
    this.specsList =
      this._specPipeType + CONST.DELIMITER_SPEC + this._specMaterial + CONST.DELIMITER_SPEC + this._specPipeCrossSize;
  }

  // @override
  protected makeSpecs(): void {
    this.specs = this._specsList;
  }
}
