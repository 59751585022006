import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { ColumnPartVL_SideStraight } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_SideStraight";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { EndpiecePositionIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpiecePositionIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WindowPositionIL } from "vhows-design/src/object/design/other/window/il/WindowPositionIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { ColumnPartVL_Column } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Column";
import { ColumnPartVL_Beam } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Beam";
import { ColumnPartVL_Middle } from "vhows-design/src/object/design/frame/column/vl/ColumnPartVL_Middle";
import { RackPosition } from "vhows-design/src/object/design/other/rack/RackPosition";

/**
 * @author 오은혜
 * @copyright RUNean Inc.
 * @date 2022-04-08
 */
@jsonObject({
  knownTypes: [ColumnPartVL_Beam, ColumnPartVL_Column, ColumnPartVL_Middle, ColumnPartVL_SideStraight],
})
export class ColumnPositionVL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public prismColumnPart: ColumnPartVL_Column;
  public prismBeamPart: ColumnPartVL_Beam;
  public prismMiddlePart: ColumnPartVL_Middle;
  public sideStraightPart: ColumnPartVL_SideStraight;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /**
   * 기둥 길이 <- 각기둥 길이
   */
  public get columnLength(): number {
    return this.prismColumnPart.columnLength;
  }

  /**
   * 기둥 간격 <- 각기둥 간격
   */
  public get columnInterval(): number {
    return this.prismColumnPart.columnInterval;
  }

  /**
   * 기둥 수량 <- 각기둥 수량
   */
  public get columnTotalQuantity(): number {
    return this.prismColumnPart.columnTotalQuantity;
  }

  /**
   * 기둥 파이프형태 <- 각기둥 파이프
   */
  public get columnPipeType(): string {
    return this.prismColumnPart.columnPipeSample.specPipeType;
  }

  /**
   * 기둥 단면크기 <- 각기둥 파이프
   */
  public get columnCrossSize(): string {
    return this.prismColumnPart.columnPipeSample.specCrossSize;
  }

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.sideStraightPart.algorithmPart();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 지붕 골조
    // for (const roofLevel of this.struct.roofWorkIL.levelAC as RoofLevelVL[]) {
    //   roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndColumnEdge_secondLevelSoleSconce();
    //   roofLevel.roofPosition.rafterPart.algorithm_rafterInterval();
    //   roofLevel.roofPosition.rafterPart.algorithmPart();
    // }
    //
    // // 방풍벽 골조
    // this.struct.wingWorkIL.algorithmBasic();
    //
    // 바닥 골조
    this.struct.foundationWorkIL.level1.foundationPosition.girthPipePart.algorithmPart();
    this.struct.foundationWorkIL.level1.foundationPosition.columnConcretePart.algorithmPart();
    //
    // // 마구리 골조
    // for (const endpiecePosition of this.struct.endpieceWorkIL.level1.positionAC as EndpiecePositionIL[]) {
    //   endpiecePosition.algorithm_typeByColumnType();
    // }
    // this.struct.endpieceWorkIL.algorithmBasic();
    //
    // 물받이
    for (const gutterLevel of this.struct.gutterWorkIL.levelAC as GutterLevelIL[]) {
      gutterLevel.gutterPosition.ironPlatePart.algorithmSpec_ironPlate_ubar();
      gutterLevel.gutterPosition.ironPlatePart.algorithmPart();
      gutterLevel.gutterPosition.plasticPart.algorithmSpec_plastic_ubar();
      gutterLevel.gutterPosition.plasticPart.algorithmPart();
    }

    // // 피복 고정
    // for (const fixingLevel of this.struct.fixingWorkIL.levelAC as FixingLevelIL[]) {
    //   fixingLevel.columnPosition.algorithmBasic();
    //   fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipColumn_normalClip();
    //   fixingLevel.frontPosition.algorithmBasic();
    //   fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipColumn_normalClip();
    //   fixingLevel.backPosition.algorithmBasic();
    // }
    //
    // 치마 피복
    for (const skirtLevel of this.struct.skirtWorkIL.levelAC as SkirtLevelIL[]) {
      skirtLevel.columnPosition.fixingSkirtClipPart.algorithm_clipInterval();
      skirtLevel.columnPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
      skirtLevel.columnPosition.algorithmBasic();
    }

    // 바람막이 피복
    for (const windbreakLevel of this.struct.windbreakWorkIL.levelAC as WindbreakLevelIL[]) {
      windbreakLevel.columnPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      windbreakLevel.columnPosition.algorithmBasic();
    }

    // 선반/균상다이
    if (this.level.index === 0) {
      const rackPosition: RackPosition = this.struct.rackWork.level1.wholePosition;
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame_tClamp();
      rackPosition.rackPart.algorithmSpec_rackColumnAndFrame2_normalHolder();
      rackPosition.rackPart.algorithmPart();
    }

    // 커튼 개폐
    for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
      curSwitchLevel.columnPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
      curSwitchLevel.columnPosition.axisPart.algorithmPart();
      curSwitchLevel.columnPosition.linePart.algorithmPart();
      curSwitchLevel.columnPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.frontPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
      curSwitchLevel.frontPosition.axisPart.algorithmPart();
      curSwitchLevel.frontPosition.linePart.algorithmPart();
      curSwitchLevel.frontPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.backPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
      curSwitchLevel.backPosition.axisPart.algorithmPart();
      curSwitchLevel.backPosition.linePart.algorithmPart();
      curSwitchLevel.backPosition.fixingClipSidePart.algorithmPart();
      curSwitchLevel.ceilingPosition.motorPart.reducedMotorSample.algorithmSpec();
      curSwitchLevel.ceilingPosition.framePart.algorithmSpec_frameDriveAxis_bearingSet();
      curSwitchLevel.ceilingPosition.framePart.algorithmSpec_frameSupportAxis_uClamp();
      curSwitchLevel.ceilingPosition.framePart.algorithmPart();
      curSwitchLevel.ceilingPosition.linePart.algorithmPart();
      curSwitchLevel.ceilingPosition.fixingClipCeilingPart.algorithmPart();
    }
    (<CurSwitchLevelIL>(
      this.struct.curSwitchWorkIL.levelAC[this.level.index]
    )).ceilingPosition.linePart.algorithm_towLineNumberAndSupportLineNumber();

    // 관수 제어부
    if (this.level.index === 0) {
      this.struct.mainpipeWork.level1.wholePosition.upperFramePart.algorithmPart();
    }

    // 창문
    if (this.level.index === 0) {
      for (const windowPosition of this.struct.windowWorkIL.level1.positionAC as WindowPositionIL[]) {
        windowPosition.sashPart.sashFramePipeSample.algorithmSpec();
      }
    }
    // 양액시스템
    if (this.level.index === 0) {
      this.struct.hydCultivationWork.level1.wholePosition.bedPart.algorithm_hallwayWidth();
    }

    // 운반레일
    if (this.level.index === 0) {
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithm_railHookInterval();
      this.struct.railWork.level1.wholePosition.upperRailPart.algorithmSpec_railAndFrame_secondLevelRailHook();
      this.struct.railWork.level1.wholePosition.sideRailPart.algorithm_railHookInterval();
      this.struct.railWork.level1.wholePosition.sideRailPart.algorithmSpec_railAndFrame_sideRailHook();
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.prismColumnPart = new ColumnPartVL_Column();
    this.prismBeamPart = new ColumnPartVL_Beam();
    this.prismMiddlePart = new ColumnPartVL_Middle();
    this.sideStraightPart = new ColumnPartVL_SideStraight();

    this.partAC = [this.prismColumnPart, this.prismBeamPart, this.prismMiddlePart, this.sideStraightPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.prismColumnPart = <ColumnPartVL_Column>this.partAC[0];
    this.prismBeamPart = <ColumnPartVL_Beam>this.partAC[1];
    this.prismMiddlePart = <ColumnPartVL_Middle>this.partAC[2];
    this.sideStraightPart = <ColumnPartVL_SideStraight>this.partAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    if (this.level.index >= 0) {
      // 기둥
      this.prismColumnPart.setDefaultData(
        CONST.INDEX_VL_COLUMN_PRISM_COLUMN,
        true,
        false,
        true,
        CONST.LB_COLUMN_PRISM_COLUMN,
        "",
        3.5,
        4.0,
        4.0,
        false,
        CONST.LB_COLUMN_BRACING_TYPE_X_BRACING,
        2,
      );

      // 보
      this.prismBeamPart.setDefaultData(
        CONST.INDEX_VL_COLUMN_PRISM_BEAM,
        true,
        false,
        true,
        CONST.LB_COLUMN_PRISM_BEAM,
        "",
        CONST.LB_COLUMN_BEAM_TYPE_GUTTER_BEAM,
      );

      // 중방
      this.prismMiddlePart.setDefaultData(
        CONST.INDEX_VL_COLUMN_PRISM_MIDDLE,
        true,
        false,
        true,
        CONST.LB_COLUMN_PRISM_MIDDLE,
        "",
        false,
        0.7,
        20,
        2,
        CONST.LB_COLUMN_MIDDLE_BRACING_TYPE_X_BRACING,
        2,
      );

      // 측면 가로대
      this.sideStraightPart.setDefaultData(
        CONST.INDEX_VL_COLUMN_SIDE_STRAIGHT,
        true,
        false,
        true,
        CONST.LB_COLUMN_SIDE_STRAIGHT,
        "",
        8,
        CONST.LB_COLUMN_SIDE_STRAIGHT_TYPE_C_SECTION_STEEL,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_COLUMN_TYPE_PRISM) {
      this.prismColumnPart.selected = true;
      this.prismColumnPart.visible = true;
      this.prismBeamPart.selected = true;
      this.prismBeamPart.visible = true;
      this.prismMiddlePart.selected = true;
      this.prismMiddlePart.visible = true;
      this.sideStraightPart.selected = true;
      this.sideStraightPart.visible = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
