import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { Level } from "vhows-design/src/object/design/base/Level";
import { Position } from "vhows-design/src/object/design/base/Position";
import { MaterialPart_Custom } from "vhows-design/src/object/design/other/material/MaterialPart_Custom";
import { VentExhaustPart_EndpieceFan } from "vhows-design/src/object/design/vent/exhaust/VentExhaustPart_EndpieceFan";
import { VentExhaustPart_RoofFanPower } from "vhows-design/src/object/design/vent/exhaust/VentExhaustPart_RoofFanPower";
import { VentExhaustPart_RoofFanManual } from "vhows-design/src/object/design/vent/exhaust/VentExhaustPart_RoofFanManual";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2018-02-13
 */
@jsonObject({
  knownTypes: [
    VentExhaustPart_EndpieceFan,
    VentExhaustPart_RoofFanManual,
    VentExhaustPart_RoofFanPower,
    MaterialPart_Custom,
  ],
})
export class VentExhaustPosition extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public endpieceFanPart: VentExhaustPart_EndpieceFan;
  public roofFanPowerPart: VentExhaustPart_RoofFanPower;
  public roofFanManualPart: VentExhaustPart_RoofFanManual;
  public customPart: MaterialPart_Custom;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(String)
  public _roofType: string; // 지붕 형태

  /**
   * 지붕 형태
   */
  public get roofType(): string {
    return this._roofType;
  }

  //
  public set roofType(value: string) {
    this._roofType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.endpieceFanPart = new VentExhaustPart_EndpieceFan();
    this.roofFanPowerPart = new VentExhaustPart_RoofFanPower();
    this.roofFanManualPart = new VentExhaustPart_RoofFanManual();
    this.customPart = new MaterialPart_Custom();

    this.partAC = [this.endpieceFanPart, this.roofFanPowerPart, this.roofFanManualPart, this.customPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.endpieceFanPart = <VentExhaustPart_EndpieceFan>this.partAC[0];
    this.roofFanPowerPart = <VentExhaustPart_RoofFanPower>this.partAC[1];
    this.roofFanManualPart = <VentExhaustPart_RoofFanManual>this.partAC[2];
    this.customPart = <MaterialPart_Custom>this.partAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   * @param roofType: string 지붕 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
    roofType: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    this._roofType = roofType;

    if (this.level.index >= 0) {
      if (
        this.design.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.design.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.design.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        // 앞뒷면 배기팬
        this.endpieceFanPart.setDefaultData(
          CONST.INDEX_CM_VENT_EXHAUST_ENDPIECE_FAN,
          false,
          false,
          false,
          CONST.LB_VENT_EXHAUST_ENDPIECE_FAN,
          "",
          1,
        );

        // 지붕 환기팬(동력)
        this.roofFanPowerPart.setDefaultData(
          CONST.INDEX_CM_VENT_EXHAUST_ROOF_FAN_POWER,
          false,
          false,
          false,
          CONST.LB_VENT_EXHAUST_ROOF_FAN_POWER,
          "",
          3,
          16.0,
          1,
        );

        // 지붕 환기구(수동)
        this.roofFanManualPart.setDefaultData(
          CONST.INDEX_CM_VENT_EXHAUST_ROOF_FAN_MANUAL,
          true,
          false,
          true,
          CONST.LB_VENT_EXHAUST_ROOF_FAN_MANUAL,
          "",
          1,
          8.0,
          1,
          false,
        );
      } else if (
        this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
        this.design.basic.structureName === CONST.LB_STRUCT_VENLO
      ) {
        // 앞뒷면 배기팬
        this.endpieceFanPart.setDefaultData(
          CONST.INDEX_CM_VENT_EXHAUST_ENDPIECE_FAN,
          true,
          false,
          true,
          CONST.LB_VENT_EXHAUST_ENDPIECE_FAN,
          "",
          3,
        );

        // 지붕 환기팬(동력)
        this.roofFanPowerPart.setDefaultData(
          CONST.INDEX_CM_VENT_EXHAUST_ROOF_FAN_POWER,
          false,
          false,
          false,
          CONST.LB_VENT_EXHAUST_ROOF_FAN_POWER,
          "",
          3,
          16.0,
          1,
        );

        // 지붕 환기구(수동)
        this.roofFanManualPart.setDefaultData(
          CONST.INDEX_CM_VENT_EXHAUST_ROOF_FAN_MANUAL,
          false,
          false,
          false,
          CONST.LB_VENT_EXHAUST_ROOF_FAN_MANUAL,
          "",
          1,
          8.0,
          1,
          false,
        );
      }

      // 임의 품목
      this.customPart.setDefaultData(
        CONST.INDEX_CM_VENT_EXHAUST_CUSTOM,
        false,
        false,
        false,
        CONST.LB_VENT_EXHAUST_CUSTOM,
        "",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct, work: Work, level: Level): void {
    if (this.partAC.length === 3) {
      // 임의 품목
      const customPart: MaterialPart_Custom = new MaterialPart_Custom();
      customPart.setAssociation(design, struct, work, level, this);
      customPart.setDefaultData(
        CONST.INDEX_CM_VENT_EXHAUST_CUSTOM,
        false,
        false,
        false,
        CONST.LB_VENT_EXHAUST_CUSTOM,
        "",
      );
      this.partAC.push(customPart);
    }

    super.restoreLatestObject(design, struct, work, level);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태, 지붕 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_VENT_EXHAUST_TYPE_FAN) {
      this.endpieceFanPart.selected = true;
      this.endpieceFanPart.visible = true;
    } else {
      this.endpieceFanPart.selected = false;
      this.endpieceFanPart.visible = false;
    }

    if (this.roofType === CONST.LB_VENT_EXHAUST_ROOF_TYPE_FAN_POWER) {
      this.roofFanPowerPart.selected = true;
      this.roofFanPowerPart.visible = true;
      this.roofFanManualPart.selected = false;
      this.roofFanManualPart.visible = false;
    } else if (this.roofType === CONST.LB_VENT_EXHAUST_ROOF_TYPE_FAN_MANUAL) {
      this.roofFanPowerPart.selected = false;
      this.roofFanPowerPart.visible = false;
      this.roofFanManualPart.selected = true;
      this.roofFanManualPart.visible = true;
    } else {
      this.roofFanPowerPart.selected = false;
      this.roofFanPowerPart.visible = false;
      this.roofFanManualPart.selected = false;
      this.roofFanManualPart.visible = false;
    }

    if (this.type === CONST.LB_VENT_EXHAUST_TYPE_CUSTOM || this.roofType === CONST.LB_VENT_EXHAUST_ROOF_TYPE_CUSTOM) {
      this.customPart.selected = true;
      this.customPart.visible = true;
    } else {
      this.customPart.selected = false;
      this.customPart.visible = false;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
