import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { SkirtLevelIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtLevelIL";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { FoundationLevelIL } from "vhows-design/src/object/design/foundation/il/FoundationLevelIL";
import { FoundationPositionIL } from "vhows-design/src/object/design/foundation/il/FoundationPositionIL";
import { GutterLevelIL } from "vhows-design/src/object/design/frame/gutter/il/GutterLevelIL";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { WingLevelIL } from "vhows-design/src/object/design/frame/wing/WingLevelIL";
import { WindowPositionIL } from "vhows-design/src/object/design/other/window/il/WindowPositionIL";
import { WindbreakLevelIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakLevelIL";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { ColumnPartIL_CylinderMiddle } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderMiddle";
import { ColumnPartIL_CylinderColumn } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderColumn";
import { ColumnPartIL_CylinderBeam } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderBeam";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-14
 */
@jsonObject
export class ColumnSampleIL_CylinderColumnPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const cylinderColumnPart: ColumnPartIL_CylinderColumn = <ColumnPartIL_CylinderColumn>this.part;
    cylinderColumnPart.algorithmSpec_column_pipe();
    cylinderColumnPart.columnBracingPipeSample.algorithmSpec();
    cylinderColumnPart.algorithmSpec_columnBracingAndColumn_rotationAsibaClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    const cylinderBeamPart: ColumnPartIL_CylinderBeam = (<ColumnPositionIL>this.position).cylinderBeamPart;
    cylinderBeamPart.beamPipeSample.algorithmSpec();
    cylinderBeamPart.algorithmSpec_beamAndColumn_tClamp();

    const cylinderMiddlePart: ColumnPartIL_CylinderMiddle = (<ColumnPositionIL>this.position).cylinderMiddlePart;
    cylinderMiddlePart.middlePipeSample.algorithmSpec();
    cylinderMiddlePart.algorithmSpec_middleAndColumn_fixAsibaClamp();
    cylinderMiddlePart.algorithmSpec_middleSupportNormal_middlePairClamp();
    cylinderMiddlePart.algorithmSpec_middleSupportNormal_middleSoleClamp();

    /// //////// 외부 ///////////

    if (this.position.type === CONST.LB_COLUMN_TYPE_CYLINDER) {
      // 지붕 골조
      for (const roofLevel of this.struct.roofWorkIL.levelAC as RoofLevelIL[]) {
        // 모든 중수
        roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndColumnCenterCylinder_secondLevelPairSconce();
        roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndBeamEdge_firstLevelSoleSconce();
        roofLevel.roofPosition.rafterPart.algorithmSpec_rafterAndColumnEdge_secondLevelSoleSconce();
        roofLevel.roofPosition.rafterPart.algorithmPart();
      }

      // 방풍벽 골조
      for (const wingLevel of this.struct.wingWorkIL.levelAC as WingLevelIL[]) {
        // 모든 중수
        wingLevel.wingPosition.wingRafterPart.algorithmSpec_wingRafterSecondAndBeamCylinder_secondLevelPairSconce();
      }

      // 바닥 골조
      const foundationPosition: FoundationPositionIL = (<FoundationLevelIL>(
        this.struct.foundationWorkIL.levelAC[this.level.index]
      )).foundationPosition;
      foundationPosition.girthPipePart.algorithmSpec_girthLengthAndColumnCylinder_fixAsibaClamp();
      foundationPosition.girthPipePart.algorithmSpec_girthLengthAndColumnCylinder_steelStringGrabber();
      foundationPosition.girthPipePart.algorithmSpec_girthWidthAndColumnCylinder_fixAsibaClamp();
      foundationPosition.girthPipePart.algorithmSpec_girthWidthAndColumnCylinder_steelStringGrabber();
      foundationPosition.columnConcretePart.algorithmSpec_columnConcreteStrengthen_steelStringGrabber();

      // 마구리 골조
      for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) {
        // 모든 중수
        endpieceLevel.frontPosition.cylinderStudSepPart.studBottomPipeSample.algorithmSpec();
        endpieceLevel.backPosition.cylinderStudSepPart.studBottomPipeSample.algorithmSpec();
      }

      // 물받이
      for (const gutterLevel of this.struct.gutterWorkIL.levelAC as GutterLevelIL[]) {
        // 모든 중수
        gutterLevel.gutterPosition.ironPlatePart.algorithmSpec_ironPlate_ubar();
        gutterLevel.gutterPosition.plasticPart.algorithmSpec_plastic_ubar();
        gutterLevel.gutterPosition.weavingFilmPart.algorithmSpec_weavingFilm_steelStringGrabber();
      }

      // 피복 고정
      for (const fixingLevel of this.struct.fixingWorkIL.levelAC as FixingLevelIL[]) {
        // 모든 중수
        fixingLevel.frontPosition.normalClipPart.algorithmSpec_normalClipColumn_normalClip();
        fixingLevel.backPosition.normalClipPart.algorithmSpec_normalClipColumn_normalClip();
      }

      // 치마 피복
      for (const skirtLevel of this.struct.skirtWorkIL.levelAC as SkirtLevelIL[]) {
        skirtLevel.columnPosition.fixingSkirtClipPart.skirtClipSample.algorithmSpec();
      }

      // 바람막이 피복
      for (const windbreakLevel of this.struct.windbreakWorkIL.levelAC as WindbreakLevelIL[]) {
        windbreakLevel.columnPosition.fixingNormalClipPart.normalClipSample.algorithmSpec();
      }

      // 커튼 개폐
      for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
        curSwitchLevel.columnPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
        curSwitchLevel.frontPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
        curSwitchLevel.backPosition.axisPart.algorithmSpec_axisHolder_bearingSet();
        curSwitchLevel.ceilingPosition.motorPart.reducedMotorSample.algorithmSpec();
        curSwitchLevel.ceilingPosition.framePart.algorithmSpec_frameDriveAxis_bearingSet();
        curSwitchLevel.ceilingPosition.framePart.algorithmSpec_frameSupportAxis_uClamp();
      }

      // 창문
      if (this.level.index === 0) {
        for (const windowPosition of this.struct.windowWorkIL.level1.positionAC as WindowPositionIL[]) {
          windowPosition.sashPart.sashFramePipeSample.algorithmSpec();
        }
      }

      // 운반레일
      if (this.level.index === 0) {
        this.struct.railWork.level1.wholePosition.sideRailPart.algorithmSpec_railAndFrame_sideRailHook();
      }
    }
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 길이(파트)
   */
  // @override
  public algorithmSpec(): void {
    this.specLength = (<ColumnPartIL_CylinderColumn>this.part).columnLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
