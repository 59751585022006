import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemWood } from "vhows-design/src/object/design/item/list/ItemWood";
import { WindowLevelIL } from "vhows-design/src/object/design/other/window/il/WindowLevelIL";
import { ScreennetPositionIL } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { WindbreakPositionIL } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPositionIL";
import { FixingPartIL_Wood } from "vhows-design/src/object/design/cover/fixing/il/FixingPartIL_Wood";
import { FixingLevelIL } from "vhows-design/src/object/design/cover/fixing/il/FixingLevelIL";
import { FixingPositionIL } from "vhows-design/src/object/design/cover/fixing/il/FixingPositionIL";
import { WindbreakPartIL_FixingWood } from "vhows-design/src/object/design/cover/windbreak/il/WindbreakPartIL_FixingWood";
import { SkirtPartIL_FixingWood } from "vhows-design/src/object/design/cover/skirt/il/SkirtPartIL_FixingWood";
import { ScreennetPartIL_FixingWood } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPartIL_FixingWood";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-30
 */
@jsonObject
export class FixingSampleIL_RectLumber extends ItemWood {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const woodPart: FixingPartIL_Wood = <FixingPartIL_Wood>this.part;
    woodPart.algorithmSpec_wood_rectLumber();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 위치
    for (let p: number = 0; p < this.level.positionAC.length; p++) {
      if (this.position.index === p) {
        continue;
      }
      (<FixingPositionIL>this.level.positionAC[p]).woodPart.rectLumberSample.specs = this.specs;
    }

    // 다른 중수
    if (this.level.index === 0) {
      for (let l: number = 1; l < this.work.levelAC.length; l++) {
        // 해당 중수의 측면 위치만 바꿔줘도 전체 위치의 규격이 바뀌게됨
        (<FixingLevelIL>this.work.levelAC[l]).columnPosition.woodPart.rectLumberSample.specs = this.specs;
      }
    }

    /// //////// 외부 ///////////

    // 치마 피복
    const skirtPart_FixingWood: SkirtPartIL_FixingWood = (<SkirtPositionIL>(
      this.struct.skirtWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingWoodPart;
    skirtPart_FixingWood.rectLumberSample.algorithmSpec();

    // 바람막이 피복
    const windbreakPart_FixingWood: WindbreakPartIL_FixingWood = (<WindbreakPositionIL>(
      this.struct.windbreakWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    )).fixingWoodPart;
    windbreakPart_FixingWood.rectLumberSample.algorithmSpec();

    // 방충망
    if (this.level.index === 0) {
      const screennetPart_FixingWood: ScreennetPartIL_FixingWood = (<ScreennetPositionIL>(
        this.struct.screennetWorkIL.levelAC[this.level.index].positionAC[this.position.index]
      )).fixingWoodPart;
      screennetPart_FixingWood.rectLumberSample.algorithmSpec();
    }

    // 창문
    if (this.level.index === 0) {
      const windowLevel: WindowLevelIL = <WindowLevelIL>this.struct.windowWorkIL.levelAC[this.level.index];
      if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
        windowLevel.leftPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
        windowLevel.rightPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_FRONT) {
        windowLevel.frontPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
      } else if (this.position.label === CONST.LB_POSITION_BACK) {
        windowLevel.backPosition.fixingWoodPart.rectLumberSample.algorithmSpec();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
