import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Work } from "vhows-design/src/object/design/base/Work";
import { CoverLevelIL } from "vhows-design/src/object/design/cover/cover/il/CoverLevelIL";
import { StructIL } from "vhows-design/src/object/design/base/StructIL";
import { StructVL } from "vhows-design/src/object/design/base/StructVL";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Level } from "vhows-design/src/object/design/base/Level";
import { CoverPart_WeavingFilm } from "vhows-design/src/object/design/cover/cover/CoverPart_WeavingFilm";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-29
 */
@jsonObject({
  knownTypes: [CoverLevelIL],
})
export class CoverWorkIL extends Work {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public level1: CoverLevelIL;
  public level2: CoverLevelIL;
  public level3: CoverLevelIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.level1 = new CoverLevelIL();
    this.level2 = new CoverLevelIL();
    this.level3 = new CoverLevelIL();

    this.levelAC = [this.level1, this.level2, this.level3];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.level1 = <CoverLevelIL>this.levelAC[0];
    this.level2 = <CoverLevelIL>this.levelAC[1];
    this.level3 = <CoverLevelIL>this.levelAC[2];

    super.setReferenceVariable();
  }

  // @override
  public setDefaultData(
    index: number = 0,
    depth: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    category: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, depth, selected, enabled, visible, label, category, buildNote);
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      this.level1.setDefaultData(0, true, true, true, CONST.LB_LEVEL_1);
      this.level2.setDefaultData(1, true, false, true, CONST.LB_LEVEL_2);
      this.level3.setDefaultData(2, true, false, true, CONST.LB_LEVEL_3);
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      this.level1.setDefaultData(0, true, true, true, CONST.LB_LEVEL_1);
      this.level2.setDefaultData(1, true, false, true, CONST.LB_LEVEL_2);
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct): void {
    if (design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      this.levelAC.splice(2, 1);
    }

    super.restoreLatestObject(design, struct);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
