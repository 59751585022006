interface ToastType {
  ERROR: "error";
  SUCCESS: "success";
}

export const ENTRY_TYPE = {
  COUNSEL: "counsel",
  HCOST: "hcost",
};

export const INFLOW_TYPE = {
  COUNSEL: 4,
  HCOST: 0,
};

export const Facil_Factors_Event = {
  expand: "accordionExpand",
  checked: "listChecked",
};

export const FARMMORNING_ACCESS_TOKEN = "fm-at";
export const FARMMORNING_REFRESH_TOKEN = "fm-rt";

export enum PROGRESS_STATUS {
  PREPARATION = "P01", // 준비
  CONSULTATION_STOP = "P02", // 종료
  CONSULTATION_REQUEST = "P03", // 상담요청
  CONSULTING = "P04", // 상담중
  DESIGN_ESTIMATE = "P05", // 설계/견적중
  CONSULTATION_COMPLETE = "P06", // 상담완료
  CONTRACT = "P07", // 계약중
  CONTRACT_COMPLETED = "P08", // 계약 완료
  CONSTRUCTION = "P09", // 시공중
  CONSTRUCTION_COMPLETED = "P10", // 시공 완료
  TRANSACTION_SETTLEMENT = "P11", // 거래/정산중
  COMPLETED = "P12", // 완료
}

export const PROGRESS_TEXT = {
  REQUEST: "요청",
  PROGRESS: "진행중",
  COMPLETE: "완료",
};

export const TRANSACTION_METHOD = [
  { text: "시공까지 하고 싶어요", value: "T03" },
  { text: "자재만 구입 할래요", value: "T02" },
];

export const ERROR_MESSAGE = {
  ERROR: "오류가 발생하였습니다.",
};

export const QueryKey = {
  GET_B2C_COUNSEL_LIST: "getB2CCounselList",
  GET_B2C_COUNSEL: "getB2CCounsel",
  GET_B2C_DESIGN_MODEL: "getB2CDesignModel",
  GET_PRODUCTS: "getProducts",
};

export const ExpertName = {
  KIM: "김치곤",
  LEE: "이기완",
};

export const MODIFY_ALLOW_STATUS = [PROGRESS_STATUS.PREPARATION, PROGRESS_STATUS.CONSULTATION_REQUEST];

export const PROGRESS_COMPLETE_CASE = [PROGRESS_STATUS.COMPLETED, PROGRESS_STATUS.CONSULTATION_STOP];

export enum ProgressStatusGroup {
  //요청
  REQUEST = "G01",
  // 진행중
  PROGRESS = "G02",
  // 완료
  COMPLETE = "G03",
}

export const TOAST_TYPE: ToastType = {
  ERROR: "error",
  SUCCESS: "success",
};

export const LAST_STEP_TEXT = {
  DESIGN: "설계결과!",
  COUNSEL: "신청완료!",
};

export const BLUR_DATA_URL =
  "data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mN8//HLfwYiAOOoQvoqBAB  bWyZJf74GZgAAAABJRU5ErkJggg==";

export const HEAD_TITLE = "비닐하우스 견적/시공 상담";
