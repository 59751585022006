import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPowerSwitcher } from "vhows-design/src/object/design/item/list/ItemPowerSwitcher";
import { ItemReducedMotor } from "vhows-design/src/object/design/item/list/ItemReducedMotor";
import { ItemSkylightMotor } from "vhows-design/src/object/design/item/list/ItemSkylightMotor";
import { Position } from "vhows-design/src/object/design/base/Position";
import { Part } from "vhows-design/src/object/design/base/Part";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { ControllerPartIL_Controller } from "vhows-design/src/object/design/switches/controller/il/ControllerPartIL_Controller";
import { CurSwitchPositionIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchPositionIL";
import { SkySwitchLevelIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchLevelIL";
import { SkySwitchPositionIL } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPositionIL";
import { SwitcherLevelIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { Basic } from "vhows-design/src/object/design/basic/Basic";
import { SwitcherPartIL_Power } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPartIL_Power";
import { SkySwitchPartIL_Power } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPartIL_Power";
import { SkySwitchPartIL_Rackpinion } from "vhows-design/src/object/design/skylight/switches/il/SkySwitchPartIL_Rackpinion";
import { CurSwitchPartIL_Power } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Power";
import { CurSwitchPartIL_Motor } from "vhows-design/src/object/design/curtain/switches/CurSwitchPartIL_Motor";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [ControllerPartIL_Controller],
})
export class ControllerPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public controllerPart: ControllerPartIL_Controller;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _vinylSwitcherQuantity: number = 0; // 비닐 개폐기 수량
  @jsonMember(Number)
  public _nonwovenSwitcherQuantity: number = 0; // 부직포 개폐기 수량
  @jsonMember(Number)
  public _laggingSwitcherQuantity: number = 0; // 보온덮개 개폐기 수량
  @jsonMember(Number)
  public _vinylCurSwitcherQuantity: number = 0; // 비닐 커튼 개폐기 수량
  @jsonMember(Number)
  public _nonwovenCurSwitcherQuantity: number = 0; // 부직포 커튼 개폐기 수량
  @jsonMember(Number)
  public _laggingCurSwitcherQuantity: number = 0; // 보온덮개 커튼 개폐기 수량
  @jsonMember(Number)
  public _sigleCurMotorQuantity: number = 0; // 단상 커튼 모터 수량
  @jsonMember(Number)
  public _threeCurMotorQuantity: number = 0; // 삼상 커튼 모터 수량

  /**
   * 비닐 개폐기 수량
   */
  public get vinylSwitcherQuantity(): number {
    return this._vinylSwitcherQuantity;
  }

  public set vinylSwitcherQuantity(value: number) {
    this._vinylSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 부직포 개폐기 수량
   */
  public get nonwovenSwitcherQuantity(): number {
    return this._nonwovenSwitcherQuantity;
  }

  public set nonwovenSwitcherQuantity(value: number) {
    this._nonwovenSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 보온덮개 개폐기 수량
   */
  public get laggingSwitcherQuantity(): number {
    return this._laggingSwitcherQuantity;
  }

  public set laggingSwitcherQuantity(value: number) {
    this._laggingSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 비닐 커튼 개폐기 수량
   */
  public get vinylCurSwitcherQuantity(): number {
    return this._vinylCurSwitcherQuantity;
  }

  public set vinylCurSwitcherQuantity(value: number) {
    this._vinylCurSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 부직포 커튼 개폐기 수량
   */
  public get nonwovenCurSwitcherQuantity(): number {
    return this._nonwovenCurSwitcherQuantity;
  }

  public set nonwovenCurSwitcherQuantity(value: number) {
    this._nonwovenCurSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 보온덮개 커튼 개폐기 수량
   */
  public get laggingCurSwitcherQuantity(): number {
    return this._laggingCurSwitcherQuantity;
  }

  public set laggingCurSwitcherQuantity(value: number) {
    this._laggingCurSwitcherQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 단상 커튼 모터 수량
   */
  public get sigleCurMotorQuantity(): number {
    return this._sigleCurMotorQuantity;
  }

  public set sigleCurMotorQuantity(value: number) {
    this._sigleCurMotorQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  /**
   * 삼상 커튼 모터 수량
   */
  public get threeCurMotorQuantity(): number {
    return this._threeCurMotorQuantity;
  }

  public set threeCurMotorQuantity(value: number) {
    this._threeCurMotorQuantity = CommonUtil.fixFloat(value);

    // 알고리즘
    this.controllerPart.integratedControllerSample.algorithmSpec();
  }

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      // algorithmBasic();
      // 파트 알고리즘 개별 호출
      let part: Part;
      for (part of this.partAC) {
        part.algorithmBasic();
      }
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    this.controllerPart.positionType = value;

    // 알고리즘

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.controllerPart = new ControllerPartIL_Controller();

    this.partAC = [this.controllerPart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.controllerPart = <ControllerPartIL_Controller>this.partAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    if (this.level.index >= 0) {
      // 제어기
      this.controllerPart.setDefaultData(
        CONST.INDEX_IL_CONTROLLER_DEFAULT,
        true,
        false,
        true,
        CONST.LB_CONTROLLER_DEFAULT,
        "",
        0,
        CONST.LB_CONTROLLER_CONTROLL_TYPE_SEMI_AUTO,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  // @override
  public algorithmBasic(): void {
    this.algorithm_selectedBySwitcherByBasic();
    this.algorithm_usageSwitcherQuantity();
    this.algorithm_usageCurSwitcherAndMotorQuantity();

    super.algorithmBasic();
  }

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    /// //////// 선택, 가용성, 가시성 ///////////

    if (this.type === CONST.LB_CONTROLLER_TYPE_CUSTOM) {
      this.controllerPart.selected = true;
      this.controllerPart.visible = true;
    }
  }

  /**
   * 알고리즘: 선택 <- 중수(기본정보), 형태(개폐기), 선택(개폐기), 선택(천창 개폐), 형태(천창 개폐), 선택(커튼 개페)
   */
  public algorithm_selectedBySwitcherByBasic(): void {
    const basic: Basic = this.design.basic;
    let isManualAll: boolean = true;

    // 개폐기 작업
    for (const switcherLevel of this.struct.switcherWorkIL.levelAC as SwitcherLevelIL[]) {
      if (switcherLevel.selected === true) {
        for (const switcherPosition of switcherLevel.positionAC as SwitcherPositionIL[]) {
          if (switcherPosition.selected === true && switcherPosition.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
            isManualAll = false;
            break;
          }
        }
        if (isManualAll === false) {
          break;
        }
      }
    }
    // 천창 개폐 작업
    if (isManualAll === true) {
      const skySwitchPosition: SkySwitchPositionIL = this.struct.skySwitchWorkIL.level1.skylightPosition;
      if (skySwitchPosition.selected === true && skySwitchPosition.type !== CONST.LB_SWITCHER_TYPE_MANUAL) {
        isManualAll = false;
      }
    }
    // 커튼 개폐 작업
    if (isManualAll === true) {
      for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
        if (curSwitchLevel.selected === true) {
          for (const curSwitchPosition of curSwitchLevel.positionAC as CurSwitchPositionIL[]) {
            if (curSwitchPosition.selected === true) {
              isManualAll = false;
              break;
            }
          }
          if (isManualAll === false) {
            break;
          }
        }
      }
    }

    if (isManualAll === true) {
      // 모든 형태가 수동이면, 제어기 선택 해제
      this.selected = false;
    } else {
      // 모든 형태가 수동이 아니면, 제어기 선택 체크
      this.selected = true;
    }
  }

  /**
   * 알고리즘: 용도별 개폐기 수량 <- (기본정보), 선택(개폐기), 형태(개폐기), 개폐 방향(개폐기), 최대 차수(개폐기), 규격(동력 개폐기 샘플) <br/>
   *  <- 선택(천창 개폐), 형태(천창 개폐), 개폐 방향(천창 개폐), 규격(동력 개폐기 샘플)
   */
  public algorithm_usageSwitcherQuantity(): void {
    let vinylSwitcher: number = 0;
    let nonwovenSwitcher: number = 0;
    let laggingSwitcher: number = 0;
    let itemPowerSwitcher: ItemPowerSwitcher;
    let skylightMoter: ItemSkylightMotor;

    // 개폐기 작업
    for (const switcherLevel of this.struct.switcherWorkIL.levelAC as SwitcherLevelIL[]) {
      if (switcherLevel.selected === true) {
        for (const switcherPosition of switcherLevel.positionAC as SwitcherPositionIL[]) {
          if (switcherPosition.selected === true) {
            const power1Part: SwitcherPartIL_Power = switcherPosition.power1Part;
            if (power1Part.selected === true) {
              itemPowerSwitcher = power1Part.power_powerSwitcher;
              if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE) {
                nonwovenSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingSwitcher += itemPowerSwitcher.getSelectedQuantity();
              }
            }
            const power2Part: SwitcherPartIL_Power = switcherPosition.power2Part;
            if (power2Part.selected === true) {
              itemPowerSwitcher = power2Part.power_powerSwitcher;
              if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE) {
                nonwovenSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingSwitcher += itemPowerSwitcher.getSelectedQuantity();
              }
            }
          }
        }
      }
    }

    // 천창 개폐 작업
    for (const skySwitchLevel of this.struct.skySwitchWorkIL.levelAC as SkySwitchLevelIL[]) {
      if (skySwitchLevel.selected === true) {
        for (const skySwitchPosition of skySwitchLevel.positionAC as SkySwitchPositionIL[]) {
          if (skySwitchPosition.selected === true) {
            const powerPart: SkySwitchPartIL_Power = skySwitchPosition.powerPart;
            if (powerPart.selected === true) {
              itemPowerSwitcher = powerPart.power_powerSwitcher;
              if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE) {
                nonwovenSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingSwitcher += itemPowerSwitcher.getSelectedQuantity();
              }
            }
            const rackpinionPart: SkySwitchPartIL_Rackpinion = skySwitchPosition.rackpinionPart;
            if (rackpinionPart.selected === true) {
              skylightMoter = rackpinionPart.rackpinion_skylightMotor;
              if (skylightMoter.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylSwitcher += skylightMoter.getSelectedQuantity();
              } else if (
                skylightMoter.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE ||
                skylightMoter.specUsage === CONST.ITEM_SPEC_VALUE_SKYLIGHT_USAGE
              ) {
                nonwovenSwitcher += skylightMoter.getSelectedQuantity();
              } else if (skylightMoter.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingSwitcher += skylightMoter.getSelectedQuantity();
              }
            }
          }
        }
      }
    }

    this.vinylSwitcherQuantity = vinylSwitcher;
    this.nonwovenSwitcherQuantity = nonwovenSwitcher;
    this.laggingSwitcherQuantity = laggingSwitcher;
  }

  /**
   * 알고리즘: 용도별 커튼 개폐기 및 모터 수량 <- (기본정보), 선택(커튼 개폐), 형태(커튼 개폐), 개폐 방향(커튼 개폐), 개폐 분할(커튼 개폐), 규격(동력 개폐기 샘플), 규격(감속 모터 샘플)
   */
  public algorithm_usageCurSwitcherAndMotorQuantity(): void {
    let vinylCurSwitcher: number = 0;
    let nonwovenCurSwitcher: number = 0;
    let laggingCurSwitcher: number = 0;
    let sigleCurMotor: number = 0;
    let threeCurMotor: number = 0;
    let itemPowerSwitcher: ItemPowerSwitcher;
    let itemReducedMotor: ItemReducedMotor;

    for (const curSwitchLevel of this.struct.curSwitchWorkIL.levelAC as CurSwitchLevelIL[]) {
      if (curSwitchLevel.selected === true) {
        for (const curSwitchPosition of curSwitchLevel.positionAC as CurSwitchPositionIL[]) {
          if (curSwitchPosition.selected === true) {
            const powerPart: CurSwitchPartIL_Power = curSwitchPosition.powerPart;
            if (powerPart.selected === true) {
              itemPowerSwitcher = powerPart.power_powerSwitcher;
              if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_VINYL_USAGE) {
                vinylCurSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_NONWOVEN_USAGE) {
                nonwovenCurSwitcher += itemPowerSwitcher.getSelectedQuantity();
              } else if (itemPowerSwitcher.specUsage === CONST.ITEM_SPEC_VALUE_LAGGING_USAGE) {
                laggingCurSwitcher += itemPowerSwitcher.getSelectedQuantity();
              }
            }
            const motorPart: CurSwitchPartIL_Motor = curSwitchPosition.motorPart;
            if (motorPart.selected === true) {
              itemReducedMotor = motorPart.motor_reducedMotor;
              if (itemReducedMotor.specTransformType === CONST.ITEM_SPEC_VALUE_SINGLE_PHASE) {
                sigleCurMotor += itemReducedMotor.getSelectedQuantity();
              } else if (itemReducedMotor.specTransformType === CONST.ITEM_SPEC_VALUE_THREE_PHASE) {
                threeCurMotor += itemReducedMotor.getSelectedQuantity();
              }
            }
          }
        }
      }
    }

    this.vinylCurSwitcherQuantity = vinylCurSwitcher;
    this.nonwovenCurSwitcherQuantity = nonwovenCurSwitcher;
    this.laggingCurSwitcherQuantity = laggingCurSwitcher;
    this.sigleCurMotorQuantity = sigleCurMotor;
    this.threeCurMotorQuantity = threeCurMotor;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
