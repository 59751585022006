import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { GutterSample_GutterPad } from "vhows-design/src/object/design/frame/gutter/GutterSample_GutterPad";
import { GutterPosition } from "vhows-design/src/object/design/frame/gutter/GutterPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-28
 */
@jsonObject({
  knownTypes: [GutterSample_GutterPad],
})
export class GutterPart_FixingGutterPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public gutterPadSample: GutterSample_GutterPad; // 물받이 패드 샘플

  // 아이템
  public gutterPad_gutterPad: ItemPad;
  public gutterPad_padConnectionPin: ItemPadConnector;
  public gutterPad_normalPadSpring: ItemPadSpring;
  public gutterPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.gutterPadSample = new GutterSample_GutterPad();

    this.sampleAC = [this.gutterPadSample];

    // 아이템
    this.gutterPad_gutterPad = new ItemPad();
    this.gutterPad_padConnectionPin = new ItemPadConnector();
    this.gutterPad_normalPadSpring = new ItemPadSpring();
    this.gutterPad_screw = new ItemScrew();

    this.itemAC = [
      this.gutterPad_gutterPad,
      this.gutterPad_padConnectionPin,
      this.gutterPad_normalPadSpring,
      this.gutterPad_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.gutterPadSample = <GutterSample_GutterPad>this.sampleAC[0];

    // 아이템
    this.gutterPad_gutterPad = <ItemPad>this.itemAC[0];
    this.gutterPad_padConnectionPin = <ItemPadConnector>this.itemAC[1];
    this.gutterPad_normalPadSpring = <ItemPadSpring>this.itemAC[2];
    this.gutterPad_screw = <ItemScrew>this.itemAC[3];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index === 0) {
      this.gutterPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_GUTTER_PAD,
        CONST.ITEM_NAME_GUTTER_PAD,
        "물받이 패드",
        "칼라 철, 0.7T, 6m",
      );
    } else {
      this.gutterPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_GUTTER_PAD,
        CONST.ITEM_NAME_GUTTER_PAD,
        "물받이 패드",
        "칼라 철, 0.7T, 6m",
      );
    }

    // 아이템
    if (this.level.index === 0) {
      this.gutterPad_gutterPad.setDefaultData(
        0,
        CONST.ITEM_ID_GUTTER_PAD,
        CONST.ITEM_NAME_GUTTER_PAD,
        "물받이 패드",
        "칼라 철, 0.7T, 6m",
      );
      this.gutterPad_padConnectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "물받이 패드",
        "칼라 철",
      );
      this.gutterPad_normalPadSpring.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "물받이 패드",
        "일반, 2m",
      );
      this.gutterPad_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "물받이 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    } else {
      this.gutterPad_gutterPad.setDefaultData(
        0,
        CONST.ITEM_ID_GUTTER_PAD,
        CONST.ITEM_NAME_GUTTER_PAD,
        "물받이 패드",
        "칼라 철, 0.7T, 6m",
      );
      this.gutterPad_padConnectionPin.setDefaultData(
        1,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "물받이 패드",
        "칼라 철",
      );
      this.gutterPad_normalPadSpring.setDefaultData(
        2,
        CONST.ITEM_ID_NORMAL_PAD_SPRING,
        CONST.ITEM_NAME_NORMAL_PAD_SPRING,
        "물받이 패드",
        "일반, 2m",
      );
      this.gutterPad_screw.setDefaultData(
        3,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "물받이 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    // 하드코딩
    if (
      (<GutterPosition>this.position).ironPlatePart.ironPlateGutterSample.specFeature ===
      CONST.ITEM_SPEC_VALUE_PAD_WORKED
    ) {
      this.gutterPad_gutterPad.selected = false;
      this.gutterPad_gutterPad.visible = true;
      this.gutterPad_padConnectionPin.selected = false;
      this.gutterPad_padConnectionPin.visible = true;
    } else {
      this.gutterPad_gutterPad.selected = true;
      this.gutterPad_gutterPad.visible = true;
      this.gutterPad_padConnectionPin.selected = true;
      this.gutterPad_padConnectionPin.visible = true;
    }
    this.gutterPad_normalPadSpring.selected = true;
    this.gutterPad_normalPadSpring.visible = true;
    this.gutterPad_screw.selected = true;
    this.gutterPad_screw.visible = true;

    /// //////// 수량 ///////////

    if (this.level.index === 0) {
      this.gutterPad_gutterPad.designQuantity =
        ((this.basicLevel.length + 1) * 2 * (this.basic.buildingNumber - 1)) / this.gutterPad_gutterPad.specLength;
    } else {
      this.gutterPad_gutterPad.designQuantity =
        ((this.basicLevel.length + 1) * 4 * this.basic.buildingNumber) / this.gutterPad_gutterPad.specLength;
    }

    this.gutterPad_padConnectionPin.designQuantity = this.gutterPad_gutterPad.designQuantity;

    this.gutterPad_normalPadSpring.designQuantity =
      this.gutterPad_gutterPad.designQuantity *
      (this.gutterPad_gutterPad.specLength / this.gutterPad_normalPadSpring.specLength) *
      CONST.NUM_EXTRA_RATE_PAD_SPRING;

    this.gutterPad_screw.designQuantity =
      ((this.gutterPad_gutterPad.getSelectedQuantity() * 6 +
        this.gutterPad_padConnectionPin.getSelectedQuantity() * 2) /
        this.gutterPad_screw.specUnitQuantity) *
      CONST.NUM_EXTRA_RATE_SCREW;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [물받이 패드] 물받이 패드 <- 물받이 패드 샘플
   */
  public algorithmSpec_gutterPad_gutterPad(): void {
    this.gutterPad_gutterPad.specs = this.gutterPadSample.specs;
  }

  /**
   * 규격 알고리즘: [물받이 패드] 패드 연결핀 <- 물받이 패드 샘플
   */
  public algorithmSpec_gutterPad_padConnectionPin(): void {
    this.gutterPad_padConnectionPin.specMaterial = this.gutterPadSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [물받이 패드] 일반 패드 스프링 <- 고정
   */
  public algorithmSpec_gutterPad_normalPadSpring(): void {}

  /**
   * 규격 알고리즘: [물받이 패드] 나사 <- 공통 샘플
   */
  public algorithmSpec_gutterPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
