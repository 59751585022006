import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Design } from "vhows-design/src/object/design/Design";
import { Struct } from "vhows-design/src/object/design/base/Struct";
import { Work } from "vhows-design/src/object/design/base/Work";
import { CurSwitchLevelIL } from "vhows-design/src/object/design/curtain/switches/CurSwitchLevelIL";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-05-08
 */
@jsonObject({
  knownTypes: [CurSwitchLevelIL],
})
export class CurSwitchWorkIL extends Work {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public level1: CurSwitchLevelIL;
  // public level2: CurSwitchLevelIL;
  // public level3: CurSwitchLevelIL;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.level1 = new CurSwitchLevelIL();
    // this.level2 = new CurSwitchLevelIL();
    // this.level3 = new CurSwitchLevelIL();

    this.levelAC = [
      this.level1,
      // this.level2,
      // this.level3,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.level1 = <CurSwitchLevelIL>this.levelAC[0];
    // this.level2 = <CurSwitchLevelIL> this.levelAC[1];
    // this.level3 = <CurSwitchLevelIL> this.levelAC[2];

    super.setReferenceVariable();
  }

  // @override
  public setDefaultData(
    index: number = 0,
    depth: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    category: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, depth, selected, enabled, visible, label, category, buildNote);

    this.level1.setDefaultData(0, false, true, true, CONST.LB_LEVEL_WHOLE);
    // this.level2.setDefaultData(
    //   1, false, true, true, CONST.LB_LEVEL_2,
    // );
    // this.level3.setDefaultData(
    //   2, false, true, true, CONST.LB_LEVEL_3,
    // );
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  // @override
  public restoreLatestObject(design: Design, struct: Struct): void {
    if (CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion)) {
      if (this.levelAC.length === 2) {
        // 3중
        const level3: CurSwitchLevelIL = new CurSwitchLevelIL();
        this.levelAC.push(level3);
        level3.setAssociation(design, struct, this);
        level3.setDefaultData(2, false, true, true, CONST.LB_LEVEL_3);
      }
    }

    if (CommonUtil.compareVersion(CONST.APP_VERSION, design.dbVersion) && this.selected) {
      this.levelAC.splice(1);
    }

    super.restoreLatestObject(design, struct);
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
