import { jsonObject } from "typedjson";

import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { SkySwitchPart_SupDiagonal } from "vhows-design/src/object/design/skylight/switches/SkySwitchPart_SupDiagonal";
import { SkySwitchPosition } from "vhows-design/src/object/design/skylight/switches/SkySwitchPosition";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-11-18
 */
@jsonObject
export class SkySwitchSample_DiagonalSupPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    // 외부 (소스코드에서 불러온 경우)
    this.algorithmSpec();

    super.algorithmBasic();
  }

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const supDiagonalPart: SkySwitchPart_SupDiagonal = <SkySwitchPart_SupDiagonal>this.part;
    supDiagonalPart.algorithmSpec_diagonalSupport_pipe();

    // 파트 알고리즘 호출
    this.part.algorithmPart();

    // 다른 파트
    (<SkySwitchPosition>this.position).powerPart.powerSwitcherSample.algorithmSpec();
  }

  //----------------------------------
  // 샘플 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 대각형 지지 파이프 샘플(개폐기)
   */
  // @override
  public algorithmSpec(): void {
    this.specs = this.struct.switcherWork.level1.sidePosition.supportPart.diagonalSupportPipeSample.specs;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
