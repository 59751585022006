import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPipe } from "vhows-design/src/object/design/item/list/ItemPipe";
import { ColumnPositionIL } from "vhows-design/src/object/design/frame/column/ColumnPositionIL";
import { ColumnPartIL_CylinderColumn } from "vhows-design/src/object/design/frame/column/ColumnPartIL_CylinderColumn";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-15
 */
@jsonObject
export class ColumnSampleIL_CylinderColumnBracingPipe extends ItemPipe {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmSample(): void {
    super.algorithmSample();

    /// //////// 내부 ///////////

    const cylinderColumnPart: ColumnPartIL_CylinderColumn = <ColumnPartIL_CylinderColumn>this.part;
    cylinderColumnPart.algorithmSpec_columnBracing_pipe();
    cylinderColumnPart.algorithmSpec_columnBracingAndColumn_rotationAsibaClamp();

    // 파트 알고리즘 호출
    this.part.algorithmPart();
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘 <- 기둥 파이프 샘플, 보 파이프 샘플
   */
  // @override
  public algorithmSpec(): void {
    if ((<ColumnPartIL_CylinderColumn>this.part).columnPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_48_MM) {
      this.specsList = (<ColumnPartIL_CylinderColumn>this.part).columnPipeSample.specsList;
    } else if (
      (<ColumnPartIL_CylinderColumn>this.part).columnPipeSample.specCrossSize === CONST.ITEM_SPEC_VALUE_60_MM
    ) {
      this.specsList = (<ColumnPositionIL>this.position).cylinderBeamPart.beamPipeSample.specsList;
    }
  }

  /**
   * 규격 알고리즘 <- 보 파이프 샘플
   */
  public algorithmSpec_specLength(): void {
    this.specLength = (<ColumnPositionIL>this.position).cylinderBeamPart.beamPipeSample.specLength;
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
