import { jsonMember, jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemVinyl } from "vhows-design/src/object/design/item/list/ItemVinyl";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { SkirtPositionIL } from "vhows-design/src/object/design/cover/skirt/il/SkirtPositionIL";
import { SkirtSampleIL_StringVinyl } from "vhows-design/src/object/design/cover/skirt/il/SkirtSampleIL_StringVinyl";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-04-04
 */
@jsonObject({
  knownTypes: [SkirtSampleIL_StringVinyl],
})
export class SkirtPartIL_String extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public stringVinylSample: SkirtSampleIL_StringVinyl; // 끈 비닐 샘플

  // 아이템
  // 동적 생성

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 치마 길이 AC */
  public skirtLengthAC: number[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _skirtWidth: number; // 치마 폭
  @jsonMember(Number)
  public _skirtLength: number; // 치마 길이

  /**
   * 치마 폭
   */
  public get skirtWidth(): number {
    return this._skirtWidth;
  }

  //
  public set skirtWidth(value: number) {
    this._skirtWidth = CommonUtil.fixFloat(value);
  }

  /**
   * 치마 길이
   */
  public get skirtLength(): number {
    return this._skirtLength;
  }

  //
  public set skirtLength(value: number) {
    this._skirtLength = CommonUtil.fixFloat(value);
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.stringVinylSample = new SkirtSampleIL_StringVinyl();

    this.sampleAC = [this.stringVinylSample];

    // 아이템
    // 동적 생성
    this.itemAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.stringVinylSample = <SkirtSampleIL_StringVinyl>this.sampleAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.stringVinylSample.setDefaultData(
        0,
        CONST.ITEM_ID_VINYL,
        CONST.ITEM_NAME_VINYL,
        "끈 치마",
        "0.1mm, 120cm, 200m, 일타, 박스, 한끈",
        "기본회사, 장수, 0.94",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      // 동적 생성
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    // 동적 생성 : 선택된 경우에만 동작
    if (this.selected === false) {
      return;
    }

    // 동적 생성 : 품목AC 초기화
    this.itemAC = new Array();

    /// //////// 선언 ///////////

    const skirtPosition: SkirtPositionIL = <SkirtPositionIL>this.position;
    // 외부
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    );
    // 기타
    let item: Item;
    let basicBuilding: BasicBuilding;
    let i: number;

    /// //////// 수치 ///////////

    if (skirtPosition.label === CONST.LB_POSITION_SIDE_COLUMN || skirtPosition.label === CONST.LB_POSITION_SIDE_WING) {
      this.skirtWidth = skirtPosition.skirtHeight + 0.2;
      this.skirtLength = this.basicLevel.length;

      if (this.basic.isVariableLength === true) {
        this.skirtLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.skirtLengthAC.push(basicBuilding.length);
        }
      }
    } else if (skirtPosition.label === CONST.LB_POSITION_ROOF) {
      // 2동이상
      if (this.basic.buildingNumber >= 2) {
        this.skirtWidth = skirtPosition.skirtHeight;
      } else {
        if (this.level.index === 0) {
          // 1동&1중만 어깨패드 거리에 영향
          this.skirtWidth = skirtPosition.skirtHeight + this.basicLevel.distanceShoulderPad;
        } else {
          this.skirtWidth = skirtPosition.skirtHeight;
        }
      }
      this.skirtLength = this.basicLevel.length;
      if (this.basic.isVariableLength === true) {
        this.skirtLengthAC = new Array();
        for (basicBuilding of this.basicLevel.basicBuildingAC) {
          this.skirtLengthAC.push(basicBuilding.length);
        }
      }
    } else if (skirtPosition.label === CONST.LB_POSITION_FRONT) {
      this.skirtWidth = skirtPosition.skirtHeight + 0.2;
      this.skirtLength = this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber;
    } else if (skirtPosition.label === CONST.LB_POSITION_BACK) {
      this.skirtWidth = skirtPosition.skirtHeight + 0.2;
      this.skirtLength = this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber;
    }

    /// //////// 생성, 선택, 가시성, 규격, 수량 ///////////

    // 기둥(측면)
    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN) {
      // 2동이상 && 가변길이인 경우
      if (this.basic.buildingNumber >= 2 && this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < this.basic.buildingNumber; i++) {
          // 양끝2동
          if (i === 0 || i === this.basic.buildingNumber - 1) {
            item = this.getNewSkirtItem(true, i);
            item.purpose = "끈 치마";
            item.designQuantity = 1;

            this.itemAC.push(item);
          }
        }
      } else {
        // 개폐 방향값만큼 수량 입력
        item = this.getNewSkirtItem();
        item.purpose = "끈 치마";
        item.designQuantity = switcherPosition.switchWayValue;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_SIDE_WING) {
      // 2동이상 && 가변길이인 경우
      if (this.basic.buildingNumber >= 2 && this.basic.isVariableLength === true) {
        // 동수만큼 품목 생성
        for (i = 0; i < this.basic.buildingNumber; i++) {
          // 양끝2동
          if (i === 0 || i === this.basic.buildingNumber - 1) {
            item = this.getNewSkirtItem(true, i);
            item.purpose = "끈 치마";
            item.designQuantity = 1;

            this.itemAC.push(item);
          }
        }
      } else {
        // 개폐 방향값만큼 수량 입력
        item = this.getNewSkirtItem();
        item.purpose = "끈 치마";
        item.designQuantity = switcherPosition.switchWayValue;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_ROOF) {
      // 2동이상
      if (this.basic.buildingNumber >= 2) {
        // 가변길이인 경우
        if (this.basic.isVariableLength === true) {
          // 동수만큼 품목 생성
          for (i = 0; i < this.basic.buildingNumber; i++) {
            // 양끝2동
            if (i === 0 || i === this.basic.buildingNumber - 1) {
              item = this.getNewSkirtItem(true, i);
              item.purpose = `${i + 1}번동 끈 치마`;
              if (this.level.index === 0) {
                item.designQuantity = 1;
              } else {
                item.designQuantity = switcherPosition.switchWayValue;
              }

              this.itemAC.push(item);
            } else {
              item = this.getNewSkirtItem(true, i);
              item.purpose = `${i + 1}번동 끈 치마`;
              item.designQuantity = switcherPosition.switchWayValue;

              this.itemAC.push(item);
            }
          }
        } else {
          // 동수만큼 수량 입력
          item = this.getNewSkirtItem();
          item.purpose = "끈 치마";
          if (this.level.index === 0) {
            item.designQuantity = 2 + switcherPosition.switchWayValue * (this.basic.buildingNumber - 2);
          } else {
            item.designQuantity = switcherPosition.switchWayValue * this.basic.buildingNumber;
          }

          this.itemAC.push(item);
        }
      } else {
        item = this.getNewSkirtItem();
        item.purpose = "끈 치마";
        item.designQuantity = switcherPosition.switchWayValue;

        this.itemAC.push(item);
      }
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      item = this.getNewSkirtItem();
      item.purpose = "끈 치마";
      item.designQuantity = 1;

      this.itemAC.push(item);
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      item = this.getNewSkirtItem();
      item.purpose = "끈 치마";
      item.designQuantity = 1;

      this.itemAC.push(item);
    }

    // 동적 생성 : 인덱스 재설정
    CommonUtil.reindexAC(this.itemAC);
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [] 아이템 <- 샘플
   */
  public algorithmSpec_item(): void {
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemVinyl) {
        (<ItemVinyl>item).specsList = this.stringVinylSample.specsList;
        (<ItemVinyl>item).specWidthType = this.stringVinylSample.specWidthType;
        (<ItemVinyl>item).specPackingType = this.stringVinylSample.specPackingType;
        (<ItemVinyl>item).specStringType = this.stringVinylSample.specStringType;
        (<ItemVinyl>item).brands = this.stringVinylSample.brands;
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 새로운 치마 피복 아이템 만들기
   * @param isVariableLength 가변길이 여부
   * @param buildingIndex 동수 인덱스
   * @return 치마 피복 아이템
   */
  private getNewSkirtItem(isVariableLength: boolean = false, buildingIndex: number = 0): ItemVinyl {
    let item: ItemVinyl;
    item = ItemVinyl.getNewDesignItemBySample(this, this.stringVinylSample);

    (<ItemVinyl>item).specWidth = (<ItemVinyl>item).getFoldedWidth(CommonUtil.convertM2CM(this.skirtWidth));
    if (isVariableLength === true) {
      (<ItemVinyl>item).specLength = this.skirtLengthAC[buildingIndex];
    } else {
      (<ItemVinyl>item).specLength = this.skirtLength;
    }
    return item;
  }
}
