import { jsonObject, jsonMember, jsonArrayMember } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { Item } from "vhows-design/src/object/design/item/Item";
import { CategoryCompanyModel } from "vhows-design/src/object/item/category/CategoryCompanyModel";

/**
 * 임의 인건비
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-08-09
 */
@jsonObject
export class ItemCustomLaborCost extends Item {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 규격
  //----------------------------------

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public getCategoryModel(): CategoryCompanyModel {
    return this.itemModel.customLaborCostCategoryModel;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //----------------------------------
  // 하우스 견적
  //----------------------------------

  // @override
  public getSellingSupply(type: number = CONST.ID_ITEM_SELLING_TYPE_UNIT, otherCost: number = null): number {
    // 기본 단가
    if (this.unitPriceModel == null) {
      return 0;
    }
    let defaultUnitSupply: number = this.customUnitSupply; // 임의 단가
    if (type === CONST.ID_ITEM_SELLING_TYPE_PURCHASE) {
      defaultUnitSupply = this.customPurchaseSupply; // 임의 매입가
    }

    // 매출가 계산식
    let sellingSupply: number = defaultUnitSupply;

    // 매출가 끝수 처리
    sellingSupply = CommonUtil.roundWhat(
      sellingSupply,
      this.productCompanyModel.sellingPriceFractionPosition,
      this.productCompanyModel.sellingPriceFractionType,
    );

    return CommonUtil.fixFloat(sellingSupply);
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계 > 상표
  //----------------------------------

  // @override
  protected makeEachBrandByBrands(): void {
    // 사용 안함
  }

  // @override
  protected makeEachBrandByBrandsList(): void {
    // 사용 안함
  }

  // @override
  protected makeBrandsList(): void {
    // 상표 리스트는 항상 빈값
    this.brandsList = "";
  }

  // @override
  protected makeBrands(): void {
    this.brands = this._brandsList;
  }
}
