import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { Position } from "vhows-design/src/object/design/base/Position";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { FoundationPartIL_ColumnConcrete } from "vhows-design/src/object/design/foundation/il/FoundationPartIL_ColumnConcrete";
import { FoundationPartIL_GirthPipe } from "vhows-design/src/object/design/foundation/il/FoundationPartIL_GirthPipe";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-12-22
 */
@jsonObject({
  knownTypes: [FoundationPartIL_ColumnConcrete, FoundationPartIL_GirthPipe],
})
export class FoundationPositionIL extends Position {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  public girthPipePart: FoundationPartIL_GirthPipe;
  public columnConcretePart: FoundationPartIL_ColumnConcrete;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //----------------------------------
  //  재정의
  //----------------------------------

  /**
   * 선택
   */
  // @override
  public get selected(): boolean {
    return this._selected;
  }

  //
  public set selected(value: boolean) {
    if (this._selected === value) return;

    this._selected = value;

    // 알고리즘
    this.level.algorithm_selectedByPosition();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 선택된 경우, 기본 알고리즘 및 파트 알고리즘 호출
    if (this._selected === true) {
      this.algorithmBasic();
    }

    /// //////// 외부 ///////////

    // 마구리 골조
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) {
        endpieceLevel.frontPosition.cylinderStudPart.algorithmPart();
        endpieceLevel.frontPosition.cylinderStudSepPart.algorithmPart();
        endpieceLevel.frontPosition.prismStudSepPart.algorithmPart();
        endpieceLevel.backPosition.cylinderStudPart.algorithmPart();
        endpieceLevel.backPosition.cylinderStudSepPart.algorithmPart();
        endpieceLevel.backPosition.prismStudSepPart.algorithmPart();
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      for (const endpieceLevel of this.struct.endpieceWorkVL.levelAC as EndpieceLevelVL[]) {
        endpieceLevel.frontPosition.endpiecePart.algorithmPart();
        endpieceLevel.backPosition.endpiecePart.algorithmPart();
      }
    }
  }

  /**
   * 형태
   */
  // @override
  public get type(): string {
    return this._type;
  }

  //
  public set type(value: string) {
    if (this._type === value) return;

    this._type = value;

    // 알고리즘
    this.girthPipePart.algorithm_centerLengthGirtSelected();

    // 파트 활성화
    this.algorithm_partActivationByType();

    // 파트 알고리즘 호출
    this.callPartAlgorithmBasic();

    /// //////// 외부 ///////////

    // 마구리 골조
    if (
      this.design.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.design.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      for (const endpieceLevel of this.struct.endpieceWorkIL.levelAC as EndpieceLevelIL[]) {
        endpieceLevel.frontPosition.cylinderStudPart.algorithmPart();
        endpieceLevel.frontPosition.cylinderStudSepPart.algorithmPart();
        endpieceLevel.frontPosition.prismStudSepPart.algorithmPart();
        endpieceLevel.backPosition.cylinderStudPart.algorithmPart();
        endpieceLevel.backPosition.cylinderStudSepPart.algorithmPart();
        endpieceLevel.backPosition.prismStudSepPart.algorithmPart();
      }
    } else if (this.design.basic.structureName === CONST.LB_STRUCT_VENLO) {
      for (const endpieceLevel of this.struct.endpieceWorkVL.levelAC as EndpieceLevelVL[]) {
        endpieceLevel.frontPosition.endpiecePart.algorithmPart();
        endpieceLevel.backPosition.endpiecePart.algorithmPart();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    this.girthPipePart = new FoundationPartIL_GirthPipe();
    this.columnConcretePart = new FoundationPartIL_ColumnConcrete();

    this.partAC = [this.girthPipePart, this.columnConcretePart];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    this.girthPipePart = <FoundationPartIL_GirthPipe>this.partAC[0];
    this.columnConcretePart = <FoundationPartIL_ColumnConcrete>this.partAC[1];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param type: string 형태
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    type: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, type);

    if (this.level.index >= 0) {
      // 바닥도리 파이프
      this.girthPipePart.setDefaultData(
        CONST.INDEX_IL_FOUNDATION_GIRTH_PIPE,
        true,
        false,
        true,
        CONST.LB_FOUNDATION_GIRTH_PIPE,
        "",
        true,
        true,
      );

      // 기둥 콘크리트
      this.columnConcretePart.setDefaultData(
        CONST.INDEX_IL_FOUNDATION_COLUMN_CONCRETE,
        false,
        false,
        false,
        CONST.LB_FOUNDATION_COLUMN_CONCRETE,
        "",
        CONST.LB_CONCRETE_MOLD_DIAMETER_250,
        0.3,
        true,
        0.2,
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 알고리즘: 파트 활성화 <- 형태
   */
  // @override
  public algorithm_partActivationByType(): void {
    if (this.type === CONST.LB_FOUNDATION_TYPE_GIRTH_PIPE) {
      this.girthPipePart.selected = true;
      this.girthPipePart.visible = true;
      this.columnConcretePart.selected = false;
      this.columnConcretePart.visible = false;
    } else if (this.type === CONST.LB_FOUNDATION_TYPE_COLUMN_CONCRETE) {
      this.girthPipePart.selected = false;
      this.girthPipePart.visible = false;
      this.columnConcretePart.selected = true;
      this.columnConcretePart.visible = true;
    } else if (this.type === CONST.LB_FOUNDATION_TYPE_GIRTH_AND_COLUMN_CONCRETE) {
      this.girthPipePart.selected = true;
      this.girthPipePart.visible = true;
      this.columnConcretePart.selected = true;
      this.columnConcretePart.visible = true;
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
