import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemOtherLaborCost } from "vhows-design/src/object/design/item/list/ItemOtherLaborCost";
import { ItemIronPlateGutter } from "vhows-design/src/object/design/item/list/ItemIronPlateGutter";
import { Part } from "vhows-design/src/object/design/base/Part";
import { Item } from "vhows-design/src/object/design/item/Item";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2017-08-10
 */
@jsonObject({
  knownTypes: [],
})
export class BuildingPart_Custom extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 아이템
  public buildingCostPerConstruction: ItemOtherLaborCost;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플

  // 아이템

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    this.buildingCostPerConstruction = new ItemOtherLaborCost();
    // 아이템
    this.itemAC = [this.buildingCostPerConstruction];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 아이템
    let item: Item;
    for (item of this.itemAC) {
      if (item instanceof ItemOtherLaborCost) {
        this.buildingCostPerConstruction = <ItemOtherLaborCost>item;
      }
    }
    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
    }

    // 아이템
    if (this.level.index >= 0) {
      this.buildingCostPerConstruction.setDefaultData(0, CONST.ITEM_ID_CUSTOM_LABOR_COST, "시공 인건비", "시공비", "");
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 하우스 설계
  //----------------------------------
}
