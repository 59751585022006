import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";

import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemHeatingFan } from "vhows-design/src/object/design/item/list/ItemHeatingFan";
import { Part } from "vhows-design/src/object/design/base/Part";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2020-02-21
 */
@jsonObject({
  knownTypes: [],
})
export class TemHeatingPart_HeatingFan extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플

  // 아이템
  public tem_heatingFan: ItemHeatingFan;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.sampleAC = [];

    // 아이템
    this.tem_heatingFan = new ItemHeatingFan();

    this.itemAC = [this.tem_heatingFan];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    // 아이템
    this.tem_heatingFan = <ItemHeatingFan>this.itemAC[0];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플

    // 아이템
    if (this.level.index >= 0) {
      this.tem_heatingFan.setDefaultData(
        0,
        CONST.ITEM_ID_HEATING_FAN,
        CONST.ITEM_NAME_HEATING_FAN,
        "열풍기",
        "일반",
        "삼일엔지니어링, SIF-110, 등유, 11000kcal/h, 1.1l/h, 15~25평",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmBasic(): void {
    super.algorithmBasic();
  }

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    /// //////// 선택, 가시성 ///////////

    // this.tem_heatingFan.selected = true;
    // this.tem_heatingFan.visible = true;

    /// ////////  수량 ///////////

    // this.tem_heatingFan.designQuantity = 0;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [열풍기] 열풍기 <- 임의
   */
  public algorithmSpec_tem_heatingFan(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
