import { jsonObject } from "typedjson";

import { CONST } from "vhows-design/src/common/constant/CONST";
import { ItemPad } from "vhows-design/src/object/design/item/list/ItemPad";
import { ItemPadConnector } from "vhows-design/src/object/design/item/list/ItemPadConnector";
import { ItemPadSpring } from "vhows-design/src/object/design/item/list/ItemPadSpring";
import { ItemPadVinyl } from "vhows-design/src/object/design/item/list/ItemPadVinyl";
import { ItemScrew } from "vhows-design/src/object/design/item/list/ItemScrew";
import { Part } from "vhows-design/src/object/design/base/Part";
import { EndpieceLevelIL } from "vhows-design/src/object/design/frame/endpiece/il/EndpieceLevelIL";
import { SwitcherPositionIL } from "vhows-design/src/object/design/switches/switcher/il/SwitcherPositionIL";
import { ScreennetSampleIL_LaggingPad } from "vhows-design/src/object/design/cover/screennet/il/ScreennetSampleIL_LaggingPad";
import { ScreennetPositionIL } from "vhows-design/src/object/design/cover/screennet/il/ScreennetPositionIL";
import { EndpieceLevelVL } from "vhows-design/src/object/design/frame/endpiece/vl/EndpieceLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2016-01-04
 */
@jsonObject({
  knownTypes: [ScreennetSampleIL_LaggingPad],
})
export class ScreennetPartIL_FixingLaggingPad extends Part {
  //--------------------------------------------------------------------------
  //
  // Variables
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  // 샘플
  public laggingPadSample: ScreennetSampleIL_LaggingPad; // 광폭 패드 샘플

  // 아이템
  public laggingPad_laggingPad: ItemPad;
  public laggingPad_laggingPadSpring: ItemPadSpring;
  public laggingPad_padConnectionPin: ItemPadConnector;
  public laggingPad_padVinyl: ItemPadVinyl;
  public laggingPad_screw: ItemScrew;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  //--------------------------------------------------------------------------
  //
  // Remote Variables
  //
  //--------------------------------------------------------------------------

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();

    // 샘플
    this.laggingPadSample = new ScreennetSampleIL_LaggingPad();

    this.sampleAC = [this.laggingPadSample];

    // 아이템
    this.laggingPad_laggingPad = new ItemPad();
    this.laggingPad_laggingPadSpring = new ItemPadSpring();
    this.laggingPad_padConnectionPin = new ItemPadConnector();
    this.laggingPad_padVinyl = new ItemPadVinyl();
    this.laggingPad_screw = new ItemScrew();

    this.itemAC = [
      this.laggingPad_laggingPad,
      this.laggingPad_laggingPadSpring,
      this.laggingPad_padConnectionPin,
      this.laggingPad_padVinyl,
      this.laggingPad_screw,
    ];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  // @override
  public setReferenceVariable(): void {
    // 샘플
    this.laggingPadSample = <ScreennetSampleIL_LaggingPad>this.sampleAC[0];

    // 아이템
    this.laggingPad_laggingPad = <ItemPad>this.itemAC[0];
    this.laggingPad_laggingPadSpring = <ItemPadSpring>this.itemAC[1];
    this.laggingPad_padConnectionPin = <ItemPadConnector>this.itemAC[2];
    this.laggingPad_padVinyl = <ItemPadVinyl>this.itemAC[3];
    this.laggingPad_screw = <ItemScrew>this.itemAC[4];

    super.setReferenceVariable();
  }

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index: number 파트인덱스
   * @param selected: boolean 선택 여부
   * @param enabled: boolean 가용성
   * @param visible: boolean 가시성
   * @param label: string 명칭
   * @param buildNote: string 시공 방법
   */
  // @override
  public setDefaultData(
    index: number = 0,
    selected: boolean = false,
    enabled: boolean = false,
    visible: boolean = false,
    label: string = "",
    buildNote: string = "",
  ): void {
    super.setDefaultData(index, selected, enabled, visible, label, buildNote);

    // 샘플
    if (this.level.index >= 0) {
      this.laggingPadSample.setDefaultData(
        0,
        CONST.ITEM_ID_LAGGING_PAD,
        CONST.ITEM_NAME_LAGGING_PAD,
        "광폭 패드",
        "칼라 철, 0.7T, 6m",
      );
    }

    // 아이템
    if (this.level.index >= 0) {
      this.laggingPad_laggingPad.setDefaultData(
        0,
        CONST.ITEM_ID_LAGGING_PAD,
        CONST.ITEM_NAME_LAGGING_PAD,
        "광폭 패드",
        "칼라 철, 0.7T, 6m",
      );
      this.laggingPad_laggingPadSpring.setDefaultData(
        1,
        CONST.ITEM_ID_LAGGING_PAD_SPRING,
        CONST.ITEM_NAME_LAGGING_PAD_SPRING,
        "광폭 패드",
        "일반, 2m",
      );
      this.laggingPad_padConnectionPin.setDefaultData(
        2,
        CONST.ITEM_ID_PAD_CONNECTION_PIN,
        CONST.ITEM_NAME_PAD_CONNECTION_PIN,
        "광폭 패드",
        "칼라 철",
      );
      this.laggingPad_padVinyl.setDefaultData(
        3,
        CONST.ITEM_ID_PAD_VINYL,
        CONST.ITEM_NAME_PAD_VINYL,
        "광폭 패드",
        "일반, 200m",
      );
      this.laggingPad_screw.setDefaultData(
        4,
        CONST.ITEM_ID_SCREW,
        CONST.ITEM_NAME_SCREW,
        "광폭 패드",
        "십자, 일반, 8/9*13mm, 1000개",
      );
    }
  }

  // @override
  public setDefaultModel(): void {
    super.setDefaultModel();
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  // @override
  public algorithmPart(): void {
    super.algorithmPart();

    /// //////// 선언 ///////////

    const screennetPosition: ScreennetPositionIL = <ScreennetPositionIL>this.position;
    let bothEndQuantity: number;
    let centralQuantity: number;
    // 외부
    let endpieceLevel: EndpieceLevelIL | EndpieceLevelVL;
    if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      endpieceLevel = <EndpieceLevelIL>this.struct.endpieceWorkIL.levelAC[this.level.index];
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      endpieceLevel = <EndpieceLevelVL>this.struct.endpieceWorkVL.levelAC[this.level.index];
    }
    const switcherPosition: SwitcherPositionIL = <SwitcherPositionIL>(
      this.struct.switcherWorkIL.levelAC[this.level.index].positionAC[this.position.index]
    );

    /// //////// 선택, 가시성 ///////////

    this.laggingPad_laggingPad.selected = false;
    this.laggingPad_laggingPad.visible = true;
    this.laggingPad_laggingPadSpring.selected = true;
    this.laggingPad_laggingPadSpring.visible = true;
    this.laggingPad_padConnectionPin.selected = false;
    this.laggingPad_padConnectionPin.visible = true;
    this.laggingPad_padVinyl.selected = false;
    this.laggingPad_padVinyl.visible = true;
    this.laggingPad_screw.selected = false;
    this.laggingPad_screw.visible = true;

    /// //////// 수량 ///////////

    // laggingPad_laggingPad.designQuantity = 0;

    if (this.position.label === CONST.LB_POSITION_SIDE_COLUMN || this.position.label === CONST.LB_POSITION_SIDE_WING) {
      this.laggingPad_laggingPadSpring.designQuantity =
        Math.ceil(this.basicLevel.length / this.laggingPad_laggingPadSpring.specLength) *
        switcherPosition.switchWayValue *
        CONST.NUM_EXTRA_RATE_PAD_SPRING;
    } else if (this.position.label === CONST.LB_POSITION_ROOF) {
      if (this.basic.buildingNumber >= 2) {
        bothEndQuantity = (this.basicLevel.length / this.laggingPad_laggingPadSpring.specLength) * 2;
        centralQuantity =
          (this.basicLevel.length / this.laggingPad_laggingPadSpring.specLength) *
          switcherPosition.switchWayValue *
          (this.basic.buildingNumber - 2);
        this.laggingPad_laggingPadSpring.designQuantity =
          (bothEndQuantity + centralQuantity) * CONST.NUM_EXTRA_RATE_PAD_SPRING;
      } else {
        this.laggingPad_laggingPadSpring.designQuantity =
          (this.basicLevel.length / this.laggingPad_laggingPadSpring.specLength) *
          switcherPosition.switchWayValue *
          CONST.NUM_EXTRA_RATE_PAD_SPRING;
      }
    } else if (this.position.label === CONST.LB_POSITION_FRONT) {
      this.laggingPad_laggingPadSpring.designQuantity =
        Math.ceil(
          (this.basicLevel.width * endpieceLevel.frontPosition.switchBuildingNumber) /
            this.laggingPad_laggingPadSpring.specLength,
        ) * CONST.NUM_EXTRA_RATE_PAD_SPRING;
    } else if (this.position.label === CONST.LB_POSITION_BACK) {
      this.laggingPad_laggingPadSpring.designQuantity =
        Math.ceil(
          (this.basicLevel.width * endpieceLevel.backPosition.switchBuildingNumber) /
            this.laggingPad_laggingPadSpring.specLength,
        ) * CONST.NUM_EXTRA_RATE_PAD_SPRING;
    }

    // laggingPad_padConnectionPin.designQuantity = 0;

    // laggingPad_padVinyl.designQuantity = 0;

    // laggingPad_screw.designQuantity = 0;
  }

  //----------------------------------
  // 품목 규격 및 상표
  //----------------------------------

  /**
   * 규격 알고리즘: [광폭 패드] 광폭 패드 <- 광폭 패드 샘플
   */
  public algorithmSpec_laggingPad_laggingPad(): void {
    this.laggingPad_laggingPad.specs = this.laggingPadSample.specs;
  }

  /**
   * 규격 알고리즘: [광폭 패드] 광폭 패드 스프링 <- 없음
   */
  public algorithmSpec_laggingPad_laggingPadSpring(): void {}

  /**
   * 규격 알고리즘: [광폭 패드] 패드 연결핀 <- 광폭 패드 샘플
   */
  public algorithmSpec_laggingPad_padConnectionPin(): void {
    this.laggingPad_padConnectionPin.specMaterial = this.laggingPadSample.specMaterial;
  }

  /**
   * 규격 알고리즘: [광폭 패드] 패드 비닐 <- 공통 샘플
   */
  public algorithmSpec_laggingPad_padVinyl(): void {}

  /**
   * 규격 알고리즘: [광폭 패드] 나사 <- 공통 샘플
   */
  public algorithmSpec_laggingPad_screw(): void {}

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
