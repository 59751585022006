import { jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { CommonUtil } from "vhows-design/src/common/util/CommonUtil";
import { CONST } from "vhows-design/src/common/constant/CONST";
import { BaseEntity } from "vhows-design/src/object/base/BaseEntity";
import { Design } from "vhows-design/src/object/design/Design";
import { Basic } from "vhows-design/src/object/design/basic/Basic";
import { BasicBuilding } from "vhows-design/src/object/design/basic/BasicBuilding";
import { RoofLevelIL } from "vhows-design/src/object/design/frame/roof/RoofLevelIL";
import { RoofPositionIL } from "vhows-design/src/object/design/frame/roof/RoofPositionIL";
import { TrunkLevel } from "vhows-design/src/object/design/frame/trunk/TrunkLevel";
import { TrunkPosition } from "vhows-design/src/object/design/frame/trunk/TrunkPosition";
import { TrunkPart_Rafter } from "vhows-design/src/object/design/frame/trunk/TrunkPart_Rafter";
import { RoofPartIL_Rafter } from "vhows-design/src/object/design/frame/roof/RoofPartIL_Rafter";
import { RoofPartVL_Rafter } from "vhows-design/src/object/design/frame/roof/vl/RoofPartVL_Rafter";
import { RoofPositionVL } from "vhows-design/src/object/design/frame/roof/vl/RoofPositionVL";
import { StructVL } from "vhows-design/src/object/design/base/StructVL";
import { RoofLevelVL } from "vhows-design/src/object/design/frame/roof/vl/RoofLevelVL";

/**
 * @author 김평화
 * @copyright RUNean Inc.
 * @date 2015-03-26
 */
@jsonObject
export class BasicLevel extends BaseEntity {
  //--------------------------------------------------------------------------
  //
  // Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /** 설계 객체 */
  public design: Design;

  /** 기본정보 객체 */
  public basic: Basic;

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  /** 깊은 복사중 여부 */
  public isDeepCopying: boolean = false;
  /** 동수별 기본정보 */
  @jsonArrayMember(BasicBuilding)
  public basicBuildingAC: BasicBuilding[];

  //--------------------------------------------------------------------------
  //
  // Remote Variables (변경시 deepCopy()함수도 변경할 것)
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 레퍼런스 변수
  //----------------------------------

  /**
   * 서까래 길이
   */
  public get lengthRafter(): number {
    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      return (<TrunkPart_Rafter>this.design.struct.trunkWork.levelAC[this.index].positionAC[0].partAC[0])
        .rafterTotalLength;
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.index >= this.design.struct.roofWorkIL.levelAC.length) return 0;
      return (<RoofPartIL_Rafter>this.design.struct.roofWorkIL.levelAC[this.index].positionAC[0].partAC[0])
        .rafterTotalLength;
    } else if (this.design.struct instanceof StructVL) {
      // 추후 확인 - 기본정보에서 적용을 눌러야 벤로형 구조로 변경됨. 따라서 현 시점에서는 벤로형 지붕 골조가 없음
      // return 0;
      if (this.index >= this.design.struct.roofWorkVL.levelAC.length) return 0;
      return (<RoofPartVL_Rafter>this.design.struct.roofWorkVL.level1.positionAC[0].partAC[0]).rafterTotalLength;
    } else {
      return this._lengthRafterIdeal;
    }
  }
  //
  public set lengthRafter(value: number) {}

  //----------------------------------
  // 데이터 변수
  //----------------------------------

  @jsonMember(Number)
  public _index: number = 0; // 인덱스
  @jsonMember(Number)
  public _width: number = 0; // 폭
  @jsonMember(Number)
  public _length: number = 0; // 길이
  @jsonMember(Number)
  public _heightShoulder: number = 0; // 측고
  @jsonMember(Number)
  public _depthLaying: number = 0; // 매설
  @jsonMember(Number)
  public _distanceShoulderPad: number = 0; // 어깨패드 거리
  @jsonMember(Number)
  public _perimeterRoof: number = 0; // 지붕 둘레
  @jsonMember(Number)
  public _perimeterRoofIdeal: number = 0; // 지붕 둘레(이상값)
  @jsonMember(Number)
  public _heightRoofMax: number = 0; // 지붕 높이
  @jsonMember(Number)
  public _heightRoofMaxIdeal: number = 0; // 지붕 높이(이상값)
  @jsonMember(Number)
  public _heightMax: number = 0; // 동고
  @jsonMember(Number)
  public _heightMaxIdeal: number = 0; // 동고(이상값)
  @jsonMember(Number)
  public _lengthRafterIdeal: number = 0; // 서까래 길이(이상값)

  /**
   * 인덱스 <br/>
   * 현재 중수-1 값을 갖는 고유번호.
   */
  public get index(): number {
    return this._index;
  }

  //
  public set index(value: number) {
    this._index = value;
  }

  /**
   * 폭
   */
  public get width(): number {
    return this._width;
  }

  //
  public set width(value: number) {
    this._width = CommonUtil.fixFloat(value);

    if (this.isDeepCopying === false) {
      this.basic.isApplyChanged = true;
      this.basic.isDesignChanged = true;

      // 알고리즘
      if (this.index === 0) {
        this.basic.algorithm_area();
      }
      this.algorithm_perimeterRoofIdeal();
      // this.algorithm_heightRoofMaxIdeal();
      this.algorithm_heightRoofMax();
      this.basic.algorithm_widthByLevel(this.index);
    }
  }

  /**
   * 길이
   */
  public get length(): number {
    return this._length;
  }

  //
  public set length(value: number) {
    this._length = CommonUtil.fixFloat(value);

    if (this.isDeepCopying === false) {
      this.basic.isApplyChanged = true;
      this.basic.isDesignChanged = true;

      // 알고리즘
      if (this.index === 0) {
        this.basic.algorithm_area();
      }
      // 가변길이가 아닐경우만 수행
      if (!this.basic.isVariableLength) {
        this.basic.algorithm_lengthByLevel(this.index);
      }
    }
  }

  /**
   * 측고
   */
  public get heightShoulder(): number {
    return this._heightShoulder;
  }

  //
  public set heightShoulder(value: number) {
    this._heightShoulder = CommonUtil.fixFloat(value);

    if (this.isDeepCopying === false) {
      this.basic.isApplyChanged = true;
      this.basic.isDesignChanged = true;

      // 알고리즘
      this.algorithm_distanceShoulderPad();
      this.algorithm_heightMaxIdeal();
      this.algorithm_rafterTotalLengthIdeal();
      // this.algorithm_perimeterRoof();
      // this.algorithm_heightMax();
      this.basic.algorithm_heightShoulderByLevel(this.index);
    }
  }

  /**
   * 매설
   */
  public get depthLaying(): number {
    return this._depthLaying;
  }

  //
  public set depthLaying(value: number) {
    this._depthLaying = CommonUtil.fixFloat(value);

    if (this.isDeepCopying === false) {
      this.basic.isApplyChanged = true;
      this.basic.isDesignChanged = true;

      // 알고리즘
      this.algorithm_rafterTotalLengthIdeal();
      // this.algorithm_perimeterRoof();
      if (this.index === 0) {
        this.basic.algorithm_depthLayingByLevel1();
      }
    }
  }

  /**
   * 어깨패드 거리
   */
  public get distanceShoulderPad(): number {
    return this._distanceShoulderPad;
  }

  //
  public set distanceShoulderPad(value: number) {
    this._distanceShoulderPad = CommonUtil.fixFloat(value);

    if (this.isDeepCopying === false) {
      this.basic.isApplyChanged = true;
      this.basic.isDesignChanged = true;
    }
  }

  /**
   * 지붕 둘레
   */
  public get perimeterRoof(): number {
    return this._perimeterRoof;
  }

  //
  public set perimeterRoof(value: number) {
    this._perimeterRoof = value;

    if (this.isDeepCopying === false) {
      // 알고리즘
      this.algorithm_heightRoofMax();
    }
  }

  /**
   * 지붕 둘레(이상값)
   */
  public get perimeterRoofIdeal(): number {
    return this._perimeterRoofIdeal;
  }

  //
  public set perimeterRoofIdeal(value: number) {
    this._perimeterRoofIdeal = value;

    if (this.isDeepCopying === false) {
      // 알고리즘
      this.algorithm_heightRoofMaxIdeal();
      this.algorithm_rafterTotalLengthIdeal();
    }
  }

  /**
   * 지붕 높이
   */
  public get heightRoofMax(): number {
    return this._heightRoofMax;
  }

  //
  public set heightRoofMax(value: number) {
    this._heightRoofMax = value;

    if (this.isDeepCopying === false) {
      // 알고리즘
      this.algorithm_heightMax();
    }
  }

  /**
   * 지붕 높이(이상값)
   */
  public get heightRoofMaxIdeal(): number {
    return this._heightRoofMaxIdeal;
  }

  //
  public set heightRoofMaxIdeal(value: number) {
    this._heightRoofMaxIdeal = value;

    if (this.isDeepCopying === false) {
      // 알고리즘
      this.algorithm_heightMaxIdeal();
    }
  }

  /**
   * 동고
   */
  public get heightMax(): number {
    return this._heightMax;
  }

  //
  public set heightMax(value: number) {
    this._heightMax = value;

    if (this.isDeepCopying === false) {
    }
  }

  /**
   * 동고(이상값)
   */
  public get heightMaxIdeal(): number {
    return this._heightMaxIdeal;
  }

  //
  public set heightMaxIdeal(value: number) {
    this._heightMaxIdeal = value;

    if (this.isDeepCopying === false) {
    }
  }

  /**
   * 서까래 길이(이상값)
   */
  public get lengthRafterIdeal(): number {
    return this._lengthRafterIdeal;
  }
  //
  public set lengthRafterIdeal(value: number) {
    this._lengthRafterIdeal = value;

    if (this.isDeepCopying === false) {
      if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
        this.design.struct.roofWorkVL.level1.roofPosition.rafterPart.rafterPipeSample.algorithmSpec_specLength();
      }
    }
  }

  //--------------------------------------------------------------------------
  //
  // Constructor
  //
  //--------------------------------------------------------------------------

  /**
   * 생성자
   */
  constructor() {
    super();
    this.basicBuildingAC = [];
  }

  //--------------------------------------------------------------------------
  //
  // Methods
  //
  //--------------------------------------------------------------------------

  //----------------------------------
  // 객체 일반
  //----------------------------------

  /**
   * 객체 연관 설정
   */
  public setAssociation(design: Design, basic: Basic): void {
    this.design = design;
    this.basic = basic;

    if (this.basicBuildingAC != null) {
      for (const basicBuilding of this.basicBuildingAC) {
        basicBuilding.setAssociation(basic, this);
      }
    }
  }

  /**
   * 레퍼런스 변수 설정
   */
  public setReferenceVariable(): void {}

  /**
   * 기본 데이터 설정: 데이터베이스를 대신함
   * @param index 중수인덱스
   * @param width 폭
   * @param length 길이
   * @param heightShoulder 측고
   * @param depthLaying 매설
   * @param distanceShoulderPad 어깨패드 거리
   * @param perimeterRoof 지붕 둘레(실제값,이상값)
   * @param heightRoofMax 지붕 높이(실제값,이상값)
   * @param heightMax 동고(실제값,이상값)
   * @param lengthRafterIdeal 서까래 길이(이상값)
   */
  public setDefaultData(
    index: number,
    width: number,
    length: number,
    heightShoulder: number,
    depthLaying: number,
    distanceShoulderPad: number,
    perimeterRoof: number = NaN,
    heightRoofMax: number = NaN,
    heightMax: number = NaN,
    lengthRafterIdeal: number = NaN,
  ): void {
    this._index = index;
    this._width = width;
    this._length = length;
    this._heightShoulder = heightShoulder;
    this._depthLaying = depthLaying;
    this._distanceShoulderPad = distanceShoulderPad;

    this._perimeterRoof = perimeterRoof;
    this._perimeterRoofIdeal = perimeterRoof;
    this._heightRoofMax = heightRoofMax;
    this._heightRoofMaxIdeal = heightRoofMax;
    this._heightMax = heightMax;
    this._heightMaxIdeal = heightMax;
    this._lengthRafterIdeal = lengthRafterIdeal;
  }

  /**
   * 기본 변수 설정 <br/>
   * - 새로 만들던, 불러오던 모두 동작함
   */
  public setDefaultVariable(): void {}

  /**
   * 저장된 아이디값 세팅
   */
  public setSavedId(savedBaiscLevel: BasicLevel): void {
    if (savedBaiscLevel != null) {
      this.id = savedBaiscLevel.id;

      if (this.basicBuildingAC != null && savedBaiscLevel.basicBuildingAC != null) {
        for (let i: number = 0; i < this.basicBuildingAC.length; i++) {
          // 기존 배열길이만큼 할당하되 저장된 배열길이가 더 작을경우는 할당하지 않음
          if (i < savedBaiscLevel.basicBuildingAC.length) {
            this.basicBuildingAC[i].id = savedBaiscLevel.basicBuildingAC[i].id;
          }
        }
      }
    }
  }

  /**
   * 깊은 복사 - 뷰에서 임시로 보여줄 값으로 사용하기 위한 용도
   * @param origin 원본 객체
   */
  public deepCopy(origin: BasicLevel): void {
    this.isDeepCopying = true;

    // Remote Variables
    // 아이디, 수정일시 등은 복사안함
    this._index = origin.index;
    this._width = origin.width;
    this._length = origin.length;
    this._heightShoulder = origin.heightShoulder;
    this._depthLaying = origin.depthLaying;
    this._distanceShoulderPad = origin.distanceShoulderPad;
    this._perimeterRoof = origin.perimeterRoof;
    this._perimeterRoofIdeal = origin.perimeterRoofIdeal;
    this._heightRoofMax = origin.heightRoofMax;
    this._heightRoofMaxIdeal = origin.heightRoofMaxIdeal;
    this._heightMax = origin.heightMax;
    this._heightMaxIdeal = origin.heightMaxIdeal;
    this._lengthRafterIdeal = origin.lengthRafterIdeal;

    // 동수별 기본정보
    this.basicBuildingAC = [];
    for (let i: number = 0; origin.basicBuildingAC && i < origin.basicBuildingAC.length; i++) {
      this.basicBuildingAC.push(new BasicBuilding());
    }
    for (let i: number = 0; origin.basicBuildingAC && i < origin.basicBuildingAC.length; i++) {
      this.basicBuildingAC[i].deepCopy(origin.basicBuildingAC[i]);
    }

    // // 동수별 기본정보
    // if (origin.basicBuildingAC) {
    //   this.basicBuildingAC = [];
    //   for (let basicBuilding of origin.basicBuildingAC) {
    //     this.basicBuildingAC.push(basicBuilding);
    //   }
    //   for (let i: number = 0; i < origin.basicBuildingAC.length; i++) {
    //     this.basicBuildingAC[i].setAssociation(this.basic, this);
    //     this.basicBuildingAC[i].setReferenceVariable();
    //     this.basicBuildingAC[i].deepCopy(origin.basicBuildingAC[i]);
    //   }
    // }

    this.isDeepCopying = false;
  }

  //----------------------------------
  // 하우스 설계
  //----------------------------------

  /**
   * 기본 정보 알고리즘
   */
  public algorithmBasic(): void {}

  /**
   * 알고리즘: 어깨패드 거리 <- 측고
   */
  public algorithm_distanceShoulderPad(): void {}

  /**
   * 알고리즘: 지붕 둘레 <- 구조, 매설, 측고, 최종 서까래 길이(단동/기초 골조), 최종 서까래 길이(연동/지붕 골조)
   */
  public algorithm_perimeterRoof(): void {
    if (this.design != null) {
      if (
        this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
        this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
        this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
      ) {
        const trunkPosition: TrunkPosition = (<TrunkLevel>this.design.struct.trunkWork.levelAC[this.index])
          .trunkPosition;
        this.perimeterRoof = CommonUtil.fixFloat(
          trunkPosition.rafterPart.rafterTotalLength -
            CommonUtil.fixFloat(CommonUtil.fixFloat(this.depthLaying + this.heightShoulder) * 2),
        );
      } else if (
        this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
        this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
      ) {
        const roofPosition: RoofPositionIL = (<RoofLevelIL>this.design.struct.roofWorkIL.levelAC[this.index])
          .roofPosition;
        this.perimeterRoof = CommonUtil.fixFloat(roofPosition.rafterPart.rafterTotalLength);
      } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
        const roofPosition: RoofPositionVL = (<RoofLevelVL>this.design.struct.roofWorkVL.levelAC[this.index])
          .roofPosition;
        this.perimeterRoof = CommonUtil.fixFloat(roofPosition.rafterPart.rafterTotalLength);
      } else {
        this.perimeterRoof = this.perimeterRoofIdeal;
      }
    }
  }

  /**
   * 알고리즘: 지붕 둘레(이상값) <- 구조, 폭
   */
  public algorithm_perimeterRoofIdeal(): void {
    let factor: number;

    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      if (this.index === 0) {
        // 1중
        if (this.width <= 6.0) {
          factor = 1.13;
        } else if (this.width < 7.5) {
          factor = 1.14;
        } else {
          factor = 1.15;
        }
        this.perimeterRoofIdeal = CommonUtil.round(CommonUtil.fixFloat(this.width * factor), 1);
      } else {
        // 2중이상
        this.perimeterRoofIdeal = CommonUtil.round(this.basic.basicLevelAC[this.index - 1].perimeterRoofIdeal - 0.6, 1);
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH) {
      if (this.index === 0) {
        // 1중
        if (this.width < 20.0) {
          this.perimeterRoofIdeal = this.width + 1.0;
        } else {
          this.perimeterRoofIdeal = this.width + 2.0;
        }
      } else {
        // 2중이상
        this.perimeterRoofIdeal = CommonUtil.round(this.basic.basicLevelAC[this.index - 1].perimeterRoofIdeal - 0.6, 1);
      }
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.width <= 6.0) {
        factor = 1.13;
      } else if (this.width < 7) {
        factor = 1.14;
      } else {
        factor = 1.17;
      }
      this.perimeterRoofIdeal = CommonUtil.round(CommonUtil.fixFloat(this.width * factor), 1);
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      // 추후 확인
      if (this.design.struct instanceof StructVL) {
        const RoofPosition: RoofPositionVL = this.design.struct.roofWorkVL.level1.roofPosition;
        let rafterWidth: number = this.width / RoofPosition.rafterPart.rafterValleyQuantity;
        if (rafterWidth <= 6.0) {
          factor = 1.13;
        } else if (rafterWidth < 7) {
          factor = 1.14;
        } else {
          factor = 1.17;
        }
        this.perimeterRoofIdeal = CommonUtil.round(CommonUtil.fixFloat(rafterWidth * factor), 1);
      }
    }
  }

  /**
   * 알고리즘: 지붕 높이 <- 폭, 지붕 둘레
   */
  public algorithm_heightRoofMax(): void {
    const halfWidth: number = this.width / 2;
    const halfPerimeter: number = this.perimeterRoof / 2;

    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      if (this.perimeterRoof > this.width) {
        this.heightRoofMax = CommonUtil.roundDown(Math.sqrt(halfPerimeter * halfPerimeter - halfWidth * halfWidth), 1);
      } else {
        this.heightRoofMax = 0;
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH) {
      if (this.perimeterRoof > this.width) {
        this.heightRoofMax = CommonUtil.roundDown(2 + (this.perimeterRoof - this.width) * 0.1, 1);
      } else {
        this.heightRoofMax = 0;
      }
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.perimeterRoof > this.width) {
        this.heightRoofMax = CommonUtil.roundDown(Math.sqrt(halfPerimeter * halfPerimeter - halfWidth * halfWidth), 1);
      } else {
        this.heightRoofMax = 0;
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const RoofPosition: RoofPositionVL = this.design.struct.roofWorkVL.level1.roofPosition;
      const venloHalfWidth: number = this.width / RoofPosition.rafterPart.rafterValleyQuantity / 2;
      const venloHalfPerimeter: number = this.perimeterRoof / 2;
      if (this.perimeterRoof > this.width / RoofPosition.rafterPart.rafterValleyQuantity) {
        this.heightRoofMax = CommonUtil.roundDown(
          Math.sqrt(venloHalfPerimeter * venloHalfPerimeter - venloHalfWidth * venloHalfWidth),
          1,
        );
      } else {
        this.heightRoofMax = 0;
      }
    }
  }

  /**
   * 알고리즘: 지붕 높이(이상값) <- 폭, 지붕 둘레(이상값)
   */
  public algorithm_heightRoofMaxIdeal(): void {
    const halfWidth: number = this.width / 2;
    const halfPerimeter: number = this.perimeterRoofIdeal / 2;

    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      if (this.perimeterRoofIdeal > this.width) {
        this.heightRoofMaxIdeal = CommonUtil.roundDown(
          Math.sqrt(halfPerimeter * halfPerimeter - halfWidth * halfWidth),
          1,
        );
      } else {
        this.heightRoofMaxIdeal = 0;
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH) {
      if (this.perimeterRoofIdeal > this.width) {
        this.heightRoofMaxIdeal = CommonUtil.roundDown(2 + (this.perimeterRoofIdeal - this.width) * 0.1, 1);
      } else {
        this.heightRoofMaxIdeal = 0;
      }
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL
    ) {
      if (this.perimeterRoofIdeal > this.width) {
        this.heightRoofMaxIdeal = CommonUtil.roundDown(
          Math.sqrt(halfPerimeter * halfPerimeter - halfWidth * halfWidth),
          1,
        );
      } else {
        this.heightRoofMaxIdeal = 0;
      }
    } else if (this.basic.structureName === CONST.LB_STRUCT_VENLO) {
      const RoofPosition: RoofPositionVL = this.design.struct.roofWorkVL.level1.roofPosition;
      const venloHalfWidth: number = this.width / RoofPosition.rafterPart.rafterValleyQuantity / 2;
      const venloHalfPerimeter: number = this.perimeterRoofIdeal / 2;
      if (this.perimeterRoofIdeal > this.width / RoofPosition.rafterPart.rafterValleyQuantity) {
        this.heightRoofMaxIdeal = CommonUtil.roundDown(
          Math.sqrt(venloHalfPerimeter * venloHalfPerimeter - venloHalfWidth * venloHalfWidth),
          1,
        );
      } else {
        this.heightRoofMaxIdeal = 0;
      }
    }
  }

  /**
   * 알고리즘: 동고 <- 측고, 지붕 높이
   */
  public algorithm_heightMax(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.heightMax = CommonUtil.roundDown(this.heightShoulder + this.heightRoofMax, 1);
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.heightMax = CommonUtil.roundDown(this.heightShoulder + this.heightRoofMax, 1);
    }
  }

  /**
   * 알고리즘: 동고(이상값) <- 측고, 지붕 높이(이상값)
   */
  public algorithm_heightMaxIdeal(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.heightMaxIdeal = CommonUtil.roundDown(this.heightShoulder + this.heightRoofMaxIdeal, 1);
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.heightMaxIdeal = CommonUtil.roundDown(this.heightShoulder + this.heightRoofMaxIdeal, 1);
    }
  }

  /**
   * 알고리즘: 서까래 길이(이상값) <- 매설, 측고, 지붕 둘레(이상값)
   */
  public algorithm_rafterTotalLengthIdeal(): void {
    if (
      this.basic.structureName === CONST.LB_STRUCT_SINGLE ||
      this.basic.structureName === CONST.LB_STRUCT_DOUBLE_WIDTH ||
      this.basic.structureName === CONST.LB_STRUCT_RAIN_PROOF
    ) {
      this.lengthRafterIdeal = CommonUtil.fixFloat(
        this.perimeterRoofIdeal + CommonUtil.fixFloat(CommonUtil.fixFloat(this.depthLaying + this.heightShoulder) * 2),
      );
    } else if (
      this.basic.structureName === CONST.LB_STRUCT_INTERLOCK ||
      this.basic.structureName === CONST.LB_STRUCT_ANGULAR_IL ||
      this.basic.structureName === CONST.LB_STRUCT_VENLO
    ) {
      this.lengthRafterIdeal = CommonUtil.fixFloat(this.perimeterRoofIdeal);
    }
  }

  /**
   * 알고리즘: 기본동수 배열 <- 동수, 가변길이 여부 <br/>
   * 가변길이 여부 및 동수를 기반으로 기본동수 객체를 재구성함
   * @param 기본정보 객체
   */
  public algorithm_basicBuildingAC(basic: Basic, isVariableLength: boolean): void {
    let i: number = 0;

    if (isVariableLength === true) {
      if (this.basicBuildingAC == null) {
        // 동수별 정보가 없을경우 생성
        this.basicBuildingAC = [];
        for (i = 0; i < basic.buildingNumber; i++) {
          const newBasicBuilding: BasicBuilding = new BasicBuilding();
          newBasicBuilding.setAssociation(basic, this);
          newBasicBuilding.setDefaultData(i, i + 1, this.length);
          this.basicBuildingAC.push(newBasicBuilding);
        }
      } else {
        if (basic.buildingNumber > this.basicBuildingAC.length) {
          // 동수가 늘어나면 추가
          for (i = this.basicBuildingAC.length; i < basic.buildingNumber; i++) {
            const addBasicBuilding: BasicBuilding = new BasicBuilding();
            addBasicBuilding.setAssociation(basic, this);
            addBasicBuilding.setDefaultData(i, i + 1, this.length);
            this.basicBuildingAC.push(addBasicBuilding);
          }
        } else if (basic.buildingNumber < this.basicBuildingAC.length) {
          // 동수가 줄어들면 삭제
          for (i = this.basicBuildingAC.length; i > basic.buildingNumber; i--) {
            this.basicBuildingAC.splice(i - 1, 1);
          }
        }
      }
    } else {
      this.basicBuildingAC = null;
    }
  }

  /**
   * 알고리즘: 평균 길이 <- 동수별 길이, 동수
   */
  public algorithm_averageLength(): void {
    if (this.basicBuildingAC != null) {
      let sum: number = 0;
      for (const basicBuilding of this.basicBuildingAC) {
        sum += basicBuilding.length;
      }
      this.length = CommonUtil.roundUp(sum / this.basic.buildingNumber, 1);
    }
  }

  //--------------------------------------------------------------------------
  //
  // Internal Methods
  //
  //--------------------------------------------------------------------------
}
