export const ROUTE = {
  TYPE: "/type",
  AREA: "/area",
  CAREER: "/career",
  CROP: "/crop",
  ADDRESS: "/address",
  PLAN: "/plan",
  SUBSIDY_STATUS: "/subsidy-status",
  SUBSIDY_TYPE: "/subsidy-type",
  RESULT: "/result",
  PRIVACY: "/privacy",
  USAGE: "/usage",
  INDEX: "/",
  CONTRACT: "/contract",
  WIDTHLENGTH: "/widthLength",
  APPLICATION: "/application",
  MEMO: "/memo",
  FACIL_TYPE: "/facility-type",
  FACIL_DETAIL_TYPE: "/facility-detail-type",
  DETAIL: "/detail",
  LIST: "/list",
  ACCESS_DENIED: "/access-denied",
  DESIGN_UPDATE: "/design/update",
  LANDING: "/landing",
};

export const ROUTE_ORDER = [
  ROUTE.LANDING,
  ROUTE.FACIL_TYPE,
  ROUTE.WIDTHLENGTH,
  ROUTE.FACIL_DETAIL_TYPE,
  ROUTE.ADDRESS,
  ROUTE.CONTRACT,
  ROUTE.RESULT,
  ROUTE.PLAN,
  ROUTE.SUBSIDY_STATUS,
  ROUTE.SUBSIDY_TYPE,
  ROUTE.CAREER,
  ROUTE.MEMO,
  ROUTE.PRIVACY,
];
