type Navigator = {
  /**
   * 클릭 콜백
   */
  onClick: Function;
};

export const Navigator = ({ onClick }: Navigator) => {
  return (
    <div id="header" className="absolute left-0 z-10 h-14">
      <div
        className="float-left h-14 w-14 cursor-pointer p-3 text-center"
        onClick={event => {
          onClick(event);
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
        </svg>
      </div>
    </div>
  );
};
